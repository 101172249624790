import { NgClass } from "@angular/common";
import {
   Component,
   computed,
   effect,
   inject,
   input,
   output,
   signal,
   type OnInit,
} from "@angular/core";
import {
   DropdownComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { CustomTagList } from "src/app/tasks/components/customTagListModal/customTagList.element.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type User = {
   fullName: string;
};

export type Tag = {
   name: string;
   tagged: boolean;
};

@Component({
   selector: "task-description",
   templateUrl: "./task-description.component.html",
   styleUrls: ["./task-description.component.scss"],
   imports: [
      InfoPanelComponent,
      NgClass,
      MinimalIconButtonComponent,
      IconComponent,
      LimbleHtmlDirective,
      BetterDatePipe,
      DropdownComponent,
      CustomTagList,
   ],
})
export class TaskDescriptionComponent implements OnInit {
   public readonly task = input.required<TaskDataViewerViewModel>();
   public readonly userStartedThisTask = input.required<User>();
   public readonly checklistEstTimeMinutes = input<number>(0);
   public readonly checklistEstTimeHours = input<number>(0);
   public readonly itemsEditable = input<boolean>(false);
   public readonly tags = input<Array<Tag>>([]);
   public readonly customTagListObj = input<any>({});

   public readonly clickTag = output<Tag>();

   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);

   protected readonly currentUser = this.manageUser.getCurrentUser();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly expandDescription = signal(true);

   protected readonly displayTags = signal<Array<Tag>>([]);

   protected readonly description = computed(() => {
      const checklistInstructions = this.task().checklistInstructions;
      if (!checklistInstructions) {
         return "";
      }
      return checklistInstructions.length > this.MAX_DESCRIPTION_LENGTH
         ? `${checklistInstructions.substring(0, this.MAX_DESCRIPTION_LENGTH)}...`
         : checklistInstructions;
   });

   protected readonly taskDescriptionExistsAndBelowMaxLength = computed(() => {
      const checklistInstructions = this.task().checklistInstructions;
      return (
         checklistInstructions &&
         checklistInstructions.length < this.MAX_DESCRIPTION_LENGTH
      );
   });

   protected readonly MAX_DESCRIPTION_LENGTH = 120;

   protected readonly tagTaskCred = computed(() =>
      this.credService.isAuthorized(
         this.task().locationID,
         this.credService.Permissions.TagATaskWithACustomTag,
      ),
   );

   public constructor() {
      // TODO: Use a linked signal instead of an effect once we migrate to Angular 19.
      effect(
         () => {
            const tags = this.tags().filter((t) => t.tagged);
            this.displayTags.update(() => Array.from(tags));
         },
         { allowSignalWrites: true },
      );
   }

   public ngOnInit(): void {
      this.displayTags.set([...this.tags()]);
   }

   protected toggleExpandDescription() {
      this.expandDescription.set(!this.expandDescription());
   }

   protected clickedTag(tag: Tag) {
      this.clickTag.emit(tag);
   }
}
