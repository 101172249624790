import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   IconButtonComponent,
   IconComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageTool } from "src/app/assets/services/manageTool";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { CheckOutRequest } from "src/app/shared/types/general.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "tool-status-info",
   templateUrl: "./toolStatusInfo.element.component.html",
   styleUrls: ["./toolStatusInfo.element.component.scss"],
   imports: [
      IconComponent,
      TooltipDirective,
      PrimaryButtonComponent,
      IconButtonComponent,
   ],
})
export class ToolStatusInfo implements OnInit {
   @Input() tool;
   @Input() mostRecentRequest: CheckOutRequest | null | undefined;
   @Input() inTaskTool: boolean | undefined;

   @Input() createCheckOutRequest: (() => Promise<CheckOutRequest | false>) | undefined;
   @Input() checkInTool:
      | (() => Promise<CheckOutRequest | null | false | undefined>)
      | undefined;
   @Input() cancelRequest:
      | (() => Promise<CheckOutRequest | null | false | undefined>)
      | undefined;

   public status: "checkedIn" | "requestPending" | "checkedOut" = "checkedIn";
   public canCheckIn: boolean | undefined;
   public isUserWhoRequested: boolean | undefined;
   public requestedByUserDisplay: string | undefined;
   public requestSentToDisplay: string | false | undefined;
   public sendRequestClicked: boolean = false;
   public reSendRequestClicked: boolean = false;
   public requestButtonTooltip: string | undefined = undefined;

   private readonly currentUserID: number;

   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageTool = inject(ManageTool);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.currentUserID = Number(this.manageUser.getCurrentUser().gUserID);
   }

   public ngOnInit() {
      this.setMostRecentRequestAndStatus(this.mostRecentRequest);
      this.setPermissions();
      this.setUserDisplays();
   }

   private setMostRecentRequestAndStatus(mostRecentRequest) {
      this.mostRecentRequest = mostRecentRequest;
      if (!this.mostRecentRequest) {
         return;
      }
      this.status = this.manageTool.determineToolStatus(this.mostRecentRequest);
      if (this.status === "requestPending") {
         this.requestButtonTooltip = this.lang().PendingApprovalTooltip;
      } else {
         this.requestButtonTooltip = undefined;
      }
   }

   private setPermissions() {
      this.canCheckIn =
         this.credService.checkCredGlobal(this.credService.Permissions.ManageRoles) ||
         this.currentUserID === this.mostRecentRequest?.requestApprovalUserID ||
         this.currentUserID === this.mostRecentRequest?.requestedByUserID;
      this.isUserWhoRequested =
         this.currentUserID === this.mostRecentRequest?.requestedByUserID;
   }

   private setUserDisplays() {
      this.requestedByUserDisplay = this.manageUser.getUserFullName(
         Number(this.mostRecentRequest?.requestedByUserID),
      );
      this.requestSentToDisplay = this.manageUser.getUserOrProfileDisplay(
         this.mostRecentRequest?.requestSentToUserID,
         this.mostRecentRequest?.requestSentToProfileID,
      );
   }

   public async runCreateCheckOutRequest() {
      if (!this.createCheckOutRequest) {
         return;
      }
      this.sendRequestClicked = true;
      const mostRecentRequest = await this.createCheckOutRequest();
      this.sendRequestClicked = false;
      if (!mostRecentRequest) {
         return;
      }
      this.setMostRecentRequestAndStatus(mostRecentRequest);
      this.setUserDisplays();
      this.setPermissions();
   }

   public async runCheckInTool() {
      if (!this.checkInTool) {
         return;
      }
      const mostRecentRequest = await this.checkInTool();
      if (!mostRecentRequest) {
         return;
      }
      this.setMostRecentRequestAndStatus(mostRecentRequest);
   }

   public async reSendCheckOutRequest() {
      if (!this.mostRecentRequest) {
         return;
      }
      this.reSendRequestClicked = true;
      await this.manageTool.reSendCheckOutRequest(
         this.tool.assetID,
         this.mostRecentRequest.checkOutID,
         this.mostRecentRequest.checklistID,
      );
      this.reSendRequestClicked = false;
   }

   public async runCancelRequest() {
      if (!this.cancelRequest) {
         return;
      }
      const mostRecentRequest = await this.cancelRequest();
      if (!mostRecentRequest) {
         return;
      }
      this.setMostRecentRequestAndStatus(mostRecentRequest);
   }
}
