import { inject, Injectable } from "@angular/core";
import { assetInitializer } from "src/app/lite/local-db/resources/collection/asset/asset.initializer";
import { assetSchema } from "src/app/lite/local-db/resources/collection/asset/asset.schema";
import { AssetStorageService } from "src/app/lite/local-db/resources/collection/asset/asset.storage.service";
import { refetchValidData } from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { z } from "zod";

@Injectable({
   providedIn: "root",
})
export class AssetStorageSyncService extends StorageSyncService {
   private readonly assetStorageService = inject(AssetStorageService);

   public constructor() {
      super();
   }

   public async syncAsset(assetID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = assetInitializer;
         const assets = await refetchValidData({
            url,
            params: { assetIDs: [assetID] },
            validation: z.array(assetSchema),
         });
         const [asset] = assets;
         assert(
            asset !== undefined,
            `Asset #${assetID} not found in fetch when attempting to sync`,
         );

         await this.assetStorageService.putAsset(asset);
      });
   }
}
