<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="modal-info">
            <div class="info-group">
               <span>{{ message }}</span>
            </div>
            <a class="table-link" (click)="whoToNotify()" data-test-id="notifiedUsers">
               {{ lang().WhoWillBeSentNotifications }}
            </a>
         </div>
         <div
            type="text"
            class="comment-area"
            [(ngModel)]="comment"
            limbleContentEditable
            data-test="addNoteText"
            data-test-id="addNoteText"
            id="input-field-{{ mentionUid }}"
            focusAuto
         ></div>

         <mention
            [mentionList]="mentionList"
            [userProfiles]="userProfiles"
            [updateNewMentionedList]="updateNewMentionedList"
            dropdownDirection="down"
            [mentionUid]="mentionUid"
         />
         <div class="modal-info">
            <div class="info-group">
               <comment-files
                  [uploadObject]="uploadObject"
                  [files]="files"
                  icon="cloudUploadRegular"
                  label="AttachFile"
                  data-test-id="attachFile"
               />
            </div>
         </div>
      </lim-ui-info-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-primary-button (click)="submit()" data-test-id="submitButton">
         {{ lang().Submit }}
      </lim-ui-primary-button>
      <lim-ui-secondary-button (click)="close()" data-test-id="closeButton">
         {{ lang().Cancel }}
      </lim-ui-secondary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
