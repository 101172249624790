import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { CommentFiles } from "src/app/files/components/commentFiles/commentFiles.element.component";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import { ManageVendorLogs } from "src/app/vendors/services/manageVendorLogs";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "edit-vendor-log-item",
   templateUrl: "./editVendorLogItem.modal.component.html",
   styleUrls: ["./editVendorLogItem.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      IconComponent,
      TooltipDirective,
      FormsModule,
      CommentFiles,
      LimbleHtmlDirective,
      BetterDatePipe,
   ],
})
export class EditVendorLogItem implements OnInit {
   public log;
   public CID;
   private vendor: Vendor | undefined;
   public logUploadObj;
   public errorMsg;
   public data;
   public resolve;
   public modalInstance;

   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageVendorLogs = inject(ManageVendorLogs);
   private readonly manageFiles = inject(ManageFiles);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.log = this.data.log;
      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.vendor = this.manageVendor.getVendor(this.data.log.vendorID);
      if (this.vendor === undefined) {
         throw new Error("Could not get the specified vendor object");
      }

      //if they are not logged in then we need to close the model because they are redirected to the login page.
      this.manageLogin.checkLogin().then((answer: any) => {
         if (answer.data.success == false) {
            this.modalInstance.close();
         }
      });
      this.logUploadObj = {};
      this.logUploadObj.primaryID = "fileID";
      this.logUploadObj.posturl = `phpscripts/manageVendorLogs.php?action=makeFileToLog&locationID=${this.vendor.locationID}&vendorID=${this.vendor.vendorID}&logID=${this.log.vendorLogID}`;
      this.logUploadObj.maxFiles = 5;
      this.logUploadObj.viewOnly = false;
      this.logUploadObj.uploading = false;
      this.logUploadObj.deleteData = {};

      this.logUploadObj.deleteCall = async (data2) => {
         return this.manageVendorLogs.deleteVendorLogFile(data2);
      };

      this.logUploadObj.deleteSuccess = (answer) => {
         if (answer.data.success == true) {
            for (const key in this.log.logFiles) {
               if (
                  this.log.logFiles[key].fileID == JSON.parse(answer.config.data).fileID
               ) {
                  this.log.logFiles.splice(key, 1);
               }
            }
            for (const key in this.log.logPictures) {
               if (
                  this.log.logPictures[key].fileID ==
                  JSON.parse(answer.config.data).fileID
               ) {
                  this.log.logPictures.splice(key, 1);
               }
            }
            this.alertService.addAlert(this.lang().successMsg, "success", 5000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      };

      this.logUploadObj.uploadComplete = (data2) => {
         if (data2.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );
         }

         if (data2.fileID != 0) {
            const regex = /(?:\.([^.]+))?$/;
            const regexResult = regex.exec(data2.row.fileName);
            if (regexResult === null) {
               data2.row.fileExt = undefined;
            } else {
               data2.row.fileExt = regexResult[1].toLowerCase();
            }

            data2.row.getURL = `viewFile.php?f=upload-${this.CID}/vendors/${this.vendor?.locationID}/${data2.row.vendorID}/logs/${this.log.vendorLogID}/${data2.row.fileName}`;
            if (this.manageFiles.checkImageExt(data2.fileName)) {
               this.log.logPictures = this.log.logPictures || [];
               this.log.logPictures = [...this.log.logPictures, data2.row];
            } else {
               this.log.logFiles = this.log.logFiles || [];
               this.log.logFiles = [...this.log.logFiles, data2.row];
            }
         } else if (data2.fileID == 0) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         }
      };
   }

   changeDate = (log) => {
      const currentTime = new Date(log.logDate).getTime() / 1000;

      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PleaseSelectADateAndClickChange,
            title: this.lang().ChangeAnVendorLogsDate,
            buttonText: this.lang().Change,
            currentDate: currentTime,
            data: {
               viewTimeOfDay: true,
               parentOfDate: "log",
            },
         },
      };

      instance.result.then((result) => {
         if (result.date) {
            this.manageVendorLogs.updateLogDate(log, result.date).then((answer) => {
               if (answer.data.success == true) {
                  log.logDate = answer.data.date;
                  this.alertService.addAlert(this.lang().successMsg, "success", 5000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   changeLogDetails = (log) => {
      this.manageVendorLogs.changeLogDetails(log).then((answer) => {
         if (answer.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 5000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   close = () => {
      this.errorMsg = "";

      if (this.logUploadObj.uploading) {
         this.errorMsg = this.lang().pleaseWaitForFileToLoad;
      } else {
         this.modalInstance.close();
      }
   };
}
