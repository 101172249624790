import type { AssetTemplateField } from "src/app/assets/services/asset-field.types";
import type { ListResponse } from "src/app/shared/services/flannel-api-service";

export function mapAssetTemplateFieldApi(
   assetTemplateFields: AssetTemplateField[],
): Partial<ListResponse<AssetTemplateField>> {
   const listResponse: Partial<ListResponse<AssetTemplateField>> = {
      data: [],
      total: 0,
   };

   listResponse.data = assetTemplateFields;
   listResponse.total = assetTemplateFields.length;
   return listResponse;
}
