<span class="lim-ui-mobile-table-column-label mobile-label"
   >{{ lang().PODeliveryDate }}:</span
>
@if (purchaseOrder && purchaseOrder.state !== null) {
   <span class="mobile-flex">
      @if (purchaseOrder.state <= 97 && deliveryDate) {
         <span style="color: #999" [limUiTooltip]="lang().ExpectedDeliveryDate">
            {{ deliveryDate * 1000 | betterDate: "date" }}
         </span>
      }
      @if (purchaseOrder.state > 97) {
         <span class="mobile-flex">
            @for (prID of purchaseOrder?.prIDs; track prID) {
               <div class="mobile-flex">
                  @if (billDeliveryTotals.get(prID); as deliveryInfo) {
                     @if (deliveryInfo.total > 0 && deliveryInfo.date !== null) {
                        <div>
                           {{ deliveryInfo.total }} {{ lang().onStr }}
                           <a class="cursor table-link" (click)="popPR(prID)">
                              {{ deliveryInfo.date * 1000 | betterDate: "date" }}
                           </a>
                        </div>
                     }
                  }
               </div>
            }
         </span>
      }
   </span>
}
