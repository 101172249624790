import { inject, Injectable } from "@angular/core";
import { datadogRum } from "@datadog/browser-rum";
import { isMobile } from "@limblecmms/lim-ui";
import { getEnvString } from "src/app/shared/external-scripts/util/env-string";
import { loadJs } from "src/app/shared/external-scripts/util/load-js";
import { UserTypeService } from "src/app/shared/external-scripts/util/user-type.service";
import { ManageUser } from "src/app/users/services//manageUser";
import { CredService } from "src/app/users/services/creds/cred.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class IntercomService {
   private readonly window: any = window;

   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly userType = inject(UserTypeService);

   public async loadOrRefresh({
      limbleVersion,
   }: {
      limbleVersion: string;
   }): Promise<void> {
      if (!this.shouldUse()) {
         return;
      }
      await this.instantiate();
      const currentUser = this.manageUser.getCurrentUser();
      this.window.intercomSettings = {
         "app_id": "nl9zbdf7",
         "name": `${currentUser.userInfo.fName} ${currentUser.userInfo.lName}`, // Full name
         "email": currentUser.userInfo.userLoginName, // Email address
         "custom_in_app": 1,
         "custom_user_logins": currentUser.userInfo.logins,
         "custom_CusID": Number(currentUser.userInfo.customerID),
         "custom_review_segment": (Number(currentUser.userInfo.customerID) % 12) + 1,
         "Phone": currentUser.userInfo.userPhone,
         "plan_name": currentUser.userInfo.customerPlan,
         "Company Name": currentUser.userInfo.customerName,
         "customer_subscription_status": currentUser.userInfo.customerStatus,
         "env": getEnvString(),
         "sendUXSurvey": currentUser.userInfo.sendUXSurvey,
         "user_hash": currentUser.userInfo.userHash,
         "lead_source": currentUser.userInfo.leadSource,
      };

      if (
         this.credService.checkCredAnywhere(this.credService.Permissions.ViewManageAssets)
      ) {
         this.window.intercomSettings.limbleVersion = limbleVersion;
         if (datadogRum?.getInternalContext()?.session_id) {
            this.window.intercomSettings.custom_datadog_session_url = `https://app.datadoghq.com/rum/sessions?query=%40type%3Asession%20%40application.id%3A47b211d1-e891-4f2f-8487-c0a1d7c426ab%20%40session.id%3A${
               datadogRum.getInternalContext()?.session_id
            }&cols=&replay_tab=most_interacted_with`;
         }
      }

      this.window.intercomSettings.custom_userType = this.userType.get();

      if (this.credService.checkCredAnywhere(this.credService.Permissions.AddUsers)) {
         //if they can add users then they are at least a manager
         this.window.intercomSettings.custom_is_manager = true;
      } else {
         this.window.intercomSettings.custom_is_manager = false;
      }

      if (this.credService.checkCredGlobal(this.credService.Permissions.ManageRoles)) {
         //if they can do permission 30 they are a super user
         this.window.intercomSettings.custom_is_super_user = true;
      } else {
         this.window.intercomSettings.custom_is_super_user = false;
      }
      if (!document.domain.includes("localhost")) {
         this.window.Intercom("update", this.window.intercomSettings);
      }
   }

   public addUserProperties<T extends object>(metadata: T) {
      if (this.shouldUse()) {
         this.window.Intercom("update", metadata);
      }
   }

   private shouldUse(): boolean {
      const currentUser = this.manageUser.getCurrentUser();
      if (environment.production !== true) {
         return false;
      }
      if (currentUser.userInfo.userInternal === 1) {
         return false;
      }
      if (currentUser.gUserID == 341) {
         //no intercom for the live test drive user
         return false;
      }
      if (isMobile()) {
         return false;
      }
      return true;
   }

   private async instantiate(): Promise<void> {
      const win = window as any;
      type Intercom = ((...args: Array<unknown>) => void) & {
         array?: Array<unknown>;
         construct?: (args: Array<unknown>) => void;
      };
      if (typeof win.Intercom === "function") {
         win.Intercom("reattach_activator");
         win.Intercom("update", win.intercomSettings);
         return Promise.resolve();
      }
      const intercom: Intercom = function (...args: Array<unknown>): void {
         (intercom.construct as (args: Array<unknown>) => void)(args);
      };
      intercom.array = [];
      intercom.construct = (args: Array<unknown>): void => {
         (intercom.array as Array<unknown>).push(args);
      };
      win.Intercom = intercom;
      return loadJs("https://widget.intercom.io/widget/gg1xcirb");
   }
}
