import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "gather-number",
   templateUrl: "./gatherNumber.modal.component.html",
   styleUrls: ["./gatherNumber.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      FormsModule,
      AlertComponent,
      BasicModalFooterComponent,
   ],
})
export class GatherNumber implements OnInit {
   public message;
   public message2;
   public title;
   public warning;
   public errorMsg;
   public number;
   public data;
   public resolve;
   public modalInstance;

   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.message2 = this.resolve.message2;
      this.title = this.resolve.title;
      this.warning = this.resolve.warning;
      this.data = this.resolve.data;
      this.errorMsg = "";
      if (this.data.currentNumber) {
         this.number = Number(this.data.currentNumber);
      } else {
         this.number = 0;
      }
   }

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      if (this.number == null) {
         this.errorMsg = this.lang().CantBeBlank;
         return;
      }
      this.modalInstance.close(this.number);
   };
}
