import type { OnDestroy, OnInit } from "@angular/core";
import { Component, computed, forwardRef, inject, Input } from "@angular/core";
import {
   IconComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalService,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task, TaskLookup } from "src/app/tasks/types/task.types";

@Component({
   selector: "po-item-tasks-hint",
   templateUrl: "./poItemTasksHint.element.component.html",
   styleUrls: ["./poItemTasksHint.element.component.scss"],
   imports: [
      IconComponent,
      TooltipDirective,
      MinimalIconButtonComponent,
      LimbleHtmlDirective,
      forwardRef(() => AssetParentList),
   ],
})
export class PoItemTasksHint implements OnInit, OnDestroy {
   @Input() public poItemID: number | undefined;
   @Input() public other;
   @Input() public state: number | undefined;

   public tasksWaiting: Set<Task> = new Set();
   public tasksUsed: Set<Task> = new Set();
   public otherPurchaseOrdersAndStatus: Set<
      PurchaseOrder & { currentState: PurchaseOrderCurrentState | undefined }
   > = new Set();
   public purchaseOrderItem: PurchaseOrderItem | undefined;
   public openPOWatchVarSub;
   public openPRWatchVarSub;
   public completedTasksWatchVarSub;
   public tasksWatchVarSub;
   public InitialLoad = 0;
   public showOtherPOs = true;
   public taskAssetNames: LimbleMap<number, string> = new LimbleMap();

   private readonly modalService = inject(ModalService);
   private readonly managePO = inject(ManagePO);
   private readonly manageTask = inject(ManageTask);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);
   private readonly refreshService = inject(RefreshService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (this.poItemID === undefined) return;
      this.purchaseOrderItem = this.managePO.getPurchaseOrderItem(this.poItemID);

      this.openPOWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.watchData();
         },
      );

      this.openPRWatchVarSub = this.manageObservables.setSubscription(
         "OpenBillWatchVar",
         () => {
            this.watchData();
         },
      );

      this.completedTasksWatchVarSub = this.manageObservables.setSubscription(
         "completedTasksWatchVar",
         () => {
            this.watchData();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );

      this.tasksWatchVarSub = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.watchData();
         },
      );
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.tasksWatchVarSub,
         this.completedTasksWatchVarSub,
         this.openPOWatchVarSub,
         this.openPRWatchVarSub,
      ]);
   }

   watchData = () => {
      if (this.InitialLoad < 3) {
         this.InitialLoad++;
         return;
      }

      this.findTasks();
   };

   findTasks = () => {
      if (!this.purchaseOrderItem?.itemType) return;
      const tasks = this.manageTask.getTasks();
      const completedTasks = this.manageTask.getCompletedTasks("PoItemTasksHint");

      this.tasksWaiting.clear();
      this.tasksUsed.clear();
      this.otherPurchaseOrdersAndStatus.clear();

      if (this.purchaseOrderItem.itemType === 3) return;

      if (this.purchaseOrderItem.itemType === 1) {
         this.findPartTasks(tasks, completedTasks);
      } else if (
         this.purchaseOrderItem.itemType === 2 ||
         this.purchaseOrderItem.itemType === 4
      ) {
         this.findInvoicesTasks(tasks, completedTasks);
      }
   };

   private findPartTasks(tasks: TaskLookup, completedTasks: TaskLookup): void {
      if (this.purchaseOrderItem === undefined) return;
      const partRelations = this.manageTask.getPartRelations();

      for (const partRelation of partRelations) {
         const checklistID = partRelation.checklistID;
         if (!checklistID) continue;
         const task = tasks.get(checklistID);
         const completedTask = completedTasks.get(checklistID);

         if (partRelation.poItemID === this.purchaseOrderItem.poItemID && completedTask) {
            this.addToTaskAssetNames(completedTask); //to catch a bug where the asset name wasn't showing properly
            this.tasksUsed.add(completedTask);
         }

         if (
            partRelation.partID === this.purchaseOrderItem.partID &&
            task &&
            task.checklistTemplate === 0 &&
            task.checklistCompletedDate === 0
         ) {
            this.addToTaskAssetNames(task);
            this.tasksWaiting.add(task);
         }
      }

      //we should also check to see if there are any other open POs for this specific part already
      const poItems = this.managePO.getPurchaseOrderItems();

      for (const poItem of poItems) {
         const purchaseOrder = poItem.poID
            ? this.managePO.getPurchaseOrder(poItem.poID)
            : undefined;
         if (
            poItem.partID == this.purchaseOrderItem.partID &&
            poItem.poID !== null &&
            poItem.poID != this.purchaseOrderItem.poID &&
            purchaseOrder?.state &&
            purchaseOrder.state <= 99 //only include POs that aren't open
         ) {
            const purchaseOrderState = this.managePO.getPurchaseOrderCurrentState(
               purchaseOrder.poID,
            );
            this.otherPurchaseOrdersAndStatus.add({
               ...purchaseOrder,
               currentState: purchaseOrderState,
            });
         }
      }
      if (this.otherPurchaseOrdersAndStatus.size > 2) {
         this.showOtherPOs = false;
      } else {
         this.showOtherPOs = true;
      }
   }

   private findInvoicesTasks(tasks: TaskLookup, completedTasks: TaskLookup): void {
      if (this.purchaseOrderItem === undefined) return;
      const invoices = this.manageInvoice.getInvoices();

      for (const invoice of invoices) {
         if (
            invoice.poItemID == this.purchaseOrderItem.poItemID &&
            invoice.checklistID > 0
         ) {
            const completedTask = completedTasks.get(invoice.checklistID);
            const task = tasks.get(invoice.checklistID);

            if (completedTask) {
               this.tasksUsed.add(completedTask);
               this.addToTaskAssetNames(completedTask);
            }
            if (task && task.checklistTemplate == 0) {
               this.addToTaskAssetNames(task);
               //only include tasks that are open
               this.tasksWaiting.add(task);
            }
         }
      }
   }

   viewTask = (task): void => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: true,
            },
         },
      };
   };

   public addToTaskAssetNames(task: Task): void {
      if (!task.assetID) return;
      const asset = this.manageAsset.getAsset(task.assetID);
      if (!asset?.assetName) {
         return;
      }
      this.taskAssetNames.set(task.checklistID, asset.assetName);
   }

   viewAsset = (obj) => {
      const asset = this.manageAsset.getAsset(obj.assetID);
      if (!asset) {
         return;
      }
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   popPoComponent = (poID) => {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   };
}
