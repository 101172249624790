import type { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   Component,
   EventEmitter,
   Input,
   Output,
   ViewChild,
   computed,
} from "@angular/core";
import type { Subscription } from "rxjs";
import { filter, map } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { LwmTaskClosed, LwmTaskOpen } from "src/app/shared/types/websocket.types";
import { TaskSimultaneousUsersService } from "src/app/tasks/components/taskSimultaneousUsers/taskSimultaneousUsers.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { SimultaneousUsersComponent } from "src/app/users/components/simultaneousUsers/simultaneousUsers.element.component";

@Component({
   selector: "task-simultaneous-users",
   templateUrl: "./taskSimultaneousUsers.component.html",
   styleUrls: ["./taskSimultaneousUsers.component.scss"],
   imports: [SimultaneousUsersComponent],
})
export class TaskSimultaneousUsersComponent implements OnInit, OnDestroy, AfterViewInit {
   @Input() public checklistID: number | undefined;
   private taskOpenSubscription: Subscription | undefined;
   private taskClosedSubscription: Subscription | undefined;
   private readonly connectionsPerUser: Map<number, Set<string>>;
   public readonly usersViewingTask: Set<number>;
   @ViewChild(SimultaneousUsersComponent) simultaneousUsers:
      | SimultaneousUsersComponent
      | undefined;
   @Output() readonly connectionAdded: EventEmitter<void>;
   @Input() public isPopTask: boolean | undefined;

   private readonly manageTask = inject(ManageTask);
   private readonly tsuService = inject(TaskSimultaneousUsersService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.connectionsPerUser = new Map();
      this.usersViewingTask = new Set();
      this.connectionAdded = new EventEmitter();
   }

   public ngOnInit(): void {
      if (this.checklistID === undefined) {
         throw new Error(
            "Failed to initialize TaskSimultaneousUsers. checklistID is not defined.",
         );
      }
      this.taskOpenSubscription = this.manageTask.taskMessages$
         .pipe(
            filter((msg): msg is LwmTaskOpen => msg.action === "taskOpen"),
            filter((msg) => msg.data.taskID == this.checklistID),
            map((msg) => msg.data),
         )
         .subscribe((msgData) => {
            this.usersViewingTask.add(msgData.userID);
            const currentNumberOfConnections = this.getNumberOfConnectionsForUser(
               msgData.userID,
            );
            const newNumberOfConnections = this.addConnectionID(
               msgData.userID,
               msgData.connectionId,
            );
            const connectionAdded = newNumberOfConnections > currentNumberOfConnections;
            if (connectionAdded === true) {
               this.connectionAdded.emit();
            }
            this.simultaneousUsers?.update();
         });
      this.taskClosedSubscription = this.manageTask.taskMessages$
         .pipe(
            filter((msg): msg is LwmTaskClosed => msg.action === "taskClosed"),
            filter((msg) => msg.data.taskID == this.checklistID),
            map((msg) => msg.data),
         )
         .subscribe((msgData) => {
            this.usersViewingTask.delete(msgData.userID);
            this.removeConnectionID(msgData.userID, msgData.connectionId);
            this.simultaneousUsers?.update();
         });
      if (!this.isPopTask) {
         this.tsuService.increment();
      }
   }

   public ngAfterViewInit(): void {
      this.simultaneousUsers?.update();
   }

   public ngOnDestroy(): void {
      if (this.taskOpenSubscription !== undefined) {
         this.taskOpenSubscription.unsubscribe();
      }
      if (this.taskClosedSubscription !== undefined) {
         this.taskClosedSubscription.unsubscribe();
      }
      if (!this.isPopTask) {
         this.tsuService.decrement();
      }
   }

   /** @returns the number of connections associated with that user. */
   private addConnectionID(userID: number, connectionId: string): number {
      const connections = this.connectionsPerUser.get(userID);
      if (connections === undefined) {
         this.connectionsPerUser.set(userID, new Set([connectionId]));
      }
      if (connections !== undefined && !connections.has(connectionId)) {
         connections.add(connectionId);
      }
      return this.getNumberOfConnectionsForUser(userID);
   }

   /** @returns the number of connections associated with that user */
   private removeConnectionID(userID: number, connectionId: string): number {
      const connections = this.connectionsPerUser.get(userID);
      if (connections !== undefined) {
         connections.delete(connectionId);
         if (connections.size === 0) {
            this.connectionsPerUser.delete(userID);
         }
      }
      return this.getNumberOfConnectionsForUser(userID);
   }

   private getNumberOfConnectionsForUser(userID: number): number {
      return this.connectionsPerUser.get(userID)?.size ?? 0;
   }
}
