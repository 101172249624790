<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (description) {
         <lim-ui-info-panel>
            <p class="message-area">
               <span [limbleHtml]="description">
                  {{ description }}
               </span>
            </p>
         </lim-ui-info-panel>
      }
      <lim-ui-panel>
         <div class="thumbnails-container">
            @for (tutorial of tutorials; track tutorial) {
               @if (tutorial.isVideo) {
                  <video-thumbnail class="thumbnail" [tutorial]="tutorial" />
               }

               @if (tutorial.isDoc) {
                  <doc-thumbnail class="thumbnail" [tutorial]="tutorial" />
               }
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer />
</lim-ui-modal>
