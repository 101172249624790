import type { OnInit } from "@angular/core";
import { inject, ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import {
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import $ from "jquery";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "import-completed-tasks",
   templateUrl: "./importCompletedTasks.modal.component.html",
   //Goes off of the sister components scss file to avoid redundancy
   styleUrls: ["../importOpenTasksModal/importOpenTasks.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      IconComponent,
      TooltipDirective,
      ModalFooterComponent,
      FileUploader,
   ],
})
export class ImportCompletedTasks implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public errorMsg;
   public importAttempt;
   public locationID;
   public uploadObject;
   public addedPseudoTasks;
   public failedPseudoTasks;
   public sample_completed_tasks_Templates_list;
   public axios = axios;
   protected successfulImport: boolean = false;

   private readonly router = inject(Router);
   private readonly manageAsset = inject(ManageAsset);
   private readonly refreshService = inject(RefreshService);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageTask = inject(ManageTask);
   private readonly modalService = inject(ModalService);
   private readonly manageUser = inject(ManageUser);
   private readonly changeDetectorRef = inject(ChangeDetectorRef);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);
   private readonly loadingBarService = inject(LoadingBarService);
   protected readonly i18n = inject(TranslationService).i18n;

   public constructor() {
      this.errorMsg = false;
      this.importAttempt = false;
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      setTimeout(() => {
         if (
            this.manageUser.getCurrentUser() === "none" ||
            this.manageUser.getCurrentUser() === undefined
         ) {
            this.router.navigate(["/login"]);
         }
      }, 500);

      this.message = this.resolve.message;
      this.title = this.resolve.title;

      //if this is passed we are only intending to import a location at a time.
      this.locationID = this.resolve.locationID;

      this.uploadObject = {};
      this.uploadObject.primaryID = "fileName";
      this.uploadObject.uploadTypes = "importFiles"; //images / documents / importFiles (excel + csv) or empty for default (images and documents)
      //bindings have to be all lowercase...
      this.uploadObject.viewOnly = false;
      this.uploadObject.onUploadPopEditor = false; //boolean will images immediately open for editing after upload
      this.uploadObject.posturl = `phpscripts/checklistManager.php?action=importTasks&locationID=${this.locationID}&completed=1`;
      this.uploadObject.deleteData = {};

      this.uploadObject.uploadCall = async (posturl, data) => {
         this.resetErrors();

         this.loadingBarService.show({
            header: this.i18n().t("ThisMayTakeAMoment"),
            msg: this.i18n().t("ImportingTasksMessage"),
            showProgressBar: false,
         });

         return this.axios({
            url: posturl,
            method: "POST",
            data: data,
         });
      };
      this.uploadObject.uploadComplete = async (data) => {
         this.loadingBarService.remove();
         if (data.success == true) {
            this.addedPseudoTasks = data.addedTasks;

            //removes duplicates from failedReasons
            for (const failedTask of data.failedTasks) {
               const arr = failedTask.failedReasons;
               const unique_array: any = [];

               for (const failedReason of arr) {
                  if (!unique_array.includes(failedReason)) {
                     unique_array.push(failedReason);
                  }
               }
               failedTask.failedReasons = unique_array;
            }

            this.failedPseudoTasks = data.failedTasks;

            if (this.failedPseudoTasks.length > 0) {
               //if they had failed Completed Taskswe need to display an error msg.
               this.errorMsg = this.i18n().t(
                  "WeFoundErrorsWithTheListOfCompletedTasksYouTriedToUpload",
               );
            } else {
               await this.refreshService.refreshData(); //reload the entire app's data set.
               //successful import occured so display the list of Completed Tasks
               this.successfulImport = true;
            }
            this.importAttempt = true;
         } else {
            if (data.error === "missing required column") {
               this.errorMsg = `${this.i18n().t("WhoopsYouAreMissingTheFollowingRequiredColumn")} ${data.column}`;
            } else if (data.error === "cred") {
               this.errorMsg = this.i18n().t(
                  "PleaseMakeSureYouHaveTheCredentialsToImportCompletedTasks",
               );
            } else if (data.error === "too many lines") {
               this.errorMsg = this.i18n().t("YouCanImportCompletedTasksMultipleTimes");
            } else if (data.error === "incorrect header") {
               this.errorMsg = `${this.i18n().t("PleaseKeepTheHeadersTheSameAsTheSampleCSVFile")} <b>${data.invalidHeaderName}</b> ${this.i18n().t("andShouldHaveBeen")} ${data.correctHeader}.`;
            } else if (data.error === "no Completed Tasks in CSV") {
               this.errorMsg = this.i18n().t("ThereAreNoRowsInTheCSVFileYouUploaded");
            } else if (data.error === "catchall error") {
               this.errorMsg = this.i18n().t("errorMsg");
            } else if (data.error === "debug") {
               console.info(data);
            }
         }
         this.changeDetectorRef.detectChanges();
      };

      //this timeout makes sure that the modal starts at the top of the screen
      setTimeout(() => {
         $(".modal").scrollTop(0);
      }, 1);
   }

   downloadCSV = () => {
      this.manageUtil.objToExcel(
         this.sample_completed_tasks_Templates_list,
         "Completed Tasks",
         "sample-completed-tasks-import.xlsx",
      );
   };

   protected deleteTask(checklistID: number): void {
      const promise = this.manageTask.deleteCompletedTask(checklistID);
      promise.then(
         () => {
            for (const key in this.addedPseudoTasks) {
               if (this.addedPseudoTasks[key].checklistID == checklistID) {
                  this.addedPseudoTasks.splice(key, 1);
               }
            }

            this.alertService.addAlert(
               this.i18n().t("NewlyAddedCompletedTaskDeleted"),
               "success",
               3000,
            );
         },
         () => {
            this.alertService.addAlert(this.i18n().t("errorMsg"), "danger", 6000);
         },
      );
   }

   protected popAsset(assetID: number): void {
      const asset = this.manageAsset.getAsset(assetID);

      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset?.assetID,
            locationID: asset?.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   protected popTask(checklistID: number): void {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: false,
               template: false,
               title: this.i18n().t("ViewNewlyImportedCompletedTask"),
               message: "",
            },
         },
      };
   }

   close = () => {
      this.modalInstance.close(0);
   };

   protected resetErrors(): void {
      this.errorMsg = false;
      this.importAttempt = false;
      this.successfulImport = false;
      this.failedPseudoTasks = null;
      this.changeDetectorRef.detectChanges();
   }
}
