import { Component, inject } from "@angular/core";
import { CardComponent, ThumbsUpRockStarsComponent } from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";

@Component({
   selector: "way-to-fix-that-card",
   templateUrl: "./way-to-fix-that-card.component.html",
   styleUrls: ["./way-to-fix-that-card.component.scss"],
   standalone: true,
   imports: [CardComponent, ThumbsUpRockStarsComponent],
})
export class WayToFixThatCardComponent {
   protected readonly lang = inject(TranslationService).i18n;
}
