import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
   resource,
   signal,
   type Resource,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AlertComponent } from "@limblecmms/lim-ui";
import type { PmSuggestionsFilters } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource-types";
import { PmSuggestionsResourceService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource.service";

@Component({
   selector: "suggestions-notification",
   templateUrl: "./suggestions-notification.component.html",
   styleUrls: ["./suggestions-notification.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [AlertComponent],
})
export class SuggestionsNotificationComponent {
   public readonly limits = input<PmSuggestionsFilters>({});
   public readonly test = signal<number>(0);
   private readonly pmSuggestionsResourceService = inject(PmSuggestionsResourceService);

   public readonly count: Resource<number> = resource({
      request: () => ({ filters: this.limits(), test: this.test() }),
      loader: async ({ request }) => {
         const result = await this.pmSuggestionsResourceService.get(request);
         return result.length;
      },
   }).asReadonly();

   public readonly dismissed = signal<boolean>(false);

   public readonly isVisible = computed(
      () => (this.count.value() ?? 0) > 0 && this.dismissed() === false,
   );

   public constructor() {
      this.pmSuggestionsResourceService.mutations
         .pipe(takeUntilDestroyed())
         .subscribe(() => {
            this.count.reload();
         });
   }
}
