import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   ModalService,
   LimbleHtmlDirective,
   TableButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ChangePartCategory } from "src/app/parts/components/changePartCategoryModal/changePartCategory.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Category } from "src/app/parts/types/category/category.types";
import type { Part } from "src/app/parts/types/part.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "part-category-field",
   templateUrl: "./partCategory.element.component.html",
   styleUrls: ["../shared-field-styles.scss"],
   imports: [NgClass, TooltipDirective, TableButtonComponent, LimbleHtmlDirective],
})
export class PartCategory implements OnInit {
   @Input() public partID: number | undefined;
   @Input() public usedIn: "table" | "listItem" | undefined = undefined;

   public part: Part | undefined;
   public credChangePartCategory;

   private readonly modalService = inject(ModalService);
   private readonly credService = inject(CredService);
   public readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      assert(this.partID);
      this.part = this.manageParts.getPart(this.partID);
      assert(this.part);

      this.credChangePartCategory = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ChangePartCategory,
      );
   }

   public async changeCategory() {
      assert(this.part);
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartCategory,
         )
      ) {
         return;
      }

      const currentlySelectedCategory: Category | undefined =
         this.manageParts.getCategory(this.part.categoryID);

      const instance = this.modalService.open(ChangePartCategory);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               message: this.lang().ChangePartCategoryMsg,
               title: this.lang().ChangePartCategory,
               part: this.part,
               selectedCategory: currentlySelectedCategory,
            },
         },
      };

      const result = await instance.result;
      if (result === currentlySelectedCategory || result === 0) {
         return;
      }
      const answer = await this.manageParts.updateCatID(this.part, result.categoryID);

      if (answer?.data.success == true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }
}
