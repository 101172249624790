import { Component, computed, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { PostLogsApiService } from "src/app/settings/services/post-logs-api/post-logs-api.service";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";

@Component({
   selector: "post-log-action-filter",
   imports: [
      DropdownButtonComponent,
      DropdownClearFilterItemComponent,
      DropdownDividerComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      SearchBoxComponent,
      TranslateDirective,
   ],
   templateUrl: "./post-log-action-filter.component.html",
   styleUrls: ["./post-log-action-filter.component.scss"],
})
export class PostLogActionFilterComponent extends BaseFilterComponent {
   private readonly logsApi = inject(PostLogsApiService);

   public postLogActions = toSignal(this.logsApi.getActions());

   public postLogActionsDropdown = computed(() => {
      let actions = this.postLogActions() ?? [];
      if (this.searchPostLogActions().length > 0) {
         actions = actions.filter((action) =>
            action.toLowerCase().includes(this.searchPostLogActions().toLowerCase()),
         );
      }
      return actions.sort((first, second) => first.localeCompare(second));
   });

   public searchPostLogActions = signal<string>("");

   protected postLogAction?: string | undefined;

   /**
    * This is a label that is shown whenever the filter is active to indicate what was selected
    */
   protected activeFilterLabel?: string | undefined;

   public constructor() {
      super();
   }

   public handleSetAction(action) {
      if (!action) return;
      this.postLogAction = action;

      this.activeFilterLabel = `${this.translateService.instant("Action")} - ${action}`;
      const filter = { [this.filter().key]: action };
      this.setFilter(filter, this.activeFilterLabel);
   }

   public handleSearch(searchText: string) {
      this.searchPostLogActions.set(searchText);
   }

   public handleClear() {
      this.postLogAction = undefined;
      this.activeFilterLabel = undefined;
      this.remove.emit(this.filter());
   }

   private setFilter(value, activeLabel: string): void {
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value,
      });
   }
}
