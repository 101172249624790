import type { Signal } from "@angular/core";
import type { LanguageDictionary } from "src/app/languages/types/language.types";
import {
   type Column,
   ColumnKeys,
} from "src/app/shared/data-viewer/column-builder/column-builder.models";

export class ColumnBuilder {
   private columns: Array<Column> = new Array<Column>();

   public constructor(private readonly lang: Signal<LanguageDictionary>) {}

   public build(): Column[] {
      const columns = Object.assign(this.columns);
      this.columns = new Array<Column>();
      return columns;
   }

   public addTaskName(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TASK_NAME,
         displayName: this.lang().Name,
         sortBy: "checklistName",
         columnWidth: 4,
         extraColumns: ["assets", "locationName", "partsDetails", "commentsDetails"],
         params: { includeDeletedAssets: true },
         exportableFields: ["checklistName"],
         minWidth: 150,
         ...columnOverrides,
      };

      this.columns.push(column);

      return this;
   }

   public addTaskMinimalName(columnOverrides?: Partial<Column>): this {
      return this.addTaskName({
         key: ColumnKeys.TASK_MINIMAL_NAME,
         displayName: this.lang().TaskName,
         columnWidth: 4,
         ...columnOverrides,
      });
   }

   public addTaskNameWithoutAsset(columnOverrides?: Partial<Column>): this {
      return this.addTaskName({
         key: ColumnKeys.TASK_NAME_WITHOUT_ASSET,
         displayName: this.lang().Name,
         sortBy: "checklistName",
         columnWidth: 4,
         ...columnOverrides,
      });
   }

   public addTaskScheduleCombinedName(columnOverrides?: Partial<Column>): this {
      return this.addTaskName({
         key: ColumnKeys.TASK_SCHEDULE_NAME,
         ...columnOverrides,
      });
   }

   public addLocation(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.LOCATION,
         displayName: this.lang().Location,
         sortBy: "locationName",
         columnWidth: 2,
         exportableFields: ["locationName"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskDueDate(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.DUE_DATE,
         displayName: this.lang().Due,
         sortBy: "checklistDueDate",
         columnWidth: 2,
         exportableFields: ["checklistDueDate"],
         ...columnOverrides,
      };

      this.columns.push(column);
      return this;
   }

   public addTaskCompletedOn(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.COMPLETED_ON,
         displayName: this.lang().Completed,
         sortBy: "checklistCompletedDate",
         columnWidth: 2,
         extraColumns: ["completionColor"],
         exportableFields: ["checklistCompletedDate"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskCompletedBy(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.COMPLETED_BY,
         displayName: this.lang().CompletedBy,
         sortBy: "completedBy",
         columnWidth: 2,
         exportable: true,
         extraColumns: ["completedBy"],

         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskTimeSpent(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TIME_SPENT,
         displayName: this.lang().CompletionTime,
         sortBy: "timeSpentSecs",
         columnWidth: 2,
         extraColumns: ["timeSpentSecs"],
         exportableFields: ["checklistPromptTime"],

         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addAssignedTo(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.ASSIGNED_TO,
         displayName: this.lang().AssignedTo,
         sortBy: "assignment",
         columnWidth: 2,
         exportableFields: ["assignedTo"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskType(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TASK_TYPE,
         displayName: this.lang().Type,
         sortBy: "taskType",
         columnWidth: 2,
         exportable: true,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTotalPartsCost(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TOTAL_PARTS_COST,
         displayName: this.lang().Parts,
         sortBy: "partsCost",
         columnWidth: 2,
         extraColumns: ["partsCost"],
         exportableFields: ["checklistTotalPartsCost"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addInvoiceCost(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.INVOICE_COST,
         displayName: this.lang().Invoices,
         sortBy: "invoiceCost",
         columnWidth: 2,
         extraColumns: ["invoiceCost"],
         exportableFields: ["checklistTotalInvoiceCost"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addLaborCost(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.LABOR_COST,
         displayName: this.lang().Labor,
         sortBy: "laborCost",
         columnWidth: 2,
         extraColumns: ["timeSpentSecs", "laborCost"],
         exportableFields: ["laborCost"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTimeTotalWithLaborCost(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TIME_TOTAL_WITH_LABOR_COST,
         displayName: this.lang().Labor,
         sortBy: "timeSpentSecs",
         columnWidth: 2,
         extraColumns: ["timeSpentSecs", "laborCost"],
         exportableFields: ["checklistPromptTimeTotalWithLaborCost"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addOperatingCost(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.OPERATING_COST,
         displayName: this.lang().Total,
         sortBy: "operatingCost",
         columnWidth: 2,
         extraColumns: ["operatingCost"],
         exportableFields: ["checklistTotalOperatingCost"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addDowntime(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.DOWNTIME,
         displayName: this.lang().Downtime,
         sortBy: "checklistDowntime",
         columnWidth: 3,
         exportableFields: ["checklistDowntime"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addStatusColor(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TASK_STATUS_COLOR,
         displayName: this.lang().Status,
         sortBy: "finalColorStatus",
         columnWidth: 2,
         exportableFields: [], //none
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTimeInStatus(
      status: "timeSpentInStatusOpen" | "timeSpentInStatusInProgress" | "none",
      columnOverrides?: Partial<Column>,
   ): this {
      let columnDisplayName: string;

      if (status === "timeSpentInStatusOpen") {
         columnDisplayName = this.lang().TimeInOpen;
      } else if (status === "timeSpentInStatusInProgress") {
         columnDisplayName = this.lang().TimeInProgress;
      } else {
         columnDisplayName = this.lang().TimeSpent;
      }
      const column = {
         key: status,
         displayName: columnDisplayName,
         sortBy: status,
         columnWidth: 2,
         exportable: true,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskPartQuantity(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TASK_PART_QUANTITY,
         displayName: this.lang().Qty,
         sortBy: "qtyUsed",
         columnWidth: 2,
         exportable: true,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskID(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TASK_ID,
         displayName: this.lang().ID,
         sortBy: "checklistID",
         columnWidth: 1,
         exportableFields: ["checklistID"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addAssetName(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.ASSET_NAME,
         displayName: this.lang().Asset,
         sortBy: "assetName",
         columnWidth: 2,
         exportableFields: ["assetName"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskLatestActivityTime(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.LATEST_ACTIVITY_TIME,
         displayName: this.lang().LatestActivity,
         sortBy: "checklistLastEdited",
         columnWidth: 1,
         exportableFields: ["checklistLastEdited"],
         ...columnOverrides,
      };

      this.columns.push(column);
      return this;
   }

   public addTaskEstimatedTime(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TASK_ESTIMATED_TIME,
         displayName: this.lang().EstimatedTime,
         sortBy: "estimatedTime",
         columnWidth: 2,
         exportableFields: ["checklistEstTime"],
         ...columnOverrides,
      };

      this.columns.push(column);
      return this;
   }

   public addCompletionNotes(columnOverrides?: Partial<Column>): this {
      const column = {
         displayName: this.lang().CompletionNotes,
         sortBy: "completionNotes",
         key: ColumnKeys.COMPLETION_NOTES,
         columnWidth: 2,
         ...columnOverrides,
      };

      this.columns.push(column);

      return this;
   }

   public addDowntimeEfficiencyRate(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.DOWNTIME_EFFICIENCY_RATE,
         displayName: this.lang().EfficiencyRate,
         sortBy: "downtimeEfficiencyRate",
         columnWidth: 2,
         exportableFields: ["downtimeEfficiencyRate"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addLaborCostEfficiencyRate(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.LABOR_COST_EFFICIENCY_RATE,
         displayName: this.lang().EfficiencyRate,
         sortBy: "laborCostEfficiencyRate",
         columnWidth: 2,
         exportableFields: ["laborCostEfficiencyRate"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addPartCostEfficiencyRate(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PART_COST_EFFICIENCY_RATE,
         displayName: this.lang().EfficiencyRate,
         sortBy: "partCostEfficiencyRate",
         columnWidth: 2,
         exportableFields: ["partCostEfficiencyRate"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addDowntimeAverageCost(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.AVERAGE_DOWNTIME_COST,
         displayName: this.lang().AverageDowntimeCost,
         sortBy: "averageDowntimeCost",
         columnWidth: 2,
         exportableFields: ["averageDowntimeCost"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addDowntimeEfficiencySavings(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.DOWNTIME_EFFICIENCY_SAVINGS,
         displayName: this.lang().EfficiencySavings,
         sortBy: "checklistDowntime",
         columnWidth: 2,
         exportableFields: ["downtimeEfficiencySavings"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addLaborCostEfficiencySavings(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.LABOR_COST_EFFICIENCY_SAVINGS,
         displayName: this.lang().EfficiencySavings,
         sortBy: "laborCost",
         columnWidth: 2,
         exportableFields: ["laborCostEfficiencySavings"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addPartCostEfficiencySavings(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PART_COST_EFFICIENCY_SAVINGS,
         displayName: this.lang().EfficiencySavings,
         sortBy: "partsCost",
         columnWidth: 2,
         exportableFields: ["partCostEfficiencySavings"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskPartName(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TASK_PART_NAME,
         displayName: this.lang().Name,
         sortBy: "checklistName",
         columnWidth: 4,
         exportableFields: ["taskPartName"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addPartLogDate(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PART_LOG_DATE,
         displayName: this.lang().Date,
         sortBy: "logDate",
         columnWidth: 3,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addPartLogEntry(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PART_LOG_ENTRY,
         displayName: this.lang().Entry,
         sortBy: "logDetails",
         columnWidth: 6,
         extraColumns: ["task"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addPartLogUser(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PART_LOG_USER,
         displayName: this.lang().User,
         sortBy: "userID",
         columnWidth: 3,
         extraColumns: ["user"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addAssetPartUsedName(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PART_NAME,
         displayName: this.lang().PartName,
         sortBy: "partName",
         columnWidth: 2,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addAssetPartUsedTaskCompletedOn(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.COMPLETED_ON,
         displayName: this.lang().Date,
         sortBy: "checklistCompletedDate",
         columnWidth: 2,
         exportable: true,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addAssetPartUsedCount(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PARTS_USED,
         displayName: this.lang().TotalUsed,
         sortBy: "usedNumber",
         columnWidth: 4,
         extraColumns: ["asset"],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addAssetPartUsedNumber(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PART_NUMBER,
         displayName: this.lang().PartNumber,
         sortBy: "partNumber",
         columnWidth: 2,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addAssetTotalCostOfPartsUsed(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.TOTAL_COST_OF_PARTS_USED,
         displayName: this.lang().TotalCost,
         sortBy: "totalPriceOfParts",
         columnWidth: 2,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addLoggedTime(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.PROMPT_TIME,
         displayName: this.lang().TimeSpent,
         sortBy: "promptTime",
         columnWidth: 2,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addLoggedTimeNotes(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.NOTES,
         displayName: this.lang().Note,
         sortBy: "notes",
         columnWidth: 2,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addLoggedAt(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.LOGGED_AT,
         displayName: this.lang().Date,
         sortBy: "loggedAt",
         columnWidth: 2,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addLoggedByUser(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.USER_DISPLAY_NAME,
         displayName: this.lang().User,
         sortBy: "",
         columnWidth: 2,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addLoggedTimeTaskName(columnOverrides?: Partial<Column>): this {
      const column = {
         key: ColumnKeys.LOGGED_TIME_TASK_NAME,
         displayName: this.lang().Task,
         sortBy: "checklistName",
         extraColumns: [],
         columnWidth: 2,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }
}
