export function createKeyToDefaultMap<T extends ReadonlyArray<FlagDescription | string>>(
   flags: T,
): TupleToRecord<T> {
   return Object.fromEntries(
      flags.map((flag) => (typeof flag === "string" ? [flag, null] : flag)),
   ) as TupleToRecord<T>;
}

type FlagDescription = [string, unknown];

type TupleToRecord<T extends ReadonlyArray<FlagDescription | string>> = {
   [K in TransformArray<T>[number] as K extends readonly [
      infer Key extends string,
      unknown,
   ]
      ? Key
      : never]: K extends readonly [unknown, infer Value] ? Value : never;
};

type TransformArray<T extends ReadonlyArray<FlagDescription | string>> = {
   [K in keyof T]: TransformToTuple<T[K]>;
};

type TransformToTuple<T> = T extends string ? [T, null] : T;
