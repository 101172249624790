import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DangerButtonComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "vendor-settings",
   templateUrl: "./vendorSettings.modal.component.html",
   styleUrls: ["./vendorSettings.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      FormsModule,
      DropdownComponent,
      PrimaryButtonComponent,
      SearchBoxComponent,
      DropdownDividerComponent,
      DropdownTextItemComponent,
      NgClass,
      IconComponent,
      TooltipDirective,
      DatePickerInputComponent,
      AlertComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      DangerButtonComponent,
   ],
})
export class VendorSettings implements OnInit {
   public title;
   public vendor: Vendor | undefined;
   public errorMsg;
   public locationsIndex;
   public currentType;
   public dateTime;
   public dateOptions;
   public formats;
   public format;
   public totalLocLength;
   public searchBar;
   public locations;
   public opened;
   public resolve;
   public modalInstance;

   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly refreshService = inject(RefreshService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.title = this.resolve.title;
      this.vendor = this.resolve.vendor;
      assert(this.vendor);
      this.errorMsg = "";

      this.buildData();

      this.locationsIndex = this.manageLocation.getLocationsIndex();

      this.currentType = this.lang().Vendor;

      assert(this.vendor.vendorCreated);
      this.dateTime = new Date(this.vendor.vendorCreated * 1000);

      this.dateOptions = {
         formatYear: "yy",
         startingDay: 1,
      };

      this.formats = ["dd-MMMM-yyyy", "yyyy/MM/dd", "dd.MM.yyyy", "shortDate"];
      this.format = this.formats[0];
   }

   runSearch = () => {
      this.buildData();
   };

   buildData = () => {
      let locations = this.manageLocation.getLocations();
      this.totalLocLength = locations.length;

      if (this.searchBar && this.searchBar.length > 0) {
         locations = this.manageFilters.filterLocationsToSearch(
            locations,
            this.searchBar,
         );
      }

      locations = orderBy(locations, "locationNameWithRegions");

      this.locations = locations;
   };

   changeLocation = (location) => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.EditVendorSettings,
         )
      ) {
         this.alertService.addAlert(this.lang().cred135Fail, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().MoveVendorsLocationMsg} <b>${this.vendor.vendorName}</b>?  `,
            title: this.lang().MoveVendorsLocation,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            assert(this.vendor);
            this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
            this.manageVendor
               .changeVendorLocation(this.vendor, location.locationID)
               .then((answer) => {
                  if (answer.data.success) {
                     this.refreshService.refreshData().then(() => {
                        this.loadingBarService.remove();

                        this.modalInstance.close(0);

                        this.alertService.addAlert(
                           `${this.lang().MoveVendorsLocationSuccessMsg} <b>${location.locationName}</b>!`,
                           "success",
                           10000,
                        );
                     });
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   deleteVendor = () => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.DeleteVendors,
         )
      ) {
         this.alertService.addAlert(this.lang().cred127Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteVendorMsg,
            title: this.lang().DeleteVendor,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            const confirmModal = this.modalService.open(Confirm);

            this.paramsService.params = {
               modalInstance: confirmModal,
               resolve: {
                  message: this.lang().ReallyDeleteThisVendorMsg,
                  title: this.lang().ReallyDeleteThisVendor,
               },
            };

            confirmModal.result.then(() => {
               assert(this.vendor);
               this.manageVendor.deleteVendor(this.vendor).then((answer) => {
                  if (answer.data.success == true) {
                     this.loadingBarService.show({
                        header: this.lang().ThisMayTakeAMoment,
                     });

                     this.modalInstance.close(0);

                     setTimeout(() => {
                        assert(this.vendor);
                        if ($(`#popVendor${this.vendor.vendorID}`)[0]) {
                           $(`#popVendor${this.vendor.vendorID}`)[0].click();
                        }
                     }, 350);

                     this.refreshService.refreshData().then(() => {
                        this.loadingBarService.remove();

                        this.alertService.addAlert(
                           this.lang().VendorDeleted,
                           "success",
                           3000,
                        );
                     });
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
            });
         }
      });
   };

   open = ($event) => {
      $event.preventDefault();
      $event.stopPropagation();

      this.opened = true;
   };

   setVendorCreatedDate = () => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.EditVendorSettings,
         )
      ) {
         this.alertService.addAlert(this.lang().cred135Fail, "danger", 10000);
         return;
      }

      const date = new Date(this.dateTime);
      const newTime = date.getTime() / 1000;
      const oldTime = `${this.vendor.vendorCreated}`;

      if (Number(oldTime) != newTime && newTime != 0) {
         this.manageVendor
            .updateVendorCreatedDate(newTime, this.vendor.vendorID)
            .then((answer) => {
               assert(this.vendor);
               if (answer.data.success == true) {
                  this.vendor.vendorCreated = Number(newTime);

                  this.manageTask.incCompletedTasksWatchVar();
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               }
            });
      }
   };

   close = () => {
      this.modalInstance.close(0);
   };
}
