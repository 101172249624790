@if (
   item()?.itemTypeID &&
   item().itemTypeID !== TaskInstructionType.UpdatePartQty &&
   taskMode() === "template" &&
   info() !== undefined
) {
   <lim-ui-nested-panel class="chk-item-panel edit-instructions-panel allowOffline">
      <div class="chk-item-instruction">
         <div class="chk-item-edit-instruction-header">
            <span
               class="limble-tree-handle-container lim-ui-hide-on-mobile"
               [ngClass]="{
                  'greater-than-two-digit-item-order':
                     item().itemOrder.toString().length >= 2,
                  'greater-than-three-digit-item-order':
                     item().itemOrder.toString().length >= 3,
               }"
            >
               @if (treeBranch) {
                  <lim-ui-icon
                     icon="arrowsUpDownLeftRightRegular"
                     iconSize="small"
                     class="move-item-icon"
                     [limbleTreeDraggable]="treeBranch"
                  />
               }
               <span class="item-order-number">{{ item().itemOrder }}</span>
            </span>

            <div
               class="change-type-icon-container"
               [ngClass]="{
                  'disabled':
                     featureESignatureVerification &&
                     (item().itemTypeID === 16 || item().itemTypeID === 15) &&
                     item().itemResponse,
                  'read-only': instructionSetInstance,
               }"
               (click)="changeItemType(item())"
               limUiTooltip="{{ lang()['itemTypeDescription' + item().itemTypeID] }}. {{
                  lang().ClickToChangeInstructionType
               }}"
               placement="right"
            >
               <lim-ui-icon [icon]="item().itemIcon | iconAlias" iconSize="small" />
               <lim-ui-icon icon="angleDownRegular" iconSize="small" />
            </div>
         </div>

         <div
            class="instruction-content"
            [ngClass]="{ 'greyout-chk-item': item().greyOut }"
         >
            @switch (item().itemTypeID) {
               @case (TaskInstructionType.CheckBox) {
                  <ng-container
                     *ngTemplateOutlet="checkboxItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.OptionList) {
                  <ng-container
                     *ngTemplateOutlet="radioOptionListItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.TextBox) {
                  <ng-container
                     *ngTemplateOutlet="textboxItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.DropdownList) {
                  <ng-container
                     *ngTemplateOutlet="dropdownItem1; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.DatePicker) {
                  <ng-container
                     *ngTemplateOutlet="datePickerItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.Note) {
                  <ng-container
                     *ngTemplateOutlet="labelItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.Reassign) {
                  <ng-container
                     *ngTemplateOutlet="reassignItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.FileOrPictureAttachment) {
                  <ng-container
                     *ngTemplateOutlet="fileAttachmentItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.AssignPM) {
                  <ng-container
                     *ngTemplateOutlet="assignTaskItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.DeadlineDatePicker) {
                  <ng-container
                     *ngTemplateOutlet="deadlineDatePickerItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.AssignAuditPM) {
                  <ng-container
                     *ngTemplateOutlet="assignAuditItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.Number) {
                  <ng-container
                     *ngTemplateOutlet="numberItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.StartWO) {
                  <ng-container
                     *ngTemplateOutlet="startWOItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.CaptureSignature) {
                  <ng-container
                     *ngTemplateOutlet="signatureItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.RequestApproval) {
                  <ng-container
                     *ngTemplateOutlet="requestApprovalItem; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.InstructionSet) {
                  <ng-container
                     *ngTemplateOutlet="instructionSet; context: { item: item() }"
                  />
               }

               @case (TaskInstructionType.VerifyLocation) {
                  <ng-container
                     *ngTemplateOutlet="verifyLocation; context: { item: item() }"
                  />
               }
            }
         </div>
      </div>

      <ng-template #checkboxItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
                  data-nodrag
               />
            }
         </div>
      </ng-template>

      <ng-template #radioOptionListItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div>
                  <div
                     class="editUnderline"
                     [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                     [(ngModel)]="item.itemText"
                     (afterEdit)="updateItemText()"
                     limbleContentEditable
                     (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
                  ></div>
               </div>
            }
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="optionParent"
               />
            }
         </div>
      </ng-template>

      <ng-template #textboxItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <span>
            <div
               type="text"
               class="div-text-box"
               [limbleContentEditable]="false"
               placement="top"
               limUiTooltip="{{ lang().InstructionBehaveTooltip }}"
               disableEditor="true"
            >
               {{ lang().EnterTextHere }}
            </div>
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #dropdownItem1 let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <span placement="top" limUiTooltip="{{ lang().InstructionBehaveTooltip }}">
            <lim-ui-form-dropdown-input
               [inputString]="' '"
               storeOffline
               [disabled]="true"
            />
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="optionParent"
               />
            }
         </div>
      </ng-template>

      <ng-template #datePickerItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <span>
            <div
               type="text"
               class="div-text-box"
               [limbleContentEditable]="false"
               placement="top"
               limUiTooltip="{{ lang().InstructionBehaveTooltip }}"
               disableEditor="true"
            >
               {{ currentDate | betterDate: "date" }}
            </div>
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #labelItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  limbleContentEditable
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #reassignItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <div>
            <h5>{{ lang().WhenTriggeredReassignTo }}</h5>
            <a class="cursor" (click)="pickWODefaultOwner()">
               @if (item.itemWOAssignToLocked * 1) {
                  <span>
                     <lim-ui-icon icon="lock" iconSize="small" />
                  </span>
               }
               <span>{{ reassignUserTarget }}</span>
            </a>
         </div>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #fileAttachmentItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #assignTaskItem let-item="item">
         <div
            class="header-subtext"
            [ngClass]="{ paywalled: !featureStartWOStartPM }"
            [limUiPopover]="startWOStartPMPopoverDisabledArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureStartWOStartPM"
         >
            <span>{{ lang().PMtoAssign }}</span>
            <div>
               <a
                  role="button"
                  (click)="assignTaskConfig()"
                  [limbleHtml]="item.itemText"
                  [hidden]="!(item.itemText != lang().Instructions)"
               >
                  {{ item.itemText }}
               </a>
               <a
                  role="button"
                  (click)="assignTaskConfig()"
                  [hidden]="!(item.itemText == lang().Instructions)"
                  limUiTooltip="{{ lang().ChooseAPMTooltip }}"
                  data-placement="top"
               >
                  {{ lang().PickPM }}
               </a>

               @if (asset(); as asset) {
                  <span>
                     -
                     <a role="button" class="green" (click)="popAsset(asset.assetID)">{{
                        asset.assetName
                     }}</a>

                     <asset-parent-list
                        [assetID]="asset.assetID"
                        linkColorClass="green-color"
                     />
                  </span>
               }
            </div>
         </div>

         <div
            [ngClass]="{ paywalled: !featureStartWOStartPM }"
            [limUiPopover]="startWOStartPMPopoverDisabledArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureStartWOStartPM"
         >
            <h5>{{ lang().WhenStartedBelongsToAsset }}</h5>
            <lim-ui-form-dropdown-input
               [inputString]="item.taskBelongsToOptionPicked"
               [disabled]="!featureStartWOStartPM"
            >
               <ng-container menu>
                  @for (option of item.taskBelongsToOptions; track option) {
                     <lim-ui-dropdown-item
                        [ngClass]="{ dropDownSelectListBorder: !$last }"
                        (click)="pickBelongsToAsset(option.value)"
                     >
                        <div class="assign-pm-dropdown-item">
                           <div [limbleHtml]="option.label"></div>
                           <lim-ui-icon
                              icon="circleQuestion"
                              iconSize="small"
                              [hidden]="!option.hint"
                              title="{{ option.hint }}"
                           />
                        </div>
                     </lim-ui-dropdown-item>
                  }
               </ng-container>
            </lim-ui-form-dropdown-input>

            <a class="green-color" (click)="pickBelongsToAsset(1)">
               <span [limbleHtml]="taskBelongsToPrompt"></span>
            </a>
         </div>
         <ng-template #startWOStartPMPopoverDisabledArea>
            <upsell-popover
               [message]="lang().FeatureStartWOStartPMPopoverInstructionMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>

         <div class="chk-options-container pricing-plan-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [onlyDelete]="!featureStartWOStartPM"
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
            @if (!featureStartWOStartPM) {
               <lim-ui-badge
                  class="pricing-plan-badge"
                  icon="stars"
                  [label]="lang().PremiumPlus"
                  [limUiPopover]="startPMToAssignBadge"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'bottom'"
                  [limUiPopoverHidden]="featureStartWOStartPM"
               />
            }
            <ng-template #startPMToAssignBadge>
               <upsell-popover
                  [message]="lang().FeatureStartWOStartPMPopoverInstructionMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>
         </div>
      </ng-template>

      <ng-template #deadlineDatePickerItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <p>
            <lim-ui-date-picker-input
               [model]="dummyDate"
               placement="top"
               limUiTooltip="{{ lang().InstructionBehaveTooltip }}"
            />
         </p>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #assignAuditItem let-item="item">
         <span>
            {{ lang().AuditPMToAssign }}:
            <a
               class="headerLink cursor"
               (click)="assignTaskConfig()"
               [limbleHtml]="item.itemText"
               [hidden]="!(item.itemText != lang().Instructions)"
            >
               {{ item.itemText }}
            </a>
            <a
               class="headerLink cursor"
               (click)="assignTaskConfig()"
               [hidden]="!(item.itemText == lang().Instructions)"
            >
               {{ lang().ChooseAPM }}
            </a>
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #numberItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <span>
            <div
               type="text"
               class="div-text-box"
               [limbleContentEditable]="false"
               placement="top"
               limUiTooltip="{{ lang().InstructionBehaveTooltip }}"
               disableEditor="true"
            >
               {{ lang().EnterNumberHere }}
            </div>
         </span>

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #startWOItem let-item="item">
         <span
            [limUiPopover]="startWOItemDisabledArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureStartWOStartPM"
         >
            <span (mouseover)="item.hovered = true">
               @if (!item.hovered) {
                  <div
                     class="editUnderline"
                     [ngClass]="{ paywalled: !featureStartWOStartPM }"
                     [limbleHtml]="item.itemText"
                  ></div>
               }
               @if (item.hovered) {
                  <div
                     [ngClass]="{ paywalled: !featureStartWOStartPM }"
                     class="editUnderline"
                     [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                     [(ngModel)]="item.itemText"
                     (afterEdit)="featureStartWOStartPM ? updateItemText() : null"
                     limbleContentEditable
                     (click)="
                        featureStartWOStartPM
                           ? checkFirstInstructionTextSelectAll(item().itemText, item)
                           : null
                     "
                  ></div>
               }
            </span>
         </span>

         <div
            [ngClass]="{ paywalled: !featureStartWOStartPM }"
            [limUiPopover]="startWOItemDisabledArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureStartWOStartPM"
         >
            <h5>{{ lang().UseWoTemplate }}</h5>
            <a
               class="cursor headerLink"
               (click)="featureStartWOStartPM ? pickWODefault() : null"
            >
               @if (!woTemplate) {
                  <span>{{ lang().No }}</span>
               }
               @if (item.itemWOTemplateLocked * 1) {
                  <span>
                     <lim-ui-icon icon="lock" iconSize="small" />
                  </span>
               }
               @if (woTemplate) {
                  <span>{{ woTemplate }}</span>
               }
            </a>
         </div>
         <div
            [ngClass]="{ paywalled: !featureStartWOStartPM }"
            [limUiPopover]="startWOItemDisabledArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureStartWOStartPM"
         >
            <h5>{{ lang().AssignWoTo }}</h5>
            <a
               class="cursor headerLink"
               (click)="featureStartWOStartPM ? pickWODefaultOwner() : null"
            >
               @if (item.itemWOAssignToLocked * 1) {
                  <span>
                     <lim-ui-icon icon="lock" iconSize="small" />
                  </span>
               }
               @if (woUser) {
                  <span>{{ woUser }}</span>
               }
            </a>
         </div>
         <div
            [ngClass]="{ paywalled: !featureStartWOStartPM }"
            [limUiPopover]="startWOItemDisabledArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureStartWOStartPM"
         >
            <h5>{{ lang().WhenStartedBelongsToAsset }}</h5>
            <lim-ui-form-dropdown-input
               [inputString]="item.taskBelongsToOptionPicked"
               [disabled]="!featureStartWOStartPM"
            >
               <ng-container menu>
                  @for (option of item.taskBelongsToOptions; track option) {
                     <lim-ui-dropdown-item
                        [ngClass]="{ dropDownSelectListBorder: !$last }"
                        (click)="pickBelongsToAsset(option.value)"
                     >
                        <div class="assign-pm-dropdown-item">
                           <div [limbleHtml]="option.label"></div>
                           <lim-ui-icon
                              icon="circleQuestion"
                              iconSize="small"
                              [hidden]="!option.hint"
                              title="{{ option.hint }}"
                           />
                        </div>
                     </lim-ui-dropdown-item>
                  }
               </ng-container>
            </lim-ui-form-dropdown-input>

            <a
               class="green-color"
               (click)="featureStartWOStartPM ? pickBelongsToAsset(1) : null"
            >
               <span [limbleHtml]="taskBelongsToPrompt"></span>
            </a>
         </div>
         <ng-template #startWOItemDisabledArea>
            <upsell-popover
               [message]="lang().FeatureStartWOStartPMPopoverInstructionMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>
         <div class="chk-options-container pricing-plan-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [onlyDelete]="!featureStartWOStartPM"
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
            @if (!featureStartWOStartPM) {
               <lim-ui-badge
                  class="pricing-plan-badge"
                  icon="stars"
                  [label]="lang().PremiumPlus"
                  [limUiPopover]="startWOStartPMPopoverEditBadge"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'bottom'"
                  [limUiPopoverHidden]="featureStartWOStartPM"
               />
            }
            <ng-template #startWOStartPMPopoverEditBadge>
               <upsell-popover
                  [message]="lang().FeatureStartWOStartPMPopoverInstructionMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>
         </div>
      </ng-template>

      <ng-template #signatureItem let-item="item">
         <span class="template-view-item-name" (mouseover)="item.hovered = true">
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         <signature-pad
            [signature]="sigPrep"
            [signatureInfo]="sigInfo"
            [disabled]="true"
            [placement]="'top'"
            [limUiTooltip]="lang().signatureBuilderTooltip"
         />

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
                  [noDelete]="featureESignatureVerification && item.itemResponse"
               />
            }
         </div>
      </ng-template>

      <ng-template #requestApprovalItem let-item="item">
         <span
            class="template-view-item-name"
            [ngClass]="{ paywalled: !featureCustomApprovalInstruction }"
            (mouseover)="item.hovered = true"
            [limUiPopover]="startCustomApprovalInstructionArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureCustomApprovalInstruction"
         >
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  limbleContentEditable
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
            <a
               class="cursor"
               (click)="changeApprovalTarget()"
               placement="top"
               limUiTooltip="{{ lang().RequestApprovalPickUserTooltip }}"
               [ngClass]="{
                  disabled: featureESignatureVerification && item.itemResponse,
               }"
            >
               {{ item.assignTargetStr }}
            </a>
         </span>

         <signature-pad
            [signature]="sigPrep"
            [signatureInfo]="sigInfo"
            [showAutoSign]="true"
            [disabled]="true || !featureCustomApprovalInstruction"
            [placement]="'top'"
            [limUiTooltip]="lang().signatureBuilderTooltip"
            [type]="'approval'"
            [ngClass]="{ paywalled: !featureCustomApprovalInstruction }"
            [limUiPopover]="startCustomApprovalInstructionArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureCustomApprovalInstruction"
         />

         <div
            [ngClass]="{ paywalled: !featureCustomApprovalInstruction }"
            [limUiPopover]="startCustomApprovalInstructionArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureCustomApprovalInstruction"
         >
            <h5>{{ lang().WhenApprovedAssignTaskTo }}</h5>

            <a
               class="cursor"
               (click)="changeAfterApprovalTarget()"
               [ngClass]="{
                  disabled: featureESignatureVerification && item.itemResponse,
               }"
            >
               <span [limbleHtml]="item.onApproveTargetStr"></span>
            </a>
         </div>

         <div
            [ngClass]="{ paywalled: !featureCustomApprovalInstruction }"
            [limUiPopover]="startCustomApprovalInstructionArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureCustomApprovalInstruction"
         >
            <h5>{{ lang().WhenDisapprovedAssignTaskTo }}</h5>
            <a
               class="cursor"
               (click)="changeAfterDisapproveTarget()"
               [ngClass]="{
                  disabled: featureESignatureVerification && item.itemResponse,
               }"
            >
               <span [limbleHtml]="item.onDisapproveTargetStr"></span>
            </a>
            &nbsp;
            @if (item.onDisapproveUserID == 0 && item.onDisapproveProfileID == 0) {
               <lim-ui-icon
                  icon="circleQuestion"
                  iconSize="small"
                  limUiTooltip="{{ lang().OriginalOwnerTooltip }}"
               />
            }
         </div>
         <ng-template #startCustomApprovalInstructionArea>
            <upsell-popover
               [message]="lang().FeatureCustomApprovalAdvancedPopoverMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>

         <div class="chk-options-container pricing-plan-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [onlyDelete]="!featureCustomApprovalInstruction"
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  [type]="'item'"
                  [noDelete]="featureESignatureVerification && item.itemResponse"
               />
            }
            @if (!featureCustomApprovalInstruction) {
               <lim-ui-badge
                  class="pricing-plan-badge"
                  icon="stars"
                  [label]="lang().Enterprise"
                  [limUiPopover]="startRequestApprovalEditBadge"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'bottom'"
                  [limUiPopoverHidden]="featureCustomApprovalInstruction"
               />
            }
            <ng-template #startRequestApprovalEditBadge>
               <upsell-popover
                  [message]="lang().FeatureCustomApprovalAdvancedPopoverMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>
         </div>
      </ng-template>

      <ng-template #instructionSet let-item="item">
         <span
            class="template-view-item-name"
            (mouseover)="item.hovered = true"
            [limUiTooltip]="
               instructionSetInstance
                  ? lang().InstructionSetInstanceNameTooltip
                  : undefined
            "
         >
            @if (!item.hovered) {
               <div class="editUnderline" [limbleHtml]="item.itemText"></div>
            }
            @if (item.hovered) {
               <div
                  class="editUnderline"
                  [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
                  [(ngModel)]="item.itemText"
                  (afterEdit)="updateItemText()"
                  [limbleContentEditable]="!instructionSetInstance"
                  (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
               ></div>
            }
         </span>

         @if (item.instructionSetTimestamp > 0) {
            <span class="align-items-center instruction-set-timestamp">
               <span
                  placement="right"
                  limUiTooltip="{{ instructionSetTimestampTooltip }}"
               >
                  {{ instructionSetTimestamp | betterDate: "dateTimeWithSeconds" }}
               </span>
            </span>
         }

         <div class="chk-options-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
         </div>
      </ng-template>

      <ng-template #verifyLocation let-item="item">
         <div
            class="template-view-item-name"
            [ngClass]="{ paywalled: !featureCustomApprovalInstruction }"
            [limUiPopover]="startCustomApprovalInstructionVerifyLocationArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureCustomApprovalInstruction"
         >
            <span (mouseover)="item.hovered = true">
               @if (!item.hovered) {
                  <div class="editUnderline" [limbleHtml]="item.itemText"></div>
               }
               @if (item.hovered) {
                  <div
                     class="editUnderline"
                     [(ngModel)]="item.itemText"
                     (afterEdit)="updateItemText()"
                     limbleContentEditable
                     (click)="checkFirstInstructionTextSelectAll(item.itemText, item)"
                  ></div>
               }
            </span>
         </div>

         <div
            [ngClass]="{ paywalled: !featureCustomApprovalInstruction }"
            [limUiPopover]="startCustomApprovalInstructionVerifyLocationArea"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'bottom'"
            [limUiPopoverHidden]="featureCustomApprovalInstruction"
         >
            <h5>{{ lang().WhatDoYouWantToVerifyLocationAgainst }}</h5>
            <lim-ui-form-dropdown-input
               [inputString]="item.taskBelongsToOptionPicked"
               [disabled]="!featureCustomApprovalInstruction"
            >
               <ng-container menu>
                  @for (option of item.taskBelongsToOptions; track option) {
                     <lim-ui-dropdown-item
                        [ngClass]="{ dropDownSelectListBorder: !$last }"
                        (click)="pickBelongsToAsset(option.value)"
                     >
                        <div class="assign-pm-dropdown-item">
                           <div [limbleHtml]="option.label"></div>
                           <lim-ui-icon
                              icon="circleQuestion"
                              iconSize="small"
                              [hidden]="!option.hint"
                              title="{{ option.hint }}"
                           />
                        </div>
                     </lim-ui-dropdown-item>
                  }
               </ng-container>
            </lim-ui-form-dropdown-input>

            <a class="green-color" (click)="pickBelongsToAsset(1)">
               <span [limbleHtml]="taskBelongsToPrompt"></span>
            </a>
         </div>
         <ng-template #startCustomApprovalInstructionVerifyLocationArea>
            <upsell-popover
               [message]="lang().FeatureCustomApprovalAdvancedPopoverMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>

         <div class="chk-options-container pricing-plan-container">
            @if (treeBranch) {
               <chk-item-build-options
                  [onlyDelete]="!featureCustomApprovalInstruction"
                  [treeBranch]="treeBranch"
                  [info]="info()"
                  [item]="item"
                  [items]="info().items"
                  [options]="options()"
                  type="item"
               />
            }
            @if (!featureCustomApprovalInstruction) {
               <lim-ui-badge
                  class="pricing-plan-badge"
                  icon="stars"
                  [label]="lang().Enterprise"
                  [limUiPopover]="startVerifyLocationPopoverEditBadge"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'bottom'"
                  [limUiPopoverHidden]="featureCustomApprovalInstruction"
               />
            }
            <ng-template #startVerifyLocationPopoverEditBadge>
               <upsell-popover
                  [message]="lang().FeatureCustomApprovalAdvancedPopoverMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>
         </div>
      </ng-template>
   </lim-ui-nested-panel>
}

@if (
   item()?.itemOptionID &&
   item()?.parentItemTypeID &&
   taskMode() === "template" &&
   info() !== undefined
) {
   <lim-ui-nested-panel class="chk-item-panel edit-instructions-panel allowOffline">
      <div class="chk-item-instruction">
         <span
            class="limble-tree-handle-container lim-ui-hide-on-mobile"
            [ngClass]="{
               'greater-than-two-digit-item-order':
                  item().itemOptionOrder.toString().length >= 2,
               'greater-than-three-digit-item-order':
                  item().itemOptionOrder.toString().length >= 3,
            }"
         >
            @if (treeBranch) {
               <lim-ui-icon
                  icon="arrowsUpDown"
                  iconSize="small"
                  class="move-item-icon"
                  [limbleTreeDraggable]="treeBranch"
               />
            }
            <span class="item-order-number">{{ item().itemOptionOrder }}</span>
         </span>

         @switch (item().parentItemTypeID) {
            @case (TaskInstructionType.OptionList) {
               <ng-container
                  *ngTemplateOutlet="radioOptionsItem; context: { item: item() }"
               />
            }

            @case (TaskInstructionType.DropdownList) {
               <ng-container
                  *ngTemplateOutlet="dropdownItem2; context: { item: item() }"
               />
            }
         }
      </div>

      <ng-template #radioOptionsItem let-item="item">
         <div class="type-icon-container">
            <lim-ui-icon icon="circleRegular" iconSize="small" />
         </div>

         <div class="instruction-content">
            <div
               class="editUnderline"
               [attr.data-placeholder-text]="lang().SetupWorkOrderPlaceholder"
               [(ngModel)]="item.itemOptionText"
               (afterEdit)="updateOptionText(item)"
               limbleContentEditable
               (click)="checkFirstInstructionTextSelectAll(item().itemOptionText, item)"
               stripHtml="true"
            ></div>

            <div class="chk-options-container">
               @if (treeBranch) {
                  <chk-option-build-options
                     [treeBranch]="treeBranch"
                     [option]="item"
                     [options]="options()"
                     [item]="item.parentItem"
                     [items]="info().items"
                     [info]="info()"
                  />
               }
            </div>
         </div>
      </ng-template>

      <ng-template #dropdownItem2 let-item="item">
         <div class="type-icon-container">
            <lim-ui-icon
               icon="squareCaretDownRegular"
               iconSize="small"
               limUiTooltip="{{ lang().ThisIsADropdownItem }}"
               placement="right"
            />
         </div>

         <div class="instruction-content">
            <div
               class="editUnderline"
               [(ngModel)]="item.itemOptionText"
               (afterEdit)="updateOptionText(item)"
               limbleContentEditable
               (click)="checkFirstInstructionTextSelectAll(item.itemOptionText, item)"
               stripHtml="true"
            ></div>

            <div class="chk-options-container">
               @if (treeBranch) {
                  <chk-option-build-options
                     [treeBranch]="treeBranch"
                     [option]="item"
                     [options]="options()"
                     [item]="item.parentItem"
                     [items]="info().items"
                     [info]="info()"
                  />
               }
            </div>
         </div>
      </ng-template>
   </lim-ui-nested-panel>
}

@if (item()?.itemTypeID && taskMode() === "instance" && info() !== undefined) {
   <lim-ui-nested-panel class="chk-item-panel">
      <div
         class="chk-item-instruction-wrapper allowOffline"
         [ngClass]="{ 'greyout-chk-item': item().greyOut }"
      >
         <div class="chk-item-instruction">
            <span class="item-order-holder lim-ui-fonts-body-extra-bold">{{
               item().itemOrder
            }}</span>
            <div class="chk-item-instruction-group">
               <div class="instruction-content-with-pointer">
                  <div
                     class="instruction-content"
                     [ngClass]="{
                        'inline-instruction-content':
                           item().itemTypeID === TaskInstructionType.Number ||
                           item().itemTypeID === TaskInstructionType.UpdatePartQty ||
                           item().itemTypeID === TaskInstructionType.TextBox ||
                           item().itemTypeID === TaskInstructionType.DatePicker,
                     }"
                  >
                     @switch (item().itemTypeID) {
                        @case (TaskInstructionType.CheckBox) {
                           <ng-container
                              *ngTemplateOutlet="
                                 checkboxItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.OptionList) {
                           <ng-container
                              *ngTemplateOutlet="
                                 radioOptionListItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.TextBox) {
                           <ng-container
                              *ngTemplateOutlet="
                                 textboxItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.DropdownList) {
                           <ng-container
                              *ngTemplateOutlet="
                                 dropdownItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.DatePicker) {
                           <ng-container
                              *ngTemplateOutlet="
                                 datePickerItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.Note) {
                           <ng-container
                              *ngTemplateOutlet="
                                 labelItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.Reassign) {
                           <ng-container
                              *ngTemplateOutlet="
                                 reassignItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.FileOrPictureAttachment) {
                           <ng-container
                              *ngTemplateOutlet="
                                 fileAttachmentItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.AssignPM) {
                           <ng-container
                              *ngTemplateOutlet="
                                 assignTaskItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.DeadlineDatePicker) {
                           <ng-container
                              *ngTemplateOutlet="
                                 deadlineDatePickerItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.Number) {
                           <ng-container
                              *ngTemplateOutlet="
                                 numberItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.StartWO) {
                           <ng-container
                              *ngTemplateOutlet="
                                 assignWOInstructionInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.CaptureSignature) {
                           <ng-container
                              *ngTemplateOutlet="
                                 signatureItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.RequestApproval) {
                           <ng-container
                              *ngTemplateOutlet="
                                 requestApprovalItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.UpdatePartQty) {
                           <ng-container
                              *ngTemplateOutlet="
                                 updatePartQtyItemInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.InstructionSet) {
                           <ng-container
                              *ngTemplateOutlet="
                                 instructionSetInstance;
                                 context: { item: item() }
                              "
                           />
                        }

                        @case (TaskInstructionType.VerifyLocation) {
                           <ng-container
                              *ngTemplateOutlet="
                                 verifyLocationInstance;
                                 context: { item: item() }
                              "
                           />
                        }
                     }
                  </div>

                  <chk-item-pointer
                     [item]="item()"
                     [instructions]="instructions() ?? []"
                  />
               </div>
               @if (item().linkHint) {
                  <task-item-link-hint
                     [showHelp]="linkHintTooltip"
                     [linkHint]="item().linkHint"
                  />
               }
            </div>
         </div>
         @if (anomalyStateStore.showDetectedAnomalousValueCard()) {
            <anomalous-value-detected-card
               [newValue]="anomalyStateStore.currentlyEnteredValue()"
               [checklistItemId]="item().checklistItemID"
               [anomalyID]="anomalyStateStore.anomalyID()"
               @fadeIn
            />
         }
         @if (anomalyStateStore.showCorrectedAnomalousValueCard()) {
            <way-to-fix-that-card @collapseUp />
         }
         @if (item().itemFileName || item().taskImage) {
            <div class="chk-item-instruction-files">
               <instructional-files [item]="item()" />
            </div>
         }

         <!-- If itemTypeID != 2 then we display the chk-item().
            itemTypeID == 2 will be handled based on the lim-ui-radio-button selection. -->
         @if (
            item()?.itemTypeID &&
            item()?.itemTypeID !== TaskInstructionType.OptionList &&
            item()?.itemTypeID !== TaskInstructionType.DropdownList
         ) {
            @for (itemRepeat of childItems; track itemRepeat) {
               <chk-item
                  #chkItem
                  [item]="itemRepeat"
                  [taskInstructionsViewParameters]="taskInstructionsViewParameters()"
                  [itemParentResponse]="item().itemResponse"
                  [parentTaskInput]="parentTaskInput()"
                  [info]="info()"
                  [taskHasOptionSelectedWithSameCustomTag]="
                     taskHasOptionSelectedWithSameCustomTag
                  "
                  [tempEdit]="tempEdit"
                  [instructions]="instructions()"
               />
            }
         }
      </div>

      <ng-template #checkboxItemInstance let-item="item">
         <div class="inline-display">
            <lim-ui-checkbox
               [(model)]="item.itemResponse"
               [label]="item.itemText"
               [alignTop]="true"
               (modelChange)="setResponse()"
               [disabled]="!taskItemsEditable() || item.greyOut"
               data-test="task-checkbox"
            />
         </div>
      </ng-template>

      <ng-template #radioOptionListItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         <div
            class="radio-buttons-container"
            [ngClass]="{ 'greyout-chk-item': item.greyOut }"
         >
            @for (
               option of options()
                  | filter: { itemID: item.itemID }
                  | orderBy: "itemOptionOrder";
               track option;
               let last = $last
            ) {
               <div
                  [ngClass]="{
                     'child-item':
                        (
                           info().items
                           | filter
                              : {
                                   itemParentID: item.checklistItemCount + '',
                                   itemParentResponse: option.itemOptionCount,
                                }
                        ).length > 0 &&
                        (item.itemResponse == option.itemOptionCount || expand()),
                     'last': last,
                  }"
               >
                  <div class="success smooth circle">
                     <div>
                        <lim-ui-radio-button
                           storeOffline
                           [checked]="item.itemResponse == option.itemOptionCount"
                           (click)="handleClickOnOptionItem(item, info(), option)"
                           [disabled]="!taskItemsEditable()"
                           [label]="option.itemOptionText"
                        />
                     </div>
                  </div>
                  @if (item.itemResponse == option.itemOptionCount || expand()) {
                     <div class="chk-item-instruction-wrapper">
                        @for (
                           itemRepeat of info().items
                              | filter
                                 : {
                                      itemParentID: item.checklistItemCount + "",
                                      itemParentResponse: option.itemOptionCount,
                                   };
                           track itemRepeat
                        ) {
                           <chk-item
                              #radioChkItem
                              [item]="itemRepeat"
                              [info]="info()"
                              [taskInstructionsViewParameters]="
                                 taskInstructionsViewParameters()
                              "
                              [taskHasOptionSelectedWithSameCustomTag]="
                                 taskHasOptionSelectedWithSameCustomTag
                              "
                              [parentTaskInput]="parentTaskInput()"
                              [itemParentResponse]="option.itemOptionCount"
                              [tempEdit]="tempEdit"
                              [instructions]="instructions()"
                           />
                        }
                     </div>
                  }
               </div>
            }
         </div>
      </ng-template>

      <ng-template #textboxItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         <div class="input-container-with-btn">
            <span class="textboxInCheckOffset">
               <div
                  type="text"
                  class="small-text-field auto-expand"
                  [(ngModel)]="item.itemResponse"
                  [limbleContentEditable]="taskItemsEditable() && !item.greyOut"
                  (blur)="setResponse()"
                  (keyup)="setDoneTextbox()"
                  id="input-field-{{ item.itemID }}"
                  storeOffline
               ></div>
            </span>

            <div class="past-responses">
               <div
                  class="past-responses-toggle printHide"
                  [limUiTooltip]="lang().ViewPastResponses"
                  (click)="showPastResponses = !showPastResponses"
                  data-log="task-showPastResponses"
               >
                  <div class="icon-outline" [ngClass]="{ active: showPastResponses }">
                     <lim-ui-icon
                        icon="list"
                        iconSize="small"
                        class="past-responses-button"
                     />
                  </div>
               </div>
               @if (showPastResponses) {
                  <div class="past-responses-popover" @fadeIn @fadeOut>
                     <lim-ui-scroll-container class="scroll-container" [thin]="true">
                        <div class="header">
                           <h5>
                              <lim-ui-icon icon="list" iconSize="small" />
                              {{ lang().ViewingPastResponses }}
                           </h5>
                           @if (item.pastResponses?.length > 0) {
                              <lim-ui-minimal-icon-button
                                 icon="cloudDownloadRegular"
                                 [superMinimal]="true"
                                 placement="left"
                                 limUiTooltip="{{ lang().ExportAllPastResponseToExcel }}"
                                 (click)="exportPastResponses(item)"
                              />
                           }
                        </div>

                        <div class="listTable">
                           <div class="listItemHeader">
                              <div>
                                 {{ lang().Response }}
                              </div>
                              <div>
                                 {{ lang().ResponseDate }}
                              </div>
                           </div>

                           @for (
                              past of item.pastResponses
                                 | orderBy: "-checklistCompletedDate";
                              track past;
                              let last = $last
                           ) {
                              <div class="listItem" [ngClass]="{ last: last == true }">
                                 <div class="listCol listCol50">
                                    <span [limbleHtml]="past.itemResponse"></span>
                                 </div>
                                 <div class="listCol listCol50 date">
                                    {{ past.dateStr }}
                                 </div>
                              </div>
                           }

                           @if (item.pastResponses?.length == 0) {
                              <div class="listItem last">
                                 <div class="listCol">
                                    {{ lang().NothingRecorded }}
                                 </div>
                              </div>
                           }

                           @if (item.pastResponses?.length > 6) {
                              <span>
                                 <br />
                                 <br />
                              </span>
                           }
                        </div>
                     </lim-ui-scroll-container>
                  </div>
               }
            </div>
         </div>
      </ng-template>

      <ng-template #dropdownItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         <lim-ui-form-dropdown-input
            class="allowOffline dropdown-input"
            [disabled]="
               !taskItemsEditable() ||
               (startedTaskCompletedDate() ?? 0) > 0 ||
               item.greyOut
            "
            [inputString]="item.displayText"
         >
            <ng-container menu>
               @for (
                  option of options()
                     | filter: { itemID: item.itemID }
                     | orderBy: "itemOptionOrder";
                  track option
               ) {
                  <lim-ui-dropdown-item
                     class="allowOffline"
                     [ngClass]="{ dropDownSelectListBorder: !$last }"
                     (click)="setResponse(option)"
                     storeOffline
                  >
                     <span [limbleHtml]="option.itemOptionText"></span>
                  </lim-ui-dropdown-item>
               }
            </ng-container>
         </lim-ui-form-dropdown-input>
         @if (item.itemResponse) {
            @for (
               itemSubDrop of info().items
                  | filter
                     : {
                          itemParentID: item.checklistItemCount,
                          itemParentResponse: item.itemResponse + "",
                       }
                     : true;
               track itemSubDrop
            ) {
               <chk-item
                  #dropdownChkItem
                  [taskHasOptionSelectedWithSameCustomTag]="
                     taskHasOptionSelectedWithSameCustomTag
                  "
                  [taskInstructionsViewParameters]="taskInstructionsViewParameters()"
                  [item]="itemSubDrop"
                  [info]="info()"
                  [parentTaskInput]="parentTaskInput()"
                  [tempEdit]="tempEdit"
                  [instructions]="instructions()"
               />
            }
         }
      </ng-template>

      <ng-template #datePickerItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         <lim-ui-date-picker-input
            [disabled]="!taskItemsEditable()"
            [(model)]="item.itemResponse"
            class="printHide allowOffline"
            (modelChange)="setResponse()"
            storeOffline
         />

         <span class="printViewShow">
            <div type="text" class="exitOnEnterKeyPress">
               {{ item.itemResponse | betterDate: "date" }}
            </div>
         </span>
      </ng-template>

      <ng-template #labelItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>
      </ng-template>

      <ng-template #reassignItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         <lim-ui-secondary-button
            [hidden]="!(taskItemsEditable() && item.itemResponse != 1)"
            [classNames]="'mobile-full-width'"
            class="cursor"
            (click)="changeAssignmentFromInstruction()"
            storeOffline
            icon="user"
         >
            {{ lang().ReassignTask }}
            @if (item.itemWOAssignToLocked > 0) {
               <span> &nbsp;{{ lang().to }} {{ reassignUserTarget }}</span>
            }
         </lim-ui-secondary-button>

         <span
            class="checkbox-container"
            [hidden]="!(!taskItemsEditable() || item.itemResponse == 1)"
         >
            <lim-ui-checkbox [disabled]="true" [model]="true" />
            {{ lang().ReassignTask }}
         </span>
      </ng-template>

      <ng-template #fileAttachmentItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         <div>
            <file-uploader
               offline="allowOffline"
               label="{{ lang().AttachFile }}"
               icon="cloudArrowUp"
               [uploadObject]="uploadObj"
            />
         </div>

         @let infoFiles = info().files;
         @if (infoFiles && (infoFiles | filter: { itemID: item.itemID }).length > 0) {
            <div class="file-image-wrapper">
               @for (file of infoFiles | filter: { itemID: item.itemID }; track file) {
                  <div class="expand-small">
                     @if (imageExts.includes(file.ext)) {
                        <span>
                           <editable-image
                              [file]="file"
                              [permission]="taskItemsEditable()"
                              [edit]="openEditor"
                              (delete)="deleteFile($event)"
                           />
                        </span>
                     }
                  </div>
               }
            </div>
         }
         @if (infoFiles && (infoFiles | filter: { itemID: item.itemID }).length > 0) {
            @for (file of infoFiles | filter: { itemID: item.itemID }; track file) {
               <div class="gradual-ml">
                  <lim-ui-icon
                     class="file-fail hidePrint"
                     icon="banRegular"
                     iconSize="small"
                     [hidden]="!(file.status === false)"
                  />

                  <!--Used for non images so people can click them-->
                  @if (!imageExts.includes(file.ext)) {
                     <div class="list-group-item cursor hoverContainer3">
                        <div class="file-item-container">
                           <file-list-item
                              [file]="file"
                              url="viewFile.php?f=upload-{{ customerID }}/{{
                                 item.checklistID
                              }}-{{ item.itemID }}/{{ file.fileName | escape }}"
                           />

                           <div class="hidePrint delete-file-button">
                              <lim-ui-icon
                                 icon="trashCanRegular"
                                 iconSize="small"
                                 (click)="deleteFile(file)"
                                 [hidden]="!taskItemsEditable()"
                                 limUiTooltip="{{ lang().DeleteThisFile }}"
                                 data-placement="bottom"
                                 storeOffline
                              />
                           </div>
                        </div>
                     </div>
                  }
               </div>
            }
         }
      </ng-template>

      <ng-template #assignTaskItemInstance let-item="item">
         @if (item.itemText === "Instructions") {
            <span>
               {{ lang().NoPMToAssignFeedback }}
            </span>
         }

         @if (
            (item.itemResponse == 0 || item.itemResponse == null) &&
            taskItemsEditable() &&
            item.itemText !== "Instructions"
         ) {
            <span
               class="pricing-plan-container"
               [limUiPopover]="startWOStartPMPopoverAssignPM"
               [limUiPopoverType]="'upsell'"
               [limUiPopoverPlacement]="'top'"
               [limUiPopoverHidden]="featureStartWOStartPM"
            >
               <span
                  [ngClass]="{ paywalled: !featureStartWOStartPM }"
                  (click)="assignAssetToNewPM()"
               >
                  {{ lang().AssignPM }}:
               </span>
               <lim-ui-icon
                  icon="wrench"
                  iconSize="small"
                  [ngClass]="{ paywalled: !featureStartWOStartPM }"
               />
               <a
                  class="cursor"
                  (click)="assignAssetToNewPM()"
                  [limbleHtml]="assetPMChecklistName()"
                  id="itemStartTask{{ item.itemID }}"
               ></a>

               @if (asset(); as asset) {
                  <span [ngClass]="{ paywalled: !featureStartWOStartPM }">
                     -
                     <a
                        class="cursor green-color-link"
                        (click)="popAsset(asset.assetID)"
                        >{{ asset.assetName }}</a
                     >
                  </span>
               }
               -
               <lim-ui-icon
                  [ngClass]="{ paywalled: !featureStartWOStartPM }"
                  icon="circleQuestion"
                  iconSize="small"
                  (click)="previewPM()"
               />
               @if (!featureStartWOStartPM) {
                  <lim-ui-badge
                     class="pricing-plan-badge"
                     icon="stars"
                     [label]="lang().PremiumPlus"
                  />
               }
            </span>
         }
         <ng-template #startWOStartPMPopoverAssignPM>
            <upsell-popover
               [message]="lang().FeatureStartWOStartPMPopoverInstructionMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>

         @if (
            (item.itemResponse == 0 || item.itemResponse == null) && !taskItemsEditable()
         ) {
            <span>
               {{ lang().AssignPM }}: <lim-ui-icon icon="wrench" iconSize="small" /><span
                  [limbleHtml]="assetPMChecklistName()"
               ></span>

               @if (asset(); as asset) {
                  <span>
                     -
                     <a
                        class="cursor green-color-link"
                        (click)="popAsset(asset.assetID)"
                        >{{ asset.assetName }}</a
                     >
                  </span>
               }
            </span>
         }

         @if (item.itemResponse > 1) {
            <span
               class="checkbox-container"
               [class.with-task-status]="
                  assetPMChecklistName() && taskStatusNameFromWOorPM()
               "
            >
               <span class="content-group d-flex">
                  <lim-ui-checkbox [disabled]="true" [model]="true" />
                  @if (assetPMChecklistName() && taskStatusNameFromWOorPM()) {
                     <span class="instruction-task-display-status"
                        >{{ taskStatusNameFromWOorPM() }}
                     </span>
                  }
               </span>
               <span class="content-group">
                  <lim-ui-icon icon="wrench" iconSize="small" />
                  @if (assetPMChecklistName()) {
                     <span class="checklist-name">
                        <a class="cursor" (click)="popTaskPmInstance(item.itemResponse)">
                           <span
                              [limbleHtml]="assetPMChecklistName()"
                              class="cursor"
                           ></span>
                           - #{{ item.itemResponse }}
                        </a>

                        @if (asset(); as asset) {
                           <span>
                              -
                              <a
                                 class="cursor green-color-link"
                                 (click)="popAsset(asset.assetID)"
                                 >{{ asset.assetName }}</a
                              >
                           </span>
                        }
                     </span>
                  } @else {
                     <span class="red-color content-group">
                        {{ lang().TaskWasDeleted }}
                     </span>
                  }
               </span>
            </span>
         }

         @if (item.itemResponse > 1) {
            <span>
               {{ lang().PMSuccessFeedback }}
               <br />
               <div class="another-pm-button">
                  @if (taskItemsEditable()) {
                     <lim-ui-secondary-button
                        class="cursor"
                        (click)="startAnotherTask()"
                        >{{ lang().StartAnotherPM }}</lim-ui-secondary-button
                     >
                  }
               </div>
            </span>
         }
      </ng-template>

      <ng-template #deadlineDatePickerItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         <lim-ui-date-picker-input
            [disabled]="!taskItemsEditable()"
            (modelChange)="setResponse()"
            [(model)]="item.itemResponse"
            storeOffline
         />
      </ng-template>

      <ng-template #numberItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         <span class="input-container-with-btn">
            <div class="input-and-past-responses-container">
               <input
                  type="number"
                  [(ngModel)]="item.itemResponse"
                  [disabled]="!taskItemsEditable()"
                  (blur)="setResponse()"
                  (ngModelChange)="onModelChange()"
                  storeOffline
               />

               <div class="past-responses">
                  <div
                     class="past-responses-toggle printHide"
                     [limUiTooltip]="lang().ViewPastResponses"
                     (click)="showPastResponses = !showPastResponses"
                     data-log="task-showPastResponses"
                  >
                     <div class="icon-outline" [ngClass]="{ active: showPastResponses }">
                        <lim-ui-icon
                           icon="list"
                           iconSize="small"
                           class="past-responses-button"
                        />
                     </div>
                  </div>
                  @if (showPastResponses) {
                     <div class="past-responses-popover" @fadeIn @fadeOut>
                        <lim-ui-scroll-container class="scroll-container" [thin]="true">
                           <div class="header">
                              <h5>
                                 <lim-ui-icon icon="list" iconSize="small" />
                                 {{ lang().ViewingPastResponses }}
                              </h5>
                              @if (item.pastResponses?.length > 0) {
                                 <lim-ui-minimal-icon-button
                                    icon="cloudDownloadRegular"
                                    [superMinimal]="true"
                                    placement="left"
                                    limUiTooltip="{{
                                       lang().ExportAllPastResponseToExcel
                                    }}"
                                    (click)="exportPastResponses(item)"
                                 />
                              }
                           </div>

                           <div class="listTable">
                              <div class="listItemHeader">
                                 <div>
                                    {{ lang().Response }}
                                 </div>
                                 <div>
                                    {{ lang().ResponseDate }}
                                 </div>
                              </div>

                              @for (
                                 past of item.pastResponses
                                    | orderBy: "-checklistCompletedDate";
                                 track past;
                                 let last = $last
                              ) {
                                 <div class="listItem" [ngClass]="{ last: last == true }">
                                    <div class="listCol listCol50">
                                       {{ past.itemResponse }}
                                    </div>
                                    <div class="listCol listCol50 date">
                                       {{ past.dateStr }}
                                    </div>
                                 </div>
                              }

                              @if (item.pastResponses?.length == 0) {
                                 <div class="listItem last">
                                    <div class="listCol">
                                       {{ lang().NothingRecorded }}
                                    </div>
                                 </div>
                              }

                              @if (item.pastResponses?.length > 6) {
                                 <span>
                                    <br />
                                    <br />
                                 </span>
                              }
                           </div>
                        </lim-ui-scroll-container>
                     </div>
                  }
               </div>
            </div>
            @if (anomalyStateStore.showConfirmedAnomalousValueCard()) {
               <anomalous-value-confirmed-card @fadeIn @fadeOut />
            }
         </span>
      </ng-template>

      <ng-template #assignWOInstructionInstance let-item="item">
         @if (item.itemResponse == 0 || item.itemResponse == null) {
            <span [limbleHtml]="item.itemText"></span>
         }

         @if (checklistTemplate == 0) {
            <span>
               @if (
                  (item.itemResponse == 0 || item.itemResponse == null) &&
                  taskItemsEditable()
               ) {
                  <div
                     class="pricing-plan-container"
                     [limUiPopover]="startWOStartPMPopoverRead"
                     [limUiPopoverType]="'upsell'"
                     [limUiPopoverPlacement]="'top'"
                     [limUiPopoverHidden]="featureStartWOStartPM"
                  >
                     <lim-ui-secondary-button
                        icon="wpforms"
                        [classNames]="'mobile-full-width'"
                        id="itemStartTask{{ item.itemID }}"
                        (click)="startWO()"
                        [disabled]="!featureStartWOStartPM"
                     >
                        <span>{{ lang().ItemStartWO }}</span>
                     </lim-ui-secondary-button>
                     @if (!featureStartWOStartPM) {
                        <lim-ui-badge
                           class="pricing-plan-badge"
                           icon="stars"
                           [label]="lang().PremiumPlus"
                        />
                     }
                  </div>
               }
               <ng-template #startWOStartPMPopoverRead>
                  <upsell-popover
                     [message]="lang().FeatureStartWOStartPMPopoverInstructionMessage"
                     [pricingLinkText]="lang().LearnMore"
                  />
               </ng-template>

               @if (item.itemResponse > 1) {
                  <span
                     class="checkbox-container"
                     [class.with-task-status]="isTaskStatusNameFromWOOrPMVisible()"
                  >
                     <span class="content-group d-flex">
                        <lim-ui-checkbox [disabled]="true" [model]="true" />
                        @if (isTaskStatusNameFromWOOrPMVisible()) {
                           <span class="instruction-task-display-status"
                              >{{ taskStatusNameFromWOorPM() }}
                           </span>
                        }
                     </span>
                     <span class="content-group">
                        <lim-ui-icon icon="wpforms" iconSize="small" />
                        @if (instrTaskName()) {
                           <span class="checklist-name content-group">
                              @if ((startedTaskCompletedDate() ?? 0) > 0) {
                                 <span limUiTooltip="{{ lang().TaskCompleted }}"></span>
                              }
                              <a class="cursor" (click)="popTask(item.itemResponse)">
                                 <span [limbleHtml]="instrTaskName()"></span> - #{{
                                    item.itemResponse
                                 }}
                              </a>

                              @if (assetID(); as assetID) {
                                 <span>
                                    -
                                    <a
                                       class="cursor green-color-link"
                                       (click)="popAsset(assetID)"
                                       >{{ assetName }}</a
                                    >
                                 </span>
                              }
                           </span>
                        } @else {
                           <span class="red-color content-group">
                              {{ lang().TaskWasDeleted }}
                           </span>
                        }
                     </span>
                  </span>
               }

               @if (item.itemResponse > 1) {
                  <div>
                     <span>
                        {{ lang().ItemStartWOSuccessFeedback2 }}
                     </span>
                     <div class="another-wo-button">
                        @if (taskItemsEditable()) {
                           <lim-ui-secondary-button
                              class="cursor"
                              (click)="startAnotherTask()"
                              >{{
                                 lang().StarAnotherWONonQuestion
                              }}</lim-ui-secondary-button
                           >
                        }
                     </div>
                  </div>
               }
            </span>
         }

         @if (checklistTemplate > 0) {
            <div>
               <lim-ui-secondary-button
                  limUiTooltip="{{ lang().ToTestTheFunctionalityOfStartWO }}"
                  icon="wpforms"
                  [disabled]="true"
               >
                  <span>{{ lang().ItemStartWO }}</span>
               </lim-ui-secondary-button>
            </div>
         }
      </ng-template>

      <ng-template #signatureItemInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>

         @if (taskItemsEditable()) {
            <div [ngClass]="{ 'greyout-chk-item': item.greyOut }">
               <signature-pad
                  [clear]="clearSignature"
                  [signature]="sigPrep"
                  [signatureInfo]="sigInfo"
                  [disabled]="
                     !canClearSignatures() ||
                     (featureESignatureVerification &&
                        (sigPrep || !taskBelongsToCurrentUser))
                  "
                  [save]="completeSignature"
                  [showAutoSign]="true"
                  [limUiTooltip]="
                     featureESignatureVerification && !taskBelongsToCurrentUser
                        ? lang().NotAssignedToSignatureInstruction
                        : ''
                  "
               />
            </div>
         }

         @if (!taskItemsEditable() && item.itemResponse?.length > 0) {
            <div>
               @if (sigPrep) {
                  <signature-pad
                     [signature]="sigPrep"
                     [signatureInfo]="sigInfo"
                     [disabled]="true"
                     [type]="'image'"
                  />
               }
            </div>
         }
      </ng-template>

      <ng-template #requestApprovalItemInstance let-item="item">
         @if (taskItemsEditable() && item.checklistToSpawn == Status.Rejected) {
            <span>
               <strong>{{ lang().ApprovalDisapprovedWarning }}</strong>
               <br />
               {{ lang().ReasonCollon }}
               <strong [limbleHtml]="item.itemResponse" class="red-color"></strong>
               <br />
               {{ lang().PleaseFixTheProblemAndThenRequestAApprovalAgain }}
            </span>
         }

         @if (
            (taskItemsEditable() && item.checklistToSpawn == Status.NotRequested) ||
            item.checklistToSpawn == Status.Rejected
         ) {
            <span [limbleHtml]="item.itemText"></span>
            <div
               [limUiPopoverHidden]="featureCustomApprovalInstruction"
               [limUiPopoverPlacement]="'top'"
               [limUiPopoverType]="'upsell'"
               [limUiPopover]="startCustomApprovalInstructionPopover"
               class="pricing-plan-container"
            >
               <lim-ui-secondary-button
                  (click)="triggerApproval()"
                  [disabled]="!featureCustomApprovalInstruction"
                  icon="user"
               >
                  {{ item.assignTargetStr }}
               </lim-ui-secondary-button>
               @if (!featureCustomApprovalInstruction) {
                  <lim-ui-badge
                     [label]="lang().Enterprise"
                     class="pricing-plan-badge"
                     icon="stars"
                  />
               }
            </div>
         }

         <ng-template #startCustomApprovalInstructionPopover>
            <upsell-popover
               [message]="lang().FeatureCustomApprovalAdvancedPopoverMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>
         @if (
            !taskItemsEditable() &&
            (item.checklistToSpawn == Status.NotRequested ||
               item.checklistToSpawn == Status.Requested ||
               item.checklistToSpawn == Status.Rejected)
         ) {
            <span>
               <span [limbleHtml]="item.itemText"></span>
               <br class="mobileShow" />
               <lim-ui-icon icon="user" iconSize="small" />
               {{ item.assignTargetStr }}
            </span>
         }

         @if (taskItemsEditable() && item.checklistToSpawn == Status.Requested) {
            <span>
               {{ item.assignTargetStr }}
               <span [limbleHtml]="lang().WasRequestedToApprovalTheWorkAbove"></span>
            </span>
         }

         @if (item.checklistToSpawn == Status.Approved) {
            <span>
               {{ item.assignTargetStr }}
               <span [limbleHtml]="lang().SuccessfullyApproved"> </span>
            </span>
         }

         @if (
            taskItemsEditable() &&
            (item.checklistToSpawn == Status.Requested ||
               item.checklistToSpawn == Status.Approved) &&
            workOrderUser != 1
         ) {
            <div [ngClass]="{ 'greyout-chk-item': item.greyOut }">
               <signature-pad
                  (click)="handleApprovalSignatureClick()"
                  [clear]="clearSignature"
                  [disabled]="
                     sigPrep || !itemBelongsToCurrentUser || !canClearSignatures()
                  "
                  [disapprove]="disapproveApproval"
                  [limUiTooltip]="
                     !itemBelongsToCurrentUser
                        ? lang().NotAssignedToSignatureInstruction
                        : ''
                  "
                  [save]="approveApproval"
                  [showAutoSign]="true"
                  [signatureInfo]="sigInfo"
                  [signature]="sigPrep"
                  [type]="'approval'"
               />
            </div>
         }

         @if (!taskItemsEditable() && item.checklistToSpawn == Status.Approved) {
            <div>
               @if (sigPrep) {
                  <signature-pad
                     [disabled]="true"
                     [signatureInfo]="sigInfo"
                     [signature]="sigPrep"
                     [type]="'image'"
                  />
               }
            </div>
         }
      </ng-template>

      <ng-template #updatePartQtyItemInstance let-item="item">
         <span
            >{{ lang().QtyCountForPart }}:
            @if (relatedPart) {
               <a class="link-styling cursor" (click)="openPart()">
                  {{ relatedPart.partName }}
                  @if (relatedPart.partNumber) {
                     <span> - <span [limbleHtml]="relatedPart.partNumber"></span></span>
                  }
                  @if (relatedPart.partLocation) {
                     <span> - <span [limbleHtml]="relatedPart.partLocation"></span></span>
                  }
                  @if (taskItemsEditable()) {
                     <span>
                        ({{
                           manageParts.getSingleCalculatedPartInfo(relatedPart.partID)
                              ?.totalQty
                        }}
                        @if (unitOfMeasureService.isFeatureEnabled() && relatedPart) {
                           <span
                              [limUiTooltip]="
                                 relatedPart.partID | partUnitOfMeasure: 'singular'
                              "
                              >{{ relatedPart.partID | partUnitOfMeasure: "short" }}</span
                           >
                        }
                        )</span
                     >
                  }
               </a>
            }
         </span>
         <span class="input-container">
            <input
               type="number"
               class="form-control"
               [(ngModel)]="item.itemResponse"
               [disabled]="!taskItemsEditable()"
               (blur)="setResponse()"
               onclick="this.focus();"
               storeOffline
            />
            @if (unitOfMeasureService.isFeatureEnabled() && relatedPart) {
               <span
                  [limUiTooltip]="relatedPart.partID | partUnitOfMeasure: 'singular'"
                  >{{ relatedPart.partID | partUnitOfMeasure: "short" }}</span
               >
            }
         </span>
      </ng-template>

      <ng-template #instructionSetInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>
      </ng-template>

      <ng-template #verifyLocationInstance let-item="item">
         <span [limbleHtml]="item.itemText"></span>
         <div
            class="pricing-plan-container"
            [limUiPopover]="startVerifyLocationPopover"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'top'"
            [limUiPopoverHidden]="featureCustomApprovalInstruction"
         >
            <lim-ui-secondary-button
               class="blueColor cursor"
               (click)="checkMyLocation()"
               icon="locationArrow"
               [classNames]="'mobile-full-width'"
               [disabled]="!featureCustomApprovalInstruction"
            >
               {{ lang().CheckMyLocation }}
            </lim-ui-secondary-button>
            @if (!featureCustomApprovalInstruction) {
               <lim-ui-badge
                  class="pricing-plan-badge"
                  icon="stars"
                  [label]="lang().Enterprise"
               />
            }
         </div>
         <span>
            <div>
               <lim-ui-secondary-button
                  id="itemStartTask{{ item.itemID }}"
                  (click)="verifyLocation()"
                  icon="circleLocationArrowRegular"
                  [classNames]="'mobile-full-width'"
                  [disabled]="!featureCustomApprovalInstruction"
                  [limUiPopover]="startVerifyLocationPopover"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'top'"
                  [limUiPopoverHidden]="featureCustomApprovalInstruction"
               >
                  {{ lang().VerifyLocation }}
               </lim-ui-secondary-button>
            </div>

            @if (item.itemResponse) {
               <div>
                  <lim-ui-icon icon="check" iconSize="small" />
                  <span>
                     {{ lang().LocationSuccessfullyVerifiedAt }}
                     {{ item.itemResponse | betterDate: "dateTime" }}</span
                  >
               </div>
            }
         </span>
      </ng-template>
      <ng-template #startVerifyLocationPopover>
         <upsell-popover
            [message]="lang().FeatureCustomApprovalAdvancedPopoverMessage"
            [pricingLinkText]="lang().LearnMore"
         />
      </ng-template>
   </lim-ui-nested-panel>
}
