import { Injectable } from "@angular/core";
import axios from "axios/dist/axios";
import { CalendarNote } from "src/app/tasks-analytics/calendar/calendar-notes/calendar-note";
import { calendarNoteDtoSchema } from "src/app/tasks-analytics/calendar/calendar-notes/calendar-note-dto";
import { environment } from "src/environments/environment";
import { z } from "zod";

@Injectable({ providedIn: "root" })
export class CalendarNotesApi {
   public async create(
      text: string,
      noteDate: number,
      noteDateEnd: number,
      locationID: number,
      users: Array<number>,
      color: string,
   ): Promise<CalendarNote> {
      const usersStringifed = JSON.stringify(users);
      const answer = await axios.post(
         "phpscripts/checklistManager.php?action=addCalendarNote",
         {
            text: text,
            noteDate: noteDate,
            noteDateEnd: noteDateEnd,
            locationID: locationID,
            users: usersStringifed,
            color: color,
         },
      );
      if (answer.data.success !== true) {
         throw new Error("Failed to add calendar note");
      }
      const noteDto = calendarNoteDtoSchema.parse(answer.data.calendarNote);
      return CalendarNote.fromDto(noteDto);
   }

   public async delete(calendarNoteID: number): Promise<void> {
      const answer = await axios.post(
         "phpscripts/checklistManager.php?action=deleteCalendarNote",
         { calendarNoteID: calendarNoteID },
      );
      if (answer.data.success !== true) {
         throw new Error("Failed to delete calendar note");
      }
   }

   public async update(note: CalendarNote): Promise<void> {
      const usersStringified = JSON.stringify(note.getUsers());
      const answer = await axios.post(
         "phpscripts/checklistManager.php?action=updateCalendarNote",
         {
            calendarNoteID: note.id,
            text: note.getTitle(),
            noteDate: note.getStartTime().epochSeconds,
            noteDateEnd: note.getEndTime().epochSeconds,
            users: usersStringified,
            color: note.getColor(),
         },
      );
      if (answer.data.success !== true) {
         throw new Error("Failed to update calendar note");
      }
   }

   public async fetchAll(): Promise<Array<CalendarNote>> {
      const response = await axios.get(`${environment.flannelUrl}/calendarNotes`);
      const noteDtos = z.array(calendarNoteDtoSchema).parse(response.data);
      return CalendarNote.fromDtos(noteDtos);
   }
}
