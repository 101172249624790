import type { ComponentRef } from "@angular/core";
import { inject, ApplicationRef, Injectable, createComponent } from "@angular/core";
import { UpdateToastComponent } from "src/app/shared/services/update/update-toast/update-toast.component";

@Injectable({ providedIn: "root" })
export class UpdateToastService {
   private activeToast: ComponentRef<UpdateToastComponent> | undefined = undefined;
   private readonly appRef = inject(ApplicationRef);

   /** Show the toast notification if it is not already shown. */
   public show(): void {
      if (this.activeToast !== undefined) return;
      this.activeToast = createComponent(UpdateToastComponent, {
         environmentInjector: this.appRef.injector,
      });
      document.body.appendChild(this.activeToast.location.nativeElement);
      this.appRef.attachView(this.activeToast.hostView);
      this.activeToast.changeDetectorRef.detectChanges();
   }

   /** Dismiss the toast notification if it is shown. */
   public dismiss(): void {
      if (this.activeToast === undefined) return;
      this.activeToast.destroy();
      this.activeToast = undefined;
   }

   /** Check if the toast notification is currently shown. */
   public isShown(): boolean {
      return this.activeToast !== undefined;
   }
}
