import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   CheckboxComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   RadioButtonComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import moment from "moment";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { POEmailDefaults } from "src/app/purchasing/pos/poEmailDefaults/poEmailDefaults";
import { TimeOfDayPicker } from "src/app/shared/components/global/timeOfDayPickerElement/timeOfDayPicker.element.component";
import { AutoFocusDirective } from "src/app/shared/directives/autofocus/autoFocus.directive";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

export type EmailTemplateResult = {
   recipients: string;
   subject: string;
   message: string;
   users: number[];
   checklistID: number;
   date?: number;
   recurringData?: any;
   userIDs?: number[];
};

@Component({
   selector: "email-template",
   templateUrl: "./emailTemplate.modal.component.html",
   styleUrls: ["./emailTemplate.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      SecondaryButtonComponent,
      TooltipDirective,
      PanelComponent,
      DatePickerInputComponent,
      TimeOfDayPicker,
      RadioButtonComponent,
      FormsModule,
      DropdownComponent,
      DropdownTextItemComponent,
      IconComponent,
      CheckboxComponent,
      UserImage,
      ContenteditableDirective,
      AutoFocusDirective,
      LoadingAnimationComponent,
      BasicModalFooterComponent,
      OrderByPipe,
   ],
})
export class EmailTemplate implements OnInit {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public errorMsg;
   public data;
   public emailMessage;
   public emailSubject;
   public feedback;
   public pickEmployees;
   public emailTo;
   public vendor;
   public checklistID;
   public onSubmit;
   public extraToHint;
   public pickDate;
   public sendingPOToVendor;
   public attachments;
   public pickRecurringDate;
   public pickTimeOfDay;
   public recurringData;
   public dashboardReminder;
   public users;
   public date;
   public timeOfDay;
   public hour;
   public minute;
   public AMPM;
   public ordinalTranslator;
   public dayTranslator;
   public dayNumberTranslator;
   public dayOptions;
   public monthOptions;
   public monthTranslator;
   public monthOrdinalOptions;
   public updateRecip;
   public recipients;
   public task: Task | undefined;
   public allowEditTo;
   public status;
   public nextDate;
   public spinner;
   public submitButtonText: string = "";
   public locationID: number | undefined;

   private readonly modalService = inject(ModalService);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly betterDate = inject(BetterDate);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.errorMsg = false;
      this.data = this.resolve.data;
      this.emailMessage = this.data.emailMessage;
      this.emailSubject = this.data.emailSubject;
      this.feedback = false;
      this.pickEmployees = this.data.pickEmployees;
      this.emailTo = this.data.emailTo; //only used if pickEmployees is false
      this.vendor = this.data.vendor || {};
      this.locationID = this.data.locationID;
      this.checklistID = this.data.checklistID; //will get set if task gets passed in to the actually task ID
      this.onSubmit = this.data.onSubmit;
      this.extraToHint = this.data.extraToHint || false;
      this.pickDate = this.data.pickDate || false;
      this.sendingPOToVendor = this.data.sendingPOToVendor || false;
      this.attachments = this.data.attachments || [];
      this.pickRecurringDate = this.data.pickRecurringDate;
      this.pickTimeOfDay = this.data.pickTimeOfDay;
      this.recurringData = this.data.recurringData || {};
      this.dashboardReminder = this.data.dashboardReminder;
      this.users = [];

      if (this.onSubmit === "send" || this.sendingPOToVendor) {
         this.submitButtonText = this.lang().SendMessage;
      }

      if (this.onSubmit === "returnData" && !this.sendingPOToVendor) {
         this.submitButtonText = this.lang().Set;
      }

      if (this.pickDate == true || this.pickTimeOfDay) {
         this.date = new Date();
         this.timeOfDay = true;

         if (this.manageUser.getCurrentUser()?.getInfo?.customerClockFormat == "24-hr") {
            this.hour = "09";
            this.minute = "00";
            this.AMPM = "";
         } else {
            this.hour = "9";
            this.minute = "00";
            this.AMPM = "AM";
         }
      }

      // translate 'second' to '2nd'
      this.ordinalTranslator = {
         first: this.lang().firstFollowedByDayOfWeek,
         second: this.lang().secondFollowedByDayOfWeek,
         third: this.lang().thirdFollowedByDayOfWeek,
         fourth: this.lang().fourthFollowedByDayOfWeek,
      };

      // translate 'mon' to 'Monday'
      this.dayTranslator = {
         mon: this.lang().Monday,
         tue: this.lang().Tuesday,
         wed: this.lang().Wednesday,
         thu: this.lang().Thursday,
         fri: this.lang().Friday,
         sat: this.lang().Saturday,
         sun: this.lang().Sunday,
      };

      this.dayNumberTranslator = {
         1: "1st",
         2: "2nd",
         3: "3rd",
         4: "4th",
         5: "5th",
         6: "6th",
         7: "7th",
         8: "8th",
         9: "9th",
         10: "10th",
         11: "11th",
         12: "12th",
         13: "13th",
         14: "14th",
         15: "15th",
         16: "16th",
         17: "17th",
         18: "18th",
         19: "19th",
         20: "20th",
         21: "21st",
         22: "22nd",
         23: "23rd",
         24: "24th",
         25: "25th",
         26: "26th",
         27: "27th",
         28: "28th",
         29: "29th",
         30: "30th",
         31: "31st",
         first: "1st",
         second: "2nd",
         third: "3rd",
         fourth: "4th",
      };

      // allow for select list to use these day options
      this.dayOptions = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

      // allow for select list to show these months
      this.monthOptions = [
         this.lang().January,
         this.lang().February,
         this.lang().March,
         this.lang().April,
         this.lang().May,
         this.lang().June,
         this.lang().July,
         this.lang().August,
         this.lang().September,
         this.lang().October,
         this.lang().November,
         this.lang().December,
      ];

      // translate 'jan' to 'January'
      this.monthTranslator = {
         jan: this.lang().January,
         feb: this.lang().February,
         mar: this.lang().March,
         apr: this.lang().April,
         may: this.lang().May,
         jun: this.lang().June,
         jul: this.lang().July,
         aug: this.lang().August,
         sep: this.lang().September,
         oct: this.lang().October,
         nov: this.lang().November,
         dec: this.lang().December,
      };

      // allow for selected list based on these month abbr.
      this.monthOrdinalOptions = [
         "jan",
         "feb",
         "mar",
         "apr",
         "may",
         "jun",
         "jul",
         "aug",
         "sep",
         "oct",
         "nov",
         "dec",
      ];

      if (this.data.pickEmployees === true) {
         this.getEmployees(this.data.id);
      }

      if (this.data.emailTo == "" || this.data.emailTo.length > 0) {
         this.recipients = this.data.emailTo;
         this.allowEditTo = true;
      } else {
         this.allowEditTo = false;
      }

      this.status = {
         isopen: false,
      };

      if (this.pickRecurringDate) {
         if (this?.recurringData?.reminderID && this.recurringData.sendDate) {
            if (this.recurringData.sendDate == "0") {
               this.nextDate = null;
            } else {
               this.nextDate = this.betterDate.formatBetterDate(
                  moment(Number(this.recurringData.sendDate) * 1000).toDate(),
                  "date",
               );
            }
         } else {
            this.recurringData = {
               reoccurType: "disabled",
               reoccurFld1: "0",
               reoccurFld2: "0",
               reoccurFld3: "0",
               sendDate: "0",
               reminderID: 0,
               timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
         }
      }
   }

   private async getEmployees(taskID?: string): Promise<void> {
      const task = await this.manageTask.getTask(taskID ?? this.checklistID);
      if (!this.locationID) {
         if (task) {
            this.locationID = task.locationID;
            this.task = task;
         }
         if (this.dashboardReminder) {
            this.locationID = 0;
         }
      }
      const answer = this.manageUser.getUsersAndProfilesAtLocation(this.locationID);
      this.users = answer.data.users || [];
      let profileStr = "";
      for (const user of this.users) {
         user.selected = false;
      }
      this.updateRecip = () => {
         let toStr = "";
         for (const user of this.users) {
            if (user.selected == true) {
               toStr += user.userEmail;
               toStr += "; ";
            }
         }
         if (toStr == "") {
            toStr = this.lang().noUsersSelected;
         }
         this.recipients = toStr;
      };
      if (this.dashboardReminder && this.emailTo) {
         const emailArray = this.emailTo.split(";");
         for (let emailAdd of emailArray) {
            emailAdd = emailAdd.trim();
            if (emailAdd && emailAdd != "") {
               for (const key in this.users) {
                  if (emailAdd == this.users[key].userEmail) {
                     this.users[key].selected = true;
                  }
               }
            }
         }
         this.updateRecip();
      } else if (this.task?.userID && this.task?.userID > 0) {
         for (const user of this.users) {
            if (user.userID == this.task.userID) {
               user.selected = true;
               this.updateRecip();
            }
         }
      } else if (this.task) {
         this.recipients = "";
         const emailsAnswer = await this.manageProfile.getProfileEmails(
            this.task.profileID,
            this.task.locationID,
         );
         profileStr = emailsAnswer.data.profileStr;
         if (profileStr == null) {
            this.recipients = this.lang().noUsersCurrentlyHaveProfile;
         } else {
            for (const userInfo of emailsAnswer.data.userIDsAndEmails) {
               for (const user of this.users) {
                  if (userInfo.userID == user.userID) {
                     user.selected = true;
                  }
               }
            }
            this.updateRecip();
         }
      } else {
         this.recipients = "";
      }
   }

   getNumber = (num) => {
      return new Array(num);
   };

   resetRecurringData = () => {
      this.recurringData.reoccurFld1 = "0";
      this.recurringData.reoccurFld2 = "0";
      this.recurringData.reoccurFld3 = "0";
      this.recurringData.sendDate = 0;
   };

   calculateSendDate = () => {
      setTimeout(() => {
         if (this.recurringData.reoccurType === "disabled") {
            this.recurringData.sendDate = 0;
         } else if (this.recurringData.reoccurType === "week") {
            this.recurringData.sendDate = this.getWeekSendDate();
         } else if (
            this.recurringData.reoccurType === "month" &&
            this.recurringData.reoccurFld1 === "weekday"
         ) {
            this.recurringData.sendDate = this.getMonthWeekdaySendDate();
         } else if (
            this.recurringData.reoccurType === "month" &&
            this.recurringData.reoccurFld1 === "numeric"
         ) {
            this.recurringData.sendDate = this.getMonthNumericSendDate();
         }
         if (this.recurringData.sendDate == "0") {
            this.nextDate = null;
         } else {
            this.recurringData.sendDate = moment(
               Number(this.recurringData.sendDate) * 1000,
            )
               .set({
                  hour: 5,
                  minute: 30,
                  second: 0,
               })
               .format("X");

            this.nextDate = this.betterDate.formatBetterDate(
               moment(Number(this.recurringData.sendDate) * 1000).toDate(),
               "date",
            );
         }
      }, 10);
   };

   getWeekSendDate = () => {
      const dayIndex = this.dayOptions.indexOf(this.recurringData.reoccurFld1);
      const currentDayIndex = Number(moment().format("d"));
      let addAWeek = 0;
      if (dayIndex <= currentDayIndex) {
         addAWeek = 7;
      }
      return moment()
         .day(dayIndex + addAWeek)
         .format("X");
   };

   getMonthWeekdaySendDate = () => {
      const monthStartDayIndex = Number(moment().startOf("month").format("d"));
      const currentMonthDateIndex = Number(moment().format("DD"));
      const dayIndex = this.dayOptions.indexOf(this.recurringData.reoccurFld3);
      let addDays = 0;
      switch (this.recurringData.reoccurFld2) {
         case "second":
            addDays = 7;
            break;
         case "third":
            addDays = 14;
            break;
         case "fourth":
            addDays = 21;
            break;
         default:
            break;
      }
      let totalAddDays = addDays;
      if (dayIndex < monthStartDayIndex) {
         totalAddDays = totalAddDays + 7;
      }
      const tempMonthDateIndex = Number(
         moment()
            .startOf("month")
            .day(dayIndex + totalAddDays)
            .format("DD"),
      );
      if (tempMonthDateIndex <= currentMonthDateIndex) {
         const nextMonthStartDayIndex = Number(
            moment().add(1, "month").startOf("month").format("d"),
         );
         totalAddDays = addDays;
         if (dayIndex < nextMonthStartDayIndex) {
            totalAddDays = totalAddDays + 7;
         }
         return moment()
            .startOf("month")
            .add(1, "month")
            .day(dayIndex + totalAddDays)
            .format("X");
      }
      return moment()
         .startOf("month")
         .day(dayIndex + totalAddDays)
         .format("X");
   };

   getMonthNumericSendDate = () => {
      const dateIndex = Number(this.recurringData.reoccurFld2);
      const currentDateIndex = Number(moment().format("DD"));
      if (dateIndex <= currentDateIndex) {
         const endOfNextMonthDateIndex = Number(
            moment().startOf("month").add(1, "months").endOf("month").format("DD"),
         );
         if (dateIndex > endOfNextMonthDateIndex) {
            return moment().startOf("month").add(1, "months").endOf("month").format("X");
         }
         return moment().add(1, "months").date(dateIndex).format("X");
      }
      const endOfMonthDateIndex = Number(moment().endOf("month").format("DD"));
      if (dateIndex > endOfMonthDateIndex) {
         return moment().endOf("month").format("X");
      }
      return moment().date(dateIndex).format("X");
   };

   setPOEmailDefaults = () => {
      const instance = this.modalService.open(POEmailDefaults);

      this.paramsService.params = {
         modalInstance: instance,
         locationID: this.locationID,
      };

      instance.result.then((resp) => {
         if (resp) {
            // Remove default placeholders from template and display the actual info
            if (resp.emailRecips.includes("{{vendor-email}}")) {
               this.emailTo = resp.emailRecips.replace(
                  "{{vendor-email}}",
                  this.vendor.vendorEmail,
               );
            }

            // The /{{vendor-contact}}/g finds all instances of {{vendor-contact}}, rather than just the first
            if (resp.emailSubject.includes("{{vendor-contact}}")) {
               this.emailSubject = resp.emailSubject.replace(
                  /{{vendor-contact}}/g,
                  this.vendor.vendorContact,
               );
            }

            if (resp.emailMessage.includes("{{vendor-contact}}")) {
               this.emailMessage = resp.emailMessage.replace(
                  /{{vendor-contact}}/g,
                  this.vendor.vendorContact,
               );
            }
         }
      });
   };

   submit = () => {
      this.feedback = false;
      this.errorMsg = "";

      let submit = true;

      if (this.dashboardReminder && this.recurringData.reoccurType === "disabled") {
         submit = true;
      } else if (
         this.dashboardReminder &&
         this.recurringData.reoccurType !== "disabled" &&
         (this.recipients == this.lang().noUsersSelected ||
            this.recipients == this.lang().noUsersCurrentlyHaveProfile)
      ) {
         this.alertService.addAlert(this.lang().error1EmailTemplate, "warning", 6000);
         submit = false;
      } else if (this.recipients?.length > 800) {
         this.alertService.addAlert(this.lang().TooManyEmailsMsg, "warning", 6000);
         submit = false;
      } else if (this.recipients == this.lang().noUsersSelected) {
         this.alertService.addAlert(this.lang().error1EmailTemplate, "warning", 6000);
         submit = false;
      } else if (
         (!this.recipients || !this.validateEmailStr(this.recipients)) &&
         this.recipients.trim() !== ""
      ) {
         this.alertService.addAlert(
            this.lang().vendorEmailValidationFail,
            "warning",
            6000,
         );
         submit = false;
      }
      if (this.errorMsg !== "") {
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
      }

      let emailMessage = this.emailMessage;
      emailMessage = emailMessage.toString();
      emailMessage = emailMessage.replace(/<div>/g, "<br />");
      emailMessage = emailMessage.replace(/<\/div>/g, "");

      const subject = this.emailSubject;

      if (subject.length > 997) {
         this.alertService.addAlert(this.lang().error2EmailTemplate, "warning", 6000);
         submit = false;
      }

      if (submit) {
         const users: any = [];

         if (this.data.onSubmit === "send") {
            this.spinner = true;

            for (const user of this.users) {
               if (user.selected == true) {
                  users.push(user.userID);
               }
            }

            this.manageTask
               .notify(this.recipients, subject, emailMessage, users, this.checklistID)
               .then((answer) => {
                  this.spinner = false;
                  if (answer.data.success == true) {
                     this.errorMsg = false;
                     if (this.data.emailTo == false) {
                        this.feedback = this.lang().success1EmailTemplate;
                     } else {
                        this.feedback = this.lang().success2EmailTemplate;
                     }
                     if (this.feedback !== "") {
                        this.alertService.addAlert(this.feedback, "success", 2000);
                     }
                  } else {
                     this.errorMsg = answer.data.error;
                     if (this.errorMsg !== "") {
                        this.alertService.addAlert(this.errorMsg, "warning", 6000);
                     }
                  }
               });
         } else if (this.data.onSubmit === "returnData") {
            if (subject.length < 3) {
               this.alertService.addAlert(
                  this.lang().PleaseEnterASubject,
                  "warning",
                  6000,
               );
               if (this.errorMsg !== "") {
                  this.alertService.addAlert(this.errorMsg, "warning", 6000);
               }
               return;
            }
            if (emailMessage.length < 3) {
               this.alertService.addAlert(
                  this.lang().PleaseEnterAnEmailMessage,
                  "warning",
                  6000,
               );
               return;
            }
            const result: EmailTemplateResult = {
               recipients: this.recipients,
               subject: subject,
               message: emailMessage,
               users: users,
               checklistID: this.checklistID,
            };

            if (this.pickDate == true) {
               const date = new Date(this.date);

               if (this.timeOfDay == true) {
                  if (this.AMPM === "PM" && this.hour != 12) {
                     this.hour = `${Number(this.hour) + 12}`;
                  }

                  if (this.AMPM === "AM" && this.hour == "12") {
                     this.hour = 0;
                  }
                  date.setHours(this.hour);
                  date.setMinutes(this.minute);
                  date.setSeconds(0);
               } else if (this.data.setNoTimeOfDayOffset == true) {
                  date.setHours(2);
                  date.setMinutes(0);
                  date.setSeconds(1);
               }

               result.date = Math.round(date.getTime() / 1000);
            }

            if (this.pickRecurringDate == true) {
               if (this.recurringData.reoccurType === "disabled") {
                  this.recurringData.sendDate = 0;
               }
               result.recurringData = this.recurringData;
            }

            if (this.dashboardReminder) {
               result.userIDs = [];
               for (const user of this.users) {
                  if (user.selected == true) {
                     result.userIDs.push(user.userID);
                  }
               }
            }

            this.modalInstance.close(result);
         }
      }
   };

   private validateEmailStr(emailStr) {
      if (
         !emailStr ||
         emailStr.trim() === "" ||
         emailStr.includes(";;") ||
         emailStr.includes(",,") ||
         emailStr.includes(",;") ||
         emailStr.includes(";,")
      ) {
         return false;
      }
      const emailArr = emailStr.split(/[;,]+/);
      if (emailArr[emailArr.length - 1] === "" || emailArr[emailArr.length - 1] === " ") {
         emailArr.pop();
      }
      for (const email of emailArr) {
         const isValid = this.validateEmail(email.trim());
         if (!isValid) {
            return false;
         }
      }
      return true;
   }

   validateEmail = (email) => {
      const regex =
         /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,63}(?:\.[a-z]{2})?)$/i;
      return regex.test(email);
   };

   close = () => {
      this.modalInstance.close(0);
   };
}
