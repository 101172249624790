import { NgClass, NgStyle } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, forwardRef, computed } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   CheckboxComponent,
   CheckboxSelectionPanelComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   SearchBoxComponent,
   SelectionPanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import type { BulkPrintTasksSettings } from "src/app/shared/types/general.types";
import { Lookup } from "src/app/shared/utils/lookup";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "pick-tasks",
   templateUrl: "./pickTasks.modal.component.html",
   styleUrls: ["./pickTasks.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      CheckboxSelectionPanelComponent,
      CheckboxComponent,
      SelectionPanelComponent,
      SearchBoxComponent,
      NoSearchResults,
      IconComponent,
      NgClass,
      NgStyle,
      LimbleHtmlDirective,
      forwardRef(() => AssetParentList),
      TooltipDirective,
      BasicModalFooterComponent,
   ],
})
export class PickTasks implements OnInit, OnDestroy {
   public message;
   public title;
   public errorMsg;
   public data;
   public multiLocation;
   public tasks: TaskLookup = new Lookup("checklistID");
   public locations: Array<any>;
   public searchBar;
   public noSearchResults;
   public resolve;
   public modalInstance;
   private priorityListSub;
   public priorityList;
   public priorityListIndex;
   public pickInformationToInclude: boolean;
   public informationToInclude: BulkPrintTasksSettings | undefined;
   public checkboxInformationError: boolean;
   public chooseTaskError: boolean;
   public bulkPrintSettingsLanguageMap: Map<string, string>;
   public assets: Lookup<"assetID", Asset>;
   public sortedProperties: string[] = [];
   public tasksExtraInfo: Map<
      number,
      {
         selected: boolean;
         checklistDueDateDisplay: string;
         taskIcon: Aliases;
         days: number;
         daysMsg: string;
         priorityLevel: number;
         displayName: string;
      }
   > = new Map();
   public unfilteredTasks: TaskLookup = new Lookup("checklistID");
   public initialLoadComplete: boolean = false;

   private readonly modalService = inject(ModalService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageUser = inject(ManageUser);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageParts = inject(ManageParts);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.assets = this.manageAsset.getAssets();
      this.locations = [];
      this.pickInformationToInclude = false;
      this.checkboxInformationError = false;
      this.chooseTaskError = false;

      // Set the language objects to the db column names. We do this so we can show the correct lang objects in a loop
      this.bulkPrintSettingsLanguageMap = new Map([
         ["status", this.lang().Status],
         ["priority", this.lang().Priority],
         ["type", this.lang().Type],
         ["assignment", this.lang().Assignment],
         ["location", this.lang().Location],
         ["asset", this.lang().Asset],
         ["dueDate", this.lang().DueDate],
         ["downtime", this.lang().Downtime],
         ["description", this.lang().Description],
         ["instructions", this.lang().Instructions],
         ["completionNotes", this.lang().CompletionNotes],
         ["comments", this.lang().Comments],
         ["parts", this.lang().Parts],
         ["invoices", this.lang().Invoices],
         ["completedBy", this.lang().CompletedBy],
         ["completedDate", this.lang().CompletedDate],
         ["assetInformation", this.lang().AssetInformation],
         ["timeSpent", this.lang().TimeSpent],
      ]);
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.errorMsg = false;
      this.data = this.resolve.data;

      if (this.data.pickInformationToInclude) {
         this.pickInformationToInclude = this.data.pickInformationToInclude;
      }

      this.multiLocation = false;
      this.resolve.taskIDs.forEach((taskID) => {
         const task = this.manageTask.getTaskLocalLookup(taskID);
         if (!task || (task.checklistTemplate && task.checklistTemplate > 0)) return;
         this.unfilteredTasks.set(taskID, task);
         const checklistDueDateDisplay = this.manageTask.getTaskDueDateDisplay(task);
         const taskIcon = this.taskTypeService.getTaskTypeIcon(task);
         const { days, daysMsg } = this.manageTask.getDaysForTask(task) ?? {
            days: 0,
            daysMsg: "",
         };
         const priorityLevel = this.manageTask.getPriorityInfo(
            task.priorityID,
         ).priorityLevel;
         const { displayName } = this.manageTask.getTaskAssignmentInfo(task);
         this.tasksExtraInfo.set(taskID, {
            selected: false,
            checklistDueDateDisplay,
            taskIcon,
            days,
            daysMsg,
            priorityLevel,
            displayName,
         });
      });
      const filteredTasks = this.unfilteredTasks.orderBy("checklistName");
      this.tasks = filteredTasks;

      const locationsObj = {};

      for (const task of this.tasks) {
         locationsObj[task.locationID] = task.locationID;
      }

      const locations: any = [];
      for (const key in locationsObj) {
         if (
            typeof this.manageLocation.getLocationsIndex()[locationsObj[key]] !==
            "undefined"
         ) {
            locations.push(this.manageLocation.getLocationsIndex()[locationsObj[key]]);
         }
      }

      this.generateLocationData(locations);

      this.locations = orderBy(this.locations, "locationName");

      this.setTasks();

      this.priorityListSub = this.manageObservables.setSubscription(
         "priorityList",
         () => {
            this.priorityList = this.managePriority.getPriorityList();
            this.priorityListIndex = this.managePriority.getPriorityListIndex();
         },
      );

      this.initializeUserPreferences();
   }

   private async initializeUserPreferences(): Promise<void> {
      const defaultSettings = {
         status: 1,
         priority: 1,
         type: 1,
         assignment: 1,
         location: 1,
         asset: 1,
         dueDate: 1,
         downtime: 1,
         description: 1,
         instructions: 1,
         completionNotes: 1,
         comments: 1,
         parts: 1,
         invoices: 1,
         completedBy: 1,
         completedDate: 1,
         assetInformation: 1,
         timeSpent: 1,
      };

      if (this.pickInformationToInclude) {
         this.informationToInclude =
            (await this.manageUser.getBulkPrintTasksSettings()) ?? defaultSettings;
         this.sortedProperties = Object.keys(this.informationToInclude).sort();
      }
   }

   public generateLocationData(locations): void {
      for (const location of locations) {
         this.locations.push({
            locationName: location.locationName,
            locationID: location.locationID,
         });
      }
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeSubscription(this.priorityListSub);
   }

   public updateSearch(): void {
      this.noSearchResults = false;
      if (this.searchBar && this.searchBar.length > 0) {
         const filterInfo = this.manageFilters.filterTasksToSearch(
            this.tasks,
            this.searchBar,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         );
         this.tasks = filterInfo.tasks;
         if (this.tasks.size === 0) {
            this.noSearchResults = true;
         }
      } else {
         this.tasks = this.unfilteredTasks;
      }

      this.setTasks();
   }

   public setTasks(): void {
      const obj = {};

      for (const location of this.locations) {
         location.tasks = [];
         obj[location.locationID] = location;
      }

      for (const task of this.tasks) {
         if (obj[task.locationID]) {
            obj[task.locationID].tasks.push(task);
         }
         if (this.initialLoadComplete === false) {
            const taskExtraInfo = this.tasksExtraInfo.get(task.checklistID);
            if (!taskExtraInfo) continue;
            this.tasksExtraInfo.set(task.checklistID, {
               ...taskExtraInfo,
               selected: false,
            });
         }
      }

      this.initialLoadComplete = true;

      for (const location of this.locations) {
         location.hide = false;
         location.showKids = false;
         if (location.tasks === undefined || location.tasks.length == 0) {
            location.hide = true;
            location.showKids = false;
         }
      }

      if (this.searchBar && this.searchBar.length > 0) {
         for (const location of this.locations) {
            location.showKids = true;
         }
      } else {
         this.locations[0].showKids = true;
      }

      let count = 0;
      for (const location of this.locations) {
         if (location.tasks.length > 0) {
            count++;
         }
      }
      if (count > 1) {
         this.multiLocation = true;
      }
   }

   public focusTask(taskToFocus): void {
      if (this.data.selectOne == true) {
         for (const task of this.tasks) {
            if (task.checklistID !== taskToFocus.checklistID) {
               const taskExtraInfo = this.tasksExtraInfo.get(task.checklistID);
               if (!taskExtraInfo) continue;
               this.tasksExtraInfo.set(task.checklistID, {
                  ...taskExtraInfo,
                  selected: false,
               });
            }
         }
      }

      if (this.data.selectOne == true) {
         if (this.tasksExtraInfo.get(taskToFocus.checklistID)?.selected === true) {
            this.submit();
         }
      }

      const taskToFocusSelectedExists = this.tasksExtraInfo.get(taskToFocus.checklistID);
      if (taskToFocusSelectedExists) {
         this.tasksExtraInfo.set(taskToFocus.checklistID, {
            ...taskToFocusSelectedExists,
            selected: !taskToFocusSelectedExists.selected,
         });
      }

      this.errorMsg = false;
   }

   public clearSelection(): void {
      for (const task of this.tasks) {
         const taskExtraInfo = this.tasksExtraInfo.get(task.checklistID);
         if (!taskExtraInfo) continue;
         this.tasksExtraInfo.set(task.checklistID, { ...taskExtraInfo, selected: false });
      }
   }

   public markAllSelection(): void {
      for (const task of this.tasks) {
         const taskExtraInfo = this.tasksExtraInfo.get(task.checklistID);
         if (!taskExtraInfo) continue;
         this.tasksExtraInfo.set(task.checklistID, { ...taskExtraInfo, selected: true });
      }

      for (const location of this.locations) {
         location.showKids = true;
      }
   }

   public async updateUserSettings(): Promise<void> {
      if (!this.informationToInclude) {
         return;
      }
      const answer = await this.manageUser.updateBulkPrintTasksSettings(
         this.informationToInclude,
      );
      if (answer.status === 200) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   public popTask(task): void {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: false,
               preview: true,
            },
         },
      };
   }

   public close(): void {
      this.modalInstance.close(0);
   }

   public submit(): void {
      this.errorMsg = false;
      this.checkboxInformationError = false;
      this.chooseTaskError = false;

      if (this.pickInformationToInclude && this.informationToInclude) {
         // Check if there is at least one checkbox checked
         const atLeastOneCheckboxIsChecked = Object.values(
            this.informationToInclude,
         ).some((setting) => setting === 1);

         if (!atLeastOneCheckboxIsChecked) {
            this.errorMsg = this.lang().PleaseSelectAtLeastOneInformationFieldPrint;
            this.alertService.addAlert(this.errorMsg, "warning", 3000);

            this.checkboxInformationError = true;
            return;
         }
      }

      this.checkboxInformationError = false;

      // Create a Lookup of the selected tasks
      const lookupOfTasks: TaskLookup = new Lookup("checklistID");

      for (const task of this.tasks) {
         if (this.tasksExtraInfo.get(task.checklistID)?.selected == true) {
            lookupOfTasks.set(task.checklistID, task);
         }
      }

      // Make sure the user selected at least one task
      if (lookupOfTasks.size === 0) {
         this.errorMsg = this.lang().PleaseSelectAtLeastOneTask;
         this.alertService.addAlert(this.errorMsg, "warning", 3000);
         this.chooseTaskError = true;
         return;
      }

      this.chooseTaskError = false;

      this.modalInstance.close({
         lookupOfTasks: lookupOfTasks,
         informationToInclude: this.informationToInclude,
      });
   }
}
