<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message-area" [limbleHtml]="message"></span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="deselect-all">
            <a (click)="deselectAll()">{{ lang().DeselectAll }}</a>
         </div>

         <div class="seasons-list">
            @for (season of defaultHolidayList; track season) {
               <div
                  (click)="focusSeason(season)"
                  class="lim-ui-list-item lim-ui-selectable-list-item"
                  [ngClass]="{
                     'lim-ui-selected-list-item': season.selected,
                     'lim-ui-disabled-list-item': season.alreadyChosen,
                  }"
               >
                  @if (season.selected) {
                     <lim-ui-icon icon="check" iconSize="small" iconColor="primary" />
                  }

                  @if (!season.alreadyChosen) {
                     <span>{{ season.name }} </span>
                  }
                  @if (season.alreadyChosen) {
                     <span
                        ><b>{{ season.name }} - {{ lang().AlreadyChosen }}</b>
                     </span>
                  }
               </div>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Select"
      (successClick)="selectSeasons()"
   />
</lim-ui-modal>
