import type { OnInit } from "@angular/core";
import { Component, computed, inject } from "@angular/core";
import type { SafeResourceUrl } from "@angular/platform-browser";
import { DomSanitizer } from "@angular/platform-browser";
import {
   BasicModalHeaderComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "video-player-modal",
   templateUrl: "./video-player-modal.component.html",
   styleUrls: ["./video-player-modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
   ],
})
export class VideoPlayerModal implements OnInit {
   protected title: string = "";
   protected src: SafeResourceUrl = "";
   private resolve: any;
   private modalInstance: any;

   private readonly paramsService = inject(ParamsService);
   private readonly sanitizer = inject(DomSanitizer);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;

      if (data.title) {
         this.title = data.title;
      }

      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(data.src);
   }

   public close(): void {
      this.modalInstance.close();
   }
}
