<ng-container *translate="let t">
   @if (cell?.isCompleted) {
      <span class="due-date-sub-container allowOffline" (click)="changeDueDate($event)">
         <!-- The date -->
         <span class="date-text" [ngClass]="{ cursor: canChangeDueDate }">{{
            cell?.checklistDueDateDisplay
         }}</span>

         <span class="days-due-tag">
            @if (tagDetails?.value) {
               <e-tag
                  [rounded]="true"
                  [severity]="tagDetails?.severity"
                  [tooltip]="tagDetails?.tooltip"
                  [value]="tagDetails?.value ?? ''"
               />
            }
         </span>

         @if (printOnly) {
            <lim-ui-minimal-icon-button
               [superMinimal]="true"
               icon="envelopeRegular"
               (click)="emailReminder($event)"
               limUiTooltip="{{ t('sendReminder') }}"
               placement="top"
               class="lim-ui-show-on-desktop"
            />

            @if (canDeferTask) {
               <lim-ui-minimal-icon-button
                  icon="rotateRight"
                  (click)="deferTask($event)"
                  limUiTooltip="{{ t('delay') }}"
                  placement="top"
                  [superMinimal]="true"
               />
            }
         }

         @if (estimatedTime > 0) {
            <span placement="top" limUiTooltip="{{ t('estTime') }}">
               {{ estimatedTime / 60 / 60 | betterNumber: "1.2-2" }}
               {{ t("hrs") }}
            </span>
         }
      </span>
   }
</ng-container>
