import { CommonModule } from "@angular/common";
import {
   Component,
   computed,
   inject,
   input,
   Input,
   type OnDestroy,
   type OnInit,
} from "@angular/core";
import { IconComponent, LimbleHtmlDirective, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { AssetHierarchyListItemComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/asset-hierarchy-list-item/asset-hierarchy-list-item.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { CredService } from "src/app/users/services//creds/cred.service";

@Component({
   selector: "task-minimal-name-cell",
   imports: [
      CommonModule,
      IconComponent,
      LimbleHtmlDirective,
      TooltipDirective,
      AssetHierarchyListItemComponent,
   ],
   templateUrl: "./task-minimal-name-cell.component.html",
   styleUrls: ["./task-minimal-name-cell.component.scss"],
})
export class TaskMinimalNameCellComponent implements OnInit, OnDestroy {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;
   @Input() public dataLogSection!: string;

   @Input() public searchHint: string | undefined = undefined;
   public readonly showAsset = input<boolean>(true);

   public dropdownClicked = false;

   public isAllowedToDelete: boolean = false;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public statusListSub;
   public statusList: any = [];
   public statusListIndex = {};

   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageStatus = inject(ManageStatus);
   private readonly manageObservables = inject(ManageObservables);
   public readonly manageAsset = inject(ManageAsset);
   protected readonly facade = inject(TasksFacadeService);

   public isSuperUser = this.credService.checkCredGlobal(30);
   protected isInModal = this.facade.getIsInModal();

   public ngOnInit(): void {
      this.statusListSub = this.manageObservables.setSubscription("statusList", () => {
         this.statusList = [];
         setTimeout(() => {
            this.statusList = this.manageStatus.getStatusList();
            this.statusListIndex = this.manageStatus.getStatusListIndex();
         }, 1);
      });

      this.isAllowedToDelete = this.facade.isAllowedToDelete(this.task);
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeManySubscriptions([this.statusListSub]);
   }
   public async onViewTask(): Promise<void> {
      await this.facade.viewTask(this.task.checklistID);
   }

   public completedTaskWarning(): void {
      this.alertService.addAlert(this.lang().completedTaskWarning, "warning", 10000);
   }

   public deleteTask(): void {
      this.facade.deleteTask(this.task);
   }

   public onAssetClick(): void {
      //This even should open the Asset on desktop and the Task on mobile
      //The below combined with a flag passed into asset-hierarchy-list-item ensures that happens
      if (this.facade.isMobile) {
         this.facade.viewTask(this.task.checklistID);
      }
   }
}
