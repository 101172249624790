import { Component, inject, type Signal } from "@angular/core";
import {
   BasicModalHeaderComponent,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalFooterComponent,
   type ModalResult,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import {
   ApplyTemplateStore,
   type ApplyTemplateLocation,
} from "src/app/assets/services/apply-asset-templates/apply-template.store";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";

@Component({
   selector: "continue-without-merging-modal",
   standalone: true,
   imports: [
      BasicModalHeaderComponent,
      TranslateDirective,
      ModalComponent,
      ModalBodyComponent,
      PanelComponent,
      ModalFooterComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
   ],
   templateUrl: "./continue-without-merging-modal.component.html",
})
export class ContinueWithoutMergingModalComponent
   implements ModalResult<boolean | undefined>
{
   private readonly applyTemplateState = inject(ApplyTemplateStore);
   protected readonly selectedLocation: Signal<ApplyTemplateLocation> =
      this.applyTemplateState.selectedLocation;

   public readonly modalRef: LimUiModalRef<
      ContinueWithoutMergingModalComponent,
      boolean | undefined
   > = inject(LimUiModalRef);

   protected continue() {
      this.modalRef.close(true);
   }

   protected close() {
      this.modalRef.close(false);
   }
}
