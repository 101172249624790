import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, ChangeDetectorRef, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   AlertComponent,
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownResultsPerPageComponent,
   DropdownTextItemComponent,
   FilterInputComponent,
   FilterInputWrapperComponent,
   FiltersWrapperComponent,
   IconButtonComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   PaginationComponent,
   PanelComponent,
   PrimaryButtonComponent,
   RowHoverButtonsComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { CommentFiles } from "src/app/files/components/commentFiles/commentFiles.element.component";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { EscapePipe } from "src/app/shared/pipes/customEscape.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { EditVendorLogItem } from "src/app/vendors/components/editVendorLogItemModal/editVendorLogItem.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import { ManageVendorLogs } from "src/app/vendors/services/manageVendorLogs";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "vendor-log",
   templateUrl: "./vendorLog.wrapper.component.html",
   styleUrls: ["./vendorLog.wrapper.component.scss"],
   imports: [
      PanelComponent,
      SearchBoxComponent,
      IconComponent,
      FiltersWrapperComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownClearFilterItemComponent,
      FilterInputWrapperComponent,
      FilterInputComponent,
      SortColumn,
      NoSearchResults,
      NgClass,
      TooltipDirective,
      LimbleHtmlDirective,
      FileListItem,
      ViewImage,
      RowHoverButtonsComponent,
      IconButtonComponent,
      FormsModule,
      CommentFiles,
      PrimaryButtonComponent,
      AlertComponent,
      PaginationComponent,
      DropdownResultsPerPageComponent,
      BetterDatePipe,
      EscapePipe,
      SliceArray,
   ],
})
export class VendorLog implements OnInit {
   @Input() public restrict;
   @Input() public vendorID: number | undefined;

   public CID;
   public orderBy;
   public logItems;
   public workOrderChoiceState;
   public logTasks;
   public totalLogs;
   public fileUploaded;
   public newEntryViewable;
   public addEntryDate;
   public errorMsg;
   public successMsg;
   public sourceState;
   public dateState;
   public dateDuration;
   public manageVendorLogCred;
   public sourceID;
   public workOrderChoice;
   public workOrderChoiceBatchID;
   public workOrderChoiceVendorID;
   public page = 1;
   public itemsPerPage = 10;
   public searchBar;
   public noSearchResults;
   public timeout;
   public logs;
   public logsLoaded: Promise<unknown> | undefined;
   protected logUploadObj;
   protected fileQue;
   protected pictureQue;
   protected eyeSlashIcon: Aliases = "eyeSlashRegular";
   protected eyeIcon: Aliases = "eyeRegular";

   public vendor: Vendor | undefined;
   public vendorDisplayInfo: {
      entryText: string;
   } = { entryText: "" };

   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageFiles = inject(ManageFiles);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageVendorLogs = inject(ManageVendorLogs);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (!this.vendorID) {
         return;
      }
      this.logsLoaded = this.getLogs(this.vendorID);

      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.orderBy = "-logDate";

      if (!this.vendorID) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }
      this.vendor = this.manageVendor.getVendor(this.vendorID);
      if (!this.vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }

      this.vendor = this.manageVendor.getVendor(this.vendorID);
      if (!this.vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }

      this.workOrderChoiceState = false;
      this.manageVendorLogCred = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ManageVendorLogs,
      );

      this.logUploadObj = {};

      this.logUploadObj.primaryID = "fileID";
      this.logUploadObj.posturl = `phpscripts/manageVendorLogs.php?action=makeFile&locationID=${this.vendor.locationID}&vendorID=${this.vendor.vendorID}`;

      this.logUploadObj.maxFiles = 5;
      this.logUploadObj.uploading = false;
      this.logUploadObj.viewOnly = false;
      this.logUploadObj.deleteData = {};

      this.logUploadObj.deleteCall = async (data) => {
         return this.manageVendorLogs.deleteVendorLogFile(data);
      };
      this.logUploadObj.deleteSuccess = (answer) => {
         if (answer.data.success == true) {
            for (const key in this.fileQue) {
               if (this.fileQue[key].fileID == JSON.parse(answer.config.data).fileID) {
                  this.fileQue.splice(key, 1);
               }
            }
            for (const key in this.pictureQue) {
               if (this.pictureQue[key].fileID == JSON.parse(answer.config.data).fileID) {
                  this.pictureQue.splice(key, 1);
               }
            }
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      };

      this.logUploadObj.uploadComplete = (data) => {
         assert(this.vendor);
         if (data.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            this.fileUploaded = true;
            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );
         }

         if (data.fileID != 0) {
            const regex = /(?:\.([^.]+))?$/;
            data.row.fileExt = regex.exec(data.row.fileName);
            if (data.row.fileExt.length > 1) {
               data.row.fileExt = data.row.fileExt[1].toLowerCase();
            }

            data.row.getURL = `viewFile.php?f=upload-${this.CID}/vendors/${this.vendor.locationID}/${data.row.vendorID}/logs/${data.fileName}`;
            if (this.manageFiles.checkImageExt(data.fileName)) {
               this.pictureQue = this.pictureQue || [];
               this.pictureQue = [...this.pictureQue, data.row];
            } else {
               this.fileQue = this.fileQue || [];
               this.fileQue = [...this.fileQue, data.row];
            }
         } else if (data.fileID == 0) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         }
      };

      this.newEntryViewable = 1;
      this.addEntryDate = new Date();
      this.sourceState = false;
      this.dateState = false;
      this.buildData();
   }

   public async getLogs(vendorID: number) {
      return this.manageVendorLogs.getLogs(vendorID).then((answer) => {
         const logs = answer.data.vendorLogs || [];
         const files = answer.data.logFiles || [];

         const vendors = this.manageVendor.getVendors();
         //regex for stripping extension.
         const regex = /(?:\.([^.]+))?$/;
         //this mess right here adds the log files to the actual logs.  It also adds the Name for use in asset group leaders.
         for (const key in logs) {
            logs[key].logFiles = [];
            logs[key].logPictures = [];
            if (vendors[logs[key].vendorID]) {
               logs[key].vendorName = vendors[logs[key].vendorID].vendorName;
            }
            for (const key2 in files) {
               files[key2].fileExt = regex.exec(files[key2].fileName);
               if (files[key2].fileExt.length > 1) {
                  files[key2].fileExt = files[key2].fileExt[1].toLowerCase();
               }
               if (answer.data.logFiles[key2].vendorLogID == logs[key].vendorLogID) {
                  if (this.manageFiles.checkDocumentExts(files[key2].fileName)) {
                     logs[key].logFiles.push(files[key2]);
                  } else if (this.manageFiles.checkImageExt(files[key2].fileName)) {
                     logs[key].logPictures.push(files[key2]);
                  }
               }
            }
         }
         this.logs = logs;
      });
   }

   pageChanged = () => {
      this.ref.detectChanges();
   };

   private async buildData() {
      await this.logsLoaded;
      this.logTasks = [];
      const logTasks = {};

      let tempLogs = this.logs;
      this.totalLogs = this.logs?.length;

      if (this.sourceState) {
         tempLogs = this.manageFilters.filterLogsBySourceID(tempLogs, {
            sourceID: Number(this.sourceID),
            type: "vendors",
         });
      }
      if (this.dateState) {
         tempLogs = this.manageFilters.filterLogsToLastXMilliseconds(
            tempLogs,
            this.dateDuration,
         );
      }

      if (this.searchBar && this.searchBar.length > 0) {
         tempLogs = tempLogs.filter((log) =>
            this.manageFilters.searchObjValuesRecursivelyForString(log, this.searchBar),
         );
      }

      this.noSearchResults = false;
      if (tempLogs?.length == 0 && this.totalLogs != 0) {
         this.noSearchResults = true;
      }

      this.logItems = tempLogs || [];
      for (const logItem of this.logItems) {
         if (logItem.logDetails.checklistID > 0 && logItem.logDetails.itemResponse) {
            logItem.logDetails.itemBatchID = logItem.itemBatchID;
            logItem.logDetails.vendorID = logItem.vendorID;

            //give a unique index so that it removes duplicates based on the itemBatchID and the vendorID
            logTasks[`${logItem.logDetails.itemBatchID} ${logItem.logDetails.vendorID}`] =
               logItem.logDetails;
         }

         this.manageTask.setInternalTaskUserNameLogDefaults(logItem);
      }

      for (const key in logTasks) {
         this.logTasks.push(logTasks[key]);
      }

      for (const key in this.logItems) {
         if (this.logItems[key].logDetails.checklistID > 0) {
            if (this.logItems[key].logDetails.itemText) {
               //only for log items that are recorded from a checklist
               if (
                  this.logItems[key].logDetails.itemResponse == "" ||
                  this.logItems[key].logDetails.itemResponse == null
               ) {
                  this.logItems.splice(key, 1); //remove any entries that have no value.
               }
            }
         }
      }

      for (const key in this.logItems) {
         if (this.logItems[key].sourceID == 2) {
            //we only show or hide this for manual entrys for now.
            if (!this.manageVendorLogCred && this.logItems[key].logViewable == 0) {
               //theydon't have permissions to see everything and this log is marked as not viewable
               this.logItems.splice(key, 1);
            }
         }

         if (this.logItems[key].logPictures.length > 0) {
            this.logItems[key].logPictures.forEach((pic) => {
               this.addURL(pic, this.logItems[key]);
            });
         }
      }

      this.logItems = orderBy(this.logItems, this.orderBy);

      this.page = this.manageFilters.checkPagination(
         this.page,
         this.itemsPerPage,
         this.logItems.length,
      );
   }

   showHideNewEntry = () => {
      if (this.newEntryViewable == 1) {
         this.newEntryViewable = 0;
      } else {
         this.newEntryViewable = 1;
      }
   };

   deleteLogEntry = (log) => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().DeleteLogMsg}<br /><br />${log.logDetails}.`,
            title: this.lang().DeleteLog,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.manageVendorLogs.deleteLogEntry(log).then((answer) => {
               if (answer.data.success == true) {
                  const index = this.logs.indexOf(log);
                  this.logs.splice(index, 1);
                  this.buildData();
                  this.alertService.addAlert(
                     this.lang().DeleteLogSuccessMsg,
                     "success",
                     2000,
                  );
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               }
            });
         }
      });
   };

   setAddEntryDate = () => {
      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PleaseSelectADateAndClickChange,
            title: this.lang().ChangeDate,
            buttonText: this.lang().Change,
            currentDate: this.addEntryDate.getTime() / 1000,
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: false,
            },
         },
      };

      instance.result.then((result) => {
         if (result.date) {
            this.addEntryDate = new Date(result.date);
         }
      });
   };

   addEntry = () => {
      this.errorMsg = false;

      const files: any = [];
      this.fileQue = this.fileQue || [];
      this.pictureQue = this.pictureQue || [];

      for (const file of this.fileQue) {
         files.push(file.fileID);
      }

      for (const pic of this.pictureQue) {
         files.push(pic.fileID);
      }

      let valid = true;
      if (this.logUploadObj.uploading) {
         this.errorMsg = this.lang().pleaseWaitForFileToLoad;
         valid = false;
      }

      if (this.vendorDisplayInfo.entryText === undefined && files.length == 0) {
         this.errorMsg = this.lang().PleaseEnterAMessage;
         valid = false;
      } else if (this.vendorDisplayInfo.entryText == "" && files.length == 0) {
         this.errorMsg = this.lang().PleaseEnterAMessage;
         valid = false;
      }
      if (valid) {
         this.manageVendorLogs
            .addEntry(
               this.vendorDisplayInfo.entryText,
               this.vendor,
               this.addEntryDate,
               files,
               this.newEntryViewable,
            )
            .then((answer) => {
               if (answer.data.success == true) {
                  this.addEntryDate = new Date();
                  const vendors = this.manageVendor.getVendors();

                  answer.data.log.logFiles = [];
                  for (const file of this.fileQue) {
                     answer.data.log.logFiles.push(file);
                  }
                  this.fileQue = [];

                  answer.data.log.logPictures = [];
                  for (const pic of this.pictureQue) {
                     answer.data.log.logPictures.push(pic);
                  }
                  this.pictureQue = [];
                  answer.data.log.vendorName = vendors.get(
                     answer.data.log.vendorID,
                  )?.vendorName;
                  this.logs.push(answer.data.log);

                  this.vendorDisplayInfo.entryText = "";
                  this.buildData();

                  this.successMsg = this.lang().YouHaveSuccessfullyAddedAnEntry;
                  setTimeout(() => {
                     this.successMsg = "";
                  }, 3000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               }
            });
      }
   };

   public async popTask(checklistID: number): Promise<void> {
      const task = await this.manageTask.getTask(checklistID);
      if (!task) {
         return;
      }
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: true,
            },
         },
      };
   }

   filterSource = (sourceID) => {
      this.sourceState = true;
      this.sourceID = sourceID;
      this.workOrderChoiceState = false;
      this.workOrderChoice = null;
      this.workOrderChoiceBatchID = null;
      this.workOrderChoiceVendorID = null;
      this.buildData();
   };

   clearSource = () => {
      this.sourceState = false;
      this.workOrderChoiceState = false;
      this.workOrderChoice = null;
      this.workOrderChoiceBatchID = null;
      this.workOrderChoiceVendorID = null;
      this.buildData();
   };

   filterDate = (duration) => {
      this.dateState = true;
      this.dateDuration = duration;
      this.buildData();
   };

   clearDateFilter = () => {
      this.dateState = false;
      this.buildData();
   };

   hideShowLogEntry = (log) => {
      if (this.manageVendorLogCred == false) {
         this.alertService.addAlert(this.lang().cred137Fail, "warning", 10000);
         return;
      }

      if (log.logViewable == 1) {
         log.logViewable = 0;
      } else {
         log.logViewable = 1;
      }

      this.manageVendorLogs.showHideLogEntry(log).then((answer) => {
         if (answer.data.success == true) {
            if (log.logViewable == 1) {
               this.alertService.addAlert(
                  this.lang().ThisLogIsNowVisibleForEverUser,
                  "success",
                  5000,
               );
            } else {
               this.alertService.addAlert(
                  this.lang().ThisLogIsNowVisibleForOnlyManagers,
                  "success",
                  5000,
               );
            }
         } else {
            if (log.logViewable == 1) {
               log.logViewable = 0;
            } else {
               log.logViewable = 1;
            }
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      });
   };

   addURL = (pic, log) => {
      assert(this.vendor);
      pic.getURL = `viewFile.php?f=upload-${this.CID}/vendors/${this.vendor.locationID}/${this.vendor.vendorID}/logs/${log.vendorLogID}/${pic.fileName}`;
   };

   editLogEntry = (log) => {
      if (this.manageVendorLogCred == false) {
         this.alertService.addAlert(this.lang().cred137Fail, "warning", 10000);
         return;
      }

      log.logPictures.forEach((pic) => {
         this.addURL(pic, log);
      });

      const instance = this.modalService.open(EditVendorLogItem);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { log: log },
         },
      };
   };

   public sortLogsBySortBind = (newSortValue) => {
      this.orderBy = newSortValue;
      this.logItems = orderBy(this.logItems, this.orderBy);
   };

   public searchLogs() {
      // debounce function
      if (this.timeout) {
         clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
         this.buildData();
      }, 500); //so it doesn't trigger until half a second after I call it.
   }

   updateUIPref = (number) => {
      this.itemsPerPage = number;
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage =
         this.itemsPerPage;
      this.manageUser.updateUserUIPreferences();
   };
}
