import type { OnInit } from "@angular/core";
import { inject, ChangeDetectorRef, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PaginationComponent,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ViewAssets } from "src/app/assets/components/viewAssetsModal/viewAssets.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "update-asset-hours-of-operation",
   templateUrl: "./updateAssetHoursOfOperation.modal.component.html",
   styleUrls: ["./updateAssetHoursOfOperation.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      FormsModule,
      PaginationComponent,
      OrderByPipe,
      SliceArray,
   ],
})
export class UpdateAssetHoursOfOperation implements OnInit {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public asset: Asset | undefined;
   public errorMsg;
   public page: any = 1;
   public itemsPerPage;
   public manualHours: Array<{ start: string; hours: any; lookup: string; end: string }> =
      [];

   public readonly modalService = inject(ModalService);
   public readonly manageAsset = inject(ManageAsset);
   private readonly refreshService = inject(RefreshService);
   public readonly manageTask = inject(ManageTask);
   public readonly manageLang = inject(ManageLang);
   public readonly paramsService = inject(ParamsService);
   public readonly ref = inject(ChangeDetectorRef);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.itemsPerPage =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage || 10;

      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.asset = this.resolve.asset;
      this.errorMsg = "";

      assert(this.asset);
      if (this.asset.assetTrackHoursManually == 1) {
         this.manualHours = this.manageAsset.prepHoursManually(this.asset);
      }
   }

   pageChanged = () => {
      this.ref.detectChanges();
   };

   updateAssetTrackHoursManuallyData = (hour, assetToUpdate) => {
      const childrenIDs = this.manageAsset.findChildrenIDs(assetToUpdate, []);

      if (childrenIDs.length > 0) {
         childrenIDs.push(assetToUpdate.assetID); //add the very top parent asset on so we show everything and delete everything if they click yes

         const instance = this.modalService.open(ViewAssets);

         instance.componentInstance.message.set(
            this.lang().UpdateChildAssetsTrackedManuallyMsg,
         );
         instance.componentInstance.title.set(
            this.lang().UpdateChildAssetsTrackedManually,
         );
         instance.componentInstance.assetsIDs.set(childrenIDs);
         instance.componentInstance.showUndoneWarning.set(true);
         instance.componentInstance.buttonActionAll.set({
            text: this.lang().UpdateAll,
            dataLogLabel: "UpdateAllAssets",
         });
         instance.componentInstance.buttonActionTopLevel.set({
            text: this.lang().UpdateOnlyTopLevel,
            dataLogLabel: "UpdateOnlyTopLevelAssets",
         });
         instance.componentInstance.dataLogWorkflowLabel.set("UpdateChildAssets");

         instance.result.then((result) => {
            if (result === "includeChildren") {
               this.manageAsset
                  .updateAssetTrackHoursManuallyData(hour, assetToUpdate, 1)
                  .then((answer) => {
                     if (answer.data.success == true) {
                        this.refreshService.refreshData();
                        this.manageTask.incCompletedTasksWatchVar();
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           2000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
                     }
                  });
            } else if (result === "parentOnly") {
               this.manageAsset
                  .updateAssetTrackHoursManuallyData(hour, assetToUpdate, 0)
                  .then((answer) => {
                     if (answer.data.success == true) {
                        this.manageTask.incCompletedTasksWatchVar();
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           2000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
                     }
                  });
            }
         });
      } else {
         this.manageAsset
            .updateAssetTrackHoursManuallyData(hour, assetToUpdate, 0)
            .then((answer) => {
               if (answer.data.success == true) {
                  this.manageTask.incCompletedTasksWatchVar();
                  this.alertService.addAlert(this.lang().successMsg, "success", 2000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               }
            });
      }
   };

   close = () => {
      this.modalInstance.close(0);
   };
}
