import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, HostBinding, computed } from "@angular/core";
import { ScrollContainerComponent, isPhoneScreenSize$ } from "@limblecmms/lim-ui";
import type { Observable } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { VendorInfoTabHeader } from "src/app/shared/types/general.types";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { VendorTile } from "src/app/vendors/components/vendorTileWrapper/vendorTile.wrapper.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "pop-vendor",
   templateUrl: "./popVendor.modal.component.html",
   imports: [ScrollContainerComponent, NgTemplateOutlet, VendorTile, AsyncPipe],
})
export class PopVendor implements OnInit, OnDestroy {
   @HostBinding("class") public readonly classes = "scroll-height-inheritance";
   public resolve;
   public modalInstance;
   public locationID;
   public vendorID: number | undefined;
   public restrict;
   public vendor: Vendor | undefined;
   public location;
   public locationWatchVarSub;
   public hasLoaded = false;
   public navigateToTab: VendorInfoTabHeader | undefined;

   protected isPhoneScreenSize$: Observable<boolean> = isPhoneScreenSize$;

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      //if they are not logged in then we need to close the modal because they are redirected to the login page.
      this.manageLogin.checkLogin().then((answer: any) => {
         if (answer.data.success == false) {
            this.modalInstance.close();
         }
      });
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;
      this.locationID = this.resolve.locationID;
      this.vendorID = this.resolve.vendorID;
      this.restrict = data.restrict;
      this.navigateToTab = this.resolve.data.navigateToTab;

      if (!this.vendorID) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      this.vendor = this.manageVendor.getVendor(this.vendorID);
      if (!this.vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.locationWatchVarSub = this.manageObservables.setSubscription(
         "locationWatchVar",
         () => {
            this.location = this.manageLocation.getLocation(this.locationID);
         },
      );
      this.hasLoaded = true;
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.locationWatchVarSub);
   }

   close = () => {
      this.modalInstance.close();
   };
}
