import { inject, Injectable } from "@angular/core";
import type { PhraseMap } from "src/app/languages/translation/phrase-map.types";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { CustomUnit } from "src/app/parts/unit-of-measure/custom-units/custom-unit.model";
import { getUnitDetails } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/get-unit-details";
import { ProvidedUnit } from "src/app/parts/unit-of-measure/provided-units/provided-unit.model";
import type {
   CustomUnitDto,
   ProvidedUnitDto,
   UnitDto,
} from "src/app/parts/unit-of-measure/unit-of-measure.dto.types";
import type { Unit } from "src/app/parts/unit-of-measure/unit.types";
import { UnitTypes } from "src/app/parts/unit-of-measure/unit.types";

@Injectable({
   providedIn: "root",
})
export class UnitOfMeasureMappingService {
   private readonly i18n = inject(TranslationService).i18n;

   public mapDtosToModels(dtos: Array<UnitDto>): Array<Unit> {
      return dtos.map((dto) => this.mapDto(dto)).filter((unit) => unit !== undefined);
   }

   public mapModelsToProvidedUnitMap(units: Array<Unit>): Record<number, ProvidedUnit> {
      return Object.fromEntries(
         units
            .filter((unit) => unit instanceof ProvidedUnit)
            .map((unit) => [unit.id, unit]),
      );
   }

   public mapModelsToCustomUnitMap(units: Array<Unit>): Record<number, CustomUnit> {
      return Object.fromEntries(
         units
            .filter((unit) => unit instanceof CustomUnit)
            .map((unit) => [unit.id, unit]),
      );
   }

   private mapDto(dto: UnitDto): Unit | undefined {
      return dto.type === UnitTypes.Provided
         ? this.mapProvidedUnitDto(dto)
         : this.mapCustomUnitDto(dto);
   }

   private mapProvidedUnitDto(dto: ProvidedUnitDto): ProvidedUnit | undefined {
      const translationKey = dto.nameSingular;
      const unitDetails = getUnitDetails(translationKey);

      if (unitDetails === undefined) {
         console.error(`Unit details not found for provided unit ${translationKey}`);
         return undefined;
      }

      return new ProvidedUnit({
         id: dto.id,
         aliasInitialValue: dto.alias,
         category: dto.category,
         translationKey: unitDetails.singular as keyof PhraseMap,
         short: unitDetails.short,
         conversionFactorToSIUnit: unitDetails.conversionFactorToSIUnit,
         i18n: this.i18n,
      });
   }

   private mapCustomUnitDto(dto: CustomUnitDto): CustomUnit {
      return new CustomUnit({
         id: dto.id,
         singularInitialValue: dto.nameSingular,
         shortInitialValue: dto.nameShort,
      });
   }
}
