@if (item()) {
   <div class="listItem">
      <div class="pr-item-row">
         <div class="col gl-col">
            <span class="col-label">{{ lang().GL }}</span>
            <div class="col-value">
               <span>{{ transactionExtraInfo?.glAbbr }}</span>
            </div>
         </div>

         @let $purchasableSnapshot = purchasableSnapshot();
         <div class="col item-col">
            <span class="col-label">{{ lang().Item }}</span>
            <div class="col-value">
               <div class="item-name" id="pr-item-{{ item().poItemID }}">
                  @let $item = item();
                  @if ($item.partID && $item.partID > 0) {
                     <lim-ui-icon icon="gears" iconSize="small" />
                  }
                  @if (item().itemType === 2) {
                     <lim-ui-icon icon="wpforms" iconSize="small" />
                  }

                  @if (item().itemType === 4) {
                     <lim-ui-icon icon="superpowers" iconSize="small" />
                  }
                  <span [limbleHtml]="transactionExtraInfo?.itemName"></span>
                  @if (transactionExtraInfo?.itemNumber?.length) {
                     •
                     <span [limbleHtml]="transactionExtraInfo?.itemNumber"></span>
                  }
               </div>
               @if (
                  isUnitOfMeasureEnabled() &&
                  $purchasableSnapshot !== null &&
                  $purchasableSnapshot !== undefined
               ) {
                  <div class="item-uom">
                     <span>{{ $purchasableSnapshot.name }}</span>
                  </div>
               }
            </div>
         </div>

         <div class="col desc-col">
            <span class="col-label">{{ lang().Description }}</span>
            <div class="col-value">
               <span [limbleHtml]="item().description"></span>
            </div>
         </div>

         <div class="col amount-col">
            <span class="col-label">{{ lang().Qty }}</span>
            <div class="col-value">
               <div
                  [class.qty-input]="!isUnitOfMeasureEnabled()"
                  [class.purchasable-quantity-received-input-container]="
                     isUnitOfMeasureEnabled()
                  "
               >
                  @if (transactionExtraInfo) {
                     <input
                        type="number"
                        (ngModelChange)="validateQty()"
                        [(ngModel)]="transaction().qtyReceived"
                        [disabled]="!transactionExtraInfo.editable"
                        (blur)="setPRTransactionQty()"
                        onclick="this.focus();document.execCommand('selectAll', false, null);"
                        min="0"
                     />
                  }
                  <div class="purchasable-conversion">
                     <div [limUiTooltip]="lang().QtyLeftToReceive">
                        {{ lang().ofStr }} {{ max }}
                     </div>
                     @if (
                        isUnitOfMeasureEnabled() &&
                        $purchasableSnapshot !== null &&
                        $purchasableSnapshot !== undefined
                     ) {
                        <unit-label
                           [unitDisplay]="$purchasableSnapshot.orderUnitDisplay"
                        />
                        @if (
                           $purchasableSnapshot.orderUnitDisplay.name !==
                           $purchasableSnapshot.stockUnitDisplay.name
                        ) {
                           <div>
                              <unit-conversion
                                 [stockUnits]="$purchasableSnapshot.partUnits"
                                 [unitDisplay]="{
                                    name: $purchasableSnapshot.stockUnitDisplay.name,
                                    abbreviation:
                                       $purchasableSnapshot.stockUnitDisplay.abbreviation,
                                 }"
                                 [enclosed]="true"
                              />
                           </div>
                        }
                     }
                  </div>
               </div>
            </div>
         </div>

         <div class="col amount-col">
            <span class="col-label">{{ lang().Rate }}</span>
            <div class="col-value">
               @if (currencyCode()) {
                  <span [limUiTooltip]="currencyCode() | currencyName">{{
                     item().rate | localeCurrency: currencyCode()
                  }}</span>
               } @else {
                  <span>{{ item().rate | betterCurrency }}</span>
               }
            </div>
         </div>

         <div class="col amount-col">
            <span class="col-label">{{ lang().Tax }}</span>
            <div class="col-value">
               <span>{{ item().tax }}%</span>
            </div>
         </div>

         <div class="col amount-col">
            <span class="col-label">{{ lang().Discount }}</span>
            <div class="col-value">
               <span>{{ item().discount }}%</span>
            </div>
         </div>

         <div class="col amount-col">
            <span class="col-label">{{ lang().Shipping }}</span>
            <div class="col-value">
               @if (currencyCode()) {
                  <span [limUiTooltip]="currencyCode() | currencyName">{{
                     item().shipping | localeCurrency: currencyCode()
                  }}</span>
               } @else {
                  <span>{{ item().shipping | betterCurrency }}</span>
               }
            </div>
         </div>

         <div class="col total-col">
            <span class="col-label">{{ lang().Total }}</span>
            <div class="col-value">
               @if (currencyCode()) {
                  <span [limUiTooltip]="currencyCode() | currencyName">{{
                     transactionExtraInfo?.total | localeCurrency: currencyCode()
                  }}</span>
               } @else {
                  <span>{{ transactionExtraInfo?.total | betterCurrency }}</span>
               }
            </div>
         </div>

         <div class="col received-col">
            <span class="col-label">{{ lang().Received }}</span>
            <div class="col-value">
               @let $transaction = transaction();
               @if ($transaction.dateReceived !== null) {
                  <span>
                     {{ $transaction.dateReceived * 1000 | betterDate: "date" }}
                  </span>
               }
            </div>
         </div>
         <div class="col delete-col">
            <div class="col-value">
               <lim-ui-minimal-icon-button
                  icon="trashCanRegular"
                  iconColor="danger"
                  size="small"
                  (click)="deletePRTransaction()"
                  style="margin-right: 1px"
                  [hidden]="!(bill && bill.status && bill.status <= 1)"
                  [disabled]="!(bill && bill.status && bill.status <= 1)"
               />
            </div>
         </div>
      </div>
      <po-item-tasks-hint
         [id]="transaction().poItemID"
         [other]="false"
         [state]="transactionExtraInfo?.state ?? 0"
      />
   </div>
}
