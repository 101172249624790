import type { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import {
   MeasuredPurchasablePurchaseOrderItemDtoSchema,
   type MeasuredPurchasablePurchaseOrderItemDto,
} from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/measured-purchasable-purchase-order-item.dto";
import type { UnitDisplayParserService } from "src/app/purchasing/pos/purchasable-snapshot/parsers/util/unit-display-parser.service";
import type { PurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot-parser.interface";
import type { PurchasableSnapshot } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.model";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";

export class MeasuredPurchasableSnapshotParser implements PurchasableSnapshotParser {
   public constructor(
      private readonly item: MeasuredPurchasablePurchaseOrderItemDto,
      private readonly unitDisplayParserService: UnitDisplayParserService,
      private readonly unitOfMeasureService: UnitOfMeasureService,
   ) {}

   public static isValid(
      item: PurchaseOrderItem,
   ): item is MeasuredPurchasablePurchaseOrderItemDto {
      return MeasuredPurchasablePurchaseOrderItemDtoSchema.safeParse(item).success;
   }

   public parse(): PurchasableSnapshot | null {
      const orderUnitDisplay = this.unitDisplayParserService.parseProvided({
         providedUnitID: this.item.purchasableProvidedOrderUnitID,
         providedUnitAlias: this.item.purchasableProvidedOrderUnitAlias,
      });
      if (orderUnitDisplay === null) return null;

      const stockUnitDisplay = this.unitDisplayParserService.parseProvided({
         providedUnitID: this.item.partStockProvidedUnitID,
         providedUnitAlias: this.item.partStockProvidedUnitAlias,
      });
      if (stockUnitDisplay === null) return null;

      const orderUnit = this.unitOfMeasureService.getUnit({
         id: this.item.purchasableProvidedOrderUnitID,
         type: "provided",
      })();
      if (orderUnit === null) return null;

      const stockUnit = this.unitOfMeasureService.getUnit({
         id: this.item.partStockProvidedUnitID,
         type: "provided",
      })();
      if (stockUnit === null) return null;

      const partUnits = orderUnit.convertUnit(this.item.qty, stockUnit);

      return {
         partID: this.item.partID,
         name: this.item.purchasableName,
         orderUnitDisplay,
         stockUnitDisplay,
         partUnits,
      };
   }
}
