import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { type Observable, filter, tap } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { environment } from "src/environments/environment";

const ASSETS_URL = "assets";
interface options {
   updateState?: boolean;
}

@Injectable({
   providedIn: "root",
})
export class AssetFieldValuesService {
   private readonly http = inject(HttpClient);
   private readonly manageAsset = inject(ManageAsset);

   public getFieldValues(
      assetID: number,
      options?: options,
   ): Observable<Array<AssetFieldValue>> {
      return this.http
         .get<
            Array<AssetFieldValue>
         >(`${environment.servicesURL()}/${ASSETS_URL}/${assetID}/values`, { withCredentials: true })
         .pipe(
            filter(() => options?.updateState === true),
            tap((fieldValues) => {
               this.manageAsset.updateFieldValuesInLocalData(fieldValues);
            }),
         );
   }
}
