@if (part) {
   <span
      class="part-name-wrapper"
      [ngClass]="{ 'full-width-input-wrapper-with-buttons': usedIn === 'listItem' }"
   >
      @if (credChangePartName && part.partDeleted == 0) {
         <span
            class="part-name growing-field"
            [ngClass]="{
               'editUnderline': credChangePartName,
               'red-border': credChangePartName && part.partName?.length === 0,
            }"
            [(ngModel)]="part.partName"
            (afterEdit)="updatePartName()"
            disableEditor="true"
            limbleContentEditable
            stripHtml="true"
         ></span>
      }
      @if (!credChangePartName || part.partDeleted == 1) {
         <span>
            <span class="part-name" [limbleHtml]="part.partName"></span>
         </span>
      }
      <span
         class="smaller-grey-text"
         placement="bottom"
         limUiTooltip="{{ lang().LimblesInternalPartIDTooltip }}"
      >
         #{{ part.partID }}
      </span>
   </span>
}
