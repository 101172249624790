<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message">{{ message }}</span>
      </lim-ui-info-panel>
      @if (fields?.length > 0) {
         <lim-ui-panel>
            @for (field of fields; track field) {
               <div
                  class="lim-ui-list-item lim-ui-selectable-list-item"
                  (click)="focusField(field)"
                  [ngClass]="{
                     'lim-ui-selected-list-item': field.selected,
                     'lim-ui-disabled-list-item': field.greyOut,
                  }"
               >
                  <div class="lim-ui-list-item-content">
                     <div class="icon-with-text">
                        <lim-ui-icon
                           [icon]="field.fieldTypeIcon | iconAlias"
                           limUiTooltip="{{
                              field.fieldTypeIcon === 'faListOl'
                                 ? lang().DropdownRecreateIconMsg
                                 : lang().EmailRecreateIconMsg
                           }}"
                           iconSize="small"
                        />
                        <div [limbleHtml]="field.fieldName"></div>
                     </div>
                  </div>
               </div>
            }
            @if (errorMsg !== "") {
               <lim-ui-alert [title]="lang().Warning" alertType="warning">
                  <span [limbleHtml]="errorMsg"></span>
               </lim-ui-alert>
            }
         </lim-ui-panel>
      }
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Recreate"
      (successClick)="submit()"
   />
</lim-ui-modal>
