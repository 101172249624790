import { Component, effect, input, model, type ModelSignal } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import type { FieldValueFilter } from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";

@Component({
   selector: "number-field-filter",
   standalone: true,
   imports: [ReactiveFormsModule, TranslateDirective, FormsModule],
   templateUrl: "./number-field-filter.component.html",
})
export class NumberFieldFilterComponent {
   public readonly filterNumberLowest: ModelSignal<number | null> = model<number | null>(
      null,
   );
   public readonly filterNumberHighest: ModelSignal<number | null> = model<number | null>(
      null,
   );

   public initialValue = input<Partial<FieldValueFilter> | undefined>();

   public constructor() {
      effect(() => {
         const value = this.initialValue();
         if (value?.from) {
            const from = Number(value.from);
            this.onFilterNumberLowestChanged(Number.isNaN(from) ? null : from);
         }
         if (value?.to) {
            const to = Number(value.to);
            this.onFilterNumberHighestChanged(Number.isNaN(to) ? null : to);
         }
      });
   }

   public onFilterNumberLowestChanged(value: number | null) {
      this.filterNumberLowest.set(value);
   }

   public onFilterNumberHighestChanged(value: number | null) {
      this.filterNumberHighest.set(value);
   }
}
