import { Injectable, inject } from "@angular/core";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { TaskEntityPart } from "src/app/parts/types/part.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type {
   TaskPartLegacy,
   TaskPartRelation,
} from "src/app/tasks/types/part/task-part.types";

@Injectable({ providedIn: "root" })
export class TaskPartsAvailabilityService {
   private readonly manageTask = inject(ManageTask);
   private readonly manageParts = inject(ManageParts);

   /**
    * Takes an array of TaskParts and returns the subset of them which are considered
    * "underStocked" -- meaning there are fewer parts in stock than the suggested number
    * on the task.
    */
   public partsUnderStocked(
      parts: Array<TaskPartLegacy | TaskEntityPart>,
   ): Array<TaskPartLegacy | TaskEntityPart> {
      return parts.filter((part) => {
         const totalSuggested = parts
            .filter((part2) => part.partID === part2.partID)
            .reduce<number>((total, part3) => total + (part3.suggestedNumber ?? 0), 0);
         return totalSuggested > this.stockCount(part);
      });
   }

   /** Takes a TaskPart and returns the number of that part in stock */
   public stockCount(part: TaskPartLegacy | TaskEntityPart): number {
      if ("partExtraBatchIDs" in part) {
         const extraBatches = this.manageParts
            .getExtraBatches()
            .filter((batch) => part.partExtraBatchIDs.includes(batch.extraBatchID));

         return extraBatches.reduce<number>(
            (total, batch) => total + (Number(batch.partQty) - Number(batch.partQtyUsed)),
            Number(part.partQty),
         );
      }
      return (
         part.extraBatches?.reduce<number>(
            (total, batch) => total + (Number(batch.partQty) - Number(batch.partQtyUsed)),
            Number(part.partQty),
         ) ?? part.partQty
      );
   }

   /**
    * Takes an array of TaskParts and returns the subset of them which are considered
    * "overReserved" -- meaning that the number of parts in stock is less than the number
    * of parts "reserved" by open tasks.
    */
   public partsOverReserved(
      taskParts: Array<TaskPartLegacy | TaskEntityPart>,
   ): Array<TaskPartLegacy | TaskEntityPart> {
      const partRelations = taskParts
         .map((taskPart) => [
            ...(this.manageTask.getPartRelationsByPartID(taskPart.partID) ?? []),
         ])
         .flat()
         .filter((relation) => this.relationHasOpenTask(relation));
      const reservedMap: LimbleMap<number, number> = new LimbleMap();
      for (const relation of partRelations) {
         const alreadyReserved = reservedMap.get(relation.partID) ?? 0;
         reservedMap.set(
            relation.partID,
            alreadyReserved + (relation.suggestedNumber ?? 0),
         );
      }
      return [...reservedMap.entries()]
         .map(([partID, reserved]) => {
            return {
               applicableTaskParts: taskParts.filter((part) => part.partID === partID),
               reserved,
            };
         })
         .filter(({ applicableTaskParts, reserved }) => {
            /* There may be multiple taskParts that match the partID, but we only need one
            for this check. */
            return reserved > this.stockCount(applicableTaskParts[0]);
         })
         .map(({ applicableTaskParts }) => applicableTaskParts)
         .flat();
   }

   private relationHasOpenTask(relation: TaskPartRelation): boolean {
      return (
         relation.checklistID !== null &&
         this.manageTask.getOpenTaskLocalLookup(relation.checklistID) !== undefined &&
         this.manageTask.getOpenTaskLocalLookup(relation.checklistID)
            ?.checklistTemplate === 0
      );
   }
}
