import { NgClass, NgTemplateOutlet } from "@angular/common";
import {
   type OnDestroy,
   type OnInit,
   type QueryList,
   computed,
   model,
   input,
   inject,
   signal,
} from "@angular/core";
import { Component, Input, ViewChildren, forwardRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { DraggableDirective, type TreeBranch } from "@limble/limble-tree";
import {
   BadgeComponent,
   CheckboxComponent,
   DatePickerInputComponent,
   DropdownItemComponent,
   FormDropdownInputComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   NestedPanelComponent,
   PopoverDirective,
   RadioButtonComponent,
   ScrollContainerComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   UpsellPopover,
   fadeIn,
   fadeOut,
   collapseUpAnimation,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import moment from "moment";
import { debounceTime, Subject, Subscription } from "rxjs";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { EditableImage } from "src/app/files/components/editableImage/editableImage.element.component";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import { InstructionalFiles } from "src/app/files/components/instructionalFiles/instructionalFiles.element.component";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { FileStorageSyncService } from "src/app/lite/local-db/resources/collection/task/file/file.storage.sync.service";
import { buildFileDownloadUrl } from "src/app/lite/local-db/resources/collection/task/file/file.utils";
import { buildPathPrefixAttachmentInstructionResponse } from "src/app/lite/local-db/resources/collection/task/file/variants/attachment-instruction-response/attachment-instruction-response.utils";
import { InstructionStorageSyncService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.sync.service";
import { ViewMap } from "src/app/maps/components/viewMapModal/viewMap.modal.component";
import { ManageMaps } from "src/app/maps/services/manageMaps";
import type {
   GeoCoordinates,
   GeoFeature,
   GeoReference,
} from "src/app/maps/types/geoMap.types";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { WayToFixThatCardComponent } from "src/app/shared/components/way-to-fix-that-card/way-to-fix-that-card.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { EscapePipe } from "src/app/shared/pipes/customEscape.pipe";
import { FilterArrayPipe } from "src/app/shared/pipes/filterArray.pipe";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageFilters, parseUrlFilter } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { AnomalousValueConfirmedCardComponent } from "src/app/tasks/components/anomalous-value-confirmed-card/anomalous-value-confirmed-card.component";
import { AnomalousValueDetectedCardComponent } from "src/app/tasks/components/anomalous-value-detected-card/anomalous-value-detected-card.component";
import { ChkItemBuildOptions } from "src/app/tasks/components/chkItemBuildOptionsElement/chkItemBuildOptions.element.component";
import { ChkItemService } from "src/app/tasks/components/chkItemElement/chkItem.service";
import { TaskItemLinkHint } from "src/app/tasks/components/chkItemElement/taskItemLinkHintElement/taskItemLinkHint.element.component";
import { ChkItemPointer } from "src/app/tasks/components/chkItemPointerElement/chkItemPointer.element.component";
import { ChkOptionBuildOptions } from "src/app/tasks/components/chkOptionBuildOptionsElement/chkOptionBuildOptions.element.component";
import { DisapproveApproval } from "src/app/tasks/components/disapproveApprovalModal/disapproveApproval.modal.component";
import { PickItem } from "src/app/tasks/components/pickItemModal/pickItem.modal.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { SetupWorkOrder } from "src/app/tasks/components/setupWorkOrderModal/setupWorkOrder.modal.component";
import { PickTaskTemplateModalComponent } from "src/app/tasks/components/shared/components/pick-task-template-modal/pick-task-template-modal.component";
import { TaskFormHelperService } from "src/app/tasks/components/shared/services/task-form-helper/task-form-helper.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { UpdateTaskStateService } from "src/app/tasks/components/task-form/services/update-task-state.service";
import { NoTimeLogged } from "src/app/tasks/components/taskItems/noTimeLogged/noTimeLogged.modal.component";
import { SignaturePadComponent } from "src/app/tasks/components/taskItems/signaturePad/signaturePad.element.component";
import { WorkOrderTemplatesComponent } from "src/app/tasks/components/work-order-templates-component/work-order-templates.component";
import {
   ApprovalStatus,
   TaskInstructionTypeID,
} from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { ManageTaskItemOption } from "src/app/tasks/services/manageTaskItemOption";
import { AnomalyStateStore } from "src/app/tasks/stores/anomaly-detection/anomaly-state.store";
import type {
   TaskInfo,
   TaskFormSettings,
} from "src/app/tasks/types/info/task-info.types";
import type { Task } from "src/app/tasks/types/task.types";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

export type TaskInstructionDisplayData = {
   mode: "instance" | "template";
   itemsEditable: boolean;
   expand: boolean;
   limited: boolean;
   disableAlerts: boolean;
   options: any;
   taskCompleted: boolean;
};

export type TaskInstruction = any;

@Component({
   selector: "chk-item",
   templateUrl: "./chkItem.element.component.html",
   styleUrls: ["./chkItem.element.component.scss"],
   animations: [fadeIn, fadeOut, collapseUpAnimation],
   imports: [
      NestedPanelComponent,
      NgClass,
      IconComponent,
      DraggableDirective,
      TooltipDirective,
      NgTemplateOutlet,
      LimbleHtmlDirective,
      ContenteditableDirective,
      FormsModule,
      ChkItemBuildOptions,
      FormDropdownInputComponent,
      forwardRef(() => AssetParentList),
      DropdownItemComponent,
      DatePickerInputComponent,
      SecondaryButtonComponent,
      ChkOptionBuildOptions,
      ChkItemPointer,
      TaskItemLinkHint,
      InstructionalFiles,
      CheckboxComponent,
      RadioButtonComponent,
      ScrollContainerComponent,
      MinimalIconButtonComponent,
      FileUploader,
      EditableImage,
      FileListItem,
      SignaturePadComponent,
      BetterDatePipe,
      EscapePipe,
      FilterArrayPipe,
      IconAlias,
      OrderByPipe,
      BadgeComponent,
      UpsellPopover,
      PopoverDirective,
      LimbleHtmlDirective,
      PartUnitOfMeasurePipe,
      AnomalousValueConfirmedCardComponent,
      AnomalousValueDetectedCardComponent,
      WayToFixThatCardComponent,
   ],
   providers: [AnomalyStateStore],
})
export class ChkItem implements OnInit, OnDestroy {
   public readonly item = model.required<any>();
   public readonly parentTaskInput = input<TaskEntity>();
   // TODO: These should eventually be attached to the instructions returned as a column from the flannel backend.
   public readonly tasks = input<TaskEntity[]>();
   public readonly instructions = input<TaskInstruction[]>();

   /**
    * @deprecated Use the taskInstructionsViewParameters/other new inputs instead.
    */
   public readonly info = input.required<TaskInfo | TaskFormSettings>();

   //TODO: Migrate any info fields to this object over time.
   public readonly taskInstructionsViewParameters = input<
      TaskInstructionDisplayData | undefined
   >(undefined);

   @Input() public treeBranch?: TreeBranch<ChkItem>;
   @Input() public itemParentResponse;
   @Input() public tempEdit: boolean;
   @Input() public taskHasOptionSelectedWithSameCustomTag:
      | ((
           previousOption: Record<string, any>,
           parentItem: Record<string, any>,
        ) => boolean)
      | undefined;
   @ViewChildren("chkItem") public readonly directChildItems:
      | QueryList<ChkItem>
      | undefined;

   @ViewChildren("dropdownChkItem") public readonly subDropdownChildItems:
      | QueryList<ChkItem>
      | undefined;
   @ViewChildren("radioChkItem") public readonly subDropdownChildItemsCollection:
      | QueryList<ChkItem>
      | undefined;
   protected currentDate: Date;
   public imageExts;
   public itemOptions;
   public dateOptions;
   public newDate;
   public dummyDate;
   public checklistUserCompleted;
   public checklistTemplate;
   public uploadObj;
   public mentionList;
   public userProfiles;
   public newMentionedList;
   public mentionUid;
   public customerID;
   public workOrderUser;
   public uploadedFiles;
   public uploadElement;
   public funcObj;
   public assetName;
   public woTemplate;
   public woUser;
   public reassignUserTarget;
   public taskBelongsToPrompt;
   public tags;
   public foundPMAssigned;
   public showSigImage;
   public sigPrep;
   public sigInfo;
   public tempSigURL;
   public setPMAssignAssetStrSafetyCount;
   public setWOAssignTaskStrSafetyCount;
   public startWOExtraTasks;
   public startWOExtraUsers;
   public relatedPart: Part | undefined;
   public instructionSetTemplate;
   public instructionSetInstance;
   public parentTask: Task | TaskTemplateEntity | TaskEntity | undefined;
   public instructionSetTimestampTooltip;
   public instructionSetTimestamp;
   public linkHintTooltip: boolean = false;
   protected readonly Status = ApprovalStatus;
   protected childItems;
   protected featureESignatureVerification: boolean = false;
   protected itemBelongsToCurrentUser: boolean = false;
   protected taskBelongsToCurrentUser: boolean = false;
   protected waitForLoadingImage: boolean = false;
   protected featureStartWOStartPM: boolean = false;
   protected featureCustomApprovalInstruction: boolean = false;

   protected TaskInstructionType = TaskInstructionTypeID;
   protected showPastResponses = false;

   private tasksSubscription: Subscription | null = null;
   private manageFeatureFlagsSub: Subscription = new Subscription();
   private canUpdateCustomApprovalInstruction: boolean = false;
   private canUpdateStartWOStartPMInstruction: boolean = false;
   private canUpdateInstruction: boolean = false;
   private readonly fileExtRexExp = /(?:\.([^.]+))?$/;
   private statusIsChanging = false;

   private componentStatePreviousOption: number | undefined;

   private readonly manageTask = inject(ManageTask);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly manageTaskItemOption = inject(ManageTaskItemOption);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageFiles = inject(ManageFiles);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageUser = inject(ManageUser);
   private readonly manageProfile = inject(ManageProfile);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageObservables = inject(ManageObservables);
   public readonly manageParts = inject(ManageParts);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageMap = inject(ManageMaps);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly instructionStorageSyncService = inject(InstructionStorageSyncService);
   private readonly fileStorageSyncService = inject(FileStorageSyncService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageLang = inject(ManageLang);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly chkItemService = inject(ChkItemService);
   private readonly updateTaskStateService = inject(UpdateTaskStateService);
   private readonly taskFormHelperService = inject(TaskFormHelperService);
   protected readonly anomalyStateStore = inject(AnomalyStateStore);

   private readonly modelChangeSubject = new Subject<void>();
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly taskMode = computed(() => {
      const taskMode = this.taskInstructionsViewParameters()?.mode;
      if (taskMode) return taskMode;
      const info = this.info();
      if ("mode" in info) {
         return info.mode;
      }
      throw new Error(
         "Task mode not found in the info object. Please use the taskInstructionsViewParameters input to set the mode.",
      );
   });

   protected readonly taskItemsEditable = computed(() => {
      const taskItemsEditable = this.taskInstructionsViewParameters()?.itemsEditable;
      if (taskItemsEditable !== undefined) return taskItemsEditable;
      const info = this.info();
      if ("editable" in info) {
         return info.editable;
      }
      throw new Error(
         "Task editable status not found in the info object. Please use the taskInstructionsViewParameters input to set the editable status.",
      );
   });

   protected readonly canClearSignatures = computed(() => {
      const canEditTask = this.taskInstructionsViewParameters()?.itemsEditable;
      return canEditTask && !this.taskInstructionsViewParameters()?.taskCompleted;
   });

   protected readonly limited = computed(() => {
      const limited = this.taskInstructionsViewParameters()?.limited;
      if (limited !== undefined) return limited;
      const info = this.info();
      if ("limited" in info) {
         return info.limited;
      }
      throw new Error(
         "Task limited status not found in the info object. Please use the taskInstructionsViewParameters input to set the limited status.",
      );
   });

   protected readonly expand = computed(() => {
      const expand = this.taskInstructionsViewParameters()?.expand;
      if (expand !== undefined) return expand;
      const info = this.info();
      if ("expand" in info) {
         return info.expand;
      }
      throw new Error(
         "Task expand status not found in the info object. Please use the taskInstructionsViewParameters input to set the expand status.",
      );
   });

   protected readonly disableAlerts = computed(() => {
      const disableAlerts = this.taskInstructionsViewParameters()?.disableAlerts;
      if (disableAlerts !== undefined) return disableAlerts;
      const info = this.info();
      if ("disableAlerts" in info) {
         return info.disableAlerts;
      }
      throw new Error(
         "Task disable alerts status not found in the info object. Please use the taskInstructionsViewParameters input to set the disable alerts status.",
      );
   });

   protected readonly options = computed<Array<any>>(() => {
      const options = this.taskInstructionsViewParameters()?.options;
      if (options !== undefined) return options;
      const info = this.info();
      if ("options" in info) {
         return info.options;
      }
      throw new Error(
         "Task options not found in the info object. Please use the taskInstructionsViewParameters input to set the options.",
      );
   });

   protected readonly taskSignal = computed(() => {
      return this.item().task;
   });

   public instrTaskName = computed(() => {
      const task = this.taskSignal();
      if (!task) {
         return undefined;
      }
      return task.checklistName;
   });

   protected readonly assetPMChecklistName = computed<string | null>(() => {
      if (this.item().itemResponse < 1) {
         return this.item().assetPMChecklistName;
      }
      return this.taskSignal()?.checklistName ?? null;
   });

   protected readonly isTaskStatusNameFromWOOrPMVisible = computed(() => {
      return this.instrTaskName() && this.taskStatusNameFromWOorPM();
   });

   protected readonly assetID = computed(() => {
      const task = this.taskSignal();
      if (!task) {
         if (this.item().itemBelongsTo > 0) {
            return this.item().itemBelongsTo;
         } else if (this.item().itemBelongsTo < 0) {
            return 0;
         }
         return this.item().assetIDFromPmToAssign;
      }
      return task?.assetID;
   });

   protected readonly asset = computed(() => {
      const assetID = this.assetID();
      if (assetID !== undefined) {
         return this.manageAsset.getAsset(assetID);
      }
      return undefined;
   });

   protected readonly startedTaskCompletedDate = computed(() => {
      return this.taskSignal()?.checklistCompletedDate;
   });

   protected readonly taskStatusNameFromWOorPM = computed(() => {
      const task = this.taskSignal();
      if (!task) {
         return null;
      }
      const statusInfo = this.manageTask.getStatusInfo(task.statusID);
      return statusInfo?.statusName ?? null;
   });

   // Used to reset to the old item response if an update failed.
   private readonly oldItemResponse = signal<any>(undefined);

   public constructor() {
      this.setPMAssignAssetStrSafetyCount = 0;
      this.setWOAssignTaskStrSafetyCount = 0;
      this.dateOptions = {
         formatYear: "yy",
         startingDay: 1,
      };
      this.newDate = new Date();
      this.dummyDate = new Date("02/09/1985");
      const currentUser = this.manageUser.getCurrentUser();
      this.customerID = currentUser.userInfo.customerID;
      this.workOrderUser = currentUser.workOrderUser;
      this.currentDate = new Date();
      this.tempEdit = false;

      this.modelChangeSubject
         .pipe(debounceTime(1500), takeUntilDestroyed())
         .subscribe(() => {
            this.setResponse();
         });
   }

   protected infoSettings(info: TaskInfo | TaskFormSettings | undefined) {
      if (!info) {
         return undefined;
      }
      if ("mode" in info) {
         return info.mode;
      }
      return undefined;
   }

   protected async initAnomalyState() {
      this.anomalyStateStore.setCurrentlyEnteredValue(this.item().itemResponse);
      await this.anomalyStateStore.setAnomalyID(this.item().anomalyID, true);
   }

   public ngOnInit() {
      if (this.info() === undefined) {
         throw new Error("`info` is a required input in ChkItem");
      }

      this.item.set(this.treeBranch?.meta().nodeData ?? this.item());
      this.oldItemResponse.set(this.item().itemResponse);

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureStartWOStartPM = isFeatureEnabledMap.featureStartWOStartPM;
            this.featureCustomApprovalInstruction =
               isFeatureEnabledMap.featureCustomApprovalInstruction;

            if (this.item().itemTypeID) {
               this.canUpdateCustomApprovalInstruction =
                  this.manageFeatureFlags.canUpdateCustomApprovalInstruction(
                     this.item().itemTypeID,
                  );
               this.canUpdateStartWOStartPMInstruction =
                  this.manageFeatureFlags.canUpdateStartWOStartPMInstruction(
                     this.item().itemTypeID,
                  );
               this.canUpdateInstruction =
                  this.canUpdateCustomApprovalInstruction &&
                  this.canUpdateStartWOStartPMInstruction;
            }
         },
      );

      this.imageExts = this.manageFiles.imageExts;

      this.itemOptions = this.options();

      if (
         this.item().itemText &&
         typeof this.item().itemText === "string" &&
         this.item().itemText.indexOf("</a>") == -1
      ) {
         //BRYAN TO DO... code a way to skip if they already have <a> tags in the itemText
         this.item().itemText = parseUrlFilter(this.item().itemText);
      }

      this.parentTask = this.info()?.task;
      if (this.parentTask !== undefined) {
         this.checklistUserCompleted = this.parentTask.checklistUserCompleted;

         this.checklistTemplate = this.parentTask.checklistTemplate;
      }
      if (Number(this.item().instructionSetTimestamp) !== 0) {
         this.instructionSetTimestamp = new Date(
            Number(this.item().instructionSetTimestamp) * 1000,
         );
      }
      if (Number(this.checklistTemplate) === 6) {
         this.instructionSetTemplate = true;
         this.instructionSetTimestampTooltip =
            this.lang().ThisIsTheLastTimeThisInstructionSetTemplateWasEdited;
      }
      if (
         Number(this.item().itemTypeID) === TaskInstructionTypeID.InstructionSet &&
         !this.instructionSetTemplate
      ) {
         this.instructionSetInstance = true;
         this.instructionSetTimestampTooltip = this.lang().LastTimeInstructionSetSynced;
      }

      //this will run once the file uploader has been successful
      this.uploadObj = {};
      this.uploadObj.primaryID = "fileName";
      this.uploadObj.deleteData = {
         checklistID: this.item().checklistID,
         itemID: this.item().itemID,
      };
      this.uploadObj.deleteCall = this.manageTaskItem.deleteFile;
      this.uploadObj.uploadCall = this.manageTaskItem.addFile;
      this.uploadObj.loadingCall = (started) => {
         //this is used so we can delay things from happening before the image is fully done
         if (started == true) {
            this.waitForLoadingImage = true;
         }
      };
      this.uploadObj.posturl = `phpscripts/checklistManager.php?action=makeFile&itemID=${this.item().itemID}&checklistID=${this.item().checklistID}`;

      this.uploadObj.viewOnly = !this.taskItemsEditable();

      /** calls function to populate users list */
      if (this.item() && this.item().itemTypeID == TaskInstructionTypeID.TextBox) {
         this.getMentionList();
      }

      if (
         this.item()?.itemTypeID == TaskInstructionTypeID.UpdatePartQty &&
         this.item()?.partID
      ) {
         this.relatedPart = this.manageParts.getPart(this.item().partID);
         if (this.relatedPart) {
            this.manageParts.calculatePartData(this.relatedPart);
         }
      }

      this.uploadObj.uploadComplete = async (data): Promise<void> => {
         const info = this.info();
         assert(info !== undefined);
         if (data.failed) {
            return;
         }

         this.item().itemResponse = 1;
         this.item().done = true;
         this.updateTaskStateService.guidesNeedsUpdate();
         if (info.files === undefined || info.files == null) {
            info.files = [];
         }

         const regex = /(?:\.([^.]+))?$/;
         const ext = regex.exec(data.fileName)?.[1];

         const file: any = {};

         const fileName = data.fileName;
         const customerID = this.customerID;
         const taskID = this.item().checklistID;
         const instructionID = this.item().itemID;

         // if the file is an image
         if (this.manageFiles.checkImageExt(data.fileName)) {
            file.getURL = buildFileDownloadUrl({
               customerID,
               fileDownloadPathPrefix: buildPathPrefixAttachmentInstructionResponse({
                  taskID,
                  instructionID,
               }),
               fileName,
            });
            file.parentRef = "itemID";
            file.deleteData = {
               fileName: file.fileName,
               checklistID: file.checklistID,
               itemID: file.itemID,
            };
         }

         file.checklistID = this.item().checklistID;
         file.itemID = this.item().itemID;
         file.fileName = data.fileName;
         file.fileDescription = data.fileName;
         file.fileID = data.fileID;
         file.ext = ext;
         file.message = "File Upload Success";
         file.status = true;

         info.files = [...info.files, file]; //for angular change detection

         await this.fileStorageSyncService.syncAttachmentInstructionFileAddition({
            fileName,
            customerID,
            taskID,
            instructionID,
         });
      };

      if (this.item().itemTypeID == TaskInstructionTypeID.RequestApproval) {
         this.setAssignTargetStr();
         this.updateTaskStateService.incGreyOutWatchVar();
      }

      if (
         this.item().itemTypeID == TaskInstructionTypeID.StartWO ||
         this.item().itemTypeID == TaskInstructionTypeID.Reassign
      ) {
         this.startWOExtraTasks = {
            arr: [
               {
                  checklistName: this.lang().LetTheUserPick,
                  checklistID: -100,
               },
            ],
            key: {},
         };
         this.startWOExtraTasks.arr.forEach((extraTask) => {
            this.startWOExtraTasks.key[extraTask.checklistID] = extraTask;
         });

         this.startWOExtraUsers = {
            arr: [
               //users that have special meaning for generating a work order
               {
                  userFirstName: this.lang().TheUserDoingTheWork,
                  userID: -100,
                  profileID: 0,
               },
               {
                  userFirstName: this.lang().LetTheUserPickOwnTeams,
                  userID: -101,
                  profileID: 0,
                  locked: true,
               },
               {
                  userFirstName: this.lang().LetTheUserPick,
                  userID: -102,
                  profileID: 0,
                  locked: false,
               },
            ],
            key: {},
         };
         this.startWOExtraUsers.arr.forEach((extraUser) => {
            this.startWOExtraUsers.key[extraUser.userID] = extraUser;
         });

         this.setWOAssignAssetTemplateStr(this.item().itemWOTemplateID);

         this.setWOAssignUserStr({
            userID: Number(this.item().itemWOAssignToUserID),
            profileID: Number(this.item().itemWOAssignToProfileID),
         });
      }

      if (this.item().itemTypeID == TaskInstructionTypeID.Reassign) {
         this.startWOExtraUsers = {
            arr: [
               {
                  userFirstName: this.lang().LetTheUserPick,
                  userID: -102,
                  profileID: 0,
                  locked: false,
               },
            ],
            key: {},
         };
         this.startWOExtraUsers.arr.forEach((extraUser) => {
            this.startWOExtraUsers.key[extraUser.userID] = extraUser;
         });

         this.setReassignUserStr({
            userID: Number(this.item().itemWOAssignToUserID),
            profileID: Number(this.item().itemWOAssignToProfileID),
         });

         this.updateTaskStateService.incGreyOutWatchVar();
      }

      //
      //
      //

      if (this.item().itemTypeID == TaskInstructionTypeID.AssignPM) {
         //asset PM
         this.item().taskBelongsToOptions = [
            //for the dropdown box selection
            {
               value: 0,
               label: this.lang().SameAssetAsPMTemplate,
               hint: this.lang().SameAssetAsPMTemplateHint,
            },
            { value: -1, label: this.lang().LetTheUserPick },
            { value: 1, label: this.lang().PreSelectAsset },
         ];
      }

      if (this.item().itemTypeID == TaskInstructionTypeID.StartWO) {
         //start WO
         this.item().taskBelongsToOptions = [
            //for the dropdown box selection
            { value: 0, label: this.lang().sameAssetAsParent },
            { value: -1, label: this.lang().LetTheUserPick },
            { value: 1, label: this.lang().PreSelectAsset },
         ];
      }

      if (this.item().itemTypeID == TaskInstructionTypeID.VerifyLocation) {
         // Verify location
         this.item().taskBelongsToOptions = [
            //for the dropdown box selection
            { value: 0, label: this.lang().TheAssetAssignedToThisTask },
            { value: -1, label: this.lang().ThisTask },
            { value: 1, label: this.lang().PreSelectAsset },
         ];
         this.updateTaskStateService.incGreyOutWatchVar();
      }

      this.setWOBelongsTo(Number(this.item().itemBelongsTo));

      if (this.item().fieldIDForLink > 0) {
         this.setItemLinkHint();
      }
      this.setFileDescription();

      if (this.item().itemTypeID == TaskInstructionTypeID.DropdownList) {
         this.setDropdownDisplayText();
      }

      this.uploadedFiles = [{ message: "sample message", status: false }];

      //use this switch statement to set default values as needed for angular to work properly
      //   then there is a switch statement in "setResponse" above if you need to translate them back for mySQL
      switch (Number(this.item().itemTypeID)) {
         case 9: //file upload
            break;
         case 10:
            if (!(this.item().itemResponse > 0)) {
               this.item().itemResponse = 0;
            }
            break;
         case 5:
         case 11:
            if (this.item().itemResponse > 1) {
               if (!(this.item().itemResponse instanceof Date)) {
                  //if it is stored in localDB it will still be a date, no need to convert
                  this.item().itemResponse = new Date(this.item().itemResponse * 1000);
               }
            } else {
               this.item().itemResponse = null;
            }

            break;

         default:
            break;
      }

      this.chkItemService.setTaskItemFiles(this.item(), this.parentTask, this.customerID);

      this.setSigPrep();

      this.funcObj = {};

      this.funcObj.viewImage = this.viewImage;

      this.tasksSubscription = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.setItemLinkHint();
         },
      );

      this.childItems = this.info().items.filter(
         (item) => item.itemParentID === this.item().checklistItemCount,
      );

      this.featureESignatureVerification =
         this.manageUser.getCurrentUser().userInfo.featureESignatureVerification === 1;

      this.setItemBelongsToCurrentUser();
      this.setTaskBelongsToCurrentUser();
      this.resetComponentStatePreviousOption();
      this.initAnomalyState();
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.tasksSubscription);
      this.manageFeatureFlagsSub.unsubscribe();
   }

   setItemLinkHint = () => {
      let assetID;
      let field;
      if (Number(this.item().assetIDForLink) > 0) {
         assetID = this.item().assetIDForLink;
      } else {
         assetID = this.parentTask?.assetID ?? undefined;
      }

      const asset = this.manageAsset.getAsset(assetID);

      const fieldFromFieldIDForLink = this.manageAsset.getField(
         this.item().fieldIDForLink,
      );
      if (!fieldFromFieldIDForLink) {
         return;
      }
      field = fieldFromFieldIDForLink;

      if (
         asset?.locationID !== fieldFromFieldIDForLink.locationID &&
         fieldFromFieldIDForLink.scopeType !== "standardized" // Necessary to show standardized field links properly
      ) {
         const fieldAtAssetLocation =
            this.manageAsset.findIdenticalFieldAtDifferentLocation(
               Number(this.item().fieldIDForLink),
               Number(asset?.locationID),
            );
         if (fieldAtAssetLocation) {
            field = fieldAtAssetLocation;
         }
      }

      const value = this.manageAsset.getFieldValueFromFieldIDAndAssetID(
         field.fieldID,
         assetID,
      );

      const fieldTypeID = this.manageAsset.getFieldType(field.fieldTypeID)?.fieldTypeID;
      if (
         value &&
         (fieldTypeID == 1 || //text
            fieldTypeID == 2 || //date
            fieldTypeID == 5 || //number
            fieldTypeID == 6 || //currency
            fieldTypeID == 7) && //dropdown
         asset?.assetDeleted !== 1
      ) {
         const dummyTask: any = {}; //create a dummy task object so we can utilze the hier build
         dummyTask.assetID = assetID;
         const assetName = this.manageAsset.getAssetNameIncludeParents(assetID);

         this.item().linkHint = `${this.lang().YourResponseWillUpdate} <i class="fa fa-solid fa-circle-info fa-fw" style="margin-right: 3px;"></i>${field.fieldName} - <i class="fa-solid fa-cube fa-fw" style="margin-right: 3px;"></i>${assetName}`;
         if (value.valueContent != null) {
            this.item().linkHint += ` | ${this.lang().CurrentlyAt} ${value.valueContent}`;
         }
      } else if (
         Number(this.parentTask?.assetID) > 0 &&
         Number(this.item().assetIDForLink) === 0
      ) {
         this.item().linkHint = `${this.lang().LinkedTo} <i class="fa fa-solid fa-circle-info fa-fw" style="margin-right: 3px;"></i>${field.fieldName} - ${this.lang().onTheAssetThisTaskBelongsTo} <b>${this.lang().butTheAssetDoesNotHaveThisField}</b>`;
         this.linkHintTooltip = true;
      } else {
         this.item().linkHint = `${this.lang().LinkedTo} <i class="fa fa-solid fa-circle-info fa-fw" style="margin-right: 3px;"></i>${field.fieldName} - <b>${this.lang().butIsCurrentlyNotLinkedToAnAsset}</b>`;
         this.linkHintTooltip = true;
      }
   };

   protected handleClickOnOptionItem(item, info, option): void {
      if (!this.taskItemsEditable()) {
         return;
      }
      item.itemResponse = option.itemOptionCount;
      this.setResponse(option);
      this.updateTaskStateService.incGreyOutWatchVar();
   }

   setWOAssignAssetTemplateStr = (templateId) => {
      const templateTask = this.manageTask.getTaskLocalLookup(templateId);
      if (this.startWOExtraTasks.key[templateId]) {
         this.woTemplate = this.startWOExtraTasks.key[templateId].checklistName;
      } else if (templateTask === undefined) {
         this.woTemplate = this.lang().LetTheUserPick;
      } else {
         this.woTemplate = templateTask.checklistName;
      }
   };

   setWOAssignUserStr = ({ userID, profileID }: any) => {
      if (userID === 0 && profileID === 0) {
         this.woUser = this.lang().LetTheUserPick;
      } else if (userID > 0) {
         const tempUser = this.manageTask.getAllUsers().get(userID);
         this.woUser = `${tempUser?.userFirstName} ${tempUser?.userLastName}`;
      } else if (profileID > 0) {
         const tempProfile = this.manageTask.getAllProfiles().get(profileID);
         this.woUser = tempProfile?.profileDescription ?? "";
      } else if (this.startWOExtraUsers.key[userID]) {
         this.woUser = this.startWOExtraUsers.key[userID].userFirstName;
      }
   };

   setReassignUserStr = ({ userID, profileID }: any) => {
      if (userID === 0 && profileID === 0) {
         this.reassignUserTarget = this.lang().LetTheUserPick;
      } else if (userID > 0) {
         const tempUser = this.manageTask.getAllUsers().get(userID);
         this.reassignUserTarget = `${tempUser?.userFirstName} ${tempUser?.userLastName}`;
      } else if (profileID > 0) {
         const tempProfile = this.manageTask.getAllProfiles().get(profileID);
         this.reassignUserTarget = tempProfile?.profileDescription ?? "";
      } else if (this.startWOExtraUsers.key[userID]) {
         this.reassignUserTarget = this.startWOExtraUsers.key[userID].userFirstName;
      }
   };

   setWOBelongsTo = (belongsTo) => {
      if (
         this.item().itemTypeID == TaskInstructionTypeID.AssignPM ||
         this.item().itemTypeID == TaskInstructionTypeID.StartWO ||
         this.item().itemTypeID == TaskInstructionTypeID.VerifyLocation
      ) {
         this.taskBelongsToPrompt = "";
         if (belongsTo === 0 || belongsTo === -1) {
            for (const tempBelongsTo of this.item().taskBelongsToOptions) {
               if (tempBelongsTo.value == belongsTo) {
                  this.item().taskBelongsToOptionPicked = tempBelongsTo.label;
               }
            }
         } else if (belongsTo > 0) {
            const assetName = this.manageAsset.getAsset(belongsTo)?.assetName;

            for (const tempBelongsTo of this.item().taskBelongsToOptions) {
               if (tempBelongsTo.value == 1) {
                  this.item().taskBelongsToOptionPicked = tempBelongsTo.label;
               }
            }

            this.taskBelongsToPrompt = `${assetName}`;
         }
      }
   };
   /** Gets mention list for the mention system dropdown menu */
   getMentionList = () => {
      const tags = this.manageUser.getCurrentUser().tags.split("@");
      const customTags: any = [];
      for (const tag of tags) {
         if (tag.length > 0) {
            const obj: any = {};
            obj.name = `@${tag}`;
            obj.tagDescription = tag;
            obj.itemID = this.manageUtil.getRandomString();
            customTags.push(obj);
         }
      }
      this.tags = this.manageUtil.alphabeticalSort(customTags, "tagDescription");

      this.mentionList = this.tags;
   };

   /** creates a callback function that allows the mention child component to update the newMentionedList data here */
   updateNewMentionedList = (newMentionedList) => {
      this.newMentionedList = newMentionedList;
   };

   //
   // end of initializing mention system data and logic
   // on entering information in an instructional textblock, the data (newMentionedList) is passed down
   // to the chk controller component to handle the adding of the tags to the tag list.
   //

   //opens a doka instance -> no permissions here as if you are on the build tasks page you should have permission to edit the files.
   openEditor = async (file) => {
      if (this.taskItemsEditable()) {
         const doka = await this.manageFiles.createImageEditor(file.getURL);
         //when the user clicks confirm this deletes the old version of the file, and uploads the edited file
         //has to convert doka's output to formdata for uploading and play with the fileName a little bit so the names
         //don't get longer each time the file is edited
         doka.onconfirm = (output) => {
            assert(this.info()?.files !== undefined);
            this.uploadObj.deleteData.fileName = file.fileName;

            //send the call to delete the image
            this.uploadObj.deleteCall(this.uploadObj.deleteData);

            const getFileIndex = this.info().files?.findIndex(
               (tempFile) => tempFile.fileName === file.fileName,
            );
            //remove it from the browser's state
            if (getFileIndex) {
               this.info().files?.splice(getFileIndex, 1);
            }

            //remove it from indexedDB
            this.fileStorageSyncService.syncAttachmentInstructionFileDeletion({
               instructionID: this.item().itemID,
               fileName: file.fileName,
            });

            const formData = new FormData();
            formData.append(
               "myfile",
               output.file,
               file.fileName.replace(/\d\d\d\d-/, ""),
            );

            this.uploadObj
               .uploadCall(this.uploadObj.posturl, formData)
               .then((response) => {
                  this.uploadObj.uploadComplete(response.data);
               });
         };
      }
   };

   setAssignTargetStr = () => {
      if (this.item().itemUserID > 0) {
         const tempUser = this.manageTask.getAllUsers().get(this.item().itemUserID);
         if (tempUser === undefined) {
            this.item().assignTargetStr = this.lang().DeletedUser;
         } else {
            this.item().assignTargetStr = `${tempUser.userFirstName} ${tempUser.userLastName}`;
         }
      } else if (this.item().itemProfileID > 0) {
         const tempProfile = this.manageTask
            .getAllProfiles()
            .get(this.item().itemProfileID);
         this.item().assignTargetStr = tempProfile?.profileDescription ?? "";
      } else {
         this.item().assignTargetStr = this.lang().PickUser;
      }

      if (this.item().onApproveUserID > 0) {
         const tempUser = this.manageTask.getAllUsers().get(this.item().onApproveUserID);
         if (tempUser) {
            this.item().onApproveTargetStr = `${tempUser.userFirstName} ${tempUser.userLastName}`;
         } else {
            this.item().onApproveTargetStr = this.lang().DeletedUser;
         }
      } else if (this.item().onApproveProfileID > 0) {
         const tempProfile = this.manageTask
            .getAllProfiles()
            .get(this.item().onApproveProfileID);
         this.item().onApproveTargetStr = tempProfile?.profileDescription ?? "";
      } else if (
         this.item().onApproveUserID == -1 &&
         this.item().onApproveProfileID == -1
      ) {
         this.item().onApproveTargetStr = this.lang().OriginalOwner;
      } else {
         this.item().onApproveTargetStr = this.lang().DontChangeAssignment;
      }

      if (this.item().onDisapproveUserID > 0) {
         const tempUser = this.manageTask
            .getAllUsers()
            .get(this.item().onDisapproveUserID);
         if (tempUser) {
            this.item().onDisapproveTargetStr = `${tempUser.userFirstName} ${tempUser.userLastName}`;
         } else {
            this.item().onDisapproveTargetStr = this.lang().DeletedUser;
         }
      } else if (this.item().onDisapproveProfileID > 0) {
         const tempProfile = this.manageTask
            .getAllProfiles()
            .get(this.item().onDisapproveProfileID);
         this.item().onDisapproveTargetStr = tempProfile?.profileDescription ?? "";
      } else {
         this.item().onDisapproveTargetStr = this.lang().OriginalOwner;
      }
   };

   setDoneTextbox = () => {
      //this is a workaround for when someone is typing in a textbox and then IMMEDIATELY afterwards clicks complete.
      // We need to temp mark this as "done" so that ti doesn't prevent him from having to double tap the complete
      this.item().done = true;
   };

   setFileDescription = () => {
      for (const file of this.info()?.files ?? []) {
         if (file.fileDescription == "" || file.fileDescription == null) {
            file.fileDescription = file.fileName;
         }
      }
   };

   //sets display text for dropdowns.  Added to option list in order to work with linked asset dropdown fields
   setDropdownDisplayText = () => {
      if (
         Number(this.item().itemTypeID) === TaskInstructionTypeID.DropdownList ||
         Number(this.item().itemTypeID) === TaskInstructionTypeID.OptionList
      ) {
         for (const option of this.options() ?? []) {
            if (
               this.item().itemResponse == option.itemOptionCount &&
               this.item().itemID == option.itemID
            ) {
               if (
                  option.itemOptionText &&
                  typeof option.itemOptionText === "string" &&
                  option.itemOptionText.indexOf("</a>") === -1
               ) {
                  option.itemOptionText = parseUrlFilter(option.itemOptionText);
               }

               this.item().displayText = option.itemOptionText;
            }
         }
      }
   };

   changeApprovalTarget = () => {
      if (this.parentTask === undefined || !this.canUpdateCustomApprovalInstruction) {
         return;
      }
      const locationID = this.parentTask.locationID;

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().LetTheUserPick,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               title: this.lang().ChangeApprovalTarget,
               message: this.lang().ChangeApprovalTargetMsg,
               locationID: locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.item().itemUserID,
               defaultProfile: this.item().itemProfileID,
            },
         },
      };

      instance.result.then((result) => {
         if (result != 0) {
            this.manageTaskItem
               .changeApprovalTarget(
                  result.userID,
                  result.profileID,
                  result.multiUsers,
                  this.item(),
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     const userID = answer.data.userID;
                     const profileID = answer.data.profileID;
                     const profileDescription = answer.data.profileDescription;
                     const tempLocationID = answer.data.locationID;

                     if (result.multiUsers.length > 0) {
                        //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
                        this.manageFilters.updateHiddenProfiles(
                           {
                              profileID: profileID,
                              name: profileDescription,
                              locationID: tempLocationID,
                              multiUsers: result.multiUsers,
                           },
                           this.manageTask,
                           this.manageUser,
                           this.manageProfile,
                        );
                     }

                     this.item().itemUserID = userID;
                     this.item().itemProfileID = profileID;
                     this.setAssignTargetStr();

                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         }
      });
   };

   changeAfterApprovalTarget = () => {
      if (this.parentTask === undefined || !this.canUpdateCustomApprovalInstruction) {
         return;
      }
      const locationID = this.parentTask.locationID;

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().DontChangeAssignment,
               userID: 0,
               profileID: 0,
            },
            {
               userFirstName: this.lang().OriginalOwner,
               userID: -1,
               profileID: -1,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               title: this.lang().ChangeAfterApprovalTarget,
               message: this.lang().ChangeAfterApprovalTargetMsg,
               locationID: locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.item().onApproveUserID,
               defaultProfile: this.item().onApproveProfileID,
            },
         },
      };

      instance.result.then((result) => {
         if (result != 0) {
            this.manageTaskItem
               .changeAfterApprovalTarget(
                  result.userID,
                  result.profileID,
                  result.multiUsers,
                  this.item(),
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     const userID = answer.data.userID;
                     const profileID = answer.data.profileID;
                     const profileDescription = answer.data.profileDescription;
                     const tempLocationID = answer.data.locationID;

                     if (result.multiUsers.length > 0) {
                        //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
                        this.manageFilters.updateHiddenProfiles(
                           {
                              profileID: profileID,
                              name: profileDescription,
                              locationID: tempLocationID,
                              multiUsers: result.multiUsers,
                           },
                           this.manageTask,
                           this.manageUser,
                           this.manageProfile,
                        );
                     }

                     this.item().onApproveUserID = userID;
                     this.item().onApproveProfileID = profileID;
                     this.setAssignTargetStr();

                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         }
      });
   };

   changeAfterDisapproveTarget = () => {
      if (this.parentTask === undefined || !this.canUpdateCustomApprovalInstruction) {
         return;
      }
      const locationID = this.parentTask.locationID;

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().OriginalOwner,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               title: this.lang().changeAfterDisapproveTarget,
               message: this.lang().changeAfterDisapproveTargetMsg,
               locationID: locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.item().onDisapproveUserID,
               defaultProfile: this.item().onDisapproveProfileID,
            },
         },
      };

      instance.result.then((result) => {
         if (result != 0) {
            this.manageTaskItem
               .changeAfterDisapproveTarget(
                  result.userID,
                  result.profileID,
                  result.multiUsers,
                  this.item(),
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     const userID = answer.data.userID;
                     const profileID = answer.data.profileID;
                     const profileDescription = answer.data.profileDescription;
                     const tempLocationID = answer.data.locationID;

                     if (result.multiUsers.length > 0) {
                        //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
                        this.manageFilters.updateHiddenProfiles(
                           {
                              profileID: profileID,
                              name: profileDescription,
                              locationID: tempLocationID,
                              multiUsers: result.multiUsers,
                           },
                           this.manageTask,
                           this.manageUser,
                           this.manageProfile,
                        );
                     }

                     this.item().onDisapproveUserID = userID;
                     this.item().onDisapproveProfileID = profileID;
                     this.setAssignTargetStr();

                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         }
      });
   };

   triggerApproval = () => {
      if (this.parentTask === undefined || !this.canUpdateCustomApprovalInstruction) {
         return;
      }

      if (this.parentTask.checklistTemplate) {
         //templates can't start this process
         this.alertService.addAlert(
            this.lang().PleaseStartACopyOfThisTemplateToSeeHowItPerforms,
            "warning",
            5000,
         );
         return;
      }

      const processTriggerApproval = (userID, profileID, multiUsers, confirm) => {
         const trigger = () => {
            assert(this.parentTask); //we want an error if they try to approve, but we don't have a task.
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            this.manageTask
               .triggerApproval(
                  this.item().itemID,
                  userID,
                  profileID,
                  multiUsers,
                  this.parentTask.checklistID,
                  this.manageUser,
                  this.manageProfile,
               )
               .then((answer) => {
                  if (answer.data.success === true) {
                     assert(this.info() !== undefined);
                     this.updateTaskStateService.incItemWatchVar();
                     this.loadingBarService.remove();
                     this.item().checklistToSpawn = 1;
                     if (this.parentTask !== undefined) {
                        this.parentTask.approvalItemState = 1;
                     }

                     //we need to close them out.
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);

                     //we need to do a couple things.  First if it belongs to me then don't do anything...
                     const currentUser = this.manageUser.getCurrentUser();

                     if (
                        this.credService.checkCredGlobal(
                           this.credService.Permissions.ManageRoles,
                        )
                     ) {
                        //we are a super user so we can change it, so don't reload state
                        this.updateTaskStateService.buildData(true);
                     } else if (currentUser.gUserID == this.item().itemUserID) {
                        //we just assigned to ourselves so don't reload state
                        this.updateTaskStateService.buildData(true);
                     } else {
                        let foundOurProfile = false;
                        //next we need to check if we assigned it to a profile we belong to.
                        for (const tempProfile of currentUser.profileLoc) {
                           if (
                              tempProfile.locationID === this.parentTask?.locationID &&
                              tempProfile.profileID === this.item().itemProfileID
                           ) {
                              foundOurProfile = true;
                           }
                        }

                        if (!foundOurProfile) {
                           //ok we aren't a super user, approval wasn't sent to ourselves and approval wasn't set to a profile we have at this location... all of that means we need to reload the state so that the screen shows a limited Task
                           this.closePopTask(
                              this.lang().ApprovalHasBeenSuccessfullyRequested,
                           );
                        } else if (foundOurProfile) {
                           this.updateTaskStateService.buildData(true);
                        }
                     }

                     const instructionID = Number(this.item().itemID);
                     this.instructionStorageSyncService.syncInstruction(instructionID);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         };

         if (this.parentTask?.checklistTemplate === 0) {
            if (confirm) {
               const instance = this.modalService.open(Confirm);

               this.paramsService.params = {
                  modalInstance: instance,
                  resolve: {
                     message: this.lang().AreYouSureYouWouldLikeToSendThisTaskToApproval,
                     title: this.lang().RequestApproval,
                  },
               };

               instance.result.then((result) => {
                  if (result == 1) {
                     trigger();
                  }
               });
            } else {
               trigger();
            }
         } else {
            //for templates we just move them forward because we aren't actually reassigning it to someone else.
            this.item().checklistToSpawn = 1;
         }
      };

      const askForUser = () => {
         //it was left unassigned so let the user pick
         const instance = this.modalService.open(PickUserOrProfileLegacy);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: {
                  showAuditOptions: false,
                  title: this.lang().WhoWillPerformApproval,
                  message: "",
                  locationID: this.parentTask?.locationID ?? undefined,
               },
            },
         };

         instance.result.then((result) => {
            if (result != 0) {
               //now that data is
               processTriggerApproval(
                  result.userID,
                  result.profileID,
                  result.multiUsers,
                  false,
               );
            }
         });
      };

      //first we need to see if they have time set or not.
      // We also don't want to allow them to log time if they are an external user.
      const isExternalUser: boolean = this.manageUser.getCurrentUser().workOrderUser;
      const parentTask = this.parentTaskInput();
      const extraTimeIDs = parentTask?.extraTime;
      if (extraTimeIDs?.length === 0 && !isExternalUser) {
         const instance = this.modalService.open(NoTimeLogged);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {},
         };

         instance.result.then(async (result) => {
            if (result == 1) {
               if (this.item().itemUserID > 0 || this.item().itemProfileID > 0) {
                  processTriggerApproval(
                     this.item().itemUserID,
                     this.item().itemProfileID,
                     [],
                     false,
                  );
               } else {
                  askForUser();
               }
            } else if (result === "logTime") {
               await this.updateTaskStateService.callSetOpenTimeLogModal();
            }
         });
      } else if (this.item().itemUserID > 0 || this.item().itemProfileID > 0) {
         processTriggerApproval(
            this.item().itemUserID,
            this.item().itemProfileID,
            [],
            false,
         );
      } else {
         askForUser();
      }
   };

   setResponse = async (selectedOption?: Record<string, any>) => {
      if (this.info() === undefined) return;
      let resetCustomTag = false;
      if (selectedOption !== undefined && this.taskHasOptionSelectedWithSameCustomTag) {
         const previouslySelectedOption =
            this.item().nodes?.find(
               (option) => option.itemOptionCount === this.componentStatePreviousOption,
            ) ?? undefined;
         if (previouslySelectedOption !== undefined) {
            resetCustomTag = !this.taskHasOptionSelectedWithSameCustomTag(
               previouslySelectedOption,
               this.item(),
            );
         }
      }
      //dropdownResponse is only added if a dropdown item calling.
      const myID = this.item().itemID; //just making this data centralized for this function
      let myResponse = this.item().itemResponse; //just making this data centralized for this function in case we want to do it different later

      switch (
         Number(this.item().itemTypeID) //may need to manipulate the response based on the item type...
      ) {
         case TaskInstructionTypeID.OptionList:
            this.setDropdownDisplayText();
            break;
         case TaskInstructionTypeID.TextBox: {
            setTimeout(() => {
               this.updateTaskStateService.submitTags(this.newMentionedList);
            }, 50);
            const InputEl = document.getElementById(`input-field-${this.item().itemID}`);
            if (InputEl && InputEl.innerHTML != myResponse) {
               myResponse = InputEl.innerHTML;
            }
            break;
         }
         case TaskInstructionTypeID.DropdownList:
            myResponse = selectedOption?.itemOptionCount;
            this.item().itemResponse = selectedOption?.itemOptionCount;
            this.setDropdownDisplayText();
            break;
         case TaskInstructionTypeID.DatePicker:
            if (myResponse) {
               myResponse = new Date(myResponse).getTime() / 1000;
            }
            break;

         case TaskInstructionTypeID.DeadlineDatePicker:
            if (myResponse) {
               myResponse = new Date(myResponse).getTime() / 1000;

               const tempTask = this.manageTask.getTaskLocalLookup(
                  this.item().checklistID,
               );
               if (tempTask !== undefined) {
                  tempTask.checklistDueDate = myResponse;
               }
            }
            break;

         case TaskInstructionTypeID.Number: {
            const isNumeric = /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)$/;

            if (myResponse === null) {
               this.item().done = false;
               this.updateTaskStateService.guidesNeedsUpdate();
               this.anomalyStateStore.resetAnomalyState();
               return Promise.resolve();
            }

            isNumeric.test(myResponse);

            if (myResponse && !isNumeric.test(myResponse)) {
               if (this.item().itemResponse !== "") {
                  if (this.item().itemResponse !== null) {
                     this.alertService.addAlert(
                        this.lang().PleaseEnterANumber,
                        "danger",
                        6000,
                     );
                  }
               }

               this.item().itemResponse = "";
               this.item().done = false;
               this.updateTaskStateService.guidesNeedsUpdate();
               return Promise.resolve();
            }
            break;
         }

         default:
            break;
      }
      if (this.parentTask === undefined) {
         return;
      }
      //this checks if the setresponse should override the backend check that the task has already been completed
      const overrideCompleted = this.tempEdit || false; //info.editable is set to true when chk.tempEdit was set to true

      const valueChanged = this.item().itemResponse !== this.oldItemResponse();
      if (valueChanged) {
         await this.anomalyStateStore.checkForAnomaly(
            this.item().assetIDForLink,
            this.item().fieldIDForLink,
            this.item().itemResponse,
         );
      }

      //this function actually does the post to the server, returning an $http promise
      const post = this.manageTaskItem
         .setResponse(
            myID,
            myResponse,
            overrideCompleted,
            resetCustomTag,
            this.anomalyStateStore.responseValueOutsideOfExpectedRange(),
         )
         .then(
            async (answer) => {
               //on success
               if (answer.data.success === true) {
                  this.oldItemResponse.set(myResponse);
                  assert(this.info() !== undefined);
                  if (!this.disableAlerts()) {
                     if (
                        this.item().itemTypeID === TaskInstructionTypeID.CaptureSignature
                     ) {
                        this.alertService.addAlert(
                           this.lang().successMsgSignature,
                           "success",
                           2000,
                        );
                     } else {
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     }
                  }

                  this.item().done = answer.data.done;

                  this.updateTaskStateService.guidesNeedsUpdate();
                  if (
                     this.item().itemTypeID === TaskInstructionTypeID.DropdownList ||
                     this.item().itemTypeID === TaskInstructionTypeID.OptionList
                  ) {
                     this.updateTaskStateService.incGreyOutWatchVar();
                  }

                  if (
                     this.item().itemTypeID === TaskInstructionTypeID.CaptureSignature ||
                     this.item().itemTypeID === TaskInstructionTypeID.RequestApproval
                  ) {
                     this.item().itemResponse = answer.data.response;
                     this.setSigPrep();

                     this.fileStorageSyncService.syncSignatureFileAddition({
                        customerID: this.customerID,
                        instructionID: this.item().itemID,
                        fileName: this.item().itemResponse,
                        itemBatchID: this.item().itemBatchID,
                     });
                  }
                  const oldInstructions = this.parentTask?.checklistInstructions; //if this is null it will trigger rebuilding tasks.
                  const newInstructions = answer.data.checklistInstructions;
                  if (this.parentTask !== undefined) {
                     //set the task to have an updated last edited value
                     this.parentTask.checklistLastEdited = answer.data.lastEdited;
                  }

                  //set the task to have an updated statusID value
                  if (answer?.data?.statusID) {
                     const currentStatusID = this.parentTask?.statusID;

                     const noteHiddenFromExternal = Number(
                        this.manageUser.getCurrentUser().userInfo
                           .noteHiddenFromExternalFlag,
                     );

                     if (
                        currentStatusID === 0 &&
                        answer.data.statusID === 1 &&
                        !this.statusIsChanging
                     ) {
                        this.statusIsChanging = true;

                        // Manually add a comment since this status change is not from the normal stauts update button flow
                        await this.manageTask.sendTaskStatusUpdatedNote(
                           this.item().checklistID,
                           currentStatusID,
                           answer.data.statusID,
                           noteHiddenFromExternal,
                        );

                        // Update the statusID of the task on the frontend so we do not add duplicate comments
                        if (this.parentTask) {
                           this.parentTask.statusID = answer.data.statusID;
                        }
                     } else {
                        this.statusIsChanging = false;
                     }
                  }
                  this.resetComponentStatePreviousOption();
                  //set the task to update the checklistInstructions
                  if (this.parentTask !== undefined) {
                     this.parentTask.checklistInstructions =
                        answer.data.checklistInstructions;
                     if (oldInstructions !== newInstructions) {
                        this.updateTaskStateService.rebuildTags(newInstructions);
                     }
                  }
                  const instructionID = Number(this.item().itemID);
                  this.instructionStorageSyncService.syncInstruction(instructionID);

                  if (valueChanged) {
                     await this.anomalyStateStore.setAnomalyID(answer.data.anomalyID);
                     await this.anomalyStateStore.calcAndSetAnomalyState();
                  }
               } else if (answer.data.reason === "alreadyCompleted") {
                  this.item().itemResponse = this.oldItemResponse();
                  this.alertService.addAlert(
                     this.lang().AlreadyCompletedMsg,
                     "warning",
                     6000,
                  );
               } else if (answer.data.cred === "failed") {
                  this.item().itemResponse = this.oldItemResponse();
                  this.alertService.addAlert(
                     this.lang().YouAreNoLongerAssignedThisTask,
                     "warning",
                     6000,
                  );
               } else {
                  this.item().itemResponse = this.oldItemResponse();
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            },
            () => {
               //on failure
               this.item().itemResponse = this.oldItemResponse();
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            },
         );
      return post;
   };

   exportPastResponses = (item) => {
      const usersIndex = this.manageTask.getAllUsers();
      const past: any = [];
      for (const pastResponse of item.pastResponses) {
         const obj = {};
         obj[this.lang().PastResponse] = pastResponse.itemResponse;
         obj[this.lang().CompletedBy] = `${
            usersIndex.get(pastResponse.checklistUserCompleted)?.userFirstName
         } ${usersIndex.get(pastResponse.checklistUserCompleted)?.userLastName}`;
         obj[this.lang().DueOn] = moment(pastResponse.checklistDueDate * 1000).format(
            "D MMM, YYYY",
         );
         obj[this.lang().CompletedOn] = moment(
            pastResponse.checklistCompletedDate * 1000,
         ).format("D MMM, YYYY");
         past.push(obj);
      }

      const today = moment().format("D MMM, YYYY");
      const name = `${this.lang().PastResponsesFileName} ${item.itemText} ${today}`;
      this.manageUtil.objToExcel(past, name, `${name}.xlsx`);
   };

   popTaskPmInstance = (checklistID: number) => {
      const task = this.manageTask.getTaskLocalLookup(this.item().checklistID);

      if (task?.checklistTemplate != null && task.checklistTemplate > 0) {
         this.alertService.addAlert(
            this.lang().PopTaskPMInTemplateWarningHint,
            "warning",
            10000,
         );
      } else {
         this.popTask(checklistID);
      }
   };

   protected async popTask(checklistID: number): Promise<void> {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: true,
            },
         },
      };

      await instance.result;

      const itemTypeIDNumber = Number(this.item().itemTypeID);
      if (
         itemTypeIDNumber === TaskInstructionTypeID.StartWO ||
         itemTypeIDNumber === TaskInstructionTypeID.AssignPM
      ) {
         const task = await this.manageTask.refreshLocalTask(
            Number(this.item().itemResponse),
         );
         this.item.update((item) => {
            return {
               ...item,
               task: task,
            };
         });
      }
   }

   popAsset = (assetID) => {
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         return;
      }

      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   changeAssignmentFromInstruction = () => {
      if (this.parentTask === undefined) {
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      let defaultUserID, defaultProfileID;
      if (this.item().itemWOAssignToUserID > 0) {
         defaultUserID = this.item().itemWOAssignToUserID;
         defaultProfileID = 0;
      } else if (this.item().itemWOAssignToProfileID > 0) {
         defaultUserID = 0;
         defaultProfileID = this.item().itemWOAssignToProfileID;
      } else {
         defaultUserID = this.parentTask.userID;
         defaultProfileID = this.parentTask.profileID;
      }

      if (this.item().itemWOAssignToLocked > 0) {
         //if it is locked we don't even need to ask them who to reassign to

         const instance = this.modalService.open(Confirm);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: `${this.lang().AreYouSureYouWantToAssignTheTaskTo} ${this.reassignUserTarget}?`,
               title: this.lang().ReassignTask,
            },
         };

         instance.result.then((result) => {
            if (result == 1) {
               const data: any = {};
               data.userID = defaultUserID;
               data.profileID = defaultProfileID;
               data.multiUsers = [];
               this.executeChangeAssignmentFromInstruction(data, this.item().checklistID);
            }
         });
         return;
      }

      //the selection is forced so let's have them pick
      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().ReassignThisTaskMsg,
               title: this.lang().ReassignThisTask,
               locationID: this.parentTask.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: defaultUserID,
               defaultProfile: defaultProfileID,
            },
         },
      };

      instance.result.then((data) => {
         if (data) {
            this.executeChangeAssignmentFromInstruction(data, this.item().checklistID);
         }
      });
   };

   private executeChangeAssignmentFromInstruction(data, checklistID: number) {
      if (this.limited() === false) {
         const promise = this.manageTask.changeOwnerInChk(
            data.userID,
            data.profileID,
            data.multiUsers,
            checklistID,
            this.item(),
            this.manageUser,
            this.manageProfile,
         );
         promise.then((answer: any) => {
            assert(this.info() !== undefined);
            if (answer.data.success === true) {
               this.item().itemResponse = 1;
               this.item().done = true;
               this.updateTaskStateService.guidesNeedsUpdate();
               this.updateTaskStateService.incGreyOutWatchVar();

               //closes the task they are working on.
               this.closePopTask(this.lang().successMsg);
            }
         });
      } else {
         this.item().itemResponse = 1;
         this.item().done = true;
         this.setResponse();
         this.alertService.addAlert(this.lang().ReassignThisTaskError, "success", 6000);
      }
   }

   private closePopTask(langString: string | undefined = undefined): void {
      if (this.modalService.getActiveModal()?.componentInstance instanceof PopTask) {
         this.modalService.getActiveModal()?.close();
      }
      if (langString !== undefined) {
         this.alertService.addAlert(langString, "success", 6000);
      }
   }

   deleteFile = (file) => {
      if (this.taskItemsEditable() !== true) {
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AreYouSureYouWouldLikeToDeleteThisFile,
            title: this.lang().DeleteFile,
         },
      };

      instance.result.then((result) => {
         if (result === 1) {
            const deleteData = {
               fileName: file.fileName,
               checklistID: this.item().checklistID,
               itemID: this.item().itemID,
            };
            this.manageTaskItem.deleteFile(deleteData).then(
               (answer) => {
                  if (answer.data.success === true) {
                     assert(this.info() !== undefined);
                     this.manageUtil.removeFromArray(
                        this.info().files ?? [],
                        "fileName",
                        file.fileName,
                     );

                     this.info().files = [...(this.info().files ?? [])];

                     this.item().done = (this.info().files?.length ?? 0) > 0;
                     this.updateTaskStateService.guidesNeedsUpdate();
                     const currentTask = this.manageTask.getTaskLocalLookup(
                        this.item().checklistID,
                     );
                     //set the task to have an updated last edited value **setResponse updates on php side**
                     if (currentTask !== undefined) {
                        currentTask.checklistLastEdited = Math.floor(Date.now() / 1000);
                     }

                     this.alertService.addAlert(this.lang().successMsg, "success", 750);

                     this.fileStorageSyncService.syncAttachmentInstructionFileDeletion({
                        instructionID: this.item().itemID,
                        fileName: file.fileName,
                     });
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               },
               () => {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               },
            );
         }
      });
   };

   previewPM = () => {
      const tasks = this.manageTask.getTasks();
      let checklist: Task | undefined;

      for (const tempTask of tasks) {
         if (tempTask.checklistBatchID == this.item().checklistToSpawn) {
            checklist = this.manageTask.getTaskLocalLookup(tempTask.checklistID);
         }
      }

      if (!checklist) {
         //couldn't find the task ;/
         return;
      }

      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklist.checklistID,
               editable: false,
               preview: true,
            },
         },
      };
   };

   assignAssetToNewPM = () => {
      if (!this.canUpdateStartWOStartPMInstruction) {
         return;
      }

      //if PM wasn't picked during creating Assign PM instruction
      if (!this.item().assetPMChecklistName) {
         this.alertService.addAlert(
            this.lang().PMRequiredForAssignPMInstruction,
            "danger",
            12000,
         );
         return;
      }

      let assetChosen;
      // Determine which asset the new work order will belong to
      if (this.item().itemBelongsTo > 0) {
         // pre-defined asset
         this.startPM(this.item().itemBelongsTo);
      } else if (this.item().itemBelongsTo == 0 && this.assetID() !== undefined) {
         // use the templates asset
         //this is assigned to this.assetID in the method 'this.setPMAssignAssetStr'
         this.startPM(this.assetID());
      } else if (this.item().itemBelongsTo < 0 && this.parentTask !== undefined) {
         // the user must select one first
         assetChosen = this.modalService.open(PickAssets);
         const instance = assetChosen.componentInstance;
         instance.message = this.lang().FirstSelectAnAssetOrLocationMsg;
         instance.title = this.lang().SelectAssetOrLocation;
         instance.singleLocation = this.parentTask.locationID;
         instance.selectOne = true;
         instance.restrictToCred = false;
         instance.iDontKnowOption = false;
         instance.allowPickLocation = false;

         assetChosen.result.then((asset) => {
            this.startPM(asset.assetID);
            // return; we don't need to return anything
         });
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   };

   private async startPM(startWithAssetID) {
      if (this.parentTask === undefined) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(SetupWorkOrder);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().AssignTaskPMTitle,
            data: {
               WO: false,
               locationID: this.parentTask.locationID,
               sourceItemID: this.item().itemID,
            },
         },
      };
      const data = await instance.result;
      if (!data) {
         return;
      }
      if (this.limited() === false) {
         this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
         const answer = await this.manageTask
            .assignTask(
               this.item(),
               data,
               startWithAssetID,
               this.manageUser,
               this.manageProfile,
            )
            .catch(() => {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            });

         this.loadingBarService.remove();
         if (answer?.data?.success !== true) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            return;
         }
         assert(this.info() !== undefined);
         this.item().itemResponse = answer.data.checklist.checklistID;
         this.item().done = true;
         this.updateTaskStateService.guidesNeedsUpdate();

         //set the task to have an updated last edited value
         if (this.parentTask !== undefined) {
            this.parentTask.checklistLastEdited = answer.data.lastEdited;
         }

         const currentUser = this.manageUser.getCurrentUser();
         const profiles = currentUser.profileLoc;
         let assignedToProfileIBelongTo = false;
         for (const profile of profiles) {
            if (
               profile.profileID == data.profileID &&
               profile.locationID == this.parentTask.locationID
            ) {
               assignedToProfileIBelongTo = true;
            }
         }

         if (data.userID == currentUser.gUserID || assignedToProfileIBelongTo) {
            //open the task for them since they gave it to themselves
            const instance2 = this.modalService.open(PopTask);
            this.paramsService.params = {
               modalInstance: instance2,
               resolve: {
                  data: {
                     checklistID: answer.data.checklist.checklistID,
                     editable: true,
                  },
               },
            };
         } else {
            this.alertService.addAlert(
               this.lang().PMSuccessfullyAssigned,
               "success",
               4000,
            );
         }

         this.foundPMAssigned = false;
         if (this.manageTask.getTaskLocalLookup(this.item().itemResponse)) {
            this.foundPMAssigned = true;
         }
         this.updateTaskStateService.refreshInstructions();
      } else {
         this.item().itemResponse = 2;
         this.item().done = true;
         this.setResponse();
         this.alertService.addAlert(
            this.lang().PMSuccessfullyAssignedTesting,
            "success",
            6000,
         );
      }
   }

   pickBelongsToAsset = (itemBelongsTo) => {
      if (this.parentTask === undefined) {
         return;
      }
      //function that will be used below

      if (!this.canUpdateInstruction) {
         return;
      }

      const processRequest = (tempItemBelongsTo) => {
         this.manageTask
            .updateItemBelongsTo({
               checklistID: this.item().checklistID,
               itemID: this.item().itemID,
               itemBelongsTo: tempItemBelongsTo,
            })
            .then((answer) => {
               if (answer.data.success == true) {
                  //if it saved to server, then update the scope as well
                  this.setWOBelongsTo(tempItemBelongsTo);
                  this.alertService.addAlert(this.lang().successMsg, "success", 2000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               }
            });
      };

      if (itemBelongsTo < 1) {
         // 0 & -1 do not need to be selected right now, just pass them through.
         processRequest(itemBelongsTo);
      } else {
         // if it is a 1, then the user  needs to choose the asset right now
         const modalRef = this.modalService.open(PickAssets);
         const instance = modalRef.componentInstance;
         instance.message = this.lang().FirstSelectAnAssetOrLocationMsg;
         instance.title = this.lang().SelectAssetOrLocation;
         instance.singleLocation = this.parentTask.locationID;
         instance.selectOne = true;
         instance.restrictToCred = false;
         instance.iDontKnowOption = false;
         instance.allowPickLocation = false;

         modalRef.result.then(({ assetID: tempItemBelongsTo }) => {
            //rename assetID to itemBelongsTo
            processRequest(tempItemBelongsTo);
         });
      }
   };

   // Can use with (ngModelChange)="onModelChange($event)" to call setResponse()
   // after specified debounce time.
   protected onModelChange() {
      this.modelChangeSubject.next();
   }

   protected pickWODefault() {
      const data: any = {};
      data.title = this.lang().WorkOrderTemplates;

      data.locationID = this.parentTask?.locationID ?? undefined;
      data.extraTasks = this.startWOExtraTasks.arr;
      data.locked = Number(this.item().itemWOTemplateLocked);
      data.itemWOTemplateID = this.item().itemWOTemplateID;
      data.letUserPick = true;

      const instance = this.modalService.open(WorkOrderTemplatesComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: data,
         },
      };

      instance.result.then((res) => {
         if (res != 0) {
            const params = {
               checklistID: this.item().checklistID,
               itemID: this.item().itemID,
               itemWOTemplateID: res.checklistID,
               itemWOTemplateLocked: res.woTemplateLocked,
            };
            this.manageTask
               .updateItemWODefault(params)
               .then(() => {
                  this.setWOAssignAssetTemplateStr(res.checklistID);
                  this.item().itemWOTemplateID = res.checklistID;
                  this.item().itemWOTemplateLocked = res.woTemplateLocked ? 1 : 0;
               })
               .catch(() => {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               });
         }
      });
   }

   pickWODefaultOwner = () => {
      const locationID = this.parentTask?.locationID ?? undefined;

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               title: this.lang().changeWODefaultOwner,
               message: this.lang().changeWODefaultOwnerMsg,
               locationID: locationID,
               overrideUnassignedStr: this.lang().LetTheUserPick,
               advancedOptions: true,
               extraUsers: this.startWOExtraUsers.arr,
               locked: Boolean(Number(this.item().itemWOAssignToLocked)),
               defaultUser: this.item().itemWOAssignToUserID,
               defaultProfile: this.item().itemWOAssignToProfileID,
            },
         },
      };

      instance.result.then((res) => {
         const { locked, userID, profileID, multiUsers } = res;

         const params = {
            checklistID: this.item().checklistID,
            itemID: this.item().itemID,
            itemWOAssignToProfileID: profileID,
            itemWOAssignToUserID: userID,
            itemWOAssignToLocked: locked ? 1 : 0,
            multiUsers: multiUsers,
         };

         this.manageTask
            .updateItemWOAssignDefault(params)
            .then((result) => {
               if (result.success == true) {
                  const tempUserID = result.userID;
                  const tempProfileID = result.profileID;
                  const profileDescription = result.profileDescription;
                  const tempLocationID = result.locationID;

                  if (multiUsers.length > 0) {
                     //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
                     this.manageFilters.updateHiddenProfiles(
                        {
                           profileID: tempProfileID,
                           name: profileDescription,
                           locationID: tempLocationID,
                           multiUsers,
                        },
                        this.manageTask,
                        this.manageUser,
                        this.manageProfile,
                     );
                  }

                  this.item().itemWOAssignToProfileID = tempProfileID;
                  this.item().itemWOAssignToUserID = tempUserID;
                  this.item().itemWOAssignToLocked = params.itemWOAssignToLocked;

                  this.setWOAssignUserStr({
                     userID: tempUserID,
                     profileID: tempProfileID,
                  });
                  this.setReassignUserStr({
                     userID: tempUserID,
                     profileID: tempProfileID,
                  });

                  this.alertService.addAlert(this.lang().successMsg, "success", 2000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
               }
            })
            .catch((err: unknown) => {
               console.error(err);
            });
      });
   };

   startWO = () => {
      if (!this.canUpdateStartWOStartPMInstruction) {
         return;
      }

      if (this.waitForLoadingImage) {
         return;
      }

      assert(this.parentTask);

      const startWOOnceAssetIDPicked = (assetID) => {
         const instance = this.modalService.open(SetupWorkOrder);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().SetupWorkOrderMsg,
               title: this.lang().SetupWorkOrder,
               data: {
                  WO: true,
                  assetID: assetID,
                  locationID: this.parentTask?.locationID ?? undefined,
                  defaultProfileID: Number(this.item().itemWOAssignToProfileID),
                  defaultUserID: Number(this.item().itemWOAssignToUserID),
                  defaultOwnerLocked: Number(this.item().itemWOAssignToLocked),
                  selectedTemplateID: Number(this.item().itemWOTemplateID),
                  defaultTemplateLocked: Number(this.item().itemWOTemplateLocked),
                  editChecklistName: true,
                  sourceItemID: this.item().itemID,
                  hasPredefinedAsset: hasPredefinedAsset,
               },
            },
         };

         instance.result.then((data) => {
            if (data) {
               if (this.limited() === false) {
                  this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
                  this.manageTask
                     .goLiveWorkOrder(
                        data.WO,
                        data.profileID,
                        data.userID,
                        data.multiUsers,
                        data.timestamp,
                        this.item().itemID,
                        data.timeOfDay,
                        data.startDate,
                        data.startDateTimeOfDay,
                        this.manageUser,
                        this.manageProfile,
                     )
                     .then(
                        (answer) => {
                           if (answer.data.success === true) {
                              assert(this.info() !== undefined);
                              const userID = answer.data.task.userID;
                              const profileID = answer.data.task.profileID;

                              this.item().itemResponse = data.WO.task.checklistID;
                              this.item().done = true;
                              this.updateTaskStateService.guidesNeedsUpdate();
                              this.updateTaskStateService.buildData(true);
                              const currentUser = this.manageUser.getCurrentUser();
                              const profiles = currentUser.profileLoc;
                              let assignedToProfileIBelongTo = false;
                              if (this.parentTask !== undefined) {
                                 this.manageTask.addRelation(
                                    this.item().itemID,
                                    this.item().checklistID,
                                    data.WO.task.checklistID,
                                    this.parentTask.checklistName,
                                 );

                                 //set the task to have an updated last edited value
                                 this.parentTask.checklistLastEdited =
                                    answer.data.lastEdited;

                                 for (const profile of profiles) {
                                    if (
                                       profile.profileID == profileID &&
                                       profile.locationID == this.parentTask.locationID
                                    ) {
                                       assignedToProfileIBelongTo = true;
                                    }
                                 }
                              }
                              if (
                                 userID == currentUser.gUserID ||
                                 assignedToProfileIBelongTo
                              ) {
                                 //open the task for them since they gave it to themselves
                                 const instancePT = this.modalService.open(PopTask);
                                 this.paramsService.params = {
                                    modalInstance: instancePT,
                                    resolve: {
                                       data: {
                                          checklistID: data.WO.task.checklistID,
                                          editable: true,
                                       },
                                    },
                                 };
                              } else {
                                 this.alertService.addAlert(
                                    this.lang().WorkOrderSuccessfullyStartedAndNotify,
                                    "success",
                                    5000,
                                 );
                              }

                              this.updateTaskStateService.refreshInstructions();
                           } else {
                              this.alertService.addAlert(
                                 this.lang().errorMsg,
                                 "danger",
                                 10000,
                              );
                           }
                        },
                        () => {
                           this.alertService.addAlert(
                              this.lang().errorMsg,
                              "danger",
                              10000,
                           );
                        },
                     )
                     .finally(() => {
                        this.loadingBarService.remove();
                     });
               } else {
                  this.item().itemResponse = 2;
                  this.item().done = true;
                  this.setResponse();

                  this.alertService.addAlert(
                     this.lang().PMSuccessfullyAssignedTesting,
                     "warning",
                     10000,
                  );
               }
            }
         });
      };

      // Determine which asset the new work order will belong to
      let assetChosen;
      let hasPredefinedAsset = false;
      if (this.item().itemBelongsTo > 0) {
         // pre-defined asset
         hasPredefinedAsset = true;
         startWOOnceAssetIDPicked(this.item().itemBelongsTo);
      } else if (this.item().itemBelongsTo === 0) {
         // same as the parent checklist
         hasPredefinedAsset = true;
         startWOOnceAssetIDPicked(this.parentTask.assetID);
      } else if (this.item().itemBelongsTo < 0) {
         // the user must select one first

         assetChosen = this.modalService.open(PickAssets);
         const instance = assetChosen.componentInstance;
         instance.message = this.lang().FirstSelectAnAssetOrLocationMsg;
         instance.title = this.lang().SelectAssetOrLocation;
         instance.singleLocation = this.parentTask.locationID;
         instance.selectOne = true;
         instance.restrictToCred = false;
         instance.iDontKnowOption = false;
         instance.allowPickLocation = false;

         assetChosen = assetChosen.result.then((asset) => {
            if (asset != 0) {
               startWOOnceAssetIDPicked(asset.assetID);
            }
            // return; we don't need to return anything
         });
      } else {
         startWOOnceAssetIDPicked(this.parentTask.assetID);
      }
   };

   startAnotherTask = () => {
      if (this.taskItemsEditable() !== true) {
         //don't let them do this if the task can't be edited :P
         return;
      }

      let title, msg;
      if (this.item().itemTypeID === TaskInstructionTypeID.AssignPM) {
         title = this.lang().StartAnotherPM;
         msg = this.lang().StartAnotherPMMsg;
      } else if (this.item().itemTypeID === TaskInstructionTypeID.StartWO) {
         title = this.lang().StartAnotherWO;
         msg = this.lang().StartAnotherWOMsg;
      }
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: msg,
            title: title,
         },
      };

      instance.result.then((result) => {
         if (result === 1) {
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            this.manageTaskItem
               .duplicateItem(this.item())
               .then((answer) => {
                  if (answer.data.success === true) {
                     assert(this.info() !== undefined);
                     this.updateTaskStateService.buildData(true);

                     let counter = 0;
                     const interval = setInterval(() => {
                        counter++;
                        if (!answer.data?.newItem?.myID) {
                           this.alertService.addAlert(
                              this.lang().errorMsg,
                              "danger",
                              6000,
                           );
                           clearInterval(interval);
                        }

                        if (
                           typeof $(`#itemStartTask${answer.data.newItem.myID}`)[0] !==
                           "undefined"
                        ) {
                           $(`#itemStartTask${answer.data.newItem.myID}`)[0].click();
                           clearInterval(interval);
                        }
                        if (counter > 5000) {
                           clearInterval(interval);
                        }
                     }, 100);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               })
               .finally(() => {
                  this.loadingBarService.remove();
               });
         }
      });
   };

   setSigPrep = () => {
      if (this.item().itemResponse == "") {
         this.showSigImage = false;
      } else {
         this.sigPrep = `viewFile.php?f=upload-${this.customerID}/items/${this.item().itemBatchID}/${this.item().itemResponse}`;
         this.showSigImage = true;
         const sigInfo = this.item().approvalSignatureInfo ?? "";
         if (sigInfo) {
            this.sigInfo = JSON.parse(sigInfo);
         }
      }
   };

   private canSignApproval() {
      return this.itemBelongsToCurrentUser;
   }

   protected handleApprovalSignatureClick() {
      if (!this.canSignApproval()) {
         this.alertService.addAlert(
            this.lang().NotAssignedToSignatureInstruction,
            "danger",
            5000,
         );
      }
   }

   approveApproval = (sigData) => {
      if (!this.canSignApproval()) {
         this.handleApprovalSignatureClick();
         return;
      }

      if (
         sigData === undefined ||
         sigData == undefined ||
         sigData ==
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjgAAADcCAQAAADXNhPAAAACIklEQVR42u3UIQEAAAzDsM+/6UsYG0okFDQHMBIJAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcCQADAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDkQAwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAegeayZAN3dLgwnAAAAAElFTkSuQmCC"
      ) {
         this.alertService.addAlert(this.lang().CaptureApproveSigError, "warning", 4000);
      } else {
         this.tempSigURL = sigData;
         this.item().itemResponse = sigData;
         this.item().done = true;
         this.setResponse().then(() => {
            this.manageTask.approveApproval(this.item()).then((answer) => {
               if (answer.data.success == true) {
                  this.item().checklistToSpawn = 2;
                  this.item().done = true;
                  if (this.parentTask !== undefined) {
                     this.parentTask.approvalItemState = 0;
                  }
                  if (
                     this.item().onApproveUserID > 0 ||
                     this.item().onApproveProfileID > 0
                  ) {
                     //on approve we are changing approval so let's close out this task

                     //we need to close them out.
                     this.alertService.addAlert(
                        this.lang().ApprovalHasBeenSuccessfullyApproved,
                        "success",
                        10000,
                     );

                     this.closePopTask();
                  } else {
                     assert(this.info() !== undefined);
                     //since it is sticking with us let's
                     //now that this is approved we need to see if we should finish this checklist automatically or not...

                     if (
                        this.taskFormHelperService.calcCompletion(
                           this.info().items,
                           this.item().itemID,
                        )
                     ) {
                        //the task is completed so let's go ahead and close out the task
                        this.updateTaskStateService.completeTask();
                     }

                     this.updateTaskStateService.incGreyOutWatchVar();
                     this.updateTaskStateService.guidesNeedsUpdate();
                  }

                  const instructionID = Number(this.item().itemID);
                  this.instructionStorageSyncService.syncInstruction(instructionID);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         });
      }
   };

   disapproveApproval = () => {
      if (!this.canSignApproval()) {
         this.handleApprovalSignatureClick();
         return;
      }

      const instance = this.modalService.open(DisapproveApproval);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: this.lang().DisapproveApprovalTitle,
               item: this.item(),
            },
         },
      };

      instance.result.then((result) => {
         if (result) {
            this.manageTask
               .disapproveApproval(
                  this.item(),
                  result.userID,
                  result.profileID,
                  result.multiUsers,
                  result.reason,
                  this.manageUser,
                  this.manageProfile,
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     assert(this.info() !== undefined);
                     this.loadingBarService.remove();
                     this.item().checklistToSpawn = 0;
                     this.item().done = false;
                     if (this.parentTask !== undefined) {
                        this.parentTask.approvalItemState = 2;
                     }

                     //we need to close them out.
                     this.alertService.addAlert(
                        this.lang().ApprovalHasBeenSuccessfullyDisapproved,
                        "success",
                        10000,
                     );

                     this.closePopTask();
                     this.updateTaskStateService.incGreyOutWatchVar();
                     const instructionID = Number(this.item().itemID);
                     this.instructionStorageSyncService.syncInstruction(instructionID);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   completeSignature = (sigData) => {
      this.showSigImage = false;
      if (
         sigData === undefined ||
         sigData === true ||
         sigData ===
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjgAAADcCAQAAADXNhPAAAACIklEQVR42u3UIQEAAAzDsM+/6UsYG0okFDQHMBIJAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcCQADAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDkQAwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAegeayZAN3dLgwnAAAAAElFTkSuQmCC"
      ) {
         this.sigPrep = {};
         this.alertService.addAlert(this.lang().CaptureSigError, "warning", 4000);
      } else if (sigData === "autoSign") {
         // Handles autoSign scenario, the correct itemResponse is assigned later via the php
         this.item().itemResponse = "autoSign";
         this.item().done = true;
         this.setResponse();
      } else {
         this.item().itemResponse = sigData;
         this.item().done = true;
         this.setResponse();
      }
   };

   clearSignature = () => {
      if (this.info() === undefined) return;
      this.showSigImage = false;
      this.item().done = false;
      this.item().itemResponse = "";
      this.setResponse();
      if (this.item().itemTypeID === TaskInstructionTypeID.RequestApproval) {
         this.item().checklistToSpawn = 1;
         this.updateTaskStateService.incGreyOutWatchVar();
      }
   };

   /************************
    *
    *
    *       TASK BUILDER SECTION
    *
    *
    **********************/

   public updateItemText(): boolean {
      if (!this.canUpdateInstruction) {
         return false;
      }

      this.manageTaskItem.updateItemText(this.item()).then((answer) => {
         if (answer.data.success === true) {
            if (this.instructionSetTemplate) {
               this.updateParentInstructionSetTimeStamp();
            }
            if (!this.disableAlerts()) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            }
            this.updateTaskStateService.setTask();
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });

      return true;
   }

   updateOptionText = (option) => {
      this.manageTaskItemOption.updateOptionText(option, this.item()).then((answer) => {
         if (answer.data.success === true) {
            if (!this.disableAlerts()) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            }
            this.updateTaskStateService.setTask();
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   protected assignTaskConfig() {
      if (!this.canUpdateStartWOStartPMInstruction || this.parentTask === undefined) {
         return;
      }

      const locationID = this.parentTask.locationID;

      const instance = this.modalService.open(PickTaskTemplateModalComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            locationID,
         },
      };

      instance.result.then((task) => {
         if (task != 0) {
            this.manageTaskItem
               .linkAssignTask(this.item(), task.checklistID)
               .then((answer) => {
                  if (answer.data.success === true) {
                     this.item().itemText = answer.data.checkName;
                     this.item().checklistToSpawn = answer.data.checklistToSpawn;
                     if (!this.disableAlerts()) {
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     }
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   }

   viewImage = (itemFileName, itemBatchID) => {
      const imagePath = `viewFile.php?f=upload-${this.customerID}/items/${
         itemBatchID || this.item().itemBatchID
      }/${itemFileName}`;

      this.manageFiles.createImageViewer(imagePath);
   };

   checkFirstInstructionTextSelectAll = (str, obj) => {
      if (!this.canUpdateInstruction) {
         return;
      }

      if (str == this.lang().Instructions && obj.firstHighlightHappened === undefined) {
         document.execCommand("selectAll", false, undefined);
         obj.firstHighlightHappened = true;
      }
   };

   changeItemType = (item) => {
      if (Number(item.itemTypeID) === TaskInstructionTypeID.InstructionSet) {
         //prevent users from changing instruction sets to a different type of chkItem
         this.alertService.addAlert(
            this.lang().InstructionSetsCannotHaveTheirTypeChanged,
            "warning",
            6000,
         );
         return;
      }
      const instance = this.modalService.open(PickItem);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().changeItemTypeMsg,
            title: this.lang().ChangeInstruction,
            button: this.lang().Change,
         },
      };

      instance.result.then((newItemTypeID) => {
         if (newItemTypeID > 0) {
            //they picked the same item typeID so we shouldn't change it
            if (item.itemTypeID === newItemTypeID) {
               return;
            }

            const runPost = () => {
               this.manageTaskItem.changeItemType(item, newItemTypeID).then((answer) => {
                  if (answer.data.success === true) {
                     assert(this.info() !== undefined);
                     this.updateTaskStateService.buildData(true);

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
            };

            //if it is a radio/option list or dropdown list and it is going to something other then we need to problem them.
            if (
               item.itemTypeID == TaskInstructionTypeID.OptionList ||
               item.itemTypeID == TaskInstructionTypeID.DropdownList
            ) {
               if (
                  newItemTypeID != TaskInstructionTypeID.OptionList &&
                  newItemTypeID != TaskInstructionTypeID.DropdownList
               ) {
                  const tempInstance = this.modalService.open(Confirm);

                  this.paramsService.params = {
                     modalInstance: tempInstance,
                     resolve: {
                        message: this.lang().changeItemTypeWarningMsg,
                        title: this.lang().changeItemTypeWarning,
                     },
                  };

                  tempInstance.result.then((result) => {
                     if (result == 1) {
                        runPost();
                     }
                  });
               } else {
                  runPost();
               }
            } else {
               runPost();
            }
         }
      });
   };

   public openPart() {
      assert(this.relatedPart);
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: this.relatedPart.partID,
            locationID: this.relatedPart.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   updateParentInstructionSetTimeStamp = () => {
      this.manageTaskItem.updateParentInstructionSetTimeStamp(this.item().checklistID);
   };

   public async verifyLocation() {
      if (!this.canUpdateCustomApprovalInstruction) {
         return;
      }

      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });

      const maybeGetErrorMessage = async () => {
         const geoLocation = this.getGeoReference(this.item())?.refObj.geoLocation;
         const geoToCheck: GeoCoordinates | undefined =
            geoLocation?.geometry?.coordinates;
         if (!geoToCheck) {
            return this.lang().CouldNotFindMapLocationInformationOnTaskOrAsset;
         }

         const currentGeo = await this.manageMap.getDeviceLocationInGeoJsonCoordsFormat();
         if (!currentGeo) {
            return this.lang().CouldNotGetYourCurrentLocation;
         }

         const closeEnough = this.manageMap.checkIfImCloseEnough(geoToCheck, currentGeo);
         if (!closeEnough) {
            return this.lang().SorryLocationVerificationFailed;
         }

         return undefined;
      };
      const errorMessage = await maybeGetErrorMessage();

      if (errorMessage !== undefined) {
         this.alertService.addAlert(errorMessage, "danger", 6000);
         this.loadingBarService.remove();
         return;
      }

      this.item().itemResponse = Date.now();
      this.alertService.addAlert(
         this.lang().CongratulationsLocationSuccessfullyVerified,
         "success",
         6000,
      );
      this.item().done = true;
      this.setResponse();
      assert(this.info() !== undefined);
      this.updateTaskStateService.incGreyOutWatchVar();
      this.loadingBarService.remove();
   }

   public async checkMyLocation() {
      if (!this.canUpdateCustomApprovalInstruction) {
         return;
      }

      const geoRef = this.getGeoReference(this.item());
      const locationID = geoRef?.refObj.locationID;
      const geoLocation = geoRef?.refObj.geoLocation;
      const geoToCheck = geoLocation?.geometry?.coordinates;

      if (!geoToCheck) {
         this.alertService.addAlert(
            this.lang().CouldNotFindMapLocationInformationOnTaskOrAsset,
            "danger",
            6000,
         );
         return;
      }

      const currentGeo = await this.manageMap.getDeviceLocationInGeoJsonCoordsFormat();
      if (!currentGeo) {
         this.alertService.addAlert(
            this.lang().CouldNotGetYourCurrentLocation,
            "danger",
            6000,
         );
         return;
      }

      const closeEnough = this.manageMap.checkIfImCloseEnough(geoToCheck, currentGeo);

      const instance = this.modalService.open(ViewMap);
      let title = "";
      if (closeEnough) {
         title = this.lang().YouAreCloseEnoughToCheckin;
      } else {
         title = this.lang().SorryYouAreNotCloseEnoughToCheckin;
      }

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            asset: geoRef?.refType === "asset" ? geoRef.refObj : undefined,
            task: geoRef?.refType === "task" ? geoRef.refObj : undefined,
            locationID: locationID,
            title: title,
            canEdit: false,
            limitedMode: true,
            showMyLocation: true,
         },
      };
   }

   private getGeoReference(item): GeoReference | undefined {
      assert(item.itemTypeID === TaskInstructionTypeID.VerifyLocation);
      if (this.parentTask === undefined) {
         return undefined;
      }
      if (item.itemBelongsTo == -1) {
         if (this.parentTask.geoLocation !== undefined) {
            const refObj = {
               locationID: this.parentTask.locationID,
               geoLocation: this.parentTask.geoLocation as GeoFeature,
            };
            return {
               refType: "task",
               refObj,
            };
         }
      }
      let assetID = item.itemBelongsTo;
      if (assetID === 0) {
         assetID = this.parentTask.assetID;
      }
      if (!assetID) {
         return undefined;
      }
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset?.geoLocation) {
         return undefined;
      }
      const refObj = { locationID: asset.locationID, geoLocation: asset.geoLocation };
      return { refType: "asset", refObj };
   }

   private setItemBelongsToCurrentUser(): void {
      const currentUser = this.manageUser.getCurrentUser();
      const item = this.item;

      const locationProfileIDs = this.manageUser.getCurrentUserProfileIDsAtLocation(
         this.parentTask?.locationID,
      );

      const isAssignedToCurrentUserID = item().itemUserID === currentUser.gUserID;
      const isAssignedToCurrentUserProfileLoc = locationProfileIDs.includes(
         item().itemProfileID,
      );

      this.itemBelongsToCurrentUser =
         isAssignedToCurrentUserID || isAssignedToCurrentUserProfileLoc;
   }

   private setTaskBelongsToCurrentUser(): void {
      const currentUser = this.manageUser.getCurrentUser();

      const locationProfileIDs = this.manageUser.getCurrentUserProfileIDsAtLocation(
         this.parentTask?.locationID,
      );
      if (this.parentTask !== undefined) {
         const isAssignedToCurrentUserID = this.parentTask.userID === currentUser.gUserID;
         let isAssignedToCurrentUserProfileLoc: boolean = false;
         if (this.parentTask.profileID !== null) {
            isAssignedToCurrentUserProfileLoc = locationProfileIDs.includes(
               this.parentTask.profileID ?? -1,
            );
         }
         this.taskBelongsToCurrentUser =
            isAssignedToCurrentUserID || isAssignedToCurrentUserProfileLoc;
      }
   }

   private resetComponentStatePreviousOption(): void {
      if (this.item().itemTypeID !== 2 && this.item().itemTypeID !== 4) {
         return;
      }
      this.componentStatePreviousOption = Number(this.item().itemResponse);
   }
}
