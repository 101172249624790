import type { OnInit } from "@angular/core";
import { Component, Input, ViewChild, computed, inject } from "@angular/core";
import {
   BlockIconComponent,
   DropdownItemComponent,
   DropdownMentionComponent,
   LimbleHtmlDirective,
   isMobile,
} from "@limblecmms/lim-ui";
import clone from "rfdc";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";

const deepClone = clone();

@Component({
   selector: "mention",
   templateUrl: "./mention.html",
   styleUrls: ["./mention.scss"],
   imports: [
      DropdownMentionComponent,
      DropdownItemComponent,
      UserImage,
      BlockIconComponent,
      LimbleHtmlDirective,
   ],
})
export class Mention implements OnInit {
   @Input() public mentionList;
   @Input() public userProfiles;
   @Input() public updateNewMentionedList;
   @Input() public dropdownDirection; // @
   @Input() public mentionUid;
   @Input() parentElement!: HTMLElement;

   public newMentionedList;
   public currentWord;
   public currentHTMLWord;
   public listFilter;
   public filteredList;
   public cursorHtmlLocation;
   public cursorTextLocation;
   public dropdownOffsetX;
   public dropdownOffsetY;
   public userAgent;
   public isFirefox;
   public lineCount;
   public isW3;
   public isMobile;
   public addMentionInputEl;
   public dropdownEl;
   public dropdownMenuEl;
   public sliceLength;
   private lastKnownRange;
   private trackRange = false;

   @ViewChild(DropdownMentionComponent) public myDropdown:
      | DropdownMentionComponent
      | undefined;

   private readonly manageLang = inject(ManageLang);
   private readonly alertService = inject(AlertService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      //
      // Initialize Data
      //

      /** newMentionedList is the list of items mentioned in the textblock */
      this.newMentionedList = [];
      /** currentWord tracks what the currently selected word is as the innerText of our input element */
      this.currentWord = "";
      /** currentHTMLWord tracks what the currently selected word is as the innerHTML of our input element */
      this.currentHTMLWord = "";
      /** listFilter is the current word minus the @ symbol and is used to filter our dropdown list */
      this.listFilter = "";
      this.filteredList = [];
      this.cursorHtmlLocation = 0;
      this.cursorTextLocation = 0;
      this.dropdownOffsetX = 0;
      this.dropdownOffsetY = 0;

      this.userAgent = window.navigator.userAgent;
      this.isFirefox = this.userAgent.match(/Firefox\/([0-9]+)\./);
      this.isW3 = window.getSelection !== undefined && true;
      this.isMobile = isMobile();
      //
      // Event Listeners
      //
      setTimeout(() => {
         this.sliceLength = 8 + this.mentionUid.length;
         /** addMentionInputEl is our input field DOM element */
         this.addMentionInputEl =
            document.getElementById(`input-field-${this.mentionUid}`) ?? undefined;
         this.dropdownEl = document.getElementById(`myDropdown-${this.mentionUid}`);
         this.dropdownMenuEl = document.getElementById(
            `myDropdownMenu-${this.mentionUid}`,
         );
         /** addMentionInputEl keyup listener */
         this.addMentionInputEl?.addEventListener("keyup", (event) => {
            this.commentChanged(event);
            // We start to track the range when the user presses the @ key.
            // The reason we want to do that is to reference it later, when the event happens outside of the input field.
            if (this.trackRange) {
               const sel = window.getSelection();
               if (sel?.rangeCount) {
                  this.lastKnownRange = sel.getRangeAt(0);
               }
            }
         });

         /** addMentionInputEl keydown listener */
         this.addMentionInputEl?.addEventListener("keydown", (event) => {
            this.mapKeys(event);
         });

         /** addMentionInputEl click listener */
         this.addMentionInputEl?.addEventListener("click", () => {
            this.setCurrentWord();
         });
      }, 50);
   }

   //
   // Main Functions
   //

   /** commentChanged is ran every time a keypress occurs in our comment input field */
   commentChanged = (event) => {
      this.setCurrentWord();
      this.verifyCurrentMentions();
      if (this.myDropdown?.isOpen()) {
         const keyCode = event.keyCode;
         if (!(keyCode === 38 || keyCode === 40 || keyCode === 9 || keyCode === 13)) {
            setTimeout(() => {
               this.resetDropdownFocus();
            }, 50);
         }
      }
   };

   /** mapKeys is ran every time a keydown occurs in our comment input field */
   mapKeys = (event) => {
      if (this.myDropdown?.isOpen()) {
         switch (event.keyCode) {
            case 38: // up arrow key
               event.preventDefault();
               this.moveDropdownFocus("up");
               break;
            case 40: // down arrow key
               event.preventDefault();
               this.moveDropdownFocus("down");
               break;
            case 9: // tab key
               event.preventDefault();
               this.selectFocusedItem();
               break;
            case 13: // enter key
               event.preventDefault();
               this.selectFocusedItem();
               break;
            default:
         }
      }
   };

   /** moveDropdownFocus moves the focus of items in dropdown menu */
   moveDropdownFocus = (direction) => {
      const menuItems = this.dropdownMenuEl.querySelectorAll(".comment-dropdown-item");
      const currentFocus = this.dropdownMenuEl.querySelector(
         ".comment-dropdown-item-has-focus",
      );

      const currentIndex = currentFocus ? Number(currentFocus.dataset.index) : null;
      const listLength = menuItems.length;
      if (menuItems && listLength > 0) {
         if (currentFocus) {
            currentFocus.classList.remove("comment-dropdown-item-has-focus");
         }
         if (listLength === 1 || (!currentIndex && currentIndex !== 0)) {
            menuItems[0].classList.add("comment-dropdown-item-has-focus");
         } else if (currentIndex === listLength - 1 && direction === "down") {
            menuItems[0].classList.add("comment-dropdown-item-has-focus");
         } else if (currentIndex === 0 && direction === "up") {
            menuItems[listLength - 1].classList.add("comment-dropdown-item-has-focus");
         } else if (direction === "down") {
            menuItems[currentIndex + 1].classList.add("comment-dropdown-item-has-focus");
         } else if (direction === "up") {
            menuItems[currentIndex - 1].classList.add("comment-dropdown-item-has-focus");
         }
      }

      this.dropdownMenuEl
         .querySelector(".comment-dropdown-item-has-focus")
         .scrollIntoView({
            behavior: "smooth",
            block: "nearest",
         });
   };

   /** resetDropdownFocus sets the initial focus on dropdown */
   resetDropdownFocus = () => {
      const menuItems = this.dropdownMenuEl.querySelectorAll(".comment-dropdown-item");
      const currentFocus = this.dropdownMenuEl.querySelector(
         ".comment-dropdown-item-has-focus",
      );
      if (menuItems && menuItems.length > 0) {
         if (currentFocus) {
            currentFocus.classList.remove("comment-dropdown-item-has-focus");
         }
         menuItems[0].classList.add("comment-dropdown-item-has-focus");
      }
   };

   /** selectFocusedItem selects the currently focused item in the dropdown menu */
   selectFocusedItem = () => {
      const focusedItem = this.dropdownMenuEl.querySelector(
         ".comment-dropdown-item-has-focus",
      );

      focusedItem.classList.remove("comment-dropdown-item-has-focus");
      if (focusedItem) {
         let itemData;
         itemData = this.mentionList.filter(
            (item) => item.profileID === Number(focusedItem.dataset.profileid),
         );
         if (itemData && itemData.length == 1) {
            this.addMention(itemData[0]);
         } else {
            itemData = this.mentionList.filter(
               (item) =>
                  item.itemID === focusedItem.dataset.itemid ||
                  item.userID === Number(focusedItem.dataset.itemid) ||
                  item.profileID === Number(focusedItem.dataset.itemid),
            );
            this.addMention(itemData[0]);
         }
      }
   };

   /** toggleAddItemDropdown toggles the dropdown menu */
   toggleAddItemDropdown = () => {
      if (this.myDropdown?.isOpen()) {
         this.myDropdown?.close();
      } else {
         this.myDropdown?.open();
      }
   };

   /** setCurrentWord runs on change or click and sets the current word and checks whether
    * dropdown should be toggled either open or closed.
    */
   setCurrentWord = () => {
      this.setCursorPositions();
      if (
         this.addMentionInputEl !== undefined &&
         deepClone(this.addMentionInputEl.innerText).replace(/(\r\n|\n|\r)/gm, "") ==
            "" &&
         this.lineCount == 1
      ) {
         this.addMentionInputEl.innerHTML = "";
      }
      this.currentWord = this.getCurrentWord();
      const currentElement = this.getCurrentElement();
      if (
         this.currentWord.slice(0, 1) === "@" &&
         !this.myDropdown?.isOpen() &&
         currentElement?.nodeName !== "SPAN"
      ) {
         this.filterList();
         this.myDropdown?.open();
         this.trackRange = true;
      } else if (this.currentWord.slice(0, 1) !== "@" && this.myDropdown?.isOpen()) {
         this.myDropdown?.close();
      }
      if (this.myDropdown?.isOpen()) {
         this.filterList();
      }
   };

   filterList = () => {
      if (this.currentWord.length > 0) {
         this.listFilter = this.currentWord.slice(1);
      } else {
         this.listFilter = "";
      }
      this.filteredList = this.mentionList.filter((item) => {
         if (item.profileHidden) {
            return false;
         }
         const searchString = `${item.userFirstName ? item.userFirstName : ""} ${
            item.userFirstName && item.userLastName
               ? item.userFirstName + item.userLastName
               : ""
         } ${item.userLastName ? item.userLastName : ""} ${
            item.profileDescription ? item.profileDescription : ""
         } ${item.tagDescription ? item.tagDescription : ""}`;

         return searchString.toLowerCase().trim().includes(this.listFilter.toLowerCase());
      });
      if (this.filteredList.length === 0) {
         this.toggleAddItemDropdown();
      }
   };

   /** The purpose of this function is to, for each mention tag found in the DOM, compare its saved 'name' attribute
    * against the actual text found in the tag.  If the text has been edited or deleted, the tag must be rendered
    * invalid and the associated user/profile must be removed from the list of notifications.
    */
   private verifyCurrentMentions() {
      const mentionedTags: Array<HTMLElement> = this.getMentionedTags();

      this.newMentionedList = [];

      for (const mentionedTag of mentionedTags) {
         const mentionedTagName = mentionedTag.dataset.name;

         // The text in the tag will always have an ampersand at the beginning and may have
         // white space at the end.  These need to be removed for a proper comparison against
         // the tag's 'name'.
         // Example: The text "@Some User " must be compared with "Some User"
         const tagText = mentionedTag.innerText.slice(1).trimEnd();

         const mentionedTagID: string = mentionedTag.id.slice(this.sliceLength);

         if (mentionedTagName === tagText) {
            const tagHtmlHasTrailingSpace = mentionedTag.innerHTML.endsWith("&nbsp;");
            if (tagHtmlHasTrailingSpace) {
               this.handleTrailingSpaceOnTag(mentionedTagID);
            }
            this.addItemsMentionedToNotificationList(mentionedTagID);
         } else {
            this.removeItemMention(mentionedTag.id.slice(this.sliceLength));
         }
      }

      this.updateNewMentionedList(this.newMentionedList);
   }

   private getMentionedTags(): Array<HTMLElement> {
      return Array.from(this.addMentionInputEl?.querySelectorAll(".mentionedItem"));
   }

   private addItemsMentionedToNotificationList(mentionedTagID: string) {
      const newMentionTemp = this.mentionList.filter((itemPossiblyMentioned) =>
         this.checkIfItemWasMentioned(itemPossiblyMentioned, mentionedTagID),
      );

      this.newMentionedList.push(...newMentionTemp);
   }

   /**
    * This method looks at an item that was possibly mentioned, which could be a tag, user, or profile.
    * It compares the mentioned html element to the item to possibly mention, and returns true if we found it.
    * This is used to filter all possibly mentioned items to find the ones that were actually mentioned.
    */
   private checkIfItemWasMentioned(
      itemPossiblyMentioned,
      mentionedTagID: string,
   ): boolean {
      const tagWasMentioned: boolean = itemPossiblyMentioned.itemID === mentionedTagID;
      const userWasMentioned: boolean =
         itemPossiblyMentioned.userID === Number(mentionedTagID) &&
         !itemPossiblyMentioned.profileID;

      const profileWasMentioned: boolean =
         itemPossiblyMentioned.profileID === Number(mentionedTagID);

      if (tagWasMentioned || userWasMentioned || profileWasMentioned) {
         return true;
      }
      return false;
   }

   /** addMention replaces the plain text name of the item with the stylized span element,
    * and also adds that item's data to the newMentionedItem array.
    */
   private addMention(item): void {
      const itemID: number | string = this.getItemID(item);

      const isItemCurrMentioned = this.isItemCurrentlyMentioned(itemID);
      if (isItemCurrMentioned.length === 0) {
         this.insertMentionTag(item);
      } else {
         let itemName = "";
         if (item.userFirstName) itemName = `${item.userFirstName} ${item.userLastName}`;
         else if (item.tagDescription) itemName = item.tagDescription;
         this.alertService.addAlert(
            `${this.lang().YouHaveAlreadyMentioned} ${itemName}`,
            "warning",
            5000,
         );
      }
   }

   public getItemID(item: any): number | string {
      if (item.profileID) {
         return item.profileID;
      }
      if (item.userID) {
         return item.userID;
      }
      if (item.itemID) {
         return item.itemID;
      }
      return 0;
   }

   /**
    * Adds a mention to the contenteditable element at the current cursor position.
    * The mention is wrapped in a span element with a specific style and ID.
    * This function also updates the mentioned list and the cursor position.
    *
    * @param {Object} item - The object containing the mention data.
    */
   private insertMentionTag(item): void {
      let itemName = "";
      const itemID = this.getItemID(item);
      if (item.userFirstName) itemName = item.userFirstName.trim();
      if (item.userLastName && itemName !== "") {
         itemName = `${itemName} ${item.userLastName.trim()}`;
      } else if (item.userLastName && itemName === "") {
         itemName = item.userLastName.trim();
      } else if (itemName === "" && item.profileDescription) {
         itemName = item.profileDescription.trim();
      } else if (item.tagDescription) {
         itemName = item.tagDescription.trim();
      }

      const nameLength = itemName.length + 1;
      let prevCharSpace =
         this.addMentionInputEl.innerHTML.slice(
            this.cursorHtmlLocation - 6,
            this.cursorHtmlLocation,
         ) === "&nbsp;"
            ? 6
            : 0;

      // Create a span element for the mention and set its properties
      const mentionTag = document.createElement("span");
      mentionTag.id = `itemId-${this.mentionUid}-${itemID}`;
      mentionTag.style.color = "#4684d0";
      mentionTag.dataset.name = itemName;
      mentionTag.classList.add("mentionedItem");

      // Create a text node with the mention text and a non-breaking space
      const mentionText = document.createTextNode(`@${itemName}\u00A0`);

      // Append the text node as a child of the mentionTag element
      mentionTag.appendChild(mentionText);

      // Get the current selection and range
      const sel = window.getSelection();

      if (sel?.rangeCount) {
         const currentRange = sel.getRangeAt(0);

         // Remove the current word and any previous whitespace
         currentRange.setStart(
            currentRange.startContainer,
            Math.max(
               0,
               currentRange.startOffset - this.currentWord.length - prevCharSpace,
            ),
         );
         currentRange.deleteContents();

         // Re-calculate prevCharSpace after deletion
         prevCharSpace =
            (currentRange.startContainer.textContent ?? "").slice(
               currentRange.startOffset - 1,
               currentRange.startOffset,
            ) === " "
               ? 1
               : 0;

         // Insert the mention tag into the contenteditable element
         currentRange.insertNode(mentionTag);

         // Move the cursor to the right of the mention tag if the lastChild exists
         if (mentionTag.lastChild) {
            currentRange.setStartAfter(mentionTag.lastChild);
            currentRange.setEndAfter(mentionTag.lastChild);
         }

         // Update the selection with the modified range
         sel.removeAllRanges();
         sel.addRange(currentRange);
      }
      this.newMentionedList.push(item);
      this.updateNewMentionedList(this.newMentionedList);

      this.cursorTextLocation =
         this.cursorTextLocation + nameLength - this.currentWord.length;
      if (prevCharSpace) {
         this.cursorTextLocation = this.cursorTextLocation - 1;
      }

      if (this.currentWord.length > 0) {
         this.cursorTextLocation = this.cursorTextLocation + 1;
      }
      this.setCaretPosition(this.addMentionInputEl, this.cursorTextLocation);
      this.setCursorPositions();
      this.cursorTextLocation = this.cursorTextLocation + 1;
      this.currentWord = "";
      this.trackRange = false;
   }

   /** removeItemMention removes the item mentions span styling in the innerHTML, and replaces it with
    * just the innerText that was in the span.
    */
   removeItemMention = (itemID) => {
      this.setCursorPositions();
      const innerText = document.getElementById(
         `itemId-${this.mentionUid}-${itemID}`,
      )?.innerText;
      const tempReg = `<\\s*span id="itemId-${this.mentionUid}-${itemID}"[^>]*>(.*?)<\\s*\\/s*span>`;
      const regex = new RegExp(tempReg, "g");
      this.addMentionInputEl.innerHTML = this.addMentionInputEl.innerHTML.replace(
         regex,
         innerText,
      );

      this.setCaretPosition(this.addMentionInputEl, this.cursorTextLocation);
   };

   /**
    * moveSpaceToDiv is used to prevent editing text within the mentioned item's span element.
    * When the user is at the end of a mention and presses the space bar, this function
    * removes the non-breaking space from within the span, and adds it to the contenteditable
    * element, right after the mentioned item's span element. It also moves the cursor accordingly.
    *
    * @param {string} itemID - The ID of the mentioned item.
    */
   handleTrailingSpaceOnTag = (itemID) => {
      this.setCursorPositions();
      const spanElement = document.getElementById(`itemId-${this.mentionUid}-${itemID}`);
      if (spanElement === null) {
         return;
      }

      // Check if the span element contains a non-breaking space at the end
      if (spanElement?.textContent?.endsWith("\u00A0")) {
         const mentionText = spanElement.textContent.slice(0, -1);
         spanElement.textContent = mentionText;
      }

      const sel = window.getSelection();

      if (sel?.rangeCount) {
         const currentRange = sel.getRangeAt(0);

         // Add a non-breaking space outside the span element
         const nonBreakingSpace = document.createTextNode("\u00A0");
         currentRange.setStartAfter(spanElement);
         currentRange.setEndAfter(spanElement);
         currentRange.insertNode(nonBreakingSpace);

         // Move the cursor to the right of the non-breaking space
         currentRange.setStartAfter(nonBreakingSpace);
         currentRange.setEndAfter(nonBreakingSpace);
         sel.removeAllRanges();
         sel.addRange(currentRange);
      }
   };

   isItemCurrentlyMentioned = (itemID) => {
      return this.newMentionedList.filter(
         (item) =>
            item.itemID === itemID || item.userID === itemID || item.profileID === itemID,
      );
   };

   /**
    * Since the click event is on the dropdown, we need to get the last known range
    * from the contenteditable div and then set the focus back to the div.
    *
    * @param item Selected item from the dropdown
    */
   public handleDropdownItemClick(item: any): void {
      if (this.addMentionInputEl && this.lastKnownRange) {
         this.addMentionInputEl.focus(); // Focus the input element

         // Restore the last known range
         const sel = window.getSelection();
         if (sel) {
            sel.removeAllRanges();
            sel.addRange(this.lastKnownRange);

            this.addMention(item); // Call the addMention function with the item
            this.verifyCurrentMentions();
         }
      }
   }

   //
   // Helper Functions
   //

   setCursorPositions = () => {
      const selection = window.getSelection();
      if (this.isW3 && selection && (document.getSelection()?.rangeCount ?? 0) > 0) {
         const tmp = document.createElement("div");
         const range = selection.getRangeAt(0);
         const preCaretRange = range.cloneRange();
         preCaretRange.selectNodeContents(this.addMentionInputEl);
         preCaretRange.setEnd(range.endContainer, range.endOffset);
         tmp.appendChild(preCaretRange.cloneContents());

         // Calculate the length of innerHTML between line breaks.
         let innerHtmlLength = tmp.innerHTML.length;
         const divMatches = tmp.innerHTML.match(/<div>/g) ?? [];
         const closeDivMatches = tmp.innerHTML.match(/<\/div>/g) ?? [];
         innerHtmlLength -= divMatches.length * 5 + closeDivMatches.length * 6;

         this.cursorTextLocation = preCaretRange.toString().length;
         this.cursorHtmlLocation = innerHtmlLength;
         this.lineCount = (tmp.innerHTML.match(/<br>/g) ?? []).length + 1;
      }
   };

   /** setCaretPosition is used to reset the caret position after selecting an item mention */
   setCaretPosition = (element, caretPosition, offset = 0) => {
      element.focus();
      const range = this.createRange(element, {
         count: caretPosition + offset,
      });
      range.collapse(false);
      const sel = window.getSelection();
      if (sel) {
         sel.removeAllRanges();
         sel.addRange(range);
      }
   };

   /** createRange gets the range used for setting the current caret position in the input field */
   createRange = (node, chars, tempRange?) => {
      let range = tempRange;
      if (!range) {
         range = document.createRange();
         range.selectNode(node);
         range.setStart(node, 0);
      }

      if (chars.count === 0) {
         range.setEnd(node, chars.count);
      } else if (node && chars.count > 0) {
         if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
               chars.count -= node.textContent.length;
            } else {
               range.setEnd(node, chars.count);
               chars.count = 0;
            }
         } else {
            for (const childNode of node.childNodes) {
               range = this.createRange(childNode, chars, range);

               if (chars.count === 0) {
                  break;
               }
            }
         }
      }
      return range;
   };

   /** strSplice splices a string together */
   strSplice = (str, tempIndex, count, add) => {
      // We cannot pass negative indexes directly to the 2nd slicing operation.
      let index = tempIndex;
      if (index < 0) {
         index = str.length + index;
         if (index < 0) {
            index = 0;
         }
      }
      return str.slice(0, index) + (add || "") + str.slice(index - 1 + count);
   };

   /** getCurrentWord gets the current word where the caret is located in the input field */
   getCurrentWord = () => {
      let sel: any = "";
      let word = "";
      let char = "";
      const selection: any = window.getSelection();

      if (this.isMobile) {
         const tempStr = this.addMentionInputEl.innerText.replace(/(\r\n|\n|\r)/gm, " ");
         const tempPos = this.cursorTextLocation;

         // Perform type conversions.
         const str = String(tempStr);
         let pos = Number(tempPos);
         pos = pos - 2 + this.lineCount;

         // str = str.replace(/&nbsp;/g, " ");
         // Search for the word's beginning and end.
         const left = str.slice(0, pos + 1).search(/\S+$/);
         const right = str.slice(pos).search(/\s/);
         // The last word in the string is a special case.
         if (left < 0) {
            word = "";
         } else if (right < 0) {
            word = str.slice(left);
         } else {
            // Return the word, using the located bounds to extract it from the string.
            word = str.slice(left, right + pos);
         }
      } else if (selection && selection.rangeCount > 0 && (sel = selection).modify) {
         const selectedRange = sel.getRangeAt(0);
         sel.collapseToStart();
         sel.modify("extend", "backward", "word");
         word = sel.toString();
         sel.modify("extend", "backward", "character");
         char = sel.toString().trim();
         sel.modify("extend", "backward", "character");
         const prevChar = sel.toString().trim();

         if (this.isFirefox) {
            if (
               (word.endsWith("@") && word.charAt(word.length - 2) === " ") ||
               (word === "@" && char === "@")
            ) {
               word = word.charAt(word.length - 1);
            } else {
               word = prevChar;
            }
         } else if (
            (char !== "@" && word !== "@") ||
            (prevChar.length == 3 && prevChar.slice(2) === "@" && char !== "@")
         ) {
            word = prevChar;
         }

         // Restore selection
         sel.removeAllRanges();
         sel.addRange(selectedRange);
      }

      return word;
   };

   /** getCurrentElement gets the current element that the cursor is on in the input field.
    * returns 'DIV' if in the parent field, and returns 'SPAN' if the cursor is within
    * a item mention.
    */
   getCurrentElement = () => {
      const selection: any = window.getSelection();
      const container = selection.anchorNode;
      if (container?.nodeType !== 3) {
         return container;
      } else if (container) {
         return container.parentNode;
      }
      return null;
   };
}
