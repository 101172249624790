import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type { AssetVendorAssociationSchema } from "src/app/assets/schemata/asset-vendor-association.schema";
import { environment } from "src/environments/environment";
import type { z } from "zod";

export type AssetVendorAssociation = z.infer<typeof AssetVendorAssociationSchema>;

@Injectable({
   providedIn: "root",
})
export class AssetVendorAssociationService {
   private readonly http = inject(HttpClient);

   public getVendors(assetIDs: number[] = []): Observable<AssetVendorAssociation[]> {
      const params =
         assetIDs.length > 0
            ? new HttpParams().set("assetIDs", assetIDs.join(","))
            : new HttpParams();

      return this.http.get<AssetVendorAssociation[]>(
         `${environment.flannelUrl}/assets/vendor-associations`,
         { params },
      );
   }

   public getAssets(vendorIDs: number[] = []): Observable<AssetVendorAssociation[]> {
      const params =
         vendorIDs.length > 0
            ? new HttpParams().set("vendorIDs", vendorIDs.join(","))
            : new HttpParams();

      return this.http.get<AssetVendorAssociation[]>(
         `${environment.flannelUrl}/assets/vendor-associations`,
         { params },
      );
   }
}
