import { Component, DestroyRef, inject, type OnInit, type Signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import {
   AlertComponent,
   CheckboxComponent,
   ModalService,
   TextButtonComponent,
} from "@limblecmms/lim-ui";
import { catchError, of } from "rxjs";
import { ViewFieldCollisionsModalComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/review-and-confirm-step/view-field-collisions-modal/view-field-collisions-modal.component";
import { StepComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/step/step.component";
import { ApplyTemplateService } from "src/app/assets/services/apply-asset-templates/apply-asset-template.service";
import type {
   ApplyTemplateCriteria,
   FieldCollisions,
} from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import {
   type ApplyTemplateLocation,
   ApplyTemplateStore,
} from "src/app/assets/services/apply-asset-templates/apply-template.store";
import { AssetErrorService } from "src/app/assets/services/asset-error.service";
import { AssetTemplateService } from "src/app/assets/services/asset-template.service";
import { TemplatesPageStore } from "src/app/assets/services/templatesPage.store";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { assert } from "src/app/shared/utils/assert.utils";

@Component({
   selector: "review-and-confirm-step",
   standalone: true,
   imports: [TextButtonComponent, TranslateDirective, AlertComponent, CheckboxComponent],
   templateUrl: "./review-and-confirm-step.component.html",
   styleUrl: "./review-and-confirm-step.component.scss",
})
export class ReviewAndConfirmStepComponent extends StepComponent implements OnInit {
   private readonly assetTemplateService = inject(AssetTemplateService);
   private readonly applyTemplateService = inject(ApplyTemplateService);
   private readonly applyTemplateState = inject(ApplyTemplateStore);
   private readonly assetErrorService = inject(AssetErrorService);
   private readonly modalService = inject(ModalService);
   private readonly destroyRef = inject(DestroyRef);
   private readonly route = inject(ActivatedRoute);
   private readonly templatesPageStore = inject(TemplatesPageStore);

   public fieldCollisions: FieldCollisions | null = null;
   public assetCollisionsCount: number = 0;
   public fieldCollisionsCount: number = 0;

   protected userConfirmed: {
      location: boolean;
      assets: boolean;
      fields: boolean;
   } = {
      location: false,
      assets: false,
      fields: false,
   };

   protected isConfirmationError: boolean = false;
   protected selectedLocation: Signal<ApplyTemplateLocation> =
      this.applyTemplateState.selectedLocation;
   protected selectedAssetCount: Signal<number> =
      this.applyTemplateState.selectedAssetCount;
   protected fieldsToMergeCount: Signal<number> =
      this.applyTemplateState.fieldsToMergeCount;

   public ngOnInit(): void {
      this.fetchCollisions();
   }

   private fetchCollisions() {
      /**
       * Check to see if there are any merge collisions with
       * the user's template/field mappings
       */
      const applyCriteria = this.applyTemplateState.applyCriteria();
      assert(applyCriteria !== undefined);
      // If we don't have any fieldMappings, we can't check for collisions
      if (
         applyCriteria === undefined ||
         Object.keys(applyCriteria.fieldMappings).length === 0
      ) {
         return;
      }
      this.assetTemplateService
         .checkMergeFieldCollisions(applyCriteria)
         .pipe(
            catchError((err) => {
               this.assetErrorService.handleRequestError(err);
               return of(null);
            }),
            takeUntilDestroyed(this.destroyRef),
         )
         .subscribe((result) => {
            if (result?.assets) {
               this.fieldCollisions = result;
               this.assetCollisionsCount = result.assets.length;
               this.fieldCollisionsCount = 0;
               for (const asset of result.assets) {
                  this.fieldCollisionsCount += Object.keys(asset.mergeConflicts).length;
               }
            } else {
               /**
                * They don't have any collisions so make sure everything is reset
                * in case they resolved the collisions.
                */
               this.fieldCollisions = null;
               this.assetCollisionsCount = 0;
               this.fieldCollisionsCount = 0;
            }
         });
   }

   public override handleNextStepClick() {
      this.isConfirmationError = false;

      // Check if all entities are confirmed
      if (!Object.values(this.userConfirmed).every((value) => value)) {
         this.isConfirmationError = true;
         return;
      }

      const applyCriteria = this.applyTemplateState.applyCriteria();
      assert(applyCriteria !== undefined);
      if (applyCriteria === undefined) {
         return;
      }

      const templateID = Number(this.route.snapshot.params.assetTemplateId);
      this.assetTemplateService
         .applyTemplate(templateID, applyCriteria)
         .pipe(
            catchError((err) => {
               this.assetErrorService.handleRequestError(err);
               return of(null);
            }),
            takeUntilDestroyed(this.destroyRef),
         )
         .subscribe((assetTemplate) => {
            if (!assetTemplate) {
               return;
            }

            this.templatesPageStore.setUiMode("published");

            this.context().goToNextStep();
         });
   }

   public async openFieldConflictsModal() {
      const modalRef = this.modalService.open(ViewFieldCollisionsModalComponent);
      modalRef.setInput("fieldCollisionsInput", this.fieldCollisions);
      modalRef.setInput(
         "applyCriteria",
         this.applyTemplateState.applyCriteria() as ApplyTemplateCriteria,
      );
      modalRef.setInput(
         "templateName",
         this.templatesPageStore.template()?.Name ?? "Name not found",
      );

      await modalRef.result;

      /**
       * Refetch the collisions after the user closes/confirms the modal in case they have resolved them
       */
      this.fetchCollisions();
   }

   protected toggleCheckbox(entity: "location" | "assets" | "fields") {
      if (!entity) return;
      this.userConfirmed[entity] = !this.userConfirmed[entity];
   }

   protected resetError() {
      this.isConfirmationError = false;
   }
}
