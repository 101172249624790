import type { OnChanges, SimpleChanges } from "@angular/core";
import { inject, Component, Input } from "@angular/core";
import { TooltipDirective } from "@limblecmms/lim-ui";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "simultaneous-users",
   templateUrl: "./simultaneousUsers.element.component.html",
   styleUrls: ["./simultaneousUsers.element.component.scss"],
   imports: [TooltipDirective, UserImage],
})
export class SimultaneousUsersComponent implements OnChanges {
   @Input() public userIDs: Set<number> | undefined;
   private readonly users;
   public readonly usersToShow;
   private readonly manageUser = inject(ManageUser);

   public constructor() {
      this.users = this.manageUser.getUsersObj().index;
      this.usersToShow = [];
   }

   public ngOnChanges(changes: SimpleChanges) {
      if (changes.userIDs) {
         this.update();
      }
   }

   /**
    * Rebuilds the list of users to show. This should be called whenever the list of
    * userIDs passed into this component is modified.
    */
   public update() {
      this.usersToShow.length = 0;
      for (const userID of this.userIDs ?? []) {
         this.usersToShow.push(this.users[userID]);
      }
   }

   public trackByFn(index: number, user) {
      if (user === undefined) {
         return `Index${index}`;
      }
      return `ID${user.userID}`;
   }
}
