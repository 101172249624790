import { computed, inject, Injectable } from "@angular/core";
import type { TableColumn } from "@empowered/base/table/table.models";
import { TableColumnBuilder } from "@empowered/base/table/utils/column-table-builder";
import { ManageLang } from "src/app/languages/services/manageLang";
import {
   type Column,
   ColumnBuilder,
   ColumnKeys,
   type ValuesOfColumnKeys,
} from "src/app/shared/data-viewer/column-builder";
import {
   TaskViewType_AnyTasks,
   TaskViewType_AverageDowntimeHours,
   TaskViewType_AverageTimeSpent,
   TaskViewType_AverageUptimeHours,
   TaskViewType_ByCost,
   TaskViewType_ByDowntime,
   TaskViewType_ByStatus,
   TaskViewType_ByType,
   TaskViewType_CompletedPlannedWOs,
   TaskViewType_CompletedPMs,
   TaskViewType_CompletedTasks,
   TaskViewType_CompletedUnplannedWOs,
   TaskViewType_CompletedWorkRequests,
   TaskViewType_CostInvoices,
   TaskViewType_CostLabor,
   TaskViewType_CostParts,
   TaskViewType_CostTotal,
   TaskViewType_CycleCountTasks,
   TaskViewType_DowntimeHours,
   TaskViewType_DowntimePercent,
   TaskViewType_mtbf,
   TaskViewType_mttr,
   TaskViewType_OpenPlannedWOs,
   TaskViewType_OpenTasks,
   TaskViewType_OpenUnplannedWOs,
   TaskViewType_OpenWorkRequests,
   TaskViewType_TasksByStatusDuration,
   TaskViewType_TaskSchedulesCombined,
   TaskViewType_TasksIncludeUpcoming,
   TaskViewType_TimeSpent,
   TaskViewType_TotalCompletedTasks,
   TaskViewType_TotalDowntimeHours,
   TaskViewType_TotalOpenTasks,
   TaskViewType_TotalTasks,
   TaskViewType_TotalTimeSpent,
   TaskViewType_TotalUptimeHours,
   TaskViewType_UptimePercent,
   type TaskViewTypes,
   VirtualTaskViewType_CriticalWOsAndPMs,
   VirtualTaskViewType_downtimeCount,
   VirtualTaskViewType_downtimeHoursEfficiency,
   VirtualTaskViewType_laborCostEfficiency,
   VirtualTaskViewType_mtbf,
   VirtualTaskViewType_mttr,
   VirtualTaskViewType_OnTimeWOsAndPMs,
   VirtualTaskViewType_operatingCostsMonthlyLine,
   VirtualTaskViewType_OverdueWOsAndPMs,
   VirtualTaskViewType_partCostEfficiency,
   VirtualTaskViewType_PlannedMaintenance,
   VirtualTaskViewType_PlannedVsUnplannedMonthly,
   VirtualTaskViewType_totalInvoiceCost,
   VirtualTaskViewType_totalLaborCost,
   VirtualTaskViewType_totalOperatingCost,
   VirtualTaskViewType_totalPartsCost,
   VirtualTaskViewType_UnplannedMaintenance,
} from "src/app/tasks/components/shared/services/task-column-definitions-factory/task-column-definitions-factory.models";

@Injectable({
   providedIn: "root",
})
export class TaskColumnDefinitionsFactoryService {
   private readonly columnBuilderLegacy: ColumnBuilder;
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly tableColumnBuilder = new TableColumnBuilder();
   public constructor() {
      this.columnBuilderLegacy = new ColumnBuilder(this.lang);
   }

   /**
    * Returns the columns for the given view type
    */
   public getByViewType(
      type: TaskViewTypes,
      status?: "timeSpentInStatusOpen" | "timeSpentInStatusInProgress",
      isNewDataViewer?: boolean,
   ): Array<Column | TableColumn> {
      let columns: Array<Column | TableColumn>;
      switch (type) {
         case TaskViewType_ByCost:
         case TaskViewType_CostParts:
         case TaskViewType_CostLabor:
         case TaskViewType_CostInvoices:
         case TaskViewType_CostTotal:
         case VirtualTaskViewType_totalOperatingCost:
         case VirtualTaskViewType_totalPartsCost:
         case VirtualTaskViewType_totalLaborCost:
         case VirtualTaskViewType_totalInvoiceCost:
         case VirtualTaskViewType_operatingCostsMonthlyLine:
            columns = this.getByCostColumns();
            break;

         case TaskViewType_ByDowntime:
         case TaskViewType_TotalDowntimeHours:
         case TaskViewType_TotalUptimeHours:
         case TaskViewType_AverageDowntimeHours:
         case TaskViewType_AverageUptimeHours:
         case TaskViewType_UptimePercent:
         case TaskViewType_DowntimePercent:
         case TaskViewType_DowntimeHours:
         case TaskViewType_mtbf:
         case TaskViewType_mttr:
         case VirtualTaskViewType_mtbf:
         case VirtualTaskViewType_mttr:
         case VirtualTaskViewType_downtimeCount:
            columns = this.getByDowntimeColumns();
            break;

         case TaskViewType_ByStatus:
         case VirtualTaskViewType_OnTimeWOsAndPMs:
         case VirtualTaskViewType_OverdueWOsAndPMs:
         case VirtualTaskViewType_CriticalWOsAndPMs:
            columns = this.getByStatusColumns();
            break;

         case TaskViewType_CompletedUnplannedWOs:
         case TaskViewType_CompletedPlannedWOs:
         case TaskViewType_ByType:
         case TaskViewType_CompletedPMs:
         case TaskViewType_CompletedWorkRequests:
         case TaskViewType_TotalTasks:
         case TaskViewType_TotalCompletedTasks:
         case VirtualTaskViewType_PlannedMaintenance:
         case VirtualTaskViewType_UnplannedMaintenance:
         case VirtualTaskViewType_PlannedVsUnplannedMonthly:
            columns = this.getByTypeColumns();
            break;

         case TaskViewType_CompletedTasks:
            columns = this.getCompletedTasksColumns(isNewDataViewer);
            break;

         case TaskViewType_OpenTasks:
         case TaskViewType_OpenPlannedWOs:
         case TaskViewType_OpenUnplannedWOs:
         case TaskViewType_OpenWorkRequests:
         case TaskViewType_TotalOpenTasks:
            columns = this.getOpenTasksColumns();
            break;

         case TaskViewType_AnyTasks:
            columns = this.getAnyTasksColumns();
            break;

         case TaskViewType_TotalTimeSpent:
         case TaskViewType_TimeSpent:
         case TaskViewType_AverageTimeSpent:
            columns = this.getByTimeSpentColumns();
            break;

         case TaskViewType_TasksIncludeUpcoming:
            columns = this.getByIncludingSchedules();
            break;

         case TaskViewType_CycleCountTasks:
            columns = this.getCycleCountsColumns();
            break;

         case TaskViewType_TasksByStatusDuration:
            columns = this.getByStatusDurationColumns(status ?? "none");
            break;
         case TaskViewType_TaskSchedulesCombined:
            columns = this.getTasksSchedulesColumns();
            break;
         case VirtualTaskViewType_downtimeHoursEfficiency:
            columns = this.getDowntimeEfficiencyColumns();
            break;
         case VirtualTaskViewType_laborCostEfficiency:
            columns = this.getLaborCostEfficiencyColumns();
            break;
         case VirtualTaskViewType_partCostEfficiency:
            columns = this.getPartCostEfficiencyColumns();
            break;
         default:
            columns = this.getAnyTasksColumns();
            break;
      }

      return columns;
   }

   public getByColumnKeys(columnKeys: Array<ValuesOfColumnKeys>) {
      // TODO: Need to add functionality to override column defaults when creating by column keys
      //        https://limble.atlassian.net/browse/CMMS-1933
      const columnOverrides = {};

      for (const key of columnKeys) {
         this.addColumn(key, columnOverrides);
      }

      return this.columnBuilderLegacy.build();
   }

   private addColumn(columnKey: ValuesOfColumnKeys, columnOverrides?: Partial<Column>) {
      // eslint-disable-next-line default-case, typescript/switch-exhaustiveness-check -- should not add any column by default, cases include columns for other domains
      switch (columnKey) {
         case ColumnKeys.TASK_NAME:
            this.columnBuilderLegacy.addTaskName(columnOverrides);
            break;
         case ColumnKeys.DUE_DATE:
            this.columnBuilderLegacy.addTaskDueDate(columnOverrides);
            break;
         case ColumnKeys.COMPLETED_ON:
            this.columnBuilderLegacy.addTaskCompletedOn(columnOverrides);
            break;
         case ColumnKeys.COMPLETED_BY:
            this.columnBuilderLegacy.addTaskCompletedBy(columnOverrides);
            break;
         case ColumnKeys.TIME_SPENT:
            this.columnBuilderLegacy.addTaskTimeSpent(columnOverrides);
            break;
         case ColumnKeys.ASSIGNED_TO:
            this.columnBuilderLegacy.addAssignedTo(columnOverrides);
            break;
         case ColumnKeys.TASK_TYPE:
            this.columnBuilderLegacy.addTaskType(columnOverrides);
            break;
         case ColumnKeys.TOTAL_PARTS_COST:
            this.columnBuilderLegacy.addTotalPartsCost(columnOverrides);
            break;
         case ColumnKeys.INVOICE_COST:
            this.columnBuilderLegacy.addInvoiceCost(columnOverrides);
            break;

         case ColumnKeys.TIME_TOTAL_WITH_LABOR_COST:
            this.columnBuilderLegacy.addTimeTotalWithLaborCost(columnOverrides);
            break;
         case ColumnKeys.OPERATING_COST:
            this.columnBuilderLegacy.addOperatingCost(columnOverrides);
            break;

         case ColumnKeys.COMPLETION_NOTES:
            this.columnBuilderLegacy.addCompletionNotes(columnOverrides);
            break;
         case ColumnKeys.TASK_MINIMAL_NAME:
            this.columnBuilderLegacy.addTaskMinimalName(columnOverrides);
            break;

         case ColumnKeys.LOCATION:
            this.columnBuilderLegacy.addLocation(columnOverrides);
            break;
         case ColumnKeys.DOWNTIME:
            this.columnBuilderLegacy.addDowntime(columnOverrides);
            break;
         case ColumnKeys.TASK_STATUS_COLOR:
            this.columnBuilderLegacy.addStatusColor(columnOverrides);
            break;
         case ColumnKeys.TASK_TIME_IN_OPEN:
            this.columnBuilderLegacy.addTimeInStatus(
               "timeSpentInStatusOpen",
               columnOverrides,
            );
            break;
         case ColumnKeys.TASK_TIME_IN_PROGRESS:
            this.columnBuilderLegacy.addTimeInStatus(
               "timeSpentInStatusInProgress",
               columnOverrides,
            );
            break;
         case ColumnKeys.TASK_TIME_IN_NONE:
            this.columnBuilderLegacy.addTimeInStatus("none", columnOverrides);
            break;
         case ColumnKeys.TASK_PART_QUANTITY:
            this.columnBuilderLegacy.addTaskPartQuantity(columnOverrides);
            break;
         case ColumnKeys.TASK_PART_NAME:
            this.columnBuilderLegacy.addTaskPartName(columnOverrides);
            break;
         case ColumnKeys.TASK_ID:
            this.columnBuilderLegacy.addTaskID(columnOverrides);
            break;
         case ColumnKeys.ASSET_NAME:
            this.columnBuilderLegacy.addAssetName(columnOverrides);
            break;
         case ColumnKeys.LATEST_ACTIVITY_TIME:
            this.columnBuilderLegacy.addTaskLatestActivityTime(columnOverrides);
            break;
         case ColumnKeys.TASK_ESTIMATED_TIME:
            this.columnBuilderLegacy.addTaskEstimatedTime(columnOverrides);
            break;
         case ColumnKeys.TASK_SCHEDULE_NAME:
            this.columnBuilderLegacy.addTaskScheduleCombinedName(columnOverrides);
            break;
         case ColumnKeys.TASK_SCHEDULE_DUE_DATE:
            this.columnBuilderLegacy.addTaskDueDate(columnOverrides);
            break;
         case ColumnKeys.DOWNTIME_EFFICIENCY_RATE:
            this.columnBuilderLegacy.addDowntimeEfficiencyRate(columnOverrides);
            break;
         case ColumnKeys.LABOR_COST_EFFICIENCY_RATE:
            this.columnBuilderLegacy.addLaborCostEfficiencyRate(columnOverrides);
            break;
         case ColumnKeys.PART_COST_EFFICIENCY_RATE:
            this.columnBuilderLegacy.addPartCostEfficiencyRate(columnOverrides);
            break;
         case ColumnKeys.AVERAGE_DOWNTIME_COST:
            this.columnBuilderLegacy.addDowntimeAverageCost(columnOverrides);
            break;
         case ColumnKeys.DOWNTIME_EFFICIENCY_SAVINGS:
            this.columnBuilderLegacy.addDowntimeEfficiencySavings(columnOverrides);
            break;
         case ColumnKeys.LABOR_COST_EFFICIENCY_SAVINGS:
            this.columnBuilderLegacy.addLaborCostEfficiencySavings(columnOverrides);
            break;
         case ColumnKeys.PART_COST_EFFICIENCY_SAVINGS:
            this.columnBuilderLegacy.addPartCostEfficiencySavings(columnOverrides);
            break;
         case ColumnKeys.LABOR_COST:
            this.columnBuilderLegacy.addLaborCost(columnOverrides);
            break;
         case ColumnKeys.PART_LOG_DATE: {
            this.columnBuilderLegacy.addPartLogDate(columnOverrides);
            break;
         }
         case ColumnKeys.PART_LOG_ENTRY: {
            this.columnBuilderLegacy.addPartLogEntry(columnOverrides);
            break;
         }
         case ColumnKeys.PART_LOG_USER: {
            this.columnBuilderLegacy.addPartLogUser(columnOverrides);
            break;
         }
         case ColumnKeys.PART_NAME: {
            this.columnBuilderLegacy.addTaskPartName(columnOverrides);
            break;
         }
         case ColumnKeys.PARTS_USED: {
            this.columnBuilderLegacy.addAssetPartUsedName(columnOverrides);
            break;
         }
         case ColumnKeys.PART_NUMBER: {
            this.columnBuilderLegacy.addAssetPartUsedNumber(columnOverrides);
            break;
         }
         case ColumnKeys.TOTAL_COST_OF_PARTS_USED: {
            this.columnBuilderLegacy.addTotalPartsCost(columnOverrides);
            break;
         }
         case ColumnKeys.TASK_NAME_WITHOUT_ASSET: {
            this.columnBuilderLegacy.addTaskMinimalName(columnOverrides);
            break;
         }
         case ColumnKeys.PROMPT_TIME: {
            this.columnBuilderLegacy.addLoggedTime(columnOverrides);
            break;
         }
         case ColumnKeys.LOGGED_AT: {
            this.columnBuilderLegacy.addLoggedAt(columnOverrides);
            break;
         }
         case ColumnKeys.NOTES: {
            this.columnBuilderLegacy.addLoggedTimeNotes(columnOverrides);
            break;
         }
         case ColumnKeys.USER_DISPLAY_NAME: {
            this.columnBuilderLegacy.addLoggedByUser(columnOverrides);
            break;
         }
         case ColumnKeys.LOGGED_TIME_TASK_NAME: {
            this.columnBuilderLegacy.addLoggedTimeTaskName(columnOverrides);
            break;
         }
      }
   }

   public getLoggedTimeColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addLoggedByUser()
         .addLoggedTime()
         .addLoggedTimeTaskName()
         .addLocation()
         .addLoggedAt()
         .addLoggedTimeNotes()
         .build();
   }

   public getByCostColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskName({ columnWidth: 8 })
         .addTotalPartsCost({ columnWidth: 1 })
         .addTimeTotalWithLaborCost({ columnWidth: 1 })
         .addInvoiceCost({ columnWidth: 1 })
         .addOperatingCost({ columnWidth: 1 })
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .build();
   }

   public getByDowntimeColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskName({ columnWidth: 6 })
         .addDowntime({ columnWidth: 2 })
         .addTaskTimeSpent({ columnWidth: 2 })
         .addTaskCompletedOn({ columnWidth: 2 })
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .build();
   }

   public getByStatusColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskName()
         .addStatusColor()
         .addTaskDueDate()
         .addTaskTimeSpent()
         .addTaskCompletedBy()
         .addTaskLatestActivityTime()
         .build();
   }

   public getByTypeColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskName({ columnWidth: 4 })
         .addTaskType({ columnWidth: 2 })
         .addTaskCompletedOn({ columnWidth: 2 })
         .addTaskTimeSpent({ columnWidth: 2 })
         .addTaskCompletedBy({ columnWidth: 2 })
         .addTaskLatestActivityTime()
         .build();
   }

   public getCompletedTasksColumns(
      isNewDataViewer?: boolean,
   ): Array<Column | TableColumn> {
      if (isNewDataViewer) {
         return (
            this.tableColumnBuilder
               // .addTaskPriority()
               .addTaskStatus()
               .addTaskType()
               .addTaskID()
               .addTaskName()
               .addTaskDueDate()
               .addTaskCompletedOn()
               .addTaskTimeSpent()
               .addTaskCompletedBy()
               .addTaskLatestActivityTime()
               .addTaskActions()
               .build()
         );
      }

      return this.columnBuilderLegacy
         .addTaskName()
         .addTaskDueDate()
         .addTaskCompletedOn()
         .addTaskTimeSpent()
         .addTaskCompletedBy()
         .addTaskLatestActivityTime()
         .build();
   }

   public getTasksSchedulesColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskScheduleCombinedName({ sortBy: "name" })
         .addTaskEstimatedTime({ sortBy: "estimatedTime" })
         .addTaskDueDate({ sortBy: "dueDate" })
         .addAssignedTo({ sortBy: "assignment" })
         .addTaskLatestActivityTime()
         .build();
   }

   public getAnyTasksColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskName({ columnWidth: 4 })
         .addTaskDueDate({ columnWidth: 2 })
         .addTaskCompletedOn({ columnWidth: 2 })
         .addTaskCompletedBy({ columnWidth: 2 })
         .addAssignedTo({ columnWidth: 2 })
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .build();
   }

   public getByIncludingSchedules() {
      return this.columnBuilderLegacy
         .addTaskName({ columnWidth: 4 })
         .addTaskDueDate({ columnWidth: 4 })
         .addAssignedTo({ columnWidth: 4 })
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .build();
   }

   public getOpenTasksColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskName({ columnWidth: 6 })
         .addTaskDueDate({ columnWidth: 2 })
         .addAssignedTo({ columnWidth: 2 })
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .build();
   }

   public getOpenTasksColumnsForPage(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskNameWithoutAsset()
         .addTaskID({ columnWidth: 1 })
         .addAssetName({ columnWidth: 3 })
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .addTaskDueDate({ columnWidth: 2 })
         .addAssignedTo({ columnWidth: 2 })
         .build();
   }

   public getOpenTasksColumnsForPageLegacy(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskName({ columnWidth: 6 })
         .addTaskID({ columnWidth: 1 })
         .addAssetName({ columnWidth: 3 })
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .addTaskDueDate({ columnWidth: 2 })
         .addAssignedTo({ columnWidth: 2 })
         .build();
   }

   public getByStatusDurationColumns(
      status: "timeSpentInStatusOpen" | "timeSpentInStatusInProgress" | "none",
   ): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskName()
         .addTaskDueDate()
         .addTaskCompletedOn()
         .addTimeInStatus(status)
         .addTaskCompletedBy()
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .build();
   }

   public getByTimeSpentColumns(): Array<Column> {
      return this.columnBuilderLegacy
         .addTaskCompletedBy({ displayName: this.lang().Name })
         .addTaskTimeSpent({ columnWidth: 2 })
         .addTaskMinimalName()
         .addLocation()
         .addTaskCompletedOn({ displayName: this.lang().Date })
         .addCompletionNotes()
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .build();
   }

   public getGlobalSearchTaskListColumns() {
      return this.columnBuilderLegacy
         .addTaskName()
         .addTaskDueDate({ displayName: this.lang().DueDate })
         .addAssignedTo()
         .addTaskCompletedOn({ displayName: this.lang().CompletedDate })
         .addTaskCompletedBy()
         .addTaskLatestActivityTime({ columnWidth: 2 })
         .build();
   }

   public getCycleCountsColumns() {
      return this.columnBuilderLegacy
         .addTaskName()
         .addTaskDueDate()
         .addTaskCompletedOn()
         .addTaskCompletedBy()
         .addAssignedTo()
         .addTaskLatestActivityTime()
         .build();
   }

   public getDowntimeEfficiencyColumns() {
      return this.columnBuilderLegacy
         .addTaskName()
         .addTaskCompletedOn()
         .addDowntime()
         .addDowntimeEfficiencyRate()
         .addDowntimeAverageCost()
         .addDowntimeEfficiencySavings()
         .build();
   }

   public getLaborCostEfficiencyColumns() {
      return this.columnBuilderLegacy
         .addTaskName()
         .addTaskCompletedOn()
         .addLaborCost()
         .addLaborCostEfficiencyRate()
         .addLaborCostEfficiencySavings()
         .build();
   }

   public getPartCostEfficiencyColumns() {
      return this.columnBuilderLegacy
         .addTaskName()
         .addTaskCompletedOn()
         .addTotalPartsCost()
         .addPartCostEfficiencyRate()
         .addPartCostEfficiencySavings()
         .build();
   }
}
