import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { firstValueFrom, Subject } from "rxjs";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { AssetPartAssociationService } from "src/app/assets/services/asset-part-association.service";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { PartAssetAssociationInfo } from "src/app/parts/types/asset-relation/asset-relation.types";
import type { Part } from "src/app/parts/types/part.types";
import type { PartVendorRelation } from "src/app/parts/types/vendors-relations/vendor-relation.types";
import type { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { CurrencyService } from "src/app/shared/services/currency.service";
import { LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { UpdateSignaler } from "src/app/shared/services/update-signaler/update-signaler";
import { AssociationType } from "src/app/shared/types/association.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { AssetsApiService } from "src/app/tasks/components/shared/services/assets-api/assets-api.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services//manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";
import { environment } from "src/environments/environment";
// Define a type for the part association details
interface PartAssociationDetails {
   manualRelation?: boolean;
   automaticRelation?: boolean;
   tmpPoItemID?: number;
   partNumber?: string;
   partPrice?: number;
   partVendorAvgLeadTime?: string | null;
}

// Define a type for the return value
interface PartAssociationResult {
   parts: Array<Part>;
   partAssociations: Map<number, PartAssociationDetails>;
}

@Injectable({ providedIn: "root" })
export class ManageAssociations {
   private readonly axios = axios;

   private partVendorRelations: Lookup<"relationID", PartVendorRelation> = new Lookup(
      "relationID",
   );

   public readonly partVendorRelationUpdates$: Subject<{
      partID: number;
      vendorID: number;
      partPrice?: number;
      partNumber?: string;
      defaultForPartPO?: boolean;
   }> = new Subject();

   public readonly partAssetRelationUpdates$: Subject<{
      assetID: number;
      partID: number;
      assetPartQty?: number;
   }> = new Subject();

   private readonly manageParts = inject(ManageParts);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageAsset = inject(ManageAsset);
   private readonly assetService = inject(AssetsApiService);
   private readonly manageUser = inject(ManageUser);
   protected readonly assetPartAssociationService = inject(AssetPartAssociationService);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly currencyService = inject(CurrencyService);

   private readonly partVendorRelationUpdateSignaler = new UpdateSignaler();

   public readonly partVendorRelationUpdated =
      this.partVendorRelationUpdateSignaler.updated;

   public async getData() {
      await Promise.all([this.fetchPartVendorRelations()]);
   }

   public async bulkAssociatePartsToAssets(
      partIDsToAssociate: Array<number>,
      assetIDsToAssociate: Array<number>,
      locationID: number,
   ) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "bulkAssociatePartsToAssets",
         },
         data: {
            parts: partIDsToAssociate,
            assets: assetIDsToAssociate,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      //fetch all the asset relations until we refactor assets
      await Promise.all([
         this.manageParts.fetchAssetsRelations(),
         this.manageParts.fetchParts(),
      ]);

      this.manageParts.incrementPartsWatchVar();

      return post;
   }

   public async bulkAssociatePartsToVendors(
      partIDsToAssociate: Array<number>,
      vendorIDsToAssociate: Array<number>,
      locationID: number,
   ) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "bulkAssociatePartsToVendors",
         },
         data: {
            vendors: vendorIDsToAssociate,
            parts: partIDsToAssociate,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      await Promise.all([this.fetchPartVendorRelations(), this.manageParts.fetchParts()]);

      this.manageParts.incrementPartsWatchVar();
      this.manageVendor.incrementVendorsWatchVar();
      return post;
   }

   public async deleteManualPartVendorAssociation(vendorID: number, partID: number) {
      const part = this.manageParts.getPart(partID);
      assert(part);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "deleteManualPartAssociation",
         },
         data: {
            vendorID: vendorID,
            locationID: part.locationID,
            partID: part.partID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const partVendorIDIndex = part.partVendorIDs.findIndex(
         (partVendorID) => partVendorID === vendorID,
      );
      part.partVendorIDs.splice(partVendorIDIndex, 1);

      //ok here ive gotta do it for the vendor too AND the association entity
      const vendor = this.manageVendor.getVendor(vendorID);
      if (!vendor) {
         return undefined;
      }
      const vendorPartRelationIDIndex = vendor.vendorPartRelationIDs.findIndex(
         (relationID) => {
            const relation = this.getPartVendorRelation(relationID);
            return relation?.partID === partID;
         },
      );
      vendor.vendorPartRelationIDs.splice(vendorPartRelationIDIndex, 1);

      await this.fetchPartVendorRelations();

      this.manageVendor.incrementVendorsWatchVar();
      this.manageParts.incrementPartsWatchVar();
      return post;
   }

   public async deletePartAssetRelation(assetID: number, partID: number) {
      const part = this.manageParts.getPart(partID);
      assert(part);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "deleteManualPartRelation",
         },
         data: {
            assetID: assetID,
            partID: part.partID,
            locationID: part.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      await Promise.all([
         this.manageParts.fetchPartsAndAssociationsByID([partID]),
         this.fetchPartVendorRelations(),
         this.manageParts.fetchAssetsRelations(),
      ]);
      this.manageParts.incrementPartsWatchVar();

      return post;
   }

   public getAssociatedPartsForVendor(
      vendorID: number,
      purchaseOrderItems: Lookup<"poItemID", PurchaseOrderItem> | undefined,
   ): PartAssociationResult {
      const partAssociations: Map<number, PartAssociationDetails> = new Map();

      //first we need to find manual associations
      const relations = this.getPartVendorRelations();
      for (const relation of relations) {
         if (relation.vendorID !== vendorID) {
            continue;
         }
         const part = this.manageParts.getPart(relation.partID);
         if (part?.partDeleted !== 0) {
            continue;
         }
         partAssociations.set(part.partID, {
            manualRelation: true,
            partPrice: relation.partPrice,
            partNumber: relation.partNumber,
         });
      }
      if (purchaseOrderItems) {
         for (const poItem of purchaseOrderItems) {
            if (!poItem.partID) {
               continue;
            }

            const part = this.manageParts.getPart(poItem.partID);
            if (part?.partDeleted !== 0) {
               continue;
            }
            const partAssociation = partAssociations.get(part.partID);
            if (!partAssociation) {
               partAssociations.set(part.partID, {
                  automaticRelation: true,
                  tmpPoItemID: poItem.poItemID,
               });
               continue;
            }
            partAssociation.automaticRelation = true;
            partAssociation.tmpPoItemID = poItem.poItemID;
         }
      }

      const partsToReturn: Array<Part> = [];

      for (const partID of partAssociations.keys()) {
         const part = this.manageParts.getPart(partID);
         if (part?.partDeleted !== 0) {
            continue;
         }
         part.partVendorCurrencyCode = this.currencyService.getCurrencyCodeByLocationID(
            part.locationID,
         );
         partsToReturn.push(part);
      }

      return { parts: partsToReturn, partAssociations: partAssociations };
   }

   public getAssociatedVendorsForPart(
      partID: number,
      poItems: Lookup<"poItemID", PurchaseOrderItem> | undefined,
      managePO: ManagePO,
   ): Lookup<
      "vendorID",
      {
         automaticPartRelation?: boolean;
         manualPartRelation?: boolean;
         locationName: string;
         tmpPOItemsIDs: Array<number>;
         vendorID: number;
         vendorName: string;
         partNumber?: string;
         partPrice?: number;
      }
   > {
      if (!partID) {
         return new Lookup("vendorID");
      }
      const part = this.manageParts.getPart(partID);
      if (!part) {
         return new Lookup("vendorID");
      }
      const vendorsLookup = this.manageVendor.getVendors();
      const locationsIndex = this.manageLocation.getLocationsIndex();
      const vendorRelationInfo: Lookup<
         "vendorID",
         {
            automaticPartRelation?: boolean;
            manualPartRelation?: boolean;
            locationName: string;
            tmpPOItemsIDs: Array<number>;
            vendorID: number;
            vendorName: string;
            partNumber?: string;
            partPrice?: number;
            currencyCode: string;
         }
      > = new Lookup("vendorID");

      //first we need to get all of the POitems that have been used for this part
      if (poItems) {
         for (const poItem of poItems) {
            if (!poItem.poID) {
               continue;
            }
            const vendorID = managePO.getPurchaseOrder(poItem.poID)?.vendorID;
            if (!vendorID) {
               continue;
            }

            const vendor = this.manageVendor.getVendor(vendorID);

            if (!vendor || vendor.vendorDeleted !== 0) {
               continue;
            }
            const location = locationsIndex[vendor.locationID];
            if (!location) {
               continue;
            }
            const relationInfo = {
               automaticPartRelation: true,
               locationName: location.locationName,
               tmpPOItemsIDs: [poItem.poItemID],
               vendorID: vendor.vendorID,
               vendorName: vendor.vendorName ?? "",
               currencyCode: "",
            };
            const vendorRelationInfoItem = vendorRelationInfo.get(vendor.vendorID);
            if (!vendorRelationInfoItem) {
               vendorRelationInfo.set(vendor.vendorID, relationInfo);
               continue;
            }
            vendorRelationInfoItem.tmpPOItemsIDs.push(poItem.poItemID);
         }
      }

      const partVendorRelations = part.partVendorRelationIDs.map((relationID) =>
         this.getPartVendorRelation(relationID),
      );

      //next check the manual associations for parts to vendors
      const relations = part.partVendorIDs;
      for (const vendorID of relations) {
         const vendor = vendorsLookup.get(vendorID);
         const relation = partVendorRelations.find(
            (partVendorRelation) => partVendorRelation?.vendorID === vendorID,
         );
         if (!vendor || vendor.vendorDeleted !== 0) {
            continue;
         }
         const location = locationsIndex[vendor.locationID];
         if (!location) {
            continue;
         }
         const currencyCode = this.currencyService.getCurrencyCodeByLocationID(
            vendor.locationID,
         );
         const relationInfo = {
            manualPartRelation: true,
            locationName: location.locationName,
            tmpPOItemsIDs: [],
            vendorID: vendor.vendorID,
            vendorName: vendor.vendorName ?? "",
            partNumber: relation?.partNumber ?? "",
            partPrice: relation?.partPrice ?? 0,
            currencyCode: currencyCode,
         };
         const existingVendorRelationInfoItem = vendorRelationInfo.get(vendor.vendorID);
         if (!existingVendorRelationInfoItem) {
            vendorRelationInfo.set(vendor.vendorID, relationInfo);
            continue;
         }
         vendorRelationInfo.set(vendor.vendorID, {
            ...relationInfo,
            ...existingVendorRelationInfoItem,
         });
      }

      return vendorRelationInfo;
   }

   public async associateVendorsToPart(vendorIDs: Array<number>, part: Part) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "associateVendorsToPart",
         },
         data: {
            vendors: vendorIDs,
            partID: part.partID,
            locationID: part.locationID,
         },
      });

      if (!post.data.success === true) {
         return undefined;
      }

      for (const vendor of post.data.addedVendors) {
         part.partVendorRelationIDs.push(vendor.relationID);
      }
      const singleVendorFetchPromises: Array<Promise<void>> = [];
      for (const vendorID of vendorIDs) {
         singleVendorFetchPromises.push(
            this.manageVendor.fetchSingleVendorAndAssociations(vendorID),
         );
      }

      await Promise.all([
         this.manageParts.fetchPartsAndAssociationsByID([part.partID]),
         this.fetchPartVendorRelations([part.partID]),
         ...singleVendorFetchPromises,
      ]);

      this.manageParts.incrementPartsWatchVar();
      this.manageVendor.incrementVendorsWatchVar();

      return post;
   }

   public async associatePartsToVendor(partIDs: Array<number>, vendor: Vendor) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "associatePartsToVendor",
         },
         data: {
            parts: partIDs,
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      await Promise.all([
         this.manageParts.fetchPartsAndAssociationsByID(partIDs),
         this.fetchPartVendorRelations(partIDs),
         this.manageVendor.fetchSingleVendorAndAssociations(vendor.vendorID),
      ]);

      this.manageParts.incrementPartsWatchVar();
      this.manageVendor.incrementVendorsWatchVar();

      return post;
   }

   public async updatePriceToVendorAssociation(
      vendorID: number,
      partID: number,
      partPrice: number,
   ): Promise<AxiosResponse<any> | undefined> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePriceToVendorAssociation",
         },
         data: {
            partID: partID,
            vendorID: vendorID,
            partPrice: partPrice,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      this.partVendorRelationUpdates$.next({
         partID,
         vendorID,
         partPrice,
      });

      return post;
   }

   public async updateNumberToVendorAssociation(
      vendorID: number,
      partID: number,
      partNumber: string,
   ): Promise<AxiosResponse<any> | undefined> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateNumberToVendorAssociation",
         },
         data: {
            partID: partID,
            vendorID: vendorID,
            partNumber: partNumber,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      this.partVendorRelationUpdates$.next({
         partID,
         vendorID,
         partNumber,
      });

      return post;
   }

   public async setDefaultVendorForPart(part: Part, vendorID: number) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "setDefaultVendorForPart",
         },
         data: {
            vendorID: vendorID,
            partID: part.partID,
            locationID: part.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      part.defaultVendorID = vendorID;

      this.partVendorRelationUpdates$.next({
         partID: part.partID,
         vendorID,
         defaultForPartPO: true,
      });

      return post;
   }

   /**
    *
    * @deprecated
    * This function is deprecated and should not be used.
    * Instead of using this method, make an API call with the appropriate filters. We should already have all of the filters we need in Flannel.
    * Once the components calling this method have been refactored for Tasks JIT, we can remove this method. (TASK-512)
    */
   public getTaskListFromAssetsForPartsUsage(
      assets: Array<any>,
      dateRanges?: { dateRange1: Date; dateRange2: Date },
      xDays?: number,
   ): TaskLookup {
      let tasks = this.manageTask
         .getCompletedTasks("ManageAssociations")
         .filter(
            (task) =>
               task.checklistTemplate === 0 &&
               task.checklistStatusID === 1 &&
               task.partRelationIDs.length > 0,
         );
      if (dateRanges?.dateRange1 && dateRanges?.dateRange2) {
         const start = Math.round(dateRanges.dateRange1.getTime() / 1000);
         const end = Math.round(dateRanges.dateRange2.getTime() / 1000) + 86399;
         tasks = this.manageFilters.filterTasksByCompletedDate(tasks, {
            start: start,
            end: end,
         });
      }
      if (xDays && xDays > 0) {
         tasks = this.manageFilters.filterTasksToLastXDays(
            tasks,
            "checklistCompletedDate",
            xDays,
         );
      }
      //get all of the assets and their children as well if appropriate.
      const tempAssetIDs: Record<number, number> = {};
      for (const asset of assets) {
         tempAssetIDs[asset.assetID] = asset.assetID;
         const fullAsset = this.manageAsset.getAsset(asset.assetID);
         if (fullAsset?.includeChildData == 1) {
            const children2 = this.manageAsset.findChildrenIDs(fullAsset, []);
            if (children2.length > 0) {
               for (const assetID of children2) {
                  tempAssetIDs[assetID] = assetID;
               }
            }
         }
      }
      const aIds: Array<number> = [];
      for (const key6 in tempAssetIDs) {
         aIds.push(tempAssetIDs[key6]);
      }
      tasks = this.manageFilters.filterTasksByAssetIDs(tasks, aIds);
      return tasks;
   }

   public async updateAssetPartQty(
      assetID: number,
      partID: number,
      assetPartQty: number,
   ) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateAssetPartQty",
         },
         data: {
            partID: partID,
            assetID: assetID,
            assetPartQty: assetPartQty,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      await Promise.all([
         this.manageParts.fetchAssetsRelations(),
         this.manageParts.fetchPartsAndAssociationsByID([partID]),
      ]);

      this.partAssetRelationUpdates$.next({
         assetID,
         partID,
         assetPartQty,
      });

      return post;
   }

   public async associatePartsToAsset(partIDs: Array<number>, asset: Asset) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "associatePartsToAsset",
         },
         data: {
            parts: partIDs,
            assetID: asset.assetID,
            locationID: asset.locationID,
         },
      });

      await Promise.all([
         this.manageParts.fetchParts(),
         this.manageParts.fetchAssetsRelations(),
      ]);

      return post;
   }

   public async associateAssetsToPart(assets: Array<any>, partID: number) {
      const assetIDs = assets.map((asset) => asset.assetID);
      const part = this.manageParts.getPart(partID);
      assert(part);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "associateAssetsToPart",
         },
         data: {
            assets: assetIDs,
            partID: part.partID,
            locationID: part.locationID,
         },
      });

      if (!post.data.success == true) {
         return undefined;
      }

      await Promise.all([
         this.manageParts.fetchAssetsRelations(),
         this.manageParts.fetchPartsAndAssociationsByID([partID]),
      ]);

      return post;
   }

   public async fetchPartVendorRelations(partIDs?: Array<number>) {
      const startTime = Math.floor(Date.now());

      const partIDsList = partIDs?.toString();
      const response = await axios.get(
         `${environment.flannelUrl}/sharedAssociations/partVendor`,
         {
            params: {
               partIDs: partIDsList,
            },
         },
      );

      logApiPerformance(
         "partVendorsRelations",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );

      if (!partIDs) {
         this.partVendorRelations = new Lookup("relationID", response.data);
         this.partVendorRelationUpdateSignaler.trigger();
         return;
      }

      for (const relation of response.data) {
         if (partIDs.includes(relation.partID)) {
            this.partVendorRelations.set(relation.relationID, relation);
         }
      }

      this.partVendorRelationUpdateSignaler.trigger();
   }

   public getPartVendorRelation(relationID: number) {
      return this.partVendorRelations.get(relationID);
   }

   public getPartVendorRelations() {
      return this.partVendorRelations;
   }

   public async deleteAssetVendorAssociation(vendorID: number, assetID: number) {
      const vendor = this.manageVendor.getVendor(vendorID);
      const asset = this.manageAsset.getAsset(assetID);
      assert(vendor && asset);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "deleteManualAssetAssociation",
         },
         data: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            assetID: assetID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const assetIDIndex = vendor.vendorAssetIDs.findIndex(
         (vendorAssetID) => vendorAssetID === assetID,
      );
      vendor.vendorAssetIDs.splice(assetIDIndex, 1);

      const vendorIDIndex = asset.assetVendorIDs.findIndex(
         (assetVendorID) => assetVendorID === vendorID,
      );
      asset.assetVendorIDs.splice(vendorIDIndex, 1);

      return post;
   }

   public async associateAssetsToVendor(assetIDs: Array<number>, vendorID: number) {
      const vendor = this.manageVendor.getVendor(vendorID);
      assert(vendor);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "associateAssetsToVendor",
         },
         data: {
            assets: assetIDs,
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
         },
      });

      if (!post.data.success == true) {
         return undefined;
      }
      vendor.vendorAssetIDs = Array.from(
         new Set([...vendor.vendorAssetIDs, ...assetIDs]),
      );

      for (const assetID of assetIDs) {
         const asset = this.manageAsset.getAsset(assetID);
         assert(asset);
         asset.assetVendorIDs.push(vendorID);
      }

      return post;
   }

   public async associateVendorsToAsset(vendorIDs: Array<number>, assetID: number) {
      const asset = this.manageAsset.getAsset(assetID);
      assert(asset);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "associateVendorsToAsset",
         },
         data: {
            vendors: vendorIDs,
            assetID: asset.assetID,
            locationID: asset.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      asset.assetVendorIDs = Array.from(new Set([...asset.assetVendorIDs, ...vendorIDs]));

      for (const vendorID of vendorIDs) {
         const vendor = this.manageVendor.getVendor(vendorID);
         assert(vendor);
         vendor.vendorAssetIDs.push(asset.assetID);
      }

      return post;
   }

   public async getAssociatedAssetsForPart(
      partID: number,
   ): Promise<PartAssetAssociationInfo[]> {
      if (!partID) return [];

      const part = this.manageParts.getPart(partID);
      assert(part);

      const associatedAssets: PartAssetAssociationInfo[] = [];

      const assetRelations = await firstValueFrom(
         this.assetPartAssociationService.getAssets([partID]),
      );

      for (const relation of assetRelations) {
         const asset = this.manageAsset.getAsset(relation.assetID);
         if (!asset || asset?.assetDeleted) {
            continue;
         }

         const locationName =
            this.manageLocation.getLocation(asset.locationID)?.locationName ?? "";

         associatedAssets.push({
            assetID: relation.assetID,
            manualPartRelation: relation.association_type === AssociationType.Manual,
            automaticPartRelation:
               relation.association_type === AssociationType.Automatic,
            assetPartQty: relation.assetPartQty ?? 0,
            assetName: asset.assetName ?? "",
            locationName,
         });
      }

      return associatedAssets;
   }
}
