import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, ViewChild, computed } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   ProgressBarComponent,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { BehaviorSubject, debounceTime, filter } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PickLocationsModal } from "src/app/locations/components/pickLocationsModal/pickLocations.modal.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { HierarchyContainerComponent } from "src/app/shared/components/global/hierarchy/hierarchy-container-component/hierarchy-container.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { TaskTemplateType } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { TemplateHierarchyNode } from "src/app/tasks/components/shared/services/task-templates-hierarchy/template-hierarchy.service";
import { TemplateHierarchyService } from "src/app/tasks/components/shared/services/task-templates-hierarchy/template-hierarchy.service";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "pick-instruction-set",
   templateUrl: "./pickInstructionSet.modal.component.html",
   styleUrls: ["./pickInstructionSet.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      SecondaryButtonComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      SearchBoxComponent,
      HierarchyContainerComponent,
      AlertComponent,
      BasicModalFooterComponent,
      LoadingAnimationComponent,
      ReactiveFormsModule,
      NoSearchResults,
      ProgressBarComponent,
      BetterDecimalPipe,
   ],
})
export class PickInstructionSetComponent implements OnInit, OnDestroy {
   @ViewChild("hierarchy") hierarchyContainer?: HierarchyContainerComponent;
   public message;
   public title;
   public resolve;
   public modalInstance;
   public instructionSetTasks: TaskLookup = new Lookup("checklistID");
   public templateHierarchyNodes: LimbleMap<number, TemplateHierarchyNode> =
      new LimbleMap();
   public hierOptions: HierarchyOptions;
   public treeData: Array<HierarchyNode> = [];
   public instructionSetSelectedID: number | undefined;
   public locationsIndex: Record<number, HierarchyNode> = {};
   private locations: Array<HierarchyNode> = [];
   public currentlyAt: number = 0;
   public end: number = 0;
   public progressObservable$: BehaviorSubject<number> = new BehaviorSubject(0);
   public progressSub: Subscription;

   protected noInstructionSetTemplatesExist: boolean = false;
   protected noSearchResults: boolean = false;
   protected isLoading: boolean = false;

   protected search: string = "";
   protected searchControl = new FormControl<string>("");

   private readonly searchControlSub: Subscription = this.searchControl.valueChanges
      .pipe(
         debounceTime(500),
         filter((searchValue) => {
            if (
               searchValue !== null &&
               searchValue.length > 0 &&
               searchValue.length < 3
            ) {
               return false;
            }
            return true;
         }),
      )
      .subscribe((search) => {
         this.fetchSearchedTemplates(search);
      });

   private readonly paramsService = inject(ParamsService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageTask = inject(ManageTask);
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly credService = inject(CredService);
   private readonly templateHierarchyService = inject(TemplateHierarchyService);
   private readonly manageLang = inject(ManageLang);
   private readonly tasksFacadeService = inject(TasksFacadeService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly modalCloseEventsSub = this.modalService.closeEvents
      .pipe(filter(() => this.modalService.getActiveModal() === this.modalInstance))
      .subscribe(() => {
         this.rebuildTreeData();
      });

   public constructor() {
      this.hierOptions = {
         idKey: "checklistID",
         selection: {
            singleSelection: true,
            submitOnClick: false,
         },
         nodeButtons: [
            {
               clickFunction: this.syncRelatedInstructionSets.bind(this),
               tooltip: this.lang().SyncRelatedInstructionSetsTooltip,
               permissionNumber: this.credService.Permissions.DeleteWOTemplates,
               icon: "rss",
            },
            {
               clickFunction: this.editInstructionSet.bind(this),
               tooltip: this.lang().Edit,
               permissionNumber: this.credService.Permissions.DeleteWOTemplates,
               icon: "pencil",
            },
            {
               clickFunction: this.deleteInstructionSet.bind(this),
               tooltip: this.lang().Delete,
               permissionNumber: this.credService.Permissions.DeleteWOTemplates,
               icon: "trashCanRegular",
               iconColor: "danger",
            },
         ],
         submit: this.submit,
      };

      this.progressSub = this.progressObservable$.subscribe((value) => {
         this.currentlyAt = value;
      });
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;

      this.fetchInitialTemplateData();
   }

   public ngOnDestroy() {
      this.progressSub.unsubscribe();
      this.searchControlSub.unsubscribe();
      this.modalCloseEventsSub.unsubscribe();
   }

   private async rebuildTreeData() {
      const { locationsIndex, treeData, noSearchResults, templateNodes } =
         await this.templateHierarchyService.rebuildTreeData(
            this.treeData,
            this.templateHierarchyNodes,
            {
               search: this.search,
               checklistTemplates: [TaskTemplateType.instructionSetTemplate],
            },
         );

      this.locationsIndex = locationsIndex;
      this.treeData = treeData;
      this.noSearchResults = noSearchResults;
      this.templateHierarchyNodes = templateNodes;

      if (this.hierarchyContainer) {
         this.hierarchyContainer.renderInitialHierarchyItems();
      }
   }

   private async fetchInitialTemplateData() {
      this.isLoading = true;
      this.buildLocations();

      const response = await this.templateHierarchyService
         .fetchInitialData(this.locations, {
            checklistTemplates: [TaskTemplateType.instructionSetTemplate],
         })
         .finally(() => {
            this.isLoading = false;
         });
      if (!response) {
         console.error("Error fetching initial data in pick-instruction-set");
         return;
      }
      this.treeData = response;
      if (this.treeData.length === 0) {
         this.noInstructionSetTemplatesExist = true;
      }
   }

   fetchMoreTemplatesAtLocation = async (locationID: number) => {
      const location = this.locationsIndex[locationID];

      await this.templateHierarchyService.fetchMoreTasksAtLocation(
         location,
         this.templateHierarchyNodes,
         {
            checklistTemplates: [TaskTemplateType.instructionSetTemplate],
            search: this.search ?? "",
         },
      );

      if (this.hierarchyContainer) {
         this.hierarchyContainer.renderInitialHierarchyItems();
      }
   };

   protected async fetchSearchedTemplates(search: string | null) {
      this.isLoading = true;
      this.noSearchResults = false;

      this.buildLocations();

      const response = await this.templateHierarchyService
         .fetchSearchedData(this.locations, {
            search: this.search,
            checklistTemplates: [TaskTemplateType.instructionSetTemplate],
         })
         .finally(() => {
            this.isLoading = false;
         });

      if (response === undefined) {
         console.error("Error fetching searched data in pick-instruction-set");
         return;
      }

      const { filteredTreeData, groupedResponses, noSearchResults, templateNodes } =
         response;
      this.treeData = filteredTreeData;

      this.noSearchResults = noSearchResults;
      this.templateHierarchyNodes = templateNodes;

      if (search === null || search === "") {
         return;
      }

      this.templateHierarchyService.processAndMapGroupedResponse(
         groupedResponses,
         this.locationsIndex,
         this.templateHierarchyNodes,
         this.search,
      );
   }

   private buildLocations() {
      const { locationsIndex, filteredTreeData } =
         this.templateHierarchyService.getLocationNodesWithoutRegions();

      this.locations = filteredTreeData;
      this.locationsIndex = locationsIndex;
   }

   submit = async (): Promise<void> => {
      const selectedInstructionSet = this.templateHierarchyNodes.find(
         (item) => item.selected,
      );

      if (selectedInstructionSet === undefined) {
         this.alertService.addAlert(
            this.lang().WhoopsPleaseSelectAnInstructionSet,
            "warning",
            4000,
         );
         return;
      }

      const itemsResponse = await this.manageTaskItem.getTaskItems(
         selectedInstructionSet.checklistID,
      );

      const instructionSet = itemsResponse.data.items.find(
         (item) => Number(item.itemTypeID) === TaskInstructionTypeID.InstructionSet,
      );
      this.modalInstance.close(instructionSet);
   };

   public close(): void {
      this.modalInstance.close();
   }

   public async setLocationID(): Promise<number> {
      const instance = this.modalService.open(PickLocationsModal);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().WhereShouldThisInstructionSetTemplateBeCreated,
            data: {
               selectOne: true,
               buttonText: this.lang().Select,
            },
         },
      };

      const locations = await instance.result;

      return locations[0].locationID;
   }

   public async newInstructionSet(): Promise<void> {
      const locationID = await this.setLocationID();
      const newTask = await this.manageTask.createInstructionSet(Number(locationID));
      if (!newTask) return;
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: newTask.checklistID,
               editable: true,
               title: `${this.lang().New} ${this.lang().InstructionSet}`,
               template: true,
            },
         },
      };
      instance.result.then(() => {
         const newInstructionSetID = this.instructionSetTasks.find(
            (item) => item.checklistID === newTask.checklistID,
         )?.checklistID;

         this.instructionSetSelectedID = newInstructionSetID;
      });
   }

   public async deleteInstructionSet(template: TemplateHierarchyNode): Promise<void> {
      if (template.locationID === undefined) {
         throw new Error("Template has no locationID and cannot be deleted");
      }
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ConfirmDeleteInstructionSetTemplate,
            title: this.lang().ConfirmDeleteInstructionSetTemplateTitle,
         },
      };

      const result = await instance.result;
      if (result !== 1) {
         return;
      }

      const { success } = await this.manageTask.deleteInstructionSet(
         template.checklistID,
         template.locationID,
      );
      if (success) {
         this.removeNodeLocally(template);
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   private removeNodeLocally(template: TemplateHierarchyNode): void {
      if (template.locationID === undefined) {
         throw new Error("Template has no locationID and cannot be deleted");
      }
      const location = this.locationsIndex[template.locationID];

      const nodeToDeleteIndex = location.nodes.findIndex(
         (node) => node.nodeID === template.checklistID,
      );

      if (nodeToDeleteIndex !== -1) {
         location.nodes.splice(nodeToDeleteIndex, 1);
      }
      this.templateHierarchyNodes.delete(template.checklistID);

      if (location.nodes.length === 0) {
         const locationIndex = this.treeData.indexOf(location);
         this.treeData.splice(locationIndex, 1);
         if (this.hierarchyContainer) {
            this.hierarchyContainer.renderInitialHierarchyItems();
         }
      }
   }

   public async editInstructionSet(
      instructionSetTask: TemplateHierarchyNode,
   ): Promise<void> {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: instructionSetTask.checklistID,
               editable: true,
               title: `${this.lang().Edit} ${this.lang().InstructionSet}`,
               template: true,
            },
         },
      };
      await instance.result;
      this.rebuildTreeData();
   }

   public async syncRelatedInstructionSets(instructionSetTask): Promise<void> {
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const resObj = await this.manageTaskItem.prepSyncRelatedInstructionSets(
         Number(instructionSetTask.checklistID),
      );
      this.loadingBarService.remove();
      if (!resObj) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: resObj.message,
            title: this.lang().ConfirmSyncRelatedInstructionSetsTitle,
         },
      };
      instance.result.then(async (result) => {
         if (result === 1) {
            this.currentlyAt = 0;
            this.end = resObj.toUpdateArray.length;
            const answer = await this.tasksFacadeService.syncRelatedInstructionSets(
               resObj.instructionSetBatchID,
               resObj.toUpdateArray,
               this.progressObservable$,
            );
            this.currentlyAt = 0;
            this.end = 0;
            if (answer) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         }
      });
   }
}
