import { Component, inject, type OnDestroy, type OnInit, signal } from "@angular/core";
import { DropdownButtonComponent, DropdownTextItemComponent } from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { ManageStatus } from "src/app/tasks/services/manageStatus";

export type StatusFlterType = {
   statusID: number;
   name: string;
};

@Component({
   selector: "task-status-filter",
   imports: [DropdownButtonComponent, DropdownTextItemComponent, TranslateDirective],
   templateUrl: "./task-status-filter.component.html",
})
export class TaskStatusFilterComponent
   extends BaseFilterComponent
   implements OnInit, OnDestroy
{
   private readonly manageStatus = inject(ManageStatus);

   statuses = signal<Array<StatusFlterType>>([]);

   private readonly statusesLoadedSub = this.manageStatus.statusesLoaded$.subscribe(
      () => {
         const statuses = this.fetchStatuses();
         this.statuses.set(statuses);
      },
   );

   public ngOnInit(): void {
      const statuses = this.fetchStatuses();
      this.statuses.set(statuses);
   }

   public ngOnDestroy(): void {
      this.statusesLoadedSub.unsubscribe();
   }

   public onSetFilter(status: StatusFlterType) {
      const value = { [this.filter().key]: status.statusID };
      this.set.emit({ ...this.filter(), activeLabel: status.name, value });
   }

   private fetchStatuses() {
      return this.manageStatus.getStatusList().map(
         (status) =>
            ({
               statusID: status.statusID,
               name: status.name,
            }) satisfies StatusFlterType,
      );
   }
}
