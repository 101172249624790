import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";

export type DowntimeEfficiencyRateCellViewModel = {
   downtimeEfficiencyRate?: number;
};

@Component({
   selector: "downtime-efficiency-rate-cell",
   imports: [CommonModule],
   templateUrl: "./downtime-efficiency-rate-cell.component.html",
})
export class DowntimeEfficiencyRateCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: DowntimeEfficiencyRateCellViewModel;

   @Input() public column?: Column;
}
