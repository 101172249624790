import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   ScrollContainerComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "view-list-of-tasks-by-schedule",
   templateUrl: "./viewListOfTasksBySchedule.modal.component.html",
   styleUrls: ["./viewListOfTasksBySchedule.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SortColumn,
      ScrollContainerComponent,
      NgClass,
      IconComponent,
      TooltipDirective,
      LoadingAnimationComponent,
      BetterDatePipe,
   ],
})
export class ViewListOfTasksBySchedule implements OnInit {
   public message;
   public title;
   public schedules;
   public loadingBar;
   public sortBind;
   public resolve;
   public modalInstance;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLocation = inject(ManageLocation);
   private readonly credService = inject(CredService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.schedules = this.resolve.schedules;
      this.loadingBar = false;

      const users = this.manageUser.getFlatUsersIndex();
      const profiles = this.manageUser.getProfilesIndex();
      const locations = this.manageLocation.getLocationsIndex();

      this.sortBind = "checklistName";
      for (const key in this.schedules) {
         if (this.schedules[key].userID > 0) {
            this.schedules[key].assignment = `${
               users[this.schedules[key].userID].userFirstName
            } ${users[this.schedules[key].userID].userLastName}`;
         } else if (this.schedules[key].profileID > 0) {
            this.schedules[key].assignment =
               profiles[this.schedules[key].profileID].profileDescription;
         } else {
            this.schedules[key].assignment = this.lang().Unassigned;
         }

         this.schedules[key].locationName =
            locations[this.schedules[key].locationID].locationName;
      }
   }

   protected changeSchedule(schedule): void {
      if (
         !this.credService.isAuthorized(
            schedule.locationID,
            this.credService.Permissions.ChangePMAssignments,
         )
      ) {
         this.alertService.addAlert(this.lang().cred56Fail, "danger", 10000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };

      extraUsersOptions.arr.forEach((user) => {
         extraUsersOptions.key[user.userID] = user;
      });

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().ChangeTheTaskAssignmentForASingleScheduleMsg,
               title: this.lang().ChangeTheTaskAssignmentForASingleSchedule,
               locationID: schedule.locationID,
               extraUsers: extraUsersOptions.arr,
            },
         },
      };

      instance.result.then((data) => {
         if (data) {
            this.manageTask
               .updateSingleSchedule(
                  data.userID,
                  data.profileID,
                  data.multiUsers,
                  schedule.scheduleID,
               )
               .then((answer) => {
                  if (answer.data.lName == null) {
                     schedule.assignment = answer.data.fName;
                  } else {
                     schedule.assignment = `${answer.data.fName} ${answer.data.lName}`;
                  }

                  schedule.userID = data.userID;
                  schedule.profileID = data.profileID;
               });
         }
      });
   }

   popTask = (checklistID: number) => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: false,
               preview: true,
               template: true,
            },
         },
      };
   };

   protected viewAsset(schedule): void {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: schedule.assetID,
            locationID: schedule.locationID,
            data: {},
         },
      };
   }

   protected checkCredAtLocation(locationID: number, credID: number): boolean {
      return this.credService.isAuthorized(locationID, credID);
   }

   protected close(): void {
      this.modalInstance.close(0);
   }
}
