import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable, UnaryFunction } from "rxjs";
import { filter, map, mergeMap, pipe, retry, shareReplay } from "rxjs";
import { WidgetCacheService } from "src/app/dashboards/custom-dashboards/custom-dashboard/printable-dashboard/printable-widget/widget-cache.service";
import type {
   Widget,
   WidgetDefinition,
} from "src/app/dashboards/custom-dashboards/customDashboard.types";
import type { WidgetContent } from "src/app/dashboards/widgets/widget/widgetContent.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";
import { environment } from "src/environments/environment";

/** @beta plaid-dashboards */
@Injectable({ providedIn: "root" })
export class WidgetService {
   public readonly WIDGET_CONTENT_CACHE_TTL: number; // cache TTL in milliseconds
   private static readonly widgetContentCache$ = new Map<
      number,
      Observable<WidgetContent>
   >(); // singleton cache instance for widget content

   private readonly http = inject(HttpClient);
   private readonly widgetCacheService = inject(WidgetCacheService);

   public constructor() {
      // adjust caching time based on customer data size.
      const dataLimitingNumberOfMonths: number =
         inject(ManageUser).getCurrentUser()?.userInfo?.dataLimitingNumberOfMonths ?? 0;
      if (dataLimitingNumberOfMonths === 1) {
         this.WIDGET_CONTENT_CACHE_TTL = 120000;
      } else if (dataLimitingNumberOfMonths > 1) {
         this.WIDGET_CONTENT_CACHE_TTL = 30000;
      } else {
         this.WIDGET_CONTENT_CACHE_TTL = 10000;
      }
   }

   public fetchWidgets(params: {
      widgetIDs?: Array<number>;
      dashboardIDs?: Array<number>;
   }): Observable<Lookup<"widgetID", Widget>> {
      const serializedParams = this.serializeParams(params);
      return this.http
         .get<Array<Widget>>(`${environment.flannelUrl}/dashboards/custom/widgets`, {
            params: serializedParams,
         })
         .pipe(
            map((answer) => {
               //We have to sort the widgets so that they display in the correct order
               //on mobile view, because Gridster does not respect coordinates in mobile.
               return new Lookup("widgetID", this.sortWidgetsByCoordinates(answer));
            }),
         );
   }

   public fetchWidgetDefinition(params: {
      widgetIDs: Array<number>;
   }): Observable<Array<WidgetDefinition>> {
      const serializedParams = this.serializeParams(params);
      const result = this.http
         .get<
            Array<WidgetDefinition>
         >(`${environment.flannelUrl}/dashboards/custom/widgets/definitions`, { params: serializedParams })
         .pipe(shareReplay(1));
      result.subscribe((widgetDefs) => {
         for (const widgetDef of widgetDefs) {
            this.widgetCacheService.setWidgetDef(widgetDef.widgetID, widgetDef);
         }
      });
      return result;
   }

   public fetchWidgetContent(
      widgetDef: WidgetDefinition,
      forceRefresh = true,
   ): Observable<WidgetContent> {
      assert(widgetDef !== undefined);
      if (forceRefresh || !WidgetService.widgetContentCache$.has(widgetDef.widgetID)) {
         const fetchWidgetContent$ = this.http
            .get(
               `${environment.flannelUrl}/dashboards/custom/widgets/content?widgetIDs=${widgetDef.widgetID}`,
               {
                  params: {
                     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  },
               },
            )
            .pipe(
               map((response) => response[0]),
               shareReplay(1, this.WIDGET_CONTENT_CACHE_TTL),
               retry({
                  count: 2,
                  delay: 3000, // delay the re-request a few seconds to give a better change of subsequent success
               }),
            );

         // set to cache
         WidgetService.widgetContentCache$.set(widgetDef.widgetID, fetchWidgetContent$);
         // clear out from cache after TTL to avoid cache growing infinitely
         setTimeout(() => {
            WidgetService.widgetContentCache$.delete(widgetDef.widgetID);
         }, this.WIDGET_CONTENT_CACHE_TTL);
         fetchWidgetContent$.subscribe((widgetContent) => {
            this.widgetCacheService.setWidgetContent(widgetDef.widgetID, widgetContent);
         });
      }

      return WidgetService.widgetContentCache$.get(
         widgetDef.widgetID,
      ) as Observable<WidgetContent>;
   }

   public clearWidgetContentCache(widgetID: number | undefined = undefined): void {
      if (widgetID) {
         WidgetService.widgetContentCache$.delete(widgetID);
      } else {
         WidgetService.widgetContentCache$.clear();
      }
   }

   /**
    * @deprecated plaid-dashboards
    * A temporary feature-flagging utility that will go away once we have finished
    * the migration to Flannel.
    */
   public async usePlaidBackend(widgetDef: WidgetDefinition): Promise<boolean> {
      // TODO (amartin): temporary - emergency fix for list widgets (other than Tasks). Will need to be removed and/or cleaned up later.
      if (
         ["assets", "parts", "POs"].includes(widgetDef.type as string) &&
         widgetDef.viewedAs === "listView"
      ) {
         return Promise.resolve(false);
      }

      return Promise.resolve(true);
   }

   // Halt the pipeline if we're using Plaid.
   public haltOnPlaid<T>(
      widgetDef: () => WidgetDefinition | undefined,
   ): UnaryFunction<Observable<T>, Observable<T>> {
      return this.usePlaidBackendPipe(false, widgetDef);
   }

   // Halt the pipeline if we're not using Plaid.
   public restrictToPlaid<T>(
      widgetDef: () => WidgetDefinition | undefined,
   ): UnaryFunction<Observable<T>, Observable<T>> {
      return this.usePlaidBackendPipe(true, widgetDef);
   }

   /**
    * @deprecated plaid-dashboards
    * A temporary feature-flagging utility that will go away once we have finished
    * the migration to Flannel.
    *
    * This override tool can be used by SEs in any environment for debugging.
    */
   public localStorageOverride(): boolean {
      // Use the backend for all widgets when value is "use backend". All other values should use the frontend
      return localStorage.getItem("widgets-migration-override") === "use backend";
   }

   private usePlaidBackendPipe<T>(
      shouldUsePlaid: boolean,
      widgetDef: () => WidgetDefinition | undefined,
   ): UnaryFunction<Observable<T>, Observable<T>> {
      return pipe(
         mergeMap(async (value: T): Promise<[T, boolean]> => {
            const def = widgetDef();
            if (def === undefined) {
               // Not really sure what to do if the widget def is undefined, so let's just not
               // do anything.
               return [value, false];
            }
            const usePlaid = await this.usePlaidBackend(def);
            return [value, shouldUsePlaid === usePlaid];
         }),
         filter((row) => row[1] === true),
         map((row) => row[0]),
      );
   }

   private sortWidgetsByCoordinates(widgets: Array<Widget>): Array<Widget> {
      return widgets.sort((widgetA, widgetB) => {
         if (widgetA.y < widgetB.y) return -1;
         if (widgetA.y > widgetB.y) return 1;
         if (widgetA.x < widgetB.x) return -1;
         if (widgetA.x > widgetB.x) return 1;
         return 0;
      });
   }

   private serializeParams(params: {
      [param: string]: string | number | boolean | Array<string | number | boolean>;
   }): {
      [param: string]: string | number | boolean;
   } {
      const serializedParams: { [param: string]: string | number | boolean } = {};
      for (const [key, value] of Object.entries(params)) {
         if (Array.isArray(value)) {
            serializedParams[key] = value.join(",");
         } else {
            serializedParams[key] = value;
         }
      }
      return serializedParams;
   }
}
