import type { OnInit } from "@angular/core";
import { inject, Component, computed, input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   DropdownComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";

// Because of how this data was created originally, a negative value is 'after' a due date and a positive value is 'before' a due date
// We do various checks and formatting in here to make the UI more intuitive so the customer doesn't have to deal with negative numbers
@Component({
   selector: "task-color-status-config",
   styleUrls: ["./taskColorStatusConfig.element.component.scss"],
   templateUrl: "./taskColorStatusConfig.element.component.html",
   imports: [
      DropdownComponent,
      DropdownTextItemComponent,
      DropdownItemComponent,
      FormsModule,
   ],
})
export class TaskColorStatusConfig implements OnInit {
   public task: Task | undefined;

   public readonly data = input<any>(undefined);
   public readonly taskID = input<number | undefined>(undefined);
   public readonly disableAlerts = input<boolean | undefined>(undefined);

   public readonly taskColorStatusViewParameters = input<
      | {
           disableAlerts: boolean;
        }
      | undefined
   >(undefined);

   protected readonly disableAlertsSignal = computed(() => {
      return (
         this.taskColorStatusViewParameters()?.disableAlerts ?? this.data().disableAlerts
      );
   });

   protected readonly taskIDSignal = computed(() => {
      return this.taskID() ?? this.data().checklistID;
   });

   public checklistGreenDropdownStr;
   public checklistYellowDropdownStr;
   public checklistRedDropdownStr;
   public checklistYellowDisplayDays;
   public checklistRedDisplayDays;
   public dayOptions: Array<{
      days: number;
      display: string;
   }>;

   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.dayOptions = [
         { days: 1, display: `1 ${this.lang().day}` },
         { days: 2, display: `2 ${this.lang().days}` },
         { days: 3, display: `3 ${this.lang().days}` },
         { days: 7, display: `7 ${this.lang().days}` },
         { days: 30, display: `30 ${this.lang().days}` },
      ];
   }

   public ngOnInit() {
      const checklistID = this.taskIDSignal();
      this.task = this.manageTask.getTaskLocalLookup(checklistID);
      this.setTaskConfigStrs(this.task);
   }

   protected setTaskConfigStrs(task: Task | undefined): void {
      if (!this.task) {
         return;
      }

      if (!task) {
         return;
      }

      // Format numbers as needed to make UI more clear
      this.checklistYellowDisplayDays = Number(this.task.checklistYellow) * -1;
      this.checklistRedDisplayDays = Number(this.task.checklistRed) * -1;

      // Create the strings for the UI
      if (task.checklistGreen === 0) {
         this.checklistGreenDropdownStr = this.lang().TheDayOf;
      } else if (task.checklistGreen === 1) {
         this.checklistGreenDropdownStr = `${task.checklistGreen} ${this.lang().day} ${this.lang().before}`;
      } else {
         this.checklistGreenDropdownStr = `${task.checklistGreen} ${this.lang().days} ${this.lang().before}`;
      }

      if (task.checklistYellow === 0) {
         this.checklistYellowDropdownStr = this.lang().TheDayOf;
      } else if (task.checklistYellow === -1) {
         this.checklistYellowDropdownStr = `${this.checklistYellowDisplayDays} ${this.lang().day} ${this.lang().after}`;
      } else {
         this.checklistYellowDropdownStr = `${this.checklistYellowDisplayDays} ${this.lang().days} ${this.lang().after}`;
      }

      if (task.checklistRed === 0) {
         this.checklistRedDropdownStr = this.lang().TheDayOf;
      } else if (task.checklistRed === -1) {
         this.checklistRedDropdownStr = `${this.checklistRedDisplayDays} ${this.lang().day} ${this.lang().after}`;
      } else {
         this.checklistRedDropdownStr = `${this.checklistRedDisplayDays} ${this.lang().days} ${this.lang().after}`;
      }
   }

   protected updateTemplateScheduleColor(
      tempChk: Task,
      value: number | null,
      colorID: 1 | 2 | 3,
      xDays = false,
   ): void {
      const numberValue = Number(value);
      let newVal = numberValue;
      // We only have to worry about xDays for yellow/red statuses
      if (xDays && colorID !== 1) {
         newVal = numberValue * -1;
      }

      switch (colorID) {
         case 1:
            tempChk.checklistGreen = newVal;
            break;
         case 2:
            tempChk.checklistYellow = newVal;
            break;
         case 3:
            tempChk.checklistRed = newVal;
            break;
         default:
            tempChk.checklistGreen = newVal;
            break;
      }

      this.manageTask
         .updateTemplateScheduleColor(tempChk.checklistID, newVal, colorID)
         .then(
            (answer) => {
               if (answer.data.success !== true) {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  return;
               }

               this.setTaskConfigStrs(tempChk);
               if (!this.disableAlertsSignal()) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               }
            },
            () => {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            },
         );
   }
}
