import { Component, input } from "@angular/core";
import { UnitLabelComponent } from "src/app/parts/unit-of-measure/components/unit-label/unit-label.component";
import { StockUnitsRoundingPipe } from "src/app/parts/unit-of-measure/pipes/stock-units-rounding.pipe";
import type { UnitDisplay } from "src/app/purchasing/pos/purchasable-snapshot/unit-display.model";

@Component({
   selector: "unit-conversion",
   templateUrl: "./unit-conversion.component.html",
   styleUrls: ["./unit-conversion.component.scss"],
   imports: [StockUnitsRoundingPipe, UnitLabelComponent],
})
export class UnitConversionComponent {
   public readonly stockUnits = input.required<number>();
   public readonly unitDisplay = input.required<UnitDisplay>();
   public readonly enclosed = input<boolean>();
}
