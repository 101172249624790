<div #dummyContainer [hidden]="!item.dummy || !preview" class="dummy-container">
   <div class="offset-by-container">
      <span>
         {{ item.displayIndex + 1 }}
      </span>
   </div>
</div>
<div [hidden]="item.dummy">
   <div>
      <div #qrCodeContainer class="qr-code-container">
         <span #dataContainer class="dataContainer">
            <div class="data-container-record">
               <div class="fields-container">
                  @if (item.title) {
                     <div class="value-title">
                        {{ item.title }}
                     </div>
                  }
                  <!-- item name-->
                  <div class="header-and-value-container">
                     <span class="header-label">
                        {{ dataType | titlecase }} {{ lang().Name }}:
                     </span>
                     <span class="value-label" [limbleHtml]="item.name"></span>
                  </div>
               </div>
               <div class="fields-container">
                  @for (field of filteredFieldsArray; track field) {
                     <div class="header-and-value-container">
                        <!-- field name -->
                        <span class="header-label">
                           <span [limbleHtml]="field.fieldName"></span>:
                        </span>

                        @switch (field.fieldTypeID) {
                           <!-- date type -->
                           @case (2) {
                              <span class="value-label">
                                 {{ field.valueContent | betterDate: "date" }}
                              </span>
                           }
                           <!-- assets type -->
                           @case ("assetsArray") {
                              <span class="value-label">
                                 @for (
                                    asset of field.valueContent;
                                    track asset;
                                    let last = $last
                                 ) {
                                    <span>
                                       <span [limbleHtml]="asset.assetName"></span
                                       >{{ !last ? "," : "" }}
                                    </span>
                                 }
                              </span>
                           }
                           <!-- vendors type -->
                           @case ("vendorsArray") {
                              <span class="value-label">
                                 @for (
                                    vendor of field.valueContent;
                                    track vendor;
                                    let last = $last
                                 ) {
                                    <span>
                                       <span [limbleHtml]="vendor.vendorName"></span
                                       >{{ !last ? "," : "" }}
                                    </span>
                                 }
                              </span>
                           }
                           <!-- all other types -->
                           @default {
                              <span
                                 class="value-label"
                                 [limbleHtml]="field.valueContent"
                              ></span>
                           }
                        }
                     </div>
                  }
               </div>
            </div>
         </span>
      </div>
   </div>
</div>
