import { computed, inject, Injectable } from "@angular/core";
import { datadogLogs } from "@datadog/browser-logs";
import { isMobile } from "@limblecmms/lim-ui";
import moment from "moment";
import {
   BehaviorSubject,
   combineLatest,
   combineLatestWith,
   filter,
   from,
   map,
   type Observable,
   of,
   switchMap,
   take,
   tap,
} from "rxjs";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ConnectionService } from "src/app/lite/connection/connection.service";
import { OfflinePrepService } from "src/app/lite/local-db/offline-prep.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { AlertService } from "src/app/shared/services/alert.service";
import { FeatureFlagRefreshService } from "src/app/shared/services/feature-flags/feature-flag-refresh.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { logMemoryUsage } from "src/app/shared/services/performance-logger";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageProfile } from "src/app/users/services//manageProfile";
import { ManageUser } from "src/app/users/services//manageUser";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Injectable({ providedIn: "root" })
export class RefreshService {
   private readonly refreshingState$ = new BehaviorSubject<boolean>(false);
   private readonly lastRefreshed$ = new BehaviorSubject<string | null>(null);
   private shouldNotify = false;

   private readonly manageLang = inject(ManageLang);
   private readonly alertService = inject(AlertService);
   private readonly featureFlagRefreshService = inject(FeatureFlagRefreshService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUser = inject(ManageUser);
   private readonly manageParts = inject(ManageParts);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageDashboard = inject(ManageDashboard);
   private readonly manageVendor = inject(ManageVendor);
   private readonly managePO = inject(ManagePO);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageBilling = inject(ManageBilling);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageStatus = inject(ManageStatus);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageLogin = inject(ManageLogin);
   private readonly connectionService = inject(ConnectionService);
   private readonly offlinePrepService = inject(OfflinePrepService);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected isImproveFirstLoadEnabled: boolean | undefined;
   private initialLoadStartTime: number | undefined;

   public async refreshData({ force = false, notify = true } = {}): Promise<void> {
      const userHasInvalidCreds = await this.hasInvalidCreds();

      const shouldDoPartialRefresh =
         userHasInvalidCreds || (!force && !this.shouldRefresh());

      // Fetch priority and location data even for external users because it is
      // needed to start work orders for external users. Priority info is also
      // needed to properly render the task form.
      // We also need the asset data in order to load the asset fields for a given task for external users
      if (shouldDoPartialRefresh) {
         await Promise.all([
            this.managePriority.getData(),
            this.manageLocation.getData(),
            this.manageAsset.getData(),
         ]);
         return;
      }

      console.info("Limble: Starting data refresh.");
      const lang = this.lang();
      this.shouldNotify = notify && Object.keys(lang).length > 0;
      if (this.shouldNotify) {
         this.alertService.addAlert(lang.PullingTheLatestData, "success", 3000);
      }
      this.refreshingState$.next(true);
      await this.initializeData();
   }

   private isExternalView(): boolean {
      return window.location.href.includes("viewExternalTask");
   }

   private async hasInvalidCreds(): Promise<boolean> {
      await this.manageLogin.checkLogin();
      const userInfo = this.manageUser.getCurrentUser();
      return this.isExternalView() || userInfo === "none" || userInfo === undefined;
   }

   /**
    * Emits true when the data is being refreshed, false when a refresh finishes.
    * Emits the latest value immediately upon subscription.
    */
   public stateOfRefresh(): Observable<boolean> {
      return this.refreshingState$.asObservable();
   }

   /**
    * Emits the time when the data finishes refreshing. Emits the last value
    * immediately upon subscription, if it exists.
    */
   public lastRefreshed(): Observable<string> {
      return this.lastRefreshed$.pipe(
         filter((value): value is string => typeof value === "string"),
      );
   }

   /**
    * Emits and completes as soon as all the data has been initialized.
    * Note that this means it may emit and complete immediately.
    */
   public dataInitialized(): Observable<void> {
      return this.refreshingState$.pipe(
         combineLatestWith(this.lastRefreshed$),
         filter(
            ([refreshingState, lastRefreshed]) =>
               refreshingState === false && lastRefreshed !== null,
         ),
         take(1),
         map(() => undefined),
      );
   }

   private shouldRefresh(): boolean {
      return (
         (this.lastRefreshed$.value === null ||
            document.querySelectorAll("[do-not-auto-refresh]").length === 0) &&
         this.refreshingState$.value === false &&
         this.connectionService.isOnline() === true
      );
   }

   private async initializeData(): Promise<void> {
      this.isImproveFirstLoadEnabled ??= await this.launchFlagsService.isEnabled(
         Flags.CT_IMPROVE_FIRST_LOAD,
      );
      const initializeForMobile = isMobile();

      let endPhaseTwoTime: number, phaseTwoTotal: number;

      const isReadyForPhaseTwo = new BehaviorSubject(false);
      const isReadyForCompletedTasks = new BehaviorSubject(false);
      const isReadyForPhaseThree = new BehaviorSubject(false);

      combineLatest(
         isReadyForPhaseTwo.asObservable(),
         from(this.manageTask.requestUncompletedTasks()),
      )
         .pipe(
            filter(([ready]) => ready),
            take(1),
            tap(() => {
               this.featureFlagRefreshService.refresh();
            }),

            switchMap(async ([, data]) => {
               // Get locations first as other loads rely on it.
               await this.manageLocation.getData();
               return Promise.all([
                  this.manageTask.getDataNew(data),
                  this.manageUser.getData(),
                  this.manageProfile.getData(0),
                  this.manageParts.getData(),
                  this.manageInvoice.getData(),
                  this.manageVendor.getData(),
                  this.managePO.getData(),
                  this.manageBilling.getData(),
                  this.manageAssociations.getData(),
                  this.managePriority.getData(),
                  this.manageAsset.getData(),
               ]);
            }),
         )
         .subscribe(() => {
            endPhaseTwoTime = Math.floor(Date.now());
            phaseTwoTotal = endPhaseTwoTime - endPhaseOneTime;

            this.manageParts.calculateDataForAllParts();

            if (initializeForMobile) isReadyForPhaseThree.next(true);
            else isReadyForCompletedTasks.next(true);
         });

      if (!initializeForMobile) {
         // TODO: remove this when the cleaning up the flag
         //  This is to start loading the completed tasks and saving them in local storage
         if (this.isImproveFirstLoadEnabled) {
            this.manageTask.requestCompletedTasks();
         }
         combineLatest([
            // If we have the flag, we just pass bogus data to keep going with the other calls
            this.isImproveFirstLoadEnabled
               ? of({ tasks: [] })
               : this.manageTask.requestCompletedTasksLegacy(),
            isReadyForCompletedTasks.asObservable(),
         ])
            .pipe(
               filter(([, ready]) => ready),
               take(1),
            )
            .subscribe(([response]) => {
               // If we don't have the flag, we process the completed tasks,
               // otherwise the tasks will be processed by the call to `requestCompletedTasks`
               if (!this.isImproveFirstLoadEnabled) {
                  this.manageTask.processCompletedTasksLegacy(response.tasks);
               }
               isReadyForPhaseThree.next(true);
            });
      }

      isReadyForPhaseThree
         .asObservable()
         .pipe(
            filter((done) => done),
            take(1),
         )
         .subscribe(() => {
            this.manageProfile.sortData();

            const userInfo = this.manageUser.getCurrentUser().userInfo;
            const userID: number = userInfo.userID;
            const customerID: number = userInfo.customerID;

            const totalTimeToInitData = Math.floor(Date.now()) - beginPhaseOneTime;

            const refreshTimeLog = {
               name: "appLoaded",
               userID,
               totalTimeToInitData,
               phaseOneInit: phaseOneTotal,
               phaseTwoInit: phaseTwoTotal,
               customerID,
               isMobile: initializeForMobile,
            };

            datadogLogs.logger.info("App loaded", refreshTimeLog);

            logMemoryUsage(userID, customerID, false);

            this.onRefreshComplete(refreshTimeLog);
         });

      const beginPhaseOneTime = Math.floor(Date.now());

      if (this.initialLoadStartTime === undefined) {
         this.initialLoadStartTime = beginPhaseOneTime;
      }

      this.manageTask.clearData();
      await this.manageStatus.getStatusData();

      const endPhaseOneTime = Math.floor(Date.now());
      const phaseOneTotal = endPhaseOneTime - beginPhaseOneTime;
      isReadyForPhaseTwo.next(true);
   }

   private onRefreshComplete(refreshTimeLog: any): void {
      this.manageDashboard.fetchDashboards().subscribe(() => {
         if (this.shouldNotify) {
            this.alertService.addAlert(
               this.lang().FinishedPullingTheLatestData,
               "success",
               3000,
            );
         }
      });
      this.refreshingState$.next(false);
      this.lastRefreshed$.next(moment().format("LT"));
      console.info("Limble: Finished data refresh.");
      console.info("Refresh time log", refreshTimeLog);
      this.offlinePrepService.prepare();
   }

   public logTimeToLandingPageComponentLoad(componentSelector: string) {
      if (
         window.history.state.navigationId !== 1 ||
         this.initialLoadStartTime === undefined
      ) {
         //This log should only be recorded when the component being looked at is part of the initial landing page of the app
         return;
      }
      const timeToLoadLandingPageComponent = Date.now() - this.initialLoadStartTime;
      console.info("Time to load landing page component", {
         timeToLoad: timeToLoadLandingPageComponent,
         componentSelector: componentSelector,
      });
      const userInfo = this.manageUser.getCurrentUser().userInfo;
      const userID: number = userInfo.userID;
      const customerID: number = userInfo.customerID;
      datadogLogs.logger.info("Time to load landing page component", {
         name: "timeToLoadLandingPageComponent",
         customerID,
         userID,
         timeToLoad: timeToLoadLandingPageComponent,
         componentName: componentSelector,
      });
   }
}
