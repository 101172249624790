@if (vendor) {
   <lim-ui-entity-viewer-modal
      description-color-class="blue"
      [entityTitle]="
         vendor.vendorDeleted
            ? vendor.vendorName + '-' + lang().VendorWasDeleted
            : vendor.vendorName
      "
      watermarkIcon="addressCard"
      (closeModalEvent)="emitCloseModal()"
      class="scroll-height-inheritance"
   >
      <ng-container header-title>
         <span
            [limbleHtml]="
               vendor.vendorDeleted
                  ? vendor.vendorName + '-' + lang().VendorWasDeleted
                  : vendor.vendorName
            "
         ></span>
      </ng-container>
      <ng-container entity-image>
         @if (uploadObj) {
            <image-tile
               [uploadObject]="uploadObj"
               [image]="vendorImageObj"
               [canEdit]="!uploadObj.viewOnly"
               type="medium"
            />
         }
      </ng-container>

      <ng-container header-buttons>
         @if (
            !isDemo && creds.editVendorSettings && vendor.vendorDeleted === 0 && !restrict
         ) {
            <lim-ui-minimal-icon-button
               icon="gearRegular"
               iconSize="medium-large"
               (click)="settings()"
               limUiTooltip="{{ lang().Settings }}"
               placement="top"
            />
         }
      </ng-container>

      <ng-container entity-viewer>
         <div class="tab-bar-container">
            <lim-ui-tab-bar
               [selectedTabId]="selectedTabId"
               [tabList]="pageTabs"
               (selectedTabIdChange)="updateSelectedTab($event)"
               class="tab-bar"
            >
               @if (selectedTabId === pageTabs[0].id) {
                  <div tabHeader class="tab-bar-content">
                     <span class="tab-header-message">{{
                        lang().InformationVendorTabIntro
                     }}</span>
                     <span class="right-align-buttons">
                        <lim-ui-icon-button
                           print-div
                           icon="printRegular"
                           limUiTooltip="{{ vendor.vendorName }} - {{
                              lang().PrintVendorInformationTab
                           }}"
                           placement="left"
                           attr.target="vendorInfo{{ vendor.vendorID }}"
                        />
                     </span>
                  </div>
               }

               @if (selectedTabId === pageTabs[1].id) {
                  <div tabHeader class="tab-bar-content">
                     <span class="tab-header-message">
                        {{ lang().VendorTabWorkOrdersHeadingMsg }}
                     </span>
                     <div class="right-align-buttons">
                        <lim-ui-icon-button
                           icon="printRegular"
                           print-div
                           attr.target="vendorWOPrint{{ vendor.vendorID }}"
                           limUiTooltip="{{ vendor.vendorName }} - {{
                              lang().PrintVendorWOTab
                           }}"
                           placement="left"
                        />
                     </div>
                  </div>
               }

               @if (selectedTabId === pageTabs[2].id) {
                  <div tabHeader>
                     {{ lang().BelowIsAListOfAssetsThisVendorIsAssociatedWith }}
                  </div>
               }

               @if (selectedTabId === pageTabs[3].id) {
                  <div tabHeader>
                     {{ lang().BelowIsAListOfPartsThisVendorIsAssociatedWith }}
                  </div>
               }

               @if (selectedTabId === pageTabs[4].id) {
                  <div tabHeader>
                     {{ lang().BelowIsAListOfPOsThisVendorIsAssociatedWith }}
                  </div>
               }

               @if (selectedTabId === pageTabs[5].id) {
                  <div tabHeader class="tab-bar-content">
                     <span class="tab-header-message">{{
                        lang().VendorTabLogHeadingMsg
                     }}</span>
                     <div class="right-align-buttons">
                        <lim-ui-icon-button
                           icon="printRegular"
                           print-div
                           attr.target="vendorLogPrint{{ vendor.vendorID }}"
                           limUiTooltip="{{ vendor.vendorName }} - {{
                              lang().PrintVendorLogTab
                           }}"
                           placement="left"
                        />
                     </div>
                  </div>
               }
            </lim-ui-tab-bar>
         </div>

         @if (scrollableContent) {
            <lim-ui-scroll-container [thin]="true">
               <ng-container *ngTemplateOutlet="mainContent" />
            </lim-ui-scroll-container>
         } @else {
            <div class="main-content">
               @if (selectedTabId === pageTabs[0].id) {
                  <div>
                     <vendor-information [vendor]="vendor" [restrict]="restrict" />
                  </div>
               }

               @if (selectedTabId === pageTabs[1].id) {
                  @if (newVendorWOWrapperEnabled) {
                     <div>
                        <vendor-work-order-wrapper [vendor]="vendor" />
                     </div>
                  } @else {
                     <div>
                        <vendor-work-order-wrapper-legacy [vendor]="vendor" />
                     </div>
                  }
               }

               @if (selectedTabId === pageTabs[2].id) {
                  <div>
                     <vendor-assets [restrict]="restrict" [vendorID]="vendor.vendorID" />
                  </div>
               }

               @if (selectedTabId === pageTabs[3].id) {
                  <div>
                     <vendor-parts [vendor]="vendor" />
                  </div>
               }

               @if (selectedTabId === pageTabs[4].id) {
                  <div>
                     <vendor-purchase-order [vendor]="vendor" />
                  </div>
               }

               @if (selectedTabId === pageTabs[5].id) {
                  <div>
                     <vendor-log [vendorID]="vendor.vendorID" [restrict]="restrict" />
                  </div>
               }
            </div>
         }

         <ng-template #mainContent>
            <div class="main-content">
               @if (selectedTabId === pageTabs[0].id) {
                  <div>
                     <vendor-information [vendor]="vendor" [restrict]="restrict" />
                  </div>
               }

               @if (selectedTabId === pageTabs[1].id) {
                  @if (newVendorWOWrapperEnabled) {
                     <div>
                        <vendor-work-order-wrapper [vendor]="vendor" />
                     </div>
                  } @else {
                     <div>
                        <vendor-work-order-wrapper-legacy [vendor]="vendor" />
                     </div>
                  }
               }

               @if (selectedTabId === pageTabs[2].id) {
                  <div>
                     <vendor-assets [restrict]="restrict" [vendorID]="vendor.vendorID" />
                  </div>
               }

               @if (selectedTabId === pageTabs[3].id) {
                  <div>
                     <vendor-parts [vendor]="vendor" />
                  </div>
               }

               @if (selectedTabId === pageTabs[4].id) {
                  <div>
                     <vendor-purchase-order [vendor]="vendor" />
                  </div>
               }

               @if (selectedTabId === pageTabs[5].id) {
                  <div>
                     <vendor-log [vendorID]="vendor.vendorID" [restrict]="restrict" />
                  </div>
               }
            </div>
         </ng-template>
      </ng-container>
   </lim-ui-entity-viewer-modal>
}
