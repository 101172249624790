<lim-ui-modal [showWatermark]="true" [small]="isSmallConfirm">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="unit-of-measure-confirmation-container">
            <div [limbleHtml]="message"></div>
            @if (showLearnMoreLink) {
               <div class="help-section">
                  @if (unitOfMeasureTutorialName !== undefined) {
                     <lim-ui-secondary-button
                        icon="youtube"
                        iconColor="youtube-red"
                        (click)="watchTutorial()"
                        [limUiTooltip]="tutorialTooltip()"
                        placement="left"
                     >
                        {{ lang().Tutorial }}
                     </lim-ui-secondary-button>
                  }
                  <a
                     href="https://help.limblecmms.com/en/articles/9943894"
                     target="_blank"
                  >
                     {{ lang().LearnMore }}
                     <lim-ui-icon icon="arrowUpRightFromSquare" iconSize="small" />
                  </a>
               </div>
            }
         </div>
      </lim-ui-panel>
      @if (showCheckbox) {
         <lim-ui-panel>
            <div class="confirm-checkbox-container">
               <lim-ui-checkbox
                  [model]="isConfirmed"
                  (modelChange)="isConfirmed = !isConfirmed"
               />
               <strong class="confirm-text">
                  {{ lang().ConfirmCheckboxTextUnitOfMeasureModal }}
               </strong>
            </div>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (!isDeleteAction) {
         <lim-ui-secondary-button (click)="close()">
            {{ dismissButtonText }}
         </lim-ui-secondary-button>
         <lim-ui-primary-button
            [dataLogLabel]="'manageUnitsOfMeasure_storeConfirmUnitOrPurchasable'"
            (click)="submit()"
         >
            {{ actionButtonText }}
         </lim-ui-primary-button>
      } @else {
         <lim-ui-secondary-button
            [dataLogLabel]="'manageUnitsOfMeasure_storeConfirmUnitOrPurchasable'"
            (click)="submit()"
         >
            {{ actionButtonText }}
         </lim-ui-secondary-button>
         <lim-ui-primary-button (click)="close()">
            {{ dismissButtonText }}
         </lim-ui-primary-button>
      }
   </lim-ui-modal-footer>
</lim-ui-modal>
