import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
} from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { PartLogSourceID } from "src/app/parts/components/shared/services/logs-api/part-log-api.models";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";

@Component({
   selector: "part-log-type-filter",
   imports: [
      CommonModule,
      DropdownClearFilterItemComponent,
      DropdownDividerComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      TranslateDirective,
   ],
   templateUrl: "./part-log-type-filter.component.html",
})
export class PartLogTypeFilterComponent extends BaseFilterComponent {
   protected readonly PartLogSourceID = PartLogSourceID;

   public handleSetTypeFilter(taskType: PartLogSourceID): void {
      this.setState(taskType, this.getGroupName(taskType));
   }

   public handleClear() {
      this.remove.emit(this.filter());
   }

   public setState(value: PartLogSourceID, activeLabel: string): void {
      const sourceValue = {
         [this.filter().key]: value,
      };
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value: sourceValue,
      });
   }

   public getGroupName(group: PartLogSourceID): string {
      // eslint-disable-next-line default-case -- We want Typescript to check this.
      switch (group) {
         case PartLogSourceID.AddedLogEntry:
            return this.translateService.instant("ManualEntry");
         case PartLogSourceID.UpdatedPartName:
            return this.translateService.instant("ManualUpdateName");
         case PartLogSourceID.UpdatedPartNumber:
            return this.translateService.instant("ManualUpdateNumber");
         case PartLogSourceID.UpdatedPartQuantity:
            return this.translateService.instant("ManualUpdateQty");
         case PartLogSourceID.UpdatedPartPrice:
            return this.translateService.instant("ManualUpdatePrice");
         case PartLogSourceID.UpdatedPartLocation:
            return this.translateService.instant("ManualUpdateLocation");
         case PartLogSourceID.UpdatedPartSupplier:
            return this.translateService.instant("ManualUpdateSupplier");
         case PartLogSourceID.UpdatedPartStaleThreshold:
            return this.translateService.instant("ManualUpdateStaleThreshold");
         case PartLogSourceID.UpdatedPartMaxQuantityThreshold:
            return this.translateService.instant("ManualUpdatePartQtyThreshold");
         case PartLogSourceID.TaskUsedPart:
            return this.translateService.instant("EntryFromTaskWOPM");
         case PartLogSourceID.PartQuantityThresholdStatusChanged:
            return this.translateService.instant("PartQtyStatusChange");
         case PartLogSourceID.PartImportedOrBulkUpdated:
            return this.translateService.instant("Import");
         default:
            console.error(`Unexpected task type group: ${group}`);
            return "";
      }
   }
}
