<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel class="head-panel-spacing">
         @if (message) {
            <span [limbleHtml]="message"></span>
         }
      </lim-ui-info-panel>

      <lim-ui-panel>
         <div class="settings-info-wrapper">
            <strong class="custom-title-with-buttons">{{ lang().Settings }} </strong>

            <task-color-status-config [data]="data" [taskID]="data.checklistID" />
         </div>
      </lim-ui-panel>

      @for (recurrence of recurrences; track recurrence) {
         <lim-ui-panel>
            <recurrence
               [taskTemplate]="taskTemplate"
               [isJitTemplatesEnabled]="true"
               [recurrence]="recurrence"
               [canToggleRecurrences]="canToggleRecurrences"
               (recurrenceDeleted)="deleteRecurrence($event)"
               (recurrenceUpdated)="updateRecurrence($event)"
            />
         </lim-ui-panel>
      }
   </lim-ui-modal-body>

   <lim-ui-modal-footer class="modal-footer-buttons">
      <lim-ui-secondary-button
         dataLogLabel="managePMs-initiateAPMSchedule"
         (click)="addRecurrence(taskTemplate)"
         [limUiPopover]="popoverAddScheduleButton"
         [limUiPopoverType]="'upsell'"
         [limUiPopoverPlacement]="'right'"
         [limUiPopoverHidden]="canToggleRecurrences"
         [disabled]="!canToggleRecurrences"
         [icon]="!canToggleRecurrences ? 'stars' : undefined"
         >{{ lang().AddSchedule }}</lim-ui-secondary-button
      >

      <ng-template #popoverAddScheduleButton>
         <upsell-popover
            [message]="lang().FeatureUnlimitedPMsPopoverMessage"
            [pricingLinkText]="lang().LearnMore"
         />
      </ng-template>
   </lim-ui-modal-footer>
</lim-ui-modal>
