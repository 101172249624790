<div class="container">
   <p-table
      [stripedRows]="stripedRows()"
      scrollHeight="flex"
      [scrollable]="true"
      [reorderableColumns]="true"
      [resizableColumns]="true"
      [size]="'small'"
      [columns]="columns()"
      [value]="data()"
      ngClass="table"
   >
      <ng-template #header let-columns>
         <tr class="header-row">
            @for (col of columns; track trackColumn($index, col, "header")) {
               <th
                  class="header-row__column"
                  pReorderableColumn
                  pFrozenColumn
                  [frozen]="col.frozen"
               >
                  <e-table-column-header
                     [column]="col"
                     [initialSort]="sort()"
                     (sortChange)="onSortChange($event)"
                  />
               </th>
            }
         </tr>
      </ng-template>
      <ng-template #body let-rowData let-columns="columns">
         <tr>
            @for (col of columns; track trackColumn($index, col, "body")) {
               <td>
                  @if (col.cellRenderer) {
                     <ng-container
                        *ngComponentOutlet="
                           col.cellRenderer;
                           inputs: {
                              data: rowData,
                              column: col,
                              dataLogSection: '',
                           }
                        "
                     />
                  } @else {
                     {{ rowData[col.field] }}
                  }
               </td>
            }
         </tr>
      </ng-template>
   </p-table>
</div>
