import { inject, Injectable } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import { firstValueFrom } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import {
   HIERARCHY_LIMIT,
   HierarchyService,
} from "src/app/shared/components/global/hierarchy/hierarchy.service";
import type { GroupedListResponse } from "src/app/shared/services/flannel-api-service";
import type { HierarchyNode } from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { DEFAULT } from "src/app/shared/utils/sortingHelpers";
import type {
   TaskTemplateEntity,
   TaskTemplateEntityFilters,
} from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplateType } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { DefaultSearchHintService as SearchHintService } from "src/app/tasks/search-hint/default-search-hint.service";

export type TemplateHierarchyNode = HierarchyNode & {
   checklistID: number;
   woTemplateLocked: boolean;
};

@Injectable({
   providedIn: "root",
})
export class TemplateHierarchyService extends HierarchyService {
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly searchHintService = inject(SearchHintService);
   private readonly manageAsset = inject(ManageAsset);

   public async fetchInitialData(
      treeData: Array<HierarchyNode>,
      queryParams: TaskTemplateEntityFilters,
   ) {
      const locationIDs = treeData.map((location) => location.locationID as number);

      const groupsOfGroupedTaskResponses: Array<
         Array<GroupedListResponse<TaskTemplateEntity>>
      > = [];
      const groupedResponsesStream =
         this.taskTemplatesApiService.getStreamedListGroupedByLocationID({
            filters: {
               locationIDs,
               excludeDeletedAssets: true,
               groupBy: "locationID",
               ...queryParams,
            },
         });
      for await (const groupedResponse of groupedResponsesStream) {
         groupsOfGroupedTaskResponses.push(groupedResponse);
      }
      const groupedTaskResponses = groupsOfGroupedTaskResponses.flat();

      return this.filterTreeDataToLocationsWithResponses<TaskTemplateEntity>(
         groupedTaskResponses,
         treeData,
      );
   }

   public async fetchSearchedData(
      treeData: Array<HierarchyNode>,
      queryParams: TaskTemplateEntityFilters,
   ): Promise<{
      filteredTreeData: Array<HierarchyNode>;
      groupedResponses: Array<GroupedListResponse<TaskTemplateEntity> | undefined>;
      noSearchResults: boolean;
      templateNodes: LimbleMap<number, TemplateHierarchyNode>;
   }> {
      const locationIDs = treeData.map((location) => location.locationID as number);

      const groupsOfGroupedTaskResponses: Array<
         Array<GroupedListResponse<TaskTemplateEntity>>
      > = [];
      const groupedResponsesStream =
         this.taskTemplatesApiService.getStreamedListGroupedByLocationID({
            sort: "checklistName",
            filters: {
               locationIDs,
               excludeDeletedAssets: true,
               groupBy: "locationID",
               ...queryParams,
            },
         });
      for await (const groupedResponse of groupedResponsesStream) {
         groupsOfGroupedTaskResponses.push(groupedResponse);
      }

      const groupedResponses = groupsOfGroupedTaskResponses.flat();

      const filteredTreeData =
         this.filterTreeDataToLocationsWithResponses<TaskTemplateEntity>(
            groupedResponses,
            treeData,
         );

      let noSearchResults = false;
      if (groupedResponses.every((response) => response?.data.length === 0)) {
         noSearchResults = true;
      }
      const newTemplateNodes = this.buildTemplateNodesFromTreeData(filteredTreeData);
      return {
         filteredTreeData,
         groupedResponses,
         noSearchResults,
         templateNodes: newTemplateNodes,
      };
   }

   public processAndMapGroupedResponse(
      groupedResponses: Array<GroupedListResponse<TaskTemplateEntity> | undefined>,
      locationsIndex: Record<number, HierarchyNode>,
      templateNodes: LimbleMap<number, TemplateHierarchyNode>,
      search: string,
   ) {
      for (const response of groupedResponses) {
         if (response === undefined) return;
         const locationNode = locationsIndex[response.groupKey];
         if (locationNode === undefined) return;
         locationNode.nodes = response.data.map((template) => {
            const icon = this.determineIcon(template.checklistTemplate);
            const templateNode = this.mapTaskToTaskNode(template, search, icon);
            if (templateNodes.get(template.checklistID) === undefined) {
               templateNodes.set(template.checklistID, templateNode);
            }
            return templateNode;
         });
         locationNode.pagination.total = response.total;
         locationNode.pagination.page = 1;
         locationNode.collapsed = false;
         locationNode.includeShowMore =
            locationNode.pagination?.total > locationNode.nodes.length;
      }
   }

   fetchMoreTasksAtLocation = async (
      location: HierarchyNode,
      templateNodes: LimbleMap<number, TemplateHierarchyNode>,
      queryParams: TaskTemplateEntityFilters,
   ) => {
      if (location.pagination === undefined || location.locationID === undefined) {
         throw new Error("Location node is missing pagination or locationID");
      }
      if (queryParams.checklistTemplates === undefined) {
         throw new Error(
            "queryParams.checklistTemplate must be defined in order to fetch more templates",
         );
      }

      // context -> CASE-3952 - on initial search results, for each individual nodes we usually receive few items at a time instead of getting results by limit.
      // for example, instead of getting HIERARCHY_LIMIT number of items per each location, we may get 2 or 3 items on initial result.
      // so when we want to do show more on specific location, we need to make sure we fetch the first HIERARCHY_LIMIT # of items starting from 0th index, instead of
      // blindly increasing the page and fetching the next HIERARCHY_LIMIT # of items starting from HIERARCHY_LIMIT-th index.
      // example scenario -> 3 out of 49 items on initial load for the location (with HIERARCHY_LIMIT = 100). when we click "show more" we should be fetching 100 items from first page (0th index),
      // instead of fetching 100 items from 2nd page (100th index).
      const isInitialFetchMoreWithSearch = Boolean(
         queryParams.search &&
            location.pagination.page < 2 &&
            location.nodes &&
            location.nodes.length < HIERARCHY_LIMIT,
      );
      if (!isInitialFetchMoreWithSearch) {
         location.pagination.page++;
      }

      const taskResponse = await firstValueFrom(
         this.taskTemplatesApiService.getList({
            sort: "checklistName",
            pagination: {
               page: isInitialFetchMoreWithSearch ? 1 : location.pagination.page,
               limit: HIERARCHY_LIMIT,
            },
            filters: {
               locationIDs: [location.locationID],
               excludeDeletedAssets: true,
               ...queryParams,
            },
         }),
      );

      if (taskResponse.data === undefined || taskResponse.total === undefined) {
         throw new Error("An error occurred retrieving tasks from the server");
      }

      location.pagination.total = taskResponse.total;

      if (taskResponse.data.length === 0) {
         location.nodes = [];
         location.collapsed = true;
         return;
      }

      const newTaskNodes = taskResponse.data.map((template) => {
         const icon = this.determineIcon(template.checklistTemplate);
         const templateNode = this.mapTaskToTaskNode(template, queryParams.search, icon);
         templateNodes.set(template.checklistID, templateNode);
         return templateNode;
      });

      // on isInitialFetchMoreWithSearch there is an overlap on previously existing location.nodes, so we're just replacing instead of appending.
      location.nodes = isInitialFetchMoreWithSearch
         ? newTaskNodes
         : [...location.nodes, ...newTaskNodes];
      location.includeShowMore = location.pagination.total > location.nodes.length;
   };

   public mapTaskToTaskNode(
      template: TaskTemplateEntity,
      search: string | undefined,
      icon: Aliases,
   ): TemplateHierarchyNode {
      const searchHint = this.searchHintService.getSearchHint(template, search, {
         joinAllMatches: true,
      });
      const node = {
         checklistID: template.checklistID,
         nodeID: template.checklistID,
         icon,
         collapsed: true,
         selected: false,
         nodes: [],
         title: template.assetID
            ? `${template.checklistName} - ${this.manageAsset.getAssetNameIncludeParents(
                 template.assetID,
              )}`
            : (template.checklistName ?? ""),
         name: template.checklistName ?? "",
         displayButtons: true,
         locationID: template.locationID,
         searchHint,
         woTemplateLocked: false,
      };

      return node;
   }

   public determineIcon(templateType: TaskTemplateType): Aliases {
      switch (templateType) {
         case TaskTemplateType.pmTemplate:
            return "wrench";
         case TaskTemplateType.woTemplate:
            return "wpforms";
         case TaskTemplateType.unPlannedWoTemplate:
            return "file";
         case TaskTemplateType.instructionSetTemplate:
            return "indent";
         default:
            return "wrench";
      }
   }

   public async rebuildTreeData(
      oldTreeData: Array<HierarchyNode>,
      templateNodes: LimbleMap<number, TemplateHierarchyNode>,
      queryParams: TaskTemplateEntityFilters,
      locationIDs?: Array<number>,
   ) {
      const { locationsIndex, filteredTreeData } =
         this.getLocationNodesWithoutRegions(locationIDs);

      const locationPagination: Array<{
         locationID: number;
         page: number;
      }> = oldTreeData.map((node: HierarchyNode) => {
         return {
            page: node?.pagination?.page ?? 0,
            locationID: node.locationID as number,
         };
      });

      const treeData = await this.fetchInitialData(filteredTreeData, queryParams);
      let noSearchResults = false;
      if (queryParams.search?.length) {
         const { groupedResponses, noSearchResults: updatedNoSearchResults } =
            await this.fetchSearchedData(treeData, queryParams);
         noSearchResults = updatedNoSearchResults;
         this.processAndMapGroupedResponse(
            groupedResponses,
            locationsIndex,
            templateNodes,
            queryParams.search,
         );
      }

      const pagesToFetch = new LimbleMap<number, Array<number>>();
      for (const pagination of locationPagination) {
         if (pagination.page === 1 && queryParams.search?.length) {
            continue;
         }
         for (let pageToFetch = pagination.page - 1; pageToFetch >= 0; pageToFetch--) {
            const pageCollection = pagesToFetch.get(pageToFetch);
            if (pageCollection === undefined) {
               pagesToFetch.set(pageToFetch, [pagination.locationID]);
               continue;
            }
            pageCollection.push(pagination.locationID);
         }
      }

      const pageQueries = Array.from(pagesToFetch.entries())
         .sort((first, second) => {
            return DEFAULT(first[0], second[0]);
         })
         .map(([page, locationIDsByPageToFetch]) => {
            return locationIDsByPageToFetch.map(async (locationID) => {
               const location = locationsIndex[locationID];
               location.collapsed = false;
               location.pagination = { page, total: location.pagination?.total ?? 0 };
               return this.fetchMoreTasksAtLocation(location, templateNodes, queryParams);
            });
         });
      for await (const pageQuery of pageQueries) {
         await Promise.all(pageQuery);
      }

      const newTemplateNodes = this.buildTemplateNodesFromTreeData(treeData);

      return {
         locationsIndex,
         treeData,
         noSearchResults,
         templateNodes: newTemplateNodes,
      };
   }

   private buildTemplateNodesFromTreeData(
      treeData: Array<HierarchyNode | TemplateHierarchyNode>,
   ): LimbleMap<number, TemplateHierarchyNode> {
      const templateNodes = new LimbleMap<number, TemplateHierarchyNode>();
      for (const location of treeData) {
         if (!location.nodes?.length) {
            continue;
         }
         for (const template of location.nodes as Array<TemplateHierarchyNode>) {
            if (template.checklistID === undefined) {
               continue;
            }
            templateNodes.set(template.checklistID, template);
         }
      }
      return templateNodes;
   }
}
