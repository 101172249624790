import { inject, Injectable } from "@angular/core";
import type { EventDropArg } from "@fullcalendar/core";
import type { Dictionary } from "@fullcalendar/core/internal";
import type { EventResizeDoneArg } from "@fullcalendar/interaction";
import { Temporal } from "@js-temporal/polyfill";
import { ModalService } from "@limblecmms/lim-ui";
import moment from "moment";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { PopTaskSchedule } from "src/app/schedules/popTaskScheduleModal/popTaskSchedule.modal.component";
import type { Schedule } from "src/app/schedules/schedule.types";
import { TemporalUtilsService } from "src/app/schedules/temporal-utils/temporal-utils.service";
import { PickCustomList } from "src/app/shared/components/global/pickCustomListModal/pickCustomList.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import type { ScheduleEntity } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { StartWOService } from "src/app/tasks/services/start-wo.service";
import type { Task } from "src/app/tasks/types/task.types";
import type { CalendarNote } from "src/app/tasks-analytics/calendar/calendar-notes/calendar-note";
import { CalendarNotesStoreService } from "src/app/tasks-analytics/calendar/calendar-notes/calendar-notes-store.service";
import { EditCalendarNote } from "src/app/tasks-analytics/calendar/editCalendarNoteModal/editCalendarNote.modal.component";
import type {
   CalendarEvent,
   CalendarSettings,
} from "src/app/tasks-analytics/calendar/task-calendar/calendar.model";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({ providedIn: "root" })
export class CalendarFacadeService {
   private readonly manageLang = inject(ManageLang);
   private readonly manageTask = inject(ManageTask);
   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly betterDate = inject(BetterDate);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly managePriority = inject(ManagePriority);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly startWOService = inject(StartWOService);
   private readonly lang = this.manageLang.lang;
   private readonly locationLookup = this.manageTask.getCalendarLocationLookup();
   private assignmentLookup = this.manageTask.getFlatAssignmentLookup();
   private readonly temporalUtils = inject(TemporalUtilsService);
   private readonly calendarNotesStore = inject(CalendarNotesStoreService);

   public isCalendarNoteEditable(note: CalendarNote): boolean {
      if (note.owner === this.manageUser.getCurrentUser().gUserID) {
         //if I made it I can edit it...
         return true;
      }
      if (
         this.credService.checkCredGlobal(this.credService.Permissions.ManageLocations)
      ) {
         //I am super user so I can see all
         return true;
      }
      if (this.credService.isAuthorized(note.locationID, 55)) {
         //I can change PM schedules so let me see all at this location
         return true;
      }
      return false;
   }

   public mapScheduleToCalendarEvent(
      schedule: ScheduleEntity,
      calendarSettings: CalendarSettings,
   ): CalendarEvent {
      const date = this.getCalendarScheduleDate(schedule);
      const formattedScheduleStartDate = date.toPlainDate().toString();

      const title =
         this.buildCalendarScheduleTitle(schedule, calendarSettings, date) ?? "";

      const priorityListLength = this.managePriority.getPriorityList().length;

      const { color, textColorClass } = this.getCalendarScheduleColors(
         schedule,
         calendarSettings,
      );

      return {
         title,
         start: formattedScheduleStartDate,
         end: formattedScheduleStartDate,
         color,
         classNames: [textColorClass],
         display: "block",
         checklistID: schedule.checklistID,
         scheduleID: schedule.scheduleID,
         schedule: true,
         editable: this.canEditSchedule(schedule),
         priority: priorityListLength + 1, //JIT TODO: tying into the comment at the top about not including the priorityList
         //as a parameter in these functions
         durationEditable: false, //we don't want them to be able to change how long a schedule will be,
         userID: schedule.userID,
         profileID: schedule.profileID,
      };
   }

   private getCalendarScheduleDate(
      schedule: ScheduleEntity | Schedule,
   ): Temporal.ZonedDateTime {
      if (schedule.scheduleStartDate === null) {
         throw new Error(
            "scheduleStartDate is null, cannot compute schedule date for calendar",
         );
      }
      const timezone =
         this.manageLocation.getTimeZoneForLocation(schedule.locationID) ??
         Temporal.Now.timeZoneId();
      const startOfScheduleWithTZ = Temporal.Instant.fromEpochSeconds(
         schedule.scheduleStartDate,
      ).toZonedDateTimeISO(timezone);
      const startOfScheduleDay = startOfScheduleWithTZ.startOfDay();
      if (schedule.scheduleTimeOfDay === "" || schedule.scheduleTimeOfDay === null) {
         return startOfScheduleDay.add({ hours: 2, minutes: 30 });
      }
      const hours = Number(schedule.scheduleTimeOfDay.slice(0, 2));
      const minutes = Number(schedule.scheduleTimeOfDay.slice(2));
      return startOfScheduleDay.add({ hours, minutes });
   }

   private buildCalendarScheduleTitle(
      schedule: ScheduleEntity,
      calendarSettings: CalendarSettings,
      scheduleStartDate: Temporal.ZonedDateTime,
   ) {
      let title = "";
      if (schedule.scheduleTimeOfDay !== "" && schedule.scheduleTimeOfDay !== null) {
         //we need to show time of day
         title += `${this.betterDate.formatBetterDate(scheduleStartDate.toInstant().epochMilliseconds, "time")} - `;
      }

      title += schedule.checklistName ?? "";
      if (calendarSettings.showAsset && schedule.relatedTemplate?.checklistID) {
         title +=
            this.manageTask.buildAssetNameStringForTaskLegacy(
               schedule.relatedTemplate.checklistID,
               calendarSettings.showAssetParents,
            ) ?? "";
      }
      if (calendarSettings.showAssign) {
         const assignmentInfo = this.manageTask.getTaskAssignmentInfo(schedule);
         title += ` - ${assignmentInfo.displayName}`;
      }
      if (
         calendarSettings.showEstTime &&
         schedule.relatedTemplate?.checklistEstTime !== null &&
         schedule.relatedTemplate?.checklistEstTime > 0
      ) {
         title += ` - ${(schedule.relatedTemplate.checklistEstTime / 60 / 60).toFixed(2)} ${
            this.lang()?.hrs
         }`;
      }

      if (
         schedule.locationID &&
         calendarSettings.showLoc &&
         this.locationLookup[schedule.locationID]
      ) {
         let locName = "";
         const loc = this.locationLookup[schedule.locationID];
         locName = ` - ${loc.name}`;
         title = title + locName;
      }

      return title;
   }

   private getCalendarScheduleColors(
      schedule: ScheduleEntity | Schedule,
      calendarSettings: CalendarSettings,
   ) {
      let color = "#40ade6";
      let textColorClass = "fc-white-text";

      if (calendarSettings.colorCode === "location") {
         if (schedule.locationID && this.locationLookup?.[schedule.locationID]) {
            color = this.locationLookup[schedule.locationID].color;
         }
      }

      if (calendarSettings.colorCode === "assignment") {
         this.assignmentLookup = this.manageTask.getFlatAssignmentLookup();
         if (schedule.userID && this.assignmentLookup[`u${schedule.userID}`]) {
            color = this.assignmentLookup[`u${schedule.userID}`].color;
            textColorClass = this.assignmentLookup[`u${schedule.userID}`]?.textColorClass;
         } else if (
            schedule.profileID &&
            this.assignmentLookup[`p${schedule.profileID}`]
         ) {
            color = this.assignmentLookup[`p${schedule.profileID}`].color;
            textColorClass =
               this.assignmentLookup[`p${schedule.profileID}`]?.textColorClass;
         } else {
            color = this.assignmentLookup[0].color;
            textColorClass = this.assignmentLookup[0]?.textColorClass;
         }
      }

      if (!textColorClass) {
         textColorClass = this.determineTextContrastClass(color);
      }
      return { color, textColorClass };
   }

   private canEditSchedule(schedule: ScheduleEntity | Schedule): boolean {
      if (!schedule.locationID) {
         return false;
      }
      return this.credService.isAuthorized(
         schedule.locationID,
         this.credService.Permissions.ChangeDueDatesOfOpenTasks,
      );
   }

   public mapCalendarNoteToCalendarEvent(note: CalendarNote): CalendarEvent | undefined {
      const priorityList = this.managePriority.getPriorityList();
      const editable = this.isCalendarNoteEditable(note);
      const calendarNoteClassName = this.getCalendarNoteColor(note);
      const timezone =
         this.manageLocation.getTimeZoneForLocation(note.locationID) ??
         Temporal.Now.timeZoneId();
      return {
         title: note.getTitle() ?? "",
         start: note.getStartTime().toZonedDateTimeISO(timezone).toPlainDate().toString(),
         end: note
            .getEndTime()
            .toZonedDateTimeISO(timezone)
            // full calendar does not show the event on the end date, so we
            // need to add a day to make it work as expected
            .add({ days: 1 })
            .toPlainDate()
            .toString(),
         color: `#${note.getColor()}`,
         classNames: [calendarNoteClassName],
         display: "block",
         calendarNoteID: note.id,
         editable: editable,
         priority: priorityList.length + 2,
         durationEditable: false,
      };
   }

   private getCalendarNoteColor(note: CalendarNote) {
      // See calendarNote.jsonc; color "F29423" appears to be an exception
      if (note.getColor() === "F29423") {
         return "fa-black-text";
      }
      return this.determineTextContrastClass(note.getColor());
   }

   public mapTaskToCalendarEvent(
      task: TaskEntity | Task,
      calendarSettings: CalendarSettings,
   ): CalendarEvent {
      const priorityList = this.managePriority.getPriorityList();
      const taskPriorityIndex = priorityList.findIndex(
         (item) => Number(item.priorityID) === Number(task.priorityID),
      );
      const taskPriority = priorityList[taskPriorityIndex];

      const { color, textColorClass } = this.getCalendarTaskColors(
         task,
         calendarSettings,
         taskPriority,
      );

      const { startDate, endDate } = this.getTaskDate(task);

      return {
         title: this.titleForTask(task, calendarSettings),
         start: startDate,
         end: endDate,
         color: color,
         classNames: [textColorClass],
         display: "block",
         checklistID: task.checklistID,
         open: true,
         editable: this.isTaskEventEditable(task),
         priority: taskPriority?.priorityLevel ?? 0,
         durationEditable: !task.checklistCompletedDate,
         userID: task.userID ?? null,
         profileID: task.profileID ?? null,
      };
   }

   public getTaskDate(task: TaskEntity | Task): { startDate: string; endDate: string } {
      if (!task.checklistDueDate) {
         console.error(
            "Task due date is null, undefined, or 0; cannot compute task date for calendar",
         );
         return { startDate: "", endDate: "" };
      }
      const oneDayInSeconds = 86400;
      const endTimestamp = task.checklistCompletedDate
         ? task.checklistCompletedDate
         : task.checklistDueDate;
      const start = task.checklistStartDate ? task.checklistStartDate : endTimestamp;
      const end = task.checklistStartDate ? endTimestamp + oneDayInSeconds : endTimestamp;
      const timezone =
         this.manageLocation.getTimeZoneForLocation(task.locationID) ??
         Temporal.Now.timeZoneId();
      return {
         startDate: this.temporalUtils.timestampToIsoDateString(start, timezone),
         endDate: this.temporalUtils.timestampToIsoDateString(end, timezone),
      };
   }

   private getCalendarTaskColors(
      task: TaskEntity | Task,
      calendarSettings: CalendarSettings,
      taskPriority: any,
   ) {
      let color = "#429B1F"; //default color to green
      let textColorClass = "";

      if (calendarSettings.colorCode === "assignment") {
         this.assignmentLookup = this.manageTask.getFlatAssignmentLookup();
         if (task.userID && this.assignmentLookup[`u${task.userID}`]) {
            color = this.assignmentLookup[`u${task.userID}`].color;
            textColorClass = this.assignmentLookup[`u${task.userID}`]?.textColorClass;
         } else if (task.profileID && this.assignmentLookup[`p${task.profileID}`]) {
            color = this.assignmentLookup[`p${task.profileID}`].color;
            textColorClass = this.assignmentLookup[`p${task.profileID}`]?.textColorClass;
         } else {
            color = this.assignmentLookup[0].color;
            textColorClass = this.assignmentLookup[0]?.textColorClass;
         }
      } else if (calendarSettings.colorCode === "location") {
         if (task.locationID > 0 && this.locationLookup?.[task.locationID]) {
            color = this.locationLookup[task.locationID].color;
         }
      } else if (taskPriority) {
         //if the colorCode is set to priority, the color should reflect the task's assigned custom priority
         color = taskPriority.color;
         textColorClass = this.determineTextContrastClass(color);
      } else {
         //if the priority is 'unset', set the color to yellow
         color = "#f1c40f";
      }
      if (!textColorClass) {
         textColorClass = this.determineTextContrastClass(color);
      }
      return { color, textColorClass };
   }

   private titleForTask(
      task: TaskEntity | Task,
      calendarSettings: CalendarSettings,
   ): string {
      let title = "";
      if (task.checklistDueDateSetting === 1 && task.checklistDueDate !== null) {
         const timezoneID = this.manageLocation.getLocation(task.locationID)?.timezoneID;
         if (timezoneID !== undefined && timezoneID !== null) {
            //we need to show time of day
            title += this.betterDate.formatBetterDate(
               (task.checklistDueDate ?? 0) * 1000,
               "time",
               this.manageLocation.getTimezonesIndex()?.[timezoneID]?.timezonePHPName,
            );
         }
         title += ` - `;
      }

      title += task.checklistName;

      if (task.assetID && calendarSettings.showAsset) {
         const taskAsset = this.manageAsset.getAsset(task.assetID);
         if (taskAsset && (task as any).assets) {
            title += this.manageTask.buildAssetNameStringForTask(
               task as TaskEntity, //JIT TODO: remove these coercions once we arent supporting multiple
               //types of task
               calendarSettings.showAssetParents,
            );
         } else {
            //JIT TODO: remove these coercions once we arent supporting multiple types of task
            title += this.manageTask.buildAssetNameStringForTaskLegacy(
               task.checklistID,
               calendarSettings.showAssetParents,
            );
         }
      }

      if (calendarSettings.showAssign) {
         const assignmentInfo = this.manageTask.getTaskAssignmentInfo(task);
         title += ` - ${assignmentInfo.displayName}`;
      }

      if (calendarSettings.showLoc && task.locationID > 0) {
         const locationName = this.manageLocation.getLocation(
            task.locationID,
         )?.locationName;
         title += ` • ${locationName ?? ""}`;
      }

      if (task.checklistEstTime && calendarSettings.showEstTime) {
         title += ` - ${(task.checklistEstTime / 60 / 60).toFixed(2)} ${
            this.lang()?.hrs
         }`;
      }

      return title;
   }

   private isTaskEventEditable(task: TaskEntity | Task): boolean {
      return this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.ChangeDueDatesOfOpenTasks,
      );
   }

   public async handleDropEvent(eventDrop: EventDropArg): Promise<void> {
      if (this.lang === undefined) {
         console.error("Language not loaded");
         return;
      }
      const event = eventDrop.event._def.extendedProps;
      const delta = eventDrop.delta;
      //if the checklist is currently an open task
      if (event.open) {
         //change the database

         const answer = await this.manageTask.updateTaskDueDateByDays(
            event.checklistID,
            delta.days,
            true,
         );

         if (answer.data.success === true) {
            this.alertService.addAlert(
               this.lang()?.DueDateUpdated ?? "",
               "success",
               1000,
            );
         } else {
            this.alertService.addAlert(this.lang()?.errorMsg ?? "", "danger", 6000);
         }

         //if a scheduleID is set that means we need to update the due date of the schedule.
      } else if (event.scheduleID > 0) {
         //change the database
         const answer = await this.manageSchedule.updateScheduleStartDateByDays(
            event.scheduleID,
            delta.days,
         );
         if (answer.data.success === true) {
            this.alertService.addAlert(
               this.lang()?.StartDateUpdated ?? "",
               "success",
               1000,
            );
         } else {
            this.alertService.addAlert(this.lang()?.errorMsg ?? "", "danger", 6000);
         }
      } else if (event.calendarNoteID > 0) {
         const note = await this.calendarNotesStore.get(event.calendarNoteID);
         if (note === undefined) {
            throw new Error("Could not find calendar note");
         }
         const timeZone =
            this.manageLocation.getTimeZoneForLocation(note.locationID) ??
            Temporal.Now.timeZoneId();
         note.move(delta.days, timeZone);
         try {
            this.calendarNotesStore.save(note);
         } catch {
            this.alertService.addAlert(this.lang()?.errorMsg ?? "", "danger", 6000);
            return;
         }
         this.alertService.addAlert(this.lang()?.successMsg ?? "", "success", 1000);
      }
   }

   public async handleResizeEvent(resizeEvent: EventResizeDoneArg): Promise<void> {
      const lang = this.lang();
      if (lang === undefined) {
         console.error("Language not loaded");
         return;
      }

      const dayDelta = resizeEvent.endDelta;
      const event = resizeEvent.event._def.extendedProps;
      if (event.calendarNoteID > 0) {
         const note = await this.calendarNotesStore.get(event.calendarNoteID);
         if (note === undefined) {
            throw new Error("Could not find calendar note");
         }
         const timeZone =
            this.manageLocation.getTimeZoneForLocation(note.locationID) ??
            Temporal.Now.timeZoneId();
         note.resize(dayDelta.days, timeZone);
         try {
            this.calendarNotesStore.save(note);
         } catch {
            this.alertService.addAlert(this.lang()?.errorMsg ?? "", "danger", 6000);
            return;
         }
         this.alertService.addAlert(this.lang()?.successMsg ?? "", "success", 1000);
      } else if (event.checklistID > 0) {
         const answer = await this.manageTask.resizeTaskStartOn(
            event.checklistID,
            dayDelta.days,
         );
         if (answer) {
            this.alertService.addAlert(lang.successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(lang.errorMsg, "danger", 6000);
         }
      }
   }

   public async openTaskModal(checklistID: number): Promise<void> {
      const completedTask = this.manageTask.getCompletedTask(
         checklistID,
         "CalendarFacadeService",
      );
      const editable = completedTask === undefined;

      const instance = this.modalService.open(PopTask);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: editable,
            },
         },
      };
      await instance.result;
   }

   public async openFutureTaskModal(
      checklistID: number,
      scheduleID: number,
   ): Promise<void> {
      const instance = this.modalService.open(PopTaskSchedule);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               scheduleID: scheduleID,
            },
         },
      };
      await instance.result;
   }

   public async openEditNoteModal(event: Dictionary): Promise<CalendarNote | undefined> {
      const lang = this.lang();
      if (lang === undefined) {
         console.error("Language not loaded");
         return undefined;
      }
      if (event.editable == false) {
         this.alertService.addAlert(
            lang.WhoopsYouCantEditThisCalendarNote,
            "danger",
            10000,
         );
         return undefined;
      }

      const calendarNote = await this.calendarNotesStore.get(event.calendarNoteID);
      if (!calendarNote) return undefined;

      const instance = this.modalService.open(EditCalendarNote);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: lang.EditCalendarNote,
               message: "",
               calendarNoteID: event.calendarNoteID,
               text: calendarNote.getTitle(),
               locationID: calendarNote.locationID,
               noteDate: calendarNote.getStartTime().epochSeconds,
               noteDateEnd: calendarNote.getEndTime().epochSeconds,
            },
         },
      };

      const result = await instance.result;
      if (result === 0) {
         return undefined;
      }
      if (result === 1) {
         try {
            this.calendarNotesStore.delete(calendarNote.id);
         } catch {
            this.alertService.addAlert(lang.errorMsg, "danger", 6000);
            return undefined;
         }
         this.alertService.addAlert(lang.successMsg, "success", 1000);
         return undefined;
      }
      calendarNote.setTitle(result.text);
      calendarNote.setStartTime(Temporal.Instant.fromEpochSeconds(result.noteDate));
      calendarNote.setEndTime(Temporal.Instant.fromEpochSeconds(result.noteDateEnd));
      calendarNote.setUsers(result.users);
      calendarNote.setColor(result.color);
      try {
         await this.calendarNotesStore.save(calendarNote);
      } catch {
         this.alertService.addAlert(lang.errorMsg, "danger", 6000);
         return undefined;
      }
      //JIT TODO: see about removing the below watch var calls
      //have to call increments in case we have multiple calendars on a dashboard
      this.manageTask.incTasksWatchVar(); //have to call these increment variables so the dashboard refreshes
      this.manageTask.incCompletedTasksWatchVar(); //have to call these increment variables so the dashboard refreshes
      this.alertService.addAlert(lang.successMsg, "success", 1000);
      return calendarNote;
   }

   public async handleDateSelectEvent(): Promise<0 | 1 | 2> {
      if (this.lang === undefined) {
         console.error("Language not loaded");
         return 0;
      }

      const list = [
         {
            name: this.lang()?.StartWO,
            value: 1,
            icon: "fa-brands fa-plus",
         },
         {
            name: this.lang()?.AddNote,
            value: 2,
            icon: "fa-regular fa-note-sticky",
         },
      ];

      const instance = this.modalService.open(PickCustomList);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               list: list,
               message: "",
               title: this.lang()?.WhatWouldYouLikeToAddToTheCalendar,
            },
         },
      };

      return instance.result;
   }

   public startWorkOrder(date: Date): void {
      this.startWOService.startWorkOrder(date);
   }

   public async addCalendarNote(
      startingDate: Date,
      locationID: number,
   ): Promise<CalendarNote | undefined> {
      if (this.lang === undefined) {
         console.error("Language not loaded");
         return undefined;
      }
      const date2 = moment(startingDate).format("X");
      const editCalendarNoteInstance = this.modalService.open(EditCalendarNote);

      this.paramsService.params = {
         modalInstance: editCalendarNoteInstance,
         resolve: {
            data: {
               title: this.lang()?.AddNote,
               message: "",
               calendarNoteID: 0,
               text: "",
               locationID: locationID,
               noteDate: date2,
               noteDateEnd: 0,
            },
         },
      };

      const calendarNoteResult = await editCalendarNoteInstance.result;
      if (calendarNoteResult && calendarNoteResult != 0) {
         const text = calendarNoteResult.text;
         const users = calendarNoteResult.users;
         const noteDate = calendarNoteResult.noteDate;
         const noteDateEnd = calendarNoteResult.noteDateEnd;
         let newNote: CalendarNote;
         try {
            newNote = await this.calendarNotesStore.create({
               text,
               noteDate,
               noteDateEnd,
               locationID,
               users,
               color: calendarNoteResult.color,
            });
         } catch {
            this.alertService.addAlert(this.lang()?.errorMsg ?? "", "danger", 6000);
            return undefined;
         }
         //have to call increments because multiple calendars will need to be updated
         //JIT TODO: see about removing these
         this.manageTask.incTasksWatchVar(); //have to call these increment variables so the dashboard refreshes
         this.manageTask.incCompletedTasksWatchVar(); //have to call these increment variables so the dashboard refreshes

         this.alertService.addAlert(this.lang()?.successMsg ?? "", "success", 1000);
         return newNote;
      }
      return undefined;
   }

   public getAssignmentLegend(
      events: Array<CalendarEvent>,
   ): Array<{ color: string; name: string }> {
      const flatAssignmentsLookup = this.manageTask.setShowFlatAssignmentsLookup(
         this.assignmentLookup,
         events,
      );
      return Object.values(flatAssignmentsLookup).map((assignment) => ({
         color: assignment.color,
         name: assignment.name,
      }));
   }

   public getPriorityLegend(): Array<{ color: string; name: string }> {
      return this.managePriority
         .getPriorityList()
         .map((priority) => ({
            color: priority.color,
            name: `(${priority.priorityLevel}) ${priority.name}`,
         }))
         .concat([
            {
               color: "#5083d5",
               name: this.lang()?.Upcoming,
            },
            {
               color: "#f1c40f",
               name: this.lang()?.PriorityUnset,
            },
         ]);
   }

   public getUpdatedCalendarSettingsFromUser(
      oldCalendarSettings: CalendarSettings,
      user?,
   ): CalendarSettings {
      const startOfWorkWeek = user?.userInfo?.customerStartOfWorkWeek || 0;

      const savedCalendarSettings = user?.userInfo?.userUIPreferences?.calendarUI ?? {};

      return {
         ...oldCalendarSettings,
         ...savedCalendarSettings,
         startOfWorkWeek,
      };
   }

   private determineTextContrastClass(hexColor: string | null): string {
      if (!hexColor) {
         return "fc-black-text";
      }
      /* eslint-disable no-bitwise -- for calculating contrast*/
      let color = hexColor;
      if (hexColor.startsWith("#")) {
         color = hexColor.substring(1); // strip #
      }
      const rgb = parseInt(color, 16); // convert rrggbb to decimal
      const red = (rgb >> 16) & 0xff; // extract red
      const green = (rgb >> 8) & 0xff; // extract green
      const blue = (rgb >> 0) & 0xff; // extract blue
      /* eslint-enable no-bitwise */

      const luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue; // per ITU-R BT.709 luman equation

      //0 is black, 100 i white scale
      const lumaPercentageScale = (luma / 255) * 100;
      const lumaWhiteTextThreshold = 70;
      //too dark of color! Apply white text.
      if (lumaPercentageScale < lumaWhiteTextThreshold) {
         return "fc-white-text";
      }

      return "fc-black-text";
   }
}
