import { inject, Injectable } from "@angular/core";
import { taskCommentInitializer } from "src/app/lite/local-db/resources/collection/task/comment/comment.initializer";
import { taskCommentSchema } from "src/app/lite/local-db/resources/collection/task/comment/comment.schema";
import { CommentStorageService } from "src/app/lite/local-db/resources/collection/task/comment/comment.storage.service";
import { refetchValidData } from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { z } from "zod";

@Injectable({
   providedIn: "root",
})
export class CommentStorageSyncService extends StorageSyncService {
   private readonly commentStorageService = inject(CommentStorageService);

   public constructor() {
      super();
   }

   public async syncComment(commentID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = taskCommentInitializer;
         const comments = await refetchValidData({
            url,
            params: { commentIDs: [commentID] },
            validation: z.array(taskCommentSchema),
         });
         const [comment] = comments;
         assert(
            comment !== undefined,
            `Comment #${commentID} not found in fetch when attempting to sync`,
         );

         await this.commentStorageService.putComment(comment);
      });
   }

   public async syncCommentsByTask(taskID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = taskCommentInitializer;
         const comments = await refetchValidData({
            url,
            params: { taskIDs: [taskID] },
            validation: z.array(taskCommentSchema),
         });
         if (comments.length > 0) {
            await Promise.all(
               comments.map(async (comment) =>
                  this.commentStorageService.putComment(comment),
               ),
            );
         }
      });
   }

   public async syncCommentDeletion(commentID: number): Promise<void> {
      return this.sync(async () => {
         await this.commentStorageService.deleteComment(commentID);
      });
   }
}
