<div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
   <span class="lim-ui-mobile-table-column-label">{{ lang().Labor }}:</span>
   <span class="column">
      @if (task && task.laborCost) {
         @if (task.laborCost > 0) {
            <div class="right-margin checklist-name-section-item">
               <span> {{ task.laborCost | localeCurrency: task.currencyCode }}</span>
            </div>

            <div class="checklist-name-section-item">
               ({{ (task.timeSpentSecs ?? 0) / 60 / 60 | betterNumber: "1.2-2" }}
               @if (task.timeSpentSecs === 3600) {
                  <span>{{ lang().hr }})</span>
               }
               @if (task.timeSpentSecs !== 3600) {
                  <span>{{ lang().hrs }})</span>
               }
            </div>
         } @else if (task.laborCost === 0) {
            <span>-</span>
         }
      } @else {
         <span>-</span>
      }
   </span>
</div>
