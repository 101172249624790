@if (topLevel && !showIcon) {
   &nbsp;-&nbsp;
}
@if (showIcon) {
   <lim-ui-icon icon="cube" iconColor="standard" class="asset-icon" />
}

<span
   (click)="onViewAsset()"
   class="{{ linkColorClass }} {{ linkColorClass }}{{ hover && '-hover' }} clickable"
   [limbleHtml]="hierarchicalAsset()?.assetName"
></span>

@let assetParent = hierarchicalAsset()?.parent;

@if (assetParent && !isOnlyParentVisible) {
   &nbsp;>&nbsp;<asset-hierarchy-list-item
      [asset]="assetParent"
      [linkColorClass]="linkColorClass"
      [hover]="hover"
      [preventParentAccess]="preventParentAccess"
      [topLevel]="false"
   />
}
