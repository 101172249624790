<lim-ui-panel
   [title]="task().checklistStatusID ? lang().PartsThatWereUsed : lang().PartsYouMayNeed"
   [collapsable]="true"
>
   @for (part of parts(); track part) {
      <task-parts-list-item
         [part]="part"
         [task]="task()"
         [taskFormState]="taskFormState()"
         (startPoForPart)="startPoForPart.emit($event)"
         (updatedPartSuggestedNumber)="updatedPartSuggestedNumber.emit($event)"
         (removePartFromTask)="removePartFromTask.emit($event)"
         (popPartModal)="popPartModal.emit($event)"
         (partsUpdated)="partsUpdated.emit($event)"
         (popPoComponent)="popPoComponent.emit($event)"
      />
   }
</lim-ui-panel>
