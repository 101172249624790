@if (fieldValue) {
   <div class="lim-ui-list-item">
      @if (
         creds.viewManageParts ||
         (!creds.viewManageParts && fieldValue.viewableByTech == 1)
      ) {
         <div class="lim-ui-list-item-content">
            @if (creds.configurePartFields && part?.partDeleted == 0) {
               <span class="tree-icon-action">
                  @if (treeBranch) {
                     <lim-ui-icon
                        icon="arrowsUpDownLeftRightRegular"
                        iconSize="small"
                        [limbleTreeDraggable]="treeBranch"
                     />
                  }
                  <span>{{ fieldValue.valueSort }}</span>
               </span>
            }

            <div class="info-item-label-wrapper">
               @if (part?.partDeleted == 0) {
                  <div class="icon-with-text">
                     @if (
                        fieldInfo?.fieldTypeID != 6 &&
                        valueExtraInfo &&
                        valueExtraInfo.fieldTypeIcon
                     ) {
                        <lim-ui-icon
                           [icon]="valueExtraInfo.fieldTypeIcon | iconAlias"
                           iconSize="small"
                           limUiTooltip="{{ valueExtraInfo?.fieldTypeHint }}"
                           placement="right"
                        />
                     }
                     @if (fieldInfo?.fieldTypeID == 6) {
                        <span
                           limUiTooltip="{{ valueExtraInfo?.fieldTypeHint }}"
                           placement="right"
                           >{{ currencySymbol }}</span
                        >
                     }
                     <span [limbleHtml]="fieldInfo?.fieldName" class="field-name"></span>
                     @if (fieldInfo?.lockedDefault) {
                        <lim-ui-icon
                           icon="lockRegular"
                           iconSize="small"
                           [limUiTooltip]="lang().FieldLockdedTooltip"
                           placement="right"
                           iconColor="medium-grey"
                        />
                     }
                  </div>
               }
               <lim-ui-dropdown class="lim-ui-show-on-mobile">
                  <lim-ui-minimal-icon-button
                     button
                     icon="ellipsisVertical"
                     iconSize="small"
                  />
                  <div class="mobile-item-options-dropdown" menu>
                     @if (creds.configurePartFields) {
                        <lim-ui-dropdown-item (click)="removeField()">
                           <lim-ui-icon
                              class="dropdown-option-icon"
                              icon="trashCanRegular"
                              iconSize="medium"
                              iconColor="danger"
                           />
                           {{ lang().RemoveThisField }}
                        </lim-ui-dropdown-item>
                     }
                     @if (creds.configurePartFields && !restrict) {
                        <lim-ui-dropdown-item
                           (click)="updateFieldViewableByTech(fieldValue)"
                        >
                           <lim-ui-icon
                              class="dropdown-option-icon"
                              [icon]="
                                 fieldValue.viewableByTech == 1 ? eyeIcon : eyeSlashIcon
                              "
                              iconSize="medium"
                           />
                           {{ lang().ShowHideField }}
                        </lim-ui-dropdown-item>
                     }
                  </div>
               </lim-ui-dropdown>
            </div>
            <div class="full-width-input-wrapper-with-buttons">
               <div class="item-information">
                  <div
                     class="item-information-container"
                     limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
                  >
                     <field-item
                        [valueID]="fieldValue.valueID"
                        [restrict]="restrict"
                        usedIn="listItem"
                     />
                  </div>
               </div>
               @if (part?.partDeleted == 0 && !restrict) {
                  <div class="item-action lim-ui-hide-on-mobile">
                     <div class="lim-ui-list-item-hover-buttons">
                        @if (creds.configurePartFields && !restrict) {
                           <lim-ui-minimal-icon-button
                              [icon]="
                                 fieldValue.viewableByTech == 1 ? eyeIcon : eyeSlashIcon
                              "
                              iconSize="small"
                              [superMinimal]="true"
                              limUiTooltip="{{ lang().ChangeDefaultShowHidePartCard }}"
                              placement="top"
                              (click)="updateFieldViewableByTech(fieldValue)"
                           />
                        }
                        @if (creds.configurePartFields && !fieldLocked) {
                           <lim-ui-minimal-icon-button
                              icon="trashCanRegular"
                              iconSize="small"
                              iconColor="danger"
                              [superMinimal]="true"
                              limUiTooltip="{{ lang().RemoveThisField }}"
                              placement="top"
                              (click)="removeField()"
                           />
                        }
                     </div>
                  </div>
               }
            </div>
         </div>
      }
   </div>
}
