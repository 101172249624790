<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />

   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="map-panel-content">
            <div class="map-top-buttons-wrapper">
               <div class="map-buttons-start-group">
                  @if (canEdit && !geoLocation && !addGeo) {
                     <lim-ui-secondary-button
                        (click)="enableAddGeo()"
                        icon="plus"
                        dataLogLabel="task-startAddLocation"
                     >
                        <span class="mobileDisappear">{{ lang().Add }}</span>
                     </lim-ui-secondary-button>
                  }
                  @if (canEdit && geoLocation && !editGeo) {
                     <lim-ui-secondary-button (click)="enableEditGeo()" icon="pencil">
                        <span class="mobileDisappear">{{ lang().Edit }}</span>
                     </lim-ui-secondary-button>
                  }

                  @if (canEdit && geoLocation && !editGeo) {
                     <lim-ui-secondary-button
                        (click)="removeGeo()"
                        [disabled]="editGeo"
                        icon="trashCanRegular"
                        iconColor="danger"
                     >
                        <span class="mobileDisappear danger-color">{{
                           lang().Delete
                        }}</span>
                     </lim-ui-secondary-button>
                  }
                  @if (deviceGeoEnabled && (addGeo || editGeo)) {
                     <lim-ui-secondary-button
                        (click)="
                           setGeoFromDeviceLocation(); disableAddGeo(); disableEditGeo()
                        "
                        icon="crosshairs"
                     >
                        <span class="mobileDisappear">{{ lang().MyLocation }}</span>
                     </lim-ui-secondary-button>
                  }
                  @if (currentFeatureServiceID && !locationMappedToArcGis) {
                     <lim-ui-secondary-button
                        (click)="addArcGisAssetMap()"
                        icon="squarePlus"
                        [disabled]="addGeo"
                     >
                        <span class="mobileDisappear">{{ lang().LinkToArcGis }}</span>
                     </lim-ui-secondary-button>
                  }
               </div>
               <div>
                  @if (geoLocation && !editGeo) {
                     <lim-ui-primary-button
                        (click)="navigateToLocation(geoLocation)"
                        icon="locationArrow"
                     >
                        <span class="mobileDisappear">{{ lang().Navigate }}</span>
                     </lim-ui-primary-button>
                  }
               </div>
            </div>

            <gis-map
               #gisMap
               id="GisMap"
               class="map"
               [(addGeo)]="addGeo"
               [(editGeo)]="editGeo"
               (updateGeo)="updateGeo($event)"
               [asset]="asset"
               [task]="task"
               [location]="location"
               [(geoLocation)]="geoLocation"
               [initialBoundaries]="initialBoundaries"
               [limitedMode]="limitedMode"
               [canEdit]="canEdit"
               [showMyLocation]="showMyLocation"
            />
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   @if (addGeo || editGeo) {
      <lim-ui-modal-footer>
         @if (editGeo) {
            <lim-ui-primary-button (click)="save()">{{
               lang().Save
            }}</lim-ui-primary-button>
         }
         @if (addGeo || editGeo) {
            <lim-ui-secondary-button (click)="cancel()">{{
               lang().Cancel
            }}</lim-ui-secondary-button>
         }
      </lim-ui-modal-footer>
   }
</lim-ui-modal>
