import type { CountedPurchasable } from "src/app/parts/purchasable/counted-purchasable.model";
import type { StockUnitCalculator } from "src/app/parts/purchasable/services/stock-unit-calculator/stock-unit-calculator.interface";

export class CountedStockUnitCalculator implements StockUnitCalculator {
   public constructor(private readonly purchasable: CountedPurchasable) {}

   public calculate(): number | null {
      return this.purchasable.size;
   }
}
