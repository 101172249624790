<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      title="{{ lang().CreateLinkToArcGis }}"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      <lim-ui-panel [noSidePadding]="true">
         <div class="form-input">
            <label>
               {{ lang().EnterGisGlobalID }}
            </label>
            <input type="text" [(ngModel)]="gisGlobalID" />
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Add"
      (successClick)="linkAssetToArcGis()"
   />
</lim-ui-modal>
