<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      [title]="lang().TransferPartsToDifferentLocation"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      @if (!contentLoaded) {
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      }
      <span>
         @if (contentLoaded && permissionsErrorMessage) {
            <lim-ui-alert alertType="warning">{{ permissionsErrorMessage }}</lim-ui-alert>
         }
      </span>

      @if (contentLoaded && !permissionsErrorMessage) {
         <lim-ui-panel>
            <div class="form-container">
               {{ lang().TransferPartsMessage }}
               <form class="form-container">
                  <div class="form-group">
                     <div class="form-group-elements">
                        <label>
                           {{ lang().Transfer }} {{ part?.partName }}
                           {{ lang().To }}</label
                        >
                        <lim-ui-form-dropdown-input
                           [inputString]="locationSentTo.locationName"
                        >
                           <div menu>
                              @for (location of this.locations; track location) {
                                 <lim-ui-dropdown-text-item
                                    [displayString]="location.locationName"
                                    [outputString]="location.locationName"
                                    (itemSelectedEvent)="setLocation(location)"
                                 />
                              }
                           </div>
                        </lim-ui-form-dropdown-input>
                     </div>
                  </div>

                  @if (credChangePartQty) {
                     <div class="form-group">
                        <div class="form-group-elements">
                           <label>{{ lang().NumberOfPartsToTransfer }}</label>
                           <input
                              type="number"
                              min="0"
                              [(ngModel)]="qty"
                              [ngModelOptions]="{ standalone: true }"
                              (blur)="verifyQtyPossible()"
                           />
                        </div>
                     </div>
                  }
               </form>
               @if (part && partsExtraInfo.get(part.partID)?.totalQty; as totalQty) {
                  <div class="form-group">
                     @if (qty > totalQty) {
                        <lim-ui-alert [title]="lang().Warning" alertType="warning">
                           {{ lang().InsufficientPartQtyAtThisLocation }}
                        </lim-ui-alert>
                     }
                  </div>
               }
               @if (locationSending.locationID == locationSentTo.locationID) {
                  <div class="form-group">
                     <lim-ui-alert [title]="lang().Warning" alertType="warning">
                        {{ lang().cannotSendPartsToTheSameLocation }}
                     </lim-ui-alert>
                  </div>
               }
            </div>
         </lim-ui-panel>
      }
      @if (contentLoaded && !permissionsErrorMessage) {
         <lim-ui-modal-footer>
            @if (part && partsExtraInfo.get(part.partID)?.totalQty; as totalQty) {
               <lim-ui-primary-button
                  [disabled]="
                     qty > totalQty ||
                     locationSending.locationID == locationSentTo.locationID
                  "
                  (click)="submit()"
               >
                  {{ lang().Add }}
               </lim-ui-primary-button>
            }
            <lim-ui-secondary-button (click)="close()">
               {{ lang().Cancel }}
            </lim-ui-secondary-button>
         </lim-ui-modal-footer>
      }
   </lim-ui-modal-body>
</lim-ui-modal>
