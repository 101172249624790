import { computed, inject, Injectable } from "@angular/core";
import { ModalService } from "@limblecmms/lim-ui";
import { Subject } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";
import type { BulkPrintTasksSettings } from "src/app/shared/types/general.types";
import type { Lookup } from "src/app/shared/utils/lookup";
import { PickTasks } from "src/app/tasks/components/pickTasksModal/pickTasks.modal.component";
import { PopBulkTasks } from "src/app/tasks/components/popBulkTasksModal/popBulkTasks.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";

@Injectable({ providedIn: "root" })
export class BulkTasks {
   //This observable is used to communicate to the parent printTasks element
   // that one of its taskPrintTemplate children has its images loaded and is ready to print
   public readonly taskReady$: Subject<{ checklistID: number }> = new Subject();

   private readonly modalService = inject(ModalService);
   private readonly manageLang = inject(ManageLang);
   private readonly paramsService = inject(ParamsService);
   private readonly manageTask = inject(ManageTask);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public async bulkPrint(
      listOfTasks: Lookup<
         "checklistID",
         { checklistID: number; checklistDueDate: number | null }
      >,
   ) {
      const filteredTasks = listOfTasks.orderBy("checklistDueDate");
      const filteredTaskIDs = Array.from(filteredTasks.values()).map(
         (task) => task.checklistID,
      );

      const instance = this.modalService.open(PickTasks);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().BulkPrintOpenTasksMsg,
            title: this.lang().BulkPrintTasks,
            taskIDs: filteredTaskIDs,
            data: { selectOne: false, pickInformationToInclude: true },
         },
      };

      const data = await instance.result;
      if (!data.lookupOfTasks?.size) {
         return;
      }
      const taskIDs: Array<number> = Array.from(data.lookupOfTasks as TaskLookup).map(
         (task) => task.checklistID,
      );

      if ((data.informationToInclude as BulkPrintTasksSettings)?.comments === 1) {
         await this.manageTask.fetchNotes({ checklistIDs: taskIDs });
      }

      const instancePBT = this.modalService.open(PopBulkTasks);
      this.paramsService.params = {
         modalInstance: instancePBT,
         resolve: {
            data: {
               title: this.lang().PrintBulkTasks,
               message: "",
               print: true,
               taskIDs: taskIDs,
               informationToInclude: data.informationToInclude,
            },
         },
      };
   }
}
