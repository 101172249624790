<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body [scrollable]="true">
      <lim-ui-panel id="pickAssetHolder">
         @if (areAssetsLoaded() === false) {
            <div class="loading-icon">
               <lim-ui-loading-animation animationSize="small" />
               <span class="loading-text lim-ui-fonts-body"
                  >{{ lang().Loading }} {{ lang().AssetsLowerCase }}...</span
               >
            </div>
         } @else {
            <div class="panel-container scroll-height-inheritance">
               <div class="header-action-container">
                  <lim-ui-search-all-wrapper class="header-search-wrapper">
                     <lim-ui-search-box
                        searchBox
                        [(searchVal)]="searchBar"
                        [placeholder]="lang().Search"
                        [focusOnLoad]="true"
                     />
                     @if (searchBar) {
                        <lim-ui-filter-input
                           searchAllCriteria
                           (clearFilterEvent)="searchBar = ''"
                           [filterCriteria]="searchBar"
                        >
                           <label inputLabel> {{ lang().SearchFilter }}: </label>
                        </lim-ui-filter-input>
                     }
                  </lim-ui-search-all-wrapper>

                  @if (inMobileApp) {
                     <qr-code-button (scan)="scanQrCallback($event)" />
                  }
               </div>
               <div class="panel-body-container scroll-height-inheritance">
                  @if (iDontKnowOption && !isPlgSetUpWorkOrderEnabled()) {
                     <div
                        class="lim-ui-list-item lim-ui-selectable-list-item unsure-option"
                        (click)="selectNonTreeProperty('unsure')"
                        [ngClass]="{ 'lim-ui-selected-list-item': unsure }"
                        data-log="managerWOs-initiateWithoutAsset"
                     >
                        <div class="icon-with-text">
                           @if (unsure) {
                              <lim-ui-icon
                                 icon="check"
                                 iconSize="small"
                                 class="green-color"
                              />
                           }
                           <lim-ui-icon icon="cubeRegular" iconSize="small" />
                           <span>{{ lang().CreateWithoutAnAssignedAsset }}</span>
                        </div>
                     </div>
                  }

                  @if (
                     iDontKnowOption &&
                     isPlgSetUpWorkOrderEnabled() &&
                     !this.allowPickLocation
                  ) {
                     <div
                        class="lim-ui-list-item lim-ui-selectable-list-item unsure-option"
                        (click)="selectNonTreeProperty('unsure')"
                        [ngClass]="{ 'lim-ui-selected-list-item': unsure }"
                        data-log="managerWOs-initiateWithoutAsset"
                     >
                        <div class="icon-with-text">
                           @if (unsure) {
                              <lim-ui-icon
                                 icon="check"
                                 iconSize="small"
                                 class="green-color"
                              />
                           }
                           <lim-ui-icon icon="cubeRegular" iconSize="small" />
                           <span>{{ lang().CreateWithoutAnAssignedAsset }}</span>
                        </div>
                     </div>
                  }

                  <div class="asset-options scroll-height-inheritance">
                     @if (!selectOne) {
                        <div class="lim-ui-pick-list-header">
                           <lim-ui-selection-controls
                              [collapseAll]="lang().CollapseAll"
                              (handleCollapseAll)="collapseAll()"
                              [selectAll]="lang().SelectAll"
                              (handleSelectAll)="markAllAssetSelection()"
                              [deselectAll]="lang().DeselectAll"
                              (handleDeselectAll)="clearAssetSelection()"
                           />
                        </div>
                     }
                     <pick-assets-hierarchy
                        [singleLocation]="singleLocation"
                        [selectOne]="selectOne"
                        [restrictToCred]="restrictToCred"
                        [allowPickLocation]="allowPickLocation"
                        [preselectedAssetIDs]="preselectedAssetIDs"
                        [hiddenAssetIDs]="hiddenAssetIDs"
                        [search]="searchBar"
                        [extraCredCheck]="extraCredCheck"
                        (autoSubmit)="submit()"
                     />

                     @if (choosingLink) {
                        <div>
                           <div
                              (click)="selectNonTreeProperty('useParentTaskAsset')"
                              class="lim-ui-list-item lim-ui-selectable-list-item"
                              [ngClass]="{
                                 'lim-ui-selected-list-item': useParentTaskAsset,
                              }"
                           >
                              <div class="icon-with-text">
                                 @if (useParentTaskAsset) {
                                    <lim-ui-icon
                                       icon="check"
                                       iconSize="small"
                                       class="green-color"
                                    />
                                 }
                                 <lim-ui-icon
                                    icon="cube"
                                    iconSize="small"
                                    iconStyle="solid"
                                 />
                                 <span>{{ lang().UseTheAssetTheTaskBelongsTo }}</span>
                              </div>
                           </div>
                        </div>
                     }
                  </div>
               </div>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <div class="modal-footer-buttons move-assets-feature">
         <div class="footer-section">
            @if (canAddAssets() && deleteOption === false) {
               <lim-ui-secondary-button
                  dataLogLabel="manageAssets-initiateAnAsset"
                  (click)="openAddAssetModal()"
                  icon="plus"
                  class="add-asset"
               >
                  {{ lang().AddAsset }}
               </lim-ui-secondary-button>
            }
            @if (canMoveAssets() && showMoveAssetButton && !isMobile) {
               <lim-ui-secondary-button
                  dataLogLabel="manageAssets-initiateMoveAssetFromHierarchy-clickMoveAsset"
                  (click)="openMoveAssetModal()"
                  icon="listTreeRegular"
                  class="move-asset"
                  [disabled]="!hierarchy()?.selectedAssetNode"
               >
                  {{ lang().MoveAsset }}
               </lim-ui-secondary-button>
            }
         </div>
         <!-- 
            This button needs to display differently on mobile 
            No icon and in it's own container
          -->
         @if (canMoveAssets() && showMoveAssetButton && isMobile) {
            <div class="footer-section">
               <lim-ui-secondary-button
                  dataLogLabel="manageAssets-initiateMoveAssetFromHierarchy-clickMoveAsset"
                  (click)="openMoveAssetModal()"
                  class="move-asset"
                  [disabled]="!hierarchy()?.selectedAssetNode"
               >
                  {{ lang().MoveAsset }}
               </lim-ui-secondary-button>
            </div>
         }
         <div class="footer-section">
            <lim-ui-primary-button
               class="select-asset"
               (click)="submit()"
               [dataLogLabel]="dataLogSelectLabel"
            >
               {{ lang().Select }}
            </lim-ui-primary-button>
         </div>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
