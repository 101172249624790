<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-selection-panel [title]="message">
         <div panel-subtitle>
            <span class="select-all-options">
               @if (!data.selectOne) {
                  <a (click)="markAllSelection()" class="text-btn">{{
                     lang().SelectAll
                  }}</a>
               }
               <div class="text-btn-divider">|</div>
               @if (!data.selectOne) {
                  <a (click)="clearSelection()" class="text-btn">{{
                     lang().DeselectAll
                  }}</a>
               }
            </span>
         </div>
         <ng-container selection-panel-options>
            <lim-ui-search-box
               [placeholder]="lang().Search"
               [(searchVal)]="searchBar"
               (searchValChange)="updateSearch()"
               class="task-search"
               [focusOnLoad]="true"
            />
         </ng-container>
         <ng-container selection-panel-content>
            @if (noSearchResults) {
               <div>
                  <no-search-results />
               </div>
            }
            <div class="list-container">
               @for (location of locations; track location) {
                  <div>
                     @if (multiLocation && location.tasks?.length > 0) {
                        <div
                           (click)="location.showKids = !location.showKids"
                           class="lim-ui-list-item lim-ui-selectable-list-item"
                        >
                           <div class="location">
                              <lim-ui-icon
                                 [icon]="location.showKids ? 'angleUp' : 'angleDown'"
                                 iconSize="small"
                                 class="icon-left collapse-toggle"
                              />
                              <lim-ui-icon
                                 [icon]="'houseChimney'"
                                 iconSize="small"
                                 class="house-icon"
                              />
                              <div>{{ location.locationName }}</div>
                           </div>
                        </div>
                     }
                     @if (location.showKids == true) {
                        <div
                           [ngClass]="{
                              'lim-ui-nested-list-item': multiLocation,
                           }"
                        >
                           @for (task of location.tasks; track task) {
                              <div
                                 (click)="focusTask(task)"
                                 class="lim-ui-list-item lim-ui-selectable-list-item task"
                                 [ngClass]="{
                                    'lim-ui-selected-list-item': task.selected,
                                 }"
                              >
                                 <div class="task-content-main">
                                    <div class="list-item-content-group">
                                       @if (task.selected) {
                                          <lim-ui-icon
                                             icon="check"
                                             iconSize="small"
                                             iconColor="success"
                                          />
                                       }
                                       <lim-ui-icon icon="wrench" iconSize="small" />

                                       <div>
                                          <span [limbleHtml]="task.checklistName"></span>
                                          - #{{ task.checklistID }}

                                          @if (task.assetID != 0) {
                                             <span>
                                                @if (
                                                   assets.get(task.assetID);
                                                   as taskAsset
                                                ) {
                                                   <span>
                                                      &nbsp;-&nbsp;
                                                      <span
                                                         [limbleHtml]="
                                                            taskAsset.assetName
                                                         "
                                                         class="asset-name"
                                                      ></span>
                                                      @if (task.assetID) {
                                                         <asset-parent-list
                                                            [assetID]="task.assetID"
                                                            linkColorClass="green-color"
                                                         />
                                                      }
                                                   </span>
                                                }

                                                @for (
                                                   extraAsset of task.extraAssets;
                                                   track extraAsset
                                                ) {
                                                   <span>
                                                      &nbsp;-&nbsp;
                                                      <span
                                                         [limbleHtml]="
                                                            extraAsset.assetName
                                                         "
                                                         class="asset-name"
                                                      ></span>
                                                      <asset-parent-list
                                                         [assetID]="extraAsset.assetID"
                                                         linkColorClass="green-color"
                                                         [hover]="false"
                                                      />
                                                   </span>
                                                }
                                             </span>
                                          }
                                       </div>
                                    </div>
                                    <div class="list-item-content-group">
                                       <div>
                                          @for (
                                             recurrence of task.recurrences;
                                             track recurrence
                                          ) {
                                             <div>
                                                @if (
                                                   recurrence && recurrence.reoccurType
                                                ) {
                                                   @if (recurrence.reoccurType * 1 == 1) {
                                                      <span>
                                                         {{ lang().RepeatsEvery }}
                                                         {{ recurrence.reoccurFld1 }}
                                                         @if (
                                                            recurrence.reoccurFld1 > 1
                                                         ) {
                                                            <span>{{ lang().Days }}</span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld1 == 1
                                                         ) {
                                                            <span>{{ lang().Day }}</span>
                                                         }
                                                      </span>
                                                   }

                                                   @if (recurrence.reoccurType * 1 == 2) {
                                                      <span>
                                                         {{ lang().RepeatsEvery }}
                                                         {{ recurrence.reoccurFld1 }}
                                                         @if (
                                                            recurrence.reoccurFld1 > 1
                                                         ) {
                                                            <span>{{
                                                               lang().Weeks
                                                            }}</span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld1 == 1
                                                         ) {
                                                            <span>{{ lang().Week }}</span>
                                                         }
                                                         {{ lang().onStr }}
                                                         @if (
                                                            recurrence.reoccurFld2 == 1
                                                         ) {
                                                            <span
                                                               >{{ lang().Mon }}
                                                               @if (
                                                                  !$last &&
                                                                  +recurrence.reoccurFld2 >
                                                                     0
                                                               ) {
                                                                  <span>, </span>
                                                               }
                                                            </span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld2 == 2
                                                         ) {
                                                            <span
                                                               >{{ lang().Tue }}
                                                               @if (
                                                                  !$last &&
                                                                  +recurrence.reoccurFld2 >
                                                                     0
                                                               ) {
                                                                  <span>, </span>
                                                               }
                                                            </span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld2 == 3
                                                         ) {
                                                            <span
                                                               >{{ lang().Wed }}
                                                               @if (
                                                                  !$last &&
                                                                  +recurrence.reoccurFld2 >
                                                                     0
                                                               ) {
                                                                  <span>, </span>
                                                               }
                                                            </span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld2 == 4
                                                         ) {
                                                            <span
                                                               >{{ lang().Thu }}
                                                               @if (
                                                                  !$last &&
                                                                  +recurrence.reoccurFld2 >
                                                                     0
                                                               ) {
                                                                  <span>, </span>
                                                               }
                                                            </span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld2 == 5
                                                         ) {
                                                            <span
                                                               >{{ lang().Fri }}
                                                               @if (
                                                                  !$last &&
                                                                  +recurrence.reoccurFld2 >
                                                                     0
                                                               ) {
                                                                  <span>, </span>
                                                               }
                                                            </span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld2 == 6
                                                         ) {
                                                            <span
                                                               >{{ lang().Sat }}
                                                               @if (
                                                                  !$last &&
                                                                  +recurrence.reoccurFld2 >
                                                                     0
                                                               ) {
                                                                  <span>, </span>
                                                               }
                                                            </span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld2 == 7
                                                         ) {
                                                            <span
                                                               >{{ lang().Sun }}
                                                               @if (
                                                                  !$last &&
                                                                  +recurrence.reoccurFld2 >
                                                                     0
                                                               ) {
                                                                  <span>, </span>
                                                               }
                                                            </span>
                                                         }
                                                      </span>
                                                   }

                                                   @if (recurrence.reoccurType * 1 == 3) {
                                                      <span>
                                                         {{ lang().RepeatsEvery }}
                                                         {{ recurrence.reoccurFld1 }}
                                                         @if (
                                                            recurrence.reoccurFld1 > 1
                                                         ) {
                                                            <span>{{
                                                               lang().Months
                                                            }}</span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld1 == 1
                                                         ) {
                                                            <span>{{
                                                               lang().Month
                                                            }}</span>
                                                         }
                                                         {{ lang().onThe }}
                                                         {{
                                                            recurrence.reoccurFld2
                                                               | addDateEnding
                                                         }}
                                                      </span>
                                                   }

                                                   @if (recurrence.reoccurType * 1 == 4) {
                                                      <span>
                                                         {{ lang().RepeatsEvery }}
                                                         {{ recurrence.reoccurFld1 }}
                                                         @if (
                                                            recurrence.reoccurFld1 > 1
                                                         ) {
                                                            <span>{{
                                                               lang().Months
                                                            }}</span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld1 == 1
                                                         ) {
                                                            <span>{{
                                                               lang().Month
                                                            }}</span>
                                                         }
                                                         {{ lang().onThe }}
                                                         {{
                                                            recurrence.reoccurFld2
                                                               | firstSecondEtcToMultiLanguage
                                                         }}
                                                         {{
                                                            recurrence.reoccurFld3
                                                               | fullDay
                                                               | capitalize
                                                         }}
                                                      </span>
                                                   }

                                                   @if (recurrence.reoccurType * 1 == 5) {
                                                      <span>
                                                         {{ lang().RepeatsEvery }}
                                                         {{ recurrence.reoccurFld1 }}
                                                         @if (
                                                            recurrence.reoccurFld1 > 1
                                                         ) {
                                                            <span>{{
                                                               lang().Years
                                                            }}</span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld1 == 1
                                                         ) {
                                                            <span>{{ lang().Year }}</span>
                                                         }
                                                         {{ lang().inStr }}
                                                         {{
                                                            recurrence.reoccurFld2
                                                               | monthNumericToString
                                                         }}
                                                         {{ lang().onThe }}
                                                         {{
                                                            recurrence.reoccurFld3
                                                               | addDateEnding
                                                         }}
                                                      </span>
                                                   }

                                                   @if (recurrence.reoccurType * 1 == 6) {
                                                      <span>
                                                         {{ lang().RepeatsEvery }}
                                                         {{ recurrence.reoccurFld1 }}
                                                         @if (
                                                            recurrence.reoccurFld1 > 1
                                                         ) {
                                                            <span>{{
                                                               lang().Years
                                                            }}</span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld1 == 1
                                                         ) {
                                                            <span>{{ lang().Year }}</span>
                                                         }
                                                         {{ lang().onThe }}
                                                         {{
                                                            recurrence.reoccurFld2
                                                               | firstSecondEtcToMultiLanguage
                                                         }}
                                                         {{
                                                            recurrence.reoccurFld3
                                                               | fullDay
                                                         }}
                                                         {{ lang().ofStr }}
                                                         {{
                                                            recurrence.reoccurFld4
                                                               | fullMonth
                                                         }}
                                                      </span>
                                                   }

                                                   @if (recurrence.reoccurType * 1 == 7) {
                                                      <span>
                                                         {{ lang().Every }}
                                                         {{ recurrence.reoccurFld2 }}
                                                         {{
                                                            manageSchedule.getRecurrenceFieldName(
                                                               recurrence
                                                            )
                                                         }}
                                                      </span>
                                                   }

                                                   @if (recurrence.reoccurType * 1 == 8) {
                                                      <span>
                                                         {{ lang().IfEver }}
                                                         @if (
                                                            recurrence.reoccurFld3 == 0
                                                         ) {
                                                            <span>{{
                                                               lang().below
                                                            }}</span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld3 == 1
                                                         ) {
                                                            <span>{{
                                                               lang().above
                                                            }}</span>
                                                         }
                                                         @if (
                                                            recurrence.reoccurFld3 == 2
                                                         ) {
                                                            <span>{{
                                                               lang().between
                                                            }}</span>
                                                         }
                                                         {{ recurrence.reoccurFld2 }}
                                                         @if (
                                                            recurrence.reoccurFld3 == 2
                                                         ) {
                                                            <span
                                                               >{{ lang().and }}
                                                               {{
                                                                  recurrence.reoccurFld5
                                                               }}</span
                                                            >
                                                         }
                                                         {{
                                                            manageSchedule.getRecurrenceFieldName(
                                                               recurrence
                                                            )
                                                         }}
                                                      </span>
                                                   }

                                                   @if (
                                                      recurrence.reoccurType * 1 != 7 &&
                                                      recurrence.reoccurType * 1 != 8 &&
                                                      recurrence.reoccurTimeOfDay !=
                                                         null &&
                                                      recurrence.reoccurTimeOfDay !== ""
                                                   ) {
                                                      <span>
                                                         {{
                                                            lang().at +
                                                               " " +
                                                               formatTime(
                                                                  recurrence.reoccurTimeOfDay
                                                               )
                                                         }}
                                                      </span>
                                                   }
                                                }
                                             </div>
                                          }
                                       </div>
                                    </div>
                                    <div class="list-item-content-group">
                                       <span
                                          [limbleHtml]="
                                             manageTask.getTaskAssignmentInfo(task)
                                                .displayName
                                          "
                                       ></span>
                                    </div>
                                    <div class="list-item-content-group view-task">
                                       <a
                                          (click)="
                                             popTask(task); $event.stopPropagation()
                                          "
                                          limUiTooltip="{{ lang().ViewThisTasksDetails }}"
                                          class="text-btn"
                                       >
                                          {{ lang().View }}
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           }
                        </div>
                     }
                  </div>
               }
            </div>
         </ng-container>
      </lim-ui-selection-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer class="pick-tasks-footer">
      <lim-ui-secondary-button (click)="close()">
         {{ lang().Cancel }}</lim-ui-secondary-button
      >
      <lim-ui-primary-button (click)="submit()">{{
         lang().Select
      }}</lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
