import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import type { ModalResult } from "@limblecmms/lim-ui";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   CheckboxComponent,
   IconButtonComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimUiModalRef,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalButtonComponent,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PaginationComponent,
   PanelComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import clone from "rfdc";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { PickTasksTemplateView } from "src/app/tasks/components/pickTasksTemplateViewModal/pickTasksTemplateView.modal.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";

const deepClone = clone();

interface RelatedTaskListItem {
   checklistID: number;
   assetID: number;
   locationID: number;
   checklistBatchID: number;
   checklistPriorBatchID: number;
   locationName: string;
   checklistName: string;
   selected: boolean;
}

@Component({
   selector: "update-related-tasks",
   styleUrls: ["./updateRelatedTasks.component.scss"],
   templateUrl: "./updateRelatedTasks.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      IconButtonComponent,
      PanelComponent,
      CheckboxComponent,
      LimbleHtmlDirective,
      LoadingAnimationComponent,
      SecondaryButtonComponent,
      SelectionControlsComponent,
      IconComponent,
      MinimalIconButtonComponent,
      TooltipDirective,
      PaginationComponent,
      MinimalButtonComponent,
      BasicModalFooterComponent,
      OrderByPipe,
      SliceArray,
   ],
})
export class UpdateRelatedTasksComponent implements OnInit, ModalResult<void> {
   @Input({ required: true }) public checklistID!: number;
   @Input({ required: true }) public checklistBatchID!: number;
   @Input({ required: true }) public locationID!: number;

   public loadingBar;
   public showExtraTasks;
   public updateMsg;
   public buttonColor;
   public title;
   public message;
   public locationName: string = "";
   public numOfLocations;
   public syncItems;
   public syncParts;
   public syncSettings;
   public syncName;
   public syncDescription;
   public syncAssignments;
   public syncRecurrances;
   public templates: Array<RelatedTaskListItem> = [];
   public thisLocationTemplates: Array<RelatedTaskListItem> = [];
   public otherLocationTemplates: Array<RelatedTaskListItem> = [];
   public at;
   public end;
   public data;
   public page = 1;
   public itemsPerPage;
   public assets: Lookup<"assetID", Asset>;

   private readonly credService = inject(CredService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   public readonly modalRef: LimUiModalRef<UpdateRelatedTasksComponent, void> =
      inject(LimUiModalRef);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.assets = this.manageAsset.getAssets();
   }

   public ngOnInit() {
      this.loadingBar = true;
      this.showExtraTasks = false;

      this.updateMsg = false;
      this.buttonColor = "green";

      this.itemsPerPage = 50;

      this.title = this.lang().UpdateRelatedPMs;
      this.message = this.lang().UpdateRelatedPMsMsg;

      this.numOfLocations = this.manageLocation.getLocations().length;

      this.syncItems = false;
      this.syncParts = false;
      this.syncSettings = false;
      this.syncName = false;
      this.syncDescription = false;
      this.syncAssignments = false;
      this.syncRecurrances = false;

      this.setupData();
   }

   protected async setupData(): Promise<void> {
      const relatedTasksResponse =
         await this.manageTask.getListOfRelatedTaskTemplatesOnBatchID(this.checklistID);

      if (!relatedTasksResponse.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const locationsIndex = this.manageLocation.getLocationsIndex();

      if (this.locationID) {
         this.locationName = locationsIndex[this.locationID].locationName;
      }
      this.templates = [];
      for (const task of relatedTasksResponse.data.tasks) {
         //IMPORTANT... do not delete the conversation to int here UNLESS we have php returning it as an int
         task.locationID = Number(task.locationID);

         //they have to have the ability to edit that task template
         if (
            this.checklistID != task.checklistID &&
            this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.ChangePMDetails,
            ) &&
            this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.ChangeTheAssetThisPMBelongsTo,
            ) &&
            this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.ChangePMSchedules,
            ) &&
            this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.ChangePMAssignments,
            ) &&
            this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.BulkUpdatePMs,
            )
         ) {
            const selected = this.locationID === task.locationID;
            const locationName = locationsIndex[task.locationID].locationName;
            this.templates.push({ ...task, selected, locationName });
         }
      }

      this.thisLocationTemplates = this.templates.filter(
         (task) => task.locationID === this.locationID,
      );

      this.otherLocationTemplates = this.templates.filter(
         (task) => task.locationID !== this.locationID,
      );

      this.loadingBar = false;
      this.page = this.manageFilters.checkPagination(
         this.page,
         this.itemsPerPage,
         this.templates.length,
      );
   }

   extraTasks = () => {
      this.showExtraTasks = !this.showExtraTasks;

      //reset the tasks that have been marked as update because they closed view other related tasks outside of location
      for (const task of this.templates) {
         if (task) {
            if (task.locationID != this.locationID) {
               task.selected = false;
            }
         }
      }
   };

   linkNewPMs = async () => {
      let global;
      if (this.showExtraTasks == true) {
         global = true;
      } else {
         global = false;
      }

      const tasks = this.manageTask.getTasks();
      const finalTasks: any = [];
      let temp: TaskLookup = new Lookup("checklistID");
      const locations = this.manageLocation.getLocations();

      for (const location of locations) {
         //JIT TODO: remove this section of code once desktop templates are enabled, `finalTasks` is not used by JIT templates
         if (
            this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.BulkUpdatePMs,
            )
         ) {
            let include = false;
            if (global) {
               include = true;
            } else if (location.locationID == this.locationID) {
               include = true;
            }
            if (include) {
               temp = this.manageFilters.getTaskTemplatesAtLocation(
                  tasks,
                  location.locationID,
               );
               temp = temp.filter((task) => task.checklistTemplate === 1);
               temp = this.manageFilters.filterOutTasksWithDeletedAssets(
                  temp,
                  this.manageAsset.getAssets(),
               );
               for (const task of temp) {
                  if (task.checklistID !== this.checklistID) {
                     finalTasks.push(task);
                  }
               }
            }
         }
      }

      const locationIDs = this.showExtraTasks
         ? this.manageLocation.getLocations().map((location) => location.locationID)
         : [this.locationID];

      const modalRef = this.modalService.open(PickTasksTemplateView);
      modalRef.componentInstance.message = this.lang().CreateNewPMRelationsMsg;
      modalRef.componentInstance.title = this.lang().CreateNewPMRelationsTitle;
      modalRef.componentInstance.locationIDs = locationIDs;
      modalRef.componentInstance.singleSelection = false;
      modalRef.componentInstance.taskIDsToIgnore = [this.checklistID];
      const result = await modalRef.result;

      const pickedTemplateIDs = result ? Array.from(result) : [];

      if (pickedTemplateIDs.length === 0) {
         return;
      }
      this.manageTask
         .changePMTemplateRelationsInBulk(
            pickedTemplateIDs,
            this.checklistBatchID,
            this.checklistID,
         )
         .then((answer) => {
            if (answer.data.success == true) {
               this.setupData();
               this.alertService.addAlert(
                  this.lang().successMsgNewRelations,
                  "success",
                  2000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   };

   breakRelation = (task) => {
      this.manageTask.breakRelationPMTemplate(task.checklistID).then((answer) => {
         if (answer.data.success == true) {
            this.setupData();
            this.alertService.addAlert(
               this.lang().successMsgBreakRelations,
               "success",
               2000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         }
      });
   };

   popTask = (task): void => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               preview: true,
               title: this.lang().PreviewPM,
               template: true,
            },
         },
      };
   };

   popAsset = (task) => {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: task.assetID,
            locationID: task.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   submit = () => {
      if (this.loadingBar == true) {
         //this prevents something from repeatedly clicking
         return;
      }

      if (
         this.syncItems == false &&
         this.syncParts == false &&
         this.syncSettings == false &&
         this.syncName == false &&
         this.syncDescription == false &&
         this.syncAssignments == false &&
         this.syncRecurrances == false
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOneItemToBulkSync,
            "warning",
            6000,
         );
         return;
      }

      const finalTasks: any = [];
      const tempCopy = deepClone(this.templates);
      for (const task of tempCopy) {
         if (task.selected == true) {
            finalTasks.push(task);
         }
      }

      //we need to strip the data that isn't so the total amount isn't sent to the server.  If the url is too long the server times out.
      finalTasks.forEach((value) => {
         delete value.assetName;
         delete value.checklistName;
         delete value.locationName;
         delete value.checklistBatchID;
         delete value.checklistPriorBatchID;
         delete value.selected;
      });

      if (finalTasks.length == 0) {
         this.loadingBar = false;
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOnePMToUpdateOtherPMs,
            "warning",
            6000,
         );
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AreYouSureUpdateRelatedPMsMsg,
            title: this.lang().AreYouSureUpdateRelatedPMsTitle,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.loadingBar = true;
            this.updateMsg = true;
            this.at = 0;
            this.end = finalTasks.length;

            for (const task of finalTasks) {
               task.processed = false;
            }

            this.buttonColor = "grey"; //grays out button so they know it is working
            this.processBatch(finalTasks);
         }
      });
   };

   processBatch = (tasksToProcess) => {
      const thisBatch: any = [];
      let count = 1;
      const interval = 1;

      for (const key in tasksToProcess) {
         if (count > interval) {
            break;
         }

         if (tasksToProcess[key].processed == false) {
            tasksToProcess[key].processed = true;
            thisBatch.push(tasksToProcess[key]);
            count++;
         }
      }

      if (thisBatch.length == 0) {
         //we have processed all of them so let's no longer do this
         return true;
      }

      setTimeout(() => {
         //I wanted to give this a little pause in between so that the server's load is spaced out a little bit

         //we only update thisBatch at a time because this can be a resource heavy process
         this.manageTask
            .updateListOfRelatedTaskTemplates(
               this.checklistID,
               thisBatch,
               this.syncItems,
               this.syncParts,
               this.syncSettings,
               this.syncName,
               this.syncDescription,
               this.syncAssignments,
               this.syncRecurrances,
            )
            .then((answer) => {
               if (answer.data.success == true) {
                  for (const task of this.templates) {
                     if (answer.data.newTasks[task.checklistID]) {
                        task.checklistName =
                           answer.data.newTasks[task.checklistID].checklistName;
                        this.at++;
                     }
                  }

                  let doneProcessing = true;
                  for (const task of tasksToProcess) {
                     //we loop through all of the tasks that need to be process.  If there are any that aren't processed we need to start processing the batch again.
                     if (task.processed == false) {
                        doneProcessing = false;
                        this.processBatch(tasksToProcess);
                        break;
                     }
                  }

                  if (doneProcessing) {
                     //alot was changed so we need to fully resync the data.
                     this.manageAsset.getData().then(() => {
                        this.manageTask.getData().then(() => {
                           this.updateMsg = false;
                           this.loadingBar = false;
                           this.alertService.addAlert(
                              this.lang().UpdateRelatedPMsSuccessMsg,
                              "success",
                              2000,
                           );
                        });
                     });
                  }
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               }
            });
      }, 2500);

      return true;
   };

   deselectAllThisLocation = () => {
      for (const task of this.templates) {
         if (task) {
            if (task.locationID == this.locationID) {
               task.selected = false;
            }
         }
      }
   };

   selectAllThisLocation = () => {
      for (const task of this.templates) {
         if (task) {
            if (task.locationID == this.locationID) {
               task.selected = true;
            }
         }
      }
   };

   deselectAllOtherLocations = () => {
      for (const task of this.templates) {
         if (task) {
            if (task.locationID != this.locationID) {
               task.selected = false;
            }
         }
      }
   };

   selectAllOtherLocations = () => {
      for (const task of this.templates) {
         if (task) {
            if (task.locationID != this.locationID) {
               task.selected = true;
            }
         }
      }
   };

   openHelp = () => {
      const url =
         "https://help.limblecmms.com/en/articles/2971790-duplicate-bulk-edit-pms#3-bulk-update-pm-details";
      const win = window.open(url, "_blank");
      if (win !== null) {
         win.focus();
      }
   };

   close = () => {
      this.modalRef.close();
   };
}
