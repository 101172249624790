import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";

export enum PartLogSourceID {
   UpdatedPartName = 1,
   UpdatedPartNumber = 2,
   UpdatedPartQuantity = 3,
   AddedLogEntry = 4,
   TaskUsedPart = 5,
   UpdatedPartPrice = 6,
   UpdatedPartStaleThreshold = 7,
   UpdatedPartOverstockedThreshold = 8,
   DeletedPart = 9,
   CreatedTaskToOrderMoreOfThisPart = 10,
   PartQuantityThresholdStatusChanged = 11,
   PartImportedOrBulkUpdated = 12,
   UpdatedPartLocation = 13,
   UpdatedPartSupplier = 14,
   UpdatedPartImage = 15,
   RestoredPartsBecauseCompletedTaskReopened = 16,
   PartsUpdatedBecauseTaskEdited = 17,
   UpdatedPartFromPO = 18,
   UpdatedPartMaxQuantityThreshold = 19,
   UpdatedPartReceivedFromPO = 20,
   UpdatedPartVendorAssociation = 21,
   UpdatedPartVenderQuantity = 22,
   UpdatedPartVendorPrice = 23,
   CopiedPart = 24,
   CreatedOnAssetTransfer = 25,
   UpdatedPartUnitOfMeasure = 26,
}

export interface BasePartLogEntity {
   logID: number;
   logDate?: string;
   logDetails?: string;
   sourceID: PartLogSourceID;
   userID: number;
   partID: number;
   associatedID: number;
   oldValue?: string;
   newValue?: string;
   partPriceAtTime?: number;
   manualChangePartQtyReason?: string;
}

export interface PartLogEntityExtraFields {
   task?: TaskEntity;
   user?: { userFirstName: string; userLastName: string };
   newStockUnitName?: string;
   oldStockUnitName?: string;
}

export interface PartLogEntity extends BasePartLogEntity, PartLogEntityExtraFields {}

export interface PartLogEntityFilters {
   logID?: number;
   partID?: number;
   sourceID?: PartLogSourceID;

   start?: Date;
   end?: Date;

   search?: string;
}
