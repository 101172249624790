import { Component, effect, input, model, type ModelSignal } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePickerInputComponent } from "@limblecmms/lim-ui";
import type { FieldValueFilter } from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";

@Component({
   selector: "date-field-filter",
   standalone: true,
   imports: [
      DatePickerInputComponent,
      TranslateDirective,
      ReactiveFormsModule,
      FormsModule,
   ],
   templateUrl: "./date-field-filter.component.html",
})
export class DateFieldFilterComponent {
   public readonly filterDateStart: ModelSignal<Date | null> = model<Date | null>(null);
   public readonly filterDateEnd: ModelSignal<Date | null> = model<Date | null>(null);

   public initialValue = input<Partial<FieldValueFilter> | undefined>();

   public constructor() {
      effect(() => {
         const value = this.initialValue();
         if (value?.from) {
            const from = new Date(value.from);
            this.onFilterDateStartChanged(Number.isNaN(from.getTime()) ? null : from);
         }
         if (value?.to) {
            const to = new Date(value.to);
            this.onFilterDateEndChanged(Number.isNaN(to.getTime()) ? null : to);
         }
      });
   }

   public onFilterDateStartChanged(date: Date | null) {
      this.filterDateStart.set(date);
   }

   public onFilterDateEndChanged(date: Date | null) {
      this.filterDateEnd.set(date);
   }
}
