<lim-ui-mention-dropdown id="myDropdown-{{mentionUid}}">
   <div id="myDropdownMenu-{{mentionUid}}" menu>
      @for (item of filteredList; track item) {
      <lim-ui-dropdown-item
         class="comment-dropdown-item"
         id="selectable-item-{{mentionUid}}-{{$index}}"
         attr.data-index="{{$index}}"
         attr.data-itemID="{{item.itemID ? item.itemID : item.userID}}"
         attr.data-profileID="{{item.profileID}}"
         (click)="handleDropdownItemClick(item)"
      >
         @if (item.userFirstName || item.userLastName) {
         <span class="mention-row">
            <user-image [user]="item" />
            <span> {{ item.userFirstName }} {{ item.userLastName }} </span>
         </span>
         } @if (item.profileDescription) {
         <span class="mention-row">
            <lim-ui-block-icon icon="users" />
            <span [limbleHtml]="item.profileDescription"></span>
         </span>
         } @if (item.tagDescription) {
         <span class="mention-row">
            <lim-ui-block-icon icon="tag" />
            <span [limbleHtml]="item.tagDescription"></span>
         </span>
         }
      </lim-ui-dropdown-item>
      }
   </div>
</lim-ui-mention-dropdown>
