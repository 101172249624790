import { Component, computed, inject, signal, Input, type OnInit } from "@angular/core";
import { TooltipDirective } from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { CurrencyNamePipe } from "src/app/purchasing/currency/pipes/currency-name.pipe";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

@Component({
   selector: "cost-viewer-component",
   imports: [LocaleCurrencyPipe, TooltipDirective, CurrencyNamePipe, BetterDecimalPipe],
   templateUrl: "./cost-viewer-component.html",
   styleUrl: "./cost-viewer-component.scss",
})
export class CostViewerComponent implements OnInit {
   @Input() public rate!: number | undefined | null;
   @Input() public locationID!: number | undefined;
   @Input() public currencySymbol!: string;
   private readonly currentLocationID = signal<number | undefined>(undefined);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationsQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationsQueries.detail(this.currentLocationID()),
   );

   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );
   public ngOnInit(): void {
      this.currentLocationID.set(this.locationID);
   }
}
