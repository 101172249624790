import { Component, inject, input, signal, viewChild } from "@angular/core";
import {
   CheckboxComponent,
   LimUiModalRef,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   ScrollContainerComponent,
   type ModalResult,
} from "@limblecmms/lim-ui";
import {
   NgxSkeletonLoaderComponent,
   type NgxSkeletonLoaderConfigTheme,
} from "ngx-skeleton-loader";
import { PickAssetsHierarchyComponent } from "src/app/assets/components/pickAssetsModal/pick-assets-hierarchy/pick-assets-hierarchy.component";
import { AiModalComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-modal/ai-modal.component";
import { PmSuggestionsAssets } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/pm-suggestions-assets";

@Component({
   selector: "select-assets",
   templateUrl: "./select-assets-modal.component.html",
   styleUrls: ["./select-assets-modal.component.scss"],
   imports: [
      SecondaryButtonComponent,
      PrimaryButtonComponent,
      SearchBoxComponent,
      SelectionControlsComponent,
      CheckboxComponent,
      PickAssetsHierarchyComponent,
      NgxSkeletonLoaderComponent,
      AiModalComponent,
      PanelComponent,
      ScrollContainerComponent,
   ],
})
export class SelectAssetsModalComponent
   implements ModalResult<Array<number> | undefined>
{
   public readonly modalRef: LimUiModalRef<
      SelectAssetsModalComponent,
      Array<number> | undefined
   > = inject(LimUiModalRef);

   public assets = inject(PmSuggestionsAssets).assets;

   public readonly locationID = input<number | undefined>(undefined);

   public readonly hierarchy = viewChild(PickAssetsHierarchyComponent);

   public readonly search = signal<string>("");

   public readonly autoSelectChildren = signal<boolean>(false);

   protected readonly skeletonTheme: NgxSkeletonLoaderConfigTheme = {
      width: "100%",
      height: "45px",
      border: "0",
      margin: "0",
   };

   public async submit(): Promise<void> {
      return this.modalRef.close(this.getSelectedAssetIDs());
   }

   public async close(): Promise<void> {
      return this.modalRef.close(undefined);
   }

   public getSelectedAssetIDs(): Array<number> {
      const hierarchy = this.hierarchy();
      const assets = this.assets.value();
      if (hierarchy === undefined || assets === undefined) return [];
      return [...assets]
         .filter((asset) => hierarchy.getAssetNode(asset.assetID)?.selected === true)
         .map((asset) => asset.assetID);
   }
}
