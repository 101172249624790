import type { TutorialData } from "src/app/onboarding/services/tutorials/tutorial";
import { Tutorial } from "src/app/onboarding/services/tutorials/tutorial";
import type { Video } from "src/app/shared/types/general.types";

export interface VideoTutorialData extends TutorialData {
   videoId: string;
   startTime?: string;
   domain?: "youtube" | "loom";
   trackingID?: string;
}

export class VideoTutorial extends Tutorial {
   public videoId: string = "";
   /** Only supported for YouTube videos */
   public startTime?: string;
   public override isVideo: boolean = true;
   public readonly domain: Exclude<VideoTutorialData["domain"], undefined>;
   /** Only used for Loom videos */
   public readonly trackingID: Exclude<VideoTutorialData["trackingID"], undefined> | null;

   public constructor(private readonly data: VideoTutorialData) {
      super(data);
      Object.assign(this, data);
      this.domain = data.domain ?? "youtube";
      this.trackingID = data.trackingID ?? null;
   }

   public get thumbnail(): string {
      if (this.domain === "loom") {
         return this.getLoomThumbnail();
      }
      return this.getYoutubeThumbnail();
   }

   public get src(): string {
      if (this.domain === "loom") {
         return this.getLoomSrc();
      }
      return this.getYoutubeSrc();
   }

   public get video(): Video {
      return {
         title: this.title,
         src: this.src,
         thumbnail: this.thumbnail,
      };
   }

   private getLoomSrc(): string {
      return this.trackingID
         ? `https://www.loom.com/embed/${this.videoId}?sid=${this.trackingID}`
         : `https://www.loom.com/embed/${this.videoId}`;
   }

   private getYoutubeSrc(): string {
      return this.startTime
         ? `https://www.youtube.com/embed/${this.videoId}?autoplay=1&res=0&start=${this.startTime}`
         : `https://www.youtube.com/embed/${this.videoId}?autoplay=1&res=0`;
   }

   private getLoomThumbnail(): string {
      return `https://img.loom.com/embed/${this.videoId}/thumbnail.jpg`;
   }

   private getYoutubeThumbnail(): string {
      return `https://img.youtube.com/vi/${this.videoId}/mqdefault.jpg`;
   }
}
