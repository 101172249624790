import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed, input, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { DragEndEvent, LimbleTreeOptions } from "@limble/limble-tree";
import { LimbleTreeRootComponent } from "@limble/limble-tree";
import {
   BadgeComponent,
   CheckboxComponent,
   DangerButtonComponent,
   IconButtonComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LoadingAnimationComponent,
   PanelComponent,
   PopoverDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { BudgetTreeItem } from "src/app/purchasing/budgets/budgetTreeItemElement/budgetTreeItem.element.component";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { DefaultBudgetType, ManagePO } from "src/app/purchasing/services/managePO";
import type { BudgetWorkflow } from "src/app/purchasing/types/budget-workflow.types";
import type { Budget } from "src/app/purchasing/types/budget.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { EmailTemplate } from "src/app/shared/components/global/emailTemplateModal/emailTemplate.modal.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

@Component({
   selector: "budget",
   templateUrl: "./budget.wrapper.component.html",
   styleUrls: [
      "./budget.wrapper.component.scss",
      "../budgetTreeItemElement/budgetTreeItem.element.component.scss",
   ],
   imports: [
      NgClass,
      LoadingAnimationComponent,
      PanelComponent,
      FormsModule,
      IconButtonComponent,
      DangerButtonComponent,
      InfoPanelComponent,
      IconComponent,
      LimbleTreeRootComponent,
      CheckboxComponent,
      BadgeComponent,
      UpsellPopover,
      PopoverDirective,
   ],
})
export class BudgetComponent implements OnDestroy, OnInit {
   public readonly budgetID = input.required<number>();
   public budget: Budget | undefined;
   public budgetAwaitingDisplayName: string = "";
   public budgetPrDisplayName: string = "";
   public CID;
   public currencySymbol;
   public loading;
   public gridLoaded;
   public superUser;
   public steps: Array<BudgetWorkflow> = [];
   public defaultBudget: boolean = false;
   public defaultBudgetRequestPurchase: boolean = false;
   public defaultBudgetMinPart: boolean = false;
   public editBudgetCred;
   public treeOptions?: LimbleTreeOptions;
   public oldBudgetName;
   protected featureCustomBudgets: boolean = false;
   protected canEditReadyToRecieveSection: boolean = true;
   private customerPlan: string = "";
   private readonly manageFeatureFlagsSub: Subscription = new Subscription();

   private readonly manageLang = inject(ManageLang);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageLocation = inject(ManageLocation);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly currentLocationID = signal<number | undefined>(undefined);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationsQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationsQueries.detail(this.currentLocationID()),
   );

   protected readonly isCurrencyPending = computed(
      () => this.locationQuery.isPending() || this.accountCurrencyQuery.isPending(),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureCustomBudgets = isFeatureEnabledMap.featureCustomBudgets;
         },
      );
   }

   public ngOnInit(): void {
      const currentUser = this.manageUser.getCurrentUser();
      this.getUserData(currentUser);
      this.currentLocationID.set(this.budget?.locationID);
   }

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private getUserData(currentUser) {
      this.CID = currentUser.customerID;
      this.customerPlan = currentUser.userInfo.customerPlan;
      this.currencySymbol = currentUser.currency.symbol;
      this.loading = true;
      this.gridLoaded = false;
      this.canEditReadyToRecieveSection = this.featureCustomBudgets;
      this.buildData();
      if (!this.budget?.locationID) return;
      this.editBudgetCred = this.credService.isAuthorized(
         this.budget.locationID,
         this.credService.Permissions.ChangeBudgetSettings,
      );
      this.treeOptions = {
         allowNesting: false,
         defaultComponent: {
            class: BudgetTreeItem,
            bindings: {
               editBudgetCred: this.editBudgetCred,
               editBudgetWorkflowStepEmail: this.editBudgetWorkflowStepEmail.bind(this),
               deleteStep: this.deleteStep.bind(this),
               addStep: this.addStep.bind(this),
               budgetID: this.budgetID(),
               currencyCode: this.currencyCode,
            },
         },
      };

      this.getAwaitingDisplayName();
      this.getPrDisplayName();

      this.oldBudgetName = this.budget.name;
   }

   protected async onMoveNode(event: DragEndEvent<BudgetTreeItem>): Promise<void> {
      if (!this.featureCustomBudgets) {
         return;
      }
      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }
      const draggedField = this.steps.splice(event.oldIndex(), 1);
      this.steps.splice(event.newIndex(), 0, draggedField[0]);
      for (const [index, step] of this.steps.entries()) {
         if (step) step.order = index + 1;
      }

      const updates: Array<{ order: number | null; budgetWorkflowID: number }> =
         this.steps
            .map((step) => {
               if (step === undefined) return undefined;
               return { order: step?.order, budgetWorkflowID: step?.budgetWorkflowID };
            })
            .filter((step) => {
               return step !== undefined;
            }) as Array<{ order: number | null; budgetWorkflowID: number }>;

      const reorderWorkflowStepsResponse = await this.managePO.reorderBudgetWorkflowSteps(
         this.budgetID(),
         updates,
      );
      if (reorderWorkflowStepsResponse) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   public async buildData(): Promise<void> {
      this.budget = this.managePO.getBudget(this.budgetID());
      if (!this.budget?.locationID) return;
      const budgetDetails = await this.managePO.getBudgetDetails(this.budgetID());
      if (!budgetDetails) return;

      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      ); //if they can manage roles they must be a super user
      const tempSteps = this.budget?.budgetWorkflowIDs
         .map((budgetWorkflowID) => {
            const step = this.managePO.getBudgetWorkflow(budgetWorkflowID);

            return step;
         })
         .filter((step) => {
            return step !== undefined;
         });

      this.steps = orderBy(tempSteps, "order");

      //set if this budget is default for anything.
      const location = this.manageLocation.getLocation(this.budget.locationID);
      assert(location !== undefined);

      if (this.budgetID() === location.defaultBudgetID) {
         this.defaultBudget = true;
         // An edge case for premium customers. They can edit the ready to recieve section but not the rest of the budget.
         if (this.customerPlan == "premium") {
            this.canEditReadyToRecieveSection = true;
         }
      }
      if (this.budgetID() === location.defaultBudgetIDRequestPurchase) {
         this.defaultBudgetRequestPurchase = true;
      }
      if (this.budgetID() === location.defaultBudgetIDMinPart) {
         this.defaultBudgetMinPart = true;
      }

      this.loading = false;
   }

   protected async addStep(order: number): Promise<void> {
      if (!this.featureCustomBudgets) {
         return;
      }

      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      const newOrder = Number(order) + 1;

      for (const step of this.steps) {
         if (this.steps.length > 0 && step.order && step.order > order) {
            step.order += 1;
         }
      }
      const addWorkflowStepResponse = await this.managePO.addBudgetWorkflowStep(
         this.budgetID(),
         newOrder,
      );
      const tempSteps = this.budget?.budgetWorkflowIDs
         .map((budgetWorkflowID) => {
            return this.managePO.getBudgetWorkflow(budgetWorkflowID);
         })
         .filter((step) => {
            return step !== undefined;
         });
      if (addWorkflowStepResponse.success == true) {
         this.steps = orderBy(tempSteps, "order");

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   private poIsAssignedToBudget(po: any): boolean {
      return po.budgetID === this.budgetID();
   }

   private poIsOpen(po: any): boolean {
      return po.state < 10;
   }

   private budgetStepHasOpenPOs(openPONumbers: number[]): boolean {
      if (openPONumbers.length > 0) {
         const formattedPONumbers = openPONumbers.join(" ");
         this.alertService.addAlert(
            `${this.lang().DeleteBudgetStepWithOpenPOWarning} <br /><br />${formattedPONumbers}`,
            "warning",
            15000,
         );
         return true;
      }

      return false;
   }

   private deleteStep(step: BudgetWorkflow): void {
      if (!this.featureCustomBudgets) {
         return;
      }
      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      this.managePO
         .getOpenPONumbersInBudgetStep(step.budgetID, step.order)
         .then(async (openPOs) => {
            if (!this.budgetStepHasOpenPOs(openPOs)) {
               const instance = this.modalService.open(Confirm);

               this.paramsService.params = {
                  modalInstance: instance,
                  resolve: {
                     message: this.lang().AreYouSure,
                     title: this.lang().DeleteStep,
                  },
               };

               return instance.result;
            }

            return null;
         })
         .then(async (result) => {
            if (result == 1) {
               const order = step.order;
               const workflowStepDeletedResponse =
                  await this.managePO.deleteBudgetWorkflowStep(
                     this.budgetID(),
                     step.budgetWorkflowID,
                  );
               if (workflowStepDeletedResponse === true) {
                  step.deleted = 1;
                  this.steps = this.steps.filter((element) => element.deleted === 0);

                  for (const tempStep of this.steps) {
                     if (tempStep.order && order && tempStep.order > order) {
                        tempStep.order -= 1;
                     }
                  }
                  if (this.budgetID() && this.budget) {
                     const tempSteps = this.budget.budgetWorkflowIDs
                        .map((budgetWorkflowID) => {
                           return this.managePO.getBudgetWorkflow(budgetWorkflowID);
                        })
                        .filter((elem) => {
                           return elem !== undefined;
                        });
                     this.steps = orderBy(tempSteps, "order");
                  }
               }
            }
         });
   }

   private editBudgetWorkflowStepEmail(step: BudgetWorkflow): void {
      if (!this.featureCustomBudgets) {
         return;
      }
      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      let to, subject, body, extraToHint;

      //sets an email to hint
      if (step.userID == 0 && step.profileID == 0) {
         extraToHint = "";
      } else {
         const getDisplayNameResponse = this.managePO.getPurchasingAssignmentName({
            userID: step.userID ?? 0,
            profileID: step.profileID ?? 0,
            defaultValue: this.lang().TheUserThatStartedThePO,
         });
         if (getDisplayNameResponse) {
            extraToHint = `${this.lang().EmailsWillAlsoBeSentToWhoThePOIsAssignedTo} ${getDisplayNameResponse}`;
         }
      }

      if (step.emailTo != null && step.emailTo.length > 0) {
         to = step.emailTo;

         const lastChar = to.substr(to.length - 1);
         if (lastChar !== ";") {
            to = `${to};`;
         }
      } else {
         to = " ";
      }
      if (to === ";" || to === " ;") {
         to = " ";
      }

      if (step.emailSubject != null && step.emailSubject.length > 0) {
         subject = step.emailSubject;
      } else {
         subject = "";
      }

      if (step.emailBody != null && step.emailBody.length > 0) {
         body = step.emailBody;
      } else {
         body = "";
      }
      const message = this.getPOEmailMessageHint();

      const instance = this.modalService.open(EmailTemplate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: message,
            title: `${step.name} - ${this.lang().AutomaticEmailNotificationWhenThisStepStarts}`,
            data: {
               emailTo: to,
               emailSubject: subject,
               emailMessage: body,
               pickEmployees: false,
               checklistID: false,
               onSubmit: "returnData",
               extraToHint: extraToHint,
            },
         },
         backdrop: "static",
         keyboard: false,
      };

      instance.result.then(async (result) => {
         if (result === 0) return;
         const updateEmailResponse = await this.managePO.updateBudgetWorkflowStepEmail(
            step.budgetWorkflowID,
            result.recipients,
            result.subject,
            result.message,
         );
         if (updateEmailResponse) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      });
   }

   protected setBudgetAwaitingAssignment(): void {
      if (!this.canEditReadyToRecieveSection && !this.featureCustomBudgets) {
         return;
      }
      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().TheUserThatStartedThePO,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: "",
               title: this.lang().WhoWouldYouLikeToAssignThePOToWhenReadyToRecieveStarts,
               locationID: this.budget.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.budget.awaitingUserID,
               defaultProfile: this.budget.awaitingProfileID,
            },
         },
      };

      instance.result.then(async (result) => {
         if (result === 0) return;
         const setBudgetAwaitAssignmentRes =
            await this.managePO.setBudgetAwaitingAssignment(
               this.budgetID(),
               result.userID,
               result.profileID,
               result.multiUsers,
            );
         if (setBudgetAwaitAssignmentRes) {
            this.getAwaitingDisplayName();
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      });
   }

   protected setBudgetPRAssignment(): void {
      if (!this.canEditReadyToRecieveSection && !this.featureCustomBudgets) {
         return;
      }

      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().TheUserThatStartedThePO,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: "",
               title: this.lang().WhoWouldYouLikeToAssignTheBillToAfterPOItemsAreReceived,
               locationID: this.budget.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.budget.prUserID,
               defaultProfile: this.budget.prProfileID,
            },
         },
      };

      instance.result.then(async (result) => {
         if (result === 0) return;
         const setBudgetAssignmentResponse = await this.managePO.setBudgetBillAssignment(
            this.budgetID(),
            result.userID,
            result.profileID,
            result.multiUsers,
         );
         if (setBudgetAssignmentResponse) {
            this.getPrDisplayName();
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      });
   }

   protected editAwaitingEmail(): void {
      if (!this.canEditReadyToRecieveSection && !this.featureCustomBudgets) {
         return;
      }

      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      let to, subject, body, extraToHint;

      //sets an email to hint
      if (this.budget.awaitingUserID == 0 && this.budget.awaitingProfileID == 0) {
         extraToHint = "";
      } else {
         this.getAwaitingDisplayName();

         extraToHint = `${this.lang().EmailsWillAlsoBeSentToWhoThePOIsAssignedTo} ${this.budgetAwaitingDisplayName}`;
      }

      if (this.budget.awaitingEmailTo != null && this.budget.awaitingEmailTo.length > 0) {
         to = this.budget.awaitingEmailTo;

         const lastChar = to.substr(to.length - 1);
         if (lastChar !== ";") {
            to = `${to};`;
         }
      } else {
         to = " ";
      }
      if (to === ";" || to === " ;") {
         to = " ";
      }

      if (
         this.budget.awaitingEmailSubject != null &&
         this.budget.awaitingEmailSubject.length > 0
      ) {
         subject = this.budget.awaitingEmailSubject;
      } else {
         subject = "";
      }

      if (
         this.budget.awaitingEmailBody != null &&
         this.budget.awaitingEmailBody.length > 0
      ) {
         body = this.budget.awaitingEmailBody;
      } else {
         body = "";
      }

      const message = this.getPOEmailMessageHint();

      const instance = this.modalService.open(EmailTemplate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: message,
            title: this.lang().ReadyToRecieveAutomaticEmailDescription,
            data: {
               emailTo: to,
               emailSubject: subject,
               emailMessage: body,
               pickEmployees: false,
               checklistID: false,
               onSubmit: "returnData",
               extraToHint: extraToHint,
            },
         },
         backdrop: "static",
         keyboard: false,
      };

      instance.result.then(async (result) => {
         if (result === 0) return;
         const updateBudgetAwaitingEmailResponse =
            await this.managePO.updateBudgetAwaitingEmail(
               this.budgetID(),
               result.recipients,
               result.subject,
               result.message,
            );
         if (updateBudgetAwaitingEmailResponse) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      });
   }

   protected editPREmail(): void {
      if (!this.canEditReadyToRecieveSection && !this.featureCustomBudgets) {
         return;
      }

      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      let to, subject, body, extraToHint;

      //sets an email to hint
      if (this.budget.prUserID == 0 && this.budget.prProfileID == 0) {
         extraToHint = "";
      } else {
         this.getPrDisplayName();
         extraToHint = `${this.lang().EmailsWillAlsoBeSentToWhoThePR} ${this.budgetPrDisplayName}`;
      }

      if (this.budget.prEmailTo != null && this.budget.prEmailTo.length > 0) {
         to = this.budget.prEmailTo;

         const lastChar = to.substr(to.length - 1);
         if (lastChar !== ";") {
            to = `${to};`;
         }
      } else {
         to = " ";
      }
      if (to === ";" || to === " ;") {
         to = " ";
      }

      if (this.budget.prEmailSubject != null && this.budget.prEmailSubject.length > 0) {
         subject = this.budget.prEmailSubject;
      } else {
         subject = "";
      }

      if (this.budget.prEmailBody != null && this.budget.prEmailBody.length > 0) {
         body = this.budget.prEmailBody;
      } else {
         body = "";
      }

      const message = this.getPREmailMessageHint();

      const instance = this.modalService.open(EmailTemplate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: message,
            title: this.lang().BillAutomaticEmailDescription,
            data: {
               emailTo: to,
               emailSubject: subject,
               emailMessage: body,
               pickEmployees: false,
               checklistID: false,
               onSubmit: "returnData",
               extraToHint: extraToHint,
            },
         },
         backdrop: "static",
         keyboard: false,
      };

      instance.result.then(async (result) => {
         if (result === 0) return;
         const updateBudgetPREmailResponse = await this.managePO.updateBudgetBillEmail(
            this.budgetID(),
            result.recipients,
            result.subject,
            result.message,
         );
         if (updateBudgetPREmailResponse) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      });
   }

   protected async setAwaitingEmailSend(): Promise<void> {
      if (!this.canEditReadyToRecieveSection && !this.featureCustomBudgets) {
         return;
      }

      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }
      const newValue = this.budget.awaitingEmailSend;
      const setAwaitingEmailSendResponse = await this.managePO.setAwaitingEmailSend(
         this.budgetID(),
         newValue,
      );
      if (setAwaitingEmailSendResponse) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   protected async setPREmailSend(): Promise<void> {
      if (!this.canEditReadyToRecieveSection && !this.featureCustomBudgets) {
         return;
      }

      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      const newValue = this.budget.prEmailSend;

      const setPREmailSendResponse = await this.managePO.setBillEmailSend(
         this.budgetID(),
         newValue,
      );
      if (setPREmailSendResponse) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   protected async setBudgetAwaitingPOCanBeEdited(): Promise<void> {
      if (!this.canEditReadyToRecieveSection && this.featureCustomBudgets) {
         return;
      }

      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      const newValue = this.budget.awaitingAllowPOEdit;

      const setBudgetAwaitingPOCanBeEditedResponse =
         await this.managePO.setBudgetAwaitingPurchaseOrderCanBeEdited(
            this.budgetID(),
            newValue,
         );
      if (setBudgetAwaitingPOCanBeEditedResponse) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   protected deleteBudget(): void {
      if (this.defaultBudget || !this.featureCustomBudgets) {
         return;
      }
      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.DeleteBudget,
         )
      ) {
         return;
      }

      //only allow delete if there are not any open POs for this...
      const POs = this.managePO.getPurchaseOrders();
      let openPOStr = "";
      for (const tempPO of POs) {
         if (this.poIsAssignedToBudget(tempPO) && this.poIsOpen(tempPO)) {
            openPOStr += `${tempPO.poNumber}, `;
         }
      }

      if (openPOStr != "") {
         //we found open POs so don't let them continue;
         openPOStr = openPOStr.substring(0, openPOStr.length - 2);

         this.alertService.addAlert(
            `${this.lang().DeleteBudgetWithOpenPOWarning} <br /><br />${openPOStr}`,
            "warning",
            15000,
         );
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().DeletingABudgetDescription}<br /><br />${this.lang().AreYouSureYouWantToDeleteThisBudget}<br /><br /><b class='red-color'>${this.lang().ThisActionCanNotBeUndone}</b>`,
            title: this.lang().DeleteBudget,
         },
      };

      instance.result.then(async (result) => {
         if (result !== 1) return;
         const deleteBudgetResponse = await this.managePO.deleteBudget(this.budgetID());
         if (deleteBudgetResponse == true) {
            this.modalService.getActiveModal()?.close();
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      });
   }

   protected async updateBudgetName(): Promise<void> {
      if (!this.defaultBudget && !this.featureCustomBudgets) {
         return;
      }
      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      if (this.oldBudgetName == this.budget.name) {
         return;
      }
      const updateBudgetNameResponse = await this.managePO.updateBudgetName(
         this.budgetID(),
         this.budget.name,
      );
      if (updateBudgetNameResponse) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   protected async setDefaultBudgetStandard(): Promise<void> {
      return this.setDefaultBudget(DefaultBudgetType.Standard);
   }

   protected async setDefaultBudgetPurchaseRequest(): Promise<void> {
      return this.setDefaultBudget(DefaultBudgetType.PurchaseRequest);
   }

   protected async setDefaultBudgetMinPart(): Promise<void> {
      return this.setDefaultBudget(DefaultBudgetType.MinimumPartQuantityPurchaseOrder);
   }

   private async setDefaultBudget(type: DefaultBudgetType): Promise<void> {
      if (!this.defaultBudget && !this.featureCustomBudgets) {
         return;
      }
      if (!this.budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            this.budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }
      const setDefaultBudgetResponse = await this.managePO.setDefaultBudget(
         this.budgetID(),
         type,
         this.budget.locationID,
      );
      if (setDefaultBudgetResponse) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   private getAwaitingDisplayName(): void {
      if (!this.budget) return;
      const getAwaitingBudgetReq = {
         userID: this.budget.awaitingUserID ?? 0,
         profileID: this.budget.awaitingProfileID ?? 0,
         defaultValue: this.lang().TheUserThatStartedThePO,
      };
      const getAwaitingDisplayNameResponse =
         this.managePO.getPurchasingAssignmentName(getAwaitingBudgetReq);
      if (getAwaitingDisplayNameResponse)
         this.budgetAwaitingDisplayName = getAwaitingDisplayNameResponse;
   }

   private getPrDisplayName(): void {
      if (!this.budget) return;
      const getPRDisplayName = {
         userID: this.budget.prUserID ?? 0,
         profileID: this.budget.prProfileID ?? 0,
         defaultValue: this.lang().TheUserThatStartedThePO,
      };
      const getPRDisplayNameResponse =
         this.managePO.getPurchasingAssignmentName(getPRDisplayName);
      if (getPRDisplayNameResponse) this.budgetPrDisplayName = getPRDisplayNameResponse;
   }

   private getPOEmailMessageHint(): string {
      let message;
      message = "";
      message +=
         "<b>Every step of a PO's workflow can have an email notification automatically sent when that Step is started.</b>";
      message += "<br />";
      message += "<br />";
      message +=
         "This is helpful for letting co workers know that a PO is now on a certain Step.  Additionally you can trigger the next step in the workflow straight from the email.";
      message += "<br />";
      message += "<br />";
      message +=
         "To help you display data that will change from one PO to another PO we have included placeholder tags that looks like this: {{po-date}}.  These placeholder tags let you have your emails display the exact information for that PO.";
      message += "<br />";
      message += "<br />";
      message += "<b>Possible placeholders</b>";
      message += "<br />";
      message += "<br />";
      message += "{{po-number}} - The PO number e.g #1234";
      message += "<br />";
      message += "{{po-vendor}} - The Vendor this PO is currently assigned to";
      message += "<br />";
      message += "{{po-budget}} - The Budget this PO is currently assigned to";
      message += "<br />";
      message += "{{po-assignment}} - The User or Team this PO is currently assigned to";
      message += "<br />";
      message += "{{po-date}} - The date the PO was started";
      message += "<br />";
      message +=
         "{{po-expected-delivery-date}} - The date the PO items are expected to be delivered by.";
      message += "<br />";
      message +=
         "{{po-custom-field-name-1}}, {{po-custom-field-name-2}}... - Up to 6 custom PO field's names.  ";
      message += "<br />";
      message +=
         "{{po-custom-field-data-1}}, {{po-custom-field-data-2}}... - Up to 6 custom PO field's data.  ";
      message += "<br />";
      message += "{{po-total}} - The total cost of the PO";
      message += "<br />";
      message += "{{po-items}} - A table outlining all of the PO items";
      message += "<br />";
      message += "{{po-comments}} - A table outlining all of the Comments left on a PO";
      message += "<br />";
      message +=
         "{{link-to-trigger-next-step}} - A link that when clicked will mark this step as done and move to the next step";
      message += "<br />";
      message +=
         "{{link-to-disapprove}} - A link that when clicked will allow the user to disapprove the PO and set it back to a previous step";
      message += "<br />";
      message +=
         "{{link-to-view-po}} - A link that when clicked will take you to the PO inside Limble's web app";
      message += "<br />";
      message += "<br />";
      message +=
         "<b>Once you have filled in the information below, the next time a PO has this step triggered this email will be sent.</b>";
      message += "<br />";
      message += "<br />";

      return message;
   }

   private getPREmailMessageHint(): string {
      let message;
      message = "<hr />";
      message +=
         "<b>Every time PO items are received Limble will send out an email message with the Bills details.</b>";
      message += "<br />";
      message += "<br />";
      message +=
         "This is helpful for letting co workers know that a PO item(s) have been received and are ready for payment.";
      message += "<br />";
      message += "<br />";
      message +=
         "To help you display data that will change from one Bill to another, we have included placeholder tags that looks like this: {{bill-number}}.  These placeholder tags let you have your emails display the exact information for that Bill.";
      message += "<br />";
      message += "<br />";
      message += "<b>Possible placeholders</b>";
      message += "<br />";
      message += "<br />";
      message += "{{bill-number}} - The Bill Number e.g #21";
      message += "<br />";
      message += "{{bill-vendor}} - The Vendor this Bill was for";
      message += "<br />";
      message += "{{bill-budget}} - The Budget this Bill was for";
      message += "<br />";
      message += "{{bill-date}} - The date of this Bill";
      message += "<br />";
      message += "{{bill-assignment}} - Who the PR is currently assigned to";
      message += "<br />";
      message +=
         "{{po-expected-delivery-date}} - The Purchase Order's expected delivery date";
      message += "<br />";
      message +=
         "{{po-custom-field-name-1}}, {{po-custom-field-name-2}}... - Up to 6 custom PO field's names.  ";
      message += "<br />";
      message +=
         "{{po-custom-field-data-1}}, {{po-custom-field-data-2}}... - Up to 6 custom PO field's data.  ";
      message += "<br />";
      message += "{{bill-items-received}} - The items the Bill has marked as received";
      message += "<br />";
      message += "{{bill-total}} - The total cost of the Items received";
      message += "<br />";
      message += "{{bill-comments}} - A table outlining all of the Comments left on a PR";
      message += "<br />";
      message +=
         "{{link-to-mark-paid}} - A link that when clicked will mark this Bill as paid";
      message += "<br />";
      message +=
         "{{link-to-view-pr}} - A link that when clicked will take you to the Bill inside Limble's web app";
      message += "<br />";
      message += "{{po-number}} - The number this Bill is associated with";
      message += "<br />";
      message += "<br />";
      message +=
         "<b>Once you have filled in the information below, the next time a Bill is submitted this email will be sent.</b>";
      message += "<br />";
      message += "<br />";

      return message;
   }
}
