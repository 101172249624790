import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";

type PartsExtraInfo = Map<number, { totalQty: number }>;

@Component({
   selector: "parts-transfer",
   templateUrl: "./partsTransfer.modal.component.html",
   styleUrls: ["./partsTransfer.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      LoadingAnimationComponent,
      AlertComponent,
      PanelComponent,
      FormsModule,
      FormDropdownInputComponent,
      DropdownTextItemComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
   ],
})
export class PartsTransfer implements OnInit {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public locations;
   public locationSending;
   public locationSentTo;
   public receivingPart;
   public credChangePartQty;
   public errorMsg;
   public part: Part | undefined;
   public partsExtraInfo: PartsExtraInfo = new Map();
   public qty;
   protected contentLoaded: boolean = false;
   protected permissionsErrorMessage: string = "";

   private readonly manageParts = inject(ManageParts);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.errorMsg = "";
      this.qty = 0;
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.part = this.resolve.part;
      this.message = this.resolve.message;
      this.title = this.resolve.title;

      if (this.part === undefined) {
         throw new Error(
            "partsTransfer component requires a `part`, but a valid value was not provided.",
         );
      }

      this.locations = this.manageLocation.getLocations();
      this.locationSending = this.manageLocation.getLocation(this.part.locationID);
      this.locations = this.filterLocations();

      this.locationSentTo = this.locations[0];
      this.credChangePartQty = this.verifyLocation();

      for (const part of this.manageParts.getParts()) {
         const totalQty = this.manageParts.calculatePartData(part)?.totalQty ?? 0;
         this.partsExtraInfo.set(part.partID, { totalQty });
      }

      this.contentLoaded = true;
   }

   public setLocation(location) {
      this.locationSentTo = location;
      this.credChangePartQty = this.verifyLocation();
   }

   verifyQtyPossible = () => {
      assert(this.part);
      const totalQty = this.manageParts.calculatePartData(this.part)?.totalQty ?? 0;
      if (this.qty > totalQty) {
         return false;
      }
      return true;
   };

   verifyLocation = () => {
      assert(this.part);

      if (
         !this.credService.isAuthorized(
            this.locationSentTo?.locationID,
            this.credService.Permissions.ManuallyChangePartQty,
         ) ||
         !this.credService.isAuthorized(
            this.part?.locationID,
            this.credService.Permissions.ManuallyChangePartQty,
         )
      ) {
         this.permissionsErrorMessage = this.lang().cred72Fail;
         return false;
      }

      if (
         !this.credService.isAuthorized(
            this.part?.locationID,
            this.credService.Permissions.ChangePartLocation,
         ) ||
         !this.credService.isAuthorized(
            this.locationSentTo?.locationID,
            this.credService.Permissions.ChangePartLocation,
         )
      ) {
         this.permissionsErrorMessage = this.lang().cred74Fail;
         return false;
      }

      return true;
   };

   filterLocations = () => {
      const availableLocations = this.locations.filter((location) => {
         //need to be able to view parts in that location and edit part amounts
         if (
            !this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.ChangePartLocation,
            ) ||
            !this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.ManuallyChangePartQty,
            )
         ) {
            return false;
         }
         if (location.locationID == this.locationSending.locationID) {
            return false;
         }
         return true;
      });
      return availableLocations;
   };

   getPartAtNewLocation = (part, locationSentTo) => {
      const parts = this.manageParts.getParts();
      const partsArr = Array.from(parts);
      this.receivingPart = undefined;
      partsArr.some((singlePart) => {
         if (
            singlePart.locationID === locationSentTo.locationID &&
            singlePart.partName === part.partName &&
            singlePart.partNumber === part.partNumber &&
            singlePart.partDeleted != 1
         ) {
            assert(this.part);
            this.receivingPart = singlePart;
            this.manageParts.transferParts(
               this.locationSending,
               this.locationSentTo,
               this.part,
               singlePart,
               this.qty,
            );
            return true;
         }
         return false;
      });
      if (this.receivingPart === undefined) {
         if (
            !this.credService.isAuthorized(
               locationSentTo.locationID,
               this.credService.Permissions.AddParts,
            )
         ) {
            this.alertService.addAlert(this.lang().cred82Fail, "danger", 6000);
            return;
         }
         this.manageParts
            .addPart(
               locationSentTo.locationID,
               part.partName,
               part.partNumber,
               part.partPrice,
               0,
            )
            .then((answer) => {
               if (!answer?.data.success) {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               }
               assert(this.part);
               this.receivingPart = answer?.data.part;
               this.manageParts.copyPartImage(this.part.partID, answer?.data.part.partID);
               this.manageParts.transferParts(
                  this.locationSending,
                  this.locationSentTo,
                  this.part,
                  answer?.data.part,
                  this.qty,
               );
               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            });
      }
   };

   submit = () => {
      this.credChangePartQty = this.verifyQtyPossible();
      const locationCredentials = this.verifyLocation();
      if (this.credChangePartQty == true && locationCredentials == true) {
         this.getPartAtNewLocation(this.part, this.locationSentTo);

         this.modalInstance.close();
      }
   };

   close = () => {
      this.modalInstance.close();
   };
}
