export function splitArrayIntoChunks<T>(
   array: Array<T>,
   chunkSize: number,
): Array<Array<T>> {
   const numberOfChunks = Math.ceil(array.length / chunkSize);
   const chunks: Array<Array<T>> = Array.from({ length: numberOfChunks }, () => []);
   array.forEach((element, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      chunks[chunkIndex].push(element);
   });
   return chunks;
}
