<div class="panel">
   <pm-builder-panel-header
      (click)="toggle()"
      [caretDirection]="isExpanded() ? 'up' : 'down'"
   />
   <pm-builder-panel-body
      [@accordion]="isExpanded() ? 'expanded' : 'collapsed'"
      (@accordion.start)="animationEvents$.next($event)"
      (@accordion.done)="animationEvents$.next($event)"
   />
</div>
