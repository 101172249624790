<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />

   <lim-ui-modal-body>
      @if (data && pickInformationToInclude) {
         <lim-ui-checkbox-selection-panel
            [title]="lang().PleaseSelectTheInformationToInclude"
            [error]="checkboxInformationError"
            [tooltip]="lang().ThatAreTooltipCustomDashboard"
            [selectAllLabel]="!data.selectOne ? lang().SelectAll : ''"
            (selectAllClick)="updateUserSettings()"
            [deselectAllLabel]="!data.selectOne ? lang().DeselectAll : ''"
            (deselectAllClick)="updateUserSettings()"
            groupId="task-type-data-selection"
         >
            <ng-container checkbox-list>
               @if (informationToInclude) {
                  <div class="checkbox-list">
                     @for (property of sortedProperties; track property) {
                        <div class="checkbox-container">
                           <lim-ui-checkbox
                              [label]="bulkPrintSettingsLanguageMap.get(property) ?? ''"
                              [(model)]="informationToInclude[property]"
                              (click)="updateUserSettings()"
                              groupId="task-type-data-selection"
                              [labelNoWrap]="true"
                           />
                        </div>
                     }
                  </div>
               }
            </ng-container>
         </lim-ui-checkbox-selection-panel>
      }

      <lim-ui-selection-panel [title]="message" [error]="chooseTaskError">
         <div panel-subtitle>
            @if (data && !data.selectOne) {
               <span class="select-all-options">
                  <a (click)="markAllSelection()" class="text-btn">{{
                     lang().SelectAll
                  }}</a>
                  <div class="text-btn-divider">|</div>
                  <a (click)="clearSelection()" class="text-btn">{{
                     lang().DeselectAll
                  }}</a>
               </span>
            }
         </div>
         <ng-container selection-panel-options>
            <lim-ui-search-box
               [placeholder]="lang().Search"
               [(searchVal)]="searchBar"
               (searchValChange)="updateSearch()"
               class="task-search"
               [focusOnLoad]="true"
            />
         </ng-container>
         <ng-container selection-panel-content>
            @if (noSearchResults) {
               <div>
                  <no-search-results />
               </div>
            }
            <div class="list-container">
               @for (location of locations; track location) {
                  <div>
                     @if (!location.hide) {
                        <div>
                           @if (multiLocation) {
                              <div
                                 (click)="location.showKids = !location.showKids"
                                 class="lim-ui-list-item lim-ui-selectable-list-item"
                              >
                                 <div class="location">
                                    <lim-ui-icon
                                       [icon]="
                                          location.showKids ? 'angleUp' : 'angleDown'
                                       "
                                       iconSize="small"
                                       class="icon-left collapse-toggle"
                                    />
                                    <lim-ui-icon
                                       [icon]="'houseChimney'"
                                       iconSize="small"
                                       class="house-icon"
                                    />
                                    <div>{{ location.locationName }}</div>
                                 </div>
                              </div>
                           }
                           @if (location.showKids == true) {
                              <div
                                 [ngClass]="{
                                    'lim-ui-nested-list-item': multiLocation,
                                 }"
                              >
                                 @for (task of location.tasks; track task) {
                                    <div
                                       (click)="focusTask(task)"
                                       class="lim-ui-list-item lim-ui-selectable-list-item task"
                                       [ngClass]="{
                                          'lim-ui-selected-list-item': tasksExtraInfo.get(
                                             task.checklistID
                                          )?.selected,
                                       }"
                                    >
                                       <div class="list-item-content">
                                          <div
                                             class="list-item-content-group task-status"
                                             [ngStyle]="{
                                                color: priorityListIndex[task.priorityID]
                                                   ? priorityListIndex[task.priorityID]
                                                        .color
                                                   : 'var(--lim-el-no-priority-color)',
                                             }"
                                          >
                                             @if (
                                                tasksExtraInfo.get(task.checklistID)
                                                   ?.selected
                                             ) {
                                                <lim-ui-icon
                                                   icon="check"
                                                   iconSize="small"
                                                   iconColor="primary"
                                                   class="icon-left"
                                                />
                                             }
                                             <lim-ui-icon
                                                icon="circleExclamation"
                                                iconSize="small"
                                                class="icon-left"
                                             />
                                             <div class="priority-level-value">
                                                {{
                                                   tasksExtraInfo.get(task.checklistID)
                                                      ?.priorityLevel
                                                }}
                                             </div>
                                          </div>

                                          <div
                                             class="list-item-content-group task-summary"
                                          >
                                             @if (
                                                tasksExtraInfo.get(task.checklistID)
                                                   ?.taskIcon
                                             ) {
                                                <lim-ui-icon
                                                   [icon]="
                                                      tasksExtraInfo.get(task.checklistID)
                                                         ?.taskIcon ?? 'notdef'
                                                   "
                                                   iconSize="small"
                                                   class="icon-left"
                                                />
                                             }

                                             <div>
                                                <span
                                                   [limbleHtml]="task.checklistName"
                                                ></span>
                                                - #{{ task.checklistID }}

                                                @if (task.assetID != 0) {
                                                   <span>
                                                      @if (
                                                         assets.get(task.assetID);
                                                         as taskAsset
                                                      ) {
                                                         <span>
                                                            &nbsp;-&nbsp;
                                                            <span
                                                               [limbleHtml]="
                                                                  taskAsset.assetName
                                                               "
                                                               class="asset-name"
                                                            ></span>
                                                            @if (task.assetID) {
                                                               <asset-parent-list
                                                                  [assetID]="task.assetID"
                                                                  linkColorClass="green-color"
                                                               />
                                                            }
                                                         </span>
                                                      }

                                                      @if (task.extraAssetIDs?.length) {
                                                         @for (
                                                            taskExtraAssetID of task.extraAssetIDs;
                                                            track taskExtraAssetID
                                                         ) {
                                                            <span>
                                                               @if (
                                                                  assets.get(
                                                                     taskExtraAssetID
                                                                  );
                                                                  as extraAsset
                                                               ) {
                                                                  <span>
                                                                     &nbsp;-&nbsp;
                                                                     <span
                                                                        [limbleHtml]="
                                                                           extraAsset.assetName
                                                                        "
                                                                        class="asset-name"
                                                                     ></span>
                                                                     <asset-parent-list
                                                                        [assetID]="
                                                                           extraAsset.assetID
                                                                        "
                                                                        linkColorClass="green-color"
                                                                        [hover]="false"
                                                                     />
                                                                  </span>
                                                               }
                                                            </span>
                                                         }
                                                      }
                                                   </span>
                                                }
                                             </div>
                                          </div>

                                          @if (
                                             task.checklistCompletedDate == 0 &&
                                             tasksExtraInfo.get(task.checklistID)
                                          ) {
                                             <div
                                                class="list-item-content-group task-date"
                                             >
                                                {{
                                                   tasksExtraInfo.get(task.checklistID)
                                                      ?.checklistDueDateDisplay
                                                }}

                                                <span
                                                   class="badge"
                                                   [ngClass]="{
                                                      'danger-badge':
                                                         task.checklistColor * 1 == 3 ||
                                                         task.checklistColor * 1 == 0,
                                                      'warning-badge':
                                                         task.checklistColor * 1 == 2,
                                                      'success-badge':
                                                         task.checklistColor * 1 == 1,
                                                   }"
                                                   placement="top"
                                                   limUiTooltip="{{
                                                      tasksExtraInfo.get(task.checklistID)
                                                         ?.days +
                                                         ' ' +
                                                         tasksExtraInfo.get(
                                                            task.checklistID
                                                         )?.daysMsg
                                                   }}"
                                                >
                                                   {{
                                                      tasksExtraInfo.get(task.checklistID)
                                                         ?.days
                                                   }}
                                                </span>
                                             </div>
                                          }

                                          @if (tasksExtraInfo.get(task.checklistID)) {
                                             <div
                                                class="list-item-content-group task-assignee"
                                                [limbleHtml]="
                                                   tasksExtraInfo.get(task.checklistID)
                                                      ?.displayName
                                                "
                                             ></div>
                                          }

                                          <div class="list-item-content-group view-task">
                                             <a
                                                (click)="
                                                   popTask(task); $event.stopPropagation()
                                                "
                                                limUiTooltip="{{
                                                   lang().ViewThisTasksDetails
                                                }}"
                                                class="view-task-btn text-btn"
                                             >
                                                {{ lang().View }}
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                 }
                              </div>
                           }
                        </div>
                     }
                  </div>
               }
            </div>
         </ng-container>
      </lim-ui-selection-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Select }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
