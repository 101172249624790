<div class="purchasing-item-list-short-container">
   @if (showButton) {
      <lim-ui-minimal-icon-button
         class="chevron-button"
         (click)="hide = !hide"
         [icon]="!hide ? 'chevronDown' : 'chevronRight'"
      />
   }
   @if (!hide) {
      <div class="main-short-list-container">
         @for (item of augmentedPurchaseOrderItems; track item) {
            @if (item && item.itemType !== 0) {
               <div
                  class="purchasing-item-list-short-container"
                  (click)="popPart(item.partID); $event.stopPropagation()"
               >
                  <div class="purchasing-column-badge">{{ item.qty }}</div>

                  @if (item.itemType === 1) {
                     @let $purchasableSnapshot = item.purchasableSnapshot();
                     @if (
                        isUnitOfMeasureEnabled() &&
                        $purchasableSnapshot !== null &&
                        $purchasableSnapshot !== undefined
                     ) {
                        <unit-label
                           class="purchasable-conversion"
                           [unitDisplay]="$purchasableSnapshot.orderUnitDisplay"
                        />
                     }
                     <lim-ui-icon icon="gears" />
                  }
                  @if (item.itemType === 2) {
                     <lim-ui-icon icon="wpforms" />
                  }
                  @if (item.itemType === 4) {
                     <lim-ui-icon icon="superpowers" />
                  }
                  <div class="short-list-item-name-and-question-icon">
                     @let $itemName = item.itemName();
                     @let $itemNumber = item.itemNumber();
                     @if ($itemName !== null && $itemName !== undefined) {
                        <div class="purchasing-item-name">
                           <a class="table-link" [limbleHtml]="$itemName"></a>
                        </div>
                     }
                     @if ($itemNumber !== null && $itemNumber !== undefined) {
                        <div class="item-number" [limbleHtml]="$itemNumber"></div>
                     }
                  </div>
               </div>
            }
         }
      </div>
   }
</div>
