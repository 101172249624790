import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import { StatusButtonComponent } from "src/app/tasks/components/status-button/status-button.component";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "task-status-cell",
   templateUrl: "./task-status-cell-renderer.component.html",
   imports: [StatusButtonComponent],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskStatusCellRendererComponent extends BaseCellRendererComponent<TaskDataViewerViewModel> {
   private readonly credService = inject(CredService);

   protected get isSuperUser(): boolean {
      return this.credService.checkCredGlobal(30);
   }

   protected get isTaskCompleted(): boolean {
      return this.cell?.isCompleted ?? false;
   }

   protected get taskId(): number {
      return this.cell?.checklistID ?? 0;
   }

   protected get statusId(): number | null {
      return this.cell?.statusID ?? null;
   }

   protected get locationId(): number {
      return this.cell?.locationID ?? 0;
   }
}
