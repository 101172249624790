import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { isMobile } from "@limblecmms/lim-ui";
import type { Observable } from "rxjs";
import { Subject } from "rxjs";
import { filter, first, map, shareReplay } from "rxjs/operators";
import { EnvRoutesService } from "src/app/shared/services/envRoutes.service";
import type {
   LimbleWebsocketMessage,
   WebsocketConnectionAck,
   WebsocketMessage,
} from "src/app/shared/types/websocket.types";
import { UsersApiService } from "src/app/users/services/users-api-service";

@Injectable({ providedIn: "root" })
export class WebsocketService {
   /** The raw socket, not wrapped in RxJs */
   private basicSocket: WebSocket | undefined;

   /**
    * A subject that carries all messages from the server, including housekeeping
    * messages like ACK. `messages$` is piped from this subject.
    */
   private readonly socket$: Subject<WebsocketMessage>;

   /**
    * The observable through which all our websocket messages will
    * flow. It is the font of all server-side knowledge and wisdom ;)
    */
   public readonly messages$: Observable<LimbleWebsocketMessage>;

   /**
    * Emits the connectionId after the socket has been initialized. If the socket
    * is already initialized upon subscription, the subscription will run with the
    * previously-obtained connectionId
    */
   public readonly socketReady$: Observable<string>;

   /** The identifier for the websocket connection. */
   private connectionId: string | undefined;

   /**
    * The identifier for the current user session on a particular agent.
    * For example, if a user has 3 chrome tabs open and all of them are running Limble,
    * they will all have the same sessionID. If the user then opens a Limble tab in a
    * second browser, that new instance of the app will have a different sessionID.
    */
   private sessionId: string | undefined;

   // Do not inject any of our god services like manageUtil into this service.
   // Many parts of the webApp will likely need access to the socket connection, and we don't want circular dependencies
   private readonly envRoutesService = inject(EnvRoutesService);
   private readonly http = inject(HttpClient);
   private readonly usersApiService = inject(UsersApiService);

   public constructor() {
      this.socket$ = new Subject();
      this.socketReady$ = this.socket$.pipe(
         filter((msg): msg is WebsocketConnectionAck => "connectionId" in msg),
         map((msg) => msg.connectionId),
         filter((connectionId): connectionId is string => connectionId !== undefined),
         first(),
         shareReplay(1),
      );
      this.socketReady$.subscribe((connectionId) => {
         // the first response back from lambda will give aws apigateway's connectionId
         this.runInitialConnections(connectionId);
      });
      this.messages$ = this.socket$.pipe(
         filter((msg): msg is LimbleWebsocketMessage => "action" in msg),
      );

      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;

      setInterval(() => {
         //socket health check -> https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
         if (this.basicSocket?.readyState != 1) {
            this.refreshSocket();
            return;
         }

         //need the socket to be active every 10 minutes otherwise aws will close it
         this.basicSocket?.send(
            JSON.stringify({
               type: "ping",
            }),
         );
      }, minute);

      //aws api gateway doesn't allow a socket to stay open more than 2 hours at a time
      setInterval(() => {
         this.refreshSocket();
      }, hour * 1.99);

      this.refreshSocket();
   }

   private refreshSocket(): void {
      if (this.basicSocket) {
         //code 1000: normal closure -> https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#Status_codes
         this.basicSocket.close(1000);
      }

      this.envRoutesService.getRoutes().then((routes) => {
         if (!routes.websocketUrl) {
            console.error(
               "Skipping websocket connection because websocketUrl is not defined",
            );
            return;
         }

         this.basicSocket = new WebSocket(routes.websocketUrl);

         //wait for the connection to finish opening before sending the first ping - which allows us to grab the connectionId
         this.basicSocket.addEventListener("open", () => {
            setTimeout(() => {
               this.basicSocket?.send(
                  JSON.stringify({
                     type: "getConnectionId",
                  }),
               );
            });
         });
         this.basicSocket.addEventListener("message", (event) => {
            try {
               const message = JSON.parse(event.data);
               this.socket$.next(message);
            } catch (exception: any) {
               if (exception instanceof SyntaxError) {
                  console.error("Error parsing websocket message", event.data);
               } else {
                  throw exception;
               }
            }
         });
      });
   }

   /** Creates a record in the dynamoDb, representing the fact that this user has a websocket connection */
   private async sendInitial(): Promise<void> {
      const { userID, customerID } = await this.usersApiService.getSelf();
      this.basicSocket?.send(
         JSON.stringify({
            type: "initial",
            connectionId: this.connectionId,
            sessionID: this.sessionId,
            mobile: isMobile() ? "true" : "false",
            userID: Number(userID),
            customerID: Number(customerID),
         }),
      );
   }

   /** Runs setup logic. Intended to run as soon as the websocket is connected */
   private runInitialConnections(connectionId: string): void {
      this.connectionId = connectionId;
      this.setSessionID();
      this.sendInitial();
   }

   /** each tab has a separate connection. the session id allows us to target all tabs in one browser */
   private setSessionID(): void {
      if (!localStorage.sid) {
         localStorage.sid = this.connectionId;
      }
      this.sessionId = localStorage.sid;
   }

   public getConnectionId(): string | undefined {
      return this.connectionId;
   }

   public getSessionID(): string | undefined {
      return this.sessionId;
   }
}
