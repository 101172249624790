import { Component } from "@angular/core";
import { TranslocoDirective } from "@jsverse/transloco";
import { DropdownButtonComponent, SearchBoxComponent } from "@limblecmms/lim-ui";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";

@Component({
   selector: "id-filter",
   imports: [SearchBoxComponent, TranslocoDirective, DropdownButtonComponent],
   templateUrl: "./id-filter.component.html",
   styleUrls: ["./id-filter.component.scss"],
})
export class IdFilterComponent extends BaseFilterComponent {
   protected activeFilterLabel?: string | undefined;

   public constructor() {
      super();
   }

   public handleSearch(ids: string | undefined) {
      if (!ids) return;

      const labelKey = this.filter().labelKey;
      this.activeFilterLabel = `${this.translateService.instant(labelKey)} - ${ids}`;

      const filter = { [this.filter().key]: this.convertToArray(ids) };
      this.setFilter(filter, this.activeFilterLabel);
   }

   public handleClear() {
      this.activeFilterLabel = undefined;
      this.remove.emit(this.filter());
   }

   private setFilter(value, activeLabel: string): void {
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value,
      });
   }

   private convertToArray(value: string | undefined): string[] {
      return value ? value.split(",") : [];
   }
}
