{
   "Assets": [
      "asset name",
      "asset",
      "parent asset",
      "parent asset id",
      "asset id",
      "assetid",
      "asset to update",
      "asset to update (assetid or asset name)",
      "location name",
      "location id",
      "asset started on",
      "manually associated parts",
      "manually associated vendors"
   ],
   "Vendors": [
      "vendor name",
      "parent vendor",
      "parent vendor id",
      "vendor id",
      "location name",
      "location id",
      "vendor email",
      "email",
      "phone",
      "contact",
      "address"
   ],
   "Parts": [
      "part name",
      "part id",
      "location",
      "part number",
      "quantity",
      "price",
      "category",
      "vendor",
      "part qty threshold",
      "min part qty threshold",
      "minimum part qty threshold",
      "stale threshold",
      "part stale status",
      "lastcounted",
      "understocked",
      "max part qty threshold",
      "threshold tasks assigned to (users email or a team name)",
      "manually associated assets",
      "manually associated vendors",
      "stock unit"
   ]
}
