import { inject, Injectable } from "@angular/core";
import { single, type Observable } from "rxjs";
import { InvalidPurchasableError } from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/errors/invalid-purchasable.error";
import type {
   RequestParams,
   ValidatePurchasableParams,
} from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/part-purchasable-add-modal.service.params";
import { CountedAndMeasuredPurchasable } from "src/app/parts/purchasable/counted-and-measured-purchasable.model";
import { CountedPurchasable } from "src/app/parts/purchasable/counted-purchasable.model";
import { MeasuredPurchasable } from "src/app/parts/purchasable/measured-purchasable.model";
import { PurchasableApiService } from "src/app/parts/purchasable/purchasable-api.service";
import type {
   CreatePurchasableBodyDto,
   PurchasableDto,
} from "src/app/parts/purchasable/purchasable.dto.types";
import type { Purchasable } from "src/app/parts/purchasable/purchasable.model";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { assert } from "src/app/shared/utils/assert.utils";

@Injectable({ providedIn: "root" })
export class PartPurchasableAddModalService {
   private readonly purchasableApiService = inject(PurchasableApiService);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);

   public validatePurchasable({
      name,
      orderUnit,
      partID,
      size,
      sizeUnit,
      purchasableID,
      purchasableType,
   }: ValidatePurchasableParams): Purchasable {
      assert(name.length > 1, new InvalidPurchasableError("name"));
      assert(orderUnit !== null, new InvalidPurchasableError("orderUnit"));

      const orderUnitDescription =
         this.unitOfMeasureService.convertUnitToDescription(orderUnit);
      const id = purchasableID ?? -1;
      switch (purchasableType) {
         case "measured": {
            return new MeasuredPurchasable({
               id,
               partID,
               name,
               orderUnitDescription,
            });
         }
         case "counted": {
            return new CountedPurchasable({
               id,
               partID,
               name,
               orderUnitDescription,
               size: this.parseSize(size),
            });
         }
         case "counted-and-measured": {
            assert(sizeUnit !== null, new InvalidPurchasableError("sizeUnit"));
            return new CountedAndMeasuredPurchasable({
               id,
               partID,
               name,
               orderUnitDescription,
               size: this.parseSize(size),
               providedSizeUnitID: sizeUnit.id,
            });
         }
         default: {
            throw new InvalidPurchasableError();
         }
      }
   }

   public requestCreate({
      partID,
      purchasable,
   }: RequestParams): Observable<PurchasableDto> {
      const dto = this.convertPurchasableToCreateDto(purchasable);
      return this.purchasableApiService.createPurchasable(partID, dto).pipe(single());
   }

   public requestEdit({
      partID,
      purchasable,
   }: RequestParams): Observable<PurchasableDto> {
      const dto = this.convertPurchasableToCreateDto(purchasable);
      return this.purchasableApiService
         .updatePurchasable(partID, purchasable.id, dto)
         .pipe(single());
   }

   private parseSize(size: number | null): number {
      assert(size !== null, new InvalidPurchasableError("size"));
      return size;
   }

   private convertPurchasableToCreateDto(
      purchasable: Purchasable,
   ): CreatePurchasableBodyDto {
      return {
         name: purchasable.name(),
         orderUnitDescription: purchasable.orderUnitDescription,
         size: this.getSize(purchasable),
         providedSizeUnitID:
            purchasable instanceof CountedAndMeasuredPurchasable
               ? purchasable.providedSizeUnitID
               : undefined,
      };
   }

   private getSize(purchasable: Purchasable): number | undefined {
      const hasSize =
         purchasable instanceof CountedPurchasable ||
         purchasable instanceof CountedAndMeasuredPurchasable;
      return hasSize ? purchasable.size : undefined;
   }
}
