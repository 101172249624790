import { inject, Injectable } from "@angular/core";
import { PartsApiService } from "src/app/parts/components/shared/parts-api-service";
import type { PartEntity } from "src/app/parts/components/shared/parts-api-service/parts-api.models";
import { DataAccumulatorService } from "src/app/parts/components/shared/services/data-accumulator/data-accumulator.service";

@Injectable()
export class PartsDataAccumulatorService extends DataAccumulatorService<PartEntity> {
   protected override apiService = inject(PartsApiService);

   protected override compareEntities(
      firstObject: PartEntity,
      secondObject: PartEntity,
   ): boolean {
      return firstObject.partID === secondObject.partID;
   }
}
