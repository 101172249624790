import { NgClass } from "@angular/common";
import {
   Component,
   computed,
   effect,
   inject,
   input,
   output,
   signal,
   type Signal,
   type WritableSignal,
} from "@angular/core";
import {
   IconComponent,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
} from "@limblecmms/lim-ui";
import { lastValueFrom } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { TranslationService } from "src/app/languages/translation/translation.service";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import type { NotUndefined } from "src/app/shared/types/utility-types";
import {
   TaskTemplatesApiService,
   type TaskTemplateEntity,
} from "src/app/tasks/components/shared/services/task-templates-api";

@Component({
   selector: "field-selection",
   templateUrl: "./field-selection.component.html",
   styleUrls: ["./field-selection.component.scss"],
   standalone: true,
   imports: [NgClass, IconComponent, LimbleHtmlDirective, LoadingAnimationComponent],
})
export class FieldSelectionComponent {
   public readonly recurrence = input.required<Recurrence>();
   public readonly field = output<AssetField>();
   public readonly fieldValue = output<AssetFieldValue | null>();

   private readonly manageAsset = inject(ManageAsset);
   protected readonly i18n = inject(TranslationService).i18n;
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);

   public readonly taskTemplate: WritableSignal<TaskTemplateEntity | undefined> =
      signal(undefined);

   public selectedField: WritableSignal<AssetField | null> = signal(null);
   public readonly fieldToReturn: Signal<AssetField | null> = computed(() => {
      const masterField = this.masterField();
      if (masterField === null || masterField === undefined) return this.selectedField();
      return masterField;
   });
   public readonly masterField = input<AssetField | null | undefined>();
   public assetFieldValues: Signal<Array<AssetFieldValue> | null> = computed(() => {
      const taskTemplate = this.taskTemplate();
      if (taskTemplate === undefined) return null;
      const asset = this.manageAsset.getAsset(Number(taskTemplate.assetID));
      if (asset === undefined) {
         return [];
      }
      return asset.assetValueIDs
         .map((valueID) => this.manageAsset.getFieldValue(valueID))
         .filter(
            (value): value is NotUndefined<typeof value> =>
               value !== undefined &&
               this.manageAsset.getField(value.fieldID) !== undefined,
         );
   });
   public assetFields: Signal<Array<AssetField> | null> = computed(() => {
      const assetFieldValues = this.assetFieldValues();
      if (assetFieldValues === null) return null;
      return orderBy(
         assetFieldValues
            .map((value) => this.manageAsset.getField(value.fieldID))
            .filter(
               (field): field is NotUndefined<typeof field> =>
                  field !== undefined && field.fieldTypeID === 5,
            ),
         "fieldName",
      );
   });
   public selectedFieldValue: Signal<AssetFieldValue | null> = computed(() => {
      const taskTemplate = this.taskTemplate();
      const assetFieldValues = this.assetFieldValues();
      if (taskTemplate === undefined || assetFieldValues === null) return null;
      if (taskTemplate.assets.length > 1) {
         // If the task template has more than one asset, there are probably
         // multiple fields to choose from. Rather than confuse the user,
         // we'll just return null.
         return null;
      }
      const field = this.selectedField();
      if (field === null) return null;
      const valueForSelectedField = assetFieldValues.find(
         (value) => value.fieldID === field.fieldID,
      );
      if (valueForSelectedField === undefined) return null;
      return valueForSelectedField;
   });

   public constructor() {
      this.selectedField.set(this.masterField() ?? null);
      effect(
         () => {
            const assetFieldValues = this.assetFieldValues();
            if (assetFieldValues === null) return;
            const assetFields = this.assetFields();
            if (assetFields === null) return;
            const recurrence = this.recurrence();
            if (
               recurrence.reoccurFld1 !== null &&
               typeof recurrence.reoccurFld1 !== "string"
            ) {
               throw new Error("Recurrence field 1 is not valid");
            }
            const selectedField: AssetField | undefined = recurrence.reoccurFld1
               ?.split(",")
               .filter((valueID) => valueID !== "" && valueID !== "0")
               .map((valueIDStr) => {
                  const value = assetFieldValues.find(
                     (val) => val.valueID === Number(valueIDStr),
                  );
                  if (value === undefined) {
                     return undefined;
                  }
                  return assetFields.find((fld) => fld.fieldID === value.fieldID);
               })
               .find((field) => field !== undefined);
            this.selectedField.set(selectedField ?? null);
         },
         { allowSignalWrites: true },
      );
      effect(() => {
         const field = this.selectedField();
         if (field === null) return;
         this.field.emit(field);
         //this.selectedField.set(null);
      });
      effect(
         () => {
            const recurrence = this.recurrence();
            lastValueFrom(
               this.taskTemplatesApiService.getById(recurrence.checklistID, {
                  columns: `assets`,
               }),
            ).then((taskTemplate) => {
               this.taskTemplate.set(taskTemplate);
            });
         },
         { allowSignalWrites: true },
      );
      effect(() => {
         const fieldValue = this.selectedFieldValue();
         if (fieldValue === null) return;
         this.fieldValue.emit(fieldValue);
      });
   }
}
