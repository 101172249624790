@if (asset) {
   <lim-ui-entity-viewer-modal
      description-color-class="green"
      [entityTitle]="asset.assetName"
      watermarkIcon="cube"
      [showCloseModalButton]="showCloseModalButton"
      (closeModalEvent)="emitCloseModal()"
      class="scroll-height-inheritance"
      data-test-id="assetModal"
   >
      <ng-container header-title>
         <div class="header-wrapper">
            <span [limbleHtml]="asset.assetName" data-test-id="assetModalTitle"></span>

            @if (asset.assetDeleted === 1) {
               <span
                  class="restore-asset-banner"
                  [ngClass]="{ 'clickable-banner': restoreAssetCred }"
                  (click)="restoreAsset()"
               >
                  {{ lang().AssetWasDeleted }}

                  @if (restoreAssetCred) {
                     <span
                        class="lim-ui-hide-on-mobile"
                        data-test-id="assetModalRestoreAssetButton"
                     >
                        {{ lang().ClickHereToRestore }}
                     </span>
                  }
               </span>
            }
         </div>
      </ng-container>
      <ng-container entity-image>
         @if (uploadObj) {
            <image-tile
               [uploadObject]="uploadObj"
               [image]="assetImageObj"
               [canEdit]="!uploadObj.viewOnly"
               type="medium"
               data-test-id="assetModalImageTile"
            />
         }
      </ng-container>

      <ng-container header-buttons>
         <lim-ui-minimal-icon-button
            class="lim-ui-hide-on-mobile"
            icon="qrCodeRegular"
            iconSize="medium-large"
            limUiTooltip="{{
               lang().PrintQTCodesForReportingAProblemAndLookUpAnAssetTooltip
            }}"
            (click)="viewQRCodes(asset)"
            data-test-id="assetModalQrCodeButton"
         />
         @if (creds.editAssetSettings && asset.assetDeleted === 0 && !restrict) {
            <lim-ui-minimal-icon-button
               icon="gearRegular"
               iconSize="medium-large"
               (click)="settings()"
               limUiTooltip="{{ lang().Settings }}"
               placement="top"
               data-test-id="assetModalSettingsButton"
            />
         }
      </ng-container>

      <ng-template #buttonDownload>
         <lim-ui-icon-button
            class="lim-ui-hide-on-mobile"
            (click)="downloadAssetsInfo()"
            icon="cloudDownloadRegular"
            limUiTooltip="{{ asset.assetName }} - {{
               lang().DownloadAssetsInformationViaExcel
            }}"
            placement="top"
            attr.target="assetInfo{{ asset.assetID }}"
            data-test-id="assetModalDownloadButton"
         />
      </ng-template>
      <ng-template #buttonPrint>
         <lim-ui-icon-button
            class="lim-ui-hide-on-mobile"
            print-div
            icon="printRegular"
            limUiTooltip="{{ asset.assetName }} - {{ lang().PrintAssetInformationTab }}"
            placement="left"
            attr.target="assetInfo{{ asset.assetID }}"
            data-test-id="assetModalPrintButton"
         />
      </ng-template>
      <ng-template #buttonWatchTutorial>
         <lim-ui-icon-button
            class="lim-ui-hide-on-mobile"
            (click)="watchTutorial()"
            iconColor="youtube-red"
            icon="youtube"
            limUiTooltip="{{ lang().tutorialAssetCardTooltip }}"
            placement="left"
            data-test-id="assetModalWatchTutorialButton"
         />
      </ng-template>

      <ng-container entity-viewer>
         @if (credToViewAsset) {
            <div class="scroll-height-inheritance">
               <div class="tab-bar-container">
                  <lim-ui-tab-bar
                     [selectedTabId]="selectedTabId"
                     [tabList]="pageTabs"
                     (selectedTabIdChange)="updateSelectedTab($event)"
                     class="tab-bar"
                  >
                     @if (selectedTabId === pageTabs[0].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">{{
                              lang().InformationAssetTabIntro
                           }}</span>
                           <span class="right-align-buttons">
                              <ng-container *ngTemplateOutlet="buttonDownload" />
                              <ng-container *ngTemplateOutlet="buttonPrint" />
                              <ng-container *ngTemplateOutlet="buttonWatchTutorial" />
                           </span>
                        </div>
                     }

                     @if (selectedTabId === pageTabs[1].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">{{
                              lang().AssetTabPlannedMaintenanceMsg
                           }}</span>
                           <div class="right-align-buttons">
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 icon="printRegular"
                                 print-div
                                 attr.target="assetPMPrint{{ asset.assetID }}"
                                 limUiTooltip="{{ asset.assetName }} - {{
                                    lang().PrintAssetPMTab
                                 }}"
                                 placement="left"
                              />
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 iconColor="youtube-red"
                                 icon="youtube"
                                 [limUiTooltip]="
                                    lang().tutorialAssetCardSettingUpPMTooltip
                                 "
                                 placement="left"
                                 (click)="watchPMTutorial()"
                              />
                           </div>
                        </div>
                     }

                     @if (selectedTabId === pageTabs[2].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">{{
                              lang().AssetTabWorkOrdersHeadingMsg
                           }}</span>
                           <div class="right-align-buttons">
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 icon="printRegular"
                                 print-div
                                 attr.target="assetWOPrint{{ asset.assetID }}"
                                 limUiTooltip="{{ asset.assetName }} - {{
                                    lang().PrintAssetWOTab
                                 }}"
                                 placement="left"
                              />
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 iconColor="youtube-red"
                                 icon="youtube"
                                 [limUiTooltip]="
                                    lang().tutorialAssetCardWorkOrderTabTooltip
                                 "
                                 placement="left"
                                 (click)="watchWOTutorial()"
                              />
                           </div>
                        </div>
                     }

                     @if (selectedTabId === pageTabs[3].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">{{
                              lang().BelowIsAListOfSparePartsAssociatedWithThisAsset
                           }}</span>
                        </div>
                     }

                     @if (selectedTabId === pageTabs[4].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">{{
                              lang().BelowIsAListOfVendorsThisAssetIsAssociatedWith
                           }}</span>
                        </div>
                     }

                     @if (selectedTabId === pageTabs[5].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">{{
                              lang().AssetCardCheckInOutTabHeadingMsg
                           }}</span>
                           <span class="right-align-buttons">
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 icon="printRegular"
                                 print-div
                                 attr.target="assetCheckInOutPrint{{ asset.assetID }}"
                                 limUiTooltip="{{ lang().PrintCheckInOutTab }}"
                                 placement="left"
                              />
                           </span>
                        </div>
                     }

                     @if (selectedTabId === pageTabs[6].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">{{
                              lang().AssetTabLogHeadingMsg
                           }}</span>
                           <span class="right-align-buttons">
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 icon="printRegular"
                                 print-div
                                 attr.target="assetLogPrint{{ asset.assetID }}"
                                 limUiTooltip="{{ asset.assetName }} - {{
                                    lang().PrintAssetLogTab
                                 }}"
                                 placement="left"
                              />
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 iconColor="youtube-red"
                                 icon="youtube"
                                 [limUiTooltip]="lang().tutorialAssetCardLogTabTooltip"
                                 placement="left"
                                 (click)="watchLogTutorial()"
                              />
                           </span>
                        </div>
                     }

                     @if (selectedTabId === pageTabs[7].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">
                              {{ lang().AssetTabReportsHeadingMsg }}
                           </span>
                           <span class="right-align-buttons">
                              @if (asset) {
                                 <lim-ui-icon-button
                                    class="lim-ui-hide-on-mobile"
                                    limUiTooltip="{{ asset.assetName }} - {{
                                       lang().PrintAssetReportsTab
                                    }}"
                                    icon="printRegular"
                                    print-div
                                    attr.target="assetReportsPrint{{ asset.assetID }}"
                                 />
                              }
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 icon="youtube"
                                 iconColor="youtube-red"
                                 (click)="watchAssetReportsTutorial()"
                                 limUiTooltip="{{
                                    lang().tutorialAssetCardAssetTabTooltip
                                 }}"
                              />
                           </span>
                        </div>
                     }

                     @if (selectedTabId === pageTabs[8].id) {
                        <div tabHeader class="tab-bar-content">
                           <span class="tab-header-message">
                              {{ lang().BelowIsAListOfChildrenAssetsFor }}&nbsp;<span
                                 [limbleHtml]="asset.assetName"
                              ></span
                              >.&nbsp;{{ lang().AssetTabChildrenHint }}.
                           </span>

                           <span class="right-align-buttons">
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 (click)="downloadChildren()"
                                 icon="cloudDownloadRegular"
                                 limUiTooltip="{{ lang().ExportChildAssets }}"
                                 placement="top"
                              />
                              <lim-ui-icon-button
                                 class="lim-ui-hide-on-mobile"
                                 icon="youtube"
                                 iconColor="youtube-red"
                                 (click)="watchAssetChildrenTutorial()"
                                 limUiTooltip="{{
                                    lang().tutorialAssetCardChildrenTabTooltip
                                 }}"
                              />
                           </span>
                        </div>
                     }
                  </lim-ui-tab-bar>
               </div>

               @if (scrollableContent) {
                  <lim-ui-scroll-container [thin]="true">
                     <ng-container *ngTemplateOutlet="mainContent" />
                  </lim-ui-scroll-container>
               } @else {
                  <div class="main-content">
                     @if (selectedTabId === pageTabs[0].id) {
                        <div>
                           <asset-information-tab
                              [asset]="asset"
                              [preventParentAccess]="preventParentAccess"
                              [restrict]="restrict"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[1].id) {
                        <div>
                           <asset-preventative-maintenance-tab
                              [assetID]="asset.assetID"
                              [restrict]="restrict"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[2].id) {
                        <div>
                           <asset-work-order-tab
                              [assetID]="asset.assetID"
                              [restrict]="restrict"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[3].id) {
                        <div>
                           <asset-parts-tab [assetID]="asset.assetID" />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[4].id) {
                        <div>
                           <asset-vendors-tab
                              [asset]="asset"
                              [dataLogOptions]="dataLogOptions"
                              [restrict]="restrict"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[5].id) {
                        <div>
                           <asset-check-in-out-tab [assetID]="asset.assetID" />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[6].id) {
                        <div>
                           <asset-log-tab
                              [assetID]="asset.assetID"
                              [restrict]="restrict"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[7].id) {
                        <div>
                           @if (isJitCompletedTasksAssetsPhase2Enabled()) {
                              <asset-report-tab
                                 [assetID]="asset.assetID"
                                 [restrict]="restrict"
                              />
                           } @else {
                              <asset-report-tab-legacy
                                 [assetID]="asset.assetID"
                                 [restrict]="restrict"
                              />
                           }
                        </div>
                     }

                     @if (selectedTabId === pageTabs[8].id) {
                        <div>
                           <asset-children-tab [asset]="asset" [restrict]="restrict" />
                        </div>
                     }
                  </div>
               }

               <ng-template #mainContent>
                  <div class="main-content">
                     @if (selectedTabId === pageTabs[0].id) {
                        <div>
                           <asset-information-tab
                              [asset]="asset"
                              [preventParentAccess]="preventParentAccess"
                              [restrict]="restrict"
                              data-test-id="assetModalInformationTab"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[1].id) {
                        <div>
                           <asset-preventative-maintenance-tab
                              [assetID]="asset.assetID"
                              [restrict]="restrict"
                              data-test-id="assetModalPreventativeMaintenanceTab"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[2].id) {
                        <div>
                           <asset-work-order-tab
                              [assetID]="asset.assetID"
                              [restrict]="restrict"
                              data-test-id="assetModalWorkOrderTab"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[3].id) {
                        <div>
                           @if (isJitCompletedTasksAssetsEnabled()) {
                              <asset-parts-tab
                                 [assetID]="asset.assetID"
                                 data-test-id="assetModalPartsTab"
                              />
                           } @else {
                              <asset-parts-tab-legacy
                                 [assetID]="asset.assetID"
                                 data-test-id="assetModalPartsTabLegacy"
                              />
                           }
                        </div>
                     }

                     @if (selectedTabId === pageTabs[4].id) {
                        <div>
                           <asset-vendors-tab
                              [asset]="asset"
                              [dataLogOptions]="dataLogOptions"
                              [restrict]="restrict"
                              data-test-id="assetModalVendorsTab"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[5].id) {
                        <div>
                           <asset-check-in-out-tab
                              [assetID]="asset.assetID"
                              data-test-id="assetModalCheckInOutTab"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[6].id) {
                        <div>
                           <asset-log-tab
                              [assetID]="asset.assetID"
                              [restrict]="restrict"
                              data-test-id="assetModalLogTab"
                           />
                        </div>
                     }

                     @if (selectedTabId === pageTabs[7].id) {
                        <div>
                           @if (isJitCompletedTasksAssetsPhase2Enabled()) {
                              <asset-report-tab
                                 [assetID]="asset.assetID"
                                 [restrict]="restrict"
                                 data-test-id="assetModalReportTab"
                              />
                           } @else {
                              <asset-report-tab-legacy
                                 [assetID]="asset.assetID"
                                 [restrict]="restrict"
                                 data-test-id="assetModalReportTabLegacy"
                              />
                           }
                        </div>
                     }

                     @if (selectedTabId === pageTabs[8].id) {
                        <div>
                           <asset-children-tab
                              [asset]="asset"
                              [restrict]="restrict"
                              data-test-id="assetModalChildrenTab"
                           />
                        </div>
                     }
                  </div>
               </ng-template>
            </div>
         }

         @if (!credToViewAsset) {
            <div>
               <div
                  class="alert alert-warning"
                  style="margin-top: 0px; margin-bottom: 0px"
               >
                  <strong>Warning</strong> --
                  <span>
                     {{ lang().credToViewAssetWarning }}
                  </span>
               </div>
            </div>
         }
      </ng-container>
   </lim-ui-entity-viewer-modal>
}
