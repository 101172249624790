import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "change-completed-time",
   templateUrl: "./changeCompletedTime.modal.component.html",
   styleUrls: ["./changeCompletedTime.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      NgClass,
      FormsModule,
      AlertComponent,
      LimbleHtmlDirective,
      BasicModalFooterComponent,
   ],
})
export class ChangeCompletedTime implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public errorMsg;
   public hours;
   public minutes;
   public allowZero;
   public hoursError;
   public minutesError;
   public valid;
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected dataLogSubmitLabelLookup = {
      [this.lang().ChangeLoggedTimesDate]: "task-submitChangeLoggedWorkTime",
      [this.lang().ChangeEstimatedTime]: "task-submitChangeEstimatedTime",
   };

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;

      this.message = this.resolve.message;
      this.title = this.resolve.title;

      this.errorMsg = "";

      this.hours = data.startHour || 0;
      this.minutes = data.startMin || 0;
      this.allowZero = data.allowZero || false;
   }

   isNumeric = (num) => {
      return !isNaN(parseFloat(num)) && isFinite(num);
   };

   close = () => {
      this.modalInstance.close();
   };

   submit = () => {
      this.errorMsg = "";
      this.hoursError = false;
      this.minutesError = false;
      this.valid = true;

      if (!this.allowZero) {
         if (this.minutes < 1 && this.hours == 0) {
            if (this.minutes > 0) {
               // This means the user likely tried to enter a number like 0.0001. This caused confusion for users so we are now requiring at least 1 minute to be entered.
               this.errorMsg += `${this.lang().YouMustLogAtLeastOneMinute}<br />`;
               this.minutesError = true;

               this.valid = false;
            } else {
               this.errorMsg += `${this.lang().PleaseEnterHowLongThisTaskTook}<br />`;
               this.hoursError = true;
               this.minutesError = true;
               this.valid = false;
            }
         }
      }

      if (!this.isNumeric(this.hours)) {
         this.errorMsg += `${this.lang().PleaseEnterValidValueForHours}<br />`;
         this.hoursError = true;
         this.valid = false;
      }

      if (!this.isNumeric(this.minutes)) {
         this.errorMsg += `${this.lang().PleaseEnterValidValueForMinutes}<br />`;
         this.minutesError = true;
         this.valid = false;
      }

      if (this.valid) {
         const submitData: any = {};
         submitData.hours = this.hours;
         submitData.minutes = this.minutes;

         this.modalInstance.close(submitData);
      }
   };

   protected lookupDataLogSubmitLabel(): string | undefined {
      return this.dataLogSubmitLabelLookup[this.title];
   }
}
