<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (message) {
         <lim-ui-info-panel>
            <span class="message">{{ message }}</span>
         </lim-ui-info-panel>
      }
      <lim-ui-info-panel>
         @if (selection == 1 || selection == 2) {
            <div>
               <div class="input-group">
                  <div class="input-group-section">
                     <label class="modal-label">{{
                        lang().PleaseTypeInANameForTheNewVendor
                     }}</label>
                     <input
                        class="vendor-label-input"
                        [(ngModel)]="name"
                        focusAuto
                        (click)="setDontChangeName()"
                        type="text"
                     />
                  </div>
                  @if (!locationKnown) {
                     <div class="input-group-section dropdown-search">
                        <label class="modal-label">{{
                           lang().WhichLocationWouldYouLikeThisVendorToBeAt
                        }}</label>
                        <lim-ui-form-dropdown-input
                           [inputString]="selectedLocationName"
                           class="form-dropdown-input"
                        >
                           <div menu>
                              @if (totalLocLength > 5) {
                                 <lim-ui-search-box
                                    [useDropdownStyling]="true"
                                    [searchVal]="searchLocations"
                                    (searchValChange)="
                                       runLocationSearch(); updateSearch(searchString)
                                    "
                                    [focusOnLoad]="true"
                                 />
                              }

                              <lim-ui-dropdown-divider />

                              @for (location of locations; track location) {
                                 <lim-ui-dropdown-text-item
                                    [ngClass]="{ dropDownSelectListBorder: !$last }"
                                    [displayString]="location.locationNameWithRegions"
                                    [outputString]="location.locationNameWithRegions"
                                    (itemSelectedEvent)="setLocation(location)"
                                 />
                              }
                           </div>
                        </lim-ui-form-dropdown-input>
                     </div>
                  }
               </div>
            </div>
         }
      </lim-ui-info-panel>

      <lim-ui-panel>
         <div class="horizotal-panel-content">
            <ng-container radio-button-list groupID="addAVendorGroup">
               <lim-ui-radio-button
                  groupID="addAVendorGroup"
                  [label]="lang().ABlankVendor"
                  [checked]="selection == 1"
                  (click)="selection = 1"
               />
               <lim-ui-radio-button
                  groupID="addAVendorGroup"
                  [label]="lang().ACopyOfAnExistingVendor"
                  [tooltip]="lang().ACopyOfAnExistingVendorTooltip"
                  [checked]="selection == 2"
                  (click)="selection = 2; buildHierView()"
               />
            </ng-container>
         </div>

         @if (selection == 2) {
            <div class="message horizontal-search-content">
               @if (!noSearchResults) {
                  <span class="pick-a-vendor-to-copy-message">
                     {{ lang().PickAnVendorToCopyFromYourLocation }}
                  </span>
               }
               <lim-ui-search-box
                  [placeholder]="lang().Search"
                  [(searchVal)]="searchString"
                  (searchValChange)="updateSearch($event)"
                  [focusOnLoad]="true"
               />
            </div>

            <div class="search-results scroll-height-inheritance">
               @if (noSearchResults) {
                  <div>
                     <no-search-results />
                  </div>
               }
               @if (treeData?.length && !noSearchResults) {
                  <hierarchy-container-legacy
                     #hierarchy
                     [treeData]="treeData"
                     [options]="hierarchyOptions"
                  />
               }
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Add"
      (successClick)="submit()"
      dataLogSubmitLabel="manageVendors-storeAVendor"
   />
</lim-ui-modal>
