import {
   Component,
   effect,
   inject,
   input,
   output,
   signal,
   type WritableSignal,
} from "@angular/core";
import { NumberInputWithButtonsComponent } from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { TranslationService } from "src/app/languages/translation/translation.service";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { FieldSelectionComponent } from "src/app/schedules/recurrences/field-selection/field-selection.component";
import { LocationFieldSelectionComponent } from "src/app/schedules/recurrences/location-field-selection/location-field-selection.component";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";

@Component({
   selector: "interval-settings",
   templateUrl: "./interval-settings.component.html",
   styleUrls: ["./interval-settings.component.scss"],
   imports: [
      NumberInputWithButtonsComponent,
      FieldSelectionComponent,
      LocationFieldSelectionComponent,
   ],
})
export class IntervalSettingsComponent {
   public readonly recurrence = input.required<Recurrence>();
   public readonly field = output<AssetField>();
   public readonly fieldValue = output<AssetFieldValue>();
   public readonly interval = output<number>();
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   public readonly start = output<number>();

   protected readonly i18n = inject(TranslationService).i18n;
   private readonly manageAsset = inject(ManageAsset);
   private readonly launchFlagsService = inject(LaunchFlagsService);
   public selectedField: WritableSignal<AssetField | null> = signal(null);
   public selectedFieldValue: WritableSignal<AssetFieldValue | null> = signal(null);
   public selectedInterval: WritableSignal<number> = signal(0);
   public selectedStart: WritableSignal<number> = signal(0);
   public asset: Asset | undefined;
   protected readonly pmSuggestionsFlag = this.launchFlagsService.getFlag(
      "release-pm-suggestions",
      false,
   );

   public constructor() {
      effect(
         () => {
            const assetID = this.recurrence().assetID;
            if (assetID === null) return;
            this.asset = this.manageAsset.getAsset(assetID);
         },
         { allowSignalWrites: true },
      );
      effect(
         () => {
            this.selectedInterval.set(this.recurrence().reoccurFld2);
         },
         { allowSignalWrites: true },
      );
      effect(
         () => {
            this.selectedStart.set(this.recurrence().reoccurFld3);
         },
         { allowSignalWrites: true },
      );
      effect(() => {
         const field = this.selectedField();
         if (field === null) return;
         this.field.emit(field);
      });
      effect(() => {
         this.interval.emit(this.selectedInterval());
      });
      effect(() => {
         this.start.emit(this.selectedStart());
      });
      effect(() => {
         const fieldValue = this.selectedFieldValue();
         //we will want to add the field to the asset here if it doesn't exist on the asset
         if (
            this.asset &&
            !this.asset?.assetValueIDs.includes(fieldValue?.valueID ?? 0)
         ) {
            const field = this.selectedField();
            if (field === null) return;
            this.addFieldToAsset(field);
            return;
         }

         if (fieldValue === null) return;
         this.fieldValue.emit(fieldValue);
      });
   }

   public setNewSelectedField(field: AssetField) {
      this.selectedField.set(field);
      this.selectedFieldValue.set(null);
   }

   public async addFieldToAsset(field: AssetField): Promise<void> {
      const assetID = this.recurrence().assetID;
      if (assetID === null) return;
      const asset = this.manageAsset.getAsset(assetID);
      if (asset === null || asset === undefined) return;
      const response = await this.manageAsset.addExistingField(asset, field.fieldID);
      this.fieldValue.emit(response?.data.row);
   }
}
