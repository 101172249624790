import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { ButtonComponent } from "src/app/shared/empowered/base/button/button.component";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

interface TaskIDCellData {
   checklistID: number;
}

@Component({
   selector: "task-id-cell-renderer",
   imports: [CommonModule, TranslateDirective, ButtonComponent],
   templateUrl: "./task-id-cell-renderer.component.html",
   styleUrls: ["./task-id-cell-renderer.component.scss"],
})
export class TaskIDCellRendererComponent extends BaseCellRendererComponent<TaskIDCellData> {
   protected readonly facade = inject(TasksFacadeService);

   public async viewTask(): Promise<void> {
      await this.facade.viewTask(this.cell.checklistID);
   }
}
