<div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
   <span class="lim-ui-mobile-table-column-label"
      >{{ lang().AverageDowntimeCost }}:
   </span>
   @if (task && task.averageDowntimeCost) {
      <span> {{ task.averageDowntimeCost | localeCurrency: task.currencyCode }}</span>
   } @else {
      <span>-</span>
   }
</div>
