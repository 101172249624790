export enum OfflinePrepState {
   Prepared = 0,
   PreparedButLimited = 1,
   InProgress = 2,
   Failed = 3,
}

export interface UserIdentity {
   userID: number;
   customerID: number;
}
