import { NgClass } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { IconComponent } from "@limblecmms/lim-ui";
import type { TaskInstruction } from "src/app/tasks/components/chkItemElement/chkItem.element.component";
import { TaskFormHelperService } from "src/app/tasks/components/shared/services/task-form-helper/task-form-helper.service";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";

@Component({
   selector: "chk-item-pointer",
   templateUrl: "./chkItemPointer.element.component.html",
   styleUrls: ["./chkItemPointer.element.component.scss"],
   imports: [NgClass, IconComponent],
})
export class ChkItemPointer {
   public readonly item = input.required<any>();
   public readonly instructions = input.required<TaskInstruction[]>();

   private readonly taskFormHelperService = inject(TaskFormHelperService);

   protected shouldShowPointer = computed(() => {
      const parent = this.taskFormHelperService.getInstructionParent(
         this.item(),
         this.instructions(),
      );

      if (parent === undefined) {
         return true;
      }

      return (
         Number(parent?.itemResponse) === Number(this.item().itemParentResponse) ||
         this.item().itemParentID === 0 ||
         (parent?.itemTypeID !== TaskInstructionTypeID.OptionList &&
            parent?.itemTypeID !== TaskInstructionTypeID.DropdownList)
      );
   });

   protected TaskInstructionType = TaskInstructionTypeID;
}
