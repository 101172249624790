<lim-ui-panel>
   <div class="panel-content">
      @if (message().length !== 0) {
         <div [limbleHtml]="message()"></div>
      }
      @if (usersAndProfilesAtLocation() === undefined) {
         <ngx-skeleton-loader [theme]="{ margin: 0 }" />
         <ngx-skeleton-loader [theme]="{ margin: 0 }" />
         <ngx-skeleton-loader [theme]="{ margin: 0 }" />
      } @else {
         @if (showSearchBox()) {
            <lim-ui-search-box
               class="search-box"
               [placeholder]="lang()?.Search ?? ''"
               [formControl]="searchBoxFormControl"
               [focusOnLoad]="true"
            />
         } @else {
            <div>
               <!-- Empty div for spacing reasons -->
            </div>
         }
         @if (noSearchResults()) {
            <span class="no-search-results">
               <no-search-results />
               <div class="no-search-results-buttons">
                  @if (canAddUsers()) {
                     <lim-ui-primary-button
                        icon="plus"
                        (click)="openAddUserModal()"
                        dataLogLabel="manageUsers-initiateAUser"
                     >
                        {{ lang()?.AddAUser }}
                     </lim-ui-primary-button>
                  }
                  <lim-ui-secondary-button
                     icon="plus"
                     (click)="closeAllAndRedirect('/profileManagement/' + locationID())"
                  >
                     {{ lang()?.SetUpATeam }}
                  </lim-ui-secondary-button>
               </div>
            </span>
         } @else {
            <div class="double-column-pick-list">
               <div class="column">
                  <div class="list-header">
                     {{ lang()?.Teams }}
                  </div>
                  <div class="list-options">
                     @if (manageProfileCred()) {
                        <lim-ui-secondary-button
                           icon="plus"
                           (click)="
                              closeAllAndRedirect('/profileManagement/' + locationID())
                           "
                        >
                           {{ lang()?.SetUpATeam }}
                        </lim-ui-secondary-button>
                     }
                  </div>
                  <div class="list">
                     @for (profile of displayedProfiles(); track profile.profileID) {
                        <div
                           (click)="selectProfile(profile.profileID)"
                           class="lim-ui-list-item lim-ui-selectable-list-item"
                           [ngClass]="{
                              'lim-ui-selected-list-item lim-ui-selected-pick-list-item':
                                 selectedProfile?.profileID === profile.profileID,
                           }"
                        >
                           <div class="lim-ui-list-item-content-group">
                              @if (selectedProfile?.profileID === profile.profileID) {
                                 <lim-ui-icon
                                    icon="check"
                                    iconSize="small"
                                    iconColor="primary"
                                 />
                              }
                              <div class="lim-ui-icon-with-text">
                                 <lim-ui-block-icon icon="users" />
                                 <span [limbleHtml]="profile.profileDescription"></span>
                              </div>
                           </div>
                        </div>
                     }
                  </div>
               </div>
               <div class="column">
                  <div class="list-header">
                     {{ lang()?.Users }}
                  </div>
                  <div class="list-options">
                     @if (canAddUsers()) {
                        <lim-ui-secondary-button icon="plus" (click)="openAddUserModal()">
                           {{ lang()?.AddAUser }}
                        </lim-ui-secondary-button>
                     }
                     <lim-ui-selection-controls
                        [selectAll]="lang()?.SelectAll"
                        (handleSelectAll)="selectAllUsers()"
                        [deselectAll]="lang()?.DeselectAll"
                        (handleDeselectAll)="deselectAll()"
                     />
                  </div>
                  <div class="list">
                     @for (user of displayedUsers(); track user.userID) {
                        <div
                           (click)="selectUser(user)"
                           class="lim-ui-list-item lim-ui-selectable-list-item"
                           [ngClass]="{
                              'lim-ui-selected-list-item': user.selected === true,
                              'lim-ui-hinted-list-item': user.hint === true,
                              'lim-ui-warning-list-item': user.extra,
                           }"
                        >
                           <div class="lim-ui-list-item-content">
                              <div class="lim-ui-list-item-content-group">
                                 @if (user.selected === true) {
                                    <lim-ui-icon
                                       icon="check"
                                       iconSize="small"
                                       [iconColor]="user.extra ? 'danger' : 'primary'"
                                    />
                                 }
                                 <div class="lim-ui-icon-with-text">
                                    <user-image [user]="user" />
                                    <span>
                                       {{ user.userFirstName }} {{ user.userLastName }}
                                    </span>
                                 </div>
                              </div>
                              @if (user.tooltipText && user.tooltipText.length) {
                                 <lim-ui-icon
                                    icon="circleQuestion"
                                    iconSize="small"
                                    limUiTooltip="{{ user.tooltipText }}"
                                    placement="top"
                                 />
                              }
                           </div>
                        </div>
                     }
                  </div>
               </div>
            </div>
         }
      }
   </div>
</lim-ui-panel>
