import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconButtonComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "change-downtime",
   templateUrl: "./changeDowntime.modal.component.html",
   styleUrls: ["./changeDowntime.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      NgClass,
      IconButtonComponent,
      FormsModule,
      LimbleHtmlDirective,
      BasicModalFooterComponent,
   ],
})
export class ChangeDowntime implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public errorMsg;
   public hours;
   public minutes;
   public hoursError;
   public minutesError;
   public valid;
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;

      this.errorMsg = "";
      this.hours = this.resolve.hours || 0;
      this.minutes = this.resolve.minutes || 0;
   }

   minusHours = () => {
      if (this.hours > 0.999) {
         this.hours = Number(this.hours) - 1;
      }
   };

   minusMinutes = () => {
      if (this.minutes > 0.999) {
         this.minutes = Number(this.minutes) - 1;
      }
   };

   isNumeric = (num) => {
      return !isNaN(parseFloat(num)) && isFinite(num);
   };

   close = () => {
      this.modalInstance.close();
   };

   submit = () => {
      this.errorMsg = "";
      this.hoursError = false;
      this.minutesError = false;
      this.valid = true;

      if (!this.isNumeric(this.hours)) {
         this.errorMsg += `${this.lang().PleaseEnterValidValueForHours}<br />`;
         this.hoursError = true;
         this.valid = false;
      }

      if (!this.isNumeric(this.minutes)) {
         this.errorMsg += `${this.lang().PleaseEnterValidValueForMinutes}<br />`;
         this.minutesError = true;
         this.valid = false;
      }

      if (this.valid) {
         const data: any = {};
         data.hours = this.hours;
         data.minutes = this.minutes;

         this.modalInstance.close(data);
      }
   };
}
