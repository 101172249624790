import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface AssetTemplate {
   ID: number;
   Name: string;
   Status: string;
   AssetCount: number;
   JobProgress?: number;
   UpdatedAt: string;
}

@Injectable({ providedIn: "root" })
export class AssetTemplateApiService {
   private readonly http = inject(HttpClient);

   private readonly baseUrl: string = `${environment.servicesURL()}/assets`;

   public getById(id: number): Observable<AssetTemplate | AssetTemplate[]> {
      return this.http.get<AssetTemplate[] | AssetTemplate>(
         `${this.baseUrl}/template/${id}`,
         { withCredentials: true },
      );
   }
}
