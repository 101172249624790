import { inject, Injectable } from "@angular/core";
import { instructionOptionInitializer } from "src/app/lite/local-db/resources/collection/task/instruction/instruction-option/instruction-option.initializer";
import { instructionOptionSchema } from "src/app/lite/local-db/resources/collection/task/instruction/instruction-option/instruction-option.schema";
import { InstructionOptionStorageService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction-option/instruction-option.storage.service";
import { refetchValidData } from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { z } from "zod";

@Injectable({ providedIn: "root" })
export class InstructionOptionStorageSyncService extends StorageSyncService {
   private readonly instructionOptionService = inject(InstructionOptionStorageService);

   public constructor() {
      super();
   }

   public async syncInstructionOption(instructionOptionID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = instructionOptionInitializer;
         const instructionOptions = await refetchValidData({
            url,
            params: { instructionOptionIDs: [instructionOptionID], pageSize: 40000 },
            validation: z.array(instructionOptionSchema),
         });
         const [instructionOption] = instructionOptions;
         assert(
            instructionOption !== undefined,
            `Instruction Option #${instructionOptionID} not found in fetch when attempting to sync`,
         );

         await this.instructionOptionService.putInstructionOption(instructionOption);
      });
   }

   public async syncInstructionOptionDeletion(
      instructionOptionID: number,
   ): Promise<void> {
      return this.sync(async () => {
         await this.instructionOptionService.deleteInstructionOption(instructionOptionID);
      });
   }
}
