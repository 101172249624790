import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type { PurchasableSearchParams } from "src/app/parts/purchasable/purchasable-api.service.params";
import { purchasableDto } from "src/app/parts/purchasable/purchasable.dto";
import type {
   CreatePurchasableBodyDto,
   PurchasableDto,
   UpdatePurchasableBodyDto,
} from "src/app/parts/purchasable/purchasable.dto.types";
import { validateFilter } from "src/app/shared/rxjs-operators/validate-filter";
import { environment } from "src/environments/environment";
import { z } from "zod";

@Injectable({ providedIn: "root" })
export class PurchasableApiService {
   private readonly httpClient = inject(HttpClient);

   public fetchByPart(partID: number): Observable<Array<PurchasableDto>> {
      return this.httpClient
         .get(this.createGetUrl(partID))
         .pipe(validateFilter(z.array(purchasableDto)));
   }

   public search({
      filters,
      limit,
   }: PurchasableSearchParams): Observable<Array<PurchasableDto>> {
      const queryParams = new HttpParams()
         .set("limit", limit.toString())
         .set("orderUnitType", filters.orderUnitDescription.type)
         .set("orderUnitID", filters.orderUnitDescription.id.toString());

      return this.httpClient
         .get(`${environment.flannelUrl}/purchasables/search`, { params: queryParams })
         .pipe(validateFilter(z.array(purchasableDto)));
   }

   private createGetUrl(partID: number): string {
      return `${environment.flannelUrl}/parts/${partID}/purchasables`;
   }

   public createPurchasable(
      partID: number,
      purchasable: CreatePurchasableBodyDto,
   ): Observable<PurchasableDto> {
      return this.httpClient
         .post(this.createGetUrl(partID), purchasable)
         .pipe(validateFilter(purchasableDto));
   }

   public updatePurchasable(
      partID: number,
      purchasableID: number,
      purchasable: UpdatePurchasableBodyDto,
   ): Observable<PurchasableDto> {
      return this.httpClient
         .patch(`${this.createGetUrl(partID)}/${purchasableID}`, purchasable)
         .pipe(validateFilter(purchasableDto));
   }
   public deletePurchasable(
      partID: number,
      purchasableID: number,
   ): Observable<{ id: number }> {
      return this.httpClient
         .delete(`${this.createGetUrl(partID)}/${purchasableID}`)
         .pipe(validateFilter(z.object({ id: z.number() })));
   }
}
