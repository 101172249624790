import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "gather-text",
   templateUrl: "./gatherText.modal.component.html",
   styleUrls: ["./gatherText.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      ContenteditableDirective,
      FormsModule,
      AlertComponent,
      BasicModalFooterComponent,
   ],
})
export class GatherText implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public message2;
   public title;
   public warning;
   public data;
   public errorMsg;
   public text;
   public singleLine;
   public placeholder;
   public buttonText;
   protected dataLogSubmitLabelLookup: Record<string, string>;

   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.dataLogSubmitLabelLookup = {
         [this.lang().UpdateLaborDescription]: "task-submitChangeLoggedWorkDescription",
      };
   }
   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.message2 = this.resolve.message2;
      this.title = this.resolve.title;
      this.warning = this.resolve.warning;
      this.data = this.resolve.data;
      this.errorMsg = "";

      if (this.data.currentText) {
         this.text = this.data.currentText;
      } else {
         this.text = "";
      }

      this.singleLine = this.data.singleLine || false;
      this.placeholder = this.data.placeholder || "";
      this.buttonText = this.data.buttonText || this.lang().Submit;
   }

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      if (this.text.length < 1) {
         this.errorMsg = this.lang().CantBeBlank;
         return;
      }

      this.modalInstance.close(this.text.replace(/&nbsp;/gi, "")); //removes white space from ends of string
   };

   protected lookupDataLogSubmitLabel(): string | undefined {
      return this.dataLogSubmitLabelLookup[this.title];
   }
}
