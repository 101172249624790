import { HttpClient } from "@angular/common/http";
import type { Signal } from "@angular/core";
import { Injectable, inject, signal } from "@angular/core";
import { catchError, firstValueFrom, tap, throwError, type Observable } from "rxjs";
import type {
   EfficiencyRates,
   EfficiencyRatesSettingsResponse,
} from "src/app/shared/services/insights/insights.model";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class InsightsService {
   private readonly http = inject(HttpClient);
   private readonly customEfficiencyRatesEndpoint = `${environment.flannelUrl}/insights/efficiencyRates`;
   private readonly defaultEfficiencyRates: EfficiencyRates = {
      downtimeEfficiencyRate: 31,
      averageDowntimeCost: 200,
      laborEfficiencyRate: 41,
      partSpendEfficiencyRate: 19,
      poSpendEfficiencyRate: 11,
   };
   private readonly efficiencyRatesSettings = signal<EfficiencyRatesSettingsResponse>([]);
   private readonly currentEfficiencyRates = signal(this.defaultEfficiencyRates);

   public checkForCustomEfficiencyRates$: Observable<EfficiencyRatesSettingsResponse> =
      this.http
         .get<EfficiencyRatesSettingsResponse>(this.customEfficiencyRatesEndpoint)
         .pipe(
            tap((customEfficiencyRates) => {
               if (
                  customEfficiencyRates &&
                  customEfficiencyRates.length > 0 &&
                  customEfficiencyRates[0].settingsID !== 0
               ) {
                  this.efficiencyRatesSettings.set(customEfficiencyRates);
                  const efficiencyRates: EfficiencyRates = {
                     downtimeEfficiencyRate: 0,
                     averageDowntimeCost: 0,
                     laborEfficiencyRate: 0,
                     partSpendEfficiencyRate: 0,
                     poSpendEfficiencyRate: 0,
                  };
                  customEfficiencyRates.forEach((customEfficiencyRate) => {
                     efficiencyRates[customEfficiencyRate.alias] =
                        customEfficiencyRate.float_value;
                  });
                  this.currentEfficiencyRates.set(efficiencyRates);
               }
            }),
            catchError((error) => {
               console.error("Failed to retrieve custom efficiency rates.", error);
               return throwError(
                  () => new Error("Failed to retrieve custom efficiency rates."),
               );
            }),
         );

   public getEfficiencyRates(): Signal<EfficiencyRates> {
      return this.currentEfficiencyRates;
   }

   public getDefaultEfficiencyRates(): EfficiencyRates {
      return this.defaultEfficiencyRates;
   }

   public async saveEfficiencyRates(efficiencyRates: EfficiencyRates): Promise<void> {
      try {
         const efficiencyRatesSettings =
            this.buildEfficiencyRatesSettings(efficiencyRates);
         const response: EfficiencyRatesSettingsResponse = await firstValueFrom(
            this.http.post<EfficiencyRatesSettingsResponse>(
               this.customEfficiencyRatesEndpoint,
               efficiencyRatesSettings,
            ),
         );
         this.efficiencyRatesSettings.set(response);
         this.currentEfficiencyRates.set(efficiencyRates);
      } catch (error) {
         throw new Error("Failed to save efficiency rates.");
      }
   }

   private buildEfficiencyRatesSettings(
      efficiencyRates: EfficiencyRates,
   ): EfficiencyRatesSettingsResponse {
      const newRatesSettings: EfficiencyRatesSettingsResponse = [];
      if (this.efficiencyRatesSettings().length > 0) {
         const efficiencyRatesSettings = this.efficiencyRatesSettings();
         efficiencyRatesSettings.forEach((setting) => {
            setting.float_value = efficiencyRates[setting.alias];
            newRatesSettings.push(setting);
         });
      } else {
         for (const alias in efficiencyRates) {
            newRatesSettings.push({
               alias,
               float_value: efficiencyRates[alias],
               settingsID: 0,
               data_type: "float",
               setting_type: "roi_efficiency_rates",
            });
         }
      }
      return newRatesSettings;
   }

   public calcDowntimeSavings(downtimeHours: number) {
      return (
         (downtimeHours /
            (1 - this.currentEfficiencyRates().downtimeEfficiencyRate / 100) -
            downtimeHours) *
         this.currentEfficiencyRates().averageDowntimeCost
      );
   }

   public calcLaborSavings(laborCost: number) {
      return (
         laborCost / (1 - this.currentEfficiencyRates().laborEfficiencyRate / 100) -
         laborCost
      );
   }

   public calcPartsSpendSavings(partsCost: number) {
      return (
         partsCost / (1 - this.currentEfficiencyRates().partSpendEfficiencyRate / 100) -
         partsCost
      );
   }

   public calcPosSpendSavings(posCost: number) {
      return (
         posCost / (1 - this.currentEfficiencyRates().poSpendEfficiencyRate / 100) -
         posCost
      );
   }
}
