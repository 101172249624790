import { Injectable, inject } from "@angular/core";
import type { Optional } from "@empowered/types/util";
import { TranslateService } from "src/app/languages";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade";

@Injectable({
   providedIn: "root",
})
export class TaskMenuItemsService {
   private readonly facade = inject(TasksFacadeService);
   private readonly t = inject(TranslateService);

   private task: Optional<TaskDataViewerViewModel>;

   public getItems(task: TaskDataViewerViewModel) {
      this.task = task;
      return [
         {
            label: this.t.instant("ViewTask"),
            command: () => {
               this.handleViewTask(task);
            },
         },
         {
            label: this.t.instant("ViewAsset"),
            command: () => {
               this.handleViewAsset(task);
            },
         },
         {
            separator: true,
         },
         {
            label: this.t.instant("DeferTask"),
            command: () => {
               this.handleDeferTask(task);
            },
         },
         {
            label: this.t.instant("ChangeDueDate"),
            command: () => {
               this.handleChangeDueDate(task);
            },
         },
         {
            label: this.t.instant("SendAReminder"),
            command: () => {
               this.handleSendReminder(task);
            },
         },
         {
            separator: true,
         },
         {
            label: this.t.instant("duplicateOpenTask"),
            command: () => {
               this.handleDuplicate(task);
            },
         },
         {
            label: this.t.instant("DeleteTask"),
            command: () => {
               this.handleDelete(task);
            },
         },
      ];
   }

   /**
    * PERMISSIONS
    */
   protected canEdit(): boolean {
      return true;
      // return this.taskF.canEdit ?? false;
   }

   protected canDelete(): boolean {
      return true;
      // return this.task?.canDelete ?? false;
   }

   /**
    * COMMANDS
    */

   private handleViewTask(task: TaskDataViewerViewModel): void {
      this.facade.viewTask(task.checklistID);
   }

   private handleViewAsset(task: TaskDataViewerViewModel): void {
      const assetID = task.assetID;
      if (assetID) {
         this.facade.viewAsset(assetID);
      }
   }

   private handleChangeAssignee(task: TaskDataViewerViewModel): void {
      this.facade.changeAssignment(task);
   }

   private handleSendReminder(task: TaskDataViewerViewModel): void {
      this.facade.emailReminder(task);
   }

   private handleDuplicate(task: TaskDataViewerViewModel): void {
      this.facade.duplicate(task, {});
   }

   private handleDelete(task: TaskDataViewerViewModel): void {
      this.facade.deleteTask(task);
   }

   private handleDeferTask(task: TaskDataViewerViewModel): void {
      this.facade.deferTask(task);
   }

   private handleChangeDueDate(task: TaskDataViewerViewModel): void {
      this.facade.changeDueDate(task);
   }
}
