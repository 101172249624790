import {
   Directive,
   ElementRef,
   Renderer2,
   type AfterViewInit,
   inject,
} from "@angular/core";

@Directive({
   standalone: true,
   selector: "[colorizeString]",
})
export class ColorizeStringDirective implements AfterViewInit {
   private readonly elementRef = inject(ElementRef);
   private readonly renderer = inject(Renderer2);

   public ngAfterViewInit() {
      this.colorizeLinkText();
   }

   private colorizeLinkText() {
      const spans = this.elementRef.nativeElement.querySelectorAll("span.link-text");
      spans.forEach((span: HTMLElement) => {
         this.renderer.setStyle(span, "color", "var(--colors-interactive-text)");
      });
   }
}
