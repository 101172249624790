import { Injectable } from "@angular/core";
import axios from "axios/dist/axios";
import type { PickUserOrProfileSelection } from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.component";
import type {
   PickedProfile,
   PickedUser,
} from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.types";

@Injectable({ providedIn: "root" })
export class AssignmentService {
   public async saveAssignment(
      selection: PickUserOrProfileSelection,
      taskID: number,
   ): Promise<void> {
      if ("selectedProfile" in selection) {
         return this.saveProfileAssignment(taskID, selection.selectedProfile);
      }
      if (selection.selectedUsers.length === 0) {
         throw new Error("No users selected");
      }
      return this.saveUserAssignment(taskID, selection.selectedUsers);
   }

   private async saveProfileAssignment(
      taskID: number,
      selectedProfile: PickedProfile,
   ): Promise<void> {
      return axios.post(
         "phpscripts/manageDashboard.php",
         {
            chkID: taskID,
            userID: 0,
            profileID: selectedProfile.profileID,
            multiUsers: JSON.stringify([]),
            dynamicAssignment: 0,
         },
         { params: { action: "changeSchedule" } },
      );
   }

   private async saveUserAssignment(
      taskID: number,
      selectedUsers: PickedUser[],
   ): Promise<void> {
      const userID = selectedUsers.length > 1 ? 0 : selectedUsers[0].userID;
      const multiUsers =
         selectedUsers.length > 1 ? JSON.stringify(selectedUsers) : JSON.stringify([]);
      const dynamicAssignment =
         selectedUsers.length > 1 ? 0 : (selectedUsers[0].dynamicAssignment ?? 0);
      return axios.post(
         "phpscripts/manageDashboard.php",
         {
            chkID: taskID,
            userID,
            profileID: 0,
            multiUsers,
            dynamicAssignment,
         },
         { params: { action: "changeSchedule" } },
      );
   }
}
