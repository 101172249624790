<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header
      [title]="title"
      (closeModal)="dismiss()"
      id="popTaskCloseMe"
   />
   <lim-ui-modal-body id="confirmHolder">
      <lim-ui-panel>
         <span [limbleHtml]="message"></span>

         @for (item of list | orderBy: "name"; track item) {
            <div (click)="submit(item.value)" class="lim-ui-list-item">
               @if (item.icon) {
                  <span><lim-ui-icon [icon]="item.icon | iconAlias" /> </span>
               }
               {{ item.name }}
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer id="confirmFooter" />
</lim-ui-modal>
