import { CountedPurchasable } from "src/app/parts/purchasable/counted-purchasable.model";
import type { PurchasableCountedAndMeasuredDto } from "src/app/parts/purchasable/purchasable.dto.types";

export class CountedAndMeasuredPurchasable extends CountedPurchasable {
   public readonly providedSizeUnitID: number;

   public constructor(params: PurchasableCountedAndMeasuredDto) {
      super(params);

      this.providedSizeUnitID = params.providedSizeUnitID;
   }
}
