<ng-container *translate="let t">
   <lim-ui-dropdown-button [label]="t(filter().labelKey) || ''" filterDropdown>
      @if (locations().length > 5) {
         <div header>
            <lim-ui-search-box
               [placeholder]="t('Search')"
               [searchVal]="searchLocations()"
               (searchValChange)="handleSearch($event)"
               [useDropdownStyling]="true"
            />
            <lim-ui-dropdown-divider />
         </div>
      }
      <div menu>
         @for (location of locationsDropdown(); track location) {
            <lim-ui-dropdown-item (click)="setLocationFilter(location)">
               <div [limbleHtml]="location.locationNameWithRegionsIcon"></div>
            </lim-ui-dropdown-item>
         }
      </div>
      <div footer>
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-clear-filter-item (click)="handleClear()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
