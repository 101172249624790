import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BadgeComponent,
   BasicModalHeaderComponent,
   CheckboxComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   NumberInputWithButtonsComponent,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   RadioButtonComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import type { Subscription } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { IntervalSettingsComponent } from "src/app/schedules/recurrences/pickReoccurModal/interval-settings/interval-settings.component";
import { PickReoccurService } from "src/app/schedules/recurrences/pickReoccurModal/pick-reoccur.service";
import { ThresholdSettingsComponent } from "src/app/schedules/recurrences/pickReoccurModal/threshold-settings/threshold-settings.component";
import { TimeOfDayPicker } from "src/app/shared/components/global/timeOfDayPickerElement/timeOfDayPicker.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ParamsService } from "src/app/shared/services/params.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

type reoccurType = "day" | "week" | "month" | "year" | "everyX" | "threshold" | undefined;

@Component({
   selector: "pick-reoccur",
   templateUrl: "./pickReoccur.modal.component.html",
   styleUrls: ["./pickReoccur.modal.component.scss"],
   imports: [
      BadgeComponent,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      RadioButtonComponent,
      FormsModule,
      NgClass,
      TimeOfDayPicker,
      CheckboxComponent,
      DropdownComponent,
      LimbleHtmlDirective,
      IconComponent,
      DropdownTextItemComponent,
      TooltipDirective,
      DatePickerInputComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      PopoverDirective,
      UpsellPopover,
      NumberInputWithButtonsComponent,
      IntervalSettingsComponent,
      ThresholdSettingsComponent,
   ],
   providers: [PickReoccurService],
})
export class PickReoccur implements OnInit, OnDestroy {
   public modalInstance;
   public message;
   public title;
   protected recurrence!: Recurrence;
   public monthFlip: "Weekday" | "Numeric" | false = false;
   public yearFlip: "Ordinal" | "Numeric" | false = false;
   public ordinalTranslator;
   public dayTranslator;
   public dayNumberTranslator;
   public dayOptions;
   public monthOptions;
   public monthTranslator;
   public monthOrdinalOptions;
   public hours;
   public week;
   public reoccurType: reoccurType = undefined;
   public timeOfDay;
   public hour;
   public minute;
   public AMPM;
   public minDate;
   public opened;
   public openedEnd;
   public hasLoaded = false;
   private reoccurID: number | undefined;
   private checklistID: number | undefined;
   protected featureMeterThresholdPM: boolean = false;
   protected taskTemplate: TaskTemplateEntity | undefined;
   protected reoccurSpawnInMilliseconds: number | null = null;
   protected reoccurSpawnEndInMilliseconds: number | null = null;
   private readonly manageFeatureFlagsSub: Subscription;

   private readonly manageTask = inject(ManageTask);
   public readonly manageAsset = inject(ManageAsset);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);
   private readonly pickReoccurService = inject(PickReoccurService);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureMeterThresholdPM = isFeatureEnabledMap.featureMeterThresholdPM;
         },
      );
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.message = params.resolve.message;
         this.title = params.resolve.title;
         this.reoccurID = params.resolve.reoccurID;
         this.checklistID = params.resolve.checklistID;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.initialize();
   }

   private initialize(): void {
      this.setRecurrence();

      this.initializeDatesForDatePickers();

      this.yearFlip = false;

      this.setReoccurSpawnEndDefault();

      this.scrollToTopOfModal();

      this.initializeTranslators();

      this.initializeSelectListOptions();

      this.initializeMinDate();

      this.initializeAccordingToReoccurType();

      this.initializeTimeOfDayView();

      this.hasLoaded = true;
   }

   private initializeTimeOfDayView(): void {
      if (
         this.recurrence.reoccurTimeOfDay != null &&
         this.recurrence.reoccurTimeOfDay !== ""
      ) {
         this.timeOfDay = true;

         this.hour = this.recurrence.reoccurTimeOfDay.substring(0, 2);
         this.minute = this.recurrence.reoccurTimeOfDay.substring(2, 4);

         if (this.manageUser.getCurrentUser().userInfo.customerClockFormat === "12-hr") {
            if (this.hour >= 12) {
               this.AMPM = "PM";
            } else {
               this.AMPM = "AM";
            }
            if (this.hour > 12) {
               this.hour = `${this.hour - 12}`;
            }

            if (this.hour === "00") {
               this.hour = "12";
               this.AMPM = "AM";
            }
         } else {
            this.AMPM = "";
         }
      } else {
         this.timeOfDay = false;
         if (this.manageUser.getCurrentUser().userInfo.customerClockFormat === "24-hr") {
            this.hour = "00";
            this.minute = "00";
            this.AMPM = "";
         } else {
            this.hour = "12";
            this.minute = "00";
            this.AMPM = "AM";
         }
      }
   }

   private initializeAccordingToReoccurType(): void {
      if (Number(this.recurrence.reoccurType === 1)) {
         this.reoccurType = "day";
      }

      if (Number(this.recurrence.reoccurType === 2)) {
         this.reoccurType = "week";
         this.changeReoccurFld2ToArray();
         this.setSelectedOptionForWeekDropdown();
      }

      if (Number(this.recurrence.reoccurType === 3)) {
         this.reoccurType = "month";
         this.monthFlip = "Numeric";
      }

      if (Number(this.recurrence.reoccurType === 4)) {
         this.reoccurType = "month";
         this.monthFlip = "Weekday";
      }

      if (Number(this.recurrence.reoccurType === 5)) {
         this.reoccurType = "year";
         this.yearFlip = "Numeric";
      }

      if (Number(this.recurrence.reoccurType === 6)) {
         this.reoccurType = "year";
         this.yearFlip = "Ordinal";
      }

      if (Number(this.recurrence.reoccurType === 7)) {
         this.reoccurType = "everyX";
         this.recurrence.reoccurFld2 = Number(this.recurrence.reoccurFld2);
         this.recurrence.reoccurFld3 = Number(this.recurrence.reoccurFld3);
         this.recurrence.reoccurFld4 = Number(this.recurrence.reoccurFld4);
         this.recurrence.reoccurFld5 = Number(this.recurrence.reoccurFld5);
      }

      if (Number(this.recurrence.reoccurType === 8)) {
         this.reoccurType = "threshold";
         this.recurrence.reoccurFld2 = Number(this.recurrence.reoccurFld2);
         this.recurrence.reoccurFld3 = Number(this.recurrence.reoccurFld3);
         this.recurrence.reoccurFld4 = Number(this.recurrence.reoccurFld4);
         this.recurrence.reoccurFld5 = Number(this.recurrence.reoccurFld5);
      }
   }

   private changeReoccurFld2ToArray(): void {
      if (typeof this.recurrence.reoccurFld2 === "string") {
         this.recurrence.reoccurFld2 = this.recurrence.reoccurFld2.split(",");
      }
   }

   private setSelectedOptionForWeekDropdown(): void {
      for (const key in this.recurrence.reoccurFld2) {
         for (const week of this.week) {
            if (week.num == this.recurrence.reoccurFld2[key]) {
               week.selected = true;
            }
         }
      }
   }

   private initializeSelectListOptions(): void {
      // allow for select list to use these day options
      this.dayOptions = this.pickReoccurService.getEnglishDayArray();

      // allow for select list to show these months
      this.monthOptions = this.pickReoccurService.getMonthArray();

      // allow for selected list based on these month abbr.
      this.monthOrdinalOptions =
         this.pickReoccurService.getEnglishMonthAbbreviationArray();

      this.week = this.pickReoccurService.getWeekSelectionArray();
   }

   private initializeTranslators(): void {
      // translate 'second' to '2nd'
      this.ordinalTranslator = this.pickReoccurService.getOrdinalTranslator();

      // translate 'mon' to 'Monday'
      this.dayTranslator = this.pickReoccurService.getDayTranslator();

      this.dayNumberTranslator = this.pickReoccurService.getDayNumberTranslator();

      // translate 'jan' to 'January'
      this.monthTranslator = this.pickReoccurService.getMonthTranslator();
   }
   private scrollToTopOfModal(): void {
      //this timeout makes sure that the modal starts at the top of the screen
      setTimeout(() => {
         $(".modal").scrollTop(0);
      }, 1);
   }

   protected updateSpawnStart(selectedDate: Date): void {
      this.reoccurSpawnInMilliseconds = selectedDate.getTime();
   }

   protected updateSpawnEnd(selectedDate: Date): void {
      this.reoccurSpawnEndInMilliseconds = selectedDate.getTime();
   }

   private setReoccurSpawnEndDefault(): void {
      if (
         this.recurrence.reoccurSpawnEnd == 0 ||
         (this.recurrence.reoccurSpawnEnd &&
            this.recurrence.reoccurSpawn &&
            this.recurrence?.reoccurSpawnEnd?.valueOf() <
               this.recurrence?.reoccurSpawn?.valueOf())
      ) {
         this.reoccurSpawnEndInMilliseconds = null;
      }
   }

   private setRecurrence(): void {
      if (!this.reoccurID) {
         this.clearRecurrence();
         return;
      }
      const recurrence = this.manageTask.getRecurrence(this.reoccurID);
      if (!recurrence) {
         throw new Error("Error. The recurrence you are trying to edit does not exist");
      }
      this.recurrence = { ...recurrence };
   }

   private clearRecurrence(): void {
      this.recurrence = {
         reoccurID: 0,
         reoccurType: 0,
         reoccurSpawn: 0,
         reoccurSpawnEnd: 0,
         reoccurTimeOfDay: null,
         reoccurEndBy: 0,
         reoccurRepetitions: 0,
         reoccurFld1: 0,
         reoccurFld2: 0,
         reoccurFld3: 0,
         reoccurFld4: 0,
         reoccurFld5: 0,
         reoccurFld6: 0,
         reoccurFld7: 0,
         checklistID: this.checklistID ?? 0,
         assetID: null,
         allowDuplicateInstances: 0,
         calculateBasedOnLastCompletedPM: 0,
         skipEveryX: 0,
         calculateBasedOnIncrementOnly: 0,
         scheduleCount: 0,
         valueEnd: 0,
      };
   }

   private initializeDatesForDatePickers() {
      this.reoccurSpawnInMilliseconds =
         this.manageSchedule.configureReoccurSpawnForDisplay(
            Number(this.recurrence.reoccurSpawn),
         );

      this.reoccurSpawnEndInMilliseconds =
         this.manageSchedule.configureReoccurSpawnEndForDisplay(
            this.recurrence.reoccurSpawnEnd,
         );
   }

   public ngOnDestroy() {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private initializeMinDate() {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDate = this.minDate ? null : date;
   }
   getNumber = (num) => {
      return new Array(num);
   };

   protected toggleWeekdayChecked(day): void {
      if (day.selected == true) {
         if (!(this.recurrence.reoccurFld2 instanceof Array)) {
            this.recurrence.reoccurFld2 = [];
         }
         this.recurrence.reoccurFld2.push(day.num);
      } else if (day.selected == false) {
         for (const key in this.recurrence.reoccurFld2) {
            if (this.recurrence.reoccurFld2[key] == day.num) {
               this.recurrence.reoccurFld2.splice(key, 1);
            }
         }
      }
   }

   protected resetWeek(): void {
      this.recurrence.reoccurFld1 = 0;
      this.recurrence.reoccurFld2 = [];
   }

   protected resetDayMonthYear(): void {
      this.recurrence.reoccurFld1 = 0;
      this.recurrence.reoccurFld2 = 0;
      this.recurrence.reoccurFld3 = 0;
      this.recurrence.reoccurFld4 = 0;
      this.recurrence.reoccurFld5 = 0;
      for (const week of this.week) {
         week.selected = false;
      }
   }

   close = () => {
      this.modalInstance.close(0);
   };

   open = ($event, source) => {
      $event.preventDefault();
      $event.stopPropagation();

      if (source == 1) {
         this.opened = true;
         this.openedEnd = false;
      }

      if (source == 2) {
         this.opened = false;
         this.openedEnd = true;
      }
   };

   protected submit(): void {
      const reoccurType = this.pickReoccurService.setReoccurType(
         this.reoccurType,
         this.featureMeterThresholdPM,
         this.monthFlip,
         this.yearFlip,
      );
      if (reoccurType === undefined) {
         this.alertService.addAlert(
            this.lang().PleaseMakeSureYouHaveSelectedARecurrenceAndDate,
            "warning",
            6000,
         );
         return;
      }
      if (reoccurType === 7 || reoccurType === 8) {
         this.recurrence.reoccurFld2 = Number(this.recurrence.reoccurFld2);
         this.recurrence.reoccurFld3 = Number(this.recurrence.reoccurFld3);
         this.recurrence.reoccurFld4 = true;
      }
      this.recurrence.reoccurType = reoccurType;

      if (this.reoccurType === "month" && this.monthFlip == false) {
         this.alertService.addAlert(
            this.lang().PleaseSelectEitherWeekdayOrNumericValue,
            "warning",
            6000,
         );
         return;
      }
      if (this.reoccurType === "year" && this.yearFlip == false) {
         this.alertService.addAlert(
            this.lang().PleaseSelectEitherNumericOrOrdinal,
            "warning",
            6000,
         );
         return;
      }

      if (!this.recurrenceValidation(this.recurrence)) {
         return;
      }

      if (
         !this.isValidSpawnDate(
            this.reoccurSpawnInMilliseconds,
            this.recurrence.reoccurType,
         )
      ) {
         return;
      }

      this.recurrence.reoccurTimeOfDay = this.pickReoccurService.createTimeOfDay(
         this.timeOfDay,
         this.AMPM,
         this.hour,
         this.minute,
      );

      const result = {
         recurrence: this.recurrence,
         reoccurSpawnInMilliseconds: this.reoccurSpawnInMilliseconds,
         reoccurSpawnEndInMilliseconds: this.reoccurSpawnEndInMilliseconds,
      };

      this.modalInstance.close(result);
   }

   protected isValidSpawnDate(
      spawnDateInMilliseconds: number | null,
      reoccurType: number,
   ): boolean {
      const spawnDateIsInThePast = this.manageSchedule.checkIfDateIsInThePast(
         spawnDateInMilliseconds,
      );

      if (spawnDateIsInThePast && reoccurType !== 7 && reoccurType !== 8) {
         this.alertService.addAlert(
            this.lang().DateCannotBeSetInThePast,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected clearEndDate(): void {
      this.reoccurSpawnEndInMilliseconds = null;
   }

   protected setEveryXReoccurType(recurrence: Recurrence): void {
      if (!this.featureMeterThresholdPM) {
         return;
      }
      this.resetDayMonthYear();
      this.setReoccur("everyX");
      recurrence.reoccurFld1 = null;
   }

   protected setThresholdType(reoccur): void {
      if (!this.featureMeterThresholdPM) {
         return;
      }
      this.resetDayMonthYear();
      this.setReoccur("threshold");
      reoccur.reoccurFld1 = null;
   }

   protected setReoccur(newReoccurType: reoccurType): void {
      this.reoccurType = newReoccurType;
   }

   protected setMonthlyReoccurType(type: "Weekday" | "Numeric"): void {
      this.resetDayMonthYear();
      this.monthFlip = type;
      if (type === "Weekday") {
         this.recurrence.reoccurFld1 = 1;
         this.recurrence.reoccurFld2 = "first";
         this.recurrence.reoccurFld3 = "mon";
         return;
      }
      this.recurrence.reoccurFld1 = 1;
      this.recurrence.reoccurFld2 = 1;
   }

   protected setYearlyReoccurType(type: "Ordinal" | "Numeric"): void {
      this.resetDayMonthYear();
      this.yearFlip = type;
      if (type === "Ordinal") {
         this.recurrence.reoccurFld1 = 1;
         this.recurrence.reoccurFld2 = "first";
         this.recurrence.reoccurFld3 = "mon";
         this.recurrence.reoccurFld4 = "jan";
         return;
      }
      this.recurrence.reoccurFld1 = 1;
      this.recurrence.reoccurFld2 = 1;
      this.recurrence.reoccurFld3 = 1;
   }

   protected dailyRecurrenceValidation(recurrence: Recurrence): boolean {
      if (
         Number(recurrence.reoccurFld1) < 1 ||
         Number(recurrence.reoccurFld1) > 9999 ||
         recurrence.reoccurFld1 === undefined
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfDays,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected weeklyRecurrenceValidation(recurrence: Recurrence): boolean {
      if (
         Number(recurrence.reoccurFld1) < 1 ||
         Number(recurrence.reoccurFld1) > 5000 ||
         recurrence.reoccurFld1 === undefined
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfWeeks,
            "warning",
            6000,
         );
         return false;
      }

      if (!(recurrence.reoccurFld2 instanceof Array)) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOneOfTheDaysOfTheWeek,
            "warning",
            6000,
         );
         return false;
      }
      if (recurrence.reoccurFld2.length === 0) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOneOfTheDaysOfTheWeek,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected monthlyNumericRecurrenceValidation(recurrence: Recurrence): boolean {
      if (Number(recurrence.reoccurFld1) < 1 || Number(recurrence.reoccurFld1) > 61) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfMonths,
            "warning",
            6000,
         );
         return false;
      }

      if (Number(recurrence.reoccurFld2) < 1 || Number(recurrence.reoccurFld2) > 31) {
         this.alertService.addAlert(
            this.lang().PleaseSelectDayOfTheMonth,
            "warning",
            6000,
         );
         return false;
      }

      return true;
   }
   protected monthlyOrdinalRecurrenceValidation(recurrence: Recurrence): boolean {
      if (Number(recurrence.reoccurFld1) < 1 || Number(recurrence.reoccurFld1) > 61) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfMonths,
            "warning",
            6000,
         );
         return false;
      }

      if (!this.pickReoccurService.isValidOrdinal(recurrence.reoccurFld2)) {
         this.alertService.addAlert(this.lang().PleaseSelectThe123or4th, "warning", 6000);
         return false;
      }

      if (!this.pickReoccurService.isValidDayOfTheWeek(recurrence.reoccurFld3)) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheDayOfTheWeek,
            "warning",
            6000,
         );
         return false;
      }

      return true;
   }

   protected yearlyNumericRecurrenceValidation(recurrence: Recurrence): boolean {
      if (Number(recurrence.reoccurFld1) < 1 || Number(recurrence.reoccurFld1) > 20) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfYears,
            "warning",
            6000,
         );
         return false;
      }

      if (Number(recurrence.reoccurFld2) < 1 || Number(recurrence.reoccurFld2) > 12) {
         this.alertService.addAlert(this.lang().PleaseSelectWhichMonth, "warning", 6000);
         return false;
      }

      if (Number(recurrence.reoccurFld3) < 1 || Number(recurrence.reoccurFld3) > 31) {
         this.alertService.addAlert(this.lang().PleaseSelectWhichDay, "warning", 6000);
         return false;
      }

      return true;
   }

   protected yearlyOrdinalRecurrenceValidation(recurrence: Recurrence): boolean {
      if (Number(recurrence.reoccurFld1) < 1 || Number(recurrence.reoccurFld1) > 20) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfYears,
            "warning",
            6000,
         );
         return false;
      }

      if (!this.pickReoccurService.isValidOrdinal(recurrence.reoccurFld2)) {
         this.alertService.addAlert(this.lang().PleaseSelectThe123or4th, "warning", 6000);
         return false;
      }

      if (!this.pickReoccurService.isValidDayOfTheWeek(recurrence.reoccurFld3)) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheDayOfTheWeek,
            "warning",
            6000,
         );
         return false;
      }

      if (!this.pickReoccurService.isValidMonthAbbreviation(recurrence.reoccurFld4)) {
         this.alertService.addAlert(this.lang().PleaseSelectWhichMonth, "warning", 6000);
         return false;
      }

      return true;
   }

   protected thresholdRecurrenceValidation(recurrence: Recurrence): boolean {
      if (
         typeof recurrence.reoccurFld1 !== "string" ||
         !recurrence.reoccurFld1.split(",").every((id) => Number(id) > 0)
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectWhichFieldYouWishToBaseThisOffOf,
            "warning",
            6000,
         );
         return false;
      }

      //if it is a between choice then it has to be a valid one.  E.G between 1 and 10 and NOT between 10 and 1.
      if (
         recurrence.reoccurFld3 === 2 &&
         Number(recurrence.reoccurFld2) > Number(recurrence.reoccurFld5)
      ) {
         this.alertService.addAlert(
            this.lang().ThisBetweenConditionWillNeverBeMet,
            "warning",
            6000,
         );
         return false;
      }

      return true;
   }

   protected everyXRecurrenceValidation(recurrence: Recurrence): boolean {
      if (
         typeof recurrence.reoccurFld1 !== "string" ||
         !recurrence.reoccurFld1.split(",").every((id) => Number(id) > 0)
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectWhichFieldYouWishToBaseThisOffOf,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected recurrenceValidation(recurrence: Recurrence): boolean {
      switch (Number(recurrence.reoccurType)) {
         case 1:
            if (!this.dailyRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 2:
            if (!this.weeklyRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 3:
            if (!this.monthlyNumericRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 4:
            if (!this.monthlyOrdinalRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 5:
            if (!this.yearlyNumericRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 6:
            if (!this.yearlyOrdinalRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 7:
            if (!this.everyXRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 8:
            if (!this.thresholdRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         default:
            this.alertService.addAlert(
               this.lang().PleaseMakeSureYouHaveSelectedARecurrenceAndDate,
               "warning",
               6000,
            );
            return false;
      }
      return true;
   }
}
