import { inject, Component, Input, computed } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   imports: [BetterDatePipe],
   selector: "task-last-edited-cell",
   templateUrl: "./task-last-edited-cell.component.html",
})
export class TaskLastEditedCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
