import type { OnInit } from "@angular/core";
import { inject, Component, Input } from "@angular/core";
import { IconComponent } from "@limblecmms/lim-ui";
import type { Tutorial } from "src/app/onboarding/services/tutorials/tutorial";
import { VideoTutorial } from "src/app/onboarding/services/tutorials/video-tutorial";
import { ManageUtil } from "src/app/shared/services/manageUtil";

@Component({
   selector: "video-thumbnail",
   templateUrl: "./video-thumbnail.component.html",
   styleUrls: ["./video-thumbnail.component.scss"],
   imports: [IconComponent],
})
export class VideoThumbnailComponent implements OnInit {
   @Input() public tutorial: Tutorial | undefined;
   protected videoTutorial: VideoTutorial | undefined;
   private readonly manageUtil = inject(ManageUtil);

   public ngOnInit(): void {
      if (!(this.tutorial instanceof VideoTutorial)) {
         throw new Error("Tutorial must be a VideoTutorial");
      }

      this.videoTutorial = this.tutorial;
   }

   protected linkToVideo(title: string, videoId: string): void {
      const source = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0${
         this.videoTutorial?.startTime ? `&start=${this.videoTutorial.startTime}` : ""
      }`;

      this.manageUtil.watchVideo({
         title: title,
         src: source,
      });
   }
}
