import { inject, Injectable } from "@angular/core";
import {
   mutationOptions,
   QueryClient,
   queryOptions,
   skipToken,
} from "@tanstack/angular-query-experimental";
import { lastValueFrom, map, switchMap, take } from "rxjs";
import type {
   LocationDetail,
   LocationSummary,
} from "src/app/locations/models/location-queries.models";
import type { LocationEntityFilters } from "src/app/locations/models/locations-flannel-api.models";
import type { SetCurrencyParams } from "src/app/locations/models/locations-php-api-models";
import { LocationMiniFlannelService } from "src/app/locations/services/api/locations-mini-flannel-api.service";
import { LocationMonolithFlannelApiService } from "src/app/locations/services/api/locations-monolith-flannel-api.service";
import { LocationsPhpApiService } from "src/app/locations/services/api/locations-php-api-service";
import { LocationResponseMapperService } from "src/app/locations/services/mappers/location-response-mapper.service";
import { TempCurrencyCode } from "src/app/purchasing/currency/utils/temp-currency-code";
import { ReadLaunchFlagService } from "src/app/shared/services/launch-flags/read/read-launch-flag.service";

@Injectable({ providedIn: "root" })
export class LocationQueriesService {
   private readonly queryClient = inject(QueryClient);
   private readonly phpApiService = inject(LocationsPhpApiService);
   private readonly launchFlagsService = inject(ReadLaunchFlagService);
   private readonly miniFlannelService = inject(LocationMiniFlannelService);
   private readonly monolithFlannelService = inject(LocationMonolithFlannelApiService);
   private readonly mapper = inject(LocationResponseMapperService);

   public detail(id: number | undefined) {
      return queryOptions({
         queryKey: ["locations", "detail", id] as const,
         queryFn:
            id === undefined
               ? skipToken
               : async (): Promise<LocationDetail> =>
                    lastValueFrom(
                       this.launchFlagsService
                          .getFlag$("locations-from-mini-flannel")
                          .pipe(
                             take(1),
                             switchMap((useMiniFlannel) =>
                                useMiniFlannel
                                   ? this.miniFlannelService.detail(id)
                                   : this.monolithFlannelService.detail(id),
                             ),
                             map((location) => this.mapper.toLocationDetail(location)),
                          ),
                    ),
      });
   }

   public list(
      requestOptions: {
         params: LocationEntityFilters;
      } = { params: {} },
   ) {
      return queryOptions({
         queryKey: ["locations", "list", requestOptions] as const,
         queryFn: async (): Promise<Array<LocationSummary>> =>
            lastValueFrom(
               this.launchFlagsService.getFlag$("locations-from-mini-flannel").pipe(
                  take(1),
                  switchMap((useMiniFlannel) =>
                     useMiniFlannel
                        ? this.miniFlannelService.list(requestOptions)
                        : this.monolithFlannelService.list(requestOptions),
                  ),
                  map((locations) =>
                     locations.map((location) => this.mapper.toLocationSummary(location)),
                  ),
               ),
            ),
      });
   }

   public setCurrency() {
      return mutationOptions({
         mutationKey: ["locations", "set_currency"] as const,
         mutationFn: async ({
            currencyCode,
            locationID,
         }: SetCurrencyParams): Promise<void> =>
            lastValueFrom(
               this.phpApiService
                  .setCurrency({
                     currencyID: TempCurrencyCode[currencyCode],
                     locationID,
                  })
                  .pipe(map(() => undefined)),
            ),
         onSuccess: (_, { locationID }) => {
            this.queryClient.invalidateQueries({
               queryKey: this.detail(locationID).queryKey,
            });
            this.queryClient.invalidateQueries({
               queryKey: this.list().queryKey,
            });
         },
      });
   }
}
