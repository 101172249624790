//component based on https://github.com/jackismissing/signature_pad and styling from the demo
import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, ElementRef, Input, ViewChild, computed } from "@angular/core";
import {
   DangerButtonComponent,
   IconButtonComponent,
   ModalService,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import SignaturePad from "signature_pad";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ApprovalAuth } from "src/app/tasks/components/approvalAuthModal/approvalAuth.modal.component";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "signature-pad",
   templateUrl: "./signaturePad.element.component.html",
   styleUrls: ["./signaturePad.element.component.scss"],
   imports: [
      NgClass,
      IconButtonComponent,
      TooltipDirective,
      PrimaryButtonComponent,
      DangerButtonComponent,
      SecondaryButtonComponent,
      BetterDatePipe,
   ],
})
export class SignaturePadComponent implements OnInit {
   @Input() public clear;
   @Input() public signature;
   @Input() public signatureInfo;
   @Input() public disapprove;
   @Input() public save;
   @Input() public showAutoSign;
   @Input() public disabled: boolean = false;
   @Input() public type: "approval" | "signature" | "saveOnly" | "image" = "signature";

   public btnSubmit;
   public btnClear;
   public btnDisapprove;
   public signaturePad;
   public canvas;
   public currentUser;
   public userID;
   public customerID;
   public userSignaturePath;
   protected signatureSaved: boolean = false;
   protected signedByUserName: string = "";

   @ViewChild("sigCanvas", { static: true, read: ElementRef }) sigCanvas?: ElementRef;

   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (this.signature) {
         this.signatureSaved = true;
      }

      if (this.type === "signature" || this.type === "saveOnly") {
         this.btnSubmit = this.lang().Save;
      } else {
         this.btnSubmit = this.lang().Approve;
         this.btnDisapprove = this.lang().Deny;
      }
      this.btnClear = this.lang().ItemClearSignature;

      this.canvas = this.sigCanvas?.nativeElement;

      this.signaturePad = new SignaturePad(this.canvas, {
         // It's Necessary to use an opaque color when saving image as JPEG;
         // this option can be omitted if only saving as PNG or SVG
         // dotSize: 2,
         // minWidth: 1.8,
         // maxWidth: 2.2
         // minDistance: 5,
         // velocityFilterWeight: 0.7
         backgroundColor: "rgb(255, 255, 255)",
      });

      this.currentUser = this.manageUser.getCurrentUser();
      this.customerID = this.currentUser.userInfo.customerID;
      this.userID = this.currentUser.userInfo.userID;
      this.userSignaturePath = this.currentUser.userInfo.userSignaturePath || null;
      this.signedByUserName = this.initializeSignedByUserName();

      window.onresize = this.resizeCanvas;
      this.resizeCanvas();

      // Ensure our signature pad element is rendered before trying to resize it
      setTimeout(() => {
         this.resizeCanvas();
      }, 10);
   }

   private initializeSignedByUserName(): string {
      if (this.signatureInfo?.userID) {
         return this.manageUser.getUserFullName(this.signatureInfo.userID);
      }
      return "";
   }

   // Adjust canvas coordinate space taking into account pixel ratio,
   // to make it look crisp on mobile devices.
   // This also causes canvas to be cleared.
   public resizeCanvas = () => {
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      // This part causes the canvas to be cleared
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d")?.scale(ratio, ratio);
      // This library does not listen for canvas changes, so after the canvas is automatically
      // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
      // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
      // that the state of this library is consistent with visual state of the canvas, you
      // have to clear it manually.
      this.signaturePad.clear();
   };

   public clearSig = () => {
      if (this.isSignatureEmpty() || this.disabled) {
         return;
      }

      this.signatureSaved = false;
      this.signaturePad.clear();
      this.signature = undefined;
      this.clear();

      // Re-calculate canvas size now that its no longer hidden
      setTimeout(() => {
         this.resizeCanvas();
      }, 10);
   };

   public saveSig = () => {
      if (this.isSignatureEmpty() || this.signatureSaved || this.disabled) {
         return;
      }

      if (
         this.manageUser.getCurrentUser().userInfo.featureESignatureVerification === 1 &&
         this.type !== "saveOnly"
      ) {
         const instance = this.modalService.open(ApprovalAuth);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: this.commitSignature,
         };
         return;
      }

      this.commitSignature();
   };

   public commitSignature = () => {
      if (this.type !== "saveOnly") {
         this.signatureInfo = {
            timestamp: new Date().getTime(),
            userID: this.userID,
         };
         this.signedByUserName = this.manageUser.getUserFullName(this.userID);
      }

      const sigData = this.getSignatureData();
      this.save(sigData);
      this.signatureSaved = true;
   };

   public disapproval = () => {
      if (this.signatureSaved || this.disabled) {
         return;
      }

      this.disapprove();
   };

   public autoSign = () => {
      if (this.isSignatureEmpty() === false || this.disabled) {
         return;
      }

      if (!this.userSignaturePath) {
         this.alertService.addAlert(this.lang().autoSignInfo, "info", 10000);
         return;
      }

      this.signature = `viewFile.php?f=upload-${this.customerID}/${this.userID}/signatures/${this.userSignaturePath}`;

      if (this.type !== "approval") {
         this.saveSig();
      }
   };

   protected isSignatureEmpty = (): boolean => {
      return this.signaturePad.isEmpty() && this.signature == undefined;
   };

   private readonly getSignatureData = () => {
      if (this.signature?.includes(this.userSignaturePath)) {
         return "autoSign";
      }

      return this.signaturePad.toDataURL();
   };
}
