import type { ElementRef, OnInit, Signal } from "@angular/core";
import {
   Component,
   computed,
   inject,
   input,
   Input,
   signal,
   viewChild,
} from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import {
   CardComponent,
   CheckboxComponent,
   IconComponent,
   isMobile,
   LoadingPopupPillComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { Chart, type ChartData, type ChartOptions, type TooltipModel } from "chart.js";
import moment, { type MomentInput } from "moment";
import { combineLatest, finalize, switchMap } from "rxjs";
import { FutureEstimatedCostsChartComponent } from "src/app/assets/components/asset-modal/components/future-estimated-costs-chart/future-estimated-costs-chart.component";
import { ChangeFieldsAssetCOOGraph } from "src/app/assets/components/changeFieldsAssetsCOOGraphModal/changeFieldsAssetCOOGraph.modal.component";
import { PlannedVsUnplannedComponent } from "src/app/assets/components/dashboards/planned-vs-unplanned/planned-vs-unplanned.component";
import { DepreciationLineChartCard } from "src/app/assets/components/depreciationLineChartElement/depreciationLineChartCard.element.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { getSegmentClicked } from "src/app/dashboards/widgets/chart-segment/chart-segment.utils";
import { ChartJSHelper } from "src/app/dashboards/widgets/chartJSHelper";
import type {
   PieGraphContent,
   TileContent,
} from "src/app/dashboards/widgets/widget/widgetContent.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import type { PartUsageEntityFilters } from "src/app/parts/components/shared/part-usage-api-service/part-usage-api.models";
import { PartUsageApiService } from "src/app/parts/components/shared/part-usage-api-service/part-usage-api.service";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { ViewListModal } from "src/app/shared/components/global/lists/viewList.modal.component";
import type { VirtualWidgetID } from "src/app/shared/components/global/virutalWidget/virtual-widget.models";
import { VirtualWidgetService } from "src/app/shared/components/global/virutalWidget/virtual-widget.service";
import {
   type DataViewerFilter,
   FilterLabelKey,
   DataViewerFiltersComponent,
   DataViewerFilterType,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type {
   RequestFilter,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { TaskStatus } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksModalsService } from "src/app/tasks/components/shared/services/tasks-modals/tasks-modals.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskWidgetModalsService } from "src/app/tasks-analytics/task-widget-modals/task-widget-modals.service";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

type AssetCreds = {
   viewManageAssets: boolean;
   editAssetSettings: boolean;
};

@Component({
   selector: "asset-report-tab",
   templateUrl: "./asset-report-tab.component.html",
   styleUrls: ["./asset-report-tab.component.scss"],
   providers: [DataViewerStateService],
   imports: [
      PanelComponent,
      DataViewerFiltersComponent,
      TooltipDirective,
      CheckboxComponent,
      IconComponent,
      CardComponent,
      PrimaryButtonComponent,
      DepreciationLineChartCard,
      PlannedVsUnplannedComponent,
      FutureEstimatedCostsChartComponent,
      LoadingPopupPillComponent,
      LocaleCurrencyPipe,
   ],
})
export class AssetReportTabComponent implements OnInit {
   private readonly manageLang = inject(ManageLang);
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly paramsService = inject(ParamsService);
   private readonly taskWidgetModals = inject(TaskWidgetModalsService);
   private readonly dataViewerStateService = inject(DataViewerStateService);
   private readonly virtualWidgetService = inject(VirtualWidgetService);
   private readonly chartJSHelper = inject(ChartJSHelper);
   readonly timeFilterState = inject(DataViewerStateService);
   protected i18n = inject(TranslationService).i18n;
   private readonly partUsageApi = inject(PartUsageApiService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;
   private readonly locationQueries = inject(LocationQueriesService);
   private readonly locationID = signal<number | undefined>(undefined);
   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationQueries.detail(this.locationID()),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode ?? []),
      this.isMultiCurrencyEnabled,
   );

   private readonly localeCurrencyPipe = new LocaleCurrencyPipe();

   private readonly tasksModalsService = inject(TasksModalsService);
   private readonly completedStart = computed(
      () => this.timeFilterState.requestOptionsState().filters?.completedStart,
   );
   private readonly completedEnd = computed(
      () => this.timeFilterState.requestOptionsState().filters?.completedEnd,
   );

   public readonly infinity = Infinity;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input() public restrict: boolean;

   public readonly assetID = input.required<number>();
   private readonly assetID$ = toObservable(this.assetID);

   protected readonly asset: Signal<Asset | undefined> = computed(() => {
      const assetID = this.assetID();
      if (assetID === undefined) return undefined;
      return this.manageAsset.getAsset(assetID);
   });

   protected readonly isIncludeChildDataVisible: Signal<boolean> = computed(() => {
      const asset = this.asset();
      if (asset === undefined) return false;
      return asset.assetChildrenIDs.length > 0;
   });

   protected readonly includeChildData: Signal<number> = computed(() => {
      const asset = this.asset();
      if (asset === undefined) return 0;
      return asset.includeChildData;
   });

   public creds: AssetCreds = {
      viewManageAssets: false,
      editAssetSettings: false,
   };
   public credAssetSettings;
   public pieColors;
   public depreciationSchedule;

   private readonly virtualWidgetIds: VirtualWidgetID[] = [
      "plannedMaintenance",
      "unplannedMaintenance",
      "plannedVsUnplanned",
      "timeSpent",
      "mtbf",
      "mttr",
      "downtimeHours",
      "partsUsed",
      "totalOperatingCost",
      "timeSpent",
      "countInvoices",
      "operatingCostsByCostTypePie",
   ];

   public isLoading = signal<boolean>(false);

   private readonly widgetResponse$ = combineLatest([
      this.assetID$,
      this.dataViewerStateService.filters$,
   ]).pipe(
      switchMap(([assetID, filters]) => {
         this.isLoading.set(true);

         return this.virtualWidgetService
            .fetchWidgetContents({
               widgetIds: this.virtualWidgetIds,
               filters: {
                  ...filters,
                  taskStatuses: [TaskStatus.Closed],
                  assetIDs: [assetID],
               },
            })
            .pipe(
               finalize(() => {
                  this.isLoading.set(false);
               }),
            );
      }),
   );

   private readonly widgetContents = toSignal(this.widgetResponse$);
   private readonly widgetDefinitions = toSignal(
      this.virtualWidgetService.fetchWidgetDefinitions({
         widgetIds: this.virtualWidgetIds,
      }),
   );
   private readonly requestOptions = toSignal<Partial<RequestOptions<RequestFilter>>>(
      this.dataViewerStateService.requestOptions$,
   );

   protected readonly completedUnplannedWOsCount: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("unplannedMaintenance");
   });

   protected readonly completedPlannedWorkCount: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("plannedMaintenance");
   });

   protected plannedVsUnplannedWOsCount: Signal<{
      planned: number;
      unplanned: number;
   }> = computed(() => {
      return this.getVirtualWidgetTileValue("plannedVsUnplanned");
   });

   protected readonly timeSpentCount: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("timeSpent");
   });

   protected readonly MTBF: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("mtbf");
   });

   protected readonly MTTR: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("mttr");
   });

   protected readonly downtimeHours: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("downtimeHours");
   });

   protected readonly partsUsed: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("partsUsed");
   });

   protected readonly totalCostOfOwnership: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("totalOperatingCost");
   });

   protected readonly totalPartsCost: Signal<number> = computed(() => {
      return this.getPieGraphValue("parts");
   });

   protected readonly totalLaborCost: Signal<number> = computed(() => {
      return this.getPieGraphValue("labor");
   });

   private readonly totalInvoiceCost: Signal<number> = computed(() => {
      return this.getPieGraphValue("invoice");
   });

   private readonly timeSpent: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("timeSpent");
   });

   protected readonly totalTimeRanInHours = computed(() => {
      return this.calculateTotalTimeRanInHours();
   });

   private readonly countInvoices: Signal<number> = computed(() => {
      return this.getVirtualWidgetTileValue("countInvoices");
   });

   private readonly pieGraphWidgetContent: Signal<PieGraphContent | undefined> = computed(
      () => {
         const widgetIndex = this.virtualWidgetIds.indexOf("operatingCostsByCostTypePie");
         return this.widgetContents()?.[widgetIndex] as PieGraphContent;
      },
   );

   private readonly pieChartViewChild = viewChild<ElementRef>("pieChartEle");
   private readonly pieChartLegendViewChild = viewChild<ElementRef>("pieChartLegendEle");

   private previousPieChart: Chart<"doughnut"> | undefined = undefined;

   // Counter used to force rerender of pie chart after user updates fields using
   // the "Change Fields" button
   private readonly changeFieldsUpdated = signal(1);

   private readonly pieData: Signal<ChartData<"doughnut">> = computed(() => {
      this.changeFieldsUpdated();
      const totalLaborCostFormatted = this.localeCurrencyPipe.transform(
         this.totalLaborCost(),
         this.currencyCode(),
      );
      const laborObj = {
         value: this.totalLaborCost(),
         backgroundColor: this.pieColors[0].color,
         color: this.pieColors[0].color,
         highlight: this.pieColors[0].highlight,
         label: `${this.lang().LaborCost} - ${Math.round(this.timeSpent())} ${this.lang().hours} | ${this.lang().Total}: ${totalLaborCostFormatted}`,
      };

      const totalPartsCostFormatted = this.localeCurrencyPipe.transform(
         this.totalPartsCost(),
         this.currencyCode(),
      );
      const partsObj = {
         value: this.totalPartsCost(),
         backgroundColor: this.pieColors[1].color,
         color: this.pieColors[1].color,
         highlight: this.pieColors[1].highlight,
         label: `${this.lang().PartsCost} - ${this.partsUsed()} ${this.lang().PartsHaveBeenUsed} | ${this.lang().Total}: ${totalPartsCostFormatted}`,
      };

      const totalInvoiceCostFormatted = this.localeCurrencyPipe.transform(
         this.totalInvoiceCost(),
         this.currencyCode(),
      );
      const invoiceObj = {
         value: this.totalInvoiceCost(),
         backgroundColor: this.pieColors[2].color,
         color: this.pieColors[2].color,
         highlight: this.pieColors[2].highlight,
         label: `${this.lang().InvoiceCost} - ${this.countInvoices()} ${this.lang().InvoicesHaveOccurred} | ${this.lang().Total}: ${totalInvoiceCostFormatted}`,
      };

      const pieChartData: ChartData<"doughnut"> = {
         datasets: [
            {
               data: [],
               backgroundColor: [],
            },
         ],
         labels: [],
      };

      // TODO TASK-898: Architect how we will handle the Change fields functionality.
      if (this.asset()?.assetTrackCOOParts === 1) {
         pieChartData.datasets[0].data.push(partsObj.value);
         if (pieChartData.datasets[0]?.backgroundColor) {
            if (Array.isArray(pieChartData.datasets[0].backgroundColor)) {
               pieChartData.datasets[0].backgroundColor.push(partsObj.backgroundColor);
            }
         }
         pieChartData.labels?.push(partsObj.label);
      }

      pieChartData.datasets[0].data.push(invoiceObj.value);
      pieChartData.labels?.push(invoiceObj.label);
      if (Array.isArray(pieChartData.datasets[0].backgroundColor)) {
         pieChartData.datasets[0].backgroundColor.push(invoiceObj.backgroundColor);
      }

      if (this.asset()?.assetTrackCOOLabor === 1) {
         pieChartData.datasets[0].data.push(laborObj.value);
         if (pieChartData.datasets[0]?.backgroundColor) {
            if (Array.isArray(pieChartData.datasets[0].backgroundColor)) {
               pieChartData.datasets[0].backgroundColor.push(laborObj.backgroundColor);
            }
         }
         pieChartData.labels?.push(laborObj.label);
      }

      return pieChartData;
   });
   protected readonly pieChart = computed(() => {
      let newPieChart;
      const pieChartElement = this.pieChartViewChild()?.nativeElement;
      if (pieChartElement instanceof HTMLCanvasElement) {
         if (this.previousPieChart !== undefined) {
            this.previousPieChart?.destroy();
         }
         newPieChart = new Chart(pieChartElement, {
            type: "doughnut",
            data: this.pieData(),
            options: this.pieChartOptions(),
            plugins: [
               {
                  id: "htmlLegend",
                  afterUpdate: (chart) => {
                     const chartId = this.pieChartLegendViewChild()?.nativeElement.id;
                     const ul = this.chartJSHelper.getOrCreateLegendList(
                        chart,
                        chartId,
                        "column",
                     );

                     while (ul.firstChild) {
                        ul.firstChild.remove();
                     }
                     this.chartJSHelper.chartHTMLLegend(chart, ul);
                  },
               },
            ],
         });
      }
      this.previousPieChart = newPieChart;
      return newPieChart;
   });

   private readonly pieChartOptions: Signal<ChartOptions<"doughnut">> = computed(() => {
      return {
         plugins: {
            legend: {
               display: false,
            },
            tooltip: {
               mode: "point",
               titleFont: {
                  size: 14,
               },
               bodyFont: {
                  size: 14,
               },
               position: "nearest" as const,
               enabled: false,
               external: (context) => {
                  if (!isMobile()) {
                     this.createCustomTooltip(context.chart, context.tooltip);
                  }
               },
            },
            htmlLegend: {
               containerID: this.pieChartLegendViewChild()?.nativeElement.id,
            },
         },

         animation: {
            animateRotate: true,
            animateScale: false,
         },

         cutout: "55%",
         aspectRatio: 1.7,

         onHover: (event, _chartElement, chart) => {
            this.chartJSHelper.onHoverHandler(event, chart);
         },
      };
   });

   protected readonly filters: DataViewerFilter[] = [
      {
         type: DataViewerFilterType.DATE,
         labelKey: FilterLabelKey.COMPLETED_DATE,
         key: "completed",
      },
   ];

   public constructor() {
      this.restrict = true;
   }

   private calculateTotalTimeRanInHours(): number {
      const asset = this.asset();
      if (!asset) return 0;

      const start = moment(this.completedStart() as MomentInput);
      const end = moment(this.completedEnd() as MomentInput);
      if (!start || !end) return 0;

      let total = 0;

      if (this.includeChildData() === 1) {
         const children = this.manageAsset.findChildrenIDs(asset, []);
         const tempHierAssetIDs = [...children, asset.assetID];

         total = tempHierAssetIDs.reduce((acc, tempAssetID) => {
            const tempAsset = this.manageAsset.getAsset(tempAssetID);

            if (!tempAsset) {
               return acc;
            }

            return acc + this.manageAsset.calculateAssetHrsRunTime(tempAsset, start, end);
         }, 0);
      } else {
         total = this.manageAsset.calculateAssetHrsRunTime(asset, start, end);
      }

      return Number(total.toFixed(2));
   }

   private getVirtualWidgetTileValue(widgetName: VirtualWidgetID) {
      const widgetIndex = this.virtualWidgetIds.indexOf(widgetName);
      const content = this.widgetContents()?.[widgetIndex];
      return (content as TileContent)?.value ?? 0;
   }

   private getPieGraphValue(segmentKey: string) {
      const matchedRow = this.pieGraphWidgetContent()?.rows.find(
         (row) => row.segmentKey === segmentKey,
      );
      return matchedRow?.value ?? 0;
   }

   private createCustomTooltip(chart: Chart<any>, tooltipModel: TooltipModel<any>) {
      const dataIndex = tooltipModel.dataPoints[0].dataIndex;
      const chartData = chart.config.data.datasets[0];
      const labels = chart.config.data.labels as Array<string>;
      if (!chartData.backgroundColor || !Array.isArray(chartData.backgroundColor)) {
         return;
      }
      const tooltipItems = [
         {
            color: chartData.backgroundColor[dataIndex],
            text: labels[dataIndex],
         },
      ];
      this.chartJSHelper.createCustomTooltip(chart, tooltipModel, tooltipItems);
   }

   protected changeFields = () => {
      const instance = this.modalService.open(ChangeFieldsAssetCOOGraph);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ChangeFieldsMsg,
            title: this.lang().ChangeFields,
            assetID: this.asset()?.assetID,
            currencyCode: this.currencyCode(),
         },
      };

      instance.result.then((result) => {
         if (result !== 0) {
            this.changeFieldsUpdated.set(this.changeFieldsUpdated() + 1);
         }
      });
   };

   public ngOnInit() {
      const asset = this.asset();

      assert(asset);

      this.depreciationSchedule = asset?.assetDepreciationID
         ? this.manageAsset.getDepreciationSchedule(asset?.assetDepreciationID)
         : null;

      this.locationID.set(asset.locationID);
      this.creds.viewManageAssets = this.credService.isAuthorized(
         asset.locationID,
         this.credService.Permissions.ViewManageAssets,
      );

      this.creds.editAssetSettings = this.credService.isAuthorized(
         asset.locationID,
         this.credService.Permissions.EditAssetSettings,
      );

      if (
         this.credService.isAuthorized(
            asset.locationID,
            this.credService.Permissions.EditAssetSettings,
         )
      ) {
         this.credAssetSettings = true;
      } else {
         this.credAssetSettings = false;
      }

      this.pieColors = [
         {
            //green | labor
            color: "#429b1f",
            highlight: "#47ab20",
         },
         {
            //red | parts
            color: "#c22528",
            highlight: "#d01f22",
         },
         {
            //light grey | invoices
            color: "#949fb1",
            highlight: "#a7afbc",
         },
      ];
   }

   protected async onSetFilter(dataViewerFilter: DataViewerFilter) {
      await this.timeFilterState.addFilter(dataViewerFilter);
   }

   protected async onRemoveFilter(dataViewerFilter: DataViewerFilter) {
      await this.timeFilterState.removeFilter(dataViewerFilter);
   }

   updateIncludeChildData = () => {
      const asset = this.asset();
      assert(asset);

      this.manageAsset
         .updateIncludeChildData(this.assetID(), this.includeChildData())
         .then((answer) => {
            if (answer.data.success === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               this.manageTask.incTasksWatchVar();
               this.manageTask.incCompletedTasksWatchVar();
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   protected showReportsWidgetModal(widgetId: VirtualWidgetID) {
      // NOTE no virtual widget for "partsUsed"
      if (widgetId === "partsUsed") {
         this.viewListOfPartsUsage();
         return;
      }

      const widgetIndex = this.virtualWidgetIds.indexOf(widgetId);
      const widgetDefinitions = this.widgetDefinitions();

      if (widgetDefinitions) {
         const widgetDef = widgetDefinitions[widgetIndex];
         const extraFilters = this.tasksModalsService.formatToExtraFilters(
            this.requestOptions() ?? {},
            [TaskStatus.Closed],
         );

         this.taskWidgetModals.openForWidget(widgetDef, extraFilters);
      } else {
         console.error("Widget definitions are null!");
      }
   }

   protected viewListOfPartsUsage(): void {
      const asset = this.asset();
      assert(asset);

      const assets: Array<Asset> = [];
      if (this.includeChildData() === 1) {
         // TODO TASK-883: findChildrenIDs may break the JIT endpoint if the asset has a lot of children. We may want to move this to the backend.
         const children = this.manageAsset.findChildrenIDs(asset, []);
         const tempHierAssetIDs: any = [];
         for (const child of children) {
            tempHierAssetIDs.push(child);
         }
         tempHierAssetIDs.push(this.assetID()); //tempHierAssetIDs will include the main asset and all of it's children and sub children
         for (const tempAssetID of tempHierAssetIDs) {
            const retrievedAsset = this.manageAsset.getAsset(tempAssetID);
            assert(retrievedAsset);
            assets.push(retrievedAsset);
         }
      } else {
         assets.push(asset);
      }

      const options: Partial<RequestOptions<PartUsageEntityFilters>> = {
         filters: {
            assetIDs: assets.map((assetToCheck) => assetToCheck.assetID),
            taskCompletedStart: this.completedStart(),
            taskCompletedEnd: this.completedEnd(),
         },
      };

      this.partUsageApi.getList(options).subscribe((partsUsageObjs) => {
         const columns = [
            {
               key: "partName",
               displayName: this.lang().PartName,
               sortBy: "partName",
               manualWidth: 2,
            },
            {
               key: "checklistCompletedDate",
               displayName: this.lang().Date,
               sortBy: "checklistCompletedDate",
               manualWidth: 2,
            },
            {
               key: "usedNumber",
               displayName: this.lang().TotalUsed,
               sortBy: "usedNumber",
               manualWidth: 4,
            },
            {
               key: "partNumber",
               displayName: this.lang().PartNumber,
               sortBy: "partNumber",
               manualWidth: 2,
            },
            {
               key: "totalPriceOfParts",
               displayName: this.lang().TotalCost,
               sortBy: "totalPriceOfParts",
               manualWidth: 2,
            },
         ];

         const resolveOptions = {
            sortBind: "-checklistCompletedDate",
            isModal: true,
         };

         const instance = this.modalService.open(ViewListModal);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               objs: partsUsageObjs.data,
               options: resolveOptions,
               modalTitle: this.lang().ListOfPartsUsed,
               columns: columns,
            },
         };
      });
   }

   protected viewGraphItem(event: MouseEvent): void {
      const widgetIndex = this.virtualWidgetIds.indexOf("operatingCostsByCostTypePie");
      const widgetDef = this.widgetDefinitions()?.[widgetIndex];

      assert(widgetDef);

      const pieGraphWidgetContent = this.pieGraphWidgetContent();

      if (this.taskWidgetModals.shouldOpenForWidget(widgetDef)) {
         const segment = pieGraphWidgetContent
            ? getSegmentClicked(event, this.pieChart(), pieGraphWidgetContent)
            : undefined;

         // TODO TASK-897 make the modals open with the correct titles.

         // ensure we don't open an empty modal
         if (segment) {
            this.taskWidgetModals.openForWidget(widgetDef, [], segment);
         }
      }
   }
}
