import {
   Component,
   effect,
   inject,
   input,
   output,
   signal,
   type WritableSignal,
} from "@angular/core";
import {
   DropdownComponent,
   DropdownTextItemComponent,
   IconComponent,
   LimbleHtmlDirective,
   NumberInputWithButtonsComponent,
} from "@limblecmms/lim-ui";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { TranslationService } from "src/app/languages/translation/translation.service";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { FieldSelectionComponent } from "src/app/schedules/recurrences/field-selection/field-selection.component";

@Component({
   selector: "threshold-settings",
   templateUrl: "./threshold-settings.component.html",
   styleUrls: ["./threshold-settings.component.scss"],
   standalone: true,
   imports: [
      IconComponent,
      LimbleHtmlDirective,
      NumberInputWithButtonsComponent,
      DropdownComponent,
      DropdownTextItemComponent,
      FieldSelectionComponent,
   ],
})
export class ThresholdSettingsComponent {
   public readonly recurrence = input.required<Recurrence>();
   public readonly field = output<AssetField>();
   public readonly fieldValue = output<AssetFieldValue>();
   public readonly constraintType = output<number>();
   public readonly constraintValue1 = output<number>();
   public readonly constraintValue2 = output<number>();

   protected readonly i18n = inject(TranslationService).i18n;

   public selectedField: WritableSignal<AssetField | null> = signal(null);
   public selectedFieldValue: WritableSignal<AssetFieldValue | null> = signal(null);
   public selectedConstraintType: WritableSignal<number | null> = signal(null);
   public selectedConstraintValue1: WritableSignal<number | null> = signal(null);
   public selectedConstraintValue2: WritableSignal<number | null> = signal(null);

   protected thresholdTranslator = [
      this.i18n().t("Below"),
      this.i18n().t("Above"),
      this.i18n().t("Between"),
   ];

   public constructor() {
      effect(() => {
         const field = this.selectedField();
         if (field === null) return;
         this.field.emit(field);
      });
      effect(() => {
         const fieldValue = this.selectedFieldValue();
         if (fieldValue === null) return;
         this.fieldValue.emit(fieldValue);
      });
      effect(() => {
         const constraintType = this.selectedConstraintType();
         if (constraintType === null) return;
         this.constraintType.emit(constraintType);
      });
      effect(() => {
         const constraintValue1 = this.selectedConstraintValue1();
         if (constraintValue1 === null) return;
         this.constraintValue1.emit(constraintValue1);
      });
      effect(() => {
         const constraintValue2 = this.selectedConstraintValue2();
         if (constraintValue2 === null) return;
         this.constraintValue2.emit(constraintValue2);
      });
      effect(
         () => {
            this.selectedConstraintType.set(this.recurrence().reoccurFld3);
         },
         { allowSignalWrites: true },
      );
      effect(
         () => {
            this.selectedConstraintValue1.set(this.recurrence().reoccurFld2);
         },
         { allowSignalWrites: true },
      );
      effect(
         () => {
            this.selectedConstraintValue2.set(this.recurrence().reoccurFld5);
         },
         { allowSignalWrites: true },
      );
   }
}
