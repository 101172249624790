import { ChangeDetectionStrategy, Component, output } from "@angular/core";
import { IconComponent, ModalComponent, ModalDirective } from "@limblecmms/lim-ui";
import { AiBadgeComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-badge/ai-badge.component";

@Component({
   selector: "ai-modal",
   templateUrl: "./ai-modal.component.html",
   styleUrls: ["./ai-modal.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [ModalComponent, ModalDirective, AiBadgeComponent, IconComponent],
})
export class AiModalComponent {
   public readonly close = output();
}
