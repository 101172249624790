@if (fieldValue !== undefined && asset !== undefined) {
   <div
      class="lim-ui-list-item"
      [ngClass]="{
         'locked-field': fieldLocked,
      }"
   >
      <div class="lim-ui-list-item-content">
         <span class="asset-info-prefix-icons">
            @if (isStandardizedField) {
               <span class="standardized-field-icon" [class.draggable]="isDraggable">
                  <lim-ui-icon
                     icon="books"
                     iconSize="small"
                     [limUiTooltip]="globalFieldToolip"
                     placement="right"
                  />
                  @if (isDraggable) {
                     <lim-ui-icon
                        icon="arrowsUpDownLeftRightRegular"
                        iconSize="small"
                        [limbleTreeDraggable]="treeBranch"
                        [limUiTooltip]="isDragging ? undefined : globalFieldToolip"
                        placement="right"
                        class="tree-drag-icon"
                     />
                  }
               </span>
            }
            @if (creds.configureAssetFields && asset.assetDeleted == 0 && !restrict) {
               @if (treeBranch && !isStandardizedField) {
                  <lim-ui-icon
                     icon="arrowsUpDownLeftRightRegular"
                     iconSize="small"
                     [limbleTreeDraggable]="treeBranch"
                     class="tree-drag-icon"
                  />
               }
               <span>{{ fieldValue.valueSort }}</span>
            }
         </span>

         <div class="asset-info-item-label-wrapper">
            <div class="icon-with-text">
               @if (fieldInfo?.fieldTypeID == AssetFieldTypeID.Currency) {
                  <span
                     limUiTooltip="{{ valueExtraInfo?.fieldTypeHint }}"
                     placement="right"
                     >{{ currencyCode() | currencySymbol }}</span
                  >
               } @else if (valueExtraInfo?.fieldTypeIcon) {
                  <lim-ui-icon
                     [icon]="valueExtraInfo?.fieldTypeIcon ?? '' | iconAlias"
                     iconSize="small"
                     limUiTooltip="{{ valueExtraInfo?.fieldTypeHint }}"
                     placement="right"
                  />
               }
               <span>
                  <span [limbleHtml]="fieldInfo?.fieldName" class="field-name"></span>
               </span>
               @if (fieldInfo?.lockedDefault) {
                  <lim-ui-icon
                     icon="lockRegular"
                     iconSize="small"
                     [limUiTooltip]="lang().FieldLockdedTooltip"
                     placement="right"
                     iconColor="medium-grey"
                  />
               }
            </div>
            <div class="mobile-asset-template-icon-container">
               @if (isStandardizedField) {
                  <lim-ui-icon
                     icon="books"
                     iconSize="small"
                     class="lim-ui-show-on-mobile mobile-asset-template-icon"
                  />
               }
               <lim-ui-dropdown
                  class="lim-ui-show-on-mobile"
                  [widthFitContent]="true"
                  secondaryIcon="fileLines"
               >
                  <lim-ui-minimal-icon-button
                     button
                     icon="ellipsisVertical"
                     iconSize="small"
                  />
                  <div class="mobile-asset-item-options-dropdown" menu>
                     @if (
                        fieldInfo?.fieldTypeID !== AssetFieldTypeID.Picture &&
                        fieldInfo?.fieldTypeID !== AssetFieldTypeID.File &&
                        fieldInfo?.fieldTypeID !== AssetFieldTypeID.Video
                     ) {
                        <lim-ui-dropdown-item (click)="viewHistory(fieldValue)">
                           <lim-ui-icon
                              class="dropdown-option-icon"
                              icon="clockRegular"
                              iconSize="medium"
                           />
                           {{ lang().ViewTheHistoryOfChanges }}
                        </lim-ui-dropdown-item>
                     }
                     @if (
                        displayAssetDepreciationButton &&
                        !isFieldLinkedToTemplate &&
                        !isStandardizedField
                     ) {
                        <lim-ui-dropdown-item (click)="setDepreciationSchedule()">
                           <div
                              class="pricing-plan-container"
                              [limUiPopover]="startPopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverPlacement]="'top'"
                              [limUiPopoverHidden]="featureCapitalDepreciation"
                           >
                              <lim-ui-icon
                                 class="dropdown-option-icon"
                                 icon="arrowDown"
                                 iconSize="medium"
                                 [ngClass]="{ paywalled: !featureCapitalDepreciation }"
                              />
                              <span
                                 [ngClass]="{ paywalled: !featureCapitalDepreciation }"
                                 >{{ lang().ConfigureAssetCapitalDepreciation }}</span
                              >
                              @if (!featureCapitalDepreciation) {
                                 <lim-ui-badge class="pricing-plan-badge" icon="stars" />
                              }
                           </div>
                        </lim-ui-dropdown-item>
                     }
                     @if (creds.configureAssetFields && !isFieldLinkedToTemplate) {
                        <lim-ui-dropdown-item (click)="removeField()">
                           <lim-ui-icon
                              class="dropdown-option-icon"
                              icon="trashCanRegular"
                              iconSize="medium"
                              iconColor="danger"
                           />
                           {{ lang().RemoveThisField }}
                        </lim-ui-dropdown-item>
                     }
                     @if (
                        creds.configureAssetFields &&
                        !isStandardizedField &&
                        !isFieldLinkedToTemplate
                     ) {
                        <lim-ui-dropdown-item
                           (click)="updateFieldViewableByTech(fieldValue)"
                        >
                           <lim-ui-icon
                              class="dropdown-option-icon"
                              [icon]="
                                 fieldValue.viewableByTech == 1 ? eyeIcon : eyeSlashIcon
                              "
                              iconSize="medium"
                           />
                           {{ lang().ShowHideField }}
                        </lim-ui-dropdown-item>
                     }
                     @if (
                        fieldInfo?.fieldTypeID == AssetFieldTypeID.Number &&
                        creds?.changeAssetFieldsValues &&
                        creds?.configureAssetFields &&
                        !isFieldLinkedToTemplate &&
                        !isStandardizedField
                     ) {
                        <lim-ui-dropdown-item
                           (click)="attachOrConfigureSensor(false, false)"
                        >
                           <lim-ui-icon
                              class="dropdown-option-icon"
                              icon="wifi"
                              iconSize="medium"
                           />
                           {{ lang().AttachOrConfigureASensor }}
                        </lim-ui-dropdown-item>
                     }
                  </div>
               </lim-ui-dropdown>
            </div>
         </div>
         <div
            [ngClass]="{
               'growing-input-and-buttons-container':
                  fieldInfo?.fieldTypeID == AssetFieldTypeID.Text,
            }"
            class="input-and-buttons-container"
         >
            <div class="item-information">
               <div
                  [ngClass]="{
                     'item-information-container':
                        fieldInfo?.fieldTypeID !== AssetFieldTypeID.Picture,
                     'full-width-text': fieldInfo?.fieldTypeID == AssetFieldTypeID.Text,
                     'flex-column':
                        fieldInfo?.fieldTypeID == AssetFieldTypeID.File ||
                        fieldInfo?.fieldTypeID == AssetFieldTypeID.Video,
                  }"
                  limUiTooltip="{{
                     fieldLocked && fieldInfo?.fieldTypeID !== AssetFieldTypeID.Picture
                        ? lang().FieldLockdedTooltip
                        : null
                  }}"
               >
                  @switch (fieldInfo?.fieldTypeID) {
                     <!--Text Field Type-->
                     @case (AssetFieldTypeID.Text) {
                        @if (asset?.assetDeleted == 0 && !restrict) {
                           @if (fieldValue !== undefined) {
                              <div
                                 class="input-control updateFieldHolder exitOnEnterKeyPress text-input"
                                 [ngClass]="{
                                    'editUnderline': creds.changeAssetFieldsValues,
                                    'disable-edit':
                                       fieldInfo?.fieldName === 'gisGlobalID',
                                 }"
                                 [(ngModel)]="fieldValue.valueContent"
                                 (afterEdit)="setFieldValue()"
                                 [limbleContentEditable]="
                                    creds.changeAssetFieldsValues &&
                                    fieldInfo?.fieldName !== 'gisGlobalID' &&
                                    !fieldLocked
                                 "
                                 stripHtml="true"
                                 limUiTooltip="{{
                                    fieldInfo?.fieldName === 'gisGlobalID'
                                       ? lang().NotEditGisGlobalField
                                       : null
                                 }}"
                              ></div>
                           }
                        } @else {
                           <!-- eslint-disable template/no-any -- No reason provided. This is a legacy disable. -->
                           <div class="updateFieldHolder">
                              <span [limbleHtml]="$any(fieldValue?.valueContent)"></span>
                              <!-- eslint-enable template/no-any-->
                           </div>
                        }
                     }

                     <!--Date Field Type-->
                     @case (AssetFieldTypeID.Date) {
                        @if (
                           creds.changeAssetFieldsValues &&
                           asset?.assetDeleted == 0 &&
                           !restrict &&
                           !fieldLocked
                        ) {
                           @if (fieldValue !== undefined) {
                              <lim-ui-date-picker-input
                                 [(model)]="fieldValue.valueContent"
                                 (modelChange)="setFieldValue()"
                                 [disabled]="saveInProgress"
                                 (modelClear)="clearFieldValue()"
                              />
                           }
                        } @else {
                           <div>{{ fieldValue?.valueContent | betterDate: "date" }}</div>
                        }
                     }

                     <!--Picture Field Type-->
                     @case (AssetFieldTypeID.Picture) {
                        @if (creds.changeAssetFieldsValues && !fieldLocked) {
                           <file-uploader
                              label="{{ lang().UploadPicture }}"
                              [uploadObject]="uploadObj"
                              icon="camera"
                              accept="images"
                           />
                        }

                        <div
                           class="images-container"
                           limUiTooltip="{{
                              fieldLocked ? lang().FieldLockdedTooltip : null
                           }}"
                        >
                           @for (
                              fileID of fieldValue?.assetValueFileIDs ?? [];
                              track fileID
                           ) {
                              <div class="img-holder-medium">
                                 @if (allFiles?.get(fileID); as file) {
                                    <editable-image
                                       [file]="file"
                                       [getURL]="getFileUploadUrl(file.fileName)"
                                       [permission]="
                                          creds.changeAssetFieldsValues && !fieldLocked
                                       "
                                       (delete)="deleteDocument($event)"
                                       [edit]="openEditor"
                                    />
                                 }
                              </div>
                           }
                        </div>
                     }

                     <!--File Type-->
                     @case (AssetFieldTypeID.File) {
                        @if (creds.changeAssetFieldsValues && !fieldLocked) {
                           <file-uploader
                              label="{{ lang().UploadDocument }}"
                              [uploadObject]="uploadObj"
                              icon="cloudArrowUp"
                              accept="all"
                           />
                        }

                        @if (fieldValue?.assetValueFileIDs?.length) {
                           <div class="file-group word-break-all">
                              @for (
                                 fileID of fieldValue.assetValueFileIDs;
                                 track fileID
                              ) {
                                 <span>
                                    @if (allFiles?.get(fileID); as file) {
                                       <view-file
                                          [file]="file"
                                          [permission]="
                                             creds.changeAssetFieldsValues &&
                                             asset?.assetDeleted == 0 &&
                                             !restrict &&
                                             !fieldLocked
                                          "
                                          [getURL]="getFileUploadUrl(file.fileName)"
                                          [delete]="deleteDocument"
                                       />
                                    }
                                 </span>
                              }
                           </div>
                        }
                     }

                     <!--Video Type-->
                     @case (AssetFieldTypeID.Video) {
                        @if (creds.changeAssetFieldsValues && !fieldLocked) {
                           <file-uploader
                              label="{{ lang().UploadVideo }}"
                              [uploadObject]="uploadObj"
                              icon="video"
                              accept="videos"
                           />
                        }

                        @if (fieldValue?.assetValueFileIDs?.length) {
                           <div class="file-group word-break-all">
                              @for (
                                 fileID of fieldValue.assetValueFileIDs;
                                 track fileID
                              ) {
                                 <span>
                                    @if (allFiles?.get(fileID); as file) {
                                       <view-file
                                          [file]="file"
                                          [permission]="
                                             creds.changeAssetFieldsValues &&
                                             asset?.assetDeleted == 0 &&
                                             !restrict &&
                                             !fieldLocked
                                          "
                                          [getURL]="getFileUploadUrl(file.fileName)"
                                          [delete]="deleteDocument"
                                       />
                                    }
                                 </span>
                              }
                           </div>
                        }
                     }

                     <!--Number Field Type-->
                     @case (AssetFieldTypeID.Number) {
                        @if (
                           fieldValue !== undefined &&
                           creds.changeAssetFieldsValues &&
                           asset?.assetDeleted == 0 &&
                           !restrict &&
                           !fieldLocked
                        ) {
                           <input
                              type="number"
                              min="0.00"
                              [assetValueReductionValidator]="fieldValue"
                              [(ngModel)]="fieldValue.valueContent"
                              (valueAccepted)="changeNumber()"
                              onclick="focus()"
                              class="input-control"
                           />
                        }

                        @if (sensorAttached && sensorConfig == 0) {
                           <span> {{ sensorUnits }} ({{ readingType }}) </span>
                        }
                        @if (sensorAttached && sensorConfig == 2) {
                           <span>
                              {{ runtimeUnits }}
                           </span>
                        }
                        @if (sensorAttached) {
                           <span>
                              <span> [{{ lang().SensorID }} {{ sensorAttached }}] </span>
                           </span>
                        }

                        @if (
                           !creds.changeAssetFieldsValues ||
                           asset?.assetDeleted == 1 ||
                           restrict ||
                           fieldLocked
                        ) {
                           <div>
                              {{ fieldValue?.valueContent }}
                           </div>
                        }
                     }

                     <!--Currency Field Type-->
                     @case (AssetFieldTypeID.Currency) {
                        <span class="lim-ui-input-icon-container">
                           @if (
                              creds.changeAssetFieldsValues &&
                              asset?.assetDeleted == 0 &&
                              !restrict &&
                              !fieldLocked
                           ) {
                              @if (asset) {
                                 <currency-input
                                    class="currency-value-input"
                                    [value]="fieldValueContentNumeric"
                                    (valueChanged)="
                                       handleCurrencyFieldValueChange($event)
                                    "
                                    (invalidInput)="handleInvalidCurrencyInput()"
                                    [localeID]="localeID"
                                    [locationID]="asset.locationID"
                                    [currencyInputSymbol]="
                                       currencyCode() | currencySymbol
                                    "
                                    [min]="0"
                                    [disabled]="
                                       fieldValue.valueDepreciationID ===
                                          asset.assetDepreciationID &&
                                       manageAsset.getDepreciationSchedule(
                                          asset.assetDepreciationID ?? -1
                                       )?.scheduleActive === 1
                                    "
                                    limUiTooltip="{{
                                       fieldValue.valueDepreciationID ===
                                          asset.assetDepreciationID &&
                                       manageAsset.getDepreciationSchedule(
                                          asset?.assetDepreciationID ?? -1
                                       )?.scheduleActive
                                          ? lang().depreciationEditMsg
                                          : null
                                    }}"
                                 />
                              }
                           } @else {
                              <div>{{ fieldValue?.valueContent }}</div>
                           }
                        </span>
                     }

                     <!--Dropdown Field Type-->
                     @case (AssetFieldTypeID.Dropdown) {
                        <!-- eslint-disable template/no-any -- No reason provided. This is a legacy disable. -->
                        @if (!fieldLocked) {
                           <lim-ui-form-dropdown-input
                              [inputString]="$any(fieldValue?.valueContent)"
                              [disabled]="
                                 !creds.changeAssetFieldsValues ||
                                 asset?.assetDeleted != 0 ||
                                 restrict
                              "
                           >
                              <ng-container menu>
                                 @if (
                                    valueExtraInfo &&
                                    valueExtraInfo.json &&
                                    valueExtraInfo.json.length >= 10
                                 ) {
                                    <lim-ui-dropdown-item [closeDropdownOnClick]="false">
                                       <lim-ui-search-box
                                          [(searchVal)]="searchBar"
                                          [placeholder]="lang().Search"
                                          (searchValChange)="dropdownSearch()"
                                       />
                                    </lim-ui-dropdown-item>
                                 }
                                 @for (name of searchOptions; track name) {
                                    <lim-ui-dropdown-item
                                       (click)="changeSelectedNameField(name)"
                                    >
                                       <span [limbleHtml]="name"></span>
                                    </lim-ui-dropdown-item>
                                 }
                              </ng-container>
                           </lim-ui-form-dropdown-input>
                        }
                        <!-- eslint-enable template/no-any-->
                        @if (fieldLocked) {
                           <span [limbleHtml]="fieldValue?.valueContent"></span>
                        }
                     }
                  }

                  <div class="lim-ui-list-item-hover-buttons">
                     <!--Date Field hover button-->
                     @if (
                        fieldInfo?.fieldTypeID == AssetFieldTypeID.Date &&
                        fieldValue?.valueContent &&
                        showDateHint &&
                        (fieldInfo?.dateReminder1 != 0 ||
                           fieldInfo?.dateReminder2 != 0 ||
                           fieldInfo?.dateReminder3 != 0)
                     ) {
                        <lim-ui-minimal-icon-button
                           icon="bellRegular"
                           iconSize="small"
                           [superMinimal]="true"
                           limUiTooltip="{{ dateReminderHint }}"
                           placement="bottom"
                           (click)="showFieldOptions(fieldValue)"
                        />
                     }
                     @if (
                        fieldValue &&
                        fieldInfo?.fieldTypeID !== AssetFieldTypeID.Picture &&
                        fieldInfo?.fieldTypeID !== AssetFieldTypeID.File &&
                        fieldInfo?.fieldTypeID !== AssetFieldTypeID.Video
                     ) {
                        <lim-ui-minimal-icon-button
                           class="lim-ui-hide-on-mobile"
                           icon="clockRegular"
                           iconSize="small"
                           [superMinimal]="true"
                           limUiTooltip="{{ lang().ViewTheHistoryOfChanges }}"
                           placement="bottom"
                           (click)="viewHistory(fieldValue)"
                        />
                     }
                  </div>
               </div>
            </div>
            @if (asset?.assetDeleted == 0 && !restrict) {
               <div class="item-action lim-ui-hide-on-mobile">
                  <div class="lim-ui-list-item-hover-buttons">
                     @if (
                        creds.configureAssetFields &&
                        !isStandardizedField &&
                        !isFieldLinkedToTemplate
                     ) {
                        <lim-ui-minimal-icon-button
                           [icon]="
                              fieldValue?.viewableByTech == 1 ? eyeIcon : eyeSlashIcon
                           "
                           iconSize="small"
                           [superMinimal]="true"
                           limUiTooltip="{{ lang().ChangeDefaultShowHideAssetCard }}"
                           placement="top"
                           (click)="updateFieldViewableByTech(fieldValue)"
                        />
                     }
                     @if (
                        fieldInfo?.fieldTypeID == AssetFieldTypeID.Number &&
                        creds?.changeAssetFieldsValues &&
                        creds?.configureAssetFields &&
                        !isFieldLinkedToTemplate &&
                        !isStandardizedField
                     ) {
                        <lim-ui-minimal-icon-button
                           icon="wifi"
                           iconSize="small"
                           [iconColor]="sensorAttached ? 'success' : 'primary'"
                           [superMinimal]="true"
                           limUiTooltip="{{ lang().AttachOrConfigureASensor }}"
                           placement="top"
                           (click)="attachOrConfigureSensor(false, false)"
                        />
                     }
                     @if (
                        displayAssetDepreciationButton &&
                        !isFieldLinkedToTemplate &&
                        !isStandardizedField
                     ) {
                        <lim-ui-minimal-icon-button
                           icon="arrowDown"
                           iconSize="small"
                           [superMinimal]="true"
                           limUiTooltip="{{ lang().ConfigureAssetCapitalDepreciation }}"
                           placement="top"
                           (click)="setDepreciationSchedule()"
                           [ngClass]="{ paywalled: !featureCapitalDepreciation }"
                           [disabled]="featureCapitalDepreciation"
                           [limUiPopover]="startPopover"
                           [limUiPopoverType]="'upsell'"
                           [limUiPopoverPlacement]="'left'"
                           [limUiPopoverHidden]="featureCapitalDepreciation"
                        />
                     }
                     @if (
                        creds.configureAssetFields &&
                        !fieldLocked &&
                        !isFieldLinkedToTemplate
                     ) {
                        <lim-ui-minimal-icon-button
                           icon="trashCanRegular"
                           iconSize="small"
                           iconColor="danger"
                           [superMinimal]="true"
                           limUiTooltip="{{ lang().RemoveThisField }}"
                           placement="top"
                           (click)="removeField()"
                        />
                     }
                  </div>
               </div>
            }
         </div>
      </div>
   </div>
}

<ng-template #startPopover>
   <upsell-popover
      [message]="lang().FeatureCapitalDepreciationPopoverMessage"
      [pricingLinkText]="lang().LearnMore"
   />
</ng-template>
