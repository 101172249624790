import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
} from "@angular/core";
import { IconComponent } from "@limblecmms/lim-ui";
import { AiBadgeComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-badge/ai-badge.component";
import { PmBuilderPanelService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel.service";

@Component({
   selector: "pm-builder-panel-header",
   templateUrl: "./pm-builder-panel-header.component.html",
   styleUrls: ["./pm-builder-panel-header.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [IconComponent, AiBadgeComponent],
})
export class PmBuilderPanelHeaderComponent {
   public readonly caretDirection = input.required<"up" | "down">();

   private readonly pmSuggestionsPanelService = inject(PmBuilderPanelService);

   public readonly count = this.pmSuggestionsPanelService.count;

   public readonly caretIcon = computed(() =>
      this.caretDirection() === "down" ? "chevronDown" : "chevronUp",
   );
}
