import type { OnInit, Signal } from "@angular/core";
import { inject, Component, Input, computed, input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { UnitConversionComponent } from "src/app/parts/unit-of-measure/components/unit-conversion/unit-conversion.component";
import { UnitLabelComponent } from "src/app/parts/unit-of-measure/components/unit-label/unit-label.component";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { CurrencyNamePipe } from "src/app/purchasing/currency/pipes/currency-name.pipe";
import { PoItemTasksHint } from "src/app/purchasing/pos/poItemTasksHintElement/poItemTasksHint.element.component";
import { PurchasableSnapshotService } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { BillTransaction } from "src/app/purchasing/types/bill-transaction.types";
import type { Bill } from "src/app/purchasing/types/bill.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type transactionExtraInfo = {
   editable: boolean;
   locationID: number;
   oldQtyReceived: number;
   glAbbr: string | undefined | null;
   itemName: string | undefined;
   itemNumber: string | undefined;
   total: number | undefined;
   state: number | undefined | null;
};

@Component({
   selector: "pr-transaction",
   templateUrl: "./prTransaction.element.component.html",
   styleUrls: ["./prTransaction.component.scss"],
   imports: [
      IconComponent,
      LimbleHtmlDirective,
      FormsModule,
      TooltipDirective,
      MinimalIconButtonComponent,
      PoItemTasksHint,
      BetterCurrencyPipe,
      BetterDatePipe,
      UnitConversionComponent,
      UnitLabelComponent,
      LocaleCurrencyPipe,
      CurrencyNamePipe,
   ],
})
export class PrTransaction implements OnInit {
   public readonly transaction = input.required<
      BillTransaction & { qtyReceived: number },
      BillTransaction
   >({
      transform: (transaction) => ({
         ...transaction,
         qtyReceived: Number(transaction.qtyReceived),
      }),
   });
   @Input() public data;
   @Input() public currencyCode!: Signal<string>;

   public CID;
   public currencySymbol;
   public readonly item = computed(() => {
      const transaction = this.transaction();
      assert(transaction.poItemID !== null);

      const item = this.managePO.getPurchaseOrderItem(transaction.poItemID);
      assert(item !== undefined);

      return item;
   });
   public startingQtyReceived;
   public max;
   public bill: Bill | undefined;
   protected transactionExtraInfo: transactionExtraInfo | undefined;

   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly credService = inject(CredService);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected readonly purchasableSnapshotService = inject(PurchasableSnapshotService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly purchasableSnapshot = computed(() =>
      this.purchasableSnapshotService.parseFromPurchaseOrderItem(this.item()),
   );
   protected readonly isUnitOfMeasureEnabled = this.unitOfMeasureService.isFeatureEnabled;

   public ngOnInit() {
      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      const item = this.item();

      let glAbbr: string | undefined | null = "";
      //set the right display HTML for the general ledger
      if (
         item.glID &&
         item.glID > 0 &&
         this.managePO.getGeneralLedger(item.glID) !== undefined
      ) {
         glAbbr = this.managePO.getGeneralLedger(item.glID)?.abbr;
      }

      const itemReceivedQty =
         this.managePO.getPurchaseOrderItemReceivedInfo(item.poItemID)?.receivedQty ?? 0;

      this.startingQtyReceived = this.transaction().qtyReceived;
      this.max = item.qty - (itemReceivedQty - this.startingQtyReceived);
      this.max = Number(this.max.toFixed(3));
      this.bill = this.managePO.getBill(this.transaction().prID);

      if (this.bill === undefined) {
         throw new Error("No Bill was found for this transaction.");
      }

      this.transactionExtraInfo = {
         editable: this.managePO.getBillEditable(this.transaction().prID),
         locationID: this.bill?.locationID,
         oldQtyReceived: this.transaction().qtyReceived,
         glAbbr: glAbbr,
         itemName: this.managePO.getPurchaseOrderItemRelatedInfo(item.poItemID)?.itemName,
         itemNumber: this.managePO.getPurchaseOrderItemRelatedInfo(item.poItemID)
            ?.itemNumber,
         total: this.managePO.calculateReceivedTransactionItemTotal(
            this.transaction().transactionID,
         ),
         state: this.managePO.getPurchaseOrder(item.poID)?.state,
      };
   }

   checkCred = (cred) => {
      assert(this.transactionExtraInfo);
      return this.credService.isAuthorized(this.transactionExtraInfo.locationID, cred);
   };

   validateQty = () => {
      if (this.transaction().qtyReceived && this.transaction().qtyReceived < 0) {
         this.transaction().qtyReceived = 0;
      }
      if (this.transaction().qtyReceived && this.transaction().qtyReceived > this.max) {
         this.transaction().qtyReceived = this.max;
      }
   };

   deletePRTransaction = () => {
      assert(this.transactionExtraInfo);
      if (
         !this.credService.isAuthorized(
            this.transactionExtraInfo.locationID,
            this.credService.Permissions.ChangeBillTransaction,
         )
      ) {
         this.alertService.addAlert(this.lang().cred158Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PRDeleteItemMsg,
            title: this.lang().PRDeleteItem,
         },
      };

      instance.result.then((result) => {
         if (result === 1) {
            this.managePO
               .deleteBillTransaction(this.transaction().transactionID)
               .then((answer) => {
                  if (answer.data.success === true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
                  }
               });
         }
      });
   };

   setPRTransactionQty = () => {
      assert(this.transactionExtraInfo);
      if (this.transactionExtraInfo.oldQtyReceived === this.transaction().qtyReceived) {
         return; //there was no change ;p
      }

      if (
         !this.credService.isAuthorized(
            this.transactionExtraInfo.locationID,
            this.credService.Permissions.ChangeBillTransaction,
         )
      ) {
         this.alertService.addAlert(this.lang().cred158Fail, "danger", 10000);
         this.transaction().qtyReceived = this.transactionExtraInfo.oldQtyReceived;
         return;
      }

      this.validateQty();
      this.managePO
         .setBillTransactionQty(
            this.transaction().transactionID,
            this.transaction().qtyReceived ?? 0,
         )
         .then((answer) => {
            assert(this.transactionExtraInfo);
            this.transactionExtraInfo.oldQtyReceived =
               this.transaction().qtyReceived ?? 0;

            if (answer?.data.success === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else if (
               answer?.data.success === false &&
               answer.data.reason === "too many"
            ) {
               this.managePO.getData().then(() => {
                  this.data.buildData();
               });

               this.alertService.addAlert(
                  this.lang().WhoopsSomeoneElseMayHaveReceived,
                  "warning",
                  6000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
            }
         });
   };
}
