import { InjectionToken, type Provider, type Resource } from "@angular/core";
import type { AssetFieldValueFile } from "src/app/assets/types/field/value/file/file.types";
import type { LimbleMap } from "src/app/shared/utils/limbleMap";
import { AssetDocumentsFromManageAssetService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/asset-documents/asset-documents-from-manage-asset.service";
import { AssetDocumentsFromMemoryService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/asset-documents/asset-documents-from-memory.service";

export type PmSuggestionsAssetDocumentsInterface = {
   /** A Resource of a map of assetIDs to document files */
   documents: Resource<LimbleMap<number, Array<AssetFieldValueFile>>>;
};

export const PmSuggestionsAssetDocuments =
   new InjectionToken<PmSuggestionsAssetDocumentsInterface>(
      "PmSuggestionsAssetDocuments",
   );

export const providePmSuggestionsAssetDocumentsFromManageAssets = (): Provider => {
   return {
      provide: PmSuggestionsAssetDocuments,
      useClass: AssetDocumentsFromManageAssetService,
   };
};

export const providePmSuggestionsAssetDocumentsFromMemory = (): Provider => {
   return {
      provide: PmSuggestionsAssetDocuments,
      useClass: AssetDocumentsFromMemoryService,
   };
};
