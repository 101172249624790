import { NgClass, NgTemplateOutlet } from "@angular/common";
import type { OnDestroy, OnInit, Signal } from "@angular/core";
import {
   Component,
   computed,
   ElementRef,
   EventEmitter,
   HostBinding,
   inject,
   Input,
   Output,
   Renderer2,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import type { Tab } from "@limblecmms/lim-ui";
import {
   EntityViewerModalComponent,
   IconButtonComponent,
   isMobile,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalService,
   ScrollContainerComponent,
   TabBarComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import $ from "jquery";
import { forkJoin, from, Subscription } from "rxjs";
import { AssetCheckInOutTabComponent } from "src/app/assets/components/asset-modal/components/asset-check-in-out-tab/asset-check-in-out-tab.component";
import { AssetChildrenTabComponent } from "src/app/assets/components/asset-modal/components/asset-children-tab/asset-children-tab.component";
import { AssetInformationTabComponent } from "src/app/assets/components/asset-modal/components/asset-information-tab/asset-information-tab.component";
import { AssetLogTabComponent } from "src/app/assets/components/asset-modal/components/asset-log-tab/asset-log-tab.component";
import { AssetPartsTabLegacyComponent } from "src/app/assets/components/asset-modal/components/asset-parts-tab/asset-parts-tab-legacy/asset-parts-tab-legacy.component";
import { AssetPartsTabComponent } from "src/app/assets/components/asset-modal/components/asset-parts-tab/asset-parts-tab.component";
import { AssetPreventativeMaintenanceTabComponent } from "src/app/assets/components/asset-modal/components/asset-preventative-maintenance-tab/asset-preventative-maintenance-tab.component";
import { AssetReportTabComponent } from "src/app/assets/components/asset-modal/components/asset-report-tab/asset-report-tab.component";
import { AssetReportTabLegacyComponent } from "src/app/assets/components/asset-modal/components/asset-report-tab-legacy/asset-report-tab-legacy.component";
import { AssetVendorsTabComponent } from "src/app/assets/components/asset-modal/components/asset-vendors-tab/asset-vendors-tab.component";
import { AssetWorkOrderTabComponent } from "src/app/assets/components/asset-modal/components/asset-work-order-tab/asset-work-order-tab.component";
import { AssetSettings } from "src/app/assets/components/assetSettingsModal/assetSettings.modal.component";
import { QRCodesViewAsset } from "src/app/assets/components/qrCodesViewAssetModal/qrCodesViewAsset.modal.component";
import { PrintDivDirective } from "src/app/assets/directives/printDiv/printDiv.directive";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageReport } from "src/app/assets/services/manageReport";
import { ManageTool } from "src/app/assets/services/manageTool";
import type { Asset } from "src/app/assets/types/asset.types";
import { ImageTile } from "src/app/files/components/imageTile/imageTile.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageTutorial } from "src/app/onboarding/services/tutorials/manageTutorial";
import type { TutorialSection } from "src/app/onboarding/services/tutorials/tutorial-section";
import type { VideoTutorial } from "src/app/onboarding/services/tutorials/video-tutorial";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { AssetInfoTabHeader } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskRefreshService } from "src/app/tasks/services/task-refresh.service";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type AssetCreds = {
   viewManageAssets: boolean;
   editAssetSettings: boolean;
};

@Component({
   selector: "asset-modal",
   templateUrl: "./asset-modal.component.html",
   styleUrls: ["./asset-modal.component.scss"],
   imports: [
      EntityViewerModalComponent,
      LimbleHtmlDirective,
      NgClass,
      ImageTile,
      MinimalIconButtonComponent,
      TooltipDirective,
      IconButtonComponent,
      PrintDivDirective,
      TabBarComponent,
      NgTemplateOutlet,
      ScrollContainerComponent,
      AssetInformationTabComponent,
      AssetWorkOrderTabComponent,
      AssetPartsTabComponent,
      AssetVendorsTabComponent,
      AssetCheckInOutTabComponent,
      AssetLogTabComponent,
      AssetReportTabComponent,
      AssetReportTabLegacyComponent,
      AssetChildrenTabComponent,
      AssetPreventativeMaintenanceTabComponent,
      AssetPartsTabLegacyComponent,
   ],
})
export class AssetModalComponent implements OnInit, OnDestroy {
   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly elementRef = inject(ElementRef);
   private readonly renderer = inject(Renderer2);
   private readonly manageTool = inject(ManageTool);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageReport = inject(ManageReport);
   private readonly manageTutorial = inject(ManageTutorial);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly refreshService = inject(RefreshService);
   private readonly taskRefreshService = inject(TaskRefreshService);

   @HostBinding("class") public readonly classes = "scroll-height-inheritance";

   @Input() public assetID: number | undefined;
   @Input() public searchFields;
   @Input() public restrict;
   @Input() public preventParentAccess;
   @Input() public navigateToTab: AssetInfoTabHeader | undefined;
   @Input() public scrollableContent: boolean = true;
   @Input() public showCloseModalButton: boolean = true;
   @Input() public dataLogOptions: DataLogEventDefinition | undefined;
   @Output() readonly closeModalEvent = new EventEmitter();

   public asset: Asset | undefined;
   public selectedLocation;
   public location;
   public restoreAssetCred;
   public credToViewAsset = false;
   public isDemo;
   public assetCheckInOut: boolean | undefined;
   public creds: AssetCreds = {
      viewManageAssets: false,
      editAssetSettings: false,
   };
   public woTile: { color: number; count: number } | undefined;
   private assetsWatchVarSub: Subscription | null | undefined;
   protected pageTabs: ReadonlyArray<Tab> = [];
   protected selectedTabId: string = "info";
   protected uploadObj;
   protected assetImageObj;

   private customerID: number | undefined;
   private readonly tutorialSection: TutorialSection;

   private featureUnlimitedVendors: boolean = false;
   private featureLimitedNumber: boolean = false;
   private featureUnlimitedParts: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription = new Subscription();
   private canBeCheckedOutSub: Subscription | undefined;

   protected isJitRemoveCallsEnabled: boolean | undefined;

   protected isJitCompletedTasksAssetsEnabled: Signal<boolean | undefined> = toSignal(
      from(this.launchFlagsService.isEnabled(Flags.JIT_CT_ASSETS)),
   );

   protected isJitCompletedTasksAssetsPhase2Enabled: Signal<boolean | undefined> =
      toSignal(from(this.launchFlagsService.isEnabled(Flags.JIT_CT_ASSETS_PHASE_2)));

   protected isCompletedTasksAssetModalEnabled: boolean | undefined;
   private readonly manageLang = inject(ManageLang);

   private readonly appInitialized$ = forkJoin([
      this.refreshService.dataInitialized(),
      this.taskRefreshService.dataInitialized(),
   ]);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.tutorialSection = this.manageTutorial.getTutorialSection("assetsModal");

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureUnlimitedVendors = isFeatureEnabledMap.featureUnlimitedVendors;
            this.featureLimitedNumber = isFeatureEnabledMap.featureLimitedNumber;
            this.featureUnlimitedParts = isFeatureEnabledMap.featureUnlimitedParts;

            this.pageTabs = [
               {
                  id: "info",
                  title: this.lang().InfoTabHeading,
                  icon: "circleInfoSolid",
               },
               {
                  id: "pm",
                  title: this.lang().PMsTabHeading,
                  icon: "wrench",
               },
               {
                  id: "wo",
                  title: this.lang().WOsTabHeading,
                  icon: "wpforms",
               },
               {
                  id: "parts",
                  title: this.lang().PartsTabHeading,
                  icon: "gears",
                  disabled: !this.featureUnlimitedParts && !this.featureLimitedNumber,
                  badgeInfo: {
                     popoverPlacement: "bottom",
                     popoverType: "upsell",
                     type: "icon",
                     icon: "stars",
                     hasFeature: this.featureUnlimitedParts || this.featureLimitedNumber,
                     message: this.lang().FeatureUnlimitedPartsPopUpMessage,
                     text: this.lang().PremiumPlus,
                     pricingLinkText: this.lang().LearnMore,
                  },
               },
               {
                  id: "vendors",
                  title: this.lang().AssetCardVendorsTabHeading,
                  icon: "addressCard",
                  disabled: !this.featureUnlimitedVendors && !this.featureLimitedNumber,
                  badgeInfo: {
                     popoverPlacement: "bottom",
                     popoverType: "upsell",
                     type: "icon",
                     icon: "stars",
                     hasFeature:
                        this.featureUnlimitedVendors || this.featureLimitedNumber,
                     message: this.lang().FeatureUnlimitedVendorsMessage,
                     text: this.lang().PremiumPlus,
                     pricingLinkText: this.lang().LearnMore,
                  },
               },
               {
                  id: "checkInOut",
                  title: this.lang().AssetCardCheckInOutTabHeading,
                  icon: "screwdriverWrench",
                  isShown: Boolean(this.asset?.canCheckOutAsTool),
               },
               {
                  id: "log",
                  title: this.lang().LogTabHeading,
                  icon: "list",
               },
               {
                  id: "reports",
                  title: this.lang().ReportsTabHeading,
                  icon: "chartColumn",
               },
               {
                  id: "showChildrenAssets",
                  title: this.lang().ChildrenTabHeading,
                  icon: "cubes",
               },
            ];
         },
      );
   }

   public ngOnInit() {
      this.initialize();
   }

   private async initialize() {
      this.isJitRemoveCallsEnabled ??= await this.launchFlagsService.isEnabled(
         Flags.JIT_CT_REMOVE_CALLS,
      );
      this.assetsWatchVarSub = this.manageAsset.assetStateAfterLoad().subscribe(() => {
         this.buildView();
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.assetsWatchVarSub);
      this.manageFeatureFlagsSub.unsubscribe();
      if (this.canBeCheckedOutSub) {
         this.canBeCheckedOutSub.unsubscribe();
      }
   }

   private buildView() {
      if (this.assetID === undefined) {
         throw new Error(
            "assetTile component requires an `assetID` input binding, but a value was not provided.",
         );
      }

      this.asset = this.manageAsset.getAsset(this.assetID);
      if (this.asset === undefined) {
         throw new Error(
            "assetTile component requires an `asset`, but no asset exists for the provided assetID.",
         );
      }

      this.calcCred();

      this.assetCheckInOut =
         this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.AssetCheckInOut,
         ) && this.manageUser.getCurrentUser().userInfo.featureAssetTools;
      this.renderer.setAttribute(
         this.elementRef.nativeElement,
         "id",
         `printAssetContainer${this.asset.assetID}`,
      );

      this.credToViewAsset =
         this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.ViewManageAssets,
         ) ||
         this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.ViewLookupAnAsset,
         ); //if they can manage assets or if they can look up an asset then they should be able to see Asset Cards
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.selectedLocation = this.manageLocation.getSelectedLocation();
      this.location = this.manageLocation.getLocationsIndex()[this.asset.locationID];
      this.asset.canCheckOutAsTool = Number(this.asset.canCheckOutAsTool);
      this.updateCheckOutTab(Boolean(this.asset.canCheckOutAsTool));

      this.createUploadObj();
      if (this.asset.assetFileName) {
         this.setImage(this.asset.assetFileName);
      }

      //this timeout is in place so that on mobile devices will pull completed data only when viewing an asset.
      //brand new customers with 0 complete tasks will have this called multiple times, but that is ok
      //as new customers will get completed tasks very quickly
      //this will only be called on mobile views

      if (!this.isJitRemoveCallsEnabled) {
         // I see no need to do this once we remove the initial getCompletedTasks call because each child component
         // will get its data JIT style.
         setTimeout(() => {
            if (isMobile()) {
               if (this.manageTask.getCompletedTasks("AssetModalComponent").size < 1) {
                  this.manageTask.fetchCompletedTasks();
               }
            }
         }, 50);
      }

      this.canBeCheckedOutSub = this.manageTool.canBeCheckedOut$?.subscribe(
         (newValue) => {
            assert(this.asset);
            if (Number(newValue.assetID) === Number(this.asset.assetID)) {
               this.asset.canCheckOutAsTool = Number(newValue.canCheckOutAsTool);
               this.updateCheckOutTab(Boolean(this.asset.canCheckOutAsTool));
            }
         },
      );
      if (this.navigateToTab) {
         this.updateSelectedTab(this.navigateToTab);
      }

      //this has to run again because of the problem associated with the dir-pagination.
      //items after 10 didn't have the watch on tasks's properly trigger.
      //if we only have it here then when we add a new open WO or PM the number doesn't update.
      this.woTile = this.manageFilters.assetTileOpenWorkOrders(
         this.manageTask.getTasks(),
         this.asset.assetID,
      );

      //if they can add assets then they can restore an asset
      this.restoreAssetCred = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.AddAssets,
      );
   }

   protected print(): void {
      this.renderer.addClass(this.elementRef.nativeElement, "printItem");
      window.print();
   }

   //This is because dir-paginate was only performing this on the first 10 assets.  This causes a problem
   //for any asset on pagenate 2+
   protected calcCred(): void {
      assert(this.asset);
      this.creds.viewManageAssets = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ViewManageAssets,
      );

      this.creds.editAssetSettings = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.EditAssetSettings,
      );
   }

   protected viewQRCodes(asset: Asset): void {
      const instance = this.modalService.open(QRCodesViewAsset);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AssetQRCodesModalMsg,
            title: this.lang().AssetQRCodes,
            asset: asset,
         },
      };
   }

   protected settings(): void {
      assert(this.asset);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.EditAssetSettings,
         )
      ) {
         this.alertService.addAlert(this.lang().cred68Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(AssetSettings);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: `${this.lang().Settings} - ${this.asset.assetName}`,
            assetID: this.asset.assetID,
         },
      };
   }

   protected restoreAsset(): void {
      if (this.restoreAssetCred == false) {
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AreYouSureYouWantToRestoreThisAsset,
            title: this.lang().AreYouSure,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            assert(this.asset);
            this.manageAsset.restoreAsset(this.asset.assetID).then((answer) => {
               if (answer.data.success == true) {
                  assert(this.asset);
                  this.asset.assetDeleted = 0;
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   }

   protected downloadAssetsInfo(): void {
      assert(this.asset);
      this.manageReport
         .downloadAssetsByLocation(this.asset.locationID, this.asset.assetID)
         .then((answer) => {
            if (answer.data.success == true) {
               this.alertService.addAlert(
                  this.lang().DownloadWillStartBriefly,
                  "success",
                  1000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   protected watchTutorial = (): void => {
      this.watchTutorialVideo("Info Tab");
   };

   private updateCheckOutTab(setting: boolean): void {
      const currentCheckInOutTab = this.pageTabs.find((tab) => tab.id === "checkInOut");
      if (currentCheckInOutTab) {
         currentCheckInOutTab.isShown = Boolean(setting && this.assetCheckInOut);
      }
   }

   public updateSelectedTab(tabID: string): void {
      this.selectedTabId = tabID;
   }

   protected createUploadObj(): void {
      assert(this.asset);
      this.uploadObj = {};
      this.uploadObj.deleteData = {};
      this.uploadObj.maxFiles = 1;
      this.uploadObj.uploadTypes = "images"; //images / documents / importFiles (excel + csv) or empty for default (images and documents)
      this.uploadObj.posturl = `phpscripts/manageAsset.php?action=makeFileAssetMainImage&locationID=${this.asset.locationID}&assetID=${this.asset.assetID}`;

      this.uploadObj.viewOnly = !this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ChangeAssetImage,
      );

      this.uploadObj.deleteCall = async () => {
         assert(this.asset);
         return axios({
            url: `phpscripts/manageAsset.php?action=deleteAssetMainImage&locationID=${this.asset.locationID}&assetID=${this.asset.assetID}`,
            method: "POST",
            data: null,
         });
      };

      this.uploadObj.deleteSuccess = () => {
         assert(this.asset);
         this.assetImageObj = null;
         this.asset.assetFileName = null;
      };

      this.uploadObj.uploadComplete = (data) => {
         if (data.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );

            //keep this timeout to counter a bug on iPhone not updating.  This timeout doesn't cause efficency issues - Bryan.
            setTimeout(() => {
               assert(this.asset);
               this.asset.assetFileName = data.fileName;
               this.setImage(data.fileName);
            }, 100);
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         }
      };
   }

   protected setImage(fileName) {
      const errPrefix = "Asset Modal - Image Error";
      assert(this.asset?.locationID, `${errPrefix} - missing location id`);
      assert(this.asset?.assetID, `${errPrefix} - missing asset id`);
      assert(this.customerID, `${errPrefix} - missing customer id`);
      assert(fileName, `${errPrefix} - missing file name`);

      const image = {
         getURL: `viewFile.php?f=upload-${this.customerID}/assets/${this.asset.locationID}/${this.asset.assetID}/${fileName}`,
         fileName: fileName,
      };
      this.assetImageObj = image;
   }

   protected emitCloseModal() {
      this.closeModalEvent.emit();
   }

   protected watchLogTutorial = (): void => {
      this.watchTutorialVideo("Log Tab");
   };

   protected watchWOTutorial = (): void => {
      this.watchTutorialVideo("WOs Tab");
   };

   protected watchAssetReportsTutorial = (): void => {
      this.watchTutorialVideo("Reports Tab");
   };

   protected watchAssetChildrenTutorial = (): void => {
      this.watchTutorialVideo("Children Tab");
   };

   protected downloadChildren(): void {
      if (!this.asset) {
         return;
      }
      const childAssets: Array<Asset> = this.manageAsset
         .findChildrenIDs(this.asset, [])
         .map((childAssetID) => {
            const asset = this.manageAsset.getAsset(childAssetID);
            assert(asset);
            return asset;
         })
         .filter((asset) => asset.assetDeleted === 0);

      const childAssetLookup = new Lookup("assetID", childAssets);

      const sortedAssets = this.sortAssetsByUserPreference(childAssetLookup);

      const featureAssetTools = Boolean(
         this.manageUser.getCurrentUser().userInfo.featureAssetTools,
      );
      this.manageAsset.downloadAssets(sortedAssets, false, false, featureAssetTools);
   }

   private sortAssetsByUserPreference(
      assets: Lookup<"assetID", Asset>,
   ): Lookup<"assetID", Asset> {
      const currentUser = this.manageUser.getCurrentUser();
      if (currentUser.userInfo.customerPickingAssetsIsOrderedBy === 1) {
         return assets.orderBy("assetOrder");
      }
      return assets.orderBy("assetName");
   }

   protected watchPMTutorial = (): void => {
      this.watchTutorialVideo("PMs Tab");
   };

   private watchTutorialVideo(tutorialName: string): void {
      const videoTutorial: VideoTutorial | undefined =
         this.tutorialSection.getVideoTutorialByName(tutorialName);
      if (!videoTutorial) {
         console.error(`No video tutorial found for ${tutorialName}`);
         return;
      }

      this.manageTutorial.watchVideoTutorial(videoTutorial);
   }
}
