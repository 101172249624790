<lim-ui-panel [collapsable]="true" [title]="lang().Comments">
   <div class="comments-wrapper">
      @for (note of comments(); track note.noteID) {
         <div
            [hidden]="!isCommentVisible(note)"
            [ngClass]="{
               printHide: note.noteHidden === 1,
            }"
         >
            <div class="lim-ui-list-item comment-list-item hoverContainer">
               @if (taskDisplayData().noteData?.get(note.noteID); as noteDisplayData) {
                  <div class="comment-list-item-content">
                     <div>
                        <span
                           class="contain-text"
                           [limbleHtml]="note?.noteMessage"
                        ></span>
                        <span class="edit-info">
                           @if (note.lastEditedTimestamp) {
                              <span
                                 [limUiTooltip]="
                                    note.lastEditedTimestamp * 1000
                                       | betterDate: 'dateTime'
                                 "
                                 >({{ lang().Edited }})</span
                              >
                           }
                        </span>

                        @if (note.relatedChecklistID) {
                           <span>
                              <u>
                                 <a
                                    class="headerLink cursor"
                                    (click)="
                                       popTask.emit({
                                          checklistID: note.relatedChecklistID,
                                          options: {},
                                       })
                                    "
                                 >
                                    <span
                                       [limbleHtml]="noteDisplayData.relatedChecklistName"
                                    ></span>
                                    - #{{ note.relatedChecklistID }}
                                 </a>
                              </u>
                           </span>
                        }
                     </div>
                     <div class="comment-footer">
                        <span>
                           {{ noteDisplayData.displayName }}
                           •
                           {{ (note.noteTimestamp ?? 0) * 1000 | betterDate: "dateTime" }}
                        </span>

                        @if (note.noteAutomaticGen === 1) {
                           <span>
                              •
                              <lim-ui-icon
                                 icon="circleQuestionRegular"
                                 iconsSize="small"
                                 [limUiTooltip]="lang().ThisCommentWasAutoGen"
                              />
                           </span>
                        }
                     </div>
                     @for (file of noteDisplayData.files; track file) {
                        <div>
                           @if (!file.isImage) {
                              <div class="comment-file-list-item">
                                 <file-list-item [file]="file" url="{{ file.getURL }}" />
                              </div>
                           }

                           @if (file.isImage) {
                              <span>
                                 <view-image [src]="file.getURL" />
                              </span>
                           }
                        </div>
                     }
                  </div>
               }
               <div class="comment-list-item-hover-buttons">
                  @if (note.noteHidden === 0 && currentUser.workOrderUser !== 1) {
                     <lim-ui-minimal-icon-button
                        icon="eyeRegular"
                        iconSize="small"
                        (click)="showOrHideNoteForExternalView(note)"
                        placement="left"
                        [limUiTooltip]="lang().ShowHideCommentTooltip"
                     />
                  }
                  @if (note.noteHidden === 1 && currentUser.workOrderUser !== 1) {
                     <lim-ui-minimal-icon-button
                        icon="eyeSlashRegular"
                        iconSize="small"
                        (click)="showOrHideNoteForExternalView(note)"
                        placement="left"
                        [limUiTooltip]="lang().ShowHideCommentTooltip"
                     />
                  }
                  @if (
                     currentUser.userInfo.userID === note.userID &&
                     (hasEditCommentCredential() || superUser()) &&
                     !note.noteAutomaticGen
                  ) {
                     <span>
                        <lim-ui-minimal-icon-button
                           [ngClass]="{
                              'lim-ui-hide-on-tablet': hideOnTablet(),
                           }"
                           icon="penToSquare"
                           iconSize="small"
                           (click)="saveNote(note)"
                        />
                     </span>
                  }
                  @if (deleteCommentCred()) {
                     <span>
                        <lim-ui-minimal-icon-button
                           [ngClass]="{
                              'lim-ui-hide-on-tablet': hideOnTablet(),
                           }"
                           icon="trashCanRegular"
                           iconSize="small"
                           iconColor="danger"
                           (click)="deleteNote(note)"
                           placement="left"
                           limUiTooltip="{{ lang().PermDeleteCommentTooltip }}"
                           dataLogLabel="task-deleteComment"
                        />
                     </span>
                  }
                  @if (
                     currentUser.userInfo.userID === note.userID &&
                     deleteCommentCred() &&
                     hasEditCommentCredential() &&
                     !note.noteAutomaticGen
                  ) {
                     <lim-ui-dropdown class="lim-ui-show-on-tablet">
                        <lim-ui-minimal-icon-button
                           button
                           icon="ellipsisVertical"
                           iconSize="small"
                        />
                        <div menu aria-labelledby="dropdown">
                           <div class="dropdown-menu-item" (click)="saveNote(note)">
                              <lim-ui-icon icon="penToSquare" iconSize="small" />
                              <span>{{ lang().EditComment }}</span>
                           </div>
                           <div class="dropdown-menu-item" (click)="deleteNote(note)">
                              <lim-ui-icon
                                 icon="trashCanRegular"
                                 iconSize="small"
                                 iconColor="danger"
                                 placement="left"
                                 limUiTooltip="{{ lang().PermDeleteCommentTooltip }}"
                                 dataLogLabel="task-deleteComment"
                              />
                              <span>{{ lang().DeleteComment }}</span>
                           </div>
                        </div>
                     </lim-ui-dropdown>
                  }
               </div>
            </div>
         </div>
      }
   </div>

   <!--Making this so anyone can edit it if open-->
   @if (task().checklistTemplate === 0 && addCommentCred()) {
      <lim-ui-secondary-button (click)="saveNote()" data-test="addNote">
         <lim-ui-icon icon="commentRegular" iconSize="small" />
         /
         <lim-ui-icon icon="cameraRegular" iconSize="small" />
         <span class="comment-button">{{ lang().AddComment }}</span>
      </lim-ui-secondary-button>
   }
</lim-ui-panel>
