import { Component, forwardRef } from "@angular/core";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
} from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import {
   CustomTagList,
   type CustomTagListInput,
} from "src/app/tasks/components/customTagListModal/customTagList.element.component";

@Component({
   selector: "custom-tag-filter",
   imports: [
      DropdownClearFilterItemComponent,
      DropdownButtonComponent,
      forwardRef(() => CustomTagList),
      TranslateDirective,
   ],
   templateUrl: "./custom-tag-filter.component.html",
})
export class CustomTagFilterComponent extends BaseFilterComponent {
   #tagFilter: string = "";

   public tagListOptions: CustomTagListInput = {
      clickTag: (tag: { name: string }) => {
         const newTag = tag.name;
         if (newTag === this.#tagFilter) {
            this.handleClearFilter();
         } else {
            this.handleSetTag(newTag);
         }
      },
      selectOne: true,
      advancedSettings: false,
      sources: [""],
   };

   public handleSetTag(tag: string): void {
      this.#tagFilter = tag;
      const filter = this.filter();

      this.set.emit({
         ...filter,
         activeLabel: this.#tagFilter,
         value: {
            [filter.key]: [this.#tagFilter],
         },
      });
   }

   public handleClearFilter(): void {
      this.#tagFilter = "";
      this.remove.emit(this.filter());
   }
}
