import type { Chart, ChartDataset, InteractionItem } from "chart.js";
import type { ChartSegment } from "src/app/dashboards/widgets/chart-segment/chart-segment.models";
import type {
   LineBarGraphContent,
   PieGraphContent,
} from "src/app/dashboards/widgets/widget/widgetContent.types";

/**
 * Maps pie graph content to an array of ChartSegments,
 * in this case the segments are the rows of the pie graph
 */
const mapPieToSegments = (content: PieGraphContent): Array<ChartSegment> => {
   return content.rows.map((item) => ({
      value: item.value,
      segmentKey: item.segmentKey ?? "",
   }));
};

/**
 * Determines the dataset that the user clicked on for a multiple line chart and
 * returns that dataset.
 */
const getRelevantDataset = (content: LineBarGraphContent, dataset: ChartDataset) => {
   let clickedValues = [...dataset.data];
   // ChartJS rounds to two decimal places. Need to ensure that numbers are rounded
   // appropriately for both sets of values for this comparison.
   if (clickedValues.every((val) => typeof val === "number")) {
      clickedValues = clickedValues.map((val) => parseFloat(val.toFixed(2)));
   }
   for (const contentDataset of content.datasets) {
      let values = contentDataset.map((obj) => obj.value);
      if (values.every((val) => typeof val === "number")) {
         values = values.map((val) => parseFloat(val.toFixed(2)));
      }
      if (clickedValues.every((val, index) => val === values[index])) {
         return contentDataset;
      }
   }
   return null;
};

/**
 * Maps line/bar graph content to an array of ChartSegments,
 * in this case the segments are part of the first dataset
 */
const mapLineToSegments = (
   content: LineBarGraphContent,
   dataset: ChartDataset,
): Array<ChartSegment> => {
   // If the content contains more than one dataset, i.e. there are multiple
   // lines for the segment, determine which one the user clicked on and use that.
   // If only one line, go ahead and use that one.
   const relevantDataset =
      content.datasets.length > 1
         ? getRelevantDataset(content, dataset)
         : content.datasets[0];
   return relevantDataset
      ? relevantDataset.map((item) => ({
           value: item.value,
           segmentKey: item.segmentKey,
           lineKey: item.lineKey,
        }))
      : [];
};

/**
 * Determines the chart segment clicked based on the event and chart data.
 */
export const getSegmentClicked = (
   event: any,
   chart: Chart<any, any>,
   content: PieGraphContent | LineBarGraphContent,
): ChartSegment | undefined => {
   if (!chart) {
      throw new Error("Chart is undefined");
   }
   // Get the active points from the chart based on the event
   const activePoints = chart.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      false,
   ) as [InteractionItem] | [];
   if (activePoints.length === 0) return undefined;

   const datasetIndex = activePoints[0].datasetIndex;
   const dataset = chart.data.datasets[datasetIndex];
   const segmentIndex = activePoints[0].index;
   const segments =
      "rows" in content ? mapPieToSegments(content) : mapLineToSegments(content, dataset);
   return segments[segmentIndex];
};
