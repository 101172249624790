import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "recreate-work-request",
   templateUrl: "./recreateWorkRequest.modal.component.html",
   styleUrls: ["./recreateWorkRequest.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      PanelComponent,
      NgClass,
      IconComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      AlertComponent,
      BasicModalFooterComponent,
      IconAlias,
   ],
})
export class RecreateWorkRequest implements OnInit {
   public selectedField;
   public selectedType;
   public selectedArr;
   public errorMsg;
   public message;
   public title;
   public checklistID: number | undefined;
   public task: Task | undefined;
   public resolve;
   public modalInstance;
   public fields;
   public allFields;
   public location;

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.selectedField = 0;
      this.selectedType = 0;
      this.selectedArr = [];

      this.errorMsg = "";
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.checklistID = this.resolve.checklistID;
      if (!this.checklistID) {
         return;
      }
      this.task = this.manageTask.getTaskLocalLookup(this.checklistID);
      if (!this.task) {
         return;
      }
      this.location = this.manageLocation.getLocationsIndex()[this.task.locationID];
      this.getTemplateOptions();

      for (const field of this.fields) {
         field.greyOut = false;
         field.selected = false;
         field.fieldID = field.checklistID;
         field.fieldName = field.name || field.emailEndpoint || "";
         field.fieldTypeIcon = field.emailEndpoint
            ? "fa-solid fa-envelope"
            : "fa-solid fa-list-ol";
         field.fieldNameTemp = `${field.fieldName}`;
      }
   }

   getTemplateOptions = () => {
      assert(this.task);
      const globalSettings = this.manageUser.getCurrentUser().userInfo;
      const tasks = this.manageTask.getTasks();
      const settings = {};
      this.allFields = [];
      if (
         this.location &&
         this.task.checklistTemplate == 0 &&
         this.task.checklistTemplateOld == 2 &&
         this.task.checklistBatchID == 300112
      ) {
         // checking for WR templates in custom dropdowns
         for (let index = 1; index <= 3; index++) {
            if (this.location[`reportProblemShowCustomDropdown${index}`] == -1) {
               //-1 means we use globally
               settings[`reportProblemShowCustomDropdown${index}`] =
                  globalSettings[`reportProblemShowCustomDropdown${index}`];
            } else {
               settings[`reportProblemShowCustomDropdown${index}`] =
                  this.location[`reportProblemShowCustomDropdown${index}`];
            }
            if (
               this.location[`reportProblemShowCustomDropdown${index}Options`] === "{}"
            ) {
               // {} means we should use global
               settings[`reportProblemShowCustomDropdown${index}Options`] =
                  globalSettings[`reportProblemShowCustomDropdown${index}Options`];
            } else {
               settings[`reportProblemShowCustomDropdown${index}Options`] =
                  this.location[`reportProblemShowCustomDropdown${index}Options`];
            }

            if (settings[`reportProblemShowCustomDropdown${index}Options`] !== "{}") {
               settings[`reportProblemShowCustomDropdown${index}OptionsArr`] = JSON.parse(
                  settings[`reportProblemShowCustomDropdown${index}Options`],
               );
               for (const setting of settings[
                  `reportProblemShowCustomDropdown${index}OptionsArr`
               ]) {
                  if (setting.checklistID > 0) {
                     this.allFields.push({
                        ...setting,
                        task: tasks.get(setting.checklistID),
                     });
                  }
               }
            }
         }
         // checking for WR templates in email options
         let emailToTask = this.manageLocation.getEmailToTask();

         if (this.task.locationID > 0) {
            emailToTask = this.manageFilters.filterArrayByLocationIDs(emailToTask, [
               this.task.locationID,
            ]);
         }

         for (const endpoint of emailToTask) {
            if (endpoint.checklistID > 0) {
               this.allFields.push({
                  ...endpoint,
                  task: tasks.get(endpoint.checklistID),
               });
            }
         }
      }
      this.fields = this.allFields;
   };

   close = () => {
      this.modalInstance.close();
   };

   focusField = (fieldToFocus) => {
      //if it is greyed out they shouldn't be able to click it
      if (!fieldToFocus.greyOut) {
         this.selectedArr = [];
         this.selectedArr.push(fieldToFocus);
         for (const field of this.fields) {
            if (fieldToFocus.fieldID == field.fieldID) {
               field.selected = true;
            } else {
               field.selected = false;
            }
         }
         this.errorMsg = "";
      }
   };

   submit = () => {
      let data: any = null;
      if (this.selectedArr.length > 0) {
         data = this.selectedArr[0];
      }
      if (data) {
         if (!data.tags) {
            data.tags = "";
         }
         this.modalInstance.close(data);
      } else {
         this.errorMsg = this.lang().PleaseSelectAWorkRequestOption;
      }
   };
}
