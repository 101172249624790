<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />

   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="main-container">
            <div
               type="text"
               [(ngModel)]="text"
               limbleContentEditable
               [attr.placeholder]="lang().PleaseEnterAReason"
               class="text-box"
            ></div>
            <div>
               {{ lang()?.ThisReasonWillBeAddedAsACommentAndAssignedBackTo }}
               <a class="cursor table-link" (click)="setAssignedBack()">{{
                  assignedBackStr
               }}</a>
            </div>
            @if (errorMsg) {
               <lim-ui-alert [title]="lang().Warning" alertType="warning">
                  <div [limbleHtml]="errorMsg"></div>
               </lim-ui-alert>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      <lim-ui-primary-button (click)="submit()">
         {{ lang()?.Deny }}
      </lim-ui-primary-button>
      <lim-ui-secondary-button (click)="close()">
         {{ lang()?.Cancel }}
      </lim-ui-secondary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
