<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="change-completed-time-container">
            <div class="change-completed-time-panel-column">
               <div class="form-group" [ngClass]="{ 'has-error': hoursError }">
                  <label class="control-label">{{ lang().Hours }}</label>
                  <input
                     type="number"
                     [(ngModel)]="hours"
                     min="0"
                     (click)="errorMsg = ''; hoursError = false; minutesError = false"
                  />
               </div>
            </div>
            <div class="change-completed-time-panel-column">
               <div class="form-group" [ngClass]="{ 'has-error': minutesError }">
                  <label class="control-label">{{ lang().Minutes }}</label>
                  <input
                     type="number"
                     [(ngModel)]="minutes"
                     min="0"
                     (click)="errorMsg = ''; hoursError = false; minutesError = false"
                  />
               </div>
            </div>
         </div>
      </lim-ui-panel>
      @if (errorMsg !== "") {
         <lim-ui-panel>
            <div class="complete-task-panel-container">
               @if (errorMsg) {
                  <lim-ui-alert [title]="lang().Warning" alertType="danger">
                     <div [limbleHtml]="errorMsg"></div>
                  </lim-ui-alert>
               }
            </div>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Change }}"
      (successClick)="submit()"
      [dataLogSubmitLabel]="lookupDataLogSubmitLabel()"
   />
</lim-ui-modal>
