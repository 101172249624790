import { inject, Injectable } from "@angular/core";
import { queryOptions } from "@tanstack/angular-query-experimental";
import { lastValueFrom, map, switchMap, take } from "rxjs";
import type { CurrencyDto } from "src/app/purchasing/currency/dto/mini-flannel/currency.dto";
import { CurrenciesMiniFlannelApiService } from "src/app/purchasing/currency/services/api/currencies/currencies-mini-flannel-api.service";
import { CurrenciesPhpApiService } from "src/app/purchasing/currency/services/api/currencies/currencies-php-api.service";
import { PhpCurrencyDtoMappingService } from "src/app/purchasing/currency/services/mapping/php-currency-dto-mapping.service";
import { TempCurrencyCode } from "src/app/purchasing/currency/utils/temp-currency-code";
import { ReadLaunchFlagService } from "src/app/shared/services/launch-flags/read/read-launch-flag.service";

@Injectable({ providedIn: "root" })
export class CurrenciesQueriesService {
   private readonly launchFlagService = inject(ReadLaunchFlagService);
   private readonly miniFlannelApiService = inject(CurrenciesMiniFlannelApiService);
   private readonly phpApiService = inject(CurrenciesPhpApiService);
   private readonly phpDtoMappingService = inject(PhpCurrencyDtoMappingService);

   public list() {
      return queryOptions({
         queryKey: ["currencies", "list"] as const,
         queryFn: async (): Promise<Array<CurrencyDto>> =>
            lastValueFrom(
               this.launchFlagService.getFlag$("get-currencies-from-mini-flannel").pipe(
                  take(1),
                  switchMap((useMiniFlannel) =>
                     useMiniFlannel
                        ? this.miniFlannelApiService.list()
                        : this.phpApiService.getCurrencyData().pipe(
                             map((response) => {
                                const currencies = response.currencies;
                                const activeCurrencies = currencies.filter(
                                   (currency) =>
                                      TempCurrencyCode[currency.currencyID] !== undefined,
                                );
                                return activeCurrencies.map((currency) =>
                                   this.phpDtoMappingService.mapDto(currency),
                                );
                             }),
                          ),
                  ),
               ),
            ),
      });
   }
}
