import { NgClass } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { IconComponent, LimbleHtmlDirective, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { TasksSchedulesNameCellViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/tasks-schedules-combined-name-cell/tasks-schedules-combined-name-cell.models";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { CalendarFacadeService } from "src/app/tasks-analytics/calendar/task-calendar/calendar-facade.service";

@Component({
   selector: "tasks-schedules-combined-name-cell",
   imports: [TooltipDirective, IconComponent, NgClass, LimbleHtmlDirective],
   templateUrl: "./tasks-schedules-combined-name-cell.component.html",
   styleUrls: ["./tasks-schedules-combined-name-cell.component.scss"],
})
export class TasksSchedulesCombinedNameCellComponent {
   // injects
   public readonly manageAsset = inject(ManageAsset);
   protected readonly facade = inject(TasksFacadeService);
   protected readonly calendarFacade = inject(CalendarFacadeService);
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   // inputs
   public data = input.required<TasksSchedulesNameCellViewModel>();
   public dataLogSection = input<string>();
   public searchHint = input<string>();

   // props
   protected isInModal = this.facade.getIsInModal();

   public async onViewTask(): Promise<void> {
      const scheduleID = this.data().scheduleID;
      if (scheduleID) {
         this.calendarFacade.openFutureTaskModal(this.data().id, scheduleID);
      } else {
         await this.facade.viewTask(this.data().id);
      }
   }

   public onViewAsset(): void {
      const assetId = this.data().assetId;
      if (assetId) {
         this.facade.viewAsset(assetId);
      }
   }
}
