<ng-container>
   <lim-ui-panel>
      <div class="step-wrapper">
         <div class="header">
            <div class="list-section">
               @if (treeBranch) {
                  <div
                     [limbleTreeDraggable]="treeBranch"
                     [ngClass]="{ 'drag-icon-section': featureCustomBudgets }"
                  >
                     <lim-ui-icon
                        class="drag-icon lim-ui-show-on-desktop"
                        icon="arrowsUpDownLeftRightRegular"
                        iconSize="small"
                     />
                  </div>
               }

               @if (step) {
                  <span
                     class="order"
                     [ngClass]="{
                        'no-feature-flag': !editBudgetCred || !featureCustomBudgets,
                     }"
                     >{{ step.order }}</span
                  >
               }

               @if (step) {
                  <div
                     type="text"
                     class="input"
                     [(ngModel)]="step.name"
                     [ngClass]="{
                        'no-feature-flag': !editBudgetCred || !featureCustomBudgets,
                     }"
                     [limbleContentEditable]="editBudgetCred && featureCustomBudgets"
                     (afterEdit)="updateBudgetWorkflowName(step)"
                     tabindex="{{ step.order }}"
                  ></div>
               }
            </div>

            <lim-ui-icon-button
               icon="trashCanRegular"
               classNames=" delete "
               (click)="deleteStep(step)"
               [disabled]="!featureCustomBudgets"
            />
         </div>
         <span>{{ lang().OnThisStepWeAreWaitingForAllPOItems }}</span>
         <div class="step-setting">
            <span>{{ lang().WhenThisStepStarts }}</span>
            <div class="settings">
               <div class="start-block">
                  <span>{{ lang().AutomaticallyAssignPOTo }}</span>
                  @if (step) {
                     <div
                        class="option"
                        (click)="setPOWorkFlowStepAssignment(step)"
                        [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                     >
                        <lim-ui-icon icon="user" iconSize="small" />
                        {{ stepDisplayName }}
                     </div>
                  }
               </div>
               <div class="end-block">
                  @if (step) {
                     <lim-ui-checkbox
                        [(model)]="step.emailSend"
                        (modelChange)="setBudgetWorkflowCheckbox(step, 'emailSend')"
                        [disabled]="!editBudgetCred || !featureCustomBudgets"
                     />
                  }
                  <div>
                     <span>
                        {{ lang().Send }}
                     </span>
                     <span
                        class="option"
                        (click)="
                           editBudgetWorkflowStepEmail(step); $event.stopPropagation()
                        "
                        [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                     >
                        {{ lang().emailMessage }}
                     </span>
                  </div>
               </div>
            </div>
         </div>

         <div class="step-setting">
            <span>{{ lang().WhileOnThisStep }}</span>
            <div class="settings">
               <div class="end-block">
                  @if (step) {
                     <lim-ui-checkbox
                        [(model)]="step.allowPOEdit"
                        (modelChange)="setBudgetWorkflowCheckbox(step, 'allowPOEdit')"
                        [disabled]="!editBudgetCred || !featureCustomBudgets"
                        [label]="lang().POCanBeEdited"
                     />
                  }
               </div>
               <div class="end-block">
                  @if (step) {
                     <lim-ui-checkbox
                        [(model)]="step.allowPR"
                        (modelChange)="setBudgetWorkflowCheckbox(step, 'allowPR')"
                        [disabled]="!editBudgetCred || !featureCustomBudgets"
                        [label]="lang().POItemsCanBeReceived"
                     />
                  }
               </div>
            </div>
         </div>
         <div class="step-setting last">
            <span>{{ lang().MoveToTheNextStepWhen }}</span>
            <div class="settings">
               <div class="end-block">
                  {{ lang().NextStepIsClickedFromWithinThePO }} {{ lang().or }}
                  {{ lang().TriggeredFromLinkWithinEmail }}
               </div>
               <div class="drop-down-block">
                  <lim-ui-checkbox
                     [(model)]="skipStepEnabled"
                     [disabled]="!editBudgetCred || !featureCustomBudgets"
                     (modelChange)="
                        updateBudgetSkipStepData(step, 'skipStepEnabled', skipStepEnabled)
                     "
                  />
                  <div class="skip-step-wrapper">
                     {{ lang().SkipStepIf }}
                     <lim-ui-dropdown-inline-text
                        [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                        class="inline-dropdown"
                        label="{{ conditionSourceDisplay }}"
                     >
                        <div menu>
                           <lim-ui-dropdown-text-item
                              [displayString]="lang().PoTotal"
                              [outputString]="lang().PoTotal"
                              [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                              (itemSelectedEvent)="
                                 updateBudgetSkipStepConditionSource('poTotal')
                              "
                           />
                           <lim-ui-dropdown-text-item
                              [displayString]="lang().EveryPOItemsTotal"
                              [outputString]="lang().EveryPOItemsTotal"
                              [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                              (itemSelectedEvent)="
                                 updateBudgetSkipStepConditionSource('everyPoItemsTotal')
                              "
                           />
                        </div>
                     </lim-ui-dropdown-inline-text>
                     {{ lang().Is }}
                     <lim-ui-dropdown-inline-text
                        label="{{ skipStepDisplay }}"
                        [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                     >
                        <div menu>
                           <lim-ui-dropdown-text-item
                              [displayString]="lang().LessOrEqualTo"
                              [outputString]="lang().LessOrEqualTo"
                              [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                              (itemSelectedEvent)="
                                 updateBudgetSkipStepDataCondition('lessThan')
                              "
                           />
                           <lim-ui-dropdown-text-item
                              [displayString]="lang().GreaterOrEqualTo"
                              [outputString]="lang().GreaterOrEqualTo"
                              [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                              (itemSelectedEvent)="
                                 updateBudgetSkipStepDataCondition('greaterThan')
                              "
                           />
                        </div>
                     </lim-ui-dropdown-inline-text>
                     @if (currencyCode()) {
                        <lim-ui-input-with-prefix
                           class="skip-step-total"
                           [disabled]="!editBudgetCred || !featureCustomBudgets"
                           [prefixText]="currencyCode() | currencySymbol"
                           [(value)]="skipStepTotal"
                           (inputBlur)="
                              updateBudgetSkipStepData(
                                 step,
                                 'skipStepTotal',
                                 skipStepTotal
                              )
                           "
                           [min]="0"
                        />
                     } @else {
                        <lim-ui-input-with-prefix
                           class="skip-step-total"
                           [disabled]="!editBudgetCred || !featureCustomBudgets"
                           [prefixText]="strCurrencySymbol"
                           [(value)]="skipStepTotal"
                           (inputBlur)="
                              updateBudgetSkipStepData(
                                 step,
                                 'skipStepTotal',
                                 skipStepTotal
                              )
                           "
                           [min]="0"
                        />
                     }
                  </div>
               </div>
            </div>
         </div>
      </div>
   </lim-ui-panel>
   <div class="chev-wrapper">
      <lim-ui-icon icon="chevronDown" iconSize="medium" />
      @if (step) {
         <div
            class="add-step"
            (click)="addStep(step.order)"
            [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
         >
            {{ lang().AddAnotherStep }}
         </div>
      }
   </div>
</ng-container>
