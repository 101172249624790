//component for viewing editable images
//class -> default img-holder-medium
//file should have getURL as an attribute

import type { OnInit } from "@angular/core";
import { inject, Component, EventEmitter, Input, Output, computed } from "@angular/core";
import { IconButtonComponent, IconComponent, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
   selector: "editable-image",
   templateUrl: "./editableImage.element.component.html",
   styleUrls: ["./editableImage.element.component.scss"],
   imports: [IconComponent, IconButtonComponent, TooltipDirective],
})
export class EditableImage implements OnInit {
   @Input() public file;
   @Input() public getURL: string | undefined;
   @Input() public permission;
   @Input() public edit;
   @Input() public holderClass;
   @Output() readonly delete = new EventEmitter();

   public crossOriginAnonymous;

   private readonly manageFiles = inject(ManageFiles);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (!this.holderClass) {
         this.holderClass = "img-holder-medium";
      }

      //includes a rather ugly test to see if user in on the dreaded ie to prevent a chrome bug which doesn't like grabbing cross-origin images after grabbing them normally
      this.crossOriginAnonymous = /Chrome|FireFox/.test(window.navigator.userAgent);
   }

   openViewer = () => {
      const src = this.file?.getURL ? this.file.getURL : this.getURL;

      if (!src) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.manageFiles.createImageViewer(src);
   };

   public deleteImage(file) {
      this.delete.emit(file);
   }
}
