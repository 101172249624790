import { PurchaseOrderItemType } from "src/app/purchasing/pos/purchase-order-item-type";
import { purchaseOrderItemDto } from "src/app/purchasing/pos/purchase-order-item.dto";
import { z } from "zod";

export const PartPurchaseOrderItemDtoSchema = purchaseOrderItemDto.extend({
   itemType: z.literal(PurchaseOrderItemType.Part),
   partID: z.number(),
});

export type PartPurchaseOrderItemDto = z.infer<typeof PartPurchaseOrderItemDtoSchema>;
