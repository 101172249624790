import { combineLatestWith, firstValueFrom, mergeMap, of, toArray } from "rxjs";
import { fetchValidData$ } from "src/app/lite/local-db/resources/resource.initializer";
import type {
   FetchValidDataParams,
   SendRequestParams,
} from "src/app/lite/local-db/resources/resource.initializer.params";
import type { RefetchAndMapWithDependenciesParams } from "src/app/lite/local-db/resources/resource.utils.params";
import { z } from "zod";

/**
 * Utility function to make an http request and
 * map the response for the resource's refetch data.
 */
export async function refetchValidData<Schema extends z.Schema>({
   url,
   params,
   validation,
}: FetchValidDataParams<Schema>): Promise<z.infer<Schema>> {
   return firstValueFrom(fetchValidData$({ url, params, validation }));
}

/**
 * Utility function to use data from other requests
 * to map this resource's refetch data.
 */
export async function refetchAndMapWithDependencies<
   T extends Record<string, unknown>,
   CollectionDependencySchema extends z.AnyZodObject,
   AdditionalDependencies extends Array<FetchValidDataParams>,
>({
   from: source,
   with: additionalDependencies,
   to: initializeFromDependencies,
}: RefetchAndMapWithDependenciesParams<
   T,
   CollectionDependencySchema,
   AdditionalDependencies
>): Promise<Array<T>> {
   const sourceCollection$ = fetchValidData$(source);
   const additionalDependencyObservableArray = additionalDependencies.map(
      (additionalDependency) => fetchValidData$(additionalDependency),
   );

   return firstValueFrom(
      sourceCollection$.pipe(
         combineLatestWith(...additionalDependencyObservableArray),
         mergeMap((dependencies) => initializeFromDependencies(of(dependencies) as any)),
         toArray(),
      ),
   );
}

/**
 * Utility function to create an http request dependency
 * of a single object when refetching a resource with dependencies.
 */
export function refetchHttpObjectDependency<Schema extends z.AnyZodObject>(
   params: FetchValidDataParams<Schema>,
): FetchValidDataParams<Schema> {
   return params;
}

/**
 * Utility function to create an http request dependency
 * of an array when refetching a resource with dependencies.
 */
export function refetchHttpArrayDependency<Schema extends z.AnyZodObject>({
   validationForEachElement,
   ...rest
}: RefetchArrayParams<Schema>): FetchValidDataParams<z.ZodArray<Schema>> {
   return { validation: z.array(validationForEachElement), ...rest };
}

interface RefetchArrayParams<Schema extends z.AnyZodObject> extends SendRequestParams {
   validationForEachElement: Schema;
}
