import { inject, Injectable } from "@angular/core";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import type { SortColumnInfo } from "src/app/shared/types/general.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({ providedIn: "root" })
export class CustomColumnListHeaderService {
   private readonly percentChanges = [100, 50, 33, 25, 20, 17, 14, 13, 11, 10];
   private readonly manageUser = inject(ManageUser);

   public setColumnsToUserUIPreferences(columnsParam, dashboardID) {
      if (!columnsParam?.length) {
         return columnsParam;
      }

      let columns = columnsParam;

      const selectedColumns =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences
            ?.customDashboardListViewColumns?.[dashboardID];
      // userSettingsMatchList is to make sure the the custom dashboard list hasn't been edited, and if it has, we
      // reset the settings and ignore any user UI preference settings because they would now be inaccurate.
      let userSettingsMatchList = true;
      if (selectedColumns && Object.keys(selectedColumns).length === columns.length) {
         for (const column of columns) {
            if (selectedColumns[column.key]) {
               column.width = selectedColumns[column.key].width;
               column.order = selectedColumns[column.key].order;
            } //for some reason, we use two different keys for who it's assigned to.  Ordering wants 'assignment', and columns want 'assignedTo'
            else if (
               column.key === "assignedTo" &&
               selectedColumns.assignment != undefined
            ) {
               column.width = selectedColumns.assignment.width;
               column.order = selectedColumns.assignment.order;
            } else {
               userSettingsMatchList = false;
               break;
            }
         }
      } else {
         userSettingsMatchList = false;
      }

      if (!userSettingsMatchList) {
         let totalPercent = 0;
         for (const [index, column] of columns.entries()) {
            if (index + 1 === columns.length) {
               column.width = 100 - totalPercent;
               column.order = index;
            } else {
               column.width = this.percentChanges[columns.length - 1];
               totalPercent += column.width;
               column.order = index;
            }
         }
      }

      if (userSettingsMatchList) {
         columns = orderBy(columns, "order");
      }
      return columns;
   }

   // A positive amountChanged means we want to increase the current widths by this percent because we are going to remove a column
   // A negative amountChanged would therefore be the opposite, we are reducing each column proportionally, so that we can add a new column
   public recalculateColumnWidth<T, K>(
      amountChanged: number,
      listViewColumns: Array<SortColumnInfo<T, K>>,
   ) {
      let newChange;
      let totalPercent = 0;
      if (amountChanged > 0) {
         // old value / new value(oldValue - change) - 1 gives us the percent we need to times the remaining percentage values by in order to get
         // the total percent back up to 100%
         newChange = 100 / (100 - amountChanged) - 1;
      } else {
         // when we are decreasing the percentages to make room for a new column, we can just use the percent changed and times it by the remaining percentages
         // so that when you add in the new column your total equals 100% again.
         newChange = amountChanged / 100;
      }
      for (const [index, column] of listViewColumns.entries()) {
         if (index + 1 === listViewColumns.length) {
            column.width = 100 - totalPercent;
         } else {
            column.width = Math.round(Number(column.width) * (1 + newChange));
            totalPercent += column.width;
         }
      }
      return listViewColumns;
   }

   public setInitialColumnLengths(listViewColumns) {
      if (!listViewColumns?.length) {
         return listViewColumns;
      }
      let totalPercent = 0;
      for (const [index, column] of listViewColumns.entries()) {
         if (index + 1 === listViewColumns.length) {
            column.width = 100 - totalPercent;
         } else {
            column.width = this.percentChanges[listViewColumns.length - 1];
            totalPercent += column.width;
         }
      }
      return listViewColumns;
   }
}
