<lim-ui-modal [showWatermark]="true" [extraLarge]="true">
   <lim-ui-basic-modal-header title=" {{ title }}" (closeModal)="close()" />
   @if (dashboardReportingLimit) {
      <div alert class="data-limiting-alert">
         <upsell-popover
            [alertDismissable]="true"
            [message]="
               dashboardReportingLimit == 30
                  ? lang().FeatureLimited30DashboardsPopoverMessage
                  : lang().FeatureLimited90DashboardsPopoverMessage
            "
            [pricingLinkText]="lang().LearnMore"
         />
      </div>
   }

   <lim-ui-modal-body [scrollable]="true" class="scroll-height-inheritance">
      <lim-ui-panel class="scroll-height-inheritance">
         <div class="task-list-header-container">
            <div class="search-and-buttons">
               <lim-ui-search-box
                  class="search-box"
                  [placeholder]="lang().Search"
                  (searchValChange)="runSearch($event)"
               />
               <!-- EXPORT DROPDOWN -->
               <lim-ui-dropdown class="lim-ui-hide-on-mobile">
                  <lim-ui-icon-button
                     button
                     icon="cloudDownload"
                     limUiTooltip="{{ lang().ExportListToExcel }}"
                     placement="bottom"
                  />
                  <div menu aria-labelledby="edit-visible-columns-dropdown">
                     <lim-ui-dropdown-text-item
                        displayString="{{ lang().ExportVisibleData }}"
                        (click)="
                           downloadExcelTasksLimited(tasks); exportDropdownToggle = false
                        "
                     />
                     <lim-ui-dropdown-text-item
                        displayString="{{ lang().ExportAllData }}"
                        (click)="downloadExcel(tasks); exportDropdownToggle = false"
                     />
                  </div>
               </lim-ui-dropdown>
               <lim-ui-icon-button
                  class="lim-ui-hide-on-mobile"
                  icon="print"
                  (click)="bulkPrintTasks()"
                  limUiTooltip="{{ lang().BulkPrintTasks }}"
                  placement="top"
               />
               @if (modalType === "openTasks") {
                  <lim-ui-primary-button
                     headerPrimaryButton
                     (click)="startWorkOrder(false)"
                     icon="plus"
                     dataLogLabel="tasks-initiateAWorkOrder"
                  >
                     {{ lang().StartWO }}
                  </lim-ui-primary-button>
               }
            </div>

            <!-- FILTERS -->
            <div class="filters-container">
               <lim-ui-filters-wrapper [noMargins]="true" title="{{ lang().Filter }}:">
                  @if (modalType === "cycleCountTasks") {
                     <lim-ui-dropdown-button
                        [label]="lang().CycleCountTasksInformation"
                        filterDropdown
                     >
                        @for (column of filterColumns; track column) {
                           <div menu>
                              <lim-ui-dropdown-text-item
                                 displayString="{{ column.displayName }}"
                                 (click)="setCycleCountTaskTypeFilter(column)"
                              />
                           </div>
                        }
                     </lim-ui-dropdown-button>
                  }
                  @if (modalType !== "cycleCountTasks") {
                     <lim-ui-dropdown-button [label]="lang().Type" filterDropdown>
                        <div menu>
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().PMs }}"
                              (click)="setTypeFilter(1)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().UnplannedWOs }}"
                              (click)="setTypeFilter(2)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().PlannedWOs }}"
                              (click)="setTypeFilter(4)"
                           />
                           <lim-ui-dropdown-divider />
                           <lim-ui-dropdown-clear-filter-item (click)="clearTypeFilter()">
                              {{ lang().ClearFilter }}
                           </lim-ui-dropdown-clear-filter-item>
                        </div>
                     </lim-ui-dropdown-button>
                  }
                  @if (modalType !== "cycleCountTasks") {
                     <lim-ui-dropdown-button [label]="lang().CustomTags" filterDropdown>
                        <div menu>
                           <custom-tag-list [data]="customTagListObj" />
                           <lim-ui-dropdown-clear-filter-item (click)="clearTagFilter()">
                              {{ lang().ClearFilter }}
                           </lim-ui-dropdown-clear-filter-item>
                        </div>
                     </lim-ui-dropdown-button>
                  }
                  <lim-ui-filter-input-wrapper filterInputs>
                     <!-- CLEAR TYPE FILTER -->
                     @if (typeFilter) {
                        <lim-ui-filter-input
                           [filterCriteria]="typeFilterName"
                           (clearFilterEvent)="clearTypeFilter()"
                        >
                           <label inputLabel> {{ lang().ActiveFilter }}:</label>
                           <b
                              ><span>{{ typeFilterName }}</span></b
                           >
                        </lim-ui-filter-input>
                     }
                     <!-- CLEAR TAG FILTERS -->
                     @if (tagFilter) {
                        <lim-ui-filter-input
                           [filterCriteria]="tagFilter"
                           (clearFilterEvent)="clearTagFilter()"
                        >
                           <label inputLabel> {{ lang().ActiveFilter }}:</label>
                           <b
                              ><span>{{ tagFilter }}</span></b
                           >
                        </lim-ui-filter-input>
                     }

                     <!-- CLEAR CYCLECOUNTFILTERS -->
                     @for (field of searchFields; track field) {
                        <div>
                           <lim-ui-filter-input
                              (clearFilterEvent)="clearTagFilterByName($index)"
                           >
                              <label inputLabel> {{ field.displayName }}:</label>
                              @if (field.inputType === "text") {
                                 <input
                                    inputElement
                                    type="text"
                                    [ngModel]="field.searchValue"
                                    (ngModelChange)="runFilter($event, field)"
                                 />
                              }
                              @if (field.inputType === "dropdown") {
                                 <lim-ui-dropdown-button
                                    inputElement
                                    button
                                    icon="rss"
                                    label="{{ field.searchValue }}"
                                    filterDropdown
                                 >
                                    @for (
                                       dropDownOption of field.dropdownOptions;
                                       track dropDownOption
                                    ) {
                                       <div menu>
                                          <lim-ui-dropdown-text-item
                                             displayString="{{ dropDownOption }}"
                                             (click)="
                                                updateFieldSearchValue(
                                                   dropDownOption,
                                                   field
                                                )
                                             "
                                          />
                                       </div>
                                    }
                                 </lim-ui-dropdown-button>
                              }

                              @if (field.inputType === "dateRange") {
                                 <lim-ui-dropdown-button
                                    inputElement
                                    id="select due date range"
                                    button
                                    icon="rss"
                                    [label]="lang().DateRange"
                                 >
                                    <div menu>
                                       <lim-ui-dropdown-date-range-picker
                                          [sectionLabel]="lang().DateRange"
                                          [startDateFieldLabel]="lang().from"
                                          [(startDate)]="searchFields[$index].beginDate"
                                          [endDateFieldLabel]="lang().To"
                                          [(endDate)]="searchFields[$index].endDate"
                                          [setButtonLabel]="lang().SetDateRange"
                                          (setButtonClicked)="
                                             setCustomDateRange($event, $index)
                                          "
                                       />
                                    </div>
                                 </lim-ui-dropdown-button>
                              }
                           </lim-ui-filter-input>
                        </div>
                     }
                  </lim-ui-filter-input-wrapper>
               </lim-ui-filters-wrapper>
            </div>
         </div>
         <!-- LIST OF TASKS -->
         <view-list-of-tasks
            [taskIDs]="taskIDs"
            [columns]="taskColumns"
            [loadingBar]="loadingBar"
            [storedSortBindValue]="sortBind"
            [noSearchResults]="noSearchResults"
            [isModal]="true"
         />
         <!-- MODAL FOOTER -->
         @if (showLoading) {
            <div class="listOptionHolder">
               <div class="loading-icon">
                  <lim-ui-loading-animation />
               </div>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
