<ng-container *translate="let t">
   <lim-ui-dropdown-button [label]="t('Priority')" filterDropdown>
      <div menu>
         @for (priority of priorityLevels(); track priority.priorityID) {
            <lim-ui-dropdown-text-item
               displayString="{{ priority.name }}"
               (click)="onSetFilter(priority.priorityID)"
            />
         }
      </div>
   </lim-ui-dropdown-button>
</ng-container>
