import {
   ChangeDetectionStrategy,
   Component,
   inject,
   output,
   signal,
   type OnInit,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { SelectComponent } from "src/app/shared/empowered/base/select/select.component";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "e-page-size-select",
   imports: [SelectComponent, TranslateDirective, FormsModule],
   templateUrl: "./page-size-select.component.html",
   styleUrls: ["./page-size-select.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSizeSelectComponent implements OnInit {
   private readonly manageUser = inject(ManageUser);

   /** Emits the page size change event */
   readonly changePageSize = output<number>();

   /**
    * The available page size options
    */
   readonly pageSizeOptions = [
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
   ];

   /**
    * The current page size
    */
   readonly pageSize = signal<{ label: string; value: number }>(this.pageSizeOptions[1]);

   public ngOnInit(): void {
      // Set the initial page size
      const initialPageSize =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage;
      this.setPageSize(initialPageSize);
   }

   public onPageSizeChange(event: any): void {
      const pageSize = Number(event.value);
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage = pageSize;
      this.setPageSize(pageSize);
   }

   private setPageSize(pageSize: number): void {
      const pageSizeOption = this.pageSizeOptions.find(
         (option) => option.value === pageSize,
      );
      this.changePageSize.emit(pageSize);
      this.pageSize.set(pageSizeOption ?? this.pageSizeOptions[0]);
   }
}
