import { Component, computed, inject, input, output } from "@angular/core";
import { CardComponent } from "@limblecmms/lim-ui";
import { CountUpDirective } from "src/app/assets/directives/countUp/countUp.directive";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "planned-vs-unplanned",
   imports: [CardComponent, CountUpDirective],
   templateUrl: "./planned-vs-unplanned.component.html",
   styleUrl: "./planned-vs-unplanned.component.scss",
})
export class PlannedVsUnplannedComponent {
   private readonly manageLang = inject(ManageLang);

   public readonly plannedWOsCount = input.required<number>();
   public readonly unplannedWOsCount = input.required<number>();

   public readonly showCompletedPreventiveWorkOrders = output();
   public readonly showCompletedWorkOrders = output();

   // We convert the decimals to integers so we can pass them into the count up directive.
   public plannedWOsPercent = computed(() => this.plannedWOsCount() * 100);
   public unplannedWOsPercent = computed(() => this.unplannedWOsCount() * 100);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
