<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (!successfulImport && !importAttempt) {
         <lim-ui-panel>
            <div class="steps">
               <h1>{{ i18n().t("ToImportCompletedTasks") }}</h1>
               <ol>
                  <li>
                     {{ i18n().t("Watch") }}
                     <a
                        class="emphasized-strings"
                        href="https://youtu.be/THkRzg81gMk"
                        target="_blank"
                     >
                        {{ i18n().t("HowToImportCompletedTasks") }}</a
                     >
                     {{ i18n().t("OrRead") }}
                     <a
                        class="emphasized-strings"
                        href="https://help.limblecmms.com/en/articles/3085249"
                        target="_blank"
                        >{{ i18n().t("HowToImportCompletedTasks") }}</a
                     >
                  </li>
                  <li>
                     {{ i18n().t("DownloadThe")
                     }}<a
                        class="emphasized-strings"
                        href="assets/downloads/tasks/sampleCompletedTaskImport.xlsx"
                        download="Sample Completed Tasks Import.xlsx"
                     >
                        {{ i18n().t("SampleImportFile") }}
                     </a>
                  </li>
                  <li>
                     <b class="bold"
                        >{{ i18n().t("DoNotRemoveThe") }}
                        <span class="red">{{ i18n().t("RequiredFields") }} </span>
                        {{ i18n().t("InRow1") }}</b
                     >
                     <div class="list-spacing">
                        <ul>
                           <li>
                              <span class="red">
                                 {{ i18n().t("TaskName") }}
                              </span>
                              <lim-ui-icon
                                 icon="circleQuestionRegular"
                                 iconSize="small"
                                 limUiTooltip="{{
                                    i18n().t('TheTaskNameColumnIsRequiredTooltip')
                                 }}"
                              />
                           </li>
                           <li>
                              <span class="red">{{ i18n().t("Instruction1") }}</span>
                              <lim-ui-icon
                                 icon="circleQuestionRegular"
                                 iconSize="small"
                                 limUiTooltip="{{
                                    i18n().t('Instruction1CompTaskColumnTooltip')
                                 }}"
                              />
                           </li>
                           <li>
                              <span class="red">{{
                                 i18n().t("CompletedOnDateFormat")
                              }}</span>
                              <lim-ui-icon
                                 icon="circleQuestionRegular"
                                 iconSize="small"
                                 limUiTooltip="{{ i18n().t('CompletedOnColumnTooltip') }}"
                              />
                           </li>
                           <li>
                              <span class="red">{{
                                 i18n().t("CompletedByUserLogin")
                              }}</span>
                              <lim-ui-icon
                                 icon="circleQuestionRegular"
                                 iconSize="small"
                                 limUiTooltip="{{ i18n().t('CompletedByColumnTooltip') }}"
                              />
                           </li>
                        </ul>
                     </div>
                  </li>
                  <li>
                     <span class="bold">{{ i18n().t("FormatTheSpreadSheet") }}</span>
                     <div class="list-spacing">
                        <ul>
                           <li>
                              {{ i18n().t("ClearOutTheSampleDataInRows") }}
                           </li>
                           <li>
                              <span
                                 >{{
                                    i18n().t(
                                       "FillInTheSpreadsheetWithYourDataYouCanUploadAMax"
                                    )
                                 }}
                                 2000 {{ i18n().t("CompletedTasksLowerCase") }}
                                 {{ i18n().t("perImport") }}</span
                              >
                           </li>
                        </ul>
                     </div>
                  </li>
                  <li>
                     <b class="bold">{{ i18n().t("SaveAndUploadTheFile") }}.</b>
                  </li>
                  <li>
                     <b class="bold">{{ i18n().t("ReviewYourResults") }}</b>
                  </li>
               </ol>
            </div>
         </lim-ui-panel>
      }

      @if (errorMsg) {
         <div class="error-panel">
            <lim-ui-panel>
               <div class="bad-data">
                  <div class="error-list">
                     <div class="error">
                        <div class="error-list-nested">
                           <div class="red-color">
                              {{ i18n().t("Warning") }}! -
                              {{ i18n().t("NoCompletedTasksWereImported") }}
                           </div>

                           <div [limbleHtml]="errorMsg"></div>
                        </div>
                     </div>
                  </div>
                  @if (
                     importAttempt && failedPseudoTasks && failedPseudoTasks?.length > 0
                  ) {
                     <div class="error-list error-list-children">
                        @for (
                           failedPseudoTask of failedPseudoTasks;
                           track failedPseudoTask
                        ) {
                           <div class="error">
                              <div class="error-list">
                                 <span class="red-color">
                                    <lim-ui-icon
                                       icon="xmark"
                                       iconSize="small"
                                       iconColor="danger"
                                       class="error-triangle"
                                    />
                                    {{ i18n().t("InvalidDataOnRow") }}
                                    {{ failedPseudoTask.count + 2 }} -
                                    {{ failedPseudoTask.taskName }}
                                 </span>

                                 @for (
                                    reason of failedPseudoTask.failedReasons;
                                    track reason
                                 ) {
                                    <div class="error-list-nested">
                                       {{ reason }}
                                    </div>
                                 }
                              </div>
                           </div>
                        }
                     </div>
                  }
               </div>
            </lim-ui-panel>
         </div>
      }

      @if (successfulImport) {
         <lim-ui-panel>
            <div class="success-message">{{ i18n().t("Success") }}!</div>
            @for (pseudoTask of addedPseudoTasks; track pseudoTask) {
               <div
                  class="lim-ui-list-item lim-ui-selectable-list-item lim-ui-selected-list-item"
               >
                  <div class="lim-ui-list-item-content">
                     <span
                        class="cursor added-tasks-container"
                        (click)="popTask(pseudoTask.checklistID)"
                     >
                        <lim-ui-icon icon="check" iconColor="success" />

                        @if (pseudoTask.checklistTemplateOld == 1) {
                           <lim-ui-icon icon="wrench" />
                        }
                        @if (pseudoTask.checklistTemplateOld == 2) {
                           <lim-ui-icon icon="wpforms" />
                        }
                        @if (pseudoTask.checklistTemplateOld == 4) {
                           <lim-ui-icon icon="file" />
                        }
                        @if (pseudoTask.checklistTemplateOld == 5) {
                           <lim-ui-icon icon="gears" />
                        }

                        <b>{{ pseudoTask.taskName }}</b> -
                        <b class="green-color">{{ i18n().t("SuccessfulImport") }}</b>
                     </span>

                     <div>
                        <lim-ui-icon
                           icon="xmarkLarge"
                           iconColor="danger"
                           (click)="deleteTask(pseudoTask.checklistID)"
                           data-placement="left"
                           limUiTooltip="{{
                              i18n().t('DeleteThisNewlyImportedCompletedTask')
                           }}"
                        />

                        @if (pseudoTask.assetID > 0) {
                           <lim-ui-icon
                              icon="cube"
                              (click)="popAsset(pseudoTask.assetID)"
                              data-placement="left"
                              limUiTooltip="{{
                                 i18n().t('TaskALookAtTheAssetThisCompletedTaskBelongsTo')
                              }}"
                           />
                        }
                     </div>
                  </div>
               </div>
            }
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (!successfulImport) {
         @if (uploadObject) {
            <file-uploader
               [uploadObject]="uploadObject"
               label="{{ i18n().t('UploadFile') }}"
               buttonType="primary"
               [loadingIndicator]="false"
               accept="import"
            />
         }
      }
   </lim-ui-modal-footer>
</lim-ui-modal>
