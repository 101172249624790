import { Component, input } from "@angular/core";
import { TooltipDirective } from "@limblecmms/lim-ui";
import type { UnitDisplay } from "src/app/purchasing/pos/purchasable-snapshot/unit-display.model";

@Component({
   selector: "unit-label",
   templateUrl: "./unit-label.component.html",
   imports: [TooltipDirective],
})
export class UnitLabelComponent {
   public readonly unitDisplay = input.required<UnitDisplay>();
}
