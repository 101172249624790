import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DropdownButtonComponent,
   DropdownTextItemComponent,
   FilterInputWrapperComponent,
   FiltersWrapperComponent,
   FormDropdownInputComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   RadioButtonComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   UpsellPopover,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import type { PartVendorRelation } from "src/app/parts/types/vendors-relations/vendor-relation.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { LocationHierarchyService } from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy.service";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { REVERSE } from "src/app/shared/utils/sortingHelpers";
import { PickTasks } from "src/app/tasks/components/pickTasksModal/pickTasks.modal.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { SetupWorkOrder } from "src/app/tasks/components/setupWorkOrderModal/setupWorkOrder.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

type PartHierarchyNode = HierarchyNode & {
   partID?: number;
};

@Component({
   selector: "pick-p-o-items-part-service",
   templateUrl: "./pickPOItemsPartService.modal.component.html",
   styleUrls: ["./pickPOItemsPartService.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      AlertComponent,
      PanelComponent,
      RadioButtonComponent,
      SearchAllWrapperComponent,
      SearchBoxComponent,
      FiltersWrapperComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      FilterInputWrapperComponent,
      IconComponent,
      HierarchyContainerLegacy,
      ContenteditableDirective,
      FormsModule,
      FormDropdownInputComponent,
      PrimaryButtonComponent,
      TooltipDirective,
      NgClass,
      LimbleHtmlDirective,
      ModalFooterComponent,
      SecondaryButtonComponent,
      BetterDatePipe,
      BetterDecimalPipe,
      PopoverDirective,
      UpsellPopover,
   ],
})
export class PickPOItemsPartService implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public purchaseOrderItem: PurchaseOrderItem | undefined;
   public purchaseOrder: PurchaseOrder | undefined;
   public text;
   public createPartShow;
   public locationID;
   public vendorName;
   public showOnlyVendorParts;
   public currencySymbol;
   public selectOne;
   public creatingPart;
   public serviceDescription;
   public otherDescription;
   public newService;
   public newChecklistID;
   public allPartsLength;
   public etNewPartLocation;
   public newPartLocationID;
   public newPartLocationName;
   public task: Task | undefined;
   public pastServices: Lookup<
      "poItemID",
      PurchaseOrderItem & {
         poNumber?: number | null;
         selected: boolean;
         date: number | null;
      }
   > = new Lookup("poItemID");
   public filteredPastServices: Lookup<
      "poItemID",
      PurchaseOrderItem & {
         poNumber?: number | null;
         selected?: boolean;
         date?: number | null;
      }
   > = new Lookup("poItemID");
   public searchBar;
   public singleLocation;
   public selectedLocation;
   public partsLength;
   public newPartName;
   public oldQty;
   public oldRate;
   public treeData: Array<PartHierarchyNode> = [];
   public loadCount;
   public loadedHier;
   public serviceQty;
   public serviceRate;
   public timeoutSearchParts;
   public parts: Lookup<"partID", Part> = new Lookup("partID");
   public locations;
   public searchParts: string = "";
   public searchServices: string = "";
   public newLocations;
   public createPartCred;
   public createPartDisabled;
   public newPartNumber;
   public newPartPrice;
   public showOnlyUnderstockedParts = false;
   public showOnlyPartsWithoutOpenPOs: boolean = false;
   public partNodes: Map<number, PartHierarchyNode> = new Map();
   public associatedPOsByPart: Map<number, Lookup<"poID", PurchaseOrder>> = new Map();
   public partsSearchHints: Map<number, string> = new Map();
   public assetNameStr: string | undefined;
   public hierarchyOptions: HierarchyOptions;
   public newItemType: number | undefined;
   private readonly manageFeatureFlagsSub: Subscription;
   protected canAddParts: boolean = false;

   private readonly manageParts = inject(ManageParts);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageProfile = inject(ManageProfile);
   private readonly betterDate = inject(BetterDate);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageVendor = inject(ManageVendor);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly locationHierarchyService = inject(LocationHierarchyService);
   private readonly manageLang = inject(ManageLang);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);

   private readonly isUnitOfMeasureEnabled = this.unitOfMeasureService.isFeatureEnabled;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.hierarchyOptions = {
         idKey: "partID",
         selection: { singleSelection: false },
         nodeButtons: [
            {
               tooltip: this.lang().OpenThisPart,
               clickFunction: this.runPopPart.bind(this),
               permissionNumber: this.credService.Permissions.ViewLookupAPart,
               text: this.lang().View,
            },
         ],
         submit: this.submit.bind(this),
      };

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(() => {
         this.canAddParts = this.manageFeatureFlags.canAddParts();
      });
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.purchaseOrderItem = this.managePO.getPurchaseOrderItem(
         this.resolve.data.poItemID,
      );
      if (this.purchaseOrderItem === undefined) {
         throw new Error(
            "purchaseOrderItem is undefined in pickPOItemsPartService and must be defined for this view to function properly",
         );
      }

      this.title = this.resolve.data.title;
      this.message = this.resolve.data.message || false;
      this.text = "";
      this.createPartShow = false;
      this.showOnlyVendorParts = false;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.selectOne = this.resolve.data.selectOne;
      this.hierarchyOptions.selection = { singleSelection: Boolean(this.selectOne) };
      this.oldQty = this.purchaseOrderItem.qty;
      this.oldRate = this.purchaseOrderItem.rate;
      this.treeData = [];
      this.loadCount = 0;
      this.loadedHier = false;
      this.creatingPart = false;
      this.serviceQty = this.purchaseOrderItem.qty;
      this.serviceRate = this.purchaseOrderItem.rate;

      this.purchaseOrder = this.purchaseOrderItem.poID
         ? this.managePO.getPurchaseOrder(this.purchaseOrderItem.poID)
         : undefined;

      this.locationID = this.purchaseOrder ? this.purchaseOrder.locationID : undefined;
      this.vendorName = this.purchaseOrder?.vendorID
         ? this.manageVendor.getVendor(this.purchaseOrder.vendorID)?.vendorName
         : undefined;

      //on inital load we gotta clean up selected and make sure nothing is selected... we don't do this on buildView so that the search works properly without removing what they have picked before
      this.resetParts();
      this.resetPartsSelected();

      if (this.purchaseOrderItem.itemType === 1) {
         //parts don't have a text field as we use their name...
      } else if (this.purchaseOrderItem.itemType === 2) {
         this.serviceDescription = this.purchaseOrderItem.prodServDescription;
      } else if (this.purchaseOrderItem.itemType === 3) {
         //to do when we add assets in the future
      } else if (this.purchaseOrderItem.itemType === 4) {
         this.otherDescription = this.purchaseOrderItem.prodServDescription;
      }

      if (this.purchaseOrderItem.qty === 0 && this.purchaseOrderItem.rate === 0) {
         this.newService = true;
      } else {
         this.newService = false;
      }

      this.newItemType = Number(this.purchaseOrderItem.itemType);
      this.newChecklistID = this.purchaseOrderItem.checklistID;

      this.setTask();
      this.setPastServices();
      this.updateSearchParts();

      const allParts = this.manageParts
         .getParts()
         .filter((part) => part.partDeleted === 0);
      this.allPartsLength = allParts.size;

      this.newLocations = [];
      this.createPartCred = false;
      this.locations = this.manageLocation.getLocations();
      for (const location of this.locations) {
         if (
            this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.AddParts,
            ) ||
            this.credService.checkCredGlobal(this.credService.Permissions.ManageRoles)
         ) {
            //if they can make add a part at that location or if they are a super user
            this.newLocations.push(location);
            this.createPartCred = true;
         }
      }
   }

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   protected updateSearchParts() {
      if (this.timeoutSearchParts) {
         clearTimeout(this.timeoutSearchParts);
      }
      this.timeoutSearchParts = setTimeout(() => {
         this.buildView();
      }, 250);
   }

   protected setNewPartLocation(location) {
      this.newPartLocationID = location.locationID;
      this.newPartLocationName = location.locationName;
   }

   public resetParts() {
      this.parts = this.manageParts.getParts();
   }

   protected resetPartsSelected() {
      for (const node of this.partNodes.values()) {
         node.selected = false;
         node.searchHint = "";
      }
   }

   protected setTask(): void {
      if (this.newChecklistID > 0) {
         this.task = this.manageTask.getTaskLocalLookup(this.newChecklistID);

         if (this.task === undefined) {
            this.newChecklistID = 0;
         } else if (this.task.assetID) {
            this.assetNameStr = this.manageAsset.getAssetNameIncludeParents(
               this.task.assetID,
            );
         }
      }
   }

   private setPastServices() {
      if (!this.purchaseOrder?.vendorID) return;
      const purchaseOrderItems = this.managePO
         .getPurchaseOrderItemsByVendorID(this.purchaseOrder?.vendorID)
         ?.filter((poItem) => poItem.itemType === 2);
      if (purchaseOrderItems === undefined) return;
      for (const purchaseOrderItem of purchaseOrderItems) {
         if (purchaseOrderItem.poID === null) continue;
         const purchaseOrder = this.managePO.getPurchaseOrder(purchaseOrderItem.poID);
         if (
            purchaseOrderItem.itemType !== 2 ||
            purchaseOrderItem.poItemID === this.purchaseOrderItem?.poItemID ||
            purchaseOrder?.state !== 100
         ) {
            continue;
         }

         this.pastServices.set(purchaseOrderItem.poItemID, {
            ...purchaseOrderItem,
            selected: false,
            date: purchaseOrder.date,
            poNumber: purchaseOrder.poNumber,
         });
      }
      this.pastServices.orderBy("date", REVERSE);
      this.filteredPastServices = new Lookup("poItemID", this.pastServices);
   }

   private buildView() {
      let regions;
      let regionsIndex;
      this.resetParts();
      this.partsSearchHints = new Map();

      //filter parts that are deleted.
      this.parts = this.parts.filter((part) => part.partDeleted === 0);

      if (!this.partNodes.size) {
         this.partNodes = new Map(
            [...this.parts.entries()].map(([partID, part]) => [
               partID,
               {
                  partID,
                  collapsed: true,
                  selected: false,
                  nodes: [],
                  icon: "gears",
                  title: part.partName ?? "",
                  displayButtons: true,
                  locationID: part.locationID,
               },
            ]),
         );
      }

      //if we are only showing this.parts associated with this vendor decrease the list.
      if (this.showOnlyVendorParts === true) {
         if (this.purchaseOrder?.vendorID && this.purchaseOrder.vendorID > 0) {
            this.parts = new Lookup(
               "partID",
               this.manageAssociations.getAssociatedPartsForVendor(
                  this.purchaseOrder.vendorID,
                  this.managePO.getPurchaseOrderItemsByVendorID(
                     this.purchaseOrder?.vendorID,
                  ),
               ).parts,
            );
         }
      }

      //filter to only parts that are understocked
      if (this.showOnlyUnderstockedParts) {
         this.parts = this.parts.filter((item) => {
            const totalAvailableQty =
               this.manageParts.getSingleCalculatedPartInfo(item.partID)
                  ?.totalAvailableQty ?? 0;
            return (
               item.partOverstockedThreshold !== undefined &&
               item.partOverstockedThreshold !== null &&
               item.partOverstockedThreshold > 0 &&
               totalAvailableQty < item.partOverstockedThreshold
            );
         });
      }

      //filter to only parts that don't have open POs currently
      if (this.showOnlyPartsWithoutOpenPOs) {
         // Need to get associated POs for any parts that don't have that set already
         for (const part of this.parts) {
            const partToCheck = this.associatedPOsByPart.get(part.partID);

            if (!partToCheck) {
               const associatedPOs = this.managePO.findPendingPurchaseOrderInfoForPart(
                  part.partID,
                  false,
               ).purchaseOrders;
               this.associatedPOsByPart.set(part.partID, associatedPOs);
            }
         }

         this.parts = this.parts.filter((item) => {
            return this.associatedPOsByPart.get(item.partID)?.size === 0;
         });
      }

      this.locations = this.manageLocation.getLocations();

      const locationIDs: Array<number> = [];
      for (const location of this.locations) {
         location.childrenBuilt = false;
         locationIDs.push(location.locationID);
      }

      if (this.manageLocation.getRegions().length > 0) {
         //they are using regions so we have to behave a little differently
         const rst = this.locationHierarchyService.buildHierarchy({
            locations: this.locations,
            regions: this.manageLocation.getRegions(),
            search: this.searchBar,
            filterRegions: false,
            filterLocations: false,
            alwaysReturnRegions: false,
            totalLocationCount: this.manageLocation.getLocations().length,
         });
         regions = rst.regionTree;
         regionsIndex = rst.regionsIndex;
      }

      this.parts = this.parts.filter((part) => locationIDs.includes(part.locationID));

      this.parts = this.manageFilters.filterPartsToNameAndTextFields(
         this.parts,
         this.manageParts.getFields(),
         this.manageParts.getFieldValues(),
         this.manageParts.getFieldValueFiles(),
         this.partsSearchHints,
         {
            search: this.searchParts,
            hier: true,
         },
         this.betterDate,
         this.manageParts,
         this.managePO,
         this.manageAssociations,
      );

      this.singleLocation = false;
      for (const location of this.locations) {
         if (location.locationID === this.locationID) {
            this.selectedLocation = location;
         }
      }
      this.newPartLocationID = this.selectedLocation.locationID;
      this.setNewPartLocation(this.selectedLocation);

      //prepare the lookup
      const locationIndex: any = [];
      for (const location of this.locations) {
         locationIndex[location.locationID] = location;
      }

      this.treeData = [];

      for (const location of this.locations) {
         location.nodes = [];
         location.title = location.locationName;
         location.icon = "houseChimney";
         location.unselectable = true;

         if (this.searchParts !== undefined && this.searchParts.length > 1) {
            location.collapsed = false;
         } else if (this.selectedLocation.locationID === location.locationID) {
            location.collapsed = false;
         } else {
            location.collapsed = true;
         }
      }

      this.parts = new Lookup("partID", orderBy(Array.from(this.parts), "partName"));

      for (const part of this.parts) {
         this.manageParts.calculatePartData(part);
         const partNode = this.getPartNode(part.partID);

         let vendorPartRelation: PartVendorRelation | undefined;
         for (const relationID of part.partVendorRelationIDs) {
            const relation: PartVendorRelation | undefined =
               this.manageAssociations.getPartVendorRelation(relationID);
            if (
               relation &&
               relation.vendorID === this.purchaseOrder?.vendorID &&
               relation.partNumber?.length
            ) {
               vendorPartRelation = relation;
               break;
            }
         }

         if (vendorPartRelation === undefined) {
            partNode.title = part.partName ?? "";

            if (
               part.partNumber !== undefined &&
               part.partNumber !== null &&
               part.partNumber.length > 0
            ) {
               partNode.title += ` - ${part.partNumber}`;
            }
         } else {
            partNode.title =
               this.manageParts.getAltPartNameAndNumberFromVendorRelation(
                  vendorPartRelation,
               );
         }

         const totalAvailableQty =
            this.manageParts.getSingleCalculatedPartInfo(part.partID)
               ?.totalAvailableQty ?? 0;

         let unit = "";
         if (this.isUnitOfMeasureEnabled()) {
            /** Hierarchy node properties are not reactive so this depends on the "are units initialized" event above and an optional chain operator. */
            unit = ` ${this.unitOfMeasureService.getUnit(part.unitDescription)()?.short() ?? ""}`;
         }

         if (totalAvailableQty !== undefined && totalAvailableQty !== null) {
            partNode.title += ` - ${this.lang().Qty}: ${totalAvailableQty}${unit} ${this.lang().available}`;
         } else {
            partNode.title += ` - ${this.lang().Qty}: 0${unit} ${this.lang().available}`;
         }

         if (
            part.partOverstockedThreshold !== undefined &&
            part.partOverstockedThreshold !== null &&
            part.partOverstockedThreshold > 0
         ) {
            partNode.title += ` - ${this.lang().MinQty}: ${part.partOverstockedThreshold}${unit}`;
         } else {
            partNode.title += ` - ${this.lang().MinQty}: ${this.lang().Off}`;
         }

         if (
            part.partMaxQtyThreshold !== undefined &&
            part.partMaxQtyThreshold !== null &&
            part.partMaxQtyThreshold > 0
         ) {
            partNode.title += ` - ${this.lang().MaxQty}: ${part.partMaxQtyThreshold}${unit}`;
         } else {
            partNode.title += ` - ${this.lang().MaxQty}: ${this.lang().Off}`;
         }

         if (
            part.partLocation !== undefined &&
            part.partLocation !== null &&
            part.partLocation.length > 0
         ) {
            partNode.title += ` - ${this.lang().at}: `;
            partNode.title += part.partLocation;
         }

         if (
            part?.partOverstockedThreshold !== undefined &&
            part.partOverstockedThreshold !== null &&
            part.partOverstockedThreshold > 0 &&
            totalAvailableQty < part.partOverstockedThreshold //
         ) {
            partNode.title += ` - ${this.lang().ThisPartIsCurrentlyUnderTheMinimumPartQtyThreshold}`;
            partNode.icon = "triangleExclamation";
            partNode.iconColor = "danger";
         }

         if (!this.searchParts.length) {
            this.partsSearchHints.set(part.partID, "");
         }

         partNode.searchHint = this.partsSearchHints.get(part.partID) ?? "";
         partNode.searchFound = Boolean(partNode.searchHint.length);

         if (locationIndex[part.locationID]) {
            locationIndex[part.locationID].nodes.push(partNode);
         }
      }

      for (const index in regionsIndex) {
         const region = regionsIndex[index];
         region.title = region.regionName;
         region.icon = "earthAmericas";
         region.unselectable = true;

         if (
            (this.searchBar !== undefined && this.searchBar.length > 1) || //if we are searching start not collapsed
            this.manageLocation.getRegions().length <= 3 //if they are starting collapsed and they don' thave many regions then let's show them
         ) {
            region.collapsed = false;
         } else {
            region.collapsed = true;
         }

         region.selected = false;
      }

      this.treeData = [];

      if (this.locations.length > 0) {
         //first we do a little logic to see if they only have one part... that way we can show them a smaller list
         const partLocationIDs = Array.from(this.parts).map((part) => part.locationID);
         const only1Loc = new Set(partLocationIDs).size === 1;

         if (this.manageLocation.getRegions().length > 0) {
            //they are doing regions so let's show those correctly...

            //first we need to remove any locations that don't have parts or other children on them or any regions that are empty
            this.manageFilters.removeEmptyRegionsOrLocations(regionsIndex);

            if (only1Loc) {
               for (const part of this.parts) {
                  const partNode = this.getPartNode(part.partID);
                  this.treeData.push(partNode);
               }
            } else {
               //lastly add the top level regions
               for (const region of regions) {
                  if (region.nodes.length > 0) {
                     if (this.searchParts?.length) {
                        region.nodes.forEach((locationNode) => {
                           if (locationNode.nodes.length > 0) {
                              locationNode.collapsed = false;
                              region.collapsed = false;
                           }
                        });
                     }
                     this.treeData.push(region);
                  }
               }
            }
         } else if (only1Loc) {
            //they aren't doing regions so process it like normal locations
            for (const part of this.parts) {
               const partNode = this.getPartNode(part.partID);
               this.treeData.push(partNode);
            }
         } else {
            this.locations = orderBy(this.locations, "locationName");
            for (const location of this.locations) {
               if (location.nodes?.length > 0) {
                  if (this.searchParts?.length) {
                     location.collapsed = false;
                  }
                  this.treeData.push(location);
               }
            }
         }
      } else {
         for (const part of this.parts) {
            const partNode = this.getPartNode(part.partID);
            this.treeData.push(partNode);
         }
      }

      this.partsLength = this.parts.size;

      if (this.partsLength === 0) {
         this.newPartName = this.searchParts;
      }
   }

   protected createPart() {
      if (!this.canAddParts) {
         return;
      }
      this.createPartDisabled = true;
      if (
         this.newPartName === undefined ||
         this.newPartName === "" ||
         this.newPartName === false
      ) {
         this.alertService.addAlert(
            this.lang().WhoopsPleaseProvideAPartName,
            "warning",
            6000,
            this.lang().Warning,
         );
         this.createPartDisabled = false;
         return;
      }
      if (this.newPartLocationID === 0) {
         return;
      }

      if (this.newPartNumber === false || this.newPartNumber === undefined) {
         this.newPartNumber = "";
      }

      if (this.newPartPrice === false || this.newPartPrice === undefined) {
         this.newPartPrice = 0;
      }

      if (this.newPartName.length > 0 && this.newPartNumber.length > 0) {
         //both part name and part number are set so let's loop through parts at this location and see if we should warn them it may already exist
         const partsToCheck = this.manageParts.getParts();
         let foundSimilarPart = false;
         const newLocation = this.manageLocation.getLocation(this.newPartLocationID);
         for (const part of partsToCheck) {
            if (
               part.partDeleted === 0 &&
               part.locationID === this.newPartLocationID &&
               part.partName?.toLowerCase() === this.newPartName.toLowerCase() &&
               part.partNumber?.toLowerCase() === this.newPartNumber.toLowerCase()
            ) {
               //we found at this location.
               foundSimilarPart = true;
            }
            if (
               newLocation?.partNumberUnique &&
               part.partNumber?.toLowerCase() === this.newPartNumber?.toLowerCase()
            ) {
               foundSimilarPart = true;
            }
         }

         if (foundSimilarPart) {
            if (newLocation?.partNumberUnique) {
               this.alertService.addAlert(
                  this.lang().YouHaveUniquePartNumbersEnabled,
                  "warning",
                  6000,
               );
               this.createPartDisabled = false;
               return;
            }

            //we found a simliar part so let's ask them if they want to continue
            const instance = this.modalService.open(Confirm);

            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: this.lang().ThatPartAlreadyExistsMessage,
                  title: this.lang().ThatPartAlreadyExists,
               },
            };

            instance.result.then(
               (result) => {
                  if (result === 1) {
                     this.addPart();
                  } else {
                     this.createPartDisabled = false;
                  }
               },
               () => {
                  this.createPartDisabled = false;
               },
            );
         } else {
            this.addPart();
         }
      } else {
         this.addPart();
      }
   }

   protected addPart() {
      if (!this.canAddParts) {
         return;
      }
      this.creatingPart = true;
      this.manageParts
         .addPart(
            this.newPartLocationID,
            this.newPartName,
            this.newPartNumber,
            this.newPartPrice,
            0,
         )
         .then((answer) => {
            if (answer?.data.success === true) {
               const obj: any = {};
               obj.type = this.newItemType;
               obj.value = [answer.data.part.partID];
               this.modalInstance.close(obj);
            } else {
               this.alertService.addAlert(
                  this.lang().pickPOItemsPartServiceErrorMessage,
                  "danger",
                  10000,
               );
            }
         });
   }

   protected close() {
      this.modalInstance.close(false);
   }

   protected checkIfStartedFromTask() {
      if (this.newChecklistID === 0 && this.purchaseOrder?.checklistID !== null) {
         //the checklistID is the Task the PO was started from.  If that is set then we should default to that Task if they haven't already selected another
         this.newChecklistID = this.purchaseOrder?.checklistID;
         this.setTask();
      }
   }

   protected submit() {
      if (this.newItemType === 1 && this.createPartShow === true) {
         return; //if they are in the middle of trying to create a part don't let them submit a new part..
      }

      if (this.newItemType === 2) {
         //important... type 2 has tasks required.  type 4 Other, doesn't.
         if (this.newChecklistID === 0) {
            this.alertService.addAlert(
               this.lang().WhoopsPleaseSelectATask,
               "warning",
               6000,
               this.lang().Warning,
            );
            return;
         }
      }

      const obj: any = {};
      obj.type = this.newItemType;

      if (this.newItemType === 1) {
         const returnParts: any = [];
         for (const [partID, partNode] of this.partNodes) {
            if (partNode.selected === true) {
               returnParts.push(partID);
            }
         }
         obj.value = returnParts; //an array of partIDs
         this.modalInstance.close(obj);
      } else if (this.newItemType === 2) {
         obj.value = this.serviceDescription;
         obj.checklistID = this.newChecklistID;
         obj.qty = this.serviceQty;
         obj.rate = this.serviceRate;

         this.modalInstance.close(obj);
      } else if (this.newItemType === 3) {
         //not building out asset yet
      } else if (this.newItemType === 4) {
         obj.value = this.otherDescription;
         obj.checklistID = this.newChecklistID;
         obj.qty = this.oldQty;
         obj.rate = this.oldRate;
         this.modalInstance.close(obj);
      } else {
         this.alertService.addAlert(
            this.lang().PleaseSelectAPart,
            "warning",
            6000,
            this.lang().Warning,
         );
      }
   }

   protected toggleCreatePartView(): void {
      if (!this.canAddParts) {
         return;
      }
      this.createPartShow = !this.createPartShow;
      this.searchParts = "";
      this.updateSearchParts();
   }

   protected pickTask(): void {
      let tasksLookup = this.manageTask
         .getTasks()
         .filter((task) => task.checklistTemplate === 0 && task.checklistStatusID === 0);

      //only show tasks I can see at specific locations
      this.locations = this.manageLocation.getLocations();
      const locIDs: any = [];
      for (const location of this.locations) {
         if (
            this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.ViewAllOpenTasks,
            ) ||
            this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.ViewMyOpenTasks,
            )
         ) {
            //if I can view tasks at that location then I can start a PO for tasks at that loaction
            locIDs.push(location.locationID);
         }
      }

      tasksLookup = this.manageFilters.filterLookupByLocationIDs(tasksLookup, locIDs);

      const instance = this.modalService.open(PickTasks);

      const taskIDs = Array.from(tasksLookup).map((task) => task.checklistID);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().PickTask,
            taskIDs: taskIDs,
            data: { selectOne: true },
         },
      };

      instance.result.then((pickedTasks) => {
         if (pickedTasks?.lookupOfTasks?.size > 0) {
            this.newChecklistID = pickedTasks.lookupOfTasks
               .values()
               .next().value.checklistID;

            this.setTask();
            this.setPastServices();
         }
      });
   }

   protected popPoComponent(poID) {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   }

   protected startWorkOrder() {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred43Fail, "warning", 10000);
         return;
      }

      if (this.manageUser.getCurrentUser().userInfo.customerStartingAWOAskAsset === 0) {
         //they have turned off picking an asset, so have them pick no asset to start
         const asset = {
            locationID: this.locationID,
            assetID: 0,
         };
         this.newWO(asset);
         return;
      }

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.lang().WhichAssetWouldYouLikeToStartThisWorkOrderFor;
      instance.title = this.lang().FirstSelectAnAsset;
      instance.singleLocation = this.locationID;
      instance.selectOne = true;
      instance.restrictToCred = false;
      instance.iDontKnowOption = true;

      modalRef.result.then((result) => {
         if (result) {
            if (result === "unsure") {
               const asset = {
                  locationID: this.locationID,
                  assetID: 0, //they don't know what asset so don't assign it to an asset
               };
               this.newWO(asset);
            } else {
               this.newWO(result);
            }
         }
      });
   }

   protected newWO(asset) {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred43Fail, "warning", 10000);
         return;
      }

      const instance = this.modalService.open(SetupWorkOrder);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().SetupWorkOrderMsg,
            title: this.lang().SetupWorkOrder,
            data: {
               WO: true,
               assetID: asset.assetID,
               locationID: asset.locationID,
               date: false,
            },
         },
      };

      instance.result.then((data) => {
         if (data) {
            this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });

            this.manageTask
               .goLiveWorkOrder(
                  data.WO,
                  data.profileID,
                  data.userID,
                  data.multiUsers,
                  data.timestamp,
                  0,
                  data.timeOfDay,
                  data.startDate,
                  data.startDateTimeOfDay,
                  this.manageUser,
                  this.manageProfile,
               )
               .then(
                  (answer) => {
                     this.loadingBarService.remove();
                     if (answer.data.success !== true) {
                        this.alertService.addAlert(
                           this.lang().pickPOItemsPartServiceErrorMessage,
                           "danger",
                           10000,
                        );
                        return;
                     }
                     const userID = answer.data.task.userID;
                     const profileID = answer.data.task.profileID;

                     if (answer.data.task.checklistID !== undefined) {
                        this.newChecklistID = answer.data.task.checklistID;

                        this.setTask();
                        this.setPastServices();
                     }

                     const currentUser = this.manageUser.getCurrentUser();
                     const profiles = currentUser.profileLoc || [];
                     let assignedToProfileIBelongTo = false;
                     for (const profile of profiles) {
                        if (
                           profile.profileID === profileID &&
                           profile.locationID === asset.locationID
                        ) {
                           assignedToProfileIBelongTo = true;
                        }
                     }

                     //if I am assigning it to myself or a profile I belong to let's automatically pop it up
                     if (userID === currentUser.gUserID || assignedToProfileIBelongTo) {
                        const instanceP = this.modalService.open(PopTask);
                        this.paramsService.params = {
                           modalInstance: instanceP,
                           resolve: {
                              data: {
                                 checklistID: data.WO.task.checklistID,
                                 editable: true,
                              },
                           },
                        };
                        this.alertService.addAlert(
                           this.lang().WorkOrderSuccessfullyStarted,
                           "success",
                           5000,
                        );
                     } else {
                        this.alertService.addAlert(
                           this.lang()
                              .WorkOrderSuccessfullyStartedAndEmailPushNotificationSent,
                           "success",
                           5000,
                        );
                     }
                  },
                  () => {
                     this.loadingBarService.remove();
                     this.alertService.addAlert(
                        this.lang().pickPOItemsPartServiceErrorMessage,
                        "danger",
                        10000,
                     );
                  },
               );
         }
      });
   }

   protected setService(item) {
      this.newService = false;
      for (const pastService of this.pastServices) {
         pastService.selected = false;
      }

      if (item === 0) {
         this.serviceQty = this.purchaseOrderItem?.qty; //use the old values of qty and rate (if it is new the old values will be 0)
         this.serviceRate = this.purchaseOrderItem?.rate;
         this.newService = true;
      } else {
         this.serviceQty = item.qty;
         this.serviceRate = item.rate;
         item.selected = true;
      }
   }

   public updatePartFilter(filter: string) {
      if (filter === "showOnlyPartsWithoutOpenPOs") {
         this.showOnlyPartsWithoutOpenPOs = !this.showOnlyPartsWithoutOpenPOs;
      } else if (filter === "showOnlyUnderstockedParts") {
         this.showOnlyUnderstockedParts = !this.showOnlyUnderstockedParts;
      } else if (filter === "showOnlyVendorParts") {
         this.showOnlyVendorParts = !this.showOnlyVendorParts;
      }

      this.searchParts = "";
      this.updateSearchParts();
      this.buildView();
   }

   private getPartNode(partID: number) {
      const partNode = this.partNodes.get(partID);
      assert(partNode);
      return partNode;
   }
   public runPopPart(partNode: PartHierarchyNode) {
      if (!partNode.partID) {
         this.alertService.addAlert(
            this.lang().pickPOItemsPartServiceErrorMessage,
            "danger",
            6000,
         );
         return;
      }
      const part = this.manageParts.getPart(partNode.partID);
      if (!part) {
         return;
      }
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: part.partID,
            locationID: part.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   protected filterServicesToSearch() {
      if (!this.searchServices.length) {
         this.filteredPastServices = new Lookup("poItemID", this.pastServices);
         return;
      }
      this.filteredPastServices = this.manageFilters.filterPurchaseOrderItemsToSearch(
         this.pastServices,
         this.searchServices,
         this.managePO,
      ).purchaseOrderItems;
   }

   protected updateNewItemType(newType: 1 | 2 | 4) {
      this.newItemType = newType;
   }
}
