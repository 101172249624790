<lim-ui-dropdown [disabled]="taskIsCompleted" (click)="dropdownClickEvent.emit()">
   <lim-ui-table-button
      [disabled]="taskIsCompleted"
      button
      id="status-button"
      limUiTooltip="{{ statusInfo?.statusName }}"
      placement="top"
      class="status-button"
      [fixedWidthTableButton]="fixedWidthTableButton"
   >
      @if (useFullName()) {
         {{ statusInfo?.statusName }}
      } @else {
         {{ statusInfo?.statusAbbr }}
      }
   </lim-ui-table-button>

   <ng-container menu aria-labelledby="status-button">
      @for (status of statusList | orderBy: "sortOrder"; track status) {
         <lim-ui-dropdown-item
            (click)="updateTaskStatusID(status)"
            [limUiTooltip]="status.description ?? null"
            placement="right"
         >
            {{ status.name }}
         </lim-ui-dropdown-item>
      }
      @if (superUser) {
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-item (click)="editStatuses()">
            <lim-ui-icon icon="penToSquare" />
            {{ lang().EditStatuses }}
         </lim-ui-dropdown-item>
      }
   </ng-container>
</lim-ui-dropdown>
