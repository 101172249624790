import { NgClass } from "@angular/common";
import {
   input,
   type OnDestroy,
   type OnInit,
   inject,
   Component,
   computed,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   IconComponent,
   InfoPanelComponent,
   LimUiModalRef,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { OrderByPipe, orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageSubscription } from "src/app/subscriptions/services/manageSubscription";
import { ManageUser, type UserData } from "src/app/users/services/manageUser";

type LocationListItem = {
   locationID: number;
   locationNameWithRegions: string;
};

@Component({
   selector: "add-user",
   templateUrl: "./addUser.modal.component.html",
   styleUrls: ["./addUser.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      IconComponent,
      InfoPanelComponent,
      PanelComponent,
      AlertComponent,
      NgClass,
      FormsModule,
      FormDropdownInputComponent,
      DropdownTextItemComponent,
      SearchBoxComponent,
      DropdownDividerComponent,
      LoadingAnimationComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      TooltipDirective,
      OrderByPipe,
   ],
})
export class AddUser implements OnInit, OnDestroy {
   public locationID = input<number>();

   public roles;
   public errorMsg = "";
   public usernameError = false;
   public firstNameError = false;
   public lastNameError = false;
   public emailError = false;
   public repeatEmailError = false;
   public phoneError = false;
   public locationError = false;
   public roleError = false;

   public username: string | undefined;
   public firstName: string | undefined;
   public lastName: string | undefined;
   public email: string | undefined;
   public repeatEmail: string | undefined;
   public phone: string | undefined;
   public roleID: number | undefined;
   public searchLocations;

   public loading = false;
   public disabled = false;
   public enableUserSSO = false;
   public ssoEnabled = false;
   public ssoEnabledSub: Subscription | null = null;

   public totalLocLength: number | undefined;
   public locations: LocationListItem[] | undefined;
   public selectedLocationName: string | undefined;
   public selectedLocationID: number | undefined;
   public selectedRoleDescription: string | undefined;
   public formDropdownName: string | undefined;

   public readonly modalRef: LimUiModalRef<AddUser, number> = inject(LimUiModalRef);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly alertService = inject(AlertService);
   private readonly manageSubscription = inject(ManageSubscription);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.roles = this.manageUser.getRoles();

      const locationID = this.locationID();
      if (locationID !== undefined) {
         const location = this.manageLocation.getLocation(locationID);
         if (location !== undefined) {
            this.setSelectedLocation(location.locationID);
         }
      }

      this.ssoEnabledSub = this.manageObservables.setSubscription("ssoEnabled", (val) => {
         this.ssoEnabled = Boolean(val);

         if (this.ssoEnabled) {
            this.enableUserSSO = true;
         }

         this.buildData();
      });
   }

   buildData = () => {
      let locations = this.manageLocation.getLocations();
      this.totalLocLength = locations.length;
      if (this.searchLocations && this.searchLocations.length > 0) {
         locations = this.manageFilters.filterLocationsToSearch(
            locations,
            this.searchLocations,
         );
      }

      let locationList = locations.map((location) => {
         return {
            locationID: location.locationID,
            locationNameWithRegions:
               this.manageLocation.getLocationNameWithRegions(location),
         };
      });

      locationList = orderBy(locationList, "locationNameWithRegions");

      this.locations = locationList;
   };

   runSearch = () => {
      this.buildData();
   };

   close = () => {
      this.modalRef.close(0);
   };

   validateEmail = (email) => {
      const regex =
         /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,63}(?:\.[a-z]{2})?)$/i;
      return regex.test(email);
   };

   validateForm = async (): Promise<string> => {
      let errorMsg = "";
      const email = this.email?.toLowerCase();
      const repeatEmail = this.repeatEmail?.toLowerCase();

      const validUserName = await this.manageUser.checkUsername(this.username);
      if (!validUserName.data.success) {
         errorMsg += `<div>${this.lang().ThatEmailIsALreadyRegisteredInOurSystem}</div>`;
         this.usernameError = true;
      }

      // Validate first name
      if (this.firstName === undefined) {
         errorMsg += `<div>${this.lang().TheFirstNameIsBlank}</div>`;
         this.firstNameError = true;
      }

      // Validate last name
      if (this.lastName === undefined) {
         errorMsg += `<div>${this.lang().TheLastNameIsBlank}</div>`;
         this.lastNameError = true;
      }

      // Validate phone number
      if (this.phone === undefined) {
         errorMsg += `<div>${this.lang().ThePhoneNumberIsBlank}</div>`;
         this.phoneError = true;
      }

      // Validate email
      if (this.email === undefined) {
         errorMsg += `<div>${this.lang().TheEmailAddressIsBlank}</div>`;
         this.emailError = true;
      }

      if (this.repeatEmail === undefined) {
         errorMsg += `<div>${this.lang().TheRepeatEmailAddressIsBlank}</div>`;
         this.repeatEmailError = true;
      }

      if (!this.validateEmail(email)) {
         errorMsg += `<div>${this.lang().PleaseEnterAValidEmailAddress}</div>`;
         this.emailError = true;
      }

      if (!this.validateEmail(repeatEmail)) {
         errorMsg += `<div>${this.lang().PleaseEnterAValidRepeatEmailAddress}</div>`;
         this.repeatEmailError = true;
      }

      if (email && repeatEmail && email !== repeatEmail) {
         errorMsg += `<div>${this.lang().TheEmailAddressesDoNotMatch}</div>`;
      }

      // Validate assigned location
      if (this.selectedLocationID === undefined) {
         errorMsg += `<div>${this.lang().PleaseSelectALocation}</div>`;
         this.locationError = true;
      }

      // Validate assigned role
      if (this.roleID === undefined) {
         errorMsg += `<div>${this.lang().PleaseSelectARole}</div>`;
         this.roleError = true;
      }

      if (email && this.manageUser.userEmailExistsInCustomer(email)) {
         errorMsg += `<div>${this.lang().ErrorDuplicateEmailInCustomerMessage}</div>`;
         this.emailError = true;
         this.repeatEmailError = true;
      }

      return errorMsg;
   };

   displayAddLicenseModal = async (user: UserData) => {
      const jsThinksThereAreEnoughLicenses = await this.manageUser.licenseConfirm(
         0,
         this.roleID,
      );

      // If there are enough licenses, re-add the user on confirm success
      if (jsThinksThereAreEnoughLicenses) {
         const newlyAddedUserResult = await this.manageUser.addUser(user);

         // If the user was re-added successfully (happens when trialing with no credit card), re-render the chargify data object from the integration, refresh the user data and close the modal with the selected new user ID
         if (!newlyAddedUserResult.data.success) {
            this.displayAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }

         this.displayAlert(
            this.lang().successMsg,
            "success",
            2000,
            newlyAddedUserResult.data.row.userID,
         );
         await this.manageSubscription.getChargifyData();
      }
   };

   displayAlert = async (
      msg: string,
      type: "warning" | "danger" | "success",
      timeout: number = 6000,
      userID: number = 0,
   ) => {
      this.alertService.addAlert(msg, type, timeout);
      if (type === "success") {
         await this.manageUser.getData();
         this.modalRef.close(userID);
      }
      this.complete();
   };

   submit = async () => {
      if (this.disabled) {
         return;
      }

      this.errorMsg = await this.validateForm();

      if (this.errorMsg !== "") {
         this.displayAlert(this.errorMsg, "warning", 6000);
         return;
      }

      this.setLoading(true);

      const user: UserData = {
         login: this.email?.toLowerCase() ?? "",
         email: this.email?.toLowerCase() ?? "",
         firstName: this.firstName ?? "",
         lastName: this.lastName ?? "",
         repeatEmail: this.repeatEmail?.toLowerCase() ?? "",
         phone: this.phone ?? "",
         locationID: this.selectedLocationID ?? 0,
         roleID: this.roleID ?? 0,
         enableUserSSO: this.enableUserSSO,
      };

      const addUserResult = await this.manageUser.addUser(user);

      if (addUserResult.data.success) {
         this.displayAlert(
            this.lang().successMsg,
            "success",
            2000,
            addUserResult.data.row.userID,
         );
         return;
      }

      // If the user was not added due to not enough licenses, open the license confirm modal and re-add the user on confirm success
      if (addUserResult.data.error === "Not enough licenses") {
         this.displayAddLicenseModal(user);
         return;
      }

      this.displayAlert(this.lang().errorMsg, "danger", 6000);
   };

   protected setSelectedLocation(locationID: number): void {
      this.selectedLocationID = locationID;
      this.selectedLocationName = this.manageLocation.getLocationNameWithRegions(
         this.manageLocation.getLocation(locationID),
      );
      this.locationError = false;
   }

   private setLoading(loading: boolean) {
      this.loading = loading;
      this.disabled = loading;
   }

   private complete() {
      this.setLoading(false);
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.ssoEnabledSub);
   }

   public handleFormDropdownSelected($event): void {
      this.formDropdownName = $event;
   }
}
