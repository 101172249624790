import { inject, Injectable, signal } from "@angular/core";
import { datadogLogs } from "@datadog/browser-logs";
import type { Observable } from "rxjs";
import {
   Subject,
   filter,
   firstValueFrom,
   fromEvent,
   map,
   merge,
   withLatestFrom,
} from "rxjs";
import { ConnectionService } from "src/app/lite/connection/connection.service";
import {
   LiteEnabledState,
   LiteEnablementService,
} from "src/app/lite/lite-enablement.service";
import type { InitResponse } from "src/app/lite/local-db/initializer.types";
import {
   OfflinePrepState,
   type UserIdentity,
} from "src/app/lite/local-db/offline-prep.service.types";
import type {
   CompleteEventData,
   LiteLaunchFlags,
   WorkerConfig,
} from "src/app/lite/local-db/offline-prep.worker.types";
import { Flags } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageUser } from "src/app/users/services/manageUser";
import { environment } from "src/environments/environment";
import { getWorker } from "worker";

@Injectable({ providedIn: "root" })
export class OfflinePrepService {
   public static readonly WORKER_NAME = "offline-prep-worker";
   private static readonly PROCESS_NAME = "Offline Mode initialization";
   private static readonly ANALYTICS_ID = "Lite database initialized";

   public readonly state = signal<OfflinePrepState>(OfflinePrepState.InProgress);
   private worker: Worker | undefined;
   private readonly userIdentity$: Observable<UserIdentity>;
   private readonly databaseInitialized$ = new Subject<CompleteEventData["payload"]>();

   private readonly connectionService = inject(ConnectionService);
   private readonly liteEnabled = inject(LiteEnablementService).liteEnabled;
   private readonly manageUser = inject(ManageUser);
   private readonly launchFlags = inject(LaunchFlagsService);

   public constructor() {
      this.userIdentity$ = this.createUserObservable();
      this.sendAnalyticsWhenDbInitialized();
   }

   private sendAnalyticsWhenDbInitialized(): void {
      this.databaseInitialized$
         .pipe(withLatestFrom(this.userIdentity$))
         .subscribe(([initializationResult, userInfo]) => {
            datadogLogs.logger.info(OfflinePrepService.ANALYTICS_ID, {
               durationMilliseconds: initializationResult.initDurationMilliseconds,
               limitReached: initializationResult.limitReached,
               userID: userInfo.userID,
               customerID: userInfo.customerID,
            });
         });
   }

   private createUserObservable(): Observable<UserIdentity> {
      return this.manageUser.currentUserChanges$.pipe(
         filter((user) => user !== undefined),
         filter((user) => user.userInfo !== undefined),
         filter((user) => user.userInfo.userID !== undefined),
         filter((user) => user.userInfo.customerID !== undefined),
         map(
            (user: {
               userID: number;
               customerID: number;
               [unknownProperty: string]: any;
            }) => ({
               userID: user.userInfo.userID,
               customerID: user.userInfo.customerID,
            }),
         ),
      );
   }

   /** Clears the local db and grabs all necessary data from the server. */
   public async prepare(): Promise<void> {
      if (this.worker !== undefined) return;
      if (this.liteEnabled() !== LiteEnabledState.Enabled) return;
      assert(
         this.connectionService.isOnline(),
         `disconnected before ${OfflinePrepService.name} could be initialized`,
      );

      this.state.set(OfflinePrepState.InProgress);
      console.info(OfflinePrepService.PROCESS_NAME, "starting.");
      try {
         this.worker = this.createOfflinePrepWorker();
         const initResponse = await this.updateLocalDatabase();
         this.databaseInitialized$.next(initResponse);
         console.info(
            OfflinePrepService.PROCESS_NAME,
            "completed in:",
            (initResponse.initDurationMilliseconds / 1000).toFixed(3),
            "seconds.",
         );
         if (initResponse.limitReached) {
            this.state.set(OfflinePrepState.PreparedButLimited);
            console.warn("Offline prep reached data limits.");
         } else {
            this.state.set(OfflinePrepState.Prepared);
         }
      } catch (error) {
         console.info(OfflinePrepService.PROCESS_NAME, "failed.");
         console.error(error);
         this.state.set(OfflinePrepState.Failed);
      } finally {
         this.cleanup();
      }
   }

   private createOfflinePrepWorker(): Worker {
      return getWorker();
   }

   private async updateLocalDatabase(): Promise<InitResponse> {
      this.assertWorkerInitialized(this.worker);
      const responsePromise = firstValueFrom(
         merge(
            fromEvent<MessageEvent<CompleteEventData>>(this.worker, "message").pipe(
               filter((event) => event.data.event === "complete"),
            ),
            fromEvent<ErrorEvent>(this.worker, "error"),
         ),
      );

      const workerConfig: WorkerConfig = {
         launchFlags: await this.getLaunchFlags(),
         env: {
            production: environment.production,
            servicesUrl: environment.servicesURL(),
            flannelUrl: environment.flannelUrl,
            useLaunchDarklySecureMode: environment.useLaunchDarklySecureMode,
         },
      };

      this.worker.postMessage(workerConfig);
      const response = await responsePromise;

      if (this.isErrorEvent(response)) {
         throw new Error(
            `Error in ${OfflinePrepService.WORKER_NAME}: ${response.message}`,
         );
      }

      return response.data.payload;
   }

   private async getLaunchFlags(): Promise<LiteLaunchFlags> {
      const [locations, usersSelf, extraTimeFilter, extraTimeDataLimit] =
         await Promise.all([
            this.launchFlags.getFlagPromise(Flags.MINI_FLANNEL_LOCATIONS, false),
            this.launchFlags.getFlagPromise(Flags.MINI_FLANNEL_USERS_SELF, false),
            this.launchFlags.getFlagPromise(Flags.EXTRA_TIME_COMPLETED_FILTER, false),
            this.launchFlags.getFlagPromise(Flags.EXTRA_TIME_DATA_LIMIT, false),
         ]);
      return {
         useMiniFlannelForLocations: locations,
         useMiniFlannelForUserSelf: usersSelf,
         experimentExtraTimeCompletedFilter: extraTimeFilter,
         extraTimeDataLimit,
      };
   }

   private cleanup(): void {
      this.worker?.terminate();
      this.worker = undefined;
   }

   private assertWorkerInitialized(worker: Worker | undefined): asserts worker is Worker {
      assert(worker !== undefined, `${OfflinePrepService.WORKER_NAME} not initialized`);
   }

   private isErrorEvent(response: ErrorEvent | MessageEvent): response is ErrorEvent {
      return response.type === "error";
   }
}
