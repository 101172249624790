import { computed, inject, Injectable, type Signal } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { Subject } from "rxjs";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { ExtraBatch } from "src/app/parts/types/extra-batch/extra-batch.types";
import type { Part } from "src/app/parts/types/part.types";
import type { PartVendorRelation } from "src/app/parts/types/vendors-relations/vendor-relation.types";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import type { BillTransaction } from "src/app/purchasing/types/bill-transaction.types";
import type { Bill } from "src/app/purchasing/types/bill.types";
import type { BudgetWorkflow } from "src/app/purchasing/types/budget-workflow.types";
import type { Budget } from "src/app/purchasing/types/budget.types";
import type { GeneralLedger } from "src/app/purchasing/types/general-ledger.types";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrderComment } from "src/app/purchasing/types/purchase-order/purchase-order-comment.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderItemRelatedInfo } from "src/app/purchasing/types/purchase-order-item-related-info.type";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import type { PurchaseOrderWorkflow } from "src/app/purchasing/types/purchase-order-workflow.types";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { UpdateSignaler } from "src/app/shared/services/update-signaler/update-signaler";
import type { CalculatedPartInfo } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services//creds/cred.service";
import { ManageProfile } from "src/app/users/services//manageProfile";
import { ManageUser } from "src/app/users/services//manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";
import { environment } from "src/environments/environment";

export type PurchaseOrderItemToAddSkeleton = Pick<
   PurchaseOrderItem,
   | "itemType"
   | "partID"
   | "assetID"
   | "checklistID"
   | "description"
   | "glID"
   | "qty"
   | "rate"
>;

export type AugmentedPurchasingComment = PurchaseOrderComment & {
   displayName?: string;
   files: Array<{
      fileName: string;
      fileExt: string;
      getURL: string;
      isImage?: boolean;
   }>;
};
export enum DefaultBudgetType {
   Standard = 1,
   PurchaseRequest = 2,
   MinimumPartQuantityPurchaseOrder = 3,
}
@Injectable({ providedIn: "root" })
export class ManagePO {
   private purchaseOrders: Lookup<"poID", PurchaseOrder> = new Lookup("poID");
   private bills: Lookup<"prID", Bill> = new Lookup("prID");
   private purchaseOrderItems: Lookup<"poItemID", PurchaseOrderItem> = new Lookup(
      "poItemID",
   );
   private readonly purchaseOrderItemsByPartID: LimbleMap<
      number,
      Lookup<"poItemID", PurchaseOrderItem>
   > = new LimbleMap();
   private readonly purchaseOrderItemsByAssetID: LimbleMap<
      number,
      Lookup<"poItemID", PurchaseOrderItem>
   > = new LimbleMap();
   private readonly purchaseOrderItemsByChecklistID: LimbleMap<
      number,
      Lookup<"poItemID", PurchaseOrderItem>
   > = new LimbleMap();
   private readonly purchaseOrderItemsByVendorID: LimbleMap<
      number,
      Lookup<"poItemID", PurchaseOrderItem>
   > = new LimbleMap();
   private billTransactions: Lookup<"transactionID", BillTransaction> = new Lookup(
      "transactionID",
   );
   private budgets: Lookup<"budgetID", Budget> = new Lookup("budgetID");
   private budgetWorkflows: Lookup<"budgetWorkflowID", BudgetWorkflow> = new Lookup(
      "budgetWorkflowID",
   );
   private generalLedgers: Lookup<"glID", GeneralLedger> = new Lookup("glID");

   private OpenPurchaseOrderWatchVar = 0;
   private OpenBillWatchVar = 0;
   private generalLedgerWatchVar = 0;
   private budgetWatchVar = 0;
   private readonly axios = axios;

   public triggerPurchaseOrderItemInfoRefresh$: Subject<void> = new Subject();

   private readonly manageVendor = inject(ManageVendor);
   private readonly manageParts = inject(ManageParts);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageLang = inject(ManageLang);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageFiles = inject(ManageFiles);
   private readonly betterDate = inject(BetterDate);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly credService = inject(CredService);

   private readonly updateSignaler = new UpdateSignaler();

   public readonly updated = this.updateSignaler.updated;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.createObservables();
   }

   public getPurchaseOrders(): Lookup<"poID", PurchaseOrder> {
      return this.purchaseOrders;
   }

   public getPurchaseOrder(poID: number): PurchaseOrder | undefined {
      const purchaseOrder = this.purchaseOrders.get(poID);
      if (purchaseOrder) {
         if (purchaseOrder.prIDs === undefined) {
            purchaseOrder.prIDs = [];
         }
         if (purchaseOrder.poItemIDs === undefined) {
            purchaseOrder.poItemIDs = [];
         }
      }
      return purchaseOrder;
   }

   public getBills(): Lookup<"prID", Bill> {
      return this.bills;
   }

   public getBill(prID: number): Bill | undefined {
      return this.bills.get(prID);
   }

   public getPurchaseOrderItems(): Lookup<"poItemID", PurchaseOrderItem> {
      return this.purchaseOrderItems;
   }
   public getPurchaseOrderItem(poItemID: number): PurchaseOrderItem | undefined {
      return this.purchaseOrderItems.get(poItemID);
   }

   public getPurchaseOrderItemsByPartID(
      partID: number,
   ): Lookup<"poItemID", PurchaseOrderItem> | undefined {
      return this.purchaseOrderItemsByPartID.get(partID);
   }

   public getPurchaseOrderItemsByVendorID(
      vendorID: number,
   ): Lookup<"poItemID", PurchaseOrderItem> | undefined {
      return this.purchaseOrderItemsByVendorID.get(vendorID);
   }

   public getPurchaseOrderFromPurchaseOrderItem(
      poItemID: number,
   ): PurchaseOrder | undefined {
      const poItem = this.getPurchaseOrderItem(poItemID);
      if (!poItem) return undefined;
      return this.getPurchaseOrder(poItem.poID);
   }

   public getBillTransactions(): Lookup<"transactionID", BillTransaction> {
      return this.billTransactions;
   }

   public getBillTransaction(transactionID: number): BillTransaction | undefined {
      return this.billTransactions.get(transactionID);
   }

   public getBudgets(): Lookup<"budgetID", Budget> {
      return this.budgets;
   }

   public getBudget(budgetID: number): Budget | undefined {
      const budget = this.budgets.get(budgetID);
      if (budget && budget.budgetWorkflowIDs === undefined) {
         budget.budgetWorkflowIDs = [];
      }
      return budget;
   }

   public getBudgetWorkflows(): Lookup<"budgetWorkflowID", BudgetWorkflow> {
      return this.budgetWorkflows;
   }

   public getBudgetWorkflow(budgetWorkflowID: number): BudgetWorkflow | undefined {
      return this.budgetWorkflows.get(budgetWorkflowID);
   }

   public getGeneralLedgers(): Lookup<"glID", GeneralLedger> {
      return this.generalLedgers;
   }

   public getGeneralLedger(glID: number): GeneralLedger | undefined {
      return this.generalLedgers.get(glID);
   }

   createObservables = () => {
      this.manageObservables.createObservable(
         "OpenPurchaseOrderWatchVar",
         this.OpenPurchaseOrderWatchVar,
      );
      this.manageObservables.createObservable("OpenBillWatchVar", this.OpenBillWatchVar);
      this.manageObservables.createObservable(
         "generalLedgerWatchVar",
         this.generalLedgerWatchVar,
      );
      this.manageObservables.createObservable("budgetWatchVar", this.budgetWatchVar);
   };

   public getBillNumberForDisplay(prID: number): string | undefined {
      const bill = this.getBill(prID);
      if (!bill?.prNumber) return undefined;
      return `#${this.manageUtil.padString(bill.prNumber, 4)}`;
   }

   public buildPurchasingComment(
      filePathType: "po" | "pr",
      uniqueID: PurchaseOrder["poID"],
      comment: PurchaseOrderComment,
   ): AugmentedPurchasingComment {
      const augmentedComment: AugmentedPurchasingComment = { ...comment, files: [] };
      if (
         augmentedComment.externalEmailAddress &&
         augmentedComment.externalEmailAddress.length > 2
      ) {
         augmentedComment.displayName = `${augmentedComment.externalEmailAddress} - ${
            this.lang().ExternalUser
         }`;
      } else {
         const user = augmentedComment.userID
            ? this.manageUser.getFlatUsersIndex()[augmentedComment.userID]
            : undefined;
         if (user) {
            augmentedComment.displayName = `${user.userFirstName} ${user.userLastName}`;
         } else {
            const systemUser = augmentedComment.userID
               ? this.manageUser.getSysUsersIndex()[augmentedComment.userID]
               : undefined;
            if (systemUser && systemUser.userInternal == 1) {
               augmentedComment.displayName = this.lang().System;
            } else {
               augmentedComment.displayName = this.lang().DeletedUser;
            }
         }
      }

      //adds images and files if needed
      if (augmentedComment.commentFiles != null) {
         const files = augmentedComment.commentFiles.split("|");
         for (const fileString of files) {
            if (fileString.length > 1) {
               const file: any = {};
               file.fileName = fileString;
               const regex = /(?:\.([^.]+))?$/;
               file.fileExt = regex.exec(file.fileName)?.[1].toLowerCase();
               const customerID = this.manageUser.getCurrentUser().userInfo.customerID;
               file.getURL = `viewFile.php?f=upload-${customerID}/comments/${filePathType}/${augmentedComment.userID}/${uniqueID}/${file.fileName}`;

               if (this.manageFiles.checkImageExt(file.fileName)) {
                  file.isImage = true;
               }
               augmentedComment.files.push(file);
            }
         }
      }

      return augmentedComment;
   }

   public deleteLocalBillTransactionAndReferences(transactionID: number): void {
      const transaction = this.getBillTransaction(transactionID);

      const bill = transaction?.prID ? this.getBill(transaction.prID) : undefined;

      const purchaseOrderItem = transaction?.poItemID
         ? this.getPurchaseOrderItem(transaction.poItemID)
         : undefined;

      const billsTransactionIDsIndex = bill?.transactionIDs.indexOf(transactionID);
      if (billsTransactionIDsIndex && billsTransactionIDsIndex !== -1) {
         bill?.transactionIDs.splice(billsTransactionIDsIndex, 1);
      }

      const purchaseOrderItemTransactionIDsIndex =
         purchaseOrderItem?.transactionIDs.indexOf(transactionID);
      if (
         purchaseOrderItemTransactionIDsIndex !== undefined &&
         purchaseOrderItemTransactionIDsIndex !== -1
      ) {
         bill?.transactionIDs.splice(purchaseOrderItemTransactionIDsIndex, 1);
      }

      this.billTransactions.delete(transactionID);
   }

   public findPendingPurchaseOrderInfoForPart(
      partID: number,
      includeCompletedPOs: boolean,
   ): {
      purchaseOrders: Lookup<"poID", PurchaseOrder>;
      extraBatchesByPOID: LimbleMap<number, ExtraBatch>;
   } {
      const part = this.manageParts.getPart(partID);
      const poItemsForPart = this.getPurchaseOrderItemsByPartID(partID);

      const purchaseOrdersLookup: Lookup<
         "poID",
         PurchaseOrder & { poNumberForDisplay: string | undefined }
      > = new Lookup("poID");
      const extraBatchesByPOID = new LimbleMap<number, ExtraBatch>();

      if (!part || !poItemsForPart) {
         return { purchaseOrders: purchaseOrdersLookup, extraBatchesByPOID };
      }

      for (const item of poItemsForPart) {
         if (item.partID == partID) {
            if (!item.poID) {
               continue;
            }
            const purchaseOrder = this.getPurchaseOrder(item.poID);
            if (purchaseOrder) {
               const partExtraBatches = part.partExtraBatchIDs.map((extraBatchID) =>
                  this.manageParts.getExtraBatch(extraBatchID),
               );

               const extraBatch = partExtraBatches.find(
                  (batch) => batch?.poItemID === item.poItemID,
               );

               if (extraBatch) {
                  extraBatchesByPOID.set(item.poID, extraBatch);
               }

               if (purchaseOrder.state !== null && purchaseOrder.state <= 99) {
                  //if the PO is currently in setup, custom workflow, or partially received include them
                  purchaseOrdersLookup.set(item.poID, {
                     ...purchaseOrder,
                     poNumberForDisplay: this.getPurchaseOrderNumberForDisplay(
                        purchaseOrder.poID,
                     )?.poNumberForDisplay,
                  });
               } else if (purchaseOrder.state == 100 && includeCompletedPOs == true) {
                  //if it is fully completed we should only return it if the extra batch has parts that can be used on it...  We wouldn't want to show pending POs if they have been fully used up.
                  if (
                     extraBatch !== undefined &&
                     extraBatch.partQtyUsed != extraBatch.partQty
                  ) {
                     purchaseOrdersLookup.set(item.poID, {
                        ...purchaseOrder,
                        poNumberForDisplay: this.getPurchaseOrderNumberForDisplay(
                           purchaseOrder.poID,
                        )?.poNumberForDisplay,
                     });
                  }
               } else if (purchaseOrder.state == 101) {
                  //this PO's request was dispproved
               }
            }
         }
      }

      return { purchaseOrders: purchaseOrdersLookup, extraBatchesByPOID };
   }

   public recalcUsedPartsExtraBatchAndInvoiceData(answer): void {
      //after certain actions, the state of invoices, task-part-relations, and part "extraBatches"
      //must be trued up with their new values

      if (answer.data.invoices) {
         //we had to update invoices so let's process those here
         for (const invoice of answer.data.invoices) {
            if (
               typeof this.manageInvoice.getInvoicesIndex()[invoice.invoiceID] !==
               "undefined"
            ) {
               this.manageInvoice.getInvoicesIndex()[invoice.invoiceID].invoiceCost =
                  Number(invoice.invoiceCost);
            }
         }
      }

      if (answer.data.parts) {
         //we had to update invoices so let's process those here
         for (const part of answer.data.parts) {
            const relations = this.manageTask.getPartRelationsByPartID(part.partID);
            if (relations === undefined) {
               continue;
            }
            for (const relation of relations) {
               relation.usedPrice = part.usedPrice;
            }
         }
      }

      if (answer.data.extraBatches) {
         //we had to update batches so let's process those here
         for (const batch of answer.data.extraBatches) {
            const batchFromLookup = this.manageParts.getExtraBatch(batch.extraBatchID);
            if (!batchFromLookup) {
               return;
            }
            if (typeof batchFromLookup !== "undefined") {
               batchFromLookup.extraBatchID = Number(batch.extraBatchID);
               batchFromLookup.partID = Number(batch.partID);
               batchFromLookup.partPrice = Number(batch.partPrice);
               batchFromLookup.partQty = Number(batch.partQty);
               batchFromLookup.partQtyUsed = Number(batch.partQtyUsed);
               batchFromLookup.poItemID = Number(batch.poItemID);

               const partToUpdate = this.manageParts.getPart(batchFromLookup.partID);
               if (partToUpdate) {
                  this.manageParts.calculatePartData(partToUpdate);
               }
            }
         }
      }
   }

   public getPurchaseOrderEditable(
      poID: number,
      manageUser: ManageUser,
      credService: CredService,
      poWorkflows?: Array<PurchaseOrderWorkflow>,
   ): { editable: boolean; assignedToMeAndMissingPermission: boolean } | undefined {
      const po = this.getPurchaseOrder(poID);
      if (!po) return undefined;

      const permissionsInfo = this.checkPurchaseOrderEditableByCurrentUser(
         poID,
         manageUser,
         credService,
      );
      if (!permissionsInfo?.editable) return permissionsInfo;

      if (po.state == 100) {
         //it's closed so make it so it isn't editable
         return { editable: false, assignedToMeAndMissingPermission: false };
      } else if (
         (po.state == 99 || po.state == 98 || po.state == 97) &&
         po.budgetID !== null
      ) {
         //we are in the ready to receive state so check budget to see what the value should be
         const budget = this.getBudget(po.budgetID);

         if (budget?.awaitingAllowPOEdit == 1) {
            //if they are ready to receive check the budget for this to see if the budget allows for them to change it.
            return { editable: true, assignedToMeAndMissingPermission: false };
         }
      } else if (po.state !== null && po.state > 0 && po.state <= 96 && poWorkflows) {
         //we are in custom work flows;
         for (const workflow of poWorkflows) {
            if (workflow.order !== po.state || workflow.budgetWorkflowID === null) {
               continue;
            }
            const budgetWorkflow = this.getBudgetWorkflow(workflow.budgetWorkflowID);
            if (budgetWorkflow?.allowPOEdit == 1) {
               return { editable: true, assignedToMeAndMissingPermission: false };
            }
         }
      } else if (po.state == 0) {
         return { editable: true, assignedToMeAndMissingPermission: false }; //they are in setup mode so go ahead and allow them to change the PO
      }

      return { editable: false, assignedToMeAndMissingPermission: false };
   }

   private checkPurchaseOrderEditableByCurrentUser(
      poID: number,
      manageUser: ManageUser,
      credService: CredService,
   ): { editable: boolean; assignedToMeAndMissingPermission: boolean } | undefined {
      const po = this.getPurchaseOrder(poID);
      if (!po) return undefined;
      if (!po.locationID)
         return { editable: false, assignedToMeAndMissingPermission: true };
      //if they are a super user, let them edit or complete any POs.
      if (credService.checkCredGlobal(this.credService.Permissions.ManageLocations))
         return { editable: true, assignedToMeAndMissingPermission: false };
      //check if they have permission to edit or complete any POs
      if (
         credService.isAuthorized(
            po.locationID,
            this.credService.Permissions.EditAndCompleteAnyPOs,
         )
      )
         return { editable: true, assignedToMeAndMissingPermission: false };
      if (
         credService.isAuthorized(
            po.locationID,
            this.credService.Permissions.EditAndCompleteMyPOs,
         ) ||
         po.state === 0
      ) {
         //they have permission to edit or complete their own so let's check if they own this one.
         if (po.userID === manageUser.getCurrentUser().gUserID)
            return { editable: true, assignedToMeAndMissingPermission: false };
         const userProfiles = manageUser.getCurrentUser().profileLoc;
         const userBelongsToPoProfile = userProfiles.some(
            (profile) =>
               profile.profileID === po.profileID && profile.locationID === po.locationID,
         );
         if (userBelongsToPoProfile)
            return { editable: true, assignedToMeAndMissingPermission: false };
      }

      return { editable: false, assignedToMeAndMissingPermission: true };
   }

   public getBillEditable(prID: number): boolean {
      const bill = this.getBill(prID);
      let editable = false;
      if (!bill) return editable;
      if (bill.status == 0) {
         editable = true;
      } else if (bill.status == 1) {
         editable = true;
      } else if (bill.status == 2) {
         editable = false;
      }
      return editable;
   }

   public calculatePurchaseOrderDeliveryDate(
      purchaseOrderID: number,
   ): number | undefined {
      const purchaseOrder = this.getPurchaseOrder(purchaseOrderID);
      if (!purchaseOrder) {
         return undefined;
      }
      let deliveryDate: number;
      if (purchaseOrder.state !== null && purchaseOrder.state <= 97) {
         //we haven't received anything yet so use the expected date
         deliveryDate = purchaseOrder.expectedDate ?? 0;
      } else {
         //they are partially received so we need to build the HTML appropriately

         let date = 0;
         for (const prID of purchaseOrder.prIDs) {
            const pr = this.bills.get(prID);
            if (pr === undefined) {
               continue;
            }
            let total = 0;
            for (const transactionID of pr.transactionIDs) {
               const transaction = this.billTransactions.get(transactionID);
               if (transaction === undefined) continue;
               total += transaction.qtyReceived ?? 0;
            }
            if (total > 0) {
               if (date < Number(pr.date)) {
                  date = pr.date ?? 0;
               }
            }
         }
         deliveryDate = date;
      }
      return deliveryDate;
   }

   public calcPurchaseOrderLeadTime(poID: number):
      | {
           part: {
              seconds: number;
              hours: number;
              days: number;
              count: number;
           };
           service: {
              seconds: number;
              hours: number;
              days: number;
           };
           other: {
              seconds: number;
              hours: number;
              days: number;
              count: number;
           };
        }
      | undefined {
      const purchaseOrder = this.getPurchaseOrder(poID);
      if (purchaseOrder === undefined) {
         return undefined;
      }
      if (purchaseOrder.vendorID === null || purchaseOrder.vendorID <= 0) {
         return undefined;
      }

      const purchaseOrderItems = this.purchaseOrderItemsByVendorID.get(
         purchaseOrder.vendorID,
      );

      if (purchaseOrderItems === undefined) {
         return undefined;
      }

      let partCount = 0;
      let partDifference = 0;
      let serviceCount = 0;
      let serviceDifference = 0;
      let otherCount = 0;
      let otherDifference = 0;
      for (const purchaseOrderItem of purchaseOrderItems) {
         if (purchaseOrderItem.poID === null) continue;
         //this purchase order may be different from the purchase order accessed at the beginning
         //of the function, since this is ALL purchaseOrderItems for a vendor, so we need to
         //grab it specifically again
         const parentPurchaseOrder = this.getPurchaseOrder(purchaseOrderItem.poID);
         //only calculate it if it is partially received
         if (
            (parentPurchaseOrder?.state && parentPurchaseOrder?.state < 98) ||
            purchaseOrder.date === null
         ) {
            continue;
         }
         const poDate = purchaseOrder.date; //when it should have arrived

         for (const transactionID of purchaseOrderItem.transactionIDs) {
            const transaction = this.getBillTransaction(transactionID);
            if (transaction === undefined) continue;
            const dateReceived = transaction.dateReceived; //when it actually received
            if (dateReceived === null || dateReceived <= poDate) {
               continue;
            }
            if (purchaseOrderItem.itemType == 1) {
               //parts
               partCount++;
               partDifference = partDifference + (dateReceived - poDate);
            } else if (purchaseOrderItem.itemType == 2) {
               //service
               serviceCount++;
               serviceDifference = serviceDifference + (dateReceived - poDate);
            } else if (purchaseOrderItem.itemType == 4) {
               //other
               otherCount++;
               otherDifference = otherDifference + (dateReceived - poDate);
            }
         }
      }

      if (partCount > 0) {
         partDifference = partDifference / partCount;
      }
      if (serviceCount > 0) {
         serviceDifference = serviceDifference / serviceCount;
      }
      if (otherCount > 0) {
         otherDifference = otherDifference / otherCount;
      }

      return {
         part: {
            seconds: partDifference,
            hours: Math.floor(partDifference / 60 / 60),
            days: Math.floor(partDifference / 60 / 60 / 24),
            count: partCount,
         },
         service: {
            seconds: serviceDifference,
            hours: Math.floor(serviceDifference / 60 / 60),
            days: Math.floor(serviceDifference / 60 / 60 / 24),
         },
         other: {
            seconds: otherDifference,
            hours: Math.floor(otherDifference / 60 / 60),
            days: Math.floor(otherDifference / 60 / 60 / 24),
            count: otherCount,
         },
      };
   }

   public setPurchaseOrderVendorItemRate(
      poItemID: number,
      relations: Array<PartVendorRelation>,
   ): number | undefined {
      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);

      if (purchaseOrderItem?.rate && purchaseOrderItem.itemType !== 1) {
         this.setPurchaseOrderItemRate(
            purchaseOrderItem.poItemID,
            purchaseOrderItem?.rate,
         );
         return purchaseOrderItem?.rate;
      }

      const itemRate = this.getPurchaseOrderVendorPartItemRate(poItemID, relations);

      if (purchaseOrderItem && itemRate) {
         this.setPurchaseOrderItemRate(purchaseOrderItem.poItemID, itemRate);
      }
      return itemRate;
   }

   private getPurchaseOrderVendorPartItemRate(
      poItemID: number,
      relations: Array<PartVendorRelation>,
   ): number | undefined {
      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
      if (!purchaseOrderItem?.partID || !purchaseOrderItem?.poID) return undefined;
      const purchaseOrder = this.getPurchaseOrder(purchaseOrderItem.poID);
      if (!purchaseOrder?.vendorID) return undefined;

      const partStandardRate =
         this.manageParts.getPart(purchaseOrderItem.partID)?.partPrice ?? 0;
      const vendor = this.manageVendor.getVendor(purchaseOrder.vendorID);
      if (!vendor) {
         return partStandardRate;
      }

      const vendorPartRelation = relations.find(
         (relation) =>
            relation.partID === purchaseOrderItem.partID &&
            relation.vendorID === purchaseOrder.vendorID &&
            relation.partPrice &&
            relation.partPrice > 0,
      );

      if (vendorPartRelation === undefined) {
         return partStandardRate;
      }

      return vendorPartRelation.partPrice;
   }

   public getPurchaseOrderItemRelatedInfoSignal(
      poItemID: number,
   ): Signal<PurchaseOrderItemRelatedInfo | undefined | null> {
      return computed(() => {
         if (
            this.updated() === null ||
            this.manageParts.updated() === null ||
            this.manageAssociations.partVendorRelationUpdated() === null
         ) {
            return null;
         }

         return this.getPurchaseOrderItemRelatedInfo(poItemID);
      });
   }

   public getPurchaseOrderItemRelatedInfo(
      poItemID: number,
   ): PurchaseOrderItemRelatedInfo | undefined {
      const poItem = this.getPurchaseOrderItem(poItemID);
      if (!poItem) return undefined;
      let itemName;
      let itemSearchStr;
      let itemNumber;
      if (poItem.itemType == 1 && poItem.partID) {
         const part = this.manageParts.getPart(poItem.partID);
         if (!part) {
            return undefined;
         }
         itemName = part.partName;
         itemSearchStr = part.partName;
         const vendorPartRelations = part.partVendorRelationIDs.map((relationID) =>
            this.manageAssociations.getPartVendorRelation(relationID),
         );

         const vendorPartRelation = vendorPartRelations.find((relation) => {
            if (!poItem.poID) return false;
            const purchaseOrder = this.getPurchaseOrder(poItem.poID);
            if (!purchaseOrder) return false;
            const vendorID = purchaseOrder.vendorID;
            return (
               relation?.partID === poItem.partID &&
               relation?.vendorID === vendorID &&
               relation?.partNumber &&
               relation?.partNumber.length > 0
            );
         });

         if (vendorPartRelation !== undefined) {
            const vendorNumber =
               this.manageParts.getAltPartNameAndNumberFromVendorRelation(
                  vendorPartRelation,
               );
            itemName = vendorNumber;
            itemSearchStr += vendorNumber;
         } else if (part.partNumber) {
            itemNumber = part.partNumber;
            itemSearchStr += ` - ${part.partNumber}`;
         }
      } else if (poItem.itemType == 2) {
         //service
         itemName = poItem.prodServDescription;
         itemSearchStr = poItem.prodServDescription;
      } else if (poItem.itemType == 3) {
         //asset
      } else if (poItem.itemType == 4) {
         //other
         itemName = poItem.prodServDescription;
         itemSearchStr = poItem.prodServDescription;
      } else {
         itemName = `<b class="red-color">${this.lang().SelectItem}</b>`; //default value if an item type is not selected
         itemSearchStr = this.lang().SelectItem;
      }
      return {
         itemName,
         itemNumber,
         itemSearchStr,
      };
   }

   //
   //
   //  When changing PO items we add and remove parts .  This function trues that up on the JS side
   //
   //
   checkAndProcessNewPartsFromPOItemUpdate = (
      partRelationsCleanup,
      partRelation,
      startedFromChecklistID: number,
   ) => {
      //during the of changing po items we may need to clean up parts relations
      const task = this.manageTask.getTaskLocalLookup(startedFromChecklistID);
      if (partRelationsCleanup) {
         for (const relation of partRelationsCleanup) {
            this.manageTask.getPartRelations().delete(relation.relationID);
            //clean up on the task
            const relationIDIndex = task?.partRelationIDs.indexOf(relation.relationID);
            if (relationIDIndex !== undefined && relationIDIndex !== -1) {
               task?.partRelationIDs.splice(relationIDIndex, 1);
            }
         }
      }
      // during that same process we might be adding them back...
      if (partRelation) {
         //add it to the memory
         this.manageTask.getPartRelations().set(partRelation.relationID, partRelation);
         //add to the task
         if (task) {
            task.partRelationIDs.push(partRelation.relationID);
         }
         this.manageTask.addPartRelationByChecklistIDToLocalData(partRelation);
      }
   };

   //
   //
   //this function cleans up invoices and parts.
   //For example if I start a service that adds an invoice to the task.  On the back end we clean that up, but we need to clean it up here too
   //
   //
   private removeInvoicesAndPartsWhenPurchaseOrderItemDeleted(poItemID: number): void {
      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
      if (!purchaseOrderItem) return;
      const purchaseOrder = purchaseOrderItem.poID
         ? this.getPurchaseOrder(purchaseOrderItem.poID)
         : undefined;

      //fix part qty
      if (purchaseOrderItem.partID) {
         const part = this.manageParts.getPart(purchaseOrderItem.partID);
         if (part) {
            this.manageParts.calculatePartData(part);
         }
      }
      //clean up invoices
      const invoices = this.manageInvoice.getInvoices();
      let index = invoices.length;
      while (index--) {
         if (invoices[index].poItemID == poItemID) {
            invoices.splice(index, 1); //remove it from the array;
         }
      }

      const task = purchaseOrder?.checklistID
         ? this.manageTask.getTaskLocalLookup(purchaseOrder.checklistID)
         : undefined;
      if (task) {
         for (const [index2, invoiceID] of task.invoiceIDs.entries()) {
            const invoice = this.manageInvoice.getInvoicesIndex()[invoiceID];
            if (invoice?.poItemID === poItemID) {
               task.invoiceIDs.splice(index2, 1);
               break;
            }
         }
      }

      //clean up parts only if the task is still open and the PO was started from it.

      if (
         purchaseOrder?.checklistID &&
         purchaseOrder.checklistID > 0 &&
         task &&
         task.checklistCompletedDate == 0 &&
         task.checklistTemplate == 0
      ) {
         //we only do this for tasks that the PO was started from.
         //only do if the task is currently not completed
         if (task.partRelationIDs.length) {
            for (const [index2, relationID] of task.partRelationIDs.entries()) {
               const partRelation = this.manageTask.getPartRelation(relationID);
               if (partRelation?.poItemID === poItemID) {
                  task.partRelationIDs.splice(index2, 1);
               }
            }
         }
      }
   }

   incOpenPurchaseOrderWatchVar = () => {
      this.OpenPurchaseOrderWatchVar++;
      this.manageObservables.updateObservable(
         "OpenPurchaseOrderWatchVar",
         this.OpenPurchaseOrderWatchVar,
      );
      this.buildSecondaryLookups();
   };
   incOpenBillWatchVar = () => {
      this.OpenBillWatchVar++;
      this.manageObservables.updateObservable("OpenBillWatchVar", this.OpenBillWatchVar);
      this.buildSecondaryLookups();
   };
   incGeneralLedgerWatchVar = () => {
      this.generalLedgerWatchVar++;
      this.manageObservables.updateObservable(
         "generalLedgerWatchVar",
         this.generalLedgerWatchVar,
      );
   };
   incBudgetWatchVar = () => {
      this.budgetWatchVar++;
      this.manageObservables.updateObservable("budgetWatchVar", this.budgetWatchVar);
   };

   public async getData() {
      await Promise.all([
         this.fetchPurchaseOrders(),
         this.fetchBills(),
         this.fetchPurchaseOrderItems(),
         this.fetchBillTransactions(),
         this.fetchBudgets(),
         this.fetchBudgetWorkflows(),
         this.fetchGeneralLedgers(),
      ]);

      this.buildSecondaryLookups();
      this.updateSignaler.trigger();
      return this.manageUser.ready$.subscribe(() => {
         this.incOpenPurchaseOrderWatchVar();
         this.incOpenBillWatchVar();
         this.incBudgetWatchVar();
      });
   }

   private buildSecondaryLookups() {
      const purchaseOrderItems = this.getPurchaseOrderItems();
      for (const purchaseOrderItem of purchaseOrderItems) {
         if (purchaseOrderItem.partID) {
            const existingMappedItems = this.purchaseOrderItemsByPartID.get(
               purchaseOrderItem.partID,
            );
            if (!existingMappedItems) {
               const purchaseOrderItemLookup: Lookup<"poItemID", PurchaseOrderItem> =
                  new Lookup("poItemID");
               purchaseOrderItemLookup.set(purchaseOrderItem.poItemID, purchaseOrderItem);
               this.purchaseOrderItemsByPartID.set(
                  purchaseOrderItem.partID,
                  purchaseOrderItemLookup,
               );
            }
            existingMappedItems?.set(purchaseOrderItem.poItemID, purchaseOrderItem);
         }
         if (purchaseOrderItem.assetID) {
            const existingMappedItems = this.purchaseOrderItemsByAssetID.get(
               purchaseOrderItem.assetID,
            );
            if (!existingMappedItems) {
               const purchaseOrderItemLookup: Lookup<"poItemID", PurchaseOrderItem> =
                  new Lookup("poItemID");
               purchaseOrderItemLookup.set(purchaseOrderItem.poItemID, purchaseOrderItem);
               this.purchaseOrderItemsByAssetID.set(
                  purchaseOrderItem.assetID,
                  purchaseOrderItemLookup,
               );
            }
            existingMappedItems?.set(purchaseOrderItem.assetID, purchaseOrderItem);
         }
         if (purchaseOrderItem.checklistID) {
            const existingMappedItems = this.purchaseOrderItemsByChecklistID.get(
               purchaseOrderItem.checklistID,
            );
            if (!existingMappedItems) {
               const purchaseOrderItemLookup: Lookup<"poItemID", PurchaseOrderItem> =
                  new Lookup("poItemID");
               purchaseOrderItemLookup.set(purchaseOrderItem.poItemID, purchaseOrderItem);
               this.purchaseOrderItemsByChecklistID.set(
                  purchaseOrderItem.checklistID,
                  purchaseOrderItemLookup,
               );
            }
            existingMappedItems?.set(purchaseOrderItem.checklistID, purchaseOrderItem);
         }

         const associatedPurchaseOrder = purchaseOrderItem.poID
            ? this.getPurchaseOrder(purchaseOrderItem.poID)
            : undefined;
         if (associatedPurchaseOrder?.vendorID) {
            const existingMappedItems = this.purchaseOrderItemsByVendorID.get(
               associatedPurchaseOrder.vendorID,
            );
            if (!existingMappedItems) {
               const purchaseOrderItemLookup: Lookup<"poItemID", PurchaseOrderItem> =
                  new Lookup("poItemID");
               purchaseOrderItemLookup.set(purchaseOrderItem.poItemID, purchaseOrderItem);
               this.purchaseOrderItemsByVendorID.set(
                  associatedPurchaseOrder.vendorID,
                  purchaseOrderItemLookup,
               );
               continue;
            }
            existingMappedItems.set(purchaseOrderItem.poItemID, purchaseOrderItem);
         }
      }
   }

   private async fetchPurchaseOrders() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/pos`);

      logApiPerformance("pos", startTime, this.manageUser.getCurrentUser(), response);
      this.purchaseOrders = new Lookup("poID", response.data);
   }

   private async fetchBills() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/pos/prs`);

      logApiPerformance("posPrs", startTime, this.manageUser.getCurrentUser(), response);
      this.bills = new Lookup("prID", response.data);
   }

   private async fetchPurchaseOrderItems() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/pos/items`);

      logApiPerformance(
         "posItems",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.purchaseOrderItems = new Lookup("poItemID", response.data);
   }

   public async refreshPurchaseOrderItem(id: number): Promise<void> {
      const purchaseOrderItem = await this.fetchSinglePurchaseOrderItem(id);
      this.purchaseOrderItems.set(id, purchaseOrderItem);
      this.updateSignaler.trigger();
   }

   public async fetchSinglePurchaseOrderItem(
      poItemID: number,
   ): Promise<PurchaseOrderItem> {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/pos/items/${poItemID}`);

      logApiPerformance(
         "posItems",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      return response.data[0];
   }

   private async fetchBillTransactions() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/pos/prs/transactions`);

      logApiPerformance(
         "posPrsTransactions",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.billTransactions = new Lookup("transactionID", response.data);
   }

   private async fetchBudgets() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/pos/budgets`);

      logApiPerformance(
         "posBudgets",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.budgets = new Lookup("budgetID", response.data);
   }

   private async fetchBudgetWorkflows() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/pos/budgets/workflows`);

      logApiPerformance(
         "posBudgetsWorkflows",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.budgetWorkflows = new Lookup("budgetWorkflowID", response.data);
   }

   private async fetchGeneralLedgers() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/pos/generalLedgers`);

      logApiPerformance(
         "posGeneralLedgers",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.generalLedgers = new Lookup("glID", response.data);
   }

   public getGeneralLedgersForAsset(
      assetID: number,
      locationID: number,
   ): Array<GeneralLedger> {
      const selectedLedgers: Array<GeneralLedger> = [];
      for (const ledger of this.generalLedgers) {
         if (ledger.assetID === assetID && ledger.locationID === locationID) {
            selectedLedgers.push(ledger);
         }
      }
      return selectedLedgers;
   }

   getOpenPurchaseOrderWatchVar = () => {
      return this.OpenPurchaseOrderWatchVar;
   };

   getOpenBillWatchVar = () => {
      return this.OpenBillWatchVar;
   };

   getGeneralLedgerWatchVar = () => {
      return this.generalLedgerWatchVar;
   };

   getBudgetWatchVar = () => {
      return this.budgetWatchVar;
   };

   public getPurchaseOrdersByVendor(vendorID: number): Lookup<"poID", PurchaseOrder> {
      const purchaseOrders = this.getPurchaseOrders();
      const vendorPOs = purchaseOrders.filter(
         (purchaseOrder) => purchaseOrder.vendorID == vendorID,
      );

      return vendorPOs;
   }

   public getOpenPurchaseOrdersByVendor(vendorID: number): Lookup<"poID", PurchaseOrder> {
      const purchaseOrders = this.getPurchaseOrders();
      const vendorPOs = purchaseOrders.filter(
         (purchaseOrder) =>
            purchaseOrder.vendorID === vendorID && purchaseOrder.state !== 100,
      );

      return vendorPOs;
   }

   public getPurchaseOrdersByPart(partID: number): Lookup<"poID", PurchaseOrder> {
      const purchaseOrderItems = this.getPurchaseOrderItemsByPartID(partID);
      const purchaseOrders: Lookup<"poID", PurchaseOrder> = new Lookup("poID");

      if (!purchaseOrderItems) {
         return purchaseOrders;
      }

      for (const purchaseOrderItem of purchaseOrderItems) {
         const purchaseOrder = this.getPurchaseOrder(purchaseOrderItem.poID);
         if (purchaseOrder) {
            purchaseOrders.set(purchaseOrder.poID, purchaseOrder);
         }
      }

      return purchaseOrders;
   }

   public async addPurchaseOrder(
      locationID: number,
      poItemsToAdd: Array<PurchaseOrderItemToAddSkeleton>,
      vendorID: number,
      checklistID: number,
      requestedByUserID: number,
      requestDescription: string,
      source: string,
   ): Promise<AxiosResponse | undefined> {
      const poItemsToAddStringified = JSON.stringify(poItemsToAdd);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addPO",
         },
         data: {
            locationID,
            poItemsToAdd: poItemsToAddStringified,
            vendorID,
            checklistID,
            requestedByUserID,
            requestDescription,
            source,
         },
      });

      if (post.data.success == true) {
         if (
            post.data.budget &&
            this.getBudget(post.data.budget.budgetID) === undefined
         ) {
            this.budgets.set(post.data.budget.budgetID, {
               ...post.data.budget,
               budgetWorkflowIDs: [],
            });
         }
         if (post.data.po) {
            this.purchaseOrders.set(post.data.po.poID, {
               ...post.data.po,
               poItemIDs: [],
               prIDs: [],
            });
            if (post.data.poItemsAdded) {
               for (const poItem of post.data.poItemsAdded) {
                  this.purchaseOrderItems.set(poItem.poItemID, {
                     ...poItem,
                     transactionIDs: [],
                  });
                  const purchaseOrderToUpdate = this.purchaseOrders.get(poItem.poID);
                  purchaseOrderToUpdate?.poItemIDs.push(poItem.poItemID);
               }
            }
         }

         if (post.data.workedPOInvoicesToAdd) {
            for (const key in post.data.workedPOInvoicesToAdd) {
               const item = post.data.workedPOInvoicesToAdd[key];
               if (item.invoice) {
                  const invoices = this.manageInvoice.getInvoices();
                  invoices.push(item.invoice);
                  const newIndex = invoices.length - 1;
                  this.manageInvoice.getInvoicesIndex()[item.invoice.invoiceID] =
                     invoices[newIndex];

                  const task = this.manageTask.getTaskLocalLookup(item.checklistID);
                  if (task) {
                     task.invoiceIDs.push(item.invoiceID);
                  }
               }
            }
         }

         if (post.data.workedPartsToUpdate) {
            for (const part of post.data.workedPartsToUpdate) {
               this.checkAndProcessNewPartsFromPOItemUpdate(
                  part.partRelationsCleanup,
                  part.partRelation,
                  part.startedFromChecklistID,
               );
            }
         }
      }
      return post;
   }

   /****************************************
    *@function resetSearchHints
    *@purpose
    *@name resetSearchHints
    *@param
    *@return
    ****************************************/
   resetSearchHints = (POsToReset) => {
      for (const tempPO of POsToReset) {
         tempPO.searchHint = false;
      }
   };

   public async getPurchaseOrderDetails(poID: number): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "getPODetails",
         },
         data: {
            poID: poID,
         },
      });

      const localPurchaseOrder = this.getPurchaseOrder(poID);
      if (post.data.success !== true || localPurchaseOrder === undefined) {
         return post;
      }
      Object.assign(localPurchaseOrder, post.data.po);

      for (const remotePurchaseOrderItem of post.data.POsItems) {
         const localPurchaseOrderItem = this.getPurchaseOrderItem(
            remotePurchaseOrderItem.poItemID,
         );

         if (!localPurchaseOrder.poItemIDs.includes(remotePurchaseOrderItem.poItemID)) {
            localPurchaseOrder.poItemIDs.push(remotePurchaseOrderItem.poItemID);
         }

         if (localPurchaseOrderItem === undefined) {
            remotePurchaseOrderItem.transactionIDs = [];
            this.purchaseOrderItems.set(
               remotePurchaseOrderItem.poItemID,
               remotePurchaseOrderItem,
            );
            continue;
         }

         Object.assign(localPurchaseOrderItem, remotePurchaseOrderItem);
      }

      for (const remoteBill of post.data.PRs) {
         const localBill = this.getBill(remoteBill.prID);

         if (!localPurchaseOrder.prIDs.includes(remoteBill.prID)) {
            localPurchaseOrder.prIDs.push(remoteBill.prID);
         }

         if (localBill === undefined) {
            remoteBill.transactionIDs = [];
            this.bills.set(remoteBill.poItemID, remoteBill);
            continue;
         }
         Object.assign(localBill, remoteBill);
      }

      for (const remoteTransaction of post.data.PRsTransactions) {
         const localTransaction = this.getBillTransaction(
            remoteTransaction.transactionID,
         );

         if (localTransaction === undefined) {
            this.billTransactions.set(remoteTransaction.transactionID, remoteTransaction);
            continue;
         }
         Object.assign(localTransaction, remoteTransaction);
      }

      this.buildSecondaryLookups();

      return post;
   }

   public async getPurchaseOrderWorkflows(poID: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "getPOWorkflows",
         },
         data: {
            poID: poID,
         },
      });
      return post;
   }

   public async getBillDetails(
      prID: number,
   ): Promise<AugmentedPurchasingComment[] | undefined> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "getPRDetails",
         },
         data: {
            prID: prID,
         },
      });

      if (!post.data.success) {
         return [];
      }

      const transactionIDs: number[] = post.data.PRsTransactions.map((transaction) => {
         this.billTransactions.set(transaction.transactionID, transaction);
         return transaction.transactionID;
      });

      this.bills.set(
         prID,
         Object.assign(post.data.pr, { transactionIDs: transactionIDs }),
      );

      return post.data.comments;
   }

   public async getBudgetDetails(budgetID: number): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "getBudgetDetails",
         },
         data: {
            budgetID: budgetID,
         },
      });

      if (!post.data.success) return false;
      const budget = this.getBudget(budgetID);
      if (!budget) return false;
      Object.assign(budget, post.data.budget); //update with the latest information

      for (const workflow of post.data.budgetWorkflows) {
         this.budgetWorkflows.set(workflow.budgetWorkflowID, workflow);
      }
      return true;
   }

   public async updateVendor(
      poID: number,
      vendorID: number,
   ): Promise<AxiosResponse | undefined> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updateVendor",
         },
         data: {
            poID: poID,
            vendorID: vendorID,
         },
      });

      if (post.data.success !== true) {
         return post;
      }
      const purchaseOrder = this.getPurchaseOrder(poID);
      if (!purchaseOrder) return undefined;

      purchaseOrder.vendorID = vendorID;
      purchaseOrder.field1 = post.data.field1 ?? null;
      purchaseOrder.field2 = post.data.field2 ?? null;
      purchaseOrder.field3 = post.data.field3 ?? null;
      purchaseOrder.field4 = post.data.field4 ?? null;
      purchaseOrder.field5 = post.data.field5 ?? null;
      purchaseOrder.field6 = post.data.field6 ?? null;
      this.incOpenBillWatchVar();
      return post;
   }

   public async setDefaultCustomVendorField(
      poID: number,
      vendorID: number,
      field: number,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setDefaultCustomVendorField",
         },
         data: {
            poID: poID,
            vendorID: vendorID,
            field: field,
         },
      });

      const purchaseOrder = this.getPurchaseOrder(poID);
      if (post.data.success !== true || purchaseOrder === undefined) {
         return post;
      }

      const tempField = `field${field}`;
      if (post.data.field) {
         purchaseOrder[tempField] = post.data.field;
      } else {
         purchaseOrder[tempField] = null;
      }

      this.incOpenBillWatchVar();

      return post;
   }

   public async setBudget(poID: number, budgetID: number) {
      const response = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setBudget",
         },
         data: {
            poID,
            budgetID,
         },
      });

      if (response.data.success === true) {
         this.incOpenBillWatchVar();
      }

      return response;
   }

   public async updatePurchaseOrderDate(poID: number, newDate: number): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePODate",
         },
         data: {
            poID: poID,
            newDate: newDate,
         },
      });
      if (!post.data.success) return false;
      this.incOpenBillWatchVar();
      return true;
   }

   public async updatePurchaseOrderExpectedDate(
      poID: number,
      newDate: number,
   ): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePOExpectedDeliveryDate",
         },
         data: {
            poID: poID,
            newDate: newDate,
         },
      });

      if (post.data.success !== true) {
         return false;
      }

      this.incOpenBillWatchVar();
      return true;
   }

   public async updatePurchaseOrderNotesToVendor(
      poID: number,
      notesToVendor: string,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePONotesToVendor",
         },
         data: {
            poID: poID,
            notesToVendor: notesToVendor,
         },
      });

      const purchaseOrder = this.getPurchaseOrder(poID);

      if (post.data.success !== true || purchaseOrder === undefined) {
         return post;
      }
      purchaseOrder.notesToVendor = notesToVendor;

      this.incOpenBillWatchVar();

      return post;
   }

   public async addPurchaseOrderItem(poID: number): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addPOItem",
         },
         data: {
            poID: poID,
         },
      });

      if (post.data.success !== true) {
         return post;
      }
      const purchaseOrder = this.getPurchaseOrder(poID);
      if (!purchaseOrder) return post;

      const newPurchaseOrderItem: PurchaseOrderItem = Object.assign(post.data.item, {
         transactionIDs: [],
      });

      this.purchaseOrderItems.set(post.data.item.poItemID, newPurchaseOrderItem);

      purchaseOrder.poItemIDs.push(newPurchaseOrderItem.poItemID);

      if (post.data.newState) {
         purchaseOrder.state = post.data.newState;
      }

      this.incOpenBillWatchVar();

      if (post.data.item.glID > 0) {
         this.incGeneralLedgerWatchVar();
      }

      return post;
   }

   public async deletePurchaseOrder(poID: number, locationID: number): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deletePO",
         },
         data: {
            poID: poID,
            locationID: locationID,
         },
      });

      if (post.data.success !== true) {
         return false;
      }

      //the below call will true up the front end with whatever changes happened to the
      //parts on the back end in the process of deleting the PO
      await this.manageParts.getData();

      const purchaseOrder = this.getPurchaseOrder(poID);
      if (!purchaseOrder) return false;

      const transactionIDsToRemove: Array<number> = purchaseOrder.poItemIDs
         .map((poItemID) => {
            const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
            return purchaseOrderItem?.transactionIDs ?? [];
         })
         .flat();
      //remove transactions associated with the purchaseOrderItems
      for (const transactionID of transactionIDsToRemove) {
         this.billTransactions.delete(transactionID);
      }

      //remove purchaseOrderItems associated with the purchaseOrder
      for (const poItemID of purchaseOrder.poItemIDs) {
         this.removeInvoicesAndPartsWhenPurchaseOrderItemDeleted(poItemID);
         this.purchaseOrderItems.delete(poItemID);
      }

      //remove bills associated with the purchaseOrder
      for (const prID of purchaseOrder.prIDs) {
         this.bills.delete(prID);
      }

      //finally removes the purchaseOrder itself
      this.purchaseOrders.delete(poID);

      this.incOpenPurchaseOrderWatchVar();
      this.manageParts.incrementPartsWatchVar();

      return true;
   }

   public async mergePurchaseOrders(
      currentPOID: number,
      poIDsToMerge: Array<number>,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "mergePOs",
         },
         data: {
            currentPOID: currentPOID,
            poIDsToMerge: poIDsToMerge,
         },
      });

      if (post.data.success !== true) {
         return post;
      }

      await this.manageParts.getData();
      await this.getData();
      this.incOpenPurchaseOrderWatchVar();
      return post;
   }

   public async deletePurchaseOrderItem(
      poItemID: number,
   ): Promise<AxiosResponse | undefined> {
      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
      if (!purchaseOrderItem?.poID) return undefined;
      const purchaseOrder = this.getPurchaseOrder(purchaseOrderItem.poID);
      if (purchaseOrder === undefined) return undefined;

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deletePOItem",
         },
         data: {
            poItemID: poItemID,
         },
      });

      if (post.data.success !== true) {
         return post;
      }

      //we need to clean up invoices and parts that exist on open tasks.
      this.removeInvoicesAndPartsWhenPurchaseOrderItemDeleted(poItemID);

      //delete the PO Item
      this.purchaseOrderItems.delete(poItemID);

      const indexOfIDToRemove = purchaseOrder?.poItemIDs.indexOf(poItemID);
      if (indexOfIDToRemove !== -1) {
         purchaseOrder?.poItemIDs.splice(indexOfIDToRemove, 1);
      }

      if (post.data.newState) {
         purchaseOrder.state = post.data.newState;
      }

      this.recalcUsedPartsExtraBatchAndInvoiceData(post);

      this.updateSignaler.trigger();
      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return post;
   }

   public async addBillTransactions(
      prID: number,
      items: Array<{ poItemID: number; qtyReceived: number }>,
   ): Promise<AxiosResponse> {
      const itemsStringified = JSON.stringify(items);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addPRTransactions",
         },
         data: {
            prID: prID,
            arr: itemsStringified,
         },
      });

      if (post.data.success !== true) {
         return post;
      }

      for (const transaction of post.data.transactions) {
         transaction.qtyReceived = Number(transaction.qtyReceived);
         this.billTransactions.set(transaction.transactionID, transaction);
         const newTransaction = this.billTransactions.get(transaction.transactionID);
         assert(newTransaction?.prID);
         assert(newTransaction?.poItemID);
         this.bills
            .get(newTransaction.prID)
            ?.transactionIDs.push(newTransaction?.transactionID);
         this.purchaseOrderItems
            .get(newTransaction.poItemID)
            ?.transactionIDs.push(newTransaction?.transactionID);
      }

      this.incOpenPurchaseOrderWatchVar();
      this.incOpenBillWatchVar();

      return post;
   }

   public async deleteBillTransaction(transactionID: number): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deletePRTransaction",
         },
         data: {
            transactionID: transactionID,
         },
      });

      if (post.data.success !== true) {
         return post;
      }

      //cleanup the Bill transaction
      this.deleteLocalBillTransactionAndReferences(transactionID);

      const prID = this.billTransactions.get(transactionID)?.prID;
      if (prID) {
         const bill = this.bills.get(prID);

         //if the PO changed state we need to update it
         if (
            bill?.poID &&
            post.data.newState != false &&
            this.purchaseOrders.get(bill.poID)
         ) {
            const purchaseOrder = this.purchaseOrders.get(bill.poID);
            if (purchaseOrder) {
               purchaseOrder.state = post.data.newState;
            }
         }
      }

      this.recalcUsedPartsExtraBatchAndInvoiceData(post);

      this.incOpenPurchaseOrderWatchVar();
      this.incOpenBillWatchVar();

      return post;
   }

   public async setBillTransactionQty(
      transactionID: number,
      qtyReceived: number,
   ): Promise<AxiosResponse | undefined> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPRTransactionQty",
         },
         data: {
            transactionID: transactionID,
            qtyReceived: qtyReceived,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const transaction = this.getBillTransaction(transactionID);
      if (!transaction) return undefined;
      transaction.qtyReceived = qtyReceived ?? 0;

      const poItemID = transaction.poItemID;
      if (!poItemID) return undefined;
      const poID = this.getPurchaseOrderItem(poItemID)?.poID;

      if (poID) {
         const purchaseOrder = this.getPurchaseOrder(poID);
         if (purchaseOrder) {
            purchaseOrder.state = post.data.newState;
         }
      }

      //this is used so that we can update the extraBatch information so the data stays in sync
      const batch = post.data.extraBatch;
      if (batch) {
         let extraBatch = this.manageParts.getExtraBatch(batch.extraBatchID);

         //if the extraBatch doesn't exist we need to add it really quick.  This helps cover a bug where the extraBatch wasn't included
         if (extraBatch === undefined) {
            this.manageParts.addExtraBatchToLookup(batch);
            extraBatch = this.manageParts.getExtraBatch(batch.extraBatchID);
         } else {
            //extraBatch already exists so let's update it
            extraBatch.partQty = batch.partQty;
            extraBatch.partQtyUsed = batch.partQtyUsed;
            extraBatch.partPrice = batch.partPrice;
         }

         if (extraBatch) {
            const part = this.manageParts.getPart(extraBatch.partID);

            if (part) {
               this.manageParts.calculatePartData(part);
            }
         }
      }

      this.incOpenPurchaseOrderWatchVar();
      this.incOpenBillWatchVar();

      return post;
   }

   public async setPurchaseOrderItemDescription(
      poItemID: number,
      description: string,
   ): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOItemDescription",
         },
         data: {
            poItemID: poItemID,
            description: description,
         },
      });

      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
      if (post.data.success !== true || !purchaseOrderItem) {
         return false;
      }

      purchaseOrderItem.description = description;

      this.updateSignaler.trigger();
      this.incOpenBillWatchVar();

      return true;
   }

   public async setPurchaseOrderItemPartServiceDescription(
      poItemID: number,
      type: number,
      partID: number,
      assetID: number,
      checklistID: number,
      description: string,
      vendorID: number | null,
      qty: number,
      rate: number,
      glID: number | null,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOItemPartServiceDescription",
         },
         data: {
            poItemID: poItemID,
            type: type,
            partID: partID,
            assetID: assetID,
            checklistID: checklistID,
            description: description,
            vendorID: vendorID,
            qty: qty,
            rate: rate,
            glID: glID,
         },
      });

      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);

      if (post.data.success !== true || purchaseOrderItem === undefined) {
         return post;
      }
      purchaseOrderItem.itemType = type;
      purchaseOrderItem.partID = partID;
      purchaseOrderItem.assetID = assetID;
      purchaseOrderItem.checklistID = checklistID;
      purchaseOrderItem.prodServDescription = description;
      purchaseOrderItem.rate = Number(post.data.rate);
      purchaseOrderItem.qty = Number(post.data.qty);
      purchaseOrderItem.glID = post.data.glID;

      //clean up any invoices
      const invoices = this.manageInvoice.getInvoices();
      let taskIDToClean = 0;
      for (const key in invoices) {
         if (invoices[key].poItemID == purchaseOrderItem.poItemID) {
            taskIDToClean = invoices[key].checklistID;
            invoices.splice(key, 1);
         }
      }
      if (taskIDToClean > 0) {
         this.manageTask.getTask(taskIDToClean).then((task) => {
            if (task === undefined) return;
            for (const [index2, invoiceID] of task.invoiceIDs.entries()) {
               const invoice = this.manageInvoice.getInvoicesIndex()[invoiceID];
               if (invoice?.poItemID === poItemID) {
                  task.invoiceIDs.splice(index2, 1);
                  break;
               }
            }
         });
      }

      const invoice = post.data.invoice;
      if (invoice) {
         //if item type is 2 then we remade the invoice
         invoice.invoiceCost = Number(invoice.invoiceCost);

         invoices.push(invoice);
         const newIndex = invoices.length - 1;
         this.manageInvoice.getInvoicesIndex()[invoice.invoiceID] = invoices[newIndex];
         this.manageTask.getTask(checklistID).then((task) => {
            if (task === undefined) return;
            task.invoiceIDs.push(invoices[newIndex].invoiceID);
         });
      }
      this.checkAndProcessNewPartsFromPOItemUpdate(
         post.data.partRelationsCleanup,
         post.data.partRelation,
         post.data.startedFromChecklistID,
      );

      this.incOpenBillWatchVar();
      this.updateSignaler.trigger();

      return post;
   }

   public async setPurchaseOrderItemQty(
      item: PurchaseOrderItem,
      poItemID: number,
      qty: number,
   ): Promise<AxiosResponse | undefined> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOItemQty",
         },
         data: {
            poItemID: poItemID,
            qty: qty,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      item.qty = qty;

      const purchaseOrder = this.getPurchaseOrder(item.poID);
      if (purchaseOrder) {
         purchaseOrder.state = post.data.newState;
      }

      this.recalcUsedPartsExtraBatchAndInvoiceData(post);

      this.updateSignaler.trigger();
      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return post;
   }

   public async setPOItemPurchasable(
      poItemID: number,
      purchasableID: number,
      partID: number,
   ): Promise<AxiosResponse | undefined> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOItemPurchasable",
         },
         data: {
            poItemID: poItemID,
            purchasableID: purchasableID,
            partID: partID,
         },
      });

      return post;
   }

   public async setPurchaseOrderItemTax(
      poItemID: number,
      tax: number,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOItemTax",
         },
         data: {
            poItemID: poItemID,
            tax: tax,
         },
      });

      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
      if (post.data.success !== true || purchaseOrderItem === undefined) {
         return post;
      }

      purchaseOrderItem.tax = tax;

      this.recalcUsedPartsExtraBatchAndInvoiceData(post);

      this.updateSignaler.trigger();
      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return post;
   }

   public async setPurchaseOrderItemDiscount(
      poItemID: number,
      discount: number,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOItemDiscount",
         },
         data: {
            poItemID: poItemID,
            discount: discount,
         },
      });

      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);

      if (post.data.success !== true || purchaseOrderItem === undefined) {
         return post;
      }

      purchaseOrderItem.discount = discount;
      this.recalcUsedPartsExtraBatchAndInvoiceData(post);

      this.updateSignaler.trigger();
      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return post;
   }

   public async setPurchaseOrderItemShipping(
      poItemID: number,
      shipping: number,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOItemShipping",
         },
         data: {
            poItemID: poItemID,
            shipping: shipping,
         },
      });

      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);

      if (post.data.success !== true || purchaseOrderItem === undefined) {
         return post;
      }

      purchaseOrderItem.shipping = shipping;
      this.recalcUsedPartsExtraBatchAndInvoiceData(post);

      this.updateSignaler.trigger();
      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return post;
   }

   public async setPurchaseOrderItemRate(
      poItemID: number,
      rate: number,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOItemRate",
         },
         data: {
            poItemID: poItemID,
            rate: rate,
         },
      });

      if (post.data.success !== true) {
         return post;
      }

      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
      if (!purchaseOrderItem) return post;

      purchaseOrderItem.rate = rate;

      this.recalcUsedPartsExtraBatchAndInvoiceData(post);

      this.updateSignaler.trigger();
      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return post;
   }

   /****************************************
    *@function cleanCommentTempFiles
    *@purpose removes files from the temp files when adding a comment/note
    *@name cleanCommentTempFiles
    *@param
    *@return
    ****************************************/
   cleanCommentTempFiles = async () => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "cleanCommentTempFiles",
         },
         data: {},
      });
      return post;
   };

   /****************************************
    *@function deleteTempFileFromAddComment
    *@purpose removes a file from the temp files when adding a comment/note
    *@name deleteTempFileFromAddComment
    *@param
    *@return
    ****************************************/
   public async deleteTempFileFromAddComment(fileName: string): Promise<AxiosResponse> {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deleteTempFileFromAddComment",
         },
         data: {
            fileName,
         },
      });

      return post;
   }

   public async addPurchaseOrderComment(
      comment: string,
      poID: number,
      autoGen: number,
      sendNotifications: boolean,
   ): Promise<undefined | AugmentedPurchasingComment> {
      const timestamp = Math.floor(Date.now() / 1000);
      const answer = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addPOComment",
         },
         data: {
            comment: comment,
            poID: poID,
            timestamp: timestamp,
            autoGen: autoGen,
            externalEmailAddress: "",
            sendNotifications: sendNotifications,
         },
      });
      const purchaseOrder = this.getPurchaseOrder(poID);
      if (
         answer.data.success !== true ||
         !answer.data.comment ||
         purchaseOrder === undefined
      ) {
         return undefined;
      }

      return this.buildPurchasingComment("po", purchaseOrder.poID, answer.data.comment);
   }

   /****************************************
    *@function deletePOComment
    *@purpose deletes a comment from a PO
    *@name deletePOComment
    *@param
    *@return
    ****************************************/
   deletePOComment = async (comment) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deletePOComment",
         },
         data: {
            commentID: comment.commentID,
         },
      });

      return post;
   };

   /****************************************
    *@function getWhoWillReceivePONotifications
    *@purpose
    *@name getWhoWillReceivePONotifications
    *@param
    *@return post
    ****************************************/
   getWhoWillReceivePONotifications = async (poID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "getWhoWillReceivePONotifications",
         },
         data: {
            poID: poID,
         },
      });

      return post;
   };

   /****************************************
    *@function addUserToPONotifications
    *@purpose This function is used to add a User to a PO's notification chain
    *@name addUserToPONotifications
    *@param
    *@return
    ****************************************/
   addUserToPONotifications = async (userID, poID, reason = "manually added") => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addUserToPONotifications",
         },
         data: {
            poID: poID,
            userID: userID,
            reason: reason,
         },
      });

      return post;
   };

   /****************************************
    *@function addEmailStrToPONotifications
    *@purpose This function is used to add a an email string to a PO's notification chain
    *@name addEmailStrToPONotifications
    *@param
    *@return
    ****************************************/
   addEmailStrToPONotifications = async (emailStr, poID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addEmailStrToPONotifications",
         },
         data: {
            poID: poID,
            emailStr: emailStr,
         },
      });

      return post;
   };

   /****************************************
    *@function removeUserFromPONotifications
    *@purpose This function is used to remove a user from a PO's notification chain
    *@name removeUserFromPONotifications
    *@param
    *@return
    ****************************************/
   removeUserFromPONotifications = async (userID, poID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "removeUserFromPONotifications",
         },
         data: {
            userID: userID,
            poID: poID,
         },
      });

      return post;
   };

   /****************************************
    *@function removeManualPOCommentEmail
    *@purpose This function is used to remove an email from a PO's notification chain
    *@name removeManualPOCommentEmail
    *@param
    *@return
    ****************************************/
   removeManualPOCommentEmail = async (emailStr, poID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "removeManualPOCommentEmail",
         },
         data: {
            emailStr: emailStr,
            poID: poID,
         },
      });

      return post;
   };

   public async addBillComment(comment, prID: number, autoGen, sendNotifications) {
      const timestamp = Math.floor(Date.now() / 1000);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addPRComment",
         },
         data: {
            comment: comment,
            prID: prID,
            timestamp: timestamp,
            autoGen: autoGen,
            externalEmailAddress: "",
            sendNotifications: sendNotifications,
         },
      });

      return this.buildPurchasingComment("pr", prID, post.data.comment);
   }
   /****************************************
    *@function addUserToBillNotifications
    *@purpose This function is used to add a User to a Bill's notification chain
    *@name addUserToBillNotifications
    *@param
    *@return
    ****************************************/
   addUserToBillNotifications = async (userID, prID, reason = "manually added") => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addUserToPRNotifications",
         },
         data: {
            prID: prID,
            userID: userID,
            reason: reason,
         },
      });

      return post;
   };

   /****************************************
    *@function addEmailStrToBillNotifications
    *@purpose This function is used to add a an email string to a Bill's notification chain
    *@name addEmailStrToBillNotifications
    *@param
    *@return
    ****************************************/
   addEmailStrToBillNotifications = async (emailStr, prID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addEmailStrToPRNotifications",
         },
         data: {
            prID: prID,
            emailStr: emailStr,
         },
      });

      return post;
   };

   /****************************************
    *@function removeUserFromBillNotifications
    *@purpose This function is used to remove a user from a Bill's notification chain
    *@name removeUserFromBillNotifications
    *@param
    *@return
    ****************************************/
   removeUserFromBillNotifications = async (userID, prID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "removeUserFromPRNotifications",
         },
         data: {
            userID: userID,
            prID: prID,
         },
      });

      return post;
   };

   /****************************************
    *@function removeManualBillCommentEmail
    *@purpose This function is used to remove an email from a Bill's notification chain
    *@name removeManualBillCommentEmail
    *@param
    *@return
    ****************************************/
   removeManualBillCommentEmail = async (emailStr, prID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "removeManualPRCommentEmail",
         },
         data: {
            emailStr: emailStr,
            prID: prID,
         },
      });

      return post;
   };

   /****************************************
    *@function getWhoWillReceiveBillNotifications
    *@purpose This function is used to get a list of the users and emails that will be notified if a Bill receives a comment
    *@name getWhoWillReceiveBillNotifications
    *@param
    *@return post
    ****************************************/
   getWhoWillReceiveBillNotifications = async (prID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "getWhoWillReceivePRNotifications",
         },
         data: {
            prID: prID,
         },
      });

      return post;
   };

   public async deleteBillComment(comment) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deletePRComment",
         },
         data: {
            commentID: comment.commentID,
         },
      });

      return post.data.success;
   }

   public findPurchaseRequestsStartedFromTask(
      checklistID: number,
   ): Lookup<"poID", PurchaseOrder> {
      return this.purchaseOrders.filter(
         (purchaseOrder) =>
            purchaseOrder.requestedByUserID !== null &&
            purchaseOrder.requestedByUserID > 0 &&
            purchaseOrder.checklistID === checklistID,
      );
   }

   public async addBill(poID: number): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addPR",
         },
         data: {
            poID,
         },
      });

      if (post.data.success !== true) {
         return post;
      }

      if (post.data.pr) {
         const bill = post.data.pr;
         bill.transactionIDs = [];
         this.bills.set(bill.prID, bill);
      }

      this.incOpenPurchaseOrderWatchVar();
      this.incOpenBillWatchVar();

      return post;
   }

   public async deleteBill(prID: number): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deletePR",
         },
         data: {
            prID: prID,
         },
      });

      if (!post.data.success == true) return false;
      const poID = this.getBill(prID)?.poID;
      if (!poID) return false;
      const purchaseOrder = this.getPurchaseOrder(poID);
      if (!purchaseOrder) return false;

      const prIdIndex = purchaseOrder.prIDs.indexOf(prID);
      if (prIdIndex !== -1) {
         purchaseOrder.prIDs.splice(prIdIndex, 1);
      }

      const bill = this.getBill(prID);
      if (!bill) return false;
      for (const transactionID of bill.transactionIDs) {
         //cleanup the Bill transaction
         this.deleteLocalBillTransactionAndReferences(transactionID);
      }
      this.bills.delete(prID);

      //if the PO changed state we need to update it
      if (post.data.newState != false && purchaseOrder) {
         purchaseOrder.state = post.data.newState;
      }

      this.recalcUsedPartsExtraBatchAndInvoiceData(post);

      //redo the sorts now that it is done

      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return true;
   }

   public async submitBill(prID: number): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "submitPR",
         },
         data: {
            prID: prID,
         },
      });

      if (post.data.success !== true) {
         return post;
      }

      const poID = this.bills.get(prID)?.poID;
      if (!poID) {
         return post;
      }
      const purchaseOrder = this.purchaseOrders.get(poID);
      const bill = this.bills.get(prID);
      if (!purchaseOrder || !bill) {
         return post;
      }
      purchaseOrder.state = post.data.newState;
      bill.status = post.data.newStatus;

      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      if (post.data.JSreloadParts) {
         await this.manageParts.getData();
         this.incOpenBillWatchVar();
         this.incOpenPurchaseOrderWatchVar();
      }

      if (post.data.JSreloadAssets) {
         await this.manageAsset.getData();
         this.incOpenBillWatchVar();
         this.incOpenPurchaseOrderWatchVar();
      }

      if (post.data.JSreloadInvoices) {
         await this.manageInvoice.getData();
         await this.manageTask.getData();
         this.incOpenBillWatchVar();
         this.incOpenPurchaseOrderWatchVar();
      }

      return post;
   }

   public async markBillAsPaid(prID: number): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "markPRAsPaid",
         },
         data: {
            prID: prID,
         },
      });

      if (post.data.success !== true) {
         return post;
      }

      const poID = this.bills.get(prID)?.poID;
      if (!poID) {
         return post;
      }

      const purchaseOrder = this.purchaseOrders.get(poID);
      const bill = this.bills.get(prID);

      if (!purchaseOrder || !bill) {
         return post;
      }

      purchaseOrder.state = post.data.newState;
      bill.status = 2;

      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return post;
   }

   public async addBudgetWorkflowStep(
      budgetID: number | null,
      order: number,
   ): Promise<{ workflow?: BudgetWorkflow; success: boolean }> {
      if (budgetID === null) return { success: false };

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addBudgetWorkflowStep",
         },
         data: {
            budgetID: budgetID,
            order: order,
         },
      });

      if (!post.data.success || !post.data.workflow) return { success: false };

      this.budgetWorkflows.set(post.data.workflow.budgetWorkflowID, post.data.workflow);

      const budget = this.budgets.get(budgetID);
      if (!budget) return { success: false };
      budget.budgetWorkflowIDs.push(post.data.workflow.budgetWorkflowID);
      return { workflow: post.data.workflow, success: true };
   }

   public async deleteBudgetWorkflowStep(
      budgetID: number | null,
      budgetWorkflowID: number | null,
   ): Promise<boolean> {
      if (budgetWorkflowID === null || budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deleteBudgetWorkflowStep",
         },
         data: {
            budgetID: budgetID,
            budgetWorkflowID: budgetWorkflowID,
         },
      });
      if (!post.data.success) return false;
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      const budgetWorkflow = this.budgetWorkflows.get(budgetWorkflowID);
      const budgetWorkflowIDIndex = budget.budgetWorkflowIDs.indexOf(budgetWorkflowID);
      if (!budgetWorkflow || budgetWorkflowIDIndex < 0) return false;
      budget?.budgetWorkflowIDs.splice(budgetWorkflowIDIndex, 1);
      if (budgetWorkflow) {
         budgetWorkflow.deleted = 1;
      }

      return true;
   }

   public async reorderBudgetWorkflowSteps(
      budgetID: number | null,
      updates: Array<{ order: number | null; budgetWorkflowID: number }>,
   ): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "reorderBudgetWorkflowSteps",
         },
         data: {
            budgetID: budgetID,
            updates: updates,
         },
      });

      if (!post.data.success) return false;
      updates.forEach((update) => {
         const budgetWorkflow = this.budgetWorkflows.get(update.budgetWorkflowID);
         if (!budgetWorkflow) return;
         budgetWorkflow.order = update.order;
      });

      return true;
   }

   public async updateBudgetWorkflowName(name, budgetWorkflowID): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updateBudgetWorkflowName",
         },
         data: {
            name: name,
            budgetWorkflowID: budgetWorkflowID,
         },
      });

      if (!post.data.success) return false;
      const budgetWorkflow = this.budgetWorkflows.get(budgetWorkflowID);
      if (!budgetWorkflow) return false;
      budgetWorkflow.name = name;

      return true;
   }

   public async setPurchaseOrderWorkFlowStepAssignment(
      budgetWorkflowID: number | null,
      userID: number | null,
      profileID: number | null,
      multiUsers: Array<number>,
   ): Promise<boolean> {
      if (budgetWorkflowID === null) return false;
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOWorkFlowStepAssignment",
         },
         data: {
            budgetWorkflowID: budgetWorkflowID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringified,
         },
      });

      if (!post.data.success) return false;
      if (post.data.profileID > 0) {
         this.manageFilters.updateHiddenProfiles(
            {
               profileID: post.data.profileID,
               name: post.data.profileDescription,
               locationID: post.data.locationID,
               multiUsers,
            },
            this.manageTask,
            this.manageUser,
            this.manageProfile,
         );
      }
      const budgetWorkflow = this.budgetWorkflows.get(budgetWorkflowID);
      if (!budgetWorkflow) return false;
      budgetWorkflow.userID = post.data.userID;
      budgetWorkflow.profileID = post.data.profileID;

      return true;
   }

   public async setBudgetAwaitingAssignment(
      budgetID: number | null,
      userID: number | null,
      profileID: number | null,
      multiUsers: Array<number> | null,
   ): Promise<boolean> {
      const multiUsersStringified = JSON.stringify(multiUsers);
      if (budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setBudgetAwaitingAssignment",
         },
         data: {
            budgetID: budgetID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringified,
         },
      });
      if (!post.data.success) return false;
      if (post.data.profileID > 0) {
         this.manageFilters.updateHiddenProfiles(
            {
               profileID: post.data.profileID,
               name: post.data.profileDescription,
               locationID: post.data.locationID,
               multiUsers: multiUsers ?? [],
            },
            this.manageTask,
            this.manageUser,
            this.manageProfile,
         );
      }
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.awaitingUserID = post.data.userID;
      budget.awaitingProfileID = post.data.profileID;

      return true;
   }

   public async setBudgetBillAssignment(
      budgetID: number | null,
      userID: number | null,
      profileID: number | null,
      multiUsers: Array<number> | null,
   ): Promise<boolean> {
      if (budgetID === null) return false;
      const multiUsersStringified = JSON.stringify(multiUsers);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setBudgetPRAssignment",
         },
         data: {
            budgetID: budgetID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringified,
         },
      });

      if (!post.data.success) return false;
      if (post.data.profileID > 0) {
         this.manageFilters.updateHiddenProfiles(
            {
               profileID: post.data.profileID,
               name: post.data.profileDescription,
               locationID: post.data.locationID,
               multiUsers: multiUsers ?? [],
            },
            this.manageTask,
            this.manageUser,
            this.manageProfile,
         );
      }
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.prUserID = post.data.userID;
      budget.prProfileID = post.data.profileID;

      return true;
   }

   public async setBudgetWorkflowCheckbox(
      budgetWorkflowID: number,
      field: keyof BudgetWorkflow,
      newValue,
   ): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setBudgetWorkflowCheckbox",
         },
         data: {
            budgetWorkflowID: budgetWorkflowID,
            field: field,
            newValue: newValue,
         },
      });

      if (!post.data.success) return false;
      const budgetWorkflow = this.budgetWorkflows.get(budgetWorkflowID);
      if (!budgetWorkflow) return false;
      budgetWorkflow[field] = newValue as never;

      return true;
   }

   public async setAwaitingEmailSend(
      budgetID: number | null,
      newValue: number | null,
   ): Promise<boolean> {
      if (budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setAwaitingEmailSend",
         },
         data: {
            budgetID: budgetID,
            newValue: newValue,
         },
      });

      if (!post.data.success) return false;
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.awaitingEmailSend = newValue;

      return true;
   }

   public async setBillEmailSend(
      budgetID: number | null,
      newValue: number | null,
   ): Promise<boolean> {
      if (budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPREmailSend",
         },
         data: {
            budgetID: budgetID,
            newValue: newValue,
         },
      });

      if (!post.data.success) return false;
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.prEmailSend = newValue;

      return true;
   }

   public async setBudgetAwaitingPurchaseOrderCanBeEdited(
      budgetID: number | null,
      newValue: number | null,
   ): Promise<boolean> {
      if (budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setBudgetAwaitingPOCanBeEdited",
         },
         data: {
            budgetID: budgetID,
            newValue: newValue,
         },
      });

      if (!post.data.success) return false;
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.awaitingAllowPOEdit = newValue;

      return true;
   }

   public async updateBudgetWorkflowStepEmail(
      budgetWorkflowID: number | null,
      to: string,
      subject: string,
      message: string,
   ): Promise<boolean> {
      if (budgetWorkflowID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updateBudgetWorkflowStepEmail",
         },
         data: {
            budgetWorkflowID: budgetWorkflowID,
            to: to,
            subject: subject,
            message: message,
         },
      });

      if (!post.data.success) return false;
      const workflow = this.budgetWorkflows.get(budgetWorkflowID);
      if (!workflow) return false;
      workflow.emailTo = to;
      workflow.emailSubject = subject;
      workflow.emailBody = message;
      workflow.emailSend = 1;

      return true;
   }

   public async updateBudgetAwaitingEmail(
      budgetID: number | null,
      to: string,
      subject: string,
      message: string,
   ): Promise<boolean> {
      if (budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updateBudgetAwaitingEmail",
         },
         data: {
            budgetID: budgetID,
            to: to,
            subject: subject,
            message: message,
         },
      });

      if (!post.data.success) return false;
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.awaitingEmailTo = to;
      budget.awaitingEmailSubject = subject;
      budget.awaitingEmailBody = message;
      budget.awaitingEmailSend = 1;

      return true;
   }

   public async updateBudgetBillEmail(
      budgetID: number | null,
      to: string,
      subject: string,
      message: string,
   ): Promise<boolean> {
      if (budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updateBudgetPREmail",
         },
         data: {
            budgetID: budgetID,
            to: to,
            subject: subject,
            message: message,
         },
      });

      if (!post.data.success) return false;
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.prEmailTo = to;
      budget.prEmailSubject = subject;
      budget.prEmailBody = message;
      budget.prEmailSend = 1;

      return true;
   }

   /****************************************
    *@function prepExternalPOWorkflowStep
    *@purpose
    *@name prepExternalPOWorkflowStep
    *@param
    *@return
    ****************************************/
   prepExternalPOWorkflowStep = async (hash, direction, poWorkflowID, poID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "prepExternalPOWorkflowStep",
         },
         data: {
            hash: hash,
            direction: direction,
            poWorkflowID: poWorkflowID,
            poID: poID,
         },
      });

      return post;
   };

   /****************************************
    *@function prepExternalWorkflowBill
    *@purpose
    *@name prepExternalWorkflowBill
    *@param
    *@return
    ****************************************/
   prepExternalWorkflowBill = async (hash, prID, code) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "prepExternalWorkflowPR",
         },
         data: {
            hash: hash,
            prID: prID,
            code: code,
         },
      });

      return post;
   };

   public async approvePurchaseOrderWorkflowStep(
      code: string,
      poID: number,
      poWorkflowID: number | undefined,
      externalEmailAddress: string,
   ): Promise<AxiosResponse> {
      const answer = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "approvePOWorkflowStep",
         },
         data: {
            code: code,
            poID: poID,
            poWorkflowID: poWorkflowID,
            externalEmailAddress: externalEmailAddress,
         },
      });

      const purchaseOrder = this.getPurchaseOrder(poID);
      if (!purchaseOrder) return answer;

      // Check to see if the userID/profileID was changed (if the budget re-assigns the PO based on the current step)
      if (answer.data.userID > 0 && purchaseOrder.userID !== answer.data.userID) {
         purchaseOrder.profileID = 0;
         purchaseOrder.userID = answer.data.userID;
      } else if (
         answer.data.profileID > 0 &&
         purchaseOrder.profileID != answer.data.profileID
      ) {
         purchaseOrder.userID = 0;
         purchaseOrder.profileID = answer.data.profileID;
      }

      if (answer.data.success !== true) {
         return answer;
      }
      purchaseOrder.state = answer.data.newState;

      this.incOpenPurchaseOrderWatchVar();
      return answer;
   }

   public async disapprovePurchaseOrderWorkflowStep(
      code: string,
      newState: number,
      poID: number,
      reason: string,
      externalEmailAddress: string,
   ): Promise<AxiosResponse> {
      const answer = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "disapprovePOWorkflowStep",
         },
         data: {
            code: code,
            newState: newState,
            poID: poID,
            reason: reason,
            externalEmailAddress: externalEmailAddress,
         },
      });

      const purchaseOrder = this.getPurchaseOrder(poID);

      if (answer.data.success !== true || purchaseOrder === undefined) {
         return answer;
      }

      purchaseOrder.state = Number(answer.data.newState);

      this.incOpenPurchaseOrderWatchVar();

      return answer;
   }

   /****************************************
    *@function getPOStepsPerformed
    *@purpose
    *@name getPOStepsPerformed
    *@param
    *@return
    ****************************************/
   getPOStepsPerformed = async (code, poID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "getPOStepsPerformed",
         },
         data: {
            code: code,
            poID: poID,
         },
      });

      return post;
   };

   public async changeBillAssignment(
      userID: number,
      profileID: number,
      prID: number,
      multiUsers: Array<number>,
      sendNotifications: boolean,
   ): Promise<boolean> {
      const multiUsersStringified = JSON.stringify(multiUsers);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "changePRAssignment",
         },
         data: {
            prID: prID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringified,
            sendNotifications: sendNotifications,
         },
      });

      if (post.data.profileID > 0) {
         this.manageFilters.updateHiddenProfiles(
            {
               profileID: post.data.profileID,
               name: post.data.profileDescription,
               locationID: post.data.locationID,
               multiUsers,
            },
            this.manageTask,
            this.manageUser,
            this.manageProfile,
         );
      }

      const bill = this.getBill(prID);
      if (!bill) return false;
      bill.userID = post.data.userID;
      bill.profileID = post.data.profileID;

      this.incOpenBillWatchVar();

      return true;
   }

   public async changePurchaseOrderAssignment(
      userID: number | undefined,
      profileID: number | undefined,
      poID: number,
      multiUsers: Array<number> | undefined,
      sendNotifications: boolean,
   ): Promise<boolean> {
      const multiUsersStringified = JSON.stringify(multiUsers);
      const answer = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "changePOAssignment",
         },
         data: {
            poID: poID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringified,
            sendNotifications: sendNotifications,
         },
      });
      if (!answer.data.success) return false;

      if (answer.data.profileID > 0) {
         this.manageFilters.updateHiddenProfiles(
            {
               profileID: answer.data.profileID,
               name: answer.data.profileDescription,
               locationID: answer.data.locationID,
               multiUsers: multiUsers ?? [],
            },
            this.manageTask,
            this.manageUser,
            this.manageProfile,
         );
      }
      const purchaseOrder = this.getPurchaseOrder(poID);
      if (!purchaseOrder) return false;

      purchaseOrder.userID = answer.data.userID;
      purchaseOrder.profileID = answer.data.profileID;

      this.incOpenPurchaseOrderWatchVar();

      return true;
   }

   /****************************************
    *@function disapproveBill
    *@purpose
    *@name disapproveBill
    *@param
    *@return
    ****************************************/
   disapproveBill = async (prID, reason) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "disapprovePR",
         },
         data: {
            prID: prID,
            reason: reason,
         },
      });

      post.then(() => {
         this.incOpenBillWatchVar();
      });

      return post;
   };

   /****************************************
    *@function updatePOCustomField
    *@purpose
    *@name updatePOCustomField
    *@param
    *@return
    ****************************************/
   updatePOCustomField = async (poID, field, value) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePOCustomField",
         },
         data: {
            poID: poID,
            field: field,
            value: value,
         },
      });

      post.then(() => {
         this.incOpenBillWatchVar();
      });

      return post;
   };

   /****************************************
    *@function removePurchaseOrderCustomField
    *@purpose
    *@name removePurchaseOrderCustomField
    *@param
    *@return
    ****************************************/
   public async removePurchaseOrderCustomField(locationID: number, field: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "removePOCustomField",
         },
         data: {
            locationID: locationID,
            field: field,
         },
      });

      post.then(() => {
         this.incOpenBillWatchVar();
         this.incOpenPurchaseOrderWatchVar();
      });

      return post;
   }

   /****************************************
    *@function changePOCustomFieldSettings
    *@purpose
    *@name changePOCustomFieldSettings
    *@param
    *@return
    ****************************************/
   changePOCustomFieldSettings = async (name, locationID, field, vendorFieldID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "changePOCustomFieldSettings",
         },
         data: {
            name: name,
            locationID: locationID,
            field: field,
            vendorFieldID,
         },
      });

      post.then(() => {
         this.incOpenBillWatchVar();
         this.incOpenPurchaseOrderWatchVar();
      });

      return post;
   };

   public async updatePurchaseOrderBillTo(
      poID: number,
      billTo: string,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePOBillTo",
         },
         data: {
            poID: poID,
            billTo: billTo,
         },
      });

      this.incOpenBillWatchVar();

      return post;
   }

   public async updatePurchaseOrderShipTo(
      poID: number,
      shipTo: string,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePOShipTo",
         },
         data: {
            poID: poID,
            shipTo: shipTo,
         },
      });

      this.incOpenBillWatchVar();

      return post;
   }

   public async setupGeneralLedger(
      glID: number,
      name: string,
      abbr: string,
      locationID: number,
      glDescription: string,
      assetID: number,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setupGeneralLedger",
         },
         data: {
            glID: glID,
            name: name,
            abbr: abbr,
            locationID: locationID,
            glDescription: glDescription,
            assetID: assetID,
         },
      });

      if (glID !== 0) {
         const generalLedger = this.getGeneralLedger(glID);
         if (!generalLedger) {
            return post;
         }

         Object.assign(generalLedger, { name, abbr, glDescription, assetID });
      }

      const newGeneralLedger = post.data.generalLedger;
      if (newGeneralLedger) {
         this.generalLedgers.set(newGeneralLedger.glID, newGeneralLedger);
      }

      this.updateSignaler.trigger();
      this.incGeneralLedgerWatchVar();

      return post;
   }

   /****************************************
    *@function updatePurchaseRequestAssignment
    *@purpose
    *@name updatePurchaseRequestAssignment
    *@param
    *@return
    ****************************************/
   updatePurchaseRequestAssignment = async (
      locationID,
      profileID,
      userID,
      multiUsers,
   ) => {
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePurchaseRequestAssignment",
         },
         data: {
            locationID: locationID,
            profileID: profileID,
            userID: userID,
            multiUsers: multiUsersStringified,
         },
      });

      return post;
   };

   public async disapprovePurchaseRequest(
      poID: number,
      reason: string,
   ): Promise<AxiosResponse> {
      const answer = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "disapprovePurchaseRequest",
         },
         data: {
            poID: poID,
            reason: reason,
         },
      });

      const purchaseOrder = this.getPurchaseOrder(poID);

      if (answer.data.success !== true || purchaseOrder === undefined) {
         return answer;
      }
      purchaseOrder.state = 101; //set it to closed - disapproved purchase request

      //we need to clean up any poItems that are a Service or an Other type
      for (const purchaseOrderItem of this.purchaseOrderItems) {
         if (
            purchaseOrderItem.poID === purchaseOrder.poID &&
            (purchaseOrderItem.itemType === 2 || purchaseOrderItem.itemType === 4)
         ) {
            this.removeInvoicesAndPartsWhenPurchaseOrderItemDeleted(
               purchaseOrderItem.poItemID,
            );
         }
      }

      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return answer;
   }

   public async reopenPurchaseRequest(
      poID: number,
      reason: string,
   ): Promise<AxiosResponse> {
      const answer = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "reopenPurchaseRequest",
         },
         data: {
            poID: poID,
            reason: reason,
         },
      });

      const purchaseOrder = this.getPurchaseOrder(poID);

      if (answer.data.success !== true || purchaseOrder === undefined) {
         return answer;
      }
      purchaseOrder.state = 0; //set it to open since it should be re evaluated

      const invoices = this.manageInvoice.getInvoices();
      for (const invoice of answer.data.invoicesToAdd) {
         //if item type is 2 then we remade the invoice
         invoice.invoiceCost = Number(invoice.invoiceCost);

         invoices.push(invoice);
         const newIndex = invoices.length - 1;
         this.manageInvoice.getInvoicesIndex()[invoice.invoiceID] = invoices[newIndex];

         this.manageTask.getTask(invoice.checklistID).then((task) => {
            if (task === undefined) return;
            task.invoiceIDs.push(invoices[newIndex].invoiceID);
         });
      }

      this.incOpenBillWatchVar();
      this.incOpenPurchaseOrderWatchVar();

      return answer;
   }

   public async setGeneralLedger(glID: number, poItemID: number): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setGeneralLedger",
         },
         data: {
            glID: glID,
            poItemID: poItemID,
         },
      });

      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);

      if (post.data.success !== true || purchaseOrderItem === undefined) {
         return post;
      }

      purchaseOrderItem.glID = glID;

      this.updateSignaler.trigger();
      this.incOpenBillWatchVar();
      this.incGeneralLedgerWatchVar();

      return post;
   }

   public async deleteGeneralLedger(glID: number): Promise<AxiosResponse | undefined> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deleteGeneralLedger",
         },
         data: {
            glID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      this.generalLedgers.delete(glID);

      for (const item of this.purchaseOrderItems) {
         const poItem = this.getPurchaseOrderItem(item.poItemID);
         if (poItem?.glID === glID) {
            poItem.glID = 0;
         }
      }

      this.incGeneralLedgerWatchVar();

      return post;
   }

   public async setDefaultBudget(
      budgetID: number,
      type: DefaultBudgetType,
      locationID: number | null,
   ): Promise<boolean> {
      if (locationID === null) return false;

      const response = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setDefaultBudget",
         },
         data: {
            budgetID,
            type,
            locationID,
         },
      });

      if (!response.data.success) return false;

      const location = this.manageLocation.getLocation(locationID);
      assert(location !== undefined);

      switch (type) {
         case DefaultBudgetType.Standard:
            location.defaultBudgetID = budgetID;
            break;
         case DefaultBudgetType.PurchaseRequest:
            location.defaultBudgetIDRequestPurchase = budgetID;
            break;
         case DefaultBudgetType.MinimumPartQuantityPurchaseOrder:
            location.defaultBudgetIDMinPart = budgetID;
            break;
         default:
            throw new Error("Invalid DefaultBudgetType");
      }

      this.incBudgetWatchVar();
      return true;
   }

   public async addBudget(locationID: number): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "addBudget",
         },
         data: {
            locationID: locationID,
         },
      });

      if (post.data.success !== true) {
         return post;
      }
      this.budgets.set(post.data.budget.budgetID, {
         ...post.data.budget,
         budgetWorkflowIDs: [],
      });

      this.incBudgetWatchVar();

      return post;
   }

   public async deleteBudget(budgetID: number | null): Promise<boolean> {
      if (budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "deleteBudget",
         },
         data: {
            budgetID: budgetID,
         },
      });

      if (!post.data.success) return false;
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.budgetDeleted = 1;
      this.incBudgetWatchVar();

      return true;
   }

   public async updateBudgetName(
      budgetID: number | null,
      name: string | null,
   ): Promise<boolean> {
      if (budgetID === null) return false;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updateBudgetName",
         },
         data: {
            budgetID: budgetID,
            name: name,
         },
      });

      if (!post.data.success) return false;
      const budget = this.budgets.get(budgetID);
      if (!budget) return false;
      budget.name = name;
      this.incBudgetWatchVar();

      return true;
   }

   /****************************************
          *@function sendPOToVendor
          *@purpose sends an email with the PO automatically
          attached to the vendor
          *@name sendPOToVendor
          *@param
          *@return
          ****************************************/
   sendPOToVendor = async (poID, emailTo, emailSubject, emailMessage) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "sendPOToVendor",
         },
         data: {
            poID: poID,
            emailTo: emailTo,
            emailSubject: emailSubject,
            emailMessage: emailMessage,
         },
      });

      return post;
   };

   public async updateDisableBillsAndBillEmailSend(
      disableBills: 0 | 1,
      locationID: number,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updateDisableBillsAndPrEmailSend",
         },
         data: {
            disableBills,
            locationID,
         },
      });

      if (!post.data.success) {
         return post;
      }

      const prEmailSend = disableBills === 0 ? 1 : 0;
      const locationBudgets = this.getBudgets().filter(
         (budget) => budget.budgetDeleted === 0 && budget.locationID === locationID,
      );

      for (const budget of locationBudgets) {
         budget.prEmailSend = prEmailSend;
      }

      return post;
   }

   public async updateLocationDefaultTaxRate(
      locationID: number,
      defaultPOTaxRate: number,
   ): Promise<AxiosResponse> {
      const patch = await this.axios({
         method: "PATCH",
         url: `${environment.flannelUrl}/locations`,
         params: {
            locationID,
         },
         data: {
            defaultPOTaxRate,
         },
      });

      if (patch.status !== 200) {
         return patch;
      }

      const location = this.manageLocation.getLocationsIndex()[locationID];
      location.defaultPOTaxRate = defaultPOTaxRate;

      return patch;
   }

   /****************************************
    *@function updatePOPrefix
    *@purpose
    *@name updatePOPrefix
    *@param
    *@return
    ****************************************/
   updatePOPrefix = async (poPrefix, locationID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePOPrefix",
         },
         data: {
            poPrefix: poPrefix,
            locationID: locationID,
         },
      });

      return post;
   };

   /****************************************
    *@function updatePOFooterText
    *@purpose
    *@name updatePOFooterText
    *@param
    *@return
    ****************************************/
   updatePOFooterText = async (poFooterText, locationID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePOFooterText",
         },
         data: {
            poFooterText: poFooterText,
            locationID: locationID,
         },
      });

      return post;
   };

   /****************************************
    *@function moveCommentTempFile
    *@purpose
    *@name moveCommentTempFile
    *@param
    *@return
    ****************************************/
   moveCommentTempFile = async (poID, file) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "moveCommentTempFile",
         },
         data: {
            poID: poID,
            file: file,
         },
      });

      return post;
   };

   /****************************************
    *@function updatePONumber
    *@purpose
    *@name updatePONumber
    *@param
    *@return
    ****************************************/
   updatePONumber = async (poID, poNumber) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updatePONumber",
         },
         data: {
            poID: poID,
            poNumber: poNumber,
         },
      });

      return post;
   };

   /*****************************************
    *@function setPOEmailDefaults
    *@purpose
    *@name setPOEmailDefaults
    *@param
    *@return
    ****************************************/
   setPOEmailDefaults = async (locationID, emailRecips, emailSubject, emailMessage) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "setPOEmailDefaults",
         },
         data: {
            locationID: locationID,
            emailRecips: emailRecips,
            emailSubject: emailSubject,
            emailMessage: emailMessage,
         },
      });

      return post;
   };

   public exportPurchaseOrders(
      purchaseOrders: Lookup<"poID", PurchaseOrder>,
      location,
   ): void {
      const POsToDownload: any = [];

      for (const purchaseOrder of purchaseOrders) {
         const vendor = purchaseOrder.vendorID
            ? this.manageVendor.getVendor(purchaseOrder.vendorID)
            : undefined;
         const budget = purchaseOrder.budgetID
            ? this.getBudget(purchaseOrder.budgetID)
            : undefined;
         const currentState = this.getPurchaseOrderCurrentState(purchaseOrder.poID);
         const deliveryDate = this.calculatePurchaseOrderDeliveryDate(purchaseOrder.poID);
         const totals = this.getPurchaseOrderCostTotals(purchaseOrder.poID);

         const obj = {
            "PO ID": purchaseOrder.poID,
            "PO Number": this.getPurchaseOrderNumberForDisplay(purchaseOrder.poID)
               ?.poNumberForDisplay,
            "Vendor Name": vendor?.vendorName,
            "PO Date": purchaseOrder.date
               ? this.betterDate.formatBetterDate(purchaseOrder.date * 1000, "date")
               : undefined,
            "Bill To": purchaseOrder.billTo,
            "Ship To": purchaseOrder.shipTo,
            "Budget": budget?.name,
            [location?.poField1Name ?? "Field 1"]: purchaseOrder.field1,
            [location?.poField2Name ?? "Field 2"]: purchaseOrder.field2,
            [location?.poField3Name ?? "Field 3"]: purchaseOrder.field3,
            [location?.poField4Name ?? "Field 4"]: purchaseOrder.field4,
            [location?.poField5Name ?? "Field 5"]: purchaseOrder.field5,
            [location?.poField6Name ?? "Field 6"]: purchaseOrder.field6,
            "Notes": purchaseOrder.notesToVendor,
            "Phase": currentState?.name,
            "Delivery Date": deliveryDate
               ? this.betterDate.formatBetterDate(deliveryDate * 1000, "date")
               : undefined,
            "Tax": totals?.tax,
            "Discount": totals?.discount,
            "Shipping": totals?.shipping,
            "Subtotal": totals?.subtotal,
            "Total": totals?.total,
         };

         if (purchaseOrder.expectedDate && purchaseOrder.expectedDate > 0) {
            obj["Expected Delivery Date"] = this.betterDate.formatBetterDate(
               purchaseOrder.expectedDate * 1000,
               "date",
            );
         } else {
            obj["Expected Delivery Date"] = null;
         }

         const poItems: any = [];
         for (const poItemID of purchaseOrder.poItemIDs) {
            const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
            if (purchaseOrderItem?.itemType && purchaseOrderItem.itemType > 0) {
               const itemInfo = this.getPurchaseOrderItemRelatedInfo(
                  purchaseOrderItem.poItemID,
               );
               const qtyWithName = `${purchaseOrderItem.qty}, ${itemInfo?.itemSearchStr}`;
               poItems.push(qtyWithName);
            }
         }

         obj["Qty/PO Items"] = poItems.join(" | ");

         POsToDownload.push(obj);
      }

      const today = this.betterDate.createTodayTimestamp();

      this.manageUtil.objToExcel(POsToDownload, "POs", `POs-${today}.xlsx`);
   }

   public exportPurchaseOrderItems(
      purchaseOrders: Lookup<"poID", PurchaseOrder>,
      purchaseOrderItems?: Lookup<"poItemID", PurchaseOrderItem>,
   ): void {
      const POItemsToDownload: any = [];

      let POItemsObj: any = null;

      if (purchaseOrderItems && purchaseOrderItems.size > 0) {
         POItemsObj = {};
         for (const item of purchaseOrderItems) {
            POItemsObj[item.poItemID] = true;
         }
      }

      for (const purchaseOrder of purchaseOrders) {
         const vendor = purchaseOrder.vendorID
            ? this.manageVendor.getVendor(purchaseOrder.vendorID)
            : undefined;
         const budget = purchaseOrder.budgetID
            ? this.getBudget(purchaseOrder.budgetID)
            : undefined;
         for (const poItemID of purchaseOrder.poItemIDs) {
            //If there are purchaseOrderItems passed in, there is a chance that they have been modified
            //and will display slightly different data than the single-source-of-truth purchaseOrderItems
            //that live in managePO.  That means we want to use them specifically, not the ones in managePO
            // JIRA ticket LIM-7766
            const purchaseOrderItem = purchaseOrderItems
               ? purchaseOrderItems.get(poItemID)
               : this.getPurchaseOrderItem(poItemID);
            if (!purchaseOrderItem) continue;
            const itemInfo = this.getPurchaseOrderItemRelatedInfo(poItemID);

            //The function call below is used instead of calculatePurchaseOrderItemTotals and
            //getPurchaseOrderItemReceivedInfo because of how we're manipulating the list of
            //transactionIDs in a certain case in manageFilters.  | JIRA ticket LIM-7766
            const { total, receivedQty } =
               this.calculatePurchaseOrderItemDataBasedOnTransactions(purchaseOrderItem);
            const generalLedger = purchaseOrderItem.glID
               ? this.getGeneralLedger(purchaseOrderItem.glID)
               : undefined;
            const generalLedgerAsset = generalLedger?.assetID
               ? this.manageAsset.getAsset(generalLedger?.assetID)
               : undefined;
            if (
               purchaseOrderItem.itemType &&
               purchaseOrderItem.itemType > 0 &&
               (!POItemsObj || POItemsObj[purchaseOrderItem.poItemID])
            ) {
               const poItemReceivedDates = purchaseOrderItem.transactionIDs.map(
                  (transactionID) => {
                     const transaction = this.getBillTransaction(transactionID);
                     if (!transaction) return "";
                     const dateReceived = transaction.dateReceived
                        ? this.betterDate.formatBetterDate(
                             transaction.dateReceived * 1000,
                             "date",
                          )
                        : "";
                     return `${transaction.qtyReceived} on ${dateReceived}`;
                  },
               );

               POItemsToDownload.push({
                  "PO ID": purchaseOrderItem.poID,
                  "PO Number": this.getPurchaseOrderNumberForDisplay(purchaseOrder.poID)
                     ?.poNumberForDisplay,
                  "Item Name": itemInfo?.itemSearchStr,
                  "Description": purchaseOrderItem.description,
                  "Qty": purchaseOrderItem.qty,
                  "Rate": purchaseOrderItem.rate,
                  "Tax": purchaseOrderItem.tax,
                  "Discount": purchaseOrderItem.discount,
                  "Shipping": purchaseOrderItem.shipping,
                  "Total": total,
                  "Vendor Name": vendor?.vendorName,
                  "Received": receivedQty,
                  "GL Abbreviation": generalLedger?.abbr,
                  "GL Name": generalLedger?.name,
                  "GL Description": generalLedger?.glDescription,
                  "GL Asset": generalLedgerAsset?.assetName,
                  "Budget": budget?.name,
                  "PO Date": purchaseOrder.date
                     ? this.betterDate.formatBetterDate(purchaseOrder.date * 1000, "date")
                     : undefined,
                  "PO Item Received Dates": poItemReceivedDates.join(", "),
               });
            }
         }
      }

      const today = this.betterDate.createTodayTimestamp();
      this.manageUtil.objToExcel(POItemsToDownload, "PO Items", `POItems-${today}.xlsx`);
   }

   public exportBills(
      bills: Lookup<
         "prID",
         Bill & {
            total: number | undefined;
            vendorNameStr: string;
            displayName: string | undefined;
         }
      >,
   ): void {
      const PRsToDownload: any = [];

      for (const bill of bills) {
         if (!bill?.poID) continue;
         const purchaseOrder = this.getPurchaseOrder(bill.poID);
         if (!purchaseOrder?.budgetID) continue;
         const budget = this.getBudget(purchaseOrder.budgetID);
         const phase = this.getBillStatusAndReceivableInfo(bill.prID);
         const obj = {
            "Bill ID": bill.prID,
            "Bill Number": bill.prNumber,
            "Vendor Name": bill.vendorNameStr,
            "Bill Date": bill.date
               ? this.betterDate.formatBetterDate(bill.date * 1000, "date")
               : "",
            "Budget": budget?.name,
            "Phase": phase?.currentStatus.name,
            "Total": bill.total,
            "PO Number": purchaseOrder.poNumber,
         };
         const prItems: any = [];
         if (bill.transactionIDs.length > 0) {
            let deliveryDateTemp = 0;
            for (const transactionID of bill.transactionIDs) {
               // Get the Bill Items
               const transaction = this.getBillTransaction(transactionID);
               if (!transaction?.poItemID) continue;
               const itemInfo = this.getPurchaseOrderItemRelatedInfo(
                  transaction.poItemID,
               );
               const strippedName = this.manageUtil.stripTags(itemInfo?.itemName ?? "");
               const qtyWithName = `${transaction.qtyReceived}, ${strippedName}`;
               prItems.push(qtyWithName);

               // Get the latest delivery date
               if ((transaction.dateReceived ?? 0) > deliveryDateTemp) {
                  deliveryDateTemp = transaction.dateReceived ?? 0;
                  obj["Delivery Date"] = this.betterDate.formatBetterDate(
                     deliveryDateTemp * 1000,
                     "date",
                  );
               }
            }
         }
         obj["Qty/Bill Items"] = prItems.join(" | ");
         PRsToDownload.push(obj);
      }

      const today = this.betterDate.createTodayTimestamp();

      this.manageUtil.objToExcel(PRsToDownload, "Bills", `Bills-${today}.xlsx`);
   }

   public exportBillItems(
      bills: Lookup<
         "prID",
         Bill & {
            total: number | undefined;
            vendorNameStr: string;
            displayName: string | undefined;
         }
      >,
   ): void {
      const PRItemsToDownload: any = [];

      for (const bill of bills) {
         if (!bill?.poID) continue;
         const purchaseOrder = this.getPurchaseOrder(bill.poID);
         for (const transactionID of bill.transactionIDs) {
            const transaction = this.getBillTransaction(transactionID);
            if (!transaction?.poItemID) continue;
            const purchaseOrderItem = this.getPurchaseOrderItem(transaction.poItemID);
            const itemInfo = this.getPurchaseOrderItemRelatedInfo(transaction.poItemID);
            const total = this.calculatePurchaseOrderItemTotal(transaction.poItemID);
            PRItemsToDownload.push({
               "Bill ID": bill.prID,
               "Bill Number": bill.prNumber,
               "Item Name": this.manageUtil.stripTags(itemInfo?.itemName ?? ""),
               "Description": purchaseOrderItem?.description,
               "Qty Received": transaction.qtyReceived,
               "Rate": purchaseOrderItem?.rate,
               "Tax": purchaseOrderItem?.tax,
               "Discount": purchaseOrderItem?.discount,
               "Shipping": purchaseOrderItem?.shipping,
               "Total": total,
               "Vendor Name": bill.vendorNameStr,
               "PO Number": purchaseOrder?.poNumber,
            });
         }
      }

      const today = this.betterDate.createTodayTimestamp();

      this.manageUtil.objToExcel(
         PRItemsToDownload,
         "Bill Items",
         `BillItems-${today}.xlsx`,
      );
   }

   public async updateBudgetSkipStepData(
      budgetWorkflowID,
      prop,
      value,
   ): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "updateBudgetSkipStepData",
         },
         data: {
            budgetWorkflowID: budgetWorkflowID,
            prop: prop,
            value: value,
         },
      });

      return post.data.success;
   }

   public async importGeneralLedgers(
      GLsToImport: unknown,
      locationID: number,
   ): Promise<AxiosResponse> {
      const GLsToImportStringified = JSON.stringify(GLsToImport);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "importGeneralLedgers",
         },
         data: {
            locationID: locationID,
            generalLedgers: GLsToImportStringified,
         },
      });

      if (post?.data?.glArray?.length === 0) {
         return post;
      }

      for (const glData of post.data.glArray) {
         glData.locationID = Number(glData.locationID);
         this.generalLedgers.set(glData.glID, glData);
      }
      this.incGeneralLedgerWatchVar();

      return post;
   }

   public getUnderstockedVendorParts(
      parts: Array<Part>,
      vendor: Vendor,
      partAssociations: Map<
         number,
         {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
         }
      >,
      calculatedPartInfo: Map<number, CalculatedPartInfo>,
   ): Array<Part> {
      const vendorParts: Array<Part> = [];

      for (const part of parts) {
         const associatedPOs = this.findPendingPurchaseOrderInfoForPart(
            part.partID,
            false,
         ).purchaseOrders;
         const partAssociationInfo = partAssociations.get(part.partID);
         const calculatedInfo = calculatedPartInfo.get(part.partID);

         let associatedPOsOverride = false;

         if (part.partOverstocked !== 1) {
            continue;
         }

         for (const purchaseOrder of associatedPOs) {
            // If there's an open PO with this part on it, we need to see if it's been received in that PO (but the PO itself is still open)
            // If it has, and the part qty is still below the min threshold, then we set the override flag
            for (const purchaseOrderItemID of purchaseOrder.poItemIDs) {
               const purchaseOrderItem = this.getPurchaseOrderItem(purchaseOrderItemID);
               if (!purchaseOrderItem || purchaseOrderItem.partID !== part.partID)
                  continue;
               const receivedQty =
                  this.getPurchaseOrderItemReceivedInfo(purchaseOrderItemID)
                     ?.receivedQty ?? 0;

               if (receivedQty !== purchaseOrderItem.qty) {
                  associatedPOsOverride = false;
                  break;
               }
               if (!calculatedInfo || !part.partOverstockedThreshold) {
                  continue;
               }
               associatedPOsOverride =
                  calculatedInfo?.totalQty < part.partOverstockedThreshold;
            }
         }

         if (associatedPOs.size === 0 || associatedPOsOverride) {
            if (partAssociationInfo?.manualRelation) {
               vendorParts.push(part);
            } else if (
               partAssociationInfo?.automaticRelation &&
               partAssociationInfo?.tmpPoItemID
            ) {
               const tmpPoItem = this.getPurchaseOrderItem(
                  partAssociationInfo.tmpPoItemID,
               );
               if (!tmpPoItem?.poID) continue;
               const purchaseOrder = this.getPurchaseOrder(tmpPoItem.poID);
               if (
                  purchaseOrder?.vendorID == vendor.vendorID &&
                  tmpPoItem.partID !== null &&
                  tmpPoItem.partID > 0
               ) {
                  vendorParts.push(part);
               }
            }
            continue;
         }

         // If there are no open POs with this part or we triggered the override, then we allow a PO to be created
      }

      return vendorParts;
   }

   public getPurchaseOrderCostTotals(purchaseOrderID: number):
      | {
           subtotal: number;
           tax: number;
           discount: number;
           shipping: number;
           total: number;
           totalQty: number;
        }
      | undefined {
      const purchaseOrder = this.getPurchaseOrder(purchaseOrderID);
      if (purchaseOrder === undefined) return undefined;
      let subtotal = 0;
      let tax = 0;
      let discount = 0;
      let shipping = 0;
      let total = 0;
      let totalQty = 0;
      for (const poItemID of purchaseOrder.poItemIDs) {
         const poItem = this.purchaseOrderItems.get(poItemID);
         if (poItem === undefined) {
            continue;
         }
         const itemSubtotal = (poItem.qty ?? 0) * (poItem.rate ?? 0);
         const itemDiscount = itemSubtotal * ((poItem.discount ?? 0) / 100);
         const itemTax = (itemSubtotal - itemDiscount) * ((poItem.tax ?? 0) / 100);
         subtotal += itemSubtotal;
         tax += itemTax;
         discount += itemDiscount;
         shipping += poItem.shipping ?? 0;
         total += this.calculatePurchaseOrderItemTotal(poItemID) ?? 0;
         totalQty += poItem.qty ?? 0;
      }
      //lastly round to nearest cent + 2 spaces
      subtotal = Math.round(subtotal * 10000) / 10000;
      tax = Math.round(tax * 10000) / 10000;
      discount = Math.round(discount * 10000) / 10000;
      shipping = Math.round(shipping * 10000) / 10000;

      return { subtotal, tax, discount, shipping, total, totalQty };
   }

   public calculatePurchaseOrderItemTotal(poItemID: number): number | undefined {
      const purchaseOrderItem = this.getPurchaseOrderItem(poItemID);
      if (!purchaseOrderItem) return undefined;

      //we need to find the poItems total...
      let total = (purchaseOrderItem.qty ?? 0) * (purchaseOrderItem.rate ?? 0);

      if (purchaseOrderItem.discount) {
         total = total - (total * purchaseOrderItem.discount) / 100;
      }

      if (purchaseOrderItem.tax) {
         total = total + (total * purchaseOrderItem.tax) / 100;
      }

      total = total + Number(purchaseOrderItem.shipping);

      //lastly round to nearest cent + 2 spaces
      total = Math.round(total * 10000) / 10000;
      return total;
   }

   public calculatePurchaseOrderItemDataBasedOnTransactions(
      purchaseOrderItem: PurchaseOrderItem,
   ): {
      total: number;
      receivedQty: number;
   } {
      let localTotal = 0;
      let localReceivedQty = 0;
      for (const transactionID of purchaseOrderItem.transactionIDs) {
         const transaction = this.getBillTransaction(transactionID);

         if (transaction === undefined) continue;
         const transactionTotal = this.getTransactionTotal(transactionID);
         if (!transactionTotal) continue;
         localTotal = localTotal + transactionTotal;
         localReceivedQty = localReceivedQty + (transaction.qtyReceived ?? 0);
      }
      return {
         total: localTotal,
         receivedQty: localReceivedQty,
      };
   }

   public calculateReceivedTransactionItemTotal(
      transactionID: number,
   ): number | undefined {
      const transaction = this.getBillTransaction(transactionID);
      if (!transaction?.poItemID) return undefined;
      const purchaseOrderItem = this.getPurchaseOrderItem(transaction.poItemID);
      if (!purchaseOrderItem) return undefined;

      //we need to find the poItems total...
      let total = (transaction.qtyReceived ?? 0) * (purchaseOrderItem.rate ?? 0);

      if (purchaseOrderItem.discount) {
         total = total - (total * purchaseOrderItem.discount) / 100;
      }

      if (purchaseOrderItem.tax) {
         total = total + (total * purchaseOrderItem.tax) / 100;
      }

      total = total + Number(purchaseOrderItem.shipping);

      //lastly round to nearest cent + 2 spaces
      total = Math.round(total * 10000) / 10000;
      return total;
   }

   public calcPurchaseOrderItemQty(part: Part): number {
      this.manageParts.calculatePartData(part);
      const calculatedPartInfo = this.manageParts.getSingleCalculatedPartInfo(
         part.partID,
      );
      assert(calculatedPartInfo);
      let qty = 1;
      if (part.partMaxQtyThreshold && part.partMaxQtyThreshold > 0) {
         qty = part.partMaxQtyThreshold - calculatedPartInfo.totalAvailableQty;
      } else if (part.partOverstockedThreshold && part.partOverstockedThreshold > 0) {
         //its not set and a min threshold is set so do the calc for them
         qty = part.partOverstockedThreshold - calculatedPartInfo.totalAvailableQty;
      }
      return qty;
   }

   public getPurchaseOrderCurrentState(
      poID: number,
      poWorkflows?: Array<PurchaseOrderWorkflow>,
   ): PurchaseOrderCurrentState | undefined {
      const lang = this.lang();

      const purchaseOrder = this.getPurchaseOrder(poID);
      if (purchaseOrder?.budgetID === undefined || purchaseOrder?.budgetID === null) {
         return undefined;
      }
      const currentState: PurchaseOrderCurrentState = {};
      const budget = this.getBudget(purchaseOrder.budgetID);
      if (purchaseOrder.state == 0) {
         currentState.name = lang.POStageSetup;
         currentState.poWorkflowID = 0;
      } else if (purchaseOrder.state == 97) {
         currentState.name = lang.POStageReadyToReceive;
         currentState.poWorkflowID = 97;
      } else if (purchaseOrder.state == 98) {
         currentState.name = lang.POStagePartiallyReceived;
         currentState.poWorkflowID = 98;
      } else if (purchaseOrder.state == 99) {
         currentState.name = lang.POStageFullyReceived;
         currentState.poWorkflowID = 99;
      } else if (purchaseOrder.state == 100) {
         currentState.name = lang.POStageClosed;
         currentState.poWorkflowID = 100;
      } else if (purchaseOrder.state == 101) {
         currentState.name = `${lang.POStageClosed} - ${lang.PORequestDisapproved}`;
         currentState.poWorkflowID = 101;
      } else if (purchaseOrder.budgetID !== null) {
         //we need to find the name of the step they are in
         if (budget) {
            for (const workflowID of budget.budgetWorkflowIDs) {
               const workflow = this.getBudgetWorkflow(workflowID);
               if (workflow?.deleted == 0 && workflow.order == purchaseOrder.state) {
                  currentState.name = workflow.name ?? "";
                  currentState.budgetWorkflowID = workflow.budgetWorkflowID;
               }
            }
         }
         if (poWorkflows) {
            //if this is set we need to overright the stateStr to show the most recent for these

            for (const workflow of poWorkflows) {
               if (workflow.order == purchaseOrder.state) {
                  currentState.name = workflow.name ?? "";
                  currentState.poWorkflowID = workflow.poWorkflowID;
               }
            }
         }
      }

      //now let's find if this PO is receivable
      if (purchaseOrder.state == 0) {
         currentState.allowReceipts = false;
      } else if (purchaseOrder.state == 97) {
         currentState.allowReceipts = true;
      } else if (purchaseOrder.state == 98) {
         currentState.allowReceipts = true;
      } else if (purchaseOrder.state == 99) {
         currentState.allowReceipts = false;
      } else if (purchaseOrder.state == 100) {
         currentState.allowReceipts = false;
      } else if (purchaseOrder.state == 101) {
         currentState.allowReceipts = false;
      } else if (budget) {
         for (const workflowID of budget.budgetWorkflowIDs) {
            const workflow = this.getBudgetWorkflow(workflowID);
            if (workflow?.order == purchaseOrder.state) {
               if (workflow?.allowPR == 1) {
                  currentState.allowReceipts = true;
               } else {
                  currentState.allowReceipts = false;
               }
            }
         }
      }

      //now let's find the next step
      if (purchaseOrder.state == 97) {
         currentState.nextStep = undefined;
      } else if (purchaseOrder.state == 98) {
         currentState.nextStep = undefined;
      } else if (purchaseOrder.state == 99) {
         currentState.nextStep = undefined;
      } else if (purchaseOrder.state == 100) {
         currentState.nextStep = undefined;
      } else if (purchaseOrder.state == 101) {
         currentState.nextStep = undefined;
      } else {
         //ok we know they are on either the start or a custom step
         if (budget) {
            for (const workflowID of budget.budgetWorkflowIDs) {
               const workflow = this.getBudgetWorkflow(workflowID);
               if (
                  workflow?.deleted == 0 &&
                  workflow.order == Number(purchaseOrder.state) + 1
               ) {
                  currentState.nextStep = {};
                  currentState.nextStep.name = workflow.name ?? "";
                  currentState.nextStep.budgetWorkflowID = workflow.budgetWorkflowID;
               }
            }
         }

         if (poWorkflows) {
            //if this is set we need to overright the stateStr to show the most recent for these
            for (const workflow of poWorkflows) {
               if (workflow.order == Number(purchaseOrder.state) + 1) {
                  currentState.nextStep = workflow;
               }
            }
         }
         if (currentState.nextStep == undefined) {
            //we checked all of the next steps and couldn't find one which means they are at the end.
            const obj: any = {};
            obj.name = this.lang().POStageReadyToReceive;
            currentState.nextStep = obj;
         }
      }
      return currentState;
   }

   public getPurchaseOrderUserStartedName(poID: number): string | undefined {
      const po = this.getPurchaseOrder(poID);
      if (!po?.userIDStarted) return undefined;
      const user = this.manageUser.getFlatUsersIndex()[po.userIDStarted];
      if (user) {
         return `${user.userFirstName} ${user.userLastName}`;
      }

      const systemUser = this.manageUser.getSysUsersIndex()[po.userIDStarted];
      if (systemUser) {
         if (systemUser.userInternal == 1) {
            return this.lang().System;
         }
         return this.lang().ExternalUser;
      }

      return this.lang().DeletedUser;
   }

   public getPurchasingAssignmentName({
      userID,
      profileID,
      defaultValue,
   }: {
      userID?: number | null;
      profileID?: number | null;
      defaultValue?: string | null;
   }): string | undefined {
      if (
         userID &&
         userID > 0 &&
         this.manageUser.getFlatUsersIndex()[userID] &&
         profileID === 0
      ) {
         return `${this.manageUser.getFlatUsersIndex()[userID].userFirstName} ${
            this.manageUser.getFlatUsersIndex()[userID].userLastName
         }`;
      } else if (
         profileID &&
         profileID > 0 &&
         this.manageUser.getProfilesIndex()[profileID]
      ) {
         return this.manageUser.getProfilesIndex()[profileID].profileDescription;
      }
      if (defaultValue) {
         return defaultValue;
      }
      return this.lang().Unassigned;
   }

   public getPurchaseOrderItemReceivedInfo(
      poItemID: number,
   ): { receivedQty: number; ableToReceive: boolean } | undefined {
      const poItem = this.getPurchaseOrderItem(poItemID);
      if (!poItem?.poID) return undefined;
      const po = this.getPurchaseOrder(poItem.poID);
      if (!po) return undefined;
      if (po.state == 101) {
         //this PO was requested and then disapproved... so we obviously never received any items
         return {
            receivedQty: 0,
            ableToReceive: false,
         };
      } else if (po.state == 100) {
         //if this po items PO is fully completed then this item must be fully recieved
         return {
            receivedQty: poItem.qty ?? 0,
            ableToReceive: false,
         };
      }
      //we aren't in a completed state so go through its transactions and let's see how many are received
      let totalReceivedQty = 0;
      for (const transactionID of poItem.transactionIDs) {
         const transaction = this.getBillTransaction(transactionID);
         if (!transaction) continue;
         totalReceivedQty += Number(transaction.qtyReceived ?? 0);
      }
      totalReceivedQty = Number(totalReceivedQty.toFixed(3));
      if (poItem.qty && totalReceivedQty >= poItem.qty) {
         return { receivedQty: totalReceivedQty, ableToReceive: false };
      }
      return { receivedQty: totalReceivedQty, ableToReceive: true };
   }

   public getPurchaseOrderNumberForDisplay(
      poID: number,
   ): { poNumberWithoutPrefix: string; poNumberForDisplay: string } | undefined {
      const po = this.getPurchaseOrder(poID);
      if (!po?.poNumber) return undefined;
      const poNumberWithoutPrefix = `#${this.manageUtil.padString(po.poNumber, 4)}`;

      if (po.poPrefix) {
         return {
            poNumberForDisplay: `#${po.poPrefix} - ${this.manageUtil.padString(
               po.poNumber,
               4,
            )}`,
            poNumberWithoutPrefix,
         };
      }
      return {
         poNumberForDisplay: poNumberWithoutPrefix,
         poNumberWithoutPrefix,
      };
   }

   public getBillTotals(
      prID: number,
   ): { total: number; totalQtyReceived: number } | undefined {
      const pr = this.getBill(prID);
      if (!pr) return undefined;

      let totalQtyReceived = 0;
      let total = 0;

      for (const transactionID of pr.transactionIDs) {
         const transaction = this.getBillTransaction(transactionID);
         if (!transaction?.poItemID) continue;
         const poItem = this.getPurchaseOrderItem(transaction.poItemID);
         if (!poItem) continue;

         totalQtyReceived = totalQtyReceived + Number(transaction.qtyReceived);

         total += this.getTransactionTotal(transactionID) ?? 0;
      }
      return { totalQtyReceived, total };
   }

   public getTransactionTotal(transactionID: number): number | undefined {
      const transaction = this.getBillTransaction(transactionID);
      if (!transaction?.poItemID) return undefined;
      const poItem = this.getPurchaseOrderItem(transaction.poItemID);
      if (!poItem) return undefined;

      let transactionTotal = (transaction.qtyReceived ?? 0) * (poItem.rate ?? 0);

      if (poItem.discount && poItem.discount > 0) {
         transactionTotal = transactionTotal - (poItem.discount / 100) * transactionTotal;
      }

      if (poItem.tax && poItem.tax > 0) {
         transactionTotal = transactionTotal + (poItem.tax / 100) * transactionTotal;
      }

      //shipping needs to apply after everything
      const transactionShipping =
         ((transaction.qtyReceived ?? 0) / (poItem.qty ?? 0)) * (poItem.shipping ?? 0);

      transactionTotal = transactionTotal + transactionShipping;

      return transactionTotal;
   }

   public getBillStatusAndReceivableInfo(
      prID: number,
   ): { receivable: boolean; currentStatus: { name: string } } | undefined {
      const pr = this.getBill(prID);
      if (!pr) return undefined;
      if (pr.status == 2) {
         return {
            receivable: false,
            currentStatus: { name: this.lang().PRStagePaid },
         };
      } else if (pr.status == 1) {
         return {
            receivable: false,
            currentStatus: {
               name: this.lang().PRStageReceivedPendingPayment,
            },
         };
      } else if (pr.status == 0 && pr.poID) {
         const po = this.getPurchaseOrder(pr.poID);
         if (!po) return undefined;
         for (const poItemID of po.poItemIDs) {
            const poItem = this.getPurchaseOrderItem(poItemID);
            const poItemReceivedInfo = this.getPurchaseOrderItemReceivedInfo(poItemID);
            if (!poItem?.qty || !poItemReceivedInfo) continue;
            if (poItemReceivedInfo.receivedQty < poItem.qty) {
               //if any item has a receivedQty less than the poItem.qty, the Bill is still receivable
               //so we can return early if one
               return {
                  receivable: true,
                  currentStatus: {
                     name: this.lang().PRStageSetup,
                  },
               };
            }
         }
         return {
            receivable: false,
            currentStatus: {
               name: this.lang().PRStageSetup,
            },
         };
      }
      return undefined;
   }

   public async getOpenPONumbersInBudgetStep(
      budgetID: number | null,
      stepID: number | null,
   ): Promise<number[]> {
      const request = this.axios({
         method: "POST",
         url: "phpscripts/managePO.php",
         params: {
            action: "getOpenPONumbersInBudgetStep",
         },
         data: { budgetID: budgetID ?? 0, stepID: stepID ?? 0 },
      });

      return request.then((response) => response.data);
   }
}
