import { createProvidedUnitSchema } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/create-provided-unit-dto-schema";
import type {
   DescribeCountCategoryParams,
   DescribeUnitCategoryParams,
} from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/describe-unit-category.params";
import type { UnitCategoryDescription } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/unit-category-description.types";
import { UnitCategories } from "src/app/parts/unit-of-measure/unit.types";

export function describeUnitCategory(
   description: DescribeUnitCategoryParams,
): UnitCategoryDescription {
   return {
      schema: createProvidedUnitSchema(description),
      detailsMap: description.detailsMap,
      category: description.category,
   };
}

export function describeCountCategory(
   description: DescribeCountCategoryParams,
): UnitCategoryDescription {
   const unitDetailsMapWithConversion = Object.fromEntries(
      Object.entries(description).map(([unitName, translationKeyMap]) => [
         unitName,
         { ...translationKeyMap, conversionFactorToSIUnit: 1 },
      ]),
   );

   return describeUnitCategory({
      category: UnitCategories.Count,
      detailsMap: unitDetailsMapWithConversion,
   });
}
