import { NgTemplateOutlet } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import {
   Component,
   computed,
   ElementRef,
   EventEmitter,
   HostBinding,
   inject,
   Input,
   Output,
   Renderer2,
} from "@angular/core";
import type { Tab } from "@limblecmms/lim-ui";
import {
   EntityViewerModalComponent,
   IconButtonComponent,
   isMobile,
   LimbleHtmlDirective,
   manageDemo,
   MinimalIconButtonComponent,
   ModalService,
   ScrollContainerComponent,
   TabBarComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import $ from "jquery";
import type { Subscription } from "rxjs";
import { ImageTile } from "src/app/files/components/imageTile/imageTile.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import type { VendorInfoTabHeader } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { VendorWorkOrderWrapper } from "src/app/vendors/components/vendor-work-order-wrapper/vendor-work-order.wrapper.component";
import { VendorWorkOrderLegacyWrapper } from "src/app/vendors/components/vendor-work-order-wrapper-legacy/vendor-work-order-legacy.wrapper.component";
import { VendorAssets } from "src/app/vendors/components/vendorAssetsWrapper/vendorAssets.wrapper.component";
import { VendorInformation } from "src/app/vendors/components/vendorInformationWrapper/vendorInformation.wrapper.component";
import { VendorLog } from "src/app/vendors/components/vendorLogWrapper/vendorLog.wrapper.component";
import { VendorParts } from "src/app/vendors/components/vendorPartsWrapper/vendorParts.wrapper.component";
import { VendorPurchasingOrders } from "src/app/vendors/components/vendorPurchaseOrdersWrapper/vendorPurchaseOrders.wrapper.component";
import { VendorSettings } from "src/app/vendors/components/vendorSettingsModal/vendorSettings.modal.component";
import { PrintDivDirective } from "src/app/vendors/directives/printDiv/printDiv.directive";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

type VendorCreds = {
   editVendorSettings: boolean;
   viewManageVendors: boolean;
};

@Component({
   selector: "vendor-tile",
   templateUrl: "./vendorTile.wrapper.component.html",
   styleUrls: ["./vendorTile.wrapper.component.scss"],
   imports: [
      EntityViewerModalComponent,
      LimbleHtmlDirective,
      ImageTile,
      MinimalIconButtonComponent,
      TooltipDirective,
      TabBarComponent,
      IconButtonComponent,
      PrintDivDirective,
      ScrollContainerComponent,
      NgTemplateOutlet,
      VendorInformation,
      VendorWorkOrderWrapper,
      VendorWorkOrderLegacyWrapper,
      VendorAssets,
      VendorParts,
      VendorLog,
      VendorPurchasingOrders,
   ],
})
export class VendorTile implements OnInit, OnDestroy {
   @HostBinding("class") public readonly classes = "scroll-height-inheritance";
   @Input() public vendorID: number | undefined;
   @Input() public restrict;
   @Input() public clearFilter;
   @Input() public navigateToTab: VendorInfoTabHeader | undefined;
   @Input() public scrollableContent: boolean = true;
   @Output() readonly closeModalEvent = new EventEmitter();
   public vendor: Vendor | undefined;
   public selectedLocation;
   public location;
   public loading;
   public locationID;
   public vendorTileWatchVarSub;
   public isDemo;
   public creds: VendorCreds = {
      editVendorSettings: false,
      viewManageVendors: false,
   };
   public woTile: { color: number; count: number } | undefined;
   private tasksWatchVarSub: Subscription | null | undefined;
   private completedTasksWatchVarSub: Subscription | null | undefined;
   protected selectedTabId: string = "info";
   protected pageTabs: ReadonlyArray<Tab> = [];
   protected uploadObj;
   protected vendorImageObj;
   private readonly customerID: number;
   public fileUploaded;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly credService = inject(CredService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageParts = inject(ManageParts);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly elementRef = inject(ElementRef);
   private readonly renderer = inject(Renderer2);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   readonly #flagsService = inject(LegacyLaunchFlagsService);
   private readonly refreshService = inject(RefreshService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   /**
    * Flag to determine whether to use the new vendor work order wrapper or the legacy one.
    */
   public readonly newVendorWOWrapperEnabled;

   public constructor() {
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.newVendorWOWrapperEnabled ??= this.#flagsService.isEnabled(
         Flags.JIT_SMALL_STANDALONE_TASKS,
      );

      this.pageTabs = [
         {
            id: "info",
            title: this.lang().InfoTabHeading,
            icon: "circleInfoSolid",
         },
         {
            id: "WOs",
            title: this.lang().VendorWOsTabHeading,
            icon: "wrench",
         },
         {
            id: "assets",
            title: this.lang().VendorAssetsTabHeading,
            icon: "cubes",
         },
         {
            id: "parts",
            title: this.lang().VendorPartsTabHeading,
            icon: "gears",
         },
         {
            id: "POs",
            title: this.lang().VendorPOTabHeading,
            icon: "filePowerpoint",
         },
         {
            id: "logs",
            title: this.lang().VendorLogTabHeading,
            icon: "list",
         },
      ];
   }

   public ngOnInit() {
      if (this.vendorID === undefined) {
         throw new Error(
            "VendorTile component requires a `vendorID` input binding, but a value was not provided.",
         );
      }

      this.vendor = this.manageVendor.getVendor(this.vendorID);
      if (this.vendor === undefined) {
         throw new Error(
            "VendorTile component requires a `vendor` , but no vendor with the provided vendorID exists.",
         );
      }

      this.getVendorCreds();

      this.renderer.setAttribute(
         this.elementRef.nativeElement,
         "id",
         `printVendorContainer${this.vendor.vendorID}`,
      );
      this.isDemo = manageDemo.demo;

      this.selectedLocation = this.manageLocation.getSelectedLocation();
      this.location = this.manageLocation.getLocationsIndex()[this.vendor.locationID];
      this.createUploadObj();

      if (this.vendor.vendorFileName) {
         this.setImage();
      }

      this.loading = false;

      //this timeout is in place so that on mobile devices will pull completed data only when viewing an vendor.
      //brand new customers with 0 complete tasks will have this called multiple times, but that is ok
      //as new customers will get completed tasks very quickly
      //this will only be called on mobile views
      setTimeout(() => {
         if (isMobile()) {
            if (this.manageTask.getCompletedTasks("VendorTile").size < 1) {
               this.manageTask.fetchCompletedTasks();
            }
         }
      }, 50);

      //builds the basic Vendor data.  This has to be set in a watch because not everything is built.
      //Look at pagination done by dir-paginate.  Vendors 10+ aren't built because they aren't the first 10
      //this helps solve that problem.
      this.vendorTileWatchVarSub = this.manageObservables.setSubscription(
         "vendorTileWatchVar",
         () => {
            this.watchData();
         },
      );

      this.tasksWatchVarSub = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.calcWoTileInfo();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );

      this.completedTasksWatchVarSub = this.manageObservables.setSubscription(
         "completedTasksWatchVar",
         () => {
            this.calcWoTileInfo();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );
      if (this.navigateToTab) {
         this.updateSelectedTab(this.navigateToTab);
      }
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.tasksWatchVarSub,
         this.vendorTileWatchVarSub,
         this.completedTasksWatchVarSub,
      ]);
   }

   watchData = () => {
      if (!this.vendor) {
         return;
      }

      this.locationID = this.vendor.locationID;

      //this has to run again because of the problem associated with the dir-pagination.
      //items after 10 didn't have the watch on tasks's properly trigger.
      //if we only have it here then when we add a new open WO or PM the number doesn't update.
      this.calcWoTileInfo();
   };

   print = () => {
      this.renderer.addClass(this.elementRef.nativeElement, "printItem");
      window.print();
   };

   //This is because dir-paginate was only performing this on the first 10 vendors.  This causes a problem
   //for any vendor on pagenate 2+
   getVendorCreds = () => {
      assert(this.vendor);
      this.creds.editVendorSettings = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.EditVendorSettings,
      );
      this.creds.viewManageVendors = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ViewManageVendors,
      );
   };

   settings = () => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.EditVendorSettings,
         )
      ) {
         this.alertService.addAlert(this.lang().cred135Fail, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(VendorSettings);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: `${this.lang().Settings} - ${this.vendor.vendorName}`,
            vendor: this.vendor,
         },
      };
   };

   public calcWoTileInfo(): void {
      if (!this.vendor) {
         return;
      }

      this.woTile = this.manageFilters.vendorTileOpenWorkOrders(
         this.manageTask.getTasks(),
         this.manageTask.getVendorTasks(),
         this.vendor.vendorID,
      );
   }

   public updateSelectedTab(tabID: string): void {
      this.selectedTabId = tabID;
   }

   protected createUploadObj(): void {
      assert(this.vendor);

      this.uploadObj = {};
      this.uploadObj.deleteData = {};
      this.uploadObj.maxFiles = 1;
      this.uploadObj.uploadTypes = "images"; //images / documents / importFiles (excel + csv) or empty for default (images and documents)
      this.uploadObj.posturl = `phpscripts/manageVendor.php?action=makeFileVendorMainImage&locationID=${this.vendor.locationID}&vendorID=${this.vendor.vendorID}`;
      this.uploadObj.viewOnly = !this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ChangeVendorImage,
      );

      this.uploadObj.deleteCall = async () => {
         return axios({
            url: `phpscripts/manageVendor.php?action=deleteVendorMainImage&locationID=${this.vendor?.locationID}&vendorID=${this.vendor?.vendorID}`,
            method: "POST",
            data: null,
         });
      };

      this.uploadObj.deleteSuccess = () => {
         assert(this.vendor);
         this.vendor.vendorFileName = null;
         this.vendorImageObj = null;
         this.manageVendor.updateVendorFieldsObservable();
      };

      this.uploadObj.uploadComplete = (data) => {
         if (data.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            this.fileUploaded = true;

            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );

            //keep this timeout to counter a bug on iPhone not updating.  This timeout doesn't cause efficency issues - Bryan.
            setTimeout(() => {
               assert(this.vendor);
               this.vendor.vendorFileName = data.filename;
               this.setImage();
               this.manageVendor.updateVendorFieldsObservable();
            }, 100);

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         }
      };
   }

   setImage = () => {
      assert(this.vendor);
      const image = {
         getURL: `viewFile.php?f=upload-${this.customerID}/vendors/${this.vendor.locationID}/${this.vendor.vendorID}/${this.vendor.vendorFileName}`,
         fileName: this.vendor.vendorFileName,
      };
      this.vendorImageObj = image;
   };

   protected emitCloseModal() {
      this.closeModalEvent.emit();
   }
}
