import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   ChangeDetectorRef,
   Component,
   Input,
   computed,
   signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   CardComponent,
   DropdownButtonComponent,
   DropdownDateRangePickerComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
   PaginationComponent,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import type { Chart } from "chart.js";
import type { Subscription } from "rxjs";
import { ReplaySubject } from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { PartReportsForecastTableComponent } from "src/app/parts/components/part-modal/components/part-reports-tab/part-reports-forecast-table/part-reports-forecast-table.component";
import type { AugmentedTask } from "src/app/parts/components/part-modal/components/shared/model/augmented-task-model";
import type { Part } from "src/app/parts/types/part.types";
import { CurrencyNamePipe } from "src/app/purchasing/currency/pipes/currency-name.pipe";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { ScheduleListComponent } from "src/app/schedules/schedule-list-component/schedule-list.component";
import { ViewListOfTasksBySchedule } from "src/app/schedules/viewListOfTasksByScheduleModal/viewListOfTasksBySchedule.modal.component";
import { SortColumn_refactor } from "src/app/shared/components/global/sortColumnModal/sortColumn_refactor.element.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { SliceLimbleMap } from "src/app/shared/pipes/sliceLimbleMap.pipe";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import type { SortColumnInfo } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { getComparator } from "src/app/shared/utils/sortingHelpers";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

type TaskSortColumnInfo = SortColumnInfo<
   {
      checklistName: string | undefined;
      qtyUsed: number | undefined;
      checklistCompletedDate: number | undefined;
      completedByStr: string | undefined;
   },
   "augmentedTask"
>;
@Component({
   selector: "part-reports-tab-legacy",
   templateUrl: "./part-reports-tab-legacy.component.html",
   styleUrls: ["./part-reports-tab-legacy.component.scss"],
   imports: [
      PanelComponent,
      DropdownButtonComponent,
      TooltipDirective,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownDateRangePickerComponent,
      CardComponent,
      IconComponent,
      NgClass,
      LimbleHtmlDirective,
      PaginationComponent,
      BetterDatePipe,
      BetterDecimalPipe,
      SliceLimbleMap,
      SortColumn_refactor,
      PartReportsForecastTableComponent,
      TooltipDirective,
      CurrencyNamePipe,
      LocaleCurrencyPipe,
   ],
})
export class PartReportTabLegacyComponent implements OnInit, OnDestroy {
   private readonly manageLang = inject(ManageLang);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly manageAsset = inject(ManageAsset);

   @Input({ required: true }) public part!: Part;
   public loaded;
   public rendered;
   public filterTimeStr;
   public dueDate1;
   public dueDate2;
   public totalParts;
   public totalPartCost;
   public tasks: Lookup<"checklistID", AugmentedTask> = new Lookup("checklistID");
   public daysSinceUsed;
   public staleStatus;
   public lineData;
   public lineOptions;
   public theMonths;
   public yyyy;
   public totalPartCount;
   public currencySymbol;
   public lineChart: Chart<any> | undefined;
   public lineChartData;
   public filterTime;
   public page = 1;
   public itemsPerPage = 10;
   public sortBind: keyof AugmentedTask = "checklistName";
   private readonly sortChangesSub: Subscription;
   public taskAssetNames: LimbleMap<number, string> = new LimbleMap();
   public dayFilters: Array<{ days: number | false; display: string }> = [];

   private readonly jitSchedulesEnabled: boolean = false;

   protected readonly sortChanges: ReplaySubject<TaskSortColumnInfo> =
      new ReplaySubject();
   public sortColumnInfo: Record<
      "checklistName" | "qtyUsed" | "checklistCompletedDate" | "completedByStr",
      TaskSortColumnInfo
   >;
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly currentLocationID = signal<number | undefined>(undefined);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationsQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationsQueries.detail(this.currentLocationID()),
   );

   protected readonly isCurrencyPending = computed(
      () => this.locationQuery.isPending() || this.accountCurrencyQuery.isPending(),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );
   public constructor() {
      this.manageUser.currentUserChanges$
         .pipe(takeUntilDestroyed())
         .subscribe((currentUser) => {
            this.currencySymbol = currentUser.currency?.symbol;
         });

      this.sortColumnInfo = {
         checklistName: {
            columnName: this.lang().TaskName,
            sortProperty: "checklistName",
            locationOfProperty: "augmentedTask",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         qtyUsed: {
            columnName: this.lang().Qty,
            sortProperty: "qtyUsed",
            locationOfProperty: "augmentedTask",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         checklistCompletedDate: {
            columnName: this.lang().UsedOn,
            sortProperty: "checklistCompletedDate",
            locationOfProperty: "augmentedTask",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         completedByStr: {
            columnName: this.lang().UsedBy,
            sortProperty: "completedByStr",
            locationOfProperty: "augmentedTask",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
      };

      this.sortChangesSub = this.sortChanges.subscribe((sortOptions) => {
         this.handleSortChange(sortOptions);
      });
   }

   public ngOnInit() {
      this.initialize();
      this.currentLocationID.set(this.part.locationID);
   }

   private initialize() {
      this.filterTime = "All";
      this.loaded = false;
      this.rendered = false;

      this.filterTimeStr = this.lang().AllTime;

      this.buildData();
   }

   public ngOnDestroy() {
      this.sortChangesSub.unsubscribe();
   }

   pageChanged = () => {
      this.ref.detectChanges();
   };

   protected setDaysFilter(days: any, displayText: string) {
      this.filterTimeStr = displayText;

      if (days) {
         this.filterTime = `${days}Days`;
      } else {
         this.filterTime = false;
      }

      this.buildData();
   }

   setDateRange = () => {
      const month: any = [];
      month[0] = this.lang().Jan;
      month[1] = this.lang().Feb;
      month[2] = this.lang().Mar;
      month[3] = this.lang().Apr;
      month[4] = this.lang().May;
      month[5] = this.lang().Jun;
      month[6] = this.lang().Jul;
      month[7] = this.lang().Aug;
      month[8] = this.lang().Sep;
      month[9] = this.lang().Oct;
      month[10] = this.lang().Nov;
      month[11] = this.lang().Dec;

      const dueDate1 = new Date(this.dueDate1);
      const dueDate2 = new Date(this.dueDate2);

      const str1 = `${month[dueDate1.getMonth()]} ${dueDate1.getDate()}`;
      const str2 = `${month[dueDate2.getMonth()]} ${dueDate2.getDate()}`;
      this.filterTimeStr = `${str1}, ${dueDate1.getFullYear()} - ${str2},${dueDate2.getFullYear()}`;

      this.filterTime = "dateRange";
      this.buildData();
   };

   popTask = (task): void => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: false,
               preview: true,
            },
         },
      };
   };

   viewAsset = (task) => {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: task.assetID,
            locationID: task.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   buildData = () => {
      let tasks = this.manageTask.getCompletedTasks();

      const locations = this.manageLocation.getLocations();
      //if filterTime is set then we need to filter to the time period
      if (this.filterTime === "7Days") {
         tasks = this.manageFilters.filterTasksToLastXDays(
            tasks,
            "checklistCompletedDate",
            7,
         );
      } else if (this.filterTime === "30Days") {
         tasks = this.manageFilters.filterTasksToLastXDays(
            tasks,
            "checklistCompletedDate",
            30,
         );
      } else if (this.filterTime === "60Days") {
         tasks = this.manageFilters.filterTasksToLastXDays(
            tasks,
            "checklistCompletedDate",
            60,
         );
      } else if (this.filterTime === "90Days") {
         tasks = this.manageFilters.filterTasksToLastXDays(
            tasks,
            "checklistCompletedDate",
            90,
         );
      } else if (this.filterTime === "365Days") {
         tasks = this.manageFilters.filterTasksToLastXDays(
            tasks,
            "checklistCompletedDate",
            365,
         );
      } else if (this.filterTime === "dateRange") {
         if (this.dueDate1 !== "undefined" && this.dueDate2 !== "undefined") {
            tasks = this.manageFilters.dateRange(tasks, {
               property: "checklistCompletedDate",
               startUnix: this.dueDate1,
               endUnix: this.dueDate2,
            });
         }
      } else {
         //all time, do nothing to filter tasks
         tasks = this.manageTask.getCompletedTasks();
      }

      assert(this.part);
      const priceArr = [0];
      const usedArr = [0];
      tasks = this.filterTasksByPart(tasks, this.part.partID);

      for (const task of tasks) {
         //loop through partRelations data
         let usedNumber = 0;
         for (const relationID of task.partRelationIDs) {
            const relation = this.manageTask.getPartRelation(relationID);
            if (relation?.partID === this.part.partID) {
               if (relation) {
                  usedArr.push(Number(relation.usedNumber));
                  const usedPrice = relation.usedPrice ?? 0;
                  usedNumber = usedNumber + (relation.usedNumber ?? 0);
                  priceArr.push(Number(usedPrice * usedNumber));
               }
            }
         }

         let locationName = "";
         if (locations[task.locationID]) {
            locationName = locations[task.locationID].locationName;
         }

         const completedByStr =
            this.manageTask.getCompletedTaskCalculatedInfo(task)?.completedByStr ?? "";

         this.tasks.set(task.checklistID, {
            ...task,
            qtyUsed: usedNumber,
            locationName,
            completedByStr,
         });
         if (task.assetID) {
            const assetName = this.manageAsset.getAssetNameIncludeParents(task.assetID);
            this.taskAssetNames.set(task.checklistID, assetName);
         }
      }
      this.tasks = this.tasks.orderBy(this.sortBind);
      this.totalParts = usedArr.reduce((aaa, bbb) => Number(aaa) + Number(bbb));
      this.totalPartCost = priceArr.reduce((aaa, bbb) => aaa + bbb).toFixed(2);

      //Calculate days since used
      if (tasks.size == 0) {
         this.daysSinceUsed = this.lang().NeverUsed;
      } else {
         const dates: any = [];
         for (const task of tasks) {
            dates.push(task.checklistCompletedDate);
         }
         const recentUsedDate = Math.max(...dates);

         const today = Math.round(new Date().getTime() / 1000);

         const secondsSinceUsed = Math.abs(today - recentUsedDate);

         this.daysSinceUsed = Math.floor(secondsSinceUsed / (3600 * 24));
      }

      if (this.part.partStaleThreshold == -1) {
         this.staleStatus = this.lang().Disabled;
      } else if (
         this.part.partStaleThreshold &&
         this.daysSinceUsed >= this.part.partStaleThreshold
      ) {
         this.staleStatus = this.lang().Stale;
      } else {
         this.staleStatus = this.lang().NotStale;
      }

      this.loaded = true;
      this.rendered = true;
   };

   getNextMonth = (month) => {
      if (month > 11) {
         this.yyyy = Number(this.yyyy) + 1;
         return 1;
      }
      return month + 1;
   };

   public viewBarChartItem(event) {
      assert(this.part);
      assert(this.lineChart);
      const activePoints = this.lineChart.getElementsAtEventForMode(
         event,
         "nearest",
         { intersect: true },
         false,
      );

      const schedules = this.lineChartData[activePoints[0].index].customData;

      if (this.jitSchedulesEnabled) {
         const modalRef = this.modalService.open(ScheduleListComponent);
         modalRef.componentInstance.scheduleIDs = schedules.map(
            (schedule) => schedule.scheduleID,
         );
         modalRef.componentInstance.title = `<b>${this.part.partName}</b> - ${this.lang().EstimatedPartUsage}`;
      } else {
         const instance = this.modalService.open(ViewListOfTasksBySchedule);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().EstimatedPartUsageMsg,
               title: `<b>${this.part.partName}</b> - ${this.lang().EstimatedPartUsage}`,
               schedules: schedules,
            },
         };
      }
   }

   private filterTasksByPart(tasks: TaskLookup, partID: number): TaskLookup {
      return tasks.filter((task) => {
         if (!task.partRelationIDs.length) {
            return false;
         }
         return task.partRelationIDs.some((relationID) => {
            const relation = this.manageTask.getPartRelation(relationID);
            return relation?.partID === partID;
         });
      });
   }

   protected handleSortChange(sortInfo: TaskSortColumnInfo): void {
      if (!sortInfo) {
         return;
      }
      if (sortInfo.locationOfProperty === "augmentedTask") {
         const comparator = getComparator(sortInfo.sortDirection);
         this.tasks = this.tasks.orderBy(sortInfo.sortProperty, comparator);
      }
   }
}
