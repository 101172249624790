<lim-ui-panel [noSidePadding]="true">
   <div class="header">
      <div class="text-with-button">
         <span class="panel-text">
            <lim-ui-icon icon="gears" />
            {{ lang().AssociatedParts }}
         </span>
         <lim-ui-primary-button (click)="associatePartsToAsset()">{{
            lang().AssociateParts
         }}</lim-ui-primary-button>
      </div>

      <lim-ui-search-all-wrapper>
         <data-viewer-search
            searchBox
            [isClearable]="true"
            (searchChange)="onSearchChange($event)"
         />
      </lim-ui-search-all-wrapper>
   </div>
   <div class="list-items">
      @if (total() === 0 && !isLoading()) {
         @if (noSearchResults()) {
            <no-search-results />
         } @else {
            <span>{{ lang().NoAssociatedPartsForAsset }}</span>
         }
      }

      @let associatedAsset = asset();
      @if (associatedAsset) {
         @if (isLoading()) {
            <ngx-skeleton-loader count="3" [theme]="skeletonThemes.fillSize" /><br />
         } @else {
            @for (partAndRelation of associatedPartsSignal(); track partAndRelation) {
               <asset-associated-part
                  [partAndRelation]="partAndRelation"
                  [asset]="associatedAsset"
                  (associatedPartUpdated)="
                     associatedPartsDataAccumulator.refreshDataAccumulated()
                  "
                  (associationDeleted)="
                     associatedPartsDataAccumulator.deleteEntity($event)
                  "
               />
            }
         }
      }

      @if (total() > (associatedPartsSignal()?.size ?? 0) && !isLoading()) {
         <lim-ui-text-button (click)="showMore()">
            {{ lang().ShowMore }}</lim-ui-text-button
         >
      }
   </div>
</lim-ui-panel>
