import {
   Component,
   computed,
   inject,
   input,
   viewChild,
   type ElementRef,
   type Signal,
} from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { IconComponent, ModalService } from "@limblecmms/lim-ui";
import {
   Chart,
   type ChartData,
   type ChartOptions,
   type Color,
   type InteractionItem,
} from "chart.js";
import moment from "moment";
import { from, switchMap, toArray, type Observable } from "rxjs";
import { ViewSingleAssetBarReport } from "src/app/assets/components/viewSingleAssetBarReportModal/viewSingleAssetBarReport.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ChartJSHelper } from "src/app/dashboards/widgets/chartJSHelper";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { ScheduleEntity } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import { SchedulesApiService } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "future-estimated-costs-chart",
   imports: [IconComponent, LocaleCurrencyPipe],
   templateUrl: "./future-estimated-costs-chart.component.html",
   styleUrl: "./future-estimated-costs-chart.component.scss",
})
export class FutureEstimatedCostsChartComponent {
   public readonly currencyCode = input.required<string>();
   public readonly assetID = input.required<number>();
   public readonly totalLaborCost = input.required<number>();
   public readonly totalPartsCost = input.required<number>();
   public readonly asset = input.required<Asset | undefined>();

   private readonly chartJSHelper = inject(ChartJSHelper);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUser = inject(ManageUser);
   private readonly schedulesApiService = inject(SchedulesApiService);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);

   protected readonly i18n = inject(TranslationService).i18n;

   private readonly asset$ = toObservable(this.asset);

   public readonly flatUsers = this.manageUser.getFlatUsersIndex();

   private readonly schedulesData: Map<number, Array<ScheduleEntity>> = new Map();

   private readonly futureEstimatedCostViewChild = viewChild<ElementRef>(
      "futureEstimatedCostChartEle",
   );

   private readonly futureEstimatedCostLegendViewChild = viewChild<ElementRef>(
      "futureEstimatedCostChartLegendEle",
   );

   private previousFutureEstimatedCostChart: Chart<"bar"> | undefined = undefined;

   private readonly relevantSchedules$: Observable<ScheduleEntity[]> = this.asset$.pipe(
      switchMap((asset) => {
         if (asset === undefined) return [];

         const nextScheduleStart = moment().startOf("month").toDate();
         const nextScheduleEnd = moment().add(1, "year").endOf("month").toDate();

         let assetIDs: Array<number> = [];
         if (asset.includeChildData === 1) {
            const children = this.manageAsset.findChildrenIDs(asset, []);
            assetIDs = [...children, asset.assetID];
         } else {
            assetIDs = [asset.assetID];
         }

         const schedulesStream = this.schedulesApiService.getStreamedList({
            filters: {
               assetIDs,
               nextScheduleStart,
               nextScheduleEnd,
            },
            columns: "relatedTemplate,parts",
         });

         return from(schedulesStream).pipe(toArray());
      }),
   );

   private readonly relevantSchedules = toSignal(this.relevantSchedules$);

   private readonly futureEstimatedCostChartData: Signal<ChartData<"bar">> = computed(
      () => {
         const dataStyleOptions = {
            tension: 0.5,
            pointRadius: 5,
            fill: false,
            xAxisID: "xAxes",
            yAxisID: "yAxes",
         };

         const chartData: ChartData<"bar"> = {
            labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            datasets: [
               {
                  ...dataStyleOptions,
                  label: "",
                  backgroundColor: "rgba(66,155,31,1)",
                  borderColor: "rgba(66,155,31,1)",
                  data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
               },
               {
                  ...dataStyleOptions,
                  label: "",
                  backgroundColor: "rgba(194,37,40,.9)",
                  borderColor: "rgba(194,37,40,.9)",
                  data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
               },
            ],
         };

         const theMonths = {
            1: {
               short: this.i18n().t("Jan"),
               long: this.i18n().t("January"),
               days: 31,
            },
            2: {
               short: this.i18n().t("Feb"),
               long: this.i18n().t("February"),
               days: 28,
            },
            3: {
               short: this.i18n().t("Mar"),
               long: this.i18n().t("March"),
               days: 31,
            },
            4: {
               short: this.i18n().t("Apr"),
               long: this.i18n().t("April"),
               days: 30,
            },
            5: { short: this.i18n().t("May"), long: this.i18n().t("May"), days: 31 },
            6: { short: this.i18n().t("Jun"), long: this.i18n().t("June"), days: 30 },
            7: { short: this.i18n().t("Jul"), long: this.i18n().t("July"), days: 31 },
            8: {
               short: this.i18n().t("Aug"),
               long: this.i18n().t("August"),
               days: 31,
            },
            9: {
               short: this.i18n().t("Sep"),
               long: this.i18n().t("September"),
               days: 30,
            },
            10: {
               short: this.i18n().t("Oct"),
               long: this.i18n().t("October"),
               days: 31,
            },
            11: {
               short: this.i18n().t("Nov"),
               long: this.i18n().t("November"),
               days: 30,
            },
            12: {
               short: this.i18n().t("Dec"),
               long: this.i18n().t("December"),
               days: 31,
            },
         };

         const getNextMonth = (mm) => {
            if (mm > 11) {
               yyyy = Number(yyyy) + 1;
               return 1;
            }
            return mm + 1;
         };

         const asset = this.asset();
         assert(asset);
         const children = this.manageAsset.findChildrenIDs(asset, []);
         const tempHierAssetIDs: any = [];

         for (const child of children) {
            tempHierAssetIDs.push(child);
         }
         tempHierAssetIDs.push(this.assetID()); //tempHierAssetIDs will include the main asset and all of it's children and sub children

         //sets the information for the bar chart
         let today = new Date(new Date().setFullYear(new Date().getFullYear()));
         today.setMonth(today.getMonth());
         let mm = today.getMonth() + 1; //January is 0!
         let yyyy = today.getFullYear();

         //loop through the months
         assert(chartData?.labels);
         for (let index = 0; index < 12; index++) {
            chartData.labels[index] = `${theMonths[mm].short}, ${yyyy}`;

            let start = new Date(`${mm}/01/${yyyy}`).getTime();
            start = Math.floor(start / 1000);

            mm = getNextMonth(mm);

            let end = new Date(`${mm}/01/${yyyy}`).getTime();
            end = Math.floor(end / 1000);

            let count = 0;

            let thisTime = 0;
            const customerID = this.manageUser.getCurrentUser().userInfo.customerID;
            let defaultTeamWage = 25;
            if (customerID === 185 || customerID === 266) {
               defaultTeamWage = 0;
            }

            for (const tempSchedule of this.relevantSchedules() ?? []) {
               const relatedTemplate = tempSchedule.relatedTemplate;

               if (
                  relatedTemplate === undefined ||
                  tempSchedule.userID === null ||
                  relatedTemplate.checklistEstTime === null
               )
                  continue;
               if (
                  Number(tempSchedule.scheduleStartDate) > start &&
                  Number(tempSchedule.scheduleStartDate) < end
               ) {
                  count = count + 1;
                  if (this.flatUsers[tempSchedule.userID]) {
                     thisTime =
                        thisTime +
                        relatedTemplate.checklistEstTime *
                           this.flatUsers[tempSchedule.userID].userWage;
                  } else {
                     //if we can't find the user's wage then we need to add a basic 15 dollars an hour
                     thisTime =
                        thisTime + relatedTemplate.checklistEstTime * defaultTeamWage;
                  }
               }
            }

            let cost: any = thisTime / 60 / 60;

            cost = cost.toFixed(2);

            chartData.datasets[0].data[index] = cost;
         }

         //sets the information for the bar chart
         today = new Date(new Date().setFullYear(new Date().getFullYear()));
         today.setMonth(today.getMonth());
         mm = today.getMonth() + 1; //January is 0!
         yyyy = today.getFullYear();

         //loop through the months
         for (let index = 0; index < 12; index++) {
            chartData.labels[index] = `${theMonths[mm].short}, ${yyyy}`;
            chartData.datasets[1].data[index] = 0;
            let start = new Date(`${mm}/01/${yyyy}`).getTime();
            start = Math.floor(start / 1000);

            mm = getNextMonth(mm);

            let end = new Date(`${mm}/01/${yyyy}`).getTime();
            end = Math.floor(end / 1000);

            let thisPartsCost = 0;
            let thisPartTotalNum = 0;

            //loop through schedules and if it falls in date range then calculate values for the parts section of the graph
            const tempSchedules: Array<ScheduleEntity> = [];

            for (const tempSchedule of this.relevantSchedules() ?? []) {
               if (
                  Number(tempSchedule.scheduleStartDate) > start &&
                  Number(tempSchedule.scheduleStartDate) < end
               ) {
                  const parts = tempSchedule.parts;

                  if (parts) {
                     for (const part of parts) {
                        thisPartTotalNum = Number(part.suggestedNumber);
                        thisPartsCost = part.partPrice ?? 0;

                        chartData.datasets[1].data[index] =
                           (chartData.datasets[1].data[index] as number) +
                           thisPartsCost * thisPartTotalNum * 1;
                     }

                     if (
                        parts.length > 0 ||
                        (tempSchedule.relatedTemplate.checklistEstTime &&
                           tempSchedule.relatedTemplate.checklistEstTime > 0)
                     ) {
                        tempSchedules.push(tempSchedule);
                        this.schedulesData.set(index, tempSchedules);
                     }
                  }
               }
            }

            chartData.datasets[1].data[index] = Number(chartData.datasets[1].data[index]);
         }

         chartData.datasets[0].label = this.i18n().t("Labor");
         chartData.datasets[1].label = this.i18n().t("Parts");

         return chartData;
      },
   );

   protected readonly futureEstimatedCostChart = computed(() => {
      const futureEstimatedCostChartElement =
         this.futureEstimatedCostViewChild()?.nativeElement;

      if (futureEstimatedCostChartElement instanceof HTMLCanvasElement) {
         if (this.previousFutureEstimatedCostChart !== undefined) {
            this.previousFutureEstimatedCostChart?.destroy();
         }

         const newChart = new Chart(futureEstimatedCostChartElement, {
            type: "bar",
            data: this.futureEstimatedCostChartData(),
            options: this.futureEstimatedCostChartOptions(),
            plugins: [
               {
                  id: "htmlLegend",
                  afterUpdate: (chart) => {
                     const chartId =
                        this.futureEstimatedCostLegendViewChild()?.nativeElement.id;
                     const ul = this.chartJSHelper.getOrCreateLegendList(
                        chart,
                        chartId,
                        "row",
                     );

                     while (ul.firstChild) {
                        ul.firstChild.remove();
                     }
                     this.chartJSHelper.chartHTMLLegend(chart, ul);
                  },
               },
            ],
         });
         this.previousFutureEstimatedCostChart = newChart;
         return newChart;
      }

      return undefined;
   });

   private readonly futureEstimatedCostChartOptions: Signal<ChartOptions<"bar">> =
      computed(() => {
         return {
            plugins: {
               legend: {
                  display: false,
                  position: "bottom",
                  align: "start",
                  labels: {
                     font: {
                        size: 14,
                     },
                  },
               },
               tooltip: {
                  mode: "index",
                  titleFont: {
                     size: 14,
                  },
                  bodyFont: {
                     size: 14,
                  },
                  position: "nearest",
                  intersect: false,
                  callbacks: {
                     labelColor: function (context) {
                        const borderColor = context.dataset.borderColor as Color;
                        const backgroundColor = context.dataset.backgroundColor as Color;
                        return {
                           borderColor: borderColor,
                           backgroundColor: backgroundColor,
                        };
                     },
                  },
               },
               htmlLegend: {
                  containerID: `barChart1Legend${this.assetID()}`,
               },
            },
            scales: {
               xAxes: {
                  beginAtZero: true,
                  grid: {
                     display: true,
                     lineWidth: 1,
                  },
               },
               yAxes: {
                  beginAtZero: true,
                  grid: {
                     display: true,
                     lineWidth: 1,
                  },
               },
            },
            aspectRatio: 2,

            onHover: (event, _chartElement, chart) => {
               this.chartJSHelper.onHoverHandler(event, chart);
            },
         };
      });

   public viewChartItem(event): void {
      const chart = this.futureEstimatedCostChart();
      assert(chart);
      const activePoints: InteractionItem[] = chart.getElementsAtEventForMode(
         event,
         "nearest",
         { intersect: true },
         false,
      );
      if (activePoints?.length === 0) return;

      const label = (chart.config.data?.labels?.[activePoints[0].index] as string) || "";

      const data: any = [{ customData: [] }];

      const customData = this.schedulesData.get(activePoints[0].index) ?? [];
      data[0].customData = [customData];

      const instance = this.modalService.open(ViewSingleAssetBarReport);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: `${this.i18n().t("FutureEstimatedCosts")} - ${label}`,
            data: data,
         },
      };
   }
}
