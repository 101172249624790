<lim-ui-modal [showWatermark]="true">
   @if (field) {
      <lim-ui-basic-modal-header [title]="title" (closeModal)="dismiss()" />
      <lim-ui-modal-body>
         <lim-ui-info-panel>
            <span class="message-area" [limbleHtml]="lang().editVendorField"></span>
         </lim-ui-info-panel>
         <lim-ui-panel>
            <div class="panel-label">{{ lang().Field }} {{ lang().Name }}</div>

            <div
               class="updateFieldHolder"
               [(ngModel)]="fieldExtraInfo.fieldNameTemp"
               (afterEdit)="updateSuggestedFieldName(field)"
               limbleContentEditable
               stripHtml="true"
               disableEditor="true"
            ></div
         ></lim-ui-panel>
         <lim-ui-panel class="checkboxes">
            @if (field.fieldTypeID == 1 || field.fieldTypeID == 5) {
               <div class="checkbox-row">
                  <lim-ui-checkbox
                     [label]="lang().RequireValuesToBeUnique"
                     [(model)]="valueUnique"
                     (modelChange)="updateValueUnique()"
                  />
                  <lim-ui-icon
                     placement="top"
                     limUiTooltip="{{
                        lang().RequireValuesToBeUniqueTooltip +
                           ' ' +
                           lang().valuesUniqueTooltipExtra
                     }}"
                     icon="circleQuestionRegular"
                     iconSize="small"
                  />
               </div>
            }
            <div class="checkbox-row">
               <lim-ui-checkbox
                  [(model)]="isCustomDefault"
                  (modelChange)="updateCustomDefault()"
                  [label]="lang().AutomaticallyAddFieldToNewVendorsAtLocation"
               />
            </div>
         </lim-ui-panel>
         @if (field.fieldTypeID == 7) {
            <lim-ui-panel>
               <div class="panel-label">
                  <div>{{ lang().Field }} {{ lang().Options }}</div>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="top"
                     limUiTooltip="{{ lang().vendorFieldOptionsToolTip }}"
                     iconSize="small"
                  />
               </div>
               <div>
                  <limble-tree-root
                     #tree
                     [data]="options"
                     [options]="treeOptions"
                     (treeDrop)="onMoveNode($event)"
                  />
               </div>
               <lim-ui-secondary-button (click)="addDropdownOption()" icon="plus">{{
                  lang().AddOption
               }}</lim-ui-secondary-button>
            </lim-ui-panel>
         }
         @if (field.fieldTypeID == 2) {
            <lim-ui-panel>
               <div class="panel-label">
                  {{ lang().ReminderOptions }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="top"
                     limUiTooltip="{{ lang().fieldReminderToolTip }}"
                     iconSize="small"
                  />
               </div>
               <div class="date-reminder-options">
                  <date-reminder [field]="field" reminder="dateReminder1" type="vendor" />
                  <date-reminder [field]="field" reminder="dateReminder2" type="vendor" />
                  <date-reminder [field]="field" reminder="dateReminder3" type="vendor" />
                  <div class="send-to-container">
                     {{ lang().SendTo }}
                     <div
                        (click)="changeFieldOwner(field)"
                        limUiTooltip="{{ lang().RemindersWillBeSentToThisUserOrTeam }}"
                        placement="bottom"
                     >
                        @if (field.userID == 0 && field.profileID == 0) {
                           <div class="blue clickable">
                              {{ lang().Manager }}
                           </div>
                        }
                        @if (field.userID && field.userID > 0) {
                           <div class="blue clickable">
                              {{ fieldExtraInfo.userFirstName }}
                              {{ fieldExtraInfo.userLastName }}
                           </div>
                        }
                        @if (field.profileID && field.profileID > 0) {
                           <div class="blue clickable">
                              {{ fieldExtraInfo.profileDescription }}
                           </div>
                        }
                     </div>
                  </div>
               </div>
            </lim-ui-panel>
         }
         <lim-ui-panel>
            <div class="panel-label">
               {{ lang().DefaultBehaviors }}
            </div>
            <div class="checkbox-row">
               <lim-ui-checkbox
                  [model]="!field.viewableByTechFieldDefault"
                  (modelChange)="
                     updateFieldViewableByTechFieldDefault(
                        !field.viewableByTechFieldDefault
                     )
                  "
                  [label]="lang().HideField"
                  [tooltip]="lang().ChangeDefaultShowHideVendorColumnsTool"
               />
            </div>
            <div class="checkbox-explanation">
               @if (!field.viewableByTechFieldDefault) {
                  <p class="lim-ui-fonts-caption">
                     {{ lang().VendorFieldHiddenExplanation }}
                  </p>
               }
               @if (field.viewableByTechFieldDefault) {
                  <p class="lim-ui-fonts-caption">
                     {{ lang().VendorFieldShownExplanation }}
                  </p>
               }
            </div>
            @if (isSuperUser && hasFieldLockFlag) {
               <div>
                  <div class="checkbox-row">
                     <lim-ui-checkbox
                        [(model)]="field.lockedDefault"
                        (modelChange)="toggleFieldLockedDefault()"
                        [label]="lang().LockField"
                        [tooltip]="lang().LockVendorFieldTooltip"
                     />
                  </div>
                  <div class="checkbox-explanation">
                     @if (field.lockedDefault) {
                        <p class="lim-ui-fonts-caption">
                           {{ lang().LockedFieldExplanation }}
                        </p>
                     }
                     @if (!field.lockedDefault) {
                        <p class="lim-ui-fonts-caption">
                           {{ lang().UnlockedFieldExplanation }}
                        </p>
                     }
                  </div>
               </div>
            }
         </lim-ui-panel>
      </lim-ui-modal-body>
      <lim-ui-modal-footer>
         <div class="footer-wrapper">
            <lim-ui-danger-button
               (click)="removeSuggestedField(field)"
               [disabled]="!credCreateAndDeleteVendorFields"
            >
               {{ lang().Delete }}
            </lim-ui-danger-button>
         </div>
      </lim-ui-modal-footer>
   }
</lim-ui-modal>
