import { Injectable, computed, inject, type Signal } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { distinctUntilChanged, filter, map } from "rxjs";
import { ManageUser } from "src/app/users/services//manageUser";
import type { User } from "src/app/users/services/manage-user.types";

export enum LiteEnabledState {
   Unknown = 0,
   Enabled = 1,
   Disabled = 2,
   Unavailable = 3,
}

@Injectable({ providedIn: "root" })
export class LiteEnablementService {
   public readonly liteEnabled: Signal<LiteEnabledState>;
   private readonly manageUser = inject(ManageUser);

   public constructor() {
      const serverData = toSignal(
         this.manageUser.currentUserChanges$.pipe(
            filter((currentUser) => currentUser !== undefined && currentUser !== "none"),
            map((currentUser) => {
               return {
                  offlineAllowed: currentUser.userInfo.featureOffline === 1,
                  liteEnabled: currentUser.userInfo.userUIPreferences.limbleLite === true,
                  userID: currentUser.userInfo.userID,
               };
            }),
            distinctUntilChanged((previous, current) => {
               return (
                  previous.offlineAllowed === current.offlineAllowed &&
                  previous.liteEnabled === current.liteEnabled &&
                  previous.userID === current.userID
               );
            }),
            takeUntilDestroyed(),
         ),
      );
      this.liteEnabled = computed(() => {
         const data = serverData();
         if (data === undefined) {
            return LiteEnabledState.Unknown;
         }
         if (data.offlineAllowed === false) {
            return LiteEnabledState.Unavailable;
         }
         return data.liteEnabled === true
            ? LiteEnabledState.Enabled
            : LiteEnabledState.Disabled;
      });
   }

   public updateLiteEnabled({ enabled, user }: { enabled: boolean; user: User }): void {
      if (!user?.userUIPreferences) return;

      if (user.userUIPreferences.limbleLite === enabled) return;
      user.userUIPreferences.limbleLite = enabled;
      this.manageUser.updateUserUIPreferences(user.userID, user.userUIPreferences);
   }
}
