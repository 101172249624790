<lim-ui-modal [showWatermark]="true">
   @if (loading()) {
      <lim-ui-modal-body>
         <lim-ui-panel>
            <div class="text-center">
               <ngx-skeleton-loader
                  count="1"
                  [theme]="{ width: '30%', height: '15px' }"
               />
            </div>

            <br /><ngx-skeleton-loader
               count="1"
               [theme]="{ height: '15px', border: '0px' }"
            /><br />
         </lim-ui-panel>

         <lim-ui-panel>
            <div class="text-center">
               <ngx-skeleton-loader
                  count="1"
                  [theme]="{ width: '30%', height: '15px' }"
               />
            </div>

            <br /><ngx-skeleton-loader
               count="1"
               [theme]="{ height: '15px', border: '0px' }"
            /><br />
         </lim-ui-panel>

         <lim-ui-panel>
            <div class="text-center">
               <ngx-skeleton-loader
                  count="1"
                  [theme]="{ width: '30%', height: '15px' }"
               />
            </div>

            <br /><ngx-skeleton-loader
               count="1"
               [theme]="{ height: '15px', border: '0px' }"
            /><br />
         </lim-ui-panel>
      </lim-ui-modal-body>
   } @else {
      <lim-ui-basic-modal-header
         title="{{ lang().CompleteTask }}"
         (closeModal)="close()"
      />
      <lim-ui-modal-body>
         <!-- HowLongToComplete section -->
         <!-- No extraTime was set so ask them the standard how long did it take -->
         @if (!multiWork && task() !== undefined) {
            <lim-ui-panel>
               <div class="complete-task-panel-container pricing-plan-container">
                  <span
                     class="pricing-plan-row"
                     [limUiPopover]="startPopover"
                     [limUiPopoverType]="'upsell'"
                     [limUiPopoverPlacement]="'bottom'"
                     [limUiPopoverHidden]="featureTimeCost"
                  >
                     <lim-ui-text-button
                        class="control-label complete-task-prompt"
                        [limbleHtml]="lang().HowLongToComplete"
                        [ngClass]="{ 'grey-out': !featureTimeCost }"
                     />
                     @if (!featureTimeCost) {
                        <lim-ui-badge
                           additional-primary-options
                           class="pricing-plan-badge"
                           icon="stars"
                           [label]="lang().PremiumPlus"
                        />
                     }
                  </span>
                  <div [ngClass]="{ 'grey-out': !featureTimeCost }">
                     @if (viewingLengthHint) {
                        <label
                           class="control-label secondary-text"
                           [limbleHtml]="viewingLengthHint"
                           [limUiPopover]="startPopover"
                           [limUiPopoverType]="'upsell'"
                           [limUiPopoverPlacement]="'bottom'"
                           [limUiPopoverHidden]="featureTimeCost"
                        ></label>
                     }
                     @if (!editTime()) {
                        <span class="control-label">
                           <label
                              [limbleHtml]="
                                 lang().EditingThisTimeHasBeenDisabledByYourManager
                              "
                           >
                           </label>
                           <lim-ui-icon
                              icon="circleQuestion"
                              iconSize="small"
                              limUiTooltip="{{ lang().TaskTimerTooltipWaitItOut }}"
                           />
                        </span>
                     }
                     <div class="complete-task-panel-column">
                        <div class="form-group" [ngClass]="{ 'has-error': hoursError() }">
                           <label class="control-label bold"
                              >{{ lang().Hours }}<span class="red-color">*</span></label
                           >

                           <lim-ui-number-input-with-buttons
                              [(unit)]="hours"
                              (unitChange)="resetErrors(['hoursError', 'minutesError'])"
                              [incrementBy]="1"
                              [canEdit]="editTime() && featureTimeCost"
                              [hasError]="hoursError()"
                              [limUiPopover]="startPopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverPlacement]="'right'"
                              [limUiPopoverHidden]="featureTimeCost"
                              dataLogLabel="completeTask-changeTimeSpentHours"
                              [focusOnLoad]="true"
                           />
                        </div>
                     </div>
                     <div class="complete-task-panel-column">
                        @if (showMinutes) {
                           <div
                              class="form-group"
                              [ngClass]="{ 'has-error': minutesError() }"
                           >
                              <label class="control-label bold"
                                 >{{ lang().Minutes
                                 }}<span class="red-color">*</span></label
                              >

                              <lim-ui-number-input-with-buttons
                                 [(unit)]="minutes"
                                 (unitChange)="
                                    resetErrors(['hoursError', 'minutesError'])
                                 "
                                 [incrementBy]="5"
                                 [canEdit]="editTime() && featureTimeCost"
                                 [hasError]="minutesError()"
                                 [limUiPopover]="startPopover"
                                 [limUiPopoverType]="'upsell'"
                                 [limUiPopoverPlacement]="'right'"
                                 [limUiPopoverHidden]="featureTimeCost"
                                 dataLogLabel="completeTask-changeTimeSpentMinutes"
                              />
                           </div>
                        }
                     </div>

                     <lim-ui-secondary-button
                        icon="plus"
                        (click)="switchLogTime()"
                        [disabled]="!featureTimeCost"
                        [limUiPopover]="startPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverPlacement]="'right'"
                        [limUiPopoverHidden]="featureTimeCost"
                     >
                        {{ lang().LogMoreThanOneTime }}
                     </lim-ui-secondary-button>
                  </div>
               </div>
            </lim-ui-panel>
         }

         <!-- HowLongToComplete section -->
         <!-- Extra time was set so we have to ask different questions -->
         @if (multiWork && task() !== undefined) {
            <lim-ui-panel>
               <div class="complete-task-panel-container">
                  <label class="control-label medium-spacing">
                     {{ lang().SoFarTheTaskHasRecorded }}
                     <b>
                        {{ calculatedTaskInfo()?.checklistPromptTimeTotalHours ?? 0 }}
                        {{ lang().hr }}
                        {{ lang().and }}
                        {{ calculatedTaskInfo()?.checklistPromptTimeTotalMinutes ?? 0 }}
                        {{ lang().min }}
                     </b>
                  </label>
                  @if (taskHasExtraTimes()) {
                     <br />
                  }
                  <div>
                     @if (displayMode === extraTimeDisplayModes.ByUser) {
                        <a
                           href="#"
                           (click)="
                              $event.preventDefault();
                              setDisplayMode(extraTimeDisplayModes.ByTime)
                           "
                        >
                           {{ lang().SortTimeLoggedByDate }}
                        </a>
                     }
                     @if (displayMode === extraTimeDisplayModes.ByTime) {
                        <a
                           href="#"
                           (click)="
                              $event.preventDefault();
                              setDisplayMode(extraTimeDisplayModes.ByUser)
                           "
                        >
                           {{ lang().SortTimeLoggedByUser }}
                        </a>
                     }
                  </div>
                  @let taskExtraTimeViewModel = taskExtraTimeVM();
                  @if (taskExtraTimeViewModel) {
                     <extra-time-list
                        [displayMode]="displayMode"
                        [extraTimeViewModel]="taskExtraTimeViewModel"
                        [checklistID]="checklistID()"
                        (extraTimeUpdated)="tellTaskToRefresh()"
                        #extraTimeList
                     />
                  }

                  <lim-ui-secondary-button
                     icon="plus"
                     (click)="logTimeOnTask()"
                     [ngClass]="taskHasExtraTimes() ? 'small-spacing' : 'no-spacing'"
                  >
                     {{ lang().AddTime }}
                  </lim-ui-secondary-button>
               </div>
            </lim-ui-panel>
         }

         <!-- DidThisTaskCauseUnplannedDowntime section -->
         @if (shouldShowDowntimeSection()) {
            <lim-ui-panel>
               <div class="complete-task-panel-container">
                  <div
                     class="downtime-tracking-pricing-plan-container"
                     [ngClass]="{ 'has-error': unplannedDowntimeError() }"
                  >
                     <span>
                        <label
                           class="control-label"
                           [ngClass]="{ 'grey-out': !featureDowntimeTracking }"
                           [limbleHtml]="lang().DidThisTaskCauseUnplannedDowntime"
                        >
                        </label>
                        <span class="red-color">*</span>
                     </span>

                     @if (!featureDowntimeTracking) {
                        <lim-ui-badge
                           class="pricing-plan-badge"
                           [label]="lang().Standard"
                           icon="stars"
                           [limUiPopover]="startPopover"
                           [limUiPopoverType]="'upsell'"
                           [limUiPopoverPlacement]="'right'"
                        />
                     }
                  </div>
                  @if (!featureDowntimeTracking) {
                     <div
                        class="lim-ui-radio-container"
                        [ngClass]="{
                           'disable-pointer': !featureDowntimeTracking,
                           'grey-out': !featureDowntimeTracking,
                        }"
                     >
                        <ng-container radio-button-list groupID="CauseUnplannedDowntime">
                           <lim-ui-radio-button
                              groupID="CauseUnplannedDowntime"
                              [label]="lang().Yes"
                              (click)="trackDowntime = true; setStartingDownTime()"
                              [checked]="trackDowntime === true"
                           />
                           <lim-ui-radio-button
                              groupID="CauseUnplannedDowntime"
                              [label]="lang().No"
                              (click)="trackDowntime = false"
                              [checked]="trackDowntime === false"
                           />
                        </ng-container>
                     </div>
                  }
                  @if (featureDowntimeTracking === true) {
                     <div class="lim-ui-radio-container">
                        <lim-ui-radio-button
                           groupID="trackDowntimeRadio"
                           label="{{ lang().Yes }}"
                           [checked]="trackDowntime === 1"
                           (click)="setStartingDownTime()"
                        />
                        <lim-ui-radio-button
                           groupID="trackDowntimeRadio"
                           label="{{ lang().No }}"
                           [checked]="trackDowntime === 0"
                           (click)="trackDowntime = 0"
                        />
                     </div>
                  }
               </div>
               @if (
                  task() && trackDowntime === 1 && task()?.assetID;
                  as assignedAssetID !== null && assignedAssetID > 0
               ) {
                  <div class="complete-task-panel-container">
                     <label class="control-label secondary-text"
                        >{{ lang().BasedOnOurRecordsThisTaskWasCreated }}
                        {{ differenceHours }} {{ lang().hrs }}, {{ differenceMinutes }}
                        {{ lang().mins }} {{ lang().ago }}.
                     </label>

                     <div class="complete-task-panel-column">
                        <div
                           class="form-group"
                           [ngClass]="{ 'has-error': hoursErrorDowntime() }"
                        >
                           <label class="control-label bold"
                              >{{ lang().Hours }}<span class="red-color">*</span></label
                           >

                           <lim-ui-number-input-with-buttons
                              [(unit)]="hoursDowntime"
                              (unitChange)="
                                 resetErrors([
                                    'hoursErrorDowntime',
                                    'minutesErrorDowntime',
                                 ])
                              "
                              [incrementBy]="1"
                              [canEdit]="true"
                              [hasError]="hoursErrorDowntime()"
                              dataLogLabel="completeTask-changeDowntimeHours"
                           />
                        </div>
                     </div>
                     <div class="complete-task-panel-column">
                        <div
                           class="form-group"
                           [ngClass]="{ 'has-error': minutesErrorDowntime() }"
                        >
                           <label class="control-label bold"
                              >{{ lang().Minutes }}<span class="red-color">*</span></label
                           >

                           <lim-ui-number-input-with-buttons
                              [(unit)]="minutesDowntime"
                              (unitChange)="
                                 resetErrors([
                                    'hoursErrorDowntime',
                                    'minutesErrorDowntime',
                                 ])
                              "
                              [incrementBy]="5"
                              [canEdit]="true"
                              [hasError]="minutesErrorDowntime()"
                              dataLogLabel="completeTask-changeDowntimeMinutes"
                           />
                        </div>
                     </div>
                  </div>
               }

               @if (task() && trackDowntime === 1 && task()?.assetID === 0) {
                  <div class="pick-asset-for-down-time-wrapper">
                     <label class="control-label secondary-text">
                        {{ lang().WhoopsNeedsToBeAssignedToAssetForDowntime }}
                     </label>
                  </div>
               }
            </lim-ui-panel>
         }

         <!-- WouldYouLikeToAssignToAnAsset section -->
         @if (shouldShowAssignToAssetSection()) {
            <lim-ui-panel>
               <div
                  class="complete-task-panel-container"
                  [ngClass]="{ 'has-error': assignToAssetError() }"
               >
                  <span>
                     <label
                        class="control-label"
                        [limbleHtml]="lang().WouldYouLikeToAssignToAnAsset"
                     >
                     </label>
                     <span class="red-color">*</span>
                  </span>

                  <div class="lim-ui-radio-container">
                     <lim-ui-radio-button
                        groupID="assignToAsset"
                        label="{{ lang().Yes }}"
                        [checked]="assignToAsset === 1"
                        (click)="assignToAsset = 1; changeAsset()"
                     />
                     <lim-ui-radio-button
                        groupID="assignToAsset"
                        label="{{ lang().No }}"
                        [checked]="assignToAsset === 0"
                        (click)="assignToAsset = 0; setAssetToZero()"
                     />
                  </div>
               </div>
               @if (assignToAsset === 1) {
                  <div class="complete-task-panel-container">
                     <lim-ui-secondary-button button (click)="changeAsset()">
                        @if (!asset()) {
                           <span>{{ lang().PickAsset }}</span>
                        }
                        @if (asset()?.assetName) {
                           <span>{{ asset()?.assetName }}</span>
                        }
                     </lim-ui-secondary-button>
                  </div>
               }
            </lim-ui-panel>
         }

         <!-- WouldYouUpdate section - recurrence code -->
         @if (task() !== undefined && assetNumericFieldsDisplayInfo().length > 0) {
            <lim-ui-panel>
               @for (displayInfo of assetNumericFieldsDisplayInfo(); track displayInfo) {
                  <div
                     class="asset-numeric-field-container"
                     [ngClass]="{ 'has-error': assetFieldsError() }"
                  >
                     @if (
                        manageAsset.getFieldValue(displayInfo.fieldValueID);
                        as fieldValue
                     ) {
                        <div>
                           @if (manageAsset.getField(fieldValue.fieldID); as field) {
                              <div>
                                 <div class="complete-task-panel-container">
                                    <label class="control-label">
                                       {{ lang().WouldYouUpdate }}
                                       <b [limbleHtml]="field.fieldName"></b>
                                       <lim-ui-icon
                                          icon="circleQuestionRegular"
                                          iconSize="small"
                                          limUiTooltip="{{ displayInfo.tooltip }}"
                                       />
                                    </label>
                                    <div
                                       class="lim-ui-radio-container"
                                       [ngClass]="{ disabled: field.lockedDefault === 1 }"
                                       [limUiTooltip]="
                                          field.lockedDefault
                                             ? lang().FieldLockdedTooltip
                                             : undefined
                                       "
                                    >
                                       <lim-ui-radio-button
                                          groupID="UpdateRecurrence{{ field.fieldName }}"
                                          label="{{ lang().Yes }}"
                                          [checked]="
                                             displayInfo.userWantsToUpdate === true
                                          "
                                          (click)="
                                             toggleUpdateFieldValue(
                                                field.lockedDefault,
                                                displayInfo,
                                                true
                                             )
                                          "
                                          dataLogLabel="completeTask-updateAssetFieldValue"
                                          [disabled]="field.lockedDefault === 1"
                                       />
                                       <lim-ui-radio-button
                                          groupID="UpdateRecurrence{{ field.fieldName }}"
                                          label="{{ lang().No }}"
                                          [checked]="
                                             displayInfo.userWantsToUpdate === false
                                          "
                                          (click)="
                                             toggleUpdateFieldValue(
                                                field.lockedDefault,
                                                displayInfo,
                                                false
                                             )
                                          "
                                          [disabled]="field.lockedDefault === 1"
                                       />
                                    </div>
                                 </div>
                                 @if (displayInfo.userWantsToUpdate) {
                                    <div class="complete-task-panel-container">
                                       <div class="complete-task-panel-column">
                                          <div class="form-group">
                                             <label class="control-label bold">
                                                {{
                                                   field.fieldName[0].toUpperCase() +
                                                      field.fieldName.slice(1)
                                                }}
                                             </label>

                                             <lim-ui-number-input-with-buttons
                                                [(unit)]="fieldValue.valueContent"
                                                (unitChange)="
                                                   updateAssetField(displayInfo)
                                                "
                                                [incrementBy]="1"
                                                [canEdit]="true"
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 }
                              </div>
                           }
                        </div>
                     }
                  </div>
               }
            </lim-ui-panel>
         }

         <!-- HowManyPartsWereActuallyUsed section -->
         @if (task() && parts() && parts().length > 0) {
            <lim-ui-panel>
               <label
                  class="control-label"
                  [limbleHtml]="lang().HowManyPartsWereActuallyUsed"
               >
               </label>
               <div class="part-wrapper">
                  @for (part of parts() | orderBy: "partName"; track part) {
                     <div class="lim-ui-list-item">
                        <div class="part-list-item-wrapper">
                           <div class="input-parent-wrapper">
                              <div class="part-icon-textbox-wrapper">
                                 <lim-ui-icon icon="gears" iconSize="small" />

                                 <lim-ui-number-input-with-buttons
                                    [unit]="part.suggestedNumber"
                                    (unitChange)="updatePart(part, $event)"
                                    [incrementBy]="1"
                                    [canEdit]="true"
                                    [smallWidth]="true"
                                    [hasError]="
                                       preventTaskCompletionInsufficientParts &&
                                       partsUnderStocked.has(part.partID)
                                    "
                                 />
                              </div>

                              <div class="part-info-wrapper">
                                 @if (unitOfMeasureService.isFeatureEnabled()) {
                                    <div
                                       [limUiTooltip]="
                                          part.partID | partUnitOfMeasure: 'singular'
                                       "
                                    >
                                       {{ part.partID | partUnitOfMeasure: "short" }}
                                    </div>
                                 }
                                 <div class="part-name-number-wrapper">
                                    <span class="bold">
                                       {{ part.partName }}
                                       @if (part.partNumber) {
                                          <span class="part-name-span">
                                             -
                                             <span [limbleHtml]="part.partNumber"></span>
                                          </span>
                                       }
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  }
               </div>
            </lim-ui-panel>
         }

         <!-- CheckIn section -->
         @if (task() && tools && tools.length && assetCheckInOut && featureAssetTools) {
            <lim-ui-panel>
               <div class="complete-task-panel-container">
                  <label class="control-label">
                     {{ lang().CheckIn }}
                     <b>{{ lang().Tools }}</b>
                     {{ lang().fromThisTask }}
                  </label>
                  <div class="complete-task-panel-column">
                     <div class="form-group">
                        @for (toolData of tools; track toolData) {
                           <task-tool
                              [mostRecentRequest]="toolData.mostRecentRequest"
                              [tool]="toolData.tool"
                              [checklistID]="checklistID()"
                              [instance]="true"
                           />
                        }
                     </div>
                  </div>
               </div>
            </lim-ui-panel>
         }

         <!-- WhatBillingCategoryWouldYouLikeToUse section -->
         @if (task() && !multiWork && categories?.length > 0 && featureLaborCategories) {
            <lim-ui-panel (click)="laborCategoriesError.set(false)">
               <div class="complete-task-panel-container">
                  <label
                     class="control-label"
                     [limbleHtml]="lang().WhatBillingCategoryWouldYouLikeToUse"
                  >
                  </label>

                  <div class="complete-task-panel-column">
                     <div
                        class="label-form-group"
                        [ngClass]="{ 'has-error': laborCategoriesError() }"
                     >
                        <label class="control-label bold"
                           >{{ lang().LaborCategories }}<span class="red-color">*</span>
                        </label>
                        <lim-ui-form-dropdown-input
                           [inputString]="selectedCategoryName"
                           [hasError]="laborCategoriesError()"
                        >
                           <ng-container menu>
                              @for (category of categories; track category) {
                                 <lim-ui-dropdown-text-item
                                    [displayString]="category.categoryName"
                                    (click)="setCategory(category)"
                                    dataLogLabel="completeTask-laborCategoryDropdownItem"
                                 />
                              }
                           </ng-container>
                        </lim-ui-form-dropdown-input>
                     </div>
                  </div>
               </div>
            </lim-ui-panel>
         }

         @let requestorTask = task();
         <!-- IfDesiredSendNotesToRequestionWhichWeWillEmailTo section -->
         @if (task() && requestorTask && showNotesToRequestor(requestorTask)) {
            <lim-ui-panel (click)="notesToRequestorError.set(false)">
               <div class="complete-task-panel-container">
                  <label
                     class="control-label completion-notes-label"
                     [ngClass]="{ redColor: notesToRequestorError() }"
                  >
                     @if (customerRequiresNotesToRequestor === 1) {
                        <span
                           [limbleHtml]="
                              lang().PleaseIncludeCompletionNoteshereToShareWith
                           "
                        ></span>
                        <span class="bold">{{ task()?.checklistEmailCN }}</span>
                        <span>({{ lang().required }})</span>
                     }

                     @if (customerRequiresNotesToRequestor !== 1) {
                        <span
                           [limbleHtml]="lang().IncludeAnyCompletionNotesHeretoShareWith"
                        ></span>
                        <span class="bold">{{ task()?.checklistEmailCN }}</span>
                        <span>({{ lang().optional }})</span>
                     }
                  </label>
                  <div class="notes-to-the-requestor-wrapper">
                     <div
                        [(ngModel)]="notesToTheRequestor"
                        class="limble-content-editable-class"
                        limbleContentEditable
                     ></div>
                     <comment-files
                        [uploadObject]="uploadObject"
                        [files]="files"
                        icon="plus"
                        label="AddPictureDocument"
                        class="comment-files-class"
                     />
                  </div>
               </div>
            </lim-ui-panel>
         }
      </lim-ui-modal-body>
      <lim-ui-basic-modal-footer
         [successButtonDisabled]="processing()"
         successButtonText="{{ lang().Complete }}"
         (successClick)="submit()"
         dataLogSubmitLabel="completeTask-submitCompleteTask"
      />
   }
</lim-ui-modal>

<ng-template #startPopover>
   <upsell-popover
      [message]="lang().FeatureDowntimeTrackingPopoverMessage"
      [pricingLinkText]="lang().LearnMore"
   />
</ng-template>
