<ng-container *translate="let t">
   <div class="merge-fields-row header">
      <div class="location-based-fields">
         <p class="text-with-hint">
            <span>{{ t("LocationBasedFieldsFrom") }}:</span>
            <b>{{ selectedLocation()?.locationName }}</b>
            <lim-ui-icon
               icon="circleQuestionRegular"
               iconSize="small"
               limUiTooltip="{{ t('MergeLocationBasedFieldsHint') }}"
            />
         </p>
      </div>
      <div class="merge-icon-holder">
         <!-- no icon here -->
      </div>
      <div class="template-fields">
         <p class="text-with-hint">
            <span>{{ t("FieldsInThisTemplate") }}</span>
            <lim-ui-icon
               icon="circleQuestionRegular"
               iconSize="small"
               limUiTooltip="{{ t('FieldsInThisTemplateHint') }}"
            />
         </p>
      </div>
   </div>

   @for (templateField of templateFields; track templateField.fieldID) {
      <div class="merge-fields-row">
         <div class="location-based-fields">
            <e-multi-select
               [options]="getLocationFieldsGroup(templateField)"
               [placeholder]="t('SelectMergeAssetFieldsPlaceholder')"
               [optionLabel]="'fieldName'"
               [filters]="true"
               [optionDisabled]="'disabled'"
               [ngModel]="selectedFields[templateField.fieldID] ?? []"
               (change)="onMultiselectChange($event, templateField.fieldID)"
               data-log="assetTemplatesApplyTemplateFlow-mergeAssetFields-clickSelectAssetFieldsToMerge"
            />
         </div>
         <div class="merge-icon-holder">
            <lim-ui-icon icon="merge" iconSize="small" />
         </div>
         <div class="template-fields">
            <div class="lim-ui-list-item">
               <lim-ui-icon
                  [icon]="getFieldIcon(templateField.fieldTypeID) ?? '' | iconAlias"
                  iconSize="small"
               />
               <span>{{ templateField.fieldName }}</span>
            </div>
         </div>
      </div>
   }
</ng-container>
