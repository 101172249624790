import { describeUnitCategory } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/describe-unit-category";
import { UnitCategories } from "src/app/parts/unit-of-measure/unit.types";

export const weightDescription = describeUnitCategory({
   category: UnitCategories.Weight,
   detailsMap: {
      gram: {
         singular: "gram",
         short: "g",
         conversionFactorToSIUnit: 1,
      },
      ounce: {
         singular: "ounce",
         short: "oz",
         conversionFactorToSIUnit: 28.3495,
      },
      pound: {
         singular: "pound",
         short: "lb",
         conversionFactorToSIUnit: 453.592,
      },
      ton_us: {
         singular: "ton_us",
         short: "ton",
         conversionFactorToSIUnit: 907185,
      },
      kilogram: {
         singular: "kilogram",
         short: "kg",
         conversionFactorToSIUnit: 1000,
      },
      milligram: {
         singular: "milligram",
         short: "mg",
         conversionFactorToSIUnit: 0.001,
      },
      metric_ton: {
         singular: "metric_ton",
         short: "tonne",
         conversionFactorToSIUnit: 1000000,
      },
   },
});
