import { inject, Injectable } from "@angular/core";
import { ModalService } from "@limblecmms/lim-ui";
import type { WidgetViewType } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import type { ChartSegment } from "src/app/dashboards/widgets/chart-segment/chart-segment.models";
import type {
   RequestFilter,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import { TasksListModalComponent } from "src/app/tasks/components/shared/components";
import {
   type TasksDataViewerOptions,
   TasksDataViewerOptionsFactoryService,
} from "src/app/tasks/components/shared/components/tasks-data-viewer";
import type { TaskViewTypes } from "src/app/tasks/components/shared/services/task-column-definitions-factory";
import type { TaskStatus } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";

@Injectable({ providedIn: "root" })
export class TasksModalsService {
   private readonly modalService = inject(ModalService);
   private readonly tasksDataViewer = inject(TasksDataViewerOptionsFactoryService);

   /**
    * Opens the Tasks List modal, and its only functionality is to pass the correct options.
    *
    * @param type - The type of the Task  list to showView.
    * @param requestFilters  - This is optional since it is only needed in cases the modal is open from a widget
    * @param segment - This is used for modals that are open for a segment and provides data related to it
    */
   public open(
      type: TaskViewTypes,
      requestFilters?: Array<RequestFilter>,
      segment?: ChartSegment,
      optionsOverrides?: Partial<TasksDataViewerOptions>,
      viewedAs?: WidgetViewType,
   ) {
      const options = this.tasksDataViewer.getOptions({
         type,
         viewedAs,
         segment,
         requestFilters,
         optionsOverrides: { ...optionsOverrides },
      });

      const instance = this.modalService.open(TasksListModalComponent);
      instance.setInput("options", options);
   }

   public formatToExtraFilters(
      requestOptions: Partial<RequestOptions<RequestFilter>>,
      tasksStatuses: TaskStatus[],
   ): Array<RequestFilter> {
      const extraFilters = Array<RequestFilter>();
      extraFilters.push({ statuses: tasksStatuses });

      Object.entries(requestOptions.filters ?? {}).forEach(([key, value]) => {
         extraFilters.push({ [key]: value });
      });

      return extraFilters;
   }
}
