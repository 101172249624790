import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed, input, signal } from "@angular/core";
import {
   IconComponent,
   ModalService,
   LoadingAnimationComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import type { FilePond } from "filepond";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AndroidFileUploadMenuComponent } from "src/app/mobile/files/android-file-upload-menu/android-file-upload-menu.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { AndroidFeatureAvailabilityService } from "src/app/shared/services/mobile-native-device-info/android-feature-availability/android-feature-availability.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "image-tile",
   templateUrl: "./imageTile.element.component.html",
   styleUrls: ["./imageTile.element.component.scss"],
   imports: [
      NgClass,
      IconComponent,
      TooltipDirective,
      LoadingAnimationComponent,
      AndroidFileUploadMenuComponent,
   ],
})
export class ImageTile implements OnInit {
   private readonly manageFiles = inject(ManageFiles);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);
   private readonly androidFeatureAvailabilityService = inject(
      AndroidFeatureAvailabilityService,
   );

   public uploadObject = input.required<any>(); // TODO(FORGE-125): Replace with FileUploaderModel type
   @Input() public image;
   @Input() public canEdit: boolean | undefined;
   @Input() public type:
      | "table-view"
      | "task-view"
      | "extra-small"
      | "small"
      | "medium-small"
      | "medium"
      | undefined;
   public crossOriginAnonymous: boolean;
   public uploading: boolean;
   public axios = axios;

   protected isAndroidMenuNeeded = signal(false);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      //includes a rather ugly test to see if user in on the dreaded ie to prevent a chrome bug which doesn't like grabbing cross-origin images after grabbing them normally
      this.crossOriginAnonymous = /Chrome|FireFox/.test(window.navigator.userAgent);
      this.uploading = false;
      this.setIsAndroidMenuNeeded();
   }

   public ngOnInit() {
      this.uploadObject().editOnOpen = this.uploadObject().editOnOpen ?? false;
      this.uploadObject().errorHandler = (errorMsg) => {
         this.alertService.addAlert(errorMsg, "danger", 3000);
      };

      this.uploadObject().uploadCall = async (posturl, formD) => {
         return this.axios.post(posturl, formD);
      };

      if (!this.uploadObject().deleteData) {
         this.uploadObject().deleteData = {};
      }

      if (this.uploadObject().deleteCall === undefined) {
         this.uploadObject().deleteCall = async (deleteurl, formD) => {
            return this.axios.post(deleteurl, formD);
         };
      }
   }

   //opens a doka instance -> no permissions here as if you are on the build tasks page you should have permission to edit the files.
   public async openEditor() {
      if (!this.canEdit) {
         throw new Error("User does not have permission to edit an image!");
      }
      if (!this.image) {
         throw new Error("No image to open!");
      }
      const doka = await this.manageFiles.createImageEditor(this.image.getURL);
      //when the user clicks confirm this deletes the old version of the file, and uploads the edited file
      //has to convert doka's output to formdata for uploading and play with the fileName a little bit so the names
      //don't get longer each time the file is edited
      doka.onconfirm = async (output) => {
         this.uploading = true;
         this.uploadObject().deleteData.fileName = this.image.fileName;

         const answer = await this.uploadObject().deleteCall(
            this.uploadObject().deleteurl,
            this.uploadObject().deleteData,
         );

         if (typeof this.uploadObject().deleteSuccess === "function") {
            this.uploadObject().deleteSuccess(answer);
         }
         const formData = new FormData();
         formData.append(
            "myfile",
            output.file,
            this.image.fileName.replace(/\d\d\d\d-/, ""),
         );
         const response = await this.uploadObject().uploadCall(
            this.uploadObject().posturl,
            formData,
         );
         this.uploadObject().uploadComplete(response.data);
         this.uploading = false;
      };
   }

   public openViewer() {
      if (!this.image) {
         throw new Error("No image to open!");
      }
      //override in case they pass in that they want to open editor when they go to view a Image
      if (this.uploadObject().editOnOpen && this.canEdit) {
         this.openEditor();
         return;
      }
      this.manageFiles.createImageViewer(this.image.getURL);
   }

   public uploadFromGallery(): void {
      this.upload(this.manageFiles.createImageUploader);
   }

   public uploadFromCamera(): void {
      this.upload(this.manageFiles.createCameraUploader);
   }

   private upload(uploader: FilePond): void {
      if (!this.canEdit) {
         throw new Error("User does not have permission to upload an image!");
      }
      const uploading = () => {
         this.uploading = true;
      };
      const uploaded = () => {
         this.uploading = false;
      };
      this.manageFiles.setUploadServerSettings(
         uploader,
         this.uploadObject(),
         uploaded,
         uploading,
      );
      uploader.browse();
   }

   public async deleteFile() {
      if (!this.canEdit) {
         throw new Error("User does not have permission to delete an image!");
      }
      if (!this.image) {
         throw new Error("No image to delete!");
      }
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().AreYouSureYouWouldLikeToDeleteThisFile}<br /><br />${this.image.fileName}<br /><br /><b><span class="red-color">${this.lang().ThisActionCanNotBeUndone}</span></b><br />`,
            title: this.lang().DeleteFile,
         },
      };
      const result = await instance.result;
      if (result != 1) return;

      const answer = await this.uploadObject().deleteCall(
         this.uploadObject().deleteurl,
         this.uploadObject().deleteData,
      );
      if (typeof this.uploadObject().deleteSuccess === "function") {
         this.uploadObject().deleteSuccess(answer);
      }
   }

   private async setIsAndroidMenuNeeded(): Promise<void> {
      const isFileUploadMenuAvailable =
         await this.androidFeatureAvailabilityService.hasFileUploadMenu();

      this.isAndroidMenuNeeded.set(isFileUploadMenuAvailable);
   }
}
