import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, type Observable } from "rxjs";
import type {
   AssetFieldDefinition,
   AssetFieldScopeType,
} from "src/app/assets/services/asset-field.types";
import type {
   ListResponse,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import { environment } from "src/environments/environment";

const FIELD_DEFINITIONS_URL = "assets/fields";

@Injectable({
   providedIn: "root",
})
export class AssetFieldDefinitionApiService {
   private readonly http = inject(HttpClient);

   public getList(
      requestOptions: Partial<
         RequestOptions<{
            scopeType?: AssetFieldScopeType;
            locationID?: number;
         }>
      >,
   ): Observable<Partial<ListResponse<AssetFieldDefinition>>> {
      let params = new HttpParams();

      if (requestOptions.filters?.scopeType) {
         params = params.set("scopeType", requestOptions.filters.scopeType);
      }
      if (requestOptions.filters?.locationID) {
         params = params.set("locationID", requestOptions.filters.locationID.toString());
      }

      return this.http
         .get<AssetFieldDefinition[]>(
            `${environment.servicesURL()}/${FIELD_DEFINITIONS_URL}/`,
            {
               withCredentials: true,
               params,
            },
         )
         .pipe(
            map((definitions) => {
               return {
                  data: definitions,
                  total: definitions.length,
               };
            }),
         );
   }
}
