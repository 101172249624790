<lim-ui-modal
   [showWatermark]="true"
   [small]="true"
   cdkTrapFocus
   #modal
   role="dialog"
   autofocus
   aria-labelledby="addPurchasableHeader"
>
   <lim-ui-basic-modal-header
      [title]="modalTitle()"
      (closeModal)="close()"
      id="addPurchasableHeader"
   />
   <lim-ui-modal-body [scrollable]="true">
      <lim-ui-panel>
         @let $orderUnits = orderUnits();
         @if ($orderUnits === null) {
            <lim-ui-loading-animation />
         } @else {
            <div class="panel-container">
               <div class="form-group">
                  <div class="label">
                     <label class="control-label" for="purchasableName">
                        {{ i18n().t("Name") }}
                        <span class="required-field">*</span>
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           [limUiTooltip]="i18n().t('PurchasableNameToolTip')"
                        />
                     </label>
                  </div>
                  <input
                     #purchasableName
                     id="purchasableName"
                     type="text"
                     maxlength="100"
                     [value]="name()"
                     (input)="updateName($event)"
                     [placeholder]="
                        i18n().t('PurchasableNamePlaceHolder') | stripHtmlTags
                     "
                     focusAuto
                  />
               </div>
               <div class="form-group">
                  <label class="control-label" for="searchPurchasableOrderUnit">
                     {{ i18n().t("PurchasableOrderUnit") }}
                     <span class="required-field">*</span>
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        [limUiTooltip]="i18n().t('PurchasableOrderUnitToolTip')"
                     />
                  </label>
                  <lim-ui-form-dropdown-input
                     id="searchPurchasableOrderUnit"
                     [inputString]="orderUnit()?.short() ?? ''"
                  >
                     <div menu>
                        <lim-ui-search-box
                           [useDropdownStyling]="true"
                           [(searchVal)]="orderUnitSearchQuery"
                           [placeholder]="i18n().t('Search')"
                        />
                        <lim-ui-dropdown-divider />

                        @if ($orderUnits.length === 0) {
                           <span class="no-search-results-text">
                              {{ i18n().t("NoMatchingResultsFound") }}
                           </span>
                        } @else {
                           @for (orderUnit of $orderUnits; track orderUnit.id) {
                              <lim-ui-dropdown-text-item
                                 [displayString]="orderUnit.formatted()"
                                 [outputString]="orderUnit.formatted()"
                                 (itemSelectedEvent)="updateOrderUnit(orderUnit)"
                                 role="option"
                              />
                           }
                        }
                        <lim-ui-dropdown-item [closeDropdownOnClick]="true">
                           <lim-ui-alert alertType="info">
                              <span
                                 >{{ addUnitHelp }}
                                 @if (isSuperUser) {
                                    <a
                                       class="lim-ui-show-on-desktop"
                                       [routerLink]="['/settings/limbleConfig']"
                                       routerLinkActive="active"
                                       >{{ i18n().t("SettingsPage") }}
                                    </a>
                                    <span class="lim-ui-show-on-desktop">{{
                                       i18n().t("AddUnitPartSettings")
                                    }}</span>
                                    <span
                                       class="lim-ui-hide-on-desktop lim-ui-show-on-mobile"
                                       >{{ i18n().t("SettingsPage")
                                       }}{{ i18n().t("AddUnitPartSettings") }}</span
                                    >
                                 }
                              </span>
                           </lim-ui-alert>
                        </lim-ui-dropdown-item>
                     </div>
                  </lim-ui-form-dropdown-input>
               </div>
               @let $purchasableType = purchasableType();
               @let $orderUnit = orderUnit();
               @if ($purchasableType !== null && $orderUnit !== null) {
                  <div class="form-group">
                     <label class="control-label">
                        {{ i18n().t("PurchasableSize") }}
                        <span class="required-field">*</span>
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           [limUiTooltip]="i18n().t('PurchasableSizeToolTip')"
                        />
                     </label>
                     <label class="control-label calculation-dropdown">
                        <span class="unit-conversion">
                           1
                           <unit-label
                              [unitDisplay]="{
                                 name: $orderUnit.singular(),
                                 abbreviation: $orderUnit.short(),
                              }"
                           />
                           =
                        </span>
                        @let $stockUnit = stockUnit();
                        @let $purchasableSizeUnits = sizeUnits();
                        @if ($stockUnit === null || $purchasableSizeUnits === null) {
                           <lim-ui-loading-animation />
                        } @else {
                           @let $stockUnitConversion = stockUnitConversion();
                           @if ($purchasableType === "measured") {
                              @if ($stockUnitConversion !== null) {
                                 <unit-conversion
                                    [stockUnits]="$stockUnitConversion"
                                    [unitDisplay]="{
                                       name: $stockUnit.singular(),
                                       abbreviation: $stockUnit.short(),
                                    }"
                                 />
                              }
                           }
                           @if (
                              $purchasableType === "counted" ||
                              $purchasableType === "counted-and-measured"
                           ) {
                              <input
                                 type="number"
                                 min="0"
                                 step="1"
                                 class="countable-unit-input"
                                 [value]="size()"
                                 (input)="updateSize($event)"
                                 (keydown)="preventNonNumericCharacters($event)"
                              />
                           }
                           @if ($purchasableType === "counted") {
                              <unit-label
                                 class="control-label"
                                 [unitDisplay]="{
                                    name: $stockUnit.singular(),
                                    abbreviation: $stockUnit.short(),
                                 }"
                              />
                           }
                           @if ($purchasableType === "counted-and-measured") {
                              @let $sizeUnit = sizeUnit();
                              @let $isSizeUnitDifferentFromStockUnit =
                                 isSizeUnitDifferentFromStockUnit();
                              @if (
                                 $sizeUnit === null ||
                                 $isSizeUnitDifferentFromStockUnit === null
                              ) {
                                 <lim-ui-loading-animation />
                              } @else {
                                 <div class="unit-calculation">
                                    <lim-ui-form-dropdown-input
                                       class="size-unit-dropdown"
                                       [inputString]="$sizeUnit.short() ?? ''"
                                    >
                                       <div menu role="listbox" cdkFocusRegion>
                                          <lim-ui-search-box
                                             [useDropdownStyling]="true"
                                             [(searchVal)]="sizeUnitSearchQuery"
                                             [placeholder]="i18n().t('Search')"
                                          />
                                          <lim-ui-dropdown-divider />

                                          @let $sizeUnits = sizeUnits();
                                          @if ($sizeUnits === null) {
                                             <lim-ui-loading-animation />
                                          } @else if ($sizeUnits.length === 0) {
                                             <span>
                                                <no-search-results />
                                             </span>
                                          } @else {
                                             @for (
                                                sizeUnit of $sizeUnits;
                                                track sizeUnit.id
                                             ) {
                                                <lim-ui-dropdown-text-item
                                                   (itemSelectedEvent)="
                                                      updateSizeUnit(sizeUnit)
                                                   "
                                                   [displayString]="sizeUnit.formatted()"
                                                   [outputString]="sizeUnit.formatted()"
                                                   role="option"
                                                />
                                             }
                                          }
                                       </div>
                                    </lim-ui-form-dropdown-input>

                                    @if (
                                       $isSizeUnitDifferentFromStockUnit &&
                                       $stockUnitConversion !== null
                                    ) {
                                       <span class="calculated-text">
                                          <unit-conversion
                                             [stockUnits]="$stockUnitConversion"
                                             [unitDisplay]="{
                                                name: $stockUnit.singular(),
                                                abbreviation: $stockUnit.short(),
                                             }"
                                             [enclosed]="true"
                                          />
                                       </span>
                                    }
                                 </div>
                              }
                           }
                        }
                     </label>
                  </div>
               } @else if (editMode() === true) {
                  <lim-ui-loading-animation />
               }
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-secondary-button (click)="close()">
         {{ i18n().t("Cancel") }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button
         (click)="submit()"
         [dataLogLabel]="'manageUnitsOfMeasure_storeAPurchasable'"
      >
         {{ submitButtonText() }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
