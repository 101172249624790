import { NgClass } from "@angular/common";
import {
   inject,
   Component,
   computed,
   viewChild,
   DestroyRef,
   signal,
} from "@angular/core";
import { toSignal, takeUntilDestroyed } from "@angular/core/rxjs-interop";
import type { ModalResult } from "@limblecmms/lim-ui";
import {
   BasicModalHeaderComponent,
   FilterInputComponent,
   IconComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   LimUiModalRef,
   isNativeMobileApp,
   LoadingAnimationComponent,
} from "@limblecmms/lim-ui";
import { from } from "rxjs";
import { PickAssetsHierarchyComponent } from "src/app/assets/components/pickAssetsModal/pick-assets-hierarchy/pick-assets-hierarchy.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageAssetServ } from "src/app/assets/services/manageAssetServ";
import type { Asset } from "src/app/assets/types/asset.types";
import { PickAssetDataLogType } from "src/app/assets/types/pick-assets.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { QrCodeService } from "src/app/shared/components/global/qrCodeButton/qr-code.service";
import { QRCodeButton } from "src/app/shared/components/global/qrCodeButton/qrCodeButton.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import type { HierarchyNode } from "src/app/shared/types/general.types";
import { Lookup } from "src/app/shared/utils/lookup";
import type { PermissionID } from "src/app/users/schemata/users/self/credentials/permission.enum";
import { CredService } from "src/app/users/services/creds/cred.service";

type AssetHierarchyNode = HierarchyNode & {
   assetID?: number;
   uniqueID?: string;
};

@Component({
   selector: "pick-assets",
   templateUrl: "./pickAssets.modal.component.html",
   styleUrls: ["./pickAssets.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SearchAllWrapperComponent,
      SearchBoxComponent,
      FilterInputComponent,
      QRCodeButton,
      NgClass,
      IconComponent,
      SelectionControlsComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
      PickAssetsHierarchyComponent,
      LoadingAnimationComponent,
   ],
})
export class PickAssets implements ModalResult<any> {
   public readonly hierarchy = viewChild(PickAssetsHierarchyComponent);

   public message: string = "";
   public title: string = "";
   public singleLocation: number = 0;
   public selectOne: boolean = false;
   public restrictToCred: boolean = false;
   public iDontKnowOption: boolean = false;
   public deleteOption: boolean = false;
   public allowPickLocation: boolean = false;
   public preselectedAssetIDs: Array<number> = [];
   public hiddenAssetIDs: Array<number> = [];
   public showMoveAssetButton: boolean = false;
   public isMobile: boolean = false;
   public parentTaskAssetID: number = 0;
   public choosingLink: boolean = false;
   public dataLogSelectLabel: PickAssetDataLogType | undefined = undefined;
   public popSelectionOnly: boolean = false;
   public extraCredCheck: PermissionID | null = null;
   public onSubmit: ((assets: any) => Promise<void>) | undefined;

   public locations;
   public assets: Lookup<"assetID", Asset> = new Lookup("assetID");
   public errorMsg;
   public unsure = false;
   public inMobileApp = isNativeMobileApp();
   public searchBar: string = "";
   public useParentTaskAsset;
   public areAssetsLoaded = signal(false);

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAsset = inject(ManageAsset);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly qrCodeService = inject(QrCodeService);
   private readonly manageAssetService = inject(ManageAssetServ);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly manageLang = inject(ManageLang);
   private readonly destroyRef = inject(DestroyRef);
   public readonly modalRef: LimUiModalRef<PickAssets, any> = inject(LimUiModalRef);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly isPlgSetUpWorkOrderEnabled = toSignal(
      from(this.legacyLaunchFlagsService.isEnabled(Flags.PLG_SETUP_WORK_ORDER)),
      { initialValue: false },
   );

   public constructor() {
      this.manageAsset
         .assetsLoaded()
         .pipe(takeUntilDestroyed(this.destroyRef))
         .subscribe(() => {
            this.areAssetsLoaded.set(true);
         });
   }

   protected canAddAssets(): boolean {
      return this.credService.isAuthorized(
         this.singleLocation,
         this.credService.Permissions.AddAssets,
      );
   }

   protected canMoveAssets(): boolean {
      return this.credService.isAuthorized(
         this.singleLocation,
         this.credService.Permissions.ChangeAssetHierarchy,
      );
   }

   protected async openAddAssetModal(): Promise<void> {
      await this.manageAssetService
         .addAssetLogic(this.singleLocation, 0, false, this.searchBar)
         .then((result) => {
            if (result) {
               this.hierarchy()?.buildHierView(true);
            }
         });
   }

   protected async openMoveAssetModal(): Promise<void> {
      const hierarchy = this.hierarchy();
      if (!hierarchy?.selectedAssetNode?.assetID) {
         return;
      }

      const assetToMove = this.manageAsset.getAsset(hierarchy.selectedAssetNode.assetID);
      if (!assetToMove) {
         return;
      }

      await this.manageAssetService.moveAssetLogic(
         assetToMove,
         PickAssetDataLogType.MOVE_ASSET,
      );

      // Reset selected asset node after the asset is moved.
      hierarchy.selectedAssetNode = null;

      // Completely rebuild the hierarchy to reflect the changes.
      hierarchy.buildHierView(true);
   }

   protected scanQrCallback(code: string): void {
      const route = this.qrCodeService.parseUrlString(code);
      let asset: Asset | undefined;

      if (route === undefined) {
         // Try to find asset by searching through names and field values if there is no link.
         // This allows field values like "QR Code number" to work with custom qr codes that are just numbers qr codes attached to the asset field.
         asset = this.qrCodeService.tryGetAssetFromCode(code);
         if (!asset) {
            this.qrCodeService.throwError(code);
            return;
         }
      } else if (route[0] === "mobileAssets") {
         asset = this.manageAsset.getAsset(Number(route[1]));
         this.modalRef.close(asset);
      } else if (route[0] === "problem") {
         asset = this.manageAsset.getAsset(Number(route[3]));
         this.modalRef.close(asset);
      } else {
         this.qrCodeService.throwError(code);
         return;
      }

      if (asset) {
         // Select the asset in the hierarchy
         const assetNode = this.getAssetNode(asset.assetID);
         if (assetNode) {
            assetNode.selected = true;
            // If single selection is enabled, submit immediately
            if (this.selectOne) {
               this.submit();
            }
         }
      } else {
         this.qrCodeService.throwError(code);
      }
   }

   collapseAll = () => {
      this.hierarchy()?.collapseAll();
   };

   clearAssetSelection = () => {
      this.hierarchy()?.clearAssetSelection();
   };

   markAllAssetSelection = () => {
      this.hierarchy()?.markAllAssetSelection();
   };

   selectNonTreeProperty = (property) => {
      if (this.hierarchy) {
         this.hierarchy()?.clearAssetSelection();
         if (this[property] == true) {
            this.submit();
         }
      }
      this[property] = true;
   };

   close = () => {
      this.modalRef.close(0);
   };

   public async submit() {
      const hierarchy = this.hierarchy();
      if (hierarchy === undefined) return;
      const selectedAsset = hierarchy.getSelectedUnsureAssetUnderLocation();
      this.errorMsg = false;
      let arr: any = [];
      if (this.iDontKnowOption == true) {
         if (selectedAsset?.selected && this.isPlgSetUpWorkOrderEnabled()) {
            this.modalRef.close({
               assetID: selectedAsset.assetID ?? 0,
               locationID: selectedAsset.locationID,
            });
            return;
         }

         if (this.unsure == true) {
            this.modalRef.close("unsure"); //they aren't sure so close it out that way.
            return;
         }
      }

      const locationsIndex = this.manageLocation.getLocationsIndex();

      if (this.useParentTaskAsset) {
         this.modalRef.close({ useParentTaskAsset: true });
         return;
      }

      this.assets = hierarchy.originalAssets;

      for (const asset of this.assets) {
         const assetNode = this.getAssetNode(asset.assetID);
         if (!assetNode) {
            continue;
         }
         if (assetNode.selected == true) {
            arr.push({
               assetID: asset.assetID,
               assetName: asset.assetName,
               locationName: locationsIndex[asset.locationID].locationName,
               locationID: asset.locationID,
            });
         }
      }

      if (arr.length == 0) {
         //couldn't find a specific asset so check if locations are selected
         for (const key in this.locations) {
            if (this.locations[key].selected == true) {
               //if they just selected a location it is going to simply say that no
               arr.push({
                  assetID: 0,
                  assetName: locationsIndex[this.locations[key].locationID].locationName,
                  locationName:
                     locationsIndex[this.locations[key].locationID].locationName,
                  locationID: this.locations[key].locationID,
               });
            }
         }
      }

      if (arr.length == 0) {
         this.errorMsg = this.lang().PleasePickAtLeast1Asset;
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
         return;
      }

      if (
         this.selectOne == true &&
         this.unsure == false &&
         this.popSelectionOnly == true
      ) {
         //convert it to just the asset object
         if (arr[0].assetID > 0) {
            arr = this.manageAsset.getAsset(arr[0].assetID);
         } else {
            arr = arr[0];
         }

         const assetNode = this.getAssetNode(arr.assetID);

         if (assetNode) {
            this.manageAsset.popAsset(arr.assetID);
         } else {
            if (this.onSubmit) {
               await this.onSubmit(arr);
            }
            this.modalRef.close(arr);
         }
         return;
      }

      if (this.selectOne == true && this.unsure == false) {
         //convert it to just the asset object
         if (arr[0].assetID > 0) {
            arr = this.manageAsset.getAsset(arr[0].assetID);
         } else {
            arr = arr[0];
         }
      }

      if (this.onSubmit) {
         await this.onSubmit(arr);
      }

      this.modalRef.close(arr);
   }

   public runPopAsset = (assetNode: AssetHierarchyNode) => {
      if (!assetNode.assetID) {
         this.alertService.addAlert(this.lang().CannotViewThisAsset, "warning", 6000);
         return;
      }
      this.manageAsset.popAsset(assetNode.assetID);
   };

   private getAssetNode(assetID: number): AssetHierarchyNode | undefined {
      return this.hierarchy()?.getAssetNode(assetID);
   }
}
