import type { OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   ChangeDetectorRef,
   Component,
   forwardRef,
   computed,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PaginationComponent,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { FilterArrayPipe } from "src/app/shared/pipes/filterArray.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

//the purpose of this modal is to show all of the schedules of 7 and 8 and when they will be scheduled next
@Component({
   selector: "view-list-of-schedules-type-7",
   templateUrl: "./viewListOfSchedulesType7.modal.component.html",
   styleUrls: ["./viewListOfSchedulesType7.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      FormsModule,
      IconComponent,
      forwardRef(() => AssetParentList),
      TooltipDirective,
      PaginationComponent,
      LoadingAnimationComponent,
      FilterArrayPipe,
      SliceArray,
   ],
})
export class ViewListOfSchedulesType7 implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public currencySymbol;
   public task: TaskTemplateEntity | undefined;
   public reoccurID;
   public showLoading;
   public schedules;
   public tasksWatchVarSub;
   public assetsWatchVarSub;
   public page = 1;
   public itemsPerPage = 20;
   public searchBar = "";

   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.message = this.resolve.message || false;
      this.title = this.resolve.title;
      this.task = this.resolve.task;
      this.reoccurID = this.resolve.reoccurID;
      this.showLoading = true;

      this.tasksWatchVarSub = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.buildData();
         },
      );

      this.assetsWatchVarSub = this.manageAsset.assetState().subscribe(() => {
         this.buildData();
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.assetsWatchVarSub,
         this.tasksWatchVarSub,
      ]);
   }

   pageChanged = () => {
      this.ref.detectChanges();
   };

   buildData = () => {
      this.manageTask
         .getTasksMultiAssetsSchedule78NextDue(this.reoccurID)
         .then((answer) => {
            if (answer.data.success == true) {
               this.schedules = [];

               for (const key in answer.data.schedules) {
                  this.schedules.push(answer.data.schedules[key]);
               }

               this.schedules.forEach((schedule) => {
                  const asset = this.manageAsset.getAsset(schedule.assetID);
                  const field = this.manageAsset.getField(schedule.fieldID);
                  schedule.valueEndWithIncrement =
                     Number(schedule.valueEnd) + Number(schedule.increment);
                  delete schedule.valueEnd;
                  if (asset) {
                     schedule.asset = asset;
                  }
                  if (field) {
                     schedule.field = field;
                  }
               });

               this.schedules = orderBy(this.schedules, "asset.assetName");

               this.showLoading = false;
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   public async popTask(checklistID: number): Promise<void> {
      const task = await this.manageTask.getTask(checklistID);
      if (!task) {
         return;
      }

      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               title: task.checklistName,
               message: "",
            },
         },
      };
   }

   viewAsset = (asset: Asset) => {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   close = () => {
      this.modalInstance.close(0);
   };
}
