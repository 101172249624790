import { Injectable } from "@angular/core";
import type {
   LocationDetail,
   LocationSummary,
} from "src/app/locations/models/location-queries.models";
import type { LocationResponse } from "src/app/locations/models/locations-api.models";
import { TempCurrencyCode } from "src/app/purchasing/currency/utils/temp-currency-code";

@Injectable({ providedIn: "root" })
export class LocationResponseMapperService {
   public toLocationSummary(location: LocationResponse): LocationSummary {
      return {
         id: location.locationID,
         name: location.locationName ?? "",
         regionID: location.regionID,
      };
   }

   public toLocationDetail(location: LocationResponse): LocationDetail {
      return {
         ...this.toLocationSummary(location),
         currencyCode: this.parseCurrencyCode(location.currencyCode, location.currencyID),
         phoneNumber: location.locationPhone,
         coordinates: this.parseCoordinates(location.geoLocation),
         address1: location.locationAddress,
         address2: location.locationAddress2,
         metaField1: location.metaField1,
         metaField2: location.metaField2,
         metaField3: location.metaField3,
         hoursOfOperationPerWeek: location.operationHoursPerWeek ?? 40,
         workTookTooLongAssignmentProfileID: location.workTookTooLongAssignmentProfileID,
         workTookTooLongAssignmentUserID: location.workTookTooLongAssignmentUserID,
      };
   }

   private parseCoordinates(geoLocationJson: string | null): [number, number] | null {
      if (geoLocationJson === null) return null;

      try {
         const geoLocationObject = JSON.parse(geoLocationJson);
         const coordinates = geoLocationObject.geometry.coordinates;
         return [coordinates[0], coordinates[1]];
      } catch {
         return null;
      }
   }

   private parseCurrencyCode(
      currencyCode: string | null | undefined,
      currencyID: number | null | undefined,
   ): string | null {
      if (currencyCode !== undefined) return currencyCode;

      if (currencyID === undefined || currencyID === null) return null;

      return TempCurrencyCode[currencyID];
   }
}
