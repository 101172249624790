import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root",
})
export class SortingService {
   /**
    * Sorts a list of items alphabetically by a given tag name.
    * @param list The list of items to sort.
    * @param propertyName The name of the item's property to sort by.
    * @returns The sorted list of items.
    */
   public alphabeticalSort<T>(list: Array<T>, propertyName: keyof T): Array<T> {
      return list.sort((item1, item2) => {
         // eslint-disable-next-line no-nested-ternary -- Bryan approved
         return item1[propertyName] < item2[propertyName]
            ? -1
            : item1[propertyName] > item2[propertyName]
              ? 1
              : 0;
      });
   }
}
