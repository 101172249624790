import { computed, signal } from "@angular/core";

export class UpdateSignaler {
   public readonly updated = computed(() => this._updated());

   private readonly _updated = signal<number | null>(null);

   public trigger(): void {
      const count = this.updated() ?? 0;
      this._updated.set(count + 1);
   }
}
