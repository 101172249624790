import { computed, Injectable, type Signal } from "@angular/core";
import { DEFAULT_CURRENCY } from "src/app/purchasing/currency/utils/default-currency-code";

@Injectable({ providedIn: "root" })
export class CurrencyDisplayService {
   public evaluateSignal(
      baseCurrency: Signal<string | undefined>,
      specialCurrencies: Signal<Array<string | null> | string | null | undefined>,
      isMultiCurrencyEnabled: Signal<boolean | null>,
   ): Signal<string> {
      return computed(() => {
         const base = baseCurrency();
         const special = specialCurrencies();
         const enabled = isMultiCurrencyEnabled();
         if (base === undefined || special === undefined || enabled === null) {
            return DEFAULT_CURRENCY;
         }

         const specialArray = Array.isArray(special) ? special : [special];

         return this.evaluate(base, specialArray, enabled);
      });
   }

   public evaluate(
      baseCurrency: string,
      specialCurrencies: Array<string | null>,
      isMultiCurrencyEnabled: boolean,
   ): string {
      if (!isMultiCurrencyEnabled) {
         return baseCurrency;
      }

      if (specialCurrencies.length === 0) {
         return baseCurrency;
      }

      const specialCurrenciesNullsToBase = specialCurrencies.map(
         (special) => special ?? baseCurrency,
      );

      if (
         specialCurrenciesNullsToBase.every(
            (currency) => currency === specialCurrenciesNullsToBase[0],
         )
      ) {
         return specialCurrenciesNullsToBase[0];
      }

      return baseCurrency;
   }
}
