<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      [title]="title"
      (closeModal)="close()"
      id="taskOptionsAdvancedSettings"
   />
   <lim-ui-modal-body>
      @if (errorMsg) {
         <lim-ui-alert [title]="lang().Warning" alertType="warning">
            <span [limbleHtml]="errorMsg"></span>
         </lim-ui-alert>
      }
      <lim-ui-info-panel>
         <div class="form-group">
            <div class="control-label">
               <label [limbleHtml]="lang().AddCustomTag"></label>
               <lim-ui-dropdown>
                  <div button class="text-btn">{{ addCustomTagEventStr }}</div>
                  <ng-container menu>
                     <lim-ui-dropdown-text-item
                        (click)="setCustomTagToAddEvent(0)"
                        [displayString]="
                           lang().WhenThisTaskIsCompletedAdvOptSettingsNoHTML
                        "
                     />
                     <lim-ui-dropdown-text-item
                        (click)="setCustomTagToAddEvent(1)"
                        [displayString]="
                           lang().WhenThisInstructionIsChangedAdvOptSettingsNoHTML
                        "
                     />
                  </ng-container>
               </lim-ui-dropdown>
            </div>
            <lim-ui-dropdown>
               @if (!customTagToAdd) {
                  <div button class="text-btn">
                     {{ lang().PickCustomTag }}
                  </div>
               }
               @if (customTagToAdd) {
                  <div button class="text-btn">
                     {{ customTagToAdd }}
                  </div>
               }
               <custom-tag-list menu [data]="customTagListObjForToAdd" />
            </lim-ui-dropdown>
         </div>

         <div class="form-group">
            <div class="control-label">
               <label [limbleHtml]="lang().RemoveCustomTag"></label>
               <lim-ui-dropdown>
                  <div button class="text-btn">{{ removeCustomTagEventStr }}</div>
                  <ng-container menu>
                     <lim-ui-dropdown-text-item
                        (click)="setCustomTagToRemoveEvent(0)"
                        [displayString]="
                           lang().WhenThisTaskIsCompletedAdvOptSettingsNoHTML
                        "
                     />
                     <lim-ui-dropdown-text-item
                        (click)="setCustomTagToRemoveEvent(1)"
                        [displayString]="
                           lang().WhenThisInstructionIsChangedAdvOptSettingsNoHTML
                        "
                     />
                  </ng-container>
               </lim-ui-dropdown>
            </div>
            <lim-ui-dropdown>
               @if (!customTagToRemove) {
                  <div button class="text-btn">
                     {{ lang().PickCustomTag }}
                  </div>
               }
               @if (customTagToRemove) {
                  <div button class="text-btn">
                     {{ customTagToRemove }}
                  </div>
               }
               <custom-tag-list menu [data]="customTagListObjForToRemove" />
            </lim-ui-dropdown>
         </div>
      </lim-ui-info-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
