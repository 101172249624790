<ng-container *translate="let t">
   <div class="container">
      <label id="page-size-select-label">{{ t("itemsPerPage") }}</label>
      <e-select
         ariaLabelledBy="page-size-select-label"
         [options]="pageSizeOptions"
         [ngModel]="pageSize()"
         (ngModelChange)="onPageSizeChange($event)"
      />
   </div>
</ng-container>
