<div class="interval-settings-container">
   <div class="section">
      <div class="section-title">{{ i18n().t("RepeatEvery") }}</div>
      <div class="number-input-container">
         <lim-ui-number-input-with-buttons
            [incrementBy]="1"
            [(unit)]="selectedInterval"
            min="0"
            [canEdit]="true"
         />
      </div>
   </div>
   <div class="section">
      <div class="section-title">
         {{ i18n().t("SelectField") }}
      </div>
      <field-selection
         [recurrence]="recurrence()"
         [masterField]="selectedField()"
         (field)="selectedField.set($event)"
         (fieldValue)="selectedFieldValue.set($event)"
      />
      @if (pmSuggestionsFlag() === true) {
         <div class="section">
            <div class="optional-section-title">
               {{ i18n().t("AddExistingFieldFromLocation") }}
            </div>
            <location-field-selection
               [recurrence]="recurrence()"
               [masterField]="selectedField()"
               (field)="selectedField.set($event)"
               (fieldValue)="selectedFieldValue.set($event)"
            />
         </div>
      }
   </div>
   <div class="section">
      <div class="section-title">{{ i18n().t("StartsAt") }}</div>
      <div class="start-time-container">
         <div class="number-input-container">
            <lim-ui-number-input-with-buttons
               [incrementBy]="1"
               [(unit)]="selectedStart"
               min="0"
               [canEdit]="true"
            />
         </div>
         @if (selectedField() !== null) {
            <div>
               {{ selectedField()?.fieldName }}
               @if (selectedFieldValue() !== null) {
                  <span class="current-value-hint">
                     ({{ selectedField()?.fieldName }}'s
                     {{ i18n().t("currentValueIs") }}
                     {{ selectedFieldValue()?.valueContent ?? i18n().t("Unset") }})
                  </span>
               }
            </div>
         }
      </div>
      @if (selectedField() !== null) {
         <div class="hint-text">
            {{ i18n().t("TheFirstTaskWillBeCreatedAt") }}
            <span class="task-created-at"
               >{{ selectedInterval() + selectedStart() }}
               {{ selectedField()?.fieldName }}.</span
            >
         </div>
      }
   </div>
</div>
