import { CommonModule } from "@angular/common";
import { Component, computed, input } from "@angular/core";
import {
   type Aliases as FontAliases,
   IconComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { TagModule } from "primeng/tag";
import type { TagSeverity } from "src/app/shared/empowered/base/tag/tag.component.types";

/**
 * Empowered wrapper component for PrimeNG's Tag component.
 * Provides a consistent interface for using tags in the application.
 */
@Component({
   selector: "e-tag",
   imports: [CommonModule, IconComponent, TagModule, TooltipDirective],
   templateUrl: "./tag.component.html",
   styleUrls: ["./tag.component.scss"],
})
export class TagComponent {
   /** Icon name to display on the tag. */
   public readonly iconName = input<FontAliases>();

   /** Whether the tag should be rounded (i.e. pill-shaped). */
   public readonly rounded = input<boolean>(false);

   /** Severity level of the tag. */
   public readonly severity = input<TagSeverity>();

   /** The tooltip (and aria-label) of the tag. */
   public readonly tooltip = input<string>();

   /** Value to display inside the tag. */
   public readonly value = input.required<string>();

   /** Final/computed tooltip value. */
   protected readonly tooltipFinal = computed<string>(() => {
      const tooltip = this.tooltip();
      if (tooltip) return tooltip;
      return this.value();
   });

   /** Computed class. */
   protected readonly styleClass = computed<TagSeverity | "primary">(() => {
      const severity = this.severity();
      if (!severity) return "primary";
      return severity;
   });
}
