import { BaseSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/base-schema";
import { CustomOrderUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/custom-order-unit-schema";
import { ProvidedOrderUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/provided-order-unit-schema";
import { ProvidedStockUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/provided-stock-unit-schema";
import { z } from "zod";

const CountedAndMeasuredBaseSchema = BaseSchema.extend({
   purchasableSize: z.number(),
   purchasableProvidedSizeUnitID: z.number(),
});

const ProvidedStockProvidedOrderDtoSchema = CountedAndMeasuredBaseSchema.merge(
   ProvidedStockUnitSchema,
).merge(ProvidedOrderUnitSchema);

const ProvidedStockCustomOrderDtoSchema = CountedAndMeasuredBaseSchema.merge(
   ProvidedStockUnitSchema,
).merge(CustomOrderUnitSchema);

export const CountedAndMeasuredPurchasablePurchaseOrderItemDtoSchema = z.union([
   ProvidedStockProvidedOrderDtoSchema,
   ProvidedStockCustomOrderDtoSchema,
]);

export type CountedAndMeasuredPurchasablePurchaseOrderItemDto = z.infer<
   typeof CountedAndMeasuredPurchasablePurchaseOrderItemDtoSchema
>;
