import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { ImageTile } from "src/app/files/components/imageTile/imageTile.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { FileStorageSyncService } from "src/app/lite/local-db/resources/collection/task/file/file.storage.sync.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { assert } from "src/app/shared/utils/assert.utils";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "task-image-field",
   templateUrl: "./taskImage.element.component.html",
   styleUrls: ["./taskImage.element.component.scss"],
   imports: [ImageTile],
})
export class taskImage implements OnInit {
   /**
    *    @deprecated - In the legacy code this was used to find the target task.
    *    In the new code we should pass the Task directly as an input.
    */
   @Input() public taskID: number | undefined;
   @Input() public size:
      | "table-view"
      | "task-view"
      | "extra-small"
      | "small"
      | "medium-small"
      | "medium"
      | undefined;
   @Input() public canEdit: boolean | undefined;

   @Input() public task: Task | TaskTemplateEntity | TaskEntity | undefined;

   public uploadObj;
   public taskImageObj: { getURL: string; fileName: string } | undefined;

   private readonly alertService = inject(AlertService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUser = inject(ManageUser);
   private readonly fileStorageSyncService = inject(FileStorageSyncService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (this.size === undefined) {
         this.size = "small";
      }
      // NOTE: This should be cleanup once the JIT is completely rollout
      if (!this.task) {
         assert(this.taskID);
         this.task = this.manageTask.getTaskLocalLookup(this.taskID);
         assert(this.task);
      }

      if (this.task?.taskImage) {
         this.setImage(this.task.taskImage);
      }
      this.createUploadObj();
   }

   public createUploadObj() {
      assert(this.task);
      this.uploadObj = this.manageUtil.createUploadObjImage();
      this.uploadObj.posturl = `phpscripts/checklistManager.php?action=makeTaskImage&locationID=${this.task.locationID}&checklistID=${this.task.checklistID}`;

      this.uploadObj.deleteurl = `phpscripts/checklistManager.php?action=deleteTaskImage&checklistID=${this.task.checklistID}`;
      this.uploadObj.deleteData = {
         taskID: this.task.checklistID,
      };

      this.uploadObj.uploadComplete = (data) => {
         assert(this.task);
         if (data.failed) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }
         this.task.taskImage = data.fileName;
         this.setImage(data.fileName);
         this.manageTask.incTasksWatchVar();

         this.fileStorageSyncService.syncTaskImageAddition({
            fileName: data.fileName,
            customerID: this.manageUser.getCurrentUser().userInfo.customerID,
            taskID: this.task.checklistID,
         });

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      };

      this.uploadObj.deleteSuccess = (data) => {
         assert(this.task);
         if (data.status != "200") {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }
         this.task.taskImage = null;
         this.taskImageObj = undefined;
         this.manageTask.incTasksWatchVar();

         this.fileStorageSyncService.syncTaskImageDeletion({
            taskID: this.task.checklistID,
         });

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      };
   }

   private setImage(fileName: string) {
      const customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      assert(this.task);
      this.taskImageObj = {
         getURL: `viewFile.php?f=upload-${customerID}/tasks/images/${this.task.checklistID}/${fileName}`,
         fileName: fileName,
      };
   }
}
