import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type {
   PartUsageEntity,
   PartUsageEntityFilters,
} from "src/app/parts/components/shared/part-usage-api-service/part-usage-api.models";
import {
   FlannelApiService,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";

@Injectable({
   providedIn: "root",
})
export class PartUsageApiService extends FlannelApiService<
   PartUsageEntity,
   PartUsageEntityFilters
> {
   private constructor() {
      super("parts/parts-usage");
   }

   /*  eslint-disable typescript/no-unused-vars -- The variables are needed to override */
   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override create(
      body: Partial<PartUsageEntity>,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<PartUsageEntity | null> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override update(
      id: number,
      body: Partial<PartUsageEntity>,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<PartUsageEntity> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override delete(
      id: number,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<PartUsageEntity> {
      throw new Error("Method not implemented.");
   }

   /*  eslint-enable typescript/no-unused-vars  */
}
