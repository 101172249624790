import type { OnDestroy, OnInit, Signal } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitLabelComponent } from "src/app/parts/unit-of-measure/components/unit-label/unit-label.component";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import type { PurchasableSnapshot } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.model";
import { PurchasableSnapshotService } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "po-item-list-short",
   templateUrl: "./poItemListShort.element.component.html",
   styleUrls: ["./poItemListShort.element.component.scss"],
   imports: [
      MinimalIconButtonComponent,
      IconComponent,
      LimbleHtmlDirective,
      UnitLabelComponent,
   ],
})
export class PoItemListShort implements OnInit, OnDestroy {
   @Input() public purchaseOrderItemIDs: Array<number> = [];
   @Input() public dataLogOptions: DataLogEventDefinition | undefined;

   public CID;
   public currencySymbol;
   public showButton;
   public hide;
   public partsWatchVarSub;
   public augmentedPurchaseOrderItems: Array<
      | (PurchaseOrderItem & {
           itemName: Signal<string | undefined | null>;
           itemNumber: Signal<string | undefined | null>;
           purchasableSnapshot: Signal<PurchasableSnapshot | null | undefined>;
        })
      | undefined
   > = [];

   private readonly modalService = inject(ModalService);
   private readonly managePO = inject(ManagePO);
   private readonly manageParts = inject(ManageParts);
   private readonly manageUser = inject(ManageUser);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected readonly purchasableSnapshotService = inject(PurchasableSnapshotService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly isUnitOfMeasureEnabled = this.unitOfMeasureService.isFeatureEnabled;

   public ngOnInit() {
      if (this.isUnitOfMeasureEnabled()) {
         this.unitOfMeasureService.areUnitsInitialized.then(() => {
            this.initializeData();
         });
         return;
      }

      this.initializeData();
   }

   private initializeData() {
      this.augmentedPurchaseOrderItems = this.purchaseOrderItemIDs.map((itemID) => {
         const relatedInfo = this.managePO.getPurchaseOrderItemRelatedInfoSignal(itemID);
         const purchaseOrderItem = this.managePO.getPurchaseOrderItem(itemID);
         assert(purchaseOrderItem?.poID);

         const item = {
            ...purchaseOrderItem,
            poID: purchaseOrderItem.poID,
            itemName: computed(() => relatedInfo()?.itemName),
            itemNumber: computed(() => relatedInfo()?.itemNumber),
            purchasableSnapshot: computed(() =>
               this.purchasableSnapshotService.parseFromPurchaseOrderItem(item),
            ),
         };

         return item;
      });

      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      this.showButton = false;
      if (
         this.augmentedPurchaseOrderItems &&
         this.augmentedPurchaseOrderItems.length > 4
      ) {
         this.hide = true;
         this.showButton = true;
      } else {
         this.hide = false;
         this.showButton = false;
      }
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.partsWatchVarSub);
   }

   popPart = (partID) => {
      const part = this.manageParts.getPart(partID);

      if (part) {
         const instance = this.modalService.open(PopPart);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               dataLogOptions: this.dataLogOptions,
               partID: part.partID,
               locationID: part.locationID,
               data: {
                  restrict: false,
               },
            },
         };
      }
   };
}
