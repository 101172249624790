import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   LimbleHtmlDirective,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PickWhoToDisapprovePOTo } from "src/app/purchasing/pos/pickWhoToDisapprovePOToModal/pickWhoToDisapprovePOTo.modal.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "disapprove-p-o",
   templateUrl: "./disapprovePO.modal.component.html",
   styleUrls: ["./disapprovePO.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      ContenteditableDirective,
      FormsModule,
      AlertComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
   ],
})
export class DisapprovePO implements OnInit {
   public resolve;
   public modalInstance;
   public data;
   public title;
   public errorMsg;
   public text;
   public purchaseOrder: PurchaseOrder | undefined;
   public userStarted: string | undefined;
   public displayName;
   public state;

   private readonly managePO = inject(ManagePO);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.title = this.data.title;
      this.errorMsg = "";
      this.text = "";
      this.purchaseOrder = this.managePO.getPurchaseOrder(this.data.poID);
      if (this.purchaseOrder) {
         this.userStarted = this.managePO.getPurchaseOrderUserStartedName(
            this.purchaseOrder?.poID,
         );
      }
      if (this.userStarted) {
         this.displayName = `${this.lang().Setup} | ${this.userStarted}`;
      }
      this.state = 0;
   }

   close = () => {
      this.modalInstance.close(0);
   };

   changeTarget = () => {
      const code = this.manageUser.getCurrentUser().userInfo.customerBarcodeStr;

      const instance = this.modalService.open(PickWhoToDisapprovePOTo);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: this.lang().WhoWouldYouLikeToSendThePOBackTo,
               code: code,
               poID: this.data.poID,
            },
         },
      };

      instance.result.then((result) => {
         if (result) {
            this.displayName = `${result.name} | ${result.displayName}`;
            this.state = result.order;
         }
      });
   };

   submit = () => {
      if (this.text.length < 1) {
         this.errorMsg = this.lang().PleaseEnterAReason;
         return;
      }
      const obj: any = {};
      obj.newState = this.state;
      obj.text = this.text;
      this.modalInstance.close(obj);
   };
}
