import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { validate } from "src/app/shared/rxjs-operators/validate";
import { z } from "zod";

@Injectable({ providedIn: "root" })
export class LocationsPhpApiService {
   private readonly http = inject(HttpClient);
   private readonly url = `phpscripts/manageLocation.php`;

   public setCurrency({
      currencyID,
      locationID,
   }: {
      currencyID: number;
      locationID: number;
   }): Observable<{ success: true }> {
      return this.http
         .post(
            this.url,
            {
               locationID,
               currencyID,
            },
            {
               params: {
                  action: "setCurrency",
               },
            },
         )
         .pipe(validate(z.object({ success: z.literal(true) })));
   }
}
