import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import type { TaskPriorityCellModel } from "src/app/shared/empowered/base/table/components/cells-renderers/task-priority-cell-renderer/task-priority-cell-renderer.models";
import { PriorityButtonComponent } from "src/app/tasks/components/priority-button/priority-button.component";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "task-priority-cell",
   templateUrl: "./task-priority-cell-renderer.component.html",
   imports: [PriorityButtonComponent],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPriorityCellRendererComponent extends BaseCellRendererComponent<TaskPriorityCellModel> {
   private readonly credService = inject(CredService);

   protected get isSuperUser(): boolean {
      return this.credService.checkCredGlobal(30);
   }

   protected get isTaskCompleted(): boolean {
      return this.cell?.isCompleted ?? false;
   }

   protected get taskId(): number {
      return this.cell?.checklistID ?? 0;
   }

   protected get priorityId(): number | null {
      return this.cell?.priorityID ?? null;
   }

   protected get locationId(): number {
      return this.cell?.locationID ?? 0;
   }
}
