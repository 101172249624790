<lim-ui-modal [showWatermark]="true" [extraLarge]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body [scrollable]="true">
      @if (message) {
         <lim-ui-info-panel>
            <span class="message-area" [limbleHtml]="message"></span>
         </lim-ui-info-panel>
      }
      <lim-ui-panel class="scroll-height-inheritance">
         <div class="search-container">
            <lim-ui-search-all-wrapper>
               <lim-ui-search-box
                  searchBox
                  [(searchVal)]="searchAll"
                  (searchValChange)="updateSearch()"
                  [placeholder]="lang().Search"
                  [focusOnLoad]="true"
               />
               @if (searchAll) {
                  <lim-ui-filter-input
                     searchAllCriteria
                     (clearFilterEvent)="searchAll = ''; updateSearch()"
                     [filterCriteria]="searchAll"
                  >
                     <label inputLabel> {{ lang().SearchFilter }}: </label>
                  </lim-ui-filter-input>
               }
            </lim-ui-search-all-wrapper>
         </div>
         <lim-ui-scroll-container [thin]="true">
            @for (
               po of purchaseOrders
                  | sliceLimbleMap: (page - 1) * itemsPerPage : page * itemsPerPage;
               track po
            ) {
               <div
                  (click)="focusPO(po)"
                  class="lim-ui-list-item lim-ui-selectable-list-item"
                  [ngClass]="{
                     'lim-ui-selected-list-item':
                        purchaseOrderSeletedInfo.get(po.poID)?.selected === true,
                  }"
               >
                  <div class="po-and-pr-wrapper">
                     <div class="po-info">
                        @if (purchaseOrderSeletedInfo.get(po.poID)?.selected === true) {
                           <lim-ui-icon icon="check" iconColor="success" />
                        }
                        <lim-ui-icon icon="filePowerpoint" />
                        {{ lang().PO }} {{ po.poNumber }}
                        @if (searchHints.get(po.poID) && searchAll.length > 1) {
                           <span>
                              -
                              <span
                                 class="orange-color"
                                 [limbleHtml]="searchHints.get(po.poID)"
                              >
                              </span>
                           </span>
                        }
                     </div>
                     <div>
                        @if (showPOsWaitingToBeReceived) {
                           <span>
                              @if (po.poItemIDs.length) {
                                 <po-item-left-list-short
                                    [itemIDs]="po.poItemIDs"
                                    [click]="false"
                                 />
                              }
                           </span>
                        }
                     </div>
                  </div>
               </div>
            }
         </lim-ui-scroll-container>

         @if (purchaseOrders && purchaseOrders.size > itemsPerPage) {
            <lim-ui-pagination
               [maxSize]="5"
               [rotate]="true"
               [(page)]="page"
               [pageSize]="itemsPerPage"
               [collectionSize]="purchaseOrders?.size ?? 0"
            />
         }
         @if (noSearchResults) {
            <span>
               <no-search-results />
            </span>
         }
         @if (purchaseOrders?.size === 0 && !noSearchResults) {
            <div>
               <lim-ui-alert alertType="warning">
                  {{ lang().WhoopsThereAreNoPOsToPickFrom }}
               </lim-ui-alert>
            </div>
         }
         @if (purchaseOrders?.size === 0 && !noSearchResults) {
            <div></div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <div class="modal-footer-buttons">
         @if (startPOCred && allowCreate) {
            <lim-ui-secondary-button
               [icon]="canAddPOs ? 'plus' : 'stars'"
               (click)="createPO()"
               [limUiPopover]="createPOPopover"
               [limUiPopoverType]="'upsell'"
               [limUiPopoverPlacement]="'right'"
               [limUiPopoverHidden]="canAddPOs"
               [disabled]="!canAddPOs"
            >
               {{ lang().CreatePO }}
            </lim-ui-secondary-button>
         }

         <ng-template #createPOPopover>
            <upsell-popover
               [message]="lang().FeatureAddPOPopoverMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>
      </div>
      <div class="modal-footer-end-buttons">
         <lim-ui-primary-button (click)="submit()">{{
            lang().Select
         }}</lim-ui-primary-button>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
