<lim-ui-card [noPadding]="true" class="part-usage-table">
   <header>
      <h3>
         <lim-ui-icon
            icon="circleQuestionRegular"
            limUiTooltip="{{ lang().ViewWhereThisPartWasRecentlyUsed }}"
         />
         {{ lang().RecentUsage }}
      </h3>
      @if (part().totalPartsUsed > 0 && part().partPrice !== null) {
         <p>
            <strong>{{ part().totalPartsUsed }} {{ part().partName }}</strong>
            {{ lang().wereUsedInThisPeriodOfTimeForATotalOf }}
            <strong>
               {{ currencySymbol() }}
               {{ part().totalCostOfPartsUsed | betterNumber: "1.2-2" }}
            </strong>
         </p>
      }
      @if (tasksTotal() === 0 && !isLoading()) {
         <div class="usage-empty">
            {{ lang().ThisPartHasNotBeenUsedDuringTheDateRangeSelected }}
         </div>
      }
   </header>
   <tasks-data-viewer
      [columns]="columns"
      [isLoading]="isLoading()"
      [noSearchResults]="false"
      [tasks]="tasks()"
      [totalTasks]="tasksTotal()"
      [part]="part()"
   />
</lim-ui-card>
