import { PartPurchaseOrderItemDtoSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/part-purchase-order-item.dto";
import { CustomStockUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/custom-stock-unit-schema";
import { ProvidedStockUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/provided-stock-unit-schema";
import { z } from "zod";

const ProvidedStock = PartPurchaseOrderItemDtoSchema.merge(ProvidedStockUnitSchema);
const CustomStock = PartPurchaseOrderItemDtoSchema.merge(CustomStockUnitSchema);

export const AnyPurchasablePartPurchaseOrderItemDtoSchema = z.union([
   ProvidedStock,
   CustomStock,
]);

export type AnyPurchasablePartPurchaseOrderItemDto = z.infer<
   typeof AnyPurchasablePartPurchaseOrderItemDtoSchema
>;
