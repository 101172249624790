import type { RequestParam } from "src/app/shared/services/flannel-api-service";

/**
 * Returns a comma separated string of extra columns that are needed for all the columns
 * to be added to the API request that are needed to  render the column
 * NOTE: The Column type will be removed in the future and only ColumnDef will be used.
 */
export const getExtraColumns = (
   columns: Array<{ extraColumns?: Array<string> }>,
   extraColumns?: Array<string>,
): string => {
   const columnsWithExtraColumns = columns.filter(
      (column) => column.extraColumns && column.extraColumns.length > 0,
   );
   if (columnsWithExtraColumns.length <= 0) {
      return "";
   }

   const mappedColumns = columnsWithExtraColumns
      .map((column) => (column.extraColumns ? column.extraColumns.join(",") : ""))
      .join(",");

   const allColumns =
      extraColumns && extraColumns.length > 0
         ? `${mappedColumns}${mappedColumns ? "," : ""}${extraColumns.join(",")}`
         : mappedColumns;

   return allColumns;
};

/**
 * Returns an object with all the columns that should be exported. e.g. `{ checklistName:  true, checklistDueDate: false }`
 * NOTE: The Column type will be removed in the future and only ColumnDef will be used.
 */
export const getExportableColumns = (
   columns: Array<{ exportableFields?: Array<string> }>,
): Record<string, boolean> => {
   const exportableColumns: Record<string, boolean> = {};

   for (const column of columns) {
      if (column.exportableFields && column.exportableFields.length > 0) {
         for (const field of column.exportableFields) {
            exportableColumns[field] = true;
         }
      }
   }

   return exportableColumns;
};

/**
 * Returns the params for the columns that need to request  params.
 * e.g. the task name column needs to include deleted assets so it will have a param of includeDeletedAssets: true
 * NOTE: The Column type will be removed in the future and only ColumnDef will be used.
 */
export const getParams = (
   columns: Array<{ params?: RequestParam }>,
   otherParams?: RequestParam,
): RequestParam => {
   const columnsWithParams = columns.filter((column) => column.params);
   if (columnsWithParams.length <= 0) {
      return {} satisfies RequestParam;
   }

   const columnParams = columnsWithParams.reduce<RequestParam>((acc, column) => {
      return { ...acc, ...(column.params ?? {}) };
   }, {});
   return { ...columnParams, ...otherParams } satisfies RequestParam;
};
