<lim-ui-icon
   icon="houseChimney"
   limUiTooltip="{{ lang().ThisTaskIsLocatedAt }} {{ taskLocation() }}"
/>
<span>
   <a (click)="popTask()" class="cursor table-link checklist-name">
      <span [limbleHtml]="task().checklistName"></span> - #{{ task().checklistID }}
   </a>
   @if (assetVisible()) {
      <span>
         -
         <a (click)="viewAsset()" class="cursor table-link asset-name">
            <span [limbleHtml]="getTaskAssetName()"></span>
         </a>
      </span>
   }
</span>
