import { inject, computed, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { TaskComment } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskUser } from "src/app/tasks/types/task-user/task-user.types";

@Injectable({ providedIn: "root" })
export class TaskCommentViewModelMapperService {
   protected allUsers: ReturnType<ManageTask["getAllUsers"]>;

   private readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.allUsers = this.manageTask.getAllUsers();
   }

   public getUserDisplayName(comment: TaskComment): string | undefined {
      if (!comment.userID) {
         return undefined;
      }
      const user = this.allUsers.get(comment.userID);
      if (!user) {
         return this.lang().DeletedUser;
      }

      if (this.isSystemUser(user)) {
         return this.lang().System;
      }

      return `${user.userFirstName} ${user.userLastName}`;
   }

   private isSystemUser(user: TaskUser): boolean {
      return Boolean(user.userInternal);
   }
}
