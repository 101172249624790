import { inject, Injectable } from "@angular/core";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import type {
   ParseCustomParams,
   ParseProvidedParams,
} from "src/app/purchasing/pos/purchasable-snapshot/parsers/util/unit-display-parser.service.params";
import type { UnitDisplay } from "src/app/purchasing/pos/purchasable-snapshot/unit-display.model";

@Injectable({ providedIn: "root" })
export class UnitDisplayParserService {
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);

   public parseProvided({
      providedUnitID,
      providedUnitAlias,
   }: ParseProvidedParams): UnitDisplay | null {
      const unit = this.unitOfMeasureService.getUnit({
         id: providedUnitID,
         type: "provided",
      })();

      if (unit === null) return null;

      return {
         name: unit.singular(),
         abbreviation: providedUnitAlias ?? unit.shortDefault,
      };
   }

   public parseCustom({
      customUnitNameShort,
      customUnitNameSingular,
   }: ParseCustomParams): UnitDisplay {
      return {
         name: customUnitNameSingular,
         abbreviation: customUnitNameShort,
      };
   }
}
