import type { ManageParts } from "src/app/parts/services/manageParts";
import type { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import type { PartPurchaseOrderItemDto } from "src/app/purchasing/pos/part-purchase-order-item-dto/part-purchase-order-item.dto";
import {
   IndividualPurchasablePurchaseOrderItemDtoSchema,
   type IndividualPurchasablePurchaseOrderItemDto,
} from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/individual-purchasable-purchase-order-item.dto";
import type { UnitDisplayParserService } from "src/app/purchasing/pos/purchasable-snapshot/parsers/util/unit-display-parser.service";
import type { PurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot-parser.interface";
import type { PurchasableSnapshot } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.model";
import type { UnitDisplay } from "src/app/purchasing/pos/purchasable-snapshot/unit-display.model";
import { assert } from "src/app/shared/utils/assert.utils";

export class IndividualPurchasableSnapshotParser implements PurchasableSnapshotParser {
   public constructor(
      private readonly item: IndividualPurchasablePurchaseOrderItemDto,
      private readonly unitDisplayParserService: UnitDisplayParserService,
      private readonly unitOfMeasureService: UnitOfMeasureService,
      private readonly manageParts: ManageParts,
   ) {}

   public static isValid(
      item: PartPurchaseOrderItemDto,
   ): item is IndividualPurchasablePurchaseOrderItemDto {
      return IndividualPurchasablePurchaseOrderItemDtoSchema.safeParse(item).success;
   }

   public parse(): PurchasableSnapshot | null {
      const unitDisplay = this.parseIndividualUnitDisplay();

      if (unitDisplay === null) return null;

      return {
         partID: this.item.partID,
         name: unitDisplay.name,
         orderUnitDisplay: unitDisplay,
         stockUnitDisplay: unitDisplay,
         partUnits: this.item.qty,
      };
   }

   public parseIndividualUnitDisplay(): UnitDisplay | null {
      const item = this.item;
      if (item.partStockCustomUnitID === null && item.partStockProvidedUnitID === null) {
         return this.parseStockUnitDisplay();
      }

      return item.partStockCustomUnitID === null
         ? this.unitDisplayParserService.parseProvided({
              providedUnitID: item.partStockProvidedUnitID,
              providedUnitAlias: item.partStockProvidedUnitAlias,
           })
         : this.unitDisplayParserService.parseCustom({
              customUnitNameShort: item.partStockCustomUnitNameShort,
              customUnitNameSingular: item.partStockCustomUnitNameSingular,
           });
   }

   public parseStockUnitDisplay(): UnitDisplay | null {
      const part = this.manageParts.getPart(this.item.partID);
      assert(part !== undefined);

      const stockUnit = this.unitOfMeasureService.getUnit(part.unitDescription)();
      if (stockUnit === null) return null;

      return {
         name: stockUnit.singular(),
         abbreviation: stockUnit.short(),
      };
   }
}
