@if (part) {
   <div
      class="flex-field-direction-row"
      [ngClass]="{ 'full-width-input-wrapper-with-buttons': usedIn === 'listItem' }"
   >
      @if (credChangePartLocation && part.partDeleted == 0) {
         <div
            class="table-field-text text-edit-field"
            [(ngModel)]="part.partLocation"
            (afterEdit)="updatePartLocation()"
            limbleContentEditable
            stripHtml="true"
            [hidden]="!(part.partDeleted == 0)"
         ></div>
      }

      @if (!credChangePartLocation || part.partDeleted === 1) {
         <div class="table-field-text" [limbleHtml]="part.partLocation"></div>
      }
   </div>
}
