<div class="lim-ui-responsive-table-row lim-ui-responsive-table-cell full-width">
   @for (column of columns; track column) {
      @if (checkIfFieldNameExistsForMobile(column, obj)) {
         <div
            [ngClass]="
               !column.width || isCollapsedView
                  ? 'col-md-' + column.columnWidth
                  : 'list-view-custom-column'
            "
            [ngStyle]="!isCollapsedView && column.width && { width: column.width + '%' }"
            class="lim-ui-responsive-table-cell"
         >
            @switch (column.key) {
               <!-- checklistName -->

               @case ("checklistName") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().TaskName }}:
                     </span>
                     <!-- code for icon -->
                     @if (task?.checklistTemplateOld == 1) {
                        <lim-ui-icon icon="wrench" iconSize="small" />
                     } @else if (
                        task?.checklistTemplateOld == 2 &&
                        (task?.checklistBatchID == 10000 ||
                           task?.checklistBatchID == 300112)
                     ) {
                        <lim-ui-icon icon="triangleExclamation" iconSize="small" />
                     } @else if (task?.checklistTemplateOld == 2) {
                        <lim-ui-icon icon="wpforms" iconSize="small" />
                     } @else if (task?.checklistTemplateOld == 4) {
                        <lim-ui-icon icon="file" iconSize="small" />
                     } @else if (obj.scheduleID > 1) {
                        <lim-ui-icon icon="calendarDaysRegular" iconSize="small" />
                     } @else if (task?.checklistTemplate == 1) {
                        <lim-ui-icon icon="wrench" iconSize="small" />
                     }
                     <!-- end of code for icon -->
                     <a (click)="popTask()">
                        {{ obj.checklistName }} - #{{ obj.checklistID }}</a
                     >
                     @if (task && taskExtraInfo.showCommentsHint) {
                        <lim-ui-icon
                           icon="commentRegular"
                           iconSize="small"
                           placement="right"
                           limUiTooltip="{{ lang().oneOrMoreComments }}"
                           (click)="popTask()"
                        />
                     }
                     @if (
                        task &&
                        task.checklistComments !== undefined &&
                        task.checklistComments !== null &&
                        task.checklistComments.length > 2
                     ) {
                        <lim-ui-icon
                           icon="noteSticky"
                           iconSize="small"
                           placement="right"
                           limUiTooltip="{{ lang().completionNotes }}"
                           (click)="popTask()"
                        />
                     }
                     @if (task && taskExtraInfo.requestorInformation.length > 0) {
                        <lim-ui-icon
                           icon="phone"
                           iconSize="small"
                           placement="right"
                           limUiTooltip="{{ lang().RequestorInfo }} + ':' + {{
                              taskExtraInfo.requestorInformation
                           }}"
                        />
                     }
                     @if (task && task.approvalItemState !== null) {
                        @switch (task.approvalItemState) {
                           @case (1) {
                              <lim-ui-icon
                                 icon="thumbsUp"
                                 iconSize="small"
                                 limUiTooltip="{{
                                    lang().ApprovedOrDisapprovedHintTooltip
                                 }}"
                                 (click)="popTask()"
                              />
                           }
                           @case (2) {
                              <lim-ui-icon
                                 icon="thumbsDown"
                                 iconColor="danger"
                                 iconSize="small"
                                 limUiTooltip="{{
                                    lang().ApprovedOrDisapprovedHintTooltip
                                 }}"
                                 (click)="popTask()"
                              />
                           }
                        }
                     }

                     @if (task && task.assetID !== null && task.assetID > 0) {
                        <span
                           >-
                           <a (click)="viewAsset(task?.assetID)" class="green-color"
                              >{{ taskAsset?.assetName }}
                           </a>
                        </span>
                     }

                     @if (task && searchHints?.get(task.checklistID); as searchHint) {
                        <span>
                           - <span class="orange-color" [limbleHtml]="searchHint"> </span>
                        </span>
                     }
                  </div>
               }

               <!-- dueDate -->

               @case ("dueDate") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().DueDate }}:
                     </span>
                     @if (task?.checklistDueDateSetting == 0) {
                        <span class="text-break">
                           {{ obj.dueDate * 1000 | betterDate: "date" }}
                        </span>
                     }
                     @if (task && task?.checklistDueDateSetting != 0) {
                        <span class="text-break">
                           {{ obj.dueDate * 1000 | betterDate: "dateTimeWithSeconds" }}
                        </span>
                     }
                     @if (!task) {
                        <span class="text-break">
                           {{ obj.dueDate * 1000 | betterDate: "date" }}
                        </span>
                     }
                  </div>
               }

               <!-- checklistDueDate -->

               @case ("checklistDueDate") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().DueDate }}:
                     </span>
                     @if (task?.checklistDueDateSetting == 0) {
                        <span class="text-break">
                           {{ obj.checklistDueDate * 1000 | betterDate: "date" }}
                        </span>
                     }
                     @if (task && task?.checklistDueDateSetting != 0) {
                        <span class="text-break">
                           {{
                              obj.checklistDueDate * 1000
                                 | betterDate: "dateTimeWithSeconds"
                           }}
                        </span>
                     }
                     @if (!task) {
                        <span class="text-break">
                           {{ obj.checklistDueDate * 1000 | betterDate: "date" }}
                        </span>
                     }
                  </div>
               }

               <!-- checklistCompletedDate -->

               @case ("checklistCompletedDate") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().Date }}:
                     </span>
                     @if (obj?.checklistCompletedDate > 0) {
                        <span class="text-break">
                           {{ obj.checklistCompletedDate * 1000 | betterDate: "date" }}
                        </span>
                     }
                  </div>
               }

               <!-- assignedTo -->

               @case ("assignedTo") {
                  <div>
                     <lim-ui-icon class="lim-ui-mobile-table-column-label" icon="user" />

                     <span class="text-break">{{ obj.assignedTo }}</span>
                  </div>
               }

               <!-- timeSpent -->

               @case ("timeSpent") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().TimeSpent }}:
                     </span>
                     <span class="text-break"
                        >{{ obj.timeSpent / 60 / 60 | betterNumber: "1.2-2"
                        }}{{ lang().hrs }}</span
                     >
                  </div>
               }

               <!-- date -->

               @case ("date") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().Date }}:
                     </span>
                     <span class="text-break">{{
                        obj.date * 1000 | betterDate: "date"
                     }}</span>
                  </div>
               }

               <!-- usedNumber -->

               @case ("usedNumber") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ column.name }}
                     </span>
                     <span [limbleHtml]="obj[column.key]"> </span>
                     {{ lang().from }}
                     @if (obj.task?.taskIcon) {
                        <lim-ui-icon [icon]="obj.task.taskIcon" />
                     }

                     <span class="text-blue" (click)="popTask()">
                        <span [limbleHtml]="obj.task?.checklistName"></span>
                        - #{{ obj.task?.checklistID }}
                     </span>

                     @if (
                        obj.task?.assetID != 0 && obj.task?.assetID != data?.assetViewing
                     ) {
                        <span>
                           -
                           @if (obj.task?.assetID) {
                              <a
                                 (click)="viewAsset(obj.task)"
                                 class="green-color"
                                 [limbleHtml]="assets.get(obj.task.assetID)?.assetName"
                              ></a>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- totalCost -->

               @case ("totalCost") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        <span [limbleHtml]="lang().TotalOperatingCost"></span>
                     </span>
                     <span class="text-break">
                        {{ obj.totalCost | localeCurrency: obj.currencyCode }}
                     </span>
                  </div>
               }

               <!-- partPrice -->

               @case ("partPrice") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        <span [limbleHtml]="lang().PartPrice"></span>
                     </span>
                     <span class="text-break">{{
                        obj.partPrice | localeCurrency: obj.currencyCode
                     }}</span>
                  </div>
               }

               <!-- assetName -->

               @case ("assetName") {
                  <div>
                     <lim-ui-icon icon="cube" />
                     <a (click)="viewAsset(obj.assetID)" class="green-color">
                        {{ obj.assetName }}
                     </a>
                     @if (searchHints?.get(obj.assetID); as searchHint) {
                        <span class="orange-color">
                           -
                           <span [limbleHtml]="searchHint"></span>
                        </span>
                     }
                  </div>
               }

               <!-- partName -->

               @case ("partName") {
                  <div>
                     <lim-ui-icon class="lim-ui-mobile-table-column-label" icon="gears" />
                     <a (click)="popPart(obj.partID)" class="text-break"
                        >{{ obj.partName }}
                     </a>
                  </div>
               }

               <!-- locationName -->

               @case ("locationName") {
                  <div>
                     <lim-ui-icon
                        icon="houseChimney"
                        class="lim-ui-mobile-table-column-label"
                     />

                     <span class="text-break">{{ locationName }}</span>
                  </div>
               }

               <!-- partRelationsDetail -->

               @case ("partRelationsDetail") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().PartsCost }}:
                     </span>
                     @for (part of partRelationsArray; track part) {
                        <div class="text-break">
                           {{ part.suggestedNumber }} {{ part.partName }}
                           {{ part.partNumber }} -
                           {{ lang().reservedAt }}
                           @if (part.checklistPartLastEdited !== null) {
                              {{
                                 part.checklistPartLastEdited * 1000
                                    | betterDate: "dateTimeWithSeconds"
                              }}
                           }
                        </div>
                     }
                  </div>
               }

               <!-- estimatedTime -->

               @case ("estimatedTime") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().EstimatedTime }}:
                     </span>
                     <span class="text-break"
                        >{{ obj.estimatedTime / 60 / 60 | betterNumber: "1.2-2" }}
                        {{ lang().hrs }}</span
                     >
                  </div>
               }

               <!-- completedPlannedWOs -->

               @case ("completedPlannedWOs") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().CompletedPlannedWOs }}:
                     </span>
                     @if (obj.completedPlannedWOs > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'completedPlannedWOs',
                                 lang().CompletedPlannedWOs,
                                 obj.completedPlannedWOsList
                              )
                           "
                        >
                           {{ obj.completedPlannedWOs }}
                        </a>
                     }
                     @if (!(obj.completedPlannedWOs > 0)) {
                        <span>{{ obj.completedPlannedWOs }}</span>
                     }
                  </div>
               }

               <!-- completedUnplannedWOs -->

               @case ("completedUnplannedWOs") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().CompletedUnplannedWOs }}:
                     </span>
                     @if (obj.completedUnplannedWOs > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'completedUnplannedWOs',
                                 lang().CompletedUnplannedWOs,
                                 obj.completedUnplannedWOsList
                              )
                           "
                        >
                           {{ obj.completedUnplannedWOs }}
                        </a>
                     }
                     @if (!(obj.completedUnplannedWOs > 0)) {
                        <span>{{ obj.completedUnplannedWOs }}</span>
                     }
                  </div>
               }

               <!-- completedPms -->

               @case ("completedPms") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().CompletedPMs }}:
                     </span>
                     @if (obj.completedPms > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'completedPms',
                                 lang().CompletedPMs,
                                 obj.completedPmsList
                              )
                           "
                        >
                           {{ obj.completedPms }}
                        </a>
                     }
                     @if (!(obj.completedPms > 0)) {
                        <span>{{ obj.completedPms }}</span>
                     }
                  </div>
               }

               <!-- completedWorkRequests -->

               @case ("completedWorkRequests") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().CompletedWorkRequests }}:
                     </span>
                     @if (obj.completedWorkRequests > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'completedWorkRequests',
                                 lang().CompletedWorkRequests,
                                 obj.completedWorkRequestsList
                              )
                           "
                        >
                           {{ obj.completedWorkRequests }}
                        </a>
                     }
                     @if (!(obj.completedWorkRequests > 0)) {
                        <span>{{ obj.completedWorkRequests }}</span>
                     }
                  </div>
               }

               <!-- totalCompletedTasks -->

               @case ("totalCompletedTasks") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().TotalCompletedTasks }}:
                     </span>
                     @if (obj.totalCompletedTasks > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'totalCompletedTasks',
                                 lang().TotalCompletedTasks,
                                 obj.totalCompletedTasksList
                              )
                           "
                        >
                           {{ obj.totalCompletedTasks }}
                        </a>
                     }
                     @if (!(obj.totalCompletedTasks > 0)) {
                        <span>{{ obj.totalCompletedTasks }}</span>
                     }
                  </div>
               }

               <!-- openPlannedWOs -->

               @case ("openPlannedWOs") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().OpenPlannedWOs }}:
                     </span>
                     @if (obj.openPlannedWOs > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'openPlannedWorkOrders',
                                 lang().OpenPlannedWOs,
                                 obj.openPlannedWOsList
                              )
                           "
                        >
                           {{ obj.openPlannedWOs }}
                        </a>
                     }
                     @if (!(obj.openPlannedWOs > 0)) {
                        <span>{{ obj.openPlannedWOs }}</span>
                     }
                  </div>
               }

               <!-- openUnplannedWOs -->

               @case ("openUnplannedWOs") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().OpenUnplannedWOs }}:
                     </span>
                     @if (obj.openUnplannedWOs > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'openUnplannedWorkOrders',
                                 lang().OpenUnplannedWOs,
                                 obj.openUnplannedWOsList
                              )
                           "
                        >
                           {{ obj.openUnplannedWOs }}
                        </a>
                     }
                     @if (!(obj.openUnplannedWOs > 0)) {
                        <span>{{ obj.openUnplannedWOs }}</span>
                     }
                  </div>
               }

               <!-- openPms -->

               @case ("openPms") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().OpenPMs }}:
                     </span>
                     @if (obj.openPms > 0) {
                        <a
                           (click)="
                              viewListOfTasks('openPMs', lang().OpenPMs, obj.openPmsList)
                           "
                        >
                           {{ obj.openPms }}
                        </a>
                     }
                     @if (!(obj.openPms > 0)) {
                        <span>{{ obj.openPms }}</span>
                     }
                  </div>
               }

               <!-- openWorkRequests -->

               @case ("openWorkRequests") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().OpenWorkRequests }}:
                     </span>
                     @if (obj.openWorkRequests > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'openWorkRequests',
                                 lang().OpenWorkRequests,
                                 obj.openWorkRequestsList
                              )
                           "
                        >
                           {{ obj.openWorkRequests }}
                        </a>
                     }
                     @if (!(obj.openWorkRequests > 0)) {
                        <span>{{ obj.openWorkRequests }}</span>
                     }
                  </div>
               }

               <!-- totalOpenTasks -->

               @case ("totalOpenTasks") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().TotalOpenTasks }}:
                     </span>
                     @if (obj.totalOpenTasks > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'totalOpenTasks',
                                 lang().TotalOpenTasks,
                                 obj.totalOpenTasksList
                              )
                           "
                        >
                           {{ obj.totalOpenTasks }}
                        </a>
                     }
                     @if (!(obj.totalOpenTasks > 0)) {
                        <span>{{ obj.totalOpenTasks }}</span>
                     }
                  </div>
               }

               <!-- totalTasks -->

               @case ("totalTasks") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().TotalTasks }}:
                     </span>
                     @if (obj.totalTasks > 0) {
                        <a
                           (click)="
                              viewListOfTasks(
                                 'totalTasks',
                                 lang().TotalTasks,
                                 obj.totalTasksList
                              )
                           "
                        >
                           {{ obj.totalTasks }}
                        </a>
                     }
                     @if (!(obj.totalTasks > 0)) {
                        <span>{{ obj.totalTasks }}</span>
                     }
                  </div>
               }

               <!-- downtimeHrs -->

               @case ("downtimeHrs") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().Downtime }}:
                     </span>
                     @if (obj.downtime > 0) {
                        <a (click)="viewListOfTasksByDowntime(obj.downtimeTaskList)">
                           {{ obj.downtimeHrs }} {{ lang().hrs }}
                        </a>
                     }
                     @if (!(obj.downtime > 0)) {
                        <span>{{ obj.downtimeHrs }} {{ lang().hrs }}</span>
                     }
                  </div>
               }

               <!-- uptimeHrs -->

               @case ("uptimeHrs") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().Uptime }}:
                     </span>
                     @if (obj.downtime > 0) {
                        <a (click)="viewListOfTasksByDowntime(obj.downtimeTaskList)">
                           {{ obj.uptimeHrs }} {{ lang().hrs }}
                        </a>
                     }
                     @if (!(obj.downtime > 0)) {
                        <span>{{ obj.uptimeHrs }} {{ lang().hrs }}</span>
                     }
                  </div>
               }

               <!-- downtimePercent -->

               @case ("downtimePercent") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().Downtime }}:
                     </span>
                     @if (obj.downtime > 0) {
                        <a (click)="viewListOfTasksByDowntime(obj.downtimeTaskList)">
                           {{ obj.downtimePercent }} %
                        </a>
                     }
                     @if (!(obj.downtime > 0)) {
                        <span>{{ obj.downtimePercent }} %</span>
                     }
                  </div>
               }

               <!-- uptimePercent -->

               @case ("uptimePercent") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().Uptime }}:
                     </span>
                     @if (obj.downtime > 0) {
                        <a (click)="viewListOfTasksByDowntime(obj.downtimeTaskList)">
                           {{ obj.uptimePercent }} %
                        </a>
                     }
                     @if (!(obj.downtime > 0)) {
                        <span>{{ obj.uptimePercent }} %</span>
                     }
                  </div>
               }

               <!-- mttr -->

               @case ("mttr") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().MTTR }}:
                     </span>
                     @if (obj.downtime > 0) {
                        <a (click)="viewListOfTasksByDowntime(obj.downtimeTaskList)">
                           {{ obj.mttr }} {{ lang().hrs }}
                        </a>
                     }
                     @if (!(obj.downtime > 0)) {
                        <span>{{ obj.mttr }} {{ lang().hrs }}</span>
                     }
                  </div>
               }

               <!-- mtbf -->

               @case ("mtbf") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().MTBF }}:
                     </span>
                     @if (obj.downtime > 0) {
                        <a (click)="viewListOfTasksByDowntime(obj.downtimeTaskList)">
                           {{ obj.mtbf }} {{ lang().hrs }}
                        </a>
                     }
                     @if (!(obj.downtime > 0)) {
                        <span>{{ obj.mtbf }} {{ lang().hrs }}</span>
                     }
                  </div>
               }

               <!-- assetCreatedDate -->

               @case ("assetCreatedDate") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().StartedDate }}:
                     </span>
                     <span>{{ obj.assetCreatedDate * 1000 | betterDate: "date" }}</span>
                  </div>
               }

               <!-- costParts -->

               @case ("costParts") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().PartsCost }}:
                     </span>
                     @if (obj.costParts > 0) {
                        <a
                           (click)="
                              viewListOfTasksByCosts(obj.costPartsList, lang().PartsCost)
                           "
                        >
                           {{ obj.costParts | localeCurrency: obj.currencyCode }}
                        </a>
                     }
                     @if (!(obj.costParts > 0)) {
                        <span>{{
                           obj.costParts | localeCurrency: obj.currencyCode
                        }}</span>
                     }
                  </div>
               }

               <!-- costLabor -->

               @case ("costLabor") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().LaborCost }}:
                     </span>
                     @if (obj.costLabor > 0) {
                        <a
                           (click)="
                              viewListOfTasksByCosts(obj.costLaborList, lang().Labor)
                           "
                        >
                           {{ obj.costLabor | localeCurrency: obj.currencyCode }}
                        </a>
                     }
                     @if (!(obj.costLabor > 0)) {
                        <span>{{
                           obj.costLabor | localeCurrency: obj.currencyCode
                        }}</span>
                     }
                  </div>
               }

               <!-- costInvoices -->

               @case ("costInvoices") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().InvoiceCost }}:
                     </span>
                     @if (obj.costInvoices > 0) {
                        <a
                           (click)="
                              viewListOfTasksByCosts(
                                 obj.costInvoicesList,
                                 lang().Invoices
                              )
                           "
                        >
                           {{ obj.costInvoices | localeCurrency: obj.currencyCode }}
                        </a>
                     }
                     @if (!(obj.costInvoices > 0)) {
                        <span>{{
                           obj.costInvoices | localeCurrency: obj.currencyCode
                        }}</span>
                     }
                  </div>
               }

               <!-- costTotal -->

               @case ("costTotal") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().TotalOperatingCost }}:
                     </span>
                     @if (obj.costTotal > 0) {
                        <a
                           (click)="
                              viewListOfTasksByCosts(
                                 obj.costTotalList,
                                 lang().TotalOperatingCost
                              )
                           "
                        >
                           {{ obj.costTotal | localeCurrency: obj.currencyCode }}
                        </a>
                     }
                     @if (!(obj.costTotal > 0)) {
                        <span>{{
                           obj.costTotal | localeCurrency: obj.currencyCode
                        }}</span>
                     }
                  </div>
               }

               <!-- plannedVsUnplanned -->

               @case ("plannedVsUnplanned") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().TimeSpent }}:
                     </span>
                     @if (
                        obj.plannedVsUnplannedList?.length > 0 ||
                        obj.plannedVsUnplanned2List?.length > 0
                     ) {
                        <span>
                           <a
                              class="green-color"
                              (click)="
                                 viewListOfTasksByType(
                                    obj.plannedVsUnplannedList,
                                    lang().CompletedPlannedWOs
                                 )
                              "
                           >
                              {{ obj.plannedVsUnplanned }} %
                           </a>
                           <span> {{ lang().vsStr }} </span>
                           <a
                              class="redColor"
                              (click)="
                                 viewListOfTasksByType(
                                    obj.plannedVsUnplanned2List,
                                    lang().CompletedUnplannedWOs
                                 )
                              "
                           >
                              {{ obj.plannedVsUnplanned2 }} %
                           </a>
                        </span>
                     }
                     @if (
                        !(
                           obj.plannedVsUnplannedList?.length > 0 ||
                           obj.plannedVsUnplanned2List?.length > 0
                        )
                     ) {
                        <span
                           ><span
                              >{{ this.obj.plannedVsUnplanned }} {{ this.lang().vsStr }}
                              {{ this.obj.plannedVsUnplanned2 }}</span
                           ></span
                        >
                     }
                  </div>
               }

               <!-- timeSpent2 -->

               @case ("timeSpent2") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().TimeSpent }}:
                     </span>
                     @if (obj.timeSpent2 > 0) {
                        <a
                           (click)="
                              viewListOfTasksByCosts(obj.timeSpent2List, lang().TimeSpent)
                           "
                        >
                           {{ obj.timeSpent2 }} {{ lang().hrs }}
                        </a>
                     }
                     @if (!(obj.timeSpent2 > 0)) {
                        <span>{{ obj.timeSpent2 }} {{ lang().hrs }}</span>
                     }
                  </div>
               }

               <!-- partsUsed -->

               @case ("partsUsed") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().PartsUsed }}:
                     </span>
                     @if (obj.partsUsed > 0) {
                        <a (click)="viewListOfPartsUsage(obj.partsUsedList)">
                           {{ obj.partsUsed }}
                        </a>
                     }
                     @if (!(obj.partsUsed > 0)) {
                        <span>{{ obj.partsUsed }}</span>
                     }
                  </div>
               }

               <!-- costPerCustomField -->

               @case ("costPerCustomField") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().CostPerCustomField }}:
                     </span>
                     @if (obj.costPerCustomField) {
                        <span>{{
                           obj.costPerCustomField | localeCurrency: obj.currencyCode
                        }}</span>
                     }
                  </div>
               }

               <!-- customField1 -->

               @case ("customField1") {
                  <div>
                     @if (obj?.customField1Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customField1Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customField1Data?.fieldTypeID == 3 &&
                              obj.customField1Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customField1Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customField1Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customField1Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customField1Data?.fieldTypeID == 4 &&
                              obj.customField1Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (file of obj.customField1Data.files; track file) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/assets/{{ obj.locationID }}/{{
                                                obj.assetID
                                             }}/{{ obj.customField1Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customField1Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customField1Data.valueContent | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customField1Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customField1Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              obj.customField1Data &&
                              !(
                                 obj.customField1Data?.fieldTypeID == 6 ||
                                 obj.customField1Data?.fieldTypeID == 2 ||
                                 obj.customField1Data?.fieldTypeID == 4 ||
                                 obj.customField1Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customField1Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customField2 -->

               @case ("customField2") {
                  <div>
                     @if (obj?.customField2Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customField2Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customField2Data?.fieldTypeID == 3 &&
                              obj.customField2Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customField2Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customField2Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customField2Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customField2Data?.fieldTypeID == 4 &&
                              obj.customField2Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (file of obj.customField2Data.files; track file) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/assets/{{ obj.locationID }}/{{
                                                obj.assetID
                                             }}/{{ obj.customField2Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customField2Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customField2Data.valueContent | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customField2Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customField2Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              obj.customField2Data &&
                              !(
                                 obj.customField2Data?.fieldTypeID == 6 ||
                                 obj.customField2Data?.fieldTypeID == 2 ||
                                 obj.customField2Data?.fieldTypeID == 4 ||
                                 obj.customField2Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customField2Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customField3 -->

               @case ("customField3") {
                  <div>
                     @if (obj?.customField3Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customField3Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customField3Data?.fieldTypeID == 3 &&
                              obj.customField3Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customField3Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customField3Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customField3Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customField3Data?.fieldTypeID == 4 &&
                              obj.customField3Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (file of obj.customField3Data.files; track file) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/assets/{{ obj.locationID }}/{{
                                                obj.assetID
                                             }}/{{ obj.customField3Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customField3Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customField3Data.valueContent | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customField3Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customField3Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              obj.customField3Data &&
                              !(
                                 obj.customField3Data?.fieldTypeID == 6 ||
                                 obj.customField3Data?.fieldTypeID == 2 ||
                                 obj.customField3Data?.fieldTypeID == 4 ||
                                 obj.customField3Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customField3Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customField4 -->

               @case ("customField4") {
                  <div>
                     @if (obj?.customField4Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customField4Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customField4Data?.fieldTypeID == 3 &&
                              obj.customField4Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customField4Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customField4Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customField4Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customField4Data?.fieldTypeID == 4 &&
                              obj.customField4Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (file of obj.customField4Data.files; track file) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/assets/{{ obj.locationID }}/{{
                                                obj.assetID
                                             }}/{{ obj.customField4Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customField4Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customField4Data.valueContent | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customField4Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customField4Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              obj.customField4Data &&
                              !(
                                 obj.customField4Data?.fieldTypeID == 6 ||
                                 obj.customField4Data?.fieldTypeID == 2 ||
                                 obj.customField4Data?.fieldTypeID == 4 ||
                                 obj.customField4Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customField4Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customField5 -->

               @case ("customField5") {
                  <div>
                     @if (obj?.customField5Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customField5Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customField5Data?.fieldTypeID == 3 &&
                              obj.customField5Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customField5Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customField5Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customField5Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customField5Data?.fieldTypeID == 4 &&
                              obj.customField5Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (file of obj.customField5Data.files; track file) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/assets/{{ obj.locationID }}/{{
                                                obj.assetID
                                             }}/{{ obj.customField5Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customField5Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customField5Data.valueContent | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customField5Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customField5Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              obj.customField5Data &&
                              !(
                                 obj.customField5Data?.fieldTypeID == 6 ||
                                 obj.customField5Data?.fieldTypeID == 2 ||
                                 obj.customField5Data?.fieldTypeID == 4 ||
                                 obj.customField5Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customField5Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customField6 -->

               @case ("customField6") {
                  <div>
                     @if (obj?.customField6Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customField6Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customField6Data?.fieldTypeID == 3 &&
                              obj.customField6Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customField6Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customField6Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customField6Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customField6Data?.fieldTypeID == 4 &&
                              obj.customField6Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (file of obj.customField6Data.files; track file) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/assets/{{ obj.locationID }}/{{
                                                obj.assetID
                                             }}/{{ obj.customField6Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customField6Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customField6Data.valueContent | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customField6Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customField6Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              obj.customField6Data &&
                              !(
                                 obj.customField6Data?.fieldTypeID == 6 ||
                                 obj.customField6Data?.fieldTypeID == 2 ||
                                 obj.customField6Data?.fieldTypeID == 4 ||
                                 obj.customField6Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customField6Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customField7 -->

               @case ("customField7") {
                  <div>
                     @if (obj?.customField7Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customField7Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customField7Data?.fieldTypeID == 3 &&
                              obj.customField7Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customField7Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customField7Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customField7Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customField7Data?.fieldTypeID == 4 &&
                              obj.customField7Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (file of obj.customField7Data.files; track file) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/assets/{{ obj.locationID }}/{{
                                                obj.assetID
                                             }}/{{ obj.customField7Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customField7Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customField7Data.valueContent | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customField7Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customField7Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              obj.customField7Data &&
                              !(
                                 obj.customField7Data?.fieldTypeID == 6 ||
                                 obj.customField7Data?.fieldTypeID == 2 ||
                                 obj.customField7Data?.fieldTypeID == 4 ||
                                 obj.customField7Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customField7Data.valueContent">
                              </span>
                           }
                           @if (!obj.customField7) {
                              <span></span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customField8 -->

               @case ("customField8") {
                  <div>
                     @if (obj?.customField8Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customField8Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customField8Data?.fieldTypeID == 3 &&
                              obj.customField8Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customField8Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customField8Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customField8Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customField8Data?.fieldTypeID == 4 &&
                              obj.customField8Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (file of obj.customField8Data.files; track file) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/assets/{{ obj.locationID }}/{{
                                                obj.assetID
                                             }}/{{ obj.customField8Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customField8Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customField8Data.valueContent | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customField8Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customField8Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              obj.customField8Data &&
                              !(
                                 obj.customField8Data?.fieldTypeID == 6 ||
                                 obj.customField8Data?.fieldTypeID == 2 ||
                                 obj.customField8Data?.fieldTypeID == 4 ||
                                 obj.customField8Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customField8Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customPartField1 -->

               @case ("customPartField1") {
                  <div>
                     @if (obj?.customPartField1Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customPartField1Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customPartField1Data?.fieldTypeID == 3 &&
                              obj.customPartField1Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField1Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customPartField1Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customPartField1Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customPartField1Data?.fieldTypeID == 4 &&
                              obj.customPartField1Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField1Data.files;
                                    track file
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/parts/{{ obj.locationID }}/{{
                                                obj.partID
                                             }}/{{ obj.customPartField1Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customPartField1Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customPartField1Data.valueContent
                                       | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customPartField1Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customPartField1Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }

                           @if (
                              !(
                                 obj.customPartField1Data?.fieldTypeID == 6 ||
                                 obj.customPartField1Data?.fieldTypeID == 2 ||
                                 obj.customPartField1Data?.fieldTypeID == 4 ||
                                 obj.customPartField1Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customPartField1Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customPartField2 -->

               @case ("customPartField2") {
                  <div>
                     @if (obj?.customPartField2Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ obj?.customPartField2Data?.fieldName }}:
                           </span>
                           @if (
                              obj.customPartField2Data?.fieldTypeID == 3 &&
                              obj.customPartField2Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField2Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customPartField2Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customPartField2Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customPartField2Data?.fieldTypeID == 4 &&
                              obj.customPartField2Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField2Data.files;
                                    track file
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/parts/{{ obj.locationID }}/{{
                                                obj.partID
                                             }}/{{ obj.customPartField2Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customPartField2Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customPartField2Data.valueContent
                                       | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customPartField2Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customPartField2Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              !(
                                 obj.customPartField2Data?.fieldTypeID == 6 ||
                                 obj.customPartField2Data?.fieldTypeID == 2 ||
                                 obj.customPartField2Data?.fieldTypeID == 4 ||
                                 obj.customPartField2Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customPartField2Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customPartField3 -->

               @case ("customPartField3") {
                  <div>
                     @if (obj?.customPartField3Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ lang().CostPerCustomField }}:
                           </span>
                           @if (
                              obj.customPartField3Data?.fieldTypeID == 3 &&
                              obj.customPartField3Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField3Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customPartField3Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customPartField3Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customPartField3Data?.fieldTypeID == 4 &&
                              obj.customPartField3Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField3Data.files;
                                    track file
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/parts/{{ obj.locationID }}/{{
                                                obj.partID
                                             }}/{{ obj.customPartField3Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customPartField3Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customPartField3Data.valueContent
                                       | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customPartField3Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customPartField3Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              !(
                                 obj.customPartField3Data?.fieldTypeID == 6 ||
                                 obj.customPartField3Data?.fieldTypeID == 2 ||
                                 obj.customPartField3Data?.fieldTypeID == 4 ||
                                 obj.customPartField3Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customPartField3Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customPartField4 -->

               @case ("customPartField4") {
                  <div>
                     @if (obj?.customPartField4Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ lang().CostPerCustomField }}:
                           </span>
                           @if (
                              obj.customPartField4Data?.fieldTypeID == 3 &&
                              obj.customPartField4Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField4Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customPartField4Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customPartField4Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customPartField4Data?.fieldTypeID == 4 &&
                              obj.customPartField4Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField4Data.files;
                                    track file
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/parts/{{ obj.locationID }}/{{
                                                obj.partID
                                             }}/{{ obj.customPartField4Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customPartField4Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customPartField4Data.valueContent
                                       | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customPartField4Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customPartField4Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              !(
                                 obj.customPartField4Data?.fieldTypeID == 6 ||
                                 obj.customPartField4Data?.fieldTypeID == 2 ||
                                 obj.customPartField4Data?.fieldTypeID == 4 ||
                                 obj.customPartField4Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customPartField4Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customPartField5 -->

               @case ("customPartField5") {
                  <div>
                     @if (obj?.customPartField5Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ lang().CostPerCustomField }}:
                           </span>
                           @if (
                              obj.customPartField5Data?.fieldTypeID == 3 &&
                              obj.customPartField5Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField5Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customPartField5Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customPartField5Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customPartField5Data?.fieldTypeID == 4 &&
                              obj.customPartField5Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField5Data.files;
                                    track file
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/parts/{{ obj.locationID }}/{{
                                                obj.partID
                                             }}/{{ obj.customPartField5Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customPartField5Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customPartField5Data.valueContent
                                       | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customPartField5Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customPartField5Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              !(
                                 obj.customPartField5Data?.fieldTypeID == 6 ||
                                 obj.customPartField5Data?.fieldTypeID == 2 ||
                                 obj.customPartField5Data?.fieldTypeID == 4 ||
                                 obj.customPartField5Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customPartField5Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customPartField6 -->

               @case ("customPartField6") {
                  <div>
                     @if (obj?.customPartField6Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ lang().CostPerCustomField }}:
                           </span>
                           @if (
                              obj.customPartField6Data?.fieldTypeID == 3 &&
                              obj.customPartField6Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField6Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customPartField6Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customPartField6Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customPartField6Data?.fieldTypeID == 4 &&
                              obj.customPartField6Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField6Data.files;
                                    track file
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/parts/{{ obj.locationID }}/{{
                                                obj.partID
                                             }}/{{ obj.customPartField6Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customPartField6Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customPartField6Data.valueContent
                                       | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customPartField6Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customPartField6Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              !(
                                 obj.customPartField6Data?.fieldTypeID == 6 ||
                                 obj.customPartField6Data?.fieldTypeID == 2 ||
                                 obj.customPartField6Data?.fieldTypeID == 4 ||
                                 obj.customPartField6Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customPartField6Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customPartField7 -->

               @case ("customPartField7") {
                  <div>
                     @if (obj?.customPartField7Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ lang().CostPerCustomField }}:
                           </span>
                           @if (
                              obj.customPartField7Data?.fieldTypeID == 3 &&
                              obj.customPartField7Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField7Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customPartField7Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customPartField7Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customPartField7Data?.fieldTypeID == 4 &&
                              obj.customPartField7Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField7Data.files;
                                    track file
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/parts/{{ obj.locationID }}/{{
                                                obj.partID
                                             }}/{{ obj.customPartField7Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customPartField7Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customPartField7Data.valueContent
                                       | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customPartField7Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customPartField7Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              !(
                                 obj.customPartField7Data?.fieldTypeID == 6 ||
                                 obj.customPartField7Data?.fieldTypeID == 2 ||
                                 obj.customPartField7Data?.fieldTypeID == 4 ||
                                 obj.customPartField7Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customPartField7Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- customPartField8 -->

               @case ("customPartField8") {
                  <div>
                     @if (obj?.customPartField8Data?.fieldName) {
                        <span class="text-break-container">
                           <span class="lim-ui-mobile-table-column-label">
                              {{ lang().CostPerCustomField }}:
                           </span>
                           @if (
                              obj.customPartField8Data?.fieldTypeID == 3 &&
                              obj.customPartField8Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField8Data.files;
                                    track file;
                                    let index = $index
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             attr.data-file-index="{{ index }}"
                                             (click)="
                                                viewFile(
                                                   obj,
                                                   obj.customPartField8Data,
                                                   $event
                                                )
                                             "
                                             >{{
                                                obj.customPartField8Data.files[
                                                   index
                                                ].fileName.slice(5)
                                             }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (
                              obj.customPartField8Data?.fieldTypeID == 4 &&
                              obj.customPartField8Data?.files?.length > 0
                           ) {
                              <span>
                                 @for (
                                    file of obj.customPartField8Data.files;
                                    track file
                                 ) {
                                    <span>
                                       <div>
                                          <a
                                             target="_blank"
                                             href="viewFile.php?f=upload-{{
                                                customerID
                                             }}/parts/{{ obj.locationID }}/{{
                                                obj.partID
                                             }}/{{ obj.customPartField8Data.valueID }}/{{
                                                file.fileName
                                             }}"
                                             >{{ file.fileName.slice(5) }}</a
                                          >
                                       </div>
                                    </span>
                                 }
                              </span>
                           }
                           @if (obj.customPartField8Data?.fieldTypeID == 2) {
                              <span>
                                 {{
                                    obj.customPartField8Data.valueContent
                                       | betterDate: "date"
                                 }}
                              </span>
                           }
                           @if (obj.customPartField8Data?.fieldTypeID == 6) {
                              <span>
                                 {{
                                    obj.customPartField8Data.valueContent
                                       | localeCurrency: obj.currencyCode
                                 }}
                              </span>
                           }
                           @if (
                              !(
                                 obj.customPartField8Data?.fieldTypeID == 6 ||
                                 obj.customPartField8Data?.fieldTypeID == 2 ||
                                 obj.customPartField8Data?.fieldTypeID == 4 ||
                                 obj.customPartField8Data?.fieldTypeID == 3
                              )
                           ) {
                              <span [limbleHtml]="obj.customPartField8Data.valueContent">
                              </span>
                           }
                        </span>
                     }
                  </div>
               }

               <!-- poNumber -->

               @case ("poNumberForDisplay") {
                  <div>
                     <a (click)="popPoComponent(obj.poID)"
                        >{{ obj.poNumberForDisplay }}
                     </a>
                     @if (
                        obj.POs &&
                           obj.POs.listOption === "POs" &&
                           searchHints?.get(obj.poID);
                        as searchHint
                     ) {
                        <span>
                           -
                           <span class="orange-color" [limbleHtml]="searchHint"> </span>
                        </span>
                     }
                  </div>
               }

               <!-- total -->

               @case ("total") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">{{
                        lang().Total
                     }}</span>
                     <span class="text-break">{{
                        obj.total | localeCurrency: obj.currencyCode
                     }}</span>
                  </div>
               }

               <!-- rate -->

               @case ("rate") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">{{
                        lang().Rate
                     }}</span>
                     <span class="text-break">{{
                        obj.rate | localeCurrency: obj.currencyCode
                     }}</span>
                  </div>
               }

               <!-- shipping -->

               @case ("shipping") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">{{
                        lang().Shipping
                     }}</span>
                     <span class="text-break">{{
                        obj.shipping | localeCurrency: obj.currencyCode
                     }}</span>
                  </div>
               }

               <!-- tax -->

               @case ("tax") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">{{
                        lang().Tax
                     }}</span>
                     <span class="text-break">{{ obj.tax }}%</span>
                  </div>
               }

               <!-- discount -->

               @case ("discount") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">{{
                        lang().Discount
                     }}</span>
                     <span class="text-break">{{ obj.discount }}%</span>
                  </div>
               }

               <!-- itemName -->

               @case ("itemName") {
                  <div class="item-name">
                     <!-- For Parts -->
                     @if (obj.itemType == 1) {
                        <span>
                           <lim-ui-icon icon="gears" iconSize="small" />
                           @if (obj.itemSearchStr && !obj.itemName) {
                              <a (click)="popPart(obj.partID)"
                                 >{{ obj.itemSearchStr }}
                              </a>
                           }
                           @if (obj.itemName) {
                              <a (click)="popPart(obj.partID)">{{ obj.itemName }} </a>
                           }
                           @if (
                              obj.POs &&
                                 obj.POs.listOption === "POItems" &&
                                 searchHints?.get(obj.poID);
                              as searchHint
                           ) {
                              <span>
                                 -
                                 <span class="orange-color" [limbleHtml]="searchHint">
                                 </span>
                              </span>
                           }
                        </span>
                     }

                     <!-- For Service -->
                     @if (obj.itemType == 2) {
                        <span>
                           <lim-ui-icon icon="wpforms" iconSize="small" />

                           {{ obj.itemSearchStr }}
                        </span>
                     }

                     <!-- For Other -->
                     @if (obj.itemType == 4) {
                        <span>
                           <lim-ui-icon icon="superpowers" iconSize="small" />
                           {{ obj.itemSearchStr }}
                        </span>
                     }
                  </div>
               }

               <!-- items -->
               @case ("items") {
                  <div>
                     @if (obj.poItemIDs) {
                        <po-item-list-short [purchaseOrderItemIDs]="obj.poItemIDs" />
                     }
                  </div>
               }

               <!-- expectedDate -->

               @case ("deliveryDate") {
                  <div>
                     @if (obj.poID) {
                        <po-delivery-date [poID]="obj.poID" />
                     }
                  </div>
               }

               <!-- qty -->
               @case ("POItemReceived") {
                  <div>
                     <div
                        [ngClass]="{
                           poReceivedBorderBottom: obj.transactions?.length > 0,
                        }"
                     >
                        {{ obj.receivedQty }} {{ lang().ofStr }}
                        {{ obj.qty }}
                     </div>
                     @for (transaction of obj.transactions; track transaction) {
                        <div (click)="popPR(transaction)">
                           @if (transaction.qtyReceived !== null) {
                              {{ transaction.qtyReceived * 1 }} {{ lang().onStr }}
                              @if (transaction.dateReceived !== null) {
                                 <a class="headerLink">{{
                                    transaction.dateReceived * 1000 | betterDate: "date"
                                 }}</a>
                              }
                           }
                        </div>
                     }
                  </div>
               }

               <!-- partQty -->

               @case ("partQty") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label"
                        >{{ lang().PartQty }}:</span
                     >
                     @if (
                        manageParts.getSingleCalculatedPartInfo(obj.partID);
                        as calculatedPartInfo
                     ) {
                        @if (
                           unitsOfMeasureIsEnabled() &&
                           isUnitsOfMeasureFeatureAvailable() &&
                           areUnitsOfMeasureEnabled()
                        ) {
                           <span class="text-break">
                              {{ calculatedPartInfo.totalQty }}{{ obj.partUnit }}
                           </span>
                        } @else {
                           <span class="text-break">
                              {{ calculatedPartInfo.totalQty }}
                           </span>
                        }
                     }
                  </div>
               }

               <!-- partsUsedInTimeCost -->
               @case ("partsUsedInTimeCost") {
                  <div>
                     <span class="text-break">{{
                        obj.partsUsedInTimeCost | localeCurrency: obj.currencyCode
                     }}</span>
                  </div>
               }

               <!-- vendor -->

               @case ("vendor") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ column.name }}:
                     </span>
                     @if (obj.vendor && obj.vendor.vendorName) {
                        <span class="text-break" [limbleHtml]="obj.vendor.vendorName">
                        </span>
                     }
                  </div>
               }

               <!-- efficiencyRate -->
               @case ("efficiencyRate") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">{{
                        lang().EfficiencyRate
                     }}</span>
                     <span class="text-break">{{ obj.efficiencyRate }}%</span>
                  </div>
               }

               <!-- efficiencySavings -->
               @case ("efficiencySavings") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ lang().EfficiencySavings }}:
                     </span>
                     <span class="text-break">{{
                        obj.efficiencySavings | localeCurrency: obj.currencyCode
                     }}</span>
                  </div>
               }

               <!-- default -->

               @default {
                  <div>
                     <span class="lim-ui-mobile-table-column-label">
                        {{ column.displayName }}:
                     </span>
                     @if (column.isCurrency && obj.currencyCode) {
                        {{ obj[column.key] | localeCurrency: obj.currencyCode }}
                     } @else {
                        <span
                           class="text-break"
                           [limbleHtml]="
                              obj[column.key] !== '' ? obj[column.key] : '&nbsp;'
                           "
                        ></span>
                     }
                  </div>
               }
            }
         </div>
      }
   }
</div>
