import { Injectable } from "@angular/core";
import axios from "axios/dist/axios";

@Injectable({ providedIn: "root" })
export class ManageShared {
   private readonly axios = axios;

   getAllQRPrintTemplates = async (locationID) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageShared.php",
         params: { action: "getAllQRPrintTemplates" },
         data: {
            locationID: locationID,
         },
      });

      if (post.data.success) {
         if (post.data.allTemplates) {
            (post as any).data.allTemplates.forEach((item) => {
               item.options = JSON.parse(item.options);
               item.name = item.name.replace("\\'", "'");
               item.assetFields = JSON.parse(item.assetFields);
               item.partFields = JSON.parse(item.partFields);
               item.taskFields = JSON.parse(item.taskFields);
            });
         }
      }
      return post;
   };

   updateQRPrintTemplate = async (userID, template) => {
      const optionsJSON = JSON.stringify(template.options);
      const assetFieldsJSON = JSON.stringify(template.assetFields);
      const partFieldsJSON = JSON.stringify(template.partFields);
      const taskFieldsJSON = JSON.stringify(template.taskFields);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageShared.php",
         params: { action: "updateQRPrintTemplate" },
         data: {
            options: optionsJSON,
            name: template.name,
            templateID: template.templateID,
            userID: userID,
            assetFields: assetFieldsJSON,
            partFields: partFieldsJSON,
            taskFields: taskFieldsJSON,
            locationID: template.locationID,
         },
      });

      if (post.data.success) {
         (post as any).data.allTemplates.forEach((item) => {
            item.options = JSON.parse(item.options);
            item.name = item.name.replace("\\'", "'");
            item.assetFields = JSON.parse(item.assetFields);
            item.partFields = JSON.parse(item.partFields);
            item.taskFields = JSON.parse(item.taskFields);
         });
      }

      return post;
   };

   createQRPrintTemplate = async (template, userID, locationID) => {
      const assetFieldsJSON = JSON.stringify(template.assetFields);
      const partFieldsJSON = JSON.stringify(template.partFields);
      const taskFieldsJSON = JSON.stringify(template.taskFields);
      const templatesJsonString = JSON.stringify(template.options);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageShared.php",
         params: { action: "createQRPrintTemplate" },
         data: {
            options: templatesJsonString,
            name: template.name,
            userID: userID,
            locationID: locationID,
            assetFields: assetFieldsJSON,
            partFields: partFieldsJSON,
            taskFields: taskFieldsJSON,
         },
      });

      if (post.data.success) {
         (post as any).data.allTemplates.forEach((item) => {
            item.options = JSON.parse(item.options);
            item.name = item.name.replace("\\'", "'");
            item.assetFields = JSON.parse(item.assetFields);
            item.partFields = JSON.parse(item.partFields);
            item.taskFields = JSON.parse(item.taskFields);
         });
      }
      return post;
   };

   deleteQRPrintTemplate = async (templateID, userID) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageShared.php",
         params: { action: "deleteQRPrintTemplate" },
         data: {
            userID: userID,
            templateID: templateID,
         },
      });
      return post;
   };

   copyQRPrintTemplate = async (templateID, userID) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageShared.php",
         params: { action: "copyQRPrintTemplate" },
         data: {
            userID: userID,
            templateID: templateID,
         },
      });
      if (post.data.success) {
         (post as any).data.allTemplates.forEach((item) => {
            item.options = JSON.parse(item.options);
            item.name = item.name.replace("\\'", "'");
            item.assetFields = JSON.parse(item.assetFields);
            item.partFields = JSON.parse(item.partFields);
            item.taskFields = JSON.parse(item.taskFields);
         });
      }
      return post;
   };
}
