import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { Component, Input, signal } from "@angular/core";
import { IconComponent } from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import type { SortColumnInfo } from "src/app/shared/types/general.types";

@Component({
   selector: "sort-column_refactor",
   templateUrl: "./sortColumn_refactor.element.component.html",
   styleUrls: ["./sortColumn.element.component.scss"],
   imports: [NgClass, IconComponent],
})
export class SortColumn_refactor implements OnInit, OnDestroy {
   @Input() public options: SortColumnInfo<any, any> | undefined;

   private sortChangesSub: Subscription | undefined;

   protected sortState = signal<"disordered" | "ascending" | "descending">("disordered");

   public ngOnInit(): void {
      if (!this.options) {
         return;
      }
      this.sortChangesSub = this.options.sortChanges.subscribe((options) => {
         if (!options) {
            return;
         }
         if (!this.options || options.columnName === this.options.columnName) {
            this.sortState.set(options.sortDirection);
            return;
         }

         this.sortState.set("disordered");
      });
   }

   public ngOnDestroy() {
      this.sortChangesSub?.unsubscribe();
   }

   protected updateSort() {
      if (!this.options) {
         return;
      }
      if (
         this.sortState() === "disordered" ||
         this.options.sortDirection === "descending"
      ) {
         this.options.sortDirection = "ascending";
         this.sortState.set("ascending");
      } else {
         this.options.sortDirection = "descending";
         this.sortState.set("descending");
      }
      this.options.sortChanges.next(this.options);
   }
}
