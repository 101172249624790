import { Injectable, inject } from "@angular/core";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { WellDefinedWidgetDef } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import type {
   CycleCountScheduleEntity,
   ScheduleEntity,
   ScheduleEntityFilters,
} from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import { SchedulesApiService } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.service";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import type { TaskEntityFilters } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({ providedIn: "root" })
export class TaskCalendarDataService {
   private readonly manageTask = inject(ManageTask);
   //JIT TODO: remove these dependencies once we're fetching tasks JIT
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageParts = inject(ManageParts);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly schedulesApiService = inject(SchedulesApiService);
   private readonly manageDashboard = inject(ManageDashboard);
   private readonly alertService = inject(AlertService);

   public getTasks(
      filters: Pick<
         TaskEntityFilters,
         "locationIDs" | "search" | "statuses" | "checklistTemplateOld" | "statusesNot"
      >,
      widgetDef?: WellDefinedWidgetDef,
   ): Array<Task> {
      const { locationIDs, search, statuses, checklistTemplateOld, statusesNot } =
         filters;

      let tasks = this.manageTask.getAllTasks();

      tasks = tasks.filter((task) => {
         if (task.statusID === null) {
            return false;
         }

         const isNotPM = task.checklistTemplate === 0;
         if (!isNotPM) {
            return false;
         }

         const taskHasStatus = statuses ? statuses.includes(task.statusID) : true;
         if (!taskHasStatus) {
            return false;
         }

         const taskDoesNotHaveStatus = statusesNot
            ? !statusesNot.includes(task.statusID)
            : true;
         if (!taskDoesNotHaveStatus) {
            return false;
         }

         let taskInstanceMatchesTemplateTypeFilter = true;
         if (checklistTemplateOld) {
            const taskChecklistTemplateOld = task.checklistTemplateOld;
            if (taskChecklistTemplateOld) {
               taskInstanceMatchesTemplateTypeFilter = checklistTemplateOld.includes(
                  taskChecklistTemplateOld,
               );
            } else {
               taskInstanceMatchesTemplateTypeFilter = false;
            }
         }

         if (!taskInstanceMatchesTemplateTypeFilter) {
            return false;
         }

         return true;
      });
      if (locationIDs?.length) {
         tasks = tasks.filter((task) => locationIDs.includes(task.locationID));
      }

      if (search?.length) {
         tasks = this.manageFilters.filterTasksToSearch(
            tasks,
            search,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         ).tasks;
      }

      if (widgetDef) {
         tasks = this.manageFilters.filterTasksBasedOnDashboardParams(
            tasks,
            widgetDef,
            this.manageUser,
            this.manageDashboard,
            this.alertService,
            this.manageTask,
            this.manageAsset,
            this.manageLocation,
         );
      }
      return Array.from(tasks);
   }

   public async getSchedules(
      filters: ScheduleEntityFilters,
   ): Promise<Array<ScheduleEntity>> {
      const cleanedFilters =
         this.taskTemplatesApiService.removeUndefinedProperties<ScheduleEntityFilters>(
            filters,
         );

      const locationIDs = filters.locationIDs;
      const configs: { [key: number]: any } = {};
      if (locationIDs) {
         await Promise.all(
            locationIDs.map(async (locationID) => {
               configs[locationID] =
                  await this.manageLocation.getCycleCountSchedulesConfig(locationID);
            }),
         );
      }

      const schedules: Array<ScheduleEntity> = [];
      const schedulesStream = this.schedulesApiService.getStreamedList({
         filters: cleanedFilters,
         columns: "relatedTemplate",
      });
      for await (const schedule of schedulesStream) {
         const locationID = schedule.locationID;
         const config = configs[locationID];

         if (config === undefined) {
            schedules.push(schedule);
            continue;
         }
         const cycleCountSchedulesConfig = config.data.cycleCountSchedules;
         const cycleCountConfig = cycleCountSchedulesConfig.find(
            (scheduleConfig) => scheduleConfig.reoccurID === schedule.reoccurID,
         );
         if (cycleCountConfig) {
            const cycleCountSchedule: CycleCountScheduleEntity = {
               ...schedule,
               config: cycleCountConfig,
            };
            cycleCountSchedule.userID =
               cycleCountConfig.taskUserID ?? cycleCountSchedule.userID;
            cycleCountSchedule.profileID =
               cycleCountConfig.taskProfileID ?? cycleCountSchedule.profileID;
            schedules.push(cycleCountSchedule);
         } else {
            schedules.push(schedule);
         }
      }

      return schedules;
   }
}
