import type { GeoFeature } from "src/app/maps/types/geoMap.types";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import type { InvoiceEntity } from "src/app/tasks/components/shared/services/invoices-api/invoice-api.models";
import type { ScheduleEntity } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";

export interface TaskTemplateAsset {
   assetID: number;
   assetName: string | null;
   assetDeleted: number | null;
   locationID: number;
   assetFileName: string | null;
   assetTrackCOOLabor: number | null;
   assetTrackCOOParts: number | null;
   assetOperationHoursPerWeek: number | null;
   assetCreatedDate: number | null;
   assetDeletedDate: number | null;
   assetLastEdited: number | null;
   migrated: number | null;
   migratedFeedback: string | null;
   assetTrackHoursManually: number | null;
   assetTrackHoursManuallyData: string | null;
   parentAssetID: number | null;
   assetOrder: number | null;
   includeChildData: number;
   reportProblemAssignmentUserID: number | null;
   reportProblemAssignmentProfileID: number | null;
   assetCheckSimiliarWRScope: number | null;
   checkOutRequiresApproval: number;
   canCheckOutAsTool: number;
   checkOutApprovalProfileID: number;
   checkOutApprovalUserID: number;
   geoLocation: GeoFeature | null;
   assetValueIDs: Array<number>;
   assetVendorIDs: Array<number>;
   assetPartRelationIDs: Array<number>;
   assetChildrenIDs: Array<number>;
   assetDepreciationID?: number | undefined;
   defaultChecklistID: number | null;
   parent: TaskTemplateAsset;
}

export interface TaskTemplateEntityExtraFields {
   recurrences?: Array<Recurrence>;
}

export interface TaskTemplateEntity extends TaskTemplateEntityExtraFields {
   checklistID: number;
   approvalItemState: number;
   locationID: number;
   assetID: number;
   assetInfoFromCompletion: string;
   billableRate: number;
   billableTime: number;
   categoryID: number;
   checklistBatchID: number;
   checklistColor: number;
   checklistComments: string;
   checklistCompletedDate: number;
   checklistCreatedDate: number;
   checklistDepreciated: number;
   checklistDowntime: number;
   checklistDueDate: number;
   checklistDueDateSetting: number;
   checklistEmailCN: string;
   checklistEmailCNImages: string;
   checklistEstTime: number;
   checklistGreen: number;
   checklistInstructions: string;
   checklistLastEdited: number;
   checklistName: string;
   checklistPriorBatchID: number;
   checklistPriority: number;
   checklistPromptTime: number;
   checklistRed: number;
   checklistStartDate: number;
   checklistStartDateSetting: number;
   checklistStatusID: number;
   checklistTemplate: number;
   checklistTemplateOld: number;
   checklistUserCompleted: number;
   checklistUserWage: number;
   checklistYellow: number;
   createdByUserID: number;
   dynamicAssignment: number;
   extraTimeNotes: string;
   geoLocation: string;

   numOfDueDateChange: number;
   priorityID: number;
   profileID: number;
   reoccurID: number;
   requestDescription: string;
   requestDropdown1: string;
   requestDropdown2: string;
   requestDropdown3: string;
   requestField1: string;
   requestField2: string;
   requestField3: string;
   requestName: string;
   requestPhone: string;
   statusID: number;
   taskImage: string;
   userID: number;
   /**
    * Extra Fields that are either computed or not part of the original TaskEntity
    */
   assets: Array<TaskTemplateAsset>;
   recurrences: Array<Recurrence>;
   invoices: Array<InvoiceEntity>;
   hint?: {
      taskID?: number;
      taskName?: string;
      taskDescription?: string;
      assetName?: string;
      locationName?: string;
      partName?: string;
   };
   assignedTo: TemplateAssignment;
   schedules: Array<ScheduleEntity>;

   checklistDueDateCalendarOrder: number;
   startedByUser: number;

   //TODO JIT remove these properties once Tasks have been converted to JIT as well
   defermentIDs: undefined;
   extraAssetIDs: Array<number> | undefined;
   extraTimeIDs: undefined;
   finalColorStatus?: undefined;
   invoiceIDs: undefined;
   noteIDs: undefined;
   partIDs: undefined;
   partRelationIDs: undefined;
   reoccurIDs: undefined;
   instructionsUpdatedAt: number;
   instructionsUpdatedAtUserID: number;
}

export interface TaskTemplateEntityFilters {
   /**
    * Ranging from 0-6 and anything > 0 is designated as a template instead of an instance
    */
   checklistTemplates?: Array<TaskTemplateType>;
   locationIDs?: Array<number>;
   excludeDeletedAssets?: boolean;
   excludeDefaultTemplates?: boolean;
   taskIDs?: Array<number>;
   groupBy?: string;
   search?: string;
   assetName?: string;
   name?: string;
   notTaskIDs?: Array<number>;
   batchIDs?: Array<number>;
   notBatchIDs?: Array<number>;
   allowDeleted?: boolean;
   recurrenceTypes?: Array<number>;
   seasonIDs?: Array<number>;
   nextScheduleStart?: Date;
   nextScheduleEnd?: Date;
   assignmentType?: AssignmentType;
   assignmentValue?: AssignmentValue;
   partIDs?: Array<number>;
   assetIDs?: Array<number>;
}

export enum TaskTemplateType {
   pmTemplate = 1,
   woTemplate = 2,
   unPlannedWoTemplate = 4,
   cycleCount = 5,
   instructionSetTemplate = 6,
}

export enum AssignmentType {
   user = "user",
   profile = "profile",
   unassigned = "unassigned",
   dynamic = "dynamic",
}

export type AssignmentValue = number | "lastUserWhoEnteredMeterReading";

export type TaskTemplateColumnSortOptions =
   | "checklistName"
   | "asset"
   | "assignment"
   | "recurrences";

export interface TaskSeasons {
   [index: string]: Array<{ seasonName: string; seasonID: number }>;
}

export type TemplateAssignment = {
   // Assigned to an existing user.
   firstName?: string;
   lastName?: string;
   // Assigned to a deleted user.
   deleted?: true;
   // Assigned to a profile.
   profileDescription?: string;
   // Unassigned.
   unassigned?: true;
};

export enum TaskTemplateColumn {
   Assets = "assets",
   Recurrences = "recurrences",
   Assignment = "assignment",
   Invoices = "invoices",
   Schedules = "schedules",
}
