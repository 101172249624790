import type { OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Component, EventEmitter, Input, Output, computed, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   ClearFilterButtonComponent,
   DatePickerInputComponent,
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   FilterInputComponent,
   FilterInputWrapperComponent,
   FiltersWrapperComponent,
   LimbleHtmlDirective,
   SearchBoxComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { PartField } from "src/app/parts/types/field/field.types";
import type { Filter, SearchFields } from "src/app/shared/types/general.types";
import { Lookup } from "src/app/shared/utils/lookup";

@Component({
   selector: "parts-filter-selector",
   templateUrl: "./partsFilterSelector.component.html",
   styleUrls: ["./partsFilterSelector.component.scss"],
   imports: [
      FiltersWrapperComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownItemComponent,
      SearchBoxComponent,
      DropdownClearFilterItemComponent,
      FilterInputWrapperComponent,
      FilterInputComponent,
      FormsModule,
      DatePickerInputComponent,
      DropdownComponent,
      SecondaryButtonComponent,
      ClearFilterButtonComponent,
      LimbleHtmlDirective,
   ],
})
export class PartsFilterSelector implements OnInit, OnChanges {
   @Input() searchFields: SearchFields = {};
   @Input() partFilters: Array<Filter<PartField>> = [];
   @Input() partFields: Lookup<"fieldID", PartField> = new Lookup("fieldID");
   @Input() searchBarField: string = "";
   @Input() totalParts: number = 0;
   @Input() totalPartsCost: number = 0;
   @Input() optionsJsonMappedToFieldID: Map<number, Array<{ name: string }>> | undefined;
   @Output() readonly searchBarFieldChange: EventEmitter<string> = new EventEmitter();
   @Output() readonly searchFieldsChange: EventEmitter<SearchFields> = new EventEmitter();
   @Output() readonly partFiltersChange: EventEmitter<Array<any>> = new EventEmitter();
   @Output() readonly updateSearch: EventEmitter<object> = new EventEmitter();
   @Output() readonly filterParts: EventEmitter<object> = new EventEmitter();
   @Output() readonly downloadExcelOfParts: EventEmitter<object> = new EventEmitter();
   public searchPartFields: string = "";
   public filteredFields: Lookup<"fieldID", PartField> = new Lookup("fieldID");

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnChanges(changes: SimpleChanges): void {
      if (changes.partFields) {
         this.filteredFields = this.partFields;
      }
   }

   public ngOnInit(): void {
      if (this.partFields === undefined) {
         throw new Error("'partFields' is a required input");
      }
      this.partFields = this.partFields.orderBy("fieldName");
   }

   public addFilter(field: PartField | { fieldName: string; fieldTypeID?: number }) {
      // Check to make sure the filter doesn't already exist
      const filterAlreadyExists = this.partFilters.find(
         (filter) => filter.fieldName === field.fieldName,
      );
      if (!filterAlreadyExists) {
         this.searchFields[field.fieldName] = { searchValue: "" };

         this.partFilters.push({
            fieldName: field.fieldName,
            fieldSearch: "",
            field: field,
         });
         this.triggerFilterParts();
      }
   }

   public clearFilter(index?: number | undefined) {
      if (index === undefined) {
         this.partFilters = [];
         this.searchFields = {};
         this.searchBarField = "";
         this.partFiltersChange.emit(this.partFilters);
         this.searchFieldsChange.emit(this.searchFields);
         this.searchBarFieldChange.emit(this.searchBarField);
         this.updateSearch.emit();
      } else {
         const thisFilter = this.partFilters[index];
         delete this.searchFields[thisFilter.fieldName];
         this.partFilters.splice(index, 1);
      }
      this.triggerFilterParts();
   }

   public setDropdownFilter = (field: Filter<PartField>, name: string) => {
      field.fieldSearch = name;
      this.searchFields[field.fieldName] = { searchValue: name };
      this.triggerFilterParts();
   };

   public triggerFilterParts() {
      this.filterParts.emit();
   }

   public clearSearch(): void {
      this.searchBarField = "";
      this.searchBarFieldChange.emit(this.searchBarField);
      this.updateSearch.emit();
   }

   public runSearch(): void {
      this.filteredFields = this.partFields.filter((field) =>
         field.fieldName.toLowerCase().includes(this.searchPartFields.toLowerCase()),
      );
   }
}
