import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, type OnInit } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   imports: [CommonModule],
   selector: "task-assigned-to-cell",
   templateUrl: "./task-assigned-to-cell.component.html",
})
export class TaskAssignedToCellComponent implements OnInit {
   // injects
   private readonly facade = inject(TasksFacadeService);
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   // signals
   task = input.required<TaskDataViewerViewModel>();
   column = input<Column>();

   protected canAssign = false;

   public ngOnInit(): void {
      this.canAssign = this.facade.canChangeAssignment(this.task());
   }

   protected onChangeAssignment($event): void {
      this.facade.changeAssignment(this.task());
      $event.stopPropagation();
   }
}
