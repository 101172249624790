import { Injectable } from "@angular/core";
import type {
   PartLogEntity,
   PartLogEntityFilters,
} from "src/app/parts/components/shared/services/logs-api/part-log-api.models";
import { FlannelApiService } from "src/app/shared/services/flannel-api-service";

@Injectable({
   providedIn: "root",
})
export class PartLogsApiService extends FlannelApiService<
   PartLogEntity,
   PartLogEntityFilters
> {
   private constructor() {
      super("parts/logs");
   }
}
