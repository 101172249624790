<ng-container *translate="let t">
   <lim-ui-dropdown-button
      [widthFitContent]="true"
      [label]="t(filter().labelKey) || ''"
      filterDropdown
   >
      <div menu>
         @for (user of users(); track user) {
            <lim-ui-dropdown-text-item
               displayString="{{ user.firstName }} {{ user.lastName }}"
               (click)="handleSetUser(user)"
            />
         }
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-clear-filter-item (click)="handleClear()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
