import { inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { hasImageExtension } from "src/app/files/file.util";
import { FileStorageService } from "src/app/lite/local-db/resources/collection/task/file/file.storage.service";
import type {
   SyncAttachmentInstructionAdditionParams,
   SyncAttachmentInstructionDeletionParams,
   SyncCompletionFileAdditionParams,
   SyncCompletionFileDeletionParams,
   SyncInstructionalFileAdditionParams,
   SyncInstructionalFileDeletionParams,
   SyncSignatureFileParams,
   SyncTaskImageAdditionParams,
   SyncTaskImageDeletionParams,
} from "src/app/lite/local-db/resources/collection/task/file/file.storage.sync.service.params";
import {
   createFileFromFormData,
   downloadAndFormatTaskFile,
} from "src/app/lite/local-db/resources/collection/task/file/file.utils";
import { buildPathPrefixAttachmentInstructionResponse } from "src/app/lite/local-db/resources/collection/task/file/variants/attachment-instruction-response/attachment-instruction-response.utils";
import { buildPathPrefixCompletionFile } from "src/app/lite/local-db/resources/collection/task/file/variants/completion-file/completion-file.utils";
import { buildPathPrefixSignature } from "src/app/lite/local-db/resources/collection/task/file/variants/signature-instruction-response/signature-instruction-response.utils";
import { buildTaskImage } from "src/app/lite/local-db/resources/collection/task/file/variants/task-image/task-image.utils";
import { InstructionStorageService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.service";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { assert } from "src/app/shared/utils/assert.utils";

@Injectable({
   providedIn: "root",
})
export class FileStorageSyncService extends StorageSyncService {
   private readonly fileStorageService = inject(FileStorageService);
   private readonly instructionStorageService = inject(InstructionStorageService);

   public constructor() {
      super();
   }

   public async syncAttachmentInstructionFileAddition({
      fileName,
      customerID,
      taskID,
      instructionID,
   }: SyncAttachmentInstructionAdditionParams): Promise<void> {
      return this.sync(async () => {
         const taskFileData = await firstValueFrom(
            downloadAndFormatTaskFile({
               fileName,
               parentID: instructionID,
               parentType: hasImageExtension(fileName)
                  ? "attachment-instruction-response-image"
                  : "attachment-instruction-response-document",
               customerID,
               fileDownloadPathPrefix: buildPathPrefixAttachmentInstructionResponse({
                  taskID,
                  instructionID,
               }),
            }),
         );
         this.fileStorageService.addFile(taskFileData);
      });
   }

   public async syncCompletionFileAddition({
      customerID,
      taskID,
      fileName,
   }: SyncCompletionFileAdditionParams): Promise<void> {
      return this.sync(async () => {
         const taskFileData = await firstValueFrom(
            downloadAndFormatTaskFile({
               fileName,
               parentID: taskID,
               parentType: hasImageExtension(fileName)
                  ? "completion-image"
                  : "completion-document",
               customerID,
               fileDownloadPathPrefix: buildPathPrefixCompletionFile(taskID),
            }),
         );
         this.fileStorageService.addFile(taskFileData);
      });
   }

   public async syncInstructionalFileAddition({
      instructionID,
      fileName,
      formData,
   }: SyncInstructionalFileAdditionParams): Promise<void> {
      return this.sync(async () => {
         const file = createFileFromFormData(formData);
         assert(
            file !== undefined,
            `Failed to create file from form data for file "${fileName}"`,
         );

         const instruction = await firstValueFrom(
            this.instructionStorageService.getInstruction$(instructionID),
         );
         assert(
            instruction,
            `Instruction #${instructionID} not found in IDB when attempting to sync file "${fileName}"`,
         );

         /** Update the instruction with the new file name and semicolon separator. */
         instruction.itemFileName =
            instruction.itemFileName === null
               ? file.name
               : `${instruction.itemFileName};${file.name}`;

         await Promise.all([
            this.instructionStorageService.putInstruction(instruction),
            this.fileStorageService.addFile({
               fileName,
               parentType: hasImageExtension(fileName)
                  ? "instructional-image"
                  : "instructional-document",
               parentID: instructionID,
               arrayBuffer: await file.arrayBuffer(),
               mimeType: file.type,
            }),
         ]);
      });
   }

   public async syncSignatureFileAddition({
      customerID,
      instructionID,
      fileName,
      itemBatchID,
   }: SyncSignatureFileParams): Promise<void> {
      return this.sync(async () => {
         const signatureData = await firstValueFrom(
            downloadAndFormatTaskFile({
               fileName,
               parentID: instructionID,
               parentType: "signature-instruction-response",
               customerID,
               fileDownloadPathPrefix: buildPathPrefixSignature(itemBatchID),
            }),
         );
         this.fileStorageService.addFile(signatureData);
      });
   }

   public async syncTaskImageAddition({
      customerID,
      taskID,
      fileName,
   }: SyncTaskImageAdditionParams): Promise<void> {
      return this.sync(async () => {
         const taskImageData = await firstValueFrom(
            downloadAndFormatTaskFile({
               fileName,
               parentID: taskID,
               parentType: "task-image",
               customerID,
               fileDownloadPathPrefix: buildTaskImage(taskID),
            }),
         );
         this.fileStorageService.addFile(taskImageData);
      });
   }

   public async syncAttachmentInstructionFileDeletion({
      instructionID,
      fileName,
   }: SyncAttachmentInstructionDeletionParams): Promise<void> {
      return this.sync(async () => {
         const attachmentInstructionResponseFiles = await firstValueFrom(
            this.fileStorageService.getAttachmentInstructionResponseFiles$(instructionID),
         );
         const fileToDelete = attachmentInstructionResponseFiles.find(
            (file) => file.fileName === fileName,
         );
         assert(
            fileToDelete !== undefined,
            `File "${fileName}" not found in IDB when attempting to sync deletion`,
         );

         this.fileStorageService.deleteFile(fileToDelete.fileID);

         const instruction = await firstValueFrom(
            this.instructionStorageService.getInstruction$(instructionID),
         );
         assert(
            instruction !== undefined,
            `Instruction #${instructionID} not found in IDB when attempting to sync deletion`,
         );

         await this.instructionStorageService.putInstruction(instruction);
      });
   }

   public async syncCompletionFileDeletion({
      fileName,
      taskID,
   }: SyncCompletionFileDeletionParams): Promise<void> {
      return this.sync(async () => {
         const completionFiles = await firstValueFrom(
            this.fileStorageService.getCompletionFiles$(taskID),
         );
         const fileToDelete = completionFiles.find((file) => file.fileName === fileName);
         assert(
            fileToDelete !== undefined,
            `File "${fileName}" not found in IDB when attempting to sync deletion`,
         );

         this.fileStorageService.deleteFile(fileToDelete.fileID);
      });
   }

   public async syncInstructionalFileDeletion({
      instructionID,
      fileName,
   }: SyncInstructionalFileDeletionParams): Promise<void> {
      return this.sync(async () => {
         const instructionalFiles = await firstValueFrom(
            this.fileStorageService.getInstructionalFiles$(instructionID),
         );
         const fileToDelete = instructionalFiles.find(
            (file) => file.fileName === fileName,
         );
         assert(
            fileToDelete !== undefined,
            `File "${fileName}" not found in IDB when attempting to sync deletion`,
         );

         this.fileStorageService.deleteFile(fileToDelete.fileID);

         const instruction = await firstValueFrom(
            this.instructionStorageService.getInstruction$(instructionID),
         );
         assert(
            instruction !== undefined,
            `Instruction #${instructionID} not found in IDB when attempting to sync deletion`,
         );

         /** Remove file name and separator ';' from the instruction. */
         instruction.itemFileName =
            instruction.itemFileName?.replace(`${fileName};`, "") ?? null;
         await this.instructionStorageService.putInstruction(instruction);
      });
   }

   public async syncTaskImageDeletion({
      taskID,
   }: SyncTaskImageDeletionParams): Promise<void> {
      return this.sync(async () => {
         const fileToDelete = await firstValueFrom(
            this.fileStorageService.getTaskImage$(taskID),
         );
         assert(
            fileToDelete !== undefined,
            `Task image not found in IDB when attempting to sync deletion`,
         );

         this.fileStorageService.deleteFile(fileToDelete.fileID);
      });
   }
}
