import { unitDescriptionDtoSchema } from "src/app/parts/unit-of-measure/unit-of-measure.dto";
import { z } from "zod";

export const purchasableDtoBase = z.object({
   id: z.number(),
   partID: z.number(),
   name: z.string(),
   orderUnitDescription: unitDescriptionDtoSchema,
});

export const purchasableMeasuredDto = purchasableDtoBase;

export const purchasableCountedDto = purchasableDtoBase.extend({
   size: z.number(),
});

export const purchasableCountedAndMeasuredDto = purchasableCountedDto.extend({
   providedSizeUnitID: z.number(),
});

export const purchasableDto = z.union([
   purchasableCountedDto.strict(),
   purchasableMeasuredDto.strict(),
   purchasableCountedAndMeasuredDto.strict(),
]);

const createPropertyOmission = { id: true, partID: true } as const;
export const createPurchasableMeasuredBodyDtoSchema =
   purchasableMeasuredDto.omit(createPropertyOmission);
export const createPurchasableCountedBodyDtoSchema =
   purchasableCountedDto.omit(createPropertyOmission);
export const createPurchasableCountedAndMeasuredBodyDtoSchema =
   purchasableCountedAndMeasuredDto.omit(createPropertyOmission);
export const createPurchasableBodyDtoSchema = z.union([
   createPurchasableMeasuredBodyDtoSchema.strict(),
   createPurchasableCountedBodyDtoSchema.strict(),
   createPurchasableCountedAndMeasuredBodyDtoSchema.strict(),
]);

const updatePropertyOmission = { id: true, partID: true } as const;
export const updatePurchasableMeasuredBodyDtoSchema = purchasableMeasuredDto
   .partial()
   .omit(updatePropertyOmission);
export const updatePurchasableCountedBodyDtoSchema = purchasableCountedDto
   .partial()
   .omit(updatePropertyOmission);
export const updatePurchasableCountedAndMeasuredBodyDtoSchema =
   purchasableCountedAndMeasuredDto.partial().omit(updatePropertyOmission);

export const updatePurchasableBodyDtoSchema = z.union([
   updatePurchasableMeasuredBodyDtoSchema.strict(),
   updatePurchasableCountedBodyDtoSchema.strict(),
   updatePurchasableCountedAndMeasuredBodyDtoSchema.strict(),
]);

export const deletePurchasableDtoSchema = purchasableDtoBase.pick({
   id: true,
});
