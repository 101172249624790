import { formatNumber } from "@angular/common";
import { inject, Pipe, type PipeTransform } from "@angular/core";
import { LocaleService } from "src/app/languages/locale/locale.service";

@Pipe({
   standalone: true,
   name: "stockUnitsRounding",
})
export class StockUnitsRoundingPipe implements PipeTransform {
   private static readonly MIN_INTEGER_DIGITS = 1;
   private static readonly MIN_FRACTION_DIGITS = 0;
   private static readonly MAX_FRACTION_DIGITS = 2;
   private static readonly digitsInfo = StockUnitsRoundingPipe.createDigitsInfo();
   private static readonly smallestStandardNumber =
      StockUnitsRoundingPipe.createSmallestStandardNumber();

   private readonly localeService = inject(LocaleService);

   public transform(input: number): string {
      if (input < StockUnitsRoundingPipe.smallestStandardNumber) {
         return this.transformSmallNumber(input);
      }

      return formatNumber(
         input,
         this.localeService.language().locale,
         StockUnitsRoundingPipe.digitsInfo,
      );
   }

   public transformSmallNumber(input: number): string {
      const scientificNotation = input.toExponential();
      const [, exponent] = scientificNotation.split("e");
      const numDecimals = Math.max(0, -Number(exponent));

      return formatNumber(
         input,
         this.localeService.language().locale,
         `${StockUnitsRoundingPipe.MIN_INTEGER_DIGITS}.${numDecimals}-${numDecimals}`,
      );
   }

   private static createDigitsInfo(): string {
      return `${StockUnitsRoundingPipe.MIN_INTEGER_DIGITS}.${StockUnitsRoundingPipe.MIN_FRACTION_DIGITS}-${StockUnitsRoundingPipe.MAX_FRACTION_DIGITS}`;
   }

   private static createSmallestStandardNumber(): number {
      return Math.pow(10, -StockUnitsRoundingPipe.MAX_FRACTION_DIGITS);
   }
}
