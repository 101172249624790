import type { OnChanges, SimpleChanges } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   CheckboxComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { ManageLang } from "src/app/languages/services/manageLang";
import { GatherText } from "src/app/shared/components/global/gatherTextModal/gatherText.modal.component";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTags } from "src/app/tasks/services/manageTags";
import { TagEditorService } from "src/app/tasks/services/tag-editor/tag-editor.service";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

export type CustomTagListInput = {
   sources: Array<string | false>;
   selectOne?: boolean;
   advancedSettings?: boolean;
   renameTag?: any;
   clickTag: (tag: { name: string; tagged: boolean }) => void;
   deleteTag?: (tag: { name: string; tagged: boolean }) => void;
};

@Component({
   selector: "custom-tag-list",
   templateUrl: "./customTagList.element.component.html",
   styleUrls: ["./customTagList.element.component.scss"],
   imports: [
      TooltipDirective,
      IconComponent,
      DropdownDividerComponent,
      SearchBoxComponent,
      DropdownItemComponent,
      CheckboxComponent,
      LimbleHtmlDirective,
      OrderByPipe,
   ],
})
export class CustomTagList implements OnChanges {
   @Input() public data: CustomTagListInput | undefined;
   protected tags: Array<{ name: string; tagged: boolean }> = [];
   public superUser;
   public searchBar;
   public allTags;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageTags = inject(ManageTags);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);
   private readonly tagEditorService = inject(TagEditorService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnChanges(changes: SimpleChanges): void {
      if (changes.data === undefined) {
         return;
      }

      if (
         changes.data.previousValue?.selectOne !== changes.data.currentValue?.selectOne ||
         changes.data.previousValue?.advancedSettings !==
            changes.data.currentValue?.advancedSettings ||
         changes.data.previousValue?.sources?.[0] !==
            changes.data.currentValue?.sources?.[0]
      ) {
         this.buildData();
      }
   }

   //this sets the available tags for them to see
   setCustomTags = () => {
      this.tags = [];

      const tags = this.manageUser.getCurrentUser().tags.split("@");

      const customTags: any = [];

      for (const key in tags) {
         if (tags[key].length > 0) {
            const obj: any = {};
            obj.name = `@${tags[key]}`;
            const test = `${obj.name};`;
            if (
               this.data?.sources?.[0] !== false &&
               this.data?.sources?.[0]?.toLowerCase().includes(test.toLowerCase())
            ) {
               obj.tagged = true;
            } else {
               obj.tagged = false;
            }
            customTags.push(obj);
         }
      }

      this.allTags = customTags;

      if (this.searchBar && this.searchBar != "") {
         this.tags = customTags.filter((item) =>
            item.name.toLowerCase().includes(this.searchBar.trim().toLowerCase()),
         );
      } else {
         this.tags = this.allTags;
      }

      this.manageTags.tags$ = this.tags;
   };

   buildData = () => {
      if (this.data === undefined) {
         //data hasn't loaded yet so don't do anything
         return;
      }

      this.setCustomTags();

      this.data.selectOne = this.data.selectOne ?? false; //allow the list to pick one or pick many tags :)
      this.data.advancedSettings = this.data.advancedSettings ?? false; //allows the list to show advanced settings

      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
   };

   //the function that gets called when a custom tag is clicked
   clickTag = (tag) => {
      this.data?.clickTag(tag);
   };

   protected editTag(tag: { name: string; tagged: boolean }): void {
      this.tagEditorService.openEditTagModal(
         tag,
         this.data?.sources ?? [],
         this.tags,
         this.renameTag.bind(this),
         this.data?.deleteTag,
      );
   }

   protected renameTag = (oldName: string, tag: { name: string; tagged: boolean }) => {
      this.data?.renameTag?.(oldName, tag);
   };

   createTag = () => {
      if (!this.credService.checkCredGlobal(this.credService.Permissions.ManageRoles)) {
         this.alertService.addAlert(
            this.lang().WhoopsOnlyASuperUserCanDoThisAction,
            "warning",
            6000,
         );
         return;
      }
      const title = this.lang().createTagTitle;
      const message = false;
      const message2 = this.lang().createTagTitleMsg;
      const warning = "";

      const instance = this.modalService.open(GatherText);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: message,
            message2: message2,
            title: title,
            warning: warning,
            data: {
               currentText: "",
               singleLine: true,
               buttonText: false,
            },
         },
      };

      instance.result.then((result) => {
         if (result && result.length > 0) {
            const replace1 = new RegExp("@", "g");
            let newResult = result.replace(replace1, ""); //remove @ as a safety precaution because tags should only have that at the beginning
            const replace2 = new RegExp('"', "g");
            newResult = newResult.replace(replace2, ""); //we remove all " because it causes problems.
            const replace3 = new RegExp(";", "g");
            newResult = newResult.replace(replace3, ""); //we remove all ; because it causes problems.
            const replace4 = new RegExp("[\\[\\]~`']", "g");
            newResult = newResult.replace(replace4, ""); //we remove all [] because it causes problems.

            newResult = `@${newResult};`; //Always add on the @
            //first see if the tag already exists:
            let found = false;
            for (const tag of this.tags) {
               if (
                  `${tag.name};` === newResult ||
                  `${tag.name.toLowerCase()};` === newResult.toLowerCase()
               ) {
                  found = true;
                  if (tag.tagged == false) {
                     tag.tagged = true;
                  }
               }
            }
            if (!found) {
               //wasn't found so let's add it.
               this.addCustomTag(newResult).then((answer) => {
                  if (answer.data.success == true) {
                     const newTag = newResult.substring(0, newResult.length - 1);

                     this.manageUser.getCurrentUser().tags =
                        this.manageUser.getCurrentUser().tags + newTag;

                     for (const tag of this.tags) {
                        if (`${tag.name};` === newResult) {
                           tag.tagged = true;
                        }
                     }

                     this.setCustomTags();
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
            }
         }
      });
   };

   private async addCustomTag(tag: string): Promise<AxiosResponse> {
      const regex = new RegExp('"', "g"); //as a safety precaution we remove all " because it causes problems.
      const tagCleaned = tag.replace(regex, "");

      const post = axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "addCustomTag",
         },
         data: {
            tag: tagCleaned,
         },
      });

      return post;
   }
}
