<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-selection-panel [title]="message">
         <div panel-subtitle>
            <span class="select-all-options">
               @if (!singleSelection) {
                  <a (click)="markAllSelection()" class="text-btn">{{
                     lang().SelectAll
                  }}</a>
               }
               <div class="text-btn-divider">|</div>
               @if (!singleSelection) {
                  <a (click)="clearSelection()" class="text-btn">{{
                     lang().DeselectAll
                  }}</a>
               }
            </span>
         </div>
         <ng-container selection-panel-options>
            <lim-ui-search-box
               [placeholder]="lang().Search"
               [(searchVal)]="search"
               [formControl]="searchControl"
               class="task-search"
               [focusOnLoad]="true"
            />
         </ng-container>
         <ng-container selection-panel-content>
            @if (noSearchResults) {
               <div>
                  <no-search-results />
               </div>
            }
            <div class="loading-icon">
               @if (isLoading) {
                  <lim-ui-loading-animation />
               }
            </div>
            @if (treeData?.length && !noSearchResults && hierarchyOptions) {
               <hierarchy-container
                  #hierarchy
                  [treeData]="treeData"
                  [options]="hierarchyOptions"
                  [fetchMore]="fetchMoreTasksAtLocation"
                  [hidden]="isLoading"
               />
            }
         </ng-container>
      </lim-ui-selection-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer class="pick-tasks-footer">
      <lim-ui-secondary-button (click)="close()">
         {{ lang().Cancel }}</lim-ui-secondary-button
      >
      <lim-ui-primary-button (click)="submit()">{{
         lang().Select
      }}</lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
