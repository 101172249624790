@if (part) {
   <lim-ui-entity-viewer-modal
      description-color-class="purple"
      [entityTitle]="
         part.partDeleted ? part.partName + '-' + lang().PartWasDeleted : part.partName
      "
      watermarkIcon="gears"
      [showCloseModalButton]="showCloseModalButton"
      (closeModalEvent)="emitCloseModal()"
      class="scroll-height-inheritance"
   >
      <ng-container header-title>
         <span
            [limbleHtml]="
               part.partDeleted
                  ? part.partName + '-' + lang().PartWasDeleted
                  : part.partName
            "
         ></span>
      </ng-container>
      <ng-container entity-image>
         @if (uploadObj) {
            <image-tile
               [uploadObject]="uploadObj"
               [image]="partImageObj"
               [canEdit]="!uploadObj.viewOnly"
               type="medium"
            />
         }
      </ng-container>

      <ng-container header-buttons>
         <lim-ui-minimal-icon-button
            class="lim-ui-hide-on-mobile"
            icon="qrCodeRegular"
            iconSize="medium-large"
            limUiTooltip="{{ lang().PrintQRCodesForLookingUpPartTooltip }}"
            (click)="viewPartQRCode(part)"
         />
         <lim-ui-minimal-icon-button
            icon="gearRegular"
            iconSize="medium-large"
            (click)="settings(part)"
            limUiTooltip="{{ lang().Settings }}"
            placement="top"
         />
      </ng-container>

      <ng-container entity-viewer>
         <div class="tab-bar-container">
            <lim-ui-tab-bar
               [selectedTabId]="selectedTabId"
               [tabList]="pageTabs"
               (selectedTabIdChange)="updateSelectedTab($event)"
               class="tab-bar"
            >
               @if (selectedTabId === pageTabs[0].id) {
                  <div tabHeader class="tab-bar-content">
                     <span>
                        {{ lang().InformationPartTabIntro }}
                     </span>
                     <div class="right-align-buttons">
                        @if (part) {
                           <lim-ui-icon-button
                              class="lim-ui-hide-on-mobile"
                              limUiTooltip="{{ part.partName }} - {{
                                 lang().PrintPartInformationTab
                              }}"
                              icon="printRegular"
                              print-div
                              attr.target="partInfo{{ part.partID }}"
                           />
                        }
                     </div>
                  </div>
               }

               @if (selectedTabId === pageTabs[1].id && !isUnitOfMeasureVisible()) {
                  <div tabHeader>
                     {{ i18n().t("BelowIsAListOfPOsAssociatedWithThisPart") }}
                  </div>
               }
               @if (selectedTabId === pageTabs[1].id && isUnitOfMeasureVisible()) {
                  <div tabHeader>
                     {{ i18n().t("ListOfPurchasablesAndPOsAssociatedWithPart") }}
                  </div>
               }

               @if (selectedTabId === pageTabs[2].id) {
                  <div tabHeader>
                     {{ lang().BelowIsAListOfVendorsThisPartIsAssociatedWith }}
                  </div>
               }

               @if (selectedTabId === pageTabs[3].id) {
                  <div tabHeader>
                     {{ lang().BelowIsAListOfAssetsThisPartIsAssociatedWith }}
                  </div>
               }

               @if (selectedTabId === pageTabs[4].id) {
                  <div tabHeader class="tab-bar-content">
                     <span>{{ lang().PartTabLogHeadingMsg }}</span>
                     <div class="right-align-buttons">
                        <lim-ui-icon-button
                           class="lim-ui-hide-on-mobile"
                           icon="cloudArrowDown"
                           (click)="downloadPartHistory()"
                           limUiTooltip="{{ lang().ExportListToCSV }}"
                           placement="left"
                        />
                     </div>
                  </div>
               }

               @if (selectedTabId === pageTabs[5].id) {
                  <div tabHeader class="tab-bar-content">
                     <span>{{ lang().PartTabReportsHeadingMsg }}</span>
                     <div class="right-align-buttons">
                        @if (part) {
                           <lim-ui-icon-button
                              class="lim-ui-hide-on-mobile"
                              limUiTooltip="{{ part.partName }} - {{
                                 lang().PrintPartReportsTab
                              }}"
                              icon="printRegular"
                              print-div
                              attr.target="partReportsPrint{{ part.partID }}"
                           />
                        }
                     </div>
                  </div>
               }
            </lim-ui-tab-bar>
         </div>

         @if (scrollableContent) {
            <lim-ui-scroll-container [thin]="true">
               <ng-container *ngTemplateOutlet="mainContent" />
            </lim-ui-scroll-container>
         } @else {
            <div class="main-content">
               @if (selectedTabId === pageTabs[0].id) {
                  <div>
                     <part-information-tab
                        [part]="part"
                        [restrict]="restrict"
                        (changeTab)="setToDifferentTab($event)"
                     />
                  </div>
               }

               @if (selectedTabId === pageTabs[1].id) {
                  <div>
                     <part-purchase-orders-tab
                        [partID]="part?.partID"
                        [dataLogOptions]="dataLogOptions"
                     />
                  </div>
               }

               @if (selectedTabId === pageTabs[2].id) {
                  <div>
                     <part-vendors-tab
                        [partID]="part?.partID"
                        [dataLogOptions]="dataLogOptions"
                     />
                  </div>
               }

               @if (selectedTabId === pageTabs[3].id) {
                  <div>
                     <part-assets-tab [restrict]="restrict" [part]="part" />
                  </div>
               }

               @if (selectedTabId === pageTabs[4].id) {
                  <div>
                     <part-log-tab-legacy [part]="part" [logs]="[]" />
                  </div>
               }

               @if (selectedTabId === pageTabs[5].id) {
                  <div>
                     <part-reports-tab-legacy [part]="part" />
                  </div>
               }
            </div>
         }

         <ng-template #mainContent>
            <div class="main-content">
               @if (selectedTabId === pageTabs[0].id) {
                  <div>
                     <part-information-tab
                        [part]="part"
                        [restrict]="restrict"
                        (changeTab)="setToDifferentTab($event)"
                     />
                  </div>
               }

               @if (selectedTabId === pageTabs[1].id) {
                  <div>
                     <part-purchase-orders-tab
                        [partID]="part?.partID"
                        [dataLogOptions]="dataLogOptions"
                     />
                  </div>
               }

               @if (selectedTabId === pageTabs[2].id) {
                  <div>
                     <part-vendors-tab
                        [partID]="part?.partID"
                        [dataLogOptions]="dataLogOptions"
                     />
                  </div>
               }

               @if (selectedTabId === pageTabs[3].id) {
                  <div>
                     <part-assets-tab [restrict]="restrict" [part]="part" />
                  </div>
               }

               @if (selectedTabId === pageTabs[4].id) {
                  <div>
                     <part-log-tab-legacy [part]="part" [logs]="[]" />
                  </div>
               }

               @if (selectedTabId === pageTabs[5].id) {
                  <div>
                     <part-reports-tab-legacy [part]="part" />
                  </div>
               }
            </div>
         </ng-template>
      </ng-container>
   </lim-ui-entity-viewer-modal>
}
