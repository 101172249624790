import {
   ChangeDetectionStrategy,
   Component,
   computed,
   input,
   output,
   type Signal,
} from "@angular/core";
import { IconComponent, type Aliases, type Colors, type Sizes } from "@limblecmms/lim-ui";
import { ButtonModule } from "primeng/button";
import type {
   ButtonSize,
   ButtonType,
   IconPosition,
} from "src/app/shared/empowered/base/button/button.models";

@Component({
   selector: "e-button",
   templateUrl: "./button.component.html",
   imports: [ButtonModule, IconComponent],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
   /** The label of the button. */
   label = input<string>();

   /** Whether the button is disabled. */
   disabled = input<boolean>(false);

   /** Whether the button is a link. */
   link = input<boolean>(false);

   /** The type of the button. */
   type = input<ButtonType>("primary");

   /** The size of the button. */
   size = input<ButtonSize>("medium");

   /** Custom styles for the button. */
   style = input<object>({});

   /**  Specifies the variant of the component.  */
   outlined = input<boolean>(false);

   /** The tabindex of the button. */
   tabindex = input<number>(0);

   /** Whether the button should autofocus. */
   autofocus = input<boolean>(false);

   /** The aria-label of the button. */
   ariaLabel = input<string>();

   /** The icon name to show on the button. */
   iconName = input<Aliases | null>();

   /**
    * The color of the icon.
    * NOTE: This depends on also providing the icon name in the `icon` input.
    */
   iconColor = input<Colors>("white");

   /**
    * The position of the icon.
    * NOTE: This depends on also providing the icon name in the `icon` input.
    */
   iconPosition = input<IconPosition>("left");

   /**
    * The size of the icon.
    * NOTE: This depends on also providing the icon name in the `icon` input.
    */
   iconSize = input<Sizes>("medium");

   /** Emits when the button is clicked. */
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   readonly click = output<MouseEvent>();

   /** Emits when the button receives focus. */
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   readonly focus = output<FocusEvent>();

   /** Emits when the button loses focus. */
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   readonly blur = output<FocusEvent>();

   /**
    *  Computes the button size based on the input size, since when we is set to medium
    *  it should be undefined in PrimeNg
    */
   buttonSize: Signal<"small" | "large" | undefined> = computed(() => {
      const size = this.size();
      if (size === "medium") {
         return undefined;
      }
      if (size === "extra-small") {
         return "small";
      }
      return size;
   });

   /**
    *  Computes the icon position based on the input position since we need to add a class to the icon
    */
   iconPositionClass: Signal<string | undefined> = computed(() => {
      const position = this.iconPosition();
      return position ? `p-button-icon-${position}` : undefined;
   });

   /** Computes the size class of the button based on the input size */
   buttonStyle: Signal<any> = computed(() => {
      let style = this.style() || {};

      const size = this.size();
      if (size === "extra-small") {
         style = { ...style, height: "24px", width: "28px" };
      }
      return style;
   });

   /** Handles click event */
   protected handleClick(event: MouseEvent) {
      this.click.emit(event);
      event.stopPropagation(); // prevents event from bubbling to the default Angular click handler
   }

   /** Handles focus event */
   protected handleFocus(event: FocusEvent) {
      this.focus.emit(event);
   }

   /** Handles blur event */
   protected handleBlur(event: FocusEvent) {
      this.blur.emit(event);
   }
}
