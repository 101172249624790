import { inject, Injectable } from "@angular/core";
import {
   hasImageExtension,
   type DocumentFile,
   type PictureFile,
} from "src/app/files/file.util";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { WorkRequestSubmissionEntity } from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.models";
import type { partRequestedInfo } from "src/app/tasks/components/workRequests/work-request-submission-modal/work-request-submission-data-viewer.model";
import { ManageUser } from "src/app/users/services/manageUser";

export enum WorkRequestSubmissionStatus {
   PENDING = "pending",
   APPROVED = "approved",
   REJECTED = "rejected",
}

@Injectable({
   providedIn: "root",
})
export class WorkRequestSubmissionViewModelMapperService {
   private readonly translationService = inject(TranslationService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUser = inject(ManageUser);
   private readonly managePart = inject(ManageParts);

   private readonly i18n = this.translationService.i18n;

   public getWorkRequestSubmissionModalTitle(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): string {
      if (workRequestSubmission?.status === WorkRequestSubmissionStatus.PENDING) {
         return this.i18n().t("PendingWorkRequestTitle");
      } else if (workRequestSubmission?.status === WorkRequestSubmissionStatus.APPROVED) {
         return this.i18n().t("ApprovedWorkRequestTitle");
      } else if (workRequestSubmission?.status === WorkRequestSubmissionStatus.REJECTED) {
         return this.i18n().t("DeclinedWorkRequestTitle");
      }
      return "";
   }

   public getIsRejected(workRequestSubmission: WorkRequestSubmissionEntity): boolean {
      return workRequestSubmission?.status === WorkRequestSubmissionStatus.REJECTED;
   }

   public getRequestTitle(workRequestSubmission: WorkRequestSubmissionEntity): string {
      return workRequestSubmission?.requestTitle ?? "";
   }

   public getRequestDescription(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): string {
      return workRequestSubmission?.requestDescription ?? "";
   }

   private getFileURL(customerID: number, filePath: string): string {
      return `viewFile.php?f=upload-${customerID}/work-requests-temp/${filePath}&wr=true`;
   }

   public getPictures(workRequestSubmission: WorkRequestSubmissionEntity): PictureFile[] {
      return (workRequestSubmission?.files ?? [])
         .filter((file) => hasImageExtension(file.filePath))
         .map((file) => ({
            fileName: file.filePath,
            getURL: this.getFileURL(workRequestSubmission.customerID, file.filePath),
         }));
   }

   public getDocuments(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): DocumentFile[] {
      return (workRequestSubmission?.files ?? [])
         .filter((file) => !hasImageExtension(file.filePath))
         .map((file) => ({
            fileName: file.filePath,
            getURL: this.getFileURL(workRequestSubmission.customerID, file.filePath),
         }));
   }

   public getSubmittedOn(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): number | undefined {
      const timeUnix = workRequestSubmission?.requestInformation?.timeUnix;
      if (!timeUnix) {
         return undefined;
      }

      return timeUnix * 1000; // Convert to milliseconds
   }

   public getPortalCreatedFromName(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): string | undefined {
      if (!workRequestSubmission?.locationID) {
         return undefined;
      }

      // TODO TASK-1389: Add a column in tasks-flannel to have it fetch the location name along with the work request submission.
      return this.manageLocation.getLocation(workRequestSubmission.locationID)
         ?.locationName;
   }

   public getReviewedByUserName(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): string | undefined {
      return workRequestSubmission?.reviewedByUserId
         ? this.manageUser.getUserFullName(workRequestSubmission.reviewedByUserId)
         : undefined;
   }

   public getPartsRequestedInfo(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): partRequestedInfo[] {
      return (
         workRequestSubmission?.parts?.map((part) => ({
            partId: part.partId,
            partName: this.managePart.getPart(part.partId)?.partName ?? "",
            requestedQuantity: part.requestedQuantity,
         })) ?? []
      );
   }
}
