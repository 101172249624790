<lim-ui-modal>
   <div class="modal-container">
      <div class="modal-header">
         <div class="modal-title">
            <ai-badge />
            <ng-content select="[modalTitle]" />
         </div>
         <div class="modal-close-button">
            <lim-ui-icon
               (click)="close.emit()"
               icon="xmarkLargeRegular"
               iconSize="medium-large"
            />
         </div>
      </div>
      <ng-content />
      <ng-content select="[modalFooter]" />
   </div>
</lim-ui-modal>
