import { inject, Injectable } from "@angular/core";
import {
   mutationOptions,
   QueryClient,
   queryOptions,
} from "@tanstack/angular-query-experimental";
import { lastValueFrom, map, take } from "rxjs";
import { TempCurrencyCode } from "src/app/purchasing/currency/utils/temp-currency-code";
import { validate } from "src/app/shared/rxjs-operators/validate";
import type { SetCurrencyResponseDto } from "src/app/users/models/php/account-settings.dto";
import { AccountSettingsPhpApiService } from "src/app/users/services/api/account-settings/account-settings-php-api.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { z } from "zod";

@Injectable({ providedIn: "root" })
export class AccountSettingsQueriesService {
   private readonly manageUser = inject(ManageUser);
   private readonly phpApiService = inject(AccountSettingsPhpApiService);
   private readonly queryClient = inject(QueryClient);

   public currencyDetail() {
      return queryOptions({
         queryKey: ["account_settings", "currency_details"] as const,
         queryFn: async (): Promise<{ currencyCode: string }> =>
            lastValueFrom(
               this.manageUser.currentUserChanges$.pipe(
                  take(1),
                  map((user) => user.currency),
                  validate(z.object({ currencyID: z.number() })),
                  map((currency) => ({
                     currencyCode: TempCurrencyCode[currency.currencyID],
                  })),
               ),
            ),
      });
   }

   public currencyUpdate() {
      return mutationOptions({
         mutationKey: ["account_settings", "currency_update"] as const,
         mutationFn: async (currencyCode: string): Promise<SetCurrencyResponseDto> =>
            lastValueFrom(
               this.phpApiService.setCurrency({
                  currencyCode,
                  currencyID: TempCurrencyCode[currencyCode],
               }),
            ),
         onSuccess: ({ currency: { currencyID } }) => {
            this.queryClient.setQueryData(this.currencyDetail().queryKey, () => ({
               currencyCode: TempCurrencyCode[currencyID],
            }));
         },
      });
   }
}
