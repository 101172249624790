import type { Signal } from "@angular/core";
import { computed, inject, Injectable, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   catchError,
   combineLatest,
   finalize,
   type Observable,
   switchMap,
   tap,
} from "rxjs";
import { DataViewerStateService } from "src/app/shared/data-viewer/data-viewer-state.service";
import type { ListResponse } from "src/app/shared/services/flannel-api-service";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskViewModelFactoryService } from "src/app/tasks/components/shared/services/task-view-model-factory/task-view-model-factory.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable()
export class PartTasksDataService {
   private readonly dataViewerState = inject(DataViewerStateService);
   private readonly tasksApi = inject(TasksApiService);
   private readonly taskViewModelFactory = inject(TaskViewModelFactoryService);
   private readonly manageUser = inject(ManageUser);

   public isLoading = signal(false);

   public readonly tasksRequest$: Observable<Partial<ListResponse<TaskEntity>>> =
      combineLatest([this.dataViewerState.requestOptions$]).pipe(
         tap(() => {
            this.isLoading.set(true);
         }),
         switchMap(([requestOptions]) =>
            this.tasksApi.getList(requestOptions).pipe(
               finalize(() => {
                  this.isLoading.set(false);
               }),
            ),
         ),
         catchError((error, data) => {
            console.error("Error fetching tasks:", error);
            return data;
         }),
      );
   public tasksResponse = toSignal<Partial<ListResponse<TaskEntity>>>(this.tasksRequest$);
   public readonly tasks: Signal<Array<TaskDataViewerViewModel>> = computed(
      () =>
         this.tasksResponse()?.data?.map((taskEntity) =>
            this.taskViewModelFactory.getTaskDataViewerViewModel(taskEntity),
         ) ?? [],
   );
   public readonly tasksTotal = computed(() => this.tasksResponse()?.total ?? 0);

   private readonly userChangesSignal = toSignal(this.manageUser.currentUserChanges$);
   public readonly currencySymbol = computed(() => {
      return this.userChangesSignal().currency?.symbol;
   });
}
