@if (getPartsPendingPOs(); as pendingPOs) {
   @if (currentUser.workOrderUser !== 1 && pendingPOs.length > 0) {
      <span class="part-list-item-po-wrapper">
         @if (pendingPOs.length >= 2) {
            <span (click)="showPoOnPart.set(!showPoOnPart())">
               {{ pendingPOs.length }}
               {{ lang().POs }}
               <lim-ui-icon
                  [icon]="showPoOnPart() ? 'chevronUp' : 'chevronDown'"
                  iconSize="small"
               />
            </span>
         }
         @if (showPoOnPart()) {
            @for (pending of pendingPOs; track pending) {
               <span class="part-list-item-po-details">
                  <lim-ui-icon icon="filePowerpoint" iconSize="small" />
                  <span class="po-number" (click)="popPoComponent.emit(pending.poID)"
                     >{{ lang().PO }} - {{ pending.poNumber }}</span
                  >
                  -
                  <span
                     limUiTooltip="{{
                        lang().HowManyPartsAreLeftToUseFromThisPurchaseOrder
                     }}"
                  >
                     {{
                        (pending.extraBatch?.partQty ?? 0) -
                           (pending.extraBatch?.partQtyUsed ?? 0)
                     }}
                     @if (unitOfMeasureService.isFeatureEnabled()) {
                        <span
                           [limUiTooltip]="
                              pending.extraBatch?.partID | partUnitOfMeasure: 'singular'
                           "
                        >
                           {{ pending.extraBatch?.partID | partUnitOfMeasure: "short" }}
                        </span>
                     }
                     {{ lang().available }} -
                  </span>
                  ({{ pending.currentState?.name }})
               </span>
            }
         }
      </span>
   }
}
