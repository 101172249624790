import { Component, inject, type OnDestroy, type OnInit, signal } from "@angular/core";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   LimbleHtmlDirective,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { assert } from "src/app/shared/utils/assert.utils";

/**
 * Location type defined here which is tightly coupled to data format
 * provided by manageLocation service. We should eventually replace this and
 * point to a global Location type once we can migrate away from manageLocation service
 */
type Location = {
   locationID: number;
   locationNameWithRegions: string;
   locationNameWithRegionsIcon: string;
   selected?: unknown;
   nodes?: Array<unknown>;
   metaField1?: string | null;
   metaField2?: string | null;
   metaField3?: string | null;
};

@Component({
   selector: "location-filter",
   templateUrl: "./location-filter.component.html",
   imports: [
      DropdownButtonComponent,
      DropdownItemComponent,
      DropdownDividerComponent,
      DropdownClearFilterItemComponent,
      SearchBoxComponent,
      LimbleHtmlDirective,
      TranslateDirective,
   ],
})
export class LocationFilterComponent
   extends BaseFilterComponent
   implements OnInit, OnDestroy
{
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageObservables = inject(ManageObservables);

   public locations = signal<Array<Location>>([]);
   public locationsDropdown = signal<Array<Location>>([]);
   public searchLocations = signal<string>("");
   protected state = signal<Location | undefined>(undefined);

   private locationWatchVarSub: Subscription | null = null;

   public ngOnInit() {
      this.locationWatchVarSub = this.manageObservables.setSubscription(
         "locationWatchVar",
         () => {
            this.locations.set(
               orderBy(this.manageLocation.getLocations(), "locationName"),
            );
            this.buildLocationsDropdown();
         },
      );
   }

   public setLocationFilter(location: Location): void {
      this.state.set(location);
      const filterValue = { [this.filter().key]: [location.locationID] };
      this.setFilter(filterValue, location.locationNameWithRegions);
   }

   public handleSearch(searchText: string) {
      this.searchLocations.set(searchText);
      this.buildLocationsDropdown();
   }

   public handleClear() {
      this.state.set(undefined);
      this.remove.emit(this.filter());
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeManySubscriptions([this.locationWatchVarSub]);
   }

   private buildLocationsDropdown(): void {
      let locationsDropdown = this.locations();
      if (this.searchLocations().length > 0) {
         locationsDropdown = this.manageFilters.filterLocationsToSearch(
            locationsDropdown,
            this.searchLocations(),
         );
      }
      assert(locationsDropdown !== undefined);
      this.locationsDropdown.set(orderBy(locationsDropdown, "locationNameWithRegions"));
   }

   private setFilter(value, activeLabel: string): void {
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value,
      });
   }
}
