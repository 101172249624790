import { Component, inject, type OnDestroy, type OnInit, signal } from "@angular/core";
import { DropdownButtonComponent, DropdownTextItemComponent } from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { ManagePriority } from "src/app/tasks/services/managePriority";

@Component({
   selector: "priority-level-filter",
   imports: [DropdownButtonComponent, DropdownTextItemComponent, TranslateDirective],
   templateUrl: "./priority-level-filter.component.html",
})
export class PriorityLevelFilterComponent
   extends BaseFilterComponent
   implements OnInit, OnDestroy
{
   private readonly managePriority = inject(ManagePriority);

   priorityLevels = signal<Array<any>>([]);

   private readonly prioritiesLoadedSub = this.managePriority.prioritiesLoaded$.subscribe(
      () => {
         const priorities = this.managePriority.getPriorityList();
         this.priorityLevels.set(priorities);
      },
   );

   public ngOnInit(): void {
      const priorities = this.managePriority.getPriorityList();
      this.priorityLevels.set(priorities);
   }

   public ngOnDestroy(): void {
      this.prioritiesLoadedSub.unsubscribe();
   }

   public onSetFilter(priorityID: number) {
      const value = { [this.filter().key]: priorityID };
      this.set.emit({
         ...this.filter(),
         activeLabel: this.managePriority
            .getPriorityList()
            .find((priority) => priority.priorityID === priorityID)?.name,
         value,
      });
   }
}
