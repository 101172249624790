<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="modal-info">
            <div class="info-group">
               <span>{{ message }}</span>

               <comment-files
                  [uploadObject]="uploadObject"
                  [files]="files"
                  icon="cloudUploadRegular"
                  label="AttachFile"
               />
            </div>

            <a class="table-link" (click)="whoToNotify()">
               {{ lang().WhoWillBeSentNotifications }}
            </a>
         </div>

         <div
            type="text"
            class="comment-area"
            [(ngModel)]="comment"
            limbleContentEditable
            data-test="addNoteText"
            id="input-field-{{ mentionUid }}"
            focusAuto
         ></div>

         <mention
            [mentionList]="mentionList"
            [userProfiles]="userProfiles"
            [updateNewMentionedList]="updateNewMentionedList"
            dropdownDirection="down"
            [mentionUid]="mentionUid"
         />

         <span class="warning">
            @if (errorMsg !== "") {
               <lim-ui-alert [title]="lang().Warning" alertType="warning">
                  {{ errorMsg }}
               </lim-ui-alert>
            }
         </span>
      </lim-ui-info-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-primary-button (click)="submit()">{{
         lang().Submit
      }}</lim-ui-primary-button>
      <lim-ui-secondary-button (click)="close()">{{
         lang().Cancel
      }}</lim-ui-secondary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
