export enum DataViewerFilterType {
   TASK_TYPE = "taskType",
   CUSTOM_TAG = "customTag",
   DATE = "date",
   ASSIGNED_TO = "assignedTo",
   COMPLETED_BY = "completedBy",
   LOCATION = "location",
   PART_LOG_TYPE = "partLogType",
   PART_FIELD = "partField",
   PRIORITY_LEVEL = "priorityLevel",
   TASK_STATUS = "taskStatus",
   USER = "user",
   POST_LOG_ACTION = "postLogAction",
   Asset = "asset",
   ID = "id",
   ASSET_FIELD_VALUES = "assetFieldValue",
   WORK_REQUEST_STATUS = "workRequestStatus",
}

export enum FilterLabelKey {
   ACTION = "Action",
   ASSIGNED_TO = "AssignedTo",
   ASSET_NAME = "AssetName",
   COMPLETED_BY = "Completed",
   COMPLETED_DATE = "CompletedDate",
   CREATED_DATE = "CreatedDate",
   CUSTOM_TAGS = "CustomTags",
   DATE = "Date",
   DATE_RANGE = "DateRange",
   DUE_DATE = "DueDate",
   EMPTY = "",
   LAST_7_DAYS = "Last7Days",
   LAST_30_DAYS = "Last30Days",
   LAST_60_DAYS = "Last60Days",
   LAST_90_DAYS = "Last90Days",
   LAST_365_DAYS = "Last365Days",
   LOCATION = "Locations",
   PRIORITY_LEVEL = "PriorityLevel",
   STATUS = "Status",
   TASK_TYPE = "Type",
   USER = "User",
   ASSET_ID = "AssetID",
   LOCATION_ID = "LocationID",
   TASK_ID = "TaskID",
   PO_ID = "POID",
   PART_ID = "PartID",
   VENDOR_ID = "VendorID",
   ASSET_INFORMATION = "AssetInformation",
}

export type DataViewerFilter = {
   /**
    * The type of filter to display
    */
   type: DataViewerFilterType;

   /**
    * The label key to translate in the UI. e.g. "AssignedTo" that will be translated to the current language
    */
   labelKey: FilterLabelKey;

   /**
    * The key  of the filter to send to the server
    */
   key: string;

   /**
    * The label to display once the filter is active. e.g. "Assigned to: John Doe"
    */
   activeLabel?: string;

   /**
    * The value of the filter
    */
   value?: any;

   /**
    * The label to display in the data log when the filter is open
    */
   dataLogLabel?: string;

   /**
    * Additional configuration props that can be used in the component consuming the filter
    */
   options?: DataViewerFilterOptions;
};

type DataViewerFilterOptions = {
   /**
    * The earliest date that can be selected in the date filter
    */
   dateBoundaries?: {
      earliestDateBoundary?: Date | undefined;
      isPaywallBoundary?: boolean;
      paywallBoundaryMessage?: string;
   };

   /**
    * The locationID to filter the assignee options in assigned-to-location-filter
    */
   locationID?: number;
   /**
    * The initial value to be applied
    */
   initialValue?: string;
};
