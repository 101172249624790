<lim-ui-search-all-wrapper>
   <lim-ui-search-box
      (searchValChange)="onSearch($event)"
      [placeholder]="lang().Search"
      [searchVal]="search()"
      searchBox
   />
   @if (isClearable() && search().length > 1) {
      <lim-ui-filter-input
         searchAllCriteria
         (clearFilterEvent)="onSearch('')"
         [filterCriteria]="search()"
      >
         <label inputLabel> {{ lang().SearchFilter }}: </label>
      </lim-ui-filter-input>
   }
</lim-ui-search-all-wrapper>
