<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="dd" id="nestable_list_1">
            @for (step of steps | orderBy: "order"; track step) {
               <div
                  (click)="focusStep(step)"
                  class="lim-ui-list-item lim-ui-selectable-list-item"
                  [ngClass]="{ 'lim-ui-selected-list-item': selectedStep == step }"
               >
                  <div class="lim-ui-list-item-content">
                     <div class="item-text-area">
                        @if (selectedStep == step) {
                           <lim-ui-icon
                              icon="check"
                              iconSize="small"
                              iconColor="success"
                           />
                        }
                        <div class="step-items">
                           <div class="step-item">
                              {{ step.name }}
                           </div>
                           @if (step.userID > 0) {
                              <div class="step-item">
                                 {{ step.displayName }}
                              </div>
                           }
                        </div>
                     </div>
                  </div>
               </div>
            }
         </div>

         @if (errorMsg) {
            <lim-ui-alert [title]="lang().Warning" alertType="danger">
               <div [limbleHtml]="errorMsg"></div>
            </lim-ui-alert>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      <lim-ui-primary-button (click)="submit()">
         {{ lang().Select }}
      </lim-ui-primary-button>
      <lim-ui-secondary-button (click)="close()">
         {{ lang().Close }}
      </lim-ui-secondary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
