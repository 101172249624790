@if (asset(); as asset) {
   <lim-ui-panel id="assetInfo{{ asset.assetID }}">
      <div class="panel-body">
         <div class="lim-ui-list-item">
            <div class="lim-ui-list-item-content">
               <div class="icon-with-text">
                  <lim-ui-icon
                     class="lim-ui-hide-on-mobile"
                     icon="cube"
                     iconSize="small"
                  />
                  <span>{{ lang().Name }}</span>
               </div>
               <div class="item-information asset-name-container">
                  <div
                     ngDefaultControl
                     class="form-control updateFieldHolder exitOnEnterKeyPress input-not-max-width"
                     [ngClass]="{
                        editUnderline:
                           creds.changeAssetName && asset.assetDeleted == 0 && !restrict,
                     }"
                     (afterEdit)="updateAssetName()"
                     [limbleContentEditable]="
                        creds.changeAssetName && asset.assetDeleted == 0 && !restrict
                     "
                     [(ngModel)]="asset.assetName"
                     stripHtml="true"
                     disableEditor="true"
                  ></div>
                  <span
                     limUiTooltip="{{ lang().LimblesInternalAssetIDTooltip }}"
                     placement="bottom"
                     class="smaller-grey-text"
                  >
                     #{{ asset.assetID }}
                  </span>
                  <asset-parent-list
                     [assetID]="asset.assetID"
                     [preventParentAccess]="preventParentAccess"
                     linkColorClass="smaller-grey-text"
                     [restrict]="restrict"
                  />
               </div>
            </div>
         </div>
         @if (asset.assetTrackHoursManually == 1 && creds.changeAssetFieldsValues) {
            <div class="lim-ui-list-item">
               <div class="lim-ui-list-item-content">
                  <div
                     class="icon-with-text larger-text-area"
                     (click)="updateHoursOfOperation(asset)"
                  >
                     <lim-ui-icon icon="clock" iconSize="small" />
                     <a class="default-link">
                        <span>{{ lang().UpdateHoursOfOperation }}</span>
                     </a>
                  </div>
               </div>
            </div>
         }
         @if (isGeoLocationListItem) {
            <div class="lim-ui-list-item">
               @if (featureMaps != 0 && (asset.geoLocation || configAssetMapCred)) {
                  <div class="lim-ui-list-item-content">
                     <div class="icon-with-text">
                        <lim-ui-icon icon="map" iconSize="small" />
                        <span>{{ lang().Map }} </span>
                     </div>
                     <div class="item-information">
                        <a class="default-link" (click)="openViewMap()">
                           <span>{{
                              asset.geoLocation
                                 ? lang().ViewMapLocation
                                 : lang().SetMapLocation
                           }}</span>
                        </a>
                        @if (asset.geoLocation) {
                           <lim-ui-minimal-icon-button
                              icon="locationArrow"
                              (click)="navigateToLocation(asset.geoLocation)"
                              iconSize="small"
                              limUiTooltip="{{ lang().NavigateToThisLocation }}"
                              placement="bottom"
                           />
                        }
                        @if (asset.geoLocation && locationMappedToArcGis) {
                           <lim-ui-icon
                              icon="lock"
                              iconSize="small"
                              limUiTooltip="{{ lang().ArcGisLockedGeoLocation }}"
                              placement="bottom"
                           />
                        }
                     </div>
                  </div>
               }
               @if (hasGL) {
                  <div class="lim-ui-list-item-content">
                     <span limUiTooltip="{{ lang().howToUpdateGL }}" placement="bottom"
                        >{{ lang().GLAbbr }}
                     </span>
                     <span [limbleHtml]="ledger"></span>
                  </div>
               }
            </div>
         }

         @if (isLoading()) {
            <ngx-skeleton-loader count="2" [theme]="skeletonThemes.fillSize" /><br />
         }
         @if (isErrorLoadingfieldsValues()) {
            <lim-ui-alert alert [title]="lang().Warning" alertType="warning">
               {{ lang().ErrorLoadingAssetFieldValues }}
            </lim-ui-alert>
         }

         <div class="asset-information-hierarchy">
            <div #limbleTreeContainer></div>
         </div>

         <div class="panel-body-bottom-div">
            @if (creds.configureAssetFields || asset.assetDeleted == 0 || !restrict) {
               <lim-ui-primary-button
                  icon="plus"
                  (click)="addField()"
                  [dataLogLabel]="'manageAssets-initiateAddCustomField-clickAddField'"
                  >{{ lang().AddAndEditFields }}</lim-ui-primary-button
               >
            }
         </div>
      </div>
   </lim-ui-panel>
}
