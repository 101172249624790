import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder/column-builder.models";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";

export type TaskInvoicesCostViewModel = {
   invoiceCost?: number;
   locationID?: number;
   currencyCode: string;
};

@Component({
   selector: "task-invoices-cost-cell",
   imports: [CommonModule, LocaleCurrencyPipe],
   templateUrl: "./task-invoices-cost-cell.component.html",
})
export class TaskInvoicesCostCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: TaskInvoicesCostViewModel;
   @Input() public column?: Column;
}
