import { inject, Injectable } from "@angular/core";
import type { Purchasable } from "src/app/parts/purchasable/purchasable.model";
import { StockUnitCalculatorFactory } from "src/app/parts/purchasable/services/stock-unit-calculator/stock-unit-calculator-factory.service";

@Injectable({ providedIn: "root" })
export class StockUnitCalculatorService {
   private readonly factory = inject(StockUnitCalculatorFactory);

   public fromPurchasable(purchasable: Purchasable): number | null {
      const calculator = this.factory.createCalculator(purchasable);
      return calculator.calculate();
   }
}
