import { Injectable } from "@angular/core";
import type { FeatureFlagMapDto } from "src/app/shared/services/feature-flags/feature-flag-map.dto";
import type { FeatureFlagMap } from "src/app/shared/services/feature-flags/feature-flag-map.types";
import { FeatureFlagSet } from "src/app/shared/services/feature-flags/feature-flag-set.model";
import { convertPascalToCamelCase } from "src/app/shared/utils/convert-pascal-to-camel-case";

@Injectable({ providedIn: "root" })
export class FeatureFlagMappingService {
   public mapDto(dto: FeatureFlagMapDto): FeatureFlagSet {
      const map = Object.fromEntries(
         Object.entries(dto).map(([key, value]) => [
            this.mapFeatureKey(key),
            this.isEnabled(value),
         ]),
      ) as FeatureFlagMap;

      return new FeatureFlagSet(map);
   }

   private mapFeatureKey(key: string): string {
      return convertPascalToCamelCase(this.removeFeaturePrefix(key));
   }

   private removeFeaturePrefix(featureName: string): string {
      return featureName.replace(/^feature/, "");
   }

   private isEnabled(value?: number | null): boolean {
      return value !== null && value !== undefined && value !== 0;
   }
}
