import type { PmSuggestionSchedule } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion-schedule/pm-suggestion-schedule";
import {
   type TimeScheduleDto,
   timeScheduleSchema,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestion-dto";

export class TimeSchedule implements PmSuggestionSchedule {
   private readonly type: "time";
   private readonly units: "daily" | "weekly" | "monthly" | "yearly";
   private readonly interval: number;

   public constructor(options: {
      units: "daily" | "weekly" | "monthly" | "yearly";
      interval: number;
   }) {
      this.type = "time";
      this.units = options.units;
      this.interval = options.interval;
   }

   public static fromDto(dto: TimeScheduleDto): TimeSchedule {
      const parsedDto = timeScheduleSchema.parse(dto);
      return new TimeSchedule({
         units: parsedDto.units,
         interval: parsedDto.interval,
      });
   }

   public toDto(): TimeScheduleDto {
      return {
         type: "time",
         units: this.units,
         interval: this.interval,
      };
   }

   public getType(): "time" {
      return "time";
   }

   public getScheduleString(): string {
      return `Every ${this.interval} ${this.getScheduleUnitsString(this.interval > 1)}`;
   }

   private getScheduleUnitsString(plural: boolean): string {
      switch (this.units) {
         case "daily":
            return plural ? "days" : "day";
         case "weekly":
            return plural ? "weeks" : "week";
         case "monthly":
            return plural ? "months" : "month";
         case "yearly":
            return plural ? "years" : "year";
         default:
            throw new Error("Invalid schedule units");
      }
   }

   public getUnits(): "daily" | "weekly" | "monthly" | "yearly" {
      return this.units;
   }

   public getInterval(): number {
      return this.interval;
   }
}
