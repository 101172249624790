import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed, input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   CheckboxComponent,
   DropdownComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageParts } from "src/app/parts/services/manageParts";
import { AlertService } from "src/app/shared/services/alert.service";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "date-reminder",
   templateUrl: "./dateReminder.element.component.html",
   styleUrls: ["./dateReminder.element.component.scss"],
   imports: [
      CheckboxComponent,
      DropdownComponent,
      DropdownTextItemComponent,
      DropdownItemComponent,
      FormsModule,
   ],
})
export class DateReminder implements OnInit {
   @Input() public field;
   @Input() public reminder;
   @Input() public type;
   public dataLogLabel = input<string>();
   public disabled = input<boolean>(false);

   public dateReminderDisplay;
   public showDateReminderDisplay;
   public customDays;
   public customWeeks;
   public customMonths;
   public isCustomDays;
   public isCustomWeeks;
   public isCustomMonths;
   public locationID;

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageParts = inject(ManageParts);
   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.dateReminderDisplay = this.setReminderDisplayValue();
      this.locationID = this.field.locationID;
      this.showDateReminderDisplay = this.field[this.reminder] !== 0;

      this.customDays = this.isCustomDays ? this.field[this.reminder] : 0;

      this.customWeeks = this.isCustomWeeks ? this.field[this.reminder] / 7 : 0;

      this.customMonths = this.isCustomMonths ? this.field[this.reminder] / 30 : 0;
   }

   updateDateReminderEnabled = () => {
      if (this.disabled()) return;

      // Set the value to 0 if it's being unchecked, and initialize it back to 1 if it's being checked
      if (this.field[this.reminder] == 0) {
         this.enableDateReminder(1);
      } else {
         this.disableDateReminder();
      }
      this.customDays = 0;
      this.customWeeks = 0;
      this.customMonths = 0;

      // save the changes
      this.submit(this.field[this.reminder]);
   };

   enableDateReminder = (reminderTime: number) => {
      this.field[this.reminder] = reminderTime;
      this.dateReminderDisplay = this.setReminderDisplayValue();
      this.showDateReminderDisplay = true;
   };

   disableDateReminder = () => {
      this.field[this.reminder] = 0;
      this.showDateReminderDisplay = false;
   };

   updateReminderTime = (reminderTime: number) => {
      if (this.disabled()) return;

      // If they blur the field with a 0 in it, don't do anything since that's not valid
      if (reminderTime == 0) {
         return;
      }

      // Format the display to the appropriate type if the date is custom
      this.customDays = this.isCustomDays ? this.field[this.reminder] : 0;
      this.customWeeks = this.isCustomWeeks ? this.field[this.reminder] / 7 : 0;
      this.customMonths = this.isCustomMonths ? this.field[this.reminder] / 30 : 0;

      this.enableDateReminder(reminderTime);

      // save the changes
      this.submit(this.field[this.reminder]);
   };

   setReminderDisplayValue = (): string => {
      const reminderTime = this.field[this.reminder];

      this.isCustomDays = false;
      this.isCustomWeeks = false;
      this.isCustomMonths = false;

      switch (reminderTime) {
         case 1:
            return this.lang().OneDayBefore;

         case 7:
            return this.lang().OneWeekBefore;

         case 30:
            return this.lang().OneMonthBefore;

         case 60:
            return this.lang().TwoMonthsBefore;

         case 90:
            return this.lang().ThreeMonthsBefore;

         case 180:
            return this.lang().SixMonthsBefore;

         case 365:
            return this.lang().OneYearBefore;

         default:
            if (reminderTime % 30 == 0) {
               this.isCustomMonths = true;
               const monthReminderTime = reminderTime / 30;
               return `${monthReminderTime} ${this.lang().Months} ${this.lang().before}`;
            }

            if (reminderTime % 7 == 0) {
               this.isCustomWeeks = true;
               const weekReminderTime = reminderTime / 7;
               return `${weekReminderTime} ${this.lang().Weeks} ${this.lang().before}`;
            }

            this.isCustomDays = true;
            return `${reminderTime} ${this.lang().Days} ${this.lang().before}`;
      }
   };

   submit = (reminderTime) => {
      if (this.type === "asset") {
         if (
            !this.credService.isAuthorized(
               this.locationID,
               this.credService.Permissions.CreateNewFieldsAndDeleteExistingFields,
            )
         ) {
            this.alertService.addAlert(this.lang().cred65Fail, "danger", 10000);
            return;
         }

         this.manageAsset
            .updateDateReminder(this.reminder, reminderTime, this.field.fieldID)
            .then((response) => {
               if (response.data.success == true) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }

      if (this.type === "part") {
         if (
            !this.credService.isAuthorized(
               this.locationID,
               this.credService.Permissions.CreateAndDeletePartFields,
            )
         ) {
            this.alertService.addAlert(this.lang().cred171Fail, "danger", 10000);
            return;
         }

         this.manageParts
            .updateDateReminder(this.reminder, reminderTime, this.field.fieldID)
            .then((response) => {
               if (response.data.success == true) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }

      if (this.type === "vendor") {
         if (
            !this.credService.isAuthorized(
               this.locationID,
               this.credService.Permissions.CreateAndDeleteVendorFields,
            )
         ) {
            this.alertService.addAlert(this.lang().cred134Fail, "danger", 10000);
            return;
         }

         this.manageVendor
            .updateDateReminder(this.reminder, reminderTime, this.field.fieldID)
            .then((response) => {
               if (response.data.success == true) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }
   };
}
