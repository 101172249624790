import { NgClass } from "@angular/common";
import type { OnInit, Signal } from "@angular/core";
import {
   Component,
   computed,
   DestroyRef,
   inject,
   input,
   output,
   signal,
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import {
   BlockIconComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderComponent } from "ngx-skeleton-loader";
import { combineLatestWith, debounceTime, filter, first } from "rxjs";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { AddUser } from "src/app/users/components/addUserModal/addUser.modal.component";
import type {
   PickedProfile,
   PickedUser,
   UserProfile,
} from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

export type PickUserOrProfileSelection =
   | {
        selectedUsers: PickedUser[];
     }
   | {
        selectedProfile: PickedProfile;
     };

@Component({
   selector: "pick-user-or-profile",
   templateUrl: "./pick-user-or-profile.component.html",
   styleUrls: ["./pick-user-or-profile.component.scss"],
   imports: [
      PanelComponent,
      LimbleHtmlDirective,
      NgClass,
      SelectionControlsComponent,
      SearchBoxComponent,
      NoSearchResults,
      IconComponent,
      BlockIconComponent,
      UserImage,
      TooltipDirective,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
      ReactiveFormsModule,
      NgxSkeletonLoaderComponent,
   ],
})
export class PickUserOrProfileComponent implements OnInit {
   public readonly message = input.required<string>();
   public readonly locationID = input.required<number>();
   public readonly extraUsers = input<
      Array<{
         userFirstName: string;
         userID: number;
         profileID: number;
         extra?: boolean;
         dynamicAssignment?: 0 | 1;
      }>
   >(); // custom user options (ie: current user)
   public readonly defaultToDynamicAssignment = input<boolean>(false);
   public readonly defaultUser = input<number>(0);
   public readonly defaultProfile = input<number>(0);
   /**
    * Emits to inform the containing form that a selection has been made
    * and the the form should be submitted.
    */
   public readonly autoSubmit = output();
   public readonly selection = output<
      | {
           selectedUsers: PickedUser[];
        }
      | {
           selectedProfile: PickedProfile;
        }
   >();

   protected readonly lang = inject(ManageLang).lang;
   private readonly modalService = inject(ModalService);
   private readonly router = inject(Router);
   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageObservables = inject(ManageObservables);
   private readonly destroyRef = inject(DestroyRef);

   public noSearchResults = computed(() => {
      return this.displayedUsers().length === 0 && this.displayedProfiles().length === 0;
   });

   protected readonly canAddUsers = computed(() =>
      this.credService.isAuthorized(
         this.locationID(),
         this.credService.Permissions.AddUsers,
      ),
   );

   private readonly users: Signal<Array<PickedUser>> = computed(() => {
      const users = this.usersAndProfilesAtLocation()?.data.users ?? [];
      const usersThatCanCompleteWork =
         this.manageFilters.filterToUsersThatCanCompleteWork(
            users,
            {
               locations: [this.locationID()],
            },
            this.manageUser,
            this.manageProfile,
         );
      return orderBy(
         [
            ...usersThatCanCompleteWork,
            ...(this.extraUsers()?.map((user) => ({ ...user, extra: true })) ?? []),
         ],
         ["userLastName", "userFirstName"],
      );
   });

   public readonly displayedUsers: Signal<PickedUser[]> = computed(() => {
      const search = this.searchTerm()?.toLowerCase() ?? "";
      return this.users().filter(
         (user) =>
            user.selected === true ||
            (user.userFirstName?.toLowerCase().includes(search) ?? false) ||
            (user.userLastName?.toLowerCase().includes(search) ?? false),
      );
   });

   private readonly profiles: Signal<Array<PickedProfile>> = computed(() => {
      const profiles = this.usersAndProfilesAtLocation()?.data.profiles ?? [];
      this.userProfiles = this.usersAndProfilesAtLocation()?.data.userProfiles ?? [];
      const profilesThatCanCompleteWork =
         this.manageFilters.filterProfilesToWhoCanCompleteWork(
            profiles,
            this.manageProfile,
         );
      const isGlobalRole = (profile: PickedProfile): boolean => {
         return profile.locationID === 0 && profile.profileParent === 0;
      };
      const hasAssignedUser = (profile: PickedProfile): boolean => {
         return this.userProfiles.some(
            (userProfile) => userProfile.profileID === profile.profileID,
         );
      };
      const isRequiredGlobally = (): boolean => {
         return this.locationID() === 0;
      };
      const filteredProfiles = profilesThatCanCompleteWork.filter(
         (profile) =>
            !isGlobalRole(profile) || hasAssignedUser(profile) || isRequiredGlobally(),
      );
      return orderBy(filteredProfiles, "profileDescription");
   });

   public readonly displayedProfiles: Signal<PickedProfile[]> = computed(() => {
      const search = this.searchTerm()?.toLowerCase() ?? "";
      return this.profiles().filter(
         (profile) =>
            profile.profileID === this.selectedProfile?.profileID ||
            profile.profileDescription.toLowerCase().includes(search),
      );
   });

   public selectedProfile: PickedProfile | undefined;

   protected readonly manageProfileCred = computed(() =>
      this.credService.isAuthorized(
         this.locationID(),
         this.credService.Permissions.ViewManageTeams,
      ),
   );

   private userProfiles: UserProfile[] = [];

   // User clicks is used to allow users to double-click a selection to submit.
   private userClicks = 0;

   /** The search box will be shown if either list has more than this number of items */
   public showSearchMinimum = 5;

   protected readonly showSearchBox = computed(() => {
      return (
         this.users().length >= this.showSearchMinimum ||
         this.profiles().length >= this.showSearchMinimum
      );
   });

   protected readonly usersAndProfilesAtLocation = signal<
      ReturnType<typeof this.manageUser.getUsersAndProfilesAtLocation> | undefined
   >(undefined);

   public readonly searchBoxFormControl = new FormControl("");

   private readonly searchTerm = toSignal(
      this.searchBoxFormControl.valueChanges.pipe(debounceTime(300)),
   );

   public ngOnInit(): void {
      const profilesObservable = this.manageObservables.getObservable("profiles");
      const usersObservable = this.manageObservables.getObservable("users");
      if (profilesObservable === undefined || usersObservable === undefined) {
         throw new Error("Profiles or users observable is undefined");
      }
      this.manageUser.ready$
         .pipe(
            combineLatestWith(
               usersObservable.pipe(filter((watchVar) => Number(watchVar) > 0)),
               profilesObservable.pipe(filter((watchVar) => Number(watchVar) > 0)),
            ),
            takeUntilDestroyed(this.destroyRef),
            first(),
         )
         .subscribe(() => {
            this.usersAndProfilesAtLocation.set(
               this.manageUser.getUsersAndProfilesAtLocation(this.locationID()),
            );
            this.setDefaultFocus();
         });
   }

   public deselectAll(): void {
      this.selectedProfile = undefined;
      this.users().forEach((user) => {
         user.selected = false;
      });
      this.userClicks = 0;
      this.emitSelection();
   }

   public selectAllUsers(): void {
      this.selectedProfile = undefined;
      this.users()
         .filter((user) => user.extra !== true)
         .forEach((user) => {
            user.selected = true;
         });
      this.userClicks = 0;
      this.emitSelection();
   }

   private setDefaultFocus(): void {
      if (this.defaultToDynamicAssignment()) {
         const defaultAssignmentUser = this.displayedUsers().find(
            (user) => user.dynamicAssignment,
         );
         if (defaultAssignmentUser) this.selectUser(defaultAssignmentUser);
      } else if (this.defaultProfile() > 0) {
         const defaultProfile = this.manageUser.getProfilesIndex()[this.defaultProfile()];
         if (defaultProfile.profileHidden === 1 && defaultProfile.users?.length) {
            defaultProfile.users.forEach((profileUser) => {
               const user = this.displayedUsers().find(
                  (displayedUser) => displayedUser.userID === profileUser.userID,
               );
               if (user) {
                  this.selectUser(user);
               }
            });
         } else {
            this.selectProfile(this.defaultProfile());
         }
      } else {
         const defaultUser = this.displayedUsers().find(
            (user) => user.userID === this.defaultUser(),
         );
         if (defaultUser !== undefined) {
            this.selectUser(defaultUser);
            // Clicking selected user should deselect not submit when preselected
            this.userClicks = 1;
         }
      }
      this.emitSelection();
   }

   public async openAddUserModal(): Promise<void> {
      if (!this.canAddUsers()) return;
      const modalRef = this.modalService.open(AddUser);
      modalRef.setInput("locationID", this.locationID());
      const newUserID = await modalRef.result;
      if (newUserID) {
         await this.manageUser.getData();
         this.usersAndProfilesAtLocation.set(
            this.manageUser.getUsersAndProfilesAtLocation(this.locationID()),
         );
         this.selectNewUser(newUserID);
      }
   }

   public closeAllAndRedirect(url: string): void {
      this.router.navigateByUrl(url);
   }

   public selectUser(userToFocus: PickedUser): void {
      this.selectedProfile = undefined;
      this.users().forEach((user) => {
         // Hints are used to show which user belongs to which profile
         user.hint = false;
         // If user isn't extra then mark all extra options as not selected
         if (!userToFocus.extra && user.extra) {
            user.selected = false;
         }
         // If picked user is extra then mark all others as false
         if (userToFocus.extra) {
            const isDifferentUser = user.userID !== userToFocus.userID;
            const dynamicAssignmentMismatch =
               userToFocus.dynamicAssignment !== user.dynamicAssignment;
            if (isDifferentUser || dynamicAssignmentMismatch) {
               user.selected = false;
            }
         }
      });
      if (userToFocus.selected === true) {
         // If the user is already selected, we want to submit the selection
         if (userToFocus.extra === true || this.userClicks <= 0) {
            this.autoSubmit.emit();
            return;
         }
      }
      this.userClicks++;
      this.checkResetUserClicks();
      userToFocus.selected = !userToFocus.selected;
      this.emitSelection();
   }

   public selectProfile(profileID: number): void {
      this.users().forEach((user) => {
         user.selected = false;
      });
      this.userClicks = 0;
      this.users().forEach((user) => {
         user.hint = this.userProfiles.some(
            (userProfile) =>
               userProfile.profileID === profileID && userProfile.userID === user.userID,
         );
      });
      if (this.selectedProfile?.profileID === profileID) {
         // Already selected, so submit
         this.autoSubmit.emit();
         return;
      }
      this.selectedProfile = this.profiles().find(
         (profile) => profile.profileID === profileID,
      );
      this.emitSelection();
   }

   private selectNewUser(newUserID: number): void {
      const userToSelect = this.displayedUsers().find(
         (user) => user.userID === newUserID,
      );
      if (userToSelect) this.selectUser(userToSelect);
      this.emitSelection();
   }

   private checkResetUserClicks(): void {
      this.userClicks = this.manageUtil.checkResetUserClicks(
         this.displayedUsers(),
         this.userClicks,
      );
   }

   private emitSelection(): void {
      if (this.selectedProfile === undefined) {
         this.selection.emit({
            selectedUsers: this.users().filter((user) => user.selected),
         });
      } else {
         this.selection.emit({
            selectedProfile: this.selectedProfile,
         });
      }
   }
}
