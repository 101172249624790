@for (column of columns; track column) {
   <sort-column
      class="mobileLargeDisappear"
      [ngClass]="'col-md-' + column.columnWidth"
      [column]="column.sortBy"
      [name]="column.displayName"
      [(bind)]="sort"
      (bindChange)="sortChange.emit(sort)"
      (click)="updateUserUIPreferences()"
   />
}
