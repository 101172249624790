/**
 * A specialized Set that manages unique entities based on a custom comparison function.
 * Unlike a standard Set which uses reference equality, EntitySet uses a provided comparator
 * to determine uniqueness. This is particularly useful for managing entities that may have
 * the same ID but different reference equality.
 *
 * Example:
 * ```typescript
 * const parts = new EntitySet((a, b) => a.partID === b.partID);
 * parts.add({ partID: 1, name: "Part A" });
 * parts.add({ partID: 1, name: "Part A Updated" }); // Replaces the first item
 * ```
 */
export class EntitySet<T> extends Set<T> {
   public constructor(
      private readonly comparator: (a: T, b: T) => boolean,
      items?: Iterable<T>,
   ) {
      super();
      if (items) {
         for (const item of items) {
            this.add(item);
         }
      }
   }

   /**
    * Checks if an item exists in the set using the custom comparator function.
    * Unlike Set.has(), which uses reference equality, this method uses the provided
    * comparator to determine if an equivalent item exists.
    * @param item The item to check for
    * @returns true if an equivalent item exists in the set, false otherwise
    */
   public override has(item: T): boolean {
      for (const existingItem of this) {
         if (this.comparator(existingItem, item)) {
            return true;
         }
      }
      return false;
   }

   /**
    * Adds an item to the set, replacing any existing equivalent item.
    * Unlike Set.add(), which preserves duplicate references, this method
    * will replace an existing item if the comparator determines they are equivalent.
    * @param item The item to add
    * @returns The set instance for chaining
    */
   public override add(item: T): this {
      for (const existingItem of this) {
         if (this.comparator(existingItem, item)) {
            this.delete(existingItem);
            break;
         }
      }
      super.add(item);
      return this;
   }

   /**
    * Removes an item from the set using the custom comparator function.
    * Unlike Set.delete(), which uses reference equality, this method uses
    * the provided comparator to find and remove an equivalent item.
    * @param item The item to remove
    * @returns true if an item was removed, false otherwise
    */
   public override delete(item: T): boolean {
      for (const existingItem of this) {
         if (this.comparator(existingItem, item)) {
            super.delete(item);
            return true;
         }
      }
      return false;
   }
}
