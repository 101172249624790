import { inject, Injectable } from "@angular/core";
import { isNativeMobileApp } from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import Median from "median-js-bridge";
import semver from "semver";
import type {
   OneSignalInfo,
   UserIdParams,
} from "src/app/mobile/push-notifications/push-notification.service.types";
import { MobileNativeDeviceInfoService } from "src/app/shared/services/mobile-native-device-info/mobile-native-device-info.service";

@Injectable({
   providedIn: "root",
})
export class PushNotificationService {
   private readonly deviceInfo = inject(MobileNativeDeviceInfoService).deviceInfo;

   /**
    * Registers the device to the user with OneSignal and
    * sends the push registration info to our server to store.
    */
   public async register({ customerID, userID }: UserIdParams): Promise<void> {
      if (isNativeMobileApp() === false) return;

      await this.medianIsReady();

      const externalId = this.getExternalId({ customerID, userID });
      await this.setExternalUserId(externalId);

      const pushRegistrationInfo: OneSignalInfo = await Median.onesignal.onesignalInfo();
      axios({
         method: "POST",
         url: "phpscripts/pushNotification.php",
         data: pushRegistrationInfo,
      });
   }

   /**
    * Unregisters the device from user with OneSignal.
    */
   public async unregisterDevice(): Promise<void> {
      if (isNativeMobileApp() === false) return;

      if (await this.useLegacyOneSignal()) {
         Median.onesignal.externalUserId.remove();
         return;
      }
      Median.onesignal.logout();
   }

   /**
    * Updates the app badge count with the number of unread notifications
    */
   public async setBadgeCount(count: number): Promise<void> {
      if (isNativeMobileApp() === false) return;

      if (await this.useLegacyOneSignal()) {
         // Legacy OneSignal doesn't support badge counts
         return;
      }

      await this.medianIsReady();
      Median.onesignal.badge.set(count);
   }

   /**
    * Sets the external user ID for OneSignal and register the device to the user.
    */
   private async setExternalUserId(externalId: string): Promise<void> {
      if (await this.useLegacyOneSignal()) {
         Median.onesignal.externalUserId.set({ externalId });
         return;
      }

      await Median.onesignal.login(externalId);
   }

   /**
    * Waits for Median JS Bridge to be initialized.
    */
   private async medianIsReady(): Promise<void> {
      await new Promise<void>((resolve) => {
         Median.onReady(resolve);
      });
   }

   private getExternalId({ customerID, userID }: UserIdParams): string {
      return `${customerID}-${userID}`;
   }

   /**
    * Determines if the legacy OneSignal API should be used.
    * Use legacy OneSignal on Android/iOS versions below 3.2.0.
    */
   private async useLegacyOneSignal(): Promise<boolean> {
      const deviceInfo = await this.deviceInfo;
      return semver.lt(deviceInfo.appVersion, "3.2.0");
   }
}
