@if (isPhoneScreenSize$ | async) {
   <lim-ui-scroll-container [thin]="true">
      <ng-container *ngTemplateOutlet="vendorTile" />
   </lim-ui-scroll-container>
} @else {
   <vendor-tile
      [vendorID]="vendorID"
      [restrict]="restrict"
      [navigateToTab]="navigateToTab"
      [scrollableContent]="(isPhoneScreenSize$ | async) === false"
      (closeModalEvent)="close()"
   />
}

<ng-template #vendorTile>
   <vendor-tile
      [vendorID]="vendorID"
      [restrict]="restrict"
      [navigateToTab]="navigateToTab"
      [scrollableContent]="(isPhoneScreenSize$ | async) === false"
      (closeModalEvent)="close()"
   />
</ng-template>
