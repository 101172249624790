import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, ChangeDetectorRef, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BadgeComponent,
   BasicModalHeaderComponent,
   CheckboxComponent,
   DangerButtonComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   IconComponent,
   ModalService,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PaginationComponent,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   SearchBoxComponent,
   TextButtonComponent,
   TooltipDirective,
   UpsellPopover,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import moment from "moment";
import { Subscription, lastValueFrom } from "rxjs";
import { TransferAssetToNewLocationComponent } from "src/app/assets/components/TransferAssetToNewLocation/transferAssetToNewLocation.component";
import { ViewAssets } from "src/app/assets/components/viewAssetsModal/viewAssets.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageTool } from "src/app/assets/services/manageTool";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { WorkOrderTemplatesComponent } from "src/app/tasks/components/work-order-templates-component/work-order-templates.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "asset-settings",
   templateUrl: "./assetSettings.modal.component.html",
   styleUrls: ["./assetSettings.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      BadgeComponent,
      ModalBodyComponent,
      PanelComponent,
      FormsModule,
      DropdownComponent,
      PrimaryButtonComponent,
      SearchBoxComponent,
      DropdownDividerComponent,
      DropdownTextItemComponent,
      NgClass,
      CheckboxComponent,
      TooltipDirective,
      IconComponent,
      PaginationComponent,
      DatePickerInputComponent,
      TextButtonComponent,
      ModalFooterComponent,
      MinimalIconButtonComponent,
      DangerButtonComponent,
      SliceArray,
      PopoverDirective,
      UpsellPopover,
   ],
})
export class AssetSettings implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public title;
   public asset: Asset | undefined;
   public errorMsg: string;
   public locationsIndex;
   public currentType;
   public date;
   public dateOptions;
   public formats;
   public format;
   public searchBar: string;
   public totalLocLength;
   public locations;
   public reportProblemAssignmentStr;
   public loading;
   public opened;
   public years;
   public months;
   public days;
   public page = 1;
   public itemsPerPage = 10;
   public checkOutApprovalUserName: string | undefined;
   public defaultChecklistName: string | undefined;
   public featureAssetTools: boolean;
   public manualHours: Array<{ start: string; hours: any; lookup: string; end: string }>;
   public displayHours: number = 40;

   protected featureWRDuplicatePrevention: boolean = false;

   private manageFeatureFlagsSub: Subscription = new Subscription();

   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly refreshService = inject(RefreshService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUser = inject(ManageUser);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageProfile = inject(ManageProfile);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly manageTool = inject(ManageTool);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.featureAssetTools = Boolean(
         this.manageUser.getCurrentUser().userInfo.featureAssetTools,
      );
      this.searchBar = "";
      this.errorMsg = "";
      this.manualHours = [];
   }

   public ngOnInit() {
      this.initialize();
   }

   private initialize() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.title = this.resolve.title;
      const assetID = this.resolve.assetID;
      this.errorMsg = "";

      this.locationsIndex = this.manageLocation.getLocationsIndex();
      this.asset = this.manageAsset.getAsset(assetID);
      assert(this.asset);
      this.currentType = this.lang().Asset;
      assert(this.asset.assetCreatedDate);
      this.date = new Date(this.asset.assetCreatedDate * 1000);
      this.displayHours = this.manageAsset.getAssetHoursOfOperation(this.asset);
      this.dateOptions = {
         formatYear: "yy",
         startingDay: 1,
      };

      this.formats = ["dd-MMMM-yyyy", "yyyy/MM/dd", "dd.MM.yyyy", "shortDate"];
      this.format = this.formats[0];

      if (
         this.asset.assetTrackHoursManually == 1 ||
         this.asset.assetTrackHoursManually == 0
      ) {
         this.asset.assetTrackHoursManually = Number(this.asset.assetTrackHoursManually);
      }
      if (this.asset.assetTrackHoursManually == 1) {
         this.manualHours = this.manageAsset.prepHoursManually(this.asset);
      }
      this.setCheckOutApprovalInfo(
         this.asset.checkOutApprovalUserID,
         this.asset.checkOutApprovalProfileID,
      );
      this.setDefaultChecklistInfo();
      this.buildData();
      this.assignmentString();
      this.setRunningDate();

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureWRDuplicatePrevention =
               isFeatureEnabledMap.featureWRDuplicatePrevention;
            this.featureAssetTools = isFeatureEnabledMap.featureAssetTools;
         },
      );
   }

   public ngOnDestroy() {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   protected pageChanged(): void {
      this.ref.detectChanges();
   }

   protected runSearch(): void {
      this.buildData();
   }

   protected buildData(): void {
      let locations = this.manageLocation.getLocations();
      this.totalLocLength = locations.length;

      if (this.searchBar && this.searchBar.length > 0) {
         locations = this.manageFilters.filterLocationsToSearch(
            locations,
            this.searchBar,
         );
      }

      locations = orderBy(locations, "locationNameWithRegions");

      this.locations = locations;
   }

   //logic to figure out the assignment string
   protected assignmentString(): void {
      assert(this.asset);
      if (
         this.asset.reportProblemAssignmentUserID ||
         this.asset.reportProblemAssignmentProfileID
      ) {
         //found settings at asset Level so use those
         if (
            this.asset.reportProblemAssignmentProfileID &&
            this.asset.reportProblemAssignmentProfileID > 0
         ) {
            const profiles = this.manageUser.getProfilesIndex();
            this.reportProblemAssignmentStr =
               profiles[this.asset.reportProblemAssignmentProfileID].profileDescription;
         } else if (
            this.asset.reportProblemAssignmentUserID &&
            this.asset.reportProblemAssignmentUserID > 0
         ) {
            const users = this.manageUser.getFlatUsersIndex();
            this.reportProblemAssignmentStr = `${
               users[this.asset.reportProblemAssignmentUserID].userFirstName
            } ${users[this.asset.reportProblemAssignmentUserID].userLastName}`;
         }
      } else {
         //ok we couldn't find it on the asset level so we need to check their parents really quick as well...
         const checkParentAssetAssignment = (assetIn) => {
            const parentAsset = this.manageAsset.getAsset(assetIn.parentAssetID);
            assert(parentAsset);
            if (
               (parentAsset.reportProblemAssignmentUserID &&
                  parentAsset.reportProblemAssignmentUserID > 0) ||
               (parentAsset.reportProblemAssignmentProfileID &&
                  parentAsset.reportProblemAssignmentProfileID > 0)
            ) {
               //found settings at asset Level so use those
               if (
                  parentAsset.reportProblemAssignmentProfileID &&
                  parentAsset.reportProblemAssignmentProfileID > 0
               ) {
                  this.reportProblemAssignmentStr =
                     this.manageUser.getProfilesIndex()[
                        parentAsset.reportProblemAssignmentProfileID
                     ].profileDescription;
               } else if (
                  parentAsset.reportProblemAssignmentUserID &&
                  parentAsset.reportProblemAssignmentUserID > 0
               ) {
                  const users = this.manageUser.getFlatUsersIndex();
                  this.reportProblemAssignmentStr = `${
                     users[parentAsset.reportProblemAssignmentUserID].userFirstName
                  } ${users[parentAsset.reportProblemAssignmentUserID].userLastName}`;
               }
            } else if (
               parentAsset.parentAssetID !== null &&
               parentAsset.parentAssetID > 0
            ) {
               checkParentAssetAssignment(parentAsset);
            }
         };

         //only do this check if they are a child asset obviously :)
         if (this.asset.parentAssetID !== null && this.asset.parentAssetID > 0) {
            checkParentAssetAssignment(this.asset);
            if (
               this.reportProblemAssignmentStr &&
               this.reportProblemAssignmentStr.length > 0
            ) {
               //we have set an assignment string because we successfully found a parent so don't continue on
               return;
            }
         }

         //couldn't find any parent assets that had assignment so lets move on to checking location
         const location = this.locationsIndex[this.asset.locationID];
         if (
            location.reportProblemAssignmentProfileID === 0 &&
            location.reportProblemAssignmentUserID === 0
         ) {
            this.reportProblemAssignmentStr = this.lang().Unassigned;
            return;
         }
         if (
            location.reportProblemAssignmentUserID > 0 ||
            location.reportProblemAssignmentProfileID > 0
         ) {
            //found settings at location level so use those
            if (location.reportProblemAssignmentProfileID > 0) {
               this.reportProblemAssignmentStr =
                  this.manageUser.getProfilesIndex()[
                     location.reportProblemAssignmentProfileID
                  ].profileDescription;
            } else if (location.reportProblemAssignmentUserID > 0) {
               const users = this.manageUser.getFlatUsersIndex();
               this.reportProblemAssignmentStr = `${
                  users[location.reportProblemAssignmentUserID].userFirstName
               } ${users[location.reportProblemAssignmentUserID].userLastName}`;
            }
         } else {
            //couldnt find at location so check globally
            const global = this.manageUser.getCurrentUser().userInfo;

            if (
               global.reportProblemAssignmentProfileID === 0 &&
               global.reportProblemAssignmentUserID === 0
            ) {
               this.reportProblemAssignmentStr = this.lang().Unassigned;
               return;
            }

            if (
               global.reportProblemAssignmentUserID > 0 ||
               global.reportProblemAssignmentProfileID > 0
            ) {
               //found globally so set it
               if (global.reportProblemAssignmentProfileID > 0) {
                  this.reportProblemAssignmentStr =
                     this.manageUser.getProfilesIndex()[
                        global.reportProblemAssignmentProfileID
                     ].profileDescription;
               } else if (global.reportProblemAssignmentUserID > 0) {
                  const users = this.manageUser.getFlatUsersIndex();
                  this.reportProblemAssignmentStr = `${
                     users[global.reportProblemAssignmentUserID].userFirstName
                  } ${users[global.reportProblemAssignmentUserID].userLastName}`;
               }
            } else {
               //couldnt find on asset level, location level, or globally so must be the very basic default
               this.reportProblemAssignmentStr = this.lang().Manager;
            }
         }
      }
   }

   protected changeDefaultAssignment(): void {
      assert(this.asset);
      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().changeAssetDefaultAssignmentOverrideUnassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };

      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().changeAssetDefaultAssignmentMsg,
               title: this.lang().changeAssetDefaultAssignmentTitle,
               locationID: this.asset.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.asset.reportProblemAssignmentUserID,
               defaultProfile: this.asset.reportProblemAssignmentProfileID,
            },
         },
      };

      instance.result.then((result) => {
         assert(this.asset);
         if (result != 0) {
            this.manageAsset
               .updateAssetWorkRequestAssignment(
                  this.asset,
                  result.profileID,
                  result.userID,
                  result.multiUsers,
               )
               .then((answer) => {
                  assert(this.asset);
                  if (answer.data.success) {
                     const userID = answer.data.userID;
                     const profileID = answer.data.profileID;
                     const profileDescription = answer.data.profileDescription;
                     const locationID = this.asset.locationID;

                     this.asset.reportProblemAssignmentUserID = userID;
                     this.asset.reportProblemAssignmentProfileID = answer.data.profileID;

                     if (
                        profileID > 0 &&
                        result.multiUsers.length &&
                        result.multiUsers.length > 0
                     ) {
                        //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
                        this.manageFilters.updateHiddenProfiles(
                           {
                              profileID: profileID,
                              name: profileDescription,
                              locationID: locationID,
                              multiUsers: result.multiUsers,
                           },
                           this.manageTask,
                           this.manageUser,
                           this.manageProfile,
                        );
                     }

                     this.assignmentString();

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   }

   private addAssetNameAndAllChildrenAssetNamesToArray(
      assetToAdd: Asset,
      listOfAssetNames: Array<string>,
      assetIDsAlreadyChecked: Set<number> = new Set(),
   ) {
      // Add the assetName to the array
      assetToAdd.assetName = assetToAdd.assetName ?? "";
      listOfAssetNames.push(assetToAdd.assetName);
      assetIDsAlreadyChecked.add(assetToAdd.assetID);

      // If the asset has any children assets, add those too.
      if (assetToAdd.assetChildrenIDs?.length > 0) {
         for (const childAssetID of assetToAdd.assetChildrenIDs) {
            const childAsset = this.manageAsset.getAsset(childAssetID);
            assert(childAsset);
            if (assetIDsAlreadyChecked.has(childAsset.assetID)) {
               continue;
            }

            // ignore the names of deleted children when checking for uniqueness in the target location.
            if (childAsset.assetDeleted === 1) continue;

            this.addAssetNameAndAllChildrenAssetNamesToArray(
               childAsset,
               listOfAssetNames,
               assetIDsAlreadyChecked,
            );
         }
      }
   }

   protected async changeLocation(location): Promise<void> {
      assert(this.asset);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.EditAssetSettings,
         )
      ) {
         this.alertService.addAlert(this.lang().cred68Fail, "danger", 10000);
         return;
      }

      // Check if the location we are moving the asset to has the "Require Unique Asset Names" setting checked.
      if (this.manageLocation.getLocation(location.locationID)?.assetNameUnique === 1) {
         const listOfAssetNames: Array<string> = [];

         // Add the asset names and all of its children, grandchildren, etc. to an array.
         this.addAssetNameAndAllChildrenAssetNamesToArray(this.asset, listOfAssetNames);

         // Check if the item name is unique
         const post = await this.manageAsset.checkAssetNameUnique(
            listOfAssetNames,
            location.locationID,
         );

         if (!post.data.success) {
            this.alertService.addAlert(
               this.lang().TransferAssetNotUniqueMsg,
               "warning",
               8000,
            );
            return;
         }
      }

      const instance = this.modalService.open(TransferAssetToNewLocationComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().MoveAssetsLocationMsg} <b>${this.asset.assetName}</b>?<br><br>${this.lang().TheFollowingDataWillAlsoBeTransferred}`,
            title: `${this.lang().Move} ${this.asset.assetName} ${this.lang().To} "${location.locationName}"`,
            currentLocationName: this.locationsIndex[this.asset.locationID].locationName,
            newLocationName: location.locationName,
         },
      };

      const result = await instance.result;
      if (result.confirm === false) return;

      const transferParts = result.shouldTransferParts;
      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      const answer = await this.manageAsset.changeAssetLocation(
         this.asset,
         location,
         transferParts,
      );

      if (answer.data.success) {
         await this.refreshService.refreshData({ force: true, notify: false });
         this.loadingBarService.remove();
         this.modalInstance.close(0);

         this.alertService.addAlert(
            `${this.lang().MoveAssetsLocationSuccessMsg} <b>${location.locationName}</b>!`,
            "success",
            10000,
         );

         if (answer.data.unassignedUserWarning) {
            this.alertService.addAlert(
               this.lang().DuringTheMoveOneOrMoreOfTheTasksWereSetToUnassigned,
               "warning",
               10000,
            );
         }

         if (answer.data.fewerUsersWarning) {
            this.alertService.addAlert(this.lang().FewerUsersWarning, "warning", 10000);
         }
      } else {
         this.loadingBarService.remove();
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   protected deleteAsset(): void {
      assert(this.asset);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.DeleteAssets,
         )
      ) {
         this.alertService.addAlert(this.lang().cred61Fail, "danger", 10000);
         return;
      }

      let instance;
      instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteAssetMsg,
            title: this.lang().DeleteAsset,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            instance = this.modalService.open(Confirm);

            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: this.lang().ReallyDeleteThisAssetMsg,
                  title: this.lang().ReallyDeleteThisAsset,
               },
            };

            instance.result.then((confirmResult) => {
               assert(this.asset);
               if (confirmResult == 1) {
                  const children = this.manageAsset.findChildrenIDs(this.asset, []);

                  if (children.length > 0) {
                     children.push(this.asset.assetID); //add the very top parent asset on so we show everything and delete everything if they click yes

                     instance = this.modalService.open(ViewAssets);

                     instance.componentInstance.message.set(
                        this.lang().DeleteChildAssetsMsg,
                     );
                     instance.componentInstance.title.set(this.lang().DeleteChildAssets);
                     instance.componentInstance.assetsIDs.set(children);
                     instance.componentInstance.showUndoneWarning.set(true);
                     instance.componentInstance.buttonActionAll.set({
                        text: this.lang().DeleteAll,
                        dataLogLabel: "DeleteAllAssets",
                     });
                     instance.componentInstance.buttonActionTopLevel.set({
                        text: this.lang().DeleteOnlyTopLevel,
                        dataLogLabel: "DeleteOnlyTopLevelAssets",
                     });
                     instance.componentInstance.dataLogWorkflowLabel.set(
                        "DeleteChildAssets",
                     );

                     instance.result.then((viewAssetResult) => {
                        if (viewAssetResult === "includeChildren") {
                           this.loading = true;
                           const finalAssetsToDelete: any = [];
                           for (const child of children) {
                              //remember children contain the very top parent asset too
                              finalAssetsToDelete.push(child);
                           }

                           this.manageAsset
                              .deleteAssetsInBulk(finalAssetsToDelete)
                              .then((answer) => {
                                 if (answer.data.success == true) {
                                    this.loadingBarService.show({
                                       header: this.lang().ThisMayTakeAMoment,
                                    });
                                    this.modalInstance.close(0);

                                    setTimeout(() => {
                                       assert(this.asset);
                                       if ($(`#popAsset${this.asset.assetID}`)[0]) {
                                          $(`#popAsset${this.asset.assetID}`)[0].click();
                                       }
                                    }, 350);

                                    this.alertService.addAlert(
                                       this.lang().AssetsSuccessfullyDeleted,
                                       "success",
                                       2000,
                                    );
                                    this.loadingBarService.remove();
                                 } else {
                                    this.loading = false;
                                    this.alertService.addAlert(
                                       this.lang().errorMsg,
                                       "danger",
                                       10000,
                                    );
                                 }
                              });
                        } else if (viewAssetResult === "parentOnly") {
                           this.manageAsset.deleteAsset(this.asset).then((answer) => {
                              assert(answer);
                              if (answer.data.success == true) {
                                 this.loadingBarService.show({
                                    header: this.lang().ThisMayTakeAMoment,
                                 });
                                 this.modalInstance.close(0);

                                 setTimeout(() => {
                                    assert(this.asset);
                                    if ($(`#popAsset${this.asset.assetID}`)[0]) {
                                       $(`#popAsset${this.asset.assetID}`)[0].click();
                                    }
                                 }, 350);

                                 this.loadingBarService.remove();
                                 this.alertService.addAlert(
                                    this.lang().AssetDeleted,
                                    "success",
                                    3000,
                                 );
                              } else {
                                 this.alertService.addAlert(
                                    this.lang().errorMsg,
                                    "danger",
                                    6000,
                                 );
                              }
                           });
                        }
                     });
                  } else {
                     this.manageAsset.deleteAsset(this.asset).then((answer) => {
                        assert(answer);
                        if (answer.data.success == true) {
                           this.loadingBarService.show({
                              header: this.lang().ThisMayTakeAMoment,
                           });
                           this.modalInstance.close(0);

                           setTimeout(() => {
                              assert(this.asset);
                              if ($(`#popAsset${this.asset.assetID}`)[0]) {
                                 $(`#popAsset${this.asset.assetID}`)[0].click();
                              }
                           }, 350);

                           this.loadingBarService.remove();
                           this.alertService.addAlert(
                              this.lang().AssetDeleted,
                              "success",
                              3000,
                           );
                        } else {
                           this.alertService.addAlert(
                              this.lang().errorMsg,
                              "danger",
                              6000,
                           );
                        }
                     });
                  }
               }
            });
         }
      });
   }

   protected updateAssetHoursOfOperation(assetToUpdate): void {
      assert(this.asset);
      this.asset.assetOperationHoursPerWeek = this.displayHours;
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.EditAssetSettings,
         )
      ) {
         this.alertService.addAlert(this.lang().cred68Fail, "danger", 10000);
         return;
      }

      this.manageAsset.updateAssetHoursOfOperation(assetToUpdate).then((answer) => {
         assert(this.asset);
         if (answer.data.success) {
            this.asset.assetOperationHoursPerWeek =
               this.manageAsset.getAssetHoursOfOperation(this.asset);
            this.manageTask.incCompletedTasksWatchVar();
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   protected open($event): void {
      $event.preventDefault();
      $event.stopPropagation();

      this.opened = true;
   }

   protected setRunningDate(): void {
      assert(this.asset);
      assert(this.asset.assetCreatedDate);
      const assetCreatedDate = moment(this.asset.assetCreatedDate * 1000);
      const today = moment(new Date());

      const years = assetCreatedDate.diff(today, "year");
      today.add(years, "years");

      const months = assetCreatedDate.diff(today, "months");
      today.add(months, "months");

      const days = assetCreatedDate.diff(today, "days");

      this.years = years * -1;
      this.months = months * -1;
      this.days = days * -1;
   }

   protected setAssetCreatedDate($dateEvent: Date): void {
      this.date = $dateEvent;
      assert(this.asset);
      assert(this.asset.assetCreatedDate);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.EditAssetSettings,
         )
      ) {
         this.alertService.addAlert(this.lang().cred68Fail, "danger", 10000);
         return;
      }

      const date = new Date(this.date);
      const newTime = date.getTime() / 1000;
      const oldTime = new Date(this.asset.assetCreatedDate);

      if (oldTime.getTime() != newTime && newTime != 0) {
         this.manageAsset.updateAssetCreatedDate(newTime, this.asset).then((answer) => {
            assert(this.asset);
            assert(this.asset.assetCreatedDate);
            if (answer.data.success == true) {
               this.asset.assetCreatedDate = Number(newTime);
               this.setRunningDate();
               this.manageTask.incCompletedTasksWatchVar();
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
      }
   }

   protected flipHoursManually(assetToFlip): void {
      if (assetToFlip.assetTrackHoursManually == 0) {
         assetToFlip.assetTrackHoursManually = 1;
      } else {
         assetToFlip.assetTrackHoursManually = 0;
      }

      const children = this.manageAsset.findChildrenIDs(assetToFlip, []);

      if (children.length > 0) {
         children.push(assetToFlip.assetID); //add the very top parent asset on so we show everything and delete everything if they click yes

         const instance = this.modalService.open(ViewAssets);

         instance.componentInstance.message.set(
            this.lang().UpdateChildAssetsHoursOfOperationMsg,
         );
         instance.componentInstance.title.set(
            this.lang().UpdateChildAssetsHoursOfOperation,
         );
         instance.componentInstance.assetsIDs.set(children);
         instance.componentInstance.showUndoneWarning.set(true);
         instance.componentInstance.buttonActionAll.set({
            text: this.lang().UpdateAll,
            dataLogLabel: "UpdateAllAssets",
         });
         instance.componentInstance.buttonActionTopLevel.set({
            text: this.lang().UpdateOnlyTopLevel,
            dataLogLabel: "UpdateOnlyTopLevelAssets",
         });
         instance.componentInstance.dataLogWorkflowLabel.set("UpdateChildAssets");

         instance.result.then((result) => {
            if (result === "includeChildren") {
               this.manageAsset
                  .updateAssetTrackHoursManually(assetToFlip, 1)
                  .then((answer) => {
                     if (answer.data.success == true) {
                        this.manualHours =
                           this.manageAsset.prepHoursManually(assetToFlip);
                        this.manageAsset.getData();

                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            } else if (result === "parentOnly") {
               this.manageAsset
                  .updateAssetTrackHoursManually(assetToFlip, 0)
                  .then((answer) => {
                     if (answer.data.success == true) {
                        this.manualHours =
                           this.manageAsset.prepHoursManually(assetToFlip);

                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            }
         });
      } else {
         this.manageAsset.updateAssetTrackHoursManually(assetToFlip, 0).then((answer) => {
            if (answer.data.success == true) {
               this.manualHours = this.manageAsset.prepHoursManually(assetToFlip);

               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
      }
   }

   protected updateAssetCheckSimiliarWRScope(assetSimilarScope: Asset): void {
      if (!this.featureWRDuplicatePrevention) {
         return;
      }

      if (assetSimilarScope.assetCheckSimiliarWRScope == 0) {
         assetSimilarScope.assetCheckSimiliarWRScope = 1;
      } else {
         assetSimilarScope.assetCheckSimiliarWRScope = 0;
      }

      this.manageAsset
         .updateAssetCheckSimiliarWRScope(
            assetSimilarScope.assetID,
            assetSimilarScope.assetCheckSimiliarWRScope,
         )
         .then((answer) => {
            if (answer.data.success == true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   }

   protected updateAssetTrackHoursManuallyData(hour, assetManualHours): void {
      const children = this.manageAsset.findChildrenIDs(assetManualHours, []);

      if (children.length > 0) {
         children.push(assetManualHours.assetID); //add the very top parent asset on so we show everything and delete everything if they click yes

         const instance = this.modalService.open(ViewAssets);

         instance.componentInstance.message.set(
            this.lang().UpdateChildAssetsTrackedManuallyMsg,
         );
         instance.componentInstance.title.set(
            this.lang().UpdateChildAssetsTrackedManually,
         );
         instance.componentInstance.assetsIDs.set(children);
         instance.componentInstance.showUndoneWarning.set(true);
         instance.componentInstance.buttonActionAll.set({
            text: this.lang().UpdateAll,
            dataLogLabel: "UpdateAllAssets",
         });
         instance.componentInstance.buttonActionTopLevel.set({
            text: this.lang().UpdateOnlyTopLevel,
            dataLogLabel: "UpdateOnlyTopLevelAssets",
         });
         instance.componentInstance.dataLogWorkflowLabel.set("UpdateChildAssets");

         instance.result.then((result) => {
            if (result === "includeChildren") {
               this.manageAsset
                  .updateAssetTrackHoursManuallyData(hour, assetManualHours, 1)
                  .then((answer) => {
                     if (answer.data.success == true) {
                        this.refreshService.refreshData();
                        this.manageTask.incCompletedTasksWatchVar();
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            } else if (result === "parentOnly") {
               this.manageAsset
                  .updateAssetTrackHoursManuallyData(hour, assetManualHours, 0)
                  .then((answer) => {
                     if (answer.data.success == true) {
                        this.manageTask.incCompletedTasksWatchVar();
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            }
         });
      } else {
         this.manageAsset
            .updateAssetTrackHoursManuallyData(hour, assetManualHours, 0)
            .then((answer) => {
               if (answer.data.success == true) {
                  this.manageTask.incCompletedTasksWatchVar();
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               }
            });
      }
   }

   protected close(): void {
      this.modalInstance.close(0);
   }

   protected openArticleSimliarWorkRequests(): void {
      window.open(
         "https://help.limblecmms.com/en/articles/3754457-avoiding-duplicate-work-requests",
         "_blank",
      );
   }

   public async updateCanCheckOutAsTool(): Promise<void> {
      if (!this.featureAssetTools) {
         return;
      }
      assert(this.asset);
      const newSetting = await this.manageTool.updateCanCheckOutAsTool(
         this.asset.assetID,
      );
      this.asset.canCheckOutAsTool = Number(newSetting);
   }

   public async updateCheckOutApprovalUserID(): Promise<void> {
      assert(this.asset);
      const extraUsersOptions = [
         {
            userFirstName: this.lang().Default,
            userID: 0,
            profileID: 0,
            tooltipText: this.lang().defaultCheckOutUserAssignmentTooltip,
         },
      ];

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().toolCheckOutApprovalUserIconTooltip,
               title: this.lang().ChangeToolCheckOutApprovalUser,
               locationID: this.asset.locationID,
               extraUsers: extraUsersOptions,
               defaultUser: Number(this.asset.checkOutApprovalUserID),
               defaultProfile: Number(this.asset.checkOutApprovalProfileID),
            },
         },
      };
      const userOrProfile = await instance.result;
      if (!userOrProfile) {
         return;
      }
      const assignment = await this.manageTool.updateCheckOutRequestAssignment(
         this.asset.locationID,
         userOrProfile.userID,
         userOrProfile.profileID,
         userOrProfile.multiUsers,
         this.asset.assetID,
      );
      if (!assignment) {
         return;
      }
      this.setCheckOutApprovalInfo(assignment.userID, assignment.profileID);
   }

   private setCheckOutApprovalInfo(userID: number, profileID: number): void {
      const userName = this.manageUser.getUserOrProfileDisplay(userID, profileID);
      if (userName) {
         this.checkOutApprovalUserName = userName;
      } else {
         this.checkOutApprovalUserName = this.lang().Default;
      }
   }

   public async viewWOTemplates(): Promise<void> {
      assert(this.asset);
      const dataToPassIn: any = {};
      dataToPassIn.title = this.lang().WorkOrderTemplates;
      dataToPassIn.message = "";
      dataToPassIn.locationID = this.asset.locationID;

      const instance = this.modalService.open(WorkOrderTemplatesComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: dataToPassIn,
         },
      };

      const result = await instance.result;
      if (!result) {
         return;
      }
      this.asset.defaultChecklistID = result.checklistID;
      if (!this.asset.defaultChecklistID) {
         return;
      }
      const response = await this.manageAsset.updateAssetDefaultChecklist(
         this.asset.assetID,
         this.asset.defaultChecklistID,
      );
      if (!response.data.success) {
         return;
      }
      this.asset.defaultChecklistID = result.checklistID;
      this.setDefaultChecklistInfo();
   }

   public async resetDefaultWOTemplate(): Promise<void> {
      assert(this.asset);
      this.asset.defaultChecklistID = null;
      const response = await this.manageAsset.updateAssetDefaultChecklist(
         this.asset.assetID,
         this.asset.defaultChecklistID,
      );
      if (response.data.success) {
         this.setDefaultChecklistInfo();
      }
   }

   private async setDefaultChecklistInfo(): Promise<void> {
      if (!this.asset) return;

      if (this.asset.defaultChecklistID == null || !this.asset.defaultChecklistID) {
         this.asset.defaultChecklistID = null;
         this.defaultChecklistName = this.lang().None;
         return;
      }
      const task = await lastValueFrom(
         this.taskTemplatesApiService.getById(this.asset.defaultChecklistID),
      );

      if (task) {
         this.defaultChecklistName = task.checklistName ?? this.lang().None;
      }
   }

   public async updateCheckOutRequiresApproval(): Promise<void> {
      assert(this.asset);
      const newSetting = await this.manageTool.updateCheckOutRequiresApproval(
         this.asset.assetID,
      );
      this.asset.checkOutRequiresApproval = Number(newSetting);
   }
}
