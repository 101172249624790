import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { map } from "rxjs/operators";
import type { UnitOfMeasureCustomInsertType } from "src/app/parts/unit-of-measure/custom-units/custom-unit.dto";
import {
   unitDtoSchema,
   deleteCustomUnitDtoSchema,
} from "src/app/parts/unit-of-measure/unit-of-measure.dto";
import type {
   DeleteCustomUnitDto,
   UnitDto,
   UnitSettingsDto,
} from "src/app/parts/unit-of-measure/unit-of-measure.dto.types";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class UnitOfMeasureApiService {
   protected readonly httpClient = inject(HttpClient);

   public list(): Observable<Array<UnitDto>> {
      return this.httpClient
         .get(this.getUrl("units-of-measure"))
         .pipe(map((response) => unitDtoSchema.array().parse(response)));
   }

   public createCustom(body: UnitOfMeasureCustomInsertType): Observable<UnitDto> {
      return this.httpClient
         .post<UnitDto>(this.getUrl("units-of-measure"), body)
         .pipe(map((response) => unitDtoSchema.parse(response)));
   }

   public editUnit(body: UnitDto): Observable<UnitDto> {
      return this.httpClient
         .patch<UnitDto>(this.getUrl(`units-of-measure/${body.id}`), body)
         .pipe(map((response) => unitDtoSchema.parse(response)));
   }

   public updateSettings(body: UnitSettingsDto): Observable<UnitSettingsDto> {
      return this.httpClient.put<UnitSettingsDto>(
         this.getUrl("units-of-measure/settings"),
         body,
      );
   }

   public deleteCustomUnit(id: number): Observable<DeleteCustomUnitDto> {
      return this.httpClient
         .delete(this.getUrl(`units-of-measure/${id}`))
         .pipe(map((response) => deleteCustomUnitDtoSchema.parse(response)));
   }

   protected getUrl(path: string): string {
      return `${environment.flannelUrl}/${path}`;
   }
}
