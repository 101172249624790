<lim-ui-modal [extraLarge]="true" [singleComponent]="isPOEmbedded">
   @if (!loading && purchaseOrder && purchaseOrder.poID) {
      <lim-ui-modal-header>
         <div class="modal-header-main">
            <div class="modal-header-title-container">
               <h1 class="modal-title">
                  <lim-ui-icon class="lim-ui-show-on-desktop" icon="filePowerpoint" />
                  @if (purchaseOrder && purchaseOrder.poID) {
                     <div class="lim-ui-show-on-desktop">
                        {{ lang().PurchaseOrder }}
                     </div>
                     <div class="lim-ui-hide-on-desktop">{{ lang().ViewPO }}</div>

                     <span class="lim-ui-show-on-desktop">
                        {{ poNumberForDisplay }}
                     </span>

                     <div>{{ title }}</div>
                  }
               </h1>
            </div>
            <div class="modal-header-actions">
               <lim-ui-minimal-icon-button
                  (click)="mergePOs()"
                  icon="compressRegular"
                  limUiTooltip="{{ lang().MergeOpenPOs }}"
                  [hidden]="
                     purchaseOrder &&
                     (!editableStatus?.editable || purchaseOrder.state != 0)
                  "
                  iconSize="medium-large"
               />
               <lim-ui-minimal-icon-button
                  (click)="setReminder()"
                  icon="bellRegular"
                  limUiTooltip="{{ lang().SetAReminder }}"
                  iconSize="medium-large"
               />
               @if (purchaseOrder.state !== null) {
                  <lim-ui-dropdown>
                     @if (
                        (purchaseOrder.state == 0 &&
                           purchaseOrder.requestedByUserID == 0) ||
                        (purchaseOrder.state > 0 && purchaseOrder.state != 101)
                     ) {
                        <lim-ui-minimal-icon-button
                           button
                           icon="shareFromSquareRegular"
                           iconSize="medium-large"
                        />
                     }
                     <div menu>
                        @if (
                           (purchaseOrder.state == 0 &&
                              purchaseOrder.requestedByUserID == 0) ||
                           (purchaseOrder.state > 0 && purchaseOrder.state != 101)
                        ) {
                           <lim-ui-dropdown-item (click)="printPO(false)">
                              <lim-ui-icon icon="printRegular" /> {{ lang().Print }}
                           </lim-ui-dropdown-item>
                        }
                        @if (
                           (purchaseOrder.state == 0 &&
                              purchaseOrder.requestedByUserID == 0) ||
                           (purchaseOrder.state > 0 && purchaseOrder.state != 101)
                        ) {
                           <lim-ui-dropdown-item (click)="printPO(true)">
                              <lim-ui-icon icon="printRegular" />
                              {{ lang().InternalPOPrint }}
                           </lim-ui-dropdown-item>
                        }
                        @if (purchaseOrderState?.allowReceipts && checkCred(152)) {
                           <lim-ui-dropdown-item (click)="generatePurchaseOrderEmail()">
                              <lim-ui-icon icon="envelope" /> {{ lang().SendPOEmail }}
                           </lim-ui-dropdown-item>
                        }
                     </div>
                  </lim-ui-dropdown>
               }

               @if (!isPOEmbedded) {
                  <lim-ui-minimal-icon-button
                     (click)="close()"
                     icon="xmarkLargeRegular"
                     iconSize="medium-large"
                  />
               }
            </div>
         </div>
      </lim-ui-modal-header>
   }
   <lim-ui-modal-body>
      @if (loading) {
         <lim-ui-loading-animation />
      }

      @if (!loading && purchaseOrder && purchaseOrder.poID) {
         @if (
            editableStatus?.assignedToMeAndMissingPermission === true &&
            purchaseOrder.requestedByUserID != userID
         ) {
            <lim-ui-alert alertType="warning">
               {{ lang().WhoopsThisPOIsntAssignedToYou }}
            </lim-ui-alert>
         }
         @if (
            purchaseOrder.requestedByUserID !== null &&
            purchaseOrder.requestedByUserID > 0
         ) {
            <lim-ui-alert alertType="info">
               <div class="request-alert-container">
                  <strong
                     limUiTooltip="{{ requestedUser?.userEmail }} {{
                        requestedUser?.userPhone
                     }}"
                     placement="bottom"
                  >
                     {{ lang().RequestedBy }} {{ requestedUser?.userFirstName }}
                     {{ requestedUser?.userLastName }}
                  </strong>
                  @if (startedFromTask) {
                     <div>
                        <strong
                           (click)="popTask(startedFromTask.checklistID)"
                           class="clickable-content"
                        >
                           <span [limbleHtml]="startedFromTask.checklistName"></span>
                           - #{{ startedFromTask.checklistID }}
                        </strong>
                     </div>
                  }
                  @if (
                     purchaseOrder.requestDescription &&
                     purchaseOrder.requestDescription.length > 0
                  ) {
                     <div class="requested-by-banner-text">
                        <lim-ui-icon icon="comment" />
                        <span
                           [limbleHtml]="purchaseOrder.requestDescription"
                           limUiTooltip="{{ lang().ReasonForThePurchaseRequest }}"
                        ></span>
                     </div>
                  }
                  @if (
                     purchaseOrder.requestDisapproveReason &&
                     purchaseOrder.requestDisapproveReason.length > 0
                  ) {
                     <div>
                        <span
                           (click)="reopenPurchaseRequest()"
                           class="requested-by-banner-text"
                        >
                           <lim-ui-icon
                              class="clickable-content"
                              icon="thumbsDown"
                              iconColor="danger"
                           />
                           <span
                              [limbleHtml]="purchaseOrder.requestDisapproveReason"
                              limUiTooltip="{{ lang().ReasonForTheDisapproval }}"
                           ></span>
                        </span>
                     </div>
                  }
               </div>
            </lim-ui-alert>
         }

         <lim-ui-panel>
            <header>
               <div class="related-records">
                  <div class="lim-ui-show-on-mobile">
                     @if (checkCred(149)) {
                        <lim-ui-quick-swap>
                           <ng-template limUiSwapInactive>
                              <span
                                 [ngClass]="{
                                    editable: editableStatus?.editable,
                                 }"
                              >
                                 {{ poNumberForDisplay }}
                              </span>
                           </ng-template>
                           <ng-template limUiSwapActive>
                              <input
                                 class="po-number-edit"
                                 type="number"
                                 [(ngModel)]="purchaseOrder.poNumber"
                                 (blur)="updatePONumber()"
                                 (keyup.enter)="updatePONumber()"
                                 limUiFocusOnLoad
                              />
                           </ng-template>
                        </lim-ui-quick-swap>
                     } @else {
                        <span>
                           {{ poNumberForDisplay }}
                        </span>
                     }
                  </div>

                  <!-- Current purchaseOrder Status -->
                  <div
                     placement="right"
                     limUiTooltip="{{ lang().APOGoesThroughTheFollowingSteps }}"
                  >
                     <lim-ui-icon icon="circleExclamation" iconSize="small" />
                     <span [limbleHtml]="purchaseOrderState?.name"></span>
                     @if (
                        purchaseOrder.disapproveReason &&
                        purchaseOrder.disapproveReason.length > 0
                     ) {
                        <strong
                           class="disapprove-reason"
                           limUiTooltip="{{ lang().ThisIsTheReasonItWasDisapproved }}"
                        >
                           -
                           <span [limbleHtml]="purchaseOrder.disapproveReason"></span>
                        </strong>
                     }
                  </div>

                  <!-- Vendor -->
                  <div>
                     <lim-ui-icon icon="addressCard" iconSize="small" />
                     @if (purchaseOrder.vendorID == 0) {
                        <lim-ui-text-button (click)="pickVendor()">{{
                           lang().PickVendor
                        }}</lim-ui-text-button>
                     }
                     @if (purchaseOrder.vendorID !== null && purchaseOrder.vendorID > 0) {
                        <lim-ui-dropdown-inline-text
                           [label]="
                              (purchaseOrder.vendorID > 0
                                 ? associatedVendor?.vendorName
                                 : lang().PickVendor) ?? ''
                           "
                        >
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ChangeVendor }}"
                              outputString="{{ lang().ChangeVendor }}"
                              (itemSelectedEvent)="pickVendor()"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ViewVendor }}"
                              outputString="{{ lang().ViewVendor }}"
                              (itemSelectedEvent)="popVendor()"
                           />
                        </lim-ui-dropdown-inline-text>
                     }
                  </div>

                  <!-- Budget -->
                  <div>
                     <lim-ui-icon icon="creditCard" iconSize="small" />
                     @if (purchaseOrder.budgetID == 0) {
                        <lim-ui-text-button (click)="pickBudget($event)">
                           {{ lang().PickBudget }}
                        </lim-ui-text-button>
                     }

                     @if (purchaseOrder.budgetID !== null) {
                        <lim-ui-dropdown-inline-text
                           [label]="associatedBudget?.name ?? ''"
                        >
                           @if (changedBudgetHint) {
                              <span>
                                 <lim-ui-icon
                                    icon="circleQuestion"
                                    color="warn"
                                    limUiTooltip="{{ lang().ChangedBudgetHint }}"
                                 />
                              </span>
                           }
                           <div menu aria-labelledby="categoryDropdown">
                              <lim-ui-dropdown-text-item
                                 [outputString]="lang().ChangeBudget"
                                 (click)="pickBudget($event)"
                                 [limUiPopover]="startCustomBudgetsPopover"
                                 [limUiPopoverType]="'upsell'"
                                 [limUiPopoverPlacement]="'right'"
                                 [limUiPopoverHidden]="featureCustomBudgets"
                              >
                                 <span [ngClass]="{ paywalled: !featureCustomBudgets }">{{
                                    lang().ChangeBudget
                                 }}</span>
                                 @if (!featureCustomBudgets) {
                                    <lim-ui-badge
                                       icon="stars"
                                       class="pricing-plan-badge"
                                    />
                                 }
                                 <ng-template #startCustomBudgetsPopover>
                                    <upsell-popover
                                       [message]="
                                          lang().FeatureCustomBudgetsPopoverMessage
                                       "
                                       [pricingLinkText]="lang().LearnMore"
                                    />
                                 </ng-template>
                              </lim-ui-dropdown-text-item>
                              <lim-ui-dropdown-text-item
                                 [displayString]="lang().ViewBudget"
                                 [outputString]="lang().ViewBudget"
                                 (itemSelectedEvent)="popBudget(purchaseOrder.budgetID)"
                              />
                           </div>
                        </lim-ui-dropdown-inline-text>
                     }
                  </div>

                  <!-- Assigned User -->
                  <div>
                     <lim-ui-icon icon="user" iconSize="small" />
                     <lim-ui-text-button (click)="changePOAssignment()">
                        {{ displayName }}
                     </lim-ui-text-button>
                  </div>
               </div>
               <div class="total">
                  <h3 class="label">{{ lang().Total }}</h3>
                  @if (isMultiCurrencyEnabled()) {
                     <h1 class="amount" [limUiTooltip]="currencyCode() | currencyName">
                        {{ totals?.total | localeCurrency: currencyCode() }}
                     </h1>
                  } @else {
                     <h1 class="amount">{{ totals?.total | betterCurrency }}</h1>
                  }
               </div>
            </header>

            <h3
               class="header-collapsible section-header"
               (click)="showDetails = !showDetails"
            >
               <span>{{ lang().PODetails }}</span>
               <lim-ui-minimal-icon-button
                  [icon]="showDetails ? 'chevronUp' : 'chevronDown'"
                  [superMinimal]="true"
               />
            </h3>

            @if (showDetails) {
               <section class="details-grid">
                  <div>
                     <label>{{ lang().PurchaseOrderDate }}</label>
                     <lim-ui-date-picker-input
                        [(model)]="dateDisplay"
                        [disabled]="!editableStatus?.editable || !checkCred(149)"
                        [showClearIcon]="false"
                        (modelChange)="updatePODate()"
                     />
                  </div>
                  <div>
                     <label>{{ lang().ExpectedDeliveryDate }}</label>
                     <lim-ui-date-picker-input
                        [(model)]="expectedDateDisplay"
                        [disabled]="!editableStatus?.editable || !checkCred(149)"
                        [showClearIcon]="false"
                        (modelChange)="updatePOExpectedDeliveryDate()"
                     />
                     @if (
                        leadTimes &&
                        (leadTimes.part.seconds > 0 ||
                           leadTimes.service.seconds > 0 ||
                           leadTimes.other.seconds > 0)
                     ) {
                        <div
                           class="lead-time"
                           limUiTooltip="{{
                              lang()
                                 .BasedOnPastOrderFromThisVendorTheyWillFulfilThisOrderTooltip
                           }}"
                        >
                           @if (leadTimes.part?.seconds && leadTimes.part.seconds > 0) {
                              <span>
                                 {{ lang().Parts }}:
                                 @if (leadTimes.part?.days && leadTimes.part.days > 0) {
                                    {{ leadTimes.part.days }}{{ lang().hrs }}
                                 }
                                 @if (
                                    !(leadTimes.part?.days && leadTimes.part.days > 0)
                                 ) {
                                    {{ leadTimes.part.hours }}{{ lang().hrs }}
                                 }
                              </span>
                           }
                           @if (
                              leadTimes?.service?.seconds && leadTimes.service.seconds > 0
                           ) {
                              <span>
                                 @if (
                                    leadTimes.part?.seconds && leadTimes.part.seconds > 0
                                 ) {
                                    <span> | </span>
                                 }
                                 {{ lang().Service }}:
                                 @if (
                                    leadTimes.service?.days && leadTimes.service.days > 0
                                 ) {
                                    {{ leadTimes?.service?.days }}{{ lang().hrs }}
                                 }
                                 @if (
                                    !(
                                       leadTimes?.service?.days &&
                                       leadTimes.service.days > 0
                                    )
                                 ) {
                                    {{ leadTimes.service?.hours }}{{ lang().hrs }}
                                 }
                              </span>
                           }
                        </div>
                     }
                  </div>

                  @for (field of customFields; track field) {
                     @if (
                        location["poField" + field.id + "Name"] &&
                        location["poField" + field.id + "Name"].length > 0
                     ) {
                        <div class="hover-show-icons">
                           <label>
                              <span>{{ location["poField" + field.id + "Name"] }}</span>
                              <span
                                 class="field-actions"
                                 [hidden]="!credEditPOCustomFields"
                              >
                                 <lim-ui-minimal-icon-button
                                    (click)="setPOCustomSettings(field.id)"
                                    icon="penToSquareRegular"
                                    [superMinimal]="true"
                                 />
                                 <lim-ui-minimal-icon-button
                                    class="field-remove"
                                    (click)="removePurchaseOrderCustomField(field.id)"
                                    icon="trashCanRegular"
                                    iconColor="danger"
                                    [superMinimal]="true"
                                 />
                              </span>
                           </label>
                           @if (editableStatus) {
                              <div
                                 type="text"
                                 [(ngModel)]="purchaseOrder['field' + field.id]"
                                 [limbleContentEditable]="
                                    editableStatus.editable && checkCred(149)
                                 "
                                 (afterEdit)="updatePOCustomField(field.id)"
                              ></div>
                           }
                        </div>
                     }
                  }

                  @if (showAddCustomField && credEditPOCustomFields) {
                     <div class="add-field-container">
                        <label>&nbsp;</label>
                        <lim-ui-secondary-button
                           class="hidePrint"
                           [hidden]="!editableStatus?.editable"
                           (click)="addCustomField()"
                           limUiTooltip="{{ lang().PONewFieldTooltip }}"
                           icon="plus"
                        >
                           {{ lang().PONewField }}
                        </lim-ui-secondary-button>
                     </div>
                  }
               </section>
            }
         </lim-ui-panel>

         <lim-ui-panel
            [noSidePadding]="true"
            [collapsable]="true"
            title="{{ lang().POItems }}"
         >
            @if (editableStatus) {
               <section class="po-items listTable" [hidden]="!showItemDetails">
                  <div class="listItem listItemHeader po-item-row">
                     <div
                        limUiTooltip="{{ lang().GLDescriptionTooltip }}"
                        data-placement="right"
                        class="gl-col"
                     >
                        {{ lang().GL }}
                     </div>
                     <div class="item-col">{{ lang().Item }}</div>
                     <div class="desc-col">{{ lang().Description }}</div>
                     <div class="amount-col">{{ lang().Qty }}</div>
                     <div class="amount-col">{{ lang().Rate }}</div>
                     <div class="amount-col">{{ lang().Tax }}</div>
                     <div class="amount-col">{{ lang().Discount }}</div>
                     <div class="amount-col">{{ lang().Shipping }}</div>
                     <div class="total-col">{{ lang().Total }}</div>
                     <div class="received-col">{{ lang().Received }}</div>
                     <div class="delete-col">&nbsp;</div>
                  </div>

                  @for (poItemID of purchaseOrder.poItemIDs; track poItemID) {
                     <po-item
                        #poItem
                        [poItemID]="poItemID"
                        [setProdService]="setProdService"
                        [data]="data"
                        [editable]="editableStatus.editable"
                        class="table-row-container"
                        [currencyCode]="currencyCode"
                     />
                  }

                  <div class="listItem">
                     <lim-ui-secondary-button
                        class="hidePrint"
                        [hidden]="!editableStatus.editable || !canChangePoItems"
                        (click)="addPOItem()"
                        icon="plus"
                     >
                        {{ lang().AddItem }}
                     </lim-ui-secondary-button>
                  </div>

                  <footer>
                     <div class="fields">
                        <div class="hover-show-icons">
                           <label>
                              <span>
                                 {{ lang().NotesToVendor }}
                              </span>
                              <lim-ui-minimal-icon-button
                                 (click)="changePONotesToVendorDefault()"
                                 icon="gearRegular"
                                 [superMinimal]="true"
                                 [hidden]="
                                    !(
                                       editableStatus.editable &&
                                       credEditPOLocationDefaults
                                    )
                                 "
                                 limUiTooltip="{{
                                    lang().ChangeDefaultValueOfNotesToVendor
                                 }}"
                              />
                           </label>
                           <div
                              type="text"
                              class="div-text-box"
                              [(ngModel)]="purchaseOrder.notesToVendor"
                              (afterEdit)="updatePONotesToVendor()"
                              [limbleContentEditable]="
                                 editableStatus.editable && checkCred(149)
                              "
                              tabindex="9000000"
                           ></div>
                        </div>
                        <div class="hover-show-icons">
                           <label>
                              <span>
                                 {{ lang().BillTo }}
                              </span>
                              @if (
                                 editableStatus.editable &&
                                 credEditPOLocationDefaults &&
                                 purchaseOrder.state != 101
                              ) {
                                 <lim-ui-minimal-icon-button
                                    (click)="changePOBillToDefault()"
                                    icon="gearRegular"
                                    [superMinimal]="true"
                                    limUiTooltip="{{ lang().ChangeDefaultValueOfBillTo }}"
                                 />
                              }
                           </label>
                           <div
                              type="text"
                              class="div-text-box"
                              [(ngModel)]="purchaseOrder.billTo"
                              (afterEdit)="updatePOBillTo()"
                              [limbleContentEditable]="
                                 editableStatus.editable && checkCred(149)
                              "
                              tabindex="9000001"
                           ></div>
                        </div>
                        <div class="hover-show-icons">
                           <label>
                              <span>
                                 {{ lang().ShipTo }}
                              </span>
                              @if (
                                 editableStatus.editable &&
                                 credEditPOLocationDefaults &&
                                 purchaseOrder.state != 101
                              ) {
                                 <lim-ui-minimal-icon-button
                                    (click)="changePOShipToDefault()"
                                    icon="gearRegular"
                                    [superMinimal]="true"
                                    limUiTooltip="{{ lang().ChangeDefaultValueOfShipTo }}"
                                 />
                              }
                           </label>
                           <div
                              type="text"
                              class="div-text-box"
                              [(ngModel)]="purchaseOrder.shipTo"
                              (afterEdit)="updatePOShipTo()"
                              [limbleContentEditable]="
                                 editableStatus.editable && checkCred(149)
                              "
                              tabindex="9000002"
                           ></div>
                        </div>
                     </div>

                     <div class="totals">
                        <div>
                           <span class="label"> {{ lang().Subtotal }} </span>
                           <span class="amount">
                              @if (isMultiCurrencyEnabled()) {
                                 <span
                                    class="amount"
                                    [limUiTooltip]="currencyCode() | currencyName"
                                 >
                                    {{
                                       totals?.subtotal | localeCurrency: currencyCode()
                                    }}
                                 </span>
                              } @else {
                                 <span class="amount">
                                    {{ totals?.subtotal | betterCurrency }}
                                 </span>
                              }
                           </span>
                        </div>
                        <div>
                           <span class="label"> {{ lang().Discount }} </span>
                           <span class="amount">
                              @if (isMultiCurrencyEnabled()) {
                                 <span
                                    class="amount"
                                    [limUiTooltip]="currencyCode() | currencyName"
                                 >
                                    {{
                                       totals?.discount | localeCurrency: currencyCode()
                                    }}
                                 </span>
                              } @else {
                                 <span class="amount">
                                    {{ totals?.discount | betterCurrency }}
                                 </span>
                              }
                           </span>
                        </div>
                        <div>
                           <span class="label"> {{ lang().Tax }} </span>
                           <span class="amount">
                              @if (isMultiCurrencyEnabled()) {
                                 <span
                                    class="amount"
                                    [limUiTooltip]="currencyCode() | currencyName"
                                 >
                                    {{ totals?.tax | localeCurrency: currencyCode() }}
                                 </span>
                              } @else {
                                 <span class="amount">
                                    {{ totals?.tax | betterCurrency }}
                                 </span>
                              }
                           </span>
                        </div>
                        <div>
                           <span class="label"> {{ lang().Shipping }} </span>
                           <span class="amount">
                              @if (isMultiCurrencyEnabled()) {
                                 <span
                                    class="amount"
                                    [limUiTooltip]="currencyCode() | currencyName"
                                 >
                                    {{
                                       totals?.shipping | localeCurrency: currencyCode()
                                    }}
                                 </span>
                              } @else {
                                 <span class="amount">
                                    {{ totals?.shipping | betterCurrency }}
                                 </span>
                              }
                           </span>
                        </div>
                        <div class="grand-total">
                           <span class="label"> {{ lang().Total }} </span>
                           @if (isMultiCurrencyEnabled()) {
                              <span
                                 class="amount"
                                 [limUiTooltip]="currencyCode() | currencyName"
                              >
                                 {{ totals?.total | localeCurrency: currencyCode() }}
                              </span>
                           } @else {
                              <span class="amount">
                                 {{ totals?.total | betterCurrency }}
                              </span>
                           }
                        </div>
                     </div>
                  </footer>
               </section>
            }
         </lim-ui-panel>

         <lim-ui-panel [collapsable]="true" title="{{ lang().Comments }}">
            @for (comment of comments; track comment) {
               <div class="lim-ui-list-item">
                  <div class="lim-ui-list-item-content comment hover-show-icons">
                     <div class="comment-body">
                        @if (comment.timestamp !== null) {
                           <div class="comment-message-container">
                              <div
                                 class="comment-message"
                                 [limbleHtml]="comment.message"
                              ></div>
                              <div class="comment-meta">
                                 <em>{{ comment.displayName }}</em>
                                 -
                                 {{ comment.timestamp * 1000 | betterDate: "dateTime" }}
                              </div>
                           </div>
                        }
                        <div>
                           @if (superUser) {
                              <lim-ui-minimal-icon-button
                                 (click)="deleteComment(comment)"
                                 icon="trashCanRegular"
                                 iconColor="danger"
                                 limUiTooltip="{{ lang().PermDeletePOCommentTooltip }}"
                              />
                           }
                        </div>
                     </div>

                     @if (comment.files.length > 0) {
                        <div class="comment-files">
                           @for (file of comment.files; track file) {
                              <div>
                                 @if (file.isImage) {
                                    <div>
                                       <view-image [src]="file.getURL" />
                                    </div>
                                 } @else {
                                    <div class="list-group-item">
                                       <file-list-item
                                          [file]="file"
                                          [url]="file.getURL"
                                       />
                                    </div>
                                 }
                              </div>
                           }
                        </div>
                     }
                  </div>
               </div>
            }
            <lim-ui-secondary-button (click)="addPOComment()" class="hidePrint">
               <lim-ui-icon icon="commentRegular" />&nbsp;/&nbsp;
               <lim-ui-icon icon="cameraRegular" />&nbsp;
               <span>{{ lang().AddComment }}</span>
            </lim-ui-secondary-button>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (!loading && purchaseOrder && purchaseOrder.poID) {
         <div class="modal-footer-buttons">
            <div>
               @if (checkCred(145)) {
                  <lim-ui-secondary-button
                     icon="trashCanRegular"
                     iconColor="danger"
                     (click)="deletePO()"
                  >
                     <span class="lim-ui-show-on-desktop">{{ lang().DeletePO }}</span>
                  </lim-ui-secondary-button>
               }
            </div>
            <div class="update-status">
               @if (
                  allowDisapprove == true &&
                  purchaseOrder.state !== null &&
                  purchaseOrder.state > 0 &&
                  !(
                     purchaseOrder.state == 98 ||
                     purchaseOrder.state == 99 ||
                     purchaseOrder.state == 100 ||
                     purchaseOrder.state == 101
                  ) &&
                  checkCred(165)
               ) {
                  <lim-ui-secondary-button icon="arrowLeft" (click)="disapprovePO()">
                     <span class="lim-ui-show-on-desktop">{{ lang().BackAStep }}</span>
                  </lim-ui-secondary-button>
               }

               @if (
                  purchaseOrder.state == 0 &&
                  checkCred(176) &&
                  purchaseOrder.requestedByUserID !== null &&
                  purchaseOrder.requestedByUserID > 0
               ) {
                  <lim-ui-secondary-button
                     icon="thumbsDown"
                     (click)="disapprovePurchaseRequest()"
                     limUiTooltip="{{ lang().ReopenThisRequestToBeReviewed }}"
                     tabindex="9000003"
                     >{{ lang().DisapprovePurchaseRequest }}
                  </lim-ui-secondary-button>
               }

               <!-- TODO: Disabled button still allows click handler to trigger -->
               @if (purchaseOrderState?.nextStep) {
                  <lim-ui-primary-button
                     icon="squareCheckRegular"
                     (click)="startNextPOStep()"
                     [ngClass]="{
                        disabled:
                           !canMoveToNextStep || purchaseOrder.poItemIDs.length === 0,
                     }"
                     [disabled]="
                        !canMoveToNextStep || purchaseOrder.poItemIDs.length === 0
                     "
                     tabindex="9000003"
                     >{{ lang().StartNextStep }} :
                     <span [limbleHtml]="purchaseOrderState?.nextStep?.name"></span>
                  </lim-ui-primary-button>
               }

               @if (purchaseOrderState?.allowReceipts && checkCred(152)) {
                  <lim-ui-primary-button
                     icon="truck"
                     (click)="createPR()"
                     tabindex="9000003"
                     [disabled]="purchaseOrder.poItemIDs.length === 0"
                     >{{ lang().ReceiveItems }}
                  </lim-ui-primary-button>
               }

               @if (purchaseOrder.state == 101) {
                  <lim-ui-primary-button
                     icon="arrowsRotate"
                     (click)="reopenPurchaseRequest()"
                     limUiTooltip="{{ lang().ReopenThisRequestToBeReviewed }}"
                     tabindex="9000003"
                     >{{ lang().ReopenPurchaseRequest }}
                  </lim-ui-primary-button>
               }
            </div>
         </div>
      }
   </lim-ui-modal-footer>
</lim-ui-modal>
