<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         {{ lang().Abbreviation }}

         <div>
            <!--Have to have this div else edge breaks-->
            <div
               type="text"
               [(ngModel)]="abbr"
               limbleContentEditable
               disableEditor="true"
               tabindex="88888"
            ></div>
         </div>
         {{ lang().Name }}
         <div>
            <!--Have to have this div else edge breaks-->
            <div
               type="text"
               [(ngModel)]="name"
               limbleContentEditable
               disableEditor="true"
               tabindex="88889"
            ></div>
         </div>
         {{ lang().Description }}
         <div>
            <!--Have to have this div else edge breaks-->
            <div
               type="text"
               class="div-text-box"
               [(ngModel)]="glDescription"
               limbleContentEditable
               disableEditor="true"
               tabindex="88889"
            ></div>
         </div>
         {{ lang().Asset }}
         <lim-ui-icon
            icon="cube"
            limUiTooltip="{{ lang().AssetTooltip }}"
            placement="right"
         />
         <a class="cursor green-color-link" (click)="associateAssetToGL()">
            <span [hidden]="!assetName" [limbleHtml]="assetName"></span>
            <span [hidden]="!!assetName">{{ lang().None }}</span>
         </a>
      </lim-ui-panel>

      <div class="alert alert-dismissable alert-warning" [hidden]="!(errorMsg !== '')">
         <strong class="mr-1">{{ lang().Warning }}!</strong> -
         <div [limbleHtml]="errorMsg"></div>
      </div>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (checkCred(155) && glID > 0) {
         <lim-ui-danger-button (click)="deleteGeneralLedger(glID)" icon="trashCanRegular">
            {{ lang().DeleteGL }}
         </lim-ui-danger-button>
      }
      <div>
         <lim-ui-secondary-button icon="cloudArrowUp" (click)="importGeneralLedgers()">
            <span class="mobileMediumDisappear">{{ lang().Import }}</span>
         </lim-ui-secondary-button>
      </div>
      <div>
         <lim-ui-primary-button (click)="submit()">
            <span [hidden]="!(glID == 0)">{{ lang().Add }}</span>
            <span [hidden]="!(glID > 0)">{{ lang().Update }}</span>
         </lim-ui-primary-button>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
