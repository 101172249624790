<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel [noSidePadding]="true" class="modal-body">
         @if (template) {
            <div class="panel-content-wrapper">
               @for (invoice of template.invoices; track invoice) {
                  <div class="invoice-container">
                     @if (invoice.invoiceID; as invoiceID) {
                        <span class="column-flex list-item">
                           <span class="row-flex">
                              <div class="form-group">
                                 <label class="control-label"
                                    >{{ lang().InvoiceCost }} ({{
                                       currencySymbol
                                    }})</label
                                 >
                                 <div>
                                    <div class="input-length">
                                       @if (invoiceIndex[invoiceID].poItemID == 0) {
                                          <span class="input-length">
                                             <input
                                                type="number"
                                                class="input-length"
                                                [(ngModel)]="
                                                   invoiceIndex[invoiceID].invoiceCost
                                                "
                                                (blur)="
                                                   updateInvoiceCost(
                                                      invoiceIndex[invoiceID].invoiceID,
                                                      invoiceIndex[invoiceID].invoiceCost
                                                   )
                                                "
                                                onclick="this.focus();"
                                                min="0.00"
                                                placeholder="0.00"
                                             />
                                          </span>
                                       }
                                       @if (invoiceIndex[invoiceID].poItemID > 0) {
                                          <span>
                                             {{ currencySymbol }}
                                             {{
                                                invoiceIndex[invoiceID].invoiceCost
                                                   | betterNumber: "1.2-2"
                                             }}
                                             -
                                             <a
                                                class="cursor"
                                                (click)="
                                                   popPoComponent(
                                                      invoiceIndex[invoiceID].poID
                                                   )
                                                "
                                                >{{ lang().PO }} -
                                                {{ invoiceIndex[invoiceID].poNumber }}</a
                                             >
                                          </span>
                                       }
                                    </div>
                                 </div>
                              </div>

                              <div class="form-group">
                                 <label class="control-label">{{
                                    lang().InvoiceDescription
                                 }}</label>
                                 <div>
                                    <div class="input-length">
                                       @if (invoiceIndex[invoiceID].poItemID == 0) {
                                          <span>
                                             <div
                                                type="text"
                                                class="div-text-box update-invoice"
                                                [(ngModel)]="
                                                   invoiceIndex[invoiceID]
                                                      .invoiceDescription
                                                "
                                                limbleContentEditable
                                                (blur)="
                                                   updateInvoiceDescription(
                                                      invoiceIndex[invoiceID]
                                                   )
                                                "
                                             ></div>
                                          </span>
                                       }

                                       @if (invoiceIndex[invoiceID].poItemID > 0) {
                                          <span>
                                             {{
                                                invoiceIndex[invoiceID].invoiceDescription
                                             }}
                                          </span>
                                       }
                                    </div>
                                 </div>
                              </div>
                           </span>
                           <span class="row-flex-icons">
                              <div class="form-group">
                                 <label class="control-label">{{
                                    lang().AttachInvoice
                                 }}</label>

                                 @if (
                                    invoiceIndex[invoiceID].uploadObj &&
                                    (invoiceIndex[invoiceID].fileName === undefined ||
                                       invoiceIndex[invoiceID].fileName === null)
                                 ) {
                                    <file-uploader
                                       [uploadObject]="invoiceIndex[invoiceID].uploadObj"
                                       icon="cameraRegular"
                                       label="{{ lang().AttachFile }}"
                                    />
                                 }
                                 @if (
                                    invoiceIndex[invoiceID].fileName !== undefined &&
                                    invoiceIndex[invoiceID].fileName !== null &&
                                    invoiceIndex[invoiceID].uploadObj
                                 ) {
                                    <file-uploader
                                       [uploadObject]="invoiceIndex[invoiceID].uploadObj"
                                       icon="cameraRegular"
                                       label="{{ lang().ChangeFile }}"
                                    />
                                 }
                                 @if (
                                    invoiceIndex[invoiceID].fileName !== undefined &&
                                    invoiceIndex[invoiceID].fileName !== null
                                 ) {
                                    <div class="invoice-file-list-item-and-button">
                                       <file-list-item
                                          [file]="invoiceIndex[invoiceID]"
                                          url="viewFile.php?f=upload-{{
                                             customerID
                                          }}/invoices/{{ template.checklistID }}/{{
                                             invoiceIndex[invoiceID].invoiceFileName
                                                | escape
                                          }}"
                                       />
                                       <lim-ui-icon-button
                                          limUiTooltip="{{ lang().RemoveThisInvoice }}"
                                          (click)="
                                             removeInvoice(
                                                invoiceIndex[invoiceID].poItemID,
                                                invoiceIndex[invoiceID].invoiceID
                                             )
                                          "
                                          icon="trashCanRegular"
                                          iconColor="danger"
                                          iconSize="small"
                                       />
                                    </div>
                                 }
                              </div>

                              @if (
                                 invoiceIndex[invoiceID].fileName === undefined ||
                                 invoiceIndex[invoiceID].fileName === null
                              ) {
                                 <lim-ui-icon-button
                                    limUiTooltip="{{ lang().RemoveThisInvoice }}"
                                    (click)="
                                       removeInvoice(
                                          invoiceIndex[invoiceID].poItemID,
                                          invoiceIndex[invoiceID].invoiceID
                                       )
                                    "
                                    icon="trashCanRegular"
                                    iconColor="danger"
                                    iconSize="small"
                                 />
                              }
                           </span>
                        </span>
                     }

                     <div class="error-msg" [hidden]="!(errorMsg !== '')">
                        <div class="alert alert-dismissable alert-danger">
                           <strong class="mr-1">{{ lang().Warning }}!</strong>
                           <span [limbleHtml]="errorMsg"></span>
                        </div>
                     </div>

                     <hr class="title-hr" />
                  </div>
               }
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer class="add-invoice-button">
      <lim-ui-secondary-button (click)="addInvoice()">
         {{ lang().AddInvoice }}
      </lim-ui-secondary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
