import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit, Signal } from "@angular/core";
import { Component, computed, inject, Input, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   DragEndEvent,
   DraggableDirective,
   DragStartEvent,
   type TreeBranch,
} from "@limble/limble-tree";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   BadgeComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownItemComponent,
   FormDropdownInputComponent,
   IconComponent,
   LimbleHtmlDirective,
   LoadingBarService,
   MinimalIconButtonComponent,
   ModalService,
   PopoverDirective,
   SearchBoxComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import moment from "moment";
import { Subscription } from "rxjs";
import { AssetCapitalDepreciation } from "src/app/assets/components/assetCapitalDepreciationModal/assetCapitalDepreciation.modal.component";
import { AttachSensorToAsset } from "src/app/assets/components/attachSensorToAssetModal/attachSensorToAsset.modal.component";
import { ConfigureSensorInterface } from "src/app/assets/components/configureSensorInterfaceModal/configureSensorInterface.modal.component";
import { EditAssetField } from "src/app/assets/components/editAssetFieldModal/editAssetField.modal.component";
import { IntroToSensors } from "src/app/assets/components/introToSensorsModal/introToSensors.modal.component";
import { UpdateImonnitToken } from "src/app/assets/components/updateImonnitTokenModal/updateImonnitToken.modal.component";
import { ViewAssetItemHistory } from "src/app/assets/components/viewAssetItemHistoryModal/viewAssetItemHistory.modal.component";
import { ViewSensorInfo } from "src/app/assets/components/viewSensorInfoModal/viewSensorInfo.modal.component";
import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import {
   AssetFieldScopeType,
   type AssetTemplateField,
} from "src/app/assets/services/asset-field.types";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageImonnit } from "src/app/assets/services/manageImonnit";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldType } from "src/app/assets/types/field/type/asset-field-type.types";
import type { AssetFieldValueFile } from "src/app/assets/types/field/value/file/file.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { AssetValueReductionValidator } from "src/app/assets/validators/asset-value-reduction.validator";
import { EditableImage } from "src/app/files/components/editableImage/editableImage.element.component";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import { ViewFile } from "src/app/files/components/viewFile/viewFile.element.component";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { type LanguageCode, ManageLang } from "src/app/languages";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { CurrencyInputComponent } from "src/app/purchasing/currency-input/currency-input.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type ValueExtraInfo = {
   oldValueContent: string | number | Date | null;
   json: Array<any>;
   fieldTypeIcon: string | null;
   fieldTypeHint: string | null;
};

type AssetCreds = {
   configureAssetFields: boolean;
   changeAssetFieldsValues: boolean;
};

@Component({
   selector: "asset-information-item",
   templateUrl: "./assetInformationItem.element.component.html",
   styleUrls: ["./assetInformationItem.element.component.scss"],
   imports: [
      IconComponent,
      DraggableDirective,
      TooltipDirective,
      LimbleHtmlDirective,
      DropdownComponent,
      MinimalIconButtonComponent,
      DropdownItemComponent,
      NgClass,
      FormsModule,
      ContenteditableDirective,
      DatePickerInputComponent,
      FileUploader,
      EditableImage,
      ViewFile,
      FormDropdownInputComponent,
      SearchBoxComponent,
      BetterDatePipe,
      IconAlias,
      BadgeComponent,
      PopoverDirective,
      UpsellPopover,
      AssetValueReductionValidator,
      CurrencyInputComponent,
      CurrencySymbolPipe,
   ],
})
export class AssetInformationItem implements OnInit, OnDestroy {
   @Input() public treeBranch?: TreeBranch<AssetInformationItem>;
   @Input() public restrict: boolean = false;
   @Input() public assetTemplateName: string = "";
   @Input() public templateFieldsMap: Map<number, AssetTemplateField> = new Map();
   @Input() public currencyCode: Signal<string> = signal("");

   public currentUser: any;
   protected localeID: LanguageCode;
   public asset: Asset | undefined;
   public uploadObj;
   public dateReminderHint;
   public showDateHint;
   public sensorAttached;
   public sensorConfig;
   public sensorUnits;
   public readingType;
   public runtimeUnits;
   public relationship;
   public fieldOptionsSub;
   public fieldOptionsObs;
   public searchOptions;
   public searchBar;
   public displayAssetDepreciationButton: boolean | undefined;
   private assetsWatchVarSub;
   private oldValueContent;
   private dateReminderStreamSub: Subscription | undefined;
   public fieldValue: AssetFieldValue | undefined;
   public valueExtraInfo: ValueExtraInfo | undefined;
   public fieldInfo: AssetField | undefined;
   public fieldTypes: Lookup<"fieldTypeID", AssetFieldType>;
   public creds: AssetCreds = {
      configureAssetFields: false,
      changeAssetFieldsValues: false,
   };
   public allFiles: Lookup<"fileID", AssetFieldValueFile> | undefined;
   protected featureCapitalDepreciation: boolean = false;
   protected eyeSlashIcon: Aliases = "eyeSlashRegular";
   protected eyeIcon: Aliases = "eyeRegular";
   private manageFeatureFlagsSub: Subscription = new Subscription();
   protected saveInProgress: boolean = false;
   protected fieldLocked: boolean = false;
   protected isStandardizedField: boolean = false;
   protected isDraggable: boolean = false;
   protected isFieldLinkedToTemplate: boolean = false;
   protected isDragging: boolean = false; // Used to determine if the tree is in a dragging state
   protected readonly AssetFieldTypeID = AssetFieldTypeID;

   private readonly manageLang = inject(ManageLang);
   private readonly alertService = inject(AlertService);
   public readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly credService = inject(CredService);
   private readonly manageImonnit = inject(ManageImonnit);
   private readonly manageFiles = inject(ManageFiles);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUser = inject(ManageUser);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageUtil = inject(ManageUtil);

   private branchEventsSubscription;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.fieldTypes = this.manageAsset.getFieldTypes();
      this.allFiles = this.manageAsset.getFieldValueFiles();
      this.localeID = this.manageLang.getLocaleID();
   }

   public ngOnInit() {
      this.fieldValue = this.treeBranch?.meta().nodeData.fieldValue;

      if (this.fieldValue === undefined) {
         throw new Error(
            "AssetInformationItem component requires a `treeBranch` nodeData.fieldValue, but a value was not provided.",
         );
      }

      /**
       * Making sure that the valueContent is always set
       * to avoid any errors in the case that fieldValue doesn't have a valueContent property.
       * Some fieldValue properties are pruned when fetching the fieldValues to save memory.
       */
      this.fieldValue.valueContent = this.fieldValue?.valueContent ?? "";

      this.branchEventsSubscription = this.treeBranch
         ?.root()
         ?.events()
         .subscribe((event) => {
            if (event instanceof DragStartEvent) {
               this.isDragging = true;
            } else if (event instanceof DragEndEvent) {
               this.isDragging = false;
            }
         });

      this.isDraggable = this.treeBranch?.meta().nodeData?.isDraggable ?? false;

      if (this.treeBranch?.meta().nodeData.fieldInfo) {
         this.fieldInfo = this.treeBranch?.meta().nodeData.fieldInfo;
      } else {
         this.fieldInfo = this.manageAsset.getField(this.fieldValue.fieldID);
      }

      if (this.fieldInfo === undefined) {
         throw new Error(
            "AssetInformationItem component requires `fieldInfo`, no field for the given fieldID exists.",
         );
      }

      this.isStandardizedField =
         this.fieldInfo.scopeType === AssetFieldScopeType.Standardized;

      this.isFieldLinkedToTemplate = this.templateFieldsMap.has(this.fieldValue.fieldID);

      this.currentUser = this.manageUser.getCurrentUser();
      this.fieldLocked = Boolean(
         this.fieldInfo.lockedDefault &&
            !this.manageUtil.checkIfSuperUser(this.currentUser),
      );

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureCapitalDepreciation =
               isFeatureEnabledMap.featureCapitalDepreciation;
         },
      );

      const fieldType = this.fieldTypes.get(this.fieldInfo.fieldTypeID);
      const typeIcon = fieldType ? fieldType.fieldTypeIcon : null;
      const typeHint = fieldType ? fieldType.fieldTypeHint : null;

      this.valueExtraInfo = {
         json: [],
         fieldTypeIcon: typeIcon,
         fieldTypeHint: typeHint,
         oldValueContent: this.fieldValue.valueContent,
      };

      if (this.fieldInfo.fieldTypeID == AssetFieldTypeID.Dropdown) {
         this.fieldOptionsObs = `assetFieldOptionsJSON${this.fieldValue.fieldID}`;
         this.fieldOptionsSub = this.manageObservables.createAndSubscribe(
            this.fieldOptionsObs,
            () => {
               assert(this.fieldInfo);
               // optionsJSON (now parsedOptionsJson) was having problems with ng-repeat if it was updated elsewhere
               // building a local copy (field.json) cleared the errors
               const parsedOptionsJson = this.manageAsset.getParsedOptionsJson(
                  this.fieldInfo,
               );
               if (parsedOptionsJson) {
                  assert(this.valueExtraInfo);
                  this.valueExtraInfo.json = [];

                  parsedOptionsJson.forEach((option) => {
                     this.valueExtraInfo?.json.push(option.name);
                  });
               }
            },
         );
      }
      this.searchOptions = this.valueExtraInfo.json.filter((option) => option.length);
      //overriding this.asset so that it is the asset associated with the field and not the asset that this item is created under
      //This is so that we can display different asset's fields in a single asset.  This allows for group asset information
      this.asset = this.manageAsset.getAsset(this.fieldValue.assetID);

      this.getAssetCreds();

      //if it is a number of currency type then it needs to become a number not a string for the html element to work.
      if (
         [AssetFieldTypeID.Number, AssetFieldTypeID.Currency].includes(
            this.fieldInfo.fieldTypeID,
         )
      ) {
         this.fieldValue.valueContent = Number(this.fieldValue.valueContent);
      } else if (
         [
            AssetFieldTypeID.Picture,
            AssetFieldTypeID.File,
            AssetFieldTypeID.Video,
         ].includes(this.fieldInfo.fieldTypeID)
      ) {
         assert(this.asset);
         //prep data for the file uploader

         this.uploadObj = this.manageAsset.setUploadObject(
            this.fieldInfo.fieldTypeID,
            this.fieldValue.valueID,
            this.asset,
         );

         this.uploadObj.viewOnly = !this.creds.changeAssetFieldsValues;

         this.uploadObj.deleteSuccess = (answer) => {
            if (answer.data.success == true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         };
      }

      //we need to watch for dateReminder changes
      if (this.fieldInfo.fieldTypeID == AssetFieldTypeID.Date) {
         if (this.fieldValue.valueContent) {
            this.fieldValue.valueContent = new Date(this.fieldValue.valueContent);
         }
         this.setDateReminderHint();

         const dateReminderStream$ = this.manageAsset.dateReminderStreamMap.get(
            this.fieldValue.fieldID,
         );

         this.dateReminderStreamSub = dateReminderStream$?.subscribe(() => {
            this.setDateReminderHint();
         });
      }

      this.oldValueContent = this.fieldValue.valueContent;

      this.updateDepreciationButtonDisplay();

      this.assetsWatchVarSub = this.manageAsset.assetState().subscribe(() => {
         this.updateDepreciationButtonDisplay();
      });

      //=============================================================//
      //                      SENSORS                                //
      //=============================================================//

      this.sensorAttached = false;
      this.sensorConfig = -1;
      this.sensorUnits = "";
      this.readingType = "";
      this.runtimeUnits = "";
      this.relationship = {};

      //only number types can have sensors attached to them
      if (this.fieldInfo.fieldTypeID == AssetFieldTypeID.Number) {
         this.propagateSensorData();
      }
   } // end of onInit

   public ngOnDestroy() {
      this.manageObservables.unsubscribeAndDelete(
         this.fieldOptionsObs,
         this.fieldOptionsSub,
      );
      this.dateReminderStreamSub?.unsubscribe();
      this.manageObservables.removeSubscription(this.assetsWatchVarSub);
      this.manageFeatureFlagsSub.unsubscribe();

      this.branchEventsSubscription?.unsubscribe();
   }

   dropdownSearch = () => {
      if (this.searchBar && this.searchBar != "") {
         this.searchOptions = this.valueExtraInfo?.json.filter((item) =>
            item.toLowerCase().includes(this.searchBar.trim().toLowerCase()),
         );
      } else {
         this.searchOptions = this.valueExtraInfo?.json;
      }
   };

   //opens a doka instance -> no permissions here as if you are on the build tasks page you should have permission to edit the files.
   openEditor = async (file) => {
      if (this.creds.changeAssetFieldsValues) {
         assert(this.fieldValue);
         assert(this.asset);
         const imagePath = `viewFile.php?f=upload-${this.currentUser?.userInfo?.customerID}/assets/${this.asset.locationID}/${this.asset.assetID}/${this.fieldValue.valueID}/${file.fileName}`;
         const doka: any = await this.manageFiles.createImageEditor(imagePath);
         //when the user clicks confirm this deletes the old version of the file, and uploads the edited file
         //has to convert doka's output to formdata for uploading and play with the fileName a little bit so the names
         //don't get longer each time the file is edited
         doka.onconfirm = async (output) => {
            assert(this.fieldValue);
            assert(this.asset);
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            await this.manageAsset.deleteFile(
               file.fileID,
               this.fieldValue,
               this.asset.locationID,
            );
            const formData = new FormData();
            formData.append(
               "myfile",
               output.file,
               file.fileName.replace(/\d\d\d\d-/, ""),
            );

            const response = await this.uploadObj.uploadCall(
               this.uploadObj.posturl,
               formData,
            );
            if (!response) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
            }
            this.loadingBarService.remove();
            this.uploadObj.uploadComplete(response.data);
         };
      }
   };

   protected getFieldValueContent(
      valueContent: string | number | Date | null,
   ): string | number | null {
      if (valueContent instanceof Date) {
         // If valueContent is of type Date, return null or some other default value
         return null;
      }

      // Otherwise, return the original value
      return valueContent;
   }

   protected get fieldValueContentNumeric(): number {
      return Number(this.fieldValue?.valueContent ?? 0);
   }

   protected handleCurrencyFieldValueChange(updatedValue: number) {
      assert(this.fieldValue);
      this.fieldValue.valueContent = updatedValue;
      this.setFieldValue();
   }

   protected handleInvalidCurrencyInput(): void {
      this.alertService.addAlert(this.lang().PleaseEnterANumber, "warning", 1000);
   }

   protected changeNumber() {
      assert(this.fieldValue);
      if (typeof this.fieldValue.valueContent === "number") {
         this.setFieldValue();
      } else {
         this.alertService.addAlert(this.lang().PleaseEnterANumber, "warning", 1000);
      }
   }

   changeSelectedNameField = (selected) => {
      assert(this.fieldValue);
      this.fieldValue.valueContent = selected;
      this.setFieldValue();
   };

   clearFieldValue = () => {
      assert(this.fieldValue);
      this.fieldValue.valueContent = null;
      this.setFieldValue();
   };

   setFieldValue = () => {
      assert(this.fieldValue);
      assert(this.fieldInfo);
      assert(this.valueExtraInfo);
      const oldValue = this.oldValueContent;
      if (this.oldValueContent == this.fieldValue.valueContent) {
         //prevents un needed calls ;p
         return;
      }
      if (this.fieldLocked) {
         // The field is locked and they're not a super user so it can't be changed
         this.fieldValue.valueContent = this.oldValueContent;
         return;
      }

      // If it's a date, we need to make sure it's a valid date and not a string
      // An empty field is ok so that they can unset the field though
      if (
         this.fieldInfo.fieldTypeID === AssetFieldTypeID.Date &&
         this.fieldValue.valueContent !== null
      ) {
         assert(this.fieldValue.valueContent);
         if (
            !(this.fieldValue.valueContent instanceof Date) &&
            this.fieldValue.valueContent !== null
         ) {
            this.fieldValue.valueContent = this.valueExtraInfo.oldValueContent;
            return;
         }
      }
      assert(this.asset);

      this.saveInProgress = true;

      this.manageAsset.setFieldValue(this.fieldValue, this.asset, 0).then((answer) => {
         if (answer.data.success == true) {
            assert(this.fieldValue);
            assert(this.asset);
            this.oldValueContent = this.fieldValue.valueContent;
            const currentField = this.manageAsset.getField(this.fieldValue.fieldID);

            assert(currentField);
            this.manageAsset.beginChildAssetUpdates(
               oldValue,
               this.fieldValue.valueContent ?? null,
               this.fieldValue,
               this.asset,
               currentField,
            );

            // Have to do this to get an accurate 'Next Created at' on the PMs page
            if (answer.data.newTasks === "skip") {
               this.manageTask.getData();
            }

            //if a task needs to be added due to the fact that a new task was created.
            else if (answer.data.newTasks != false) {
               //filter the tasks to only include the correct ones.  This step is just a precaution
               const filteredTasks = this.manageFilters.filterTasksByCredAtLocation(
                  answer.data.newTasks,
                  this.currentUser,
               );

               for (const tempTask of filteredTasks) {
                  this.manageTask.addTaskToLookup(tempTask);
               }

               this.alertService.addAlert(
                  `<i class='fa-solid fa-circle-exclamation fa-fw'></i> ${this.lang().ANewTaskWasCreatedAsAResultOfInformationAdded}`,
                  "success",
                  5000,
               );
            }
            this.manageAsset.emitGeneralFieldValueUpdate();
            this.manageTask.incTasksWatchVar();

            if (this.fieldInfo?.fieldTypeID == AssetFieldTypeID.Date) {
               this.setDateReminderHint();
            }

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else if (answer.data.error === "nonUniqueValue") {
            assert(this.fieldValue);
            this.fieldValue.valueContent = this.oldValueContent;
            this.alertService.addAlert(this.lang().valueUniqueError, "danger", 6000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
         this.saveInProgress = false;
      });
   };

   removeField = () => {
      if (this.isFieldLinkedToTemplate) return;

      assert(this.fieldValue);
      this.manageAsset.checkForRecurrences(this.fieldValue).then((answer) => {
         //default warning message
         let warningHTML = this.lang().RemoveFieldAreYouSure;

         if (answer.data.success == true) {
            const arrayOfRecurrencesWithChecklistName = answer.data.data;

            //overwrite it since we did get recurrences back
            warningHTML = "";
            warningHTML += '<span class="redColor"><b>';
            warningHTML += this.lang().TheFollowingRecurrencesRelyOnthisField;
            warningHTML += "</b></span>";

            warningHTML += "<br /><br />";

            warningHTML += "<ul>";
            for (const recurrence of arrayOfRecurrencesWithChecklistName) {
               warningHTML += "<li>";
               warningHTML += `<b>${recurrence.checklistName}</b>`;
               warningHTML += " - ";
               switch (Number(recurrence.reoccurType)) {
                  case 7:
                     warningHTML += `${this.lang().Every} ${recurrence.reoccurFld2} ${this.fieldInfo?.fieldName}`;
                     break;
                  case 8:
                     warningHTML += `${this.lang().Every} `;
                     if (recurrence.reoccurFld3 == 0) {
                        warningHTML += this.lang().below;
                     } else if (recurrence.reoccurFld3 == 1) {
                        warningHTML += this.lang().above;
                     } else if (recurrence.reoccurFld3 == 2) {
                        warningHTML += this.lang().between;
                     }

                     warningHTML += ` ${recurrence.reoccurFld2} `;

                     if (recurrence.reoccurFld3 == 2) {
                        warningHTML += `${this.lang().and} ${recurrence.reoccurFld5} `;
                     }

                     warningHTML += this.fieldInfo?.fieldName;

                     break;
                  default:
                     break;
               }
               warningHTML += "<br />";
               warningHTML += "</li>";
            }
            warningHTML += "</ul>";

            warningHTML += "<br />";
            warningHTML += this.lang().RemoveFieldAreYouSure;
         }

         const instance = this.modalService.open(Confirm);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: warningHTML,
               title: this.lang().RemoveField,
            },
         };

         instance.result.then((result) => {
            if (result == 1) {
               assert(this.fieldValue);
               assert(this.asset);
               this.manageImonnit.detachSensorFromValueID(this.fieldValue.valueID);
               this.manageAsset
                  .removeField(this.fieldValue, this.asset)
                  .then((answer2) => {
                     if (answer2?.data.success == true) {
                        //rebuilds this asset's field data.
                        this.manageAsset.tileFieldsObs$.next(null);
                        this.manageTask.getData().then(() => {
                           //resets the correct data so that it loads properly
                           this.alertService.addAlert(
                              this.lang().successMsg,
                              "success",
                              2000,
                           );
                        });

                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     }
                  });
            }
         });
      });
   };

   setDateReminderHint = () => {
      assert(this.fieldValue);
      assert(this.fieldInfo);

      const fieldDate = this.fieldValue.valueContent;
      const today = moment();

      this.dateReminderHint = this.lang().DateReminderHint;

      const reminderDates = [
         ...new Set([
            this.fieldInfo.dateReminder1,
            this.fieldInfo.dateReminder2,
            this.fieldInfo.dateReminder3,
         ]),
      ]
         .filter((day) => day !== null)
         .map((days) => moment(fieldDate).subtract(days, "d"))
         .filter((date) => date.isAfter(today))
         .sort((dateA, dateB) => dateA.toDate().getTime() - dateB.toDate().getTime())
         .map((date) => this.betterDate.formatBetterDate(date.toDate(), "date"));

      if (reminderDates.length > 0) {
         this.dateReminderHint += ` ${reminderDates.join(", ")}`;
      } else {
         this.dateReminderHint = "No future reminders scheduled.";
      }

      // The tooltip title won't update unless we do something like this
      this.showDateHint = false;
      setTimeout(() => {
         this.showDateHint = true;
      }, 10);
   };

   showFieldOptions = (field) => {
      assert(this.asset);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.CreateNewFieldsAndDeleteExistingFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred65Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(EditAssetField);
      instance.componentInstance.fieldID.set(field.fieldID);
      instance.componentInstance.assetTemplateID.set(this.asset?.templateID ?? 0);

      instance.result.then(() => {
         this.setDateReminderHint();
      });
   };

   deleteDocument = (file) => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteDocumentMsg,
            title: this.lang().DeleteDocument,
         },
      };

      instance.result.then((result) => {
         assert(this.fieldValue);
         assert(this.asset);
         if (result == 1) {
            this.manageAsset
               .deleteFile(file.fileID, this.fieldValue, this.asset.locationID)
               .then((answer) => {
                  if (answer?.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   updateFieldViewableByTech = (field: AssetFieldValue) => {
      if (this.isStandardizedField || this.isFieldLinkedToTemplate) return;

      assert(this.fieldInfo);
      this.manageAsset
         .updateFieldViewableByTech(field, this.fieldInfo.locationID)
         .then((answer) => {
            if (answer.data.success == true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 3000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   viewHistory = (field: AssetFieldValue) => {
      this.manageAsset.getAssetItemInfoHistory(field).then((answer) => {
         if (answer.data.success) {
            const instance = this.modalService.open(ViewAssetItemHistory);

            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: "",
                  title: this.lang().HistoryOfChanges,
                  data: answer.data.history,
                  field: field,
                  currencyCode: this.currencyCode,
               },
            };
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   //=============================================================//
   //                      SENSORS                                //
   //=============================================================//

   propagateSensorData = () => {
      assert(this.fieldValue);
      this.manageImonnit
         .getFieldsWithAttachedSensors(this.fieldValue.valueID)
         .then((answer) => {
            if (answer.data.success) {
               if (answer.data.relations.length > 0) {
                  this.relationship = answer.data.relations[0];
                  this.sensorAttached = this.relationship.monnitSensorID;
                  this.sensorConfig = this.relationship.config;
                  this.sensorUnits = this.relationship.label1;
                  this.readingType = this.relationship.label2;
                  if (this.sensorConfig == 2) {
                     assert(this.fieldValue);
                     this.manageImonnit
                        .getConfigRules(this.fieldValue.valueID, this.sensorConfig)
                        .then((response) => {
                           if (response.data.success) {
                              if (response.data.rules[0].units == 0) {
                                 this.runtimeUnits = this.lang().Minutes;
                              } else if (response.data.rules[0].units == 1) {
                                 this.runtimeUnits = this.lang().Hours;
                              } else if (response.data.rules[0].units == 2) {
                                 this.runtimeUnits = this.lang().Days;
                              }
                           }
                        });
                  }
               } else {
                  //No sensor attached
                  this.sensorAttached = false;
                  this.sensorUnits = "";
                  this.readingType = "";
                  this.sensorConfig = -1;
               }
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   attachOrConfigureSensor = (username: false, password: false) => {
      if (this.isFieldLinkedToTemplate) return;

      this.manageImonnit.signIn(username, password).then((answer) => {
         if (answer.data.success) {
            if (this.sensorAttached) {
               this.viewSensorInfo().result.then((result) => {
                  if (result.detach) {
                     assert(this.fieldValue);
                     this.manageImonnit
                        .detachSensorFromValueID(this.fieldValue.valueID)
                        .then((answer2) => {
                           assert(this.fieldValue);
                           if (answer2.data.success) {
                              this.propagateSensorData();
                              this.fieldValue.valueLabel = "";
                           } else {
                              this.alertService.addAlert(
                                 this.lang().errorMsg,
                                 "danger",
                                 6000,
                              );
                           }
                        });
                  } else if (result.configure) {
                     this.configure(this.sensorAttached, result.rules).result.then(
                        (config) => {
                           if (config) {
                              this.applyConfiguration(config)
                                 .then(async () => {
                                    if (config.selected == 0) {
                                       return this.manageImonnit
                                          .getLastSensorReading(this.sensorAttached)
                                          .then((answer2) => {
                                             assert(this.fieldValue);
                                             if (answer2.data.success) {
                                                const newValues =
                                                   answer2.data.reading.PlotValues.split(
                                                      "|",
                                                   );
                                                this.fieldValue.valueContent = Number(
                                                   newValues[this.relationship.dataPoint],
                                                );
                                             } else {
                                                this.alertService.addAlert(
                                                   this.lang().errorMsg,
                                                   "danger",
                                                   6000,
                                                );
                                             }
                                          });
                                    }
                                    return Promise.resolve();
                                 })
                                 .then(() => {
                                    if (!this.fieldValue || !this.asset) {
                                       return;
                                    }
                                    this.manageAsset.setFieldValue(
                                       this.fieldValue,
                                       this.asset,
                                       0,
                                    );
                                    this.propagateSensorData();
                                 })
                                 .catch((error: unknown) => {
                                    console.error(error);
                                 });
                           }
                        },
                     );
                  }
               });
            } else {
               this.selectSensor().result.then((sensor) => {
                  if (sensor.selectedSensor) {
                     this.configure(sensor.selectedSensor).result.then((config) => {
                        if (config) {
                           this.attachSensor(sensor)
                              .then(() => {
                                 this.applyConfiguration(config);
                              })
                              .then(() => {
                                 if (!this.asset || !this.fieldValue) {
                                    return;
                                 }
                                 if (config.selected == 0) {
                                    this.fieldValue.valueContent = Number(
                                       sensor.newValue,
                                    );
                                 }

                                 this.manageAsset.setFieldValue(
                                    this.fieldValue,
                                    this.asset,
                                    0,
                                 );
                                 this.propagateSensorData();
                              });
                        }
                     });
                  }
               });
            }
         } else if (answer.data.error === "missingToken") {
            this.introToSensors();
         } else if (answer.data.error === "badToken validation") {
            this.updateImonnitToken();
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   attachSensor = async (sensor) => {
      assert(this.fieldValue);
      return this.manageImonnit
         .attachSensorToValueID(
            sensor.selectedSensor,
            sensor.selectedPoint,
            this.fieldValue.valueID,
            sensor.newLabels,
         )
         .then((answer) => {
            if (!answer.data.success) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   applyConfiguration = async (config) => {
      assert(this.fieldValue);
      return this.manageImonnit
         .applyConfig(this.fieldValue.valueID, config)
         .then((answer) => {
            if (!answer.data.success) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   selectSensor = () => {
      if (this.sensorAttached) {
         this.viewSensorInfo();
         return Promise.resolve();
      }

      const instance: any = this.modalService.open(AttachSensorToAsset);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.sensorAttached
               ? this.lang().DetachSensor
               : this.lang().AttachAMonnitSensor,
            asset: this.asset,
            sensorAttached: this.sensorAttached,
         },
      };

      return instance;
   };

   introToSensors = () => {
      //Open a modal to explain sensors
      const instance = this.modalService.open(IntroToSensors);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().SettingUpMonnitSensors,
         },
      };
   };

   updateImonnitToken = () => {
      //Open a modal to explain sensors
      const instance = this.modalService.open(UpdateImonnitToken);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().SettingUpMonnitSensors,
         },
      };

      //grabs the imonnit u/p so we can use those to update the token
      instance.result.then((result) => {
         if (result != 0) {
            this.attachOrConfigureSensor(result.username, result.password);
         }
      });
   };

   viewSensorInfo = () => {
      const instance = this.modalService.open(ViewSensorInfo);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().SensorInformation,
            relationship: this.relationship,
         },
      };

      return instance;
   };

   configure = (sensorID, previousConfigRules = null) => {
      const instance = this.modalService.open(ConfigureSensorInterface);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().WhatDoYouWantTheSensorToDo,
            sensorID: sensorID,
            field: this.fieldValue,
            previousConfig: this.sensorConfig,
            previousConfigRules: previousConfigRules ? previousConfigRules : false,
         },
      };

      return instance;
   };

   setDepreciationSchedule = () => {
      if (!this.featureCapitalDepreciation || this.isFieldLinkedToTemplate) {
         return;
      }
      assert(this.asset);
      const instance = this.modalService.open(AssetCapitalDepreciation);
      const depreciationSchedule = this.manageAsset.getDepreciationSchedule(
         this.asset.assetDepreciationID ?? -1,
      );

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AssetCapitalDepreciationScheduleMessage,
            title: this.lang().AssetCapitalDepreciationSchedule,
            data: {
               assetID: this.asset.assetID,
               depreciationSchedule,
               fieldValue: this.fieldValue,
               currencyCode: this.currencyCode,
            },
         },
      };
   };

   protected getFileUploadUrl(fileName: string | null): string {
      if (!this.asset || !this.fieldValue || !fileName?.length) return "";

      return `viewFile.php?f=upload-${this.currentUser?.userInfo?.customerID}/assets/${this.asset?.locationID}/${this.asset?.assetID}/${this.fieldValue?.valueID}/${fileName}`;
   }

   private readonly updateDepreciationButtonDisplay = () => {
      assert(this.fieldValue);
      assert(this.fieldInfo);
      const depreciationSchedule = this.asset?.assetDepreciationID
         ? this.manageAsset.getDepreciationSchedule(this.asset?.assetDepreciationID)
         : null;
      this.displayAssetDepreciationButton =
         this.fieldInfo.fieldTypeID == AssetFieldTypeID.Currency &&
         this.creds.changeAssetFieldsValues &&
         (!depreciationSchedule?.scheduleActive ||
            Number(depreciationSchedule?.valueID) === Number(this.fieldValue.valueID));
   };

   private getAssetCreds(): void {
      assert(this.asset);
      this.creds.configureAssetFields = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ConfigureAssetInformationFields,
      );
      this.creds.changeAssetFieldsValues = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ChangeAssetInformationValues,
      );
   }

   /**
    * This is abstracted in a getter in case we decide
    * to add logic to choose which tooltip is displayed depending
    * on if the field is linked to a template or not.
    */
   public get globalFieldToolip(): string {
      return this.lang().AssetTemplateFieldMessage;
   }
}
