<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />

   <lim-ui-modal-body (click)="errorMsg = ''; errorStart = false; errorDue = false">
      @if (message) {
         <lim-ui-info-panel>
            <span class="message" [limbleHtml]="message"></span>
         </lim-ui-info-panel>
      }

      @if (startDate?.show == true) {
         <lim-ui-panel>
            <div class="date-card-label">
               {{ lang().StartDate }}
            </div>
            <div class="date-group">
               <lim-ui-date-picker-input
                  [model]="startDate.dateTime"
                  (modelChange)="startDate.dateTime = $event"
               />
               @if (startDate.viewTimeOfDay) {
                  <div (click)="startDate.timeOfDay = true">
                     <time-of-day-picker
                        [tod]="startDate.timeOfDay"
                        (todChange)="startDate.timeOfDay = $event"
                        [hour]="startDate.hour"
                        (hourChange)="startDate.hour = $event"
                        [minute]="startDate.minute"
                        (minuteChange)="startDate.minute = $event"
                        [ampm]="startDate.AMPM"
                        (ampmChange)="startDate.AMPM = $event"
                     />
                  </div>
               }
            </div>
         </lim-ui-panel>
      }
      <lim-ui-panel>
         <div class="date-card-label">
            {{ endDateString }}
         </div>
         <div class="date-group">
            <lim-ui-date-picker-input
               [model]="dateTime"
               (modelChange)="dateTime = $event"
            />
            @if (viewTimeOfDay) {
               <div (click)="timeOfDay = true">
                  <time-of-day-picker
                     [tod]="timeOfDay"
                     (todChange)="timeOfDay = $event"
                     [hour]="hour"
                     (hourChange)="hour = $event"
                     [minute]="minute"
                     (minuteChange)="minute = $event"
                     [ampm]="AMPM"
                     (ampmChange)="AMPM = $event"
                  />
               </div>
            }
         </div>
      </lim-ui-panel>

      @if (errorMsg !== "") {
         <lim-ui-alert alertType="danger" [title]="lang().Warning">
            <span [limbleHtml]="errorMsg"></span>
         </lim-ui-alert>
      }
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      <div class="modal-footer-content">
         @if (startDate) {
            <lim-ui-secondary-button (click)="toggleStartDateTime()">
               @if (!startDate?.show) {
                  <span>{{ lang().AddStartDate }}</span>
               }
               @if (startDate?.show) {
                  <span>{{ lang().NoStartDate }}</span>
               }
            </lim-ui-secondary-button>
         }
         <div class="right-footer-buttons">
            <lim-ui-primary-button
               (click)="submit()"
               [dataLogLabel]="lookupDataLogSubmitLabel()"
            >
               {{ buttonText }}
            </lim-ui-primary-button>
         </div>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
