<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <span>{{ lang().ToImportWOTemplatesPleaseFollowTheseSteps }}</span>
         <ol>
            <li>
               {{ lang().Watch }}
               <a
                  class="emphasized-strings"
                  href="https://youtu.be/kOYN0CmwLFo"
                  target="_blank"
               >
                  {{ lang().HowToImportWOs }}</a
               >
               {{ lang().OrRead }}
               <a
                  class="emphasized-strings"
                  href="https://help.limblecmms.com/en/articles/8194672"
                  target="_blank"
                  >{{ lang().HowToImportWOs }}</a
               >
            </li>
            <li>
               {{ lang().DownloadThe }}
               <a
                  class="emphasized-strings"
                  href="assets/downloads/tasks/sampleWOImport.xlsx"
                  download="Sample WO Templates Import.xlsx"
                  >{{ lang().SampleImportFile }}</a
               >
            </li>
            <li>
               <b
                  >{{ lang().DoNotRemoveThe }}
                  <span class="red">{{ lang().RequiredFields }} </span>
                  {{ lang().InRow1 }}</b
               >
               <div class="list-spacing">
                  <ul>
                     <li>
                        <span class="red">
                           {{ lang().WOName }}
                        </span>
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           iconSize="small"
                           limUiTooltip="{{ lang().TheWONameColumnIsRequiredTooltip }}"
                        />
                     </li>
                     <li>
                        <span class="red">{{ lang().Instruction1 }} </span>
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           iconSize="small"
                           limUiTooltip="{{ lang().Instruction1WOColumnTooltip }}"
                        />
                     </li>
                  </ul>
               </div>
            </li>
            <li>
               <b>{{ lang().FormatTheSpreadSheet }}</b>
               <div class="list-spacing">
                  <ul class="optionals">
                     <li>
                        {{ lang().ClearOutTheSampleDataInRows }}
                     </li>
                     <li>
                        <span
                           >{{
                              lang().FillInTheSpreadsheetWithYourDataYouCanUploadAMax
                           }}
                           100 {{ lang().WOTemplatesLowerCase }}
                           {{ lang().perImport }}</span
                        >
                     </li>
                  </ul>
               </div>
            </li>
            <li>
               <b>{{ lang().SaveAndUploadTheFile }}.</b>
            </li>
            <li>
               <b>{{ lang().ReviewYourResults }}</b>
            </li>
         </ol>
         <br [hidden]="!errorMsg" />
         <div
            class="alert alert-dismissable alert-danger"
            [hidden]="!errorMsg"
            style="margin-bottom: 0px"
         >
            <strong class="mr-2"
               >{{ lang().Warning }}! - {{ lang().NoWOTemplatesWereImported }}
            </strong>
            <br />
            <div [limbleHtml]="errorMsg"></div>
         </div>

         <span [hidden]="!importAttempt" class="importAttempt">
            <hr [hidden]="!(failedTemplates != null || addedTemplates != null)" />
            @for (task of failedTemplates; track task) {
               <div class="lim-ui-list-item">
                  <lim-ui-icon icon="xmark" iconStyle="solid" iconColor="danger" />
                  <b>{{ task.woName }}</b> -
                  <b class="danger-text"
                     >{{ lang().InvalidDataOnRow }} {{ task.count + 2 }}</b
                  >
                  <br />
                  @for (reason of task.failedReasons; track reason) {
                     <div>
                        {{ reason }}
                     </div>
                  }
               </div>
            }

            <hr [hidden]="!(failedTemplates != null && addedTemplates != null)" />

            @for (wo of addedTemplates; track wo) {
               <div
                  (click)="popWO(wo)"
                  class="lim-ui-list-item lim-ui-selectable-list-item lim-ui-selected-list-item"
               >
                  <div class="lim-ui-list-item-content">
                     <span class="cursor" (click)="popWO(wo)">
                        <lim-ui-icon icon="check" iconStyle="solid" iconColor="success" />
                        <b> {{ wo.woName }}</b> -
                        <b class="green-color">{{ lang().SuccessfulImport }}</b>
                     </span>

                     <div>
                        <lim-ui-icon
                           icon="xmark"
                           iconStyle="solid"
                           iconColor="danger"
                           (click)="deleteWO(wo); $event.stopPropagation()"
                           class="icon-spacing"
                           limUiTooltip="{{ lang().DeleteThisNewlyImportedWO }}"
                        />

                        @if (wo.assetID > 0) {
                           <lim-ui-icon
                              icon="cube"
                              iconStyle="solid"
                              (click)="popAsset(wo); $event.stopPropagation()"
                              class="icon-spacing"
                              limUiTooltip="{{
                                 lang().TakeALookAtTheAssetThisPMBelongsTo
                              }}"
                           />
                        }

                        <lim-ui-icon
                           icon="wpforms"
                           limUiTooltip="{{ lang().TakeALookAtTheNewlyImportedPM }}"
                        />
                     </div>
                  </div>
               </div>
            }
         </span>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (uploadObject) {
         <file-uploader
            class="import-uploader"
            buttonType="primary"
            [uploadObject]="uploadObject"
            label="{{ lang().UploadFile }}"
            style="margin-right: 5px"
            accept="import"
         />
      }
   </lim-ui-modal-footer>
</lim-ui-modal>
