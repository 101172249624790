import { inject, Injectable } from "@angular/core";
import { PermissionID } from "src/app/users/schemata/users/self/credentials/permission.enum";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

export type EditableTaskData = {
   checklistTemplate?: number | undefined;
   checklistTemplateOld?: number | undefined;
   locationID: number;
   checklistStatusID?: number | undefined;
   createdByUserID?: number | undefined;
   userID?: number | undefined;
   profileID?: number | undefined;
};

export type TaskEditableResult = {
   editable: boolean;
   mode: "instance" | "template";
   showChangeTaskType?: boolean;
   allowEditTemplateInstructions?: boolean;
};

@Injectable({
   providedIn: "root",
})
export class TaskEditableService {
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);

   private static readonly CUSTOMER_IDS_THAT_CANNOT_EDIT_TEMPLATE_INSTRUCTIONS = [
      1175, 2591,
   ];

   public getAllowEditTemplateInstructions(
      task: EditableTaskData,
      customerID: number,
      currentAllowEditTemplateInstructions: boolean,
      tempEdit: boolean,
      preview: boolean | undefined,
   ): boolean {
      const currentUser = this.manageUser.getCurrentUser();
      if (
         tempEdit ||
         currentUser.workOrderUser === 1 ||
         preview ||
         task.checklistTemplate === 0
      ) {
         return currentAllowEditTemplateInstructions;
      }

      if (
         TaskEditableService.CUSTOMER_IDS_THAT_CANNOT_EDIT_TEMPLATE_INSTRUCTIONS.includes(
            customerID,
         )
      ) {
         return false;
      }

      if (this.isWorkOrder(task)) {
         return this.credService.isAuthorized(
            task.locationID,
            PermissionID.EditAnOpenWOsInstructions,
         );
      }

      return true;
   }

   private isWorkOrder(task: EditableTaskData) {
      return task.checklistTemplate === 2 && task.checklistTemplateOld === 2;
   }

   public getShowChangeTaskType(
      task: EditableTaskData,
      preview: boolean | undefined,
      currentShowChangeTaskType: boolean,
      tempEdit: boolean,
   ): boolean {
      const currentUser = this.manageUser.getCurrentUser();
      if (tempEdit || currentUser.workOrderUser === 1) {
         return currentShowChangeTaskType;
      }

      return (
         !(task.checklistTemplate === 2 || task.checklistTemplate === 3) &&
         preview === true
      );
   }

   public getEditableMode(
      task: EditableTaskData,
      tempEdit: boolean,
      preview: boolean | undefined,
      currentMode: "instance" | "template",
   ): "instance" | "template" {
      const currentUser = this.manageUser.getCurrentUser();
      if (tempEdit || currentUser.workOrderUser === 1) {
         return currentMode;
      }

      if (preview) {
         return "instance";
      }

      if (task.checklistTemplate === 0) {
         return currentMode;
      }

      if (task.checklistTemplate === 1) {
         if (
            this.credService.isAuthorized(task.locationID, PermissionID.ChangePMDetails)
         ) {
            return currentMode;
         }

         return "instance";
      }

      return currentMode;
   }

   public isEditable(
      task: EditableTaskData,
      tempEdit: boolean,
      externalUserOverrideActive: boolean,
      editable: boolean | undefined,
      preview: boolean | undefined,
   ): boolean {
      if (tempEdit) {
         return true;
      }

      const currentUser = this.manageUser.getCurrentUser();
      if (currentUser.workOrderUser === 1) {
         return !externalUserOverrideActive;
      }

      if (preview) {
         return false;
      }

      if (task.checklistTemplate === 0) {
         if (editable === false || Number(task.checklistStatusID) > 0) {
            return false;
         }

         const belongsToMeOrStartedByMe = this.belongsToMeOrStartedByMe(task);
         if (belongsToMeOrStartedByMe) {
            return true;
         }

         return this.credService.isAuthorized(
            task.locationID,
            PermissionID.EditAndCompleteOpenTasks,
         );
      }

      if (task.checklistTemplate === 1) {
         return this.credService.isAuthorized(
            task.locationID,
            PermissionID.ChangePMDetails,
         );
      }

      return true;
   }

   private belongsToMeOrStartedByMe(task: EditableTaskData) {
      const { belongsToMe, startedByMe } = this.getWhoStartedTheTaskBooleans(task);
      if (
         startedByMe &&
         this.credService.isAuthorized(
            task.locationID,
            PermissionID.EditAndCompleteMyOpenTasks,
         )
      ) {
         return true;
      }

      if (
         belongsToMe &&
         this.credService.isAuthorized(
            task.locationID,
            PermissionID.EditAndCompleteMyOpenTasks,
         )
      ) {
         return true;
      }

      return false;
   }

   private getWhoStartedTheTaskBooleans(task: EditableTaskData) {
      const currentUser = this.manageUser.getCurrentUser();
      const profiles = currentUser.profileArr;
      const userID = currentUser.gUserID;
      let belongsToMe = false;
      let startedByMe = false;
      const startedByUser = task.createdByUserID;
      if (startedByUser === userID) {
         startedByMe = true;
      }

      if (userID === task.userID || profiles.indexOf(task.profileID) > -1) {
         belongsToMe = true;
      }

      return {
         belongsToMe,
         startedByMe,
      };
   }
}
