<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="lang().EditVendorLog" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="content-wrapper">
            <div class="col-md-3">
               @if (log.logStatus == 1 || log.logStatus == 2) {
                  <lim-ui-icon
                     icon="circleCheck"
                     iconSize="small"
                     iconColor="success"
                     limUiTooltip="{{ lang().LogIsInGoodStatus }}"
                     placement="top"
                     class="icon-styling"
                  />
               }
               @if (log.logStatus == 3 || log.logStatus == 4) {
                  <lim-ui-icon
                     icon="circleCheck"
                     iconSize="small"
                     iconColor="warn"
                     limUiTooltip="{{ lang().LogIsInWarningStatus }}"
                     placement="top"
                     class="icon-styling"
                  />
               }
               @if (log.logStatus == 5 || log.logStatus == 6) {
                  <lim-ui-icon
                     icon="circleCheck"
                     iconSize="small"
                     iconColor="danger"
                     limUiTooltip="{{ lang().LogIsInCriticalStatus }}"
                     placement="top"
                     class="icon-styling"
                  />
               }
               <span class="set-date-link">
                  <a class="cursor" (click)="changeDate(log)">{{
                     log.logDate | betterDate: "dateTime"
                  }}</a>
               </span>
            </div>

            <div class="col-md-9">
               <div>
                  <textarea
                     class="form-control log-input-field"
                     [(ngModel)]="log.logDetails"
                     (blur)="changeLogDetails(log)"
                  ></textarea>

                  <div class="bottom-buttons-row">
                     <div class="bottom-buttons-row-left">
                        <comment-files
                           [uploadObject]="logUploadObj"
                           [documents]="log.logFiles"
                           [images]="log.logPictures"
                           icon="plus"
                           label="AttachFile"
                        />
                     </div>
                  </div>

                  <div
                     class="alert alert-warning"
                     style="margin-top: 10px"
                     [hidden]="!errorMsg"
                  >
                     <strong class="mr-1">{{ lang().Warning }}</strong> --
                     <span [limbleHtml]="errorMsg"></span>
                  </div>
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
