<p-multiSelect
   variant="outlined"
   [fluid]="true"
   [ariaLabel]="ariaLabel()"
   [placeholder]="placeholder()"
   [id]="id()"
   [inputId]="inputId()"
   [disabled]="disabled()"
   [readonly]="readonly()"
   [filterPlaceHolder]="filterPlaceholder()"
   [filter]="filters()"
   [tabindex]="tabIndex()"
   [ariaLabelledBy]="ariaLabeledBy()"
   [name]="name()"
   [display]="display()"
   [showToggleAll]="showToggleAll()"
   [emptyMessage]="emptyMessage()"
   [emptyFilterMessage]="emptyFilterMessage()"
   [showHeader]="showHeader()"
   [ariaFilterLabel]="ariaFilterLabel()"
   [filterMatchMode]="filterMatchMode()"
   [autocomplete]="autocomplete()"
   [showClear]="showClear()"
   [autofocus]="autofocus()"
   [options]="options()"
   [optionLabel]="optionLabel()"
   [maxSelectedLabels]="maxSelectedLabels()"
   [appendTo]="appendTo()"
   [optionDisabled]="optionDisabled()"
   [(ngModel)]="selectedOptions"
   (onChange)="onChange($event)"
   (onFilter)="onFilter($event)"
   (onFocus)="onFocus($event)"
   (onBlur)="onBlur($event)"
   (onClick)="onClick($event)"
   (onClear)="onClear($event)"
   (onPanelShow)="onPanelShow()"
   (onPanelHide)="onPanelHide()"
   (onRemove)="onRemove($event)"
   (onSelectAllChange)="onSelectAllChange($event)"
/>
