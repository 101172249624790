<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body [scrollable]="true">
      @if (message && message?.length > 0) {
         <lim-ui-info-panel>
            <span [limbleHtml]="message"></span>
         </lim-ui-info-panel>
      }

      <lim-ui-panel>
         <pick-locations
            class="pick-locations"
            [(data)]="pickLocData"
            [(anyLocation)]="anyLocation"
         />
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="buttonText"
      (successClick)="submit()"
      dataLogSubmitLabel="task-submitChangeLocation"
   />
</lim-ui-modal>
