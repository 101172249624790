import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { Component, computed, inject, input, signal, ViewChild } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   IconButtonComponent,
   IconComponent,
   InfoPanelComponent,
   isNativeMobileApp,
   LimbleHtmlDirective,
   LimUiModalRef,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   ModalService,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   SearchAllWrapperComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   TextButtonComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import {
   catchError,
   finalize,
   type Observable,
   of,
   type Subscription,
   switchMap,
   tap,
} from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { generatePartFields } from "src/app/parts/components/add-part-modal/part-field-utils";
import { PartsFilterSelector } from "src/app/parts/components/partsFilterSelector/partsFilterSelector.component";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import type { PartEntity } from "src/app/parts/components/shared/parts-api-service/parts-api.models";
import type { AddPartModalData } from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { PartsDataAccumulatorService } from "src/app/parts/components/shared/services/associated-parts-data-accumulator/associated-parts-data-accumulator.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { PartField } from "src/app/parts/types/field/field.types";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { LocationHierarchyService } from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy.service";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { QrCodeService } from "src/app/shared/components/global/qrCodeButton/qr-code.service";
import { QRCodeButton } from "src/app/shared/components/global/qrCodeButton/qrCodeButton.component";
import {
   type DataViewerFilter,
   FilterLabelKey,
   DataViewerFilterType,
} from "src/app/shared/data-viewer/data-viewer-filters/data-viewer-filter.models";
import { DataViewerSearchComponent } from "src/app/shared/data-viewer/data-viewer-search/data-viewer-search.component";
import { DataViewerStateService } from "src/app/shared/data-viewer/data-viewer-state.service";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type {
   Filter,
   HierarchyNode,
   HierarchyOptions,
   SearchFields,
} from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import {
   TaskTemplatesApiService,
   type TaskTemplateEntity,
} from "src/app/tasks/components/shared/services/task-templates-api";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { CredService } from "src/app/users/services/creds/cred.service";

type PartHierarchyNode = HierarchyNode & {
   partID: number;
};

type TaskInfo = {
   taskID: number;
   taskTemplate: boolean;
};

@Component({
   selector: "add-part-modal",
   templateUrl: "./add-part-modal.component.html",
   styleUrls: ["./add-part-modal.component.scss"],
   providers: [DataViewerStateService, PartsDataAccumulatorService],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      SearchAllWrapperComponent,
      QRCodeButton,
      SecondaryButtonComponent,
      IconButtonComponent,
      PartsFilterSelector,
      SelectionControlsComponent,
      HierarchyContainerLegacy,
      IconComponent,
      TooltipDirective,
      NgClass,
      ContenteditableDirective,
      FormsModule,
      FormDropdownInputComponent,
      DropdownTextItemComponent,
      PrimaryButtonComponent,
      ModalFooterComponent,
      PopoverDirective,
      UpsellPopover,
      DataViewerSearchComponent,
      TextButtonComponent,
      LoadingAnimationComponent,
   ],
})
export class AddPartModalComponent implements OnInit, OnDestroy {
   @ViewChild("hierarchy") private readonly hierarchy?: HierarchyContainerLegacy;

   public modalData = input.required<AddPartModalData>();

   public readonly modalRef: LimUiModalRef<AddPartModalComponent, any> =
      inject(LimUiModalRef);

   public selectOne: boolean = false;
   private singleLocation: number = 0; //value of 0 means show all locations

   public message?: string;
   public items: Array<any> = [];
   public viewAll: boolean = true;
   public viewAssociated: boolean = false;
   public createPartShow: boolean = false;

   protected isNativeMobileApp;
   public selectedLocation;
   public parts: Lookup<"partID", Part> = new Lookup("partID");
   public locations;
   public treeData: Array<PartHierarchyNode> = [];
   public loadCount: number = 0;
   public loadedHier: boolean = false;
   protected searchBar: string = "";
   public allPartsLength: number = 0;
   public creatingPart: boolean = false;
   public newLocations: Array<any> = [];
   public createPartCred;
   public newPartLocationID;
   public newPartLocationName;
   public newPartNumber;
   public noSearchResults;
   public partsLength;
   public newPartName;
   public createPartDisabled;
   public newPartPrice;
   public newPartQty;
   public timeoutSearchBar?: NodeJS.Timeout;
   public hideCreatePart;

   public searchFields: SearchFields = {};
   public partFilters: Array<Filter<PartField>> = [];
   public partFields: Lookup<"fieldID", PartField> = new Lookup("fieldID");
   private partFieldsSub: Subscription | null = null;
   public partNodes: LimbleMap<number, PartHierarchyNode> = new LimbleMap();
   public partsSearchHints: Map<number, string> = new Map();
   public associatedPartNodes: Map<number, { selected: boolean }> = new Map();
   public locationsIndex;
   public optionsJsonMappedToFieldID: Map<number, any> = new Map();
   public hierarchyOptions: HierarchyOptions;
   private readonly manageFeatureFlagsSub: Subscription;
   protected canAddParts: boolean = false;
   private previousFilter: DataViewerFilter | undefined;

   private readonly modalService = inject(ModalService);
   public readonly manageParts = inject(ManageParts);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly betterDate = inject(BetterDate);
   private readonly managePO = inject(ManagePO);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly qrCodeService = inject(QrCodeService);
   private readonly locationHierarchyService = inject(LocationHierarchyService);
   private readonly manageLang = inject(ManageLang);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);

   private readonly tasksApiService = inject(TasksApiService);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly associatedPartsDataAccumulator = inject(PartsDataAccumulatorService);

   protected readonly associatedPartsSignal =
      this.associatedPartsDataAccumulator.dataSignal;
   protected readonly selectedItems = this.associatedPartsDataAccumulator.selectedItems;
   protected readonly total = this.associatedPartsDataAccumulator.total;

   protected readonly associatedPartsLoading = computed(() => {
      return this.associatedPartsDataAccumulator.isLoading() || this.taskLoading();
   });

   readonly #state = inject(DataViewerStateService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly isUnitOfMeasureEnabled = this.unitOfMeasureService.isFeatureEnabled;

   private isSingleClick = false;

   private readonly taskLoading = signal<boolean>(false);
   private readonly modalData$ = toObservable(this.modalData);
   private readonly taskRequest$: Observable<
      Partial<TaskEntity | TaskTemplateEntity | undefined>
   > = this.modalData$.pipe(
      switchMap((modalData) => {
         if (!modalData.taskID) {
            return of(undefined);
         }
         return of({
            taskID: modalData.taskID,
            taskTemplate: modalData.taskTemplate ?? false,
         } satisfies TaskInfo);
      }),
      tap(() => {
         this.taskLoading.set(true);
      }),
      switchMap((taskInfo) => {
         if (!taskInfo?.taskID) {
            return of(undefined);
         }
         if (taskInfo.taskTemplate) {
            return this.taskTemplatesApiService
               .getById(taskInfo.taskID, {
                  filters: { allowDeleted: true },
               })
               .pipe(
                  finalize(() => {
                     this.taskLoading.set(false);
                  }),
               );
         }

         return this.tasksApiService.getById(taskInfo.taskID).pipe(
            finalize(() => {
               this.taskLoading.set(false);
            }),
         );
      }),
      catchError((error) => {
         console.error("Error fetching tasks:", error);
         return of(undefined);
      }),
   );

   protected readonly taskSignal = toSignal(this.taskRequest$);

   protected readonly taskExistsAndHasAsset = computed<boolean>(() => {
      const task = this.taskSignal();
      if (!task) {
         return false;
      }
      return (
         task?.assetID !== undefined &&
         task.assetID !== null &&
         task.assetID > 0 &&
         this.allPartsLength > 0
      );
   });

   protected readonly locationID = computed<number | null>(() => {
      return this.taskSignal()?.locationID ?? this.modalData().locationID ?? null;
   });

   public constructor() {
      this.hierarchyOptions = {
         idKey: "partID",
         nodeButtons: [
            {
               tooltip: this.lang().OpenThisPart,
               clickFunction: this.runPopPart.bind(this),
               permissionNumber: this.credService.Permissions.ViewLookupAPart,
               text: this.lang().View,
            },
         ],
         submit: this.submit.bind(this),
      };

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(() => {
         this.canAddParts = this.manageFeatureFlags.canAddParts();
      });

      this.taskRequest$.subscribe((task) => {
         const assetID = task?.assetID;
         if (assetID) {
            this.#state.setBaseFilters([
               {
                  assetIDs: [assetID],
               },
            ]);
         }
      });
   }

   protected updateSearchBar(searchVal: string): void {
      this.searchBar = searchVal;
      this.#state.setSearch(searchVal);
      this.updateSearch();
   }

   protected showMore() {
      this.associatedPartsDataAccumulator.fetch();
   }

   public selectAssociatedPart(partEntity: PartEntity): void {
      this.isSingleClick = true;
      setTimeout(() => {
         if (!this.isSingleClick) {
            return;
         }
         if (this.associatedPartsDataAccumulator.isItemSelected(partEntity)) {
            if (this.selectOne === true) {
               this.submit();
            }
         }
         this.associatedPartsDataAccumulator.toggleSelectItem(partEntity);
      }, 200);
   }

   protected handleDoubleClickPart(partEntity: PartEntity): void {
      this.isSingleClick = false;
      if (this.selectOne === false) {
         if (this.associatedPartsDataAccumulator.isItemSelected(partEntity)) {
            this.submit();
         }
      }
   }

   protected initializeComponent() {
      this.selectOne = this.modalData().selectOne ?? false;
      this.associatedPartsDataAccumulator.setSingleSelection(this.selectOne);
      this.singleLocation = this.modalData().singleLocation ?? 0; //value of 0 means show all locations
      this.locationsIndex = this.manageLocation.getLocationsIndex();

      this.hierarchyOptions.selection = { singleSelection: Boolean(this.selectOne) };

      this.partFieldsSub = this.manageObservables.setSubscription("partFields", () => {
         this.partFields = this.manageParts.getFields();
         this.optionsJsonMappedToFieldID = new Map();
         for (const partField of this.partFields) {
            let optionsJSON: Array<any> = [];
            if (typeof partField.optionsJSON === "string") {
               try {
                  optionsJSON = JSON.parse(partField.optionsJSON);
               } catch {
                  optionsJSON = [];
               }
            }
            this.optionsJsonMappedToFieldID.set(partField.fieldID, optionsJSON);
         }
         if (!this.singleLocation || this.singleLocation === 0) {
            return;
         }
         this.partFields = this.partFields.filter(
            (field) => field.locationID === this.singleLocation,
         );
      });

      //IMPORTANT QR codes only work in our native mobile app currently
      this.isNativeMobileApp = isNativeMobileApp();

      this.selectedLocation = false;

      const allParts = this.manageParts
         .getParts()
         .filter((part) => part.partDeleted === 0);
      this.allPartsLength = allParts.size;

      this.resetPartsSelected();

      this.newLocations = [];
      this.createPartCred = false;
      this.locations = this.manageLocation.getLocations();

      for (const location of this.locations) {
         if (
            this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.AddParts,
            ) ||
            this.credService.checkCredGlobal(this.credService.Permissions.ManageRoles)
         ) {
            //if they can make add a part at that location or if they are a super user
            this.newLocations.push(location);
            this.createPartCred = true;
         }
      }
      this.buildView();
   }

   public ngOnInit(): void {
      this.initializeComponent();
   }

   protected scanQrCallback(code: string): void {
      const part = this.parsePartFromCode(code);
      if (part === undefined) {
         this.qrCodeService.alertCannotMatchScannedCode(code);
      } else {
         this.modalRef.close([part]);
      }
   }

   private parsePartFromCode(code: string): Part | undefined {
      const route = this.qrCodeService.parseUrlString(code);
      if (route === undefined) {
         return this.qrCodeService.tryGetPartFromCode(code);
      }
      if (route[0] === "mobilePart") {
         return this.manageParts.getPart(Number(route[1]));
      }
      return undefined;
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeSubscription(this.partFieldsSub);
      this.manageFeatureFlagsSub.unsubscribe();
   }

   public updateSearch(): void {
      if (this.timeoutSearchBar) {
         clearTimeout(this.timeoutSearchBar);
      }
      this.timeoutSearchBar = setTimeout(() => {
         this.buildView();
      }, 250);
   }

   public setNewPartLocation(location): void {
      this.newPartLocationID = location.locationID;
      this.newPartLocationName = location.locationName;
   }

   public resetPartsSelected(): void {
      for (const part of this.parts) {
         const partNode = this.getPartNode(part.partID);
         if (!partNode) continue;
         if (partNode.selected) {
            partNode.selected = false;
         }
      }
   }

   protected filterPartsHandler(): void {
      // TODO: Eventually the filter component should be converted to a data viewer component, where it creates the filter there.
      const partFieldsJSON = generatePartFields(this.partFilters, this.searchFields);
      const partFieldsFilter = {
         type: DataViewerFilterType.PART_FIELD,
         labelKey: FilterLabelKey.EMPTY,
         key: "partFields",
         value: { partFields: JSON.stringify(partFieldsJSON) },
      } satisfies DataViewerFilter;
      if (this.previousFilter) {
         this.#state.removeFilter(this.previousFilter);
      }
      this.previousFilter = partFieldsFilter;
      this.#state.addFilter(partFieldsFilter);
      this.buildView();
   }

   //on inital load we gotta clean up selected and make sure nothing is selected... we don't do this on buildView so that the search works properly without removing what they have picked before

   public async buildView(): Promise<void> {
      let regions;
      let regionsIndex = {};

      this.partsSearchHints = new Map();

      const locationIDs: any = [];
      if (this.singleLocation) {
         locationIDs.push(this.singleLocation);
      } else {
         for (const location of this.locations) {
            location.childrenBuilt = false;
            locationIDs.push(location.locationID);
         }
      }

      this.parts = this.manageParts
         .getParts()
         .filter(
            (part) => part.partDeleted === 0 && locationIDs.includes(part.locationID),
         )
         .orderBy("partName");
      if (!this.partNodes.size) {
         this.partNodes = new LimbleMap(
            [...this.parts.values()].map((part) => [
               part.partID,
               {
                  partID: part.partID,
                  icon: "gearsRegular",
                  collapsed: true,
                  selected: false,
                  nodes: [],
                  title: part.partName ?? "",
                  displayButtons: true,
                  locationID: part.locationID,
               },
            ]),
         );
      }

      this.locations = [];
      if (this.singleLocation > 0) {
         const locationsIndex = this.manageLocation.getLocationsIndex();
         if (locationsIndex[this.singleLocation] === undefined) {
            return; //error catch if the location they are trying to look at doesn't exist
         }
         this.locations.push(locationsIndex[this.singleLocation]);
      } else {
         this.locations = this.manageLocation.getLocations();
      }

      if (this.manageLocation.getRegions().length > 0) {
         //they are using regions so we have to behave a little differently
         const rst = this.locationHierarchyService.buildHierarchy({
            locations: this.locations,
            regions: this.manageLocation.getRegions(),
            search: this.searchBar,
            filterRegions: false,
            filterLocations: false,
            alwaysReturnRegions: false,
            totalLocationCount: this.manageLocation.getLocations().length,
         });
         regions = rst.regionTree;
         regionsIndex = rst.regionsIndex;
      }

      if (this.searchBar !== undefined) {
         this.parts = this.manageFilters.filterPartsToNameAndTextFields(
            this.parts,
            this.manageParts.getFields(),
            this.manageParts.getFieldValues(),
            this.manageParts.getFieldValueFiles(),
            this.partsSearchHints,
            {
               search: this.searchBar,
               hier: true,
            },
            this.betterDate,
            this.manageParts,
            this.managePO,
            this.manageAssociations,
            this.partNodes,
         );
      }

      const preFilterPartsLength = this.parts.size;
      this.parts = this.manageFilters.partFields(
         this.parts,
         this.manageParts.getFieldValues(),
         this.manageParts.getFields(),
         this.manageParts.getFieldValueFiles(),
         this.manageParts.getCategories(),
         this.searchFields,
      );

      const hasFilteredParts = preFilterPartsLength !== this.parts.size;

      if (this.locationID() === null || this.locationID() === undefined) {
         this.selectedLocation = this.locations[0];
      } else {
         for (const location of this.locations) {
            if (location.locationID === this.locationID()) {
               this.selectedLocation = location;
            }
         }
      }

      this.setNewPartLocation(
         this.manageLocation.getLocationsIndex()[this.selectedLocation.locationID],
      );

      const locationIndex = {};
      for (const location of this.locations) {
         location.anyPartSelected = false;
         locationIndex[location.locationID] = location;
      }

      if (!this.parts.size) {
         this.noSearchResults = false;
      }

      this.treeData = [];

      for (const key in this.locations) {
         if (this.locations.length <= 1) {
            break;
         }
         const location = this.locations[key];
         location.nodes = [];
         location.title = location.locationName;
         location.icon = "houseChimney";
         location.unselectable = true;

         if (
            (this.searchBar !== undefined && this.searchBar.length > 1) ||
            location.anyPartSelected === true
         ) {
            location.collapsed = false;
         } else if (
            this.selectedLocation.locationID === location.locationID &&
            this.allPartsLength < 400
         ) {
            location.collapsed = false;
         } else {
            location.collapsed = true;
         }
      }

      await this.unitOfMeasureService.areUnitsInitialized;
      for (const partNode of this.partNodes) {
         if (!partNode.partID) {
            continue;
         }
         const part = this.parts.get(partNode.partID);
         if (!part) {
            continue;
         }

         if (!this.searchBar.length) {
            this.partsSearchHints.set(part.partID, "");
         }
         if (
            (partNode.selected !== undefined && partNode.selected === true) ||
            hasFilteredParts
         ) {
            //we also need to set if any of the parts are selected because those locations shouldn't be collapsed

            const location = locationIndex[part.locationID];
            location.collapsed = false;
         }
         const searchHint = this.partsSearchHints.get(part.partID);
         partNode.searchFound = Boolean(searchHint?.length);
         partNode.searchHint = searchHint ?? "";

         const calculatedPartData = this.manageParts.getSingleCalculatedPartInfo(
            part.partID,
         );

         partNode.title = part.partName ?? "";

         if (
            part.partNumber !== undefined &&
            part.partNumber !== null &&
            part.partNumber.length > 0
         ) {
            partNode.title += ` - ${part.partNumber}`;
         }

         partNode.title += ` - ${this.lang().Qty}: ${
            calculatedPartData?.totalAvailableQty ?? 0
         }`;

         if (this.isUnitOfMeasureEnabled()) {
            /** Hierarchy node properties are not reactive so this depends on the "are units initialized" event above and an optional chain operator. */
            partNode.title += ` ${this.unitOfMeasureService.getUnit(part.unitDescription)()?.short() ?? ""}`;
         }

         partNode.title += ` ${this.lang().available}`;

         if (
            part.partLocation !== undefined &&
            part.partLocation !== null &&
            part.partLocation.length > 0
         ) {
            partNode.title += ` ${this.lang().at} ${part.partLocation}`;
         }

         if (locationIndex[part.locationID] && this.locations.length > 1) {
            locationIndex[part.locationID].nodes.push(partNode);
         }
      }

      for (const index in regionsIndex) {
         if (this.locations.length <= 1) {
            break;
         }
         const region = regionsIndex[index];
         region.nodeDisplay = region.regionName;
         region.title = region.regionName;
         region.unselectable = true;
         region.icon = "earthAmericas";

         if (
            (this.searchBar !== undefined && this.searchBar.length > 1) || //if we are searching start not collapsed
            this.manageLocation.getRegions().length <= 3 //if they are starting collapsed and they don' thave many regions then let's show them
         ) {
            region.collapsed = false;
         } else {
            region.collapsed = true;
         }

         region.selected = false;
      }

      this.treeData = [];

      if (this.locations.length > 1) {
         //first we do a little logic to see if they only have one part... that way we can show them a smaller list
         const partLocationIDs = Array.from(this.parts).map((part) => part.locationID);
         const only1Loc = new Set(partLocationIDs).size === 1;

         if (this.manageLocation.getRegions().length > 0) {
            //they are doing regions so let's show those correctly...

            //first we need to remove any locations that don't have parts or other children on them or any regions that are empty
            this.manageFilters.cleanupEmptyRegionsAndLocations(regionsIndex);

            //lastly add the top level regions
            for (const region of regions) {
               if (region.nodes.length > 0) {
                  if (this.searchBar?.length) {
                     region.nodes.forEach((locationNode) => {
                        if (locationNode.nodes.length > 0) {
                           locationNode.collapsed = false;
                           region.collapsed = false;
                        }
                     });
                  }
                  this.treeData.push(region);
               }
            }
         } else if (only1Loc) {
            //they aren't doing regions so process it like normal locations
            for (const part of this.parts) {
               const partNode = this.getPartNode(part.partID);
               if (!partNode) continue;
               this.treeData.push(partNode);
            }
         } else {
            this.locations = orderBy(this.locations, "locationName");
            for (const location2 of this.locations) {
               if (location2.nodes.length > 0) {
                  if (this.searchBar?.length) {
                     location2.collapsed = false;
                  }
                  this.treeData.push(location2);
               }
            }
         }
      } else {
         for (const part of this.parts) {
            const partNode = this.getPartNode(part.partID);
            if (!partNode) continue;
            this.treeData.push(partNode);
         }
      }

      this.partsLength = this.parts.size;

      if (this.partsLength === 0) {
         this.newPartName = "";
         if (this.searchBar !== undefined) {
            this.newPartName = this.newPartName + this.searchBar;
         }
      }
   }

   public deselectAllParts(): void {
      this.hierarchy?.deselectAllNodes();
   }

   public selectAllParts(): void {
      this.hierarchy?.selectAllNodes();
      for (const location of this.locations) {
         location.collapsed = false;
      }
   }

   public createPart(): void {
      if (!this.canAddParts) {
         return;
      }
      this.createPartDisabled = true;
      if (
         this.newPartName === undefined ||
         this.newPartName === "" ||
         this.newPartName === false
      ) {
         this.createPartDisabled = false;
         this.alertService.addAlert(
            this.lang().WhoopsPleaseProvideAPartName,
            "warning",
            6000,
         );
         return;
      }
      if (this.newPartLocationID === 0) {
         return;
      }

      if (this.newPartNumber === false || this.newPartNumber === undefined) {
         this.newPartNumber = "";
      }

      if (this.newPartPrice === false || this.newPartPrice === undefined) {
         this.newPartPrice = 0;
      }

      if (this.newPartQty === false || this.newPartQty === undefined) {
         this.newPartQty = 0;
      }

      if (this.newPartName.length > 0 && this.newPartNumber.length > 0) {
         //both part name and part number are set so let's loop through parts at this location and see if we should warn them it may already exist
         const newParts = this.manageParts.getParts();
         let foundSimilarPart = false;
         const newLocation = this.manageLocation.getLocation(this.newPartLocationID);
         for (const part of newParts) {
            if (
               part.partDeleted === 0 &&
               part.locationID === this.newPartLocationID &&
               part.partName?.toLowerCase() === this.newPartName?.toLowerCase() &&
               part.partNumber?.toLowerCase() === this.newPartNumber?.toLowerCase()
            ) {
               //we found at this location.
               foundSimilarPart = true;
            }

            if (
               newLocation?.partNumberUnique &&
               part.partNumber?.toLowerCase() === this.newPartNumber?.toLowerCase()
            ) {
               foundSimilarPart = true;
            }
         }

         if (foundSimilarPart) {
            if (newLocation?.partNumberUnique) {
               this.alertService.addAlert(
                  this.lang().YouHaveUniquePartNumbersEnabled,
                  "warning",
                  6000,
               );
               this.createPartDisabled = false;
               return;
            }

            //we found a simliar part so let's ask them if they want to continue
            const instance = this.modalService.open(Confirm);

            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: this.lang().ThatPartAlreadyExistsMessage,
                  title: this.lang().ThatPartAlreadyExists,
               },
            };

            instance.result.then(
               (result) => {
                  if (result === 1) {
                     this.addPart();
                  } else {
                     this.createPartDisabled = false;
                  }
               },
               () => {
                  this.createPartDisabled = false;
               },
            );
         } else {
            this.addPart();
         }
      } else {
         this.addPart();
      }
   }

   public addPart(): void {
      if (!this.canAddParts) {
         return;
      }
      this.creatingPart = true;
      this.manageParts
         .addPart(
            this.newPartLocationID,
            this.newPartName,
            this.newPartNumber,
            this.newPartPrice,
            this.newPartQty,
         )
         .then((answer) => {
            if (answer?.data.success === true) {
               this.modalRef.close([answer.data.part]);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   public close(): void {
      this.modalRef.close(false);
   }

   public submit(): void {
      if (this.createPartShow === true) {
         return; //if they are in the middle of trying to create a part don't let them submit a new part..
      }

      const returnParts: Array<Part> = [];

      const allPartNodesToCheck = [...this.partNodes.entries()];

      for (const [partID, partNode] of allPartNodesToCheck) {
         if (!partNode.selected) {
            continue;
         }
         const part = this.manageParts.getPart(partID);
         if (!part) {
            continue;
         }
         returnParts.push(part);
      }

      returnParts.push(...this.associatedPartsDataAccumulator.getSelectedItems());

      if (returnParts.length === 0) {
         this.alertService.addAlert(this.lang().PleaseSelectAPart, "warning", 6000);
         return;
      }

      if (this.selectOne === true && this.modalData().popSelectionOnly === true) {
         const partNode = this.getPartNode(returnParts[0].partID);
         if (partNode === undefined) return;

         this.runPopPart(partNode);
         return;
      }

      this.modalRef.close(returnParts);
   }

   public clickCreatePartShow(): void {
      if (!this.canAddParts) {
         return;
      }
      this.createPartShow = !this.createPartShow;
      this.searchBar = "";
   }

   private getPartNode(partID: number): PartHierarchyNode | undefined {
      const partNode = this.partNodes.get(partID);
      assert(partNode);
      return partNode;
   }

   public runPopPart(partNode: PartHierarchyNode): void {
      const part = this.manageParts.getPart(partNode.partID);
      if (!part) {
         return;
      }
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: part.partID,
            locationID: part.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }
}
