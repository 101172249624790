import type { AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";
import {
   inject,
   ChangeDetectorRef,
   Component,
   HostBinding,
   Input,
   ViewChild,
   ViewContainerRef,
   computed,
} from "@angular/core";
import {
   ScrollContainerComponent,
   ScrollWhileDraggingDirective,
   TextButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { HierarchyItemLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-item-component-legacy/hierarchy-item-component-legacy.component";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { PermissionID } from "src/app/users/schemata/users/self/credentials/permission.enum";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "hierarchy-container-legacy",
   templateUrl: "./hierarchy-container-legacy.component.html",
   styleUrls: ["./hierarchy-container-legacy.component.scss"],
   imports: [ScrollContainerComponent, ScrollWhileDraggingDirective, TextButtonComponent],
})
export class HierarchyContainerLegacy implements AfterViewInit, OnChanges {
   //This component renders the first level components of the tree,
   //each of which has an <ol> containing its children.
   //The child component rendered by each of those has child components
   // rendered inside of it in <li> tags.
   @HostBinding("class") public readonly classes = "scroll-height-inheritance";
   @ViewChild("hierarchyItemContainer", { read: ViewContainerRef })
   hierarchyItemContainer: ViewContainerRef | undefined = undefined;
   @Input()
   public treeData: Array<HierarchyNode> | undefined;
   @Input() public options: HierarchyOptions | undefined;
   @Input() public selectedNodeID;

   selectedUnsureAssetUnderLocation: HierarchyNode | undefined;

   public clickAgainText: string;

   public nodeOptionsMap: LimbleMap<number, HierarchyOptions> = new LimbleMap();

   protected totalNumberOfParentNodes: number = 0;
   protected totalNumberOfAllNodes: number = 0;
   protected numberOfItemsRendered: number = 0;
   private readonly ADDITIONAL_ITEMS_TO_RENDER_INITIAL_VAL: number = 50;
   protected additionalItemsToRender = this.ADDITIONAL_ITEMS_TO_RENDER_INITIAL_VAL;

   private readonly credService = inject(CredService);
   private readonly changeDetector = inject(ChangeDetectorRef);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.clickAgainText = this.lang().ClickAgainToSelect;
      this.selectedUnsureAssetUnderLocation = undefined;
   }

   public ngOnChanges(changes: SimpleChanges) {
      this.createOptionsForNodes();
      this.renderInitialHierarchyItems();
      if (
         changes.selectedNodeID?.currentValue !== changes.selectedNodeID?.previousValue &&
         !changes.selectedNodeID?.firstChange
      ) {
         const foundNode = this.searchTreeForNode(
            changes.selectedNodeID.currentValue,
            true,
         );
         if (foundNode) {
            this.selectNode(foundNode);
         }
      }
   }

   public ngAfterViewInit() {
      this.renderInitialHierarchyItems();
      this.changeDetector.detectChanges();
   }

   private createOptionsForNodes() {
      if (!this.treeData?.length) {
         return;
      }
      for (const [nodeIndex, topLevelNode] of this.treeData.entries()) {
         if (topLevelNode.regionID === undefined || topLevelNode.regionID === 0) {
            this.setNodeButtonVisibility(topLevelNode, nodeIndex);
         } else {
            this.findLocationsInRegionNodes(topLevelNode, nodeIndex);
         }
      }
   }
   //recursive function
   private findLocationsInRegionNodes(topLevelNode, nodeIndex) {
      for (const node of topLevelNode.nodes) {
         if (node.locationID !== undefined) {
            this.setNodeButtonVisibility(node, nodeIndex);
         } else if (node.regionID !== undefined) {
            this.findLocationsInRegionNodes(node, nodeIndex);
         }
      }
   }

   private setNodeButtonVisibility(parentNode, nodeIndex) {
      if (!this.options?.nodeButtons) {
         return;
      }
      const nodeButtons = this.options.nodeButtons.map((button) => {
         //validating permissions: if there is no permission number, that means it does not require a permission to see it.
         if (!button.permissionNumber) {
            return { ...button, permissionValidated: true };
         }
         //if there is no locationID, don't show anything location/permission specific
         if (!parentNode.locationID) {
            return { ...button, permissionValidated: false };
         }
         return {
            ...button,
            permissionValidated: this.checkPermission(
               parentNode.locationID,
               button.permissionNumber,
            ),
         };
      });
      const nodeOptions = {
         ...this.options,
         nodeButtons: nodeButtons,
      };
      this.nodeOptionsMap.set(nodeIndex, nodeOptions);
   }

   private checkPermission(locationID: number, permissionNumber: PermissionID) {
      if (Number(permissionNumber) !== 0) {
         return this.credService.isAuthorized(locationID, permissionNumber);
      }
      return true;
   }

   private searchTreeForNode(targetNodeID: number, ignoreCollapsed: boolean) {
      if (!this.treeData) {
         return undefined;
      }
      return this.treeData.reduce((acc, cur) => {
         const foundNode = this.findNodeInBranch(cur, targetNodeID, ignoreCollapsed);
         return foundNode ? foundNode : acc;
      }, null);
   }

   private findNodeInBranch(node, targetNodeID: number, ignoreCollapsed: boolean) {
      if (ignoreCollapsed && node.collapsed) {
         return null;
      }
      if (
         this.options?.idKey &&
         Number(node[this.options?.idKey]) === Number(targetNodeID) &&
         node.type !== 1
      ) {
         //node.type === 1 means that the node is a location, so we want to always dig a level deeper with them
         return node;
      } else if (node.nodes?.length) {
         return node.nodes.find((item) => {
            return this.findNodeInBranch(item, targetNodeID, ignoreCollapsed);
         });
      }
      return null;
   }

   selectNode = (nodeToToggle: HierarchyNode) => {
      if (nodeToToggle.unselectable || nodeToToggle.preventSelection) {
         //locations and regions are unselectable
         //clicking should toggle the collapse property, not the selected property
         this.collapseNode(nodeToToggle);
         return;
      }
      if (
         this.isDisabled(nodeToToggle) ||
         nodeToToggle.preventSelection ||
         this.options?.selection?.selectionDisabled
      ) {
         return;
      }
      this.selectedUnsureAssetUnderLocation = nodeToToggle;

      if (this.options?.selection?.submitOnClick) {
         this.submit(nodeToToggle[this.options.idKey]);
      } else {
         if (this.options?.selection?.singleSelection) {
            if (nodeToToggle.selected) {
               //if the node is already selected, submit
               this.submit();
            } else {
               this.deselectAllNodes();
               nodeToToggle.selected = !nodeToToggle.selected;
            }
         } else {
            nodeToToggle.selected = !nodeToToggle.selected;
         }
      }
      if (this.options?.onSelect) {
         this.options.onSelect(nodeToToggle);
      }
   };

   collapseNode = (nodeToToggle) => {
      nodeToToggle.collapsed = !nodeToToggle.collapsed;
   };

   submit = (nodeID?) => {
      if (this.options?.submit) {
         if (this.options?.selection?.submitOnClick) {
            this.options.submit(nodeID);
            return;
         }
         this.options.submit();
      }
   };

   deselectAllNodes = () => {
      if (this.options?.deselectAllAssetNodes) {
         this.options.deselectAllAssetNodes();
         return;
      }
      if (!this.treeData) {
         return;
      }
      for (const item of this.treeData) {
         item.selected = false;
         if (item.nodes) {
            this.deselectChildren(item);
         }
      }
      this.changeDetector.markForCheck();
   };

   public selectAllNodes() {
      if (!this.treeData) {
         return;
      }
      for (const item of this.treeData) {
         if (!item.unselectable) {
            item.selected = true;
         }
         item.collapsed = false;
         if (item.nodes) {
            this.selectChildren(item);
         }
      }
   }

   public collapseAllNodes() {
      if (!this.treeData) {
         return;
      }
      for (const item of this.treeData) {
         item.collapsed = true;
         if (item.nodes) {
            this.collapseChildren(item);
         }
      }
   }

   public collapseChildren(node) {
      if (node.nodes) {
         for (const childNode of node.nodes) {
            childNode.collapsed = true;
            if (childNode.nodes) {
               this.collapseChildren(childNode);
            }
         }
      }
   }

   private deselectChildren(node) {
      if (node.nodes) {
         for (const childNode of node.nodes) {
            if (childNode.selected) {
               childNode.selected = false;
            }
            if (childNode.nodes) {
               this.deselectChildren(childNode);
            }
         }
      }
   }

   public selectChildren(node: HierarchyNode): void {
      if (!node.nodes) return;
      node.nodes.forEach((childNode) => {
         if (!childNode.unselectable) {
            childNode.selected = true;
         }
         childNode.collapsed = false;
         this.selectChildren(childNode);
      });
   }

   public isDisabled(NodeToToggle: any): boolean {
      if (this.options?.isDisabled) {
         return this.options.isDisabled(NodeToToggle);
      }
      return false;
   }

   private renderInitialHierarchyItems(): void {
      if (!this.treeData || !this.hierarchyItemContainer) {
         return;
      }

      if (this.treeData) {
         this.totalNumberOfParentNodes = this.treeData.length;
         this.totalNumberOfAllNodes = 0;
         // This counting has been profiled, and takes up to 3ms for 50,000 nodes.
         for (const node of this.treeData) {
            this.totalNumberOfAllNodes += this.countNodes(node);
         }
      }

      this.hierarchyItemContainer.clear();
      this.numberOfItemsRendered = 0;
      this.additionalItemsToRender = this.ADDITIONAL_ITEMS_TO_RENDER_INITIAL_VAL;
      const NUMBER_OF_INTIAL_ITEMS =
         this.totalNumberOfAllNodes > 500 ? 100 : this.totalNumberOfAllNodes;
      this.renderHierarchyItems(NUMBER_OF_INTIAL_ITEMS);
   }

   protected renderNextBatchOfHierarchyItems(): void {
      if (!this.treeData || !this.hierarchyItemContainer) {
         return;
      }

      this.renderHierarchyItems(this.additionalItemsToRender);
   }

   private renderHierarchyItems(numberofItemsToRender: number): void {
      if (!this.treeData || !this.hierarchyItemContainer) {
         return;
      }

      let limit = this.numberOfItemsRendered + numberofItemsToRender;
      if (this.treeData.length < limit) {
         limit = this.treeData.length;
      }

      const treeCopy = this.treeData;
      for (let keyIndex = this.numberOfItemsRendered; keyIndex < limit; keyIndex++) {
         const hierarchyItem =
            this.hierarchyItemContainer?.createComponent(HierarchyItemLegacy);
         assert(hierarchyItem !== undefined);
         hierarchyItem.instance.node = treeCopy[keyIndex];
         hierarchyItem.instance.options = this.options?.nodeButtons
            ? this.nodeOptionsMap.get(keyIndex)
            : this.options;
         hierarchyItem.instance.deselectAllNodes = this.deselectAllNodes;
         hierarchyItem.instance.selectNode = this.selectNode;
         hierarchyItem.instance.collapseNode = this.collapseNode;
         hierarchyItem.instance.clickAgainText = this.clickAgainText;
         hierarchyItem.instance.includeShowMore = this.totalNumberOfAllNodes > 500;
      }

      this.numberOfItemsRendered += numberofItemsToRender;

      this.calculateAdditionalItemsToRenderText();
   }

   private calculateAdditionalItemsToRenderText(): void {
      if (!this.treeData) {
         return;
      }

      // We only want to show up to the maximum number of items left to render
      if (
         this.numberOfItemsRendered + this.additionalItemsToRender >=
         this.treeData.length
      ) {
         this.additionalItemsToRender = this.treeData.length - this.numberOfItemsRendered;
      }
   }

   private countNodes(node: HierarchyNode): number {
      let count = 1;
      if (node.nodes) {
         for (const childNode of node.nodes) {
            count += this.countNodes(childNode);
         }
      }
      return count;
   }
}
