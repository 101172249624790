import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, type Observable } from "rxjs";
import type { AssetTemplateField } from "src/app/assets/services/asset-field.types";
import { mapAssetTemplateFieldApi } from "src/app/assets/services/asset-template-fields/asset-template-field-api-mapper";
import type {
   AssetTemplateFieldFilters,
   AssetTemplateFieldsAPI,
} from "src/app/assets/services/asset-template-fields/asset-template-field-api.models";
import type {
   ListResponse,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import { assert } from "src/app/shared/utils/assert.utils";
import { environment } from "src/environments/environment";

const TEMPLATE_FIELD_URL = "assets/template-field";

/**
 * This service is used to edit a template that has never been published. aka the template is in draft mode.
 * All changes are immediately persisted to the API.  If editing a published template, use the EditAssetTemplateFieldApiService.
 */
@Injectable({
   providedIn: "root",
})
export class AssetTemplateFieldApiService implements AssetTemplateFieldsAPI {
   private readonly http = inject(HttpClient);

   public getList(
      requestOptions: Partial<RequestOptions<AssetTemplateFieldFilters>>,
   ): Observable<Partial<ListResponse<AssetTemplateField>>> {
      assert(requestOptions.filters?.templateID, "templateID is required");
      return this.http
         .get<
            AssetTemplateField[]
         >(`${environment.servicesURL()}/${TEMPLATE_FIELD_URL}/${requestOptions.filters.templateID}/fields/`, { withCredentials: true })
         .pipe(map(mapAssetTemplateFieldApi));
   }

   /**
    * Creates a new entity with a POST request
    */
   public createBulk(
      body: number[],
      requestOptions: Partial<RequestOptions<AssetTemplateFieldFilters>>,
   ): Observable<AssetTemplateField[]> {
      assert(requestOptions.filters?.templateID, "templateID is required");
      return this.http.post<AssetTemplateField[]>(
         `${environment.servicesURL()}/${TEMPLATE_FIELD_URL}/${requestOptions.filters.templateID}/fields`,
         body,
         { withCredentials: true },
      );
   }

   /**
    * Updates an entity with a PUT request
    */
   public patch(
      id: number,
      body: Partial<AssetTemplateField>,
      requestOptions: Partial<RequestOptions<AssetTemplateFieldFilters>>,
   ): Observable<AssetTemplateField> {
      assert(requestOptions.filters?.templateID, "templateID is required");
      return this.http.patch<AssetTemplateField>(
         `${environment.servicesURL()}/${TEMPLATE_FIELD_URL}/${requestOptions.filters.templateID}/fields/${id}`,
         body,
         { withCredentials: true },
      );
   }

   /**
    * Deletes an entity with a DELETE request
    */
   public delete(
      id: number,
      requestOptions: Partial<RequestOptions<AssetTemplateFieldFilters>>,
   ): Observable<any> {
      assert(requestOptions.filters?.templateID, "templateID is required");
      return this.http.delete(
         `${environment.servicesURL()}/${TEMPLATE_FIELD_URL}/${requestOptions.filters?.templateID}/fields/${id}`,
         { withCredentials: true },
      );
   }
}
