import { computed } from "@angular/core";
import {
   patchState,
   signalStore,
   withComputed,
   withMethods,
   withState,
} from "@ngrx/signals";
import type {
   ApplyTemplateAssetFilters,
   ApplyTemplateFieldMappings,
   FieldFilter,
   ApplyTemplateCriteria,
} from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { Location } from "src/app/locations/types/location.types";
import { parseFilterString } from "src/app/shared/data-viewer/data-viewer-filters/components/asset-field-value-filter/components/asset-field-filter-util";
import type { Filter } from "src/app/shared/types/general.types";
import type { AssetEntity } from "src/app/tasks/components/shared/services/assets-api/assets-api.models";

export type ApplyTemplateLocation = Pick<Location, "locationName" | "locationID">;

type SelectAll = {
   filters: ApplyTemplateAssetFilters;
   excludedAssetIds: Set<number>;
};

type AssetSelections = {
   selectAll: SelectAll | null;
   includedAssetIds: Set<number>;
};

export type AssetFieldsFilter = {
   pageSize: number;
   assetFieldFilters?: string;
   search?: string;
   fieldInformation: Array<Filter<AssetField>>;
};

export type ApplyTemplateState = {
   /**
    * Map of locations that have been selected for applying templates
    * Key is the locationID, value contains location details
    */
   locationsSet: Map<number, ApplyTemplateLocation>;

   /**
    * Search term used for filtering locations
    */
   locationsSearch: string;

   /**
    * Tracks which assets are selected for template application
    * Can either select all assets with exclusions or specific included assets
    */
   assetSelections: AssetSelections;

   /**
    * Total count of assets available for template application
    */
   totalAssetsCount: number;

   /**
    * Mappings of template fields to location-based fields
    */
   fieldMappings: ApplyTemplateFieldMappings;

   /**
    * Count of location-based fields that will be merged during template application
    */
   fieldsToMergeCount: number;

   /**
    * Count of currently selected assets
    */
   selectedAssetCount: number;

   /**
    * Filters and settings for the asset fields
    */
   assetFieldsFilter: AssetFieldsFilter;

   /**
    * Asset selection filters and field mappings to determine how to apply the template.
    */
   applyCriteria?: ApplyTemplateCriteria;
};

const initialState: ApplyTemplateState = {
   locationsSet: new Map(),
   locationsSearch: "",
   assetSelections: {
      selectAll: null,
      includedAssetIds: new Set(),
   },
   totalAssetsCount: 0,
   fieldMappings: {},
   fieldsToMergeCount: 0,
   selectedAssetCount: 0,
   assetFieldsFilter: {
      fieldInformation: [],
      pageSize: 50,
   },
};

export const ApplyTemplateStore = signalStore(
   withState(initialState),
   withComputed((store) => ({
      /**
       * Returns the first (and should be only for now) selected location
       */
      selectedLocation: computed(() => {
         const locations = store.locationsSet();
         return [...locations.values()][0];
      }),

      /**
       * Computes total number of selected assets, either:
       * - Total assets minus excluded ones when "select all" is active
       * - Count of specifically included asset IDs
       */
      selectedAssetCount: computed(() => {
         const currentSelection = store.assetSelections();
         if (currentSelection.selectAll) {
            return (
               store.totalAssetsCount() - currentSelection.selectAll.excludedAssetIds.size
            );
         }
         return currentSelection.includedAssetIds.size ?? 0;
      }),

      /**
       * Computes total number of location-based fields that will be merged
       * by summing up all field mappings
       */
      fieldsToMergeCount: computed(() => {
         return Object.values(store.fieldMappings()).reduce(
            (total, mapping) => total + mapping.mappings.length,
            0,
         );
      }),
   })),
   withComputed((store) => ({
      /**
       * Computes the current asset selection state based on:
       * - Selected asset count
       * - Total assets available
       * - Current filters and search terms
       */
      assetSelections: computed(() => {
         const selectedAssetCount = store.selectedAssetCount();
         const totalAssetsCount = store.totalAssetsCount();
         if (selectedAssetCount === 0 || totalAssetsCount === 0) {
            return {
               selectAll: null,
               includedAssetIds: new Set(),
            };
         }
         if (selectedAssetCount === totalAssetsCount) {
            const { search, assetFieldFilters, fieldInformation } =
               store.assetFieldsFilter();
            const assetFieldFiltersToApply = assetFieldFilters
               ? String(assetFieldFilters)
               : "";
            const fields: Array<FieldFilter> = parseFilterString(
               assetFieldFiltersToApply,
               fieldInformation,
            );
            return {
               selectAll: {
                  filters: [
                     {
                        locationIds: [store.selectedLocation().locationID],
                        search: search ?? "",
                        assetInformation: {
                           fields,
                        },
                     },
                  ],
                  excludedAssetIds: new Set(),
               },
               includedAssetIds: new Set(),
            };
         }
         return { ...store.assetSelections() };
      }),
   })),
   withComputed((store) => ({
      /**
       * Computes the final criteria used for applying templates, including:
       * - Asset selection state (all or specific assets)
       * - Field mappings for template application
       */
      applyCriteria: computed(() => ({
         assetSelections: {
            selectAll: {
               filters: store.assetSelections().selectAll?.filters ?? [],
               excludedAssetIds: Array.from(
                  store.assetSelections().selectAll?.excludedAssetIds ?? [],
               ),
            },
            includedAssetIds: Array.from(store.assetSelections().includedAssetIds) ?? [],
         },
         fieldMappings: store.fieldMappings(),
      })),
   })),
   withMethods((store) => ({
      /**
       * Sets a single location for template application
       * Resets all asset and field selections
       */
      setLocationsSet: (location: ApplyTemplateLocation) => {
         const locationsSet = new Map<number, ApplyTemplateLocation>();
         locationsSet.set(location.locationID, location);

         //set location and reset assets and fields selection (if any)
         patchState(store, {
            locationsSet,
            assetSelections: {
               selectAll: null,
               includedAssetIds: new Set<number>(),
            },
            totalAssetsCount: 0,
            fieldMappings: {},
            fieldsToMergeCount: 0,
            selectedAssetCount: 0,
            assetFieldsFilter: {
               assetFieldFilters: "",
               fieldInformation: [],
               pageSize: 50,
            },
         });
      },

      /**
       * Updates the location search term
       */
      setLocationsSearch: (search: string) => {
         const locationsSearch = search ?? "";
         patchState(store, { locationsSearch });
      },

      /**
       * Updates the total count of available assets
       */
      setTotalAssetsCount: (totalAssetsCount: number) => {
         patchState(store, { totalAssetsCount });
      },

      /**
       * Toggles selection state for a single asset
       * Handles both "select all" and individual selection modes
       */
      toggleAssetSelection: (asset: AssetEntity & { selected: boolean }) => {
         const currentSelection = store.assetSelections();
         let updatedSelection: AssetSelections;

         if (currentSelection.selectAll) {
            const excludedAssetIds = asset.selected
               ? new Set(
                    [...currentSelection.selectAll.excludedAssetIds].filter(
                       (id) => id !== asset.assetID,
                    ),
                 )
               : currentSelection.selectAll.excludedAssetIds.add(asset.assetID);

            updatedSelection = {
               selectAll: {
                  ...currentSelection.selectAll,
                  excludedAssetIds,
               },
               includedAssetIds: new Set(),
            };
         } else {
            const includedAssetIds = asset.selected
               ? currentSelection.includedAssetIds.add(asset.assetID)
               : new Set(
                    [...currentSelection.includedAssetIds].filter(
                       (id) => id !== asset.assetID,
                    ),
                 );

            updatedSelection = {
               selectAll: null,
               includedAssetIds,
            };
         }
         patchState(store, {
            assetSelections: updatedSelection,
         });
      },

      /**
       * Toggles selection of all assets
       * When enabled, applies current filters and search terms
       */
      toggleSelectAllAssets(isSelected: boolean) {
         const { search, assetFieldFilters, fieldInformation } =
            store.assetFieldsFilter();
         let updatedSelection: AssetSelections;
         if (isSelected) {
            const assetFieldFiltersToApply = assetFieldFilters
               ? String(assetFieldFilters)
               : "";
            const fields: Array<FieldFilter> = parseFilterString(
               assetFieldFiltersToApply,
               fieldInformation,
            );
            updatedSelection = {
               selectAll: {
                  filters: [
                     {
                        locationIds: [store.selectedLocation().locationID],
                        search: search ?? "",
                        assetInformation: {
                           fields,
                        },
                     },
                  ],
                  excludedAssetIds: new Set(),
               },
               includedAssetIds: new Set(),
            };
         } else {
            updatedSelection = {
               selectAll: null,
               includedAssetIds: new Set(),
            };
         }
         patchState(store, {
            assetSelections: updatedSelection,
         });
      },

      /**
       * Updates the field mappings for template application
       */
      setFieldMapping: (fieldMappings: ApplyTemplateFieldMappings) => {
         patchState(store, {
            fieldMappings,
         });
      },

      /**
       * Updates the page size for asset listing
       */
      setAssetsPageSize: (pageSize: number) => {
         const assetFieldsFilter = {
            ...store.assetFieldsFilter(),
            pageSize,
         };
         patchState(store, {
            assetFieldsFilter,
         });
      },

      /**
       * Updates the search term for filtering assets
       */
      setAssetsSearch: (search: string) => {
         const assetFieldsFilter = {
            ...store.assetFieldsFilter(),
            search,
         };
         patchState(store, {
            assetFieldsFilter,
         });
      },

      /**
       * Updates the field filters for asset selection
       */
      setAssetFieldFilters: (
         assetFieldFilters: string,
         fieldInformation: Array<Filter<AssetField>> | null,
      ) => {
         const assetFieldsFilter = {
            ...store.assetFieldsFilter(),
            assetFieldFilters,
            fieldInformation: fieldInformation ?? [],
         };
         patchState(store, {
            assetFieldsFilter,
         });
      },
   })),
);
