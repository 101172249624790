import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { Component, computed, inject, Input } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   BasicModalHeaderComponent,
   IconComponent,
   LimUiModalRef,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalService,
   PanelComponent,
   ScrollContainerComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { catchError, combineLatest, finalize, map, Subject, switchMap, tap } from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { DataViewerStateService } from "src/app/shared/data-viewer/data-viewer-state.service";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import type { ScheduleEntity } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import { SchedulesApiService } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "view-list-of-tasks-by-schedule",
   templateUrl: "./schedule-list.component.html",
   styleUrls: ["./schedule-list.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SortColumn,
      ScrollContainerComponent,
      NgClass,
      IconComponent,
      TooltipDirective,
      LoadingAnimationComponent,
      BetterDatePipe,
   ],
   providers: [DataViewerStateService],
})
export class ScheduleListComponent implements OnInit {
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLocation = inject(ManageLocation);
   private readonly credService = inject(CredService);
   private readonly paramsService = inject(ParamsService);
   private readonly dataViewerStateService = inject(DataViewerStateService);
   private readonly schedulesApiService = inject(SchedulesApiService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly modalRef: LimUiModalRef<ScheduleListComponent, boolean> =
      inject(LimUiModalRef);

   @Input({ required: true }) public title!: string;
   @Input({ required: true }) public scheduleIDs!: Array<number>;
   protected schedules: Array<ScheduleEntity> = [];
   public loadingBar;
   public sortBind;
   public resolve;
   public modalInstance;

   protected isLoading: boolean = true;

   private readonly reloadSubject = new Subject<void>();
   private readonly reload$ = this.reloadSubject.asObservable();

   protected schedulesRequest$ = combineLatest([
      this.dataViewerStateService.requestOptions$,
      this.reload$,
   ]).pipe(
      tap(() => {
         this.isLoading = true;
      }),
      switchMap(([requestOptions]) =>
         this.schedulesApiService
            .getList(requestOptions)

            .pipe(finalize(() => (this.isLoading = false))),
      ),
      catchError((error, data) => {
         console.error("Error fetching tasks:", error);
         return data;
      }),
   );

   protected schedulesViewModel = toSignal(
      this.schedulesRequest$.pipe(
         map((response) => {
            return (
               response.data?.map((schedule) =>
                  this.mapScheduleEntityToViewModel(schedule),
               ) ?? []
            );
         }),
      ),
   );

   public ngOnInit() {
      this.initialize();
   }

   private initialize() {
      this.dataViewerStateService.setBaseFilters([{ scheduleIDs: this.scheduleIDs }]);
      this.dataViewerStateService.setExtraColumns("relatedTemplate");
      this.reloadSubject.next();
   }

   private mapScheduleEntityToViewModel(schedule: ScheduleEntity): ScheduleEntity & {
      locationName: string;
      assignment: string;
   } {
      const location = this.manageLocation.getLocation(schedule.locationID);

      const users = this.manageUser.getFlatUsersIndex();
      const profiles = this.manageUser.getProfilesIndex();
      let assignment = "";
      if (schedule.userID) {
         assignment = `${
            users[schedule.userID].userFirstName
         } ${users[schedule.userID].userLastName}`;
      } else if (schedule.profileID) {
         assignment = profiles[schedule.profileID].profileDescription;
      } else {
         assignment = this.lang().Unassigned;
      }
      return {
         ...schedule,
         locationName: location?.locationName ?? "",
         assignment,
      };
   }

   protected async changeSchedule(schedule): Promise<void> {
      if (
         !this.credService.isAuthorized(
            schedule.locationID,
            this.credService.Permissions.ChangePMAssignments,
         )
      ) {
         this.alertService.addAlert(this.lang().cred56Fail, "warning", 10000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };

      extraUsersOptions.arr.forEach((user) => {
         extraUsersOptions.key[user.userID] = user;
      });

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().ChangeTheTaskAssignmentForASingleScheduleMsg,
               title: this.lang().ChangeTheTaskAssignmentForASingleSchedule,
               locationID: schedule.locationID,
               extraUsers: extraUsersOptions.arr,
            },
         },
      };

      const data = await instance.result;
      if (!data) {
         this.alertService.addAlert(this.lang().cred56Fail, "danger", 10000);
      }

      const answer = await this.manageTask.updateSingleSchedule(
         data.userID,
         data.profileID,
         data.multiUsers,
         schedule.scheduleID,
      );

      if (answer.data.lName == null) {
         schedule.assignment = answer.data.fName;
      } else {
         schedule.assignment = `${answer.data.fName} ${answer.data.lName}`;
      }

      schedule.userID = data.userID;
      schedule.profileID = data.profileID;

      this.reloadSubject.next();
   }

   popTask = (checklistID: number) => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: false,
               preview: true,
               template: true,
            },
         },
      };
   };

   protected viewAsset(schedule): void {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: schedule.assetID,
            locationID: schedule.locationID,
            data: {},
         },
      };
   }

   protected checkCredAtLocation(locationID: number, credID: number): boolean {
      return this.credService.isAuthorized(locationID, credID);
   }

   protected close(): void {
      this.modalRef.close();
   }
}
