<div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
   <span class="lim-ui-mobile-table-column-label">{{ lang().Parts }}: </span>
   @if (task && task.partsCost) {
      @if (task.partsCost > 0) {
         <span> {{ task.partsCost | localeCurrency: task.currencyCode }}</span>
      } @else if (task.partsCost === 0) {
         <span>-</span>
      }
   } @else {
      <span>-</span>
   }
</div>
