/**
 * Enum representing all possible task type icons
 * These icons are used to visually represent different types of tasks
 */
export enum TaskTypeIcon {
   PlannedMaintenance = "wrench",
   UnplannedWorkOrder = "wpforms",
   PlannedWorkOrder = "file",
   WorkRequest = "triangleExclamation",
   CycleCount = "gears",
}

/**
 * Type guard to check if a string is a valid TaskTypeIcon
 * @param icon - The string to check
 * @returns boolean indicating if the string is a valid TaskTypeIcon
 */
export function isTaskTypeIcon(icon: string): icon is TaskTypeIcon {
   return Object.values(TaskTypeIcon).includes(icon as TaskTypeIcon);
}

export interface TaskTypeCellViewModel {
   isCompleted: boolean;
   checklistID: number;
   locationID: number;
   taskTypeIcons: TaskTypeIcon;
   typeLabel: string;
   checklistTemplateOld: number;
}
