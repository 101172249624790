import { ChangeDetectionStrategy, Component, input, model, output } from "@angular/core";
import { PaginatorModule, type PaginatorState } from "primeng/paginator";

@Component({
   selector: "e-paginator",
   standalone: true,
   imports: [PaginatorModule],
   templateUrl: "./paginator.component.html",
   styleUrls: ["./paginator.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
   /** Current page number (zero-based) */
   readonly page = model<number>(0);

   /** Number of items to display per page */
   readonly pageSize = model<number>(0);

   /** Number of rows to display per page */
   readonly rows = input<number>(10);

   /** Index of first record to display */
   readonly first = input<number>(0);

   /** Total number of records */
   readonly totalRecords = input<number>(0);

   /** Whether to show first and last page navigation icons */
   readonly showFirstLastIcon = input<boolean>(true);

   /** Whether to show the jump to page dropdown */
   readonly showJumpToPageDropdown = input<boolean>(false);

   /** Whether to show the current page report */
   readonly showCurrentPageReport = input<boolean>(true);

   /** Template string for current page report. Available placeholders: {currentPage}, {totalPages}, {rows}, {first}, {last}, {totalRecords} */
   readonly currentPageReportTemplate = input<string>("");

   /** Number of page links to display */
   readonly pageLinkSize = input<number>(5);

   /** Whether to display page links */
   readonly showPageLinks = input<boolean>(true);

   /** Whether to always show the paginator even with only one page */
   readonly alwaysShow = input<boolean>(true);

   /**
    * Emits the current page number when the page number changes.
    * It is not the zero-based index of the page, but the page number.
    */
   readonly changePageNumber = output<number>();

   public onPageNumberChange(state: PaginatorState) {
      this.changePageNumber.emit(state.page ?? 0);
   }
}
