import { computed, inject, Injectable } from "@angular/core";
import { LoadingBarService } from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import html2canvas from "html2canvas";
import $ from "jquery";
import { jsPDF as Pdf } from "jspdf";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { b64toBlob } from "src/app/shared/utils/app.util";

/**
 * @deprecated
 * msSaveOrOpenBlob is NOT an official part of the navigator object. MDN strongly discourages its use and Typescript has removed it.
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveOrOpenBlob}
 * {@link https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1029#issuecomment-869224737}
 */
const msSaveOrOpenBlob = (window.navigator as any).msSaveOrOpenBlob;

@Injectable({ providedIn: "root" })
export class ManageReport {
   private readonly reports: any = {};
   private readonly axios = axios;

   private readonly manageLang = inject(ManageLang);
   private readonly manageUtil = inject(ManageUtil);
   private readonly betterDate = inject(BetterDate);
   private readonly loadingBarService = inject(LoadingBarService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.reports.arr = [];
      this.reports.index = {};
   }
   getData = () => {
      this.axios({
         method: "POST",
         url: "phpscripts/manageReport.php",
         params: { action: "manageReports" },
         data: {},
      }).then((answer) => {
         //Sets arrays for user.
         this.reports.arr = answer.data.reports || [];
         //Sets array based on Primary Key then binds to orginial array for easier lookup.
         for (const report of this.reports.arr) {
            this.reports.index[report.customReportID] = report;
         }
      });
   };
   clearData = () => {
      this.reports.arr = [];
      this.reports.index = {};
   };
   getReports = () => {
      return this.reports.arr;
      //return users.index;
   };
   //PT - Good to go
   /****************************************
    *@function downloadExecutiveSummary
    *@purpose
    *@name downloadExecutiveSummary
    *@param
    *@return
    ****************************************/
   downloadExecutiveSummary = async (locationID, name) => {
      const date = this.betterDate.createTodayTimestamp();
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageReport.php",
         params: {
            action: "downloadExecutiveSummary",
         },
         data: {
            locationID: locationID,
            name: name,
            userTimezone: userTimezone,
         },
      });

      post.then(
         (answer) => {
            if (!answer.data.success) {
               return;
            }
            //added this to extract the actual data and ignore the href data info
            const afile = answer.data.file.slice(
               "data:application/vnd.ms-excel;base64,".length,
            );

            //control if the link will be windows or everything else that is better than windows
            if (window.Blob && msSaveOrOpenBlob) {
               // Falls to msSaveOrOpenBlob if download attribute is not supported
               try {
                  const blobObject = b64toBlob(afile, {
                     type: "application/vnd.ms-excel",
                  });
                  msSaveOrOpenBlob(blobObject, `${name}-${date}.xls`);
               } catch (error) {
                  console.error(error);
               }
            } else {
               const aaa = document.createElement("a");
               aaa.setAttribute("href", answer.data.file);
               aaa.setAttribute("download", `${name}-${date}.xls`);
               aaa.setAttribute("target", "_blank");
               document.body.appendChild(aaa);
               aaa.click();
               aaa.remove();
            }
         },
         (err) => {
            console.error("export error", err);
         },
      );

      return post;
   };
   /****************************************
    *@function downloadLastEdited
    *@purpose
    *@name downloadLastEdited
    *@param
    *@return
    ****************************************/
   downloadLastEdited = async (locationID) => {
      const date = this.betterDate.createTodayTimestamp();

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageReport.php",
         params: {
            action: "downloadLastEdited",
         },
         data: { locationID: locationID },
      });

      post.then(
         (answer) => {
            //added this to extract the actual data and ignore the href data info
            const afile = answer.data.file.slice(
               "data:application/vnd.ms-excel;base64,".length,
            );

            //control if the link will be windows or everything else that is better than windows
            if (window.Blob && msSaveOrOpenBlob) {
               // Falls to msSaveOrOpenBlob if download attribute is not supported
               try {
                  const blobObject = b64toBlob(afile, {
                     type: "application/vnd.ms-excel",
                  });
                  msSaveOrOpenBlob(
                     blobObject,
                     `${this.lang().limbleLastEdited}-${date}.xls`,
                  );
               } catch (error) {
                  console.error(error);
               }
            } else {
               const aaa = document.createElement("a");
               aaa.setAttribute("href", answer.data.file);
               aaa.setAttribute(
                  "download",
                  `${this.lang().limbleLastEdited}-${date}.xls`,
               );
               aaa.setAttribute("target", "_blank");
               document.body.appendChild(aaa);
               aaa.click();
               aaa.remove();
            }
         },
         (err) => {
            console.error(err);
         },
      );

      return post;
   };
   /****************************************
    *@function downloadLastEdited
    *@purpose
    *@name downloadLastEdited
    *@param
    *@return
    ****************************************/
   downloadAssetsByLocation = async (locationID: number, assetID: number) => {
      const date = this.betterDate.createTodayTimestamp();
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageReport.php",
         params: {
            action: "downloadAssetsExcel",
         },
         data: {
            locationID: locationID,
            assetID: assetID,
            userTimezone: userTimezone,
         },
      });

      post.then(
         (answer) => {
            //added this to extract the actual data and ignore the href data info
            const afile = answer.data.file.slice(
               "data:application/vnd.ms-excel;base64,".length,
            );

            //control if the link will be windows or everything else that is better than windows
            if (window.Blob && msSaveOrOpenBlob) {
               // Falls to msSaveOrOpenBlob if download attribute is not supported
               try {
                  const blobObject = b64toBlob(afile, {
                     type: "application/vnd.ms-excel",
                  });
                  msSaveOrOpenBlob(
                     blobObject,
                     `${this.lang().limbleAssetList}-${date}.xls`,
                  );
               } catch (error) {
                  console.error(error);
               }
            } else {
               const aaa = document.createElement("a");
               aaa.setAttribute("href", answer.data.file);
               aaa.setAttribute("download", `${this.lang().limbleAssetList}-${date}.xls`);
               aaa.setAttribute("target", "_blank");
               document.body.appendChild(aaa);
               aaa.click();
               aaa.remove();
            }
         },
         (err) => {
            console.error(err);
         },
      );

      return post;
   };
   /****************************************
    *@function printPDF
    *@purpose generate and download PDF files
    *@name printPDF
    *@param current page with location.path() and element by JS ID
    *@return
    ****************************************/
   printPDF = async (page, element) => {
      const lang = this.lang();
      const msg = `<strong>${lang.WarmingUpTheFluxCapacitor}...</strong></br><span>${lang.ThisMayTakeAMoment}</span>`;

      const safariOffest = /Chrome|FireFox/.test(window.navigator.userAgent) ? 0 : 75;

      this.loadingBarService.show({ header: msg });
      const setWidth = element.offsetWidth * 1.1; //added 10% - for some reason things get a little squished in this process - makes things look better

      //this jquery will hide the page while the page is rearranged to allow html2canvas to get the right image
      $("#page-container").css("opacity", "0");
      $(".modal-content").css("opacity", "0");

      // If the element is lim-ui-scroll-container, then we need to set the overflow to visible so that the html2canvas can capture the entire element.
      if (element.tagName.toLowerCase() === "lim-ui-scroll-container") {
         element.style.overflow = "visible";
      }

      if (element.id === "gridsterMainContainer") {
         //this pdf is of a customDashboard, which is built with the angular-gridster2 library
         //  If this process isn't done, the snapshot taken by html2canvas will have the widgets placed all over in the wrong places or simply not there

         //each widget in a custom dashboard is placed by angular-gridster2 using the 'transform' property in the css instead of the 'top' or 'left' properties
         //html2canvas doesn't play nice with transform: translate - so replace them with regular old left / top styles.
         //the offsetRegex grabs the number of pixels the 'transform' property is set to so we can replace it with 'top' and 'left'
         const offsetRegex = /(\d+)(?=px)/g;
         for (const gridItem of element.querySelectorAll("gridster-item")) {
            const offsets = gridItem.style.transform.match(offsetRegex);

            if (!offsets) {
               continue;
            }

            //this unsets the animation that each tile has on it so that when the html2canvas snapshot is taken, the item isn't in the middle of being repositioned
            gridItem.style.transition = "unset";

            const offsetTop = Number(offsets[1]) + safariOffest;
            gridItem.style.transform = "";
            gridItem.style.left = `${offsets[0]}px`;
            gridItem.style.top = `${offsetTop}px`;
         }
      }

      const aCanvas = await html2canvas(element, { scale: 3 }); //this will increase the resolution of the canvas - also increasing processing time

      if (element.id === "gridsterMainContainer") {
         //the styles set before the snapshot was taken now need to be removed so that the custom dashboard behaves like it did previously
         for (const gridItem of element.querySelectorAll("gridster-item")) {
            gridItem.style.transition = ".3s";
            gridItem.style.left = `0px`;
            gridItem.style.top = `0px`;
         }
      }

      const img = aCanvas.toDataURL("image/jpeg", 1);

      const imgHeight = (aCanvas.height * setWidth) / aCanvas.width;

      const doc = new Pdf("p", "px", [setWidth, setWidth * 1.41]); //set the page size - trick is to use our element width
      let position = 0; // give some top padding to first page
      const pageHeight = setWidth * 1.41;
      doc.addImage(img, "jpeg", 0, position, setWidth, imgHeight);

      let heightLeft = imgHeight;
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
         position = heightLeft - imgHeight; // top padding for other pages
         doc.addPage();
         doc.addImage(img, "jpeg", 0, position, setWidth, imgHeight);
         heightLeft -= pageHeight;
      }

      doc.save(`${page.slice(1)}.pdf`);

      setTimeout(() => {
         element.style.width = "auto";

         if (element.tagName.toLowerCase() === "lim-ui-scroll-container") {
            element.style.overflow = "auto";
         }
         this.loadingBarService.remove();
         $("#page-container").css("opacity", "1");
         $(".modal-content").css("opacity", "1");
         setTimeout(() => {
            for (const key in (window as any).Chart.instances) {
               (window as any).Chart.instances[key].resize(
                  (window as any).Chart.instances[key].render,
                  true,
               );
            }
         }, 100);
      }, 100);
   };

   public simplifyLabel(originalLabel: unknown): string {
      if (typeof originalLabel !== "string") {
         return "";
      }
      const indexAfterLocationName = originalLabel.indexOf("</b> Total :");
      const truncatedLabel = originalLabel.slice(
         0,
         originalLabel.indexOf(" -", indexAfterLocationName),
      );
      const strippedLabel = this.manageUtil.stripTags(truncatedLabel);
      const finalLabel = strippedLabel.replace("Total :", "-");
      return finalLabel;
   }
}
