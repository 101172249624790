import { Location } from "@angular/common";
import type {
   AfterViewInit,
   OnInit,
   ElementRef,
   QueryList,
   WritableSignal,
} from "@angular/core";
import {
   signal,
   inject,
   ChangeDetectorRef,
   Component,
   Renderer2,
   ViewChild,
   ViewChildren,
   computed,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   CheckboxComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownInlineTextComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   IconButtonComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PaginationComponent,
   PanelComponent,
   PrimaryButtonComponent,
   RadioButtonComponent,
   ScrollContainerComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   TextButtonComponent,
   TooltipDirective,
   LimUiModalRef,
   isNativeMobileApp,
   isMobileBrowser,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import html2canvas from "html2canvas";
import { jsPDF as Pdf } from "jspdf";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { AssetTemplateFieldService } from "src/app/assets/services/asset-template-field.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import {
   PartsFacadeService,
   type AddPartModalData,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { PartField } from "src/app/parts/types/field/field.types";
import type { Part } from "src/app/parts/types/part.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ManageShared } from "src/app/shared/components/global/qrCodeCustomizePrintModal/manageShared";
import { QRCodeItem } from "src/app/shared/components/global/qrCodeItemElement/qrCodeItem.element.component";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { PickTasksTemplateViewLegacy } from "src/app/tasks/components/pickTasksTemplateViewLegacy/pickTasksTemplateViewLegacy.component";
import { PickTasksTemplateView } from "src/app/tasks/components/pickTasksTemplateViewModal/pickTasksTemplateView.modal.component";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task, TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

interface PrintingParams {
   setWidth: number;
   setHeight: number;
   orientation: "p" | "l";
}

@Component({
   selector: "qr-code-customize-print",
   templateUrl: "./qrCodesCustomizePrint.modal.component.html",
   styleUrls: ["./qrCodesCustomizePrint.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      DropdownComponent,
      DropdownInlineTextComponent,
      SecondaryButtonComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownItemComponent,
      TooltipDirective,
      IconComponent,
      IconButtonComponent,
      FormsModule,
      RadioButtonComponent,
      FormDropdownInputComponent,
      CheckboxComponent,
      ScrollContainerComponent,
      SearchBoxComponent,
      TextButtonComponent,
      QRCodeItem,
      PaginationComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SliceArray,
   ],
})
export class QRCodesCustomizePrint implements OnInit, AfterViewInit {
   @ViewChild("printPreviewContainer")
   public printPreviewContainer?: ElementRef<HTMLElement>;
   @ViewChildren("printGroupContainer")
   public printGroupComponents?: QueryList<HTMLElement>;
   @ViewChildren("printDiv") printDivComponents?: QueryList<HTMLElement>;
   public data: WritableSignal<Lookup<string, any> | any[]> = signal([]); //parts, assets, tasks, etc.
   public dataType: WritableSignal<"asset" | "part" | "task" | undefined> =
      signal(undefined);
   public buildDataArray: any[] = [];
   public locationID;
   public path;
   public user;
   public templateBelongsToUser;
   public usingSavedTemplate = true;
   public unsavedChanges = false;
   public templateOwnerName;
   public savedTemplates: any = [];
   public allAssetFields: Lookup<"fieldID", AssetField> = new Lookup("fieldID");
   public allPartFields: Lookup<"fieldID", PartField> = new Lookup("fieldID");
   public index;
   public paperSizes: any = ["8.5 x 11", "8.5 x 14", "11 x 17"];
   public dymoSizes: any = [
      "1 1/8 x 3 1/2",
      "1 x 3 1/2",
      "2 x 1 1/4",
      "2 1/8 x 4",
      "2-5/16 x 4",
      "2-5/16 x 7-1/2",
      "1 x 2 1/8",
      "4-1/16 x 6-1/4",
   ];
   public page = 1;
   public itemsPerPage;
   public printing = false;
   public displayData: any = [];
   public availableFields: any = [];
   public displayFields: any = [];
   public rotate90;
   public currentTemplate;
   public codeItemWidth;
   public codeItemHeight;
   public printContainerWidth;
   public printContainerHeight;
   public fieldsSearchValue;
   public fieldsFilteredToSearch;
   public printGroupArray;
   public defaultTemplate;
   public loadingSavedTemplates = true;
   public pages;
   public extraElementsToAdd;
   public pagesPerBatch = 5;
   public hiddFrame;
   public customWidth;
   public customHeight;
   public itemsProcessed = 0;
   public buildPdfDisabled = false;
   private templatesPromise: Promise<boolean> | undefined;

   public readonly modalRef: LimUiModalRef<QRCodesCustomizePrint, number> =
      inject(LimUiModalRef);

   private readonly paramsService = inject(ParamsService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly renderer = inject(Renderer2);
   private readonly location = inject(Location);
   private readonly modalService = inject(ModalService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageShared = inject(ManageShared);
   private readonly manageUser = inject(ManageUser);
   private readonly alertService = inject(AlertService);
   private readonly manageParts = inject(ManageParts);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageLocation = inject(ManageLocation);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly taskTemplatesApi = inject(TaskTemplatesApiService);
   private readonly partsFacadeService = inject(PartsFacadeService);
   private readonly manageLang = inject(ManageLang);
   private readonly assetTemplateFieldService = inject(AssetTemplateFieldService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.user = this.manageUser.getCurrentUser();

      this.path = this.location.path();

      this.defaultTemplate = {
         locationID: 0,
         templateID: 0,
         userID: this.user?.gUserID,
         name: "Default template",
         options: {
            aspectRatio: "8.5 x 11",
            gridRows: 4,
            gridCols: 2,
            topPadding: 20,
            bottomPadding: 20,
            sidePadding: 20,
            verticalGap: 20,
            horizontalGap: 20,
            offsetStart: 1,
            includeSubmitWorkRequest: true,
            includeAssetLink: true,
            includeStartTask: true,
            includeFlipPMSchedule: true,
            fontSize: "Auto",
            printerType: "standard",
         },
         assetFields: [],
         partFields: [],
         taskFields: [],
      };

      this.templateBelongsToUser = true;

      this.buildDataArray = Array.from(this.data());

      if (Array.isArray(this.data())) {
         // Tasks type data won't be a Lookup
         this.locationID = this.data()[0]?.locationID;
      } else {
         const [firstItemID] = this.data().keys();
         this.locationID = (this.data() as Lookup<string, any>).get(
            firstItemID,
         ).locationID;
      }
      this.currentTemplate = this.defaultTemplate;

      this.templatesPromise = this.getSavedTemplates();
   }

   public ngAfterViewInit() {
      if (!this.templatesPromise) {
         throw new Error("saved templates promise not created");
      }
      this.templatesPromise.then((buildPreviewNow: boolean) => {
         this.buildData();
         this.setItemsPerPage();
         this.buildAvailableFields();
         this.syncAvailableFields(this.currentTemplate);
         this.updateDisplayFields();
         if (buildPreviewNow) {
            this.buildPreview();
         }
      });
   }

   /****************************
    *function buildData
    *builds out a displayData object usable by the child component to render information to the DOM.  All of the types of data (assets, parts, tasks) are similar enough to map to the same type of object
    *@name buildData
    ***************************/
   buildData = () => {
      //empty out current display data
      this.displayData = [];

      this.buildDataArray.forEach((item) => {
         if (this.dataType() === "task") {
            const task: Task = item;
            let assetName = "";
            if (task.assetID) {
               assetName = this.manageAsset.getAsset(task.assetID)?.assetName ?? "";
            }
            const host = `${window.location.protocol}//${window.location.host}`;
            const fields = [
               {
                  fieldName: "Asset",
                  valueContent: assetName,
                  fieldTypeID: null,
               },
            ];
            if (this.currentTemplate.options.includeStartTask) {
               this.displayData.push({
                  title: this.lang().StartTask,
                  name: task.checklistName,
                  URL: `${host}/startTaskTemplate/${task.checklistID}/${task.assetID}?m=true`,
                  type: "taskStartTask",
                  fields: fields,
               });
            }
            if (this.currentTemplate.options.includeFlipPMSchedule) {
               this.displayData.push({
                  title: this.lang().EnableOrDisablePMSchedule,
                  name: task.checklistName,
                  URL: `${host}/flipPMSchedule/${task.checklistID}?m=true`,
                  type: "taskFlipPMSchedule",
                  fields: fields,
               });
            }
         }
         if (this.dataType() === "asset") {
            const asset: Asset & { lookupAssetURL?: string; reportProblemURL?: string } =
               item;
            const fields: any = asset.assetValueIDs.map((valueID) => {
               const value = this.manageAsset.getFieldValue(valueID);
               assert(value);
               const field = this.manageAsset.getField(value?.fieldID);
               assert(field);
               return {
                  fieldName: field.fieldName,
                  valueContent: value.valueContent,
                  fieldTypeID: field.fieldTypeID,
               };
            });

            const location = this.manageLocation.getLocation(asset.locationID);
            assert(location, "Location not found");
            const locationName = location.locationName;

            const host = `${window.location.protocol}//${window.location.host}`;
            const barcode = this.manageUser.getCurrentUser().userInfo.customerBarcodeStr;

            if (this.currentTemplate.options.includeSubmitWorkRequest) {
               this.displayData.push({
                  title: this.lang().ReportAProblem,
                  name: item.assetName,
                  URL: `${host}/problem/${barcode}/${asset.locationID}/${asset.assetID}`,
                  type: "assetSubmitWorkRequest",
                  fields: [
                     ...fields,
                     {
                        fieldName: "Location",
                        valueContent: locationName,
                        fieldTypeID: null,
                     },
                  ],
               });
            }
            if (this.currentTemplate.options.includeAssetLink) {
               this.displayData.push({
                  title: this.lang().LookUpAsset,
                  name: item.assetName,
                  URL: `${host}/mobileAssets/${asset.assetID}/${asset.locationID}?m=true`,
                  type: "assetInfo",
                  fields: [
                     ...fields,
                     {
                        fieldName: "Location",
                        valueContent: locationName,
                        fieldTypeID: null,
                     },
                  ],
               });
            }
         }
         if (this.dataType() === "part") {
            const part: Part & { lookupPartURL?: string } = item;
            //build out the fields to display so they match up with the 'displayFields' object that controls what fields are displayed
            let fields: any = part.partValueIDs.map((valueID) => {
               const value = this.manageParts.getFieldValue(valueID);
               assert(value);
               const field = this.manageParts.getField(value?.fieldID);
               assert(field);
               return {
                  fieldName: field.fieldName,
                  valueContent: value.valueContent,
                  fieldTypeID: field.fieldTypeID,
               };
            });

            const categoryName = this.manageParts.getCategory(
               part.categoryID,
            )?.categoryName;
            const associatedAssets = part.partAssetRelationIDs
               .map((relationID) => {
                  const assetID = this.manageParts.getAssetRelation(relationID)?.assetID;
                  if (!assetID) return null;

                  const asset = this.manageAsset.getAsset(assetID);
                  if (asset && asset.assetDeleted === 0) {
                     return asset;
                  }

                  return null;
               })
               .filter((asset) => asset !== null);

            const associatedVendors = part.partVendorIDs.map((vendorID) =>
               this.manageVendor.getVendor(vendorID),
            );
            const location = this.manageLocation.getLocation(part.locationID);
            assert(location, "Location not found");
            const locationName = location.locationName;

            fields = [
               ...fields,
               {
                  fieldName: "Part Number",
                  valueContent: part.partNumber,
                  fieldTypeID: null,
               },
               {
                  fieldName: "Part Supplier",
                  valueContent: part.partSupplier,
                  fieldTypeID: null,
               },
               {
                  fieldName: "Part Category",
                  valueContent: categoryName,
                  fieldTypeID: null,
               },
               {
                  fieldName: "Assets",
                  valueContent: associatedAssets,
                  fieldTypeID: "assetsArray",
               },
               {
                  fieldName: "Vendors",
                  valueContent: associatedVendors,
                  fieldTypeID: "vendorsArray",
               },
               {
                  fieldName: "Location Name",
                  valueContent: locationName,
                  fieldTypeID: null,
               },
               {
                  fieldName: "Part Location",
                  valueContent: part.partLocation,
                  fieldTypeID: null,
               },
            ];
            const host = `${window.location.protocol}//${window.location.host}`;
            this.displayData.push({
               title: null,
               message: null,
               name: part.partName,
               URL: `${host}/mobilePart/${part.partID}/${part.locationID}?m=true`,
               type: null,
               fields: fields,
            });
         }
      });

      this.syncAvailableFields(this.currentTemplate);

      //run setOffset() at end with appropriate data array
      this.setOffset();
   };

   /****************************
    *function updateQRItems
    *handles the addition of QR code data from the appropriate data source, opening a modal and then passing the data from that modal to the buildDataArray
    *@name updateQRItems
    *@return
    * @param flag
    ***************************/
   public async updateQRItems(flag?: string): Promise<void> {
      if (flag === "clear") {
         this.buildDataArray = [];
         this.buildData();
         this.setItemsPerPage();
         this.buildAvailableFields();
         this.buildPdfDisabled = true;
         return;
      }
      if (this.dataType() === "part") {
         const [firstItemID] = this.data().keys();
         const locationID = (this.data() as Lookup<string, any>).get(
            firstItemID,
         ).locationID;

         const modalData: AddPartModalData = {
            message: this.lang().PickPartsQRCodeMsg,
            title: this.lang().PickParts,
            buttonText: this.lang().Select,
            singleLocation: locationID,
         };

         const parts = await this.partsFacadeService.openAddPartModal(modalData);

         if (parts.length) {
            this.buildPdfDisabled = false;
            //add the data to the array to build the display from and build the display
            this.buildDataArray = [...this.buildDataArray, ...parts];
            this.buildData();
         }
      }

      if (this.dataType() === "asset") {
         const modalRef = this.modalService.open(PickAssets);
         const instance = modalRef.componentInstance;
         const [firstItemID] = this.data().keys();
         const locationID = (this.data() as Lookup<string, any>).get(
            firstItemID,
         ).locationID;
         instance.message = this.lang().PickAssetsQRCodeMsg;
         instance.title = this.lang().PickAssets;
         instance.singleLocation = locationID;
         instance.selectOne = false;
         instance.restrictToCred = true;
         instance.iDontKnowOption = false;

         modalRef.result.then((assets) => {
            if (assets.length) {
               const host = `${window.location.protocol}//${window.location.host}`;
               //assets come back from pickAssets with only a bit of data, so here we get the full object for each
               const fullAssetArray = assets.map((item) => {
                  const fullAsset:
                     | (Asset & { reportProblemURL?: string; lookupAssetURL?: string })
                     | undefined = this.manageAsset.getAsset(Number(item.assetID));
                  assert(fullAsset);
                  //build the QR code URLs
                  const barcode =
                     this.manageUser.getCurrentUser().userInfo.customerBarcodeStr;
                  fullAsset.reportProblemURL = `${host}/problem/${barcode}/${fullAsset.locationID}/${fullAsset.assetID}`;
                  fullAsset.lookupAssetURL = `${host}/mobileAssets/${fullAsset.assetID}/${fullAsset.locationID}`;
                  return fullAsset;
               });
               this.buildPdfDisabled = false;
               //add the data to the array to build the display from and build the display
               this.buildDataArray = [...this.buildDataArray, ...fullAssetArray];
               this.buildData();
            }
         });
      }
      if (this.dataType() === "task" && flag && flag === "multipleTemplates") {
         const tasks: TaskLookup = this.manageTask
            .getTasks()
            .filter((task) => task.locationID === Number(this.data[0].locationID));
         const isJitTemplatesDesktopEnabled = await this.launchFlagsService.isEnabled(
            Flags.JIT_TEMPLATES_DESKTOP,
         );
         let pickedTemplates;
         if (isJitTemplatesDesktopEnabled) {
            const modalRef = this.modalService.open(PickTasksTemplateView);
            modalRef.componentInstance.message = "";
            modalRef.componentInstance.title = this.lang().PickTemplates;
            modalRef.componentInstance.locationIDs = this.manageLocation
               .getLocations()
               .map((location) => location.locationID);
            modalRef.componentInstance.singleSelection = false;
            const pickedTemplateIDs = (await modalRef.result) ?? new Set<number>();
            pickedTemplates = [];

            for await (const template of this.taskTemplatesApi.getStreamedList({
               filters: { taskIDs: Array.from(pickedTemplateIDs) },
               sort: "checklistName",
            })) {
               pickedTemplates.push(template);
            }
         } else {
            const instance = this.modalService.open(PickTasksTemplateViewLegacy);
            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: "",
                  title: this.lang().PickTemplates,
                  tasks: tasks,
                  data: { selectOne: false },
               },
            };
            pickedTemplates = await instance.result;
         }

         this.buildPdfDisabled = false;
         //add the data to the array to build the display from and build the display
         this.buildDataArray = [...this.buildDataArray, ...pickedTemplates];
         this.buildData();
      }
      if (this.dataType() === "task" && flag && flag === "multipleAssets") {
         const modalRef = this.modalService.open(PickAssets);
         const instance = modalRef.componentInstance;
         instance.message = "";
         instance.title = this.lang().PickAssets;
         instance.singleLocation = 0;
         instance.selectOne = false;
         instance.restrictToCred = true;
         instance.iDontKnowOption = false;

         modalRef.result.then((assets) => {
            if (assets.length > 0) {
               const newTasks: Array<Task> = [];
               assets.forEach((asset) => {
                  //make a copy of the task
                  this.data().forEach((task) => {
                     if (!task.dummy) {
                        const taskCopy = { ...task };

                        taskCopy.assetNameStr = asset.assetName;
                        newTasks.push(taskCopy);
                     }
                  });
               });
               this.buildPdfDisabled = false;
               //add the data to the array to build the display from and build the display
               this.buildDataArray = [...this.buildDataArray, ...newTasks];
               this.buildData();
            }
         });
      }

      this.setItemsPerPage();
      this.buildAvailableFields();
   }

   //
   //Functions for setting template styles
   //

   /****************************
    *function setPrinterTypeOptions
    *handles switching between dymo and standard printer modes, setting relevant settings
    *@name setPrinterTypeOptions
    *@param type (string)
    *@return
    ***************************/
   setPrinterTypeOptions = (type) => {
      switch (type) {
         case "dymo":
            this.currentTemplate.options.aspectRatio = this.dymoSizes[0];
            this.currentTemplate.options.gridCols = 1;
            this.currentTemplate.options.gridRows = 1;
            this.currentTemplate.options.verticalGap = 0;
            this.currentTemplate.options.horizontalGap = 0;
            this.currentTemplate.options.offsetStart = 1;
            break;
         case "standard":
            this.currentTemplate.options.aspectRatio = this.paperSizes[0];
            this.rotate90 = false;
            break;
         default:
            console.error("error in setPrinterTypeOptions");
      }
      this.setItemsPerPage();
      this.buildPreview();
      this.ref.detectChanges();
   };

   /****************************
    *function setItemsPerPage
    *handles logic for when the items per page might change
    *@name setItemsPerPage
    *@param
    *@return
    ***************************/
   setItemsPerPage = () => {
      this.itemsPerPage =
         this.currentTemplate.options.gridCols * this.currentTemplate.options.gridRows;
      if (this.itemsPerPage >= this.displayData.length) {
         this.page = 1;
      }
   };

   /****************************
    *function setDefaultFontSize
    *handles logic for setting default font size
    *@name setDefaultFontSize
    *@param
    *@return
    ***************************/
   setDefaultFontSize = () => {
      if (!this.currentTemplate.options.fontSize) {
         this.currentTemplate.options.fontSize = "Auto";
      }
   };

   /****************************
    *function setAspectRatio
    *sets dimensions of the page size and the orientation of the page for a given element
    *@name setAspectRatio
    *@param size A string that represents the dimensions selected
    *@param element The element whose dimensions are going to be altered
    *@return
    ***************************/
   setAspectRatio = (size, element) => {
      assert(this.printPreviewContainer !== undefined);
      this.currentTemplate.options.aspectRatio = size;
      this.printContainerWidth = this.printPreviewContainer.nativeElement.offsetWidth;
      switch (size) {
         case "8.5 x 11":
            this.printContainerHeight = this.printContainerWidth / (8.5 / 11);
            this.rotate90 = false;
            break;
         case "8.5 x 14":
            this.printContainerHeight = this.printContainerWidth / (8.5 / 14);
            this.rotate90 = false;
            break;
         case "11 x 17":
            this.printContainerHeight = this.printContainerWidth / (11 / 17);
            this.rotate90 = false;
            break;
         case "1 1/8 x 3 1/2":
            // made landscape
            this.printContainerHeight = this.printContainerWidth / (3.5 / 1.125);
            this.rotate90 = false;
            break;
         case "2 x 1 1/4":
            //flip 90!
            this.printContainerHeight = this.printContainerWidth / (1.25 / 2);

            this.rotate90 = true;
            break;
         case "2 1/8 x 4":
            //made landscape
            this.printContainerHeight = this.printContainerWidth / (4 / 2.125);
            this.rotate90 = false;

            break;
         case "1 x 2 1/8":
            //landscape
            this.printContainerHeight = this.printContainerWidth / 2.125;
            this.rotate90 = false;
            break;
         case "1 x 3 1/2":
            //landscape
            this.printContainerHeight = this.printContainerWidth / 3.5;
            this.rotate90 = false;
            break;
         case "2-5/16 x 4":
            //landscape
            this.printContainerHeight = this.printContainerWidth / (4 / 2.3125);
            this.rotate90 = false;
            break;
         case "2-5/16 x 7-1/2":
            //landscape
            this.printContainerHeight = this.printContainerWidth / (7.5 / 2.3125);
            this.rotate90 = false;
            break;
         case "4-1/16 x 6-1/4":
            //landscape
            this.printContainerHeight = this.printContainerWidth / (6.25 / 4.0625);
            this.rotate90 = false;
            break;
         case "custom":
            this.rotate90 =
               this.currentTemplate.options.customWidth >
               this.currentTemplate.options.customHeight;

            if (!this.currentTemplate.options.customWidth) {
               this.currentTemplate.options.customWidth = 1;
            }
            if (!this.currentTemplate.options.customHeight) {
               this.currentTemplate.options.customHeight = 1;
            }

            this.currentTemplate.options.customSizeLabel = `${this.currentTemplate.options.customWidth} x ${this.currentTemplate.options.customHeight} (${this.lang().Custom})`;

            this.printContainerHeight =
               this.printContainerWidth /
               (this.currentTemplate.options.customHeight /
                  this.currentTemplate.options.customWidth);

            break;
         default:
            console.error("error in setAspectRatio");
      }
      this.renderer.setStyle(element, "min-height", `${this.printContainerHeight}px`);
      this.setCodeItemSize();
   };

   /****************************
    *function setOffset
    *adds dummy elements to the data the QR codes are built off of in order to offset where the actual child elements are rendered
    *@name setOffset
    ***************************/
   setOffset = () => {
      //filter out any dummy items on display data array
      this.displayData = this.displayData.filter((item) => !item.dummy);

      //sets the number of empty spots there should be before the first QR code appears on the preview page
      const blankArray: any = [];
      for (let index = 1; index < this.currentTemplate.options.offsetStart; index++) {
         blankArray.push({ dummy: true, displayIndex: index - 1 });
      }
      this.displayData = [...blankArray, ...this.displayData];

      const pages = Math.ceil(this.displayData.length / this.itemsPerPage) || 1;
      this.extraElementsToAdd = pages * this.itemsPerPage - this.displayData.length;

      const trailingDummyElements: any = [];

      for (let index = 0; index < this.extraElementsToAdd; index++) {
         trailingDummyElements.push({
            dummy: true,
            displayIndex: this.displayData.length + index,
         });
      }
      this.displayData = [...this.displayData, ...trailingDummyElements];
   };

   //
   // Functions for rendering preview and print element
   //

   /****************************
    *function buildPreview
    *Collection of functions to call to render the preview
    *@name buildPreview
    ***************************/
   buildPreview = () => {
      assert(this.printPreviewContainer !== undefined);
      this.setAspectRatio(
         this.currentTemplate.options.aspectRatio,
         this.printPreviewContainer.nativeElement,
      );
      this.updateGridTemplate("all", this.printPreviewContainer.nativeElement);
      this.setOffset();
   };

   /****************************
    *function setCodeItemSize
    *Computes the size of an individual QR code item to be passed to the child qrCodeItem component
    *@name setCodeItemSize
    ***************************/
   setCodeItemSize = () => {
      if (!this.currentTemplate.options.sidePadding) {
         this.currentTemplate.options.sidePadding = this.currentTemplate.options.padding;
      }
      if (!this.currentTemplate.options.bottomPadding) {
         this.currentTemplate.options.bottomPadding =
            this.currentTemplate.options.padding;
      }
      if (!this.currentTemplate.options.topPadding) {
         this.currentTemplate.options.topPadding = this.currentTemplate.options.padding;
      }
      this.codeItemWidth =
         (this.printContainerWidth -
            this.currentTemplate.options.sidePadding * 2 -
            this.currentTemplate.options.verticalGap *
               (this.currentTemplate.options.gridCols - 1)) /
         this.currentTemplate.options.gridCols;

      this.codeItemHeight =
         (this.printContainerHeight -
            this.currentTemplate.options.topPadding -
            this.currentTemplate.options.bottomPadding -
            this.currentTemplate.options.horizontalGap *
               (this.currentTemplate.options.gridRows - 1)) /
         this.currentTemplate.options.gridRows;
   };

   /****************************
    *function updateGridTemplate
    *renders an element with updated settings according to a string passed in representing the style name or 'all' for all the available settings
    *@name updateGridTemplate
    *@param styleName a string representing the name of the style to be set
    *@param element the element to have changes made to
    ***************************/
   updateGridTemplate = (styleName, element) => {
      if (!this.currentTemplate.options.sidePadding) {
         this.currentTemplate.options.sidePadding = this.currentTemplate.options.padding;
      }
      if (!this.currentTemplate.options.bottomPadding) {
         this.currentTemplate.options.bottomPadding =
            this.currentTemplate.options.padding;
      }
      if (!this.currentTemplate.options.topPadding) {
         this.currentTemplate.options.topPadding = this.currentTemplate.options.padding;
      }
      switch (styleName) {
         case "grid-template-rows":
            this.setItemsPerPage();
            this.setOffset();
            this.renderer.setStyle(
               element,
               styleName,
               "1fr ".repeat(this.currentTemplate.options.gridRows),
            );
            break;
         case "grid-template-columns":
            this.setItemsPerPage();
            this.setOffset();
            this.renderer.setStyle(
               element,
               styleName,
               "1fr ".repeat(this.currentTemplate.options.gridCols),
            );
            break;
         case "row-gap":
            this.renderer.setStyle(
               element,
               styleName,
               `${this.currentTemplate.options.horizontalGap}px`,
            );
            break;
         case "column-gap":
            this.renderer.setStyle(
               element,
               styleName,
               `${this.currentTemplate.options.verticalGap}px`,
            );
            break;
         case "padding":
            this.renderer.setStyle(
               element,
               styleName,
               `${this.currentTemplate.options.topPadding || 0}px ${
                  this.currentTemplate.options.sidePadding || 0
               }px ${this.currentTemplate.options.bottomPadding || 0}px`,
            );
            break;
         case "all":
            this.renderer.setStyle(
               element,
               "grid-template-rows",
               "1fr ".repeat(this.currentTemplate.options.gridRows),
            );
            this.renderer.setStyle(
               element,
               "grid-template-columns",
               "1fr ".repeat(this.currentTemplate.options.gridCols),
            );
            this.renderer.setStyle(
               element,
               "gap",
               `${this.currentTemplate.options.horizontalGap}px ${this.currentTemplate.options.verticalGap}px`,
            );
            this.renderer.setStyle(
               element,
               "padding",
               `${this.currentTemplate.options.topPadding || 0}px ${
                  this.currentTemplate.options.sidePadding || 0
               }px ${this.currentTemplate.options.bottomPadding || 0}px`,
            );
            break;
         default:
            console.error("error, hit default");
      }
      this.setCodeItemSize();
      this.setItemsPerPage();
   };

   /****************************
    *function buildPrintGroupArray
    *puts QR codes into pages and then pages into batches.  This was necessary to speed up the process of building the PDF using html2canvas
    *@name buildPrintGroupArray
    ***************************/
   private buildPrintGroupArray() {
      //first build out the pages
      const printPagesArray: any = [];
      this.pages = Math.ceil(this.displayData.length / this.itemsPerPage);
      for (let pageNumber = 1; pageNumber <= this.pages; pageNumber++) {
         printPagesArray.push(
            this.displayData.slice(
               (pageNumber - 1) * this.itemsPerPage,
               pageNumber * this.itemsPerPage,
            ),
         );
      }
      //Next, slice the pages into groups, starting with a blank array each time
      const groups = Math.ceil(printPagesArray.length / this.pagesPerBatch);
      this.printGroupArray = [];
      for (let groupNumber = 1; groupNumber <= groups; groupNumber++) {
         this.printGroupArray.push(
            printPagesArray.slice(
               (groupNumber - 1) * this.pagesPerBatch,
               groupNumber * this.pagesPerBatch,
            ),
         );
      }
   }

   //
   // Print functions
   //

   /****************************
    *function handleAction
    *Displays an alert for the native app
    *For mobile browsers creates iframe and prints it
    *Builds a hidden element to build the pdf or iframe,
    * loops through the nested element structure to build images of the pages of QR codes, builds a pdf from them, and prompts a download of the pdf
    *@name handleAction
    *@param flag describes whether the function should prompt a download or a print. Choose between 'print' and 'download' as a string
    ***************************/
   public async handleAction(flag: string) {
      if (flag === "print" && this.isNativeMobileApp()) {
         this.alertService.addAlert(
            this.lang().PrintingNotSupportedOnMobile,
            "warning",
            8000,
         );
         return;
      }

      const totalNumberOfPages = Math.ceil(this.displayData.length / this.itemsPerPage);
      if (totalNumberOfPages > 60) {
         const instance = this.modalService.open(Confirm);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().TooManyPagesOfQRCodesMessage,
               title: this.lang().AreYouSure,
            },
         };

         const result = await instance.result;
         if (!result) {
            return;
         }
      }

      this.loadingBarService.show({
         header: this.getMessage(
            0,
            Math.ceil(this.displayData.length / this.itemsPerPage),
         ),
      });

      this.printing = true;

      this.buildPrintGroupArray();

      // Wait for QR codes to appear on the UI
      await new Promise((resolve) => {
         setTimeout(resolve, 3000);
      });

      //set selected styles for the pages within the pageGroupElement
      this.printDivComponents?.forEach((item) => {
         this.setAspectRatio(
            this.currentTemplate.options.aspectRatio,
            (item as any).nativeElement,
         );
         this.updateGridTemplate("all", (item as any).nativeElement);
      });

      // handle printing separately with a lightweight method suitable for mobile devices.
      if (flag === "print" && this.isMobileBrowser()) {
         this.printInMobileBrowser();
         return;
      }

      //Create an array of images of groups of 5 pages
      const canvasImgArray = await this.buildPrintImageArray();

      //Initialize the pdf
      const { setHeight, orientation, setWidth } = this.getPrintingParams();
      const doc = new Pdf(orientation, "px", [setWidth, setHeight]);

      //Variables to control the end of the loop through the array of images
      const totalPages = Math.ceil(this.displayData.length / this.itemsPerPage);
      let pageCount = 1;
      const lastImagePages = this.printGroupArray[this.printGroupArray.length - 1].length;

      //Loop through the array of images
      canvasImgArray.forEach((canvas, index) => {
         const isLastImage = index === canvasImgArray.length - 1;
         let position = 0;

         //loop through each canvas image and add a page to the pdf for each page within the collection as a slice of the image at the appropriate position
         for (
            let pageWithinGroup = 1;
            pageWithinGroup <= this.pagesPerBatch;
            pageWithinGroup++
         ) {
            //if we've reached the end of the pages, break the loop so no blank pages are created
            if (pageCount > totalPages) {
               break;
            }

            //if the loop is past the first page of the first group, start adding pages
            if ((index === 0 && pageWithinGroup > 1) || index > 0) {
               doc.addPage();
            }

            doc.addImage(
               canvas,
               "jpeg",
               0,
               position,
               setWidth,
               isLastImage ? setHeight * lastImagePages : setHeight * this.pagesPerBatch,
            );
            //Iterate the position and pageCount variables to make sure the next image is added at the right spot and no blank pages are added
            position += setHeight * -1;
            pageCount++;
         }
      });

      setTimeout(() => {
         if (flag === "download") {
            doc.save(`${this.path.slice(2)}.pdf`);
         } else if (flag === "print") {
            doc.autoPrint();
            this.hiddFrame = document.createElement("iframe");
            this.hiddFrame.style.position = "fixed";
            /* "visibility: hidden" would trigger safety rules in some browsers like safari，
            in which the iframe display in a pretty small size instead of hidden.
            here is some little hack ~ */
            this.hiddFrame.style.width = "1px";
            this.hiddFrame.style.height = "1px";
            this.hiddFrame.style.opacity = "0.01";
            const isSafari = /^((?!chrome|android).)*safari/i.test(
               window.navigator.userAgent,
            );
            if (isSafari) {
               // fallback in safari
               this.hiddFrame.onload = () => {
                  if (this.hiddFrame?.contentWindow) {
                     try {
                        this.hiddFrame.contentWindow.document.execCommand(
                           "print",
                           false,
                           undefined,
                        );
                     } catch (err) {
                        this.hiddFrame.contentWindow.print();
                     }
                  }
               };
            }
            this.hiddFrame.src = doc.output("bloburl");
            document.body.appendChild(this.hiddFrame);
         }
         this.returnView();
         window.addEventListener("focus", this.removeIframe);
         this.itemsProcessed = 0;
      }, 0);
   }

   public printInMobileBrowser() {
      const printingElement = document.getElementById("printingQRCodes");
      const inlineStyles = this.extractStylesFromElement(printingElement);
      const printContents = printingElement?.innerHTML;
      const html = `
            <html lang="en">
               <head>
                  <title>${this.lang().QRCodeCustomizePrint}</title>
                  <style>
                     .print-preview-container,
                     .print-container {
                         display: grid;
                         align-items: center;
                     }
                     .qr-code-container {
                         border: 1px solid #b3b3b3;
                         border-radius: 4px;
                         padding: 10px;
                         display: flex;
                         justify-content: flex-start;
                         align-items: center;
                         text-align: center;
                         box-sizing: border-box;
                     }
                     .data-container-record {
                         padding-left: 8px;
                         width: 100%;
                         display: flex;
                         flex-direction: column;
                         align-items: flex-start;
                         row-gap: 8px;
                     }
                     .value-title,
                      .value-title {
                        font-weight: 700;
                        color: #000000;
                     }
                     ${inlineStyles}
                  </style>
               </head>
               <body>
                  ${printContents}
               </body>
            </html>`;

      const { setWidth } = this.getPrintingParams();
      const iframe = document.createElement("iframe");
      const timestamp = new Date().getTime();
      iframe.name = `print-${timestamp}`;
      iframe.width = `${setWidth}`;
      iframe.srcdoc = html;

      document.body.appendChild(iframe);
      window.frames[`print-${timestamp}`].focus();

      setTimeout(() => {
         window.frames[`print-${timestamp}`].print();
         this.returnView();
      }, 1000);

      window.frames[`print-${timestamp}`].addEventListener("afterprint", () => {
         iframe.remove();
      });
   }

   private extractStylesFromElement(element: HTMLElement | null): string {
      let styles = "";
      if (!element) return "";

      // Extract styles from style tags within the element
      const styleTags = element.getElementsByTagName("style");
      for (const styleTag of Array.from(styleTags)) {
         styles += styleTag.innerHTML;
      }

      // Extract inline styles from the element and its children
      const collectInlineStyles = (el: HTMLElement) => {
         if (el.hasAttribute("style")) {
            const cssText = el.getAttribute("style");
            const uniqueClass = `print-style-${Math.random().toString(36).substring(2, 15)}`;
            el.classList.add(uniqueClass);
            styles += `
          .${uniqueClass} {
            ${cssText}
          }
        `;
         }
         for (const child of Array.from(el.children)) {
            collectInlineStyles(child as HTMLElement);
         }
      };

      collectInlineStyles(element);

      return styles;
   }

   private getPrintingParams(): PrintingParams {
      const params: PrintingParams = {
         orientation: "p",
         setHeight: 0,
         setWidth: 0,
      };

      if ((this.printGroupComponents?.first as any)?.nativeElement) {
         params.setWidth =
            (this.printGroupComponents?.first as any).nativeElement.offsetWidth * 0.475; // 0.475 is the scale factor for the image, don't know why but chrome is different in printer view than it is in pdf view.  This is a hack to make it work in both.,
      }

      switch (this.currentTemplate.options.aspectRatio) {
         case "8.5 x 11":
            params.orientation = "p";
            params.setHeight = params.setWidth / (8.5 / 11);
            break;
         case "8.5 x 14":
            params.orientation = "p";
            params.setHeight = params.setWidth / (8.5 / 14);
            break;
         case "11 x 17":
            params.orientation = "p";
            params.setHeight = params.setWidth / (11 / 17);
            break;
         case "1 1/8 x 3 1/2":
            params.orientation = "l";
            params.setHeight = params.setWidth / (3.5 / 1.125);
            break;
         case "2 x 1 1/4":
            params.orientation = "p";
            params.setHeight = params.setWidth / (1.25 / 2);
            break;
         case "2 1/8 x 4":
            params.orientation = "l";
            params.setHeight = params.setWidth / (4 / 2.125);
            break;
         case "1 x 2 1/8":
            params.orientation = "l";
            params.setHeight = params.setWidth / 2.125;
            break;
         case "1 x 3 1/2":
            params.orientation = "l";
            params.setHeight = params.setWidth / 3.5;
            break;
         case "2-5/16 x 4":
            params.orientation = "l";
            params.setHeight = params.setWidth / (4 / 2.3125);
            break;
         case "2-5/16 x 7-1/2":
            params.orientation = "l";
            params.setHeight = params.setWidth / (7.5 / 2.3125);
            break;
         case "4-1/16 x 6-1/4":
            params.orientation = "l";
            params.setHeight = params.setWidth / (6.25 / 4.0625);
            break;
         case "custom":
            params.orientation = this.rotate90 ? "p" : "l";
            params.setHeight =
               params.setWidth /
               (this.currentTemplate.options.customHeight /
                  this.currentTemplate.options.customWidth);
            break;
         default:
            console.error("error in setHeight switch statement in qrPrintPDF");
      }
      return params;
   }

   getMessage = (processed: number, totalToProcess: number) => {
      return `
         <b>
            ${this.lang().PerformingRiteOfPercussiveMaintenance}...
         </b>
         <br/>
         <span>
            ${this.lang().ThisMayTakeAFewMinutes}
         </span>
         <br /> <br />
         ${processed}
         ${this.lang().ofStr}
         ${totalToProcess} 
         ${this.lang().completed}`;
   };

   removeIframe = () => {
      if (this.hiddFrame) {
         this.hiddFrame.remove();
      }
      window.removeEventListener("focus", this.removeIframe);
   };

   buildPrintImageArray = async () => {
      let canvasImgArray;
      if (this.printGroupComponents) {
         canvasImgArray = this.printGroupComponents.map(async (group) => {
            const printImage = await this.buildPrintImage(group);
            return printImage;
         });
      }
      return Promise.all(canvasImgArray);
   };

   /**
    * creates canvases for groups of pages of qr codes and returns the encoded image
    * @param pageGroup path from URL for pdf file name
    * @returns encoded image string
    */
   private async buildPrintImage(pageGroup): Promise<string> {
      const pageGroupElement = pageGroup.nativeElement;

      //create a canvas for each group of pages
      const aCanvas = await html2canvas(pageGroupElement, {
         scale: 3,
         scrollX: 0,
         scrollY: 0,
         // The app sometimes shows many icons, causing the issue linked below.
         // So we ignore most of the app when we copy the document.
         // https://github.com/niklasvh/html2canvas/issues/2495
         ignoreElements: (element) => element.id === "app-wrapper",
      });
      const totalPages = Math.ceil(this.displayData.length / this.itemsPerPage);

      if (this.displayData.length < this.itemsPerPage) {
         this.itemsProcessed += 1;
      } else if (this.itemsProcessed + this.pagesPerBatch > totalPages) {
         this.itemsProcessed = totalPages;
      } else {
         this.itemsProcessed += this.pagesPerBatch;
      }
      this.loadingBarService.show({
         header: this.getMessage(
            this.itemsProcessed,
            Math.ceil(this.displayData.length / this.itemsPerPage),
         ),
      });
      //return the encoded image
      return aCanvas.toDataURL("image/jpeg", 1);
   }

   /****************************
    *function returnView
    *returns the view after it was hidden in the qrPrintPDF function
    *@name returnView
    ***************************/
   private returnView() {
      this.printing = false;
      this.loadingBarService.remove();
   }

   //
   // Information field functions
   //

   /****************************
    *function buildAvailableFields
    *pulls the available fields for the given type of data and filters them to the location of the item originally passed into the qrCodesCustomizePrint modal
    *@name buildAvailableFields
    ***************************/
   buildAvailableFields = () => {
      //add in default fields
      const defaultAssetFields = [
         {
            fieldName: "Location",
            fieldID: "Location",
            show: this.currentTemplate.assetFields.includes(
               (item) => item.fieldID === "Location",
            ),
         },
      ];
      const defaultPartFields = [
         {
            fieldName: "Part Number",
            fieldID: "Part Number",
            show: this.currentTemplate.partFields.includes(
               (item) => item.fieldID === "Part Number",
            ),
         },
         {
            fieldName: "Part Supplier",
            fieldID: "Part Supplier",
            show: this.currentTemplate.partFields.includes(
               (item) => item.fieldID === "Part Supplier",
            ),
         },
         {
            fieldName: "Part Category",
            fieldID: "Part Category",
            show: this.currentTemplate.partFields.includes(
               (item) => item.fieldID === "Part Category",
            ),
         },
         {
            fieldName: "Assets",
            fieldID: "Assets",
            show: this.currentTemplate.partFields.includes(
               (item) => item.fieldID === "Assets",
            ),
         },
         {
            fieldName: "Vendors",
            fieldID: "Vendors",
            show: this.currentTemplate.partFields.includes(
               (item) => item.fieldID === "Vendors",
            ),
         },
         {
            fieldName: "Location Name",
            fieldID: "Location Name",
            show: this.currentTemplate.partFields.includes(
               (item) => item.fieldID === "Location Name",
            ),
         },
         {
            fieldName: "Part Location",
            fieldID: "Part Location",
            show: this.currentTemplate.partFields.includes(
               (item) => item.fieldID === "Part Location",
            ),
         },
      ];
      const defaultTaskFields = [
         {
            fieldName: "Asset",
            fieldID: "Asset",
            show: this.currentTemplate.taskFields.includes(
               (item) => item.fieldID === "Asset",
            ),
         },
      ];
      switch (this.dataType()) {
         case "asset":
            this.allAssetFields = this.manageAsset
               .getFields()
               .filter((item) => Number(item.locationID) === Number(this.locationID));
            this.assetTemplateFieldService
               .getTemplateFieldsMapWithFlagChecks()
               .then((templateFields) => {
                  // Addding template fields to the list of available fields
                  const templateFieldsArray = Array.from(templateFields.values());
                  const allFields = [
                     ...templateFieldsArray,
                     ...Array.from(this.allAssetFields),
                  ];

                  this.availableFields = allFields.map((field) => {
                     const showItem = this.currentTemplate.assetFields.includes(
                        (item) => item.fieldID === field.fieldID,
                     );

                     return {
                        ...field,
                        show: showItem,
                     };
                  });

                  this.availableFields = [...defaultAssetFields, ...this.availableFields];

                  this.fieldsFilteredToSearch = this.availableFields;
               });
            break;
         case "part":
            this.allPartFields = this.manageParts
               .getFields()
               .filter((item) => Number(item.locationID) === Number(this.locationID));
            this.availableFields = Array.from(this.allPartFields).map((field) => {
               const showItem = this.currentTemplate.partFields.includes(
                  (item) => item.fieldID === field.fieldID,
               );
               return {
                  ...field,
                  show: showItem,
               };
            });

            this.availableFields = [...defaultPartFields, ...this.availableFields];
            this.fieldsFilteredToSearch = this.availableFields;
            break;
         case "task":
            this.availableFields = defaultTaskFields;
            this.fieldsFilteredToSearch = this.availableFields;
            break;
         default:
            console.error("error in buildAvailableFields");
      }
   };

   /****************************
    *function searchUpdateFields
    *filters the available fields displayed according the the search input
    *@name searchUpdateFields
    ***************************/
   searchUpdateFields = () => {
      const filteredFields = this.availableFields.filter((item) =>
         item.fieldName
            .toLowerCase()
            .includes(this.fieldsSearchValue.toLowerCase().trim()),
      );
      this.fieldsFilteredToSearch = filteredFields;
   };

   /****************************
    *function updateDisplayFields
    *updates the list of fields that are displayed on qrCodeItem elements
    *@name updateDisplayFields
    ***************************/
   updateDisplayFields = () => {
      if (!this.availableFields.length) {
         return;
      }
      this.unsavedChanges = true;

      this.displayFields = this.availableFields.filter((item) => item.show);
      switch (this.dataType()) {
         case "asset":
            this.currentTemplate.assetFields = this.displayFields.map(
               (item) => item.fieldID,
            );
            break;
         case "part":
            this.currentTemplate.partFields = this.displayFields.map(
               (item) => item.fieldID,
            );
            break;
         case "task":
            this.currentTemplate.taskFields = this.displayFields.map(
               (item) => item.fieldID,
            );
            break;
         default:
            console.error("error in updateDisplayFields");
      }
   };

   /****************************
    *function syncAvailableFields
    *updates the state of the available fields (checked/unchecked) on load of a saved template
    *@name syncAvailableFields
    *@param template a saved template object
    ***************************/
   syncAvailableFields = (template) => {
      this.availableFields.forEach((field) => {
         switch (this.dataType()) {
            case "asset":
               field.show = this.calculateFieldShow(template.assetFields, field);
               break;
            case "part":
               field.show = this.calculateFieldShow(template.partFields, field);
               break;
            case "task":
               field.show = this.calculateFieldShow(template.taskFields, field);
               break;
            default:
               console.error("Error in syncAvailableFields");
         }
      });
   };

   //
   // CRUD functions for saved templates
   //

   /****************************
    *function getSavedTemplates
    *pulls all the saved templates for a customer from the database
    *@name getSavedTemplates
    ***************************/
   getSavedTemplates = async () => {
      const post = await this.manageShared.getAllQRPrintTemplates(this.locationID);
      if (!post.data.success) {
         return false;
      }
      this.loadingSavedTemplates = false;
      this.savedTemplates = post.data.allTemplates;

      //apply userUIPreferences saved template id
      const lastUsedTemplateID = this.user.userInfo.userUIPreferences.qrPrintTemplateID;

      if (lastUsedTemplateID && this.savedTemplates) {
         const lastUsedTemplate = this.savedTemplates?.find(
            (template) => Number(template.templateID) === Number(lastUsedTemplateID),
         );
         if (!lastUsedTemplate) {
            this.applySavedTemplate(this.defaultTemplate);
         }
         this.applySavedTemplate(lastUsedTemplate);
         return false;
      }
      return true;
   };

   /****************************
    *function saveNewTemplate
    *creates a new row in the database for a new saved template
    *@name saveNewTemplate
    ***************************/
   saveNewTemplate = async () => {
      if (!this.currentTemplate.name.length) {
         this.alertService.addAlert(
            this.lang().PrintTemplateNameLengthError,
            "danger",
            6000,
         );
         return;
      }
      const post = await this.manageShared.createQRPrintTemplate(
         this.currentTemplate,
         this.user?.gUserID,
         this.locationID,
      );

      if (post.data.success) {
         this.user.userInfo.userUIPreferences.qrPrintTemplateID = post.data.insertedID;
         this.manageUser.updateUserUIPreferences();
         //update saved templates dropdown
         this.savedTemplates = post.data.allTemplates;

         //update state of form
         this.usingSavedTemplate = true;
         this.templateBelongsToUser = true;
         this.unsavedChanges = false;

         //apply options to currentTemplate object
         const copiedTemplateIndex = this.savedTemplates.findIndex(
            (item) => Number(item.templateID) === Number(post.data.insertedID),
         );
         this.currentTemplate = this.savedTemplates[copiedTemplateIndex];

         //update custom fields state
         this.syncAvailableFields(this.currentTemplate);

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else if (post.data.error === "duplicateName") {
         this.alertService.addAlert(
            this.lang().CustomizePrintDuplicateNameError,
            "danger",
            6000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   };

   /****************************
    *function updateTemplate
    *updates a row in the database that relates to the current template being used
    *@name updateTemplate
    ***************************/
   updateTemplate = async () => {
      if (!this.currentTemplate.name.length) {
         this.alertService.addAlert(
            this.lang().PrintTemplateNameLengthError,
            "danger",
            6000,
         );
         return;
      }

      const post = await this.manageShared.updateQRPrintTemplate(
         this.user?.gUserID,
         this.currentTemplate,
      );
      if (post.data.success) {
         //update data in savedTemplates
         this.savedTemplates = post.data.allTemplates;
         this.user.userInfo.userUIPreferences.qrPrintTemplateID =
            this.currentTemplate.templateID;
         this.manageUser.updateUserUIPreferences();
         this.unsavedChanges = false;

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else if (post.data.error === "duplicateName") {
         this.alertService.addAlert(
            this.lang().CustomizePrintDuplicateNameError,
            "danger",
            6000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   };

   /****************************
    *function clearTemplate
    *returns all the savable options to empty or the lowest possible values
    *@name clearTemplate
    ***************************/
   clearTemplate = () => {
      this.templateBelongsToUser = true;
      this.usingSavedTemplate = false;
      this.unsavedChanges = false;
      this.currentTemplate.name = "";
      this.currentTemplate = {
         templateID: 0,
         userID: 1,
         name: "",
         options: {
            aspectRatio: "8.5 x 11",
            gridRows: 4,
            gridCols: 2,
            topPadding: 20,
            bottomPadding: 20,
            sidePadding: 20,
            verticalGap: 20,
            horizontalGap: 20,
            offsetStart: 1,
            includeSubmitWorkRequest: true,
            includeAssetLink: true,
            includeStartTask: true,
            includeFlipPMSchedule: true,
            fontSize: "Auto",
            printerType: "standard",
         },
         assetFields: [],
         partFields: [],
         taskFields: [],
      };
      this.rotate90 = false;
      this.availableFields.forEach((field) => (field.show = false));
   };

   /****************************
    *function startNewTemplate
    *is a pathway to the clearTemplate function that is aware of whether changes might need to be saved on the current template opened by the user
    *@name startNewTemplate
    ***************************/
   startNewTemplate = () => {
      //if there are unsaved changes, warn the user that they will be overwritten
      if (this.templateBelongsToUser && this.unsavedChanges) {
         const instance = this.modalService.open(Confirm);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().StartNewTemplateMessage,
               title: `${this.lang().Start} ${this.lang().NewTemplate}`,
            },
         };

         instance.result.then((result) => {
            if (result == 1) {
               this.clearTemplate();
               this.buildPreview();
            }
         });
      } else {
         this.clearTemplate();
         this.buildPreview();
      }
   };

   /****************************
    *function applySavedTemplate
    *takes a template object from the saved templates and applys those settings to the view/preview
    *@name applySavedTemplate
    *@param template the object representing the template to apply
    ***************************/
   applySavedTemplate = (template) => {
      this.user.userInfo.userUIPreferences.qrPrintTemplateID = template.templateID;
      this.manageUser.updateUserUIPreferences();
      this.currentTemplate = {
         ...template,
         assetFields: [...template.assetFields],
         partFields: [...template.partFields],
         taskFields: [...template.taskFields],
      };
      this.usingSavedTemplate = true;
      this.templateBelongsToUser = Number(template.userID) === Number(this.user?.gUserID);
      const templateOwner = this.manageUser.getUser(template.userID);
      this.templateOwnerName = templateOwner
         ? templateOwner.userFirstName
         : "Another User";

      this.buildData();

      this.buildPreview();
      this.updateDisplayFields();
      this.unsavedChanges = false;
   };

   /****************************
    *function copyTemplate
    *Creates a new row in the database that represents a copy of the template the user had open and applies those settings to the view/preview
    *@name copyTemplate
    ***************************/
   copyTemplate = async () => {
      const post = await this.manageShared.copyQRPrintTemplate(
         this.currentTemplate.templateID,
         this.user?.gUserID,
      );

      if (post.data.success) {
         //update saved templates dropdown
         this.savedTemplates = post.data.allTemplates;

         //update state of form
         this.usingSavedTemplate = true;
         this.templateBelongsToUser = true;
         this.unsavedChanges = false;

         //apply copied options to currentTemplate object
         const copiedTemplateIndex = this.savedTemplates.findIndex(
            (item) => Number(item.templateID) === Number(post.data.insertedID),
         );
         this.currentTemplate = this.savedTemplates[copiedTemplateIndex];

         this.user.userInfo.userUIPreferences.qrPrintTemplateID = post.data.insertedID;
         this.manageUser.updateUserUIPreferences();

         //update custom fields state
         this.syncAvailableFields(this.currentTemplate);
         this.updateDisplayFields();
         this.buildPreview();

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else if (post.data.error === "duplicateName") {
         this.alertService.addAlert(
            this.lang().CustomizePrintDuplicateNameError,
            "danger",
            6000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   };

   /****************************
    *function deleteTemplate
    *removes the current template from the database and clears the current settings from the view
    *@name deleteTemplate
    ***************************/
   deleteTemplate = () => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeletePrintTemplatesMessage,
            title: this.lang().DeletePrintTemplatesTitle,
         },
      };

      instance.result.then(async (result) => {
         if (result == 1) {
            const post = await this.manageShared.deleteQRPrintTemplate(
               this.currentTemplate.templateID,
               this.user?.gUserID,
            );
            if (post.data.success) {
               //remove deleted template from saved templates
               const deletedTemplateIndex = this.savedTemplates.findIndex(
                  (item) => item.templateID === this.currentTemplate.templateID,
               );
               this.user.userInfo.userUIPreferences.qrPrintTemplateID = null;
               this.manageUser.updateUserUIPreferences();
               this.savedTemplates.splice(deletedTemplateIndex, 1);
               this.clearTemplate();
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         }
      });
   };

   //
   //  Misc
   //

   /****************************
    *function pageChanged
    *helper function used by ngbPagination directive to ensure changes happen in the view when the page is changed
    *@name pageChanged
    ***************************/
   pageChanged = () => {
      this.ref.detectChanges();
   };

   /****************************
    *function close
    *closes the modal window, prompting a confirm if there are potential unsaved changes to the current template
    *@name close
    ***************************/
   close = () => {
      if (this.modalRef) {
         if (this.unsavedChanges) {
            const confirmInstance = this.modalService.open(Confirm);
            this.paramsService.params = {
               modalInstance: confirmInstance,
               resolve: {
                  message: this.lang().CloseWithUnsavedChangesMessage,
                  title: this.lang().Close,
               },
            };
            confirmInstance.result.then((result) => {
               if (result == 1) {
                  this.modalRef.close(0);
               }
            });
         } else {
            this.modalRef.close(0);
         }
      }
   };

   private calculateFieldShow(templateFields: Array<any>, field: any): boolean {
      return (
         templateFields.findIndex((item) => {
            if (Number(field.fieldID)) {
               return Number(item) === Number(field.fieldID);
            }
            return item == field.fieldID;
         }) !== -1
      );
   }

   //added for tests spy on the methods
   public isNativeMobileApp(): boolean {
      return isNativeMobileApp();
   }

   public isMobileBrowser(): boolean {
      return isMobileBrowser();
   }
}
