import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { TaskNameCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/task-name-cell/task-name-cell.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   selector: "task-name-without-asset-cell",
   imports: [CommonModule, TaskNameCellComponent],
   templateUrl: "./task-name-without-asset-cell.component.html",
   styleUrls: ["./task-name-without-asset-cell.component.scss"],
})
export class TaskNameWithoutAssetCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;
   @Input() public dataLogSection!: string;

   @Input() public searchHint: string | undefined = undefined;
}
