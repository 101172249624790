export type PartField = {
   fieldID: number;
   fieldName: string;
   fieldTypeID: PartFieldType;
   locationID: number;
   viewableByTechFieldDefault: number | null;
   optionsJSON: string | null;
   dateReminder1: number | null;
   dateReminder2: number | null;
   dateReminder3: number | null;
   userID: number | null;
   profileID: number | null;
   valueUnique: number | null;
   isCustomDefault: number;
   lockedDefault: number;
};

export enum PartFieldType {
   Text = 1,
   Date = 2,
   Image = 3,
   Document = 4,
   Number = 5,
   Currency = 6,
   Dropdown = 7,
}

export type PartFieldJSON = {
   fieldName: string;
   id: number;
   includeCondition?: boolean;
   date?: string;
   displayName?: string;
   fieldTypeID?: number;
   fieldCondition?: string;
   fieldConditionDisplay?: string;
   optionsJSON?: unknown;
   partNameFilter?: boolean;
   partNumberFilter?: boolean;
   partQtyFilter?: boolean;
   partCategoryFilter?: boolean;
   partPriceFilter?: boolean;
   partLocationFilter?: boolean;
   valueContentContains?: string;
   number?: number;
};
