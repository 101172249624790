import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit, QueryList } from "@angular/core";
import {
   inject,
   Component,
   HostBinding,
   Input,
   ViewChildren,
   computed,
   signal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BadgeComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownInlineTextComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   FocusOnLoadDirective,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   ModalHeaderComponent,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   QuickSwapActiveDirective,
   QuickSwapComponent,
   QuickSwapInactiveDirective,
   SecondaryButtonComponent,
   TextButtonComponent,
   TooltipDirective,
   UpsellPopover,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import type { AxiosResponse } from "axios/dist/axios";
import { Subscription, filter } from "rxjs";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { MobileHeaderTitleService } from "src/app/mobile/headers-and-footers/mobile-header/mobile-header-title.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import { PickBudgets } from "src/app/purchasing/budgets/pickBudgetsModal/pickBudgets.modal.component";
import { PopBudget } from "src/app/purchasing/budgets/popBudgetModal/popBudget.modal.component";
import { CurrencyNamePipe } from "src/app/purchasing/currency/pipes/currency-name.pipe";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { AddPOComment } from "src/app/purchasing/pos/addPOCommentModal/addPOComment.modal.component";
import { DisapprovePO } from "src/app/purchasing/pos/disapprovePOModal/disapprovePO.modal.component";
import { PickPOItemsPartService } from "src/app/purchasing/pos/pickPOItemsPartServiceModal/pickPOItemsPartService.modal.component";
import { PickPOs } from "src/app/purchasing/pos/pickPOsModal/pickPOs.modal.component";
import { POCustomFieldSettings } from "src/app/purchasing/pos/poCustomFieldSettingsModal/poCustomFieldSettings.modal.component";
import { PoItem } from "src/app/purchasing/pos/poItemElement/poItem.element.component";
import { ReceivePurchaseOrderItemsModal } from "src/app/purchasing/pos/receive-purchase-order-items/modal/receive-purchase-order-items.modal.component";
import type { AugmentedPurchasingComment } from "src/app/purchasing/services/managePO";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { Budget } from "src/app/purchasing/types/budget.types";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrderComment } from "src/app/purchasing/types/purchase-order/purchase-order-comment.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderWorkflow } from "src/app/purchasing/types/purchase-order-workflow.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { EmailTemplate } from "src/app/shared/components/global/emailTemplateModal/emailTemplate.modal.component";
import { GatherText } from "src/app/shared/components/global/gatherTextModal/gatherText.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { DEFAULT } from "src/app/shared/utils/sortingHelpers";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "purchase-order-wrapper",
   templateUrl: "./po.wrapper.component.html",
   styleUrls: ["./po.wrapper.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      ModalHeaderComponent,
      IconComponent,
      MinimalIconButtonComponent,
      TooltipDirective,
      DropdownComponent,
      DropdownItemComponent,
      ModalBodyComponent,
      LoadingAnimationComponent,
      AlertComponent,
      LimbleHtmlDirective,
      PanelComponent,
      QuickSwapComponent,
      QuickSwapInactiveDirective,
      NgClass,
      QuickSwapActiveDirective,
      FormsModule,
      FocusOnLoadDirective,
      TextButtonComponent,
      DropdownInlineTextComponent,
      DropdownTextItemComponent,
      DatePickerInputComponent,
      ContenteditableDirective,
      SecondaryButtonComponent,
      PoItem,
      FileListItem,
      ViewImage,
      ModalFooterComponent,
      PrimaryButtonComponent,
      BetterCurrencyPipe,
      BetterDatePipe,
      PopoverDirective,
      UpsellPopover,
      BadgeComponent,
      LocaleCurrencyPipe,
      CurrencyNamePipe,
   ],
})
export class PoComponent implements OnInit, OnDestroy {
   @Input() public data;
   @Input() public poID;
   @Input() public isPOEmbedded;

   @HostBinding("class") public readonly classes = "scroll-height-inheritance";
   @HostBinding("class.embedded-modal") public isEmbedded: boolean = false;

   @ViewChildren("poItem") poItems: QueryList<PoItem> | undefined;

   public editingPoNumber: boolean = false;
   public resolve;
   public modalInstance;
   public title;
   public message;
   public CID;
   public currencySymbol;
   public credEditPOCustomFields;
   public loading;
   public changedBudgetHint;
   public budget;
   public oldShipTo;
   public oldBillTo;
   public oldNotesToVendor: string | null = null;
   public location;
   public credEditPOLocationDefaults;
   public customFields;
   public showAddCustomField;
   public allowDisapprove;
   public superUser;
   public userID;
   public openPOWatchVarSub;
   public canMoveToNextStep: boolean = false;
   private loginWatchVarSub;
   private manageFeatureFlagsSub: Subscription = new Subscription();

   protected originalPONumber: number | null | undefined;
   protected poNumberForDisplay: string = "";

   protected expectedDateDisplay: Date | null = null;
   protected dateDisplay: Date | undefined;
   protected comments: Lookup<"commentID", AugmentedPurchasingComment> = new Lookup(
      "commentID",
   );
   protected purchaseOrder: PurchaseOrder | undefined;
   protected purchaseOrderWorkflows: Array<PurchaseOrderWorkflow> = [];
   protected purchaseOrderState: PurchaseOrderCurrentState | undefined;
   protected editableStatus:
      | {
           editable: boolean;
           assignedToMeAndMissingPermission: boolean;
        }
      | undefined;
   protected requestedUser;
   protected startedFromTask: Task | false | undefined;
   protected associatedVendor: Vendor | undefined;
   protected associatedBudget: Budget | undefined;
   protected displayName: string | undefined;
   protected totals:
      | {
           subtotal: number;
           tax: number;
           discount: number;
           shipping: number;
           total: number;
           totalQty: number;
        }
      | undefined;
   protected showDetails: boolean = true;
   protected showItemDetails: boolean = true;
   protected showCommentDetails: boolean = true;
   protected leadTimes:
      | {
           part: {
              seconds: number;
              hours: number;
              days: number;
              count: number;
           };
           service: {
              seconds: number;
              hours: number;
              days: number;
           };
           other: {
              seconds: number;
              hours: number;
              days: number;
              count: number;
           };
        }
      | undefined;
   protected featureCustomBudgets: boolean = false;
   protected featureSkipPOEmailStep: boolean = false;
   protected canChangePoItems: boolean = false;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageUser = inject(ManageUser);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLocation = inject(ManageLocation);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageParts = inject(ManageParts);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);
   private readonly mobileHeaderTitleService = inject(MobileHeaderTitleService);
   private readonly featureFlagService = inject(FeatureFlagService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly limitVendorsToSingleLocation = computed(() =>
      this.featureFlagService.featureSetDefaulted().has("forceVendorsToSingleLocation"),
   );
   private readonly currentLocationID = signal<number | undefined>(undefined);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationsQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationsQueries.detail(this.currentLocationID()),
   );

   protected readonly isCurrencyPending = computed(
      () => this.locationQuery.isPending() || this.accountCurrencyQuery.isPending(),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );

   public ngOnInit() {
      const params = this.paramsService.params;
      this.loading = true;

      if (params) {
         if (params?.resolve) {
            this.resolve = params.resolve;
         }
         if (params?.modalInstance) {
            this.modalInstance = params.modalInstance;
         }

         this.isEmbedded = params?.isEmbedded || false;

         this.data = this.resolve?.data;

         if (this.data?.title) {
            this.title = this.data.title;
         }
         if (this.data?.message) {
            this.message = this.data.message;
         }
         this.poID = this.data.poID;
      } else if (this.poID) {
         this.data = {};
         this.data.poID = this.poID;
         this.isEmbedded = this.isPOEmbedded;
      }

      this.purchaseOrder = this.managePO.getPurchaseOrder(this.poID);

      this.loginWatchVarSub = this.manageUser.currentUserChanges$
         .pipe(filter((currentUser) => currentUser !== undefined))
         .subscribe((currentUser) => {
            this.CID = currentUser.userInfo.customerID;
            this.userID = currentUser.gUserID;
            this.currencySymbol = currentUser.currency.symbol;
         });
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureCustomBudgets = isFeatureEnabledMap.featureCustomBudgets;
            this.featureSkipPOEmailStep = isFeatureEnabledMap.featureSkipPOEmailStep;
         },
      );
      this.credEditPOCustomFields = false;

      this.mobileHeaderTitleService.setTitle(this.lang().PurchaseOrder);

      this.openPOWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.buildData();
         },
      );

      this.data.buildData = this.buildData.bind(this);
      this.changedBudgetHint = false; //if someone changes a budget we want to give them a hint that the setup was reset

      this.canChangePoItems = this.canUserMakeAChange(
         this.credService.Permissions.ChangePOItems,
      );
      this.currentLocationID.set(this.purchaseOrder?.locationID);
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.openPOWatchVarSub,
         this.loginWatchVarSub,
         this.manageFeatureFlagsSub,
      ]);
   }

   close = () => {
      this.modalInstance.close();
   };

   private async buildData() {
      const answer = await this.managePO.getPurchaseOrderDetails(this.data.poID);
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.purchaseOrder = this.managePO.getPurchaseOrder(this.data.poID);
      if (!this.purchaseOrder?.locationID) return;

      this.purchaseOrder.poItemIDs.sort(DEFAULT);

      if (answer.data.poWorkflows) {
         this.purchaseOrderWorkflows = [];
         for (const workflow of answer.data.poWorkflows) {
            this.purchaseOrderWorkflows.push(workflow);
         }
      }

      if (this.purchaseOrder.budgetID !== null && this.purchaseOrder.budgetID > 0) {
         this.budget = this.managePO.getBudget(this.purchaseOrder.budgetID);
      }

      this.leadTimes = this.managePO.calcPurchaseOrderLeadTime(this.poID);

      this.oldShipTo = this.purchaseOrder.shipTo;
      this.oldBillTo = this.purchaseOrder.billTo;
      this.oldNotesToVendor = this.purchaseOrder.notesToVendor;

      this.requestedUser = this.purchaseOrder.requestedByUserID
         ? this.manageUser.getFlatUsersIndex()[this.purchaseOrder.requestedByUserID]
         : undefined;

      this.location = this.manageLocation.getLocation(this.purchaseOrder.locationID);

      this.poNumberForDisplay =
         this.managePO.getPurchaseOrderNumberForDisplay(this.purchaseOrder.poID)
            ?.poNumberForDisplay ?? "";

      this.originalPONumber = this.purchaseOrder.poNumber;

      this.editableStatus = this.managePO.getPurchaseOrderEditable(
         this.poID,
         this.manageUser,
         this.credService,
         this.purchaseOrderWorkflows,
      );

      this.purchaseOrderState = this.managePO.getPurchaseOrderCurrentState(
         this.poID,
         this.purchaseOrderWorkflows,
      );
      if (this.purchaseOrder.checklistID) {
         this.startedFromTask =
            this.manageTask.getTaskLocalLookup(this.purchaseOrder.checklistID) ?? false;
      } else {
         this.startedFromTask = false;
      }

      this.associatedVendor = this.purchaseOrder.vendorID
         ? this.manageVendor.getVendor(this.purchaseOrder.vendorID)
         : undefined;

      this.associatedBudget = this.purchaseOrder.budgetID
         ? this.managePO.getBudget(this.purchaseOrder.budgetID)
         : undefined;

      this.displayName = this.managePO.getPurchasingAssignmentName({
         userID: this.purchaseOrder.userID,
         profileID: this.purchaseOrder.profileID,
      });

      this.totals = this.managePO.getPurchaseOrderCostTotals(this.purchaseOrder.poID);

      if (this.purchaseOrder.date !== null) {
         this.dateDisplay = new Date(this.purchaseOrder.date * 1000);
      }

      if (
         this.purchaseOrder.expectedDate !== null &&
         this.purchaseOrder.expectedDate !== 0
      ) {
         this.expectedDateDisplay = new Date(this.purchaseOrder.expectedDate * 1000);
      }

      this.dateDisplay = this.purchaseOrder.date
         ? new Date(this.purchaseOrder.date * 1000)
         : undefined;

      if (this.purchaseOrder.poID == 0) {
         //this only happens when the PO was deleted by some other user and their state is out of whack.
         location.reload();
      }

      this.credEditPOCustomFields = this.credService.isAuthorized(
         this.purchaseOrder.locationID,
         this.credService.Permissions.ConfigurePOFields,
      );
      this.credEditPOLocationDefaults = this.credService.isAuthorized(
         this.purchaseOrder.locationID,
         this.credService.Permissions.ConfigurePOFields,
      );

      this.customFields = [
         { id: 1 },
         { id: 2 },
         { id: 3 },
         { id: 4 },
         { id: 5 },
         { id: 6 },
      ]; //used to show the number of custom fields

      this.showAddCustomField = false;
      for (const customField of this.customFields) {
         if (
            this.location[`poField${customField.id}Name`] == null ||
            this.location[`poField${customField.id}Name`].length == 0
         ) {
            this.showAddCustomField = true;
         }
      }

      for (const comment of answer.data.comments) {
         this.comments.set(
            comment.commentID,
            this.managePO.buildPurchasingComment("po", this.poID, comment),
         );
      }
      this.comments = this.comments.orderBy("timestamp");

      if (this.purchaseOrder.prIDs.length > 0) {
         this.allowDisapprove = false;
      } else {
         this.allowDisapprove = true;
      }

      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      ); //if they can manage roles they must be a super user

      this.managePO.getPurchaseOrderEditable(
         this.purchaseOrder.poID,
         this.manageUser,
         this.credService,
      );

      this.checkForPODefaults();
      this.canMoveToNextStep = this.userCanProgressPo();

      this.loading = false;
   }

   protected async addPOComment(): Promise<void> {
      if (this.purchaseOrder === undefined) return;
      this.managePO.cleanCommentTempFiles();
      const instance = this.modalService.open(AddPOComment);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AddCommentMsg,
            title: this.lang().AddComment,
            data: {
               CID: this.manageUser.getCurrentUser().userInfo.customerID,
               poID: this.poID,
            },
         },
      };

      const result = await instance.result;
      if (!result) {
         return;
      }

      if (result.newMentionedList && result.newMentionedList.length > 0) {
         this.addNewMentionsToPONotifications(result.newMentionedList, this.poID);
      }
      const autoGen = 0;
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const newComment = await this.managePO.addPurchaseOrderComment(
         result.comment,
         this.purchaseOrder.poID,
         autoGen,
         true,
      );
      this.loadingBarService.remove();
      if (newComment === undefined) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }
      this.comments.set(newComment.commentID, newComment);
      this.alertService.addAlert(this.lang().AddCommentSuccess, "success", 1000);
   }

   addNewMentionsToPONotifications = (newMentionedList, poID) => {
      for (const item of newMentionedList) {
         this.managePO.addUserToPONotifications(item.userID, poID, "mentioned by");
      }
   };

   protected async deleteComment(comment: PurchaseOrderComment): Promise<void> {
      if (this.superUser == false) {
         return;
      }
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteCommentMsg,
            title: this.lang().DeleteComment,
         },
      };
      const result = await instance.result;
      if (result !== 1) {
         return;
      }
      const answer = await this.managePO.deletePOComment(comment);
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }

      this.comments.delete(comment.commentID);

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async pickVendor(): Promise<void> {
      if (
         !this.purchaseOrder?.locationID ||
         !this.purchaseOrderState ||
         !this.editableStatus
      ) {
         return;
      }

      if (!this.canUserMakeAChange(this.credService.Permissions.ChangePOVendor)) {
         this.alertService.addAlert(this.lang().cred146Fail, "danger", 10000);
         return;
      }

      let editable = true;
      if (this.editableStatus.editable === false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         editable = false;
      }
      //on rare circumstances we need to change the editable function of picking vendor
      if (
         this.purchaseOrderState.budgetWorkflowID &&
         this.purchaseOrderState.budgetWorkflowID > 0
      ) {
         const budget = this.managePO.getBudgetWorkflow(
            this.purchaseOrderState.budgetWorkflowID,
         );
         if (this.purchaseOrder.vendorID === 0 && budget?.allowPR === 1) {
            editable = true;
         }
      }
      if (!editable) {
         return;
      }

      const instance = this.modalService.open(PickVendors);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PleasePickWhichVendorYouWouldLikeToAddAPOFor,
            title: this.lang().PickVendors,
            data: {
               singleLocation: this.limitVendorsToSingleLocation()
                  ? this.purchaseOrder.locationID
                  : 0,
               selectOne: true,
               restrictToCred: false,
               iDontKnowOption: false,
               locationID: this.purchaseOrder.locationID,
            },
         },
      };
      const vendorIDArray = await instance.result;
      if (!vendorIDArray.length) {
         return;
      }
      const vendor = this.manageVendor.getVendor(vendorIDArray[0]);
      if (!vendor) {
         return;
      }
      const answer = await this.managePO.updateVendor(
         this.purchaseOrder.poID,
         vendor.vendorID,
      );
      if (!answer?.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      this.associatedVendor = vendor;

      this.leadTimes = this.managePO.calcPurchaseOrderLeadTime(this.purchaseOrder.poID);
      this.recalculateRatesForVendor();
      // Refreshes the item info label text and associations for the PO (Which is in the initializeData complex function in poItem)
      this.managePO.triggerPurchaseOrderItemInfoRefresh$.next();
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   public recalculateRatesForVendor(): void {
      if (!this.purchaseOrder) return;
      for (const poItemID of this.purchaseOrder.poItemIDs) {
         const purchaseOrderItem = this.managePO.getPurchaseOrderItem(poItemID);
         if (!purchaseOrderItem) continue;
         const newRate = this.managePO.setPurchaseOrderVendorItemRate(
            poItemID,
            Array.from(this.manageAssociations.getPartVendorRelations()),
         );
         purchaseOrderItem.rate = newRate ?? null;
      }
   }

   protected popVendor(): void {
      if (!this.purchaseOrder?.vendorID) return;
      const vendor = this.manageVendor.getVendor(this.purchaseOrder.vendorID);
      if (!vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   protected async updatePODate(): Promise<void> {
      if (this.editableStatus?.editable == false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      if (!this.purchaseOrder?.locationID || this.dateDisplay === undefined) return;

      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePODetails,
         )
      ) {
         this.alertService.addAlert(this.lang().cred149Fail, "danger", 10000);
         return;
      }
      const newDate = new Date(this.dateDisplay).getTime() / 1000;
      const answer = await this.managePO.updatePurchaseOrderDate(
         this.purchaseOrder.poID,
         newDate,
      );
      if (answer === false || !this.purchaseOrder?.date) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }
      this.purchaseOrder.date = newDate;

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async updatePOExpectedDeliveryDate(): Promise<void> {
      if (this.editableStatus?.editable == false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      if (!this.purchaseOrder?.locationID || this.expectedDateDisplay === null) return;

      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePODetails,
         )
      ) {
         this.alertService.addAlert(this.lang().cred149Fail, "warning", 10000);
         return;
      }
      const newDate = new Date(this.expectedDateDisplay).getTime() / 1000;
      const answer = await this.managePO.updatePurchaseOrderExpectedDate(
         this.purchaseOrder.poID,
         newDate,
      );

      if (answer === false) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async addPOItem(): Promise<void> {
      if (this.editableStatus?.editable == false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      if (!this.purchaseOrder?.locationID) return;

      if (!this.canUserMakeAChange(this.credService.Permissions.ChangePOItems)) {
         this.alertService.addAlert(this.lang().cred151Fail, "warning", 10000);
         return;
      }

      const answer = await this.managePO.addPurchaseOrderItem(this.purchaseOrder.poID);
      if (answer.data.success === true) {
         this.setProdService(answer.data.item.poItemID, false);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
   }

   protected async generatePurchaseOrderEmail(): Promise<void> {
      if (!this.purchaseOrder?.vendorID) return;
      const vendor = this.manageVendor.getVendor(this.purchaseOrder.vendorID);
      if (!vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return;
      }
      const message = this.lang().ThisEmailWillBeSentWithThePOAutomaticallyAttached;
      let subject =
         this.location.poDefaultReadyToReceiveEmailSubject ||
         this.lang().NewPurchaseOrder;
      let emailMessage =
         this.location.poDefaultReadyToReceiveEmailMessage ||
         `${this.lang().Hello} ${vendor.vendorContact}, <br><br> ${this.lang().AttachedIsANewPurchaseOrder} ${this.lang().ThankYou}!`;
      const vendorEmail = vendor.vendorEmail || "";
      const vendorContact = vendor.vendorContact;
      let sendEmailTo = this.location.poDefaultReadyToReceiveEmailRecips || vendorEmail;
      // Check permissions
      if (
         !this.credService.isAuthorized(
            this.budget.locationID,
            this.credService.Permissions.ReceivePOItems,
         )
      ) {
         this.alertService.addAlert(this.lang().cred152Fail, "danger", 10000);
         return;
      }
      // Remove default placeholders from template and display the actual info
      if (sendEmailTo.includes("{{vendor-email}}")) {
         sendEmailTo = sendEmailTo.replace("{{vendor-email}}", vendorEmail);
      }
      // The /{{vendor-contact}}/g finds all instances of {{vendor-contact}}, rather than just the first
      if (subject.includes("{{vendor-contact}}")) {
         subject = subject.replace(/{{vendor-contact}}/g, vendorContact);
      }
      if (emailMessage.includes("{{vendor-contact}}")) {
         emailMessage = emailMessage.replace(/{{vendor-contact}}/g, vendorContact);
      }
      // Handle semicolons as the last character of the "To" field
      if (sendEmailTo.length > 0) {
         const lastChar = sendEmailTo.substr(sendEmailTo.length - 1);
         if (lastChar !== ";") {
            sendEmailTo = `${sendEmailTo};`;
         }
      } else {
         sendEmailTo = "";
      }
      if (sendEmailTo === ";" || sendEmailTo === " ;") {
         sendEmailTo = " ";
      }
      const instance = this.modalService.open(EmailTemplate);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: message,
            title: this.lang().EmailPOToVendor,
            data: {
               emailTo: sendEmailTo,
               emailSubject: subject,
               emailMessage: emailMessage,
               locationID: this.location.locationID,
               pickEmployees: false,
               checklistID: false,
               onSubmit: "returnData",
               sendingPOToVendor: true,
               vendor: vendor,
               attachments: [
                  {
                     name: `PO-${this.purchaseOrder.poNumber}.pdf`,
                     downloadClick: this.printPO.bind(this),
                  },
               ],
            },
         },
         backdrop: "static",
         keyboard: false,
      };
      const result = await instance.result;
      if (!result) {
         return;
      }
      const response = await this.managePO.sendPOToVendor(
         this.purchaseOrder.poID,
         result.recipients,
         result.subject,
         result.message,
      );
      if (response.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }
      this.managePO.incOpenPurchaseOrderWatchVar();
      this.alertService.addAlert(this.lang().success2EmailTemplate, "success", 1000);
   }

   private userCanProgressPo(): boolean {
      if (this.purchaseOrder === undefined) return false;
      const user = this.manageUser.getCurrentUser();
      if (this.purchaseOrder.userID == user.gUserID) return true;
      const userBelongsToPoProfile = user.profileArr.some(
         (profile) => profile === this.purchaseOrder?.profileID,
      );
      if (userBelongsToPoProfile) return true;
      if (
         this.purchaseOrder.locationID &&
         this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.StartNextPOStep,
         )
      )
         return true;
      return false;
   }

   public async startNextPOStep(): Promise<void> {
      if (!this.userCanProgressPo()) {
         this.alertService.addAlert(this.lang().cred164Fail, "warning", 10000);
         return;
      }
      if (this.purchaseOrder === undefined) return;
      if (this.purchaseOrder.poItemIDs.length === 0) {
         this.alertService.addAlert(
            this.lang().WhoopsPleaseAddAtLeastOneItemToThisPOMsg,
            "warning",
            4000,
         );
         return;
      }
      for (const poItemID of this.purchaseOrder.poItemIDs) {
         const purchaseOrderItem = this.managePO.getPurchaseOrderItem(poItemID);
         if (purchaseOrderItem?.itemType != 0 && purchaseOrderItem?.qty == 0) {
            this.alertService.addAlert(
               this.lang().WhoopsMakeSureYouHaveAQty,
               "warning",
               4000,
            );
            return;
         }
      }
      if (
         this.purchaseOrderState?.nextStep?.budgetWorkflowID &&
         this.purchaseOrderState.nextStep.budgetWorkflowID > 0
      ) {
         //this is a custom step so make sure the vendor is set IF the next step allows for receipts
         const budgetWorkflow = this.managePO.getBudgetWorkflow(
            this.purchaseOrderState.nextStep.budgetWorkflowID,
         );
         if (budgetWorkflow?.allowPR == 1 && this.purchaseOrder.vendorID == 0) {
            this.alertService.addAlert(
               this.lang().WhoopsPleaseSelectAVendor,
               "warning",
               4000,
            );
            return;
         }
         //it isn't a custom step so we need to make sure the vendor is set
      } else if (this.purchaseOrder.vendorID == 0) {
         this.alertService.addAlert(
            this.lang().WhoopsPleaseSelectAVendor,
            "warning",
            4000,
         );
         return;
      }
      if (this.purchaseOrder.state == 0 && this.purchaseOrder.budgetID == 0) {
         this.alertService.addAlert(
            this.lang().WhoopsPleaseSelectABudget,
            "warning",
            4000,
         );
         return;
      }

      const code = this.manageUser.getCurrentUser().userInfo.customerBarcodeStr;

      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const answer = await this.managePO.approvePurchaseOrderWorkflowStep(
         code,
         this.purchaseOrder.poID,
         this.purchaseOrderState?.poWorkflowID,
         "",
      );
      if (answer.data.success) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         // If next step is "Ready to receive"
         if (answer.data.newState === 97 && !this.featureSkipPOEmailStep) {
            this.generatePurchaseOrderEmail();
         }
      } else if (
         answer.data.success == false &&
         answer.data.reason === "no longer on this step"
      ) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNoLongOnThisStep,
            "warning",
            10000,
         );
         this.managePO.getData();
      } else if (answer.data.success == false && answer.data.reason === "needs vendor") {
         this.alertService.addAlert(
            this.lang().WhoopsPleaseSelectAVendor,
            "warning",
            4000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
      this.loadingBarService.remove();
   }

   protected async disapprovePO() {
      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.DisapprovePOStep,
         )
      ) {
         this.alertService.addAlert(this.lang().cred165Fail, "danger", 10000);
         return;
      }
      const code = this.manageUser.getCurrentUser().userInfo.customerBarcodeStr;
      if (this.purchaseOrder.prIDs.length > 0) {
         this.alertService.addAlert(
            this.lang().WhoopsYouCanNotDisapproveAPOThatHasItemsReceived,
            "warning",
            4000,
         );
         return;
      }
      const instance = this.modalService.open(DisapprovePO);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: this.lang().BackAStep,
               poID: this.purchaseOrder.poID,
            },
         },
      };
      const result = await instance.result;
      if (!result) {
         return;
      }
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const answer = await this.managePO.disapprovePurchaseOrderWorkflowStep(
         code,
         result.newState,
         this.purchaseOrder.poID,
         result.text,
         "",
      );
      this.loadingBarService.remove();
      if (answer.data.success) {
         this.purchaseOrderState = this.managePO.getPurchaseOrderCurrentState(
            this.poID,
            this.purchaseOrderWorkflows,
         );
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else if (
         answer.data.success == false &&
         answer.data.reason === "already receiving"
      ) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOHasAlreadyReceivedItemsAndCantBeDisapproved,
            "warning",
            10000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
   }

   protected popBudget(budgetID: number): void {
      const modalRef = this.modalService.open(PopBudget);
      modalRef.setInput("budgetID", budgetID);
   }

   popTask = (taskID: number): void => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: taskID,
               editable: false,
            },
         },
      };
   };

   protected async pickBudget($event): Promise<void> {
      if (!this.featureCustomBudgets) {
         $event.stopPropagation();
         return;
      }
      if (
         !this.featureCustomBudgets &&
         this.purchaseOrder &&
         this.purchaseOrder.budgetID !== 0
      ) {
         return;
      }
      if (!this.purchaseOrder?.locationID) return;

      if (!this.canUserMakeAChange(this.credService.Permissions.ChangePOBudget)) {
         this.alertService.addAlert(this.lang().cred147Fail, "danger", 10000);
         return;
      }

      if (this.editableStatus?.editable === false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      //we don't want them to be able to change a budget if there has been any items received already.  This would mess up numbers etc.  In the future we may have the ability for a super user to override this and adjust the budget without going through the workflow reset
      if (this.purchaseOrder.prIDs.length > 0) {
         //they have PRs on this PO so don't let them change the budget.
         this.alertService.addAlert(
            this.lang().WhoopsYouCanNotChangeTheBudgetOfAPOThatHasReceivedItems,
            "warning",
            10000,
         );
         return;
      }
      const modalRef = this.modalService.open(PickBudgets);
      modalRef.setInput("locationID", this.purchaseOrder.locationID);

      const pickedBudgetID = await modalRef.result;
      if (!this.isBudgetUpdated(pickedBudgetID)) return;

      const response = await this.managePO.setBudget(
         this.purchaseOrder.poID,
         pickedBudgetID,
      );

      if (response.data.success) {
         if (this.purchaseOrder.state !== null && this.purchaseOrder.state > 0) {
            //if they aren't in setup we need to show them a warning to let them know things changed.
            this.changedBudgetHint = true;
         }

         this.purchaseOrder.budgetID = pickedBudgetID;

         this.buildData();
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
   }

   private canUserMakeAChange(permissionID: number): boolean {
      if (!this.purchaseOrder || !permissionID) {
         return false;
      }
      if (this.credService.isAuthorized(this.purchaseOrder.locationID, permissionID)) {
         return true;
      }

      // If they don't have the permission, then check to see if they're assigned to the PO
      // If the PO is assigned to them, they can still change the relevant Entity if it's in the 'setup' step
      const user = this.manageUser.getCurrentUser();
      const userBelongsToPoProfile = user.profileArr.some(
         (profile) => profile === this.purchaseOrder?.profileID,
      );

      const canEditAndCompleteMyPOs = this.credService.isAuthorized(
         this.purchaseOrder.locationID,
         this.credService.Permissions.EditAndCompleteMyPOs,
      );
      const canEditAndCompleteAnyPOs = this.credService.isAuthorized(
         this.purchaseOrder.locationID,
         this.credService.Permissions.EditAndCompleteAnyPOs,
      );
      if (
         this.purchaseOrder?.state === 0 &&
         (canEditAndCompleteMyPOs || canEditAndCompleteAnyPOs) &&
         (this.userID === this.purchaseOrder.userID || userBelongsToPoProfile)
      ) {
         return true;
      }

      return false;
   }

   private isBudgetUpdated(budgetID: number | undefined): budgetID is number {
      return budgetID !== undefined && budgetID !== this.purchaseOrder?.budgetID;
   }

   protected async createPR(): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      if (this.purchaseOrder?.vendorID == 0) {
         this.alertService.addAlert(
            this.lang().WhoopsPleaseSelectAVendor,
            "danger",
            4000,
         );
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ReceivePOItems,
         )
      ) {
         this.alertService.addAlert(this.lang().cred152Fail, "danger", 10000);
         return;
      }

      if (this.purchaseOrder.poItemIDs.length === 0) {
         this.alertService.addAlert(
            this.lang().NoPOItemsReadyToReceiveMessage,
            "warning",
            4000,
         );
         return;
      }

      const instance = this.modalService.open(ReceivePurchaseOrderItemsModal);
      instance.setInput("poID", this.purchaseOrder.poID);
      await instance.result;
      if (!this.poItems?.length) return;
      for (const childItem of this.poItems) {
         childItem.initializeData();
      }
   }

   public async deletePO(): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      //first make sure they have the credential to delete

      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.DeletePO,
         )
      ) {
         this.alertService.addAlert(this.lang().cred145Fail, "danger", 10000);
         return;
      }
      //if there are items received then we can't allow it
      let foundTransaction = false;
      for (const poItemID of this.purchaseOrder.poItemIDs) {
         const purchaseOrderItem = this.managePO.getPurchaseOrderItem(poItemID);
         if (purchaseOrderItem === undefined) continue;
         if (purchaseOrderItem.transactionIDs.length > 0) {
            foundTransaction = true;
         }
      }
      if (this.superUser == true) {
         //super users can always delete
      } else if (this.purchaseOrder.state == 0) {
         //during setup anyone can delete it if they have the credential
      } else if (foundTransaction) {
         this.alertService.addAlert(
            this.lang().WhoopsYouCantDeleteAPOOnceItHasHadItemsReceived,
            "warning",
            10000,
         );
         return;
      }
      let msg = this.lang().DeletePOMsg;
      if (foundTransaction) {
         msg += `<br /><br /><b class="red-color">${this.lang().DeletePODeletingTransactionMsg}</b>`;
      }
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: msg,
            title: this.lang().DeletePO,
         },
      };
      const result = await instance.result;
      if (result !== 1) {
         return;
      }
      const answer = await this.managePO.deletePurchaseOrder(
         this.purchaseOrder.poID,
         this.purchaseOrder?.locationID ?? 0,
      );
      if (answer !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }
      this.modalInstance.close();
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async changePOAssignment(): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      if (this.editableStatus?.editable == false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOAssignment,
         )
      ) {
         this.alertService.addAlert(this.lang().cred148Fail, "danger", 10000);
         return;
      }
      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userLastName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }
      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: "",
               title: this.lang().ChangePurchaseOrderAssignment,
               locationID: this.purchaseOrder.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.purchaseOrder.userID,
               defaultProfile: this.purchaseOrder.profileID,
            },
         },
      };
      const result = await instance.result;
      if (result === 0) {
         return;
      }
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const answer = await this.managePO.changePurchaseOrderAssignment(
         result.userID,
         result.profileID,
         this.purchaseOrder.poID,
         result.multiUsers,
         true,
      );
      this.loadingBarService.remove();
      if (answer !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
         return;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 2000);
   }

   protected checkCred(cred: number): boolean {
      if (!this.purchaseOrder?.locationID) return false;
      return this.credService.isAuthorized(this.purchaseOrder.locationID, cred);
   }

   protected async updatePONotesToVendor(): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      if (this.oldNotesToVendor == this.purchaseOrder?.notesToVendor) {
         return;
      }
      if (this.editableStatus?.editable == false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePODetails,
         )
      ) {
         this.alertService.addAlert(this.lang().cred149Fail, "danger", 10000);
         return;
      }
      const answer = await this.managePO.updatePurchaseOrderNotesToVendor(
         this.purchaseOrder.poID,
         this.purchaseOrder.notesToVendor ?? "",
      );

      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }
      this.oldNotesToVendor = this.purchaseOrder.notesToVendor;
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async updatePOCustomField(position: number): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      if (this.editableStatus?.editable == false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePODetails,
         )
      ) {
         this.alertService.addAlert(this.lang().cred149Fail, "danger", 10000);
         return;
      }
      let field, value;
      if (position == 1) {
         field = 1;
         value = this.purchaseOrder.field1;
      } else if (position == 2) {
         field = 2;
         value = this.purchaseOrder.field2;
      } else if (position == 3) {
         field = 3;
         value = this.purchaseOrder.field3;
      } else if (position == 4) {
         field = 4;
         value = this.purchaseOrder.field4;
      } else if (position == 5) {
         field = 5;
         value = this.purchaseOrder.field5;
      } else if (position == 6) {
         field = 6;
         value = this.purchaseOrder.field6;
      }

      const answer = await this.managePO.updatePOCustomField(
         this.purchaseOrder.poID,
         field,
         value,
      );

      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async setPOCustomSettings(position: number): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ConfigurePOFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred150Fail, "danger", 10000);
         return;
      }
      let field;
      if (position == 1) {
         field = 1;
      } else if (position == 2) {
         field = 2;
      } else if (position == 3) {
         field = 3;
      } else if (position == 4) {
         field = 4;
      } else if (position == 5) {
         field = 5;
      } else if (position == 6) {
         field = 6;
      }
      const instance = this.modalService.open(POCustomFieldSettings);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().POCustomFieldMsg,
            title: this.lang().POCustomField,
            field: field,
            locationID: this.purchaseOrder.locationID,
         },
      };
      const result = await instance.result;
      if (result !== 1 || this.purchaseOrder.vendorID === null) {
         //the actions below should only be taken if there's an associated vendor
         return;
      }

      const answer = await this.managePO.setDefaultCustomVendorField(
         this.purchaseOrder.poID,
         this.purchaseOrder.vendorID,
         field,
      );

      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async removePurchaseOrderCustomField(position: number): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ConfigurePOFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred150Fail, "danger", 10000);
         return;
      }
      let field;
      if (position == 1) {
         field = 1;
      } else if (position == 2) {
         field = 2;
      } else if (position == 3) {
         field = 3;
      } else if (position == 4) {
         field = 4;
      } else if (position == 5) {
         field = 5;
      } else if (position == 6) {
         field = 6;
      }
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().RemoveCustomPOFieldMsg1}<br /><br /><b class='red-color'>${this.lang().RemoveCustomPOFieldMsg2}</b>`,
            title: this.lang().RemoveCustomPOField,
         },
      };
      const result = await instance.result;
      if (result !== 1) {
         return;
      }
      const answer = await this.managePO.removePurchaseOrderCustomField(
         this.purchaseOrder.locationID,
         field,
      );

      const location = this.manageLocation.getLocation(this.purchaseOrder.locationID);
      if (answer.data.success !== true || !location) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      /**
       * We should avoid using bracket notation to access properties on an object.
       * Bracket notation ignores access modifiers like private and protected, doesn't
       * work with IDEs when finding references making it harder to refactor, and won't
       * give deprecation warnings when we start moving to a better architecture.
       *
       * The only reason to we are using it is because of tech debt in how poFields are
       * stored. The new LocationEntity makes it easier to refactor this code in a way
       * that hides how the data is actually stored under the hood. We can convert the
       * Po field data into an array of objects that have the field name and field type
       * and then use that to populate the UI.
       */
      location[`poField${field}Name`] = "";
      location[`poField${field}VendorFieldLink`] = "";
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async addCustomField(): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ConfigurePOFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred150Fail, "danger", 10000);
         return;
      }
      let field;
      for (const customField of this.customFields) {
         if (
            this.location[`poField${customField.id}Name`] == null ||
            this.location[`poField${customField.id}Name`].length == 0
         ) {
            field = customField.id;
            break;
         }
      }
      const instance = this.modalService.open(POCustomFieldSettings);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().POCustomField,
            field: field,
            locationID: this.purchaseOrder.locationID,
         },
      };
      const result = await instance.result;
      if (result !== 1 || !this.purchaseOrder.vendorID) {
         return;
      }
      const answer = await this.managePO.setDefaultCustomVendorField(
         this.purchaseOrder.poID,
         this.purchaseOrder.vendorID,
         field,
      );

      if (answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async updatePOBillTo(): Promise<void> {
      if (
         this.oldBillTo === this.purchaseOrder?.billTo ||
         !this.purchaseOrder?.locationID
      ) {
         return;
      }
      if (this.editableStatus?.editable == false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePODetails,
         )
      ) {
         this.alertService.addAlert(this.lang().cred149Fail, "danger", 10000);
         return;
      }
      const answer = await this.managePO.updatePurchaseOrderBillTo(
         this.purchaseOrder.poID,
         this.purchaseOrder.billTo ?? "",
      );
      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      this.oldBillTo = this.purchaseOrder.billTo;
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async updatePOShipTo(): Promise<void> {
      if (
         this.oldShipTo == this.purchaseOrder?.shipTo ||
         !this.purchaseOrder?.locationID
      ) {
         return;
      }
      if (this.editableStatus?.editable == false) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePODetails,
         )
      ) {
         this.alertService.addAlert(this.lang().cred149Fail, "danger", 10000);
         return;
      }
      const answer = await this.managePO.updatePurchaseOrderShipTo(
         this.purchaseOrder.poID,
         this.purchaseOrder.shipTo ?? "",
      );
      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }
      this.oldShipTo = this.purchaseOrder.shipTo;
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async changePOShipToDefault(): Promise<void> {
      if (!this.purchaseOrder?.locationID) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ConfigurePOFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred150Fail, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(GatherText);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: false,
            message2: false,
            title: this.lang().ChangeDefaultPOShipTo,
            warning: false,
            data: {
               currentText:
                  this.manageLocation.getLocation(this.purchaseOrder.locationID)
                     ?.poShipTo ?? "",
               buttonText: false,
            },
         },
      };
      const result = await instance.result;
      if (!result || result.length === 0) {
         return;
      }
      const answer = await this.manageLocation.changePOShipToDefault(
         this.purchaseOrder.locationID,
         result,
      );
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      // If they haven't entered anything in the ship to field, then add the default there and update the po to use it
      if (!this.oldShipTo) {
         this.purchaseOrder.shipTo = result;
         this.updatePOShipTo();
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async changePOBillToDefault(): Promise<void> {
      if (!this.purchaseOrder?.locationID) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ConfigurePOFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred150Fail, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(GatherText);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: false,
            message2: false,
            title: this.lang().ChangeDefaultPOBillTo,
            warning: false,
            data: {
               currentText:
                  this.manageLocation.getLocation(this.purchaseOrder.locationID)
                     ?.poBillTo ?? "",
               buttonText: false,
            },
         },
      };
      const result = await instance.result;
      if (!result || result.length === 0) {
         return;
      }
      const answer = await this.manageLocation.changePOBillToDefault(
         this.purchaseOrder.locationID,
         result,
      );
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      // If they haven't entered anything in the bill to field, then add the default there and update the po to use it
      if (!this.oldBillTo) {
         this.purchaseOrder.billTo = result;
         this.updatePOBillTo();
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async changePONotesToVendorDefault(): Promise<void> {
      if (!this.purchaseOrder?.locationID) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ConfigurePOFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred150Fail, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(GatherText);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: false,
            message2: false,
            title: this.lang().ChangeDefaultPONotesToVendor,
            warning: false,
            data: {
               currentText:
                  this.manageLocation.getLocation(this.purchaseOrder.locationID)
                     ?.poNotesToVendor ?? "",
               buttonText: false,
            },
         },
      };

      const result = await instance.result;
      if (!result || result.length === 0) {
         return;
      }

      const answer = await this.manageLocation.changePONotesToVendorToDefault(
         this.purchaseOrder.locationID,
         result,
      );

      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      // If they haven't entered anything in the bill to field, then add the default there and update the po to use it
      if (!this.oldNotesToVendor) {
         this.purchaseOrder.notesToVendor = result;
         this.updatePONotesToVendor();
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected printPO(internalPO: boolean): void {
      if (this.purchaseOrder === undefined) return;
      window.open(
         `phpscripts/managePO.php?action=printPO&poID=${this.purchaseOrder.poID}&internalPO=${internalPO}`,
         "_blank",
      );
   }

   protected async disapprovePurchaseRequest(): Promise<void> {
      if (this.purchaseOrder?.state !== 0 || !this.purchaseOrder?.locationID) {
         //can only disapprove a purchase request if we are in a setup mode
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.DisapprovePurchaseRequests,
         )
      ) {
         this.alertService.addAlert(this.lang().cred176Fail, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(GatherText);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DisapprovePurchaseRequestMsg,
            message2: false,
            title: this.lang().DisapprovePurchaseRequest,
            warning: false,
            data: {
               currentText: "",
               singleLine: false,
               placeholder: this.lang().DisapprovePurchaseRequestPlaceholder,
               buttonText: this.lang().Disapprove,
            },
         },
      };
      const result = await instance.result;
      if (!result) {
         return;
      }
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const answer = await this.managePO.disapprovePurchaseRequest(
         this.purchaseOrder.poID,
         result,
      );
      this.loadingBarService.remove();
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }
      this.alertService.addAlert(
         this.lang().ThankYouTheRequestorHasBeenNotified,
         "success",
         5000,
      );
   }

   protected async reopenPurchaseRequest(): Promise<void> {
      if (this.purchaseOrder === undefined) return;
      const instance = this.modalService.open(GatherText);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ReopenPurchaseRequestMsg,
            message2: false,
            title: this.lang().ReopenPurchaseRequest,
            warning: false,
            data: {
               currentText: "",
               singleLine: false,
               placeholder: this.lang().ReopenPurchaseRequestPlaceholder,
               buttonText: this.lang().Reopen,
            },
         },
      };

      const result = await instance.result;
      if (!result) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const answer = await this.managePO.reopenPurchaseRequest(
         this.purchaseOrder.poID,
         result,
      );
      this.loadingBarService.remove();
      if (answer.data.success == true) {
         this.alertService.addAlert(
            this.lang().ThankYouNotificationHasBeenSentToYourPurchasing,
            "success",
            5000,
         );
      }
   }

   protected async setReminder(): Promise<void> {
      if (this.purchaseOrder === undefined) return;
      const instance = this.modalService.open(EmailTemplate);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().HaveUsSendYouAnEmailAtThisTime,
            title: this.lang().SetAReminder,
            data: {
               emailTo: false,
               emailSubject: `${this.lang().ReminderForPurchaseOrder} ${this.purchaseOrder.poNumber}`,
               emailMessage: this.lang().ThisPORequiresYourAttention,
               pickEmployees: true, //if we need to turn this on for po we need to do it slightly different
               locationID: this.location.locationID,
               checklistID: false,
               onSubmit: "returnData",
               pickDate: true,
            },
         },
         backdrop: "static",
         keyboard: false,
      };
      const result = await instance.result;
      if (!result) {
         return;
      }
      const answer = await this.manageUser.setReminder(
         0,
         result.recipients,
         result.date,
         result.subject,
         result.message,
         0,
         this.purchaseOrder.poID,
         0,
      );

      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async updatePONumber(): Promise<void> {
      if (
         this.purchaseOrder?.poNumber === this.originalPONumber ||
         this.purchaseOrder === undefined ||
         this.originalPONumber === undefined
      ) {
         this.editingPoNumber = false;
         return;
      }
      const newDisplayNumber =
         this.managePO.getPurchaseOrderNumberForDisplay(this.purchaseOrder.poID)
            ?.poNumberForDisplay ?? "";
      // Make sure the input doesn't contain anything other than numbers
      const reg = /^\d+$/;
      if (reg.test(String(this.purchaseOrder.poNumber)) === false) {
         // Set number back to original number and show warning
         //Even though this is a number field, this will catch whether decimals
         // were placed in the field and prevent saving if so
         this.alertService.addAlert(
            this.lang().OnlyDigitsAreAllowedInThisField,
            "warning",
            6000,
         );
         this.resetPoNumber();
         return;
      }

      const poNumberTaken = Boolean(
         this.managePO.getPurchaseOrders().find((purchaseOrder) => {
            return (
               purchaseOrder.poID !== this.purchaseOrder?.poID &&
               purchaseOrder.poNumber === this.purchaseOrder?.poNumber
            );
         }),
      );

      // Do a check to make sure this PO Number doesn't exist already
      if (poNumberTaken) {
         // Set number back to original number and show warning
         this.alertService.addAlert(
            `${this.lang().PO} ${newDisplayNumber} ${this.lang().PONumberAlreadyExistsWarning}`,
            "warning",
            6000,
         );
         this.resetPoNumber();
         return;
      }

      const answer = await this.managePO.updatePONumber(
         this.purchaseOrder.poID,
         this.purchaseOrder.poNumber,
      );
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         this.resetPoNumber();
         return;
      }

      // Remove the old original number from the array so you can switch back to it if you want
      this.originalPONumber = this.purchaseOrder.poNumber;
      this.poNumberForDisplay = newDisplayNumber;

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      this.editingPoNumber = false;
   }

   private resetPoNumber(): void {
      if (this.purchaseOrder === undefined || this.originalPONumber === undefined) return;
      this.purchaseOrder.poNumber = this.originalPONumber;
      this.poNumberForDisplay =
         this.managePO.getPurchaseOrderNumberForDisplay(this.purchaseOrder.poID)
            ?.poNumberForDisplay ?? "";
      this.editingPoNumber = false;
   }

   protected async mergePOs(): Promise<void> {
      if (this.purchaseOrder === undefined) return;
      const mergablePurchaseOrders = this.managePO
         .getPurchaseOrders()
         .filter((purchaseOrder) => {
            const currentState = this.managePO.getPurchaseOrderCurrentState(
               purchaseOrder.poID,
               this.purchaseOrderWorkflows,
            );
            const editableStatus = this.managePO.getPurchaseOrderEditable(
               purchaseOrder.poID,
               this.manageUser,
               this.credService,
               this.purchaseOrderWorkflows,
            );
            return Boolean(
               purchaseOrder.poID != this.purchaseOrder?.poID &&
                  currentState?.name === "Setup" &&
                  editableStatus?.editable &&
                  (purchaseOrder.vendorID === this.purchaseOrder?.vendorID ||
                     purchaseOrder.vendorID == 0 ||
                     this.purchaseOrder?.vendorID === 0),
            );
         });
      if (mergablePurchaseOrders.size === 0) {
         this.alertService.addAlert(
            this.lang().YouDoNotHaveAnyPOsToMerge,
            "danger",
            6000,
         );
         return;
      }
      const instance = this.modalService.open(PickPOs);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: this.lang().PickPurchaseOrder,
               message: this.lang().MergePOsMessage,
               selectOne: false,
               showPOsWaitingToBeReceived: false,
               locationID: 0,
               allowCreate: false,
               initialPOs: mergablePurchaseOrders,
            },
         },
      };
      const result = await instance.result;
      if (!result?.length) {
         return;
      }
      const poIDs: any = [];
      for (const item of result) {
         if (item.poID) {
            poIDs.push(item.poID);
         }
      }
      if (!poIDs?.length) {
         return;
      }
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const answer = await this.managePO.mergePurchaseOrders(
         this.purchaseOrder?.poID,
         poIDs,
      );
      this.loadingBarService.remove();
      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }
      this.alertService.addAlert(this.lang().POsMergedSuccessfully, "success", 6000);
   }

   private checkForPODefaults(): void {
      if (this.purchaseOrder === undefined) return;
      // If the PO doesn't have these set, then grab the location defaults
      if (!this.purchaseOrder.shipTo) {
         this.purchaseOrder.shipTo = this.location.poShipTo;
      }
      if (!this.purchaseOrder.billTo) {
         this.purchaseOrder.billTo = this.location.poBillTo;
      }
      if (!this.purchaseOrder.notesToVendor) {
         this.purchaseOrder.notesToVendor = this.location.poNotesToVendor;
      }
   }

   setProdService = async (poItemID: number, selectOne: boolean) => {
      const purchaseOrderItem = this.managePO.getPurchaseOrderItem(poItemID);
      if (
         this.editableStatus?.editable === false ||
         !this.purchaseOrder?.locationID ||
         purchaseOrderItem === undefined
      ) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOItems,
         ) &&
         !(this.editableStatus?.editable && this.purchaseOrder?.state === 0)
      ) {
         this.alertService.addAlert(this.lang().cred151Fail, "danger", 10000);
         return;
      }

      if (purchaseOrderItem.transactionIDs.length > 0) {
         this.alertService.addAlert(
            this.lang().WhoopsYouCantChangeAnItemOnceItHasBeenReceived,
            "warning",
            10000,
         );
         return;
      }

      const instance = this.modalService.open(PickPOItemsPartService);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               message: "",
               title: this.lang().SelectTheTypeOfPOItem,
               poItemID: purchaseOrderItem.poItemID,
               selectOne: selectOne,
            },
         },
      };

      const result = await instance.result.catch(() => {
         this.deleteUnwantedPurchaseOrderItem(purchaseOrderItem.poItemID);
      });

      if (result === 0) {
         this.deleteUnwantedPurchaseOrderItem(purchaseOrderItem.poItemID);
         return;
      }

      let partID;
      let assetID;
      let checklistID;
      let description;
      let qty;
      let rate;

      const type = result.type;
      if (result === false) {
         return;
      }
      if (result.value === undefined) {
         result.value = "";
      }
      if (result.type == 1) {
         //part
         partID = result.value[0];
         assetID = 0;
         checklistID = 0;
         description = "";

         const part = this.manageParts.getPart(partID);
         assert(part);
         qty = this.managePO.calcPurchaseOrderItemQty(part);

         //set up part description if desired
         const PODescription = this.manageLocation.getLocationPODescription(
            part.locationID,
         );

         if (PODescription != null) {
            const fields = PODescription.split(",");
            if (
               purchaseOrderItem.description == "" ||
               purchaseOrderItem.description == null
            ) {
               let newDescription = "";

               const partFields = this.manageParts.getFields();

               for (const valueID of part.partValueIDs) {
                  const fieldValue = this.manageParts.getFieldValue(valueID);
                  assert(fieldValue);
                  for (const POField of fields) {
                     if (partFields.get(fieldValue.fieldID)?.fieldName == POField) {
                        newDescription +=
                           fieldValue.valueContent === null ||
                           fieldValue.valueContent === undefined
                              ? ""
                              : `${fieldValue.valueContent} `;
                     }
                  }
               }

               purchaseOrderItem.description = newDescription;
               description = newDescription;

               this.managePO.setPurchaseOrderItemDescription(
                  purchaseOrderItem.poItemID,
                  purchaseOrderItem.description ?? "",
               );
            }
         }
         rate = 0;
      } else if (result.type == 2) {
         //service
         partID = 0;
         assetID = 0;
         checklistID = result.checklistID;
         description = result.value;
         qty = result.qty;
         rate = result.rate;
      } else if (result.type == 3) {
         // //asset
         // partID = 0;
         // assetID = 0;
         // checklistID = 0;
         // description = "";
         // qty = 0;
         // rate = 0;
      } else if (result.type == 4) {
         //other
         partID = 0;
         assetID = 0;
         checklistID = result.checklistID;
         description = result.value;
         qty = result.qty;
         rate = result.rate;
      }
      const answer = await this.managePO.setPurchaseOrderItemPartServiceDescription(
         purchaseOrderItem.poItemID,
         type,
         partID,
         assetID,
         checklistID,
         description,
         this.purchaseOrder.vendorID,
         qty,
         rate,
         purchaseOrderItem.glID,
      );

      if (answer.data.success) {
         this.managePO.setPurchaseOrderVendorItemRate(
            purchaseOrderItem.poItemID,
            Array.from(this.manageAssociations.getPartVendorRelations()),
         );
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else if (
         answer.data.success == false &&
         answer.data.reason === "already received"
      ) {
         this.alertService.addAlert(
            this.lang().WhoopsYouCantChangeAnItemOnceItHasBeenReceived,
            "warning",
            10000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }

      const childPoItemElement = this.poItems?.find(
         (childComponent) => childComponent.poItemID() === poItemID,
      );

      childPoItemElement?.initializeData();

      //customers were wanting to be able to add more then one PO item at a time (for parts) so this is a quick workaround to allow them to
      const additionalPurchaseOrderItemIDs: Array<number> = [];
      if (result.type == 1 && result.value.length > 1) {
         const newItemPromises: Array<Promise<AxiosResponse>> = [];
         for (let iteration = 1; iteration < result.value.length; iteration++) {
            newItemPromises.push(
               this.managePO.addPurchaseOrderItem(this.purchaseOrder.poID),
            );
         }
         const newItemResponses = await Promise.all(newItemPromises);
         const configurationResponses: Array<Promise<any>> = [];
         for (const [index, newItemResponse] of newItemResponses.entries()) {
            const newItemPartID = result.value[index + 1];
            const newPurchaseOrderItemID = newItemResponse.data.item.poItemID;
            additionalPurchaseOrderItemIDs.push(newPurchaseOrderItemID);

            configurationResponses.push(
               this.configureAdditionalPurchaseOrderItem(
                  newPurchaseOrderItemID,
                  1,
                  newItemPartID,
                  this.purchaseOrder.vendorID,
                  newItemResponse.data.item.glID,
               ),
            );
         }
         await Promise.all(configurationResponses);
      }
      const newPurchaseOrderItemIDs = [poItemID, ...additionalPurchaseOrderItemIDs];
      newPurchaseOrderItemIDs.forEach((id) => {
         this.managePO.refreshPurchaseOrderItem(id);
      });

      this.totals = this.managePO.getPurchaseOrderCostTotals(this.purchaseOrder.poID);
   };

   private async configureAdditionalPurchaseOrderItem(
      poItemID: number,
      type: number,
      partID: number,
      vendorID: number | null,
      glID: number,
   ) {
      const purchaseOrderItem = this.managePO.getPurchaseOrderItem(poItemID);
      if (purchaseOrderItem === undefined) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }
      let poPartQty = 0;
      let newDescription = "";

      const part = this.manageParts.getPart(partID);
      assert(part);
      this.manageParts.calculatePartData(part);
      const calculatedPartInfo = this.manageParts.getSingleCalculatedPartInfo(partID);
      assert(calculatedPartInfo);

      if (part.partMaxQtyThreshold && part.partMaxQtyThreshold > 0) {
         poPartQty =
            Number(part.partMaxQtyThreshold) -
            Number(calculatedPartInfo.totalAvailableQty);
      } else if (part.partOverstockedThreshold && part.partOverstockedThreshold > 0) {
         //its not set and a min threshold is set so do the calc for them
         poPartQty = part.partOverstockedThreshold - calculatedPartInfo.totalAvailableQty;
      } else {
         //nothing is set so set it to one part as a last catchall
         poPartQty = 1;
      }
      //sets description for each item selected.
      const PODescription = this.manageLocation.getLocationPODescription(part.locationID);
      if (PODescription != null) {
         const fields = PODescription.split(",");
         const partFields = this.manageParts.getFields();

         for (const valueID of part.partValueIDs) {
            const fieldValue = this.manageParts.getFieldValue(valueID);
            assert(fieldValue);
            for (const POField of fields) {
               if (partFields.get(fieldValue.fieldID)?.fieldName == POField) {
                  newDescription += `${fieldValue.valueContent} `;
               }
            }
         }
      }

      purchaseOrderItem.description = newDescription;
      const answer = await this.managePO.setPurchaseOrderItemPartServiceDescription(
         poItemID,
         type,
         partID,
         0,
         0,
         newDescription,
         vendorID,
         poPartQty,
         0,
         glID,
      );
      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      this.managePO.setPurchaseOrderVendorItemRate(
         purchaseOrderItem.poItemID,
         Array.from(this.manageAssociations.getPartVendorRelations()),
      );
      const childPoItemElement = this.poItems?.find(
         (childComponent) => childComponent.poItemID() === poItemID,
      );

      childPoItemElement?.initializeData();
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   private deleteUnwantedPurchaseOrderItem(poItemID: number): void {
      const purchaseOrderItem = this.managePO.getPurchaseOrderItem(poItemID);
      if (!purchaseOrderItem) return;
      // Only delete the item if they never actually picked an item
      if (purchaseOrderItem.itemType === 0) {
         this.managePO.deletePurchaseOrderItem(poItemID);
      }
   }
}
