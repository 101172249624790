import type { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { AlertService } from "src/app/shared/services/alert.service";

@Injectable({
   providedIn: "root",
})
export class AssetErrorService {
   private readonly alertService = inject(AlertService);
   protected i18n = inject(TranslationService).i18n;

   public handleRequestError(err: HttpErrorResponse) {
      if (!err?.error?.error) {
         this.alertService.addAlert(this.i18n().t("errorMsg"), "danger", 6000);
         return;
      }

      switch (err.error.error) {
         case "template is deleted":
         case "no published templates but template ID provided":
            this.alertService.addAlert(
               this.i18n().t("AssetTemplateWasDeletedError"),
               "danger",
               8000,
            );
            return;
         case "template names must be unique":
            this.alertService.addAlert(
               this.i18n().t("AssetTemplateNameUniqueError"),
               "danger",
               8000,
            );
            return;
         default:
            this.alertService.addAlert(this.i18n().t("errorMsg"), "danger", 6000);
      }
   }
}
