@if (logTooltip()) {
   <lim-ui-icon
      class="icon-styling"
      [icon]="logIcon() ?? 'notdef'"
      [iconColor]="iconColor() ?? 'inherit'"
      iconSize="small"
      placement="top"
      [limUiTooltip]="logTooltip()"
   />
} @else {
   <lim-ui-icon
      class="icon-styling"
      [icon]="logIcon() ?? 'notdef'"
      [iconColor]="iconColor() ?? 'inherit'"
      iconSize="small"
      placement="top"
   />
}

<span>
   @if (log().sourceID == sourceID.UpdatedPartName) {
      {{ lang().PartNameManuallyUpdatedTo }} <b>{{ log().newValue }}</b>
   } @else if (log().sourceID == sourceID.UpdatedPartNumber) {
      {{ lang().PartNumberManuallyUpdatedTo }}
      <b>
         @if (log().newValue === "") {
            blank
         } @else {
            <span [limbleHtml]="log().newValue"></span>
         }
      </b>
   } @else if (log().sourceID == sourceID.UpdatedPartQuantity) {
      @if (logDiff() < 0) {
         <b>{{ logDiff() * -1 }}</b>
         @if (logDiff() == -1) {
            {{ lang().partWas }}
         } @else {
            {{ lang().partsWere }}
         }
         {{ lang().added }}
      } @else {
         <b>{{ logDiff() }}</b>
         @if (logDiff() == 1) {
            {{ lang().partWas }}
         } @else {
            {{ lang().partsWere }}
         }
         {{ lang().removed }}
      }
      - {{ lang().ManuallyAdjusted }}
      @if (logHasReason() && !isQtyReasonTooLong()) {
         - {{ log().manualChangePartQtyReason }}
      }
   } @else if (log().sourceID == sourceID.AddedLogEntry) {
      {{ log().logDetails }}
   } @else if (log().sourceID == sourceID.TaskUsedPart) {
      <b>{{ formattedDiff() }}</b>
      @if (logDiff() == 1) {
         {{ lang().partWas }}
      } @else {
         {{ lang().partsWere }}
      }
      {{ lang().usedOnATask }}
   } @else if (log().sourceID == sourceID.UpdatedPartPrice) {
      {{ lang().PartPriceManuallyChangedTo }} <b>{{ updatedPartPrice() }}</b>
   } @else if (log().sourceID == sourceID.UpdatedPartStaleThreshold) {
      {{ lang().StaleThresholdManuallyChangedTo }} <b>{{ log().newValue }}</b>
      {{ lang().days }}
   } @else if (log().sourceID == sourceID.UpdatedPartOverstockedThreshold) {
      {{ lang().PartQtyThresholdManuallyChangedTo }} <b>{{ log().newValue }}</b>
   } @else if (log().sourceID == sourceID.DeletedPart) {
      {{ lang().PartWasMarkedAsDeleted }}
   } @else if (log().sourceID == sourceID.CreatedTaskToOrderMoreOfThisPart) {
      {{ log().logDetails }}
   } @else if (log().sourceID == sourceID.PartQuantityThresholdStatusChanged) {
      {{ lang().PartQtyThresholdStatusChangedToA }}
      @if (log().newValue == "1") {
         <b>{{ lang().red }}</b>
      } @else if (log().newValue == "0") {
         <b>{{ lang().green }}</b>
      }
      {{ lang().status }}
   } @else if (log().sourceID == sourceID.PartImportedOrBulkUpdated) {
      {{ log().logDetails }}
   } @else if (log().sourceID == sourceID.UpdatedPartLocation) {
      {{ lang().PartLocationManuallyChangedTo }}
      <span [limbleHtml]="log().newValue"></span>
   } @else if (log().sourceID == sourceID.UpdatedPartSupplier) {
      {{ lang().PartSupplierManuallyChangedTo }} <b>{{ log().newValue }}</b>
   } @else if (log().sourceID == sourceID.UpdatedPartImage) {
      {{ lang().PartImageWas }} <b>{{ log().newValue }}</b>
   } @else if (log().sourceID == sourceID.RestoredPartsBecauseCompletedTaskReopened) {
      <b>{{ logDiff() * -1 }}</b>
      @if (logDiff() == -1 || logDiff() == 1) {
         {{ lang().partWas }}
      } @else {
         {{ lang().partsWere }}
      }
      {{ lang().restoredBecauseTheCompletedTaskWOPMWasEdited }}
   } @else if (log().sourceID == sourceID.PartsUpdatedBecauseTaskEdited) {
      @if (logDiff() > 0) {
         <b>{{ logDiff() }}</b>
         @if (logDiff() == 1) {
            {{ lang().partWas }}
         } @else {
            {{ lang().partsWere }}
         }
         {{ lang().usedBecauseTheCompletedTaskWOPMWasEdited }}
      } @else {
         <b>{{ logDiff() * -1 }}</b>
         @if (logDiff() == -1) {
            {{ lang().partWas }}
         } @else {
            {{ lang().partsWere }}
         }
         {{ lang().restoredBecauseTheCompletedTaskWOPMWasEdited }}
      }
   } @else if (log().sourceID == sourceID.UpdatedPartFromPO) {
      @if (logDiff() < 0) {
         <b>{{ logDiff() * -1 }}</b>
         @if (logDiff() == -1) {
            {{ lang().partWas }}
         } @else if (logDiff() < -1) {
            {{ lang().partsWere }}
         }
         {{ lang().received }}
      } @else {
         <b>{{ logDiff() }}</b>
         @if (logDiff() == 1) {
            {{ lang().partWas }}
         } @else if (logDiff() > 1) {
            {{ lang().partsWere }}
         }
         {{ lang().removed }}
      }
   } @else if (log().sourceID == sourceID.UpdatedPartMaxQuantityThreshold) {
      {{ lang().PartMaxQtyThresholdManuallyChangedTo }} <b>{{ log().newValue }}</b>
   } @else if (log().sourceID == sourceID.UpdatedPartReceivedFromPO) {
      <b>{{ logDiff() * -1 }}</b>
      @if (logDiff() == -1) {
         {{ lang().partWas }}
      } @else {
         {{ lang().partsWere }}
      }
      {{ lang().markedAsUsedOn }}
   } @else if (log().sourceID == sourceID.UpdatedPartVenderQuantity) {
      {{ lang().VendorPartNumberManuallyUpdatedTo }}
      <b>
         @if (log().newValue === "") {
            blank
         } @else {
            {{ logPartNumber() }}
         }
      </b>
   } @else if (log().sourceID == sourceID.UpdatedPartVendorPrice) {
      {{ lang().VendorPartPriceManuallyUpdatedTo }}
      <b> {{ userCurrencySymbol }} {{ vendorLogPrice() }} </b>
   } @else if (log().sourceID == sourceID.CopiedPart) {
      {{ lang().ThisPartWasCopiedFrom }}
   } @else if (log().sourceID == sourceID.CreatedOnAssetTransfer) {
      {{ lang().PartCreatedDuringTransferAsset }}
      <b> {{ asset()?.assetName ?? "" }} </b>
      {{ lang().FromDifferentLocation }}
   } @else if (
      log().sourceID === sourceID.UpdatedPartUnitOfMeasure &&
      logStockUnitChangeDisplay() !== undefined
   ) {
      @if (logStockUnitChangeDisplay()?.displayFrom) {
         <span>
            {{ lang().StockUnitWasChangedFrom }}
            <strong [limUiTooltip]="logStockUnitChangeDisplay()?.displayFromTooltip">{{
               logStockUnitChangeDisplay()?.displayFrom
            }}</strong>
            {{ lang().To.toLowerCase() }}
            <strong [limUiTooltip]="logStockUnitChangeDisplay()?.displayToTooltip">{{
               logStockUnitChangeDisplay()?.displayTo
            }}</strong>
         </span>
      }

      @if (!logStockUnitChangeDisplay()?.displayFrom) {
         {{ lang().StockUnitWasChangedTo }}
         <strong [limUiTooltip]="logStockUnitChangeDisplay()?.displayToTooltip">{{
            logStockUnitChangeDisplay()?.displayTo
         }}</strong>
      }
   }

   @if (isAllPartsLog()) {
      <i
         >-
         @if (logPartName() !== "") {
            {{ logPartName() }} -
         }
         @if (logPartNumber() !== "") {
            <span [limbleHtml]="logPartNumber()"></span>
         }
         -
      </i>
   }
</span>
@if (isUpdatedPartQuantityLog()) {
   @if (isQtyReasonTooLong() == true && logHasReason()) {
      <a class="cursor" (click)="popAlert()">- {{ lang().Reason }}</a>
   }
}
@if (
   isTaskUsedPartLog() ||
   isRestoredPartsBecauseCompletedTaskReopenedLog() ||
   isPartsUpdatedBecauseTaskEditedLog()
) {
   @if (log().task; as task) {
      <a (click)="popTask(task)" class="cursor"
         ><span>{{ task.checklistName }}</span> - #{{ task.checklistID }}</a
      >
   } @else {
      <span> - ({{ lang().DeletedOrHidden }}) </span>
   }
}

@if (isCreatedTaskToOrderMoreOfThisPartLog()) {
   @if (log().task; as task) {
      <a (click)="popTask(task)" class="cursor">
         {{ lang().Task }}
         @if (task.checklistID) {
            #{{ task.checklistID }}
         }
      </a>
   } @else {
      <span> - ({{ lang().DeletedOrHidden }}) </span>
   }
}

@if (isUpdatedPartFromPOLog()) {
   <a (click)="popPR()" class="cursor">{{ lang().Bill }} {{ prNumber() }}</a>
   @if (logHasReason() && isQtyReasonTooLong() == false) {
      <span class="cursor">- {{ log().manualChangePartQtyReason }}</span>
   }
   @if (isQtyReasonTooLong() == true && logHasReason()) {
      <a class="cursor" (click)="popAlert()">- {{ lang().Reason }}</a>
   }
}

@if (isUpdatedPartMaxQuantityThresholdLog() && part()?.partID == 0) {
   {{ part()?.partName }} {{ logPartNumber() }}
}

@if (isUpdatedPartReceivedFromPOLog()) {
   <a (click)="popPR()" class="cursor"> {{ lang().Bill }} {{ prNumber() }} </a>
   <span> - {{ lang().ManuallyAdjusted }} </span>
   @if (logHasReason() && isQtyReasonTooLong() == false) {
      <span class="cursor">- {{ log().manualChangePartQtyReason }}</span>
   }
   @if (isQtyReasonTooLong() == true && logHasReason()) {
      <a class="cursor" (click)="popAlert()">- {{ lang().Reason }}</a>
   }
}

@if (isUpdatedPartVendorAssociationLog()) {
   <span>{{ lang().VendorAssociation }} </span>
   @if (log().newValue == "1") {
      <span
         ><b>{{ lang().added }}</b> -
      </span>
   }
   @if (log().newValue == "-1") {
      <span
         ><b>{{ lang().removed }}</b> -
      </span>
   }
   <a (click)="popVendor()" class="cursor"> {{ vendorName() }}</a>
}

@if (isUpdatedPartVenderQuantityLog() || isUpdatedPartVendorPriceLog()) {
   <span> - </span>
   <a (click)="popVendor()" class="cursor"> {{ vendorName() }} </a>
}

@if (isCopiedPartLog()) {
   <span> - </span>
   <a (click)="popPart()" class="cursor"> {{ lang().PartName }} </a>
}
