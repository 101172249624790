@for (assetParent of assetParentInfo; track assetParent) {
   <span>
      >
      @if (!preventParentAccess) {
         <span class="parent-list-item allowOffline">
            <span
               class="{{ linkColorClass }} {{ linkColorClass }}{{
                  hover && '-hover'
               }} cursor"
               [limbleHtml]="assetParent.assetName"
            ></span>
         </span>
      }
   </span>
}
@if (areAssetsLoaded() === false) {
   <span class="loading-icon">
      <lim-ui-loading-animation animationSize="extra-small" />
      <span class="loading-text lim-ui-fonts-body">{{ lang()?.Loading }}...</span>
   </span>
}
