@if (taskData(); as task) {
   @if (informationToInclude(); as infoToInclude) {
      <div class="task-heading">
         @if (
            task.taskDisplayData.locationTaskImage !== null &&
            task.taskDisplayData.locationTaskImage !== ""
         ) {
            <img
               [src]="
                  'viewFile.php?f=upload-' +
                  customerID +
                  '/locations/' +
                  task.locationID +
                  '/defaultTaskImage/' +
                  task.taskDisplayData.locationTaskImage
               "
               class="task-heading-img"
               [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
            />
         }

         @if (
            task.taskDisplayData.locationTaskImage === null &&
            task.taskDisplayData.locationTaskImage === ""
         ) {
            <div class="logo-section">
               @if (!useCustomLogo) {
                  <img [src]="logoURLDark" class="limble-logo" alt="Limble CMMS" />
               }
               @if (useCustomLogo) {
                  <img
                     [src]="customLogoURL"
                     class="custom-logo"
                     [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
                  />
               }
            </div>
         }

         <span [limbleHtml]="task.locationName"> </span>
      </div>
      <div class="task-title">
         @if (task.taskDisplayData.taskTypeIcon) {
            <lim-ui-icon
               [icon]="
                  task.checklistTemplate && task.checklistTemplate === 1
                     ? 'wrench'
                     : task.taskDisplayData.taskTypeIcon
               "
               iconSize="medium"
            />
         }
         <span [limbleHtml]="task.checklistName"></span>
         <span>- #{{ task.checklistID }} </span>
      </div>

      <div class="task-info-item-container lim-ui-list-item">
         @if (infoToInclude.status) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="bell" />{{ lang().Status }}
               </div>
               <div class="info-item-value">
                  {{ task.statusInfo?.statusName }}
               </div>
            </div>
         }

         @if (infoToInclude.priority) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="circleExclamation" />{{ lang().Priority }}
               </div>
               <div
                  class="info-item-value"
                  [limbleHtml]="task.priorityInfo?.priorityName"
               ></div>
            </div>
         }

         @if (infoToInclude.type) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="circleExclamation" />{{ lang().Type }}
               </div>
               <div class="info-item-value">
                  @if (task.taskDisplayData.taskTypeIcon) {
                     <lim-ui-icon [icon]="task.taskDisplayData.taskTypeIcon" />
                  }
                  {{ task.taskTypeStr }}
               </div>
            </div>
         }

         @if (infoToInclude.asset) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="cubes" />{{ lang().Asset }}
               </div>
               @if (assetNameStr()?.length) {
                  <div class="info-item-value" [limbleHtml]="assetNameStr()"></div>
               }
               @if (assetNameStr()?.length === 0) {
                  <div class="info-item-value">
                     {{ lang().None }}
                  </div>
               }
            </div>
         }

         @if (infoToInclude.assignment) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="user" />{{ lang().UpdateRelatedPMsAssignedTo }}
               </div>
               <div class="info-item-value">
                  <span> {{ task.taskDisplayData.displayName }}</span>
               </div>
            </div>
         }

         @if (task.checklistTemplate === 0 && infoToInclude.dueDate) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="calendarDays" />{{ lang().DueDate }}
               </div>
               @if (task.checklistDueDate !== null) {
                  <div class="info-item-value">
                     {{ task.checklistDueDateDisplay | betterDate: "date" }}
                  </div>
               }
            </div>
         }

         @if (infoToInclude.downtime) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="fireFlameCurved" />{{ lang().Downtime }}
               </div>
               <div class="info-item-value">
                  {{ task.checklistDowntime | secToHoursMinutes }}
               </div>
            </div>
         }

         @if (infoToInclude.location) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="houseChimney" />{{ lang().Location }}
               </div>
               <div class="info-item-value" [limbleHtml]="task.locationName"></div>
            </div>
         }

         @if (task.checklistCompletedDate && infoToInclude.completedBy) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="user" />{{ lang().CompletedBy }}
               </div>
               <div class="info-item-value">
                  <span> {{ task.taskDisplayData.completedByStr }}</span>
               </div>
            </div>
         }
         @if (task.checklistCompletedDate && infoToInclude.completedDate) {
            <div class="task-info-item">
               <div class="info-item-header">
                  <lim-ui-icon icon="calendarDays" />{{ lang().CompletedDate }}
               </div>
               <div class="info-item-value">
                  {{ task.completedDate | betterDate: "dateTime" }}
               </div>
            </div>
         }
      </div>
      <div class="sections-container">
         <!-- Description section -->
         @if (
            task.checklistInstructions &&
            task.checklistInstructions?.length &&
            infoToInclude.description
         ) {
            <div class="section">
               <div class="section-header">
                  {{ lang().Description | uppercase }}
               </div>
               @if (
                  (task.checklistEstTime && task.checklistEstTime <= 0) ||
                  !task.checklistEstTime
               ) {
                  <span [limbleHtml]="task.checklistInstructions"></span>
               }
               @if (task.checklistEstTime && task.checklistEstTime > 0) {
                  <span>
                     <div class="task-info-item">
                        <div class="info-item-value">
                           {{ lang().EstimatedTime }}:
                           {{ task.checklistEstTime | secToHoursMinutes }}
                        </div>
                     </div>
                  </span>
               }
            </div>
         }

         <!-- Asset Information -->
         @if (task.assetID && displayAssetInfo()) {
            <span class="section">
               <div class="section-header">
                  {{ lang().AssetInformation | uppercase }}
               </div>
               <!-- on open tasks -->
               @if (!task.checklistStatusID) {
                  <div>
                     @for (value of assetFieldValues(); track value) {
                        <div>
                           @if (
                              value.fieldID !== null &&
                                 manageAsset.getField(value.fieldID);
                              as field
                           ) {
                              <span>
                                 @if (
                                    field.fieldTypeID !== 3 &&
                                    field.fieldTypeID !== 4 &&
                                    field.displayOnTasks === 1
                                 ) {
                                    <div>
                                       @if (
                                          manageAsset.getFieldType(field.fieldTypeID);
                                          as fieldType
                                       ) {
                                          <span>
                                             <span>
                                                @if (fieldType.fieldTypeIcon) {
                                                   <lim-ui-icon
                                                      [icon]="
                                                         fieldType.fieldTypeIcon
                                                            | iconAlias
                                                      "
                                                   />
                                                }

                                                {{
                                                   field.fieldName
                                                      ? field.fieldName
                                                      : "Unnamed"
                                                }}:
                                             </span>
                                          </span>
                                       }
                                       <span class="asset-field-value">{{
                                          field.fieldTypeID === 2
                                             ? (value.valueContent | betterDate: "date")
                                             : field.fieldTypeID === 6
                                               ? (value.valueContent | betterCurrency)
                                               : value.valueContent
                                       }}</span>
                                    </div>
                                 }
                              </span>
                           }
                        </div>
                     }
                  </div>
               }
               <!-- on closed tasks -->
               @if (task.checklistStatusID && assetInfoFromCompletionArr()?.length) {
                  <div>
                     @for (field of assetInfoFromCompletionArr(); track field) {
                        <div>
                           @if (field.assetID === task.assetID) {
                              <div>
                                 <span>
                                    @if (field.fieldTypeIcon) {
                                       <lim-ui-icon
                                          [icon]="field.fieldTypeIcon | iconAlias"
                                       />
                                    }

                                    {{ field.fieldName ? field.fieldName : "Unnamed" }}:
                                 </span>
                                 <span class="asset-field-value">{{
                                    field.fieldTypeID === 2
                                       ? (field.valueContent | betterDate: "date")
                                       : field.fieldTypeID === 6
                                         ? (field.valueContent | betterCurrency)
                                         : field.valueContent
                                 }}</span>
                              </div>
                           }
                        </div>
                     }
                  </div>
               }
            </span>
         }

         <!-- Invoices section -->
         @if (
            task.invoices?.length &&
            task.taskCredentials?.viewInvoicesCred &&
            infoToInclude.invoices
         ) {
            <span class="section">
               <div class="section-header">
                  {{ lang().Invoices | uppercase }}
               </div>
               <div>
                  @for (invoice of task.invoices; track invoice.invoiceID) {
                     <div>
                        <span>
                           @if (invoice.invoiceFileName) {
                              {{ invoice.invoiceFileName }}
                              {{ lang().forATotalOf }}
                           } @else {
                              {{ lang().InvoiceForATotalOf }}
                           }
                           <span>
                              <cost-viewer-component
                                 [rate]="invoice.invoiceCost"
                                 [locationID]="taskData()?.locationID"
                                 [currencySymbol]="currencySymbol"
                              />
                           </span>
                        </span>

                        <span> - #{{ invoice.invoiceID }} </span>

                        <span [hidden]="!invoice.invoiceDescription?.length">
                           - <span [limbleHtml]="invoice.invoiceDescription"></span>
                        </span>
                        @if (
                           invoice.poItemID &&
                           invoice.poItemID > 0 &&
                           currentUser.workOrderUser !== 1
                        ) {
                           <span>
                              -
                              {{ lang().PO }} - {{ getPoNumber(invoice.poItemID) }} ({{
                                 getPoState(invoice.poItemID)
                              }})
                           </span>
                        }
                     </div>
                  }
               </div>
            </span>
         }

         <!-- Parts section -->
         @if (task.parts && task.parts?.length && infoToInclude.parts) {
            <span class="section">
               <div class="section-header">
                  {{ lang().Parts | uppercase }}
               </div>

               @for (part of task.parts; track part) {
                  <div>
                     @if (partsLookup.get(part.partID); as associatedPart) {
                        <div class="part-container">
                           <lim-ui-icon icon="gears" />
                           <span>
                              @if (task.checklistUserCompleted === 0) {
                                 <span>{{ part.suggestedNumber }}</span
                                 >&nbsp;
                              } @else {
                                 <span> {{ part.usedNumber }}</span
                                 >&nbsp;
                              }
                              <span [limbleHtml]="part.partName"></span>
                              @if (
                                 part?.partNumber !== null && part?.partNumber?.length
                              ) {
                                 <span>
                                    <span> - </span>
                                    <span [limbleHtml]="part.partNumber"></span>
                                 </span>
                              }
                              @let partPhysicalLocation = associatedPart?.partLocation;
                              @if (partPhysicalLocation) {
                                 <span>
                                    <span> - </span>
                                    <span [limbleHtml]="partPhysicalLocation"></span
                                 ></span>
                              }
                              @let partLocationName =
                                 getPartLocationName(part.locationID);
                              @if (partLocationName) {
                                 <span>
                                    <span> - </span>
                                    <span [limbleHtml]="partLocationName"></span
                                 ></span>
                              }
                              @if (
                                 (task.checklistUserCompleted ?? 0) > 0 &&
                                 partCostOnPrintFlag === 1
                              ) {
                                 <span>
                                    <span> - </span>
                                    {{ part.usedPrice | betterCurrency }}
                                    {{ lang().partEachTotaling }}
                                    <b>{{
                                       (part.usedPrice ?? 0) * (part.usedNumber ?? 0)
                                          | betterCurrency
                                    }}</b>
                                 </span>
                              }
                           </span>
                        </div>
                     }
                  </div>
               }
            </span>
         }

         <!-- Instructions section -->
         @if (infoToInclude.instructions) {
            <span class="instructions section">
               <div class="section-header">
                  {{ lang().Instructions | uppercase }}
               </div>
               <span class="instruction-items">
                  @for (
                     instruction of taskInstructions() | filter: { itemParentID: 0 };
                     track instruction
                  ) {
                     @if (taskInfo(); as taskFormSettings) {
                        <chk-item
                           [info]="taskFormSettings"
                           [taskInstructionsViewParameters]="
                              taskInstructionsViewParameters()
                           "
                           [item]="instruction"
                           class="task-item"
                        />
                     }
                  }
               </span>
            </span>
         }

         <!-- Time Spent section -->
         @if (
            (task.checklistPromptTime || task.extraTime?.length) &&
            infoToInclude.timeSpent
         ) {
            <span class="section">
               <div class="section-header">
                  {{ lang().TimeSpent | uppercase }}
               </div>
               @if (task.checklistCompletedDate) {
                  <span class="time-spent-item">
                     {{ task.checklistCompletedDate * 1000 | betterDate: "dateTime" }}
                     - {{ task.taskDisplayData?.checklistPromptTimeHours }}
                     {{ lang().hr }}
                     {{ lang().and }}
                     {{ task.taskDisplayData?.checklistPromptTimeMinutes }}
                     {{ lang().min }}
                     {{ lang().completedBySomeone }}
                     {{ task.taskDisplayData?.completedFirstName }}
                     {{ task.taskDisplayData?.completedLastName }}
                     @if (task.categoryID) {
                        <span>
                           |
                           {{ lang().BillableHours }}:
                           <span>
                              {{ task.taskDisplayData?.billableHours }}
                              {{ lang().hr }}
                              {{ lang().and }}
                              {{ task.taskDisplayData?.billableMinutes }}
                              {{ lang().min }}
                              @if (categoriesIndex && categoriesIndex[task.categoryID]) {
                                 <span
                                    [limbleHtml]="
                                       categoriesIndex[task.categoryID]?.categoryName
                                    "
                                 ></span>
                              }
                              @if (task.taskCredentials?.viewLaborCostsCred) {
                                 <span>
                                    {{ lang().at }}
                                    <cost-viewer-component
                                       [rate]="task.billableRate"
                                       [locationID]="taskData()?.locationID"
                                       [currencySymbol]="currencySymbol"
                                    />
                                    {{ lang().perHour }}
                                 </span>
                              }
                           </span>
                        </span>
                     }

                     @if (task.extraTimeNotes?.length) {
                        <span>
                           | {{ lang().Description }}:
                           <span [limbleHtml]="task.extraTimeNotes"></span>
                        </span>
                     }
                  </span>
               }
               @if (task.extraTime?.length) {
                  @for (time of task.extraTime; track time) {
                     <span class="time-spent-item">
                        <lim-ui-icon icon="clock" />
                        <div>
                           <span>
                              {{ (time.loggedAt ?? 0) * 1000 | betterDate: "dateTime" }}
                           </span>
                           {{ time?.promptTime | secToHoursMinutes }}

                           {{ lang().completedBySomeone }}
                           @if (getUserFullName(time.user); as name) {
                              {{ name }}
                           } @else {
                              <span limUiTooltip="{{ lang().DeletedUserTooltip }}">{{
                                 lang().DeletedUser
                              }}</span>
                           }
                           <b>|</b>
                           @if (time.categoryID) {
                              <span>
                                 {{ lang().BillableHours }}:
                                 <span>
                                    {{ time?.billableTime | secToHoursMinutes }}

                                    <span
                                       [limbleHtml]="
                                          categoriesIndex[time.categoryID]?.categoryName
                                       "
                                    ></span>
                                    @if (task.taskCredentials?.viewLaborCostsCred) {
                                       <span>
                                          {{ lang().at }}
                                          <cost-viewer-component
                                             [rate]="time?.billableRate"
                                             [locationID]="taskData()?.locationID"
                                             [currencySymbol]="currencySymbol"
                                          />
                                          {{ lang().perHour }}
                                       </span>
                                    }
                                 </span>
                              </span>
                           }

                           @if (time.notes && time.notes.length > 0) {
                              <span>
                                 |
                                 {{ lang().Description }}:
                                 <span [limbleHtml]="time.notes"></span>
                              </span>
                           }
                        </div>
                     </span>
                  }
               }
            </span>
         }

         <!-- Comments section -->
         @if (
            task.comments &&
            task.comments?.length &&
            task.checklistTemplate === 0 &&
            infoToInclude.comments
         ) {
            <span class="comments section">
               <div class="section-header">
                  {{ lang().Comments | uppercase }}
               </div>
               @for (note of task.comments; track note.noteID) {
                  <div [ngClass]="{ printHide: note.noteHidden === 1 }">
                     @if (isCommentVisible(note)) {
                        <div class="comment-list-item">
                           <div>
                              <span [limbleHtml]="note.noteMessage"></span>
                              @if (note.relatedChecklistID) {
                                 <span>
                                    •
                                    @if (note.relatedChecklistName) {
                                       <span
                                          [limbleHtml]="note.relatedChecklistName"
                                       ></span>
                                    }
                                    &nbsp;#{{ note.relatedChecklistID }}
                                 </span>
                              }
                           </div>
                           @if (
                              task.taskDisplayData?.noteData?.get(note.noteID);
                              as noteDisplayData
                           ) {
                              <div class="note-files-container">
                                 @for (file of noteDisplayData.files; track file) {
                                    @if (!file.isImage) {
                                       <file-list-item
                                          [file]="file"
                                          url="{{ file.getURL }}"
                                       />
                                    }
                                 }
                              </div>
                              <div class="note-images-container">
                                 @for (file of noteDisplayData.files; track file) {
                                    @if (file.isImage) {
                                       <view-image
                                          class="task-print"
                                          [src]="file.getURL"
                                       />
                                    }
                                 }
                              </div>
                              <span class="comment-footer">
                                 {{ noteDisplayData.displayName }}
                                 •
                                 {{
                                    (note.noteTimestamp ?? 0) * 1000
                                       | betterDate: "dateTime"
                                 }}
                              </span>
                           }
                        </div>
                     }
                  </div>
               }
            </span>
         }

         <!-- Completion Notes-->
         @if (task.checklistComments?.length && infoToInclude.completionNotes) {
            <span class="section">
               <div class="section-header">
                  {{ lang().CompletionNotes | uppercase }}
               </div>
               <div>
                  <div
                     type="text"
                     class="div-text-box"
                     [limbleHtml]="task.checklistComments"
                  ></div>
               </div>
            </span>
         }
      </div>
   }
}
