import { Component, input, output, signal, type OnInit } from "@angular/core";
import { IconComponent, LimbleHtmlDirective } from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import {
   TableSortDirection,
   type SortEvent,
   type TableColumn,
} from "src/app/shared/empowered/base/table/table.models";

@Component({
   selector: "e-table-column-header",
   templateUrl: "./table-column-header.component.html",
   styleUrls: ["./table-column-header.component.scss"],
   imports: [IconComponent, LimbleHtmlDirective, TranslateDirective],
})
export class TableColumnHeaderComponent implements OnInit {
   initialSort = input<SortEvent>();
   column = input<TableColumn>();

   public readonly sortChange = output<SortEvent>();

   sortDirection = signal<TableSortDirection>(TableSortDirection.UNSET);

   public ngOnInit() {
      const sort = this.column()?.sortBy;
      if (sort !== undefined) {
         if (this.initialSort()?.columnKey === sort) {
            this.sortDirection.set(this.initialSort()?.sort as TableSortDirection);
         }
      }
   }

   public onSortDirectionClicked() {
      const direction = this.getNextSortDirection(this.sortDirection());
      this.sortDirection.set(direction);
      this.sortChange.emit({
         columnKey: this.column()?.field ?? "",
         sort: direction,
      });
   }

   private getNextSortDirection(currentDirection: TableSortDirection) {
      if (currentDirection === TableSortDirection.UNSET) {
         return TableSortDirection.ASC;
      }
      if (currentDirection === TableSortDirection.ASC) {
         return TableSortDirection.DESC;
      }
      if (currentDirection === TableSortDirection.DESC) {
         return TableSortDirection.UNSET;
      }
      return TableSortDirection.UNSET;
   }
}
