import { CommonModule } from "@angular/common";
import { inject, Component, Input, computed } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { ManagePriority } from "src/app/tasks/services/managePriority";

@Component({
   imports: [CommonModule],
   selector: "task-priority-name-cell",
   templateUrl: "./task-priority-name-cell.component.html",
})
export class TaskPriorityNameCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   protected priorityList: Record<number, string>;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.priorityList = Object.fromEntries(
         inject(ManagePriority)
            .getPriorityList()
            .map((priority) => [priority.priorityID, String(priority.color ?? "")]),
      );
   }
}
