import { computed } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import type {
   CreateQueryResult,
   QueryObserverResult,
} from "@tanstack/angular-query-experimental";
import type { Observable } from "rxjs";

export function mapOfficialTanstackQueryToNgNeatInterface<T, Error>(
   query: CreateQueryResult<T, Error>,
): {
   result$: Observable<QueryObserverResult<T, Error>>;
} {
   const queryObserverResult$ = toObservable(
      computed(() => {
         const data = query.data();
         const isSuccess = query.status() === "success";
         const isFetching = query.fetchStatus() === "fetching";
         const error = query.error();
         const isError = query.status() === "error";
         const isPending = query.status() === "pending";
         const isLoading = query.isLoading();
         const isLoadingError = query.isLoadingError();
         const isRefetchError = query.isRefetchError();
         const isPlaceholderData = query.isPlaceholderData();
         const status = query.status();
         const dataUpdatedAt = query.dataUpdatedAt();
         const errorUpdatedAt = query.errorUpdatedAt();
         const failureCount = query.failureCount();
         const failureReason = query.failureReason();
         const fetchStatus = query.fetchStatus();
         const errorUpdateCount = query.errorUpdateCount();
         const isFetched = query.isFetched();
         const isFetchedAfterMount = query.isFetchedAfterMount();
         const isInitialLoading = query.isLoading();
         const isRefetching = query.isRefetching();
         const isPaused = query.isPaused();
         const isStale = query.isStale();
         const refetch = query.refetch();
         const promise = query.promise();

         return {
            data,
            isSuccess,
            isFetching,
            error,
            isError,
            isPending,
            isLoading,
            isLoadingError,
            isRefetchError,
            isPlaceholderData,
            status,
            dataUpdatedAt,
            errorUpdatedAt,
            failureCount,
            failureReason,
            fetchStatus,
            errorUpdateCount,
            isFetched,
            isFetchedAfterMount,
            isInitialLoading,
            isRefetching,
            isPaused,
            isStale,
            refetch,
            promise,
         };
      }),
   );
   const queryObserverResultTyped = queryObserverResult$ as unknown as Observable<
      QueryObserverResult<T, Error>
   >;

   return { result$: queryObserverResultTyped };
}
