import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageImonnit } from "src/app/assets/services/manageImonnit";
import { ManageLang } from "src/app/languages/services/manageLang";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "attach-sensor-to-asset",
   templateUrl: "./attachSensorToAsset.modal.component.html",
   styleUrls: ["./attachSensorToAsset.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LoadingAnimationComponent,
      NgClass,
      AlertComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
      OrderByPipe,
   ],
})
export class AttachSensorToAsset implements OnInit {
   public loading;
   public errorMsg;
   public message;
   public title;
   public selectedSensor;
   public selectedPoint;
   public sensorAttached;
   public dataPoints;
   public sensorList;
   public resolve;
   public modalInstance;
   public result;
   public units;
   public newValues;

   private readonly manageImonnit = inject(ManageImonnit);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.loading = true;
      this.errorMsg = "";
      this.message = this.resolve.message;
      this.title = this.resolve.title;

      this.result = {};
      this.selectedSensor = 0;
      this.selectedPoint = undefined;

      this.sensorAttached = this.resolve.sensorAttached;
      this.dataPoints = [];

      //Get the list of sensors
      this.manageImonnit.getSensorList().then((answer) => {
         this.loading = false;
         if (answer.data.success) {
            this.sensorList = JSON.parse(answer.data.result);
         } else {
            //error
         }
      });
   }

   close = () => {
      this.modalInstance.close(this.result);
   };

   focusSensor = (sensorID) => {
      this.dataPoints = [];
      this.selectedPoint = undefined;
      this.errorMsg = "";
      this.selectedSensor = sensorID;
      this.manageImonnit.getLastSensorReading(this.selectedSensor).then((answer) => {
         if (answer.data.success) {
            this.dataPoints = answer.data.reading.PlotLabels.split("|");
            this.units = answer.data.reading.DataTypes.split("|");
            this.newValues = answer.data.reading.PlotValues.split("|");
            this.focusPoint(0);

            for (const sensor of this.sensorList) {
               if (sensor.SensorID == this.selectedSensor) {
                  sensor.DisplayData = answer.data.reading.DisplayData;
               }
            }
         } else {
            this.errorMsg = this.lang().NoRecentSensorReadingsAndCannotAttachSensor;
            this.selectedSensor = 0;
         }
      });
   };

   focusPoint = (point) => {
      this.selectedPoint = point;
   };

   submit = () => {
      if (this.selectedSensor == 0 || this.selectedPoint == undefined) {
         this.errorMsg = this.lang().PleaseSelectASensor;
      } else {
         this.result.selectedSensor = this.selectedSensor;
         this.result.selectedPoint = this.selectedPoint;
         this.result.newLabels = [
            this.dataPoints[this.selectedPoint],
            this.units[this.selectedPoint],
         ];
         this.result.newValue = this.newValues[this.selectedPoint];
         this.close();
      }
   };
}
