<ai-modal (close)="close()">
   <div modalTitle>Select Assets to Search for Documents</div>
   <lim-ui-scroll-container>
      <div class="modal-body">
         <lim-ui-panel>
            <div class="panel-body">
               <div class="tip">
                  Tip: For faster results, select only a few assets with documents at a
                  time.
               </div>
               <lim-ui-search-box
                  [(searchVal)]="search"
                  [focusOnLoad]="true"
                  [fullWidth]="true"
               />
               <div class="list-actions">
                  <lim-ui-selection-controls
                     collapseAll="Collapse All"
                     (handleCollapseAll)="hierarchy()?.collapseAll()"
                     selectAll="Select All"
                     (handleSelectAll)="hierarchy()?.markAllAssetSelection()"
                     deselectAll="Deselect All"
                     (handleDeselectAll)="hierarchy()?.clearAssetSelection()"
                  />
               </div>
               <div class="list-options">
                  <lim-ui-checkbox
                     label="Select all child assets"
                     [(model)]="autoSelectChildren"
                  />
               </div>
               <div class="asset-list">
                  @defer (when assets.value() !== undefined; prefetch on immediate) {
                     <pick-assets-hierarchy
                        [singleLocation]="locationID()"
                        [autoSelectChildren]="autoSelectChildren()"
                        [search]="search()"
                     />
                  } @placeholder {
                     <div class="asset-list-skeleton">
                        <ngx-skeleton-loader [theme]="skeletonTheme" />
                        <ngx-skeleton-loader [theme]="skeletonTheme" />
                        <ngx-skeleton-loader [theme]="skeletonTheme" />
                     </div>
                  }
               </div>
            </div>
         </lim-ui-panel>
      </div>
   </lim-ui-scroll-container>
   <div modalFooter class="modal-footer">
      <lim-ui-secondary-button (click)="close()"> Cancel </lim-ui-secondary-button>
      <lim-ui-primary-button
         (click)="submit()"
         [disabled]="getSelectedAssetIDs().length === 0"
      >
         Next: Select Documents
      </lim-ui-primary-button>
   </div>
</ai-modal>
