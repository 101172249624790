import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";

export type PartCostEfficiencySavingsCellViewModel = {
   partCostEfficiencySavings?: number;
   locationID?: number;
   currencyCode: string;
};

@Component({
   selector: "part-cost-efficiency-savings-cell",
   imports: [CommonModule, LocaleCurrencyPipe],
   templateUrl: "./part-cost-efficiency-savings-cell.component.html",
})
export class PartCostEfficiencySavingsCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: PartCostEfficiencySavingsCellViewModel;
   @Input() public column?: Column;
}
