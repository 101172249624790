import { CommonModule, NgClass } from "@angular/common";
import { Component, inject, type OnDestroy, type OnInit } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import { IconComponent, LimbleHtmlDirective, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { TranslateService } from "src/app/languages/i18n/translate.service";
import { ButtonComponent } from "src/app/shared/empowered/base/button/button.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { CredService } from "src/app/users/services//creds/cred.service";

interface TaskNameCellData {
   isCompleted?: boolean;
   checklistName: string;
   checklistID: number;
   checklistComments?: string[];
   hasParts?: boolean;
   hasPartsInStock?: boolean;
   requestorInformation?: string;
   approvalItemState?: number;
}

@Component({
   selector: "task-name-cell-renderer",
   imports: [
      CommonModule,
      IconComponent,
      LimbleHtmlDirective,
      NgClass,
      TooltipDirective,
      TranslateDirective,
      ButtonComponent,
   ],
   templateUrl: "./task-name-cell-renderer.component.html",
   styleUrls: ["./task-name-cell-renderer.component.scss"],
})
export class TaskNameCellRendererComponent
   extends BaseCellRendererComponent<TaskNameCellData>
   implements OnInit, OnDestroy
{
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageStatus = inject(ManageStatus);
   private readonly manageObservables = inject(ManageObservables);
   public readonly manageAsset = inject(ManageAsset);
   protected readonly facade = inject(TasksFacadeService);
   protected readonly translateService = inject(TranslateService);

   protected get hasMoreThanTwoComments(): boolean {
      return (this.cell?.checklistComments?.length ?? 0) > 2;
   }

   protected get hasApproval(): boolean {
      return (this.cell?.approvalItemState ?? 0) > 0;
   }

   protected get partsInStockTooltip(): string | null {
      let tooltip: string | null = null;
      if (this.cell?.hasParts && this.cell?.isCompleted === false) {
         tooltip = this.translateService.instant("ThisTaskIsMissingParts");
         if (this.cell?.hasPartsInStock) {
            tooltip = this.translateService.instant("ThisTaskHasAllItsParts");
         }
      }
      return tooltip;
   }

   /**
    * This property is intended for the printable version of the corresponding custom dashboard task list widget.
    * It prevents unnecessary interactive elements from rendering in the print-only context.
    */
   public printOnly?: boolean;
   public searchHint: string | undefined = undefined;
   public dropdownClicked = false;
   public isAllowedToDelete: boolean = false;

   public statusListSub;
   public statusList: any = [];
   public statusListIndex = {};

   public isSuperUser = this.credService.checkCredGlobal(30);
   protected isInModal = this.facade.getIsInModal();

   public ngOnInit(): void {
      this.statusListSub = this.manageObservables.setSubscription("statusList", () => {
         this.statusList = [];
         setTimeout(() => {
            this.statusList = this.manageStatus.getStatusList();
            this.statusListIndex = this.manageStatus.getStatusListIndex();
         }, 1);
      });

      this.isAllowedToDelete = this.facade.isAllowedToDelete(
         this.cell as unknown as TaskDataViewerViewModel,
      );
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeManySubscriptions([this.statusListSub]);
   }

   public async viewTask(): Promise<void> {
      await this.facade.viewTask(this.cell.checklistID);
   }

   public completedTaskWarning(): void {
      this.alertService.addAlert(
         this.translateService.instant("completedTaskWarning"),
         "warning",
         10000,
      );
   }

   public deleteTask(): void {
      this.facade.deleteTask(this.cell as unknown as TaskDataViewerViewModel);
   }

   public onAssetClick(): void {
      //This even should open the Asset on desktop and the Task on mobile
      //The below combined with a flag passed into asset-hierarchy-list-item ensures that happens
      if (this.facade.isMobile) {
         this.facade.viewTask(Number(this.cell.checklistID));
      }
   }
}
