<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header
      [title]="title"
      (closeModal)="close()"
      id="popTaskCloseMe"
   />

   <lim-ui-modal-body>
      <lim-ui-info-panel [limbleHtml]="message" />

      <lim-ui-panel>
         <div class="lim-ui-form-column">
            <div class="lim-ui-form-group">
               <label>{{ lang().Note }}</label>
               <div
                  type="text"
                  [(ngModel)]="text"
                  disableEditor="true"
                  limbleContentEditable
               ></div>
            </div>
         </div>
      </lim-ui-panel>

      <lim-ui-panel>
         <div class="lim-ui-form-column">
            <div class="lim-ui-form-group">
               <label>{{ lang().Color }}</label>

               <lim-ui-dropdown>
                  <lim-ui-secondary-button button>
                     <div class="color-dropdown-button-content">
                        <div
                           style="background-color:#{{
                              color
                           }}; width: 15px; height: 15px; border-radius: 5px;"
                        ></div>
                        <span class="label"> #{{ color }} </span>
                        <lim-ui-icon icon="chevronDown" />
                     </div>
                  </lim-ui-secondary-button>
                  <div menu>
                     <lim-ui-dropdown-item (click)="$event.stopPropagation()">
                        <div
                           type="text"
                           class="exitOnEnterKeyPress"
                           [(ngModel)]="color"
                           limbleContentEditable
                           disableEditor="true"
                           placeholder="#000000"
                           (ngModelChange)="validateColor()"
                           (click)="$event.stopPropagation()"
                        ></div>
                     </lim-ui-dropdown-item>

                     @for (color of colors | orderBy: "displayName"; track color) {
                        <lim-ui-dropdown-item (click)="selectColor(color)">
                           <div
                              class="color-dropdown-item"
                              style="color: {{ color.colorCode }};"
                           >
                              <span>{{ color.name }}</span>
                              <span>{{ color.colorCode }}</span>
                           </div>
                        </lim-ui-dropdown-item>
                     }
                  </div>
               </lim-ui-dropdown>
            </div>

            <div class="lim-ui-form-group">
               <label>{{ lang().DateRange }}</label>
               <div>
                  <lim-ui-date-picker-input [(model)]="dt1" />
               </div>
               <span class="until-date-wrapper">
                  <span
                     [ngClass]="{
                        greyedOut: dt2 == null,
                     }"
                  >
                     {{ lang().until }}
                  </span>
                  <lim-ui-date-picker-input
                     [(model)]="dt2"
                     [ngClass]="{
                        greyedOut: dt2 == null,
                     }"
                  />
                  @if (dt2 != null) {
                     <span>
                        <lim-ui-icon
                           icon="xmark"
                           limUiTooltip="{{ lang().DontSetADateRange }}"
                           data-placement="bottom"
                           (click)="dt2 = null; $event.stopPropagation()"
                           iconSize="medium"
                           iconColor="danger"
                        />
                     </span>
                  }
               </span>
            </div>
         </div>

         <div class="user-selection-wrapper">
            <div class="user-selection-label">
               <span>{{ lang().WhoWouldYouLikeToSeeThisNote }}</span>
               <lim-ui-selection-controls
                  class="deselect-all-text-button"
                  [deselectAll]="lang().DeselectAll"
                  (handleDeselectAll)="deslectAllusers()"
               />
            </div>

            <div
               (click)="setAnyone()"
               class="lim-ui-list-item lim-ui-selectable-list-item"
               [ngClass]="{ 'lim-ui-selected-list-item': anyone == true }"
            >
               @if (anyone == true) {
                  <lim-ui-icon icon="check" iconSize="small" iconColor="success" />
               }
               {{ lang().AllUsers }}
            </div>
            @for (user of users | orderBy: "userLastName"; track user) {
               <div
                  (click)="focusUser(user)"
                  class="lim-ui-list-item lim-ui-selectable-list-item user-row-content"
                  [ngClass]="{ 'lim-ui-selected-list-item': user.selected }"
               >
                  @if (user.selected) {
                     <lim-ui-icon icon="check" iconSize="small" iconColor="success" />
                  }

                  <user-image [user]="user" />

                  <span> {{ user.userFirstName }} {{ user.userLastName }} </span>
               </div>
            }
            <span [hidden]="!(users?.length == 0)">{{ lang().NoUsersToPickFrom }}</span>
         </div>

         <div class="alert alert-dismissable alert-warning" [hidden]="!(errorMsg !== '')">
            <strong class="mr-1">{{ lang().Warning }}!</strong> -
            <div [limbleHtml]="errorMsg"></div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <div class="footer-buttons">
         @if (calendarNoteID > 0) {
            <lim-ui-danger-button (click)="deleteNote()">
               {{ lang().DeleteNote }}
            </lim-ui-danger-button>
         }
         <lim-ui-primary-button (click)="submit()">
            {{ SubmitBtnTxt }}
         </lim-ui-primary-button>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
