import { NgClass, NgStyle } from "@angular/common";
import { Component, computed, inject, Input, output } from "@angular/core";
import { ScrollContainerComponent, TooltipDirective } from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Part } from "src/app/parts/types/part.types";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import {
   DataViewerPaginatorComponent,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import type { SortParams } from "src/app/shared/services/flannel-api-service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { TaskDataViewerItemComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/task-data-viewer-item/task-data-viewer-item.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   selector: "tasks-data-viewer",
   templateUrl: "./tasks-data-viewer.component.html",
   styleUrls: ["./tasks-data-viewer.component.scss"],
   imports: [
      NgClass,
      SortColumn,
      TooltipDirective,
      NgStyle,
      ScrollContainerComponent,
      NoSearchResults,
      NgxSkeletonLoaderModule,
      SortColumn,
      TaskDataViewerItemComponent,
      DataViewerPaginatorComponent,
   ],
})
export class TasksDataViewerComponent {
   /**
    * List of tasks to be shown
    */
   @Input() public tasks: Array<TaskDataViewerViewModel> | null = null;

   /**
    * Potential part tasks may be viewed from.
    */
   @Input() public part: Part | undefined = undefined;

   /**
    * Total number of tasks
    */
   @Input() public totalTasks: number = 0;

   /**
    * List of columns to be shown
    */
   @Input() public columns: Array<Column> | undefined;

   /**
    * // Whether to show the loading bar
    */
   @Input() public isLoading = true;

   /**
    * Whether the current data is placeholder while loading the real data
    */
   @Input() public isPlaceholderData = false;

   /**
    * Whether to show the noSearchResults section
    */
   @Input() public noSearchResults = false;

   /**
    *  Location of pagination - top, bottom, or both
    */
   @Input() public paginationLocation = "bottom";

   @Input() public noHeaders; // If true, no column headers will be displayed
   @Input() public autoHeight; // If true, table height will be auto, not 100vh
   @Input() public tableInScrollablePage = false;
   @Input() public searchHints: LimbleMap<number, string> = new LimbleMap();
   @Input() public allowSortUnreadComments: boolean = true;

   public readonly tasksUpdated = output();

   public skeletonThemes;

   private readonly manageUtil = inject(ManageUtil);
   private readonly facade = inject(TasksFacadeService);
   private readonly manageLang = inject(ManageLang);
   protected readonly dataViewerState = inject(DataViewerStateService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected isInModal = this.facade.getIsInModal();

   public sort = this.dataViewerState.sort;

   public constructor() {
      this.skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();
   }

   protected onSortChanged(sort: SortParams): void {
      this.dataViewerState.setSort(sort);
   }

   protected onPageChange(newPage: number): void {
      this.dataViewerState.setPage(newPage);
   }

   protected onPageSizeChange(newPageSize: number): void {
      this.dataViewerState.setPageSize(newPageSize);
   }
}
