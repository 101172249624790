import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
   output,
   signal,
} from "@angular/core";
import {
   OutlinedButtonComponent,
   PrimaryButtonComponent,
   ScrollContainerComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import {
   PickUserOrProfileComponent,
   type PickUserOrProfileSelection,
} from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.component";
import type { ExtraUsersOptions } from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.types";

@Component({
   selector: "assignment-step",
   templateUrl: "./assignment-step.component.html",
   styleUrls: ["./assignment-step.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      ScrollContainerComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
      PickUserOrProfileComponent,
      SecondaryButtonComponent,
   ],
})
export class AssignmentStepComponent {
   public readonly locationID = input.required<number>();
   public readonly prevStep = output();
   public readonly nextStep = output<PickUserOrProfileSelection>();
   public readonly deleteTemplate = output();

   protected readonly i18n = inject(TranslationService).i18n;

   public selection = signal<PickUserOrProfileSelection | undefined>(undefined);

   protected readonly extraUsersOptions: ExtraUsersOptions = [
      {
         userFirstName: this.i18n().t("Unassigned"),
         userID: -101,
         profileID: 0,
         dynamicAssignment: 0,
      },
   ];

   public noSelectionMade = computed(() => {
      const selection = this.selection();
      if (selection === undefined) return true;
      return "selectedUsers" in selection && selection.selectedUsers.length === 0;
   });

   public saveAndContinue(): void {
      const selection = this.selection();
      if (selection === undefined) return;
      this.nextStep.emit(selection);
   }
}
