import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PopoverDirective,
   SecondaryButtonComponent,
   UpsellPopover,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import { Subscription, lastValueFrom } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { PickReoccur } from "src/app/schedules/recurrences/pickReoccurModal/pickReoccur.modal.component";
import { RecurrenceComponent } from "src/app/schedules/recurrences/recurrence/recurrence.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { TaskColorStatusConfig } from "src/app/tasks/components/taskColorStatusConfigElement/taskColorStatusConfig.element.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";

@Component({
   selector: "pop-recurrences",
   templateUrl: "./popRecurrences.modal.component.html",
   styleUrls: ["./popRecurrences.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      RecurrenceComponent,
      ModalFooterComponent,
      SecondaryButtonComponent,
      PopoverDirective,
      UpsellPopover,
      TaskColorStatusConfig,
   ],
})
export class PopRecurrences implements OnInit, OnDestroy {
   private readonly alertService = inject(AlertService);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly taskTemplateApiService = inject(TaskTemplatesApiService);
   private readonly manageLang = inject(ManageLang);
   private readonly manageTask = inject(ManageTask);

   public title;
   public message;
   public hint;
   public taskTemplate: Task | TaskTemplateEntity | undefined;
   public checklistID: number | undefined;
   public minDate;
   public modalInstance;
   public resolve;
   public data;
   protected recurrences: Lookup<"reoccurID", Recurrence> = new Lookup("reoccurID");
   public sortBind: keyof Recurrence = "reoccurID";
   private manageFeatureFlagsSub: Subscription = new Subscription();
   protected canToggleRecurrences: boolean = false;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.initialize();
   }

   private async initialize(): Promise<void> {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;

      if (this.data.title) {
         this.title = this.data.title;
      }

      if (this.data.message) {
         this.message = this.data.message;
      }

      if (this.data.hint) {
         this.hint = this.data.hint;
      }

      if (!this.data.checklistID) {
         throw new Error("No checklistID provided");
      }

      this.checklistID = this.data.checklistID;

      this.taskTemplate = await this.getTemplate();

      if (!this.taskTemplate) {
         return;
      }

      if (
         this.taskTemplate.recurrences == undefined ||
         this.taskTemplate.recurrences.length == 0
      ) {
         this.addRecurrence(this.taskTemplate);
      }
      for (const recurrence of this.taskTemplate.recurrences) {
         this.recurrences.set(recurrence.reoccurID, recurrence);
      }

      this.recurrences = this.recurrences.orderBy(this.sortBind);

      this.toggleMin();

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(() => {
         this.setToggleRecurrencesPermission();
      });
   }

   private async getTemplate() {
      return lastValueFrom(
         this.taskTemplateApiService.getById(this.data.checklistID, {
            params: { columns: "schedules,recurrences" },
         }),
      );
   }

   private async setToggleRecurrencesPermission() {
      const hitPmTemplateLimit =
         await this.manageFeatureFlags.hasReachedPmsSchedulesLimit();
      this.canToggleRecurrences =
         (this.taskTemplate as TaskTemplateEntity).schedules?.length !== 0 ||
         !hitPmTemplateLimit;
   }

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   protected deleteRecurrence(reoccurID: number): void {
      this.recurrences.delete(reoccurID);
   }

   protected updateRecurrence(reccurence) {
      this.recurrences.set(reccurence.reoccurID, reccurence);
   }

   toggleMin = () => {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDate = this.minDate ? null : date;
   };

   protected async addRecurrence(
      task: Task | TaskTemplateEntity | undefined,
   ): Promise<void> {
      if (!this.canToggleRecurrences) {
         return;
      }

      if (!task) {
         return;
      }

      const instance = this.modalService.open(PickReoccur);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().SetRecurrenceMsg,
            title: this.lang().SetRecurrence,
            reoccurID: null,
            checklistID: task.checklistID,
         },
      };

      const result = await instance.result;
      if (result === 0) {
         return;
      }
      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });

      const answer = await this.manageSchedule.saveSchedule(
         result.recurrence,
         task,
         result.reoccurSpawnInMilliseconds,
         result.reoccurSpawnEndInMilliseconds,
      );
      if (answer.data.success === true) {
         this.manageTask.getRecurrences().set(answer.data.rID, answer.data.sched);

         this.manageTask.incTasksWatchVar();

         this.taskTemplate = await this.getTemplate();
         for (const recurrence of this.taskTemplate.recurrences) {
            this.recurrences.set(recurrence.reoccurID, recurrence);
         }

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else if (answer.data.reason === "Date cannot be set in the past") {
         this.alertService.addAlert(
            this.lang().SaveSchedulePastDateStartsOnError,
            "danger",
            10000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
      this.loadingBarService.remove();
   }

   close = () => {
      this.modalInstance.close();
   };
}
