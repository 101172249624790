<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message-area" [limbleHtml]="message"></span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="dd" id="nestable_list_1">
            @for (field of fieldView; track field) {
               <div
                  (click)="selectField(field)"
                  class="lim-ui-list-item lim-ui-selectable-list-item"
                  [ngClass]="{
                     'lim-ui-selected-list-item': field.selected,
                     'lim-ui-disabled-list-item': field.lockedDefault,
                  }"
                  [limUiTooltip]="
                     field.lockedDefault
                        ? lang().FieldIsLockedAndCannotBeLinked
                        : undefined
                  "
               >
                  <div class="lim-ui-list-item-content">
                     <div class="item-text-area">
                        @if (field.selected) {
                           <lim-ui-icon
                              icon="check"
                              iconSize="small"
                              iconColor="success"
                           />
                        }
                        @if (field.fieldTypeID == 5) {
                           <lim-ui-icon icon="hashtagRegular" iconSize="small" />
                        }
                        @if (field.fieldTypeID == 6) {
                           <span class="currency-symbol-icon">{{ currencySymbol }}</span>
                        }
                        <div>
                           {{ field.fieldName }}
                           <span class="lock-icon">
                              @if (field.lockedDefault) {
                                 <lim-ui-icon
                                    icon="lockRegular"
                                    iconSize="small"
                                    placement="right"
                                    iconColor="medium-grey"
                                 />
                              }
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            }
         </div>

         @if (fields?.size == 0) {
            <span class="no-fields-text">
               @if (this.data.assetID) {
                  <span>
                     {{ lang().NoFields }}
                     <b [limbleHtml]="fieldTypeStr" class="red-color"></b>. <br /><br />
                     <span [limbleHtml]="lang().NoFieldsExplain"></span>
                     <lim-ui-icon icon="cube" iconSize="small" />
                     @if (assetName) {
                        <span> {{ assetName }}. </span>
                     }
                  </span>
               }
               @if (!this.data.assetID) {
                  <span>
                     {{ lang().NoFieldsAtLocationMsg }}
                     <b class="red-color">{{ fieldTypeStr }}</b>
                     <br />
                     <div>{{ lang().NoFieldsExplainNoAsset }}</div>
                  </span>
               }
            </span>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   @if (fields && fields.size > 0) {
      <lim-ui-basic-modal-footer
         [successButtonText]="lang().SetLink"
         (successClick)="submit()"
      />
   }
</lim-ui-modal>
