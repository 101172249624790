import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Tutorial } from "src/app/onboarding/services/tutorials/tutorial";
import type { TutorialSection } from "src/app/onboarding/services/tutorials/tutorial-section";
import { DocThumbnailComponent } from "src/app/shared/components/global/tutorials-modal/doc-thumbnail/doc-thumbnail.component";
import { VideoThumbnailComponent } from "src/app/shared/components/global/tutorials-modal/video-thumbnail/video-thumbnail.component";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "tutorials-modal",
   templateUrl: "./tutorials-modal.component.html",
   styleUrls: ["./tutorials-modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      PanelComponent,
      VideoThumbnailComponent,
      DocThumbnailComponent,
      ModalFooterComponent,
      LimbleHtmlDirective,
   ],
})
export class TutorialsModal implements OnInit {
   protected title: string = "";
   protected description: string = "";
   protected tutorials: Tutorial[] = [];

   private resolve: any;
   private modalInstance: any;
   private readonly window: any = window;

   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const tutorialSection: TutorialSection = this.resolve.tutorialSection;

      if (tutorialSection.title) {
         this.title = tutorialSection.title;
      }

      if (tutorialSection.description) {
         this.description = tutorialSection.description;
      }

      this.tutorials = tutorialSection.tutorials;
   }

   protected close = (): void => {
      this.modalInstance.close();
   };
}
