import type { OnInit } from "@angular/core";
import { Component, computed, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "po-email-defaults",
   templateUrl: "./poEmailDefaults.html",
   styleUrls: ["./poEmailDefaults.styles.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      PanelComponent,
      IconComponent,
      TooltipDirective,
      FormsModule,
      ContenteditableDirective,
      BasicModalFooterComponent,
   ],
})
export class POEmailDefaults implements OnInit {
   public errorMsg: string;
   public loadingBar: boolean;
   public isSubmitDisabled: boolean;
   public emailRecips;
   public emailSubject: string | undefined;
   public emailMessage: string | undefined;
   public modalInstance;
   private locationID;
   private location;
   private readonly managePO = inject(ManagePO);
   private readonly manageLang = inject(ManageLang);
   private readonly alertService = inject(AlertService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly paramsService = inject(ParamsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.errorMsg = "";
      this.loadingBar = false;
      this.isSubmitDisabled = false;
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.locationID = params.locationID;
      this.location = this.manageLocation.getLocation(this.locationID);
      const defaultEmailMessage = `${this.lang().Hello} {{vendor-contact}}, <br><br> ${this.lang().AttachedIsANewPurchaseOrder} ${this.lang().ThankYou}!`;
      this.emailRecips =
         this.location.poDefaultReadyToReceiveEmailRecips || "{{vendor-email}}";
      this.emailSubject =
         this.location.poDefaultReadyToReceiveEmailSubject ||
         this.lang().NewPurchaseOrder;
      this.emailMessage =
         this.location.poDefaultReadyToReceiveEmailMessage || defaultEmailMessage;
   }

   public close = () => {
      if (this.modalInstance !== undefined) {
         this.modalInstance.close(0);
      }
   };

   public submit = () => {
      this.loadingBar = true;
      this.managePO
         .setPOEmailDefaults(
            this.locationID,
            this.emailRecips,
            this.emailSubject,
            this.emailMessage,
         )
         .then((resp) => {
            if (resp.data.success) {
               this.location.poDefaultReadyToReceiveEmailRecips = this.emailRecips;
               this.location.poDefaultReadyToReceiveEmailSubject = this.emailSubject;
               this.location.poDefaultReadyToReceiveEmailMessage = this.emailMessage;
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               const data = {
                  emailRecips: this.emailRecips,
                  emailSubject: this.emailSubject,
                  emailMessage: this.emailMessage,
               };
               if (this.modalInstance !== undefined) {
                  this.modalInstance.close(data);
               }
            } else {
               this.loadingBar = false;
               this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
            }
         });
   };
}
