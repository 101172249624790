import {
   Component,
   computed,
   forwardRef,
   inject,
   input,
   type OnInit,
   type Signal,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { getExportableColumns } from "@empowered/base/table/utils/column-table-parser";
import {
   BasicModalHeaderComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   IconButtonComponent,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { ManageLang } from "src/app/languages/services/manageLang";
import {
   type DataViewerFilter,
   DataViewerFiltersComponent,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import type {
   TaskDataViewerViewModel,
   TasksDataViewerOptions,
} from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksDataViewerComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/tasks-data-viewer.component";
import { TaskViewType_TaskSchedulesCombined } from "src/app/tasks/components/shared/services/task-column-definitions-factory/task-column-definitions-factory.models";
import { TaskViewModelFactoryService } from "src/app/tasks/components/shared/services/task-view-model-factory/task-view-model-factory.service";
import {
   type TaskEntity,
   TasksApiService,
} from "src/app/tasks/components/shared/services/tasks-api";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { TasksSchedulesCombinedApiService } from "src/app/tasks/components/shared/services/tasks-schedules-combined-api/tasks-schedules-combined-api.service";

@Component({
   selector: "tasks-list-modal",
   templateUrl: "./tasks-list-modal.component.html",
   styleUrls: ["./tasks-list-modal.component.scss"],
   providers: [DataViewerStateService],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SearchBoxComponent,
      DropdownComponent,
      IconButtonComponent,
      TooltipDirective,
      DropdownTextItemComponent,
      PrimaryButtonComponent,
      FormsModule,
      UpsellPopover,
      TasksDataViewerComponent,
      forwardRef(() => DataViewerFiltersComponent),
   ],
})
export class TasksListModalComponent implements OnInit {
   // injections
   private readonly viewModelFactory = inject(TaskViewModelFactoryService);
   private readonly tasksApiService = inject(TasksApiService);
   private readonly tasksFacade = inject(TasksFacadeService);
   private readonly state = inject(DataViewerStateService);
   private readonly tasksAndSchedulesApi = inject(TasksSchedulesCombinedApiService);
   private readonly manageLang = inject(ManageLang);
   private readonly modalRef: LimUiModalRef<TasksListModalComponent, void> =
      inject(LimUiModalRef);

   // inputs
   /**
    * All the options that can be passed to the modal, this is to avoid having to `setInput`for each
    */
   public options = input.required<TasksDataViewerOptions>();
   protected readonly isTscModal = computed(
      () => this.options().type === TaskViewType_TaskSchedulesCombined,
   );

   // queries
   protected taskQueryDefault = injectQuery(() => {
      // eslint-disable-next-line typescript/no-unused-vars -- needed for listening
      const needsRefresh = this.needsRefresh();
      const options = this.state.requestOptions();

      return this.tasksApiService.getQueryListOptions(options, !this.isTscModal());
   });

   protected taskQuerySchedules = injectQuery(() => {
      // eslint-disable-next-line typescript/no-unused-vars -- needed for listening
      const needsRefresh = this.needsRefresh();
      const options = this.state.requestOptions();

      return this.tasksAndSchedulesApi.getQueryListOptions(options, this.isTscModal());
   });

   // signals
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly needsRefresh = toSignal(this.tasksFacade.taskNeedRefresh$);

   protected taskQuery = computed(() => {
      return this.isTscModal() ? this.taskQuerySchedules : this.taskQueryDefault;
   });

   public onlyExportVisibleData = computed(
      () => this.options().onlyExportVisibleData ?? false,
   );
   public hideBulkPrintButton = computed(
      () => this.options().hideBulkPrintButton ?? false,
   );

   public taskNeedRefresh = this.tasksFacade.taskNeedRefresh$;

   protected items: Signal<Array<TaskDataViewerViewModel>> = computed(() => {
      const query = this.taskQuery();
      if (!query) {
         return [];
      }

      const entities = query.data()?.data ?? ([] as Array<TaskDataViewerViewModel>);
      return this.viewModelFactory.getViewModelList(entities as Array<TaskEntity>);
   });

   protected totalItems = computed(() => {
      const query = this.taskQuery();
      if (!query) {
         return 0;
      }
      return query.data()?.total ?? 0;
   });
   protected readonly hasNoResults = computed<boolean>(() => {
      const query = this.taskQuery();
      if (!query) {
         return true;
      }
      return this.totalItems() === 0 && !query.isLoading();
   });
   protected readonly hasResults = computed(() => {
      const query = this.taskQuery();
      if (!query) {
         return false;
      }
      return this.totalItems() > 0 && !query.isLoading();
   });

   public ngOnInit() {
      // Initialize Façade
      this.tasksFacade.setIsInModal(true);
      this.tasksFacade.setDataLogSection("tasks");

      // Initialize DataViewer
      const { sortBy, columns, requestFilters } = this.options();

      this.state.initialize({
         sort: sortBy,
         columns,
         requestFilters: requestFilters ?? [],
      });
   }

   public onSearch($event): void {
      this.state.setSearch($event);
   }

   public onClose(): void {
      this.modalRef.close();
   }

   public async onExportToExcel(): Promise<void> {
      const filters = this.state.requestOptionsWithoutPagination();
      const isTaskAndSchedules = this.isTscModal();
      await this.tasksFacade.downloadExcel(filters, isTaskAndSchedules);
   }

   public async onExportExcelLimited(): Promise<void> {
      const filters = this.state.requestOptions();
      const isTaskAndSchedules = this.isTscModal();
      const exportableColumns = getExportableColumns(this.options().columns);
      await this.tasksFacade.downloadExcelLimited(
         filters,
         exportableColumns,
         isTaskAndSchedules,
      );
   }

   public async onBulkPrint(): Promise<void> {
      const filters = this.state.requestOptionsWithoutPagination();
      await this.tasksFacade.bulkPrint(filters);
   }

   public onStartWorkOrder(date?: Date | false): void {
      this.tasksFacade.startWorkOrder(date);
   }

   public async onSetFilter(dataViewerFilter: DataViewerFilter) {
      await this.state.addFilter(dataViewerFilter);
   }

   public async onRemoveFilter(dataViewerFilter: DataViewerFilter) {
      await this.state.removeFilter(dataViewerFilter);
   }
}
