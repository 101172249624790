import { formatNumber } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   TooltipDirective,
   type Aliases,
   type Colors,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { PartLogDataViewerViewModel } from "src/app/parts/components/part-logs-data-viewer/part-log-date-viewer.model";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { PartLogSourceID } from "src/app/parts/components/shared/services/logs-api/part-log-api.models";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { PrComponent } from "src/app/purchasing/bills/prWrapper/pr.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { AlertPopup } from "src/app/shared/components/global/alertModal/alertPopup.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageUser } from "src/app/users/services/manageUser";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "log-entry-cell",
   templateUrl: "./log-entry-cell.component.html",
   styleUrls: ["./log-entry-cell.component.scss"],
   imports: [IconComponent, TooltipDirective, LimbleHtmlDirective],
})
export class LogEntryCellComponent {
   private readonly manageLang = inject(ManageLang);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageVendor = inject(ManageVendor);
   private readonly managePO = inject(ManagePO);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly managePart = inject(ManageParts);
   private readonly manageAsset = inject(ManageAsset);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public log = input.required<PartLogDataViewerViewModel>();
   public isAllPartsLog = input<boolean>(false);
   public part = computed(() => {
      return this.managePart.getPart(this.log().partID);
   });
   public asset = computed(() => {
      if (this.log().sourceID === this.sourceID.CreatedOnAssetTransfer) {
         return this.manageAsset.getAsset(this.log().associatedID);
      }
      return undefined;
   });

   protected readonly colorRed: Colors = "danger";
   protected readonly colorGreen: Colors = "success";
   public readonly sourceID = PartLogSourceID;

   protected readonly userCurrencySymbol =
      this.manageUser.getCurrentUser().currency.symbol;

   protected readonly vendorLogPrice = computed(() => {
      const partPrice = this.part()?.partPrice;
      if (partPrice === null || partPrice === undefined) {
         return undefined;
      }
      return formatNumber(partPrice, this.manageLang.getLocaleID(), "1.2-2");
   });

   protected readonly updatedPartPrice = computed(() => {
      const partPrice = Number(this.log().newValue);
      if (partPrice === null || partPrice === undefined) {
         return undefined;
      }
      return formatNumber(partPrice, this.manageLang.getLocaleID(), "1.2-2");
   });

   protected readonly vendorName = computed(() => {
      const log = this.log();
      const vendor = this.manageVendor.getVendor(log.associatedID);
      return vendor?.vendorName;
   });

   protected readonly logPrID = computed(() => {
      const log = this.log();
      if (log.sourceID === PartLogSourceID.UpdatedPartFromPO) {
         const getTransactionResponse = this.managePO.getBillTransaction(
            log.associatedID,
         );
         if (getTransactionResponse?.prID) {
            return getTransactionResponse.prID;
         }
      } else if (log.sourceID === PartLogSourceID.UpdatedPartReceivedFromPO) {
         const getBillResponse = this.managePO.getBill(log.associatedID);
         if (getBillResponse) {
            return log.associatedID;
         }
      }
      return undefined;
   });

   protected readonly prNumber = computed(() => {
      const log = this.log();
      if (log.sourceID === PartLogSourceID.UpdatedPartFromPO) {
         const getTransactionResponse = this.managePO.getBillTransaction(
            log.associatedID,
         );
         if (getTransactionResponse?.prID) {
            const prID = getTransactionResponse.prID;
            return this.managePO.getBillNumberForDisplay(prID);
         }
      } else if (log.sourceID === PartLogSourceID.UpdatedPartReceivedFromPO) {
         const getBillResponse = this.managePO.getBill(log.associatedID);
         if (getBillResponse) {
            return getBillResponse.prNumber;
         }
      }
      return undefined;
   });

   protected readonly logPartName = computed(() => {
      return this.part()?.partName ?? "";
   });

   protected readonly logPartNumber = computed(() => {
      const log = this.log();
      const vendorRelation = this.manageAssociations
         .getAssociatedVendorsForPart(
            log.partID,
            this.managePO.getPurchaseOrderItemsByPartID(log.partID),
            this.managePO,
         )
         .get(log.associatedID);

      return vendorRelation?.partNumber
         ? vendorRelation.partNumber
         : (this.part()?.partNumber ?? "");
   });

   protected readonly logVendorName = computed(() => {
      const log = this.log();
      const vendor = this.manageVendor.getVendor(log.associatedID);
      return vendor?.vendorName;
   });

   protected readonly logStockUnitChangeDisplay = computed(() => {
      const log = this.log();
      if (log.sourceID !== PartLogSourceID.UpdatedPartUnitOfMeasure) {
         return undefined;
      }
      if (log.newValue === undefined) {
         return undefined;
      }
      const oldValueJSON = log.oldValue ? JSON.parse(log.oldValue) : undefined;
      const newValueJSON = JSON.parse(log.newValue);
      let displayFrom = "";
      let displayFromTooltip = "";
      if (oldValueJSON !== undefined) {
         const oldUnit = this.unitOfMeasureService.getUnit({
            id: oldValueJSON.id,
            type: oldValueJSON.type,
         });
         displayFrom = oldUnit()?.short() ?? "";
         displayFromTooltip = oldUnit()?.singular() ?? "";
      }

      const newUnit = this.unitOfMeasureService.getUnit({
         id: newValueJSON.id,
         type: newValueJSON.type,
      });

      const displayTo = newUnit()?.short();
      const displayToTooltip = newUnit()?.singular();

      return {
         displayFrom,
         displayTo,
         displayFromTooltip,
         displayToTooltip,
      };
   });

   protected readonly formattedDiff = computed<string>(() => {
      return formatNumber(this.logDiff(), this.manageLang.getLocaleID(), "1.3-3");
   });

   protected readonly logDiff = computed<number>(() => {
      return Number(this.log().oldValue) - Number(this.log().newValue);
   });

   protected readonly isUpdatedPartQuantityLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.UpdatedPartQuantity;
   });

   protected readonly isTaskUsedPartLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.TaskUsedPart;
   });

   protected readonly isCreatedTaskToOrderMoreOfThisPartLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.CreatedTaskToOrderMoreOfThisPart;
   });

   protected readonly isRestoredPartsBecauseCompletedTaskReopenedLog = computed<boolean>(
      () => {
         return (
            this.log().sourceID ===
            PartLogSourceID.RestoredPartsBecauseCompletedTaskReopened
         );
      },
   );

   protected readonly isPartsUpdatedBecauseTaskEditedLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.PartsUpdatedBecauseTaskEdited;
   });

   protected readonly isUpdatedPartFromPOLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.UpdatedPartFromPO;
   });

   protected readonly isUpdatedPartMaxQuantityThresholdLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.UpdatedPartMaxQuantityThreshold;
   });

   protected readonly isUpdatedPartReceivedFromPOLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.UpdatedPartReceivedFromPO;
   });

   protected readonly isUpdatedPartVendorAssociationLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.UpdatedPartVendorAssociation;
   });

   protected readonly isUpdatedPartVenderQuantityLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.UpdatedPartVenderQuantity;
   });

   protected readonly isUpdatedPartVendorPriceLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.UpdatedPartVendorPrice;
   });

   protected readonly isCopiedPartLog = computed<boolean>(() => {
      return this.log().sourceID === PartLogSourceID.CopiedPart;
   });

   protected readonly logTooltip = computed<string | undefined>(() => {
      const log = this.log();
      switch (log.sourceID) {
         case PartLogSourceID.UpdatedPartName:
         case PartLogSourceID.UpdatedPartNumber:
         case PartLogSourceID.UpdatedPartPrice:
         case PartLogSourceID.UpdatedPartStaleThreshold:
         case PartLogSourceID.UpdatedPartOverstockedThreshold:
         case PartLogSourceID.PartQuantityThresholdStatusChanged:
         case PartLogSourceID.UpdatedPartLocation:
         case PartLogSourceID.UpdatedPartSupplier:
         case PartLogSourceID.UpdatedPartImage:
         case PartLogSourceID.UpdatedPartMaxQuantityThreshold:
         case PartLogSourceID.UpdatedPartReceivedFromPO: {
            return this.lang().ThisLogWasCreatedWhenThePartWasUpdated;
         }
         case PartLogSourceID.UpdatedPartQuantity: {
            return this.lang().ThisLogWasCreatedWhenAUserManuallyAdjustedThePartQty;
         }
         case PartLogSourceID.AddedLogEntry:
         case PartLogSourceID.TaskUsedPart:
         case PartLogSourceID.DeletedPart:
         case PartLogSourceID.PartImportedOrBulkUpdated: {
            return undefined;
         }
         case PartLogSourceID.CreatedTaskToOrderMoreOfThisPart: {
            return this.lang().ClickThisToSeeWhichTaskCausedThisPartToPassItsQtyThreshold;
         }
         case PartLogSourceID.RestoredPartsBecauseCompletedTaskReopened: {
            return this.lang()
               .ThisLogWasCreatedWhenCompletedTaskWasReopenedCausingAnyPArtsUsedToNoLongerBeUsed;
         }
         case PartLogSourceID.PartsUpdatedBecauseTaskEdited: {
            return this.lang().ThisLogWasCreatedBecauseACompletedTaskPartsWereEdited;
         }
         case PartLogSourceID.UpdatedPartFromPO: {
            return this.lang().ThisLogWasCreatedPartsWereReceivedOnAPurchaseOrder;
         }
         case PartLogSourceID.UpdatedPartVendorAssociation:
         case PartLogSourceID.UpdatedPartVenderQuantity:
         case PartLogSourceID.UpdatedPartVendorPrice: {
            return this.lang().AssociatedVendorLogTooltip;
         }
         case PartLogSourceID.CopiedPart: {
            return this.lang().PartCopiedLogTooltip;
         }
         case PartLogSourceID.CreatedOnAssetTransfer: {
            return "";
         }
         case PartLogSourceID.UpdatedPartUnitOfMeasure: {
            return "";
         }
         default:
            throw new Error("Unexpected sourceID type for log");
      }
   });

   protected readonly iconColor = computed<Colors | undefined>(() => {
      const log = this.log();
      if (
         log.sourceID === PartLogSourceID.UpdatedPartQuantity ||
         log.sourceID === PartLogSourceID.TaskUsedPart ||
         log.sourceID === PartLogSourceID.RestoredPartsBecauseCompletedTaskReopened ||
         log.sourceID === PartLogSourceID.PartsUpdatedBecauseTaskEdited ||
         log.sourceID === PartLogSourceID.UpdatedPartFromPO ||
         log.sourceID === PartLogSourceID.UpdatedPartReceivedFromPO
      ) {
         const diff = Number(log.oldValue) - Number(log.newValue);
         return diff > 0 ? this.colorRed : this.colorGreen;
      }
      return undefined;
   });

   protected readonly logIcon = computed<Aliases | undefined>(() => {
      const log = this.log();
      switch (log.sourceID) {
         case PartLogSourceID.UpdatedPartName:
         case PartLogSourceID.UpdatedPartNumber:
         case PartLogSourceID.AddedLogEntry:
         case PartLogSourceID.UpdatedPartPrice:
         case PartLogSourceID.UpdatedPartStaleThreshold:
         case PartLogSourceID.UpdatedPartOverstockedThreshold:
         case PartLogSourceID.UpdatedPartLocation:
         case PartLogSourceID.UpdatedPartSupplier:
         case PartLogSourceID.UpdatedPartImage:
         case PartLogSourceID.UpdatedPartMaxQuantityThreshold:
         case PartLogSourceID.UpdatedPartVenderQuantity:
         case PartLogSourceID.UpdatedPartVendorPrice:
         case PartLogSourceID.CopiedPart:
         case PartLogSourceID.CreatedOnAssetTransfer:
         case PartLogSourceID.UpdatedPartUnitOfMeasure: {
            return "handBackPointUp";
         }
         case PartLogSourceID.UpdatedPartQuantity:
         case PartLogSourceID.RestoredPartsBecauseCompletedTaskReopened:
         case PartLogSourceID.PartsUpdatedBecauseTaskEdited:
         case PartLogSourceID.UpdatedPartFromPO:
         case PartLogSourceID.TaskUsedPart: {
            const diff = Number(log.oldValue) - Number(log.newValue);
            return diff > 0 ? "arrowDown" : "arrowUp";
         }
         case PartLogSourceID.DeletedPart: {
            return "trashCanRegular";
         }
         case PartLogSourceID.CreatedTaskToOrderMoreOfThisPart: {
            return "squareArrowUpRight";
         }
         case PartLogSourceID.PartQuantityThresholdStatusChanged: {
            return "triangleExclamation";
         }
         case PartLogSourceID.PartImportedOrBulkUpdated: {
            return undefined;
         }
         case PartLogSourceID.UpdatedPartReceivedFromPO: {
            return "arrowDown";
         }
         case PartLogSourceID.UpdatedPartVendorAssociation: {
            return "addressCard";
         }
         default:
            throw new Error("Unexpected sourceID type for log");
      }
   });

   protected readonly isQtyReasonTooLong = computed<boolean>(() => {
      const log = this.log();
      if (log.manualChangePartQtyReason && log.manualChangePartQtyReason.length > 500) {
         return true;
      }
      return false;
   });

   //TODO: Update this to use the correct task type when JITifying tasks for this component.
   public popTask(task) {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: true,
            },
         },
      };
   }

   protected popAlert() {
      const part = this.part();
      assert(part);
      const instance = this.modalService.open(AlertPopup); //create a modal for a text entry
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.log().manualChangePartQtyReason,
            title: this.lang().ExplainWhyQuantityIsBeingLowered,
            data: { partID: part.partID, reason: this.log().manualChangePartQtyReason },
         },
      };
   }

   public popPR() {
      const prID = this.logPrID();
      if (prID && prID > 0) {
         const instance = this.modalService.open(PrComponent);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: { prID: prID },
            },
         };
      } else {
         this.alertService.addAlert(this.lang().noPRErrorMessage, "danger", 10000);
      }
   }

   protected logHasReason() {
      if (
         this.log().manualChangePartQtyReason !== undefined &&
         this.log().manualChangePartQtyReason !== ""
      ) {
         return true;
      }
      return false;
   }

   public popVendor() {
      const log = this.log();
      const vendor = this.manageVendor.getVendor(log.associatedID);
      if (!vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public popPart() {
      const part = this.part();
      if (!part) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: part.partID,
            locationID: part.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }
}
