<lim-ui-dropdown
   [disabled]="taskIsCompleted"
   class="priority-container"
   (click)="dropdownClickEvent.emit($event)"
>
   <lim-ui-table-button
      button
      class="priority-button"
      id="priority-button"
      [disabled]="taskIsCompleted"
      limUiTooltip="{{ lang().changePriorityLevel }}"
      [fixedWidthTableButton]="fixedWidthTableButton"
      placement="right"
   >
      <span class="priority-button-sub-container">
         <lim-ui-icon
            [ngStyle]="{
               color: priorityColor ?? 'var(--lim-el-no-priority-color)',
            }"
            icon="circleExclamation"
            iconSize="small"
         />
         <span
            [ngStyle]="{
               color: priorityColor ?? 'var(--lim-el-no-priority-color)',
            }"
         >
            {{ priorityLevel }}
            @if (useFullName() && priorityName) {
               - {{ priorityName }}
            }
         </span>
      </span>
   </lim-ui-table-button>
   <ng-container menu aria-labelledby="priority-button">
      @for (priority of priorityList; track priority) {
         <lim-ui-dropdown-item (click)="updateTaskPriorityID(priority)">
            {{ priority.priorityLevel }} -
            <span [limbleHtml]="priority.name"></span>
         </lim-ui-dropdown-item>
      }
      @if (superUser) {
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-item (click)="editPriorities()">
            <div class="priority-button-sub-container">
               <lim-ui-icon icon="penToSquare" />
               {{ lang().EditPriorities }}
            </div>
         </lim-ui-dropdown-item>
      }
   </ng-container>
</lim-ui-dropdown>
