import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type { AssetPartAssociation } from "src/app/assets/types/asset-part-association.types";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class AssetPartAssociationService {
   private readonly http = inject(HttpClient);

   public getAssets(partIDs: number[] = []): Observable<AssetPartAssociation[]> {
      const params =
         partIDs.length > 0
            ? new HttpParams().set("partIDs", partIDs.join(","))
            : new HttpParams();

      return this.http.get<AssetPartAssociation[]>(
         `${environment.flannelUrl}/parts/assetsRelations`,
         {
            params,
         },
      );
   }

   public getParts(assetIDs: number[] = []): Observable<AssetPartAssociation[]> {
      const params =
         assetIDs.length > 0
            ? new HttpParams().set("assetIDs", assetIDs.join(","))
            : new HttpParams();

      return this.http.get<AssetPartAssociation[]>(
         `${environment.flannelUrl}/parts/assetsRelations`,
         {
            params,
         },
      );
   }
}
