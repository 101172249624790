import { formatNumber } from "@angular/common";
import type { OnChanges, OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   ChangeDetectorRef,
   Component,
   Input,
   computed,
   signal,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import type { Aliases, Colors } from "@limblecmms/lim-ui";
import {
   AlertComponent,
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDateRangePickerComponent,
   DropdownDividerComponent,
   DropdownResultsPerPageComponent,
   DropdownTextItemComponent,
   FilterInputComponent,
   FilterInputWrapperComponent,
   FiltersWrapperComponent,
   IconButtonComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
   LoadingAnimationComponent,
   PaginationComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import clone from "rfdc";
import type { Subscription } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { PrComponent } from "src/app/purchasing/bills/prWrapper/pr.wrapper.component";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { AlertPopup } from "src/app/shared/components/global/alertModal/alertPopup.modal.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

const deepClone = clone();

@Component({
   selector: "part-log-tab-legacy",
   templateUrl: "./part-log-tab-legacy.component.html",
   styleUrls: ["./part-log-tab-legacy.component.scss"],
   imports: [
      PanelComponent,
      SearchBoxComponent,
      IconButtonComponent,
      TooltipDirective,
      FiltersWrapperComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownClearFilterItemComponent,
      DropdownDateRangePickerComponent,
      FilterInputWrapperComponent,
      FilterInputComponent,
      SortColumn,
      LoadingAnimationComponent,
      NoSearchResults,
      IconComponent,
      LimbleHtmlDirective,
      FormsModule,
      PrimaryButtonComponent,
      AlertComponent,
      PaginationComponent,
      DropdownResultsPerPageComponent,
      BetterDatePipe,
      SliceArray,
   ],
})
export class PartLogTabLegacyComponent implements OnInit, OnChanges, OnDestroy {
   @Input() public restrict;
   @Input() public part: Part | undefined;
   @Input() public logs;
   // This button is shown in the tab section of the parts entity modal normally, but may need to be shown in this component itself in some cases
   @Input() public showDownloadButton: boolean = false;
   public currencySymbol;
   public sortBind;
   public loadingBar;
   public sourceState;
   public dateState;
   public customDate;
   public noSearchResults;
   public searchBar;
   public sourceID;
   public dateDuration;
   public date1;
   public date2;
   public errorMsg;
   public textareaEntry;
   public successMsg;
   public allLogs;
   public varLogs;
   public filteredLogs;
   public page = 1;
   public itemsPerPage = 10;
   protected arrowDown: Aliases = "arrowDown";
   protected arrowUp: Aliases = "arrowUp";
   protected colorRed: Colors = "danger";
   protected colorGreen: Colors = "success";
   protected isUnitOfMeasureStockUnitChangesEnabled: boolean = false;
   private readonly launchFlagSub: Subscription;

   private readonly manageLang = inject(ManageLang);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly managePO = inject(ManagePO);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageFilters = inject(ManageFilters);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected launchFlagsService = inject(LaunchFlagsService);

   protected isUnitOfMeasureVisible = this.unitOfMeasureService.isFeatureEnabled;
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly currentLocationID = signal<number | undefined>(undefined);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationsQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationsQueries.detail(this.currentLocationID()),
   );

   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );
   private readonly localeCurrencyPipe = new LocaleCurrencyPipe();

   public constructor() {
      this.launchFlagSub = toObservable(
         this.launchFlagsService.getFlag(
            "release-part-stock-unit-changes-part-logs",
            false,
         ),
      ).subscribe((flag) => {
         this.isUnitOfMeasureStockUnitChangesEnabled = flag;
      });
   }

   public ngOnChanges(changes) {
      if (changes?.logs) {
         setTimeout(() => {
            this.buildData();
         }, 100);
      }
   }

   public ngOnInit() {
      assert(this.part);
      const part = this.part;

      this.sortBind = "-logDate";
      this.loadingBar = true;

      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.currentLocationID.set(this.part.locationID);

      this.allLogs = [];
      this.manageParts.getLogs(part).then((answer) => {
         if (answer.data.success === true) {
            this.allLogs = answer.data.logs || [];
            this.buildData();
            this.loadingBar = false;
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });

      this.sourceState = false;
      this.dateState = false;

      this.customDate = false;
   }

   public pageChanged() {
      this.ref.detectChanges();
   }

   public buildFilteredLogs() {
      for (const log of this.logs) {
         log.sourceID = Number(log.sourceID);
      }

      let temp = this.logs;
      if (this.sourceState) {
         temp = this.manageFilters.filterLogsBySourceID(this.logs, {
            sourceID: Number(this.sourceID),
            type: "parts",
         });
      }
      if (this.dateState) {
         temp = this.manageFilters.filterLogsToLastXMilliseconds(temp, this.dateDuration);
      }

      if (
         !this.isUnitOfMeasureStockUnitChangesEnabled ||
         !this.isUnitOfMeasureVisible()
      ) {
         temp = temp.filter((log) => log.sourceID !== 26);
      }

      temp = orderBy(temp, this.sortBind);

      this.filteredLogs = [...temp];

      this.page = this.manageFilters.checkPagination(
         this.page,
         this.itemsPerPage,
         this.filteredLogs.length,
      );
   }

   public runFilter() {
      this.buildData();
   }

   public buildData() {
      assert(this.part);
      this.logs = [];
      this.varLogs = this.allLogs;
      if (this.varLogs.length === 0) return;

      const tasks: TaskLookup = this.manageTask.getTasks();
      const cTasks: TaskLookup = this.manageTask.getCompletedTasks();
      for (const log of this.varLogs) {
         log.hasReason = false;
         if (
            log.sourceID === 5 ||
            log.sourceID === 10 ||
            log.sourceID === 16 ||
            log.sourceID === 17
         ) {
            if (tasks.get(log.associatedID)) {
               log.task = tasks.get(log.associatedID);
            } else if (cTasks.get(log.associatedID)) {
               log.task = cTasks.get(log.associatedID);
            } else {
               log.task = false;
            }
            log.searchTask = `${log.task.checklistName} - #${log.task.checklistID}`;
         } else if (log.sourceID === 18) {
            const getTransactionResponse = this.managePO.getBillTransaction(
               log.associatedID,
            );
            if (getTransactionResponse?.prID) {
               const prID = getTransactionResponse.prID;
               log.prNumber = this.managePO.getBillNumberForDisplay(prID);
               log.prID = prID;
            }
         } else if (log.sourceID === 20) {
            const getBillResponse = this.managePO.getBill(log.associatedID);
            if (getBillResponse) {
               const prID = log.associatedID;
               log.prNumber = getBillResponse.prNumber;
               log.prID = prID;
            }
         } else if (log.sourceID === 21) {
            const vendor = this.manageVendor.getVendor(log.associatedID);
            log.vendorName = vendor?.vendorName;
         } else if (log.sourceID === 22) {
            const vendor = this.manageVendor.getVendor(log.associatedID);
            log.vendorName = vendor?.vendorName;

            const vendorRelation = this.manageAssociations
               .getAssociatedVendorsForPart(
                  log.partID,
                  this.managePO.getPurchaseOrderItemsByPartID(log.partID),
                  this.managePO,
               )
               .get(log.associatedID);

            log.partNumber = vendorRelation?.partNumber;
         } else if (log.sourceID === 23) {
            const vendor = this.manageVendor.getVendor(log.associatedID);
            log.vendorName = vendor?.vendorName;

            const vendorRelation = this.manageAssociations
               .getAssociatedVendorsForPart(
                  log.partID,
                  this.managePO.getPurchaseOrderItemsByPartID(log.partID),
                  this.managePO,
               )
               .get(log.associatedID);

            log.partPrice = vendorRelation?.partPrice;
         } else if (log.sourceID === 24) {
            const part = this.manageParts.getPart(log.associatedID);
            log.PartName = part?.partName;
         } else if (log.sourceID === 25) {
            const asset = this.manageAsset.getAsset(log.associatedID);
            log.AssetName = asset?.assetName;
         } else if (
            log.sourceID === 26 &&
            this.isUnitOfMeasureVisible() &&
            this.isUnitOfMeasureStockUnitChangesEnabled
         ) {
            this.setStockUnitChangeDisplay(log);
         }

         if (log.partNumber) {
            log.partNumberStr = log.partNumber;
         } else {
            log.partNumberStr = "";
         }

         if (typeof log.oldValue === "string" && log.sourceID === 3) {
            log.oldValue = Number(log.oldValue);
         }
         log.QtyReasonTooLong = false;
         if (
            log.manualChangePartQtyReason !== null &&
            log.manualChangePartQtyReason.length > 500
         ) {
            log.QtyReasonTooLong = true;
         }
         log.display = "";
         if (log.sourceID === 1) {
            log.display += `${this.lang().PartNameManuallyUpdatedTo} <b>${log.newValue}</b>`;
         } else if (log.sourceID === 2) {
            log.display += `${this.lang().PartNumberManuallyUpdatedTo} <b>`;
            if (log.newValue === "") {
               log.display += `blank`;
            } else {
               log.display += `${log.newValue}`;
            }
            log.display += `</b>`;
         } else if (log.sourceID === 3) {
            const diff = log.oldValue - log.newValue;
            log.diff = diff;
            if (diff < 0) {
               log.display += `<b>${diff * -1}</b> `;

               if (diff === -1) {
                  log.display += this.lang().partWas;
               } else {
                  log.display += this.lang().partsWere;
               }
               log.display += ` ${this.lang().added} `;
            } else {
               log.display += `<b>${diff}</b> `;
               if (diff === 1) {
                  log.display += this.lang().partWas;
               } else {
                  log.display += this.lang().partsWere;
               }
               log.display += ` ${this.lang().removed} `;
            }

            log.display += ` - ${this.lang().ManuallyAdjusted}`;
            if (log.QtyReasonTooLong === false && this.logHasReason(log)) {
               log.display += ` - ${log.manualChangePartQtyReason}`;
            }
         } else if (log.sourceID === 4) {
            log.display += log.logDetails;
         } else if (log.sourceID === 5) {
            const diff = log.oldValue - log.newValue;
            log.diff = diff;
            log.display += `<b>${formatNumber(
               diff,
               this.manageLang.getLocaleID(),
               "1.3-3",
            )}</b> `;
            if (diff === 1) {
               log.display += `${this.lang().partWas} `;
            } else {
               log.display += `${this.lang().partsWere} `;
            }
            log.display += this.lang().usedOnATask;
         } else if (log.sourceID === 6) {
            if (this.isMultiCurrencyEnabled()) {
               log.display +=
                  `${this.lang().PartPriceManuallyChangedTo} ` +
                  `<b>${this.localeCurrencyPipe.transform(log.newValue, this.currencyCode())}</b>`;
            } else {
               log.display +=
                  `${this.lang().PartPriceManuallyChangedTo} ` +
                  `<b>${this.currencySymbol}${formatNumber(
                     log.newValue,
                     this.manageLang.getLocaleID(),
                     "1.2-2",
                  )}</b>`;
            }
         } else if (log.sourceID === 7) {
            log.display +=
               `${this.lang().StaleThresholdManuallyChangedTo} ` +
               `<b>${log.newValue}</b>` +
               ` ${this.lang().days}`;
         } else if (log.sourceID === 8) {
            log.display +=
               `${this.lang().PartQtyThresholdManuallyChangedTo} ` +
               `<b>${log.newValue}</b>`;
         } else if (log.sourceID === 9) {
            log.display += this.lang().PartWasMarkedAsDeleted;
         } else if (log.sourceID === 10) {
            log.display += log.logDetails;
         } else if (log.sourceID === 11) {
            log.display += `${this.lang().PartQtyThresholdStatusChangedToA} `;
            if (log.newValue === 1) {
               log.display += `<b>${this.lang().red}</b> `;
            } else if (log.newValue === 0) {
               log.display += `<b>${this.lang().green}</b> `;
            }
            log.display += this.lang().status;
         } else if (log.sourceID === 12) {
            log.display += log.logDetails;
         } else if (log.sourceID === 13) {
            log.display += `${this.lang().PartLocationManuallyChangedTo} <b>${log.newValue}</b>`;
         } else if (log.sourceID === 14) {
            log.display += `${this.lang().PartSupplierManuallyChangedTo} <b>${log.newValue}</b>`;
         } else if (log.sourceID === 15) {
            log.display += `${this.lang().PartImageWas} <b>${log.newValue}</b>`;
         } else if (log.sourceID === 16) {
            const diff = log.oldValue - log.newValue;
            log.diff = diff;
            log.display += `<b>${diff * -1}</b> `;
            if (diff === -1 || diff === 1) {
               log.display += `${this.lang().partWas} `;
            } else {
               log.display += `${this.lang().partsWere} `;
            }
            log.display += `${this.lang().restoredBecauseThe} ${this.lang().completedTaskWOPM} ${this.lang().wasReopened} `;
         } else if (log.sourceID === 17) {
            const diff = log.oldValue - log.newValue;
            log.diff = diff;
            if (diff > 0) {
               log.display += `<b>${diff}</b> `;
               if (diff === 1) {
                  log.display += `${this.lang().partWas} `;
               } else {
                  log.display += `${this.lang().partsWere} `;
               }
               log.display += `${this.lang().usedBecauseThe} ${this.lang().completedTaskWOPM} ${this.lang().wasEdited} `;
            } else {
               log.display += `<b>${diff * -1}</b> `;
               if (diff === -1) {
                  log.display += `${this.lang().partWas} `;
               } else {
                  log.display += `${this.lang().partsWere} `;
               }
               log.display += `${this.lang().restoredBecauseThe} ${this.lang().completedTaskWOPM} ${this.lang().wasEdited} `;
            }
         } else if (log.sourceID === 18) {
            const diff = log.oldValue - log.newValue;
            log.diff = diff;
            if (diff < 0) {
               log.display += `<b>${diff * -1}</b> `;

               if (diff === -1) {
                  log.display += `${this.lang().partWas} `;
               } else if (diff < -1) {
                  log.display += `${this.lang().partsWere} `;
               }
               log.display += `${this.lang().received} `;
            } else {
               log.display += `<b>${diff}</b> `;

               if (diff === 1) {
                  log.display += `${this.lang().partWas} `;
               } else if (diff > 1) {
                  log.display += `${this.lang().partsWere} `;
               }
               log.display += `${this.lang().removed} `;
            }
         } else if (log.sourceID === 19) {
            log.display +=
               `${this.lang().PartMaxQtyThresholdManuallyChangedTo} ` +
               `<b>${log.newValue}</b>` +
               ` `;
         } else if (log.sourceID === 20) {
            const diff = (log.oldValue - log.newValue) * -1;
            log.diff = diff;
            log.display += `<b>${diff}</b> `;

            if (diff === 1) {
               log.display += `${this.lang().partWas} `;
            } else if (diff > 1) {
               log.display += `${this.lang().partsWere} `;
            }
            log.display += this.lang().markedAsUsedOn;
         } else if (log.sourceID === 22) {
            log.display += `${this.lang().VendorPartNumberManuallyUpdatedTo} <b>`;
            if (log.newValue === "") {
               log.display += `blank`;
            } else {
               log.display += `${log.partNumberStr}`;
            }
            log.display += `</b>`;
         } else if (log.sourceID === 23) {
            if (this.isMultiCurrencyEnabled()) {
               log.display +=
                  `${this.lang().VendorPartPriceManuallyUpdatedTo} ` +
                  `<b>${this.localeCurrencyPipe.transform(log.partPrice, this.currencyCode())}</b>`;
            } else {
               log.display +=
                  `${this.lang().VendorPartPriceManuallyUpdatedTo} ` +
                  `<b>${this.currencySymbol}${formatNumber(
                     log.partPrice,
                     this.manageLang.getLocaleID(),
                     "1.2-2",
                  )}</b>`;
            }
         } else if (log.sourceID === 24) {
            log.display += this.lang().ThisPartWasCopiedFrom;
         } else if (log.sourceID === 25) {
            log.display += this.lang().PartCreatedDuringTransferAsset;
         } else if (
            log.sourceID === 26 &&
            this.isUnitOfMeasureVisible() &&
            this.isUnitOfMeasureStockUnitChangesEnabled
         ) {
            this.setStockUnitChangeDisplay(log);
         }

         //if we are viewing all parts and not a specific one...
         if (this.part.partID === 0) {
            log.display += ` - <i>${log.partName} - ${log.partNumberStr}<i>`;
         }

         //remove tags so it is searchable
         log.searchVal = this.manageUtil.stripTags(log.display).toLowerCase();

         this.manageTask.setInternalTaskUserNameLogDefaults(log);

         log.searchName = `${log.userFirstName} ${log.userLastName}`;
      }

      this.noSearchResults = false;

      if (this.searchBar && this.searchBar.length > 0) {
         const search = this.searchBar.toLowerCase();
         const filteredLogs = this.varLogs.filter((log) => {
            return (
               log.searchVal?.includes(search) ||
               log.searchName?.includes(search) ||
               String(log.prNumber ?? "").includes(search) ||
               log.searchTask?.toLowerCase().includes(search)
            );
         });

         this.varLogs = filteredLogs;

         if (this.varLogs.length === 0) {
            this.noSearchResults = true;
         }
      }

      this.logs = this.varLogs;
      this.buildFilteredLogs();
   }

   public logHasReason(log) {
      if (
         log.manualChangePartQtyReason !== undefined &&
         log.manualChangePartQtyReason !== ""
      ) {
         return true;
      }
      return false;
   }

   public filterSource(sourceID) {
      this.sourceState = true;
      this.sourceID = sourceID;
      this.buildFilteredLogs();
   }

   public clearSource() {
      this.sourceState = false;
      this.buildFilteredLogs();
   }

   public filterDate(duration) {
      this.dateState = true;
      this.dateDuration = duration;
      this.buildFilteredLogs();
   }

   public clearDateFilter() {
      this.dateState = false;
      this.customDate = false;
      this.logs = this.varLogs;
      this.date1 = null;
      this.date2 = null;
      this.buildFilteredLogs();
   }

   public setDateRange() {
      this.customDate = true;
      this.date1 ??= new Date();
      this.date2 ??= new Date();
      this.logs = this.manageFilters.partLogFilterCustomDate(
         this.varLogs,
         this.date1,
         this.date2,
         this.customDate,
      );
      this.buildFilteredLogs();
   }

   public addEntry() {
      assert(this.part);
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ManagePartsLog,
         )
      ) {
         this.alertService.addAlert(this.lang().cred77Fail, "danger", 10000);
         return;
      }

      if (this.part.partID === 0) {
         return;
      }

      this.errorMsg = false;
      let valid = true;

      if (this.textareaEntry === undefined || this.textareaEntry === "") {
         this.errorMsg = this.lang().PleaseEnterAMessage;
         valid = false;
      }

      if (valid) {
         this.manageParts.addEntry(this.textareaEntry, this.part).then((answer) => {
            if (answer.data.success === true) {
               this.allLogs.push(answer.data.log);
               this.buildData();
               this.textareaEntry = "";
               this.successMsg = this.lang().YouHaveSuccessfullyAddedAnEntry;
               setTimeout(() => {
                  this.successMsg = "";
               }, 5000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      }
   }

   public downloadPartHistory() {
      let logsToDownload = deepClone(this.filteredLogs);
      if (this.sourceState) {
         logsToDownload = this.manageFilters.filterLogsBySourceID(logsToDownload, {
            sourceID: Number(this.sourceID),
            type: "parts",
         });
      }
      if (this.dateState) {
         logsToDownload = this.manageFilters.filterLogsToLastXMilliseconds(
            logsToDownload,
            this.dateDuration,
         );
      }

      const parts = this.manageParts.getParts();
      const tasks: TaskLookup = this.manageTask.getCompletedTasks();
      const locations = this.manageLocation.getLocationsIndex();
      for (const key in logsToDownload) {
         const log = logsToDownload[key];
         const part = parts.get(log.partID);

         if (!part) {
            continue;
         }

         //rename and reorder the fields for downloadtest
         log.Date = log.logDate;
         delete log.logDate;

         log.Entry = this.manageUtil.removeDuplicateSpaces(log.searchVal);
         delete log.logDetails;
         delete log.display;

         log["Part Name"] = part.partName;
         log["Part Number"] = part.partNumber;

         log["First Name"] = log.userFirstName;
         delete log.userFirstName;

         log["Last Name"] = log.userLastName;
         delete log.userLastName;

         log["Old Value"] = log.oldValue;
         log["New Value"] = log.newValue;

         log.Difference = "";

         if (
            log.sourceID === 3 ||
            log.sourceID === 5 ||
            log.sourceID === 18 ||
            log.sourceID === 20
         ) {
            log.Difference = Number(log.newValue) - Number(log.oldValue);
         }

         //clean up and final assignments
         delete log.oldValue;
         delete log.newValue;

         log["Part ID"] = log.partID;
         log["User ID"] = log.userID;

         //adding task ID for export
         if (log.sourceID === 5) {
            log["Task ID"] = log.associatedID;
         } else {
            log["Task ID"] = "";
         }

         const taskLocationID = tasks.get(log["Task ID"])?.locationID;
         log["Used At Location"] = "";
         log["Part Location"] = "";
         if (
            log["Task ID"] > 0 &&
            log["Task ID"] !== "" &&
            taskLocationID &&
            locations[taskLocationID]
         ) {
            log["Used At Location"] = locations[taskLocationID].locationName;
            if (
               log["Part ID"] > 0 &&
               log["Part ID"] !== "" &&
               parts.get(log["Part ID"])
            ) {
               log["Part Location"] = this.manageUtil.stripTags(
                  parts.get(log["Part ID"])?.partLocation ?? "",
               );
            }
         }

         delete log.partID;
         delete log.userID;
         delete log.logID;
         delete log.sourceID;
         delete log.partNumberStr;
         delete log.associatedID;
         delete log.searchVal;
         delete log.searchName;
         delete log.prNumber;
         delete log.task;
         delete log.searchTask;
         delete log.diff;
         delete log.vendorName;
         delete log.partName;
         delete log.partNumber;
      }

      this.manageUtil.objToExcel(logsToDownload, "Parts History", "Parts History.xlsx");
   }

   public async popTask(log) {
      const task = await this.manageTask.getTask(log.associatedID);
      if (!task) {
         return;
      }
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: true,
            },
         },
      };
   }

   public popPR(log) {
      const prID = log.prID;
      if (prID > 0) {
         const instance = this.modalService.open(PrComponent);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: { prID: prID },
            },
         };
      } else {
         this.alertService.addAlert(this.lang().noPRErrorMessage, "danger", 10000);
      }
   }

   public popAlert(log) {
      assert(this.part);
      const instance = this.modalService.open(AlertPopup); //create a modal for a text entry
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: log.manualChangePartQtyReason,
            title: this.lang().ExplainWhyQuantityIsBeingLowered,
            data: { partID: this.part.partID, reason: log.manualChangePartQtyReason },
         },
      };
   }

   public popVendor(log) {
      const vendor = this.manageVendor.getVendor(log.associatedID);
      if (!vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public popPart(log) {
      const part = this.manageParts.getPart(log.associatedID);
      if (!part) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: part.partID,
            locationID: part.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public sortLogsBySortBind(newSortValue) {
      this.sortBind = newSortValue;
      this.filteredLogs = orderBy(this.filteredLogs, this.sortBind);
   }

   public updateUIPref(number) {
      this.itemsPerPage = number;
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage =
         this.itemsPerPage;
      this.manageUser.updateUserUIPreferences();
      this.page = 1;
   }

   public ngOnDestroy() {
      this.launchFlagSub.unsubscribe();
   }

   private setStockUnitChangeDisplay(log): void {
      const oldValueJSON = JSON.parse(log.oldValue);
      const newValueJSON = JSON.parse(log.newValue);

      const newUnit = this.unitOfMeasureService.getUnit({
         id: newValueJSON.id,
         type: newValueJSON.type,
      });

      if (oldValueJSON) {
         const oldUnit = this.unitOfMeasureService.getUnit({
            id: oldValueJSON.id,
            type: oldValueJSON.type,
         });

         log.displayFrom = oldUnit()?.short();
         log.displayFromTooltip = oldUnit()?.singular();
      }

      if (!oldValueJSON) {
         log.displayFrom = null;
         log.displayFromTooltip = null;
      }

      log.displayTo = newUnit()?.short();
      log.displayToTooltip = newUnit()?.singular();

      log.display = `${this.lang().StockUnitWasChangedFrom} ${log.displayFrom} ${this.lang().To} ${log.displayTo}`;
   }
}
