import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BadgeComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   PopoverDirective,
   SecondaryButtonComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { PickInstructionSetComponent } from "src/app/tasks/components/pickInstructionSetModal/pickInstructionSet.modal.component";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskInstructionType } from "src/app/tasks/types/instruction/instruction.types";

@Component({
   selector: "pick-item",
   styleUrls: ["./pickItem.modal.component.scss"],
   templateUrl: "./pickItem.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      SecondaryButtonComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      PanelComponent,
      NgClass,
      IconComponent,
      TooltipDirective,
      BasicModalFooterComponent,
      IconAlias,
      BadgeComponent,
      PopoverDirective,
      UpsellPopover,
   ],
})
export class PickItem implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public button;
   public items: Lookup<"itemTypeID", TaskInstructionType> = new Lookup("itemTypeID");
   public simpleItems: Lookup<"itemTypeID", TaskInstructionType> = new Lookup(
      "itemTypeID",
   );
   public complexItems: Lookup<"itemTypeID", TaskInstructionType> = new Lookup(
      "itemTypeID",
   );
   public errorMsg;
   public isInstructionSet;
   public parentBuildData;
   public taskItemsExtraInfo: Map<number, { selected: boolean }> = new Map();
   private readonly manageFeatureFlagsSub: Subscription;
   protected featureCustomApprovalInstruction: boolean = false;
   protected featureStartWOStartPM: boolean = false;
   private featureMaps: boolean = false;

   private readonly manageLang = inject(ManageLang);
   private readonly manageTask = inject(ManageTask);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureCustomApprovalInstruction =
               isFeatureEnabledMap.featureCustomApprovalInstruction;
            this.featureMaps = isFeatureEnabledMap.featureMaps;
            this.featureStartWOStartPM = isFeatureEnabledMap.featureStartWOStartPM;
         },
      );
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.button = this.resolve.button;
      this.isInstructionSet = this.resolve.isInstructionSet;
      this.errorMsg = "";
      this.parentBuildData = this.resolve.parentBuildData;

      this.items = this.manageTask.getItemTypes();
      this.items = this.manageFilters.pickItemFilter(this.items);
      this.items = this.setItemsLookupInformation(this.items);

      for (const item of this.items) {
         this.taskItemsExtraInfo.set(item.itemTypeID, { selected: false });
      }
      this.setupFilteredLookups();
   }

   private setupFilteredLookups() {
      this.simpleItems = this.items.filter((item) => item.complexity === "simple");
      this.simpleItems = this.simpleItems.orderBy("itemSortNo");
      this.complexItems = this.items.filter((item) => item.complexity === "complex");
      this.complexItems = this.complexItems.orderBy("itemSortNo");
   }

   selectItem = (item: TaskInstructionType) => {
      if (
         Number(item.itemTypeID) === TaskInstructionTypeID.RequestApproval &&
         !this.featureCustomApprovalInstruction
      ) {
         return;
      }
      if (
         Number(item.itemTypeID) === TaskInstructionTypeID.VerifyLocation &&
         !this.featureMaps
      ) {
         return;
      }

      if (
         (Number(item.itemTypeID) === TaskInstructionTypeID.StartWO &&
            !this.featureStartWOStartPM) ||
         (Number(item.itemTypeID) === TaskInstructionTypeID.AssignPM &&
            !this.featureStartWOStartPM)
      ) {
         return;
      }

      if (
         Number(item.itemTypeID) === TaskInstructionTypeID.RequestApproval &&
         !this.featureCustomApprovalInstruction
      ) {
         return;
      }

      if (this.taskItemsExtraInfo.get(item.itemTypeID)?.selected === true) {
         //covers duplicate click to submit
         this.submit();
      }
      this.errorMsg = "";
      for (const tempItem of this.items) {
         this.taskItemsExtraInfo.set(tempItem.itemTypeID, { selected: false });
      }
      this.taskItemsExtraInfo.set(item.itemTypeID, { selected: true });
   };

   protected popPickInstructionSet() {
      const instance = this.modalService.open(PickInstructionSetComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PickInstructionSetMsg,
            title: this.lang().InstructionSetTemplates,
            parentBuildData: this.parentBuildData,
         },
      };
      instance.result.then((response) => {
         if (response) {
            this.submit(response);
         }
      });
   }

   close = () => {
      this.modalInstance.close(false);
   };

   submit = (instructionSetItemID?: number) => {
      if (instructionSetItemID) {
         const responseObject = {
            instructionSetItem: instructionSetItemID,
            instructionSet: true,
         };
         this.modalInstance.close(responseObject);
      } else {
         let selectedItem;
         for (const item of this.items) {
            if (this.taskItemsExtraInfo.get(item.itemTypeID)?.selected == true) {
               selectedItem = item;
            }
         }

         if (selectedItem) {
            this.modalInstance.close(selectedItem.itemTypeID);
            return;
         }
         this.alertService.addAlert(
            this.lang().PleaseSelectAnInstructionType,
            "warning",
            6000,
         );
      }
   };

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private setItemsLookupInformation(
      items: Lookup<"itemTypeID", TaskInstructionType>,
   ): Lookup<"itemTypeID", TaskInstructionType> {
      const langDict = this.lang();
      for (const item of items) {
         if (item.itemTypeID == TaskInstructionTypeID.CheckBox) {
            item.itemTypeName = langDict.CheckBox;
            item.itemTypeDescription = langDict.CheckBoxTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.OptionList) {
            item.itemTypeName = langDict.RadioList;
            item.itemTypeDescription = langDict.RadioListTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.TextBox) {
            item.itemTypeName = langDict.TextBox;
            item.itemTypeDescription = langDict.TextBoxTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.DropdownList) {
            item.itemTypeName = langDict.DropdownBox;
            item.itemTypeDescription = langDict.DropdownBoxTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.DatePicker) {
            item.itemTypeName = langDict.DatePicker;
            item.itemTypeDescription = langDict.DatePickerTooltip;
         } else if (item.itemTypeID == 6) {
            //DEPRECATED ITEM
         } else if (item.itemTypeID == TaskInstructionTypeID.Note) {
            item.itemTypeName = langDict.Note;
            item.itemTypeDescription = langDict.NoteTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.Reassign) {
            item.itemTypeName = langDict.ReassignTask;
            item.itemTypeDescription = langDict.ReassignItemTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.FileOrPictureAttachment) {
            item.itemTypeName = langDict.FileOrPictureAttachment;
            item.itemTypeDescription = langDict.FileOrPictureAttachmentTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.AssignPM) {
            item.itemTypeName = langDict.AssignPM;
            item.itemTypeDescription = langDict.AssignPMTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.DeadlineDatePicker) {
            //DEPRECATED ITEM
         } else if (item.itemTypeID == TaskInstructionTypeID.AssignAuditPM) {
            //DEPRECATED ITEM
         } else if (item.itemTypeID == TaskInstructionTypeID.Number) {
            item.itemTypeName = langDict.Number;
            item.itemTypeDescription = langDict.NumberTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.StartWO) {
            item.itemTypeName = langDict.StartWO;
            item.itemTypeDescription = langDict.StartWOTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.CaptureSignature) {
            item.itemTypeName = langDict.CaptureSignature;
            item.itemTypeDescription = langDict.CaptureSignatureTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.RequestApproval) {
            item.itemTypeName = langDict.RequestApproval;
            item.itemTypeDescription = langDict.RequestApprovalTooltip;
         } else if (item.itemTypeID == TaskInstructionTypeID.InstructionSet) {
            item.itemTypeName = langDict.InstructionSet;
            item.itemTypeDescription = langDict.InstructionSetTooltip;
         }
      }
      return items;
   }
}
