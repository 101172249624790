import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ReceivePurchaseOrderItemsTriggerService {
   /**
    * This service triggers the a Receive PO Items component to submit the received items.
    * Requires a Purchase Order ID.
    */
   public readonly trigger = new Subject<number>();
}
