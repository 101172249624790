import { NgClass, NgStyle } from "@angular/common";
import type { OnChanges, OnInit } from "@angular/core";
import { Component, computed, inject, input, Input } from "@angular/core";
import {
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import clone from "rfdc";
import type { Subscription } from "rxjs";
import { fromEvent, throttleTime } from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import {
   AssetListService,
   type AssetListWidgetDef,
} from "src/app/dashboards/widgets/list/assets/asset-list.service";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureAvailabilityService } from "src/app/parts/unit-of-measure/unit-of-measure-availability.service";
import { PrComponent } from "src/app/purchasing/bills/prWrapper/pr.wrapper.component";
import { PoDeliveryDate } from "src/app/purchasing/pos/poDeliveryDateElement/poDeliveryDate.element.component";
import { PoItemListShort } from "src/app/purchasing/pos/poItemListShortElement/poItemListShort.element.component";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { BillTransaction } from "src/app/purchasing/types/bill-transaction.types";
import { PopTaskSchedule } from "src/app/schedules/popTaskScheduleModal/popTaskSchedule.modal.component";
import { ViewListModal } from "src/app/shared/components/global/lists/viewList.modal.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import type { RequestFilter } from "src/app/shared/services/flannel-api-service/api.models";
import { Flags } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { Lookup } from "src/app/shared/utils/lookup";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { TaskViewType_ByType } from "src/app/tasks/components/shared/services/task-column-definitions-factory/task-column-definitions-factory.models";
import { TasksModalsService } from "src/app/tasks/components/shared/services/tasks-modals/tasks-modals.service";
import { ViewTaskList } from "src/app/tasks/components/viewTaskListModal/viewTaskList.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { SharedData } from "src/app/tasks/services/sharedData";
import type { TaskPartRelation } from "src/app/tasks/types/part/task-part.types";
import type { Task } from "src/app/tasks/types/task.types";
import { TaskWidgetModalsService } from "src/app/tasks-analytics/task-widget-modals/task-widget-modals.service";
import { ManageUser } from "src/app/users/services/manageUser";
import type { Vendor } from "src/app/vendors/types/vendor.types";

const deepClone = clone();

type AugmentedTaskPartRelation = TaskPartRelation & {
   partName: string | null;
   partNumber: string | null;
};

export type TaskTypeView =
   | "completedPlannedWOs"
   | "completedUnplannedWOs"
   | "completedWorkRequests"
   | "completedPms"
   | "openPlannedWorkOrders"
   | "openUnplannedWorkOrders"
   | "openPMs"
   | "openWorkRequests"
   | "totalCompletedTasks"
   | "totalOpenTasks"
   | "totalTasks";

@Component({
   standalone: true,
   selector: "dashboard-list-view-obj-columns",
   templateUrl: "./dashboardListViewObjColumns.element.component.html",
   styleUrls: ["./dashboardListViewObjColumns.element.component.scss"],
   imports: [
      NgClass,
      NgStyle,
      IconComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      PoItemListShort,
      PoDeliveryDate,
      BetterDatePipe,
      BetterDecimalPipe,
      LocaleCurrencyPipe,
   ],
})
export class DashboardListViewObjColumns implements OnInit, OnChanges {
   @Input() public columns;
   @Input() public obj;
   @Input() public data;
   @Input() public searchHints?: Map<number, string> | LimbleMap<number, string>;
   widgetDef = input<AssetListWidgetDef>();
   public task: Task | undefined;
   public currencySymbol;
   public tmpObj;
   public partRelationsArray: AugmentedTaskPartRelation[] = [];
   public customerID;
   public locationName: string = "";
   public taskAsset: Asset | undefined;
   public assetID: number | null = null;
   public assets: Lookup<"assetID", Asset>;
   public vendor: Vendor | undefined;
   public taskExtraInfo: { requestorInformation: string; showCommentsHint: boolean } = {
      requestorInformation: "",
      showCommentsHint: false,
   };

   protected isCollapsedView: boolean;
   protected readonly windowResizeSub: Subscription;
   private readonly tableBreakpoint: number = 1169;

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFiles = inject(ManageFiles);
   public readonly manageLocation = inject(ManageLocation);
   private readonly manageObservables = inject(ManageObservables);
   public readonly manageParts = inject(ManageParts);
   public readonly managePO = inject(ManagePO);
   private readonly manageTask = inject(ManageTask);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly sharedData = inject(SharedData);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   private readonly launchFlagService = inject(LaunchFlagsService);
   private readonly featureFlagService = inject(FeatureFlagService);
   private readonly taskWidgetModals = inject(TaskWidgetModalsService);
   private readonly assetListService = inject(AssetListService);
   private readonly tasksModals = inject(TasksModalsService);
   private readonly unitsOfMeasureAvailabilityService = inject(
      UnitOfMeasureAvailabilityService,
   );

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly unitsOfMeasureIsEnabled = this.launchFlagService.getFlag(
      Flags.CUSTOM_DASHBOARD_PART_STOCK_UNIT,
      false,
   );

   protected readonly jitAssetListEnabled = this.launchFlagService.getFlag(
      "jit-asset-tasks",
      false,
   );
   protected readonly isUnitsOfMeasureFeatureAvailable = computed(() =>
      this.featureFlagService.featureSet()?.has("unitOfMeasure"),
   );
   protected readonly areUnitsOfMeasureEnabled =
      this.unitsOfMeasureAvailabilityService.isFeatureEnabled;

   public constructor() {
      this.assets = this.manageAsset.getAssets();

      this.isCollapsedView = window.innerWidth <= this.tableBreakpoint;
      this.windowResizeSub = fromEvent(window, "resize")
         .pipe(throttleTime(500))
         .subscribe(this.setIsMobileView.bind(this));
   }

   public ngOnChanges(changes) {
      if (changes?.columns?.currentValue !== changes?.columns?.previousValue) {
         setTimeout(() => {
            this.buildData();
            this.updateOpenModalData();
         });
      }
   }

   public ngOnInit() {
      // Get the object's location name.
      // NOTE: Since the obj property can be any object, we see if it has a locationName or locationID.
      // If not, it will just be blank and we have a bug :(
      if (this.obj.locationID) {
         const locationsIndex = this.manageLocation.getLocationsIndex();
         this.locationName = locationsIndex[this.obj.locationID]?.locationName ?? "";
      } else if (this.obj.locationName) {
         this.locationName = this.obj.locationName;
      }
      if (this.obj.checklistID) {
         //a task is set so let's load it.
         this.task =
            this.manageTask.getTaskLocalLookup(this.obj.checklistID) ??
            this.manageTask.getCompletedTask(
               this.obj.checklistID,
               "DashboardListViewObjColumns",
            );
         this.assetID = this.task?.assetID ?? null;
         if (this.task && this.obj.useExtraAsset) {
            this.task = deepClone(this.task);
            this.assetID = this.obj.assetID;
         }
         if (this.assetID !== null) {
            this.taskAsset = this.manageAsset.getAsset(this.assetID);
         }
         this.taskExtraInfo.requestorInformation = this.task
            ? this.manageTask.getTaskRequestorInfo(this.task)
            : "";
         this.taskExtraInfo.showCommentsHint = this.task
            ? this.manageTask.getTaskCommentsInfo(this.task).showCommentsHint
            : false;
      }
      this.buildData();
   }

   protected checkIfFieldNameExistsForMobile(column, obj) {
      const isMobile = this.isCollapsedView;
      if (!isMobile) {
         return true;
      }
      const comparatorFields = [
         "customField1",
         "customField2",
         "customField3",
         "customField4",
         "customField5",
         "customField6",
         "customField7",
         "customField8",
         "customPartField1",
         "customPartField2",
         "customPartField3",
         "customPartField4",
         "customPartField5",
         "customPartField6",
         "customPartField7",
         "customPartField8",
      ];
      if (obj && comparatorFields.includes(column.key)) {
         return (
            obj.customField1Data?.fieldName?.length > 0 ||
            obj.customField2Data?.fieldName?.length > 0 ||
            obj.customField3Data?.fieldName?.length > 0 ||
            obj.customField4Data?.fieldName?.length > 0 ||
            obj.customField5Data?.fieldName?.length > 0 ||
            obj.customField6Data?.fieldName?.length > 0 ||
            obj.customField7Data?.fieldName?.length > 0 ||
            obj.customField8Data?.fieldName?.length > 0
         );
      }

      return true;
   }

   public buildData(): void {
      this.tmpObj = this.task ?? this.obj; //if task exists use that, else look for it on the this.obj
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }

      if (this.columns === undefined) {
         return;
      }
      let count = 0;
      for (const key in this.columns) {
         if (this.columns[key] == true || this.columns[key].key) {
            if (key !== "autoRotate" && key !== "manualWidth" && key !== "itemsPerPage") {
               //some fields in columns are for extra options.
               count++;
            }
         }
      }

      const columnWidthObj = this.manageTask.getlistViewColumnWidthObj(count); //this gets the dynamic widths for the columns
      let counter = 0;
      for (const key in this.columns) {
         if (this.columns[key]) {
            this.columns[key].columnWidth = columnWidthObj[counter];
            if (this.columns[key].manualWidth) {
               this.columns[key].columnWidth = this.columns[key].manualWidth;
            }

            const keyCheck = this.columns[key].key ? this.columns[key].key : key;

            if (keyCheck === "partRelationsDetail") {
               this.partRelationsArray = this.getPartRelationsArray();
            }

            counter++;
         }
      }
   }

   public viewFile(asset, item, event): void {
      const fileIndex = event.target.dataset.fileIndex;
      const file = item.files[fileIndex];
      if (item && item.fieldTypeID == 3) {
         this.viewImage(asset, item, file);
      }
   }

   public viewImage(asset, item, file): void {
      const CID = this.manageUser.getCurrentUser().userInfo.customerID;
      const entityType = asset.assetID ? "assets" : "parts";
      const entityID = entityType === "assets" ? asset.assetID : asset.partID;

      const imagePath = `viewFile.php?f=upload-${CID}/${entityType}/${asset.locationID}/${entityID}/${item.valueID}/${file.fileName}`;
      this.manageFiles.createImageViewer(imagePath);
   }

   private setIsMobileView($event): void {
      if (!$event?.target) {
         return;
      }
      const windowAtResize = $event.target as Window;
      this.isCollapsedView = windowAtResize.innerWidth <= this.tableBreakpoint;
   }

   public viewListOfPartsUsage(tasks): void {
      const partsUsageObjs = this.manageParts.prepListOfPartsUsage(tasks);
      const columns = [
         {
            key: "partName",
            displayName: this.lang().PartName,
            sortBy: "partName",
            manualWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Date,
            sortBy: "checklistCompletedDate",
            manualWidth: 2,
         },
         {
            key: "usedNumber",
            displayName: this.lang().TotalUsed,
            sortBy: "usedNumber",
            manualWidth: 4,
         },
         {
            key: "partNumber",
            displayName: this.lang().PartNumber,
            sortBy: "partNumber",
            manualWidth: 2,
         },
         {
            key: "totalCost",
            displayName: this.lang().TotalCost,
            sortBy: "totalCost",
            manualWidth: 2,
         },
      ];

      const options = {
         sortBind: "-checklistCompletedDate",
         isModal: true,
      };
      const instance = this.modalService.open(ViewListModal);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            objs: partsUsageObjs,
            options: options,
            modalTitle: this.lang().ListOfPartsUsed,
            columns: columns,
         },
      };
   }

   public viewListOfTasks(type: TaskTypeView, title: string, tasks: any): void {
      const shouldUseJitAssetList = this.jitAssetListEnabled();
      if (!shouldUseJitAssetList) {
         this.viewLocalListOfTasks(type, title, tasks);
         return;
      }
      const widgetDef = this.widgetDef();
      if (!widgetDef) {
         throw new Error("Widget definition is undefined");
      }
      const filters = this.assetListService.getTasksFilter(widgetDef, type);

      const extraFilters: RequestFilter[] = [
         {
            key: "taskByType",
            assetIDs: [this.obj.assetID],
            value: type,
            type: TaskViewType_ByType,
         },
         filters,
      ];

      this.tasksModals.open(TaskViewType_ByType, extraFilters, undefined, {
         title: title,
      });
   }

   private viewLocalListOfTasks(type: TaskTypeView, title: string, tasks: any) {
      switch (type) {
         case "completedPms":
         case "completedUnplannedWOs":
         case "completedWorkRequests":
         case "completedPlannedWOs":
         case "totalCompletedTasks":
         case "totalTasks":
            this.viewListOfTasksByType(tasks, title);
            break;
         case "openPlannedWorkOrders":
         case "openUnplannedWorkOrders":
         case "openPMs":
         case "openWorkRequests":
         case "totalOpenTasks":
            this.showOpenTasks(tasks, title);
            break;
         default:
            this.viewListOfTasksByType(tasks, title);
      }
   }

   public viewListOfTasksByType(tasks, title): void {
      if (!tasks || (tasks && tasks.length == 0)) return;
      const taskIDs = tasks.map((task) => task.checklistID);
      const instance = this.modalService.open(ViewTaskList);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: title,
            data: {},
            taskIDs,
            locationID: this.obj.locationID,
            viewingAsset: 0,
            modalType: "tasksByType",
         },
      };
   }

   public showOpenTasks(tasks, title): void {
      this.sharedData.openTaskDataType = title;
      this.sharedData.openTaskModalObsID = this.obj.assetID;
      const taskIDs = tasks.map((task) => task.checklistID);
      const instance = this.modalService.open(ViewTaskList);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: title,
            taskIDs,
            data: {},
            obsID: this.sharedData.openTaskModalObsID,
            modalType: "openTasks",
         },
      };
   }

   public updateOpenModalData(): void {
      if (
         this.sharedData.openTaskDataType &&
         this.sharedData.openTaskModalObsID == this.obj?.assetID
      ) {
         let data;
         switch (this.sharedData.openTaskDataType) {
            case this.lang().OpenPlannedWOs:
               data = this.obj.openPlannedWOsList;
               break;
            case this.lang().OpenUnplannedWOs:
               data = this.obj.openUnplannedWOsList;
               break;
            case this.lang().OpenWorkRequests:
               data = this.obj.openWorkRequestsList;
               break;
            case this.lang().OpenPMs:
               data = this.obj.openPmsList;
               break;
            case this.lang().TotalOpenTasks:
               data = this.obj.totalOpenTasksList;
               break;
            default:
               break;
         }
         if (data) {
            this.manageObservables.updateObservable(
               `viewListOfOpenTasksModalObs${this.sharedData.openTaskModalObsID}`,
               data,
            );
         }
      }
   }

   public viewListOfTasksByCosts(tasks, title): void {
      const taskIDs = tasks.map((task) => task.checklistID);
      const instance = this.modalService.open(ViewTaskList);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: title,
            data: {},
            taskIDs,
            locationID: "",
            viewingAsset: "",
            sortBind: "",
            modalType: "tasksByCost",
         },
      };
   }

   public viewListOfTasksByDowntime(tasks): void {
      if (!tasks || (tasks && tasks.length == 0)) return;
      const taskIDs = tasks.map((task) => task.checklistID);
      const instance = this.modalService.open(ViewTaskList);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().ListOfTasksThatCausedDowntime,
            data: {},
            taskIDs,
            locationID: this.obj.locationID,
            viewingAsset: 0,
            sortBind: "-checklistTotalOperatingCost",
            modalType: "tasksByDowntime",
         },
      };
   }

   public popTask(): void {
      if (this.obj.scheduleID > 0) {
         const instance = this.modalService.open(PopTaskSchedule);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: {
                  checklistID: this.obj.checklistID,
                  scheduleID: this.obj.scheduleID,
               },
            },
         };
      } else {
         if (!this.task) {
            this.task = this.obj.task;
         }
         const instance = this.modalService.open(PopTask);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: {
                  checklistID: this.task?.checklistID,
                  editable: true,
               },
            },
         };
      }
   }

   public viewAsset(assetID): void {
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         return;
      }
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset ? asset.assetID : assetID.assetID,
            locationID: asset ? asset.locationID : assetID.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public popPoComponent(poID): void {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   }

   public popPR(transaction: BillTransaction): void {
      const instance = this.modalService.open(PrComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { prID: transaction.prID },
         },
      };
   }

   public popPart(partID: number): void {
      const part = this.manageParts.getPart(partID);
      if (!part) {
         return;
      }
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: part.partID,
            locationID: part.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   private getPartRelationsArray(): AugmentedTaskPartRelation[] {
      const partRelationsArray: AugmentedTaskPartRelation[] = [];

      for (const partRelationID of this.obj.partRelationIDs) {
         const partRelation = this.manageTask.getPartRelation(partRelationID);

         if (partRelation) {
            const actualPart = this.manageParts.getPart(partRelation.partID);

            if (actualPart) {
               const augmentedPartRelation = {
                  ...partRelation,
                  partName: actualPart.partName,
                  partNumber: actualPart.partNumber,
               };

               partRelationsArray.push(augmentedPartRelation);
            }
         }
      }

      return partRelationsArray;
   }
}
