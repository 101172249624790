<div class="header">
   <div class="section">
      <div class="caret">
         <lim-ui-icon [icon]="caretIcon()" />
      </div>
      <div class="title">
         <ai-badge />
         <div class="title-text">AI Powered PM Builder</div>
      </div>
   </div>
   @let num = count.value();
   @if (num !== undefined && num > 0) {
      <div class="section">
         <lim-ui-icon icon="circleCheck" class="circle-check" />
         <div class="count">
            <span> {{ num }} </span>
            <span class="count-text"> PM Suggestions </span>
         </div>
      </div>
   }
</div>
