@if (part && hasLoaded && canViewPart) {
   @if (isPhoneScreenSize$ | async) {
      <lim-ui-scroll-container [thin]="true">
         <ng-container *ngTemplateOutlet="partTile" />
      </lim-ui-scroll-container>
   } @else {
      @if (isJitCompletedTasksPartsEnabled()) {
         <part-modal
            [dataLogOptions]="dataLogOptions"
            [partID]="part.partID"
            [restrict]="restrict"
            [scrollableContent]="(isPhoneScreenSize$ | async) === false"
            (closeModalEvent)="close()"
         />
      } @else {
         <part-modal-legacy
            [dataLogOptions]="dataLogOptions"
            [partID]="part.partID"
            [restrict]="restrict"
            [scrollableContent]="(isPhoneScreenSize$ | async) === false"
            (closeModalEvent)="close()"
         />
      }
   }

   <ng-template #partTile>
      @if (isJitCompletedTasksPartsEnabled()) {
         <part-modal
            [dataLogOptions]="dataLogOptions"
            [partID]="part.partID"
            [restrict]="restrict"
            [scrollableContent]="(isPhoneScreenSize$ | async) === false"
            (closeModalEvent)="close()"
         />
      } @else {
         <part-modal-legacy
            [dataLogOptions]="dataLogOptions"
            [partID]="part.partID"
            [restrict]="restrict"
            [scrollableContent]="(isPhoneScreenSize$ | async) === false"
            (closeModalEvent)="close()"
         />
      }
   </ng-template>
}

@if (!canViewPart) {
   <div>
      <lim-ui-modal>
         <lim-ui-basic-modal-header (closeModal)="close()" />
         <lim-ui-modal-body>
            <lim-ui-alert [title]="lang().Warning" alertType="warning">
               <div [limbleHtml]="lang().restrictedPartViewMsg"></div>
            </lim-ui-alert>
         </lim-ui-modal-body>
      </lim-ui-modal>
   </div>
}
