import { NgStyle } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input } from "@angular/core";
import { type Aliases, isNativeMobileApp } from "@limblecmms/lim-ui";
import { IconComponent } from "@limblecmms/lim-ui";
import type { DocumentFile } from "src/app/files/file.util";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { UrlStringManipulator } from "src/app/files/services/url/url-string-manipulator";
import { MobileFileService } from "src/app/mobile/files/mobile-file.service";

@Component({
   selector: "file-list-item",
   templateUrl: "./fileListItem.element.component.html",
   styleUrls: ["./fileListItem.element.component.scss"],
   imports: [IconComponent, NgStyle],
})
export class FileListItem implements OnInit {
   @Input() public file?: DocumentFile;
   @Input() public url?: string;
   @Input() public fontSize;
   public ext?: string;
   protected fileIcon: Aliases = "file";
   private cacheBustedUrl?: string;
   private readonly urlStringManipulator = new UrlStringManipulator();
   /**
    * This map has file extensions as keys, and icon Aliases as values.
    * It is used to determine which icon to show for the file based on
    * its extension.
    */
   private readonly fileExtensionIconMap: Map<string, Aliases> = new Map<string, Aliases>(
      [
         ["pdf", "filePdf"],
         ["doc", "fileWord"],
         ["docx", "fileWord"],
         ["txt", "fileLines"],
         ["dwg", "fileLines"],
         ["dxf", "fileLines"],
         ["xls", "fileExcel"],
         ["xlsx", "fileExcel"],
         ["rar", "fileZipper"],
         ["zip", "fileZipper"],
         ["eml", "envelopeRegular"],
         ["mp4", "video"],
      ],
   );
   private readonly manageFiles = inject(ManageFiles);
   private readonly mobileFileService = inject(MobileFileService);

   public ngOnInit() {
      if (!this.file) {
         throw new Error("FileListItem requires a file object");
      }
      this.ext = this.manageFiles.getFileExtViaRegex(this.file.fileName ?? "");
      if (this.url) {
         this.cacheBustedUrl = this.urlStringManipulator.setSearchParam(
            this.urlStringManipulator.setRemainderAfterOrigin(
               window.location.origin,
               this.url,
            ),
            "time",
            String(Date.now()),
         );
      } else if ("getURL" in this.file) {
         this.cacheBustedUrl = this.urlStringManipulator.setSearchParam(
            this.urlStringManipulator.setRemainderAfterOrigin(
               window.location.origin,
               this.file.getURL,
            ),
            "time",
            String(Date.now()),
         );
      }
      if (this.cacheBustedUrl === undefined) {
         throw new Error("No url specified");
      }
      this.fileIcon = this.fileExtensionIconMap.has(this.ext)
         ? (this.fileExtensionIconMap.get(this.ext) ?? "file")
         : "file";
   }

   protected handleFileClick(): void {
      if (this.cacheBustedUrl === undefined) {
         throw new Error("URL is undefined");
      }

      if (isNativeMobileApp()) {
         this.mobileFileService.viewFile(this.cacheBustedUrl);
         return;
      }

      window.open(this.cacheBustedUrl, "_blank");
   }
}
