import { NgClass, SlicePipe } from "@angular/common";
import type { OnDestroy, OnInit, Signal } from "@angular/core";
import {
   Component,
   computed,
   EventEmitter,
   inject,
   input,
   Input,
   Output,
   signal,
} from "@angular/core";
import {
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   IconComponent,
   LimbleHtmlDirective,
   LoadingBarService,
   manageDemo,
   MinimalIconButtonComponent,
   ModalService,
   RowHoverButtonsComponent,
   OptionsDropdownComponent,
   type DropdownOption,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ConfirmModalComponent } from "src/app/lite/ui/confirm-modal/confirm-modal.component";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { PopSchedules } from "src/app/schedules/popSchedulesModal/popSchedules.modal.component";
import { PopRecurrences } from "src/app/schedules/recurrences/popRecurrencesModal/popRecurrences.modal.component";
import { PopupUpsellModal } from "src/app/shared/components/global/popupUpsellModal/popup-upsell-modal.component";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { QRCodesStartTask } from "src/app/tasks/components/qrCodesStartTaskModal/qrCodesStartTask.modal.component";
import { SetupWorkOrder } from "src/app/tasks/components/setupWorkOrderModal/setupWorkOrder.modal.component";
import { AssetHierarchyListItemComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/asset-hierarchy-list-item/asset-hierarchy-list-item.component";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesFacadeService } from "src/app/tasks/components/shared/services/task-templates-facade/task-templates-facade.service";
import { RecurrenceListItemComponent } from "src/app/tasks/components/task-template-list-item/reccurence-list-item/recurrence-list-item.component";
import { UpdateRelatedTasksComponent } from "src/app/tasks/components/updateRelatedTasksModal/updateRelatedTasks.component";
import { UpdateRelatedTasksLegacyComponent } from "src/app/tasks/components/updateRelatedTasksModalLegacy/updateRelatedTasksLegacy.component";
import { DefaultSearchHintService as SearchHintService } from "src/app/tasks/search-hint/default-search-hint.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickUserOrProfileModalComponent } from "src/app/users/components/pick-user-or-profile/modal/pick-user-or-profile-modal.component";
import type {
   ExtraUsersOptions,
   PickUserOrProfileData,
} from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.types";
import { ManageProfile } from "src/app/users/services//manageProfile";
import { ManageUser } from "src/app/users/services//manageUser";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "task-template-list-item",
   templateUrl: "./task-template-list-item.component.html",
   styleUrls: ["./task-template-list-item.component.scss"],
   imports: [
      NgClass,
      IconComponent,
      LimbleHtmlDirective,
      MinimalIconButtonComponent,
      RowHoverButtonsComponent,
      DropdownComponent,
      DropdownItemComponent,
      DropdownDividerComponent,
      OrderByPipe,
      AssetHierarchyListItemComponent,
      SlicePipe,
      RecurrenceListItemComponent,
      OptionsDropdownComponent,
      TooltipDirective,
   ],
})
export class TaskTemplateListItemComponent implements OnInit, OnDestroy {
   private readonly manageLang = inject(ManageLang);
   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   public readonly manageAsset = inject(ManageAsset);
   protected readonly manageSchedule = inject(ManageSchedule);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);
   private readonly manageProfile = inject(ManageProfile);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly betterDate = inject(BetterDate);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly searchHintService = inject(SearchHintService);
   private readonly legacyLaunchFlagService = inject(LegacyLaunchFlagsService);
   private readonly taskTemplatesFacadeService = inject(TaskTemplatesFacadeService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input() public selectedAssetID: number | undefined;

   @Input() public showAssetName;
   @Input() public inAssetCard;
   @Input() public restrict: boolean = false;
   public template = input.required<TaskTemplateEntity>();
   public searchValue = input<string>();
   public reachedScheduleLimit = input.required<boolean>();

   protected disableDuplicateButton = computed(() => {
      if (this.template().schedules.length === 0) {
         return false;
      }
      return this.reachedScheduleLimit();
   });

   @Output() readonly taskListItemChanged: EventEmitter<boolean> = new EventEmitter();
   protected readonly nextDueOnByRecurrence: LimbleMap<number, string> = new LimbleMap();
   protected readonly recurrencesHaveSchedules: LimbleMap<number, boolean> =
      new LimbleMap();

   protected readonly showExtraAssets = signal<boolean>(false);
   public restrictBasedOnAsset;
   public taskCurrentlyOpen;
   public assetsWatchVarSub: Subscription | null | undefined;
   public schedulesWatchVarSub: Subscription | null | undefined;
   protected featureStartPMQRCode: boolean = false;
   protected canAddPM: boolean = false;
   private featureFlags$: Subscription | undefined;

   protected assignment: Signal<string> = computed(() => {
      return this.taskTemplatesFacadeService.getTemplateAssignment(this.template());
   });

   protected readonly fieldName = signal<string | undefined>(undefined);

   protected searchHint: string | undefined = undefined;

   public ngOnInit() {
      if (!(this.template()?.schedules && this.template().assignedTo)) {
         throw new Error(
            "A template, who its assigned to, and its associated schedules are required inputs.",
         );
      }

      this.searchHint = this.searchHintService.getSearchHint(
         this.template(),
         this.searchValue(),
         {
            joinAllMatches: true,
         },
      );

      if (this.template()?.assets?.[0]) {
         if (this.restrict === true || this.template().assets[0].assetDeleted === 1) {
            this.restrictBasedOnAsset = true;
         } else {
            this.restrictBasedOnAsset = false;
         }
      } else {
         this.restrictBasedOnAsset = false;
      }

      this.featureFlags$ = this.manageFeatureFlags.features$.subscribe(
         (features: IsFeatureEnabledMap) => {
            this.featureStartPMQRCode = features.featureStartPMQRCode;
         },
      );
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.assetsWatchVarSub);
      this.featureFlags$?.unsubscribe();
   }

   protected showSingleSchedules(template: TaskTemplateEntity): void {
      const instance = this.modalService.open(PopSchedules);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: template.checklistID,
               editable: false,
               template: true,
               title: `${this.lang().UpcomingSchedulesFor}: <b>${template.checklistName}</b>`,
               message: `${this.lang().UpcomingSchedulesMsg}<br /><br />${this.lang().UpcomingSchedulesMsg2}<br /><br />${this.lang().UpcomingSchedulesMsg3}`,
            },
         },
      };
   }

   protected async updateRelatedTasks(template: TaskTemplateEntity): Promise<void> {
      assert(this.template());
      if (
         !this.credService.isAuthorized(
            this.template().locationID,
            this.credService.Permissions.BulkUpdatePMs,
         )
      ) {
         this.alertService.addAlert(this.lang().cred57Fail, "danger", 10000);
         return;
      }

      const jitTemplatesDesktopEnabled = await this.legacyLaunchFlagService.isEnabled(
         Flags.JIT_TEMPLATES_DESKTOP,
      );
      if (jitTemplatesDesktopEnabled) {
         const modalRef = this.modalService.open(UpdateRelatedTasksComponent);
         modalRef.componentInstance.checklistID = template.checklistID;
         modalRef.componentInstance.locationID = template.locationID;
         modalRef.componentInstance.checklistBatchID = template.checklistBatchID;
         await modalRef.result;
      } else {
         const instance = this.modalService.open(UpdateRelatedTasksLegacyComponent);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: {
                  checklistID: template.checklistID,
                  title: this.lang().UpdateRelatedPMs,
                  message: this.lang().UpdateRelatedPMsMsg,
               },
            },
         };
         await instance.result;
      }
   }

   protected openPopRecurrencesModal(): void {
      if (
         !this.credService.isAuthorized(
            this.template().locationID,
            this.credService.Permissions.ChangePMSchedules,
         )
      ) {
         this.alertService.addAlert(this.lang().cred55Fail, "danger", 10000);
         return;
      }

      if (this.restrictBasedOnAsset === true) {
         return;
      }

      const hint = this.lang().PopRecurrencesHint;

      const instance = this.modalService.open(PopRecurrences);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: this.template().checklistID,
               editable: false,
               template: true,
               title: `${this.lang().Schedules}: <b>${this.template().checklistName}</b>`,
               message: this.lang().AddDailyWeeklyMonthlyYearly,
               hint: hint,
            },
         },
      };
   }

   protected openPMTemplate(template: TaskTemplateEntity): void {
      let preview = false;
      if (this.restrictBasedOnAsset === true) {
         preview = true;
      }
      const instance = this.modalService.open(PopTask);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: template.checklistID,
               title: this.lang().EditPMTemplate,
               message: this.lang().EditPMTemplateMsg,
               preview: preview,
               template: true,
            },
         },
      };
   }

   protected popTask(checklistID: number): void {
      const task = this.manageTask.getTaskLocalLookup(checklistID);

      assert(task);
      const instance = this.modalService.open(PopTask);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               title: task.checklistName,
               message: "",
            },
         },
      };
   }

   protected startInstanceTask(template: TaskTemplateEntity): void {
      if (!this.featureStartPMQRCode) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            template.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred43Fail, "danger", 10000);
         return;
      }

      let assetID: number = Number(template.assetID);

      if (template?.assets?.length > 0 && this.inAssetCard === false) {
         const modalRef = this.modalService.open(PickAssets);
         const instance = modalRef.componentInstance;
         instance.message = this.lang().WhichPMWouldYouLikeToStartThisPMFor;
         instance.title = this.lang().PickAsset;
         instance.singleLocation = template.locationID;
         instance.selectOne = true;
         instance.restrictToCred = false;
         instance.iDontKnowOption = true;

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().WhichPMWouldYouLikeToStartThisPMFor,
               title: this.lang().PickAsset,
               data: {
                  singleLocation: template.locationID,
                  selectOne: true,
                  restrictToCred: false,
                  iDontKnowOption: true,
               },
            },
         };

         modalRef.result.then((asset) => {
            if (!asset) {
               return;
            }

            if (asset === "unsure") {
               assetID = 0;
            } else {
               assetID = asset.assetID;
            }

            this.triggerInstanceTask(template, assetID);
         });
      } else {
         if (this.inAssetCard === true && this.template()?.assets?.[0]) {
            assetID = this.template()?.assets?.[0].assetID;
         }

         if (this.selectedAssetID !== undefined) {
            assetID = this.selectedAssetID;
         }
         this.triggerInstanceTask(template, assetID);
      }
   }

   private triggerInstanceTask(template: TaskTemplateEntity, assetID: number): void {
      //jayce was here and he is awesome for helping daddy code
      const instance = this.modalService.open(SetupWorkOrder);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().StartTaskMsg,
            title: `${this.lang().StartTaskTitle} <b>${template.checklistName}</b>`,
            data: { WO: false, locationID: template.locationID },
         },
      };

      instance.result.then((data) => {
         if (data) {
            this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });

            this.manageTask
               .startInstanceTask(
                  template.checklistID,
                  template.locationID,
                  data.userID,
                  data.profileID,
                  data.multiUsers,
                  data.timestamp,
                  assetID,
                  data.timeOfDay,
                  this.manageUser,
                  this.manageProfile,
               )
               .then((answer) => {
                  this.loadingBarService.remove();
                  const userID = answer.data.row.userID;
                  const profileID = answer.data.row.profileID;
                  const currentUser = this.manageUser.getCurrentUser();
                  const profiles = currentUser.profileLoc;
                  let assignedToProfileIBelongTo = false;
                  for (const profile of profiles) {
                     if (
                        profile.profileID === profileID &&
                        profile.locationID === this.template()?.assets?.[0].locationID
                     ) {
                        assignedToProfileIBelongTo = true;
                     }
                  }

                  if (userID === currentUser.gUserID || assignedToProfileIBelongTo) {
                     //open the task for them since they gave it to themselves

                     const instance2 = this.modalService.open(PopTask);
                     this.paramsService.params = {
                        modalInstance: instance2,
                        resolve: {
                           data: {
                              checklistID: answer.data.row.checklistID,
                              editable: true,
                           },
                        },
                     };
                  }
                  const newChecklistID = answer.data.row.checklistID;
                  this.generateAlertWithLink(newChecklistID);
               })
               .catch(() => {
                  this.loadingBarService.remove();
               });
         }
      });
   }

   protected deleteTask(): void {
      assert(this.template());
      if (
         !this.credService.isAuthorized(
            this.template().locationID,
            this.credService.Permissions.DeletePMs,
         )
      ) {
         this.alertService.addAlert(this.lang().cred51Fail, "danger", 10000);
         return;
      }

      if (manageDemo.demo) {
         this.alertService.addAlert(
            this.lang().WereSorryButTemplatesCantBeDeletedWhenDemo,
            "danger",
            6000,
         );
      } else {
         const modalRef = this.modalService.open(ConfirmModalComponent);
         modalRef.setInput(
            "message",
            `${this.lang().AreYouSureYouWantToDelete} <b>"${this.template().checklistName}"</b>?`,
         );
         modalRef.setInput("title", this.lang().DeleteTemplate);
         modalRef.setInput("onSubmit", this.handleDeleteTaskTemplate.bind(this));
      }
   }

   protected async handleDeleteTaskTemplate() {
      await this.taskTemplatesFacadeService.delete(
         this.template().checklistID,
         this.template().locationID,
         this.selectedAssetID,
      );
   }

   protected changeSchedule(template: TaskTemplateEntity): void {
      assert(this.template());
      if (
         !this.credService.isAuthorized(
            this.template().locationID,
            this.credService.Permissions.ChangePMAssignments,
         )
      ) {
         this.alertService.addAlert(this.lang().cred56Fail, "danger", 10000);
         return;
      }

      const extraUsersOptions: ExtraUsersOptions = [
         {
            userFirstName: this.lang().Unassigned,
            userID: 0,
            profileID: 0,
            dynamicAssignment: 0,
         },
      ];

      if (this.taskHasType7Recurrence(template)) {
         extraUsersOptions.push({
            userFirstName: this.lang().LastUserWhoEnteredMeterReading,
            userID: 0,
            profileID: 0,
            dynamicAssignment: 1,
         });
      }

      const modalRef = this.modalService.open(PickUserOrProfileModalComponent);
      modalRef.setInput("title", this.lang().ChangeTheTasksAssignment);
      modalRef.setInput("message", this.lang().ChangeTheTasksAssignmentMsg);
      modalRef.setInput("showAuditOptions", false);
      modalRef.setInput("locationID", template.locationID);
      modalRef.setInput("extraUsers", extraUsersOptions);
      modalRef.setInput("defaultUser", template.userID);
      modalRef.setInput("defaultProfile", template.profileID);
      modalRef.setInput(
         "defaultToDynamicAssignment",
         Boolean(this.template().dynamicAssignment),
      );
      modalRef.setInput("beforeClose", this.updateScheduleAssignment.bind(this));
   }

   public async updateScheduleAssignment(data: PickUserOrProfileData) {
      await this.manageTask.changeSchedule(
         data.userID,
         data.profileID,
         data.multiUsers,
         this.template(),
         this.manageUser,
         this.manageProfile,
         data.dynamicAssignment,
      );
      assert(this.template());
      this.template().dynamicAssignment = data.dynamicAssignment;
      this.alertService.addAlert(this.lang().successMsg, "success", 2000);
      this.taskListItemChanged.emit();
   }

   private taskHasType7Recurrence(template: TaskTemplateEntity): boolean {
      if (!template?.recurrences) return false;
      for (const recurrence of template.recurrences) {
         if (recurrence?.reoccurType === 7) {
            return true;
         }
      }
      return false;
   }

   viewAsset = (assetID: number) => {
      assert(this.template());

      const instance = this.modalService.open(PopAsset);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: assetID,
            data: {
               restrict: false,
            },
         },
      };
   };

   protected async changeAssetOnTask(): Promise<void> {
      if (
         !this.credService.isAuthorized(
            this.template().locationID,
            this.credService.Permissions.ChangeTheAssetThisPMBelongsTo,
         )
      ) {
         this.alertService.addAlert(this.lang().cred54Fail, "danger", 10000);
         return;
      }

      const preselectedAssetIDs = new Set([
         Number(this.template().assetID),
         ...(this.template()?.assets?.map((asset) => asset.assetID) ?? []),
      ]);

      //first we need them to pick an asset
      const singleLocation = this.template().locationID;

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.lang().WhichAssetWouldYouLikeToAssignThisPMTo;
      instance.title = this.lang().PickAsset;
      instance.singleLocation = singleLocation;
      instance.selectOne = false;
      instance.restrictToCred = false;
      instance.iDontKnowOption = true;
      instance.preselectedAssetIDs = Array.from(preselectedAssetIDs);
      instance.onSubmit = this.handlePickAssetsModalResult.bind(this);

      await modalRef.result;
   }

   protected async handlePickAssetsModalResult(assets) {
      if (assets) {
         let newAsset;
         const extraAssets: any = [];
         if (assets === "unsure") {
            newAsset = {
               assetID: 0,
               assetName: "",
               locationID: this.template().locationID,
            };
         } else {
            newAsset = assets[0];

            if (assets.length > 0) {
               for (const asset of assets) {
                  if (asset.assetID !== newAsset.assetID) {
                     extraAssets.push(asset.assetID);
                  }
               }
            }
         }

         const answer = await this.manageTask.updateTasksAsset(
            this.template().checklistID,
            newAsset,
            extraAssets,
         );
         if (answer.data.success !== true) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }

         this.reloadDataAfterAssetUpdate(newAsset);
      }
   }

   protected async reloadDataAfterAssetUpdate(newAsset) {
      if (newAsset.assetID !== 0) {
         await this.manageAsset.getData();
      }

      //JIT TODO: remove the below call after JIT PM management page is live (CMMS-2527)
      this.manageTask.getData();

      this.alertService.addAlert(this.lang().successMsg, "success", 2000);
   }

   protected async removeAssetFromPM(template: TaskTemplateEntity): Promise<void> {
      if (
         !this.credService.isAuthorized(
            template.locationID,
            this.credService.Permissions.ChangeTheAssetThisPMBelongsTo,
         )
      ) {
         this.alertService.addAlert(this.lang().cred54Fail, "danger", 10000);
         return;
      }

      const newAsset = {
         assetID: 0,
         assetName: "",
         locationID: template.locationID,
      };

      const answer = await this.manageTask.updateTasksAsset(
         template.checklistID,
         newAsset,
         [],
      );
      this.taskListItemChanged.emit();
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      if (answer.data.reloadData !== true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
         return;
      }
      this.reloadDataAfterAssetUpdate(newAsset);
   }

   protected async duplicatePM(): Promise<void> {
      assert(this.template());
      if (
         !this.credService.isAuthorized(
            this.template().locationID,
            this.credService.Permissions.AddPMs,
         )
      ) {
         this.alertService.addAlert(this.lang().cred50Fail, "danger", 10000);
         return;
      }
      const canDuplicatePm = await this.taskTemplatesFacadeService.canDuplicateTemplate(
         this.template(),
      );
      if (!canDuplicatePm) {
         const popupUpsellInstance = this.modalService.open(PopupUpsellModal);
         this.paramsService.params = {
            modalInstance: popupUpsellInstance,
            resolve: {
               data: {
                  icon: "cloneRegular",
                  title: this.lang().FeatureUnlimitedPMPopupModalTitle,
                  message: this.lang().FeatureUnlimitedPMPopupModalMessage,
                  iconColor: "success",
                  iconSize: "extra-large",
                  actionText: this.lang().LearnMore,
                  cancelText: this.lang().Cancel,
                  actionLink: "https://limblecmms.com/pricing/",
               },
            },
         };
         return;
      }

      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      let name = this.template().checklistName ?? "";
      name = name.replace(` - ${this.lang().Copy}`, "");
      name = `${name} - ${this.lang().Copy}`;

      this.manageTask
         .newTaskTemplateFromCopy(
            this.template().checklistID,
            Number(this.template().assetID),
            this.template().locationID,
            name,
            true,
         )
         .then((answer) => {
            if (answer.data.success === true) {
               this.alertService.addAlert(
                  this.lang().TemplateSuccessfullyDuplicated,
                  "success",
                  3000,
               );

               this.loadingBarService.remove();
               this.taskListItemChanged.emit();
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   protected viewQRCodes(template: TaskTemplateEntity): void {
      if (!this.featureStartPMQRCode) {
         // Upsell modal here
         const popupUpsellInstance = this.modalService.open(PopupUpsellModal);
         this.paramsService.params = {
            modalInstance: popupUpsellInstance,
            resolve: {
               data: {
                  icon: "qrCodeRegular",
                  title: this.lang().FeatureStartPMQRCodePopupModalMessage,
                  message: this.lang().FeatureStartPMQRCodePopupModalSubMessage,
                  iconColor: "success",
                  iconSize: "extra-large",
                  actionText: this.lang().LearnMore,
                  cancelText: this.lang().Cancel,
                  actionLink: "https://limblecmms.com/pricing/",
               },
            },
         };
         return;
      }

      const instance = this.modalService.open(QRCodesStartTask);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().TaskQRCodesMsg,
            title: this.lang().TaskQRCodes,
            startingTaskID: template.checklistID,
            type: "pm",
         },
      };
   }

   private generateAlertWithLink(checklistID: number): void {
      const link = checklistID.toString();
      const linkInfo = link ? { link: link, title: this.lang().ViewTask } : undefined;

      this.alertService.addAlert(
         this.lang().TheTasksWereSuccessfullyGenerated,
         "success",
         7000,
         undefined,
         undefined,
         linkInfo,
      );
   }

   protected getDropdownOptions() {
      const options: DropdownOption[] = [];

      if (this.template().checklistTemplate === 1 && !this.restrictBasedOnAsset) {
         options.push({
            label: this.lang().ViewUpcomingSchedules,
            icon: "squareCaretDownRegular",
            action: () => {
               this.showSingleSchedules(this.template());
            },
         });
      }

      if (!this.restrictBasedOnAsset) {
         options.push({
            label: this.lang().StartNewTaskUsingThisPMTemplate,
            icon: "squareArrowUpRightRegular",
            action: () => {
               this.startInstanceTask(this.template());
            },
         });
      }

      if (this.template().checklistTemplate === 1 && !this.restrictBasedOnAsset) {
         options.push({
            label: this.lang().ManagePMTemplateRelations,
            icon: "rssRegular",
            action: () => {
               this.updateRelatedTasks(this.template());
            },
         });
      }

      if (this.template().checklistTemplate === 1 && !this.restrictBasedOnAsset) {
         options.push({
            label: this.lang().PrintPMTemplateQRCodes,
            icon: "qrCodeRegular",
            action: () => {
               this.viewQRCodes(this.template());
            },
         });
      }

      if (!this.restrictBasedOnAsset && !this.disableDuplicateButton()) {
         options.push({
            label: this.lang().DuplicatePMTemplate,
            icon: "cloneRegular",
            action: () => {
               this.duplicatePM();
            },
         });
      }

      if (!this.restrictBasedOnAsset) {
         options.push({
            label: this.lang().DeletePMTemplate,
            icon: "trashCanRegular",
            iconColor: "danger",
            textColor: "danger",
            action: () => {
               this.deleteTask();
            },
         });
      }

      return options;
   }
}
