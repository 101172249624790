@if (vendor) {
   <div class="purchasing-tab-items">
      <lim-ui-panel [table]="true" [noSidePadding]="true">
         <div class="vendor-purchase-orders-panel-header">
            <span class="text-with-button">
               <span class="panel-text">
                  <lim-ui-icon icon="filePowerpoint" />
                  {{ lang().AssociatedPOs }}
               </span>

               <lim-ui-primary-button
                  (click)="createAPurchaseOrder()"
                  [icon]="canAddPOs ? 'squarePlus' : 'stars'"
                  [limUiPopover]="startAddPOButtonPopover"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'right'"
                  [limUiPopoverHidden]="canAddPOs"
                  [disabled]="!canAddPOs"
                  >{{ lang().AddAPO }}
               </lim-ui-primary-button>
               <ng-template #startAddPOButtonPopover>
                  <upsell-popover
                     [message]="lang().FeatureAddPOPopoverMessage"
                     [pricingLinkText]="lang().LearnMore"
                  />
               </ng-template>
            </span>

            <lim-ui-search-all-wrapper>
               <lim-ui-search-box
                  searchBox
                  [(searchVal)]="search"
                  (searchValChange)="searchPurchaseOrders()"
                  [placeholder]="lang().Search"
               />
            </lim-ui-search-all-wrapper>
         </div>

         <purchase-order-list
            [purchaseOrders]="purchaseOrders"
            [searchHints]="searchHints"
            [excludeVendorColumn]="true"
         />
      </lim-ui-panel>
   </div>
}
