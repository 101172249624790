import { Injectable, inject } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { PartEntity } from "src/app/parts/components/shared/parts-api-service/parts-api.models";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import type {
   DateHint,
   SearchHints,
   User,
} from "src/app/tasks/search-hint/default-search-hint.service";
import { BuildDatesHintService } from "src/app/tasks/search-hint/hint-builders/build-dates-hint.service";
import { BuildRequestFieldsHintService } from "src/app/tasks/search-hint/hint-builders/build-request-fields-hint.service";

type SearchableEntity = TaskEntity | TaskTemplateEntity | PartEntity;

export type SearchHintBuilder = {
   [key in keyof SearchHints]: (
      value: SearchHints[keyof SearchHints],
      entity?: SearchableEntity,
   ) => string;
};

@Injectable({
   providedIn: "root",
})
export class DefaultSearchHintBuilderService {
   protected readonly manageLang = inject(ManageLang);
   protected readonly buildDatesHintService = inject(BuildDatesHintService);
   protected readonly buildRequestFieldsHintService = inject(
      BuildRequestFieldsHintService,
   );

   public getSearchHintBuilder(): SearchHintBuilder {
      const lang = this.manageLang.lang() ?? {};

      return {
         taskID: (value) => this.buildDefaultHint(lang.TaskID, value),
         taskName: (value) => this.buildDefaultHint(lang.TaskName, value),
         taskDescription: (value) => this.buildDefaultHint(lang.Description, value),
         assetName: (value) => this.buildDefaultHint(lang.Asset, value),
         locationName: (value) => this.buildDefaultHint(lang.Location, value),
         partName: (value) => this.buildDefaultHint(lang.Part, value),
         profileAssignment: (value) =>
            this.buildProfileAssignmentHint(lang.AssignedTo, value),
         userAssignment: (users) =>
            this.buildUsersHint(lang.AssignedTo, users as User[], lang.DeletedUser),
         completers: (completers) =>
            this.buildUsersHint(lang.CompletedBy, completers as User[], lang.DeletedUser),
         dates: (dateHints, taskEntity) =>
            this.buildDatesHintService.buildTaskDatesHint(
               dateHints as DateHint[],
               taskEntity as TaskEntity,
            ),
         requestFields: (requestFieldHints, taskEntity) =>
            this.buildRequestFieldsHintService.buildRequestFieldsHint(
               requestFieldHints as string[],
               taskEntity as TaskEntity,
            ),
      };
   }

   private buildDefaultHint(key: string, value: SearchHints[keyof SearchHints]): string {
      return `${key}: ${value}`;
   }

   private buildUsersHint(key: string, users: User[], deletedUserName: string): string {
      if (Array.isArray(users)) {
         const joinedUsers = users
            .map((user) => {
               if ("deleted" in user) {
                  return deletedUserName;
               }
               return `${user.firstName} ${user.lastName}`;
            })
            .join(", ");
         return `${key}: ${joinedUsers}`;
      }
      return "";
   }

   private buildProfileAssignmentHint(
      key: string,
      value: SearchHints[keyof SearchHints],
   ): string {
      if (typeof value === "object" && "profileName" in value) {
         return `${key}: ${value.profileName}`;
      }
      return `${key}: undefined`;
   }
}
