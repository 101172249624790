@if (task.completedInfo && task.completedDate) {
   <div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
      <span class="lim-ui-mobile-table-column-label">{{ lang().Completed }}:</span>

      <span class="completed-date">
         <span class="completed-date__label">
            {{ task.completedDate.getTime() | betterDate: "date" }}
         </span>

         @if (task.completionColor !== undefined) {
            <span
               class="badge ml-1 mr-2"
               [ngClass]="{
                  'badge-success': task.completionColor == 1,
                  'badge-orange': task.completionColor == 2,
                  'badge-danger': task.completionColor == 3 || task.completionColor == 0,
               }"
               limUiTooltip="{{ task.completedInfo.daysPastDueDateTooltip }}"
            >
               {{ task.completedInfo.daysPastDueDate }}
            </span>
         }
      </span>
   </div>
}
