// Copyright - 2016-2020 LIMBLE SOLUTIONS, LLC - ALL RIGHTS RESERVED
import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input } from "@angular/core";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { ManageObservables } from "src/app/shared/services/manageObservables";

@Component({
   selector: "instructional-files",
   templateUrl: "./instructionalFiles.element.component.html",
   styleUrls: ["./instructionalFiles.element.component.scss"],
   imports: [NgClass, ViewImage, FileListItem],
})
export class InstructionalFiles implements OnInit, OnDestroy {
   @Input() public item;
   public customerID;
   public customerIDSub;
   private readonly manageObservables = inject(ManageObservables);

   public ngOnInit() {
      this.customerIDSub = this.manageObservables.setSubscription("customerID", (val) => {
         this.customerID = val;
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.customerIDSub);
   }
}
