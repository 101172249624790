import type { OnInit } from "@angular/core";
import { Component, inject, signal } from "@angular/core";
import {
   BadgeComponent,
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDateRangePickerComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   PopoverDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import type { DateBoundaries } from "@limblecmms/lim-ui/lib/dropdowns/date-pickers/types/date-boundaries";
import { firstValueFrom } from "rxjs";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { FilterLabelKey } from "src/app/shared/data-viewer/data-viewer-filters/data-viewer-filter.models";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { BetterDate } from "src/app/shared/services/betterDate";

interface DateRange {
   from: Date;
   to: Date;
}

type DateFilterValue = { [x: string]: Date };

type DateFilterState =
   | "last7Days"
   | "last30Days"
   | "last60Days"
   | "last90Days"
   | "last365Days"
   | DateRange;

type DateFilterOption = {
   days: number;
   filterDirection: "past" | "future";
   labelKey: FilterLabelKey;
   stateID?: DateFilterState;
   disablement?: {
      isDisabled: boolean;
      isUpsellDisablement: boolean | undefined;
   };
};

@Component({
   selector: "date-filter",
   imports: [
      DropdownClearFilterItemComponent,
      DropdownDividerComponent,
      DropdownDateRangePickerComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      DropdownItemComponent,
      PopoverDirective,
      UpsellPopover,
      BadgeComponent,
      TranslateDirective,
   ],
   templateUrl: "./date-filter.component.html",
   styleUrls: ["./date-filter.component.scss"],
})
export class DateFilterComponent extends BaseFilterComponent implements OnInit {
   protected state?: DateFilterState | undefined;
   protected inputStartDate: Date = new Date();
   protected inputEndDate: Date = new Date();
   protected readonly dateBoundaries = signal<DateBoundaries>({});
   protected defaultDateFilters: DateFilterOption[] = [
      {
         days: 7,
         filterDirection: "past",
         labelKey: FilterLabelKey.LAST_7_DAYS,
         stateID: "last7Days",
      },
      {
         days: 30,
         filterDirection: "past",
         labelKey: FilterLabelKey.LAST_30_DAYS,
         stateID: "last30Days",
      },
      {
         days: 60,
         filterDirection: "past",
         labelKey: FilterLabelKey.LAST_60_DAYS,
         stateID: "last60Days",
      },
      {
         days: 90,
         filterDirection: "past",
         labelKey: FilterLabelKey.LAST_90_DAYS,
         stateID: "last90Days",
      },
      {
         days: 365,
         filterDirection: "past",
         labelKey: FilterLabelKey.LAST_365_DAYS,
         stateID: "last365Days",
      },
   ];

   private readonly betterDate = inject(BetterDate);
   private readonly heapService = inject(HeapService);

   public constructor() {
      super();
   }

   public ngOnInit() {
      this.initDefaultDateRange();
      this.initDefaultFilterOptions();
   }

   public handleSetDateFilterOption(dateFilterOption: DateFilterOption) {
      this.state = dateFilterOption.stateID;
      const value = this.buildDateFilterValue(dateFilterOption);
      this.setFilter(
         value,
         `${this.translateService.instant(this.filter().labelKey)} - ${this.translateService.instant(dateFilterOption.labelKey)}`,
      );
   }

   private buildDateFilterValue(dateFilterOption: DateFilterOption): DateFilterValue {
      let value: DateFilterValue = {};
      if (dateFilterOption.filterDirection === "past") {
         value = {
            [`${this.filter().key}Start`]: new Date(
               new Date().setDate(new Date().getDate() - dateFilterOption.days),
            ),
         };
      } else {
         value = {
            [`${this.filter().key}End`]: new Date(
               new Date().setDate(new Date().getDate() + dateFilterOption.days),
            ),
         };
      }
      return value;
   }

   public async handleSetDateRangeFilter(): Promise<void> {
      const from = new Date(this.inputStartDate);
      from.setHours(0, 0, 0, 0);

      const to = new Date(this.inputEndDate);
      to.setHours(23, 59, 59, 999);

      this.state = { from: new Date(from), to: new Date(to) };

      const value = {
         [`${this.filter().key}Start`]: this.state.from,
         [`${this.filter().key}End`]: this.state.to,
      };

      const labelKey = await firstValueFrom(
         this.translateService.select(this.filter().labelKey),
      );

      this.setFilter(
         value,
         `${labelKey} - ${this.betterDate.formatBetterDate(
            from,
            "date",
         )} - ${this.betterDate.formatBetterDate(to, "date")}`,
      );
   }

   public handleClear(): void {
      this.state = undefined;
      this.remove.emit(this.filter());
   }

   private setFilter(value: DateFilterValue, activeLabel: string): void {
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value,
      });

      const dataLogLabel = this.filter().dataLogLabel;
      if (dataLogLabel !== undefined) {
         this.heapService.trackEvent(dataLogLabel);
      }
   }

   private initDefaultDateRange(): void {
      const filter = this.filter();
      if (!filter.value) {
         return;
      }
      if (filter.options?.dateBoundaries?.earliestDateBoundary) {
         this.dateBoundaries.set({
            earliestDate: filter.options.dateBoundaries?.earliestDateBoundary,
         });
      }
      if (filter.value.startDate) {
         this.inputStartDate = filter.value.startDate;
      }
      if (filter.value.endDate) {
         this.inputEndDate = filter.value.endDate;
      }

      this.handleSetDateRangeFilter();
   }

   private initDefaultFilterOptions() {
      const earliestDateBoundary =
         this.filter().options?.dateBoundaries?.earliestDateBoundary;
      if (earliestDateBoundary) {
         for (const filterOption of this.defaultDateFilters) {
            const filterValue = this.buildDateFilterValue(filterOption);
            if (
               filterValue[`${this.filter().key}Start`].getTime() <
               earliestDateBoundary.getTime()
            ) {
               filterOption.disablement = {
                  isDisabled: true,
                  isUpsellDisablement:
                     this.filter().options?.dateBoundaries?.isPaywallBoundary,
               };
            }
         }
      }
   }
}
