import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

/*
 * Takes in a string representing a day of the week and returns a string for that day of the week in the user's language
 * Usage:
 *   string | fullDayPipe
 */

@Pipe({
   name: "fullDay",
   standalone: true,
})
export class FullDayPipe implements PipeTransform {
   private readonly manageLang = inject(ManageLang);

   public transform(inputIn) {
      let input = inputIn;
      if (input !== undefined) {
         const lang = this.manageLang.lang() ?? {};

         switch (input) {
            case "mon":
               input = lang.Monday;
               break;
            case "tue":
               input = lang.Tuesday;
               break;
            case "wed":
               input = lang.Wednesday;
               break;
            case "thu":
               input = lang.Thursday;
               break;
            case "fri":
               input = lang.Friday;
               break;
            case "sat":
               input = lang.Saturday;
               break;
            case "sun":
               input = lang.Sunday;
               break;
            default:
         }

         return input;
      }
      return input;
   }
}
