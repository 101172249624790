import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Subject, switchMap, type Observable } from "rxjs";
import type { FeatureFlagMapDto } from "src/app/shared/services/feature-flags/feature-flag-map.dto";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class FeatureFlagRefreshService {
   private readonly httpClient = inject(HttpClient);

   private readonly FEATURE_FLAG_ROUTE = "/customer/features";
   private readonly FEATURE_FLAG_URL = `${environment.flannelUrl}${this.FEATURE_FLAG_ROUTE}`;

   private readonly refreshTrigger = new Subject<void>();

   public readonly refreshes$ = this.refreshTrigger.pipe(switchMap(() => this.fetch()));

   public refresh(): void {
      this.refreshTrigger.next();
   }

   private fetch(): Observable<FeatureFlagMapDto> {
      return this.httpClient.get<FeatureFlagMapDto>(this.FEATURE_FLAG_URL);
   }
}
