<lim-ui-modal>
   <lim-ui-basic-modal-header
      title="{{ title }}"
      subTitle="{{ message }}"
      (closeModal)="close()"
   />
   <lim-ui-modal-body [scrollable]="true">
      <lim-ui-panel>
         @if (tools.length) {
            <div class="tool-search-container">
               <lim-ui-search-box
                  [placeholder]="lang().Search"
                  [(searchVal)]="searchText"
                  (searchValChange)="updateSearch()"
                  [focusOnLoad]="true"
               />
            </div>
         }
         @if (noSearchResults()) {
            <no-search-results />
         }
         @if (tools.length) {
            <hierarchy-container-legacy
               [treeData]="treeData"
               [options]="hierarchyOptions"
            />
         }
         @if (!tools.length) {
            <div>
               <h1 class="no-tools-header">
                  <lim-ui-icon-button
                     iconColor="youtube-red"
                     icon="youtube"
                     [limUiTooltip]="lang().needHelpWithToolsMsg"
                     (click)="watchTutorial()"
                  />
                  <span>{{ lang().WhoopsThereAreCurrentlyNoAssetsMarkedAsTools }}</span>
               </h1>
               <p class="no-tools-message">
                  {{ lang().toMarkAnAssetAsAToolMsg }}
               </p>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (tools.length > 0) {
         <lim-ui-primary-button data-log="manageTask-initiateATool" (click)="submit()">
            {{ lang().AddTool }}
         </lim-ui-primary-button>
      }
   </lim-ui-modal-footer>
</lim-ui-modal>
