<lim-ui-modal [small]="true" [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />

   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="content">
            <div class="message-and-text-container">
               @if (message && message !== "") {
                  <div class="top-message" [limbleHtml]="message"></div>
               }

               <input
                  type="number"
                  onclick="this.focus();"
                  class="form-control"
                  [(ngModel)]="number"
               />
            </div>
            @if (message2 && message2 !== "") {
               <div class="bottom-message" [limbleHtml]="message2"></div>
            }
            @if (warning && warning !== "") {
               <div class="warning-message" [limbleHtml]="warning"></div>
            }

            @if (errorMsg !== "") {
               <lim-ui-alert [title]="lang().Warning" alertType="warning">
                  {{ errorMsg }}
               </lim-ui-alert>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Submit"
      (successClick)="submit()"
   />
</lim-ui-modal>
