<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="i18n().t('PickBudget')" (closeModal)="close()" />
   <lim-ui-modal-body [scrollable]="true">
      <lim-ui-panel>
         <div class="panel-content">
            <div class="message-with-search-section">
               <div class="message">{{ i18n().t("PickBudgetMsg") }}</div>
               <lim-ui-search-box
                  [(searchVal)]="searchQuery"
                  [clearText]="i18n().t('clear')"
                  [placeholder]="i18n().t('Search')"
                  [focusOnLoad]="true"
               />
            </div>
            @if (nodes().length > 0) {
               <hierarchy-container-legacy
                  #hierarchy
                  [treeData]="nodes()"
                  [options]="hierarchyOptions"
               />
            } @else {
               <no-search-results />
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-secondary-button (click)="close()">
         {{ i18n().t("Cancel") }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button (click)="submit()">{{
         i18n().t("Select")
      }}</lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
