import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { DraggableDirective, type TreeBranch } from "@limble/limble-tree";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   DropdownComponent,
   DropdownItemComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { FieldItem } from "src/app/parts/components/field/fieldItem/fieldItem.element.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { PartField } from "src/app/parts/types/field/field.types";
import type { PartFieldType } from "src/app/parts/types/field/type/part-field-type.types";
import type { PartFieldValue } from "src/app/parts/types/field/value/part-field-value.types";
import type { Part } from "src/app/parts/types/part.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type ValueExtraInfo = {
   json: Array<any>;
   fieldTypeIcon: string | null;
   fieldTypeHint: string | null;
};

type PartCreds = {
   viewManageParts: boolean;
   configurePartFields: boolean;
};

@Component({
   selector: "part-information-item",
   templateUrl: "./part-information-item.component.html",
   styleUrls: [
      "./part-information-item.component.scss",
      "../../../fields/shared-field-styles.scss",
   ],
   imports: [
      IconComponent,
      DraggableDirective,
      TooltipDirective,
      LimbleHtmlDirective,
      DropdownComponent,
      MinimalIconButtonComponent,
      DropdownItemComponent,
      FieldItem,
      IconAlias,
   ],
})
export class PartInformationItemComponent implements OnInit, OnDestroy {
   @Input() public treeBranch?: TreeBranch<PartInformationItemComponent>;
   @Input() public restrict;

   public fieldValue: PartFieldValue | undefined;
   public currencySymbol;
   public part: Part | undefined;
   public fieldOptionsObs;
   public fieldOptionsSub;
   public fieldInfo: PartField | undefined;
   public fieldTypes: Lookup<"fieldTypeID", PartFieldType>;
   public valueExtraInfo: ValueExtraInfo | undefined;
   public creds: PartCreds = {
      viewManageParts: false,
      configurePartFields: false,
   };
   protected eyeSlashIcon: Aliases = "eyeSlashRegular";
   protected eyeIcon: Aliases = "eyeRegular";
   protected fieldLocked: boolean = false;

   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.fieldTypes = this.manageParts.getFieldTypes();
   }

   public ngOnInit() {
      this.fieldValue = this.treeBranch?.meta().nodeData;
      if (this.fieldValue === undefined) {
         throw new Error(
            "fieldItem component requires a `fieldValue` input binding, but a value was not provided.",
         );
      }

      this.fieldInfo = this.manageParts.getFields().get(this.fieldValue.fieldID);
      if (this.fieldInfo === undefined) {
         throw new Error(
            "fieldItem component requires `fieldInfo`, no field for the given fieldID exists.",
         );
      }

      const currentUser = this.manageUser.getCurrentUser();
      this.fieldLocked = Boolean(
         this.fieldInfo.lockedDefault && !this.manageUtil.checkIfSuperUser(currentUser),
      );

      const fieldType = this.fieldTypes.get(this.fieldInfo.fieldTypeID);
      const typeIcon = fieldType ? fieldType.fieldTypeIcon : null;
      const typeHint = fieldType ? fieldType.fieldTypeHint : null;

      this.valueExtraInfo = {
         json: [],
         fieldTypeIcon: typeIcon,
         fieldTypeHint: typeHint,
      };

      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }
      //overriding this.part so that it is the part associated with the field and not the part that this item is created under
      //This is so that we can display different part's fields in a single part.  This allows for group part information
      this.part = this.manageParts.getPart(this.fieldValue.partID);
      this.getPartCreds();

      //if it is a number of currency type then it needs to become a number not a string for the html element to work.
      if (this.fieldInfo.fieldTypeID == 5 || this.fieldInfo.fieldTypeID == 6) {
         this.fieldValue.valueContent = Number(this.fieldValue.valueContent);
      }

      if (this.fieldInfo.fieldTypeID == 2) {
         if (this.fieldValue.valueContent != null) {
            this.fieldValue.valueContent = new Date(this.fieldValue.valueContent);
         }
      }
   }

   public ngOnDestroy() {
      this.manageObservables.unsubscribeAndDelete(
         this.fieldOptionsObs,
         this.fieldOptionsSub,
      );
   }

   updateFieldViewableByTech = (field) => {
      assert(this.part);
      this.manageParts
         .updateFieldViewableByTech(field, this.part.locationID)
         .then((answer) => {
            if (answer.data.success == true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 3000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   removeField = () => {
      const warningHTML = this.lang().RemoveFieldAreYouSure;
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: warningHTML,
            title: this.lang().RemoveField,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            assert(this.fieldValue);
            assert(this.part);
            this.manageParts.removeField(this.fieldValue, this.part).then((answer) => {
               if (answer?.data.success == true) {
                  this.manageParts.tileFieldsObs$.next(null);

                  this.manageTask.getData().then(() => {
                     //resets the correct data so that it loads properly
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  });

                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   private getPartCreds() {
      assert(this.part);
      this.creds.viewManageParts = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ViewManageParts,
      );

      this.creds.configurePartFields = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ConfigurePartInformationFields,
      );
   }
}
