import type { Asset } from "src/app/assets/types/asset.types";

export function adaptLumberyardAssetToStateAsset(dataAsset: any): Partial<Asset> {
   return {
      assetID: dataAsset.id,
      assetName: dataAsset.name,
      assetDeleted: dataAsset.deleted ? 1 : 0,
      assetFileName: dataAsset.fileName,
      assetTrackCOOLabor: dataAsset.trackCOOLabor,
      assetTrackCOOParts: dataAsset.trackCOOParts,
      assetOperationHoursPerWeek: dataAsset.operationHoursPerWeek,
      assetCreatedDate: dataAsset.createdDate,
      assetDeletedDate: dataAsset.deletedDate,
      assetLastEdited: dataAsset.lastEdited,
      migrated: dataAsset.migrated,
      migratedFeedback: dataAsset.migratedFeedback,
      assetTrackHoursManually: dataAsset.trackHoursManually,
      assetTrackHoursManuallyData: dataAsset.trackHoursManuallyData,
      parentAssetID: dataAsset.parentAssetID,
      assetOrder: dataAsset.order,
      reportProblemAssignmentUserID: dataAsset.reportProblemAssignmentUserID,
      reportProblemAssignmentProfileID: dataAsset.reportProblemAssignmentProfileID,
      assetCheckSimiliarWRScope: dataAsset.checkSimilarWRScope,
      geoLocation: dataAsset.geoLocation,
      defaultChecklistID: dataAsset.defaultChecklistID,
      canCheckOutAsTool: dataAsset.canCheckOutAsTool ? 1 : 0,
      checkOutRequiresApproval: dataAsset.checkOutRequiresApproval ? 1 : 0,
      locationID: dataAsset.locationID,
      includeChildData: dataAsset.includeChildData ? 1 : 0,
      checkOutApprovalProfileID: dataAsset.checkOutApprovalProfileID,
      checkOutApprovalUserID: dataAsset.checkOutApprovalUserID,
   };
}
