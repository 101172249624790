import { inject, Injectable } from "@angular/core";
import clone from "rfdc";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { GroupedListResponse } from "src/app/shared/services/flannel-api-service";
import type { HierarchyNode } from "src/app/shared/types/general.types";
import { DEFAULT } from "src/app/shared/utils/sortingHelpers";

const deepClone = clone();

export const HIERARCHY_LIMIT = 100;

@Injectable({
   providedIn: "root",
})
export class HierarchyService {
   private readonly manageLocation = inject(ManageLocation);

   public getLocationNodesWithoutRegions(locationIDs?: Array<number>): {
      locationsIndex: Record<number, HierarchyNode>;
      filteredTreeData: Array<HierarchyNode>;
      taskIDsToFilterOut: Array<number>;
   } {
      let locations: Array<any> = [];
      if (locationIDs?.length) {
         for (const locationID of locationIDs) {
            locations.push(this.manageLocation.getLocation(locationID));
         }
      } else {
         locations = this.manageLocation.getLocations();
      }
      const locationNodes = deepClone(locations);

      const locationsIndex: Record<number, HierarchyNode> = {};

      const treeData: Array<HierarchyNode> = [];
      const taskIDsToFilterOut: Array<number> = [];
      const locationsArray: Array<HierarchyNode> = locationNodes.map((location) => {
         taskIDsToFilterOut.push(location.checklistID);
         return {
            nodes: [],
            title: location.locationName,
            type: 1,
            icon: "houseChimney",
            unselectable: true,
            pagination: {
               page: 0,
               total: 0,
            },
            locationID: location.locationID,
            collapsed: true,
            selected: false,
         };
      });

      for (const location of locationsArray) {
         if (location.locationID === undefined) continue;

         locationsIndex[location.locationID] = location;
         location.collapsed = true;
         treeData.push(location);
      }

      const filteredTreeData = treeData.sort((one, two) => DEFAULT(one.title, two.title));

      return {
         locationsIndex,
         filteredTreeData,
         taskIDsToFilterOut,
      };
   }

   public filterTreeDataToLocationsWithResponses<T>(
      groupedTaskResponses: Array<GroupedListResponse<T> | undefined>,
      treeData: Array<HierarchyNode>,
   ) {
      const locationIDsToInclude = groupedTaskResponses.map((group) => group?.groupKey);

      return treeData.filter(
         (locationNode) =>
            locationNode.locationID &&
            locationIDsToInclude.includes(locationNode.locationID),
      );
   }
}
