import type {
   AfterViewInit,
   ElementRef,
   OnChanges,
   SimpleChanges,
   OnDestroy,
} from "@angular/core";
import {
   Component,
   computed,
   EventEmitter,
   inject,
   Input,
   Output,
   ViewChild,
} from "@angular/core";
import { isMobile, ModalService } from "@limblecmms/lim-ui";
import * as L from "leaflet";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { LanguageCode } from "src/app/languages";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageMaps } from "src/app/maps/services/manageMaps";
import type { GeoFeature } from "src/app/maps/types/geoMap.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
//eslint-disable-next-line import/order -- This import has to come after the leaflet import because it is poorly written.
import "@geoman-io/leaflet-geoman-free";

const iconRetinaUrl = "assets/marker-icon-2x.png";
const iconUrl = "assets/marker-icon.png";
const shadowUrl = "assets/marker-shadow.png";
const iconDefault = L.icon({
   iconRetinaUrl,
   iconUrl,
   shadowUrl,
   iconSize: [25, 41],
   iconAnchor: [12, 41],
   popupAnchor: [1, -34],
   tooltipAnchor: [16, 0],
   shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
   selector: "gis-map",
   templateUrl: "./gisMap.element.component.html",
   styleUrls: ["./gisMap.element.component.scss"],
   standalone: true,
})
export class GisMap implements AfterViewInit, OnChanges, OnDestroy {
   @ViewChild("map") readonly mapContainer: ElementRef<HTMLElement> | undefined;
   @Input() addGeo: boolean = false;
   @Output() readonly addGeoChange = new EventEmitter();
   @Input() editGeo: boolean = false;
   @Output() readonly editGeoChange = new EventEmitter();
   @Input() asset: Asset | undefined;
   @Input() task: Task | undefined;
   @Input() location;
   @Input() geoLocation: GeoFeature | null = null;
   @Input() initialBoundaries;
   @Output() readonly geoLocationChange = new EventEmitter();
   @Output() readonly updateGeo = new EventEmitter();
   @Input() layers;
   @Input() limitedMode: boolean = false;
   @Input() canEdit: boolean = true;
   @Input() showMyLocation: boolean = false;
   private map;
   private currentLayer;
   private readonly localeID: LanguageCode;
   private baseMaps = {};
   private overlayMaps = {};
   private layersIndex = {};
   private mapControls;
   public isMobile: boolean;
   private circleMarker: L.Circle | null = null;
   private centerMarker: L.Marker | null = null;
   private readonly defaultCircleRadius = 25;
   private readonly defaultZoomLevel = 13;

   private readonly manageMaps = inject(ManageMaps);
   private readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);
   private readonly layerZoomMap = new Map<L.Layer, number>();
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      const manageLang = inject(ManageLang);
      this.localeID = manageLang.getLocaleID();
      this.isMobile = isMobile();
   }

   public ngOnChanges(changes: SimpleChanges) {
      if (
         changes.geoLocation &&
         !changes.geoLocation?.firstChange &&
         !this.manageMaps.isTheSameGeoJSON(
            changes.geoLocation.currentValue,
            changes.geoLocation.previousValue,
         )
      ) {
         this.updateGeolocation();
      }

      if (changes.layers && !changes.layers?.firstChange) {
         this.updateLayer();
      }

      if (
         !changes.initialBoundaries?.firstChange &&
         changes.initialBoundaries?.currentValue !==
            changes.initialBoundaries?.previousValue &&
         changes.initialBoundaries?.currentValue !== null
      ) {
         this.setInitialBoundaries();
      }
   }

   public ngAfterViewInit(): void {
      this.initMap();
   }

   private setInitialBoundaries() {
      if (this.initialBoundaries) {
         this.map.fitBounds([this.initialBoundaries.geo]);
         this.map.setZoom(this.initialBoundaries.zoom);
      }
   }

   public enableAddGeo(): void {
      if (!this.map || !this.mapContainer?.nativeElement) {
         return;
      }

      // Store current state
      const center = this.map.getCenter();
      const zoom = this.map.getZoom();
      const currentBaseLayer = Object.keys(this.baseMaps).find((key) =>
         this.map.hasLayer(this.baseMaps[key]),
      );

      // Unfortunately there is a known bug when deleting a marker (any type)
      // and trying to add a textMarker in the same session (you can't add the new textMarker).
      // Recreating the map when they click "Add" fixes the issue (we remember their coordinates and zoom level)
      this.map.remove();

      // Create new map
      this.map = L.map(this.mapContainer.nativeElement, {
         zoomControl: false,
         minZoom: 2,
         fadeAnimation: true,
         zoomAnimation: true,
      }).setView(center, zoom);

      // Re-add layer change listener
      this.map.on("baselayerchange", this.handleBaseLayerChange);

      // Create custom pane for markers
      this.map.createPane("highMarkers");
      const highMarkersPane = this.map.getPane("highMarkers");
      if (highMarkersPane) {
         highMarkersPane.style.setProperty("z-index", "2000", "important");
      }

      // Re-add the same base layer that was active
      if (currentBaseLayer && this.baseMaps[currentBaseLayer]) {
         this.baseMaps[currentBaseLayer].addTo(this.map);
      }

      // Re-add overlay maps if they exist
      if (this.overlayMaps) {
         this.mapControls = L.control
            .layers(this.baseMaps, this.overlayMaps)
            .addTo(this.map);
      }

      // Re-add existing layers
      if (this.layers?.length > 0) {
         this.setupOverlayMaps();
      }

      if (this.limitedMode) {
         this.map.pm.addControls({
            position: "topleft",
            drawControls: true,
            editControls: false,
            drawCircleMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawPolygon: false,
            drawCircle: false,
         });
      } else {
         this.map.pm.addControls({
            position: "topleft",
            drawControls: true,
            editControls: false,
         });
      }
      this.map.pm.enableDraw("Marker", { markerStyle: { icon: iconDefault } });
      this.map.on("pm:create", this.addGeolocation);
   }

   public disableAddGeo(): void {
      if (!this.map) {
         return;
      }
      this.map.pm.removeControls();
      this.map.pm.disableDraw();

      this.map.off("pm:create", this.addGeolocation);
   }

   private readonly addGeolocation = (event) => {
      const geoJSON = event.marker.toGeoJSON();
      this.currentLayer = event.layer;

      // If this is a text marker, set text marker update event and exit out of function
      if (this.currentLayer.options?.textMarker) {
         this.setTextMarkerUpdateEvent(geoJSON);
         return;
      }

      // If this is a circle or circleMarker
      if (event.shape.includes("Circle")) {
         this.setCircleRadius(geoJSON, event.shape, event.marker.options?.radius);
      }

      this.save(geoJSON);
   };

   private save(geoJSON: GeoFeature): void {
      this.addCurrentLayerClickEvent();
      this.addGeoChange.emit(false);
      this.disableAddGeo();
      this.saveGeo(geoJSON);
   }

   private addCurrentLayerClickEvent() {
      if (!this.canEdit) {
         return;
      }
      if (this.currentLayer) {
         this.currentLayer.on("click", () => {
            this.editGeo = true;
            this.editGeoChange.emit(true);
         });
      }
   }

   private async saveGeo(geoJSON) {
      if (this.manageMaps.isTheSameGeoJSON(geoJSON, this.geoLocation)) {
         return;
      }
      this.updateGeo.emit(geoJSON);
      this.geoLocation = geoJSON;
      this.geoLocationChange.emit(this.geoLocation);
      if (this.asset?.assetID) {
         const results = await this.manageMaps.saveAssetGeo(this.asset.assetID, geoJSON);
         if (results?.data?.success) {
            this.asset.geoLocation = geoJSON;
         }
      } else if (this.task?.checklistID) {
         const results = await this.manageMaps.saveTaskGeo(
            this.task.checklistID,
            geoJSON,
         );
         if (results?.data?.success) {
            this.task.geoLocation = geoJSON;
            this.manageTask.incTasksWatchVar();
         }
      } else if (this.location?.locationID) {
         const results = await this.manageMaps.saveLocationGeo(
            this.location.locationID,
            geoJSON,
         );
         if (results?.data?.success) {
            this.location.geoLocation = geoJSON;
         }
      }
   }

   public enableEditGeo(): void {
      if (!this.currentLayer) {
         return;
      }

      if (this.centerMarker) {
         this.map.removeLayer(this.centerMarker);
         this.centerMarker = null;
      }

      // Disable tooltips when entering edit mode
      if (this.currentLayer.eachLayer) {
         this.currentLayer.eachLayer((layer: any) => {
            if (layer.unbindTooltip) {
               layer.unbindTooltip();
            }
         });
      } else if (this.currentLayer.unbindTooltip) {
         this.currentLayer.unbindTooltip();
      }

      const isPolygonOrPolyline = Boolean(
         Object.values(this.currentLayer?._layers ?? {}).some(
            (layer: any) =>
               layer instanceof L.Polygon ||
               layer instanceof L.Polyline ||
               layer instanceof L.Rectangle,
         ),
      );

      const isMarkerType = Boolean(
         Object.values(this.currentLayer?._layers ?? {}).some(
            (layer: any) => layer instanceof L.Marker,
         ),
      );

      if (this.limitedMode) {
         this.map.pm.addControls({
            position: "topleft",
            drawControls: false,
            editControls: true,
            dragMode: false,
            cutPolygon: false,
            removalMode: false,
            rotateMode: false,
         });
      } else {
         this.map.pm.addControls({
            position: "topleft",
            drawControls: false,
            editControls: true,
            rotateMode: isPolygonOrPolyline, // Enable rotate only if makes sense (ex. no need to rotate a circle)
            editMode: !isMarkerType, // Disable resizing for marker types (regular and text)
            cutPolygon: false, // This was always broken, probably not worth fixing
            removalMode: false, // Makes no sense to save an empty shape, better to delete
         });
      }

      try {
         if (this.currentLayer.off) {
            this.currentLayer.off();
         }
         this.currentLayer.pm.enable();
      } catch (error) {
         console.warn("Error enabling edit mode:", error);
      }
   }

   public disableEditGeo(): void {
      if (!this.currentLayer) {
         return;
      }

      try {
         // First disable editing mode on all layers -- this prevents weird artifacts like temporary lingering radius lines
         this.map.pm.disableGlobalEditMode();

         // Then remove controls
         this.map.pm.removeControls();

         // Safely disable the current layer's edit mode
         if (this.currentLayer.pm && typeof this.currentLayer.pm.disable === "function") {
            this.currentLayer.pm.disable();
         }
      } catch (error) {
         console.warn("Error disabling edit mode:", error);
      }

      let geoJSON;

      // Handle circle markers specifically
      if (this.circleMarker) {
         geoJSON = this.handleCircleMarkerGeoJSON();
      } else {
         // Existing logic for other types of layers
         if (this.currentLayer?.pm?._layer) {
            geoJSON = this.currentLayer?.pm?._layer.toGeoJSON();
         } else if (this.currentLayer?.pm?._layers?.length > 0) {
            geoJSON = this.currentLayer.pm._layers[0].toGeoJSON();
         } else if (this.currentLayer?.pm?._layerGroup?._layers) {
            const keys = Object.keys(this.currentLayer.pm._layerGroup._layers);
            const key = keys[0];
            geoJSON = this.currentLayer.pm._layerGroup._layers[key].toGeoJSON();
         }
      }

      if (geoJSON) {
         this.saveGeo(geoJSON);
      }
   }

   public cancelEditGeo(): void {
      if (!this.currentLayer) {
         return;
      }
      this.map.pm.removeControls();
      this.currentLayer.pm.disable();
      this.updateGeolocation();
   }

   public cancelAddGeo(): void {
      this.disableAddGeo();
   }

   public showLayer(tempLayer, layerID: number) {
      if (this.layersIndex[layerID]) {
         this.removeLayer(layerID);
      }
      const newLayer = this.getGeoJSONLayer(tempLayer);
      newLayer.addTo(this.map);
      this.layersIndex[layerID] = newLayer;
   }

   public setLayersBounds() {
      if (Object.keys(this.layersIndex).length === 0) return;
      const tempLayers: any = [];
      for (const index in this.layersIndex) {
         tempLayers.push(this.layersIndex[index]);
      }
      this.map.fitBounds(L.featureGroup(tempLayers).getBounds());
   }

   public removeLayer(layerID) {
      const layer = this.layersIndex[layerID];
      if (!layer) {
         return;
      }
      layer.eachLayer((tempLayer) => {
         this.map.removeLayer(tempLayer);
      });
      this.map.removeLayer(layer);
      this.layersIndex[layerID] = null;
   }

   private updateGeolocation() {
      if (!this.map) {
         return;
      }

      if (this.centerMarker) {
         this.map.removeLayer(this.centerMarker);
         this.centerMarker = null;
      }

      if (this.currentLayer) {
         this.map.removeLayer(this.currentLayer);
         this.currentLayer = null;
      }

      if (this.circleMarker) {
         this.map.removeLayer(this.circleMarker);
         this.circleMarker = null;
      }

      if (this.geoLocation) {
         this.currentLayer = this.setupMarker(this.geoLocation).addTo(this.map);
         this.addCurrentLayerClickEvent();
         this.map.fitBounds(this.currentLayer.getBounds());
      }
   }

   private updateLayer() {
      if (!this.layers || this.layers?.length === 0) {
         if (this.currentLayer) {
            this.map.removeLayer(this.currentLayer);
            this.currentLayer = null;
         }
         return;
      }
      if (this.mapControls) {
         this.mapControls.remove(this.map);
         this.map.eachLayer((tempLayer) => {
            if (!tempLayer._url) {
               this.map.removeLayer(tempLayer);
            }
         });
      }

      const mapLayers: Array<any> = this.setupOverlayMaps();

      // Force marker pane to always be on top
      const markerPane = this.map.getPanes().markerPane;
      if (markerPane) {
         // Use !important to override any other styles
         markerPane.style.setProperty("z-index", "2000", "important");
      }

      this.mapControls = L.control
         .layers(this.baseMaps, this.overlayMaps)
         .addTo(this.map);

      if (this.currentLayer) {
         this.map.fitBounds(this.currentLayer.getBounds());
         this.map.setZoom(this.defaultZoomLevel);
      } else if (mapLayers?.length > 0 && Object.keys(mapLayers[0]._layers).length > 0) {
         this.map.fitBounds(L.featureGroup(mapLayers).getBounds());
      }

      if (this.currentLayer) {
         this.map.removeLayer(this.currentLayer);
         this.currentLayer = null;
      }
      if (this.geoLocation) {
         this.currentLayer = this.setupMarker(this.geoLocation).addTo(this.map);
         this.addCurrentLayerClickEvent();
         this.map.fitBounds(this.currentLayer.getBounds());
         this.map.setZoom(this.defaultZoomLevel);
      }
   }

   private setupOverlayMaps() {
      this.overlayMaps = {};
      const mapLayers: Array<any> = [];
      if (this.layers?.length > 0) {
         let count = 0;
         for (const tempLayer of this.layers) {
            if (!tempLayer.properties) {
               tempLayer.properties = {};
            }
            if (!tempLayer?.properties?.name) {
               tempLayer.properties.name = `layer ${count + 1}`;
            }
            mapLayers.push(this.getGeoJSONLayer(tempLayer).addTo(this.map));
            this.overlayMaps[count + 1] = mapLayers[count];
            count++;
         }
      }
      return mapLayers;
   }

   private initMap() {
      if (!this.mapContainer) {
         return;
      }

      // Create map instance
      this.map = L.map(this.mapContainer.nativeElement, {
         zoomControl: false,
         minZoom: 2,
      }).setView([0, 0], 2);

      // Create custom pane immediately after map initialization
      this.map.createPane("highMarkers");
      const highMarkersPane = this.map.getPane("highMarkers");
      if (highMarkersPane) {
         highMarkersPane.style.setProperty("z-index", "2000", "important");
      }

      // Add base tile layers
      const googleStreets = L.tileLayer(
         `https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=${this.localeID}`,
         {
            maxZoom: 20,
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
            noWrap: true,
            zIndex: 100, // IMPORTANT: keep these guys low so markers always show on top
         },
      );

      const googleSat = L.tileLayer(
         `https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=${this.localeID}`,
         {
            maxZoom: 20,
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
            noWrap: true,
            zIndex: 100, // IMPORTANT: keep these guys low so markers always show on top
         },
      );

      this.baseMaps = {
         GoogleStreet: googleStreets,
         GoogleSat: googleSat,
      };

      // Load the saved view preference (GoogleStreet or GoogleSat)
      const savedView = this.loadMapViewPreference();
      if (savedView && this.baseMaps[savedView]) {
         this.baseMaps[savedView].addTo(this.map);
      } else {
         googleStreets.addTo(this.map); // Default to street view if no preference is saved
      }

      // Now add markers and other layers
      if (this.geoLocation?.type === "Feature") {
         this.currentLayer = this.setupMarker(this.geoLocation).addTo(this.map);
      }

      if (!this.canEdit) {
         this.addClickEvents(this.geoLocation, this.currentLayer);
      }

      if (this.showMyLocation) {
         this.loadMyLocation();
      }

      const mapLayers: Array<any> = this.setupOverlayMaps();

      this.mapControls = L.control
         .layers(this.baseMaps, this.overlayMaps)
         .addTo(this.map);

      // Add layer change listener
      this.map.on("baselayerchange", this.handleBaseLayerChange);

      if (this.initialBoundaries) {
         this.map.fitBounds([this.initialBoundaries.geo]);
         this.map.setZoom(this.initialBoundaries.zoom);
      } else if (this.currentLayer) {
         this.map.fitBounds(this.currentLayer.getBounds());
         this.map.setZoom(this.getInitialZoomLevel());
      } else if (mapLayers?.length > 0 && mapLayers[0]._layers.length > 0) {
         this.map.fitBounds(mapLayers[0].getBounds());
      }

      if (this.isMobile) {
         this.map.on("popupopen", ($event) => {
            this.setClickEvent($event);
         });
         this.map.on("popupclose", ($event) => {
            this.removeClickEvent($event);
         });
      }
      this.addCurrentLayerClickEvent();
      window.dispatchEvent(new Event("resize"));
   }

   private setClickEvent(clickEvent) {
      const layer = clickEvent.popup._source.pm._layer;
      setTimeout(() => {
         layer.on("click", this.clickLayerEvent);
      }, 100);
   }

   private removeClickEvent(event) {
      const layer = event.popup._source.pm._layer;
      setTimeout(() => {
         layer.off("click", this.clickLayerEvent);
      }, 200);
   }

   private readonly clickLayerEvent = (event) => {
      // Add safety checks
      if (!event?.sourceTarget?.pm?._layer?.feature?.properties) {
         console.warn("Missing required properties for click event");
         return;
      }

      const properties = event.sourceTarget.pm._layer.feature.properties;
      if (properties.type === "task") {
         this.popTask(properties.id);
      } else if (properties.type === "asset") {
         this.popAsset(properties.id);
      }
   };

   private async popTask(checklistID) {
      const task = await this.manageTask.getTask(checklistID);
      if (!task) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: true,
            },
         },
      };
   }

   private popAsset(assetID) {
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   private getDIVIcon(feature): L.DivIconOptions {
      const color = this.getMarkerColor(feature);
      const icon = this.getMarkerIcon(feature);
      return {
         html: `<div class="map-default-icon-styles" style="-webkit-mask: url('/assets/img/map/${icon}') center no-repeat; mask: url('/assets/img/map/${icon}') center no-repeat; background: ${color}"></div>`,
         className: "NA",
         iconSize: [25, 41],
         iconAnchor: [12, 41],
         popupAnchor: [1, -34],
         tooltipAnchor: [16, -28],
      };
   }

   private getMarkerIcon(feature) {
      let icon = "marker.svg";
      if (feature.properties.customIcon) {
         icon = feature.properties.customIcon;
      } else if (feature.properties.type === "asset") {
         icon = "cube.svg";
      } else if (feature.properties.type === "task") {
         icon = "check-square.svg";
      }
      return icon;
   }

   private getMarkerColor(feature) {
      let color = "#3388ff";
      if (feature.properties.customColor) {
         color = feature.properties.customColor;
      } else if (feature.properties.type === "asset") {
         color = "#28a745";
      } else if (feature.properties.type === "task") {
         color = "#3388ff";
      }
      return color;
   }

   private getPathStyles(feature): L.PathOptions {
      const color = this.getMarkerColor(feature);
      return {
         color: color,
      };
   }

   private getGeoJSONLayer(tempLayer) {
      return L.geoJSON(tempLayer, {
         pointToLayer: (feature, latlng) => {
            return L.marker(latlng, {
               icon: L.divIcon(this.getDIVIcon(feature)),
               pane: "highMarkers",
               zIndexOffset: 2000,
            });
         },
         style: (feature) => this.getPathStyles(feature),
         onEachFeature: (feature, layer) => {
            this.addClickEvents(feature, layer);
         },
      });
   }

   private addClickEvents(feature, layer) {
      const name =
         feature.properties.name ??
         this.asset?.assetName ??
         this.task?.checklistName ??
         "";

      if (this.isMobile) {
         layer.bindPopup(
            `<i class="${feature.properties.popupIcon} mr-2"></i><span>${name}</span>`,
         );
      } else {
         layer.bindTooltip(name, {
            offset: [0, 25],
            opacity: 0.85,
            direction: "right",
         });

         layer.on("click", this.clickLayerEvent);
      }
   }

   private async loadMyLocation() {
      const myLocationFeature =
         await this.manageMaps.getDeviceLocationInGeoFeatureFormat();

      const myLocationLayer = L.geoJSON(myLocationFeature).addTo(this.map);
      if (!this.canEdit) {
         this.addClickEvents(myLocationFeature, myLocationLayer);
      }
      this.map.flyToBounds([
         [myLocationLayer.getBounds(), this.currentLayer.getBounds()],
      ]);
   }

   public zoomIn() {
      this.map.zoomIn();
   }

   public zoomOut() {
      this.map.zoomOut();
   }

   private setTextMarkerUpdateEvent(geoJSON: GeoFeature) {
      // Text markers behave differently and call "addGeoLocation"
      // as soon as you click to open it, but we only care about the text marker AFTER
      // it's had text entered into it, so watch for the update event.
      // Also performing a quick sanitization check (see Derek's "sanitizeHtml"
      // function in limbleHtml.directive.ts).
      this.currentLayer.on("pm:update", () => {
         if (!geoJSON.properties) return;

         const badString = this.currentLayer.options.text.includes("<");
         geoJSON.properties.text = badString
            ? "Error: cannot have HTML or scripts in text. Please delete and retry."
            : this.currentLayer.options.text;
         this.save(geoJSON);
      });
   }

   private setCircleRadius(
      geoJSON: GeoFeature,
      shape: string,
      radius: number | undefined,
   ) {
      if (!geoJSON.properties) return;
      geoJSON.properties.radius = this.defaultCircleRadius;
      if (shape === "Circle" && radius) geoJSON.properties.radius = radius;
   }

   private getTextMarker(latlng: L.LatLngExpression, text: string): L.Marker {
      const icon = L.divIcon({
         className: "", // prevents a weird upper left-hand corner darkening effect
         html: `<span style="border: 1px solid rgba(0, 0, 0, .2); background-color: #f2f2f2; color: #212427; padding: 5px; border-radius: 5px; box-shadow: 5px 3px 10px #888; width: max-content; max-width: 200px !important; display: block; word-break: break-word; font-weight: 575; letter-spacing: 0.6px; text-align: center;">${text}</span>`,
      });

      const marker = L.marker(latlng, {
         icon,
         zIndexOffset: 1000,
      });

      return marker;
   }

   private setupMarker(geoLocation: GeoFeature): L.GeoJSON {
      this.circleMarker = null;
      this.centerMarker = null;

      return L.geoJSON(geoLocation, {
         pointToLayer: (feature, latlng) => {
            if (feature.properties.radius) {
               this.circleMarker = new L.Circle(latlng, {
                  ...feature.properties,
                  zIndex: 2000,
                  pane: "highMarkers",
               });
               this.centerMarker = new L.Marker(latlng, {
                  icon: iconDefault,
                  zIndexOffset: 2000,
                  pane: "highMarkers",
               });

               // Add tooltip to both circle and centerMarker
               this.addClickEvents(feature, this.circleMarker);
               this.addClickEvents(feature, this.centerMarker);

               this.layerZoomMap.set(this.centerMarker, this.map.getZoom());
               return L.featureGroup([this.circleMarker, this.centerMarker]);
            }
            if (feature.properties.text) {
               const textMarker = this.getTextMarker(latlng, feature.properties.text);
               // Add tooltip to text marker
               this.addClickEvents(feature, textMarker);
               return textMarker;
            }
            const marker = new L.Marker(latlng, {
               icon: iconDefault,
               zIndexOffset: 2000,
               pane: "highMarkers",
            });
            // Add tooltip to regular marker
            this.addClickEvents(feature, marker);
            return marker;
         },
         onEachFeature: (feature, layer) => {
            if (
               feature.geometry.type === "Polygon" ||
               feature.geometry.type === "LineString"
            ) {
               const bounds = (layer as L.Polyline).getBounds();
               const center = bounds.getCenter();

               this.centerMarker = new L.Marker(center, {
                  icon: iconDefault,
                  zIndexOffset: 2000,
                  pane: "highMarkers",
               }).addTo(this.map);

               // Add tooltip to both the polygon/line and centerMarker
               this.addClickEvents(feature, layer);
               this.addClickEvents(feature, this.centerMarker);

               const group = L.featureGroup([layer]);
               layer.remove();
               return group;
            }
            return layer;
         },
      });
   }

   private getInitialZoomLevel(): number {
      if (this.currentLayer) {
         const bounds = this.currentLayer.getBounds();
         const size =
            Math.max(
               bounds.getNorth() - bounds.getSouth(),
               bounds.getEast() - bounds.getWest(),
            ) * 111000; // Convert degrees to meters

         return this.getZoomLevelFromSize(size);
      }

      return this.defaultZoomLevel;
   }

   private getZoomLevelFromSize(size: number): number {
      if (size < 10) return 19;
      if (size < 30) return 18;
      if (size < 50) return 17;
      if (size < 100) return 16;
      if (size < 1000) return 15;
      if (size < 1500) return 14;
      return this.defaultZoomLevel;
   }

   // Method to save the current view to local storage
   private saveMapViewPreference(view: string): void {
      try {
         localStorage.setItem("mapViewPreference", view);
      } catch (error) {
         console.warn("Unable to save map view preference:", error);
      }
   }

   // Method to load the saved view from local storage
   private loadMapViewPreference(): string | null {
      const view = localStorage.getItem("mapViewPreference");
      return view;
   }
   public switchToSatelliteView(): void {
      // eslint-disable-next-line typescript/dot-notation -- necessary for google maps
      if (this.map && this.baseMaps["GoogleSat"]) {
         // Remove current base layer
         Object.values(this.baseMaps).forEach((layer) => {
            if (this.map.hasLayer(layer)) {
               this.map.removeLayer(layer);
            }
         });
         // Add satellite layer
         // eslint-disable-next-line typescript/dot-notation -- necessary for google maps
         this.baseMaps["GoogleSat"].addTo(this.map);
         this.saveMapViewPreference("GoogleSat");
      }
   }
   public switchToStreetView(): void {
      // eslint-disable-next-line typescript/dot-notation -- necessary for google maps
      if (this.map && this.baseMaps["GoogleStreet"]) {
         // Remove current base layer
         Object.values(this.baseMaps).forEach((layer) => {
            if (this.map.hasLayer(layer)) {
               this.map.removeLayer(layer);
            }
         });
         // Add street layer
         // eslint-disable-next-line typescript/dot-notation -- necessary for google maps
         this.baseMaps["GoogleStreet"].addTo(this.map);
         this.saveMapViewPreference("GoogleStreet");
      }
   }

   private handleCircleMarkerGeoJSON(): GeoFeature | null {
      let updatedRadius;

      const layerIds = Object.keys(this.currentLayer._layers);
      const mainLayerId = layerIds[0];

      if (this.currentLayer._layers[mainLayerId]?._layers) {
         Object.values(this.currentLayer._layers[mainLayerId]._layers).forEach(
            (layer: any) => {
               if (layer.getRadius) {
                  updatedRadius = layer.getRadius();
               }
            },
         );
      }

      const geoJSON = this.circleMarker?.toGeoJSON();
      if (!geoJSON) {
         return null;
      }

      // Ensure properties exists and is initialized
      if (!geoJSON.properties) {
         geoJSON.properties = {};
      }

      // Set the radius property safely
      geoJSON.properties.radius =
         updatedRadius ?? this.circleMarker?.getRadius() ?? this.defaultCircleRadius;
      const latlng = this.circleMarker?.getLatLng();
      if (latlng) {
         this.centerMarker = new L.Marker(latlng, {
            icon: iconDefault,
            zIndexOffset: 2000,
            pane: "highMarkers",
         }).addTo(this.map);
      }

      // Re-add tooltip after edit mode
      if (!this.isMobile) {
         try {
            const name =
               geoJSON.properties.name ??
               this.asset?.assetName ??
               this.task?.checklistName ??
               "";
            if (this.circleMarker?.bindTooltip) {
               this.circleMarker.bindTooltip(name, {
                  offset: [0, 25],
                  opacity: 0.85,
                  direction: "right",
               });
            }
         } catch (error) {
            console.warn("Error re-adding tooltip:", error);
         }
      }

      return geoJSON;
   }

   private readonly handleBaseLayerChange = (event: L.LayersControlEvent): void => {
      if (event.name === "GoogleSat") {
         this.saveMapViewPreference("GoogleSat");
      } else if (event.name === "GoogleStreet") {
         this.saveMapViewPreference("GoogleStreet");
      }
   };

   public ngOnDestroy(): void {
      if (this.map) {
         // Remove all map-level event listeners
         this.map.off("baselayerchange");
         this.map.off("pm:create");
         this.map.off("popupopen");
         this.map.off("popupclose");

         // Clean up current layer events if it exists
         if (this.currentLayer) {
            this.currentLayer.off("click");
            this.currentLayer.off("pm:update");

            // If it's a feature group, clean up its layers
            if (this.currentLayer.eachLayer) {
               this.currentLayer.eachLayer((layer) => {
                  layer.off("click", this.clickLayerEvent);
               });
            }
         }

         // Clean up circle and center marker if they exist
         if (this.circleMarker) {
            this.circleMarker.off();
            this.map.removeLayer(this.circleMarker);
         }

         if (this.centerMarker) {
            this.centerMarker.off();
            this.map.removeLayer(this.centerMarker);
         }

         // Clean up any remaining overlay layers
         if (this.layersIndex) {
            Object.values(this.layersIndex).forEach((layer: any) => {
               if (layer && typeof layer === "object" && "eachLayer" in layer) {
                  layer.eachLayer((sublayer: any) => {
                     sublayer.off();
                     this.map.removeLayer(sublayer);
                  });
                  this.map.removeLayer(layer);
               }
            });
         }

         // Remove the map instance
         this.map.remove();
         this.map = null;
      }
   }
}
