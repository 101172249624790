import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { ReplaySubject } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { resolvePromisesMap } from "src/app/shared/utils/app.util";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { ManageUser } from "src/app/users/services//manageUser";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ManageStatus {
   private readonly statusList: any = {};
   private readonly statusLog: any = {};
   private readonly statusLogIndexedByChecklistID: LimbleMap<number, Array<any>> =
      new LimbleMap();
   private readonly axios = axios;
   private defaultStatuses;

   private readonly manageObservables = inject(ManageObservables);
   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);

   protected readonly lang = this.manageLang.lang;

   private readonly statusesLoadedSubject = new ReplaySubject<boolean>();
   public readonly statusesLoaded$ = this.statusesLoadedSubject.asObservable();

   public constructor() {
      this.statusList.arr = [];
      this.statusList.index = {};
      this.createObservables();
   }

   createObservables = () => {
      this.manageObservables.createObservable("statusList", this.statusList.arr);
   };

   private setupDefaultStatuses() {
      const lang = this.lang();
      this.defaultStatuses = [
         {
            statusID: 0,
            name: lang?.Open ?? "Open",
            sortOrder: -100,
            defaultStatus: true,
            description: lang?.statusOpenDescription,
         },
         {
            statusID: 1,
            name: lang?.InProgress ?? "In Progress",
            sortOrder: -99,
            defaultStatus: true,
            description: lang?.statusInProgressDescription,
         },
         {
            statusID: 2,
            name: lang?.Completed ?? "Completed",
            sortOrder: 999999,
            defaultStatus: true,
            isHidden: true,
            description: lang?.statusCompletedDescription,
         },
      ];
   }

   public async getStatusData(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const promises = new Map([
         ["statuses", axios.get(`${environment.flannelUrl}/statuses`)],
      ]);
      const responses = await resolvePromisesMap(promises);

      logApiPerformance("statuses", startTime, this.manageUser.getCurrentUser());

      const entries = [...responses.entries()].map((entry) => {
         return [entry[0], entry[1].data];
      });
      const unifiedResponse = Object.fromEntries(entries);
      this.sortStatusData(unifiedResponse);
      this.statusesLoadedSubject.next(true);
   }

   private sortStatusData(data) {
      this.statusList.arr = [];
      this.statusList.index = {};

      if (data?.statuses) {
         const indexedBySortOrder = data.statuses.sort((first, second) => {
            return first.sortOrder - second.sortOrder;
         });
         this.statusList.arr = indexedBySortOrder;
      }

      this.addDefaultStatuses();
      this.updateStatusIndex();

      this.manageObservables.updateObservable("statusList", 1);
   }

   getStatusLogData = async () => {
      const promises = new Map([
         ["logs", axios.get(`${environment.flannelUrl}/statuses/logs`)],
      ]);
      const responses = await resolvePromisesMap(promises);
      const entries = [...responses.entries()].map((entry) => {
         return [entry[0], entry[1].data];
      });
      const unifiedResponse = Object.fromEntries(entries);
      this.sortStatusLogData(unifiedResponse);
   };

   sortStatusLogData = (data) => {
      this.statusLog.arr = [];
      this.statusLog.index = {};

      if (data?.logs) {
         const indexedByStatusID = data.logs.sort((first, second) => {
            return first.statusID - second.statusID;
         });
         this.statusLog.arr = indexedByStatusID;

         for (const statusLog of this.statusLog.arr) {
            this.statusLog.index[statusLog.statusLogID] = statusLog;
            this.addOrUpdateStatusLogByChecklistID(statusLog);
         }
      }
   };

   private addOrUpdateStatusLogByChecklistID(statusLog) {
      const collectionByChecklistID = this.statusLogIndexedByChecklistID.get(
         statusLog.checklistID,
      );
      if (collectionByChecklistID === undefined) {
         this.statusLogIndexedByChecklistID.set(statusLog.checklistID, [statusLog]);
         return;
      }
      collectionByChecklistID.push(statusLog);
   }

   private addDefaultStatuses() {
      if (!this.defaultStatuses) {
         this.setupDefaultStatuses();
      }
      if (!this.statusList.arr) {
         this.statusList.arr = [];
      }
      this.statusList.arr = this.defaultStatuses.concat(this.statusList.arr);
      this.updateDefaultStatuses();
   }

   private updateDefaultStatuses() {
      if (this.statusList?.arr?.length === 0) {
         return;
      }
      this.setupDefaultStatuses();
      for (const status of this.defaultStatuses) {
         const defaultStatus = this.statusList.arr.find(
            (tempStat) => tempStat.statusID === status.statusID,
         );
         defaultStatus.name = status.name;
         defaultStatus.description = status.description;
      }
   }

   updateStatusIndex = () => {
      this.statusList.index = {};
      for (const status of this.statusList.arr) {
         status.sortOrder = Number(status.sortOrder);
         this.statusList.index[status.statusID] = status;
      }
   };

   clearData = () => {
      this.statusList.arr = [];
      this.statusList.index = {};
      this.manageObservables.updateObservable("statusList", 1);
   };

   getStatusList = () => {
      return this.statusList.arr;
   };

   getStatusListWithoutHidden = () => {
      return this.statusList.arr.filter((status) => !status.isHidden);
   };

   getStatusListWithoutDefaults = () => {
      return this.statusList.arr.filter((status) => !status.defaultStatus);
   };

   getStatusListIndex = () => {
      return this.statusList.index;
   };

   getStatusByOrder = (order) => {
      return this.statusList.arr.filter((status) => status.sortOrder == order)[0];
   };

   getStatusListDefaults = () => {
      return this.statusList.arr.filter((status) => status.defaultStatus);
   };

   getStatusLog = () => {
      return this.statusLog.arr;
   };

   public getStatusLogsIndexedByChecklistID() {
      return this.statusLogIndexedByChecklistID;
   }

   public getArrayOfStatusLogsByChecklistID(checklistID): Array<any> | undefined {
      return this.statusLogIndexedByChecklistID.get(checklistID);
   }

   getStatusLogIndex = () => {
      return this.statusLog.index;
   };

   createStatus = async () => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageStatus.php",
         params: {
            action: "createStatus",
         },
         data: {},
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.statusList.arr.push(answer.data.status);
            const newIndex = this.statusList.arr.length - 1;
            this.statusList.index[answer.data.status.statusID] =
               this.statusList.arr[newIndex];
            this.manageObservables.updateObservable("statusList", 1);
         }
      });
      return post;
   };

   updateSingleStatus = async (status) => {
      const statusJSON = JSON.stringify(status);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageStatus.php",
         params: {
            action: "updateSingleStatus",
         },
         data: { statusJSON: statusJSON },
      });
      return post;
   };

   public async updateStatuses(statuses): Promise<AxiosResponse> {
      const statusJSON = JSON.stringify(statuses);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageStatus.php",
         params: {
            action: "updateStatuses",
         },
         data: { statusJSON: statusJSON },
      });

      //if bulk update succeeded, update locally stored status data
      if (post.data.success) {
         this.statusList.arr = statuses;
         this.statusList.arr = this.statusList.arr.sort((first, second) => {
            return first.sortOrder - second.sortOrder;
         });
         this.addDefaultStatuses();
         this.updateStatusIndex();
         this.manageObservables.updateObservable("statusList", 1);
      }

      return post;
   }

   public async deleteStatus(statusID, sortOrder): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageStatus.php",
         params: {
            action: "deleteStatus",
         },
         data: { statusID: statusID, sortOrder: sortOrder },
      });

      if (post.data.success) {
         //locally remove the deleted status
         const indexToRemove = this.statusList.arr.findIndex(
            (item) => item.statusID === statusID,
         );
         this.statusList.arr.splice(indexToRemove, 1);
         this.updateStatusIndex();

         //reorder all statuses that have a sortOrder greater than the deleted status
         this.statusList.arr.forEach((item) => {
            if (item.sortOrder > sortOrder) {
               item.sortOrder -= 1;
            }
         });
         this.manageObservables.updateObservable("statusList", 1);
      }

      return post;
   }

   public hasOpenTasks(filterObj) {
      // filterObj is an object of status IDs used to filter a list of tasks. example - {0: true, 1: false, 2: true, 108: true}
      for (const key in filterObj) {
         if (key != "2" && filterObj[key] == true) {
            return true;
         }
      }
      return false;
   }

   public hasCompletedTasks(filterObj) {
      // filterObj is an object of status IDs used to filter a list of tasks. example - {0: true, 1: false, 2: true, 108: true}
      if (filterObj[2] == true) {
         return true;
      }
      return false;
   }

   public hasOpenAndCompletedTasks(filterObj) {
      // filterObj is an object of status IDs used to filter a list of tasks. example - {0: true, 1: false, 2: true, 108: true}
      if (!filterObj[2]) {
         return false;
      }
      for (const key in filterObj) {
         if (key != "2" && filterObj[key] == true) {
            return true;
         }
      }
      return false;
   }
}
