import { Injectable, computed, inject } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { TemplateAssignment } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";

@Injectable({ providedIn: "root" })
export class TaskTemplatesMapperService {
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public mapTemplateAssignmentToDisplay({
      firstName,
      lastName,
      unassigned,
      deleted,
      profileDescription,
   }: TemplateAssignment): string {
      if (firstName) {
         return `${firstName} ${lastName}`;
      } else if (profileDescription) {
         return profileDescription;
      } else if (deleted) {
         return this.lang().DeletedUser;
      } else if (unassigned) {
         return this.lang().Unassigned;
      }

      return "";
   }
}
