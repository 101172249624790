import { datadogLogs } from "@datadog/browser-logs";
import type { LocalData } from "src/app/shared/services/migration-logger/local-data-usage-logger.models";

/**
 * Logs the usage of local data, including the calling function and current timestamp.
 * This is used to track which components or services are the ones that are using the data
 * the most in order to prioritize them for Migration.
 */
export function logLocalDataUsage(
   localData: LocalData,
   isLoggingEnabled: boolean,
   caller?: string,
) {
   if (!isLoggingEnabled || !caller) return;

   const time = Date.now();
   const info = {
      name: "localDataUsage",
      caller,
      localData,
      time,
   };

   datadogLogs.logger.info("Migration", info);
}
