import type { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import type { PartPurchaseOrderItemDto } from "src/app/purchasing/pos/part-purchase-order-item-dto/part-purchase-order-item.dto";
import {
   CountedAndMeasuredPurchasablePurchaseOrderItemDtoSchema,
   type CountedAndMeasuredPurchasablePurchaseOrderItemDto,
} from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/counted-and-measured-purchasable-purchase-order-item.dto";
import type { UnitDisplayParserService } from "src/app/purchasing/pos/purchasable-snapshot/parsers/util/unit-display-parser.service";
import type { PurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot-parser.interface";
import type { PurchasableSnapshot } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.model";

export class CountedAndMeasuredPurchasableSnapshotParser
   implements PurchasableSnapshotParser
{
   public constructor(
      private readonly item: CountedAndMeasuredPurchasablePurchaseOrderItemDto,
      private readonly unitDisplayParserService: UnitDisplayParserService,
      private readonly unitOfMeasureService: UnitOfMeasureService,
   ) {}

   public static isValid(
      item: PartPurchaseOrderItemDto,
   ): item is CountedAndMeasuredPurchasablePurchaseOrderItemDto {
      return CountedAndMeasuredPurchasablePurchaseOrderItemDtoSchema.safeParse(item)
         .success;
   }

   public parse(): PurchasableSnapshot | null {
      const item = this.item;

      const orderUnitDisplay =
         item.purchasableCustomOrderUnitID === null
            ? this.unitDisplayParserService.parseProvided({
                 providedUnitID: item.purchasableProvidedOrderUnitID,
                 providedUnitAlias: item.purchasableProvidedOrderUnitAlias,
              })
            : this.unitDisplayParserService.parseCustom({
                 customUnitNameShort: item.purchasableCustomOrderUnitNameShort,
                 customUnitNameSingular: item.purchasableCustomOrderUnitNameSingular,
              });
      if (orderUnitDisplay === null) return null;

      const stockUnitDisplay = this.unitDisplayParserService.parseProvided({
         providedUnitID: item.partStockProvidedUnitID,
         providedUnitAlias: item.partStockProvidedUnitAlias,
      });
      if (stockUnitDisplay === null) return null;

      const sizeUnit = this.unitOfMeasureService.getUnit({
         id: item.purchasableProvidedSizeUnitID,
         type: "provided",
      })();
      if (sizeUnit === null) return null;

      const stockUnit = this.unitOfMeasureService.getUnit({
         id: item.partStockProvidedUnitID,
         type: "provided",
      })();
      if (stockUnit === null) return null;

      return {
         partID: item.partID,
         name: item.purchasableName,
         orderUnitDisplay,
         stockUnitDisplay,
         partUnits: sizeUnit.convertUnit(item.qty * item.purchasableSize, stockUnit),
      };
   }
}
