import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { injectIsFetching, injectQuery } from "@tanstack/angular-query-experimental";
import { lastValueFrom, map, type Observable } from "rxjs";
import { mapOfficialTanstackQueryToNgNeatInterface } from "src/app/shared/utils/map-official-tanstack-query-to-ngneat-interface";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class RefreshApiService {
   protected readonly httpClient = inject(HttpClient);

   private readonly QUERY_GC_TIME = 604800000; // 1 week

   readonly #isFetching = injectIsFetching({
      queryKey: ["completedTasks"],
   });
   public isBackgroundFetchingCompletedTasks$: Observable<boolean> = toObservable(
      this.#isFetching,
   ).pipe(map((fetchCount) => Boolean(fetchCount > 0)));

   protected completedTasksUrl: string | undefined;

   public getCompletedTasks(page: number, limit: number) {
      this.completedTasksUrl ??=
         localStorage.getItem("use-streamed-completed-tasks") === "yes"
            ? `${environment.flannelUrl}/tasks/completedTasksStreamed`
            : `${environment.flannelUrl}/tasks/completedTasks`;

      return mapOfficialTanstackQueryToNgNeatInterface(
         injectQuery(() => ({
            queryKey: ["completedTasks", { page, limit }],
            queryFn: async () =>
               lastValueFrom(
                  this.httpClient.get<Array<any>>(this.completedTasksUrl ?? "", {
                     params: {
                        limit: limit.toString(),
                        page: page.toString(),
                     },
                  }),
               ),
            gcTime: this.QUERY_GC_TIME,
         })),
      );
   }
}
