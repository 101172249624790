import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PickLocations } from "src/app/locations/components/pickLocations/pickLocations.element.component";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "pick-locations-modal",
   templateUrl: "./pickLocations.modal.component.html",
   styleUrls: ["./pickLocations.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      PickLocations,
      BasicModalFooterComponent,
   ],
})
export class PickLocationsModal implements OnInit {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public selectOne;
   public buttonText;
   public selectedLocations;
   public pickLocData;
   public anyLocation;

   private readonly manageLocation = inject(ManageLocation);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.selectOne = this.resolve.data.selectOne || false;
      this.buttonText = this.resolve.data.buttonText || this.lang().Change;

      this.anyLocation = false;

      this.selectedLocations = [];

      this.pickLocData = {
         selectOne: this.selectOne,
         selectedLocations: this.selectedLocations,
         massSelectOptions: true,
         submit: this.submit, //important that this is after this.submit else it will be considered undefined
      };
   }

   close = () => {
      this.modalInstance.close(false);
   };

   submit = () => {
      const locationsIndex = this.manageLocation.getLocationsIndex();
      const locations: any = [];
      for (const key in this.selectedLocations) {
         const id = this.selectedLocations[key];
         if (locationsIndex[id]) {
            locations.push(locationsIndex[id]);
         }
      }
      this.modalInstance.close(locations);
   };
}
