import type { OnInit } from "@angular/core";
import { inject, ChangeDetectorRef, Component, computed } from "@angular/core";
import { Router } from "@angular/router";
import {
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import $ from "jquery";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "import-wot-templates",
   templateUrl: "./importWOTemplates.modal.component.html",
   styleUrls: ["./importWOTemplates.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      LimbleHtmlDirective,
      PanelComponent,
      IconComponent,
      TooltipDirective,
      FileUploader,
      ModalFooterComponent,
   ],
})
export class ImportWOTemplates implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public errorMsg;
   public importAttempt;
   public locationID;
   public uploadObject;
   public addedTemplates;
   public failedTemplates;
   public sample_WO_Templates_list;
   private readonly axios = axios;

   private readonly router = inject(Router);
   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly refreshService = inject(RefreshService);
   private readonly alertService = inject(AlertService);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageAsset = inject(ManageAsset);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly changeDetectorRef = inject(ChangeDetectorRef);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.errorMsg = false;
      this.importAttempt = false;
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      setTimeout(() => {
         if (
            this.manageUser.getCurrentUser() === "none" ||
            this.manageUser.getCurrentUser() === undefined
         ) {
            this.router.navigate(["/login"]);
         }
      }, 500);

      this.message = this.resolve.message;
      this.title = this.resolve.title;

      //if this is passed we are only intending to import a location at a time.
      this.locationID = this.resolve.locationID;

      this.uploadObject = {};
      this.uploadObject.primaryID = "fileName";
      this.uploadObject.uploadTypes = "importFiles"; //images / documents / importFiles (excel + csv) or empty for default (images and documents)
      //bindings have to be all lowercase...
      this.uploadObject.viewOnly = false;
      this.uploadObject.onUploadPopEditor = false; //boolean will images immediately open for editing after upload
      this.uploadObject.posturl = `phpscripts/checklistManager.php?action=importTemplates&locationID=${this.locationID}&type=wo`;
      this.uploadObject.deleteData = {};

      this.uploadObject.uploadCall = async (posturl, data) => {
         this.resetErrors();

         return this.axios({
            url: posturl,
            method: "POST",
            data: data,
         });
      };

      this.uploadObject.uploadComplete = async (data) => {
         if (data.success == true) {
            this.addedTemplates = data.addedTemplates;

            //removes duplicates from failedReasons
            for (const failedTemplate of data.failedTemplates) {
               const arr = failedTemplate.failedReasons;
               const unique_array: any = [];

               for (const failedReason of arr) {
                  if (!unique_array.includes(failedReason)) {
                     unique_array.push(failedReason);
                  }
               }
               failedTemplate.failedReasons = unique_array;
            }

            this.failedTemplates = data.failedTemplates;

            if (this.failedTemplates.length > 0) {
               //if they had failed WO Templates we need to display an error msg.
               this.errorMsg =
                  this.lang().WeFoundErrorsWithTheListOfWOTemplatesYouUploaded;
            } else {
               await this.refreshService.refreshData(); //reload the entire app's data set.
               //successful import occured so display the list of WO Templates
            }

            this.importAttempt = true;
         } else {
            if (data.error === "missing required column") {
               this.errorMsg = `${this.lang().WhoopsYouAreMissingTheFollowingRequiredColumn} ${data.column}`;
            } else if (data.error === "type") {
               this.errorMsg = this.lang().PleaseMakeSureYouAreUploadingAnCSVFile;
            } else if (data.error === "cred") {
               this.errorMsg =
                  this.lang().PleaseMakeSureYouHaveCredentialsToImportWOTemplates;
            } else if (data.error === "too many lines") {
               this.errorMsg =
                  this.lang().YouCanImportMultipleTimesButKeepTo100WOTemplates;
            } else if (data.error === "incorrect header") {
               this.errorMsg = `${this.lang().PleaseKeepTheHeadersTheSameAsTheSampleCSVFile} <b>${data.invalidHeaderName}</b> ${this.lang().andShouldHaveBeen} ${data.correctHeader}.  `;
            } else if (data.error === "no PM Templates in CSV") {
               this.errorMsg = this.lang().ThereAreNoRowsInTheCSVFileYouUploaded;
            } else if (data.error === "catchall error") {
               this.errorMsg = this.lang().errorMsg;
            } else if (data.error === "debug") {
               console.info(data);
            }
         }
         this.changeDetectorRef.detectChanges();
      };

      //this timeout makes sure that the modal starts at the top of the screen
      setTimeout(() => {
         $(".modal").scrollTop(0);
      }, 1);
   }

   //START HERE
   deleteWO = (pm) => {
      const promise = this.manageTask.deleteTaskTemplate(pm, pm.locationID);
      promise.then(
         () => {
            for (const key in this.addedTemplates) {
               if (this.addedTemplates[key].checklistID == pm.checklistID) {
                  this.addedTemplates.splice(key, 1);
               }
            }

            this.alertService.addAlert(this.lang().NewlyAddedWODeleted, "success", 3000);
         },
         () => {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         },
      );
   };

   popAsset = (wo) => {
      const asset = this.manageAsset.getAsset(wo.assetID);
      if (!asset) {
         return;
      }
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   popWO = (wo) => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: wo.checklistID,
               editable: true,
               template: true,
               title: this.lang().ViewNewlyImportedWOTemplate,
               message: "",
            },
         },
      };
   };

   protected resetErrors(): void {
      this.errorMsg = false;
      this.importAttempt = false;
      this.failedTemplates = null;
      this.changeDetectorRef.detectChanges();
   }

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      this.errorMsg = false;
      this.modalInstance.close(1);
   };
}
