import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";

@Component({
   selector: "task-email-defaults",
   templateUrl: "./taskEmailDefaults.modal.component.html",
   styleUrls: ["./taskEmailDefaults.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      ContenteditableDirective,
      FormsModule,
      AlertComponent,
      LimbleHtmlDirective,
      BasicModalFooterComponent,
   ],
})
export class TaskEmailDefaults implements OnInit {
   public resolve;
   public errorMsg: string;
   public loadingBar: boolean;
   public emailSubject: string | undefined;
   public emailMessage: string | undefined;
   public modalInstance;
   public locationID;
   private location;
   public data;
   public title;
   public taskInfoPlaceholder = "{{task-info}}";
   public taskLinkPlaceholder = "{{task-link}}";

   private readonly alertService = inject(AlertService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.errorMsg = "";
      this.loadingBar = false;
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.title = this.resolve.title;
      this.locationID = this.data.task.locationID;
      this.location = this.manageLocation.getLocation(this.locationID);
      const defaultEmailMessage = this.data.emailMessage;
      const defaultEmailSubject = `${this.lang().SharedTask} "{{task-info}}"`;

      this.emailSubject =
         this.location.shareTaskDefaultEmailSubject || defaultEmailSubject;
      this.emailMessage =
         this.location.shareTaskDefaultEmailMessage || defaultEmailMessage;

      this.emailMessage = this.emailMessage?.replace(
         new RegExp("\\bTask\\b", "g"),
         "{{task-link}}",
      );
   }

   public close = () => {
      if (this.modalInstance !== undefined) {
         this.modalInstance.close(0);
      }
   };

   public submit = () => {
      this.loadingBar = true;

      // When the client hits the "enter" key while formatting their default email, a <div> and </div> get added to the string,
      // but we do not allow for those tags on the backend. However we do allow for <br /> in the backend,
      // so here we're replacing <div> with <br /> and </div> with empty string to achieve the same desired line spacing
      this.emailMessage = this.emailMessage
         ?.replace(new RegExp("<div>", "g"), "<br />")
         .replace(new RegExp("</div>", "g"), "");

      this.manageTask
         .setTaskEmailDefaults(this.locationID, this.emailSubject, this.emailMessage)
         .then((resp) => {
            if (resp.data.success) {
               this.location.shareTaskDefaultEmailSubject = this.emailSubject;
               this.location.shareTaskDefaultEmailMessage = this.emailMessage;
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               const data = {
                  emailSubject: this.emailSubject,
                  emailMessage: this.emailMessage,
               };
               if (this.modalInstance !== undefined) {
                  this.modalInstance.close(data);
               }
            } else {
               this.loadingBar = false;
               this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
            }
         });
   };
}
