import { z } from "zod";

export const CurrencyDtoSchema = z.object({
   currencyName: z.string(),
   currencySymbol: z.string(),
   alphabeticCodeIso: z.string(),
   rate: z.string(),
   currencyID: z.number(),
});

export const GetCurrencyDataResponseDtoSchema = z.object({
   success: z.literal(true),
   currencies: z.array(CurrencyDtoSchema),
});

export type CurrencyDto = z.infer<typeof CurrencyDtoSchema>;
export type GetCurrencyDataResponseDto = z.infer<typeof GetCurrencyDataResponseDtoSchema>;
