@if (part) {
   <div
      [ngClass]="{
         'flex-field-direction-column': usedIn === 'table',
         'flex-field-direction-row': usedIn === 'listItem',
      }"
      (click)="changeCategory()"
      limUiTooltip="{{ lang().ChangeCategory }}"
   >
      @if (credChangePartCategory) {
         <lim-ui-table-button icon="penToSquareRegular" />
      }

      <div>
         @if (manageParts.getCategory(part.categoryID); as category) {
            <span [limbleHtml]="category?.categoryName"></span>
         }
      </div>
   </div>
}
