import { inject, Injectable } from "@angular/core";
import type { Optional } from "@empowered/types/util";
import { TranslateService } from "src/app/languages";
import type { WrSubmissionDataViewerModel } from "src/app/tasks/components/shared/services/w-r-submissions-view-model/models/data-viewer-model";
import { WorkRequestsFacadeService } from "src/app/tasks/components/shared/services/work-requests-facade/work-requests-facade.service";

@Injectable({
   providedIn: "root",
})
export class WorkRequestMenuItemsService {
   private readonly facade = inject(WorkRequestsFacadeService);
   private readonly t = inject(TranslateService);

   private workRequestSubmission: Optional<WrSubmissionDataViewerModel>;

   public getItems(workRequestSubmission: WrSubmissionDataViewerModel) {
      this.workRequestSubmission = workRequestSubmission;
      return [
         {
            label: this.t.instant("Approve"),
            command: () => {
               this.handleApproveWorkRequest();
            },
         },
         {
            label: this.t.instant("Decline"),
            command: () => {
               this.handleDeclineWorkRequest();
            },
         },
         {
            separator: true,
         },
         {
            label: this.t.instant("Delete"),
            command: () => {
               this.handleDeleteWorkRequest();
            },
         },
      ];
   }

   private handleApproveWorkRequest(): void {
      if (this.workRequestSubmission) {
         this.facade.approveWorkRequest(this.workRequestSubmission.id);
      }
   }

   private handleDeclineWorkRequest(): void {
      if (this.workRequestSubmission) {
         this.facade.openDeclineWorkRequestModal(
            this.workRequestSubmission.id,
            this.workRequestSubmission.name,
            this.workRequestSubmission.locationID,
         );
      }
   }

   private handleDeleteWorkRequest(): void {
      if (this.workRequestSubmission) {
         this.facade.deleteWorkRequest(this.workRequestSubmission.id);
      }
   }
}
