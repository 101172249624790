import { NgClass } from "@angular/common";
import { input, type OnInit, inject, Component, signal, computed } from "@angular/core";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   SearchBoxComponent,
   FiltersWrapperComponent,
   FilterInputWrapperComponent,
   ClearFilterButtonComponent,
   DropdownButtonComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   DropdownDividerComponent,
   DropdownClearFilterItemComponent,
   SelectionControlsComponent,
} from "@limblecmms/lim-ui";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { Location } from "src/app/locations/types/location.types";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { DEFAULT } from "src/app/shared/utils/sortingHelpers";
import type { Role, User } from "src/app/users/components/pick-user/pick-user.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "pick-user",
   templateUrl: "./pick-user.component.html",
   styleUrls: ["./pick-user.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      SearchBoxComponent,
      NgClass,
      IconComponent,
      UserImage,
      AlertComponent,
      BasicModalFooterComponent,
      OrderByPipe,
      FiltersWrapperComponent,
      FilterInputWrapperComponent,
      ClearFilterButtonComponent,
      DropdownButtonComponent,
      DropdownItemComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownClearFilterItemComponent,
      SelectionControlsComponent,
      NoSearchResults,
   ],
})
export class PickUserComponent implements OnInit {
   public readonly modalRef: LimUiModalRef<PickUserComponent, User[] | 0> =
      inject(LimUiModalRef);

   protected readonly manageUser = inject(ManageUser);
   protected readonly manageLocation = inject(ManageLocation);

   public allUsers = input<User[]>([]);
   public multiselect = input<boolean>(false);
   public defaultToDeselected = input<boolean>(false);
   public message = input<string>("");
   public title = input<string>("");

   public users = signal<User[]>([]);
   public locations = signal<Location[]>([]);
   private readonly locationUserMap = new Map<number, User[]>();
   public roles = signal<Role[]>([]);
   public searchTerm = signal("");
   public searchTermForLocationFilter = signal<string>("");
   public searchTermForRoleFilter = signal<string>("");
   public locationFilter = signal<Location | null>(null);
   public roleFilter = signal<Role | null>(null);
   public errorMsg = signal("");

   public filteredLocations = computed(() => {
      if (this.searchTermForLocationFilter()) {
         return this.filterLocations();
      }
      return this.locations();
   });
   public filteredRoles = computed(() => {
      if (this.searchTermForRoleFilter()) {
         return this.filterRoles();
      }
      return this.roles();
   });

   protected readonly lang = inject(ManageLang).lang;

   protected readonly dataLogSubmitLabelLookup = {
      [this.lang()?.ChangeWhoLoggedTimeOnThisTask ?? ""]:
         "task-submitChangeLoggedWorkAuthor",
   };

   public ngOnInit() {
      this.users.set(this.allUsers());

      const locations = this.getSortedLocations();
      this.locations.set(locations);
      this.buildLocationUserMap(locations);

      const roles = this.getRoles();
      this.roles.set(roles);

      if (this.defaultToDeselected()) this.deselectAllUsers();
   }

   public close() {
      this.modalRef.close(0);
   }

   public focusUser(user: User) {
      if (this.multiselect() === false) {
         if (user.selected) {
            this.submit();
         } else {
            this.deselectAllUsers();
         }
      }

      user.selected = !(user.selected ?? false);
   }

   public submit() {
      const selectedUsers: User[] = [];
      this.users().forEach((user) => {
         if (user.selected && user.selected === true) {
            selectedUsers.push(user);
         }
      });

      if (this.multiselect() === false && selectedUsers.length === 0) {
         this.errorMsg.set(this.lang()?.PleaseSelectAUser ?? "");
         return;
      }

      this.modalRef.close(selectedUsers);
   }

   public filterOnSearchTerm = () => {
      if (
         this.searchTerm().length === 0 &&
         this.locationFilter() === null &&
         this.roleFilter() === null
      ) {
         this.users.set(this.allUsers());
         return;
      }

      const search = this.searchTerm().toLowerCase();
      const location = this.locationFilter();
      const role = this.roleFilter();

      const selectedUsers = this.users()
         .filter((user) => user.selected)
         .reduce((acc, user) => ({ ...acc, [user.userID]: user }), {});

      this.users.set(
         this.allUsers()
            .filter(
               (user) =>
                  selectedUsers[user.userID] ||
                  (this.userNamesMatchSearchTerm(user, search) &&
                     this.userExistsAtLocation(user, location) &&
                     this.userHasRole(user, role, location)),
            )
            .map((user) => selectedUsers[user.userID] || user),
      );
   };

   private filterLocations(): Location[] {
      return this.locations().filter((location) => {
         return location.locationName
            .toLowerCase()
            .includes(this.searchTermForLocationFilter().toLowerCase());
      });
   }

   private filterRoles(): Role[] {
      return this.roles().filter((role) => {
         return role.roleDescription
            .toLowerCase()
            .includes(this.searchTermForRoleFilter().toLowerCase());
      });
   }

   private deselectAllUsers() {
      this.users.update((users) => {
         users.forEach((user) => {
            user.selected = false;
         });
         return users;
      });
   }

   public addLocationFilter(location: Location) {
      this.locationFilter.set(location);
      this.filterOnSearchTerm();
   }

   public clearLocationFilter() {
      this.locationFilter.set(null);
      this.filterOnSearchTerm();
   }

   public addRoleFilter(role: Role) {
      this.roleFilter.set(role);
      this.filterOnSearchTerm();
   }

   public clearRoleFilter() {
      this.roleFilter.set(null);
      this.filterOnSearchTerm();
   }

   public selectAllUsers(isSelected: boolean) {
      this.users.update((users) =>
         users.map((user) => ({ ...user, selected: isSelected })),
      );
   }

   private userNamesMatchSearchTerm(user: User, searchTerm: string): boolean {
      user.userFullName = `${user.userFirstName} ${user.userLastName}`;
      return user.userFullName?.toLowerCase().includes(searchTerm) ?? false;
   }

   private userExistsAtLocation(user: User, location: Location | null) {
      return location
         ? this.locationUserMap
              .get(location.locationID)
              ?.some((locUser) => locUser.userID === user.userID)
         : true;
   }

   private userHasRole(
      user: User,
      role: Role | null,
      location: Location | null,
   ): boolean {
      if (location !== null) {
         if (role === null) return true;
         if (user.roles === undefined) return false;

         return user.roles
            .filter(
               (userRole) =>
                  userRole.locationID === location.locationID ||
                  userRole.locationID === 0,
            )
            .some((userRole) => userRole.roleID === role?.roleID);
      }

      return role
         ? user.roles?.some((userRole) => userRole.roleID === role?.roleID)
         : true;
   }

   private getSortedLocations(): Location[] {
      const locations = this.manageLocation.getLocations();
      locations.sort((locationA, locationB) =>
         DEFAULT(locationA.locationName, locationB.locationName),
      );

      return locations;
   }

   private buildLocationUserMap(locations: Location[]) {
      for (const location of locations) {
         const locationID = location.locationID;
         this.locationUserMap.set(locationID, this.getUsersAtLocation(locationID));
      }
   }

   private getUsersAtLocation(locationID): User[] {
      return this.manageUser.getUsersAndProfilesAtLocation(locationID)?.data?.users ?? [];
   }

   private getRoles(): Role[] {
      const roles = this.manageUser.getRoles().filter((role) => role.roleParent !== 1029);
      roles.sort((roleA, roleB) => DEFAULT(roleA.roleDescription, roleB.roleDescription));

      return roles;
   }
}
