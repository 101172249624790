import { Injectable } from "@angular/core";
import type { Recurrence } from "src/app/schedules/recurrence.types";

@Injectable({ providedIn: "root" })
export class RecurrenceService {
   public setShowAdvancedSettingsToDefault(
      recurrence: Recurrence,
      seasonRelations: any[],
   ): boolean {
      if (
         ((Number(recurrence.calculateBasedOnIncrementOnly) === 1 ||
            Number(recurrence.allowDuplicateInstances) === 1 ||
            seasonRelations.length > 0 ||
            Number(recurrence.calculateBasedOnLastCompletedPM) === 1) &&
            !this.recurrenceIs7Or8(recurrence)) ||
         (Number(recurrence.skipEveryX) > 0 && this.recurrenceIs7Or8(recurrence))
      ) {
         return true;
      }

      return false;
   }

   public recurrenceIs7Or8(recurrence: Recurrence): boolean {
      return Number(recurrence.reoccurType) === 7 || Number(recurrence.reoccurType) === 8;
   }
}
