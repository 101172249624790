import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { Component, computed, inject, Input } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   IconComponent,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   PaginationComponent,
   PanelComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ToolStatusInfo } from "src/app/assets/components/toolStatusInfoElement/toolStatusInfo.element.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageTool } from "src/app/assets/services/manageTool";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { CheckOutEntry } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "asset-check-in-out-tab",
   templateUrl: "./asset-check-in-out-tab.component.html",
   styleUrls: ["./asset-check-in-out-tab.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      LoadingAnimationComponent,
      ToolStatusInfo,
      SearchBoxComponent,
      NoSearchResults,
      NgClass,
      LimbleHtmlDirective,
      TooltipDirective,
      PaginationComponent,
      BetterDatePipe,
      SliceArray,
   ],
})
export class AssetCheckInOutTabComponent implements OnInit {
   private readonly manageTool = inject(ManageTool);
   private readonly manageUser = inject(ManageUser);
   protected readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);

   @Input() assetID: number | undefined;
   public asset: Asset | undefined;
   public searchText: string = "";
   public noSearchResults: boolean = false;
   public itemsPerPage: number = 10;
   public page: number = 1;
   public filteredEntries: Array<CheckOutEntry> = [];
   public mostRecentRequest;
   public loading: boolean = true;
   public orderBy: string | null = null;
   public checkOutEntries: Array<CheckOutEntry> = [];
   protected sortIcon: Aliases = "sort";
   protected sortDescIcon: Aliases = "sortDesc";
   protected sortAscIcon: Aliases = "sortAsc";
   protected entryInfo;

   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly currentUserID: number;

   public constructor() {
      this.currentUserID = Number(this.manageUser.getCurrentUser().gUserID);
   }

   public ngOnInit(): void {
      if (!this.assetID) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      this.asset = this.manageAsset.getAsset(this.assetID);
      this.getAndProcessCheckOutData().then(() => {
         this.loading = false;
      });
   }

   private async getAndProcessCheckOutData() {
      assert(this.asset);

      const data = await this.manageTool.getCheckOutDataForAsset(
         Number(this.asset.assetID),
      );
      if (!data) {
         return;
      }
      this.checkOutEntries = this.manageTool.formatCheckOutDataForAssetToLog(data);

      this.filteredEntries = this.checkOutEntries;
      this.setupEntryInfo();
      if (data.length) {
         this.mostRecentRequest = data.reduce((prev, cur) => {
            return prev.requestDate > cur.requestDate ? prev : cur;
         });
      }
   }

   public setupEntryInfo() {
      if (!this.filteredEntries) {
         return;
      }
      this.entryInfo = {};
      for (const entry of this.filteredEntries) {
         this.entryInfo[entry.date] = {};
         const splitEntry = entry.entry.split(/:(.*)/s);
         this.entryInfo[entry.date].title = splitEntry[0];
         if (splitEntry && splitEntry.length > 1) {
            this.entryInfo[entry.date].subTitle = splitEntry[1];
         }
      }
   }

   public searchLogs() {
      if (this.searchText?.length) {
         this.filteredEntries = this.checkOutEntries?.filter((entry) => {
            return Object.values(entry).some((item) => {
               return String(item)
                  .toLowerCase()
                  .trim()
                  .includes(this.searchText.toLowerCase().trim());
            });
         });
         this.page = 1;
         this.noSearchResults = !this.filteredEntries?.length;
      } else {
         this.filteredEntries = this.checkOutEntries;
         this.noSearchResults = false;
      }
   }

   //passed into sub-element
   public createCheckOutRequest = async () => {
      assert(this.asset);
      const createdRequestData = await this.manageTool.createCheckOutRequest(
         Number(this.asset.assetID),
         Number(this.asset.locationID),
      );
      if (!createdRequestData) {
         return false;
      }
      if (this.checkOutEntries) {
         this.checkOutEntries = [createdRequestData.newEntry, ...this.checkOutEntries];
         this.filteredEntries = this.checkOutEntries;
         this.setupEntryInfo();
      }
      this.mostRecentRequest = createdRequestData.fullRequest;
      return this.mostRecentRequest;
   };

   //passed into sub-element
   public checkInTool = async () => {
      if (this.currentUserID !== this.mostRecentRequest?.requestedByUserID) {
         await this.manageTool.confirmCheckIn();
      }

      const checkInData = await this.manageTool.checkToolIn(
         Number(this.mostRecentRequest?.checkOutID),
         Number(this.currentUserID),
      );
      if (!checkInData) {
         return false;
      }
      if (this.checkOutEntries) {
         this.checkOutEntries = [checkInData.entry, ...this.checkOutEntries];
         this.filteredEntries = this.checkOutEntries;
         this.setupEntryInfo();
      }
      if (this.mostRecentRequest) {
         this.mostRecentRequest.checkInDate = checkInData.entry.date;
         this.mostRecentRequest.checkedInByUserID = this.currentUserID;
      }
      return this.mostRecentRequest;
   };

   //passed into sub-element
   public cancelRequest = async () => {
      const cancelledData = await this.manageTool.cancelRequest(
         Number(this.mostRecentRequest?.checkOutID),
      );
      if (!cancelledData) {
         return false;
      }
      if (this.checkOutEntries) {
         this.checkOutEntries = [cancelledData.entry, ...this.checkOutEntries];
         this.filteredEntries = this.checkOutEntries;
      }
      if (this.mostRecentRequest) {
         this.mostRecentRequest.requestCancelledDate = cancelledData.entry.date;
      }
      return this.mostRecentRequest;
   };

   public orderByCall() {
      if (this.orderBy === "-date") {
         this.orderBy = "date";
      } else {
         this.orderBy = "-date";
      }
      if (this.checkOutEntries) {
         this.filteredEntries = orderBy(this.checkOutEntries, this.orderBy);
      }
   }

   public popTask(checklistID) {
      this.manageTask.popTask(checklistID);
   }
}
