<div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
   <span class="lim-ui-mobile-table-column-label">{{ lang().Total }}:</span>
   @if (task && task.operatingCost) {
      @if (task.operatingCost > 0) {
         <span> {{ task.operatingCost | localeCurrency: task.currencyCode }}</span>
      } @else if (task.operatingCost === 0) {
         <span>-</span>
      }
   } @else {
      <span>-</span>
   }
</div>
