import { inject, Injectable } from "@angular/core";
import { partSchema } from "src/app/lite/local-db/resources/collection/part/part.schema";
import {
   mapTaskPartDependencies,
   partsUrl,
   taskPartRelationsUrl,
} from "src/app/lite/local-db/resources/collection/task/part/task-part-initializer";
import { taskPartRelationSchema } from "src/app/lite/local-db/resources/collection/task/part/task-part-relation.schema";
import { TaskPartStorageService } from "src/app/lite/local-db/resources/collection/task/part/task-part.storage.service";
import type { TaskPart } from "src/app/lite/local-db/resources/collection/task/part/task-part.types";
import {
   refetchAndMapWithDependencies,
   refetchHttpArrayDependency,
} from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";

@Injectable({
   providedIn: "root",
})
export class TaskPartStorageSyncService extends StorageSyncService {
   private readonly taskPartStorageService = inject(TaskPartStorageService);

   public constructor() {
      super();
   }

   public async syncTaskPart(part: TaskPart): Promise<void> {
      return this.sync(async () => {
         await this.taskPartStorageService.putTaskPart(part);
      });
   }

   public async syncTaskPartDeletion(partID: number) {
      return this.sync(async () => {
         await this.taskPartStorageService.deleteTaskPart(partID);
      });
   }

   public async syncTaskPartsByTaskID(taskID: number) {
      return this.sync(async () => {
         const taskParts = await refetchAndMapWithDependencies({
            from: {
               url: taskPartRelationsUrl,
               validation: taskPartRelationSchema,
               params: {
                  ignoreLocationCreds: true,
                  taskIDs: [taskID],
               },
            },
            with: [
               refetchHttpArrayDependency({
                  url: partsUrl,
                  validationForEachElement: partSchema,
               }),
            ],
            to: (deps) => mapTaskPartDependencies(deps),
         });

         if (taskParts.length < 1) return;

         await Promise.all(
            taskParts.map(async (taskPart) =>
               this.taskPartStorageService.putTaskPart(taskPart),
            ),
         );
      });
   }
}
