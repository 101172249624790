<lim-ui-scroll-container>
   <task-form [data]="taskInput()" class="chk-wrapper" />
</lim-ui-scroll-container>
<div class="step-footer">
   <lim-ui-outlined-button color="red" (click)="deleteTemplate.emit()">
      {{ i18n().t("DeleteTemplate") }}
   </lim-ui-outlined-button>
   <div class="navigation">
      <lim-ui-secondary-button (click)="prevStep.emit()">
         {{ i18n().t("Back") + ": " + i18n().t("AssignedTo") }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button (click)="done.emit()">
         {{ i18n().t("Done") }}
      </lim-ui-primary-button>
   </div>
</div>
