<div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
   <span class="lim-ui-mobile-table-column-label">{{ lang().Labor }}:</span>
   <span class="column">
      @if (task && task.laborCost) {
         @if (task.laborCost > 0) {
            <div class="right-margin checklist-name-section-item">
               <span> {{ task.laborCost | localeCurrency: currencyCode() }}</span>
            </div>
         } @else if (task.laborCost === 0) {
            <span>-</span>
         }
      } @else {
         <span>-</span>
      }
   </span>
</div>
