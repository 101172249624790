import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   FilterInputComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PaginationComponent,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   ScrollContainerComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PickLocationsModal } from "src/app/locations/components/pickLocationsModal/pickLocations.modal.component";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { POItemLeftListShort } from "src/app/purchasing/pos/poItemLeftListShortElement/poItemLeftListShort.element.component";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { SliceLimbleMap } from "src/app/shared/pipes/sliceLimbleMap.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "pick-pos",
   templateUrl: "./pickPOs.modal.component.html",
   styleUrls: ["./pickPOs.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      SearchAllWrapperComponent,
      SearchBoxComponent,
      FilterInputComponent,
      ScrollContainerComponent,
      NgClass,
      IconComponent,
      POItemLeftListShort,
      PaginationComponent,
      NoSearchResults,
      AlertComponent,
      ModalFooterComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
      SliceLimbleMap,
      PopoverDirective,
      UpsellPopover,
   ],
})
export class PickPOs implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public selectOne;
   public showPOsWaitingToBeReceived;
   public locationID;
   public errorMsg;
   public allowCreate;
   public searchAll;
   public purchaseOrders: Lookup<"poID", PurchaseOrder> = new Lookup("poID");
   public startPOCred;
   public noSearchResults;
   public page = 1;
   public itemsPerPage = 100;
   public initialPOs: Lookup<"poID", PurchaseOrder> | undefined;
   protected searchHints: LimbleMap<number, string> = new LimbleMap();
   protected purchaseOrderSeletedInfo: LimbleMap<
      number,
      { poID: number; selected: boolean }
   > = new LimbleMap();
   protected canAddPOs: boolean = false;
   private manageFeatureFlagsSub: Subscription = new Subscription();
   private popSelectionOnly: boolean = false;

   private readonly modalService = inject(ModalService);
   private readonly managePO = inject(ManagePO);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly betterDate = inject(BetterDate);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.title = this.resolve.data.title;
      this.message = this.resolve.data.message || false;
      this.popSelectionOnly = this.resolve.data.popSelectionOnly || false;
      this.selectOne = this.resolve.data.selectOne || false;
      this.showPOsWaitingToBeReceived =
         this.resolve.data.showPOsWaitingToBeReceived || false;
      this.locationID = this.resolve.data.locationID;
      this.errorMsg = "";
      this.allowCreate = this.resolve.data.allowCreate || false;
      this.initialPOs = this.resolve.data.initialPOs;

      this.setPurchaseOrders();
   }

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub.unsubscribe();
   }
   updateSearch = () => {
      this.setPurchaseOrders();
   };

   close = () => {
      this.modalInstance.close(0);
   };

   private setPurchaseOrders(): void {
      let purchaseOrders: Lookup<"poID", PurchaseOrder> | undefined;

      if (this.initialPOs) {
         purchaseOrders = this.initialPOs;
      } else {
         purchaseOrders = this.managePO.getPurchaseOrders();
      }

      if (this.locationID > 0) {
         purchaseOrders = purchaseOrders?.filter(
            (purchaseOrder) => purchaseOrder.locationID === this.locationID,
         );
         if (this.allowCreate) {
            this.startPOCred = this.credService.isAuthorized(
               this.locationID,
               this.credService.Permissions.StartAPO,
            );
         }
      } else {
         const locations = this.manageLocation.getLocations();
         const locationIDs: any = [];

         this.startPOCred = false;
         for (const location of locations) {
            //only show them POs for the locations they can receive at, they can manage POs at, or they can start POs at
            if (
               this.credService.isAuthorized(
                  location.locationID,
                  this.credService.Permissions.ManagePOs,
               ) ||
               this.credService.isAuthorized(
                  location.locationID,
                  this.credService.Permissions.StartAPO,
               ) ||
               this.credService.isAuthorized(
                  location.locationID,
                  this.credService.Permissions.ReceivePOItems,
               )
            ) {
               locationIDs.push(location.locationID);
            }

            if (
               this.credService.isAuthorized(
                  location.locationID,
                  this.credService.Permissions.StartAPO,
               )
            ) {
               //also set if they can create a PO
               this.startPOCred = true;
            }
         }
         if (this.credService.checkCredGlobal(this.credService.Permissions.StartAPO)) {
            this.startPOCred = true;
         }

         purchaseOrders = purchaseOrders?.filter((purchaseOrder) =>
            locationIDs.includes(purchaseOrder.locationID),
         );
      }

      if (this.showPOsWaitingToBeReceived == true) {
         //filter the list of POs to just ones that have items left to receive
         purchaseOrders = this.manageFilters.filterPurchaseOrdersToOnesReadyToReceive(
            purchaseOrders,
            this.managePO,
         );
      }

      const totalPOs = purchaseOrders.size;
      this.searchHints = new LimbleMap();

      if (this.searchAll !== undefined && this.searchAll.length > 1) {
         purchaseOrders = this.manageFilters.filterPurchaseOrdersToSearch(
            this.searchAll,
            purchaseOrders,
            this.searchHints,
            this.managePO,
            this.manageLocation,
            this.manageVendor,
            this.betterDate,
         );
      }

      purchaseOrders.orderBy("poNumber");

      this.noSearchResults = false;
      if (purchaseOrders?.size == 0 && totalPOs > 0) {
         this.noSearchResults = true;
      }

      this.purchaseOrders = purchaseOrders;
      for (const purchaseOrder of this.purchaseOrders) {
         this.purchaseOrderSeletedInfo.set(purchaseOrder.poID, {
            poID: purchaseOrder.poID,
            selected: false,
         });
      }

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(() => {
         this.canAddPOs = this.manageFeatureFlags.canAddPOs();
      });
   }

   protected focusPO(purchaseOrder: PurchaseOrder): void {
      this.errorMsg = "";
      const selectedPO = this.purchaseOrderSeletedInfo.get(purchaseOrder.poID);
      if (!selectedPO) return;
      if (this.selectOne == true) {
         if (selectedPO?.selected === true) {
            this.submit();
            return;
         }
      }
      selectedPO.selected = !selectedPO.selected;
      if (this.selectOne == true) {
         for (const selectedInfo of this.purchaseOrderSeletedInfo) {
            if (selectedInfo !== selectedPO) {
               selectedInfo.selected = false;
            }
         }
      }
   }

   protected submit(): void {
      const result: any = [];
      for (const purchaseOrder of this.purchaseOrderSeletedInfo) {
         //set back to default before returning the number

         if (purchaseOrder.selected == true) {
            const selectedPurchaseOrder = this.purchaseOrders.get(purchaseOrder.poID);
            result.push(selectedPurchaseOrder);
         }
      }

      if (result.length == 0) {
         this.alertService.addAlert(this.lang().WhoopsPleaseSelectAPO, "warning", 6000);
         return;
      }

      if (this.selectOne == true && this.popSelectionOnly == true) {
         this.popPoComponent(result[0].poID);
         return;
      }

      this.modalInstance.close(result);
   }

   popPoComponent = (poID) => {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   };

   protected createPO(): void {
      if (!this.canAddPOs) {
         return;
      }

      if (!this.startPOCred) {
         return;
      }

      const instance = this.modalService.open(PickLocationsModal);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().PickLocation,
            data: {
               selectOne: true,
               buttonText: this.lang().Select,
            },
         },
      };
      instance.result.then((locations) => {
         if (locations) {
            const locationID = locations[0].locationID;

            this.managePO
               .addPurchaseOrder(locationID, [], 0, 0, 0, "", "standard")
               .then((answer) => {
                  if (answer?.data.success == true) {
                     this.modalInstance.close(0);
                     this.popPoComponent(answer.data.po.poID);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
                  }
               });
         }
      });
   }
}
