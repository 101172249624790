<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title()" (closeModal)="close()" />
   <lim-ui-modal-body class="pick-user-or-profile-modal">
      <pick-user-or-profile
         [message]="message()"
         [locationID]="locationID()"
         [extraUsers]="extraUsers()"
         [defaultToDynamicAssignment]="defaultToDynamicAssignment()"
         [defaultUser]="defaultUser()"
         [defaultProfile]="defaultProfile()"
         (autoSubmit)="submit()"
         (selection)="selection.set($event)"
      />
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      successButtonText="{{ lang()?.Select }}"
      (successClick)="submit()"
      [dataLogSubmitLabel]="
         (isPhoneScreenSize$ | async)
            ? 'taskMobile-submitChangeAssigned'
            : 'task-submitChangeAssigned'
      "
   />
</lim-ui-modal>
