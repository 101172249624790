import { NgClass } from "@angular/common";
import {
   ChangeDetectionStrategy,
   Component,
   computed,
   forwardRef,
   inject,
   input,
   output,
   signal,
} from "@angular/core";
import { ClearFilterButtonComponent, FiltersWrapperComponent } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AssetFieldValueFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/asset-field-value-filter/asset-field-value-filter.component";
import { AssetFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/asset-filter/asset-filter.component";
import { AssignedToFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/assigned-to-filter/assigned-to-filter.component";
import { CompletedByFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/completed-by-filter/completed-by-filter.component";
import { CustomTagFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/custom-tag-filter/custom-tag-filter.component";
import { DateFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/date-filter/date-filter.component";
import { IdFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/id-filter/id-filter.component";
import { LocationFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/location-filter/location-filter.component";
import { PartLogTypeFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/part-log-type-filter/part-log-type-filter.component";
import { PostLogActionFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/post-log-action-filter/post-log-action-filter.component";
import { PriorityLevelFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/priority-level-filter/priority-level-filter.component";
import { TaskStatusFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/task-status-filter/task-status-filter.component";
import { TaskTypeFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/task-type-filter/task-type-filter.component";
import { UserFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/user-filter/user-filter.component";
import { WorkRequestStatusFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/work-request-status-filter/work-request-status-filter.component";
import type { DataViewerFilter } from "src/app/shared/data-viewer/data-viewer-filters/data-viewer-filter.models";

@Component({
   selector: "data-viewer-filters",
   imports: [
      FiltersWrapperComponent,
      ClearFilterButtonComponent,
      forwardRef(() => CustomTagFilterComponent),
      forwardRef(() => DateFilterComponent),
      forwardRef(() => CompletedByFilterComponent),
      forwardRef(() => AssignedToFilterComponent),
      forwardRef(() => TaskTypeFilterComponent),
      forwardRef(() => LocationFilterComponent),
      forwardRef(() => PartLogTypeFilterComponent),
      forwardRef(() => PriorityLevelFilterComponent),
      forwardRef(() => TaskStatusFilterComponent),
      forwardRef(() => UserFilterComponent),
      forwardRef(() => PostLogActionFilterComponent),
      NgClass,
      forwardRef(() => AssetFilterComponent),
      forwardRef(() => IdFilterComponent),
      forwardRef(() => AssetFieldValueFilterComponent),
      forwardRef(() => WorkRequestStatusFilterComponent),
   ],
   templateUrl: "./data-viewer-filters.component.html",
   styleUrls: ["./data-viewer-filters.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataViewerFiltersComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   filters = input.required<Array<DataViewerFilter>>();
   public noMargins = input<boolean>(false);
   public showActiveFilters = input<boolean>(true);

   readonly remove = output<DataViewerFilter>();
   readonly set = output<DataViewerFilter>();

   protected activeFilters = signal<Array<DataViewerFilter>>([]);

   public onRemoveFilter(filter: DataViewerFilter) {
      const activeFilters = this.activeFilters();

      this.activeFilters.set([
         ...activeFilters.filter((item) => item.key !== filter.key),
      ]);
      this.remove.emit(filter);
   }

   public onSetFilter(filter: DataViewerFilter) {
      const activeFilters = this.activeFilters();
      this.activeFilters.set([
         ...activeFilters.filter((item) => item.key !== filter.key),
         filter,
      ]);
      this.set.emit(filter);
   }
}
