<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()">
      <div [limbleHtml]="message"></div>
   </lim-ui-basic-modal-header>
   <lim-ui-modal-body>
      <lim-ui-panel class="content-wrapper">
         <div class="content-header">
            {{ lang().PleaseSelectATeamOrUser }}
            <lim-ui-search-box
               type="textbox"
               [(searchVal)]="searchTeamsAndUser"
               (searchValChange)="filterTeamsAndUsers(searchTeamsAndUser)"
               [placeholder]="lang().Search"
            />
         </div>
         <div class="config-container">
            <div class="config-column">
               <span class="config-column-label">{{ lang().Teams }} </span>
               @for (
                  profile of filteredTeams | orderBy: "profileDescription";
                  track profile
               ) {
                  <div
                     (click)="focusProfile(profile)"
                     class="lim-ui-list-item lim-ui-selectable-list-item"
                     [ngClass]="{ 'lim-ui-selected-list-item': profile.selected }"
                  >
                     <div class="lim-ui-list-item-content-group">
                        @if (profile.selected) {
                           <lim-ui-icon
                              icon="check"
                              iconSize="small"
                              iconColor="success"
                           />
                        }

                        <lim-ui-icon class="users-icon" icon="users" iconSize="small" />
                        <span [limbleHtml]="profile.profileDescription"></span>
                     </div>
                  </div>
               }
            </div>
            <div class="config-column">
               <span class="config-column-label">{{ lang().Users }} </span>
               @for (user of filteredUsers | orderBy: "userLastName"; track user) {
                  <div
                     (click)="focusUser(user)"
                     class="lim-ui-list-item lim-ui-selectable-list-item"
                     [ngClass]="{
                        'lim-ui-selected-list-item': user.selected,
                        'lim-ui-hinted-list-item': user.hint,
                     }"
                  >
                     <div class="lim-ui-list-item-content-group">
                        @if (user.selected) {
                           <lim-ui-icon
                              icon="check"
                              iconSize="small"
                              iconColor="success"
                           />
                        }

                        <user-image [user]="user" />
                        <span
                           [limbleHtml]="user.userFirstName + ' ' + user.userLastName"
                        ></span>
                     </div>
                  </div>
               }
            </div>
         </div>
         <div class="section-divider-container">
            <div class="label">
               {{ lang().or }}
            </div>
            <div class="divider"></div>
         </div>
         <div class="config-container">
            <div class="config-column">
               <span class="control-label">{{ lang().EnterEmailAddress }} </span>
               <input
                  type="text"
                  [(ngModel)]="email"
                  (click)="selectedUser = false"
                  limUiTooltip="{{ lang().AddUserToNotificationsToEmailHint }}"
               />
            </div>
         </div>
      </lim-ui-panel>
      @if (errorMsg) {
         <lim-ui-alert [title]="lang().Warning" alertType="danger">
            <div [limbleHtml]="errorMsg"></div>
         </lim-ui-alert>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Select }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
