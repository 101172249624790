import { z } from "zod";

export const timeScheduleSchema = z.object({
   type: z.literal("time"),
   units: z.union([
      z.literal("daily"),
      z.literal("weekly"),
      z.literal("monthly"),
      z.literal("yearly"),
   ]),
   interval: z.number(),
});

export const metricScheduleSchema = z.object({
   type: z.literal("metric"),
   units: z.string(),
   interval: z.number(),
   start_at: z.number(),
});

export const scheduleSchema = z.union([timeScheduleSchema, metricScheduleSchema]);

/** TODO(WRENCH-297): Make sure this is accurate */
export const pmSuggestionDtoSchema = z.object({
   predicted_maintenance_id: z.number(),
   asset_id: z.number(),
   customer_id: z.number(),
   name: z.string(),
   instructions: z.array(z.string()),
   document_id: z.string(),
   schedule: scheduleSchema,
});

export type PmSuggestionDto = z.infer<typeof pmSuggestionDtoSchema>;
export type ScheduleDto = z.infer<typeof scheduleSchema>;
export type TimeScheduleDto = z.infer<typeof timeScheduleSchema>;
export type MetricScheduleDto = z.infer<typeof metricScheduleSchema>;
