<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         @if (asset) {
            <div class="row">
               <div class="col-md-12">
                  <div class="form-horizontal row-border">
                     @if (asset.assetTrackHoursManually == 1) {
                        <div class="form-group">
                           <label class="col-sm-3"> </label>
                           <label class="col-sm-6">
                              <div class="listTable">
                                 @for (
                                    hour of manualHours
                                       | orderBy: "-start"
                                       | sliceArray
                                          : (page - 1) * itemsPerPage
                                          : page * itemsPerPage;
                                    track hour
                                 ) {
                                    <div class="listItem list-item-custom-styles">
                                       <div class="manual-hours-row">
                                          <span>
                                             {{ hour.start }} {{ lang().to }}
                                             {{ hour.end }}
                                          </span>

                                          <input
                                             type="number"
                                             min="0"
                                             class="form-control manual-hours-input"
                                             [(ngModel)]="hour.hours"
                                             (blur)="
                                                updateAssetTrackHoursManuallyData(
                                                   hour,
                                                   asset
                                                )
                                             "
                                             onclick="this.focus();"
                                          />

                                          <span>
                                             {{ lang().hours }}
                                          </span>
                                       </div>
                                    </div>
                                 }
                              </div>

                              @if (
                                 manualHours &&
                                 manualHours.length &&
                                 manualHours.length > itemsPerPage
                              ) {
                                 <div class="pagination-container">
                                    <lim-ui-pagination
                                       [maxSize]="5"
                                       [rotate]="true"
                                       [(page)]="page"
                                       [pageSize]="itemsPerPage"
                                       [collectionSize]="manualHours.length"
                                       (pageChange)="pageChanged()"
                                    />
                                 </div>
                              }
                           </label>
                        </div>
                     }
                  </div>
               </div>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
