import { Injectable, resource, type ResourceRef } from "@angular/core";
import type { AssetFieldValueFile } from "src/app/assets/types/field/value/file/file.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { PmSuggestionsAssetDocumentsInterface } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/asset-documents/asset-documents";

@Injectable({ providedIn: "root" })
export class AssetDocumentsFromMemoryService
   implements PmSuggestionsAssetDocumentsInterface
{
   public static readonly startingDocuments: LimbleMap<
      number,
      Array<AssetFieldValueFile>
   > = new LimbleMap([
      [
         1,
         [
            {
               fileID: 1,
               fileName: "document1.pdf",
               fileDescription: "Document 1",
               valueID: 1,
               migrated: 0,
               migratedFeedback: "",
            },
         ],
      ],
   ]);

   public readonly documents: ResourceRef<LimbleMap<number, Array<AssetFieldValueFile>>> =
      resource({
         request: () => ({}),
         loader: async () =>
            new Promise((resolve) => {
               setTimeout(() => {
                  resolve(AssetDocumentsFromMemoryService.startingDocuments);
               }, 0);
            }),
      });
}
