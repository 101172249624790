import { computed, inject, Injectable } from "@angular/core";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { ReadLaunchFlagService } from "src/app/shared/services/launch-flags/read/read-launch-flag.service";

@Injectable({ providedIn: "root" })
export class MultiCurrencyAvailabilityService {
   private readonly launchFlagService = inject(ReadLaunchFlagService);
   private readonly featureFlagService = inject(FeatureFlagService);

   public readonly isEnabled = computed(() => {
      const isReleased = this.isReleased();
      const hasFeature = this.hasFeature();
      if (isReleased === null || hasFeature === null) {
         return null;
      }
      return isReleased && hasFeature;
   });

   private readonly isReleased = this.launchFlagService.getFlag(
      "release-multi-currency-phase-1",
   );
   private readonly hasFeature = computed(
      () => this.featureFlagService.featureSet()?.has("multiCurrency") ?? null,
   );
}
