<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="lang().emailDefaultTitle" (closeModal)="close()">
      " (closeModal)="" >
   </lim-ui-basic-modal-header>
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="message">
            <span class="message-header">{{ lang().possiblePlaceholders }}</span>
            <span>
               <span ngNonBindable> {{vendor-email}} </span>
               - {{ lang().vendorEmailPlaceholderDefinition }}
            </span>
            <span>
               <span ngNonBindable> {{vendor-contact}} </span>
               - {{ lang().vendorContactPlaceholderDefinition }}
            </span>
         </div>
      </lim-ui-info-panel>

      <lim-ui-panel>
         <div class="email-section">
            <div class="subject-label">
               {{ lang().To }}
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  [limUiTooltip]="lang().MultipleEmailsWithSemicolonHint"
               />
               <div
                  class="email-template-input content-editable-input"
                  [(ngModel)]="emailRecips"
                  ngDefaultControl
                  limbleContentEditable
                  disableEditor="true"
                  stripHtml="true"
               ></div>
            </div>

            <div class="email-section subject-section">
               <div class="subject-label">{{ lang().Subject }}:</div>
               <div
                  class="email-template-input content-editable-input"
                  [(ngModel)]="emailSubject"
                  ngDefaultControl
                  limbleContentEditable
                  disableEditor="true"
                  stripHtml="true"
               ></div>
            </div>

            <div class="email-section message-section">
               <div class="subject-label">{{ lang().Message }}:</div>

               <div
                  class="email-template-input content-editable-input message-box"
                  [(ngModel)]="emailMessage"
                  ngDefaultControl
                  limbleContentEditable
                  stripHtml="false"
               ></div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Save"
      (successClick)="submit()"
   />
</lim-ui-modal>
