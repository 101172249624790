import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import type { OnInit } from "@angular/core";
import {
   Component,
   computed,
   EventEmitter,
   HostBinding,
   inject,
   Input,
   Output,
   ViewChild,
} from "@angular/core";
import type { Tab } from "@limblecmms/lim-ui";
import {
   EntityViewerModalComponent,
   IconButtonComponent,
   isMobile,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalService,
   ScrollContainerComponent,
   TabBarComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { from } from "rxjs";
import { ImageTile } from "src/app/files/components/imageTile/imageTile.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageTutorial } from "src/app/onboarding/services/tutorials/manageTutorial";
import { PartAssetsTabComponent } from "src/app/parts/components/part-modal/components/part-assets-tab/part-assets-tab.component";
import { PartInformationTabComponent } from "src/app/parts/components/part-modal/components/part-information-tab/part-information-tab.component";
import { PartLogTabLegacyComponent } from "src/app/parts/components/part-modal/components/part-log-tab/part-log-tab-legacy/part-log-tab-legacy.component";
import { PartLogTabComponent } from "src/app/parts/components/part-modal/components/part-log-tab/part-log-tab.component";
import { PartPurchaseOrdersTabComponent } from "src/app/parts/components/part-modal/components/part-purchase-order-tab/part-purchase-orders-tab.component";
import { PartReportsTabComponent } from "src/app/parts/components/part-modal/components/part-reports-tab/part-reports-tab.component";
import { PartVendorsTabComponent } from "src/app/parts/components/part-modal/components/part-vendors-tab/part-vendors-tab.component";
import { PartSettings } from "src/app/parts/components/partSettingsModal/partSettings.modal.component";
import { QrCodesViewPart } from "src/app/parts/components/qrCodesViewPartModal/qrCodesViewPart.modal.component";
import { PrintDivDirective } from "src/app/parts/directives/printDiv/printDiv.directive";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "part-modal",
   templateUrl: "./part-modal.component.html",
   styleUrls: ["./part-modal.component.scss"],
   imports: [
      EntityViewerModalComponent,
      LimbleHtmlDirective,
      ImageTile,
      MinimalIconButtonComponent,
      TooltipDirective,
      TabBarComponent,
      IconButtonComponent,
      PrintDivDirective,
      ScrollContainerComponent,
      NgTemplateOutlet,
      PartInformationTabComponent,
      PartVendorsTabComponent,
      PartAssetsTabComponent,
      PartLogTabComponent,
      PartPurchaseOrdersTabComponent,
      PartReportsTabComponent,
      AsyncPipe,
      PartLogTabLegacyComponent,
      IconButtonComponent,
   ],
})
export class PartModalComponent implements OnInit {
   @HostBinding("class") public readonly classes = "scroll-height-inheritance";
   @ViewChild(PartLogTabLegacyComponent) partLogLegacy!: PartLogTabLegacyComponent;
   @ViewChild(PartLogTabComponent) partLog!: PartLogTabComponent;
   @Input() public partID: number | undefined;
   @Input() public restrict;
   @Input() public searchFields;
   @Input() public showCloseModalButton: boolean = true;
   @Input() public scrollableContent: boolean = true;
   @Input() public dataLogOptions: DataLogEventDefinition | undefined;

   public part: Part | undefined;
   public selectedLocation;
   public location;
   public locationID;
   public currencySymbol;
   public loading;
   protected selectedTabId: string = "info";
   protected pageTabs: ReadonlyArray<Tab> = [];
   protected uploadObj;
   protected partImageObj;
   protected POCredentials: boolean = false;
   public fileUploaded;
   private readonly customerID: number;
   @Output() readonly closeModalEvent = new EventEmitter();

   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   private readonly launchFlags = inject(LegacyLaunchFlagsService);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly heapService = inject(HeapService);
   private readonly manageTutorial = inject(ManageTutorial);
   private readonly tutorialSection =
      this.manageTutorial.getTutorialSection("unitOfMeasure");

   protected readonly i18n = inject(TranslationService).i18n;
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected isUnitOfMeasureVisible = this.unitOfMeasureService.isFeatureEnabled;

   protected readonly useNewPartLogTab = from(
      this.launchFlags.isEnabled(Flags.JIT_CT_PART_LOGS),
   );

   public constructor() {
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.pageTabs = [
         {
            id: "info",
            title: this.lang().InfoTabHeading,
            icon: "circleInfoSolid",
            dataLogLabel: "manageParts-displayPartInfo",
         },
         {
            id: "purchaseOrders",
            title: this.lang().POs,
            icon: "filePowerpoint",
            isShown: this.POCredentials,
            dataLogLabel: "manageParts-displayPartPurchaseOrders",
         },
         {
            id: "vendors",
            title: this.lang().Vendors,
            icon: "addressCard",
            dataLogLabel: "manageParts-displayPartVendors",
         },
         {
            id: "assets",
            title: this.lang().Assets,
            icon: "cubes",
            dataLogLabel: "manageParts-displayPartAssets",
         },
         {
            id: "log",
            title: this.lang().Log,
            icon: "list",
            dataLogLabel: "manageParts-displayPartLogs",
         },
         {
            id: "reports",
            title: this.lang().Reports,
            icon: "chartColumn",
            dataLogLabel: "manageParts-displayPartReports",
         },
      ];
   }

   public ngOnInit(): void {
      if (this.partID === undefined) {
         throw new Error(
            "partTile component requires a `partID` input binding, but a value was not provided.",
         );
      }

      this.part = this.manageParts.getPart(this.partID);
      if (this.part === undefined) {
         throw new Error(
            "partTile component requires a `part`, but no part exists for the provided partID.",
         );
      }

      this.setViewCreds();

      this.selectedLocation = this.manageLocation.getSelectedLocation();
      this.location = this.manageLocation.getLocationsIndex()[this.part.locationID];

      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      this.createUploadObj();

      if (this.part.partImage) {
         this.setImage(this.part.partImage);
      }

      //this timeout is in place so that on mobile devices will pull completed data only when viewing an vendor.
      //brand new customers with 0 complete tasks will have this called multiple times, but that is ok
      //as new customers will get completed tasks very quickly
      //this will only be called on mobile views
      setTimeout(() => {
         if (!isMobile()) {
            return;
         }

         if (this.manageTask.getCompletedTasks("PartModalComponent").size < 1) {
            this.manageTask.fetchCompletedTasks();
         }
      }, 50);

      this.buildFromWatch();

      if (this.unitOfMeasureService.isFeatureEnabled() && this.pageTabs[1]) {
         this.pageTabs[1].title = this.lang().Purchasing;
         this.pageTabs[1].icon = "cartShoppingSolid";
      }
      this.loading = false;
   }

   // build function that was previously in a watch, now called in the onInit and in onChanges in case the part passed in (as a binding) were to change somehow
   public buildFromWatch(): void {
      if (!this.part) {
         return;
      }

      this.locationID = this.part.locationID;
   }

   public setViewCreds(): void {
      assert(this.part);
      this.POCredentials = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ManagePOs,
      );
      // Without explicitly setting the isShow property, the change detection wasn't capturing this change so the tab would never show
      const poTab = this.pageTabs.find((tab) => tab.id === "purchaseOrders");
      if (poTab) {
         poTab.isShown = this.POCredentials;
      }
   }

   public viewPartQRCode(part: Part): void {
      const instance = this.modalService.open(QrCodesViewPart);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PartQRCodesModalMsg,
            title: this.lang().PartQRCodes,
            part: part,
         },
      };
   }

   public settings(part: Part): void {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.ChangePartSettings,
         )
      ) {
         this.alertService.addAlert(this.lang().cred79Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PartSettings);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: `<b>${part.partName}</b> - ${this.lang().Settings}`,
            part: part,
            locationID: this.locationID,
         },
      };
   }

   public updateSelectedTab(tabID: string): void {
      this.selectedTabId = tabID;
   }

   protected createUploadObj(): void {
      assert(this.part);

      this.uploadObj = {};
      this.uploadObj.uploadTypes = "images"; //images / documents / importFiles (excel + csv) or empty for default (images and documents)
      //bindings have to be all lowercase...
      this.uploadObj.posturl = `phpscripts/managePart.php?action=makePartImage&locationID=${this.part.locationID}&partID=${this.part.partID}`;
      this.uploadObj.partID = this.part.partID;

      this.uploadObj.maxFiles = 1;

      this.uploadObj.viewOnly = !this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ChangePartImage,
      );

      this.uploadObj.deleteCall = async () => {
         return this.manageParts.deletePartImage(this.uploadObj.partID);
      };
      this.uploadObj.deleteSuccess = () => {
         assert(this.part);
         this.part.partImage = null;
         this.partImageObj = null;
         this.manageParts.updatePartFieldsObs();
      };
      this.uploadObj.uploadComplete = (data) => {
         if (data.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            this.fileUploaded = true;

            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );

            //keep this timeout to counter a bug on iPhone not updating.  This timeout doesn't cause efficency issues - Bryan.
            setTimeout(() => {
               assert(this.part);
               this.part.partImage = data.fileName;
               this.setImage(data.fileName);
               this.manageParts.updatePartFieldsObs();
            }, 100);

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         }
      };
   }

   protected setToDifferentTab($event) {
      this.selectedTabId = $event;
   }

   public setImage(fileName: string): void {
      assert(this.part);
      const image = {
         getURL: `viewFile.php?f=upload-${this.customerID}/parts/images/${this.part.partID}/${fileName}`,
         fileName: fileName,
      };
      this.partImageObj = image;
   }

   public emitCloseModal(): void {
      this.closeModalEvent.emit();
   }

   public async downloadPartHistory(): Promise<void> {
      await this.partLog.downloadPartHistory();
   }

   public downloadPartHistoryLegacy(): void {
      this.partLogLegacy.downloadPartHistory();
   }

   protected watchTutorial(tutorialName: "Purchasables"): void {
      this.heapService.trackEvent(
         "partModal_purchasing__purchasables_initiateWatchTutorial",
      );
      const videoTutorial = this.tutorialSection.getVideoTutorialByName(tutorialName);
      if (!videoTutorial) {
         return;
      }

      this.manageTutorial.watchVideoTutorial(videoTutorial);
   }
}
