import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DangerButtonComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
   manageDemo,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ImportGeneralLedgers } from "src/app/purchasing/pos/importGeneralLedgersModal/importGeneralLedgers.modal.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { decodeHTMLEntities } from "src/app/shared/utils/app.util";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "setup-general-ledger",
   templateUrl: "./setupGeneralLedger.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      ContenteditableDirective,
      FormsModule,
      IconComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      ModalFooterComponent,
      DangerButtonComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
   ],
})
export class SetupGeneralLedger implements OnInit {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public errorMsg;
   public name;
   public abbr;
   public glID;
   public CID;
   public locationID;
   public glDescription;
   public assetID;
   public assetName;

   private readonly modalService = inject(ModalService);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;
      this.title = data.title;
      this.message = data.message;
      this.errorMsg = "";

      this.name = data.name;
      this.abbr = data.abbr;
      this.glID = data.glID;
      this.glDescription = data.glDescription;
      this.assetID = data.assetID;

      if (this.assetID && this.assetID > 0) {
         this.assetName = this.manageAsset.getAsset(this.assetID)?.assetName ?? "";
      } else {
         this.assetName = "";
      }

      this.CID = data.CID;
      this.locationID = data.locationID || null;
   }

   checkCred = (cred) => {
      return this.credService.isAuthorized(this.locationID, cred);
   };

   protected deleteGeneralLedger(glID: number): void {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.DeleteGeneralLedger,
         )
      ) {
         this.alertService.addAlert(this.lang().cred155Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteGeneralLedgerMsg,
            title: this.lang().DeleteGeneralLedger,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.managePO.deleteGeneralLedger(glID).then((answer) => {
               if (answer?.data.success) {
                  this.close();
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
               }
            });
         }
      });
   }

   associateAssetToGL = () => {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.SetPOItemGeneralLedger,
         )
      ) {
         this.alertService.addAlert(this.lang().cred78Fail, "danger", 10000);
         return;
      }

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.lang().PickAsset;
      instance.title = this.lang().AssociateAnAssetWithThisGL;
      instance.singleLocation = 0;
      instance.selectOne = true;
      instance.restrictToCred = true;
      instance.iDontKnowOption = true;

      modalRef.result.then((asset: Asset | 0) => {
         if (asset != 0) {
            this.assetID = asset.assetID;
            this.assetName = asset.assetName;
         }
      });
   };

   importGeneralLedgers = () => {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.AddGeneralLedger,
         )
      ) {
         this.alertService.addAlert(this.lang().cred153Fail, "danger", 10000);
         return;
      }

      if (manageDemo.demo) {
         this.alertService.addAlert(
            this.lang().ImportingGeneralLedgersIsDisabledOnDemo,
            "danger",
            6000,
         );
         return;
      }
      const location = this.manageLocation.getLocation(this.locationID);

      const instance = this.modalService.open(ImportGeneralLedgers);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: `${this.lang().ImportAListOfGeneralLedgersInto} ${location?.locationName ?? ""}`,
            locationID: this.locationID,
            type: "import",
         },
      };
   };

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      this.errorMsg = "";

      this.name = decodeHTMLEntities(this.name);
      this.abbr = decodeHTMLEntities(this.abbr);
      if (this.abbr == "" || this.abbr.length > 4) {
         this.errorMsg = this.lang().NoAbbreviationGLError;
         return;
      }

      if (this.name == "") {
         this.errorMsg = this.lang().NoNameGLError;
         return;
      }
      const obj: any = {};
      obj.abbr = this.abbr;
      obj.name = this.name;
      obj.glDescription = this.glDescription;
      obj.assetID = this.assetID;
      this.modalInstance.close(obj);
   };
}
