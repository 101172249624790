import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";

@Pipe({
   name: "partUnitOfMeasure",
   standalone: true,
})
export class PartUnitOfMeasurePipe implements PipeTransform {
   private readonly manageParts = inject(ManageParts);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);

   public transform(partID: number | undefined, type: "short" | "singular") {
      if (partID === undefined) return "";
      const part = this.manageParts.getPart(partID);
      if (part === undefined) return "";

      const unit = this.unitOfMeasureService.getUnit(part.unitDescription)();
      if (unit === null)
         return ""; /** Race condition that relies on Default Change Detection strategy. */
      if (type === "singular") {
         return unit.singular();
      }
      return unit.short();
   }
}
