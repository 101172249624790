import { inject, Injectable } from "@angular/core";
import type { LoggedTimeViewModel } from "src/app/tasks/components/shared/components/logged-time-data-viewer/logged-time-data-viewer.model";
import { TaskLoggedTimeViewModelMapperService } from "src/app/tasks/components/shared/services/task-view-model-factory/mappers/task-logged-time-view-model-mapper.service";
import type { TaskLoggedTime } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";

@Injectable({ providedIn: "root" })
export class TaskLoggedTimeViewModelBuilderService {
   private readonly taskLoggedTimeViewModelMapperService = inject(
      TaskLoggedTimeViewModelMapperService,
   );

   public buildTaskLoggedTimeViewModel(
      taskLoggedTime: TaskLoggedTime,
   ): LoggedTimeViewModel {
      return {
         ...taskLoggedTime,
         userDisplayName:
            this.taskLoggedTimeViewModelMapperService.getUserDisplayName(taskLoggedTime),
         locationName:
            this.taskLoggedTimeViewModelMapperService.getLocationName(taskLoggedTime),
      };
   }
}
