import { computed, inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { BehaviorSubject, Subject, filter, first } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { RefreshService } from "src/app/shared/services/refresh.service";

@Injectable({ providedIn: "root" })
export class DashboardDataService {
   private readonly dataInitializedSubject = new BehaviorSubject<boolean>(false);
   /**
    * A map to hold Subjects for each widgetID. It maps a widgetID to its corresponding
    * Subject that streams an array of taskIDs.
    */
   private readonly widgetTaskIDsSources: Map<number, Subject<Array<number>>> = new Map();
   private readonly manageLang = inject(ManageLang);
   private readonly refreshService = inject(RefreshService);
   private readonly alertService = inject(AlertService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.refreshService.dataInitialized().subscribe(() => {
         this.dataInitializedSubject.next(true);
      });
   }

   public async waitForDataInitialization(): Promise<void> {
      const openingModalAlert = this.alertService.addAlert(
         this.lang().SyncingYourData,
         "warning",
         Infinity,
      );

      await this.dataInitializedSubject
         .pipe(
            filter((initialized) => initialized),
            first(),
         )
         .toPromise();

      this.alertService.closeAlert(openingModalAlert);
   }

   /**
    * Updates or sets the taskIDs for a given widgetID.
    * @param widgetID The ID of the widget.
    * @param taskIDs An array of task IDs to be associated with the widget.
    */
   public updateWidgetTaskIDs(widgetID: number, taskIDs: Array<number>): void {
      const subject = this.getWidgetTaskIDsSubject(widgetID);
      subject.next(taskIDs);
   }

   /**
    * Returns an observable for the taskIDs of a specific widgetID.
    * @param widgetID The ID of the widget.
    * @returns An Observable streaming the taskIDs array.
    */
   public widgetTaskIDs$(widgetID: number): Observable<Array<number>> {
      return this.getWidgetTaskIDsSubject(widgetID).asObservable();
   }

   /**
    * Retrieves or initializes the Subject for a given widgetID.
    * @param widgetID The ID of the widget.
    * @returns The Subject associated with the widgetID.
    */
   private getWidgetTaskIDsSubject(widgetID: number): Subject<Array<number>> {
      let subject = this.widgetTaskIDsSources.get(widgetID);
      if (!subject) {
         subject = new Subject<Array<number>>();
         this.widgetTaskIDsSources.set(widgetID, subject);
      }
      return subject;
   }
}
