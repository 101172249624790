import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { VendorField } from "src/app/vendors/types/field/field.types";

@Component({
   selector: "pick-vendor-field",
   templateUrl: "./pickVendorField.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      NgClass,
      IconComponent,
      SecondaryButtonComponent,
      BasicModalFooterComponent,
   ],
})
export class PickVendorField implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public data;
   public fields: Lookup<"fieldID", Partial<VendorField>> = new Lookup("fieldID");
   public selectedField: number | undefined;
   public breakLink;

   private readonly manageVendor = inject(ManageVendor);
   private readonly manageLocation = inject(ManageLocation);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      const locationIDs = this.manageLocation
         .getLocations()
         .map((location) => location.locationID);

      this.fields = this.manageVendor
         .getFields()
         .filter(
            (field) =>
               field.fieldTypeID != 4 &&
               field.fieldTypeID != 3 &&
               locationIDs.includes(field.locationID),
         );

      this.fields.set(-4, {
         fieldName: this.lang().Address,
         fieldID: -4,
         fieldTypeID: 1,
      });
      this.fields.set(-3, {
         fieldName: this.lang().Contact,
         fieldID: -3,
         fieldTypeID: 1,
      });
      this.fields.set(-2, {
         fieldName: this.lang().Phone,
         fieldID: -2,
         fieldTypeID: 1,
      });
      this.fields.set(-1, {
         fieldName: this.lang().Email,
         fieldID: -1,
         fieldTypeID: 1,
      });
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.data = this.resolve.data;
      this.breakLink = this.data.breakLink || false;

      if (this.data.type === "number") {
         this.fields = this.fields.filter(
            (field) => field.fieldTypeID == 5 || field.fieldTypeID == 6,
         );
         if (this.fields.size === 0) {
            this.alertService.addAlert(
               this.lang().PleaseHaveAVendorFieldOfTheTypeNumber,
               "warning",
               6000,
            );
         }
      }

      this.fields = this.fields.orderBy("fieldName");

      if (this.data.startingField) {
         this.selectedField = this.data.startingField;
      } else {
         this.selectedField = undefined;
      }
   }

   focusField = (fieldID: number) => {
      if (this.selectedField === fieldID) {
         this.submit();
      }
      this.selectedField = fieldID;
   };

   close = () => {
      this.modalInstance.close(0);
   };

   breakLinkFunc = () => {
      this.modalInstance.close("break");
   };

   submit = () => {
      if (this.selectedField === undefined) {
         this.alertService.addAlert(
            this.lang().WhoopsPleaseSelectAField,
            "warning",
            6000,
         );
         return;
      }
      this.modalInstance.close(this.selectedField);
   };
}
