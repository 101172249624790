import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   RadioButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { EditVendorField } from "src/app/vendors/components/editVendorFieldModal/editVendorField.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { VendorField } from "src/app/vendors/types/field/field.types";
import type { VendorFieldType } from "src/app/vendors/types/field/type/vendor-field-type.types";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "add-vendor-field",
   templateUrl: "./addVendorField.modal.component.html",
   styleUrls: ["./addVendorField.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      RadioButtonComponent,
      SearchBoxComponent,
      NoSearchResults,
      NgClass,
      IconComponent,
      TooltipDirective,
      MinimalIconButtonComponent,
      FormsModule,
      BasicModalFooterComponent,
      IconAlias,
   ],
})
export class AddVendorField implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public selectedField: number | undefined;
   public selectedType;
   public errorMsg;
   public message;
   public title;
   public credConfigureNewFields;
   protected credCreateAndDeleteVendorFields: boolean = false;
   public currencySymbol;
   public searchFields;
   public showNew;
   public showSuggested;
   public newName;
   public nameErr;
   public userClicks;
   public vendor: Vendor | undefined;
   public types: Lookup<"fieldTypeID", VendorFieldType> = new Lookup("fieldTypeID");
   public fields: Lookup<"fieldID", VendorField> = new Lookup("fieldID");
   public fieldDisplayInfo: Map<
      number,
      { greyOut: boolean; selected: boolean; fieldNameTemp: string }
   > = new Map();
   public allFields: Array<VendorField> = [];
   public fieldIDsOnThisVendor: Array<number> = [];
   public selectedFields: Lookup<"fieldID", VendorField> = new Lookup("fieldID");
   public vendorFieldsSub: Subscription | null;

   private readonly modalService = inject(ModalService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.vendorFieldsSub = this.manageObservables.setSubscription(
         "vendorFields",
         () => {
            if (!this.vendor) {
               return;
            }
            this.buildFields();
         },
      );
   }

   public ngOnInit() {
      this.userClicks = 0;
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.selectedField = 0;
      this.selectedType = 0;

      this.errorMsg = "";
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.vendor = this.resolve.vendor;
      assert(this.vendor);
      this.credConfigureNewFields = this.credService.isAuthorized(
         this.vendor?.locationID,
         this.credService.Permissions.ConfigureVendorInformationFields,
      );

      this.credCreateAndDeleteVendorFields = this.credService.isAuthorized(
         this.vendor?.locationID,
         this.credService.Permissions.CreateAndDeleteVendorFields,
      );

      this.types = this.manageVendor.getFieldTypes().orderBy("fieldTypeOrder");

      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }

      if (this.credConfigureNewFields == false) {
         this.message = this.lang().PleaseSelectFromTheListOfSuggestedFields;
      }

      this.buildFields();
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.vendorFieldsSub);
   }

   private buildFields() {
      assert(this.vendor);
      this.allFields = Array.from(
         this.manageVendor
            .getFields()
            .filter((field) => field.locationID === this.vendor?.locationID),
      );
      this.allFields = orderBy(this.allFields, "fieldName");

      this.fields = new Lookup("fieldID", this.allFields);

      for (const valueID of this.vendor.vendorValueIDs) {
         const value = this.manageVendor.getValue(valueID);
         assert(value);
         this.fieldIDsOnThisVendor.push(value.fieldID);
      }

      for (const field of this.fields) {
         this.fieldDisplayInfo.set(field.fieldID, {
            greyOut: false,
            selected: false,
            fieldNameTemp: field.fieldName,
         });
      }

      for (const fieldID of this.fieldIDsOnThisVendor) {
         const displayInfo = this.fieldDisplayInfo.get(fieldID);
         assert(displayInfo);
         displayInfo.greyOut = true;
      }

      if (this.fields.size == 0 && this.credCreateAndDeleteVendorFields) {
         this.showSuggested = false;
         this.showNew = true;
      } else {
         this.showSuggested = true;
         this.showNew = false;
      }
   }

   filterFields = () => {
      const fields = this.allFields.filter((field) =>
         field.fieldName.toLowerCase().includes(this.searchFields.toLowerCase()),
      );
      this.fields = new Lookup("fieldID", fields);
   };

   close = () => {
      const data: any = {};
      data.view = "updated";
      data.fields = this.fields;
      this.modalInstance.close(data);
   };

   showFieldOptions = (field) => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ConfigureVendorInformationFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred132Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(EditVendorField);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            field: field,
         },
      };
   };

   focusField = (fieldToFocus: VendorField) => {
      const displayInfo = this.fieldDisplayInfo.get(fieldToFocus.fieldID);
      assert(displayInfo);
      if (displayInfo.selected == true) {
         if (this.userClicks <= 1) {
            //only one is selected so they meant to double tap it to select
            this.submit();
            return;
         }
      }
      //if it is greyed out they shouldn't be able to click it
      if (displayInfo.greyOut) {
         return;
      }

      if (this.selectedFields.has(fieldToFocus.fieldID)) {
         this.selectedFields.delete(fieldToFocus.fieldID);
         displayInfo.selected = false;
      } else {
         this.selectedFields.set(fieldToFocus.fieldID, fieldToFocus);
         displayInfo.selected = true;
      }

      this.userClicks++;
      this.checkResetUserClicks();
      this.errorMsg = "";
   };

   checkResetUserClicks = () => {
      this.userClicks = this.manageUtil.checkResetUserClicks(
         Array.from(this.fieldDisplayInfo.values()),
         this.userClicks,
      );
   };

   focusType = (type) => {
      this.clearSelected();
      this.selectedType = type.fieldTypeID;
   };

   clearSelected = () => {
      this.selectedField = 0;
      this.selectedType = 0;
   };

   submit = () => {
      const data: any = {};

      if (this.showNew) {
         data.view = "new";

         for (const type of this.types) {
            if (type.fieldTypeID == this.selectedType) {
               data.type = type;
            }
         }

         data.name = this.newName;

         const options: any = [];
         if (this.selectedType == 7) {
            //have at least one option...
            options.push({ order: 1, name: "" });
         }

         data.options = JSON.stringify(options);

         let valid = true;
         this.errorMsg = "";
         this.nameErr = false;

         if (data.type === undefined) {
            this.errorMsg += `${this.lang().PleaseSelectAFieldType}.  <br />`;
            this.alertService.addAlert(this.errorMsg, "warning", 6000);
            valid = false;
         }

         if (data.name === undefined || data.name == "") {
            this.errorMsg += `${this.lang().PleaseSelectAFieldName}.  <br />`;
            this.nameErr = true;
            this.alertService.addAlert(this.errorMsg, "warning", 6000);
            valid = false;
         }

         if (this.manageVendor.usingForbiddenFieldNames(data.name)) {
            this.errorMsg += `${this.lang().ThisNameIsReserved}.  <br />`;
            this.nameErr = true;
            this.alertService.addAlert(this.errorMsg, "warning", 6000);
            valid = false;
         }

         for (const field of this.fields) {
            if (
               this.newName !== undefined &&
               field.fieldName.toLowerCase() == this.newName.toLowerCase()
            ) {
               this.errorMsg += `${this.lang().addVendorFieldErrorMsgNameTaken}  <br />`;
               this.selectedField = field.fieldID;
               this.alertService.addAlert(this.errorMsg, "warning", 6000);
               valid = false;
            }
         }

         if (valid) {
            this.modalInstance.close(data);
         }
      }

      if (this.showSuggested) {
         data.view = "suggested";
         data.field = this.selectedFields.keys();

         this.errorMsg = "";

         if (data.field.length == 0) {
            this.errorMsg = this.lang().PleaseSelectASuggestedField;
         } else {
            this.modalInstance.close(data);
         }
      }
   };

   protected startConfiguringNewFields(): void {
      if (!this.credConfigureNewFields) {
         this.alertService.addAlert(this.lang().cred132Fail, "danger", 10000);
         return;
      }
      this.showSuggested = true;
      this.showNew = false;
      this.errorMsg = "";
   }

   protected startAddingNewFields(): void {
      if (!this.credCreateAndDeleteVendorFields) {
         this.alertService.addAlert(this.lang().cred134Fail, "danger", 10000);
         return;
      }
      this.showSuggested = false;
      this.showNew = true;
      this.errorMsg = "";
   }
}
