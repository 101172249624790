import {
   Component,
   computed,
   forwardRef,
   inject,
   type OnDestroy,
   type OnInit,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownComponent,
   DropdownDateRangePickerComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   FilterInputComponent,
   FilterInputWrapperComponent,
   FiltersWrapperComponent,
   IconButtonComponent,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import type { Moment } from "moment";
import moment from "moment";
import { BehaviorSubject, combineLatest, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { WidgetModalType } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import { DashboardDataService } from "src/app/dashboards/custom-dashboards/dashboardDataService.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { CustomTagList } from "src/app/tasks/components/customTagListModal/customTagList.element.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ViewListOfTasks } from "src/app/tasks/components/viewListOfTasksElement/viewListOfTasks.element.component";
import { BulkTasks } from "src/app/tasks/services/bulkTasks";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { SetupWorkOrderService } from "src/app/tasks/services/setup-work-order.service";
import { SharedData } from "src/app/tasks/services/sharedData";
import { StartWOService } from "src/app/tasks/services/start-wo.service";
import type { Task, TaskLookup } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

export interface CycleCountTaskFilter {
   key?: string;
   searchValue?: string;
   displayName: string;
   inputType: string;
   beginDate?: undefined | Date;
   endDate?: undefined | Date;
   dropdownOptions?: Array<string> | [];
   name: string | undefined;
}

@Component({
   selector: "view-task-list",
   templateUrl: "./viewTaskList.modal.component.html",
   styleUrls: ["./viewTaskList.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SearchBoxComponent,
      DropdownComponent,
      IconButtonComponent,
      TooltipDirective,
      DropdownTextItemComponent,
      PrimaryButtonComponent,
      FiltersWrapperComponent,
      DropdownButtonComponent,
      DropdownDividerComponent,
      DropdownClearFilterItemComponent,
      forwardRef(() => CustomTagList),
      FilterInputWrapperComponent,
      FilterInputComponent,
      FormsModule,
      DropdownDateRangePickerComponent,
      ViewListOfTasks,
      LoadingAnimationComponent,
      UpsellPopover,
   ],
})
export class ViewTaskList implements OnInit, OnDestroy {
   public message;
   public title;
   public locationID;
   public loadingBar;
   public sortBind;
   public tagFilter;
   public customTagListObj;
   public typeFilterNumber;
   public itemsPerPage;
   public completedData;
   public location;
   public tasks: TaskLookup = new Lookup("checklistID");
   public searchBar;
   public noSearchResults;
   public typeFilter;
   public typeFilterName;
   public showLoading;
   public resolve;
   public modalInstance;
   public data;
   public initialTasks: Array<Task> = [];
   public filterTimeout;
   public locationWatchVarSub;
   public completedTasksWatchVarSub;
   public alertLinkClickSub$;
   public exportTasksCred;
   public shareTasksCred;
   public taskColumns;
   public filterColumns: Array<{
      key: string;
      inputType: string;
      dropdownOptions?: Array<string> | [];
      displayName: string;
      beginDate?: Date | undefined;
      endDate?: Date | undefined;
   }> = [];
   public modalType: WidgetModalType | undefined; // determines which columns to show, and if no tasks are passed in, then determines how to calculate the tasks
   public spreadSheetColumns;
   public loaded;
   public users;
   public profiles;
   public locations;
   public customerID;
   public tasksWatchVarSub;
   public openTasksSub;
   private openTasksObs: `viewListOfOpenTasksModalObs${number}` | undefined;
   public searchFields: Array<CycleCountTaskFilter> = [];
   public obsID;

   /** @beta plaid-dashboards */
   public openTasksCDBSub;
   public exportDropdownToggle: boolean = false;
   public selectedCycleCountTaskFilterType: CycleCountTaskFilter | null = null;
   public initialTaskIDs: Array<number> = [];
   public taskIDs: Array<number> = [];
   public searchHints: LimbleMap<number, string> = new LimbleMap();
   public tasksExtraInfo: LimbleMap<
      number,
      {
         assignment: string;
         locationName: string;
         selected: boolean;
         taskData: Array<{
            checklistID: number;
            mode: string;
            editable: boolean;
            expand: boolean;
         }>;
         priorityName?: string;
         statusName?: string;
         completedByStr?: string;
         displayName?: string;
      }
   > = new LimbleMap();
   protected dashboardReportingLimit: number | undefined;

   private readonly searchedTextSubject = new BehaviorSubject<string>("");
   private readonly searchedText$ = this.searchedTextSubject.asObservable();

   /**
    * The ID of the widget that opened this modal
    * This is used in modals opened from a widget that needs to show tasks
    * and it is passed while using the ModalService
    */
   private readonly widgetIdSubject = new BehaviorSubject<number | undefined>(undefined);
   private readonly widgetId$ = this.widgetIdSubject.asObservable();

   // NOTE: This is how are going to be requesting tasks from the API
   // At the moment is not hooked up, but it should serve as an initial
   // point to add the other filters
   private readonly tasks$ = combineLatest([this.widgetId$, this.searchedText$]).pipe(
      filter(([widgetID]) => widgetID !== undefined),
      tap(([widgetID, searchedText]) => {
         const currentSearch = {
            widgetID,
            searchedText,
         };
         // eslint-disable-next-line no-console -- this is temporary
         console.log(currentSearch);
      }),
   );

   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageUtil = inject(ManageUtil);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageProfile = inject(ManageProfile);
   private readonly alertService = inject(AlertService);
   private readonly sharedData = inject(SharedData);
   public readonly bulkTasks = inject(BulkTasks);
   private readonly credService = inject(CredService);
   private readonly manageParts = inject(ManageParts);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageStatus = inject(ManageStatus);
   private readonly dashboardDataService = inject(DashboardDataService);
   private readonly startWOService = inject(StartWOService);
   public setupWorkOrderService = inject(SetupWorkOrderService);
   private readonly manageLang = inject(ManageLang);
   private readonly refreshService = inject(RefreshService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      if (this.resolve?.dashboardReportingLimit) {
         this.dashboardReportingLimit = this.resolve.dashboardReportingLimit;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.locationID = this.resolve.locationID;
      this.loadingBar = false;
      this.sortBind = this.resolve.sortBind;
      this.data = this.resolve.data;
      this.initialTaskIDs = this.resolve.taskIDs;
      if (this.initialTaskIDs !== undefined) {
         for (const taskID of this.initialTaskIDs) {
            const task = this.manageTask.getTaskLocalLookup(taskID);
            if (task) this.initialTasks.push(task);
         }
      }

      this.initialTasks.forEach((task) => {
         this.tasksExtraInfo.set(task.checklistID, {
            assignment: "",
            locationName: "",
            selected: false,
            taskData: [],
         });
      });
      this.tagFilter = false;
      this.customTagListObj = {};
      this.modalType = this.resolve.modalType;
      this.users = this.manageUser.getFlatUsersIndex();
      this.profiles = this.manageUser.getProfilesIndex();
      this.locations = this.manageLocation.getLocationsIndex();
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.obsID = this.resolve.obsID;
      this.widgetIdSubject.next(this.resolve.widgetID);

      this.openTasksObs = `viewListOfOpenTasksModalObs${this.obsID}`;

      this.typeFilterNumber = false;

      this.itemsPerPage =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage || 10;

      this.exportTasksCred = this.credService.checkCredAnywhere(
         this.credService.Permissions.ExportTasks,
      );
      this.shareTasksCred = this.credService.checkCredAnywhere(
         this.credService.Permissions.PrintOrShareTasks,
      );

      this.completedData = {};
      this.completedData.assetViewing = this.resolve.viewingAsset;

      this.locationWatchVarSub = this.manageObservables.setSubscription(
         "locationWatchVar",
         () => {
            this.location = this.manageLocation.getLocation(this.locationID);
         },
      );

      this.tasksWatchVarSub = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.buildTasks();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );

      this.openTasksSub = this.manageObservables.createAndSubscribe(
         this.openTasksObs,
         (val: TaskLookup) => {
            this.initialTasks = Array.from(val.values());
            this.buildTasks();
         },
         this.initialTasks,
      );

      /** @beta plaid-dashboards */
      this.openTasksCDBSub = this.dashboardDataService
         .widgetTaskIDs$(this.resolve.widgetID)
         .subscribe((taskIDs) => {
            if (taskIDs.length > 0) {
               this.initialTaskIDs = taskIDs;
               const fromTaskIDs = true;
               this.buildTasks(fromTaskIDs);
            }
         });

      this.completedTasksWatchVarSub = this.manageObservables.setSubscription(
         "completedTasksWatchVar",
         () => {
            this.buildTasks();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );

      this.setCustomTagListObj(this.customTagListObj);
      this.subscribeToAlertLinkClick();
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.locationWatchVarSub,
         this.completedTasksWatchVarSub,
         this.tasksWatchVarSub,
         this.alertLinkClickSub$,
      ]);
      if (this.openTasksObs !== undefined) {
         this.manageObservables.unsubscribeAndDelete(
            this.openTasksObs,
            this.openTasksSub,
         );
      }
      this.openTasksCDBSub?.unsubscribe();

      this.sharedData.openTaskDataType = null;
      this.sharedData.openTaskModalObsID = null;
   }

   private buildDropdownUsers(
      dropdownKeyString: "completedByStr" | "displayName",
   ): Array<string> | [] {
      const userNames: Array<string> = [this.lang().LastUserWhoEnteredMeterReading];

      for (const task of this.tasks) {
         const extraInfo = this.tasksExtraInfo.get(task.checklistID);
         if (extraInfo === undefined) continue;
         const user = extraInfo[dropdownKeyString];
         if (!user) {
            continue;
         }
         const splitUsers = user.split(",");
         for (const splitUser of splitUsers) {
            const userName = splitUser.trim();
            if (userNames.some((templateUser) => templateUser === userName)) {
               continue;
            }
            userNames.push(userName);
         }
      }

      return userNames;
   }

   //if tasks are specifically passed in then we don't set a watch variable.
   //This happens when we call this function from a graph and it has to send in a list of tasks

   public buildTasks(fromTaskIDs = false): void {
      switch (this.modalType) {
         case "tasksByCost":
            this.setTasksByCostColumns();
            break;

         case "tasksByDowntime":
            this.setTasksByDowntimeColumns();
            break;

         case "tasksByStatus":
            this.setTasksByStatusColumns();
            break;

         case "tasksByType":
            this.setTasksByTypeColumns();
            break;

         case "completedTasks":
            this.setCompletedTasksColumns();
            break;

         case "openTasks":
            this.setOpenTasksColumns();
            break;

         case "anyTasks":
            this.setAnyTasksColumns();
            break;

         case "cycleCountTasks":
            this.setTasksColumnsFilters();
            break;

         case "tasksByStatusDuration":
            this.setTasksByStatusDurationColumns();
            break;

         default:
            break;
      }

      if (this.initialTaskIDs === undefined) {
         // No tasks were passed in, so calculate those based on the data passed in
         switch (this.modalType) {
            case "tasksByCost":
               this.setTasksByCostData();
               break;

            case "tasksByDowntime":
               this.setTasksByDowntimeData();
               break;

            case "tasksByStatus":
               this.setTasksByStatusData();
               break;

            case "tasksByType":
               this.setTasksByTypeData();
               break;

            case "completedTasks":
               this.setCompletedTasksData();
               break;

            case "openTasks":
               this.setOpenTasksData();
               break;
            case "anyTasks":
               this.setAnyTasksData();
               break;
            case "cycleCountTasks":
               this.setCycleCountTasksData();
               break;

            default:
               break;
         }
      } else {
         //tasks were passed in
         let tempTasks = this.initialTasks;
         if (this.typeFilterNumber) {
            const tasksAsLookup = new Lookup("checklistID", tempTasks);
            const filteredTasksAsLookup =
               this.manageFilters.filterTasksByChecklistTemplateOld(tasksAsLookup, [
                  this.typeFilterNumber,
               ]);
            tempTasks = [...filteredTasksAsLookup.values()];
         }
         if (this.tagFilter) {
            const tasksAsLookup = new Lookup("checklistID", tempTasks);
            const filteredTasksAsLookup = this.manageFilters.filterTasksToCustomTags(
               tasksAsLookup,
               {
                  tag: this.tagFilter,
               },
            );
            tempTasks = [...filteredTasksAsLookup.values()];
         }
         this.taskIDs = [];
         this.tasks = new Lookup("checklistID");
         const isSearching = this.searchBar && this.searchBar.length > 0;
         if (fromTaskIDs && !isSearching) {
            this.taskIDs = this.initialTaskIDs;
         } else {
            tempTasks.forEach((item) => {
               this.tasks.set(item.checklistID, item);
               this.taskIDs.push(item.checklistID);
            });
         }

         if (isSearching) {
            const filterResponse = this.manageFilters.filterTasksToSearch(
               this.tasks,
               this.searchBar,
               this.manageTask,
               this.manageLocation,
               this.manageUser,
               this.manageUtil,
               this.manageAsset,
               this.manageInvoice,
               this.manageParts,
               this.manageSchedule,
            );
            this.tasks = filterResponse.tasks;
            this.searchHints = filterResponse.searchHints;
            this.taskIDs = Array.from(this.tasks).map((task) => task.checklistID);
         }

         this.noSearchResults = false;
         if (this.tasks.size === 0 && this.taskIDs.length === 0) {
            this.noSearchResults = true;
         }
      }
   }

   // START OF BUILDING DATA FUNCTIONS BASED ON MODALTYPE

   // tasksByCost modalType
   public setTasksByCostColumns(): void {
      // Set columns to be displayed in list view
      this.taskColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 4,
         },
         {
            key: "checklistTotalPartsCost",
            displayName: this.lang().Parts,
            sortBy: "checklistTotalPartsCost",
            columnWidth: 2,
         },
         {
            key: "checklistPromptTimeTotalWithLaborCost",
            displayName: this.lang().Labor,
            sortBy: "checklistPromptTimeTotal",
            columnWidth: 2,
         },
         {
            key: "checklistTotalInvoiceCost",
            displayName: this.lang().Invoices,
            sortBy: "checklistTotalInvoiceCost",
            columnWidth: 2,
         },
         {
            key: "checklistTotalOperatingCost",
            displayName: this.lang().Total,
            sortBy: "checklistTotalOperatingCost",
            columnWidth: 2,
         },
         {
            key: "checklistLastEdited",
            displayName: this.lang().LatestActivity,
            sortBy: "checklistLastEdited",
            columnWidth: 2,
         },
      ];

      // Set columns to show when tasks are exported for Excel
      this.spreadSheetColumns = {
         checklistTotalPartsCost: true,
         checklistTotalLaborCost: true,
         checklistTotalInvoiceCost: true,
         checklistTotalOperatingCost: true,
      };
   }

   public setTasksByCostData(): void {
      // No tasks passed in, so calculate the tasks based on the data that was passed in
      let completedTasks = this.manageTask
         .getCompletedTasks("ViewTaskList")
         .filter((task) => {
            if (task.checklistTemplate !== 0) {
               return false; //only instances
            }
            if (task.checklistStatusID !== 1) {
               return false; //only completed
            }
            if (
               this.data.locationID > 0 &&
               task.locationID !== Number(this.data.locationID)
            ) {
               return false; //specific location
            }
            return true;
         });

      if (this.data.assetID > 0) {
         //if assetID is set filter to a specific asset
         //first we need to see if this asset has children.  If it does then we need to filter to the parent/child group of assets
         const asset = this.manageAsset.getAsset(this.data.assetID);
         if (asset?.includeChildData == 1) {
            const childrenIDs = this.manageAsset.findChildrenIDs(asset, []);
            if (childrenIDs.length > 0) {
               const tempAssetIDs: any = [];
               for (const child of childrenIDs) {
                  tempAssetIDs.push(child);
               }
               tempAssetIDs.push(this.data.assetID);
               completedTasks = this.manageFilters.filterTasksByAssetIDs(
                  completedTasks,
                  tempAssetIDs,
               );
            } else {
               completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
                  this.data.assetID,
               ]);
            }
         } else {
            completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
               this.data.assetID,
            ]);
         }
      }

      if (this.data.dateRange1 && this.data.dateRange2) {
         const date1 = moment(this.data.dateRange1, "yyyy-mm-dd").startOf("day").toDate();
         const date2 = moment(this.data.dateRange2, "yyyy-mm-dd").endOf("day").toDate();
         const start = Math.round(date1.getTime() / 1000);
         const end = Math.round(date2.getTime() / 1000);
         completedTasks = this.manageFilters.filterTasksByCompletedDate(completedTasks, {
            start: start,
            end: end,
         });
      }

      if (this.data.has === "all") {
         //every task has some form of cost to do
      } else if (this.data.has === "labor") {
         //every task has some form of labor cost so do nothing
      } else if (this.data.has === "parts") {
         let tempTasks: any = [];
         for (const task of completedTasks) {
            if (task.partRelationIDs.length > 0) {
               tempTasks.push(task);
            }
         }
         // Filter out any tasks that have parts that cost $0.00
         tempTasks = tempTasks.filter((taskItem) => taskItem.checklistTotalPartsCost > 0);
         completedTasks = tempTasks;
      } else if (this.data.has === "invoices") {
         let tempTasks: any = [];
         for (const task of completedTasks) {
            if (task.invoiceIDs.length > 0) {
               tempTasks.push(task);
            }
         }
         // Filter out any tasks that have an invoice cost of $0.00
         tempTasks = tempTasks.filter(
            (taskItem) => taskItem.checklistTotalInvoiceCost > 0,
         );
         completedTasks = tempTasks;
      }

      if (this.data.xDays > 0) {
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            this.data.xDays,
         );
      }

      if (this.data.checklistTemplateOld !== undefined) {
         //if checklistTemplateOldID is set then filter to those types.
         completedTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            completedTasks,
            this.data.checklistTemplateOld,
         );
      }

      if (
         this.data.checklistBatchID !== undefined &&
         this.data.checklistBatchID !== false
      ) {
         //if checklistBatchID is set then we need to filter to batchIDs
         completedTasks = this.manageFilters.filterTasksByChecklistBatchID(
            completedTasks,
            this.data.checklistBatchID,
         );
      }

      if (this.data.assets) {
         //get all of the assets and their children as well.
         const tempAssetIDs2 = {};
         for (const asset of this.data.assets) {
            const lookupAsset = this.manageAsset.getAsset(asset.assetID);
            assert(lookupAsset);
            tempAssetIDs2[asset.assetID] = asset.assetID;
            if (lookupAsset.includeChildData == 1) {
               const childrenIDs = this.manageAsset.findChildrenIDs(lookupAsset, []);
               if (childrenIDs.length > 0) {
                  for (const childID of childrenIDs) {
                     tempAssetIDs2[childID] = childID;
                  }
               }
            }
         }
         const aIds: any = [];
         for (const key6 in tempAssetIDs2) {
            aIds.push(tempAssetIDs2[key6]);
         }
         completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, aIds);
      }

      if (this.typeFilterNumber) {
         completedTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            completedTasks,
            [this.typeFilterNumber],
         );
      }
      if (this.tagFilter) {
         completedTasks = this.manageFilters.filterTasksToCustomTags(completedTasks, {
            tag: this.tagFilter,
         });
      }

      if (this.searchBar && this.searchBar.length > 0) {
         const filterResult = this.manageFilters.filterTasksToSearch(
            completedTasks,
            this.searchBar,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         );
         completedTasks = filterResult.tasks;
         this.searchHints = filterResult.searchHints;
      }

      this.noSearchResults = false;
      if (completedTasks.size == 0) {
         this.noSearchResults = true;
      }
      this.tasks = completedTasks;
      this.taskIDs = Array.from(completedTasks).map((task) => task.checklistID);
   }

   // tasksByDowntime modalType
   public setTasksByDowntimeColumns(): void {
      // Set columns to be displayed in list view
      this.taskColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 4,
         },
         {
            key: "checklistDowntime",
            displayName: this.lang().Downtime,
            sortBy: "checklistDowntime",
            columnWidth: 3,
         },
         {
            key: "checklistPromptTimeTotal",
            displayName: this.lang().CompletionTime,
            sortBy: "checklistPromptTimeTotal",
            columnWidth: 3,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Completed,
            sortBy: "checklistCompletedDate",
            columnWidth: 2,
         },
         {
            key: "checklistLastEdited",
            displayName: this.lang().LatestActivity,
            sortBy: "checklistLastEdited",
            columnWidth: 2,
         },
      ];

      // Set columns to show when tasks are exported for Excel
      this.spreadSheetColumns = {
         checklistDowntime: true,
         checklistPromptTime: true,
         checklistCompletedDate: true,
      };
   }

   public setTasksByDowntimeData(): void {
      let completedTasks = this.manageTask
         .getCompletedTasks("ViewTaskList")
         .filter((task) => {
            if (task.checklistTemplate !== 0) {
               return false; //only instances
            }
            if (task.checklistStatusID !== 1) {
               return false; //only completed
            }
            if (
               this.data.locationID > 0 &&
               task.locationID !== Number(this.data.locationID)
            ) {
               return false; //specific location
            }
            return true;
         });

      if (this.data.assetID > 0) {
         //if assetID is set filter to a specific asset
         //first we need to see if this asset has children.  If it does then we need to filter to the parent/child group of assets
         const asset = this.manageAsset.getAsset(this.data.assetID);
         assert(asset);
         if (asset.includeChildData == 1) {
            const childrenIDs = this.manageAsset.findChildrenIDs(asset, []);
            if (childrenIDs.length > 0) {
               const tempAssetIDs: any = [];
               for (const childID of childrenIDs) {
                  tempAssetIDs.push(childID);
               }
               tempAssetIDs.push(this.data.assetID);
               completedTasks = this.manageFilters.filterTasksByAssetIDs(
                  completedTasks,
                  tempAssetIDs,
               );
            } else {
               completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
                  this.data.assetID,
               ]);
            }
         } else {
            completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
               this.data.assetID,
            ]);
         }
      }

      if (this.data.dateRange1 && this.data.dateRange2) {
         const date1 = new Date(this.data.dateRange1.startOf("day"));
         const date2 = new Date(this.data.dateRange2.endOf("day"));
         const start = Math.round(date1.getTime() / 1000);
         const end = Math.round(date2.getTime() / 1000);

         completedTasks = this.manageFilters.filterTasksByCompletedDate(completedTasks, {
            start: start,
            end: end,
         });
      }

      if (this.data.xDays > 0) {
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            this.data.xDays,
         );
      }

      if (this.data.checklistTemplateOld !== undefined) {
         //if checklistTemplateOldID is set then filter to those types.
         completedTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            completedTasks,
            this.data.checklistTemplateOld,
         );
      }

      if (this.data.downtime) {
         //filter them down to only tasks that have downtime
         const tempTasks: any = [];
         for (const task of completedTasks) {
            if (task.checklistDowntime !== null && task.checklistDowntime > 0) {
               tempTasks.push(task);
            }
         }
         completedTasks = tempTasks;
      }

      if (
         this.data.checklistBatchID !== undefined &&
         this.data.checklistBatchID !== false
      ) {
         //if checklistBatchID is set then we need to filter to batchIDs
         completedTasks = this.manageFilters.filterTasksByChecklistBatchID(
            completedTasks,
            this.data.checklistBatchID,
         );
      }

      if (this.data.assets) {
         //get all of the assets and their childre as well.
         const tempAssetIDs2 = {};
         for (const asset of this.data.assets) {
            const lookupAsset = this.manageAsset.getAsset(asset.assetID);
            assert(lookupAsset);
            tempAssetIDs2[asset.assetID] = asset.assetID;
            if (lookupAsset.includeChildData == 1) {
               const childrenIDs = this.manageAsset.findChildrenIDs(lookupAsset, []);
               if (childrenIDs.length > 0) {
                  for (const childID of childrenIDs) {
                     tempAssetIDs2[childID] = childID;
                  }
               }
            }
         }
         const aIds: any = [];
         for (const key6 in tempAssetIDs2) {
            aIds.push(tempAssetIDs2[key6]);
         }
         completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, aIds);
      }

      if (this.tagFilter) {
         completedTasks = this.manageFilters.filterTasksToCustomTags(completedTasks, {
            tag: this.tagFilter,
         });
      }

      if (this.searchBar && this.searchBar.length > 0) {
         const filterResult = this.manageFilters.filterTasksToSearch(
            completedTasks,
            this.searchBar,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         );
         completedTasks = filterResult.tasks;
         this.searchHints = filterResult.searchHints;
      }

      this.noSearchResults = false;
      if (completedTasks.size == 0) {
         this.noSearchResults = true;
      }

      this.tasks = completedTasks;
      this.taskIDs = Array.from(completedTasks).map((task) => task.checklistID);
   }

   // tasksByStatus modalType
   public setTasksByStatusColumns(): void {
      // Set columns to be displayed in list view
      this.taskColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 4,
         },
         {
            key: "finalColorStatus",
            displayName: this.lang().Status,
            sortBy: "finalColorStatus",
            columnWidth: 2,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 2,
         },
         {
            key: "checklistPromptTimeTotal",
            displayName: this.lang().TimeSpent,
            sortBy: "checklistPromptTimeTotal",
            columnWidth: 2,
         },
         {
            key: "completedByStr",
            displayName: this.lang().CompletedBy,
            sortBy: "completedByStr",
            columnWidth: 2,
         },
         {
            key: "checklistLastEdited",
            displayName: this.lang().LatestActivity,
            sortBy: "checklistLastEdited",
            columnWidth: 2,
         },
      ];

      // Set columns to show when tasks are exported for Excel
      this.spreadSheetColumns = {
         checklistDueDate: true,
         checklistPromptTime: true,
         checklistUserCompleted: true,
      };
   }

   public setTasksByStatusData(): void {
      if (typeof this.data === "object" && this.data.filterByDueDate === undefined) {
         this.data.filterByDueDate = false;
      }
      let completedTasks = this.manageTask
         .getCompletedTasks("ViewTaskList")
         .filter((task) => {
            if (task.checklistTemplate !== 0) {
               return false; //only instances
            }
            if (task.checklistStatusID !== 1) {
               return false; //only completed
            }
            if (
               this.data.locationID > 0 &&
               task.locationID !== Number(this.data.locationID)
            ) {
               return false; //specific location
            }
            if (
               this.data.userID &&
               task.checklistUserCompleted !== Number(this.data.userID)
            ) {
               return false;
            }
            return true;
         });
      if (this.data.assetID > 0) {
         //if assetID is set filter to a specific asset
         //first we need to see if this asset has children.  If it does then we need to filter to the parent/child group of assets
         const asset = this.manageAsset.getAsset(this.data.assetID);
         assert(asset);
         if (asset.includeChildData == 1) {
            const childrenIDs = this.manageAsset.findChildrenIDs(asset, []);
            if (childrenIDs.length > 0) {
               const tempAssetIDs: any = [];
               for (const childID of childrenIDs) {
                  tempAssetIDs.push(childID);
               }
               tempAssetIDs.push(this.data.assetID);
               completedTasks = this.manageFilters.filterTasksByAssetIDs(
                  completedTasks,
                  tempAssetIDs,
               );
            } else {
               completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
                  this.data.assetID,
               ]);
            }
         } else {
            completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
               this.data.assetID,
            ]);
         }
      }
      if (this.data.dateRange1 && this.data.dateRange2) {
         const date1 = new Date(this.data.dateRange1.startOf("day"));
         const date2 = new Date(this.data.dateRange2.endOf("day"));
         const start = Math.round(date1.getTime() / 1000);
         const end = Math.round(date2.getTime() / 1000);
         if (this.data.filterByDueDate == true) {
            completedTasks = this.manageFilters.filterTasksByDueDate(completedTasks, {
               start: start,
               end: end,
            });
         } else {
            completedTasks = this.manageFilters.filterTasksByCompletedDate(
               completedTasks,
               {
                  start: start,
                  end: end,
               },
            );
         }
      }
      if (this.data.xDays > 0) {
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            this.data.xDays,
         );
      }
      if (this.data.status > 0) {
         const tempTasks: any = [];
         for (const task of completedTasks) {
            if (task.finalColorStatus == this.data.status) {
               tempTasks.push(task);
            }
         }
         completedTasks = tempTasks;
      }
      if (this.data.checklistTemplateOld !== undefined) {
         //if checklistTemplateOldID is set then filter to those types.
         completedTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            completedTasks,
            this.data.checklistTemplateOld,
         );
      }
      if (
         this.data.checklistBatchID !== undefined &&
         this.data.checklistBatchID !== false
      ) {
         //if checklistBatchID is set then we need to filter to batchIDs
         completedTasks = this.manageFilters.filterTasksByChecklistBatchID(
            completedTasks,
            this.data.checklistBatchID,
         );
      }
      if (this.data.assets) {
         //get all of the assets and their childre as well.
         const tempAssetIDs2 = {};
         for (const asset of this.data.assets) {
            const lookupAsset = this.manageAsset.getAsset(asset.assetID);
            assert(lookupAsset);
            tempAssetIDs2[asset.assetID] = asset.assetID;
            if (lookupAsset.includeChildData == 1) {
               const childrenIDs = this.manageAsset.findChildrenIDs(lookupAsset, []);
               if (childrenIDs.length > 0) {
                  for (const childID of childrenIDs) {
                     tempAssetIDs2[childID] = childID;
                  }
               }
            }
         }
         const aIds: any = [];
         for (const key6 in tempAssetIDs2) {
            aIds.push(tempAssetIDs2[key6]);
         }
         completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, aIds);
      }
      if (this.typeFilterNumber) {
         completedTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            completedTasks,
            [this.typeFilterNumber],
         );
      }
      if (this.tagFilter) {
         completedTasks = this.manageFilters.filterTasksToCustomTags(completedTasks, {
            tag: this.tagFilter,
         });
      }
      if (this.searchBar && this.searchBar.length > 0) {
         completedTasks = this.manageFilters.filterTasksToSearch(
            completedTasks,
            this.searchBar,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         ).tasks;
      }
      this.noSearchResults = false;
      if (completedTasks.size == 0) {
         this.noSearchResults = true;
      }
      this.tasks = completedTasks;
      this.taskIDs = Array.from(completedTasks.values()).map((task) => task.checklistID);
   }

   // tasksByType modalType
   public setTasksByTypeColumns(): void {
      // Set columns to be displayed in list view
      this.taskColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 4,
         },
         {
            key: "checklistTemplateOldString",
            displayName: this.lang().Type,
            sortBy: "checklistTemplateOldString",
            columnWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Completed,
            sortBy: "checklistCompletedDate",
            columnWidth: 2,
         },
         {
            key: "checklistPromptTimeTotal",
            displayName: this.lang().TimeSpent,
            sortBy: "checklistPromptTimeTotal",
            columnWidth: 2,
         },
         {
            key: "completedByStr",
            displayName: this.lang().CompletedBy,
            sortBy: "completedByStr",
            columnWidth: 2,
         },
         {
            key: "checklistLastEdited",
            displayName: this.lang().LatestActivity,
            sortBy: "checklistLastEdited",
            columnWidth: 2,
         },
      ];

      // Set columns to show when tasks are exported for Excel
      this.spreadSheetColumns = {
         checklistTemplateOld: true,
         checklistCompletedDate: true,
         checklistPromptTime: true,
         checklistUserCompleted: true,
      };
   }

   public setTasksByTypeData(): void {
      let completedTasks = this.manageTask
         .getCompletedTasks("ViewTaskList")
         .filter((task) => {
            if (task.checklistTemplate !== 0) {
               return false; //only instances
            }
            if (task.checklistStatusID !== 1) {
               return false; //only completed
            }
            if (
               this.data.locationID > 0 &&
               task.locationID !== Number(this.data.locationID)
            ) {
               return false; //specific location
            }
            if (
               this.data.userID &&
               task.checklistUserCompleted !== Number(this.data.userID)
            ) {
               return false;
            }
            return true;
         });

      if (this.data.assetID > 0) {
         //if assetID is set filter to a specific asset
         //first we need to see if this asset has children.  If it does then we need to filter to the parent/child group of assets
         const asset = this.manageAsset.getAsset(this.data.assetID);
         assert(asset);
         if (asset.includeChildData == 1) {
            const childrenIDs = this.manageAsset.findChildrenIDs(asset, []);
            if (childrenIDs.length > 0) {
               const tempAssetIDs: any = [];
               for (const childID of childrenIDs) {
                  tempAssetIDs.push(childID);
               }
               tempAssetIDs.push(this.data.assetID);
               completedTasks = this.manageFilters.filterTasksByAssetIDs(
                  completedTasks,
                  tempAssetIDs,
               );
            } else {
               completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
                  this.data.assetID,
               ]);
            }
         } else {
            completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
               this.data.assetID,
            ]);
         }
      }

      if (this.data.dateRange1 && this.data.dateRange2) {
         const date1 = new Date(this.data.dateRange1.startOf("day"));
         const date2 = new Date(this.data.dateRange2.endOf("day"));
         const start = Math.round(date1.getTime() / 1000);
         const end = Math.round(date2.getTime() / 1000);

         completedTasks = this.manageFilters.filterTasksByCompletedDate(completedTasks, {
            start: start,
            end: end,
         });
      }

      if (this.data.xDays > 0) {
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            this.data.xDays,
         );
      }

      if (this.data.checklistTemplateOld !== undefined) {
         //if checklistTemplateOldID is set then filter to those types.
         completedTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            completedTasks,
            this.data.checklistTemplateOld,
         );
      }

      if (
         this.data.checklistBatchID !== undefined &&
         this.data.checklistBatchID !== false
      ) {
         //if checklistBatchID is set then we need to filter to batchIDs
         completedTasks = this.manageFilters.filterTasksByChecklistBatchID(
            completedTasks,
            this.data.checklistBatchID,
         );
      }

      if (this.data.assets) {
         //get all of the assets and their childre as well.
         const tempAssetIDs2 = {};
         for (const asset of this.data.assets) {
            const lookupAsset = this.manageAsset.getAsset(asset.assetID);
            assert(lookupAsset);
            tempAssetIDs2[asset.assetID] = asset.assetID;
            if (lookupAsset.includeChildData == 1) {
               const childrenIDs = this.manageAsset.findChildrenIDs(lookupAsset, []);
               if (childrenIDs.length > 0) {
                  for (const childID of childrenIDs) {
                     tempAssetIDs2[childID] = childID;
                  }
               }
            }
         }
         const aIds: any = [];
         for (const key6 in tempAssetIDs2) {
            aIds.push(tempAssetIDs2[key6]);
         }
         completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, aIds);
      }

      if (this.data.deferred) {
         const tempTasks: any = [];
         for (const task of completedTasks) {
            if (task.defermentIDs) {
               if (task.defermentIDs.length > 0) {
                  tempTasks.push(task);
               }
            }
         }
         completedTasks = tempTasks;
      }

      if (this.tagFilter) {
         completedTasks = this.manageFilters.filterTasksToCustomTags(completedTasks, {
            tag: this.tagFilter,
         });
      }

      if (this.searchBar && this.searchBar.length > 0) {
         const filterResult = this.manageFilters.filterTasksToSearch(
            completedTasks,
            this.searchBar,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         );
         completedTasks = filterResult.tasks;
         this.searchHints = filterResult.searchHints;
      }

      this.noSearchResults = false;
      if (completedTasks.size == 0) {
         this.noSearchResults = true;
      }

      this.tasks = completedTasks;
      this.taskIDs = Array.from(completedTasks).map((task) => task.checklistID);
   }

   // completedTasks modalType
   public setCompletedTasksColumns(): void {
      // Set columns to be displayed in list view
      this.taskColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 4,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Completed,
            sortBy: "checklistCompletedDate",
            columnWidth: 2,
         },
         {
            key: "checklistPromptTimeTotal",
            displayName: this.lang().TimeSpent,
            sortBy: "checklistPromptTimeTotal",
            columnWidth: 2,
         },
         {
            key: "completedByStr",
            displayName: this.lang().CompletedBy,
            sortBy: "completedByStr",
            columnWidth: 2,
         },
         {
            key: "checklistLastEdited",
            displayName: this.lang().LatestActivity,
            sortBy: "checklistLastEdited",
            columnWidth: 2,
         },
      ];

      // Set columns to show when tasks are exported for Excel
      this.spreadSheetColumns = {
         checklistDueDate: true,
         checklistCompletedDate: true,
         checklistPromptTime: true,
         checklistUserCompleted: true,
      };
   }

   public setCompletedTasksData(): void {
      let completedTasks = this.manageTask
         .getCompletedTasks("ViewTaskList")
         .filter((task) => {
            if (task.checklistTemplate !== 0) {
               return false; //only instances
            }
            if (task.checklistStatusID !== 1) {
               return false; //only completed
            }
            if (
               this.data.locationID > 0 &&
               task.locationID !== Number(this.data.locationID)
            ) {
               return false; //specific location
            }
            if (
               this.data.profileID !== undefined &&
               this.data.profileID > 0 &&
               task.profileID !== this.data.profileID
            ) {
               return false;
            }
            return true;
         });
      if (this.data.assetID > 0) {
         //if assetID is set filter to a specific asset
         //first we need to see if this asset has children.  If it does then we need to filter to the parent/child group of assets
         const asset = this.manageAsset.getAsset(this.data.assetID);
         assert(asset);
         if (asset.includeChildData == 1) {
            const childrenIDs = this.manageAsset.findChildrenIDs(asset, []);
            if (childrenIDs.length > 0) {
               const tempAssetIDs: any = [];
               for (const childID of childrenIDs) {
                  tempAssetIDs.push(childID);
               }
               tempAssetIDs.push(this.data.assetID);
               completedTasks = this.manageFilters.filterTasksByAssetIDs(
                  completedTasks,
                  tempAssetIDs,
               );
            } else {
               completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
                  this.data.assetID,
               ]);
            }
         } else {
            completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
               this.data.assetID,
            ]);
         }
      }

      if (this.data.dateRange1 && this.data.dateRange2) {
         const date1 = new Date(this.data.dateRange1.startOf("day"));
         const date2 = new Date(this.data.dateRange2.endOf("day"));
         const start = Math.round(date1.getTime() / 1000);
         const end = Math.round(date2.getTime() / 1000);

         completedTasks = this.manageFilters.filterTasksByCompletedDate(completedTasks, {
            start: start,
            end: end,
         });
      }

      if (this.data.xDays > 0) {
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            this.data.xDays,
         );
      }

      if (this.data.checklistTemplateOld !== undefined) {
         //if checklistTemplateOldID is set then filter to those types.
         completedTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            completedTasks,
            this.data.checklistTemplateOld,
         );
      }

      if (
         this.data.checklistBatchID !== undefined &&
         this.data.checklistBatchID !== false
      ) {
         //if checklistBatchID is set then we need to filter to batchIDs
         completedTasks = this.manageFilters.filterTasksByChecklistBatchID(
            completedTasks,
            this.data.checklistBatchID,
         );
      }

      if (this.data.assets) {
         //get all of the assets and their childre as well.
         const tempAssetIDs2 = {};
         for (const asset of this.data.assets) {
            const lookupAsset = this.manageAsset.getAsset(asset.assetID);
            assert(lookupAsset);
            tempAssetIDs2[asset.assetID] = asset.assetID;
            if (lookupAsset.includeChildData == 1) {
               const childrenIDs = this.manageAsset.findChildrenIDs(lookupAsset, []);
               if (childrenIDs.length > 0) {
                  for (const childID of childrenIDs) {
                     tempAssetIDs2[childID] = childID;
                  }
               }
            }
         }
         const aIds: any = [];
         for (const key6 in tempAssetIDs2) {
            aIds.push(tempAssetIDs2[key6]);
         }
         completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, aIds);
      }

      if (this.data.userID !== undefined && this.data.userID > 0) {
         completedTasks = this.manageFilters.filterTaskByCompletedOwner(
            completedTasks,
            this.data.userID,
            this.manageTask,
         );
      }

      if (this.data.sort !== undefined) {
         this.sortBind = this.data.sort;
      }

      if (this.typeFilterNumber) {
         completedTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            completedTasks,
            [this.typeFilterNumber],
         );
      }
      if (this.tagFilter) {
         completedTasks = this.manageFilters.filterTasksToCustomTags(completedTasks, {
            tag: this.tagFilter,
         });
      }

      if (this.searchBar && this.searchBar.length > 0) {
         const filterResult = this.manageFilters.filterTasksToSearch(
            completedTasks,
            this.searchBar,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         );
         completedTasks = filterResult.tasks;
         this.searchHints = filterResult.searchHints;
      }

      this.noSearchResults = false;
      if (completedTasks.size == 0) {
         this.noSearchResults = true;
      }
      this.tasks = completedTasks;
      this.taskIDs = Array.from(completedTasks).map((task) => task.checklistID);
   }

   // setTasksColumnsFilters modalType
   private setTasksColumnsFilters(): void {
      // Set columns to be displayed in list view
      this.setAnyTasksColumns();

      // Set filters to show in list dynamically

      const mappedPriorityList = this.managePriority.getPriorityList().map((priority) => {
         return priority.name;
      });

      const mappedStatusList = this.manageStatus
         .getStatusList()
         .map((status) => status.name);

      this.filterColumns = [
         {
            key: "priorityName",
            inputType: "dropdown",
            dropdownOptions: mappedPriorityList,
            displayName: this.lang().PriorityLevel,
         },
         {
            key: "statusName",
            inputType: "dropdown",
            dropdownOptions: mappedStatusList,
            displayName: this.lang().Status,
         },
         {
            key: "checklistName",
            inputType: "text",
            displayName: this.lang().Name,
         },
         {
            key: "checklistDueDate",
            inputType: "dateRange",
            beginDate: undefined,
            endDate: undefined,
            displayName: this.lang().Due,
         },
         {
            key: "checklistCompletedDate",
            inputType: "dateRange",
            beginDate: undefined,
            endDate: undefined,
            displayName: this.lang().Completed,
         },
         {
            key: "completedByStr",
            inputType: "dropdown",
            dropdownOptions: [],
            displayName: this.lang().CompletedBy,
         },
         {
            key: "displayName",
            inputType: "dropdown",
            dropdownOptions: [],
            displayName: this.lang().AssignedTo,
         },
      ];
   }

   public setAnyTasksColumns(): void {
      // Set columns to be displayed in list view
      this.taskColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 4,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Completed,
            sortBy: "checklistCompletedDate",
            columnWidth: 2,
         },
         {
            key: "completedByStr",
            displayName: this.lang().CompletedBy,
            sortBy: "completedByStr",
            columnWidth: 2,
         },
         {
            key: "assignedTo",
            displayName: this.lang().AssignedTo,
            sortBy: "assignment",
            columnWidth: 2,
         },
         {
            key: "checklistLastEdited",
            displayName: this.lang().LatestActivity,
            sortBy: "checklistLastEdited",
            columnWidth: 2,
         },
      ];

      if (this.resolve.columns) {
         this.resolve.columns.forEach((column: Column) => {
            this.taskColumns.push(column);
         });
      }

      // Set columns to show when tasks are exported for Excel
      this.spreadSheetColumns = {
         checklistDueDate: true,
         checklistCompletedDate: true,
         checklistUserCompleted: true,
         assignedTo: true,
      };
   }

   // openTasks modalType
   public setOpenTasksColumns(): void {
      // Set columns to be displayed in list view
      this.taskColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 6,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 3,
         },
         {
            key: "assignedTo",
            displayName: this.lang().AssignedTo,
            sortBy: "assignment",
            columnWidth: 3,
         },
         {
            key: "checklistLastEdited",
            displayName: this.lang().LatestActivity,
            sortBy: "checklistLastEdited",
            columnWidth: 2,
         },
      ];

      // Set columns to show when tasks are exported for Excel
      this.spreadSheetColumns = {
         checklistDueDate: true,
         assignedTo: true,
      };
   }

   public setCustomDateRange(event: MouseEvent, index: number): void {
      if (
         this.searchFields[index].beginDate === undefined ||
         this.searchFields[index].endDate === undefined
      ) {
         event.stopPropagation();
         return;
      }
      const moment1 = moment(this.searchFields[index].beginDate);
      const moment2 = moment(this.searchFields[index].endDate);
      if (moment2.isSameOrBefore(moment1)) {
         [this.searchFields[index].beginDate, this.searchFields[index].endDate] = [
            this.searchFields[index].endDate,
            this.searchFields[index].beginDate,
         ];
      }
      this.buildTasks();
   }

   public setOpenTasksData(): void {
      let allTasks = this.manageTask.getTasks().filter((task) => {
         if (task.checklistTemplate !== 0) {
            return false;
         }
         if (task.checklistStatusID !== 0) {
            return false;
         }
         if (
            this.data.locationID > 0 &&
            task.locationID !== Number(this.data.locationID)
         ) {
            return false;
         }
         return true;
      });

      if (this.data.assetID > 0) {
         //if assetID is set filter to a specific asset
         allTasks = this.manageFilters.filterTasksByAssetIDs(allTasks, [
            this.data.assetID,
         ]);
      }

      if (
         this.data.checklistBatchID !== undefined &&
         this.data.checklistBatchID !== false
      ) {
         //if checklistBatchID is set then we need to filter to batchIDs
         allTasks = this.manageFilters.filterTasksByChecklistBatchID(
            allTasks,
            this.data.checklistBatchID,
         );
      }

      if (this.data.checklistTemplateOld !== undefined) {
         //if checklistTemplateOldID is set then filter to those types.
         allTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            allTasks,
            this.data.checklistTemplateOld,
         );
      }

      if (this.typeFilterNumber) {
         allTasks = this.manageFilters.filterTasksByChecklistTemplateOld(allTasks, [
            this.typeFilterNumber,
         ]);
      }
      if (this.tagFilter) {
         allTasks = this.manageFilters.filterTasksToCustomTags(allTasks, {
            tag: this.tagFilter,
         });
      }

      if (this.searchBar && this.searchBar.length > 0) {
         const filterResult = this.manageFilters.filterTasksToSearch(
            allTasks,
            this.searchBar,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         );
         allTasks = filterResult.tasks;
         this.searchHints = filterResult.searchHints;
      }

      this.noSearchResults = false;
      if (allTasks.size == 0) {
         this.noSearchResults = true;
      }

      this.tasks = allTasks;
      this.taskIDs = Array.from(allTasks).map((task) => task.checklistID);

      for (const task of this.tasks) {
         const taskExtraInfo = this.tasksExtraInfo.get(task.checklistID);
         if (!taskExtraInfo) return;
         if (task.userID !== null && task.userID > 0) {
            this.tasksExtraInfo.set(task.checklistID, {
               ...taskExtraInfo,
               assignment: `${this.users[task.userID]?.userFirstName} ${
                  this.users[task.userID]?.userLastName
               }`,
            });
         } else if (task.profileID !== null && task.profileID > 0) {
            if (this.profiles[task.profileID]) {
               this.tasksExtraInfo.set(task.checklistID, {
                  ...taskExtraInfo,
                  assignment: this.profiles[task.profileID].profileDescription,
               });
            } else {
               this.tasksExtraInfo.set(task.checklistID, {
                  ...taskExtraInfo,
                  assignment: "",
               });
            }
         } else {
            this.tasksExtraInfo.set(task.checklistID, {
               ...taskExtraInfo,
               assignment: "Unassigned",
            });
         }

         this.tasksExtraInfo.set(task.checklistID, {
            ...taskExtraInfo,
            locationName: this.locations[task.locationID].locationName,
         });
      }
   }

   private setCycleCountTasksData(): void {
      this.setAnyTasksData();
      if (this.selectedCycleCountTaskFilterType !== null) {
         this.buildCycleCountDropdownUserData();
      }
      this.filterCycleCountTasks();
   }

   private filterCycleCountTasks(): void {
      if (!this.searchFields || this.searchFields.length === 0) {
         return;
      }
      this.tasks = this.tasks.filter((task) => this.taskMatchesSearch(task));
      this.taskIDs = Array.from(this.tasks).map((task) => task.checklistID);
      this.noSearchResults = false;
      if (this.tasks.size === 0) {
         this.noSearchResults = true;
      }
   }

   private taskMatchesSearch(task: Task): boolean {
      for (const field of this.searchFields) {
         if (!field.name || field.name == "") {
            continue;
         }
         if (this.searchFieldIsValue(field)) {
            if (field.searchValue === "" || field.searchValue === undefined) return true;
            const extraInfo = this.tasksExtraInfo.get(task.checklistID);

            if (task[field.name] !== undefined) {
               return task[field.name]
                  .toString()
                  .toLowerCase()
                  .includes(field.searchValue?.toLowerCase());
            }
            if (extraInfo?.[field.name]) {
               return extraInfo[field.name]
                  .toString()
                  .toLowerCase()
                  .includes(field.searchValue?.toLowerCase());
            }
         }
         if (this.searchFieldIsDate(field)) {
            if (field.beginDate === undefined && field.endDate === undefined) return true;
            const dateToCheck = moment.unix(task[field.name]);
            const beginDate = moment(field.beginDate);
            const endDate = moment(field.endDate);
            if (this.dayIsBetween(dateToCheck, beginDate, endDate)) {
               return true;
            }
         }
      }
      return false;
   }

   private searchFieldIsDate(field: CycleCountTaskFilter): boolean {
      return field.inputType === "dateRange";
   }

   private searchFieldIsValue(field: CycleCountTaskFilter): boolean {
      return !this.searchFieldIsDate(field);
   }

   /** Checks if `targetDay` is between `startDay` and `endDay`, inclusive. Ignores time of day. */
   private dayIsBetween(targetDay: Moment, startDay: Moment, endDay: Moment): boolean {
      return (
         targetDay.isBetween(startDay, endDay, "day") ||
         targetDay.isSame(startDay, "day") ||
         targetDay.isSame(endDay, "day")
      );
   }

   private buildCycleCountDropdownUserData(): void {
      if (!this.selectedCycleCountTaskFilterType) {
         return;
      }

      const selectedCycleCountTaskFilterTypeObject = this.filterColumns.find(
         (column) => column.key == this.selectedCycleCountTaskFilterType?.key,
      );
      if (
         selectedCycleCountTaskFilterTypeObject &&
         !this.searchFields.some(
            (field) => field.name == this.selectedCycleCountTaskFilterType?.key,
         )
      ) {
         if (selectedCycleCountTaskFilterTypeObject.inputType == "dropdown") {
            if (this.selectedCycleCountTaskFilterType.key == "completedByStr") {
               selectedCycleCountTaskFilterTypeObject.dropdownOptions =
                  this.buildDropdownUsers("completedByStr");
            }
            if (this.selectedCycleCountTaskFilterType.key == "displayName") {
               selectedCycleCountTaskFilterTypeObject.dropdownOptions =
                  this.buildDropdownUsers("displayName");
            }
         }

         this.searchFields.push({
            searchValue: "",
            displayName: selectedCycleCountTaskFilterTypeObject.displayName,
            inputType: selectedCycleCountTaskFilterTypeObject.inputType,
            beginDate: selectedCycleCountTaskFilterTypeObject.beginDate,
            endDate: selectedCycleCountTaskFilterTypeObject.endDate,
            dropdownOptions: selectedCycleCountTaskFilterTypeObject.dropdownOptions ?? [],
            name: this.selectedCycleCountTaskFilterType?.key,
         });
      }
   }

   public setAnyTasksData(): void {
      let allTasks = new Lookup("checklistID", [
         ...this.manageTask.getTasks().values(),
         ...this.manageTask.getCompletedTasks("ViewTaskList").values(),
      ]);

      allTasks = allTasks.filter((task) => {
         if (task.checklistTemplate !== 0) {
            return false;
         }
         if (
            this.data.locationID > 0 &&
            task.locationID !== Number(this.data.locationID)
         ) {
            return false;
         }
         return true;
      });
      if (this.data.assetID > 0) {
         //if assetID is set filter to a specific asset
         allTasks = this.manageFilters.filterTasksByAssetIDs(allTasks, [
            this.data.assetID,
         ]);
      }

      if (
         this.data.checklistBatchID !== undefined &&
         this.data.checklistBatchID !== false
      ) {
         //if checklistBatchID is set then we need to filter to batchIDs
         allTasks = this.manageFilters.filterTasksByChecklistBatchID(
            allTasks,
            this.data.checklistBatchID,
         );
      }

      if (this.data.checklistTemplateOld !== undefined) {
         //if checklistTemplateOldID is set then filter to those types.
         allTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            allTasks,
            this.data.checklistTemplateOld,
         );
      }

      if (this.typeFilterNumber) {
         allTasks = this.manageFilters.filterTasksByChecklistTemplateOld(
            allTasks,
            this.typeFilterNumber,
         );
      }
      if (this.tagFilter) {
         allTasks = this.manageFilters.filterTasksToCustomTags(allTasks, {
            tag: this.tagFilter,
         });
      }

      if (this.searchBar && this.searchBar.length > 0) {
         allTasks = this.manageFilters.filterTasksToSearch(
            allTasks,
            this.searchBar,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         ).tasks;
      }

      this.noSearchResults = false;
      if (allTasks.size == 0) {
         this.noSearchResults = true;
      }

      this.tasks = allTasks;
      this.taskIDs = Array.from(allTasks).map((task) => task.checklistID);
      for (const task of this.tasks) {
         const taskExtraInfo = this.tasksExtraInfo.get(task.checklistID) ?? {
            selected: false,
            taskData: [],
         };
         let assignment;
         if (task.userID !== null && task.userID > 0) {
            assignment = `${this.users[task.userID]?.userFirstName} ${
               this.users[task.userID]?.userLastName
            }`;
         } else if (task.profileID !== null && task.profileID > 0) {
            if (this.profiles[task.profileID]) {
               assignment = this.profiles[task.profileID].profileDescription;
            } else {
               assignment = "";
            }
         } else {
            assignment = this.lang().Unassigned;
         }

         const statusName =
            task.statusID === null
               ? ""
               : this.manageStatus.getStatusListIndex()[task.statusID].name;
         const priorityName =
            task.priorityID === null || task.priorityID === 0
               ? ""
               : this.managePriority.getPriorityListIndex()[task.priorityID]?.name;
         const completedByStr =
            this.manageTask.getCompletedTaskCalculatedInfo(task)?.completedByStr ?? "";
         const { displayName } = this.manageTask.getTaskAssignmentInfo(task);

         this.tasksExtraInfo.set(task.checklistID, {
            ...taskExtraInfo,
            locationName: this.locations[task.locationID].locationName,
            assignment,
            statusName,
            priorityName,
            completedByStr,
            displayName,
         });
      }
   }

   // tasksByOpenStatusDuration modalType
   public setTasksByStatusDurationColumns(): void {
      this.taskColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 6,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Completed,
            sortBy: "checklistCompletedDate",
            columnWidth: 2,
         },
         {
            key: "completedByStr",
            displayName: this.lang().CompletedBy,
            sortBy: "completedByStr",
            columnWidth: 2,
         },
         {
            key: "checklistLastEdited",
            displayName: this.lang().LatestActivity,
            sortBy: "checklistLastEdited",
            columnWidth: 2,
         },
      ];

      // Set columns to show when tasks are exported for Excel
      this.spreadSheetColumns = {
         checklistDueDate: true,
         checklistCompletedDate: true,
         checklistUserCompleted: true,
      };
   }

   public runFilter($event, field): void {
      field.searchValue = $event;
      this.runTimeoutAndBuildTasks();
   }

   public updateFieldSearchValue(value: string, field): void {
      field.searchValue = value;
      this.runTimeoutAndBuildTasks();
   }

   public runSearch($event): void {
      this.searchBar = $event;

      this.runTimeoutAndBuildTasks();
      this.searchedTextSubject.next($event);
   }

   public runTimeoutAndBuildTasks(): void {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
         this.buildTasks();
      }, 300);
   }

   public close(): void {
      this.modalInstance.close(0);
   }

   public setCycleCountTaskTypeFilter(column): void {
      this.selectedCycleCountTaskFilterType = column;
      this.buildTasks();
   }

   public setTypeFilter(flag: number): void {
      this.typeFilter = true;

      if (flag == 1) {
         this.typeFilterName = this.lang().PMs;
      } else if (flag == 2) {
         this.typeFilterName = this.lang().UnplannedWOs;
      } else if (flag == 4) {
         this.typeFilterName = this.lang().PlannedWOs;
      }

      this.typeFilterNumber = flag;
      this.buildTasks();
   }

   public clearTypeFilter(): void {
      this.typeFilter = false;
      this.typeFilterName = false;
      this.typeFilterNumber = 0;
      this.selectedCycleCountTaskFilterType = null;
      this.buildTasks();
   }

   public downloadExcel(tasksToDownload: TaskLookup): void {
      this.showLoading = true;
      this.manageTask.downloadExcel(tasksToDownload).then(() => {
         this.showLoading = false;
      });
   }

   public downloadExcelTasksLimited(tasksToDownload: TaskLookup): void {
      this.loadingBar = true;
      const host = `${window.location.protocol}//${window.location.host}`;
      const tasksToDownloadArr: Array<
         Task &
            Partial<{
               startTemplateURL: string;
               flipScheduleURL: string;
               timeSpentInStatusInProgress: number;
               timeSpentInStatusOpen: number;
               extraTimeDownloadTemp: any[];
            }>
      > = [];

      for (const task of tasksToDownload) {
         const startTemplateURL = `${host}/startTaskTemplate/${task.checklistID}/${task.assetID}?m=true`;
         const flipScheduleURL = `${host}/flipPMSchedule/${task.checklistID}?m=true`;
         const extraTimeDownloadTemp = task.extraTimeIDs.map((extraTimeID) => {
            return this.manageTask.getExtraTime(extraTimeID);
         });
         tasksToDownloadArr.push({
            ...task,
            startTemplateURL,
            flipScheduleURL,
            extraTimeDownloadTemp: extraTimeDownloadTemp,
         });
      }
      this.manageTask.downloadTasksLimited(tasksToDownloadArr, this.spreadSheetColumns);

      this.loadingBar = false;
   }

   public setCustomTagListObj(obj): void {
      const clickTag = (tag) => {
         if (`${tag.name};` == this.tagFilter) {
            this.clearTagFilter();
         } else {
            this.tagFilter = `${tag.name};`;
         }
         this.buildTasks();
      };
      obj.selectOne = true;
      obj.clickTag = clickTag;
      obj.advancedSettings = false;
      obj.sources = [""];
   }

   protected clearTagFilterByName(index: number): void {
      this.searchFields.splice(index, 1);
      if (this.searchFields.length === 0) {
         this.selectedCycleCountTaskFilterType = null;
      }

      this.buildTasks();
   }
   public clearTagFilter(): void {
      this.tagFilter = false;
      this.buildTasks();
   }

   public bulkPrintTasks(): void {
      const tasksToPrint = this.manageFilters.filterTasksToSearch(
         this.tasks,
         this.searchBar,
         this.manageTask,
         this.manageLocation,
         this.manageUser,
         this.manageUtil,
         this.manageAsset,
         this.manageInvoice,
         this.manageParts,
         this.manageSchedule,
      ).tasks;

      this.bulkTasks.bulkPrint(tasksToPrint);
   }

   public startWorkOrder(date): void {
      const pushTaskExtraInfo = (
         data1: any,
         data2: { task: { checklistID: number } },
      ): void => {
         const taskExtraInfo = this.tasksExtraInfo.get(data2.task.checklistID);
         if (taskExtraInfo) {
            taskExtraInfo.selected = !taskExtraInfo.selected;
            taskExtraInfo.taskData.push({
               checklistID: data2.task.checklistID,
               mode: "instance",
               editable: true,
               expand: false,
            });
         }
      };

      const allowPickLocation = true;
      this.startWOService.startWorkOrder(
         date,
         undefined,
         {
            callback: pushTaskExtraInfo.bind(this),
         },
         allowPickLocation,
      );
   }

   private subscribeToAlertLinkClick() {
      this.alertLinkClickSub$ = this.alertService
         .getAlertLinkClicked()
         .subscribe((link) => {
            if (link) {
               this.openNewlyCreatedTask(link);
            }
         });
   }

   protected openNewlyCreatedTask(taskID: string): void {
      const checklistID = (taskID as unknown as number) ?? undefined;
      const instancePT = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instancePT,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: true,
            },
         },
      };
   }
}
