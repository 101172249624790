<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title()" (closeModal)="dismissModal()" />
   @if (actionType() === "copy" && assetTemplatesRequired && isAssetTemplatesEnabled()) {
      <lim-ui-alert alertType="info" class="copy-assets-alert"
         ><p>
            {{ lang().NewFeatureAssetTemplates }}
            <span> {{ lang().EarlyAccessTemplateAssetsMessage2 }} </span>
            <a (click)="goToHelpCenter()"
               >{{ lang().HelpCenter }} <lim-ui-icon icon="arrowUpRightFromSquare"
            /></a>
            {{ lang().ToLearnMore }}
         </p></lim-ui-alert
      >
   }

   <lim-ui-modal-body>
      <lim-ui-panel>
         @if (showUndoneWarning()) {
            <div class="warning">
               {{ lang().ThisActionCannotBeUndone }}
            </div>
         }
         <div [limbleHtml]="message()" class="message"></div>
         @if (data().length) {
            <hierarchy-container-legacy
               [treeData]="data()"
               [options]="hierarchyOptions"
            />
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (
         actionType() === "copy" &&
         assetTemplatesRequired &&
         assetFamilyHasTemplate === false &&
         isAssetTemplatesEnabled()
      ) {
         <lim-ui-primary-button
            [disabled]="true"
            [classNames]="buttonActionAll()?.color ?? ''"
         >
            {{ buttonActionAll()?.text }}
         </lim-ui-primary-button>
      } @else {
         <lim-ui-primary-button
            [disabled]="false"
            [classNames]="buttonActionAll()?.color ?? ''"
            (click)="actionWithParentAndChildren()"
            [dataLogLabel]="
               'manageAssets-store' +
               dataLogWorkflowLabel() +
               '-click' +
               buttonActionAll()?.dataLogLabel
            "
         >
            {{ buttonActionAll()?.text }}
         </lim-ui-primary-button>
      }
      <lim-ui-secondary-button
         [classNames]="buttonActionTopLevel()?.color ?? ''"
         (click)="actionWithParentOnly()"
         [dataLogLabel]="
            'manageAssets-store' +
            dataLogWorkflowLabel() +
            '-click' +
            buttonActionTopLevel()?.dataLogLabel
         "
      >
         {{ buttonActionTopLevel()?.text }}
      </lim-ui-secondary-button>
      <lim-ui-secondary-button
         (click)="dismissModal()"
         [dataLogLabel]="'manageAssets-store' + dataLogWorkflowLabel() + '-clickCancel'"
      >
         {{ lang().Cancel }}
      </lim-ui-secondary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
