import { Component, effect, input, model } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@Component({
   selector: "text-field-filter",
   standalone: true,
   imports: [ReactiveFormsModule, FormsModule],
   templateUrl: "./text-field-filter.component.html",
})
export class TextFieldFilterComponent {
   public initialValue = input<string | undefined>("");

   public readonly filterText = model("");

   public constructor() {
      effect(() => {
         const value = this.initialValue();
         if (value?.length) {
            this.onFilterTextChanged(value);
         }
      });
   }

   public onFilterTextChanged(filterText: string) {
      this.filterText.set(filterText);
   }
}
