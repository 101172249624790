<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="instructions">
            {{ lang().SelectASensorBelowAndItWillAutomaticallyUpdateTheField }}
            {{ lang().ThisWillAllowYouToAutomaticallyCreatePMsSendEmailsEtc }}
         </div>
         @if (loading) {
            <div class="loading">
               <lim-ui-loading-animation />
            </div>
         }
         @for (sensor of sensorList | orderBy: "SensorID"; track sensor) {
            <div>
               <div
                  class="lim-ui-list-item lim-ui-selectable-list-item"
                  [ngClass]="{
                     'lim-ui-selected-list-item': selectedSensor === sensor.SensorID,
                  }"
                  (click)="focusSensor(sensor.SensorID)"
               >
                  <div class="lim-ui-list-item-content">
                     {{ sensor.SensorName }}
                  </div>
               </div>
               @if (selectedSensor === sensor.SensorID) {
                  <div>
                     @if (dataPoints.length < 1) {
                        <div class="loading">
                           <lim-ui-loading-animation />
                        </div>
                     }
                     @if (dataPoints.length > 0) {
                        <div class="select-sensor-reading">
                           <div>
                              <div class="last-reading-label">
                                 {{ lang().SensorLastReading }}
                              </div>
                              <div>{{ sensor.DisplayData }}</div>
                           </div>
                           <div>{{ lang().BasedOnTheSensorsLastReadingPleasePick }}</div>
                           <div>
                              @for (pointName of dataPoints; track pointName) {
                                 <div
                                    class="lim-ui-list-item lim-ui-selectable-list-item"
                                    [ngClass]="{
                                       'lim-ui-selected-list-item':
                                          selectedPoint === $index,
                                    }"
                                    (click)="focusPoint($index)"
                                 >
                                    @if (pointName) {
                                       <div class="lim-ui-list-item-content">
                                          Label: {{ pointName }}
                                       </div>
                                    }
                                    @if (units[$index]) {
                                       <div class="lim-ui-list-item-content">
                                          DataType: {{ units[$index] }}
                                       </div>
                                    }
                                    @if (newValues[$index]) {
                                       <div class="lim-ui-list-item-content">
                                          Last Value: {{ newValues[$index] }}
                                       </div>
                                    }
                                 </div>
                              }
                           </div>
                        </div>
                     }
                  </div>
               }
            </div>
         }
         @if (errorMsg !== "") {
            <lim-ui-alert
               [title]="lang().CannotAttachSensor + ' - ' + errorMsg"
               alertType="danger"
            />
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   @if (!loading) {
      <lim-ui-modal-footer>
         <lim-ui-primary-button (click)="submit()">
            {{ lang().Next }}
         </lim-ui-primary-button>
         <lim-ui-secondary-button (click)="close()">
            {{ lang().Close }}
         </lim-ui-secondary-button>
      </lim-ui-modal-footer>
   }
</lim-ui-modal>
