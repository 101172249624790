import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { resolvePromisesMap } from "src/app/shared/utils/app.util";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ManageProfile {
   private readonly users: any = {};
   private readonly userProfiles: any = {};
   private readonly profiles: any = {};
   private readonly axios = axios;

   private readonly manageUser = inject(ManageUser);
   private readonly manageTask = inject(ManageTask);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);

   public constructor() {
      this.users.arr = [];
      this.users.index = {};

      this.userProfiles.arr = [];

      this.profiles.arr = [];
      this.profiles.index = {};
      this.createObservables();
   }

   createObservables = () => {
      this.manageObservables.createObservable(
         "profileUserProfiles",
         this.userProfiles.arr,
      );
      this.manageObservables.createObservable("profiles", this.profiles.arr);
      this.manageObservables.createObservable("profileUsers", this.users.arr);
   };

   sortData = () => {
      for (const profile of this.profiles.arr) {
         profile.credsByCredID = {};

         profile.creds = profile.creds || [];
         for (const cred of profile.creds) {
            profile.credsByCredID[cred.credID] = cred;
         }
         this.profiles.index[profile.profile.profileID] = profile;
      }
      this.manageObservables.updateObservable("profiles", 1);
   };
   getData = async (locationID) => {
      const startTime = Math.floor(Date.now());

      const promises = new Map([
         [
            "usersForProfilesGetData",
            axios.get(`${environment.flannelUrl}/users/usersForProfilesGetData`, {
               params: { locationID: locationID },
            }),
         ],
         [
            "userProfileRelationsForProfilesGetData",
            axios.get(
               `${environment.flannelUrl}/profiles/userProfileRelationsForProfilesGetData`,
               {
                  params: { locationID: locationID },
               },
            ),
         ],
         [
            "profilesWithCredsForGetDataProfiles",
            axios.get(
               `${environment.flannelUrl}/profiles/profilesWithCredsForGetDataProfiles`,
               {
                  params: { locationID: locationID },
               },
            ),
         ],
      ]);

      const responses = await resolvePromisesMap(promises);
      logApiPerformance("profilesInfo", startTime, this.manageUser.getCurrentUser());

      const entries = [...responses.entries()].map((entry) => {
         return [entry[0], entry[1].data];
      });
      const unifiedResponse = Object.fromEntries(entries);

      this.users.arr = unifiedResponse.usersForProfilesGetData || [];
      for (const user of this.users.arr) {
         this.users.index[user.userID] = user;
      }

      this.userProfiles.arr =
         unifiedResponse.userProfileRelationsForProfilesGetData || [];

      this.profiles.arr = unifiedResponse.profilesWithCredsForGetDataProfiles || [];

      this.manageObservables.updateObservable("profileUsers", 1);
      this.manageObservables.updateObservable("profileUserProfiles", 1);

      this.sortData();
   };
   clearData = () => {
      this.users.arr = [];
      this.users.index = {};
      this.manageObservables.updateObservable("profileUsers", 1);

      this.userProfiles.arr = [];
      this.manageObservables.updateObservable("profileUserProfiles", 1);

      this.profiles.arr = [];
      this.manageObservables.updateObservable("profiles", 1);
      this.profiles.index = {};
   };
   getUsers = () => {
      return this.users.arr;
      //return users.index;
   };
   getUserProfiles = () => {
      return this.userProfiles.arr;
      //return locations.index;
   };

   getProfiles = () => {
      const temp: any = [];
      for (const profile of this.profiles.arr) {
         //we don't want hidden profiles to show up visibly throughout the tool.  They still need to exist in the index though so this is a method to make that happen - Bryan
         if (profile.profile.profileHidden == 0) {
            temp.push(profile);
         }
      }
      return temp;
   };
   getProfilesIndex = () => {
      return this.profiles.index;
   };

   public getProfileByParentID(profileParent) {
      return this.profiles.arr.find(
         (profile) => Number(profile.profile.profileParent) === Number(profileParent),
      );
   }

   /****************************************
    *@function addHiddenProfileToFlatDataIfNeeded
    *@purpose Adds a profile to the data set if needed (helps prevent extra posts)
    *@name addHiddenProfileToFlatDataIfNeeded
    *@param
    *@return
    ****************************************/

   addHiddenProfileToFlatDataIfNeeded = (profileID, name, locationID) => {
      if (profileID > 0 && this.profiles.index[profileID] === undefined) {
         //we made a hidden profile on the fly so we need to now update it.
         const obj: any = {};
         obj.profileID = profileID;
         obj.profileDescription = name;
         obj.profileDeleted = 0;
         obj.locationID = locationID;
         obj.profileHidden = 1;
         this.profiles.arr.push(obj);
         const newIndex = this.profiles.arr.length - 1;
         this.profiles.index[profileID] = this.profiles.arr[newIndex];
         this.manageObservables.updateObservable("profiles", 1);
      }
   };

   /****************************************
    *@function addProfileToUser
    *@purpose add a profile to a certain user at a certain location
    *@name addProfileToUser
    *@param
    *@return
    ****************************************/
   addProfileToUser = async (userID, locationID, profileID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "addProfileToUser",
         },
         data: {
            userID: userID,
            locationID: locationID,
            profileID: profileID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.userProfiles.arr.push(answer.data.row);

            //update the data source in manageUser
            const userProfilesObj = this.manageUser.getUserProfilesObj();
            userProfilesObj.arr.push(answer.data.row);
            const newIndex = userProfilesObj.arr.length - 1;
            userProfilesObj.index[answer.data.row.securityID] =
               userProfilesObj.arr[newIndex];
            this.manageObservables.updateObservable("userProfiles", 1);
            this.manageObservables.updateObservable("profileUserProfiles", 1);
         }
      });

      return post;
   };
   /****************************************
    *@function removeProfileFromUser
    *@purpose removes a profile from a certain user at a certain location
    *@name removeProfileFromUser
    *@param
    *@return
    ****************************************/
   removeProfileFromUser = async (userID, profileID, locationID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "removeProfileFromUser",
         },
         data: {
            userID: userID,
            locationID: locationID,
            profileID: profileID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            for (const [index, userProfile] of this.userProfiles.arr.entries()) {
               if (userProfile.profileID == profileID && userProfile.userID == userID) {
                  this.userProfiles.arr.splice(index, 1);
               }
            }

            //update the data source in manageUser
            const userProfilesObj = this.manageUser.getUserProfilesObj();
            for (const [index, userProfile] of userProfilesObj.arr.entries()) {
               if (userProfile.profileID == profileID && userProfile.userID == userID) {
                  userProfilesObj.arr.splice(index, 1);
               }
            }

            this.manageObservables.updateObservable("userProfiles", 1);
            this.manageObservables.updateObservable("profileUserProfiles", 1);
         }
      });

      return post;
   };
   /****************************************
    *@function updateCredStatus
    *@purpose updates a cred to the right status
    *@name updateCredStatus
    *@param
    *@return
    ****************************************/
   updateCredStatus = async (status, profile_credID, type) => {
      const credID = profile_credID;
      const is = status;
      let changeTo;

      //This is a screwed up way to do it, but I really don't want to rewrite the PHP code so alas
      //this is the way I am doing it.  If I could go back in time and slap myself I would!
      if (type === "havePriv") {
         if (is == 0) {
            changeTo = 1;
         } else {
            changeTo = 0;
         }
      } else if (is == 0) {
         changeTo = 2;
      } else if (is == 1) {
         changeTo = 2;
      } else {
         changeTo = 0;
      }

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "updateCred",
         },
         data: {
            credID: credID,
            changeTo: changeTo,
            is: is,
         },
      });
      post.then((answer) => {
         if (answer.data.success == true) {
            for (const profile of this.profiles.arr) {
               for (const cred of profile.creds) {
                  if (cred.profile_credID == credID) {
                     cred.profile_credStatus = changeTo;
                  }
               }
            }
            this.manageObservables.updateObservable("profiles", 1);
         }
      });

      return post;
   };
   /****************************************
    *@function updateProfileName
    *@purpose updates a profiles name
    *@name updateProfileName
    *@param
    *@return
    ****************************************/
   updateProfileName = async (description, profileID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "updateProfileName",
         },
         data: {
            description: description,
            profileID: profileID,
         },
      });

      post.then(() => {
         //the next 8 lines of code is a work around to update the information held in manageUser.
         //This is a horrible way of doing it, but sadly I don't have the time to rewrite it all and this works.
         const profilesObjInUser = this.manageUser.getProfilesObj();
         profilesObjInUser.index[profileID].profileDescription = description;

         //this updates the storage we use in manage Task

         const taskProfile = this.manageTask.getTaskProfile(profileID);
         if (taskProfile === undefined) return;
         taskProfile.profileDescription = description;
      });

      return post;
   };
   /****************************************
    *@function updateRoleName
    *@purpose updates a roles name
    *@name updateRoleName
    *@param
    *@return
    ****************************************/
   updateRoleName = async (description, profileID) => {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "updateRoleName",
         },
         data: {
            description: description,
            profileID: profileID,
         },
      });
   };
   /****************************************
    *@function createCustomProfile
    *@purpose creates a custom profile for that location
    *@name createCustomProfile
    *@param
    *@return
    ****************************************/
   createCustomProfile = async (locationID) => {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "createCustomProfile",
         },
         data: {
            locationID: locationID,
         },
      }).then((answer) => {
         answer.data.profiles.newProfile = true;

         this.profiles.arr.push(answer.data.profiles);
         const newIndex = this.profiles.arr.length - 1;
         this.profiles.index[answer.data.profiles.profile.profileID] =
            this.profiles.arr[newIndex];
         const profile = this.profiles.arr[newIndex];
         const profileID = profile.profileID;

         const newProfile = answer.data.profileNew;

         //the next 8 lines of code is a work around to update the information held in manageUser.
         //This is a horrible way of doing it, but sadly I don't have the time to rewrite it all and this works.
         const profilesObjInUser = this.manageUser.getProfilesObj();
         profilesObjInUser.arr.push(newProfile);
         const newIndex2 = profilesObjInUser.arr.length - 1;
         profilesObjInUser.index[newProfile.profileID] = profilesObjInUser.arr[newIndex2];

         //this updates the storage we use in manage Task

         const taskProfiles = this.manageTask.getAllProfiles();
         taskProfiles.set(newProfile.profileID, newProfile);
         this.manageObservables.updateObservable("profiles", 1);

         return profileID;
      });
   };
   /****************************************
    *@function getProfileEmails
    *@purpose gets the list of users and their emails assocated with a given profile
    *@name getProfileEmails
    *@param
    *@return
    ****************************************/
   getProfileEmails = async (profileID, locationID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "getProfileEmails",
         },
         data: {
            profileID: profileID,
            locationID: locationID,
         },
      });

      return post;
   };
   /****************************************
    *@function getProfileDetailUnsecure
    *@purpose gets the details for a given profile  This should only be coupled with none editable areas.
    *@name getProfileDetailUnsecure
    *@param
    *@return
    ****************************************/
   getProfileDetailUnsecure = async (profileID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "getProfileDetailUnsecure",
         },
         data: {
            profileID: profileID,
         },
      });

      return post;
   };
   /****************************************
    *@function deleteProfile
    *@purpose
    *@name deleteProfile
    *@param
    *@return
    ****************************************/
   deleteProfile = async (profile) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "deleteProfile",
         },
         data: {
            profileID: profile.profileID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            //the next 4 lines of code is a work around to update the information held in manageUser.
            //This is a horrible way of doing it, but sadly I don't have the time to rewrite it all and this works.
            const profilesObj = this.manageUser.getProfilesObj();

            for (const [index, profile2] of profilesObj.arr.entries()) {
               if (profile2.profileID == profile.profileID) {
                  profilesObj.arr.splice(index, 1);
               }
            }

            //this is why we shouldn't have data exist in two locations... sigh if I could smack my 2 year ago self.
            for (const [index, profile2] of this.profiles.arr.entries()) {
               if (profile2?.profile?.profileID == profile.profileID) {
                  this.profiles.arr.splice(index, 1);
               }
            }
            this.manageObservables.updateObservable("profiles", 1);
         }
      });

      return post;
   };
   /****************************************
    *@function deleteRole
    *@purpose
    *@name deleteRole
    *@param
    *@return
    ****************************************/
   deleteRole = async (profile) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "deleteRole",
         },
         data: {
            profileID: profile.profileID,
         },
      });

      return post;
   };
   /****************************************
    *@function createRole
    *@purpose
    *@name createRole
    *@param description
    *@return
    ****************************************/
   createRole = async (description?: string) => {
      const data = description ? { description: description } : {};
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "createRole",
         },
         data: data,
      });

      return post;
   };
   /****************************************
    *@function duplicateRole
    *@purpose
    *@name duplicateRole
    *@param
    *@return
    ****************************************/
   duplicateRole = async (duplicatedProfileID, duplicatedProfileDescription) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "duplicateRole",
         },
         data: {
            duplicatedProfileID: duplicatedProfileID,
            duplicatedProfileDescription: duplicatedProfileDescription,
         },
      });

      return post;
   };
   /****************************************
    *@function resetRole
    *@purpose resets the current profile back to the permissions settings of the original default profile
    *@name resetRole
    *@param
    *@return
    ****************************************/
   resetRole = async (currentProfileId, defaultProfileId) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "resetRole",
         },
         data: {
            currentProfileId: currentProfileId,
            defaultProfileId: defaultProfileId,
         },
      });

      return post;
   };
   /****************************************
    *@function setMinimumRole
    *@purpose
    *@name setMinimumRole
    *@param
    *@return
    ****************************************/
   setMinimumRole = async (profile) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "setMinimumRole",
         },
         data: {
            profileID: profile.profileID,
            minimumRole: profile.minimumRole,
         },
      });

      return post;
   };

   getOrCreateHiddenProfileAndDescription = async (
      multiUsersArray: Array<number>,
      locationID: number,
   ): Promise<false | AxiosResponse<any>> => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageCred.php",
         params: {
            action: "getOrCreateHiddenProfileAndDescription",
         },
         data: {
            multiUsersArray: multiUsersArray,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return false;
      }
      this.manageFilters.updateHiddenProfiles(
         {
            profileID: post.data.profileID,
            name: post.data.profileDescription,
            locationID: locationID,
            multiUsers: multiUsersArray,
         },
         this.manageTask,
         this.manageUser,
         this,
      );

      return post;
   };
}
