<ng-container *translate="let t">
   <lim-ui-dropdown-button [label]="t(filter().labelKey) || ''" filterDropdown>
      @if ((postLogActions()?.length ?? 0) > 5) {
         <div header>
            <lim-ui-search-box
               [placeholder]="t('Search')"
               [searchVal]="searchPostLogActions()"
               (searchValChange)="handleSearch($event)"
               [useDropdownStyling]="true"
            />
            <lim-ui-dropdown-divider />
         </div>
      }
      <div menu>
         @for (action of postLogActionsDropdown(); track action) {
            <lim-ui-dropdown-text-item
               displayString="{{ action }}"
               (click)="handleSetAction(action)"
               [dataLogLabel]="filter().dataLogLabel"
            />
         }
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-clear-filter-item (click)="handleClear()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
