@let $purchaseOrderItem = purchaseOrderItem();
@let $purchasableSnapshot = purchasableSnapshot();
@if ($purchaseOrderItem !== null && $purchasableSnapshot !== null) {
   <div class="listItem">
      <div class="po-item-row">
         <div class="col gl-col">
            <span class="col-label">{{ lang().GL }}</span>
            <div class="col-value">
               <lim-ui-dropdown
                  limUiTooltip="{{ generalLedgerTooltip }}"
                  [disabled]="!featureGeneralLedger"
               >
                  <div button [ngClass]="{ 'grey-out': !featureGeneralLedger }">
                     @if ($purchaseOrderItem.glID === 0 || !featureGeneralLedger) {
                        <lim-ui-minimal-icon-button
                           icon="plus"
                           [superMinimal]="true"
                           [limUiPopover]="startPopover"
                           [limUiPopoverType]="'upsell'"
                           [limUiPopoverPlacement]="'right'"
                           [limUiPopoverHidden]="featureGeneralLedger"
                        />
                     }
                     <ng-template #startPopover>
                        <upsell-popover
                           [message]="lang().FeatureGeneralLedgerPopoverMessage"
                           [pricingLinkText]="lang().LearnMore"
                        />
                     </ng-template>
                     @if (
                        $purchaseOrderItem.glID !== 0 &&
                        CID !== 1990 &&
                        featureGeneralLedger
                     ) {
                        <span class="edit-gl">{{ generalLedgerAbbreviation }}</span>
                     }
                     @if ($purchaseOrderItem.glID !== 0 && CID === 1990) {
                        <span class="edit-gl">{{ generalLedgerName }}</span>
                     }
                  </div>
                  <div menu>
                     <lim-ui-search-box
                        [useDropdownStyling]="true"
                        [(searchVal)]="searchLedger"
                        (searchValChange)="searchGeneralLedgers()"
                        [placeholder]="lang().Search"
                     />
                     @for (ledger of filteredGeneralLedgers; track ledger) {
                        <lim-ui-dropdown-item (click)="setGeneralLedger(ledger.glID)">
                           <span class="custom-ledger-item">
                              <span> {{ ledger.abbr }} - {{ ledger.name }} </span>
                              <lim-ui-icon
                                 class="settings-icon"
                                 icon="gear"
                                 iconSize="small"
                                 (click)="
                                    setupGeneralLedger(ledger); $event.stopPropagation()
                                 "
                              />
                           </span>
                        </lim-ui-dropdown-item>
                     }
                     <lim-ui-dropdown-text-item
                        displayString="{{ lang().NoGL }}"
                        outputString="nogl"
                        (itemSelectedEvent)="setGeneralLedger(0)"
                     />
                     <lim-ui-dropdown-text-item
                        displayString="{{ lang().AddGeneralLedger }}"
                        outputString="addgl"
                        (itemSelectedEvent)="setupGeneralLedger(false)"
                     />
                  </div>
               </lim-ui-dropdown>
            </div>
         </div>

         <div class="col item-col">
            <span class="col-label">{{ lang().Item }}</span>
            <div class="col-value">
               <div class="select-item" id="po-item-{{ $purchaseOrderItem.poItemID }}">
                  <div
                     (click)="setProdService($purchaseOrderItem.poItemID, true)"
                     id="po-item-{{ $purchaseOrderItem.poItemID }}"
                  >
                     @if (
                        $purchaseOrderItem.partID !== null &&
                        $purchaseOrderItem.partID > 0
                     ) {
                        <lim-ui-minimal-icon-button
                           (click)="
                              popPart($purchaseOrderItem.partID); $event.stopPropagation()
                           "
                           icon="gears"
                           [superMinimal]="true"
                        />
                     }
                     @if ($purchaseOrderItem.itemType === 2) {
                        <lim-ui-minimal-icon-button
                           icon="wpforms"
                           [superMinimal]="true"
                        />
                     }
                     @if ($purchaseOrderItem.itemType === 4) {
                        <lim-ui-minimal-icon-button
                           icon="superpowers"
                           [superMinimal]="true"
                        />
                     }
                     <span [limbleHtml]="itemRelatedInfo?.itemName"></span>
                     @if (itemRelatedInfo?.itemNumber?.length) {
                        <span>
                           •
                           <span [limbleHtml]="itemRelatedInfo?.itemNumber"></span>
                        </span>
                     }
                  </div>
               </div>
               @if ($purchasableSnapshot !== undefined) {
                  @if (!editable || !(checkCred(151) || editableWithoutCred)) {
                     <lim-ui-form-dropdown-input
                        class="disabled-purchasable-dropdown-input"
                        [disabled]="true"
                        [inputString]="$purchasableSnapshot.name"
                     />
                  } @else {
                     <lim-ui-dropdown>
                        <div
                           button
                           class="select-item part-purchasable-dropdown"
                           (click)="
                              filteredPurchasables.length === 0
                                 ? fetchPartPurchasables()
                                 : null
                           "
                        >
                           <span [limbleHtml]="$purchasableSnapshot.name"></span>
                           <lim-ui-icon icon="chevronDown" />
                        </div>
                        <ng-container menu>
                           @if (partPurchasables().length >= 4) {
                              <lim-ui-search-box
                                 [useDropdownStyling]="true"
                                 [(searchVal)]="searchPurchasable"
                                 (searchValChange)="searchPurchasables()"
                                 [placeholder]="lang().Search"
                              />
                           }

                           @if (
                              !searchPurchasable ||
                              filteredSingleUnitMatch ||
                              partPurchasables().length < 4
                           ) {
                              <lim-ui-dropdown-text-item
                                 dataLogLabel="manageUnitsOfMeasure_changePOItemPurchasable"
                                 (click)="setPurchasableWithItemAndFocus(undefined)"
                                 [displayString]="
                                    $purchasableSnapshot.stockUnitDisplay.name
                                 "
                              />
                           }

                           @for (
                              purchasable of filteredPurchasables;
                              track purchasable.id
                           ) {
                              <lim-ui-dropdown-text-item
                                 dataLogLabel="manageUnitsOfMeasure_changePOItemPurchasable"
                                 (click)="setPurchasableWithItemAndFocus(purchasable)"
                                 [displayString]="purchasable.name()"
                              />
                           }

                           @if (
                              searchPurchasable &&
                              filteredPurchasables.length === 0 &&
                              !filteredSingleUnitMatch
                           ) {
                              <lim-ui-dropdown-text-item
                                 [displayString]="lang().NoMatchingResultsFound"
                              />
                           }

                           @if (canCreatePurchasables()) {
                              <lim-ui-dropdown-item (click)="addAPurchasableToItem()">
                                 <lim-ui-icon icon="plus" [iconColor]="'blue'" />
                                 <lim-ui-text-button [text]="lang().AddAPurchasable" />
                              </lim-ui-dropdown-item>
                           }
                        </ng-container>
                     </lim-ui-dropdown>
                  }
               }
            </div>
         </div>
         <div class="col desc-col">
            <span class="col-label">{{ lang().Description }}</span>
            <div class="col-value">
               <div
                  type="text"
                  [(ngModel)]="$purchaseOrderItem.description"
                  [limbleContentEditable]="
                     editable && (checkCred(151) || editableWithoutCred)
                  "
                  (afterEdit)="setPOItemDescription()"
                  tabindex="{{ $purchaseOrderItem.poItemID + '1' }}"
               ></div>
            </div>
         </div>
         <div class="col amount-col">
            <span class="col-label">{{ lang().Qty }}</span>
            <div class="col-value">
               <input
                  #poItemQuantityInput
                  type="number"
                  [(ngModel)]="$purchaseOrderItem.qty"
                  [disabled]="!editable || !(checkCred(151) || editableWithoutCred)"
                  (blur)="setPOItemQty()"
                  onclick="this.focus();document.execCommand('selectAll', false, null);"
                  min="0"
                  tabindex="{{ $purchaseOrderItem.poItemID + '2' }}"
                  class="heap-tracking-qty-input"
               />
               @if ($purchasableSnapshot !== undefined) {
                  <div class="purchasable-conversion">
                     <unit-label [unitDisplay]="$purchasableSnapshot.orderUnitDisplay" />
                     @if (
                        $purchasableSnapshot.orderUnitDisplay.name !==
                        $purchasableSnapshot.stockUnitDisplay.name
                     ) {
                        <div>
                           <unit-conversion
                              [stockUnits]="$purchasableSnapshot.partUnits"
                              [unitDisplay]="{
                                 name: $purchasableSnapshot.stockUnitDisplay.name,
                                 abbreviation:
                                    $purchasableSnapshot.stockUnitDisplay.abbreviation,
                              }"
                              [enclosed]="true"
                           />
                        </div>
                     }
                  </div>
               }
            </div>
         </div>

         <div class="col">
            <span class="col-label">{{ lang().Rate }}</span>
            <div class="col-value">
               <div class="lim-ui-input-icon-container">
                  @if (currencyCode()) {
                     <lim-ui-input-with-prefix
                        [prefixText]="currencyCode() | currencySymbol"
                        [value]="$purchaseOrderItem.rate"
                        (valueChange)="setPOItemRate($event)"
                        [disabled]="!editable || !(checkCred(151) || editableWithoutCred)"
                        [min]="0"
                        tabIndexInput="{{ $purchaseOrderItem.poItemID + '3' }}"
                        classNames="heap-tracking-rate-input"
                     />
                  } @else {
                     <lim-ui-input-with-prefix
                        [prefixText]="currencySymbol"
                        [value]="$purchaseOrderItem.rate"
                        (valueChange)="setPOItemRate($event)"
                        [disabled]="!editable || !(checkCred(151) || editableWithoutCred)"
                        [min]="0"
                        tabIndexInput="{{ $purchaseOrderItem.poItemID + '3' }}"
                        classNames="heap-tracking-rate-input"
                     />
                  }
               </div>
            </div>
         </div>

         <div class="col amount-col">
            <span class="col-label">{{ lang().Tax }}</span>
            <div class="col-value">
               <div class="lim-ui-input-icon-container">
                  <input
                     type="number"
                     [ngClass]="{ 'red-border': taxWarning }"
                     [(ngModel)]="$purchaseOrderItem.tax"
                     [disabled]="!editable || !(checkCred(151) || editableWithoutCred)"
                     (blur)="setPOItemTax()"
                     onclick="this.focus();document.execCommand('selectAll', false, null);"
                     tabindex="{{ $purchaseOrderItem.poItemID + '4' }}"
                     class="heap-tracking-tax-input"
                  />
                  <span>%</span>
               </div>
            </div>
         </div>

         <div class="col amount-col">
            <span class="col-label">{{ lang().Discount }}</span>
            <div class="col-value">
               <div class="lim-ui-input-icon-container">
                  <input
                     type="number"
                     [ngClass]="{ 'red-border': discountWarning }"
                     [(ngModel)]="$purchaseOrderItem.discount"
                     [disabled]="!editable || !(checkCred(151) || editableWithoutCred)"
                     (blur)="setPOItemDiscount()"
                     onclick="this.focus();document.execCommand('selectAll', false, null);"
                     tabindex="{{ $purchaseOrderItem.poItemID + '5' }}"
                     class="heap-tracking-discount-input"
                  />
                  <span>%</span>
               </div>
            </div>
         </div>

         <div class="col">
            <span class="col-label">{{ lang().Shipping }}</span>
            <div class="col-value">
               <div class="lim-ui-input-icon-container">
                  <span [limbleHtml]="currencySymbol"></span>

                  @if (currencyCode()) {
                     <lim-ui-input-with-prefix
                        [prefixText]="currencyCode() | currencySymbol"
                        [value]="$purchaseOrderItem.shipping"
                        [disabled]="!editable || !(checkCred(151) || editableWithoutCred)"
                        (valueChange)="setPOItemShipping($event)"
                        [min]="0"
                        tabIndexInput="{{ $purchaseOrderItem.poItemID + '6' }}"
                        classNames="heap-tracking-shipping-input"
                     />
                  } @else {
                     <lim-ui-input-with-prefix
                        [prefixText]="currencySymbol"
                        [value]="$purchaseOrderItem.shipping"
                        [disabled]="!editable || !(checkCred(151) || editableWithoutCred)"
                        (valueChange)="setPOItemShipping($event)"
                        [min]="0"
                        tabIndexInput="{{ $purchaseOrderItem.poItemID + '6' }}"
                        classNames="heap-tracking-shipping-input"
                     />
                  }
               </div>
            </div>
         </div>

         <div class="col total-col">
            <span class="col-label">{{ lang().Total }}</span>
            <div class="col-value">
               @if (currencyCode()) {
                  <span [limUiTooltip]="currencyCode() | currencyName">
                     {{ total | localeCurrency: currencyCode() }}
                  </span>
               } @else {
                  <span>
                     {{ total | betterCurrency }}
                  </span>
               }
            </div>
         </div>

         <div class="col received-col">
            <span class="col-label">{{ lang().Received }}</span>
            <div class="col-value">
               <span>
                  {{ itemReceivedInfo?.receivedQty }} {{ lang().ofStr }}
                  {{ $purchaseOrderItem.qty }}
                  @if ($purchasableSnapshot !== undefined) {
                     <unit-label [unitDisplay]="$purchasableSnapshot.orderUnitDisplay" />
                  }
               </span>
               @if (
                  $purchaseOrderItem.transactionIDs &&
                  $purchaseOrderItem.transactionIDs.length > 0
               ) {
                  <ul class="receive-transactions">
                     @for (
                        transactionID of $purchaseOrderItem.transactionIDs;
                        track transactionID
                     ) {
                        @if (transactions?.get(transactionID); as transaction) {
                           <li (click)="popPR(transactionID)">
                              {{ transaction.qtyReceived | betterNumber: "1.0-3" }}
                              @if ($purchasableSnapshot !== undefined) {
                                 <unit-label
                                    [unitDisplay]="$purchasableSnapshot.orderUnitDisplay"
                                 />
                              }
                              {{ lang().onStr }}
                              @if (transaction.dateReceived !== null) {
                                 <a class="link-received">{{
                                    transaction.dateReceived * 1000 | betterDate: "date"
                                 }}</a>
                              }
                           </li>
                        }
                     }
                  </ul>
               }
            </div>
         </div>
         <div class="col delete-col">
            <div class="col-value">
               <lim-ui-minimal-icon-button
                  (click)="deletePOItem()"
                  icon="trashCanRegular"
                  iconColor="danger"
                  size="small"
                  [hidden]="!editable"
               />
            </div>
         </div>
      </div>
      @if (purchaseOrder && purchaseOrder.state !== null) {
         <po-item-tasks-hint
            [poItemID]="$purchaseOrderItem.poItemID"
            [other]="true"
            [state]="purchaseOrder?.state"
         />
      }
   </div>
}
