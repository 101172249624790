<lim-ui-panel class="lim-ui-fonts-body" id="assetReportsPrint{{ asset?.assetID }}">
   <header class="report-metrics-header">
      <div class="filter-selection-bar">
         <div>
            <lim-ui-icon icon="filter" iconSize="small" />
            {{ lang().Filter }}:
         </div>
         <lim-ui-dropdown-button
            label="{{ filterTimeStr }}"
            limUiTooltip="{{ lang().FilterThisAssetReportsToTimePeriodTooltip }}"
         >
            <div menu>
               @for (filter of dayFilters; track filter) {
                  <lim-ui-dropdown-text-item
                     displayString="{{ filter.display }}"
                     outputString="filter.days"
                     (itemSelectedEvent)="setDaysFilter(filter.days, filter.display)"
                  />
               }
               <lim-ui-dropdown-divider />
               <lim-ui-dropdown-date-range-picker
                  sectionLabel="{{ lang().DateRange }}"
                  [(startDate)]="dueDate1"
                  startDateFieldLabel="{{ lang().From }}"
                  [(endDate)]="dueDate2"
                  endDateFieldLabel="{{ lang().To }}"
                  setButtonLabel="{{ lang().Set }}"
                  (setButtonClicked)="setDateRange()"
               />
            </div>
         </lim-ui-dropdown-button>
      </div>
      <div>
         @if (asset && asset.assetChildrenIDs.length > 0) {
            <lim-ui-checkbox
               limUiTooltip="{{ lang().IncludeChildrenDataTooltip }}"
               [(model)]="asset.includeChildData"
               [disabled]="restrict || !creds?.editAssetSettings"
               label="{{ lang().IncludeChildrenData }}"
               (modelChange)="updateIncludeChildData()"
            />
         }
         <lim-ui-icon
            [hidden]="
               !(
                  asset &&
                  asset.assetChildrenIDs.length > 0 &&
                  creds &&
                  creds.viewManageAssets &&
                  !creds.editAssetSettings
               )
            "
            icon="circleQuestionRegular"
            limUiTooltip="{{ lang().IncludeChildrenDataCredTooltip }}"
         />
      </div>
   </header>

   <section class="metrics">
      <lim-ui-card
         [clickableStyling]="true"
         (click)="showCompletedPreventiveWorkOrders()"
      >
         <h3>
            {{ totalPMs }}
         </h3>
         <p>
            {{ lang().CompletedPlannedWorkAssetReports }}
         </p>
      </lim-ui-card>
      <lim-ui-card [clickableStyling]="true" (click)="showCompletedWorkOrders()">
         <h3>
            {{ totalWOs }}
         </h3>
         <p>
            {{ lang().CompletedUnplannedWorkAssetReports }}
         </p>
      </lim-ui-card>
      @if (asset) {
         <lim-ui-card [clickableStyling]="true">
            <h3>
               <span class="success" (click)="showCompletedPreventiveWorkOrders()">
                  <span
                     [count-up]="finalPMSCount"
                     attr.id="{{ asset.assetID }}PMs"
                     data-num-decimals="0"
                     data-animation-length="1.5"
                  >
                  </span>
                  <span class="greenColor">%</span>
               </span>
               {{ lang().vsStr }}
               <span class="danger" (click)="showCompletedWorkOrders()">
                  <span
                     [count-up]="finalWOSCount"
                     attr.id="{{ asset.assetID }}WOs"
                     data-num-decimals="0"
                     data-animation-length="1.5"
                  >
                  </span>
                  <span class="redColor">%</span>
               </span>
            </h3>
            <p>
               {{ lang().PlannedVsUnplanned }}
            </p>
         </lim-ui-card>
      }
      <lim-ui-card [clickableStyling]="true" (click)="viewListOfLaborTasks()">
         <h3>{{ totalHours }} {{ lang().hrs }}</h3>
         <p>{{ lang().TimeSpent }}</p>
      </lim-ui-card>
      <lim-ui-card [clickableStyling]="true" (click)="viewListOfDowntimeTasks()">
         @if (MTBF !== "Infinity" && MTBF != 0) {
            <h3 placement="bottom" limUiTooltip="{{ lang().AssetReportsMTBFTooltip }}">
               {{ MTBF }} {{ lang().hrs }}
            </h3>
         }
         @if (MTBF === "Infinity" || MTBF == 0) {
            <h3
               placement="bottom"
               limUiTooltip="{{ lang().AssetReportsMTBFTooltipWarning }}"
            >
               {{ lang().NA }}
            </h3>
         }
         <p>
            {{ lang().MTBF }}
            <i
               class="fa-solid fa-circle-question fa-fw grey-color"
               limUiTooltip="{{ lang().InTheSelectedTimeFrameTheAssetRan }} {{
                  totalTimeRanInHours
               }} {{ lang().hrs }}"
            ></i>
         </p>
      </lim-ui-card>

      <lim-ui-card [clickableStyling]="true" (click)="viewListOfDowntimeTasks()">
         @if (MTTR > 0) {
            <h3 placement="bottom" limUiTooltip="{{ lang().AssetReportsMTTRTooltip }}">
               {{ MTTR }} {{ lang().hrs }}
            </h3>
         }
         @if (MTTR == 0) {
            <h3
               placement="bottom"
               limUiTooltip="{{ lang().AssetReportsMTTRTooltipWarning }}"
            >
               {{ lang().NA }}
            </h3>
         }
         <p>{{ lang().MTTR }}</p>
      </lim-ui-card>

      <lim-ui-card [clickableStyling]="true" (click)="viewListOfDowntimeTasks()">
         <h3>{{ downtimeHours }} {{ lang().hrs }}</h3>
         <p>{{ lang().Downtime }}</p>
      </lim-ui-card>

      <lim-ui-card [clickableStyling]="true" (click)="viewListOfPartsUsage()">
         <h3>
            {{ totalParts }}
         </h3>
         <p>{{ lang().PartsUsed }}</p>
      </lim-ui-card>
   </section>

   <section class="data-visualizations">
      <div class="pie-container">
         <lim-ui-card>
            <h3 class="graph-header">
               <lim-ui-icon icon="chartColumn" />
               {{ lang().TotalCostOfOwnership }} :
               <span>{{ totalCost | localeCurrency: currencyCode() }}</span>
            </h3>
            <div>
               <canvas
                  [id]="'pieChart1' + asset?.assetID"
                  (click)="viewPieItem($event)"
               ></canvas>
               <div id="pieChart1Legend{{ asset?.assetID }}"></div>
               <div class="chart-controls" [hidden]="!credAssetSettings">
                  <lim-ui-primary-button
                     (click)="changeFields()"
                     icon="penToSquareRegular"
                  >
                     {{ lang().ChangeFields }}
                  </lim-ui-primary-button>
               </div>
            </div>
         </lim-ui-card>
      </div>

      <lim-ui-card>
         <h3 class="graph-header">
            <lim-ui-icon icon="chartColumn" />
            {{ lang().FutureEstimatedCosts }} : {{ lang().Labor }}
            <span>{{ laborCost | localeCurrency: currencyCode() }}</span> |
            {{ lang().Parts }}
            <span>{{ totalPartsCost | localeCurrency: currencyCode() }}</span>
            | {{ lang().Total }}:
            <span>{{ laborCost + totalPartsCost | localeCurrency: currencyCode() }}</span>
         </h3>
         <div>
            <canvas
               [id]="'barChart1' + asset?.assetID"
               (click)="viewBarChartItem($event)"
            ></canvas>
            <div id="barChart1Legend{{ asset?.assetID }}"></div>
         </div>
      </lim-ui-card>

      @if (depreciationSchedule && depreciationSchedule.scheduleActive) {
         <div>
            <depreciation-line-chart-card
               [schedule]="depreciationSchedule"
               [currencyCode]="currencyCode()"
            />
         </div>
      }
   </section>
</lim-ui-panel>
