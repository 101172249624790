<div id="signature-pad" class="signature-pad" [ngClass]="{ disabled: disabled }">
   <span [hidden]="type === 'image'">
      <div class="signature-pad--body">
         @if (signature) {
            <img [src]="signature" crossorigin="anonymous" class="signature" />
         }
         <canvas [hidden]="signature || disabled" #sigCanvas></canvas>
         @if (showAutoSign && signature == undefined) {
            <lim-ui-icon-button
               class="auto-sign"
               icon="pencil"
               [limUiTooltip]="lang().autoSignToolTip"
               (click)="autoSign()"
               [disabled]="(signaturePad && !isSignatureEmpty()) || disabled"
            />
         }
         @if (signatureSaved) {
            <div class="signature-curtain"></div>
         }
         @if (signatureInfo && signatureSaved) {
            <div class="signature-info">
               {{ signatureInfo.timestamp | betterDate: "dateTime" }}
               @if (signedByUserName.length) {
                  <span>{{ lang().By }} {{ signedByUserName }}</span>
               }
            </div>
         }
      </div>
      <div class="signature-pad--footer">
         <div class="primary-signature-buttons">
            <lim-ui-primary-button
               (click)="saveSig()"
               [classNames]="'mobile-full-width'"
               [disabled]="
                  (signaturePad && isSignatureEmpty()) || signatureSaved || disabled
               "
               class="footer-button"
            >
               {{ btnSubmit }}
            </lim-ui-primary-button>
            @if (type === "approval") {
               <lim-ui-danger-button
                  [classNames]="'mobile-full-width'"
                  (click)="disapproval()"
                  class="footer-button"
                  [disabled]="signatureSaved || disabled"
               >
                  {{ btnDisapprove }}
               </lim-ui-danger-button>
            }
         </div>
         <lim-ui-secondary-button
            [classNames]="'mobile-full-width'"
            (click)="clearSig()"
            class="footer-button"
            icon="broomWideRegular"
            [disabled]="(signaturePad && isSignatureEmpty()) || disabled"
         >
            {{ btnClear }}
         </lim-ui-secondary-button>
      </div>
   </span>

   @if (type === "image") {
      <span>
         <div class="signature-pad--body">
            @if (signature) {
               <img [src]="signature" crossorigin="anonymous" class="signature" />
            }
            @if (signatureInfo && signatureSaved) {
               <div class="signature-info">
                  {{ signatureInfo.timestamp | betterDate: "dateTime" }}
                  @if (signedByUserName.length) {
                     <span>{{ lang().By }} {{ signedByUserName }}</span>
                  }
               </div>
            }
         </div>
      </span>
   }
</div>
