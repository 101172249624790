<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message-area" [limbleHtml]="message"></span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="row">
            <div class="col-xs-12">
               <div class="dd" id="nestable_list_1">
                  <div class="top-text">
                     {{ lang().TrackPartsOrLabor }}
                  </div>

                  @if (asset) {
                     <div>
                        <div
                           class="lim-ui-list-item lim-ui-selectable-list-item"
                           (click)="selectParts()"
                           [ngClass]="{
                              'lim-ui-selected-list-item': asset.assetTrackCOOParts == 1,
                           }"
                        >
                           <div class="lim-ui-list-item-content">
                              <div>
                                 @if (
                                    asset.assetTrackCOOParts &&
                                    asset.assetTrackCOOParts === 1
                                 ) {
                                    <lim-ui-icon
                                       icon="check"
                                       iconSize="small"
                                       iconColor="success"
                                    />
                                 }

                                 {{ lang().Parts }}
                              </div>
                              <lim-ui-icon icon="gears" iconSize="small" />
                           </div>
                        </div>

                        <div
                           class="lim-ui-list-item lim-ui-selectable-list-item"
                           (click)="selectLabor()"
                           [ngClass]="{
                              'lim-ui-selected-list-item': asset.assetTrackCOOLabor == 1,
                           }"
                        >
                           <div class="lim-ui-list-item-content">
                              <div>
                                 @if (
                                    asset.assetTrackCOOLabor &&
                                    asset.assetTrackCOOLabor === 1
                                 ) {
                                    <lim-ui-icon
                                       icon="check"
                                       iconSize="small"
                                       iconColor="success"
                                    />
                                 }
                                 {{ lang().Labor }}
                              </div>
                              <lim-ui-icon icon="clock" iconSize="small" />
                           </div>
                        </div>
                     </div>
                  }

                  <div class="middle-text">
                     {{ lang().CustomFieldsCurrencyFields }}
                  </div>

                  <div class="middle-text" [hidden]="fieldValues.size != 0">
                     {{
                        lang().ThisAssetDoesNotHaveAnyCurrencyOrNumericFieldsToChooseFrom
                     }}
                  </div>

                  @for (field of fieldValues; track field) {
                     @if (assetFields.get(field.fieldID); as assetField) {
                        <div
                           class="lim-ui-list-item lim-ui-selectable-list-item"
                           (click)="selectField(field)"
                           [ngClass]="{
                              'lim-ui-selected-list-item':
                                 fieldValues.get(field.valueID)?.reportCostOfOwnership ==
                                 1,
                           }"
                        >
                           <div class="lim-ui-list-item-content">
                              <div>
                                 <lim-ui-icon
                                    icon="check"
                                    iconSize="small"
                                    iconColor="success"
                                    [hidden]="field?.reportCostOfOwnership != 1"
                                 />
                                 {{ assetFields.get(field.fieldID)?.fieldName }}
                              </div>
                              <div>
                                 @if (assetFields.get(field.fieldID)?.fieldTypeID === 5) {
                                    <lim-ui-icon icon="hashtagRegular" iconSize="small" />
                                 }
                                 @if (assetFields.get(field.fieldID)?.fieldTypeID === 6) {
                                    <span>{{ currencyCode | currencySymbol }}</span>
                                 }
                              </div>
                           </div>
                        </div>
                     }
                  }
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Done"
      (successClick)="submit()"
   />
</lim-ui-modal>
