import { inject, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

export type ColorSet = {
   name: string;
   displayName: string;
   colorCode: string;
   textColorClass: string;
};

export type ColorSets = {
   arr: Array<ColorSet>;
   index: {
      [index: string]: ColorSet;
   };
};

@Injectable({ providedIn: "root" })
export class ColorSetsService {
   private readonly lang = inject(ManageLang).lang;

   public colorSets(): ColorSets {
      const lang = this.lang() ?? {};
      const arr = [
         {
            name: "Red",
            displayName: lang.Red,
            colorCode: "#E62737",
            textColorClass: "fc-white-text",
         },
         {
            name: "Green",
            displayName: lang.Green,
            colorCode: "#289E49",
            textColorClass: "fc-white-text",
         },
         {
            name: "Orange",
            displayName: lang.Orange,
            colorCode: "#F29423",
            textColorClass: "fc-black-text",
         },
         {
            name: "Dark Grey",
            displayName: lang.DarkGrey,
            colorCode: "#4F5259",
            textColorClass: "fc-white-text",
         },
         {
            name: "Blue",
            displayName: lang.Blue,
            colorCode: "#4684D0",
            textColorClass: "fc-white-text",
         },
         {
            name: "Dark Blue",
            displayName: lang.DarkBlue,
            colorCode: "#34495E",
            textColorClass: "fc-white-text",
         },
         {
            name: "Pink",
            displayName: lang.Pink,
            colorCode: "#E667BB",
            textColorClass: "fc-white-text",
         },
         {
            name: "Olive",
            displayName: lang.Olive,
            colorCode: "#808000",
            textColorClass: "fc-white-text",
         },
         {
            name: "Silver",
            displayName: lang.Olive,
            colorCode: "#COCOCO",
            textColorClass: "fc-black-text",
         },
         {
            name: "Light Grey",
            displayName: lang.LightGrey,
            colorCode: "#999999",
            textColorClass: "fc-white-text",
         },
         {
            name: "Light Blue",
            displayName: lang.LightBlue,
            colorCode: "#2BBCE0",
            textColorClass: "fc-black-text",
         },
         {
            name: "Aqua",
            displayName: lang.Aqua,
            colorCode: "#1E5878",
            textColorClass: "fc-white-text",
         },
         {
            name: "Teal",
            displayName: lang.Teal,
            colorCode: "#008080",
            textColorClass: "fc-white-text",
         },
         {
            name: "Gold",
            displayName: lang.Gold,
            colorCode: "#FFD700",
            textColorClass: "fc-black-text",
         },
         {
            name: "Light Green",
            displayName: lang.LightGreen,
            colorCode: "#90EE90",
            textColorClass: "fc-black-text",
         },
         {
            name: "Thistle",
            displayName: lang.Thistle,
            colorCode: "#D8BFD8",
            textColorClass: "fc-black-text",
         },
         {
            name: "Peru",
            displayName: lang.Peru,
            colorCode: "#CD853F",
            textColorClass: "fc-white-text",
         },
         {
            name: "Petal",
            displayName: lang.Petal,
            colorCode: "#F98866",
            textColorClass: "fc-black-text",
         },
         {
            name: "Poppy",
            displayName: lang.Poppy,
            colorCode: "#FF420E",
            textColorClass: "fc-white-text",
         },
         {
            name: "Stem",
            displayName: lang.Stem,
            colorCode: "#80BD9E",
            textColorClass: "fc-black-text",
         },
         {
            name: "Spring Green",
            displayName: lang.SpringGreen,
            colorCode: "#89DA59",
            textColorClass: "fc-black-text",
         },
         {
            name: "Mist",
            displayName: lang.Mist,
            colorCode: "#90AFC5",
            textColorClass: "fc-black-text",
         },
         {
            name: "Stone",
            displayName: lang.Stone,
            colorCode: "#336B87",
            textColorClass: "fc-white-text",
         },
         {
            name: "Shadow",
            displayName: lang.Shadow,
            colorCode: "#2A3132",
            textColorClass: "fc-white-text",
         },
         {
            name: "Autumn Foliage",
            displayName: lang.AutumnFoliage,
            colorCode: "#763626",
            textColorClass: "fc-white-text",
         },
         {
            name: "Crevice",
            displayName: lang.Crevice,
            colorCode: "#46211A",
            textColorClass: "fc-white-text",
         },
         {
            name: "Thunder Cloud",
            displayName: lang.ThunderCloud,
            colorCode: "#505160",
            textColorClass: "fc-white-text",
         },
         {
            name: "Waterfall",
            displayName: lang.Waterfall,
            colorCode: "#68829E",
            textColorClass: "fc-white-text",
         },
         {
            name: "Moss",
            displayName: lang.Moss,
            colorCode: "#AEDB38",
            textColorClass: "fc-black-text",
         },
         {
            name: "Meadow",
            displayName: lang.Meadow,
            colorCode: "#598234",
            textColorClass: "fc-white-text",
         },
         {
            name: "Forest Green",
            displayName: lang.ForestGreen,
            colorCode: "#2E4600",
            textColorClass: "fc-white-text",
         },
         {
            name: "Blue Black",
            displayName: lang.BlueBlack,
            colorCode: "#021C1E",
            textColorClass: "fc-white-text",
         },
         {
            name: "Cadet Blue",
            displayName: lang.CadetBlue,
            colorCode: "#004445",
            textColorClass: "fc-white-text",
         },
         {
            name: "Rain",
            displayName: lang.Rain,
            colorCode: "#2C7873",
            textColorClass: "fc-white-text",
         },
         {
            name: "Greenery",
            displayName: lang.Greenery,
            colorCode: "#6FB98F",
            textColorClass: "fc-black-text",
         },
         {
            name: "Sky",
            displayName: lang.Sky,
            colorCode: "#FB6542",
            textColorClass: "fc-white-text",
         },
         {
            name: "Sunflower",
            displayName: lang.Sunflower,
            colorCode: "#FFBB00",
            textColorClass: "fc-black-text",
         },
         {
            name: "Grass",
            displayName: lang.Grass,
            colorCode: "#3F681C",
            textColorClass: "fc-white-text",
         },
         {
            name: "Aquamarine",
            displayName: lang.Aquamarine,
            colorCode: "#98DBC6",
            textColorClass: "fc-black-text",
         },
         {
            name: "Turquoise",
            displayName: lang.Turquoise,
            colorCode: "#5BC8AC",
            textColorClass: "fc-black-text",
         },
         {
            name: "Granite",
            displayName: lang.Granite,
            colorCode: "#B7B8B6",
            textColorClass: "fc-black-text",
         },
         {
            name: "Fields",
            displayName: lang.Fields,
            colorCode: "#B3C100",
            textColorClass: "fc-black-text",
         },
         {
            name: "Sea",
            displayName: lang.Sea,
            colorCode: "#20948B",
            textColorClass: "fc-white-text",
         },
         {
            name: "Lagoon",
            displayName: lang.Lagoon,
            colorCode: "#6AB187",
            textColorClass: "fc-white-text",
         },
         {
            name: "Crimson",
            displayName: lang.Crimson,
            colorCode: "#8D230F",
            textColorClass: "fc-white-text",
         },
         {
            name: "Rust",
            displayName: lang.Rust,
            colorCode: "#9B4F0F",
            textColorClass: "fc-white-text",
         },
         {
            name: "Gold",
            displayName: lang.Gold,
            colorCode: "#C99E10",
            textColorClass: "fc-black-text",
         },
         {
            name: "Branch",
            displayName: lang.Branch,
            colorCode: "#5D535E",
            textColorClass: "fc-white-text",
         },
         {
            name: "Berry",
            displayName: lang.Berry,
            colorCode: "#EC96A4",
            textColorClass: "fc-black-text",
         },
         {
            name: "Dark Navy",
            displayName: lang.DarkNavy,
            colorCode: "#011A27",
            textColorClass: "fc-white-text",
         },
         {
            name: "Blueberry",
            displayName: lang.Blueberry,
            colorCode: "#063852",
            textColorClass: "fc-white-text",
         },
         {
            name: "Tangerine",
            displayName: lang.Tangerine,
            colorCode: "#F0810F",
            textColorClass: "fc-white-text",
         },
         {
            name: "Sunglow",
            displayName: lang.Sunglow,
            colorCode: "#D9B44A",
            textColorClass: "fc-black-text",
         },
         {
            name: "Mountains",
            displayName: lang.Mountains,
            colorCode: "#4F6457",
            textColorClass: "fc-white-text",
         },
         {
            name: "Indigo",
            displayName: lang.Indigo,
            colorCode: "#2D4262",
            textColorClass: "fc-white-text",
         },
         {
            name: "Taupe",
            displayName: lang.Taupe,
            colorCode: "#73605B",
            textColorClass: "fc-white-text",
         },
         {
            name: "Blush",
            displayName: lang.Blush,
            colorCode: "#D09683",
            textColorClass: "fc-black-text",
         },
         {
            name: "Bubblegum",
            displayName: lang.Bubblegum,
            colorCode: "#FA6775",
            textColorClass: "fc-white-text",
         },
         {
            name: "Chartreuse",
            displayName: lang.Chartreuse,
            colorCode: "#9BC01C",
            textColorClass: "fc-black-text",
         },
         {
            name: "Bark",
            displayName: lang.Bark,
            colorCode: "#2E2300",
            textColorClass: "fc-white-text",
         },
         {
            name: "Seaweed Green",
            displayName: lang.SeaweedGreen,
            colorCode: "#6E6702",
            textColorClass: "fc-white-text",
         },
         {
            name: "Bronze",
            displayName: lang.Bronze,
            colorCode: "#C05805",
            textColorClass: "fc-white-text",
         },
         {
            name: "Goldenrod",
            displayName: lang.Goldenrod,
            colorCode: "#DB9501",
            textColorClass: "fc-black-text",
         },
         {
            name: "Eggplant",
            displayName: lang.Eggplant,
            colorCode: "#50312F",
            textColorClass: "fc-white-text",
         },
         {
            name: "Strawberry",
            displayName: lang.Strawberry,
            colorCode: "#CB0000",
            textColorClass: "fc-white-text",
         },
         {
            name: "Basil Green",
            displayName: lang.BasilGreen,
            colorCode: "#3F6C45",
            textColorClass: "fc-white-text",
         },
         {
            name: "Leafy Green",
            displayName: lang.LeafyGreen,
            colorCode: "#7CAA2D",
            textColorClass: "fc-white-text",
         },
         {
            name: "Seeds",
            displayName: lang.Seeds,
            colorCode: "#CB6318",
            textColorClass: "fc-white-text",
         },
         {
            name: "Candy Apple",
            displayName: lang.CandyApple,
            colorCode: "#F62A00",
            textColorClass: "fc-white-text",
         },
         {
            name: "Slate",
            displayName: lang.Slate,
            colorCode: "#626D71",
            textColorClass: "fc-white-text",
         },
         {
            name: "Latte",
            displayName: lang.Latte,
            colorCode: "#DDBC95",
            textColorClass: "fc-black-text",
         },
         {
            name: "Coffee",
            displayName: lang.Coffee,
            colorCode: "#B38867",
            textColorClass: "fc-white-text",
         },
         {
            name: "Avocado",
            displayName: lang.Avocado,
            colorCode: "#258039",
            textColorClass: "fc-white-text",
         },
         {
            name: "Aqua Blue",
            displayName: lang.AquaBlue,
            colorCode: "#31A9B8",
            textColorClass: "fc-white-text",
         },
         {
            name: "Tomato",
            displayName: lang.Tomato,
            colorCode: "#CF3721",
            textColorClass: "fc-white-text",
         },
         {
            name: "Charred",
            displayName: lang.Charred,
            colorCode: "#752A07",
            textColorClass: "fc-white-text",
         },
         {
            name: "Toasted",
            displayName: lang.Toasted,
            colorCode: "#FBCB7B",
            textColorClass: "fc-black-text",
         },
         {
            name: "Bluebell",
            displayName: lang.Bluebell,
            colorCode: "#92AAC7",
            textColorClass: "fc-black-text",
         },
         {
            name: "Raspberry",
            displayName: lang.Raspberry,
            colorCode: "#ED5752",
            textColorClass: "fc-white-text",
         },
         {
            name: "Cayenne",
            displayName: lang.Cayenne,
            colorCode: "#AF4425",
            textColorClass: "fc-white-text",
         },
         {
            name: "Cinnamon",
            displayName: lang.Cinnamon,
            colorCode: "#662E1C",
            textColorClass: "fc-white-text",
         },
         {
            name: "Fig",
            displayName: lang.Fig,
            colorCode: "#4C3F54",
            textColorClass: "fc-white-text",
         },
         {
            name: "Basil",
            displayName: lang.Basil,
            colorCode: "#486824",
            textColorClass: "fc-white-text",
         },
         {
            name: "Citrus",
            displayName: lang.Citrus,
            colorCode: "#FAAF08",
            textColorClass: "fc-black-text",
         },
         {
            name: "Granny Smith",
            displayName: lang.GrannySmith,
            colorCode: "#BBCF4A",
            textColorClass: "fc-black-text",
         },
         {
            name: "Ripe Apple",
            displayName: lang.RipeApple,
            colorCode: "#E73F0B",
            textColorClass: "fc-white-text",
         },
         {
            name: "Onion",
            displayName: lang.Onion,
            colorCode: "#FD974F",
            textColorClass: "fc-black-text",
         },
         {
            name: "Driftwood",
            displayName: lang.Driftwood,
            colorCode: "#805A3B",
            textColorClass: "fc-white-text",
         },
         {
            name: "Salmon",
            displayName: lang.Salmon,
            colorCode: "#F56C57",
            textColorClass: "fc-white-text",
         },
         {
            name: "Black Seaweed",
            displayName: lang.BlackSeaweed,
            colorCode: "#231B12",
            textColorClass: "fc-white-text",
         },
         {
            name: "Pewter",
            displayName: lang.Pewter,
            colorCode: "#867666",
            textColorClass: "fc-white-text",
         },
      ];
      const index: { [index: string]: ColorSet } = {};
      for (const color of arr) {
         index[color.name] = color;
      }
      const colors: ColorSets = {
         arr: arr,
         index: index,
      };
      return colors;
   }
}
