<ng-container *translate="let t">
   <button class="column-sort-header" (click)="onSortDirectionClicked()">
      <span
         class="column-sort-header__name"
         [limbleHtml]="column()?.headerNameKey ?? ''"
      ></span>
      <span class="column-sort-header__icon">
         @if (sortDirection() === "asc") {
            <lim-ui-icon icon="arrowUpShortWide" />
         } @else if (sortDirection() === "desc") {
            <lim-ui-icon icon="arrowDownWideShort" />
         } @else if (sortDirection() === "unset") {
            <lim-ui-icon icon="arrowUpArrowDown" />
         }
      </span>
   </button>
</ng-container>
