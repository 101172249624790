import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import axios, { type AxiosResponse } from "axios/dist/axios";
import type * as TaskApiParams from "src/app/tasks/services/task-php-api/task-php-api.types";

/**
 * Wrapper class to call the legacy axios methods.
 * NOTE: We want to split this up into multiple services, but this is a nice wrapper
 * to help isolate the API legacy code for testability and higher ease of refactoring.
 */
@Injectable({
   providedIn: "root",
})
export class TaskPhpApiService {
   private readonly http = inject(HttpClient);

   public async createCycleCountInstance(
      params: TaskApiParams.CreateCycleCountInstanceParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "createCycleCount",
         },
         data: {
            assetID: params.assetID,
            locationID: params.locationID,
            checklistID: params.checklistID,
            reoccurID: params.reoccurID,
            customerID: params.customerID,
            profileID: params.profileID,
            timestamp: params.timestamp,
            userID: params.userID,
            multiUsers: params.multiUsers,
         },
      });
      return answer;
   }

   public async addNote(params: TaskApiParams.AddNoteParams): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "addNote",
         },
         data: {
            note: params.note,
            checklistID: params.checklistID,
            noteHidden: params.noteHidden,
            timestamp: params.timestamp,
            autoGen: params.autoGen,
            sendNotifications: params.sendNotifications,
         },
      });
      return answer;
   }

   public async editNote(params: TaskApiParams.EditNoteParams): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "editNote",
         },
         data: {
            noteID: params.noteID,
            noteMessage: params.noteMessage,
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async getAllTaskRelations(): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getAllTaskRelations" },
      });
      return answer;
   }

   public async generateUniqueExternalLink(
      params: TaskApiParams.GenerateUniqueExternalLinkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "generateUniqueExternalLink" },
         data: {
            checklistID: params.checklistID,
            email: params.email,
            emailMessage: params.emailMessage,
            subject: params.subject,
            locationID: params.locationID,
            vendorID: params.vendorID,
         },
      });
      return answer;
   }

   public async getUniqueExternalLink(
      params: TaskApiParams.GetUniqueExternalLinkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageNoLogin.php",
         params: { action: "getUniqueExternalLink" },
         data: {
            link: params.link,
            email: params.email,
            customerID: params.customerID,
         },
      });
      return answer;
   }

   public async getFutureTasks(
      params: TaskApiParams.GetFutureTasksParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "getFutureTasks" },
         data: {
            checklistID: params.taskID,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async updateSingleSchedule(
      params: TaskApiParams.UpdateSingleScheduleParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "updateSingleSchedule" },
         data: {
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            scheduleID: params.scheduleID,
         },
      });
      return answer;
   }

   public async deleteSingleSchedule(
      params: TaskApiParams.DeleteSingleScheduleParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "deleteSingleSchedule" },
         data: {
            scheduleID: params.scheduleID,
            userID: params.userID,
         },
      });
      return answer;
   }

   public async startInstanceTask(
      params: TaskApiParams.StartInstanceTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "startInstanceTask" },
         data: {
            checklistID: params.checklistID,
            locationID: params.locationID,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            timestamp: params.timestamp,
            assetID: params.assetID,
            timeOfDay: params.timeOfDay,
         },
      });
      return answer;
   }

   public async duplicateOpenTask(
      params: TaskApiParams.DuplicateOpenTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "duplicateOpenTask" },
         data: {
            checklistID: params.checklistID,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async deleteTaskTemplate(
      params: TaskApiParams.DeleteTaskTemplateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteChecklistTemplate" },
         data: {
            chk: params.checklistID,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async deleteWOTemplate(
      params: TaskApiParams.DeleteWOTemplateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteWOTemplate" },
         data: {
            chk: params.checklistID,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async updateTemplateScheduleColor(
      params: TaskApiParams.UpdateTemplateScheduleColorParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTemplateScheduleColor" },
         data: {
            chkID: params.checklistID,
            colorID: params.colorID,
            days: params.days,
         },
      });
      return answer;
   }

   public async changeSchedule(
      params: TaskApiParams.ChangeScheduleParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "changeSchedule" },
         data: {
            chkID: params.taskID,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            dynamicAssignment: params.dynamicAssignment,
         },
      });
      return answer;
   }

   public async changeOwner(
      params: TaskApiParams.ChangeOwnerParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "changeOwner" },
         data: {
            chkID: params.checklistID,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
         },
      });
      return answer;
   }

   public async changeOwnerV2(
      params: TaskApiParams.ChangeOwnerV2Params,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "changeOwner2" },
         data: {
            chkID: params.checklistID,
            userID: params.userID,
            profileID: params.profileID,
         },
      });
      return answer;
   }

   public async changeOwnerInTask(
      params: TaskApiParams.ChangeOwnerInTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "changeOwnerInChk" },
         data: {
            chkID: params.checklistID,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            itemID: params.itemID,
         },
      });
      return answer;
   }

   public async triggerApproval(
      params: TaskApiParams.TriggerApprovalParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "triggerApproval" },
         data: {
            itemID: params.itemID,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
         },
      });
      return answer;
   }

   public async approveApproval(
      params: TaskApiParams.ApproveApprovalParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "approveApproval" },
         data: {
            itemID: params.itemID,
            approvalSignatureInfo: params.approvalSignatureInfo,
         },
      });
      return answer;
   }

   public async disapproveApproval(
      params: TaskApiParams.DisapproveApprovalParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "disapproveApproval" },
         data: {
            itemID: params.itemID,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            reason: params.reason,
         },
      });
      return answer;
   }

   public async changeCompletedUser(
      params: TaskApiParams.ChangeCompletedUserParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "changeCompletedUser" },
         data: {
            chkID: params.checklistID,
            userID: params.userID,
         },
      });
      return answer;
   }

   public async changeOwnerCompletedTask(
      params: TaskApiParams.ChangeOwnerCompletedTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "changeOwnerCompletedTask" },
         data: {
            checklistID: params.checklistID,
            userID: params.userID,
            multiUsers: params.multiUsers,
            profileID: params.profileID,
         },
      });
      return answer;
   }

   public async notify(params: TaskApiParams.NotifyParams): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageDashboard.php",
         params: { action: "notify" },
         data: {
            to: params.to,
            subject: params.subject,
            message: params.message,
            users: params.users,
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async spawnChecklist(
      params: TaskApiParams.SpawnChecklistParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "spawnChecklist" },
         data: {
            chkID: params.checklistID,
            locationID: params.locationID,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            item: params.itemID,
            date: params.date,
            timeOfDay: params.timeOfDay,
            assetID: params.assetID,
         },
      });
      return answer;
   }

   public async resetTask(params: TaskApiParams.ResetTaskParams): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "resetChecklist" },
         data: {
            chk: params.checklistID,
            lastEdited: params.lastEdited,
         },
      });
      return answer;
   }

   public async logTimeOnTask(
      params: TaskApiParams.LogTimeOnTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "logTimeOnTask" },
         data: {
            checklistID: params.checklistID,
            totalSeconds: params.totalSeconds,
            userID: params.userID,
            loggedAt: params.loggedAt,
            extraTimeNotes: params.extraTimeNotes,
            categoryID: params.categoryID,
            addNote: params.addNote,
            noteHidden: params.noteHidden,
         },
      });
      return answer;
   }

   public async finishChecklist(
      params: TaskApiParams.FinishChecklistParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "finishChecklist" },
         data: {
            chk: params.checklistID,
            locationID: params.locationID,
            promptTime: params.promptTime,
            downtime: params.downtime,
            parts: params.parts,
            notesToTheRequestor: params.notesToTheRequestor,
            multiWork: params.multiWork,
            categoryID: params.categoryID,
         },
      });
      return answer;
   }

   public async deleteChecklistInstance(
      params: TaskApiParams.DeleteChecklistInstanceParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteChecklistInstance" },
         data: {
            chk: params.checklistID,
         },
      });
      return answer;
   }

   public async deleteOpenTasksInBulk(
      params: TaskApiParams.DeleteOpenTasksInBulkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteOpenTasksInBulk" },
         data: {
            tasks: params.tasks,
         },
      });
      return answer;
   }

   public async deletePMTemplatesInBulk(
      params: TaskApiParams.DeletePMTemplatesInBulkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deletePMTemplatesInBulk" },
         data: {
            tasks: params.tasks,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async reassignOpenTasksInBulk(
      params: TaskApiParams.ReassignOpenTasksInBulkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "reassignOpenTasksInBulk" },
         data: {
            tasks: params.tasks,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async bulkCompleteOpenTasks(
      params: TaskApiParams.BulkCompleteOpenTasksParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "bulkCompleteOpenTasks" },
         data: {
            tasks: params.tasks,
            totalSeconds: params.totalSeconds,
            userID: params.userID,
            loggedAt: params.loggedAt,
            categoryID: params.categoryID,
            locationID: params.locationID,
            notes: params.notes,
         },
      });
      return answer;
   }

   public async bulkChangeDueDateOpenTasks(
      params: TaskApiParams.BulkChangeDueDateOpenTasksParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "bulkChangeDueDateOpenTasks" },
         data: {
            tasks: params.tasks,
            newTime: params.newTime,
            timeOfDay: params.timeOfDay,
            timestamp: params.timestamp,
            newStartDate: params.newStartDate,
            startDateSetting: params.startDateSetting,
         },
      });
      return answer;
   }

   public async bulkChangePriorityOpenTasks(
      params: TaskApiParams.BulkChangePriorityOpenTasksParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "bulkChangePriorityOpenTasks" },
         data: {
            tasks: params.tasks,
            priorityID: params.priorityID,
         },
      });
      return answer;
   }

   public async bulkDeferTasks(
      params: TaskApiParams.BulkDeferTasksParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "bulkDeferTasks" },
         data: {
            tasks: params.tasks,
            reason: params.reason,
            pickedDate: params.pickedDate,
         },
      });
      return answer;
   }

   public async reassignPMTemplatesInBulk(
      params: TaskApiParams.ReassignPMTemplatesInBulkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "reassignPMTemplatesInBulk" },
         data: {
            tasks: params.tasks,
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            locationID: params.locationID,
            dynamicAssignment: params.dynamicAssignment,
         },
      });
      return answer;
   }

   public async changePMTemplatesAssetAssignmentInBulk(
      params: TaskApiParams.ChangePMTemplatesAssetAssignmentInBulkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changePMTemplatesAssetAssignmentInBulk" },
         data: {
            tasks: params.tasks,
            assetID: params.assetID,
         },
      });
      return answer;
   }

   public async changePMTemplateRelationsInBulk(
      params: TaskApiParams.ChangePMTemplateRelationsInBulkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changePMTemplateRelationsInBulk" },
         data: {
            tasks: params.tasks,
            checklistBatchID: params.checklistBatchID,
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async breakRelationPMTemplate(
      params: TaskApiParams.BreakRelationPMTemplateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "breakRelationPMTemplate" },
         data: {
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async addUserToChecklistNotifications(
      params: TaskApiParams.AddUserToChecklistNotificationsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "addUserToChecklistNotifications" },
         data: {
            checklistID: params.checklistID,
            userID: params.userID,
            reason: params.reason,
         },
      });
      return answer;
   }

   public async addEmailStrToChecklistNotifications(
      params: TaskApiParams.AddEmailStrToChecklistNotificationsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "addEmailStrToChecklistNotifications" },
         data: {
            checklistID: params.checklistID,
            emailStr: params.emailStr,
         },
      });
      return answer;
   }

   public async removeUserFromChecklistNotifications(
      params: TaskApiParams.RemoveUserFromChecklistNotificationsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "removeUserFromChecklistNotifications" },
         data: {
            checklistID: params.checklistID,
            userID: params.userID,
         },
      });
      return answer;
   }

   public async removeManuallyAddedCommentNotificationEmailStr(
      params: TaskApiParams.RemoveManuallyAddedCommentNotificationEmailStrParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "removeManuallyAddedCommentNotificationEmailStr" },
         data: {
            emailStr: params.emailStr,
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async insItem(params: TaskApiParams.InsItemParams): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "insItem" },
         data: {
            chk: params.chk,
            type: params.itemTypeID,
            itemText: params.itemText,
         },
      });
      return answer;
   }

   public async updateTaskDescription(
      params: TaskApiParams.UpdateTaskDescriptionParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTaskDescription" },
         data: {
            chk: params.checklistID,
            text: params.checklistInstructions,
         },
      });
      return answer;
   }

   public async addOrRemoveCustomTag(
      params: TaskApiParams.AddOrRemoveCustomTagParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "addOrRemoveCustomTag" },
         data: {
            text: params.checklistInstructions,
            chk: params.checklistID,
            state: params.state,
            customTag: params.customTag,
         },
      });
      return answer;
   }

   public async updateTaskName(
      params: TaskApiParams.UpdateTaskNameParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTaskName" },
         data: {
            text: params.checklistName,
            chk: params.checklistID,
         },
      });
      return answer;
   }

   public async createWorkOrder(
      params: TaskApiParams.CreateWorkOrderParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "createWorkOrder" },
         data: {
            assetID: params.assetID,
            locationID: params.locationID,
            templateID: params.templateID,
            sourceItemID: params.sourceItemID,
         },
      });
      return answer;
   }

   public async goLiveWorkOrderSimple(
      params: TaskApiParams.GoLiveWorkOrderSimpleParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "goLiveWorkOrderSimple" },
         data: {
            checklistID: params.checklistID,
            name: params.name,
            description: params.description,
            profileID: params.profileID,
            userID: params.userID,
            multiUsers: params.multiUsers,
            options: params.options,
            dueDate: params.dueDate,
            type: params.type,
            itemID: params.itemID,
            timeOfDay: params.timeOfDay,
            startDate: params.startDate,
            startDateTimeOfDay: params.startDateTimeOfDay,
         },
      });
      return answer;
   }

   public async goLiveWorkOrderAdvanced(
      params: TaskApiParams.GoLiveWorkOrderAdvancedParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "goLiveWorkOrderAdvanced" },
         data: {
            checklistID: params.checklistID,
            profileID: params.profileID,
            userID: params.userID,
            multiUsers: params.multiUsers,
            dueDate: params.dueDate,
            type: params.type,
            itemID: params.itemID,
            timeOfDay: params.timeOfDay,
            startDate: params.startDate,
            startDateTimeOfDay: params.startDateTimeOfDay,
         },
      });
      return answer;
   }

   public async addPartToTask(
      params: TaskApiParams.AddPartToTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "addPartToTask" },
         data: {
            checklistID: params.checklistID,
            partID: params.partID,
            overrideCompleted: params.overrideCompleted,
         },
      });
      return answer;
   }

   public async removePartFromTask(
      params: TaskApiParams.RemovePartFromTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "removePartFromTask" },
         data: {
            checklistID: params.checklistID,
            relationID: params.relationID,
         },
      });
      return answer;
   }

   public async updatePartSuggestedNumber(
      params: TaskApiParams.UpdatePartSuggestedNumberParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updatePartSuggestedNumber" },
         data: {
            relationID: params.relationID,
            suggestedNumber: params.suggestedNumber,
         },
      });
      return answer;
   }

   public async updatePartUsedNumber(
      params: TaskApiParams.UpdatePartUsedNumberParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updatePartUsedNumber" },
         data: {
            relationID: params.relationID,
            usedNumber: params.usedNumber,
         },
      });
      return answer;
   }
   public async getWhoWillReceiveTaskNotifications(
      params: TaskApiParams.GetWhoWillReceiveTaskNotificationsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getWhoWillReceiveTaskNotifications" },
         data: {
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async updateChecklistEstTime(
      params: TaskApiParams.UpdateChecklistEstTimeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateChecklistEstTime" },
         data: {
            checklistID: params.checklistID,
            minutes: params.estimatedTimeInMinutes,
         },
      });
      return answer;
   }

   public async updateTaskCompletionNotes(
      params: TaskApiParams.UpdateTaskCompletionNotesParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTaskCompletionNotes" },
         data: {
            checklistID: params.checklistID,
            comments: params.comments,
         },
      });
      return answer;
   }

   public async deferTask(params: TaskApiParams.DeferTaskParams): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deferTask" },
         data: {
            checklistID: params.checklistID,
            reason: params.reason,
            pickedDate: params.pickedDate,
         },
      });
      return answer;
   }

   public async newTaskTemplateFromCopy(
      params: TaskApiParams.NewTaskTemplateFromCopyParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "newTaskTemplateFromCopy" },
         data: {
            taskToCopyID: params.taskToCopyID,
            assetIDTarget: params.assetIDTarget,
            locationIDTarget: params.locationIDTarget,
            newName: params.newName,
            isDuplicating: params.isDuplicating,
         },
      });
      return answer;
   }

   public async newTemplate(
      params: TaskApiParams.NewTemplateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "newTemplate" },
         data: {
            assetID: params.assetID,
            newName: params.newName,
            locationID: params.locationID,
            type: params.type,
         },
      });
      return answer;
   }

   public async downloadExcel(
      params: TaskApiParams.DownloadExcelParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "downloadExcel" },
         data: {
            tasks: params.tasks,
         },
      });
      return answer;
   }

   public async downloadExcelSchedules(
      params: TaskApiParams.DownloadExcelSchedulesParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "downloadExcelSchedules" },
         data: {
            tasks: params.tasks,
            schedules: params.schedules,
         },
      });
      return answer;
   }

   public async checkSubmitProblemRecaptcha(
      params: TaskApiParams.CheckSubmitProblemRecaptchaParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "checkRecaptcha" },
         data: {
            recaptcha: params.recaptcha,
         },
      });
      return answer;
   }

   public async checkForDuplicateWorkRequests(
      params: TaskApiParams.CheckForDuplicateWorkRequestsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "checkForDuplicateWorkRequests" },
         data: {
            customerID: params.customerID,
            locationID: params.locationID,
            assetID: params.assetID,
         },
      });
      return answer;
   }

   public async submitProblemReport(
      action: "submitProblemReport" | "v2-submitProblemReport",
      params: TaskApiParams.SubmitProblemReportParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: action },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async prepProblemReportHTML(
      params: TaskApiParams.PrepProblemReportHTMLParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "prepProblemReportHTML" },
         data: {
            locationID: params.locationID,
            assetID: params.assetID,
            customerCode: params.customerCode,
         },
      });
      return answer;
   }

   public async prepCheckWorkRequestsPage(
      params: TaskApiParams.PrepCheckWorkRequestsPageParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "prepCheckWorkRequestsPage" },
         data: {
            customerCode: params.customerCode,
            locationID: params.locationID,
            emailViewing: params.emailViewing,
            hash: params.hash,
         },
      });
      return answer;
   }

   public async checkWorkRequestsPageValidateEmail(
      params: TaskApiParams.CheckWorkRequestsPageValidateEmailParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "checkWorkRequestsPageValidateEmail" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async getCheckWorkRequestsPageData(
      params: TaskApiParams.GetCheckWorkRequestsPageDataParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "getCheckWorkRequestsPageData" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async prepWorkRequestTemplate(
      params: TaskApiParams.PrepWorkRequestTemplateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "prepWorkRequestTemplate" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async updateTaskPriorityID(
      params: TaskApiParams.UpdateTaskPriorityIDParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTaskPriorityID" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async updateTaskStatusID(
      params: TaskApiParams.UpdateTaskStatusIDParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTaskStatusID" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeCompletedTime(
      params: TaskApiParams.ChangeCompletedTimeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeCompletedTime" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeCompletedTimeExtraTime(
      params: TaskApiParams.ChangeCompletedTimeExtraTimeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeCompletedTimeExtraTime" },
         data: {
            extraTimeID: params.extraTimeID,
            newTime: params.newTime,
         },
      });
      return answer;
   }

   public async changeCompletedUserExtraTime(
      params: TaskApiParams.ChangeCompletedUserExtraTimeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeCompletedUserExtraTime" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async deleteExtraTime(
      params: TaskApiParams.DeleteExtraTimeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteExtraTime" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeTaskBillableTime(
      params: TaskApiParams.ChangeTaskBillableTimeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeTaskBillableTime" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeTaskBillableCategory(
      params: TaskApiParams.ChangeTaskBillableCategoryParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeTaskBillableCategory" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeTaskBillableRate(
      params: TaskApiParams.ChangeTaskBillableRateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeTaskBillableRate" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeTaskExtraTimeNotes(
      params: TaskApiParams.ChangeTaskExtraTimeNotesParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeTaskExtraTimeNotes" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeExtraTimeBillableTime(
      params: TaskApiParams.ChangeExtraTimeBillableTimeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeExtraTimeBillableTime" },
         data: {
            extraTimeID: params.extraTimeID,
            newTime: params.newTime,
         },
      });
      return answer;
   }

   public async changeExtraTimeBillableCategory(
      params: TaskApiParams.ChangeExtraTimeBillableCategoryParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeExtraTimeBillableCategory" },
         data: {
            extraTimeID: params.extraTimeID,
            categoryID: params.categoryID,
         },
      });
      return answer;
   }

   public async changeExtraTimeBillableRate(
      params: TaskApiParams.ChangeExtraTimeBillableRateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeExtraTimeBillableRate" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeExtraTimeNotes(
      params: TaskApiParams.ChangeExtraTimeNotesParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeExtraTimeNotes" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async changeDowntime(
      params: TaskApiParams.ChangeDowntimeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "changeDowntime" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async addNoteFromExternal(
      params: TaskApiParams.AddNoteFromExternalParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "addNoteFromExternal" },
         data: {
            note: params.note,
            checklistID: params.checklistID,
            timestamp: params.timestamp,
            CID: params.customerID,
            email: params.email,
         },
      });
      return answer;
   }

   public async deleteNote(
      params: TaskApiParams.DeleteNoteParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteNote" },
         data: {
            noteID: params.noteID,
         },
      });
      return answer;
   }

   public async showOrHideNoteForExternalView(
      params: TaskApiParams.ShowOrHideNoteForExternalViewParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "showOrHideNoteForExternalView" },
         data: {
            noteID: params.noteID,
            noteHidden: params.noteHidden,
         },
      });
      return answer;
   }

   public async updateDueDate(
      params: TaskApiParams.UpdateDueDateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateDueDate" },
         data: {
            oldTime: params.oldTime,
            newTime: params.newTime,
            checklistID: params.checklistID,
            timestamp: params.timestamp,
            timeOfDay: params.timeOfDay,
            oldStartDate: params.oldStartDate,
            newStartDate: params.newStartDate,
         },
      });
      return answer;
   }

   public async updateCompletedDate(
      params: TaskApiParams.UpdateCompletedDateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateCompletedDate" },
         data: {
            oldTime: params.oldTime,
            newTime: params.newTime,
            oldTimeStr: params.oldTimeStr,
            newTimeStr: params.newTimeStr,
            checklistID: params.checklistID,
            timestamp: params.timestamp,
         },
      });
      return answer;
   }

   public async updateExtraTimeDate(
      params: TaskApiParams.UpdateExtraTimeDateParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateExtraTimeDate" },
         data: {
            newTime: params.newTime,
            extraTimeID: params.extraTimeID,
            checklistID: params.checklistID,
            showTime: params.showTime,
         },
      });
      return answer;
   }

   public async updateTasksAsset(
      params: TaskApiParams.UpdateTasksAssetParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTasksAsset" },
         data: {
            checklistID: params.checklistID,
            newAssetID: params.newAssetID,
            extraAssets: params.extraAssets,
         },
      });
      return answer;
   }

   public async updateTasksLocation(
      params: TaskApiParams.UpdateTasksLocationParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTasksLocation" },
         data: {
            checklistID: params.checklistID,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async updateTaskType(
      params: TaskApiParams.UpdateTaskTypeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTaskType" },
         data: {
            ...params,
         },
      });
      return answer;
   }

   public async addCustomTag(
      params: TaskApiParams.AddCustomTagParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "addCustomTag" },
         data: {
            tag: params.tag,
         },
      });
      return answer;
   }

   public async renameCustomTag(
      params: TaskApiParams.RenameCustomTagParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "renameCustomTag" },
         data: {
            newName: params.newName,
            oldName: params.oldName,
         },
      });
      return answer;
   }

   public async deleteCustomTag(
      params: TaskApiParams.DeleteCustomTagParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteCustomTag" },
         data: {
            name: params.name,
         },
      });
      return answer;
   }

   public async getCustomTagEventSettings(
      params: TaskApiParams.GetCustomTagEventSettingsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getCustomTagEventSettings" },
         data: {
            name: params.name,
         },
      });
      return answer;
   }

   public async updateCustomTagEvent(
      params: TaskApiParams.UpdateCustomTagEventParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateCustomTagEvent" },
         data: {
            customTag: params.customTag,
            trigger: params.trigger,
            actionToUpdate: params.actionToUpdate,
            field1: params.field1,
            active: params.active,
         },
      });
      return answer;
   }

   public async markNotificationHistoryVisited(
      params: TaskApiParams.MarkNotificationHistoryVisitedParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "markNotificationHistoryVisited" },
         data: {
            historyID: params.historyID,
         },
      });
      return answer;
   }

   public async setWorkRequestFilterData(
      params: TaskApiParams.SetWorkRequestFilterDataParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "setWorkRequestFilterData" },
         data: {
            itemData: params.itemData,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async updateWorkRequestPortalSettings(
      params: TaskApiParams.UpdateWorkRequestPortalSettingsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateWorkRequestPortalSettings" },
         data: {
            target: params.target,
            value: params.value,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async updateReportProblemHeader(
      params: TaskApiParams.UpdateReportProblemHeaderParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateReportProblemHeader" },
         data: {
            value: params.value,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async updateReportProblemEmailToNotify(
      params: TaskApiParams.UpdateReportProblemEmailToNotifyParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateReportProblemEmailToNotify" },
         data: {
            value: params.value,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async deleteWorkRequestPortalImage(
      params: TaskApiParams.DeleteWorkRequestPortalImageParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteWorkRequestPortalImage" },
         data: {
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async updateCustomFieldTitle(
      params: TaskApiParams.UpdateCustomFieldTitleParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateCustomFieldTitle" },
         data: {
            value: params.value,
            locationID: params.locationID,
            index: params.index,
         },
      });
      return answer;
   }

   public async updateCustomDropdownTitle(
      params: TaskApiParams.UpdateCustomDropdownTitleParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateCustomDropdownTitle" },
         data: {
            value: params.value,
            locationID: params.locationID,
            index: params.index,
         },
      });
      return answer;
   }

   public async updateWRCustomDropdownOptions(
      params: TaskApiParams.UpdateWRCustomDropdownOptionsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateWRCustomDropdownOptions" },
         data: {
            value: params.value,
            locationID: params.locationID,
            index: params.index,
         },
      });
      return answer;
   }

   public async checkMultiUsersForWRCustomDropdownOptions(
      params: TaskApiParams.CheckMultiUsersForWRCustomDropdownOptionsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "checkMultiUsersForWRCustomDropdownOptions" },
         data: {
            locationID: params.locationID,
            multiUsers: params.multiUsers,
         },
      });
      return answer;
   }

   public async resetWRTemplateToDefault(
      params: TaskApiParams.ResetWRTemplateToDefaultParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "resetWRTemplateToDefault" },
         data: {
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async setWRDefaultAssignment(
      params: TaskApiParams.SetWRDefaultAssignmentParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "setWRDefaultAssignment" },
         data: {
            userID: params.userID,
            profileID: params.profileID,
            multiUsers: params.multiUsers,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async getRequestDetails(
      params: TaskApiParams.GetRequestDetailsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getRequestDetails" },
         data: {
            checklistID: params.checklistID,
            customerCode: params.customerCode,
            emailViewing: params.emailViewing,
            hash: params.hash,
         },
      });
      return answer;
   }

   public async updateReportProblemTagThisRequestStr(
      params: TaskApiParams.UpdateReportProblemTagThisRequestStrParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "updateReportProblemTagThisRequestStr" },
         data: {
            value: params.value,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async deleteCompletedTask(
      params: TaskApiParams.DeleteCompletedTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteCompletedTask" },
         data: {
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async reopenCompletedTask(
      params: TaskApiParams.ReopenCompletedTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "reopenCompletedTask" },
         data: {
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async updateTaskDueDateByDays(
      params: TaskApiParams.UpdateTaskDueDateByDaysParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTaskDueDateByDays" },
         data: {
            checklistID: params.checklistID,
            days: params.days,
         },
      });
      return answer;
   }

   public async resizeTaskStartOn(
      params: TaskApiParams.ResizeTaskStartOnParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "resizeTaskStartOn" },
         data: {
            checklistID: params.checklistID,
            days: params.days,
         },
      });
      return answer;
   }

   public async deleteTempFileFromAddComment(
      params: TaskApiParams.DeleteTempFileFromAddCommentParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteTempFileFromAddComment" },
         data: {
            fileName: params.fileName,
         },
      });
      return answer;
   }

   public async deleteFileFromChecklistEmailCNImages(
      params: TaskApiParams.DeleteFileFromChecklistEmailCNImagesParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteFileFromChecklistEmailCNImages" },
         data: {
            fileName: params.fileName,
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async cleanNotesTempFiles(): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "cleanNotesTempFiles" },
         data: {},
      });
      return answer;
   }

   public async cleanNotesTempFilesForExternal(
      params: TaskApiParams.CleanNotesTempFilesForExternalParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/manageProblem.php",
         params: { action: "cleanNotesTempFilesForExternal" },
         data: {
            CID: params.customerID,
         },
      });
      return answer;
   }

   public async setItem9FileDescription(
      params: TaskApiParams.SetItem9FileDescriptionParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "setItem9FileDescription" },
         data: {
            fileID: params.fileID,
            fileDescription: params.fileDescription,
         },
      });
      return answer;
   }

   public async getTasksMultiAssetsSchedule78NextDue(
      params: TaskApiParams.GetTasksMultiAssetsSchedule78NextDueParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getTasksMultiAssetsSchedule78NextDue" },
         data: {
            reoccurID: params.reoccurID,
         },
      });
      return answer;
   }

   public async getListOfRelatedTaskTemplatesOnBatchID(
      params: TaskApiParams.GetListOfRelatedTaskTemplatesOnBatchIDParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getListOfRelatedTaskTemplatesOnBatchID" },
         data: {
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async updateListOfRelatedTaskTemplates(
      params: TaskApiParams.UpdateListOfRelatedTaskTemplatesParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateListOfRelatedTaskTemplates" },
         data: {
            sourceChecklistID: params.sourceChecklistID,
            tasksToChange: params.tasksToChange,
            syncItems: params.syncItems,
            syncParts: params.syncParts,
            syncSettings: params.syncSettings,
            syncName: params.syncName,
            syncDescription: params.syncDescription,
            syncAssignments: params.syncAssignments,
            syncRecurrances: params.syncRecurrances,
         },
      });
      return answer;
   }

   public async disableChecklistShare(
      params: TaskApiParams.DisableChecklistShareParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "disableChecklistShare" },
         data: {
            shareID: params.shareID,
            shareTargetEmail: params.shareTargetEmail,
         },
      });
      return answer;
   }

   public async taskShareComplete(
      params: TaskApiParams.TaskShareCompleteParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "checklistShareComplete" },
         data: {
            link: params.link,
         },
      });
      return answer;
   }

   public async getSharedExternalUsers(
      params: TaskApiParams.GetSharedExternalUsersParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getSharedExternalUsers" },
         data: {
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async updateItemBelongsTo(
      params: TaskApiParams.UpdateItemBelongsToParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateItemBelongsTo" },
         data: {
            itemID: params.itemID,
            itemBelongsTo: params.itemBelongsTo,
            chk: params.checklistID,
         },
      });
      return answer;
   }

   public async updateItemWODefault(
      params: TaskApiParams.UpdateItemWODefaultParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateItemWODefault" },
         data: {
            itemID: params.itemID,
            itemWOTemplateID: params.itemWOTemplateID,
            itemWOTemplateLocked: params.itemWOTemplateLocked,
            chk: params.checklistID,
         },
      });
      return answer;
   }

   public async updateItemWOAssignDefault(
      params: TaskApiParams.UpdateItemWOAssignDefaultParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateItemWOAssignDefault" },
         data: {
            chk: params.checklistID,
            itemID: params.itemID,
            itemWOAssignToProfileID: params.itemWOAssignToProfileID,
            itemWOAssignToUserID: params.itemWOAssignToUserID,
            itemWOAssignToLocked: params.itemWOAssignToLocked,
            multiUsers: params.multiUsers,
         },
      });
      return answer;
   }

   public async updateTaskTemplateType(
      params: TaskApiParams.UpdateTaskTemplateTypeParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateTaskTemplateType" },
         data: {
            checklistID: params.checklistID,
            checklistTemplate: params.checklistTemplate,
         },
      });
      return answer;
   }

   public async updateSurveyLinkTitle(
      params: TaskApiParams.UpdateSurveyLinkTitleParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateSurveyLinkTitle" },
         data: {
            surveyLinkTitle: params.surveyLinkTitle,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async updateSurveyLink(
      params: TaskApiParams.UpdateSurveyLinkParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateSurveyLink" },
         data: {
            surveyLink: params.surveyLink,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async copyWRLocationSettings(
      params: TaskApiParams.CopyWRLocationSettingsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "copyWRLocationSettings" },
         data: {
            locationID: params.locationID,
            locationIDToCopy: params.locationIDToCopy,
         },
      });
      return answer;
   }

   public async recreateWorkRequest(
      params: TaskApiParams.RecreateWorkRequestParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "recreateWorkRequest" },
         data: {
            checklistID: params.checklistID,
            templateID: params.templateID,
            profileID: params.profileID,
            userID: params.userID,
            defaultPriorityID: params.defaultPriorityID,
            tags: params.tags,
         },
      });
      return answer;
   }

   public async mergeWorkRequest(
      params: TaskApiParams.MergeWorkRequestParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "mergeWorkRequest" },
         data: {
            checklistID: params.checklistID,
            otherChecklistID: params.otherChecklistID,
         },
      });
      return answer;
   }

   public async setTaskEmailDefaults(
      params: TaskApiParams.SetTaskEmailDefaultsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "setTaskEmailDefaults" },
         data: {
            locationID: params.locationID,
            emailSubject: params.emailSubject,
            emailMessage: params.emailMessage,
         },
      });
      return answer;
   }

   public async setCommentEmailDefaults(
      params: TaskApiParams.SetCommentEmailDefaultsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "setCommentEmailDefaults" },
         data: {
            locationID: params.locationID,
            emailSubject: params.emailSubject,
            emailMessage: params.emailMessage,
         },
      });
      return answer;
   }

   public async setTaskReassignmentEmailDefaults(
      params: TaskApiParams.SetTaskReassignmentEmailDefaultsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "setTaskReassignmentEmailDefaults" },
         data: {
            locationID: params.locationID,
            emailSubject: params.emailSubject,
            emailMessage: params.emailMessage,
         },
      });
      return answer;
   }

   public async setTaskApprovalEmailDefaults(
      params: TaskApiParams.SetTaskApprovalEmailDefaultsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "setTaskApprovalEmailDefaults" },
         data: {
            locationID: params.locationID,
            emailSubject: params.emailSubject,
            emailMessage: params.emailMessage,
         },
      });
      return answer;
   }

   public async updateAssetInfoFromCompletion(
      params: TaskApiParams.UpdateAssetInfoFromCompletionParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "updateAssetInfoFromCompletion" },
         data: {
            assetInfoFromCompletion: params.assetInfoFromCompletion,
            checklistID: params.checklistID,
         },
      });
      return answer;
   }

   public async broadcastTaskOpen(
      params: TaskApiParams.BroadcastTaskOpenParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "broadcastTaskOpen" },
         data: {
            taskID: params.taskID,
            userID: params.userID,
            connectionId: params.connectionId,
         },
      });
      return answer;
   }

   public async broadcastTaskClosed(
      params: TaskApiParams.BroadcastTaskClosedParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "broadcastTaskClosed" },
         data: {
            taskID: params.taskID,
            userID: params.userID,
            connectionId: params.connectionId,
         },
      });
      return answer;
   }

   public async getLocationCycleCountTask(
      params: TaskApiParams.GetLocationCycleCountTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getLocationCycleCountTask" },
         data: { locationID: params.locationID },
      });
      return answer;
   }

   public async createInstructionSet(
      params: TaskApiParams.CreateInstructionSetParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "createInstructionSet" },
         data: { locationID: params.locationID },
      });
      return answer;
   }

   public async deleteInstructionSet(
      params: TaskApiParams.DeleteInstructionSetParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteInstructionSet" },
         data: {
            checklistID: params.checklistID,
            locationID: params.locationID,
         },
      });
      return answer;
   }

   public async removeMentionedUsersFromNotifications(
      params: TaskApiParams.RemoveMentionedUsersFromNotificationsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "removeMentionedUsersFromNotifications" },
         data: {
            checklistID: params.checklistID,
            removeAll: params.removeAll,
         },
      });
      return answer;
   }

   public async getTaskParts(
      params: TaskApiParams.GetTaskPartsParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getTaskParts" },
         data: { checklistID: params.checklistID },
      });
      return answer;
   }

   public async getTaskAssignmentDisplayName(
      params: TaskApiParams.GetTaskAssignmentDisplayNameParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getTaskAssignmentDisplayName" },
         data: { checklistID: params.checklistID },
      });
      return answer;
   }

   public async deleteTimeLoggedFromCompletedTask(
      params: TaskApiParams.DeleteTimeLoggedFromCompletedTaskParams,
   ): Promise<AxiosResponse> {
      const answer = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "deleteTimeLoggedFromCompletedTask" },
         data: { checklistID: params.checklistID },
      });
      return answer;
   }
}
