import { computed, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ModalService, LoadingBarService } from "@limblecmms/lim-ui";
import { from } from "rxjs";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { SetupWorkOrder } from "src/app/tasks/components/setupWorkOrderModal/setupWorkOrder.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({ providedIn: "root" })
export class StartWOService {
   // TODO(rob): Put this in a more central location.
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUser = inject(ManageUser);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageProfile = inject(ManageProfile);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly isPlgSetupWorkOrderEnabled = toSignal(
      from(this.launchFlagsService.isEnabled(Flags.PLG_SETUP_WORK_ORDER)),
      { initialValue: false },
   );

   public canCreateWOs(): boolean {
      return this.credService.checkCredAnywhere(
         this.credService.Permissions.StartNewTasks,
      );
   }

   public startWorkOrder(
      date?: Date | false,
      locationID?: number,
      taskOptFunction?: {
         callback: (data1: any, data2: any) => void;
         runForAllUsers?: boolean;
      },
      allowPickLocation?: boolean,
   ): void {
      if (!this.canCreateWOs()) {
         this.alertService.addAlert(this.lang().cred43Fail, "danger", 10000);
         return;
      }

      //first we need them to pick an asset
      //if they only have one location,
      //or if a location context is present,
      //simply show only that location in pickAssestCtrl.js
      let singleLocation;
      if (locationID != null) {
         singleLocation = locationID;
      } else if (this.manageLocation.getLocations().length == 1) {
         singleLocation = this.manageLocation.getLocations()[0].locationID;
      } else {
         singleLocation = 0;
      }

      //they have turned off picking an asset, so have them pick no asset to start
      //it is important to note that this is only true if there is one location. We don't want tasks going to weird spots.
      //since they don't know what location or asset it is for
      //we assign it to the first location they have an credential at
      if (
         this.manageUser.getCurrentUser().userInfo.customerStartingAWOAskAsset == 0 &&
         this.manageLocation.getLocations().length == 1
      ) {
         const asset: any = {};
         asset.locationID = this.manageLocation.getLocations()[0].locationID;
         asset.assetID = this.isPlgSetupWorkOrderEnabled() ? null : 0;
         this.newWO(asset, date, taskOptFunction, allowPickLocation);
         return;
      }

      const title = this.isPlgSetupWorkOrderEnabled()
         ? this.lang().PleaseSelectAnAsset
         : this.lang().SelectAssetOrLocation;

      if (this.isPlgSetupWorkOrderEnabled()) {
         const unsureAsset: any = {};

         //since they don't know what location or asset it is for
         //we assign it to the first location they have an credential at
         unsureAsset.locationID = this.manageLocation.getLocations()[0].locationID;
         unsureAsset.assetID = undefined;
         this.newWO(unsureAsset, date, undefined, allowPickLocation);

         return;
      }

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.lang().FirstSelectAnAssetOrLocationMsg;
      instance.title = title;
      instance.singleLocation = singleLocation;
      instance.selectOne = true;
      instance.restrictToCred = false;
      instance.iDontKnowOption = true;
      instance.allowPickLocation = allowPickLocation ?? false;

      modalRef.result.then((asset) => {
         if (asset) {
            if (asset === "unsure") {
               const tempAsset: Pick<Asset, "locationID" | "assetID"> = {
                  locationID: singleLocation,
                  assetID: 0,
               };

               //since they don't know what asset it is for,
               //we assign it to the existing location context,
               //or the first location they have an credential at,
               //or their first location if they have no credentials
               if (singleLocation == 0) {
                  const firstValidLocationId = this.manageLocation
                     .getLocations()
                     .find((location) => {
                        return this.credService.isAuthorized(
                           location.locationID,
                           this.credService.Permissions.StartNewTasks,
                        );
                     })?.locationID;
                  tempAsset.locationID =
                     firstValidLocationId ??
                     this.manageLocation.getLocations()[0].locationID;
               }

               this.newWO(tempAsset, date, taskOptFunction, allowPickLocation);
            } else {
               this.newWO(asset, date, taskOptFunction, allowPickLocation);
            }
         }
      });
   }

   private newWO(
      asset: Pick<Asset, "locationID" | "assetID">,
      date?: Date | false,
      taskOptFunction?: {
         callback: (data1: any, data2: any) => void;
         runForAllUsers?: boolean;
      },
      allowPickLocation?: boolean,
   ): void {
      if (
         !this.credService.isAuthorized(
            asset.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred43Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(SetupWorkOrder);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().SetupWorkOrderMsg,
            title: this.lang().SetupWorkOrder,
            data: {
               WO: true,
               assetID: asset.assetID,
               locationID: asset.locationID,
               date: date,
               allowPickLocation: allowPickLocation,
            },
         },
      };

      instance.result.then((data) => {
         if (data) {
            this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
            this.manageTask
               .goLiveWorkOrder(
                  data.WO,
                  data.profileID,
                  data.userID,
                  data.multiUsers,
                  data.timestamp,
                  0,
                  data.timeOfDay,
                  data.startDate,
                  data.startDateTimeOfDay,
                  this.manageUser,
                  this.manageProfile,
               )
               .then(
                  (answer) => {
                     this.loadingBarService.remove();
                     if (answer.data.success == true) {
                        const userID = answer.data.task.userID;
                        const profileID = answer.data.task.profileID;

                        const currentUser = this.manageUser.getCurrentUser();
                        const profiles = currentUser.profileLoc;
                        let assignedToProfileIBelongTo = false;
                        for (const profile of profiles) {
                           if (
                              profile.profileID == profileID &&
                              profile.locationID == asset.locationID
                           ) {
                              assignedToProfileIBelongTo = true;
                           }
                        }

                        if (taskOptFunction?.runForAllUsers) {
                           taskOptFunction.callback(data, answer.data);
                        }

                        if (userID == currentUser.gUserID || assignedToProfileIBelongTo) {
                           if (taskOptFunction && !taskOptFunction.runForAllUsers) {
                              taskOptFunction.callback(data, answer.data);
                           }
                           this.viewTask(answer.data.task.checklistID);
                           this.alertService.addAlert(
                              `${this.lang().successMsgWorkOrderStarted} ${answer.data.task.checklistID} I belong`,
                              "success",
                              5000,
                           );
                        } else {
                           const link = data.WO.task.checklistID.toString();
                           const linkInfo = link
                              ? { link, title: this.lang()?.ViewTask ?? "" }
                              : undefined;
                           this.alertService.addAlert(
                              this.lang()
                                 ?.WorkOrderSuccessfullyStartedAndEmailPushNotificationSent ??
                                 "",
                              "success",
                              7000,
                              undefined,
                              undefined,
                              linkInfo,
                           );
                        }
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  },
                  () => {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  },
               );
         }
      });
   }

   private viewTask(checklistID: number): void {
      const instancePT = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instancePT,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: true,
            },
         },
      };
   }
}
