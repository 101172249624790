<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      title="{{ modalData().title }}"
      (closeModal)="close()"
      id="pickPartHeader"
   />
   <lim-ui-modal-body [scrollable]="true">
      @if (message) {
         <lim-ui-info-panel>
            <div class="panel-container">
               <label class="control-label" [limbleHtml]="message"> </label>
            </div>
         </lim-ui-info-panel>
      }
      <lim-ui-panel id="pickPartHolder">
         <div class="panel-container scroll-height-inheritance">
            <div class="header-action-container">
               <lim-ui-search-all-wrapper class="searchbar-container">
                  @if (allPartsLength != 0) {
                     <lim-ui-search-box
                        searchBox
                        [(searchVal)]="searchBar"
                        (searchValChange)="updateSearch()"
                        [placeholder]="lang().Search"
                        [focusOnLoad]="true"
                     />
                  }
                  @if (searchBar) {
                     <lim-ui-filter-input
                        searchAllCriteria
                        (clearFilterEvent)="searchBar = ''; updateSearch()"
                        [filterCriteria]="searchBar"
                     >
                        <label inputLabel> {{ lang().SearchFilter }}: </label>
                     </lim-ui-filter-input>
                  }
               </lim-ui-search-all-wrapper>
               @if (
                  isNativeMobileApp ||
                  (task &&
                     task.assetID !== null &&
                     task.assetID !== undefined &&
                     task.assetID > 0 &&
                     allPartsLength > 0)
               ) {
                  <span class="btn-container">
                     @if (isNativeMobileApp) {
                        <qr-code-button (scan)="scanQrCallback($event)" />
                     }
                     @if (
                        task &&
                        task.assetID !== null &&
                        task.assetID !== undefined &&
                        task.assetID > 0 &&
                        allPartsLength > 0
                     ) {
                        <span class="btn-container">
                           @if (viewAll) {
                              <lim-ui-secondary-button
                                 icon="cubes"
                                 (click)="viewAll = false; viewAssociated = true"
                                 class="lim-ui-hide-on-mobile"
                                 >{{
                                    lang().ViewAssociatedParts
                                 }}</lim-ui-secondary-button
                              >
                           }
                           @if (viewAll) {
                              <lim-ui-icon-button
                                 icon="cubes"
                                 (click)="viewAll = false; viewAssociated = true"
                                 class="lim-ui-show-on-mobile"
                              />
                           }
                           @if (viewAssociated) {
                              <lim-ui-secondary-button
                                 icon="list"
                                 (click)="viewAssociated = false; viewAll = true"
                                 class="lim-ui-hide-on-mobile"
                                 >{{ lang().ViewAll }}</lim-ui-secondary-button
                              >
                           }
                           @if (viewAssociated) {
                              <lim-ui-icon-button
                                 icon="list"
                                 (click)="viewAssociated = false; viewAll = true"
                                 class="lim-ui-show-on-mobile"
                              />
                           }
                        </span>
                     }
                  </span>
               }
            </div>

            @if (allPartsLength == 0) {
               <b>{{ lang().WhoopsNoPartsToLookup }}</b>
            }

            <parts-filter-selector
               [(searchFields)]="searchFields"
               [(partFilters)]="partFilters"
               [(searchBarField)]="searchBar"
               [optionsJsonMappedToFieldID]="optionsJsonMappedToFieldID"
               [partFields]="partFields"
               (updateSearch)="updateSearch()"
               (filterParts)="buildView()"
            />
            <div
               [hidden]="!(viewAll == true && createPartShow == false)"
               class="scroll-height-inheritance"
            >
               @if (!selectOne) {
                  <div class="lim-ui-pick-list-header">
                     <lim-ui-selection-controls
                        [selectAll]="lang().SelectAll"
                        (handleSelectAll)="selectAllParts()"
                        [deselectAll]="lang().DeselectAll"
                        (handleDeselectAll)="deselectAllParts()"
                     />
                  </div>
               }
               @if (treeData.length) {
                  <hierarchy-container-legacy
                     #hierarchy
                     [treeData]="treeData"
                     [options]="hierarchyOptions"
                  />
               }
            </div>
            @if (viewAssociated == true && createPartShow == false) {
               <div class="associated-list-wrapper">
                  <label class="control-label with-tooltip"
                     >{{ lang().BelowIsAListOfAssociatedParts
                     }}<lim-ui-icon
                        icon="circleQuestionRegular"
                        limUiTooltip="{{
                           lang().ViewOnlyPartsAssociatedToThisAssetTooltip
                        }}"
                  /></label>

                  @for (part of associatedParts; track part) {
                     @if (associatedPartNodes.get(part.partID); as associatedPartNode) {
                        @if (
                           manageParts.getSingleCalculatedPartInfo(part.partID);
                           as calculatedPartInfo
                        ) {
                           <div
                              (click)="selectPart(part)"
                              class="lim-ui-list-item lim-ui-selectable-list-item"
                              [ngClass]="{
                                 'lim-ui-selected-list-item':
                                    associatedPartNode.selected == true,
                              }"
                           >
                              @if (associatedPartNode.selected == true) {
                                 <lim-ui-icon
                                    icon="check"
                                    color="green-color"
                                    limUiTooltip="{{
                                       lang().ViewOnlyPartsAssociatedToThisAssetTooltip
                                    }}"
                                 />
                              }
                              <lim-ui-icon icon="gears" />

                              <b [limbleHtml]="part.partName"></b> -
                              <span [limbleHtml]="part.partNumber"></span> -
                              {{ lang().Qty }}:
                              {{ calculatedPartInfo.totalAvailableQty }}
                              {{ lang().available }}
                              {{ lang().at }}
                              <span
                                 [limbleHtml]="
                                    locationsIndex?.[part.locationID]?.locationName
                                 "
                              ></span>
                           </div>
                        }
                     }
                  }
               </div>
            }
            @if (
               allPartsLength > 0 &&
               viewAll == true &&
               (!searchBar || searchBar.length < 1) &&
               createPartShow == false
            ) {
               <label
                  class="lim-ui-hide-on-mobile control-label warning-text"
                  [limbleHtml]="lang().PartNotHereMsg"
               >
               </label>
            }

            @if (associatedParts.length == 0 && viewAssociated == true) {
               <label
                  class="control-label warning-text"
                  [limbleHtml]="lang().NoAssociatedPartsToThisAssetYetMsg"
               >
               </label>
            }

            @if (allPartsLength == 0) {
               <label
                  class="lim-ui-hide-on-mobile control-label warning-text"
                  [limbleHtml]="lang().YouCurrentlyDoNotHaveAnyPartsToAdd"
               >
               </label>
            }

            @if (allPartsLength > 0 && partsLength == 0) {
               <label
                  class="control-label warning-text"
                  [limbleHtml]="lang().YourSearchDidNotYieldAnyPossibleParts"
               >
               </label>
            }

            <!-- Create Part -->
            @if ((partsLength == 0 || createPartShow == true) && createPartCred) {
               <div>
                  <label class="control-label bold-text"
                     >{{ lang().CreateAPart }}...</label
                  >
                  <div class="create-part-wrapper">
                     <div class="half-column">
                        <div class="form-group">
                           <label class="control-label">{{ lang().PartName }}</label>
                           <div
                              type="text"
                              [(ngModel)]="newPartName"
                              limbleContentEditable
                              disableEditor="true"
                           ></div>
                        </div>
                        <div class="form-group">
                           <label class="control-label">{{ lang().PartPrice }}</label>
                           <input type="number" [(ngModel)]="newPartPrice" min="0" />
                        </div>
                        <div class="form-group">
                           <label class="control-label">{{ lang().Location }}</label>
                           <lim-ui-form-dropdown-input
                              [inputString]="newPartLocationName"
                           >
                              <ng-container menu>
                                 @for (location of newLocations; track location) {
                                    <lim-ui-dropdown-text-item
                                       [displayString]="location.locationName"
                                       (click)="setNewPartLocation(location)"
                                    />
                                 }
                              </ng-container>
                           </lim-ui-form-dropdown-input>
                        </div>
                     </div>
                     <div class="half-column">
                        <div class="form-group">
                           <label class="control-label">{{ lang().PartNumber }}</label>
                           <div class="input-with-button-wrapper">
                              <div
                                 type="text"
                                 [(ngModel)]="newPartNumber"
                                 limbleContentEditable
                                 disableEditor="true"
                                 class="input-with-button"
                              ></div>

                              @if (isNativeMobileApp) {
                                 <qr-code-button (scan)="scanQrCallback($event)" />
                              }
                           </div>
                        </div>
                        <div class="form-group">
                           <label class="control-label">{{
                              lang().StartingPartQty
                           }}</label>
                           <input type="number" [(ngModel)]="newPartQty" min="0" />
                        </div>
                     </div>

                     <div class="form-group">
                        <lim-ui-primary-button
                           (click)="createPart()"
                           [disabled]="createPartDisabled || !canAddParts"
                           [limUiPopover]="createPartPopover"
                           [limUiPopoverType]="'upsell'"
                           [limUiPopoverPlacement]="'right'"
                           [limUiPopoverHidden]="canAddParts"
                           dataLogLabel="manageParts-storeAPart"
                        >
                           {{ lang().Create }}
                        </lim-ui-primary-button>
                     </div>
                  </div>
               </div>
            }

            @if (partsLength == 0 && !createPartCred) {
               <label
                  class="control-label warning-text"
                  [limbleHtml]="lang().CreateAPartWarningNoPermission"
               >
               </label>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      <div class="modal-footer-buttons">
         <div>
            @if (createPartCred && !modalData().hideCreatePart) {
               <lim-ui-secondary-button
                  class="left-button"
                  [icon]="canAddParts ? 'plus' : 'stars'"
                  (click)="clickCreatePartShow()"
                  [disabled]="!canAddParts"
                  [limUiPopover]="createPartPopover"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'right'"
                  [limUiPopoverHidden]="canAddParts"
                  dataLogLabel="manageParts-initiateAPart"
                  >{{ lang().CreatePart }}</lim-ui-secondary-button
               >
            }
         </div>

         <div class="modal-footer-end-buttons">
            @if (createPartShow == false) {
               <lim-ui-primary-button
                  [dataLogLabel]="
                     modalData().dataLogOptions?.taskStoreLabel ??
                     'manageParts-storeAPart'
                  "
                  (click)="submit()"
                  >{{ modalData().buttonText }}</lim-ui-primary-button
               >
            }
         </div>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>

<ng-template #createPartPopover>
   <upsell-popover
      [message]="lang().FeatureAddPartsPopoverMessage"
      [pricingLinkText]="lang().LearnMore"
   />
</ng-template>
