<lim-ui-info-panel class="top-info-panel">
   <!--
                  The description panel uses some special case handling of the collapsed state,
                  so instead of using lim-ui-panel, it uses lim-ui-info-panel which is super
                  basic, and then implements the custom logic for toggling the collapsed state.
                  Specifically, the collapsed state of the description panel is only a
                  partially collapsed state. When collapsed, this panel still shows a single
                  line of the description, instead of hiding everything.
               -->
   <div class="custom-description-title" panelCustomTitle>
      <span class="chk-panel-title">{{ lang().Description }}</span>
      <lim-ui-minimal-icon-button
         [superMinimal]="true"
         [icon]="expandDescription() ? 'angleUpRegular' : 'angleDownRegular'"
         iconSize="small"
         (click)="toggleExpandDescription()"
      />
   </div>
   <div class="fourth-row">
      <div
         class="fourth-row-description"
         [ngClass]="{ 'display-all': expandDescription() === false }"
      >
         <span class="description-and-dates">
            @let checklistInstructions = task().checklistInstructions;
            @if (
               checklistInstructions &&
               checklistInstructions.length > MAX_DESCRIPTION_LENGTH &&
               expandDescription() === false
            ) {
               <div
                  class="Description-style"
                  (click)="toggleExpandDescription()"
                  [limbleHtml]="description()"
               ></div>
            }
            @if (
               taskDescriptionExistsAndBelowMaxLength() || expandDescription() === true
            ) {
               <div
                  class="Description-style"
                  [limbleHtml]="task().checklistInstructions"
               ></div>
            }

            <div class="created-and-edited-dates">
               @if (userStartedThisTask()) {
                  <span>
                     {{ lang().StartedBy }}:
                     {{ userStartedThisTask()?.fullName }}
                  </span>
               }
               @if (task().checklistCreatedDate) {
                  <span>
                     {{ lang().CreatedOn }}:
                     {{
                        (task().checklistCreatedDate ?? 0) * 1000
                           | betterDate: "dateTimeWithSeconds"
                     }}
                  </span>
               }

               @if (
                  task().checklistLastEdited !== null &&
                  task().checklistLastEdited !== 0 &&
                  task().checklistTemplate == 0
               ) {
                  <span>
                     <span class="chkCompleteTaskSpacer">
                        {{ lang().LastEdited }}:
                        {{
                           (task().checklistLastEdited ?? 0) * 1000
                              | betterDate: "dateTimeWithSeconds"
                        }}
                     </span>
                  </span>
               }

               @if (checklistEstTimeMinutes() > 0 || checklistEstTimeHours() > 0) {
                  <span>
                     <span class="chkCompleteTaskSpacer">
                        {{ lang().EstimatedTime }}: {{ checklistEstTimeHours() }}
                        {{ lang().hr }} {{ lang().and }} {{ checklistEstTimeMinutes() }}
                        {{ lang().min }}
                     </span>
                  </span>
               }
            </div>
         </span>
      </div>
   </div>

   <div class="tags-container">
      @if (itemsEditable() && currentUser?.workOrderUser !== 1 && tagTaskCred()) {
         <lim-ui-dropdown>
            <span button class="header-wrapper header-extra-space tags">
               <lim-ui-icon
                  icon="plus"
                  class="text-and-icon-spacing"
                  iconSize="small"
               />{{ lang().addTags }}
            </span>
            <custom-tag-list menu [data]="customTagListObj()" />
         </lim-ui-dropdown>
      }
      @for (tag of displayTags(); track tag) {
         @if (tag.tagged === true) {
            <span class="header-wrapper header-extra-space gray-wrapper tags unclickable">
               @if (
                  itemsEditable() && currentUser?.workOrderUser !== 1 && tagTaskCred()
               ) {
                  <lim-ui-icon
                     icon="xmarkLarge"
                     iconColor="danger"
                     iconSize="small"
                     (click)="clickedTag(tag)"
                     dataLogLabel="task-deleteTag"
                  />
               }

               {{ tag.name }}
            </span>
         }
      }
   </div>
</lim-ui-info-panel>
