import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   IconComponent,
   InputWithPrefixComponent,
   ModalService,
   MinimalIconButtonComponent,
   PanelComponent,
   PrimaryButtonComponent,
   RadioButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { CurrencyService } from "src/app/shared/services/currency.service";
import { Flags } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { LeadTimeCalculationService } from "src/app/shared/services/leadTimeCalculation.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ViewListOfVendorsForPart } from "src/app/vendors/components/viewListOfVendorsForPartModal/viewListOfVendorsForPart.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

type PartVendorRelation = {
   automaticPartRelation?: boolean;
   manualPartRelation?: boolean;
   locationName: string;
   locationCurrencySymbol?: string | null;
   tmpPOItemsIDs: Array<number>;
   vendorID: number;
   defaultForPartPO?: boolean;
   partPrice?: number;
   partNumber?: string;
   oldPartPrice?: number | null;
   oldPartNumber?: string | null;
   dateReceived?: string | null;
};

@Component({
   selector: "part-vendors-tab",
   styleUrls: ["./part-vendors-tab.component.scss"],
   templateUrl: "./part-vendors-tab.component.html",
   imports: [
      PanelComponent,
      IconComponent,
      PrimaryButtonComponent,
      RadioButtonComponent,
      FormsModule,
      InputWithPrefixComponent,
      MinimalIconButtonComponent,
      TooltipDirective,
      CurrencySymbolPipe,
   ],
})
export class PartVendorsTabComponent implements OnInit, OnDestroy {
   @Input() public partID: number | undefined;
   @Input() public dataLogOptions: DataLogEventDefinition | undefined;

   protected readonly i18n = inject(TranslationService).i18n;
   public part: Part | undefined;
   public vendors: Array<Vendor> = [];
   public vendorRelationInfo: Lookup<"vendorID", PartVendorRelation> | undefined;
   public changePartSupplierCred;
   public changePartVendorAssociations;
   public locationID;
   public OpenPurchaseOrderWatchVarSub: any;
   public OpenBillWatchVarSub: any;
   public vendorsWatchVarSub: any;
   public initialLoadCheck: number = 0;
   public readonly currencySymbol: string;
   public locationsIndex;
   public defaultVendorID: number | undefined;

   public partVendorRelationsSub: Subscription;

   private readonly credService = inject(CredService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageParts = inject(ManageParts);
   private readonly managePO = inject(ManagePO);
   private readonly alertService = inject(AlertService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   private readonly leadTimeService = inject(LeadTimeCalculationService);
   private readonly launchService = inject(LaunchFlagsService);
   protected readonly currencyService = inject(CurrencyService);
   protected readonly isVendorLeadTimeEnabled = this.launchService.getFlag<boolean>(
      Flags.TRACK_PART_VENDOR_LEAD_TIME,
      false,
   );

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly credChangePartNumber = computed(() =>
      this.credService.isAuthorized(
         this.part?.locationID ?? 0,
         this.credService.Permissions.ChangePartNumber,
      ),
   );

   protected readonly credChangePartPrice = computed(() =>
      this.credService.isAuthorized(
         this.part?.locationID ?? 0,
         this.credService.Permissions.ChangePartPrice,
      ),
   );

   public constructor() {
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.locationsIndex = this.manageLocation.getLocationsIndex();

      this.partVendorRelationsSub =
         this.manageAssociations.partVendorRelationUpdates$.subscribe((newRelation) => {
            const vendorRelation = this.vendorRelationInfo?.get(newRelation.vendorID);
            if (!vendorRelation) {
               return;
            }

            this.vendorRelationInfo?.set(vendorRelation.vendorID, {
               ...vendorRelation,
               ...newRelation,
            });
            if (newRelation.defaultForPartPO && this.part) {
               this.part.defaultVendorID = newRelation.vendorID;
            }
         });
   }

   public ngOnInit() {
      assert(this.partID);
      this.part = this.manageParts.getPart(this.partID);
      assert(this.part);

      this.defaultVendorID = Number(this.part.defaultVendorID);

      this.changePartSupplierCred = this.credService.isAuthorized(
         this.locationID,
         this.credService.Permissions.ChangePartSupplier,
      );
      this.changePartVendorAssociations = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ChangePartVendorAssociations,
      );

      this.setVendors();

      this.OpenPurchaseOrderWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            if (this.managePO.getOpenPurchaseOrderWatchVar() > 0) {
               this.setVendors();
            }
         },
      );
      this.OpenBillWatchVarSub = this.manageObservables.setSubscription(
         "OpenBillWatchVar",
         () => {
            if (this.managePO.getOpenPurchaseOrderWatchVar() > 0) {
               this.setVendors();
            }
         },
      );
      this.vendorsWatchVarSub = this.manageObservables.setSubscription(
         "vendorsWatchVar",
         () => {
            if (this.managePO.getOpenPurchaseOrderWatchVar() > 0) {
               this.setVendors();
            }
         },
      );
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.OpenPurchaseOrderWatchVarSub,
         this.OpenBillWatchVarSub,
         this.vendorsWatchVarSub,
      ]);

      this.partVendorRelationsSub.unsubscribe();
   }

   deleteManualPartAssociation = (partID, vendorToRemove) => {
      assert(this.part);
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartVendorAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred141Fail, "danger", 10000);
         return;
      }

      //get parts
      this.manageAssociations
         .deleteManualPartVendorAssociation(vendorToRemove.vendorID, partID)
         .then((answer) => {
            if (answer?.data.success === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   };

   setVendors = () => {
      if (
         this.initialLoadCheck < 3 ||
         this.managePO.getOpenPurchaseOrderWatchVar() === 0 ||
         this.managePO.getOpenBillWatchVar() === 0 ||
         this.manageVendor.getWatchVar() === 0
      ) {
         this.initialLoadCheck++;
         return;
      }
      assert(this.part);
      this.vendors = [];

      const allVendors = this.manageVendor.getVendors();
      this.vendorRelationInfo = this.manageAssociations.getAssociatedVendorsForPart(
         this.part.partID,
         this.managePO.getPurchaseOrderItemsByPartID(this.part.partID),
         this.managePO,
      );
      if (!this.vendorRelationInfo) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return;
      }

      for (const [vendorID, relationInfo] of this.vendorRelationInfo.entries()) {
         const vendor = allVendors.get(vendorID);
         if (!vendor) {
            continue;
         }
         relationInfo.oldPartNumber = relationInfo.partNumber ?? null;
         relationInfo.oldPartPrice = relationInfo.partPrice ?? null;
         if (this.isVendorLeadTimeEnabled()) {
            this.leadTimeService
               .getLeadTimeForPart(this.part.partID, vendor.vendorID)
               .subscribe((leadTime) => {
                  if (leadTime !== null && leadTime !== undefined) {
                     const strLeadTime = `${leadTime} ${this.i18n().t(leadTime === 1 ? "day" : "days")}`;
                     vendor.vendorPartAverageLeadTime = strLeadTime;
                  }
               });
         }
         if (this.currencyService.isMultiCurrencyEnabled()) {
            vendor.currencyCode = this.currencyService.getCurrencyCodeByLocationID(
               vendor.locationID,
            );
         } else {
            vendor.currencyCode = this.currencyService.getCurrencyCodeByLocationID(
               this.part.locationID,
            );
         }

         this.vendors.push(vendor);
         relationInfo.defaultForPartPO = vendorID === this.part.defaultVendorID;
      }

      this.vendors = orderBy(this.vendors, "vendorName");
   };

   public async updateNumberToVendorAssociation(
      vendor: Vendor,
      part: any,
   ): Promise<void> {
      const vendorRelation = this.vendorRelationInfo?.get(vendor.vendorID);
      if (!vendorRelation) {
         return;
      }

      if (vendorRelation.oldPartNumber === vendorRelation.partNumber) {
         return;
      }
      const updatedNumberValue = vendorRelation.partNumber ?? "";
      const hasManualAssociation = await this.validateManualAssociation(
         vendorRelation,
         part,
      );
      if (!hasManualAssociation) {
         return;
      }
      const newVendorRelation = this.getSelectedPartVendorRelation(
         vendorRelation.vendorID,
      );
      newVendorRelation.partNumber = updatedNumberValue;
      vendorRelation.oldPartNumber = updatedNumberValue;
      const answer = await this.manageAssociations.updateNumberToVendorAssociation(
         vendorRelation.vendorID,
         part.partID,
         updatedNumberValue,
      );
      if (answer?.data.success === true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   private getSelectedPartVendorRelation(vendorID: number): any {
      const vendorRelations = this.part?.partVendorRelationIDs.map((relationID) =>
         this.manageAssociations.getPartVendorRelation(relationID),
      );
      if (!vendorRelations) {
         return undefined;
      }
      return vendorRelations.find(
         (vendorRelation) => vendorRelation?.vendorID === vendorID,
      );
   }

   public async updatePriceToVendorAssociation(
      vendor: Vendor,
      part: any,
      vendorRelation: PartVendorRelation,
   ): Promise<void> {
      if (vendorRelation.oldPartPrice === vendorRelation.partPrice) {
         return;
      }
      const updatedPriceValue = Number(vendorRelation.partPrice);
      const hasManualAssociation = await this.validateManualAssociation(
         vendorRelation,
         part,
      );
      if (!hasManualAssociation) {
         return;
      }
      //now that state has updated, we need the new
      const newVendorRelation = this.getSelectedPartVendorRelation(
         vendorRelation.vendorID,
      );
      newVendorRelation.partPrice = updatedPriceValue;
      vendorRelation.oldPartPrice = updatedPriceValue;
      const answer = await this.manageAssociations.updatePriceToVendorAssociation(
         vendorRelation.vendorID,
         part.partID,
         updatedPriceValue,
      );
      if (answer?.data.success === true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   private async validateManualAssociation(
      vendorRelation: PartVendorRelation,
      part: any,
   ): Promise<boolean> {
      if (
         vendorRelation.manualPartRelation !== undefined &&
         vendorRelation.manualPartRelation !== false
      ) {
         return true;
      }

      const answer = await this.manageAssociations.associateVendorsToPart(
         [vendorRelation.vendorID],
         part,
      );
      if (answer?.data.success === false) {
         return false;
      }

      return true;
   }

   popVendor = (vendor) => {
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   public setDefaultVendor(vendor: Vendor) {
      assert(this.part);
      if (!this.vendorRelationInfo) {
         return;
      }
      for (const [vendorID, relationInfo] of this.vendorRelationInfo.entries()) {
         if (vendorID === vendor.vendorID) {
            relationInfo.defaultForPartPO = true;
         }
         relationInfo.defaultForPartPO = false;
      }
      this.manageAssociations
         .setDefaultVendorForPart(this.part, vendor.vendorID)
         .then((answer) => {
            if (answer?.data.success === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   }

   protected updatePartPrice($event: any, vendorRelation: PartVendorRelation) {
      vendorRelation.partPrice = Number($event);
   }

   viewPartVendors = () => {
      assert(this.part);
      const instance = this.modalService.open(ViewListOfVendorsForPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            dataLogOptions: this.dataLogOptions,
            data: { partID: this.part.partID },
         },
      };
   };
}
