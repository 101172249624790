import { computed, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { firstValueFrom, map, take } from "rxjs";
import { filter } from "rxjs/operators";
import type { MappedFeatureKey } from "src/app/shared/services/feature-flags/feature-flag-map.types";
import { FeatureFlagMappingService } from "src/app/shared/services/feature-flags/feature-flag-mapping.service";
import { FeatureFlagRefreshService } from "src/app/shared/services/feature-flags/feature-flag-refresh.service";
import { FeatureFlagSet } from "src/app/shared/services/feature-flags/feature-flag-set.model";

@Injectable({ providedIn: "root" })
export class FeatureFlagService {
   private readonly refreshService = inject(FeatureFlagRefreshService);
   private readonly mappingService = inject(FeatureFlagMappingService);

   public readonly featureSet = toSignal(
      this.refreshService.refreshes$.pipe(map((dto) => this.mappingService.mapDto(dto))),
      { initialValue: null },
   );

   /**
    * @deprecated Use `flagMap` instead.
    * That is because `flagMap` is null until the initial fetch is done,
    * allowing the consumer to accurately handle the loading state.
    * This unsafe version will simply return false for all flags until the initial fetch is done,
    * potentially leading to flickering or jumping UI.
    */
   public readonly featureSetDefaulted = computed(
      () => this.featureSet() ?? new FeatureFlagSet({}),
   );

   public async isEnabled(flagName: MappedFeatureKey): Promise<boolean> {
      const currentSet = this.featureSet();

      if (currentSet === null) {
         const refreshPromise = firstValueFrom(
            this.refreshService.refreshes$.pipe(
               map((dto) => this.mappingService.mapDto(dto)),
               filter((featureSet) => featureSet !== null),
               take(1),
            ),
         );

         this.refreshService.refresh();

         try {
            await refreshPromise;
         } catch (error) {
            console.error(new Error(`Failed to retrieve feature flags`));
            return false;
         }
      }

      const finalSet = this.featureSet();
      return Boolean(finalSet?.has(flagName));
   }
}
