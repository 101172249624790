import { NgClass, UpperCasePipe } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, forwardRef, computed } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import { IconComponent, LimbleHtmlDirective, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { FilterArrayPipe } from "src/app/shared/pipes/filterArray.pipe";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { BetterDate } from "src/app/shared/services/betterDate";
import { WhiteLabelService } from "src/app/shared/services/white-label/white-label.service";
import type {
   BulkPrintTasksSettings,
   TimeLoggedRecord,
} from "src/app/shared/types/general.types";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ChkItem } from "src/app/tasks/components/chkItemElement/chkItem.element.component";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { CommentCalculatedInfo } from "src/app/tasks/types/comment/comment.types";
import type { ExtraTime } from "src/app/tasks/types/extra-time/extra-time.types";
import type { TaskInfo } from "src/app/tasks/types/info/task-info.types";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services//manageUser";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "task-print-template-legacy",
   templateUrl: "./task-print-template-legacy.component.html",
   styleUrls: ["./task-print-template-legacy.component.scss"],
   imports: [
      LimbleHtmlDirective,
      IconComponent,
      forwardRef(() => ChkItem),
      TooltipDirective,
      NgClass,
      FileListItem,
      ViewImage,
      UpperCasePipe,
      BetterCurrencyPipe,
      BetterDatePipe,
      BetterDecimalPipe,
      FilterArrayPipe,
      IconAlias,
      OrderByPipe,
   ],
})
export class TaskPrintTemplateLegacyComponent implements OnInit {
   @Input() data?: {
      checklistID: number;
      items: Array<any>;
      info: TaskInfo;
      asset: Asset | undefined;
      displayAssetInfo: any;
      flag?: any;
      task?: Task | TaskTemplateEntity;
   };
   @Input() informationToInclude: BulkPrintTasksSettings | undefined = {
      status: 1,
      priority: 1,
      type: 1,
      assignment: 1,
      location: 1,
      asset: 1,
      dueDate: 1,
      downtime: 1,
      description: 1,
      instructions: 1,
      completionNotes: 1,
      comments: 1,
      parts: 1,
      invoices: 1,
      completedBy: 1,
      completedDate: 1,
      assetInformation: 1,
      timeSpent: 1,
   };
   @Input() parts;

   public title;
   public task!: Task | TaskTemplateEntity;
   public currentUser;
   public customerID;
   public logoURL;
   public userAssignedTo;
   public items;
   public info;
   public currencySymbol;
   public partsLookup: Lookup<"partID", Part>;
   public asset: Asset | undefined;
   public assetFieldValues: Array<AssetFieldValue> = [];
   public displayAssetInfo;
   public assetInfoFromCompletionArr;
   public checklistEstTimeHours;
   public checklistEstTimeMinutes;
   public checklistDowntimeHours;
   public checklistDowntimeMinutes;
   public checklistPromptTimeHours;
   public checklistPromptTimeMinutes;
   public billableHours;
   public billableMinutes;
   public categoriesIndex;
   public viewLaborCostsCred;
   public viewInvoicesCred;
   public limbleTreeOptions;
   public flag;
   public fileName;
   public userCompletedBy;
   public partCostOnPrintFlag;
   public assetNameStr: string = "";
   protected readonly crossOriginAnonymous: boolean;
   protected readonly logoURLDark;
   protected useCustomLogo: boolean = false;
   protected customLogoURL: string | undefined;
   protected taskDisplayInfo:
      | {
           locationTaskImage: string | null;
           locationName: string;
           taskIcon: Aliases;
           typeStr: string | undefined;
           priorityName: string;
           statusName: string | undefined;
           displayName: string;
           completedByStr: string | undefined;
           completedFirstName: string | undefined;
           completedLastName: string | undefined;
           noteData: Map<number, CommentCalculatedInfo>;
        }
      | undefined;
   protected invoices;
   protected timeLogged: Array<TimeLoggedRecord> | undefined;

   private readonly manageUser = inject(ManageUser);
   private readonly manageParts = inject(ManageParts);
   private readonly manageBilling = inject(ManageBilling);
   private readonly credService = inject(CredService);
   private readonly betterDate = inject(BetterDate);
   public readonly manageAsset = inject(ManageAsset);
   private readonly whiteLabelService = inject(WhiteLabelService);
   protected readonly manageTask = inject(ManageTask);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageLocation = inject(ManageLocation);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.crossOriginAnonymous = /Chrome|FireFox/.test(window.navigator.userAgent);
      this.logoURLDark = this.whiteLabelService.logoUrlDark();

      this.partsLookup = this.manageParts.getParts();
      this.currentUser = this.manageUser.getCurrentUser();
      this.customerID = this.currentUser.userInfo.customerID;
      this.setCustomLogo(this.currentUser.userInfo.customLogoFileName);
   }

   public ngOnInit() {
      if (!this.data) {
         return;
      }
      this.info = this.data?.info;
      this.items = this.data?.items;
      // NOTE: This should be cleanup once the JIT is completely rollout
      if (this.data.task === undefined) {
         const task = this.manageTask.getTaskLocalLookup(this.data.checklistID);
         if (task === undefined) {
            throw new Error("Task undefined in taskPrintTemplate");
         }
         this.task = task;
      } else {
         this.task = this.data.task;
      }
      this.asset = this.data?.asset;

      if (this.asset) {
         for (const valueID of this.asset?.assetValueIDs) {
            const value = this.manageAsset.getFieldValue(valueID);
            if (value) {
               this.assetFieldValues.push(value);
            }
         }
         this.assetNameStr = this.manageAsset.getAssetNameIncludeParents(
            this.asset.assetID,
         );
      }
      this.invoices = this.manageInvoice.getInvoicesIndex();
      this.flag = this.data?.flag;
      this.displayAssetInfo = this.data?.displayAssetInfo;
      this.assetInfoFromCompletionArr = this.task.assetInfoFromCompletion
         ? JSON.parse(this.task.assetInfoFromCompletion)
         : [];
      this.fileName = `${this.task.checklistName}-${
         this.task.checklistID
      }-${this.betterDate.formatBetterDate(new Date(), "date")}`;

      this.partCostOnPrintFlag = Number(
         this.manageUser.getCurrentUser().userInfo.partCostOnPrintFlag,
      );

      this.limbleTreeOptions = {
         allowNesting: () => {
            return true;
         },
         defaultComponent: { class: ChkItem, bindings: { info: this.info } },
         indent: 20,
      };

      this.checklistDowntimeHours = Math.floor((this.task.checklistDowntime ?? 0) / 3600);

      this.checklistDowntimeMinutes = Math.floor(
         ((this.task.checklistDowntime ?? 0) / 60) % 60,
      );

      this.checklistPromptTimeHours = Math.floor(
         (this.task.checklistPromptTime ?? 0) / 3600,
      );

      this.checklistPromptTimeMinutes = Math.floor(
         ((this.task.checklistPromptTime ?? 0) / 60) % 60,
      );

      this.categoriesIndex = this.manageBilling.getCategoriesIndex();

      if (this.task.billableTime) {
         this.billableHours = Number(Math.floor(this.task.billableTime / 3600));
         this.billableMinutes = Number(Math.floor((this.task.billableTime / 60) % 60));
      }
      this.viewLaborCostsCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.ViewLaborCosts,
      );
      this.viewInvoicesCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.ViewTaskInvoices,
      );

      if (this.task.checklistEstTime) {
         this.checklistEstTimeHours = Number(
            Math.floor(this.task.checklistEstTime / 3600),
         );
         this.checklistEstTimeMinutes = Number(
            Math.floor((this.task.checklistEstTime / 60) % 60),
         );
      }

      this.logoURL = this.whiteLabelService.logoUrl();
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      this.userAssignedTo = this.manageUser.getUser(Number(this.task.userID));

      this.userCompletedBy = this.manageUser.getUser(
         Number(this.task.checklistUserCompleted),
      );

      this.timeLogged = this.task.extraTimeIDs?.map((extraTimeID) =>
         this.buildExtraTimeData(extraTimeID),
      );

      this.buildTaskDisplayInfo();
   }

   private setCustomLogo(fileName: string | null) {
      if (!fileName) {
         this.useCustomLogo = false;
         this.customLogoURL = "";
         return;
      }
      this.customLogoURL = `viewFile.php?f=upload-${this.customerID}/customLogo/${fileName}`;
      this.useCustomLogo = true;
   }

   private buildExtraTimeData(extraTimeID: number): TimeLoggedRecord {
      const extraTime = this.manageTask.getExtraTime(extraTimeID);

      const calculatedData = this.manageTask.getExtraTimeCalculatedData(
         extraTime as ExtraTime,
      );

      return {
         ...extraTime,
         ...calculatedData,
      };
   }

   private buildTaskDisplayInfo() {
      if (this.task === undefined) return;
      const { locationName, locationTaskImage } = this.manageLocation.getLocation(
         this.task.locationID,
      ) ?? { locationName: "", locationTaskImage: null };
      const completionInfo = this.manageTask.getCompletedTaskCalculatedInfo(this.task);
      this.taskDisplayInfo = {
         locationName,
         locationTaskImage,
         taskIcon: this.taskTypeService.getTaskTypeIcon(this.task),
         typeStr: this.manageTask.getTypeDisplay(this.task),
         priorityName: this.manageTask.getPriorityInfo(this.task.priorityID).priorityName,
         statusName: this.manageTask.getStatusInfo(this.task.statusID)?.statusName,
         displayName: this.manageTask.getTaskAssignmentInfo(this.task).displayName,
         completedByStr: completionInfo?.completedByStr,
         completedFirstName: completionInfo?.completedFirstName,
         completedLastName: completionInfo?.completedLastName,
         noteData: this.manageTask.buildNoteDataMapForSingleTaskLegacy(this.task),
      };
   }
}
