import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import type { Aliases, Colors, LimUiModalRef, Sizes } from "@limblecmms/lim-ui";
import { ModalService, UpsellModalComponent } from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "popup-upsell-modal",
   templateUrl: "./popup-upsell-modal.component.html",
   styleUrls: ["./popup-upsell-modal.component.scss"],
   imports: [UpsellModalComponent],
})
export class PopupUpsellModal implements OnInit, OnDestroy {
   protected icon: Aliases | undefined;
   protected title: string | undefined;
   protected message: string | undefined;
   protected iconColor: Colors | undefined;
   protected iconSize: Sizes | undefined;
   protected actionText: string | undefined;
   protected cancelText: string | undefined;
   protected actionLink: string | undefined;
   protected daysLeft: number = 1;
   protected numberOfLocations: number = 0;
   protected locationsString: string = "";
   protected numberOfParts: number = 0;
   protected partsString: string = "";
   protected numberOfVendors: number = 0;
   protected vendorsString: string = "";
   protected listType: "enterprise" | "premium" | undefined;
   public activeModal: LimUiModalRef | null;
   private readonly partsWatchVarSub: Subscription | null;
   private readonly locationWatchVar: Subscription | null;
   private readonly vendorsWatchVarSub: Subscription | null;
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageParts = inject(ManageParts);
   private readonly manageVendors = inject(ManageVendor);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.activeModal = this.modalService.getActiveModal();

      this.vendorsWatchVarSub = this.manageObservables.setSubscription(
         "vendorsWatchVar",
         () => {
            if (this.listType != "premium") {
               return;
            }
            this.vendorsString = "";
            const vendors = this.manageVendors.getVendors();

            this.numberOfVendors = vendors.size;
            if (this.numberOfVendors > 0) {
               for (const vendor of vendors.slice(0, 1)) {
                  this.vendorsString += ` ${vendor.vendorName}`;
               }
            }
         },
      );

      this.partsWatchVarSub = this.manageObservables.setSubscription(
         "partsWatchVar",
         () => {
            this.partsString = "";
            const parts = this.manageParts.getParts();

            this.numberOfParts = parts.size;
            let index = 1;
            let maxNumberOfPartsToShow = 9;
            if (this.listType == "premium") {
               maxNumberOfPartsToShow = 1;
            }
            for (const part of parts.slice(0, maxNumberOfPartsToShow)) {
               this.partsString += ` ${part.partName}`;
               if (index < maxNumberOfPartsToShow - 1) {
                  this.partsString += ", ";
               }
               index++;
            }
         },
      );

      this.locationWatchVar = this.manageObservables.setSubscription(
         "locationWatchVar",
         () => {
            const locations = this.manageLocation.getLocations();

            this.numberOfLocations = locations.length;
            this.locationsString = "";
            let maxNumberOfLocationsToShow = 3;
            let index = 1;
            if (this.listType == "premium") {
               maxNumberOfLocationsToShow = 1;
               index = 0;
            }

            for (const location of locations.slice(1, maxNumberOfLocationsToShow)) {
               this.locationsString += ` ${location.locationName}`;
               if (index < maxNumberOfLocationsToShow - 1) {
                  this.locationsString += ", ";
               }
               index++;
            }
         },
      );
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      const data = params.resolve.data;

      this.icon = data.icon;
      this.title = data.title;
      this.message = data.message;
      this.iconColor = data.iconColor;
      this.iconSize = data.iconSize;
      this.actionText = data.actionText;
      this.cancelText = data.cancelText;
      this.actionLink = data.actionLink;
      this.listType = data.listType;
      this.daysLeft = data.daysLeft;
   }

   public ngOnDestroy(): void {
      this.partsWatchVarSub?.unsubscribe();
      this.locationWatchVar?.unsubscribe();
      this.vendorsWatchVarSub?.unsubscribe();
   }

   openActionLink = () => {
      if (this.actionLink) {
         window.open(this.actionLink, "_blank");
      }
   };
}
