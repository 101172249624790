import { computed, inject, Injectable } from "@angular/core";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { CurrenciesQueriesService } from "src/app/purchasing/currency/services/queries/currencies-queries.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api";
import type { Task } from "src/app/tasks/types/task.types";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

@Injectable({ providedIn: "root" })
export class CurrencyService {
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   public readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;
   private readonly manageLocation = inject(ManageLocation);
   private readonly currenciesQueries = inject(CurrenciesQueriesService);

   private readonly currenciesQuery = injectQuery(() => ({
      ...this.currenciesQueries.list(),
   }));
   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly currencies = computed(() => this.currenciesQuery.data() ?? []);

   public getTaskCurrencyCode(task: Task | TaskEntity): string {
      return this.getCurrencyCodeByLocationID(task.locationID);
   }

   public getCurrencyCodeByLocationID(locationID: number): string {
      const currencyID = this.manageLocation.getLocation(locationID)?.currencyID ?? 0;
      const locationCurrencyCode =
         this.currencies().find((currencyRow) => currencyRow.id === currencyID)?.code ??
         [];
      return this.currencyDisplayService.evaluateSignal(
         computed(() => this.accountCurrencyQuery.data()?.currencyCode),
         computed(() => locationCurrencyCode),
         this.isMultiCurrencyEnabled,
      )();
   }
}
