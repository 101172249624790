<p-tag
   [attr.aria-label]="tooltipFinal()"
   role="tooltip"
   [rounded]="rounded()"
   [severity]="severity()"
   [value]="value()"
   [styleClass]="styleClass()"
   [limUiTooltip]="tooltipFinal()"
>
   @if (iconName()) {
      <ng-template #icon>
         <lim-ui-icon [icon]="iconName()!" iconColor="inherit" iconSize="small" />
      </ng-template>
   }
</p-tag>
