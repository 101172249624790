import {
   Directive,
   EventEmitter,
   HostListener,
   Input,
   Output,
   computed,
   inject,
   type OnInit,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { ModalService } from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";

@Directive({
   selector: "[assetValueReductionValidator]",
   standalone: true,
})
export class AssetValueReductionValidator implements OnInit {
   @Input("assetValueReductionValidator") field!: AssetFieldValue;
   @Output() readonly valueAccepted = new EventEmitter<number | null>();

   private initialValue: number | null = null;
   private readonly ngControl = inject(NgControl);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLang = inject(ManageLang);
   private readonly manageTask = inject(ManageTask);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      assert(this.field);
      this.initialValue = (this.field.valueContent as number) ?? 0;
   }

   @HostListener("blur", ["$event.target"])
   public async verify(source: HTMLInputElement) {
      if (await this.matchesModalCriteria(source.valueAsNumber)) {
         const modal = this.modalService.open(Confirm);
         this.paramsService.params = {
            modalInstance: modal,
            resolve: {
               message: this.lang().AreYouSureYouWantToEnterALowerValue,
               title: this.lang().DecreaseValue,
            },
         };
         modal.result.then((result) => {
            const acceptedValue = 1;
            if (result === acceptedValue) {
               this.initialValue = source.valueAsNumber;
               this.valueAccepted.emit(source.valueAsNumber);
            } else {
               this.ngControl.control?.setValue(this.initialValue);
            }
         });
         return;
      }

      const valueHasChanged = this.initialValue !== source.valueAsNumber;
      if (valueHasChanged) {
         this.initialValue = source.valueAsNumber;
         this.valueAccepted.emit(source.valueAsNumber);
      }
   }

   private async matchesModalCriteria(updatedValue: number | null) {
      return (
         typeof this.initialValue === "number" &&
         typeof updatedValue === "number" &&
         this.initialValue > updatedValue &&
         (await this.manageAsset.doesFieldHavePMType7Recurrence(this.field))
      );
   }
}
