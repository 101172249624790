<div class="lim-ui-list-item">
   <lim-ui-icon class="lim-ui-hide-on-mobile" icon="gears" iconSize="small" />
   <div class="arrow-adjustable-input">
      <input
         type="number"
         min="1"
         [disabled]="!canEditPartsCred()"
         [(ngModel)]="associatedPartQty"
         (blur)="updateAssetPartQty()"
      />
   </div>
   @let calculatedPartInfoVar = calculatedPartInfo();
   @if (calculatedPartInfoVar) {
      @if (unitOfMeasureService.isFeatureEnabled()) {
         <div [limUiTooltip]="partAndRelation().partID | partUnitOfMeasure: 'singular'">
            {{ partAndRelation().partID | partUnitOfMeasure: "short" }}
         </div>
      }
      <span>
         <a (click)="popPart()" class="table-link">
            <span [limbleHtml]="partAndRelation().partName"></span>
            <span [hidden]="!partAndRelation().partNumber">
               - <span [limbleHtml]="partAndRelation().partNumber"></span
            ></span>
         </a>
         - {{ lang().Qty }}: {{ calculatedPartInfoVar.totalQty }}
         @if (unitOfMeasureService.isFeatureEnabled()) {
            <span
               [limUiTooltip]="partAndRelation().partID | partUnitOfMeasure: 'singular'"
               >{{ partAndRelation().partID | partUnitOfMeasure: "short" }}</span
            >
         }
         -

         @if (partLocation().length > 0) {
            <span><span [limbleHtml]="partLocation()"></span> - </span>
         }
         @if (
            locationsIndex[partAndRelation().locationID] &&
            locationsIndex[partAndRelation().locationID].locationName
         ) {
            <span class="house-wrapper">
               <lim-ui-icon icon="houseChimney" />
               <span
                  [limbleHtml]="locationsIndex[partAndRelation().locationID].locationName"
               ></span>
            </span>
         }
      </span>
   }
   <div class="relation-icons">
      @if (!partHasManualRelation(partAndRelation())) {
         <lim-ui-icon
            placement="left"
            limUiTooltip="{{ lang().PartAutomaticAssociationTooltip }}"
            icon="wandMagicSparkles"
         />
      }
      <lim-ui-minimal-icon-button
         (click)="deletePartRelation(partAndRelation())"
         placement="left"
         limUiTooltip="{{ lang().RemoveAssociation }}"
         icon="trashCanRegular"
         iconColor="danger"
         [superMinimal]="true"
      />
   </div>
</div>
