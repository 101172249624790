@if (isVisible() === true) {
   <lim-ui-alert
      alertType="info"
      [dismissible]="true"
      displayMode="banner"
      (dismissedEvent)="dismissed.set(true)"
   >
      You have PM Suggestions to review:
      <span class="pm-suggestions-count">{{ count.value() }}</span>
   </lim-ui-alert>
}
