<div class="panel-body panel-middleGreen" style="border-bottom: 0">
   <lim-ui-panel class="scroll-height-inheritance" [noSidePadding]="true">
      <div class="header">
         @if (title()) {
            <div class="title-text">
               <lim-ui-icon iconSize="small" icon="squareCheck" />
               <span>{{ title() }}</span>
            </div>
         }
         <div class="title-buttons-and-search">
            @if (!isLoading() && itemCount() > 0) {
               <lim-ui-icon-button
                  icon="cloudDownloadRegular"
                  limUiTooltip="{{ lang().ExportListToExcel }}"
                  (click)="downloadExcel()"
               />
            }

            <data-viewer-search (searchChange)="onSearchChange($event)" />
         </div>
      </div>

      @if (isLoading()) {
         <ngx-skeleton-loader count="3" [theme]="skeletonThemes.fillSize" /><br />
      }

      @if (itemCount() === 0 && !isLoading()) {
         <div class="empty-state-wrap">
            @if (noSearchResults()) {
               <no-search-results />
            } @else {
               <span>{{ lang().NoUsedPartsForAsset }}</span>
            }
         </div>
      }

      @if (!isLoading() && itemCount() > 0) {
         <div class="listTable scroll-height-inheritance">
            <div
               class="listItem listItemHeader scrollable-list-header table-scrollable-page-header"
            >
               @for (column of columns; track column) {
                  <sort-column
                     class="col-md-{{ column.columnWidth }}"
                     column="{{ column.sortBy }}"
                     name="{{ column.displayName }}"
                     [bind]="sort"
                     (bindChange)="onSortChanged($event)"
                     [ngStyle]="{
                        'text-align': column.textAlign ? column.textAlign : 'left',
                     }"
                  />
               }
            </div>
            <lim-ui-scroll-container [thin]="true" class="tasks-container">
               @for (partUsage of partUsages(); track partUsage; let isLast = $last) {
                  <div class="table-row-container">
                     <div
                        class="lim-ui-responsive-table-row listItem"
                        [ngClass]="[isLast ? 'last' : '']"
                     >
                        <div class="col-md-2 lim-ui-responsive-table-cell">
                           <a (click)="popPart(partUsage.partID, partUsage.locationID)">{{
                              partUsage.partName
                           }}</a>
                        </div>

                        <div class="col-md-2 lim-ui-responsive-table-cell">
                           {{
                              partUsage.checklistCompletedDate * 1000
                                 | betterDate: "dateTime"
                           }}
                        </div>

                        <div class="col-md-4 lim-ui-responsive-table-cell">
                           {{ partUsage.usedNumber }} -
                           <a (click)="popTask(partUsage.checklistID)">{{
                              partUsage.checklistName
                           }}</a>
                           -
                           <a
                              (click)="
                                 popAsset(partUsage.assetID, partUsage.asset?.locationID)
                              "
                              class="green-color"
                              >{{ partUsage.asset?.assetName ?? "" }}</a
                           >
                        </div>

                        <div class="col-md-2 lim-ui-responsive-table-cell">
                           {{ partUsage.partNumber }}
                        </div>

                        <div class="col-md-2 lim-ui-responsive-table-cell">
                           {{ partUsage.totalPriceOfParts }}
                        </div>
                     </div>
                  </div>
               }
            </lim-ui-scroll-container>

            @if (isLoading()) {
               <div>
                  <div class="loading-icon">
                     <lim-ui-loading-animation />
                  </div>
               </div>
            }
         </div>
      }
      <div panelFooter class="pagination">
         @if (!isLoading() && itemCount() > 0) {
            <data-viewer-paginator
               class="table-footer pagination-container modal-pagination"
               [totalItems]="itemCount()"
               [page]="state.page()"
               [pageSize]="state.pageSize()"
               (pageChange)="onPageChange($event)"
               (pageSizeChange)="onPageSizeChange($event)"
            />
         }
      </div>
   </lim-ui-panel>
</div>
