import { inject, Injectable } from "@angular/core";
import { ModalService } from "@limblecmms/lim-ui";
import { ParamsService } from "src/app/shared/services/params.service";
import { EditCustomTag } from "src/app/tasks/components/editCustomTagModal/editCustomTag.modal.component";

@Injectable({
   providedIn: "root",
})
export class TagEditorService {
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);

   public openEditTagModal(
      tag: { name: string; tagged: boolean },
      sources: Array<string | false>,
      tags: Array<{ name: string; tagged: boolean }>,
      renameTagCallback: (
         oldName: string,
         tag: { name: string; tagged: boolean },
      ) => void,
      deleteTagCallback?: (tag: { name: string; tagged: boolean }) => void,
   ) {
      const instance = this.modalService.open(EditCustomTag);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               tag,
               sources,
               tags,
               renameTag: renameTagCallback,
            },
         },
      };

      instance.result.then(
         (data) => {
            if (data?.deleted && deleteTagCallback) {
               deleteTagCallback(data.tag);
            }
         },
         (data) => {
            if (data?.deleted && deleteTagCallback) {
               deleteTagCallback(data.tag);
            }
         },
      );
   }
}
