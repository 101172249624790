import { computed, inject, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageUser } from "src/app/users/services//manageUser";

@Injectable({ providedIn: "root" })
export class ManageDepreciationSchedule {
   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public getValueAtTime(
      schedule,
      timeStamp: number,
      standardUsefulLifeUnix: number,
   ): number {
      const elapsedSecondsSincePurchase = timeStamp - schedule.depreciationStartDate;
      const usefulLifeLeft = standardUsefulLifeUnix - elapsedSecondsSincePurchase;

      const depreciationPerSecond =
         (schedule.purchaseCost - schedule.salvageValue) / standardUsefulLifeUnix;

      const valueAtTimeStamp =
         schedule.purchaseCost -
         depreciationPerSecond * (standardUsefulLifeUnix - usefulLifeLeft);

      switch (true) {
         case timeStamp < schedule.depreciationStartDate:
            return schedule.purchaseCost;

         case valueAtTimeStamp < schedule.salvageValue:
            return schedule.salvageValue;

         case valueAtTimeStamp <= 0 || valueAtTimeStamp > schedule.purchaseCost:
            return 0;

         case valueAtTimeStamp > 0:
            return valueAtTimeStamp;
         default:
            throw new Error("error in switch statement, manageDepreciationSchedule @43");
      }
   }

   public getEndOfDepreciationScheduleTimestamp(schedule): number {
      const standardUsefulLifeInSeconds = schedule.standardUsefulLife * 86400 * 30.437;

      return Number(schedule.depreciationStartDate) + standardUsefulLifeInSeconds;
   }

   public getUserOrProfileDisplayForDepreciation(
      userID: number | undefined,
      profileID: number | undefined,
   ): string {
      if (userID) {
         const profile = this.manageUser.getProfilesIndex()[userID];
         if (profile) {
            return profile.profileDescription;
         }
         return this.lang().Unassigned;
      } else if (profileID) {
         const user = this.manageUser.getUser(profileID);
         if (user) {
            return `${user.userFirstName} ${user.userLastName}`;
         }
         return this.lang().Unassigned;
      }
      return this.lang().Unassigned;
   }
}
