//component for popup for viewing images in the doka library.
import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input } from "@angular/core";
import { IconComponent, type Sizes } from "@limblecmms/lim-ui";
import { ManageFiles } from "src/app/files/services/manageFiles";

@Component({
   selector: "view-image",
   templateUrl: "./viewImage.element.component.html",
   styleUrls: ["./viewImage.element.component.scss"],
   imports: [NgClass, IconComponent],
})
export class ViewImage implements OnInit {
   @Input() public src;
   @Input() public size: Sizes = "medium";
   public crossOriginAnonymous;
   private readonly manageFiles = inject(ManageFiles);

   public ngOnInit() {
      this.crossOriginAnonymous = /Chrome|FireFox/.test(window.navigator.userAgent);
   }

   viewImage = (src) => {
      this.manageFiles.createImageViewer(src);
   };
}
