import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   InfoPanelComponent,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   RadioButtonComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { Subject, debounceTime, distinctUntilChanged } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationHierarchyService } from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy.service";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AutoFocusDirective } from "src/app/shared/directives/autofocus/autoFocus.directive";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { isNullish } from "src/app/shared/utils/app.util";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

type VendorHierarchyNode = HierarchyNode & {
   vendorID?: number;
};

@Component({
   selector: "pick-new-vendor-type",
   templateUrl: "./pickNewVendorType.modal.component.html",
   styleUrls: ["./pickNewVendorType.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      FormsModule,
      AutoFocusDirective,
      FormDropdownInputComponent,
      SearchBoxComponent,
      DropdownDividerComponent,
      DropdownTextItemComponent,
      NgClass,
      PanelComponent,
      RadioButtonComponent,
      NoSearchResults,
      HierarchyContainerLegacy,
      BasicModalFooterComponent,
   ],
})
export class PickNewVendorType implements OnInit, OnDestroy {
   private resolve;
   private modalInstance;
   public message;
   public title;
   public errorMsg;
   public selection: 1 | 2;
   public locationKnown: boolean = false;
   public vendors: Lookup<"vendorID", Vendor> = new Lookup("vendorID");
   public tempLocations: Array<any>;
   public treeData: Array<HierarchyNode> | undefined;
   public loadCount: number;
   public loadedHier: boolean;
   public allLocations;
   public name: string | null | undefined;
   public locationID: number | undefined;
   public locations;
   public selectedLocationName;
   public totalLocLength;
   public searchLocations;
   public searchString: string;
   public locLength;
   public noSearchResults;
   public fromLocationID;
   private readonly search$: Subject<string>;
   private readonly searchListener: Subscription;
   public vendorNodes: LimbleMap<number, VendorHierarchyNode>;
   public vendorSearchHints: Map<number, string> = new Map();
   public hierarchyOptions: HierarchyOptions;
   public dontChangeName: boolean = false;

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly betterDate = inject(BetterDate);
   private readonly credService = inject(CredService);
   private readonly locationHierarchyService = inject(LocationHierarchyService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.errorMsg = false;
      this.selection = 1;
      this.vendorNodes = new LimbleMap();
      this.tempLocations = [];
      this.loadCount = 0;
      this.loadedHier = false;
      this.searchString = "";
      this.search$ = new Subject();
      this.searchListener = this.search$
         .pipe(distinctUntilChanged(), debounceTime(250))
         .subscribe((searchString) => {
            this.searchString = searchString;
            this.buildHierView();
         });
      this.hierarchyOptions = {
         idKey: "partID",
         selection: { singleSelection: true },
         nodeButtons: [
            {
               tooltip: this.lang().OpenThisVendor,
               clickFunction: this.runPopVendor.bind(this),
               permissionNumber: this.credService.Permissions.ViewLookUpAVendor,
               text: this.lang().View,
            },
         ],
         onSelect: this.setName.bind(this),
         submit: this.submit.bind(this),
      };
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.locationKnown = this.resolve.locationKnown;
      this.fromLocationID = this.resolve.fromLocationID;

      if (this.locationKnown && !this.fromLocationID) {
         throw new Error("locationKnown is true but fromLocationID is not set");
      }

      //needed for just their locations if they need to pick which location this vendor should be in (show.newLocation)
      this.tempLocations = this.manageLocation.getLocations();

      for (const location of this.tempLocations) {
         location.selected = false;
      }

      this.buildLocData();

      this.manageLocation.getAllLocations().then((answer) => {
         //needed for for copying from other locations
         this.allLocations = answer.data.locations ?? [];
      });

      this.name = "";

      this.locationID = 0;

      if (!this.locationKnown) {
         this.locationID = this.locations[0].locationID;
         this.selectedLocationName = this.locations[0].locationName;
      }
   }

   public ngOnDestroy(): void {
      this.searchListener.unsubscribe();
   }

   public updateSearch(searchString: string): void {
      this.search$.next(searchString);
   }

   runLocationSearch = () => {
      this.buildLocData();
   };

   buildLocData = () => {
      this.tempLocations = this.manageLocation.getLocations();
      this.totalLocLength = this.tempLocations.length;

      if (this.searchLocations && this.searchLocations.length > 0) {
         this.tempLocations = this.manageFilters.filterLocationsToSearch(
            this.tempLocations,
            this.searchLocations,
         );
      }

      this.tempLocations = orderBy(this.tempLocations, "locationNameWithRegions");

      this.locations = this.tempLocations;
   };

   setLocation = (location) => {
      this.locationID = location.locationID;
      this.selectedLocationName = location.locationName;
   };

   buildHierView = () => {
      this.tempLocations = this.allLocations;
      let regions;
      let regionsIndex = {};

      this.vendorSearchHints = new Map();

      const locationIDs: any = [];
      for (const location of this.tempLocations) {
         locationIDs.push(location.locationID);
      }

      if (this.manageLocation.getRegions().length > 0) {
         //they are using regions so we have to behave a little differently
         const rst = this.locationHierarchyService.buildHierarchy({
            locations: this.tempLocations,
            regions: this.manageLocation.getRegions(),
            search: this.searchString,
            filterRegions: false,
            filterLocations: false,
            alwaysReturnRegions: false,
            totalLocationCount: this.manageLocation.getLocations().length,
         });
         regions = rst.regionTree;
         regionsIndex = rst.regionsIndex;
      }

      this.vendors = this.manageVendor
         .getVendors()
         .filter(
            (vendor) =>
               vendor.vendorDeleted === 0 && locationIDs.includes(vendor.locationID),
         );
      if (!this.vendorNodes.size) {
         this.vendorNodes = new LimbleMap(
            [...this.vendors.entries()].map(([vendorID, vendor]) => [
               vendorID,
               {
                  icon: "addressCard",
                  title: vendor.vendorName ?? "",
                  collapsed: true,
                  selected: false,
                  nodes: [],
                  locationID: vendor.locationID,
                  displayButtons: true,
                  vendorID,
               },
            ]),
         );
      }

      for (const vendor of this.vendors) {
         const vendorNode = this.getVendorNode(vendor.vendorID);
         vendorNode.searchHint = "";
      }

      // set the right data on the locations
      for (const location of this.tempLocations) {
         location.nodes = [];
         location.title = location.locationName;
         location.icon = "houseChimney";
         location.unselectable = true;
         location.selected = false;
      }

      // prepare lookup
      const locationIndex = {};
      this.locLength = this.tempLocations.length;
      for (const location of this.tempLocations) {
         locationIndex[location.locationID] = location;
      }

      this.vendors = this.vendors.orderBy("vendorName");

      this.treeData = []; //prepare the tree structure

      if (this.locLength > 1) {
         for (const vendor of this.vendors) {
            const vendorNode = this.getVendorNode(vendor.vendorID);
            //this loop must run before the next loop so all empty node arrays are built
            if (locationIndex[vendor.locationID]) {
               if (this.locLength > 3) {
                  locationIndex[vendor.locationID].collapsed = true;
               } else {
                  locationIndex[vendor.locationID].collapsed = false;
               }
               locationIndex[vendor.locationID].nodes.push(vendorNode);
            }
         }
      }
      this.noSearchResults = false;
      //filter the vendors down if needed to just the search bar
      if (this.searchString.length > 1) {
         this.vendors = this.manageFilters.filterVendorsToNameAndTextFields(
            this.vendors,
            this.manageVendor.getFields(),
            this.manageVendor.getValues(),
            this.manageVendor.getFiles(),
            this.vendorSearchHints,
            {
               search: this.searchString,
               hier: false,
               field: false,
            },
            this.betterDate,
         );

         if (this.vendors.size === 0) {
            this.noSearchResults = true;
         }

         for (const location of this.tempLocations) {
            location.nodes = [];
         }

         for (const vendor of this.vendors) {
            const vendorNode = this.getVendorNode(vendor.vendorID);
            locationIndex[vendor.locationID].nodes.push(vendorNode);
            locationIndex[vendor.locationID].collapsed = false;
         }
      }

      for (const vendorNode of this.vendorNodes) {
         if (!vendorNode.vendorID) {
            vendorNode.searchHint = "";
            vendorNode.searchFound = false;
            continue;
         }
         const searchHint = this.vendorSearchHints.get(vendorNode.vendorID);
         vendorNode.searchFound = Boolean(searchHint?.length);
         vendorNode.searchHint = searchHint ?? "";
      }

      for (const index in regionsIndex) {
         const region = regionsIndex[index];
         region.nodeDisplay = region.regionName;
         region.title = region.regionName;
         region.unselectable = true;

         if (
            this.searchString.length > 0 || //if we are searching start not collapsed
            this.manageLocation.getRegions().length <= 3 //if they are starting collapsed and they don' thave many regions then let's show them
         ) {
            region.collapsed = false;
         } else {
            region.collapsed = true;
         }

         region.selected = false;
      }

      if (this.locLength > 1) {
         if (this.manageLocation.getRegions().length > 0) {
            //they are doing regions so let's show those correctly...

            //first we need to remove any locations that don't have assets on them or any regions that are empty
            this.manageFilters.removeEmptyRegionsOrLocations(regionsIndex);

            //lastly add the top level regions
            for (const region of regions) {
               if (region.nodes.length > 0) {
                  this.treeData.push(region);
               }
            }
         } else {
            //they aren't doing regions so process it like normal locations

            for (const location of this.tempLocations) {
               if (location.nodes.some((node) => node.selected)) {
                  location.collapsed = false;
               }
               if (location.nodes.length > 0) {
                  this.treeData.push(location);
               }
            }
            this.treeData = orderBy(this.treeData, "locationName");
         }
      } else {
         for (const vendor of this.vendors) {
            const vendorNode = this.getVendorNode(vendor.vendorID);
            this.treeData.push(vendorNode);
         }
      }
   };

   public close(): void {
      this.modalInstance.close(0);
   }

   public async submit(): Promise<void> {
      this.errorMsg = false;

      if (this.selection == 1 || this.selection == 2) {
         if (isNullish(this.name) || this.name.length < 1) {
            this.errorMsg = this.lang().PleaseEnterAValidName;

            this.alertService.addAlert(this.errorMsg, "warning", 6000);
            return;
         }
      }

      let selectedVendor;
      if (this.selection == 2) {
         let found = false;
         for (const vendor of this.vendors) {
            const vendorNode = this.getVendorNode(vendor.vendorID);
            if (vendorNode.selected === true) {
               selectedVendor = vendor;
               found = true;
            }
         }

         if (!found) {
            this.errorMsg = this.lang().PleaseSelectAVendorFromTheListBelow;
            this.alertService.addAlert(this.errorMsg, "warning", 6000);
            return;
         }
      }

      if (!this.locationKnown) {
         if (this.locationID == 0) {
            this.errorMsg = this.lang().PleaseSelectALocation;

            this.alertService.addAlert(this.errorMsg, "warning", 6000);
            return;
         }
      }

      // This code validates that the vendor name is unique for the location if the settings box vendorNameUnique is checked.
      // If the settings box is not checked, then the vendor name does not need to be unique.
      // If the settings box is checked, then the vendor name must be unique for the location.
      // this.locationID is set if locationKnown is false further up in this file. That means we are working from pickVendors. Otherwise, fromLocationID is set because we are working from vendorManagement and the locationID is already known.
      const selectedLocationID = this.fromLocationID ?? this.locationID;
      if (this.manageLocation.getLocation(selectedLocationID)?.vendorNameUnique == 1) {
         const post = await this.manageVendor.checkVendorNameUnique(
            this.name,
            selectedLocationID,
         );
         if (post.data.success == false) {
            this.errorMsg = this.lang().addVendorNameUniqueError;
            this.alertService.addAlert(this.errorMsg, "warning", 6000);

            return;
         }
      }

      //if it made it past all the returns we can successfully close
      const data: any = {};

      if (this.selection == 2) {
         data.vendor = this.vendors.get(selectedVendor.vendorID);
      }

      data.selection = this.selection;
      data.locationID = this.locationID;

      data.newName = this.name;

      this.modalInstance.close(data);
   }

   private getVendorNode(vendorID: number) {
      const vendorNode = this.vendorNodes.get(vendorID);
      assert(vendorNode);
      return vendorNode;
   }

   public runPopVendor(vendorNode) {
      const vendor = this.manageVendor.getVendor(vendorNode.vendorID);
      if (!vendor) {
         return;
      }
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public setDontChangeName() {
      if (this.name != "") {
         this.dontChangeName = true;
      }
   }

   public setName() {
      if (this.dontChangeName) {
         return;
      }
      const selectedNode = this.vendorNodes.find((node) => node.selected === true);
      if (!selectedNode) {
         return;
      }
      this.name = `${selectedNode.title} - ${this.lang().Copy}`;
   }
}
