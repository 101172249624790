import { HttpClient, type HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type { WidgetDefinition } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import type { WidgetContent } from "src/app/dashboards/widgets/widget/widgetContent.types";
import type {
   VirtualWidgetID,
   VirtualWidgetFilters,
} from "src/app/shared/components/global/virutalWidget/virtual-widget.models";
import { assert } from "src/app/shared/utils/assert.utils";
import { environment } from "src/environments/environment";

// this is from the type defs of HttpClient.get
type RequestParams =
   | HttpParams
   | {
        [param: string]:
           | string
           | number
           | boolean
           | ReadonlyArray<string | number | boolean>;
     };

@Injectable({ providedIn: "root" })
export class VirtualWidgetService {
   private readonly http = inject(HttpClient);

   public fetchWidgetDefinitions({
      widgetIds,
   }: {
      widgetIds: VirtualWidgetID[];
   }): Observable<WidgetDefinition[]> {
      assert(widgetIds.length > 0, "widgetIds must not be empty");
      const params = this.generateRequestParams({ widgetIds, filters: undefined });
      const url = `${environment.flannelUrl}/widgets/definitions`;
      return this.http.get<WidgetDefinition[]>(url, { params });
   }

   public fetchWidgetContents({
      widgetIds,
      filters,
   }: {
      widgetIds: VirtualWidgetID[];
      filters?: VirtualWidgetFilters;
   }): Observable<WidgetContent[]> {
      assert(widgetIds.length > 0, "widgetIds must not be empty");
      const params = this.generateRequestParams({ widgetIds, filters });
      const url = `${environment.flannelUrl}/widgets/content`;
      return this.http.get<WidgetContent[]>(url, { params });
   }

   private generateRequestParams({
      widgetIds,
      filters,
   }: {
      widgetIds: VirtualWidgetID[];
      filters: VirtualWidgetFilters | undefined;
   }): RequestParams {
      const params: RequestParams = {};

      params.widgetIDs = widgetIds.join(",");
      if (filters) {
         if (filters.assetIDs) {
            params.assetIDs = filters.assetIDs.join(",");
         }
         if (filters.locationIDs) {
            params.locationIDs = filters.locationIDs.join(",");
         }
         if (filters.taskTypes) {
            params.taskTypes = filters.taskTypes.join(",");
         }
         if (filters.taskStatuses) {
            params.taskStatuses = filters.taskStatuses.join(",");
         }
         if (filters.completedStart) {
            params.completedStart = filters.completedStart.toISOString();
         }
         if (filters.completedEnd) {
            params.completedEnd = filters.completedEnd.toISOString();
         }

         // TODO: We will be adding these filters in TASK-619
         if (filters.dueStart) {
            params.dueStart = filters.dueStart.toISOString();
         }
         if (filters.dueEnd) {
            params.dueEnd = filters.dueEnd.toISOString();
         }

         // TODO: We will be adding these filters in TASK-619
         if (filters.completedBy) {
            params.completedBy = filters.completedBy;
         }
      }

      return params;
   }
}
