//wrapper for the viewimage componment to set up the user image and the default profile image

import type { OnChanges, OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input } from "@angular/core";
import { BlockIconComponent, type BlockSizes } from "@limblecmms/lim-ui";
import { ManageObservables } from "src/app/shared/services/manageObservables";

@Component({
   selector: "user-image",
   templateUrl: "./userImage.element.component.html",
   styleUrls: ["./userImage.element.component.scss"],
   imports: [BlockIconComponent],
})
export class UserImage implements OnInit, OnDestroy, OnChanges {
   @Input() public user;
   @Input() public imgClass;
   @Input() public size: BlockSizes = "small";

   public crossOriginAnonymous;
   public customerID;
   public imageSub;
   protected userImageURL: string | undefined;

   private readonly manageObservables = inject(ManageObservables);

   public ngOnChanges(changes) {
      if (!changes.user?.firstChange && changes.user?.currentValue?.userImage) {
         const fileName = this.getUserImageFileName(changes.user.currentValue);
         if (!fileName) {
            this.userImageURL = undefined;
            return;
         }
         this.userImageURL = `viewFile.php?f=upload-${
            this.customerID
         }/users/${this.getUserID(changes.user.currentValue)}/profileImage/${fileName}`;
      }
   }

   public ngOnInit() {
      if (this.user === undefined) {
         return;
      }
      this.imageSub = this.manageObservables.setSubscription(
         "customerID",
         (customerID) => {
            this.customerID = customerID;

            const fileName = this.getUserImageFileName(this.user);
            if (!fileName) {
               this.userImageURL = undefined;
               return;
            }
            this.userImageURL = `viewFile.php?f=upload-${customerID}/users/${this.getUserID(
               this.user,
            )}/profileImage/${fileName}`;
         },
      );
      //includes a rather ugly test to see if user in on the dreaded ie to prevent a chrome bug which doesn't like grabbing cross-origin images after grabbing them normally
      this.crossOriginAnonymous = /Chrome|FireFox/.test(window.navigator.userAgent);
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.imageSub);
   }

   protected getUserID(userObject): number | undefined {
      //this element can be passed multiple types/structures of user object,
      //so must account for the different locaitons of the data in the user object
      if (userObject.gUserID) {
         return this.user.gUserID;
      }
      if (userObject.userID) {
         return this.user.userID;
      }
      return undefined;
   }
   protected getUserImageFileName(userObject): string | undefined {
      //this element can be passed multiple types/structures of user object,
      //so must account for the different locaitons of the data in the user object
      if (userObject.userInfo?.userImage) {
         return userObject.userInfo.userImage;
      }
      if (userObject.userImage) {
         return userObject.userImage;
      }
      return undefined;
   }
}
