import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   DangerButtonComponent,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageImonnit } from "src/app/assets/services/manageImonnit";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "view-sensor-info",
   templateUrl: "./viewSensorInfo.modal.component.html",
   styleUrls: ["./viewSensorInfo.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      LoadingAnimationComponent,
      PanelComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      DangerButtonComponent,
      TooltipDirective,
   ],
})
export class ViewSensorInfo implements OnInit {
   public message;
   public title;
   public config;
   public dataPoint;
   public loading;
   public load1;
   public load2;
   public load3;
   public sensor;
   public incrementRules;
   public runtimeRule;
   public dataPoints;
   public units;
   public incrementTypes;
   public incrementDirections;
   public runtimeUnits;
   public resolve;
   public modalInstance;
   public relationship;
   public result;

   private readonly manageImonnit = inject(ManageImonnit);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.relationship = this.resolve.relationship;
      this.config = this.relationship.config;
      this.dataPoint = this.relationship.dataPoint;

      this.loading = true;
      this.load1 = true;
      this.load2 = true;
      this.load3 = true;
      this.result = {};

      this.sensor = {
         SensorID: "",
         SensorName: "",
         LastCommunicationDate: "",
         NextCommunicationDate: "",
         BatteryLevel: "",
         SignalStrength: "",
      };

      this.manageImonnit
         .getSensorInfo(this.relationship.monnitSensorID)
         .then((answer) => {
            if (answer.data.success) {
               this.sensor = JSON.parse(answer.data.sensor);
               this.sensor.LastCommunicationDate = (Date as any)(
                  this.sensor.LastCommunicationDate.substring(
                     6,
                     this.sensor.LastCommunicationDate.length - 2,
                  ),
               ).toString();
               this.sensor.NextCommunicationDate = (Date as any)(
                  this.sensor.NextCommunicationDate.substring(
                     6,
                     this.sensor.NextCommunicationDate.length - 2,
                  ),
               ).toString();
            } else {
               this.alertService.addAlert(
                  this.lang().UnableToRetrieveSensorInfo,
                  "danger",
                  10000,
               );
            }
            this.load1 = false;
            if (!this.load1 && !this.load2 && !this.load3) {
               this.loading = false;
            }
         });

      this.manageImonnit
         .getConfigRules(this.relationship.valueID, this.config)
         .then((response) => {
            if (response.data.success) {
               this.result.rules = response.data.rules;
               if (this.config == 1) {
                  this.incrementRules = response.data.rules;
               } else if (this.config == 2) {
                  this.runtimeRule = response.data.rules[0];
               }
            } else if (this.config != 0) {
               this.alertService.addAlert(
                  this.lang().UnableToRetrieveSensorInfo,
                  "danger",
                  10000,
               );
            }
            this.load2 = false;
            if (!this.load1 && !this.load2 && !this.load3) {
               this.loading = false;
            }
         });

      this.manageImonnit
         .getLastSensorReading(this.relationship.monnitSensorID)
         .then((answer) => {
            if (answer.data.success) {
               this.dataPoints = answer.data.reading.PlotLabels.split("|");
               this.units = answer.data.reading.DataTypes.split("|");
            } else {
               this.alertService.addAlert(
                  this.lang().UnableToRetrieveSensorInfo,
                  "danger",
                  10000,
               );
            }
            this.load3 = false;
            if (!this.load1 && !this.load2 && !this.load3) {
               this.loading = false;
            }
         });

      this.incrementTypes = [this.lang().Add, this.lang().Subtract];
      this.incrementDirections = [
         this.lang().Above,
         this.lang().Below,
         this.lang().Between,
      ];
      this.runtimeUnits = [this.lang().minutes, this.lang().hours, this.lang().days];
   }
   close = () => {
      this.modalInstance.close(this.result);
   };

   detach = () => {
      this.result.detach = true;
      this.close();
   };

   configure = () => {
      this.result.configure = true;
      this.close();
   };
}
