import { Injectable, inject } from "@angular/core";
import type { PartLogEntity } from "src/app/parts/components/shared/services/logs-api/part-log-api.models";
import { PartLogDataViewerViewModelBuilderService } from "src/app/parts/services/part-log-view-model-factory/builders/part-logs-data-viewer-view-model-builder.service";

/**
 * This service is the entry point for accessing various ViewModels
 * that is mapped from PartLogEntity which comes from jit/parts/logs endpoint
 */
@Injectable({
   providedIn: "root",
})
export class PartLogViewModelFactoryService {
   private readonly partLogDataViewerViewModelBuilderService = inject(
      PartLogDataViewerViewModelBuilderService,
   );

   public getPartLogDataViewerViewModel(
      partLog: PartLogEntity,
      hasReason: boolean = false,
      searchTask: string = "",
   ) {
      return this.partLogDataViewerViewModelBuilderService.buildPartLogDataViewerViewModel(
         partLog,
         hasReason,
         searchTask,
      );
   }
}
