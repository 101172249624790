@if (!recurrence || !taskTemplate) {
   <lim-ui-alert alertType="danger">{{ lang().ErrorDisplayingRecurrence }}</lim-ui-alert>
}

@if (loading) {
   <div class="loading-icon">
      <lim-ui-loading-animation />
   </div>
}

@if (recurrence && !loading && taskTemplate && !editing) {
   <div class="recurrence-wrapper">
      <!-- Day -->
      @if (recurrence.reoccurType && recurrence.reoccurType * 1 === 1) {
         <div class="reoccurHolder">
            <div>
               <span class="settings-space">
                  <span class="descriptor-and-input">
                     <div class="title-spacing">
                        <span
                           class="lim-ui-fonts-body-semi-bold pointer"
                           (click)="editRecurrence(taskTemplate, recurrence)"
                           >{{ lang().RepeatsEvery }} {{ recurrence.reoccurFld1 }}

                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 !== 1
                           ) {
                              <span>{{ lang().Days }}</span>
                           }
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 === 1
                           ) {
                              <span>{{ lang().Day }}</span>
                           }
                           <!-- This shows them what time of day it will occur on -->
                           @if (
                              !recurrenceIs7Or8 &&
                              recurrence.reoccurTimeOfDay !== null &&
                              recurrence.reoccurTimeOfDay !== ""
                           ) {
                              <span>
                                 {{ lang().andDueAt }}
                                 <span>{{
                                    recurrence.reoccurTimeOfDay | betterDate: "time"
                                 }}</span>
                              </span>
                           }
                        </span>
                     </div>

                     <span>
                        <span class="dual-dates">
                           <span class="starting-text-spacing">{{
                              lang().StartsOn
                           }}</span>

                           <div
                              class="input-group input-width"
                              limUiTooltip="{{ lang().SetWhenThisScheduleGoesLive }}"
                              placement="top"
                           >
                              <lim-ui-date-picker-input
                                 [model]="reoccurSpawnInMilliseconds"
                                 class="printHide allowOffline"
                                 (modelChange)="
                                    updateRecurrenceStartDate(
                                       taskTemplate.locationID,
                                       recurrence,
                                       $event
                                    )
                                 "
                              />
                           </div>
                           <span
                              [ngClass]="{
                                 greyedOutIcon:
                                    recurrence.reoccurSpawnEnd === 0 ||
                                    recurrence.reoccurSpawnEnd === null,
                              }"
                              class="date-spacing"
                              >{{ lang().until }}</span
                           >

                           <div
                              class="input-group input-width"
                              limUiTooltip="{{
                                 lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                              }}"
                              placement="top"
                           >
                              <lim-ui-date-picker-input
                                 [model]="reoccurSpawnEndInMilliseconds"
                                 class="printHide allowOffline"
                                 (modelChange)="
                                    updateRecurrenceEndDate(
                                       taskTemplate.locationID,
                                       recurrence,
                                       $event
                                    )
                                 "
                                 [ngClass]="{
                                    greyedOutIcon:
                                       reoccurSpawnEndInMilliseconds === 0 ||
                                       reoccurSpawnEndInMilliseconds === null,
                                 }"
                                 [showClearIcon]="
                                    reoccurSpawnEndInMilliseconds !== 0 &&
                                    reoccurSpawnEndInMilliseconds !== null
                                 "
                                 (modelClear)="
                                    clearEndOnDate(recurrence, taskTemplate.locationID)
                                 "
                              />
                           </div>
                        </span>
                     </span>
                  </span>

                  <ng-container *ngTemplateOutlet="buttonsMenu" />
               </span>

               <ng-container *ngTemplateOutlet="advancedSettings" />
            </div>
         </div>
      }

      <!-- Week -->
      @if (recurrence.reoccurType && recurrence.reoccurType * 1 === 2) {
         <div class="reoccurHolder">
            <div class="row">
               <span class="settings-space">
                  <span class="descriptor-and-input">
                     <div class="title-spacing">
                        <span
                           class="lim-ui-fonts-body-semi-bold pointer"
                           (click)="editRecurrence(taskTemplate, recurrence)"
                        >
                           {{ lang().RepeatsEvery }} {{ recurrence.reoccurFld1 }}

                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 > 1
                           ) {
                              <span>{{ lang().Weeks }}</span>
                           }
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 === 1
                           ) {
                              <span>{{ lang().Week }}</span>
                           }
                           {{ lang().onStr }}

                           @for (day of recurrence.reoccurFld2; track day) {
                              <span>
                                 @if (+day === 1) {
                                    <span
                                       >{{ lang().Mon }}
                                       @if (!$last && +day > 0) {
                                          <span>, </span>
                                       }
                                    </span>
                                 }
                                 @if (+day === 2) {
                                    <span
                                       >{{ lang().Tue }}
                                       @if (!$last && +day > 0) {
                                          <span>, </span>
                                       }
                                    </span>
                                 }
                                 @if (+day === 3) {
                                    <span
                                       >{{ lang().Wed }}
                                       @if (!$last && +day > 0) {
                                          <span>, </span>
                                       }
                                    </span>
                                 }
                                 @if (+day === 4) {
                                    <span
                                       >{{ lang().Thu }}
                                       @if (!$last && +day > 0) {
                                          <span>, </span>
                                       }
                                    </span>
                                 }
                                 @if (+day === 5) {
                                    <span
                                       >{{ lang().Fri }}
                                       @if (!$last && +day > 0) {
                                          <span>, </span>
                                       }
                                    </span>
                                 }
                                 @if (+day === 6) {
                                    <span
                                       >{{ lang().Sat }}
                                       @if (!$last && +day > 0) {
                                          <span>, </span>
                                       }
                                    </span>
                                 }
                                 @if (+day === 7) {
                                    <span
                                       >{{ lang().Sun }}
                                       @if (!$last && +day > 0) {
                                          <span>, </span>
                                       }
                                    </span>
                                 }
                              </span>
                           }

                           <!-- This shows them what time of day it will occur on -->
                           @if (
                              recurrence.reoccurType * 1 !== 7 &&
                              recurrence.reoccurType * 1 !== 8 &&
                              recurrence.reoccurTimeOfDay !== null &&
                              recurrence.reoccurTimeOfDay !== ""
                           ) {
                              <span>
                                 {{ lang().andDueAt }}
                                 <span>{{
                                    recurrence.reoccurTimeOfDay | betterDate: "time"
                                 }}</span>
                              </span>
                           }
                        </span>
                     </div>
                     <div>
                        <span class="dual-dates">
                           <span class="starting-text-spacing">{{
                              lang().StartsOn
                           }}</span>

                           <div
                              class="input-group input-width"
                              limUiTooltip="{{ lang().SetWhenThisScheduleGoesLive }}"
                              placement="top"
                           >
                              <lim-ui-date-picker-input
                                 [model]="reoccurSpawnInMilliseconds"
                                 class="printHide allowOffline"
                                 (modelChange)="
                                    updateRecurrenceStartDate(
                                       taskTemplate.locationID,
                                       recurrence,
                                       $event
                                    )
                                 "
                              />
                           </div>

                           <span
                              [ngClass]="{
                                 greyedOutIcon:
                                    recurrence.reoccurSpawnEnd === 0 ||
                                    recurrence.reoccurSpawnEnd === null,
                              }"
                              class="date-spacing"
                              >{{ lang().until }}</span
                           >

                           <div
                              class="input-group input-width"
                              limUiTooltip="{{
                                 lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                              }}"
                              placement="top"
                           >
                              <lim-ui-date-picker-input
                                 [model]="reoccurSpawnEndInMilliseconds"
                                 class="printHide allowOffline"
                                 (modelChange)="
                                    updateRecurrenceEndDate(
                                       taskTemplate.locationID,
                                       recurrence,
                                       $event
                                    )
                                 "
                                 [ngClass]="{
                                    greyedOutIcon:
                                       reoccurSpawnEndInMilliseconds === 0 ||
                                       reoccurSpawnEndInMilliseconds === null,
                                 }"
                                 [showClearIcon]="
                                    reoccurSpawnEndInMilliseconds !== 0 &&
                                    reoccurSpawnEndInMilliseconds !== null
                                 "
                                 (modelClear)="
                                    clearEndOnDate(recurrence, taskTemplate.locationID)
                                 "
                              />
                           </div>
                        </span>
                     </div>
                  </span>

                  <ng-container *ngTemplateOutlet="buttonsMenu" />
               </span>
               <ng-container *ngTemplateOutlet="advancedSettings" />
            </div>
         </div>
      }

      <!-- Month Numeric -->
      @if (recurrence.reoccurType && recurrence.reoccurType * 1 === 3) {
         <div class="reoccurHolder">
            <div class="row">
               <span class="settings-space">
                  <span class="descriptor-and-input">
                     <div class="title-spacing">
                        <span
                           class="lim-ui-fonts-body-semi-bold pointer"
                           (click)="editRecurrence(taskTemplate, recurrence)"
                        >
                           {{ lang().RepeatsEvery }} {{ recurrence.reoccurFld1 }}
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 > 1
                           ) {
                              <span>{{ lang().Months }}</span>
                           }
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 === 1
                           ) {
                              <span>{{ lang().Month }}</span>
                           }
                           {{ lang().onThe }}
                           {{
                              recurrence.reoccurFld2 &&
                                 (recurrence.reoccurFld2 | addDateEnding)
                           }}

                           <!-- This shows them what time of day it will occur on -->
                           @if (
                              !recurrenceIs7Or8 &&
                              recurrence.reoccurTimeOfDay !== null &&
                              recurrence.reoccurTimeOfDay !== ""
                           ) {
                              <span>
                                 {{ lang().andDueAt }}
                                 <span>{{
                                    recurrence.reoccurTimeOfDay | betterDate: "time"
                                 }}</span>
                              </span>
                           }
                        </span>
                     </div>
                     <div class="dual-dates">
                        <span
                           ><span class="starting-text-spacing">{{
                              lang().StartsOn
                           }}</span>

                           <div
                              class="input-group input-width"
                              limUiTooltip="{{ lang().SetWhenThisScheduleGoesLive }}"
                              placement="top"
                           >
                              <lim-ui-date-picker-input
                                 [model]="reoccurSpawnInMilliseconds"
                                 class="printHide allowOffline"
                                 (modelChange)="
                                    updateRecurrenceStartDate(
                                       taskTemplate.locationID,
                                       recurrence,
                                       $event
                                    )
                                 "
                              />
                           </div>
                        </span>

                        <span
                           class="date-spacing"
                           [ngClass]="{
                              greyedOutIcon:
                                 recurrence.reoccurSpawnEnd === 0 ||
                                 recurrence.reoccurSpawnEnd === null,
                           }"
                           >{{ lang().until }}</span
                        >

                        <div
                           class="input-group input-width"
                           limUiTooltip="{{
                              lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                           }}"
                           placement="top"
                        >
                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnEndInMilliseconds"
                              class="printHide allowOffline"
                              (modelChange)="
                                 updateRecurrenceEndDate(
                                    taskTemplate.locationID,
                                    recurrence,
                                    $event
                                 )
                              "
                              [ngClass]="{
                                 greyedOutIcon:
                                    reoccurSpawnEndInMilliseconds === 0 ||
                                    reoccurSpawnEndInMilliseconds === null,
                              }"
                              [showClearIcon]="
                                 reoccurSpawnEndInMilliseconds !== 0 &&
                                 reoccurSpawnEndInMilliseconds !== null
                              "
                              (modelClear)="
                                 clearEndOnDate(recurrence, taskTemplate.locationID)
                              "
                           />
                        </div>
                     </div>
                  </span>

                  <ng-container *ngTemplateOutlet="buttonsMenu" />
               </span>
               <ng-container *ngTemplateOutlet="advancedSettings" />
            </div>
         </div>
      }

      <!-- Month Weekday -->
      @if (recurrence.reoccurType && recurrence.reoccurType * 1 === 4) {
         <div class="reoccurHolder">
            <div class="row">
               <span class="settings-space">
                  <span class="descriptor-and-input">
                     <div>
                        <span
                           class="lim-ui-fonts-body-semi-bold pointer"
                           (click)="editRecurrence(taskTemplate, recurrence)"
                        >
                           {{ lang().RepeatsEvery }} {{ recurrence.reoccurFld1 }}
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 > 1
                           ) {
                              <span>{{ lang().Months }}</span>
                           }
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 === 1
                           ) {
                              <span>{{ lang().Month }}</span>
                           }
                           {{ lang().onThe }}
                           {{ recurrence.reoccurFld2 | firstSecondEtcToMultiLanguage }}
                           {{ recurrence.reoccurFld3 | fullDay | capitalize }}
                           <!-- This shows them what time of day it will occur on -->
                           @if (
                              !recurrenceIs7Or8 &&
                              recurrence.reoccurTimeOfDay !== null &&
                              recurrence.reoccurTimeOfDay !== ""
                           ) {
                              <span>
                                 {{ lang().andDueAt }}
                                 <span>{{
                                    recurrence.reoccurTimeOfDay | betterDate: "time"
                                 }}</span>
                              </span>
                           }
                        </span>
                     </div>
                     <div class="dual-dates">
                        <span>
                           <span class="starting-text-spacing">{{
                              lang().StartsOn
                           }}</span>
                        </span>
                        <div
                           class="input-group input-width"
                           limUiTooltip="{{ lang().SetWhenThisScheduleGoesLive }}"
                           placement="top"
                        >
                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnInMilliseconds"
                              class="printHide allowOffline"
                              (modelChange)="
                                 updateRecurrenceStartDate(
                                    taskTemplate.locationID,
                                    recurrence,
                                    $event
                                 )
                              "
                           />
                        </div>

                        <span
                           [ngClass]="{
                              greyedOutIcon:
                                 recurrence.reoccurSpawnEnd === 0 ||
                                 recurrence.reoccurSpawnEnd === null,
                           }"
                           class="date-spacing"
                           >{{ lang().until }}</span
                        >

                        <div
                           class="input-group input-width"
                           limUiTooltip="{{
                              lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                           }}"
                           placement="top"
                        >
                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnEndInMilliseconds"
                              class="printHide allowOffline"
                              (modelChange)="
                                 updateRecurrenceEndDate(
                                    taskTemplate.locationID,
                                    recurrence,
                                    $event
                                 )
                              "
                              [ngClass]="{
                                 greyedOutIcon:
                                    reoccurSpawnEndInMilliseconds === 0 ||
                                    reoccurSpawnEndInMilliseconds === null,
                              }"
                              [showClearIcon]="
                                 reoccurSpawnEndInMilliseconds !== 0 &&
                                 reoccurSpawnEndInMilliseconds !== null
                              "
                              (modelClear)="
                                 clearEndOnDate(recurrence, taskTemplate.locationID)
                              "
                           />
                        </div>
                     </div>
                  </span>

                  <ng-template *ngTemplateOutlet="buttonsMenu" />
               </span>
               <ng-template *ngTemplateOutlet="advancedSettings" />
            </div>
         </div>
      }

      <!-- Year Numeric -->
      @if (recurrence.reoccurType && recurrence.reoccurType * 1 === 5) {
         <div class="reoccurHolder">
            <div class="row">
               <span class="settings-space">
                  <span class="descriptor-and-input">
                     <div class="title-spacing">
                        <span
                           class="lim-ui-fonts-body-semi-bold pointer"
                           (click)="editRecurrence(taskTemplate, recurrence)"
                        >
                           {{ lang().RepeatsEvery }} {{ recurrence.reoccurFld1 }}
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 > 1
                           ) {
                              <span>{{ lang().Years }}</span>
                           }
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 === 1
                           ) {
                              <span>{{ lang().Year }}</span>
                           }
                           {{ lang().inStr }}
                           {{ recurrence.reoccurFld2 | monthNumericToString }}
                           {{ lang().onThe }}
                           {{ recurrence.reoccurFld3 | addDateEnding }}

                           <!-- This shows them what time of day it will occur on -->
                           @if (
                              !recurrenceIs7Or8 &&
                              recurrence.reoccurTimeOfDay !== null &&
                              recurrence.reoccurTimeOfDay !== ""
                           ) {
                              <span>
                                 {{ lang().andDueAt }}
                                 <span>{{
                                    recurrence.reoccurTimeOfDay | betterDate: "time"
                                 }}</span>
                              </span>
                           }
                        </span>
                     </div>
                     <div class="dual-dates">
                        <span>
                           <span class="starting-text-spacing">{{
                              lang().StartsOn
                           }}</span>

                           <div
                              class="input-group input-width"
                              limUiTooltip="{{ lang().SetWhenThisScheduleGoesLive }}"
                              placement="top"
                           >
                              <lim-ui-date-picker-input
                                 [model]="reoccurSpawnInMilliseconds"
                                 class="printHide allowOffline"
                                 (modelChange)="
                                    updateRecurrenceStartDate(
                                       taskTemplate.locationID,
                                       recurrence,
                                       $event
                                    )
                                 "
                              />
                           </div>
                        </span>
                        <span
                           class="date-spacing"
                           [ngClass]="{
                              greyedOutIcon:
                                 recurrence.reoccurSpawnEnd === 0 ||
                                 recurrence.reoccurSpawnEnd === null,
                           }"
                           >{{ lang().until }}</span
                        >

                        <div
                           class="input-group input-width"
                           limUiTooltip="{{
                              lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                           }}"
                           placement="top"
                        >
                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnEndInMilliseconds"
                              class="printHide allowOffline"
                              (modelChange)="
                                 updateRecurrenceEndDate(
                                    taskTemplate.locationID,
                                    recurrence,
                                    $event
                                 )
                              "
                              [ngClass]="{
                                 greyedOutIcon:
                                    reoccurSpawnEndInMilliseconds === 0 ||
                                    reoccurSpawnEndInMilliseconds === null,
                              }"
                              [showClearIcon]="
                                 reoccurSpawnEndInMilliseconds !== 0 &&
                                 reoccurSpawnEndInMilliseconds !== null
                              "
                              (modelClear)="
                                 clearEndOnDate(recurrence, taskTemplate.locationID)
                              "
                           />
                        </div>
                     </div>
                  </span>
                  <ng-container *ngTemplateOutlet="buttonsMenu" />
               </span>
               <ng-container *ngTemplateOutlet="advancedSettings" />
            </div>
         </div>
      }

      <!-- Year Ordinal -->
      @if (recurrence.reoccurType && recurrence.reoccurType * 1 === 6) {
         <div class="reoccurHolder">
            <div class="row">
               <span class="settings-space">
                  <span class="descriptor-and-input">
                     <div class="title-spacing">
                        <span
                           class="lim-ui-fonts-body-semi-bold pointer"
                           (click)="editRecurrence(taskTemplate, recurrence)"
                           >{{ lang().RepeatsEvery }} {{ recurrence.reoccurFld1 }}
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 > 1
                           ) {
                              <span>{{ lang().Years }}</span>
                           }
                           @if (
                              recurrence.reoccurFld1 && recurrence.reoccurFld1 * 1 === 1
                           ) {
                              <span>{{ lang().Year }}</span>
                           }
                           {{ lang().onThe }}
                           {{ recurrence.reoccurFld2 | firstSecondEtcToMultiLanguage }}
                           {{ recurrence.reoccurFld3 | fullDay }} {{ lang().ofStr }}
                           {{ recurrence.reoccurFld4 | fullMonth }}

                           <!-- This shows them what time of day it will occur on -->
                           @if (
                              !recurrenceIs7Or8 &&
                              recurrence.reoccurTimeOfDay !== null &&
                              recurrence.reoccurTimeOfDay !== ""
                           ) {
                              <span>
                                 {{ lang().andDueAt }}

                                 <span>{{
                                    recurrence.reoccurTimeOfDay | betterDate: "time"
                                 }}</span>
                              </span>
                           }
                        </span>
                     </div>
                     <div class="dual-dates">
                        <span class="starting-text-spacing">{{ lang().StartsOn }}</span>

                        <div
                           class="input-group input-width"
                           limUiTooltip="{{ lang().SetWhenThisScheduleGoesLive }}"
                           placement="top"
                        >
                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnInMilliseconds"
                              class="printHide allowOffline"
                              (modelChange)="
                                 updateRecurrenceStartDate(
                                    taskTemplate.locationID,
                                    recurrence,
                                    $event
                                 )
                              "
                           />
                        </div>

                        <span
                           [ngClass]="{
                              greyedOutIcon:
                                 recurrence.reoccurSpawnEnd === 0 ||
                                 recurrence.reoccurSpawnEnd === null,
                           }"
                           class="date-spacing"
                           >{{ lang().until }}</span
                        >

                        <div
                           class="input-group input-width"
                           limUiTooltip="{{
                              lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                           }}"
                           placement="top"
                        >
                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnEndInMilliseconds"
                              class="printHide allowOffline"
                              (modelChange)="
                                 updateRecurrenceEndDate(
                                    taskTemplate.locationID,
                                    recurrence,
                                    $event
                                 )
                              "
                              [ngClass]="{
                                 greyedOutIcon:
                                    reoccurSpawnEndInMilliseconds === 0 ||
                                    reoccurSpawnEndInMilliseconds === null,
                              }"
                              [showClearIcon]="
                                 reoccurSpawnEndInMilliseconds !== 0 &&
                                 reoccurSpawnEndInMilliseconds !== null
                              "
                              (modelClear)="
                                 clearEndOnDate(recurrence, taskTemplate.locationID)
                              "
                           />
                        </div>
                     </div>
                  </span>

                  <ng-container *ngTemplateOutlet="buttonsMenu" />
               </span>

               <ng-container *ngTemplateOutlet="advancedSettings" />
            </div>
         </div>
      }

      <!-- Every x -->
      @if (recurrence.reoccurType && recurrence.reoccurType * 1 === 7) {
         <div class="reoccurHolder">
            <div>
               <span class="settings-space">
                  <span class="descriptor-and-input">
                     <div class="title-spacing">
                        <span
                           class="lim-ui-fonts-body-semi-bold pointer"
                           (click)="editRecurrence(taskTemplate, recurrence)"
                           >{{ lang().RepeatsEvery }} {{ recurrence.reoccurFld2 }}
                           {{ fieldName }}
                        </span>
                     </div>
                     <div class="input-context">
                        <span class="starting-text-spacing">{{ lang().StartsAt }}:</span>
                        <div class="input-group input-width-small">
                           <input
                              type="number"
                              class="form-control"
                              [(ngModel)]="recurrence.reoccurFld3"
                              (blur)="updateType7StartOn(recurrence)"
                              style="display: inline-block; position: relative; top: -2px"
                           />
                        </div>
                        <span class="field-spacing">
                           {{ fieldName }}
                        </span>
                     </div>
                  </span>
                  <ng-container *ngTemplateOutlet="buttonsMenu" />
               </span>

               <ng-container *ngTemplateOutlet="advancedSettings" />
            </div>
         </div>
      }

      <!-- Threshold -->
      @if (recurrence.reoccurType && recurrence.reoccurType * 1 === 8) {
         <div class="reoccurHolder">
            <div class="row">
               <span class="settings-space">
                  <span class="descriptor-and-input">
                     <div class="title-spacing">
                        <span
                           class="lim-ui-fonts-body-semi-bold pointer"
                           (click)="editRecurrence(taskTemplate, recurrence)"
                           >{{ lang().IfEver }}

                           @if (recurrence.reoccurFld3 === 0) {
                              <span>{{ lang().below }}</span>
                           }
                           @if (recurrence.reoccurFld3 === 1) {
                              <span>{{ lang().above }}</span>
                           }
                           @if (recurrence.reoccurFld3 === 2) {
                              <span>{{ lang().between }}</span>
                           }
                           {{ recurrence.reoccurFld2 }}
                           @if (recurrence.reoccurFld3 === 2) {
                              <span>{{ lang().and }} {{ recurrence.reoccurFld5 }}</span>
                           }
                           {{ fieldName }}
                        </span>
                     </div>
                  </span>
                  <ng-container *ngTemplateOutlet="buttonsMenu" />
               </span>
               <ng-container *ngTemplateOutlet="advancedSettings" />
            </div>
         </div>
      }

      <!-- Templates -->
      <ng-template #advancedSettings>
         <!-- Advanced settings section -->
         @if (showAdvancedSettings) {
            <div class="advanced-settings">
               <span class="lim-ui-fonts-body-semi-bold">{{
                  lang().AdvancedSettings
               }}</span>

               @if (recurrence && taskTemplate) {
                  <div class="advanced-settings-content">
                     <!-- Stack PMs checkbox -->
                     @if (!recurrenceIs7Or8) {
                        <div class="options-spacing">
                           <div class="advanced-setting-format">
                              <lim-ui-checkbox
                                 [(model)]="recurrence.allowDuplicateInstances"
                                 (modelChange)="changeAllowDuplicateInstances(recurrence)"
                                 [disabled]="false"
                                 [label]="lang().AlwaysCreateNewInstances"
                              />
                              <lim-ui-icon
                                 class="clickable-item tooltip-spacing"
                                 icon="circleQuestionRegular"
                                 iconSize="small"
                                 limUiTooltip="{{
                                    lang().AlwaysCreateNewInstancesTooltip
                                 }}"
                                 placement="right"
                              />
                              <a class="link-text" (click)="openStackPMs()">{{
                                 lang().LearnMore
                              }}</a>
                           </div>
                        </div>
                     }

                     <!-- Recalculate schedule on PM completion checkbox -->
                     @if (recurrence.reoccurType === 1) {
                        <div class="options-spacing">
                           <div
                              class="advanced-setting-format"
                              limUiTooltip="{{
                                 lang().RecalculateScheduleOnPMCompletionTooltip
                              }}"
                              placement="bottom"
                           >
                              <span>
                                 <lim-ui-checkbox
                                    [(model)]="recurrence.calculateBasedOnLastCompletedPM"
                                    (modelChange)="
                                       changeCalculateBasedOnLastCompletedPM(recurrence)
                                    "
                                    [disabled]="false"
                                    [label]="lang().RecalculateOnPMCompletion"
                                 />
                              </span>
                           </div>
                        </div>
                     }

                     <!-- Enterprise pricing features -->
                     <div class="enterprise-pricing-features-container">
                        <!-- scheduleAtRegularIntervals checkbox -->
                        @if (recurrence.reoccurType === 7) {
                           <div class="options-spacing">
                              <div
                                 class="advanced-setting-format checklist-spacing"
                                 placement="bottom"
                              >
                                 <lim-ui-checkbox
                                    [(model)]="recurrence.calculateBasedOnIncrementOnly"
                                    (modelChange)="
                                       updateCalculateBasedOnIncrementOnly(recurrence)
                                    "
                                    [disabled]="!featureConditionalScheduling"
                                    [label]="lang().scheduleAtRegularIntervals"
                                    [ngClass]="{
                                       'grey-out': !featureConditionalScheduling,
                                    }"
                                 />

                                 @if (!featureConditionalScheduling) {
                                    <lim-ui-badge
                                       class="clickable-item"
                                       icon="stars"
                                       [limUiPopover]="startPopover"
                                       [limUiPopoverType]="'upsell'"
                                       [limUiPopoverPlacement]="'right'"
                                       [limUiPopoverHidden]="featureConditionalScheduling"
                                    />
                                 }

                                 <lim-ui-icon
                                    class="clickable-item tooltip-spacing"
                                    icon="circleQuestionRegular"
                                    iconSize="small"
                                    [limUiTooltip]="
                                       lang().scheduleAtRegularIntervalsTooltip
                                    "
                                    [ngClass]="{
                                       'grey-out': !featureConditionalScheduling,
                                    }"
                                 />
                                 <a class="link-text" (click)="openRegularIntervals()">{{
                                    lang().LearnMore
                                 }}</a>
                              </div>
                           </div>
                        }

                        <!-- Skip every x inputs  -->
                        <div>
                           <div placement="bottom" class="skip-every-format">
                              <lim-ui-checkbox
                                 [(model)]="recurrence.skipEveryX"
                                 (modelChange)="toggleSkipEveryX(recurrence)"
                                 [disabled]="
                                    (!recurrence.skipEveryX ||
                                       recurrence.skipEveryX < 2) &&
                                    !featureConditionalScheduling
                                 "
                                 class="checklist-spacing"
                                 [label]="lang().SkipEvery"
                                 [ngClass]="{
                                    'grey-out': !featureConditionalScheduling,
                                 }"
                              />
                              <input
                                 type="number"
                                 class="skipX-input"
                                 limUiTooltip="{{ lang().SkipEveryXTooltip }}"
                                 [(ngModel)]="recurrence.skipEveryX"
                                 (blur)="updateSkipEveryX(recurrence)"
                                 min="2"
                                 [disabled]="!featureConditionalScheduling"
                                 [ngClass]="{
                                    'grey-out': !featureConditionalScheduling,
                                 }"
                              />
                              <div class="occurrences-container">
                                 <span
                                    [ngClass]="{
                                       'grey-out': !featureConditionalScheduling,
                                    }"
                                    >{{ lang().Occurrences }}</span
                                 >
                                 @if (!featureConditionalScheduling) {
                                    <lim-ui-badge
                                       class="clickable-item"
                                       icon="stars"
                                       [limUiPopover]="startPopover"
                                       [limUiPopoverType]="'upsell'"
                                       [limUiPopoverPlacement]="'right'"
                                       [limUiPopoverHidden]="featureConditionalScheduling"
                                    />
                                 }
                              </div>

                              <lim-ui-icon
                                 class="clickable-item"
                                 icon="circleQuestionRegular"
                                 iconSize="small"
                                 limUiTooltip="{{ lang().EnableSkipEveryXTooltip }}"
                                 [ngClass]="{
                                    'grey-out': !featureConditionalScheduling,
                                 }"
                              />
                              <a class="link-text" (click)="openSkipOccurrences()">{{
                                 lang().LearnMore
                              }}</a>
                           </div>
                        </div>

                        <!-- Conditional scheduling - Seasons -->
                        @if (!recurrenceIs7Or8) {
                           <div class="checklist-spacing">
                              <span
                                 [ngClass]="{
                                    'grey-out': !featureConditionalScheduling,
                                 }"
                                 >{{ lang().OnlyCreatePMDuring }}:</span
                              >

                              <a
                                 [ngClass]="{
                                    'grey-out': !featureConditionalScheduling,
                                 }"
                                 class="cursor"
                                 (click)="openSeasonsModal(recurrence, 'season')"
                                 >{{ selectedSeasonsStr }}</a
                              >
                              @if (!featureConditionalScheduling) {
                                 <lim-ui-badge
                                    class="clickable-item"
                                    icon="stars"
                                    [limUiPopover]="startPopover"
                                    [limUiPopoverType]="'upsell'"
                                    [limUiPopoverPlacement]="'right'"
                                    [limUiPopoverHidden]="featureConditionalScheduling"
                                 />
                              }
                           </div>
                        }

                        <!-- Conditional scheduling - Holidays -->
                        @if (!recurrenceIs7Or8) {
                           <div class="checklist-spacing">
                              <span
                                 [ngClass]="{
                                    'grey-out': !featureConditionalScheduling,
                                 }"
                                 >{{ lang().PM }} {{ lang().Holidays }}:</span
                              >

                              <a
                                 [ngClass]="{
                                    'grey-out': !featureConditionalScheduling,
                                 }"
                                 class="cursor"
                                 (click)="openSeasonsModal(recurrence, 'holiday')"
                                 >{{ selectedHolidaysStr }}</a
                              >
                              @if (!featureConditionalScheduling) {
                                 <lim-ui-badge
                                    class="clickable-item"
                                    icon="stars"
                                    [limUiPopover]="startPopover"
                                    [limUiPopoverType]="'upsell'"
                                    [limUiPopoverPlacement]="'right'"
                                    [limUiPopoverHidden]="featureConditionalScheduling"
                                 />
                              }
                           </div>
                        }
                     </div>
                  </div>
               }
            </div>
         }
      </ng-template>

      <ng-template #buttonsMenu>
         <div class="buttons-format">
            <span
               class="schedule-format"
               [limUiPopover]="startScheduleTogglePopoverOne"
               [limUiPopoverType]="'upsell'"
               [limUiPopoverPlacement]="'bottom'"
               [limUiPopoverHidden]="canToggleRecurrences"
            >
               @if (!recurrenceHasActiveSchedules && !canToggleRecurrences) {
                  <lim-ui-badge
                     class="clickable-item"
                     [label]="lang().Standard"
                     icon="stars"
                  />
               }

               @if (recurrenceHasActiveSchedules) {
                  <span>
                     {{ lang().ScheduleEnabled }}
                  </span>
               }
               @if (!recurrenceHasActiveSchedules) {
                  <span>
                     {{ lang().ScheduleDisabled }}
                  </span>
               }

               @if (!recurrenceIs7Or8) {
                  <lim-ui-toggle
                     [disabled]="!canToggleRecurrences && !recurrenceHasActiveSchedules"
                     (activeChange)="flipRecurrence(recurrence)"
                     [active]="recurrenceHasActiveSchedules"
                     class="toggle-spacing"
                  />
               }

               @if (recurrenceIs7Or8) {
                  <lim-ui-toggle
                     (activeChange)="flipRecurrenceFor7and8(recurrence)"
                     [active]="recurrenceHasActiveSchedules"
                     class="toggle-spacing"
                  />
               }
            </span>

            <ng-template #startScheduleTogglePopoverOne>
               <upsell-popover
                  [message]="lang().FeatureUnlimitedPMsPopoverMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>

            <lim-ui-icon-button
               button
               icon="gearRegular"
               limUiTooltip=" {{ lang().AdvancedSettings }}"
               (click)="toggleShowAdvancedSettings()"
            />
            <lim-ui-icon-button
               button
               class="icon-button-padding"
               icon="editRegular"
               (click)="editRecurrence(taskTemplate, recurrence)"
               limUiTooltip="{{ lang().editSchedule }}"
            />
            <lim-ui-icon-button
               button
               iconColor="danger"
               icon="trashCanRegular"
               (click)="deleteRecurrence(taskTemplate, recurrence.reoccurID)"
               limUiTooltip="{{ lang().DeleteThisSchedule }}"
            />
         </div>
      </ng-template>
   </div>
}
@if (recurrence && !loading && taskTemplateEntity && editing) {
   <div class="recurrence-wrapper">
      <reoccur-options
         [reoccurID]="recurrence.reoccurID"
         [taskTemplate]="taskTemplateEntity"
         (recurrenceID)="updateRecurrence(recurrence.reoccurID)"
      />
   </div>
}

<ng-template #startPopover>
   <upsell-popover [message]="lang().FeatureConditionalSchedulingPopoverMessage" />
</ng-template>
