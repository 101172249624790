import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { decodeHTMLEntities } from "src/app/shared/utils/app.util";
import { assert } from "src/app/shared/utils/assert.utils";
import { PickVendorField } from "src/app/vendors/components/pickVendorFieldModal/pickVendorField.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "po-custom-field-settings",
   templateUrl: "./poCustomFieldSettings.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      ContenteditableDirective,
      FormsModule,
      IconComponent,
      TooltipDirective,
      BasicModalFooterComponent,
   ],
})
export class POCustomFieldSettings implements OnInit {
   public name;
   public vendorFieldID;
   public originalVendorFieldID;
   public message;
   public title;
   public field;
   public locationID;
   public loadingBar;
   public fieldName;
   public resolve;
   public modalInstance;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageVendor = inject(ManageVendor);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.locationID = this.resolve.locationID;
      this.field = this.resolve.field;
      this.loadingBar = false;

      const location = this.manageLocation.getLocation(this.locationID);
      assert(location, "Location not found");

      if (this.field == 1) {
         this.name = location.poField1Name;
         this.vendorFieldID = location.poField1VendorFieldLink;
      } else if (this.field == 2) {
         this.name = location.poField2Name;
         this.vendorFieldID = location.poField2VendorFieldLink;
      } else if (this.field == 3) {
         this.name = location.poField3Name;
         this.vendorFieldID = location.poField3VendorFieldLink;
      } else if (this.field == 4) {
         this.name = location.poField4Name;
         this.vendorFieldID = location.poField4VendorFieldLink;
      } else if (this.field == 5) {
         this.name = location.poField5Name;
         this.vendorFieldID = location.poField5VendorFieldLink;
      } else if (this.field == 6) {
         this.name = location.poField6Name;
         this.vendorFieldID = location.poField6VendorFieldLink;
      }

      this.originalVendorFieldID = this.vendorFieldID;

      this.setVendorFieldName();
   }

   setVendorFieldName = () => {
      const fieldNameMap = {
         "0": this.lang().PickVendorField,
         "-4": this.lang().Address,
         "-3": this.lang().Contact,
         "-2": this.lang().Phone,
         "-1": this.lang().Email,
      };
      if (this.vendorFieldID in fieldNameMap) {
         this.fieldName = fieldNameMap[this.vendorFieldID];
         return;
      }

      const field = this.manageVendor.getField(this.vendorFieldID);

      if (!field) {
         this.fieldName = this.lang().PickVendorField;
         return;
      }
      this.fieldName = field.fieldName;
   };

   close = () => {
      if (this.originalVendorFieldID == this.vendorFieldID || this.vendorFieldID == 0) {
         this.modalInstance.close(0);
      } else {
         this.modalInstance.close(1);
      }
   };

   changePOCustomFieldSettings = () => {
      this.name = decodeHTMLEntities(this.name);
      this.managePO
         .changePOCustomFieldSettings(
            this.name,
            this.locationID,
            this.field,
            this.vendorFieldID,
         )
         .then((answer) => {
            if (answer.data.success) {
               const location = this.manageLocation.getLocation(this.locationID);
               assert(location, "Location not found");

               if (this.field == 1) {
                  location.poField1Name = this.name;
                  location.poField1VendorFieldLink = this.vendorFieldID;
               } else if (this.field == 2) {
                  location.poField2Name = this.name;
                  location.poField2VendorFieldLink = this.vendorFieldID;
               } else if (this.field == 3) {
                  location.poField3Name = this.name;
                  location.poField3VendorFieldLink = this.vendorFieldID;
               } else if (this.field == 4) {
                  location.poField4Name = this.name;
                  location.poField4VendorFieldLink = this.vendorFieldID;
               } else if (this.field == 5) {
                  location.poField5Name = this.name;
                  location.poField5VendorFieldLink = this.vendorFieldID;
               } else if (this.field == 6) {
                  location.poField6Name = this.name;
                  location.poField6VendorFieldLink = this.vendorFieldID;
               }

               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
            }
         });
   };

   linkField = () => {
      const instance = this.modalService.open(PickVendorField);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().PickVendorField,
            data: {
               locationID: this.locationID,
               breakLink: true,
            },
         },
      };

      instance.result.then((id) => {
         let newID = id;
         if (id === "break") {
            newID = 0;
         }
         if (!id) {
            return;
         }

         this.vendorFieldID = newID;
         this.setVendorFieldName();
         this.changePOCustomFieldSettings();
      });
   };

   protected submit() {
      if (!this.name) {
         this.alertService.addAlert(
            this.lang().POCustomFieldsWarningMsg,
            "warning",
            6000,
         );
         return;
      }
      this.changePOCustomFieldSettings();
      this.close();
   }
}
