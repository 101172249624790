<div class="row">
   @if (loadingBar) {
      <div>
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      </div>
   }

   @if (items && items.length > 0 && !loadingBar) {
      <div class="col-xs-12">
         @for (item of items; track item) {
            <div class="lim-ui-list-item">
               <div class="lim-ui-list-item-content">
                  <div class="po-item-information">
                     <div class="po-item-count">
                        <input
                           type="number"
                           onclick="this.focus();"
                           [(ngModel)]="item.tempNumberReceived"
                           max="{{ item.qty - item.receivedQty | betterNumber: '1.0-3' }}"
                           (blur)="validateItemNumber(item)"
                        />
                        <div
                           class="quantity-left-to-receive"
                           limUiTooltip="{{ lang().QtyLeftToReceive }}"
                        >
                           {{ lang().ofStr }}
                           {{ item.qty - item.receivedQty | betterNumber: "1.0-3" }}
                        </div>
                        @let $purchasableSnapshot = item.purchasableSnapshot();
                        @if ($purchasableSnapshot !== undefined) {
                           @if ($purchasableSnapshot === null) {
                              <lim-ui-loading-animation />
                           } @else {
                              <unit-label
                                 [unitDisplay]="$purchasableSnapshot.orderUnitDisplay"
                              />
                           }
                        }
                     </div>
                     <div class="part-name">
                        @if (item.partID && item.partID > 0) {
                           <lim-ui-icon icon="gears" iconSize="small" />
                        }
                        @if (item.itemType == 2) {
                           <lim-ui-icon icon="wpforms" iconSize="small" />
                        }
                        @if (item.itemType == 4) {
                           <lim-ui-icon icon="superpowers" iconSize="small" />
                        }
                        <span [limbleHtml]="item.itemName"></span>

                        @if (item.itemNumber?.length) {
                           <span>
                              •
                              <span [limbleHtml]="item.itemNumber"></span>
                           </span>
                        }
                        @if (item.description && item.description.length > 0) {
                           <span>
                              •
                              <span [limbleHtml]="item.description"></span>
                           </span>
                        }
                     </div>
                  </div>
               </div>
            </div>
         }
      </div>
   }
   @if (items?.length == 0 && removedItem == false) {
      <div class="col-xs-12">
         <div class="alert alert-dismissable alert-success">
            <strong>{{ lang().AllOfTheItemsForThisPOHasBeenReceived }}</strong>

            {{ lang().OnceAllBillsHaveBeenMarkedPaidThisPOWillClose }}
         </div>
      </div>
   }
</div>
<div class="alert alert-dismissable alert-warning" [hidden]="!(errorMsg !== '')">
   <strong class="mr-1">{{ lang().Warning }}!</strong> - {{ errorMsg }}
</div>
