import { purchaseOrderItemDtoStrict } from "src/app/purchasing/pos/purchase-order-item.dto.strict";
import { nonNullableExcept } from "src/app/shared/utils/validation.utils";
import type { z } from "zod";

export const purchaseOrderItemDto = nonNullableExcept(purchaseOrderItemDtoStrict, [
   "rate" /** TODO(LEAF-374): Investigate usage to see if it can be made non nullable. */,

   "assetID",
   "checklistID",
   "glID",
   "prodServDescription",
   "description",
   "purchasableID",
   "purchasableName",
   "purchasableSize",
   "purchasableQuantity",
   "purchasableRate",
   "purchasableProvidedOrderUnitID",
   "purchasableProvidedOrderUnitAlias",
   "purchasableCustomOrderUnitID",
   "purchasableCustomOrderUnitNameShort",
   "purchasableCustomOrderUnitNameSingular",
   "purchasableProvidedSizeUnitID",
   "purchasableProvidedSizeUnitAlias",
   "partStockUnitDefault",
   "partStockProvidedUnitID",
   "partStockProvidedUnitAlias",
   "partStockCustomUnitID",
   "partStockCustomUnitNameShort",
   "partStockCustomUnitNameSingular",
]);

export type PurchaseOrderItemDto = z.infer<typeof purchaseOrderItemDto>;
