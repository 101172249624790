import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   IconComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { ViewListOfTasks } from "src/app/tasks/components/viewListOfTasksElement/viewListOfTasks.element.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { StartWOService } from "src/app/tasks/services/start-wo.service";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "vendor-work-order-wrapper-legacy",
   templateUrl: "./vendor-work-order-legacy.wrapper.component.html",
   styleUrls: ["./vendor-work-order-legacy.wrapper.component.scss"],
   imports: [
      IconComponent,
      PanelComponent,
      PrimaryButtonComponent,
      ViewListOfTasks,
      SearchBoxComponent,
   ],
})
export class VendorWorkOrderLegacyWrapper implements OnInit, OnDestroy {
   @Input() public vendor: Vendor | undefined;
   public currentUser;
   public taskExtraData;
   public completedData;
   public locationID;
   public sortBind;
   public sortBind2;
   public sortBindOpen;
   public sortBindCompleted;
   public tasks: TaskLookup = new Lookup("checklistID");
   public loading;
   public completedTasks: TaskLookup = new Lookup("checklistID");
   public tasksWatchVarSub;
   public completedTasksWatchVarSub;
   public InitialLoadCheck = 0;
   public searchCompleted;
   public openWOColumns;
   public completedWOColumns;
   public noSearchResults;
   public completedTaskIDsToShow: Array<number> = [];
   public taskIDs: Array<number> = [];

   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageParts = inject(ManageParts);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly startWOService = inject(StartWOService);
   private readonly manageLang = inject(ManageLang);
   private readonly refreshService = inject(RefreshService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      assert(this.vendor);
      this.currentUser = this.manageUser.getCurrentUser();
      //default variables for viewing an open task
      this.taskExtraData = {};
      this.taskExtraData.assign = true;
      this.taskExtraData.vendorViewing = this.vendor.vendorID;

      this.completedData = {};
      this.completedData.vendorViewing = this.vendor.vendorID;

      this.locationID = this.vendor.locationID;
      this.sortBind = "checklistName";
      this.sortBind2 = "checklistName";
      this.sortBindOpen = "checklistDueDate";
      this.sortBindCompleted = "-checklistDueDate";

      //get the completed and open WOs/PMs for this vendor WOs/PMs have a checklistTemplate Old value of 1 or 2
      this.tasksWatchVarSub = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.watchData();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );

      this.completedTasksWatchVarSub = this.manageObservables.setSubscription(
         "completedTasksWatchVar",
         () => {
            this.watchData();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );

      this.openWOColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 6,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 3,
         },
         {
            key: "assignedTo",
            displayName: this.lang().AssignedTo,
            sortBy: "assignment",
            columnWidth: 3,
            textAlign: "right",
         },
      ];

      this.completedWOColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 4,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Completed,
            sortBy: "checklistCompletedDate",
            columnWidth: 2,
         },
         {
            key: "checklistPromptTimeTotal",
            displayName: this.lang().TimeSpent,
            sortBy: "checklistPromptTimeTotal",
            columnWidth: 2,
         },
         {
            key: "completedByStr",
            displayName: this.lang().CompletedBy,
            sortBy: "completedByStr",
            columnWidth: 2,
         },
      ];
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.tasksWatchVarSub,
         this.completedTasksWatchVarSub,
      ]);
   }

   protected watchData(): void {
      assert(this.vendor);
      if (this.InitialLoadCheck < 1) {
         this.InitialLoadCheck++;
         return;
      }

      const vendorTasks = this.manageTask.getVendorTasks();

      let tasks = this.manageTask.getTasks();
      tasks = this.manageFilters.filterOpenTasksToVendor(
         tasks,
         vendorTasks,
         this.vendor.vendorID,
      );

      tasks = tasks.filter(
         (task) => task.checklistTemplate === 0 && task.checklistStatusID === 0,
      );
      tasks = this.manageFilters.filterTasksByChecklistTemplateOld(tasks, [1, 2, 4]);

      this.tasks = tasks;

      this.taskIDs = [...tasks.keys()];

      // Clear out old values
      this.completedTasks = new Lookup("checklistID");
      this.noSearchResults = false;

      const completedTasks = this.manageTask.getCompletedTasks();
      const completedTaskIDsToShow: Array<number> = [];

      for (const vendorTask of vendorTasks) {
         //important we loop through the vendorTasks and compare vs completed task lookup
         if (
            this.vendor.vendorID == vendorTask.vendorID &&
            completedTasks.get(vendorTask.checklistID)
         ) {
            if (vendorTask.shareDisabled == 0 || vendorTask.vendorCompleted == 1) {
               const completedTask = completedTasks.get(vendorTask.checklistID);
               if (completedTask) {
                  // We use this.completedTasks to pass into the filterTasksToSearch function,
                  // and also to know whether or not to display "No completed tasks" message in template
                  this.completedTasks.set(vendorTask.checklistID, completedTask);
                  // We use completedTaskIDsToShow as the input for view-list-of-tasks component to know which completed tasks to show
                  completedTaskIDsToShow.push(vendorTask.checklistID);
               }
            }
         }
      }

      this.completedTaskIDsToShow = completedTaskIDsToShow;

      // For user initiated search:
      if (this.searchCompleted !== undefined && this.searchCompleted.length > 0) {
         const filteredTaskInfo = this.manageFilters.filterTasksToSearch(
            this.completedTasks,
            this.searchCompleted,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         );

         // Based on the user search input, reset the completedTaskIDsToShow for the view-list-of-tasks component input,
         // to update which completedTasks to show
         this.completedTaskIDsToShow = [...filteredTaskInfo.tasks.keys()];

         if (this.completedTaskIDsToShow.length === 0) {
            this.noSearchResults = true;
         }
      }
   }

   protected startWorkOrder(): void {
      assert(this.vendor);
      //check cred for starting a work order at a vendor here?

      //right here check if the vendor has an email address and prompt them to enter one.
      if (!this.vendor.vendorEmail) {
         this.alertService.addAlert(this.lang().VendorHasNoEmail, "warning", 10000);
      } else if (this.vendor.vendorEmail) {
         const shareTaskExternally = (data1, data2) => {
            assert(this.vendor);

            const emailMessage = `${
               this.lang().ShareTaskExternallyEmailTemplate +
               this.currentUser.userInfo.fName
            } ${this.currentUser.userInfo.lName}<br />${
               this.currentUser.userInfo.customerSignature
            }<br />${this.currentUser.userInfo.userEmail}`;

            const assetName =
               this.manageAsset.getAsset(data1.WO.task.assetID)?.assetName ?? "";

            const emailSubject =
               `${this.lang().SharedTask} ` +
               `"${data1.WO.task.checklistName} - #${data1.WO.task.checklistID} - ${assetName} at ${data1.WO.task.locationName}" `;

            //callback here to share the task externally
            this.manageTask
               .generateUniqueExternalLink(
                  data2.task.checklistID,
                  this.vendor.vendorEmail,
                  emailMessage,
                  emailSubject,
                  data2.task.locationID,
                  this.vendor.vendorID,
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     this.alertService.addAlert(
                        this.lang().WorkOrderSuccessfullyStarted,
                        "success",
                        5000,
                     );
                  } else {
                     this.alertService.addAlert(
                        this.lang().error3EmailTemplate,
                        "danger",
                        10000,
                     );
                  }
               });
         };

         this.startWOService.startWorkOrder(undefined, this.locationID, {
            callback: shareTaskExternally.bind(this),
            runForAllUsers: true,
         });
      }
   }
}
