@if (uploading === true) {
   <div class="tile-uploader-{{ type }}">
      <lim-ui-loading-animation class="tileUploading" animationSize="small" />
   </div>
} @else {
   @if (image) {
      <div class="hoverContainer3 cursor img-holder-{{ type }}" (click)="openViewer()">
         <img
            [src]="image.getURL"
            class="rounded img-responsive cursor"
            [ngClass]="{ 'task-view-image': type === 'task-view' }"
            [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
         />
         <span class="vertical-alignment-action-icons">
            <span class="img-action-icons">
               @if (canEdit) {
                  <lim-ui-icon
                     class="img-button hoverAppear delete-button"
                     (click)="deleteFile(); $event.stopPropagation()"
                     placement="bottom"
                     icon="xmarkLargeRegular"
                     [iconSize]="type === 'table-view' ? 'extra-small' : 'small'"
                  />
                  <lim-ui-icon
                     class="img-button hoverAppear edit-button"
                     (click)="openEditor(); $event.stopPropagation()"
                     icon="editRegular"
                     [iconSize]="type === 'table-view' ? 'extra-small' : 'small'"
                  />
               }
            </span>
         </span>
      </div>
   } @else {
      @if (canEdit) {
         @if (isAndroidMenuNeeded()) {
            <android-file-upload-menu
               [isDocumentOptionIncluded]="false"
               (takePhoto)="uploadFromCamera()"
               (addImage)="uploadFromGallery()"
            >
               <lim-ui-icon
                  class="hoverContainer3 cursor tile-uploader-{{ type }}"
                  icon="camera"
                  [iconSize]="type === 'table-view' ? 'small' : 'medium'"
               />
            </android-file-upload-menu>
         } @else {
            <lim-ui-icon
               class="hoverContainer3 cursor tile-uploader-{{ type }}"
               (click)="uploadFromGallery()"
               icon="camera"
               [iconSize]="type === 'table-view' ? 'small' : 'medium'"
            />
         }
      } @else {
         <lim-ui-icon
            class="hoverContainer3 tile-uploader-{{ type }} no-upload"
            [limUiTooltip]="lang().NoImageUploadPermission"
            icon="camera"
            [iconSize]="type === 'table-view' ? 'small' : 'medium'"
         />
      }
   }
}
