<lim-ui-modal>
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()">
      <div class="modal-title-content" modalTitle>
         <lim-ui-secondary-button
            (click)="newWOTemplate()"
            data-test-id="new-wo-template"
         >
            {{ lang().NewTemplate }}
         </lim-ui-secondary-button>
         <lim-ui-icon-button
            icon="cloudUploadRegular"
            [limUiTooltip]="lang().ImportWOTemplates"
            (click)="importWOTemplates()"
            data-test-id="import-wo-templates"
         />
      </div>
   </lim-ui-basic-modal-header>
   <lim-ui-modal-body [scrollable]="true">
      <lim-ui-panel>
         <div class="list-search-container">
            <lim-ui-search-box
               [placeholder]="lang().Search"
               [(searchVal)]="search"
               [formControl]="searchControl"
            />
         </div>
         <div class="loading-icon">
            @if (isLoading) {
               <lim-ui-loading-animation />
            }
         </div>
         @if (treeData?.length && !noSearchResults && hierarchyOptions) {
            <hierarchy-container
               #hierarchy
               [treeData]="treeData"
               [options]="hierarchyOptions"
               [fetchMore]="fetchMoreTemplatesAtLocation"
               [hidden]="isLoading"
            />
         }
         @if (letUserPick) {
            <div
               class="lim-ui-list-item lim-ui-selectable-list-item lim-ui-warning-list-item margin-from-hierarchy"
               (click)="letUserPickSubmit()"
            >
               {{ lang().LetUserPick }}
            </div>
         }
         @if (noSearchResults) {
            <no-search-results />
         }
         @if (noTemplatesExist) {
            <lim-ui-alert type="warning" title="Warning">
               {{ lang().NoWOTemplatesExist }}
            </lim-ui-alert>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Select"
      (successClick)="submit()"
      class="wo-template-footer"
   />
</lim-ui-modal>
