<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message-area" [limbleHtml]="message"></span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div>
            <div class="list-header-text">{{ lang().Categories }}:</div>
            <div
               (click)="focusCategory(emptyCategory)"
               class="lim-ui-list-item lim-ui-selectable-list-item lim-ui-warning-list-item"
               [ngClass]="{
                  'lim-ui-selected-list-item': selectedCategory?.categoryID === 0,
               }"
            >
               <div class="item-content-icon-text-area">
                  @if (selectedCategory?.categoryID === 0) {
                     <lim-ui-icon icon="check" iconSize="small" iconColor="success" />
                  }

                  <span class="ml-2">{{ lang().NoCategory }}</span>
               </div>
            </div>
            @for (cat of categories; track cat) {
               <div
                  (click)="focusCategory(cat)"
                  class="lim-ui-list-item lim-ui-selectable-list-item"
                  [ngClass]="{
                     'lim-ui-selected-list-item':
                        selectedCategory?.categoryID == cat.categoryID,
                  }"
               >
                  <div class="lim-ui-list-item-content">
                     <div class="item-content-icon-text-area">
                        @if (selectedCategory?.categoryID == cat.categoryID) {
                           <lim-ui-icon
                              icon="check"
                              iconSize="small"
                              iconColor="success"
                           />
                        }

                        <div
                           ngDefaultControl
                           class="updateFieldHolder exitOnEnterKeyPress contenteditable-div"
                           [(ngModel)]="cat.categoryName"
                           [limbleContentEditable]="
                              categoryExtraInfo.get(cat.categoryID)?.edit ?? false
                           "
                           [ngClass]="{
                              'hide-contenteditable-border': !categoryExtraInfo.get(
                                 cat.categoryID
                              )?.edit,
                           }"
                           (afterEdit)="updateCatName(cat)"
                           disableEditor="true"
                           stripHtml="true"
                           (click)="clickOnContentEditable(cat); $event.stopPropagation()"
                        ></div>
                     </div>
                     <div>
                        <lim-ui-minimal-icon-button
                           icon="penToSquare"
                           (click)="toggleEdit(cat.categoryID); $event.stopPropagation()"
                           [limUiTooltip]="lang().EditCategoryNameTooltip"
                           data-placement="left"
                        />
                        @if (isSuperUser) {
                           <lim-ui-minimal-icon-button
                              icon="trashCanRegular"
                              (click)="deleteCat(cat); $event.stopPropagation()"
                              [limUiTooltip]="lang().DeleteThisCategory"
                              data-placement="left"
                           />
                        }
                     </div>
                  </div>
               </div>
            }
         </div>
         <div class="list-add-cat-button">
            <lim-ui-primary-button (click)="addCat()">
               {{ lang().AddNewCategory }}</lim-ui-primary-button
            >
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Save"
      (successClick)="submit()"
   />
</lim-ui-modal>
