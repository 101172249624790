import { inject, Injectable, resource, type ResourceRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter, firstValueFrom, map, startWith } from "rxjs";
import type { PmSuggestionsUserPreferencesInterface } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/user-preferences/pm-suggestions-user-preferences";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable()
export class PmSuggestionsUserPreferencesFromManageUserService
   implements PmSuggestionsUserPreferencesInterface
{
   private readonly manageUser = inject(ManageUser);

   private readonly itemsPerPage$ = this.manageUser.currentUserChanges$.pipe(
      takeUntilDestroyed(),
      startWith(this.manageUser.getCurrentUser()),
      filter((user) => user !== undefined && user !== "none"),
      map((user) => user.userInfo.userUIPreferences.itemsPerPage ?? null),
   );

   private readonly itemsPerPageInternal: ResourceRef<number | null> = resource({
      loader: async () => {
         return firstValueFrom(this.itemsPerPage$);
      },
   });

   public readonly itemsPerPage = this.itemsPerPageInternal.asReadonly();

   public constructor() {
      this.itemsPerPage$.subscribe((itemsPerPage) => {
         this.itemsPerPageInternal.set(itemsPerPage);
      });
   }
}
