import { Component, inject, type OnInit, signal } from "@angular/core";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
} from "@limblecmms/lim-ui";
import { map, type Observable, share, take } from "rxjs";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import {
   type AssignmentFilterValue,
   TaskAssignmentType,
} from "src/app/shared/data-viewer/data-viewer-filters/components/assigned-to-filter/assigned-to-filter.models";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import type { TaskUserMetadata } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";

@Component({
   selector: "assigned-to-filter",
   imports: [
      DropdownClearFilterItemComponent,
      DropdownDividerComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      TranslateDirective,
   ],
   templateUrl: "./assigned-to-filter.component.html",
   styleUrls: ["./assigned-to-filter.component.scss"],
})
export class AssignedToFilterComponent extends BaseFilterComponent implements OnInit {
   readonly #tasksApi = inject(TasksApiService);

   protected assignedTo?: number | undefined;
   protected activeFilterLabel?: string | undefined;

   #userMetadata$!: Observable<TaskUserMetadata>;

   protected readonly users = signal<
      | {
           userID: number;
           firstName: string;
           lastName: string;
        }[]
      | undefined
   >(undefined);

   protected readonly profiles = signal<
      | {
           profileID: number;
           profileName: string;
        }[]
      | undefined
   >(undefined);

   public constructor() {
      super();
   }
   public ngOnInit() {
      const locationID = this.filter().options?.locationID;
      this.#userMetadata$ = this.#tasksApi
         .getUserMetadata(locationID ? { locationID } : undefined)
         .pipe(share());

      this.#userMetadata$
         .pipe(
            take(1),
            map((metadata) =>
               metadata.assignedUsers.sort((userA, userB) => {
                  if (userA?.lastName === undefined || userB?.lastName === undefined)
                     return 0;
                  return userA.lastName?.localeCompare(userB.lastName || "");
               }),
            ),
         )
         .subscribe((assignedUsers) => {
            this.users.set(assignedUsers);
         });

      this.#userMetadata$
         .pipe(
            take(1),
            map((metadata) =>
               metadata.assignedProfiles.sort((profileA, profileB) =>
                  profileA.profileName?.localeCompare(profileB.profileName || ""),
               ),
            ),
         )
         .subscribe((assignedProfiles) => {
            this.profiles.set(assignedProfiles);
         });
   }

   public handleAssignedToUser(user: TaskUserMetadata["assignedUsers"][0]) {
      this.assignedTo = user.userID;
      this.activeFilterLabel = `${this.translateService.instant("AssignedTo")} - ${user.firstName} ${user.lastName}`;
      this.setFilter(
         { assignmentType: TaskAssignmentType.User, assignmentValue: user.userID },
         this.activeFilterLabel,
      );
   }

   public handleAssignedToProfile(profile: TaskUserMetadata["assignedProfiles"][0]) {
      this.assignedTo = profile.profileID;
      this.activeFilterLabel = `${this.translateService.instant("AssignedTo")} - ${profile.profileName}`;
      this.setFilter(
         {
            assignmentType: TaskAssignmentType.Profile,
            assignmentValue: profile.profileID,
         },
         this.activeFilterLabel,
      );
   }

   public handleClear() {
      this.assignedTo = undefined;
      this.activeFilterLabel = undefined;
      this.remove.emit(this.filter());
   }

   private setFilter(value: AssignmentFilterValue, activeLabel: string): void {
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value,
      });
   }
}
