import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import {
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   TextButtonComponent,
   TooltipDirective,
   isMobile,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { filter } from "rxjs";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { PrTransaction } from "src/app/purchasing/bills/prTransactionElement/prTransaction.element.component";
import { CurrencyNamePipe } from "src/app/purchasing/currency/pipes/currency-name.pipe";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { AddPRComment } from "src/app/purchasing/pos/addPRCommentModal/addPRComment.modal.component";
import { PickPOItems } from "src/app/purchasing/pos/pickPOItemsModal/pickPOItems.modal.component";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import type { AugmentedPurchasingComment } from "src/app/purchasing/services/managePO";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { BillTransaction } from "src/app/purchasing/types/bill-transaction.types";
import type { Bill } from "src/app/purchasing/types/bill.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "pr",
   templateUrl: "./pr.wrapper.component.html",
   styleUrls: ["./pr.wrapper.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      LoadingAnimationComponent,
      PanelComponent,
      TooltipDirective,
      IconComponent,
      LimbleHtmlDirective,
      TextButtonComponent,
      MinimalIconButtonComponent,
      PrTransaction,
      SecondaryButtonComponent,
      FormsModule,
      ContenteditableDirective,
      FileListItem,
      ViewImage,
      ModalFooterComponent,
      PrimaryButtonComponent,
      BetterCurrencyPipe,
      BetterDatePipe,
      LocaleCurrencyPipe,
      CurrencyNamePipe,
      TooltipDirective,
   ],
})
export class PrComponent implements OnInit, OnDestroy {
   @Input() public data;
   @Input() public prID: number | undefined;
   @Input() public isPREmbedded;

   public CID;
   public currencySymbol;
   public purchaseOrder: PurchaseOrder | undefined;
   public loading = true;
   public location;
   public customFields;
   public superUser;
   public processing;
   public OpenBillWatchVarSub;
   public modalInstance;
   public resolve;
   public title;
   public message;
   public bill: Bill | undefined;
   public transactions: LimbleMap<
      number,
      BillTransaction & {
         itemType: number | null | undefined;
         itemName?: string | undefined;
      }
   > = new LimbleMap();
   private loginWatchVarSub;
   public showDetails: boolean | undefined;
   public showItemDetails: boolean | undefined;
   public showCommentDetails: boolean | undefined;
   public dateDisplay: number | undefined;
   public expectedDateDisplay: string | undefined;
   protected comments: Lookup<"commentID", AugmentedPurchasingComment> = new Lookup(
      "commentID",
   );
   protected status: string | undefined;
   protected disapproveReason: string | undefined;
   protected vendorNameStr: string = "";
   protected budgetNameStr: string = "";
   protected displayName: string | undefined;
   protected total: number = 0;
   protected receivable: boolean | undefined;
   protected poNumberForDisplay: string | undefined;

   private readonly modalService = inject(ModalService);
   private readonly router = inject(Router);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageLocation = inject(ManageLocation);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly currentLocationID = signal<number | undefined>(undefined);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationsQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationsQueries.detail(this.currentLocationID()),
   );

   protected readonly isCurrencyPending = computed(
      () => this.locationQuery.isPending() || this.accountCurrencyQuery.isPending(),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );
   public ngOnInit() {
      const params = this.paramsService.params;
      this.loading = true;
      if (params) {
         if (params?.resolve) {
            this.resolve = params.resolve;
         }
         if (params?.modalInstance) {
            this.modalInstance = params.modalInstance;
         }
         this.data = this.resolve?.data;

         if (this.data?.title) {
            this.title = this.data.title;
         }
         if (this.data?.message) {
            this.message = this.data.message;
         }
      } else if (this.prID) {
         this.data = {};
         this.data.prID = this.prID;
      }

      this.loginWatchVarSub = this.manageUser.currentUserChanges$
         .pipe(filter((currentUser) => currentUser !== undefined))
         .subscribe((currentUser) => {
            this.CID = currentUser.userInfo.customerID;
            this.currencySymbol = currentUser.currency.symbol;
         });

      //if they are not logged in then we need to close the model because they are redirected to the login page.
      this.manageLogin.checkLogin().then((answer) => {
         if ((answer as any).data.success === false) {
            this.modalInstance.close();
         }
      });

      this.data.buildData = this.buildData;
      this.OpenBillWatchVarSub = this.manageObservables.setSubscription(
         "OpenBillWatchVar",
         () => {
            this.buildData();
         },
      );
      this.currentLocationID.set(this.data?.locationID);
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.OpenBillWatchVarSub,

         this.loginWatchVarSub,
      ]);
   }

   setDetails = () => {
      if (!this.bill || !this.data.prID || this.purchaseOrder === undefined) return;
      this.showDetails = this.showDetails ?? true;
      this.showItemDetails = this.showItemDetails ?? true;
      this.showCommentDetails = this.showCommentDetails ?? true;
      if (this.bill.date) this.dateDisplay = this.bill.date * 1000;
      if (this.purchaseOrder?.expectedDate) {
         this.expectedDateDisplay = `${this.purchaseOrder.expectedDate * 1000}`;
      } else {
         this.expectedDateDisplay = "";
      }

      this.poNumberForDisplay = this.managePO.getPurchaseOrderNumberForDisplay(
         this.purchaseOrder.poID,
      )?.poNumberForDisplay;
   };

   closeOut = () => {
      if (this.data.closePopup) {
         this.data.closePopup();

         return;
      }

      if (isMobile()) {
         this.router.navigate(["/mobileDashboard"]);

         return;
      }
      if (!this.bill) return;
      this.router.navigate(["/prManagement", this.bill.locationID]);
   };

   buildData = () => {
      this.managePO.getBillDetails(this.data.prID).then((comments) => {
         if (!this.data.prID) return;
         const getBillResponse = this.managePO.getBill(this.data.prID);
         if (getBillResponse) this.bill = getBillResponse;
         if (this.bill?.poID) {
            const getPurchaseOrderResponse = this.managePO.getPurchaseOrder(
               this.bill.poID,
            );
            if (getPurchaseOrderResponse) this.purchaseOrder = getPurchaseOrderResponse;
         }
         const billStatusAndReceivableInfo = this.managePO.getBillStatusAndReceivableInfo(
            this.data.prID,
         );
         this.status = billStatusAndReceivableInfo?.currentStatus.name;
         this.receivable = billStatusAndReceivableInfo?.receivable;
         this.disapproveReason = this.purchaseOrder?.disapproveReason ?? undefined;
         if (this.purchaseOrder?.vendorID) {
            this.vendorNameStr =
               this.manageVendor.getVendor(this.purchaseOrder.vendorID)?.vendorName ?? "";
         }
         if (this.purchaseOrder?.budgetID)
            this.budgetNameStr =
               this.managePO.getBudget(this.purchaseOrder.budgetID)?.name ?? "";
         this.displayName = this.managePO.getPurchasingAssignmentName({
            userID: this.bill?.userID ?? 0,
            profileID: this.bill?.profileID ?? 0,
            defaultValue: this.lang().TheUserThatStartedThePO,
         });

         if (!this.bill) {
            throw new Error("The pr.wrapper component must have a bill to build data");
         }
         this.total = this.managePO.getBillTotals(this.bill.prID)?.total ?? 0;
         if (!comments) {
            this.alertService.addAlert(
               this.lang().WhoopsThatBillCanNoLongerBeFound,
               "warning",
               6000,
            );
            return;
         }
         if (this.purchaseOrder?.locationID) {
            this.location = this.manageLocation.getLocation(
               this.purchaseOrder.locationID,
            );
         }
         this.customFields = [
            { id: 1 },
            { id: 2 },
            { id: 3 },
            { id: 4 },
            { id: 5 },
            { id: 6 },
         ]; //used to show the number of custom fields

         this.setDetails();

         if (comments && this.bill.prID) {
            for (const comment of comments) {
               const commentResponse = this.managePO.buildPurchasingComment(
                  "pr",
                  this.bill.prID,
                  comment,
               );
               this.comments.set(commentResponse.commentID, commentResponse);
            }
         }
         this.comments = this.comments.orderBy("timestamp");

         this.superUser = this.credService.checkCredGlobal(
            this.credService.Permissions.ManageRoles,
         ); //if they can manage roles they must be a super user
         this.loading = false;

         this.transactions = new LimbleMap();
         for (const transactionID of this.bill.transactionIDs) {
            const transaction = this.managePO.getBillTransaction(transactionID);
            if (!transaction?.poItemID) continue;
            const purchaseOrderItem = this.managePO.getPurchaseOrderItem(
               transaction.poItemID,
            );
            const purchaseOrderItemRelatedInfo =
               this.managePO.getPurchaseOrderItemRelatedInfo(transaction.poItemID);
            this.transactions.set(
               transactionID,
               Object.assign(transaction, {
                  itemType: purchaseOrderItem?.itemType,
                  itemName: purchaseOrderItemRelatedInfo?.itemName,
               }),
            );
         }
         this.transactions.orderBy("itemName");
         this.transactions.orderBy("itemType");
      });
   };

   addPRComment = () => {
      if (!this.bill) return;
      this.managePO.cleanCommentTempFiles();
      const instance = this.modalService.open(AddPRComment);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AddCommentMsg,
            title: this.lang().AddComment,
            data: {
               CID: this.CID,
               prID: this.bill.prID,
            },
         },
      };

      instance.result.then(async (result) => {
         if (!this.bill) return;
         if (result != 0) {
            if (result.newMentionedList && result.newMentionedList.length > 0) {
               this.addNewMentionsToPRNotifications(
                  result.newMentionedList,
                  this.bill.prID,
               );
            }
            const autoGen = 0;

            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            const commentResponse = await this.managePO.addBillComment(
               result.comment,
               this.bill.prID,
               autoGen,
               true,
            );
            this.loadingBarService.remove();
            if (!commentResponse) {
               this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               return;
            }
            this.comments.set(commentResponse.commentID, commentResponse);
            this.comments.orderBy("timestamp");
            this.alertService.addAlert(this.lang().AddCommentSuccess, "success", 1000);
         }
      });
   };

   addNewMentionsToPRNotifications = (newMentionedList, prID) => {
      for (const item of newMentionedList) {
         this.managePO.addUserToBillNotifications(item.userID, prID, "mentioned by");
      }
   };

   deleteComment = (comment) => {
      if (this.superUser == false) {
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteCommentMsg,
            title: this.lang().DeleteComment,
         },
      };

      instance.result.then(async (result) => {
         if (result == 1) {
            const deleteBillCommentResponse =
               await this.managePO.deleteBillComment(comment);
            if (!deleteBillCommentResponse) {
               this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               return;
            }
            this.comments.delete(comment.commentID);
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         }
      });
   };

   popPoComponent = () => {
      if (!this.purchaseOrder) return;
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: this.purchaseOrder.poID },
         },
      };
   };

   addPRTransaction = () => {
      if (!this.bill) return;
      const instance = this.modalService.open(PickPOItems);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: this.lang().WhichItemsDidYouReceive,
               poID: this.bill.poID,
            },
         },
      };

      instance.result.then((result) => {
         if (!this.bill) return;
         if (result) {
            this.managePO.addBillTransactions(this.bill.prID, result).then((answer) => {
               if (answer.data.success == true) {
                  if (answer.data.failed.length > 0) {
                     let str = this.lang().WhoopsThoseItemsHaveAlreadyBeenFullyReceived;

                     str += "<br /><br />";
                     for (const key in answer.data.failed) {
                        if (!answer.data.failed[key].poItemID) continue;
                        const itemName = this.managePO.getPurchaseOrderItemRelatedInfo(
                           answer.data.failed[key].poItemID,
                        )?.itemName;
                        if (!itemName) continue;
                        str = `${str + itemName}<br />`;
                     }
                     str += "<br />";
                     str += this.lang().RefreshingData;
                     this.alertService.addAlert(str, "warning", 10000);

                     this.managePO.getData().then(() => {
                        this.buildData();
                     });
                  } else {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  }
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               }
            });
         }
      });
   };

   deletePR = () => {
      if (!this.bill) return;
      if (
         !this.credService.isAuthorized(
            this.bill.locationID,
            this.credService.Permissions.DeleteBill,
         )
      ) {
         this.alertService.addAlert(this.lang().cred159Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteBillMsg,
            title: this.lang().DeleteBill,
         },
      };

      instance.result.then((result) => {
         if (!this.bill) return;
         if (result == 1) {
            this.managePO.deleteBill(this.bill.prID).then((answer) => {
               if (answer) {
                  this.closeOut();
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               }
            });
         }
      });
   };

   submitPR = () => {
      if (!this.bill) return;
      if (
         !this.credService.isAuthorized(
            this.bill.locationID,
            this.credService.Permissions.ReceivePOItems,
         )
      ) {
         this.alertService.addAlert(this.lang().cred152Fail, "danger", 10000);
         return;
      }

      this.processing = true;
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().SubmitBillMsg,
            title: this.lang().SubmitBill,
         },
      };

      instance.result.then(
         (result) => {
            if (!this.bill) return;
            if (result == 1) {
               this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
               this.managePO.submitBill(this.bill.prID).then((answer) => {
                  this.processing = false;
                  if (answer.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
                  }
                  this.loadingBarService.remove();
               });
            } else {
               this.processing = false;
            }
         },
         () => {
            this.processing = false;
            this.loadingBarService.remove();
         },
      );
   };

   changePRAssignment = () => {
      if (!this.bill) return;
      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: "",
               title: this.lang().ChangeBillAssignment,
               locationID: this.bill.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.bill.userID,
               defaultProfile: this.bill.profileID,
            },
         },
      };

      instance.result.then((result) => {
         if (!this.bill) return;
         if (result != 0) {
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            this.managePO
               .changeBillAssignment(
                  result.userID,
                  result.profileID,
                  this.bill.prID,
                  result.multiUsers,
                  true,
               )
               .then((answer) => {
                  this.loadingBarService.remove();
                  if (answer) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         }
      });
   };

   markPRAsPaid = () => {
      if (!this.bill) return;
      if (
         !this.credService.isAuthorized(
            this.bill.locationID,
            this.credService.Permissions.MarkBillAsPaid,
         )
      ) {
         this.alertService.addAlert(this.lang().cred157Fail, "danger", 10000);
         return;
      }

      this.processing = true;
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().MarkBillAsPaidMsg,
            title: this.lang().MarkBillAsPaid,
         },
      };

      instance.result.then(
         (result) => {
            if (!this.bill) return;
            if (result == 1) {
               this.managePO.markBillAsPaid(this.bill.prID).then((answer) => {
                  if (!this.bill) return;
                  this.processing = false;
                  if (answer.data.success == true) {
                     if (answer.data.comment) {
                        this.managePO.buildPurchasingComment(
                           "pr",
                           this.bill.prID,
                           answer.data.comment,
                        );
                     }

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
                  }
               });
            } else {
               this.processing = false;
            }
         },
         () => {
            this.processing = false;
         },
      );
   };

   popVendor = () => {
      if (!this.purchaseOrder?.vendorID) return;
      const vendor = this.manageVendor.getVendor(this.purchaseOrder.vendorID);

      if (!vendor) {
         return;
      }

      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   close = () => {
      this.modalInstance.close();
   };
}
