import type { PurchasableCountedDto } from "src/app/parts/purchasable/purchasable.dto.types";
import { Purchasable } from "src/app/parts/purchasable/purchasable.model";

export class CountedPurchasable extends Purchasable {
   public readonly size: number;

   public constructor(params: PurchasableCountedDto) {
      super(params);

      this.size = params.size;
   }
}
