import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { type TreeBranch, TreeCollapseService } from "@limble/limble-tree";
import {
   ModalService,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   PopoverDirective,
   TooltipDirective,
   UpsellPopover,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import $ from "jquery";
import moment from "moment";
import { Subscription } from "rxjs";
import { PickAssetInfoToLink } from "src/app/assets/components/pickAssetInfoToLinkModal/pickAssetInfoToLink.modal.component";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { ChkItem } from "src/app/tasks/components/chkItemElement/chkItem.element.component";
import { ChkItemService } from "src/app/tasks/components/chkItemElement/chkItem.service";
import { PickItem } from "src/app/tasks/components/pickItemModal/pickItem.modal.component";
import { Question } from "src/app/tasks/components/questionModal/question.modal.component";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { UpdateTaskStateService } from "src/app/tasks/components/task-form/services/update-task-state.service";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { ManageTaskItemOption } from "src/app/tasks/services/manageTaskItemOption";
import type {
   TaskInfo,
   TaskFormSettings,
} from "src/app/tasks/types/info/task-info.types";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "chk-item-build-options",
   templateUrl: "./chkItemBuildOptions.element.component.html",
   styleUrls: ["./chkItemBuildOptions.element.component.scss"],
   imports: [
      TooltipDirective,
      MinimalIconButtonComponent,
      LoadingAnimationComponent,
      UpsellPopover,
      PopoverDirective,
   ],
})
export class ChkItemBuildOptions implements OnInit, OnDestroy {
   @Input() public info?: TaskInfo | TaskFormSettings;
   @Input() public item;
   @Input() public items;
   @Input() public options: Array<any> = [];
   @Input() public type;
   @Input() public treeBranch?: TreeBranch<ChkItem>;
   @Input() public noDelete: boolean = false;
   @Input() public onlyDelete: boolean = false;

   public customerID;
   public showPlaceholders;
   public ItemPlaceHolderTooltip;
   public imageExts;
   public task: Task | TaskTemplateEntity | TaskEntity | undefined;
   public uploadObj;
   public fileUploaded;
   public imageUploader;
   public docUploader;
   public uploading;
   public instructionSetTemplate = false;
   public instructionSetTemplateItem = false;
   public isInstructionSet;
   protected featureUnlimitedWOs: boolean = false;
   protected TaskInstructionType = TaskInstructionTypeID;
   private manageFeatureFlagsSub: Subscription = new Subscription();
   protected canAddInstruction: boolean = false;
   protected woInstructionLimit: number = 2;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly manageTaskItemOption = inject(ManageTaskItemOption);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFiles = inject(ManageFiles);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly paramsService = inject(ParamsService);
   private readonly collapseService = inject(TreeCollapseService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);
   private readonly chkItemService = inject(ChkItemService);
   private readonly updateTaskStateService = inject(UpdateTaskStateService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (this.treeBranch === undefined) {
         throw new Error("'treeBranch' is a required input");
      }

      //clears out the displays
      this.item.itemDescriptionDisplay = false;
      this.item.itemSourceDisplay = false;
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.showPlaceholders = false;
      for (const item of this.items) {
         if (
            item.itemTypeID == TaskInstructionTypeID.AssignPM ||
            item.itemTypeID == TaskInstructionTypeID.StartWO
         ) {
            this.showPlaceholders = true;
         }
      }

      this.setInstructionSetTemplateStatus();

      if (
         !(
            this.item.itemTypeID == TaskInstructionTypeID.CheckBox ||
            this.item.itemTypeID == TaskInstructionTypeID.OptionList ||
            this.item.itemTypeID == TaskInstructionTypeID.TextBox ||
            this.item.itemTypeID == TaskInstructionTypeID.DropdownList ||
            this.item.itemTypeID == TaskInstructionTypeID.DatePicker ||
            this.item.itemTypeID == TaskInstructionTypeID.Note ||
            this.item.itemTypeID == TaskInstructionTypeID.FileOrPictureAttachment ||
            this.item.itemTypeID == TaskInstructionTypeID.Number
         )
      ) {
         this.showPlaceholders = false; //only some items are supported for placeholders
      }

      if (Number(this.item.itemTypeID) === TaskInstructionTypeID.InstructionSet) {
         this.isInstructionSet = true;
      }

      this.ItemPlaceHolderTooltip = `${this.lang().ItemPlaceholderTooltip} {{instruction-${this.item.checklistItemCount}}}`;

      this.imageExts = this.manageFiles.imageExts;

      this.task = this.info?.task;

      if (this.task === undefined) return;

      //file upload settings - this doesn't seem to run before the uploader all of the way so things are a little different
      this.uploadObj = {};
      this.uploadObj.primaryID = "fileName";
      this.uploadObj.deleteData = {
         itemID: this.item.itemID,
         item: this.item,
      };
      this.uploadObj.deleteCall = this.removeItemImage;
      this.uploadObj.uploadCall = this.manageTaskItem.addInstructionalFile;
      this.uploadObj.posturl = `phpscripts/checklistManager.php?action=makeFileForItem&itemBatchID=${this.item.itemBatchID}&itemID=${this.item.itemID}&locationID=${this.task.locationID}`;
      this.uploadObj.viewOnly = false;
      this.uploadObj.onUploadPopEditor = false; //boolean will images immediately open for editing after upload

      this.uploadObj.deleteSuccess = (answer) => {
         if (answer.data.success == true) {
            this.item.buildInstructionImagesArr();
            this.alertService.addAlert(this.lang().successMsg, "success", 3000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      };

      this.uploadObj.uploadComplete = (data) => {
         this.uploading = false;
         if (data.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            this.fileUploaded = true;
            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );
         }
         if (this.item.itemFileName === null) {
            this.item.itemFileName = "";
         }
         this.item.itemFileName = `${this.item.itemFileName + data.fileName};`;

         this.chkItemService.setTaskItemFiles(this.item, this.task, this.customerID);
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      };

      this.imageUploader = this.manageFiles.createImageUploader;
      this.docUploader = this.manageFiles.createDocUploader;

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(() => {
         this.hasReachedWOLimit().then((limitReached) => {
            this.canAddInstruction = !limitReached;
         });
         this.woInstructionLimit = this.manageFeatureFlags.getWOInstructionLimit();
      });
   }

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   goToPlaceholderTutorial = () => {
      window.open(
         "https://help.limblecmms.com/en/articles/3682914-passing-data-from-parent-task-to-children-tasks",
         "_blank",
      );
   };

   uploaded = () => {
      this.uploading = false;
   };

   uploadingFunction = () => {
      this.uploading = true;
   };

   uploadFile = (uploader) => {
      if (this.onlyDelete) {
         return;
      }

      this[uploader].browse();
      this.manageFiles.setUploadServerSettings(
         this[uploader],
         this.uploadObj,
         this.uploaded,
         this.uploadingFunction,
      );

      if (this.instructionSetTemplate) {
         //not sure how to tell if an upload has actually happened here, so running update timestamp anyway
         this.updateParentInstructionSetTimeStamp();
      }
      document.body.onfocus = () => {
         setTimeout(() => {
            if (this[uploader].status == 0) {
               this.uploading = false;
            }
            document.body.onfocus = null;
         }, 700);
      };
   };

   //opens a doka instance -> no permissions here as if you are on the build tasks page you should have permission to edit the files.
   openViewer = async (src) => {
      if (this.onlyDelete) {
         return;
      }
      const doka = await this.manageFiles.createImageEditor(src);

      //when the user clicks confirm this deletes the old version of the file, and uploads the edited file
      //has to convert doka's output to formdata for uploading and play with the fileName a little bit so the names
      //don't get longer each time the file is edited
      doka.onconfirm = (output) => {
         const fileName = src.split("/")[src.split("/").length - 1];
         this.removeItemImage(this.item, fileName);

         const formData = new FormData();
         formData.append("myfile", output.file, fileName.replace(/\d\d\d\d-/, ""));

         this.uploadObj.uploadCall(this.uploadObj.posturl, formData).then((response) => {
            this.uploadObj.uploadComplete(response.data);
         });
      };
   };

   deleteItem = () => {
      if (this.noDelete) {
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteItemTooltip,
            title: this.lang().DeleteItem,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            this.manageTaskItem.itemDelete(this.item).then((answer) => {
               if (answer.data.success == true) {
                  assert(this.info !== undefined);
                  for (const item of this.items) {
                     if (item.itemID == this.item.itemID) {
                        this.deleteDescendants(this.item.checklistItemCount);
                     }
                  }
                  this.updateTaskStateService.buildData(true);
                  this.loadingBarService.remove();

                  if (this.instructionSetTemplate) {
                     this.updateParentInstructionSetTimeStamp();
                  }

                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   deleteDescendants = (checklistItemCount) => {
      if (this.info === undefined) return;
      for (const item of this.items) {
         if (item.itemParentID == checklistItemCount) {
            this.updateTaskStateService.buildData(true);
         }
      }
   };

   protected async addSubItem() {
      if (this.onlyDelete) {
         return;
      }
      if (this.onlyDelete) {
         return;
      }
      if (this.type === "item") {
         if (
            this.task?.checklistCreatedDate &&
            (await this.manageFeatureFlags.hasReachedWOInstructionLimitFor(
               moment.unix(this.task.checklistCreatedDate),
            )) &&
            this.info &&
            this.info.items.length > 0 && // All tasks are allowed to have up to one instruction regardless of feature flags
            this.info.items.length < 2 // If limit is reached only allow instructions to be added to tasks that already have multiple instructions
         ) {
            return;
         }

         const instance = this.modalService.open(PickItem);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().AddInstructionMsg,
               title: this.lang().AddInstruction,
               button: this.lang().Add,
               isInstructionSet:
                  this.item.instructionSetChild ||
                  Number(this.item.itemTypeID) === TaskInstructionTypeID.InstructionSet,
            },
         };

         instance.result.then((response) => {
            if (this.task === undefined) return;
            if (typeof response === "object" && response.instructionSet) {
               this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
               this.manageTaskItem
                  .generateInstructionSetInstance(
                     response.instructionSetItem.itemID,
                     this.task.checklistID,
                     null,
                     null,
                     this.item.checklistItemCount,
                  )
                  .then((answer) => {
                     if (answer.data.success) {
                        assert(this.info !== undefined);
                        this.updateTaskStateService.buildData(true);
                        this.item.collapsed = false;
                        this.manageTaskItemOption.updateItemCollapsed(this.item, 0);
                        if (this.instructionSetTemplate) {
                           this.updateParentInstructionSetTimeStamp();
                        }
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                        this.loadingBarService.remove();
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                        this.loadingBarService.remove();
                     }
                  });
            } else if (response > 0) {
               const itemTypeID = response;
               this.manageTaskItem
                  .insertChild(this.item, itemTypeID, 0)
                  .then((answer) => {
                     if (answer.data.success == true) {
                        assert(this.info !== undefined);
                        this.item.collapsed = false;
                        this.manageTaskItemOption.updateItemCollapsed(this.item, 0);
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                        this.updateTaskStateService.refreshInstructions();
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     }
                  });
            }
         });
      } else if (this.type === "optionParent") {
         this.manageTaskItemOption.insOption(this.item).then((answer) => {
            if (answer.data.success == true) {
               assert(this.info !== undefined);
               if (!this.options) {
                  this.options = [];
               }

               this.options.push(answer.data.myOption[answer.data.oID]);
               this.item.collapsed = false;
               this.updateTaskStateService.refreshInstructions();
               this.manageTaskItemOption.updateItemCollapsed(this.item, 0);

               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      }
   }

   toggleLog = () => {
      let message = "";
      let icon = "";

      if (this.item.assetIDToLogResponseTo > 0) {
         const tempAsset = this.manageAsset.getAsset(this.item.assetIDToLogResponseTo);
         if (tempAsset) {
            message = tempAsset.assetName ?? "";
            icon = "cube";
         }

         const instance = this.modalService.open(Question);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: message,
               icon: icon,
               title: this.lang().LogTheResponseOfThisInstruction,
               data: {
                  yesButton: this.lang().BreakLink,
                  noButton: this.lang().Change,
               },
            },
         };

         instance.result.then((result) => {
            if (result == 1) {
               this.manageTaskItem
                  .removeItemResponseToLogLink(
                     this.item,
                     this.item.assetIDToLogResponseTo,
                  )
                  .then((answer) => {
                     if (answer.data.success == true) {
                        if (this.instructionSetTemplate) {
                           this.updateParentInstructionSetTimeStamp();
                        }
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     }
                  });
            } else if (result == 0) {
               this.setLogOnAsset();
            }
         });
      } else {
         this.setLogOnAsset();
      }
   };

   setLogOnAsset = () => {
      if (this.task === undefined) return;

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = "";
      instance.title = this.lang().WhichAssetWouldYouLikeToLogThisInstructionsResponse;
      instance.singleLocation = this.task.locationID;
      instance.selectOne = true;
      instance.restrictToCred = true;
      instance.iDontKnowOption = false;

      modalRef.result.then((asset) => {
         if (asset) {
            this.manageTaskItem
               .addItemResponseToLogLink(this.item, asset.assetID)
               .then((answer) => {
                  if (answer.data.success == true) {
                     if (this.instructionSetTemplate) {
                        this.updateParentInstructionSetTimeStamp();
                     }
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   getCurrentlyLinkedMsg = () => {
      const field = this.manageAsset.getField(this.item.fieldIDForLink);
      if (!field) {
         return "";
      }
      if (this.item.assetIDForLink > 0) {
         const asset = this.manageAsset.getAsset(this.item.assetIDForLink);
         if (!asset) {
            return "";
         }
         return `${this.lang().CurrentlyLinkedTo}: <b><i class="fa-solid fa-cube fa-fw"></i>${asset.assetName} - <i class="fa-solid fa-circle-info fa-fw"></i>${field.fieldName}</b>`;
      }
      if (this.item.fieldIDForLink > 0) {
         return `${this.lang().CurrentlyLinkedTo}: <b><i class="fa-solid fa-circle-info fa-fw"></i>${field.fieldName}</b> ${this.lang().onTheAssetThisTaskBelongsTo}`;
      }
      return "";
   };

   toggleLink = () => {
      if (this.onlyDelete) {
         return;
      }
      const data: any = {};
      data.chk = this.task;
      data.item = this.item;

      const message = this.getCurrentlyLinkedMsg();

      if (!this.item.fieldIDForLink || Number(this.item.fieldIDForLink) === 0) {
         this.setLinkOnAssetInfo();
         return;
      }

      const instance = this.modalService.open(Question);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: message,
            title: this.lang().LinkResponseToAssetFieldTitle,
            data: {
               yesButton: this.lang().BreakLink,
               noButton: this.lang().Change,
            },
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.manageTaskItem.linkItemToAssetInfo(this.item, 0, 0).then((answer) => {
               if (answer.data.success == true) {
                  if (this.instructionSetTemplate) {
                     this.updateParentInstructionSetTimeStamp();
                  }
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  this.item.assetIDForLink = 0;
                  this.item.fieldIDForLink = 0;
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         } else if (result === 0) {
            this.setLinkOnAssetInfo();
         }
      });
   };

   setLinkOnAssetInfo = () => {
      //only used in the above function, so pass the necessary data into it

      if (this.task === undefined) return;

      const message = this.getCurrentlyLinkedMsg();
      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = message;
      instance.title = this.lang().ToggleLinkTitle;
      instance.singleLocation = this.task.locationID;
      instance.selectOne = true;
      instance.restrictToCred = true;
      instance.iDontKnowOption = false;
      instance.parentTaskAssetID = this.task.assetID ?? 0;
      instance.choosingLink = true;

      modalRef.result.then((result) => {
         if (result) {
            const tempInstance = this.modalService.open(PickAssetInfoToLink);
            this.paramsService.params = {
               modalInstance: tempInstance,
               resolve: {
                  message: this.lang().LinkResponseToAssetFieldMsg,
                  title: this.lang().LinkResponseToAssetFieldTitle,
                  data: {
                     chk: this.task,
                     item: this.item,
                     assetID: result.assetID,
                     selectFromAllFieldsAtLocation: result.useParentTaskAsset,
                  },
               },
            };
            tempInstance.result.then((result2) => {
               this.linkChosenFieldToItem(result2);
            });
         }
      });
   };

   linkChosenFieldToItem = (result) => {
      if (result === "empty") {
         //the response is empty so update it to no field
         this.manageTaskItem.linkItemToAssetInfo(this.item, 0, 0).then((answer) => {
            if (answer.data.success == true) {
               if (this.instructionSetTemplate) {
                  this.updateParentInstructionSetTimeStamp();
               }
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               this.item.fieldIDForLink = 0;
               this.item.assetIDForLink = null;
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      } else if (result) {
         if (result.valueUnique === 1) {
            this.alertService.addAlert(this.lang().fieldValueUniqueError, "danger", 6000);
            return;
         }

         if (!result.assetID) {
            result.assetID = 0;
         }

         this.manageTaskItem
            .linkItemToAssetInfo(this.item, result.fieldID, result.assetID)
            .then((answer) => {
               if (answer.data.success === true) {
                  if (answer.data.newOptions) {
                     assert(this.info !== undefined);
                     answer.data.newOptions.forEach((option) => {
                        option.newItemOption.myOption[
                           option.newItemOption.oID
                        ].itemOptionText = option.name;
                        this.options.push(
                           option.newItemOption.myOption[option.newItemOption.oID],
                        );
                     });
                     this.updateTaskStateService.refreshInstructions();
                     this.item.collapsed = false;
                     this.manageTaskItemOption.updateItemCollapsed(this.item, 0);
                  }
                  if (this.instructionSetTemplate) {
                     this.updateParentInstructionSetTimeStamp();
                  }
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  this.item.fieldIDForLink = result.fieldID;
                  this.item.assetIDForLink = result.assetID;
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }
   };

   removeItemImage = (item, itemFileName) => {
      if (this.onlyDelete) {
         return;
      }
      const deleteData = {
         itemID: this.item.itemID,
         item: item,
         fileName: itemFileName,
      };
      this.manageTaskItem.removeItemImage(deleteData).then((answer) => {
         if (answer.data.success == true) {
            this.item.buildInstructionImagesArr();
            if (this.instructionSetTemplate) {
               this.updateParentInstructionSetTimeStamp();
            }
            this.alertService.addAlert(this.lang().successMsg, "success", 3000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   private async hasReachedWOLimit(): Promise<boolean> {
      const createdDate = this.task?.checklistCreatedDate;
      const limitReached =
         (await this.manageFeatureFlags.hasReachedWOInstructionLimitFor(
            moment.unix(createdDate ?? 0),
         )) &&
         this.info !== undefined &&
         this.info.items.length > 0 && // All tasks are allowed to have up to one instruction regardless of feature flags
         this.info.items.length < 2; // If limit is reached only allow instructions to be added to tasks that already have multiple instructions

      return limitReached;
   }

   protected async duplicateItem(item) {
      if (this.onlyDelete) {
         return;
      }
      const hasReachedWOLimit = await this.hasReachedWOLimit();
      if (hasReachedWOLimit) {
         return;
      }

      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      this.manageTaskItem.duplicateItem(item).then((answer) => {
         if (answer.data.success == true) {
            assert(this.info !== undefined);
            this.updateTaskStateService.buildData(true);
            this.loadingBarService.remove();

            if (this.instructionSetTemplate) {
               this.updateParentInstructionSetTimeStamp();
            }

            this.alertService.addAlert(this.lang().successMsgDuplicated, "success", 3000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   protected async collapseItems(): Promise<AxiosResponse> {
      assert(this.treeBranch);
      if (this.collapseService.isCollapsed(this.treeBranch)) {
         this.collapseService.expand(this.treeBranch);
         this.item.collapsed = false;
      } else {
         this.collapseService.collapse(this.treeBranch);
         this.item.collapsed = true;
      }
      return this.manageTaskItemOption.updateItemCollapsed(
         this.item,
         Number(this.item.collapsed),
      );
   }
   syncInstructionSet = () => {
      if (this.onlyDelete) {
         return;
      }
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ConfirmSyncInstructionSet,
            title: this.lang().ConfirmSyncInstructionSetTitle,
         },
      };
      instance.result.then(async (result) => {
         if (result === 1) {
            const itemParentResponse = parseInt(this.item.itemParentResponse);
            const response = await this.manageTaskItem.syncInstructionSetInstance(
               Number(this.item.itemID),
               Number(this.item.checklistID),
               Number(this.item.instructionSetBatchID),
               isNaN(itemParentResponse) ? null : itemParentResponse,
            );
            if (response.data.success) {
               assert(this.info !== undefined);
               this.updateTaskStateService.buildData(true);
               this.alertService.addAlert(this.lang().successMsg, "success", 3000);
            } else if (response.data.reason === "templateDeleted") {
               this.alertService.addAlert(
                  this.lang().InstructionSetTemplateDeletedWarning,
                  "warning",
                  6000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         }
      });
   };

   updateParentInstructionSetTimeStamp = () => {
      //needs instructionSetBatchID to query for the matching one with a instructionSetTemplate of 1

      this.manageTaskItem.updateParentInstructionSetTimeStamp(this.item.checklistID);
   };

   private setInstructionSetTemplateStatus(): void {
      const parentTask = this.info?.task;
      if (parentTask === undefined) return;
      if (parentTask.checklistTemplate === 6) {
         this.instructionSetTemplate = true;
         if (this.item.itemTypeID === TaskInstructionTypeID.InstructionSet) {
            //if the item is an instruction set, check whether the task it's on is an instruction set task to toggle whether delete/duplicate buttons show
            this.instructionSetTemplateItem = true;
         }
      }
   }
}
