import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import {
   GetCurrencyDataResponseDtoSchema,
   type GetCurrencyDataResponseDto,
} from "src/app/purchasing/currency/dto/php/currency.dto";
import { validate } from "src/app/shared/rxjs-operators/validate";

@Injectable({ providedIn: "root" })
export class CurrenciesPhpApiService {
   private readonly http = inject(HttpClient);
   private readonly url = `phpscripts/manageUser.php`;

   public getCurrencyData(): Observable<GetCurrencyDataResponseDto> {
      return this.http
         .post(this.url, undefined, {
            params: {
               action: "getCurrencyData",
            },
         })
         .pipe(validate(GetCurrencyDataResponseDtoSchema));
   }
}
