import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   LimUiModalRef,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   RadioButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { finalize, take } from "rxjs";
import { EditAssetField } from "src/app/assets/components/editAssetFieldModal/editAssetField.modal.component";
import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import { AssetFieldDefinitionService } from "src/app/assets/services/asset-field-definition.service";
import { AssetFieldScopeType } from "src/app/assets/services/asset-field.types";
import { AssetTemplateFieldService } from "src/app/assets/services/asset-template-field.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldType } from "src/app/assets/types/field/type/asset-field-type.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "add-asset-field",
   templateUrl: "./addAssetField.modal.component.html",
   styleUrls: ["./addAssetField.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      RadioButtonComponent,
      SearchBoxComponent,
      NoSearchResults,
      NgClass,
      IconComponent,
      TooltipDirective,
      MinimalIconButtonComponent,
      FormsModule,
      BasicModalFooterComponent,
      IconAlias,
      NgxSkeletonLoaderModule,
   ],
})
export class AddAssetField implements OnInit {
   public selectedFieldID: number;
   public selectedType: number;
   public selectedArr;
   public errorMsg: string;
   public asset: Asset | undefined;
   public modalInstance: LimUiModalRef<AddAssetField, boolean | undefined> =
      inject(LimUiModalRef);
   public assetID = signal<number>(0);
   public assetTemplateID = signal<number>(0);
   public isForTemplate = computed(() => {
      return this.assetTemplateID() > 0;
   });
   public fields: Lookup<"fieldID", AssetField> | undefined;
   public credConfigureNewFields;
   public currencySymbol;
   public allFields: Lookup<"fieldID", AssetField> | undefined;
   public types: Lookup<"fieldTypeID", AssetFieldType> | undefined;
   public showSuggested: boolean = true;
   public showNew;
   public searchFields;
   public nameErr;
   public newName;
   public userClicks;
   public fieldDisplayInfo: Map<
      number,
      { greyOut: boolean; selected: boolean; fieldNameTemp: string }
   > = new Map();
   protected isLoading: boolean = false;
   //public typeDisplayInfo: Map<number, { selected: boolean }> = new Map();

   protected readonly AssetFieldTypeID = AssetFieldTypeID;

   private readonly manageAsset = inject(ManageAsset);
   private readonly assetTemplateFieldService = inject(AssetTemplateFieldService);
   private readonly assetFieldDefinitionService = inject(AssetFieldDefinitionService);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageFilters = inject(ManageFilters);
   private readonly modalService = inject(ModalService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public message: string = this.lang().AddAFieldMsg;

   public skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

   public constructor() {
      this.errorMsg = "";
      this.selectedFieldID = 0;
      this.selectedType = 0;
   }

   public ngOnInit() {
      this.userClicks = 0;

      this.selectedArr = [];

      this.errorMsg = "";

      if (this.isForTemplate()) {
         this.isLoading = true;
         this.assetFieldDefinitionService
            .getDefinitions({ scopeType: AssetFieldScopeType.Standardized })
            .pipe(
               take(1),
               finalize(() => {
                  this.isLoading = false;
               }),
            )
            .subscribe((fields) => {
               this.fields = new Lookup("fieldID", fields as AssetField[]);
               this.credConfigureNewFields = true;
               this.buildFields();
            });
      } else {
         this.asset = this.manageAsset.getAsset(this.assetID());
         assert(this.asset);

         this.fields = this.manageAsset.getFields();

         this.credConfigureNewFields = this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.CreateNewFieldsAndDeleteExistingFields,
         );
         this.fields = this.fields.filter(
            (field) =>
               field.locationID === this.asset?.locationID &&
               field.scopeType !== AssetFieldScopeType.Standardized,
         );

         this.buildFields();
      }

      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }

      if (this.credConfigureNewFields == false) {
         this.message = this.lang().PleaseSelectFromTheListOfSuggestedFields;
      }
   }

   private buildFields() {
      assert(this.fields);

      this.allFields = this.fields;
      this.types = this.manageAsset.getFieldTypes();

      for (const field of this.fields) {
         this.fieldDisplayInfo.set(field.fieldID, {
            greyOut: false,
            selected: false,
            fieldNameTemp: field.fieldName,
         });
      }

      const values = {};
      this.types = this.types.orderBy("fieldTypeOrder");
      for (const valueID of this.asset?.assetValueIDs ?? []) {
         const value = this.manageAsset.getFieldValue(valueID);
         if (value != undefined) {
            values[value.fieldID] = value;
         }
      }

      for (const field of this.fields) {
         if (values[field.fieldID] != undefined) {
            const displayField = this.fieldDisplayInfo.get(field.fieldID);
            assert(displayField);
            displayField.greyOut = true;
         }
      }

      //grey out fields already added to a template
      if (this.isForTemplate()) {
         this.assetTemplateFieldService
            .getTemplateFieldsMap(this.assetTemplateID())
            .subscribe({
               next: (fieldsMap) => {
                  assert(this.fields);
                  const templateFieldsMap = fieldsMap;
                  for (const field of this.fields) {
                     if (templateFieldsMap.has(field.fieldID)) {
                        const displayField = this.fieldDisplayInfo.get(field.fieldID);
                        assert(displayField);
                        displayField.greyOut = true;
                     }
                  }
               },
            });
      }

      this.fields = this.fields.orderBy("fieldName");
      if (this.fields.size === 0) {
         this.showSuggested = false;
         this.showNew = true;
      } else {
         this.showSuggested = true;
         this.showNew = false;
      }
   }

   filterFields = () => {
      assert(this.allFields);
      const fields = this.allFields.filter((field) =>
         this.manageFilters.searchObjValuesForString(field, this.searchFields),
      );
      this.fields = fields;
   };

   close = () => {
      const data: any = {};
      data.view = "updated";
      data.fields = this.fields;
      this.modalInstance.close(data);
   };

   showFieldOptions = (field: AssetField) => {
      if (!this.isForTemplate()) {
         if (this.asset === null) {
            return;
         }
         assert(this.asset);
         if (
            !this.credService.isAuthorized(
               this.asset.locationID,
               this.credService.Permissions.CreateNewFieldsAndDeleteExistingFields,
            )
         ) {
            this.alertService.addAlert(this.lang().cred65Fail, "danger", 10000);
            return;
         }
      }

      const instance = this.modalService.open(EditAssetField);
      instance.componentInstance.fieldID.set(field.fieldID);
      instance.componentInstance.assetTemplateID.set(this.assetTemplateID());

      instance.result.then((result) => {
         if (
            result.fieldID !== undefined &&
            this.fields !== undefined &&
            result.action === "delete"
         ) {
            this.fields.delete(result.fieldID);
            this.clearSelected();
            return;
         }

         //the field data might be changed
         const updatedField = this.manageAsset.getField(field.fieldID);
         if (updatedField) {
            assert(this.fields);
            this.fields.setValue(updatedField);
            assert(this.allFields);
            this.allFields.setValue(updatedField);

            this.manageAsset.tileFieldsObs$.next(null);
         }
      });
   };

   focusField = (fieldToFocus: AssetField) => {
      const displayInfo = this.fieldDisplayInfo.get(fieldToFocus.fieldID);
      assert(displayInfo);

      //if it is greyed out they shouldn't be able to click it
      if (displayInfo.selected == true) {
         if (this.userClicks <= 1) {
            //only one is selected so they meant to double tap it to select
            this.submit();
            return;
         }
      }
      if (!displayInfo.greyOut) {
         let found = false;
         for (let index = 0; index < this.selectedArr.length; index++) {
            if (this.selectedArr[index] == fieldToFocus.fieldID) {
               this.selectedArr.splice(index, 1);
               found = true;
               displayInfo.selected = false;
            }
         }

         if (!found) {
            this.selectedArr.push(fieldToFocus.fieldID);
            displayInfo.selected = true;
         }
         this.userClicks++;
         this.checkResetUserClicks();

         this.errorMsg = "";
      }
   };

   checkResetUserClicks = () => {
      assert(this.fields);
      this.userClicks = this.manageUtil.checkResetUserClicks(
         Array.from(this.fields),
         this.userClicks,
      );
   };

   focusType = (typeToFocus: AssetFieldType) => {
      if (this.selectedType == typeToFocus.fieldTypeID) {
         this.submit();
         return;
      }
      this.clearSelected();
      this.selectedType = typeToFocus.fieldTypeID;
   };

   clearSelected = () => {
      this.selectedFieldID = 0;
      this.selectedType = 0;
   };

   submit = () => {
      const data: any = {};

      if (this.showNew) {
         data.view = "new";
         assert(this.types);
         for (const type of this.types) {
            if (type.fieldTypeID == this.selectedType) {
               data.type = type;
            }
         }

         data.name = this.newName;

         const options: any = [];
         if (this.selectedType === AssetFieldTypeID.Dropdown) {
            //have at least one option...
            options.push({ order: 1, name: "" });
         }

         data.options = JSON.stringify(options);

         let valid = true;
         this.errorMsg = "";
         this.nameErr = false;

         if (data.type === undefined) {
            this.errorMsg += `${this.lang().PleaseSelectAFieldType}.  <br />`;
            valid = false;
         }

         if (this.manageAsset.usingForbiddenFieldNames(data.name)) {
            this.errorMsg += `${this.lang().ThisNameIsReserved}.  <br />`;
            this.nameErr = true;
            valid = false;
         }

         if (data.name === undefined || data.name == "") {
            this.errorMsg += `${this.lang().PleaseSelectAFieldName}.  <br />`;
            this.nameErr = true;
            valid = false;
         }
         assert(this.fields);
         for (const field of this.fields) {
            if (
               this.newName !== undefined &&
               field.fieldName.toLowerCase() == this.newName.toLowerCase()
            ) {
               this.errorMsg += `${this.lang().addAssetFieldErrorMsgNameTaken}  <br />`;
               this.selectedFieldID = field.fieldID;
               valid = false;
            }
         }

         if (valid) {
            this.modalInstance.close(data);
         }
      }

      if (this.showSuggested) {
         data.view = "suggested";
         data.field = this.selectedArr;

         this.errorMsg = "";

         if (data.field.length == 0) {
            this.errorMsg = this.lang().PleaseSelectASuggestedField;
         } else {
            this.modalInstance.close(data);
         }
      }
      if (this.errorMsg !== "") {
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
      }
   };
}
