import { inject, Injectable } from "@angular/core";
import { map, type Observable } from "rxjs";
import { PurchasableApiService } from "src/app/parts/purchasable/purchasable-api.service";
import { PurchasableMappingService } from "src/app/parts/purchasable/purchasable-mapping.service";
import type { Purchasable } from "src/app/parts/purchasable/purchasable.model";

@Injectable({
   providedIn: "root",
})
export class PurchasableService {
   private readonly apiService = inject(PurchasableApiService);
   private readonly mappingService = inject(PurchasableMappingService);

   public fetchByPart(partID: number): Observable<Array<Purchasable>> {
      return this.apiService
         .fetchByPart(partID)
         .pipe(map((purchasableDto) => this.mappingService.mapDtos(purchasableDto)));
   }
}
