import { Component, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
} from "@limblecmms/lim-ui";
import { map } from "rxjs";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";

@Component({
   selector: "completed-by-filter",
   imports: [
      DropdownButtonComponent,
      DropdownClearFilterItemComponent,
      DropdownDividerComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      TranslateDirective,
   ],
   templateUrl: "./completed-by-filter.component.html",
   styleUrls: ["./completed-by-filter.component.scss"],
})
export class CompletedByFilterComponent extends BaseFilterComponent {
   readonly #tasksApi = inject(TasksApiService);

   protected completedBy?: string | undefined;

   /**
    * This is a label that is shown whenever the filter is active to indicate what was selected
    */
   protected activeFilterLabel?: string | undefined;

   public users = toSignal(
      this.#tasksApi.getUserMetadata().pipe(
         map((metadata) =>
            metadata.completedUsers
               .filter((user) => user.deleted !== true)
               .sort((userA, userB) => {
                  if (userA?.lastName === undefined || userB?.lastName === undefined)
                     return 0;
                  return userA.lastName?.localeCompare(userB.lastName || "");
               }),
         ),
      ),
   );

   public handleSetUser(user) {
      if (!user) return;
      this.completedBy = user.userID;

      this.activeFilterLabel = `${this.translateService.instant("CompletedBy")} - ${user.firstName} ${user.lastName}`;
      const filter = { [this.filter().key]: user.userID };
      this.setFilter(filter, this.activeFilterLabel);
   }

   public handleClear() {
      this.completedBy = undefined;
      this.activeFilterLabel = undefined;
      this.remove.emit(this.filter());
   }

   private setFilter(value, activeLabel: string): void {
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value,
      });
   }
}
