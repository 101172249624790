import type { PipeTransform } from "@angular/core";
import { Pipe } from "@angular/core";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
/*
 * Selects a subset of items from a typed array and returns it as a new array, preserving the type of the items inside.
 */
@Pipe({
   name: "sliceLimbleMap",
   standalone: true,
})
export class SliceLimbleMap implements PipeTransform {
   public transform<T>(
      map: LimbleMap<any, T>,
      start: number,
      end: number,
   ): Iterable<T> | undefined {
      if (!map) {
         return undefined;
      }
      if (map instanceof LimbleMap) {
         return [...map].slice(start, end);
      }
      return map;
   }
}
