import {
   numberAttribute,
   type OnDestroy,
   type OnInit,
   inject,
   Component,
   Input,
   computed,
   type SimpleChanges,
   type OnChanges,
   signal,
} from "@angular/core";
import { LimbleHtmlDirective, LoadingAnimationComponent } from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";

type AssetParent = {
   assetID: number;
   assetName: string | null;
   parentAssetID: number;
};

type AssetParentInfo = Lookup<"assetID", AssetParent>;

@Component({
   selector: "asset-parent-list",
   templateUrl: "./assetParentList.element.component.html",
   styleUrls: ["./assetParentList.element.component.scss"],
   imports: [LimbleHtmlDirective, LoadingAnimationComponent],
})
export class AssetParentList implements OnInit, OnDestroy, OnChanges {
   @Input({ transform: numberAttribute }) public assetID: number | undefined;
   @Input() public preventParentAccess: boolean | undefined;
   @Input() public linkColorClass: string | undefined;
   @Input() public restrict: boolean | undefined;
   @Input() public hover: boolean = true;

   public asset: Asset | undefined;
   public assetParentInfo: AssetParentInfo = new Lookup("assetID");
   public assetsWatchVarSub: Subscription | undefined | null;
   public areAssetsLoaded = signal(false);

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnChanges(changes: SimpleChanges): void {
      if (changes?.assetID?.currentValue !== changes?.assetID?.previousValue) {
         if (changes?.assetID?.currentValue === 0) {
            return;
         }
         this.getParentInfo();
      }
   }

   public ngOnInit() {
      this.assetsWatchVarSub = this.manageAsset.assetState().subscribe(() => {
         // If the assetID is 0 then it means there is no asset, so we don't need to do anything
         if (this.assetID === 0) {
            this.areAssetsLoaded.set(true);
            return;
         }
         this.getParentInfo();
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.assetsWatchVarSub);
   }

   private getParentInfo(): void {
      if (this.assetID === undefined) {
         console.error(
            "assetParentList component requires an `assetID` input binding, but a value was not provided.",
         );
         return;
      }

      this.asset = this.manageAsset.getAsset(this.assetID);
      if (this.asset === undefined) {
         console.error(
            "assetParentList component requires an `asset`, but no asset exists for the provided assetID.",
         );
         return;
      }

      assert(this.asset);
      this.assetParentInfo = this.manageAsset.buildParentInfo(this.asset.assetID);
      this.areAssetsLoaded.set(true);
   }
}
