<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (loading) {
         <div class="loading">
            <lim-ui-loading-animation />
         </div>
      }
      @if (!loading) {
         <lim-ui-panel>
            <div class="sensor-info-rows">
               <div class="sensor-info-row">
                  <div class="info-label">{{ lang().IdentificationNumber }}</div>
                  <div>
                     {{ sensor.SensorID }}
                  </div>
               </div>
               <div class="sensor-info-row">
                  <div class="info-label">{{ lang().Name }}</div>
                  <div>
                     {{ sensor.SensorName }}
                  </div>
               </div>
               <div class="sensor-info-row">
                  <div class="info-label">{{ lang().BatteryLevel }}</div>
                  <div>
                     {{ sensor.BatteryLevel }}
                  </div>
               </div>
               <div class="sensor-info-row">
                  <div class="info-label">{{ lang().LastReading }}</div>
                  <div>
                     {{ sensor.LastCommunicationDate }}
                  </div>
               </div>
               <div class="sensor-info-row">
                  <div class="info-label">{{ lang().ExpectedNextReading }}</div>
                  <div>
                     {{ sensor.NextCommunicationDate }}
                  </div>
               </div>
               <div class="sensor-info-row">
                  <div class="info-label">{{ lang().SignalStrength }}</div>
                  <div>
                     {{ sensor.SignalStrength }}
                  </div>
               </div>
               <div class="sensor-info-row">
                  <div class="info-label">
                     {{ lang().ConfigurationsForThisField }}
                  </div>
                  <ul>
                     <li>
                        <div>
                           {{ lang().UseTheReadingsFor }} {{ units[dataPoint] }} ({{
                              dataPoints[dataPoint]
                           }})
                        </div>
                     </li>
                     @if (config == 0) {
                        <li>
                           <div>
                              {{ lang().DisplayTheMostRecentSensorReading }}
                           </div>
                        </li>
                     }
                     @if (config == 1) {
                        @for (irule of incrementRules; track irule) {
                           <li>
                              <div>
                                 {{ incrementTypes[irule.operation] }} {{ irule.amount }}
                                 {{ lang().ToCurrentValueWhenSensorReadingIs }}
                                 {{ incrementDirections[irule.direction] }}
                                 {{ irule.threshold }}
                                 @if (irule.direction == 2) {
                                    <span> {{ lang().And }} {{ irule.threshold2 }} </span>
                                 }
                              </div>
                           </li>
                        }
                     }
                     @if (config == 2) {
                        <li>
                           <div>
                              {{ lang().KeepTrackOfHowLongReadingIs }}
                              {{ incrementDirections[runtimeRule.direction] }}
                              {{ runtimeRule.threshold }}
                              @if (runtimeRule.direction == 2) {
                                 <span>
                                    {{ lang().And }} {{ runtimeRule.threshold2 }}
                                 </span>
                              }
                           </div>
                        </li>
                     }
                     @if (config == 2) {
                        <li>
                           <div>
                              {{ lang().TrackTimeIn }}
                              {{ runtimeUnits[runtimeRule.units] }}
                           </div>
                        </li>
                     }
                  </ul>
               </div>
            </div>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   @if (!loading) {
      <lim-ui-modal-footer>
         <lim-ui-primary-button (click)="configure()">
            {{ lang().Reconfigure }}
         </lim-ui-primary-button>
         <lim-ui-danger-button
            (click)="detach()"
            placement="bottom"
            [limUiTooltip]="
               lang().RemoveTheSensorFromTheFieldThisDeletesTheConfigurations
            "
         >
            {{ lang().Detach }}
         </lim-ui-danger-button>
      </lim-ui-modal-footer>
   }
</lim-ui-modal>
