<ng-container *transloco="let t">
   <lim-ui-dropdown-button [label]="t(filter().labelKey) || ''" filterDropdown>
      <div menu>
         <lim-ui-search-box
            [placeholder]="t('Search')"
            [useDropdownStyling]="true"
            [showSearchButton]="true"
            (search)="handleSearch($event)"
         />
      </div>
   </lim-ui-dropdown-button>
</ng-container>
