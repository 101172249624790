<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (message) {
         <lim-ui-info-panel>
            <span class="message-area" [limbleHtml]="message"></span>
         </lim-ui-info-panel>
      }
      <lim-ui-panel [noSidePadding]="true">
         <div>
            <div class="col-xs-12 removePaddingCol-xs-12">
               <div class="listTable">
                  <div class="listItem listItemHeader">
                     <sort-column
                        class="col-md-4 mobileLargeDisappear"
                        column="checklistName"
                        name="{{ lang().TaskName }}"
                        [(bind)]="sortBind"
                     />
                     <sort-column
                        class="col-md-4 mobileLargeDisappear"
                        column="scheduleStartDate"
                        name="{{ lang().Due }}"
                        [(bind)]="sortBind"
                     />
                     <div class="col-md-4 mobileLargeDisappear">
                        {{ lang().Parts }}
                     </div>
                  </div>

                  @for (schedule of data | orderBy: sortBind; track schedule) {
                     <div class="listItem" [ngClass]="{ tranactionEveryOther: $even }">
                        <div class="col-md-4">
                           <lim-ui-icon
                              icon="squareCaretRightRegular"
                              iconSize="small"
                              class="icon-styles"
                           />
                           <a class="cursor" (click)="popTask(schedule)">{{
                              schedule.checklistName
                           }}</a>
                           @if (schedule.assetID) {
                              <span> - </span>
                              <a
                                 (click)="popAsset(schedule)"
                                 class="cursor green-color-link"
                              >
                                 {{ this.assets.get(schedule.assetID)?.assetName }}
                              </a>
                           }
                        </div>

                        <div class="col-md-4">
                           {{
                              schedule.scheduleStartDate &&
                                 schedule.scheduleStartDate * 1000 | betterDate: "date"
                           }}
                        </div>

                        <div class="col-md-4">
                           @for (
                              part of partsByScheduleID.get(schedule.scheduleID);
                              track part
                           ) {
                              <div>
                                 <b>{{ part.suggestedNumber }}</b> -
                                 {{ part.partName }} at
                                 <u
                                    >{{ currencySymbol
                                    }}{{ part.partPrice | betterNumber: "1.2-2" }}</u
                                 >
                                 each
                              </div>
                           }
                        </div>
                     </div>
                  }
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
