import type { FeatureFlagMap } from "src/app/shared/services/feature-flags/feature-flag-map.types";

export class FeatureFlagSet {
   public constructor(private readonly map: FeatureFlagMap) {}

   public has(key: keyof FeatureFlagMap): boolean {
      const flag = this.map[key];

      if (flag === undefined) {
         console.warn(`Feature flag "${key}" not found`);
         return false;
      }

      return flag === true;
   }
}
