// set up new component for purchase order list

import {
   Component,
   computed,
   inject,
   Input,
   type OnInit,
   type OnDestroy,
   input,
   signal,
} from "@angular/core";
import {
   LoadingBarService,
   ModalService,
   IconComponent,
   LimbleHtmlDirective,
   PaginationComponent,
   DropdownTextItemComponent,
   DropdownResultsPerPageComponent,
} from "@limblecmms/lim-ui";
import { ReplaySubject, type Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PoDeliveryDate } from "src/app/purchasing/pos/poDeliveryDateElement/poDeliveryDate.element.component";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderWorkflow } from "src/app/purchasing/types/purchase-order-workflow.types";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { SortColumn_refactor } from "src/app/shared/components/global/sortColumnModal/sortColumn_refactor.element.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { SliceLimbleMap } from "src/app/shared/pipes/sliceLimbleMap.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { SortColumnInfo } from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { getComparator } from "src/app/shared/utils/sortingHelpers";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

type PurchaseOrderForDisplay = PurchaseOrder & {
   purchaseOrderNumberForDisplay: string;
   vendorNameStr: string;
   assignedToName: string | undefined;
   dateDisplay: Date | undefined;
   deliveryDate: number | undefined;
   currentStatusName: string | undefined;
};

@Component({
   selector: "purchase-order-list",
   templateUrl: "./purchase-order-list.component.html",
   styleUrls: ["./purchase-order-list.component.scss"],
   imports: [
      SortColumn_refactor,
      SliceLimbleMap,
      IconComponent,
      BetterDatePipe,
      LimbleHtmlDirective,
      NoSearchResults,
      PoDeliveryDate,
      PaginationComponent,
      DropdownResultsPerPageComponent,
      DropdownTextItemComponent,
   ],
})
export class PurchaseOrderListComponent implements OnInit, OnDestroy {
   public readonly purchaseOrders = input<Lookup<"poID", PurchaseOrder>>(
      new Lookup("poID"),
   );
   @Input() public searchHints: LimbleMap<number, string> = new LimbleMap();
   @Input() public partID?: number | undefined;
   public readonly excludeVendorColumn = input<boolean | undefined>(undefined);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected page: number = 1;
   protected itemsPerPage: number = 10;
   protected readonly currencySymbol: string;

   protected readonly sortChanges: ReplaySubject<
      SortColumnInfo<PurchaseOrderForDisplay, "purchaseOrderForDisplay">
   > = new ReplaySubject();
   private readonly sortInfo = signal<
      SortColumnInfo<PurchaseOrderForDisplay, "purchaseOrderForDisplay"> | undefined
   >(undefined);

   private readonly sortChangesSub: Subscription;
   public sortColumnInfo: Record<
      "poNumber" | "vendor" | "orderDate" | "deliveryDate" | "status" | "assignedTo",
      SortColumnInfo<PurchaseOrderForDisplay, "purchaseOrderForDisplay">
   >;

   protected readonly purchaseOrdersForDisplay = computed(() => {
      const orders = new Lookup<"poID", PurchaseOrderForDisplay>("poID");

      // First create the display orders
      for (const purchaseOrder of this.purchaseOrders()) {
         const purchaseOrderForDisplay: PurchaseOrderForDisplay = {
            ...purchaseOrder,
            purchaseOrderNumberForDisplay:
               this.managePO.getPurchaseOrderNumberForDisplay(purchaseOrder.poID)
                  ?.poNumberForDisplay ?? "",
            vendorNameStr:
               purchaseOrder.vendorID === null
                  ? ""
                  : (this.manageVendor.getVendor(purchaseOrder.vendorID)?.vendorName ??
                    ""),
            assignedToName: this.getAssignedToUserName(purchaseOrder),
            currentStatusName:
               this.managePO.getPurchaseOrderCurrentState(purchaseOrder.poID)?.name ?? "",
            dateDisplay: purchaseOrder.date
               ? new Date(purchaseOrder.date * 1000)
               : undefined,
            deliveryDate: this.managePO.calculatePurchaseOrderDeliveryDate(
               purchaseOrder.poID,
            ),
         };
         orders.set(purchaseOrder.poID, purchaseOrderForDisplay);
      }

      // Then apply sorting if needed
      const currentSort = this.sortInfo();
      if (currentSort) {
         const comparator = getComparator(currentSort.sortDirection);
         return orders.orderBy(currentSort.sortProperty, comparator);
      }

      return orders;
   });

   protected readonly noSearchResults = computed(() => this.purchaseOrders().size === 0);

   private readonly managePO = inject(ManagePO);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly loadingBarService = inject(LoadingBarService);

   public constructor() {
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      this.sortColumnInfo = {
         poNumber: {
            columnName: this.lang().PONumber,
            sortProperty: "poNumber",
            locationOfProperty: "purchaseOrderForDisplay",
            sortDirection: "descending",
            sortChanges: this.sortChanges,
         },
         vendor: {
            columnName: this.lang().Vendor,
            sortProperty: "vendorNameStr",
            locationOfProperty: "purchaseOrderForDisplay",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         orderDate: {
            columnName: this.lang().OrderDate,
            sortProperty: "date",
            locationOfProperty: "purchaseOrderForDisplay",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         deliveryDate: {
            columnName: this.lang().DeliveryDate,
            sortProperty: "deliveryDate",
            locationOfProperty: "purchaseOrderForDisplay",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         status: {
            columnName: this.lang().Status,
            sortProperty: "currentStatusName",
            locationOfProperty: "purchaseOrderForDisplay",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         assignedTo: {
            columnName: this.lang().AssignedTo,
            sortProperty: "assignedToName",
            locationOfProperty: "purchaseOrderForDisplay",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
      };

      this.sortChangesSub = this.sortChanges.subscribe((sortOptions) => {
         this.sortPurchaseOrders(sortOptions);
      });
   }

   public ngOnInit() {
      this.itemsPerPage =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage || 10;
   }

   public ngOnDestroy() {
      this.sortChangesSub.unsubscribe();
   }

   protected updateUserUIPreferences(numberOfItems: string): void {
      this.itemsPerPage = Number(numberOfItems);
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage =
         this.itemsPerPage;
      this.manageUser.updateUserUIPreferences();
   }

   private getAssignedToUserName(purchaseOrder: PurchaseOrder): string {
      const assignedToUserName = this.managePO.getPurchasingAssignmentName({
         userID: purchaseOrder.userID,
         profileID: purchaseOrder.profileID,
      });
      return assignedToUserName ? assignedToUserName : "";
   }

   private sortPurchaseOrders(
      sortInfo: SortColumnInfo<PurchaseOrderForDisplay, "purchaseOrderForDisplay">,
   ): void {
      if (!sortInfo || sortInfo.locationOfProperty !== "purchaseOrderForDisplay") {
         return;
      }

      this.sortInfo.set({
         ...sortInfo,
         locationOfProperty: "purchaseOrderForDisplay",
      });
   }

   protected popPurchaseOrder(poID: number): void {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   }

   protected popVendor(vendorID: number | null): void {
      if (vendorID === null) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const vendor = this.manageVendor.getVendor(vendorID);
      if (!vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendorID,
            locationID: vendor.locationID,
         },
      };
   }

   protected async changePOAssignment(purchaseOrderID: number): Promise<void> {
      const purchaseOrder = this.managePO.getPurchaseOrder(purchaseOrderID);
      if (purchaseOrder === undefined) return;

      if (
         !this.credService.isAuthorized(
            purchaseOrder.locationID,
            this.credService.Permissions.ChangePOAssignment,
         )
      ) {
         this.alertService.addAlert(this.lang().cred148Fail, "danger", 10000);
         return;
      }

      if (!(await this.isPurchaseOrderEditable(purchaseOrderID))) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }

      const extraUsersOptions = this.setExtraUsersOptions();

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: "",
               title: this.lang().ChangePurchaseOrderAssignment,
               locationID: purchaseOrder.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: purchaseOrder.userID,
               defaultProfile: purchaseOrder.profileID,
            },
         },
      };

      const result = await instance.result;

      if (result === 0) return;

      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });

      const answer = await this.managePO.changePurchaseOrderAssignment(
         result.userID,
         result.profileID,
         purchaseOrder.poID,
         result.multiUsers,
         true,
      );

      this.loadingBarService.remove();

      if (answer !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
         return;
      }

      this.alertService.addAlert(this.lang().successMsg, "success", 2000);
   }

   private setExtraUsersOptions() {
      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      return extraUsersOptions;
   }

   private async isPurchaseOrderEditable(purchaseOrderID: number): Promise<boolean> {
      const purchaseOrderWorkflows = await this.getWorkflows(purchaseOrderID);
      const editableInfo = this.managePO.getPurchaseOrderEditable(
         purchaseOrderID,
         this.manageUser,
         this.credService,
         purchaseOrderWorkflows,
      );

      return editableInfo?.editable ?? false;
   }

   private async getWorkflows(
      purchaseOrderID: number,
   ): Promise<Array<PurchaseOrderWorkflow>> {
      const answer = await this.managePO.getPurchaseOrderWorkflows(purchaseOrderID);
      if (!answer.data.poWorkflows) {
         return [];
      }
      return answer.data.poWorkflows;
   }
}
