<lim-ui-modal>
   <lim-ui-basic-modal-header title="{{ modalTitle }}" (closeModal)="close()" />
   <lim-ui-modal-body [scrollable]="true">
      <div class="panel-content scroll-height-inheritance">
         @if (columns && objs) {
            <view-list-of-any-obj
               class="scroll-height-inheritance"
               [objs]="objs"
               [columns]="columns"
               [options]="options"
            />
         }
         @if (this.message) {
            <div class="message">
               <span [limbleHtml]="message"></span>
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  placement="right"
                  limUiTooltip="{{ this.messageTooltip }}"
               />
            </div>
         }
      </div>
   </lim-ui-modal-body>
</lim-ui-modal>
