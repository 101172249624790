<p-button
   [label]="label()"
   [link]="link()"
   [disabled]="disabled()"
   [severity]="type()"
   [size]="buttonSize()"
   [tabindex]="tabindex()"
   [autofocus]="autofocus()"
   [outlined]="outlined()"
   [ariaLabel]="ariaLabel()"
   [style]="buttonStyle()"
   (onClick)="handleClick($event)"
   (onFocus)="handleFocus($event)"
   (onBlur)="handleBlur($event)"
>
   @if (iconName() && iconName() !== null) {
      <ng-template #icon>
         <lim-ui-icon
            [icon]="iconName()!"
            [iconColor]="iconColor()"
            [iconSize]="iconSize()"
            [class]="iconPositionClass()"
         />
      </ng-template>
   }
</p-button>
