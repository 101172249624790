import { inject, computed, Injectable } from "@angular/core";
import axios, { type AxiosResponse } from "axios/dist/axios";
import $ from "jquery";
import { Subject } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import type { UploadObj } from "src/app/shared/types/general.types";
import { cleanWordPaste, resolvePromisesMap } from "src/app/shared/utils/app.util";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services//manageUser";
import type { VendorField } from "src/app/vendors/types/field/field.types";
import type { VendorFieldType } from "src/app/vendors/types/field/type/vendor-field-type.types";
import type { VendorFieldValueFile } from "src/app/vendors/types/field/value/file/file.types";
import type { VendorFieldValue } from "src/app/vendors/types/field/value/vendor-field-value.types";
import type { Vendor } from "src/app/vendors/types/vendor.types";
import { environment } from "src/environments/environment";
import reservedNames from "src/root/phpscripts/reservedNames.json";

@Injectable({ providedIn: "root" })
export class ManageVendor {
   private vendorsLoaded = 0;
   private vendorsWatchVar = 0;
   private readonly axios = axios;
   private vendors: Lookup<"vendorID", Vendor> = new Lookup("vendorID");
   private fields: Lookup<"fieldID", VendorField> = new Lookup("fieldID");
   private fieldTypes: Lookup<"fieldTypeID", VendorFieldType> = new Lookup("fieldTypeID");
   private fieldValues: Lookup<"valueID", VendorFieldValue> = new Lookup("valueID");
   private fieldValueFiles: Lookup<"fileID", VendorFieldValueFile> = new Lookup("fileID");
   public tileFieldsObs$ = new Subject<null>();
   public dateReminderStreamMap: Map<number, Subject<null>> = new Map();
   private readonly manageLang = inject(ManageLang);
   private readonly manageUtil = inject(ManageUtil);
   private readonly alertService = inject(AlertService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUser = inject(ManageUser);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      //this sets the data for the manageVendor page;
      this.createObservables();
   }

   createObservables = () => {
      this.manageObservables.createObservable("vendorsWatchVar", this.vendorsWatchVar);
      this.manageObservables.createObservable("vendorFields");
      this.manageObservables.createObservable("vendorsLoaded", this.vendorsLoaded);
   };

   //this is a watch variable used so that large vendor objs don't have to be watched deeply.  This is done with tasks and completed tasks in manageTask as well.
   incrementVendorsWatchVar = () => {
      this.vendorsWatchVar++;
      this.manageObservables.updateObservable("vendorsWatchVar", this.vendorsWatchVar);
   };

   updateVendorFieldsObservable = () => {
      this.manageObservables.updateObservable("vendorFields", 1);
   };

   public setUploadObject(fieldTypeID: number, valueID: number, vendor: Vendor) {
      const uploadCall = async (posturl, formdata) => {
         return this.axios({
            method: "POST",
            url: posturl,
            data: formdata,
         });
      };

      const uploadComplete = this.uploadComplete.bind(this);

      const uploadObj: UploadObj = {
         posturl: `phpscripts/manageVendor.php?action=makeFile&locationID=${vendor.locationID}&vendorID=${vendor.vendorID}&valueID=${valueID}`,
         primaryID: "fileName",
         uploadTypes: fieldTypeID === 3 ? "images" : "documents",
         uploadCall,
         uploadComplete,
      };
      return uploadObj;
   }

   public uploadComplete(data) {
      if (data.failed) {
         $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
      } else {
         $("#status").html(
            `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
         );
      }

      if (!data.fileID) {
         $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         return;
      }
      this.fieldValueFiles.set(data.fileID, data.row);
      const value = this.fieldValues.get(data.row.valueID);

      if (!value) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      value.vendorValueFileIDs.push(data.fileID);
      this.updateVendorFieldsObservable();
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   getData = async () => {
      await Promise.all([
         this.fetchVendors(),
         this.fetchFields(),
         this.fetchFieldValues(),
         this.fetchFieldValueFiles(),
         this.fetchFieldTypes(),
      ]);
      this.incrementVendorsWatchVar();
   };

   private async fetchVendors() {
      const useMiniFlannel = await this.launchFlagsService.isEnabled(
         Flags.MINI_FLANNEL_VENDORS,
      );

      let vendors;
      if (useMiniFlannel) {
         const response = await fetch(`${environment.servicesURL()}/vendors`, {
            mode: "cors",
            credentials: "include",
            method: "GET",
         });
         vendors = await response.json();
      } else {
         const startTime = Math.floor(Date.now());
         const response = await axios.get(`${environment.flannelUrl}/vendors`);
         logApiPerformance(
            "vendors",
            startTime,
            this.manageUser.getCurrentUser(),
            response,
         );
         vendors = response.data;
      }

      this.vendors = new Lookup("vendorID", vendors);
   }

   private async fetchFields() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/vendors/fields`);
      logApiPerformance(
         "vendorsFields",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );

      const hasFieldLockFlag = await this.launchFlagsService.isEnabled(
         Flags.PART_VENDOR_FIELD_LOCK,
      );
      for (const field of response.data) {
         if (field.fieldTypeID === 2) {
            this.dateReminderStreamMap.set(field.fieldID, new Subject());
         }

         if (!hasFieldLockFlag) {
            field.lockedDefault = 0;
         }
      }

      this.fields = new Lookup("fieldID", response.data);
   }

   private async fetchFieldValues() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/vendors/fields/values`);

      logApiPerformance(
         "vendorsFieldsValues",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.fieldValues = new Lookup("valueID", response.data);
   }

   private async fetchFieldValueFiles() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(
         `${environment.flannelUrl}/vendors/fields/values/files`,
      );

      logApiPerformance(
         "vendorsFieldsValuesFiles",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.fieldValueFiles = new Lookup("fileID", response.data);
   }

   private async fetchFieldTypes() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/vendors/fields/types`);

      logApiPerformance(
         "vendorsFieldsTypes",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.fieldTypes = new Lookup("fieldTypeID", response.data);
   }

   getWatchVar = () => {
      return this.vendorsWatchVar;
   };

   getVendorsLoaded = () => {
      return this.vendorsLoaded;
   };

   incrementVendorsLoaded = () => {
      this.vendorsLoaded++;
      this.manageObservables.updateObservable("vendorsLoaded", this.vendorsLoaded);
   };

   public async addExistingField(vendor: Vendor, fieldID: number) {
      const vendorID = vendor.vendorID;

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "addExistingField",
         },
         data: {
            locationID: vendor.locationID,
            vendorID: vendorID,
            fieldID: fieldID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const newValue = post.data.row;
      newValue.vendorValueFileIDs = [];
      this.fieldValues.set(newValue.valueID, newValue);
      vendor.vendorValueIDs.push(newValue.valueID);

      this.updateVendorFieldsObservable();
      this.manageObservables.updateObservable(`vendorFields${vendor.vendorID}`, 1);

      return post;
   }

   private getBannedFieldNames() {
      return reservedNames.Vendors;
   }

   public usingForbiddenFieldNames(name: string): boolean {
      return this.getBannedFieldNames().some(
         (fieldName) => fieldName.toLowerCase() === name.toLowerCase(),
      );
   }

   public async addNewField(
      vendor: Vendor,
      fieldTypeID: number,
      name: string,
      options: string,
   ) {
      const nameClean = cleanWordPaste(name);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "addNewField",
         },
         data: {
            locationID: vendor.locationID,
            vendorID: vendor.vendorID,
            fieldTypeID: fieldTypeID,
            options: options,
            name: nameClean,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const newField = post.data.field;
      this.fields.set(newField.fieldID, newField);

      if (newField.fieldTypeID === 2) {
         this.dateReminderStreamMap.set(newField.fieldID, new Subject());
      }

      //adds the new value to the vendor's list.
      const newValue = post.data.row;
      newValue.vendorValueFileIDs = [];
      this.fieldValues.set(newValue.valueID, newValue);

      //adds the field into the correct vendor
      vendor.vendorValueIDs.push(newValue.valueID);

      //add fieldTypeID info to the return object
      post.data.row.fieldTypeID = fieldTypeID;

      this.updateVendorFieldsObservable();
      this.manageObservables.updateObservable(`vendorFields${vendor.vendorID}`, 1);

      return post;
   }

   public async addField(name: string, fieldTypeID: number, locationID: number) {
      const nameClean = cleanWordPaste(name);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "addField",
         },
         data: {
            name: nameClean,
            fieldTypeID: fieldTypeID,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const newField = post.data.field;
      this.fields.set(newField.fieldID, newField);

      this.updateVendorFieldsObservable();

      return post;
   }

   setFieldValue = async (value: VendorFieldValue, vendor: Vendor) => {
      let valueContent = value.valueContent;
      if (typeof valueContent === "string") {
         valueContent = cleanWordPaste(valueContent);
      }

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "setFieldValue",
         },
         data: {
            locationID: vendor.locationID,
            valueID: value.valueID,
            valueContent: valueContent,
         },
      });

      return post;
   };

   public async removeField(fieldToRemove: VendorFieldValue, vendor: Vendor) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "removeField",
         },
         data: {
            locationID: vendor.locationID,
            valueID: fieldToRemove.valueID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      this.fieldValues.delete(fieldToRemove.valueID);
      const index = vendor.vendorValueIDs.indexOf(fieldToRemove.valueID);
      vendor.vendorValueIDs.splice(index, 1);

      const valuesOnField: Array<VendorFieldValue> = [];
      for (const valueID of vendor.vendorValueIDs) {
         const value = this.getValue(valueID);
         if (value) {
            valuesOnField.push(value);
         }
      }

      for (const value of valuesOnField) {
         if (
            value.valueSort &&
            fieldToRemove.valueSort &&
            value.valueSort > fieldToRemove.valueSort
         ) {
            fieldToRemove.valueSort = value.valueSort - 1;
         }
      }

      this.updateVendorFieldsObservable();
      this.manageObservables.updateObservable(`vendorFields${vendor.vendorID}`, 1);

      return post;
   }

   public async deleteFile(fileID: number, value: VendorFieldValue, locationID: number) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "deleteDocument",
         },
         data: {
            fileID: fileID,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const index = value.vendorValueFileIDs.indexOf(fileID);
      value.vendorValueFileIDs.splice(index, 1);
      this.fieldValueFiles.delete(fileID);

      return post;
   }

   public async updateSorts(updates: Array<any>, locationID: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateSorts",
         },
         data: {
            updates: JSON.stringify(updates),
            locationID: locationID,
         },
      });

      return post;
   }

   public async updateVendorName(vendor: Vendor) {
      if (!vendor.vendorName) {
         vendor.vendorName = "";
      }

      vendor.vendorName = cleanWordPaste(vendor.vendorName);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateVendorName",
         },
         data: {
            vendorID: vendor.vendorID,
            vendorName: vendor.vendorName,
            locationID: vendor.locationID,
         },
      });

      return post;
   }

   public async updateVendorEmail(vendor: Vendor) {
      if (!vendor.vendorEmail) {
         vendor.vendorEmail = "";
      }
      vendor.vendorEmail = cleanWordPaste(vendor.vendorEmail);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateVendorEmail",
         },
         data: {
            vendorID: vendor.vendorID,
            vendorEmail: vendor.vendorEmail,
         },
      });

      return post;
   }

   public async updateVendorPhone(vendor: Vendor) {
      if (!vendor.vendorPhone) {
         vendor.vendorPhone = "";
      }
      vendor.vendorPhone = cleanWordPaste(vendor.vendorPhone);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateVendorPhone",
         },
         data: {
            vendorID: vendor.vendorID,
            vendorPhone: vendor.vendorPhone,
         },
      });

      return post;
   }

   public async updateVendorContact(vendor: Vendor) {
      if (!vendor.vendorContact) {
         vendor.vendorContact = "";
      }
      vendor.vendorContact = cleanWordPaste(vendor.vendorContact);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateVendorContact",
         },
         data: {
            vendorID: vendor.vendorID,
            vendorContact: vendor.vendorContact,
         },
      });

      return post;
   }

   public async updateVendorAddress(vendor: Vendor) {
      if (!vendor.vendorAddress) {
         vendor.vendorAddress = "";
      }
      vendor.vendorAddress = cleanWordPaste(vendor.vendorAddress);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateVendorAddress",
         },
         data: {
            vendorID: vendor.vendorID,
            vendorAddress: vendor.vendorAddress,
         },
      });

      return post;
   }

   //pass locationID for cred purposes, pass groupID > 0 if they are joining a group, pass type 1 means a normal vendor should be made and 2 means a group leader vendor should be made
   public async addVendor(locationID, type, newName) {
      const newNameClean = cleanWordPaste(newName);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "addVendor",
         },
         data: {
            locationID: locationID,
            type: type,
            newName: newNameClean,
         },
      });

      const vendor: Vendor = {
         ...post.data.vendor,
         vendorValueIDs: [],
         vendorAssetIDs: [],
         vendorPartIDs: [],
         vendorPoIDs: [],
      };

      for (const fieldValue of post.data.customDefaultFields) {
         //Have to process all of the fields that were automatically added
         vendor.vendorValueIDs.push(fieldValue.valueID);
         fieldValue.vendorValueFileIDs = [];
         this.fieldValues.set(fieldValue.valueID, fieldValue);
      }

      this.vendors.set(vendor.vendorID, vendor);

      this.incrementVendorsWatchVar();

      return post;
   }

   public async copyVendor(sourceVendorID, locationIDTarget, newName) {
      const newNameClean = cleanWordPaste(newName);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "copyVendor",
         },
         data: {
            sourceVendorID: sourceVendorID,
            locationIDTarget: locationIDTarget,
            newName: newNameClean,
         },
      });

      await this.fetchSingleVendorAndAssociations(post.data.vendor.vendorID);

      this.incrementVendorsWatchVar();

      return post;
   }

   public async deleteVendor(vendor: Vendor) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "deleteVendor",
         },
         data: {
            locationID: vendor.locationID,
            vendorID: vendor.vendorID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            vendor.vendorDeleted = 1;
            this.incrementVendorsWatchVar();
         }
      });
      return post;
   }

   public async updateSuggestedFieldName(
      field: VendorField,
      locationID: number,
      fieldName: string,
   ) {
      const newFieldName = cleanWordPaste(fieldName);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateSuggestedFieldName",
         },
         data: {
            locationID: locationID,
            fieldID: field.fieldID,
            fieldName: newFieldName,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      //have to update the fields in the other vendors to make sure this corresponds properly
      field.fieldName = fieldName;
      this.incrementVendorsWatchVar();
      this.updateVendorFieldsObservable();

      return post;
   }

   public async updateDropdownOptions(field: VendorField) {
      assert(field.optionsJSON);
      field.optionsJSON = cleanWordPaste(field.optionsJSON);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateDropdownOptions",
         },
         data: {
            fieldID: field.fieldID,
            optionsJSON: field.optionsJSON,
         },
      });
      post.then((answer) => {
         if (answer.data.success == true) {
            const fieldToUpdate = this.getField(field.fieldID);
            assert(fieldToUpdate);
            fieldToUpdate.optionsJSON = field.optionsJSON;
            this.manageObservables.updateObservable(
               `vendorFieldOptionsJSON${field.fieldID}`,
               1,
            );
            this.updateVendorFieldsObservable();
         }
      });

      return post;
   }

   public async updateDateReminder(field: string, value: number, fieldID: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateDateReminder",
         },
         data: {
            fieldToUpdate: field,
            value: value,
            fieldID: fieldID,
         },
      });

      const currentField = this.fields.get(fieldID);
      if (currentField) {
         currentField[field] = value;
      }

      this.dateReminderStreamMap.get(fieldID)?.next(null);

      return post;
   }

   public async updateDateReminderAssignments(
      fieldID: number,
      userID: number,
      profileID: number,
      multiUsers: Array<number>,
      locationID: number,
   ) {
      const multiUsersStringifed = JSON.stringify(multiUsers);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateDateReminderAssignments",
         },
         data: {
            fieldID: fieldID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringifed,
            locationID: locationID,
         },
      });
      return post;
   }

   public async removeSuggestedField(field: VendorField) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "removeSuggestedField",
         },
         data: {
            locationID: field.locationID,
            fieldID: field.fieldID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      //remove this field from the list of possible fields.
      this.fields.delete(field.fieldID);
      this.dateReminderStreamMap.delete(field.fieldID);

      const valueIDsWithFieldID: Array<number> = [];
      const vendorIDsThatHaveThisValue: Set<number> = new Set();
      for (const [valueID, value] of this.fieldValues.entries()) {
         if (value.fieldID !== field.fieldID) {
            continue;
         }
         vendorIDsThatHaveThisValue.add(value.vendorID);
         valueIDsWithFieldID.push(valueID);
         this.fieldValues.delete(valueID);
      }

      for (const vendorID of vendorIDsThatHaveThisValue) {
         const vendor = this.vendors.get(vendorID);
         assert(vendor);
         const index = vendor.vendorValueIDs.findIndex((valueID) =>
            valueIDsWithFieldID.includes(valueID),
         );
         vendor.vendorValueIDs.splice(index, 1);
      }

      this.updateVendorFieldsObservable();

      return post;
   }

   public async changeVendorLocation(vendor: Vendor, locationID: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "changeVendorLocation",
         },
         data: {
            vendorID: vendor.vendorID,
            locationID: locationID,
            checkParent: 1,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.incrementVendorsWatchVar();
         }
      });

      return post;
   }

   public async updateFieldViewableByTech(field: VendorFieldValue, locationID: number) {
      let newValue;
      if (Number(field.viewableByTech) === 1) {
         newValue = 0;
      } else {
         newValue = 1;
      }

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateFieldViewableByTech",
         },
         data: {
            locationID: locationID,
            valueID: field.valueID,
            newValue: newValue,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            field.viewableByTech = newValue;
         }
      });

      return post;
   }

   public async updateFieldViewableByTechFieldDefault(field: VendorField) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateFieldViewableByTechFieldDefault",
         },
         data: {
            locationID: field.locationID,
            fieldID: field.fieldID,
            newValue: field.viewableByTechFieldDefault,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      for (const value of this.fieldValues) {
         if (Number(value.fieldID) === Number(field.fieldID)) {
            value.viewableByTech = field.viewableByTechFieldDefault;
         }
      }

      return post;
   }

   public async updateVendorCreatedDate(newTime: number, vendorID: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateVendorCreatedDate",
         },
         data: {
            newTime,
            vendorID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.incrementVendorsWatchVar();
         }
      });

      return post;
   }

   public async deleteVendorsInBulk(vendorsIn: Array<number>) {
      const vendorsStringified = JSON.stringify(vendorsIn);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "deleteVendorsInBulk",
         },
         data: {
            vendors: vendorsStringified,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      for (const vendorID of vendorsIn) {
         const vendorFromState = this.vendors.get(vendorID);
         if (!vendorFromState) {
            continue;
         }
         vendorFromState.vendorDeleted = 1;
      }
      this.incrementVendorsWatchVar();
      return post;
   }

   importVendors = async (vendorsIn, fields, locationID) => {
      const vendorsStringified = JSON.stringify(vendorsIn);
      const fieldsStringified = JSON.stringify(fields);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "importVendors",
         },
         data: {
            vendors: vendorsStringified,
            fields: fieldsStringified,
            locationID: locationID,
         },
      });

      return post;
   };

   public downloadVendors(vendors: Lookup<"vendorID", Vendor>) {
      const vendorsToDownload: any = [];
      let locationID;

      const arrayOfVendors: Array<Vendor> = Array.from(vendors);

      for (const [index, vendor] of arrayOfVendors.entries()) {
         locationID = vendor.locationID;

         const obj = {
            "Vendor Name": vendor.vendorName,
            "Vendor ID": vendor.vendorID,
            "Vendor Email": vendor.vendorEmail,
            "Phone": vendor.vendorPhone,
            "Contact": vendor.vendorContact,
            "Address": vendor.vendorAddress,
         };

         for (const valueID of vendor.vendorValueIDs) {
            const value = this.fieldValues.get(valueID);
            if (!value) {
               continue;
            }
            const field = this.fields.get(value.fieldID);
            if (!field) {
               continue;
            }

            //sets the appropriate field values

            if (Number(field.fieldTypeID) === 5 || Number(field.fieldTypeID) === 6) {
               if (
                  typeof value.valueContent === "string" ||
                  typeof value.valueContent === "number"
               ) {
                  //valid so let's set them as their
                  // field.valueContent = field.valueContent;
               } else {
                  value.valueContent = "";
               }
            }

            if (Number(field.fieldTypeID) === 2 && value.valueContent) {
               const date: any = new Date(value.valueContent);
               let str;

               if (date === "Invalid Date") {
                  str = "";
               } else {
                  str = this.betterDate.formatBetterDate(date, "date");
               }

               value.valueContent = str;
            }

            obj[field.fieldName] = value.valueContent;

            if (Number(field.fieldTypeID) === 3 || Number(field.fieldTypeID) === 4) {
               obj[field.fieldName] = "";
               for (const fileID of value.vendorValueFileIDs) {
                  const file = this.fieldValueFiles.get(fileID);
                  if (!file) {
                     continue;
                  }
                  obj[field.fieldName] += `${file.fileName}, `;
               }
               if (obj[field.fieldName].length > 2) {
                  obj[field.fieldName] = obj[field.fieldName].substring(
                     0,
                     obj[field.fieldName].length - 2,
                  );
               }
            }

            //the first row must ALWAYS have whatever fieldName or else the export won't work appropriately
            if (index > 0 && vendorsToDownload[0][field.fieldName] === undefined) {
               vendorsToDownload[0][field.fieldName] = "";
            }
         }
         vendorsToDownload.push(obj);
      }

      if (vendorsToDownload[1]) {
         //doing a little check here to make sure all fields are included on a download.  Previously it only shows fields if a vendor has that value somewhere
         for (const field of this.fields) {
            if (Number(field.locationID) === Number(locationID)) {
               if (vendorsToDownload[1][field.fieldName] === undefined) {
                  vendorsToDownload[1][field.fieldName] = "";
               }
            }
         }
      }

      const today = this.betterDate.createTodayTimestamp();

      this.manageUtil.objToExcel(vendorsToDownload, "Vendors", `Vendors-${today}.xlsx`);
   }

   /**
    * Updates the `isCustomDefault` property of a "vendor field" object and saves
    * to the database.
    * @param fieldID - The ID of the vendor field object to update
    * @param isCustomDefault - The new value for the `isCustomDefault` property
    * @returns true if successful or false if failed.
    */
   public async updateFieldIsCustomDefault(
      fieldID: number,
      isCustomDefault: boolean | 0 | 1,
   ): Promise<boolean> {
      const newValue = isCustomDefault ? 1 : 0;

      return axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateFieldIsCustomDefault",
         },
         data: {
            fieldID: fieldID,
            isCustomDefault: newValue,
         },
      })
         .then((answer) => {
            if (answer.data.success === false) {
               return false;
            }
            const field = this.getFields().get(fieldID);
            assert(field);
            field.isCustomDefault = newValue;
            return true;
         })
         .catch(() => {
            return false;
         });
   }

   public async updateValueUnique(fieldID: number, value: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "updateValueUnique",
         },
         data: {
            fieldID: fieldID,
            value: value,
         },
      });

      return post;
   }

   /****************************************
    *@function checkVendorNameUnique
    *@purpose
    *@name checkVendorNameUnique
    *@param
    *@return
    ****************************************/
   checkVendorNameUnique = async (vendorName, locationID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "checkVendorNameUnique",
         },
         data: {
            vendorName: vendorName,
            locationID: locationID,
         },
      });

      return post;
   };

   public async fetchSingleVendorAndAssociations(vendorID: number) {
      const singleVendorPromises = new Map([
         [
            "vendors",
            axios.get(`${environment.flannelUrl}/vendors`, {
               params: { vendorIDs: vendorID },
            }),
         ],
         [
            "fields",
            axios.get(`${environment.flannelUrl}/vendors/fields`, {
               params: { vendorIDs: vendorID },
            }),
         ],
         [
            "values",
            axios.get(`${environment.flannelUrl}/vendors/fields/values`, {
               params: { vendorIDs: vendorID },
            }),
         ],
         [
            "files",
            axios.get(`${environment.flannelUrl}/vendors/fields/values/files`, {
               params: { vendorIDs: vendorID },
            }),
         ],
      ]);
      const responses = await resolvePromisesMap(singleVendorPromises);
      const entries = [...responses.entries()].map((entry) => {
         return [entry[0], entry[1].data];
      });

      const { vendors, fields, values, files } = Object.fromEntries(entries);

      this.vendors.set(vendors[0].vendorID, vendors[0]);

      for (const field of fields) {
         this.fields.set(field.fieldID, field);
      }

      for (const value of values) {
         this.fieldValues.set(value.valueID, value);
      }

      for (const file of files) {
         this.fieldValueFiles.set(file.fileID, file);
      }
   }

   public getVendors(): Lookup<"vendorID", Vendor> {
      return this.vendors;
   }

   public getFields() {
      return this.fields;
   }

   public getFieldTypes() {
      return this.fieldTypes;
   }

   public getValues() {
      return this.fieldValues;
   }

   public getFiles() {
      return this.fieldValueFiles;
   }

   public getVendor(vendorID: number): Vendor | undefined {
      return this.vendors.get(vendorID);
   }

   public getField(fieldID: number): VendorField | undefined {
      return this.fields.get(fieldID);
   }

   public getValue(valueID: number): VendorFieldValue | undefined {
      return this.fieldValues.get(valueID);
   }

   public async saveFieldLockedDefault(field: VendorField): Promise<AxiosResponse> {
      const userID = this.manageUser.getCurrentUser().userInfo.userID;

      const post = axios({
         method: "POST",
         url: "phpscripts/manageVendor.php",
         params: {
            action: "saveFieldLockedDefault",
         },
         data: {
            fieldLocked: field.lockedDefault,
            fieldID: field.fieldID,
            userID,
         },
      });

      return post;
   }
}
