import { ChangeDetectionStrategy, Component, inject, input, output } from "@angular/core";
import { PageSizeSelectComponent } from "@empowered/gears/page-size-select/page-size-select.component";
import { TranslateService } from "src/app/languages";
import { PaginatorComponent } from "src/app/shared/empowered/base/paginator/paginator.component";

@Component({
   selector: "e-data-viewer-paginator",
   imports: [PaginatorComponent, PageSizeSelectComponent],
   templateUrl: "./data-viewer-paginator.component.html",
   styleUrls: ["./data-viewer-paginator.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataViewerPaginatorComponent {
   private readonly t = inject(TranslateService);

   /** The total number of items */
   public totalItems = input.required<number>();

   /** Whether to show the results per page dropdown */
   public showPageSizeSelect = input<boolean>(true);

   /** The current page number */
   public page = input<number>(0);

   /**
    * Emits the page number change event
    * It is not the zero-based index of the page, but the page number.
    */
   readonly changePageNumber = output<number>();

   /** Emits the page size change event */
   readonly changePageSize = output<number>();

   protected get currentPageReportTemplate() {
      return `{first} - {last} ${this.t.instant("of")} {totalRecords}`;
   }

   public onPageSizeChange(pageSize: number) {
      this.changePageSize.emit(pageSize);
   }

   public onPageChange(pageNumber: number) {
      this.changePageNumber.emit(pageNumber);
   }
}
