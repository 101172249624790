import { inject, Injectable } from "@angular/core";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import type { PartPurchaseOrderItemDto } from "src/app/purchasing/pos/part-purchase-order-item-dto/part-purchase-order-item.dto";
import { InvalidPartPurchaseOrderItemPurchasableError } from "src/app/purchasing/pos/purchasable-snapshot/errors/invalid-part-purchase-order-item-purchasable.error";
import { CountedPurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/parsers/counted/counted-purchasable-snapshot-parser";
import { CountedAndMeasuredPurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/parsers/counted-and-measured/counted-and-measured-snapshot-parser";
import { IndividualPurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/parsers/individual/individual-purchasable-snapshot-parser";
import { MeasuredPurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/parsers/measured/measured-purchasable-snapshot-parser";
import { UnitDisplayParserService } from "src/app/purchasing/pos/purchasable-snapshot/parsers/util/unit-display-parser.service";
import type { PurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot-parser.interface";

@Injectable({ providedIn: "root" })
export class PurchasableSnapshotParserFactoryService {
   private readonly unitDisplayParserService = inject(UnitDisplayParserService);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly manageParts = inject(ManageParts);

   public createParser(item: PartPurchaseOrderItemDto): PurchasableSnapshotParser {
      if (CountedAndMeasuredPurchasableSnapshotParser.isValid(item)) {
         return new CountedAndMeasuredPurchasableSnapshotParser(
            item,
            this.unitDisplayParserService,
            this.unitOfMeasureService,
         );
      }

      if (CountedPurchasableSnapshotParser.isValid(item)) {
         return new CountedPurchasableSnapshotParser(item, this.unitDisplayParserService);
      }

      if (MeasuredPurchasableSnapshotParser.isValid(item)) {
         return new MeasuredPurchasableSnapshotParser(
            item,
            this.unitDisplayParserService,
            this.unitOfMeasureService,
         );
      }

      if (IndividualPurchasableSnapshotParser.isValid(item)) {
         return new IndividualPurchasableSnapshotParser(
            item,
            this.unitDisplayParserService,
            this.unitOfMeasureService,
            this.manageParts,
         );
      }

      throw new InvalidPartPurchaseOrderItemPurchasableError(item);
   }
}
