import { InjectionToken, type Provider, type Resource } from "@angular/core";
import type { Lookup } from "src/app/shared/utils/lookup";
import type { TaskAsset } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { PmSuggestionsAssetsFromManageAssetsService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/pm-suggestions-assets-from-manage-assets.service";
import { PmSuggestionsAssetsFromMemoryService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/pm-suggestions-assets-from-memory.service";

export type PmSuggestionsAssetsInterface = {
   assets: Resource<Lookup<"assetID", TaskAsset>>;
};

export const PmSuggestionsAssets = new InjectionToken<PmSuggestionsAssetsInterface>(
   "PmSuggestionsAssets",
);

export const providePmSuggestionsAssetsFromManageAssets = (): Provider => {
   return {
      provide: PmSuggestionsAssets,
      useClass: PmSuggestionsAssetsFromManageAssetsService,
   };
};

export const providePmSuggestionsAssetsFromMemory = (): Provider => {
   return {
      provide: PmSuggestionsAssets,
      useClass: PmSuggestionsAssetsFromMemoryService,
   };
};
