<ng-container *translate="let t">
   <span class="filter-inputs-container">
      <div>{{ t("Between") }}</div>

      <div>
         <lim-ui-date-picker-input
            class="filter-input-box"
            [dateBoundaries]="
               filterDateEnd() ? { latestDate: filterDateEnd()! } : undefined
            "
            [ngModel]="filterDateStart()"
            (ngModelChange)="onFilterDateStartChanged($event)"
         />
      </div>

      <div>{{ t("and") }}</div>

      <div>
         <lim-ui-date-picker-input
            class="filter-input-box"
            [dateBoundaries]="
               filterDateStart() ? { earliestDate: filterDateStart()! } : undefined
            "
            [ngModel]="filterDateEnd()"
            (ngModelChange)="onFilterDateEndChanged($event)"
         />
      </div>
   </span>
</ng-container>
