import type { AfterViewInit, ElementRef, OnInit } from "@angular/core";
import { Component, computed, inject, Input, ViewChild } from "@angular/core";
import { CardComponent, IconComponent } from "@limblecmms/lim-ui";
import {
   Chart,
   type ChartData,
   type ChartOptions,
   Filler,
   LinearScale,
   LineController,
   LineElement,
   PointElement,
   TimeScale,
} from "chart.js";
import "chartjs-adapter-moment";
import moment from "moment";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageDepreciationSchedule } from "src/app/assets/services/manageDepreciationSchedule";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { ChartJSHelper } from "src/app/dashboards/widgets/chartJSHelper";
import { ManageLang } from "src/app/languages/services/manageLang";
import { DEFAULT_CURRENCY } from "src/app/purchasing/currency/utils/default-currency-code";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { ManageUser } from "src/app/users/services/manageUser";

Chart.register(Filler, LineController, LineElement, LinearScale, TimeScale, PointElement);

@Component({
   selector: "depreciation-line-chart-card",
   templateUrl: "./depreciationLineChartCard.element.component.html",
   styleUrls: ["./depreciationLineChartCard.element.component.scss"],
   imports: [CardComponent, IconComponent, LocaleCurrencyPipe],
})
export class DepreciationLineChartCard implements OnInit, AfterViewInit {
   @ViewChild("chart") lineChartRef?: ElementRef<HTMLCanvasElement>;
   @Input() public schedule;
   @Input() public currencyCode: string = DEFAULT_CURRENCY;
   public scheduleFieldValue: AssetFieldValue | undefined;
   private readonly localeCurrencyPipe = new LocaleCurrencyPipe();

   private readonly chartJSHelper = inject(ChartJSHelper);

   private readonly lineOptions: ChartOptions<"line"> = {
      plugins: {
         legend: {
            display: false,
         },
         tooltip: {
            mode: "point",
            titleFont: {
               size: 14,
            },
            bodyFont: {
               size: 14,
            },
            position: "nearest",
            intersect: false,
         },
         htmlLegend: {
            containerID: "depreciation-line-chart-legend",
         },
      },
      maintainAspectRatio: true,
      scales: {
         yAxes: {
            beginAtZero: true,
            ticks: {
               maxTicksLimit: 8,
               callback: (value) => {
                  return this.localeCurrencyPipe.transform(value, this.currencyCode);
               },
            },
         },
         xAxes: {
            type: "time",
            ticks: {
               maxTicksLimit: 15,
            },
         },
      },
      onHover: (event, _chartElement, chart) => {
         this.chartJSHelper.onHoverHandler(event, chart);
      },
   } as any; // NOTE: this is a workaround to avoid type errors since ChartJS doesn't have the correct types for the options to include `htmlLegend`

   public lineChart: Chart<any> | undefined;

   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageDepreciationSchedule = inject(ManageDepreciationSchedule);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.schedule.depreciationStartDate = Number(this.schedule.depreciationStartDate);
      this.schedule.purchaseCost = Number(this.schedule.purchaseCost);
      this.schedule.standardUsefulLife = Number(this.schedule.standardUsefulLife);
      this.schedule.salvageValue = Number(this.schedule.salvageValue);

      this.scheduleFieldValue = this.manageAsset.getFieldValue(this.schedule.valueID);
   }

   public ngAfterViewInit() {
      this.updateChart();
   }

   private initialLineData(): ChartData<"line"> {
      const defaults = {
         tension: 0,
         pointRadius: 3,
         fill: true,
         xAxisID: "xAxes",
         yAxisID: "yAxes",
         data: [],
      };
      return {
         labels: [],
         datasets: [
            {
               backgroundColor: "rgba(194,37,40,.9)",
               borderColor: "#fff",
               ...defaults,
            },
            {
               label: "",
               backgroundColor: "rgba(66,155,31,1)",
               borderColor: "#fff",
               ...defaults,
            },
         ],
      };
   }

   private readonly calculateScheduleData = () => {
      let firstPointStartDate;
      if (this.schedule.depreciationStartDate < Date.now() / 1000) {
         firstPointStartDate = this.schedule.depreciationStartDate;
      } else {
         const scheduleStartDayOfMonth = moment(
            this.schedule.depreciationStartDate * 1000,
         ).date();

         const currentDayOfMonth = moment().date();

         const startOfLastMonth = moment()
            .subtract(currentDayOfMonth - 1, "days")
            .subtract(1, "month");

         firstPointStartDate = startOfLastMonth
            .add(scheduleStartDayOfMonth, "days")
            .unix();
      }

      const standardUsefulLifeUnix = this.schedule.standardUsefulLife * 86400 * 30.437;

      //schedule points
      const pointsToCompute =
         moment(this.schedule.depreciationStartDate * 1000).diff(
            firstPointStartDate * 1000,
            "months",
         ) +
         this.schedule.standardUsefulLife +
         3;
      const scheduleData: any = [];

      for (let index = 0; index < pointsToCompute; index++) {
         const momentTime = moment(firstPointStartDate * 1000).add(index, "months");
         const timeStamp = momentTime.unix();
         scheduleData.push(this.calculatePointData(timeStamp, standardUsefulLifeUnix));
      }
      const lineData = this.initialLineData();

      lineData.datasets[1].data = scheduleData;
      lineData.datasets[1].label = this.lang().ScheduledValue;

      //current date point
      const currentDateY: any = [
         this.calculatePointData(moment().unix(), standardUsefulLifeUnix),
      ];

      lineData.datasets[0].data = currentDateY;
      lineData.datasets[0].label = this.lang().CurrentAssetValue;

      return lineData;
   };

   private readonly calculatePointData = (
      timeStamp: number,
      standardUsefulLifeUnix: number,
   ) => {
      const value = this.manageDepreciationSchedule
         .getValueAtTime(this.schedule, timeStamp, standardUsefulLifeUnix)
         .toFixed(2);

      // eslint-disable-next-line id-length -- library requires these property names
      return { x: new Date(timeStamp * 1000), y: value };
   };

   private readonly updateChart = () => {
      const lineData = this.calculateScheduleData();

      if (this.lineChartRef) {
         this.lineChart = new Chart(this.lineChartRef.nativeElement, {
            type: "line",
            data: lineData,
            options: this.lineOptions,
            plugins: [
               {
                  id: "htmlLegend",
                  afterUpdate: (chart) => {
                     const ul = this.chartJSHelper.getOrCreateLegendList(
                        chart,
                        "depreciation-line-chart-legend",
                        "row",
                     );
                     while (ul.firstChild) {
                        ul.firstChild.remove();
                     }
                     this.chartJSHelper.chartHTMLLegend(chart, ul);
                  },
               },
            ],
         });
      }
   };
}
