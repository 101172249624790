<li placement="bottom" limUiTooltip="{{ lang().CustomTagsTooltip }}" class="list-title">
   <lim-ui-icon icon="tag" iconSize="small" />
   <b>{{ lang().Tags }}</b>
   <lim-ui-icon icon="circleQuestionRegular" iconSize="small" />
</li>

@if (allTags?.length >= 8) {
   <lim-ui-dropdown-divider />
}

@if (allTags?.length >= 8) {
   <div class="search">
      <lim-ui-search-box
         [(searchVal)]="searchBar"
         (searchValChange)="setCustomTags()"
         clearText="{{ lang().clear }}"
         [placeholder]="lang().Search"
      />
   </div>
}
<lim-ui-dropdown-divider />
@for (tag of tags | orderBy: "name"; track tag.name) {
   <lim-ui-dropdown-item
      class="hoverContainer"
      [closeDropdownOnClick]="false"
      dataLogLabel="task-addTagDropdownItem"
   >
      @if (!data?.selectOne) {
         <span class="flex-dropdown-item">
            <lim-ui-checkbox
               [label]="tag.name"
               [model]="tag.tagged"
               (click)="clickTag(tag)"
               class="custom-tag-checkbox"
            />
            @if (superUser && data?.advancedSettings) {
               <span class="hoverItemNoFade mobileDisappear">
                  <lim-ui-icon
                     icon="editRegular"
                     iconSize="small"
                     (click)="editTag(tag)"
                     limUiTooltip="{{ lang().editCustomTagTooltip }}"
                     class="edit-icon-padding"
                  />
               </span>
            }
         </span>
      }
      @if (data?.selectOne) {
         <span>
            <div (click)="clickTag(tag)" class="select-one-option cursor">
               <span [limbleHtml]="tag.name"></span>
            </div>
         </span>
      }
   </lim-ui-dropdown-item>
}
<lim-ui-dropdown-divider />
@if (superUser && data?.advancedSettings) {
   <lim-ui-dropdown-item
      (click)="createTag()"
      class="cursor"
      dataLogLabel="task-createTagDropdownItem"
   >
      <lim-ui-icon icon="plus" iconSize="small" class="list-title-icons" />
      {{ lang().CreateTag }}
   </lim-ui-dropdown-item>
}
