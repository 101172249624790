import { inject, Component, input } from "@angular/core";
import { TooltipDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TimerFormatPipe } from "src/app/tasks/timer/timer-format.pipe";

/**
 * Like TaskTimerComponent, but cannot be started or stopped. It only displays
 * the time passed into it.
 */
@Component({
   selector: "task-timer-static",
   templateUrl: "./task-timer-static.component.html",
   styleUrls: ["./task-timer-static.component.scss"],
   imports: [TooltipDirective, TimerFormatPipe],
})
export class TaskTimerStaticComponent {
   /** The time to display, in seconds */
   public actualTime = input.required<number>();
   /** If provided, displays a hint on the timer. This value is in seconds. */
   public estimatedTime = input<number>(0);
   protected readonly lang = inject(ManageLang).lang;
}
