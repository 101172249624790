import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";

type AugmentedPurchaseOrderItem = PurchaseOrderItem & {
   itemName: string;
   itemNumber: string;
   receivedQty: number;
   tempNumberReceived: number;
};

@Component({
   selector: "pick-po-items",
   templateUrl: "./pickPOItems.modal.component.html",
   styleUrls: ["./pickPOItems.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      FormsModule,
      TooltipDirective,
      LimbleHtmlDirective,
      MinimalIconButtonComponent,
      BasicModalFooterComponent,
      BetterDecimalPipe,
   ],
})
export class PickPOItems implements OnInit {
   public resolve;
   public modalInstance;
   public roles;
   public message;
   public title;
   public errorMsg;
   public po: PurchaseOrder | undefined;
   public purchaseOrderItems: Lookup<"poItemID", AugmentedPurchaseOrderItem> = new Lookup(
      "poItemID",
   );
   public removedItem;

   private readonly manageUser = inject(ManageUser);
   private readonly managePO = inject(ManagePO);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.roles = this.manageUser.getRoles();

      this.message = this.resolve.data.message;
      this.title = this.resolve.data.title;
      this.errorMsg = "";

      this.po = this.managePO.getPurchaseOrder(this.resolve.data.poID);
      if (!this.po) {
         throw new Error("No PO exists for this ID.");
      }
      let tempPurchaseOrderItems: Array<AugmentedPurchaseOrderItem> = [];
      for (const purchaseOrderItemID of this.po.poItemIDs) {
         const purchaseOrderItem =
            this.managePO.getPurchaseOrderItem(purchaseOrderItemID);
         if (!purchaseOrderItem) {
            continue;
         }
         const receivedQty =
            this.managePO.getPurchaseOrderItemReceivedInfo(purchaseOrderItemID)
               ?.receivedQty ?? 0;
         const relatedInfo = this.managePO.getPurchaseOrderItemRelatedInfo(
            purchaseOrderItem.poItemID,
         );
         const itemName = relatedInfo?.itemName ?? "";
         const itemNumber = relatedInfo?.itemNumber ?? "";
         let tempNumberReceived = Number(purchaseOrderItem.qty) - receivedQty; //sets the temp received to the number they should be able to receive
         tempNumberReceived = Number(tempNumberReceived.toFixed(3));
         tempPurchaseOrderItems.push({
            ...purchaseOrderItem,
            itemName,
            itemNumber,
            receivedQty,
            tempNumberReceived,
         });
      }

      this.purchaseOrderItems.filter((item) => item.tempNumberReceived > 0);
      tempPurchaseOrderItems = orderBy(tempPurchaseOrderItems, ["-itemType", "itemName"]);
      this.purchaseOrderItems = new Lookup("poItemID", tempPurchaseOrderItems);

      this.removedItem = false;
   }

   protected close(): void {
      this.modalInstance.close(0);
   }

   protected validateItemNumber(item: AugmentedPurchaseOrderItem): void {
      const qtyLeft = Number((item.qty - item.receivedQty).toFixed(3));
      if (item.tempNumberReceived === undefined || item.tempNumberReceived > qtyLeft) {
         item.tempNumberReceived = Number(item.qty);
      }
      if (item.tempNumberReceived < 0) {
         item.tempNumberReceived = 0;
      }
   }

   protected removeItem(item: AugmentedPurchaseOrderItem): void {
      const itemToRemove = this.purchaseOrderItems.get(item.poItemID);
      if (!itemToRemove) {
         return;
      }
      this.purchaseOrderItems.delete(itemToRemove.poItemID);
      this.removedItem = true;
   }

   protected submit(): void {
      for (const item of this.purchaseOrderItems) {
         this.validateItemNumber(item);
      }
      const arr: any = [];

      for (const item of this.purchaseOrderItems) {
         if (item.tempNumberReceived > 0) {
            //they marked they received some so let's send it for processing
            const obj: any = {};
            obj.poItemID = item.poItemID;
            obj.qtyReceived = item.tempNumberReceived;
            arr.push(obj);
         }
      }

      if (arr.length == 0) {
         this.errorMsg = this.lang().WhoopsYouMustReceiveSomething;
         return;
      }
      for (const item of this.purchaseOrderItems) {
         //set back to default before returning the number
         item.tempNumberReceived = 0;
      }
      this.modalInstance.close(arr);
   }
}
