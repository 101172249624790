<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <span class="description-and-search" [hidden]="!message">
            <span [limbleHtml]="message"></span>
            <input
               class="search-bar"
               type="textbox"
               [placeholder]="lang().Search"
               [(ngModel)]="searchBar"
            />
         </span>

         <div class="listTable" style="width: 100%">
            @for (
               schedule of schedules
                  | filter: searchBar : false : true
                  | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
               track schedule
            ) {
               <div class="listItem">
                  <div class="listCol listCol50">
                     <lim-ui-icon icon="cube" />
                  </div>

                  <div class="listCol listCol50">
                     <a
                        [limbleHtml]="schedule.asset.assetName"
                        (click)="viewAsset(schedule.asset)"
                        class="green-color-link cursor"
                     ></a>

                     <asset-parent-list
                        [assetID]="schedule.asset.assetID"
                        linkColorClass="green-color"
                     />
                  </div>
                  <div class="listCol listCol50">
                     {{ lang().Every }} {{ schedule.increment }}
                     {{ schedule.field.fieldName }}
                     |

                     @if (schedule.openChecklistID == 0) {
                        <span
                           class="item-detail"
                           limUiTooltip="{{ lang().NextCreatedAtTooltip }}"
                           placement="bottom"
                        >
                           {{ lang().NextCreatedAt }} {{ schedule.valueEndWithIncrement }}
                           {{ schedule.field.fieldName }}
                        </span>
                     }

                     @if (schedule.openChecklistID > 0) {
                        <span
                           class="item-detail"
                           (click)="popTask(schedule.openChecklistID)"
                        >
                           {{ lang().PMIsCurrentlyOpen }}
                        </span>
                     }
                  </div>
               </div>
            }

            <div class="text-center">
               @if (schedules?.length && schedules?.length > itemsPerPage) {
                  <lim-ui-pagination
                     [maxSize]="5"
                     [rotate]="true"
                     [(page)]="page"
                     [pageSize]="itemsPerPage"
                     [collectionSize]="schedules?.length"
                     (pageChange)="pageChanged()"
                  />
               }
            </div>
         </div>

         @if (showLoading) {
            <div class="listOptionHolder">
               <div class="loading-icon">
                  <lim-ui-loading-animation />
               </div>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
