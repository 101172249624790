<ng-container *translate="let t">
   <div class="review-summary">
      @if (fieldCollisions) {
         <lim-ui-alert alertType="warning">
            <div>
               <span class="extra-bold">
                  {{ t("MergeErrorsFound") }}: {{ assetCollisionsCount }} </span
               ><br />
               <span>{{ t("AssetsCannotBeLinked") }}</span>
               <lim-ui-text-button
                  class="default-link"
                  (click)="openFieldConflictsModal()"
                  >{{ t("ViewErrorDetails") }}</lim-ui-text-button
               >
            </div>
         </lim-ui-alert>
      }
      <h2 class="lim-ui-fonts-h2">{{ t("Summary") }}</h2>
      <p class="semi-bold">
         {{ t("ReviewAndConfirmSelections") }}<br />
         <span class="red">{{ t("OnceYouProceed") }}</span>
      </p>
      <div class="checkboxes-container">
         <div class="checkbox-row">
            <lim-ui-checkbox
               [(model)]="userConfirmed.location"
               (modelChange)="resetError()"
            />
            <span class="checkbox-label" (click)="toggleCheckbox('location')"
               ><b>{{ t("Location") }}:</b> {{ selectedLocation().locationName }}</span
            >
         </div>
         <div class="checkbox-row">
            <lim-ui-checkbox
               [(model)]="userConfirmed.assets"
               (modelChange)="resetError()"
            />
            <span class="checkbox-label" (click)="toggleCheckbox('assets')"
               ><b>{{ t("AssetsToBeLinkedToTemplate") }}:</b>
               {{ selectedAssetCount() - assetCollisionsCount }}</span
            >
         </div>
         <div class="checkbox-row">
            <lim-ui-checkbox
               [(model)]="userConfirmed.fields"
               (modelChange)="resetError()"
            />
            <span class="checkbox-label" (click)="toggleCheckbox('fields')"
               ><b>{{ t("LocationFieldsToMerge") }}:</b>
               {{ fieldsToMergeCount() - fieldCollisionsCount }}</span
            >
         </div>
      </div>
      @if (isConfirmationError) {
         <p class="red">{{ t("CheckAllNotice") }}</p>
      }
   </div>
</ng-container>
