<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel [noSidePadding]="true" class="modal-body">
         @if (task()) {
            <div class="panel-content-wrapper">
               @for (invoice of taskInvoices(); track invoice.invoiceID) {
                  <div class="invoice-container">
                     <span class="column-flex list-item">
                        <span class="row-flex">
                           <div class="form-group">
                              <label class="control-label"
                                 >{{ lang().InvoiceCost }}
                                 @if (!isMultiCurrencyEnabled()) {
                                    ({{ strCurrencySymbol }})
                                 }
                              </label>
                              <div>
                                 <div class="input-length">
                                    @if (invoice.poItemID === 0) {
                                       <span class="input-length">
                                          @if (isMultiCurrencyEnabled()) {
                                             <lim-ui-input-with-prefix
                                                [prefixText]="
                                                   currencyCode() | currencySymbol
                                                "
                                                [type]="'number'"
                                                [(value)]="invoice.invoiceCost"
                                                (valueChange)="
                                                   updateInvoiceCost(
                                                      invoice.invoiceID,
                                                      invoice.invoiceCost ?? 0
                                                   )
                                                "
                                                [min]="0"
                                                onclick="this.focus();"
                                                class="number-field"
                                             />
                                          } @else {
                                             <input
                                                type="number"
                                                class="input-length"
                                                [(ngModel)]="invoice.invoiceCost"
                                                (blur)="
                                                   updateInvoiceCost(
                                                      invoice.invoiceID,
                                                      invoice.invoiceCost ?? 0
                                                   )
                                                "
                                                onclick="this.focus();"
                                                min="0.00"
                                                placeholder="0.00"
                                             />
                                          }
                                       </span>
                                    }
                                    @if (invoice.poItemID && invoice.poItemID > 0) {
                                       <span>
                                          @if (isMultiCurrencyEnabled()) {
                                             {{
                                                invoice.invoiceCost
                                                   | localeCurrency: currencyCode()
                                             }}
                                          } @else {
                                             {{ strCurrencySymbol }}
                                             {{
                                                invoice.invoiceCost
                                                   | betterNumber: "1.2-2"
                                             }}
                                          }
                                          -
                                          <a
                                             class="cursor"
                                             (click)="popPoComponent(invoice.poItemID)"
                                             >{{ lang().PO }} -
                                             {{ getInvoicePO(invoice)?.poNumber }}</a
                                          >
                                       </span>
                                    }
                                 </div>
                              </div>
                           </div>

                           <div class="form-group">
                              <label class="control-label">{{
                                 lang().InvoiceDescription
                              }}</label>
                              <div>
                                 <div class="input-length">
                                    @if (invoice.poItemID === 0) {
                                       <span>
                                          <div
                                             type="text"
                                             class="div-text-box update-invoice"
                                             [(ngModel)]="invoice.invoiceDescription"
                                             limbleContentEditable
                                             (blur)="updateInvoiceDescription(invoice)"
                                          ></div>
                                       </span>
                                    }

                                    @if (invoice.poItemID && invoice.poItemID > 0) {
                                       <span>
                                          {{ invoice.invoiceDescription }}
                                       </span>
                                    }
                                 </div>
                              </div>
                           </div>
                        </span>
                        <span class="row-flex-icons">
                           <div class="form-group">
                              <label class="control-label">{{
                                 lang().AttachInvoice
                              }}</label>

                              @let invoiceFileName = getInvoiceFileName(invoice);
                              @if (invoiceFileName) {
                                 <file-list-item
                                    [file]="getInvoiceFileInfo(invoice)"
                                    [url]="getInvoiceFileNameUrl(invoice)"
                                 />
                              }
                              <div class="space-between">
                                 @if (!invoiceFileName) {
                                    <file-uploader
                                       [uploadObject]="getInvoiceUploadObj(invoice)"
                                       icon="cameraRegular"
                                       label="{{ lang().AttachFile }}"
                                    />
                                 } @else {
                                    <file-uploader
                                       [uploadObject]="getInvoiceUploadObj(invoice)"
                                       icon="cameraRegular"
                                       label="{{ lang().ChangeFile }}"
                                    />
                                 }

                                 <lim-ui-icon-button
                                    limUiTooltip="{{ lang().RemoveThisInvoice }}"
                                    (click)="
                                       removeInvoice(
                                          invoice.poItemID ?? 0,
                                          invoice.invoiceID
                                       )
                                    "
                                    icon="trashCanRegular"
                                    iconColor="danger"
                                    iconSize="small"
                                 />
                              </div>
                           </div>
                        </span>
                     </span>

                     <div class="error-msg" [hidden]="!(errorMsg !== '')">
                        <div class="alert alert-dismissable alert-danger">
                           <strong class="mr-1">{{ lang().Warning }}!</strong>
                           <span [limbleHtml]="errorMsg"></span>
                        </div>
                     </div>

                     <hr class="title-hr" />
                  </div>
               }
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer class="add-invoice-button">
      <lim-ui-secondary-button icon="plus" (click)="addInvoice()">
         {{ lang().AddAnotherInvoice }}
      </lim-ui-secondary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
