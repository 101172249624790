import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   standalone: true,
   selector: "completion-notes-cell",
   templateUrl: "./completion-notes-cell.component.html",
})
export class CompletionNotesCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
