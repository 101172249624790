import { inject, Component } from "@angular/core";
import { IconComponent } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { UpdateToastService } from "src/app/shared/services/update/update-toast/update-toast.service";

@Component({
   selector: "update-toast",
   templateUrl: "./update-toast.component.html",
   styleUrls: ["./update-toast.component.scss"],
   imports: [IconComponent],
})
export class UpdateToastComponent {
   private readonly updateToastService = inject(UpdateToastService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = this.manageLang.lang;

   public refresh(): void {
      // Reload the page to update to the latest version.
      document.location.reload();
   }

   public dismiss(): void {
      this.updateToastService.dismiss();
   }
}
