import { computed } from "@angular/core";
import type { CreateQueryResult } from "@tanstack/angular-query-experimental";
import type { ListResponse } from "src/app/shared/services/flannel-api-service/api.models";
import type {
   ListMapperFunction,
   QuerySelectors,
} from "src/app/shared/services/query/query-selectors.models";

export function createQuerySelectors<Entity, MappedEntity = Entity>(
   query: CreateQueryResult<Entity> | undefined,
   listMapper: ListMapperFunction<any, MappedEntity>,
): QuerySelectors<Entity, MappedEntity> {
   const isLoading = computed(() => query?.isLoading() ?? false);
   const isError = computed(() => query?.isError() ?? false);
   const error = computed(() => query?.error() ?? null);
   const data = computed(() => query?.data() as ListResponse<Entity> | undefined);
   const status = computed(() => query?.status() ?? "pending");
   const items = computed(() => {
      const response = data();
      const rawItems = response?.data ?? [];
      return listMapper(rawItems);
   });

   const totalItems = computed(() => {
      const response = data();
      return response?.total ?? 0;
   });

   const hasResults = computed(() => totalItems() > 0 && !isLoading());
   const hasNoResults = computed(() => totalItems() === 0 && !isLoading());
   const isLoadingFirstTime = computed(() => isLoading() && !data());

   return {
      isLoading,
      isError,
      error,
      data,
      items,
      totalItems,
      hasResults,
      hasNoResults,
      isLoadingFirstTime,
      status,
   };
}
