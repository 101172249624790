import type { Moment } from "moment";
import moment from "moment";
import type { FilterBase } from "src/app/dashboards/filters/index";
import type { TaskLookup } from "src/app/tasks/types/task.types";

const DATE_FORMAT = "YYYY/MM/DD";

export class DateRangeFilter implements FilterBase {
   public readonly filterType = "dateRange" as const;
   public readonly displayString: string;

   public constructor(
      public readonly date1: Moment,
      public readonly date2: Moment,
      public readonly attribute: "checklistDueDate" | "checklistCompletedDate",
   ) {
      this.displayString = `${date1.format(DATE_FORMAT)} to ${date2.format(DATE_FORMAT)}`;
   }

   public filterFunction(tasks: TaskLookup): TaskLookup {
      const adjustedBeginning = this.date1.clone().startOf("day");
      const adjustedEnd = this.date2.clone().endOf("day");
      return tasks.filter((task) => {
         if (task[this.attribute] === null) return false;

         return moment
            .unix(Number(task[this.attribute]))
            .isBetween(adjustedBeginning, adjustedEnd);
      });
   }

   public getValue(): { startDate: Date; endDate: Date } {
      return {
         startDate: this.date1.toDate(),
         endDate: this.date2.toDate(),
      };
   }
}
