import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export type NewMentionedList = any;

@Injectable({ providedIn: "root" })
export class UpdateTaskStateService {
   private readonly _updateGuides = new Subject<void>();
   public readonly updateGuides$ = this._updateGuides.asObservable();

   private readonly _completeTask = new Subject<void>();
   public readonly completeTask$ = this._completeTask.asObservable();

   private readonly _buildData = new Subject<boolean>();
   public readonly buildData$ = this._buildData.asObservable();

   private readonly _setTask = new Subject<void>();
   public readonly setTask$ = this._setTask.asObservable();

   private readonly _refreshInstructions = new Subject<void>();
   public readonly refreshInstructions$ = this._refreshInstructions.asObservable();

   private readonly _submitTags = new Subject<NewMentionedList>();
   public readonly submitTags$ = this._submitTags.asObservable();

   private openLogTimeModal?: (
      extraMsg?: any,
      extratooltip?: any,
   ) => Promise<void> | void;

   private readonly _timeLogged = new Subject<void>();
   public readonly timeLogged$ = this._timeLogged.asObservable();

   private readonly _rebuildTags = new Subject<string | undefined>();
   public readonly rebuildTags$ = this._rebuildTags.asObservable();

   private readonly _incGreyOutWatchVar = new Subject<void>();
   public readonly incGreyOutWatchVar$ = this._incGreyOutWatchVar.asObservable();

   private readonly _incItemWatchVar = new Subject<void>();
   public readonly incItemWatchVar$ = this._incItemWatchVar.asObservable();

   public guidesNeedsUpdate(): void {
      this._updateGuides.next();
   }

   public completeTask(): void {
      this._completeTask.next();
   }

   public buildData(updateInstructions: boolean = false): void {
      this._buildData.next(updateInstructions);
   }

   public setTask(): void {
      this._setTask.next();
   }

   public refreshInstructions(): void {
      this._refreshInstructions.next();
   }

   public submitTags(newMentionedList: NewMentionedList): void {
      this._submitTags.next(newMentionedList);
   }

   public timeLogged(): void {
      this._timeLogged.next();
   }

   public rebuildTags(tags?: string): void {
      this._rebuildTags.next(tags);
   }

   public incGreyOutWatchVar(): void {
      this._incGreyOutWatchVar.next();
   }

   public incItemWatchVar(): void {
      this._incItemWatchVar.next();
   }

   /**
    * Eventually, the modal opener should be less stateful of a method, and can be opened by chk item.
    *
    * But for now, we need to set the method, as the old method is incredibly stateful and fragile.
    */
   public setOpenLogTimeModalMethod(
      method: (extraMsg?: any, extratooltip?: any) => Promise<void> | void,
   ): void {
      this.openLogTimeModal = method;
   }

   public async callSetOpenTimeLogModal(
      extraMsg?: any,
      extratooltip?: any,
   ): Promise<void> {
      await this.openLogTimeModal?.(extraMsg, extratooltip);
   }
}
