import { Injectable } from "@angular/core";
import axios from "axios/dist/axios";

@Injectable({ providedIn: "root" })
export class ManageImonnit {
   private readonly axios = axios;

   getSensorList = async () => {
      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "getSensorList",
      });
   };
   attachSensorToValueID = async (sensorID, dataPoint, valueID, labels) => {
      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "attachSensorToValueID",
         sensorID: sensorID,
         dataPoint: dataPoint,
         valueID: valueID,
         labels: labels,
      });
   };
   getLastSensorReading = async (sensorID) => {
      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "getLastSensorReading",
         sensorID: sensorID,
      });
   };
   signIn = async (usernameIn?, passwordIn?) => {
      const username = usernameIn || false;
      const password = passwordIn || false;

      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "signIn",
         username: username,
         password: password,
      });
   };
   getFieldsWithAttachedSensors = async (valueIDIn) => {
      const valueID = valueIDIn || false;

      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "getFieldsWithAttachedSensors",
         valueID: valueID,
      });
   };
   detachSensorFromValueID = async (valueIDIn) => {
      const valueID = valueIDIn || false;
      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "detachSensorFromValueID",
         valueID: valueID,
      });
   };
   getSensorInfo = async (sensorID) => {
      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "getSensorInfo",
         sensorID: sensorID,
      });
   };
   applyConfig = async (valueID, config) => {
      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "applyConfig",
         valueID: valueID,
         config: config,
      });
   };
   getConfigRules = async (valueID, configNum) => {
      return this.axios.post("phpscripts/manageImonnit.php", {
         action: "getConfigRules",
         valueID: valueID,
         configNum: configNum,
      });
   };
}
