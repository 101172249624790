import { Pipe, type PipeTransform } from "@angular/core";
import { CurrencyCodeToDetailMap } from "src/app/purchasing/currency/utils/currency-code-to-detail-map";

@Pipe({
   name: "currencySymbol",
})
export class CurrencySymbolPipe implements PipeTransform {
   public transform(currencyCode: string): string {
      const detail = CurrencyCodeToDetailMap[currencyCode];
      if (detail === undefined) return "";

      const { symbol } = detail;
      return symbol;
   }
}
