import { unitCategoryDescriptions } from "src/app/parts/unit-of-measure/provided-units/descriptions";
import type { ProvidedUnitDtoSchema } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/provided-unit.dto.schema";
import type { ToMultiple } from "src/app/shared/types/utility-types";
import { z } from "zod";

const schemas = unitCategoryDescriptions.map(
   (description) => description.schema,
) as ToMultiple<ProvidedUnitDtoSchema>;

export const providedUnitDtoSchema = z.union(schemas);
