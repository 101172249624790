<p-paginator
   [pageLinkSize]="pageLinkSize()"
   [showFirstLastIcon]="showFirstLastIcon()"
   [showJumpToPageDropdown]="showJumpToPageDropdown()"
   [showPageLinks]="showPageLinks()"
   [showCurrentPageReport]="showCurrentPageReport()"
   [currentPageReportTemplate]="currentPageReportTemplate()"
   [rows]="rows()"
   [first]="first()"
   [totalRecords]="totalRecords()"
   [alwaysShow]="alwaysShow()"
   (onPageChange)="onPageNumberChange($event)"
/>
