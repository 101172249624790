@if (isLoaded === true && !isLoadingTaskNotes) {
   <div class="table-container">
      <div class="listTable lim-ui-responsive-table">
         @if (!noHeaders) {
            <div
               [ngClass]="{
                  'listItem listItemHeader modal-no-margin': isModal,
                  'listItem listItemHeader': !isModal,
                  'table-scrollable-page-header': tableInScrollablePage,
               }"
               class="lim-ui-responsive-table-header scrollable-list-header header"
               [hidden]="!!noSearchResults || taskIDs?.length == 0"
            >
               @for (column of columns; track column) {
                  @if (column.key === "checklistName") {
                     <div
                        class="col-md-{{ column.columnWidth }}"
                        [ngClass]="
                           isModal ? 'task-name-header-modal' : 'task-name-header'
                        "
                     >
                        <sort-column
                           column="priorityLevel"
                           iconName="circleExclamation"
                           [bind]="storedSortBindValue"
                           (bindChange)="bindChanged($event)"
                           [sortFn]="sortFn"
                           limUiTooltip="{{ lang().Priority }}"
                        />

                        <sort-column
                           column="statusName"
                           iconName="circleNotch"
                           [bind]="storedSortBindValue"
                           (bindChange)="bindChanged($event)"
                           [sortFn]="sortFn"
                           limUiTooltip="{{ lang().Status }}"
                        />

                        <sort-column
                           column="typeIcon"
                           name="{{ lang().Type }}"
                           [bind]="storedSortBindValue"
                           (bindChange)="bindChanged($event)"
                           [sortFn]="sortFn"
                        />

                        <sort-column
                           column="checklistName"
                           name="{{ lang().Name }}"
                           [bind]="storedSortBindValue"
                           (bindChange)="bindChanged($event)"
                           [sortFn]="sortFn"
                        />

                        <sort-column
                           column="uncreadComments"
                           iconName="comment"
                           [bind]="storedSortBindValue"
                           (bindChange)="bindChanged($event)"
                           [sortFn]="sortFn"
                           limUiTooltip="{{ lang().UnreadComments }}"
                        />
                     </div>
                  }
                  @if (column.key !== "checklistName") {
                     <sort-column
                        class="col-md-{{ column.columnWidth }}"
                        column="{{ column.sortBy }}"
                        name="{{ column.displayName }}"
                        [bind]="storedSortBindValue"
                        (bindChange)="bindChanged($event)"
                        [ngStyle]="{
                           'text-align': column.textAlign ? column.textAlign : 'left',
                        }"
                        [sortFn]="sortFn"
                        [hidden]="!!noSearchResults || !taskIDs || taskIDs.length == 0"
                     />
                  }
               }
            </div>
         }

         <lim-ui-scroll-container
            class="tasks-container"
            [ngClass]="{ 'modal-height': isModal || autoHeight }"
            [thin]="true"
         >
            @if (noSearchResults) {
               <no-search-results />
            }

            @for (task of tasksInView; track task; let isLast = $last) {
               <div class="table-row-container">
                  <task-item
                     [ngClass]="[
                        isModal ? 'listItem modal-no-margin' : 'listItem',
                        isLast ? 'last' : '',
                     ]"
                     class="lim-ui-responsive-table-row"
                     [isModal]="isModal"
                     [taskID]="task.checklistID"
                     [columns]="columns"
                     [globalSearch]="true"
                     [searchHint]="searchHints.get(task.checklistID)"
                     [dataLogSection]="dataLogSection"
                     [isTaskNameIncludingIdAndAssetName]="
                        isTaskNameIncludingIdAndAssetName
                     "
                  />
               </div>
            }
         </lim-ui-scroll-container>
      </div>

      @if (taskIDs && tasksInView.length > 0) {
         <div
            class="table-footer"
            [ngClass]="{
               'pagination-container': !tableInScrollablePage,
               'modal-pagination': isModal || autoHeight,
            }"
         >
            <lim-ui-pagination
               [maxSize]="5"
               [rotate]="true"
               [page]="pageTasks"
               [pageSize]="itemsPerPage"
               [collectionSize]="taskIDs.length"
               (pageChange)="pageNumberChanged($event)"
            />
            <lim-ui-dropdown-results-per-page
               [selectedValue]="itemsPerPage"
               [label]="lang().itemsPerPage"
               class="readable-background"
            >
               <ng-container menu>
                  @for (num of ["5", "10", "20", "50", "100"]; track num) {
                     <lim-ui-dropdown-text-item
                        [displayString]="num"
                        [outputString]="num"
                        (itemSelectedEvent)="updateItemsPerPage(num)"
                     />
                  }
               </ng-container>
            </lim-ui-dropdown-results-per-page>
         </div>
      }

      @if (loadingBar) {
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      }
   </div>
} @else {
   <div class="text-center">
      <ngx-skeleton-loader count="1" [theme]="{ width: '30%', height: '20px' }" />
   </div>

   <br /><ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
   <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
   <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
   <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
   <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
   <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" />

   <div class="text-center">
      <ngx-skeleton-loader count="1" [theme]="{ width: '30%', height: '20px' }" />
   </div>

   @if (loadingBar) {
      <div>
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      </div>
   }

   <div [hidden]="!noSearchResults">
      <no-search-results />
   </div>
}
