<ng-container *translate="let t">
   <lim-ui-dropdown-button
      [label]="t(inheritedFilter().labelKey) || ''"
      filterDropdown
      [dataLogLabel]="
         'assetTemplatesApplyTemplateFlow-selectAssets-clickAssetInformationFilter'
      "
   >
      @if (fields().length > 5) {
         <div header>
            <lim-ui-search-box
               [placeholder]="t('Search')"
               [searchVal]="fieldsSearchValue()"
               (searchValChange)="handleSearch($event)"
               [useDropdownStyling]="true"
            />
            <lim-ui-dropdown-divider />
         </div>
      }
      <div menu>
         @for (field of fieldsDropdown(); track field.fieldID) {
            <lim-ui-dropdown-item
               [dataLogLabel]="
                  'assetTemplatesApplyTemplateFlow-selectAssets-clickAsserInformationFilterOption'
               "
               (click)="handleSetField(field)"
               class="field-option"
            >
               @if (field.scopeType === AssetFieldScopeType.Standardized) {
                  <lim-ui-icon icon="books" class="option-icon" />
               }
               {{ field.fieldName }}
            </lim-ui-dropdown-item>
         }
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-clear-filter-item (click)="handleClearAll()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
   <lim-ui-filter-input-wrapper filterInputs>
      @for (filter of assetFilters(); track filter) {
         <div>
            <lim-ui-filter-input (clearFilterEvent)="clearFilter($index)">
               <label inputLabel> {{ filter.fieldName }}: </label>
               <span inputElement>
                  @switch (filter.field?.fieldTypeID) {
                     @case (AssetFieldTypeID.Date) {
                        <date-field-filter
                           [initialValue]="initialValues()[filter.fieldName]"
                           (filterDateStartChange)="
                              updateDateField(filter.fieldName, $event, 'beginDate')
                           "
                           (filterDateEndChange)="
                              updateDateField(filter.fieldName, $event, 'endDate')
                           "
                        />
                     }
                     @case (AssetFieldTypeID.Number) {
                        <number-field-filter
                           [initialValue]="initialValues()[filter.fieldName]"
                           (filterNumberLowestChange)="
                              updateNumberField(filter.fieldName, $event, 'lowest')
                           "
                           (filterNumberHighestChange)="
                              updateNumberField(filter.fieldName, $event, 'highest')
                           "
                        />
                     }
                     @case (AssetFieldTypeID.Currency) {
                        <number-field-filter
                           [initialValue]="initialValues()[filter.fieldName]"
                           (filterNumberLowestChange)="
                              updateNumberField(filter.fieldName, $event, 'lowest')
                           "
                           (filterNumberHighestChange)="
                              updateNumberField(filter.fieldName, $event, 'highest')
                           "
                        />
                     }
                     @case (AssetFieldTypeID.Dropdown) {
                        <dropdown-field-filter
                           [fieldID]="filter.field?.fieldID ?? 0"
                           [initialValue]="initialValues()[filter.fieldName]?.value"
                           (filterDropdownChange)="
                              updateSearchField(filter.fieldName, $event)
                           "
                        />
                     }

                     @default {
                        <!-- Text, Picture or Document field -->
                        <text-field-filter
                           [initialValue]="initialValues()[filter.fieldName]?.value"
                           (filterTextChange)="
                              updateSearchField(filter.fieldName, $event)
                           "
                        />
                     }
                  }
               </span>
            </lim-ui-filter-input>
         </div>
      }
   </lim-ui-filter-input-wrapper>
</ng-container>
