import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageAsset } from "src/app/assets/services/manageAsset";

@Pipe({
   name: "assetName",
   standalone: true,
})
export class AssetName implements PipeTransform {
   private readonly manageAsset = inject(ManageAsset);

   public transform(assetID: number): string {
      const asset = this.manageAsset.getAsset(assetID);

      if (!asset?.assetName) {
         return "";
      }

      return asset.assetName;
   }
}
