//current readme doc https://limble.atlassian.net/wiki/spaces/Engineering/pages/58720293/Hierarchy+Item
import { NgClass, SlicePipe } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   type Aliases,
   IconComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   TextButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { HierarchyOptions, HierarchyNode } from "src/app/shared/types/general.types";

@Component({
   selector: "hierarchy-item-legacy",
   templateUrl: "./hierarchy-item-component-legacy.component.html",
   styleUrls: ["./hierarchy-item-component-legacy.component.scss"],
   imports: [
      NgClass,
      IconComponent,
      LimbleHtmlDirective,
      MinimalIconButtonComponent,
      TooltipDirective,
      TextButtonComponent,
      SlicePipe,
   ],
})
export class HierarchyItemLegacy implements OnInit {
   @Input() node?: HierarchyNode;
   @Input() options: HierarchyOptions | undefined = {
      idKey: "",
      nodeButtons: [],
   };
   @Input() deselectAllNodes;
   @Input() selectNode;
   @Input() collapseNode;
   @Input() icon?: Aliases;
   @Input() clickAgainText: string | undefined;
   @Input() includeShowMore: boolean | undefined;
   protected numberOfChildrenToAddInitialVal: number = 0;
   protected numberOfItemsRendered: number = 0;
   protected numberOfChildrenToAdd: number = 50;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (!this.node) {
         return;
      }
      if (this.includeShowMore) {
         this.numberOfChildrenToAddInitialVal = 3;
         this.numberOfItemsRendered = this.numberOfChildrenToAddInitialVal;
         this.calculateAdditionalItemsToRenderText();
      } else {
         this.numberOfItemsRendered = this.node.nodes?.length;
      }
   }

   protected showMoreItems(): void {
      this.numberOfItemsRendered += this.numberOfChildrenToAdd;
      this.calculateAdditionalItemsToRenderText();
   }

   private calculateAdditionalItemsToRenderText(): void {
      if (!this.node?.nodes?.length) {
         return;
      }
      if (
         this.numberOfItemsRendered + this.numberOfChildrenToAdd >=
         this.node?.nodes?.length
      ) {
         this.numberOfChildrenToAdd =
            this.node?.nodes?.length - this.numberOfItemsRendered;
      }
   }
}
