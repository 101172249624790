<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header (closeModal)="close()" [title]="title" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         {{ message }}
      </lim-ui-panel>
      <lim-ui-panel>
         <div class="panel-sub-container">
            <div class="reoccur-type-container">
               <lim-ui-radio-button
                  groupID="reoccurType"
                  [label]="lang().Daily"
                  [checked]="reoccurType === 'day'"
                  (click)="
                     resetDayMonthYear(); setReoccur('day'); recurrence.reoccurFld1 = 1
                  "
               />
               @if (reoccurType === "day") {
                  <div class="recurrence-option">
                     <div class="day-and-time-container">
                        <div class="options-sub-container">
                           {{ lang().RepeatEvery }}

                           <lim-ui-number-input-with-buttons
                              class="number-input"
                              [incrementBy]="1"
                              [(unit)]="recurrence.reoccurFld1"
                              min="1"
                              [canEdit]="true"
                           />
                           @if (
                              recurrence.reoccurFld1 > 1 || recurrence.reoccurFld1 === 0
                           ) {
                              <span>{{ lang().days }}</span>
                           }
                           @if (recurrence.reoccurFld1 == 1) {
                              <span>{{ lang().day }}</span>
                           }
                        </div>
                        <div class="options-sub-container">
                           <span [ngClass]="{ 'time-of-day-disabled': !timeOfDay }">
                              {{ lang().at }}
                           </span>
                           <div>
                              <time-of-day-picker
                                 [(tod)]="timeOfDay"
                                 [(hour)]="hour"
                                 [(minute)]="minute"
                                 [(ampm)]="AMPM"
                                 [optional]="true"
                              />
                           </div>
                        </div>
                     </div>

                     <div class="calendar-picker-container">
                        <label>{{ lang().StartsOn }}</label>
                        <span class="options-sub-container calendar-picker-sub-container">
                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnInMilliseconds"
                              (modelChange)="updateSpawnStart($event)"
                           />
                           <span
                              [ngClass]="{
                                 'no-schedule-end-date':
                                    recurrence.reoccurSpawnEnd === 0 ||
                                    recurrence.reoccurSpawnEnd === null,
                              }"
                              limUiTooltip="{{
                                 lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                              }}"
                           >
                              {{ lang().andEndsOn }}
                           </span>

                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnEndInMilliseconds"
                              (modelChange)="updateSpawnEnd($event)"
                              [ngClass]="{
                                 'date-picker-unset':
                                    recurrence.reoccurSpawnEnd === 0 ||
                                    recurrence.reoccurSpawnEnd === null,
                              }"
                              [showClearIcon]="
                                 !(
                                    recurrence.reoccurSpawnEnd === 0 ||
                                    recurrence.reoccurSpawnEnd === null
                                 )
                              "
                              (modelClear)="recurrence.reoccurSpawnEnd = null"
                           />
                        </span>
                     </div>
                     <div class="hint-text">
                        {{ lang().EveryXDaysHint }}
                     </div>
                  </div>
               }
               <lim-ui-radio-button
                  groupID="reoccurType"
                  [label]="lang().Weekly"
                  [checked]="reoccurType === 'week'"
                  (click)="resetWeek(); setReoccur('week'); recurrence.reoccurFld1 = 1"
               />
               @if (reoccurType === "week") {
                  <div class="recurrence-option">
                     <div class="day-and-time-container">
                        <div class="options-sub-container">
                           {{ lang().RepeatEvery }}

                           <lim-ui-number-input-with-buttons
                              class="number-input"
                              [incrementBy]="1"
                              [(unit)]="recurrence.reoccurFld1"
                              min="1"
                              [canEdit]="true"
                           />

                           @if (
                              recurrence.reoccurFld1 > 1 || recurrence.reoccurFld1 == 0
                           ) {
                              <span>
                                 {{ lang().weeks }}
                              </span>
                           }
                           @if (recurrence.reoccurFld1 == 1) {
                              <span>
                                 {{ lang().week }}
                              </span>
                           }
                        </div>
                        <div class="options-sub-container">
                           <span
                              [ngClass]="{ 'time-of-day-disabled': timeOfDay === false }"
                           >
                              {{ lang().at }}
                           </span>
                           <time-of-day-picker
                              [(tod)]="timeOfDay"
                              [(hour)]="hour"
                              [(minute)]="minute"
                              [(ampm)]="AMPM"
                              [optional]="true"
                           />
                        </div>
                     </div>
                     <div class="weekday-container">
                        {{ lang().onStr }}
                        <div class="weekday-checkboxes">
                           <lim-ui-checkbox
                              [(model)]="week[0].selected"
                              (modelChange)="toggleWeekdayChecked(week[0])"
                              [label]="lang().Monday"
                           />
                           <lim-ui-checkbox
                              [(model)]="week[1].selected"
                              (modelChange)="toggleWeekdayChecked(week[1])"
                              [label]="lang().Tuesday"
                           />
                           <lim-ui-checkbox
                              [(model)]="week[2].selected"
                              (modelChange)="toggleWeekdayChecked(week[2])"
                              [label]="lang().Wednesday"
                           />
                           <lim-ui-checkbox
                              [(model)]="week[3].selected"
                              (modelChange)="toggleWeekdayChecked(week[3])"
                              [label]="lang().Thursday"
                           />
                           <lim-ui-checkbox
                              [(model)]="week[4].selected"
                              (modelChange)="toggleWeekdayChecked(week[4])"
                              [label]="lang().Friday"
                           />
                           <lim-ui-checkbox
                              [(model)]="week[5].selected"
                              (modelChange)="toggleWeekdayChecked(week[5])"
                              [label]="lang().Saturday"
                           />
                           <lim-ui-checkbox
                              [(model)]="week[6].selected"
                              (modelChange)="toggleWeekdayChecked(week[6])"
                              [label]="lang().Sunday"
                           />
                        </div>
                     </div>
                     <div class="weekly-calendar-picker-container">
                        <label>{{ lang().StartsOn }}</label>
                        <span class="options-sub-container calendar-picker-sub-container">
                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnInMilliseconds"
                              (modelChange)="updateSpawnStart($event)"
                           />
                           <span
                              [ngClass]="{
                                 'no-schedule-end-date':
                                    recurrence.reoccurSpawnEnd === 0 ||
                                    recurrence.reoccurSpawnEnd === null,
                              }"
                              limUiTooltip="{{
                                 lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                              }}"
                           >
                              {{ lang().andEndsOn }}
                           </span>

                           <lim-ui-date-picker-input
                              [model]="reoccurSpawnEndInMilliseconds"
                              (modelChange)="updateSpawnEnd($event)"
                              [ngClass]="{
                                 'date-picker-unset':
                                    recurrence.reoccurSpawnEnd === 0 ||
                                    recurrence.reoccurSpawnEnd === null,
                              }"
                              [showClearIcon]="
                                 !(
                                    recurrence.reoccurSpawnEnd === 0 ||
                                    recurrence.reoccurSpawnEnd === null
                                 )
                              "
                              (modelClear)="recurrence.reoccurSpawnEnd = null"
                           />
                        </span>
                     </div>
                  </div>
               }
               <lim-ui-radio-button
                  groupID="reoccurType"
                  [label]="lang().Monthly"
                  [checked]="reoccurType === 'month'"
                  (click)="resetDayMonthYear(); setReoccur('month')"
               />
               @if (reoccurType === "month") {
                  <div class="recurrence-option">
                     <div class="options-sub-container recurrence-sub-option-selection">
                        <lim-ui-radio-button
                           groupID="monthly-type"
                           [label]="lang().Weekday"
                           [checked]="monthFlip === 'Weekday'"
                           (click)="setMonthlyReoccurType('Weekday')"
                        />
                        <lim-ui-radio-button
                           groupID="monthly-type"
                           [label]="lang().Numeric"
                           [checked]="monthFlip === 'Numeric'"
                           (click)="setMonthlyReoccurType('Numeric')"
                        />
                     </div>

                     @if (monthFlip) {
                        <div class="recurrence-sub-option">
                           <div class="day-and-time-container">
                              <div class="options-sub-container">
                                 {{ lang().RepeatEvery }}

                                 <lim-ui-number-input-with-buttons
                                    class="number-input"
                                    [incrementBy]="1"
                                    [(unit)]="recurrence.reoccurFld1"
                                    min="1"
                                    [canEdit]="true"
                                 />
                                 @if (recurrence.reoccurFld1 > 1) {
                                    <span>
                                       {{ lang().months }}
                                    </span>
                                 }
                                 @if (
                                    recurrence.reoccurFld1 === 1 ||
                                    recurrence.reoccurFld1 === 0
                                 ) {
                                    <span>
                                       {{ lang().month }}
                                    </span>
                                 }
                              </div>
                              <div class="options-sub-container">
                                 <span [ngClass]="{ 'time-of-day-disabled': !timeOfDay }">
                                    {{ lang().at }}
                                 </span>
                                 <div>
                                    <time-of-day-picker
                                       [(tod)]="timeOfDay"
                                       [(hour)]="hour"
                                       [(minute)]="minute"
                                       [(ampm)]="AMPM"
                                       [optional]="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           @if (monthFlip === "Weekday") {
                              <div class="day-of-month-container">
                                 {{ lang().onThe }}
                                 <lim-ui-dropdown>
                                    <div
                                       class="dropdown-input table-field-text table-input-field-dropdown"
                                       button
                                    >
                                       <span
                                          [limbleHtml]="
                                             ordinalTranslator[recurrence.reoccurFld2] ||
                                             ''
                                          "
                                       ></span>
                                       <lim-ui-icon icon="angleDown" iconSize="small" />
                                    </div>
                                    <ng-container menu>
                                       <lim-ui-dropdown-text-item
                                          [displayString]="
                                             lang().firstFollowedByDayOfWeek
                                          "
                                          (click)="recurrence.reoccurFld2 = 'first'"
                                       />
                                       <lim-ui-dropdown-text-item
                                          [displayString]="
                                             lang().secondFollowedByDayOfWeek
                                          "
                                          (click)="recurrence.reoccurFld2 = 'second'"
                                       />
                                       <lim-ui-dropdown-text-item
                                          [displayString]="
                                             lang().thirdFollowedByDayOfWeek
                                          "
                                          (click)="recurrence.reoccurFld2 = 'third'"
                                       />
                                       <lim-ui-dropdown-text-item
                                          [displayString]="
                                             lang().fourthFollowedByDayOfWeek
                                          "
                                          (click)="recurrence.reoccurFld2 = 'fourth'"
                                       />
                                    </ng-container>
                                 </lim-ui-dropdown>
                                 <lim-ui-dropdown>
                                    <div
                                       class="dropdown-input table-field-text table-input-field-dropdown"
                                       button
                                    >
                                       <span
                                          [limbleHtml]="
                                             dayTranslator[recurrence.reoccurFld3] || ''
                                          "
                                       ></span>
                                       <lim-ui-icon icon="angleDown" iconSize="small" />
                                    </div>
                                    <ng-container menu>
                                       @for (day of dayOptions; track day) {
                                          <lim-ui-dropdown-text-item
                                             [displayString]="dayTranslator[day]"
                                             (click)="recurrence.reoccurFld3 = day"
                                          />
                                       }
                                    </ng-container>
                                 </lim-ui-dropdown>
                              </div>
                           }
                           @if (monthFlip === "Numeric") {
                              <div class="day-of-month-container">
                                 {{ lang().onThe }}
                                 <lim-ui-dropdown>
                                    <div
                                       class="dropdown-input table-field-text table-input-field-dropdown"
                                       button
                                    >
                                       <span
                                          [limbleHtml]="
                                             dayNumberTranslator[
                                                recurrence.reoccurFld2
                                             ] || ''
                                          "
                                       ></span>
                                       <lim-ui-icon icon="angleDown" iconSize="small" />
                                    </div>
                                    <ng-container menu>
                                       @for (i of getNumber(31); track $index) {
                                          <lim-ui-dropdown-text-item
                                             [displayString]="
                                                dayNumberTranslator[$index + 1] || ''
                                             "
                                             (click)="recurrence.reoccurFld2 = $index + 1"
                                          />
                                       }
                                    </ng-container>
                                 </lim-ui-dropdown>
                              </div>
                           }
                           <div class="calendar-picker-container-with-dropdowns">
                              <label>{{ lang().StartsOn }}</label>
                              <span
                                 class="options-sub-container calendar-picker-sub-container"
                              >
                                 <lim-ui-date-picker-input
                                    [model]="reoccurSpawnInMilliseconds"
                                    (modelChange)="updateSpawnStart($event)"
                                 />
                                 <span
                                    [ngClass]="{
                                       'no-schedule-end-date':
                                          recurrence.reoccurSpawnEnd === 0 ||
                                          recurrence.reoccurSpawnEnd === null,
                                    }"
                                    limUiTooltip="{{
                                       lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                                    }}"
                                 >
                                    {{ lang().andEndsOn }}
                                 </span>

                                 <lim-ui-date-picker-input
                                    [model]="reoccurSpawnEndInMilliseconds"
                                    (modelChange)="updateSpawnEnd($event)"
                                    [ngClass]="{
                                       'date-picker-unset':
                                          recurrence.reoccurSpawnEnd === 0 ||
                                          recurrence.reoccurSpawnEnd === null,
                                    }"
                                    [showClearIcon]="
                                       !(
                                          recurrence.reoccurSpawnEnd === 0 ||
                                          recurrence.reoccurSpawnEnd === null
                                       )
                                    "
                                    (modelClear)="recurrence.reoccurSpawnEnd = null"
                                 />
                              </span>
                           </div>
                        </div>
                     }
                     @if (monthFlip === "Numeric") {
                        <span
                           class="hint-text"
                           [limbleHtml]="lang().ForMonthsWithLessThanEditScheduleHint"
                        ></span>
                     }
                  </div>
               }
               <lim-ui-radio-button
                  groupID="reoccurType"
                  [label]="lang().Yearly"
                  [checked]="reoccurType === 'year'"
                  (click)="resetDayMonthYear(); setReoccur('year')"
               />

               @if (reoccurType === "year") {
                  <div class="recurrence-option">
                     <div class="options-sub-container recurrence-sub-option-selection">
                        <lim-ui-radio-button
                           groupID="yearly-type"
                           [label]="lang().Ordinal"
                           [checked]="yearFlip === 'Ordinal'"
                           (click)="setYearlyReoccurType('Ordinal')"
                        />
                        <lim-ui-radio-button
                           groupID="monthly-type"
                           [label]="lang().Numeric"
                           [checked]="yearFlip === 'Numeric'"
                           (click)="setYearlyReoccurType('Numeric')"
                        />
                     </div>
                     @if (yearFlip) {
                        <div class="recurrence-sub-option">
                           <div class="day-and-time-container">
                              <div class="options-sub-container">
                                 {{ lang().RepeatEvery }}

                                 <lim-ui-number-input-with-buttons
                                    class="number-input"
                                    [incrementBy]="1"
                                    [(unit)]="recurrence.reoccurFld1"
                                    min="1"
                                    [canEdit]="true"
                                 />

                                 @if (recurrence.reoccurFld1 > 1) {
                                    <span>
                                       {{ lang().years }}
                                    </span>
                                 }
                                 @if (
                                    recurrence.reoccurFld1 === 1 ||
                                    recurrence.reoccurFld1 === 0
                                 ) {
                                    <span>
                                       {{ lang().year }}
                                    </span>
                                 }
                              </div>
                              <div class="options-sub-container">
                                 <span [ngClass]="{ 'time-of-day-disabled': !timeOfDay }">
                                    {{ lang().at }}
                                 </span>
                                 <div>
                                    <time-of-day-picker
                                       [(tod)]="timeOfDay"
                                       [(hour)]="hour"
                                       [(minute)]="minute"
                                       [(ampm)]="AMPM"
                                       [optional]="true"
                                    />
                                 </div>
                              </div>
                           </div>

                           @if (yearFlip === "Ordinal") {
                              <div class="day-of-month-container">
                                 {{ lang().onThe }}
                                 <lim-ui-dropdown>
                                    <div
                                       class="dropdown-input table-field-text table-input-field-dropdown"
                                       button
                                    >
                                       <span
                                          [limbleHtml]="
                                             ordinalTranslator[recurrence.reoccurFld2] ||
                                             ''
                                          "
                                       ></span>
                                       <lim-ui-icon icon="angleDown" iconSize="small" />
                                    </div>
                                    <ng-container menu>
                                       <lim-ui-dropdown-text-item
                                          [displayString]="
                                             lang().firstFollowedByDayOfWeek
                                          "
                                          (click)="recurrence.reoccurFld2 = 'first'"
                                       />
                                       <lim-ui-dropdown-text-item
                                          [displayString]="
                                             lang().secondFollowedByDayOfWeek
                                          "
                                          (click)="recurrence.reoccurFld2 = 'second'"
                                       />
                                       <lim-ui-dropdown-text-item
                                          [displayString]="
                                             lang().thirdFollowedByDayOfWeek
                                          "
                                          (click)="recurrence.reoccurFld2 = 'third'"
                                       />
                                       <lim-ui-dropdown-text-item
                                          [displayString]="
                                             lang().fourthFollowedByDayOfWeek
                                          "
                                          (click)="recurrence.reoccurFld2 = 'fourth'"
                                       />
                                    </ng-container>
                                 </lim-ui-dropdown>
                                 <lim-ui-dropdown>
                                    <div
                                       class="dropdown-input table-field-text table-input-field-dropdown"
                                       button
                                    >
                                       <span
                                          [limbleHtml]="
                                             dayTranslator[recurrence.reoccurFld3] || ''
                                          "
                                       ></span>
                                       <lim-ui-icon icon="angleDown" iconSize="small" />
                                    </div>
                                    <ng-container menu>
                                       @for (day of dayOptions; track day) {
                                          <lim-ui-dropdown-text-item
                                             [displayString]="dayTranslator[day]"
                                             (click)="recurrence.reoccurFld3 = day"
                                          />
                                       }
                                    </ng-container>
                                 </lim-ui-dropdown>
                                 {{ lang().ofStr }}
                                 <lim-ui-dropdown>
                                    <div
                                       class="dropdown-input table-field-text table-input-field-dropdown"
                                       button
                                    >
                                       <span
                                          [limbleHtml]="
                                             monthTranslator[recurrence.reoccurFld4] || ''
                                          "
                                       ></span>
                                       <lim-ui-icon icon="angleDown" iconSize="small" />
                                    </div>
                                    <ng-container menu>
                                       @for (mth of monthOrdinalOptions; track mth) {
                                          <lim-ui-dropdown-text-item
                                             [displayString]="monthTranslator[mth]"
                                             (click)="recurrence.reoccurFld4 = mth"
                                          />
                                       }
                                    </ng-container>
                                 </lim-ui-dropdown>
                              </div>
                           }
                           @if (yearFlip === "Numeric") {
                              <div class="day-of-month-container">
                                 {{ lang().onThe }}
                                 <lim-ui-dropdown>
                                    <div
                                       class="dropdown-input table-field-text table-input-field-dropdown"
                                       button
                                    >
                                       <span
                                          [limbleHtml]="
                                             dayNumberTranslator[
                                                recurrence.reoccurFld3
                                             ] || ''
                                          "
                                       ></span>
                                       <lim-ui-icon icon="angleDown" iconSize="small" />
                                    </div>
                                    <ng-container menu>
                                       @for (i of getNumber(31); track $index) {
                                          <lim-ui-dropdown-text-item
                                             [displayString]="
                                                dayNumberTranslator[$index + 1] || ''
                                             "
                                             (click)="recurrence.reoccurFld3 = $index + 1"
                                          />
                                       }
                                    </ng-container>
                                 </lim-ui-dropdown>
                                 {{ lang().ofStr }}
                                 <lim-ui-dropdown>
                                    <div
                                       class="dropdown-input table-field-text table-input-field-dropdown"
                                       button
                                    >
                                       <span
                                          [limbleHtml]="
                                             monthOptions[recurrence.reoccurFld2 - 1] ||
                                             ''
                                          "
                                       ></span>
                                       <lim-ui-icon icon="angleDown" iconSize="small" />
                                    </div>
                                    <ng-container menu>
                                       @for (mth of monthOptions; track mth) {
                                          <lim-ui-dropdown-text-item
                                             [displayString]="monthOptions[$index]"
                                             (click)="recurrence.reoccurFld2 = $index + 1"
                                          />
                                       }
                                    </ng-container>
                                 </lim-ui-dropdown>
                              </div>
                           }
                           <div class="calendar-picker-container-with-dropdowns">
                              <label>{{ lang().StartsOn }}</label>
                              <span
                                 class="options-sub-container calendar-picker-sub-container"
                              >
                                 <lim-ui-date-picker-input
                                    [model]="reoccurSpawnInMilliseconds"
                                    (modelChange)="updateSpawnStart($event)"
                                 />
                                 <span
                                    [ngClass]="{
                                       'no-schedule-end-date':
                                          recurrence.reoccurSpawnEnd === 0 ||
                                          recurrence.reoccurSpawnEnd === null,
                                    }"
                                    limUiTooltip="{{
                                       lang().SetWhenThisScheduleWillNotMakeMoreSchedules
                                    }}"
                                 >
                                    {{ lang().andEndsOn }}
                                 </span>

                                 <lim-ui-date-picker-input
                                    [model]="reoccurSpawnEndInMilliseconds"
                                    (modelChange)="updateSpawnEnd($event)"
                                    [ngClass]="{
                                       'date-picker-unset':
                                          recurrence.reoccurSpawnEnd === 0 ||
                                          recurrence.reoccurSpawnEnd === null,
                                    }"
                                    [showClearIcon]="
                                       !(
                                          recurrence.reoccurSpawnEnd === 0 ||
                                          recurrence.reoccurSpawnEnd === null
                                       )
                                    "
                                    (modelClear)="recurrence.reoccurSpawnEnd = null"
                                 />
                              </span>
                           </div>
                           @if (yearFlip === "Numeric") {
                              <span
                                 class="hint-text"
                                 [limbleHtml]="
                                    lang().ForMonthsWithLessThanEditScheduleHint
                                 "
                              ></span>
                           }
                        </div>
                     }
                  </div>
               }

               <span
                  class="radio-and-badge-container"
                  [limUiPopover]="startMeterThreshholdPMPopover"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'top'"
                  [limUiPopoverHidden]="featureMeterThresholdPM"
               >
                  <lim-ui-radio-button
                     groupID="reoccurType"
                     [label]="lang().NumberOfUnits"
                     [disabled]="!featureMeterThresholdPM"
                     [ngClass]="{ 'grey-out': !featureMeterThresholdPM }"
                     [checked]="reoccurType === 'everyX'"
                     (click)="setEveryXReoccurType(recurrence)"
                     [limUiTooltip]="lang().EveryXNumberOfUnitsTooltip"
                  />
                  @if (!featureMeterThresholdPM) {
                     <lim-ui-badge
                        class="pricing-plan-badge"
                        [label]="lang().PremiumPlus"
                        icon="stars"
                     />
                  }
               </span>
               <ng-template #startMeterThreshholdPMPopover>
                  <upsell-popover
                     [message]="lang().FeatureMeterThresholdPMPopoverMessage"
                  />
               </ng-template>
               @if (reoccurType === "everyX") {
                  <interval-settings
                     [recurrence]="recurrence"
                     (fieldValue)="recurrence.reoccurFld1 = $event.valueID.toString()"
                     (interval)="recurrence.reoccurFld2 = $event"
                     (start)="recurrence.reoccurFld3 = $event"
                  />
               }
               <div
                  class="radio-and-badge-container"
                  [limUiPopover]="startMeterThreshholdPMPopoverTwo"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'top'"
                  [limUiPopoverHidden]="featureMeterThresholdPM"
               >
                  <lim-ui-radio-button
                     [limUiTooltip]="lang().ThresholdTooltip"
                     [disabled]="!featureMeterThresholdPM"
                     [ngClass]="{ 'grey-out': !featureMeterThresholdPM }"
                     groupID="reoccurType"
                     [label]="lang().Threshold"
                     [checked]="reoccurType === 'threshold'"
                     (click)="setThresholdType(recurrence)"
                  />
                  @if (!featureMeterThresholdPM) {
                     <lim-ui-badge
                        class="pricing-plan-badge"
                        [label]="lang().PremiumPlus"
                        icon="stars"
                     />
                  }
               </div>
               <ng-template #startMeterThreshholdPMPopoverTwo>
                  <upsell-popover
                     [message]="lang().FeatureMeterThresholdPMPopoverMessage"
                  />
               </ng-template>
               @if (reoccurType === "threshold") {
                  <threshold-settings
                     [recurrence]="recurrence"
                     (fieldValue)="recurrence.reoccurFld1 = $event.valueID.toString()"
                     (constraintType)="recurrence.reoccurFld3 = $event"
                     (constraintValue1)="recurrence.reoccurFld2 = $event"
                     (constraintValue2)="recurrence.reoccurFld5 = $event"
                  />
               }
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-primary-button (click)="submit()" dataLogLabel="managePMs-storeAPMSchedule">
         {{ lang().Save }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
