<ng-container *translate="let t">
   <div class="data-viewer">
      @if (status() === "pending") {
         <div class="data-viewer__loading">
            <div class="data-viewer__loading-text">{{ t("Loading") }}...</div>
            <lim-ui-loading-animation class="data-viewer__loading-spinner" />
         </div>
      } @else if (status() === "error") {
         <no-search-results [msg]="t('NoMatchingResultsFound')" />
      } @else if (data() && data().length === 0) {
         <no-search-results [msg]="t('NoMatchingResultsFound')" />
      } @else {
         <e-table
            [columns]="columns()"
            [data]="data()"
            (sortChange)="onSort($event)"
            class="data-viewer__table"
         />
         <div class="data-viewer__footer">
            <e-data-viewer-paginator
               class="data-viewer__paginator"
               [totalItems]="totalItems()"
               [page]="page()"
               [showPageSizeSelect]="true"
               (changePageNumber)="onPageChange($event)"
               (changePageSize)="onPageSizeChange($event)"
            />
         </div>
      }
   </div>
</ng-container>
