<div class="table-container">
   <div class="listTable lim-ui-responsive-table">
      @if (!noHeaders) {
         <div
            [ngClass]="{
               'listItem listItemHeader modal-no-margin': isInModal,
               'listItem listItemHeader': !isInModal,
               'table-scrollable-page-header': tableInScrollablePage,
            }"
            class="lim-ui-responsive-table-header scrollable-list-header"
            [hidden]="noSearchResults || tasks?.length === 0"
         >
            @if (columns && columns.length > 0) {
               @for (column of columns; track column) {
                  @if (
                     column.key === "checklistName" ||
                     column.key === "taskNameWithoutAsset"
                  ) {
                     <div class="col-md-{{ column.columnWidth }} task-name-header">
                        <sort-column
                           column="priorityID"
                           iconName="circleExclamation"
                           [bind]="sort()"
                           (bindChange)="onSortChanged($event)"
                        />

                        <sort-column
                           column="statusID"
                           iconName="circleNotch"
                           [bind]="sort()"
                           (bindChange)="onSortChanged($event)"
                        />

                        <sort-column
                           column="taskType"
                           name="{{ lang().Type }}"
                           [bind]="sort()"
                           (bindChange)="onSortChanged($event)"
                        />

                        <sort-column
                           column="checklistName"
                           name="{{ lang().Name }}"
                           [bind]="sort()"
                           (bindChange)="onSortChanged($event)"
                        />

                        @if (allowSortUnreadComments) {
                           <sort-column
                              column="numUnreadComments"
                              iconName="comment"
                              [bind]="sort()"
                              (bindChange)="onSortChanged($event)"
                              [placement]="'bottom'"
                              limUiTooltip="{{ lang().UnreadComments }}"
                           />
                        }
                     </div>
                  }
                  @if (
                     column.key !== "checklistName" &&
                     column.key !== "taskNameWithoutAsset"
                  ) {
                     <sort-column
                        class="col-md-{{ column.columnWidth }}"
                        column="{{ column.sortBy }}"
                        name="{{ column.displayName }}"
                        [bind]="sort()"
                        (bindChange)="onSortChanged($event)"
                        [ngStyle]="{
                           'text-align': column.textAlign ? column.textAlign : 'left',
                        }"
                        [hidden]="noSearchResults || !tasks || tasks.length === 0"
                     />
                  }
               }
            }
         </div>
      }

      @if (isLoading) {
         <br /><ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
         <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
         <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
         <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
         <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
         <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '25px' }" />

         <div class="text-center">
            <ngx-skeleton-loader count="1" [theme]="{ width: '30%', height: '20px' }" />
         </div>
      } @else if (noSearchResults || tasks?.length === 0) {
         <no-search-results />
      } @else {
         <lim-ui-scroll-container
            class="tasks-container"
            [ngClass]="{ 'modal-height': isInModal || autoHeight }"
            [thin]="true"
         >
            @if (tasks && columns) {
               @for (task of tasks; track task; let isLast = $last) {
                  <div class="table-row-container">
                     <task-data-viewer-item
                        [ngClass]="[
                           isInModal ? 'listItem modal-no-margin' : 'listItem',
                           isLast ? 'last' : '',
                        ]"
                        [ngStyle]="{
                           opacity: isPlaceholderData ? '0.5' : '1',
                        }"
                        class="lim-ui-responsive-table-row"
                        [task]="task"
                        [columns]="columns"
                        [globalSearch]="true"
                        [searchHint]="searchHints.get(task.checklistID)"
                        [part]="part"
                        (tasksUpdated)="tasksUpdated.emit()"
                     />
                  </div>
               }
            }
         </lim-ui-scroll-container>
      }
   </div>

   <data-viewer-paginator
      class="table-footer"
      [ngClass]="{
         'pagination-container': !tableInScrollablePage,
         'modal-pagination': isInModal || autoHeight,
      }"
      [totalItems]="totalTasks"
      [page]="dataViewerState.page()"
      [pageSize]="dataViewerState.pageSize()"
      (pageChange)="onPageChange($event)"
      (pageSizeChange)="onPageSizeChange($event)"
   />
</div>
