import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

/*
 * Replaces a given string abbreviation with the appropriate month from lang
 * Takes a string as an input and returns a string as output
 * Usage:
 *   string | fullMonth
 */

@Pipe({
   name: "fullMonth",
   standalone: true,
})
export class FullMonthPipe implements PipeTransform {
   private readonly manageLang = inject(ManageLang);

   public transform(inputIn) {
      let input = inputIn;
      if (input !== undefined) {
         const lang = this.manageLang.lang() ?? {};

         switch (input) {
            case "jan":
               input = lang.January;
               break;
            case "feb":
               input = lang.February;
               break;
            case "mar":
               input = lang.March;
               break;
            case "apr":
               input = lang.April;
               break;
            case "may":
               input = lang.May;
               break;
            case "jun":
               input = lang.June;
               break;
            case "jul":
               input = lang.July;
               break;
            case "aug":
               input = lang.August;
               break;
            case "sep":
               input = lang.September;
               break;
            case "oct":
               input = lang.October;
               break;
            case "nov":
               input = lang.November;
               break;
            case "dec":
               input = lang.December;
               break;
            default:
         }

         return input;
      }
      return input;
   }
}
