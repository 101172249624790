import { computed, inject, Injectable } from "@angular/core";
import { ModalService } from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import { Subject } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { GatherText } from "src/app/shared/components/global/gatherTextModal/gatherText.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import type { CheckOutEntry, CheckOutRequest } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Comment } from "src/app/tasks/types/comment/comment.types";
import { CredService } from "src/app/users/services//creds/cred.service";
import { ManageProfile } from "src/app/users/services//manageProfile";
import { ManageUser } from "src/app/users/services//manageUser";

@Injectable({ providedIn: "root" })
export class ManageTool {
   private readonly axios = axios;
   public readonly canBeCheckedOut$: Subject<{
      assetID: number;
      canCheckOutAsTool: boolean;
   }>;
   public readonly checkOutRequestUpdate$: Subject<{
      assetID: number;
      checkOutRequest: CheckOutRequest;
   }>;
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageTask = inject(ManageTask);
   private readonly manageProfile = inject(ManageProfile);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.canBeCheckedOut$ = new Subject();
      this.checkOutRequestUpdate$ = new Subject();
   }

   public async getCheckOutDataForAsset(
      assetID: number,
   ): Promise<Array<CheckOutRequest> | false> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "getCheckOutDataForAsset",
         },
         data: {
            assetID: assetID,
         },
      });
      if (!post.data.success) {
         return false;
      }
      const checkOutData = this.mapCheckOutDataToCheckOutRequest(
         post.data.checkOutData,
         post.data.requestSentToUserID,
         post.data.requestSentToProfileID,
      );
      return checkOutData;
   }

   public async getIndexedCheckOutDataForListOfAssets(assetIDs: Array<number>) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "getCheckOutDataForListOfAssets",
         },
         data: {
            assetIDs: assetIDs,
         },
      });
      const checkOutInfoMappedToAssetID = new Map();
      if (!post.data.success) {
         return checkOutInfoMappedToAssetID;
      }
      for (const key in post.data.checkOutInfoIndexedByAssetID) {
         const { checkOutData, requestSentToProfileID, requestSentToUserID } =
            post.data.checkOutInfoIndexedByAssetID[key];
         const mappedCheckOutData = this.mapCheckOutDataToCheckOutRequest(
            checkOutData,
            requestSentToUserID,
            requestSentToProfileID,
         );
         mappedCheckOutData.sort((itemOne, itemTwo) => {
            return itemTwo.requestDate - itemOne.requestDate;
         });
         checkOutInfoMappedToAssetID.set(Number(key), mappedCheckOutData);
      }
      return checkOutInfoMappedToAssetID;
   }

   private mapCheckOutDataToCheckOutRequest(
      checkOutData,
      requestSentToUserID?,
      requestSentToProfileID?,
   ): Array<CheckOutRequest> {
      const checkOutRequests = checkOutData.map((item): CheckOutRequest => {
         return {
            checkOutID: Number(item.checkOutID),
            requestedByUserID: Number(item.requestedByUserID),
            requestDate: Number(item.requestDate) * 1000,
            requestDecisionDate: Number(item.requestDecisionDate) * 1000,
            requestDeniedComment: item.requestDeniedComment,
            requestApproved: Boolean(Number(item.requestApproved)),
            requestApprovalUserID: Number(item.requestApprovalUserID),
            checkInDate: Number(item.checkInDate ?? 0) * 1000,
            checkedInByUserID: Number(item.checkedInByUserID),
            checkOutComment: item.checkOutComment,
            requestCancelledDate: Number(item.requestCancelledDate) * 1000,
            requestSentToUserID: Number(requestSentToUserID),
            requestSentToProfileID: Number(requestSentToProfileID),
            checklistID: Number(item.checklistID),
         };
      });
      return checkOutRequests;
   }

   private createCheckOutEntriesFromCheckOutRequest(
      checkOutRequest: CheckOutRequest,
   ): Array<CheckOutEntry> {
      const entries: Array<CheckOutEntry> = [];
      const {
         requestDate,
         checkOutComment,
         requestedByUserID,
         requestApprovalUserID,
         requestDecisionDate,
         requestApproved,
         requestDeniedComment,
         requestCancelledDate,
         checkInDate,
         checkedInByUserID,
         checklistID,
      } = checkOutRequest;

      if (requestApproved && !requestDecisionDate) {
         entries.push(
            this.createCheckOutEntry(
               requestDate,
               `${this.lang().ToolCheckedOut}: '${checkOutComment}'`,
               requestedByUserID,
               checklistID,
            ),
         );
      } else {
         entries.push(
            this.createCheckOutEntry(
               requestDate,
               `${this.lang().RequestMadeToCheckOutTool}: '${checkOutComment}'`,
               requestedByUserID,
               checklistID,
            ),
         );
      }

      if (requestDecisionDate && requestApprovalUserID) {
         const entry = requestApproved
            ? this.lang().RequestApproved
            : `${this.lang().RequestDenied}: '${requestDeniedComment}'`;

         entries.push(
            this.createCheckOutEntry(requestDecisionDate, entry, requestApprovalUserID),
         );
      }

      if (requestCancelledDate) {
         entries.push(
            this.createCheckOutEntry(
               requestCancelledDate,
               this.lang().CheckOutRequestCancelled,
               requestedByUserID,
            ),
         );
      }

      if (checkInDate && checkedInByUserID) {
         entries.push(
            this.createCheckOutEntry(
               checkInDate,
               this.lang().ToolCheckedIn,
               checkedInByUserID,
            ),
         );
      }
      return entries;
   }

   private createCheckOutEntry(
      date: number,
      entry: string,
      userID: number,
      checklistID?: number,
   ): CheckOutEntry {
      return {
         date: date,
         entry: entry,
         user: this.manageUser.getUserFullName(userID),
         checklistID: checklistID,
      };
   }

   public formatCheckOutDataForAssetToLog(
      checkOutData: Array<CheckOutRequest>,
   ): Array<CheckOutEntry> {
      const checkOutEntryArrayOfArrays: Array<Array<CheckOutEntry>> = [];
      checkOutData.forEach((item) => {
         const entries = this.createCheckOutEntriesFromCheckOutRequest(item);
         checkOutEntryArrayOfArrays.push(entries);
      });
      const checkOutEntries = checkOutEntryArrayOfArrays.reduce(
         (acc, cur) => acc.concat(cur),
         [],
      );
      checkOutEntries.sort((itemOne, itemTwo) => {
         return itemTwo.date - itemOne.date;
      });
      return checkOutEntries;
   }

   public async createCheckOutRequest(
      assetID: number,
      locationID: number,
      checklistID?: number,
   ): Promise<
      | {
           newEntry: CheckOutEntry;
           fullRequest: CheckOutRequest;
           taskNote: Comment | undefined;
        }
      | false
   > {
      const bypassApproval =
         this.credService.isAuthorized(
            locationID,
            this.credService.Permissions.BypassToolCheckOutApproval,
         ) || !this.manageAsset.getAsset(assetID)?.checkOutRequiresApproval;

      const userID = this.manageUser.getCurrentUser().userInfo.userID;

      const requestSentToName = this.getCheckOutRequestAssignmentName(assetID);
      const requiresApproval =
         this.manageAsset.getAsset(assetID)?.checkOutRequiresApproval;

      const requestSentToMsg = requiresApproval
         ? `<br/> ${this.lang().UserOrTeamRequestsAreSentTo}: <b>${requestSentToName}</b>`
         : "";
      const instance = this.modalService.open(GatherText);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().PleaseEnterTheReasonYouAreCheckingThisToolOut}. ${requestSentToMsg}`,
            message2: false,
            title: this.lang().ToolCheckOut,
            warning: false,
            data: {
               currentText: "",
               buttonText: false,
            },
         },
      };
      const checkOutComment = await instance.result;

      if (checkOutComment === 0) {
         return false;
      }

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "createCheckOutRequest",
         },
         data: {
            assetID: assetID,
            userID: userID,
            checkOutComment: checkOutComment,
            checklistID: checklistID,
            bypassApproval: bypassApproval,
         },
      });

      if (!post.data.success) {
         if (post.data.reason === "checkOutAlreadyRequested") {
            this.handleAlreadyCheckedOut(post.data);
         } else if (post.data.reason === "cannotCheckOutAsTool") {
            this.alertService.addAlert(
               this.lang().CannotCheckOutAsToolMsg,
               "warning",
               6000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 4000);
         }
         return false;
      }

      const {
         checkOutID,
         requestDate,
         requestSentToProfileID,
         requestSentToUserID,
         requestApproved,
         taskNote,
      } = post.data.checkOutInfo;
      const fullRequest: CheckOutRequest = {
         checkOutID: Number(checkOutID),
         requestedByUserID: Number(userID),
         requestDate: Number(requestDate) * 1000,
         checkOutComment: checkOutComment,
         requestSentToProfileID: Number(requestSentToProfileID),
         requestSentToUserID: Number(requestSentToUserID),
         requestApproved: Boolean(requestApproved),
      };
      const newEntry = this.createCheckOutEntriesFromCheckOutRequest(fullRequest)[0];

      this.checkOutRequestUpdate$.next({
         assetID: assetID,
         checkOutRequest: fullRequest,
      });
      return {
         newEntry,
         fullRequest,
         taskNote: taskNote,
      };
   }

   public async reSendCheckOutRequest(
      toolAssetID: number,
      checkOutID: number,
      checklistID: number | undefined,
   ): Promise<void> {
      const requestSentToName = this.getCheckOutRequestAssignmentName(toolAssetID);
      const instance = this.modalService.open(GatherText);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().PleaseEnterAMessageToTheUserApprovingThisRequest}. <br/> ${this.lang().UserOrTeamRequestsAreSentTo}: <b>${requestSentToName}</b>`,
            message2: false,
            title: this.lang().ReRequest,
            warning: false,
            data: {
               currentText: "",
               buttonText: false,
            },
         },
      };
      const message = await instance.result;
      if (message === 0) {
         return;
      }
      const userID = this.manageUser.getCurrentUser().userInfo.userID;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "reSendCheckOutRequest",
         },
         data: {
            toolAssetID: toolAssetID,
            message: message,
            userID: userID,
            checkOutID: checkOutID,
            checklistID: Number(checklistID),
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         return;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 4000);
   }

   public async getSingleCheckOutRequestData(checkOutID: number): Promise<
      | {
           requestApprovedByUser: string;
           requestedByUserName: string;
           assetID: number;
           locationName: string;
           assetName: string;
           checkOutRequest: CheckOutRequest;
           approvalUserID: number;
           approvalProfileID: number;
           locationID: number;
        }
      | false
   > {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "getSingleCheckOutRequestData",
         },
         data: {
            checkOutID: checkOutID,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         return false;
      }
      return {
         requestApprovedByUser: post.data.requestData.requestApprovalUserFullName,
         requestedByUserName: `${post.data.requestData.userFirstName} ${post.data.requestData.userLastName}`,
         assetID: Number(post.data.approvalUserAndAssetInfo.assetID),
         locationName: post.data.approvalUserAndAssetInfo.locationName,
         assetName: post.data.approvalUserAndAssetInfo.assetName,
         approvalUserID: Number(post.data.approvalUserAndAssetInfo.userID),
         approvalProfileID: Number(post.data.approvalUserAndAssetInfo.profileID),
         locationID: Number(post.data.approvalUserAndAssetInfo.locationID),
         checkOutRequest: {
            requestDate: Number(post.data.requestData.requestDate) * 1000,
            requestApproved: Boolean(Number(post.data.requestData.requestApproved)),
            requestDecisionDate: Number(post.data.requestData.requestDecisionDate) * 1000,
            requestDeniedComment: post.data.requestData.requestDeniedComment,
            checkInDate: Number(post.data.requestData.checkInDate),
            checkOutComment: post.data.requestData.checkOutComment,
            requestCancelledDate: Number(post.data.requestData.requestCancelledDate),
            requestedByUserID: Number(post.data.requestData.requestedByUserID),
            checkOutID: Number(post.data.requestData.checkOutID),
            checklistID: Number(post.data.requestData.checklistID),
         },
      };
   }

   public async approveCheckOut(checkOutID: number): Promise<boolean> {
      const userID = this.manageUser.getCurrentUser().userInfo.userID;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "approveCheckOut",
         },
         data: {
            checkOutID: checkOutID,
            userID: userID,
         },
      });
      if (!post.data.success) {
         if (post.data.reason === "requestCancelled") {
            this.alertService.addAlert(
               this.lang().CantApproveAfterCancelMsg,
               "warning",
               6000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 4000);
         }
         return false;
      }
      return true;
   }

   public async denyCheckOut(
      checkOutID: number,
      requestDeniedComment: string,
   ): Promise<boolean> {
      const userID = this.manageUser.getCurrentUser().userInfo.userID;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "denyCheckOut",
         },
         data: {
            checkOutID: checkOutID,
            requestDeniedComment: requestDeniedComment,
            userID: userID,
         },
      });
      if (!post.data.success) {
         if (post.data.reason === "requestCancelled") {
            this.alertService.addAlert(
               this.lang().CantDenyAfterCancelMsg,
               "warning",
               6000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 4000);
         }
         return false;
      }
      return true;
   }

   public async checkToolIn(
      checkOutID: number,
      userID: number,
   ): Promise<{ entry: CheckOutEntry; taskNote: Comment | undefined } | false> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "checkToolIn",
         },
         data: {
            checkOutID: checkOutID,
            userID: userID,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 4000);
         return false;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 4000);
      const checkOutRequestArray = this.mapCheckOutDataToCheckOutRequest([
         post.data.checkOutRequest,
      ]);
      this.checkOutRequestUpdate$.next({
         assetID: post.data.checkOutRequest.assetID,
         checkOutRequest: checkOutRequestArray[0],
      });
      return {
         entry: {
            date: Number(post.data.checkOutRequest.checkInDate) * 1000,
            entry: this.lang().ToolCheckedIn,
            user: this.manageUser.getUserFullName(userID),
         },
         taskNote: post.data.taskNote,
      };
   }

   public async updateCheckOutRequestAssignment(
      locationID: number,
      userID: number,
      profileID: number,
      multiUsers: Array<number>,
      assetID?: number | null,
      updateLocation?: boolean,
      updateAll?: boolean,
   ): Promise<{ userID: number; profileID: number } | false> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "updateCheckOutRequestAssignment",
         },
         data: {
            locationID: locationID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsers,
            assetID: assetID,
            updateLocation: updateLocation,
            updateAll: updateAll,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         return false;
      }
      if (multiUsers.length) {
         this.manageFilters.updateHiddenProfiles(
            {
               profileID: post.data.profileID,
               name: post.data.hiddenProfileDescription,
               locationID: locationID,
               multiUsers: multiUsers ?? [],
            },
            this.manageTask,
            this.manageUser,
            this.manageProfile,
         );
      }
      if (updateLocation) {
         const location = this.manageLocation.getLocation(locationID);
         assert(location, "Location not found");
         location.defaultCheckOutApprovalUserID = userID;
         location.defaultCheckOutApprovalProfileID = post.data.profileID;
      } else if (assetID) {
         const asset = this.manageAsset.getAsset(assetID);
         assert(asset);
         asset.checkOutApprovalUserID = userID;
         asset.checkOutApprovalProfileID = Number(post.data.profileID);
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 4000);
      return {
         userID: userID,
         profileID: profileID,
      };
   }

   private getCheckOutRequestAssignmentName(assetID: number): string | false {
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         return false;
      }
      const { checkOutApprovalUserID, checkOutApprovalProfileID, locationID } = asset;
      if (Number(checkOutApprovalUserID) || Number(checkOutApprovalProfileID)) {
         return this.manageUser.getUserOrProfileDisplay(
            checkOutApprovalUserID,
            checkOutApprovalProfileID,
         );
      }
      const { defaultCheckOutApprovalUserID, defaultCheckOutApprovalProfileID } =
         this.manageLocation.getLocation(locationID) ?? {
            defaultCheckOutApprovalUserID: null,
            defaultCheckOutApprovalProfileID: null,
         };
      return this.manageUser.getUserOrProfileDisplay(
         defaultCheckOutApprovalUserID ?? undefined,
         defaultCheckOutApprovalProfileID ?? undefined,
      );
   }

   public async getTaskTools(
      checklistID: number,
      onlyCheckedOutTools?: boolean,
   ): Promise<Array<{ mostRecentRequest: CheckOutRequest; tool }> | undefined> {
      if (!checklistID) {
         return undefined;
      }
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "getTaskTools",
         },
         data: {
            checklistID: Number(checklistID),
            onlyCheckedOutTools: onlyCheckedOutTools,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
      }
      return post.data.associatedAssetData
         .map((item): { mostRecentRequest: CheckOutRequest | null; tool } => {
            const asset = this.manageAsset.getAsset(item.assetID);
            if (asset === undefined) {
               return { mostRecentRequest: null, tool: undefined };
            }
            const mostRecentRequest = item.requestData;
            if (!mostRecentRequest) {
               return { mostRecentRequest: null, tool: asset };
            }
            return {
               mostRecentRequest: {
                  checkOutID: Number(mostRecentRequest.checkOutID),
                  requestedByUserID: Number(mostRecentRequest.requestedByUserID),
                  requestDate: Number(mostRecentRequest.requestDate) * 1000,
                  requestDecisionDate:
                     Number(mostRecentRequest.requestDecisionDate) * 1000,
                  requestDeniedComment: mostRecentRequest.requestDeniedComment,
                  requestApproved: Boolean(Number(mostRecentRequest.requestApproved)),
                  checkOutComment: mostRecentRequest.checkOutComment,
                  requestApprovalUserID: Number(mostRecentRequest.requestApprovalUserID),
                  checkInDate: Number(mostRecentRequest.checkInDate ?? 0) * 1000,
                  checklistID: Number(mostRecentRequest.checklistID),
                  requestCancelledDate: Number(mostRecentRequest.requestCancelledDate),
                  requestSentToProfileID: Number(item.requestSentToProfileID),
                  requestSentToUserID: Number(item.requestSentToUserID),
               },
               tool: asset,
            };
         })
         .filter((item) => item.tool?.canCheckOutAsTool);
   }

   public getAllTools(): Array<Record<string, any>> {
      return Array.from(
         this.manageAsset
            .getAssets()
            .filter(
               (item) =>
                  Number(item.canCheckOutAsTool) === 1 && Number(item.assetDeleted) === 0,
            ),
      );
   }

   public async associateToolsWithTask(
      checklistID: number,
      selectedToolAssetIDs: Array<number>,
   ): Promise<Array<{ mostRecentRequest: CheckOutRequest | null; tool }> | false> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "associateToolsWithTask",
         },
         data: {
            checklistID: checklistID,
            selectedToolAssetIDs: selectedToolAssetIDs,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         return false;
      }
      if (post.data.alreadyAssociatedIDs.length) {
         const assetNames = post.data.alreadyAssociatedIDs.reduce(
            (acc, currentID, index, array) => {
               const asset = this.manageAsset.getAsset(currentID);
               if (!asset) {
                  return acc;
               }
               const locationName = this.manageLocation.getLocation(
                  asset.locationID,
               )?.locationName;
               if (index === array.length - 1) {
                  return `${acc} ${asset.assetName} - ${locationName}`;
               }
               return `${acc} ${asset.assetName} - ${locationName}; `;
            },
            "",
         );
         const msg = `${this.lang().ToolAlreadyAssociatedMsg}: ${assetNames}`;
         this.alertService.addAlert(msg, "warning", 10000);
      } else {
         this.alertService.addAlert(this.lang().successMsg, "success", 4000);
      }

      return post.data.taskToolsData.map((item) => {
         const asset = this.manageAsset.getAsset(item.assetID);
         assert(asset);
         const mostRecentRequest = item.requestData;
         if (!mostRecentRequest) {
            return { mostRecentRequest: null, tool: asset };
         }
         return {
            tool: asset,
            mostRecentRequest: {
               checkOutID: Number(mostRecentRequest.checkOutID),
               requestedByUserID: Number(mostRecentRequest.requestedByUserID),
               requestDate: Number(mostRecentRequest.requestDate) * 1000,
               requestDecisionDate: Number(mostRecentRequest.requestDecisionDate) * 1000,
               requestDeniedComment: mostRecentRequest.requestDeniedComment,
               requestApproved: Boolean(Number(mostRecentRequest.requestApproved)),
               requestApprovalUserID: Number(mostRecentRequest.requestApprovalUserID),
               checkInDate: mostRecentRequest.checkInDate
                  ? Number(mostRecentRequest.checkInDate) * 1000
                  : 0,
               checklistID: Number(mostRecentRequest.checklistID),
               requestCancelledDate: Number(mostRecentRequest.requestCancelledDate),
            },
         };
      });
   }

   public async removeToolFromTask(
      toolAssetID: number,
      checklistID: number,
   ): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "removeToolFromTask",
         },
         data: {
            toolAssetID: toolAssetID,
            checklistID: checklistID,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         return false;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 4000);
      return true;
   }

   public async updateCanCheckOutAsTool(assetID: number): Promise<number | false> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "updateCanCheckOutAsTool",
         },
         data: {
            assetID: assetID,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         return false;
      }
      const newSetting = post.data.newSetting;

      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         return false;
      }
      asset.canCheckOutAsTool = newSetting;

      this.alertService.addAlert(this.lang().successMsg, "success", 4000);

      this.canBeCheckedOut$?.next({ assetID: assetID, canCheckOutAsTool: newSetting });

      return Number(newSetting);
   }

   public async updateCheckOutRequiresApproval(assetID: number): Promise<number | false> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "updateCheckOutRequiresApproval",
         },
         data: {
            assetID: assetID,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         return false;
      }
      const newSetting = post.data.newSetting;
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         return false;
      }
      asset.checkOutRequiresApproval = newSetting;

      this.alertService.addAlert(this.lang().successMsg, "success", 4000);
      return Number(newSetting);
   }

   private handleAlreadyCheckedOut({ requestedByUserID }) {
      const requestedByUserName = this.manageUser.getUserFullName(requestedByUserID);
      const message = `${this.lang().WhoopsLooksLike} ${requestedByUserName} ${this.lang().hasAlreadyRequestedToCheckOutThisTool}.`;
      this.alertService.addAlert(message, "warning", 10000);
   }

   public async confirmCheckIn(): Promise<boolean> {
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ConfirmCheckInToolMsg,
            title: `${this.lang().CheckInTool}?`,
         },
      };
      const confirmed = await instance.result;

      return Boolean(confirmed);
   }

   public async cancelRequest(
      checkOutID: number,
   ): Promise<{ entry: CheckOutEntry; taskNote: Comment | undefined } | false> {
      const userID = this.manageUser.getCurrentUser().userInfo.userID;
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageTool.php",
         params: {
            action: "cancelRequest",
         },
         data: {
            checkOutID: checkOutID,
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         return false;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 4000);
      const checkOutRequestArray = this.mapCheckOutDataToCheckOutRequest([
         post.data.checkOutRequest,
      ]);
      this.checkOutRequestUpdate$.next({
         assetID: post.data.checkOutRequest.assetID,
         checkOutRequest: checkOutRequestArray[0],
      });
      return {
         entry: this.createCheckOutEntry(
            Number(post.data.checkOutRequest.requestCancelledDate) * 1000,
            this.lang().CheckOutRequestCancelled,
            userID,
         ),
         taskNote: post.data.taskNote,
      };
   }

   public determineToolStatus(
      checkOutRequest: CheckOutRequest,
   ): "requestPending" | "checkedOut" | "checkedIn" {
      if (!checkOutRequest) {
         return "checkedIn";
      }
      const { requestApproved, checkInDate, requestCancelledDate, requestDecisionDate } =
         checkOutRequest;

      if (!requestApproved && !requestDecisionDate && !requestCancelledDate) {
         return "requestPending";
      }

      if (
         requestApproved &&
         !checkInDate &&
         (!requestCancelledDate || !requestDecisionDate)
      ) {
         return "checkedOut";
      }

      return "checkedIn";
   }
}
