<div class="table">
   @let rows = tableData();
   @defer (when rows !== undefined; prefetch on immediate) {
      @if (rows !== undefined) {
         <div class="header-row">
            <div class="header-cell checkbox-column">
               <lim-ui-checkbox
                  [model]="selectedSuggestions().length === rows.length"
                  (modelChange)="selectAll($event)"
               />
            </div>
            <div class="header-cell task-column">
               <div class="header-cell-title clickable" (click)="sort('name')">
                  <lim-ui-icon [icon]="getSortIcon('name')" />
                  Task
               </div>
            </div>
            <div class="header-cell asset-column">
               <div class="header-cell-title clickable" (click)="sort('asset')">
                  <lim-ui-icon [icon]="getSortIcon('asset')" />
                  Asset
               </div>
            </div>
            <div class="header-cell document-column">
               <div class="header-cell-title clickable" (click)="sort('document')">
                  <lim-ui-icon [icon]="getSortIcon('document')" />
                  Document
               </div>
            </div>
            <div class="header-cell schedules-column">
               <div class="header-cell-title clickable" (click)="sort('schedules')">
                  <lim-ui-icon [icon]="getSortIcon('schedules')" />
                  Schedules
               </div>
            </div>
            <div class="header-cell actions-column">
               <div class="header-cell-title">Actions</div>
            </div>
         </div>
         @for (row of rows; track row.id) {
            <div class="row">
               <div class="cell checkbox-column">
                  <lim-ui-checkbox
                     [model]="selectedSuggestions().includes(row.id)"
                     (modelChange)="select(row.id, $event)"
                  />
               </div>
               <div class="cell task-column">
                  {{ row.name }}
               </div>
               <div class="cell asset-column">
                  @defer (when assets.value() !== undefined; prefetch on immediate) {
                     @if (row.asset === undefined) {
                        ERROR: Asset not found
                     } @else {
                        <asset-hierarchy-list-item
                           [asset]="row.asset"
                           [showIcon]="true"
                        />
                     }
                  } @placeholder {
                     <ngx-skeleton-loader [theme]="tableCellSkeletonTheme" />
                  }
               </div>
               <div class="cell document-column">{{ row.document }}</div>
               <div class="cell schedules-column">{{ row.schedules }}</div>
               <div class="cell actions-column">
                  <div class="actions">
                     <lim-ui-outlined-button
                        [disabled]="assets.value() === undefined"
                        (click)="createPmTemplate(row.id)"
                     >
                        <div class="create-pm-button-text">Create PM Template</div>
                     </lim-ui-outlined-button>
                     <lim-ui-outlined-button
                        [isIconButton]="true"
                        (click)="deleteSuggestion(row.id)"
                        limUiTooltip="Delete PM Suggestion"
                     >
                        <lim-ui-icon icon="trashCanRegular" />
                     </lim-ui-outlined-button>
                  </div>
               </div>
            </div>
         }
      }
   } @placeholder {
      <div class="table-skeleton">
         <ngx-skeleton-loader [theme]="tableRowSkeletonTheme" />
         <ngx-skeleton-loader [theme]="tableRowSkeletonTheme" />
         <ngx-skeleton-loader [theme]="tableRowSkeletonTheme" />
      </div>
   }
</div>
@let pageSizeLocal = pageSize();
@let totalLocal = total.value();
<div class="table-options">
   <div class="bulk-actions">
      <lim-ui-outlined-button
         [disabled]="selectedSuggestions().length === 0"
         (click)="deleteSelectedSuggestions()"
      >
         <div class="bulk-action-button">
            <lim-ui-icon icon="trashCanRegular" />
            Delete Selected
         </div>
      </lim-ui-outlined-button>
   </div>
   <div class="pagination">
      @defer (
         when pageSizeLocal !== undefined && totalLocal !== undefined;
         prefetch on idle
      ) {
         @if (pageSizeLocal !== undefined && totalLocal !== undefined) {
            <data-viewer-paginator
               [totalItems]="totalLocal"
               [areResultsPerPageEnabled]="true"
               [(page)]="page"
               [pageSize]="pageSizeLocal"
               data-test-id="pm-suggestions-paginator"
            />
         }
      }
   </div>
   <div class="feedback">
      <!-- TODO(WRENCH-274): I just threw this in as a placeholder. -->
      Are these results helpful?
      <div class="feedback-buttons">
         <lim-ui-outlined-button [isIconButton]="true"> X </lim-ui-outlined-button>
         <lim-ui-outlined-button [isIconButton]="true"> X </lim-ui-outlined-button>
      </div>
   </div>
</div>
