import { Component, computed, effect, inject, input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   IconComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   SearchAllWrapperComponent,
   TextButtonComponent,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AssetAssociatedPartComponent } from "src/app/assets/components/asset-modal/components/asset-associated-part/asset-associated-part.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import {
   type AddPartModalData,
   PartsFacadeService,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { PartsDataAccumulatorService } from "src/app/parts/components/shared/services/associated-parts-data-accumulator/associated-parts-data-accumulator.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import {
   DataViewerSearchComponent,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "asset-associated-parts",
   templateUrl: "./asset-associated-parts.component.html",
   styleUrls: ["./asset-associated-parts.component.scss"],
   providers: [DataViewerStateService, PartsDataAccumulatorService],
   imports: [
      PanelComponent,
      IconComponent,
      FormsModule,
      PrimaryButtonComponent,
      SearchAllWrapperComponent,
      NoSearchResults,
      TextButtonComponent,
      DataViewerSearchComponent,
      AssetAssociatedPartComponent,
      NgxSkeletonLoaderModule,
   ],
})
export class AssetAssociatedPartsComponent {
   public readonly assetID = input.required<number>();

   protected readonly manageLang = inject(ManageLang);
   protected readonly manageAsset = inject(ManageAsset);
   private readonly credService = inject(CredService);
   protected readonly alertService = inject(AlertService);
   private readonly partsFacadeService = inject(PartsFacadeService);
   private readonly manageAssociations = inject(ManageAssociations);
   public readonly manageParts = inject(ManageParts);
   public readonly modalService = inject(ModalService);
   private readonly manageUtil = inject(ManageUtil);

   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);

   readonly #state = inject(DataViewerStateService);

   protected readonly associatedPartsDataAccumulator = inject(
      PartsDataAccumulatorService,
   );

   protected readonly associatedPartsSignal =
      this.associatedPartsDataAccumulator.dataSignal;
   protected readonly selectedItems = this.associatedPartsDataAccumulator.selectedItems;
   protected readonly total = this.associatedPartsDataAccumulator.total;

   protected readonly isLoading = computed(() => {
      return this.associatedPartsDataAccumulator.isLoading();
   });

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly noSearchResults = computed(() => {
      const requestOptions = this.#state?.requestOptions();
      const searchTerm = requestOptions?.search;
      return searchTerm?.length && this.total() === 0;
   });

   protected readonly asset = computed<Asset | undefined>(() => {
      const assetID = this.assetID();
      if (!assetID) {
         return undefined;
      }
      return this.manageAsset.getAsset(assetID);
   });

   protected readonly skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

   public constructor() {
      effect(() => {
         this.#state.setBaseFilters([
            {
               assetIDs: [this.assetID()],
            },
         ]);
      });

      this.#state.setExtraColumns("assetPartQuantities");
      this.#state.setSort({
         field: "partName",
         direction: "asc",
      });
   }

   public async associatePartsToAsset(): Promise<void> {
      const asset = this.asset();
      if (!asset) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            asset.locationID,
            this.credService.Permissions.ChangePartAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred78Fail, "danger", 10000);
         return;
      }

      const modalData: AddPartModalData = {
         title: this.lang().PickParts,
         buttonText: this.lang().Select,
         dataLogOptions: {
            taskStoreLabel: "manageParts-storeAnAssociation",
         },
         locationID: asset.locationID,
      };

      const parts = await this.partsFacadeService.openAddPartModal(modalData);

      if (parts.length > 0) {
         const partIDs = parts.map((part) => part.partID);
         this.manageAssociations.associatePartsToAsset(partIDs, asset).then((answer) => {
            if (answer.data.success === true) {
               this.associatedPartsDataAccumulator.refreshDataAccumulated();
               this.alertService.addAlert(
                  this.lang().PartsSuccessfullyAssociated,
                  "success",
                  2000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
      }
   }

   protected showMore() {
      this.associatedPartsDataAccumulator.fetch();
   }
   protected onSearchChange(search: string) {
      this.#state.setSearch(search);
   }
}
