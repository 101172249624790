import { Injectable, inject } from "@angular/core";
import { firstValueFrom } from "rxjs";
import {
   AnomalyApiService,
   type AnomalyCheckResponse,
} from "src/app/tasks/components/shared/services/anomaly-api";
import type { AnomalyState } from "src/app/tasks/types/anomaly/anomaly.types";

@Injectable({ providedIn: "root" })
export class AnomalyDetectionService {
   private readonly anomalyApiService = inject(AnomalyApiService);

   public async isAnomaly(
      assetID: number,
      fieldID: number,
      nextValue: number,
   ): Promise<AnomalyCheckResponse> {
      if (!nextValue || !assetID || !fieldID) {
         return {
            isAnomaly: false,
         };
      }

      return firstValueFrom(
         this.anomalyApiService.checkForAnomaly({
            assetID,
            fieldID,
            nextValue,
         }),
      );
   }

   public async updateAnomalyState(anomalyID: number | null): Promise<AnomalyState> {
      if (!anomalyID) {
         return undefined;
      }

      const anomalyInfo = await this.anomalyApiService.getAnomaly(anomalyID);
      if (!anomalyInfo) {
         return undefined;
      }

      if (anomalyInfo.correctedAt) {
         return "corrected";
      }

      if (anomalyInfo.acknowledgedAt) {
         return "confirmed";
      }

      if (anomalyInfo && !anomalyInfo.acknowledgedAt && !anomalyInfo.correctedAt) {
         return "detected";
      }

      return undefined;
   }
}
