@if (asset) {
   <lim-ui-panel
      [showWatermark]="false"
      [noSidePadding]="true"
      [useCustomTitle]="true"
      [showHeaderDivider]="true"
      id="assetVendorsPrint{{ asset.assetID }}"
   >
      <div class="asset-vendors-panel-header" panelCustomTitle>
         <span>
            <lim-ui-icon icon="addressCard" />
            {{ lang().AssociatedVendors }}
         </span>

         <lim-ui-primary-button (click)="associateVendorsToAsset()">
            {{ lang().AssociateVendors }}
         </lim-ui-primary-button>
      </div>

      @if (isLoading) {
         <ngx-skeleton-loader count="3" [theme]="skeletonThemes.fillSize" /><br />
      } @else {
         <div class="vendor-list">
            @for (vendor of vendorsInfo | orderBy: "vendorName"; track vendor.vendorID) {
               <div class="lim-ui-list-item">
                  <div class="lim-ui-list-item-content">
                     @if (vendor.locationName) {
                        <span class="vendor-information">
                           <span class="vendor-breadcrumbs">
                              <lim-ui-icon icon="addressCard" class="vendor-icon" />

                              <span
                                 class="vendor-name"
                                 (click)="popVendor(vendor.vendorID)"
                                 [limbleHtml]="vendor?.vendorName"
                              >
                              </span>
                              -
                              <lim-ui-icon icon="houseChimney" />
                              {{ vendor.locationName }}
                           </span>
                        </span>
                     }
                     <span class="row-icons">
                        @if (vendor.associationType === AssociationType.Automatic) {
                           <lim-ui-icon
                              placement="left"
                              limUiTooltip="{{ lang().VendorAssetAutoAssociationTip }}"
                              icon="wandMagicSparkles"
                           />
                        }
                        <lim-ui-minimal-icon-button
                           (click)="deleteAssetVendorAssociation(vendor.vendorID)"
                           placement="left"
                           limUiTooltip="{{ lang().RemoveAssociation }}"
                           icon="trashCanRegular"
                           iconColor="danger"
                           [superMinimal]="true"
                        />
                     </span>
                  </div>
               </div>
            }
         </div>
      }
   </lim-ui-panel>
}
