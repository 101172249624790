import { OfflinePrepService } from "./src/app/lite/local-db/offline-prep.service";

export function getWorker() {
   if (typeof Worker === "undefined") {
      throw new Error("Workers are not supported in this environment");
   }
   return new Worker(
      new URL("./src/app/lite/local-db/offline-prep.worker.ts", import.meta.url),
      /* @vite-ignore */
      {
         type: "module",
         name: OfflinePrepService.WORKER_NAME,
      },
   );
}
