import { Component } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";

interface TimeSpentCellData {
   timeSpentSecs?: number;
}

@Component({
   selector: "task-time-spent-cell-renderer",
   imports: [BetterDecimalPipe, TranslateDirective],
   templateUrl: "./time-spent-cell-renderer.component.html",
})
export class TimeSpentCellRendererComponent extends BaseCellRendererComponent<TimeSpentCellData> {
   protected get timeSpentHours(): number {
      return (this.cell?.timeSpentSecs ?? 0) / 60 / 60;
   }
}
