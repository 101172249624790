import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   IconButtonComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   ProgressBarComponent,
   ScrollContainerComponent,
   SecondaryButtonComponent,
   SliderComponent,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import Cookies from "js-cookie";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PrintDivDirective } from "src/app/assets/directives/printDiv/printDiv.directive";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { QRCodesCustomizePrint } from "src/app/shared/components/global/qrCodeCustomizePrintModal/qrCodesCustomizePrint.modal.component";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { ElementsToImages } from "src/app/shared/services/elementsToImages";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";
import QRCode from "src/assets/js/qrcode";

@Component({
   selector: "qr-codes-view-asset",
   templateUrl: "./qrCodesViewAsset.modal.component.html",
   styleUrls: ["./qrCodesViewAsset.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      IconButtonComponent,
      SecondaryButtonComponent,
      SliderComponent,
      FormsModule,
      ReactiveFormsModule,
      PanelComponent,
      LoadingAnimationComponent,
      ProgressBarComponent,
      ScrollContainerComponent,
      ModalFooterComponent,
      DropdownComponent,
      DropdownTextItemComponent,
      PrimaryButtonComponent,
      PrintDivDirective,
      BetterDecimalPipe,
   ],
})
export class QRCodesViewAsset implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public locationID;
   public assets: Lookup<"assetID", Asset> = new Lookup("assetID");
   public assetsLength;
   public loading;
   public index: number = 0;
   protected zoomFormControl = new FormControl(50);
   public startingAsset;
   public errorMsg;
   public started;
   public assetsExtraInfo: Map<
      number,
      { locationName: string; reportProblemURL: string; lookupAssetURL: string }
   > = new Map();

   private readonly manageUser = inject(ManageUser);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUtil = inject(ManageUtil);
   private readonly elementsToImages = inject(ElementsToImages);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.zoomFormControl.valueChanges.subscribe((value) => {
         if (!this.started) {
            this.runWatch();
         }
         const date = new Date();
         date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
         Cookies.set("assetSliderValue1", String(value), { expires: date, path: "/" });
      });
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.startingAsset = this.resolve.asset;
      this.locationID = this.startingAsset.locationID;
      this.errorMsg = "";
      this.started = false;

      this.assets.set(this.startingAsset.assetID, this.startingAsset);
      this.assetsLength = this.assets.size;

      this.loading = true;
      this.buildQRCodes(this.startingAsset);
      this.loading = false;

      const sliderCookieValue = Cookies.get("assetSliderValue1");
      if (sliderCookieValue) {
         this.zoomFormControl.setValue(Number(sliderCookieValue));
      }
   }

   private getZoomValue(): number {
      return this.zoomFormControl.value ?? 50;
   }

   public linkToVideo(title, videoId, startAt) {
      this.manageUtil.watchVideo({
         title,
         src: `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&feature=shared&start=${startAt}`,
      });
   }

   showMoreThenOne = () => {
      const preselectedAssetIDs = Array.from(this.assets).map((item) =>
         Number(item.assetID),
      );

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.lang().PickAssetsQRCodeMsg;
      instance.title = this.lang().PickAssets;
      instance.singleLocation = this.startingAsset.locationID;
      instance.selectOne = false;
      instance.restrictToCred = true;
      instance.iDontKnowOption = false;
      instance.preselectedAssetIDs = preselectedAssetIDs;

      modalRef.result.then((assets) => {
         if (assets.length > 0) {
            const assetsTemp = assets;

            setTimeout(() => {
               this.loading = true;
               this.assetsLength = assetsTemp.length;
               this.index = 0;

               setTimeout(() => {
                  this.assets = new Lookup("assetID");
                  for (const asset of assetsTemp) {
                     const obj = this.manageAsset.getAsset(asset.assetID);
                     if (obj) {
                        this.assets.set(asset.assetID, obj);
                     }

                     this.buildQRCodes(asset);
                  }

                  this.assets = this.assets.orderBy("assetName");
                  this.loading = false;
               }, 300);
            }, 100);
         }
      });
   };

   buildQRCodes = (asset: Asset) => {
      this.index = this.index + 1;
      const barcode = this.manageUser.getCurrentUser().userInfo.customerBarcodeStr;
      const host = `${window.location.protocol}//${window.location.host}`;
      const locationName =
         this.manageLocation.getLocation(asset.locationID)?.locationName ?? "";

      setTimeout(() => {
         const reportProblemURL = `${host}/problem/${barcode}/${asset.locationID}/${asset.assetID}`;
         const lookupAssetURL = `${host}/mobileAssets/${asset.assetID}/${asset.locationID}?m=true`;
         this.assetsExtraInfo.set(asset.assetID, {
            locationName,
            reportProblemURL,
            lookupAssetURL,
         });

         $(`#qrcodeReportProblem${asset.assetID}`).empty();
         // eslint-disable-next-line no-new -- we don't have control over this library
         new QRCode(document.getElementById(`qrcodeReportProblem${asset.assetID}`), {
            text: reportProblemURL,
            width: 400 * (this.getZoomValue() / 100),
            height: 400 * (this.getZoomValue() / 100),
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
         });

         $(`#qrcodeAssetDiag${asset.assetID}`).empty();
         // eslint-disable-next-line no-new -- we don't have control over this library
         new QRCode(document.getElementById(`qrcodeAssetDiag${asset.assetID}`), {
            text: lookupAssetURL,
            width: 400 * (this.getZoomValue() / 100),
            height: 400 * (this.getZoomValue() / 100),
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
         });

         $(".resizeable-text").css("font-size", `${this.getZoomValue() / 3}px`);
         $(".qrCodeContainer").css("width", `${this.getZoomValue() * 10}px`);
      }, 200);
   };

   runWatch = () => {
      this.started = true;
      setTimeout(() => {
         for (const asset of this.assets) {
            this.buildQRCodes(asset);
         }
         this.started = false;
      }, 1000);
   };

   close = () => {
      this.modalInstance.close(false);
   };

   downloadAll = () => {
      const elements = new Map();
      for (const asset of this.assets) {
         const element1 = document.getElementById(
            `qrCodeContainerProblem${asset.assetID}`,
         );
         const element2 = document.getElementById(`qrCodeContainerDiag${asset.assetID}`);
         const name = asset.assetName?.replace(/[^a-z0-9]/gi, "_").toLowerCase();
         if (element1) {
            elements.set(`${name}-${asset.assetID}-problem`, element1);
         }
         if (element2) {
            elements.set(`${name}-${asset.assetID}-lookup`, element2);
         }
      }
      this.elementsToImages.run(elements).then(this.elementsToImages.zipAndDownload);
   };

   downloadUrls = (flag) => {
      const featureAssetTools = Boolean(
         this.manageUser.getCurrentUser().userInfo.featureAssetTools,
      );
      if (flag === "selected") {
         this.manageAsset.downloadAssets(this.assets, true, false, featureAssetTools);
      } else if (flag === "all") {
         const assets = this.manageAsset
            .getAssets()
            .filter(
               (asset) =>
                  asset.locationID === this.locationID && asset.assetDeleted === 0,
            );

         this.manageAsset.downloadAssets(assets, true, false, featureAssetTools);
      }
   };

   popQRCodesCustomizePrint = () => {
      const instance = this.modalService.open(QRCodesCustomizePrint);
      instance.componentInstance.data.set(this.assets);
      instance.componentInstance.dataType.set("asset");
   };
}
