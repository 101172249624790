<div class="purchasing-item-list-short-containter">
   <div class="main-short-list-container">
      @for (item of items; track item) {
         <div
            (click)="popPoComponent(item.poID)"
            class="purchasing-item-list-short-container"
         >
            <div class="purchasing-column-badge">{{ item.receivedQty }}</div>
            @if (item.itemType === 1) {
               <lim-ui-icon icon="gears" />
            }
            @if (item.itemType === 2) {
               <lim-ui-icon icon="wpforms" />
            }
            @if (item.itemType === 4) {
               <lim-ui-icon icon="superpowers" />
            }
            <div class="purchasing-item-name">
               <div [limbleHtml]="item.itemName"></div>
               @if (item.itemNumber?.length) {
                  <span>
                     •
                     <span [limbleHtml]="item.itemNumber"></span>
                  </span>
               }
            </div>
         </div>
      }
   </div>
</div>
