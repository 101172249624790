<div class="paginator-container">
   @if (showPageSizeSelect()) {
      <e-page-size-select (changePageSize)="onPageSizeChange($event)" />
   }
   <e-paginator
      [pageLinkSize]="3"
      [showFirstLastIcon]="false"
      [showJumpToPageDropdown]="false"
      [showPageLinks]="true"
      [showCurrentPageReport]="true"
      [currentPageReportTemplate]="currentPageReportTemplate"
      [first]="page()"
      [totalRecords]="totalItems()"
      (pageChange)="onPageChange($event)"
      [alwaysShow]="true"
   />
</div>
