<lim-ui-upsell-modal
   [icon]="icon ?? 'notdef'"
   [iconSize]="iconSize ?? 'extra-large'"
   [iconColor]="iconColor ?? 'success'"
   [title]="title"
   [message]="message"
   [actionText]="actionText"
   [cancelText]="cancelText"
   (actionClickEvent)="openActionLink()"
>
   @if (listType === "enterprise") {
      <ng-container lists>
         @if (daysLeft > 3) {
            <ul>
               <li>{{ lang().MultipleLocations }}</li>
               <li>{{ lang().Parts }}</li>
               <li>{{ lang().Purchasing }}</li>
            </ul>
            <ul>
               <li>{{ lang().Vendors }}</li>
            </ul>
         }
         @if (daysLeft <= 3) {
            <ul>
               @if (numberOfLocations > 1) {
                  <li>
                     <strong>{{ lang().LocationsPluralForUpsellModal }}:</strong>
                     {{ numberOfLocations }} {{ lang().Locations }},
                     {{ lang().including }}
                     <i>{{ locationsString }}</i>
                  </li>
               }
               @if (!numberOfLocations || numberOfLocations <= 0) {
                  <li>
                     <strong>{{ lang().TimeAndCostTracking }}</strong>
                  </li>
               }

               @if (numberOfParts > 1) {
                  <li>
                     <strong>{{ lang().Parts }}</strong> {{ numberOfParts }}
                     {{ lang().Parts }},
                     {{ lang().including }}
                     <i>{{ partsString }}</i>
                  </li>
               }
               @if (!numberOfParts || numberOfParts <= 0) {
                  <li>
                     <strong>{{ lang().DowntimeTrackingAndReporting }}</strong>
                  </li>
               }

               <li>
                  <strong>{{ lang().Purchasing }}</strong>
               </li>
            </ul>
         }
      </ng-container>
   }

   @if (listType === "premium") {
      <ng-container lists>
         @if (daysLeft > 3) {
            <ul>
               <li>{{ lang().Parts }}</li>
               <li>{{ lang().Purchasing }}</li>
               <li>{{ lang().Vendors }}</li>
            </ul>
            <ul>
               <li>{{ lang().TimeAndCostTracking }}</li>
               <li>{{ lang().DowntimeTrackingAndReporting }}</li>
            </ul>
         }
         @if (daysLeft <= 3) {
            <ul>
               @if (numberOfVendors > 1) {
                  <li>
                     <strong>{{ lang().Vendors }}:</strong>
                     {{ numberOfVendors }} {{ lang().Vendors }}, {{ lang().including }}
                     <i>{{ vendorsString }}</i>
                  </li>
               }
               @if (!numberOfVendors || numberOfVendors <= 0) {
                  <li>
                     <strong>{{ lang().TimeAndCostTracking }}</strong>
                  </li>
               }

               @if (numberOfParts > 1) {
                  <li>
                     <strong>{{ lang().Parts }}</strong> {{ numberOfParts }}
                     {{ lang().Parts }},
                     {{ lang().including }}
                     <i>{{ partsString }}</i>
                  </li>
               }
               @if (!numberOfParts || numberOfParts <= 0) {
                  <li>
                     <strong>{{ lang().DowntimeTrackingAndReporting }}</strong>
                  </li>
               }

               <li>
                  <strong>{{ lang().Purchasing }}</strong>
               </li>
            </ul>
         }
      </ng-container>
   }
</lim-ui-upsell-modal>
