import { Component, inject, type OnInit } from "@angular/core";
import { ButtonComponent } from "src/app/shared/empowered/base/button/button.component";
import { MenuComponent } from "src/app/shared/empowered/base/menus/menu/menu.component";
import type { MenuItem } from "src/app/shared/empowered/base/menus/menus.models";
import { BaseCellRendererComponent } from "src/app/shared/empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import { TaskMenuItemsService } from "src/app/tasks/components/shared/services/task-menu-items/task-menu-items.service";

@Component({
   selector: "task-actions-cell-renderer",
   templateUrl: "./task-actions-cell-renderer.component.html",
   styleUrls: ["./task-actions-cell-renderer.component.scss"],
   imports: [ButtonComponent, MenuComponent],
})
export class TaskActionsCellRendererComponent
   extends BaseCellRendererComponent<TaskDataViewerViewModel>
   implements OnInit
{
   private readonly menuItemsService = inject(TaskMenuItemsService);

   public menuItems!: Array<MenuItem>;

   public ngOnInit() {
      this.menuItems = this.menuItemsService.getItems(this.cell);
   }
}
