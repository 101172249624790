import type { FilterBase } from "src/app/dashboards/filters/index";
import type { TaskLookup } from "src/app/tasks/types/task.types";

export class LocationFilter implements FilterBase {
   public readonly filterType = "location" as const;
   public readonly displayString: string;

   public constructor(
      public readonly locationID: number,
      locationName: string,
   ) {
      this.displayString = locationName;
   }

   public filterFunction(tasks: TaskLookup): TaskLookup {
      return tasks.filter((task) => Number(task.locationID) === this.locationID);
   }

   public getValue(): number {
      return this.locationID;
   }
}
