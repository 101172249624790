<div class="lim-ui-list-item">
   <div class="lim-ui-list-item-content tool-container">
      <span class="tool-info">
         <lim-ui-icon icon="screwdriverWrench" iconSize="small" />
         <span class="green-color-link" (click)="popAsset()">
            <b>{{ tool.assetName }}</b> -
            <span>{{ toolLocationName }}</span>
         </span>

         <!-- Show on Mobile -->
         <lim-ui-minimal-icon-button
            class="tool-removal-icon lim-ui-show-on-mobile"
            icon="trashCanRegular"
            iconSize="small"
            iconColor="danger"
            (click)="runRemoveToolFromTask()"
            placement="left"
            limUiTooltip="{{ lang().RemoveThisTool }}"
         />
      </span>

      @if (!taskComplete && instance) {
         <tool-status-info
            [tool]="tool"
            [mostRecentRequest]="mostRecentRequest"
            [createCheckOutRequest]="createCheckOutRequest"
            [checkInTool]="checkInTool"
            [cancelRequest]="cancelRequest"
            [inTaskTool]="true"
            class="tool-status"
         />
      }

      <!-- Hidden on Mobile -->
      <lim-ui-minimal-icon-button
         [superMinimal]="true"
         class="tool-removal-icon lim-ui-hide-on-mobile lim-ui-list-item-hover-buttons"
         icon="trashCanRegular"
         iconSize="small"
         iconColor="danger"
         (click)="runRemoveToolFromTask()"
         placement="left"
         limUiTooltip="{{ lang().RemoveThisTool }}"
      />
   </div>
</div>
