import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

/*
 * adds an appropriate date ending to the string input according the the user's language
 * Takes a number as a string and returns the number as a string with the appropriate date ending
 * Usage:
 *   string | addDateEnding
 */
@Pipe({
   name: "addDateEnding",
   standalone: true,
})
export class AddDateEndingPipe implements PipeTransform {
   private readonly manageLang = inject(ManageLang);

   public transform(inputIn) {
      let input = inputIn;
      if (!(input > 0)) {
         return input;
      }
      if (input !== undefined) {
         const lang = this.manageLang.lang() ?? {};

         switch (Number(input)) {
            case 1:
               input = input + lang.st;
               break;
            case 2:
               input = input + lang.nd;
               break;
            case 3:
               input = input + lang.rd;
               break;
            case 4:
               input = input + lang.th;
               break;
            case 5:
               input = input + lang.th;
               break;
            case 6:
               input = input + lang.th;
               break;
            case 7:
               input = input + lang.th;
               break;
            case 8:
               input = input + lang.th;
               break;
            case 9:
               input = input + lang.th;
               break;
            case 10:
               input = input + lang.th;
               break;
            case 11:
               input = input + lang.th;
               break;
            case 12:
               input = input + lang.th;
               break;
            case 13:
               input = input + lang.th;
               break;
            case 14:
               input = input + lang.th;
               break;
            case 15:
               input = input + lang.th;
               break;
            case 16:
               input = input + lang.th;
               break;
            case 17:
               input = input + lang.th;
               break;
            case 18:
               input = input + lang.th;
               break;
            case 19:
               input = input + lang.th;
               break;
            case 20:
               input = input + lang.th;
               break;
            case 21:
               input = input + lang.st;
               break;
            case 22:
               input = input + lang.nd;
               break;
            case 23:
               input = input + lang.rd;
               break;
            case 24:
               input = input + lang.th;
               break;
            case 25:
               input = input + lang.th;
               break;
            case 26:
               input = input + lang.th;
               break;
            case 27:
               input = input + lang.th;
               break;
            case 28:
               input = input + lang.th;
               break;
            case 29:
               input = input + lang.th;
               break;
            case 30:
               input = input + lang.th;
               break;
            case 31:
               input = input + lang.st;
               break;
            default:
         }

         return input;
      }
      return input;
   }
}
