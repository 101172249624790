import { inject, Injectable } from "@angular/core";
import { assetFieldInitializer } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.initializer";
import { assetFieldSchema } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.schema";
import { AssetFieldStorageService } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.storage.service";
import type { AssetFieldUpdate } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.storage.service.types";
import { refetchValidData } from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { z } from "zod";

@Injectable({
   providedIn: "root",
})
export class AssetFieldStorageSyncService extends StorageSyncService {
   private readonly assetFieldStorageService = inject(AssetFieldStorageService);

   public constructor() {
      super();
   }

   public async syncAssetField(fieldID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = assetFieldInitializer;
         const assetFields = await refetchValidData({
            url,
            params: { fieldIDs: [fieldID] },
            validation: z.array(assetFieldSchema),
         });
         const [assetField] = assetFields;
         assert(
            assetField !== undefined,
            `AssetField #${fieldID} not found in fetch when attempting to sync`,
         );

         await this.assetFieldStorageService.putAssetField(assetField);
      });
   }

   public async syncAssetFieldUpdate(
      fieldID: number,
      update: AssetFieldUpdate,
   ): Promise<void> {
      return this.sync(async () => {
         await this.assetFieldStorageService.updateAssetField(fieldID, update);
      });
   }
}
