<!-- Field Type 1-->
<!--Text Type-->
@if (fieldInfo?.fieldTypeID == 1 && fieldValue) {
   <div>
      @if (deleted == 0 && !restrict && changeFieldsValues) {
         <div
            class="exitOnEnterKeyPress table-field-text input-item"
            [ngClass]="{
               'table-input-field-editable':
                  usedIn === 'table' &&
                  changeFieldsValues &&
                  (fieldValue.valueContent == 0 || fieldValue.valueContent == null),
               'table-input-field-editable no-margin-left growing-field':
                  usedIn === 'listItem' &&
                  changeFieldsValues &&
                  (fieldValue.valueContent == 0 || fieldValue.valueContent == null),
            }"
            [(ngModel)]="fieldValue.valueContent"
            (afterEdit)="setFieldValue(fieldValue, part)"
            [limbleContentEditable]="
               changeFieldsValues && deleted == 0 && !restrict && !fieldLocked
            "
            stripHtml="true"
         ></div>
      }

      @if (deleted == 1 || restrict || !changeFieldsValues) {
         <div class="table-field-text">
            <!-- eslint-disable template/no-any -- No reason provided. This is a legacy disable. -->
            <div [limbleHtml]="$any(fieldValue.valueContent ?? '')"></div>
            <!-- eslint-enable template/no-any-->
         </div>
      }
   </div>
}

<!-- Field Type 2-->
<!--Date Type-->
@if (fieldInfo?.fieldTypeID == 2 && fieldValue) {
   <span
      [ngClass]="{
         'flex-field-wrapper': usedIn === 'listItem',
      }"
   >
      @if (deleted == 0 && !restrict && changeFieldsValues && !fieldLocked) {
         <lim-ui-date-picker-input
            class="table-field-text"
            [ngClass]="{
               'table-date-field':
                  fieldValue.valueContent == null || fieldValue.valueContent === '',
            }"
            [(model)]="fieldValue.valueContent"
            (modelChange)="setFieldValue(fieldValue, part)"
            (modelClear)="clearFieldValue(fieldValue, part)"
         />
      }

      @if (deleted == 1 || restrict || !changeFieldsValues || fieldLocked) {
         <div class="table-field-text">
            {{ fieldValue.valueContent | betterDate: "date" }}
         </div>
      }

      <div class="lim-ui-list-item-hover-button">
         @if (
            fieldValue.valueContent &&
            showDateHint &&
            (fieldInfo?.dateReminder1 != 0 ||
               fieldInfo?.dateReminder2 != 0 ||
               fieldInfo?.dateReminder3 != 0)
         ) {
            <lim-ui-minimal-icon-button
               icon="bellRegular"
               iconSize="small"
               [superMinimal]="true"
               limUiTooltip="{{ dateReminderHint }}"
               placement="bottom"
               (click)="showFieldOptions(fieldValue)"
            />
         }
      </div>
   </span>
}

<!-- Field Type 3-->
<!--Picture Type-->
@if (fieldInfo?.fieldTypeID == 3) {
   <span>
      @if (changeFieldsValues && deleted == 0 && !restrict && !fieldLocked) {
         <file-uploader
            label="{{ list ? '' : lang().UploadPicture }}"
            [uploadObject]="uploadObj"
            icon="camera"
            accept="images"
            buttonType="table"
            class="table-button"
            [ngClass]="{
               uploadListBtnWrap: list,
               redColorImportantWrap:
                  list &&
                  !uploadObj.uploading &&
                  (fieldValue?.partValueFileIDs?.length == 0 ||
                     fieldValue?.partValueFileIDs == null),
            }"
         />
      }

      @if (list) {
         <span limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}">
            @for (fileID of fieldValue?.partValueFileIDs; track fileID) {
               <div>
                  @if (allFiles?.get(fileID); as file) {
                     <div class="hoverContainer3">
                        <lim-ui-icon
                           class="dark-icon"
                           icon="imageRegular"
                           iconSize="small"
                           placement="bottom"
                        />

                        <a class="document-link" (click)="viewImage(file)">
                           {{ file.fileName?.slice(5) }}
                        </a>

                        @if (changeFieldsValues && deleted == 0 && !restrict) {
                           <lim-ui-row-hover-buttons
                              class="delete-document-link-button"
                              (click)="deleteFile(file)"
                              deleteButtonTooltip="{{ lang().Delete }} {{
                                 file.fileName?.slice(5)
                              }}"
                              [showCopyButton]="false"
                              [showDeleteButton]="true"
                           />
                        }
                     </div>
                  }
               </div>
            }
         </span>
      }

      @if (!list) {
         <span>
            <div class="images-container">
               @for (fileID of fieldValue?.partValueFileIDs; track fileID) {
                  <div class="img-holder-medium">
                     @if (allFiles?.get(fileID); as file) {
                        <editable-image
                           [file]="file"
                           [getURL]="fileGetURLs.get(fileID)"
                           [permission]="
                              changeFieldsValues &&
                              deleted == 0 &&
                              !restrict &&
                              !fieldLocked
                           "
                           [edit]="openEditor"
                           holderClass="img-holder-medium"
                           (delete)="deleteFile($event)"
                        />
                     }
                  </div>
               }
            </div>
         </span>
      }
   </span>
}

<!-- Field Type 4-->
<!--File Type-->
@if (fieldInfo?.fieldTypeID == 4) {
   <span>
      @if (changeFieldsValues && deleted == 0 && !restrict && !fieldLocked) {
         <file-uploader
            [ngClass]="{
               uploadListBtnWrap: list,
               redColorImportantWrap:
                  list &&
                  !uploadObj.uploading &&
                  (fieldValue?.partValueFileIDs?.length == 0 ||
                     fieldValue?.partValueFileIDs == null),
            }"
            label="{{ list ? '' : lang().UploadDocument }}"
            [uploadObject]="uploadObj"
            icon="cloudUploadRegular"
            accept="all"
            buttonType="table"
            class="table-button"
         />
      }

      @if (list == true) {
         <div>
            <div class="list-group">
               @for (fileID of fieldValue?.partValueFileIDs; track fileID) {
                  <span class="cursor">
                     @if (allFiles?.get(fileID); as file) {
                        <view-file
                           [file]="file"
                           [getURL]="fileGetURLs.get(fileID)"
                           [permission]="
                              changeFieldsValues &&
                              deleted == 0 &&
                              !restrict &&
                              !fieldLocked
                           "
                           [delete]="deleteFile"
                        />
                     }
                  </span>
               }
            </div>
         </div>
      }

      @if (list != true) {
         <div>
            <div>
               <div class="list-group">
                  @for (fileID of fieldValue?.partValueFileIDs; track fileID) {
                     <span class="list-group-item cursor">
                        @if (allFiles?.get(fileID); as file) {
                           <view-file
                              [file]="file"
                              [getURL]="fileGetURLs.get(fileID)"
                              [permission]="
                                 changeFieldsValues &&
                                 deleted == 0 &&
                                 !restrict &&
                                 !fieldLocked
                              "
                              [delete]="deleteFile"
                           />
                        }
                     </span>
                  }
               </div>
            </div>
         </div>
      }
   </span>
}

<!-- Field Types 5 & 6-->
<!--Number/Currency Type-->
@if (fieldInfo?.fieldTypeID == 5 || fieldInfo?.fieldTypeID == 6) {
   <span>
      @if (
         deleted == 0 && !restrict && changeFieldsValues && fieldValue && !fieldLocked
      ) {
         <span>
            @if (fieldInfo?.fieldTypeID == 6 && list != true) {
               <span class="mobileDisappear">
                  {{ currencySymbol }}
               </span>
            }
            <input
               type="number"
               min="0.00"
               [(ngModel)]="fieldValue.valueContent"
               (blur)="setFieldValue(fieldValue, part)"
               onclick="this.focus();"
               class="table-field-text"
               [ngClass]="{
                  'table-input-field-editable': usedIn === 'table',
                  'table-input-field-editable no-margin-left': usedIn === 'listItem',
               }"
            />
         </span>
      }
      @if (deleted == 1 || restrict || !changeFieldsValues || fieldLocked) {
         <span>
            <div class="table-field-text">
               @if (fieldInfo?.fieldTypeID == 5) {
                  <span> {{ fieldValue?.valueContent }} </span>
               }
               @if (fieldInfo?.fieldTypeID == 6) {
                  <span>
                     {{ fieldValue?.valueContent | betterCurrency }}
                  </span>
               }
            </div>
         </span>
      }
   </span>
}

<!-- Field Types 7-->
<!--Dropdown Type-->
@if (fieldInfo?.fieldTypeID == 7 && fieldValue) {
   <span>
      @if (deleted == 0 && !restrict && changeFieldsValues && !fieldLocked) {
         <lim-ui-form-dropdown-input
            [inputString]="convertToString(fieldValue.valueContent)"
         >
            @if (valueJSON) {
               <div menu>
                  @for (name of valueJSON; track name) {
                     <lim-ui-dropdown-text-item
                        [displayString]="name"
                        (click)="setDropdown(fieldValue, name, false, part)"
                     />
                  }
               </div>
            }
         </lim-ui-form-dropdown-input>
      }

      @if (deleted == 1 || restrict || !changeFieldsValues || fieldLocked) {
         <span>
            <div
               class="table-field-text"
               [limbleHtml]="convertToString(fieldValue.valueContent)"
            ></div>
         </span>
      }
   </span>
}
