<lim-ui-modal>
   <div class="modal-container">
      <div class="sticky-header">
         <div class="modal-header">
            <h2>{{ i18n().t("ReviewAndEditPMTemplate") }}</h2>
            <div class="close-button-container">
               <lim-ui-minimal-icon-button
                  icon="xmarkLargeRegular"
                  iconSize="medium-large"
                  (click)="close()"
               />
            </div>
         </div>
         <pm-builder-wizard-stepper [(stepNumber)]="stepNumber" />
      </div>
      @if (stepNumber() === 1) {
         <task-form-step
            [taskInput]="taskInput()"
            (deleteTemplate)="deleteTemplate()"
            (nextStep)="nextStep()"
         />
      } @else if (stepNumber() === 2 && taskTemplate) {
         <recurrence-step
            [taskTemplate]="taskTemplate"
            [recurrenceID]="recurrenceID"
            (setRecurrenceID)="setRecurrenceID($event)"
            [scheduleSuggestion]="scheduleSuggestion()"
            (recurrenceDeleted)="recurrenceDeleted()"
            (nextStep)="nextStep()"
            (prevStep)="prevStep()"
         />
      } @else if (stepNumber() === 3) {
         <assignment-step
            [locationID]="locationID()"
            (prevStep)="prevStep()"
            (nextStep)="saveAssignmentAndContinue($event)"
            (deleteTemplate)="deleteTemplate()"
         />
      } @else if (stepNumber() === 4) {
         <task-preview-step
            [taskInput]="taskInputPreview()"
            (deleteTemplate)="deleteTemplate()"
            (prevStep)="prevStep()"
            (done)="close()"
         />
      }
   </div>
</lim-ui-modal>
