import { countDescription } from "src/app/parts/unit-of-measure/provided-units/descriptions/count-description";
import { lengthDescription } from "src/app/parts/unit-of-measure/provided-units/descriptions/length-description";
import type { UnitCategoryDescription } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/unit-category-description.types";
import { volumeDescription } from "src/app/parts/unit-of-measure/provided-units/descriptions/volume-description";
import { weightDescription } from "src/app/parts/unit-of-measure/provided-units/descriptions/weight-description";
import type { ToMultiple } from "src/app/shared/types/utility-types";

export const unitCategoryDescriptions: ToMultiple<UnitCategoryDescription> = [
   countDescription,
   lengthDescription,
   volumeDescription,
   weightDescription,
];
