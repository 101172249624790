import { inject, Injectable } from "@angular/core";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { PlanTypeService } from "src/app/shared/external-scripts/util/plan-type.service";
import { UserTypeService } from "src/app/shared/external-scripts/util/user-type.service";
import { ManageUser } from "src/app/users/services//manageUser";

@Injectable({ providedIn: "root" })
export class DatadogService {
   private readonly manageUser = inject(ManageUser);
   private readonly userType = inject(UserTypeService);
   private readonly planType = inject(PlanTypeService);

   public init({ limbleVersion }: { limbleVersion: string }): void {
      if (!this.shouldRecordSessions()) {
         return;
      }
      const url = window.location.href;
      let env;
      if (url.includes("limblecmms.com")) {
         env = "prod";
      } else if (url.includes("dev.limblestaging.com")) {
         env = "dev";
      } else if (url.includes("app.limblestaging.com")) {
         env = "staging";
      } else if (url.includes("devops-app.limbledev.com")) {
         env = "devops";
      }
      datadogRum.init({
         applicationId: "47b211d1-e891-4f2f-8487-c0a1d7c426ab",
         clientToken: "pub9842b0d71db91252dd07e90218131a35",
         site: "datadoghq.com",
         service: "webapp",
         env: env ?? "",
         version: limbleVersion,
         sessionSampleRate: 100,
         sessionReplaySampleRate: 100,
         trackUserInteractions: true,
         trackResources: true,
         trackLongTasks: true,
         defaultPrivacyLevel: "allow",
         allowedTracingUrls: [
            (traceURL) => traceURL.startsWith("https://app.limblecmms.com"),
            (traceURL) => traceURL.startsWith("https://21cfr-app.limblecmms.com"),
            (traceURL) => traceURL.startsWith("https://ca-app.limblecmms.com"),
            (traceURL) => traceURL.startsWith("https://devops-app.limbledev.com"),
            (traceURL) => traceURL.startsWith("https://dev.limblestaging.com"),
            (traceURL) => traceURL.startsWith("https://app.limblestaging.com"),
         ],
         beforeSend: (event: any) => {
            // discard a RUM error if status code is 401, that just means that got timed out of the app.
            //see these docs: https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
            if (
               event?.resource?.status_code === 401 ||
               event?.resource?.status_code === 403
            ) {
               return false;
            }
            return true;
         },
      });
      const currentUser = this.manageUser.getCurrentUser();
      datadogRum.setUser({
         id: currentUser.gUserID,
         customerID: currentUser.userInfo.customerID,
         name: `${currentUser.userInfo.fName} ${currentUser.userInfo.lName}`,
         email: currentUser.userInfo.userEmail,
         username: currentUser.userInfo.userLoginName,
         userType: this.userType.get(),
         userCreatedDate: new Date(
            currentUser.userInfo.userDateAdded * 1000,
         ).toISOString(),
         accountCreatedDate: new Date(
            currentUser.userInfo.customerAgreementDate * 1000,
         ).toISOString(),
         accountConversionDate: new Date(
            currentUser.userInfo.accountConversionDate * 1000,
         ).toISOString(),
         subscriptionType: currentUser.userInfo.subscriptionType,
         userLicenseType: this.manageUser.getCurrentUserLicenseType(),
         customerStatus: currentUser.userInfo.customerStatus,
         customerTier: this.planType.get(),
         leadSource: currentUser.userInfo.leadSource,
      });
      //trigger rum logs
      datadogLogs.init({
         clientToken: "pub9842b0d71db91252dd07e90218131a35",
         site: "datadoghq.com",
         service: "webapp",
         env: env ?? "",
         version: limbleVersion,
         forwardErrorsToLogs: false,
         sessionSampleRate: 100,
      });

      datadogRum.startSessionReplayRecording();
   }

   /** Determines whether user sessions should be logged in our monitoring systems */
   private shouldRecordSessions(): boolean {
      const currentUser = this.manageUser.getCurrentUser();
      return (
         currentUser?.userInfo?.enableLogRocket === 1 &&
         currentUser?.userInfo?.userInternal === 0 &&
         window.location.hostname !== "localhost" &&
         !window.location.hostname.includes("127.0.0.1")
      );
   }
}
