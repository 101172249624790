<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      [title]="title ?? lang().ViewSchedules"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      <!-- message panel -->
      <lim-ui-info-panel>
         <div class="info-panel-with-button">
            <span class="message-area" [limbleHtml]="message"></span>
            <lim-ui-icon-button
               class="mobileDisappear"
               icon="printRegular"
               print-div
               target="printSchedules"
            />
         </div>
      </lim-ui-info-panel>
      <!-- table panel -->
      <lim-ui-panel [noSidePadding]="true">
         @if (loadingBar) {
            <div>
               <div class="loading-icon">
                  <lim-ui-loading-animation />
               </div>
            </div>
         }

         @if (!loadingBar && (!futureTasks || futureTasks.length == 0)) {
            <div class="no-schedules-message">
               <p>
                  <b>{{ lang().ThereAreCurrentlyNoTimeBasedSchedulesSetForThisPM }}</b>
               </p>
            </div>
         }

         <div [hidden]="!(futureTasks && futureTasks.length > 0)" id="printSchedules">
            <div class="listTable" style="width: 100%">
               <div class="listItemHeader">
                  <div class="col-md-3">{{ lang().AssignedTo }}</div>
                  <div class="col-md-2">{{ lang().WillBeCreatedOn }}</div>
                  <div class="col-md-2">{{ lang().WillBeDueOn }}</div>
                  <div class="col-md-4">{{ lang().Information }}</div>
                  <div class="col-md-1"></div>
               </div>

               @for (
                  futureTask of futureTasks
                     | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
                  track futureTask
               ) {
                  <div
                     class="listItem"
                     [ngClass]="{
                        'schedule-disabled': futureTask.isDisabled,
                     }"
                  >
                     <div class="col-md-3">
                        <a
                           class="cursor"
                           [ngClass]="{ disabled: futureTask.isDisabled }"
                           (click)="updateSingleSchedule(futureTask)"
                        >
                           <span> {{ futureTask.profileDescription }} </span>
                           <span>
                              {{ futureTask.userFirstName }} {{ futureTask.userLastName }}
                           </span>
                           <span
                              class="red-color"
                              [hidden]="
                                 !(
                                    futureTask.profileDescription == null &&
                                    futureTask.userFirstName == null
                                 )
                              "
                           >
                              {{ lang().Unassigned }}
                           </span>
                        </a>
                     </div>
                     @if (days !== null) {
                        <div class="col-md-2">
                           {{
                              (futureTask.scheduleStartDate - days * 86400) * 1000
                                 | betterDate: "date"
                           }}
                        </div>
                     }
                     <div class="col-md-2">
                        {{ futureTask.scheduleStartDate * 1000 | betterDate: "date" }}
                     </div>
                     <div class="col-md-4">
                        @if (futureTask.skipScheduleFlag === "deleted") {
                           <span>
                              {{ lang().DeletedBy }}: {{ futureTask.deletedByUserName }}
                           </span>
                        }
                        @if (futureTask.skipScheduleFlag === "skip") {
                           <span>
                              {{ lang().SkippedDueToScheduleSettings }}
                           </span>
                        }
                     </div>
                     <div class="col-md-1 list-last-item">
                        @if (!futureTask.isDisabled) {
                           <lim-ui-icon
                              icon="trashCanRegular"
                              iconSize="small"
                              iconColor="danger"
                              (click)="deleteSingleSchedule(task, futureTask)"
                           />
                        }
                        @if (futureTask.skipScheduleFlag === "deleted") {
                           <span>
                              <a
                                 class="cursor greenColor restore"
                                 (click)="restoreSingleSchedule(futureTask)"
                                 >{{ lang().Restore }}</a
                              >
                           </span>
                        }
                     </div>
                  </div>
               }

               <div class="pagination-wrapper">
                  @if (
                     futureTasks &&
                     futureTasks.length &&
                     futureTasks.length > itemsPerPage
                  ) {
                     <lim-ui-pagination
                        [maxSize]="5"
                        [rotate]="true"
                        [(page)]="page"
                        [pageSize]="itemsPerPage"
                        [collectionSize]="futureTasks.length"
                     />
                  }
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer />
</lim-ui-modal>
