import type { DocTutorial } from "src/app/onboarding/services/tutorials/doc-tutorial";
import type { Tutorial } from "src/app/onboarding/services/tutorials/tutorial";
import type { VideoTutorial } from "src/app/onboarding/services/tutorials/video-tutorial";

export interface TutorialSectionData {
   title: string;
   description: string;
   tutorials: Array<VideoTutorial | DocTutorial>;
   limbleRepName: string;
   limbleRepPhotoURL: string;
}

export class TutorialSection {
   public title: string = "";
   public description: string = "";
   public tutorials: Tutorial[] = [];
   public limbleRepName: string = "";
   public limbleRepPhotoURL: string = "";

   public constructor(data: TutorialSectionData) {
      Object.assign(this, data);
   }

   public getVideoTutorials(): VideoTutorial[] {
      return this.tutorials.filter((tutorial) => tutorial.isVideo) as VideoTutorial[];
   }

   public getDocTutorials(): DocTutorial[] {
      return this.tutorials.filter((tutorial) => tutorial.isDoc) as DocTutorial[];
   }

   public getTutorialByName(tutorialName: string): Tutorial | undefined {
      return this.tutorials.find((tutorial) => tutorial.name === tutorialName);
   }

   public getVideoTutorialByName(tutorialName: string): VideoTutorial | undefined {
      return this.getVideoTutorials().find((tutorial) => tutorial.name === tutorialName);
   }

   public getDocTutorialByName(tutorialName: string): DocTutorial | undefined {
      return this.getDocTutorials().find((tutorial) => tutorial.name === tutorialName);
   }
}
