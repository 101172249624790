<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="lim-ui-form-column">
            <div class="lim-ui-form-group">
               <label>{{ lang().Name }}</label>
               <div>
                  <div
                     type="text"
                     [(ngModel)]="name"
                     disableEditor="true"
                     limbleContentEditable
                  ></div>
               </div>
            </div>
            <div class="lim-ui-form-group">
               <label>
                  {{ lang().AutoFillWith }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     iconSize="small"
                     limUiTooltip="{{ lang().AutoFillWithVendorInfoTooltip }}"
                  />
               </label>
               <a (click)="linkField()">
                  {{ fieldName }}
               </a>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Save"
      (successClick)="submit()"
   />
</lim-ui-modal>
