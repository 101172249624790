import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   IconComponent,
   InputWithPrefixComponent,
   ModalService,
   LimbleHtmlDirective,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import {
   PartsFacadeService,
   type AddPartModalData,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import type { PartVendorRelation } from "src/app/parts/types/vendors-relations/vendor-relation.types";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import type { PartLeadTime } from "src/app/shared/services/leadTimeCalculation.service";
import { LeadTimeCalculationService } from "src/app/shared/services/leadTimeCalculation.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "vendor-parts",
   templateUrl: "./vendorParts.wrapper.component.html",
   styleUrls: ["./vendorParts.wrapper.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      LimbleHtmlDirective,
      IconComponent,
      FormsModule,
      InputWithPrefixComponent,
      TooltipDirective,
      PrimaryButtonComponent,
      CurrencySymbolPipe,
   ],
})
export class VendorParts implements OnInit, OnDestroy {
   @Input() public vendor: Vendor | undefined;
   protected readonly i18n = inject(TranslationService).i18n;
   public zeroManualPartsMsg;
   public zeroUsedPartsMsg;
   public manualParts: Array<
      Partial<
         Part & {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
            currencyCode: string;
         }
      >
   > = [];
   public usedParts: Array<
      Partial<
         Part & {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
            POs?: any;
         }
      >
   > = [];

   public partRelationOldValues: Map<
      number,
      { oldPartNumberValue: string | null; oldPartPriceValue: number | null }
   > = new Map();

   public partDisplayInfo: Map<number, { locationName: string; showDetails: boolean }> =
      new Map();
   public loading;
   public readonly currencySymbol: string;

   public partVendorRelationsSub: Subscription;

   private readonly manageParts = inject(ManageParts);
   private readonly managePO = inject(ManagePO);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUser = inject(ManageUser);
   private readonly partsFacadeService = inject(PartsFacadeService);
   private readonly manageLang = inject(ManageLang);
   private readonly leadTimeService = inject(LeadTimeCalculationService);
   private readonly launchFlagsService = inject(LaunchFlagsService);
   protected readonly isPartLeadTimeEnabled = this.launchFlagsService.getFlag<boolean>(
      Flags.TRACK_PART_VENDOR_LEAD_TIME,
      false,
   );

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly credChangePartPrice = computed(() =>
      this.credService.isAuthorized(
         this.vendor?.locationID ?? 0,
         this.credService.Permissions.ChangePartPrice,
      ),
   );
   protected readonly credChangePartNumber = computed(() =>
      this.credService.isAuthorized(
         this.vendor?.locationID ?? 0,
         this.credService.Permissions.ChangePartNumber,
      ),
   );

   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   public constructor() {
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      this.partVendorRelationsSub =
         this.manageAssociations.partVendorRelationUpdates$.subscribe((newRelation) => {
            const oldValue = this.partRelationOldValues.get(newRelation.partID);
            const partAssociation = this.manualParts.find(
               (relation) => relation.partID === newRelation.partID,
            );
            const partAssociatonIndex = this.manualParts.findIndex(
               (relation) => relation.partID === newRelation.partID,
            );
            if (!oldValue || !partAssociation) {
               return;
            }
            this.manualParts[partAssociatonIndex] = {
               ...partAssociation,
               ...newRelation,
            };
         });
   }

   public ngOnInit() {
      this.zeroManualPartsMsg = this.lang().zeroManualPartsMsg;
      this.zeroUsedPartsMsg = this.lang().zeroUsedPartsMsg;

      this.buildPartsAssociations();
   }

   public ngOnDestroy(): void {
      this.partVendorRelationsSub.unsubscribe();
   }

   protected updatePartPrice(
      $event: any,
      partAssociation: Partial<
         Part & {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
         }
      >,
   ) {
      partAssociation.partPrice = Number($event);
   }

   buildPartsAssociations = () => {
      this.manualParts = [];
      this.usedParts = [];
      this.partDisplayInfo = new Map();

      assert(this.vendor);

      const { parts, partAssociations } =
         this.manageAssociations.getAssociatedPartsForVendor(
            this.vendor.vendorID,
            this.managePO.getPurchaseOrderItemsByVendorID(this.vendor.vendorID),
         );

      let leadTimes: PartLeadTime[] = [];
      if (this.isPartLeadTimeEnabled()) {
         this.leadTimeService
            .getLeadTimeForVendor(this.vendor.vendorID)
            .subscribe((leadtime) => {
               leadTimes = leadtime ?? [];
            });
      }
      for (const part of parts) {
         this.partRelationOldValues.set(part.partID, {
            oldPartNumberValue: null,
            oldPartPriceValue: null,
         });
         const association = partAssociations.get(part.partID);
         if (association?.manualRelation) {
            const leadTime = leadTimes?.find(
               (item) => item.partID === part.partID,
            )?.averageLeadTime;

            if (leadTime !== null && leadTime !== undefined) {
               const strLeadTime = `${leadTime} ${this.i18n().t(leadTime === 1 ? "day" : "days")}`;
               association.partVendorAvgLeadTime = strLeadTime;
            }

            this.manualParts.push({ ...part, ...association });
            const locationName =
               this.manageLocation.getLocation(part.locationID)?.locationName ?? "";
            this.partDisplayInfo.set(part.partID, { locationName, showDetails: false });
         }
         if (association?.automaticRelation) {
            if (!association.tmpPoItemID) {
               continue;
            }
            const purchaseOrderItem = this.managePO.getPurchaseOrderItem(
               association.tmpPoItemID,
            );

            if (!purchaseOrderItem?.poID || !purchaseOrderItem.partID) {
               continue;
            }

            const purchaseOrderItemVendorID = this.managePO.getPurchaseOrder(
               purchaseOrderItem.poID,
            )?.vendorID;

            if (
               purchaseOrderItemVendorID === this.vendor.vendorID &&
               purchaseOrderItem?.partID > 0
            ) {
               const POs: Array<PurchaseOrderItem> = [];
               const itemsByPartID = this.managePO.getPurchaseOrderItemsByPartID(
                  part.partID,
               );
               if (!itemsByPartID) {
                  continue;
               }

               for (const partPurchaseOrderItem of itemsByPartID) {
                  if (!partPurchaseOrderItem.poID) {
                     continue;
                  }

                  const partVendorID = this.managePO.getPurchaseOrder(
                     partPurchaseOrderItem.poID,
                  )?.vendorID;
                  if (partVendorID === this.vendor.vendorID) {
                     POs.push(partPurchaseOrderItem);
                  }
               }
               this.usedParts.push({ ...part, POs });
               const locationName =
                  this.manageLocation.getLocation(part.locationID)?.locationName ?? "";
               this.partDisplayInfo.set(part.partID, {
                  locationName,
                  showDetails: false,
               });
            }
         }
      }
   };

   popPoComponent = (poID) => {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   };

   public popPart(
      partAssociation: Partial<
         Part & {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
         }
      >,
   ) {
      const instance = this.modalService.open(PopPart);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: partAssociation.partID,
            locationID: partAssociation.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   public async updateNumberToVendorAssociation(
      partRelation: Partial<
         Part & {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
         }
      >,
      vendor: any,
   ): Promise<void> {
      if (!partRelation.partID) {
         return;
      }
      const oldValues = this.partRelationOldValues.get(partRelation.partID);

      if (!oldValues) {
         return;
      }

      if (oldValues.oldPartNumberValue === partRelation.partNumber) {
         return;
      }

      const updatedNumberValue = String(partRelation.partNumber);
      const hasManualAssociation = await this.validateManualAssociation(
         partRelation,
         vendor,
      );
      if (!hasManualAssociation) {
         return;
      }
      const newVendorRelation = this.getSelectedPartVendorRelation(
         Number(partRelation.partID),
         vendor.vendorID,
      );

      newVendorRelation.partNumber = updatedNumberValue;

      oldValues.oldPartNumberValue = updatedNumberValue;
      const answer = await this.manageAssociations.updateNumberToVendorAssociation(
         vendor.vendorID,
         partRelation.partID,
         updatedNumberValue,
      );

      if (answer?.data.success === true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   private getSelectedPartVendorRelation(partID: number, vendorID: number): any {
      const vendorRelations: Array<PartVendorRelation> = Array.from(
         this.manageAssociations.getPartVendorRelations(),
      );
      return vendorRelations.find(
         (vendorRelation) =>
            vendorRelation.vendorID === vendorID && vendorRelation.partID === partID,
      );
   }

   public async updatePriceToVendorAssociation(
      partRelation: Partial<
         Part & {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
         }
      >,
      vendor: any,
   ): Promise<void> {
      if (!partRelation.partID) {
         return;
      }
      const oldValues = this.partRelationOldValues.get(partRelation.partID);

      if (!oldValues) {
         return;
      }

      if (oldValues.oldPartPriceValue === partRelation.partPrice) {
         return;
      }
      const updatedPriceValue = Number(partRelation.partPrice);
      const hasManualAssociation = await this.validateManualAssociation(
         partRelation,
         vendor,
      );
      if (!hasManualAssociation) {
         return;
      }

      const newVendorRelation = this.getSelectedPartVendorRelation(
         partRelation.partID,
         vendor.vendorID,
      );

      newVendorRelation.partPrice = updatedPriceValue;
      oldValues.oldPartPriceValue = updatedPriceValue;

      const answer = await this.manageAssociations.updatePriceToVendorAssociation(
         vendor.vendorID,
         partRelation.partID,
         updatedPriceValue,
      );
      if (answer?.data.success === true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   private async validateManualAssociation(
      partRelation: Partial<
         Part & {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
         }
      >,
      vendor: any,
   ): Promise<boolean> {
      if (
         partRelation.manualRelation !== undefined &&
         partRelation.manualRelation !== false
      ) {
         return true;
      }
      if (!partRelation.partID) {
         return false;
      }
      const part = this.manageParts.getPart(partRelation.partID);
      if (!part) {
         return false;
      }
      const answer = await this.manageAssociations.associateVendorsToPart(
         [vendor.vendorID],
         part,
      );
      if (answer?.data.success === false) {
         return false;
      }

      return true;
   }

   public async associatePartsToVendor(): Promise<void> {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangePartVendorAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred141Fail, "danger", 10000);
         return;
      }

      const modalData: AddPartModalData = {
         message: this.lang().AssociatePartsToAVendorMsg,
         title: this.lang().AssociatePartsToAVendor,
         buttonText: this.lang().Select,
         dataLogOptions: {
            taskStoreLabel: "manageParts-storeAnAssociation",
         },
         locationID: this.vendor.locationID,
      };

      const parts: Array<Part> =
         await this.partsFacadeService.openAddPartModal(modalData);

      assert(this.vendor);
      const partIDs = parts.map((part) => part.partID);
      if (parts.length > 0) {
         this.loading = true;
         this.manageAssociations
            .associatePartsToVendor(partIDs, this.vendor)
            .then((answer) => {
               if (answer?.data.success === true) {
                  this.buildPartsAssociations();
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  this.loading = false;
               } else {
                  this.loading = false;
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               }
            });
      }
   }

   deletePartVendorRelation = (
      partAssociation: Partial<
         Part & {
            manualRelation?: boolean;
            automaticRelation?: boolean;
            tmpPoItemID?: number;
         }
      >,
   ) => {
      assert(this.vendor && partAssociation.partID);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangePartVendorAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred141Fail, "danger", 10000);
         return;
      }

      this.manageAssociations
         .deleteManualPartVendorAssociation(this.vendor.vendorID, partAssociation.partID)
         .then((answer) => {
            if (answer?.data.success === true) {
               this.buildPartsAssociations();

               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   };

   public flipPartShowDetails(partID: number) {
      const partDisplayInfo = this.partDisplayInfo.get(partID);
      if (!partDisplayInfo) {
         return;
      }
      partDisplayInfo.showDetails = !partDisplayInfo?.showDetails;
   }
}
