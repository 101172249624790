<lim-ui-panel [noSidePadding]="true" id="assetCheckInOutPrint{{ asset?.assetID }}">
   <!-- print related content -->
   <div class="printViewShow">
      <div class="col-md-12">
         <h5 class="my-3">
            <span>
               <lim-ui-icon
                  icon="screwdriverWrench"
                  iconSize="small"
                  class="icon-styling"
               />
               <b>{{ lang().AssetCardCheckInOutTabHeading }}</b> -
               {{ lang().AssetCardCheckInOutTabHeadingMsg }}
            </span>
         </h5>
      </div>
   </div>

   <div>
      @if (loading) {
         <div>
            <div class="loading-icon">
               <lim-ui-loading-animation />
            </div>
         </div>
      }

      @if (!loading) {
         <tool-status-info
            [tool]="asset"
            [mostRecentRequest]="mostRecentRequest"
            [createCheckOutRequest]="createCheckOutRequest"
            [checkInTool]="checkInTool"
            [cancelRequest]="cancelRequest"
            class="tool-status-info"
         />
      }

      @if (!loading && checkOutEntries && checkOutEntries.length) {
         <div class="search-row">
            <lim-ui-search-box
               [(searchVal)]="searchText"
               (searchValChange)="searchLogs()"
               [placeholder]="lang().Search"
            />
         </div>
      }

      @if (!loading && checkOutEntries && checkOutEntries.length) {
         <div class="main-container">
            <div class="entries-container listTable lim-ui-responsive-table">
               @if (!noSearchResults) {
                  <div class="listItemHeader lim-ui-responsive-table-header">
                     <div
                        class="col-md-3 lim-ui-hide-on-mobile cursor"
                        (click)="orderByCall()"
                     >
                        <span class="date-header">
                           <lim-ui-icon
                              [icon]="
                                 orderBy !== 'date' && orderBy !== '-date'
                                    ? sortIcon
                                    : orderBy === 'date'
                                      ? sortDescIcon
                                      : sortAscIcon
                              "
                              placement="top"
                              class="icon-styling"
                           />
                           <span>{{ lang().Date }}</span>
                        </span>
                     </div>
                     <div class="col-md-6 lim-ui-hide-on-mobile">
                        <span> {{ lang().Entry }} </span>
                     </div>
                     <div class="col-md-3 lim-ui-hide-on-mobile">
                        <span> {{ lang().User }} </span>
                     </div>
                  </div>
               }
               @if (noSearchResults) {
                  <span>
                     <no-search-results />
                  </span>
               }
               @for (
                  checkOut of filteredEntries
                     | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
                  track checkOut
               ) {
                  <div
                     class="listItem lim-ui-responsive-table-row"
                     [ngClass]="{ tranactionEveryOther: $even }"
                  >
                     <div class="col-md-3 lim-ui-responsive-table-cell icon-with-text">
                        <lim-ui-icon icon="clockRegular" iconSize="small" />
                        <span>{{ checkOut.date | betterDate: "dateTime" }}</span>
                     </div>

                     @if (entryInfo[checkOut.date].title) {
                        <div class="col-md-6 lim-ui-responsive-table-cell tool-history">
                           <span>
                              <span [limbleHtml]="entryInfo[checkOut.date].title"></span>
                              @if (checkOut.checklistID) {
                                 <span
                                    (click)="popTask(checkOut.checklistID)"
                                    [limUiTooltip]="
                                       lang().TheTaskThisRequestOrCheckOutWasMadeOn
                                    "
                                    placement="right"
                                 >
                                    &nbsp;|&nbsp;
                                    <a class="cursor">
                                       @if (
                                          manageTask.getTaskLocalLookup(
                                             checkOut.checklistID
                                          );
                                          as checkoutTask
                                       ) {
                                          <span
                                             [limbleHtml]="checkoutTask?.checklistName"
                                          ></span>
                                       }
                                       - #{{ checkOut.checklistID }}
                                    </a>
                                 </span>
                              }
                           </span>

                           @if (entryInfo[checkOut.date].subTitle) {
                              <span
                                 [limbleHtml]="entryInfo[checkOut.date].subTitle"
                                 class="list-item-sub-title"
                              ></span>
                           }
                        </div>
                     }

                     <div class="col-md-3 lim-ui-responsive-table-cell icon-with-text">
                        <lim-ui-icon icon="user" iconSize="small" />
                        <span>{{ checkOut.user }}</span>
                     </div>
                  </div>
               }
            </div>

            @if (
               filteredEntries &&
               filteredEntries.length &&
               filteredEntries.length > itemsPerPage
            ) {
               <div class="pagination-container">
                  <lim-ui-pagination
                     [maxSize]="5"
                     [rotate]="true"
                     [(page)]="page"
                     [pageSize]="itemsPerPage"
                     [collectionSize]="filteredEntries?.length ?? 0"
                  />
               </div>
            }
         </div>
      }
   </div>
</lim-ui-panel>
