<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="lim-ui-form-column">
            <div class="lim-ui-form-group">
               <label class="control-label">{{ lang().possiblePlaceholders }}</label>
               <span>
                  {{ taskInfoPlaceholder }} -
                  {{ lang().CommentTaskInfoPlaceholderDefinition }}
               </span>
               <span> {{ taskID }} - {{ lang().taskIDPlaceholderDefinition }} </span>
               <span>
                  {{ taskLinkPlaceholder }} -
                  {{ lang().CommentTaskLinkPlaceholderDefinition }}
               </span>
               <span>
                  {{ userCommentPlaceholder }} -
                  {{ lang().UserCommentPlaceholderDefinition }}
               </span>
            </div>
         </div>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="lim-ui-form-column">
            <div class="lim-ui-form-group">
               <label>
                  {{ lang().Subject }}
               </label>
               <div
                  [(ngModel)]="emailSubject"
                  limbleContentEditable
                  disableEditor="true"
                  stripHtml="true"
               ></div>
            </div>
            <div class="lim-ui-form-group">
               <label>
                  {{ lang().Message }}
               </label>
               <div
                  [(ngModel)]="emailMessage"
                  limbleContentEditable
                  stripHtml="false"
               ></div>
            </div>
         </div>
      </lim-ui-panel>
      @if (errorMsg !== "") {
         <lim-ui-info-panel>
            <div class="complete-task-panel-container">
               <lim-ui-alert [title]="lang().Warning" alertType="danger">
                  <div [limbleHtml]="errorMsg"></div>
               </lim-ui-alert>
            </div>
         </lim-ui-info-panel>
      }
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Set"
      (successClick)="submit()"
   />
</lim-ui-modal>
