<lim-ui-panel class="lim-ui-fonts-body" id="assetReportsPrint{{ assetID() }}">
   @if (isLoading()) {
      <loading-popup-pill />
   }
   <header class="report-metrics-header">
      <div class="filter-selection-bar">
         <data-viewer-filters
            [filters]="filters"
            (set)="onSetFilter($event)"
            (remove)="onRemoveFilter($event)"
         />
      </div>
      <div>
         @if (isIncludeChildDataVisible()) {
            <lim-ui-checkbox
               limUiTooltip="{{ lang().IncludeChildrenDataTooltip }}"
               [model]="includeChildData()"
               [disabled]="restrict || !creds?.editAssetSettings"
               label="{{ lang().IncludeChildrenData }}"
               (modelChange)="updateIncludeChildData()"
            />
         }

         @if (
            isIncludeChildDataVisible() &&
            creds &&
            creds.viewManageAssets &&
            !creds.editAssetSettings
         ) {
            <lim-ui-icon
               icon="circleQuestionRegular"
               limUiTooltip="{{ lang().IncludeChildrenDataCredTooltip }}"
            />
         }
      </div>
   </header>

   <section class="metrics">
      <lim-ui-card
         [clickableStyling]="true"
         (click)="showReportsWidgetModal('plannedMaintenance')"
      >
         <h3 class="dashboard-data">
            {{ completedPlannedWorkCount() }}
         </h3>
         <p class="dashboard-title">
            {{ lang().CompletedPlannedWorkAssetReports }}
         </p>
      </lim-ui-card>
      <lim-ui-card
         [clickableStyling]="true"
         (click)="showReportsWidgetModal('unplannedMaintenance')"
      >
         <h3 class="dashboard-data">
            {{ completedUnplannedWOsCount() }}
         </h3>
         <p class="dashboard-title">
            {{ lang().CompletedUnplannedWorkAssetReports }}
         </p>
      </lim-ui-card>
      @if (asset()) {
         <planned-vs-unplanned
            [plannedWOsCount]="plannedVsUnplannedWOsCount().planned"
            [unplannedWOsCount]="plannedVsUnplannedWOsCount().unplanned"
            (showCompletedPreventiveWorkOrders)="
               showReportsWidgetModal('plannedMaintenance')
            "
            (showCompletedWorkOrders)="showReportsWidgetModal('unplannedMaintenance')"
         />
      }
      <lim-ui-card
         [clickableStyling]="true"
         (click)="showReportsWidgetModal('timeSpent')"
      >
         <h3 class="dashboard-data">
            {{ timeSpentCount().toFixed(2) }} {{ lang().hrs }}
         </h3>
         <p class="dashboard-title">{{ lang().TimeSpent }}</p>
      </lim-ui-card>
      <lim-ui-card [clickableStyling]="true" (click)="showReportsWidgetModal('mtbf')">
         @if (MTBF() !== infinity && MTBF() !== 0) {
            <h3
               placement="bottom"
               limUiTooltip="{{ lang().AssetReportsMTBFTooltip }}"
               class="dashboard-data"
            >
               {{ MTBF().toFixed(2) }} {{ lang().hrs }}
            </h3>
         } @else {
            <h3
               class="dashboard-data"
               placement="bottom"
               limUiTooltip="{{ lang().AssetReportsMTBFTooltipWarning }}"
            >
               {{ lang().NA }}
            </h3>
         }

         <p class="dashboard-title">
            {{ lang().MTBF }}
            <lim-ui-icon
               icon="circleQuestionRegular"
               limUiTooltip="{{ lang().InTheSelectedTimeFrameTheAssetRan }} {{
                  totalTimeRanInHours()
               }} {{ lang().hrs }}"
               placement="top"
            />
         </p>
      </lim-ui-card>

      <lim-ui-card [clickableStyling]="true" (click)="showReportsWidgetModal('mttr')">
         @if (MTTR() > 0) {
            <h3
               placement="bottom"
               limUiTooltip="{{ lang().AssetReportsMTTRTooltip }}"
               class="dashboard-data"
            >
               {{ MTTR().toFixed(2) }} {{ lang().hrs }}
            </h3>
         }
         @if (MTTR() === 0) {
            <h3
               class="dashboard-data"
               placement="bottom"
               limUiTooltip="{{ lang().AssetReportsMTTRTooltipWarning }}"
            >
               {{ lang().NA }}
            </h3>
         }
         <p class="dashboard-title">{{ lang().MTTR }}</p>
      </lim-ui-card>

      <lim-ui-card
         [clickableStyling]="true"
         (click)="showReportsWidgetModal('downtimeHours')"
      >
         <h3 class="dashboard-data">{{ downtimeHours().toFixed(2) }} {{ lang().hrs }}</h3>
         <p class="dashboard-title">{{ lang().Downtime }}</p>
      </lim-ui-card>

      <lim-ui-card
         [clickableStyling]="true"
         (click)="showReportsWidgetModal('partsUsed')"
      >
         <h3 class="dashboard-data">
            {{ partsUsed() }}
         </h3>
         <p class="dashboard-title">{{ lang().PartsUsed }}</p>
      </lim-ui-card>
   </section>

   <section class="data-visualizations">
      <lim-ui-card>
         <h3 class="graph-header">
            <lim-ui-icon icon="chartColumn" />
            {{ lang().TotalCostOfOwnership }} :
            <span>{{ totalCostOfOwnership() | localeCurrency: currencyCode() }}</span>
         </h3>
         <div>
            <canvas
               [id]="'pieChart1' + assetID()"
               #pieChartEle
               (click)="viewGraphItem($event)"
               >{{ pieChart() }}</canvas
            >
            <div id="'pieChartLegend1' + asset?.assetID" #pieChartLegendEle></div>
            @if (credAssetSettings) {
               <div class="chart-controls">
                  <lim-ui-primary-button
                     (click)="changeFields()"
                     icon="penToSquareRegular"
                  >
                     {{ lang().ChangeFields }}
                  </lim-ui-primary-button>
               </div>
            }
         </div>
      </lim-ui-card>

      <lim-ui-card>
         <future-estimated-costs-chart
            [currencyCode]="currencyCode()"
            [assetID]="assetID()"
            [totalLaborCost]="totalLaborCost()"
            [totalPartsCost]="totalPartsCost()"
            [asset]="asset()"
         />
      </lim-ui-card>

      @if (depreciationSchedule && depreciationSchedule.scheduleActive) {
         <div>
            <depreciation-line-chart-card
               [schedule]="depreciationSchedule"
               [currencyCode]="currencyCode()"
            />
         </div>
      }
   </section>
</lim-ui-panel>
