import type { WritableSignal } from "@angular/core";
import type { AssetTemplateAccountSettings } from "src/app/assets/services/asset-template-settings.service";
import type { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { ManageLocation } from "src/app/locations/services/manageLocation";
import type {
   LocationHierarchyService,
   NoRegion,
   RegionWithNodes,
   LocationWithNodes,
} from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy.service";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import type { BetterDate } from "src/app/shared/services/betterDate";
import type { ManageFilters } from "src/app/shared/services/manageFilters";
import type { HierarchyNode } from "src/app/shared/types/general.types";
import type { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { Lookup } from "src/app/shared/utils/lookup";
import type { CredService } from "src/app/users/services/creds/cred.service";
import type { ManageUser } from "src/app/users/services/manageUser";

export type AssetHierarchyNode = HierarchyNode & {
   uniqueID?: string;
   assetID: number;
   templateID: number | undefined;
};

export type LocationNode = LocationWithNodes &
   HierarchyNode & {
      nodes: AssetHierarchyNode[];
      childrenBuilt: boolean;
      collapsed: boolean;
   };

type LocationIndex = {
   [key: number]: LocationNode;
};

export type RegionNode = RegionWithNodes &
   HierarchyNode & {
      nodeDisplay: string;
   };
export type NoRegionNode = NoRegion &
   HierarchyNode & {
      nodeDisplay: string;
   };

export type TreeNode = LocationNode | RegionNode | AssetHierarchyNode | NoRegionNode;

/**
 * This is used to build a list of assets in a hierarchy structure.
 */
export default class AssetHierarchyBuilder {
   public locLength: number = 0;
   public assetSearchHints: Map<number, { searchHint: string; searchFound: boolean }> =
      new Map();
   public tmpLocations: LocationNode[] = [];

   private locationIndex: LocationIndex = {};
   private locationIDs: number[] = [];
   private regions: Array<RegionNode | NoRegionNode> | [] = [];
   private regionsIndex!: Record<number, RegionNode | NoRegionNode>;
   private startCollapsed: boolean = false;

   public constructor(
      private readonly manageLocation: ManageLocation,
      private readonly manageAsset: ManageAsset,
      private readonly credService: CredService,
      private readonly manageFilters: ManageFilters,
      private readonly locationHierarchyService: LocationHierarchyService,
      private readonly betterDate: BetterDate,
      private readonly manageUser: ManageUser,
      private readonly templatesStatus: WritableSignal<
         AssetTemplateAccountSettings | undefined
      >,
      public assets: Lookup<"assetID", Asset>,
      public allLocations: LocationNode[],
      public search: string | undefined,
      public assetNodes: LimbleMap<number, AssetHierarchyNode>,
      public treeData: TreeNode[],
      public noSearchResults: boolean,
   ) {
      this.setCollapsedState();
   }

   public buildList(initialBuild?: boolean): {
      treeData: TreeNode[];
      noSearchResults: boolean;
   } {
      this.setLocations();

      this.filterAssets();

      this.orderAssets();

      this.setNodes(initialBuild);

      this.handleSearch();

      this.setRegions();

      this.buildTreeData();

      /**
       * Setting no search results here so it's always
       * set after all searching/filtering
       */
      this.noSearchResults = this.assets.size === 0;

      return {
         treeData: this.treeData,
         noSearchResults: this.noSearchResults,
      };
   }

   private setCollapsedState(): void {
      if (this.search !== undefined && this.search.length > 1) {
         //override... if they are searching then we don't want the nodes to be collapsed
         this.startCollapsed = false;
         return;
      }

      if (this.manageUser.getCurrentUser().userInfo.customerAssetsStartCollapsed === 1) {
         this.startCollapsed = true;
      } else {
         this.startCollapsed = false;
      }
   }

   private handleNodesForMultipleLocations(
      asset: Asset,
      assetNode: AssetHierarchyNode,
      initialBuild?: boolean,
   ): void {
      const isTopLevelParent =
         asset.parentAssetID === 0 && this.locationIndex[asset.locationID];
      if (isTopLevelParent) {
         this.locationIndex[asset.locationID].nodes.push(assetNode);

         if (initialBuild && this.locLength > 3) {
            this.locationIndex[asset.locationID].collapsed = true;
         } else {
            this.locationIndex[asset.locationID].collapsed = false;
         }
      } else if (asset.parentAssetID) {
         const parentAssetNode = this.assetNodes.get(asset.parentAssetID);
         if (!parentAssetNode?.nodes) {
            this.handleTemplatedChildWithoutTemplatedParent(asset, assetNode);
            return;
         }

         parentAssetNode.nodes.push(assetNode);
      }
   }

   /**
    * Handles the case where a parent asset isn't created from
    * a template, but the child asset is and templated assets are required.
    *
    * The child asset will still be shown to be able to be copied, but
    * directly under the location instead of it's parent.
    */
   private handleTemplatedChildWithoutTemplatedParent(
      asset: Asset,
      assetNode: AssetHierarchyNode,
   ): void {
      if (this.templatesStatus()?.templatesStatus === "required") {
         this.locationIndex[asset.locationID].nodes.push(assetNode);
      }
   }

   /**
    * Builds the tree structure for locations, parent assets, and children assets.
    */
   private setNodes(initialBuild?: boolean): void {
      if (!this.assets) {
         return;
      }

      /**
       * Set asset nodes for initial build
       */
      if (initialBuild) {
         for (const asset of this.assets) {
            this.assetNodes.set(asset.assetID, {
               assetID: asset.assetID,
               collapsed: this.startCollapsed,
               selected: false,
               nodes: [],
               icon: "cube",
               title: asset.assetName ?? "",
               displayButtons: true,
               locationID: asset.locationID,
               templateID: asset.templateID,
            });
         }
      }

      for (const asset of this.assets) {
         //this loop must run before the next loop so all empty node arrays are built
         const assetNode = this.assetNodes.get(asset.assetID);
         if (!assetNode) {
            continue;
         }

         assetNode.nodes = [];
      }

      for (const asset of this.assets) {
         const assetNode = this.assetNodes.get(asset.assetID);
         if (!assetNode) {
            continue;
         }

         const hasMultipleLocations = this.locLength > 1;
         if (hasMultipleLocations) {
            this.handleNodesForMultipleLocations(asset, assetNode, initialBuild);
         } else {
            if (asset.parentAssetID && asset.parentAssetID > 0) {
               const parentAssetNode = this.assetNodes.get(asset.parentAssetID);
               if (!parentAssetNode?.nodes) {
                  this.handleTemplatedChildWithoutTemplatedParent(asset, assetNode);
                  continue;
               }
               parentAssetNode.nodes.push(assetNode);
            }
         }
      }
   }

   private setLocations(): void {
      this.tmpLocations = this.allLocations ?? [];

      const tempArr: any = [];
      for (const location of this.tmpLocations) {
         location.selected = false;
         if (
            this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.ViewManageAssets,
            )
         ) {
            tempArr.push(location);
         }
      }
      this.tmpLocations = tempArr;

      this.locationIDs = [];
      this.locationIndex = {};
      this.locLength = 0;
      for (const location of this.tmpLocations) {
         location.childrenBuilt = false;
         this.locationIDs.push(location.locationID);

         location.nodes = [];
         location.title = location.locationName;
         location.icon = "houseChimney";
         location.unselectable = true;
         location.collapsed = this.startCollapsed;

         this.locationIndex[location.locationID] = location;
         this.locLength++;
      }
   }

   private setRegions(): void {
      this.regionsIndex = {};

      if (this.manageLocation.getRegions().length > 0) {
         //they are using regions so we have to behave a little differently
         const rst = this.locationHierarchyService.buildHierarchy({
            locations: this.tmpLocations,
            regions: this.manageLocation.getRegions(),
            search: this.search,
            filterRegions: false,
            filterLocations: false,
            alwaysReturnRegions: false,
            totalLocationCount: this.allLocations.length,
         });
         this.regions = rst.regionTree as (RegionNode | NoRegionNode)[];
         this.regionsIndex = rst.regionsIndex as Record<
            number,
            RegionNode | NoRegionNode
         >;
      }

      for (const index in this.regionsIndex) {
         const region = this.regionsIndex[index];
         region.nodeDisplay = region.regionName;
         region.title = region.regionName;
         region.unselectable = true;
         region.icon = "earthAmericas";

         /**
          * If we are searching start not collapsed.
          * If they are starting collapsed and they don't thave many regions then let's show them
          */
         if (
            (this.search !== undefined && this.search.length > 1) ||
            (!this.startCollapsed && this.manageLocation.getRegions().length <= 3)
         ) {
            region.collapsed = false;
         } else {
            region.collapsed = true;
         }

         region.selected = false;
      }
   }

   private filterAssets(): void {
      // filter out deleted assets and assets not in a location
      this.assets = this.manageAsset.getAssets().filter((asset) => {
         return asset.assetDeleted === 0 && this.locationIDs.includes(asset.locationID);
      });

      // filter out non-templated assets if templates are required
      if (this.templatesStatus()?.templatesStatus === "required") {
         this.assets = this.assets.filter((asset) => {
            if (!asset.templateID) {
               return false;
            }
            return true;
         });
      }
   }

   /**
    * Filters the assets based on the search input.
    */
   private handleSearch(): void {
      this.assetSearchHints = new Map();

      if (this.search === undefined || this.search.length <= 1) {
         return;
      }

      this.assets = this.manageFilters.filterAssetsToNameAndTextFields_refactor(
         this.assets,
         this.manageAsset.getFields(),
         this.manageAsset.getFieldValues(),
         this.manageAsset.getFieldValueFiles(),
         this.assetSearchHints,
         {
            search: this.search,
            hier: true,
            field: false,
            includeChildren: true,
         },
         this.manageAsset,
         this.betterDate,
      );

      /**
       * after the search we need to refilter the nodes
       * because some nodes should not exist
       * (they weren't found in the search, but nodes persisted from before).
       * It is unfortunate we have to run this again,
       * but the search function needs the nodes to perform
       * the search and its much faster to rebuild this way then loop through
       * the asset's nodes of the pre built data set
       */
      for (const asset of this.assets) {
         const assetNode = this.assetNodes.get(asset.assetID);
         if (!assetNode) {
            continue;
         }

         assetNode.collapsed = false;
         assetNode.nodes = [];
      }

      for (const location of this.tmpLocations) {
         location.nodes = [];
      }

      const assetsIndex2 = {};

      for (const asset of this.assets) {
         assetsIndex2[asset.assetID] = asset;
      }

      for (const asset of this.assets) {
         const assetNode = this.assetNodes.get(asset.assetID);
         if (!assetNode) {
            continue;
         }
         if (
            asset.parentAssetID &&
            asset.parentAssetID > 0 &&
            assetsIndex2[asset.parentAssetID]
         ) {
            const parentAssetNode = this.assetNodes.get(asset.parentAssetID);
            if (!parentAssetNode?.nodes) {
               continue;
            }
            parentAssetNode.nodes.push(assetNode);
         } else {
            this.locationIndex[asset.locationID].nodes.push(assetNode);
            this.locationIndex[asset.locationID].collapsed = false;
         }

         const assetSearchHint = this.assetSearchHints.get(asset.assetID);
         assetNode.searchFound = Boolean(assetSearchHint?.searchFound);
         assetNode.searchHint = assetSearchHint?.searchHint ?? "";
      }
   }

   private buildTreeData(): void {
      this.treeData = []; //prepare the tree structure

      if (this.locLength > 1) {
         if (this.manageLocation.getRegions().length > 0) {
            //they are doing regions so let's show those correctly...

            //first we need to remove any locations that don't have assets on them or any regions that are empty
            this.manageFilters.cleanupEmptyRegionsAndLocations(this.regionsIndex);

            //lastly add the top level regions
            for (const region of this.regions) {
               if (!region.nodes.length) {
                  continue;
               }
               this.treeData.push(region);
            }
         } else {
            //they aren't doing regions so process it like normal locations
            for (const location of this.tmpLocations) {
               if (!location.nodes.length) {
                  continue;
               }
               this.treeData.push(location);
            }
            this.treeData = orderBy(this.treeData, "locationName");
         }
      } else {
         for (const asset of this.assets) {
            if (asset.parentAssetID === 0) {
               const assetNode = this.assetNodes.get(asset.assetID);
               if (!assetNode) {
                  continue;
               }
               assetNode.collapsed = this.startCollapsed;
               this.treeData.push(assetNode);
            }
         }
      }
   }

   private orderAssets(): void {
      this.assets = this.assets.orderBy("assetName");
   }
}
