import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, forwardRef, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   SelectionPanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import moment from "moment";
import clone from "rfdc";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AddDateEndingPipe } from "src/app/shared/pipes/addDateEnding.pipe";
import { CapitalizePipe } from "src/app/shared/pipes/capitalize.pipe";
import { FirstSecondEtcToMultiLanguagePipe } from "src/app/shared/pipes/firstSecondEtcToMultiLanguage.pipe";
import { FullDayPipe } from "src/app/shared/pipes/fullDay.pipe";
import { FullMonthPipe } from "src/app/shared/pipes/fullMonth.pipe";
import { MonthNumericToStringPipe } from "src/app/shared/pipes/monthNumericToString.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import type { Lookup } from "src/app/shared/utils/lookup";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";

const deepClone = clone();

type AugmentedTask = Task & {
   selected: boolean;
   extraAssets: Array<Asset>;
   recurrences: Array<Recurrence>;
};

@Component({
   selector: "pick-tasks-template-view-legacy",
   templateUrl: "./pickTasksTemplateViewLegacy.component.html",
   styleUrls: ["./pickTasksTemplateViewLegacy.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      LimbleHtmlDirective,
      SearchBoxComponent,
      NoSearchResults,
      NgClass,
      IconComponent,
      forwardRef(() => AssetParentList),
      TooltipDirective,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
      AddDateEndingPipe,
      CapitalizePipe,
      FirstSecondEtcToMultiLanguagePipe,
      FullDayPipe,
      FullMonthPipe,
      MonthNumericToStringPipe,
      SelectionPanelComponent,
   ],
})
export class PickTasksTemplateViewLegacy implements OnInit {
   public message;
   public title;
   public data;
   public multiLocation;
   public tasks: Array<AugmentedTask> = [];
   public locations;
   public searchBar;
   public noSearchResults;
   public resolve;
   public modalInstance;
   public assets: Lookup<"assetID", Asset>;

   private readonly modalService = inject(ModalService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   protected readonly manageTask = inject(ManageTask);
   protected readonly manageSchedule = inject(ManageSchedule);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.assets = this.manageAsset.getAssets();
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.data = this.resolve.data;

      this.multiLocation = false;

      const filteredTasks = orderBy(this.resolve.tasks, "checklistName");

      this.tasks = filteredTasks;

      const locationsObj = {};

      for (const task of this.tasks) {
         task.selected = false;
         locationsObj[task.locationID] = task.locationID;
      }
      const locations: any = [];
      for (const key in locationsObj) {
         if (
            typeof this.manageLocation.getLocationsIndex()[locationsObj[key]] !==
            "undefined"
         ) {
            locations.push(this.manageLocation.getLocationsIndex()[locationsObj[key]]);
         }
      }

      this.locations = deepClone(locations);

      this.locations = orderBy(this.locations, "locationName");

      this.setTasks();
   }

   protected updateSearch(): void {
      for (const task of this.tasks) {
         task.selected = false;
      }
      this.noSearchResults = false;
      if (this.searchBar && this.searchBar.length > 0) {
         this.tasks = this.resolve.tasks.filter((task) =>
            this.manageFilters.searchObjValuesForString(task, this.searchBar),
         );
         if (this.tasks.length == 0) {
            this.noSearchResults = true;
         }
      } else {
         this.tasks = this.resolve.tasks;
      }

      this.setTasks();
   }

   protected setTasks(): void {
      const obj = {};

      for (const location of this.locations) {
         location.tasks = [];
         obj[location.locationID] = location;
      }

      for (const task of this.tasks) {
         if (obj[task.locationID]) {
            obj[task.locationID].tasks.push(task);
            task.extraAssets = [];
            task.recurrences = [];

            if (task.extraAssetIDs) {
               for (const assetID of task.extraAssetIDs) {
                  const extraAsset = this.assets.get(assetID);
                  if (extraAsset) task.extraAssets.push(extraAsset);
               }
            }

            if (task.reoccurIDs) {
               for (const reoccurID of task.reoccurIDs) {
                  const recurrence = this.manageTask.getRecurrence(reoccurID);
                  if (recurrence) task.recurrences.push(recurrence);
               }
            }
         }
      }

      for (const location of this.locations) {
         location.hide = false;
         location.showKids = false;
         if (location.tasks === undefined || location.tasks.length == 0) {
            location.hide = true;
            location.showKids = false;
         }
      }

      if (this.searchBar && this.searchBar.length > 0) {
         for (const location of this.locations) {
            location.showKids = true;
         }
      } else {
         this.locations[0].showKids = true;
      }

      let count = 0;
      for (const location of this.locations) {
         if (location.tasks.length > 0) {
            count++;
         }
      }
      if (count > 1) {
         this.multiLocation = true;
      }
   }

   protected focusTask(taskToFocus: AugmentedTask): void {
      if (this.data.selectOne == true) {
         for (const task of this.tasks) {
            if (task.checklistID != taskToFocus.checklistID) {
               task.selected = false;
            }
         }
      }

      if (this.data.selectOne == true) {
         if (taskToFocus.selected == true) {
            this.submit();
         }
      }

      taskToFocus.selected = !taskToFocus.selected;
   }

   protected clearSelection(): void {
      for (const task of this.tasks) {
         task.selected = false;
      }
   }

   protected markAllSelection(): void {
      for (const task of this.tasks) {
         task.selected = true;
      }
      for (const location of this.locations) {
         location.showKids = true;
      }
   }

   protected popTask(task: AugmentedTask): void {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: false,
               preview: true,
               template: true,
            },
         },
      };
   }

   protected formatTime(time: string | null): string {
      return time ? moment(time, "hh:mm").format("LT") : "";
   }

   protected submit(): void {
      const selectedTasks: Array<Task> = [];

      for (const task of this.tasks) {
         if (task.selected == true) {
            // Converting back to regular tasks (not AugmentedTasks)
            const selectedTask = this.manageTask.getTaskLocalLookup(task.checklistID);
            if (!selectedTask) continue;
            selectedTasks.push(selectedTask);
         }
      }

      if (selectedTasks.length == 0) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOneTask,
            "warning",
            6000,
         );
         return;
      }

      this.modalInstance.close(selectedTasks);
   }

   protected close(): void {
      this.modalInstance.close(0);
   }
}
