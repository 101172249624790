<div class="empty-here-container">
   <div class="image-container">
      <img src="assets/img/LimbleRobotNew.png" />
   </div>
   <div class="empty-here-title">
      {{ lang().ItsSoEmptyHere }}
   </div>
   <div class="empty-here-text">
      @if (customText) {
         <span [limbleHtml]="customText"></span>
      }
      @if (showAddButton && showDefaultSubtitle() === true) {
         <span>
            {{ lang().ClickTheButtonBelowToGetStarted }}
         </span>
      }
   </div>
   <div class="buttons-container">
      @if (showAddButton) {
         @if (addButtonSecondary() === true) {
            <lim-ui-secondary-button
               [disabled]="addButtonDisabled"
               icon="plus"
               (click)="addTextBtnClicked()"
               [dataLogLabel]="dataLogAddButtonLabel"
               data-test-id="addButton"
            >
               {{ addText }}
            </lim-ui-secondary-button>
         } @else {
            <lim-ui-primary-button
               [disabled]="addButtonDisabled"
               icon="plus"
               (click)="addTextBtnClicked()"
               [dataLogLabel]="dataLogAddButtonLabel"
               data-test-id="addButton"
            >
               {{ addText }}
            </lim-ui-primary-button>
         }
      }
      @if (importText) {
         <lim-ui-secondary-button
            icon="cloudDownloadRegular"
            (click)="triggerImport()"
            data-test-id="importButton"
         >
            {{ importText }}</lim-ui-secondary-button
         >
      }
   </div>
</div>
