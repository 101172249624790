import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed, signal, input, output } from "@angular/core";
import { takeUntilDestroyed, toObservable, toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import {
   IconComponent,
   ModalService,
   MinimalIconButtonComponent,
   PopoverDirective,
   TooltipDirective,
   UpsellPopover,
   FormDropdownInputComponent,
   DropdownTextItemComponent,
   DropdownDividerComponent,
   SearchBoxComponent,
   LoadingAnimationComponent,
   AlertComponent,
   DropdownItemComponent,
   LimbleHtmlDirective,
} from "@limblecmms/lim-ui";
import { debounceTime, filter, Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ConfirmUnitOfMeasureModal } from "src/app/parts/components/confirm-uom-modal/confirm-uom.modal.component";
import { PartsTransfer } from "src/app/parts/components/partsTransferModal/partsTransfer.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import type { Unit } from "src/app/parts/unit-of-measure/unit.types";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { GatherText } from "src/app/shared/components/global/gatherTextModal/gatherText.modal.component";
import { ViewListModal } from "src/app/shared/components/global/lists/viewList.modal.component";
import { ColorizeStringDirective } from "src/app/shared/directives/colorize-string/colorize-string.directive";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "part-qty-field",
   templateUrl: "./partQty.element.component.html",
   styleUrls: ["../shared-field-styles.scss", "./partQty.element.component.scss"],
   imports: [
      NgClass,
      FormsModule,
      MinimalIconButtonComponent,
      TooltipDirective,
      IconComponent,
      PopoverDirective,
      UpsellPopover,
      FormDropdownInputComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      SearchBoxComponent,
      TooltipDirective,
      LoadingAnimationComponent,
      PartUnitOfMeasurePipe,
      AlertComponent,
      DropdownItemComponent,
      RouterLink,
      LimbleHtmlDirective,
      ColorizeStringDirective,
   ],
})
export class PartQty implements OnInit, OnDestroy {
   private static readonly SEARCH_FILTER_DUE_TIME = 250;

   public readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly managePO = inject(ManagePO);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageUtil = inject(ManageUtil);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);
   private readonly manageObservables = inject(ManageObservables);
   private readonly heapService = inject(HeapService);
   private readonly refreshService = inject(RefreshService);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);

   @Input() public usedIn: "table" | "listItem" | undefined = undefined;
   public readonly partID = input.required<number>();
   public readonly clickPurchasingTabLink = output();
   protected readonly part = computed(() => {
      if (this.manageParts.updated() === null) return null;
      const part = this.manageParts.getPart(this.partID());
      assert(part !== undefined);
      return part;
   });

   private readonly partInfoCalculated = toSignal(
      this.manageParts.partInfoCalculated$.pipe(
         takeUntilDestroyed(),
         filter((partID) => partID === this.partID()),
      ),
   );

   private readonly stateOfRefresh = toSignal(
      this.refreshService.stateOfRefresh().pipe(takeUntilDestroyed()),
   );

   private readonly isPartInfoCalculated = computed(
      () =>
         this.manageParts.isAllPartDataCalculated() !== undefined ||
         this.partInfoCalculated() !== undefined,
   );
   protected readonly calculatedPartInfo = computed(() => {
      if (!this.isPartInfoCalculated() || this.stateOfRefresh()) return null;
      const calculatedPartInfo = this.manageParts.getSingleCalculatedPartInfo(
         this.partID(),
      );
      assert(calculatedPartInfo !== undefined);

      return calculatedPartInfo;
   });
   public oldTotalQty: number | undefined;
   public credChangePartQty: boolean | undefined;
   public showDetails: boolean = false;
   public featureMultipleLocations: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription = new Subscription();
   public extraBatchPONumbers: Map<number, number> = new Map();
   private partDataCalculatedSub$: Subscription | undefined;
   protected unitHasLockedStatus = signal(false);
   protected canEditBaseUoM: boolean | undefined;
   protected canViewPurchaseOrders: boolean | undefined;
   protected isSuperUser = false;
   protected isStockUnitLocked = computed(() => {
      const unit = this.selectedUnit();
      return unit === undefined || this.unitHasLockedStatus();
   });

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public openPurchaseOrderWatchVarSub: Subscription | null = null;

   private readonly searchableUnits = this.unitOfMeasureService.units;

   protected readonly searchQuery = signal("");
   protected readonly searchQueryUpdates = toSignal(
      toObservable(this.searchQuery).pipe(
         debounceTime(PartQty.SEARCH_FILTER_DUE_TIME),
         takeUntilDestroyed(),
      ),
   );
   private readonly searchQueryDebounced = computed(() => {
      return this.searchQueryUpdates() ?? "";
   });
   protected readonly units = computed(() =>
      this.filterUnitsByAnyName({
         units: this.searchableUnits(),
         query: this.searchQueryDebounced(),
      }),
   );
   protected readonly unit = computed(() => {
      const part = this.part();
      const stockUnit =
         part === null ? null : this.unitOfMeasureService.getUnit(part.unitDescription);
      return (
         this.selectedUnit() ?? stockUnit?.() ?? this.unitOfMeasureService.defaultUnit()
      );
   });
   protected readonly selectedUnit = signal<Unit | null>(null);
   protected addUnitHelp: string | null = null;

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureMultipleLocations = isFeatureEnabledMap.featureMultipleLocations;
         },
      );
   }

   public ngOnInit(): void {
      const part = this.part();
      assert(part !== null);
      this.getLockedStockUnit();
      this.partDataCalculatedSub$ = this.manageParts.partDataCalculated$.subscribe(() => {
         if (this.part === undefined) {
            return;
         }
         this.oldTotalQty = this.manageParts.getSingleCalculatedPartInfo(
            this.partID(),
         )?.totalQty;
      });

      this.credChangePartQty = this.credService.isAuthorized(
         part.locationID,
         this.credService.Permissions.ManuallyChangePartQty,
      );
      this.canEditBaseUoM = this.credService.isAuthorized(
         part.locationID,
         this.credService.Permissions.EditBaseUoM,
      );
      const currentUser = this.manageUser.getCurrentUser();
      this.isSuperUser = this.manageUtil.checkIfSuperUser(currentUser);
      this.addUnitHelp = this.isSuperUser
         ? this.lang().CreateMissingUnitHelp
         : this.lang().MissingUnitHelp;
      this.canViewPurchaseOrders = this.credService.isAuthorized(
         part.locationID,
         this.credService.Permissions.ManagePOs,
      );

      this.refreshExtraBatchPONumbers();

      this.openPurchaseOrderWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.refreshExtraBatchPONumbers();
         },
      );
   }

   private async getLockedStockUnit(): Promise<void> {
      if (this.usedIn === "listItem") {
         const partWithLockedStatus = await this.manageParts.getSinglePart(this.partID());
         if (!partWithLockedStatus) {
            return;
         }
         this.unitHasLockedStatus.set(partWithLockedStatus.isStockUnitLocked);
      }
   }

   private refreshExtraBatchPONumbers(): void {
      const extraBatchIDs = this.part()?.partExtraBatchIDs ?? [];

      for (const extraBatchID of extraBatchIDs) {
         const extraBatch = this.manageParts.getExtraBatch(extraBatchID);
         if (!extraBatch) return;
         const poItem = this.managePO.getPurchaseOrderItem(extraBatch?.poItemID);
         if (!poItem?.poID) return;
         const purchaseOrder = this.managePO.getPurchaseOrder(poItem.poID);
         if (!purchaseOrder?.poNumber) return;
         this.extraBatchPONumbers.set(extraBatchID, purchaseOrder.poNumber);
         if (!this.unitOfMeasureService.isFeatureEnabled()) return;
      }
   }

   public ngOnDestroy() {
      this.manageFeatureFlagsSub.unsubscribe();
      this.partDataCalculatedSub$?.unsubscribe();
      this.openPurchaseOrderWatchVarSub?.unsubscribe();
   }

   private calculateNumberOfReservedPartsFromUnviewableTasks(
      tasksUserCannotView: Lookup<
         "checklistID",
         { checklistID: number; suggestedNumber: number }
      >,
   ): number {
      let numOfParts: number = 0;
      for (const task of tasksUserCannotView) {
         numOfParts += task.suggestedNumber;
      }
      return numOfParts;
   }

   public popReservedTasks(part: Part): void {
      assert(part.partID);
      const calculatedPartInfo = this.manageParts.getSingleCalculatedPartInfo(
         part.partID,
      );
      const tasks: Array<Task> = [];
      const tasksUserCannotView: Lookup<
         "checklistID",
         { checklistID: number; suggestedNumber: number }
      > = new Lookup("checklistID");
      assert(calculatedPartInfo);

      for (const reservedTask of calculatedPartInfo.reservedTasks) {
         const task = this.manageTask.getTaskLocalLookup(reservedTask.checklistID);
         if (task) {
            tasks.push(task);
         } else {
            tasksUserCannotView.set(reservedTask.checklistID, reservedTask);
         }
      }

      const numOfPartsReservedFromUnviewableTasks =
         this.calculateNumberOfReservedPartsFromUnviewableTasks(tasksUserCannotView);

      let message: string = "";
      let messageTooltip: string = "";
      if (numOfPartsReservedFromUnviewableTasks > 0) {
         message = `${this.lang().PartsReservedFromOtherTasks} ${numOfPartsReservedFromUnviewableTasks}`;
         messageTooltip = this.lang().ThesePartsAreLinkedToTasksYouDoNotHaveAccess;
      }

      const columns = [
         {
            key: "checklistName",
            displayName: this.lang().TaskName,
            sortBy: "checklistName",
            manualWidth: 6,
         },
         {
            key: "partRelationsDetail",
            displayName: this.lang().Parts,
            sortBy: "partRelationsDetail",
            manualWidth: 6,
         },
      ];

      const options = {
         sortBind: "-checklistName",
         isModal: true,
         title: this.lang().RecentlyUsedParts,
      };

      const instance = this.modalService.open(ViewListModal);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            objs: tasks,
            options: options,
            modalTitle: this.lang().ViewList,
            columns: columns,
            message: message,
            messageTooltip: messageTooltip,
         },
      };
   }

   public async updatePartQty(): Promise<void> {
      const part = this.part();

      if (part === null || this.oldTotalQty === undefined) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         console.error("part or oldTotalQty undefined");
         return;
      }
      const currentCalculatedPartInfo = this.manageParts.getSingleCalculatedPartInfo(
         part.partID,
      );
      assert(currentCalculatedPartInfo);
      if (this.oldTotalQty === currentCalculatedPartInfo.totalQty) {
         return;
      }

      if (
         this.alertService.noCredAtLocationAlert(
            part.locationID,
            this.credService.Permissions.ManuallyChangePartQty,
         )
      ) {
         return;
      }
      let reason: string;

      if (
         this.manageUser.getCurrentUser().userInfo.manualChangePartQtyReasonFlag === 1 &&
         this.oldTotalQty > currentCalculatedPartInfo.totalQty
      ) {
         const instance = this.modalService.open(GatherText);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().ThisWillNotAddToYourPartSpend,
               message2: false,
               title: this.lang().ExplainWhyQuantityIsBeingLowered,
               warning: false,
               data: {
                  currentText: "",
                  buttonText: false,
               },
            },
         };
         const result = await instance.result;

         if (!result) {
            this.manageParts.setSingleCalculatedPartInfo(part.partID, {
               ...currentCalculatedPartInfo,
               totalQty: Number(this.oldTotalQty),
            });
            return;
         }
         reason = result;
      } else {
         reason = "";
      }

      const answer = await this.manageParts.updatePartQty(part.partID, reason);

      if (!answer?.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      const newTotalQty = this.manageParts.calculatePartData(part)?.totalQty;
      this.oldTotalQty = newTotalQty;

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   public async popPoComponent(poItemID: number): Promise<void> {
      const poItem = this.managePO.getPurchaseOrderItem(poItemID);
      if (!poItem) return;
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poItem.poID },
         },
      };
      const result = await instance.result;
      if (!result) {
         return;
      }

      this.refreshExtraBatchPONumbers();
   }

   public transferParts(): void {
      const part = this.part();
      assert(part !== null);
      if (!this.featureMultipleLocations) {
         return;
      }

      if (
         this.alertService.noCredAtLocationAlert(
            part.locationID,
            this.credService.Permissions.TransferPartsBetweenLocations,
         )
      ) {
         return;
      }

      const instance = this.modalService.open(PartsTransfer);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            part: part,
            locationID: part.locationID,
         },
      };
   }

   public toggleDetails(): void {
      this.showDetails = !this.showDetails;
   }

   protected async changePartUnitOfMeasure(unit: Unit): Promise<void> {
      if (this.isStockUnitLocked()) {
         return;
      }
      this.heapService.trackEvent("manageUnitsOfMeasure_initiateChangeStockUnit");

      const instance = this.modalService.open(ConfirmUnitOfMeasureModal);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            title: this.lang().ChangeStockUnit,
            message: this.lang().UnitOfMeasureConfirmationDescriptionText,
            alertMessage: this.lang().ConfirmUoMModalAlertText,
            actionButtonText: this.lang().ChangeUnit,
            dismissButtonText: this.lang().Cancel,
            isSmallConfirm: true,
            unitOfMeasureTutorialName: "StockUnits",
         },
      };

      const result = await instance.result;
      if (!result) {
         return;
      }
      await this.manageParts.setPartUnitOfMeasure(this.partID(), unit);
      this.selectedUnit.set(unit);
   }

   private filterUnitsByAnyName({
      units,
      query,
   }: {
      units: Array<Unit> | null;
      query: string;
   }): Array<Unit> | null {
      const processedQuery = query.toLowerCase().trim();
      return units === null
         ? null
         : units.filter(
              (unit) =>
                 unit.short().toLowerCase().includes(processedQuery) ||
                 unit.singular().toLowerCase().includes(processedQuery),
           );
   }

   protected disabledStockUnitClicked(): void {
      this.heapService.trackEvent("manageUnitsOfMeasure_disabledStockUnitClicked");
   }
}
