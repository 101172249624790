import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { Router } from "@angular/router";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   ProgressBarComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import $ from "jquery";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { GeneralLedger } from "src/app/purchasing/types/general-ledger.types";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "import-general-ledgers",
   templateUrl: "./importGeneralLedgers.modal.component.html",
   styleUrls: ["./importGeneralLedgers.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      LoadingAnimationComponent,
      InfoPanelComponent,
      PanelComponent,
      IconComponent,
      TooltipDirective,
      AlertComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      FileUploader,
      PrimaryButtonComponent,
      ProgressBarComponent,
      BetterDecimalPipe,
   ],
})
export class ImportGeneralLedgers implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public type;
   public errorMsg;
   public importAttempt;
   public fileReviewReady;
   public locationID;
   public uploadObject;
   public GeneralLedgersToAdd;
   public file;
   public sample_general_ledger_list;
   public buttonDisabled;
   public currentlyAt;
   public end;
   public loading;
   public axios = axios;
   public showGeneralLedgersToBeAdded = false;
   public generalLedgers: Lookup<"glID", GeneralLedger>;
   public generalLedgersGLCodeIndex = {};

   private readonly router = inject(Router);
   private readonly managePO = inject(ManagePO);
   private readonly manageUser = inject(ManageUser);
   private readonly alertService = inject(AlertService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.generalLedgers = this.managePO.getGeneralLedgers();
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      setTimeout(() => {
         if (
            this.manageUser.getCurrentUser() === "none" ||
            this.manageUser.getCurrentUser() === undefined
         ) {
            this.router.navigate(["/login"]);
         }
      }, 500);
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.type = this.resolve.type;
      this.errorMsg = false;
      this.importAttempt = false;
      this.fileReviewReady = false;

      //if this is passed we are only intending to import a location at a time.
      this.locationID = this.resolve.locationID;

      for (const generalLedger of this.generalLedgers) {
         if (generalLedger.locationID !== this.locationID || generalLedger.abbr === null)
            continue;
         this.generalLedgersGLCodeIndex[generalLedger.abbr] = generalLedger;
      }

      this.uploadObject = {};
      this.uploadObject.primaryID = "fileName";
      this.uploadObject.uploadTypes = "importFiles"; //images / documents / importFiles (excel + csv) or empty for default (images and documents)
      //bindings have to be all lowercase...
      this.uploadObject.viewOnly = false;
      this.uploadObject.onUploadPopEditor = false; //boolean will images immediately open for editing after upload
      this.uploadObject.posturl = `phpscripts/managePO.php?action=prepGeneralLedgerImport&locationID=${this.locationID}`;
      this.uploadObject.deleteData = {};

      this.uploadObject.uploadCall = async (posturl, formData) => {
         return this.axios({
            url: posturl,
            method: "POST",
            data: formData,
         });
      };

      this.uploadObject.uploadComplete = (data) => {
         // In case they try to upload an incorrect file type
         if (data.failed) {
            this.loading = false;
            return;
         }

         this.errorMsg = false;

         if (data.success === false) {
            if (data.error === "tooManyRecords") {
               this.errorMsg = this.lang().NoMoreThen1000NewAssetsWhenImporting;
            } else if (data.error === "wrongLocationID") {
               this.errorMsg = this.lang().WhoopsAssetIDsDetectedForOtherLocation;
            }
            $(".importHeader").html("");
            return;
         }
         const fields: any = [];
         for (const field in data.fields) {
            fields.push(data.fields[field]);
         }

         fields.forEach((field) => {
            if (field.uploadable === false) {
               field.error = "This type of field cannot be bulk uploaded.";
            }
         });
         if (data?.data?.length > 0) {
            for (const [index, row] of data.data.entries()) {
               if (
                  data.core.name &&
                  this.generalLedgersGLCodeIndex[row[data.core.abbr.position]]
               ) {
                  row.error = `${this.lang().ThisGeneralAlreadyExistsForThisLocation} ${
                     index + 2
                  }`;
                  continue;
               }

               if (
                  !data.core.name ||
                  (data.core.name &&
                     (!row[data.core.name.position] ||
                        row[data.core.name.position] == "" ||
                        row[data.core.name.position] === " "))
               ) {
                  row.error = `${this.lang().TheGeneralLedgerIsMissingAGeneralLedgerName} ${
                     index + 2
                  }`;
                  continue;
               }

               if (
                  !data.core.abbr ||
                  (data.core.abbr &&
                     (!row[data.core.abbr.position] ||
                        row[data.core.abbr.position].length < 1 ||
                        row[data.core.abbr.position].length > 4))
               ) {
                  row.error = `${this.lang().TheGeneralledgerAbbreviationIsInvalid} ${
                     index + 2
                  }`;
                  continue;
               }

               //if assetID was a column, figure out if they are all valid IDs
               if (data.core.assetID) {
                  if (data.found[row[data.core.assetID.position]] === false) {
                     row.error = `${this.lang().TheAssetCannotBeFound} ${index + 2}`;
                     continue;
                  }
               }
            }
         }

         data.badRows = data.data
            .filter((row) => {
               //filter creates a new array where true is returned.  Row is the data you can check to see if it should be true
               //this commented section is basically the same as return !!row.error...
               // if(!!row.error){
               //    return false;
               // }else{
               //    return true;
               // }
               return Boolean(row.error);
            })
            .map((row) => {
               const tmp: any = {};
               if (data.core.assetID) tmp.assetID = row[data.core.assetID.position];
               if (data.core.name) tmp.name = row[data.core.name.position];
               if (data.core.glDescription)
                  tmp.glDescription = row[data.core.glDescription.position];
               if (data.core.abbr) tmp.abbr = row[data.core.abbr.position];

               tmp.error = row.error;

               return tmp;
            });

         data.goodRows = data.data
            .filter((row) => {
               return !row.error;
            })
            .map((row) => {
               const tmp: any = {};
               if (data.core.assetID) tmp.assetID = row[data.core.assetID.position];
               if (data.core.name) tmp.name = row[data.core.name.position];
               if (data.core.glDescription)
                  tmp.glDescription = row[data.core.glDescription.position];
               if (data.core.abbr) tmp.abbr = row[data.core.abbr.position];

               tmp.error = row.error;
               return tmp;
            });

         this.GeneralLedgersToAdd = [];

         for (const row of data.goodRows) {
            this.GeneralLedgersToAdd.push(row);
         }

         this.file = data;
         this.fileReviewReady = true;
      };

      //this timeout makes sure that the modal starts at the top of the screen
      setTimeout(() => {
         $(".modal").scrollTop(0);
      }, 1);

      this.sample_general_ledger_list = [
         {
            "General Ledger Name": "Office 1",
            "Abbreviation": "OFF1",
            "Description": "Any general supplies for Office 1",
            "Asset ID or Asset Name": "",
         },
         {
            "General Ledger Name": "Sanitation",
            "Abbreviation": "SAN",
            "Description": "Any supplies for the Sanitation Department",
            "Asset ID or Asset Name": "",
         },
         {
            "General Ledger Name": "Plumbing Supplies",
            "Abbreviation": "PLU",
            "Description": "Any supplies needed for Plumbing",
            "Asset ID or Asset Name": "",
         },
         {
            "General Ledger Name": "Electrical Supplies",
            "Abbreviation": "ELE",
            "Description": "Any supplies needed for Electrical",
            "Asset ID or Asset Name": "",
         },
      ];

      this.buttonDisabled = false;
   }

   confirmUpload = async () => {
      const sizeOfBatch = 10; //DO NOT increase above 10 because Jeff and Cloudfront will time out people
      const batches = this.manageUtil.splitArr(this.file.goodRows, sizeOfBatch);

      this.currentlyAt = 0;
      this.end = batches.length;
      this.buttonDisabled = true;
      this.loading = true;

      const processBatch = async (batch) => {
         return new Promise((resolve, reject) => {
            this.managePO.importGeneralLedgers(batch, this.locationID).then((answer) => {
               if (answer.data.success == true) {
                  this.currentlyAt++;
                  resolve(null);
               } else {
                  reject(new Error("Import Failed"));
               }
            });
         });
      };

      let failed = false;
      for (const batch of batches) {
         try {
            // eslint-disable-next-line no-await-in-loop -- BRYAN approved.
            await processBatch(batch);
         } catch (err) {
            failed = true;
            break;
         }
      }
      this.loading = false;
      this.buttonDisabled = false;
      if (failed) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
      } else {
         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
         this.modalInstance.close(0);
      }
   };

   downloadCSV = (list) => {
      this.manageUtil.objToExcel(
         list,
         "General Ledgers",
         "sample-general-ledgers-import.xlsx",
      );
   };

   close = () => {
      this.modalInstance.close(0);
   };
}
