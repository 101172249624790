import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "pick-billing-category",
   templateUrl: "./pickBillingCategory.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      NgClass,
      IconComponent,
      AlertComponent,
      BasicModalFooterComponent,
      OrderByPipe,
   ],
})
export class PickBillingCategory implements OnInit {
   public resolve;
   public modalInstance;
   public data;
   public message;
   public title;
   public errorMsg;
   public categories;
   protected dataLogSubmitLabelLookup: Record<string, string>;

   private readonly manageBilling = inject(ManageBilling);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.dataLogSubmitLabelLookup = {
         [this.lang().PickCategory]: "task-submitChangeLoggedWorkCategory",
      };
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.message = this.data.message;
      this.title = this.data.title;
      this.errorMsg = "";

      this.categories = this.manageBilling.getCategories(false);

      for (const key in this.categories) {
         this.categories[key].selected = false;
      }
   }

   selectCategory = (category) => {
      if (category.selected == true) {
         //covers duplicate click to submit
         this.submit();
      }

      this.errorMsg = "";
      for (const key in this.categories) {
         this.categories[key].selected = false;
      }
      category.selected = true;
   };

   close = () => {
      //has to clean up the selected categories
      for (const key in this.categories) {
         this.categories[key].selected = false;
      }

      this.modalInstance.close(false);
   };

   submit = () => {
      this.errorMsg = "";

      for (const key in this.categories) {
         if (this.categories[key].selected == true) {
            this.modalInstance.close(this.categories[key].categoryID);
         }
      }

      this.errorMsg = this.lang().PleasePickALaborCategory;
   };

   protected lookupDataLogSubmitLabel(): string | undefined {
      return this.dataLogSubmitLabelLookup[this.title];
   }
}
