import type { FieldFilter } from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { Filter, SearchFields } from "src/app/shared/types/general.types";

export function stringifyFilterArray(
   assetFilters: Array<Filter<AssetField>> = [],
   searchFields: SearchFields = {},
): string {
   const filterPairs: string[] = [];

   /*
     mapping according to 'assetFieldFilters' description located at flannel/src/endpoints/jit/assets/assets.types.ts
     */
   assetFilters.forEach((filter) => {
      if (filter.field && "fieldID" in filter.field) {
         const fieldData = searchFields[filter.fieldName];
         let filterValue = "";

         if (fieldData) {
            // Handle string search value
            if (fieldData.searchValue) {
               filterValue = `s-${fieldData.searchValue}`;
            }

            // Handle date range
            if (fieldData.beginDate || fieldData.endDate) {
               const beginDate = fieldData.beginDate
                  ? new Date(fieldData.beginDate).toISOString().split("T")[0]
                  : "";
               const endDate = fieldData.endDate
                  ? new Date(fieldData.endDate).toISOString().split("T")[0]
                  : "";
               filterValue = `rd-${beginDate}to${endDate}`;
            }

            // Handle number range
            if (fieldData.lowest !== undefined || fieldData.highest !== undefined) {
               const lowest = fieldData.lowest ?? "";
               const highest = fieldData.highest ?? "";
               filterValue = `rn-${lowest}to${highest}`;
            }
         }

         filterPairs.push(`${filter.field.fieldID}:${filterValue}`);
      }
   });

   return filterPairs.join(",");
}

export function parseFilterString(
   filterString: string,
   fieldInformation: Array<Filter<AssetField>> | null = null,
): Array<FieldFilter> {
   if (!filterString) {
      return [];
   }

   const fields = filterString.split(",").map((pair) => {
      const [fieldId, value] = pair.split(":");
      const field: FieldFilter = {
         fieldId: parseInt(fieldId),
      };

      // Add fieldTypeID
      if (fieldInformation) {
         const fieldInfo = fieldInformation?.find(
            (info) => Number(info.field.fieldID) === Number(fieldId),
         );
         const fieldTypeID = fieldInfo?.field.fieldTypeID ?? null;
         if (fieldTypeID) {
            field.fieldTypeID = fieldTypeID;
         }
      }

      if (value) {
         // Only split on first occurrence of "-" to preserve any in the value
         const splitIndex = value.indexOf("-");
         const type = value.slice(0, splitIndex);
         const val = value.slice(splitIndex + 1);

         if (type === "s") {
            field.fieldValue = {
               type: "text",
               value: val,
            };
         } else if (type === "rd") {
            const [beginDate, endDate] = val.split("to");
            field.fieldValue = {
               type: "dateRange",
               from: beginDate ?? "",
               to: endDate ?? "",
            };
         } else if (type === "rn") {
            const [lowest, highest] = val.split("to");
            field.fieldValue = {
               type: "numberRange",
               from: lowest ?? "",
               to: highest ?? "",
            };
         }
      }

      return field;
   });

   return fields;
}
