@if (asset) {
   <div id="assetWOPrint{{ asset?.assetID }}">
      <!-- print related content -->
      <div class="panel" style="margin-bottom: 0px">
         <div class="panel-body panel-middleGreen printViewShow">
            <div class="col-md-12">
               <h5 style="margin: 10px 0px 10px 0px">
                  <span>
                     <lim-ui-icon
                        icon="wpforms"
                        iconSize="small"
                        iconColor="violet"
                        class="icon-styling"
                     />
                     <b>{{ lang().AssetTabWorkOrdersHeading }}</b> -
                     {{ lang().AssetTabWorkOrdersHeadingMsg }}.
                  </span>
               </h5>
            </div>
         </div>
      </div>
      <div class="wo-panels-wrapper">
         <!-- Open WorkOrders Section -->
         <lim-ui-panel [noSidePadding]="true">
            <div class="wo-panel-content">
               @if (asset) {
                  <div class="header-section-row">
                     <div class="header-items-group">
                        <span
                           ><lim-ui-icon
                              icon="circleExclamation"
                              iconSize="small"
                              iconColor="violet"
                              class="icon-styling"
                           />
                           <b>{{ lang().OpenWOs }}</b>
                        </span>
                        <lim-ui-search-box
                           [(searchVal)]="searchOpenWOs"
                           (searchValChange)="buildFilteredOpenWOsData()"
                           [placeholder]="lang().Search"
                        />
                        <lim-ui-primary-button
                           icon="plus"
                           (click)="newWO()"
                           [hidden]="!(!restrict && asset.assetDeleted == 0)"
                           >{{ lang().StartWorkOrder }}</lim-ui-primary-button
                        >
                     </div>

                     @if (asset.assetChildrenIDs.length > 0) {
                        <div class="checkbox-row">
                           <lim-ui-checkbox
                              [(model)]="asset.includeChildData"
                              (modelChange)="buildOpenWOsData()"
                              [disabled]="restrict || !creds?.editAssetSettings"
                              [label]="lang().IncludeChildrenData"
                           />
                           <lim-ui-icon
                              class="lim-ui-hide-on-mobile"
                              icon="circleQuestionRegular"
                              [limUiTooltip]="
                                 creds?.viewManageAssets && !creds?.editAssetSettings
                                    ? lang().IncludeChildrenDataCredTooltip
                                    : lang().IncludeChildrenDataTooltip
                              "
                              iconSize="small"
                           />
                        </div>
                     }
                  </div>
               }

               <!-- PLAID_TODO Bring in changes from LIM-7602-->
               <view-list-of-tasks
                  [taskIDs]="taskIDs"
                  [columns]="openWOColumns"
                  [storedSortBindValue]="sortBindOpen"
                  [autoHeight]="true"
                  [tableInScrollablePage]="true"
                  [searchHints]="searchHints"
                  [noSearchResults]="noSearchResultsOpenWOs"
               />

               <div class="no-content-message" [hidden]="!(tasks.size == 0 && !restrict)">
                  <span>{{ lang().YouDoNotHaveAnyOpenWOs }}</span>
               </div>
               <div class="no-content-message" [hidden]="!restrict">
                  <span>{{ lang().OpenWOsAreCurrentlyNotAvailable }}</span>
               </div>

               @if (showLoading) {
                  <div class="listOptionHolder">
                     <div class="loading-icon">
                        <lim-ui-loading-animation />
                     </div>
                  </div>
               }
            </div>
         </lim-ui-panel>

         @if (isEmpoweredTables()) {
            <tasks-panel
               [restrictedContentLabel]="lang().CompletedWOsAreCurrentlyNotAvailable"
               [columnsViewType]="'completedTasks'"
               [requestFilters]="completedTasksRequestFilters()"
               [canExport]="canExportCompletedTasks"
               [hasRestrictedPermissions]="restrict"
               [includeChildAssets]="!!asset.includeChildData"
            >
               <span title>
                  <lim-ui-icon
                     icon="squareCheck"
                     iconSize="small"
                     iconColor="violet"
                     class="icon-styling"
                  />
                  <b>{{ lang().CompletedWOs }}</b>
               </span>
            </tasks-panel>
         } @else {
            <tasks-panel-legacy
               [noContentLabel]="lang().ThereAreNotAnyCompletedWOsForThisAsset"
               [restrictedContentLabel]="lang().CompletedWOsAreCurrentlyNotAvailable"
               [columnsViewType]="'completedTasks'"
               [requestFilters]="completedTasksRequestFilters()"
               [canExport]="canExportCompletedTasks"
               [hasRestrictedPermissions]="restrict"
               [includeChildAssets]="!!asset.includeChildData"
            >
               <span title>
                  <lim-ui-icon
                     icon="squareCheck"
                     iconSize="small"
                     iconColor="violet"
                     class="icon-styling"
                  />
                  <b>{{ lang().CompletedWOs }}</b>
               </span>
            </tasks-panel-legacy>
         }
      </div>
   </div>
}
