import { inject, Injectable, signal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import axios from "axios/dist/axios";
import { lastValueFrom } from "rxjs";
import { DEFAULT_LANGUAGE, TranslateService } from "src/app/languages";
import type { PhraseMap } from "src/app/languages/translation/phrase-map.types";
import { loadJs } from "src/app/shared/external-scripts/util/load-js";
import { ManageSubscription } from "src/app/subscriptions/services/manageSubscription";
import { ManageTrials } from "src/app/subscriptions/services/manageTrials";
import { AssetsApiService } from "src/app/tasks/components/shared/services/assets-api/assets-api.service";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { environment } from "src/environments/environment";

export enum ChurnZeroEventNames {
   Login = "Login",
}

export type ChurnZeroEvent = {
   name: ChurnZeroEventNames;
   description?: string;
   quantity?: number;
};

type EmptyObject = Record<string, never>;

type LicenseInfo = {
   full: number;
   operator: number;
   viewOnly: number;
};

type UserInfo = {
   customerName: string;
   customerPlan: string;
   customerStatus: string;
   fName: string;
   isSuperUser: boolean;
   lName: string;
   salesforceID: string;
   ssoUser: 0 | 1;
   userEmail: string;
   userInternal: 0 | 1;
};

@Injectable({ providedIn: "root" })
export class ChurnZeroService {
   private apiKey = "";
   private apiEndpoint = "";
   private eventQueue: Array<ChurnZeroEvent> = [];

   private readonly axios = axios;
   private readonly window: any = window;
   private readonly isConnected = signal(false);
   private readonly connectedSub = toObservable(this.isConnected).subscribe(
      (isConnected) => {
         if (isConnected) {
            this.purgeQueue();
            this.setAttributes();
         }
      },
   );
   private readonly assetService = inject(AssetsApiService);
   private readonly manageSubscription = inject(ManageSubscription);
   private readonly manageTrial = inject(ManageTrials);
   private readonly manageUser = inject(ManageUser);
   private readonly taskService = inject(TasksApiService);
   private readonly translateService = inject(TranslateService);

   public async loadAndTrackLogin(): Promise<void> {
      if (!this.shouldUse()) {
         return;
      }

      await this.importEnvData();

      if (!this.hasEnvData()) {
         return;
      }

      const isInstantiated = await this.instantiate();
      if (isInstantiated) {
         const userInfo = this.getUserInfo();
         const isSSO = userInfo.ssoUser === 1;
         this.trackEvent({
            name: ChurnZeroEventNames.Login,
            description: isSSO ? "SSO" : "Standard",
         });
      }
   }

   public trackEvent(evt: ChurnZeroEvent) {
      if (this.isConnected() && this.eventQueue.length === 0) {
         this.sendTrackEvent(evt);
      } else {
         this.eventQueue.push(evt);
      }
   }

   public unload() {
      if (this.window.ChurnZero !== undefined) {
         this.window.ChurnZero.push(["stop"]);
         this.window.ChurnZero.push(["ChurnZeroEvent:off", "connected"]);
      }
      this.connectedSub.unsubscribe();

      // Clear the queue on unload. If it isn't empty at this point, it's most likely that we were never
      // able to establish a ChurnZero connection. Clear it in preparation for the next session.
      this.eventQueue = [];
   }

   private shouldUse(): boolean {
      const userInfo = this.getUserInfo();

      // For Prod, only activate for users who are NOT:
      // a) part of a trial account
      // b) internal
      return (
         environment.production !== true ||
         (!["trialing", "in_trial"].includes(userInfo.customerStatus ?? "") &&
            userInfo.userInternal !== 1)
      );
   }

   private async importEnvData() {
      if (this.hasEnvData()) {
         return;
      }

      try {
         const response = await this.axios.get("phpscripts/manageIntegrations.php", {
            params: {
               action: "getChurnZeroData",
            },
         });

         if (response.status !== 200) {
            throw new Error("ChurnZero integration data request was unsuccessful");
         }
         this.apiKey = response.data.key ?? "";
         this.apiEndpoint = response.data.endpoint ?? "";
      } catch (error) {
         this.logError((error as Error).message ?? "");
      }
   }

   private hasEnvData(): boolean {
      return this.apiKey.length > 0 && this.apiEndpoint.length > 0;
   }

   private sendTrackEvent(evt: ChurnZeroEvent) {
      const details = ["trackEvent", evt.name];
      const desc = evt.description ?? "";
      if (desc.length) {
         details.push(desc);
      }
      this.window.ChurnZero.push(details);
   }

   private async instantiate(): Promise<boolean> {
      if (!this.isLoaded()) {
         await this.load();
      }

      if (!this.isLoaded()) {
         this.logError("Javascript was not loaded");
         return false;
      }

      if (!this.isConnected()) {
         /*
         * For troubleshooting, if needed.
         * Ref: https://support.churnzero.com/hc/en-us/articles/360004683552-Integrate-ChurnZero-using-Javascript
         * Running either of these in the dev tool's console is preferable but uncommenting them here
         * can also be useful.
         /*
         /*
         // Note: this is a toggle. Each time it's executed, the current users' session is put into or taken out of debug mode.
         // This can be make troubleshooting difficult as the mode persists across browser refreshes.
         this.window.ChurnZero.debug();

         // Note: this outputs some troubleshooting data to the dev tool console.
         // Helpful for verifying ChurnZero activity and connection status.
         this.window.ChurnZero.verify();
         */

         this.window.ChurnZero.push([
            "ChurnZeroEvent:on",
            "connected",
            () => {
               this.isConnected.set(true);
            },
         ]);

         this.window.ChurnZero.push(["setAppKey", this.apiKey]);
         this.identifyUser();
      }

      return true;
   }

   private logError(errorMsg: string) {
      console.error(`ChurnZero could not be initialized. ${errorMsg}.`);
   }

   private async load(): Promise<void> {
      this.window.ChurnZero = [];
      return loadJs(`https://${this.apiEndpoint}/churnzero.js`);
   }

   private isLoaded(): boolean {
      return this.window.ChurnZero !== undefined;
   }

   private identifyUser() {
      const userInfo = this.getUserInfo();
      let error: string | undefined;

      if (userInfo.salesforceID === undefined) {
         error = "User is missing Salesforce ID";
      } else if (userInfo.userEmail === undefined) {
         error = "User is missing email address";
      }

      if (error !== undefined) {
         this.logError(error);
         return;
      }

      this.window.ChurnZero.push([
         "setContact",
         userInfo.salesforceID,
         userInfo.userEmail,
      ]);
   }

   private getUserInfo(): UserInfo | EmptyObject {
      const currentUser = this.manageUser.getCurrentUser();
      return currentUser.userInfo ?? {};
   }

   private async setAttributes() {
      const assetCount = await this.getAssetCount();
      const completedTaskCount = await this.getCompletedTaskCount();
      const planName = await this.getPlanName();
      const licenseInfo = this.getLicenseInfo();
      const userInfo = this.getUserInfo();

      this.window.ChurnZero.push([
         "setAttribute",
         "account",
         {
            "Full License Count": licenseInfo.full,
            "Operator License Count": licenseInfo.operator,
            "Plan": planName,
            "View Only License Count": licenseInfo.viewOnly,
         },
      ]);
      if (userInfo.isSuperUser) {
         // Only send these attributes for super users so that the counts aren't affected by access restrictions
         this.window.ChurnZero.push([
            "setAttribute",
            "account",
            {
               "Number of Assets": assetCount,
               "Number of Completed Tasks": completedTaskCount,
            },
         ]);
      }

      this.window.ChurnZero.push([
         "setAttribute",
         "contact",
         {
            "Account Name": userInfo.customerName,
            "Email": userInfo.userEmail,
            "FirstName": userInfo.fName,
            "LastName": userInfo.lName,
            // 'Role': "", // TODO: after figuring out details (with ChurnZero folks) of how to pass roles by location (of possible)
            "Super User": userInfo.isSuperUser,
         },
      ]);
   }

   private getLicenseInfo(): LicenseInfo {
      const users = this.manageUser.getNeededLicenses();

      return {
         full: users.regular,
         operator: users.minimalUse,
         viewOnly: users.viewOnly,
      };
   }

   private async getPlanName(): Promise<string> {
      await this.manageSubscription.getChargifyData();
      const billingInfo = this.manageSubscription.getBilling();
      const userInfo = this.getUserInfo();
      let planNamePhraseKey: keyof PhraseMap = "Other";

      try {
         const planNameRaw =
            billingInfo?.chargifyPlan?.component_handle ?? userInfo.customerPlan;
         planNamePhraseKey =
            this.manageTrial.getTrialDisplayInfo(planNameRaw)?.tierTextPhraseKey;
      } catch (error) {
         planNamePhraseKey = "Other";
         console.error(error);
      }

      const planName = await lastValueFrom(
         this.translateService.select(planNamePhraseKey, DEFAULT_LANGUAGE.languageCode),
      );
      return planName;
   }

   private async getAssetCount(): Promise<number> {
      let count = 0;
      try {
         count = await this.assetService.getTotal();
      } catch (error) {
         count = 0;
         console.error(error);
      }
      return count;
   }

   private async getCompletedTaskCount(): Promise<number> {
      let count = 0;
      try {
         count = await lastValueFrom(this.taskService.getCompletedTasksCount());
      } catch (error) {
         count = 0;
         console.error(error);
      }
      return count;
   }

   private purgeQueue() {
      while (this.eventQueue.length !== 0) {
         this.sendTrackEvent(this.eventQueue.shift() as ChurnZeroEvent);
      }
   }
}
