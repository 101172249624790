import type { AfterViewInit, OnInit } from "@angular/core";
import { inject, Component, ViewChild, computed } from "@angular/core";
import type { LimUiModalRef } from "@limblecmms/lim-ui";
import {
   BasicModalHeaderComponent,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { GisMap } from "src/app/maps/components/gisMap/gisMap.element.component";
import { LinkArcGis } from "src/app/maps/components/linkArcGisModal/linkArcGis.modal.component";
import { ManageAtlas } from "src/app/maps/services/manageAtlas";
import { ManageMaps } from "src/app/maps/services/manageMaps";
import type { GeoCoordinates, GeoFeature } from "src/app/maps/types/geoMap.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";

@Component({
   selector: "view-map",
   templateUrl: "./viewMap.modal.component.html",
   styleUrls: ["./viewMap.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
      GisMap,
      ModalFooterComponent,
   ],
})
export class ViewMap implements OnInit, AfterViewInit {
   @ViewChild("gisMap") readonly gisMap: GisMap | undefined;
   public title: string = "";
   public resolve: any;
   public modalInstance: LimUiModalRef | null = null;
   public asset: Asset | undefined;
   public task: Task | undefined;
   public taskID: number | undefined;
   public geoLocation: GeoFeature | null = null;
   public canEdit: boolean = false;
   public addGeo: boolean = false;
   public editGeo: boolean = false;
   public updateGeolocation: any;
   public deviceLocation: GeoCoordinates | undefined;
   public initialBoundaries;
   public locationID: number | undefined;
   public location: any;
   public limitedMode: boolean = false;
   public showMyLocation: boolean = false;
   public encodedArcGisAPICred: string | undefined;
   public locationMappedToArcGis: boolean = false;
   public currentFeatureServiceID: number | undefined;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly manageMaps = inject(ManageMaps);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAtlas = inject(ManageAtlas);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);

   public deviceGeoEnabled: boolean = this.manageMaps.isDeviceGeoEnabled();

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
         this.title = this.resolve.title ?? "";
         this.asset = this.resolve.asset;
         this.taskID = this.resolve.taskID;
         this.task = this.resolve.task;
         this.location = this.resolve.location;
         this.geoLocation = this.resolve.geoLocation;
         this.updateGeolocation = this.resolve.updateGeolocation;
         this.locationID = this.resolve.locationID;
         this.canEdit = this.resolve.canEdit ?? false;
         this.showMyLocation = this.resolve.showMyLocation ?? false;
         this.limitedMode = this.resolve.limitedMode ?? false;
         if (this.asset?.geoLocation) {
            this.geoLocation = this.asset.geoLocation;
         } else if (this.location?.geoLocation) {
            this.geoLocation = this.location.geoLocation;
         } else if (this.task?.geoLocation) {
            this.geoLocation = this.task.geoLocation;
         } else if (this.task && !this.task.geoLocation && this.task.assetID) {
            const asset = this.manageAsset.getAsset(this.task.assetID);
            if (asset?.geoLocation) {
               this.geoLocation = asset.geoLocation;
            }
         }
         this.locationMappedToArcGis = this.resolve.locationMappedToArcGis;
         this.currentFeatureServiceID = this.resolve.currentFeatureServiceID;
         this.encodedArcGisAPICred = this.resolve.encodedArcGisAPICred;
      }
      this.loadInitialData();
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
   }

   public async loadInitialData() {
      if (!this.geoLocation) {
         await this.setInitialBoundaries();
      }
   }

   public ngAfterViewInit() {
      if (this.limitedMode && !this.geoLocation) {
         this.enableAddGeo();
      }
   }

   public async setGeoFromDeviceLocation() {
      let deviceGeo: any = {};
      try {
         deviceGeo = await this.manageMaps.getDeviceLocationInGeoJsonCoordsFormat();
      } catch (err) {
         console.error(err);
         this.alertService.addAlert(
            this.lang().CouldNotAccessThisDevicesLocation,
            "danger",
            6000,
         );
         return;
      }
      if (deviceGeo?.length > 0) {
         this.geoLocation = {
            geometry: {
               coordinates: [deviceGeo[0], deviceGeo[1]],
               type: "Point",
            },
            properties: {},
            type: "Feature",
         };
         this.saveGeo(this.geoLocation);
      }
   }

   public async removeGeo() {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.locationMappedToArcGis
               ? this.lang().DeleteAssetMapping
               : this.lang().DeleteMapLocationMsg,
            title: this.locationMappedToArcGis
               ? this.lang().DeleteAssetMappingTitle
               : this.lang().DeleteMapLocationTitle,
         },
      };
      const result = await instance.result;

      if (result === 0) {
         return;
      }
      if (this.locationMappedToArcGis) {
         const removeArcGisLink = await this.manageAtlas.deleteAssetMap(
            this.encodedArcGisAPICred,
            this.asset?.assetID,
            this.currentFeatureServiceID,
         );
         if (removeArcGisLink.data.success === "false") {
            this.alertService.addAlert(this.lang().AssetMapNotDeleted, "danger", 6000);
         } else {
            this.geoLocation = null;
            this.saveGeo(this.geoLocation);
            this.locationMappedToArcGis = false;
            this.alertService.addAlert(this.lang().AssetMapDeleted, "success", 1000);
         }
      } else {
         this.geoLocation = null;
         this.saveGeo(this.geoLocation);
      }
   }

   private async saveGeo(geo) {
      if (this.asset) {
         const results = await this.manageMaps.saveAssetGeo(this.asset.assetID, geo);
         if (results?.data?.success) {
            this.asset.geoLocation = geo;
         }
      } else if (this.task) {
         const results = await this.manageMaps.saveTaskGeo(this.task.checklistID, geo);
         if (results?.data?.success) {
            this.task.geoLocation = geo;
            this.manageTask.incTasksWatchVar();
         }
      } else if (this.location) {
         const results = await this.manageMaps.saveLocationGeo(
            this.location.locationID,
            geo,
         );
         if (results?.data?.success) {
            this.location.geoLocation = geo;
         }
      } else if (this.updateGeolocation) {
         this.updateGeolocation(geo);
      }
   }

   public updateGeo(geoData) {
      if (this.updateGeolocation) {
         this.updateGeolocation(geoData);
      }
   }

   public enableAddGeo() {
      this.addGeo = true;
      if (this.gisMap) {
         this.gisMap.enableAddGeo();
      }
   }

   public disableAddGeo() {
      this.addGeo = false;
      if (this.gisMap) {
         this.gisMap.disableAddGeo();
      }
   }

   public enableEditGeo() {
      if (this.locationMappedToArcGis === true) {
         this.alertService.addAlert(
            this.lang().CannotEditArcGisMappedLocation,
            "danger",
            6000,
         );
         return;
      }
      this.editGeo = true;
      if (this.gisMap) {
         this.gisMap.enableEditGeo();
      }
   }

   public disableEditGeo() {
      this.editGeo = false;
      if (this.gisMap) {
         this.gisMap.disableEditGeo();
      }
   }

   public cancel(): void {
      if (!this.gisMap) {
         return;
      }
      if (this.editGeo) {
         this.gisMap.cancelEditGeo();
      } else {
         this.gisMap.cancelAddGeo();
      }
      this.editGeo = false;
      this.addGeo = false;
   }

   public save(): void {
      if (this.editGeo) {
         this.disableEditGeo();
      } else {
         this.disableAddGeo();
      }
   }

   public close() {
      this.modalInstance?.close({
         locationMappedToArcGis: this.locationMappedToArcGis,
      });
   }

   private async setInitialBoundaries() {
      let locationGeo;
      if (this.locationID) {
         locationGeo =
            this.manageLocation.getLocationsIndex()[this.locationID]?.geoLocation;
      }

      try {
         this.deviceLocation =
            await this.manageMaps.getDeviceLocationInGeoJsonCoordsFormat();
      } catch (err) {
         console.error(err);
      }
      if (this.deviceLocation && this.deviceLocation.length > 0) {
         this.deviceGeoEnabled = true;
         this.initialBoundaries = {
            geo: [this.deviceLocation[1], this.deviceLocation[0]],
            zoom: 13,
         };
      } else if (locationGeo) {
         this.initialBoundaries = {
            geo: [
               locationGeo.geometry.coordinates[1],
               locationGeo.geometry.coordinates[0],
            ],
            zoom: 13,
         };
      }
   }

   public navigateToLocation(geoLocation) {
      this.manageMaps.navigateToGeolocation(geoLocation.geometry.coordinates);
   }

   public async addArcGisAssetMap() {
      const instance = this.modalService.open(LinkArcGis);
      this.paramsService.params = {
         modalInstance: instance,
         encodedArcGisAPICred: this.encodedArcGisAPICred,
         assetID: this.asset?.assetID,
         currentFeatureServiceID: this.currentFeatureServiceID,
      };
      const result = await instance.result;
      if (result.success === false) {
         return;
      }
      this.locationMappedToArcGis = true;
      this.geoLocation = result.geoLocation;
      this.saveGeo(this.geoLocation);
      this.alertService.addAlert(this.lang().LinkArcGisSuccess, "success", 1000);
   }
}
