import { Injectable } from "@angular/core";
import { map, switchMap, tap, throwError, timer, type Observable } from "rxjs";
import type {
   PmSuggestionDto,
   ScheduleDto,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestion-dto";
import type { PmSuggestionsPersistenceInterface } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestions-persistence";
import type {
   CreateSuggestion,
   CreateSuggestionSchedule,
   GetPmSuggestionsParams,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource-types";

/**
 * An implementation of the PmSuggestionsPersistenceInterface which uses a crude
 * in-memory database instead of long-term persistence. Geared towards testing.
 */
@Injectable({ providedIn: "root" })
export class PmSuggestionsMemoryService implements PmSuggestionsPersistenceInterface {
   private databaseRecords: Array<PmSuggestionDto> = [
      {
         predicted_maintenance_id: 1,
         name: "PM Suggestion 1",
         schedule: {
            type: "time",
            units: "daily",
            interval: 1,
         },
         instructions: ["Instruction 1"],
         customer_id: 1,
         asset_id: 1,
         document_id: "1234567890",
      },
   ];
   private state: "up" | "down" = "up";
   private readonly delay = 0;

   public get(params: GetPmSuggestionsParams): Observable<Array<PmSuggestionDto>> {
      if (this.state === "down") {
         return timer(this.delay).pipe(
            switchMap(() => throwError(() => new Error("Service is down"))),
         );
      }
      /* TODO(WRENCH-262): Implement filters */
      if (params.filters !== undefined && Object.keys(params.filters).length > 0) {
         console.warn(
            `filters are not yet implemented: ${JSON.stringify(params.filters)}`,
         );
      }
      return timer(this.delay).pipe(map(() => this.databaseRecords));
   }

   public delete(params: { ids: Array<number> }): Observable<void> {
      if (this.state === "down") {
         return timer(this.delay).pipe(
            switchMap(() => throwError(() => new Error("Service is down"))),
         );
      }
      return timer(this.delay).pipe(
         map(() => undefined),
         tap(() => {
            this.databaseRecords = this.databaseRecords.filter(
               (suggestion) => !params.ids.includes(suggestion.predicted_maintenance_id),
            );
         }),
      );
   }

   public create(params: { suggestion: CreateSuggestion }): Observable<void> {
      if (this.state === "down") {
         return timer(this.delay).pipe(
            switchMap(() => throwError(() => new Error("Service is down"))),
         );
      }
      return timer(this.delay).pipe(
         map(() => undefined),
         tap(() => {
            //eslint-disable-next-line typescript/consistent-type-assertions -- I think this is a bug in Typescript?
            const newRecord = this.createSuggestionDto(params.suggestion);
            this.databaseRecords = [...this.databaseRecords, newRecord];
         }),
      );
   }

   private createSuggestionDto(suggestion: CreateSuggestion): PmSuggestionDto {
      const newId = this.databaseRecords.length + 1;
      return {
         predicted_maintenance_id: newId,
         name: suggestion.name,
         instructions: suggestion.instructions,
         document_id: suggestion.documentID,
         schedule: this.createScheduleDto(suggestion.schedule),
         asset_id: suggestion.assetID,
         customer_id: suggestion.customerID,
      };
   }

   private createScheduleDto(schedule: CreateSuggestionSchedule): ScheduleDto {
      if (schedule.type === "time") {
         return {
            type: "time",
            units: schedule.units,
            interval: schedule.interval,
         };
      }
      return {
         type: "metric",
         units: schedule.units,
         interval: schedule.interval,
         start_at: schedule.startAt,
      };
   }

   public setState(state: "up" | "down"): void {
      this.state = state;
   }
}
