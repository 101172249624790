<ng-container *translate="let t">
   <data-viewer-search
      [isClearable]="true"
      [initialValue]="assetFieldsFilter()?.search"
      (searchChange)="onSearchChange($event)"
      data-log="assetTemplatesApplyTemplateFlow-selectAssets-clickSearch"
   />
   <data-viewer-filters
      [filters]="filters"
      [showActiveFilters]="false"
      (set)="onSetFilter($event)"
      (remove)="onRemoveFilter($event)"
   />

   @if (isLoading()) {
      <ngx-skeleton-loader count="3" [theme]="skeletonThemes.fillSize" /><br />
   } @else {
      @if (hasAssets()) {
         <div class="lim-ui-pick-list-header">
            <div class="list-info list-header-group">
               <p>
                  {{ t("AssetsFrom") }}: <b>{{ selectedLocation().locationName }}</b>
               </p>

               <data-viewer-pagination-info
                  [pageSize]="dataViewerState.pageSize()"
                  [currentPage]="dataViewerState.page()"
                  [totalResults]="total() ?? 0"
               />
            </div>
            <div class="list-header-group">
               <p class="selected-info">
                  <span class="badge-selected-count">
                     {{ selectedAssetCount() }}
                  </span>
                  {{ t("Selected") }}
               </p>
               <lim-ui-selection-controls
                  [selectAll]="t('SelectAll')"
                  [deselectAll]="t('DeselectAll')"
                  (handleSelectAll)="selectAllAssets(true)"
                  (handleDeselectAll)="selectAllAssets(false)"
               />
            </div>
         </div>
      }
      @for (asset of assets(); track asset.assetID) {
         <div
            (click)="selectAsset(asset)"
            class="lim-ui-list-item lim-ui-selectable-list-item"
            [ngClass]="{
               'lim-ui-selected-list-item lim-ui-selected-pick-list-item': asset.selected,
            }"
            data-log="assetTemplatesApplyTemplateFlow-selectAssets-clickAnyAsset"
         >
            <div class="lim-ui-list-item-content-group">
               @if (asset.selected) {
                  <lim-ui-icon icon="check" iconSize="small" iconColor="success" />
               }
               <lim-ui-icon icon="cube" />
               <span>{{ asset.assetName }}</span>
            </div>
            @if (canViewAssets) {
               <div class="list-item-actions">
                  <lim-ui-text-button
                     (click)="viewAsset(asset); $event.stopPropagation()"
                     data-log="assetTemplatesApplyTemplateFlow-selectAssets-clickViewAsset"
                  >
                     {{ t("View") }}
                  </lim-ui-text-button>
               </div>
            }
         </div>
      } @empty {
         <span class="no-search-results">
            <no-search-results />
         </span>
      }
      @if (hasAssets()) {
         <data-viewer-paginator
            class="table-footer"
            [totalItems]="total() ?? 0"
            [areResultsPerPageEnabled]="true"
            [page]="dataViewerState.page()"
            [pageSize]="dataViewerState.pageSize()"
            (pageChange)="pageChanged($event)"
            (pageSizeChange)="pageSizeChange($event)"
         />
      }
   }
</ng-container>
