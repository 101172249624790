import { NgClass } from "@angular/common";
import type { OnChanges, OnInit } from "@angular/core";
import { inject, Component, EventEmitter, Input, Output, computed } from "@angular/core";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import { ManageLang } from "src/app/languages/services/manageLang";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { ManageTask } from "src/app/tasks/services/manageTask";

@Component({
   selector: "dashboard-list-view-obj-columns-headers",
   templateUrl: "./dashboardListViewObjColumnsHeaders.element.component.html",
   imports: [SortColumn, NgClass],
})
export class DashboardListViewObjColumnsHeaders implements OnInit, OnChanges {
   @Input() public sort;
   @Output() public readonly sortChange = new EventEmitter();
   @Input() public columns;
   @Input() public dashboardID;

   private readonly manageTask = inject(ManageTask);
   private readonly manageDashboard = inject(ManageDashboard);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnChanges(changes) {
      if (changes?.columns?.currentValue !== changes?.columns?.previousValue) {
         setTimeout(() => {
            this.buildData();
         }, 0);
      }
   }

   public ngOnInit() {
      this.buildData();
   }

   buildData = () => {
      if (this.columns === undefined) {
         return;
      }

      const columnWidthObj = this.manageTask.getlistViewColumnWidthObj(
         this.columns.length,
      ); //this gets the dynamic widths for the columns

      let counter = 0;

      for (const column of this.columns) {
         if (column) {
            let columnWidth = columnWidthObj[counter];

            if (column.manualWidth) {
               columnWidth = column.manualWidth;
            }
            column.columnWidth = columnWidth;
            counter++;
         }
      }
   };

   updateUserUIPreferences = () => {
      // currently we're only saving the sorting preference for custom dashboards
      if (!this.dashboardID) {
         return;
      }

      this.manageDashboard.updateUserUIPreferences(this.dashboardID, this.sort);
   };
}
