@if (part) {
   <lim-ui-panel id="partLogPrint{{ part.partID }}">
      <!-- header section (search, include children, && print header that is hidden until printing) -->
      <div>
         <div class="header-section-row">
            <data-viewer-search
               [isClearable]="true"
               (searchChange)="onSearchChange($event)"
            />
            @if (showDownloadButton) {
               <div class="right-align-buttons">
                  <lim-ui-icon-button
                     class="lim-ui-hide-on-mobile"
                     icon="cloudArrowDown"
                     (click)="downloadPartHistory()"
                     limUiTooltip="{{ lang().ExportListToCSV }}"
                     placement="left"
                  />
               </div>
            }
         </div>
      </div>

      <div class="panel-body panel-bottom panel-middleGreen">
         @if (part) {
            <div class="listTable lim-ui-responsive-table">
               <data-viewer-filters
                  class="filters-row"
                  [filters]="filters"
                  (set)="onSetFilter($event)"
                  (remove)="onRemoveFilter($event)"
               />
               <part-logs-data-viewer
                  [columns]="columns"
                  [isLoading]="loadingBar"
                  [noSearchResults]="false"
                  [partLogs]="partLogs()"
                  [totalPartLogs]="partLogsTotal()"
                  [isAllPartsLog]="isAllPartsLog()"
               />

               @if (!restrict) {
                  <div class="comments-container hidePrint">
                     @if (part.partID !== 0) {
                        <div class="comments-instructions-and-entry-container">
                           <div class="instruction-text">
                              {{ lang().AddEntry }}
                           </div>
                        </div>
                        <div class="comment-input-box">
                           <textarea
                              class="form-control log-input-field"
                              [(ngModel)]="textareaEntry"
                           >
                           </textarea>

                           <div class="bottom-buttons-row">
                              <lim-ui-primary-button (click)="addEntry()">
                                 {{ lang().Submit }}
                              </lim-ui-primary-button>
                           </div>
                           <div class="entry-alert">
                              <lim-ui-alert
                                 [title]="lang().Warning"
                                 [hidden]="!errorMsg"
                                 alertType="warning"
                              >
                                 <span [limbleHtml]="errorMsg"></span>
                              </lim-ui-alert>

                              <lim-ui-alert
                                 [hidden]="!successMsg"
                                 [title]="lang().Success"
                                 alertType="success"
                              >
                                 <span [limbleHtml]="successMsg"></span>
                              </lim-ui-alert>
                           </div>
                        </div>
                     }
                  </div>
               }
            </div>
         }
      </div>
   </lim-ui-panel>
}
