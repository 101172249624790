import {
   partAssociationsDtoStrict,
   partDtoStrict,
} from "src/app/parts/schemata/part.schema.strict";
import { unitDescriptionDtoSchema } from "src/app/parts/unit-of-measure/unit-of-measure.dto";
import { nullableExcept } from "src/app/shared/utils/validation.utils";
import { z } from "zod";

const partDtoBase = nullableExcept(partDtoStrict, [
   "partID",
   "locationID",
   "categoryID",
]).extend({
   unitDescription: unitDescriptionDtoSchema,
});

export const partDto = partDtoBase.extend({
   isStockUnitLocked: z.boolean(),
});

const partAssociationDto = partAssociationsDtoStrict;

export const partAndAssociationDto = partDtoBase.merge(partAssociationDto);
