import type { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { lastValueFrom, map, type Observable } from "rxjs";
import {
   FlannelApiService,
   type GroupedListResponse,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type {
   TaskTemplateEntity,
   TaskTemplateEntityFilters,
} from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";

export type PmTemplatesExportData = {
   "Task Name": string;
   "Asset Name": string;
   "Location Name": string;
   "Assigned To User": string;
   "Assigned To Profile": string;
   "Timezone": string;
   "Description": string;
   "Estimated Time in Minutes": number;
   "Green Color Config": number;
   "Orange Color Config": number;
   "Red Color Config": number;
   "Schedules": string;
   "Instructions": string;
   "Schedules JSON": string;
   "Instructions JSON": string;
   "Options JSON": string;
};

@Injectable({
   providedIn: "root",
})
export class TaskTemplatesApiService extends FlannelApiService<
   TaskTemplateEntity,
   TaskTemplateEntityFilters
> {
   private constructor() {
      super("task-templates");
   }

   public override async *getStreamedList(
      requestOptions?: Partial<RequestOptions<TaskTemplateEntityFilters>>,
   ): AsyncGenerator<TaskTemplateEntity, void> {
      if (requestOptions?.filters?.taskIDs === undefined) {
         for await (const task of super.getStreamedList(requestOptions)) {
            yield task;
         }
         return;
      }

      const taskIDs = requestOptions.filters.taskIDs;
      const limit = 100;

      for (let startIdx = 0; startIdx < taskIDs.length; startIdx += limit) {
         const currentBatch = super.getStreamedList({
            ...requestOptions,
            filters: {
               ...requestOptions.filters,
               taskIDs: taskIDs.slice(startIdx, startIdx + limit),
            },
         });
         // eslint-disable-next-line no-await-in-loop -- This is a generator, Promise.all doesn't make sense.
         for await (const task of currentBatch) {
            yield task;
         }
      }
   }

   public async getExhaustiveList(
      requestOptions?: Partial<RequestOptions<TaskTemplateEntityFilters>>,
   ): Promise<Array<TaskTemplateEntity>> {
      const templates: Array<TaskTemplateEntity> = [];
      const stream = this.getStreamedList(requestOptions);
      for await (const template of stream) {
         templates.push(template);
      }
      return templates;
   }

   public async *getStreamedListGroupedByLocationID(
      requestOptions: Partial<RequestOptions<TaskTemplateEntityFilters>>,
   ): AsyncGenerator<GroupedListResponse<TaskTemplateEntity>[], void> {
      if (
         requestOptions?.filters?.locationIDs === undefined ||
         requestOptions.filters.groupBy !== "locationID"
      ) {
         console.error(
            "locationIDs and groupBy must be provided to getStreamedListGroupedByLocationID",
         );
         return;
      }
      const locationIDs = requestOptions.filters.locationIDs;
      const limit = 100;
      let cursor = 0;
      do {
         const currentPageOfLocationIDs = locationIDs.slice(cursor, cursor + limit);

         // eslint-disable-next-line no-await-in-loop -- This is a generator, Promise.all doesn't make sense.
         const currentGroupedList = await lastValueFrom(
            super
               .request("GET", {
                  ...requestOptions,
                  filters: {
                     ...requestOptions.filters,
                     locationIDs: currentPageOfLocationIDs,
                  },
                  observe: "response",
               })
               .pipe(
                  map((res) =>
                     this.mapGroupedListResponse(
                        res as HttpResponse<Array<TaskTemplateEntity>>,
                     ),
                  ),
               ),
         );

         cursor += limit;

         const sleepDuration = locationIDs.length / 5;

         if (cursor !== 0) {
            // eslint-disable-next-line no-await-in-loop -- This generator needs to have a sleep to prevent hammering flannel
            await super.sleep(sleepDuration);
         }

         yield currentGroupedList;
      } while (cursor <= locationIDs.length);
   }

   /*  eslint-disable typescript/no-unused-vars -- The variables are needed to override */
   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override create(
      body: Partial<TaskTemplateEntity>,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<TaskTemplateEntity | null> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override update(
      id: number,
      body: Partial<TaskTemplateEntity>,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<TaskTemplateEntity> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override delete(
      id: number,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<TaskTemplateEntity> {
      throw new Error("Method not implemented.");
   }

   public async exportPmTemplates(templateIDs: Array<number>): Promise<
      AxiosResponse<
         | Array<PmTemplatesExportData>
         // The response is an empty string (and status is 200) if an exception is thrown in the backend, due to PHP silliness.
         | ""
      >
   > {
      return axios.post(
         "phpscripts/checklistManager.php",
         { taskIDs: templateIDs },
         { params: { action: "exportPMTemplatesV2" } },
      );
   }

   public async deleteLegacy(
      checklistID: number,
      locationID: number,
      assetID?: number,
   ): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "deleteChecklistTemplate",
         },
         data: {
            chk: checklistID,
            locationID: locationID,
            assetID: assetID,
         },
      });
   }
   /*  eslint-enable typescript/no-unused-vars  */
}
