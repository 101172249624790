import {
   trigger,
   state,
   style,
   transition,
   animate,
   type AnimationEvent,
} from "@angular/animations";
import {
   ChangeDetectionStrategy,
   Component,
   effect,
   ElementRef,
   inject,
   input,
   signal,
} from "@angular/core";
import { filter, firstValueFrom, Subject } from "rxjs";
import { PmBuilderPanelBodyComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel-body/pm-builder-panel-body.component";
import { PmBuilderPanelHeaderComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel-header/pm-builder-panel-header.component";
import { PmBuilderPanelService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel.service";

/**
 * A panel that displays and generates PM suggestions, which can be used to build PMs.
 *
 * CSS Variables and their defaults:
 * - `--pm-builder-panel-title-size: 20px;`. Determines the font size of the title.
 * - `--pm-builder-panel-padding-small: 16px;` Padding for the panel on small screens.
 * - `--pm-builder-panel-padding-large: 24px;` Padding for the panel on large screens.
 */
@Component({
   selector: "pm-builder-panel",
   templateUrl: "./pm-builder-panel.component.html",
   styleUrls: ["./pm-builder-panel.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [PmBuilderPanelHeaderComponent, PmBuilderPanelBodyComponent],
   animations: [
      trigger("accordion", [
         state("expanded", style({ height: "*" })),
         state("collapsed", style({ height: "0" })),
         transition("expanded <=> collapsed", animate("200ms ease-in-out")),
      ]),
   ],
   providers: [PmBuilderPanelService],
})
export class PmBuilderPanelComponent {
   public readonly limits = input<{ locationID?: number; assetID?: number }>({});

   private readonly pmSuggestionsPanelService = inject(PmBuilderPanelService);
   private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);

   public readonly isExpanded = signal<boolean>(false);
   public readonly animationEvents$ = new Subject<AnimationEvent>();

   public constructor() {
      effect(() => {
         this.pmSuggestionsPanelService.limits.set(this.limits());
      });
   }

   public toggle() {
      this.isExpanded.update((expanded) => !expanded);
   }

   public async scrollToAndExpand(): Promise<void> {
      const element = this.elementRef.nativeElement;
      const scrollOptions: ScrollIntoViewOptions = { behavior: "smooth", block: "start" };
      element.scrollIntoView(scrollOptions);
      if (this.isExpanded() === false) {
         this.isExpanded.set(true);
         // Wait for animation to finish, else it may not scroll far enough.
         await firstValueFrom(
            this.animationEvents$.pipe(
               filter(
                  (value) => value.phaseName === "done" && value.toState === "expanded",
               ),
            ),
         );
         element.scrollIntoView(scrollOptions);
      }
   }
}
