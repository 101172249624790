import { AsyncPipe } from "@angular/common";
import { inject, Component, computed, input } from "@angular/core";
import {
   BasicModalHeaderComponent,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   UpsellPopover,
   type ModalResult,
} from "@limblecmms/lim-ui";
import { map } from "rxjs";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { BudgetComponent } from "src/app/purchasing/budgets/budgetWrapper/budget.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";

@Component({
   selector: "pop-budget",
   templateUrl: "./popBudget.modal.component.html",
   styleUrls: ["./popBudget.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      BudgetComponent,
      UpsellPopover,
      AsyncPipe,
   ],
})
export class PopBudget implements ModalResult<void> {
   private readonly managePO = inject(ManagePO);
   public readonly modalRef: LimUiModalRef<PopBudget, undefined> = inject(LimUiModalRef);
   protected readonly featureCustomBudgets = inject(ManageFeatureFlags).features$.pipe(
      map(({ featureCustomBudgets }) => featureCustomBudgets),
   );
   protected i18n = inject(TranslationService).i18n;

   public readonly budgetID = input.required<number>();

   protected readonly budgetName = computed(
      () => this.managePO.getBudget(this.budgetID())?.name ?? "",
   );

   protected close(): void {
      this.modalRef.close();
   }
}
