import { Component, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   selector: "task-created-on-cell",
   imports: [BetterDatePipe],
   templateUrl: "./task-created-on-cell.component.html",
})
export class TaskCreatedOnCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   @Input() public column?: Column;

   protected lang;

   public constructor() {
      this.lang = inject(ManageLang).lang;
   }
}
