@if (task?.isCompleted !== undefined) {
   <div
      [ngClass]="[
         isInModal
            ? 'name-priority-type-status-container-modal'
            : 'name-priority-type-status-container',
         printOnly ? 'print-only-container' : '',
      ]"
      class="taskItemClass allowOffline cell-container"
   >
      <!-- Priority button -->
      <priority-button-legacy
         (dropdownClickEvent)="onDropdownClick($event)"
         [taskID]="task.checklistID"
         [locationID]="task.locationID"
         [priorityID]="task.priorityID"
         [fixedWidthTableButton]="true"
         [superUser]="isSuperUser"
         [taskIsCompleted]="task.isCompleted"
         class="name-button-spacing"
         [ngClass]="{ 'print-only-buttons': printOnly }"
      />

      <!-- status button -->
      <status-button-legacy
         (dropdownClickEvent)="onDropdownClick($event)"
         [taskID]="task.checklistID"
         [locationID]="task.locationID"
         [statusID]="task.statusID"
         [fixedWidthTableButton]="true"
         [statusList]="statusList"
         [superUser]="isSuperUser"
         [taskIsCompleted]="task.isCompleted"
         class="name-button-spacing"
         [ngClass]="{ 'print-only-buttons': printOnly }"
      />

      <!-- Task type button -->
      @if (!task.isCompleted && isAllowedToDelete) {
         <lim-ui-dropdown
            (click)="dropdownClicked = true"
            class="lim-ui-hide-on-desktop button-menu float-right"
         >
            <lim-ui-minimal-icon-button
               button
               icon="ellipsisVertical"
               id="mobile-widget-buttons"
            />
            @if (facade.canDuplicateTasks$ | async; as canDuplicate) {
               <div aria-labelledby="mobile-widget-buttons" menu>
                  <lim-ui-dropdown-item
                     (click)="onDuplicate($event)"
                     [limUiPopoverHidden]="canDuplicate"
                     [limUiPopoverPlacement]="'bottom'"
                     [limUiPopoverType]="'upsell'"
                     [limUiPopover]="startDuplicateTaskMobilePopover"
                     [ngClass]="{
                        'grey-out': !canDuplicate,
                     }"
                  >
                     <div class="dropdown-with-badge-container">
                        <span>{{ lang().duplicateOpenTask }}</span>
                        @if (!canDuplicate) {
                           <lim-ui-badge class="pricing-plan-badge" icon="stars" />
                        }
                     </div>
                  </lim-ui-dropdown-item>
                  <ng-template #startDuplicateTaskMobilePopover>
                     @if (facade.woInstructionLimit$ | async; as limit) {
                        <upsell-popover
                           [message]="
                              limit > 2
                                 ? lang().FeatureThreeWOsPopoverMessage
                                 : lang().FeatureTwoWOsPopoverMessage
                           "
                           [pricingLinkText]="lang().LearnMore"
                        />
                     }
                  </ng-template>

                  <lim-ui-dropdown-divider />
                  <lim-ui-dropdown-item (click)="deleteTask()">
                     <lim-ui-icon icon="trashCanRegular" iconColor="danger" />
                     {{ lang().permDeleteTaskTooltip }}
                  </lim-ui-dropdown-item>
               </div>
            }
         </lim-ui-dropdown>
      }

      @if (!printOnly) {
         <lim-ui-dropdown
            (click)="onDropdownClick($event)"
            [disabled]="
               task.isCompleted ||
               (task.checklistTemplateOld !== null && task.checklistTemplateOld == 5)
            "
         >
            <lim-ui-table-button
               [disabled]="
                  task.isCompleted ||
                  (task.checklistTemplateOld !== null && task.checklistTemplateOld == 5)
               "
               [fixedWidthTableButton]="true"
               [icon]="task.taskTypeIcons"
               button
               class="lim-ui-show-on-desktop name-button-spacing"
               id="task-type"
               limUiTooltip="{{ task.typeLabel }}"
               placement="right"
            />
            <ng-container aria-labelledby="task-type" menu>
               <lim-ui-dropdown-item
                  (click)="onChangeTaskType()"
                  class="lim-ui-show-on-desktop"
                  limUiTooltip="{{ lang().changeTasksTypeTooltip }}"
                  placement="right"
               >
                  <lim-ui-icon icon="penToSquare" />
                  {{ lang().changeTasksType }}
               </lim-ui-dropdown-item>
            </ng-container>
         </lim-ui-dropdown>

         @if ((facade.currentLocationID$ | async) !== task.locationID) {
            <lim-ui-icon
               icon="houseChimney"
               limUiTooltip="{{ lang().locatedAt }} {{ task.locationName }}"
               class="lim-ui-show-on-desktop house-icon"
            />
         }
      }

      <span class="no-float-desktop" [ngClass]="{ 'print-only-name': printOnly }">
         @if (task.taskTypeIcons) {
            <lim-ui-icon
               class="mobile-task-icon lim-ui-mobile-table-column-label task-name lim-ui-hide-on-desktop"
               [icon]="task.taskTypeIcons"
            />
         }
         <!-- This is the name -->
         <span
            (click)="viewTask()"
            [limbleHtml]="task.checklistName + ' - # ' + task.checklistID"
            class="text task-name"
         ></span>

         <!-- This is the asset and its parent assets-->
         @let asset = taskAsset();
         @if (asset && showAsset()) {
            <asset-hierarchy-list-item
               (click)="onAssetClick()"
               [asset]="asset"
               linkColorClass="green-color"
               [preventParentAccess]="false"
               [preventViewAsset]="facade.isMobile"
            />
         }

         @if (!printOnly) {
            <!-- The icons -->
            <span class="icon-container">
               @if (task.commentInfo?.showCommentsHint) {
                  <lim-ui-icon
                     icon="commentRegular"
                     limUiTooltip="{{ lang().oneOrMoreComments }}"
                     placement="right"
                     (click)="viewTask()"
                  />
               }

               @if (task.commentInfo && task.commentInfo.unreadComments > 0) {
                  <div class="unread-comments-number">
                     ({{ task.commentInfo.unreadComments }})
                  </div>
               }

               @if (task.hasParts) {
                  <span class="part-stock-icon-container">
                     @if (task.isCompleted === false) {
                        <lim-ui-icon
                           container="body"
                           icon="gearsRegular"
                           limUiTooltip="{{
                              task.hasPartsInStock
                                 ? lang().ThisTaskHasAllItsParts
                                 : lang().ThisTaskIsMissingParts
                           }}"
                           placement="right"
                        />
                     }
                     @if (task.isCompleted === true) {
                        <lim-ui-icon
                           container="body"
                           icon="gearsRegular"
                           placement="right"
                        />
                     }
                     @if (task.hasPartsInStock) {
                        <lim-ui-icon
                           class="part-check-icon"
                           icon="check"
                           iconColor="success"
                           placement="right"
                        />
                     }
                  </span>
               }

               @if (task.checklistComments && task.checklistComments.length > 2) {
                  <lim-ui-icon
                     icon="noteSticky"
                     limUiTooltip="{{ lang().completionNotes }}"
                     placement="right"
                  />
               }

               @if (task.requestorInformation && task.requestorInformation.length > 0) {
                  <lim-ui-icon
                     icon="phoneRegular"
                     limUiTooltip="{{ lang().RequestorInfo }}: {{
                        task.requestorInformation
                     }}"
                     placement="right"
                  />
               }

               @if (
                  task.approvalItemState !== undefined &&
                  task.approvalItemState !== null &&
                  task.approvalItemState > 0
               ) {
                  <lim-ui-icon
                     [iconColor]="task.approvalItemState == 2 ? 'danger' : 'inherit'"
                     [icon]="task.approvalItemState == 1 ? 'thumbsUp' : 'thumbsDown'"
                     limUiTooltip="{{ lang().ApprovedOrDisapprovedHintTooltip }}"
                     placement="right"
                  />
               }
            </span>
         }
      </span>

      <!-- The search filter -->
      @if (searchHint && searchHint.length > 0 && !printOnly) {
         <span class="search-hint desktop-search-hint">
            <span [limbleHtml]="searchHint"> </span>
         </span>
      }
   </div>
}
