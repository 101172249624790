import type { OnInit } from "@angular/core";
import { Component, computed, effect, inject, Input, input, signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   IconButtonComponent,
   InputWithPrefixComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   ModalService,
   PanelComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import axios from "axios/dist/axios";
import {
   BehaviorSubject,
   combineLatest,
   finalize,
   of,
   Subject,
   switchMap,
   forkJoin,
} from "rxjs";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import type { DocumentFile } from "src/app/files/file.util";
import { ManageLang } from "src/app/languages/services/manageLang";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { EscapePipe } from "src/app/shared/pipes/customEscape.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { InvoiceEntity } from "src/app/tasks/components/shared/services/invoices-api/invoice-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

type InvoiceWithExtraData = {
   invoice: InvoiceEntity;
   po: PurchaseOrder | undefined;
   uploadObj: any;
   invoiceDescriptionOld: string | undefined;
   fileName: string | undefined;
};
@Component({
   selector: "add-invoice-to-task",
   templateUrl: "./add-invoice-to-task-modal.component.html",
   styleUrls: ["./add-invoice-to-task-modal.component.scss"],
   providers: [EscapePipe],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      FormsModule,
      ContenteditableDirective,
      FileUploader,
      FileListItem,
      ModalFooterComponent,
      IconButtonComponent,
      InputWithPrefixComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      SecondaryButtonComponent,
      BetterDecimalPipe,
      LocaleCurrencyPipe,
      CurrencySymbolPipe,
   ],
})
export class AddInvoiceToTaskModalComponent implements OnInit {
   public strCurrencySymbol;
   public resolve;
   public message;
   public title;
   public invoiceIndex: Array<any> = [];
   public errorMsg;
   public customerID;
   public uploadObj;
   public modalInstance;
   private readonly axios = axios;

   private readonly modalService = inject(ModalService);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly managePO = inject(ManagePO);
   private readonly manageLang = inject(ManageLang);
   private readonly tasksApiService = inject(TasksApiService);
   private readonly escapePipe = inject(EscapePipe);

   @Input() public locationID: number | undefined; // Declare locationID as a function returning a signal
   private readonly currentLocationID = signal<number | undefined>(undefined);
   protected readonly checklistID = input.required<number>();

   protected readonly checklistID$ = toObservable(this.checklistID);

   protected readonly taskID$ = new BehaviorSubject<number | undefined>(undefined);
   protected readonly invoicesNeedsUpdate = new Subject<void>();
   protected readonly taskRequest$ = combineLatest([
      this.checklistID$,
      this.invoicesNeedsUpdate,
   ]).pipe(
      switchMap(([taskID]) => {
         if (taskID === undefined) {
            return of(undefined);
         }
         return this.tasksApiService
            .getById(taskID, {
               columns: "invoices",
            })
            .pipe(
               finalize(() => {
                  this.isLoading.set(false);
               }),
            );
      }),
   );

   protected readonly isLoading = signal(true);

   protected readonly hasNoInvoices = computed(
      () => this.taskInvoices().length === 0 && !this.isLoading(),
   );

   protected readonly task = toSignal(this.taskRequest$);

   protected readonly taskInvoices = computed(() => {
      const task = this.task();
      if (task) {
         return this.task()?.invoices ?? [];
      }
      return [];
   });
   protected readonly taskInvoiceIDs = computed(() =>
      this.taskInvoices().map((invoice) => invoice.invoiceID),
   );

   protected readonly invoicesWithPOs = computed<Array<InvoiceWithExtraData>>(() => {
      const invoices = this.taskInvoices();
      return invoices.map((invoice) => {
         return {
            invoice,
            po: invoice.poItemID
               ? this.managePO.getPurchaseOrderFromPurchaseOrderItem(invoice.poItemID)
               : undefined,
            uploadObj: this.addFileUploader(invoice),
            invoiceDescriptionOld: invoice.invoiceDescription ?? undefined,
            fileName: invoice.invoiceFileName ?? undefined,
         };
      });
   });

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationsQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationsQueries.detail(this.currentLocationID()),
   );

   protected readonly isCurrencyPending = computed(
      () => this.locationQuery.isPending() || this.accountCurrencyQuery.isPending(),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );

   public constructor() {
      effect(() => {
         if (this.hasNoInvoices()) {
            this.addInvoice();
         }
      });
   }

   protected getInvoicePO(invoice: InvoiceEntity): PurchaseOrder | undefined {
      return invoice.poItemID
         ? this.managePO.getPurchaseOrderFromPurchaseOrderItem(invoice.poItemID)
         : undefined;
   }

   protected getInvoiceUploadObj(invoice: InvoiceEntity): any {
      return this.addFileUploader(invoice);
   }

   protected getInvoiceDescriptionOld(invoice: InvoiceEntity): string | undefined {
      return invoice.invoiceDescription ?? undefined;
   }

   protected getInvoiceFileName(invoice: InvoiceEntity): string | undefined {
      return invoice.invoiceFileName ?? undefined;
   }

   protected getInvoiceFileNameUrl(invoice: InvoiceEntity): string {
      const invoiceFileName = this.escapePipe.transform(invoice.invoiceFileName);
      return `viewFile.php?f=upload-${this.customerID}/invoices/${this.task()?.checklistID}/${invoiceFileName}`;
   }

   protected getInvoiceFileInfo(invoice: InvoiceEntity): DocumentFile {
      return {
         fileName: invoice.invoiceFileName ?? null,
         getURL: this.getInvoiceFileNameUrl(invoice),
      };
   }

   public ngOnInit() {
      this.strCurrencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;
      this.invoicesNeedsUpdate.next();

      this.taskID$.next(data.checklistID);
      this.message = data.message;
      this.title = data.title;
      this.invoiceIndex = this.manageInvoice.getInvoicesIndex();

      this.errorMsg = "";
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.currentLocationID.set(this.locationID);
   }

   protected removeInvoice(poItemID: number, invoiceID: number): void {
      if (poItemID > 0) {
         this.alertService.addAlert(
            this.lang().WhoopsYouMustRemoveThePOItemToGetRidOfThisInvoice,
            "danger",
            4000,
         );
         return;
      }
      this.manageInvoice.removeInvoice(invoiceID).then(() => {
         assert(this.task);
         this.invoicesNeedsUpdate.next();
      });
   }

   addInvoice = () => {
      assert(this.task);
      this.manageInvoice.addInvoice(this.resolve.data.checklistID).then((answer) => {
         assert(this.task);

         this.invoicesNeedsUpdate.next();
         this.addFileUploader(answer.data.invoice);
      });
   };

   protected updateInvoiceCost(invoiceID: number, invoiceCost: number): void {
      this.manageInvoice.updateInvoiceCost(invoiceID, invoiceCost).then((answer) => {
         if (answer.data.success === true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   updateInvoiceDescription = (invoice) => {
      if (invoice.invoiceDescriptionOld !== invoice.invoiceDescription) {
         this.manageInvoice.updateInvoiceDescription(invoice).then((answer) => {
            if (answer.data.success === true) {
               invoice.invoiceDescription = answer.data.invoiceDescription;
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               invoice.invoiceDescriptionOld = invoice.invoiceDescription;
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      }
   };

   popPoComponent = (poID) => {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   };

   protected addFileUploader(invoice: InvoiceEntity) {
      return {
         primaryID: "fileName",
         deleteData: {},
         onUploadPopEditor: false,
         noPreview: true,
         maxFiles: 1,
         posturl: `phpscripts/checklistManager.php?action=makeFileInvoice&checklistID=${invoice.checklistID}&invoiceID=${invoice.invoiceID}`,
         deleteCall: () => {
            //leave empty in case we need to do a delete later
         },
         uploadCall: async (posturl, formData) => {
            return this.axios({
               url: posturl,
               method: "POST",
               data: formData,
            });
         },
         uploadComplete: (result) => {
            assert(this.task);
            if (result === undefined) {
               result.invoiceID = -1;
            }
            for (const invoiceIDOnTask of this.taskInvoiceIDs()) {
               if (invoiceIDOnTask === Number(result.invoiceID)) {
                  const invoiceOnTask = this.invoiceIndex[invoiceIDOnTask];
                  invoiceOnTask.invoiceFileName = result.fileName;
                  invoiceOnTask.fileName = result.fileName;

                  // get the new file ext
                  invoiceOnTask.ext = result.fileName.substr(
                     result.fileName.lastIndexOf(".") + 1,
                  );

                  this.invoicesNeedsUpdate.next();
               }
            }
         },
      };
   }

   close = () => {
      // We need to run through the invoices and remove any that are empty
      const removalPromises = this.taskInvoices()
         .filter(
            (invoice) =>
               !invoice.invoiceCost &&
               !invoice.invoiceFileName &&
               !invoice.invoiceDescription,
         )
         .map(async (invoice) => this.manageInvoice.removeInvoice(invoice.invoiceID));

      if (removalPromises.length > 0) {
         forkJoin(removalPromises).subscribe({
            next: () => {
               this.modalInstance.close();
            },
            error: (error) => {
               console.error("Error removing empty invoices:", error);
               this.alertService.addAlert(
                  this.lang().errorRemovingEmptyInvoices,
                  "danger",
                  4000,
               );
               this.modalInstance.close();
            },
         });
      } else {
         this.modalInstance.close();
      }
   };
}
