import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "pick-task-type",
   templateUrl: "./pickTaskType.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      NgClass,
      IconComponent,
      BasicModalFooterComponent,
      OrderByPipe,
   ],
})
export class PickTaskType implements OnInit, OnDestroy {
   public types;
   public title;
   public message;
   public resolve;
   public modalInstance;
   private readonly langLoadedSub: Subscription | undefined;
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.langLoadedSub = inject(ManageLang).langLoaded$.subscribe(() => {
         this.types = [
            {
               text: this.lang().PM,
               checklistTemplateOld: 1,
               checklistBatchID: "default",
               order: 1,
            },
            {
               text: this.lang().UnplannedWO,
               checklistTemplateOld: 2,
               checklistBatchID: "default",
               order: 2,
            },
            {
               text: this.lang().PlannedWO,
               checklistTemplateOld: 4,
               checklistBatchID: "default",
               order: 3,
            },
            {
               text: this.lang().WorkRequest,
               checklistTemplateOld: 2,
               checklistBatchID: 300112,
               order: 4,
            },
         ];
      });
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.title = this.resolve.title;
      this.message = this.resolve.message;

      //this timeout makes sure that the modal starts at the top of the screen
      setTimeout(() => {
         $(".modal").scrollTop(0);
      }, 1);
   }

   public ngOnDestroy() {
      if (this.langLoadedSub !== undefined) {
         this.langLoadedSub.unsubscribe();
      }
   }

   selectType = (policy) => {
      if (policy.selected == true) {
         //handles double click to submit
         this.submit();
      }

      for (const type of this.types) {
         type.selected = false;
      }
      policy.selected = true;
   };

   close = () => {
      for (const type of this.types) {
         type.selected = false;
      }
      this.modalInstance.close(false);
   };

   submit = () => {
      for (const type of this.types) {
         if (type.selected == true) {
            this.modalInstance.close(type);
         }
      }
   };
}
