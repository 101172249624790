import { formatNumber } from "@angular/common";
import {
   Component,
   computed,
   inject,
   Input,
   input,
   type Signal,
   type OnInit,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   CardComponent,
   IconComponent,
   ModalService,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { Chart, type ChartData, type ChartOptions, type Color, Title } from "chart.js";
import { ChartJSHelper } from "src/app/dashboards/widgets/chartJSHelper";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Part } from "src/app/parts/types/part.types";
import { ScheduleListComponent } from "src/app/schedules/schedule-list-component/schedule-list.component";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { assert } from "src/app/shared/utils/assert.utils";
import type { ScheduleEntity } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

Chart.register(Title);

type MonthTimeFrame = Array<{
   start: number;
   end: number;
}>;

@Component({
   selector: "part-reports-forecast-table",
   templateUrl: "./part-reports-forecast-table.component.html",
   styleUrls: ["./part-reports-forecast-table.component.scss"],
   imports: [CardComponent, IconComponent, TooltipDirective],
})
export class PartReportsForecastTableComponent implements OnInit {
   @Input() public currencyCode!: Signal<string>;
   private readonly manageLang = inject(ManageLang);
   private readonly manageTask = inject(ManageTask);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly manageUser = inject(ManageUser);
   private readonly modalService = inject(ModalService);
   private readonly chartJSHelper = inject(ChartJSHelper);

   public part = input.required<Part>();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected lineData: ChartData<any>;
   protected totalPartCount;
   protected theMonths;
   protected yyyy;
   protected lineChart: Chart<any> | undefined;
   protected lineChartData: any[] = [];
   protected lineOptions: ChartOptions<"bar"> | undefined;
   protected dayFilters: Array<{ days: number | false; display: string }> = [];
   protected currencySymbol;
   protected chartTitle: string | undefined;
   private readonly schedulesMap: Map<number, Array<ScheduleEntity>> = new Map();

   protected localeCurrencyPipe = new LocaleCurrencyPipe();
   public constructor() {
      const datasetStyleOptions = {
         tension: 0.5,
         pointRadius: 5,
         fill: false,
         xAxisID: "xAxes",
         yAxisID: "yAxes",
      };
      this.lineData = {
         labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         datasets: [
            {
               ...datasetStyleOptions,
               label: "",
               fillColor: "rgba(66,155,31,.9)",
               strokeColor: "rgba(66,155,31,1)",
               pointColor: "rgba(66,155,31,1)",
               backgroundColor: "rgba(66,155,31,1)",
               pointStrokeColor: "#fff",
               pointHighlightFill: "#fff",
               pointHighlightStroke: "rgba(220,220,220,1)",
               data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
               dataSrc: [],
            },
         ],
      };
      this.setStaticChartSettings();

      this.manageUser.currentUserChanges$
         .pipe(takeUntilDestroyed())
         .subscribe((currentUser) => {
            this.currencySymbol = currentUser.currency?.symbol;
         });
   }

   public viewBarChartItem(event) {
      assert(this.part);
      assert(this.lineChart);
      const activePoints = this.lineChart.getElementsAtEventForMode(
         event,
         "nearest",
         { intersect: true },
         false,
      );

      const schedules = this.schedulesMap.get(activePoints[0].index);
      assert(schedules);

      const modalRef = this.modalService.open(ScheduleListComponent);
      modalRef.componentInstance.scheduleIDs = schedules.map(
         (schedule) => schedule.scheduleID,
      );
      modalRef.componentInstance.title = `<b>${this.part().partName}</b> - ${this.lang().EstimatedPartUsage}`;
   }

   public ngOnInit(): void {
      this.initialize();
   }

   private async initialize() {
      await this.setChartData();
      this.resetBarChart();
   }

   private async setChartData(): Promise<void> {
      this.lineData.datasets[0].dataSrc = [];

      this.totalPartCount = 0;

      const { monthTimeFrames, yearStart, yearEnd } = this.getMonthlyDisplaySkeleton();

      const schedules = await this.getSchedulesFromTemplates();

      const schedulesSortedByStartDate = this.filterAndSortSchedulesForBarChart(
         schedules,
         yearStart,
         yearEnd,
      );

      const partsUsedPerTaskByChecklistID = this.getPartsUsedPerTaskByChecklistID();

      this.populateChartWithProjectedPartUsage(
         monthTimeFrames,
         schedulesSortedByStartDate,
         partsUsedPerTaskByChecklistID,
      );

      this.setBarChartLabels();
   }

   private setChartOptions() {
      assert(this.chartTitle);
      this.lineOptions = {
         responsive: true,
         maintainAspectRatio: true,
         plugins: {
            legend: {
               display: false,
            },
            title: {
               display: true,
               text: this.chartTitle,
               font: {
                  size: 14,
                  weight: "normal",
               },
               align: "start",
            },
            tooltip: {
               titleFont: {
                  size: 14,
               },
               bodyFont: {
                  size: 14,
               },
               position: "nearest",
               intersect: false,
               callbacks: {
                  labelColor: function (context) {
                     const borderColor = context.dataset.borderColor as Color;
                     const backgroundColor = context.dataset.backgroundColor as Color;
                     return {
                        borderColor: borderColor,
                        backgroundColor: backgroundColor,
                     };
                  },
               },
            },
            htmlLegend: {
               containerID: "part-reports-forecast-chart-legend",
            },
         },
         aspectRatio: 1.5,

         scales: {
            xAxes: {
               beginAtZero: true,
               grid: {
                  display: true,
                  color: "rgba(0,0,0,.05)",
                  lineWidth: 1,
               },
            },
            yAxes: {
               beginAtZero: true,
               grid: {
                  display: true,
                  color: "rgba(0,0,0,.05)",
                  lineWidth: 1,
               },
            },
         },
         onHover: (event, _chartElement, chart) => {
            this.chartJSHelper.onHoverHandler(event, chart);
         },
      } as any; // NOTE: this is a workaround to avoid type errors since ChartJS doesn't have the correct types for the options to include HTML Legend
   }

   private setBarChartLabels() {
      let strPrice = "";
      if (this.currencyCode()) {
         const pp = this.part().partPrice ?? 0;
         strPrice = this.localeCurrencyPipe.transform(
            pp * this.totalPartCount,
            this.currencyCode(),
         );
      } else {
         strPrice =
            String(this.currencySymbol) +
            formatNumber(
               this.totalPartCount * (this.part().partPrice ?? 0),
               this.manageLang.getLocaleID(),
               "1.2-2",
            );
      }

      this.chartTitle = `${this.lang().TotalPartsExpectedToBeUsed}: ${this.totalPartCount} - ${this.lang().EstimatedCostOf}: ${strPrice}`;
      this.lineData.datasets[0].label = this.lang().EstimatedMonthlyCost;
      this.lineData.datasets[0].labelNoHTML = `${this.lang().TotalPartsExpectedToBeUsed}: ${this.totalPartCount} - ${this.lang().EstimatedCostOf}: ${strPrice}`;
      this.setChartOptions();
   }

   private populateChartWithProjectedPartUsage(
      monthTimeFrames: MonthTimeFrame,
      schedulesSortedByStartDate: Array<ScheduleEntity & { scheduleStartDate: number }>,
      partsUsedPerTaskByChecklistID: Map<number, number>,
   ) {
      const dataSrc = this.lineData.datasets[0].dataSrc;
      for (const [monthNumber, month] of monthTimeFrames.entries()) {
         const currentMonthData = dataSrc[monthNumber];
         const indexOfLastSchedule = schedulesSortedByStartDate.findIndex((schedule) => {
            return schedule.scheduleStartDate >= month.end;
         });
         const schedulesInThisMonth = schedulesSortedByStartDate.splice(
            0,
            indexOfLastSchedule === -1
               ? schedulesSortedByStartDate.length - 1
               : indexOfLastSchedule,
         );
         let partsUsedThisMonth = 0;
         for (const schedule of schedulesInThisMonth) {
            const partsUsedForSchedulesTemplate = partsUsedPerTaskByChecklistID.get(
               schedule.checklistID,
            );
            if (partsUsedForSchedulesTemplate === undefined) continue;
            partsUsedThisMonth += partsUsedForSchedulesTemplate;
            currentMonthData.customData.push(schedule);
         }

         this.schedulesMap.set(monthNumber, currentMonthData.customData);

         const priceUsed = partsUsedThisMonth * (this.part().partPrice ?? 0);
         this.lineData.datasets[0].data[monthNumber] = priceUsed;
         this.totalPartCount += partsUsedThisMonth;
      }
   }

   private getPartsUsedPerTaskByChecklistID(): Map<number, number> {
      // JIT TODO: attach parts to templates to get this instead of using the refresh service data (CMMS-3052)
      const partRelations = this.manageTask.getPartRelationsByPartID(this.part().partID);
      const partsUsedPerTaskByChecklistID = new Map<number, number>();
      for (const partRelation of partRelations) {
         const partsUsed = partsUsedPerTaskByChecklistID.get(partRelation.checklistID);
         if (partsUsed) {
            partsUsedPerTaskByChecklistID.set(
               partRelation.checklistID,
               partsUsed + (partRelation.suggestedNumber ?? 0),
            );
            continue;
         }
         partsUsedPerTaskByChecklistID.set(
            partRelation.checklistID,
            partRelation.suggestedNumber ?? 0,
         );
      }
      return partsUsedPerTaskByChecklistID;
   }

   private filterAndSortSchedulesForBarChart(
      schedules: Array<ScheduleEntity>,
      yearStart: number,
      yearEnd: number,
   ) {
      return schedules
         .filter(
            (schedule): schedule is ScheduleEntity & { scheduleStartDate: number } =>
               schedule.scheduleStartDate !== null &&
               schedule.scheduleStartDate > yearStart &&
               schedule.scheduleStartDate < yearEnd,
         )
         .sort((scheduleA, scheduleB) => {
            return scheduleA.scheduleStartDate - scheduleB.scheduleStartDate;
         });
   }

   private async getSchedulesFromTemplates(): Promise<Array<ScheduleEntity>> {
      const groupsOfSchedules: Array<Array<ScheduleEntity>> = [];
      const templatesStream = this.taskTemplatesApiService.getStreamedList({
         filters: {
            partIDs: [this.part().partID],
         },
         columns: "schedules",
      });

      for await (const template of templatesStream) {
         groupsOfSchedules.push(template.schedules);
      }
      return groupsOfSchedules.flat();
   }

   private getMonthlyDisplaySkeleton(): {
      monthTimeFrames: MonthTimeFrame;
      yearStart: number;
      yearEnd: number;
   } {
      const dataSrc = this.lineData.datasets[0].dataSrc;

      const monthTimeFrames: MonthTimeFrame = [];

      const today = new Date(new Date().setFullYear(new Date().getFullYear()));
      today.setMonth(today.getMonth());
      let mm = today.getMonth() + 1; //January is 0!
      this.yyyy = today.getFullYear();

      let yearStart: number = 0;
      let yearEnd: number = 0;
      assert(this.lineData.labels);
      for (let currentMonth = 0; currentMonth < 12; currentMonth++) {
         this.lineData.labels[currentMonth] = `${this.theMonths[mm].short}, ${this.yyyy}`;

         dataSrc[currentMonth] = {
            label: `${this.theMonths[mm].short}, ${this.yyyy}`,
            customData: [],
         };

         let start = new Date(`${mm}/01/${this.yyyy}`).getTime();
         start = Math.floor(start / 1000);

         if (currentMonth === 0) {
            yearStart = start;
         }

         mm = this.getNextMonth(mm);

         let end = new Date(`${mm}/01/${this.yyyy}`).getTime();
         end = Math.floor(end / 1000);

         if (currentMonth === 11) {
            yearEnd = end;
         }

         monthTimeFrames.push({ start, end });
      }
      return {
         monthTimeFrames,
         yearStart,
         yearEnd,
      };
   }

   private setStaticChartSettings() {
      const datasetStyleOptions = {
         tension: 0.5,
         pointRadius: 5,
         fill: false,
         xAxisID: "xAxes",
         yAxisID: "yAxes",
      };
      this.lineData = {
         labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         datasets: [
            {
               ...datasetStyleOptions,
               label: "",
               fillColor: "rgba(66,155,31,.9)",
               strokeColor: "rgba(66,155,31,1)",
               pointColor: "rgba(66,155,31,1)",
               backgroundColor: "rgba(66,155,31,1)",
               pointStrokeColor: "#fff",
               pointHighlightFill: "#fff",
               pointHighlightStroke: "rgba(220,220,220,1)",
               data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
               dataSrc: [],
            },
         ],
      };

      this.dayFilters = [
         {
            days: 7,
            display: this.lang().Last7Days,
         },
         {
            days: 30,
            display: this.lang().Last30Days,
         },
         {
            days: 60,
            display: this.lang().Last60Days,
         },
         {
            days: 90,
            display: this.lang().Last90Days,
         },
         {
            days: 365,
            display: this.lang().Last365Days,
         },
         {
            days: false,
            display: this.lang().AllTime,
         },
      ];

      this.theMonths = {
         1: {
            short: this.lang().Jan,
            long: this.lang().January,
            days: 31,
         },
         2: {
            short: this.lang().Feb,
            long: this.lang().February,
            days: 28,
         },
         3: {
            short: this.lang().Mar,
            long: this.lang().March,
            days: 31,
         },
         4: {
            short: this.lang().Apr,
            long: this.lang().April,
            days: 30,
         },
         5: {
            short: this.lang().May,
            long: this.lang().May,
            days: 31,
         },
         6: {
            short: this.lang().Jun,
            long: this.lang().June,
            days: 30,
         },
         7: {
            short: this.lang().Jul,
            long: this.lang().July,
            days: 31,
         },
         8: {
            short: this.lang().Aug,
            long: this.lang().August,
            days: 31,
         },
         9: {
            short: this.lang().Sep,
            long: this.lang().September,
            days: 30,
         },
         10: {
            short: this.lang().Oct,
            long: this.lang().October,
            days: 31,
         },
         11: {
            short: this.lang().Nov,
            long: this.lang().November,
            days: 30,
         },
         12: {
            short: this.lang().Dec,
            long: this.lang().December,
            days: 31,
         },
      };
   }

   private resetBarChart() {
      if (this.lineChart !== undefined) {
         this.lineChart.destroy();
      }
      assert(this.lineOptions);
      const ctx = document.getElementById(`barChart1${this.part().partID}`);

      if (ctx instanceof HTMLCanvasElement) {
         this.lineChart = new Chart(ctx, {
            type: "bar",
            data: this.lineData,
            options: this.lineOptions,
            plugins: [
               {
                  id: "htmlLegend",
                  afterUpdate: (chart) => {
                     const ul = this.chartJSHelper.getOrCreateLegendList(
                        chart,
                        "part-reports-forecast-chart-legend",
                        "row",
                     );
                     while (ul.firstChild) {
                        ul.firstChild.remove();
                     }
                     this.chartJSHelper.chartHTMLLegend(chart, ul);
                  },
               },
            ],
         });
      }
   }

   getNextMonth = (month) => {
      if (month > 11) {
         this.yyyy = Number(this.yyyy) + 1;
         return 1;
      }
      return month + 1;
   };
}
