<div class="hoverContainer3">
   <file-list-item [file]="file" url="{{ getURL ? getURL : file.getURL }}" />
   @if (permission) {
      <lim-ui-icon
         icon="trashCanRegular"
         iconSize="small"
         iconColor="danger"
         (click)="delete(file)"
         limUiTooltip="{{ lang().Delete }} {{ file.fileName?.slice(5) }}"
         placement="top"
      />
   }
</div>
