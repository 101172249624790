import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import {
   FlannelApiService,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type {
   AssetEntity,
   AssetEntityFilters,
} from "src/app/tasks/components/shared/services/assets-api/assets-api.models";

@Injectable({
   providedIn: "root",
})
export class AssetsApiService extends FlannelApiService<AssetEntity, AssetEntityFilters> {
   private constructor() {
      super("assets");
   }

   /*  eslint-disable typescript/no-unused-vars -- The variables are needed to override */
   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override create(
      body: Partial<AssetEntity>,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<AssetEntity | null> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override update(
      id: number,
      body: Partial<AssetEntity>,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<AssetEntity> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override delete(
      id: number,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<AssetEntity> {
      throw new Error("Method not implemented.");
   }

   /*  eslint-enable typescript/no-unused-vars  */
}
