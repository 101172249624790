import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageAtlas } from "src/app/maps/services/manageAtlas";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "link-arcgis",
   templateUrl: "./linkArcGis.modal.component.html",
   styleUrls: ["./linkArcGis.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      FormsModule,
      BasicModalFooterComponent,
   ],
})
export class LinkArcGis implements OnInit {
   public resolve;
   public modalInstance;
   public isMobile;
   public currentFeatureServiceID: number | undefined;
   public encodedArcGisAPICred: string | undefined;
   public gisGlobalID: string | undefined;
   public assetID: number | undefined;

   private readonly paramsService = inject(ParamsService);
   private readonly manageAtlas = inject(ManageAtlas);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.encodedArcGisAPICred = params.encodedArcGisAPICred;
      this.currentFeatureServiceID = params.currentFeatureServiceID;
      this.assetID = params.assetID;
   }
   public async linkAssetToArcGis() {
      if (!this.gisGlobalID) {
         this.alertService.addAlert(this.lang().EnterGisGlobalID, "warning", 6000);
         return;
      }
      const linkAsset = await this.manageAtlas.createAssetMap(
         this.encodedArcGisAPICred,
         this.assetID,
         this.currentFeatureServiceID,
         this.gisGlobalID,
      );
      if (linkAsset.data.success === false) {
         const failureReason = linkAsset.data.reason;
         const errorMsg =
            failureReason === "invalid gisGlobalID"
               ? this.lang().InvalidGisGlobalID
               : `${this.lang().LinkedToAsset} ${linkAsset.data.assetID}`;
         this.alertService.addAlert(errorMsg, "danger", 6000);
         return;
      }

      this.modalInstance.close(linkAsset.data);
   }
   public close() {
      this.modalInstance.close({ success: false });
   }
}
