import { inject, Injectable } from "@angular/core";
import { getEnvString } from "src/app/shared/external-scripts/util/env-string";
import { ManageUser } from "src/app/users/services//manageUser";
import userflow from "userflow.js";

@Injectable({ providedIn: "root" })
export class UserflowService {
   private readonly manageUser = inject(ManageUser);

   public loadOrRefresh(): void {
      if (!this.shouldUse()) {
         return;
      }

      const currentUser = this.manageUser.getCurrentUser();

      userflow.init("ct_utitvd7unrgqfdvdnyfd5tudna");
      userflow.identify(currentUser.userInfo.userID, {
         "name": `${currentUser.userInfo.fName} ${currentUser.userInfo.lName}`, // Full name
         "email": currentUser.userInfo.userLoginName, // Email address
         "custom_in_app": 1,
         "custom_user_logins": currentUser.userInfo.logins,
         "custom_CusID": Number(currentUser.userInfo.customerID),
         "custom_review_segment": (Number(currentUser.userInfo.customerID) % 12) + 1,
         "Phone": currentUser.userInfo.userPhone,
         "plan_name": currentUser.userInfo.customerPlan,
         "Company Name": currentUser.userInfo.customerName,
         "customer_subscription_status": currentUser.userInfo.customerStatus,
         "env": getEnvString(),
         "sendUXSurvey": currentUser.userInfo.sendUXSurvey,
         "user_hash": currentUser.userInfo.userHash,
         "lead_source": currentUser.userInfo.leadSource,
      });
   }

   private shouldUse(): boolean {
      // We only want this to run on staging or local for now
      const url = window.location.href;
      if (url.includes("app.limblestaging.com")) {
         return true;
      }

      const currentUser = this.manageUser.getCurrentUser();
      if (currentUser?.userInfo?.userInternal === 1) {
         return false;
      }
      return false;
   }
}
