import {
   DestroyRef,
   type OnInit,
   Component,
   computed,
   inject,
   Input,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   IconComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AssetPartAssociationService } from "src/app/assets/services/asset-part-association.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { AssetPartAssociationInfo } from "src/app/assets/types/asset-part-association.types";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import {
   PartsFacadeService,
   type AddPartModalData,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { ViewListOfAnyObj } from "src/app/shared/components/global/lists/viewListOfAnyObj.element.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { AssociationType } from "src/app/shared/types/association.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type AssetCreds = {
   viewManageAssets: boolean;
   editAssetSettings: boolean;
};

type PartWithRelationsLegacy = Part & {
   auto: boolean;
   manual: boolean;
   assetPartQty: number;
   taskStr: string;
};

@Component({
   selector: "asset-parts-tab-legacy",
   templateUrl: "./asset-parts-tab-legacy.component.html",
   styleUrls: ["./asset-parts-tab-legacy.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      FormsModule,
      LimbleHtmlDirective,
      TooltipDirective,
      PrimaryButtonComponent,
      ViewListOfAnyObj,
      MinimalIconButtonComponent,
      SearchAllWrapperComponent,
      SearchBoxComponent,
      NoSearchResults,
      PartUnitOfMeasurePipe,
      NgxSkeletonLoaderModule,
   ],
})
export class AssetPartsTabLegacyComponent implements OnInit {
   public readonly modalService = inject(ModalService);
   public readonly manageParts = inject(ManageParts);
   public readonly manageLang = inject(ManageLang);
   public readonly manageTask = inject(ManageTask);
   public readonly alertService = inject(AlertService);
   public readonly manageAsset = inject(ManageAsset);
   public readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly partsFacadeService = inject(PartsFacadeService);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected readonly assetPartAssociationService = inject(AssetPartAssociationService);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly destroyRef = inject(DestroyRef);

   @Input() public assetID: number;
   public creds: AssetCreds = {
      viewManageAssets: false,
      editAssetSettings: false,
   };
   public asset: Asset | undefined;
   public partsAndRelations: Array<AssetPartAssociationInfo> = [];
   public filteredPartsAndRelations: Array<AssetPartAssociationInfo> = [];
   public partsAndRelationsLegacy: Array<PartWithRelationsLegacy> = [];
   public filteredPartsAndRelationsLegacy: Array<PartWithRelationsLegacy> = [];
   public currencySymbol;
   public columns;
   public options;
   public dueDate1;
   public dueDate2;
   public listParts;
   public listTitle;
   public listMessage;
   public sort;
   public canEditPartsCred;
   public locationsIndex;
   protected search: string = "";
   protected noSearchResults: boolean = false;
   protected isLoading: boolean = false;
   protected skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();
   protected readonly AssociationType = AssociationType;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.locationsIndex = this.manageLocation.getLocationsIndex();
      this.assetID = 0;
   }

   public ngOnInit() {
      this.asset = this.manageAsset.getAsset(this.assetID);

      assert(this.asset);

      this.buildPartsAssociations();

      this.getAssetPartsUsed();

      this.canEditPartsCred = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ChangePartAssetAssociations,
      );
      this.creds.viewManageAssets = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ViewManageAssets,
      );

      this.creds.editAssetSettings = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.EditAssetSettings,
      );
   }

   protected buildPartsAssociations(): void {
      assert(this.asset);
      this.partsAndRelations = [];

      this.assetPartAssociationService
         .getParts([this.asset.assetID])
         .pipe(takeUntilDestroyed(this.destroyRef))
         .subscribe({
            next: (list) => {
               for (const association of list) {
                  if (association.assetID === null || association.partID === null) {
                     continue;
                  }

                  const part = this.manageParts.getPart(association.partID);
                  if (!part || part?.partDeleted) {
                     continue;
                  }

                  const locationName =
                     this.manageLocation.getLocation(part.locationID)?.locationName ?? "";

                  this.partsAndRelations.push({
                     relationID: association.relationID,
                     partID: association.partID,
                     partNumber: part.partNumber,
                     partName: part.partName,
                     partLocation: part.partLocation,
                     assetID: association.assetID,
                     assetPartQty: association.assetPartQty,
                     association_type: association.association_type,
                     locationID: part.locationID,
                     locationName,
                  });
               }

               this.partsAndRelations = orderBy(this.partsAndRelations, "partName");
               this.filterPartNamesToSearch();
            },
         });
   }

   getAssetPartsUsed = () => {
      assert(this.asset);
      const assets: Array<Asset> = [];
      if (this.asset.includeChildData === 1) {
         const children = this.manageAsset.findChildrenIDs(this.asset, []);
         const tempHierAssetIDs: any = [];
         for (const child of children) {
            tempHierAssetIDs.push(child);
         }
         tempHierAssetIDs.push(this.asset.assetID); //tempHierAssetIDs will include the main asset and all of it's children and sub children
         for (const assetId of tempHierAssetIDs) {
            const retrievedAsset = this.manageAsset.getAsset(assetId);
            assert(retrievedAsset);
            assets.push(retrievedAsset);
         }
      } else {
         assets.push(this.asset);
      }

      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      this.columns = [
         {
            key: "partName",
            displayName: this.lang().PartName,
            sortBy: "partName",
            manualWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Date,
            sortBy: "checklistCompletedDate",
            manualWidth: 2,
         },
         {
            key: "usedNumber",
            displayName: this.lang().TotalUsed,
            sortBy: "usedNumber",
            manualWidth: 4,
         },
         {
            key: "partNumber",
            displayName: this.lang().PartNumber,
            sortBy: "partNumber",
            manualWidth: 2,
         },
         {
            key: "totalCost",
            displayName: this.lang().TotalCost,
            sortBy: "totalCost",
            manualWidth: 2,
         },
      ];

      this.options = {
         sortBind: "-checklistCompletedDate",
         isModal: false,
         title: this.lang().RecentlyUsedParts,
      };

      /**
       * Tasks Team TODO replace this with an API call to Flannel - TASK-512
       */
      const tasksForPartUsage =
         this.manageAssociations.getTaskListFromAssetsForPartsUsage(assets, {
            dateRange1: this.dueDate1,
            dateRange2: this.dueDate2,
         });

      const partsUsageObjs = this.manageParts.prepListOfPartsUsage(tasksForPartUsage);

      this.listParts = partsUsageObjs;
      this.listTitle = this.lang().ListOfPartsUsed;
      this.listMessage = "";
      this.sort = "-checklistCompletedDate";
   };

   public async updateAssetPartQty(partID: number | null, assetPartQty: number | null) {
      assert(this.asset);
      if (partID === null || assetPartQty === null) return;

      const result = await this.manageAssociations.updateAssetPartQty(
         this.asset.assetID,
         partID,
         assetPartQty,
      );

      if (result?.data.success) {
         this.buildPartsAssociations();
         this.alertService.addAlert(this.lang().Success, "success", 2000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   public async associatePartsToAsset(): Promise<void> {
      assert(this.asset);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.ChangePartAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred78Fail, "danger", 10000);
         return;
      }

      const modalData: AddPartModalData = {
         title: this.lang().PickParts,
         buttonText: this.lang().Select,
         dataLogOptions: {
            taskStoreLabel: "manageParts-storeAnAssociation",
         },
         locationID: this.asset.locationID,
      };

      const parts = await this.partsFacadeService.openAddPartModal(modalData);

      if (parts.length > 0) {
         assert(this.asset);
         const partIDs = parts.map((part) => part.partID);
         this.manageAssociations
            .associatePartsToAsset(partIDs, this.asset)
            .then((answer) => {
               if (answer.data.success === true) {
                  this.buildPartsAssociations();
                  this.alertService.addAlert(
                     this.lang().PartsSuccessfullyAssociated,
                     "success",
                     2000,
                  );
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               }
            });
      }
   }

   deletePartRelation = (part) => {
      assert(this.asset);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.ChangePartAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred78Fail, "danger", 10000);
         return;
      }

      this.manageAssociations
         .deletePartAssetRelation(this.asset.assetID, part.partID)
         .then((answer) => {
            if (answer?.data.success === true) {
               this.buildPartsAssociations();
               this.alertService.addAlert(
                  this.lang().AssociationSuccessfullyRemoved,
                  "success",
                  2000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   };

   popPart = (part) => {
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: part.partID,
            locationID: part.locationID,
            data: {
               restrict: false,
            },
         },
      };

      instance.result.finally(() => {
         this.buildPartsAssociations();
      });
   };

   protected filterPartNamesToSearch(): void {
      if (!this.search) {
         this.noSearchResults = false;
         this.filteredPartsAndRelations = this.partsAndRelations;
         return;
      }
      this.filteredPartsAndRelations = this.partsAndRelations.filter((part) => {
         if (part?.partNumber?.toLowerCase().includes(this.search.toLowerCase())) {
            return part?.partNumber?.toLowerCase().includes(this.search.toLowerCase());
         }
         return part?.partName?.toLowerCase().includes(this.search.toLowerCase());
      });
      this.noSearchResults = this.filteredPartsAndRelations.length === 0;
   }
}
