import {
   Component,
   computed,
   effect,
   inject,
   input,
   type OnInit,
   signal,
   type Signal,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   getExtraColumns,
   getParams,
} from "@empowered/base/table/utils/column-table-parser";
import {
   IconButtonComponent,
   ModalService,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import {
   catchError,
   combineLatest,
   filter,
   finalize,
   type Observable,
   of,
   startWith,
   switchMap,
   tap,
} from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import {
   DataViewerSearchComponent,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import type {
   ListResponse,
   RequestFilter,
} from "src/app/shared/services/flannel-api-service";
import { TasksDataViewerOptionsFactoryService } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksDataViewerComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/tasks-data-viewer.component";
import {
   TaskColumnDefinitionsFactoryService,
   type TaskViewTypes,
} from "src/app/tasks/components/shared/services/task-column-definitions-factory";
import { TaskViewModelFactoryService } from "src/app/tasks/components/shared/services/task-view-model-factory/task-view-model-factory.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   selector: "tasks-panel-legacy",
   providers: [DataViewerStateService],
   imports: [
      IconButtonComponent,
      PanelComponent,
      TooltipDirective,
      TasksDataViewerComponent,
      DataViewerSearchComponent,
   ],
   templateUrl: "./tasks-panel-legacy.component.html",
   styleUrl: "./tasks-panel-legacy.component.scss",
})
export class TasksPanelComponentLegacy implements OnInit {
   readonly #columnFactory = inject(TaskColumnDefinitionsFactoryService);
   readonly #tasksFacade = inject(TasksFacadeService);
   readonly #tasksDataViewer = inject(TasksDataViewerOptionsFactoryService);
   readonly #state = inject(DataViewerStateService);
   readonly #tasksApi = inject(TasksApiService);
   readonly #viewModelFactory = inject(TaskViewModelFactoryService);
   readonly #modalService = inject(ModalService);
   readonly #manageLang = inject(ManageLang);

   public columnsViewType = input.required<TaskViewTypes>();

   /**
    * Any request filters that are needed in the panel.
    * e.g.
    *  [
    *    {
    *      statuses: [2],
    *      locationIDs: [this.locationID],
    *      assetIDs: [this.assetID()],
    *      taskTypes: [TaskType.PlannedMaintenances],
    *     },
    *  ]
    */
   public requestFilters = input<Array<RequestFilter>>([]);

   /**
    * This will hide the export button
    */
   public canExport = input(false);

   /**
    * The label to display when there are no results and the user has not searched
    * NOTE: usually, the app displays the robot with a generic message, but in these panels
    * we want to display a more specific message
    */
   public noContentLabel = input("");

   /**
    * This is a message to display when the user does not have the correct permissions to view the content
    */
   public restrictedContentLabel = input("");

   /**
    * This is a flag to determine if the user has restricted permissions
    */
   public hasRestrictedPermissions = input(false);

   /**
    * This is an event that fires when we check the "include child assets" checkbox
    */

   public includeChildAssets = input(false);

   public columns = computed(
      () => this.#columnFactory.getByViewType(this.columnsViewType()) as Array<Column>,
   );
   public hasColumns = computed(() => this.columns().length > 0);
   public isLoading = signal(true);
   protected readonly lang = computed(() => this.#manageLang.lang() ?? {});

   private readonly modalCloseEvents$ = this.#modalService.closeEvents.pipe(
      filter(
         () => this.#modalService.getActiveModal()?.componentInstance instanceof PopAsset,
      ),
      startWith(0),
   );

   readonly #tasksRequest$: Observable<Partial<ListResponse<TaskEntity>>> = combineLatest(
      [this.#state.requestOptions$, this.modalCloseEvents$],
   ).pipe(
      tap(() => {
         this.isLoading.set(true);
      }),
      switchMap(([requestOptions]) =>
         this.#tasksApi.getList(requestOptions).pipe(
            finalize(() => {
               this.isLoading.set(false);
            }),
         ),
      ),
      catchError((error) => {
         console.error("Error fetching tasks:", error);
         this.isLoading.set(false);
         return of();
      }),
   );
   public tasksResponse = toSignal(this.#tasksRequest$);
   public tasks: Signal<Array<TaskDataViewerViewModel>> = computed(
      () =>
         this.tasksResponse()?.data?.map((taskEntity) =>
            this.#viewModelFactory.getTaskDataViewerViewModel(taskEntity),
         ) ?? [],
   );

   public tasksTotal = computed(() => this.tasksResponse()?.total ?? 0);

   public hasResults = computed(
      () => this.isLoading() || (!this.isLoading() && this.tasksTotal() > 0),
   );

   public isDataViewerVisible = computed(() => {
      const searchedText = this.#state.requestOptions().search;
      const hasTheUserSearched = searchedText !== undefined && searchedText !== "";
      return (
         this.hasColumns() &&
         !this.hasRestrictedPermissions() &&
         (this.isLoading() || this.hasResults() || hasTheUserSearched)
      );
   });

   public hasContent = computed(() => {
      const searchedText = this.#state.requestOptions().search;
      const hasTheUserSearched = searchedText !== undefined && searchedText !== "";
      const hasResults = this.hasResults();
      return hasResults || (!hasResults && hasTheUserSearched);
   });

   public constructor() {
      effect(() => {
         const columns = this.columns();
         const requestParams = getParams(columns);
         requestParams.includeChildAssets = this.includeChildAssets();

         queueMicrotask(() => {
            this.#state.setParams(requestParams);
         });
      });
   }

   public ngOnInit() {
      // Get the sort bind based on the view type
      const sort = this.#tasksDataViewer.getSortBy(this.columnsViewType());
      this.#state.setSort(sort);

      // Get the params and extra columns needed based on the columns definitions
      const columns = this.columns();
      let requestParams = getParams(columns);
      requestParams = { ...requestParams, includeChildAssets: this.includeChildAssets() };
      this.#state.setParams(requestParams);

      const extraColumns = getExtraColumns(columns);
      this.#state.setExtraColumns(extraColumns);

      // Sets the extra filters required for the view
      this.#state.setBaseFilters([...this.requestFilters()]);
   }

   public handleExportToExcel() {
      const requestOptions = this.#state.requestOptionsWithoutPagination();
      this.#tasksFacade.downloadExcel(requestOptions);
   }

   protected onSearchChange(search: string) {
      this.#state.setSearch(search);
   }
}
