import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   selector: "task-status-color-cell",
   imports: [],
   templateUrl: "./task-status-color-cell.component.html",
   styleUrl: "./task-status-color-cell.component.scss",
})
export class TaskStatusColorCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   @Input() public column?: Column;
}
