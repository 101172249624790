import type { OnInit } from "@angular/core";
import { inject, Component, Input } from "@angular/core";
import { SecondaryButtonComponent } from "@limblecmms/lim-ui";
import { DocTutorial } from "src/app/onboarding/services/tutorials/doc-tutorial";
import { ManageTutorial } from "src/app/onboarding/services/tutorials/manageTutorial";
import type { Tutorial } from "src/app/onboarding/services/tutorials/tutorial";

@Component({
   selector: "doc-thumbnail",
   templateUrl: "./doc-thumbnail.component.html",
   styleUrls: ["./doc-thumbnail.component.scss"],
   imports: [SecondaryButtonComponent],
})
export class DocThumbnailComponent implements OnInit {
   @Input() public tutorial: Tutorial | undefined;
   protected docTutorial: DocTutorial | undefined;
   private readonly manageTutorial = inject(ManageTutorial);

   public ngOnInit(): void {
      if (!(this.tutorial instanceof DocTutorial)) {
         throw new Error("Tutorial must be a DocTutorial");
      }

      this.docTutorial = this.tutorial;
   }

   protected openDoc = (): void => {
      if (!this.docTutorial) {
         return;
      }

      this.manageTutorial.openDocTutorial(this.docTutorial);
   };
}
