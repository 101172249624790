import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input, type OnInit } from "@angular/core";
import {
   IconComponent,
   MinimalIconButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   selector: "task-due-date-cell",
   imports: [
      CommonModule,
      IconComponent,
      MinimalIconButtonComponent,
      TooltipDirective,
      BetterDecimalPipe,
      BetterDatePipe,
   ],
   templateUrl: "./task-due-date-cell.component.html",
   styleUrls: ["./task-due-date-cell.component.scss"],
})
export class TaskDueDateCellComponent implements OnInit {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   /**
    * This property is intended for the printable version of the corresponding custom dashboard task list widget.
    * It prevents unnecessary interactive elements from rendering in the print-only context.
    */
   @Input public printOnly?: boolean;

   @Input() public column?: Column;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected canDeferTask = false;
   protected canChangeDueDate = false;

   private readonly facade = inject(TasksFacadeService);

   public ngOnInit(): void {
      this.canDeferTask = this.facade.getPermissionToDeferTask();

      this.canChangeDueDate = this.facade.getPermissionToChangeDueDate(this.task);
   }

   public emailReminder($event): void {
      this.facade.emailReminder(this.task);
      $event.stopPropagation();
   }

   public changeDueDate($event): void {
      this.facade.changeDueDate(this.task);
      $event.stopPropagation();
   }

   public deferTask($event): void {
      this.facade.deferTask(this.task);
      $event.stopPropagation();
   }
}
