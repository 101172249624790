import { CommonModule } from "@angular/common";
import { Component, computed, inject } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import { ManageLang } from "src/app/languages";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import type { ColumnKeys } from "src/app/shared/data-viewer/column-builder";

@Component({
   selector: "reviewer-cell-renderer-component",
   imports: [CommonModule, TranslateDirective],
   templateUrl: "./reviewer-cell-renderer.component.html",
})
export class ReviewerCellRendererComponent extends BaseCellRendererComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   submission = computed(
      () =>
         this.cell as {
            [ColumnKeys.WR_SUBMISSION_STATUS]: string;
            [ColumnKeys.REVIEWER]: string;
         },
   );

   protected hasReviewer = computed(() => {
      return (
         this.submission() &&
         this.submission().status !== "pending" &&
         this.submission().reviewer &&
         this.submission().reviewer.trim().length > 0
      );
   });
}
