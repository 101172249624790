import { computed, signal, type Signal, type WritableSignal } from "@angular/core";
import { Decimal } from "decimal.js";
import type { PhraseMap } from "src/app/languages/translation/phrase-map.types";
import { CountUnitConversionError } from "src/app/parts/unit-of-measure/provided-units/errors/count-unit-conversion.error";
import { DifferentUnitCategoryConversionError } from "src/app/parts/unit-of-measure/provided-units/errors/different-unit-category-conversion.error";
import { StringTooShortError } from "src/app/parts/unit-of-measure/provided-units/errors/string-too-short.error";
import type { ProvidedUnitParams } from "src/app/parts/unit-of-measure/provided-units/provided-unit.params";
import { UnitBase } from "src/app/parts/unit-of-measure/unit.model";
import { UnitCategories } from "src/app/parts/unit-of-measure/unit.types";
import { assert } from "src/app/shared/utils/assert.utils";

export class ProvidedUnit extends UnitBase<false> {
   private static readonly CONVERSION_SIGNIFICANT_DIGITS = 4;
   private static readonly ALIAS_MIN_LENGTH = 2;

   public readonly translationKey: keyof PhraseMap;
   private readonly _alias: WritableSignal<string | null>;
   public readonly shortDefault: string;
   public constructor({
      i18n,
      translationKey,
      short,
      aliasInitialValue,
      ...rest
   }: ProvidedUnitParams) {
      const alias = signal(aliasInitialValue);
      super({
         ...rest,
         singular: computed(() => i18n().t(translationKey)),
         short: computed(() => alias() ?? short),
      });

      this.translationKey = translationKey;
      this._alias = alias;
      this.shortDefault = short;
   }

   public get alias(): Signal<string | null> {
      return this._alias;
   }

   public updateAlias(newAlias: string | null): void {
      assert(
         newAlias === null || newAlias.length >= ProvidedUnit.ALIAS_MIN_LENGTH,
         new StringTooShortError(ProvidedUnit.ALIAS_MIN_LENGTH, newAlias?.length ?? 0),
      );

      this._alias.set(newAlias);
   }

   public convertUnit(value: number, to: ProvidedUnit): number {
      assert(
         this.category !== UnitCategories.Count && to.category !== UnitCategories.Count,
         new CountUnitConversionError(this, to),
      );
      assert(
         this.category === to.category,
         new DifferentUnitCategoryConversionError(this, to),
      );

      const valueInSIUnit = new Decimal(value).times(this.conversionFactorToSIUnit);
      return Number(
         valueInSIUnit
            .dividedBy(to.conversionFactorToSIUnit)
            .toPrecision(ProvidedUnit.CONVERSION_SIGNIFICANT_DIGITS),
      );
   }
}
