<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ lang().AddingAUser }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <label class="control-label">
            {{ lang().PleaseFillOutTheFollowingInformationAndClickAdd }}
         </label>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="config-container">
            @if (enableUserSSO) {
               <lim-ui-alert alertType="danger">{{
                  lang().ThisNeedsToMatchSSO
               }}</lim-ui-alert>
            }
            <div class="config-column">
               <div class="form-group" [ngClass]="{ 'has-error': firstNameError }">
                  <label class="control-label">
                     {{ lang().FirstName }} <span class="required-symbol">*</span>
                  </label>
                  <div class="input-controller">
                     <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="firstName"
                        (ngModelChange)="firstNameError = false"
                        tabindex="1"
                        data-test-id="addUserModalFirstNameInput"
                     />
                  </div>
               </div>
            </div>
            <div class="config-column">
               <div class="form-group" [ngClass]="{ 'has-error': lastNameError }">
                  <label class="control-label">
                     {{ lang().LastName }} <span class="required-symbol">*</span>
                  </label>
                  <div class="input-controller">
                     <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="lastName"
                        (ngModelChange)="lastNameError = false"
                        tabindex="2"
                        data-test-id="addUserModalLastNameInput"
                     />
                  </div>
               </div>
            </div>
         </div>
      </lim-ui-panel>
      <lim-ui-panel>
         <div class="config-container">
            <div class="config-column">
               <div class="form-group" [ngClass]="{ 'has-error': emailError }">
                  <label class="control-label">
                     {{ lang().EmailAddress }} <span class="required-symbol">*</span>
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        limUiTooltip="{{ lang().EmailAddressUseExplanation }}"
                     />
                  </label>
                  <div class="input-controller">
                     <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="email"
                        (ngModelChange)="emailError = false"
                        tabindex="3"
                        data-test-id="addUserModalEmailInput"
                     />
                  </div>
               </div>
               <div class="form-group" [ngClass]="{ 'has-error': phoneError }">
                  <label class="control-label">
                     {{ lang().PhoneNumber }} <span class="required-symbol">*</span>
                  </label>
                  <div class="input-controller">
                     <input
                        type="text"
                        class="form-control mask"
                        [(ngModel)]="phone"
                        (ngModelChange)="phoneError = false"
                        data-inputmask="'mask':'(999) 999-9999'"
                        tabindex="5"
                        data-test-id="addUserModalPhoneInput"
                     />
                  </div>
               </div>
            </div>
            <div class="config-column">
               <div class="form-group" [ngClass]="{ 'has-error': repeatEmailError }">
                  <label class="control-label">
                     {{ lang().RepeatEmailAddress }}
                     <span class="required-symbol">*</span>
                  </label>
                  <div class="input-controller">
                     <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="repeatEmail"
                        (ngModelChange)="repeatEmailError = false"
                        tabindex="4"
                        data-test-id="addUserModalRepeatEmailInput"
                     />
                  </div>
               </div>
            </div>
         </div>
      </lim-ui-panel>
      <lim-ui-panel>
         <div class="config-container">
            <div class="config-column">
               <div class="form-group" [ngClass]="{ 'has-error': roleError }">
                  <label class="control-label" [ngClass]="{ 'has-error': roleError }">
                     {{ lang().Role }} <span class="required-symbol">*</span>
                  </label>
                  <lim-ui-form-dropdown-input
                     [hasError]="roleError"
                     [inputString]="selectedRoleDescription ?? ''"
                     data-test-id="addUserModalRoleDropdown"
                  >
                     <ng-container menu>
                        @for (role of roles | orderBy: "roleDescription"; track role) {
                           <lim-ui-dropdown-text-item
                              [displayString]="role.roleDescription"
                              (click)="
                                 roleID = role.roleID;
                                 selectedRoleDescription = role.roleDescription;
                                 roleError = false
                              "
                              (itemSelectedEvent)="handleFormDropdownSelected($event)"
                              data-test-id="addUserModalRoleDropdownItem"
                           />
                        }
                     </ng-container>
                  </lim-ui-form-dropdown-input>
               </div>
               <div class="form-group" [hidden]="!ssoEnabled">
                  <label class="control-label"> {{ lang().EnableSSO }} </label>
                  <div class="pretty success smooth">
                     <input
                        type="checkbox"
                        [(ngModel)]="enableUserSSO"
                        style="margin-top: 3px; width: 20px"
                        data-test-id="addUserModalEnableSSOCheckbox"
                     />

                     <label class="chkItemInlineDisplay" style="margin: 7px">
                        <i class="mdi mdi-check"></i>
                     </label>
                  </div>
               </div>
            </div>
            <div class="config-column">
               <div class="form-group" [ngClass]="{ 'has-error': locationError }">
                  <label class="control-label" [ngClass]="{ 'has-error': locationError }">
                     {{ lang().Location }} <span class="required-symbol">*</span>
                  </label>
                  <lim-ui-form-dropdown-input
                     button
                     id="searchLocationDropdown"
                     [inputString]="selectedLocationName ?? ''"
                     [hasError]="locationError"
                     data-test-id="addUserModalLocationDropdown"
                  >
                     <div menu aria-labelledby="searchLocationDropdown">
                        <lim-ui-search-box
                           [useDropdownStyling]="true"
                           [(searchVal)]="searchLocations"
                           (searchValChange)="runSearch()"
                           [placeholder]="lang().Search"
                           [hidden]="!totalLocLength || !(totalLocLength > 5)"
                        />
                        <lim-ui-dropdown-divider
                           [hidden]="!totalLocLength || !(totalLocLength > 5)"
                        />
                        @for (location of locations; track location) {
                           <lim-ui-dropdown-text-item
                              [displayString]="location.locationNameWithRegions"
                              (click)="setSelectedLocation(location.locationID)"
                              data-test-id="addUserModalLocationDropdownItem"
                           />
                        }
                     </div>
                  </lim-ui-form-dropdown-input>
               </div>
            </div>
         </div>
      </lim-ui-panel>

      @if (loading) {
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-primary-button
         dataLogLabel="manageUsers-storeAUser"
         (click)="submit()"
         [disabled]="disabled"
         data-test-id="addUserModalAddButton"
      >
         {{ lang().Add }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
