import { inject, Component, Input, computed } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

interface HasStatusName {
   statusName: string;
}

@Component({
   imports: [],
   selector: "status-name-cell",
   templateUrl: "./status-name-cell.component.html",
})
export class StatusNameCellComponent {
   @Input({ required: true }) public target!: HasStatusName;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
