import { inject, Injectable } from "@angular/core";
import { TranslationService } from "src/app/languages/translation/translation.service";
import type {
   PartUsageEntity,
   PartUsageEntityFilters,
} from "src/app/parts/components/shared/part-usage-api-service/part-usage-api.models";
import { PartUsageApiService } from "src/app/parts/components/shared/part-usage-api-service/part-usage-api.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import type { RequestOptions } from "src/app/shared/services/flannel-api-service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { FakePercentageTimerService } from "src/app/tasks/services/fake-percentage-timer/fake-percentage-timer.service";

export type PartsUsageExcelCell = {
   "Total Used": number;
   "Total Cost": number;
   "Part ID": number;
   "Date Used": string;
   "Part Name": string;
   "Part Number": string;
   "Asset Name": string;
};

@Injectable({ providedIn: "root" })
export class AssetPartUsageExporterService {
   private readonly partsUsageApiService = inject(PartUsageApiService);
   protected i18n = inject(TranslationService).i18n;
   private readonly fakePercentageTimerService = inject(FakePercentageTimerService);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUtil = inject(ManageUtil);

   public async exportPartsUsageData(
      requestOptions: Partial<RequestOptions<PartUsageEntityFilters>>,
   ): Promise<void> {
      const total = await this.partsUsageApiService.getTotal(requestOptions);

      this.fakePercentageTimerService.setLoadingTimer(total, 2000);

      const partsUsed = await this.partsUsageApiService.getAllItems(
         {
            ...requestOptions,
            columns: requestOptions.columns ? `${requestOptions.columns},asset` : "asset",
         },
         total,
      );

      const excelRows = this.formatPartsUsedToExcel(partsUsed);
      this.convertToExcel(excelRows);
   }

   private formatPartsUsedToExcel(
      partUsageEntities: Array<PartUsageEntity>,
   ): Array<PartsUsageExcelCell> {
      return partUsageEntities.map((partUsageEntity) => {
         return {
            "Total Used": partUsageEntity.usedNumber,
            "Total Cost": partUsageEntity.totalPriceOfParts,
            "Part ID": partUsageEntity.partID,
            "Date Used": new Date(
               partUsageEntity.checklistCompletedDate * 1000,
            ).toDateString(),
            "Part Name": partUsageEntity.partName,
            "Part Number": partUsageEntity.partNumber,
            "Asset Name": partUsageEntity.asset?.assetName ?? "",
         };
      });
   }

   private convertToExcel(partsUsed: Array<PartsUsageExcelCell>) {
      const today = this.betterDate.createTodayTimestamp();
      const fileName = `${this.i18n().t("partUsageExcelTitle")}-${today}.xlsx`;

      const partUsageWithLanguage = partsUsed.map((partUsage) => {
         const excelRow = {};
         excelRow[this.i18n().t("partUsageExcelTotalUsed")] = partUsage["Total Used"];
         excelRow[this.i18n().t("partUsageExcelTotalCost")] = partUsage["Total Cost"];
         excelRow[this.i18n().t("partUsageExcelPartID")] = partUsage["Part ID"];
         excelRow[this.i18n().t("partUsageExcelDate")] = partUsage["Date Used"];
         excelRow[this.i18n().t("partUsageExcelPartName")] = partUsage["Part Name"];
         excelRow[this.i18n().t("partUsageExcelPartNumber")] = partUsage["Part Number"];
         excelRow[this.i18n().t("partUsageExcelAssetName")] = partUsage["Asset Name"];
         return excelRow;
      });

      this.manageUtil.objToExcel(partUsageWithLanguage, "Parts Used", fileName);
   }
}
