<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (vendor) {
         <lim-ui-panel>
            <div class="panel-container">
               <div class="settings-row-spacing">
                  <label>{{ lang().Location }}</label>
                  <div class="settings-row-spacing">
                     <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="locationsIndex[vendor.locationID].locationName"
                        disabled="true"
                     />
                     <lim-ui-dropdown>
                        <lim-ui-primary-button button secondaryIcon="angleDown"
                           >{{ lang().Move }}
                        </lim-ui-primary-button>
                        <div menu>
                           @if (totalLocLength > 5) {
                              <lim-ui-search-box
                                 [useDropdownStyling]="true"
                                 [(searchVal)]="searchBar"
                                 (searchValChange)="runSearch()"
                              />
                           }

                           <lim-ui-dropdown-divider />

                           @for (location of locations; track location) {
                              <lim-ui-dropdown-text-item
                                 [ngClass]="{ dropDownSelectListBorder: !$last }"
                                 [hidden]="!(location.locationID != vendor?.locationID)"
                                 [displayString]="location.locationNameWithRegions"
                                 [outputString]="location.locationNameWithRegions"
                                 (itemSelectedEvent)="changeLocation(location)"
                              />
                           }
                        </div>
                     </lim-ui-dropdown>
                  </div>
               </div>

               <div class="settings-row-spacing">
                  <label
                     >{{ lang().VendorStartedOn }}
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        limUiTooltip="{{ lang().VendorStartedOnTooltip }}"
                     />
                  </label>

                  <div class="settings-row-spacing">
                     <lim-ui-date-picker-input
                        limUiTooltip="{{ lang().SetWhenThisScheduleGoesLive }}"
                        [model]="dateTime"
                        (modelChange)="setVendorCreatedDate()"
                     />
                  </div>
               </div>
            </div>
         </lim-ui-panel>
      }
      @if (errorMsg !== "") {
         <lim-ui-panel>
            @if (errorMsg) {
               <lim-ui-alert [title]="lang().Warning" alertType="danger">
                  <div [limbleHtml]="errorMsg"></div>
               </lim-ui-alert>
            }
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer class="flex-start">
      <lim-ui-danger-button (click)="deleteVendor()">
         {{ lang().DeleteVendor }}
      </lim-ui-danger-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
