<span class="filter-inputs-container">
   <lim-ui-dropdown>
      <lim-ui-secondary-button button secondaryIcon="angleDown">
         <span [limbleHtml]="selected()"></span>
      </lim-ui-secondary-button>

      @if (fieldID()) {
         <div menu>
            @for (option of options(); track option) {
               <lim-ui-dropdown-text-item
                  [displayString]="option"
                  [outputString]="option"
                  (click)="setDropdownFilter(option)"
               />
            }
         </div>
      }
   </lim-ui-dropdown>
</span>
