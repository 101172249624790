<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel class="scroll-height-inheritance">
         <div class="listTable scroll-height-inheritance">
            <div class="listItem listItemHeader">
               <sort-column
                  class="col-md-6 mobileLargeDisappear"
                  column="checklistName"
                  name="{{ lang().TaskName }}"
                  [(bind)]="sortBind"
               />
               <sort-column
                  class="col-md-3 mobileLargeDisappear"
                  column="scheduleStartDate"
                  name="{{ lang().Date }}"
                  [(bind)]="sortBind"
               />
               <sort-column
                  class="col-md-3 mobileLargeDisappear"
                  column="assignment"
                  name="{{ lang().AssignedTo }}"
                  [(bind)]="sortBind"
               />
            </div>
            <lim-ui-scroll-container [thin]="true">
               @for (schedule of schedulesViewModel(); track schedule) {
                  <div class="listItem" [ngClass]="{ tranactionEveryOther: $even }">
                     <div class="col-md-6 task-info-container">
                        @if (
                           schedule.assetID == 0 ||
                           schedule.assetID === undefined ||
                           schedule.assetID === null
                        ) {
                           <lim-ui-icon icon="squareCaretRightRegular" iconSize="small" />
                        }
                        @if (
                           schedule.assetID != 0 &&
                           schedule.assetID !== undefined &&
                           schedule.assetID !== null
                        ) {
                           <lim-ui-icon
                              icon="cube"
                              iconSize="small"
                              class="pop-asset-cube"
                              (click)="viewAsset(schedule)"
                              placement="right"
                              limUiTooltip="{{ lang().ViewTheAssetThisTaskIsFor }}"
                           />
                        }
                        <lim-ui-icon
                           icon="houseChimney"
                           iconSize="small"
                           placement="right"
                           limUiTooltip="{{ lang().ThisTaskIsLocatedAt }} {{
                              schedule.locationName
                           }}"
                        />
                        <a (click)="popTask(schedule.checklistID)" class="cursor">
                           {{ schedule.checklistName }}
                        </a>
                     </div>

                     <div class="col-md-3">
                        {{ schedule.scheduleStartDate * 1000 | betterDate: "date" }}
                     </div>

                     <div class="col-md-3">
                        <a
                           class="cursor"
                           (click)="changeSchedule(schedule)"
                           [ngClass]="{
                              redColor: schedule.assignment === 'Unassigned',
                           }"
                           [hidden]="!checkCredAtLocation(schedule.locationID, 56)"
                        >
                           {{ schedule.assignment }}
                        </a>
                        <span [hidden]="!!checkCredAtLocation(schedule.locationID, 56)">
                           {{ schedule.assignment }}
                        </span>
                     </div>
                  </div>
               }
            </lim-ui-scroll-container>
            @if (isLoading) {
               <div>
                  <div class="loading-icon">
                     <lim-ui-loading-animation />
                  </div>
               </div>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
