//commentFiles modifies all of the inputs - adds and deletes files/image, sets uploadObject.uploading so the container can wait to submit if it wants
import type { OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { inject, Component, Input, computed, input, output } from "@angular/core";
import { IconComponent, ModalService, type Aliases } from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import { EditableImage } from "src/app/files/components/editableImage/editableImage.element.component";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import type { UploadType } from "src/app/files/services/file-service.types";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "comment-files",
   templateUrl: "./commentFiles.element.component.html",
   styleUrls: ["./commentFiles.element.component.scss"],
   imports: [FileUploader, EditableImage, FileListItem, IconComponent],
})
export class CommentFiles implements OnInit, OnChanges {
   public readonly uploadObject = input.required<any>(); // TODO(FORGE-125): Replace with FileUploaderModel type
   @Input() public images;
   @Input() public documents;
   @Input() public files;
   @Input() public label;
   public readonly accept = input<UploadType>("all");
   public readonly icon = input.required<Aliases>();
   public documentExts;
   public imageExts;

   public axios = axios;

   private readonly manageFiles = inject(ManageFiles);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly hasFiles = output<boolean>();
   //lets uploadCall wait till things have been initialized to be set
   public ngOnChanges(changes: SimpleChanges) {
      if (this.uploadObject().uploadLimit) {
         if (changes.files?.currentValue?.length >= this.uploadObject().uploadLimit) {
            this.uploadObject().preventMoreUploads = true;
         } else {
            this.uploadObject().preventMoreUploads = false;
         }
      }

      this.uploadObject().uploadCall = async (postUrl: string, formData: FormData) => {
         this.uploadObject().uploading = true;

         return this.axios({
            url: postUrl,
            method: "POST",
            data: formData,
         });
      };
      //used to sort in the template but upgrade to angular made that hard
      this.groupFilesByType();
   }

   public ngOnInit() {
      this.groupFilesByType();
   }

   private groupFilesByType() {
      if (!this.imageExts) {
         this.imageExts = this.manageFiles.imageExts;
      }
      if (!this.documentExts) {
         this.documentExts = this.manageFiles.fileExts;
      }
      if (this.files) {
         this.images = [];
         this.documents = [];
         for (const file of this.files) {
            if (this.imageExts.includes(file.fileExt)) {
               this.images.push(file);
            } else {
               this.documents.push(file);
            }
         }
         this.images = [...this.images];
         this.documents = [...this.documents];

         this.hasFiles.emit(Boolean(this.files.length));
      }
   }

   //opens a doka instance -> no permissions here as if you are on the build tasks page you should have permission to edit the files.
   openEditor = async (file) => {
      const doka = await this.manageFiles.createImageEditor(file.getURL);
      //when the user clicks confirm this deletes the old version of the file, and uploads the edited file
      //has to convert doka's output to formdata for uploading and play with the fileName a little bit so the names
      //don't get longer each time the file is edited
      doka.onconfirm = (out) => {
         this.uploadObject().deleteData.fileName = file.fileName;
         if (file.fileID) {
            this.uploadObject().deleteData.fileID = file.fileID;
         }

         //send the call to delete the image
         this.uploadObject()
            .deleteCall(this.uploadObject().deleteData)
            .then((answer) => {
               if (typeof this.uploadObject().deleteSuccess === "function") {
                  this.uploadObject().deleteSuccess(answer);
               }
            });

         const formData = new FormData();
         formData.append("myfile", out.file, file.fileName.replace(/\d\d\d\d-/, ""));

         this.uploadObject()
            .uploadCall(this.uploadObject().posturl, formData)
            .then((response) => {
               this.uploadObject().uploadComplete(response.data);
               this.uploadObject().uploading = false;
            });
      };
   };

   deleteFile = (file) => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().AreYouSureYouWouldLikeToDeleteThisFile}<br /><br />${file.fileName}<br /><br /><b><span class="red-color">${this.lang().ThisActionCanNotBeUndone}</span></b><br />`,
            title: this.lang().DeleteFile,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.uploadObject().deleteData.fileName = file.fileName;
            if (file.fileID) {
               this.uploadObject().deleteData.fileID = file.fileID;
            }
            this.uploadObject()
               .deleteCall(this.uploadObject().deleteData)
               .then((answer) => {
                  this.uploadObject().deleteSuccess(answer);
               });
         }
      });
   };
}
