import { inject, Injectable } from "@angular/core";
import type { ActivatedRouteSnapshot, Data } from "@angular/router";
import { Router } from "@angular/router";

/**
 * This service provides utility functions for tasks related to Angular routing.
 */

@Injectable({ providedIn: "root" })
export class RoutingService {
   private readonly router = inject(Router);

   public getAllRouteNames(): Array<unknown> {
      return this.getAllRouteData().map((data) => data.routeName);
   }

   public getAllRouteData(): Array<Data> {
      return this.getDescendantRouteData(this.router.routerState.root.snapshot);
   }

   public getDescendantRouteData(
      startingRoute: ActivatedRouteSnapshot | null | undefined,
   ): Array<Data> {
      const data: Array<Data> = [];
      let route = startingRoute;
      while (route !== null && route !== undefined) {
         data.push(route.data);
         route = route.firstChild;
      }
      return data;
   }

   public getDomain(): string {
      const hostname = window.location.hostname;
      if (this.isLocalhost()) {
         return "localhost";
      }
      //remove subdomains from the hostname
      return hostname.split(".").slice(-2).join(".");
   }

   public isLocalhost(): boolean {
      return window.location.hostname.endsWith("localhost");
   }

   public loginRequired(): boolean {
      return this.getAllRouteData().some((data) => data.requireLogin);
   }
}
