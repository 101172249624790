<div class="task-hint-container">
   @if (
      otherPurchaseOrdersAndStatus &&
      otherPurchaseOrdersAndStatus.size > 0 &&
      other &&
      state != 100
   ) {
      <div class="related-items">
         <h3 class="pos">
            <span>{{ lang().OpenPOsThatAlreadyHaveThisItem }}</span>
            <lim-ui-icon
               icon="circleQuestionRegular"
               limUiTooltip="{{ lang().OpenPOsThatAlreadyHaveThisItemTooltip }}"
            />
         </h3>

         @if (otherPurchaseOrdersAndStatus.size >= 2) {
            <div class="toggle-pos">
               <span (click)="showOtherPOs = !showOtherPOs">
                  {{ lang().YouHave }} {{ otherPurchaseOrdersAndStatus.size }}
                  {{ lang().OpenPOs }}
                  <lim-ui-minimal-icon-button
                     [icon]="showOtherPOs ? 'chevronDown' : 'chevronRight'"
                  />
               </span>
            </div>
         }

         @if (showOtherPOs === true) {
            @for (purchaseOrder of otherPurchaseOrdersAndStatus; track purchaseOrder) {
               <div>
                  <a (click)="popPoComponent(purchaseOrder.poID)" class="related-link po">
                     {{ lang().PO }} - {{ purchaseOrder.poNumber }}
                  </a>
                  ({{ purchaseOrder.currentState?.name }})
               </div>
            }
         }
      </div>
   }

   @if (tasksWaiting.size > 0) {
      <div class="related-items">
         @if (state === 100) {
            {{ lang().OpenTasksAssociatedWithThisItem }}
         } @else {
            <h3>
               {{ lang().TasksWaitingOnThisItem }}
            </h3>
         }
         @for (task of tasksWaiting; track task) {
            <div>
               <a (click)="viewTask(task)" class="related-link task">
                  <span [limbleHtml]="task.checklistName"></span> - #{{
                     task.checklistID
                  }}
               </a>

               @if (task.assetID != 0) {
                  <span>
                     -
                     <a
                        (click)="viewAsset(task)"
                        class="related-link asset"
                        [limbleHtml]="this.taskAssetNames.get(task.checklistID)"
                     ></a>

                     <asset-parent-list
                        [assetID]="task.assetID ?? 0"
                        [preventParentAccess]="false"
                        linkColorClass="green-color"
                        [restrict]="false"
                     />
                  </span>
               }
            </div>
         }
      </div>
   }

   @if (tasksUsed.size > 0) {
      <div class="related-items">
         <h3>
            {{ lang().TasksThatUsedThisItem }}
         </h3>
         @for (task of tasksUsed; track task) {
            <div>
               <a (click)="viewTask(task)" class="related-link task">
                  <span [limbleHtml]="task.checklistName"></span> - #{{
                     task.checklistID
                  }}
               </a>

               @if (task.assetID != 0) {
                  <span>
                     -
                     <a
                        (click)="viewAsset(task)"
                        class="cursor related-link asset"
                        [limbleHtml]="this.taskAssetNames.get(task.checklistID)"
                     ></a>

                     <asset-parent-list
                        [assetID]="task.assetID ?? 0"
                        [preventParentAccess]="false"
                        linkColorClass="green-color"
                        [restrict]="false"
                     />
                  </span>
               }
            </div>
         }
      </div>
   }
</div>
