import { BaseSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/base-schema";
import { ProvidedStockUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/provided-stock-unit-schema";
import { z } from "zod";

const MeasuredBaseSchema = BaseSchema.extend({
   purchasableProvidedOrderUnitID: z.number(),

   purchasableSize: z.null(),
   purchasableCustomOrderUnitID: z.null(),
   purchasableCustomOrderUnitNameShort: z.null(),
   purchasableCustomOrderUnitNameSingular: z.null(),
   purchasableProvidedSizeUnitID: z.null(),
   purchasableProvidedSizeUnitAlias: z.null(),
});

export const MeasuredPurchasablePurchaseOrderItemDtoSchema = MeasuredBaseSchema.merge(
   ProvidedStockUnitSchema,
);

export type MeasuredPurchasablePurchaseOrderItemDto = z.infer<
   typeof MeasuredPurchasablePurchaseOrderItemDtoSchema
>;
