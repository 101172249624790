import { NgClass, NgTemplateOutlet } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, EventEmitter, Input, Output, computed } from "@angular/core";
import {
   IconComponent,
   SearchBoxComponent,
   SelectionControlsComponent,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationHierarchyService } from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy.service";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "pick-locations",
   templateUrl: "./pickLocations.element.component.html",
   imports: [
      SearchBoxComponent,
      SelectionControlsComponent,
      NgClass,
      IconComponent,
      NgTemplateOutlet,
      NoSearchResults,
   ],
})
export class PickLocations implements OnInit, OnDestroy {
   public treeData;
   public prepRst;
   public nodesUniqueIDIndex;
   public searchBar;
   public randomID;
   public regionsIndex;
   public locationsIndex;
   public allLocationsLength;
   public allRegionsLength;
   public regionTreeLength;
   public locationWatchVarSub: any;
   @Input() public data: {
      selectedLocations: number[];
      selectOne: boolean;
      massSelectOptions: boolean;
      filterByPermission?: string;
      initialSearch?: string;
      submit?: () => void;
   } = {
      selectedLocations: [],
      selectOne: false,
      massSelectOptions: true,
   };
   @Input() public anyLocation;
   @Output() public readonly dataChange = new EventEmitter();
   @Output() public readonly anyLocationChange = new EventEmitter();
   @Output() public readonly searchChange = new EventEmitter<string>();

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly credService = inject(CredService);
   private readonly locationHierarchyService = inject(LocationHierarchyService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.nodesUniqueIDIndex = {};
      this.searchBar = this.data?.initialSearch ?? "";
      this.randomID = Math.floor(Math.random() * 1000000000); //id we use as a selector so that the html we are putting this into are unique.

      this.locationWatchVarSub = this.manageObservables.setSubscription(
         "locationWatchVar",
         () => {
            if (this.manageLocation.getWatchVar() > 0) {
               this.prepRst = this.locationHierarchyService.prepLocRegionData(); //we call the prep data before hand because I don't want to erease all of it
               this.buildData();
            }
         },
      );
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.locationWatchVarSub);
   }

   searchFilter = () => {
      setTimeout(() => {
         this.buildData();
         this.searchChange.emit(this.searchBar);
      }, 1);
   };

   buildData = () => {
      let filteredLocations = this.prepRst.locations;
      if (this.data?.filterByPermission) {
         let filterBy;
         switch (this.data.filterByPermission) {
            case "ManagePOs":
               filterBy = this.credService.Permissions.ManagePOs;
               break;
            default:
               break;
         }
         if (!filterBy) {
            return;
         }
         filteredLocations = filteredLocations.filter((location) =>
            this.credService.isAuthorized(location.locationID, filterBy),
         );
      }

      const rst = this.locationHierarchyService.buildHierarchy({
         locations: filteredLocations,
         regions: this.prepRst.regions,
         search: this.searchBar,
         filterRegions: true,
         filterLocations: true,
         alwaysReturnRegions: false,
         totalLocationCount: this.manageLocation.getLocations().length,
      });
      this.treeData = rst.regionTree;
      this.regionsIndex = rst.regionsIndex;
      this.manageFilters.removeEmptyRegions(this.treeData);
      this.locationsIndex = rst.locationsIndex;

      this.allLocationsLength = this.manageLocation.getLocations().length;
      this.allRegionsLength = this.manageLocation.getRegions().length;
      this.regionTreeLength = rst.regionTree.length;

      if (this.anyLocation == true) {
         for (const key in this.locationsIndex) {
            const loc = this.locationsIndex[key];
            loc.selected = true;
            if (this.data.selectedLocations.includes(loc.locationID)) {
               //it already has it
            } else {
               this.data.selectedLocations.push(loc.locationID);
               this.dataChange.emit(this.data);
            }
         }
         for (const key in this.regionsIndex) {
            this.regionsIndex[key].selected = true;
         }
      }

      //if they have a lot of locations and they currently aren't searching then we need to hide the region's kids for speed reasons
      if (
         (this.prepRst.locations.length > 6 || this.prepRst.regions.length > 10) &&
         (this.searchBar == false || this.searchBar == "")
      ) {
         for (const key in this.regionsIndex) {
            const region = this.regionsIndex[key];
            region.showKids = false;
         }
      }

      for (const key in this.regionsIndex) {
         const region = this.regionsIndex[key];
         region.uniqueID = `${this.randomID}-PICK-LOC-R-${region.regionID}`;
         this.nodesUniqueIDIndex[`${this.randomID}-PICK-LOC-R-${region.regionID}`] =
            region;
      }

      for (const key in this.locationsIndex) {
         const location = this.locationsIndex[key];
         location.uniqueID = `${this.randomID}-PICK-LOC-L-${location.locationID}`;
         this.nodesUniqueIDIndex[`${this.randomID}-PICK-LOC-L-${location.locationID}`] =
            location;

         if (this.data.selectedLocations.includes(location.locationID)) {
            location.selected = true;
         }
      }

      if (this.searchBar == false || this.searchBar == "") {
         const testRegions = () => {
            for (const key in this.regionsIndex) {
               const region = this.regionsIndex[key];

               let haveAll = true;
               let haveOne = false;
               for (const kid of region.nodes) {
                  if (kid.selected == true) {
                     haveOne = true;
                  } else {
                     haveAll = false;
                  }
               }

               if (!region.nodes || region.nodes.length == 0) {
                  haveAll = false;
               }

               if (haveAll) {
                  region.selected = true;
                  region.showKids = true;
               } else if (haveOne) {
                  region.showKids = true;
               }
            }
         };

         let count = 0;
         while (count < 3) {
            //we have to run this a couple times because a region might not be selected on the first go.  Think of it this way.  We have Midwest region, with utah as a sub region, utah has 2 locations salt lake and draper.  First run will show that Utah should be selected, but when it checks midwest on the first run it will think it isn't selected beacuse there is a small change that utah hasn't been calculated yet.  Running this a couple times is very quick and makes sure this bug doesn't happen.
            count++;
            testRegions();
         }
      }
   };

   collapse = (id) => {
      const node = this.nodesUniqueIDIndex[id];
      node.showKids = !node.showKids;

      const col = $(`#collapse-${node.uniqueID}`);
      if (node.showKids) {
         col.removeClass("fa-solid fa-chevron-right");
         col.addClass("fa-solid fa-chevron-down");
      } else {
         col.removeClass("fa-solid fa-chevron-down");
         col.addClass("fa-solid fa-chevron-right");
      }
   };

   selected = (node) => {
      node.selected = true;
   };

   deselected = (node) => {
      node.selected = false;
   };

   selectKids = (kids) => {
      for (const kid of kids) {
         kid.selected = true;
         this.mark(kid);
         this.selected(kid);
         if (kid.nodes) {
            this.selectKids(kid.nodes);
         }
      }
   };

   deselectKids = (kids) => {
      for (const kid of kids) {
         kid.selected = false;
         this.mark(kid);
         this.deselected(kid);
         if (kid.nodes) {
            this.deselectKids(kid.nodes);
         }
      }
   };

   deselectRegionParent = (region) => {
      region.selected = false;
      this.mark(region);
      this.deselected(region);
      if (region.parentRegionID > 0) {
         this.deselectRegionParent(this.regionsIndex[region.parentRegionID]);
      }
   };

   //jaxymn was here

   mark = (node) => {
      //we only mark locations as selected or not
      if (node.location) {
         if (node.selected) {
            if (this.data.selectedLocations.includes(node.locationID)) {
               //it already has it
            } else {
               this.data.selectedLocations.push(node.locationID);
               this.dataChange.emit(this.data);
            }
         } else {
            //deselecting so let's remove it
            const index = this.data.selectedLocations.indexOf(node.locationID);
            if (index > -1) {
               this.data.selectedLocations.splice(index, 1);
               this.dataChange.emit(this.data);
            }
         }
      }
   };

   pickNode = (id) => {
      const node = this.nodesUniqueIDIndex[id];

      node.selected = !node.selected;

      if ((node.region || node.noRegion) && node.selected == true) {
         //force open if they are picking a region
         node.showKids = false;
         this.collapse(id);
      }

      setTimeout(() => {
         //DON"T REMOVE.  This is needed else when you pick a region while it is collapsed it will not auto select it's kids because they don't exist yet

         this.mark(node);

         if (node.location) {
            if (node.selected) {
               this.selected(node);
            } else {
               this.deselected(node);

               if (node.regionID > 0) {
                  this.deselectRegionParent(this.regionsIndex[node.regionID]);
               }
            }
         } else if (node.selected) {
            //they are picking a region so select/deselect all of their kids
            this.selected(node);
            this.selectKids(node.nodes);
         } else {
            this.deselected(node);
            this.deselectKids(node.nodes);
         }

         this.anyLocation = false;
         this.anyLocationChange.emit(this.anyLocation);
         // this.$scope.$digest();
      }, 2);
   };

   pickNodeSelectOne = (id) => {
      const node = this.nodesUniqueIDIndex[id];

      if (node.region || node.noRegion) {
         this.collapse(id);
         return;
      }

      if (
         node.location &&
         this.data.selectOne == true &&
         this.data.submit !== undefined &&
         node.selected == true
      ) {
         this.data.submit();
         return;
      }

      for (const key in this.locationsIndex) {
         //clear out any other selections
         const loc = this.locationsIndex[key];
         loc.selected = false;
         this.deselected(loc);
         this.mark(loc);
      }

      node.selected = true;
      this.mark(node);
      this.selected(node);
   };

   markAllLocations = (selected: boolean) => {
      for (const node of this.treeData) {
         node.selected = !selected;
         this.pickNode(node.uniqueID);
      }

      setTimeout(() => {
         //VERY important to keep this.  if you remove then a bug occurs when clicking Any Location and it flickers
         this.anyLocation = selected;
         this.anyLocationChange.emit(this.anyLocation);
      }, 10);
   };
}
