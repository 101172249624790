import type { OnInit } from "@angular/core";
import { Component, computed, inject, Input } from "@angular/core";
import {
   IconComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { finalize, take } from "rxjs";
import {
   type AssetVendorAssociation,
   AssetVendorAssociationService,
} from "src/app/assets/services/asset-vendor-association.service";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { Location } from "src/app/locations/types/location.types";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { AssociationType } from "src/app/shared/types/association.types";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

type VendorInfo = Pick<Vendor, "vendorID" | "vendorName"> &
   Pick<AssetVendorAssociation, "associationType"> &
   Pick<Location, "locationName">;

@Component({
   selector: "asset-vendors-tab",
   templateUrl: "./asset-vendors-tab.component.html",
   styleUrls: ["./asset-vendors-tab.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      PrimaryButtonComponent,
      LimbleHtmlDirective,
      MinimalIconButtonComponent,
      TooltipDirective,
      OrderByPipe,
      NgxSkeletonLoaderModule,
   ],
})
export class AssetVendorsTabComponent implements OnInit {
   private readonly manageVendor = inject(ManageVendor);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly assetVendorAssociationService = inject(AssetVendorAssociationService);
   private readonly manageUtil = inject(ManageUtil);

   @Input() public asset: Asset | undefined;
   @Input() public restrict: boolean | undefined;
   @Input() public dataLogOptions: DataLogEventDefinition | undefined;

   protected vendorsInfo: VendorInfo[] = [];
   protected isLoading: boolean = false;
   protected skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly AssociationType = AssociationType;

   public ngOnInit() {
      this.buildVendorAssociations();
   }

   protected buildVendorAssociations(): void {
      if (!this.asset) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.isLoading = true;

      this.vendorsInfo = [];

      this.assetVendorAssociationService
         .getVendors([this.asset.assetID])
         .pipe(
            take(1),
            finalize(() => {
               this.isLoading = false;
            }),
         )
         .subscribe({
            next: (associations) => {
               for (const association of associations) {
                  if (association.assetID === null || association.vendorID === null)
                     continue;

                  const vendor = this.manageVendor.getVendor(association.vendorID);
                  if (!vendor) {
                     continue;
                  }

                  if (!vendor.locationID) continue;

                  const locationName =
                     this.manageLocation.getLocation(vendor.locationID)?.locationName ??
                     "";

                  this.vendorsInfo.push({
                     vendorID: association.vendorID,
                     vendorName: vendor.vendorName,
                     associationType: association.associationType,
                     locationName,
                  });
               }
            },
         });
   }

   protected associateVendorsToAsset(): void {
      if (!this.asset) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.ChangeVendorAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred138Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PickVendors);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message:
               this.lang().PleaseChooseWhichVendorsYouWouldLikeToAssociateThisVendorWith,
            title: this.lang().PickVendors,
            dataLogOptions: this.dataLogOptions,
            data: {
               singleLocation: 0,
               selectOne: false,
               restrictToCred: true,
               iDontKnowOption: false,
               task: false,
               locationID: this.asset.locationID,
               extraCredCheck: this.credService.Permissions.ChangeVendorAssetAssociations,
            },
         },
      };

      instance.result.then((pickedVendors) => {
         assert(this.asset);
         if (pickedVendors.length > 0) {
            this.manageAssociations
               .associateVendorsToAsset(pickedVendors, this.asset.assetID)
               .then((answer) => {
                  if (answer?.data.success === true) {
                     this.buildVendorAssociations();

                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   protected deleteAssetVendorAssociation(vendorID: number): void {
      if (!this.asset) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.ChangeVendorAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred138Fail, "danger", 10000);
         return;
      }

      const vendor = this.manageVendor.getVendor(vendorID);
      assert(vendor);

      this.manageAssociations
         .deleteAssetVendorAssociation(vendor.vendorID, this.asset.assetID)
         .then((answer) => {
            if (answer?.data.success === true) {
               this.buildVendorAssociations();

               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   }

   protected popVendor(vendorID: number): void {
      if (!this.asset) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      const vendor = this.manageVendor.getVendor(vendorID);

      if (!vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: this.restrict,
            },
         },
      };
   }
}
