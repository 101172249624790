@if (task?.isCompleted !== undefined) {
   <div
      [ngClass]="
         isInModal
            ? 'name-priority-type-status-container-modal'
            : 'name-priority-type-status-container'
      "
      class="allowOffline cell-container"
   >
      <span class="lim-ui-mobile-table-column-label"> {{ lang().TaskName }}: </span>

      <!-- The Task Type Icon -->
      <div class="task-type-icon">
         @if (task?.checklistTemplateOld === 1) {
            <lim-ui-icon icon="wrench" iconSize="small" />
         } @else if (
            task?.checklistTemplateOld === 2 &&
            (task?.checklistBatchID === 10000 || task?.checklistBatchID === 300112)
         ) {
            <lim-ui-icon icon="triangleExclamation" iconSize="small" />
         } @else if (task?.checklistTemplateOld === 2) {
            <lim-ui-icon icon="wpforms" iconSize="small" />
         } @else if (task?.checklistTemplateOld === 4) {
            <lim-ui-icon icon="file" iconSize="small" />
         } @else if (task?.checklistTemplate === 1) {
            <lim-ui-icon icon="wrench" iconSize="small" />
         }
      </div>
      <span class="no-float-desktop">
         <!-- This is the name -->
         <a (click)="onViewTask()">{{
            task.checklistName + " - # " + task.checklistID
         }}</a>

         <!-- The icons related to comments -->
         <span class="icon-container">
            @if (task.commentInfo?.showCommentsHint) {
               <lim-ui-icon
                  icon="commentRegular"
                  limUiTooltip="{{ lang().oneOrMoreComments }}"
                  [placement]="'right'"
                  (click)="onViewTask()"
               />
            }

            @if (task.checklistComments && task.checklistComments.length > 2) {
               <lim-ui-icon
                  icon="noteSticky"
                  limUiTooltip="{{ lang().completionNotes }}"
                  [placement]="'right'"
               />
            }

            @if (task.requestorInformation && task.requestorInformation.length > 0) {
               <lim-ui-icon
                  icon="phoneRegular"
                  limUiTooltip="{{ lang().RequestorInfo }}: {{
                     task.requestorInformation
                  }}"
                  [placement]="'right'"
               />
            }

            @if (task.approvalItemState !== undefined && task.approvalItemState > 0) {
               <lim-ui-icon
                  [iconColor]="task.approvalItemState === 2 ? 'danger' : 'inherit'"
                  [icon]="task.approvalItemState === 1 ? 'thumbsUp' : 'thumbsDown'"
                  limUiTooltip="{{ lang().ApprovedOrDisapprovedHintTooltip }}"
                  [placement]="'right'"
               />
            }
         </span>

         <!-- This is the asset and its parent assets-->
         @if (task.assets !== undefined && showAsset()) {
            <asset-hierarchy-list-item
               (click)="onAssetClick()"
               [asset]="task.assets[0]"
               linkColorClass="green-color"
               [isOnlyParentVisible]="true"
               [preventParentAccess]="false"
               [preventViewAsset]="facade.isMobile"
            />
         }
      </span>

      <!-- The search filter -->
      @if (searchHint && searchHint.length > 0) {
         <span class="search-hint desktop-search-hint">
            <span [limbleHtml]="searchHint"> </span>
         </span>
      }
   </div>
}
