import { KeyValuePipe } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   CheckboxComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   IconButtonComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import { finalize, take } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { DefaultHolidaysSelect } from "src/app/shared/components/global/defaultHolidaysSelectModal/defaultHolidaysSelect.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { SeasonEventType } from "src/app/shared/types/general.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import defaultHolidays from "src/root/holidays.json";

@Component({
   selector: "seasons-config",
   templateUrl: "./seasonsConfig.modal.component.html",
   styleUrls: ["./seasonsConfig.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      SecondaryButtonComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      FormsModule,
      FormDropdownInputComponent,
      DropdownTextItemComponent,
      IconComponent,
      TooltipDirective,
      DropdownComponent,
      CheckboxComponent,
      IconButtonComponent,
      BasicModalFooterComponent,
      KeyValuePipe,
   ],
})
export class SeasonsConfig implements OnInit {
   public title: string = "";
   public message: string = "";
   public seasonType;
   public dates: Map<
      number,
      { name: string; number: number; days: number; daysArr: Array<any> }
   > = new Map();
   public seasonsList: Map<number, any> = new Map();
   public readonly superUser: boolean;
   public applyAllTooltip;

   private editedSeasons: Array<any> = [];
   private readonly deletedSeasons: Array<any> = [];
   private newSeasons: Array<any> = [];
   private newDefaultSeasons: Array<any> = [];
   private resolve;
   private modalInstance;
   private hasChanges: boolean = false;
   private hasNameChanges: boolean = false;
   public successString: string = "";

   private readonly paramsService = inject(ParamsService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);
   private readonly launchFlagService = inject(LaunchFlagsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;
      this.title = data.title;
      this.seasonType = data.seasonType;
      const whatYouCanEdit: string =
         this.seasonType == "holiday" ? this.lang().Holiday : this.lang().Season;
      this.successString = `${this.lang().Add} ${whatYouCanEdit}`;
      this.getData();
   }

   private getData() {
      this.dates = this.manageSchedule.getDateInfo();

      // Get the relevant seasons
      this.manageSchedule.getSeasons(this.seasonType).then((answer) => {
         if (answer.data.success && answer.data.seasons?.length > 0) {
            const seasons = answer.data.seasons;

            // create a lookup
            this.seasonsList = seasons.reduce((lookup, season) => {
               lookup.set(season.seasonID, season);
               return lookup;
            }, new Map());

            this.seasonsList = this.sortSeasonsReverseKeyOrder();

            this.getDisplayStrs();
            this.getInitialApplyAllVals();
         }
      });

      // Determine the modal message
      this.getMessage();
   }

   private getDisplayStrs() {
      for (const season of this.seasonsList.values()) {
         if (!season.defaultHolidayID) {
            this.manageSchedule.getCustomSeasonDateStr(season, this.dates);
         }

         if (season.defaultHolidayID) {
            season.static = defaultHolidays[season.defaultHolidayID].static;
            // Grab the dates info for the holiday so we can figure out what should display for the date
            season.dates = defaultHolidays[season.defaultHolidayID].dates;
            this.manageSchedule.getDefaultHolidayDateStr(season, this.seasonsList);
         }

         this.manageSchedule.getCreatedStr(season);
         this.getApplyAllTooltip(season);
      }
   }

   private getMessage() {
      if (this.seasonType === "season") {
         this.message = this.lang().seasonsSettingsTooltip;
      }

      if (this.seasonType === "holiday") {
         this.message = this.lang().holidaysSettingsTooltip;
      }
   }

   private getApplyAllTooltip(holiday) {
      if (Number(holiday.applyToAllSchedules) === 1) {
         holiday.applyAllTooltip = this.lang().RemoveDefaultHolidayTooltip;
      } else {
         holiday.applyAllTooltip = this.lang().MakeDefaultHolidayTooltip;
      }
   }

   private getInitialApplyAllVals() {
      // We need to get these because if this value changes on a holiday we have to go recalculate ALL schedules for every PM
      // That's a big process and we only want to do that if necessary
      for (const season of this.seasonsList.values()) {
         season.applyToAllSchedules = Number(season.applyToAllSchedules);
         season.initialApplyAllVal = season.applyToAllSchedules;
      }
   }

   public addSeason() {
      this.hasChanges = true;
      let name;
      let endDefault;

      if (this.seasonType === "season") {
         name = "New Season";
         endDefault = 1;
      } else if (this.seasonType === "holiday") {
         name = "New Holiday";
         endDefault = 0;
      }

      const season = {
         seasonID: (this.newSeasons.length + 1) * -1,
         name: name,
         seasonType: this.seasonType,
         startMonth: 1,
         startDay: 1,
         endMonth: endDefault,
         endDay: endDefault,
         event: "skip",
         applyToAllSchedules: 0,
      };

      this.newSeasons.push(season);
      this.seasonsList.set(season.seasonID, season);
      this.getDisplayStrs();
   }

   public deleteSeason(seasonToDelete) {
      this.hasChanges = true;
      const seasonID = seasonToDelete.seasonID;

      // Temp seasonIDs will be negative, and since those haven't added to the db yet, we don't want to try to delete them
      if (seasonID > 0) {
         this.deletedSeasons.push(seasonToDelete);
      }

      this.seasonsList.delete(seasonID);

      // Make sure that if this was a new season that we don't keep it in the list of seasons to add if they delete it
      this.newSeasons = this.newSeasons.filter((season) => season.seasonID !== seasonID);
      this.newDefaultSeasons = this.newDefaultSeasons.filter(
         (season) => season.seasonID !== seasonID,
      );

      // Make sure that if they edited this season before deleting it that we don't keep it in the list of seasons to update
      this.editedSeasons = this.editedSeasons.filter(
         (season) => season.seasonID !== seasonID,
      );

      this.seasonsList.delete(seasonToDelete.seasonID);
   }

   public updateSeason(prop: string, value, season) {
      season[prop] = value;

      if (prop !== "name") {
         // We track name changes separately so that we don't have to recalculate schedules if they only change the season name
         this.hasChanges = true;
      }

      if (prop === "startMonth" || prop === "endMonth") {
         this.manageSchedule.getCustomSeasonDateStr(season, this.dates);
      }

      // Only add existing seasons to the editedSeasons array. New seasons have a negative ID and are added separately
      if (season.seasonID && season.seasonID > 0) {
         // Check to see if this season is already in the list
         const indexToUpdate = this.editedSeasons.findIndex(
            (seasonToUpdate) => seasonToUpdate.seasonID === season.seasonID,
         );

         if (indexToUpdate > -1) {
            // Replace the season with the most up to date info for that season
            this.editedSeasons[indexToUpdate] = season;
         } else {
            // Add the season to the array
            this.editedSeasons.push(season);
         }
      }
   }

   public updateSeasonName(value: string, season) {
      this.hasNameChanges = true;

      this.updateSeason("name", value, season);
   }

   public updateSeasonEvent(eventType: SeasonEventType, season) {
      season.event = eventType;
      this.updateSeason("event", eventType, season);

      this.manageSchedule.getCreatedStr(season);
   }

   public toggleApplyAll(holiday) {
      this.updateSeason("applyToAllSchedules", holiday.applyToAllSchedules, holiday);
      this.getApplyAllTooltip(holiday);
   }

   public addHolidayEndDates(seasonID: number) {
      const season = this.seasonsList.get(seasonID);
      this.hasChanges = true;
      season.endMonth = 1;
      season.endDay = 1;
      this.manageSchedule.getCustomSeasonDateStr(season, this.dates);
      this.updateSeason("endMonth", season.endMonth, season);
      this.updateSeason("endDay", season.endDay, season);
   }

   public close() {
      if (this.hasChanges || this.hasNameChanges) {
         const instance = this.modalService.open(Confirm);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().CancelSeasonsModalMsg,
               title: this.lang().ExitWithoutSaving,
            },
         };

         instance.result.then((result) => {
            if (result === 1) {
               this.modalInstance.close();
            }
         });
      } else {
         this.modalInstance.close(0);
      }
   }

   private setError(answer: any): void {
      let errorMsg: string = this.lang().errorMsg;
      if (answer.data.errorCode === "Must be a super user to update seasons") {
         errorMsg = this.lang().YouMustBeASuperUser;
      }

      if (answer.data.errorCode === "Feature not enabled") {
         errorMsg = this.lang().YouDoNotHaveFeatureFlag;
      }
      this.alertService.addAlert(errorMsg, "danger", 6000);
   }

   public saveSeasons() {
      const promiseArr: Array<any> = [];
      const seasonsToCheck: Array<any> = [];
      let deletedRecurrencesToRecalculate: Array<any> = [];
      let newSeasonsApplyAll = false;
      let hasError = false;

      // We don't want to run everything and recalculate schedules if they're just changing season names
      if (this.hasNameChanges && !this.hasChanges) {
         this.manageSchedule.updateSeasons(this.editedSeasons).then((answer) => {
            if (!answer.data.success) {
               this.setError(answer);
            }
            this.hasNameChanges = false;
            this.modalInstance.close(1);

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         });
      } else if (this.hasChanges) {
         const instance = this.modalService.open(Confirm);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().UpdateAllPMSchedulesMsg,
               title: this.lang().SaveChanges,
            },
         };

         instance.result.then((result) => {
            if (result === 1) {
               this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
               // First add any new seasons
               if (this.launchFlagService.getFlag(Flags.SEASONS_CONFIG_MODAL, false)()) {
                  if (this.newSeasons.length > 0) {
                     this.manageSchedule
                        .createSeasons(this.newSeasons)
                        .pipe(
                           take(1),
                           finalize(() => {
                              this.loadingBarService.remove();
                           }),
                        )
                        .subscribe({
                           next: (template) => {
                              this.newSeasons.push(template);
                              this.seasonsList.set(template.seasonID, template);
                              this.getDisplayStrs();
                           },
                           error: (err) => {
                              this.setError(err);
                           },
                        });
                  }
               } else {
                  const addSeasonPromise = this.manageSchedule
                     .addSeasons(this.newSeasons)
                     .then((answer) => {
                        if (!answer.data.success) {
                           this.setError(answer);
                           hasError = true;
                           return;
                        }
                        for (const newSeason of answer.data.newSeasons) {
                           if (Number(newSeason.applyToAllSchedules) === 1) {
                              seasonsToCheck.push(newSeason);
                              newSeasonsApplyAll = true;
                           }
                        }
                     });
                  promiseArr.push(addSeasonPromise);
               }

               // Add any default seasons
               if (this.newDefaultSeasons.length > 0) {
                  const addDefaultSeasonPromise = this.manageSchedule
                     .addDefaultHolidays(this.newDefaultSeasons)
                     .then((answer) => {
                        if (!answer.data.success) {
                           this.setError(answer);
                           hasError = true;
                           return;
                        }

                        for (const newSeason of answer.data.newSeasons) {
                           if (Number(newSeason.applyToAllSchedules) === 1) {
                              seasonsToCheck.push(newSeason);
                              newSeasonsApplyAll = true;
                           }
                        }
                     });
                  promiseArr.push(addDefaultSeasonPromise);
               }

               // Delete any seasons that have been deleted
               if (this.deletedSeasons.length > 0) {
                  const deleteSeasonPromise = this.manageSchedule
                     .deleteSeasons(this.deletedSeasons)
                     .then((answer) => {
                        if (!answer.data.success) {
                           this.setError(answer);
                           hasError = true;
                           return;
                        }

                        deletedRecurrencesToRecalculate = answer.data.recurrences;

                        // We need to add any seasons that were deleted to an array so we can update all associated schedules later
                        for (const season of this.deletedSeasons) {
                           seasonsToCheck.push(season);
                        }
                     });
                  promiseArr.push(deleteSeasonPromise);
               }

               // Update any seaons that have been edited
               if (this.editedSeasons.length > 0) {
                  const editedSeasonPromise = this.manageSchedule
                     .updateSeasons(this.editedSeasons)
                     .then((answer) => {
                        if (!answer.data.success) {
                           this.setError(answer);
                           hasError = true;
                        }

                        // We need to add any seasons that were edited to an array so we can update all associated schedules later
                        for (const season of this.editedSeasons) {
                           seasonsToCheck.push(season);
                        }
                     });
                  promiseArr.push(editedSeasonPromise);
               }

               Promise.all(promiseArr).then(() => {
                  if (hasError) {
                     this.loadingBarService.remove();
                  } else {
                     if (seasonsToCheck.length > 0) {
                        // We changed current seasons so we need to update recurrences that are associated with them
                        this.updateSchedulesForEditedSeasons(
                           seasonsToCheck,
                           newSeasonsApplyAll,
                           deletedRecurrencesToRecalculate,
                        );
                     } else {
                        // We just added new seasons, so just refresh the season data
                        this.loadingBarService.remove();
                        this.modalInstance.close(1);
                     }
                     this.hasChanges = false;
                     this.hasNameChanges = false;
                  }
               });
            }
         });
      } else {
         this.close();
      }
   }

   private async updateSchedulesForEditedSeasons(
      seasons,
      newSeasonsApplyAll,
      deletedRecurrencesToRecalculate,
   ) {
      if (seasons.length === 0) {
         return;
      }
      const promiseArr: Array<any> = [];
      const seasonIDs: Array<any> = [];
      let allRecurrences: Array<any> = [];
      let recalculateAllSchedules = false;

      const shouldFetchAllTimeBasedRecurrences = seasons.find(
         (season) =>
            Number(season.initialApplyAllVal) !== Number(season.applyToAllSchedules),
      );

      if (shouldFetchAllTimeBasedRecurrences) {
         const allSchedulesAnswer =
            await this.manageSchedule.getAllTimeBasedRecurrences();

         if (!allSchedulesAnswer.data.success) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }

         allRecurrences = allSchedulesAnswer.data.recurrences;
      }

      for (const season of seasons) {
         // Only run this if the applyToAllSchedules prop has changed
         if (
            Number(season.initialApplyAllVal) === Number(season.applyToAllSchedules) &&
            !newSeasonsApplyAll
         ) {
            // If they're not applying anything to all schedules, just get a list of seasonIDs that are changing so we can update any recurrences associated with those
            seasonIDs.push(season.seasonID);
         } else {
            // Otherwise, we're going to have to recalculate the schedules for ALL recurrences
            recalculateAllSchedules = true;
            // We make all the season to recurrence associations here
            const seasonAssociationPromise = this.manageSchedule
               .batchToggleAssociateSeasonToAllSchedules(season, allRecurrences)
               .then((response) => {
                  if (!response.success) {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
            promiseArr.push(seasonAssociationPromise);
         }
      }

      Promise.all(promiseArr).then(() => {
         if (recalculateAllSchedules) {
            // Update it all!
            this.recalculateSchedules(allRecurrences);
         } else {
            // Get all the recurrences associated with just these changed seasons and update those
            this.manageSchedule
               .getSeasonAssociatedRecurrences(seasonIDs)
               .then((answer) => {
                  if (!answer.data.success) {
                     this.loadingBarService.remove();
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     return;
                  }

                  const recurrences = answer.data.recurrences;
                  // Add any recurrences for seasons that were deleted (these are passed in since the relations were already deleted)
                  const combinedRecurrences = recurrences.concat(
                     deletedRecurrencesToRecalculate,
                  );
                  // Make sure we don't have duplicates
                  const finalRecurrences = [...new Set(combinedRecurrences)];
                  this.recalculateSchedules(finalRecurrences);
               });
         }
      });
   }

   private async recalculateSchedules(recurrences) {
      const recalculateResult =
         await this.manageSchedule.batchRecalculateSchedules(recurrences);
      if (!recalculateResult.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         this.loadingBarService.remove();
         return;
      }

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      this.loadingBarService.remove();
      this.modalInstance.close(1);
   }

   public openDefaultSeasonsModal() {
      const alreadyChosenDefaults: Array<any> = [];
      for (const season of this.seasonsList.values()) {
         if (season.defaultHolidayID) {
            // Also make sure it's not in the list of holidays that are marked to be deleted
            const seasonToCheckFor = this.deletedSeasons.find(
               (holiday) => holiday.defaultHolidayID === season.defaultHolidayID,
            );
            if (!seasonToCheckFor) {
               alreadyChosenDefaults.push(season);
            }
         }
      }

      const instance = this.modalService.open(DefaultHolidaysSelect);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               alreadyChosenDefaults: alreadyChosenDefaults,
            },
         },
      };

      instance.result.then((newDefaultHolidays) => {
         if (newDefaultHolidays === 0 || newDefaultHolidays.length === 0) {
            return;
         }

         this.hasChanges = true;
         this.newDefaultSeasons = this.newDefaultSeasons.concat(newDefaultHolidays);

         for (const holiday of newDefaultHolidays) {
            holiday.seasonID = holiday.defaultHolidayID;
            this.seasonsList.set(holiday.seasonID, holiday);
            this.getDisplayStrs();
         }
      });
   }

   private sortSeasonsReverseKeyOrder() {
      return new Map(
         [...this.seasonsList].sort((item1, item2) =>
            String(item2[1]).localeCompare(item1[1], undefined, {
               numeric: true,
            }),
         ),
      );
   }

   // In order to turn off the default keyvalue pipe sorting of a Map, the property has to be set to a function that returns 0
   // Just using 0 instead of a function gives a ts TypeError.
   public returnZero() {
      return 0;
   }
}
