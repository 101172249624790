import {
   pmSuggestionScheduleFactory,
   type PmSuggestionSchedule,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion-schedule/pm-suggestion-schedule";
import {
   pmSuggestionDtoSchema,
   type PmSuggestionDto,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestion-dto";

export class PmSuggestion {
   private readonly id: number;
   private readonly name: string;
   private readonly schedule: PmSuggestionSchedule;
   private readonly instructions: Array<string>;
   private readonly customerId: number;
   private readonly assetId: number;
   private readonly fileHash: string;

   public constructor(options: {
      id: number;
      name: string;
      schedule: PmSuggestionSchedule;
      instructions: Array<string>;
      customerId: number;
      assetId: number;
      fileHash: string;
   }) {
      this.name = options.name;
      this.schedule = options.schedule;
      this.instructions = options.instructions;
      this.customerId = options.customerId;
      this.assetId = options.assetId;
      this.fileHash = options.fileHash;
      this.id = options.id;
   }

   public static fromDto(dto: PmSuggestionDto): PmSuggestion {
      const parsedDto = pmSuggestionDtoSchema.parse(dto);
      return new PmSuggestion({
         name: parsedDto.name,
         schedule: pmSuggestionScheduleFactory(parsedDto.schedule),
         instructions: parsedDto.instructions,
         customerId: parsedDto.customer_id,
         assetId: parsedDto.asset_id,
         fileHash: parsedDto.document_id,
         id: parsedDto.predicted_maintenance_id,
      });
   }

   public toDto(): PmSuggestionDto {
      return {
         name: this.name,
         schedule: this.schedule.toDto(),
         instructions: this.instructions,
         customer_id: this.customerId,
         asset_id: this.assetId,
         document_id: this.fileHash,
         predicted_maintenance_id: this.id,
      };
   }

   public getId(): number {
      return this.id;
   }

   public getName(): string {
      return this.name;
   }

   public getAssetID(): number {
      return this.assetId;
   }

   public getScheduleString(): string {
      return this.schedule.getScheduleString();
   }

   public getSchedule(): PmSuggestionSchedule {
      return this.schedule;
   }

   public getFileHash(): string {
      return this.fileHash;
   }
   public getInstructions(): Array<string> {
      return this.instructions;
   }
}
