import { Injectable } from "@angular/core";
import { firstValueFrom, type Observable } from "rxjs";
import {
   FlannelApiService,
   type RequestFilter,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type {
   Anomaly,
   AnomalyCheckResponse,
} from "src/app/tasks/components/shared/services/anomaly-api/anomaly-api.models";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class AnomalyApiService extends FlannelApiService<never, RequestFilter> {
   private constructor() {
      super("anomalies");
   }

   /**
    * Checks if a given value would be considered anomalous based on historical data
    */
   public checkForAnomaly(params: {
      assetID: number;
      fieldID: number;
      nextValue: number;
      maxNumHistoricVals?: number;
   }): Observable<AnomalyCheckResponse> {
      const requestOptions: Partial<RequestOptions> = {
         url: `${environment.servicesURL()}/tasks/anomaly-detection`,
         params: {
            assetID: params.assetID,
            fieldID: params.fieldID,
            nextValue: params.nextValue,
            ...(params.maxNumHistoricVals !== undefined && {
               maxNumHistoricVals: params.maxNumHistoricVals,
            }),
         },
      };
      return this.request("GET", requestOptions, undefined, undefined, true);
   }

   /**
    * Acknowledges an anomaly by its ID and associates it with a task item
    */
   public async confirmAnomaly(anomalyID: number, taskItemID: number): Promise<boolean> {
      try {
         const requestOptions: Partial<RequestOptions> = {
            url: `${environment.servicesURL()}/tasks/anomalies/${anomalyID}/acknowledge`,
         };
         await firstValueFrom(
            this.request("POST", requestOptions, { taskItemID }, undefined, true),
         );
         return true;
      } catch {
         return false;
      }
   }

   public async getAnomaly(anomalyID: number): Promise<Anomaly> {
      const requestOptions: Partial<RequestOptions> = {
         url: `${environment.servicesURL()}/tasks/anomaly-detection/anomaly/${anomalyID}`,
      };
      return firstValueFrom(
         this.request("GET", requestOptions, undefined, undefined, true),
      );
   }
}
