import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, ViewChild, computed } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   InfoPanelComponent,
   ModalService,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   RadioButtonComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { debounceTime, filter } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { HierarchyContainerComponent } from "src/app/shared/components/global/hierarchy/hierarchy-container-component/hierarchy-container.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AutoFocusDirective } from "src/app/shared/directives/autofocus/autoFocus.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import {
   WORK_REQUEST_TEMPLATE_BATCH_ID,
   type HierarchyNode,
   type HierarchyOptions,
} from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { TaskTemplateType } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { TemplateHierarchyNode } from "src/app/tasks/components/shared/services/task-templates-hierarchy/template-hierarchy.service";
import { TemplateHierarchyService } from "src/app/tasks/components/shared/services/task-templates-hierarchy/template-hierarchy.service";
import type { Task } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "pick-new-template",
   templateUrl: "./pick-new-template.component.html",
   styleUrls: ["./pick-new-template.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      FormsModule,
      AutoFocusDirective,
      PanelComponent,
      RadioButtonComponent,
      SearchBoxComponent,
      NoSearchResults,
      HierarchyContainerComponent,
      BasicModalFooterComponent,
      ReactiveFormsModule,
      LoadingAnimationComponent,
   ],
})
export class PickNewTemplateComponent implements OnInit, OnDestroy {
   @ViewChild("hierarchy") hierarchyContainer?: HierarchyContainerComponent;
   public data;
   public message;
   public title;
   public selectedUser;
   public errorMsg;
   public name: string = "";
   public selection;
   public selectedTemplateNode: TemplateHierarchyNode | undefined;
   public dontChangeName: boolean = false;
   public type: "PM" | "WO" = "PM";
   public taskTemplateTypes: Array<TaskTemplateType> = [1];
   public faIcon;
   public resolve;
   public modalInstance;

   public noSearchResults;
   protected isLoading: boolean = false;

   public hierarchyOptions: HierarchyOptions;

   public templateNodes: LimbleMap<number, TemplateHierarchyNode> = new LimbleMap();

   public locationsIndex: Record<number, any> = {};
   public locations: Array<HierarchyNode> = [];
   public treeData: Array<HierarchyNode> = [];

   protected search: string = "";
   protected searchControl = new FormControl<string>("");
   private readonly searchControlSub: Subscription = this.searchControl.valueChanges
      .pipe(
         debounceTime(500),
         filter((searchValue) => {
            if (
               searchValue !== null &&
               searchValue.length > 0 &&
               searchValue.length < 3
            ) {
               return false;
            }
            return true;
         }),
      )
      .subscribe((search) => {
         this.fetchSearchedTasks(search);
      });

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly credService = inject(CredService);
   private readonly templateHierarchyService = inject(TemplateHierarchyService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly modalCloseEventsSub = this.modalService.closeEvents
      .pipe(filter(() => this.modalService.getActiveModal() === this.modalInstance))
      .subscribe(() => {
         this.rebuildTreeData();
      });

   public constructor() {
      this.hierarchyOptions = {
         idKey: "checklistID",
         selection: { singleSelection: true },
         nodeButtons: [
            {
               tooltip: this.lang().OpenThisTask,
               clickFunction: this.popTask.bind(this),
               text: this.lang().View,
            },
         ],
         onSelect: this.onSelection.bind(this),
         submit: this.submit.bind(this),
      };
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.message;
      this.data = this.resolve.data;
      this.type = this.data.type;

      this.selectedUser = false;
      this.errorMsg = false;

      this.selection = 1;

      this.fetchInitialTaskData();
   }

   public ngOnDestroy() {
      this.searchControlSub.unsubscribe();
      this.modalCloseEventsSub.unsubscribe();
   }

   protected setDontChangeName() {
      if (this.name === "") {
         return;
      }
      this.dontChangeName = true;
   }

   close = () => {
      this.modalInstance.close(false);
   };

   private async rebuildTreeData() {
      const { locationsIndex, treeData, noSearchResults, templateNodes } =
         await this.templateHierarchyService.rebuildTreeData(
            this.treeData,
            this.templateNodes,
            {
               search: this.search,
               checklistTemplates: this.taskTemplateTypes,
               excludeDefaultTemplates: true,
               notBatchIDs: [WORK_REQUEST_TEMPLATE_BATCH_ID],
            },
         );

      this.locationsIndex = locationsIndex;
      this.treeData = treeData;
      this.templateNodes = templateNodes;
      this.noSearchResults = noSearchResults;

      if (this.hierarchyContainer) {
         this.hierarchyContainer.renderInitialHierarchyItems();
      }
   }

   buildInitialHierarchyData = () => {
      if (this.type === "PM") {
         this.taskTemplateTypes = [TaskTemplateType.pmTemplate];
         this.faIcon = "wrench";
      } else if (this.type === "WO") {
         this.taskTemplateTypes = [
            TaskTemplateType.woTemplate,
            TaskTemplateType.unPlannedWoTemplate,
         ];
         this.faIcon = "wpforms";
      }
      this.locationsIndex = {};
      const { locationsIndex, filteredTreeData } =
         this.templateHierarchyService.getLocationNodesWithoutRegions();

      this.locations = filteredTreeData;
      this.locationsIndex = locationsIndex;
   };

   private async fetchInitialTaskData() {
      this.isLoading = true;
      this.buildInitialHierarchyData();

      const response = await this.templateHierarchyService
         .fetchInitialData(this.locations, {
            checklistTemplates: this.taskTemplateTypes,
            excludeDefaultTemplates: true,
            notBatchIDs: [WORK_REQUEST_TEMPLATE_BATCH_ID],
         })
         .finally(() => {
            this.isLoading = false;
         });
      if (response === undefined) {
         console.error("Error fetching initial data in pick-new-template");
         return;
      }
      this.treeData = response;
   }

   protected async fetchSearchedTasks(search: string | null) {
      this.isLoading = true;
      this.buildInitialHierarchyData();
      this.noSearchResults = false;

      const response = await this.templateHierarchyService
         .fetchSearchedData(this.locations, {
            search: this.search,
            checklistTemplates: this.taskTemplateTypes,
            excludeDefaultTemplates: true,
            notBatchIDs: [WORK_REQUEST_TEMPLATE_BATCH_ID],
         })
         .finally(() => {
            this.isLoading = false;
         });
      if (response === undefined) {
         console.error("Error fetching searched data in pick-new-template");
         return;
      }
      const { filteredTreeData, groupedResponses, noSearchResults, templateNodes } =
         response;
      this.treeData = filteredTreeData;
      this.templateNodes = templateNodes;

      this.noSearchResults = noSearchResults;

      if (search === null || search === "") {
         return;
      }

      this.templateHierarchyService.processAndMapGroupedResponse(
         groupedResponses,
         this.locationsIndex,
         this.templateNodes,
         this.search,
      );
   }

   //This needs to be an arrow function to preserve its execution context because it is called from a child element
   //it WILL NOT WORK with .bind() for reasons unknown.
   fetchMoreTasksAtLocation = async (locationID: number) => {
      const location = this.locationsIndex[locationID];

      await this.templateHierarchyService.fetchMoreTasksAtLocation(
         location,
         this.templateNodes,
         {
            checklistTemplates: this.taskTemplateTypes,
            search: this.search,
            excludeDefaultTemplates: true,
            notBatchIDs: [WORK_REQUEST_TEMPLATE_BATCH_ID],
         },
      );

      if (this.hierarchyContainer) {
         this.hierarchyContainer.renderInitialHierarchyItems();
      }
   };

   popTask = (task: Task): void => {
      let preview = true;
      const superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
      if (superUser) {
         //if they are a super user let them edit any Template anywhere.
         preview = false;
      }
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               preview: preview,
               template: true,
            },
         },
      };
   };

   submit = () => {
      this.errorMsg = false;

      if (this.selection == 0) {
         this.errorMsg = this.lang().PleaseSelectAValidOption;
         if (this.selection == 2) {
            this.alertService.addAlert(this.errorMsg, "warning", 3000);
         }

         return;
      }

      if (this.name.length < 1) {
         this.errorMsg = this.lang().PleaseEnterAValidName;
         this.alertService.addAlert(this.errorMsg, "warning", 3000);

         return;
      }

      if (this.selection == 2) {
         if (this.selectedTemplateNode == undefined) {
            this.errorMsg = this.lang().PleaseSelectAPMToCopy;
            this.alertService.addAlert(this.errorMsg, "warning", 3000);

            return;
         }
      }

      //if it made it past all the returns we can successfully close
      const returnData: any = {};
      returnData.task = this.selectedTemplateNode;
      returnData.newName = this.name;
      this.modalInstance.close(returnData);
   };

   public setName(template: TemplateHierarchyNode) {
      if (this.dontChangeName) {
         return;
      }
      this.name = `${template.name} - ${this.lang().Copy}`;
   }

   public updateSelectedTask(template: TemplateHierarchyNode) {
      if (!template) {
         this.selectedTemplateNode = undefined;
         return;
      }
      this.selectedTemplateNode = template;
   }

   public onSelection() {
      const selectedNode = this.templateNodes.find((node) => node.selected === true);
      if (!selectedNode) {
         return;
      }
      this.setName(selectedNode);
      this.updateSelectedTask(selectedNode);
   }
}
