@if (part()) {
   <lim-ui-panel class="lim-ui-fonts-body" id="partReportsPrint{{ part()?.partID }}">
      <header class="report-metrics-header">
         <div>
            <data-viewer-filters
               [filters]="filters"
               (set)="onSetFilter($event)"
               (remove)="onRemoveFilter($event)"
            />
         </div>
      </header>

      <!-- Part Stats Section -->
      <section class="metrics">
         <lim-ui-card>
            <h3>
               {{ part()?.totalPartsUsed }}
            </h3>
            <p limUiTooltip="{{ lang().TotalPartsToolTip }}">
               {{ lang().TotalPartsUsed }}
            </p>
         </lim-ui-card>
         <lim-ui-card>
            <h3>{{ currencySymbol() }}{{ part()?.totalCostOfPartsUsed }}</h3>
            <p limUiTooltip="{{ lang().TotalCostToolTip }}">
               {{ lang().TotalCost }}
            </p>
         </lim-ui-card>
         <lim-ui-card>
            <h3>
               {{ staleStatus() }}
            </h3>
            <p limUiTooltip="{{ lang().StaleThresholdTooltip }}">
               {{ lang().StaleStatus }}
            </p>
         </lim-ui-card>
         <lim-ui-card>
            <h3>
               {{ part()?.daysSincePartUsed ?? lang().NeverUsed }}
            </h3>
            <p limUiTooltip="{{ lang().DaysSinceLastUsedToolTip }}">
               {{ lang().DaysSinceLastUsed }}
            </p>
         </lim-ui-card>
      </section>
      @if (part(); as part) {
         <section class="data-visualizations">
            <part-reports-forecast-table [part]="part" />
            <part-reports-usage-table [part]="part" />
         </section>
      }
   </lim-ui-panel>
}
