import { Component, input, output } from "@angular/core";
import { LoadingAnimationComponent } from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { TableComponent } from "src/app/shared/empowered/base/table/table.component";
import type {
   SortEvent,
   TableColumn,
} from "src/app/shared/empowered/base/table/table.models";
import { DataViewerPaginatorComponent } from "src/app/shared/empowered/gears/data-viewer-paginator/data-viewer-paginator.component";

@Component({
   selector: "e-data-viewer",
   templateUrl: "./data-viewer.component.html",
   styleUrls: ["./data-viewer.component.scss"],
   imports: [
      TableComponent,
      LoadingAnimationComponent,
      NoSearchResults,
      TranslateDirective,
      DataViewerPaginatorComponent,
   ],
})
export class DataViewerComponent {
   /**   The columns to display in the table  */
   public columns = input.required<Array<TableColumn>>();

   /** The data to display in the table */
   public data = input.required<Array<any>>();

   /** The total number of items to display */
   public totalItems = input.required<number>();

   /**  The current page   */
   public page = input.required<number>();

   /** Whether the table is loading */
   public status = input.required<"pending" | "success" | "error">();

   /** The current sort */
   public sort = input<SortEvent>();

   /** Emits the sort change event */
   readonly sortChange = output<SortEvent>();

   /** Emits the page change event */
   readonly changePage = output<number>();

   /** Emits the page size change event */
   readonly changePageSize = output<number>();

   /** Handle the sort event  */
   protected onSort(event: SortEvent | undefined) {
      if (event) {
         this.sortChange.emit(event);
      }
   }

   /** Handle the page change event */
   protected onPageChange(event: number) {
      this.changePage.emit(event);
   }

   /** Handle the page size change event   */
   protected onPageSizeChange(event: number) {
      this.changePageSize.emit(event);
   }
}
