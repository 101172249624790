<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      [title]="title"
      (closeModal)="close()"
      id="pickTasksHeader"
   />
   <lim-ui-modal-body id="pickTasksHolder">
      <!-- name template section -->
      <lim-ui-info-panel>
         <div class="input-group">
            @if (selection) {
               <div>
                  <label class="modal-label">{{ lang().NewTemplatesName }}</label>
                  <input
                     [(ngModel)]="name"
                     (blur)="setDontChangeName()"
                     focusAuto
                     type="text"
                  />
               </div>
            }
         </div>
      </lim-ui-info-panel>

      <lim-ui-panel>
         <!-- radio button section -->
         <div class="horizotal-panel-content">
            <ng-container radio-button-list groupID="addATemplateGroup">
               <lim-ui-radio-button
                  groupID="addATemplateGroup"
                  [label]="lang().BlankTemplate"
                  [checked]="selection == 1"
                  [tooltip]="lang().BlankTemplateTooltip"
                  placement="bottom"
                  (click)="selection = 1"
               />
               <lim-ui-radio-button
                  groupID="addATemplateGroup"
                  [label]="lang().CopyFromAnExistingTemplate"
                  [tooltip]="lang().CopyFromAnExistingTemplateTooltip"
                  placement="bottom"
                  [checked]="selection == 2"
                  (click)="selection = 2"
               />
            </ng-container>
         </div>

         <!-- select template section -->
         @if (selection == 2) {
            <div class="message horizontal-search-content">
               <span>
                  {{
                     type === "PM"
                        ? lang().PickAPMTemplateToCopy
                        : lang().PickAWOTemplateToCopy
                  }}
               </span>
               <lim-ui-search-box
                  [(searchVal)]="search"
                  [formControl]="searchControl"
                  [placeholder]="lang().Search"
               />
            </div>

            <div class="search-results scroll-height-inheritance">
               @if (noSearchResults) {
                  <div>
                     <no-search-results />
                  </div>
               }
               <div class="loading-icon">
                  @if (isLoading) {
                     <lim-ui-loading-animation />
                  }
               </div>
               @if (treeData?.length && !noSearchResults && hierarchyOptions) {
                  <hierarchy-container
                     #hierarchy
                     [treeData]="treeData"
                     [options]="hierarchyOptions"
                     [fetchMore]="fetchMoreTasksAtLocation"
                     [hidden]="isLoading"
                  />
               }
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Create"
      (successClick)="submit()"
   />
</lim-ui-modal>
