import { computed, inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   patchState,
   signalStore,
   withComputed,
   withMethods,
   withState,
} from "@ngrx/signals";
import { SettingsAliases } from "src/app/settings/services/limble-config-api/limble-config-api.service";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { AnomalyApiService } from "src/app/tasks/components/shared/services/anomaly-api";
import { AnomalyDetectionService } from "src/app/tasks/services/anomaly-detection/anomaly-detection.service";
import type { AnomalyState } from "src/app/tasks/types/anomaly/anomaly.types";
import { AnomalyStateEnum } from "src/app/tasks/types/anomaly/anomaly.types";
import { ManageUser } from "src/app/users/services/manageUser";

interface AnomalyStoreState {
   anomalyID: number | null;
   anomalyState: AnomalyState;
   responseValueOutsideOfExpectedRange: boolean;
   showCorrectedCard: boolean;
   currentlyEnteredValue: number | undefined;
}

const initialState: AnomalyStoreState = {
   anomalyID: null,
   anomalyState: undefined,
   responseValueOutsideOfExpectedRange: false,
   showCorrectedCard: false,
   currentlyEnteredValue: undefined,
};

// Create a shared signal to track the config setting
const anomalyConfigSettingEnabled = signal(false);

// Function to initialize the config setting
function initConfigSetting(manageUser: ManageUser, launchFlags: LaunchFlagsService) {
   const anomalyDetectionFlag = launchFlags.getFlag("feature-anomaly-detection", false);
   if (!anomalyDetectionFlag()) {
      anomalyConfigSettingEnabled.set(false);
      return;
   }

   // Use the ManageUser service to get the setting value
   const enabled = manageUser.getLimbleSetting(SettingsAliases.EnableUnexpectedValue);
   anomalyConfigSettingEnabled.set(enabled);
}

// Create a helper function to check if anomaly detection is enabled
function checkAnomalyDetectionEnabled(launchFlags: LaunchFlagsService) {
   const anomalyDetectionFlag = launchFlags.getFlag("feature-anomaly-detection", false);
   return anomalyDetectionFlag() && anomalyConfigSettingEnabled();
}

export const AnomalyStateStore = signalStore(
   withState(initialState),

   withMethods(
      (
         store,
         anomalyDetectionService = inject(AnomalyDetectionService),
         anomalyApiService = inject(AnomalyApiService),
         launchFlags = inject(LaunchFlagsService),
         manageUser = inject(ManageUser),
      ) => {
         // Initialize the config setting when the store is created
         initConfigSetting(manageUser, launchFlags);

         // Subscribe to user changes to update the setting when user data changes
         manageUser.currentUserChanges$.pipe(takeUntilDestroyed()).subscribe(() => {
            initConfigSetting(manageUser, launchFlags);
         });

         return {
            async setAnomalyID(id: number | null, isInitialLoad = false) {
               patchState(store, { anomalyID: id });
               if (id !== null) {
                  await this.calcAndSetAnomalyState(isInitialLoad);
               }
            },

            setCurrentlyEnteredValue(value: number | undefined) {
               patchState(store, { currentlyEnteredValue: value });
            },

            async calcAndSetAnomalyState(isInitialLoad = false) {
               const previousState = store.anomalyState();
               const newState = await anomalyDetectionService.updateAnomalyState(
                  store.anomalyID(),
               );

               if (isInitialLoad) {
                  patchState(store, {
                     anomalyState: newState,
                     showCorrectedCard: false,
                  });
               } else {
                  if (
                     newState === AnomalyStateEnum.Corrected &&
                     previousState !== AnomalyStateEnum.Corrected
                  ) {
                     this.showCorrectedCardTemporarily();
                  }
                  patchState(store, { anomalyState: newState });
               }
            },
            async checkForAnomaly(assetID: number, fieldID: number, nextValue: number) {
               if (!checkAnomalyDetectionEnabled(launchFlags)) {
                  patchState(store, {
                     responseValueOutsideOfExpectedRange: false,
                  });
                  return;
               }

               const anomalyCheckResponse = await anomalyDetectionService.isAnomaly(
                  assetID,
                  fieldID,
                  nextValue,
               );
               patchState(store, {
                  responseValueOutsideOfExpectedRange: anomalyCheckResponse.isAnomaly,
                  currentlyEnteredValue: nextValue,
               });
            },

            resetAnomalyState() {
               // Don't reset if confirmed to maintain acknowledgment state
               if (store.anomalyState() !== AnomalyStateEnum.Confirmed) {
                  patchState(store, {
                     anomalyState: undefined,
                     responseValueOutsideOfExpectedRange: false,
                     currentlyEnteredValue: undefined,
                  });
               }
            },

            async confirmAnomaly(anomalyID: number, checklistItemId: number) {
               const confirmed = await anomalyApiService.confirmAnomaly(
                  anomalyID,
                  checklistItemId,
               );
               if (confirmed) {
                  patchState(store, { anomalyState: AnomalyStateEnum.Confirmed });
               }
            },

            showCorrectedCardTemporarily() {
               patchState(store, { showCorrectedCard: true });
               setTimeout(() => {
                  patchState(store, { showCorrectedCard: false });
               }, 3000); // Show for 3 seconds
            },
         };
      },
   ),

   withComputed((store, launchFlags = inject(LaunchFlagsService)) => {
      // Create a computed property that uses the same helper function
      const isAnomalyDetectionEnabled = computed(() => {
         return checkAnomalyDetectionEnabled(launchFlags);
      });

      return {
         isAnomalyDetectionEnabled,

         showDetectedAnomalousValueCard: computed(() => {
            if (!isAnomalyDetectionEnabled()) return false;
            return (
               store.anomalyState() === AnomalyStateEnum.Detected ||
               (store.anomalyState() === undefined &&
                  store.responseValueOutsideOfExpectedRange())
            );
         }),

         showCorrectedAnomalousValueCard: computed(() => {
            if (!isAnomalyDetectionEnabled()) return false;
            return (
               store.anomalyState() === AnomalyStateEnum.Corrected &&
               store.showCorrectedCard()
            );
         }),

         showConfirmedAnomalousValueCard: computed(() => {
            if (!isAnomalyDetectionEnabled()) return false;
            return store.anomalyState() === AnomalyStateEnum.Confirmed;
         }),
      };
   }),
);
