<lim-ui-card [clickableStyling]="true">
   <h3 class="dashboard-data">
      <span class="success" (click)="showCompletedPreventiveWorkOrders.emit()">
         <span
            [count-up]="plannedWOsPercent()"
            id="PMs"
            data-num-decimals="0"
            data-animation-length="1.5"
         >
         </span>
         <span>%</span>
      </span>
      {{ lang().vsStr }}
      <span class="danger" (click)="showCompletedWorkOrders.emit()">
         <span
            [count-up]="unplannedWOsPercent()"
            id="WOs"
            data-num-decimals="0"
            data-animation-length="1.5"
         >
         </span>
         <span>%</span>
      </span>
   </h3>
   <p class="dashboard-title">
      {{ lang().PlannedVsUnplanned }}
   </p>
</lim-ui-card>
