import type { WritableSignal } from "@angular/core";
import { BehaviorSubject, type Observable, Subject } from "rxjs";

export enum StepStatus {
   INCOMPLETE = "incomplete",
   INPROGRESS = "inprogress",
   COMPLETE = "complete",
}

export type Step = {
   stepNumber: number; // 0 based step number
   label: string;
   headerText: string;
   nextButtonText: string;
   nextButtonDataLogLabel?: string;
   previousButtonText: string | undefined;
   previousButtonDataLogLabel?: string;
   status: WritableSignal<StepStatus>;
   component: any;
};

export type StepTextData = Pick<
   Step,
   | "headerText"
   | "previousButtonText"
   | "nextButtonText"
   | "previousButtonDataLogLabel"
   | "nextButtonDataLogLabel"
>;

export enum ChangeStep {
   NEXT = "next",
   PREVIOUS = "previous",
}

export class StepperWizardContext<T> {
   public changeStep$: Subject<ChangeStep> = new Subject<ChangeStep>();

   private readonly _steps: Array<Step>;
   private _currentStep: Step;
   private readonly _state: T;
   private readonly _currentStep$: BehaviorSubject<Step>;
   private readonly _wizardCompleted$: BehaviorSubject<boolean> =
      new BehaviorSubject<boolean>(false);

   public constructor(steps: Array<Step>, state: T) {
      if (steps.length === 0) {
         throw new Error("Step wizard was supplied an empty list of steps.");
      }

      /**
       * stepNumber is used internally to find the next step, but it's also used in the stepper wizard UI.
       * This check insures that step numbers are consecutive, start at 0, and helps avoid potential
       * bugs in this class that could be caused by invalid stepNumbers.
       */
      for (let index = 0; index < steps.length; index++) {
         const step = steps[index];
         if (step.stepNumber !== index) {
            throw new Error(
               `Wizard steps stepNumber is invalid. Expected ${index} found ${step.stepNumber}`,
            );
         }
      }

      this._steps = steps;
      this._state = state;
      this._currentStep = this._steps[0];
      this._currentStep$ = new BehaviorSubject<Step>(this.currentStep);
   }

   public get totalSteps(): number {
      return this._steps.length;
   }

   public get steps(): Array<Step> {
      return this._steps ?? [];
   }

   public get previousStep(): Step | undefined {
      const previousStep = this.currentStep.stepNumber - 1;

      return previousStep >= 0 ? this._steps[previousStep] : undefined;
   }

   public get currentStep(): Step {
      return this._currentStep;
   }

   private set currentStep(step: Step) {
      this._currentStep.status.set(StepStatus.COMPLETE);
      step.status.set(StepStatus.INPROGRESS);

      this._currentStep = step;

      this.notifyOfStepChange();
   }

   public get nextStep(): Step | undefined {
      const nextStep = this.currentStep.stepNumber + 1;

      return nextStep < this._steps.length ? this._steps[nextStep] : undefined;
   }

   public get currentStep$(): Observable<Step> {
      return this._currentStep$.asObservable();
   }

   public get wizardCompleted$(): Observable<boolean> {
      return this._wizardCompleted$.asObservable();
   }

   public get state(): T {
      return this._state;
   }

   public goToNextStep(): Step | undefined {
      if (!this.nextStep) {
         this._wizardCompleted$.next(true);
         return undefined;
      }

      this.currentStep = this.nextStep;

      return this.currentStep;
   }

   public goToPreviousStep(): Step | undefined {
      if (!this.previousStep) {
         return undefined;
      }

      this.currentStep = this.previousStep;

      return this.currentStep;
   }

   private notifyOfStepChange(): void {
      this._currentStep$.next(this.currentStep);
   }
}
