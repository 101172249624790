<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body class="modal-body">
      @if (asset) {
         <lim-ui-panel>
            <div class="panel-container">
               <div class="settings-row-spacing">
                  <label>{{ lang().Location }}</label>

                  @if (asset) {
                     <input
                        type="text"
                        [(ngModel)]="locationsIndex[asset.locationID].locationName"
                        disabled="true"
                     />
                  }

                  <lim-ui-dropdown>
                     <lim-ui-primary-button button secondaryIcon="angleDown"
                        >{{ lang().Move }}
                     </lim-ui-primary-button>
                     <div menu>
                        @if (totalLocLength > 5) {
                           <lim-ui-search-box
                              [useDropdownStyling]="true"
                              [(searchVal)]="searchBar"
                              (searchValChange)="runSearch()"
                           />
                        }

                        <lim-ui-dropdown-divider />

                        @for (location of locations; track location) {
                           <lim-ui-dropdown-text-item
                              [ngClass]="{ dropDownSelectListBorder: !$last }"
                              [hidden]="!(location.locationID != asset?.locationID)"
                              [displayString]="location.locationNameWithRegions"
                              [outputString]="location.locationNameWithRegions"
                              (itemSelectedEvent)="changeLocation(location)"
                           />
                        }
                     </div>
                  </lim-ui-dropdown>
               </div>
               <div class="settings-row-spacing">
                  <label>{{ lang().HoursOfOperationPerWeek }} </label>

                  @if (asset && asset.assetTrackHoursManually == 0) {
                     <input
                        type="number"
                        min="0"
                        [(ngModel)]="displayHours"
                        (blur)="updateAssetHoursOfOperation(asset)"
                        onclick="this.focus();"
                     />
                  }

                  @if (asset?.assetTrackHoursManually == 1) {
                     <div>
                        {{ lang().NATrackedManually }}
                     </div>
                  }
                  <div class="icon-by-text">
                     <lim-ui-checkbox
                        [label]="lang().TrackManually"
                        (modelChange)="flipHoursManually(asset)"
                        [model]="asset?.assetTrackHoursManually"
                        [limUiTooltip]="lang().manuallyTrackHoursOfOperationTooltip"
                     />
                     @if (asset && asset.assetTrackHoursManually == 0) {
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           placement="bottom"
                           [limUiTooltip]="lang().hourlyTrackManuallyTooltip"
                        />
                     }
                  </div>
               </div>

               @if (asset?.assetTrackHoursManually == 1) {
                  <div>
                     <div class="listTable">
                        @for (
                           hour of this.manualHours
                              | sliceArray
                                 : (page - 1) * itemsPerPage
                                 : page * itemsPerPage;
                           track hour
                        ) {
                           <div class="listItem">
                              <div>
                                 <span>
                                    {{ hour.start }} {{ lang().to }} {{ hour.end }}
                                 </span>

                                 <input
                                    type="number"
                                    min="0"
                                    [(ngModel)]="hour.hours"
                                    (blur)="
                                       updateAssetTrackHoursManuallyData(hour, asset)
                                    "
                                    onclick="this.focus();"
                                    style="
                                       width: 60px;
                                       float: left;
                                       position: relative;
                                       top: -2px;
                                    "
                                 />

                                 <span>
                                    {{ lang().hours }}
                                 </span>
                              </div>
                           </div>
                        }
                     </div>

                     <div class="text-center">
                        @if (
                           this.manualHours.length &&
                           this.manualHours.length > itemsPerPage
                        ) {
                           <lim-ui-pagination
                              [maxSize]="5"
                              [rotate]="true"
                              [(page)]="page"
                              [pageSize]="itemsPerPage"
                              [collectionSize]="this.manualHours.length"
                              (pageChange)="pageChanged()"
                           />
                        }
                     </div>
                  </div>
               }

               <div class="settings-row-spacing">
                  <label
                     >{{ lang().AssetStartedOn }}
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        [limUiTooltip]="lang().AssetStartedOnTooltip"
                     />
                  </label>

                  <lim-ui-date-picker-input
                     [limUiTooltip]="lang().SetWhenThisScheduleGoesLive"
                     [model]="date"
                     (modelChange)="setAssetCreatedDate($event)"
                  />

                  <span class="lim-ui-fonts-caption">
                     {{ lang().RunningFor }}
                     @if (years > 0) {
                        <span>{{ years }} {{ lang().years }} </span>
                     }
                     @if (months > 0) {
                        <span>{{ months }} {{ lang().months }} </span>
                     }
                     @if (days > 0) {
                        <span>{{ days }} {{ lang().days }}</span>
                     }
                  </span>
               </div>
            </div>
         </lim-ui-panel>
      }

      @if (asset) {
         <lim-ui-panel>
            <div class="panel-container">
               <div class="settings-row-spacing">
                  <label
                     >{{ lang().WorkRequestsAssignedTo }}
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        [limUiTooltip]="lang().WorkRequestsAssignedToTooltip"
                     />
                  </label>
                  <lim-ui-text-button (click)="changeDefaultAssignment()">
                     {{ reportProblemAssignmentStr }}
                  </lim-ui-text-button>
               </div>

               <div class="settings-row-spacing">
                  <label
                     >{{ lang().DefaultWOTemplate }}
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        placement="right"
                        [limUiTooltip]="lang().AssociateDefaultWOWithAsset"
                     />
                  </label>
                  <div class="icon-by-text">
                     <lim-ui-text-button (click)="viewWOTemplates()" class="cursor">
                        {{ defaultChecklistName }}
                     </lim-ui-text-button>
                     @if (
                        asset.defaultChecklistID &&
                        asset.defaultChecklistID > 0 &&
                        asset.locationID > 0
                     ) {
                        <lim-ui-minimal-icon-button
                           icon="rotateLeft"
                           placement="right"
                           [limUiTooltip]="lang().DefaultAssetWOChecklistRevertTooltip"
                           (click)="resetDefaultWOTemplate(); $event.stopPropagation()"
                           [superMinimal]="true"
                        />
                     }
                  </div>
               </div>

               <div class="settings-row-spacing">
                  <span
                     class="checked-asset-container"
                     [limUiPopover]="startDuplicatePreventionPopover"
                     [limUiPopoverType]="'upsell'"
                     [limUiPopoverPlacement]="'top'"
                     [limUiPopoverHidden]="featureWRDuplicatePrevention"
                  >
                     <div
                        class="icon-by-text"
                        [ngClass]="{ paywalled: !featureWRDuplicatePrevention }"
                     >
                        <lim-ui-checkbox
                           [disabled]="!featureWRDuplicatePrevention"
                           [useSemiBoldLabel]="true"
                           [label]="lang().CheckingSimliarWorkRequests"
                           (modelChange)="updateAssetCheckSimiliarWRScope(asset)"
                           [model]="asset?.assetCheckSimiliarWRScope"
                        />
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           (click)="openArticleSimliarWorkRequests()"
                           placement="bottom"
                           [limUiTooltip]="lang().OnlyCheckThisAssetOrItsChildrenTooltip"
                        />
                     </div>
                     @if (!featureWRDuplicatePrevention) {
                        <lim-ui-badge
                           class="pricing-plan-badge"
                           icon="stars"
                           [label]="lang().Enterprise"
                        />
                     }
                     <ng-template #startDuplicatePreventionPopover>
                        <upsell-popover
                           [message]="lang().FeatureWRDuplicatePreventionPopoverMessage"
                           [pricingLinkText]="lang().LearnMore"
                        />
                     </ng-template>
                  </span>

                  <div
                     class="lim-ui-fonts-caption"
                     [ngClass]="{ paywalled: !featureWRDuplicatePrevention }"
                  >
                     {{ lang().OnlyCheckThisAssetOrItsChildren }}
                  </div>
               </div>

               <div class="settings-row-spacing">
                  <span
                     class="checked-asset-container"
                     [limUiPopover]="startToolsPopover"
                     [limUiPopoverType]="'upsell'"
                     [limUiPopoverPlacement]="'top'"
                     [limUiPopoverHidden]="featureAssetTools"
                  >
                     <div
                        class="icon-by-text"
                        [ngClass]="{ paywalled: !featureAssetTools }"
                     >
                        <lim-ui-checkbox
                           [useSemiBoldLabel]="true"
                           [model]="asset?.canCheckOutAsTool && featureAssetTools"
                           [label]="lang().AssetCanBeCheckedOutAsTool"
                           (modelChange)="updateCanCheckOutAsTool()"
                           [disabled]="!featureAssetTools"
                        />
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           placement="right"
                           [limUiTooltip]="lang().assetCanBeCheckedOutAsToolTooltip"
                        />
                        <a
                           href="https://intercom.help/limble-cmms/en/articles/7020225-using-tools-in-limble"
                           target="_blank"
                        >
                           {{ lang().LearnMore }}
                           <lim-ui-icon icon="arrowUpRightFromSquare" iconSize="small" />
                        </a>
                     </div>
                     @if (!featureAssetTools) {
                        <lim-ui-badge
                           class="pricing-plan-badge"
                           icon="stars"
                           [label]="lang().Enterprise"
                        />
                     }
                     <ng-template #startToolsPopover>
                        <upsell-popover
                           [message]="lang().FeatureAssetToolsPopoverMessage"
                           [pricingLinkText]="lang().LearnMore"
                        />
                     </ng-template>
                  </span>
               </div>
               @if (asset?.canCheckOutAsTool && featureAssetTools) {
                  <div class="icon-by-text">
                     <lim-ui-checkbox
                        [label]="lang().ToolRequiresApprovalProcess"
                        (modelChange)="updateCheckOutRequiresApproval()"
                        [model]="asset?.checkOutRequiresApproval"
                     />
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        placement="right"
                        [limUiTooltip]="lang().toolRequiresApprovalProcessTootip"
                     />
                  </div>

                  @if (
                     asset?.checkOutRequiresApproval &&
                     asset?.canCheckOutAsTool &&
                     featureAssetTools
                  ) {
                     <div class="settings-row-spacing">
                        <label>
                           {{ lang().ToolCheckOutApprovalUser }}
                           @if (checkOutApprovalUserName === "default") {
                              <lim-ui-icon
                                 icon="circleQuestionRegular"
                                 [limUiTooltip]="
                                    lang().defaultToolCheckOutApprovalUserTooltip
                                 "
                              />
                           }
                        </label>
                        <lim-ui-text-button (click)="updateCheckOutApprovalUserID()">
                           {{ checkOutApprovalUserName }}
                        </lim-ui-text-button>
                     </div>
                  }
               }
            </div>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer class="flex-start">
      <lim-ui-danger-button (click)="deleteAsset()">
         {{ lang().DeleteAsset }}
      </lim-ui-danger-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
