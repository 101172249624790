import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";

export type TaskPartsCostViewModel = {
   partsCost?: number;
   locationID?: number;
   currencyCode: string;
};
@Component({
   selector: "task-parts-cost-cell",
   imports: [CommonModule, LocaleCurrencyPipe],
   templateUrl: "./task-parts-cost-cell.component.html",
})
export class TaskPartsCostCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: TaskPartsCostViewModel;

   @Input() public column?: Column;
}
