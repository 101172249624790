import { Injectable, inject } from "@angular/core";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { SearchHintBuilder } from "src/app/tasks/search-hint/hint-builders/default-search-hint-builder.service";
import { DefaultSearchHintBuilderService } from "src/app/tasks/search-hint/hint-builders/default-search-hint-builder.service";

export interface SearchHints {
   taskID?: number;
   taskName?: string;
   taskDescription?: string;
   assetName?: string;
   locationName?: string;
   partName?: string;
   userAssignment?: TaskAssignment;
   profileAssignment?: TaskAssignment;
   completers?: TaskCompleters;
   requestFields?: Array<string>;
   dates?: Array<DateHint>;
}

export type User = { firstName: string; lastName: string } | { deleted: true };
type Profile = { profileName: string };

export type TaskAssignment = Array<User> | Profile;
export type TaskCompleters = Array<User>; // Only users can complete a task.

export enum DateHint {
   Started = "started",
   Completed = "completed",
   Due = "due",
   Created = "created",
}

export interface SearchHintMatchOptions {
   /**
    * Wraps the search string match in <b> tag
    */
   boldMatch?: boolean;

   /**
    * Whether to match all hints and join them. If falsy -> will return the first match only.
    */
   joinAllMatches?: boolean;

   /**
    * Sets the sequential order for matching search hints
    */
   matchLookupOrder?: Array<keyof SearchHints>;
}

@Injectable({
   providedIn: "root",
})
export class DefaultSearchHintService {
   protected readonly searchHintBuilder: SearchHintBuilder;

   protected readonly searchHintBuilderService = inject(DefaultSearchHintBuilderService);

   public constructor() {
      this.searchHintBuilder = this.searchHintBuilderService.getSearchHintBuilder();
   }

   public getSearchHints(
      entityPrimaryKey: string | number,
      entities: Array<{ hint?: SearchHints }>,
      searchValue: string | undefined,
      options?: SearchHintMatchOptions,
   ): LimbleMap<number, string> {
      const searchHints = new LimbleMap<number, string>();
      for (const entity of entities) {
         const hint = this.getSearchHint(entity, searchValue, options);
         if (hint !== "" && entity[entityPrimaryKey]) {
            searchHints.set(entity[entityPrimaryKey], hint);
         }
      }
      return searchHints;
   }

   public getSearchHint(
      entity: { hint?: SearchHints },
      searchValue: string | undefined,
      options?: SearchHintMatchOptions,
   ): string {
      if (!searchValue || searchValue === "" || !entity.hint) {
         return "";
      }

      const entries = options?.matchLookupOrder
         ? options.matchLookupOrder
              .map(
                 (matchLookup) =>
                    [matchLookup, entity.hint?.[matchLookup]] as [string, any],
              )
              .filter(([, value]) => value)
         : Object.entries(entity.hint);

      const searchHintMatches = entries.map(([key, value]) => {
         const builder = this.searchHintBuilder[key];
         const hint = builder(value, entity);
         return options?.boldMatch
            ? this.boldMatchSearchHint(hint, searchValue ?? "")
            : hint;
      });

      if (searchHintMatches.length === 0) {
         return "";
      }

      if (options?.joinAllMatches) {
         return searchHintMatches.join(" | ");
      }
      return searchHintMatches[0];
   }

   private boldMatchSearchHint(hint: string, searchTerm: string): string {
      let search = searchTerm.toLowerCase();
      if (search.startsWith("#")) {
         search = search.substr(1);
      }
      const searchRegExp = new RegExp(
         search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
         "gi",
      );
      const replaceWith = "<b>$&</b>";
      return hint.replace(searchRegExp, replaceWith);
   }
}
