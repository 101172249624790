import { Injectable } from "@angular/core";
import type { CurrencyDto } from "src/app/purchasing/currency/dto/mini-flannel/currency.dto";
import type { CurrencyDto as PhpCurrencyDto } from "src/app/purchasing/currency/dto/php/currency.dto";

@Injectable({ providedIn: "root" })
export class PhpCurrencyDtoMappingService {
   public mapDto(dto: PhpCurrencyDto): CurrencyDto {
      return {
         id: dto.currencyID,
         code: dto.alphabeticCodeIso,
         name: dto.currencyName,
         symbol: dto.currencySymbol,
         rate: Number(dto.rate),
      };
   }
}
