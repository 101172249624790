//current readme doc https://limble.atlassian.net/wiki/spaces/Engineering/pages/58720293/Hierarchy+Item
import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   type Aliases,
   IconComponent,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   TextButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { Subject, debounceTime } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { HIERARCHY_LIMIT } from "src/app/shared/components/global/hierarchy/hierarchy.service";
import type { HierarchyOptions, HierarchyNode } from "src/app/shared/types/general.types";

@Component({
   selector: "hierarchy-item",
   templateUrl: "./hierarchy-item.component.html",
   styleUrls: ["./hierarchy-item.component.scss"],
   imports: [
      NgClass,
      IconComponent,
      LimbleHtmlDirective,
      MinimalIconButtonComponent,
      TooltipDirective,
      TextButtonComponent,
      LoadingAnimationComponent,
   ],
})
export class HierarchyItemComponent implements OnInit {
   @Input() node?: HierarchyNode;
   @Input() options: HierarchyOptions | undefined;
   @Input() deselectAllNodes;
   @Input() selectNode!: (node: HierarchyNode) => Promise<void>;
   @Input() collapseNode!: (node: HierarchyNode) => Promise<void>;
   @Input() icon?: Aliases;
   @Input() clickAgainText: string | undefined;
   @Input() fetchMore: ((locationID: number) => Promise<void>) | undefined;
   protected numberOfItemsRendered: number = 0;
   protected numberOfChildrenToAdd: number = HIERARCHY_LIMIT;

   protected readonly userClickSubject = new Subject<void>();

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.options = {
         idKey: "",
         nodeButtons: [],
      };

      this.userClickSubject.pipe(debounceTime(50), takeUntilDestroyed()).subscribe(() => {
         if (this.node === undefined) {
            return;
         }
         this.selectNode(this.node);
      });
   }

   public ngOnInit() {
      if (!this.node) {
         return;
      }
      this.numberOfItemsRendered = this.node.nodes?.length;
      this.calculateAdditionalItemsToRenderText();
   }
   //This needs to be an arrow function to preserve its execution context
   //it WILL NOT WORK with .bind() for reasons unknown.
   handleFetchMore = async (): Promise<void> => {
      if (this.fetchMore === undefined || this.node?.locationID === undefined) {
         return;
      }
      await this.fetchMore(this.node.locationID);
      //after running this.fetchMore, the function's execution context is still in the wrong place
   };

   protected async showMoreItems(): Promise<void> {
      await this.handleFetchMore();
      //this should be this.numberOfItemsRendered = this.node?.pagination.page * this.numberOfChildrenToAdd
      if (!this.node?.pagination?.total) {
         return;
      }

      this.numberOfItemsRendered =
         this.node.pagination.page * HIERARCHY_LIMIT < this.node.pagination.total
            ? this.node.pagination.page * HIERARCHY_LIMIT
            : this.node.pagination.total;

      this.calculateAdditionalItemsToRenderText();
   }

   private calculateAdditionalItemsToRenderText(): void {
      if (!this.node?.nodes?.length || !this.node?.pagination?.total) {
         return;
      }
      const remainder = this.node.pagination.total % HIERARCHY_LIMIT;
      if (
         this.node.pagination.page * HIERARCHY_LIMIT <
         this.node.pagination.total - remainder
      ) {
         this.numberOfChildrenToAdd = HIERARCHY_LIMIT;
      } else {
         this.numberOfChildrenToAdd = remainder;
      }
   }
}
