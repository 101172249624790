import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   IconButtonComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   ProgressBarComponent,
   ScrollContainerComponent,
   SecondaryButtonComponent,
   SliderComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import Cookies from "js-cookie";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import {
   PartsFacadeService,
   type AddPartModalData,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { PrintDivDirective } from "src/app/parts/directives/printDiv/printDiv.directive";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Category } from "src/app/parts/types/category/category.types";
import type { Part } from "src/app/parts/types/part.types";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { QRCodesCustomizePrint } from "src/app/shared/components/global/qrCodeCustomizePrintModal/qrCodesCustomizePrint.modal.component";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ElementsToImages } from "src/app/shared/services/elementsToImages";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";
import QRCode from "src/assets/js/qrcode";

type PartsExtraInfo = Map<number, { locationName: string; lookupPartURL: string }>;

@Component({
   selector: "qr-codes-view-part",
   templateUrl: "./qrCodesViewPart.modal.component.html",
   styleUrls: ["./qrCodesViewPart.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      IconButtonComponent,
      SecondaryButtonComponent,
      TooltipDirective,
      SliderComponent,
      FormsModule,
      ReactiveFormsModule,
      PanelComponent,
      LoadingAnimationComponent,
      ProgressBarComponent,
      ScrollContainerComponent,
      ModalFooterComponent,
      DropdownComponent,
      DropdownTextItemComponent,
      PrimaryButtonComponent,
      PrintDivDirective,
      BetterDecimalPipe,
   ],
})
export class QrCodesViewPart implements OnInit {
   public message;
   public title;
   public loading;
   public CID;
   public locationID;
   public errorMsg;
   public parts: Lookup<"partID", Part> = new Lookup("partID");
   public partsLength;
   protected zoomFormControl = new FormControl(50);

   public index: number = 0;
   public startingPart: Part | undefined;
   public started;
   public resolve;
   public modalInstance;
   public partCategories: Lookup<"categoryID", Category> = new Lookup("categoryID");
   public assetsLookup: Record<number, Asset> = {};
   public vendors: Lookup<"vendorID", Vendor>;
   public partsExtraInfo: PartsExtraInfo = new Map();
   private readonly locationsIndex;
   public partsActiveAssetRelationIDs: Record<number, number[]> = {};

   private readonly manageUser = inject(ManageUser);
   public readonly manageParts = inject(ManageParts);
   private readonly manageUtil = inject(ManageUtil);
   private readonly elementsToImages = inject(ElementsToImages);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly managePO = inject(ManagePO);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly partsFacadeService = inject(PartsFacadeService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.partCategories = this.manageParts.getCategories();
      this.locationsIndex = this.manageLocation.getLocationsIndex();
      this.vendors = this.manageVendor.getVendors();
      this.zoomFormControl.valueChanges.subscribe((value) => {
         if (!this.started) {
            this.runWatch();
         }
         const date = new Date();
         date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
         Cookies.set("partSliderValue", String(value), { expires: date, path: "/" });
      });
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.startingPart = this.manageParts.getPart(this.resolve.part.partID);

      this.loading = false;
      this.started = false;
      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.errorMsg = "";

      if (!this.startingPart) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      this.locationID = this.startingPart.locationID;
      this.parts.set(this.startingPart.partID, this.startingPart);
      this.partsLength = this.parts.size;

      this.loading = true;

      this.buildPartAssetRelationIDs(this.startingPart);

      this.buildQRCodes(this.startingPart);
      this.loading = false;

      const sliderCookieValue = Cookies.get("partSliderValue");
      if (sliderCookieValue) {
         this.zoomFormControl.setValue(Number(sliderCookieValue));
      }
   }

   private getZoomValue(): number {
      return this.zoomFormControl.value ?? 50;
   }

   public linkToVideo(title, videoId, startAt) {
      this.manageUtil.watchVideo({
         title,
         src: `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&feature=shared&start=${startAt}`,
      });
   }

   downloadAll = () => {
      const elementMap = new Map();
      for (const part of this.parts) {
         const name = part.partName?.replace(/[^a-z0-9]/gi, "_").toLowerCase();
         const element = document.getElementById(`qrCodeContainer${part.partID}`);
         elementMap.set(`${name}-${part.partID}`, element);
      }

      this.elementsToImages.run(elementMap).then(this.elementsToImages.zipAndDownload);
   };

   downloadUrls = (flag) => {
      const today = new Date();
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      if (flag === "selected") {
         this.manageParts.downloadExcel(
            this.parts,
            `Parts List with URLs - ${date}`,
            true,
            false,
            false,
            false,
            true,
            this.manageAssociations.getPartVendorRelations(),
            this.managePO,
         );
      } else if (flag === "all") {
         const parts = this.manageParts
            .getParts()
            .filter(
               (part) => part.locationID === this.locationID && part.partDeleted === 0,
            );

         this.manageParts.downloadExcel(
            parts,
            `Parts List with URLs - ${date}`,
            true,
            false,
            false,
            false,
            true,
            this.manageAssociations.getPartVendorRelations(),
            this.managePO,
         );
      }
   };

   public async showMoreThenOne(): Promise<void> {
      if (!this.startingPart) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      const modalData: AddPartModalData = {
         title: this.lang().PickParts,
         buttonText: this.lang().Select,
         singleLocation: this.startingPart.locationID,
         message: this.lang().PickPartsQRCodeMsg,
      };

      const parts: Array<Part> | false =
         await this.partsFacadeService.openAddPartModal(modalData);

      if (parts && parts.length > 0) {
         const partsTemp = parts;

         setTimeout(() => {
            this.loading = true;
            this.partsLength = partsTemp.length;
            this.index = 0;

            setTimeout(() => {
               this.parts = new Lookup("partID");
               this.assetsLookup = {};

               for (const part of partsTemp) {
                  const obj = this.manageParts.getPart(part.partID);
                  this.manageAssociations.getAssociatedAssetsForPart(part.partID);
                  if (obj) {
                     this.parts.set(part.partID, obj);
                  }

                  this.buildPartAssetRelationIDs(part);

                  this.buildQRCodes(part);
               }
               this.sortParts();
               this.loading = false;
            }, 300);
         }, 100);
      }
   }

   private buildPartAssetRelationIDs(part: Part): void {
      this.partsActiveAssetRelationIDs[part.partID] = [];
      for (const relationID of part.partAssetRelationIDs) {
         const assetID = this.manageParts.getAssetRelation(relationID)?.assetID;
         if (!assetID) {
            continue;
         }
         const asset = this.manageAsset.getAsset(assetID);

         if (!asset || asset.assetDeleted === 1) {
            continue;
         }
         this.partsActiveAssetRelationIDs[part.partID].push(relationID);
         this.assetsLookup[assetID] = asset;
      }
   }

   buildQRCodes = (part) => {
      this.index = this.index + 1;
      setTimeout(() => {
         const host = `${window.location.protocol}//${window.location.host}`;
         const lookupPartURL = `${host}/mobilePart/${part.partID}/${part.locationID}?m=true`;
         this.partsExtraInfo.set(part.partID, {
            locationName: this.locationsIndex[part.locationID].locationName,
            lookupPartURL,
         });

         $(`#qrCodePart${part.partID}`).empty();

         // eslint-disable-next-line no-new -- we don't have control over this library
         new QRCode(document.getElementById(`qrCodePart${part.partID}`), {
            text: this.partsExtraInfo.get(part.partID)?.lookupPartURL,
            width: 400 * (this.getZoomValue() / 100),
            height: 400 * (this.getZoomValue() / 100),
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
         });

         this.manageAssociations.getAssociatedAssetsForPart(part.partID);

         $(".resizeable-text").css("font-size", `${this.getZoomValue() / 3}px`);
         $(".qrCodeContainer").css("width", `${this.getZoomValue() * 10}px`);
      }, 200);
   };

   runWatch = () => {
      this.started = true;
      setTimeout(() => {
         for (const part of this.parts) {
            this.buildQRCodes(part);
         }
         this.started = false;
      }, 1000);
   };

   popQRCodesCustomizePrint = () => {
      const instance = this.modalService.open(QRCodesCustomizePrint);
      instance.componentInstance.data.set(this.parts);
      instance.componentInstance.dataType.set("part");
   };

   public sortParts() {
      this.parts = this.parts.orderBy("partName");
   }

   close = () => {
      this.modalInstance.close(false);
   };
}
