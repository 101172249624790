import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import {
   ChangeDetectorRef,
   Component,
   computed,
   HostBinding,
   inject,
   NgZone,
   type OnDestroy,
   type OnInit,
} from "@angular/core";
import { isPhoneScreenSize$, ScrollContainerComponent } from "@limblecmms/lim-ui";
import type { Observable } from "rxjs";
import { AssetModalComponent } from "src/app/assets/components/asset-modal/asset-modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { AssetInfoTabHeader } from "src/app/shared/types/general.types";
import { ManageLogin } from "src/app/users/services/manageLogin";

@Component({
   selector: "pop-asset",
   templateUrl: "./popAsset.modal.component.html",
   imports: [ScrollContainerComponent, NgTemplateOutlet, AssetModalComponent, AsyncPipe],
})
export class PopAsset implements OnInit, OnDestroy {
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLogin = inject(ManageLogin);
   private readonly paramsService = inject(ParamsService);
   private readonly zone = inject(NgZone);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   @HostBinding("class") public readonly classes = "scroll-height-inheritance";

   public resolve;
   public modalInstance;
   public assetID: number | undefined;
   public loadingBar;
   public restrict;
   public asset: Asset | undefined;
   public preventParentAccess;
   public navigateToTab: AssetInfoTabHeader | undefined;

   protected isPhoneScreenSize$: Observable<boolean> = isPhoneScreenSize$;
   protected dataLogOptions: DataLogEventDefinition | undefined;
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private closeModalFunc;

   public constructor() {
      setTimeout(() => {
         this.zone.run(() => {
            this.ref.detectChanges();
         });
      });
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;
      this.dataLogOptions = this.resolve.dataLogOptions;
      this.closeModalFunc = this.resolve.closeModalFunc;

      this.navigateToTab = data.navigateToTab;
      this.assetID = this.resolve.assetID;
      this.loadingBar = true;
      this.restrict = data.restrict ?? false;
      this.preventParentAccess = data.preventParentAccess;

      //if they are not logged in then we need to close the model because they are redirected to the login page.
      this.manageLogin.checkLogin().then((answer: any) => {
         if (answer.data.success == false) {
            this.modalInstance.close();
         }
      });

      //add a 400 ms delay so that the modal can load properly before the asset loads.  This makes the tool look much smoother.
      setTimeout(() => {
         this.loadingBar = false;

         if (!this.assetID) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }
         this.asset = this.manageAsset.getAsset(this.assetID);
      }, 300);
   }

   public ngOnDestroy() {
      if (this.closeModalFunc) {
         this.closeModalFunc();
      }
   }

   close = () => {
      this.modalInstance.close();
   };
}
