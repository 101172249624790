import {
   Component,
   computed,
   inject,
   input,
   output,
   signal,
   type OnInit,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IconComponent, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { ExtraBatch } from "src/app/parts/types/extra-batch/extra-batch.types";
import type { TaskEntityPart } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import type { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";

type PartID = number;

export type UpdatePartUsedNumberEvent = {
   partID: PartID;
   usedNumber: number;
};

export type PurchaseOrderWithExtraBatches = PurchaseOrder & {
   currentState: PurchaseOrderCurrentState | undefined;
   extraBatch?: ExtraBatch;
};

@Component({
   selector: "part-pending-pos",
   imports: [
      IconComponent,
      TooltipDirective,
      FormsModule,
      PartUnitOfMeasurePipe,
      PartUnitOfMeasurePipe,
   ],
   templateUrl: "./part-pending-pos.component.html",
   styleUrl: "./part-pending-pos.component.scss",
})
export class PartPendingPOsComponent implements OnInit {
   public readonly part = input.required<TaskEntityPart>();

   protected readonly managePO = inject(ManagePO);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected showPoOnPart = signal<boolean>(false);

   protected readonly currentUser = this.manageUser.getCurrentUser();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   // Outputs the po ID
   public readonly popPoComponent = output<number>();

   protected getPartsPendingPOs = computed<PurchaseOrderWithExtraBatches[]>(() => {
      const part = this.part();
      const pendingPOs: {
         purchaseOrders: Lookup<"poID", PurchaseOrder>;
         extraBatchesByPOID: LimbleMap<number, ExtraBatch>;
      } = this.managePO.findPendingPurchaseOrderInfoForPart(part.partID, true) || [];
      const partPendingPOs: Array<
         PurchaseOrder & {
            currentState: PurchaseOrderCurrentState | undefined;
            extraBatch?: ExtraBatch;
         }
      > = [];
      for (const purchaseOrder of pendingPOs.purchaseOrders) {
         if (purchaseOrder.state === null) continue;
         const extraBatch = pendingPOs.extraBatchesByPOID.get(purchaseOrder.poID);
         const currentState = this.managePO.getPurchaseOrderCurrentState(
            purchaseOrder.poID,
         );
         if (purchaseOrder.state <= 97) {
            //if we are still ready to receive or a prior step include
            partPendingPOs.push({ ...purchaseOrder, currentState });
         } else if (
            extraBatch?.partQtyUsed !== null &&
            extraBatch?.partQty &&
            extraBatch.partQtyUsed < extraBatch.partQty
         ) {
            //we are partially received or better so only include if we have items for them to see num available
            partPendingPOs.push({ ...purchaseOrder, currentState, extraBatch });
         } else if (purchaseOrder.state === 98 && extraBatch === undefined) {
            partPendingPOs.push({ ...purchaseOrder, currentState });
         }
      }
      return partPendingPOs;
   });

   public ngOnInit() {
      const pendingPOs = this.getPartsPendingPOs();
      if (pendingPOs.length < 3) {
         this.showPoOnPart.set(true);
      }
   }
}
