import { Component, input } from "@angular/core";
import { AssetAssociatedPartsComponent } from "src/app/assets/components/asset-modal/components/asset-parts-tab/asset-associated-parts/asset-associated-parts.component";
import { AssetPartsUsageComponent } from "src/app/assets/components/asset-modal/components/asset-parts-tab/asset-parts-usage/asset-parts-usage.component";

@Component({
   selector: "asset-parts-tab",
   templateUrl: "./asset-parts-tab.component.html",
   styleUrls: ["./asset-parts-tab.component.scss"],
   imports: [AssetAssociatedPartsComponent, AssetPartsUsageComponent],
})
export class AssetPartsTabComponent {
   public readonly assetID = input<number>(0);
}
