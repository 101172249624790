import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input, type OnInit } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TaskEntityType } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";

export type TaskTypeCellViewModel = {
   taskType?: TaskEntityType;
   taskTypeStr?: string;
};

@Component({
   imports: [CommonModule],
   selector: "task-type-cell",
   templateUrl: "./task-type-cell.component.html",
   styleUrls: ["./task-type-cell.component.scss"],
})
export class TaskTypeCellComponent implements OnInit {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: TaskTypeCellViewModel;

   protected buttonColor: string = "";

   public ngOnInit(): void {
      this.buttonColor = this.getButtonColor(this.task);
   }

   private getButtonColor(task: TaskTypeCellViewModel): string {
      if (task.taskType === undefined) {
         return "";
      }

      // eslint-disable-next-line default-case -- We want Typescript to do exhaustiveness checks.
      switch (task.taskType) {
         case TaskEntityType.PlannedMaintenances:
         case TaskEntityType.PlannedWorkOrders:
         case TaskEntityType.MaterialRequests:
            return "darkGreen";
         case TaskEntityType.PartThresholds:
         case TaskEntityType.WorkRequests:
         case TaskEntityType.UnplannedWorkOrders:
            return "darkOrange";
         case TaskEntityType.CycleCounts:
            return "";
      }
      throw new Error(`Unexpected task type: ${task.taskType}`);
   }
}
