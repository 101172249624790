import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { ManageUser } from "src/app/users/services//manageUser";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ManageInvoice {
   private readonly manageUser = inject(ManageUser);

   private readonly invoices: any = {};
   private readonly axios = axios;

   public constructor() {
      this.invoices.arr = [];
      this.invoices.index = {};
   }

   getData = async () => {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/invoices`);

      logApiPerformance(
         "invoices",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.invoices.arr = response.data || [];

      const regex = /(?:\.([^.]+))?$/;
      for (const invoice of this.invoices.arr) {
         this.invoices.index[invoice.invoiceID] = invoice;
         invoice.invoiceCost = Number(invoice.invoiceCost);
         invoice.ext = regex.exec(invoice.invoiceFileName)?.[1];
      }
   };
   clearData = () => {
      this.invoices.arr = [];
      this.invoices.index = {};
   };
   getInvoices = () => {
      return this.invoices.arr;
   };
   getInvoicesIndex = () => {
      return this.invoices.index;
   };
   public async updateInvoiceCost(
      invoiceID: number,
      invoiceCost: number,
   ): Promise<AxiosResponse> {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageInvoice.php",
         params: {
            action: "updateInvoiceCost",
         },
         data: {
            invoiceID: invoiceID,
            invoiceCost: invoiceCost,
         },
      });

      return post;
   }
   updateInvoiceDescription = async (invoice) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageInvoice.php",
         params: {
            action: "updateInvoiceDescription",
         },
         data: {
            invoiceID: invoice.invoiceID,
            invoiceDescription: invoice.invoiceDescription,
         },
      });

      return post;
   };
   addInvoice = async (checklistID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageInvoice.php",
         params: {
            action: "addInvoice",
         },
         data: {
            checklistID: checklistID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            answer.data.invoice.invoiceCost = Number(answer.data.invoice.invoiceCost);

            this.invoices.arr.push(answer.data.invoice);
            const newIndex = this.invoices.arr.length - 1;
            this.invoices.index[answer.data.invoice.invoiceID] =
               this.invoices.arr[newIndex];
         }
      });

      return post;
   };

   public async removeInvoice(invoiceID: number): Promise<AxiosResponse> {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageInvoice.php",
         params: {
            action: "removeInvoice",
         },
         data: {
            invoiceID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            for (let index = 0; index < this.invoices.arr.length; index++) {
               if (this.invoices.arr[index].invoiceID === invoiceID) {
                  this.invoices.arr.splice(index, 1);
               }
            }
         }
      });

      return post;
   }

   public addInvoiceToLocalData(invoice) {
      const newInvoiceIndex = this.invoices.arr.push(invoice) - 1;
      this.invoices.index[invoice.invoiceID] = this.invoices.arr[newInvoiceIndex];
   }
}
