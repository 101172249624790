import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
} from "@angular/core";
import { TranslateService } from "src/app/languages";
import { ButtonComponent } from "src/app/shared/empowered/base/button/button.component";
import { MenuComponent } from "src/app/shared/empowered/base/menus/menu/menu.component";
import type { MenuItem } from "src/app/shared/empowered/base/menus/menus.models";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageTask } from "src/app/tasks/services/manageTask";

@Component({
   selector: "priority-button",
   standalone: true,
   templateUrl: "./priority-button.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [MenuComponent, ButtonComponent],
})
export class PriorityButtonComponent {
   taskIsCompleted = input(false);
   id = input.required<number | null>();
   taskId = input.required<number>();
   locationId = input.required<number>();
   superUser = input(false);
   useFullName = input(false);

   private readonly manageTask = inject(ManageTask);
   private readonly managePriority = inject(ManagePriority);
   private readonly tasksFacade = inject(TasksFacadeService);
   private readonly t = inject(TranslateService);

   protected readonly info = computed(() => {
      const info = this.manageTask.getPriorityInfo(this.id());
      return {
         level: info.priorityLevel,
         name: this.cleanName(info.priorityLevel, info.priorityName),
      };
   });

   protected readonly list = computed(() => this.managePriority.getPriorityList());

   protected readonly color = computed(() => {
      const currentId = this.id();
      const listIndex = this.managePriority.getPriorityListIndex();

      if (currentId !== null && currentId !== 0 && listIndex !== undefined) {
         return listIndex[currentId]?.color;
      }
      return undefined;
   });

   buttonSize = computed(() => (this.useFullName() ? "small" : "extra-small"));

   buttonStyle = computed(() => ({
      color: this.color() ?? "var(--lim-el-no-priority-color)",
   }));

   menuItems = computed<MenuItem[]>(() => {
      const items: MenuItem[] = this.list().map((item) => ({
         label: `${item.priorityLevel} - ${item.name}`,
         value: item.priorityID,
         command: () => {
            this.updateId(item);
         },
         disabled: this.taskIsCompleted(),
      }));

      if (this.superUser()) {
         items.push({
            separator: true,
         });
         items.push({
            label: this.t.instant("EditPriorities"),
            icon: "penToSquare",
            command: () => {
               this.edit();
            },
         });
      }

      return items;
   });

   selectedLabel = computed(() => {
      const info = this.info();
      if (this.useFullName() && info.name) {
         return `${info.level} - ${info.name}`;
      }
      return String(info.level);
   });

   protected edit(): void {
      this.tasksFacade.editPriorities();
   }

   protected updateId(item: { priorityID: number }): void {
      this.tasksFacade.updateTaskPriorityID(
         this.taskId(),
         this.locationId(),
         item.priorityID,
         this.id(),
      );
   }

   private cleanName(level?: number, name?: string): string | undefined {
      if (!this.useFullName() || level === undefined || name === undefined) {
         return undefined;
      }
      return name.replace(level.toString(), "");
   }
}
