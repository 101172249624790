import { inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { PmSuggestion } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion";
import { PmSuggestionsPersistence } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestions-persistence";
import type {
   CreateSuggestion,
   GetPmSuggestionsParams,
   MutationEvent,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource-types";

@Injectable({ providedIn: "root" })
export class PmSuggestionsResourceService {
   private readonly pmSuggestionsPersistence = inject(PmSuggestionsPersistence);

   /** Emits upon a successful mutation of a PM suggestion. */
   public readonly mutations = new Subject<MutationEvent>();

   public readonly cache = new Map<string, Promise<Array<PmSuggestion>>>();

   public async get(params: GetPmSuggestionsParams = {}): Promise<Array<PmSuggestion>> {
      const cached = this.cache.get(JSON.stringify(params));
      if (cached !== undefined) {
         return cached;
      }
      const request = new Promise<Array<PmSuggestion>>((resolve, reject) => {
         this.pmSuggestionsPersistence.get(params).subscribe({
            next: (newSuggestions) => {
               const suggestions = newSuggestions.map((suggestion) =>
                  PmSuggestion.fromDto(suggestion),
               );
               resolve(suggestions);
            },
            error: (error: Error) => {
               this.cache.delete(JSON.stringify(params));
               reject(error);
            },
         });
      });
      this.cache.set(JSON.stringify(params), request);
      return request;
   }

   public async create(suggestion: CreateSuggestion): Promise<void> {
      return new Promise((resolve, reject) => {
         this.pmSuggestionsPersistence.create({ suggestion }).subscribe({
            next: () => {
               this.cache.clear();
               this.mutations.next({
                  type: "create",
                  suggestion,
               });
               resolve();
            },
            error: (error: Error) => {
               reject(error);
            },
         });
      });
   }

   public async delete(ids: Array<number>): Promise<void> {
      return new Promise((resolve, reject) => {
         this.pmSuggestionsPersistence.delete({ ids }).subscribe({
            next: () => {
               this.cache.clear();
               this.mutations.next({
                  type: "delete",
                  ids,
               });
               resolve();
            },
            error: (error: Error) => {
               reject(error);
            },
         });
      });
   }
}
