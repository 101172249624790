<ng-container *translate="let t">
   <lim-ui-dropdown-button
      [widthFitContent]="true"
      [label]="t(filter().labelKey) || ''"
      filterDropdown
   >
      <div menu>
         <div class="split-dropdown">
            <span class="dropdown-column">
               <div class="dropdown-column-title">
                  {{ t("User") }}
               </div>
               @for (user of users(); track user) {
                  <lim-ui-dropdown-text-item
                     displayString="{{ user.firstName }} {{ user.lastName }}"
                     (click)="handleAssignedToUser(user)"
                  />
               }
            </span>
            <span class="dropdown-column">
               <div class="dropdown-column-title">
                  {{ t("Team") }}
               </div>
               @for (profile of profiles(); track profile) {
                  <lim-ui-dropdown-text-item
                     displayString="{{ profile.profileName }}"
                     (click)="handleAssignedToProfile(profile)"
                  />
               }
            </span>
         </div>
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-clear-filter-item (click)="handleClear()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
