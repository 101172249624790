<lim-ui-modal class="setup-work-order-modal" [extraLarge]="true">
   @if (data) {
      <lim-ui-modal-header (closeModal)="close()">
         <div class="modal-header-main">
            <div class="modal-header-title-container">
               <div
                  class="modal-title"
                  [limbleHtml]="title"
                  [ngClass]="{ 'title-normal-weight': !data.WO }"
               ></div>
               <div modalTitle class="additional-buttons">
                  <!-- Note that we discovered that lang().MachineDownAlertTooltip breaks the click event on mobile only. -->
                  @if (showMachineDownAlert && data.WO) {
                     <lim-ui-secondary-button
                        (click)="machineDownAlert(task)"
                        icon="envelope"
                        [limUiTooltip]="isMobile ? '' : lang().MachineDownAlertTooltip"
                     >
                        <span [limbleHtml]="showMachineDownAlertButtonText"> </span
                     ></lim-ui-secondary-button>
                  }
                  @if (!data.defaultTemplateLocked && data.WO) {
                     <lim-ui-secondary-button
                        [ngClass]="{
                           'plan-restricted-access': (WOInstructionLimitReached$ | async),
                        }"
                        (click)="viewWOTemplates()"
                        data-test-id="view-wo-templates"
                        [icon]="isMobile && !canAddInstruction ? 'stars' : undefined"
                        [limUiPopover]="startPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverPlacement]="'right'"
                        [limUiPopoverHidden]="canAddInstruction"
                        [disabled]="!canAddInstruction"
                     >
                        {{ lang().WOTemplates }}
                     </lim-ui-secondary-button>
                  }
                  @if (
                     !isMobile &&
                     !canAddInstruction &&
                     !data.defaultTemplateLocked &&
                     data.WO
                  ) {
                     <lim-ui-badge
                        class="pricing-plan-badge"
                        [label]="lang().PremiumPlus"
                        icon="stars"
                        [limUiPopover]="startPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverPlacement]="'right'"
                        [limUiPopoverHidden]="canAddInstruction"
                     />
                  }
                  <ng-template #startPopover>
                     <upsell-popover
                        [message]="
                           woInstructionLimit > 2
                              ? lang().FeatureThreeWOsPopoverMessage
                              : lang().FeatureTwoWOsPopoverMessage
                        "
                        [pricingLinkText]="lang().LearnMore"
                     />
                  </ng-template>
               </div>
            </div>
            <lim-ui-minimal-icon-button
               (click)="close()"
               data-test-id="close-setup-work-order"
               icon="xmarkLargeRegular"
               iconSize="medium-large"
            />
         </div>
      </lim-ui-modal-header>
   }

   @if (data) {
      <lim-ui-modal-body>
         @if (data.WO) {
            @if (
               data.WOState === "woTemplate" &&
               !loadingBar &&
               taskData?.checklistID &&
               task != undefined
            ) {
               <div class="loaded-template-title">
                  {{ lang().LoadedTemplate }}
               </div>
            }

            @if (
               loadingBar ||
               (data.WOState === "woTemplate" && !loadingBar) ||
               (taskData?.checklistID &&
                  data.WOState === "woTemplate" &&
                  task != undefined) ||
               (taskData?.checklistID && data.WOState === "advanced" && task != undefined)
            ) {
               <lim-ui-panel>
                  @if (loadingBar) {
                     <div class="loading-icon chk-spinner-box">
                        <lim-ui-loading-animation />
                     </div>
                  }

                  @if (
                     taskData?.checklistID &&
                     data.WOState === "woTemplate" &&
                     task != undefined
                  ) {
                     <div class="wo-templates-container">
                        <div
                           class="read-more-content"
                           [ngClass]="{ 'read-more-content-after': readMoreContent }"
                        >
                           @if (taskData?.checklistID && task) {
                              <task-form
                                 [data]="taskData"
                                 [initialTask]="task"
                                 [(workOrderTemplateType)]="WOtype"
                              />
                           }
                        </div>

                        <div class="read-more-buttons">
                           @if (!readMoreContent) {
                              <lim-ui-secondary-button
                                 class="read-more-button cursor"
                                 (click)="expandReadMore()"
                              >
                                 {{ lang().ViewMore }}
                              </lim-ui-secondary-button>
                           }
                           @if (readMoreContent) {
                              <lim-ui-secondary-button
                                 class="read-more-button cursor"
                                 (click)="collapseReadMore()"
                              >
                                 {{ lang().ViewLess }}
                              </lim-ui-secondary-button>
                           }
                        </div>
                     </div>
                  }

                  @if (
                     taskData?.checklistID &&
                     data.WOState === "advanced" &&
                     task != undefined
                  ) {
                     <div>
                        @if (taskData?.checklistID && task) {
                           <task-form
                              [data]="taskData"
                              [initialTask]="task"
                              [(workOrderTemplateType)]="WOtype"
                           />
                        }
                     </div>
                  }
               </lim-ui-panel>
            }
         }
         @if (data.WO && data.WOState === "simple") {
            <lim-ui-panel
               [class.has-error]="
                  (!WOname || asset?.assetID === null) &&
                  showErrorValidations &&
                  isPlgSetupWorkOrderEnabled()
               "
               [class.form-control]="isPlgSetupWorkOrderEnabled()"
            >
               <div class="panel-content-container">
                  <div class="name-and-asset-container">
                     <div
                        [class.has-error]="!WOname && showErrorValidations"
                        class="input-group-container"
                     >
                        <label [ngClass]="{ 'control-label': !WOname }"
                           >{{ lang().Name }}
                           <span class="required-symbol">*</span></label
                        >
                        <input
                           type="text"
                           class="work-order-name-input"
                           [(ngModel)]="WOname"
                           (afterEdit)="chkName()"
                           [placeholder]="lang().TryReplaceHVACFilter"
                        />
                     </div>

                     <!-- New flow without Asset modal popup during WO creation  -->
                     @if (isPlgSetupWorkOrderEnabled()) {
                        <div
                           class="input-group-container"
                           [class.has-error]="
                              asset?.assetID === null && showErrorValidations
                           "
                        >
                           <label [class.control-label]="asset?.assetID === null">
                              {{ lang().SelectAnAsset }}
                              @if (isPlgSetupWorkOrderEnabled()) {
                                 <span class="required-symbol"> * </span>
                              }
                           </label>
                           @if (asset?.assetID === 0) {
                              <span class="no-asset-chosen-button">
                                 <lim-ui-icon icon="cubeRegular" iconSize="small" />
                                 <span>{{ lang().NoAssetAssigned }}</span>
                              </span>
                           }
                           @if (asset && asset?.assetID) {
                              <div button id="popAndUpdateAsset">
                                 <span>
                                    <lim-ui-icon
                                       class="asset-name-cube"
                                       icon="cube"
                                       iconSize="small"
                                    />
                                    <span
                                       (click)="popAsset(asset?.assetID)"
                                       class="green-color asset-name"
                                       [limbleHtml]="asset?.assetName"
                                    ></span>
                                    @if (asset) {
                                       <asset-parent-list
                                          [assetID]="asset.assetID"
                                          linkColorClass="green-color"
                                       />
                                    }
                                 </span>
                              </div>
                           }
                           <div>
                              <lim-ui-secondary-button
                                 dataLogLabel="setupWorkOrder-initiateAnAsset"
                                 (click)="updateWOAsset()"
                                 icon="cube"
                              >
                                 @if (
                                    (asset?.assetID === null || asset?.assetID === 0) &&
                                    !hasPredefinedAsset
                                 ) {
                                    <span>{{ lang().AddAsset }} </span>
                                 } @else {
                                    {{ lang().ChangeAsset }}
                                 }
                              </lim-ui-secondary-button>
                           </div>
                           @if (asset && asset?.assetID && !hasPredefinedAsset) {
                              <span class="asset-parent-list"> </span>
                           }
                        </div>
                     }

                     <!-- Current flow with asset modal  -->
                     @if (!isPlgSetupWorkOrderEnabled()) {
                        <div class="input-group-container">
                           <label>{{ lang().Asset }} </label>
                           @if (asset && asset.assetID > 0 && !hasPredefinedAsset) {
                              <span class="asset-parent-list">
                                 <lim-ui-dropdown>
                                    <div button id="popAndUpdateAsset">
                                       <span>
                                          <lim-ui-icon
                                             class="asset-name-cube"
                                             icon="cube"
                                             iconSize="small"
                                          />
                                          <span
                                             class="green-color asset-name"
                                             [limbleHtml]="asset?.assetName"
                                          ></span>
                                       </span>

                                       @if (asset) {
                                          <asset-parent-list
                                             [assetID]="asset.assetID"
                                             linkColorClass="green-color"
                                          />
                                       }
                                    </div>

                                    <div menu aria-labelledby="popAndUpdateAsset">
                                       @if (asset?.assetName) {
                                          <lim-ui-dropdown-text-item
                                             (click)="popAsset(asset?.assetID)"
                                             [displayString]="lang().ViewAsset"
                                          />
                                       }

                                       @if (asset?.assetName) {
                                          <lim-ui-dropdown-text-item
                                             (click)="updateWOAsset()"
                                             [displayString]="lang().ChangeAsset"
                                          />
                                       }
                                    </div>
                                 </lim-ui-dropdown>
                              </span>
                           }

                           @if (
                              ((!asset && !hasPredefinedAsset) ||
                                 (asset && asset.assetID === 0)) &&
                              !isPlgSetupWorkOrderEnabled()
                           ) {
                              <div
                                 class="no-asset-chosen-button"
                                 (click)="updateWOAsset()"
                                 data-test="NoAssetChosen"
                              >
                                 <lim-ui-icon icon="cubeRegular" />
                                 <lim-ui-text-button>
                                    {{ lang().NoAssetChosen }}
                                 </lim-ui-text-button>
                              </div>
                           }
                        </div>
                     }
                  </div>
                  @if (isPlgSetupWorkOrderEnabled()) {
                     <div class="input-group-container">
                        <label>{{ lang().Description }} </label>
                        <div
                           class="updateFieldHolder editUnderline editableDivInCheck description-input input-scroll-bar"
                           [(ngModel)]="taskDescription"
                           limbleContentEditable
                           attr.placeholder="{{ lang().TryFilterNeedsToBeChanged }}"
                           stripHtml="true"
                           data-log="task-descriptionEdit"
                           type="text"
                        ></div>
                     </div>
                  }
                  @if (!isPlgSetupWorkOrderEnabled()) {
                     <div class="input-group-container">
                        <label>{{ lang().Instructions }} </label>
                        <div
                           type="text"
                           [(ngModel)]="WOdescription"
                           limbleContentEditable
                           attr.placeholder="{{ lang().EnterInstructionsHere }}"
                           disableEditor="true"
                           stripHtml="true"
                        ></div>
                     </div>
                  }
               </div>
            </lim-ui-panel>

            @if (isPlgSetupWorkOrderEnabled()) {
               <lim-ui-panel>
                  <div class="instructions-panel">
                     <header>
                        <div class="top">
                           <label>{{ lang().Instructions }}</label>
                        </div>
                     </header>
                     <div class="nested-panels-body">
                        @if (info && task) {
                           <edit-instructions
                              [info]="info"
                              [options]="info.options"
                              [taskInstructions]="info.items"
                              [checklistID]="task.checklistID"
                           />
                        }
                     </div>
                     <div class="add-team-button">
                        <lim-ui-secondary-button
                           [disabled]="!canAddNewInstruction"
                           icon="plusRegular"
                           (click)="addInstruction()"
                        >
                           {{ lang().AddInstruction }}
                        </lim-ui-secondary-button>
                     </div>
                  </div>
               </lim-ui-panel>
            }

            <lim-ui-panel>
               <div class="panel-content-container">
                  <div class="input-group-container">
                     <label>{{ lang().Picture }} </label>
                     @if (uploadObj) {
                        <comment-files
                           [uploadObject]="uploadObj"
                           [files]="files"
                           icon="camera"
                           label="AddPictureDocument"
                           style="min-width: 155px"
                        />
                     }
                  </div>

                  <div class="input-group-container">
                     <label>{{ lang().Priority }} </label>
                     <lim-ui-dropdown>
                        <lim-ui-secondary-button button secondaryIcon="angleDown">
                           <span
                              [ngStyle]="{
                                 color: priorityListIndex[priority.priorityID]
                                    ? priorityListIndex[priority.priorityID].color
                                    : 'var(--lim-el-no-priority-color)',
                              }"
                           >
                              <lim-ui-icon icon="circleExclamation" />

                              {{ priority.priorityLevel }}
                           </span>
                        </lim-ui-secondary-button>
                        <div menu>
                           @for (priority of priorityList; track priority) {
                              <lim-ui-dropdown-item
                                 class="cursor"
                                 (click)="updateTaskPriorityID(priority)"
                              >
                                 {{ priority.priorityLevel }} -
                                 <span [limbleHtml]="priority.name"></span>
                              </lim-ui-dropdown-item>
                           }
                           @if (superUser) {
                              <lim-ui-dropdown-divider />
                           }
                           @if (superUser) {
                              <lim-ui-dropdown-item (click)="editPriorities()">
                                 <div class="priority-button-sub-container">
                                    <lim-ui-icon icon="penToSquare" />
                                    {{ lang().EditPriorities }}
                                 </div>
                              </lim-ui-dropdown-item>
                           }
                        </div>
                     </lim-ui-dropdown>
                  </div>
               </div>
            </lim-ui-panel>
         }

         <lim-ui-panel>
            <div class="input-group-container">
               <div class="panel-content-container">
                  <div class="input-group-container">
                     <label>{{ lang().DueDate }}</label>
                     <div class="input-group-row">
                        <lim-ui-date-picker-input
                           [(model)]="dueDate"
                           [disableMaxWidth]="true"
                        />

                        <time-of-day-picker
                           [timeOfDayNoMargin]="true"
                           [(tod)]="timeOfDay"
                           [(hour)]="hour"
                           [(minute)]="minute"
                           [(ampm)]="AMPM"
                           (click)="timeOfDay = true"
                        />
                     </div>
                  </div>
                  <div class="input-group-container allowOffline">
                     <div class="start-date-label">
                        <label>{{ lang().StartDate }}</label>
                        <i class="optional-text">({{ lang().Optional }})</i>
                     </div>

                     <div class="input-group-row">
                        <lim-ui-date-picker-input
                           [disableMaxWidth]="true"
                           [(model)]="startDate.date"
                           (modelChange)="startDate.opened = true"
                           [showClearIcon]="true"
                           (modelClear)="resetStartDate()"
                           [disabled]="!startDateShow"
                           (click)="initializeStartDate(); startDateShow = true"
                        />
                        <time-of-day-picker
                           [timeOfDayNoMargin]="true"
                           (click)="startDateShow = true; startDate.timeOfDay = true"
                           [(tod)]="startDate.timeOfDay"
                           [(hour)]="startDate.hour"
                           [(minute)]="startDate.minute"
                           [(ampm)]="startDate.AMPM"
                        />
                     </div>
                  </div>
               </div>

               <div class="panel-content-container">
                  @if (data.WO && customerID != 1264) {
                     <div class="input-group-container">
                        <label>{{ lang().Type }}</label>
                        <div class="input-checkbox-group-row">
                           <lim-ui-radio-button
                              groupID="WOTypeGroup"
                              [label]="lang().Unplanned"
                              [checked]="WOtype == 2"
                              (click)="setWoType(2)"
                           />
                           <lim-ui-radio-button
                              groupID="WOTypeGroup"
                              [label]="lang().Planned"
                              [checked]="WOtype == 4"
                              (click)="setWoType(4)"
                           />
                        </div>
                     </div>
                  }
               </div>
            </div>
         </lim-ui-panel>

         <lim-ui-panel
            [ngClass]="{
               'has-error': !hasSelectedUserOrProfile && showErrorValidations,
            }"
            class="form-control"
         >
            @if (showAssignment) {
               <div class="assigned-to-container" [title]="lang().AssignedTo">
                  <div class="title-and-search">
                     <label class="control-label"
                        >{{ lang().AssignedTo }}
                        <span class="required-symbol">*</span></label
                     >
                     <div class="add-user-with-search">
                        @if (canAddUsers && !noSearchResults) {
                           <lim-ui-secondary-button
                              icon="plus"
                              (click)="openAddUserModal()"
                              dataLogLabel="manageUsers-initiateAUser"
                           >
                              {{ lang().AddAUser }}
                           </lim-ui-secondary-button>
                        }
                        @if (allUsers.length >= 5 || allProfiles.length >= 5) {
                           <div heading-options>
                              <div class="panel-options">
                                 <lim-ui-search-box
                                    class="search-box"
                                    [placeholder]="lang().Search"
                                    [(searchVal)]="searchUsersTeams"
                                    (searchValChange)="runUserProfileSearch()"
                                 />
                              </div>
                           </div>
                        }
                     </div>
                  </div>

                  <div class="team-and-user-lists">
                     @if (noSearchResults) {
                        <span class="no-search-results">
                           <no-search-results />
                           <div class="no-search-results-buttons">
                              @if (canAddUsers) {
                                 <lim-ui-primary-button
                                    icon="plus"
                                    (click)="openAddUserModal()"
                                    dataLogLabel="manageUsers-initiateAUser"
                                 >
                                    {{ lang().AddAUser }}
                                 </lim-ui-primary-button>
                              }
                              <lim-ui-secondary-button
                                 icon="plus"
                                 (click)="navigateToProfileManagement()"
                              >
                                 {{ lang().SetUpATeam }}
                              </lim-ui-secondary-button>
                           </div>
                        </span>
                     }
                     @if (!noSearchResults) {
                        <div class="teams">
                           <div class="lim-ui-pick-list-header">
                              <div>
                                 {{ lang().Teams }}
                              </div>
                           </div>
                           @for (
                              profile of profiles | orderBy: "profileDescription";
                              track profile
                           ) {
                              <div
                                 (click)="focusProfile(profile.profileID)"
                                 class="lim-ui-list-item lim-ui-selectable-list-item"
                                 [ngClass]="{
                                    'lim-ui-selected-list-item lim-ui-selected-pick-list-item':
                                       selectedProfile == profile.profileID,
                                 }"
                              >
                                 <div class="lim-ui-list-item-content-group">
                                    @if (selectedProfile == profile.profileID) {
                                       <lim-ui-icon
                                          icon="check"
                                          iconSize="small"
                                          iconColor="primary"
                                       />
                                    }
                                    <div class="lim-ui-icon-with-text">
                                       <lim-ui-block-icon icon="users" />
                                       <span
                                          [limbleHtml]="profile.profileDescription"
                                       ></span>
                                    </div>
                                 </div>
                              </div>
                           }
                        </div>
                     }
                     @if (!noSearchResults) {
                        <div class="users">
                           <div class="lim-ui-pick-list-header">
                              <div>
                                 {{ lang().Users }}
                              </div>
                           </div>
                           @for (user of users | orderBy: "userLastName"; track user) {
                              <div
                                 (click)="focusUser(user)"
                                 class="lim-ui-list-item lim-ui-selectable-list-item"
                                 [ngClass]="{
                                    'lim-ui-selected-list-item': user.selected == true,
                                    'lim-ui-hinted-list-item': user.hint,
                                    'lim-ui-warning-list-item': user.extra,
                                 }"
                              >
                                 <div class="lim-ui-list-item-content-group">
                                    @if (user.selected == true) {
                                       <lim-ui-icon
                                          icon="check"
                                          iconSize="small"
                                          [iconColor]="user.extra ? 'danger' : 'primary'"
                                       />
                                    }
                                    <div class="lim-ui-icon-with-text">
                                       <user-image [user]="user" />
                                       <span>
                                          {{ user.userFirstName }} {{ user.userLastName }}
                                       </span>
                                    </div>
                                    @if (user.tooltipText && user.tooltipText.length) {
                                       <lim-ui-icon
                                          icon="circleQuestion"
                                          iconSize="small"
                                          limUiTooltip="{{ user.tooltipText }}"
                                          placement="top"
                                       />
                                    }
                                 </div>
                              </div>
                           }
                           <div
                              (click)="focusUnassigned()"
                              class="lim-ui-list-item lim-ui-selectable-list-item lim-ui-warning-list-item"
                              [ngClass]="{
                                 'lim-ui-selected-list-item': selectedUnassigned,
                              }"
                           >
                              <div class="lim-ui-list-item-content-group">
                                 @if (selectedUnassigned) {
                                    <lim-ui-icon
                                       icon="check"
                                       iconSize="small"
                                       iconColor="danger"
                                    />
                                 }
                                 <div class="lim-ui-icon-with-text">
                                    <lim-ui-block-icon icon="user" />
                                    <span>{{ lang().Unassigned }}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     }
                  </div>

                  @if (manageProfileCred && !noSearchResults) {
                     <div class="add-team-button">
                        <lim-ui-outlined-button
                           icon="plus"
                           (click)="navigateToProfileManagement()"
                        >
                           {{ lang().SetUpATeam }}
                        </lim-ui-outlined-button>
                     </div>
                  }

                  @if (!noSearchResults && usersLocked) {
                     <div class="panel-content-container">
                        <div
                           class="alert alert-info"
                           [limbleHtml]="lang().OwnerSelectionLockedDescription"
                        ></div>
                     </div>
                  }

                  <ng-container panel-footer>
                     <div class="panel-footer">
                        @if (!noSearchResults && usersLocked) {
                           <div class="advanced-options">
                              <div
                                 class="alert alert-info"
                                 [limbleHtml]="lang().OwnerSelectionLockedDescription"
                              ></div>
                           </div>
                        }
                     </div>
                  </ng-container>
               </div>
            }
         </lim-ui-panel>
      </lim-ui-modal-body>
   }
   <lim-ui-basic-modal-footer
      dataLogSubmitLabel="manageWOs-storeAWorkOrder"
      [successButtonText]="lang().Create"
      (successClick)="submit()"
   />
</lim-ui-modal>
