import { Injectable, inject } from "@angular/core";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { Lookup } from "src/app/shared/utils/lookup";
import type { TaskAsset } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";

@Injectable({
   providedIn: "root",
})
export class AssetHierarchyListItemService {
   private readonly manageAsset = inject(ManageAsset);

   public buildHierarchicalAsset(baseAsset: TaskAsset): TaskAsset | undefined {
      if (!baseAsset) return undefined;

      const parentInfo = this.manageAsset.buildParentInfo(baseAsset.assetID);
      return this.buildHierarchyFromParentInfo(baseAsset, parentInfo);
   }

   private buildHierarchyFromParentInfo(
      baseAsset: TaskAsset,
      parentInfo: Lookup<
         "assetID",
         { assetID: number; assetName: string | null; parentAssetID: number }
      >,
   ): TaskAsset {
      let current: TaskAsset | undefined = undefined;

      const sortedParents = Array.from(
         (parentInfo ?? new Lookup("assetID")).values(),
      ).sort((a, b) => (a.parentAssetID || 0) - (b.parentAssetID || 0));

      for (const parent of sortedParents) {
         current = this.createHierarchyNode(
            {
               assetID: parent.assetID,
               assetName: parent.assetName,
               locationID: baseAsset.locationID,
               parentAssetID: parent.parentAssetID,
            },
            current,
         );
      }

      return this.createHierarchyNode(baseAsset, current);
   }

   private createHierarchyNode(
      asset: Partial<Asset> & { assetID: number; assetName: string | null },
      parent: TaskAsset | undefined = undefined,
   ): TaskAsset {
      return {
         assetID: asset.assetID,
         assetName: asset.assetName ?? "",
         locationID: asset.locationID ?? 0,
         parentAssetID: asset.parentAssetID ?? null,
         parent: parent ?? undefined,
      } as unknown as TaskAsset;
   }
}
