<div class="recurrence-wrapper">
   @if (template().checklistTemplate === 1 && !restrictBasedOnAsset()) {
      <lim-ui-icon
         [icon]="!recurrenceHasSchedule() ? 'calendarXmark' : 'calendarCheck'"
         [iconColor]="!recurrenceHasSchedule() ? 'danger' : 'success'"
         placement="top"
      />
   }
   @let recurrenceType = recurrence().reoccurType;
   @if (recurrenceType) {
      <span class="text blue" data-test-id="recurrenceFrequencyDisplay">
         @if (recurrenceType === 1) {
            <span>
               {{ recurrenceFrequencyDisplay() }}
            </span>
         }

         @if (recurrenceType === 2) {
            <span>
               {{ recurrenceFrequencyDisplay() }}
               {{ lang().onStr }}
               @for (
                  day of getDaysOfWeekFromRecurrence();
                  track day;
                  let isLast = $last
               ) {
                  <span>
                     <span
                        >{{ day }}
                        @if (!isLast) {
                           <span>, </span>
                        }
                     </span>
                  </span>
               }
            </span>
         }

         @if (recurrenceType === 3) {
            <span>
               {{ recurrenceFrequencyDisplay() }}
               {{ lang().onThe }}
               {{ recurrence().reoccurFld2 | addDateEnding }}
            </span>
         }

         @if (recurrenceType === 4) {
            <span>
               {{ recurrenceFrequencyDisplay() }}
               {{ lang().onThe }}
               {{ recurrence().reoccurFld2 | firstSecondEtcToMultiLanguage }}
               {{ recurrence().reoccurFld3 | fullDay | capitalize }}
            </span>
         }

         @if (recurrenceType === 5) {
            <span>
               {{ recurrenceFrequencyDisplay() }}
               {{ lang().inStr }}
               {{ recurrence().reoccurFld2 | monthNumericToString }}
               {{ lang().onThe }}
               {{ recurrence().reoccurFld3 | addDateEnding }}
            </span>
         }

         @if (recurrenceType === 6) {
            <span>
               {{ recurrenceFrequencyDisplay() }}
               {{ lang().onThe }}
               {{ recurrence().reoccurFld2 | firstSecondEtcToMultiLanguage }}
               {{ recurrence().reoccurFld3 | fullDay }}
               {{ lang().ofStr }}
               {{ recurrence().reoccurFld4 | fullMonth }}
            </span>
         }

         @if (recurrenceType === 7) {
            <span>
               {{ lang().Every }} {{ recurrence().reoccurFld2 }} {{ fieldName() }} |

               @if (!showExtraAssets()) {
                  <span>
                     <span
                        limUiTooltip="{{ lang().NextCreatedAtTooltip }}"
                        data-placement="bottom"
                     >
                        {{ getNextCreatedAtDisplay() }}
                     </span>
                     @if (taskCurrentlyOpen()) {
                        <span
                           class="cursor"
                           (click)="popTask(); $event.stopPropagation()"
                        >
                           {{ lang().PMIsCurrentlyOpen }}
                        </span>
                     }
                  </span>
               }

               <span
                  limUiTooltip="{{ lang().NextCreatedAtTooltip }}"
                  data-placement="bottom"
                  [hidden]="!showExtraAssets()"
                  (click)="popMultipleType7Schedules(); $event.stopPropagation()"
               >
                  {{ lang().NextCreatedAt }}...
               </span>
            </span>
         }

         @if (recurrenceType === 8) {
            <span>
               {{ getThresholdDisplay() }}
            </span>
            @if (taskCurrentlyOpen()) {
               <span class="cursor" (click)="popTask(); $event.stopPropagation()">
                  | {{ lang().PMIsCurrentlyOpen }}
               </span>
            }
         }

         <span
            limUiTooltip="{{ lang().tempNextDueOnHint }}"
            data-placement="bottom"
            (click)="showSingleSchedules(); $event.stopPropagation()"
         >
            {{ nextDueDisplay() ?? "" }}
         </span>

         <!-- This shows them what time of day it will occur on -->
         @if (
            recurrenceType !== 7 &&
            recurrenceType !== 8 &&
            recurrenceType !== null &&
            recurrence().reoccurTimeOfDay
         ) {
            <span>
               {{ lang().at }}
               <span
                  >{{ recurrence().reoccurTimeOfDay | betterDate: "time" }}
                  {{ timezone() }}</span
               >
            </span>
         }
      </span>
   }
</div>
