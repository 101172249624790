import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
   signal,
   type Signal,
} from "@angular/core";
import {
   AlertComponent,
   IconComponent,
   LimUiModalRef,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   ScrollContainerComponent,
   type ModalResult,
} from "@limblecmms/lim-ui";
import {
   NgxSkeletonLoaderComponent,
   type NgxSkeletonLoaderConfigTheme,
} from "ngx-skeleton-loader";
import type { AssetFieldValueFile } from "src/app/assets/types/field/value/file/file.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { AiModalComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-modal/ai-modal.component";
import { SelectDocumentsListComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel-body/select-documents-modal/select-documents-list/select-documents-list.component";
import { PmSuggestionsAssetDocuments } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/asset-documents/asset-documents";

type DocumentIDs = Set<number>;

export type SelectDocumentsModalResult = "back" | DocumentIDs | undefined;

@Component({
   selector: "select-documents-modal",
   templateUrl: "./select-documents-modal.component.html",
   styleUrls: ["./select-documents-modal.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      AiModalComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
      IconComponent,
      SelectDocumentsListComponent,
      PanelComponent,
      NgxSkeletonLoaderComponent,
      AlertComponent,
      ScrollContainerComponent,
   ],
})
export class SelectDocumentsModalComponent
   implements ModalResult<SelectDocumentsModalResult>
{
   public readonly modalRef: LimUiModalRef<
      SelectDocumentsModalComponent,
      SelectDocumentsModalResult
   > = inject(LimUiModalRef);

   public readonly assetIDs = input.required<Array<number>>();

   public hardLengthLimit = 250;
   public softLengthLimit = 25;

   private readonly allDocuments = inject(PmSuggestionsAssetDocuments).documents;

   public readonly documentsByAssetID: Signal<
      LimbleMap<number, Array<AssetFieldValueFile>> | undefined
   > = computed(() => {
      const allDocuments = this.allDocuments.value();
      if (allDocuments === undefined) {
         return undefined;
      }
      return new LimbleMap(
         [...allDocuments.entries()].filter(([assetID]) =>
            this.assetIDs().includes(assetID),
         ),
      );
   });

   public readonly documentCount: Signal<number> = computed(() => {
      const documentsByAssetID = this.documentsByAssetID();
      if (documentsByAssetID === undefined) {
         return 0;
      }
      return [...documentsByAssetID].flat().length;
   });

   private readonly softLimitBypassedInternal = signal(false);

   public readonly softLimitBypassed = this.softLimitBypassedInternal.asReadonly();

   public readonly selectedDocuments = signal<DocumentIDs>(new Set<number>());

   protected readonly listItemSkeletonTheme: NgxSkeletonLoaderConfigTheme = {
      width: "100%",
      height: "24px",
      border: "0",
      margin: "0",
   };

   protected readonly listOptionsSkeletonTheme: NgxSkeletonLoaderConfigTheme = {
      width: "100px",
      maxWidth: "100%",
      height: "16px",
      border: "0",
      margin: "0",
   };

   public async close(): Promise<void> {
      return this.modalRef.close(undefined);
   }

   public async back(): Promise<void> {
      return this.modalRef.close("back");
   }

   public async submit(): Promise<void> {
      if (this.selectedDocuments().size === 0) return;
      return this.modalRef.close(this.selectedDocuments());
   }

   public bypassSoftLimit(): void {
      this.softLimitBypassedInternal.set(true);
   }
}
