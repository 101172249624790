<lim-ui-scroll-container [thin]="true" class="scroll-container" limUiScrollWhileDragging>
   <div #hierarchyItemContainer></div>

   @if (totalNumberOfParentNodes > numberOfItemsRendered) {
      <lim-ui-text-button
         text="{{ lang().Show }} {{ additionalItemsToRender }} {{ lang().more }}"
         (click)="renderNextBatchOfHierarchyItems()"
      />
      <span class="number-of-items-rendered">
         ({{ numberOfItemsRendered }}/{{ totalNumberOfParentNodes }})
      </span>
   }
</lim-ui-scroll-container>
