<span class="lim-ui-mobile-table-column-label">{{ lang().Completed }}: </span>
@if (task.finalColorStatus !== undefined && task.finalColorStatus == 1) {
   <span class="greenColor">{{ lang().Green }}</span>
}
@if (task.finalColorStatus !== undefined && task.finalColorStatus == 2) {
   <span class="warning">{{ lang().Orange }}</span>
}
@if (task.finalColorStatus !== undefined && task.finalColorStatus == 3) {
   <span class="danger">{{ lang().Red }}</span>
}
