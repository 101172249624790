<lim-ui-scroll-container>
   <div class="step-body">
      <pick-user-or-profile
         [message]="i18n().t('ChangeTheTasksAssignmentMsg')"
         [locationID]="locationID()"
         [extraUsers]="extraUsersOptions"
         [defaultUser]="-101"
         (selection)="selection.set($event)"
      />
   </div>
</lim-ui-scroll-container>
<div class="step-footer">
   <lim-ui-outlined-button color="red" (click)="deleteTemplate.emit()">
      {{ i18n().t("DeleteTemplate") }}
   </lim-ui-outlined-button>
   <div class="navigation">
      <lim-ui-secondary-button (click)="prevStep.emit()">
         {{ i18n().t("Back") + ": " + i18n().t("Schedules") }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button (click)="saveAndContinue()" [disabled]="noSelectionMade()">
         {{ i18n().t("Next") + ": " + i18n().t("Preview") }}
      </lim-ui-primary-button>
   </div>
</div>
