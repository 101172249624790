<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message-area" [limbleHtml]="message"></span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="modal-body">
            <div class="row">
               <div class="col-xs-12">
                  <data-viewer-search
                     class="searchBar"
                     style="position: relative; top: -13px"
                     (searchChange)="onSearchChange($event)"
                  />

                  @if (viewModel$ | async; as vm) {
                     <div class="dd mt-3" id="nestable_list_1">
                        {{ lang().PMs }}:

                        @for (task of vm.data; track task) {
                           <div
                              (click)="selectTask(task)"
                              class="lim-ui-list-item lim-ui-selectable-list-item"
                              [ngClass]="{
                                 'lim-ui-selected-list-item': selectedTask == task,
                              }"
                           >
                              @if (selectedTask == task) {
                                 <lim-ui-icon icon="check" />
                              }
                              <span [limbleHtml]="task.checklistName"></span>
                              @if (task.assetID && task.assetID > 0) {
                                 <span>
                                    -
                                    <a
                                       [limbleHtml]="
                                          allAssets?.get(task.assetID)?.assetName
                                       "
                                       class="greenColor"
                                    ></a>

                                    @if (task.assetID) {
                                       <asset-parent-list
                                          [assetID]="task.assetID"
                                          linkColorClass="green-color"
                                       />
                                    }
                                 </span>
                              }
                           </div>
                        }
                        <!-- TODO: convert this to a component
                             Work on it on https://limble.atlassian.net/browse/CMMS-1836  -->
                        @if (vm.hasMoreItems) {
                           <lim-ui-text-button
                              text="{{ lang().Show }} {{ vm.loadableItems }} {{
                                 lang().more
                              }}"
                              (click)="loadMoreItems()"
                           />
                           <span class="number-of-items-rendered">
                              ({{ vm.itemsDisplayed }}/{{ vm.total }})
                           </span>
                        }
                     </div>

                     @if (vm.hasNoItems) {
                        <no-search-results />
                     }
                  }
               </div>
            </div>
            <br />
            <div
               class="alert alert-dismissable alert-warning"
               [hidden]="errorMsg === undefined"
               style="margin-bottom: 0px"
            >
               <strong class="mr-1">{{ lang().Warning }}!</strong> - {{ errorMsg }}
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Select }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
