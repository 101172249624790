import { inject, Injectable } from "@angular/core";
import { ModalService, type LimUiModalRef } from "@limblecmms/lim-ui";
import type { WorkRequestSubmissionPostRequestBody } from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.models";
import { WorkRequestSubmissionsApiService } from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.service";
import { DeclineWorkRequestModalComponent } from "src/app/tasks/components/workRequests/decline-work-request-modal/decline-work-request-modal.component";
import type { DeclineWorkRequestModalViewModel } from "src/app/tasks/components/workRequests/decline-work-request-modal/decline-work-request-modal.types";
import { WorkRequestSubmissionModalComponent } from "src/app/tasks/components/workRequests/work-request-submission-modal/work-request-submission-modal.component";
import { WorkRequestsStore } from "src/app/tasks/stores/work-requests/work-requests.store";

@Injectable({
   providedIn: "root",
})
export class WorkRequestsFacadeService {
   private readonly workRequestsStore = inject(WorkRequestsStore);
   private readonly modalService = inject(ModalService);
   private readonly workRequestSubmissionsApiService = inject(
      WorkRequestSubmissionsApiService,
   );
   /**
    * TODO: feel free to change function name to a more specific event
    */
   public onRequestInboxInit() {
      const requests = []; // TODO: fetch list from API
      this.workRequestsStore.onLoadRequests(requests);
   }

   public async declineWorkRequest(
      requestID: number,
      reason: string,
      message: string,
   ): Promise<boolean> {
      const success = await this.workRequestSubmissionsApiService.decline(
         requestID,
         reason,
         message,
      );
      if (success) {
         // TODO: make sure the request page is refreshed
      }
      return success;
   }

   public async approveWorkRequest(requestID: number): Promise<boolean> {
      const success = await this.workRequestSubmissionsApiService.approve(requestID);
      if (success) {
         // TODO: make sure the request page is refreshed
      }
      return success;
   }

   public async deleteWorkRequest(requestID: number): Promise<boolean> {
      //TODO: implemenent this
      return Promise.resolve(Boolean(requestID));
   }

   public openWorkRequestSubmissionModal(workRequestSubmissionID: number) {
      const modalRef = this.modalService.open(WorkRequestSubmissionModalComponent);
      modalRef.setInput("workRequestSubmissionID", workRequestSubmissionID);
   }

   public openDeclineWorkRequestModal(
      workRequestSubmissionID: number,
      workRequestTitle: string,
      locationID: number,
   ): LimUiModalRef<DeclineWorkRequestModalComponent> {
      const modalRef = this.modalService.open(DeclineWorkRequestModalComponent);
      const declineWorkRequestModalViewModel: DeclineWorkRequestModalViewModel = {
         workRequestSubmissionID,
         workRequestTitle,
         locationID,
      };
      modalRef.setInput(
         "declineWorkRequestModalViewModel",
         declineWorkRequestModalViewModel,
      );
      return modalRef;
   }

   public async submitWorkRequestSubmission(
      workRequestSubmission: WorkRequestSubmissionPostRequestBody,
   ) {
      try {
         const response =
            await this.workRequestSubmissionsApiService.post(workRequestSubmission);

         // TODO: We might want to return this, and then we can show the user their submitted response easily.
         console.warn(response);
         return true;
      } catch (error) {
         console.error(error);
         return false;
      }
   }
}
