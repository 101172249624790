import { Component, Input } from "@angular/core";
import type { Column } from "src/app/shared/data-viewer/column-builder";

@Component({
   selector: "base-cell-renderer",
   template: "",
})
export class BaseCellRendererComponent<T = any> {
   /** The data to display in the cell */
   @Input() data: any;

   /** The column related to the cell*/
   @Input() column!: Column;

   /** The data log section related to the cell*/
   @Input() dataLogSection = "";

   protected get cell(): T {
      return this.data as T;
   }
}
