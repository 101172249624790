import { inject, Injectable, resource } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { firstValueFrom, map, mergeWith, switchMap } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { PmSuggestionsAssetDocumentsInterface } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/asset-documents/asset-documents";

@Injectable({ providedIn: "root" })
export class AssetDocumentsFromManageAssetService
   implements PmSuggestionsAssetDocumentsInterface
{
   private readonly manageAsset = inject(ManageAsset);

   private readonly documents$ = this.manageAsset.assetsLoaded().pipe(
      takeUntilDestroyed(),
      mergeWith(this.manageAsset.assetChanges()),
      switchMap(() => this.manageAsset.assetState()),
      map((assets) =>
         assets
            .map(([assetID, asset]) => [
               assetID,
               [...this.manageAsset.getFieldValues()]
                  .filter((fieldValue) =>
                     asset.assetValueIDs.includes(fieldValue.valueID),
                  )
                  .map((fieldValue) => fieldValue.assetValueFileIDs)
                  .filter((fileIDs) => fileIDs !== undefined)
                  .map((fileIDs) => [
                     ...this.manageAsset
                        .getFieldValueFiles()
                        .filter((file) => fileIDs.includes(file.fileID)),
                  ])
                  .flat(),
            ])
            .filter((documents) => documents.length > 0),
      ),
   );

   public readonly documents = resource({
      loader: async () => {
         return firstValueFrom(this.documents$);
      },
   }).asReadonly();
}
