<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="video-wrapper">
            <iframe
               [src]="src"
               frameborder="0"
               allow="autoplay; encrypted-media"
               allowfullscreen
            ></iframe>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
