import { HttpClient } from "@angular/common/http";
import type { OnDestroy } from "@angular/core";
import { computed, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   LimUiDateAdapter,
   LimUiDateParserFormatter,
   ModalService,
} from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import $ from "jquery";
import type { Moment } from "moment";
import moment from "moment";
import {
   BehaviorSubject,
   combineLatestWith,
   EMPTY,
   expand,
   filter,
   firstValueFrom,
   from,
   map,
   type Observable,
   reduce,
   shareReplay,
   Subject,
   take,
   tap,
} from "rxjs";
import { PopAsset } from "src/app/assets/components//popAssetModal/popAsset.modal.component";
import type { StoreEvent } from "src/app/assets/mutable-store/mutable-store";
import { MutableStore } from "src/app/assets/mutable-store/mutable-store";
import { AssetFieldScopeType } from "src/app/assets/services/asset-field.types";
import { AssetTemplateFieldService } from "src/app/assets/services/asset-template-field.service";
import {
   AssetTemplateSettingsService,
   type AssetTemplateAccountSettings,
} from "src/app/assets/services/asset-template-settings.service";
import { adaptLumberyardAssetToStateAsset } from "src/app/assets/services/lumberyard-asset-adapter";
import type { Asset } from "src/app/assets/types/asset.types";
import type { DepreciationSchedule } from "src/app/assets/types/depreciation-schedules/depreciation-schedule.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldType } from "src/app/assets/types/field/type/asset-field-type.types";
import type { AssetFieldValueFile } from "src/app/assets/types/field/value/file/file.types";
import type {
   AssetFieldValue,
   AssetFieldValueWithContent,
} from "src/app/assets/types/field/value/value.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AssetStorageSyncService } from "src/app/lite/local-db/resources/collection/asset/asset.storage.sync.service";
import { AssetFieldStorageSyncService } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.storage.sync.service";
import { AssetFieldValueStorageSyncService } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.storage.sync.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { ManageParts } from "src/app/parts/services/manageParts";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { Flags } from "src/app/shared/services/launch-flags/launch-flags.models";
import { LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags/legacy-launch-flags.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { DepreciationOptions, UploadObj } from "src/app/shared/types/general.types";
import type { NotUndefined } from "src/app/shared/types/utility-types";
import { b64toBlob, cleanWordPaste } from "src/app/shared/utils/app.util";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services//manageUser";
import type { ManageVendor } from "src/app/vendors/services/manageVendor";
import { environment } from "src/environments/environment";
import reservedNames from "src/root/phpscripts/reservedNames.json";

const msSaveOrOpenBlob = (window.navigator as any).msSaveOrOpenBlob;

@Injectable({ providedIn: "root" })
export class ManageAsset implements OnDestroy {
   public tileFieldsObs$ = new Subject<null>();
   public dateReminderStreamMap: Map<number, Subject<null>> = new Map();
   private readonly assetStore: MutableStore<"assetID", Asset> = new MutableStore(
      "assetID",
   );
   private readonly fieldStore: MutableStore<"fieldID", AssetField> = new MutableStore(
      "fieldID",
   );
   private readonly fieldValueStore: MutableStore<"valueID", AssetFieldValue> =
      new MutableStore("valueID");
   private fieldValueFiles: Lookup<"fileID", AssetFieldValueFile> = new Lookup("fileID");
   private fieldTypes: Lookup<"fieldTypeID", AssetFieldType> = new Lookup("fieldTypeID");
   private depreciationSchedules: Lookup<"depreciationID", DepreciationSchedule> =
      new Lookup("depreciationID");
   private readonly assetsLoaded$ = new BehaviorSubject<boolean>(false);

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLang = inject(ManageLang);
   private readonly manageUtil = inject(ManageUtil);
   private readonly alertService = inject(AlertService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly betterDate = inject(BetterDate);
   private readonly http = inject(HttpClient);
   private readonly dateParserFormatter = inject(LimUiDateParserFormatter);
   private readonly dateAdapter = inject(LimUiDateAdapter);
   private readonly manageUser = inject(ManageUser);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly taskTemplateApiService = inject(TaskTemplatesApiService);
   private readonly assetStorageSyncService = inject(AssetStorageSyncService);
   private readonly assetFieldStorageSyncService = inject(AssetFieldStorageSyncService);
   private readonly assetFieldValueStorageSyncService = inject(
      AssetFieldValueStorageSyncService,
   );
   private readonly assetTemplateSettingsService = inject(AssetTemplateSettingsService);
   private readonly assetTemplateFieldService = inject(AssetTemplateFieldService);
   private readonly featureFlagService = inject(FeatureFlagService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly baseUrl: string = `${environment.servicesURL()}/assets/`;

   private readonly isMp4Enabled = toSignal(
      from(this.legacyLaunchFlagsService.isEnabled(Flags.MP4)),
   );

   public ngOnDestroy(): void {
      this.assetStore.destroy();
      this.fieldStore.destroy();
      this.fieldValueStore.destroy();
      this.tileFieldsObs$.complete();
   }

   /** @deprecated */
   public emitGeneralAssetUpdate(): void {
      this.assetStore.emitGeneral();
   }

   /** @deprecated */
   public emitGeneralFieldValueUpdate(): void {
      this.fieldValueStore.emitGeneral();
   }

   public assetChanges(): Observable<StoreEvent<Asset>> {
      return this.assetStore.changes();
   }

   public assetState(): Observable<Lookup<"assetID", Asset>> {
      return this.assetStore.state();
   }

   /**
    * Same as assetState(), but the returned observable only starts emitting after
    * all of the assets, fields, etc are downloaded and processed.
    */
   public assetStateAfterLoad(): Observable<Lookup<"assetID", Asset>> {
      return this.assetStore.state().pipe(
         combineLatestWith(this.assetsLoaded()),
         map(([assets]) => assets),
      );
   }

   public fieldChanges(): Observable<StoreEvent<AssetField>> {
      return this.fieldStore.changes();
   }

   public fieldState(): Observable<Lookup<"fieldID", AssetField>> {
      return this.fieldStore.state();
   }

   public fieldValueChanges(): Observable<StoreEvent<AssetFieldValue>> {
      return this.fieldValueStore.changes();
   }

   public fieldValueState(): Observable<Lookup<"valueID", AssetFieldValue>> {
      return this.fieldValueStore.state();
   }

   public assetLookup(): Lookup<"assetID", Asset> {
      return this.assetStore.lookup();
   }

   public updateStore(asset: Asset): void {
      this.assetStore.update(asset);
   }

   public async updateAssetFieldChildSetting(
      fieldID: number,
      fieldUpdateChildrenOption: "replace" | "increment" | "ignore",
   ): Promise<AxiosResponse> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetFieldChildrenSetting",
         },
         data: {
            fieldID: fieldID,
            assetFieldChildrenOption: fieldUpdateChildrenOption,
         },
      });
      post.then(() => {
         this.emitGeneralAssetUpdate();
         this.updateAssetChildrenIDs();
      });
      return post;
   }

   public findChildrenIDs(asset: Asset, returnArr: Array<number>): Array<number> {
      for (const assetID of asset.assetChildrenIDs) {
         //finding a child that isn't deleted
         const pulledAsset = this.getAsset(assetID);
         assert(pulledAsset);
         if (pulledAsset.assetDeleted == 0) {
            returnArr.push(assetID);
            this.findChildrenIDs(pulledAsset, returnArr); //we also need to check the child's children etc.
         }
      }
      return returnArr;
   }

   public updateAssetChildrenIDs(): void {
      for (const asset of this.assetStore.lookup()) {
         asset.assetChildrenIDs = [];
      }

      //it is very important that these are split up.  This is due to the fact that we must have assetChildrenIDs build
      // before hand and each time we reset it - it needs to be started from scratch
      for (const asset of this.assetStore.lookup()) {
         if (asset.parentAssetID) {
            const parentAsset = this.getAsset(asset.parentAssetID);
            parentAsset?.assetChildrenIDs.push(asset.assetID);
         }
      }
   }

   private async bulkChangeAssetLocation(
      assetsToUpdate: Array<number>,
      locationID: number,
      transferParts: boolean,
   ): Promise<AxiosResponse> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "bulkChangeAssetLocation",
         },
         data: {
            assets: assetsToUpdate,
            locationID: locationID,
            transferParts,
         },
      });

      post.then(() => {
         this.emitGeneralAssetUpdate();
      });

      return post;
   }

   public setUploadObject(fieldTypeID: number, valueID: number, asset: Asset): UploadObj {
      const uploadCall = async (url, data) => {
         return axios({
            method: "POST",
            url,
            data,
         });
      };

      const uploadComplete = this.uploadComplete.bind(this);
      const errorHandler = this.uploadErrorHandler.bind(this);

      const uploadObj: UploadObj = {
         posturl: `phpscripts/manageAsset.php?action=makeFile&locationID=${asset.locationID}&assetID=${asset.assetID}&valueID=${valueID}`,
         primaryID: "fileName",
         uploadTypes: fieldTypeID === 3 ? "images" : "documents",
         viewOnly: false,
         noPreview: true,
         onUploadPopEditor: false,
         uploadCall,
         uploadComplete,
         errorHandler,
      };
      return uploadObj;
   }

   public uploadErrorHandler(errorMsg: string): void {
      setTimeout(() => {
         this.alertService.addAlert(errorMsg, "danger", 5000);
      }, 100); //need to go from the root scope here. The alert is on a scope sometimes higher than the component's parent scope
   }

   public uploadComplete(data): void {
      if (data.failed) {
         $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
      } else {
         $("#status").html(
            `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
         );
      }

      if (!data.fileID) {
         $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         return;
      }

      this.fieldValueFiles.set(data.fileID, data.row);
      const value = this.fieldValueStore.get(data.row.valueID);

      if (!value) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      if (!value?.assetValueFileIDs) {
         value.assetValueFileIDs = [];
      }

      value.assetValueFileIDs.push(data.fileID);
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   public addFileToSource(
      data,
      fieldValue: AssetFieldValue,
      cid?: number,
      asset?: Asset,
   ): void {
      const regex = /(?:\.([^.]+))?$/;
      data.ext = regex.exec(data.fileName)?.[1];

      if (data.fileDescription == null || data.fileDescription == "") {
         data.fileDescription = data.fileName;
      }

      if (fieldValue.assetValueFileIDs === undefined) {
         fieldValue.assetValueFileIDs = [];
      }

      if (cid && asset) {
         data.getURL = `viewFile.php?f=upload-${cid}/assets/${asset.locationID}/${asset.assetID}/${fieldValue.valueID}/${data.fileName}`;
         data.parentRef = "valueID";
      }
      this.fieldValueFiles.set(data.fileID, data);
      fieldValue.assetValueFileIDs.push(data.fileID);
   }

   public async getData(): Promise<void> {
      await Promise.all([
         this.fetchAssets(),
         this.fetchFields(),
         this.fetchFieldValueFiles(),
         this.fetchFieldTypes(),
      ]);
      await Promise.all([
         this.fetchDepreciationSchedules(),
         this.getPaginatedAssetFieldValues(),
      ]);
      this.assetsLoaded$.next(true);
   }

   public async fetchAssets(): Promise<void> {
      let page = 1;
      const limit = 50000;
      const assets$ = from(this.getFlannelAssets(page, limit)).pipe(
         expand((response): Promise<AxiosResponse<Array<any>>> | Observable<never> => {
            page += 1;
            const responseBelowLimit = response.data.length < limit;
            if (responseBelowLimit) {
               return EMPTY;
            }
            return this.getFlannelAssets(page, limit);
         }),
         map((val) => val.data),
         reduce((acc, cur) => {
            // eslint-disable-next-line typescript/prefer-for-of -- because the for loop is faster for this performance sensitive action
            for (let i = 0; i < cur.length; i++) {
               acc.push(cur[i]);
            }
            return acc;
         }),
      );
      const assets = await firstValueFrom(assets$);
      this.assetStore.reset(assets);
   }

   private async getFlannelAssets(
      page: number,
      limit: number,
   ): Promise<AxiosResponse<Array<any>>> {
      const startTime = Math.floor(Date.now());
      const response = await axios.get(`${environment.flannelUrl}/assets`, {
         params: { page, limit },
      });
      logApiPerformance("assets", startTime, this.manageUser.getCurrentUser(), response);
      for (const asset of response.data) {
         asset.geoLocation = JSON.parse(asset.geoLocation);
      }
      return response;
   }

   public async fetchFields(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/assets/fields`);

      logApiPerformance(
         "assetsFields",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      const fields = new Lookup<"fieldID", AssetField>("fieldID", response.data);
      for (const field of fields) {
         if (field.fieldTypeID === 2) {
            this.dateReminderStreamMap.set(field.fieldID, new Subject());
         }
      }
      this.fieldStore.reset(fields);
   }

   public async fetchFieldValueFiles(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(
         `${environment.flannelUrl}/assets/fields/values/files`,
      );

      logApiPerformance(
         "assetsFieldsValuesFiles",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.fieldValueFiles = new Lookup("fileID", response.data);
   }

   public async fetchFieldTypes(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/assets/fields/types`);

      logApiPerformance(
         "assetsFieldsTypes",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.fieldTypes = new Lookup("fieldTypeID", response.data);
   }

   public async fetchDepreciationSchedules(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(
         `${environment.flannelUrl}/assets/depreciationSchedules`,
      );

      logApiPerformance(
         "assetsDepreciationSchedules",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.depreciationSchedules = new Lookup("depreciationID", response.data);
   }

   private async getPaginatedAssetFieldValues(
      valueLastEdited: number = 0,
   ): Promise<void> {
      let page = 1;
      const limit = 250000;
      const assetFieldValues$ = from(
         this.getFlannelAssetFieldValues(page, limit, valueLastEdited),
      ).pipe(
         expand((response): Promise<AxiosResponse<Array<any>>> | Observable<never> => {
            page += 1;
            const responseBelowLimit = response.data.length < limit;
            if (responseBelowLimit) {
               return EMPTY;
            }
            return this.getFlannelAssetFieldValues(page, limit);
         }),
         map((val) => val.data),
         reduce((acc, cur) => {
            // eslint-disable-next-line typescript/prefer-for-of -- because the for loop is faster for this performance sensitive action
            for (let i = 0; i < cur.length; i++) {
               acc.push(cur[i]);
            }
            return acc;
         }),
      );
      const assetFieldValues = await firstValueFrom(assetFieldValues$);
      this.fieldValueStore.reset(assetFieldValues);
   }

   private async getFlannelAssetFieldValues(
      page: number,
      limit: number,
      valueLastEdited: number = 0,
   ): Promise<AxiosResponse<Array<any>>> {
      const response = await axios.get(`${environment.flannelUrl}/assets/fields/values`, {
         params: { valueLastEdited: valueLastEdited, page, limit },
      });

      return response;
   }

   /**
    * @returns
    * an observable that emits if/when the assets, fields, etc have all
    * been downloaded and processed. Emits exactly once for all subscribers.
    */
   public assetsLoaded(): Observable<void> {
      return this.assetsLoaded$.pipe(
         filter((loaded) => loaded === true),
         take(1),
         map(() => undefined),
         shareReplay(1),
      );
   }

   /**
    * @returns
    * true if the assets, fields, etc have all been downloaded and processed,
    * false otherwise.
    */
   public assetsAreLoaded(): boolean {
      return this.assetsLoaded$.value;
   }

   public getAssets(): Lookup<"assetID", Asset> {
      return this.assetStore.lookup();
   }

   public getAsset(assetID: number): Asset | undefined {
      return this.assetStore.get(assetID);
   }

   public getAssetHoursOfOperation(asset: Asset | undefined): number {
      if (!asset) {
         return 40;
      }
      if (asset.assetOperationHoursPerWeek !== -1) {
         return Number(asset.assetOperationHoursPerWeek);
      }
      const locations = this.manageLocation.getLocationsIndex();
      if (locations[asset.locationID]) {
         return Number(locations[asset.locationID].operationHoursPerWeek);
      }
      return 40;
   }

   public getFields(): Lookup<"fieldID", AssetField> {
      return this.fieldStore.lookup();
   }

   public getField(fieldID: number): AssetField | undefined {
      return this.fieldStore.get(fieldID);
   }

   public getFieldValues(): Lookup<"valueID", AssetFieldValue> {
      return this.fieldValueStore.lookup();
   }

   public getFieldValue(valueID: number): AssetFieldValueWithContent | undefined {
      const fieldValue = this.fieldValueStore.get(valueID);
      /**
       * Making sure to set a valueContent property because it's an optional property
       * from the backend to save memory in instances where it's null.
       *
       * Pruning this property and others saves memory used by our application.
       * But when we're getting a field value directly we want to make sure
       * it has a valueContent property if it doesn't already.
       */
      if (fieldValue) {
         fieldValue.valueContent = fieldValue.valueContent ?? null;
      }
      return fieldValue as AssetFieldValueWithContent;
   }

   public getFieldTypes(): Lookup<"fieldTypeID", AssetFieldType> {
      if (this.isMp4Enabled()) {
         return this.fieldTypes;
      }
      return this.fieldTypes.filter((fieldType) => fieldType.fieldTypeID !== 8);
   }

   public getFieldType(fieldTypeID: number): AssetFieldType | undefined {
      return this.fieldTypes.get(fieldTypeID);
   }

   public getFieldValueFiles(): Lookup<"fileID", AssetFieldValueFile> {
      return this.fieldValueFiles;
   }

   public getFieldValueFile(fileID: number): AssetFieldValueFile | undefined {
      return this.fieldValueFiles.get(fileID);
   }

   public getDepreciationSchedules(): Lookup<"depreciationID", DepreciationSchedule> {
      return this.depreciationSchedules;
   }

   public getDepreciationSchedule(
      depreciationID: number,
   ): DepreciationSchedule | undefined {
      return this.depreciationSchedules.get(depreciationID);
   }

   public getParsedOptionsJson(field: AssetField): any[] | null {
      const optionsJSON = field.optionsJSON;
      if (!optionsJSON) {
         return null;
      }

      if (Array.isArray(optionsJSON)) {
         return optionsJSON;
      }

      try {
         return JSON.parse(optionsJSON);
      } catch {
         return [];
      }
   }

   public async beginChildAssetUpdates(
      oldValue: string | number | Date | null,
      newValue: string | number | Date | null,
      fieldValue: AssetFieldValue,
      asset: Asset,
      assetField: AssetField,
   ): Promise<void> {
      if (
         assetField.updateChildAssetFieldValue === "ignore" ||
         assetField.updateChildAssetFieldValue == undefined ||
         asset.assetChildrenIDs?.length === 0
      ) {
         return;
      }

      const childAssetValues = this.verifyAndUpdateAssetChildren(
         oldValue,
         newValue,
         fieldValue,
         asset,
         assetField,
      );

      await this.completeSetFieldValueCalls(
         oldValue,
         newValue,
         childAssetValues,
         assetField,
      );
      this.getData();
   }

   public isNumCheck(name: string): boolean {
      const cleanName = String(name).replace(/\s/g, "");
      const numbers = /^[0-9]+$/;
      return numbers.exec(cleanName) !== null;
   }

   private async completeSetFieldValueCalls(
      oldValue: string | number | Date | null,
      newValue: string | number | Date | null,
      childAssetValues: Array<Asset>,
      assetField: AssetField,
   ): Promise<Array<AxiosResponse>> {
      const promises: Array<Promise<AxiosResponse>> = [];
      for (const selectedAsset of childAssetValues) {
         let fieldToUse;
         for (const valueID of selectedAsset.assetValueIDs) {
            const value = this.getFieldValue(valueID);
            if (value?.fieldID === assetField.fieldID) {
               fieldToUse = value;
            }
         }

         if (!fieldToUse) {
            continue;
         }

         if (assetField.updateChildAssetFieldValue === "replace") {
            fieldToUse.valueContent = newValue;
         } else if (
            typeof newValue === "number" &&
            typeof oldValue === "number" &&
            assetField.updateChildAssetFieldValue === "increment"
         ) {
            fieldToUse.valueContent += newValue - oldValue;
         }
         promises.push(this.setFieldValue(fieldToUse, selectedAsset, 0));
      }
      return Promise.all(promises);
   }

   private verifyAndUpdateAssetChildren(
      oldValue: string | number | Date | null,
      newValue: string | number | Date | null,
      fieldValue: AssetFieldValue | undefined,
      asset: Asset,
      assetField: AssetField,
   ): Array<Asset> {
      if (asset.assetChildrenIDs.length === 0) {
         return [];
      }
      return asset.assetChildrenIDs
         .map((childID) => this.getAsset(childID))
         .filter((child): child is NotUndefined<typeof child> => child !== undefined)
         .flatMap((child) =>
            this.updateChildrenAssets(oldValue, newValue, fieldValue, child, assetField),
         );
   }

   private updateChildrenAssets(
      oldValue: string | number | Date | null,
      newValue: string | number | Date | null,
      fieldValue: AssetFieldValue | undefined,
      asset: Asset,
      assetField: AssetField,
   ): Array<Asset> {
      const fieldToUse = this.getFieldValue(fieldValue?.valueID ?? NaN);
      if (fieldToUse === undefined) {
         return this.verifyAndUpdateAssetChildren(
            oldValue,
            newValue,
            fieldToUse,
            asset,
            assetField,
         );
      }
      return [
         asset,
         ...this.verifyAndUpdateAssetChildren(
            oldValue,
            newValue,
            fieldValue,
            asset,
            assetField,
         ),
      ];
   }

   public async addExistingField(
      asset: Asset,
      fieldID: number,
   ): Promise<AxiosResponse | undefined> {
      const assetID = asset.assetID;

      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "addExistingField",
         },
         data: {
            locationID: asset.locationID,
            assetID: assetID,
            fieldID: fieldID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const newValue = post.data.row;
      newValue.assetValueFileIDs = [];
      asset.assetValueIDs.push(newValue.valueID);
      this.fieldValueStore.add(newValue);
      this.manageObservables.updateObservable(`assetFields${asset.assetID}`, 1);

      const valueID = Number(newValue.valueID);
      this.assetStorageSyncService.syncAsset(asset.assetID);
      this.assetFieldValueStorageSyncService.syncAssetFieldValue(valueID);

      return post;
   }

   public async addNewField(
      asset: Asset,
      type,
      name: string,
      options: string,
   ): Promise<AxiosResponse | undefined> {
      const cleanName = cleanWordPaste(name);

      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "addNewField",
         },
         data: {
            locationID: asset.locationID,
            assetID: asset.assetID,
            fieldTypeID: type.fieldTypeID,
            options: options,
            name: cleanName,
         },
      });

      if (!post.data.success) {
         return post;
      }

      const newField = post.data.field;

      if (newField.fieldTypeID === 2) {
         this.dateReminderStreamMap.set(newField.fieldID, new Subject());
      }

      //adds the new value to the part's list.
      const newValue = post.data.row;
      newValue.assetValueFileIDs = [];

      //adds the field into the correct asset
      asset.assetValueIDs.push(newValue.valueID);

      //add fieldTypeID info to the return object
      post.data.row.fieldTypeID = type.fieldTypeID;

      this.fieldStore.add(newField);
      this.fieldValueStore.add(newValue);
      this.manageObservables.updateObservable(`assetFields${asset.assetID}`, 1);

      const fieldID = Number(newField.fieldID);
      const valueID = Number(newValue.valueID);
      this.assetStorageSyncService.syncAsset(asset.assetID);
      this.assetFieldStorageSyncService.syncAssetField(fieldID);
      this.assetFieldValueStorageSyncService.syncAssetFieldValue(valueID);

      return post;
   }

   public async addField(
      name: string,
      fieldTypeID: number,
      locationID: number,
   ): Promise<AxiosResponse> {
      const cleanName = cleanWordPaste(name);
      const response = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "addField",
         },
         data: {
            name: cleanName,
            fieldTypeID: fieldTypeID,
            locationID: locationID,
         },
      });
      const newField = response.data.field;
      if (newField.fieldTypeID === 2) {
         this.dateReminderStreamMap.set(newField.fieldID, new Subject());
      }
      this.fieldStore.add(newField);

      if (newField) {
         const valueID = Number(newField.valueID);
         this.assetFieldValueStorageSyncService.syncAssetFieldValue(valueID);
      }

      return response;
   }

   public async setFieldValue(
      value: AssetFieldValue,
      asset: Asset,
      checklistID: number,
   ): Promise<AxiosResponse> {
      let valueContent = value.valueContent;
      if (typeof valueContent === "string") {
         valueContent = cleanWordPaste(String(value.valueContent));
      }
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "setFieldValue",
         },
         data: {
            locationID: asset.locationID,
            valueID: value.valueID,
            valueContent: valueContent,
            checklistID: checklistID,
         },
      });

      post.then((answer) => {
         if (answer.data.success && value) {
            const valueID = Number(value.valueID);
            this.assetFieldValueStorageSyncService.syncAssetFieldValue(valueID);
         }
      });

      return post;
   }

   public async removeField(
      fieldToRemove: AssetFieldValue,
      asset: Asset,
   ): Promise<AxiosResponse | undefined> {
      if (!fieldToRemove || !asset) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return undefined;
      }

      const isAssetTemplatesEnabled = await this.legacyLaunchFlagsService.isEnabled(
         Flags.ASSET_TEMPLATES,
      );

      const isFeatureAssetTemplates = this.featureFlagService
         .featureSet()
         ?.has("assetTemplates");

      if (isAssetTemplatesEnabled && isFeatureAssetTemplates) {
         if (asset.templateID) {
            const isFieldLinkedToTemplate = await firstValueFrom(
               this.assetTemplateFieldService.isFieldLinkedToTemplate(
                  fieldToRemove.fieldID,
                  asset.templateID,
               ),
            );
            if (isFieldLinkedToTemplate) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               return undefined;
            }
         }
      }

      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "removeField",
         },
         data: {
            locationID: asset.locationID,
            valueID: fieldToRemove.valueID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      this.fieldValueStore.remove(fieldToRemove);
      const index = asset.assetValueIDs.indexOf(fieldToRemove.valueID);
      asset.assetValueIDs.splice(index, 1);

      const valuesOnField: Array<AssetFieldValue> = [];
      for (const valueID of asset.assetValueIDs) {
         const value = this.getFieldValue(valueID);
         if (value) {
            valuesOnField.push(value);
         }
      }

      for (const value of valuesOnField) {
         if (
            value.valueSort &&
            fieldToRemove.valueSort &&
            value.valueSort > fieldToRemove.valueSort
         ) {
            value.valueSort = value.valueSort - 1;
         }
      }

      if (asset.assetDepreciationID !== undefined) {
         const depreciationSchedule = this.getDepreciationSchedule(
            asset.assetDepreciationID,
         );

         if (depreciationSchedule?.valueID === fieldToRemove.valueID) {
            depreciationSchedule.scheduleActive = 0;
         }
      }

      this.manageObservables.updateObservable(`assetFields${asset.assetID}`, 1);

      this.assetStorageSyncService.syncAsset(asset.assetID);
      this.assetFieldValueStorageSyncService.syncAssetFieldValueDeletion(
         fieldToRemove.valueID,
      );

      return post;
   }

   public async deleteFile(
      fileID: number,
      value: AssetFieldValue,
      locationID: number,
   ): Promise<AxiosResponse | undefined> {
      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "deleteDocument",
         },
         data: {
            fileID: fileID,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      // This shouldn't ever happen but this is mostly for the compiler.
      // The assetValueFileIDs are optional so we need to check for that.
      if (!value.assetValueFileIDs) {
         return post;
      }

      const index = value.assetValueFileIDs.indexOf(fileID);
      value.assetValueFileIDs.splice(index, 1);
      this.fieldValueFiles.delete(fileID);

      return post;
   }

   public async updateSorts(
      updates: Array<any>,
      locationID: number,
   ): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateSorts",
         },
         data: {
            updates: JSON.stringify(updates),
            locationID,
         },
      });
   }

   public async importAssets(
      assetsToImport,
      fieldsToImport,
      locationID: number,
      batchedImport: boolean = false,
      isAssetTemplateFieldsPrioritized: boolean = false,
   ): Promise<AxiosResponse> {
      const assetsToImportStringified = JSON.stringify(assetsToImport);
      const fieldsToImportStringified = JSON.stringify(fieldsToImport);

      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "importAssets",
         },
         data: {
            locationID: locationID,
            assets: assetsToImportStringified,
            fields: fieldsToImportStringified,
            isAssetTemplateFieldsPrioritized,
         },
      });

      if (batchedImport) {
         //the call to getData should not be run for batched imports, as it will be called at the end of that process
         //if called on each batch, it could cause the browser to crash
         return post;
      }

      this.getData();
      return post;
   }

   public async updateAssetName(asset: Asset): Promise<AxiosResponse> {
      if (!asset.assetName) {
         asset.assetName = "";
      }

      asset.assetName = cleanWordPaste(asset.assetName);
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetName",
         },
         data: {
            assetID: asset.assetID,
            assetName: asset.assetName,
            locationID: asset.locationID,
         },
      });

      post.then((answer) => {
         if (answer.data.success) {
            this.assetStore.update(asset);

            this.assetStorageSyncService.syncAsset(asset.assetID);
         }
      });

      return post;
   }

   public async updateAssetDefaultChecklist(
      assetID: number,
      defaultChecklistID: number | null,
   ): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetDefaultChecklist",
         },
         data: {
            assetID: assetID,
            defaultChecklistID: defaultChecklistID,
         },
      });
   }

   public async addAssetLumberyard(
      locationID: number,
      newName: string,
      parentAssetID: number,
      templateID?: number,
   ): Promise<AxiosResponse> {
      const cleanNewName = cleanWordPaste(newName);
      const response = await this.http
         .post<any>(
            `${this.baseUrl}/`,
            {
               name: cleanNewName,
               locationID,
               parentAssetID,
               templateID,
            },
            { withCredentials: true, observe: "response" },
         )
         .toPromise();

      const axiosResponse: AxiosResponse = {
         data: response?.body ?? { sucess: false, message: "add asset request failed" },
         status: response?.status ?? 500,
         statusText: response?.statusText ?? "Internal Server Error",
         headers: {},
         config: {},
      };

      return axiosResponse;
   }

   public addAssetLumberyardResponseHandler(response) {
      const dataAsset = response.data.asset;

      const stateAsset = adaptLumberyardAssetToStateAsset(dataAsset);

      const asset: Asset = {
         ...dataAsset,
         ...stateAsset,
         assetValueIDs: [],
         assetVendorIDs: [],
         assetPartRelationIDs: [],
         assetChildrenIDs: [],
      };

      response.data.asset = asset;
      for (const field of response.data.fields) {
         /* Setting location id to the location id of the asset for now.
          * When lumberyard is ready to have this response include scope type we will make this able to work
          * with multiple locations for standardized fields.
          */

         field.fieldValue.locationID = field.fieldDefinition.locationID;

         if (field.fieldValue.locationID === 0) {
            field.fieldValue.locationID = null;
         }

         asset.assetValueIDs.push(field.fieldValue.valueID);
         this.fieldValueStore.add({
            ...field.fieldValue,
            assetValueFileIDs: [],
         });
      }
      this.assetStore.lookup().setValue(asset);
      this.updateAssetChildrenIDs();
      this.assetStore.add(asset);

      this.assetStorageSyncService.syncAsset(asset.assetID);

      return response;
   }

   public addAssetPHPResponseHandler(response) {
      const asset: Asset = {
         ...response.data.asset,
         assetValueIDs: [],
         assetVendorIDs: [],
         assetPartRelationIDs: [],
         assetChildrenIDs: [],
      };
      for (const fieldValue of response.data.customDefaultFields) {
         //Have to process all of the fields that were automatically added
         asset.assetValueIDs.push(fieldValue.valueID);
         this.fieldValueStore.add({
            ...fieldValue,
            assetValueFileIDs: [],
         });
      }
      this.assetStore.lookup().setValue(asset);
      this.updateAssetChildrenIDs();
      this.assetStore.add(asset);

      this.assetStorageSyncService.syncAsset(asset.assetID);
   }

   //pass locationID for cred purposes, pass groupID > 0 if they are joining a group, pass type 1 means a normal asset should be made and 2 means a group leader asset should be made
   public async addAsset(
      locationID: number,
      newName: string,
      parentAssetID: number,
      templateID?: number,
   ): Promise<AxiosResponse> {
      const cleanNewName = cleanWordPaste(newName);
      const lumberyardFlag = await this.legacyLaunchFlagsService.isEnabled(
         Flags.ASSET_TEMPLATES,
      );
      const isFeatureAssetTemplates = this.featureFlagService
         .featureSet()
         ?.has("assetTemplates");

      if (lumberyardFlag && isFeatureAssetTemplates) {
         let response: AxiosResponse = {
            data: { success: false },
            status: 500,
            statusText: "Internal Server Error",
            headers: {},
            config: {},
         };
         response = await this.addAssetLumberyard(
            locationID,
            cleanNewName,
            parentAssetID,
            templateID,
         );

         if (response.data.success) {
            response = this.addAssetLumberyardResponseHandler(response);
         } else {
            throw new Error(`Failed to add asset: ${response.data.message}`);
         }

         return response;
      }

      const response = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "addAsset",
         },
         data: {
            locationID: locationID,
            newName: cleanNewName,
            parentAssetID: parentAssetID,
         },
      });
      if (response.data.success) {
         this.addAssetPHPResponseHandler(response);
      } else {
         throw new Error(`Failed to add asset: ${response.data.message}`);
      }

      return response;
   }

   /**
    * Gets a list of IDs for custom default asset fields at a location
    * @param locationID - The ID of the location in which to find
    * custom default fields.
    * @returns An array of fieldIDs. Returns undefined if the operation fails
    */
   public async getCustomDefaultFields(
      locationID: number,
   ): Promise<Array<number> | undefined> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "getCustomDefaultFields",
         },
         data: {
            locationID: locationID,
         },
      }).then((answer) => {
         if (answer.data.success === false) {
            return undefined;
         }
         return answer.data.fields;
      });
   }

   public async copyAsset(
      sourceAssetID: number,
      locationIDTarget: number,
      newName: string,
      parentAssetID: number,
      copyChildren: boolean,
      newAssetCopiedFromParentID: number,
   ): Promise<AxiosResponse> {
      const cleanNewName = cleanWordPaste(newName);

      let templatesStatus: AssetTemplateAccountSettings["templatesStatus"] | undefined;
      const isAssetTemplatesEnabled = await this.legacyLaunchFlagsService.isEnabled(
         Flags.ASSET_TEMPLATES,
      );
      const isFeatureAssetTemplates = this.featureFlagService
         .featureSet()
         ?.has("assetTemplates");

      if (isAssetTemplatesEnabled && isFeatureAssetTemplates) {
         templatesStatus = (
            await firstValueFrom(
               this.assetTemplateSettingsService.getAccountSettings(),
            ).catch(() => {
               console.error("GET account template settings failed, check lumberyard. ");
            })
         )?.templatesStatus;
      }

      const response = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "copyAsset",
         },
         data: {
            sourceAssetID: sourceAssetID,
            locationIDTarget: locationIDTarget,
            newName: cleanNewName,
            parentAssetID: parentAssetID,
            copyChildren: copyChildren,
            newAssetCopiedFromParentID: newAssetCopiedFromParentID,
            templatesStatus: templatesStatus,
         },
      });

      if (response.data.success === true) {
         const newAssetsResponse = await axios.get(`${environment.flannelUrl}/assets`, {
            params: { assetIDs: response.data.newAssetIDs.join(",") },
         });
         const newAssets = newAssetsResponse.data;
         this.assetStore.lookup().setValues(newAssets);
         this.updateAssetChildrenIDs();
         this.assetStore.add(newAssets);
         await this.getPaginatedAssetFieldValues();
         await this.fetchFieldValueFiles();
         //we might create new fields during copying an asset if those fields don't exist at the target location
         await this.fetchFields();
      }
      return response;
   }

   public async changeAssetTrackCOOParts(asset): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "changeAssetTrackCOOParts",
         },
         data: {
            locationID: asset.locationID,
            assetID: asset.assetID,
         },
      });
   }

   public async changeAssetTrackCOOLabor(asset): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "changeAssetTrackCOOLabor",
         },
         data: {
            locationID: asset.locationID,
            assetID: asset.assetID,
         },
      });
   }

   public async changeReportCostOfOwnership(asset, field): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "changeReportCostOfOwnership",
         },
         data: {
            locationID: asset.locationID,
            valueID: field.valueID,
         },
      });
   }

   public async deleteAsset(asset): Promise<AxiosResponse | undefined> {
      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "deleteAsset",
         },
         data: {
            assetID: asset.assetID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }
      asset.assetDeleted = 1;
      const parentAsset = this.getAsset(asset.parentAssetID);
      if (parentAsset) {
         const indexToRemove = parentAsset.assetChildrenIDs.findIndex(
            (assetID) => assetID === asset.assetID,
         );
         parentAsset.assetChildrenIDs.splice(indexToRemove, 1);
      }

      this.assetStore.update(asset);

      return post;
   }

   public async deleteAssetsInBulk(
      assetsToDelete: Array<number>,
   ): Promise<AxiosResponse> {
      const response = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "deleteAssetsInBulk",
         },
         data: {
            assets: assetsToDelete,
         },
      });
      if (response.data.success === true) {
         const assetLookup = this.assetLookup();
         const assets = assetsToDelete.map((assetID) => assetLookup.strictGet(assetID));
         assets.forEach((asset) => {
            asset.assetDeleted = 1;
         });
         this.assetStore.update(assets);
      }
      return response;
   }

   public async updateSuggestedFieldName(
      field: AssetField,
      locationID: number,
      fieldName: string,
   ): Promise<AxiosResponse | undefined> {
      const newFieldName = cleanWordPaste(fieldName);
      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateSuggestedFieldName",
         },
         data: {
            locationID: locationID,
            fieldID: field.fieldID,
            fieldName: newFieldName,
         },
      });
      if (!post.data.success) {
         return post;
      }
      field.fieldName = fieldName;
      this.fieldStore.update(field);

      this.assetFieldStorageSyncService.syncAssetFieldUpdate(field.fieldID, {
         fieldName: fieldName,
      });

      return post;
   }

   public async updateDropdownOptions(
      field: AssetField,
      oldValue: string | null = null,
      newValue: string | null = null,
   ): Promise<AxiosResponse> {
      assert(field);
      field.optionsJSON = cleanWordPaste(String(field.optionsJSON));
      const response = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateDropdownOptions",
         },
         data: {
            locationID: field.locationID,
            fieldID: field.fieldID,
            optionsJSON: field.optionsJSON,
            oldValue: oldValue,
            newValue: newValue,
         },
      });
      if (response.data.success == true) {
         const fieldToUpdate = this.getField(field.fieldID);
         assert(fieldToUpdate);
         fieldToUpdate.optionsJSON = field.optionsJSON;
         this.manageObservables.updateObservable(
            `assetFieldOptionsJSON${field.fieldID}`,
            1,
         );
         this.fieldStore.update(fieldToUpdate);
      }
      return response;
   }

   public async updateFieldViewableByTech(
      field: AssetFieldValue,
      locationID: number,
   ): Promise<AxiosResponse> {
      let newValue;
      if (field.viewableByTech == 1) {
         newValue = 0;
      } else {
         newValue = 1;
      }

      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateFieldViewableByTech",
         },
         data: {
            locationID: locationID,
            valueID: field.valueID,
            newValue: newValue,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            field.viewableByTech = newValue;
         }
      });

      return post;
   }

   public async updateFieldViewableByTechFieldDefault(
      field: AssetField,
   ): Promise<AxiosResponse> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateFieldViewableByTechFieldDefault",
         },
         data: {
            locationID: field.locationID,
            fieldID: field.fieldID,
            newValue: field.viewableByTechFieldDefault,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            for (const value of this.fieldValueStore.lookup()) {
               if (value.fieldID == field.fieldID) {
                  value.viewableByTech = field.viewableByTechFieldDefault;
               }
            }
         }
      });

      return post;
   }

   public async updateDateReminder(
      field: string,
      value: number,
      fieldID: number,
   ): Promise<AxiosResponse> {
      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateDateReminder",
         },
         data: {
            fieldToUpdate: field,
            value: value,
            fieldID: fieldID,
         },
      });

      const fieldToUpdate = this.getField(fieldID);
      if (fieldToUpdate) {
         fieldToUpdate[field] = value;
      }

      this.dateReminderStreamMap.get(fieldID)?.next(null);
      return post;
   }

   public async updateDateReminderAssignments(
      fieldID: number,
      userID: number,
      profileID: number,
      multiUsers: Array<number>,
      locationID: number,
   ): Promise<AxiosResponse> {
      const multiUsersStringifed = JSON.stringify(multiUsers);
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateDateReminderAssignments",
         },
         data: {
            fieldID: fieldID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringifed,
            locationID: locationID,
         },
      });
   }

   public async removeSuggestedField(
      field: AssetField,
   ): Promise<AxiosResponse | undefined> {
      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "removeSuggestedField",
         },
         data: {
            locationID: field.locationID,
            fieldID: field.fieldID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      this.dateReminderStreamMap.delete(field.fieldID);

      //have to remove the fields in the other assets to make sure this corresponds properly
      const valueIDsWithFieldID: Array<number> = [];
      const assetIDsThatHaveThisValue: Set<number> = new Set();
      for (const [valueID, value] of this.fieldValueStore.lookup().entries()) {
         if (value.fieldID !== field.fieldID) {
            continue;
         }
         assetIDsThatHaveThisValue.add(value.assetID);
         valueIDsWithFieldID.push(valueID);
         this.fieldValueStore.remove(value);
      }

      for (const assetID of assetIDsThatHaveThisValue) {
         const asset = this.assetStore.get(assetID);
         assert(asset);
         const index = asset.assetValueIDs.findIndex((valueID) =>
            valueIDsWithFieldID.includes(valueID),
         );
         asset.assetValueIDs.splice(index, 1);
      }

      this.fieldStore.remove(field);

      return post;
   }

   public async updateFieldDisplayOnTasks(
      fieldID: number,
      value: number,
   ): Promise<AxiosResponse> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateFieldDisplayOnTasks",
         },
         data: {
            value: value,
            fieldID: fieldID,
         },
      });
      post.then((answer) => {
         if (answer.data.success) {
            this.assetFieldStorageSyncService.syncAssetFieldUpdate(fieldID, {
               displayOnTasks: value,
            });
         }
      });

      return post;
   }

   public async checkForRecurrences(fieldValue: AssetFieldValue): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "checkForRecurrences",
         },
         data: {
            valueID: fieldValue.valueID,
         },
      });
   }

   public async getAssetItemInfoHistory(
      fieldValue: AssetFieldValue,
   ): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "getAssetItemInfoHistory",
         },
         data: {
            valueID: fieldValue.valueID,
         },
      });
   }

   public async editAssetItemInfoHistory(historyID, value): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "editAssetItemInfoHistory",
         },
         data: {
            historyID: historyID,
            value: value,
         },
      });
   }

   public async editAssetItemTimestampHistory(historyID, value): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "editAssetItemTimestampHistory",
         },
         data: {
            historyID: historyID,
            value: value,
         },
      });
   }

   public async deleteAssetItemInfoHistory(historyID): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "deleteAssetItemInfoHistory",
         },
         data: {
            historyID: historyID,
         },
      });
   }

   public async getAssetItemsInfoHistory(valueIDs): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "getAssetItemsInfoHistory",
         },
         data: {
            valueIDs: valueIDs,
         },
      });
   }

   public async changeAssetLocation(
      asset: Asset,
      location,
      transferParts: boolean,
   ): Promise<AxiosResponse> {
      //now we need to check to see if the asset has any children.  If so then we need to move those as well.
      const children = this.findChildrenIDs(asset, []);

      if (children.length > 0) {
         return this.bulkChangeAssetLocation(
            [...children, asset.assetID],
            location.locationID,
            transferParts,
         );
      }

      //we didn't find children so just change the single asset's location
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "changeAssetLocation",
         },
         data: {
            assetID: asset.assetID,
            locationID: location.locationID,
            checkParent: 1,
            transferParts: transferParts,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.assetStore.update(asset);
            this.updateAssetChildrenIDs();
         }
      });

      return post;
   }

   public async updateAssetHoursOfOperation(asset): Promise<AxiosResponse> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetHoursOfOperation",
         },
         data: {
            assetID: asset.assetID,
            assetOperationHoursPerWeek: this.getAssetHoursOfOperation(asset),
         },
      });
      post.then((answer) => {
         if (answer.data.success == true) {
            this.assetStore.update(asset);
         }
      });

      return post;
   }

   public async updateAssetCreatedDate(newTime, asset): Promise<AxiosResponse> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetCreatedDate",
         },
         data: {
            newTime: newTime,
            assetID: asset.assetID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.assetStore.update(asset);
         }
      });

      return post;
   }

   public async restoreAsset(assetID: number): Promise<AxiosResponse> {
      const asset = this.assetStore.get(assetID);
      if (asset === undefined) {
         throw new Error("failed to restore asset");
      }
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "restoreAsset",
         },
         data: {
            assetID: assetID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            // Add the asset being restored back into its parent's children list if needed
            if (asset?.parentAssetID && asset.parentAssetID > 0) {
               const parentAsset = this.assetStore.get(asset?.parentAssetID);
               parentAsset?.assetChildrenIDs.push(assetID);
            }
            this.assetStore.update(asset);
         }
      });

      return post;
   }

   //accepts the asset obj
   //a moment obj with the start of the timeframe
   //a moment obj with the end of the timefram
   public calculateAssetHrsRunTime(asset: Asset, start: Moment, end: Moment): number {
      //the method to check if they are NOT tracking assets opertion hours manually

      if (asset.assetTrackHoursManually == 1) {
         //if we are automatically tracking then do the old logic
         let runningHours = 0;
         const startClone = start.clone(); //have to clone because of mutability in moment
         const endClone = end.clone();

         while (startClone.valueOf() < endClone.valueOf()) {
            const startOfWeek = moment(startClone).startOf("week").format("YYYY-MM-DD");
            if (asset?.assetTrackHoursManuallyData?.[startOfWeek]) {
               runningHours += asset.assetTrackHoursManuallyData[startOfWeek] / 7;
            } else {
               runningHours += this.getAssetHoursOfOperation(asset) / 7;
            }
            startClone.add(1, "day");
         }
         return runningHours;
      }

      if (asset.assetCreatedDate == undefined || asset.assetCreatedDate > end.unix()) {
         /* The asset was created after the end period we are checking therefore the asset was not running during this time to be calculated.
          * If we don't check here then we get a negative number returned because it will do for example September 17th, 2018 minus
          * February 12th, 2018 and it generates a negative number of days ran... which isn't possible. Returning 0 because it ran for 0 hours.
          */
         return 0;
      }

      /* NOTE to DEVS:
       If you make any changes here, please align them with the back-end calculation.
       The file is located here: ~/flannel/src/endpoints/dashboards/custom/widgets/content/assets/helpers/calcAssetRuntime.ts
       method: calcAssetRuntime
   */
      // if the assetCreatedDate was created after our start date then we need to use the assetCreatedDate because the asset has not been running for the entire time period.
      // E.g if we are using a date range of sept 1 to sept 30 2017 and the asset started on sept 19 then we should only calculate for the 19th to 30th
      let correctedStart: Moment;
      if (asset.assetCreatedDate && asset.assetCreatedDate > start.unix()) {
         correctedStart = moment(asset.assetCreatedDate * 1000).startOf("day");
      } else {
         correctedStart = start;
      }

      //next we need to calculate how many days it has been running.  IMPORTANT... do not use moment's built in function (.diff) to compare days.  It is very very slow
      const daysRan = Math.round((end.unix() - correctedStart.unix()) / 86400);
      //finally determine how many hours of operation that actually was for that asset and return it.
      return (this.getAssetHoursOfOperation(asset) / 7) * daysRan;
   }

   public async updateAssetTrackHoursManually(
      asset,
      includeChildren,
   ): Promise<AxiosResponse> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetTrackHoursManually",
         },
         data: {
            assetID: asset.assetID,
            value: asset.assetTrackHoursManually,
            includeChildren: includeChildren,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.assetStore.update(asset);
         }
      });

      return post;
   }

   public prepHoursManually(
      assetIn: Asset,
   ): Array<{ start: string; hours: number; lookup: string; end: string }> {
      const manualHours = [];
      assert(assetIn);
      assert(assetIn.assetCreatedDate);
      const calcWeek = (
         asset: Asset,
         weekIn: Moment,
         manualAssetHours: Array<{
            start: string;
            hours: number;
            lookup: string;
            end: string;
         }>,
      ) => {
         let week = weekIn;
         assert(asset);
         assert(asset.assetTrackHoursManuallyData);
         //function that will handle calculating the week and marking the array correctly
         week = week.day(3); //set it back to middle of the week
         const hoursData = JSON.parse(asset.assetTrackHoursManuallyData);

         week = week.startOf("week");
         const start = week.format("YYYY-MM-DD");
         let hours = 0;
         if (hoursData[start]) {
            hours = Number(hoursData[start]);
         } else {
            hours = Number(this.getAssetHoursOfOperation(asset));
         }

         manualAssetHours.push({
            //manualHours array is really only used for displaying the information in the view.  calculateAssetHrsRunTime will not use this array, but instead assetTrackHoursManuallyData
            start: start,
            hours: hours,
            lookup: week.format("YYYY-MM-DD"),
            end: week.endOf("week").format("YYYY-MM-DD"),
         });

         week = week.add(1, "week");
         return week;
      };

      let week = moment(assetIn.assetCreatedDate * 1000).day(3);
      const now = moment().add(1, "week");

      while (week < now) {
         week = calcWeek(assetIn, week, manualHours);
      }
      return manualHours;
   }

   public async updateAssetTrackHoursManuallyData(
      hour,
      asset: Asset,
      includeChildren,
   ): Promise<AxiosResponse> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetTrackHoursManuallyData",
         },
         data: {
            assetID: asset.assetID,
            start: hour.start,
            hours: hour.hours,
            includeChildren: includeChildren,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            asset.assetTrackHoursManuallyData = JSON.parse(
               answer.data.assetTrackHoursManuallyData,
            );
            this.assetStore.update(asset);
         }
      });

      return post;
   }

   public updateParentChildRelationship(
      assetOrderUpdates: Array<{ asset: number; index: number }>,
      sourceAssetID: number,
      newParentID: number,
      previousParentID: number,
   ): Observable<{ success: boolean }> {
      return this.http
         .post<{
            success: boolean;
         }>(
            "phpscripts/manageAsset.php",
            { assetOrderUpdates, sourceAssetID, newParentID, previousParentID },
            { params: { action: "updateParentChildRelationship" } },
         )
         .pipe(
            tap(() => {
               this.updateAssetChildrenIDs();
            }),
         );
   }

   public async updateIncludeChildData(assetID, flag): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateIncludeChildData",
         },
         data: {
            assetID: assetID,
            flag: flag,
         },
      });
   }

   public async updateAssetWorkRequestAssignment(
      asset,
      profileID,
      userID,
      multiUsers,
   ): Promise<AxiosResponse> {
      const multiUsersStringified = JSON.stringify(multiUsers);
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetWorkRequestAssignment",
         },
         data: {
            assetID: asset.assetID,
            profileID: profileID,
            userID: userID,
            multiUsers: multiUsersStringified,
         },
      });
   }

   public async updateAssetCheckSimiliarWRScope(
      assetID,
      assetCheckSimiliarWRScope,
   ): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateAssetCheckSimiliarWRScope",
         },
         data: {
            assetID: assetID,
            assetCheckSimiliarWRScope: assetCheckSimiliarWRScope,
         },
      });
   }

   public async downloadAllAssets(
      locationIDs: Array<number>,
      date: string,
   ): Promise<any> {
      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "downloadAllAssets",
         },
         data: {
            locationIDs: locationIDs,
         },
      });

      post.then(
         (answer) => {
            if (!answer.data.success) {
               return;
            }
            //added this to extract the actual data and ignore the href data info
            const afile = answer.data.file.slice(
               "data:application/vnd.ms-excel;base64,".length,
            );

            //control if the link will be windows or everything else that is better than windows
            if (window.Blob && msSaveOrOpenBlob) {
               // Falls to msSaveOrOpenBlob if download attribute is not supported
               try {
                  const blobObject = b64toBlob(afile, {
                     type: "application/vnd.ms-excel",
                  });
                  msSaveOrOpenBlob(blobObject, `${name}-${date}.xlsx`);
               } catch (error) {
                  console.error(error);
               }
            } else {
               const aaa = document.createElement("a");
               aaa.setAttribute("href", answer.data.file);
               aaa.setAttribute("download", `${this.lang().Assets}-${date}.xlsx`);
               aaa.setAttribute("target", "_blank");
               document.body.appendChild(aaa);
               aaa.click();
               aaa.remove();
            }
         },
         (err) => {
            console.error("export error", err);
         },
      );

      return post;
   }

   public downloadAssets(
      assets: Lookup<"assetID", Asset>,
      includeUrlsIn: boolean,
      swapAssetIDDescription: boolean,
      featureAssetTools: boolean,
      manageParts?: ManageParts,
      manageVendors?: ManageVendor,
      options: {
         includeParentAssetName: boolean;
         includeTemplatedFieldsList?: boolean;
      } = { includeParentAssetName: true },
   ): void {
      const assetsToDownload: any = [];
      const includeUrls = includeUrlsIn || false;

      let locationID;
      for (const [index, asset] of Array.from(assets).entries()) {
         const obj = {};

         locationID = asset.locationID;
         if (!includeUrls) {
            if (swapAssetIDDescription) {
               obj["Asset To Update (AssetID or Asset Name)"] = asset.assetID;
            } else {
               obj["Asset ID"] = asset.assetID;
            }
         }

         obj["Asset Name"] = asset.assetName;

         if (!includeUrls) {
            let parentAssetID = 0;
            let parentAssetName = "";

            if (asset.parentAssetID) {
               parentAssetID = asset.parentAssetID;
               parentAssetName = this.getAsset(asset.parentAssetID)?.assetName ?? "";
            }

            obj["Parent Asset ID"] = parentAssetID;
            if (options.includeParentAssetName) {
               obj["Parent Asset Name"] = parentAssetName;
            }
         }

         if (options.includeTemplatedFieldsList) {
            obj["Templated Fields - DO NOT EDIT OR REMOVE"] = "";
         }

         if (featureAssetTools) {
            obj["Tool Check-Out"] = asset.canCheckOutAsTool;
            obj["Check-Out Requires Approval"] = asset.checkOutRequiresApproval;
         }

         const templatedFieldsNames: string[] = [];
         for (const valueID of asset.assetValueIDs) {
            const fieldValue = this.getFieldValue(valueID);
            if (!fieldValue) {
               continue;
            }
            const field = this.getField(fieldValue.fieldID);
            if (!field) {
               continue;
            }

            if (field.fieldTypeID == 5 || field.fieldTypeID == 6) {
               if (
                  typeof fieldValue.valueContent === "string" ||
                  typeof fieldValue.valueContent === "number"
               ) {
                  //valid so let's set them as their
                  // field.valueContent = field.valueContent;
               } else {
                  fieldValue.valueContent = "";
               }
            }

            if (field.fieldTypeID == 2 && fieldValue.valueContent) {
               const date = new Date(fieldValue.valueContent);

               let str;

               if (String(date) === "Invalid Date") {
                  str = "";
               } else {
                  str = this.betterDate.formatBetterDate(date, "date");
               }

               obj[field.fieldName] = str;
            }

            // We format dates (fieldTypeID 2) differently above, so exclude them here
            if (field.fieldTypeID !== 2) {
               obj[field.fieldName] = fieldValue.valueContent;
            }

            // Helper function to clean and format field content removing possibility of trailing <br /> some clients were seeing.
            const cleanFieldContent = (
               content: string | Date | number | null,
            ): string => {
               if (content === null) return "";
               let cleanedContent = String(content);
               if (cleanedContent.includes("<br>")) {
                  cleanedContent = cleanedContent.replace(/<br>/gi, "");
               }
               if (cleanedContent.includes("<br />")) {
                  cleanedContent = cleanedContent.replace(/<br\s*\/?>/gi, "");
               }
               return cleanedContent.trim();
            };

            // If the field is a text field or dropdown field we will sanitize line breaks from it from it
            if (field.fieldTypeID == 1 || field.fieldTypeID == 7) {
               obj[field.fieldName] = cleanFieldContent(obj[field.fieldName]);
            }

            if (field.fieldTypeID == 3 || field.fieldTypeID == 4) {
               obj[field.fieldName] = "";

               for (const key3 in this.fieldValueFiles.get(valueID)) {
                  const files = this.fieldValueFiles.get(valueID);
                  if (!files?.[key3]) {
                     continue;
                  }
                  obj[field.fieldName] += `${files[key3].fileDescription}, `;
               }
               if (obj[field.fieldName].length > 2) {
                  obj[field.fieldName] = obj[field.fieldName].substring(
                     0,
                     obj[field.fieldName].length - 2,
                  );
               }
            }

            //the first row must ALWAYS have whatever fieldName or else the export won't work appropriately
            if (index > 0 && assetsToDownload[0][field.fieldName] === undefined) {
               assetsToDownload[0][field.fieldName] = "";
            }

            if (
               options.includeTemplatedFieldsList &&
               field.scopeType === AssetFieldScopeType.Standardized &&
               field.fieldName
            ) {
               templatedFieldsNames.push(field.fieldName);
            }
         }

         if (options.includeTemplatedFieldsList && templatedFieldsNames.length) {
            obj["Templated Fields - DO NOT EDIT OR REMOVE"] =
               templatedFieldsNames.join(";");
         }

         if (manageParts) {
            obj["Manually Associated Parts"] = "";
            for (const relation of manageParts.getAssetsRelations()) {
               if (relation.assetID == asset.assetID) {
                  const part = manageParts.getPart(relation.partID);
                  if (part !== undefined && part.partDeleted == 0) {
                     obj["Manually Associated Parts"] = `${
                        obj["Manually Associated Parts"] + part.partName
                     }; `;
                  }
               }
            }
            if (obj["Manually Associated Parts"].length > 0) {
               obj["Manually Associated Parts"] = obj[
                  "Manually Associated Parts"
               ].substring(0, obj["Manually Associated Parts"].length - 2);
            }
         }

         if (manageVendors) {
            const manuallyAssociatedVendors = asset.assetVendorIDs;
            obj["Manually Associated Vendors"] = "";
            for (const relation of manuallyAssociatedVendors) {
               const vendor = manageVendors.getVendor(relation);
               if (vendor !== undefined && vendor.vendorDeleted == 0) {
                  obj["Manually Associated Vendors"] = `${
                     obj["Manually Associated Vendors"] + vendor.vendorName
                  }; `;
               }
            }
            if (obj["Manually Associated Vendors"].length > 0) {
               obj["Manually Associated Vendors"] = obj[
                  "Manually Associated Vendors"
               ].substring(0, obj["Manually Associated Vendors"].length - 2);
            }
         }

         if (includeUrls) {
            const host = `${window.location.protocol}//${window.location.host}`;
            const customerBarcodeStr =
               this.manageUser.getCurrentUser().userInfo.customerBarcodeStr;
            obj["Work Request Portal URL"] =
               `${host}/problem/${customerBarcodeStr}/${asset.locationID}/${asset.assetID}`;
            obj["Asset Lookup URL"] =
               `${host}/mobileAssets/${asset.assetID}/${asset.locationID}?m=true`;
         }

         if (asset.defaultChecklistID && asset.defaultChecklistID > 0) {
            obj["Default WO Template"] = asset.defaultChecklistID;
         } else {
            obj["Default WO Template"] = 0;
         }

         assetsToDownload.push(obj);
      }

      if (assetsToDownload[1]) {
         const fields = this.getFields();
         //doing a little check here to make sure all fields are included on a download.  Previously it only shows fields if an asset has that value somewhere
         for (const field of fields) {
            if (field.locationID == locationID) {
               if (assetsToDownload[1][field.fieldName] === undefined) {
                  assetsToDownload[1][field.fieldName] = "";
               }
            }
         }
      }

      const today = this.betterDate.createTodayTimestamp();

      this.manageUtil.objToExcel(assetsToDownload, "Assets", `Assets-${today}.xlsx`);
   }

   private getBannedFieldNames(): Array<string> {
      return reservedNames.Assets;
   }

   public usingForbiddenFieldNames(name: string): boolean {
      return this.getBannedFieldNames().some((fieldName) => {
         if (!fieldName || !name) {
            return false;
         }

         return fieldName.toLowerCase() == name.toLowerCase();
      });
   }

   public downloadAssetsLimited(
      assetList: Array<Asset>,
      columns,
      assetsExtraInfo,
      currentUser,
   ): void {
      let fileName = "";
      const lang = this.lang();

      let today: any = new Date();
      let day: any = today.getDate();
      let month: any = today.getMonth() + 1; //January is 0!
      const year = today.getFullYear();

      if (day < 10) {
         day = `0${day}`;
      }

      if (month < 10) {
         month = `0${month}`;
      }

      const tempAssets: any = [];
      assetList.forEach((asset) => {
         const extraInfo = assetsExtraInfo.get(asset.assetID);
         const obj = {};
         obj[lang.AssetName] = `${this.manageUtil.stripTags(asset.assetName ?? "")} - #${
            asset.assetID
         }`;

         for (const column of columns) {
            switch (column.key) {
               case "completedPlannedWOs":
                  obj[lang.CompletedPlannedWOs] = extraInfo.completedPlannedWOs;
                  break;
               case "completedUnplannedWOs":
                  obj[lang.CompletedUnplannedWOs] = extraInfo.completedUnplannedWOs;
                  break;
               case "completedWorkRequests":
                  obj[lang.CompletedWorkRequests] = extraInfo.completedWorkRequests;
                  break;
               case "totalCompletedTasks":
                  obj[lang.TotalCompletedTasks] = extraInfo.totalCompletedTasks;
                  break;
               case "completedPms":
                  obj[lang.CompletedPMs] = extraInfo.completedPms;
                  break;
               case "openPlannedWOs":
                  obj[lang.OpenPlannedWOs] = extraInfo.openPlannedWOs;
                  break;
               case "openUnplannedWOs":
                  obj[lang.OpenUnplannedWOs] = extraInfo.openUnplannedWOs;
                  break;
               case "openWorkRequests":
                  obj[lang.OpenWorkRequests] = extraInfo.openWorkRequests;
                  break;
               case "totalOpenTasks":
                  obj[lang.TotalOpenTasks] = extraInfo.totalOpenTasks;
                  break;
               case "openPms":
                  obj[lang.OpenPMs] = extraInfo.openPms;
                  break;
               case "totalTasks":
                  obj[lang.TotalTasks] = extraInfo.totalTasks;
                  break;
               case "downtimePercent":
                  obj[`${lang.Downtime} %`] = extraInfo.downtimePercent;
                  break;
               case "uptimePercent":
                  obj[`${lang.Uptime} %`] = extraInfo.uptimePercent;
                  break;
               case "downtimeHrs":
                  obj[`${lang.Downtime} hrs`] = extraInfo.downtimeHrs;
                  break;
               case "uptimeHrs":
                  obj[`${lang.Uptime} hrs`] = extraInfo.uptimeHrs;
                  break;
               case "mttr":
                  obj[lang.MTTR] = extraInfo.mttr;
                  break;
               case "mtbf":
                  obj[lang.MTBF] = extraInfo.mtbf;
                  break;
               case "assetID":
                  obj[lang.AssetID] = extraInfo.assetID;
                  break;
               case "assetCreatedDate":
                  obj[lang.StartedDate] = this.dateParserFormatter.format(
                     this.dateAdapter.fromModel(
                        new Date(extraInfo.assetCreatedDate * 1000),
                     ),
                     currentUser.userInfo.customerDateFormat,
                  );
                  break;
               case "locationName":
                  obj[lang.LocationName] = extraInfo.locationName;
                  break;
               case "customField1":
                  obj[`${column.name}`] = extraInfo.customField1Data?.files.length
                     ? extraInfo.customField1Data.fileNamesStr
                     : extraInfo.customField1Data?.formattedValueForExport;
                  break;
               case "customField2":
                  obj[`${column.name}`] = extraInfo.customField2Data?.files.length
                     ? extraInfo.customField2Data.fileNamesStr
                     : extraInfo.customField2Data?.formattedValueForExport;
                  break;
               case "customField3":
                  obj[`${column.name}`] = extraInfo.customField3Data?.files.length
                     ? extraInfo.customField3Data.fileNamesStr
                     : extraInfo.customField3Data?.formattedValueForExport;
                  break;
               case "customField4":
                  obj[`${column.name}`] = extraInfo.customField4Data?.files.length
                     ? extraInfo.customField4Data.fileNamesStr
                     : extraInfo.customField4Data?.formattedValueForExport;
                  break;
               case "customField5":
                  obj[`${column.name}`] = extraInfo.customField5Data?.files.length
                     ? extraInfo.customField5Data.fileNamesStr
                     : extraInfo.customField5Data?.formattedValueForExport;
                  break;
               case "customField6":
                  obj[`${column.name}`] = extraInfo.customField6Data?.files.length
                     ? extraInfo.customField6Data.fileNamesStr
                     : extraInfo.customField6Data?.formattedValueForExport;
                  break;
               case "customField7":
                  obj[`${column.name}`] = extraInfo.customField7Data?.files.length
                     ? extraInfo.customField7Data.fileNamesStr
                     : extraInfo.customField7Data?.formattedValueForExport;
                  break;
               case "customField8":
                  obj[`${column.name}`] = extraInfo.customField8Data?.files.length
                     ? extraInfo.customField8Data.fileNamesStr
                     : extraInfo.customField8Data?.formattedValueForExport;
                  break;
               case "plannedVsUnplanned":
                  obj[lang.PlannedVsUnplanned] = extraInfo.plannedVsUnplanned;
                  break;
               case "timeSpent2":
                  obj[lang.TimeSpent] = extraInfo.timeSpent2;
                  break;
               case "partsUsed":
                  obj[lang.PartsUsed] = extraInfo.partsUsed;
                  break;
               case "costParts":
                  obj[lang.PartsCost] = extraInfo.costParts;
                  break;
               case "costLabor":
                  obj[lang.LaborCost] = extraInfo.costLabor;
                  break;
               case "costInvoices":
                  obj[lang.InvoiceCost] = extraInfo.costInvoices;
                  break;
               case "costTotal":
                  obj[lang.TotalCost] = extraInfo.costTotal;
                  break;
               case "purchaseCost":
                  obj[lang.PurchaseCost] = extraInfo.purchaseCost;
                  break;
               case "salvageValue":
                  obj[lang.SalvageValue] = extraInfo.salvageValue;
                  break;
               case "standardUsefulLife":
                  obj[lang.StandardUsefulLife] = extraInfo.standardUsefulLife;
                  break;
               case "scheduleStartDate":
                  obj[lang.DepreciationScheduleStartDate] = extraInfo.scheduleStartDate;
                  break;
               case "scheduleEndDate":
                  obj[lang.DepreciationScheduleEndDate] = extraInfo.scheduleEndDate;
                  break;
               case "depreciationScheduleTaskAssignment":
                  obj[lang.EndOfScheduleTaskAssignment] =
                     extraInfo.depreciationScheduleTaskAssignment;
                  break;
               case "costPerCustomField":
                  obj[lang.CostPerCustomField] = extraInfo.costPerCustomField;
                  break;
               default:
            }
         }

         /**
          * Make sure to strip any HTML tags from the values
          * so they don't show up in the Excel file.
          */
         this.manageUtil.stripTagsInObj(obj);

         tempAssets.push(obj);
      });

      today = `${year}-${month}-${day}`;
      fileName = `${lang.Assets}-${today}.xlsx`;

      this.manageUtil.objToExcel(tempAssets, "Assets", fileName, true);
   }

   public getAssetNameIncludeParents(assetID: number): string {
      const asset = this.getAsset(assetID);
      let assetNameStr = asset?.assetName ?? "";

      const parentInfo = this.buildParentInfo(assetID);

      if (!parentInfo) {
         return "";
      }
      for (const assetInList of parentInfo) {
         assetNameStr += ` > ${assetInList.assetName}`;
      }

      return assetNameStr;
   }

   public getAssetName(assetID: number): string {
      const asset = this.getAsset(assetID);
      return asset?.assetName ?? "";
   }

   private buildAssetParent(parentAssetID: number): {
      assetID: number;
      assetName: string | null;
      parentAssetID: number;
   } {
      const parentAsset = this.getAsset(parentAssetID);

      return {
         assetID: parentAsset?.assetID ?? 0,
         assetName: parentAsset?.assetName ?? null,
         parentAssetID: parentAsset?.parentAssetID ?? 0,
      };
   }

   public buildParentInfo(
      assetID: number,
   ): Lookup<
      "assetID",
      { assetID: number; assetName: string | null; parentAssetID: number }
   > {
      const asset = this.getAsset(assetID);
      const assetParentInfo: Lookup<
         "assetID",
         { assetID: number; assetName: string | null; parentAssetID: number }
      > = new Lookup("assetID");

      if (!asset) {
         return new Lookup("assetID");
      }

      if (asset.parentAssetID && asset.parentAssetID > 0) {
         const parent1 = this.buildAssetParent(asset.parentAssetID);
         assetParentInfo.set(parent1.assetID, parent1);

         if (parent1.parentAssetID && parent1.parentAssetID > 0) {
            const parent2 = this.buildAssetParent(parent1.parentAssetID);
            assetParentInfo.set(parent2.assetID, parent2);

            if (parent2.parentAssetID && parent2.parentAssetID > 0) {
               const parent3 = this.buildAssetParent(parent2.parentAssetID);
               assetParentInfo.set(parent3.assetID, parent3);

               if (parent3.parentAssetID && parent3.parentAssetID > 0) {
                  const parent4 = this.buildAssetParent(parent3.parentAssetID);
                  assetParentInfo.set(parent4.assetID, parent4);

                  if (parent4.parentAssetID && parent4.parentAssetID > 0) {
                     const parent5 = this.buildAssetParent(parent4.parentAssetID);
                     assetParentInfo.set(parent5.assetID, parent5);

                     if (parent5.parentAssetID && parent5.parentAssetID > 0) {
                        const parent6 = this.buildAssetParent(parent5.parentAssetID);
                        assetParentInfo.set(parent6.assetID, parent6);
                     }
                  }
               }
            }
         }
      }

      return assetParentInfo;
   }

   public async updateValueUnique(
      fieldID: number,
      value: number,
   ): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateValueUnique",
         },
         data: {
            fieldID: fieldID,
            value: value,
         },
      });
   }

   public async checkAssetNameUnique(
      assetNames: Array<string>,
      locationID,
   ): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "checkAssetNameUnique",
         },
         data: {
            assetNames: assetNames,
            locationID: locationID,
         },
      });
   }
   public async doesFieldHavePMType7Recurrence(
      fieldValue: AssetFieldValue,
   ): Promise<boolean> {
      for await (const template of this.taskTemplateApiService.getStreamedList({
         params: { assetIDs: [fieldValue.assetID], checklistTemplate: 1 },
         pagination: { limit: 20 },
         columns: "recurrences",
      })) {
         if (template.recurrences === undefined || template.recurrences.length === 0) {
            continue;
         }
         for (const recurrence of template.recurrences) {
            if (
               recurrence !== undefined &&
               recurrence.reoccurType === 7 &&
               recurrence.reoccurFld1?.includes(String(fieldValue.valueID))
            ) {
               return true;
            }
         }
      }

      return false;
   }

   /**
    * Updates the `isCustomDefault` property of an "asset field" object and saves
    * to the database.
    * @param fieldID - The ID of the asset field object to update
    * @param isCustomDefault - The new value for the `isCustomDefault` property
    * @returns true if successful or false if failed.
    */
   public async updateFieldIsCustomDefault(
      fieldID: number,
      isCustomDefault: boolean | 0 | 1,
   ): Promise<boolean> {
      const newValue = isCustomDefault ? 1 : 0;
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateFieldIsCustomDefault",
         },
         data: {
            fieldID: fieldID,
            isCustomDefault: newValue,
         },
      })
         .then((answer) => {
            if (answer.data.success === false) {
               return false;
            }
            const field = this.getFields().get(fieldID);
            assert(field);
            field.isCustomDefault = newValue;
            return true;
         })
         .catch(() => {
            return false;
         });
   }

   public updateDepreciationOptions = async (
      options: DepreciationOptions,
      fieldValueID: number,
      locationID: number,
      assetID: number,
   ): Promise<boolean> => {
      const timeStamp = options.depreciationStartDate?.getTime() ?? Date.now();
      const updatedOptions = {
         ...options,
         depreciationStartDate: timeStamp / 1000,
         scheduleActive: options.scheduleActive ? 1 : 0,
      };
      return axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "updateDepreciationOptions",
         },
         data: {
            options: updatedOptions,
            fieldValueID: fieldValueID,
            locationID: locationID,
            assetID: assetID,
         },
      }).then((answer) => {
         if (answer.data.success === false) {
            return false;
         }

         const returnedSchedule = answer.data.schedule;
         this.depreciationSchedules.set(
            returnedSchedule.depreciationID,
            answer.data.schedule,
         );

         if (
            Number(answer.data.schedule.scheduleActive) === 1 &&
            answer.data.valueUpdateArray
         ) {
            const fieldValue = this.getFieldValue(fieldValueID);
            assert(fieldValue);
            fieldValue.valueContent = answer.data.valueUpdateArray.newValue;
         }

         this.setAssetDepreciationId(assetID, returnedSchedule.depreciationID);

         return true;
      });
   };

   private setAssetDepreciationId(assetID: number, newDepreciationId: number): void {
      const assetToUpdate = this.getAsset(assetID);
      if (assetToUpdate) {
         assetToUpdate.assetDepreciationID = newDepreciationId;
      }
   }

   public findIdenticalFieldAtDifferentLocation(
      fieldID: number,
      targetLocationID: number,
   ): AssetField | undefined {
      const fieldToMatch = this.getField(fieldID);
      if (!fieldToMatch) {
         return undefined;
      }

      const fieldsAtLocation = this.fieldStore
         .lookup()
         .filter((field) => field.locationID === targetLocationID);

      const matchedField = fieldsAtLocation.find(
         (field) =>
            field.fieldName.toLowerCase() === fieldToMatch.fieldName.toLowerCase() &&
            Number(field.fieldTypeID) === Number(fieldToMatch.fieldTypeID),
      );

      return matchedField;
   }

   public getFieldValueFromFieldIDAndAssetID(
      fieldID: number,
      assetID: number,
   ): AssetFieldValue | undefined {
      const asset = this.getAsset(assetID);
      const fieldValues = asset?.assetValueIDs.map((valueID) =>
         this.getFieldValue(valueID),
      );
      const matchedFieldValue = fieldValues?.find(
         (fieldValue) => fieldValue?.fieldID === fieldID,
      );

      return matchedFieldValue;
   }

   public async popAsset(
      assetID: number,
      options?: {
         dataLogOptions?: DataLogEventDefinition | undefined;
         restrict?: boolean;
         preventParentAccess?: boolean;
         tabName?: string;
      },
   ): Promise<void> {
      const asset = this.getAsset(assetID);
      if (!asset) {
         return;
      }
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            dataLogOptions: options?.dataLogOptions,
            data: {
               restrict: options?.restrict ?? false,
               preventParentAccess: options?.preventParentAccess ?? true,
               navigateToTab: options?.tabName,
            },
         },
      };
      return instance.result;
   }

   public findParentAssetIDsToBaseOfTree(
      asset: Asset | undefined,
      parentAssetIDs: Array<number> = [],
   ): Array<number> {
      if (!asset?.parentAssetID) {
         return parentAssetIDs;
      }
      if (asset?.assetChildrenIDs.includes(asset.parentAssetID)) {
         throw new Error(`Looped parent-child relationship on assetID: ${asset.assetID}`);
      }
      const parentAsset = this.assetStore.get(asset.parentAssetID);

      parentAssetIDs.push(asset.parentAssetID);

      return this.findParentAssetIDsToBaseOfTree(parentAsset, parentAssetIDs);
   }

   public addFieldValueToLocalData(fieldValue: AssetFieldValue): void {
      this.fieldValueStore.add(fieldValue);
   }

   public updateFieldValuesInLocalData(fieldValues: Array<AssetFieldValue>): void {
      this.fieldValueStore.update(fieldValues);
   }

   public addFieldToLocalData(field: AssetField): void {
      this.fieldStore.add(field);
   }

   public async saveFieldLockedDefault(field: AssetField): Promise<AxiosResponse> {
      const userID = this.manageUser.getCurrentUser().userInfo.userID;

      const post = axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "saveFieldLockedDefault",
         },
         data: {
            fieldLocked: field.lockedDefault,
            fieldID: field.fieldID,
            userID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            if (answer.data.workflowsDisabled?.length) {
               for (const workflow of this.manageLocation.getLocation(field.locationID)
                  ?.workflowAutomations ?? []) {
                  if (answer.data.workflowsDisabled.includes(workflow.workflowID)) {
                     workflow.active = 0;
                  }
               }
            }
         }
      });

      return post;
   }

   public async getLinkedTaskInfo(
      fieldID: number,
   ): Promise<
      Array<
         Record<
            | "checklistID"
            | "checklistName"
            | "checklistTemplate"
            | "checklistTemplateOld",
            any
         >
      >
   > {
      const post = await axios({
         method: "POST",
         url: "phpscripts/manageAsset.php",
         params: {
            action: "getLinkedTaskInfo",
         },
         data: {
            fieldID,
         },
      });

      if (!post.data.success) {
         return [];
      }

      return post.data.tasks;
   }

   public getAssetTasksByName(tasks: TaskLookup, assetNameString: string): TaskLookup {
      return tasks.filter((task) => {
         if (task.assetID === null || task.assetID === 0) {
            return false;
         }
         const asset = this.getAsset(task.assetID);
         if (asset?.assetName) {
            return asset.assetName.toLowerCase().includes(assetNameString.toLowerCase());
         }
         return false;
      });
   }
}
