import { inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { FileStorageService } from "src/app/lite/local-db/resources/collection/task/file/file.storage.service";
import { instructionInitializer } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.initializer";
import { instructionSchema } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.schema";
import { InstructionStorageService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.service";
import type { InstructionUpdate } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.service.types";
import type { TaskInstruction } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.types";
import { TaskStorageService } from "src/app/lite/local-db/resources/collection/task/task.storage.service";
import { refetchValidData } from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import type { TaskInstructionComponentData } from "src/app/lite/types/display.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { DateUtilsService } from "src/app/shared/utils/date-utils.service";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { z } from "zod";

@Injectable({
   providedIn: "root",
})
export class InstructionStorageSyncService extends StorageSyncService {
   private readonly dateUtils = inject(DateUtilsService);
   private readonly instructionStorageService = inject(InstructionStorageService);
   private readonly fileStorageService = inject(FileStorageService);
   private readonly taskStorageService = inject(TaskStorageService);

   public constructor() {
      super();
   }

   public async syncInstruction(instructionID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = instructionInitializer;
         const instructions = await refetchValidData({
            url,
            params: { instructionIDs: [instructionID] },
            validation: z.array(instructionSchema),
         });
         const [instruction] = instructions;
         assert(
            instruction !== undefined,
            `Instruction #${instructionID} not found in fetch when attempting to sync`,
         );

         await this.instructionStorageService.putInstruction(instruction);
      });
   }

   public async syncInstructionDeletion(instructionID: number): Promise<void> {
      return this.sync(async () => {
         await this.instructionStorageService.deleteInstruction(instructionID);
      });
   }

   public async syncInstructionsByTask(taskID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = instructionInitializer;
         const instructions = await refetchValidData({
            url,
            params: { taskIDs: [taskID] },
            validation: z.array(instructionSchema),
         });

         await Promise.all(
            instructions.map(async (instruction) => {
               await this.instructionStorageService.putInstruction(instruction);
            }),
         );
      });
   }

   public async syncInstructionUpdate(
      instructionID: number,
      update: InstructionUpdate,
   ): Promise<void> {
      return this.sync(async () => {
         await this.instructionStorageService.updateInstruction(instructionID, update);
      });
   }

   public async syncInstructionsReset(taskID: number): Promise<void> {
      return this.sync(async () => {
         const instructions = await firstValueFrom(
            this.instructionStorageService.getInstructionsForTask$(taskID),
         );

         instructions.forEach((instruction) => {
            this.resetInstruction(instruction);
         });
      });
   }

   private resetInstruction(instruction: TaskInstruction): void {
      switch (instruction.itemTypeID) {
         case TaskInstructionTypeID.CheckBox:
         case TaskInstructionTypeID.Checklist:
         case TaskInstructionTypeID.Reassign:
            instruction.itemResponse = "0";
            break;

         case TaskInstructionTypeID.OptionList:
         case TaskInstructionTypeID.DropdownList:
            instruction.itemResponse = null;
            break;

         case TaskInstructionTypeID.TextBox:
         case TaskInstructionTypeID.DatePicker:
         case TaskInstructionTypeID.DeadlineDatePicker:
         case TaskInstructionTypeID.Number:
         case TaskInstructionTypeID.CaptureSignature:
         case TaskInstructionTypeID.VerifyLocation:
            instruction.itemResponse = "";
            break;

         case TaskInstructionTypeID.Note:
            // We don't reset Notes
            break;

         case TaskInstructionTypeID.FileOrPictureAttachment: {
            this.resetAttachmentInstruction(instruction.itemID);
            instruction.itemResponse = "0";
            break;
         }

         case TaskInstructionTypeID.AssignPM:
         case TaskInstructionTypeID.StartWO:
            if (
               instruction.itemResponse &&
               typeof instruction.itemResponse === "number" &&
               Number.isFinite(instruction.itemResponse) &&
               instruction.itemResponse > 0
            ) {
               this.taskStorageService.updateTask(instruction.checklistID, {
                  checklistDepreciated: Date.now(),
               });
               instruction.itemResponse = "0";
            }
            break;

         case TaskInstructionTypeID.RequestApproval:
            instruction.itemResponse = "";
            instruction.checklistToSpawn = 0;
            break;

         default: // unexpected
            instruction.itemResponse = null;
      }

      this.instructionStorageService.putInstruction(instruction);
   }

   private async resetAttachmentInstruction(instructionID: number): Promise<void> {
      const files = await firstValueFrom(
         this.fileStorageService.getAttachmentInstructionResponseFiles$(instructionID),
      );
      await Promise.all(
         files.map(async (file) => this.fileStorageService.deleteFile(file.fileID)),
      );
   }

   // private convertInstructionComponentDataToInstruction(
   //    instruction: TaskInstructionComponentData
   // ): TaskInstruction {
   //    const insertableInstruction = { ...instruction };
   //    insertableInstruction.itemResponse = this.convertInstructionResponseToString(
   //       instruction.itemResponse,
   //       instruction.itemTypeID
   //    );
   //    return insertableInstruction as TaskInstruction;
   // }

   private convertInstructionResponseToString(
      instructionResponse: TaskInstructionComponentData["itemResponse"],
      instructionTypeID: TaskInstructionTypeID,
   ): string | null {
      if (instructionResponse === null) return null;

      switch (instructionTypeID) {
         case TaskInstructionTypeID.CheckBox:
            return instructionResponse ? "1" : "0";
         case TaskInstructionTypeID.DatePicker:
         case TaskInstructionTypeID.DeadlineDatePicker:
            return String(
               this.dateUtils.dateToUnixTimestamp(instructionResponse as Date),
            );
         default:
            return String(instructionResponse);
      }
   }
}
