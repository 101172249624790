import { inject, Injectable } from "@angular/core";
import { CountedAndMeasuredPurchasable } from "src/app/parts/purchasable/counted-and-measured-purchasable.model";
import { CountedPurchasable } from "src/app/parts/purchasable/counted-purchasable.model";
import { MeasuredPurchasable } from "src/app/parts/purchasable/measured-purchasable.model";
import type { Purchasable } from "src/app/parts/purchasable/purchasable.model";
import { CountedStockUnitCalculator } from "src/app/parts/purchasable/services/stock-unit-calculator/calculators/counted/counted-stock-unit-calculator";
import { CountedAndMeasuredStockUnitCalculator } from "src/app/parts/purchasable/services/stock-unit-calculator/calculators/counted-and-measured/counted-and-measured-stock-unit-calculator";
import { MeasuredStockUnitCalculator } from "src/app/parts/purchasable/services/stock-unit-calculator/calculators/measured/measured-stock-unit-calculator";
import { InvalidPurchasableTypeError } from "src/app/parts/purchasable/services/stock-unit-calculator/errors/invalid-purchasable-type.error";
import type { StockUnitCalculator } from "src/app/parts/purchasable/services/stock-unit-calculator/stock-unit-calculator.interface";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";

@Injectable({ providedIn: "root" })
export class StockUnitCalculatorFactory {
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly manageParts = inject(ManageParts);

   public createCalculator(purchasable: Purchasable): StockUnitCalculator {
      if (purchasable instanceof CountedAndMeasuredPurchasable) {
         return new CountedAndMeasuredStockUnitCalculator(
            purchasable,
            this.unitOfMeasureService,
            this.manageParts,
         );
      }
      if (purchasable instanceof MeasuredPurchasable) {
         return new MeasuredStockUnitCalculator(
            purchasable,
            this.unitOfMeasureService,
            this.manageParts,
         );
      }
      if (purchasable instanceof CountedPurchasable) {
         return new CountedStockUnitCalculator(purchasable);
      }

      throw new InvalidPurchasableTypeError();
   }
}
