@if (vendor) {
   <div id="vendorWOPrint{{ vendor.vendorID }}">
      <!-- print related content -->
      <div class="panel-body panel-middleDarkBlue printViewShow">
         <div class="col-md-12">
            <h5 style="margin: 10px 0px 10px 0px">
               <span>
                  <lim-ui-icon
                     icon="wpforms"
                     iconSize="small"
                     iconColor="violet"
                     class="icon-styling"
                  />
                  <b>{{ lang().VendorTabWorkOrdersHeading }}</b> -
                  {{ lang().VendorTabWorkOrdersHeadingMsg }}.
               </span>
            </h5>
         </div>
      </div>

      <div class="wo-panels-wrapper">
         <!-- Open WorkOrders Section -->
         <lim-ui-panel [noSidePadding]="true">
            <div class="header-section-row">
               <div class="header-items-group">
                  <span>
                     <lim-ui-icon
                        icon="circleExclamation"
                        iconSize="small"
                        iconColor="violet"
                        class="icon-styling"
                     />
                     <b>{{ lang().OpenTasks }}</b>
                  </span>
                  <lim-ui-primary-button icon="plus" (click)="startWorkOrder()">{{
                     lang().StartWorkOrderVendor
                  }}</lim-ui-primary-button>
               </div>
            </div>

            <view-list-of-tasks
               [taskIDs]="taskIDs"
               [columns]="openWOColumns"
               [storedSortBindValue]="sortBindOpen"
               [autoHeight]="true"
               [tableInScrollablePage]="true"
            />

            @if (tasks.size === 0) {
               <div class="no-content-message">
                  <span>{{ lang().VendorDoesNotHaveAnyOpenTasks }}</span>
               </div>
            }
         </lim-ui-panel>

         <!-- Completed WorkOrders section-->
         <lim-ui-panel [noSidePadding]="true">
            <div class="header-section-row">
               <div class="header-items-group">
                  <span>
                     <lim-ui-icon
                        icon="squareCheck"
                        iconSize="small"
                        iconColor="violet"
                        class="icon-styling"
                     />
                     <b>{{ lang().CompletedTasks }}</b>
                  </span>

                  <lim-ui-search-box
                     [(searchVal)]="searchCompleted"
                     (searchValChange)="watchData()"
                     [placeholder]="lang().Search"
                  />
               </div>
            </div>

            <view-list-of-tasks
               [taskIDs]="completedTaskIDsToShow"
               [columns]="completedWOColumns"
               [storedSortBindValue]="sortBindCompleted"
               [noSearchResults]="noSearchResults"
               [autoHeight]="true"
               [tableInScrollablePage]="true"
            />

            <div class="no-content-message" [hidden]="!(completedTasks.size === 0)">
               {{ lang().ThereAreNotAnyCompletedWOsForThisVendor }}
            </div>
         </lim-ui-panel>
      </div>
   </div>
}
