<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header
      title="{{ lang().iMonnetTokenError }}"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      @if (errorMsg) {
         <lim-ui-alert [title]="lang().Warning" alertType="warning">
            <div [limbleHtml]="errorMsg"></div>
         </lim-ui-alert>
      }
      <lim-ui-panel>
         <div class="request-token-text">{{ lang().RequestANewiMonnitToken }}</div>

         <div class="lim-ui-form-column">
            <div class="lim-ui-form-group">
               <div class="input-container">
                  <label>
                     {{ lang().username }}
                  </label>

                  <div
                     limbleContentEditable
                     [(ngModel)]="username"
                     stripHtml="true"
                     name="iMonnit Username"
                  ></div>
               </div>
               <div class="input-container">
                  <label>
                     {{ lang().Password }}
                  </label>
                  <div
                     limbleContentEditable
                     [(ngModel)]="password"
                     stripHtml="true"
                     name="iMonnit Password"
                  ></div>
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Submit"
      (successClick)="submit()"
   />
</lim-ui-modal>
