@if (isMultiCurrencyEnabled() && currencyCode()) {
   <lim-ui-input-with-prefix
      [prefixText]="currencyCode() | currencySymbol"
      [value]="value"
      (valueChange)="handleValueChange($event)"
      [type]="'text'"
      [min]="min"
      [disabled]="disabled"
   />
} @else {
   <lim-ui-input-with-prefix
      [prefixText]="''"
      [value]="value | betterCurrency"
      (valueChange)="handleValueChange($event)"
      [type]="'text'"
      [min]="min"
      [disabled]="disabled"
   />
}
