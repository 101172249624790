import { unitCategoryDescriptions } from "src/app/parts/unit-of-measure/provided-units/descriptions";
import type { UnitDetails } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/unit-details.types";

export function getUnitDetails(name: string): UnitDetails | undefined {
   const categoryDescription = unitCategoryDescriptions.find((description) =>
      Object.hasOwn(description.detailsMap, name),
   );
   if (categoryDescription === undefined) return undefined;

   return {
      ...categoryDescription.detailsMap[name],
      category: categoryDescription.category,
   };
}
