<div class="threshold-settings-container">
   <div class="section">
      <div class="section-title">
         {{ i18n().t("SelectField") }}
      </div>
      <field-selection
         [recurrence]="recurrence()"
         (field)="selectedField.set($event)"
         (fieldValue)="selectedFieldValue.set($event)"
      />
   </div>
   <div class="section">
      <div class="section-title">
         {{ i18n().t("OccursAt") }}
      </div>
      <div class="above-below-between-container">
         <lim-ui-dropdown>
            <div
               class="dropdown-input table-field-text table-input-field-dropdown"
               button
            >
               <span
                  [limbleHtml]="thresholdTranslator[recurrence().reoccurFld3] ?? ''"
               ></span>
               <lim-ui-icon icon="angleDown" iconSize="small" />
            </div>
            <ng-container menu>
               <lim-ui-dropdown-text-item
                  [displayString]="i18n().t('Below')"
                  (click)="selectedConstraintType.set(0)"
               />
               <lim-ui-dropdown-text-item
                  [displayString]="i18n().t('Above')"
                  (click)="selectedConstraintType.set(1)"
               />
               <lim-ui-dropdown-text-item
                  [displayString]="i18n().t('Between')"
                  (click)="selectedConstraintType.set(2)"
               />
            </ng-container>
         </lim-ui-dropdown>
         <div class="number-input-container">
            <lim-ui-number-input-with-buttons
               [incrementBy]="1"
               [(unit)]="selectedConstraintValue1"
               min="0"
               [canEdit]="true"
            />
         </div>
         @if (recurrence().reoccurFld3 === 2) {
            <span>{{ i18n().t("and") }}</span>
         }
         @if (recurrence().reoccurFld3 === 2) {
            <div class="number-input-container">
               <lim-ui-number-input-with-buttons
                  [incrementBy]="1"
                  [(unit)]="selectedConstraintValue2"
                  min="0"
                  [canEdit]="true"
               />
            </div>
         }
      </div>
      <div class="hint-text">
         {{ i18n().t("ThisTaskWillBeCreated") }}
         <span class="threshold-range-text">
            @if (recurrence().reoccurFld3 === 0) {
               <span>
                  {{ i18n().t("below") }}
               </span>
            }
            @if (recurrence().reoccurFld3 === 1) {
               <span>
                  {{ i18n().t("above") }}
               </span>
            }
            @if (recurrence().reoccurFld3 === 2) {
               <span>
                  {{ i18n().t("between") }}
               </span>
            }
            {{ recurrence().reoccurFld2 }}
            @if (recurrence().reoccurFld3 === 2) {
               <span> {{ i18n().t("and") }} {{ recurrence().reoccurFld5 }} </span>
            }
            {{ selectedField()?.fieldName }}.
         </span>
      </div>
   </div>
</div>
