import {
   Component,
   computed,
   inject,
   input,
   type OnInit,
   type Signal,
   signal,
   DestroyRef,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   BasicModalHeaderComponent,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalFooterComponent,
   type ModalResult,
   PanelComponent,
   PrimaryButtonComponent,
   AlertComponent,
   TextButtonComponent,
   IconComponent,
   ModalService,
} from "@limblecmms/lim-ui";
import { catchError, of } from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import type {
   AssetsWithMergeConflicts,
   ConflictFieldDefinition,
   FieldCollisions,
   ApplyTemplateCriteria,
} from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import { AssetErrorService } from "src/app/assets/services/asset-error.service";
import { AssetTemplateService } from "src/app/assets/services/asset-template.service";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";

type FormattedCollision = {
   assetName: string;
   assetID: number;
   locationID: number;
   templateFieldName: string;
   localFields: Array<{ name: string; ID: number }>;
};

@Component({
   selector: "view-field-collisions-modal",
   standalone: true,
   imports: [
      BasicModalHeaderComponent,
      ModalComponent,
      ModalBodyComponent,
      PanelComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      TranslateDirective,
      AlertComponent,
      TextButtonComponent,
      IconComponent,
   ],
   templateUrl: "./view-field-collisions-modal.component.html",
   styleUrl: "./view-field-collisions-modal.component.scss",
})
export class ViewFieldCollisionsModalComponent
   implements ModalResult<boolean | undefined>, OnInit
{
   public fieldCollisionsInput: Signal<FieldCollisions | null> = input(null);
   public templateName: Signal<string> = input("");
   public applyCriteria: Signal<ApplyTemplateCriteria> = input.required();
   public fieldCollisions = signal(this.fieldCollisionsInput());

   public assetCount = computed(() => {
      return this.fieldCollisions()?.assets?.length ?? 0;
   });

   public formattedCollisions: Array<FormattedCollision> = [];

   public readonly modalRef: LimUiModalRef<
      ViewFieldCollisionsModalComponent,
      boolean | undefined
   > = inject(LimUiModalRef);

   private readonly fieldDefinitions: Signal<Lookup<"fieldID", ConflictFieldDefinition>> =
      computed(() => {
         return new Lookup("fieldID", this.fieldCollisions()?.fieldDefinitions ?? []);
      });

   private readonly assetTemplateService = inject(AssetTemplateService);
   protected readonly modalService = inject(ModalService);
   protected readonly paramsService = inject(ParamsService);
   private readonly assetErrorService = inject(AssetErrorService);
   private readonly destroyRef = inject(DestroyRef);

   public ngOnInit(): void {
      this.fieldCollisions.set(this.fieldCollisionsInput());
      this.setFormattedCollisions();
   }

   public openAssetModal(assetID: number, locationID: number) {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: assetID,
            locationID: locationID,
            data: {
               restrict: false,
            },
            closeModalFunc: () => {
               this.rebuild();
            },
         },
      };
   }

   /**
    * Fetches collisions again and rebuilds the list of errors.
    */
   private rebuild() {
      const applyCriteria = this.applyCriteria();
      // If we don't have any fieldMappings, we can't check for collisions
      if (
         applyCriteria === undefined ||
         Object.keys(applyCriteria.fieldMappings).length === 0
      ) {
         return;
      }
      this.assetTemplateService
         .checkMergeFieldCollisions(applyCriteria)
         .pipe(
            catchError((err) => {
               this.assetErrorService.handleRequestError(err);
               return of(null);
            }),
            takeUntilDestroyed(this.destroyRef),
         )
         .subscribe((result) => {
            /**
             * If there aren't any more collisions found, just close the modal so the
             * user can continue with applying.
             */
            const hasConflicts = (result?.assets?.length ?? 0) > 0;
            if (hasConflicts === false) {
               this.fieldCollisions.set(null);
            } else {
               this.fieldCollisions.set(result);
            }
            this.formattedCollisions = []; // Resetting to rebuild
            this.setFormattedCollisions();
         });
   }

   /**
    * This formats the collisions into an array of objects
    * the template can more easily use.
    */
   private setFormattedCollisions() {
      const collisions = this.fieldCollisions()?.assets ?? [];
      for (const asset of collisions) {
         for (const conflict of this.getAssetMergeConflicts(asset)) {
            const templateFieldID = conflict[0];
            const localFieldIDs = conflict[1] ?? [];
            this.formattedCollisions.push({
               assetName: asset.name,
               assetID: asset.id,
               locationID: asset.locationID,
               templateFieldName: this.getFieldName(templateFieldID),
               localFields: localFieldIDs.map((fieldID) => ({
                  name: this.getFieldName(fieldID),
                  ID: fieldID,
               })),
            });
         }
      }
   }

   private getAssetMergeConflicts(asset: AssetsWithMergeConflicts) {
      return Object.entries(asset.mergeConflicts);
   }

   private getFieldName(id: string | number) {
      return this.fieldDefinitions().get(Number(id))?.fieldName ?? "";
   }

   protected close() {
      this.modalRef.close(false);
   }
}
