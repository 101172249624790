import { AsyncPipe, NgClass, NgStyle } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, forwardRef, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import {
   BadgeComponent,
   BasicModalFooterComponent,
   BlockIconComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalHeaderComponent,
   OutlinedButtonComponent,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   RadioButtonComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   TextButtonComponent,
   TooltipDirective,
   UpsellPopover,
   isMobile,
} from "@limblecmms/lim-ui";
import moment from "moment";
import clone from "rfdc";
import {
   BehaviorSubject,
   Subject,
   Subscription,
   forkJoin,
   lastValueFrom,
   map,
   takeUntil,
   first,
} from "rxjs";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { CommentFiles } from "src/app/files/components/commentFiles/commentFiles.element.component";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { CustomizePriorities } from "src/app/settings/components/customizePrioritiesModal/customizePriorities.modal.component";
import { EmailTemplate } from "src/app/shared/components/global/emailTemplateModal/emailTemplate.modal.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { TimeOfDayPicker } from "src/app/shared/components/global/timeOfDayPickerElement/timeOfDayPicker.element.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { EditInstructionsComponent } from "src/app/tasks/components/task-form/edit-instructions/edit-instructions.component";
import { TaskFormComponent } from "src/app/tasks/components/task-form/task-form.component";
import { WorkOrderTemplatesComponent } from "src/app/tasks/components/work-order-templates-component/work-order-templates.component";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { SetupWorkOrderService } from "src/app/tasks/services/setup-work-order.service";
import type { TaskInfo } from "src/app/tasks/types/info/task-info.types";
import { AddUser } from "src/app/users/components/addUserModal/addUser.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

const deepClone = clone();

@Component({
   selector: "setup-work-order",
   templateUrl: "./setupWorkOrder.modal.component.html",
   styleUrls: ["./setupWorkOrder.modal.component.scss"],
   imports: [
      EditInstructionsComponent,
      ModalComponent,
      ModalDirective,
      ModalHeaderComponent,
      LimbleHtmlDirective,
      NgClass,
      SecondaryButtonComponent,
      TooltipDirective,
      MinimalIconButtonComponent,
      ModalBodyComponent,
      PanelComponent,
      LoadingAnimationComponent,
      forwardRef(() => TaskFormComponent),
      FormsModule,
      DropdownComponent,
      IconComponent,
      forwardRef(() => AssetParentList),
      DropdownTextItemComponent,
      ContenteditableDirective,
      CommentFiles,
      NgStyle,
      DropdownItemComponent,
      DropdownDividerComponent,
      DatePickerInputComponent,
      TimeOfDayPicker,
      RadioButtonComponent,
      SearchBoxComponent,
      NoSearchResults,
      BlockIconComponent,
      UserImage,
      OutlinedButtonComponent,
      BasicModalFooterComponent,
      OrderByPipe,
      AsyncPipe,
      BadgeComponent,
      UpsellPopover,
      PopoverDirective,
      TextButtonComponent,
      PrimaryButtonComponent,
   ],
})
export class SetupWorkOrder implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public selected;
   public message;
   public title;
   public data;
   public loadingBar: boolean = false;
   public selectedUnassigned;
   public usersLocked;
   public restrictedSelections;
   public defaultProfileHidden;
   public sourceItemID;
   public hasPredefinedAsset;
   public asset: Asset | undefined;
   public userClicks;
   public veryFirstClick;
   public veryFirstUserID;
   public locations;
   public task: TaskTemplateEntity | TaskEntity | undefined;
   public taskData;
   public customerID;
   public showAssignment;
   public dueDateOptions;
   public showMachineDownAlert;
   public priority;
   public name;
   public description;
   public firstItemID;
   public firstItemBatchID;
   public showMachineDownAlertButtonText;
   public machineDownAlert;
   public users;
   public profiles;
   public userProfiles;
   public allUsers;
   public allProfiles;
   public noSearchResults;
   public manageProfileCred;
   public WOtype;
   public dueDate;
   public timeOfDay;
   public AMPM;
   public hour;
   public minute;
   public startDateShow;
   public startDate;
   public files;
   public WOname;
   public WOdescription;
   public taskDescription;
   public searchUsersTeams;
   public selectedProfile;
   protected hasSelectedUserOrProfile: boolean = false;
   public uploadObj;
   public deleteFile;
   public priorityListSub;
   public priorityList: any = [];
   public priorityListIndex = {};
   public superUser;
   protected canAddUsers: boolean = false;
   public isMobile;
   public info!: TaskInfo;
   protected readMoreContent: boolean = false;
   protected featureUnlimitedWOs: boolean = false;
   protected WOInstructionLimitReached$: BehaviorSubject<boolean> = new BehaviorSubject(
      true,
   );
   protected featureLimitedWOs: boolean = false;
   private manageFeatureFlagsSub: Subscription = new Subscription();
   private selectedTemplateID = null;
   protected woInstructionLimit: number = 2;
   protected canAddInstruction: boolean = false;
   protected showErrorValidations: boolean = false;
   protected canAddNewInstruction: boolean = false;
   private allowPickLocation = false;
   private readonly destroy$ = new Subject<void>();

   protected readonly i18n = inject(TranslationService).i18n;
   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageObservables = inject(ManageObservables);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly setupWorkOrderService = inject(SetupWorkOrderService);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly router = inject(Router);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      //here are defaults
      this.info = {
         mode: "template",
         items: [],
         options: [],
         editable: true,
         expand: true,
         limited: false,
         limitedSettings: false,
         disableAlerts: false,
         buildData: () => {
            const task = this.task;
            if (!task) {
               return;
            }
            forkJoin([
               this.setupWorkOrderService.getChecklistData({
                  asset: this.asset,
                  task: task,
               }),
               this.setupWorkOrderService.canAddNewInstruction(task, this.info),
            ])
               .pipe(takeUntil(this.destroy$))
               .subscribe(([checklistData, canAddNewInstruction]) => {
                  this.info.items = checklistData.items;
                  this.info.task = task;
                  this.canAddNewInstruction = canAddNewInstruction;
               });
         },
         setGuides: () => {
            return undefined;
         },
         // eslint-disable-next-line typescript/no-unused-vars -- required by interface
         submitTags: (newMentionedList) => {
            return undefined;
         },
         openLogTimeModal: () => {
            return undefined;
         },
         rebuildTags: () => {
            return undefined;
         },
         incGreyOutWatchVar: () => {
            return undefined;
         },
         incItemWatchVar: () => {
            return undefined;
         },
         calcCompletion: () => {
            return true;
         },
         // eslint-disable-next-line typescript/no-unused-vars -- required by interface
         doWeCheck: (item, _currentStack = 1) => {
            return true;
         },
         complete: async () => {
            return Promise.resolve(undefined);
         },
      };

      this.initializeComponent();
   }

   private initializeComponent() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.isMobile = isMobile();

      this.priorityListSub = this.manageObservables.setSubscription(
         "priorityList",
         () => {
            this.priorityList = this.managePriority.getPriorityList();
            this.priorityListIndex = this.managePriority.getPriorityListIndex();
         },
      );
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );

      this.selected = 0;
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.data = this.resolve.data;
      this.loadingBar = false;
      this.selectedUnassigned = false;
      this.usersLocked = Boolean(Number(this.data.defaultOwnerLocked));
      this.restrictedSelections = false;
      this.defaultProfileHidden = false;
      this.sourceItemID = this.data.sourceItemID || 0;
      this.hasPredefinedAsset = this.data.hasPredefinedAsset || false;
      this.asset = this.manageAsset.getAsset(this.data.assetID);
      this.userClicks = 0; //user clicks is a method to allow them to double click the selection to submit ONLY if the user behavior is they double clicked a single one.
      this.veryFirstClick = false;
      this.veryFirstUserID = 0;
      this.locations = this.manageLocation.getLocationsIndex();
      this.priority = this.managePriority.getDefaultPriority();
      this.selectedTemplateID = this.data.selectedTemplateID;
      this.allowPickLocation = this.data.allowPickLocation;

      this.canAddUsers = this.credService.isAuthorized(
         this.data.locationID,
         this.credService.Permissions.AddUsers,
      );

      if (this.setupWorkOrderService.isPlgSetupWorkOrderEnabled()) {
         if (this.data.assetID === 0) {
            const unsureAsset: any = {};
            unsureAsset.assetID = 0;
            this.asset = unsureAsset;
         }
      } else {
         if (this.asset && !this.asset.assetID) {
            this.asset.assetID = 0;
         }
      }

      if (
         this.manageUser.getCurrentUser().userInfo.customerStartingAWODefaultState == 1
      ) {
         this.data.WOState = "advanced";
      } else {
         this.data.WOState = "simple";
      }

      if (this.data.date === undefined || this.data.date == false) {
         const currentDate = new Date();
         currentDate.setDate(
            currentDate.getDate() +
               Number(
                  this.manageUser.getCurrentUser().userInfo
                     .customerStartingAWODefaultDueDateDays,
               ),
         );
         this.dueDate = currentDate;
      } else {
         const date = moment(this.data.date).toDate();
         this.dueDate = new Date(date);
      }

      this.resetStartDate();

      this.files = [];
      this.setUploadObj();

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$
         .pipe(first())
         .subscribe((isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureUnlimitedWOs = isFeatureEnabledMap.featureUnlimitedWOs;

            this.manageFeatureFlags
               .hasReachedWOInstructionLimitFor(moment())
               .then((hasReachedLimit) => {
                  this.woInstructionLimit =
                     this.manageFeatureFlags.getWOInstructionLimit();
                  this.canAddInstruction = !hasReachedLimit;
                  this.WOInstructionLimitReached$.next(hasReachedLimit);
                  const allowAssetTemplates = !hasReachedLimit;
                  this.initializeWorkOrder(allowAssetTemplates);
               })
               .catch((error: unknown) => {
                  console.error(
                     "Error checking featureUnlimitedWOs limit for WO creation.",
                     error,
                  );
               });
         });
   }

   public navigateToProfileManagement() {
      this.router.navigate([`/profileManagement/${this.data.locationID}`]);
   }

   protected get isPlgSetupWorkOrderEnabled() {
      return this.setupWorkOrderService.isPlgSetupWorkOrderEnabled;
   }
   protected addInstruction() {
      const localTask = this.task;
      if (!localTask) {
         throw new Error("task was not defined");
      }
      this.setupWorkOrderService
         .addNewInstruction(localTask, this.info)
         .pipe(
            takeUntil(this.destroy$),
            map((success) => {
               if (!success) {
                  throw new Error("Failed to add instruction");
               }
               return success;
            }),
         )
         .subscribe({
            next: () => {
               this.info.buildData();
               this.alertService.addAlert(this.lang().successMsg, "success", 3000);
            },
            error: () => {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            },
         });
   }

   protected async initializeWorkOrder(allowAssetTemplates: boolean) {
      if (this.selectedTemplateID && this.selectedTemplateID > 0) {
         // If a specific WO template has been chosen via a PM Template instruction, use that
         this.data.defaultTemplateID = this.selectedTemplateID;
      } else {
         if (
            //if we are starting the WO as advanced AND we want it to start in instance then we should load it as if it is a WO template
            this.data.WOState === "advanced" &&
            this.manageUser.getCurrentUser().userInfo
               .customerStartingAWOTemplateDefaultState == 0
         ) {
            if (
               this.data.defaultTemplateID === undefined ||
               this.data.defaultTemplateID == false ||
               this.data.defaultTemplateID == 0
            ) {
               //if it isn't set that means it was
               this.data.defaultTemplateID =
                  this.manageLocation.getLocationsIndex()[
                     this.data.locationID
                  ].checklistID; //set the default template and then treat this as a WO template
            }
            this.data.WOState = "woTemplate";
            this.task = undefined;
            this.loadingBar = true;
         }

         //Always overwrite defaultChecklistID with the asset defaultChecklistID if it exists,
         //regardless of whether WOState is advanced or simple.
         if (allowAssetTemplates && this.asset?.defaultChecklistID) {
            assert(this.asset !== undefined);
            this.data.defaultTemplateID = this.asset.defaultChecklistID;
            this.data.WOState = "woTemplate";
            this.task = undefined;
            this.loadingBar = true;
         }
      }

      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.showAssignment = true;
      if (this.customerID == 1175 || this.customerID == 2591) {
         //BRYAN TO DO... we may want this a customizable flag for more customers
         if (this.usersLocked == true) {
            this.showAssignment = false;
         }
      }

      this.dueDateOptions = {};
      if (this.customerID == 1409) {
         //BRYAN TO DO... we will want this to be a customizable flag for more customers
         this.dueDateOptions.minDate = new Date();
      }

      this.showMachineDownAlert = false;

      if (
         this.data.WO &&
         (this.data.defaultTemplateID === undefined ||
            this.data.defaultTemplateID == false ||
            Number(this.data.defaultTemplateID) < 0)
      ) {
         //if defaultTemplateID is less then 0 that means we aren't using it.
         this.name = "";
         this.description = "";
         this.loadingBar = true;
         setTimeout(() => {
            this.manageTask
               .createWorkOrder(
                  this.data.assetID,
                  this.data.locationID,
                  0,
                  this.sourceItemID,
               )
               .then(async (answer) => {
                  if (answer.data.success == true) {
                     this.loadingBar = false;

                     this.task = await lastValueFrom(
                        this.taskTemplatesApiService.getById(
                           answer.data.task.checklistID,
                           {
                              filters: { allowDeleted: true },
                           },
                        ),
                     );

                     this.firstItemID = answer.data.firstItemID;
                     this.firstItemBatchID = answer.data.firstItemBatchID;
                     this.setUploadObj();

                     if (this.task) {
                        this.info.buildData();
                        this.task.priorityID = this.priority.priorityID;
                        //promise based
                        this.taskData = {
                           checklistID: this.task?.checklistID,
                           mode: "template",
                           expand: false,
                           limited: false,
                           limitedSettings: false,
                           disableAlerts: true,
                           options: {
                              editChecklistName: Boolean(this.data.editChecklistName),
                           },
                           checklistTemplate: this.task?.checklistTemplate,
                        };

                        if (this.locations[this.task.locationID].machineDownAlert == 1) {
                           //sets if we should show machinedown alert button
                           this.showMachineDownAlert = true;
                           this.showMachineDownAlertButtonText =
                              this.locations[this.task.locationID]
                                 .machineDownAlertButtonText ??
                              this.i18n().t("MachineDownAlert");
                        } else {
                           this.showMachineDownAlert = false;
                        }
                     }
                  }
               });

            this.machineDownAlert = (chk) => {
               let body =
                  this.locations[chk.locationID].machineDownAlertBody ??
                  this.i18n().t("MachineDownBodyText");
               if (chk.assetID > 0) {
                  body = body.replace(
                     "{{assetName}}",
                     this.manageAsset.getAsset(chk.assetID)?.assetName,
                  );
               } else {
                  body = body.replace("{{assetName}}", this.lang().NotAssignedToAnAsset);
               }

               body = body.replace(/<div>/gi, "");
               body = body.replace(/<\/div>/gi, "");

               const emailTo = this.locations[chk.locationID].machineDownAlertTo;
               const lastChar = emailTo.substr(emailTo.length - 1);
               if (lastChar !== ";") {
                  this.locations[chk.locationID].machineDownAlertTo = `${
                     this.locations[chk.locationID].machineDownAlertTo
                  };`;
               }

               const instance = this.modalService.open(EmailTemplate);

               this.paramsService.params = {
                  modalInstance: instance,
                  resolve: {
                     message: this.lang().emailReminderMessage,
                     title: this.lang().emailReminderTitle,
                     data: {
                        emailTo:
                           String(this.locations[chk.locationID].machineDownAlertTo) +
                           this.locations[chk.locationID].machineDownAlertCC,
                        emailSubject:
                           this.locations[chk.locationID].machineDownAlertSubject ??
                           this.i18n().t("MachineDownAlert"),
                        emailMessage: body,
                        pickEmployees: false,
                        checklistID: chk.checklistID,
                        onSubmit: "send",
                     },
                  },
                  backdrop: "static",
                  keyboard: false,
               };
            };
         }, 1);
      }

      const initializeFirstDefaultTemplateWO = Number(this.data.defaultTemplateID) > 0;
      this.initializeUsersAndProfilesWorkWithOptionalWOTemplate(
         initializeFirstDefaultTemplateWO,
      );
      this.noSearchResults = false;

      this.manageProfileCred = this.credService.isAuthorized(
         this.data.locationID,
         this.credService.Permissions.ViewManageTeams,
      );
      //sets what the type of this work is
      if (this.data.WOState === "woTemplate" && this.data.defaultTemplateID > 0) {
         const template = await lastValueFrom(
            this.taskTemplatesApiService.getById(this.data.defaultTemplateID, {
               params: { allowDeleted: true },
            }),
         );
         if (template?.checklistTemplate) {
            this.WOtype = Number(template.checklistTemplate);
         } else {
            this.setWOTypeFromCustomerSettings();
         }
      } else {
         this.setWOTypeFromCustomerSettings();
      }
   }

   protected openAddUserModal(): void {
      if (!this.canAddUsers) return;

      const modalRef = this.modalService.open(AddUser);
      modalRef.setInput("locationID", this.data.locationID);

      modalRef.result.then((newUserID) => {
         if (newUserID) {
            const initializeFirstDefaultTemplateWO = false;
            const autoSelectUserID = newUserID;
            this.initializeUsersAndProfilesWorkWithOptionalWOTemplate(
               initializeFirstDefaultTemplateWO,
               autoSelectUserID,
            );
         }
      });
   }

   public resetStartDate() {
      this.timeOfDay = false;
      this.startDateShow = false;
      this.startDate = {};
      this.startDate.timeOfDay = false;

      if (this.manageUser.getCurrentUser().userInfo.customerClockFormat == "24-hr") {
         this.hour = "00";
         this.AMPM = "";
         this.startDate.hour = "00";
         this.startDate.AMPM = "";
      } else {
         this.hour = "12";
         this.AMPM = "AM";
         this.startDate.hour = "12";
         this.startDate.AMPM = "AM";
      }
      this.minute = "00";
      this.startDate.minute = "00";
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.priorityListSub);
      this.manageFeatureFlagsSub.unsubscribe();
      this.destroy$.next();
      this.destroy$.complete();
   }

   private initializeUsersAndProfilesWorkWithOptionalWOTemplate(
      initializeFirstDefaultTemplateWO: boolean = false,
      autoSelectUserID: number = 0,
   ): void {
      const result = this.manageUser.getUsersAndProfilesAtLocation(
         this.data.locationID,
      ) as any;
      this.users = result.data.users || [];
      this.users = deepClone(this.users);
      this.users = this.manageFilters.filterToUsersThatCanCompleteWork(
         this.users,
         {
            locations: [this.data.locationID],
         },
         this.manageUser,
         this.manageProfile,
      ); //if the user is only a view only then we shouldn't be able to assign to them

      for (const user of this.users) {
         if (user.selected) {
            user.selected = false;
         }
      }

      this.profiles = result.data.profiles || [];
      this.profiles = this.manageFilters.filterProfilesToWhoCanCompleteWork(
         this.profiles,
         this.manageProfile,
      ); //removes View only profile for example
      this.userProfiles = result.data.userProfiles || [];

      //we also need to remove any global roles that don't have someone assigned to them
      for (let index = this.profiles.length - 1; index >= 0; index--) {
         if (
            this.profiles[index].locationID == 0 && //a global role
            this.profiles[index].profileParent == 0 //a global role that wasn't a default role (manager, tech, view only)
         ) {
            let found = false;
            for (const userProfile of this.userProfiles) {
               //check all of the profiles at this location and make sure that someone is assigned that role,  if they are then we want to keep it if not remove it
               if (userProfile.profileID == this.profiles[index].profileID) {
                  found = true;
               }
            }
            if (found == false) {
               this.profiles.splice(index, 1);
            }
         }
      }

      for (const userProfile of this.userProfiles) {
         for (const user of this.users) {
            if (userProfile.userID == user.userID) {
               user.search = `${user.search + userProfile.profileDescription} `;
            }
         }
      }

      for (const user of this.users) {
         user.hint = false;
      }

      if (initializeFirstDefaultTemplateWO) {
         this.data.WOState = "woTemplate";
         this.task = undefined;
         this.loadingBar = true;
         this.manageTask
            .createWorkOrder(
               this.data.assetID,
               this.data.locationID,
               this.data.defaultTemplateID,
               this.sourceItemID,
            )
            .then(async (answer) => {
               if (answer.data.success == true) {
                  this.loadingBar = false;
                  this.firstItemID = answer.data.firstItemID;
                  this.firstItemBatchID = answer.data.firstItemBatchID;

                  let mode;

                  if (
                     this.manageUser.getCurrentUser().userInfo
                        .customerStartingAWOTemplateDefaultState == 1
                  ) {
                     mode = "template";
                     this.task = await lastValueFrom(
                        this.taskTemplatesApiService.getById(
                           answer.data.task.checklistID,
                           {
                              filters: { allowDeleted: true },
                           },
                        ),
                     );
                  } else {
                     mode = "instance";
                     this.task = await lastValueFrom(
                        this.taskTemplatesApiService.getById(
                           answer.data.task.checklistID,
                           {
                              filters: { allowDeleted: true },
                           },
                        ),
                     );
                  }

                  if (this.task) {
                     this.info.buildData();
                     //promise based
                     this.taskData = {
                        checklistID: this.task?.checklistID,
                        mode: mode,
                        expand: false,
                        limited: false,
                        limitedSettings: false,
                        disableAlerts: true,
                        preview: false,
                        options: {
                           editChecklistName: Boolean(this.data.editChecklistName),
                        },
                        checklistTemplate: this.task.checklistTemplate,
                     };

                     this.setUploadObj();

                     if (this.locations[this.task.locationID].machineDownAlert == 1) {
                        //sets if we should show machinedown alert button
                        this.showMachineDownAlert = true;
                        this.showMachineDownAlertButtonText =
                           this.locations[this.task.locationID]
                              .machineDownAlertButtonText ??
                           this.i18n().t("MachineDownAlert");
                     } else {
                        this.showMachineDownAlert = false;
                     }
                  }
               }
            });
      }

      if (this.data.defaultProfileID) {
         this.focusProfile(this.data.defaultProfileID);

         if (
            //This is used to turn users green if they are part of the default profile
            Number(this.data.defaultProfileID) > 0 &&
            !this.manageProfile.getProfilesIndex()[this.data.defaultProfileID]
         ) {
            this.defaultProfileHidden = true;
         }
      } else if (this.data.defaultUserID > 0) {
         for (const user of this.users) {
            if (user.userID == this.data.defaultUserID) {
               this.focusUser(user);
               this.veryFirstClick = true;
               this.veryFirstUserID = this.data.defaultUserID;
            }
         }
      } else if (this.data.defaultUserID == -101) {
         // -101 means the user can only choose teams they belong to
         this.restrictedSelections = true;
         const tmp = {};
         result.data.userProfiles //find teams they belong to
            .filter(
               (userProfile) =>
                  userProfile.userID == this.manageUser.getCurrentUser().gUserID,
            )
            .forEach((userProfile) => {
               tmp[userProfile.profileID] = true;
            });

         for (const profile of this.profiles) {
            profile.restricted = !tmp[profile.profileID];
            //retrict based on the user being part of the team
         }

         for (const user of this.users) {
            user.restricted = user.userID != this.manageUser.getCurrentUser().gUserID; // users restricted
         }

         this.data.defaultUserID = this.manageUser.getCurrentUser().gUserID;

         for (const user of this.users) {
            if (user.userID == this.manageUser.getCurrentUser().gUserID) {
               this.focusUser(user);
            }
         }
      } else if (this.data.defaultUserID == -100) {
         // -100 means current user
         this.data.defaultUserID = this.manageUser.getCurrentUser().gUserID;

         for (const user of this.users) {
            if (user.userID == this.manageUser.getCurrentUser().gUserID) {
               this.focusUser(user);
            }
         }
      }

      // If there are no users selected and the user just made a new user, select the lastest new user after creation
      if (autoSelectUserID > 0 && this.users?.length > 0) {
         const userToSelect = this.users.find((user) => user.userID === autoSelectUserID);
         if (userToSelect) {
            this.focusUser(userToSelect);
         }
      }

      this.allUsers = this.users;
      this.allProfiles = this.profiles;
   }

   private setWOTypeFromCustomerSettings() {
      if (this.manageUser.getCurrentUser().userInfo.customerStartingAWODefaultType == 1) {
         this.WOtype = 4;
      } else {
         this.WOtype = 2;
      }
   }

   protected chkName(): void {
      const task: any = {};
      task.checklistID = this.task?.checklistID;
      task.checklistName = this.WOname;

      //WOname needs to be at least 3 chars
      if (this.WOname.length < 3) {
         return;
      }

      this.manageTask
         .updateTaskName(task.checklistID, task.checklistName)
         .then((answer) => {
            if (answer.data.success != true) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   runUserProfileSearch = () => {
      const search = this.searchUsersTeams.toLowerCase();

      const userLookupSelected = {};
      for (const user of this.users) {
         userLookupSelected[user.userID] = user.selected;
      }

      this.users = [];
      for (const user of this.allUsers) {
         if (userLookupSelected[user.userID]) {
            //if they are selected we have to mark them as selected
            user.selected = true;
            this.users.push(user);
         } else if (
            user.userFirstName?.toLowerCase().includes(search) ||
            user.userLastName?.toLowerCase().includes(search)
         ) {
            this.users.push(user);
         }
      }

      this.profiles = [];
      for (const profile of this.allProfiles) {
         if (
            profile.profileID == this.selectedProfile ||
            profile.profileDescription.toLowerCase().includes(search)
         ) {
            this.profiles.push(profile);
         }
      }

      this.noSearchResults = false;
      if (
         this.users.length == 0 &&
         this.profiles.length == 0 &&
         this.allUsers.length > 0
      ) {
         this.noSearchResults = true;
      }
   };

   expandReadMore = () => {
      this.readMoreContent = true;
   };

   collapseReadMore = () => {
      this.readMoreContent = false;
   };

   close = () => {
      this.modalInstance.close(0);
   };

   resetUserSelection = () => {
      if (this.users) {
         for (const user of this.users) {
            user.hint = false;
            user.selected = false;
         }
      }
      this.userClicks = 0;
   };

   focusUser = (user) => {
      if (this.data.defaultUserID == -101 || this.data.defaultUserID == -102) {
         //-101 is let user pick among their teams and -102 is let the user pick.
         //We don't want to check for defaultOwnerLocked (Force Selection for those)
      } else if (this.data.defaultUserID != user.userID) {
         if (this.data.defaultOwnerLocked) return;
      }

      this.defaultProfileHidden = false; //if a change is allowed, no longer use the default profile
      this.selectedProfile = 0;
      this.selectedUnassigned = false;

      if (this.veryFirstClick && user.userID != this.veryFirstUserID) {
         //veryFirstClick is used to help smooth out the user experience when someone starts with a default selection.  If they do and they pick someone else then it will deselect themselves.  This is because MOST of the time they will want to select some other person.
         this.veryFirstClick = false;

         for (const userToChange of this.users) {
            userToChange.selected = false;
         }
      }

      //set all the users hint to false.  (hint is used to show which user belongs to which profile)
      for (const userToChange of this.users) {
         userToChange.hint = false;
      }

      if (user.selected == true) {
         //they picked one that was already selected
         if (this.userClicks <= 0) {
            //only one is selected so they meant to double tap it to select
            this.submit();
            return;
         }
         //they have more then 1 so they are trying to deselect this.
      }
      this.userClicks++;
      this.checkResetUserClicks();

      user.selected = !user.selected;
   };

   checkResetUserClicks = () => {
      this.userClicks = this.manageUtil.checkResetUserClicks(this.users, this.userClicks);
   };

   focusProfile = (profileID) => {
      if (
         this.data.defaultOwnerLocked &&
         profileID != this.data.defaultProfileID &&
         this.profiles.filter(
            // allows unrestricted profiles to be chosen
            (profile) => profile.profileID == profileID && profile.restricted === false,
         ).length === 0
      ) {
         return;
      }
      this.defaultProfileHidden = false; //when changing the selection, no longer use the default profile
      this.resetUserSelection();
      this.selectedUnassigned = false;

      for (const user of this.users) {
         user.hint = false;
      }

      for (const userProfile of this.userProfiles) {
         if (userProfile.profileID == profileID) {
            for (const user of this.users) {
               if (user.userID == userProfile.userID) {
                  user.hint = true;
               }
            }
         }
      }

      if (this.selectedProfile == profileID && !this.data.defaultOwnerLocked) {
         //already selected so submit
         this.submit();
         return;
      }

      this.selectedProfile = profileID;
   };

   focusUnassigned = () => {
      if (this.data.defaultOwnerLocked) {
         return;
      }
      this.defaultProfileHidden = false; //when changing selection no longer use the default profile
      for (const user of this.users) {
         user.hint = false;
         user.selected = false;
      }

      this.selectedProfile = 0;

      if (this.selectedUnassigned == true) {
         //already selected so submit
         this.submit();
         return;
      }

      this.selectedUnassigned = true;
   };

   protected setWoType(woType: 2 | 4) {
      this.WOtype = woType;
   }

   public initializeStartDate() {
      if (this.startDate.date === undefined || this.startDate.date == false) {
         this.startDate.date = new Date();
      }
   }

   public async submit() {
      if (this.dueDate == null || this.dueDate == false) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAValidDueDate,
            "warning",
            6000,
         );
         return;
      }

      let startDate;
      let dueDate: any = this.manageLocation.findLocationDay230(
         this.dueDate,
         this.data.locationID,
      );

      dueDate = new Date(dueDate * 1000);

      if (this.timeOfDay == true) {
         if (this.AMPM === "PM" && this.hour != 12) {
            this.hour = `${Number(this.hour) + 12}`;
         }
         if (this.AMPM === "AM" && this.hour == "12") {
            this.hour = 0;
         }

         dueDate.setHours(this.hour);
         dueDate.setMinutes(this.minute);
         dueDate.setSeconds(0);
      } else {
         dueDate.setHours(2);
         dueDate.setMinutes(0);
         dueDate.setSeconds(1);
      }

      dueDate = dueDate.getTime() / 1000;

      if (this.startDateShow == true) {
         //ok they plan on using it
         if (this.startDate.date === undefined || this.startDate.date == false) {
            //make sure something is set
            this.alertService.addAlert(
               this.lang().PleaseSelectAStartDate,
               "warning",
               6000,
            );

            return;
         }

         startDate = this.manageLocation.findLocationDay230(
            this.startDate.date,
            this.data.locationID,
         );

         startDate = new Date(startDate * 1000);

         if (this.startDate.timeOfDay == true) {
            if (this.startDate.AMPM === "PM" && this.startDate.hour != 12) {
               this.startDate.hour = `${Number(this.startDate.hour) + 12}`;
            }
            if (this.startDate.AMPM === "AM" && this.startDate.hour == "12") {
               this.startDate.hour = 0;
            }

            startDate.setHours(this.startDate.hour);
            startDate.setMinutes(this.startDate.minute);
            startDate.setSeconds(0);
         } else {
            startDate.setHours(2);
            startDate.setMinutes(0);
            startDate.setSeconds(1);
         }

         startDate = startDate.getTime() / 1000;

         if (startDate >= dueDate) {
            //make sure the start date is AFTER the due date
            this.alertService.addAlert(
               this.lang().TheStartDateMustBeBeforeTheDueDate,
               "warning",
               6000,
            );

            return;
         }
      }

      //checks to see if any users are selected
      let foundUser = false;
      this.hasSelectedUserOrProfile = true;
      const users = this.users.filter((user) => user.selected == true);
      if (users.length > 0) {
         foundUser = true;
      }

      if (!(foundUser || this.selectedProfile > 0 || this.selectedUnassigned)) {
         this.alertService.addAlert(
            this.lang().PleaseSelectUserOrProfile,
            "warning",
            6000,
         );
         this.hasSelectedUserOrProfile = false;
         this.showErrorValidations = true;
         return;
      }

      const returnData: any = {};

      returnData.multiUsers = [];
      if (this.selectedProfile > 0) {
         returnData.userID = 0;
         returnData.profileID = this.selectedProfile;
      } else {
         //checks to see if any users are selected
         const tempUsers: any = [];
         for (const user of this.users) {
            if (user.selected == true) {
               tempUsers.push(user);
            }
         }

         if (tempUsers.length == 1) {
            //they only picked one user so use the userID for that.
            returnData.userID = tempUsers[0].userID;
            returnData.profileID = tempUsers[0].profileID || 0;
         } else {
            //they picked multiple users so we need to send multiUsers
            returnData.userID = 0;
            returnData.profileID = 0;

            const tempUserIDs = tempUsers.map((user) => user.userID);
            returnData.multiUsers = tempUserIDs;
         }
      }

      returnData.timestamp = dueDate;

      if (this.timeOfDay == true) {
         returnData.timeOfDay = true;
      } else {
         returnData.timeOfDay = false;
      }
      returnData.startDate = 0;
      returnData.startDateTimeOfDay = false;
      if (this.startDateShow == true) {
         returnData.startDate = startDate;
         returnData.startDateTimeOfDay = this.startDate.timeOfDay;
      }

      //allowed a user to enter valid data first, then check for the WO template loading progress
      if (this.loadingBar) {
         this.alertService.addAlert(this.lang().WoTemplateInfoLoading, "warning", 6000);
         return;
      }

      let valid = true;

      //if we are building a work order follow this stuff...
      //I am sorry I overwrote this function to be our work order function, but it had to be done :(
      if (this.data.WO && this.task) {
         returnData.WO = {};
         returnData.WO.state = this.data.WOState;

         // Re-fetching state from getTaskLocalLookup to get most recent state vars before submit
         this.task = await lastValueFrom(
            this.taskTemplatesApiService.getById(this.task.checklistID, {
               params: { allowDeleted: true },
            }),
         );
         this.manageTask.addTaskToLookup(this.task);

         const possiblePriority = this.task?.priorityID
            ? this.managePriority.getPriorityListIndex()[this.task.priorityID]
            : null;

         //this variable holds if it is unplanned, planned, etc.
         returnData.WO.WOtype = this.WOtype;

         let priority: any = possiblePriority ?? this.managePriority.getDefaultPriority();

         if (returnData.WO.state === "simple") {
            returnData.WO.name = this.WOname;
            returnData.WO.description = this.WOdescription;
            returnData.WO.options = {
               preserveStoredInstructions: await this.launchFlagsService.isEnabled(
                  Flags.PLG_SETUP_WORK_ORDER,
               ),
            };

            //changed the returnData.WO.description requirements so that if they don't put in a description it gives them a default complete work.
            if (!returnData.WO.description) {
               returnData.WO.description = this.lang().CompleteWork;
            } else if (!(returnData.WO.description.length > 2)) {
               returnData.WO.description = this.lang().CompleteWork;
            }

            if (this.customerID != 1460 && this.customerID != 1670) {
               // TO DO... BRYAN... allow customers to turn this on or off
               //**IMPORTANT** make sure to change check below too
               if (!returnData.WO.name) {
                  valid = false;
                  this.alertService.addAlert(
                     this.lang().PleaseEnterAWOName,
                     "warning",
                     6000,
                  );
                  this.showErrorValidations = true;
               } else if (!(returnData.WO.name.length > 2)) {
                  valid = false;
                  this.alertService.addAlert(
                     this.lang().PleaseEnterAWOName,
                     "warning",
                     6000,
                  );
                  this.showErrorValidations = true;
               }

               if (
                  this.asset?.assetID === undefined &&
                  this.isPlgSetupWorkOrderEnabled()
               ) {
                  valid = false;
                  this.alertService.addAlert(
                     this.lang().PleaseSelectAnAsset,
                     "warning",
                     6000,
                  );
                  this.showErrorValidations = true;
               }
            }

            //If the WO is 'simple', it is being created from the location's hidden associated
            // Work Order Template, but the priority on that task may not have been updated/does
            // not get updated when the default priority is reset, so that task's priority cannot be used here.

            //this.priority is set to the customer's default priority when the component
            // is initialized and changes with the selection in the UI

            //the below assignment causes the priority to either be the default or
            //what the user has selected instead of pulling from the task
            priority =
               this.managePriority.getPriorityListIndex()[this.priority.priorityID];

            //if it isn't simple it has to be advanced
         } else if (this.customerID == 1460 || this.customerID == 1670) {
            //for these two customers we don't check for checklist name length for advanced
         } else if (!(this.task?.checklistName && this.task.checklistName.length > 2)) {
            valid = false;
            this.alertService.addAlert(this.lang().PleaseEnterAWOName, "warning", 6000);
         }

         this.updateTaskPriorityID(priority);
         //adjustment so we don't have to wait for a refresh to see the priority
         if (this.task) {
            this.task.checklistInstructions = this.taskDescription ?? "";
            this.task.priorityID = priority.priorityID;
            returnData.WO.task = this.task;
         }
      }
      if (valid) {
         this.modalInstance.close(returnData);
      }
   }

   viewWOTemplates = async () => {
      if (await this.manageFeatureFlags.hasReachedWOInstructionLimitFor(moment())) {
         return;
      }

      const dataToPassIn: any = {};
      dataToPassIn.title = this.lang().WorkOrderTemplates;
      dataToPassIn.message = "";
      dataToPassIn.locationID = this.data.locationID;

      const instance = this.modalService.open(WorkOrderTemplatesComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: dataToPassIn,
         },
      };
      instance.result.then((result) => {
         if (result) {
            this.setupWorkOrderTemplate(result);
         }
      });
   };

   private async setupWorkOrderTemplate(result): Promise<void> {
      this.data.WOState = "woTemplate";
      this.task = undefined;
      this.loadingBar = true;

      const template = await lastValueFrom(
         this.taskTemplatesApiService.getById(result.checklistID),
      );

      if (template === undefined) {
         return;
      }

      const priorityInfo = this.manageTask.getPriorityInfo(template.priorityID);

      this.setWoType(template.checklistTemplate == 2 ? 2 : 4);

      if (this.asset?.assetID) {
         this.data.assetID = this.asset.assetID;
      }
      this.manageTask
         .createWorkOrder(
            this.data.assetID,
            this.data.locationID,
            template.checklistID,
            this.sourceItemID,
         )
         .then(async (answer) => {
            if (answer.data.success == true) {
               this.loadingBar = false;
               this.firstItemID = answer.data.firstItemID;
               this.firstItemBatchID = answer.data.firstItemBatchID;
               this.priority =
                  this.managePriority.getPriorityByLevel(priorityInfo?.priorityLevel) ??
                  this.managePriority.getDefaultPriority();
               let mode;

               if (
                  this.manageUser.getCurrentUser().userInfo
                     .customerStartingAWOTemplateDefaultState == 1
               ) {
                  mode = "template";
               } else {
                  mode = "instance";
               }

               this.task = await lastValueFrom(
                  this.taskTemplatesApiService.getById(answer.data.task.checklistID, {
                     params: { allowDeleted: true },
                  }),
               );

               if (answer.data.task) {
                  this.info.buildData();
                  this.taskData = {
                     checklistID: answer.data.task.checklistID,
                     checklistInstructions: this.taskDescription,
                     mode: mode,
                     expand: false,
                     limited: false,
                     limitedSettings: false,
                     disableAlerts: true,
                     preview: false,
                     initialTask: this.task,
                     options: {
                        editChecklistName: Boolean(this.data.editChecklistName),
                     },
                     checklistTemplate: this.task.checklistTemplate,
                  };
                  this.setUploadObj();

                  if (
                     this.task &&
                     this.locations[this.task.locationID].machineDownAlert == 1
                  ) {
                     //sets if we should show machinedown alert button
                     this.showMachineDownAlert = true;
                     this.showMachineDownAlertButtonText =
                        this.locations[this.task.locationID].machineDownAlertButtonText ??
                        this.i18n().t("MachineDownAlert");
                  } else {
                     this.showMachineDownAlert = false;
                  }
               }
            }
         });
   }
   public updateTaskPriorityID(priority): void {
      this.priority = priority;

      if (this.task) {
         // Third param (oldPriority) is null since this task is being created and doesn't have any priority history.
         this.manageTask
            .updateTaskPriorityID(this.task.checklistID, priority.priorityID, null)
            .then((answer) => {
               if (this.task) {
                  if ((answer as any).data.success == true) {
                     // Update all instances of priority ID
                     this.task.priorityID = priority.priorityID;
                     const task = this.manageTask.getTaskLocalLookup(
                        this.task.checklistID,
                     );
                     if (task) task.priorityID = priority.priorityID;
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               }
            });
      }
   }

   editPriorities = () => {
      const instance = this.modalService.open(CustomizePriorities);
      this.paramsService.params = {
         modalInstance: instance,
      };
   };

   protected setUploadObj(): void {
      let checklistID, locationID;
      if (this.task === undefined) {
         checklistID = 0; //I am allowing 0 values because I want the upload picture to load instantly.  we will within miliseconds reset these settings with the valid checklistID, locationID, firstItemID, firstItemBatchID, etc...
         locationID = 0;
      } else {
         checklistID = this.task.checklistID;
         locationID = this.task.locationID;
      }

      //this will run once the file uploader has been successful
      this.uploadObj = {};
      this.uploadObj.primaryID = "fileName";
      this.uploadObj.deleteData = {
         checklistID: checklistID,
         itemID: this.firstItemID,
      };
      this.uploadObj.deleteSuccess = (answer) => {
         this.manageUtil.removeFromArray(this.files, "fileName", answer.data.fileName);
      };
      this.uploadObj.deleteCall = this.manageTaskItem.removeItemImage;
      this.uploadObj.uploadCall = this.manageTaskItem.addInstructionalFile;
      this.uploadObj.posturl = `phpscripts/checklistManager.php?action=makeFileForItem&itemBatchID=${this.firstItemBatchID}&itemID=${this.firstItemID}&locationID=${locationID}`;
      this.uploadObj.viewOnly = false;

      this.uploadObj.uploadComplete = (result) => {
         if (this.data.failed) {
            return;
         }
         const regex = /(?:\.([^.]+))?$/;
         // const ext = regex.exec(result.fileName)[1];
         let ext: any = regex.exec(result.fileName);
         if (ext.length > 1) {
            ext = ext[1];
         }

         const file: any = {};
         this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;

         this.deleteFile = this.manageTaskItem.removeItemImage;

         file.parentRef = "itemID";

         file.item = { itemFileName: result.fileName };
         file.getURL = `viewFile.php?f=upload-${this.customerID}/items/${this.firstItemBatchID}/${result.fileName}`;
         file.checklistID = checklistID;
         file.itemID = this.firstItemID;
         file.fileName = result.fileName;
         file.fileDescription = result.fileName;
         file.fileID = result.fileID;
         file.fileExt = ext;
         file.message = "File Upload Success";
         file.status = true;
         this.files = [...this.files, file];
      };
   }

   protected async updateWOAsset(): Promise<void> {
      const title =
         this.isPlgSetupWorkOrderEnabled() &&
         (this.asset?.assetID === undefined || this.asset?.assetID === 0)
            ? this.lang().FirstSelectAnAsset
            : this.lang().changeTasksAsset;

      const pickAssetLocation = this.allowPickLocation ? 0 : this.data.locationID;

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.lang().WhichAssetWouldYouLikeToStartThisWorkOrderFor;
      instance.title = title ?? this.lang().FirstSelectAnAsset;
      instance.singleLocation = pickAssetLocation;
      instance.selectOne = true;
      instance.restrictToCred = false;
      instance.iDontKnowOption = true;

      const pickedAsset = await modalRef.result;

      if (!pickedAsset) {
         return;
      }
      this.AssignUnsureAsset(pickedAsset);
   }

   protected async AssignUnsureAsset(pickedAsset): Promise<void> {
      if (pickedAsset === "unsure" || pickedAsset.assetID === 0) {
         const unsureAsset: any = {};
         unsureAsset.assetID = 0;
         unsureAsset.locationID = pickedAsset.locationID ?? this.task?.locationID;

         const answer = await this.manageTask.updateTasksAsset(
            this.task?.checklistID,
            unsureAsset,
            [],
         );
         if (answer.data.success == true) {
            await this.updateTaskLocation(unsureAsset);
            this.asset = unsureAsset;
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      } else {
         const answer = await this.manageTask.updateTasksAsset(
            this.task?.checklistID,
            pickedAsset,
            [],
         );

         if (!answer.data.success) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }

         await this.updateTaskLocation(pickedAsset);
         this.asset = this.manageAsset.getAsset(pickedAsset.assetID);
         this.hasPredefinedAsset = true;
      }
   }

   private async updateTaskLocation(asset): Promise<void> {
      if (!this.task) {
         return;
      }
      if (!this.isPlgSetupWorkOrderEnabled()) {
         return;
      }

      await this.manageTask.updateTasksLocation(this.task?.checklistID, asset.locationID);
   }

   popAsset = (assetID) => {
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         return;
      }
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };
}
