import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IconComponent } from "@limblecmms/lim-ui";

@Component({
   selector: "ai-badge",
   templateUrl: "./ai-badge.component.html",
   styleUrls: ["./ai-badge.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [IconComponent],
})
export class AiBadgeComponent {}
