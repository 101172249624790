import { NgClass } from "@angular/common";
import { Component, computed, inject, input, output } from "@angular/core";
import {
   IconComponent,
   isMobile,
   LimbleHtmlDirective,
   LoadingBarService,
   MinimalIconButtonComponent,
   ModalService,
   PanelComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   DropdownComponent,
} from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { ExtraBatch } from "src/app/parts/types/extra-batch/extra-batch.types";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { AddNote } from "src/app/tasks/components/addNoteModal/addNote.modal.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import type { TaskComment } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { CommentUtilsService } from "src/app/tasks/services/comment-utils/comment-utils.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type PartID = number;

export type PopTaskOutputPararms = {
   checklistID: number;
   options: object;
};

export type UpdatePartUsedNumberEvent = {
   partID: PartID;
   usedNumber: number;
};

export type PurchaseOrderWithExtraBatches = PurchaseOrder & {
   currentState: PurchaseOrderCurrentState | undefined;
   extraBatch?: ExtraBatch;
};

@Component({
   selector: "task-comments",
   imports: [
      PanelComponent,
      IconComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      MinimalIconButtonComponent,
      BetterDatePipe,
      FileListItem,
      DropdownComponent,
      ViewImage,
      SecondaryButtonComponent,
      NgClass,
   ],
   templateUrl: "./task-comments.component.html",
   styleUrl: "./task-comments.component.scss",
})
export class TaskCommentsComponent {
   public readonly task = input.required<TaskDataViewerViewModel>();

   public readonly taskFormState = input.required<{
      tempEdit: boolean;
      taskEditable: boolean;
      disableAlerts: boolean;
      preventDoubleClick: boolean;
   }>();

   public readonly noteUpdated = output();
   public readonly preventDoubleClickUpdated = output<boolean>();
   public readonly mentionClickTag = output<any>();
   public readonly popTask = output<PopTaskOutputPararms>();

   private readonly manageUser = inject(ManageUser);
   protected readonly manageLocation = inject(ManageLocation);
   protected readonly managePO = inject(ManagePO);
   protected readonly manageTask = inject(ManageTask);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly heapService = inject(HeapService);
   private readonly alertService = inject(AlertService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly credService = inject(CredService);
   private readonly commentUtilsService = inject(CommentUtilsService);
   protected readonly currentUser = this.manageUser.getCurrentUser();

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly comments = computed<Array<TaskComment>>(() => {
      return this.task().comments ?? [];
   });

   protected readonly taskDisplayData = computed(() => {
      return this.task().taskDisplayData;
   });

   protected readonly taskEditable = computed<boolean>(() => {
      return this.taskFormState().taskEditable;
   });

   protected readonly tempEdit = computed<boolean>(() => {
      return this.taskFormState().tempEdit;
   });

   protected readonly preventDoubleClick = computed<boolean>(() => {
      return this.taskFormState().preventDoubleClick;
   });

   protected readonly hasEditCommentCredential = computed<boolean>(() => {
      return this.task().taskCredentials.hasEditCommentCredential;
   });

   protected readonly superUser = computed<boolean>(() => {
      return this.task().taskCredentials.superUser;
   });

   protected readonly deleteCommentCred = computed<boolean>(() => {
      return this.task().taskCredentials.deleteCommentCred;
   });

   protected readonly addCommentCred = computed<boolean>(() => {
      return this.task().taskCredentials.addCommentCred;
   });

   protected readonly hideOnTablet = computed<boolean>(() => {
      return (
         this.task().taskCredentials.hasEditCommentCredential && this.deleteCommentCred()
      );
   });

   protected showOrHideNoteForExternalView(note: TaskComment): void {
      const prefix = isMobile() ? "mobileTasks" : "tasks";
      const eventName = note.noteHidden === 1 ? "storeReadComment" : "storeUnreadComment";

      if (note.noteHidden === 1) {
         note.noteHidden = 0;
      } else {
         note.noteHidden = 1;
      }

      this.heapService.trackEvent(`${prefix}-${eventName}`);
      this.manageTask.showOrHideNoteForExternalView(note).then((answer) => {
         if (answer.data.success === true) {
            this.noteUpdated.emit();
            this.alertService.addAlert(this.lang().successMsg, "success", 2000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   protected deleteNote(note: TaskComment) {
      if (
         !this.credService.isAuthorized(
            this.task().locationID,
            this.credService.Permissions.RemoveTaskComments,
         )
      ) {
         this.alertService.addAlert(this.lang().cred174Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteCommentMsg,
            title: this.lang().DeleteComment,
         },
      };

      instance.result.then((result) => {
         if (result === 1) {
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });

            this.manageTask
               .deleteNote(note.noteID, this.task().checklistID)
               .then((answer) => {
                  this.loadingBarService.remove();
                  if (answer.data.success === true) {
                     this.noteUpdated.emit();

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
                  }
               });
         }
      });
   }

   protected saveNote(comment?: TaskComment): void {
      //stupid catch all because apple is a POS that detects taps as double taps sometimes ;p
      if (this.preventDoubleClick() === true) {
         return;
      }
      this.preventDoubleClickUpdated.emit(true);
      setTimeout(() => {
         this.preventDoubleClickUpdated.emit(false);
      }, 500);

      this.manageTask.cleanNotesTempFiles();
      //Don't add a permission check.  We want this available to everyone even view only. - Bryan
      const instance = this.modalService.open(AddNote);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: comment ? this.lang().EditCommentMsg : this.lang().AddCommentMsg,
            title: comment ? this.lang().EditComment : this.lang().AddComment,
            data: {
               checklistID: this.task().checklistID,
               external: false,
               customerID: this.manageUser.getCurrentUser().userInfo.customerID,
               comment,
            },
         },
      };

      instance.result.then(async (result) => {
         if (result === 0) {
            return;
         }
         let note = result.note;
         note = note.replace(/&quot;/g, "'");

         const noteHidden = result.noteHidden;

         if (result.newMentionedList && result.newMentionedList.length > 0) {
            await this.addNewMentionsToChecklistNotifications(
               result.newMentionedList,
               this.task().checklistID,
            );
         }

         this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
         let post: Promise<AxiosResponse<any, any>>;
         if (comment) {
            post = this.manageTask.editNote(
               this.task().checklistID,
               comment.noteID,
               note,
            );
         } else {
            post = this.manageTask.addNote(
               note,
               this.task().checklistID,
               noteHidden,
               0,
               true,
            );
         }

         post.then((answer) => {
            this.loadingBarService.remove();
            if (answer.data.success === true) {
               // update the user last visited timestamp for this task so that our hasNewComment check
               // on task lists doesn't flag off of a comment that we added.
               this.checkUpdateUserLastVisited(true);

               this.noteUpdated.emit();

               this.alertService.addAlert(this.lang().AddCommentSuccess, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
            }
         });
      });
   }

   private async addNewMentionsToChecklistNotifications(
      newMentionedList,
      checklistID: number,
   ) {
      if (newMentionedList === undefined || newMentionedList?.length === 0) return;
      try {
         const result =
            await this.manageTask.getWhoWillReceiveTaskNotifications(checklistID);

         for (const item of newMentionedList) {
            if (item?.tagDescription) {
               this.mentionClickTag.emit(item);
            } else {
               if (
                  result?.data?.success !== true ||
                  (result?.data?.recips && !result.data.recips[item.userID])
               ) {
                  this.manageTask.addUserToChecklistNotifications(
                     item.userID,
                     checklistID,
                     "mentioned by",
                  );
               }
            }
         }
      } catch (error) {
         console.error("Error fetching who will receive task notifications:", error);
      }
   }

   private checkUpdateUserLastVisited(bypassCheck: boolean) {
      let updateTimestamp = false;
      const noteIDs = this.task().comments?.map((note) => note.noteID) ?? [];
      if (bypassCheck) {
         // you can bypass the checks for situations where you are leaving a comment, and want to make sure the the last visisted
         // timestamp gets updated. Otherwise when you leave the task it will look like you have unread comments.
         updateTimestamp = true;
      } else if (this.task().checklistCompletedDate === 0) {
         // we want to record a last visited timestamp for open tasks
         updateTimestamp = true;
      } else if (noteIDs && noteIDs.length > 0) {
         // We don't want to take into account auto generated notes, just comments when checking for new comments on a completed task.
         const userComments = noteIDs
            .map((noteID) => this.manageTask.getComment(noteID))
            .filter((note) => note?.noteAutomaticGen === 0);
         if (
            userComments?.length > 0 &&
            Number(userComments[userComments.length - 1]?.noteTimestamp) >
               Number(this.task().checklistCompletedDate)
         ) {
            // we want to record a last visited timestamp if a completed task has new comments on it since it was closed.
            updateTimestamp = true;
         }
      }

      if (updateTimestamp) {
         this.manageUser.updateUserLastVisited(this.task().checklistID, 1);
      }
   }
   protected isCommentVisible(comment: TaskComment): boolean {
      return this.commentUtilsService.isCommentVisible(comment, this.currentUser);
   }
}
