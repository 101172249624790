import { Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import type { BehaviorSubject } from "rxjs";

export interface InstructionSetUpdate {
   itemID: number;
   checklistID: number;
   itemParentResponse: string;
}

const SUB_GROUP_LENGTH = 15;
const OUTER_GROUP_LENGTH = 10;

@Injectable({
   providedIn: "root",
})
export class InstructionSetService {
   private readonly axios = axios;
   public async syncRelatedInstructionSets(
      instructionSetBatchID: number,
      toUpdateArray: Array<InstructionSetUpdate>,
      progressObservable: BehaviorSubject<number>,
   ): Promise<boolean> {
      const mappedUpdatesByTaskId = this.groupUpdatesByTaskId(toUpdateArray);
      const updateStructure = this.buildUpdateStructure(mappedUpdatesByTaskId);

      let currentlyAt = 0;
      for (const batch of updateStructure) {
         let batchSize = 0;
         const promises = batch.map(async (item) => {
            batchSize++;
            return this.syncBatchOfRelatedInstructionSets(item, instructionSetBatchID);
         });
         // Each group of promises is a group of updates with each promise corresponding to a single checklistID
         // Awaiting each batch of updates ensures that no simultaneous requests have overlapping checklistIDs
         // eslint-disable-next-line no-await-in-loop -- need for batching
         const allSyncedArray = await Promise.all(promises);
         currentlyAt += batchSize;
         if (currentlyAt > toUpdateArray.length) {
            currentlyAt = toUpdateArray.length;
         }
         progressObservable.next(currentlyAt);
         if (!allSyncedArray) {
            return false;
         }
      }
      return true;
   }

   private async syncBatchOfRelatedInstructionSets(
      relatedInstructionSets: any[],
      instructionSetBatchID: number,
   ): Promise<AxiosResponse<any>> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "syncBatchOfRelatedInstructionSets",
         },
         data: {
            stringifiedArray: relatedInstructionSets,
            instructionSetBatchID: instructionSetBatchID,
         },
      });

      return post;
   }

   public groupUpdatesByTaskId(updates: Array<InstructionSetUpdate>): Map<
      number,
      Array<{
         itemID: number;
         checklistID: number;
         itemParentResponse: string;
      }>
   > {
      const mappedUpdatesByTaskId: Map<
         number,
         Array<{
            itemID: number;
            checklistID: number;
            itemParentResponse: string;
         }>
      > = new Map();

      for (const update of updates) {
         const taskIdGroup = mappedUpdatesByTaskId.get(update.checklistID);
         if (!taskIdGroup) {
            mappedUpdatesByTaskId.set(update.checklistID, [update]);
            continue;
         }
         taskIdGroup.push(update);
      }
      return mappedUpdatesByTaskId;
   }

   /**
    *
    * @param mappedUpdatesByTaskId
    * @returns A triple stacked mega array.
    *
    * The structure of this output is built to take into account that we want to prevent spiking our servers by sending in either too many requests at the same time or too many requests in a single Promise.all().  Also, we need to ensure that no two requests have overlapping checklistIDs, as simultaneous processing of items from the same task in different processes may cause the item order on the task to get all screwy.
    *
    * The bottom level is an array of task item updates that all share a checklistID, which will be turned into a single request/promise.  The middle level is an array of those bottom level updates, and no two bottom level updates within a middle level array will have the same checklistID.  The top level is an array of those middle level arrays of update, necessary because the bottom level arrays may not have exhausted the mappedUpdatesByTaskId because of the SUB_GROUP_LENGTH limit.
    *
    */
   public buildUpdateStructure(
      mappedUpdatesByTaskId: Map<
         number,
         Array<{
            itemID: number;
            checklistID: number;
            itemParentResponse: string;
         }>
      >,
   ): {
      itemID: number;
      checklistID: number;
      itemParentResponse: string;
   }[][][] {
      // the top level collection
      const allRequestPromises: Array<
         Array<
            Array<{
               itemID: number;
               checklistID: number;
               itemParentResponse: string;
            }>
         >
      > = [];

      while (mappedUpdatesByTaskId.size > 0) {
         let iteration = 0;
         // a group of updates to be performed and awaited in the same Promise.all()
         const batchOfUpdates: Array<
            Array<{
               itemID: number;
               checklistID: number;
               itemParentResponse: string;
            }>
         > = [];

         for (const [key, groupedByTaskId] of mappedUpdatesByTaskId) {
            if (iteration >= OUTER_GROUP_LENGTH) {
               break;
            }
            // a group of updates sharing a checklistID to put into the same request to the server
            // the need for multiple iterations through the same 'groupedByTaskId' is from the SUB_GROUP_LENGTH,
            // a limit on the number of items that can be in each request.
            // Note: each group will be reduced to eventually be empty by using splice
            const subBatch = groupedByTaskId.splice(
               -groupedByTaskId.length,
               SUB_GROUP_LENGTH,
            );
            batchOfUpdates.push(subBatch);
            if (groupedByTaskId.length === 0) {
               // as these groups of updates are reduced over iterations, they will eventually be empty
               // deleting them produces the end condition for the outer while loop
               mappedUpdatesByTaskId.delete(key);
            }
            iteration++;
         }

         allRequestPromises.push(batchOfUpdates);
      }
      return allRequestPromises;
   }
}
