import { inject, Injectable, resource } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { firstValueFrom, mergeWith, switchMap } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { PmSuggestionsAssetsInterface } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/pm-suggestions-assets";

@Injectable()
export class PmSuggestionsAssetsFromManageAssetsService
   implements PmSuggestionsAssetsInterface
{
   private readonly manageAsset = inject(ManageAsset);

   private readonly assets$ = this.manageAsset.assetsLoaded().pipe(
      takeUntilDestroyed(),
      mergeWith(this.manageAsset.assetChanges()),
      switchMap(() => this.manageAsset.assetState()),
   );

   public readonly assets = resource({
      loader: async () => {
         return firstValueFrom(this.assets$);
      },
   }).asReadonly();
}
