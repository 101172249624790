import { inject, computed, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { TaskLoggedTime } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskUser } from "src/app/tasks/types/task-user/task-user.types";

@Injectable({ providedIn: "root" })
export class TaskLoggedTimeViewModelMapperService {
   protected allUsers: ReturnType<ManageTask["getAllUsers"]>;

   private readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);
   private readonly manageLocation = inject(ManageLocation);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.allUsers = this.manageTask.getAllUsers();
   }

   public getUserDisplayName(loggedTime: TaskLoggedTime): string {
      if (!loggedTime.userID) {
         return "";
      }
      const user = this.allUsers.get(loggedTime.userID);
      if (!user) {
         return this.lang().DeletedUser;
      }

      if (this.isSystemUser(user)) {
         return this.lang().System;
      }

      return `${user.userFirstName} ${user.userLastName}`;
   }

   private isSystemUser(user: TaskUser): boolean {
      return Boolean(user.userInternal);
   }

   public getLocationName(loggedTime: TaskLoggedTime): string {
      if (!loggedTime.locationID) {
         return "";
      }
      return String(
         this.manageLocation.getLocation(loggedTime.locationID)?.locationName ?? "",
      );
   }
}
