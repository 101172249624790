import {
   ChangeDetectionStrategy,
   Component,
   computed,
   forwardRef,
   input,
   output,
   signal,
   ViewChild,
} from "@angular/core";
import {
   FormsModule,
   NG_VALUE_ACCESSOR,
   ReactiveFormsModule,
   type ControlValueAccessor,
} from "@angular/forms";
import {
   SelectModule,
   type Select,
   type SelectChangeEvent,
   type SelectFilterEvent,
} from "primeng/select";
import type { SelectOption } from "src/app/shared/empowered/base/select/select.models";

@Component({
   selector: "e-select",
   standalone: true,
   imports: [SelectModule, ReactiveFormsModule, FormsModule],
   templateUrl: "./select.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => SelectComponent),
         multi: true,
      },
   ],
})
export class SelectComponent implements ControlValueAccessor {
   protected _value: any = null;
   /** An array of select items to display as the available options */
   readonly options = input<SelectOption[]>([]);

   /** Default text to display when no option is selected */
   readonly placeholder = input<string>("");

   /** When present, it specifies that the component should be disabled */
   readonly disabled = input<boolean>(false);

   // Separate signal for form control disabled state
   private readonly formDisabled = signal<boolean>(false);

   // Computed signal that combines both disabled states
   protected isDisabled = computed(() => this.disabled() || this.formDisabled());

   /** When enabled, the filter is displayed */
   readonly filterable = input<boolean>(false);

   /** When enabled, the clear button is displayed */
   readonly showClear = input<boolean>(false);

   /** When enabled, the checkmark is displayed in the selected option */
   readonly showCheckmark = input<boolean>(false);

   /** Selected value of the component */
   readonly value = input<SelectOption>();

   /** Defines a string that labels the input for accessibility */
   readonly ariaLabel = input<string>();

   /** Establishes relationships between the component and label(s) where its value should be one or more element IDs */

   readonly ariaLabelledBy = input<string>();

   /** Identifier of the accessible input element */
   readonly inputId = input<string>();

   /** Callback to invoke on filter input */
   // eslint-disable-next-line angular/no-output-native -- This is a valid use case for the output
   readonly filter = output<any>();

   /** Callback to invoke when component receives focus */
   // eslint-disable-next-line angular/no-output-native -- This is a valid use case for the output
   readonly focus = output<any>();

   /** Callback to invoke when component loses focus */
   // eslint-disable-next-line angular/no-output-native -- This is a valid use case for the output
   readonly blur = output<any>();

   /** Callback to invoke when dropdown overlay is shown */
   // eslint-disable-next-line angular/no-output-native -- This is a valid use case for the output
   readonly show = output();

   /** Callback to invoke when dropdown overlay is hidden */
   // eslint-disable-next-line angular/no-output-native -- This is a valid use case for the output
   readonly hide = output();

   @ViewChild("select") public readonly select!: Select;

   // eslint-disable-next-line typescript/no-unused-vars -- This is a valid use case for the output
   private onChange = (value: any): void => {
      // Default placeholder - will be replaced by form control
   };
   private onTouched = (): void => {
      // Default placeholder - will be replaced by form control
   };

   // Replace the value input with a getter/setter
   public get modelValue(): any {
      return this._value;
   }

   public set modelValue(val: any) {
      this._value = val;
      this.onChange(val);
   }

   // ControlValueAccessor implementation
   public writeValue(value: any): void {
      this._value = value;
   }

   public registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   public registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }

   public setDisabledState(isDisabled: boolean): void {
      this.formDisabled.set(isDisabled);
   }

   /** Resets the filter value */
   public resetFilter(): void {
      this.select.resetFilter();
   }

   /** Displays the panel */
   public showPanel(isFocus?: any): void {
      this.select.show(isFocus);
   }

   /** Hides the panel */
   public hidePanel(isFocus?: any): void {
      this.select.hide(isFocus);
   }

   /** Applies focus to the select element */
   public focusInput(): void {
      this.select.focus();
   }

   /** Clears the selected value */
   public clear(): void {
      this.select.clear();
   }

   /** Emits the change event */
   public onChangeSelect(event: SelectChangeEvent): void {
      this.modelValue = event.value;
      this.onChange(event.value);
   }

   /** Emits the filter event */
   public onFilterSelect(event: SelectFilterEvent): void {
      // For the filter event that is emitted to the parent component
      this.filter.emit(event.filter);
      // For the ControlValueAccessor implementation
      this.onTouched();
   }
}
