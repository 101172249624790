<div class="panel-body">
   <div class="table-options">
      <div class="filter-options">TODO(WRENCH-249): Add search and filter fields</div>
      <lim-ui-outlined-button
         (click)="startManualBuildRun()"
         [limUiTooltip]="
            'Ask AI to search your asset documents for PM tasks and schedules.'
         "
      >
         Use PM Builder for Specific Assets
      </lim-ui-outlined-button>
   </div>
   <pm-suggestions-table />
</div>
