<div class="priorityTreeItem lim-ui-list-item">
   <div class="lim-ui-list-item-content">
      <div class="front-list-item-data">
         <div class="list-section">
            @if (treeBranch) {
               <div [limbleTreeDraggable]="treeBranch" class="drag-icon-section">
                  <lim-ui-icon
                     class="drag-icon"
                     icon="arrowsUpDownLeftRightRegular"
                     iconSize="small"
                  />
                  <span class="priority-level">{{ priority.priorityLevel }}</span>
               </div>
            }

            <input
               class="priority-name"
               type="text"
               [(ngModel)]="priority.name"
               (change)="updateSinglePriority(priority)"
               placeholder="{{ lang().Name }}"
               tabindex="{{ priority.priorityLevel }}"
            />
         </div>

         <div class="list-section">
            <lim-ui-checkbox
               (modelChange)="setPriorityDefault(priority); $event.stopPropagation()"
               [label]="lang().DefaultPriority"
               [(model)]="priority.isDefault"
               [disabled]="false"
               tooltip="{{ lang().DefaultPriorityTooltip }}"
            />
         </div>

         <div class="color-selector list-section">
            <!-- Here's the link to the documentation for this color picker: https://liquid-js.github.io/nxt-components/demo/color-picker/getting-started -->
            <span
               (toggleChange)="onColorChange(hexCodeInput)"
               class="color-selector"
               [(nxtColor)]="hexCodeInput"
               [style.background]="hexCodeInput"
            ></span>
            <input
               type="text"
               class="color-selector-input"
               [(ngModel)]="hexCodeInput"
               (blur)="onColorChange(hexCodeInput)"
               placeholder="{{ lang().Color }}"
               maxlength="7"
            />

            <lim-ui-icon
               limUiTooltip="{{ lang().PriorityColorTooltip }}"
               icon="circleQuestionRegular"
               iconSize="small"
            />
         </div>
      </div>

      <lim-ui-minimal-icon-button
         iconColor="danger"
         [superMinimal]="true"
         icon="trashCanRegular"
         iconSize="small"
         (click)="deletePriority(priority)"
      />
   </div>
</div>
