import { NgClass } from "@angular/common";
import type {
   ElementRef,
   OnDestroy,
   OnInit,
   Signal,
   WritableSignal,
} from "@angular/core";
import {
   inject,
   Component,
   Input,
   computed,
   signal,
   ViewChild,
   input,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   DropdownComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   IconComponent,
   InputWithPrefixComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   PopoverDirective,
   SearchBoxComponent,
   TooltipDirective,
   UpsellPopover,
   FormDropdownInputComponent,
   TextButtonComponent,
} from "@limblecmms/lim-ui";
import { exhaustMap, Subject, type Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PartPurchasableAddModalComponent } from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/part-purchasable-add-modal.component";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { purchasableMeasuredDto } from "src/app/parts/purchasable/purchasable.dto";
import type { Purchasable } from "src/app/parts/purchasable/purchasable.model";
import { PurchasableService } from "src/app/parts/purchasable/purchasable.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitConversionComponent } from "src/app/parts/unit-of-measure/components/unit-conversion/unit-conversion.component";
import { UnitLabelComponent } from "src/app/parts/unit-of-measure/components/unit-label/unit-label.component";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { PrComponent } from "src/app/purchasing/bills/prWrapper/pr.wrapper.component";
import { CurrencyNamePipe } from "src/app/purchasing/currency/pipes/currency-name.pipe";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { PoItemTasksHint } from "src/app/purchasing/pos/poItemTasksHintElement/poItemTasksHint.element.component";
import { PurchasableSnapshotService } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.service";
import { SetupGeneralLedger } from "src/app/purchasing/pos/setupGenerlLedgerModal/setupGeneralLedger.modal.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { BillTransaction } from "src/app/purchasing/types/bill-transaction.types";
import type { GeneralLedger } from "src/app/purchasing/types/general-ledger.types";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderWorkflow } from "src/app/purchasing/types/purchase-order-workflow.types";
import { isPartItem } from "src/app/purchasing/util/is-part-item";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

export enum PurchaseOrderItemPurchasableType {
   Measured = "measured",
   CountedMeasured = "countedMeasured",
   Counted = "counted",
   Single = "single",
}

export type PurchasableOrderUnitType = {
   abbreviation: string | null;
   name: string | null;
};

@Component({
   selector: "po-item",
   templateUrl: "./poItem.element.component.html",
   styleUrls: ["./poItem.element.component.scss"],
   imports: [
      DropdownComponent,
      TooltipDirective,
      MinimalIconButtonComponent,
      SearchBoxComponent,
      DropdownItemComponent,
      IconComponent,
      DropdownTextItemComponent,
      NgClass,
      LimbleHtmlDirective,
      FormsModule,
      ContenteditableDirective,
      InputWithPrefixComponent,
      PoItemTasksHint,
      BetterCurrencyPipe,
      BetterDatePipe,
      BetterDecimalPipe,
      PopoverDirective,
      UpsellPopover,
      BetterDecimalPipe,
      FormDropdownInputComponent,
      UnitLabelComponent,
      UnitConversionComponent,
      TextButtonComponent,
      CurrencySymbolPipe,
      LocaleCurrencyPipe,
      CurrencyNamePipe,
   ],
})
export class PoItem implements OnInit, OnDestroy {
   public readonly poItemID = input.required<number>();
   @Input() public purchaseOrderWorkflows: Array<PurchaseOrderWorkflow> | undefined;
   @Input() public data;
   @Input() public setProdService;
   @Input() public editable: boolean = false;
   @Input() public currencyCode!: Signal<string>;
   @ViewChild("poItemQuantityInput") poItemQuantityInputElement!: ElementRef;

   public CID;
   public currencySymbol;
   public superUser;
   public oldItemQty: number = 0;
   public oldRate: number | null = null;
   public generalLedgers: Lookup<"glID", GeneralLedger> = new Lookup("glID");
   public filteredGeneralLedgers: Lookup<"glID", GeneralLedger> = new Lookup("glID");
   public searchLedger: string = "";
   public generalLedgerWatchVarSub;
   protected editableWithoutCred: boolean = false;
   protected filteredPurchasables: Array<Purchasable> = [];
   protected filteredSingleUnitMatch: boolean = false;
   protected searchPurchasable: string = "";
   private autoSelectMostRecentPurchasable: boolean = false;

   private readonly _partPurchasables: WritableSignal<Array<Purchasable>> = signal([]);

   private readonly purchasableFetchTrigger = new Subject<number>();
   protected purchaseOrderState: PurchaseOrderCurrentState | undefined;
   protected purchaseOrder: PurchaseOrder | undefined;
   protected taxWarning: boolean = false;
   protected discountWarning: boolean = false;
   protected generalLedgerAbbreviation: string = "";
   protected generalLedgerTooltip: string = "";
   protected generalLedgerName: string = "";
   protected itemRelatedInfo:
      | {
           itemName: string | undefined;
           itemSearchStr: string;
           itemNumber: string | undefined;
        }
      | undefined;
   protected total: number | undefined;
   protected itemReceivedInfo:
      | { receivedQty: number; ableToReceive: boolean }
      | undefined;
   protected transactions: Lookup<"transactionID", BillTransaction> | undefined;
   protected featureGeneralLedger: boolean = false;
   private readonly _purchasable: WritableSignal<Purchasable> | undefined;
   private readonly manageFeatureFlagsSub: Subscription;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageParts = inject(ManageParts);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected launchFlagService = inject(LaunchFlagsService);
   protected readonly purchasableMeasuredDto = typeof purchasableMeasuredDto;
   private readonly isUnitOfMeasureEnabled = this.unitOfMeasureService.isFeatureEnabled;
   protected readonly purchasableService = inject(PurchasableService);
   private readonly heapService = inject(HeapService);
   private readonly purchasableSnapshotService = inject(PurchasableSnapshotService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly canCreatePurchasables = signal(false);
   private readonly launchFlagSub: Subscription;

   protected readonly purchaseOrderItem = computed(() => {
      if (this.managePO.updated() === null) return null;

      const purchaseOrderItem = this.managePO.getPurchaseOrderItem(this.poItemID());
      assert(
         purchaseOrderItem !== undefined,
         `Purchase order item ${this.poItemID()} does not exist`,
      );

      return purchaseOrderItem;
   });

   private readonly purchaseOrderPartItem = computed(() => {
      if (this.managePO.updated() === null) return null;

      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return null;

      if (!isPartItem(purchaseOrderItem)) return undefined;

      return purchaseOrderItem;
   });

   protected readonly purchasableSnapshot = computed(() => {
      if (this.managePO.updated() === null) return null;

      const purchaseOrderPartItem = this.purchaseOrderPartItem();
      const isUnitOfMeasureEnabled = this.isUnitOfMeasureEnabled();
      if (purchaseOrderPartItem === null || isUnitOfMeasureEnabled === null) return null;

      if (purchaseOrderPartItem === undefined) return undefined;

      return this.purchasableSnapshotService.parseFromPurchaseOrderItem(
         purchaseOrderPartItem,
      );
   });

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureGeneralLedger = isFeatureEnabledMap.featureGeneralLedger;
         },
      );

      this.launchFlagSub = toObservable(
         this.launchFlagService.getFlag(
            "release-po-item-create-purchasable-option",
            false,
         ),
      ).subscribe((flag) => {
         const canAddPurchasables = this.credService.isAuthorized(
            this.purchaseOrder?.locationID ?? 0,
            this.credService.Permissions.CreateAndEditPurchasables,
         );
         this.canCreatePurchasables.set(flag && canAddPurchasables);
      });

      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
      //if they can manage roles they must be a super user
      if (this.isUnitOfMeasureEnabled()) {
         this.updatePartPurchasablesOnFetch();
      }
   }

   public ngOnInit() {
      this.initializeData();

      this.managePO.triggerPurchaseOrderItemInfoRefresh$.subscribe(() => {
         this.initializeData();
      });

      this.generalLedgerWatchVarSub = this.manageObservables.setSubscription(
         "generalLedgerWatchVar",
         () => {
            this.generalLedgers = this.managePO
               .getGeneralLedgers()
               .filter((ledger) => ledger.locationID === this.purchaseOrder?.locationID)
               .orderBy("abbr");
            this.setGeneralLedgerInfo();
            this.filteredGeneralLedgers = this.generalLedgers;
         },
      );
   }

   public fetchPartPurchasables(): void {
      const partID = this.purchaseOrderItem()?.partID;
      if (!this.isUnitOfMeasureEnabled() || partID === null || partID === undefined) {
         return;
      }

      this.purchasableFetchTrigger.next(partID);
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.generalLedgerWatchVarSub);
      this.manageFeatureFlagsSub.unsubscribe();
      this.launchFlagSub.unsubscribe();
   }
   protected async setPurchasableWithItemAndFocus(
      purchasable: Purchasable | undefined,
   ): Promise<void> {
      if (!this.isUnitOfMeasureEnabled()) return;

      await this.setPoItemPurchasable(purchasable);
      await this.managePO.refreshPurchaseOrderItem(this.poItemID());
      await this.resetPurchasablePOQtyWithItem();
      this.recordStorePurchasableEventAndFocusQtyField();
   }

   private async setPoItemPurchasable(
      purchasable: Purchasable | undefined,
   ): Promise<void> {
      if (!this.isUnitOfMeasureEnabled()) return;

      const purchaseOrderPartItem = this.purchaseOrderPartItem();
      if (purchaseOrderPartItem === null || purchaseOrderPartItem === undefined) return;

      const purchasableID = purchasable?.id ?? 0;
      await this.managePO.setPOItemPurchasable(
         purchaseOrderPartItem.poItemID,
         purchasableID,
         purchaseOrderPartItem.partID,
      );
   }

   private recordStorePurchasableEventAndFocusQtyField(): void {
      if (!this.isUnitOfMeasureEnabled()) {
         return;
      }
      this.heapService.trackEvent("managePOs_storeAPurchasable");
      this.poItemQuantityInputElement.nativeElement.focus();
   }

   private async resetPurchasablePOQtyWithItem(): Promise<void> {
      if (!this.isUnitOfMeasureEnabled()) return;

      return this.setPurchasablePoItemQuantity();
   }

   private async setPurchasablePoItemQuantity(): Promise<void> {
      const purchaseOrderPartItem = this.purchaseOrderPartItem();
      if (purchaseOrderPartItem === null || purchaseOrderPartItem === undefined) return;

      const hasReceivables =
         purchaseOrderPartItem.transactionIDs &&
         purchaseOrderPartItem.transactionIDs.length > 0;
      if (!hasReceivables || (hasReceivables && purchaseOrderPartItem.qty <= 1)) {
         purchaseOrderPartItem.qty = 1;
         await this.setPOItemQty();
      }
   }

   protected get partPurchasables(): Signal<Array<Purchasable>> {
      return this._partPurchasables;
   }

   protected get purchasable(): Signal<Purchasable> | undefined {
      return this._purchasable;
   }

   private updatePartPurchasablesOnFetch(): void {
      if (!this.isUnitOfMeasureEnabled()) {
         return;
      }

      this.purchasableFetchTrigger
         .pipe(exhaustMap((partID) => this.purchasableService.fetchByPart(partID)))
         .subscribe((purchasables) => {
            this._partPurchasables.set(purchasables);
            this.setSelectedPurchasablesList();
            this.filteredPurchasables = purchasables;
            if (this.autoSelectMostRecentPurchasable) {
               const purchasable = purchasables.reduce(
                  (mostRecent, current) =>
                     current.id > mostRecent.id ? current : mostRecent,
                  purchasables[0],
               );
               if (purchasable) {
                  this.setPurchasableWithItemAndFocus(purchasable);
                  this.autoSelectMostRecentPurchasable = false;
               }
            }
         });
   }

   private setSelectedPurchasablesList(): void {
      if (!this.isUnitOfMeasureEnabled()) return;

      const purchaseOrderPartItem = this.purchaseOrderPartItem();
      if (purchaseOrderPartItem === null || purchaseOrderPartItem === undefined) return;

      if (purchaseOrderPartItem.purchasableID !== null) {
         this.fetchPartPurchasables();
      }
   }

   private setGeneralLedgerInfo(): void {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;
      if (purchaseOrderItem.glID === null || purchaseOrderItem.glID === 0) {
         this.generalLedgerAbbreviation = "";
         this.generalLedgerTooltip = this.lang().AddGeneralLedgerForThisPOItem;
         this.generalLedgerName = "";
      } else {
         // set the right display HTML for the general ledger.
         const generalLedger = this.managePO.getGeneralLedger(purchaseOrderItem.glID);

         this.generalLedgerAbbreviation = generalLedger?.abbr ?? "";
         this.generalLedgerTooltip = generalLedger?.name ?? "";
         this.generalLedgerName = generalLedger?.name ?? "";
      }
   }

   private async fetchSinglePurchaseOrderItem(): Promise<void> {
      await this.managePO.fetchSinglePurchaseOrderItem(this.poItemID());
   }

   protected checkCred(cred): boolean {
      if (!this.purchaseOrder?.locationID) return false;
      return this.credService.isAuthorized(this.purchaseOrder.locationID, cred);
   }

   protected async setPOItemDescription() {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOItems,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred151Fail, "danger", 10000);
         return;
      }

      const answer = await this.managePO.setPurchaseOrderItemDescription(
         purchaseOrderItem.poItemID,
         purchaseOrderItem.description ?? "",
      );

      if (answer === false) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      this.heapService.trackEvent("managePOs_storeADescription");
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async setPOItemQty(): Promise<void> {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      if (this.oldItemQty === purchaseOrderItem.qty) return;

      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOItems,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred151Fail, "danger", 10000);
         return;
      }
      if (purchaseOrderItem.itemType === 0) {
         purchaseOrderItem.qty = this.oldItemQty;

         this.alertService.addAlert(
            this.lang().WhoopsPleaseSelectTheItem,
            "warning",
            6000,
         );
         return;
      }
      if (purchaseOrderItem.qty !== null && purchaseOrderItem.qty < 0) {
         purchaseOrderItem.qty = this.oldItemQty;
         this.alertService.addAlert(
            this.lang().WhoopsPOItemsMustHaveAPositiveNumber,
            "warning",
            6000,
         );
         return;
      }
      const purchaseOrderReceivedInfo = this.managePO.getPurchaseOrderItemReceivedInfo(
         purchaseOrderItem.poItemID,
      );
      //they also can't set something that has been already been received...
      if (
         purchaseOrderItem.qty !== null &&
         purchaseOrderReceivedInfo !== undefined &&
         purchaseOrderItem.qty < purchaseOrderReceivedInfo.receivedQty
      ) {
         purchaseOrderItem.qty = this.oldItemQty;
         this.alertService.addAlert(
            this.lang().WhoopsAPOItemCanNotGoBelowTheQuantityAlreadyReceived,
            "warning",
            6000,
         );
         return;
      }
      if (purchaseOrderItem.qty === null) {
         this.alertService.addAlert(this.lang().DiscountCannotBeEmpty, "warning", 6000);
         return;
      }
      const answer = await this.managePO.setPurchaseOrderItemQty(
         purchaseOrderItem,
         purchaseOrderItem.poItemID,
         purchaseOrderItem.qty,
      );

      if (answer?.data.success === true) {
         this.total = this.managePO.calculatePurchaseOrderItemTotal(
            purchaseOrderItem.poItemID,
         );
         this.oldItemQty = purchaseOrderItem.qty;
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else if (answer?.data.success === false && answer.data.reason === "old data") {
         this.oldItemQty = purchaseOrderItem.qty;
         this.alertService.addAlert(
            this.lang().WhoopsSomeoneElseChangedThisPO,
            "warning",
            6000,
         );
         this.managePO.getData();
      } else if (
         answer?.data.success === false &&
         answer.data.reason === "already received"
      ) {
         this.alertService.addAlert(
            this.lang().WhoopsAPOItemCanNotGoBelowTheQuantityAlreadyReceived,
            "warning",
            10000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
   }

   protected async setPOItemRate(
      newRate: number | string | null | undefined,
   ): Promise<void> {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      if (purchaseOrderItem.itemType === 0) {
         purchaseOrderItem.rate = this.oldRate;
         this.alertService.addAlert(
            this.lang().WhoopsPleaseSelectTheItem,
            "warning",
            6000,
         );
         return;
      }
      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOItems,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred151Fail, "danger", 10000);
         return;
      }
      if (newRate === "") {
         this.alertService.addAlert(this.lang().RateCannotBeEmpty, "warning", 6000);
         return;
      }
      purchaseOrderItem.rate = Number(newRate);
      const answer = await this.managePO.setPurchaseOrderItemRate(
         purchaseOrderItem.poItemID,
         purchaseOrderItem.rate,
      );

      if (answer.data.success === true) {
         this.oldRate = purchaseOrderItem.rate;

         this.total = this.managePO.calculatePurchaseOrderItemTotal(
            purchaseOrderItem.poItemID,
         );
         this.heapService.trackEvent("managePOs_storeARate");
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else if (
         answer.data.success === false &&
         answer.data.reason === "already received"
      ) {
         purchaseOrderItem.rate = this.oldRate;
         this.alertService.addAlert(
            this.lang().WhoopsYouCantChangeAnItemOnceItHasBeenReceived,
            "warning",
            1000,
         );
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
   }

   protected async deletePOItem(): Promise<void> {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOItems,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred151Fail, "danger", 10000);
         return;
      }

      if (!this.editable) {
         this.alertService.addAlert(
            this.lang().WhoopsThisPOIsNotCurrentlyEditable,
            "warning",
            10000,
         );
         return;
      }
      if (!this.superUser) {
         //if they are not a super user let's check to make sure they only delete things they should be able to
         if (purchaseOrderItem.transactionIDs.length > 0) {
            this.alertService.addAlert(
               this.lang().WhoopsYouCantDeleteAnItemOnceItHasBeenReceived,
               "warning",
               10000,
            );
            return;
         }
      }
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeletePOItemMsg,
            title: this.lang().DeletePOItem,
         },
      };
      const result = await instance.result;
      if (result !== 1) {
         return;
      }
      this.triggerDelete(true);
   }

   protected async triggerDelete(showAlert: boolean): Promise<void> {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      const answer = await this.managePO.deletePurchaseOrderItem(
         purchaseOrderItem.poItemID,
      );

      if (!answer?.data.success) {
         if (answer?.data.reason === "already received") {
            this.alertService.addAlert(
               this.lang().YouCantDeleteAnItemOnceItHasBeenUsed,
               "warning",
               10000,
            );
            return;
         }
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      if (showAlert) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      }
   }

   protected popPR(transactionID: number): void {
      const instance = this.modalService.open(PrComponent);
      const transaction = this.managePO.getBillTransaction(transactionID);
      if (!transaction?.prID) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { prID: transaction.prID },
         },
      };
   }

   protected async setPOItemTax(): Promise<void> {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      if (purchaseOrderItem.tax === null) {
         this.alertService.addAlert(this.lang().TaxCannotBeEmpty, "warning", 6000);
         return;
      }
      this.taxWarning = purchaseOrderItem.tax > 50;
      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOItems,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred151Fail, "danger", 10000);
         return;
      }
      const answer = await this.managePO.setPurchaseOrderItemTax(
         purchaseOrderItem.poItemID,
         purchaseOrderItem.tax,
      );
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
      this.total = this.managePO.calculatePurchaseOrderItemTotal(
         purchaseOrderItem.poItemID,
      );
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async setPOItemDiscount(): Promise<void> {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      if (purchaseOrderItem.discount === null) return;

      this.discountWarning = purchaseOrderItem.discount > 100;
      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOItems,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred151Fail, "danger", 10000);
         return;
      }
      if (purchaseOrderItem.discount === null) {
         this.alertService.addAlert(this.lang().DiscountCannotBeEmpty, "warning", 6000);
         return;
      }
      const answer = await this.managePO.setPurchaseOrderItemDiscount(
         purchaseOrderItem.poItemID,
         purchaseOrderItem.discount,
      );

      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }

      this.total = this.managePO.calculatePurchaseOrderItemTotal(
         purchaseOrderItem.poItemID,
      );
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async setPOItemShipping(
      newShipping: number | string | null | undefined,
   ): Promise<void> {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.ChangePOItems,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred151Fail, "danger", 10000);
         return;
      }
      if (newShipping === "") {
         this.alertService.addAlert(this.lang().ShippingCannotBeEmpty, "warning", 6000);
         return;
      }
      purchaseOrderItem.shipping = Number(newShipping);
      const answer = await this.managePO.setPurchaseOrderItemShipping(
         purchaseOrderItem.poItemID,
         purchaseOrderItem.shipping,
      );

      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }

      this.total = this.managePO.calculatePurchaseOrderItemTotal(
         purchaseOrderItem.poItemID,
      );
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async setupGeneralLedger(tempGl: GeneralLedger | false): Promise<void> {
      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.AddGeneralLedger,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred153Fail, "danger", 10000);
         return;
      }

      let title, name, abbr, glID, glDescription;
      let assetID = 0;
      if (tempGl !== false) {
         title = this.lang().UpdateGeneralLedger;
         name = tempGl.name;
         abbr = tempGl.abbr;
         glID = tempGl.glID;
         glDescription = tempGl.glDescription;
         assetID = tempGl.assetID ?? 0;
      } else if (tempGl === false) {
         title = this.lang().AddANewGeneralLedgerToPickForm;
         if (this.searchLedger.length > 4) {
            name = this.searchLedger;
         } else {
            name = "";
         }
         if (this.searchLedger.length < 5) {
            abbr = this.searchLedger;
         } else {
            abbr = "";
         }
         glID = 0;
      }
      const instance = this.modalService.open(SetupGeneralLedger);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: title,
               name: name,
               abbr: abbr,
               glID: glID,
               locationID: this.purchaseOrder.locationID,
               glDescription: glDescription,
               assetID: assetID,
            },
         },
      };
      const result = await instance.result;
      if (!result) {
         return;
      }
      const answer = await this.managePO.setupGeneralLedger(
         glID,
         result.name,
         result.abbr,
         this.purchaseOrder.locationID,
         result.glDescription,
         result.assetID,
      );
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         //alerts are sent out in the set call
      }
      if (glID > 0) {
         this.setGeneralLedger(glID);
      } else {
         this.setGeneralLedger(answer.data.generalLedger.glID);
      }
   }

   protected async setGeneralLedger(glID: number): Promise<void> {
      const purchaseOrderItem = this.purchaseOrderItem();
      if (purchaseOrderItem === null) return;

      if (!this.purchaseOrder?.locationID) return;
      if (
         !this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.SetPOItemGeneralLedger,
         ) &&
         !this.editableWithoutCred
      ) {
         this.alertService.addAlert(this.lang().cred154Fail, "danger", 10000);
         return;
      }
      const answer = await this.managePO.setGeneralLedger(
         glID,
         purchaseOrderItem.poItemID,
      );
      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }
      this.setGeneralLedgerInfo();
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   popPart = (partID) => {
      const part = this.manageParts.getPart(partID);
      if (part) {
         const instance = this.modalService.open(PopPart);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               partID: part.partID,
               locationID: part.locationID,
               data: {
                  restrict: false,
               },
            },
         };

         if (!this.isUnitOfMeasureEnabled()) return;

         const purchaseOrderPartItem = this.purchaseOrderPartItem();
         if (purchaseOrderPartItem === null || purchaseOrderPartItem === undefined) {
            return;
         }
         instance.result.then(() => {
            if (purchaseOrderPartItem.purchasableID !== null) {
               this.fetchPartPurchasables();
               this.fetchSinglePurchaseOrderItem();
            }
         });
      }
   };

   public initializeData() {
      const purchaseOrderItem = this.managePO.getPurchaseOrderItem(this.poItemID());
      if (purchaseOrderItem === undefined) return;

      this.itemRelatedInfo = this.managePO.getPurchaseOrderItemRelatedInfo(
         purchaseOrderItem.poItemID,
      );
      this.purchaseOrder = purchaseOrderItem.poID
         ? this.managePO.getPurchaseOrder(purchaseOrderItem.poID)
         : undefined;

      this.editableWithoutCred =
         this.editable === true && this.purchaseOrder?.state === 0;

      this.taxWarning = Boolean(purchaseOrderItem.tax && purchaseOrderItem.tax > 50);
      this.discountWarning = Boolean(
         purchaseOrderItem.discount && purchaseOrderItem.discount > 100,
      );
      this.oldItemQty = purchaseOrderItem.qty;
      this.oldRate = purchaseOrderItem.rate;
      this.total = this.managePO.calculatePurchaseOrderItemTotal(this.poItemID());
      this.itemReceivedInfo = this.managePO.getPurchaseOrderItemReceivedInfo(
         this.poItemID(),
      );

      this.transactions = this.managePO.getBillTransactions();
      this.setGeneralLedgerInfo();
   }

   protected searchGeneralLedgers(): void {
      if (!this.searchLedger.length) {
         this.filteredGeneralLedgers = this.generalLedgers;
         return;
      }
      this.filteredGeneralLedgers = this.generalLedgers.filter((ledger) => {
         const nameSearch =
            ledger.name?.toLowerCase().includes(this.searchLedger.toLowerCase()) ?? false;
         const abbrSearch =
            ledger.abbr?.toLowerCase().includes(this.searchLedger.toLowerCase()) ?? false;
         return nameSearch || abbrSearch;
      });
   }

   protected searchPurchasables(): void {
      const singleUnitName =
         this.purchasableSnapshot()?.stockUnitDisplay.name ?? this.lang().each;
      this.filteredSingleUnitMatch =
         singleUnitName?.toLowerCase().includes(this.searchPurchasable.toLowerCase()) ??
         false;

      this.filteredPurchasables = this.partPurchasables().filter((purchasable) => {
         return (
            purchasable
               .name()
               ?.toLowerCase()
               .includes(this.searchPurchasable.toLowerCase()) ?? false
         );
      });
   }

   protected async addAPurchasableToItem(): Promise<void> {
      if (!this.isUnitOfMeasureEnabled() || !this.canCreatePurchasables()) {
         return;
      }
      this.heapService.trackEvent("manageUnitsOfMeasure_initiateAddAPurchasableWithItem");
      const instance = this.modalService.open(PartPurchasableAddModalComponent);
      if (
         this.searchPurchasable &&
         this.searchPurchasable !== "" &&
         this.filteredPurchasables.length === 0
      ) {
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               name: this.searchPurchasable,
            },
         };
      }
      const partID = this.purchaseOrderItem()?.partID;
      assert(partID);
      const part = this.manageParts.getPart(partID);
      assert(part);
      instance.setInput("part", part);
      instance.setInput("editMode", false);
      const result = await instance.result;

      this.autoSelectMostRecentPurchasable = result ?? false;

      this.fetchPartPurchasables();
      this.searchPurchasable = "";
   }
}
