import { computed, Injectable, signal } from "@angular/core";
import type { GeoFeature } from "src/app/maps/types/geoMap.types";
import type { ExtraBatch } from "src/app/parts/types/extra-batch/extra-batch.types";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import type {
   CustomTagListObject,
   TaskModalCredentials,
   TaskDataMode,
} from "src/app/tasks/components/shared/services/tasks-facade";
import type { TaskItem } from "src/app/tasks/components/taskItemElement/taskItem.element.component";
import type { TaskInfo } from "src/app/tasks/types/info/task-info.types";

@Injectable()
export class TaskFormDataViewerStateService {
   private readonly _preventDoubleClick = signal(false);
   public readonly preventDoubleClick = this._preventDoubleClick.asReadonly();

   private readonly _customTagData = signal<CustomTagListObject | undefined>(undefined);
   public readonly customTagData = this._customTagData.asReadonly();

   private readonly _tempEdit = signal(false);
   public readonly tempEdit = this._tempEdit.asReadonly();

   private readonly _permissions = signal<TaskModalCredentials | undefined>(undefined);
   public readonly permissions = this._permissions.asReadonly();

   private readonly _options = signal<any>({});
   public readonly options = this._options.asReadonly();

   private readonly _limited = signal(false);
   public readonly limited = this._limited.asReadonly();

   private readonly _mode = signal<TaskDataMode>("instance");
   public readonly mode = this._mode.asReadonly();

   private readonly _disableAlerts = signal(false);
   public readonly disableAlerts = this._disableAlerts.asReadonly();

   private readonly _guided = signal(false);
   public readonly guided = this._guided.asReadonly();

   private readonly _taskEditable = signal(false);
   public readonly taskEditable = this._taskEditable.asReadonly();

   private readonly _itemsEditable = signal(false);
   public readonly itemsEditable = this._itemsEditable.asReadonly();

   private readonly _preview = signal(false);
   public readonly preview = this._preview.asReadonly();

   private readonly _info = signal<TaskInfo | undefined>(undefined);
   public readonly info = this._info.asReadonly();

   private readonly _displayAssetInfo = signal(false);
   public readonly displayAssetInfo = this._displayAssetInfo.asReadonly();

   private readonly _taskOpen = signal(false);
   public readonly taskOpen = this._taskOpen.asReadonly();

   private readonly _expand = signal(false);
   public readonly expand = this._expand.asReadonly();

   private readonly _limitedSettings = signal(false);
   public readonly limitedSettings = this._limitedSettings.asReadonly();

   private readonly _completionNotesError = signal(false);
   public readonly completionNotesError = this._completionNotesError.asReadonly();

   private readonly _partsUnderStocked = signal<Set<number>>(new Set());
   public readonly partsUnderStocked = this._partsUnderStocked.asReadonly();

   private readonly _partsOverReserved = signal<Set<number>>(new Set());
   public readonly partsOverReserved = this._partsOverReserved.asReadonly();

   private readonly _middleStatusDisplay = signal<Array<any>>([]);
   public readonly middleStatusDisplay = this._middleStatusDisplay.asReadonly();

   private readonly _geoLocation = signal<GeoFeature | null>(null);
   public readonly geoLocation = this._geoLocation.asReadonly();

   private readonly _assetNameStr = signal<string | undefined>(undefined);
   public readonly assetNameStr = this._assetNameStr.asReadonly();

   private readonly _noteHidden = signal<number>(0);
   public readonly noteHidden = this._noteHidden.asReadonly();

   private readonly _instructions = signal<Array<TaskItem>>([]);
   public readonly instructions = this._instructions.asReadonly();

   private readonly _shouldShowModeToggleButton = signal<boolean>(true);
   public readonly shouldShowModeToggleButton =
      this._shouldShowModeToggleButton.asReadonly();

   public readonly taskFormState = computed(() => {
      return {
         preventDoubleClick: this.preventDoubleClick(),
         customTagData: this.customTagData(),
         tempEdit: this.tempEdit(),
         permissions: this.permissions(),
         options: this.options(),
         limited: this.limited(),
         mode: this.mode(),
         disableAlerts: this.disableAlerts(),
         guided: this.guided(),
         taskEditable: this.taskEditable(),
         itemsEditable: this.itemsEditable(),
         preview: this.preview(),
         info: this.info(),
         displayAssetInfo: this.displayAssetInfo(),
         taskOpen: this.taskOpen(),
         expand: this.expand(),
         limitedSettings: this.limitedSettings(),
         completionNotesError: this.completionNotesError(),
         partsUnderStocked: this.partsUnderStocked(),
         partsOverReserved: this.partsOverReserved(),
         middleStatusDisplay: this.middleStatusDisplay(),
         geoLocation: this.geoLocation(),
         assetNameStr: this.assetNameStr(),
         noteHidden: this.noteHidden(),
         instructions: this.instructions(),
         shouldShowModeToggleButton: this.shouldShowModeToggleButton(),
      };
   });

   //TODO (TASK-828): Look into if these po maps need to be signals since it is used in the view.
   protected readonly partsPendingPOs: LimbleMap<
      number,
      Array<
         PurchaseOrder & {
            currentState: PurchaseOrderCurrentState | undefined;
            extraBatch?: ExtraBatch;
         }
      >
   > = new LimbleMap();

   protected readonly partsSelectedPOs: LimbleMap<number, PurchaseOrder> =
      new LimbleMap();

   public showPOsOnParts = new Map<number, boolean>();

   public updateMode(mode: TaskDataMode): void {
      this._mode.set(mode);
   }

   public updateTasksEditable(editable: boolean): void {
      this._taskEditable.set(editable);
   }

   public updateItemsEditable(editable: boolean): void {
      this._itemsEditable.set(editable);
   }

   public updateExpand(expand: boolean): void {
      this._expand.set(expand);
   }

   public updateLimited(limited: boolean): void {
      this._limited.set(limited);
   }

   public updateLimitedSettings(limitedSettings: boolean): void {
      this._limitedSettings.set(limitedSettings);
   }

   public updateDisableAlerts(disableAlerts: boolean): void {
      this._disableAlerts.set(disableAlerts);
   }

   public updateOptions(options: any): void {
      this._options.set(options);
   }

   public updateTempEdit(value: boolean): void {
      this._tempEdit.set(value);
   }

   public updatePreventDoubleClick(value: boolean): void {
      this._preventDoubleClick.set(value);
   }

   public updateShouldShowModeToggleButton(value: boolean): void {
      this._shouldShowModeToggleButton.set(value);
   }
}
