import type { ConvertPascalToCamelCase } from "src/app/shared/utils/convert-pascal-to-camel-case/convert-pascal-to-camel-case.types";

export function convertPascalToCamelCase<S extends string = string>(
   str: S,
): ConvertPascalToCamelCase<S> {
   let converted = str.slice(0, 1).toLowerCase();
   let rest = str.slice(1);
   let isPreviousUppercase = false;
   let isConverted = false;

   while (!isConverted) {
      const currentCharacter = rest.slice(0, 1);
      const nextCharacter = rest.slice(1, 2);

      const isCurrentCharacterLowerCase =
         currentCharacter === currentCharacter.toLowerCase();
      const isNextCharacterLowerCase =
         nextCharacter !== "" && nextCharacter === nextCharacter.toLowerCase();

      const isEndOfString = currentCharacter === "";

      if (
         isCurrentCharacterLowerCase ||
         isEndOfString ||
         (isPreviousUppercase && isNextCharacterLowerCase)
      ) {
         converted += rest;
         isConverted = true;
      } else {
         converted += currentCharacter.toLowerCase();
         rest = rest.slice(1);
         isPreviousUppercase = true;
      }
   }

   return converted as ConvertPascalToCamelCase<S>;
}
