<div class="filters-container" [ngClass]="{ 'no-margins': noMargins() }">
   <lim-ui-filters-wrapper [noMargins]="true" title="{{ lang().Filter }}:">
      @for (filter of filters(); track filter) {
         <ng-container filterInputs>
            @switch (filter.type) {
               @case ("date") {
                  <date-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("completedBy") {
                  <completed-by-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("assignedTo") {
                  <assigned-to-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("taskType") {
                  <task-type-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("customTag") {
                  <custom-tag-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("location") {
                  <location-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("partLogType") {
                  <part-log-type-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("priorityLevel") {
                  <priority-level-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("taskStatus") {
                  <task-status-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("user") {
                  <user-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("postLogAction") {
                  <post-log-action-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("asset") {
                  <asset-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("id") {
                  <id-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("assetFieldValue") {
                  <asset-field-value-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
               @case ("workRequestStatus") {
                  <work-request-status-filter
                     [filter]="filter"
                     (remove)="onRemoveFilter($event)"
                     (set)="onSetFilter($event)"
                  />
               }
            }
         </ng-container>
      }
   </lim-ui-filters-wrapper>
   @if (activeFilters().length > 0 && showActiveFilters()) {
      <div class="active-filters">
         <label inputLabel> {{ lang().ActiveFilter }}:</label>
         @for (
            activeFilter of activeFilters().values();
            track activeFilter;
            let i = $index
         ) {
            <b class="active-filters__label"> {{ activeFilter.activeLabel }}</b>
            <lim-ui-clear-filter-button
               class="active-filters__clear-button"
               (click)="onRemoveFilter(activeFilter)"
            />
            @if (i < activeFilters().length - 1) {
               <b> ,&nbsp; </b>
            }
         }
      </div>
   }
</div>
