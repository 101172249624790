<div class="item-description">
   <span [limbleHtml]="linkHint"></span>
   @if (showHelp) {
      <lim-ui-icon
         icon="circleQuestion"
         iconSize="small"
         placement="bottom"
         limUiTooltip="{{ lang().linkHintTooltip }}"
      />
   }
</div>
