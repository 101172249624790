import { computed, inject, Injectable, type Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { firstValueFrom, map } from "rxjs";
import type { CustomUnit } from "src/app/parts/unit-of-measure/custom-units/custom-unit.model";
import { ProvidedUnit } from "src/app/parts/unit-of-measure/provided-units/provided-unit.model";
import { UnitOfMeasureAvailabilityService } from "src/app/parts/unit-of-measure/unit-of-measure-availability.service";
import { UnitOfMeasureMappingService } from "src/app/parts/unit-of-measure/unit-of-measure-mapping.service";
import { UnitOfMeasureRefreshService } from "src/app/parts/unit-of-measure/unit-of-measure-refresh.service";
import type { UnitDescription } from "src/app/parts/unit-of-measure/unit-of-measure.dto.types";
import type { UnitDescriptionToModel } from "src/app/parts/unit-of-measure/unit-of-measure.service.types";
import type { Unit } from "src/app/parts/unit-of-measure/unit.types";
import { UnitTypes } from "src/app/parts/unit-of-measure/unit.types";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { assert } from "src/app/shared/utils/assert.utils";

@Injectable({ providedIn: "root" })
export class UnitOfMeasureService {
   private readonly DEFAULT_UNIT_TRANSLATION_KEY = "each";

   private readonly mappingService = inject(UnitOfMeasureMappingService);
   private readonly refreshService = inject(UnitOfMeasureRefreshService);
   private readonly availabilityService = inject(UnitOfMeasureAvailabilityService);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUtil = inject(ManageUtil);

   public readonly units = this.computedUnits();
   private readonly providedUnitMap = this.computedProvidedUnitMap();
   private readonly customUnitMap = this.computedCustomUnitMap();
   private readonly _areUnitsInitialized = this.refreshService.latest$.pipe(
      map(() => true),
   );

   public readonly defaultUnit = computed(() => {
      const units = this.units();
      if (units === null) return null;

      const defaultUnit = units
         .filter((unit) => unit instanceof ProvidedUnit)
         .find((unit) => unit.translationKey === this.DEFAULT_UNIT_TRANSLATION_KEY);

      assert(defaultUnit !== undefined, "Default unit not found.");

      return defaultUnit;
   });

   public readonly canUseUnitOfMeasureSettings =
      this.availabilityService.canUseUnitOfMeasureSettings;
   public readonly isFeatureEnabled = this.availabilityService.isFeatureEnabled;
   public readonly areUnitsInitialized = firstValueFrom(this._areUnitsInitialized);

   public getUnit<T extends UnitDescription>({
      id,
      type,
   }: T): Signal<UnitDescriptionToModel<T> | null> {
      return computed(() => {
         const unitMap =
            type === UnitTypes.Provided ? this.providedUnitMap() : this.customUnitMap();
         if (unitMap === null) return null;

         const unit = unitMap[id];
         assert(unit !== undefined, `Unit with ID ${id} not found.`);
         return unit as UnitDescriptionToModel<T>; /** Unable to infer union as conditional type */
      });
   }

   public convertUnitToDescription(unit: Unit): UnitDescription {
      return {
         id: unit.id,
         type: unit instanceof ProvidedUnit ? UnitTypes.Provided : UnitTypes.Custom,
      };
   }

   public isUnitDefault(unit: Unit): boolean {
      return (
         unit instanceof ProvidedUnit &&
         unit.translationKey === this.DEFAULT_UNIT_TRANSLATION_KEY
      );
   }

   private computedUnits(): Signal<Array<Unit> | null> {
      return toSignal(
         this.refreshService.latest$.pipe(
            map((unitApiDtos) =>
               this.mappingService
                  .mapDtosToModels(unitApiDtos)
                  .sort((aa, bb) => aa.short().localeCompare(bb.short())),
            ),
         ),
         { initialValue: null },
      );
   }

   private computedProvidedUnitMap(): Signal<Record<number, ProvidedUnit> | null> {
      return computed(() => {
         const units = this.units();
         if (units === null) return null;

         const providedUnitMap = this.mappingService.mapModelsToProvidedUnitMap(units);

         return providedUnitMap;
      });
   }

   private computedCustomUnitMap(): Signal<Record<number, CustomUnit> | null> {
      return computed(() => {
         const units = this.units();
         if (units === null) return null;

         const customUnitMap = this.mappingService.mapModelsToCustomUnitMap(units);

         return customUnitMap;
      });
   }

   public downloadExcel(unitsToDownload: Array<Unit> | null, name: string) {
      if (unitsToDownload === null) return;
      const partsReadyToDownload: any = [];

      for (const unit of unitsToDownload) {
         const obj: any = {};
         obj.Abbreviation = unit.short();
         obj["Unit Name"] = unit.singular();
         obj.Category = unit.category;
         partsReadyToDownload.push(obj);
      }

      const today = this.betterDate.createTodayTimestamp();

      this.manageUtil.objToExcel(partsReadyToDownload, "Units", `${name}-${today}.xlsx`);
   }
}
