import type { OnInit } from "@angular/core";
import { inject, Component, signal, input } from "@angular/core";
import { takeUntilDestroyed, toObservable, toSignal } from "@angular/core/rxjs-interop";
import {
   BasicModalHeaderComponent,
   LimUiModalRef,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   type ModalResult,
} from "@limblecmms/lim-ui";
import {
   combineLatestWith,
   concat,
   debounceTime,
   map,
   skip,
   take,
   type Observable,
} from "rxjs";
import { TranslationService } from "src/app/languages/translation/translation.service";
import type {
   CreateBudgetNodeParams,
   CreateStandardBudgetNodeParams,
   FilterNodesParams,
} from "src/app/purchasing/budgets/pickBudgetsModal/pickBudgets.modal.component.params";
import type { BudgetHierarchyNode } from "src/app/purchasing/budgets/pickBudgetsModal/pickBudgets.modal.component.types";
import { PopBudget } from "src/app/purchasing/budgets/popBudgetModal/popBudget.modal.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import type { HierarchyOptions } from "src/app/shared/types/general.types";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "pick-budgets",
   templateUrl: "./pickBudgets.modal.component.html",
   styleUrls: ["./pickBudgets.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SearchBoxComponent,
      HierarchyContainerLegacy,
      NoSearchResults,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
   ],
})
export class PickBudgets implements OnInit, ModalResult<number> {
   private static readonly SEARCH_FILTER_DUE_TIME = 250;

   private readonly managePO = inject(ManagePO);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   protected readonly i18n = inject(TranslationService).i18n;

   private readonly searchableNodes = signal([this.createNoBudgetNode()]);

   public locationID = input.required<number>();

   public modalRef = inject<LimUiModalRef<PickBudgets, number>>(LimUiModalRef);

   protected hierarchyOptions = this.createHierarchyOptions();
   protected searchQuery = signal<string>("");
   protected nodes = toSignal(
      toObservable(this.searchableNodes).pipe(
         combineLatestWith(this.createSearchQueryObservable()),
         map(([nodes, query]) => this.filterNodesByName({ nodes, query })),
      ),
      { initialValue: this.searchableNodes() },
   );

   public ngOnInit(): void {
      this.searchableNodes.set(this.createBudgetNodes());
   }

   protected close(): void {
      this.modalRef.close(undefined);
   }

   private compareTitle(nodeA: BudgetHierarchyNode, nodeB: BudgetHierarchyNode): number {
      const titleA = nodeA.title.toLocaleLowerCase();
      const titleB = nodeB.title.toLocaleLowerCase();
      return titleA.localeCompare(titleB);
   }

   protected submit(): void {
      const selectedBudgetNode = this.nodes().find((budgetNode) => budgetNode.selected);

      if (selectedBudgetNode === undefined) {
         this.alertService.addAlert(
            this.i18n().t("PleasePickAtLeast1Budget"),
            "warning",
            3000,
         );
         return;
      }

      this.modalRef.close(selectedBudgetNode.budgetID);
   }

   private filterNodesByName({
      nodes,
      query,
   }: FilterNodesParams): Array<BudgetHierarchyNode> {
      return query === ""
         ? nodes
         : nodes.filter(
              (node) => node.name?.toLowerCase().includes(query.toLowerCase()) === true,
           );
   }

   /**
    * Allows the first emission to be immediate and subsequent emissions to be debounced.
    */
   private createSearchQueryObservable(): Observable<string> {
      const initialQuery = toObservable(this.searchQuery).pipe(take(1));
      const searchQueryUpdates = toObservable(this.searchQuery).pipe(
         skip(1),
         debounceTime(PickBudgets.SEARCH_FILTER_DUE_TIME),
         takeUntilDestroyed(),
      );
      return concat(initialQuery, searchQueryUpdates);
   }

   private openBudgetModal(budgetNode: BudgetHierarchyNode): void {
      const modalRef = this.modalService.open(PopBudget);
      modalRef.setInput("budgetID", budgetNode.budgetID);
   }

   private createHierarchyOptions(): HierarchyOptions {
      return {
         idKey: "budgetID",
         selection: { singleSelection: true },
         nodeButtons: [
            {
               /** Race condition since `tooltip` is not a signal / reactive */
               tooltip: this.i18n().t("OpenThisBudget"),
               clickFunction: this.openBudgetModal.bind(this),
               permissionNumber: this.credService.Permissions.ManageBudgets,
               /** Race condition since `text` is not a signal / reactive */
               text: this.i18n().t("View"),
            },
         ],
         submit: this.submit.bind(this),
      };
   }

   private createBudgetNodes(): BudgetHierarchyNode[] {
      return [
         this.createNoBudgetNode(),
         ...this.createStandardBudgetNodes().sort((nodeA, nodeB) =>
            this.compareTitle(nodeA, nodeB),
         ),
      ];
   }

   private createStandardBudgetNodes(): BudgetHierarchyNode[] {
      return [...this.managePO.getBudgets()]
         .filter(
            (budget) =>
               budget.locationID === this.locationID() && budget.budgetDeleted === 0,
         )
         .map((budget) => this.createStandardBudgetNode(budget));
   }

   private createStandardBudgetNode({
      budgetID,
      name,
      locationID,
   }: CreateStandardBudgetNodeParams): BudgetHierarchyNode {
      return this.createBudgetNode({
         budgetID,
         name,
         locationID,
         icon: "creditCard",
      });
   }

   private createNoBudgetNode(): BudgetHierarchyNode {
      return this.createBudgetNode({
         icon: "creditCardRegular",
         budgetID: 0,
         /** Race condition since `name` is not a signal / reactive */
         name: this.i18n().t("CreateWithoutAnAssignedBudget"),
         locationID: 0,
      });
   }

   private createBudgetNode({
      icon,
      budgetID,
      name,
      locationID,
   }: CreateBudgetNodeParams): BudgetHierarchyNode {
      return {
         budgetID,
         icon,
         title: name ?? "",
         name: name ?? "",
         locationID: locationID ?? 0,
         selected: false,
         displayButtons: true,
         collapsed: false,
         nodes: [],
      };
   }
}
