import { createKeyToDefaultMap } from "src/app/shared/services/launch-flags/read/create-key-to-default-map";

export const LaunchFlagKeyToDefaultValueMap = createKeyToDefaultMap([
   "leaf-741",
   "locations-from-mini-flannel",
   "location-details-flannel-endpoint",
   "release-multi-currency-phase-1",
   "get-currencies-from-mini-flannel",
] as const);

export type FlagMap = typeof LaunchFlagKeyToDefaultValueMap;
export type FlagKey = keyof FlagMap;
