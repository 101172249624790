import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { CommentFiles } from "src/app/files/components/commentFiles/commentFiles.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { Mention } from "src/app/shared/components/global/mention/mention";
import { MentionService } from "src/app/shared/components/global/mention/mention.service";
import { WhoGetsNoteNotifications } from "src/app/shared/components/global/whoGetsNoteNotificationsModal/whoGetsNoteNotifications.modal.component";
import { AutoFocusDirective } from "src/app/shared/directives/autofocus/autoFocus.directive";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "add-p-o-comment",
   templateUrl: "./addPOComment.modal.component.html",
   styleUrls: ["./addPOComment.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      ContenteditableDirective,
      AutoFocusDirective,
      FormsModule,
      Mention,
      CommentFiles,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
   ],
})
export class AddPOComment implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public title;
   public message;
   public errorMsg;
   public purchaseOrderID;
   public customerID;
   public userID;
   public uploadObject;
   public files;
   public comment;
   public mentionList: any = [];
   public newMentionedList: any = [];
   public userProfiles;
   public mentionUid;
   public purchaseOrder: PurchaseOrder | undefined;
   public data;

   private readonly managePO = inject(ManagePO);
   private readonly alertService = inject(AlertService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly mentionService = inject(MentionService);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.errorMsg = "";
      this.purchaseOrderID = this.data.poID;
      this.purchaseOrder = this.managePO.getPurchaseOrder(this.purchaseOrderID);

      this.customerID = this.data.CID;

      this.userID = this.manageUser.getCurrentUser().gUserID;

      this.uploadObject = {};
      this.uploadObject.deleteData = {};
      this.uploadObject.primaryID = "fileName";
      this.uploadObject.viewOnly = false;

      this.uploadObject.posturl = `phpscripts/managePO.php?action=makeFileForComment&poID=${this.purchaseOrderID}&prID=0`;

      this.uploadObject.deleteCall = async (result) => {
         return this.managePO.deleteTempFileFromAddComment(result.fileName);
      };

      this.uploadObject.deleteSuccess = (answer) => {
         if (answer.data.success == true) {
            for (const [index, file] of this.files.entries()) {
               if (file.fileName == answer.data.fileName) {
                  this.files.splice(index, 1);
               }
            }
            this.files = [...this.files];
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         }
      };

      this.uploadObject.uploadComplete = (result) => {
         if (result.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );
         }

         if (result?.fileName?.length > 2) {
            const regex = /(?:\.([^.]+))?$/;
            result.row.fileExt = regex.exec(result.row.fileName);
            if (result.row.fileExt.length > 1) {
               result.row.fileExt = result.row.fileExt[1].toLowerCase();
            }
            if (this.userID === undefined) {
               this.userID = result.userID;
            }
            result.row.getURL = `viewFile.php?f=upload-${this.customerID}/comments/po/${result.userID}/${this.purchaseOrderID}/${result.fileName}`;
            this.files = this.files || [];
            this.files = [...this.files, result.row];
         }
      };

      document.addEventListener("keydown", this.submitOnCtlEnter);

      //
      // begging of data and logic for the mention system
      //

      /** List of items passed into the mention system */
      this.mentionList = [];
      /** associative array of users and teams */
      this.userProfiles = [];
      /** newMentionedList is the list of newly mentioned items in the comment */
      this.newMentionedList = [];
      /** random string used to create unique id's for the mention system */
      this.mentionUid = this.manageUtil.getRandomString();

      /** calls function to populate mentioned list */
      if (this.manageUser.getCurrentUser()) {
         this.getMentionList();
      }
   }

   public ngOnDestroy() {
      document.removeEventListener("keydown", this.submitOnCtlEnter);
   }

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      const listOfMentionsToNotify = this.mentionService.createListOfMentionsToNotify(
         this.newMentionedList,
         this.mentionList,
         this.userProfiles,
      );

      this.errorMsg = "";

      const InputEl = document.getElementById(`input-field-${this.mentionUid}`);
      if (InputEl && InputEl.innerHTML != this.comment) {
         this.comment = InputEl.innerHTML;
      }

      if (
         this.uploadObject.uploading ||
         this.comment === undefined ||
         this.comment.length == 0
      ) {
         if (this.uploadObject.uploading) {
            this.errorMsg = this.lang().pleaseWaitForFileToLoad;
         } else {
            this.errorMsg = this.lang().AddCommentError;
         }
         if (this.errorMsg !== "") {
            this.alertService.addAlert(this.errorMsg, "warning", 6000);
         }
         return;
      }

      this.comment = this.comment.replace(/<\s*span[^>]*>/g, "<font color = '#4684d0'>");
      this.comment = this.comment.replace(/<\s*\/\s*span>/g, "</font>");
      this.comment = this.comment.replace(/&nbsp;/g, " ");
      this.comment = this.comment.replace(/&quot;/g, "'");

      const result: any = {};
      result.comment = this.comment;
      result.newMentionedList = listOfMentionsToNotify;
      this.modalInstance.close(result);
   };

   submitOnCtlEnter = (evt) => {
      if (evt.ctrlKey && evt.keyCode === 13) {
         // Have to blur the field manually because ng-model doesn't update with contenteditable divs until it loses focus
         evt.target.blur();
         evt.preventDefault();
         this.submit();
      }
   };

   whoToNotify = () => {
      const listOfMentionsToNotify = this.mentionService.createListOfMentionsToNotify(
         this.newMentionedList,
         this.mentionList,
         this.userProfiles,
      );

      const instance = this.modalService.open(WhoGetsNoteNotifications);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().whoGetsNoteNotificationsTitle,
            data: {
               purchaseOrderID: this.purchaseOrderID,
               newMentionedList: listOfMentionsToNotify,
            },
         },
      };
   };

   getMentionList = () => {
      if (!this.purchaseOrder) return;
      const result = this.manageUser.getUsersAndProfilesAtLocation(
         this.purchaseOrder.locationID,
      );

      const usersAtLocationData = result.data;

      let teamsWithMembers = usersAtLocationData.profiles.filter((profile) =>
         usersAtLocationData.userProfiles.some(
            (profileCheck) => profileCheck.profileID === profile.profileID,
         ),
      );
      teamsWithMembers = this.manageUtil.alphabeticalSort(
         teamsWithMembers,
         "profileDescription",
      );
      const tags = this.manageUser.getCurrentUser().tags.split("@");
      const customTags: any = [];
      if (
         this.credService.isAuthorized(
            this.purchaseOrder.locationID,
            this.credService.Permissions.TagATaskWithACustomTag,
         )
      ) {
         for (const key in tags) {
            if (tags[key].length > 0) {
               const obj: any = {};
               obj.name = `@${tags[key]}`;
               obj.tagDescription = tags[key];
               obj.itemID = this.manageUtil.getRandomString();
               customTags.push(obj);
            }
         }
      }
      const tagsList = this.manageUtil.alphabeticalSort(customTags, "tagDescription");

      this.mentionList = this.manageUtil.alphabeticalSort(
         usersAtLocationData.users,
         "userFirstName",
      );
      this.mentionList = this.mentionList.concat(teamsWithMembers);
      this.mentionList = this.mentionList.concat(tagsList);
      this.userProfiles = usersAtLocationData.userProfiles;
   };
   updateNewMentionedList = (newMentionedList) => {
      this.newMentionedList = newMentionedList;
   };
}
