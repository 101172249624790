import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { DragEndEvent, LimbleTreeOptions } from "@limble/limble-tree";
import { LimbleTreeRootComponent } from "@limble/limble-tree";
import {
   BasicModalHeaderComponent,
   CheckboxComponent,
   DangerButtonComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import clone from "rfdc";
import { ConfirmFieldLock } from "src/app/assets/components/confirm-field-lock/confirm-field-lock.modal.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { DateReminder } from "src/app/parts/components/field/reminderFields/dateReminder.element.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { PartField } from "src/app/parts/types/field/field.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { EditFieldOption } from "src/app/shared/components/global/editFieldOptionElement/editFieldOption.element.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { cleanWordPaste } from "src/app/shared/utils/app.util";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

const deepClone = clone();

type FieldExtraInfo = {
   fieldNameTemp: string;
   profileDescription: string;
   userFirstName: string;
   userLastName: string;
};

@Component({
   selector: "edit-part-field",
   templateUrl: "./editPartField.modal.component.html",
   styleUrls: ["./editPartField.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      ContenteditableDirective,
      FormsModule,
      CheckboxComponent,
      IconComponent,
      TooltipDirective,
      LimbleTreeRootComponent,
      SecondaryButtonComponent,
      DateReminder,
      ModalFooterComponent,
      DangerButtonComponent,
   ],
})
export class EditPartField implements OnInit {
   public resolve;
   public modalInstance;
   public field: PartField | undefined;
   public options;
   public title;
   public isCustomDefault: boolean = false;
   public treeOptions?: LimbleTreeOptions;
   public fields: Lookup<"fieldID", PartField> | undefined;
   public fieldExtraInfo: FieldExtraInfo;
   protected valueUnique: boolean | undefined;
   protected isSuperUser: boolean = false;
   protected hasFieldLockFlag: boolean = false;

   private readonly modalService = inject(ModalService);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageUser = inject(ManageUser);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageTask = inject(ManageTask);
   private readonly manageProfile = inject(ManageProfile);
   private readonly paramsService = inject(ParamsService);
   private readonly legacyLaunchFlagService = inject(LegacyLaunchFlagsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.fieldExtraInfo = {
         fieldNameTemp: "",
         profileDescription: "",
         userFirstName: "",
         userLastName: "",
      };
   }

   public ngOnInit() {
      this.legacyLaunchFlagService
         .isEnabled(Flags.PART_VENDOR_FIELD_LOCK)
         .then((enabled) => {
            this.hasFieldLockFlag = enabled;
         });

      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const field = this.resolve.field;
      this.field = this.manageParts.getField(field.fieldID); //had to add because optionsJSON wasn't saving when creating new field closing and then reopening on frontend scope
      assert(this.field);
      this.field.dateReminder1 = Number(this.field.dateReminder1);
      this.field.dateReminder2 = Number(this.field.dateReminder2);
      this.field.dateReminder3 = Number(this.field.dateReminder3);
      this.isCustomDefault = Boolean(Number(this.field.isCustomDefault));
      this.options = [{ name: "", order: 1 }];
      field.fieldName = this.field.fieldName;
      this.fieldExtraInfo.fieldNameTemp = field.fieldName;
      if (field.viewableByTechFieldDefault === undefined) {
         field.viewableByTechFieldDefault = 1;
      }

      const currentUser = this.manageUser.getCurrentUser();
      this.isSuperUser = this.manageUtil.checkIfSuperUser(currentUser);

      this.valueUnique = Boolean(this.field?.valueUnique);

      this.fields = this.manageParts
         .getFields()
         .filter((element) => element.locationID === this.field?.locationID);

      this.title = `${field.fieldName} - ${this.lang().Edit}`;

      if (field.optionsJSON) {
         this.options = JSON.parse(field.optionsJSON);
      }
      this.treeOptions = {
         allowNesting: false,
         defaultComponent: {
            class: EditFieldOption,
            bindings: {
               options: this.options,
               updateFieldDropdownOptions: this.updateFieldDropdownOptions,
               deleteOption: this.deleteOption,
               workflowLabel: "Part",
            },
         },
      };

      this.setReminderDisplayStr();
   }

   /** set the order var to be the correct order */
   protected onMoveNode(event: DragEndEvent<EditFieldOption>) {
      const draggedField = this.options.splice(event.oldIndex(), 1);
      this.options.splice(event.newIndex(), 0, draggedField[0]);
      for (const [index, option] of this.options.entries()) {
         option.order = index + 1;
      }
      this.updateFieldDropdownOptions();
   }

   updateFieldViewableByTechFieldDefault = (value: boolean) => {
      if (!this.field) {
         return;
      }

      // Kinda weird, but because the checkbox in the UI needs to display the inverse of the actual value, we need to manually set this
      this.field.viewableByTechFieldDefault = value ? 1 : 0;
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().ChangeDefaultShowHideMsg} <b>${this.field.fieldName}</b>?  <br /><br />${this.lang().ChangeDefaultShowHideMsgParts}`,
            title: `${this.lang().ChangeDefaultShowHide} - <b>"${this.field.fieldName}"</b>`,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            assert(this.field);
            this.manageParts
               .updateFieldViewableByTechFieldDefault(this.field)
               .then((answer) => {
                  if (!this.field) {
                     return;
                  }
                  if (answer?.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         } else {
            assert(this.field);
            // They cancelled/closed the modal, so we revert the viewableByTech value.
            this.field.viewableByTechFieldDefault =
               this.field.viewableByTechFieldDefault === 1 ? 0 : 1;
         }
      });
   };

   updateSuggestedFieldName = (fieldToUpdate) => {
      assert(this.fields);
      assert(this.field);
      if (this.fieldExtraInfo.fieldNameTemp == fieldToUpdate.fieldName) {
         //they are the same so don't let them change it
         return;
      }

      for (const suggestedField of this.fields) {
         if (this.fieldExtraInfo.fieldNameTemp == suggestedField.fieldName) {
            this.alertService.addAlert(
               this.lang().cantNotNameFieldTheSameAsAnotherField,
               "warning",
               6000,
            );
            this.fieldExtraInfo.fieldNameTemp = `${fieldToUpdate.fieldName}`;
            return;
         }
      }

      fieldToUpdate.fieldName = this.fieldExtraInfo.fieldNameTemp; //passed checks so set it

      this.manageParts
         .updateSuggestedFieldName(
            this.field,
            fieldToUpdate.locationID,
            this.fieldExtraInfo.fieldNameTemp,
         )
         .then((answer) => {
            if (answer?.data.success == true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   addDropdownOption = () => {
      const obj: any = {};

      let order = 0;
      for (const option of this.options) {
         if (option.order > order) {
            order = Number(option.order);
         }
      }
      obj.order = order + 1;

      this.options.push(obj);
      this.updateFieldDropdownOptions();
   };

   deleteOption = (option) => {
      if (this.options.length == 1) {
         this.alertService.addAlert(
            "At least one dropdown option is required",
            "warning",
            1000,
         );
      } else {
         this.options = this.options.filter((opt) => opt.order != option.order);

         for (let index = 0; index < this.options.length; index++) {
            this.options[index].order = Number(index) + 1;
         }

         //set the orders to sync up
         this.updateFieldDropdownOptions();
      }
   };

   updateFieldDropdownOptions = () => {
      assert(this.field);
      //might want to build an obj comparison function to check for equalality and not run unnecassary calls to the db

      //since we are treating everything in options as a json we only need one function to update the DB
      //prep to send to the DB
      const optionsCopy = this.options.map((opt) => {
         //doesn't play well if there isn't a name
         if (!opt.name) {
            opt.name = "";
         }

         opt.name = this.manageUtil.removeDuplicateSpaces(opt.name);
         opt.name = opt.name.replace(/(\r\n|\n|\r)/gm, "");
         opt.name = cleanWordPaste(opt.name);
         return { order: opt.order, name: opt.name };
      });
      if (optionsCopy.length < 1) {
         this.addDropdownOption();
      }
      const newField = deepClone(this.field);
      newField.optionsJSON = JSON.stringify(optionsCopy);

      this.manageParts.updateDropdownOptions(newField).then((answer) => {
         if (answer.data.success) {
            assert(this.field);
            //have to add this stupid thing because the global settings was wigging out without posting changes to DB ;/
            assert(newField.optionsJSON);
            this.options = JSON.parse(newField.optionsJSON);

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   removeSuggestedField = (fieldToRemove: PartField) => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message:
               `${this.lang().RemoveSuggestedFieldMsg} <b>${fieldToRemove.fieldName}</b>?` +
               `  ${this.lang().RemoveSuggestedFieldMsgParts}`,
            title: `${this.lang().RemoveSuggestedField} - <b>"${fieldToRemove.fieldName}"</b>`,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.manageParts.removeSuggestedField(fieldToRemove).then((answer) => {
               if (answer?.data.success == true) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  this.modalInstance.close(this.field);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   changeFieldOwner = (fieldToUpdate) => {
      assert(this.field);
      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               message: this.lang().ChangeReminderAssignmentMsg,
               title: this.lang().ChangeThresholdReminderAssignment,
               locationID: fieldToUpdate.locationID,
               defaultUser: this.field.userID,
               defaultProfile: this.field.profileID,
            },
         },
      };

      instance.result.then((data) => {
         if (data) {
            assert(this.field);
            this.manageParts
               .updateDateReminderAssignments(
                  fieldToUpdate.fieldID,
                  data.userID,
                  data.profileID,
                  data.multiUsers,
                  this.field.locationID,
               )
               .then((response) => {
                  if (response.data.success == true) {
                     assert(this.field);
                     const userID = response.data.userID;
                     const profileID = response.data.profileID;
                     const profileDescription = response.data.profileDescription;
                     this.field.userID = userID;
                     this.field.profileID = profileID;

                     if (profileID > 0) {
                        this.manageFilters.updateHiddenProfiles(
                           {
                              profileID: profileID,
                              name: profileDescription,
                              locationID: this.field.locationID,
                              multiUsers: data.multiUsers,
                           },
                           this.manageTask,
                           this.manageUser,
                           this.manageProfile,
                        );
                     }

                     this.setReminderDisplayStr();

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   setReminderDisplayStr = () => {
      assert(this.field);
      if (this.field.userID && this.field.userID > 0) {
         const user = this.manageUser.getFlatUsersIndex()[this.field.userID];
         if (user === undefined) {
            this.fieldExtraInfo.userFirstName = this.lang().DeletedUser;
            this.fieldExtraInfo.userLastName = "";
         } else {
            this.fieldExtraInfo.userFirstName = user.userFirstName;
            this.fieldExtraInfo.userLastName = user.userLastName;
         }
      } else if (this.field.profileID && this.field.profileID > 0) {
         const profile = this.manageUser.getProfilesIndex()[this.field.profileID];
         if (profile === undefined) {
            this.fieldExtraInfo.profileDescription = this.lang().DeletedUser;
         } else {
            this.fieldExtraInfo.profileDescription = profile.profileDescription;
         }
      }
   };

   updateValueUnique = () => {
      assert(this.field);
      const newValue = this.field.valueUnique === 0 ? 1 : 0;
      if (this.field.valueUnique === 1) {
         const instance = this.modalService.open(Confirm);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().RemoveUniqueValueRequirementMessage,
               title: this.lang().RemoveUniqueValueRequirement,
            },
         };
         instance.result.then((answer) => {
            if (answer === 1) {
               this.updateValueUniqueCall(newValue);
            } else {
               assert(this.field);
               this.field.valueUnique = 1;
            }
         });
      } else {
         this.updateValueUniqueCall(newValue);
      }
   };

   updateValueUniqueCall = (newValue) => {
      assert(this.field);
      this.manageParts.updateValueUnique(this.field.fieldID, newValue).then((result) => {
         if (result.data.success == true) {
            assert(this.field);
            //trigger change detection
            this.field.valueUnique = newValue;
            this.valueUnique = Boolean(newValue);
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else if (result.data.error === "duplicateValuesAlready") {
            assert(this.field);
            this.field.valueUnique = 0;
            this.valueUnique = false;
            this.alertService.addAlert(
               this.lang().DuplicateValuesAlreadyError,
               "danger",
               6000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   dismiss = () => {
      this.modalInstance.close(this.field);
   };

   /** Updates the `isCustomDefault` property of the field and saves it to the
    * database, based on the state of the checkbox in the view. */
   protected updateCustomDefault(): void {
      assert(this.field);
      this.manageParts
         .updateFieldIsCustomDefault(this.field.fieldID, this.isCustomDefault)
         .then((succeeded) => {
            assert(this.field);
            if (succeeded === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
            this.isCustomDefault = Boolean(this.field.isCustomDefault);
         });
   }

   protected toggleFieldLockedDefault(): void {
      if (!this.field || !this.isSuperUser) {
         return;
      }
      const instance = this.modalService.open(ConfirmFieldLock);
      instance.componentInstance.field = this.field;
      instance.componentInstance.message = this.field.lockedDefault
         ? this.lang().LockConfirmationMessageBasic
         : this.lang().UnlockConfirmationMessageParts;

      instance.result.then((result) => {
         if (result === true) {
            this.saveFieldLockedDefault();
         } else {
            assert(this.field);
            // They cancelled/closed the modal, so we revert the lockedDefault value.
            this.field.lockedDefault = this.field?.lockedDefault === 1 ? 0 : 1;
         }
      });
   }

   private saveFieldLockedDefault(): void {
      assert(this.field);
      this.manageParts.saveFieldLockedDefault(this.field).then((result) => {
         if (result.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }
}
