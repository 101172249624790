import type { OnInit, WritableSignal } from "@angular/core";
import { inject, Component, computed, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DangerButtonComponent,
   IconComponent,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   TooltipDirective,
   manageDemo,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   DropdownItemComponent,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PartsSettingsService } from "src/app/parts/components/shared/services/settings/settings.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import type {
   ThresholdTaskCompleteOptions,
   ThresholdTaskType,
} from "src/app/parts/types/threshold-task/threshold-task.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { AlertPopup } from "src/app/shared/components/global/alertModal/alertPopup.modal.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "part-settings",
   templateUrl: "./partSettings.modal.component.html",
   styleUrls: ["./partSettings.modal.component.scss"],
   imports: [
      TooltipDirective,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      IconComponent,
      FormsModule,
      ModalFooterComponent,
      DangerButtonComponent,
      BetterDatePipe,
      PartUnitOfMeasurePipe,
      DropdownTextItemComponent,
      FormDropdownInputComponent,
      DropdownItemComponent,
   ],
})
export class PartSettings implements OnInit {
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageTask = inject(ManageTask);
   private readonly manageProfile = inject(ManageProfile);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);
   private readonly manageParts = inject(ManageParts);
   private readonly manageLocation = inject(ManageLocation);
   private readonly partSettingsService = inject(PartsSettingsService);

   public message;
   public title;
   public part;
   public locationID;
   public loadingBar;
   public resolve;
   public modalInstance;
   protected minThresholdTaskTypeString: WritableSignal<string> = signal("");
   protected minThresholdCompleteString: WritableSignal<string> = signal("");
   protected minThresholdDueDaysString: WritableSignal<string> = signal("");
   protected readonly dayOptions = this.partSettingsService.createDayOptions();
   protected minQuantityThreshold: number = 0;
   protected maxQuantityThreshold: number = 0;

   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.part = this.resolve.part;
      this.locationID = this.resolve.locationID;
      this.loadingBar = false;

      this.part.partOverstockedThreshold = Number(this.part.partOverstockedThreshold);
      this.part.partStaleThreshold = Number(this.part.partStaleThreshold);
      this.part.partMaxQtyThreshold = Number(this.part.partMaxQtyThreshold);

      this.minQuantityThreshold = this.part.partOverstockedThreshold;
      this.maxQuantityThreshold = this.part.partMaxQtyThreshold;

      this.setDisplayStrings();
   }

   close = (prop?) => {
      this.modalInstance.close(prop);
   };

   closeModal = () => {
      this.modalInstance.close();
   };

   setThresholdDisplayString = () => {
      if (this.part.userID > 0) {
         const user = this.manageUser.getFlatUsersIndex()[this.part.userID];
         if (user === undefined) {
            this.part.userFirstName = this.lang().DeletedUser;
            this.part.userLastName = "";
         } else {
            this.part.userFirstName = user.userFirstName;
            this.part.userLastName = user.userLastName;
         }
      } else if (this.part.profileID > 0) {
         const profile = this.manageUser.getProfilesIndex()[this.part.profileID];
         if (profile === undefined) {
            this.part.profileDescription = this.lang().DeletedUser;
         } else {
            this.part.profileDescription = profile.profileDescription;
         }
      }
   };

   updatePartStaleThreshold = () => {
      this.manageParts.updatePartStaleThreshold(this.part).then((answer) => {
         if (answer.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   updatePartOverstockedThreshold = async () => {
      const validThresholds = this.thresholdValuesAreValid();
      if (validThresholds) {
         const answer = await this.manageParts.updatePartOverstockedThreshold(this.part);
         if (answer.data.success === true) {
            this.minQuantityThreshold = this.part.partOverstockedThreshold;
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      } else {
         this.alertService.addAlert(
            this.lang().PartThresholdValidationError,
            "danger",
            6000,
         );
      }
   };

   updatePartMaxQtyThreshold = () => {
      const validThresholds = this.thresholdValuesAreValid();
      if (validThresholds) {
         this.manageParts.updatePartMaxQtyThreshold(this.part).then((answer) => {
            if (answer.data.success === true) {
               this.maxQuantityThreshold = this.part.partMaxQtyThreshold;
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      } else {
         this.alertService.addAlert(
            this.lang().PartThresholdValidationError,
            "danger",
            6000,
         );
      }
   };

   deletePart = (partToDelete) => {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.DeleteParts,
         )
      ) {
         this.alertService.addAlert(this.lang().cred80Fail, "danger", 10000);
         return;
      }

      if (manageDemo.demo) {
         const demoMessage = this.lang().DeletePartsWarningWhenLiveTestDrive;

         const instance = this.modalService.open(AlertPopup);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: demoMessage,
               title: this.lang().DeletePart,
            },
         };
      } else {
         const instance = this.modalService.open(Confirm);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().DeletePartMsg,
               title: this.lang().DeletePart,
            },
         };

         instance.result.then((result) => {
            if (result == 1) {
               this.manageParts.deletePart(partToDelete).then((answer) => {
                  partToDelete.partDeleted = 1; //mark this part as deleted
                  if (answer?.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                     this.close(0);

                     setTimeout(() => {
                        if ($(`#popPart${partToDelete.partID}`)[0]) {
                           $(`#popPart${partToDelete.partID}`)[0].click();
                        }
                     }, 350);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
            }
         });
      }
   };
   changePartOwner = (partToChange) => {
      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().ChangeThresholdTaskAssignmentMsg,
               title: this.lang().ChangeThresholdTaskAssignment,
               locationID: partToChange.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.part.userID,
               defaultProfile: this.part.profileID,
            },
         },
      };

      instance.result.then((data) => {
         if (data) {
            const confirmInstance = this.modalService.open(Confirm);

            this.paramsService.params = {
               modalInstance: confirmInstance,
               resolve: {
                  message: this.lang().BulkChangePartThresholdTaskAssignmentsMsg,
                  title: this.lang().BulkChangePartThresholdTaskAssignments,
               },
            };

            confirmInstance.result.then((result) => {
               let all;
               if (result == 1) {
                  all = 1;
               } else {
                  all = 0;
               }

               const promise = this.manageParts.updateThresholdAssignments(
                  this.part.partID,
                  data.userID,
                  data.profileID,
                  data.multiUsers,
                  all,
                  this.locationID,
               );

               promise.then(
                  (answer) => {
                     const userID = answer.data.userID;
                     const profileID = answer.data.profileID;
                     const profileDescription = answer.data.profileDescription;
                     this.part.userID = userID;
                     this.part.profileID = profileID;

                     if (all == 1) {
                        this.manageParts.getData(); //reload the entire app's data set.
                     }

                     if (profileID > 0) {
                        this.manageFilters.updateHiddenProfiles(
                           {
                              profileID: profileID,
                              name: profileDescription,
                              locationID: this.part.locationID,
                              multiUsers: data.multiUsers,
                           },
                           this.manageTask,
                           this.manageUser,
                           this.manageProfile,
                        );
                     }

                     this.setThresholdDisplayString();

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  },
                  () => {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  },
               );
            });
         }
      });
   };

   protected setDisplayStrings(): void {
      const location = this.manageLocation.getLocation(this.locationID);
      this.setThresholdDisplayString();

      if (location) {
         this.setMinThresholdTaskTypeString(
            this.part.minThresholdTaskType ?? location.minThresholdTaskTypeDefault,
         );

         this.setMinThresholdTaskCompleteString(
            this.part?.minThresholdTaskClose ?? location.minThresholdTaskCloseDefault,
         );

         this.setMinThresholdTaskDueDaysString(
            this.part?.minThresholdTaskDueDays ?? location.minThresholdTaskDueDaysDefault,
         );
      }
   }

   protected setMinThresholdTaskTypeString(value: ThresholdTaskType): void {
      const taskTypeString =
         value === "planned" ? this.lang().Planned : this.lang().Unplanned;
      this.minThresholdTaskTypeString.set(taskTypeString);
   }

   protected setMinThresholdTaskCompleteString(
      value: ThresholdTaskCompleteOptions,
   ): void {
      const taskCompleteString =
         value === "manual" ? this.lang().Manually : this.lang().Automatically;
      this.minThresholdCompleteString.set(taskCompleteString);
   }

   protected setMinThresholdTaskDueDaysString(value: number): void {
      const dueDaysString =
         this.partSettingsService.setMinThresholdTaskDueDaysString(value);
      this.minThresholdDueDaysString.set(dueDaysString);
   }

   protected async setMinThresholdTaskType(value: ThresholdTaskType) {
      const response = await this.manageParts.updateThresholdTaskType({
         partID: this.part.partID,
         minThresholdTaskType: value,
      });

      if (response.status !== 200) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.part.minThresholdTaskType = value;
      this.setMinThresholdTaskTypeString(value);
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async setMinThresholdTaskAutoComplete(value: ThresholdTaskCompleteOptions) {
      const response = await this.manageParts.updateMinThresholdTaskComplete({
         partID: this.part.partID,
         minThresholdTaskClose: value,
      });

      if (response.status !== 200) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.part.minThresholdTaskClose = value;
      this.setMinThresholdTaskCompleteString(value);
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async setMinThresholdDueDays(value: number) {
      const response = await this.manageParts.updatesetMinThresholdDueDays({
         partID: this.part.partID,
         minThresholdTaskDueDays: value,
      });

      if (response.status !== 200) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.part.minThresholdTaskDueDays = value;
      this.setMinThresholdTaskDueDaysString(value);
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected thresholdValuesAreValid(): boolean {
      // The settting for max qty is off so no need to validate
      if (this.part.partMaxQtyThreshold === -1) {
         return true;
      }

      if (this.part.partMaxQtyThreshold >= this.part.partOverstockedThreshold) {
         return true;
      }

      this.part.partOverstockedThreshold = this.minQuantityThreshold;
      this.part.partMaxQtyThreshold = this.maxQuantityThreshold;
      return false;
   }
}
