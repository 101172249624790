import type { OnInit } from "@angular/core";
import { inject, Component, forwardRef, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { column } from "src/app/shared/components/global/lists/column.types";
import { ViewListOfAnyObj } from "src/app/shared/components/global/lists/viewListOfAnyObj.element.component";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "view-list",
   templateUrl: "./viewList.modal.component.html",
   styleUrls: ["./viewList.element.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      forwardRef(() => ViewListOfAnyObj),
      LimbleHtmlDirective,
      IconComponent,
      TooltipDirective,
   ],
})
export class ViewListModal implements OnInit {
   public modalTitle = "";
   public objs: Array<{ fieldID: number }> | undefined; //objs can be assets, tasks, schedules, POs, or parts.
   public columns: Array<column> | undefined;
   public options;
   public currencySymbol;
   public resolve;
   public modalInstance;
   public assetFieldID = 0;
   public message: string = "";
   public messageTooltip: string = "";

   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.modalTitle = this.resolve.modalTitle;
      this.objs = this.resolve.objs;
      this.columns = this.resolve.columns;
      this.options = this.resolve.options;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.assetFieldID = this.resolve.assetFieldID ?? null;

      // The message and message tooltip are used to display text at the bottom of the list
      // for the user's benefit. They are optional.
      this.message = this.resolve.message;
      this.messageTooltip = this.resolve.messageTooltip;

      if (this.assetFieldID !== null) {
         assert(this.objs);
         this.setUpColumnValue(this.objs);
      }
   }
   private setUpColumnValue(objs: Array<any>): void {
      if (objs.length < 1) {
         return;
      }

      const assetField: AssetField = objs[0].fields.find(
         (field) => field.fieldID == this.assetFieldID,
      );
      assert(this.columns);
      const singleColumn = this.columns.find(
         (columnValue) => columnValue.assetFieldKey === true,
      );
      assert(singleColumn);
      singleColumn.key = "customField5";
      singleColumn.displayName = assetField.fieldName;
      singleColumn.sortBy = "customField5";
      for (const obj of objs) {
         const objAssetField = obj.fields.find(
            (field) => field.fieldID == this.assetFieldID,
         );
         obj.customField5Data = objAssetField;
         obj.customField5 = objAssetField.valueContent;
      }
   }

   close = () => {
      this.modalInstance.close(0);
   };
}
