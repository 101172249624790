import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   DatePickerInputComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   RowHoverButtonsComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import moment from "moment";
import type { Subscription } from "rxjs";
import { EditableImage } from "src/app/files/components/editableImage/editableImage.element.component";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import { ViewFile } from "src/app/files/components/viewFile/viewFile.element.component";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { EditPartField } from "src/app/parts/components/editPartFieldModal/editPartField.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { PartField } from "src/app/parts/types/field/field.types";
import type { PartFieldValueFile } from "src/app/parts/types/field/value/file/file.type";
import type { PartFieldValue } from "src/app/parts/types/field/value/part-field-value.types";
import type { Part } from "src/app/parts/types/part.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "field-item",
   templateUrl: "./fieldItem.element.component.html",
   styleUrls: ["../../fields/shared-field-styles.scss"],
   imports: [
      NgClass,
      FormsModule,
      ContenteditableDirective,
      LimbleHtmlDirective,
      DatePickerInputComponent,
      MinimalIconButtonComponent,
      TooltipDirective,
      FileUploader,
      IconComponent,
      RowHoverButtonsComponent,
      EditableImage,
      ViewFile,
      FormDropdownInputComponent,
      DropdownTextItemComponent,
      BetterCurrencyPipe,
      BetterDatePipe,
   ],
})
export class FieldItem implements OnInit, OnDestroy {
   @Input() public valueID: number | undefined;
   public fieldValue: PartFieldValue | undefined;
   @Input() public list;
   @Input() public restrict;
   @Input() public usedIn: "table" | "listItem" | undefined = undefined;
   public changeFieldsValues;
   public customerID;
   public currencySymbol;
   public deleted;
   public uploadObj;
   public oldValueContent;
   public dateReminderHint;
   public showDateHint;
   private readonly axios = axios;
   private dateReminderStreamSub: Subscription | undefined;
   public allFiles: Lookup<"fileID", PartFieldValueFile> | undefined;
   public fileGetURLs: Map<number, string> = new Map();
   public part: Part | undefined;
   public partFieldsSub: Subscription | null;
   public fieldInfo: PartField | undefined;
   public valueJSON: Array<string> = [];
   public fieldOptionsObs;
   public fieldOptionsSub;
   protected fieldLocked: boolean = false;
   protected isSuperUser: boolean = false;

   private readonly loadingBarService = inject(LoadingBarService);
   private readonly credService = inject(CredService);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly manageFiles = inject(ManageFiles);
   private readonly paramsService = inject(ParamsService);
   private readonly betterDate = inject(BetterDate);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.allFiles = this.manageParts.getFieldValueFiles();
      this.partFieldsSub = this.manageObservables.setSubscription("partFields", () => {
         if (!this.fieldValue) {
            return;
         }
         for (const fileID of this.fieldValue.partValueFileIDs) {
            this.setFileGetURLs(fileID);
         }
      });

      const currentUser = this.manageUser.getCurrentUser();
      this.isSuperUser = this.manageUtil.checkIfSuperUser(currentUser);
   }

   public ngOnInit() {
      if (this.valueID === undefined) {
         throw new Error(
            "fieldItem component requires a `valueID` input binding, but a value was not provided.",
         );
      }

      this.fieldValue = this.manageParts.getFieldValue(this.valueID);
      if (this.fieldValue === undefined) {
         throw new Error(
            "fieldItem component requires a `fieldValue`, but no fieldValue exists with the provided valueID.",
         );
      }

      this.fieldInfo = this.manageParts.getFields().get(this.fieldValue.fieldID);
      if (this.fieldInfo === undefined) {
         throw new Error(
            "fieldItem component requires a `fieldInfo`, but no field exists with the provided fieldID.",
         );
      }

      this.part = this.manageParts.getPart(this.fieldValue.partID);
      if (this.part === undefined) {
         throw new Error(
            "fieldItem component requires a `part`, but no part exists with the provided partID.",
         );
      }

      const currentUser = this.manageUser.getCurrentUser();
      this.fieldLocked = Boolean(
         this.fieldInfo.lockedDefault && !this.manageUtil.checkIfSuperUser(currentUser),
      );

      this.changeFieldsValues = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ChangePartInformationValues,
      );
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      this.deleted = this.part.partDeleted;

      this.oldValueContent = this.fieldValue.valueContent;

      //we need to watch for dateReminder changes
      if (this.fieldInfo.fieldTypeID == 2) {
         this.setDateReminderHint();

         const dateReminderStream$ = this.manageParts.dateReminderStreamMap.get(
            this.fieldValue.fieldID,
         );

         this.dateReminderStreamSub = dateReminderStream$?.subscribe(() => {
            this.setDateReminderHint();
         });
      }

      if (this.fieldInfo.fieldTypeID === 3 || this.fieldInfo.fieldTypeID === 4) {
         for (const fileID of this.fieldValue.partValueFileIDs) {
            this.setFileGetURLs(fileID);
         }

         this.setUploadObject(this.fieldValue, this.part);
      }

      if (this.fieldInfo.fieldTypeID == 7) {
         this.fieldOptionsObs = `partFieldOptionsJSON${this.fieldValue.fieldID}`;
         this.fieldOptionsSub = this.manageObservables.createAndSubscribe(
            this.fieldOptionsObs,
            () => {
               assert(this.fieldInfo);

               if (this.fieldInfo.optionsJSON) {
                  const parsedJSON = JSON.parse(this.fieldInfo.optionsJSON);
                  this.valueJSON = [];

                  parsedJSON.forEach((option) => {
                     this.valueJSON.push(option.name);
                  });
               }
            },
         );
      }
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.partFieldsSub);
      this.dateReminderStreamSub?.unsubscribe();
      this.manageObservables.unsubscribeAndDelete(
         this.fieldOptionsObs,
         this.fieldOptionsSub,
      );
   }

   //used only in the dropdown component
   setDropdown = (field, name, _search, object) => {
      //update valueContent and save the changes
      field.valueContent = name;
      this.setFieldValue(field, object);
   };

   clearFieldValue = (fieldValue, object) => {
      assert(fieldValue);
      fieldValue.valueContent = null;
      this.setFieldValue(fieldValue, object);
   };

   setFieldValue = (item, object) => {
      assert(this.fieldValue);
      assert(this.fieldInfo);
      assert(this.part);

      if (this.oldValueContent == this.fieldValue.valueContent) {
         return;
      }

      if (this.fieldLocked && !this.isSuperUser) {
         // The field is locked and they're not a super user so it can't be changed
         this.fieldValue.valueContent = this.oldValueContent;
         return;
      }

      // If it's a date, we need to make sure it's a valid date and not a string
      // An empty field is ok so that they can unset the field though
      if (this.fieldInfo.fieldTypeID == 2) {
         if (
            !(this.fieldValue.valueContent instanceof Date) &&
            this.fieldValue.valueContent !== null
         ) {
            this.fieldValue.valueContent = this.oldValueContent;
            return;
         }
      }

      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartInformationValues,
         )
      ) {
         this.alertService.addAlert(this.lang().cred170Fail, "danger", 10000);
         return;
      }

      this.manageParts.setFieldValue(item, object).then((answer) => {
         assert(this.fieldInfo);
         if (answer.data.success) {
            assert(this.fieldValue);
            this.oldValueContent = this.fieldValue.valueContent;
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else if (answer.data.error === "nonUniqueValue") {
            assert(this.fieldValue);
            this.fieldValue.valueContent = this.oldValueContent;
            this.alertService.addAlert(this.lang().valueUniqueError, "warning", 6000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }

         if (this.fieldInfo.fieldTypeID == 2) {
            this.setDateReminderHint();
         }
      });
   };

   setUploadObject = (fieldValue: PartFieldValue, part: Part) => {
      const field = this.manageParts.getField(fieldValue.fieldID);
      assert(field);
      this.uploadObj = {};
      this.uploadObj.primaryID = "fileID";
      if (field.fieldTypeID == 3) {
         this.uploadObj.uploadTypes = "images"; //images / documents / importFiles (excel + csv) or empty for default (images and documents)
      } else {
         this.uploadObj.uploadTypes = "documents";
      }

      this.uploadObj.viewOnly = false;
      this.uploadObj.noPreview = true;
      this.uploadObj.onUploadPopEditor = false; //boolean will images immediately open for editing after upload
      this.uploadObj.posturl = `phpscripts/managePart.php?action=makeFile&locationID=${part.locationID}&partID=${part.partID}&valueID=${fieldValue.valueID}`;
      this.uploadObj.uploadCall = async (posturl, formdata) => {
         return this.axios({
            url: posturl,
            method: "POST",
            data: formdata,
            transformRequest: function identity(itsMe) {
               return itsMe;
            },
         });
      };
      this.uploadObj.uploadComplete = (data: any) => {
         if (data.fileID != 0) {
            assert(this.part);
            assert(this.fieldValue);
            this.manageParts.getFieldValueFiles().set(data.fileID, data.row);
            this.setFileGetURLs(data.fileID);
            fieldValue.partValueFileIDs.push(data.fileID);
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         }
      };
      this.uploadObj.deleteSuccess = (answer) => {
         if (answer.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      };
   };

   viewImage = (file) => {
      const fileUrl = this.fileGetURLs.get(file.fileID);
      if (fileUrl === undefined) {
         console.error("File URL is undefined");
         return;
      }
      this.manageFiles.createImageViewer(fileUrl);
   };

   deleteFile = (file: PartFieldValueFile) => {
      const part = this.part;
      assert(part);

      if (
         !this.credService.isAuthorized(
            part.locationID,
            this.credService.Permissions.ChangePartInformationValues,
         )
      ) {
         this.alertService.addAlert(this.lang().cred170Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AreYouSureYouWouldLikeToDeleteThisFile,
            title: this.lang().DeleteFile,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            assert(this.fieldValue);
            this.manageParts
               .deleteFile(file.fileID, this.fieldValue, part.locationID)
               .then((answer) => {
                  if (answer?.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   setDateReminderHint = () => {
      assert(this.fieldValue);
      assert(this.fieldInfo);

      const fieldDate = this.fieldValue.valueContent;
      const today = moment();

      this.dateReminderHint = this.lang().DateReminderHint;

      const reminderDates = [
         ...new Set([
            this.fieldInfo.dateReminder1,
            this.fieldInfo.dateReminder2,
            this.fieldInfo.dateReminder3,
         ]),
      ]
         .filter((day) => day !== null)
         .map((days) => moment(fieldDate).subtract(days, "d"))
         .filter((date) => date.isAfter(today))
         .sort((dateA, dateB) => dateA.toDate().getTime() - dateB.toDate().getTime())
         .map((date) => this.betterDate.formatBetterDate(date.toDate(), "date"));

      if (reminderDates.length > 0) {
         this.dateReminderHint += ` ${reminderDates.join(", ")}`;
      } else {
         this.dateReminderHint = "No future reminders scheduled.";
      }

      // The tooltip title won't update unless we do something like this
      this.showDateHint = false;
      setTimeout(() => {
         this.showDateHint = true;
      }, 10);
   };

   //opens a doka instance -> no permissions here as if you are on the build tasks page you should have permission to edit the files.
   openEditor = async (file) => {
      const url = file.getURL ? file.getURL : this.fileGetURLs.get(file.fileID);

      const doka: any = await this.manageFiles.createImageEditor(url);
      //when the user clicks confirm this deletes the old version of the file, and uploads the edited file
      //has to convert doka's output to formdata for uploading and play with the fileName a little bit so the names
      //don't get longer each time the file is edited
      doka.onconfirm = async (output) => {
         assert(this.fieldValue);
         assert(this.part);
         this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
         await this.manageParts.deleteFile(
            file.fileID,
            this.fieldValue,
            this.part.locationID,
         );
         const formData = new FormData();
         formData.append("myfile", output.file, file.fileName.replace(/\d\d\d\d-/, ""));

         const response = await this.uploadObj.uploadCall(
            this.uploadObj.posturl,
            formData,
         );
         if (!response) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
         }
         this.loadingBarService.remove();
         this.uploadObj.uploadComplete(response.data);
      };
   };

   showFieldOptions = (field) => {
      assert(this.part);
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.CreateAndDeletePartFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred171Fail, "danger", 10000);
         return;
      }

      const instance: any = this.modalService.open(EditPartField);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            field: field,
         },
      };

      instance.result.then(() => {
         this.setDateReminderHint();
      });
   };

   public setFileGetURLs(fileID) {
      assert(this.part);
      const file = this.allFiles?.get(fileID);
      if (!file || !this.fieldValue) {
         return;
      }

      this.fileGetURLs.set(
         fileID,
         `viewFile.php?f=upload-${this.customerID}/parts/${this.part.locationID}/${this.part.partID}/${this.fieldValue.valueID}/${file.fileName}`,
      );
   }

   protected convertToString(value: string | Date | number | null): string {
      if (value === null || value === undefined) {
         return "";
      } else if (typeof value === "number" || value instanceof Date) {
         return value.toString();
      }
      return value;
   }
}
