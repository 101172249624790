<ng-container *translate="let t">
   <lim-ui-dropdown-button [label]="t(filter().labelKey)" filterDropdown>
      <div menu>
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(TaskTypeGroup.PlannedMaintenances)"
            [displayString]="t('PMs')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(TaskTypeGroup.UnplannedWOs)"
            [displayString]="t('UnplannedWOs')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(TaskTypeGroup.PlannedWOs)"
            [displayString]="t('PlannedWOs')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(TaskTypeGroup.WorkRequests)"
            [displayString]="t('WorkRequests')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(TaskTypeGroup.PartThresholds)"
            [displayString]="t('PartThreshold')"
         />
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-clear-filter-item (click)="handleClear()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
