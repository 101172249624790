import { NgClass } from "@angular/common";
import {
   Component,
   computed,
   inject,
   input,
   Input,
   type OnDestroy,
   type OnInit,
   signal,
   viewChild,
} from "@angular/core";
import {
   CheckboxComponent,
   IconComponent,
   LoadingAnimationComponent,
   LoadingBarService,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import {
   BehaviorSubject,
   combineLatest,
   filter,
   map,
   startWith,
   Subject,
   switchMap,
   tap,
} from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { DataViewerStateService } from "src/app/shared/data-viewer";
import { DataViewerPaginatorComponent } from "src/app/shared/data-viewer/data-viewer-paginator";
import { AlertService } from "src/app/shared/services/alert.service";
import type { RequestParam } from "src/app/shared/services/flannel-api-service";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { PickNewTemplateComponent } from "src/app/tasks/components/pickNewTemplateModal/pick-new-template.component";
import { TasksPanelComponentLegacy } from "src/app/tasks/components/shared/components/tasks-panel-legacy/tasks-panel-legacy.component";
import type {
   TaskTemplateColumnSortOptions,
   TaskTemplateEntity,
} from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { TaskTemplatesFacadeService } from "src/app/tasks/components/shared/services/task-templates-facade/task-templates-facade.service";
import { TaskEntityType } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TaskTemplateListItemComponent } from "src/app/tasks/components/task-template-list-item/task-template-list-item.component";
import { ViewListOfTasks } from "src/app/tasks/components/viewListOfTasksElement/viewListOfTasks.element.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { PmBuilderPanelComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel.component";
import { SuggestionsNotificationComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/suggestions-notification/suggestions-notification.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type AssetCreds = {
   viewManageAssets: boolean;
   editAssetSettings: boolean;
};

type taskColumnSortOptions =
   | "checklistName"
   | "assetName"
   | "scheduleSortNumber"
   | "displayName";

@Component({
   selector: "asset-preventative-maintenance-tab",
   templateUrl: "./asset-preventative-maintenance-tab.component.html",
   styleUrls: ["./asset-preventative-maintenance-tab.component.scss"],
   imports: [
      IconComponent,
      PanelComponent,
      PrimaryButtonComponent,
      CheckboxComponent,
      TooltipDirective,
      SortColumn,
      NgClass,
      ViewListOfTasks,
      LoadingAnimationComponent,
      TaskTemplateListItemComponent,
      DataViewerPaginatorComponent,
      TasksPanelComponentLegacy,
      PmBuilderPanelComponent,
      SuggestionsNotificationComponent,
   ],
   providers: [DataViewerStateService, TaskTemplatesFacadeService],
})
export class AssetPreventativeMaintenanceTabComponent implements OnInit, OnDestroy {
   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly credService = inject(CredService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   protected readonly dataViewerState = inject(DataViewerStateService);
   private readonly taskTemplatesApi = inject(TaskTemplatesApiService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly taskTemplatesFacadeService = inject(TaskTemplatesFacadeService);
   private readonly launchFlagsService = inject(LaunchFlagsService);

   assetID = input.required<number>();

   @Input() restrict: boolean = false;

   protected readonly suggestionsPanel = viewChild(PmBuilderPanelComponent);
   protected readonly suggestionsNotification = viewChild(
      SuggestionsNotificationComponent,
   );

   public asset: Asset | undefined;
   public creds: AssetCreds = {
      viewManageAssets: false,
      editAssetSettings: false,
   };
   public assetView: any;
   public currentUser;
   public locationID;
   public sortBindOpen;
   public showAssetName;
   public tasks: TaskLookup = new Lookup("checklistID");
   public noSearchResults;
   public showSpinner;
   public credAddPMTemplates;
   public showLoadingPM;
   public tasksWatchVarSub;
   public exportTasksCred;
   public filteredTasksOpenSingle;
   public openPMColumns;
   public filteredTasksOpenSingleIDs: Array<number> = [];
   public canExportCompletedTasks = false;
   completedTasksRequestFilters = signal([] as Array<RequestParam>);

   protected reachedScheduleLimit: boolean = false;

   private readonly sortSubject = new BehaviorSubject<TaskTemplateColumnSortOptions>(
      "checklistName",
   );
   protected readonly sort$ = this.sortSubject.asObservable();

   private readonly assetsSubject = new Subject<Array<number>>();
   protected readonly assets$ = this.assetsSubject.asObservable();

   protected templatesViewModel:
      | {
           templates: Array<TaskTemplateEntity> | undefined;
           total: number | undefined;
           loading: boolean;
        }
      | undefined;
   private templatesSub: Subscription | undefined;

   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly modalCloseEvents$ = this.modalService.closeEvents.pipe(
      filter(() => {
         return this.modalService.getActiveModal()?.componentInstance instanceof PopAsset;
      }),
   );

   private readonly isInitializedSubject = new Subject<void>();
   public readonly isInitialized$ = this.isInitializedSubject.asObservable();

   protected readonly templatesViewModel$ = combineLatest([
      this.dataViewerState.requestOptions$,
      this.assets$,
      this.sort$,
      this.modalCloseEvents$.pipe(startWith(0)),
      this.isInitialized$,
   ]).pipe(
      filter(([, assetIDs]) => assetIDs.length > 0),
      tap(() => {
         this.templatesViewModel = { templates: [], total: 0, loading: true };
      }),
      switchMap(([{ pagination }, , sort]) => {
         return this.taskTemplatesApi.getList({
            sort,
            pagination: pagination ?? {},
            params: {
               checklistTemplates: [1],
               columns: "assets,recurrences,schedules,assignment",
               excludeDeletedAssets: true,
               includeChildAssets: this.asset?.includeChildData === 1,
               assetIDs: this.assetID(),
            },
         });
      }),
      map((response) => ({ templates: response.data, total: response.total })),
   );

   protected readonly pmSuggestionsFlag = this.launchFlagsService.getFlag(
      "release-pm-suggestions",
      false,
   );

   public constructor() {
      this.assetView = {};
   }

   public ngOnInit() {
      this.initialize();
   }

   public async initialize() {
      this.reachedScheduleLimit =
         await this.taskTemplatesFacadeService.reachedCustomerScheduleLimit();

      this.templatesSub = this.templatesViewModel$.subscribe((data) => {
         this.templatesViewModel = { ...data, loading: false };
      });

      this.asset = this.manageAsset.getAsset(this.assetID());
      assert(this.asset);

      this.currentUser = this.manageUser.getCurrentUser();
      this.exportTasksCred = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ExportTasks,
      );

      this.creds.viewManageAssets = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ViewManageAssets,
      );

      this.creds.editAssetSettings = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.EditAssetSettings,
      );

      this.locationID = this.asset.locationID;
      this.sortBindOpen = "checklistDueDate";

      if (this.asset.includeChildData == 1) {
         this.showAssetName = true;
         this.assetsSubject.next(this.buildAssetIDs());
      } else {
         this.showAssetName = false;
         this.assetsSubject.next([this.asset.assetID]);
      }

      this.credAddPMTemplates = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.AddPMs,
      );

      this.tasksWatchVarSub = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.buildData();
         },
      );

      this.openPMColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 6,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 3,
         },
         {
            key: "assignedTo",
            displayName: this.lang().AssignedTo,
            sortBy: "assignment",
            columnWidth: 3,
            textAlign: "right",
         },
      ];

      // Initialize the completed work orders section
      this.completedTasksRequestFilters.set([
         {
            statuses: [2],
            locationIDs: [this.locationID],
            assetIDs: this.assetID(),
            taskTypes: [TaskEntityType.PlannedMaintenances],
         },
      ]);
      this.canExportCompletedTasks =
         !this.restrict &&
         this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.ExportCompletedTasks,
         );
      this.isInitializedSubject.next();
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([this.tasksWatchVarSub]);
      this.templatesSub?.unsubscribe();
   }

   public buildFilteredTasksOpenSingle(): void {
      let tempTasks = this.manageFilters.filterTasksByChecklistTemplateOld(this.tasks, 1);
      tempTasks = tempTasks.filter(
         (item) => item.checklistTemplate == 0 && item.checklistStatusID == 0,
      );
      this.filteredTasksOpenSingle = tempTasks;
      this.filteredTasksOpenSingleIDs = [];
      for (const task of tempTasks) {
         this.filteredTasksOpenSingleIDs.push(task.checklistID);
      }
   }

   public async updateIncludeChildData(): Promise<void> {
      assert(this.asset);

      const answer = await this.manageAsset.updateIncludeChildData(
         this.asset.assetID,
         this.asset.includeChildData,
      );

      if (answer.data.success == true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         this.showAssetName = !this.showAssetName;

         this.triggerTemplatesReload();
         this.completedTasksRequestFilters.set([
            {
               ...this.completedTasksRequestFilters()[0],
               includeChildAssets: this.asset.includeChildData === 1,
               assetIDs: this.assetID(),
            },
         ]);

         this.manageTask.incTasksWatchVar();
         this.manageTask.incCompletedTasksWatchVar();
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   public buildData(): void {
      assert(this.asset);
      this.assetView.woTile = this.manageFilters.assetTileOpenWorkOrders(
         this.manageTask.getTasks(),
         this.assetID(),
      );

      let tasks = this.manageTask.getTasks();

      if (this.asset.includeChildData == 1) {
         const assetIDs = this.buildAssetIDs();
         tasks = this.manageFilters.filterTasksByAssetIDs(tasks, assetIDs);
      } else {
         tasks = this.manageFilters.filterTasksByAssetIDs(tasks, [this.asset.assetID]);
      }

      this.tasks = tasks;
      this.buildFilteredTasksOpenSingle();
   }

   private buildAssetIDs(): Array<number> {
      assert(this.asset);
      const children = this.manageAsset.findChildrenIDs(this.asset, []);
      const tempAssetIDs: any = [];
      for (const child of children) {
         tempAssetIDs.push(child);
      }
      tempAssetIDs.push(this.asset.assetID);
      return tempAssetIDs;
   }

   protected bindChanged(newBind: taskColumnSortOptions): void {
      this.sortSubject.next(newBind as TaskTemplateColumnSortOptions);
   }

   protected async newTemplate(): Promise<void> {
      assert(this.asset);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.AddPMs,
         )
      ) {
         this.alertService.addAlert(this.lang().cred50Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PickNewTemplateComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().NewTemplateMsg,
            title: this.lang().NewTemplate,
            data: { type: "PM" },
         },
      };

      const data = await instance.result;
      if (!data) {
         return;
      }
      this.showSpinner = true;
      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      assert(this.asset);

      let answer;
      if (data.task) {
         answer = await this.manageTask.newTaskTemplateFromCopy(
            data.task.checklistID,
            this.asset.assetID,
            this.asset.locationID,
            data.newName,
         );
      } else {
         const type = "PM";
         answer = await this.manageTask.newTemplate(
            this.asset.assetID,
            data.newName,
            this.asset.locationID,
            type,
         );
      }

      if (answer.data.success == true) {
         this.alertService.addAlert(this.lang().NewTemplateSuccessMsg, "success", 1000);
         this.triggerTemplatesReload();
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
      this.loadingBarService.remove();
   }

   private triggerTemplatesReload() {
      assert(this.asset);
      if (this.asset.includeChildData) {
         this.assetsSubject.next(this.buildAssetIDs());
      } else {
         this.assetsSubject.next([this.asset.assetID]);
      }
   }

   public downloadExcel(tasks: TaskLookup): void {
      this.showLoadingPM = true;
      this.manageTask.downloadExcel(tasks).then(() => {
         this.showLoadingPM = false;
      });
   }

   protected onPageSizeChange(pageSize: number): void {
      this.dataViewerState.setPage(pageSize);
   }

   protected onPageChange(page: number): void {
      this.dataViewerState.setPage(page);
   }
}
