import { inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import moment from "moment";
import {
   BehaviorSubject,
   catchError,
   lastValueFrom,
   type Observable,
   of,
   switchMap,
   takeWhile,
} from "rxjs";
import { combineLatestAll, filter, finalize, map, take, tap, skip } from "rxjs/operators";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { RefreshApiService } from "src/app/tasks/services/refresh-api.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({ providedIn: "root" })
export class TaskRefreshService {
   private readonly TASK_LIMIT = 100000;

   private readonly manageUser = inject(ManageUser);
   private readonly refreshApiService = inject(RefreshApiService);
   private readonly tasksApi = inject(TasksApiService);

   private readonly dataInitializedState$ = new BehaviorSubject<boolean | null>(null);
   private readonly lastUpdated$ = new BehaviorSubject<string | null>(null);

   public constructor() {
      this.refreshApiService.isBackgroundFetchingCompletedTasks$
         .pipe(skip(1), takeUntilDestroyed())
         .subscribe((isFetching) => {
            if (!isFetching) {
               this.lastUpdated$.next(moment().format("LT"));
            }
         });
   }

   /**
    * Emits true if completed tasks are being fetched in the background.
    * Once fetched, cache will be updated.
    */
   public isBackgroundFetchingCompletedTasks(): Observable<boolean> {
      return this.refreshApiService.isBackgroundFetchingCompletedTasks$;
   }

   /**
    * Emits and completes as soon as all the data has been initialized.
    * The data usually comes from cache.
    * Note that this means it may emit and complete immediately.
    */
   public dataInitialized(): Observable<void> {
      return this.dataInitializedState$.pipe(
         filter((dataInitializedState) => dataInitializedState === true),
         take(1),
         map(() => undefined),
      );
   }

   /**
    * Emits the time when the data finishes updating (after background fetching). Emits the last value
    * immediately upon subscription, if it exists.
    */
   public lastUpdated(): Observable<string> {
      return this.lastUpdated$.pipe(
         filter((value): value is string => typeof value === "string"),
      );
   }

   /**
    * This is the main function that is called to retrieve and save the completed tasks into local storage
    * @param processTasks - function to process the tasks, this is where we update what is currently in `manageTasks`
    */
   public async refreshCompletedTasks(
      processTasks: (tasks: Array<any>) => void,
   ): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await lastValueFrom(
         this.getAllCompletedTasksPages().pipe(
            // Only continue if there are tasks
            filter(({ tasks }) => (tasks ?? []).length > 0),
            tap(({ tasks }) => {
               processTasks(tasks ?? []);
            }),
            map(({ tasks, isFetching }) => ({
               tasks,
               isFetching,
            })),
            takeWhile(({ isFetching }) => isFetching),
            tap(() => {
               logApiPerformance(
                  "completedTasks",
                  startTime,
                  this.manageUser.getCurrentUser(),
                  response,
               );
            }),
            catchError((error) => {
               console.error(`There was a problem fetching the completed tasks`, error);
               return of(false);
            }),
         ),
      );
   }

   private getAllCompletedTasksPages(): Observable<{
      tasks: Array<any>;
      isFetching: boolean;
   }> {
      return this.tasksApi.getCompletedTasksCount().pipe(
         switchMap((totalTasks) => {
            const totalPages = Math.ceil(totalTasks / this.TASK_LIMIT);

            // Create an array of page requests based on total pages
            const pageRequests = Array.from({ length: totalPages }, (pageIndex, index) =>
               this.getCompletedTasksPage(index + 1, index === totalPages - 1),
            );

            return pageRequests;
         }),
         combineLatestAll(),
         filter((requests) => {
            // Check if all requests have finished or at least one has errored out
            const allFinished = requests.every((request) => !request.isFetching);
            const allStarted = requests.every(
               (request) => request.isSuccess && request.isFetching,
            );
            const anyErrored = requests.some(
               (request) => !request.isFetching && !request.isSuccess,
            );

            return allFinished || allStarted || anyErrored;
         }),
         map((requests) => {
            // Aggregate tasks from all requests
            const tasks = requests.reduce(
               (acc: Array<any>, request) => acc.concat(request.data),
               [],
            );

            const isFetching = requests.some(
               (request) => request.isFetching && !request.isSuccess,
            );
            return { tasks, isFetching };
         }),
      );
   }

   /**
    * This is the function that is called to retrieve a page of completed tasks using Query and
    * map the response to the format we need.
    */
   private getCompletedTasksPage(
      page: number,
      isLastPage: boolean,
   ): Observable<{
      data: Array<any>;
      isSuccess: boolean;
      isFetching: boolean;
      last: boolean;
   }> {
      this.dataInitializedState$.next(false);
      return this.refreshApiService.getCompletedTasks(page, this.TASK_LIMIT).result$.pipe(
         map((queryResponse) => {
            const { data, isSuccess, isFetching } = queryResponse;
            const mappedResponse = {
               data: data ?? [],
               isSuccess,
               isFetching,
               last: isLastPage,
            };
            return mappedResponse;
         }),
         takeWhile((response) => {
            return response.isSuccess || response.isFetching;
         }),
         catchError((error) => {
            console.error("Error occurred: getCompletedTasksPage ->", error);
            return of({
               data: [],
               isFetching: false,
               isSuccess: false,
               last: isLastPage,
            });
         }),
         finalize(() => {
            this.dataInitializedState$.next(true);
         }),
      );
   }
}
