<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header
      title="{{ lang().WhoopsNoTimeHasBeenLogged }}"
      (closeModal)="dismiss()"
   />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="flex">
            <div panelCustomTitle>
               <span [limbleHtml]="lang().NoTimeHasBeenLogged"></span>
            </div>
            <div>{{ lang().AreYouSureYouWantToSendThisTaskForApproval }}</div>
            <lim-ui-secondary-button button icon="clock" (click)="logTime()">
               {{ lang().LogTime }}
            </lim-ui-secondary-button>
         </span>
      </lim-ui-info-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-outlined-button (click)="submit()">
         {{ lang().Yes }}
      </lim-ui-outlined-button>
      <lim-ui-primary-button (click)="close()">
         {{ lang().No }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
