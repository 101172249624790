import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   IconButtonComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   NumberInputWithButtonsComponent,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TimerDuration } from "src/app/tasks/timer/timer-duration";
import type { Task } from "src/app/tasks/types/task.types";
import { PickUserComponent } from "src/app/users/components/pick-user/pick-user.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "log-time-on-task",
   templateUrl: "./logTimeOnTask.modal.component.html",
   styleUrls: ["./logTimeOnTask.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      FormsModule,
      IconButtonComponent,
      TooltipDirective,
      ContenteditableDirective,
      FormDropdownInputComponent,
      DropdownTextItemComponent,
      BasicModalFooterComponent,
      BetterDatePipe,
      NumberInputWithButtonsComponent,
      NgClass,
   ],
})
export class LogTimeOnTask implements OnInit {
   public locationID;
   public showNotes;
   public notes: string = "";
   public customerID;
   public hours;
   public minutes;
   public task: Task | undefined;
   public categories;
   public selectedCategoryID;
   public userID;
   public loggedAt;
   public noteHidden;
   public message;
   public title;
   public data;
   public extraMsg;
   public extraMsgTooltip;
   public cred;
   public showMinutes;
   public buttonText;
   public viewingLengthHint;
   public resolve;
   public modalInstance;
   public selectedCategoryName;
   public userStr;
   public hoursError;
   public minutesError;
   public hoursErrorDowntime;
   public minutesErrorDowntime;
   public descriptionError;
   public laborCategoryError;
   public editTime;
   protected eyeSlashIcon: Aliases = "eyeSlashRegular";
   protected eyeIcon: Aliases = "eyeRegular";
   private timer: TimerDuration | undefined;

   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageBilling = inject(ManageBilling);
   private readonly paramsService = inject(ParamsService);
   private readonly manageProfile = inject(ManageProfile);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.data = this.resolve.data;

      this.locationID = this.data.locationID;
      this.extraMsg = this.data.extraMsg || false;
      this.extraMsgTooltip = this.data.extraMsgTooltip || false;
      this.showNotes = this.data.showNotes || false;
      this.timer = this.data.timer;

      //this cred will be used to govern if they can choose who/when
      this.cred = this.credService.isAuthorized(
         this.locationID,
         this.credService.Permissions.LogTimeForOtherUsers,
      );

      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.showMinutes = true;
      if (this.customerID == 1143) {
         //TO DO... build advanced setting to make it so show/hide minutes
         this.showMinutes = false;
      }

      this.buttonText = this.data.buttonText;

      this.viewingLengthHint = false;
      this.hours = 0;
      this.minutes = 0;

      const tempTask = this.manageTask.getTaskLocalLookup(this.data.checklistID);
      if (tempTask) {
         this.task = tempTask;
      }

      let categories = this.manageBilling.getCategories() || [];
      this.categories = [];
      categories = orderBy(categories, "order");
      categories.forEach((category) => {
         if (category.categoryName && category.categoryName.length > 0) {
            this.categories.push(category);
         }
      });

      //sets defaults (it's important to have even if there are no categories and they aren't using it)
      this.selectedCategoryID = 0;
      this.selectedCategoryName = this.lang().PickCategory;

      //rick boggs from cal state wanted this to default only for Work Requests.  We normally don't do this thing, but Bryan approved this single one
      if (
         this.customerID == 1804 &&
         categories.length > 0 &&
         this.task?.checklistBatchID == 300112
      ) {
         this.setCategory(categories[0]);
      }
      //end of ricks request

      this.editTime = true; //it always defaults to true.  We will only ever change it to false if the timer is being used AND they do not have the override task timer permission

      if (this.timer !== undefined) {
         this.hours = this.timer.hours;
         this.minutes = this.timer.minutes;
         this.viewingLengthHint = this.lang().WeNoticedYouHaveBeenViewingThisTaskFor;
         this.viewingLengthHint += ` ${this.timer.hours} ${this.lang().hrs}, ${this.timer.minutes} ${this.lang().mins}, ${this.timer.seconds} ${this.lang().secs}`;

         if (this.task) {
            this.editTime = this.credService.isAuthorized(
               this.task.locationID,
               this.credService.Permissions.OverrideAutomaticTaskTimer,
            );
         } else {
            this.editTime = false;
         }

         if (this.editTime == false && this.hours == 0 && this.minutes == 0) {
            this.minutes = 1; //sanity to make sure there is at least some time logged assuming they can't edit the time.  It would suck to not be able to close out a task because you can't change the time ;p
         }
      }

      this.userStr = `${this.manageUser.getCurrentUser().userInfo.fName} ${
         this.manageUser.getCurrentUser().userInfo.lName
      }`;

      this.userID = this.manageUser.getCurrentUser().gUserID;

      this.loggedAt = Date.now();

      const currentUser = this.manageUser.getCurrentUser();
      this.noteHidden = Number(currentUser.userInfo.noteHiddenFromExternalFlag);
   }

   setCategory = (category) => {
      this.selectedCategoryID = category.categoryID;
      this.selectedCategoryName = category.categoryName;
   };

   changeLoggedAt = () => {
      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ChangeLoggedTimesDateMsg,
            title: this.lang().ChangeLoggedTimesDate,
            buttonText: this.lang().Change,
            currentDate: this.loggedAt / 1000,
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: true,
            },
         },
      };

      instance.result.then((result) => {
         if (result.date) {
            const date = new Date(result.date);
            const newTime = date.getTime();
            this.loggedAt = newTime;
         }
      });
   };

   changeWho = () => {
      const profilesIndex = this.manageProfile.getProfilesIndex();
      const listOfUsersToPickFrom = this.manageUser.getUsersThatCanLogTimeAtLocation(
         this.locationID,
         profilesIndex,
      );

      const modalRef = this.modalService.open(PickUserComponent);
      modalRef.setInput("message", this.lang().ChangeWhoLoggedTimeOnThisTaskMsg);
      modalRef.setInput("title", this.lang().ChangeWhoLoggedTimeOnThisTask);
      modalRef.setInput("allUsers", listOfUsersToPickFrom);
      modalRef.setInput("defaultToDeselected", true);

      modalRef.result.then((users) => {
         if (users) {
            const user = users[0]; //select one is on so only use the first
            this.userStr = `${user.userFirstName} ${user.userLastName}`;
            this.userID = user.userID;
         }
      });
   };

   showHideNoteSetting = () => {
      if (this.noteHidden == 0) {
         this.noteHidden = 1;
      } else {
         this.noteHidden = 0;
      }
   };

   private isNumeric(num) {
      return !isNaN(parseFloat(num)) && isFinite(num);
   }

   close = () => {
      this.modalInstance.close();
   };

   public submit() {
      let errorMsg = "";
      let valid = true;
      this.hoursError = false;
      this.minutesError = false;
      this.hoursErrorDowntime = false;
      this.minutesErrorDowntime = false;
      this.descriptionError = false;
      this.laborCategoryError = false;

      if (this.minutes == 0 && this.hours == 0) {
         errorMsg += `${this.lang().PleaseEnterValidHowLong}<br />`;
         this.hoursError = true;
         this.minutesError = true;
         valid = false;
      }

      if (!this.isNumeric(this.hours)) {
         errorMsg += `${this.lang().PleaseEnterValidHours}<br />`;
         this.hoursError = true;
         valid = false;
      }

      if (!this.isNumeric(this.minutes)) {
         errorMsg += `${this.lang().PleaseEnterValidMinutes}<br />`;
         this.minutesError = true;
         valid = false;
      }

      if (this.isNegativeNumber(this.hours)) {
         errorMsg += `${this.lang().HoursCannotBeNegative}<br />`;
         this.hoursError = true;
         valid = false;
      }

      if (this.isNegativeNumber(this.minutes)) {
         errorMsg += `${this.lang().MinutesCannotBeNegative}<br />`;
         this.minutesError = true;
         valid = false;
      }

      if (this.categories.length > 0) {
         //only check this if there are actual categories
         if (this.selectedCategoryID == 0) {
            errorMsg += `${this.lang().PleasePickALaborCategory}<br />`;
            this.laborCategoryError = true;
            valid = false;
         }
      }

      if (this.showNotes) {
         if (
            this.manageUser.getCurrentUser().userInfo.customerRequireLaborDescription == 1
         ) {
            if (!this.notes || this.notes.length < 1) {
               errorMsg += `${this.lang().PleaseEnterADescription}<br />`;
               this.descriptionError = true;
               valid = false;
            }
         }
      }

      if (valid) {
         const submitData: any = {};
         submitData.hours = this.hours;
         submitData.minutes = this.minutes;
         submitData.userID = this.userID;
         submitData.loggedAt = this.loggedAt / 1000;
         submitData.notes = this.notes;
         submitData.categoryID = this.selectedCategoryID;
         submitData.noteHidden = this.noteHidden;

         this.modalInstance.close(submitData);
      }

      if (errorMsg !== "") {
         this.alertService.addAlert(errorMsg, "warning", 6000);
      }
   }

   private isNegativeNumber(num: number) {
      return num < 0;
   }

   protected resetErrors(errors: string[]): void {
      errors.forEach((error) => {
         this[error] = false;
      });
   }
}
