<div class="field-selection-container">
   @let fields = assetFieldsAtLocation();
   @if (fields === null) {
      <lim-ui-loading-animation />
   } @else if (fields.length > 0) {
      @for (field of fields; track field) {
         <div
            (click)="selectedField.set(field)"
            class="lim-ui-list-item lim-ui-selectable-list-item"
            [ngClass]="{
               'lim-ui-selected-list-item': field.fieldID === fieldToReturn()?.fieldID,
            }"
         >
            @if (field.fieldID === fieldToReturn()?.fieldID) {
               <lim-ui-icon icon="check" iconColor="success" />
            }
            <lim-ui-icon icon="hashtagRegular" />
            {{ field?.fieldName }}
         </div>
      }
   } @else {
      <div class="no-selected-fields-message">
         <b class="redColor">
            {{ i18n().t("WhoopsThereAreNoFieldsToPickFrom") }}
         </b>
         <span [limbleHtml]="i18n().t('PleaseAddAFieldThatIsANumberType')"></span>
      </div>
   }
</div>
