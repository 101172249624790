import type { OnChanges } from "@angular/core";
import { inject, Directive, ElementRef, Input } from "@angular/core";
import CountUp from "src/assets/js/countUp";

@Directive({
   selector: "[count-up]",
   standalone: true,
})
export class CountUpDirective implements OnChanges {
   @Input("count-up") public countUp: number | undefined;
   private elID;
   private numDecimals;
   private animationLength;
   private dataHasLoaded = false;
   private readonly el: ElementRef<HTMLElement> = inject(ElementRef);

   private loadData() {
      const element = this.el.nativeElement;
      this.elID = element.id;
      this.numDecimals = element.getAttribute("data-num-decimals") ?? 0;
      this.animationLength = element.getAttribute("data-animation-length") ?? 4;
      this.dataHasLoaded = true;
   }

   public ngOnChanges(changes) {
      if (!this.dataHasLoaded) {
         this.loadData();
      }
      if (changes.countUp?.previousValue !== changes.countUp?.currentValue) {
         this.runCountUp();
      }
   }

   private runCountUp() {
      return new CountUp(
         this.elID,
         0,
         this.countUp,
         this.numDecimals,
         this.animationLength,
      ).start();
   }
}
