import { NgClass, TitleCasePipe } from "@angular/common";
import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { inject, Component, Input, computed, input, output } from "@angular/core";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { TranslateService } from "src/app/languages";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PickBillingCategory } from "src/app/purchasing/bills/pickBillingCategoryModal/pickBillingCategory.modal.component";
import { CostViewerComponent } from "src/app/purchasing/currency/components/cost-viewer-component/cost-viewer-component";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { GatherNumber } from "src/app/shared/components/global/gatherNumberModal/gatherNumber.modal.component";
import { GatherText } from "src/app/shared/components/global/gatherTextModal/gatherText.modal.component";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { TimeLoggedRecord } from "src/app/shared/types/general.types";
import { ChangeCompletedTime } from "src/app/tasks/components/changeCompletedTimeModal/changeCompletedTime.modal.component";
import { TimeCreatedByNamePipe } from "src/app/tasks/components/extraTimeElement/timeCreatedByName.pipe";
import type { TaskExtraTime } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ExtraTimeDisplayModes } from "src/app/tasks/types/extra-time/extra-time.enum";
import { PickUserComponent } from "src/app/users/components/pick-user/pick-user.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

type GroupedTimeLogged = Array<{
   userID: number;
   totalPromptTimeHours: number;
   totalPromptTimeMinutes: number;
   userExtraTime: Array<TimeLoggedRecord>;
}>;

type ExtraTimeListViewModel = {
   extraTime: Array<TaskExtraTime>;
   checklistPromptTime?: number | undefined;
   checklistCompletedDate?: number | undefined;
   billableTime?: number | undefined;
   locationID: number;
   createdByUserID?: number | undefined;
   checklistID: number;
   checklistUserCompleted?: number | undefined;
   categoryID?: number | undefined;
   extraTimeNotes?: string | undefined;
   checklistUserWage?: number | undefined;
   billableRate?: number | undefined;
};

@Component({
   selector: "extra-time-list",
   templateUrl: "./extraTimeList.component.html",
   styleUrls: ["./extraTimeList.component.scss"],
   imports: [
      UserImage,
      IconComponent,
      NgClass,
      LimbleHtmlDirective,
      TooltipDirective,
      TitleCasePipe,
      BetterDatePipe,
      TimeCreatedByNamePipe,
      CostViewerComponent,
   ],
})
export class ExtraTimeListComponent implements OnChanges, OnInit, OnDestroy {
   public readonly extraTimeViewModel = input.required<ExtraTimeListViewModel>();
   public readonly checklistID = input<number | undefined>();

   @Input() public disabled: boolean = true;
   @Input() displayMode: ExtraTimeDisplayModes = ExtraTimeDisplayModes.ByUser;

   public readonly extraTimeUpdated = output();

   private readonly allTimeLogged = computed(() => {
      const task = this.extraTimeViewModel();
      if (!task) return [];

      const extraTimes = task.extraTime;
      const allTimeLogged: Array<TimeLoggedRecord> =
         extraTimes?.map((extraTime) => this.buildExtraTimeData(extraTime)) ?? [];

      // For some reason when a task is completed, we remove the most recent entry from tbl_checklists_extraTime
      // we then add that info to the task itself in tbl_checklists_$customerID
      // so if the task is completed we need to get that info from the task itself and aggregate it with the extra time to get an accurate list of all time logged on a task
      // this wasn't a problem before the UI Recode because we weren't grouping the time logged by user or sharing a single component on both open and completed tasks
      // To avoid this, it would require some changes to our data that we aren't wanting to make right now for time purposes.
      if (task.checklistCompletedDate) {
         const completedTimeObj = this.getCompletedTaskTimeLogged();
         if (completedTimeObj === undefined) return [];
         // If the checklistId has already been added to the list, don't add them again.
         const indexOfCompletedTaskData = allTimeLogged.findIndex(
            (timeLoggedRecord) => timeLoggedRecord.fromChecklistTime,
         );
         if (indexOfCompletedTaskData === -1) {
            allTimeLogged.push(completedTimeObj);
         } else {
            allTimeLogged[indexOfCompletedTaskData] = completedTimeObj;
         }
      }
      return allTimeLogged;
   });

   protected readonly groupedLoggedTime = computed(() => {
      return this.calcTotalTime(this.groupBy(this.allTimeLogged(), this.displayMode));
   });

   public readonly extraTimeDisplayModes = { ...ExtraTimeDisplayModes };
   public customerID;
   public categoriesIndex;
   public userID;
   protected canEditAnyTimeLog: boolean = false;
   protected viewLaborCostsCredential: boolean = false;
   protected canEditMyOwnTimeLog: boolean = false;
   protected reassignAnyLoggedTimeToAnyone: boolean = false;
   protected reassignOnlyMyOwnLoggedTimeToAnyone: boolean = false;
   protected editAllAspectsOfAnyonesLoggedTime: boolean = false;
   protected editAllAspectsOfOnlyMyOwnLoggedTime: boolean = false;
   public showUserExtraTime = {};
   public taskSub;
   public currencySymbol;
   public checklistPromptTimeHours;
   public checklistPromptTimeMinutes;
   public billableHours;
   public billableMinutes;

   private readonly modalService = inject(ModalService);
   private readonly credService = inject(CredService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUser = inject(ManageUser);
   private readonly manageBilling = inject(ManageBilling);
   private readonly alertService = inject(AlertService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageLang = inject(ManageLang);
   private readonly t = inject(TranslateService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly isLastTimeEntryForCompletedTask = computed(() => {
      const task = this.extraTimeViewModel();
      if (task?.checklistCompletedDate && this.allTimeLogged().length === 1) {
         return true;
      }
      return false;
   });

   protected readonly deleteLoggedTimeTooltip = computed(() => {
      if (this.isLastTimeEntryForCompletedTask()) {
         return this.t.instant("CannotDeleteLastTimeLoggedMessage");
      }
      return this.t.instant("DeleteThisLoggedTime");
   });

   public ngOnChanges(changes: SimpleChanges): void {
      if (changes.displayMode?.currentValue !== changes.displayMode?.previousValue) {
         this.extraTimeUpdated.emit();
      }
   }

   public ngOnInit() {
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.categoriesIndex = this.manageBilling.getCategoriesIndex();

      this.userID = this.manageUser.getCurrentUser().gUserID;
      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }
      this.setExtraTimePermissions();

      this.taskSub = this.manageObservables.setSubscription("task", () => {
         this.extraTimeUpdated.emit();
      });

      // Group the tasks and calculate the total time based on the grouping criteria
      this.extraTimeUpdated.emit();

      const task = this.extraTimeViewModel();
      if (!task) {
         return;
      }
      this.checklistPromptTimeHours = Math.floor(Number(task.checklistPromptTime) / 3600);

      this.checklistPromptTimeMinutes = Math.floor(
         (Number(task.checklistPromptTime) / 60) % 60,
      );

      if (Number(task.billableTime) > 0) {
         this.billableHours = Math.floor(Number(task.billableTime) / 3600);

         this.billableMinutes = Math.floor((Number(task.billableTime) / 60) % 60);
      }
   }

   public getTotalTime(time: GroupedTimeLogged): { hours: number; minutes: number } {
      // Calculate total time in minutes
      const totalMinutes = time.reduce((carry, item) => {
         // Convert hours to minutes and add minutes
         return carry + item.totalPromptTimeHours * 60 + item.totalPromptTimeMinutes;
      }, 0);

      // Convert total minutes to hours and minutes
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return { hours, minutes };
   }

   private setExtraTimePermissions(): void {
      const task = this.extraTimeViewModel();
      if (!task) return;
      const editCompletedTasksCredential = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.EditCompletedTasks,
      );
      const editAndCompleteAnyOpenTasksCredential = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.EditAndCompleteOpenTasks,
      );

      this.canEditAnyTimeLog =
         editAndCompleteAnyOpenTasksCredential ||
         (editCompletedTasksCredential && Number(task.checklistCompletedDate) > 0);

      this.canEditMyOwnTimeLog = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.EditAndCompleteMyOpenTasks,
      );

      const canLogTimeForOtherUsers = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.LogTimeForOtherUsers,
      );

      const canOverRideTaskTimer = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.OverrideAutomaticTaskTimer,
      );

      this.viewLaborCostsCredential = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.ViewLaborCosts,
      );

      this.reassignAnyLoggedTimeToAnyone =
         this.canEditAnyTimeLog && canLogTimeForOtherUsers;
      this.reassignOnlyMyOwnLoggedTimeToAnyone =
         this.canEditMyOwnTimeLog && canLogTimeForOtherUsers;

      this.editAllAspectsOfAnyonesLoggedTime =
         this.canEditAnyTimeLog && canOverRideTaskTimer;
      this.editAllAspectsOfOnlyMyOwnLoggedTime =
         this.canEditMyOwnTimeLog && canOverRideTaskTimer;
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([this.taskSub]);
   }

   public getUserByUserID(userID): any {
      return this.manageUser.getUser(userID);
   }

   private groupBy(data, key: ExtraTimeDisplayModes): any {
      return data.reduce((storage, item, index) => {
         const group = item[key] ?? index;
         storage[group] = storage[group] || [];
         storage[group].push(item);
         return storage;
      }, {});
   }

   private calcTotalTime(
      groupedLoggedTime: Record<number, [TimeLoggedRecord]>,
   ): GroupedTimeLogged {
      const task = this.extraTimeViewModel();
      const result: GroupedTimeLogged = [];
      let totalPromptTime = 0;

      Object.values(groupedLoggedTime).forEach((element: any) => {
         element.forEach((timeLogged) => {
            totalPromptTime += timeLogged.promptTime ?? 0;

            /** Here is the order of what createdBy userID is to be displayed:
             * 1) Check extra time record for createdByUserID (these are the values saved in DB, but not saved on original task completion record)
             * 2) Check the createdByUserID of actual task record and not the log time record (this is used when completing the task)
             */
            const userIDToShowInCreatedBy =
               timeLogged.createdByUserID ?? task?.createdByUserID;
            timeLogged.createdByUserFullName = this.manageUser.getUserFullName(
               userIDToShowInCreatedBy,
            );
         });

         const totalPromptTimeHours = Math.floor(totalPromptTime / 3600);
         const totalPromptTimeMinutes = Math.floor((totalPromptTime % 3600) / 60);

         const newValue = {
            userID: element[0].userID ?? 0,
            totalPromptTimeHours: totalPromptTimeHours,
            totalPromptTimeMinutes: totalPromptTimeMinutes,
            userExtraTime: element,
         };

         // Reset the values for the new iteration.
         totalPromptTime = 0;
         result.push(newValue);
         if (!element[0].userID) return;
         this.showUserExtraTime[element[0].userID] = false;
      });

      return result;
   }

   private getCompletedTaskTimeLogged(): TimeLoggedRecord | undefined {
      const task = this.extraTimeViewModel();
      if (!task) return undefined;
      const { userFirstName, userLastName } =
         this.manageUser.getUser(task.checklistUserCompleted) ?? {};

      return {
         userID: task.checklistUserCompleted ?? null,
         promptTime: task.checklistPromptTime ?? null,
         categoryID: task.categoryID ?? null,
         checklistID: task.checklistID,
         loggedAt: task.checklistCompletedDate ?? null,
         notes: task.extraTimeNotes ?? null,
         promptTimeHours: Math.floor((task.checklistPromptTime ?? 0) / 3600),
         promptTimeMinutes: Math.floor(((task.checklistPromptTime ?? 0) % 3600) / 60),
         userFirstName: userFirstName ?? "",
         userLastName: userLastName ?? "",
         userWage: task.checklistUserWage ?? null,
         billableHours: Math.floor((task.billableTime ?? 0) / 3600),
         billableMinutes: Math.floor(((task.billableTime ?? 0) % 3600) / 60),
         billableRate: task.billableRate ?? null,
         billableTime: task.billableTime ?? null,
         fromChecklistTime: true,
      };
   }

   /*
    * NOTE FOR EXTRA TIME
    * The way we are dealing with extra time is a bit complicated.
    * We have a table called tbl_checklists_extraTime that stores all the extra time for a task.
    * However, if a task has been completed, we remove the most recent entry from tbl_checklists_extraTime and add that info to the task itself in tbl_checklists_$customerID.
    * For example, if the extra time has been logged 3 times: 1 hour, 2 hour, 3 hour.
    * When the task is open, we display the 3 entries in tbl_checklists_extraTime.
    * When the task is completed, we display the 2 entries in tbl_checklists_extraTime and the 3rd entry in tbl_checklists_$customerID.
    */

   /*
    * This function is used to update the completed date.
    */
   protected updateCompletedDate(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }

      if (record.fromChecklistTime) {
         this.changeCompletedTaskCompletedDate(record);
      } else {
         this.changeCompletedTaskExtraTimeDate(record);
      }
   }

   /*
    * This function is used to update the time spent or billable time.
    * @param record - The record that is being updated.
    * @param type - The type of time being updated. Either prompt or billable.
    */
   protected updateSpentTime(record: any, type: string): void {
      if (this.disabled) {
         return;
      }

      if (type == "prompt") {
         this.updatePromptTime(record);
      } else if (type == "billable") {
         this.updateSpentBillableTime(record);
      }
   }

   /*
    * This function is used to update the completed user.
    * @param record - The record that is being updated.
    * @param type - The type of time being updated. Either prompt or billable.
    */
   protected updateCompletedUser(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }

      if (record.fromChecklistTime) {
         this.updateChecklistCompletedUser();
      } else {
         this.updateExtraTimeUser(record);
      }
   }

   /*
    * This function is used to update the billable category.
    * The fromCheckListTime attribute in record is used to determine if the time is coming from the task itself or from the extra time table.
    * @param record - The record that is being updated.
    */
   protected updateBillableCategory(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }

      if (record.fromChecklistTime) {
         this.changeTaskBillableCategory();
      } else {
         this.changeExtraTimeBillableCategory(record);
      }
   }

   /*
    * This function is used to update the billable rate.
    * The fromCheckListTime attribute in record is used to determine if the time is coming from the task itself or from the extra time table.
    * @param record - The record that is being updated.
    */
   protected updateBillableRate(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }

      if (record.fromChecklistTime) {
         this.changeTaskBillableRate();
      } else {
         this.changeExtraTimeBillableRate(record);
      }
   }

   /*
    * This function is used to update the note.
    * The fromCheckListTime attribute in record is used to determine if the time is coming from the task itself or from the extra time table.
    * @param record - The record that is being updated.
    */
   protected updateNotes(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }

      if (record.fromChecklistTime) {
         this.changeTaskExtraTimeNotes();
      } else {
         this.changeExtraTimeNotes(record);
      }
   }

   /*
    * This function is used to update the prompt time.
    * The fromCheckListTime attribute in record is used to determine if the time is coming from the task itself or from the extra time table.
    * @param record - The record that is being updated.
    */
   protected updatePromptTime(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }

      if (record.fromChecklistTime) {
         this.updateChecklistPromptTime();
      } else {
         this.updateExtraTimePromptTime(record);
      }
   }

   /*
    * This function is used to update the billable time.
    * The fromCheckListTime attribute in record is used to determine if the time is coming from the task itself or from the extra time table.
    * @param record - The record that is being updated.
    */
   protected updateSpentBillableTime(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }

      if (record.fromChecklistTime) {
         this.changeTaskBillableTime(record);
      } else {
         this.changeExtraTimeBillableTime(record);
      }
   }

   /*
    * This helper function is used to update the completed time on the task itself.
    */
   private updateChecklistPromptTime(): void {
      const task = this.extraTimeViewModel();
      if (this.disabled || !task) {
         return;
      }

      const instance = this.modalService.open(ChangeCompletedTime);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().ChangeCompletionTime,
            data: {
               startMin: this.checklistPromptTimeMinutes,
               startHour: this.checklistPromptTimeHours,
               allowZero: false,
            },
         },
      };

      instance.result.then((result) => {
         if (result && this.extraTimeViewModel()) {
            const newTime = result.hours * 60 * 60 + result.minutes * 60;

            this.manageTask
               .changeCompletedTime(task.checklistID, newTime)
               .then((answer) => {
                  if (
                     answer.data.success !== true ||
                     this.extraTimeViewModel() === undefined
                  ) {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     return;
                  }

                  this.extraTimeUpdated.emit();
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               });
         }
      });
   }

   /*
    * This helper function is used to update the completed date time on the task itself.
    */
   private changeCompletedTaskCompletedDate(record: TimeLoggedRecord): void {
      const task = this.extraTimeViewModel();
      if (!task) return;
      const instance = this.modalService.open(PickDate);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().changeCompletedTaskCompletedDateMsg,
            title: this.lang().changeCompletedTaskCompletedDateTitle,
            buttonText: this.lang().Change,
            currentDate: task?.checklistCompletedDate,
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: true,
               setTimeFromCurrentDate: true,
            },
         },
      };
      instance.result.then((result) => {
         if (result?.date && task?.checklistCompletedDate) {
            const date = new Date(result.date);
            const newTime = date.getTime() / 1000;
            const oldTime = task?.checklistCompletedDate;
            const oldTimeCheck = new Date(task?.checklistCompletedDate).getTime() / 1000;
            if (
               result.timeOfDay !== false ||
               (oldTimeCheck !== newTime && newTime !== 0)
            ) {
               this.manageTask
                  .updateCompletedDate(oldTime, newTime, task.checklistID)
                  .then((answer) => {
                     record.loggedAt = result.date / 1000;
                     if (answer.data.success == true) {
                        this.extraTimeUpdated.emit();
                        this.alertService.addAlert(
                           this.lang().successMsgCompletedDateChange,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            }
         }
      });
   }

   /*
    * This helper function is used to update the completed date time on the extra time table.
    */
   private changeCompletedTaskExtraTimeDate(record: TimeLoggedRecord): void {
      const task = this.extraTimeViewModel();
      if (!task) return;
      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().changeCompletedTaskExtraTimeDateMsg,
            title: this.lang().changeCompletedTaskExtraTimeDateTitle,
            buttonText: this.lang().Change,
            currentDate: record.loggedAt,
            data: {
               viewTimeOfDay: true,
               setTimeFromCurrentDate: true,
               setNoTimeOfDayOffset: true,
            },
         },
      };

      instance.result.then((result) => {
         if (result?.date && task && record.extraTimeID) {
            const date = new Date(result.date);
            const newTime = date.getTime() / 1000;

            this.manageTask
               .updateExtraTimeDate(
                  newTime,
                  record.extraTimeID,
                  task.checklistID,
                  result.timeOfDay,
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     record.loggedAt = result.date / 1000;
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the completed time on the extra time table.
    */
   private updateExtraTimePromptTime(record: TimeLoggedRecord): void {
      const task = this.extraTimeViewModel();
      if (!task) return;
      if (this.disabled) {
         return;
      }
      const instance = this.modalService.open(ChangeCompletedTime);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ChangeLoggedTimesDateMsg,
            title: this.lang().ChangeLoggedTimesDate,
            data: {
               startMin: record.promptTimeMinutes,
               startHour: record.promptTimeHours,
               allowZero: false,
            },
         },
      };

      instance.result.then((result) => {
         if (result && task && record.extraTimeID) {
            const newTime = result.hours * 60 * 60 + result.minutes * 60;

            this.manageTask
               .changeCompletedTimeExtraTime(
                  record.extraTimeID,
                  newTime,
                  task.checklistID,
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the billable time on the task itself.
    */
   private changeTaskBillableTime(record: TimeLoggedRecord): void {
      const task = this.extraTimeViewModel();
      if (!task) return;
      const instance = this.modalService.open(ChangeCompletedTime);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().UpdateBillableHours,
            data: {
               startMin: this.billableMinutes,
               startHour: this.billableHours,
               allowZero: false,
            },
         },
      };

      instance.result.then((result) => {
         if (result && task) {
            const newTime = result.hours * 60 * 60 + result.minutes * 60;
            if (task.billableTime !== newTime) {
               this.manageTask
                  .changeTaskBillableTime(task.checklistID, newTime)
                  .then((answer: any) => {
                     if (answer.data.success == true) {
                        this.extraTimeUpdated.emit();
                        record.billableHours = Math.floor(
                           Number(task?.billableTime) / 3600,
                        );

                        record.billableMinutes = Math.floor(
                           (Number(task?.billableTime) / 60) % 60,
                        );
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            }
         }
      });
   }

   /*
    * This helper function is used to update the billable time on the extra time table.
    */
   private changeExtraTimeBillableTime(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }
      const instance = this.modalService.open(ChangeCompletedTime);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().UpdateBillableHours,
            data: {
               startMin: record.billableMinutes,
               startHour: record.billableHours,
               allowZero: false,
            },
         },
      };

      instance.result.then((result) => {
         if (result && record.extraTimeID) {
            const newTime = result.hours * 60 * 60 + result.minutes * 60;
            if (newTime !== record.billableTime) {
               this.manageTask
                  .changeExtraTimeBillableTime(record.extraTimeID, newTime)
                  .then((answer: any) => {
                     if (answer.data.success == true && record.extraTimeID) {
                        this.extraTimeUpdated.emit();

                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            }
         }
      });
   }

   /*
    * This helper function is used to update the billable category on the task itself.
    */
   private changeTaskBillableCategory(): void {
      const task = this.extraTimeViewModel();
      if (!task) return;
      const instance = this.modalService.open(PickBillingCategory);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               message: false,
               title: this.lang().PickCategory,
            },
         },
      };

      instance.result.then((categoryID) => {
         if (categoryID > 0 && task) {
            this.manageTask
               .changeTaskBillableCategory(task.checklistID, categoryID)
               .then((answer: any) => {
                  if (answer.data.success == true) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the billable category on the extra time table.
    */
   private changeExtraTimeBillableCategory(record: TimeLoggedRecord): void {
      if (this.disabled) {
         return;
      }
      const instance = this.modalService.open(PickBillingCategory);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               message: false,
               title: this.lang().PickCategory,
            },
         },
      };

      instance.result.then((categoryID) => {
         if (categoryID > 0 && record.extraTimeID) {
            this.manageTask
               .changeExtraTimeBillableCategory(record.extraTimeID, categoryID)
               .then((answer: any) => {
                  if (answer.data.success == true && record.extraTimeID) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the billable rate on the task itself.
    */
   private changeTaskBillableRate(): void {
      const task = this.extraTimeViewModel();
      if (task === undefined) return;

      const title = this.lang().UpdateBillableRate;
      const message = false;
      const message2 = false;
      const warning = "";

      const instance = this.modalService.open(GatherNumber);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: message,
            message2: message2,
            title: title,
            warning: warning,
            data: {
               currentNumber: Number(task.billableRate),
            },
         },
      };

      instance.result.then((billableRate) => {
         if (billableRate && task) {
            this.manageTask
               .changeTaskBillableRate(task.checklistID, billableRate)
               .then((answer: any) => {
                  if (answer.data.success == true) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the billable rate on the extra time table.
    */
   private changeExtraTimeBillableRate(record: TimeLoggedRecord): void {
      const title = this.lang().UpdateBillableRate;
      const message = false;
      const message2 = false;
      const warning = "";

      const instance = this.modalService.open(GatherNumber);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: message,
            message2: message2,
            title: title,
            warning: warning,
            data: {
               currentNumber: Number(record.billableRate),
            },
         },
      };

      instance.result.then((billableRate) => {
         if (billableRate && record.extraTimeID) {
            this.manageTask
               .changeExtraTimeBillableRate(record.extraTimeID, billableRate)
               .then((answer: any) => {
                  if (answer.data.success == true && record.extraTimeID) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the note on the task itself.
    */
   private changeTaskExtraTimeNotes(): void {
      const task = this.extraTimeViewModel();
      if (task === undefined) return;
      const instance = this.modalService.open(GatherText);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: false,
            message2: false,
            title: this.lang().UpdateLaborDescription,
            warning: false,
            data: {
               currentText: task.extraTimeNotes,
               singleLine: false,
               buttonText: false,
            },
         },
      };

      instance.result.then((text) => {
         if (text && task !== undefined) {
            this.manageTask
               .changeTaskExtraTimeNotes(task.checklistID, text)
               .then((answer: any) => {
                  if (answer.data.success == true) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the note on the extra time table.
    */
   private changeExtraTimeNotes(record: TimeLoggedRecord): void {
      const instance = this.modalService.open(GatherText);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: false,
            message2: false,
            title: this.lang().UpdateLaborDescription,
            warning: false,
            data: {
               currentText: record.notes,
               singleLine: false,
               buttonText: false,
            },
         },
      };

      instance.result.then((notes) => {
         if (notes && record.extraTimeID) {
            this.manageTask
               .changeExtraTimeNotes(record.extraTimeID, notes)
               .then((answer: any) => {
                  if (answer.data.success == true && record.extraTimeID) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the completed user on the task itself.
    */
   private updateChecklistCompletedUser(): void {
      const task = this.extraTimeViewModel();
      if (this.disabled || task === undefined) {
         return;
      }

      const result = this.manageUser.getUsersAndProfilesAtLocation(
         task.locationID,
      ) as any;
      const modalRef = this.modalService.open(PickUserComponent);
      modalRef.setInput("message", this.lang().ChangeWhoCompletedThisTaskMsg);
      modalRef.setInput("title", this.lang().ChangeWhoCompletedThisTask);
      modalRef.setInput("allUsers", result.data.users);

      modalRef.result.then((users) => {
         if (users && task) {
            const user = users[0]; //select one is on so only use the first
            this.manageTask
               .changeCompletedUser(user.userID, task.checklistID)
               .then((answer) => {
                  if (answer.data.success === true) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   /*
    * This helper function is used to update the completed user on the extra time table.
    */
   private updateExtraTimeUser(record: any): void {
      const task = this.extraTimeViewModel();
      if (this.disabled || task === undefined) {
         return;
      }

      const profilesIndex = this.manageProfile.getProfilesIndex();

      const listOfUsersToPickFrom = this.manageUser.getUsersThatCanLogTimeAtLocation(
         task.locationID,
         profilesIndex,
      );

      const modalRef = this.modalService.open(PickUserComponent);
      modalRef.setInput("message", this.lang().ChangeWhoLoggedTimeOnThisTaskMsg);
      modalRef.setInput("title", this.lang().ChangeWhoLoggedTimeOnThisTask);
      modalRef.setInput("allUsers", listOfUsersToPickFrom);

      modalRef.result.then((users) => {
         if (users && task) {
            const user = users[0]; //select one is on so only use the first
            this.manageTask
               .changeCompletedUserExtraTime(
                  user.userID,
                  record.extraTimeID,
                  task.checklistID,
               )
               .then((answer) => {
                  if (answer.data.success === true) {
                     this.extraTimeUpdated.emit();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   protected deleteExtraTime(record: TimeLoggedRecord) {
      const task = this.extraTimeViewModel();

      // we don't allow deleting of the last extra time entry - because a completed task must have at least time spent entry logged.
      if (this.disabled || !task || this.isLastTimeEntryForCompletedTask()) {
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteThisLoggedTimeMsg,
            title: this.lang().DeleteThisLoggedTime,
         },
      };

      instance.result.then((result) => {
         if (result == 1 && task) {
            if (record.extraTimeID) {
               this.manageTask
                  .deleteExtraTime(record.extraTimeID, task.checklistID)
                  .then((answer) => {
                     if (answer.data.success == true && record.extraTimeID) {
                        this.removeLoggedTime(record.extraTimeID);
                        this.extraTimeUpdated.emit();
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            } else if (!record.extraTimeID && record.fromChecklistTime) {
               this.manageTask
                  .deleteTimeLoggedFromCompletedTask(task.checklistID)
                  .then((answer) => {
                     if (answer.data.success == true && answer.data.extraTimeIDToRemove) {
                        this.removeLoggedTime(answer.data.extraTimeIDToRemove);
                        this.extraTimeUpdated.emit();
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            }
         }
      });
   }

   private buildExtraTimeData(extraTime: TaskExtraTime): TimeLoggedRecord {
      if (!extraTime) return {};

      const calculatedData = this.manageTask.getTaskExtraTimeCalculatedData(extraTime);

      return {
         ...extraTime,
         ...calculatedData,
      };
   }

   private removeLoggedTime(extraTimeID: number): void {
      for (const groupedTime of this.groupedLoggedTime() ?? []) {
         const indexOfLogToRemove = groupedTime.userExtraTime.findIndex(
            (log) => log.extraTimeID === extraTimeID,
         );
         if (indexOfLogToRemove !== -1) {
            groupedTime.userExtraTime.splice(indexOfLogToRemove, 1);
         }
      }
   }
}
