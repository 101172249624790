<h3>
   <strong>{{ docTutorial?.title }}</strong>
</h3>
<div class="description-container">
   <div class="description">{{ docTutorial?.description }}</div>

   <lim-ui-secondary-button
      class="open-doc-button"
      [icon]="'fileLines'"
      (click)="openDoc()"
      >{{ docTutorial?.title }}</lim-ui-secondary-button
   >
</div>
