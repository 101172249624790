import { BehaviorSubject, filter, first, firstValueFrom, map } from "rxjs";

export class Mutex {
   private readonly queueCursor = new BehaviorSubject<number>(1);
   private readonly queueLength = new BehaviorSubject<number>(0);

   public async executeWithLock<T>(callback: () => T | Promise<T>): Promise<T> {
      await this.obtain();
      try {
         return await callback();
      } finally {
         this.release();
      }
   }

   public isLocked(): boolean {
      return this.queueCursor.value <= this.queueLength.value;
   }

   public locksPending(): number {
      return this.queueLength.value - this.queueCursor.value + 1;
   }

   public async whenNoLocksPending(): Promise<void> {
      await firstValueFrom(
         this.queueCursor.pipe(
            first(() => this.isLocked() === false),
            map(() => undefined),
         ),
      );
   }

   private async obtain(): Promise<void> {
      const myQueuePosition = this.queueLength.value + 1;
      this.queueLength.next(myQueuePosition);
      return firstValueFrom(
         this.queueCursor.pipe(
            filter((cursor) => cursor === myQueuePosition),
            map(() => undefined),
         ),
      );
   }

   private release(): void {
      this.queueCursor.next(this.queueCursor.value + 1);
   }
}
