<h3 class="graph-header">
   <lim-ui-icon icon="chartColumn" />
   {{ i18n().t("FutureEstimatedCosts") }} : {{ i18n().t("Labor") }}
   <span>{{ totalLaborCost() | localeCurrency: currencyCode() }}</span>
   |
   {{ i18n().t("Parts") }}
   <span>{{ totalPartsCost() | localeCurrency: currencyCode() }}</span>
   | {{ i18n().t("Total") }}:
   <span>{{ totalLaborCost() + totalPartsCost() | localeCurrency: currencyCode() }}</span>
</h3>
<div>
   <canvas
      [id]="'futureEstimatedCostChart' + assetID()"
      #futureEstimatedCostChartEle
      (click)="viewChartItem($event)"
      >{{ futureEstimatedCostChart() }}</canvas
   >
   <div id="futureEstimatedCostChartLegend" #futureEstimatedCostChartLegendEle></div>
</div>
