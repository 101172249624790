import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageImonnit } from "src/app/assets/services/manageImonnit";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "intro-to-sensors",
   templateUrl: "./updateImonnitToken.modal.component.html",
   styleUrls: ["./updateImonnitToken.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      AlertComponent,
      LimbleHtmlDirective,
      PanelComponent,
      ContenteditableDirective,
      FormsModule,
      BasicModalFooterComponent,
   ],
})
export class UpdateImonnitToken implements OnInit {
   public errorMsg;
   public message;
   public title;
   public resolve;
   public modalInstance;
   public username;
   public password;

   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly manageImonnit = inject(ManageImonnit);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.errorMsg = "";
      this.message = this.resolve.message;
      this.title = this.resolve.title;
   }
   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      if (this.username.length > 2 || this.password.length > 2) {
         this.manageImonnit.signIn(this.username, this.password).then((res) => {
            //check if imonnit liked this u/p combination
            if (res.status == 200) {
               this.modalInstance.close({
                  username: this.username,
                  password: this.password,
               });
            } else {
               this.alertService.addAlert(
                  this.lang().UsernameOrPasswordIncorrect,
                  "danger",
                  2000,
               );
            }
         });
      } else {
         this.alertService.addAlert(
            this.lang().UsernameOrPasswordIncorrect,
            "danger",
            2000,
         );
      }
   };
}
