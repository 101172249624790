<div class="user-image-container">
   @for (user of usersToShow; track trackByFn($index, user)) {
      <div>
         @if (user !== undefined) {
            <div
               [limUiTooltip]="
                  user.userFirstName + ' ' + user.userLastName + ' is viewing this task'
               "
            >
               <user-image [user]="user" />
            </div>
         }
         @if (user === undefined) {
            <div limUiTooltip="An unknown user is viewing this task">
               <user-image [user]="user" />
            </div>
         }
      </div>
   }
</div>
