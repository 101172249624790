import { CommonModule } from "@angular/common";
import { inject, Component, Input, computed } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   selector: "task-completed-by-cell",
   imports: [CommonModule],
   templateUrl: "./task-completed-by-cell.component.html",
})
export class TaskCompletedByCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   @Input() public column?: Column;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
