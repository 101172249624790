<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="part-settings-content">
            <div class="form-group">
               <label>
                  {{ lang().MinimumPartQtyThreshold }}

                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().MinimumPartQtyThresholdTooltip }}"
                     placement="bottom"
                  />
               </label>
               <div
                  class="input-and-unit-container"
                  [class.with-unit-of-measure]="unitOfMeasureService.isFeatureEnabled()"
               >
                  <input
                     type="number"
                     class="form-control"
                     [(ngModel)]="part.partOverstockedThreshold"
                     min="-1"
                     (blur)="updatePartOverstockedThreshold()"
                     onclick="this.focus();"
                  />

                  @if (unitOfMeasureService.isFeatureEnabled()) {
                     <span
                        class="unit"
                        [limUiTooltip]="part.partID | partUnitOfMeasure: 'singular'"
                     >
                        {{ part.partID | partUnitOfMeasure: "short" }}
                     </span>
                  }
               </div>
            </div>

            <div class="form-group">
               <label class="col-sm-4 control-label">
                  {{ lang().MaximumPartQtyThreshold }}

                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().MaximumPartQtyThresholdTooltip }}"
                     placement="bottom"
                  />
               </label>
               <div
                  class="input-and-unit-container"
                  [class.with-unit-of-measure]="unitOfMeasureService.isFeatureEnabled()"
               >
                  <input
                     type="number"
                     class="form-control"
                     [(ngModel)]="part.partMaxQtyThreshold"
                     min="-1"
                     (blur)="updatePartMaxQtyThreshold()"
                     onclick="this.focus();"
                  />

                  @if (unitOfMeasureService.isFeatureEnabled()) {
                     <span [limUiTooltip]="part.partID | partUnitOfMeasure: 'singular'">
                        {{ part.partID | partUnitOfMeasure: "short" }}
                     </span>
                  }
               </div>
            </div>

            <div class="form-group">
               <label>
                  {{ lang().StaleThreshold }} ({{ lang().days }})
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().StaleThresholdTooltip }}"
                     placement="bottom"
               /></label>
               <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="part.partStaleThreshold"
                  min="-1"
                  (blur)="updatePartStaleThreshold()"
                  onclick="this.focus();"
               />
            </div>

            <div class="form-group">
               <label>
                  {{ lang().ThresholdTasksAssignedTo }}:
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{
                        lang().TasksCreatedFromTheThresholdsWillBeAssignedToThisUserOrTeam
                     }}"
                     placement="bottom"
                  />
               </label>
               @if (part.userID == 0 && part.profileID == 0) {
                  <span>
                     <a (click)="changePartOwner(part)" class="cursor">{{
                        lang().Unassigned
                     }}</a>
                  </span>
               }

               @if (part.userID > 0) {
                  <span>
                     <a (click)="changePartOwner(part)" class="cursor">{{
                        part.userFirstName + " " + part.userLastName
                     }}</a>
                  </span>
               }

               @if (part.profileID > 0) {
                  <span>
                     <a (click)="changePartOwner(part)" class="cursor">{{
                        part.profileDescription
                     }}</a>
                  </span>
               }
            </div>

            <div class="form-group">
               <label>
                  {{ lang().ThresholdTasksWillBeDue }}

                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="bottom"
                     limUiTooltip="{{ lang().ThresholdTasksDueDaysPartTooltip }}"
                  />
               </label>
               <span class="setting-with-text">
                  <lim-ui-form-dropdown-input
                     class="setting-item"
                     [inputString]="minThresholdDueDaysString()"
                  >
                     <ng-container menu>
                        <lim-ui-dropdown-text-item
                           (click)="setMinThresholdDueDays(0)"
                           [displayString]="lang().TheSameDay"
                           [outputString]="lang().TheSameDay"
                        />
                        @for (dayOption of dayOptions; track dayOption.days) {
                           <lim-ui-dropdown-text-item
                              (click)="setMinThresholdDueDays(dayOption.days)"
                              [displayString]="dayOption.display() + ' ' + lang().after"
                           />
                        }
                        <lim-ui-dropdown-item (click)="$event.stopPropagation()">
                           <span class="cursor dropdown-item-with-input">
                              <div>
                                 <input
                                    type="number"
                                    [(ngModel)]="part.minThresholdTaskDueDays"
                                    (blur)="
                                       setMinThresholdDueDays(
                                          part.minThresholdTaskDueDays
                                       )
                                    "
                                    (click)="$event.stopPropagation()"
                                 />
                              </div>
                              <span> {{ lang().days }} {{ lang().after }} </span>
                           </span>
                        </lim-ui-dropdown-item>
                     </ng-container>
                  </lim-ui-form-dropdown-input>
                  {{ lang().TheyAreCreated }}
               </span>
            </div>

            <div class="form-group">
               <label>
                  {{ lang().ThresholdTasksWillDefaultTo }}

                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="bottom"
                     limUiTooltip="{{ lang().ThresholdTasksTypePartTooltip }}"
                  />
               </label>
               <lim-ui-form-dropdown-input
                  [inputString]="minThresholdTaskTypeString()"
                  class="full-width-input"
               >
                  <ng-container menu>
                     <lim-ui-dropdown-text-item
                        (click)="setMinThresholdTaskType('planned')"
                        [displayString]="lang().Planned"
                        [outputString]="lang().Planned"
                     />
                     <lim-ui-dropdown-text-item
                        (click)="setMinThresholdTaskType('unplanned')"
                        [displayString]="lang().Unplanned"
                        [outputString]="lang().Unplanned"
                     />
                  </ng-container>
               </lim-ui-form-dropdown-input>
            </div>

            <div class="form-group">
               <label>
                  {{ lang().ThresholdTasksAreCompletetd }}

                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="bottom"
                     limUiTooltip="{{ lang().ThresholdTasksCompletionPartTooltip }}"
                  />
               </label>
               <lim-ui-form-dropdown-input
                  [inputString]="minThresholdCompleteString()"
                  class="full-width-input"
               >
                  <ng-container menu>
                     <lim-ui-dropdown-text-item
                        (click)="setMinThresholdTaskAutoComplete('manual')"
                        [displayString]="lang().Manually"
                        [outputString]="lang().Manually"
                     />
                     <lim-ui-dropdown-text-item
                        (click)="setMinThresholdTaskAutoComplete('automatic')"
                        [displayString]="lang().Automatically"
                        [outputString]="lang().Automatically"
                     />
                  </ng-container>
               </lim-ui-form-dropdown-input>
            </div>

            <div class="form-group">
               <label>
                  {{ lang().LastCounted }}:
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().lastCountedDateTooltip }}"
                     placement="bottom"
               /></label>
               <div class="info-content">
                  @if (part.lastCounted > 0) {
                     <span>
                        {{ part.lastCounted * 1000 | betterDate: "date" }}
                     </span>
                  }
                  @if (part.lastCounted == 0) {
                     <span>
                        {{ lang().NA }}
                     </span>
                  }
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer class="flex-start">
      <lim-ui-danger-button (click)="deletePart(part)">
         {{ lang().DeletePart }}
      </lim-ui-danger-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
