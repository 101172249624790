import { Injectable } from "@angular/core";
import type { TaskComment } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import type { User } from "src/app/users/services/manage-user.types.ts";

@Injectable({ providedIn: "root" })
export class CommentUtilsService {
   public isCommentVisible(comment: TaskComment, currentUser: User): boolean {
      const isExternalUser = currentUser.workOrderUser === 1;
      if (!isExternalUser) {
         return true;
      }
      return !(
         currentUser.userInfo.noteHiddenFromExternalFlag === 1 || comment.noteHidden === 1
      );
   }
}
