@if (task && taskDisplayInfo && informationToInclude) {
   <div class="task-heading">
      @if (
         taskDisplayInfo.locationTaskImage != null &&
         taskDisplayInfo.locationTaskImage !== ""
      ) {
         <img
            [src]="
               'viewFile.php?f=upload-' +
               customerID +
               '/locations/' +
               task.locationID +
               '/defaultTaskImage/' +
               taskDisplayInfo.locationTaskImage
            "
            class="task-heading-img"
            [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
         />
      }

      @if (
         taskDisplayInfo.locationTaskImage === null ||
         taskDisplayInfo.locationTaskImage === ""
      ) {
         <div class="logo-section">
            @if (!useCustomLogo) {
               <img [src]="logoURLDark" class="limble-logo" alt="Limble CMMS" />
            }
            @if (useCustomLogo) {
               <img
                  [src]="customLogoURL"
                  class="custom-logo"
                  [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
               />
            }
         </div>
      }

      <span [limbleHtml]="taskDisplayInfo.locationName"> </span>
   </div>
   <div class="task-title">
      <lim-ui-icon
         [icon]="task.checklistTemplate === 1 ? 'wrench' : taskDisplayInfo.taskIcon"
         iconSize="medium"
      />

      <span [limbleHtml]="task.checklistName"></span>
      <span>- #{{ task.checklistID }} </span>
   </div>

   <div class="task-info-item-container lim-ui-list-item">
      @if (informationToInclude?.status) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="bell" />{{ lang().Status }}
            </div>
            <div class="info-item-value">
               {{ taskDisplayInfo.statusName }}
            </div>
         </div>
      }

      @if (informationToInclude?.priority) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="circleExclamation" />{{ lang().Priority }}
            </div>
            <div
               class="info-item-value"
               [limbleHtml]="taskDisplayInfo.priorityName"
            ></div>
         </div>
      }

      @if (informationToInclude?.type) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="circleExclamation" />{{ lang().Type }}
            </div>
            <div class="info-item-value">
               @if (taskDisplayInfo.taskIcon) {
                  <lim-ui-icon [icon]="taskDisplayInfo.taskIcon" />
               }
               {{ taskDisplayInfo.typeStr }}
            </div>
         </div>
      }

      @if (informationToInclude?.asset) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="cubes" />{{ lang().Asset }}
            </div>
            @if (assetNameStr?.length) {
               <div class="info-item-value" [limbleHtml]="assetNameStr"></div>
            }
            @if (assetNameStr?.length == 0) {
               <div class="info-item-value">
                  {{ lang().None }}
               </div>
            }
         </div>
      }

      @if (informationToInclude?.assignment) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="user" />{{ lang().UpdateRelatedPMsAssignedTo }}
            </div>
            <div class="info-item-value">
               <span> {{ taskDisplayInfo.displayName }}</span>
            </div>
         </div>
      }

      @if (task.checklistTemplate == 0 && informationToInclude?.dueDate) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="calendarDays" />{{ lang().DueDate }}
            </div>
            @if (task.checklistDueDate !== null) {
               <div class="info-item-value">
                  {{ task.checklistDueDate * 1000 | betterDate: "date" }}
               </div>
            }
         </div>
      }

      @if (informationToInclude?.downtime) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="fireFlameCurved" />{{ lang().Downtime }}
            </div>
            <div class="info-item-value">
               {{ checklistDowntimeHours }} {{ lang().hr }}
               {{ lang().and }}
               {{ checklistDowntimeMinutes }} {{ lang().min }}
            </div>
         </div>
      }

      @if (informationToInclude?.location) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="houseChimney" />{{ lang().Location }}
            </div>
            <div
               class="info-item-value"
               [limbleHtml]="taskDisplayInfo.locationName"
            ></div>
         </div>
      }

      @if (task.checklistCompletedDate && informationToInclude?.completedBy) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="user" />{{ lang().CompletedBy }}
            </div>
            <div class="info-item-value">
               <span> {{ taskDisplayInfo.completedByStr }}</span>
            </div>
         </div>
      }
      @if (task.checklistCompletedDate && informationToInclude?.completedDate) {
         <div class="task-info-item">
            <div class="info-item-header">
               <lim-ui-icon icon="calendarDays" />{{ lang().CompletedDate }}
            </div>
            <div class="info-item-value">
               {{ task.checklistCompletedDate * 1000 | betterDate: "dateTime" }}
            </div>
         </div>
      }
   </div>
   <div class="sections-container">
      <!-- Description section -->
      @if (
         task.checklistInstructions &&
         task.checklistInstructions.length &&
         informationToInclude.description
      ) {
         <div class="section">
            <div class="section-header">
               {{ lang().Description | uppercase }}
            </div>
            @if (
               (task.checklistEstTime && task.checklistEstTime <= 0) ||
               !task.checklistEstTime
            ) {
               <span [limbleHtml]="task.checklistInstructions"></span>
            }
            @if (task.checklistEstTime && task.checklistEstTime > 0) {
               <span>
                  <div class="task-info-item">
                     <div class="info-item-value">
                        {{ lang().EstimatedTime }}: {{ checklistEstTimeHours }}
                        {{ lang().hr }}
                        {{ lang().and }}
                        {{ checklistEstTimeMinutes }} {{ lang().min }}
                     </div>
                  </div>
               </span>
            }
         </div>
      }

      <!-- Asset Information -->
      @if (asset && displayAssetInfo && informationToInclude?.assetInformation) {
         <span class="section">
            <div class="section-header">
               {{ lang().AssetInformation | uppercase }}
            </div>
            <!-- on open tasks -->
            @if (!task.checklistStatusID) {
               <div>
                  @for (value of assetFieldValues; track value) {
                     <div>
                        @if (
                           value.fieldID !== null && manageAsset.getField(value.fieldID);
                           as field
                        ) {
                           <span>
                              @if (
                                 field.fieldTypeID != 3 &&
                                 field.fieldTypeID != 4 &&
                                 field.displayOnTasks == 1
                              ) {
                                 <div>
                                    @if (
                                       manageAsset.getFieldType(field.fieldTypeID);
                                       as fieldType
                                    ) {
                                       <span>
                                          <span>
                                             @if (fieldType.fieldTypeIcon) {
                                                <lim-ui-icon
                                                   [icon]="
                                                      fieldType.fieldTypeIcon | iconAlias
                                                   "
                                                />
                                             }

                                             {{
                                                field.fieldName
                                                   ? field.fieldName
                                                   : "Unnamed"
                                             }}:
                                          </span>
                                       </span>
                                    }
                                    <span class="asset-field-value">{{
                                       field.fieldTypeID == 2
                                          ? (value.valueContent | betterDate: "date")
                                          : field.fieldTypeID == 6
                                            ? (value.valueContent | betterCurrency)
                                            : value.valueContent
                                    }}</span>
                                 </div>
                              }
                           </span>
                        }
                     </div>
                  }
               </div>
            }
            <!-- on closed tasks -->
            @if (task.checklistStatusID && assetInfoFromCompletionArr?.length) {
               <div>
                  @for (field of assetInfoFromCompletionArr; track field) {
                     <div>
                        @if (field.assetID == asset.assetID) {
                           <div>
                              <span>
                                 @if (field.fieldTypeIcon) {
                                    <lim-ui-icon
                                       [icon]="field.fieldTypeIcon | iconAlias"
                                    />
                                 }

                                 {{ field.fieldName ? field.fieldName : "Unnamed" }}:
                              </span>
                              <span class="asset-field-value">{{
                                 field.fieldTypeID == 2
                                    ? (field.valueContent | betterDate: "date")
                                    : field.fieldTypeID == 6
                                      ? (field.valueContent | betterCurrency)
                                      : field.valueContent
                              }}</span>
                           </div>
                        }
                     </div>
                  }
               </div>
            }
         </span>
      }

      <!-- Invoices section -->
      @if (
         task.invoiceIDs &&
         task.invoiceIDs.length > 0 &&
         viewInvoicesCred &&
         informationToInclude?.invoices
      ) {
         <span class="section">
            <div class="section-header">
               {{ lang().Invoices | uppercase }}
            </div>
            <div>
               @for (invoiceID of task.invoiceIDs; track invoiceID) {
                  <div>
                     @if (invoices[invoiceID]; as invoice) {
                        <span [hidden]="!invoice.invoiceFileName">
                           {{ invoice.invoiceFileName }}
                           <span>
                              {{ lang().forATotalOf }}
                              {{ currencySymbol
                              }}{{ invoice.invoiceCost | betterNumber: "1.2-2" }}
                           </span>
                        </span>
                        <span [hidden]="!!invoice.invoiceFileName">
                           <span>
                              {{ lang().InvoiceForATotalOf }}&nbsp;{{ currencySymbol
                              }}{{ invoice.invoiceCost | betterNumber: "1.2-2" }}
                           </span>
                        </span>
                        <span> - #{{ invoice.invoiceID }} </span>

                        <span [hidden]="!(invoice.invoiceDescription?.length > 0)">
                           - <span [limbleHtml]="invoice.invoiceDescription"></span>
                        </span>
                        @if (invoice.poItemID > 0 && currentUser.workOrderUser != 1) {
                           <span>
                              -
                              {{ lang().PO }} - {{ invoice.poNumber }} ({{
                                 invoice.poState
                              }})
                           </span>
                        }
                     }
                  </div>
               }
            </div>
         </span>
      }

      <!-- Parts section -->
      @if (parts && parts.length > 0 && informationToInclude?.parts) {
         <span class="section">
            <div class="section-header">
               {{ lang().Parts | uppercase }}
            </div>

            @for (part of parts | orderBy: "partName"; track part) {
               <div>
                  @if (partsLookup.get(part.partID); as associatedPart) {
                     <div class="part-container">
                        <lim-ui-icon icon="gears" />

                        @if (task.checklistUserCompleted == 0) {
                           <span>
                              <span>{{ part.suggestedNumber }}</span
                              >&nbsp;
                              <span [limbleHtml]="part.partName"></span>

                              @if (
                                 part.partNumber != null && part.partNumber?.length > 0
                              ) {
                                 <span>
                                    <span> - </span>
                                    <span [limbleHtml]="part.partNumber"></span>
                                 </span>
                              }

                              @if (associatedPart.partLocation?.length) {
                                 <span>
                                    <span> - </span>
                                    <span
                                       [limbleHtml]="associatedPart.partLocation"
                                    ></span
                                 ></span>
                              }
                              <span> - </span>
                              <span [limbleHtml]="part.locationName"></span>
                           </span>
                        }

                        @if (task.checklistUserCompleted) {
                           <span>
                              <span> {{ part.usedNumber }}</span
                              >&nbsp;
                              <span>
                                 <b [limbleHtml]="part.partName"></b>
                                 @if (
                                    part.partNumber != null && part.partNumber?.length > 0
                                 ) {
                                    <span>
                                       <span> - </span>
                                       <span [limbleHtml]="part.partNumber"></span>
                                    </span>
                                 }
                              </span>

                              @if (associatedPart.partLocation?.length) {
                                 <span>
                                    <span> - </span>
                                    <span
                                       [limbleHtml]="associatedPart.partLocation"
                                    ></span
                                 ></span>
                              }
                              <span> - </span>
                              <span [limbleHtml]="part.locationName"></span>
                              @if (partCostOnPrintFlag === 1) {
                                 <span>
                                    <span> - </span>
                                    {{ part.usedPrice | betterCurrency }}
                                    {{ lang().partEachTotaling }}
                                    <b>{{
                                       part.usedPrice * part.usedNumber | betterCurrency
                                    }}</b>
                                 </span>
                              }
                           </span>
                        }
                     </div>
                  }
               </div>
            }
         </span>
      }

      <!-- Instructions section -->
      @if (informationToInclude?.instructions) {
         <span class="instructions section">
            <div class="section-header">
               {{ lang().Instructions | uppercase }}
            </div>
            <span class="instruction-items">
               @for (item of items | filter: { itemParentID: 0 }; track item) {
                  <chk-item [info]="info" [item]="item" class="task-item" />
               }
            </span>
         </span>
      }

      <!-- Time Spent section -->
      @if (
         (task.checklistPromptTime ||
            (task.extraTimeIDs !== undefined && task.extraTimeIDs.length > 0)) &&
         informationToInclude?.timeSpent
      ) {
         <span class="section">
            <div class="section-header">
               {{ lang().TimeSpent | uppercase }}
            </div>
            @if (task.checklistCompletedDate) {
               <span class="time-spent-item">
                  {{ task.checklistCompletedDate * 1000 | betterDate: "dateTime" }}
                  - {{ checklistPromptTimeHours }} {{ lang().hr }}
                  {{ lang().and }}
                  {{ checklistPromptTimeMinutes }} {{ lang().min }}
                  {{ lang().completedBySomeone }}
                  {{ taskDisplayInfo.completedFirstName }}
                  {{ taskDisplayInfo.completedLastName }}
                  @if (task.categoryID) {
                     <span>
                        |
                        {{ lang().BillableHours }}:
                        <span>
                           {{ billableHours }} {{ lang().hr }}
                           {{ lang().and }}
                           {{ billableMinutes }} {{ lang().min }}
                           @if (categoriesIndex && categoriesIndex[task.categoryID]) {
                              <span
                                 [limbleHtml]="
                                    categoriesIndex[task.categoryID]?.categoryName
                                 "
                              ></span>
                           }
                           @if (viewLaborCostsCred) {
                              <span>
                                 {{ lang().at }}
                                 {{ currencySymbol }}{{ task.billableRate }}
                                 {{ lang().perHour }}
                              </span>
                           }
                        </span>
                     </span>
                  }

                  @if (task.extraTimeNotes && task.extraTimeNotes.length > 0) {
                     <span>
                        | {{ lang().Description }}:
                        <span [limbleHtml]="task.extraTimeNotes"></span>
                     </span>
                  }
               </span>
            }
            @if (timeLogged) {
               @for (time of timeLogged | orderBy: "-loggedAt"; track time) {
                  <span class="time-spent-item">
                     <lim-ui-icon icon="clock" />
                     <div>
                        <span>
                           {{ (time.loggedAt ?? 0) * 1000 | betterDate: "dateTime" }}
                        </span>

                        {{ time.promptTimeHours }} {{ lang().hr }}
                        {{ lang().and }}
                        {{ time.promptTimeMinutes }} {{ lang().min }}

                        {{ lang().completedBySomeone }}
                        {{ time.userFirstName }} {{ time.userLastName }}
                        @if (time.userFirstName == null && time.userLastName == null) {
                           <span limUiTooltip="{{ lang().DeletedUserTooltip }}">{{
                              lang().DeletedUser
                           }}</span>
                        }
                        <b>|</b>
                        @if (time.categoryID) {
                           <span>
                              {{ lang().BillableHours }}:
                              <span>
                                 {{ time.billableHours }} {{ lang().hr }}
                                 {{ lang().and }}
                                 {{ time.billableMinutes }} {{ lang().min }}

                                 <span
                                    [limbleHtml]="
                                       categoriesIndex[time.categoryID]?.categoryName
                                    "
                                 ></span>
                                 @if (viewLaborCostsCred) {
                                    <span>
                                       {{ lang().at }}
                                       {{ currencySymbol }}{{ time.billableRate }}
                                       {{ lang().perHour }}
                                    </span>
                                 }
                              </span>
                           </span>
                        }

                        @if (time.notes && time.notes.length > 0) {
                           <span>
                              |
                              {{ lang().Description }}:
                              <span [limbleHtml]="time.notes"></span>
                           </span>
                        }
                     </div>
                  </span>
               }
            }
         </span>
      }

      <!-- Comments section -->
      @if (
         task.noteIDs !== undefined &&
         task.noteIDs.length > 0 &&
         task.checklistTemplate == 0 &&
         informationToInclude?.comments
      ) {
         <span class="comments section">
            <div class="section-header">
               {{ lang().Comments | uppercase }}
            </div>
            @for (noteID of task.noteIDs; track noteID) {
               @if (manageTask.getComment(noteID); as note) {
                  <div [ngClass]="{ printHide: note.noteHidden == 1 }">
                     @if (currentUser?.workOrderUser != 1 && note.noteHidden != 1) {
                        <div class="comment-list-item">
                           <div>
                              <span [limbleHtml]="note.noteMessage"></span>
                              @if (note.relatedChecklistID) {
                                 <span>
                                    •
                                    @if (
                                       manageTask.getTaskLocalLookup(
                                          note.relatedChecklistID
                                       );
                                       as relatedTask
                                    ) {
                                       <span
                                          [limbleHtml]="relatedTask.checklistName"
                                       ></span>
                                    }
                                    &nbsp;#{{ note.relatedChecklistID }}
                                 </span>
                              }
                           </div>
                           @if (
                              taskDisplayInfo.noteData?.get(note.noteID);
                              as noteDisplayData
                           ) {
                              <div class="note-files-container">
                                 @for (file of noteDisplayData.files; track file) {
                                    @if (!file.isImage) {
                                       <file-list-item
                                          [file]="file"
                                          url="{{ file.getURL }}"
                                       />
                                    }
                                 }
                              </div>
                              <div class="note-images-container">
                                 @for (file of noteDisplayData.files; track file) {
                                    @if (file.isImage) {
                                       <view-image
                                          class="task-print"
                                          [src]="file.getURL"
                                       />
                                    }
                                 }
                              </div>
                              <span class="comment-footer">
                                 {{ noteDisplayData.displayName }}
                                 •
                                 {{
                                    (note.noteTimestamp ?? 0) * 1000
                                       | betterDate: "dateTime"
                                 }}
                              </span>
                           }
                        </div>
                     }
                  </div>
               }
            }
         </span>
      }

      <!-- Completion Notes-->
      @if (task.checklistComments?.length && informationToInclude?.completionNotes) {
         <span class="section">
            <div class="section-header">
               {{ lang().CompletionNotes | uppercase }}
            </div>
            <div>
               <div
                  type="text"
                  class="div-text-box"
                  [limbleHtml]="task.checklistComments"
               ></div>
            </div>
         </span>
      }
   </div>
}
