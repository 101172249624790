import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, ViewChild, computed } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import type { ModalResult } from "@limblecmms/lim-ui";
import {
   BasicModalHeaderComponent,
   ModalService,
   LimUiModalRef,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   SelectionPanelComponent,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { filter } from "rxjs";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { HierarchyContainerComponent } from "src/app/shared/components/global/hierarchy/hierarchy-container-component/hierarchy-container.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { ParamsService } from "src/app/shared/services/params.service";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import type { TemplateHierarchyNode } from "src/app/tasks/components/shared/services/task-templates-hierarchy/template-hierarchy.service";
import { TemplateHierarchyService } from "src/app/tasks/components/shared/services/task-templates-hierarchy/template-hierarchy.service";
import { debounceAndFilterSearchInput } from "src/app/tasks/operators/debounce-and-filter-search-input-operator";
import type { Task } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";

type AugmentedTask = Task & {
   selected: boolean;
   extraAssets: Array<Asset>;
   recurrences: Array<Recurrence>;
};

@Component({
   selector: "pick-tasks-template-view",
   templateUrl: "./pickTasksTemplateView.modal.component.html",
   styleUrls: ["./pickTasksTemplateView.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      SearchBoxComponent,
      NoSearchResults,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
      SelectionPanelComponent,
      ReactiveFormsModule,
      HierarchyContainerComponent,
      LoadingAnimationComponent,
   ],
})
export class PickTasksTemplateView
   implements OnInit, ModalResult<Set<number>>, OnDestroy
{
   @ViewChild("hierarchy") hierarchyContainer?: HierarchyContainerComponent;
   @Input({ required: true }) public message: string = "";
   @Input({ required: true }) public title: string = "";
   @Input({ required: true }) public locationIDs: Array<number> = [];
   @Input() public singleSelection: boolean = false;
   @Input() public taskIDsToIgnore: Array<number> = [];

   public noSearchResults: boolean = false;

   public hierarchyOptions: HierarchyOptions;

   public templateNodes: LimbleMap<number, TemplateHierarchyNode> = new LimbleMap();
   public selectedNodeIDs: Set<number> = new Set();

   public locationsIndex: Record<number, any> = {};
   public locations: Array<HierarchyNode> = [];
   public treeData: Array<HierarchyNode> = [];

   protected isLoading: boolean = false;

   public search: string = "";
   protected searchControl = new FormControl<string>("");
   private readonly searchControlSub: Subscription = this.searchControl.valueChanges
      .pipe(debounceAndFilterSearchInput(500, 3))
      .subscribe((search) => {
         this.fetchSearchedTasks(search);
      });

   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly credService = inject(CredService);
   private readonly templateHierarchyService = inject(TemplateHierarchyService);
   public readonly modalRef: LimUiModalRef<PickTasksTemplateView, Set<number>> =
      inject(LimUiModalRef);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.hierarchyOptions = {
         idKey: "checklistID",
         nodeButtons: [
            {
               tooltip: this.lang().OpenThisTask,
               clickFunction: this.popTask.bind(this),
               text: this.lang().View,
            },
         ],
         submit: this.submit.bind(this),
         onSelect: this.onNodeSelected,
      };

      this.modalService.closeEvents
         .pipe(
            takeUntilDestroyed(),
            filter(() => this.modalService.getActiveModal() === this.modalRef),
         )
         .subscribe(() => {
            this.rebuildTreeData();
         });
   }

   public ngOnInit() {
      this.initialize();
   }

   public ngOnDestroy() {
      this.searchControlSub.unsubscribe();
   }

   private async initialize() {
      await this.fetchInitialTaskData();
   }

   protected clearSelection(): void {
      if (!this.hierarchyContainer) {
         return;
      }
      this.hierarchyContainer.deselectAllNodes();
      this.selectedNodeIDs.clear();
   }

   protected markAllSelection(): void {
      if (!this.hierarchyContainer) {
         return;
      }
      this.hierarchyContainer.selectAllNodes();
      for (const node of this.templateNodes) {
         this.selectedNodeIDs.add(node.checklistID);
      }
   }

   private async rebuildTreeData() {
      const { locationsIndex, treeData, noSearchResults, templateNodes } =
         await this.templateHierarchyService.rebuildTreeData(
            this.treeData,
            this.templateNodes,
            {
               search: this.search,
               checklistTemplates: [1],
               notTaskIDs: this.taskIDsToIgnore,
            },
            this.locationIDs,
         );

      this.locationsIndex = locationsIndex;
      this.treeData = treeData;
      this.templateNodes = templateNodes;
      this.noSearchResults = noSearchResults;

      this.reSelectPreviouslySelectedNodes();

      if (this.hierarchyContainer) {
         this.hierarchyContainer.renderInitialHierarchyItems();
      }
   }

   buildInitialHierarchyData = () => {
      this.locationsIndex = {};
      const { locationsIndex, filteredTreeData } =
         this.templateHierarchyService.getLocationNodesWithoutRegions(this.locationIDs);

      this.locations = filteredTreeData;
      this.locationsIndex = locationsIndex;
   };

   private async fetchInitialTaskData() {
      this.isLoading = true;
      this.buildInitialHierarchyData();

      const response = await this.templateHierarchyService
         .fetchInitialData(this.locations, {
            checklistTemplates: [1],
            notTaskIDs: this.taskIDsToIgnore,
         })
         .finally(() => {
            this.isLoading = false;
         });
      if (response === undefined) {
         console.error("Failed to fetch initial task data pick-tasks-template-view");
         return;
      }
      this.treeData = response;
   }

   protected async fetchSearchedTasks(search: string | null) {
      //JIT TODO: currently, if you search for something and then reset the search, the tasks you had
      //previously selected will still be selected, but no displayed.  Figure out a way to expand the
      //tree to show the selected tasks? Gotta look through the template nodes to find a given node, then
      //expand its parent
      this.buildInitialHierarchyData();
      this.noSearchResults = false;
      this.isLoading = true;
      const response = await this.templateHierarchyService
         .fetchSearchedData(this.locations, {
            search: this.search,
            checklistTemplates: [1],
            notTaskIDs: this.taskIDsToIgnore,
         })
         .finally(() => {
            this.isLoading = false;
         });
      if (response === undefined) {
         console.error("Error fetching searched templates in pick-tasks-template-view");
         return;
      }
      const { filteredTreeData, groupedResponses, noSearchResults, templateNodes } =
         response;
      this.treeData = filteredTreeData;
      this.templateNodes = templateNodes;

      this.noSearchResults = noSearchResults;

      if (search === null || search === "") {
         if (this.locationIDs.length === 1) {
            this.hierarchyContainer?.toggleNodeCollapsed(this.treeData[0]);
         }
      } else {
         this.templateHierarchyService.processAndMapGroupedResponse(
            groupedResponses,
            this.locationsIndex,
            this.templateNodes,
            this.search,
         );
      }
      this.reSelectPreviouslySelectedNodes();
   }

   fetchMoreTasksAtLocation = async (locationID: number) => {
      const location = this.locationsIndex[locationID];

      await this.templateHierarchyService.fetchMoreTasksAtLocation(
         location,
         this.templateNodes,
         {
            checklistTemplates: [1],
            search: this.search,
            notTaskIDs: this.taskIDsToIgnore,
         },
      );
      this.reSelectPreviouslySelectedNodes();
      if (this.hierarchyContainer) {
         this.hierarchyContainer.renderInitialHierarchyItems();
      }
   };

   protected popTask(task: AugmentedTask): void {
      let preview = true;
      const superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
      if (superUser) {
         //if they are a super user let them edit any Template anywhere.
         preview = false;
      }
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               preview: preview,
               template: true,
            },
         },
      };
   }

   submit = (): void => {
      this.modalRef.close(this.selectedNodeIDs);
   };

   protected close(): void {
      this.modalRef.close(new Set());
   }

   public onNodeSelected = (nodeToToggle?) => {
      if (nodeToToggle?.checklistID) {
         if (nodeToToggle.selected) {
            this.selectedNodeIDs.add(nodeToToggle.checklistID);
         } else {
            this.selectedNodeIDs.delete(nodeToToggle.checklistID);
         }
      }
      if (nodeToToggle.nodes.length) {
         this.reSelectPreviouslySelectedNodes();
      }
   };

   private reSelectPreviouslySelectedNodes() {
      for (const checklistID of this.selectedNodeIDs) {
         const node = this.templateNodes.get(checklistID);
         if (node && !node.selected) {
            this.hierarchyContainer?.toggleNodeSelected(node);
         }
      }
   }
}
