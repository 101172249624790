<div class="clock-container" [limUiTooltip]="lang()?.TaskTimerTooltip">
   <div class="timer-clock">
      {{ actualTime() | timerFormat }}
   </div>
   @if (estimatedTime() > 0) {
      <div class="timer-estimated-time">
         {{ lang()?.EstimatedAbbreviation }}:
         {{ estimatedTime() | timerFormat }}
      </div>
   }
</div>
