<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="row">
            @if (purchaseOrderItems.size > 0) {
               <div class="col-xs-12">
                  <div class="dd" id="nestable_list_1">
                     @for (item of purchaseOrderItems; track item) {
                        <div class="lim-ui-list-item">
                           <div class="lim-ui-list-item-content">
                              <div class="number-received">
                                 <input
                                    type="number"
                                    onclick="this.focus();"
                                    [(ngModel)]="item.tempNumberReceived"
                                    max="{{
                                       item.qty - item.receivedQty | betterNumber: '1.0-3'
                                    }}"
                                    (blur)="validateItemNumber(item)"
                                 />
                                 {{ lang().ofStr }}
                                 <strong limUiTooltip="{{ lang().QtyLeftToReceive }}">
                                    {{
                                       item.qty - item.receivedQty | betterNumber: "1.0-3"
                                    }}
                                 </strong>
                              </div>

                              <div>
                                 <span [limbleHtml]="item.itemName"></span>
                                 @if (item.itemNumber?.length) {
                                    <span>
                                       •
                                       <span [limbleHtml]="item.itemNumber"></span>
                                    </span>
                                 }
                                 <span
                                    [hidden]="
                                       !(item.description && item.description.length > 0)
                                    "
                                 >
                                    -
                                    <span [limbleHtml]="item.description"></span>
                                 </span>

                                 <lim-ui-minimal-icon-button
                                    icon="trashCanRegular"
                                    (click)="removeItem(item)"
                                 />
                              </div>
                           </div>
                        </div>
                     }
                  </div>
               </div>
            }
            @if (purchaseOrderItems.size === 0 && removedItem == false) {
               <div class="col-xs-12">
                  <div class="alert alert-dismissable alert-success">
                     <strong>{{ lang().AllOfTheItemsForThisPOHasBeenReceived }}</strong>

                     <br /><br />
                     {{ lang().OnceAllBillsHaveBeenMarkedPaidThisPOWillClose }}
                  </div>
               </div>
            }
         </div>
         <div class="alert alert-dismissable alert-warning" [hidden]="!(errorMsg !== '')">
            <strong class="mr-1">{{ lang().Warning }}!</strong> - {{ errorMsg }}
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Submit"
      (successClick)="submit()"
   />
</lim-ui-modal>
