import { inject, Injectable, signal, type WritableSignal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import type { Moment } from "moment";
import { type Observable, ReplaySubject, firstValueFrom, lastValueFrom, map } from "rxjs";
import {
   LIMITED_DASHBOARD_REPORTING_DAYS,
   RESTRICTED_DASHBOARD_REPORTING_DAYS,
} from "src/app/dashboards/global-dashboard/dashboard.service";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import { ManageMaps } from "src/app/maps/services/manageMaps";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { ManageUser } from "src/app/users/services//manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

/**
 * @deprecated
 * This service should have no dependencies.
 *
 * Because it does, it causes circularly dependencies easily.
 *
 * See full rationale: https://limble.atlassian.net/browse/LEAF-259
 *
 * Use `FeatureFlagsService` and move all feature specific logic to feature specific services.
 */
@Injectable({ providedIn: "root" })
export class ManageFeatureFlags {
   private readonly featureFlags: WritableSignal<Partial<IsFeatureEnabledMap>>;
   private readonly manageTask = inject(ManageTask);
   private readonly manageDashboard = inject(ManageDashboard);
   private readonly managePO = inject(ManagePO);
   private readonly manageParts = inject(ManageParts);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageMaps = inject(ManageMaps);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private currentUser: ManageUser["currentUser"];
   private featureMaps: boolean = false;

   /**
    * @deprecated
    * Use the `FeatureFlagsService#flagMap` signal instead.
    */
   public readonly features$: Observable<IsFeatureEnabledMap>;

   private readonly flagsInitialized: ReplaySubject<boolean> = new ReplaySubject();
   private readonly flagsInitialized$ = this.flagsInitialized.asObservable();

   public constructor() {
      this.featureFlags = signal({});
      this.features$ = toObservable(this.featureFlags).pipe(
         map((flags) => this.createDefaultsProxy(flags)),
      );
      this.features$.subscribe((isFeatureEnabledMap: IsFeatureEnabledMap) => {
         this.featureMaps = isFeatureEnabledMap.featureMaps ?? false;
      });
   }

   private async haveFlagsInitialized(): Promise<boolean> {
      return firstValueFrom(this.flagsInitialized$);
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#refresh`.
    */
   public setFeatureFlags(currentUser: ManageUser["currentUser"]): void {
      if (currentUser === undefined || !currentUser?.userInfo) {
         return;
      }
      this.flagsInitialized.next(true);
      this.currentUser = currentUser;
      const userInfo = currentUser.userInfo;
      this.parseFeatures(userInfo);
   }

   // This function was added to allow currentUser.userInfo to be mocked in unit tests (via private override in test file)
   private parseFeatures(userInfo) {
      const featureFlagsTemp = {};
      for (const featureFlagName in userInfo) {
         if (featureFlagName.startsWith("feature")) {
            featureFlagsTemp[featureFlagName] = Boolean(
               Number(userInfo[featureFlagName]) == 1,
            );
         }
      }
      this.featureFlags.set(featureFlagsTemp);
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a Geo Map service.
    */
   public addGeoMapCheck(): boolean {
      const numberOfGeoMaps = this.manageMaps.getMapsArr().length;
      if (numberOfGeoMaps > 0 && !this.featureMaps) {
         return false;
      }
      return true;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move all feature specific logic to feature specific services.
    */
   public isFeatureEnabled(featureName: string): boolean {
      return this.hasFeature(featureName);
   }

   private hasFeature(featureName: string): boolean {
      const featureFlag = this.featureFlags()[featureName];
      return typeof featureFlag === "number"
         ? Boolean(Number(featureFlag))
         : Boolean(featureFlag);
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a PM service.
    */
   public hasFeatureExportPMs(): boolean {
      return this.hasFeature("featurePMImportExport");
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a WR service.
    */
   public hasFeatureUnlimitedWRs(): boolean {
      const featureUnlimitedWRs = this.hasFeature("featureUnlimitedWRs");
      if (featureUnlimitedWRs) {
         return true;
      }

      const numberOfWorkRequestTickets =
         this.currentUser?.userInfo?.openWRChecklistCount ?? 0;
      return numberOfWorkRequestTickets < 10;
   }

   /**
   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a Part service.
    */
   public canAddParts(): boolean {
      const featureLimitedNumber = this.hasFeature("featureLimitedNumber");
      const featureUnlimitedParts = this.hasFeature("featureUnlimitedParts");
      if (!featureLimitedNumber && !featureUnlimitedParts) {
         return false;
      }
      const numberOfParts = this.manageParts
         .getParts()
         .filter((part) => Number(part.partDeleted) !== 1).size;
      return numberOfParts < 50 || featureUnlimitedParts;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a Custom Dashboard service.
    */
   public canAddCustomDashboards(): boolean {
      const featureLimitedNumber = this.hasFeature("featureLimitedNumber");
      const featureCustomDashboards = this.hasFeature("featureCustomDashboards");
      if (!featureLimitedNumber && !featureCustomDashboards) {
         return false;
      }
      const numberOfDashboards = this.manageDashboard.getDashboards().size;
      if (numberOfDashboards > 0 && !featureCustomDashboards) {
         return false;
      }
      return true;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a PO service.
    */
   public canAddPOs(): boolean {
      const featureLimitedNumber = this.hasFeature("featureLimitedNumber");
      const featureUnlimitedPOs = this.hasFeature("featureUnlimitedPOs");
      if (!featureLimitedNumber && !featureUnlimitedPOs) {
         return false;
      }
      const numberOfPOs = this.managePO.getPurchaseOrders().size;

      return numberOfPOs < 50 || featureUnlimitedPOs;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a Vendor service.
    */
   public canAddVendors(): boolean {
      const featureLimitedNumber = this.hasFeature("featureLimitedNumber");
      const featureUnlimitedVendors = this.hasFeature("featureUnlimitedVendors");
      if (!featureLimitedNumber && !featureUnlimitedVendors) {
         return false;
      }

      const numberOfVendors = this.manageVendor
         .getVendors()
         .filter((vendor) => Number(vendor.vendorDeleted) !== 1).size;

      return numberOfVendors < 10 || featureUnlimitedVendors;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a WO service.
    */
   public async hasReachedWOInstructionLimitFor(month: Moment): Promise<boolean> {
      const hasFeature = this.hasFeature("featureUnlimitedWOs");
      if (hasFeature) {
         return false;
      }

      const limit = this.hasFeature("featureLimitedWOs") ? 3 : 2;
      const limitReached = await this.checkNumberOfTasksWithInstructionsByLimit(
         limit,
         month,
      );

      return limitReached;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a PM Schedule service.
    */
   public async hasReachedPmsSchedulesLimit(): Promise<boolean> {
      await this.haveFlagsInitialized();
      if (this.hasFeature("featureUnlimitedPMs")) {
         return false;
      }
      const templatesWithSchedules = await lastValueFrom(
         this.taskTemplatesApiService.getList({
            filters: {
               nextScheduleStart: new Date(),
               excludeDeletedAssets: true,
            },
         }),
      );
      const hitPmTemplateLimit =
         templatesWithSchedules?.total && templatesWithSchedules?.total >= 4;
      return Boolean(hitPmTemplateLimit);
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a WO service.
    */
   public getWOInstructionLimit(): number {
      const hasFeature = this.hasFeature("featureUnlimitedWOs");
      if (hasFeature) {
         return Infinity;
      }

      return this.hasFeature("featureLimitedWOs") ? 3 : 2;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a Dashboard Reporting service.
    */
   public getDashboardReportingLimit(): number | undefined {
      const featureUnlimitedReporting = this.hasFeature("featureUnlimitedReporting");
      if (featureUnlimitedReporting) {
         return undefined;
      }

      return this.hasFeature("featureLimitedReporting")
         ? LIMITED_DASHBOARD_REPORTING_DAYS
         : RESTRICTED_DASHBOARD_REPORTING_DAYS;
   }

   private async checkNumberOfTasksWithInstructionsByLimit(
      numberOfLimit: number,
      month: Moment,
   ) {
      const numberOfTasksWithInstructions =
         await this.manageTask.getTaskCountWithInstructions(month);
      return numberOfTasksWithInstructions >= numberOfLimit;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a WR Portal service.
    */
   public canUseWRPortalWithMultipleLocations(
      locationIsInactive: boolean,
      locationID: number,
   ): boolean {
      if (
         (locationIsInactive || locationID === 0) &&
         !this.hasFeature("featureMultipleLocations")
      ) {
         return false;
      }

      return true;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a Task Instruction service.
    */
   public canUpdateCustomApprovalInstruction(itemTypeID: number): boolean {
      const featureCustomApprovalInstruction = this.hasFeature(
         "featureCustomApprovalInstruction",
      );
      if (
         this.itemIsPaywalledAdvancedInstructionType(itemTypeID) &&
         !featureCustomApprovalInstruction
      ) {
         return false;
      }

      return true;
   }

   /**
    * @deprecated
    * Use `FeatureFlagsService#flagMap` and move this method to a Task Instruction service.
    */
   public canUpdateStartWOStartPMInstruction(itemTypeID: number): boolean {
      const featureStartWOStartPM = this.hasFeature("featureStartWOStartPM");
      if (
         this.itemIsPaywalledStartWOStartPMInstructionType(itemTypeID) &&
         !featureStartWOStartPM
      ) {
         return false;
      }

      return true;
   }

   private itemIsPaywalledAdvancedInstructionType(itemTypeID: number): boolean {
      return [
         TaskInstructionTypeID.RequestApproval,
         TaskInstructionTypeID.VerifyLocation,
      ].includes(itemTypeID);
   }
   private itemIsPaywalledStartWOStartPMInstructionType(itemTypeID: number): boolean {
      return [TaskInstructionTypeID.StartWO, TaskInstructionTypeID.AssignPM].includes(
         itemTypeID,
      );
   }

   private createDefaultsProxy(
      featureFlags: Partial<IsFeatureEnabledMap>,
   ): IsFeatureEnabledMap {
      return new Proxy(featureFlags, {
         get(target, prop) {
            if (prop in target) {
               return Boolean(target[prop]);
            }
            console.warn(`Feature flag "${String(prop)}" not found in feature flags`);
            return false;
         },
      }) as Required<IsFeatureEnabledMap>;
   }
}
