<span class="container">
   @if (enclosed() === true) {
      <span>(</span>
   }
   <span>{{ stockUnits() | stockUnitsRounding }}</span>
   <span>&nbsp;</span>
   <unit-label [unitDisplay]="unitDisplay()" />
   @if (enclosed() === true) {
      <span>)</span>
   }
</span>
