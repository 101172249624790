export const TaskViewType_ByCost = "tasksByCost";
export const TaskViewType_ByDowntime = "tasksByDowntime";
export const TaskViewType_ByStatus = "tasksByStatus";
export const TaskViewType_ByType = "tasksByType";
export const TaskViewType_CompletedTasks = "completedTasks";
export const TaskViewType_OpenTasks = "openTasks";
export const TaskViewType_AnyTasks = "anyTasks";
export const TaskViewType_CycleCountTasks = "cycleCountTasks";
export const TaskViewType_TasksByStatusDuration = "tasksByStatusDuration";
export const TaskViewType_TasksIncludeUpcoming = "tasksIncludeUpcoming";
export const TaskViewType_UpcomingEstimatedTime = "upcomingEstimatedTime";
export const TaskViewType_TaskSchedulesCombined = "taskSchedulesCombined";
/**
 * These are related to widget display types
 */
export const TaskViewType_CompletedPlannedWOs = "completedPlannedWOs";
export const TaskViewType_CompletedUnplannedWOs = "completedUnplannedWOs";
export const TaskViewType_TotalCompletedTasks = "totalCompletedTasks";
export const TaskViewType_CompletedPMs = "completedPms";
export const TaskViewType_CompletedWorkRequests = "completedWorkRequests";
export const TaskViewType_TotalTasks = "totalTasks";
export const TaskViewType_Total = "total";
export const TaskViewType_mtbf = "mtbf";
export const TaskViewType_mttr = "mttr";
export const TaskViewType_MTBF = "MTBF";
export const TaskViewType_MTTR = "MTTR";
export const TaskViewType_DowntimeHours = "downtimeHours";

export const TaskViewType_UptimePercent = "uptimePercent";
export const TaskViewType_DowntimePercent = "downtimePercent";
export const TaskViewType_StartedVsCompleted = "startedVsCompleted";
export const TaskViewType_TotalTimeSpent = "totalTimeSpent";
export const TaskViewType_TimeSpent = "timeSpent";
/**
 * These are from the "Assets" modals that display tasks
 */
export const TaskViewType_OpenPlannedWOs = "openPlannedWOs";
export const TaskViewType_OpenUnplannedWOs = "openUnplannedWOs";
export const TaskViewType_OpenPMs = "openPms";
export const TaskViewType_OpenWorkRequests = "openWorkRequests";
export const TaskViewType_TotalOpenTasks = "totalOpenTasks";
export const TaskViewType_TotalDowntimeHours = "totalDowntimeHours";
export const TaskViewType_TotalUptimeHours = "totalUptimeHours";
export const TaskViewType_AverageDowntimeHours = "averageDowntimeHours";
export const TaskViewType_AverageUptimeHours = "averageUptimeHours";
export const TaskViewType_AverageTimeSpent = "avgTimeSpent";
export const TaskViewType_CostParts = "partsCost";
export const TaskViewType_CostLabor = "laborCost";
export const TaskViewType_CostInvoices = "invoiceCost";
export const TaskViewType_CostTotal = "operatingCost";
/**
 * These are for virtual widget display types
 */
export const VirtualTaskViewType_OnTimeWOsAndPMs = "onTimeWOsAndPMs";
export const VirtualTaskViewType_OverdueWOsAndPMs = "overdueWOsAndPMs";
export const VirtualTaskViewType_CriticalWOsAndPMs = "criticalWOsAndPMs";
export const VirtualTaskViewType_PlannedMaintenance = "plannedMaintenance";
export const VirtualTaskViewType_UnplannedMaintenance = "unplannedMaintenance";
export const VirtualTaskViewType_PlannedVsUnplannedMonthly = "plannedVsUnplannedMonthly";
export const VirtualTaskViewType_WorkRequests = "workRequests";
export const VirtualTaskViewType_mtbf = "virtual_mtbf";
export const VirtualTaskViewType_mttr = "virtual_mttr";
export const VirtualTaskViewType_downtimeCount = "downtimeCount";
export const VirtualTaskViewType_totalOperatingCost = "totalOperatingCost";
export const VirtualTaskViewType_totalPartsCost = "totalPartsCost";
export const VirtualTaskViewType_totalLaborCost = "totalLaborCost";
export const VirtualTaskViewType_totalInvoiceCost = "totalInvoiceCost";
export const VirtualTaskViewType_operatingCostsMonthlyLine = "operatingCostsMonthlyLine";
export const VirtualTaskViewType_downtimeHoursEfficiency = "downtimeHoursEfficiency";
export const VirtualTaskViewType_laborCostEfficiency = "laborCostEfficiency";
export const VirtualTaskViewType_partCostEfficiency = "partCostEfficiency";
export const VirtualTaskViewType_downtimeHoursEfficiencyLine =
   "downtimeHoursEfficiencyLine";
export const VirtualTaskViewType_laborCostEfficiencyLine = "laborCostEfficiencyLine";
export const VirtualTaskViewType_partCostEfficiencyLine = "partCostEfficiencyLine";

export const ALL_TASK_VIEW_TYPES = [
   TaskViewType_ByCost,
   TaskViewType_ByDowntime,
   TaskViewType_ByStatus,
   TaskViewType_ByType,
   TaskViewType_CompletedTasks,
   TaskViewType_TasksIncludeUpcoming,
   TaskViewType_UpcomingEstimatedTime,
   TaskViewType_OpenTasks,
   TaskViewType_AnyTasks,
   TaskViewType_CycleCountTasks,
   TaskViewType_TasksByStatusDuration,
   TaskViewType_CompletedPlannedWOs,
   TaskViewType_CompletedUnplannedWOs,
   TaskViewType_TotalCompletedTasks,
   TaskViewType_CompletedPMs,
   TaskViewType_CompletedWorkRequests,
   TaskViewType_TotalTasks,
   TaskViewType_Total,
   TaskViewType_mtbf,
   TaskViewType_mttr,
   TaskViewType_MTBF,
   TaskViewType_MTTR,
   TaskViewType_DowntimeHours,
   TaskViewType_UptimePercent,
   TaskViewType_DowntimePercent,
   TaskViewType_TaskSchedulesCombined,
   TaskViewType_TotalTimeSpent,
   TaskViewType_OpenPlannedWOs,
   TaskViewType_OpenUnplannedWOs,
   TaskViewType_OpenPMs,
   TaskViewType_OpenWorkRequests,
   TaskViewType_TotalOpenTasks,
   TaskViewType_TotalDowntimeHours,
   TaskViewType_TotalUptimeHours,
   TaskViewType_AverageDowntimeHours,
   TaskViewType_AverageUptimeHours,
   TaskViewType_AverageTimeSpent,
   TaskViewType_CostParts,
   TaskViewType_CostLabor,
   TaskViewType_CostInvoices,
   TaskViewType_CostTotal,
   TaskViewType_TimeSpent,
   VirtualTaskViewType_OnTimeWOsAndPMs,
   VirtualTaskViewType_OverdueWOsAndPMs,
   VirtualTaskViewType_CriticalWOsAndPMs,
   VirtualTaskViewType_PlannedMaintenance,
   VirtualTaskViewType_UnplannedMaintenance,
   VirtualTaskViewType_PlannedVsUnplannedMonthly,
   VirtualTaskViewType_WorkRequests,
   VirtualTaskViewType_mtbf,
   VirtualTaskViewType_mttr,
   VirtualTaskViewType_downtimeCount,
   VirtualTaskViewType_totalOperatingCost,
   VirtualTaskViewType_totalPartsCost,
   VirtualTaskViewType_totalLaborCost,
   VirtualTaskViewType_totalInvoiceCost,
   VirtualTaskViewType_operatingCostsMonthlyLine,
   VirtualTaskViewType_downtimeHoursEfficiency,
   VirtualTaskViewType_laborCostEfficiency,
   VirtualTaskViewType_partCostEfficiency,
   VirtualTaskViewType_downtimeHoursEfficiencyLine,
   VirtualTaskViewType_laborCostEfficiencyLine,
   VirtualTaskViewType_partCostEfficiencyLine,
];

export type TaskViewTypes = (typeof ALL_TASK_VIEW_TYPES)[number];

export type ColumnFilter = {
   key: string;
   inputType: string;
   displayName: string;
   dropdownOptions?: string[];
   beginDate?: Date;
   endDate?: Date;
};
