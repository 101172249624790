import { Component, computed, inject, input } from "@angular/core";
import {
   TooltipDirective,
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
} from "@limblecmms/lim-ui";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   selector: "report-task-name-cell",
   templateUrl: "./report-task-name-cell.component.html",
   styleUrls: ["./report-task-name-cell.component.scss"],
   imports: [IconComponent, TooltipDirective, LimbleHtmlDirective],
})
export class ReportTaskNameCellComponent {
   private readonly manageLang = inject(ManageLang);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);

   public task = input.required<TaskDataViewerViewModel>();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly taskLocation = computed(() => {
      return this.manageLocation.getLocation(this.task().locationID)?.locationName ?? "";
   });

   protected readonly assetVisible = computed(() => {
      const potentialAssetID = this.task().assetID;
      return potentialAssetID && potentialAssetID > 0;
   });

   protected getTaskAssetName(): string | undefined {
      const potentialAssetID = this.task()?.assetID;
      if (potentialAssetID) {
         const assetName = this.manageAsset.getAssetNameIncludeParents(potentialAssetID);
         return assetName;
      }
      return undefined;
   }

   protected popTask(): void {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: this.task().checklistID,
               editable: false,
               preview: true,
            },
         },
      };
   }

   protected viewAsset(): void {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: this.task().assetID,
            locationID: this.task().locationID,
            data: {
               restrict: false,
            },
         },
      };
   }
}
