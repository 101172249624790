import { effect, inject, Injectable } from "@angular/core";
import { filter, of, Subject, switchMap, tap } from "rxjs";
import { UnitOfMeasureApiService } from "src/app/parts/unit-of-measure/unit-of-measure-api.service";
import { UnitOfMeasureAvailabilityService } from "src/app/parts/unit-of-measure/unit-of-measure-availability.service";

@Injectable({ providedIn: "root" })
export class UnitOfMeasureRefreshService {
   private readonly isFeatureEnabled = inject(UnitOfMeasureAvailabilityService)
      .isFeatureEnabled;
   private readonly unitOfMeasureApiService = inject(UnitOfMeasureApiService);

   private readonly refreshTrigger = new Subject<void>();

   private hasRefreshed = false;

   public latest$ = this.refreshTrigger.pipe(
      filter(() => this.isFeatureEnabled() !== null),
      switchMap(() =>
         this.isFeatureEnabled() === true ? this.unitOfMeasureApiService.list() : of([]),
      ),
      tap(() => {
         this.hasRefreshed = true;
      }),
   );

   public constructor() {
      this.refreshOnFeatureEnabledSet();
   }

   public refresh(): void {
      this.refreshTrigger.next();
   }

   private refreshOnFeatureEnabledSet(): void {
      effect(
         () => {
            if (this.isFeatureEnabled() === null || this.hasRefreshed) return;

            this.refreshTrigger.next();
         },
         { allowSignalWrites: true },
      );
   }
}
