<ng-container *translate="let t">
   <lim-ui-dropdown-button [label]="t(filter().labelKey)" filterDropdown>
      <div menu>
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.AddedLogEntry)"
            [displayString]="t('ManualEntry')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.UpdatedPartName)"
            [displayString]="t('ManualUpdateName')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.UpdatedPartNumber)"
            [displayString]="t('ManualUpdateNumber')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.UpdatedPartQuantity)"
            [displayString]="t('ManualUpdateQty')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.UpdatedPartPrice)"
            [displayString]="t('ManualUpdatePrice')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.UpdatedPartLocation)"
            [displayString]="t('ManualUpdateLocation')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.UpdatedPartSupplier)"
            [displayString]="t('ManualUpdateSupplier')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.UpdatedPartStaleThreshold)"
            [displayString]="t('ManualUpdateStaleThreshold')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.UpdatedPartMaxQuantityThreshold)"
            [displayString]="t('ManualUpdatePartQtyThreshold')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.TaskUsedPart)"
            [displayString]="t('EntryFromTaskWOPM')"
         />
         <lim-ui-dropdown-text-item
            (click)="
               handleSetTypeFilter(PartLogSourceID.PartQuantityThresholdStatusChanged)
            "
            [displayString]="t('PartQtyStatusChange')"
         />
         <lim-ui-dropdown-text-item
            (click)="handleSetTypeFilter(PartLogSourceID.PartImportedOrBulkUpdated)"
            [displayString]="t('Import')"
         />

         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-clear-filter-item (click)="handleClear()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
