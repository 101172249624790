<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="lim-ui-form-column">
            <div class="lim-ui-form-group">
               <label>
                  {{ lang().HowMuchDowntimeDidThisTaskCause }}
               </label>
            </div>
            <div class="lim-ui-form-group" [ngClass]="{ 'has-error': hoursError }">
               <label>{{ lang().Hours }}</label>
               <div class="custom-number-input">
                  <lim-ui-icon-button
                     icon="minus"
                     (click)="
                        minusHours();
                        errorMsg = '';
                        hoursError = false;
                        minutesError = false
                     "
                  />
                  <input type="text" [(ngModel)]="hours" />
                  <lim-ui-icon-button
                     icon="plus"
                     (click)="
                        hours = hours * 1 + 1;
                        errorMsg = '';
                        hoursError = false;
                        minutesError = false
                     "
                  />
               </div>
            </div>

            <div class="lim-ui-form-group" [ngClass]="{ 'has-error': minutesError }">
               <label>{{ lang().Minutes }}</label>
               <div class="custom-number-input">
                  <lim-ui-icon-button
                     (click)="
                        minusMinutes();
                        errorMsg = '';
                        hoursError = false;
                        minutesError = false
                     "
                     icon="minus"
                  />
                  <input type="text" [(ngModel)]="minutes" />
                  <lim-ui-icon-button
                     (click)="
                        minutes = minutes * 1 + 1;
                        errorMsg = '';
                        hoursError = false;
                        minutesError = false
                     "
                     icon="plus"
                  />
               </div>
            </div>

            <div [hidden]="errorMsg === ''">
               <div class="alert alert-dismissable alert-danger">
                  <strong>{{ lang().Warning }}!</strong>
                  <span [limbleHtml]="errorMsg"></span>
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Change"
      (successClick)="submit()"
      dataLogSubmitLabel="completedTask-submitChangeDowntime"
   />
</lim-ui-modal>
