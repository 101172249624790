import { inject, Injectable } from "@angular/core";
import type { LoggedTimeViewModel } from "src/app/tasks/components/shared/components/logged-time-data-viewer/logged-time-data-viewer.model";
import type {
   TaskCommentViewModel,
   TaskDataViewerViewModel,
} from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskCommentViewModelBuilderService } from "src/app/tasks/components/shared/services/task-view-model-factory/builders/task-comment-view-model-builder.service";
import { TaskDataViewerViewModelBuilderService } from "src/app/tasks/components/shared/services/task-view-model-factory/builders/task-data-viewer-view-model-builder.service";
import { TaskLoggedTimeViewModelBuilderService } from "src/app/tasks/components/shared/services/task-view-model-factory/builders/task-logged-time-view-model-builder.service";
import { TasksSchedulesCombinedViewModelBuilderService } from "src/app/tasks/components/shared/services/task-view-model-factory/builders/tasks-schedules-combined-view-model-builder.service";
import type {
   TaskEntity,
   TaskLoggedTime,
} from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import type { TasksSchedulesCombinedEntity } from "src/app/tasks/components/shared/services/tasks-schedules-combined-api/tasks-schedules-combined.models";
import type { Task } from "src/app/tasks/types/task.types";

/**
 * This service is the entry point for accessing various ViewModels
 * that is mapped from TaskEntity which comes from jit/tasks endpoint
 */
@Injectable({
   providedIn: "root",
})
export class TaskViewModelFactoryService {
   private readonly taskDataViewerViewModelBuilderService = inject(
      TaskDataViewerViewModelBuilderService,
   );
   private readonly taskCommentViewModelBuilderService = inject(
      TaskCommentViewModelBuilderService,
   );

   private readonly tasksSchedulesCombinedBuilder = inject(
      TasksSchedulesCombinedViewModelBuilderService,
   );
   private readonly taskLoggedTimeViewModelBuilderService = inject(
      TaskLoggedTimeViewModelBuilderService,
   );

   public getTaskDataViewerViewModel(task: TaskEntity): TaskDataViewerViewModel {
      return this.taskDataViewerViewModelBuilderService.buildTaskDataViewerViewModel(
         task,
      );
   }

   public getTaskLocalViewModel(task: Task): TaskDataViewerViewModel {
      return this.taskDataViewerViewModelBuilderService.buildTaskDataViewerViewModelWithLocalTask(
         task,
      );
   }

   public getViewModelList(tasks: TaskEntity[]): TaskDataViewerViewModel[] {
      return tasks.map((task) => this.getTaskDataViewerViewModel(task));
   }

   public getTaskCommentsViewModel(task: TaskEntity): TaskCommentViewModel[] {
      return this.taskCommentViewModelBuilderService.buildTaskCommentsViewModel(task);
   }

   public getTaskSchedulesCombinedList(
      entities: TasksSchedulesCombinedEntity[] | undefined,
   ): TaskDataViewerViewModel[] {
      if (!entities) {
         return [];
      }
      return entities.map((entity) => this.tasksSchedulesCombinedBuilder.build(entity));
   }

   public getLoggedTimeViewModelList(entities: TaskLoggedTime[]): LoggedTimeViewModel[] {
      return entities.map((entity) =>
         this.taskLoggedTimeViewModelBuilderService.buildTaskLoggedTimeViewModel(entity),
      );
   }
}
