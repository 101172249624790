<div class="lim-ui-list-item option-wrapper" [class.viewOnly]="isViewOnlyMode">
   @if (treeBranch) {
      @if (isViewOnlyMode) {
         <span>
            {{ option.order }}
         </span>
      } @else {
         <div [limbleTreeDraggable]="treeBranch" class="drag-handle">
            <lim-ui-icon class="show-on-hover" icon="arrowsUpDownLeftRightRegular" />
            <span>
               {{ option.order }}
            </span>
         </div>
      }
   }
   @if (updateFieldDropdownOptions !== undefined) {
      <div
         class="updateFieldHolder exitOnEnterKeyPress editUnderline option-name"
         (afterEdit)="updateFieldDropdownOptions(options)"
         [limbleContentEditable]="!isViewOnlyMode"
         [(ngModel)]="option.name"
         attr.placeholder="{{ lang().Name }}"
         stripHtml="true"
         tabindex="{{ option.order }}"
      ></div>
   }
   @if (deleteOption !== undefined && !isViewOnlyMode) {
      <lim-ui-minimal-icon-button
         [superMinimal]="true"
         icon="trashCanRegular"
         iconColor="danger"
         (click)="deleteOption(option)"
         [dataLogLabel]="
            'manage' + workflowLabel + '-storeAddCustomField-clickDeleteDropdownOption'
         "
      />
   }
</div>
