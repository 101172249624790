import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   CardComponent,
   ModalService,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { Bill } from "src/app/purchasing/types/bill.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { CommentEmailDefaults } from "src/app/tasks/components/commentTemplateModal/commentEmailDefaults.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskUser } from "src/app/tasks/types/task-user/task-user.types";
import type { Task } from "src/app/tasks/types/task.types";
import { AddUserToNotifications } from "src/app/users/components/addUserToNotificationsModal/addUserToNotifications.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "who-gets-note-notifications",
   templateUrl: "./whoGetsNoteNotifications.modal.component.html",
   styleUrls: ["./whoGetsNoteNotifications.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      CardComponent,
      MinimalIconButtonComponent,
      ModalFooterComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
   ],
})
export class WhoGetsNoteNotifications implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public errorMsg;
   public userID;
   public CID;
   public checklistID: number | undefined;
   public task: Task | undefined;
   public templateCred;
   public locationID;
   public newMentionedList;
   public purchaseOrderID: number | undefined;
   public purchaseOrder: PurchaseOrder | undefined;
   public billID: number | undefined;
   public bill: Bill | undefined;
   public recips;
   public allUsers: Lookup<"userID", TaskUser> = new Lookup("userID");

   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly managePO = inject(ManagePO);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.allUsers = this.manageTask.getAllUsers();

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.errorMsg = "";

      this.userID = this.manageUser.getCurrentUser().gUserID;

      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.checklistID = this.resolve.data.checklistID;
      this.purchaseOrderID = this.resolve.data.purchaseOrderID;
      this.billID = this.resolve.data.billID;

      this.newMentionedList = this.resolve.data.newMentionedList;

      if (this.checklistID) {
         this.manageTask.getTask(this.resolve.data.checklistID).then((task) => {
            this.task = task;
            if (!this.task) {
               return;
            }

            this.locationID = this.task.locationID;
            //check if the user can edit the email template
            if (
               this.credService.isAuthorized(
                  this.task.locationID,
                  this.credService.Permissions.RemoveTaskComments,
               )
            ) {
               this.templateCred = true;
            } else {
               this.templateCred = false;
            }
         });
      }

      if (this.purchaseOrderID) {
         this.purchaseOrder = this.managePO.getPurchaseOrder(this.purchaseOrderID);
         this.locationID = this.purchaseOrder?.locationID;
      }

      if (this.billID) {
         this.bill = this.managePO.getBill(this.billID);
         this.locationID = this.bill?.locationID;
      }

      this.whoToNotify();
   }
   removeManuallyAddedCommentNotification = (userID) => {
      if (this.checklistID) {
         this.manageTask
            .removeUserFromChecklistNotifications(userID, this.checklistID)
            .then((answer) => {
               if (answer.data.success == true) {
                  this.whoToNotify();
               }
               this.displayMessage(
                  answer.data.success,
                  this.lang().UserRemovedFromNotifications,
               );
            });
      }
      if (this.purchaseOrderID) {
         this.managePO
            .removeUserFromPONotifications(userID, this.purchaseOrderID)
            .then((answer) => {
               if (answer.data.success == true) {
                  this.whoToNotify();
               }
               this.displayMessage(
                  answer.data.success,
                  this.lang().UserRemovedFromNotifications,
               );
            });
      }
      if (this.billID) {
         this.managePO
            .removeUserFromBillNotifications(userID, this.billID)
            .then((answer) => {
               if (answer.data.success == true) {
                  this.whoToNotify();
               }
               this.displayMessage(
                  answer.data.success,
                  this.lang().UserRemovedFromNotifications,
               );
            });
      }
   };

   protected removeManuallyAddedCommentNotificationEmailStr(emailStr: string): void {
      if (this.checklistID) {
         this.manageTask
            .removeManuallyAddedCommentNotificationEmailStr(emailStr, this.checklistID)
            .then((answer) => {
               if (answer.data.success == true) {
                  this.whoToNotify();
               }
               this.displayMessage(answer.data.success, this.lang().successMsg);
            });
      }
      if (this.purchaseOrderID) {
         this.managePO
            .removeManualPOCommentEmail(emailStr, this.purchaseOrderID)
            .then((answer) => {
               if (answer.data.success == true) {
                  this.whoToNotify();
               }
               this.displayMessage(answer.data.success, this.lang().successMsg);
            });
      }
      if (this.billID) {
         this.managePO
            .removeManualBillCommentEmail(emailStr, this.billID)
            .then((answer) => {
               if (answer.data.success == true) {
                  this.whoToNotify();
               }
               this.displayMessage(answer.data.success, this.lang().successMsg);
            });
      }
   }

   close = () => {
      this.modalInstance.close(0);
   };

   whoToNotify = () => {
      if (this.checklistID) {
         this.manageTask
            .getWhoWillReceiveTaskNotifications(this.checklistID)
            .then((answer) => {
               if (answer.data.success == true) {
                  const temp: any = [];
                  for (const key in answer.data.recips) {
                     temp.push(answer.data.recips[key]);
                  }
                  this.recips = temp;

                  this.newMentionedList.forEach((item) => {
                     if (
                        !item.tagDescription &&
                        this.recips.findIndex((rec) => item.userID === rec.userID) === -1
                     ) {
                        this.recips.push({
                           reason: "mentioned in this comment",
                           ...item,
                        });
                     }
                  });
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }
      if (this.purchaseOrderID) {
         this.managePO
            .getWhoWillReceivePONotifications(this.purchaseOrderID)
            .then((result) => {
               if (result.data.success === true) {
                  const temp: any = [];
                  for (const key in result.data.recips) {
                     temp.push(result.data.recips[key]);
                  }
                  this.recips = temp;
                  this.newMentionedList.forEach((item) => {
                     if (
                        !item.tagDescription &&
                        this.recips.findIndex((rec) => item.userID === rec.userID) === -1
                     ) {
                        this.recips.push({
                           reason: "mentioned in this comment",
                           ...item,
                        });
                     }
                  });
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }
      if (this.billID) {
         this.managePO.getWhoWillReceiveBillNotifications(this.billID).then((result) => {
            if (result.data.success === true) {
               const temp: any = [];
               for (const key in result.data.recips) {
                  temp.push(result.data.recips[key]);
               }
               this.recips = temp;
               this.newMentionedList.forEach((item) => {
                  if (
                     !item.tagDescription &&
                     this.recips.findIndex((rec) => item.userID === rec.userID) === -1
                  ) {
                     this.recips.push({
                        reason: "mentioned in this comment",
                        ...item,
                     });
                  }
               });
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      }
   };

   addPerson = () => {
      const answerUserProfile = this.manageUser.getUsersAndProfilesAtLocation(
         this.locationID,
      ) as any;
      const instance = this.modalService.open(AddUserToNotifications);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().AddSomeoneToNotifications,
            users: answerUserProfile.data.users,
            profiles: answerUserProfile.data.profiles,
            userProfiles: answerUserProfile.data.userProfiles,
            locationID: this.task?.locationID,
         },
      };

      instance.result.then((result) => {
         if (result.length > 0) {
            result.forEach((rst) => {
               if (rst.userID > 0) {
                  //if the person to be notified was a user
                  if (this.checklistID) {
                     //if comment is on a task
                     this.manageTask
                        .addUserToChecklistNotifications(rst.userID, this.checklistID)
                        .then((answer) => {
                           this.displayMessage(
                              answer.data.success,
                              this.lang().UserAddedToNotifications,
                              answer.data.reason,
                           );
                        });
                  }
                  if (this.purchaseOrderID) {
                     //if comment is on a PO
                     this.managePO
                        .addUserToPONotifications(rst.userID, this.purchaseOrderID)
                        .then((answer) => {
                           this.displayMessage(
                              answer.data.success,
                              this.lang().UserAddedToNotifications,
                              answer.data.reason,
                           );
                        });
                  }
                  if (this.billID) {
                     //if comment is on a PR
                     this.managePO
                        .addUserToBillNotifications(rst.userID, this.billID)
                        .then((answer) => {
                           this.displayMessage(
                              answer.data.success,
                              this.lang().UserAddedToNotifications,
                              answer.data.reason,
                           );
                        });
                  }
               } else if (rst.emailStr.length > 0) {
                  //if the person to be notified was an email address
                  if (this.checklistID) {
                     //if comment is on a task
                     this.manageTask
                        .addEmailStrToChecklistNotifications(
                           rst.emailStr,
                           this.checklistID,
                        )
                        .then((answer) => {
                           this.displayMessage(
                              answer.data.success,
                              this.lang().UserAddedToNotifications,
                              answer.data.reason,
                           );
                        });
                  }
                  if (this.purchaseOrderID) {
                     //if comment is on a PO
                     this.managePO
                        .addEmailStrToPONotifications(rst.emailStr, this.purchaseOrderID)
                        .then((answer) => {
                           this.displayMessage(
                              answer.data.success,
                              this.lang().UserAddedToNotifications,
                              answer.data.reason,
                           );
                        });
                  }
                  if (this.billID) {
                     //if comment is on a PR
                     this.managePO
                        .addEmailStrToBillNotifications(rst.emailStr, this.billID)
                        .then((answer) => {
                           this.displayMessage(
                              answer.data.success,
                              this.lang().UserAddedToNotifications,
                              answer.data.reason,
                           );
                        });
                  }
               }
            });
         }
      });
   };

   displayMessage = (success, message, reason?) => {
      if (success == true) {
         this.alertService.addAlert(message, "success", 2000);
         this.whoToNotify();
      } else {
         if (reason === "alreadyExists") {
            this.alertService.addAlert(
               this.lang().AddUserToNotificationError,
               "warning",
               2000,
            );
            return;
         }
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   };

   editCommentTemplate = () => {
      const instance = this.modalService.open(CommentEmailDefaults);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            title: this.lang().SetNotificationEmailDefaults,
            locationID: this.locationID,
         },
      };
   };
}
