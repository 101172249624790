@if (!task.isCompleted) {
   <span class="due-date-sub-container allowOffline" (click)="changeDueDate($event)">
      <!-- Calendar icon-->
      <lim-ui-icon
         class="lim-ui-mobile-table-column-label calendar-icon"
         icon="calendar"
      />

      <!-- The date -->
      <span class="date-text" [ngClass]="{ cursor: canChangeDueDate }">{{
         task.checklistDueDateDisplay
      }}</span>

      <span class="days-due-badge">
         @if (task.daysInfo && task.daysInfo.exactDays !== null) {
            <span
               class="badge ml-1 mr-2"
               [ngClass]="{
                  'badge-danger':
                     task.checklistColor !== undefined &&
                     (task.checklistColor == 3 || task.checklistColor == 0),
                  'badge-orange':
                     task.checklistColor !== undefined && task.checklistColor == 2,
                  'badge-success':
                     task.checklistColor !== undefined && task.checklistColor == 1,
               }"
               placement="top"
               limUiTooltip="{{ task.daysInfo.days + ' ' + task.daysInfo.daysMsg }}"
            >
               {{ task.daysInfo.exactDays }}
            </span>
         }
      </span>

      @if (!printOnly) {
         <lim-ui-minimal-icon-button
            [superMinimal]="true"
            icon="envelopeRegular"
            (click)="emailReminder($event)"
            limUiTooltip="{{ lang().sendReminder }}"
            placement="top"
            class="lim-ui-show-on-desktop"
         />

         @if (canDeferTask) {
            <lim-ui-minimal-icon-button
               icon="rotateRight"
               (click)="deferTask($event)"
               limUiTooltip="{{ lang().delay }}"
               placement="top"
               [superMinimal]="true"
            />
         }
      }

      @if (task.checklistEstTime !== undefined && task.checklistEstTime > 0) {
         <span placement="top" limUiTooltip="{{ lang().estTime }}">
            {{ task.checklistEstTime / 60 / 60 | betterNumber: "1.2-2" }}
            {{ lang().hrs }}
         </span>
      }
   </span>
}
@if (task.isCompleted) {
   @if (task.dueDate !== undefined) {
      <div
         [ngStyle]="{
            'text-align': column?.textAlign ?? 'left',
         }"
      >
         <lim-ui-icon class="lim-ui-mobile-table-column-label" icon="calendar" />
         {{ task.dueDate.getTime() | betterDate: "date" }}
      </div>
   }
}
