import { KeyValuePipe, NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { computed, inject, Component } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { SeasonsService } from "src/app/schedules/seasons/seasons.service";
import { SeasonsConfig } from "src/app/schedules/seasons/seasonsConfigModal/seasonsConfig.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import type { SeasonType } from "src/app/shared/types/general.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import defaultHolidays from "src/root/holidays.json";

@Component({
   selector: "seasons-select",
   templateUrl: "./seasonsSelect.modal.component.html",
   styleUrls: ["./seasonsSelect.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      SecondaryButtonComponent,
      PanelComponent,
      NgClass,
      IconComponent,
      BasicModalFooterComponent,
      KeyValuePipe,
   ],
})
export class SeasonsSelect implements OnInit {
   public title: string = "";
   public message: string = "";
   public seasonType: SeasonType = "season";
   public seasonsList: Map<number, any> = new Map();
   public dates: Map<
      number,
      { name: string; number: number; days: number; daysArr: Array<any> }
   > = new Map();
   public readonly superUser: boolean;
   public noSeasonOption: boolean = false;
   public loading: boolean = false;

   private resolve;
   private modalInstance;
   private userClicks: number = 0;
   private selectedSeasons;
   private readonly errorMsg: string;
   private currentRecurrence!: Recurrence;
   public successString: string = "";

   private readonly paramsService = inject(ParamsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly credService = inject(CredService);
   private readonly seasonsService = inject(SeasonsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
      this.errorMsg = this.lang().PleaseChooseAtLeastOneOption;
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;

      this.selectedSeasons = data.selectedSeasons || [];

      this.seasonType = data.seasonType;

      this.title = this.seasonsService.getSeasonTypeModalTitle(this.seasonType);

      this.currentRecurrence = data.currentRecurrence;

      if (!this.currentRecurrence) {
         throw new Error("No current recurrence was passed to the seasons select modal");
      }

      const whatYouCanEdit: string =
         this.seasonType == "holiday" ? this.lang().Holidays : this.lang().Seasons;
      this.successString = `${this.lang().Edit} ${whatYouCanEdit}`;
      this.getData();
   }

   private getData() {
      this.loading = true;
      this.dates = this.manageSchedule.getDateInfo();

      // Get the relevant seasons
      this.manageSchedule.getSeasons(this.seasonType).then((answer) => {
         this.loading = false;
         if (!answer.data.success || answer.data.seasons === undefined) {
            this.alertService.addAlert(
               this.lang().CouldntGetSeasonsErrMsg,
               "danger",
               6000,
            );
            return;
         }
         const seasons = answer.data.seasons;

         // create a lookup
         this.seasonsList = seasons.reduce((lookup, season) => {
            lookup.set(season.seasonID, season);
            return lookup;
         }, new Map());

         this.getDisplayStrs();
         this.seasonsList = this.sortSeasonsByDate();

         // Highlight the currently selected seasons
         for (const season of this.selectedSeasons) {
            this.seasonsList.set(season.seasonID, {
               ...this.seasonsList.get(season.seasonID),
               selected: true,
            });
            this.userClicks++;
         }
      });

      // Determine the modal message
      this.getMessage();
   }

   private getDisplayStrs() {
      for (const season of this.seasonsList.values()) {
         if (!season.defaultHolidayID) {
            this.manageSchedule.getCustomSeasonDateStr(season, this.dates);
         }

         if (season.defaultHolidayID) {
            season.static = defaultHolidays[season.defaultHolidayID].static;
            // Grab the dates info for the holiday so we can figure out what should display for the date
            season.dates = defaultHolidays[season.defaultHolidayID].dates;
            this.manageSchedule.getDefaultHolidayDateStr(season, this.seasonsList);
         }

         this.manageSchedule.getCreatedStr(season);
      }
   }

   private getMessage() {
      if (this.seasonType === "season") {
         this.message = this.lang().chooseSeasonsMsg;
      }

      if (this.seasonType === "holiday") {
         this.message = this.lang().chooseHolidaysMsg;
      }
   }

   public deselectAll() {
      for (const season of this.seasonsList.values()) {
         season.selected = false;
      }
   }

   private checkResetUserClicks() {
      const seasonsArr = Array.from(this.seasonsList.values());
      this.userClicks = this.manageUtil.checkResetUserClicks(seasonsArr, this.userClicks);
   }

   public focusSeason(season) {
      this.noSeasonOption = false;

      if (season.selected) {
         if (this.userClicks <= 0) {
            //only one is selected so they meant to double tap it to select
            this.selectSeasons();
            return;
         }
      }
      this.userClicks++;
      this.checkResetUserClicks();

      season.selected = !season.selected;
   }

   public focusNoSeasonOption() {
      this.deselectAll();

      if (this.noSeasonOption) {
         if (this.userClicks <= 0) {
            //only one is selected so they meant to double tap it to select
            this.selectSeasons();
            return;
         }
      }

      this.noSeasonOption = true;
   }

   public close() {
      this.modalInstance.close(0);
   }

   public selectSeasons() {
      const pickedSeasons: any = [];
      if (this.noSeasonOption) {
         this.modalInstance.close([]);
         return;
      }

      for (const season of this.seasonsList.values()) {
         if (season.selected === true) {
            pickedSeasons.push(season);
         }
      }

      if (pickedSeasons.length > 0 || this.noSeasonOption) {
         this.modalInstance.close(pickedSeasons);
      } else {
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
      }
   }

   public openSeasonsConfig() {
      let title = "";

      if (this.seasonType === "season") {
         title = `${this.lang().Configure} ${this.lang().Seasons}`;
      }

      if (this.seasonType === "holiday") {
         title = `${this.lang().Configure} ${this.lang().Holidays}`;
      }

      const instance = this.modalService.open(SeasonsConfig);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               title: title,
               seasonType: this.seasonType,
               errorMsg: this.errorMsg,
            },
         },
      };

      instance.result.then((result) => {
         if (result !== 1) {
            return;
         }
         // If they edited/added/deleted then we need to grab the new data
         this.manageSchedule
            .getRecurrenceAssociatedSeasons(Number(this.currentRecurrence.reoccurID))
            .then((answer) => {
               if (!answer.data.success) {
                  return;
               }
               // Have to do this to make sure the selected seasons stay in sync if the user deletes a season that was previously selected
               this.selectedSeasons = this.seasonsService.getSelectedSeasonsByType(
                  this.seasonType,
                  answer.data.seasons,
               );

               this.getData();
               this.seasonsService.seasonsChanged$.next(null);
            });
      });
   }

   private sortSeasonsByDate() {
      return new Map(
         [...this.seasonsList].sort((item1, item2) =>
            String(item1[1].dateOrder).localeCompare(item2[1].dateOrder, undefined, {
               numeric: true,
            }),
         ),
      );
   }

   // In order to turn off the default keyvalue pipe sorting of a Map, the property has to be set to a function that returns 0
   // Just using 0 instead of a function gives a ts TypeError.
   public returnZero() {
      return 0;
   }
}
