@if (part) {
   <div class="purchasing-tab-items">
      @if (isUnitOfMeasureVisible() && part) {
         <part-purchasables-list [part]="part" />
      }
      <lim-ui-panel [table]="true" [noSidePadding]="true">
         <div class="part-purchase-orders-panel-header">
            <span class="text-with-button">
               <span class="panel-text">
                  <lim-ui-icon icon="filePowerpoint" />
                  {{ lang().AssociatedPOs }}
               </span>

               <lim-ui-primary-button icon="plus" (click)="createAPurchaseOrder()">
                  {{ lang().AddAPO }}
               </lim-ui-primary-button>
            </span>

            <lim-ui-search-all-wrapper>
               <lim-ui-search-box
                  searchBox
                  [(searchVal)]="search"
                  (searchValChange)="searchPurchaseOrders()"
                  [placeholder]="lang().Search"
               />
            </lim-ui-search-all-wrapper>
         </div>

         <purchase-order-list
            [purchaseOrders]="purchaseOrders"
            [searchHints]="searchHints"
            [partID]="partID"
         />
      </lim-ui-panel>
   </div>
}
