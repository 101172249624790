import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { firstValueFrom } from "rxjs";
import type { AssetTemplateField } from "src/app/assets/services/asset-field.types";
import { AssetTemplateFieldService } from "src/app/assets/services/asset-template-field.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "pick-asset-info-to-link",
   templateUrl: "./pickAssetInfoToLink.modal.component.html",
   styleUrls: ["./pickAssetInfoToLink.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      NgClass,
      IconComponent,
      BasicModalFooterComponent,
      TooltipDirective,
   ],
})
export class PickAssetInfoToLink implements OnInit {
   public title;
   public message;
   public currencySymbol;
   public fields: Lookup<"fieldID", AssetField> | undefined;
   public fieldView: Lookup<
      "fieldID",
      {
         fieldID: number;
         selected: boolean;
         fieldTypeID: number;
         fieldTypeIcon: any;
         fieldName: string;
         assetID: number;
         lockedDefault: number;
      }
   >;
   public itemTypeID;
   public fieldTypeStr;
   public assetName;
   public data;
   public errorMsg;
   public resolve;
   public modalInstance;
   public chk;
   public item;

   protected templateFieldsMap: Map<number, AssetTemplateField> = new Map();

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly featureFlagService = inject(FeatureFlagService);
   private readonly assetTemplateFieldService = inject(AssetTemplateFieldService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.fieldView = new Lookup("fieldID");
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.data = this.resolve.data;
      this.chk = this.data.chk;
      this.item = this.data.item;

      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }

      this.setupFields();

      this.itemTypeID = this.data.item.itemTypeID;

      if (this.itemTypeID == TaskInstructionTypeID.TextBox) {
         this.fieldTypeStr = "Text";
      } else if (this.itemTypeID == TaskInstructionTypeID.Number) {
         this.fieldTypeStr = "Number";
      } else if (this.itemTypeID == TaskInstructionTypeID.DatePicker) {
         this.fieldTypeStr = "Date Picker";
      } else if (this.itemTypeID == TaskInstructionTypeID.Note) {
         this.fieldTypeStr = "Dropdown";
      }

      this.errorMsg = false;
   }

   private async setupFields(): Promise<void> {
      if (this.data.assetID) {
         const asset = this.manageAsset.getAsset(this.data.assetID);

         assert(asset);
         const fields = asset.assetValueIDs
            .filter((valueID) => {
               const fieldValue = this.manageAsset.getFieldValue(valueID);
               if (fieldValue === undefined) return false;
               const field = this.manageAsset.getField(fieldValue.fieldID);
               if (field === undefined) return false;
               return true;
            })
            .map((valueID) => {
               const fieldValue = this.manageAsset.getFieldValue(valueID);
               assert(fieldValue);
               const field = this.manageAsset.getField(fieldValue.fieldID);
               assert(field);
               return field;
            });
         const fieldLookup: Lookup<"fieldID", AssetField> = new Lookup("fieldID", fields);

         this.fields = this.filterFieldsWhenLinking(
            fieldLookup,
            Number(this.data.item.itemTypeID),
         );
         this.assetName = asset.assetName;
      }

      /**
       * Show location fields and template fields
       * when "use the asset the task belongs to" is selected
       */
      if (this.data.selectFromAllFieldsAtLocation) {
         //pull all the fields on assets at this location
         const allFields = this.manageAsset
            .getFields()
            .filter((field) => field.locationID === this.chk.locationID);
         this.fields = this.filterFieldsWhenLinking(
            allFields,
            Number(this.data.item.itemTypeID),
         );

         await this.addTemplateFields();
      }

      if (!this.fields) {
         this.fields = new Lookup("fieldID");
      }

      this.addFieldsToFieldView();

      //resets to defaults including what is currently selected.
      for (const field of this.fieldView) {
         if (field.fieldID == this.data.item.fieldIDForLink) {
            field.selected = true;
         } else {
            field.selected = false;
         }
      }
   }

   selectField = (field) => {
      if (field.lockedDefault) {
         return;
      }
      if (field.selected == true) {
         //covers if they double click it.
         this.submit();
      }

      for (const fieldItem of this.fieldView) {
         fieldItem.selected = false;
      }

      field.selected = !field.selected;
   };

   private addFieldsToFieldView(): void {
      assert(this.fields);
      for (const field of this.fields) {
         const fieldType = this.manageAsset.getFieldType(field.fieldTypeID);
         assert(fieldType);
         this.fieldView.set(field.fieldID, {
            fieldID: field.fieldID,
            selected: false,
            fieldTypeID: field.fieldTypeID,
            fieldTypeIcon: fieldType.fieldTypeIcon,
            fieldName: field.fieldName,
            assetID: this.data.assetID ?? 0,
            lockedDefault: field.lockedDefault,
         });
      }
   }

   private async addTemplateFields(): Promise<void> {
      const templateFieldsMap = await this.getTemplateFieldsMap();
      this.templateFieldsMap = templateFieldsMap;
      // Convert template fields to AssetField type
      const templateFields: AssetField[] = Array.from(templateFieldsMap.values()).map(
         (templateField) => {
            const field: AssetField = {
               ...templateField,
               locationID: 0,
            };
            return field;
         },
      );

      // Initialize this.fields if it doesn't exist
      if (!this.fields) {
         this.fields = new Lookup("fieldID");
      }

      // Add template fields to existing fields
      templateFields.forEach((field) => this.fields?.set(field.fieldID, field));
   }

   private async getTemplateFieldsMap(): Promise<Map<number, AssetTemplateField>> {
      const isAssetTemplatesEnabled = await this.legacyLaunchFlagsService.isEnabled(
         Flags.ASSET_TEMPLATES,
      );
      const hasFeatureAssetTemplates = this.featureFlagService
         .featureSet()
         ?.has("assetTemplates");

      if (!isAssetTemplatesEnabled || !hasFeatureAssetTemplates) {
         return new Map<number, AssetTemplateField>();
      }

      let fieldsMap: Map<number, AssetTemplateField> = new Map<
         number,
         AssetTemplateField
      >();
      try {
         fieldsMap = await firstValueFrom(
            this.assetTemplateFieldService.getTemplateFieldsMap(),
         );
      } catch (error) {
         console.error("Failed to retrieve asset template fields");
         this.errorMsg = this.lang().Error;
         throw error;
      }

      return fieldsMap;
   }

   close = () => {
      this.modalInstance.close(false);
      for (const field of this.fieldView) {
         field.selected = false;
      }
   };

   submit = () => {
      this.errorMsg = false;
      let result: any = "empty";

      for (const field of this.fieldView) {
         if (field.selected) {
            result = field;
         }
      }

      const assetField = this.manageAsset.getField(result.fieldID);
      if (assetField?.lockedDefault) {
         this.errorMsg = this.lang().FieldIsLockedAndCannotBeLinked;
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
         return;
      }

      //resets
      for (const field of this.fieldView) {
         field.selected = false;
      }
      if (result === "empty") {
         this.errorMsg = this.lang().PleaseSelectAFieldForLinkingResponse;
         if (this.errorMsg !== "") {
            this.alertService.addAlert(this.errorMsg, "warning", 6000);
         }
         return;
      }
      this.modalInstance.close(result);
   };

   private filterFieldsWhenLinking(
      fields: Lookup<"fieldID", AssetField>,
      itemTypeID: number,
   ): Lookup<"fieldID", AssetField> | undefined {
      /** maps itemTypeID to a list of fieldTypeIDs */
      const filterMap = {
         2: [1, 7],
         3: [1],
         4: [1, 7],
         5: [2],
         13: [5, 6],
      };
      if (!(itemTypeID in filterMap)) {
         return undefined;
      }
      const allowedFieldTypeIDs: Array<number> = filterMap[itemTypeID];
      return fields.filter((field) => allowedFieldTypeIDs.includes(field.fieldTypeID));
   }
}
