import { Component, inject, signal } from "@angular/core";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "user-filter",
   imports: [
      DropdownButtonComponent,
      DropdownClearFilterItemComponent,
      DropdownDividerComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      SearchBoxComponent,
      TranslateDirective,
   ],
   templateUrl: "./user-filter.component.html",
   styleUrls: ["./user-filter.component.scss"],
})
export class UserFilterComponent extends BaseFilterComponent {
   private readonly manageUser = inject(ManageUser);

   protected user?: string | undefined;

   /**
    * This is a label that is shown whenever the filter is active to indicate what was selected
    */
   protected activeFilterLabel?: string | undefined;

   public users = signal<Array<any>>([]);
   public usersDropdown = signal<Array<any>>([]);
   public searchUsers = signal<string>("");

   public constructor() {
      super();
      this.users.set(this.manageUser.getUsers());
      this.buildUsersDropdown();
   }

   public handleSetUser(user) {
      if (!user) return;
      this.user = user.userID;

      this.activeFilterLabel = `${this.translateService.instant("User")} - ${user.userFullName}`;
      const filter = { [this.filter().key]: user.userID };
      this.setFilter(filter, this.activeFilterLabel);
   }

   public handleSearch(searchText: string) {
      this.searchUsers.set(searchText);
      this.buildUsersDropdown();
   }

   private buildUsersDropdown(): void {
      let usersDropdown = this.users();
      if (this.searchUsers().length > 0) {
         usersDropdown = usersDropdown.filter((user) =>
            user.userFullName.toLowerCase().includes(this.searchUsers().toLowerCase()),
         );
      }
      assert(usersDropdown !== undefined);
      this.usersDropdown.set(orderBy(usersDropdown, "userFullName"));
   }

   public handleClear() {
      this.user = undefined;
      this.activeFilterLabel = undefined;
      this.remove.emit(this.filter());
   }

   private setFilter(value, activeLabel: string): void {
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value,
      });
   }
}
