import type { OnInit } from "@angular/core";
import { inject, Component, Input, forwardRef, computed } from "@angular/core";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { finalize, take } from "rxjs";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import {
   type AssetVendorAssociation,
   AssetVendorAssociationService,
} from "src/app/assets/services/asset-vendor-association.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { Location } from "src/app/locations/types/location.types";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { AssociationType } from "src/app/shared/types/association.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

type AssetInfo = Pick<AssetVendorAssociation, "assetID" | "associationType"> &
   Pick<Asset, "assetName"> &
   Pick<Location, "locationName">;

@Component({
   selector: "vendor-assets",
   templateUrl: "./vendorAssets.wrapper.component.html",
   styleUrls: ["./vendorAssets.wrapper.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      PrimaryButtonComponent,
      LimbleHtmlDirective,
      forwardRef(() => AssetParentList),
      MinimalIconButtonComponent,
      TooltipDirective,
      NgxSkeletonLoaderModule,
      OrderByPipe,
   ],
})
export class VendorAssets implements OnInit {
   @Input() public vendorID: number | undefined;
   @Input() public restrict: boolean | undefined;
   public vendor: Vendor | undefined;

   protected assetsInfo: AssetInfo[] = [];
   protected isLoading: boolean = false;

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageVendor = inject(ManageVendor);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageLang = inject(ManageLang);
   private readonly assetVendorAssociationService = inject(AssetVendorAssociationService);
   private readonly manageUtil = inject(ManageUtil);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly AssociationType = AssociationType;
   protected skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

   public ngOnInit() {
      if (!this.vendorID) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      this.vendor = this.manageVendor.getVendor(this.vendorID);
      if (!this.vendor) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.buildAssetsAssociations();
   }

   protected buildAssetsAssociations() {
      if (!this.vendorID) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.isLoading = true;

      this.assetsInfo = [];

      this.assetVendorAssociationService
         .getAssets([this.vendorID])
         .pipe(
            take(1),
            finalize(() => {
               this.isLoading = false;
            }),
         )
         .subscribe({
            next: (associations) => {
               for (const association of associations) {
                  if (association.assetID === null || association.vendorID === null)
                     continue;

                  const asset = this.manageAsset.getAsset(association.assetID);
                  if (!asset) {
                     continue;
                  }

                  if (!asset.locationID) continue;

                  const locationName =
                     this.manageLocation.getLocation(asset.locationID)?.locationName ??
                     "";

                  this.assetsInfo.push({
                     assetID: association.assetID,
                     assetName: asset.assetName,
                     associationType: association.associationType,
                     locationName,
                  });
               }
            },
         });
   }

   public async associateAssetsToVendor() {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangeVendorAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred138Fail, "danger", 10000);
         return;
      }

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message =
         this.lang().PleaseChooseWhichAssetsYouWantAssociatedWithThisVendor;
      instance.title = this.lang().PickAssets;
      instance.singleLocation = 0;
      instance.selectOne = false;
      instance.restrictToCred = true;
      instance.iDontKnowOption = false;
      instance.extraCredCheck =
         this.credService.Permissions.ChangeVendorAssetAssociations;

      const assets = await modalRef.result;
      if (!assets.length) {
         return;
      }
      const assetIDs = assets.map((asset) => asset.assetID);
      const answer = await this.manageAssociations.associateAssetsToVendor(
         assetIDs,
         this.vendor.vendorID,
      );

      if (answer?.data.success === true) {
         this.buildAssetsAssociations();

         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   public async deleteAssetVendorAssociation(assetID) {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangeVendorAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred138Fail, "danger", 10000);
         return;
      }

      const answer = await this.manageAssociations.deleteAssetVendorAssociation(
         this.vendor.vendorID,
         assetID,
      );

      if (answer?.data.success === true) {
         this.buildAssetsAssociations();

         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   popAsset = (assetID) => {
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: this.restrict,
            },
         },
      };
   };
}
