import { Component, computed, inject, input, Input } from "@angular/core";
import { IconComponent, LimbleHtmlDirective } from "@limblecmms/lim-ui";
import { AssetHierarchyListItemService } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/asset-hierarchy-list-item/asset-hierarchy-list-item.service";
import type { TaskAsset } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   standalone: true,
   selector: "asset-hierarchy-list-item",
   templateUrl: "./asset-hierarchy-list-item.component.html",
   styleUrls: ["./asset-hierarchy-list-item.component.scss"],
   imports: [LimbleHtmlDirective, IconComponent],
})
export class AssetHierarchyListItemComponent {
   public readonly asset = input.required<TaskAsset>();

   @Input() public preventParentAccess: boolean | undefined;
   @Input() public preventViewAsset: boolean | undefined;
   @Input() public showIcon: boolean = false;
   @Input() public isOnlyParentVisible: boolean = false;
   @Input() public linkColorClass:
      | "smaller-grey-text"
      | "green-color"
      | "task-sub-title" = "green-color";
   @Input() public hover: boolean = true;
   @Input() topLevel: boolean = true;
   protected readonly facade = inject(TasksFacadeService);

   private readonly service = inject(AssetHierarchyListItemService);

   protected readonly hierarchicalAsset = computed(() => {
      const baseAsset = this.asset();
      if (!baseAsset) return baseAsset;

      return this.service.buildHierarchicalAsset(baseAsset);
   });

   public onViewAsset(): void {
      if (this.preventViewAsset) {
         return;
      }
      this.facade.viewAsset(this.asset().assetID);
   }
}
