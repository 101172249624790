import {
   ChangeDetectionStrategy,
   Component,
   computed,
   effect,
   inject,
   linkedSignal,
   signal,
   type Signal,
   type WritableSignal,
} from "@angular/core";
import {
   CheckboxComponent,
   IconComponent,
   ModalService,
   OutlinedButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import {
   NgxSkeletonLoaderComponent,
   type NgxSkeletonLoaderConfigTheme,
} from "ngx-skeleton-loader";
import { DataViewerPaginatorComponent } from "src/app/shared/data-viewer";
import { AlertService } from "src/app/shared/services/alert.service";
import { AssetHierarchyListItemComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/asset-hierarchy-list-item/asset-hierarchy-list-item.component";
import type { TaskAsset } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { PmBuilderPanelService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel.service";
import { PmBuilderWizardModalComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-wizard/pm-builder-wizard-modal.component";
import { PmSuggestionsAssets } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/pm-suggestions-assets";
import type { PmSuggestion } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion";
import { PmSuggestionsUserPreferences } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/user-preferences/pm-suggestions-user-preferences";
import { PmSuggestionsCreationService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/pm-suggestions-creation.service";
import type { PmSuggestionsSort } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource-types";
import { PmSuggestionsResourceService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource.service";

export type TableRow = {
   id: number;
   name: string;
   asset: TaskAsset | undefined;
   document: string;
   schedules: string;
};

@Component({
   selector: "pm-suggestions-table",
   templateUrl: "./pm-suggestions-table.component.html",
   styleUrls: ["./pm-suggestions-table.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      CheckboxComponent,
      IconComponent,
      OutlinedButtonComponent,
      AssetHierarchyListItemComponent,
      NgxSkeletonLoaderComponent,
      DataViewerPaginatorComponent,
      TooltipDirective,
   ],
})
export class PmSuggestionsTableComponent {
   private readonly pmSuggestionsResourceService = inject(PmSuggestionsResourceService);
   private readonly modalService = inject(ModalService);
   private readonly pmSuggestionsCreationService = inject(PmSuggestionsCreationService);
   private readonly pmSuggestionsPanelService = inject(PmBuilderPanelService);

   private readonly pmSuggestionsUserPreferences = inject(PmSuggestionsUserPreferences);

   private readonly alertService = inject(AlertService);

   public readonly page = signal(1);

   public readonly pageSize = computed(() => {
      const itemsPerPage = this.pmSuggestionsUserPreferences.itemsPerPage.value();
      if (itemsPerPage === undefined) {
         return undefined;
      }
      return itemsPerPage ?? 10;
   });

   private readonly suggestions = this.pmSuggestionsPanelService.filteredSuggestions;

   protected readonly assets = inject(PmSuggestionsAssets).assets;

   public readonly tableData: Signal<Array<TableRow> | undefined> = computed(() => {
      const suggestions = this.suggestions.value();
      if (suggestions === undefined) {
         return undefined;
      }
      return suggestions.map((suggestion) => ({
         id: suggestion.getId(),
         name: suggestion.getName(),
         asset: this.assets.value()?.get(suggestion.getAssetID()),
         /** TODO(WRENCH-297) */
         document: "todo",
         schedules: suggestion.getScheduleString(),
      }));
   });

   protected readonly tableCellSkeletonTheme: NgxSkeletonLoaderConfigTheme = {
      width: "66.66%",
      minWidth: "128px",
      height: "100%",
      border: "0",
      margin: "0",
   };

   protected readonly tableRowSkeletonTheme: NgxSkeletonLoaderConfigTheme = {
      width: "100%",
      height: "24px",
      border: "0",
      margin: "0",
   };

   public readonly total = this.pmSuggestionsPanelService.count;

   private readonly selectedSuggestionsInternal: WritableSignal<Array<number>> =
      linkedSignal({
         source: this.tableData,
         computation: (newTableData, previous) => {
            if (newTableData === undefined) {
               return [];
            }
            return newTableData
               .filter((row) => previous?.value.includes(row.id))
               .map((row) => row.id);
         },
      });

   public readonly selectedSuggestions = this.selectedSuggestionsInternal.asReadonly();

   public constructor() {
      effect(() => {
         const pageSize = this.pageSize();
         if (pageSize === undefined) {
            return;
         }
         this.pmSuggestionsPanelService.userParams.update((params) => ({
            ...params,
            pagination: {
               page: this.page(),
               limit: pageSize,
            },
         }));
      });
   }

   public sort(attribute: PmSuggestionsSort["attribute"]): void {
      const currentSort = this.pmSuggestionsPanelService.sort();
      if (currentSort.attribute === attribute) {
         this.pmSuggestionsPanelService.sort.set({
            attribute: attribute,
            direction: currentSort.direction === "asc" ? "desc" : "asc",
         });
      } else {
         this.pmSuggestionsPanelService.sort.set({
            attribute: attribute,
            direction: "asc",
         });
      }
   }

   public getSortIcon(
      attribute: PmSuggestionsSort["attribute"],
   ): "sort" | "sortAsc" | "sortDesc" {
      const sort = this.pmSuggestionsPanelService.sort();
      if (sort.attribute === attribute) {
         return sort.direction === "asc" ? "sortAsc" : "sortDesc";
      }
      return "sort";
   }

   public async createPmTemplate(suggestionID: number): Promise<void> {
      const pmSuggestion = this.suggestions
         .value()
         ?.find((s) => s.getId() === suggestionID);
      if (pmSuggestion === undefined) {
         return;
      }
      const locationID = this.getLocationID(pmSuggestion);
      if (locationID === -1) {
         this.alertService.addAlert("Location not found", "danger", 6000);
         return;
      }
      const newPM = await this.pmSuggestionsCreationService.createTaskFromSuggestion(
         pmSuggestion,
         locationID,
      );
      const modalRef = this.modalService.open(PmBuilderWizardModalComponent);
      modalRef.setInput("taskInput", {
         checklistID: newPM.data.taskID,
         mode: "template",
         checklistTemplate: 1,
      });
      const schedule = pmSuggestion.getSchedule();

      modalRef.setInput("scheduleSuggestion", schedule);

      modalRef.setInput("locationID", locationID);
   }

   public getLocationID(pmSuggestion: PmSuggestion): number {
      const assetID = pmSuggestion.getAssetID();
      const asset = this.assets.value()?.get(assetID);
      if (asset?.locationID !== undefined) {
         return asset.locationID;
      }
      const limits = this.pmSuggestionsPanelService.limits();
      return limits.locationID ?? -1;
   }

   public async deleteSuggestion(id: number): Promise<void> {
      return this.pmSuggestionsResourceService
         .delete([id])
         .then(() => {
            this.alertService.addAlert("PM suggestion deleted", "success", 3000);
         })
         .catch(() => {
            this.alertService.addAlert("Failed to delete PM suggestion", "danger", 6000);
         });
   }

   public selectAll(isSelected: boolean): void {
      if (isSelected) {
         this.selectedSuggestionsInternal.set(
            this.tableData()?.map((row) => row.id) ?? [],
         );
      } else {
         this.selectedSuggestionsInternal.set([]);
      }
   }

   public select(id: number, isSelected: boolean): void {
      if (isSelected) {
         this.selectedSuggestionsInternal.update((selected) => {
            return [...selected, id];
         });
      } else {
         this.selectedSuggestionsInternal.update((selected) =>
            selected.filter((selectedId) => selectedId !== id),
         );
      }
   }

   public async deleteSelectedSuggestions(): Promise<void> {
      if (this.selectedSuggestions().length === 0) {
         return;
      }
      return this.pmSuggestionsResourceService
         .delete(this.selectedSuggestions())
         .then(() => {
            this.alertService.addAlert("PM suggestions deleted", "success", 3000);
         })
         .catch(() => {
            this.alertService.addAlert("Failed to delete PM suggestions", "danger", 6000);
         });
   }
}
