<lim-ui-modal [extraLarge]="true" [showWatermark]="true">
   <lim-ui-basic-modal-header
      [title]="i18n().t('Budget') + ': ' + budgetName()"
      (closeModal)="close()"
   />
   @if ((featureCustomBudgets | async) !== true) {
      <upsell-popover
         modalTitle
         [message]="i18n().t('FeatureCustomBudgetsPopoverMessage')"
         [alertDismissable]="true"
         [pricingLinkText]="i18n().t('LearnMore')"
      />
   } @else {
      <lim-ui-modal-body>
         <budget [budgetID]="budgetID()" />
      </lim-ui-modal-body>
   }
</lim-ui-modal>
