import type { CreateProvidedUnitSchemaParams } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/create-provided-unit-dto-schema.params";
import type { ProvidedUnitDtoSchema } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/provided-unit.dto.schema";
import type { ToMultiple } from "src/app/shared/types/utility-types";
import { z } from "zod";

export const PROVIDED_UNIT_TYPE = "provided";

export function createProvidedUnitSchema({
   category,
   detailsMap,
}: CreateProvidedUnitSchemaParams): ProvidedUnitDtoSchema {
   const names = Object.keys(detailsMap) as ToMultiple<string>;

   return z.object({
      id: z.number(),
      type: z.literal(PROVIDED_UNIT_TYPE),
      nameSingular: z.enum(names),
      category: z.literal(category),
      alias: z.string().nullable(),
   });
}
