<lim-ui-modal [showWatermark]="true" [completed]="completed" [extraLarge]="true">
   <div
      class="task-modal-body allowOffline"
      id="task-modal-body-scroll-handle"
      #scrollContent
   >
      <div class="scroll-selector">
         @if (data?.checklistID && task) {
            <task-form
               [data]="data"
               [dataLogOptions]="dataLogOptions"
               [modalInstance]="modalInstance"
               [message]="message"
               [title]="title"
               [initialTask]="task"
               class="chk-wrapper"
            />
         }
      </div>

      @if (hasLoaded) {
         <div class="modal-footer">
            @if (instructionSetTemplate) {
               <lim-ui-primary-button (click)="close()">
                  {{ lang().Done }}
               </lim-ui-primary-button>
            }
         </div>
      }
   </div>
   <ng-template #skeleton>
      <div>
         <lim-ui-icon
            id="popTaskCloseMe{{ data?.checklistID }}"
            [icon]="'xmark'"
            (click)="close()"
         />

         <h4 class="modal-title">
            <ngx-skeleton-loader count="1" [theme]="{ width: '20%' }" />
            <span> </span>
         </h4>
      </div>
      <div class="allowOffline">
         <div>
            <ngx-skeleton-loader count="1" />
            <hr />
         </div>
         @if (data?.checklistID) {
            <task-form
               [data]="data"
               [dataLogOptions]="dataLogOptions"
               class="chk-wrapper"
            />
         }
      </div>
      @if (hasLoaded) {
         <div class="modal-footer">
            <button
               type="button"
               class="btn-raised btn btn-success allowOffline"
               (click)="close()"
               style="margin-right: 0px"
            >
               {{ lang().Exit }}
            </button>
         </div>
      }
   </ng-template>
</lim-ui-modal>
