import { inject, Injectable } from "@angular/core";
import { map, type Observable } from "rxjs";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { DateUtilsService } from "src/app/shared/utils/date-utils.service";

export interface PartLeadTime {
   averageLeadTime: number | null;
   partID: number | null;
}

@Injectable({
   providedIn: "root",
})
export class LeadTimeCalculationService {
   private readonly managePO = inject(ManagePO);
   private readonly dateUtilService = inject(DateUtilsService);
   private readonly lastRefreshed$ = inject(RefreshService).lastRefreshed();

   /**
    * Calculates the average lead time for the given part and vendor.
    * Returns `null` if no matching lead times are available.
    */
   public getLeadTimeForPart(
      partID: number,
      vendorID: number,
   ): Observable<number | null> {
      return this.lastRefreshed$.pipe(
         map(() => {
            const purchaseOrders = this.managePO
               .getPurchaseOrdersByPart(partID)
               .filter((po) => po.vendorID === vendorID);
            const leadTimeArray: Array<number> = [];
            for (const po of purchaseOrders) {
               const leadTimes = this.getLeadTimeForPO(po, partID);
               leadTimeArray.push(...leadTimes);
            }

            const avgLeadTime = leadTimeArray.length
               ? Math.round(
                    leadTimeArray.reduce((sum, time) => sum + time, 0) /
                       leadTimeArray.length,
                 )
               : null;

            return avgLeadTime;
         }),
      );
   }

   public getLeadTimeForVendor(vendorID: number): Observable<PartLeadTime[] | null> {
      return this.lastRefreshed$.pipe(
         map(() => {
            const purchaseOrders = this.managePO.getPurchaseOrdersByVendor(vendorID);
            const partLeadTimes: PartLeadTime[] = [];
            for (const po of purchaseOrders) {
               const leadTimeforEachPO: PartLeadTime[] =
                  this.getLeadTimeForPOByVendor(po);
               partLeadTimes.push(...leadTimeforEachPO);
            }
            return this.calculateAverageLeadTimes(partLeadTimes);
         }),
      );
   }

   private calculateAverageLeadTimes(partLeadTimes: PartLeadTime[]): PartLeadTime[] {
      // Step 1: Group lead times by partID
      const groupedLeadTimes: Record<number, number[]> = {};

      partLeadTimes.forEach((item) => {
         if (item?.partID) {
            // Initialize the array for the partID if it doesn't exist
            if (!groupedLeadTimes[item.partID]) {
               groupedLeadTimes[item.partID] = [];
            }
            // Add the lead time to the array, defaulting to 0 if undefined
            groupedLeadTimes[item.partID].push(item.averageLeadTime ?? 0);
         }
      });

      // Step 2: Calculate the average lead time for each partID
      const averageLeadTimes: PartLeadTime[] = Object.entries(groupedLeadTimes).map(
         ([partID, leadTimes]) => {
            const totalLeadTime = leadTimes.reduce((sum, time) => sum + time, 0);
            const averageLeadTime = Math.round(totalLeadTime / leadTimes.length);
            return { partID: Number(partID), averageLeadTime };
         },
      );

      return averageLeadTimes;
   }

   private getLeadTimeForPO(po: PurchaseOrder, partID: number): number[] {
      const leadTimes: number[] = [];
      for (const poItemID of po.poItemIDs) {
         const purchaseOrderItem = this.managePO.getPurchaseOrderItem(poItemID);

         if (!purchaseOrderItem || purchaseOrderItem.partID !== partID) continue;
         const poLeadTimes = this.getLeadTimeForPOItem(purchaseOrderItem, po.date);
         leadTimes.push(...poLeadTimes);
      }
      return leadTimes;
   }

   private getLeadTimeForPOByVendor(po: PurchaseOrder): PartLeadTime[] {
      const partLeadTimes: PartLeadTime[] = [];
      for (const poItemID of po.poItemIDs) {
         const purchaseOrderItem = this.managePO.getPurchaseOrderItem(poItemID);
         if (!purchaseOrderItem) continue;
         const leadTimes = this.getLeadTimeForPOItem(purchaseOrderItem, po.date);
         for (const leadTime of leadTimes) {
            partLeadTimes.push({
               averageLeadTime: leadTime,
               partID: purchaseOrderItem?.partID,
            });
         }
      }
      return partLeadTimes;
   }
   private getLeadTimeForPOItem(
      purchaseOrderItem: PurchaseOrderItem,
      purchaseOrderDate: number,
   ): number[] {
      const leadTimesForPO: number[] = [];
      const transactions = this.managePO.getBillTransactions();
      for (const transactionID of purchaseOrderItem.transactionIDs) {
         const transaction = transactions.get(transactionID);
         if (!transaction?.dateReceived) continue;
         leadTimesForPO.push(
            this.getLeadTimeForTransaction(purchaseOrderDate, transaction.dateReceived),
         );
      }
      return leadTimesForPO;
   }

   private getLeadTimeForTransaction(
      purchaseOrderDate: number,
      dateReceived: number,
   ): number {
      const leadTime = this.dateUtilService.calculateDaysBetweenDates(
         purchaseOrderDate,
         dateReceived ?? 0,
      );
      return leadTime;
   }
}
