<div class="images-and-documents">
   <file-uploader
      [uploadObject]="uploadObject()"
      [icon]="icon()"
      label="{{ lang()[label] }}"
      [accept]="accept()"
   />
   <div class="img-styling">
      @for (image of images; track image) {
         <div class="lim-ui-list-item img-holder-small">
            <div class="lim-ui-list-item-content">
               <editable-image
                  [file]="image"
                  (delete)="deleteFile($event)"
                  [edit]="openEditor"
                  [permission]="true"
                  holderClass="medium"
               />
            </div>
         </div>
      }
   </div>

   @for (file of documents; track file) {
      <div class="lim-ui-list-item">
         <div class="lim-ui-list-item-content">
            <file-list-item [file]="file" />

            <lim-ui-icon
               icon="trashCanRegular"
               iconColor="danger"
               class="cursor"
               (click)="deleteFile(file)"
            />
         </div>
      </div>
   }
</div>
