import { NgClass } from "@angular/common";
import {
   Component,
   computed,
   DestroyRef,
   effect,
   forwardRef,
   inject,
   type OnInit,
   type Signal,
   signal,
   type WritableSignal,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import {
   IconComponent,
   ModalService,
   SelectionControlsComponent,
   TextButtonComponent,
} from "@limblecmms/lim-ui";
import {
   type NgxSkeletonLoaderConfigTheme,
   NgxSkeletonLoaderModule,
} from "ngx-skeleton-loader";
import { catchError, debounceTime, finalize, of, switchMap, tap } from "rxjs";
import { StepComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/step/step.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import {
   type ApplyTemplateLocation,
   ApplyTemplateStore,
   type AssetFieldsFilter,
} from "src/app/assets/services/apply-asset-templates/apply-template.store";
import { TranslateService } from "src/app/languages";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import {
   type DataViewerFilter,
   DataViewerFiltersComponent,
   DataViewerFilterType,
   DataViewerPaginatorComponent,
   DataViewerSearchComponent,
   FilterLabelKey,
} from "src/app/shared/data-viewer";
import { DataViewerPaginationInfoComponent } from "src/app/shared/data-viewer/data-viewer-pagination-info/data-viewer-pagination-info.component";
import { DataViewerStore } from "src/app/shared/data-viewer/data-viewer.store";
import { AlertService } from "src/app/shared/services/alert.service";
import type { RequestOptions } from "src/app/shared/services/flannel-api-service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import {
   type AssetEntity,
   AssetsApiService,
} from "src/app/tasks/components/shared/services/assets-api";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "select-assets-step",
   standalone: true,
   imports: [
      TranslateDirective,
      forwardRef(() => DataViewerFiltersComponent),
      SelectionControlsComponent,
      IconComponent,
      NgClass,
      DataViewerSearchComponent,
      NoSearchResults,
      NgxSkeletonLoaderModule,
      DataViewerPaginatorComponent,
      DataViewerPaginationInfoComponent,
      TextButtonComponent,
   ],
   templateUrl: "./select-assets-step.component.html",
   styleUrl: "./select-assets-step.component.scss",
   providers: [DataViewerStore],
})
export class SelectAssetsStepComponent extends StepComponent implements OnInit {
   protected skeletonThemes: Record<string, NgxSkeletonLoaderConfigTheme>;
   protected filters: DataViewerFilter[] = [];
   protected canViewAssets: boolean = false;

   public assets: WritableSignal<(AssetEntity & { selected: boolean })[]> = signal([]);
   public total: WritableSignal<number | undefined> = signal(undefined); // need undefined to keep store assetSelection

   public hasAssets = computed(() => {
      const total = this.total();
      return total !== undefined && total > 0;
   });

   protected isLoading = signal(true);

   private readonly assetService = inject(AssetsApiService);
   private readonly alertService = inject(AlertService);
   private readonly translateService = inject(TranslateService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly applyTemplateState = inject(ApplyTemplateStore);
   public readonly dataViewerState = inject(DataViewerStore);
   protected readonly credService = inject(CredService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly destroyRef = inject(DestroyRef);

   public requestOptions: Signal<Partial<RequestOptions>> =
      this.dataViewerState.requestOptions;

   public isDeselectAllNeeded = computed(() => {
      const requestSearch = this.requestOptions()?.search ?? "";
      const storeSearch = this.assetFieldsFilter()?.search ?? "";

      const requestFieldsFilter = this.requestOptions()?.filters?.assetFieldFilters ?? "";
      const storeFieldsFilter = this.assetFieldsFilter()?.assetFieldFilters ?? "";

      return requestSearch !== storeSearch || requestFieldsFilter !== storeFieldsFilter;
   });

   protected selectedLocation: Signal<ApplyTemplateLocation> =
      this.applyTemplateState.selectedLocation;
   protected selectedAssetCount: Signal<number> =
      this.applyTemplateState.selectedAssetCount;
   protected assetFieldsFilter: Signal<AssetFieldsFilter> =
      this.applyTemplateState.assetFieldsFilter;

   public constructor() {
      super();
      this.skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

      toObservable(this.dataViewerState.requestOptions)
         .pipe(
            debounceTime(500),
            tap((requestOptions) => {
               this.isLoading.set(true);
               if (this.isDeselectAllNeeded()) {
                  this.applyTemplateState.toggleSelectAllAssets(false);
               }
               this.setStoreFilters(requestOptions);
            }),
            switchMap((requestOptions) =>
               this.assetService
                  .getList({
                     ...requestOptions,
                  })
                  .pipe(
                     finalize(() => {
                        this.isLoading.set(false);
                     }),
                  ),
            ),
            catchError((error) => {
               this.alertService.addAlert(
                  this.translateService.instant("errorMsg"),
                  "danger",
                  6000,
               );
               console.error("Error fetching assets:", error);
               return of();
            }),
         )
         .subscribe({
            next: (response) => {
               this.assets.set(
                  (response?.data ?? []).map((asset) => ({
                     ...asset,
                     selected: this.isAssetSelected(asset.assetID),
                  })),
               );
               this.total.set(response?.total ?? 0);
            },
         });

      effect(() => {
         if (this.total() !== undefined) {
            this.applyTemplateState.setTotalAssetsCount(this.total() ?? 0);
         }
      });
   }

   public ngOnInit(): void {
      const locationID = this.selectedLocation()?.locationID;

      if (!locationID) return;

      this.canViewAssets = this.credService.isAuthorized(
         locationID,
         this.credService.Permissions.ViewLookupAnAsset,
      );

      this.filters.push({
         type: DataViewerFilterType.ASSET_FIELD_VALUES,
         labelKey: FilterLabelKey.ASSET_INFORMATION,
         key: "assetFieldFilters",
         options: {
            locationID: this.selectedLocation().locationID,
            initialValue:
               this.applyTemplateState.assetFieldsFilter().assetFieldFilters ?? "",
         },
      });

      this.initDataViewerState();
   }

   private setStoreFilters(requestOptions) {
      this.applyTemplateState.setAssetsPageSize(requestOptions?.pagination?.limit ?? 50);
      this.applyTemplateState.setAssetsSearch(requestOptions?.search ?? "");
      this.applyTemplateState.setAssetFieldFilters(
         String(requestOptions?.filters?.assetFieldFilters ?? ""),
         requestOptions?.filters?.assetFieldFiltersRaw ?? null,
      );
   }

   private initDataViewerState(): void {
      const { search, pageSize, assetFieldFilters } =
         this.applyTemplateState.assetFieldsFilter();
      this.dataViewerState.setSearch(search ?? "");
      this.dataViewerState.setPageSize(pageSize ?? 50);
      this.dataViewerState.setFixedFilters([
         {
            locationIDs: [this.selectedLocation().locationID],
            includeSFValuesOnFilter: true, //Include Standardized Field Values (SFValues) on filter by field.
         },
      ]);

      if (assetFieldFilters) {
         this.dataViewerState.setUserFilters(this.filters);
      }
   }

   public selectAllAssets(isSelected: boolean) {
      this.assets.update((assets) =>
         assets.map((asset) => ({ ...asset, selected: isSelected })),
      );
      this.applyTemplateState.toggleSelectAllAssets(isSelected);
   }

   public selectAsset(asset: AssetEntity & { selected: boolean }) {
      asset.selected = !(asset.selected ?? false);
      this.applyTemplateState.toggleAssetSelection(asset);
   }

   protected onSetFilter(dataViewerFilter: DataViewerFilter) {
      this.dataViewerState.addUserFilter(dataViewerFilter);
   }

   protected onRemoveFilter(dataViewerFilter: DataViewerFilter) {
      this.dataViewerState.removeUserFilter(dataViewerFilter);
   }

   protected onSearchChange(search: string): void {
      this.dataViewerState.setSearch(search);
   }

   public override handleNextStepClick() {
      if (!this.selectedAssetCount()) {
         this.alertService.addAlert(
            this.translateService.instant("PleaseSelectAsset"),
            "warning",
            6000,
         );
         return;
      }
      this.context().goToNextStep();
   }

   protected pageChanged(page: number): void {
      this.dataViewerState.setPage(page);
   }

   protected pageSizeChange(size: number) {
      this.dataViewerState.setPageSize(size);
   }

   private isAssetSelected(assetID: number): boolean {
      const assetSelections = this.applyTemplateState.assetSelections();
      if (assetSelections.includedAssetIds.size) {
         return assetSelections.includedAssetIds.has(assetID);
      }
      if (assetSelections.selectAll) {
         return !assetSelections.selectAll.excludedAssetIds.has(assetID);
      }
      return false;
   }

   public viewAsset(asset: AssetEntity): void {
      if (!asset) {
         return;
      }

      const modalRef = this.modalService.open(PopAsset);
      // Using the paramsService here because locationID is not available in the modalRef and this is the current standard pattern for passing data to modals
      this.paramsService.params = {
         modalInstance: modalRef,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }
}
