@if (loading) {
   <div class="loading-icon">
      <lim-ui-loading-animation />
   </div>
}
@if (!loading) {
   <span class="content">
      <div class="budget-settings">
         <div class="ng-binding header">
            {{ lang().Settings }}
         </div>

         <lim-ui-panel>
            <div
               class="budget-name"
               [limUiPopover]="startCustomBudgetsPopover"
               [limUiPopoverType]="'upsell'"
               [limUiPopoverPlacement]="'bottom'"
               [limUiPopoverHidden]="featureCustomBudgets"
            >
               <div class="budget-name-form">
                  <span>{{ lang().BudgetName }}</span>
                  <div class="budget-name-input-and-mobile-button">
                     @if (budget) {
                        <input
                           type="text"
                           [(ngModel)]="budget.name"
                           (blur)="updateBudgetName()"
                           [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
                           [disabled]="!editBudgetCred"
                           [readonly]="!featureCustomBudgets"
                        />
                     }
                     @if (!defaultBudget) {
                        <lim-ui-icon-button
                           class="tablet-down-delete-icon-button"
                           (click)="deleteBudget()"
                           [disabled]="!featureCustomBudgets"
                           icon="trashCanRegular"
                           iconColor="danger"
                        />
                     }
                  </div>
               </div>
               @if (!defaultBudget) {
                  <lim-ui-danger-button
                     class="lim-ui-show-on-desktop"
                     (click)="deleteBudget()"
                     icon="trashCanRegular"
                     [disabled]="!featureCustomBudgets"
                  >
                     {{ lang().DeleteBudget }}
                  </lim-ui-danger-button>
               }
            </div>
         </lim-ui-panel>
      </div>

      <div class="stages">
         <div class="ng-binding header">
            {{ lang().POWorkflowStages }}
         </div>

         <div class="card-wrapper">
            <lim-ui-info-panel>
               <div class="panel-info-content">
                  {{ lang().EveryPOHasAWorkflowDescription }}
               </div>
            </lim-ui-info-panel>
            <lim-ui-info-panel>
               <div class="panel-info-content">
                  <h1 class="panel-header">
                     {{ lang().POSetup }}
                  </h1>
                  {{ lang().POSetupDescription }}
               </div>
            </lim-ui-info-panel>
         </div>

         <div class="add-step-container">
            <lim-ui-icon class="step-arrow" icon="chevronDown" iconSize="medium" />

            <a
               class="add-step-dropdown"
               (click)="addStep(0)"
               [ngClass]="{ 'no-feature-flag': !featureCustomBudgets }"
               [limUiPopover]="startCustomBudgetsPopover"
               [limUiPopoverType]="'upsell'"
               [limUiPopoverPlacement]="'bottom'"
               [limUiPopoverHidden]="featureCustomBudgets"
            >
               {{ steps?.length === 0 ? lang().AddStep : lang().AddAnotherStep }}
            </a>
         </div>

         <limble-tree-root
            [data]="steps"
            [options]="treeOptions"
            (treeDrop)="onMoveNode($event)"
            #tree
         />

         <lim-ui-panel>
            <div class="step-wrapper">
               <div class="pricing-plan-container">
                  <div class="card-header">{{ lang().POStageReadyToReceive }}</div>
                  @if (!featureCustomBudgets) {
                     <lim-ui-badge
                        class="pricing-plan-badge"
                        [label]="lang().Enterprise"
                        icon="stars"
                        [limUiPopover]="startCustomBudgetsPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverPlacement]="'right'"
                        [limUiPopoverHidden]="featureCustomBudgets"
                     />
                  }
               </div>
               <span>{{ lang().OnThisStepWeAreWaitingForAllPOItems }}</span>
               <div class="step-setting">
                  <span>{{ lang().WhenThisStepStarts }}</span>
                  <div class="settings">
                     <div class="start-block">
                        <span>
                           {{ lang().AutomaticallyAssignPOTo }}
                        </span>
                        <div
                           class="option"
                           (click)="setBudgetAwaitingAssignment()"
                           [ngClass]="{
                              'no-feature-flag': !canEditReadyToRecieveSection,
                           }"
                           [limUiPopover]="startCustomBudgetsPopover"
                           [limUiPopoverType]="'upsell'"
                           [limUiPopoverPlacement]="'right'"
                           [limUiPopoverHidden]="featureCustomBudgets"
                        >
                           <lim-ui-icon icon="user" iconSize="small" />
                           {{ budgetAwaitingDisplayName }}
                        </div>
                     </div>
                     <div class="end-block">
                        @if (budget) {
                           <lim-ui-checkbox
                              [(model)]="budget.awaitingEmailSend"
                              (modelChange)="setAwaitingEmailSend()"
                              [disabled]="
                                 !canEditReadyToRecieveSection || !editBudgetCred
                              "
                              [limUiPopover]="startCustomBudgetsPopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverHidden]="featureCustomBudgets"
                              [limUiPopoverPlacement]="'right'"
                           />
                        }

                        <div>
                           <span>{{ lang().Send }}</span>
                           <span
                              class="option"
                              [ngClass]="{
                                 'no-feature-flag': !canEditReadyToRecieveSection,
                              }"
                              (click)="editAwaitingEmail(); $event.stopPropagation()"
                              [limUiPopover]="startCustomBudgetsPopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverPlacement]="'right'"
                              [limUiPopoverHidden]="featureCustomBudgets"
                              >{{ lang().emailMessage }}</span
                           >
                        </div>
                     </div>
                  </div>
               </div>

               <div class="step-setting">
                  <span>{{ lang().AsPOItemsAreReceived }}</span>
                  <div class="settings">
                     <div class="start-block">
                        <span>
                           {{ lang().AutomaticallyAssignBillTo }}
                        </span>
                        <div
                           class="option"
                           (click)="setBudgetPRAssignment()"
                           [ngClass]="{
                              'no-feature-flag': !canEditReadyToRecieveSection,
                           }"
                           [limUiPopover]="startCustomBudgetsPopover"
                           [limUiPopoverType]="'upsell'"
                           [limUiPopoverHidden]="featureCustomBudgets"
                           [limUiPopoverPlacement]="'right'"
                        >
                           <lim-ui-icon icon="user" iconSize="small" />
                           {{ budgetPrDisplayName }}
                        </div>
                     </div>
                     <div class="end-block">
                        @if (budget) {
                           <lim-ui-checkbox
                              [(model)]="budget.prEmailSend"
                              (modelChange)="setPREmailSend()"
                              [disabled]="
                                 !canEditReadyToRecieveSection || !editBudgetCred
                              "
                              [limUiPopover]="startCustomBudgetsPopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverPlacement]="'right'"
                              [limUiPopoverHidden]="featureCustomBudgets"
                           />
                        }
                        <div>
                           <span>{{ lang().Send }}</span>
                           <span
                              class="option"
                              [ngClass]="{
                                 'no-feature-flag': !canEditReadyToRecieveSection,
                              }"
                              (click)="editPREmail(); $event.stopPropagation()"
                              [limUiPopover]="startCustomBudgetsPopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverPlacement]="'right'"
                              [limUiPopoverHidden]="featureCustomBudgets"
                              >{{ lang().emailMessage }}</span
                           >
                        </div>
                     </div>
                  </div>
               </div>

               <div class="step-setting">
                  <span>{{ lang().WhileOnThisStep }}</span>
                  <div class="settings">
                     <div class="end-block">
                        @if (budget) {
                           <lim-ui-checkbox
                              [(model)]="budget.awaitingAllowPOEdit"
                              (modelChange)="setBudgetAwaitingPOCanBeEdited()"
                              [disabled]="
                                 !canEditReadyToRecieveSection || !editBudgetCred
                              "
                              [label]="lang().POCanBeEdited"
                              [limUiPopover]="startCustomBudgetsPopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverPlacement]="'right'"
                              [limUiPopoverHidden]="featureCustomBudgets"
                           />
                        }
                     </div>
                     <div class="end-block">
                        <lim-ui-checkbox
                           [model]="true"
                           [disabled]="true"
                           [label]="lang().POItemsCanBeReceived"
                           [limUiPopover]="startCustomBudgetsPopover"
                           [limUiPopoverType]="'upsell'"
                           [limUiPopoverPlacement]="'right'"
                           [limUiPopoverHidden]="featureCustomBudgets"
                        />
                     </div>
                  </div>
               </div>

               <div class="step-setting">
                  <span>{{ lang().MoveToTheNextStepWhen }}</span>
                  <span>{{ lang().AllPOItemsHaveBeenReceivedAndPaidFor }}</span>
               </div>
            </div>
         </lim-ui-panel>
         <div class="chev-wrapper">
            <lim-ui-icon icon="chevronDown" iconSize="medium" />
         </div>

         <lim-ui-panel>
            <h1>{{ lang().POClosed }}</h1>
            {{ lang().ThePOIsOfficallyCompleted }}
         </lim-ui-panel>
      </div>

      <div>
         <div class="header">
            {{ lang().AutoAssignments }}
         </div>
         <lim-ui-panel class="assignments-panel">
            <div class="assignments-wrapper">
               <span class="assignment-description">
                  {{ lang().AutomaticBudgetAssignmentsDescription }}
               </span>
               <div class="assignment-options">
                  <lim-ui-checkbox
                     [disabled]="!featureCustomBudgets"
                     [(model)]="defaultBudget"
                     (modelChange)="setDefaultBudgetStandard()"
                     [label]="lang().DefaultBudget"
                     [tooltip]="lang().DefaultBudgetTooltip"
                  />
                  <lim-ui-checkbox
                     [disabled]="!featureCustomBudgets"
                     [(model)]="defaultBudgetRequestPurchase"
                     (modelChange)="setDefaultBudgetPurchaseRequest()"
                     [label]="lang().DefaultBudgetForPurchaseRequests"
                     [tooltip]="lang().DefaultBudgetRequestPurchaseTooltip"
                  />
                  <lim-ui-checkbox
                     [disabled]="!featureCustomBudgets"
                     [(model)]="defaultBudgetMinPart"
                     (modelChange)="setDefaultBudgetMinPart()"
                     [label]="lang().DefaultBudgetForMinPartQty"
                     [tooltip]="lang().DefaultBudgetForMinPartQtyTooltip"
                  />
               </div>
            </div>
         </lim-ui-panel>
      </div>
   </span>
}

<ng-template #startCustomBudgetsPopover>
   <upsell-popover
      [message]="lang().FeatureCustomBudgetsPopoverMessage"
      [pricingLinkText]="lang().LearnMore"
   />
</ng-template>
