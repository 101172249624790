import { inject, Injectable } from "@angular/core";
import type { WidgetDefinition } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import type { ChartSegment } from "src/app/dashboards/widgets/chart-segment/chart-segment.models";
import {
   type VirtualWidgetID,
   VirtualWidgets,
} from "src/app/shared/components/global/virutalWidget/virtual-widget.models";
import type { RequestFilter } from "src/app/shared/services/flannel-api-service";
import type { TasksDataViewerOptions } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import {
   ALL_TASK_VIEW_TYPES,
   TaskViewType_AnyTasks,
   TaskViewType_ByCost,
   TaskViewType_ByDowntime,
   TaskViewType_ByStatus,
   TaskViewType_ByType,
   TaskViewType_CompletedTasks,
   TaskViewType_OpenTasks,
   TaskViewType_TaskSchedulesCombined,
   TaskViewType_Total,
   type TaskViewTypes,
   VirtualTaskViewType_CriticalWOsAndPMs,
   VirtualTaskViewType_downtimeCount,
   VirtualTaskViewType_downtimeHoursEfficiency,
   VirtualTaskViewType_laborCostEfficiency,
   VirtualTaskViewType_mtbf,
   VirtualTaskViewType_mttr,
   VirtualTaskViewType_OnTimeWOsAndPMs,
   VirtualTaskViewType_operatingCostsMonthlyLine,
   VirtualTaskViewType_OverdueWOsAndPMs,
   VirtualTaskViewType_partCostEfficiency,
   VirtualTaskViewType_PlannedMaintenance,
   VirtualTaskViewType_PlannedVsUnplannedMonthly,
   VirtualTaskViewType_totalInvoiceCost,
   VirtualTaskViewType_totalLaborCost,
   VirtualTaskViewType_totalOperatingCost,
   VirtualTaskViewType_totalPartsCost,
   VirtualTaskViewType_UnplannedMaintenance,
   VirtualTaskViewType_WorkRequests,
} from "src/app/tasks/components/shared/services/task-column-definitions-factory";
import { TasksModalsService } from "src/app/tasks/components/shared/services/tasks-modals/tasks-modals.service";

@Injectable({ providedIn: "root" })
export class TaskWidgetModalsService {
   private readonly tasksModals = inject(TasksModalsService);

   /**
    * This function will determine if based on the widget definition, the tasks modal should be opened.
    * NOTE: it checks whether the Task and Schedules modal should be opened instead, because
    *        the tasks and schedules modals also can be opened when the type is "tasks".
    * @param widgetDef
    */
   public shouldOpenForWidget(widgetDef: WidgetDefinition): boolean {
      const { type, display, viewedAs } = widgetDef;
      return (
         this.isTscModal(widgetDef) ||
         type === "tasks" ||
         ((type === "assets" || type === "users") &&
            viewedAs === "tile" &&
            this.isTaskDisplayType((display as string) || ""))
      );
   }

   public openForWidget(
      widget: WidgetDefinition,
      requestFilters: Array<RequestFilter>,
      segment?: ChartSegment,
      optionsOverrides?: Partial<TasksDataViewerOptions>,
   ) {
      const type = this.getTaskViewTypeForWidget(widget);
      const requestParams = this.getWidgetRequestFilters(widget, requestFilters);
      this.tasksModals.open(
         type,
         requestParams,
         segment,
         optionsOverrides,
         widget.viewedAs,
      );
   }

   private getWidgetRequestFilters(
      widget: WidgetDefinition,
      otherFilters: Array<RequestFilter>,
   ): Array<RequestFilter> {
      const filters = [{ widgetID: widget.widgetID }] as Array<RequestFilter>;
      return [...filters, ...otherFilters];
   }

   private getTaskViewTypeForWidget(widget: WidgetDefinition): TaskViewTypes {
      if (this.isVirtualWidgetModal(widget)) {
         return this.getVirtualWidgetDisplayType(
            widget.widgetID as unknown as VirtualWidgetID,
         );
      }

      if (this.isTscModal(widget)) {
         return TaskViewType_TaskSchedulesCombined;
      }

      // Whenever the display is a task display we return it immediately instead of trying
      // to assign a new type
      let displayType: string | undefined;
      if (typeof widget.display === "string") {
         displayType = widget.display;
      } else if ("view" in widget.display) {
         displayType = widget.display.view;
      }
      if (displayType && this.isTaskDisplayType(displayType)) {
         if (displayType === TaskViewType_Total) {
            return this.hasCompletedTasks(widget.tasks.statusObj)
               ? TaskViewType_CompletedTasks
               : TaskViewType_OpenTasks;
         }
         return this.getDisplayType(displayType);
      }

      const map = {
         // Completed
         additionalFiltersCompletionTime: TaskViewType_CompletedTasks,
         additionalFiltersEstTime: TaskViewType_CompletedTasks,
         additionalFiltersNumOfNotes: TaskViewType_CompletedTasks,

         // By Downtime
         additionalFiltersDowntime: TaskViewType_ByDowntime,

         // By Cost
         additionalFiltersTotalOperatingCost: TaskViewType_ByCost,
         additionalFiltersTotalPartsCost: TaskViewType_ByCost,
         additionalFiltersTotalLaborCost: TaskViewType_ByCost,
         additionalFiltersTotalInvoicesCost: TaskViewType_ByCost,
         additionalFiltersNumOfInvoices: TaskViewType_ByCost,

         // By Type
         additionalFiltersCompletedState: TaskViewType_ByType,
         plannedVsUnplanned: TaskViewType_ByType,

         // Any
         startedVsCompleted: TaskViewType_AnyTasks,
      };

      /**
       * Defining the type based on the widget's tasks (additional filters) or the display type
       */
      for (const [key, value] of Object.entries(map)) {
         if (widget.tasks?.[key] || widget.display === key) {
            return value;
         }
      }

      if (this.hasOpenAndCompletedTasks(widget.tasks.statusObj)) {
         return TaskViewType_AnyTasks;
      } else if (this.hasOpenTasks(widget.tasks.statusObj)) {
         return TaskViewType_OpenTasks;
      } else if (this.hasCompletedTasks(widget.tasks.statusObj)) {
         return TaskViewType_CompletedTasks;
      }

      return TaskViewType_AnyTasks;
   }

   private getVirtualWidgetDisplayType(widgetID: VirtualWidgetID): string {
      switch (widgetID) {
         case "onTime":
            return VirtualTaskViewType_OnTimeWOsAndPMs;
         case "overdue":
            return VirtualTaskViewType_OverdueWOsAndPMs;
         case "critical":
            return VirtualTaskViewType_CriticalWOsAndPMs;
         case "plannedVsUnplannedMonthlyLine":
            return VirtualTaskViewType_PlannedVsUnplannedMonthly;
         case "plannedMaintenance":
            return VirtualTaskViewType_PlannedMaintenance;
         case "unplannedMaintenance":
            return VirtualTaskViewType_UnplannedMaintenance;
         case "workRequests":
            return VirtualTaskViewType_WorkRequests;
         case "mtbf":
            return VirtualTaskViewType_mtbf;
         case "mttr":
            return VirtualTaskViewType_mttr;
         case "downtimeCount":
            return VirtualTaskViewType_downtimeCount;
         case "totalOperatingCost":
            return VirtualTaskViewType_totalOperatingCost;
         case "totalPartsCost":
            return VirtualTaskViewType_totalPartsCost;
         case "totalLaborCost":
            return VirtualTaskViewType_totalLaborCost;
         case "totalInvoiceCost":
            return VirtualTaskViewType_totalInvoiceCost;
         case "operatingCostsMonthlyLine":
            return VirtualTaskViewType_operatingCostsMonthlyLine;
         case "operatingCostsByCostTypePie":
            return VirtualTaskViewType_totalOperatingCost;
         case "downtimeHoursEfficiency":
         case "downtimeHoursEfficiencyLine":
            return VirtualTaskViewType_downtimeHoursEfficiency;
         case "laborCostEfficiency":
         case "laborCostEfficiencyLine":
            return VirtualTaskViewType_laborCostEfficiency;
         case "partCostEfficiency":
         case "partCostEfficiencyLine":
            return VirtualTaskViewType_partCostEfficiency;
         case "onTimeVsOverdueVsCriticalMonthlyLine":
            return TaskViewType_ByStatus;
         default:
            console.error(
               `Unknown virtual widget ID: ${widgetID}. Defaulting to any tasks.`,
            );
            return TaskViewType_AnyTasks;
      }
   }

   private isTaskDisplayType(type: string | { segment: string; view: string }): boolean {
      const displayType = this.getDisplayType(type);
      return displayType !== "" && ALL_TASK_VIEW_TYPES.includes(displayType);
   }

   private getDisplayType(type: string | { segment: string; view: string }): string {
      return typeof type === "string" ? type : type.view;
   }

   private isVirtualWidgetModal(widgetDef: WidgetDefinition): boolean {
      return VirtualWidgets.includes(widgetDef.widgetID as unknown as VirtualWidgetID);
   }

   /**
    * Determines if a task schedules combined modal should be displayed
    * based on the provided `widgetDef`.
    */
   private isTscModal(widgetDef: WidgetDefinition): boolean {
      return (
         this.isTasksIncludeUpcomingModal(widgetDef) ||
         this.isUpcomingEstimatedTimeModal(widgetDef)
      );
   }

   private isTasksIncludeUpcomingModal(widgetDef: WidgetDefinition): boolean {
      return (
         widgetDef.type === "tasks" &&
         widgetDef.display === "total" &&
         Boolean(widgetDef.tasks.totalTasksIncludeUpcomingTasks)
      );
   }

   private isUpcomingEstimatedTimeModal(widgetDef: WidgetDefinition): boolean {
      return widgetDef.display === "upcomingEstimatedTime";
   }

   private hasOpenTasks(filterObj: WidgetDefinition["tasks"]["statusObj"]): boolean {
      return Object.keys(filterObj).some((key) => key !== "2" && filterObj[key] === true);
   }

   private hasCompletedTasks(filterObj: WidgetDefinition["tasks"]["statusObj"]): boolean {
      return filterObj[2] === true;
   }

   private hasOpenAndCompletedTasks(
      filterObj: WidgetDefinition["tasks"]["statusObj"],
   ): boolean {
      return this.hasCompletedTasks(filterObj) && this.hasOpenTasks(filterObj);
   }
}
