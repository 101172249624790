import { Injectable } from "@angular/core";
import type { WidgetDefinition } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import type { WidgetContent } from "src/app/dashboards/widgets/widget/widgetContent.types";

@Injectable({ providedIn: "root" })
export class WidgetCacheService {
   private readonly widgetDefs = new Map<number, WidgetDefinition>();
   private readonly widgetContents = new Map<number, WidgetContent>();

   public getWidgetDef(widgetID: number): WidgetDefinition | undefined {
      return this.widgetDefs.get(widgetID);
   }

   public setWidgetDef(widgetID: number, widgetDef: WidgetDefinition): void {
      this.widgetDefs.set(widgetID, widgetDef);
   }

   public getWidgetContent(widgetID: number): WidgetContent | undefined {
      return this.widgetContents.get(widgetID);
   }

   public setWidgetContent(widgetID: number, widgetContent: WidgetContent): void {
      this.widgetContents.set(widgetID, widgetContent);
   }
}
