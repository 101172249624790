import { Component, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

export type TaskDowntimeCellViewModel = {
   checklistDowntime?: number;
};

@Component({
   selector: "task-downtime-cell",
   imports: [BetterDecimalPipe],
   templateUrl: "./task-downtime-cell.component.html",
})
export class TaskDowntimeCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   @Input() public column?: Column;

   private readonly taskFacade = inject(TasksFacadeService);
   protected readonly lang = inject(ManageLang).lang;

   protected viewCompletedTask(): void {
      this.taskFacade.viewTask(this.task.checklistID);
   }
}
