[
   {
      "fillColor": "#5083d5",
      "strokeColor": "#5083d5",
      "pointColor": "#5083d5",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#a25ae0",
      "strokeColor": "#a25ae0",
      "pointColor": "#a25ae0",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#289e49",
      "strokeColor": "#289e49",
      "pointColor": "#289e49",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#e62737",
      "strokeColor": "#e62737",
      "pointColor": "#e62737",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#8D8D8F",
      "strokeColor": "#8D8D8F",
      "pointColor": "#8D8D8F",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#f29422",
      "strokeColor": "#f29422",
      "pointColor": "#f29422",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#11732d",
      "strokeColor": "#11732d",
      "pointColor": "#11732d",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#0a4966",
      "strokeColor": "#0a4966",
      "pointColor": "#0a4966",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#4e377a",
      "strokeColor": "#4e377a",
      "pointColor": "#4e377a",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },

   {
      "fillColor": "#152232",
      "strokeColor": "#152232",
      "pointColor": "#152232",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   },
   {
      "fillColor": "#e83547",
      "strokeColor": "#e83547",
      "pointColor": "#e83547",
      "pointStrokeColor": "#fff",
      "pointHighlightFill": "#fff",
      "pointHighlightStroke": "rgba(220,220,220,1)"
   }
]
