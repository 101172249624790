import { Injectable } from "@angular/core";
import type { PartEntity } from "src/app/parts/components/shared/parts-api-service/parts-api.models";
import {
   FlannelApiService,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";

const PARTS_BATCH_SIZE = 100;

@Injectable({
   providedIn: "root",
})
export class PartsApiService extends FlannelApiService<PartEntity> {
   public constructor() {
      super("parts");
   }

   public async *getPartsByID(
      partIDs: Array<number>,
      options?: Partial<RequestOptions>,
   ): AsyncGenerator<PartEntity> {
      for (let startIdx = 0; startIdx < partIDs.length; startIdx += PARTS_BATCH_SIZE) {
         const batchIDs = partIDs.slice(startIdx, startIdx + PARTS_BATCH_SIZE);

         const parts = this.getStreamedList({
            ...options,
            filters: {
               ...(options?.filters ?? {}),
               partIDs: batchIDs,
            },
         });

         yield* parts;
      }
   }

   public async assetNamesForParts(
      partIDs: Array<number>,
   ): Promise<Map<number, Array<string>>> {
      const parts = this.getPartsByID(partIDs, {
         columns: "assets",
      });

      const result = new Map<number, Array<string>>();
      for await (const part of parts) {
         result.set(
            part.partID,
            part.assets?.map((asset) => asset.assetName ?? "") ?? [],
         );
      }
      return result;
   }
}
