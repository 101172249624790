<lim-ui-modal [showWatermark]="true" cdkTrapFocus cdkTrapFocusAutoCapture>
   <lim-ui-basic-modal-header
      [title]="title"
      (closeModal)="close()"
      [attr.aria-label]="lang().Close"
   />
   <lim-ui-modal-body [scrollable]="false">
      <lim-ui-info-panel>
         <div class="modal-panel-wrapper">
            <div class="modal-info">
               <div class="info-group">
                  <span>{{ message }}</span>

                  <lim-ui-icon-button
                     [icon]="noteHidden ? eyeSlashIcon : eyeIcon"
                     limUiTooltip="{{ lang().ShowHideCommentTooltip }}"
                     (click)="showHideNoteSetting()"
                  />
               </div>

               @if (!external || !comment) {
                  <a class="table-link" (click)="whoToNotify()">
                     {{ lang().WhoWillBeSentNotifications }}
                  </a>
               }
            </div>

            <div
               type="text"
               class="large-text-field"
               [(ngModel)]="note"
               limbleContentEditable
               data-test="addNoteText"
               id="input-field-{{ mentionUid }}"
               focusAuto
               cursorPlacement="end"
               [attr.aria-label]="lang().AddCommentMsg"
            ></div>

            <comment-files
               [uploadObject]="uploadObject"
               [files]="files"
               icon="cloudUploadRegular"
               label="AttachFile"
            />
         </div>
         <mention
            [mentionList]="mentionList"
            [userProfiles]="userProfiles"
            [updateNewMentionedList]="updateNewMentionedList"
            dropdownDirection="down"
            [mentionUid]="mentionUid"
         />
      </lim-ui-info-panel>

      @if (external) {
         <lim-ui-info-panel>
            <div class="external-email">
               @if (externalEmail === false) {
                  <span>
                     {{ lang().EnterEmailAddress }}
                  </span>
               }
               <div>
                  <i>
                     {{ lang().YourCommentWillBeSentToTheMaintenanceTeam }}
                  </i>
               </div>
               @if (externalEmail === false) {
                  <div>
                     <input
                        type="text"
                        [(ngModel)]="externalEmailValue"
                        placeholder="{{ lang().EnterEmailAddressHere }}"
                     />
                  </div>
               }
            </div>
         </lim-ui-info-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-secondary-button (click)="close()">{{
         lang().Cancel
      }}</lim-ui-secondary-button>
      <lim-ui-primary-button
         (click)="submit()"
         data-test="addNoteSubmit"
         [dataLogLabel]="lookupDataLogSubmitLabel()"
         >{{ lang().Submit }}</lim-ui-primary-button
      >
   </lim-ui-modal-footer>
</lim-ui-modal>
