<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         @if (message) {
            <label class="control-label" [limbleHtml]="message"></label>
         }
         @for (category of categories | orderBy: "order*1"; track category) {
            <div
               (click)="selectCategory(category)"
               class="lim-ui-list-item lim-ui-selectable-list-item user-row-content"
               [ngClass]="{ 'lim-ui-selected-list-item': category.selected == true }"
            >
               @if (category.selected == true) {
                  <lim-ui-icon icon="check" iconSize="small" />
               }

               <span [limbleHtml]="category.categoryName"></span>
            </div>
         }
         @if (errorMsg?.length > 0) {
            <lim-ui-alert alertType="danger" [title]="lang().Warning">
               <span [limbleHtml]="errorMsg"></span>
            </lim-ui-alert>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Change }}"
      (successClick)="submit()"
      [dataLogSubmitLabel]="lookupDataLogSubmitLabel()"
   />
</lim-ui-modal>
