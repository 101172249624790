import { customUnitDtoSchema } from "src/app/parts/unit-of-measure/custom-units/custom-unit.dto";
import { providedUnitDtoSchema } from "src/app/parts/unit-of-measure/provided-units/provided-unit.dto";
import { CUSTOM_UNIT, PROVIDED_UNIT } from "src/app/parts/unit-of-measure/unit.types";
import { z } from "zod";

export const unitDtoSchema = z.union([providedUnitDtoSchema, customUnitDtoSchema]);
export const unitSettingsDtoSchema = z.object({
   unitsOfMeasureEnabled: z.boolean(),
});

export const unitDescriptionDtoSchema = z.object({
   id: z.number(),
   type: z.enum([PROVIDED_UNIT, CUSTOM_UNIT]),
});

export const deleteCustomUnitDtoSchema = z.object({
   id: z.number(),
});
