import { inject, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDate } from "src/app/shared/services/betterDate";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { DateHint } from "src/app/tasks/search-hint/default-search-hint.service";

@Injectable({ providedIn: "root" })
export class BuildDatesHintService {
   private readonly betterDate = inject(BetterDate);
   private readonly manageLang = inject(ManageLang);

   public buildTaskDatesHint(dateHints: DateHint[], task: TaskEntity): string {
      const lang = this.manageLang.lang() ?? {};

      if (dateHints.includes(DateHint.Due)) {
         const checklistDueDateDisplay = this.getTaskDueDateDisplay(task);
         return `${lang.DueDate}: ${checklistDueDateDisplay};`;
      }

      if (dateHints.includes(DateHint.Completed)) {
         const checklistCompletedDateStr = task.checklistCompletedDate
            ? this.timestampToDateStr(task.checklistCompletedDate, true)
            : "";
         return `${lang.CompletedDate}: ${checklistCompletedDateStr}`;
      }

      if (dateHints.includes(DateHint.Created)) {
         const checklistCreatedDateStr = task.checklistCreatedDate
            ? this.timestampToDateStr(task.checklistCreatedDate, true)
            : "";
         return `${lang.CreatedDate}: ${checklistCreatedDateStr}`;
      }
      return "";
   }

   // TODO: @jellymble - see if this can be moved out to a shared utils place
   private getTaskDueDateDisplay(task: TaskEntity): string {
      if (!task.checklistDueDate || !task.checklistStartDate) return "";
      let checklistDueDateDisplay;

      if (task.checklistDueDateSetting == 1) {
         //if the setting flag shows that we should fully display...
         checklistDueDateDisplay = this.betterDate.formatBetterDate(
            task.checklistDueDate * 1000,
            "dateTimeWithSeconds",
         );
      } else {
         checklistDueDateDisplay = this.betterDate.formatBetterDate(
            task.checklistDueDate * 1000,
            "date",
         );
      }

      //if there is a start date then we need to prepend so it shows that it is between two dates
      if (
         task.checklistStartDate > 0 &&
         task.checklistStartDate < task.checklistDueDate
      ) {
         if (task.checklistStartDateSetting == 1) {
            //if the setting flag shows that we should fully display...
            checklistDueDateDisplay = `${this.betterDate.formatBetterDate(
               task.checklistStartDate * 1000,
               "dateTimeWithSeconds",
            )} - ${checklistDueDateDisplay}`;
         } else {
            checklistDueDateDisplay = `${this.betterDate.formatBetterDate(
               task.checklistStartDate * 1000,
               "date",
            )} - ${checklistDueDateDisplay}`;
         }
      }

      return checklistDueDateDisplay;
   }

   // TODO: @jellymble - this was copied over from manageUtil.ts -> see if this can be cleaned up - using angular date pipe, as an example.
   private timestampToDateStr(timestamp: number, includeHoursMinSec = false): string {
      const date = new Date(timestamp * 1000);
      let str;
      str = `${date.getFullYear()}/`;
      const month = date.getMonth() + 1;

      if (month < 10) {
         str += `0${month}`;
      } else {
         str += month;
      }

      str += "/";

      if (date.getDate() < 10) {
         str += `0${date.getDate()}`;
      } else {
         str += date.getDate();
      }

      if (includeHoursMinSec) {
         str += " ";
         let ampm = "AM";
         let hours = date.getHours();
         if (hours > 12) {
            ampm = "PM";
            hours = hours - 12;
         } else {
            ampm = "AM";
         }

         if (hours < 10) {
            str += `0${hours}:`;
         } else {
            str += `${hours}:`;
         }

         const minutes = date.getMinutes();
         if (minutes < 10) {
            str += `0${minutes}:`;
         } else {
            str += `${minutes}:`;
         }

         const seconds = date.getSeconds();
         if (seconds < 10) {
            str += `0${seconds}:`;
         } else {
            str += `${seconds}:`;
         }

         str += ` ${ampm}`;
      }

      return str;
   }
}
