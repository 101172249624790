@if (data(); as taskItem) {
   <div
      [ngClass]="
         isInModal
            ? 'name-priority-type-status-container-modal'
            : 'name-priority-type-status-container'
      "
      class="taskItemClass allowOffline cell-container"
   >
      <span class="no-float-desktop">
         @if (taskItem.taskTypeIcon) {
            <lim-ui-icon
               class="mobile-task-icon task-type-icon"
               [icon]="taskItem.taskTypeIcon"
            />
         }
         <!-- This is the name -->
         <span
            (click)="onViewTask()"
            [limbleHtml]="taskItem.name + ' - # ' + taskItem.id"
            class="text task-name"
         ></span>

         <!-- The icons -->
         @if (taskItem.hasComments) {
            <lim-ui-icon
               icon="commentRegular"
               iconSize="small"
               [placement]="'right'"
               limUiTooltip="{{ lang().oneOrMoreComments }}"
               (click)="onViewTask()"
            />
         }
         @if (taskItem.hasCompletionNotes) {
            <lim-ui-icon
               icon="noteSticky"
               iconSize="small"
               [placement]="'right'"
               limUiTooltip="{{ lang().completionNotes }}"
               (click)="onViewTask()"
            />
         }
         @if (taskItem.requesterInformation.length > 0) {
            <lim-ui-icon
               icon="phone"
               iconSize="small"
               [placement]="'right'"
               limUiTooltip="{{ lang().RequestorInfo }} + ':' + {{
                  taskItem.requesterInformation
               }}"
            />
         }
         @if (taskItem.approvalItemState) {
            @switch (taskItem.approvalItemState) {
               @case (1) {
                  <lim-ui-icon
                     icon="thumbsUp"
                     iconSize="small"
                     limUiTooltip="{{ lang().ApprovedOrDisapprovedHintTooltip }}"
                     (click)="onViewTask()"
                  />
               }
               @case (2) {
                  <lim-ui-icon
                     icon="thumbsDown"
                     iconColor="danger"
                     iconSize="small"
                     limUiTooltip="{{ lang().ApprovedOrDisapprovedHintTooltip }}"
                     (click)="onViewTask()"
                  />
               }
            }
         }

         @if (taskItem.assetName && taskItem.assetId) {
            <span
               >-
               <span (click)="onViewAsset()" class="green-color clickable"
                  >{{ taskItem.assetName }}
               </span>
            </span>
         }
      </span>

      <!-- The search filter -->
      @let search = searchHint();
      @if (search && search.length > 0) {
         <span class="search-hint desktop-search-hint">
            <span [limbleHtml]="search"> </span>
         </span>
      }
   </div>
}
