<lim-ui-card>
   <div>
      <lim-ui-icon icon="chartColumn" size="small" />

      {{ lang().CurrentAssetValue }} :
      <b>
         @if (scheduleFieldValue !== undefined) {
            {{
               scheduleFieldValue.valueContent?.toString() | localeCurrency: currencyCode
            }}
         }
      </b>
      |

      {{ lang().SalvageValue }} :
      <b>
         {{ schedule.salvageValue | localeCurrency: currencyCode }}
      </b>
   </div>
   <div>
      <!-- The canvas element needs to remain in a div -->
      <canvas class="line-chart" #chart></canvas>
      <div id="depreciation-line-chart-legend"></div>
   </div>
</lim-ui-card>
