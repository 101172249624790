<div class="paginator-container">
   @if (totalItems() > 0 && (totalItems() > pageSize() || page() > 1)) {
      <lim-ui-pagination
         [maxSize]="5"
         [rotate]="true"
         [page]="page()"
         [pageSize]="pageSize()"
         [collectionSize]="totalItems()"
         (pageChange)="onPageNumberChange($event)"
      />
   }
   @if (areResultsPerPageEnabled()) {
      <lim-ui-dropdown-results-per-page
         [selectedValue]="pageSize()"
         [label]="lang().itemsPerPage"
      >
         <ng-container menu>
            @for (num of ["5", "10", "20", "50", "100"]; track num) {
               <lim-ui-dropdown-text-item
                  [displayString]="num"
                  [outputString]="num"
                  (itemSelectedEvent)="onPageSizeChange(num)"
               />
            }
         </ng-container>
      </lim-ui-dropdown-results-per-page>
   }
</div>
