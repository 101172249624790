import type { OnInit } from "@angular/core";
import { inject, Component, forwardRef, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { lastValueFrom } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type {
   CycleCountScheduleEntity,
   ScheduleEntity,
} from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import { SchedulesApiService } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { TaskFormComponent } from "src/app/tasks/components/task-form/task-form.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { Task } from "src/app/tasks/types/task.types";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";

@Component({
   selector: "pop-task-schedule",
   templateUrl: "./popTaskSchedule.modal.component.html",
   styleUrls: ["./popTaskSchedule.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LoadingAnimationComponent,
      PrimaryButtonComponent,
      IconComponent,
      TooltipDirective,
      forwardRef(() => TaskFormComponent),
   ],
})
export class PopTaskSchedule implements OnInit {
   public loadingBar;
   public scheduleStarted;
   public showCheck;
   public task: Task | TaskTemplateEntity | undefined;
   public tempData;
   public singleScheduleAssigned: string = "";
   public scheduleStartDate;
   public resolve;
   public modalInstance;
   public data;

   private readonly modalService = inject(ModalService);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly paramsService = inject(ParamsService);
   private readonly betterDate = inject(BetterDate);
   private readonly credService = inject(CredService);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly schedulesApiService = inject(SchedulesApiService);
   private readonly manageLang = inject(ManageLang);
   private readonly manageLocation = inject(ManageLocation);
   private readonly taskTypeService = inject(TaskTypeService);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.initialize();
   }

   private async initialize() {
      const params = this.paramsService.params;

      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.loadingBar = true;
      this.scheduleStarted = false;
      this.showCheck = true;
      //if they are not logged in then we need to `close` the model because they are redirected to the login page.
      const loginAnswer = await this.manageLogin.checkLogin();
      if (loginAnswer.data.success === false) {
         this.modalInstance.close();
      }

      await this.setTaskMetaData();
   }

   private async setTaskMetaData() {
      this.loadingBar = false;
      const checklistID = this.data?.checklistID;

      this.task = await lastValueFrom(
         this.taskTemplatesApiService.getById(this.data.checklistID, {
            columns: "schedules",
         }),
      );
      const schedules = this.task?.schedules ?? [];

      let schedule = schedules.find((item) => item.scheduleID === this.data?.scheduleID);

      if (
         this.taskTypeService.isCycleCountSchedule(this.task) &&
         schedule !== undefined
      ) {
         schedule = await this.getScheduleDataforCycleCount(schedule);
      }
      this.setTaskOwnershipFromSchedule(schedule);
      this.tempData = {
         checklistID: checklistID,
         checklistTemplate: this.task.checklistTemplate,
         expand: false,
         guided: true,
         preview: true,
         checklist: this.task,
      };

      assert(schedule !== undefined);

      this.scheduleStartDate = this.betterDate.formatBetterDate(
         Number(schedule?.scheduleStartDate) * 1000,
         "date",
      );

      if (this.task === undefined) {
         const displayName = this.manageSchedule.getScheduleDisplayName(schedule);
         this.singleScheduleAssigned =
            displayName.length < 30 ? displayName : `${displayName.substring(0, 30)}...`;
      } else {
         const { displayName } = this.manageTask.getTaskAssignmentInfo(this.task);
         this.singleScheduleAssigned = displayName;
      }
   }

   private async getScheduleDataforCycleCount(
      schedule: ScheduleEntity | CycleCountScheduleEntity,
   ): Promise<ScheduleEntity | CycleCountScheduleEntity> {
      if (this.task === undefined) {
         return schedule;
      }
      const config = await this.manageLocation.getCycleCountSchedulesConfig(
         this.task.locationID,
      );
      if (schedule !== undefined) {
         const cycleCountConfig = config.data.cycleCountSchedules.find(
            (scheduleConfig) => scheduleConfig.reoccurID === schedule.reoccurID,
         );
         if (cycleCountConfig) {
            //if the schedule is found in the cycleCountConfig, then it should be a cycle count schedule
            const cycleCountSchedule: CycleCountScheduleEntity = {
               ...schedule,
               config: cycleCountConfig,
            };
            cycleCountSchedule.userID =
               cycleCountConfig.taskUserID ?? cycleCountSchedule.userID;
            cycleCountSchedule.profileID =
               cycleCountConfig.taskProfileID ?? cycleCountSchedule.profileID;
            return cycleCountSchedule;
         }
      }
      return schedule;
   }

   private setTaskOwnershipFromSchedule(schedule) {
      if (schedule && this.task) {
         this.task.userID = schedule.userID;
         this.task.profileID = schedule.profileID;
      }
   }

   start = () => {
      assert(this.task);
      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred43Fail, "danger", 10000);
         return;
      }
      this.scheduleStarted = true;
      this.loadingBar = true;
      this.showCheck = false;
      this.manageSchedule.startSingleSchedule(this.data?.scheduleID).then((answer) => {
         if (answer.data.success === true) {
            this.scheduleStarted = true;
            if (answer.data.tasks.length === 1) {
               this.tempData.checklistID = false;
               //single on then pop it up
               setTimeout(() => {
                  this.showCheck = true;
                  this.loadingBar = false;
                  this.tempData = {
                     checklistID: answer.data.tasks[0].checklistID,
                     expand: false,
                     guided: true,
                  };
               }, 1);
            } else {
               this.modalInstance.close();
            }
            this.alertService.addAlert(
               this.lang().successMsgTaskStartedAndNotification,
               "success",
               1000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   protected async updateSingleSchedule() {
      if (!this.data?.scheduleID) {
         console.error("No scheduleID provided in the pop-task-schedule modal");
         return;
      }
      if (!this.task) {
         console.error("No task provided in the pop-task-schedule modal");
         return;
      }
      const schedule = (
         await lastValueFrom(
            this.schedulesApiService.getList({
               filters: { scheduleIDs: this.data.scheduleID },
            }),
         )
      ).data?.[0];

      if (!schedule) {
         console.error("No schedule found for scheduleID", this.data.scheduleID);
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangePMAssignments,
         )
      ) {
         this.alertService.addAlert(this.lang().cred56Fail, "danger", 10000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };

      extraUsersOptions.arr.forEach((user) => {
         extraUsersOptions.key[user.userID] = user;
      });

      const modalData = {
         showAuditOptions: false,
         message: this.lang().ChangeTheTaskAssignmentForASingleScheduleMsg,
         title: this.lang().ChangeTheTaskAssignmentForASingleSchedule,
         locationID: schedule.locationID,
         extraUsers: extraUsersOptions.arr,
         defaultUser: schedule.userID,
         defaultProfile: schedule.profileID,
      };

      const instance = this.modalService.open(PickUserOrProfileLegacy);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: modalData,
         },
      };

      instance.result.then((pickUserData) => {
         if (pickUserData) {
            this.manageTask
               .updateSingleSchedule(
                  pickUserData.userID,
                  pickUserData.profileID,
                  pickUserData.multiUsers,
                  schedule.scheduleID,
               )
               .then(
                  (answer) => {
                     if (answer.data.lName === null) {
                        this.singleScheduleAssigned = answer.data.fName;
                     } else {
                        this.singleScheduleAssigned = `${answer.data.fName} ${answer.data.lName}`;
                     }

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  },
                  () => {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  },
               );
         }
      });
   }

   close = () => {
      this.modalInstance.close();
   };
}
