<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />

   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span [limbleHtml]="message"></span>

         <div [hidden]="!(errorMsg !== '')">
            <div
               class="alert alert-dismissable alert-danger"
               [hidden]="!(errorMsg !== '')"
            >
               <strong>{{ lang().Warning }}!</strong>
               <span [limbleHtml]="errorMsg"></span>
            </div>
         </div>

         @if (spinner) {
            <div class="loading-icon">
               <lim-ui-loading-animation />
            </div>
         }
      </lim-ui-info-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Finish"
      (successClick)="submit()"
   />
</lim-ui-modal>
