import { Injectable } from "@angular/core";
import { of, type Observable } from "rxjs";
import { FlannelApiService } from "src/app/shared/services/flannel-api-service";
import type {
   WorkRequestRejectReasonEntity,
   WorkRequestRejectReasonEntityFilters,
} from "src/app/tasks/components/shared/services/wr-reject-reason-api/wr-reject-reason-api.models";

@Injectable({
   providedIn: "root",
})
export class WrRejectReasonApiService extends FlannelApiService<
   WorkRequestRejectReasonEntity,
   WorkRequestRejectReasonEntityFilters
> {
   private constructor() {
      super("work-request-reject-reasons");
   }

   public get(
      filters: Array<WorkRequestRejectReasonEntityFilters>,
   ): Observable<WorkRequestRejectReasonEntity[]> {
      console.warn("getRejectReasons", filters);
      return of([
         {
            reasonID: 1,
            reason: "Reason 1",
         },
         {
            reasonID: 2,
            reason: "Reason 2",
         },
         {
            reasonID: 3,
            reason: "Reason 3",
         },
         {
            reasonID: 4,
            reason: "Reason 4",
         },
      ]);
   }
}
