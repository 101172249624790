import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import {
   CurrencyDtoSchema,
   type CurrencyDto,
} from "src/app/purchasing/currency/dto/mini-flannel/currency.dto";
import { validateFilter } from "src/app/shared/rxjs-operators/validate-filter";
import { environment } from "src/environments/environment";
import { z } from "zod";

@Injectable({ providedIn: "root" })
export class CurrenciesMiniFlannelApiService {
   private readonly http = inject(HttpClient);
   private readonly url = `${environment.servicesURL()}/currencies`;

   public list(): Observable<Array<CurrencyDto>> {
      return this.http
         .get(this.url, { withCredentials: true })
         .pipe(validateFilter(z.array(CurrencyDtoSchema)));
   }
}
