import { computed, inject, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Recurrence } from "src/app/schedules/recurrence.types";

@Injectable()
export class PickReoccurService {
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public initializeAccordingToReoccurType(
      recurrence: Recurrence,
      weekList: any[],
   ): {
      recurrence: Recurrence;
      reoccurType: string;
      monthFlip: string;
      yearFlip: string;
      updatedWeekList: any[];
   } {
      let reoccurType = "";
      let monthFlip = "";
      let yearFlip = "";
      if (Number(recurrence.reoccurType === 1)) {
         reoccurType = "day";
      }
      let changedRecurrence = recurrence;
      let updatedWeekList = weekList;
      if (Number(recurrence.reoccurType === 2)) {
         reoccurType = "week";
         changedRecurrence = this.changeReoccurFld2ToArray(recurrence);
         updatedWeekList = this.setSelectedOptionForWeekDropdown(recurrence, weekList);
      }

      if (Number(recurrence.reoccurType === 3)) {
         reoccurType = "month";
         monthFlip = "Numeric";
      }

      if (Number(recurrence.reoccurType === 4)) {
         reoccurType = "month";
         monthFlip = "Weekday";
      }

      if (Number(recurrence.reoccurType === 5)) {
         reoccurType = "year";
         yearFlip = "Numeric";
      }

      if (Number(recurrence.reoccurType === 6)) {
         reoccurType = "year";
         yearFlip = "Ordinal";
      }

      if (Number(recurrence.reoccurType === 7)) {
         reoccurType = "everyX";
         changedRecurrence = this.changeReoccurFld2ToArray(recurrence);
         changedRecurrence.reoccurFld3 = Number(changedRecurrence.reoccurFld3);
         changedRecurrence.reoccurFld4 = Number(changedRecurrence.reoccurFld4);
         changedRecurrence.reoccurFld5 = Number(changedRecurrence.reoccurFld5);
      }

      if (Number(recurrence.reoccurType === 8)) {
         reoccurType = "threshold";
         changedRecurrence = this.changeReoccurFld2ToArray(recurrence);
         changedRecurrence.reoccurFld3 = Number(changedRecurrence.reoccurFld3);
         changedRecurrence.reoccurFld4 = Number(changedRecurrence.reoccurFld4);
         changedRecurrence.reoccurFld5 = Number(changedRecurrence.reoccurFld5);
      }

      return {
         recurrence: changedRecurrence,
         reoccurType: reoccurType,
         monthFlip: monthFlip,
         yearFlip: yearFlip,
         updatedWeekList: updatedWeekList,
      };
   }

   public getMonthArray(): string[] {
      return [
         this.lang().January,
         this.lang().February,
         this.lang().March,
         this.lang().April,
         this.lang().May,
         this.lang().June,
         this.lang().July,
         this.lang().August,
         this.lang().September,
         this.lang().October,
         this.lang().November,
         this.lang().December,
      ];
   }

   public getEnglishDayArray(): string[] {
      return ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
   }

   public getEnglishMonthAbbreviationArray(): string[] {
      return [
         "jan",
         "feb",
         "mar",
         "apr",
         "may",
         "jun",
         "jul",
         "aug",
         "sep",
         "oct",
         "nov",
         "dec",
      ];
   }

   public getWeekSelectionArray(): {
      selected: boolean;
      day: string;
      num: number;
   }[] {
      return [
         {
            selected: false,
            day: "Monday",
            num: 1,
         },
         {
            selected: false,
            day: "Tuesday",
            num: 2,
         },
         {
            selected: false,
            day: "Wednesday",
            num: 3,
         },
         {
            selected: false,
            day: "Thursday",
            num: 4,
         },
         {
            selected: false,
            day: "Friday",
            num: 5,
         },
         {
            selected: false,
            day: "Saturday",
            num: 6,
         },
         {
            selected: false,
            day: "Sunday",
            num: 7,
         },
      ];
   }

   public getOrdinalTranslator(): {
      [key: string]: string;
   } {
      return {
         first: this.lang().firstFollowedByDayOfWeek,
         second: this.lang().secondFollowedByDayOfWeek,
         third: this.lang().thirdFollowedByDayOfWeek,
         fourth: this.lang().fourthFollowedByDayOfWeek,
      };
   }

   public getDayTranslator(): {
      [key: string]: string;
   } {
      return {
         mon: this.lang().Monday,
         tue: this.lang().Tuesday,
         wed: this.lang().Wednesday,
         thu: this.lang().Thursday,
         fri: this.lang().Friday,
         sat: this.lang().Saturday,
         sun: this.lang().Sunday,
      };
   }

   public getDayNumberTranslator(): {
      [key: string]: string;
   } {
      return {
         1: "1st",
         2: "2nd",
         3: "3rd",
         4: "4th",
         5: "5th",
         6: "6th",
         7: "7th",
         8: "8th",
         9: "9th",
         10: "10th",
         11: "11th",
         12: "12th",
         13: "13th",
         14: "14th",
         15: "15th",
         16: "16th",
         17: "17th",
         18: "18th",
         19: "19th",
         20: "20th",
         21: "21st",
         22: "22nd",
         23: "23rd",
         24: "24th",
         25: "25th",
         26: "26th",
         27: "27th",
         28: "28th",
         29: "29th",
         30: "30th",
         31: "31st",
      };
   }

   public getMonthTranslator(): { [key: string]: string } {
      return {
         jan: this.lang().January,
         feb: this.lang().February,
         mar: this.lang().March,
         apr: this.lang().April,
         may: this.lang().May,
         jun: this.lang().June,
         jul: this.lang().July,
         aug: this.lang().August,
         sep: this.lang().September,
         oct: this.lang().October,
         nov: this.lang().November,
         dec: this.lang().December,
      };
   }

   private changeReoccurFld2ToArray(recurrence: Recurrence): Recurrence {
      if (typeof recurrence.reoccurFld2 === "string") {
         recurrence.reoccurFld2 = recurrence.reoccurFld2.split(",");
      }
      return recurrence;
   }

   public setSelectedOptionForWeekDropdown(
      recurrence: Recurrence,
      weekList: any[],
   ): any[] {
      for (const key in recurrence.reoccurFld2) {
         for (const week of weekList) {
            if (week.num == recurrence.reoccurFld2[key]) {
               week.selected = true;
            }
         }
      }
      return weekList;
   }

   public isValidOrdinal(ordinal: string): boolean {
      return (
         ordinal === "first" ||
         ordinal === "second" ||
         ordinal === "third" ||
         ordinal === "fourth"
      );
   }
   public isValidDayOfTheWeek(dayOfTheWeek: string): boolean {
      return (
         dayOfTheWeek === "mon" ||
         dayOfTheWeek === "tue" ||
         dayOfTheWeek === "wed" ||
         dayOfTheWeek === "thu" ||
         dayOfTheWeek === "fri" ||
         dayOfTheWeek === "sat" ||
         dayOfTheWeek === "sun"
      );
   }

   public isValidMonthAbbreviation(monthAbbreviation: string): boolean {
      return (
         monthAbbreviation === "jan" ||
         monthAbbreviation === "feb" ||
         monthAbbreviation === "mar" ||
         monthAbbreviation === "apr" ||
         monthAbbreviation === "may" ||
         monthAbbreviation === "jun" ||
         monthAbbreviation === "jul" ||
         monthAbbreviation === "aug" ||
         monthAbbreviation === "sep" ||
         monthAbbreviation === "oct" ||
         monthAbbreviation === "nov" ||
         monthAbbreviation === "dec"
      );
   }

   public createTimeOfDay(
      timeOfDay: any,
      AMPM: string,
      hour: number,
      minute: number,
   ): string | null {
      if (timeOfDay == false) {
         return null;
      }
      let hourNumeric = Number(hour);
      if (AMPM === "PM" && hourNumeric !== 12) {
         hourNumeric = hourNumeric + 12;
      }
      if (AMPM === "AM" && hourNumeric === 12) {
         hourNumeric = 0;
      }
      let hourString = String(hourNumeric);
      if (hourString.length === 1) {
         hourString = `0${hourString}`;
      }
      return hourString + minute;
   }

   public setReoccurType(
      reoccurType: string | undefined,
      featureMeterThresholdPM: boolean,
      monthFlip: string | boolean,
      yearFlip: string | boolean,
   ): number | undefined {
      if (reoccurType === "day") {
         return 1;
      } else if (reoccurType === "week") {
         return 2;
      } else if (reoccurType === "month" && monthFlip === "Numeric") {
         return 3;
      } else if (reoccurType === "month" && monthFlip === "Weekday") {
         return 4;
      } else if (reoccurType === "year" && yearFlip === "Numeric") {
         return 5;
      } else if (reoccurType === "year" && yearFlip === "Ordinal") {
         return 6;
      } else if (reoccurType === "everyX") {
         if (!featureMeterThresholdPM) {
            return undefined;
         }
         return 7;
      } else if (reoccurType === "threshold") {
         if (!featureMeterThresholdPM) {
            return undefined;
         }
         return 8;
      }
      return undefined;
   }

   public spawnEndIsInvalid(recurrence: Recurrence): boolean {
      return (
         (recurrence.reoccurSpawnEnd &&
            recurrence.reoccurSpawn &&
            recurrence?.reoccurSpawnEnd?.valueOf() <
               recurrence?.reoccurSpawn?.valueOf()) === true
      );
   }
}
