import { DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { signalStore, withState, withMethods, patchState } from "@ngrx/signals";
import { catchError, filter, map, of, from } from "rxjs";
import { AssetErrorService } from "src/app/assets/services/asset-error.service";
import type {
   AssetFieldDefinition,
   AssetTemplateField,
} from "src/app/assets/services/asset-field.types";
import {
   AssetTemplateApiService,
   type AssetTemplate,
} from "src/app/assets/services/asset-template-api.service";
import { AssetTemplateFieldApiService } from "src/app/assets/services/asset-template-fields/asset-template-field-api.service";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { Lookup } from "src/app/shared/utils/lookup";
import {
   AssetsApiService,
   type AssetEntity,
} from "src/app/tasks/components/shared/services/assets-api";

export type TemplateState = {
   uiMode: "drafting" | "published" | "editing" | "applying" | undefined;
   templateID: number | undefined;
   template: AssetTemplate | undefined;
   fields: AssetTemplateField[];
   assets: {
      loading: boolean;
      total: number;
      page: number;
      data: AssetEntity[];
   };
};

const initialState: TemplateState = {
   uiMode: undefined,
   templateID: undefined,
   template: undefined,
   fields: [],
   assets: {
      loading: true,
      total: 0,
      page: 0,
      data: [],
   },
};

const statusToUIModeMap: Record<AssetTemplate["Status"], TemplateState["uiMode"]> = {
   draft: "drafting",
   published: "published",
   editing: "published", // If a template is in the status of editing then the user has finished going through the editing process, and the view shown to the user is the published view.
   applying: "published", // If a template is in the status of applying then the user has finished going through the applying process, and the view shown to the user is the published view.
};

export const TemplatesPageStore = signalStore(
   { providedIn: "root" },
   withState(initialState),
   withMethods(
      (
         store,
         templatesAPI = inject(AssetTemplateApiService),
         fieldsAPI = inject(AssetTemplateFieldApiService),
         assetErrorService = inject(AssetErrorService),
         assetsAPI = inject(AssetsApiService),
         destroyRef = inject(DestroyRef),
      ) => ({
         setUiMode(uiMode: TemplateState["uiMode"]) {
            patchState(store, { uiMode });
         },
         setTemplateID(templateID: number) {
            this.clear();
            patchState(store, { templateID });
            this.load();
         },
         addFields(fields: AssetTemplateField[]) {
            let viewOrder = store.fields().length + 1;

            patchState(store, {
               fields: [
                  ...store.fields(),
                  ...fields.map((field) => ({ ...field, viewOrder: viewOrder++ })),
               ],
            });
         },
         updateFieldDefinitions(definitions: AssetFieldDefinition[]) {
            const fieldsMap = new Lookup<"fieldID", AssetTemplateField>(
               "fieldID",
               store.fields(),
            );

            definitions.forEach((definition) => {
               const field = fieldsMap.get(definition.fieldID);
               if (field) {
                  fieldsMap.set(field.fieldID, { ...field, ...definition });
               }
            });

            patchState(store, { fields: Array.from(fieldsMap.values()) });
         },
         removeField(fieldID: number) {
            let fields = store.fields().map((field) => ({ ...field }));
            fields = fields.filter((field) => field.fieldID !== fieldID);

            fields.forEach((field, index) => {
               field.viewOrder = index + 1;
            });

            patchState(store, { fields });
         },
         moveField(oldIndex: number, newIndex: number) {
            // Store is immutable, so we need to create a new array
            const fields = store.fields().map((field) => ({ ...field }));

            // Remove the field from the old index and insert it at the new index
            const movedField = fields.splice(oldIndex, 1)[0];
            fields.splice(newIndex, 0, movedField);

            // Update the viewOrder for all fields
            fields.forEach((field, index) => {
               field.viewOrder = index + 1;
            });

            patchState(store, { fields: orderBy(fields, "viewOrder") });
            return movedField;
         },
         setTemplateStatus(status: AssetTemplate["Status"]) {
            const template = store.template();
            if (!template) return;
            patchState(store, { template: { ...template, Status: status } });
         },
         load() {
            this.fetchTemplate();
            this.fetchFields();
            this.fetchAssets();
         },
         clear() {
            patchState(store, {
               uiMode: undefined,
               templateID: undefined,
               template: undefined,
               fields: [],
               assets: {
                  loading: true,
                  total: 0,
                  page: 0,
                  data: [],
               },
            });
         },
         fetchTemplate() {
            const templateID = store.templateID();
            if (!templateID) {
               patchState(store, { template: undefined });
               return;
            }

            templatesAPI
               .getById(templateID)
               .pipe(
                  takeUntilDestroyed(destroyRef),
                  catchError((err) => {
                     assetErrorService.handleRequestError(err);
                     return of(null);
                  }),
                  filter((template) => template !== null),
               )
               .pipe(
                  map((data) => {
                     if (Array.isArray(data)) {
                        return data[0];
                     }
                     return data;
                  }),
               )
               .subscribe((template) => {
                  const uiMode = statusToUIModeMap[template.Status];
                  patchState(store, { template, uiMode });
               });
         },
         fetchFields() {
            const templateID = store.templateID();
            if (!templateID) {
               patchState(store, { fields: [] });
               return;
            }

            fieldsAPI
               .getList({
                  filters: {
                     templateID,
                  },
               })
               .pipe(
                  takeUntilDestroyed(destroyRef),
                  map((response) => {
                     return orderBy(response.data ?? [], "viewOrder");
                  }),
                  catchError((err) => {
                     assetErrorService.handleRequestError(err);
                     return of(null);
                  }),
                  filter((fields) => fields !== null),
               )
               .subscribe((fields) => {
                  patchState(store, { fields });
               });
         },
         fetchAssets() {
            const templateID = store.templateID();
            if (!templateID) {
               patchState(store, { assets: { ...store.assets(), total: 0 } });
               return;
            }

            patchState(store, { assets: { ...store.assets(), loading: true } });

            from(
               assetsAPI.getList({
                  pagination: { page: store.assets.page() },
                  filters: { templateIDs: [templateID] },
               }),
            )
               .pipe(takeUntilDestroyed(destroyRef))
               .subscribe((response) => {
                  patchState(store, {
                     assets: {
                        ...store.assets(),
                        data: response.data ?? [],
                        total: response.total ?? 0,
                        loading: false,
                     },
                  });
               });
         },
      }),
   ),
);
