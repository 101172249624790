import { inject, Injectable } from "@angular/core";
import type { TaskCommentViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskCommentViewModelMapperService } from "src/app/tasks/components/shared/services/task-view-model-factory/mappers/task-comment-view-model-mapper.service";
import type {
   TaskComment,
   TaskEntity,
} from "src/app/tasks/components/shared/services/tasks-api/task-api.models";

@Injectable({ providedIn: "root" })
export class TaskCommentViewModelBuilderService {
   private readonly taskCommentViewModelMapperService = inject(
      TaskCommentViewModelMapperService,
   );

   public buildTaskCommentsViewModel(task: TaskEntity): Array<TaskCommentViewModel> {
      return (
         task.comments?.map((taskComment: TaskComment) =>
            this.buildTaskCommentViewModel(taskComment),
         ) ?? []
      );
   }

   public buildTaskCommentViewModel(taskComment: TaskComment): TaskCommentViewModel {
      return {
         ...taskComment,
         userDisplayName:
            this.taskCommentViewModelMapperService.getUserDisplayName(taskComment),
      };
   }
}
