import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import {
   FlannelApiService,
   type ListResponse,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type {
   TasksSchedulesCombinedEntity,
   TasksSchedulesCombinedFilters,
} from "src/app/tasks/components/shared/services/tasks-schedules-combined-api/tasks-schedules-combined.models";

@Injectable({
   providedIn: "root",
})
export class TasksSchedulesCombinedApiService extends FlannelApiService<
   TasksSchedulesCombinedEntity,
   TasksSchedulesCombinedFilters
> {
   private constructor() {
      super("tasks-schedules-combined");
   }

   public override getList(
      // eslint-disable-next-line typescript/no-unused-vars -- temporary until we used real API
      requestOptions?: Partial<RequestOptions<TasksSchedulesCombinedFilters>>,
   ): Observable<Partial<ListResponse<TasksSchedulesCombinedEntity>>> {
      // remove assets, locationName, partsDetails, commentsDetails extra columns
      // from the request options, since they are not supported yet
      if (requestOptions?.columns) {
         requestOptions.columns = "";
      }
      return super.getList(requestOptions);
   }
}
