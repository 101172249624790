<lim-ui-modal [showWatermark]="true" [extraLarge]="true">
   <lim-ui-basic-modal-header
      [title]="lang()?.View + ' ' + task?.checklistName"
      (closeModal)="close()"
   />

   <lim-ui-modal-body class="modal-body">
      <lim-ui-panel>
         @if (loadingBar) {
            <div>
               <div class="loading-icon">
                  <lim-ui-loading-animation />
               </div>
            </div>
         }

         @if (task && task.checklistTemplate !== 5) {
            <div class="center-margin-bottom">
               @if (!loadingBar && !scheduleStarted) {
                  <lim-ui-primary-button
                     class="start-button btn-raised btn btn-success"
                     (click)="start()"
                  >
                     {{ lang()?.StartThisUpcomingTask }}
                  </lim-ui-primary-button>
               }
            </div>
         }

         @if (!loadingBar && !scheduleStarted) {
            <div class="center-margin-bottom">
               <div
                  class="cursor marginTop5InTask inline-link"
                  (click)="updateSingleSchedule()"
               >
                  {{ lang()?.AssignedTo }}:
                  <lim-ui-icon
                     class="user-icon"
                     icon="user"
                     limUiTooltip="{{ lang()?.whoThisTaskIsAssignedTo }}"
                     data-placement="bottom"
                  />
                  <a class="link-styling inline-link">
                     {{ singleScheduleAssigned && singleScheduleAssigned }}
                  </a>
                  {{ lang()?.Due }} {{ scheduleStartDate }}
               </div>
            </div>
         }

         @if (showCheck) {
            <span>
               @if (tempData?.checklistID) {
                  <task-form [data]="tempData" />
               }
            </span>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
