import { InjectionToken, type Provider, type Resource } from "@angular/core";
import { PmSuggestionsUserPreferencesFromManageUserService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/user-preferences/pm-suggestions-user-preferences-from-manage-user.service";
import { PmSuggestionsUserPreferencesFromMemoryService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/user-preferences/pm-suggestions-user-preferences-from-memory.service";

export type PmSuggestionsUserPreferencesInterface = {
   /** `null` indicates that the user has not set their preference. */
   itemsPerPage: Resource<number | null>;
};

export const PmSuggestionsUserPreferences =
   new InjectionToken<PmSuggestionsUserPreferencesInterface>(
      "PmSuggestionsUserPreferences",
   );

export const providePmSuggestionsUserPreferencesFromManageUser = (): Provider => {
   return {
      provide: PmSuggestionsUserPreferences,
      useClass: PmSuggestionsUserPreferencesFromManageUserService,
   };
};

export const providePmSuggestionsUserPreferencesFromMemory = (): Provider => {
   return {
      provide: PmSuggestionsUserPreferences,
      useClass: PmSuggestionsUserPreferencesFromMemoryService,
   };
};
