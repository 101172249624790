<lim-ui-card>
   <header>
      <h3>
         <lim-ui-icon
            icon="circleQuestionRegular"
            limUiTooltip="{{ lang().BelowIsTheEstimatedUseOfThisPartForTheNextYear }}"
         />
         {{ lang().Forecast }}
      </h3>
   </header>
   <div>
      <canvas
         [id]="'barChart1' + part().partID"
         (click)="viewBarChartItem($event)"
      ></canvas>
      <div id="part-reports-forecast-chart-legend"></div>
   </div>
</lim-ui-card>
