export interface TutorialData {
   name?: string;
   title: string;
   cta: string;
   order: number;
}

export abstract class Tutorial {
   public name?: string;
   public title: string = "";
   public cta: string = "";
   public order: number = 0;
   public isVideo: boolean = false;
   public isDoc: boolean = false;

   public constructor(data: TutorialData) {
      Object.assign(this, data);
   }

   public get type(): string {
      return this.isVideo ? "video" : "doc";
   }
}
