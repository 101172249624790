<div>
   <div class="lim-ui-list-item part-list-item-wrapper">
      <span class="part-list-item-details">
         <span class="part-list-item-details-group">
            @if (task().checklistStatusID === 0 || task().checklistStatusID === null) {
               <span class="part-availability-icon">
                  @if (
                     (part().suggestedNumber ?? 0) > 0 &&
                     partsOverReserved().has(part().partID) &&
                     currentUser.workOrderUser === 1
                  ) {
                     <lim-ui-icon
                        icon="circleExclamation"
                        iconSize="small"
                        iconColor="danger"
                        placement="right"
                        limUiTooltip="{{ lang().NotEnoughPartsInStockTooltip }}"
                     />
                  }

                  @if (
                     (part().suggestedNumber ?? 0) > 0 &&
                     partsOverReserved().has(part().partID) &&
                     currentUser.workOrderUser !== 1 &&
                     task().checklistTemplate !== 1
                  ) {
                     <span>
                        <lim-ui-icon
                           icon="filePowerpoint"
                           iconSize="small"
                           iconColor="danger"
                           (click)="startPoForPart.emit(part().partID)"
                           placement="right"
                           limUiTooltip="{{ lang().NotEnoughPartsInStockOrderPOTooltip }}"
                        />
                     </span>
                  }

                  @if (!partsOverReserved().has(part().partID)) {
                     <lim-ui-icon
                        icon="check"
                        iconSize="small"
                        iconColor="success"
                        placement="right"
                        limUiTooltip="{{ lang().EnoughPartsInStockTooltip }}"
                     />
                  }
               </span>
            }

            <lim-ui-icon icon="gears" iconSize="small" />

            <span class="part-quantity-column">
               @if (task().checklistUserCompleted) {
                  <span>
                     @if (!tempEdit()) {
                        <span>
                           {{ part().usedNumber }}
                        </span>
                     }

                     @if (tempEdit()) {
                        <span>
                           <!-- Have to have value here so printing works -->
                           <input
                              type="number"
                              class="form-control"
                              onclick="this.focus();"
                              [(ngModel)]="partUsageModel"
                              value="{{ part().usedNumber }}"
                              (blur)="updatePartUsedNumber()"
                           />
                        </span>
                     }
                  </span>
               }

               @if (task().checklistUserCompleted === 0) {
                  <span>
                     <!-- Have to have value here so printing works -->
                     @if (taskEditable()) {
                        <input
                           type="number"
                           min="0"
                           onclick="this.focus();"
                           [(ngModel)]="partUsageModel"
                           (blur)="updatePartSuggestedNumber()"
                           [ngClass]="{
                              'highlight-invalid': partUnderStocked(),
                           }"
                        />
                     }

                     @if (!taskEditable()) {
                        <span>{{ part().suggestedNumber }}</span>
                     }
                  </span>
                  @if (unitOfMeasureService.isFeatureEnabled()) {
                     <span [limUiTooltip]="part().partID | partUnitOfMeasure: 'singular'">
                        {{ part().partID | partUnitOfMeasure: "short" }}
                     </span>
                  }
               }
            </span>

            @if (task().checklistTemplate === 0 && taskEditable()) {
               <lim-ui-minimal-icon-button
                  class="deletion-icon lim-ui-show-on-tablet"
                  icon="trashCanRegular"
                  iconSize="small"
                  iconColor="danger"
                  (click)="removePartFromTask.emit(part())"
                  placement="left"
                  limUiTooltip="{{ lang().RemovePartTooltip }}"
               />
            }
         </span>

         <span class="part-list-item-details-group">
            <span>
               <span
                  (click)="popPartModal.emit(part())"
                  class="part-name"
                  [limbleHtml]="part().partName"
               ></span>
               @let partNumber = part().partNumber;
               @if (partNumber !== null && partNumber.length > 0) {
                  <span>
                     -
                     <span [limbleHtml]="part().partNumber"></span>
                  </span>
               }
            </span>
            @if (part().partLocation; as partLocation) {
               @if (partLocation.length !== null && partLocation.length > 0) {
                  <span>- <span [limbleHtml]="partLocation"></span></span>
               }
            }
            -
            <span [limbleHtml]="getPartLocationName(part().locationID)"></span>

            @if (task().checklistUserCompleted) {
               <span>
                  -
                  <span [limbleHtml]="getPartLocationName(part().locationID)"></span>
                  -
                  @if (currencyService.isMultiCurrencyEnabled()) {
                     @let currencyCode = getCurrencyCode(part().locationID);
                     {{ part().usedPrice | localeCurrency: currencyCode }}
                     {{ lang().partEachTotaling }}
                     <b [limUiTooltip]="currencyCode | currencyName">
                        {{
                           (part().usedPrice ?? 0) * (part().usedNumber ?? 0)
                              | localeCurrency: currencyCode
                        }}</b
                     >
                  } @else {
                     {{ part().usedPrice | betterCurrency }}
                     {{ lang().partEachTotaling }}
                     <b>{{
                        (part().usedPrice ?? 0) * (part().usedNumber ?? 0)
                           | betterCurrency
                     }}</b>
                  }

                  @let partSelectedPO = getPartSelectedPO(part());
                  @if (
                     (part().poItemID ?? 0) > 0 &&
                     currentUser.workOrderUser !== 1 &&
                     partSelectedPO
                  ) {
                     <span>
                        -
                        <span
                           class="po-number"
                           (click)="popPoComponent.emit(partSelectedPO.poID)"
                           >PO {{ partSelectedPO.poNumber }}</span
                        >
                     </span>
                  }
               </span>
            }
         </span>

         @if (task().checklistTemplate === 0 && taskEditable()) {
            <lim-ui-minimal-icon-button
               [superMinimal]="true"
               class="deletion-icon lim-ui-hide-on-tablet"
               icon="trashCanRegular"
               iconSize="small"
               iconColor="danger"
               (click)="removePartFromTask.emit(part())"
               placement="left"
               limUiTooltip="{{ lang().RemovePartTooltip }}"
            />
         }
      </span>
      <part-pending-pos [part]="part()" (popPoComponent)="popPoComponent.emit($event)" />
   </div>
</div>
