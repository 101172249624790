import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LimbleHtmlDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "part-location-field",
   templateUrl: "./partLocation.element.component.html",
   styleUrls: ["../shared-field-styles.scss"],
   imports: [NgClass, ContenteditableDirective, FormsModule, LimbleHtmlDirective],
})
export class PartLocation implements OnInit {
   @Input() public partID: number | undefined;
   @Input() public usedIn: "table" | "listItem" | undefined = undefined;
   public part: Part | undefined;
   public credChangePartLocation;
   public oldValue: string | null | undefined;

   private readonly credService = inject(CredService);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      assert(this.partID);
      this.part = this.manageParts.getPart(this.partID);
      assert(this.part);

      this.credChangePartLocation = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ChangePartLocation,
      );

      this.oldValue = this.part.partLocation;
   }

   public async updatePartLocation() {
      assert(this.part);
      if (this.oldValue === this.part?.partLocation) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartLocation,
         )
      ) {
         this.alertService.addAlert(this.lang().cred74Fail, "danger", 10000);
         return;
      }

      // If you delete everything in the field, it's saving as <br />, so we need to clean it up
      if (this.part.partLocation === "<br />" || this.part.partLocation === "<br>") {
         this.part.partLocation = "";
      }

      const answer = await this.manageParts.updatePartLocation(this.part);
      if (answer.data.success === true) {
         this.oldValue = this.part?.partLocation;
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }
}
