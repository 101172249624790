import { NgClass } from "@angular/common";
import {
   Component,
   computed,
   inject,
   input,
   model,
   output,
   signal,
   type OnInit,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   IconComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { ExtraBatch } from "src/app/parts/types/extra-batch/extra-batch.types";
import type { TaskEntityPart } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { CurrencyNamePipe } from "src/app/purchasing/currency/pipes/currency-name.pipe";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { CurrencyService } from "src/app/shared/services/currency.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskPartsAvailabilityService } from "src/app/tasks/components/task-form/task-parts-availability.service";
import { PartPendingPOsComponent } from "src/app/tasks/components/task-form/task-parts-list/part-pending-pos/part-pending-pos.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

type PartID = number;

export type UpdatePartUsedNumberEvent = {
   partID: PartID;
   usedNumber: number;
};

export type PurchaseOrderWithExtraBatches = PurchaseOrder & {
   currentState: PurchaseOrderCurrentState | undefined;
   extraBatch?: ExtraBatch;
};

@Component({
   selector: "task-parts-list-item",
   imports: [
      IconComponent,
      TooltipDirective,
      FormsModule,
      NgClass,
      PartUnitOfMeasurePipe,
      LimbleHtmlDirective,
      BetterCurrencyPipe,
      MinimalIconButtonComponent,
      PartPendingPOsComponent,
      LocaleCurrencyPipe,
      CurrencyNamePipe,
   ],
   templateUrl: "./task-parts-list-item.component.html",
   styleUrl: "./task-parts-list-item.component.scss",
})
export class TaskPartsListItemComponent implements OnInit {
   public readonly part = input.required<TaskEntityPart>();
   public readonly task = input.required<TaskDataViewerViewModel>();

   public readonly taskFormState = input.required<{
      tempEdit: boolean;
      taskEditable: boolean;
      disableAlerts: boolean;
   }>();

   public readonly startPoForPart = output<PartID>();
   // public readonly updatePartUsedNumber = output<TaskEntityPart>();
   public readonly updatedPartSuggestedNumber = output<TaskEntityPart>();
   public readonly removePartFromTask = output<TaskEntityPart>();
   public readonly popPartModal = output<TaskEntityPart>();
   public readonly partsUpdated = output();
   // Outputs the po ID
   public readonly popPoComponent = output<number>();

   private readonly manageUser = inject(ManageUser);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected readonly manageLocation = inject(ManageLocation);
   protected readonly managePO = inject(ManagePO);
   private readonly alertService = inject(AlertService);
   private readonly manageParts = inject(ManageParts);
   protected readonly manageTask = inject(ManageTask);
   private readonly taskPartsAvailabilityService = inject(TaskPartsAvailabilityService);
   protected currencyService = inject(CurrencyService);
   protected currencyCode = "";

   protected readonly showPOsOnPart = signal(false);

   protected readonly currentUser = this.manageUser.getCurrentUser();

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly disableAlerts = computed<boolean>(() => {
      return this.taskFormState().disableAlerts;
   });

   protected readonly taskEditable = computed<boolean>(() => {
      return this.taskFormState().taskEditable;
   });

   protected readonly tempEdit = computed<boolean>(() => {
      return this.taskFormState().tempEdit;
   });

   protected readonly partUnderStocked = computed<boolean>(() => {
      // Ensure this is called whenever the model is updated
      const partsUsage = this.partUsageModel();
      if (partsUsage === 0) {
         return false;
      }

      const partToCheck = this.part();
      if (this.task().checklistUserCompleted) {
         partToCheck.usedNumber = partsUsage;
      } else {
         partToCheck.suggestedNumber = partsUsage;
      }

      const partsUnderStocked = this.taskPartsAvailabilityService.partsUnderStocked([
         partToCheck,
      ]);
      return partsUnderStocked.length > 0;
   });

   protected readonly partsOverReserved = computed<Set<number>>(() => {
      const partReservationDetails = this.task()?.partsDetails;
      if (!partReservationDetails || partReservationDetails === "noPartsNeeded") {
         return new Set();
      }
      return new Set(partReservationDetails.overReservedParts.map((part) => part.partID));
   });

   protected readonly previousPartsSuggestedNumbers = new Map<number, number>();
   protected readonly previousPartUsage = signal(0);
   protected partUsageModel = model(0);

   protected updatePartUsedNumber(): void {
      const partsUsed = this.partUsageModel();
      if (partsUsed === null || typeof partsUsed !== "number") {
         this.alertService.addAlert(this.lang().PleaseEnterANumber, "warning", 3000);
         this.partUsageModel.set(this.previousPartUsage());
         return;
      }

      //first we need to see what we want to update it to
      if ((this.part().poItemID ?? 0) > 0) {
         const diff = partsUsed - this.previousPartUsage();
         const extraBatches = Array.from(this.manageParts.getExtraBatches());
         const extraBatch = extraBatches.find(
            (batch) => batch.poItemID === this.part().poItemID,
         );

         if (diff > 0) {
            let aboveQtyAvailable = false;
            let remaining;
            if (extraBatch === undefined) {
               //the extra batch doesn't exist on the front which means there are none available
               aboveQtyAvailable = true;
               remaining = 0;
            } else {
               extraBatch.partQty = extraBatch.partQty ?? 0;
               extraBatch.partQtyUsed = extraBatch.partQtyUsed ?? 0;
               remaining = extraBatch.partQty - extraBatch.partQtyUsed;
               if (diff > remaining) {
                  aboveQtyAvailable = true;
               }
            }

            if (aboveQtyAvailable) {
               //if we are above the available quantity we need to alert and set this back.
               this.alertService.addAlert(
                  `${this.lang().ThePartThisPOIsLInkedToHasUsedUpAllAvailableParts} | ` +
                     `<b>${remaining} ${this.lang().PartsRemaining}</b>`,
                  "danger",
                  6000,
               );
               this.part().usedNumber = this.previousPartUsage();
               this.partUsageModel.set(this.previousPartUsage());
               return;
            }
         }
      }

      this.part().usedNumber = partsUsed;

      this.manageTask.updatePartUsedNumber(this.part()).then((answer) => {
         if (answer.data.success === true) {
            this.previousPartUsage.set(partsUsed);
            if (answer.data.sortData === true) {
               const partTemp = this.manageParts.getPart(this.part().partID);
               assert(partTemp);
               partTemp.partOverstocked = answer.data.partOverstocked;

               const extraBatch = answer.data.returnExtraBatch || false;
               if (extraBatch) {
                  const ebLookup = this.manageParts.getExtraBatch(
                     extraBatch.extraBatchID,
                  );
                  if (ebLookup) {
                     ebLookup.partQty = extraBatch.partQty;
                     ebLookup.partQtyUsed = extraBatch.partQtyUsed;
                  } else {
                     this.manageParts.addExtraBatchToLookup(extraBatch);
                  }
               } else {
                  partTemp.partQty = answer.data.adjustedQty;
               }
               this.part().usedNumber = Number(answer.data.usedNumber);
               this.manageParts.calculatePartData(partTemp);
            }

            if (!this.disableAlerts()) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            }
         } else {
            this.partUsageModel.set(this.previousPartUsage());
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   public ngOnInit() {
      if (this.task().checklistUserCompleted) {
         this.partUsageModel.set(this.part().usedNumber ?? 0);
         this.previousPartUsage.set(this.part().usedNumber ?? 0);
      } else {
         this.partUsageModel.set(this.part().suggestedNumber ?? 0);
         this.previousPartUsage.set(this.part().suggestedNumber ?? 0);
      }
      this.showPOsOnPart.set(false);
   }

   protected getCurrencyCode(locationID: number): string {
      return this.currencyService.getCurrencyCodeByLocationID(locationID);
   }
   protected getPartLocationName(locationID: number) {
      const locations = this.manageLocation.getLocations();
      const partLocation = locations.find(
         (location) => location.locationID === locationID,
      );
      return partLocation?.locationName ?? "";
   }

   protected getPartSelectedPO(part: TaskEntityPart): PurchaseOrder | undefined {
      if (part.poItemID === null || part.poItemID === 0) {
         return undefined;
      }
      const partPoId = this.managePO.getPurchaseOrderItem(part.poItemID)?.poID;
      if (partPoId === undefined) {
         return undefined;
      }
      return this.managePO.getPurchaseOrder(partPoId);
   }

   protected updatePartSuggestedNumber(): void {
      const partsSuggestedNumber = this.partUsageModel();
      if (typeof partsSuggestedNumber === "number") {
         this.part().suggestedNumber = partsSuggestedNumber;
         this.manageTask.updatePartSuggestedNumber(this.part()).then((answer) => {
            if (answer.data.success === true) {
               this.previousPartUsage.set(partsSuggestedNumber);
               this.previousPartsSuggestedNumbers.set(
                  this.part().partID,
                  this.part().suggestedNumber ?? 0,
               );
               if (!this.disableAlerts()) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               }
            } else {
               this.part().suggestedNumber = this.previousPartUsage();
               this.partUsageModel.set(this.previousPartUsage());
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      } else {
         this.partUsageModel.set(this.previousPartUsage());
         this.alertService.addAlert(this.lang().PleaseEnterANumber, "warning", 3000);
      }
   }
}
