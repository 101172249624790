import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DraggableDirective, type TreeBranch } from "@limble/limble-tree";
import { IconComponent, MinimalIconButtonComponent } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";

@Component({
   selector: "status-tree-item",
   templateUrl: "./statusTreeItem.element.component.html",
   styleUrls: ["./statusTreeItem.element.component.scss"],
   imports: [
      DraggableDirective,
      IconComponent,
      ContenteditableDirective,
      FormsModule,
      MinimalIconButtonComponent,
   ],
})
export class StatusTreeItem implements OnInit {
   @Input() treeBranch?: TreeBranch<StatusTreeItem>;
   @Input() updateStatuses;
   @Input() statuses;
   @Input() deleteStatus;
   @Input() updateSingleStatus;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public status;

   public ngOnInit() {
      this.status = this.treeBranch?.meta().nodeData;
   }
}
