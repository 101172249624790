import type { OnDestroy, OnInit } from "@angular/core";
import { Component, computed, inject, Input } from "@angular/core";
import {
   IconComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageAssetServ } from "src/app/assets/services/manageAssetServ";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldValueFile } from "src/app/assets/types/field/value/file/file.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type AssetHierarchyNode = HierarchyNode & {
   assetID?: number;
   uniqueID?: string;
   parentAssetID: number | null;
};

@Component({
   selector: "asset-children-tab",
   templateUrl: "./asset-children-tab.component.html",
   styleUrls: ["./asset-children-tab.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      SearchAllWrapperComponent,
      SearchBoxComponent,
      NoSearchResults,
      HierarchyContainerLegacy,
      PrimaryButtonComponent,
   ],
})
export class AssetChildrenTabComponent implements OnInit, OnDestroy {
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFilters = inject(ManageFilters);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly manageAssetServ = inject(ManageAssetServ);
   private readonly manageObservables = inject(ManageObservables);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);

   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input() asset: Asset | undefined;
   @Input() restrict: boolean;
   public manageAssetsCred;
   public childAssetsIndex: any = {};
   public assets: Lookup<"assetID", Asset> | undefined;
   public searchBar;
   public treeData: Array<AssetHierarchyNode> = [];
   public assetNodes: Array<AssetHierarchyNode>;
   public noSearchResults;
   public assetsWatchVarSub;
   public hierarchyOptions: HierarchyOptions = {
      idKey: "assetID",
      selection: {
         submitOnClick: true,
      },
   };
   public fields: Lookup<"fieldID", AssetField> = new Lookup("fieldID");
   public fieldValues: Lookup<"valueID", AssetFieldValue> = new Lookup("valueID");
   public fieldValueFiles: Lookup<"fileID", AssetFieldValueFile> = new Lookup("fileID");
   public assetSearchHints: Map<number, { searchHint: string; searchFound: boolean }> =
      new Map();

   public constructor() {
      this.restrict = false;
      this.assetNodes = [];
   }

   public ngOnInit() {
      assert(this.asset);
      this.manageAssetsCred = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ViewManageAssets,
      );

      this.buildView();
      this.setFieldData();
      this.hierarchyOptions.submit = this.popAsset;

      this.assetsWatchVarSub = this.manageAsset.assetState().subscribe(() => {
         this.buildView();
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.assetsWatchVarSub);
   }

   buildView = () => {
      assert(this.asset);
      const childAssets: Array<Asset> = this.manageAsset
         .findChildrenIDs(this.asset, [])
         .map((childAssetID) => {
            const asset = this.manageAsset.getAsset(childAssetID);
            assert(asset);
            return asset;
         });
      this.assets = new Lookup(
         "assetID",
         childAssets.filter((asset) => asset.assetDeleted === 0),
      );

      this.assets = this.sortAssets(this.assets);
      this.noSearchResults = false;

      if (this.searchBar?.length) {
         this.buildSearchHierarchy();
      } else {
         this.buildTreeData(this.assets);
         this.buildFullHierarchy();
      }
   };

   buildFullHierarchy = () => {
      this.treeData = this.filterTreeDataToRootItems(this.assetNodes);
   };

   buildSearchHierarchy = () => {
      assert(this.assets);
      for (const asset of this.assets) {
         this.assetSearchHints.set(asset.assetID, { searchHint: "", searchFound: false }); //this variable means that this asset was specifically found from search.  It was not found just because it happened to be the parent of an asset that was explicitly found
      }
      const assetsLookup: Lookup<"assetID", Asset> = new Lookup("assetID");
      for (const assetLookup of this.assets) {
         assetsLookup.set(assetLookup.assetID, assetLookup);
      }
      const filteredAssets = this.manageFilters.filterAssetsToNameAndTextFields_refactor(
         assetsLookup,
         this.fields,
         this.fieldValues,
         this.fieldValueFiles,
         this.assetSearchHints,
         {
            search: this.searchBar,
            hier: true,
            field: false,
            includeChildren: true,
         },
         this.manageAsset,
         this.betterDate,
      );

      this.buildTreeData(filteredAssets);

      this.treeData = this.filterTreeDataToRootItems(this.assetNodes);

      this.findAndUpdateParentTree(this.assets, "collapsed", false, true);

      if (!this.treeData.length) {
         this.noSearchResults = true;
      }
   };

   findAndUpdateParentTree = (
      nodes: Lookup<"assetID", Asset>,
      propertyToChange: string,
      updatedPropertyValue: boolean,
      firstPass: boolean,
   ) => {
      const parentAssets: any = [];
      for (const node of nodes) {
         const assetNode = this.childAssetsIndex[node.assetID];
         if (!assetNode) {
            continue;
         }

         if (!firstPass) {
            this.childAssetsIndex[assetNode.assetID][propertyToChange] =
               updatedPropertyValue;
         }

         if (
            assetNode.parentAssetID &&
            parentAssets.indexOf(assetNode.parentAssetID) === -1 &&
            this.childAssetsIndex[assetNode.parentAssetID]
         ) {
            parentAssets.push(this.childAssetsIndex[assetNode.parentAssetID]);
         }
      }

      if (parentAssets.length) {
         this.findAndUpdateParentTree(
            parentAssets,
            propertyToChange,
            updatedPropertyValue,
            false,
         );
      }
   };

   buildTreeData = (assets: Lookup<"assetID", Asset>) => {
      assert(this.asset);
      this.assetNodes = [];
      this.childAssetsIndex = {};
      for (const asset of assets) {
         const assetDisplayInfo = this.assetSearchHints.get(asset.assetID) ?? {
            searchHint: "",
            searchFound: false,
         };

         const assetNode: AssetHierarchyNode = {
            nodes: [],
            collapsed: true,
            selected: false,
            searchFound: assetDisplayInfo.searchFound,
            searchHint: assetDisplayInfo.searchHint,
            icon: "cube",
            title: asset.assetName ?? "",
            assetID: asset.assetID,
            parentAssetID: asset.parentAssetID,
            titleColor: "asset",
         };
         this.childAssetsIndex[asset.assetID] = assetNode;

         if (assets.size > 100) {
            assetNode.collapsed = true;
         } else {
            assetNode.collapsed = false;
         }
         this.assetNodes.push(assetNode);
         assetNode.selected = false;
      }
      for (const node of this.assetNodes) {
         if (node.parentAssetID != this.asset.assetID && node.parentAssetID != null) {
            this.childAssetsIndex[node.parentAssetID].nodes.push(node);
         }
      }
   };

   filterTreeDataToRootItems = (treeData) => {
      assert(this.asset);
      return [
         ...treeData.filter(
            (asset) => Number(asset.parentAssetID) === Number(this.asset?.assetID),
         ),
      ];
   };

   addChildAsset = async () => {
      assert(this.asset);
      if (
         !this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.AddAssets,
         )
      ) {
         this.alertService.addAlert(this.lang().cred60Fail, "danger", 10000);
         return;
      }
      const isToBeNestedUnderSelectedChildsParent: boolean = true;
      const newAssetID = await this.manageAssetServ.addAssetLogic(
         this.asset.locationID,
         this.asset.assetID,
         isToBeNestedUnderSelectedChildsParent,
      );

      if (!newAssetID) {
         return;
      }

      const assetAdded = this.manageAsset.getAsset(newAssetID);
      assert(assetAdded);
      //popAsset has not been refactored and so this feature is currently incompatible.  MITCHELL TO DO... Test this once popAsset is completed.
      this.popAsset(assetAdded.assetID);

      this.buildView();
   };

   popAsset = (assetID: number | undefined) => {
      assert(assetID);
      const asset = this.manageAsset.getAsset(assetID);
      assert(asset);
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: this.restrict,
               preventParentAccess: true,
            },
         },
      };
   };

   private sortAssets(assets: Lookup<"assetID", Asset>) {
      const currentUser = this.manageUser.getCurrentUser();
      if (currentUser.userInfo.customerPickingAssetsIsOrderedBy === 1) {
         return assets.orderBy("assetOrder");
      }
      return assets.orderBy("assetName");
   }

   private setFieldData() {
      assert(this.asset);
      this.fields = new Lookup("fieldID");
      this.fieldValues = new Lookup("valueID");
      this.fieldValueFiles = new Lookup("fileID");

      const allFields = this.manageAsset.getFields();

      for (const [fieldID, field] of allFields.entries()) {
         if (field.locationID !== this.asset.locationID) {
            continue;
         }
         this.fields.set(fieldID, field);
      }

      const allValues = this.manageAsset.getFieldValues();

      for (const value of allValues) {
         const field = this.fields.get(value.fieldID);
         if (!field) {
            continue;
         }
         const asset = this.manageAsset.getAsset(value.assetID);
         if (!asset) {
            continue;
         }

         if (field.fieldTypeID === 2 && value.valueContent) {
            value.valueContent = new Date(value.valueContent);
         }

         if (field.fieldTypeID === 5 || field.fieldTypeID === 6) {
            value.valueContent = Number(value.valueContent ?? 0);
         }
         this.fieldValues.set(value.valueID, value);
      }

      this.fieldValueFiles = this.manageAsset.getFieldValueFiles();
   }
}
