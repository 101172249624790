import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ModalService, LimbleHtmlDirective, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { AlertPopup } from "src/app/shared/components/global/alertModal/alertPopup.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "part-name-field",
   templateUrl: "./partName.element.component.html",
   styleUrls: [
      "./partName.element.component.scss",
      "../../fields/shared-field-styles.scss",
   ],
   imports: [
      NgClass,
      ContenteditableDirective,
      FormsModule,
      LimbleHtmlDirective,
      TooltipDirective,
   ],
})
export class PartName implements OnInit {
   @Input() public partID: number | undefined;
   @Input() public usedIn: "table" | "listItem" | undefined = undefined;
   public part: Part | undefined;
   public credChangePartName;
   public oldValue;

   private readonly credService = inject(CredService);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      assert(this.partID);
      this.part = this.manageParts.getPart(this.partID);
      assert(this.part);

      this.credChangePartName = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ChangePartName,
      );

      this.oldValue = this.part.partName;
   }

   public async updatePartName() {
      assert(this.part);
      if (this.oldValue == this.part.partName) {
         return;
      }

      if (this.part.partName == "") {
         this.part.partName = this.oldValue;
         this.alertService.addAlert(this.lang().NameFieldCannotBeBlank, "danger", 10000);
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartName,
         )
      ) {
         this.alertService.addAlert(this.lang().cred70Fail, "danger", 10000);
         return;
      }

      const answer = await this.manageParts.updatePartName(this.part);
      if (answer.data.success == true) {
         this.checkForSameNameAndNumber(this.part.partID);
         this.oldValue = this.part.partName;
      } else if (answer.data.error === "nonUniqueValue") {
         assert(this.part);
         this.part.partName = this.oldValue;
         this.alertService.addAlert(this.lang().DefaultFieldUniqueError, "warning", 6000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   public checkForSameNameAndNumber(partID: number) {
      const foundMultiples = this.manageParts.checkForDuplicateNameAndNumber(partID);
      if (foundMultiples) {
         const instance = this.modalService.open(AlertPopup);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().warningMsgPartNameNumber,
               title: this.lang().DuplicatePartNumber,
            },
         };
         return;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }
}
