import {
   Component,
   computed,
   DestroyRef,
   inject,
   type OnInit,
   signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   FilterInputComponent,
   FilterInputWrapperComponent,
   IconComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import { map, mergeMap } from "rxjs";
import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import type {
   FieldFilter,
   FieldValueFilter,
} from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import { AssetFieldDefinitionService } from "src/app/assets/services/asset-field-definition.service";
import {
   type AssetFieldDefinition,
   AssetFieldScopeType,
} from "src/app/assets/services/asset-field.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import {
   parseFilterString,
   stringifyFilterArray,
} from "src/app/shared/data-viewer/data-viewer-filters/components/asset-field-value-filter/components/asset-field-filter-util";
import { DateFieldFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/asset-field-value-filter/components/date-field-filter/date-field-filter.component";
import { DropdownFieldFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/asset-field-value-filter/components/dropdown-field-filter/dropdown-field-filter.component";
import { NumberFieldFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/asset-field-value-filter/components/number-field-filter/number-field-filter.component";
import { TextFieldFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/asset-field-value-filter/components/text-field-filter/text-field-filter.component";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import type { Filter, SearchFields } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";

@Component({
   selector: "asset-field-value-filter",
   standalone: true,
   imports: [
      TranslateDirective,
      DropdownButtonComponent,
      DropdownDividerComponent,
      SearchBoxComponent,
      ReactiveFormsModule,
      DropdownClearFilterItemComponent,
      FilterInputComponent,
      FilterInputWrapperComponent,
      FormsModule,
      TextFieldFilterComponent,
      DateFieldFilterComponent,
      NumberFieldFilterComponent,
      DropdownFieldFilterComponent,
      DropdownItemComponent,
      IconComponent,
   ],
   templateUrl: "./asset-field-value-filter.component.html",
   styleUrl: "./asset-field-value-filter.component.scss",
})
export class AssetFieldValueFilterComponent
   extends BaseFilterComponent
   implements OnInit
{
   public fields = signal<Array<AssetFieldDefinition>>([]);
   public fieldsDropdown = signal<Array<AssetFieldDefinition>>([]);
   public fieldsSearchValue = signal<string>("");

   public assetFilters = signal<Array<Filter<AssetField>>>([]);
   public searchFields: SearchFields = {};

   protected readonly AssetFieldTypeID = AssetFieldTypeID;
   protected readonly AssetFieldScopeType = AssetFieldScopeType;

   public inheritedFilter = computed(() => this.filter()); //inherited from BaseFilterComponent

   private readonly assetFieldDefinitionService = inject(AssetFieldDefinitionService);
   private readonly destroyRef = inject(DestroyRef);

   protected initialFilters = computed(() => {
      const initialValue = this.inheritedFilter()?.options?.initialValue;
      if (!initialValue) return [];

      const filters: Array<FieldFilter> = parseFilterString(initialValue).filter(
         (filter) => filter.fieldId,
      );
      return filters;
   });

   protected initialValues = signal<Record<string, FieldValueFilter>>({});

   public constructor() {
      super();
   }

   public ngOnInit(): void {
      this.getFields();
   }

   public getFields(): void {
      const locationID = this.inheritedFilter()?.options?.locationID;

      if (!locationID) return;

      this.assetFieldDefinitionService
         .getDefinitions({ locationID })
         .pipe(
            takeUntilDestroyed(this.destroyRef),
            mergeMap((fieldsWithLocation) =>
               this.assetFieldDefinitionService
                  .getDefinitions({ scopeType: AssetFieldScopeType.Standardized })
                  .pipe(
                     map((standardizedFields) => [
                        ...fieldsWithLocation,
                        ...standardizedFields,
                     ]),
                  ),
            ),
         )
         .subscribe((allFields) => {
            this.fields.set(orderBy(allFields, "fieldName"));
            this.fieldsDropdown.set(this.fields());
            this.setInitialValues();
         });
   }

   private setInitialValues() {
      const initialFilters = this.initialFilters();
      const newFilters: Array<Filter<AssetField>> = [];
      let isSetFilterNeeded: boolean = false;

      for (const filter of initialFilters) {
         const field = this.fields().find(
            (fieldItem) => fieldItem.fieldID === filter.fieldId,
         );
         if (!field) continue;

         if (filter?.fieldValue) {
            const fieldValue: FieldValueFilter = filter.fieldValue;
            this.initialValues.update((values) => ({
               ...values,
               [field.fieldName]: fieldValue,
            }));
         } else {
            /* When a user selects a field without specifying a value, the filter will be in the format:
             * assetFieldFilters=5109: (with no value after the colon)
             * This creates a discrepancy between active filters and request options since no initial value is emitted.
             * We need to set the filter to ensure consistency.
             */
            isSetFilterNeeded = true;
         }

         this.searchFields[field.fieldName] = { searchValue: "" };

         newFilters.push({
            fieldName: field.fieldName,
            fieldSearch: "",
            field: field as AssetField,
         });
      }
      this.assetFilters.set(newFilters);

      if (isSetFilterNeeded) {
         this.setFilter();
      }
   }

   public handleClearAll() {
      this.assetFilters.set([]);
      this.searchFields = {};
      this.initialValues.set({});
      this.remove.emit(this.inheritedFilter());
   }

   public handleSearch(searchText: string) {
      this.fieldsSearchValue.set(searchText);
      this.buildFieldsDropdown();
   }

   private buildFieldsDropdown(): void {
      let fieldsDropdown = this.fields();
      const fieldsSearchValue = this.fieldsSearchValue();
      if (fieldsSearchValue.length > 0) {
         fieldsDropdown = fieldsDropdown.filter((field) =>
            field.fieldName?.toLowerCase().includes(fieldsSearchValue.toLowerCase()),
         );
      }
      assert(fieldsDropdown !== undefined);
      this.fieldsDropdown.set(orderBy(fieldsDropdown, "assetName"));
   }

   public handleSetField(
      field: AssetField | { fieldName: string; fieldTypeID?: number },
   ): void {
      const filterAlreadyExists = this.assetFilters().some(
         (filterToAdd) => filterToAdd.fieldName === field.fieldName,
      );
      if (!filterAlreadyExists) {
         this.searchFields[field.fieldName] = { searchValue: "" };
         this.assetFilters.update((filters) => [
            ...filters,
            {
               fieldName: field.fieldName,
               fieldSearch: "",
               field: field,
            },
         ]);
         this.setFilter();
      }
   }

   protected clearFilter(index?: number): void {
      if (index === undefined) {
         this.handleClearAll();
         return;
      }

      const currentFilters = this.assetFilters();
      const assetFilter = currentFilters[index];
      delete this.searchFields[assetFilter.fieldName];

      // Remove from initialValues if exists
      this.clearInitialValue(assetFilter.fieldName);

      this.assetFilters.set(currentFilters.filter((_, i) => i !== index));

      if (this.assetFilters().length === 0) {
         this.remove.emit(this.inheritedFilter());
         return;
      }

      this.setFilter();
   }

   public updateSearchField(fieldName: string, value: string): void {
      this.searchFields[fieldName].searchValue = value;
      this.setFilter();
   }

   public updateDateField(
      fieldName: string,
      date: Date | null,
      entity: "beginDate" | "endDate",
   ) {
      if (date === null) {
         delete this.searchFields[fieldName][entity];
         this.setFilter();
         return;
      }

      this.searchFields[fieldName][entity] = date.getTime();
      this.setFilter();
   }

   public updateNumberField(
      fieldName: string,
      value: number | null,
      entity: "lowest" | "highest",
   ) {
      if (value === null) {
         delete this.searchFields[fieldName][entity];
         this.setFilter();
         return;
      }

      this.searchFields[fieldName][entity] = value;
      this.setFilter();
   }

   private clearInitialValue(fieldName: string): void {
      if (!fieldName) return;
      const initialVals = this.initialValues();
      if (initialVals[fieldName]) {
         const newInitialValues = { ...initialVals };
         delete newInitialValues[fieldName];
         this.initialValues.set(newInitialValues);
      }
   }

   private setFilter() {
      const filtersString = stringifyFilterArray(this.assetFilters(), this.searchFields);
      this.set.emit({
         ...this.inheritedFilter(),
         value: {
            assetFieldFilters: filtersString,
            assetFieldFiltersRaw: this.assetFilters(),
         },
      });
   }
}
