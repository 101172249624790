import { Component, inject, type OnDestroy, type OnInit, signal } from "@angular/core";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { assert } from "src/app/shared/utils/assert.utils";

@Component({
   selector: "asset-filter",
   imports: [
      DropdownButtonComponent,
      DropdownClearFilterItemComponent,
      DropdownDividerComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      SearchBoxComponent,
      TranslateDirective,
   ],
   templateUrl: "./asset-filter.component.html",
   styleUrls: ["./asset-filter.component.scss"],
})
export class AssetFilterComponent
   extends BaseFilterComponent
   implements OnDestroy, OnInit
{
   private readonly manageAsset = inject(ManageAsset);

   protected asset?: Array<number> | undefined;

   /**
    * This is a label that is shown whenever the filter is active to indicate what was selected
    */
   protected activeFilterLabel?: string | undefined;

   public assets = signal<Array<Asset>>([]);
   public assetsDropdown = signal<Array<Asset>>([]);
   public assetsSearchValue = signal<string>("");

   private readonly assetsLoadedSub$ = this.manageAsset.assetsLoaded().subscribe(() => {
      const filter = this.filter();
      const locationID = filter?.options?.locationID;
      this.assets.set(
         Array.from(this.manageAsset.getAssets().values()).filter(
            (asset) =>
               asset.assetDeleted === 0 &&
               (locationID === 0 || asset.locationID === locationID),
         ),
      );
      this.buildAssetsDropdown();
   });

   public constructor() {
      super();
   }

   public ngOnInit(): void {
      const locationID = this.filter().options?.locationID;
      this.assets.set(
         Array.from(this.manageAsset.getAssets().values()).filter(
            (asset) =>
               asset.assetDeleted === 0 &&
               (locationID === 0 || asset.locationID === locationID),
         ),
      );
      this.buildAssetsDropdown();
   }

   public ngOnDestroy(): void {
      this.assetsLoadedSub$.unsubscribe();
   }

   public handleSetAsset(asset: Asset | undefined) {
      if (!asset) return;
      this.asset = [asset.assetID];

      this.activeFilterLabel = `${asset.assetName}`;
      const filter = { [this.filter().key]: asset.assetID };
      this.setFilter(filter, this.activeFilterLabel);
   }

   public handleSearch(searchText: string) {
      this.assetsSearchValue.set(searchText);
      this.buildAssetsDropdown();
   }

   private buildAssetsDropdown(): void {
      let assetsDropdown = this.assets();
      if (this.assetsSearchValue().length > 0) {
         assetsDropdown = assetsDropdown.filter((asset) =>
            asset.assetName
               ?.toLowerCase()
               .includes(this.assetsSearchValue().toLowerCase()),
         );
      }
      assert(assetsDropdown !== undefined);
      this.assetsDropdown.set(orderBy(assetsDropdown, "assetName"));
   }

   public handleClear() {
      this.asset = undefined;
      this.activeFilterLabel = undefined;
      this.remove.emit(this.filter());
   }

   private setFilter(value, activeLabel: string): void {
      this.set.emit({
         ...this.filter(),
         activeLabel,
         value,
      });
   }
}
