import { inject, Injectable } from "@angular/core";
import axios from "axios/dist/axios";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { ManageUser } from "src/app/users/services//manageUser";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ManageBilling {
   private readonly categories: any = {};
   private readonly axios = axios;
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);

   public constructor() {
      this.categories.arr = [];
      this.categories.index = {};
      this.createObservables();
   }

   createObservables = () => {
      this.manageObservables.createObservable("categories", this.categories.arr);
   };

   sortData = (data) => {
      this.categories.arr = data || [];

      for (const category of this.categories.arr) {
         this.categories.index[category.categoryID] = category;
      }

      this.manageObservables.updateObservable("categories", 1);
   };

   getData = async () => {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/billingCategories`);
      logApiPerformance(
         "billingCategories",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.sortData(response.data);
   };

   getCategories = (all?) => {
      if (all == true) {
         return this.categories.arr;
      }
      return this.categories.arr.filter((category) => category.categoryDeleted === 0);
   };

   getCategoriesIndex = () => {
      return this.categories.index;
   };

   updateBillingCategories = async (categoriesToUpdate) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageBilling.php",
         params: {
            action: "updateBillingCategories",
         },
         data: {
            categories: categoriesToUpdate,
         },
      });

      return post;
   };

   addBillingCategory = async () => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageBilling.php",
         params: {
            action: "addBillingCategory",
         },
         data: {},
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.categories.arr.push(answer.data.category);
            const newIndex = this.categories.arr.length - 1;
            this.categories.index[answer.data.category.categoryID] =
               this.categories.arr[newIndex];
         }
      });

      this.manageObservables.updateObservable("categories", 1);

      return post;
   };

   deleteBillingCategory = async (categoryToDelete) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageBilling.php",
         params: {
            action: "deleteBillingCategory",
         },
         data: {
            categoryID: categoryToDelete.categoryID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            for (const category of this.categories.arr) {
               //since we removed somethign we need to lower the order for anything higher then what was removed
               if (category.order > categoryToDelete.order) {
                  category.order = category.order - 1;
               }
            }

            for (let index = 0; index < this.categories.arr.length; index++) {
               //loop through categories and remove the one we are removing
               if (this.categories.arr[index].categoryID == categoryToDelete.categoryID) {
                  this.categories.arr.splice(index, 1);
               }
            }
         }
      });

      this.manageObservables.updateObservable("categories", 1);

      return post;
   };
}
