import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "pick-who-to-disapprove-p-o-to",
   templateUrl: "./pickWhoToDisapprovePOTo.modal.component.html",
   styleUrls: ["./pickWhoToDisapprovePOTo.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      NgClass,
      IconComponent,
      AlertComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
      OrderByPipe,
   ],
})
export class PickWhoToDisapprovePOTo implements OnInit {
   public message;
   public title;
   public errorMsg;
   public selectedStep;
   public loadingApproval;
   public steps;
   public data;
   public resolve;
   public modalInstance;

   private readonly managePO = inject(ManagePO);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;

      this.message = this.data.message;
      this.title = this.data.title;
      this.errorMsg = "";
      this.selectedStep = 0;

      this.managePO.getPOStepsPerformed(this.data.code, this.data.poID).then((answer) => {
         this.loadingApproval = false;
         if (answer.data.success == true) {
            const usersIndex = this.manageUser.getFlatUsersIndex();
            const sysUsers = this.manageUser.getSysUsersIndex();

            for (const step of answer.data.steps) {
               if (usersIndex[step.userID]) {
                  step.displayName = `${usersIndex[step.userID].userFirstName} ${
                     usersIndex[step.userID].userLastName
                  }`;
               } else if (sysUsers[step.userID]) {
                  if (sysUsers[step.userID].userInternal == 1) {
                     step.displayName = this.lang().System;
                  } else {
                     step.displayName = `${this.lang().ExternalUser} - ${step.externalEmailAddress}`;
                  }
               } else {
                  step.displayName = this.lang().DeletedUser;
               }
            }
            this.steps = [];

            for (const step of answer.data.steps) {
               if (step.userID > 0) {
                  this.steps.push(step);
               }
            }
         }
      });
   }

   close = () => {
      this.modalInstance.close(0);
   };

   focusStep = (step) => {
      if (this.selectedStep == step) {
         //this is already excepted so submit it
         this.submit();
         return;
      }
      this.selectedStep = step;
   };

   submit = () => {
      if (this.selectedStep == 0) {
         this.errorMsg = this.lang().PleaseSelectAStep;
      }
      if (this.selectedStep != 0) {
         this.errorMsg = "";
         this.modalInstance.close(this.selectedStep);
      }
   };
}
