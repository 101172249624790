import type { PipeTransform } from "@angular/core";
import { computed, inject, Pipe } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

/*
 * Replaces a string for an ordered number with the appropriate word in the user's language
 * takes in a string and returns a string
 * Usage:
 *   string | firstSecondEtcToMultiLanguage
 */
@Pipe({
   name: "firstSecondEtcToMultiLanguage",
   standalone: true,
})
export class FirstSecondEtcToMultiLanguagePipe implements PipeTransform {
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public transform(inputIn) {
      let input = inputIn;
      if (input !== undefined) {
         const lang = this.manageLang.lang() ?? {};

         switch (input) {
            case "first":
               input = lang.first;
               break;
            case "second":
               input = lang.second;
               break;
            case "third":
               input = lang.third;
               break;
            case "fourth":
               input = lang.fourth;
               break;
            case "fifth":
               input = lang.fifth;
               break;
            case "sixth":
               input = lang.sixth;
               break;
            case "seventh":
               input = lang.seventh;
               break;
            case "eighth":
               input = lang.eighth;
               break;
            case "ninth":
               input = lang.ninth;
               break;
            case "tenth":
               input = lang.tenth;
               break;
            default:
         }

         return input;
      }
      return input;
   }
}
