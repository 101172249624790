<lim-ui-card class="anomaly-detected-card">
   <div class="content-wrapper">
      <div class="text-content">
         <div class="no-wrap text-content-row lim-ui-fonts-body-extra-bold">
            <lim-ui-sparkles-pulse-spin
               animationSize="extra-small"
               class="inline-icon no-wrap"
            />
            {{ lang().t("UnexpectedValueDetected") }}
         </div>
         <div
            limUiTypewriter
            [text]="lang().t('ValueOutsideRange')"
            [typingSpeed]="15"
            (typingComplete)="onTypingComplete()"
            class="text-content-row lim-ui-fonts-body"
         ></div>
      </div>
      @if (isTypingComplete()) {
         <lim-ui-outlined-button
            @fadeIn
            class="confirm-anomaly-button"
            (click)="confirmAnomaly()"
            data-test-id="confirmAnomalyButton"
         >
            <span class="lim-ui-fonts-body-extra-bold">{{ newValue() }}</span>
            <span>&nbsp;{{ lang().t("IsCorrect") }}</span>
         </lim-ui-outlined-button>
      }
   </div>
</lim-ui-card>
