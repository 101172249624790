import { Component, computed, inject, input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";

@Component({
   imports: [BetterDecimalPipe],
   selector: "task-estimated-time-cell",
   templateUrl: "./task-estimated-time-cell.component.html",
})
export class TaskEstimatedTimeCellComponent {
   // injects
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   //input
   public estimatedTime = input.required<number>();
}
