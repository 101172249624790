import { z } from "zod";

export const purchaseOrderItemDtoStrict = z.object({
   /** Not Null in DB / computed */
   poItemID: z.number(),
   transactionIDs: z.array(z.number()),

   /** Should never be null but is nullable in DB */
   itemType: z.number(),
   qty: z.number(),
   rate: z.number(),
   tax: z.number(),
   discount: z.number(),
   shipping: z.number(),
   poID: z.number(),

   /** Nullable */
   partID: z.number().nullable(),
   checklistID: z.number().nullable(),
   prodServDescription: z.string().nullable(),
   description: z.string().nullable(),
   assetID: z.number().nullable(),
   glID: z.number().nullable(),
   purchasableID: z.number().nullable(),
   purchasableName: z.string().nullable(),
   purchasableSize: z.number().nullable(),
   purchasableQuantity: z.number().nullable(),
   purchasableRate: z.number().nullable(),
   purchasableProvidedOrderUnitID: z.number().nullable(),
   purchasableProvidedOrderUnitAlias: z.string().nullable(),
   purchasableCustomOrderUnitID: z.number().nullable(),
   purchasableCustomOrderUnitNameShort: z.string().nullable(),
   purchasableCustomOrderUnitNameSingular: z.string().nullable(),
   purchasableProvidedSizeUnitID: z.number().nullable(),
   purchasableProvidedSizeUnitAlias: z.string().nullable(),
   partStockUnitDefault: z.number().nullable(),
   partStockProvidedUnitID: z.number().nullable(),
   partStockProvidedUnitAlias: z.string().nullable(),
   partStockCustomUnitID: z.number().nullable(),
   partStockCustomUnitNameShort: z.string().nullable(),
   partStockCustomUnitNameSingular: z.string().nullable(),
});
