import type { OnDestroy, OnInit, Signal } from "@angular/core";
import { inject, Component, computed, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   CheckboxComponent,
   DatePickerInputComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageDepreciationSchedule } from "src/app/assets/services/manageDepreciationSchedule";
import type { Asset } from "src/app/assets/types/asset.types";
import type { DepreciationSchedule } from "src/app/assets/types/depreciation-schedules/depreciation-schedule.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { DEFAULT_CURRENCY } from "src/app/purchasing/currency/utils/default-currency-code";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DepreciationOptions } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "asset-capital-depreciation",
   templateUrl: "./assetCapitalDepreciation.modal.component.html",
   styleUrls: ["./assetCapitalDepreciation.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      CheckboxComponent,
      IconComponent,
      TooltipDirective,
      FormsModule,
      DatePickerInputComponent,
      CurrencySymbolPipe,
   ],
})
export class AssetCapitalDepreciation implements OnInit, OnDestroy {
   public message: string | undefined;
   public title: string | undefined;
   public depreciationOptions: DepreciationOptions = {};
   public userOrProfileDisplay: string | undefined;
   public currencyCode: Signal<string> = signal(DEFAULT_CURRENCY);
   private modalInstance;
   private fieldValue;
   private asset: Asset | undefined;
   private depreciationSchedule: DepreciationSchedule | undefined;
   private readonly defaultDepreciationOptions: DepreciationOptions = {
      purchaseCost: 0,
      depreciationStartDate: new Date(),
      standardUsefulLife: 60,
      salvageValue: 0,
      userIDForTaskAssignment: 0,
      profileIDForTaskAssignment: 0,
      scheduleActive: false,
   };
   private featureCapitalDepreciation: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription;
   protected isSuperUser: boolean = false;
   protected fieldLocked: boolean = false;

   private readonly paramsService = inject(ParamsService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageUser = inject(ManageUser);
   private readonly manageDepreciationSchedule = inject(ManageDepreciationSchedule);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureCapitalDepreciation =
               isFeatureEnabledMap.featureCapitalDepreciation;
         },
      );
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      let resolve;
      if (params?.resolve) {
         resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      const assetID = resolve.data.assetID;
      this.asset = this.manageAsset.getAsset(assetID);
      if (this.asset === undefined) {
         throw new Error(
            "assetCapitalDepreciation component requires an `asset`, but no asset exists for the provided assetID.",
         );
      }

      this.depreciationSchedule = resolve.data.depreciationSchedule;

      this.fieldValue = resolve.data.fieldValue;
      this.currencyCode = resolve.data.currencyCode;

      this.setInitialDepreciationOptions();

      this.message = resolve.message;
      this.title = resolve.title;

      this.userOrProfileDisplay =
         this.manageDepreciationSchedule.getUserOrProfileDisplayForDepreciation(
            this.depreciationOptions.profileIDForTaskAssignment,
            this.depreciationOptions.userIDForTaskAssignment,
         );

      const currentUser = this.manageUser.getCurrentUser();
      this.isSuperUser = this.manageUtil.checkIfSuperUser(currentUser);
      const field = this.manageAsset.getField(this.fieldValue.fieldID);
      this.fieldLocked = Boolean(field?.lockedDefault === 1 && !this.isSuperUser);
   }

   private setInitialDepreciationOptions() {
      const schedule = this.depreciationSchedule;
      if (!schedule) {
         this.depreciationOptions = {
            ...this.defaultDepreciationOptions,
            purchaseCost: Number(this.fieldValue.valueContent ?? 0),
         };
         return;
      }

      const standardInitialOptions: Partial<DepreciationOptions> = {
         depreciationStartDate: new Date(
            schedule.depreciationStartDate
               ? schedule.depreciationStartDate * 1000
               : Date.now(),
         ),
         standardUsefulLife: Number(schedule.standardUsefulLife),
         salvageValue: Number(schedule.salvageValue),
         userIDForTaskAssignment: Number(schedule.userIDForTaskAssignment),
         profileIDForTaskAssignment: Number(schedule.profileIDForTaskAssignment),
         scheduleActive: Boolean(schedule.scheduleActive),
      };
      if (schedule.scheduleActive) {
         this.depreciationOptions = {
            ...standardInitialOptions,
            purchaseCost: Number(schedule.purchaseCost),
         };
         return;
      }
      this.depreciationOptions = {
         ...standardInitialOptions,
         purchaseCost: Number(this.fieldValue.valueContent ?? 0),
      };
   }

   close = () => {
      this.modalInstance?.close();
   };

   updateDepreciationOptions = async () => {
      assert(this.asset);
      if (!this.featureCapitalDepreciation) {
         this.depreciationOptions.scheduleActive = false;
         return;
      }

      if (
         Number(this.depreciationOptions?.salvageValue) >
         Number(this.depreciationOptions?.purchaseCost)
      ) {
         this.depreciationOptions.salvageValue = Number(
            this.depreciationOptions.purchaseCost,
         );
         this.alertService.addAlert(
            this.lang().TheSalvageValueCannotBeGreaterThanThePurchaseCost,
            "warning",
            4000,
         );
         return;
      }
      const success = await this.manageAsset.updateDepreciationOptions(
         this.depreciationOptions,
         this.fieldValue.valueID,
         this.asset.locationID,
         this.asset.assetID,
      );
      if (success) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   };

   changeTaskAssignment = () => {
      assert(this.asset);
      if (!this.depreciationOptions.scheduleActive) {
         this.alertService.addAlert(
            this.lang().DepreciationScheduleMustBeEnabledMsg,
            "warning",
            4000,
         );
         return;
      }
      const extraUsersOptions = [
         {
            userFirstName: this.lang().Unassigned,
            userID: 0,
            profileID: 0,
         },
      ];
      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().changeEndOfStandardUsefulLifeTaskAssignment,
               title: this.lang().changeEndOfStandardUsefulLifeTaskAssignmentMsg,
               locationID: this.asset.locationID,
               extraUsers: extraUsersOptions,
            },
         },
      };
      instance.result.then(async (response) => {
         assert(this.asset);
         if (!response) {
            return;
         }

         if (response.multiUsers.length) {
            const hiddenProfileRst =
               await this.manageProfile.getOrCreateHiddenProfileAndDescription(
                  response.multiUsers,
                  this.asset.locationID,
               );
            if (hiddenProfileRst) {
               this.depreciationOptions.profileIDForTaskAssignment =
                  hiddenProfileRst.data.profileID;
               this.depreciationOptions.userIDForTaskAssignment = 0;
               this.userOrProfileDisplay = hiddenProfileRst.data.profileDescription;
            }
         } else {
            this.depreciationOptions.profileIDForTaskAssignment = response.profileID;
            this.depreciationOptions.userIDForTaskAssignment = response.userID;
            this.userOrProfileDisplay =
               this.manageDepreciationSchedule.getUserOrProfileDisplayForDepreciation(
                  this.depreciationOptions.profileIDForTaskAssignment,
                  this.depreciationOptions.userIDForTaskAssignment,
               );
         }

         this.updateDepreciationOptions();
      });
   };

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub.unsubscribe();
   }
}
