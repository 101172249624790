<lim-ui-modal>
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span [limbleHtml]="message"></span>
         <ul>
            <li [limbleHtml]="lang().AllOpenInProgressAndCompleted"></li>
            <li [limbleHtml]="lang().AllPmWoAndWr"></li>
            <li [limbleHtml]="lang().AllAssetSettingsAndAssetHistoryNew"></li>
            <li [limbleHtml]="lang().AllChildAssets"></li>
         </ul>
         <span [limbleHtml]="lang().TheFollowingActionsWillOccur"></span>
         <ul>
            <li
               [limbleHtml]="lang().DuringTheMoveOneOrMoreOfTheTasksWereSetToUnassigned"
            ></li>
            <li [limbleHtml]="lang().FewerUsersWarning"></li>
         </ul>
         <a
            href="https://intercom.help/limble-cmms/en/articles/3148282-how-to-move-assets-between-locations"
            target="_blank"
         >
            {{ lang().LearnMore }}
            <lim-ui-icon [icon]="'arrowUpRightFromSquare'" />
         </a>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="horizotal-panel-header">
            <div [limbleHtml]="lang().createCopyAssets">
               <span class="color-red">*</span>
            </div>
         </div>
         <div class="horizotal-panel-content">
            <ng-container radio-button-list groupID="selectionGroup">
               <lim-ui-radio-button
                  groupID="selectionGroup"
                  [label]="lang().Yes"
                  [checked]="shouldTransferParts === true"
                  (click)="updateShouldTransferParts(true)"
               />
               <lim-ui-radio-button
                  groupID="selectionGroup"
                  [label]="lang().No"
                  (click)="updateShouldTransferParts(false)"
                  [checked]="shouldTransferParts === false"
               />
            </ng-container>
         </div>
         @if (shouldTransferParts === false) {
            <div [limbleHtml]="lang().BySelectingNo"></div>
         }
         @if (shouldTransferParts === true) {
            <div [limbleHtml]="lang().BySelectingYes"></div>
         }
      </lim-ui-panel>
      <lim-ui-panel>
         <lim-ui-checkbox
            [label]="
               lang()
                  .TheseActionsCannotBeUndoneBeforeProceedingPleaseCheckThisBoxToConfirmYouUnderstand
            "
            [useWarningColor]="true"
            [model]="IUnderstand"
            (click)="IUnderstand = !IUnderstand"
         />
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      <lim-ui-outlined-button (click)="close()">
         {{ this.lang().Cancel }}
      </lim-ui-outlined-button>
      <lim-ui-primary-button (click)="submit()">
         {{ this.lang().MoveAsset }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
