<e-button
   [type]="'secondary'"
   [size]="'extra-small'"
   [outlined]="true"
   [disabled]="isDisabled"
   [ariaLabel]="label"
   [iconName]="iconName"
   [iconColor]="'medium-grey'"
   [iconSize]="'small'"
   (click)="menu.togglePopup($event)"
/>
<e-menu #menu [items]="menuItems()" [popup]="true" />
