import { inject, Component, computed, input } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   type ModalResult,
   LimUiModalRef,
} from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { RECEIVE_PURCHASE_ORDER_ITEMS_TITLE_TRANSLATION_KEY } from "src/app/purchasing/pos/receive-purchase-order-items/receive-purchase-order-items-title-translation-key";
import { ReceivePurchaseOrderItems } from "src/app/purchasing/pos/receive-purchase-order-items/receive-purchase-order-items.component";
import { ReceivePurchaseOrderItemsTriggerService } from "src/app/purchasing/pos/receive-purchase-order-items/receive-purchase-order-items.service";

@Component({
   selector: "receive-purchase-order-items-modal",
   templateUrl: "./receive-purchase-order-items.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      BasicModalFooterComponent,
      ReceivePurchaseOrderItems,
   ],
})
export class ReceivePurchaseOrderItemsModal implements ModalResult<void> {
   protected readonly i18n = inject(TranslationService).i18n;
   private readonly receiveTrigger = inject(ReceivePurchaseOrderItemsTriggerService)
      .trigger;

   public readonly poID = input.required<number>();

   public readonly modalRef =
      inject<LimUiModalRef<ReceivePurchaseOrderItemsModal, void>>(LimUiModalRef);

   protected readonly title = computed(() =>
      this.i18n().t(RECEIVE_PURCHASE_ORDER_ITEMS_TITLE_TRANSLATION_KEY),
   );

   protected close(): void {
      this.modalRef.close();
   }

   protected submit(): void {
      this.receiveTrigger.next(this.poID());
   }
}
