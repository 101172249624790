import { CommonModule } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { LimbleHtmlDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   selector: "task-id-cell",
   imports: [CommonModule, LimbleHtmlDirective],
   templateUrl: "./task-id-cell.component.html",
})
export class TaskIdCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly facade = inject(TasksFacadeService);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly task = input.required<TaskDataViewerViewModel>();

   public async viewTask(): Promise<void> {
      await this.facade.viewTask(this.task().checklistID);
   }
}
