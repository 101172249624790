import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { ChangeDetectorRef, Component, computed, inject, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   AlertComponent,
   CheckboxComponent,
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownResultsPerPageComponent,
   DropdownTextItemComponent,
   FilterInputComponent,
   FilterInputWrapperComponent,
   FiltersWrapperComponent,
   IconButtonComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
   PaginationComponent,
   PanelComponent,
   PrimaryButtonComponent,
   RowHoverButtonsComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AssetLogService } from "src/app/assets/components/asset-log.service";
import { EditAssetLogItem } from "src/app/assets/components/editAssetLogItemModal/editAssetLogItem.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { CommentFiles } from "src/app/files/components/commentFiles/commentFiles.element.component";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { EscapePipe } from "src/app/shared/pipes/customEscape.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "asset-log-tab",
   templateUrl: "./asset-log-tab.component.html",
   styleUrls: ["./asset-log-tab.component.scss"],
   imports: [
      PanelComponent,
      SearchBoxComponent,
      CheckboxComponent,
      IconComponent,
      TooltipDirective,
      FiltersWrapperComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownClearFilterItemComponent,
      FilterInputWrapperComponent,
      FilterInputComponent,
      DropdownComponent,
      SecondaryButtonComponent,
      LimbleHtmlDirective,
      SortColumn,
      NoSearchResults,
      NgClass,
      FileListItem,
      ViewImage,
      RowHoverButtonsComponent,
      PaginationComponent,
      DropdownResultsPerPageComponent,
      IconButtonComponent,
      FormsModule,
      CommentFiles,
      PrimaryButtonComponent,
      AlertComponent,
      BetterDatePipe,
      EscapePipe,
      SliceArray,
      NgxSkeletonLoaderModule,
   ],
})
export class AssetLogTabComponent implements OnInit {
   private readonly alertService = inject(AlertService);
   private readonly assetLogService = inject(AssetLogService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageFiles = inject(ManageFiles);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);

   @Input() assetID: number;
   @Input() public restrict: boolean;
   public asset: Asset | undefined;
   public logs;
   public itemsPerPage;
   public CID;
   public orderBy;
   public logItems: any = [];
   public workOrderChoiceState;
   public manageAssetLogCred;
   public searchBar;
   public logTasks;
   public sourceID;
   public sourceState;
   public dateDuration;
   public dateState;
   public workOrderChoiceBatchID;
   public workOrderChoiceAssetID;
   public noSearchResults: boolean = false;
   public fileUploaded;
   public newEntryViewable: 0 | 1 | undefined;
   public errorMsg;
   public successMsg;
   public workOrderChoice;
   public addEntryDate: Date | undefined;
   public page = 1;
   public timeout;
   public entryText: string;
   public clearFiles: any;
   public location: any;
   public viewManageAssetsCred: boolean;
   public editAssetSettingsCred: boolean;
   protected logUploadObj;
   protected fileQue;
   protected pictureQue;
   protected eyeSlashIcon: Aliases = "eyeSlashRegular";
   protected eyeIcon: Aliases = "eyeRegular";
   protected TaskInstructionType = TaskInstructionTypeID;
   public isLoading: boolean = true;
   public skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.restrict = true;
      this.entryText = "";
      this.editAssetSettingsCred = false;
      this.viewManageAssetsCred = false;
      this.assetID = -1;
   }

   public ngOnInit(): void {
      this.asset = this.manageAsset.getAsset(this.assetID);
      this.itemsPerPage =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage || 10;

      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      assert(this.asset);
      const locations = this.manageLocation.getLocationsIndex();
      if (locations[this.asset.locationID]) {
         this.location = locations[this.asset.locationID];
         this.location.locationName = locations[this.asset.locationID].locationName;
         this.location.locationTaskImage =
            locations[this.asset.locationID].locationTaskImage;
         this.location.locationPhone = locations[this.asset.locationID].locationPhone;
         this.location.locationAddress = locations[this.asset.locationID].locationAddress;
         this.location.locationAddress2 =
            locations[this.asset.locationID].locationAddress2;
      }
      this.orderBy = "-logDate";

      this.workOrderChoiceState = false;
      this.manageAssetLogCred = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ManageAssetLogs,
      );
      this.viewManageAssetsCred = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ViewManageAssets,
      );

      this.editAssetSettingsCred = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.EditAssetSettings,
      );

      this.logUploadObj = {};

      this.logUploadObj.primaryID = "fileID";
      this.logUploadObj.posturl = `phpscripts/manageAssetLogs.php?action=makeFile&locationID=${this.asset.locationID}&assetID=${this.asset.assetID}`;

      this.logUploadObj.maxFiles = 5;
      this.logUploadObj.uploading = false;
      this.logUploadObj.viewOnly = false;
      this.logUploadObj.deleteData = {};

      this.logUploadObj.deleteCall = async (data) => {
         return this.assetLogService.deleteAssetLogFile(data.fileID);
      };

      this.logUploadObj.deleteSuccess = (answer) => {
         if (answer.data.success == true) {
            for (const key in this.fileQue) {
               if (this.fileQue[key].fileID == JSON.parse(answer.config.data).fileID) {
                  this.fileQue.splice(key, 1);
               }
            }
            for (const key in this.pictureQue) {
               if (this.pictureQue[key].fileID == JSON.parse(answer.config.data).fileID) {
                  this.pictureQue.splice(key, 1);
               }
            }
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      };
      this.logUploadObj.uploadComplete = (data) => {
         assert(this.asset);
         if (data.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            this.fileUploaded = true;
            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );
         }

         if (data.fileID != 0) {
            const regex = /(?:\.([^.]+))?$/;
            data.row.fileExt = regex.exec(data.row.fileName);
            if (data.row.fileExt.length > 1) {
               data.row.fileExt = data.row.fileExt[1].toLowerCase();
            }

            data.row.getURL = `viewFile.php?f=upload-${this.CID}/assets/${this.asset.locationID}/${data.row.assetID}/logs/${data.fileName}`;
            if (this.manageFiles.checkImageExt(data.fileName)) {
               this.pictureQue = this.pictureQue || [];
               this.pictureQue = [...this.pictureQue, data.row];
            } else {
               this.fileQue = this.fileQue || [];
               this.fileQue = [...this.fileQue, data.row];
            }
         } else if (data.fileID == 0) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         }

         this.logUploadObj.uploading = false;
      };

      this.newEntryViewable = 1;
      this.addEntryDate = new Date();
      this.entryText = "";
      this.sourceState = false;
      this.dateState = false;
      this.getLogs()
         .then(() => {
            this.buildData();
         })
         .finally(() => {
            this.isLoading = false;
         });
   }

   pageChanged = () => {
      this.ref.detectChanges();
   };

   searchLogs = () => {
      // debounce function
      if (this.timeout) {
         clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
         this.buildData();
      }, 500); //so it doesn't trigger until half a second after I call it.
   };

   buildData = () => {
      this.logTasks = [];
      const tempObj = {};

      let tempLogs = this.logs;
      if (this.sourceState) {
         tempLogs = this.manageFilters.filterLogsBySourceID(tempLogs, {
            sourceID: Number(this.sourceID),
            type: "assets",
         });
      }
      if (this.dateState) {
         tempLogs = this.manageFilters.filterLogsToLastXMilliseconds(
            tempLogs,
            this.dateDuration,
         );
      }
      tempLogs = this.manageFilters.logFilterWoBatchID(
         tempLogs,
         this.workOrderChoiceBatchID,
         this.workOrderChoiceAssetID,
         this.workOrderChoiceState,
      );

      if (this.searchBar && this.searchBar.length > 0) {
         tempLogs = tempLogs.filter((log) =>
            this.manageFilters.searchObjValuesRecursivelyForString(log, this.searchBar),
         );
      }

      this.noSearchResults = tempLogs?.length == 0 && this.logs?.length != 0;

      this.logItems = tempLogs || [];
      for (const logItem of this.logItems) {
         if (logItem.logDetails.checklistID > 0 && logItem.logDetails.itemResponse) {
            logItem.logDetails.itemBatchID = logItem.itemBatchID;
            logItem.logDetails.assetID = logItem.assetID;

            //give a unique index so that it removes duplicates based on the itemBatchID and the assetID
            tempObj[`${logItem.logDetails.itemBatchID} ${logItem.logDetails.assetID}`] =
               logItem.logDetails;
         }

         if (logItem.logPictures.length > 0) {
            logItem.logPictures.forEach((pic) => {
               this.addURL(pic, logItem);
            });
         }
         this.manageTask.setInternalTaskUserNameLogDefaults(logItem);
      }
      for (const key in tempObj) {
         this.logTasks.push(tempObj[key]);
      }

      for (const index in this.logItems) {
         if (this.logItems[index].logDetails.checklistID > 0) {
            if (this.logItems[index].logDetails.itemText) {
               //only for log items that are recorded from a checklist
               if (
                  this.logItems[index].logDetails.itemResponse == "" ||
                  this.logItems[index].logDetails.itemResponse == null
               ) {
                  this.logItems.splice(index, 1); //remove any entries that have no value.
               }
            }
         }
      }

      for (const index in this.logItems) {
         if (this.logItems[index].sourceID == 2) {
            //we only show or hide this for manual entrys for now.
            if (!this.manageAssetLogCred && this.logItems[index].logViewable == 0) {
               //theydon't have permissions to see everything and this log is marked as not viewable
               this.logItems.splice(index, 1);
            }
         }
      }
      this.logItems = orderBy(this.logItems, this.orderBy) || [];
      this.page = this.manageFilters.checkPagination(
         this.page,
         this.itemsPerPage,
         this.logItems.length,
      );
   };

   updateIncludeChildData = () => {
      assert(this.asset);

      this.manageAsset
         .updateIncludeChildData(this.asset.assetID, this.asset.includeChildData)
         .then((answer) => {
            if (answer.data.success === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 2000);

               // Get fresh logs and rebuild data
               this.getLogs().then(() => {
                  this.buildData(); // Rebuild the data with new logs
               });

               this.manageTask.incTasksWatchVar();
               this.manageTask.incCompletedTasksWatchVar();
            } else {
               // If update failed, revert the checkbox
               assert(this.asset);
               this.asset.includeChildData = this.asset.includeChildData === 1 ? 0 : 1;
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         })
         .catch(() => {
            // If request failed, revert the checkbox
            assert(this.asset);
            this.asset.includeChildData = this.asset.includeChildData === 1 ? 0 : 1;
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         });
   };

   private async getLogs() {
      assert(this.asset);

      const assetIDs: Array<number> = [];
      if (this.asset.includeChildData === 1) {
         const children = this.manageAsset.findChildrenIDs(this.asset, []);
         for (const child of children) {
            assetIDs.push(child);
         }
         assetIDs.push(this.asset.assetID);
      } else {
         assetIDs.push(this.asset.assetID);
      }

      const answer = await this.assetLogService.getLogs(assetIDs);
      const logs = answer.data.assetLogs || [];
      const files = answer.data.logFiles || [];

      //regex for stripping extension.
      const regex = /(?:\.([^.]+))?$/;
      //this mess right here adds the log files to the actual logs.  It also adds the assetName for use in asset group leaders.

      for (const log of logs) {
         log.logFiles = [];
         log.logPictures = [];
         const logAsset = this.manageAsset.getAsset(log.assetID);
         if (logAsset) {
            log.assetName = logAsset.assetName;
         }
         for (const file of files) {
            file.fileExt = regex.exec(file.fileName);
            if (file.fileExt.length > 1) {
               file.fileExt = file.fileExt[1].toLowerCase();
            }
            if (file.logID === log.logID) {
               if (this.manageFiles.checkDocumentExts(file.fileExt)) {
                  log.logFiles.push(file);
               } else if (this.manageFiles.checkImageExt(file.fileExt)) {
                  log.logPictures.push(file);
               }
            }
         }
      }

      this.logs = logs;
   }

   showHideNewEntry = () => {
      if (this.newEntryViewable == 1) {
         this.newEntryViewable = 0;
      } else {
         this.newEntryViewable = 1;
      }
   };

   deleteLogEntry = (log) => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().DeleteLogMsg}<br /><br />${log.logDetails}.`,
            title: this.lang().DeleteLog,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.assetLogService.deleteLogEntry(log.logID).then((answer) => {
               if (answer.data.success == true) {
                  for (const index in this.logItems) {
                     if (this.logItems[index].logID == log.logID) {
                        this.logItems.splice(index, 1);
                        this.logItems = [...this.logItems];
                     }
                  }
                  this.alertService.addAlert(
                     this.lang().DeleteLogSuccessMsg,
                     "success",
                     2000,
                  );
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   setAddEntryDate = () => {
      assert(this.addEntryDate !== undefined);
      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PleaseSelectADateAndClickChange,
            title: this.lang().ChangeDate,
            buttonText: this.lang().Change,
            currentDate: this.addEntryDate.getTime() / 1000,
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: false,
               parentOfDate: "log",
            },
         },
      };

      instance.result.then((result) => {
         if (result.date) {
            this.addEntryDate = new Date(result.date);
         }
      });
   };

   addEntry = () => {
      assert(this.asset !== undefined);
      assert(this.addEntryDate !== undefined);
      assert(this.newEntryViewable !== undefined);
      this.errorMsg = false;

      const files: Array<number> = [];
      this.fileQue = this.fileQue || [];
      this.pictureQue = this.pictureQue || [];

      for (const tempFile of this.fileQue) {
         files.push(tempFile.fileID);
      }

      for (const tempPic of this.pictureQue) {
         files.push(tempPic.fileID);
      }

      let valid = true;

      if (this.logUploadObj.uploading) {
         this.errorMsg = this.lang().pleaseWaitForFileToLoad;
         valid = false;
      }

      if (this.entryText === undefined && files.length == 0) {
         this.errorMsg = this.lang().PleaseEnterAMessage;
         valid = false;
      } else if (this.entryText == "" && files.length == 0) {
         this.errorMsg = this.lang().PleaseEnterAMessage;
         valid = false;
      }

      if (valid) {
         this.assetLogService
            .addEntry(
               this.entryText,
               this.asset,
               this.addEntryDate,
               files,
               this.newEntryViewable,
            )
            .then((answer) => {
               if (answer.data.success == true) {
                  this.clearFiles = true; //tell fileuploader to clear included files

                  this.addEntryDate = new Date();

                  answer.data.log.logFiles = [];
                  for (const tempFile of this.fileQue) {
                     this.addURL(tempFile, answer.data.log);
                     answer.data.log.logFiles.push(tempFile);
                  }
                  this.fileQue = [];

                  answer.data.log.logPictures = [];
                  for (const tempPic of this.pictureQue) {
                     this.addURL(tempPic, answer.data.log);
                     answer.data.log.logPictures.push(tempPic);
                  }
                  this.pictureQue = [];
                  const logAsset = this.manageAsset.getAsset(answer.data.log.assetID);
                  assert(logAsset);
                  answer.data.log.assetName = logAsset.assetName;
                  this.logItems.push(answer.data.log);

                  this.logItems = [...orderBy(this.logItems, this.orderBy)];

                  this.noSearchResults =
                     this.logItems?.length == 0 && this.logs?.length != 0;

                  this.entryText = "";

                  this.successMsg = this.lang().YouHaveSuccessfullyAddedAnEntry;
                  setTimeout(() => {
                     this.successMsg = "";
                     this.clearFiles = null;
                  }, 5000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }
   };

   public async popTask(checklistID: number): Promise<void> {
      const task = await this.manageTask.getTask(checklistID);
      if (!task) {
         return;
      }
      //promise based
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               editable: true,
            },
         },
      };
   }

   filterSource = (sourceID) => {
      this.sourceState = true;
      this.sourceID = sourceID;
      this.workOrderChoiceState = false;
      this.workOrderChoice = null;
      this.workOrderChoiceBatchID = null;
      this.workOrderChoiceAssetID = null;
      this.buildData();
   };

   clearSource = () => {
      this.sourceState = false;
      this.workOrderChoiceState = false;
      this.workOrderChoice = null;
      this.workOrderChoiceBatchID = null;
      this.workOrderChoiceAssetID = null;
      this.buildData();
   };

   filterDate = (duration) => {
      this.dateState = true;
      this.dateDuration = duration;
      this.buildData();
   };

   clearDateFilter = () => {
      this.dateState = false;
      this.buildData();
   };

   viewAsset = (assetID) => {
      assert(this.asset);
      if (assetID != this.asset.assetID) {
         const asset = this.manageAsset.getAsset(assetID);
         assert(asset);
         const instance = this.modalService.open(PopAsset);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               assetID: asset.assetID,
               locationID: asset.locationID,
               data: {
                  restrict: this.restrict,
               },
            },
         };
      } else if (assetID == this.asset.assetID) {
         this.alertService.addAlert(
            this.lang().YouAreAlreadyViewingThisAsset,
            "warning",
            2000,
         );
      }
   };

   setWorkOrderChoice = (logTask) => {
      this.workOrderChoice = logTask;
      this.workOrderChoiceBatchID = logTask.itemBatchID;
      this.workOrderChoiceAssetID = logTask.assetID;
      this.workOrderChoiceState = true;
      this.buildData();
   };

   hideShowLogEntry = (log) => {
      if (this.manageAssetLogCred == false) {
         this.alertService.addAlert(this.lang().cred67Fail, "danger", 10000);
         return;
      }

      if (log.logViewable == 1) {
         log.logViewable = 0;
      } else {
         log.logViewable = 1;
      }

      this.assetLogService.showHideLogEntry(log).then((answer) => {
         if (answer.data.success == true) {
            if (log.logViewable == 1) {
               this.alertService.addAlert(
                  this.lang().ThisLogIsNowVisibleForEverUser,
                  "success",
                  5000,
               );
            } else {
               this.alertService.addAlert(
                  this.lang().ThisLogIsNowVisibleForOnlyManagers,
                  "success",
                  5000,
               );
            }
         } else {
            if (log.logViewable == 1) {
               log.logViewable = 0;
            } else {
               log.logViewable = 1;
            }
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   addURL = (file, log) => {
      assert(this.asset);
      file.getURL = `viewFile.php?f=upload-${this.CID}/assets/${this.asset.locationID}/${this.asset.assetID}/logs/${log.logID}/${file.fileName}`;
   };

   editLogEntry = (log) => {
      if (this.manageAssetLogCred == false) {
         this.alertService.addAlert(this.lang().cred67Fail, "danger", 10000);
         return;
      }

      //let uploader know paths to display pictures
      log.logPictures.forEach((pic) => {
         this.addURL(pic, log);
      });

      const instance = this.modalService.open(EditAssetLogItem);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { log: log },
         },
      };
   };

   updateUIPref = () => {
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage =
         this.itemsPerPage;
      this.manageUser.updateUserUIPreferences();
      this.page = 1;
   };

   sortLogsBySortBind = (newSortValue) => {
      this.orderBy = newSortValue;
      this.logItems = orderBy(this.logItems, this.orderBy);
   };

   protected notNullish(value: string | null | undefined): boolean {
      return value != null && value != "";
   }
}
