<!-- This will display in the task view-->
<div
   class="image-container {{ holderClass }}"
   (click)="openViewer()"
   style="margin-bottom: 5px"
>
   @if (file.status === false) {
      <lim-ui-icon icon="banRegular" iconSize="small" />
   }

   <span class="icon-row">
      @if (permission) {
         <lim-ui-icon-button
            storeOffline
            icon="xmarkRegular"
            iconSize="medium"
            (click)="deleteImage(file); $event.stopPropagation()"
            limUiTooltip="{{ lang().DeleteThisFile }}"
            data-placement="bottom"
         />
      }
      @if (permission) {
         <lim-ui-icon-button
            storeOffline
            icon="pencil"
            (click)="edit(file); $event.stopPropagation()"
         />
      }
   </span>
   <img
      [src]="file.getURL ?? getURL"
      class="image"
      [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
   />
</div>

<!-- This will display in the print view-->
<img
   [src]="file.getURL ?? getURL"
   class="print-view-image"
   [attr.crossorigin]="crossOriginAnonymous ? 'anonymous' : undefined"
/>
