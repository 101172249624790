import type { OnInit } from "@angular/core";
import { inject, Component, ViewChild, computed } from "@angular/core";
import type { DragEndEvent, LimbleTreeOptions } from "@limble/limble-tree";
import { LimbleTreeRootComponent } from "@limble/limble-tree";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { StatusTreeItem } from "src/app/settings/components/statusTreeItemElement/statusTreeItem.element.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageStatus } from "src/app/tasks/services/manageStatus";

@Component({
   selector: "customize-statuses",
   templateUrl: "./customizeStatuses.modal.component.html",
   styleUrls: ["./customizeStatuses.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      LimbleTreeRootComponent,
      SecondaryButtonComponent,
   ],
})
export class CustomizeStatuses implements OnInit {
   @ViewChild("tree") limbleTree: LimbleTreeRootComponent | undefined;

   public message;
   public title;
   public resolve;
   public modalInstance;
   public treeOptions?: LimbleTreeOptions;
   public statuses: any = [];
   public defaultStatuses: any = [];
   public statusLastStep;

   private readonly paramsService = inject(ParamsService);
   private readonly manageStatus = inject(ManageStatus);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.getStatuses();
      this.getDefaultStatuses();

      this.treeOptions = {
         allowNesting: false,
         defaultComponent: {
            class: StatusTreeItem,
            bindings: {
               statuses: this.statuses,
               updateStatuses: this.updateStatuses,
               deleteStatus: this.deleteStatus,
               updateSingleStatus: this.updateSingleStatus,
            },
         },
      };
   }

   protected onMoveNode(event: DragEndEvent<StatusTreeItem>): void {
      const draggedField = this.statuses.splice(event.oldIndex(), 1);
      this.statuses.splice(event.newIndex(), 0, draggedField[0]);
      for (const [index, status] of this.statuses.entries()) {
         status.order = index + 1;
         status.sortOrder = index + 1;
      }
      this.updateStatuses();
   }

   getStatuses = () => {
      this.statuses = this.manageStatus.getStatusListWithoutDefaults();
   };

   getDefaultStatuses = () => {
      this.defaultStatuses = this.manageStatus.getStatusListDefaults();

      // Pop the last (completed) step off the default array and make it its own element so we can put the custom statuses between these in the UI
      // We're ok to mutate the array because we want it in two separate parts
      this.statusLastStep = this.defaultStatuses.pop();
   };

   updateStatuses = () => {
      this.manageStatus.updateStatuses(this.statuses).then((result) => {
         if (result.data.success) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.setError(result);
         }
      });
   };

   addStatus = () => {
      this.manageStatus.createStatus().then((result) => {
         if (result.data.success) {
            this.getStatuses();
            this.limbleTree?.update();
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.setError(result);
         }
      });
   };

   deleteStatus = (status) => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().AreYouSureYouWantToDeleteThisStatus} </br></br> <b>${this.lang().DeleteStatusMessage} "<span class='red-color'>${this.lang().Open}</span>."</b>`,
            title: this.lang().DeleteStatusTitle,
         },
      };

      instance.result.then((modalResult) => {
         if (modalResult) {
            this.manageStatus
               .deleteStatus(status.statusID, status.sortOrder)
               .then((result) => {
                  if (result.data.success) {
                     this.getStatuses();
                     this.limbleTree?.update();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.setError(result);
                  }
               });
         }
      });
   };

   updateSingleStatus = (status) => {
      //remove accidental tags from status name
      status.name.replace(/<br>/g, "");
      this.manageStatus.updateSingleStatus(status).then((result) => {
         if (result.data.success) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.setError(result);
         }
      });
      this.limbleTree?.update();
   };

   private setError(result: any): void {
      let errorMsg: string = this.lang().errorMsg;

      if (result.data.reason === "Must be a super user to update statuses.") {
         errorMsg = this.lang().MustBeASuperUserToUpdateStatuses;
      }
      this.alertService.addAlert(errorMsg, "danger", 6000);
   }

   close = () => {
      this.modalInstance.close();
   };
}
