<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()">
      <lim-ui-secondary-button modalTitle icon="plus" (click)="addSeason()">
         <span> {{ successString }}</span>
      </lim-ui-secondary-button>

      @if (seasonType === "holiday") {
         <lim-ui-secondary-button modalTitle (click)="openDefaultSeasonsModal()">
            {{ lang().CommonHolidays }}
         </lim-ui-secondary-button>
      }
   </lim-ui-basic-modal-header>
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message-area" [limbleHtml]="message"></span>
      </lim-ui-info-panel>
      @for (season of seasonsList | keyvalue: returnZero; track season) {
         <lim-ui-panel class="seasons-panel">
            <div class="seasons-container">
               <div class="left-col">
                  <span class="first-row">
                     @if (!season.value.defaultHolidayID) {
                        <input
                           type="text"
                           [(ngModel)]="season.value.name"
                           (blur)="updateSeasonName(season.value.name, season.value)"
                        />
                     }

                     @if (season.value.defaultHolidayID) {
                        <span
                           ><b>{{ season.value.name }}</b> -
                           {{ season.value.dateStr }}</span
                        >
                     }
                  </span>
                  <div class="start-and-end-values">
                     @if (!season.value.defaultHolidayID) {
                        <div class="start-values">
                           <label>{{ lang().Start }}</label>
                           <lim-ui-form-dropdown-input
                              [inputString]="season.value.startMonthStr"
                           >
                              <ng-container menu>
                                 @for (date of dates | keyvalue; track date) {
                                    <lim-ui-dropdown-text-item
                                       [displayString]="date.value.name"
                                       (click)="
                                          updateSeason(
                                             'startMonth',
                                             date.key,
                                             season.value
                                          )
                                       "
                                    />
                                 }
                              </ng-container>
                           </lim-ui-form-dropdown-input>

                           <lim-ui-form-dropdown-input
                              [inputString]="season.value.startDay"
                           >
                              <ng-container menu>
                                 @for (
                                    day of dates.get(+season.value.startMonth)?.daysArr ??
                                       [];
                                    track day
                                 ) {
                                    <lim-ui-dropdown-text-item
                                       [displayString]="day"
                                       (click)="
                                          updateSeason('startDay', day, season.value)
                                       "
                                    />
                                 }
                              </ng-container>
                           </lim-ui-form-dropdown-input>

                           <lim-ui-icon
                              icon="circleQuestionRegular"
                              placement="bottom"
                              limUiTooltip="{{ lang().startDateTooltip }}"
                           />
                        </div>
                     }

                     @if (season.value.endMonth > 0 && !season.value.defaultHolidayID) {
                        <div class="start-values">
                           <label>{{ lang().End }}</label>
                           <lim-ui-form-dropdown-input
                              [inputString]="season.value.endMonthStr"
                           >
                              <ng-container menu>
                                 @for (date of dates | keyvalue; track date) {
                                    <lim-ui-dropdown-text-item
                                       displayString="{{ date.value.name }}"
                                       (click)="
                                          updateSeason('endMonth', date.key, season.value)
                                       "
                                    />
                                 }
                              </ng-container>
                           </lim-ui-form-dropdown-input>

                           <lim-ui-form-dropdown-input
                              [inputString]="season.value.endDay"
                           >
                              <ng-container menu>
                                 @for (
                                    day of dates.get(+season.value.startMonth)?.daysArr;
                                    track day
                                 ) {
                                    <lim-ui-dropdown-text-item
                                       displayString="{{ day }}"
                                       (click)="updateSeason('endDay', day, season.value)"
                                    />
                                 }
                              </ng-container>
                           </lim-ui-form-dropdown-input>

                           <lim-ui-icon
                              icon="circleQuestionRegular"
                              placement="bottom"
                              limUiTooltip="{{ lang().endDateToolTip }}"
                           />
                        </div>
                     }

                     @if (seasonType === "holiday" && !season.value.defaultHolidayID) {
                        <div class="holiday-end-dates">
                           @if (+season.value.endMonth === 0) {
                              <div>
                                 <lim-ui-secondary-button
                                    modalTitle
                                    secondaryIcon="plus"
                                    (click)="addHolidayEndDates(season.value.seasonID)"
                                 >
                                    {{ lang().Add }}
                                    {{ lang().End }}
                                    {{ lang().Date }}
                                 </lim-ui-secondary-button>
                              </div>
                           }
                        </div>
                     }
                  </div>

                  @if (seasonType === "holiday") {
                     <div class="event-config">
                        <div class="scheduled-options">
                           <span class="text-align-for-dropdown-input">
                              {{ lang().PMsScheduledToBeCreatedOnThisHolidayWillBe }}:
                           </span>

                           <lim-ui-dropdown>
                              <span button id="scheduleChange">
                                 <a>
                                    {{ season.value.createdStr }}
                                 </a>
                              </span>
                              <div menu aria-labelledby="scheduleChange">
                                 <lim-ui-dropdown-text-item
                                    displayString="{{ lang().Skipped }}"
                                    (click)="updateSeasonEvent('skip', season.value)"
                                 />
                                 @if (
                                    season.value.defaultHolidayID &&
                                    season.value.defaultHolidayID === "WKE"
                                 ) {
                                    <lim-ui-dropdown-text-item
                                       displayString="{{
                                          lang().DueFridayBeforeTheWeekend
                                       }}"
                                       (click)="
                                          updateSeasonEvent('dayBefore', season.value)
                                       "
                                    />
                                 }
                                 @if (
                                    !season.value.defaultHolidayID ||
                                    season.value.defaultHolidayID !== "WKE"
                                 ) {
                                    <lim-ui-dropdown-text-item
                                       displayString="{{
                                          lang().DueTheDayBeforeTheHoliday
                                       }}"
                                       (click)="
                                          updateSeasonEvent('dayBefore', season.value)
                                       "
                                    />
                                 }
                                 @if (
                                    season.value.defaultHolidayID &&
                                    season.value.defaultHolidayID === "WKE"
                                 ) {
                                    <lim-ui-dropdown-text-item
                                       displayString="{{
                                          lang().DueMondayAfterTheWeekend
                                       }}"
                                       (click)="
                                          updateSeasonEvent('dayAfter', season.value)
                                       "
                                    />
                                 }
                                 @if (
                                    !season.value.defaultHolidayID ||
                                    season.value.defaultHolidayID !== "WKE"
                                 ) {
                                    <lim-ui-dropdown-text-item
                                       displayString="{{
                                          lang().DueTheDayAfterTheHoliday
                                       }}"
                                       (click)="
                                          updateSeasonEvent('dayAfter', season.value)
                                       "
                                    />
                                 }
                              </div>
                           </lim-ui-dropdown>
                        </div>
                        <div class="apply-all">
                           <lim-ui-checkbox
                              [label]="lang().ApplyToAllSchedules"
                              [(model)]="season.value.applyToAllSchedules"
                              (click)="toggleApplyAll(season.value)"
                              limUiTooltip="{{ season.value.applyAllTooltip }}"
                              placement="bottom"
                           />
                        </div>
                     </div>
                  }
               </div>
               <div class="right-col">
                  <lim-ui-icon-button
                     iconColor="danger"
                     icon="trashCanRegular"
                     (click)="deleteSeason(season.value)"
                  />
               </div>
            </div>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Save"
      (successClick)="saveSeasons()"
   />
</lim-ui-modal>
