@if (part) {
   <div
      class="flex-field-direction-column"
      [ngClass]="{
         'no-margin-left': usedIn === 'listItem',
      }"
   >
      <div class="flex-field-direction-row">
         @if (!part.partExtraBatchIDs.length) {
            <div class="flex-field-start">
               @if (isCurrencyPending()) {
                  <ngx-skeleton-loader [theme]="CurrencySkeletonTheme" />
               } @else {
                  @if (!part.partExtraBatchIDs.length && credChangePartPrice) {
                     <span class="lim-ui-input-icon-container">
                        <lim-ui-input-with-prefix
                           [prefixText]="currencyCode() | currencySymbol"
                           [type]="'number'"
                           [value]="part.partPrice ? part.partPrice : null"
                           (valueChange)="updatePartPrice($event)"
                           [min]="0"
                           [tabIndexInput]="'${item.poItemID}3'"
                           onclick="this.focus();"
                           class="number-field"
                        />
                     </span>
                  }
                  @if (!credChangePartPrice) {
                     <span>
                        {{ part.partPrice | localeCurrency: currencyCode() }}
                     </span>
                  }
               }
            </div>
         }
      </div>
      <div class="flex-field-direction-column extra-small-row-gap">
         @if (part.partExtraBatchIDs.length) {
            <div class="flex-field-direction-row">
               <span limUiTooltip="{{ lang().AveragePriceConsideringPOs }}">
                  {{ calculatedPartInfo?.averagePrice | localeCurrency: currencyCode() }}
               </span>
            </div>
         }
         <div class="flex-field-direction-row">
            @if (isLimbleSearchEnabled() && isLimbleSearchFeatureEnabled()) {
               <div class="flex-field-direction-row">
                  <lim-ui-icon icon="tagsRegular" />
                  <a (click)="onComparePricesClick(part)">
                     {{ lang().ComparePrices }}
                  </a>
               </div>
            }
         </div>

         @if (part.partExtraBatchIDs.length) {
            <div
               class="flex-field-direction-row interactive-text"
               (click)="togglePricingDetails()"
            >
               <span class="label">{{ i18n().t("PriceDetails") }}</span>
               <lim-ui-minimal-icon-button
                  [icon]="showPricingDetails ? 'chevronUp' : 'chevronDown'"
                  [limUiTooltip]="i18n().t('ViewPartsPricingDetails')"
                  [superMinimal]="true"
               />
            </div>
            @if (showPricingDetails && part.partExtraBatchIDs.length) {
               <div class="flex-field-direction-row">
                  {{ i18n().t("GS") }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     [limUiTooltip]="i18n().t('GeneralStockTooltip')"
                  />
                  @if (credChangePartPrice) {
                     <span>
                        {{ part.partQty | betterNumber: "1.0-0" }}
                        @if (canSeeUnitOfMeasure) {
                           <span
                              [limUiTooltip]="part.partID | partUnitOfMeasure: 'singular'"
                              >{{ part.partID | partUnitOfMeasure: "short" }}</span
                           >
                        }
                        {{ i18n().t("at") }}
                        <lim-ui-input-with-prefix
                           type="number"
                           class="table-field-text number-field input-large-width"
                           [prefixText]="currencyCode() | currencySymbol"
                           [value]="
                              part.partPrice !== null ? part.partPrice.toString() : null
                           "
                           (valueChange)="
                              part.partPrice =
                                 $event !== undefined && $event !== null ? +$event : null
                           "
                           [min]="0"
                           (inputBlur)="updatePartPrice()"
                        />
                        @if (canSeeUnitOfMeasure) {
                           {{ i18n().t("per") }}
                           <span
                              [limUiTooltip]="part.partID | partUnitOfMeasure: 'singular'"
                              >{{ part.partID | partUnitOfMeasure: "short" }}</span
                           >
                        } @else {
                           {{ i18n().t("perPart") }}
                        }
                     </span>
                  }

                  @if (!credChangePartPrice) {
                     <span>
                        {{ part.partQty | betterNumber: "1.0-0" }}
                        @if (canSeeUnitOfMeasure) {
                           <span
                              [limUiTooltip]="part.partID | partUnitOfMeasure: 'singular'"
                              >{{ part.partID | partUnitOfMeasure: "short" }}</span
                           >
                        }
                        {{ i18n().t("at") }}
                        {{ part.partPrice | localeCurrency: currencyCode() }}
                        @if (canSeeUnitOfMeasure) {
                           {{ i18n().t("per") }}
                           <span
                              [limUiTooltip]="part.partID | partUnitOfMeasure: 'singular'"
                              >{{ part.partID | partUnitOfMeasure: "short" }}</span
                           >
                        } @else {
                           {{ i18n().t("perPart") }}
                        }
                     </span>
                  }
               </div>
               <!-- </div> -->
               <div class="flex-field-direction-row">
                  <div class="flex-field-direction-column extra-small-row-gap">
                     @for (batchID of part.partExtraBatchIDs; track batchID) {
                        <div>
                           @if (extraBatchMap[batchID]; as batch) {
                              <div>
                                 @if (poItemsMap[batch.poItemID]; as poItem) {
                                    <a
                                       class="cursor"
                                       (click)="popPoComponent(batch.poItemID)"
                                    >
                                       @if (
                                          purchaseOrdersMap[poItem.poID];
                                          as purchaseOrder
                                       ) {
                                          {{ i18n().t("PO") }} -
                                          {{ purchaseOrdersMap[poItem.poID].poNumber }}
                                       }
                                    </a>
                                 }
                                 <span
                                    tooltip
                                    data-title="{{
                                       i18n().t('HowManyPartsOfThePOThatHaveBeenReceived')
                                    }}"
                                 >
                                    <span class="mobileLargeShow"
                                       >{{ i18n().t("Qty") }}:
                                    </span>
                                    {{
                                       (batch.partQty ?? 0) - (batch.partQtyUsed ?? 0)
                                          | betterNumber: "1.0-0"
                                    }}
                                    @let $purchasableSnapshot =
                                       batch.purchasableSnapshot();
                                    @if (
                                       canSeeUnitOfMeasure &&
                                       $purchasableSnapshot !== undefined &&
                                       $purchasableSnapshot !== null
                                    ) {
                                       <span
                                          [limUiTooltip]="
                                             batch.partID | partUnitOfMeasure: 'singular'
                                          "
                                          >{{
                                             batch.partID | partUnitOfMeasure: "short"
                                          }}</span
                                       >
                                    }
                                    {{ i18n().t("at") }}
                                    {{ batch.partPrice | localeCurrency: currencyCode() }}
                                    @if (
                                       canSeeUnitOfMeasure &&
                                       $purchasableSnapshot !== undefined &&
                                       $purchasableSnapshot !== null
                                    ) {
                                       {{ i18n().t("per") }}
                                       <span
                                          [limUiTooltip]="
                                             batch.partID | partUnitOfMeasure: 'singular'
                                          "
                                          >{{
                                             batch.partID | partUnitOfMeasure: "short"
                                          }}</span
                                       >
                                    } @else {
                                       {{ i18n().t("perPart") }}
                                    }
                                 </span>
                              </div>
                           }
                        </div>
                     }
                  </div>
               </div>
            }
         }
      </div>
   </div>
}
