import { Component, computed, inject, model, type Signal } from "@angular/core";
import type {
   LimUiProgressTrackerStep,
   LimUiProgressTrackerSteps,
} from "@limblecmms/lim-ui";
import {
   HorizontalProgressTrackerComponent,
   DonutProgressTrackerComponent,
} from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";

@Component({
   selector: "pm-builder-wizard-stepper",
   templateUrl: "./pm-builder-wizard-stepper.component.html",
   styleUrls: ["./pm-builder-wizard-stepper.component.scss"],
   standalone: true,
   imports: [HorizontalProgressTrackerComponent, DonutProgressTrackerComponent],
})
export class PmBuilderWizardStepperComponent {
   public readonly stepNumber = model<number>(1);

   protected readonly i18n = inject(TranslationService).i18n;

   protected steps: LimUiProgressTrackerSteps = {
      Step1: {
         name: "Name and Instructions",
         title: "Step 1",
         description: "First step",
         number: 1,
      },
      Step2: {
         name: "Schedules",
         title: "Step 2",
         description: "Second step",
         number: 2,
      },
      Step3: {
         name: "Assigned to",
         title: "Step 3",
         description: "Third step",
         number: 3,
      },
      Step4: {
         name: "Preview",
         title: "Step 4",
         description: "Fourth step",
         number: 4,
      },
   };

   public currentStep: Signal<LimUiProgressTrackerStep> = computed(() => {
      return this.steps[`Step${this.stepNumber()}`];
   });
   protected nextStep: Signal<LimUiProgressTrackerStep> = computed(() => {
      return this.steps[`Step${this.stepNumber() + 1}`];
   });

   protected totalNumberOfSteps = Object.keys(this.steps).length;
}
