import { inject, Injectable } from "@angular/core";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import type { Task } from "src/app/tasks/types/task.types";

@Injectable({ providedIn: "root" })
export class ChkItemService {
   private readonly manageFiles = inject(ManageFiles);
   private readonly featureFlagService = inject(FeatureFlagService);

   public setTaskItemFiles(
      taskItem,
      parentTask: Task | TaskTemplateEntity | TaskEntity | undefined,
      customerID: number,
   ): void {
      taskItem.instructionalImages = [];
      taskItem.instructionalFiles = [];
      if (parentTask === undefined) {
         return;
      }
      const taskImage = parentTask.taskImage;
      if (taskImage && taskItem.itemOrder === 1 && taskItem.itemParentID === 0) {
         taskItem.taskImage = true;
      }
      if (!taskItem.itemFileName || taskItem.itemFileName.length === 0) {
         return;
      }
      const images = taskItem.itemFileName.split(";");
      for (const image of images) {
         this.addFileToTaskItem(image, customerID, taskItem);
      }
   }

   private addFileToTaskItem(image: string, customerID: number, taskItem) {
      const regex = /(?:\.([^.]+))?$/;
      if (image.length <= 0) {
         return;
      }
      const ext = regex.exec(image)?.[1]?.toLowerCase();
      if (ext === undefined) {
         return;
      }

      if (this.manageFiles.imageExts.includes(ext)) {
         taskItem.instructionalImages.push({
            itemFileName: image,
            fileName: image,
            ext: ext,
            src: `viewFile.php?f=upload-${customerID}/items/${taskItem.itemBatchID}/${image}`,
         });
      } else {
         taskItem.instructionalFiles.push({
            itemFileName: image,
            fileName: image,
            ext: ext,
         });
         taskItem.instructionalFiles = this.manageFiles.sortByVisibleFileName(
            taskItem.instructionalFiles,
         );
      }
   }
}
