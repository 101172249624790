import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   IconComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PartPurchasablesListComponent } from "src/app/parts/components/part-modal/components/part-purchasables-list/part-purchasables-list.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { PurchaseOrderListComponent } from "src/app/purchasing/pos/purchase-order-list/purchase-order-list.component";
import {
   ManagePO,
   type PurchaseOrderItemToAddSkeleton,
} from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "part-purchase-orders-tab",
   templateUrl: "./part-purchase-orders-tab.component.html",
   styleUrls: ["./part-purchase-orders-tab.component.scss"],
   imports: [
      PanelComponent,
      PrimaryButtonComponent,
      SearchAllWrapperComponent,
      SearchBoxComponent,
      PartPurchasablesListComponent,
      PurchaseOrderListComponent,
      IconComponent,
   ],
})
export class PartPurchaseOrdersTabComponent implements OnInit, OnDestroy {
   @Input() public partID?: number | undefined;
   @Input() public vendorID?: number | undefined;
   @Input() public dataLogOptions: DataLogEventDefinition | undefined;

   public part: Part | undefined;
   public vendor: Vendor | undefined;
   public openPurchaseOrderWatchVarSub: any;
   public purchaseOrders: Lookup<"poID", PurchaseOrder> = new Lookup("poID");
   public searchHints: LimbleMap<number, string> = new LimbleMap();
   private manageFeatureFlagsSub: Subscription = new Subscription();

   private readonly manageParts = inject(ManageParts);
   private readonly manageLang = inject(ManageLang);
   private readonly managePO = inject(ManagePO);
   private readonly manageObservables = inject(ManageObservables);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageFilters = inject(ManageFilters);
   private readonly betterDate = inject(BetterDate);
   private readonly manageLocation = inject(ManageLocation);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected sortBind;
   protected canAddPOs: boolean = false;
   protected search: string = "";
   protected noSearchResults: boolean = false;
   protected isUnitOfMeasureVisible = this.unitOfMeasureService.isFeatureEnabled;

   public ngOnInit() {
      if (this.partID) {
         this.part = this.manageParts.getPart(this.partID);
      }

      this.openPurchaseOrderWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.setupPurchaseOrders();

            this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
               () => {
                  this.canAddPOs = this.manageFeatureFlags.canAddPOs();
               },
            );
         },
      );
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.openPurchaseOrderWatchVarSub);
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private setupPurchaseOrders(): void {
      this.purchaseOrders = this.partID
         ? this.managePO.getPurchaseOrdersByPart(this.partID)
         : new Lookup("poID");

      this.searchHints = new LimbleMap();

      if (this.search !== undefined && this.search.length > 0) {
         this.purchaseOrders =
            this.manageFilters.filterPurchaseOrdersToSearch(
               this.search,
               this.purchaseOrders,
               this.searchHints,
               this.managePO,
               this.manageLocation,
               this.manageVendor,
               this.betterDate,
            ) ?? this.purchaseOrders;
      }
   }

   protected popPurchaseOrder(poID: number): void {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };

      instance.result.then(() => {
         this.setupPurchaseOrders();
      });
   }

   protected createAPurchaseOrder(): void {
      if (!this.part || !this.canAddPOs) {
         return;
      }

      const locationID = this.part
         ? this.part.locationID
         : (this.vendor?.locationID ?? 0);

      if (
         !this.credService.isAuthorized(locationID, this.credService.Permissions.StartAPO)
      ) {
         this.alertService.addAlert(this.lang().cred144Fail, "danger", 10000);
         return;
      }

      const poItemToAddTemplate = this.setPurchaseOrderItemSkeleton();

      if (this.part.defaultVendorID === null || this.part.defaultVendorID < 0) return;

      this.managePO
         .addPurchaseOrder(
            this.part.locationID,
            [poItemToAddTemplate],
            this.part.defaultVendorID,
            0,
            0,
            "",
            "standard",
         )
         .then((answer) => {
            if (answer?.data.success === true) {
               this.popPurchaseOrder(answer.data.po.poID);
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   protected searchPurchaseOrders(): void {
      this.setupPurchaseOrders();

      this.noSearchResults = this.purchaseOrders.size === 0;
   }

   private setPurchaseOrderItemSkeleton(): PurchaseOrderItemToAddSkeleton {
      assert(this.part);
      const poItemToAddTemplate: PurchaseOrderItemToAddSkeleton = {
         itemType: 1,
         partID: this.part.partID,
         assetID: 0,
         checklistID: 0,
         description: "",
         qty: this.managePO.calcPurchaseOrderItemQty(this.part),
         glID: 0,
         rate: 0,
      };

      poItemToAddTemplate.description = this.manageParts.generateDescriptionForPart(
         poItemToAddTemplate,
         this.part,
      );

      return poItemToAddTemplate;
   }
}
