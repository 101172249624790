<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message" [limbleHtml]="message"></span>
         <span class="depreciation-equation">{{ lang().depreciationEquation }}</span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="lim-ui-form-column">
            <div class="enable-container">
               <lim-ui-checkbox
                  [(model)]="depreciationOptions.scheduleActive"
                  (modelChange)="updateDepreciationOptions()"
                  [disabled]="fieldLocked"
                  limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
               />
               <label>
                  {{ lang().Enabled }}
               </label>
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  placement="right"
                  limUiTooltip="{{ lang().ScheduleEnabledTooltip }}"
               />
            </div>
            <div class="form-group-container">
               <div>
                  <label>
                     {{ lang().PurchaseCost }} ({{ currencyCode() | currencySymbol }})
                  </label>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="right"
                     limUiTooltip="{{ lang().PurchaseCostTooltip }}"
                  />
               </div>
               <div class="value">
                  <input
                     type="number"
                     step="0.01"
                     class="input-control number-sizing"
                     [(ngModel)]="depreciationOptions.purchaseCost"
                     (blur)="updateDepreciationOptions()"
                     min="0"
                     name="purchaseCost"
                     [disabled]="!depreciationOptions.scheduleActive || fieldLocked"
                     limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
                  />
               </div>
            </div>
            <div class="form-group-container">
               <div>
                  <label>
                     {{ lang().SalvageValue }} ({{ currencyCode() | currencySymbol }})
                  </label>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="right"
                     limUiTooltip="{{ lang().SalvageValueTooltip }}"
                  />
               </div>
               <div>
                  <input
                     type="number"
                     class="input-control number-sizing"
                     [(ngModel)]="depreciationOptions.salvageValue"
                     (blur)="updateDepreciationOptions()"
                     min="0"
                     name="salvageValue"
                     [disabled]="!depreciationOptions.scheduleActive || fieldLocked"
                     limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
                  />
               </div>
            </div>
            <div class="form-group-container">
               <div>
                  <label> {{ lang().StandardUsefulLife }} ({{ lang().Months }}) </label>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="right"
                     limUiTooltip="{{ lang().StandardUsefulLifeTooltip }}"
                  />
               </div>
               <div>
                  <input
                     type="number"
                     class="input-control number-sizing"
                     [(ngModel)]="depreciationOptions.standardUsefulLife"
                     (blur)="updateDepreciationOptions()"
                     min="0"
                     name="standardUsefulLife"
                     [disabled]="!depreciationOptions.scheduleActive || fieldLocked"
                     limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
                  />
               </div>
            </div>
            <div class="form-group-container">
               <div>
                  <label>
                     {{ lang().DepreciationScheduleStartDate }}
                  </label>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="right"
                     limUiTooltip="{{ lang().DepreciationScheduleStartDateTooltip }}"
                  />
               </div>
               <div>
                  <lim-ui-date-picker-input
                     [(model)]="depreciationOptions.depreciationStartDate"
                     (modelChange)="updateDepreciationOptions()"
                     [disabled]="!depreciationOptions.scheduleActive || fieldLocked"
                     limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
                  />
               </div>
            </div>
            <div class="form-group-container">
               <div>
                  <label> {{ lang().EndOfStandardUsefulLifeTasksAssignedTo }} </label>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="right"
                     limUiTooltip="{{
                        lang().EndOfStandardUsefulLifeTaskAssignmentTooltip
                     }}"
                  />
               </div>
               <div>
                  @if (!fieldLocked) {
                     <a
                        class="cursor"
                        (click)="changeTaskAssignment()"
                        limUiTooltip="{{
                           fieldLocked ? lang().FieldLockdedTooltip : null
                        }}"
                        >{{ userOrProfileDisplay }}</a
                     >
                  }
                  @if (fieldLocked) {
                     <span limUiTooltip="{{ lang().FieldLockdedTooltip }}">{{
                        userOrProfileDisplay
                     }}</span>
                  }
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
