import { inject } from "@angular/core";
import { firstValueFrom, type Observable } from "rxjs";
import {
   LiteEnabledState,
   LiteEnablementService,
} from "src/app/lite/lite-enablement.service";

/**
 * All public methods of the concrete class must be wrapped with `this.sync()` or `this.query()`.
 */
export class StorageSyncService {
   private readonly isLiteEnabled = inject(LiteEnablementService).liteEnabled;

   protected async sync(update: () => Promise<void>): Promise<void> {
      if (this.isLiteEnabled() !== LiteEnabledState.Enabled) return;
      await update();
   }

   protected async query<T>(query: () => Observable<T>): Promise<T | null> {
      if (this.isLiteEnabled() !== LiteEnabledState.Enabled) return null;
      return firstValueFrom(query());
   }
}
