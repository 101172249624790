import { Injectable, inject, type Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ModalService } from "@limblecmms/lim-ui";
import { from } from "rxjs";
import { AddPartModalComponent } from "src/app/parts/components/add-part-modal/add-part-modal.component";
import { AddPartModalComponentLegacy } from "src/app/parts/components/add-part-modal-legacy/add-part-modal-legacy.component";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";

export interface AddPartModalData {
   title: string;
   buttonText: string;
   message?: string;
   taskID?: number;
   taskTemplate?: boolean;
   locationID?: number | undefined;
   dataLogOptions?: DataLogEventDefinition | undefined;
   popSelectionOnly?: boolean;
   selectOne?: boolean;
   singleLocation?: number;
   hideCreatePart?: boolean;
}

@Injectable({
   providedIn: "root",
})
export class PartsFacadeService {
   private readonly modal = inject(ModalService);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);

   private readonly isJitCompletedTasksPartsEnabled: Signal<boolean | undefined> =
      toSignal(from(this.launchFlagsService.isEnabled(Flags.JIT_CT_PARTS)));

   public async openAddPartModal(modalData: AddPartModalData): Promise<any> {
      let modalRef;
      if (this.isJitCompletedTasksPartsEnabled()) {
         modalRef = this.modal.open(AddPartModalComponent);
      } else {
         modalRef = this.modal.open(AddPartModalComponentLegacy);
      }

      modalRef.setInput("modalData", modalData);

      const result = await modalRef.result;

      return result;
   }
}
