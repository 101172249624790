<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      [title]="title"
      [subTitle]="message"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      @if (end > 0) {
         <div class="uploading-popup">
            <lim-ui-loading-animation />
            <div class="progress-bar">
               <div class="text">
                  <div class="upload-header">{{ lang().WakingUpHamsters }}</div>
                  <lim-ui-progress-bar
                     [progress]="(currentlyAt / end) * 100 | betterNumber: '1.0-0'"
                     label="{{ lang().ThisMayTakeAMoment }}"
                  />
               </div>
            </div>
         </div>
      }

      @if (fileReviewReady) {
         <div class="info-panel-wrapper">
            <lim-ui-info-panel>
               <div class="content">
                  {{ lang().BasedOnWhatIsUploadedTheFollowingWillHappen }}
               </div>
            </lim-ui-info-panel>
         </div>
      }
      <lim-ui-panel>
         @if (type === "import" && !fileReviewReady) {
            <div class="steps">
               <h1>{{ lang().ToImportGLsPleaseFollowTheFollowingSteps }}</h1>
               <ol>
                  <li>
                     {{ lang().DownloadThe
                     }}<a
                        class="link-styling"
                        (click)="downloadCSV(sample_general_ledger_list)"
                     >
                        {{ lang().SampleImportFile }}
                     </a>
                  </li>
                  <li>
                     {{ lang().GeneralLedgerImportsHaveNoCustomFields }}
                     <div>
                        <div>
                           <div class="red">
                              {{ lang().GeneralLedgerRequiredFields }}
                           </div>
                        </div>
                        <div>
                           <div class="red">
                              {{ lang().AbbreviationMustBe1to4Chars }}
                           </div>
                        </div>
                        <div>
                           <div class="red">
                              {{ lang().AssociateAnAssetWithGeneralLedger }}
                           </div>
                           <lim-ui-icon
                              icon="circleQuestionRegular"
                              iconSize="small"
                              limUiTooltip="{{ lang().AssociateAssetTooltip }}"
                           />
                        </div>
                     </div>
                  </li>
                  <li>
                     {{ lang().GeneralLedgerStartAddingRows }}
                     <div>
                        <h1>
                           {{ lang().GeneralLedgerImportLimit }}
                        </h1>
                     </div>
                  </li>
                  <li>{{ lang().SaveAndUploadTheFile }}.</li>
               </ol>
            </div>
         }

         @if (type === "import" && fileReviewReady) {
            <div>
               @if (file?.badRows && file?.badRows?.length > 0) {
                  <div>
                     <b class="red-color">{{ lang().GeneralLedgersThatCantBeAdded }}</b>
                     <br />

                     @for (row of file?.badRows; track row) {
                        <div class="lim-ui-list-item">
                           <lim-ui-icon
                              icon="triangleExclamation"
                              iconSize="small"
                              iconColor="warn"
                           />
                           @if (row.name) {
                              <span>{{ row.name }} - </span>
                           }
                           <span>{{ row.error }}</span>
                        </div>
                     }
                  </div>
               }

               <div>
                  <h1 class="upload-header">{{ lang().GeneralLedgers }}</h1>

                  @if (GeneralLedgersToAdd?.length > 0) {
                     <span>
                        <a
                           (click)="
                              showGeneralLedgersToBeAdded = !showGeneralLedgersToBeAdded
                           "
                           class="cursor"
                        >
                           {{ GeneralLedgersToAdd?.length }}
                           {{ lang().GeneralLedgersWillBeAdded }}
                        </a>
                     </span>
                  }
                  @if (GeneralLedgersToAdd?.length == 0) {
                     <span> 0 {{ lang().GeneralLedgersWillBeAdded }} </span>
                  }
                  @if (GeneralLedgersToAdd?.length > 0 && showGeneralLedgersToBeAdded) {
                     <div>
                        @for (row of GeneralLedgersToAdd; track row) {
                           <div
                              class="lim-ui-list-item lim-ui-selectable-list-item lim-ui-selected-list-item"
                           >
                              <lim-ui-icon
                                 icon="circleCheck"
                                 iconSize="small"
                                 iconColor="success"
                              />
                              {{ row.name }}
                           </div>
                        }
                     </div>
                  }
               </div>
            </div>
         }
         @if (errorMsg) {
            <lim-ui-alert [title]="lang().Warning" alertType="danger">
               <div [limbleHtml]="errorMsg"></div>
            </lim-ui-alert>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (!fileReviewReady) {
         @if (uploadObject) {
            <file-uploader
               [uploadObject]="uploadObject"
               label="{{ lang().UploadCSV }}"
               buttonType="primary"
               [loadingIndicator]="true"
               accept="import"
            />
         }
      }
      @if (fileReviewReady) {
         <lim-ui-primary-button (click)="confirmUpload()" [disabled]="buttonDisabled">
            {{ lang().Confirm }}
         </lim-ui-primary-button>
      }
   </lim-ui-modal-footer>
</lim-ui-modal>
