import { HttpErrorResponse } from "@angular/common/http";
import { computed, inject, Injectable } from "@angular/core";
import { ModalService, LoadingBarService } from "@limblecmms/lim-ui";
import { firstValueFrom } from "rxjs";
import { PickNewAssetType } from "src/app/assets/components//pickNewAssetTypeModal/pickNewAssetType.modal.component";
import { ViewAssets } from "src/app/assets/components//viewAssetsModal/viewAssets.modal.component";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { AssetErrorService } from "src/app/assets/services/asset-error.service";
import type { AssetTemplate } from "src/app/assets/services/asset-template-api.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { CreateAssetSelection } from "src/app/assets/types/create-asset-selection.types";
import type { PickAssetDataLogType } from "src/app/assets/types/pick-assets.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Injectable({ providedIn: "root" })
export class ManageAssetServ {
   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly assetErrorService = inject(AssetErrorService);

   private newAssetID: number | undefined = undefined;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public async addAssetLogic(
      currentLocationID: number,
      parentAssetID: number,
      isToBeNestedUnderSelectedChildsParent: boolean = false,
      newAssetSearchTerm?: string,
   ): Promise<number | undefined> {
      const instance2 = this.modalService.open(PickNewAssetType);

      this.paramsService.params = {
         modalInstance: instance2,
         resolve: {
            currentLocationID: currentLocationID,
            newAssetSearchTerm: newAssetSearchTerm,
         },
      };
      const result: {
         asset?: Asset | undefined;
         assetTemplate?: AssetTemplate;
         selection?: CreateAssetSelection;
         locationID: number;
         newName: string;
      } = await instance2.result;
      if (result.selection == null) {
         return undefined;
      }
      const locationID = result.locationID;

      const returnData: any = {};
      returnData.selection = result.selection; //we will add assetID later on

      if (result.selection === "blank") {
         //blank asset
         return this.newAsset(locationID, result.newName, parentAssetID);
      } else if (result.selection === "copy" && result.asset) {
         //copying an asset
         const children = this.manageAsset.findChildrenIDs(result.asset, []);

         if (parentAssetID && children?.length > 0) {
            const amIMyOwnGrandpa = this.willThisMakeMeMyOwnGrandpa(
               parentAssetID,
               children,
            );
            if (amIMyOwnGrandpa === true) {
               this.alertService.addAlert(
                  this.lang().TheChildAssetIsAParentErrorMsg,
                  "danger",
                  6000,
               );
               return undefined;
            }
         }

         // If we are adding from asset children view and copying an asset to the child heirarchy,
         // we need to make sure that the copied asset's parentAssetID is nested under the original
         // selected asset's parentAssetID
         let newAssetCopiedFromParentID = 0;
         if (parentAssetID && isToBeNestedUnderSelectedChildsParent) {
            newAssetCopiedFromParentID = parentAssetID;
         }

         if (children.length > 0) {
            return this.copyAssetWithChildrenForAddAsset(
               children,
               result.asset,
               locationID,
               result.newName,
               newAssetCopiedFromParentID,
            );
         }
         return this.copyAssetForAddAsset(
            parentAssetID,
            result.asset,
            locationID,
            result.newName,
            newAssetCopiedFromParentID,
         );
      } else if (result.selection === "template" && result.assetTemplate) {
         this.newAssetID = await this.newAsset(
            locationID,
            result.newName,
            parentAssetID,
            result.assetTemplate.ID,
         );

         if (this.newAssetID == undefined || this.newAssetID == null) {
            return undefined;
         }
         const newAsset = this.manageAsset.getAsset(this.newAssetID);
         if (newAsset == null) {
            return undefined;
         }
      }
      return undefined;
   }

   public async moveAssetLogic(
      assetToMove: Asset,
      dataLogSelectLabel?: PickAssetDataLogType,
   ): Promise<void> {
      const assetToMoveID = assetToMove.assetID;
      const prevParentID = assetToMove?.parentAssetID ?? 0;

      const prevParent = this.manageAsset.getAsset(prevParentID);

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.lang().SelectNewParent;
      instance.title = `${this.lang().SelectNewParentFor} (${assetToMove.assetName})`;
      instance.singleLocation = assetToMove.locationID;
      instance.selectOne = true;
      instance.restrictToCred = false;
      instance.iDontKnowOption = false;
      instance.hiddenAssetIDs = [assetToMove.assetID];
      instance.dataLogSelectLabel = dataLogSelectLabel;

      const result = await modalRef.result;
      if (!result?.assetID) {
         return;
      }

      const newParentID = result.assetID;

      const newParent = this.manageAsset.getAsset(newParentID);
      if (!newParent) {
         return;
      }

      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });

      /**
       * Remove asset from previous parent's children list.
       */
      if (prevParent !== undefined) {
         const oldIndex = prevParent.assetChildrenIDs.findIndex(
            (assetID) => assetID === assetToMoveID,
         );
         prevParent.assetChildrenIDs.splice(oldIndex, 1);
      }

      /**
       * Add asset to new parent's children list.
       */
      assetToMove.parentAssetID = newParentID;
      newParent.assetChildrenIDs.push(assetToMoveID);

      /**
       * Update relationship in db.
       */
      const updateRelationshipResult = await firstValueFrom(
         this.manageAsset.updateParentChildRelationship(
            [],
            assetToMoveID,
            newParentID,
            prevParentID,
         ),
      );
      if (updateRelationshipResult.success === false) {
         this.loadingBarService.remove();
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      this.loadingBarService.remove();

      this.alertService.addAlert(this.lang().successMsg, "success", 2000);
   }

   private async newAsset(
      locationID: number,
      newName: string,
      parentAssetID: number,
      templateID?: number,
   ): Promise<number | undefined> {
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });

      try {
         const answer = await this.manageAsset.addAsset(
            locationID,
            newName,
            parentAssetID,
            templateID,
         );
         this.alertService.clearAllAlerts();
         this.loadingBarService.remove();

         if (answer.data.success === true) {
            this.alertService.addAlert(
               this.lang().AssetSuccessfullyAdded,
               "success",
               1000,
            );
            return answer.data.asset.assetID;
         }
      } catch (err) {
         this.loadingBarService.remove();
         if (err instanceof HttpErrorResponse) {
            this.assetErrorService.handleRequestError(err);
         }
         return undefined;
      }

      this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      return undefined;
   }

   public async copyAssetForAddAsset(
      parentAssetID: number,
      assetToCopy: Asset,
      locationID: number,
      newName: string,
      newAssetCopiedFromParentID: number = 0,
   ): Promise<number | undefined> {
      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      const answer = await this.manageAsset.copyAsset(
         assetToCopy.assetID,
         locationID,
         newName,
         parentAssetID,
         false,
         newAssetCopiedFromParentID,
      );
      this.alertService.clearAllAlerts();
      if (!answer.data.success) {
         this.loadingBarService.remove();
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return undefined;
      }
      if (answer.data.errors) {
         for (const key in answer.data.errors) {
            this.alertService.addAlert(`${answer.data.errors[key]}`, "danger", 30000);
         }
      }
      this.alertService.addAlert(this.lang().AssetSuccessfullyAdded, "success", 1000);
      this.loadingBarService.remove();
      const topLevelNewAssetID = answer.data.newAssetIDs[0] ?? undefined;
      return topLevelNewAssetID;
   }

   public async copyAssetWithChildrenForAddAsset(
      children: Array<number>,
      assetToCopy: Asset,
      locationID: number,
      newName: string,
      newAssetCopiedFromParentID: number = 0,
   ): Promise<number | undefined> {
      children.push(assetToCopy.assetID); //add the original asset on top.

      const instance = this.modalService.open<ViewAssets, string | undefined>(ViewAssets);

      instance.componentInstance.message.set(this.lang().CopyChildrenAssetsMsg);
      instance.componentInstance.title.set(this.lang().CopyChildrenAssetsTitle);
      instance.componentInstance.assetsIDs.set(children);
      instance.componentInstance.actionType.set("copy");
      instance.componentInstance.buttonActionAll.set({
         text: this.lang().CopyChildrenAssetsYesButton,
         dataLogLabel: "CopyChildAssets",
      });
      instance.componentInstance.buttonActionTopLevel.set({
         text: this.lang().CopyChildrenAssetsNoButton,
         dataLogLabel: "DoNotCopyChildAssets",
      });
      instance.componentInstance.dataLogWorkflowLabel.set("AddAnAssetCopy");

      const childrenResult = await instance.result;

      if (!childrenResult?.length) return undefined;

      const copyChildren = childrenResult === "includeChildren";
      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      const answer = await this.manageAsset.copyAsset(
         assetToCopy.assetID,
         locationID,
         newName,
         assetToCopy.parentAssetID ?? 0,
         copyChildren,
         newAssetCopiedFromParentID,
      );
      this.alertService.clearAllAlerts();

      if (answer.data.success !== true) {
         this.loadingBarService.remove();
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return undefined;
      }
      if (answer.data.errors) {
         for (const key in answer.data.errors) {
            this.alertService.addAlert(`${answer.data.errors[key]}`, "danger", 30000);
         }
      }
      this.alertService.addAlert(this.lang().AssetSuccessfullyAdded, "success", 1000);
      this.loadingBarService.remove();
      const topLevelNewAssetID = answer.data.newAssetIDs[0] ?? undefined;
      return topLevelNewAssetID;
   }

   private willThisMakeMeMyOwnGrandpa(
      parentID: number,
      childrenIDs: Array<number>,
   ): boolean {
      // This function is for preventing the creation of circular dependencies / loops in our data.
      // For instance if you are trying to add a child asset onto a parent asset, it will make sure that the
      // asset that we are adding as a child isn't already a parent of the asset that we are adding a child to.
      return childrenIDs.includes(parentID);
   }
}
