<lim-ui-modal>
   <lim-ui-basic-modal-header
      [title]="title"
      [subTitle]="message"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      <lim-ui-panel>
         @for (type of types | orderBy: "order"; track type) {
            <div
               class="lim-ui-list-item lim-ui-selectable-list-item"
               [ngClass]="{ 'lim-ui-selected-list-item': type.selected == true }"
               (click)="selectType(type)"
            >
               <div class="lim-ui-list-item-content-group">
                  @if (type.selected) {
                     <lim-ui-icon icon="check" iconSize="small" iconColor="primary" />
                  }
                  <div>{{ type.text }}</div>
               </div>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Change"
      (successClick)="submit()"
   />
</lim-ui-modal>
