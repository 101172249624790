import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { Component, computed, inject, ViewChild } from "@angular/core";
import {
   BasicModalHeaderComponent,
   FilterInputComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   ModalService,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import { firstValueFrom, type Subscription } from "rxjs";
import { AssetVendorAssociationService } from "src/app/assets/services/asset-vendor-association.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationHierarchyService } from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy.service";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import type { PermissionID } from "src/app/users/schemata/users/self/credentials/permission.enum";
import { CredService } from "src/app/users/services/creds/cred.service";
import { PickNewVendorType } from "src/app/vendors/components/pickNewVendorTypeModal/pickNewVendorType.modal.component";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

type nodeInfo = HierarchyNode & {
   uniqueID: string;
   vendorID?: number;
};

@Component({
   selector: "pick-vendors",
   templateUrl: "./pickVendors.modal.component.html",
   styleUrls: ["./pickVendors.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      SelectionControlsComponent,
      SearchAllWrapperComponent,
      SearchBoxComponent,
      FilterInputComponent,
      NgClass,
      IconComponent,
      HierarchyContainerLegacy,
      NoSearchResults,
      ModalFooterComponent,
      SecondaryButtonComponent,
      TooltipDirective,
      PrimaryButtonComponent,
      UpsellPopover,
      PopoverDirective,
   ],
})
export class PickVendors implements OnInit, OnDestroy {
   @ViewChild("hierarchy") private readonly hierarchy?: HierarchyContainerLegacy;

   public resolve;
   public modalInstance;
   public originalData;
   public message;
   public title;
   public errorMsg;
   public selection;
   public selectOne;
   public singleLocation;
   public restrictToCred;
   public iDontKnowOption;
   public locations;
   public loadCount;
   public loadedHier;
   public filterToAssociatedVendors;
   public filteredToAssocVendors;
   public unsure;
   public addVendorCred;
   public searchBar;
   public searchBarBefore;
   public searchTimer;
   public totalVendorsLength;
   private extraCredCheck: PermissionID | null = null;
   public locLength;
   public vendorsLength;
   public vendors: Lookup<"vendorID", Vendor> | undefined;
   public vendorSearchHints: Map<number, string> = new Map();
   public data: Array<nodeInfo> = [];
   public nodeInfoMappedToUniqueID: Map<string, nodeInfo> = new Map();
   public locationsIndex;
   public hierarchyOptions: HierarchyOptions;
   private task: Task | undefined;
   private popSelectionOnly: boolean = false;
   protected canAddVendors: boolean = false;
   protected dataLogOptions: DataLogEventDefinition | undefined;
   private readonly manageFeatureFlagsSub: Subscription;
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly betterDate = inject(BetterDate);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly locationHierarchyService = inject(LocationHierarchyService);
   private readonly manageLang = inject(ManageLang);
   private readonly assetVendorAssociationService = inject(AssetVendorAssociationService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.locationsIndex = this.manageLocation.getLocationsIndex();

      this.hierarchyOptions = {
         idKey: "vendorID",
         selection: {
            singleSelection: true,
         },

         submit: this.submit.bind(this),
      };

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(() => {
         this.canAddVendors = this.manageFeatureFlags.canAddVendors();
      });
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.dataLogOptions = this.resolve.dataLogOptions;
      this.originalData = this.resolve.data;
      this.message = this.resolve.message;
      this.title = this.resolve.title;

      this.errorMsg = false;
      this.selection = 0;
      this.popSelectionOnly = this.originalData.popSelectionOnly;
      this.selectOne = this.originalData.selectOne; //flag to allow the user to only pick one vendor
      this.hierarchyOptions.selection = { singleSelection: Boolean(this.selectOne) };
      this.singleLocation = this.originalData.singleLocation; //flag to allow the user to only view for a single location value of - means they see all locations they have creds to see.
      this.restrictToCred = this.originalData.restrictToCred; //flag to say if we should restrict the view to only locations they can manage vendors at
      this.iDontKnowOption = this.originalData.iDontKnowOption; //this is a flag to allow the user to pick I don't know which mean has the value of 0 or don't vendor to an vendor.
      if (this.restrictToCred === true) {
         this.extraCredCheck = this.originalData.extraCredCheck;
      }
      this.locations = {};
      this.loadCount = 0;
      this.loadedHier = false;

      if (this.originalData.checklistID) {
         this.task = this.manageTask.getTaskLocalLookup(this.originalData.checklistID);
      }

      if (this.task?.assetID && this.task.assetID > 0) {
         this.filterToAssociatedVendors = true;
      } else {
         this.filterToAssociatedVendors = false;
      }

      this.filteredToAssocVendors = false;
      this.buildHierView();
      this.unsure = false;
   }

   public ngOnDestroy(): void {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   updateSearchBar = () => {
      if (this.searchBar === this.searchBarBefore) return;

      this.searchBarBefore = this.searchBar;
      if (this.searchTimer) {
         clearTimeout(this.searchTimer);
      }

      this.searchTimer = setTimeout(() => {
         this.buildHierView();
      }, 250);
   };

   viewAssociatedVendors = () => {
      this.filteredToAssocVendors = !this.filteredToAssocVendors;
      this.buildHierView();
   };

   private async buildHierView() {
      this.locations = [];
      let regions;
      let regionsIndex = {};

      if (this.singleLocation > 0) {
         const locationsIndex = this.manageLocation.getLocationsIndex();
         if (locationsIndex[this.singleLocation] === undefined) {
            return; //error catch if the location they are trying to look at doesn't exist
         }
         this.locations.push(locationsIndex[this.singleLocation]);
      } else {
         this.locations = this.manageLocation.getLocations();
      }

      if (this.restrictToCred === true) {
         const tempArr: any = []; //filter to only locations where they have the Change PO Vendor credential
         for (const location of this.locations) {
            if (
               this.credService.isAuthorized(
                  location.locationID,
                  this.credService.Permissions.ChangePOVendor,
               ) ||
               (this.extraCredCheck &&
                  this.credService.isAuthorized(location.locationID, this.extraCredCheck))
            ) {
               tempArr.push(location);
            }
         }
         this.locations = tempArr;
      }

      this.addVendorCred = this.credService.checkCredAnywhere(
         this.credService.Permissions.AddVendor,
      ); //if they have add vendor cred anywhere let them add a Vendor

      const locationIDs: any = [];
      for (const location of this.locations) {
         locationIDs.push(location.locationID);
      }

      if (this.manageLocation.getRegions().length > 0) {
         //they are using regions so we have to behave a little differently
         const rst = this.locationHierarchyService.buildHierarchy({
            locations: this.locations,
            regions: this.manageLocation.getRegions(),
            search: this.searchBar,
            filterRegions: false,
            filterLocations: false,
            alwaysReturnRegions: false,
            totalLocationCount: this.manageLocation.getLocations().length,
         });
         regions = rst.regionTree;
         regionsIndex = rst.regionsIndex;
      }

      const allVendors = this.manageVendor.getVendors();
      let filteredVendors = Array.from(allVendors).filter(
         (vendor) =>
            (vendor.vendorDeleted === 0 || vendor.vendorDeleted === null) &&
            locationIDs.includes(vendor.locationID),
      );

      this.totalVendorsLength = filteredVendors.length;

      if (this.filteredToAssocVendors === true) {
         const associations = await firstValueFrom(
            this.assetVendorAssociationService.getVendors([this.task?.assetID ?? 0]),
         );
         const vendorIDs = associations?.map((vendor) => vendor.vendorID).filter(Boolean);
         const uniqueVendorIDs = Array.from(new Set(vendorIDs));

         //filter the list of vendors to those whose ids appear on our list of vendor ids
         filteredVendors = this.manageFilters.filterVendorsToVendorIDs(
            filteredVendors,
            uniqueVendorIDs,
         );
      }

      this.vendors = new Lookup("vendorID", filteredVendors);

      if (this.searchBar !== undefined) {
         if (this.searchBar.length > 1) {
            this.vendors = this.manageFilters.filterVendorsToNameAndTextFields(
               this.vendors,
               this.manageVendor.getFields(),
               this.manageVendor.getValues(),
               this.manageVendor.getFiles(),
               this.vendorSearchHints,
               {
                  search: this.searchBar,
                  hier: false,
               },
               this.betterDate,
            );
         }
      }

      this.vendors = this.vendors.orderBy("vendorName");

      const vendorIDsMappedToLocationIDs: Map<number, Array<number>> = new Map();

      for (const vendor of this.vendors) {
         const nodeInfo: nodeInfo = {
            title: vendor.vendorName ?? "",
            icon: "addressCard",
            uniqueID: `vendor-${vendor.vendorID}`,
            collapsed: true,
            selected: false,
            nodes: [],
            displayButtons: true,
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
         };
         this.nodeInfoMappedToUniqueID.set(nodeInfo.uniqueID, nodeInfo);
         const vendorIDsAtThisLocation = vendorIDsMappedToLocationIDs.get(
            vendor.locationID,
         );
         if (!vendorIDsAtThisLocation) {
            vendorIDsMappedToLocationIDs.set(vendor.locationID, [vendor.vendorID]);
            continue;
         }
         vendorIDsAtThisLocation.push(vendor.vendorID);
      }

      this.locLength = vendorIDsMappedToLocationIDs.size;

      for (const [locationID, vendorIDs] of vendorIDsMappedToLocationIDs) {
         const location = this.locationsIndex[locationID];
         const nodes = vendorIDs
            .map((vendorID) => this.nodeInfoMappedToUniqueID.get(`vendor-${vendorID}`))
            .filter((node) => node !== undefined) as Array<HierarchyNode>;

         const nodeInfo: nodeInfo = {
            title: location.locationName,
            uniqueID: `location-${location.locationID}`,
            locationID: location.locationID,
            icon: "houseChimney",
            collapsed: !this.searchBar?.length,
            selected: false,
            nodes: nodes,
            unselectable: true,
         };
         this.nodeInfoMappedToUniqueID.set(nodeInfo.uniqueID, nodeInfo);
      }

      for (const index in regionsIndex) {
         const region = regionsIndex[index];

         const nodeInfo: nodeInfo = {
            icon: "earthAmericas",
            uniqueID: `region-${region.regionID}`,
            collapsed: !this.searchBar?.length,
            selected: false,
            nodes: [],
            title: region.regionName,
            unselectable: true,
            regionID: region.regionID,
         };
         this.nodeInfoMappedToUniqueID.set(nodeInfo.uniqueID, nodeInfo);
      }

      for (const index in regionsIndex) {
         const region = regionsIndex[index];

         const nodes = region.nodes
            .filter((node) => {
               if (node.region) {
                  return true;
               }
               return Array.from(vendorIDsMappedToLocationIDs.keys()).includes(
                  node.locationID,
               );
            })
            .map((node) => {
               if (node.region) {
                  return this.nodeInfoMappedToUniqueID.get(`region-${node.regionID}`);
               }
               return this.nodeInfoMappedToUniqueID.get(`location-${node.locationID}`);
            });

         const nodeRegion = this.nodeInfoMappedToUniqueID.get(
            `region-${region.regionID}`,
         );
         assert(nodeRegion);
         nodeRegion.nodes = nodes;
      }

      this.data = []; //clear out old data
      if (this.locLength > 1) {
         if (
            this.manageLocation.getRegions().length > 0 &&
            Object.keys(regionsIndex).length > 0
         ) {
            for (const region of regions) {
               if (region.nodes?.length > 0) {
                  const displayRegion = this.nodeInfoMappedToUniqueID.get(
                     `region-${region.regionID}`,
                  );

                  assert(displayRegion);
                  this.data.push(displayRegion);
               }
            }
         } else {
            for (const locationID of vendorIDsMappedToLocationIDs.keys()) {
               const displayLocation = this.nodeInfoMappedToUniqueID.get(
                  `location-${locationID}`,
               );
               assert(displayLocation);
               this.data.push(displayLocation);
            }
         }
      } else {
         for (const vendorID of this.vendors.keys()) {
            const displayVendor = this.nodeInfoMappedToUniqueID.get(`vendor-${vendorID}`);
            assert(displayVendor);
            this.data.push(displayVendor);
         }
      }

      // Sort this.data (location) by title.
      this.data.sort((elemA, elemB) => elemA.title.localeCompare(elemB.title));

      this.vendorsLength = this.vendors.size;
   }

   collapseAll = () => {
      this.hierarchy?.collapseAllNodes();
   };

   clearVendorSelection = () => {
      this.hierarchy?.deselectAllNodes();
   };

   markAllVendorSelection = () => {
      this.hierarchy?.selectAllNodes();
   };

   public async addVendor() {
      if (!this.canAddVendors) {
         return;
      }
      const instance = this.modalService.open(PickNewVendorType);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AddAnVendorMsg,
            title: this.lang().AddAnVendor,
         },
      };

      const result = await instance.result;
      if (result === 0) {
         return;
      }

      this.alertService.addAlert(
         this.lang().StartingProcessThisCouldTakeALittleBit,
         "success",
         2000,
      );
      let answer;
      if (result.selection === 1) {
         answer = await this.manageVendor.addVendor(result.locationID, 1, result.newName);
      } else if (result.selection === 2) {
         answer = await this.manageVendor.copyVendor(
            result.vendor.vendorID,
            result.locationID,
            result.newName,
         );
      }
      const newVendor = answer.data.vendor;
      this.alertService.clearAllAlerts();

      if (newVendor.vendorID === undefined || !answer.data.success) {
         if (answer.data.errors) {
            for (const key in answer.data.errors) {
               this.alertService.addAlert(`${answer.data.errors[key]}`, "danger", 30000);
            }
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
         return;
      }

      this.alertService.addAlert(this.lang().VendorSuccessfullyAdded, "success", 2000);

      if (this.selectOne === true) {
         this.modalInstance.close([newVendor.vendorID]);
         return;
      }
      this.searchBar = "";
      this.updateSearchBar();
   }

   iDontKnow = () => {
      this.clearVendorSelection();
      if (this.unsure === true) {
         this.submit();
      }
      this.unsure = true;
   };

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      this.errorMsg = false;

      if (this.iDontKnowOption === true) {
         if (this.unsure === true) {
            this.modalInstance.close("unsure"); //they aren't sure so close it out that way.
            return;
         }
      }

      const vendorDataToSubmit = Array.from(this.nodeInfoMappedToUniqueID.values())
         .filter((node) => node.selected && node.vendorID)
         .map((node) => node.vendorID);

      if (vendorDataToSubmit.length === 0) {
         this.errorMsg = this.lang().PleasePickAtLeast1Vendor;
         this.alertService.addAlert(this.errorMsg, "warning", 3000);
         return;
      }

      if (this.popSelectionOnly === true && this.selectOne === true) {
         const vendorID = vendorDataToSubmit[0] ?? 0;
         this.runPopVendor(this.manageVendor.getVendor(vendorID));
         return;
      }

      this.modalInstance.close(vendorDataToSubmit);
   };

   public runPopVendor(vendorNode) {
      const vendor = this.manageVendor.getVendor(vendorNode.vendorID);
      if (!vendor) {
         return;
      }
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }
}
