import { Component, computed, input } from "@angular/core";
import type { Part } from "src/app/parts/types/part.types";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   selector: "report-task-quantity-cell",
   templateUrl: "./report-task-quantity-cell.component.html",
   styleUrls: ["./report-task-quantity-cell.component.scss"],
   imports: [],
})
export class ReportTaskQuantityCellComponent {
   public task = input.required<TaskDataViewerViewModel>();
   public part = input.required<Part>();

   protected readonly qtyUsed = computed(() => {
      const usedPartsForTask =
         this.task().parts?.filter((part) => {
            return part.partID === this.part().partID;
         }) ?? [];

      return usedPartsForTask.reduce((acc, part) => {
         return acc + (part?.usedNumber ?? 0);
      }, 0);
   });
}
