import { CommonModule } from "@angular/common";
import { Component, computed, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import { IconComponent, LimbleHtmlDirective, ModalService } from "@limblecmms/lim-ui";
import { map, take } from "rxjs";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ParamsService } from "src/app/shared/services/params.service";
import { AssetName } from "src/app/tasks/components/taskItemElement/assetName.pipe";

@Component({
   selector: "asset-name-cell-renderer-component",
   imports: [
      CommonModule,
      AssetParentList,
      AssetName,
      IconComponent,
      LimbleHtmlDirective,
   ],
   templateUrl: "./asset-name-cell-renderer.component.html",
   styleUrls: ["./asset-name-cell-renderer.component.scss"],
})
export class AssetNameCellRendererComponent extends BaseCellRendererComponent {
   private readonly manageAsset = inject(ManageAsset);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);

   private readonly assetData = computed(
      () =>
         this.cell as {
            assetName: number;
         },
   );

   protected readonly assetID = computed(() => {
      return this.assetData()?.assetName ?? 0;
   });

   protected readonly assetsLoaded = toSignal(
      this.manageAsset.assetsLoaded().pipe(
         take(1),
         map(() => true),
      ),
      { initialValue: false },
   );

   private readonly asset = computed(() => {
      if (!this.assetsLoaded()) {
         return undefined;
      }
      return this.manageAsset.getAsset(this.assetID());
   });

   public onAssetClick(event: Event): void {
      event.stopPropagation();

      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: this.assetID(),
            locationID: this.asset()?.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }
}
