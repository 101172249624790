// This service detects window resize events and emits a boolean value
// indicating whether the new height of the window (or visual viewport) is
// limited based on a ratio of the previous height. It is implemented as
// an Angular singleton service and provides a public method to retrieve
// the Observable that emits the boolean value.

import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { BehaviorSubject, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Injectable({
   providedIn: "root",
})
export class WindowService {
   private readonly isLimitedHeight$: Observable<boolean>;
   private previousHeight: number = window.innerHeight;

   public constructor() {
      const limitedHeight$ = new BehaviorSubject(false);
      this.isLimitedHeight$ = limitedHeight$.asObservable();

      if ("visualViewport" in window && window.visualViewport) {
         this.previousHeight = window.visualViewport.height;

         // Debounce the resize event to prevent multiple emissions in quick succession
         const resize$ = fromEvent(window.visualViewport, "resize").pipe(
            debounceTime(300),
         );

         resize$.subscribe((event: Event) => {
            limitedHeight$.next(this.viewportHandler(event));
            this.previousHeight = window.visualViewport
               ? window.visualViewport.height
               : window.innerHeight;
         });
      }
   }

   // Helper method to determine whether the new height is limited
   private viewportHandler(event: Event): boolean {
      // Ratio of the new height to the previous height that we consider to be "limited".
      const NEW_HEIGHT_VS_PREVIOUS_RATIO = 0.7;

      if (event.target instanceof VisualViewport) {
         const scale = event.target.scale;
         const newHeight = event.target.height;
         if ((newHeight * scale) / this.previousHeight < NEW_HEIGHT_VS_PREVIOUS_RATIO)
            return true;
      }
      return false;
   }

   public getIsLimitedHeight$(): Observable<boolean> {
      return this.isLimitedHeight$;
   }
}
