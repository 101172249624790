import { inject, Injectable, type Signal } from "@angular/core";
import type { Observable } from "rxjs";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import {
   type FlagMap,
   LaunchFlagKeyToDefaultValueMap,
   type FlagKey,
} from "src/app/shared/services/launch-flags/read/launch-flag-key-to-default-value-map";

/**
 * Service for retrieving feature flag values from LaunchDarkly.
 * Note: This service currently only supports LaunchDarkly flags, not COGS flags.
 */
@Injectable({ providedIn: "root" })
export class ReadLaunchFlagService {
   private readonly launchFlagsService = inject(LaunchFlagsService);

   /**
    * Gets the value of a LaunchDarkly flag as a signal
    * @param key The key of the launch flag to check
    * @returns Signal containing the flag value of the type specified in LaunchFlagKeyToDefaultValueMap
    */
   public getFlag<Key extends FlagKey>(key: Key): Signal<FlagMap[Key]> {
      return this.launchFlagsService.getFlag(key, LaunchFlagKeyToDefaultValueMap[key]);
   }

   /**
    * Gets the value of a LaunchDarkly flag as an Observable
    * @param key The key of the launch flag to check
    * @returns Observable containing the flag value of the type specified in LaunchFlagKeyToDefaultValueMap
    */
   public getFlag$<Key extends FlagKey>(key: Key): Observable<FlagMap[Key]> {
      return this.launchFlagsService.getFlagObservable(
         key,
         LaunchFlagKeyToDefaultValueMap[key],
      );
   }
}
