<ng-container *translate="let t">
   @if (cell?.timeSpentSecs !== undefined) {
      <div>
         {{ timeSpentHours | betterNumber: "1.2-2" }}
         @if (cell.timeSpentSecs === 3600) {
            <span>{{ t("hr") }}</span>
         }
         @if (cell.timeSpentSecs !== 3600) {
            <span>{{ t("hrs") }}</span>
         }
      </div>
   }
</ng-container>
