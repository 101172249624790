// component for viewing non image files
// files need a getURL, fileName
// permission to delete the file
// delete => reference to the delete function

//containing element with ng-repeat should have
// classes: list-group-item cursor

import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { IconComponent, TooltipDirective } from "@limblecmms/lim-ui";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "view-file",
   templateUrl: "./viewFile.element.component.html",
   styleUrls: ["./viewFile.element.component.scss"],
   imports: [FileListItem, IconComponent, TooltipDirective],
})
export class ViewFile implements OnInit {
   @Input() public file;
   @Input() public permission;
   @Input() public delete;
   @Input() public getURL;
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      //init goes here
   }
}
