import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed, signal } from "@angular/core";
import {
   IconComponent,
   InputWithPrefixComponent,
   ModalService,
   TooltipDirective,
   MinimalIconButtonComponent,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { NgxSkeletonLoaderComponent } from "ngx-skeleton-loader";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { PurchasableSnapshotService } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { Flags } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { CalculatedPartInfo } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { SkeletonLoaderThemes } from "src/app/shared/utils/skeleton-loader-themes";
import { CredService } from "src/app/users/services/creds/cred.service";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

@Component({
   selector: "part-price-field",
   templateUrl: "./partPrice.element.component.html",
   styleUrls: ["../shared-field-styles.scss"],
   imports: [
      NgClass,
      InputWithPrefixComponent,
      TooltipDirective,
      MinimalIconButtonComponent,
      IconComponent,
      BetterDecimalPipe,
      PartUnitOfMeasurePipe,
      LocaleCurrencyPipe,
      NgxSkeletonLoaderComponent,
      CurrencySymbolPipe,
   ],
})
export class PartPrice implements OnInit {
   @Input() public partID: number | undefined;
   @Input() public usedIn: "table" | "listItem" | undefined = undefined;

   public part: Part | undefined;
   public credChangePartPrice;
   public oldValue;
   public showPricingDetails: boolean = false;
   public extraBatchMap = {};
   public calculatedPartInfo: CalculatedPartInfo | undefined;
   public poItemsMap = {};
   public purchaseOrdersMap = {};
   public batchIDPurchaseOrderNumberLookup: LimbleMap<number, string> = new LimbleMap();
   protected canSeeUnitOfMeasure: boolean | null = null;

   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly managePO = inject(ManagePO);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly purchasableSnapshotService = inject(PurchasableSnapshotService);
   private readonly launchFlagService = inject(LaunchFlagsService);
   private readonly featureFlagService = inject(FeatureFlagService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly i18n = inject(TranslationService).i18n;

   private readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly isLimbleSearchEnabled = this.launchFlagService.getFlag<boolean>(
      Flags.PART_INJECT_LIMBLE_SEARCH,
      false,
   );

   protected readonly isLimbleSearchFeatureEnabled = computed(() =>
      this.featureFlagService.featureSet()?.has("limbleSearch"),
   );

   private readonly locationID = signal<number | undefined>(undefined);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly accountCurrencySettingsQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQueries = inject(LocationQueriesService);
   private readonly locationCurrencyQuery = injectQuery(() =>
      this.locationQueries.detail(this.locationID()),
   );
   protected readonly isCurrencyPending = computed(() => {
      return (
         this.locationCurrencyQuery.isPending() ||
         this.accountCurrencySettingsQuery.isPending()
      );
   });
   private readonly accountCurrencyCode = computed(
      () => this.accountCurrencySettingsQuery.data()?.currencyCode,
   );
   private readonly locationCurrencyCode = computed(
      () => this.locationCurrencyQuery.data()?.currencyCode,
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      this.accountCurrencyCode,
      this.locationCurrencyCode,
      this.isMultiCurrencyEnabled,
   );
   protected readonly CurrencySkeletonTheme = SkeletonLoaderThemes.fill;

   public ngOnInit(): void {
      assert(this.partID);
      this.part = this.manageParts.getPart(this.partID);
      assert(this.part);
      this.locationID.set(this.part.locationID);

      this.part?.partExtraBatchIDs.forEach((batchID) => {
         const batch = this.manageParts.getExtraBatch(batchID);
         if (!batch) return;
         const item = this.managePO.getPurchaseOrderItem(batch.poItemID);
         if (item === undefined) return;
         const purchasableSnapshot = computed(() =>
            this.purchasableSnapshotService.parseFromPurchaseOrderItem(item),
         );
         this.extraBatchMap[batchID] = { ...batch, purchasableSnapshot };
         const purchaseOrderItem = this.managePO.getPurchaseOrderItem(batch.poItemID);
         if (!purchaseOrderItem) return;
         this.poItemsMap[batch.poItemID] = purchaseOrderItem;
         if (!purchaseOrderItem.poID) return;
         this.purchaseOrdersMap[purchaseOrderItem.poID] = this.managePO.getPurchaseOrder(
            purchaseOrderItem.poID,
         );
      });

      this.calculatedPartInfo = this.manageParts.getSingleCalculatedPartInfo(this.partID);

      this.credChangePartPrice = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ChangePartPrice,
      );

      this.oldValue = this.part.partPrice;
      this.canSeeUnitOfMeasure = this.unitOfMeasureService.isFeatureEnabled();
   }

   protected togglePricingDetails(): void {
      this.showPricingDetails = !this.showPricingDetails;
   }

   public async updatePartPrice(
      newPrice?: string | number | null | undefined,
   ): Promise<void> {
      assert(this.part);
      if (newPrice && typeof newPrice === "string") {
         this.part.partPrice = parseFloat(newPrice);
      } else if (newPrice && typeof newPrice === "number") {
         this.part.partPrice = newPrice;
      }
      if (this.oldValue === this.part.partPrice) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartPrice,
         )
      ) {
         this.alertService.addAlert(this.lang().cred73Fail, "danger", 10000);
         return;
      }

      const answer = await this.manageParts.updatePartPrice(this.part.partID);
      if (answer?.data.success === true) {
         this.oldValue = this.part.partPrice;
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   public popPoComponent(poItemID: number): void {
      const getPurchaseOrderItemResponse = this.managePO.getPurchaseOrderItem(poItemID);
      if (!getPurchaseOrderItemResponse) return;
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: getPurchaseOrderItemResponse.poID },
         },
      };
   }

   protected onComparePricesClick(part: Part): void {
      const baseURL = "https://search.limble.com/results";
      const url = new URL(baseURL);
      const partNumber = part.partNumber ? `${part.partNumber} ` : "";
      const query = `${partNumber}${part.partName}`;
      url.searchParams.set("q", query);
      url.searchParams.set("utm_source", "cmms");
      url.searchParams.set("search_init_type", "cmms-manage-parts");

      const limbleSearchURL = url.toString();

      window.open(limbleSearchURL, "_blank");
   }
}
