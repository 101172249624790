import { signal } from "@angular/core";
import type { CustomUnitParams } from "src/app/parts/unit-of-measure/custom-units/custom-unit.params";
import { UnitBase } from "src/app/parts/unit-of-measure/unit.model";
import { UnitCategories } from "src/app/parts/unit-of-measure/unit.types";

export class CustomUnit extends UnitBase<true> {
   override readonly isMeasurementUnit = false;

   public constructor({
      singularInitialValue,
      shortInitialValue,
      ...rest
   }: CustomUnitParams) {
      super({
         ...rest,
         singular: signal(singularInitialValue),
         short: signal(shortInitialValue),
         category: UnitCategories.Count,
         conversionFactorToSIUnit: 1,
      });
   }
}
