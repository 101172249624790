import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import {
   FlannelApiService,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type {
   CycleCountScheduleEntity,
   ScheduleEntity,
   ScheduleEntityFilters,
} from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";

@Injectable({
   providedIn: "root",
})
export class SchedulesApiService extends FlannelApiService<
   ScheduleEntity,
   ScheduleEntityFilters
> {
   private constructor() {
      super("schedules");
   }

   public override async *getStreamedList(
      requestOptions?: Partial<RequestOptions<ScheduleEntityFilters>>,
   ): AsyncGenerator<ScheduleEntity | CycleCountScheduleEntity, void> {
      if (requestOptions?.filters?.scheduleIDs === undefined) {
         for await (const schedule of super.getStreamedList(requestOptions)) {
            yield schedule;
         }
         return;
      }

      const scheduleIDs = requestOptions.filters.scheduleIDs;
      const limit = 100;

      for (let startIdx = 0; startIdx < scheduleIDs.length; startIdx += limit) {
         const currentBatch = super.getStreamedList({
            ...requestOptions,
            filters: {
               ...requestOptions.filters,
               scheduleIDs: scheduleIDs.slice(startIdx, startIdx + limit),
            },
         });
         // eslint-disable-next-line no-await-in-loop -- This is a generator, Promise.all doesn't make sense.
         for await (const schedule of currentBatch) {
            yield schedule;
         }
      }
   }

   public async getAll(
      requestOptions?: Partial<RequestOptions<ScheduleEntityFilters>>,
   ): Promise<Array<ScheduleEntity>> {
      const schedules: ScheduleEntity[] = [];
      for await (const schedule of this.getStreamedList(requestOptions)) {
         schedules.push(schedule);
      }
      return schedules;
   }

   /*  eslint-disable typescript/no-unused-vars -- The variables are needed to override */
   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override create(
      body: Partial<ScheduleEntity>,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<ScheduleEntity | null> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override update(
      id: number,
      body: Partial<ScheduleEntity>,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<ScheduleEntity> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override delete(
      id: number,
      requestOptions?: Partial<RequestOptions>,
   ): Observable<ScheduleEntity> {
      throw new Error("Method not implemented.");
   }

   /*  eslint-enable typescript/no-unused-vars  */
}
