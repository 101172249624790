import { inject, Injectable } from "@angular/core";
import { SortingService } from "src/app/shared/components/global/mention/sorting.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({ providedIn: "root" })
export class MentionService {
   private readonly manageUser = inject(ManageUser);
   private readonly manageUtil = inject(ManageUtil);
   private readonly sortingService = inject(SortingService);

   public createListOfMentionsToNotify(
      listOfSelectedMentions: Array<any>,
      listOfSelectableMentions: Array<any>,
      userProfiles: Array<any>,
   ): Array<any> {
      let listOfMentionsToNotify: Array<any> = [];
      listOfSelectedMentions.forEach((mentionedItem: any) => {
         if (!mentionedItem.profileDescription || mentionedItem.tagDescription) {
            listOfMentionsToNotify = this.addItemToListOfMentions(
               listOfMentionsToNotify,
               mentionedItem,
            );
         }

         if (mentionedItem.profileID) {
            listOfMentionsToNotify = this.addUsersFromUserProfileToListToNotify(
               mentionedItem,
               listOfSelectableMentions,
               userProfiles,
               listOfMentionsToNotify,
            );
         }
      });

      return listOfMentionsToNotify;
   }

   private addUsersFromUserProfileToListToNotify(
      selectedProfile: any,
      listOfSelectableMentions: Array<any>,
      userProfiles: Array<any>,
      listOfMentionsToNotify: Array<any>,
   ): Array<any> {
      let listOfMentionsWithUsersFromProfile: Array<any> = listOfMentionsToNotify;

      const listOfUsersFromSelectedProfile: Array<any> =
         this.getUsersPertainingToSelectedProfile(
            selectedProfile,
            listOfSelectableMentions,
            userProfiles,
         );

      listOfUsersFromSelectedProfile.forEach((userFromSelectedProfile) => {
         listOfMentionsWithUsersFromProfile = this.addItemToListOfMentions(
            listOfMentionsToNotify,
            userFromSelectedProfile,
         );
      });

      return listOfMentionsWithUsersFromProfile;
   }

   private addItemToListOfMentions(
      listOfMentionsToNotify: Array<any>,
      selectedItem: any,
   ): Array<any> {
      if (!listOfMentionsToNotify.includes(selectedItem)) {
         listOfMentionsToNotify.push(selectedItem);
      }

      return listOfMentionsToNotify;
   }

   private getUsersPertainingToSelectedProfile(
      selectedProfile: any,
      listOfSelectableMentions: Array<any>,
      userProfiles: Array<any>,
   ): Array<any> {
      const listOfUsersFromSelectedProfile: Array<any> = [];

      userProfiles.forEach((userProfile) => {
         if (userProfile.profileID === selectedProfile.profileID) {
            listOfSelectableMentions.forEach((userOrProfile) => {
               if (userOrProfile.userID === userProfile.userID) {
                  listOfUsersFromSelectedProfile.push(userOrProfile);
               }
            });
         }
      });

      return listOfUsersFromSelectedProfile;
   }

   /**
    * Gets a list of users, teams, and tags that can be mentioned in a comment.
    *
    * @param locationID The location ID to get the list of users, teams, and tags for.
    * @returns A list of users, teams, and tags that can be mentioned in a comment.
    * @remarks There is not a lot of strong typing here. Adding some good strong typing
    *     for mentionable entities would be a good idea in the future.
    */
   public getMentionListForLocation(locationID: number): any[] {
      const result = this.manageUser.getUsersAndProfilesAtLocation(locationID);

      const usersAtLocationData = result.data;

      let teamsWithMembers = usersAtLocationData.profiles.filter((profile) =>
         usersAtLocationData.userProfiles.some(
            (profileCheck) => profileCheck.profileID === profile.profileID,
         ),
      );
      teamsWithMembers = this.sortingService.alphabeticalSort(
         teamsWithMembers,
         "profileDescription",
      );
      const tags = this.manageUser.getCurrentUser().tags.split("@");
      const customTags: any = [];
      for (const key in tags) {
         if (tags[key].length > 0) {
            const obj: any = {};
            obj.name = `@${tags[key]}`;
            obj.tagDescription = tags[key];
            obj.itemID = this.manageUtil.getRandomString();
            customTags.push(obj);
         }
      }
      const tagsList = this.sortingService.alphabeticalSort(customTags, "tagDescription");

      let mentionList: any = this.sortingService.alphabeticalSort(
         usersAtLocationData.users,
         "userFirstName",
      );
      mentionList = mentionList.concat(teamsWithMembers);
      mentionList = mentionList.concat(tagsList);
      return mentionList;
   }
}
