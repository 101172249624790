import { CommonModule } from "@angular/common";
import {
   ChangeDetectionStrategy,
   Component,
   computed,
   forwardRef,
   inject,
   input,
   output,
   type OnInit,
} from "@angular/core";
import {
   isMobile,
   LimbleHtmlDirective,
   PopoverDirective,
   RowHoverButtonsComponent,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ReportTaskNameCellComponent } from "src/app/parts/components/part-modal/components/cell-renderers/report-task-name-cell/report-task-name-cell.component";
import { ReportTaskQuantityCellComponent } from "src/app/parts/components/part-modal/components/cell-renderers/report-task-quantity-cell/report-task-quantity-cell.component";
import type { Part } from "src/app/parts/types/part.types";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { AlertService } from "src/app/shared/services/alert.service";
import {
   TaskAssignedToCellComponent,
   TaskCompletedByCellComponent,
   TaskCompletedOnCellComponent,
   TaskDowntimeCellComponent,
   TaskDueDateCellComponent,
   TaskEstimatedTimeCellComponent,
   TaskInvoicesCostCellComponent,
   TaskLaborCostCellComponent,
   TaskLatestActivityCellComponent,
   TaskNameCellComponent,
   TaskPartsCostCellComponent,
   TasksSchedulesCombinedNameCellComponent,
   TaskStatusColorCellComponent,
   TaskTimeSpentCellComponent,
   TaskTotalCostCellComponent,
   TaskTypeCellComponent,
} from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers";
import { AverageDowntimeCostCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/average-downtime-cost-cell/average-downtime-cost-cell.component";
import { CompletionNotesCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/completion-notes-cell/completion-notes-cell.component";
import { DowntimeEfficiencyRateCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/downtime-efficiency-rate-cell/downtime-efficiency-rate-cell.component";
import { DowntimeEfficiencySavingsCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/downtime-efficiency-savings-cell/downtime-efficiency-savings-cell.component";
import { LaborCostEfficiencyRateCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/labor-cost-efficiency-rate-cell/labor-cost-efficiency-rate-cell.component";
import { LaborCostEfficiencySavingsCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/labor-cost-efficiency-savings-cell/labor-cost-efficiency-savings-cell.component";
import { LocationCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/location-cell/location-cell.component";
import { PartCostEfficiencyRateCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/part-cost-efficiency-rate-cell/part-cost-efficiency-rate-cell.component";
import { PartCostEfficiencySavingsCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/part-cost-efficiency-savings-cell/part-cost-efficiency-savings-cell.component";
import { TaskAssetCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/task-asset-cell/task-asset-cell.component";
import { TaskIdCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/task-id-cell/task-id-cell.component";
import { TaskLaborCostAndTimeCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/task-labor-cost-and-time-cell/task-labor-cost-and-time-cell.component";
import { TaskMinimalNameCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/task-minimal-name-cell/task-minimal-name-cell.component";
import { TaskNameWithoutAssetCellComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/cell-renderers/task-name-without-asset-cell/task-name-without-asset-cell.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { TaskSimultaneousUsersComponent } from "src/app/tasks/components/taskSimultaneousUsers/taskSimultaneousUsers.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { Task } from "src/app/tasks/types/task.types";
import { CalendarFacadeService } from "src/app/tasks-analytics/calendar/task-calendar/calendar-facade.service";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "task-data-viewer-item",
   templateUrl: "./task-data-viewer-item.component.html",
   styleUrls: ["./task-data-viewer-item.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      CommonModule,
      LimbleHtmlDirective,
      forwardRef(() => TaskSimultaneousUsersComponent),
      RowHoverButtonsComponent,
      PopoverDirective,
      UpsellPopover,
      TaskNameCellComponent,
      TaskDueDateCellComponent,
      TaskCompletedOnCellComponent,
      TaskTimeSpentCellComponent,
      TaskCompletedByCellComponent,
      TaskSimultaneousUsersComponent,
      TaskAssignedToCellComponent,
      ReportTaskNameCellComponent,
      ReportTaskQuantityCellComponent,
      TaskPartsCostCellComponent,
      TaskInvoicesCostCellComponent,
      TaskLaborCostCellComponent,
      TaskLaborCostAndTimeCellComponent,
      TaskTotalCostCellComponent,
      TaskDowntimeCellComponent,
      TaskTypeCellComponent,
      TaskStatusColorCellComponent,
      TaskEstimatedTimeCellComponent,
      TasksSchedulesCombinedNameCellComponent,
      TaskLatestActivityCellComponent,
      TaskMinimalNameCellComponent,
      LocationCellComponent,
      CompletionNotesCellComponent,
      DowntimeEfficiencyRateCellComponent,
      PartCostEfficiencyRateCellComponent,
      LaborCostEfficiencyRateCellComponent,
      AverageDowntimeCostCellComponent,
      DowntimeEfficiencySavingsCellComponent,
      LaborCostEfficiencySavingsCellComponent,
      PartCostEfficiencySavingsCellComponent,
      TaskNameWithoutAssetCellComponent,
      TaskIdCellComponent,
      TaskAssetCellComponent,
   ],
})
export class TaskDataViewerItemComponent implements OnInit {
   // injects
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   public readonly manageAsset = inject(ManageAsset);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   protected readonly facade = inject(TasksFacadeService);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly manageLang = inject(ManageLang);
   protected readonly calendarFacade = inject(CalendarFacadeService);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   // inputs
   public task = input.required<TaskDataViewerViewModel>();
   public columns = input.required<Array<Column>>();
   public part = input<Part>();
   public globalSearch = input<boolean>();
   public searchHint = input<string>();

   public readonly tasksUpdated = output();

   // computed
   public areActionButtonsVisible = computed(
      () =>
         !this.task().isCompleted &&
         this.facade.isAllowedToDelete(this.task()) &&
         this.globalSearch(),
   );
   // props
   public customerID;
   public assign;
   public allUsers;
   protected addPartPopoverCheck: boolean = false;
   protected woInstructionLimit: number = 2;
   private readonly isMobile: boolean;

   public constructor() {
      this.isMobile = isMobile();
   }

   public ngOnInit() {
      this.assign = this.credService.isAuthorized(
         this.task().locationID,
         this.credService.Permissions.ChangeAssignmentsOfOpenTasks,
      );

      this.allUsers = this.manageTask.getAllUsers();

      // TODO:AP This needs to be moved to the mapper
      this.taskTypeService.getTaskTypeIcon(this.task() as unknown as Task);
   }

   public changeAssignment(): void {
      this.facade.changeAssignment(this.task());
   }

   public emailReminder(task: TaskDataViewerViewModel): void {
      this.facade.emailReminder(task);
   }

   public deleteTask(): void {
      this.facade.deleteTask(this.task());
   }

   public deferTask(): void {
      this.facade.deferTask(this.task());
   }

   public changeDueDate(): void {
      this.facade.changeDueDate(this.task());
   }

   public completedTaskWarning(): void {
      this.alertService.addAlert(this.lang().completedTaskWarning, "warning", 10000);
   }

   public viewTask(): void {
      const scheduleID = this.task().scheduleID;
      if (scheduleID) {
         this.calendarFacade.openFutureTaskModal(this.task().checklistID, scheduleID);
      } else {
         this.facade.viewTask(this.task().checklistID);
      }
   }

   public async duplicate($event: any) {
      await this.facade.duplicate(this.task(), $event);
      this.tasksUpdated.emit();
   }
}
