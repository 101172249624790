<div class="lim-ui-list-item lim-ui-draggable-list-item">
   <div class="lim-ui-list-item-content">
      <div class="front-list-item-data">
         <div class="list-section">
            @if (treeBranch) {
               <div [limbleTreeDraggable]="treeBranch" class="drag-icon-section">
                  <lim-ui-icon
                     class="drag-icon"
                     icon="arrowsUpDownLeftRightRegular"
                     iconSize="small"
                  />
                  <span class="status-order">{{ status.sortOrder }}</span>
               </div>
            }

            <div
               class="status-name column-area"
               (afterEdit)="updateSingleStatus(status)"
               limbleContentEditable
               [(ngModel)]="status.name"
               attr.placeholder="{{ lang().Name }}"
               stripHtml="true"
               tabindex="{{ status.sortOrder }}"
            ></div>
         </div>

         <div class="list-section">
            <div
               class="status-description column-area"
               (afterEdit)="updateSingleStatus(status)"
               limbleContentEditable
               [(ngModel)]="status.description"
               attr.placeholder="{{ lang()?.Description }}"
               stripHtml="true"
               tabindex="{{ status.sortOrder }}"
            ></div>
         </div>
      </div>

      <lim-ui-minimal-icon-button
         iconColor="danger"
         [superMinimal]="true"
         icon="trashCanRegular"
         iconSize="small"
         (click)="deleteStatus(status)"
      />
   </div>
</div>
