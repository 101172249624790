import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit, Signal } from "@angular/core";
import { inject, Component, computed, input, signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   BadgeComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   IconComponent,
   LimUiModalRef,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   NumberInputWithButtonsComponent,
   PanelComponent,
   PopoverDirective,
   RadioButtonComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
   Subject,
   Subscription,
   combineLatest,
   debounceTime,
   finalize,
   from,
   switchMap,
   tap,
   toArray,
   type Observable,
   startWith,
} from "rxjs";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageTool } from "src/app/assets/services/manageTool";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { PickAssetDataLogType } from "src/app/assets/types/pick-assets.types";
import type { Field } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import { CommentFiles } from "src/app/files/components/commentFiles/commentFiles.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { FileStorageSyncService } from "src/app/lite/local-db/resources/collection/task/file/file.storage.sync.service";
import type { TaskEntityPart } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { OrderByPipe, orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ParamsService } from "src/app/shared/services/params.service";
import type { CheckOutRequest } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { ExtraTimeListComponent } from "src/app/tasks/components/extraTimeElement/extraTimeList.component";
import { LogTimeOnTask } from "src/app/tasks/components/logTimeOnTaskModal/logTimeOnTask.modal.component";
import {
   TaskTemplatesApiService,
   type TaskTemplateEntity,
} from "src/app/tasks/components/shared/services/task-templates-api";
import { TaskViewModelFactoryService } from "src/app/tasks/components/shared/services/task-view-model-factory/task-view-model-factory.service";
import {
   TasksApiService,
   type TaskEntity,
} from "src/app/tasks/components/shared/services/tasks-api";
import { TaskPartsAvailabilityService } from "src/app/tasks/components/task-form/task-parts-availability.service";
import { TaskTool } from "src/app/tasks/components/taskToolElement/taskTool.element.component";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskType, TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { TimerDuration } from "src/app/tasks/timer/timer-duration";
import { ExtraTimeDisplayModes } from "src/app/tasks/types/extra-time/extra-time.enum";
import type { TaskPartLegacy } from "src/app/tasks/types/part/task-part.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

const SHOULD_DEFAULT_WORK_ORDER_CATEGORY_CUSTOMERS = [1804];
export interface CompleteTaskSubmitData {
   hours: number;
   minutes: number;
   notesToTheRequestor: string | null;
   parts: Array<TaskPartLegacy | TaskEntityPart>;
   selectedCategory: number;
   multiWork: boolean;
   hoursDowntime?: number;
   minutesDowntime?: number;
}

/**
 * There is a section in the modal to display the numeric fields of a recurrence associated with the associated asset.
 * This interface defines the fields displayed in the template for those numeric fields.
 */
interface AssetNumericFieldDisplayInfo {
   reoccurID: number; // The recurrence associated with this field
   fieldValueID: number;
   field: Field;
   fieldValue: AssetFieldValue | undefined;
   tooltip: string;
   userWantsToUpdate: boolean;
}

@Component({
   selector: "complete-task",
   templateUrl: "./completeTask.modal.component.html",
   styleUrls: ["./completeTask.modal.component.scss"],
   providers: [TaskPartsAvailabilityService],
   imports: [
      BadgeComponent,
      BasicModalFooterComponent,
      BasicModalHeaderComponent,
      CommentFiles,
      ContenteditableDirective,
      DropdownTextItemComponent,
      ExtraTimeListComponent,
      FormDropdownInputComponent,
      FormsModule,
      IconComponent,
      LimbleHtmlDirective,
      ModalBodyComponent,
      ModalComponent,
      ModalDirective,
      NgClass,
      NumberInputWithButtonsComponent,
      OrderByPipe,
      PanelComponent,
      PopoverDirective,
      RadioButtonComponent,
      SecondaryButtonComponent,
      TaskTool,
      FormDropdownInputComponent,
      DropdownTextItemComponent,
      ContenteditableDirective,
      CommentFiles,
      BasicModalFooterComponent,
      OrderByPipe,
      NumberInputWithButtonsComponent,
      BadgeComponent,
      PopoverDirective,
      UpsellPopover,
      TooltipDirective,
      NgxSkeletonLoaderModule,
      PartUnitOfMeasurePipe,
   ],
})
export class CompleteTask implements OnInit, OnDestroy {
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly credService = inject(CredService);
   public readonly manageAsset = inject(ManageAsset);
   private readonly manageBilling = inject(ManageBilling);
   private readonly paramsService = inject(ParamsService);
   private readonly manageTool = inject(ManageTool);
   private readonly manageUser = inject(ManageUser);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly taskPartsAvailabilityService = inject(TaskPartsAvailabilityService);
   private readonly fileStorageSyncService = inject(FileStorageSyncService);
   private readonly manageLang = inject(ManageLang);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   public readonly modalRef: LimUiModalRef<CompleteTask, any> = inject(LimUiModalRef);
   private readonly taskTypeService: TaskTypeService = inject(TaskTypeService);

   public readonly checklistID = input.required<number>();

   protected readonly completionNotes = computed(() => {
      return this.task()?.checklistComments ?? undefined;
   });

   protected readonly parts = computed(() => {
      return this.task()?.parts ?? [];
   });

   public readonly timer = input.required<TimerDuration | undefined>();
   public readonly items = input.required<Array<Record<any, any>>>();

   private readonly tasksApiService = inject(TasksApiService);
   private readonly taskViewModelFactoryService = inject(TaskViewModelFactoryService);
   private readonly checklistID$ = toObservable(this.checklistID);
   private readonly tasksUpdated$ = new Subject<void>();

   private _taskFetchedOnce = false;
   public readonly taskRequest$ = combineLatest([
      this.checklistID$,
      this.tasksUpdated$.pipe(startWith(undefined)),
   ]).pipe(
      switchMap(([checklistID]) =>
         this.tasksApiService
            .getById(checklistID, {
               columns: "comments,invoices,extraTime,parts,partsDetails",
            })
            .pipe(
               tap((task) => {
                  if (!this._taskFetchedOnce) {
                     this._taskFetchedOnce = true;
                     this.initializeModalData(task);
                  }
               }),
            ),
      ),
   );
   protected readonly taskResponse = toSignal(this.taskRequest$);
   protected readonly task = computed(() => {
      const task = this.taskResponse();
      if (!task) return undefined;
      return this.taskViewModelFactoryService.getTaskDataViewerViewModel(task);
   });

   public readonly extraTimeListComponent!: ExtraTimeListComponent;

   public assignToAsset;

   protected readonly asset = computed<Asset | undefined>(() => {
      const assetID = this.assetID();
      return assetID ? this.manageAsset.getAsset(assetID) : undefined;
   });
   protected readonly customerID = this.manageUser.getCurrentUser().userInfo.customerID;
   protected readonly customerFIFOLIFO =
      this.manageUser.getCurrentUser().userInfo.customerFIFOLIFO;
   public fieldsToUpdate: Array<AssetNumericFieldDisplayInfo> = [];
   public recurrences;
   public showMinutes;
   public categories;
   public categoriesIndex;
   protected readonly notesToTheRequestor = signal<string>("");
   public selectedCategoryID = 0;
   public selectedCategoryName;
   public recordExtraTime;
   public differenceHours;
   public differenceMinutes;
   protected readonly userID = this.manageUser.getCurrentUser().gUserID;
   protected readonly editExtraTimeCred = computed(() => {
      const task = this.task();
      if (!task) return false;
      return this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.EditAndCompleteOpenTasks,
      );
   });
   public multiWork: boolean = false;
   public errorMsg;
   public viewingLengthHint;
   public hours: number = 0;
   public minutes: number = 0;
   public hoursDowntime: number = 0;
   public minutesDowntime: number = 0;
   public trackDowntime;
   public taskAssetIDOLD = signal(0);
   public files;
   public uploadObject;
   protected readonly hoursError = signal(false);
   protected readonly minutesError = signal(false);
   protected readonly assetFieldsError = signal(false);
   protected readonly unplannedDowntimeError = signal(false);
   protected readonly assignToAssetError = signal(false);
   protected readonly hoursErrorDowntime = signal(false);
   protected readonly minutesErrorDowntime = signal(false);
   protected readonly notesToRequestorError = signal(false);
   protected readonly laborCategoriesError = signal(false);
   protected readonly editTime = computed(() => {
      if (!this.timer()) {
         return true; //it always defaults to true.  We will only ever change it to false if the timer is being used AND they do not have the override task timer permission
      }
      const task = this.task();
      if (!task) return true;
      return this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.OverrideAutomaticTaskTimer,
      );
   });
   public tools: Array<{ mostRecentRequest: CheckOutRequest; tool }> | undefined;
   public assetCheckInOut: boolean | undefined;
   public featureAssetTools: boolean = false;
   public taskAsset: Asset | undefined;
   public featureDowntimeTracking: boolean = false;
   public featureDisableTimeLogging: boolean = false;
   protected featureTimeCost: boolean = false;
   protected readonly customerRequiresNotesToRequestor: number | undefined =
      this.manageUser.getCurrentUser().userInfo.customerRequireNotesToRequestor;
   private readonly manageFeatureFlagsSub: Subscription = new Subscription();
   protected featureLaborCategories: boolean = false;
   protected processing = signal<boolean>(false);

   protected readonly calculatedTaskInfo = computed(() => {
      return this.task()?.calculatedInfo;
   });
   public displayMode = ExtraTimeDisplayModes.ByUser;
   public readonly extraTimeDisplayModes = { ...ExtraTimeDisplayModes };
   protected partsUnderStocked: Set<number> = new Set();
   protected readonly preventTaskCompletionInsufficientParts: boolean = false;

   private readonly partQuantityUpdates$ = new Subject<{
      part: TaskPartLegacy | TaskEntityPart;
      quantity: number;
   }>();
   private readonly partQuantitySubscription: Subscription;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly assetID = computed(() => {
      const task = this.task();
      return task?.assetID;
   });

   protected readonly shouldShowDowntimeSection = computed(() => {
      const currentTask = this.task();
      if (!currentTask) return false;

      const taskType = this.taskTypeService.getType(currentTask);

      return (
         taskType === TaskType.UnplannedWorkOrder ||
         taskType === TaskType.PlannedWorkOrder ||
         taskType === TaskType.WorkRequest
      );
   });

   protected readonly shouldShowAssignToAssetSection = computed(() => {
      const currentTask = this.task();
      if (!currentTask) return false;

      const taskAssetIDOLD = this.taskAssetIDOLD();

      const taskType = this.taskTypeService.getType(currentTask);

      if (taskType === TaskType.PartThreshold) {
         return false;
      }

      return (
         taskAssetIDOLD === 0 &&
         (taskType === TaskType.UnplannedWorkOrder ||
            taskType === TaskType.PlannedWorkOrder ||
            taskType === TaskType.WorkRequest)
      );
   });

   protected readonly assetID$ = toObservable(this.assetID);

   public loading = signal<boolean>(true);

   private readonly associatedTemplatesRequest$ = this.assetID$.pipe(
      switchMap((assignedAssetID) => {
         if (assignedAssetID === null || assignedAssetID === undefined) {
            this.loading.set(false);
            return [];
         }
         return this.getAssociatedTemplates(assignedAssetID).pipe(
            finalize(() => {
               this.loading.set(false);
            }),
         );
      }),
   );

   protected readonly associatedTemplates = toSignal(this.associatedTemplatesRequest$);

   private readonly mapOfAssociatedTemplateNames: Map<number, string[]> = new Map();

   protected readonly assetNumericFieldsDisplayInfo: Signal<
      AssetNumericFieldDisplayInfo[]
   > = computed(() => {
      if (!this.asset()) {
         return [];
      }
      return this.getAssetNumericFieldsDisplayInfo();
   });

   protected taskHasExtraTimes = computed(() => {
      const task = this.task();
      if (!task) return false;
      return (task.extraTime?.length ?? 0) > 0;
   });

   protected readonly taskExtraTimeVM = computed(() => {
      const task = this.task();
      if (!task) return undefined;
      return {
         extraTime: task.extraTime ?? [],
         checklistPromptTime: task.checklistPromptTime,
         checklistCompletedDate: task.checklistCompletedDate,
         billableTime: task.billableTime,
         locationID: task.locationID,
         createdByUserID: task.createdByUserID,
         checklistID: task.checklistID,
      };
   });

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureTimeCost = isFeatureEnabledMap.featureTimeCost;
            this.featureDowntimeTracking = isFeatureEnabledMap.featureDowntimeTracking;
            this.featureAssetTools = isFeatureEnabledMap.featureAssetTools;
            this.featureLaborCategories = isFeatureEnabledMap.featureLaborCategories;
            this.featureDisableTimeLogging =
               isFeatureEnabledMap.featureDisableTimeLogging;
         },
      );
      this.partQuantitySubscription = this.partQuantityUpdates$
         .pipe(
            tap(({ part, quantity }) => {
               part.suggestedNumber = quantity;
               this.setPartsUnderStocked();
            }),
            debounceTime(500),
            switchMap(async ({ part }) =>
               this.manageTask.updatePartSuggestedNumber(part),
            ),
         )
         .subscribe((response) => {
            if (response.data.success !== true) {
               const message = "Failed to save new quantity for part";
               this.alertService.addAlert(message, "danger", 6000);
               throw new Error(message);
            }
         });
      this.preventTaskCompletionInsufficientParts =
         this.manageUser.getCurrentUser().userInfo
            .preventTaskCompletionInsufficientPartsFlag === 1;
   }

   protected tellTaskToRefresh() {
      this.tasksUpdated$.next();
   }

   public ngOnInit() {
      // We must call to this or the component won't load, due to the loading state being tied to this signal/observable.
      this.associatedTemplates();

      // Logic to avoid hiding the selector if we change the enumeration values
      const displayModes = Object.values(this.extraTimeDisplayModes);
      const storedDisplayMode =
         this.manageUser.getCurrentUser().userInfo?.userUIPreferences
            ?.extraTimeDefaultDisplayMode;
      const initialDisplayMode =
         displayModes.find((mode) => mode === storedDisplayMode) ??
         ExtraTimeDisplayModes.ByUser;

      this.displayMode = initialDisplayMode;

      this.setTaskTools(this.checklistID());

      this.showMinutes = true;
      if (this.customerID == 1143) {
         //TO DO... build advanced setting to make it so customer can disable timer
         this.showMinutes = false;
      }

      let categories = this.manageBilling.getCategories() || [];
      categories = orderBy(categories, "order");
      this.categories = [];
      categories.forEach((category) => {
         if (category.categoryName && category.categoryName.length > 0) {
            this.categories.push(category);
         }
      });
      this.categoriesIndex = this.manageBilling.getCategoriesIndex();
      this.selectedCategoryName = this.lang().PickCategory;
      this.recordExtraTime = 0;

      this.errorMsg = "";
      this.viewingLengthHint = false;

      const timer = this.timer();
      if (timer) {
         this.hours = timer.hours;
         this.minutes = timer.minutes;
         this.viewingLengthHint = this.lang().WeNoticedYouHaveBeenViewingThisTaskFor;
         this.viewingLengthHint += ` ${timer.hours} ${this.lang().hrs}, ${timer.minutes} ${this.lang().mins}, ${timer.seconds} ${this.lang().secs}`;
      }
   }

   protected showNotesToRequestor(task: TaskEntity) {
      //IMPORTANT... we also need to do a check to see if we should allow the user to send notes to the requestor.  On the back end we protect against this as well
      //the check is that if there are any start WO or assign PM then the responsiblity to let a Requestor know it is completed is now responsible with the child Task.
      //we put this data here so that we can access it on complete Task.
      const items = this.items();
      if (items) {
         const shouldShowNotesToRequestor = items.reduce((shouldShowNotes, item) => {
            if (
               (item.itemTypeID === TaskInstructionTypeID.AssignPM ||
                  item.itemTypeID === TaskInstructionTypeID.StartWO) &&
               item.itemResponse > 0
            ) {
               return false;
            }
            return shouldShowNotes;
         }, true);
         if (!shouldShowNotesToRequestor) {
            return false;
         }
      }
      const checklistEmailCN = task?.checklistEmailCN;
      if (checklistEmailCN && checklistEmailCN.length > 0) {
         return true;
      }
      return false;
   }

   private initializeModalData(task: TaskEntity) {
      if (this.showNotesToRequestor(task)) {
         this.notesToTheRequestor.set(task.checklistComments ?? "");
      }

      const now = Math.floor(Date.now() / 1000);
      const difference = now - (task.checklistCreatedDate ?? 0); //this shouldn't be null ever, but it's typed to potentially by null

      this.differenceHours = Number(Math.floor(difference / 3600));
      this.differenceMinutes = Number(Math.floor((difference / 60) % 60));

      this.taskAssetIDOLD.set(task.assetID ?? 0);

      if (this.customerFIFOLIFO === 0 || this.customerFIFOLIFO === 1) {
         for (const part of this.parts()) {
            part.poItemID = 0;
         }
      }

      const categories = this.manageBilling.getCategories() || [];
      //rick boggs from cal state wanted this to default only for Work Requests.  We normally don't do this thing, but Bryan approved this single one
      if (
         SHOULD_DEFAULT_WORK_ORDER_CATEGORY_CUSTOMERS.includes(this.customerID) &&
         categories.length > 0 &&
         task.checklistBatchID === 300112
      ) {
         this.setCategory(categories[0]);
      }
      //end

      if ((task.extraTime?.length ?? 0) > 0) {
         this.multiWork = true;
      }

      const checklistDowntime = task.checklistDowntime;
      if (checklistDowntime && checklistDowntime > 0) {
         this.hoursDowntime = Number(Math.floor(checklistDowntime / 3600));
         this.minutesDowntime = Number(Math.floor((checklistDowntime / 60) % 60));
         this.trackDowntime = 1;
      }

      this.files = [];
      const checklistEmailCNImages = task.checklistEmailCNImages;
      if (
         checklistEmailCNImages !== null &&
         typeof checklistEmailCNImages === "string" &&
         checklistEmailCNImages.length > 0
      ) {
         const files = checklistEmailCNImages.split(";");
         for (const fileName of files) {
            if (fileName.length > 3) {
               const obj: any = {};
               obj.fileName = fileName;

               const reg = /(?:\.([^.]+))?$/;
               obj.fileExt = reg.exec(obj.fileName);
               if (obj.fileExt.length > 1) {
                  obj.fileExt = obj.fileExt[1].toLowerCase();
               }
               obj.getURL = `viewFile.php?f=upload-${this.customerID}/checklistEmailCNImages/${task.checklistID}/${obj.fileName}`;
               this.files.push(obj);
            }
         }
      }

      this.uploadObject = {};
      this.uploadObject.primaryID = "fileName";
      this.uploadObject.uploadTypes = "images"; //images / documents / importFiles (excel + csv) or empty for default (images and documents)
      this.uploadObject.viewOnly = false;
      this.uploadObject.onUploadPopEditor = false; //boolean will images immediately open for editing after upload
      this.uploadObject.posturl = `phpscripts/checklistManager.php?action=makeFileForChecklistEmailCNImages&checklistID=${task.checklistID}`;
      this.uploadObject.deleteData = {};
      this.uploadObject.deleteSuccess = (answer) => {
         if (answer.data.success === true) {
            this.files = [...this.files]; //this is to force the change detection to update the view
         }
      };
      this.uploadObject.deleteCall = async (deleteData) => {
         return new Promise((res) => {
            assert(this.task); //all these functions require task to be defined.
            this.manageTask
               .deleteFileFromChecklistEmailCNImages(
                  deleteData.fileName,
                  task.checklistID,
               )
               .then((answer) => {
                  if (answer.data.success === true) {
                     for (let index = this.files.length - 1; index >= 0; index--) {
                        if (this.files[index].fileName === deleteData.fileName) {
                           this.files.splice(index, 1);
                        }
                     }
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
                  res(answer);
               });

            this.fileStorageSyncService.syncCompletionFileDeletion({
               taskID: task.checklistID,
               fileName: deleteData.fileName,
            });
         });
      };
      this.uploadObject.uploadComplete = (uploadData) => {
         if (uploadData.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
            return;
         }
         $("#status").html(
            `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
         );
         //test
         if (uploadData.fileID != 0) {
            const reg = /(?:\.([^.]+))?$/;
            uploadData.row.fileExt = reg.exec(uploadData.row.fileName);
            if (uploadData.row.fileExt.length > 1) {
               uploadData.row.fileExt = uploadData.row.fileExt[1].toLowerCase();
            }
            if (this.task !== undefined) {
               uploadData.row.getURL = `viewFile.php?f=upload-${this.customerID}/checklistEmailCNImages/${task.checklistID}/${uploadData.row.fileName}`;

               this.fileStorageSyncService.syncCompletionFileAddition({
                  customerID: this.customerID,
                  taskID: task.checklistID,
                  fileName: uploadData.row.fileName,
               });
            }
            this.files = this.files || [];
            this.files = [...this.files, uploadData.row];
         }
      };
      if (this.editTime() === false && this.hours === 0 && this.minutes === 0) {
         this.minutes = 1; //sanity to make sure there is at least some time logged assuming they can't edit the time.  It would be frustrating if you couldn't close out a task because you can't change the time ;p
      }
      this.setPartsUnderStocked();
   }

   public setCategory = (category) => {
      this.selectedCategoryID = category.categoryID;
      this.selectedCategoryName = category.categoryName;
   };

   private getAssetNumericFieldsDisplayInfo(): AssetNumericFieldDisplayInfo[] {
      if (!this.isUserAuthorizedToChangeAssetInfo()) {
         return [];
      }

      const assetNumericFieldDisplayInfo: AssetNumericFieldDisplayInfo[] = [];
      const associatedTemplates = this.associatedTemplates();

      if (associatedTemplates) {
         const valueIDsAdded = new Set<number>();
         for (const template of associatedTemplates) {
            template.recurrences.forEach((recurrence) => {
               const valueIDs = recurrence.reoccurFld1.split(",");
               for (const valueID of valueIDs) {
                  if (valueIDsAdded.has(Number(valueID))) {
                     continue;
                  }
                  if (valueID > 0) {
                     const value = this.manageAsset.getFieldValue(Number(valueID));
                     if (!value) {
                        continue;
                     }

                     const field = this.manageAsset.getField(value.fieldID);
                     if (!field) {
                        continue;
                     }

                     const asset = this.asset();
                     assert(asset);

                     if (
                        asset.assetValueIDs.includes(Number(valueID)) &&
                        (value.viewableByTech === 1 ||
                           this.credService.isAuthorized(
                              asset.locationID,
                              this.credService.Permissions
                                 .ConfigureAssetInformationFields,
                           ))
                     ) {
                        assetNumericFieldDisplayInfo.push({
                           reoccurID: recurrence.reoccurID,
                           fieldValueID: Number(valueID),
                           field: field,
                           fieldValue: value,
                           tooltip: "",
                           userWantsToUpdate: false,
                        });
                        valueIDsAdded.add(Number(valueID));
                     }

                     this.updateTemplateNamesMap(Number(valueID), template.checklistName);
                  }
               }
            });
         }
      }

      this.createAssetFieldTooltips(assetNumericFieldDisplayInfo);

      return assetNumericFieldDisplayInfo;
   }
   private isUserAuthorizedToChangeAssetInfo(): boolean {
      const task = this.task();
      if (!task) return false;
      return this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.ChangeAssetInformationValues,
      );
   }

   private updateTemplateNamesMap(valueID: number, checklistName: string): void {
      const listOfNames = this.mapOfAssociatedTemplateNames.get(valueID) ?? [];
      if (!listOfNames.includes(checklistName)) {
         this.mapOfAssociatedTemplateNames.set(valueID, [...listOfNames, checklistName]);
      }
   }

   private createAssetFieldTooltips(
      assetNumericFieldDisplayInfo: AssetNumericFieldDisplayInfo[],
   ): void {
      const asset = this.asset();
      assert(asset);
      assetNumericFieldDisplayInfo.forEach((displayInfo) => {
         const value = this.manageAsset.getFieldValue(displayInfo.fieldValueID);
         assert(value);
         const field = this.manageAsset.getField(value.fieldID);
         assert(field);

         displayInfo.tooltip = `${asset.assetName} ${this.lang().UpdateAssetFieldToolTip1} "${[...(this.mapOfAssociatedTemplateNames.get(displayInfo.fieldValueID) ?? [])].join('" & "')}" ${this.lang().UpdateAssetFieldToolTip2} "${field.fieldName}" ${this.lang().UpdateAssetFieldToolTip3}`;
      });
   }

   private getAssociatedTemplates(
      assignedAssetID: number,
   ): Observable<Array<TaskTemplateEntity>> {
      const templatesStream = this.taskTemplatesApiService.getStreamedList({
         params: {
            assetIDs: [assignedAssetID],
            checklistTemplate: 1,
            recurrenceTypes: [7, 8],
         },
         columns: "recurrences",
      });

      return from(templatesStream).pipe(toArray());
   }

   public ngOnDestroy() {
      this.manageFeatureFlagsSub.unsubscribe();
      this.partQuantitySubscription.unsubscribe();
   }

   protected updateAssetField(displayInfo: AssetNumericFieldDisplayInfo): void {
      let shouldAdd = true; //only add a field to the to update list once
      this.fieldsToUpdate.forEach((field) => {
         if (field.reoccurID === displayInfo.reoccurID) {
            shouldAdd = false;
         }
      });
      if (shouldAdd) {
         this.fieldsToUpdate.push(displayInfo);
      }
   }

   setStartingDownTime = () => {
      this.minutesDowntime = this.differenceMinutes;
      this.hoursDowntime = this.differenceHours;
      this.trackDowntime = 1;
      this.assignToAsset = 1;
   };

   switchLogTime = () => {
      if (!this.featureTimeCost) {
         return;
      }

      this.multiWork = true;
      this.minutes = 0;
      this.hours = 0;
   };

   isNumeric = (num) => {
      return !isNaN(parseFloat(num)) && isFinite(num);
   };

   protected close(): void {
      this.modalRef.close();
   }

   protected async submit(): Promise<void> {
      if (this.processing()) {
         return; //we want to stop this process from running if it's already running
      }
      this.errorMsg = "";
      this.hoursError.set(false);
      this.minutesError.set(false);
      this.assetFieldsError.set(false);
      this.hoursErrorDowntime.set(false);
      this.minutesErrorDowntime.set(false);
      this.notesToRequestorError.set(false);
      this.laborCategoriesError.set(false);
      this.unplannedDowntimeError.set(false);
      this.assignToAssetError.set(false);
      this.processing.set(true);
      let validSubmission = true;
      const task = this.task();
      assert(task); //we need the task to complete the task
      assert(this.parts() !== undefined);

      if (!this.featureDowntimeTracking) {
         this.trackDowntime = 0;
      }

      // Check if we have the necessary parts
      validSubmission = this.checkIfWeHaveEnoughPartsInStock(validSubmission);

      if (this.multiWork) {
         const extraTimes = task?.extraTime ?? [];
         if (extraTimes.length === 0) {
            this.errorMsg += `${this.lang().PleaseEnterAtLeastOneTime}<br /><br />`;
            this.hoursError.set(true);
            this.minutesError.set(true);
            validSubmission = false;
         }
      } else if (
         !this.featureDisableTimeLogging && //BRYAN TO DO... some customers don't want to be prompted for a time
         this.minutes < 1 &&
         this.hours === 0 &&
         this.featureTimeCost
      ) {
         if (this.minutes > 0) {
            // This means the user likely tried to enter a number like 0.0001. This caused confusion for users so we are now requiring at least 1 minute to be entered.
            this.errorMsg += `${this.lang().YouMustLogAtLeastOneMinute}<br /><br />`;
            this.minutesError.set(true);

            validSubmission = false;
         } else {
            this.errorMsg += `${this.lang().PleaseEnterHowLongThisTaskTook}<br /><br />`;
            this.hoursError.set(true);
            this.minutesError.set(true);
            validSubmission = false;
         }
      }

      // make sure they selected an option for the downtime question
      if (this.shouldShowDowntimeSection() && this.trackDowntime === undefined) {
         this.errorMsg += `${this.lang().PleaseSelectAnOptionForDowntimeQuestion}<br /><br />`;
         this.unplannedDowntimeError.set(true);
         validSubmission = false;
      }

      // make sure they selected an option for the assign to asset question
      if (
         this.shouldShowAssignToAssetSection() &&
         this.assignToAsset === undefined &&
         !this.asset()
      ) {
         this.errorMsg += `${this.lang().PleaseSelectAnOptionForAssignToAssetQuestion}<br /><br />`;
         this.assignToAssetError.set(true);
         validSubmission = false;
      }

      if (
         this.shouldShowAssignToAssetSection() &&
         this.assignToAsset === 1 &&
         !this.asset()
      ) {
         this.errorMsg += `${this.lang().PleasePickAnAsset}<br /><br />`;
         this.assignToAssetError.set(true);
         validSubmission = false;
      }

      //if they don't already have mulitple work and they are using billing categories we have to validate this...
      if (!this.multiWork && this.categories.length > 0 && this.featureLaborCategories) {
         if (this.selectedCategoryID === 0) {
            this.errorMsg += `${this.lang().PleasePickALaborCategory}<br /><br />`;
            this.laborCategoriesError.set(true);
            validSubmission = false;
         }
      }

      if (this.fieldsToUpdate.length > 0) {
         const promises = this.fieldsToUpdate.map(async (field) => {
            const asset = this.asset();
            assert(asset);
            const value = this.manageAsset.getFieldValue(Number(field.fieldValueID));
            assert(value);
            //make sure the input is a number
            if (this.isNumeric(value.valueContent) && this.task !== undefined) {
               return this.manageAsset
                  .setFieldValue(value, asset, this.checklistID())
                  .then((answer) => {
                     // Have to do this to get an accurate 'Next Created at' on the PMs page
                     if (answer.data.newTasks && answer.data.newTasks === "skip") {
                        this.manageTask.getData();
                     }
                  });
            }
            this.errorMsg += `${this.lang().PleaseEnterANumber}<br /><br />`;
            this.assetFieldsError.set(true);
            validSubmission = false;
            return Promise.resolve();
         });
         await Promise.all(promises);
      }

      validSubmission =
         this.hoursAndMinutesAreValid(validSubmission) &&
         this.downtimeHoursAndMinutesAreValid(validSubmission);

      if (this.uploadObject.uploading) {
         validSubmission = false;
         this.errorMsg = `${this.lang().pleaseWaitForFileToLoad}<br /><br />`;
      }

      if (this.showNotesToRequestor(task)) {
         //for tasks where we need to send notes to a requestor
         if (this.customerRequiresNotesToRequestor === 1) {
            if (!this.notesToTheRequestor() || this.notesToTheRequestor().length < 1) {
               validSubmission = false;
               this.errorMsg = `${this.lang().PleaseEnterNotesToTheRequestor}<br /><br />`;
               this.notesToRequestorError.set(true);
            }
         }
      }

      if (validSubmission) {
         const submitData: CompleteTaskSubmitData = {
            hours: this.hours,
            minutes: this.minutes,
            notesToTheRequestor: this.notesToTheRequestor(),
            parts: this.parts(),
            selectedCategory: this.selectedCategoryID,
            multiWork: this.multiWork,
         };

         const assignedAssetID = task.assetID;
         if (
            (this.trackDowntime === 1 || this.trackDowntime === true) &&
            assignedAssetID &&
            assignedAssetID > 0
         ) {
            submitData.hoursDowntime = this.hoursDowntime;
            submitData.minutesDowntime = this.minutesDowntime;
         } else {
            submitData.hoursDowntime = 0;
            submitData.minutesDowntime = 0;
         }
         this.processing.set(false);
         this.modalRef.close(submitData);
      }
      if (this.errorMsg !== "") {
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
         this.processing.set(false);
      }
      this.processing.set(false);
   }

   checkIfWeHaveEnoughPartsInStock = (initialValid: boolean): boolean => {
      let valid = initialValid;
      if (
         this.preventTaskCompletionInsufficientParts &&
         this.partsUnderStocked.size > 0
      ) {
         this.errorMsg += `${this.lang().YouDoNotHAveEnoughPartsInStockToCompleteThisTask}<br /><br />`;
         valid = false;
      }
      return valid;
   };

   downtimeHoursAndMinutesAreValid = (initialValid: boolean): boolean => {
      let valid = initialValid;

      if (
         this.trackDowntime === 1 &&
         this.minutesDowntime === 0 &&
         this.hoursDowntime === 0
      ) {
         this.errorMsg += `${this.lang().PleaseEnterHowMuchDowntimeThisTaskCaused}<br /><br />`;
         this.hoursErrorDowntime.set(true);
         this.minutesErrorDowntime.set(true);
         valid = false;
      }

      if (this.trackDowntime === 1 && !this.isNumeric(this.hoursDowntime)) {
         this.errorMsg += `${this.lang().PleaseEnterValidValueForDowntimeHours}<br /><br />`;
         this.hoursErrorDowntime.set(true);
         valid = false;
      }

      if (this.trackDowntime === 1 && !this.isNumeric(this.minutesDowntime)) {
         this.errorMsg += `${this.lang().PleaseEnterValidValueForDowntimeMinutes}<br /><br />`;
         this.minutesErrorDowntime.set(true);
         valid = false;
      }
      return valid;
   };

   hoursAndMinutesAreValid = (initialValid: boolean): boolean => {
      let valid = initialValid;
      if (!this.isNumeric(this.hours)) {
         this.errorMsg += `${this.lang().PleaseEnterValidValueForHours}<br /><br />`;
         this.hoursError.set(true);
         valid = false;
      }

      if (!this.isNumeric(this.minutes)) {
         this.errorMsg += `${this.lang().PleaseEnterValidValueForMinutes}<br /><br />`;
         this.minutesError.set(true);
         valid = false;
      }
      return valid;
   };

   popPoComponent = (poID) => {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   };

   changeAsset = () => {
      const task = this.task();
      assert(task); //can't  update the task if we don't have it.

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.lang().changeTasksAssetMsg;
      instance.title = this.lang().changeTasksAsset;
      instance.singleLocation = task.locationID;
      instance.selectOne = true;
      instance.restrictToCred = false;
      instance.iDontKnowOption = true;
      instance.dataLogSelectLabel = PickAssetDataLogType.TASK;

      modalRef.result.then((asset) => {
         assert(this.task); //can't  update the task if we don't have it.
         if (asset) {
            let assetToChange = asset;
            if (asset === "unsure") {
               assetToChange = {};
               assetToChange.assetID = 0;
               assetToChange.assetName = "";
               assetToChange.locationID = task.locationID;
            }

            this.manageTask
               .updateTasksAsset(this.checklistID(), assetToChange, [])
               .then((answer) => {
                  assert(this.task); //can't  update the task if we don't have it.
                  if (answer.data.success === true) {
                     this.tasksUpdated$.next();
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   setAssetToZero = () => {
      const task = this.task();
      if (task !== undefined && task.assetID !== 0) {
         const asset: any = {};
         asset.assetID = 0;
         asset.assetName = "";
         asset.locationID = task.locationID;

         this.manageTask.updateTasksAsset(task.checklistID, asset, []).then((answer) => {
            if (answer.data.success === true) {
               this.tasksUpdated$.next();
               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      }
   };

   logTimeOnTask = () => {
      const task = this.task();
      assert(task);
      const instance = this.modalService.open(LogTimeOnTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().LogTimeMsg,
            title: this.lang().LogTime,
            data: {
               locationID: task.locationID,
               buttonText: this.lang().RecordTime,
               checklistID: task.checklistID,
               extraMsg: false,
               extraMsgTooltip: false,
               showNotes: true,
               timer: this.timer(),
            },
         },
      };

      instance.result.then((result) => {
         assert(this.task);
         if (result) {
            const totalSeconds = result.minutes * 60 + result.hours * 60 * 60;
            const addNote = false;

            this.manageTask
               .logTimeOnTask(
                  task.checklistID,
                  totalSeconds,
                  result.userID,
                  result.loggedAt,
                  result.notes,
                  result.categoryID,
                  addNote,
                  result.noteHidden,
               )
               .then((answer) => {
                  assert(this.task);
                  if (answer.data.success === true) {
                     this.tasksUpdated$.next();
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   protected setDisplayMode(displayMode: ExtraTimeDisplayModes) {
      this.displayMode = displayMode;
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.extraTimeDefaultDisplayMode =
         displayMode;
      this.manageUser.updateUserUIPreferences();
   }

   private async setTaskTools(checklistID: number) {
      this.tools = await this.manageTool.getTaskTools(checklistID, true);
   }

   protected resetErrors(errors: string[]): void {
      errors.forEach((error) => {
         this[error].set(false);
      });

      this.errorMsg = "";
   }

   protected updatePart(
      part: TaskPartLegacy | TaskEntityPart,
      newSuggestedNumber: number,
   ): void {
      this.partQuantityUpdates$.next({
         part,
         quantity: newSuggestedNumber,
      });
   }

   private setPartsUnderStocked(): void {
      assert(this.parts() !== undefined);
      this.partsUnderStocked = new Set(
         this.taskPartsAvailabilityService
            .partsUnderStocked(this.parts())
            .map((taskPart) => taskPart.partID),
      );
   }

   protected toggleUpdateFieldValue(
      fieldLocked: number,
      displayInfo: AssetNumericFieldDisplayInfo,
      userWantsToUpdate: boolean,
   ): void {
      if (fieldLocked) {
         return;
      }
      displayInfo.userWantsToUpdate = userWantsToUpdate;
   }
}
