@let taskAsset = mainAsset();
@if (taskAsset && assetsLoaded()) {
   <span class="asset-cell">
      <lim-ui-icon icon="cube" iconColor="standard" />
      @if (taskAsset.assetID) {
         <span
            (click)="onAssetClick($event)"
            class="green-color green-color clickable"
            [limbleHtml]="taskAsset.assetID ?? 0 | assetName"
         ></span>

         @if (assetsLoaded()) {
            <asset-parent-list
               [assetID]="taskAsset.assetID"
               linkColorClass="task-sub-title"
            />
         }
      }
   </span>
}
