import { CommonModule } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { TableModule } from "primeng/table";
import { TableColumnHeaderComponent } from "src/app/shared/empowered/base/table/components/table-column-header/table-column-header.component";
import type {
   SortEvent,
   TableColumn,
} from "src/app/shared/empowered/base/table/table.models";

@Component({
   selector: "e-table",
   templateUrl: "./table.component.html",
   styleUrls: ["./table.component.scss"],
   imports: [CommonModule, TableModule, TableColumnHeaderComponent],
})
export class TableComponent {
   /**  List of columns to be displayed in the table   */
   public columns = input.required<Array<TableColumn>>();

   /** List of rows to be displayed in the table   */
   public data = input.required<Array<any>>();

   /** The current sort */
   public sort = input<SortEvent>();

   public stripedRows = input<boolean>(true);

   /** Emits when the sort changes */
   public readonly sortChange = output<SortEvent>();

   public onSortChange(event: SortEvent) {
      this.sortChange.emit(event);
   }

   protected trackColumn(index: number, col: TableColumn, type: "header" | "body") {
      return `${type}-${col.headerNameKey}-${col.field}`;
   }
}
