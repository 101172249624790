import type { OnInit } from "@angular/core";
import {
   inject,
   Component,
   forwardRef,
   Input,
   computed,
   input,
   signal,
} from "@angular/core";
import {
   IconComponent,
   ModalService,
   MinimalIconButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ToolStatusInfo } from "src/app/assets/components/toolStatusInfoElement/toolStatusInfo.element.component";
import { ManageTool } from "src/app/assets/services/manageTool";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ParamsService } from "src/app/shared/services/params.service";
import type { CheckOutRequest } from "src/app/shared/types/general.types";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "task-tool",
   templateUrl: "./taskTool.element.component.html",
   styleUrls: ["./taskTool.element.component.scss"],
   imports: [
      IconComponent,
      MinimalIconButtonComponent,
      TooltipDirective,
      forwardRef(() => ToolStatusInfo),
   ],
})
export class TaskTool implements OnInit {
   @Input() tool;
   /**
    * @deprecated use the task input rather than a checklistID.
    */
   @Input() checklistID: number | undefined;
   public readonly taskInput = input<TaskEntity | undefined>(undefined);
   @Input() instance: boolean | undefined;
   @Input() mostRecentRequest: CheckOutRequest | null | undefined;

   @Input() removeToolFromTask: ((toolAssetID: number) => void) | undefined;

   public taskComplete: boolean | undefined;
   public toolLocationName: string | undefined;
   protected taskLegacy = signal<Task | undefined>(undefined);

   private isUserWhoRequested: boolean | undefined;
   private readonly currentUserID;

   private readonly manageTool = inject(ManageTool);
   private readonly manageUser = inject(ManageUser);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly task = computed(() => {
      return this.taskInput() ?? this.taskLegacy();
   });

   public constructor() {
      this.currentUserID = Number(this.manageUser.getCurrentUser().gUserID);
   }

   public ngOnInit() {
      if (!this.checklistID) {
         return;
      }
      this.taskLegacy.set(this.manageTask.getTaskLocalLookup(this.checklistID));
      const task = this.task();
      if (!task) {
         return;
      }
      if (Number(task.checklistCompletedDate)) {
         this.taskComplete = true;
      }
      this.setIsUserWhoRequested();
      this.toolLocationName =
         this.manageLocation.getLocation(this.tool.locationID)?.locationName ?? "";
   }

   public createCheckOutRequest = async (): Promise<CheckOutRequest | false> => {
      const task = this.task();
      if (!task) {
         return false;
      }
      const createdRequestData = await this.manageTool.createCheckOutRequest(
         Number(this.tool.assetID),
         Number(this.tool.locationID),
         Number(task.checklistID),
      );
      if (!createdRequestData) {
         return false;
      }
      this.mostRecentRequest = createdRequestData.fullRequest;
      if (createdRequestData.taskNote) {
         this.manageTask.addNoteToLocalData(
            createdRequestData.taskNote,
            task.checklistID,
         );
      }
      return this.mostRecentRequest;
   };

   public checkInTool = async (): Promise<CheckOutRequest | false | null | undefined> => {
      if (!this.isUserWhoRequested) {
         const result = await this.manageTool.confirmCheckIn();
         if (!result) {
            return false;
         }
      }

      const checkInData = await this.manageTool.checkToolIn(
         Number(this.mostRecentRequest?.checkOutID),
         this.currentUserID,
      );
      if (!checkInData) {
         return false;
      }
      if (this.mostRecentRequest) {
         this.mostRecentRequest.checkInDate = checkInData.entry.date;
         this.mostRecentRequest.checkedInByUserID = this.currentUserID;
      }
      if (checkInData.taskNote?.checklistID) {
         this.manageTask.addNoteToLocalData(
            checkInData.taskNote,
            checkInData.taskNote.checklistID,
         );
      }
      return this.mostRecentRequest;
   };

   public cancelRequest = async (): Promise<
      CheckOutRequest | false | null | undefined
   > => {
      const cancelledData = await this.manageTool.cancelRequest(
         Number(this.mostRecentRequest?.checkOutID),
      );
      if (!cancelledData) {
         return false;
      }
      if (this.mostRecentRequest) {
         this.mostRecentRequest.requestCancelledDate = cancelledData.entry.date;
      }
      if (cancelledData.taskNote?.checklistID) {
         this.manageTask.addNoteToLocalData(
            cancelledData.taskNote,
            cancelledData.taskNote.checklistID,
         );
      }
      return this.mostRecentRequest;
   };

   private setIsUserWhoRequested() {
      if (this.currentUserID === this.mostRecentRequest?.requestedByUserID) {
         this.isUserWhoRequested = true;
      }
   }

   public popAsset() {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: this.tool.assetID,
            locationID: this.tool.locationID,

            data: {
               restrict: false,
               navigateToTab: "checkInOut",
            },
         },
      };
   }

   public runRemoveToolFromTask() {
      if (this.removeToolFromTask) {
         this.removeToolFromTask(this.tool.assetID);
      }
   }
}
