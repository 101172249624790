@if (task) {
   <span
      id="{{ task.checklistID }}TaskHolder"
      class="task-item-wrapper lim-ui-responsive-table-row"
      [class.completed-task-row]="taskIsCompleted"
   >
      <!-- Start of Building Out Columns -->
      @for (column of columns; track column; let isLast = $last) {
         <div
            class="col-md-{{ column.columnWidth }} lim-ui-responsive-table-cell"
            (click)="viewTask()"
         >
            @switch (column.key) {
               <!-- checklistName -->
               @case ("checklistName") {
                  <div class="lim-ui-mobile-table-header">
                     <div
                        class="taskItemClass allowOffline"
                        [ngClass]="
                           isModal
                              ? 'name-priority-type-status-container-modal'
                              : 'name-priority-type-status-container'
                        "
                     >
                        <!-- Priority button -->
                        <priority-button-legacy
                           [taskIsCompleted]="taskIsCompleted"
                           (dropdownClickEvent)="dropdownClick()"
                           [taskID]="task.checklistID"
                           [priorityID]="task.priorityID"
                           [locationID]="task.locationID"
                           [superUser]="superUser"
                           [fixedWidthTableButton]="true"
                           class="name-button-spacing"
                        />

                        <!-- status button -->
                        <status-button-legacy
                           [taskIsCompleted]="taskIsCompleted"
                           (dropdownClickEvent)="dropdownClick()"
                           [taskID]="task.checklistID"
                           [locationID]="task.locationID"
                           [statusID]="task.statusID"
                           [statusList]="statusList"
                           [superUser]="superUser"
                           [fixedWidthTableButton]="true"
                           class="name-button-spacing"
                        />

                        <!-- Menu button -->
                        @if (!taskIsCompleted && deleteCred) {
                           <lim-ui-dropdown
                              class="lim-ui-hide-on-desktop button-menu float-right"
                              (click)="dropdownClicked = true"
                           >
                              <lim-ui-minimal-icon-button
                                 id="mobile-widget-buttons"
                                 button
                                 icon="ellipsisVertical"
                              />
                              <div menu aria-labelledby="mobile-widget-buttons">
                                 <lim-ui-dropdown-item
                                    [limUiPopover]="startDuplicateTaskMobilePopover"
                                    [limUiPopoverType]="'upsell'"
                                    [limUiPopoverPlacement]="'bottom'"
                                    (click)="duplicate($event)"
                                    [ngClass]="{
                                       'grey-out': !canDuplicateTasks,
                                    }"
                                    [limUiPopoverHidden]="canDuplicateTasks"
                                 >
                                    <div class="dropdown-with-badge-container">
                                       <span>{{ lang().duplicateOpenTask }}</span>
                                       @if (!canDuplicateTasks) {
                                          <lim-ui-badge
                                             class="pricing-plan-badge"
                                             icon="stars"
                                          />
                                       }
                                    </div>
                                 </lim-ui-dropdown-item>
                                 <ng-template #startDuplicateTaskMobilePopover>
                                    <upsell-popover
                                       [message]="
                                          woInstructionLimit > 2
                                             ? lang().FeatureThreeWOsPopoverMessage
                                             : lang().FeatureTwoWOsPopoverMessage
                                       "
                                       [pricingLinkText]="lang().LearnMore"
                                    />
                                 </ng-template>

                                 <lim-ui-dropdown-divider />
                                 <lim-ui-dropdown-item (click)="deleteTask()">
                                    <lim-ui-icon
                                       icon="trashCanRegular"
                                       iconColor="danger"
                                    />
                                    {{ lang().permDeleteTaskTooltip }}
                                 </lim-ui-dropdown-item>
                              </div>
                           </lim-ui-dropdown>
                        }

                        <lim-ui-dropdown
                           [disabled]="
                              taskIsCompleted ||
                              (task.checklistTemplateOld !== null &&
                                 task.checklistTemplateOld == 5)
                           "
                           (click)="dropdownClick()"
                        >
                           <lim-ui-table-button
                              placement="right"
                              limUiTooltip="{{ taskTypeStr }}"
                              [disabled]="
                                 taskIsCompleted ||
                                 (task.checklistTemplateOld !== null &&
                                    task.checklistTemplateOld == 5)
                              "
                              id="task-type"
                              button
                              [icon]="taskExtraInfo.taskTypeIcons"
                              class="lim-ui-show-on-desktop name-button-spacing"
                              [fixedWidthTableButton]="true"
                           />
                           <ng-container menu aria-labelledby="task-type">
                              <lim-ui-dropdown-item
                                 (click)="changeTaskType()"
                                 placement="right"
                                 limUiTooltip="{{ lang().changeTasksTypeTooltip }}"
                                 class="lim-ui-show-on-desktop"
                              >
                                 <lim-ui-icon icon="penToSquare" />
                                 {{ lang().changeTasksType }}
                              </lim-ui-dropdown-item>
                           </ng-container>
                        </lim-ui-dropdown>

                        @if (!showLoc) {
                           <lim-ui-icon
                              icon="houseChimney"
                              limUiTooltip="{{ lang().locatedAt }} {{
                                 taskExtraInfo.locationName
                              }}"
                              class="lim-ui-show-on-desktop house-icon"
                           />
                        }

                        <span class="no-float-desktop">
                           @if (taskExtraInfo.taskTypeIcons) {
                              <lim-ui-icon
                                 class="mobile-task-icon lim-ui-mobile-table-column-label task-name lim-ui-hide-on-desktop"
                                 [icon]="taskExtraInfo.taskTypeIcons"
                              />
                           }

                           <span
                              [limbleHtml]="task.checklistName"
                              class="text task-name"
                           ></span>
                           @if (isTaskNameIncludingIdAndAssetName) {
                              <span
                                 [limbleHtml]="' - # ' + task.checklistID"
                                 class="text task-name"
                              ></span>
                              @if (
                                 task.assetID !== null &&
                                 manageAsset.getAsset(task.assetID)
                              ) {
                                 <span
                                    class="text task-asset separate-clickable"
                                    (click)="viewAsset(task); $event.stopPropagation()"
                                 >
                                    -
                                    <span [limbleHtml]="task.assetID | assetName"></span>
                                    <asset-parent-list
                                       [assetID]="task.assetID"
                                       linkColorClass="task-sub-title"
                                    />
                                 </span>
                              }
                           }

                           <!-- The icons -->
                           <span class="icon-container">
                              @if (taskExtraInfo.commentInfo?.showCommentsHint) {
                                 <lim-ui-icon
                                    placement="right"
                                    limUiTooltip="{{ lang().oneOrMoreComments }}"
                                    icon="commentRegular"
                                 />
                              }

                              @if (
                                 taskExtraInfo.commentInfo &&
                                 taskExtraInfo.commentInfo.unreadComments > 0
                              ) {
                                 <div class="unread-comments-number">
                                    ({{ taskExtraInfo.commentInfo.unreadComments }})
                                 </div>
                              }

                              @if (hasParts) {
                                 <span class="part-stock-icon-container">
                                    @if (taskIsCompleted === false) {
                                       <lim-ui-icon
                                          icon="gearsRegular"
                                          placement="right"
                                          limUiTooltip="{{
                                             partsInStock
                                                ? lang().ThisTaskHasAllItsParts
                                                : lang().ThisTaskIsMissingParts
                                          }}"
                                          container="body"
                                       />
                                    }
                                    @if (taskIsCompleted === true) {
                                       <lim-ui-icon
                                          icon="gearsRegular"
                                          placement="right"
                                          container="body"
                                       />
                                    }
                                    @if (partsInStock) {
                                       <lim-ui-icon
                                          icon="check"
                                          iconColor="success"
                                          class="part-check-icon"
                                          placement="right"
                                       />
                                    }
                                 </span>
                              }

                              @if (
                                 task.checklistComments &&
                                 task.checklistComments.length > 2
                              ) {
                                 <lim-ui-icon
                                    icon="noteSticky"
                                    placement="right"
                                    limUiTooltip="{{ lang().completionNotes }}"
                                 />
                              }

                              @if (
                                 taskExtraInfo.requestorInformation &&
                                 taskExtraInfo.requestorInformation.length > 0
                              ) {
                                 <lim-ui-icon
                                    icon="phoneRegular"
                                    placement="right"
                                    limUiTooltip="{{ lang().RequestorInfo }}: {{
                                       taskExtraInfo.requestorInformation
                                    }}"
                                 />
                              }

                              @if (
                                 task.approvalItemState !== undefined &&
                                 task.approvalItemState !== null &&
                                 task.approvalItemState > 0
                              ) {
                                 <lim-ui-icon
                                    [icon]="
                                       task.approvalItemState == 1
                                          ? 'thumbsUp'
                                          : 'thumbsDown'
                                    "
                                    [iconColor]="
                                       task.approvalItemState == 2 ? 'danger' : 'inherit'
                                    "
                                    placement="right"
                                    limUiTooltip="{{
                                       lang().ApprovedOrDisapprovedHintTooltip
                                    }}"
                                 />
                              }
                           </span>
                        </span>

                        <!-- The search filter -->
                        @if (searchHint && searchHint.length > 0) {
                           <span class="search-hint desktop-search-hint">
                              <span [limbleHtml]="searchHint"> </span>
                           </span>
                        }
                     </div>
                  </div>
               }

               @case ("checklistID") {
                  <div>
                     <span class="text">#{{ task.checklistID }}</span>
                  </div>
               }

               @case ("assetName") {
                  <div
                     class="separate-clickable"
                     (click)="viewAsset(task); $event.stopPropagation()"
                  >
                     @if (task.assetID && task.assetID > 0) {
                        <asset-hierarchy-list
                           [assetID]="task.assetID"
                           [locationID]="task.locationID"
                        />
                     }
                  </div>
               }

               @case ("checklistLastEdited") {
                  @if (task.checklistLastEdited !== null) {
                     <div>
                        <span>{{
                           task.checklistLastEdited * 1000 | betterDate: "dateTime"
                        }}</span>
                     </div>
                  }
               }

               @case ("simultaneousUsers") {
                  <div>
                     <task-simultaneous-users [checklistID]="task.checklistID" />
                  </div>
               }

               <!-- checklistDueDate -->

               @case ("checklistDueDate") {
                  <div>
                     @if (!taskIsCompleted) {
                        <span class="due-date-sub-container">
                           <span
                              class="separate-clickable flex"
                              (click)="changeDueDate(); $event.stopPropagation()"
                           >
                              <!-- Calendar icon-->
                              <lim-ui-icon
                                 class="lim-ui-mobile-table-column-label calendar-icon"
                                 icon="calendar"
                              />

                              <!-- The date -->
                              <span
                                 class="date-text"
                                 [ngClass]="{ cursor: changeDueDateCred }"
                                 >{{ taskExtraInfo.checklistDueDateDisplay }}</span
                              >

                              <span class="days-due-badge">
                                 @if (
                                    taskExtraInfo.daysInfo &&
                                    taskExtraInfo.daysInfo.exactDays !== null
                                 ) {
                                    <span
                                       class="badge ml-1 mr-2"
                                       [ngClass]="{
                                          'badge-danger':
                                             task.checklistColor !== null &&
                                             (task.checklistColor * 1 == 3 ||
                                                task.checklistColor * 1 == 0),
                                          'badge-orange':
                                             task.checklistColor !== null &&
                                             task.checklistColor * 1 == 2,
                                          'badge-success':
                                             task.checklistColor !== null &&
                                             task.checklistColor * 1 == 1,
                                       }"
                                       placement="top"
                                       limUiTooltip="{{
                                          taskExtraInfo.daysInfo.days +
                                             ' ' +
                                             taskExtraInfo.daysInfo.daysMsg
                                       }}"
                                    >
                                       {{ taskExtraInfo.daysInfo.exactDays }}
                                    </span>
                                 }
                              </span>
                           </span>

                           <lim-ui-minimal-icon-button
                              [superMinimal]="true"
                              icon="envelopeRegular"
                              (click)="emailReminder(task); $event.stopPropagation()"
                              limUiTooltip="{{ lang().sendReminder }}"
                              placement="top"
                              class="lim-ui-show-on-desktop"
                           />

                           @if (deferTaskPermission) {
                              <lim-ui-minimal-icon-button
                                 icon="rotateRight"
                                 (click)="deferTask(); $event.stopPropagation()"
                                 limUiTooltip="{{ lang().delay }}"
                                 placement="top"
                                 [superMinimal]="true"
                              />
                           }

                           @if (
                              task.checklistEstTime !== null && task.checklistEstTime > 0
                           ) {
                              <span placement="top" limUiTooltip="{{ lang().estTime }}">
                                 {{
                                    task.checklistEstTime / 60 / 60
                                       | betterNumber: "1.2-2"
                                 }}
                                 {{ lang().hrs }}
                              </span>
                           }
                        </span>
                     }
                     @if (taskIsCompleted) {
                        @if (task.checklistDueDate !== null) {
                           <div
                              [ngStyle]="{
                                 'text-align': column.textAlign
                                    ? column.textAlign
                                    : 'left',
                              }"
                           >
                              <lim-ui-icon
                                 class="lim-ui-mobile-table-column-label"
                                 icon="calendar"
                              />
                              {{ task.checklistDueDate * 1000 | betterDate: "date" }}
                           </div>
                        }
                     }
                  </div>
               }

               <!-- assignedTo -->
               @case ("assignedTo") {
                  <div
                     class="separate-clickable"
                     (click)="changeAssignment(task); $event.stopPropagation()"
                  >
                     <lim-ui-icon class="lim-ui-mobile-table-column-label" icon="user" />

                     @if (!taskIsCompleted) {
                        <span
                           class="text blue disableOffline"
                           [limbleHtml]="taskExtraInfo.displayName"
                        ></span>
                     }

                     @if (taskIsCompleted) {
                        <span>{{ taskExtraInfo.displayName }}</span>
                     }
                  </div>
               }

               <!-- checklistPromptTimeTotal -->
               @case ("checklistPromptTimeTotal") {
                  <div>
                     @if (taskExtraInfo.checklistPromptTimeTotal !== null) {
                        <div
                           [ngStyle]="{
                              'text-align': column.textAlign ? column.textAlign : 'left',
                           }"
                        >
                           <span class="lim-ui-mobile-table-column-label"
                              >{{ lang().TimeSpent }}:
                           </span>
                           {{
                              taskExtraInfo.checklistPromptTimeTotal / 60 / 60
                                 | betterNumber: "1.2-2"
                           }}
                           @if (taskExtraInfo.checklistPromptTimeTotal == 3600) {
                              <span>{{ lang().hr }}</span>
                           }
                           @if (taskExtraInfo.checklistPromptTimeTotal != 3600) {
                              <span>{{ lang().hrs }}</span>
                           }
                        </div>
                     }
                  </div>
               }

               <!-- checklistTotalPartsCost -->
               @case ("checklistTotalPartsCost") {
                  <div>
                     @if (taskExtraInfo.calculatedInfo) {
                        <div
                           [ngStyle]="{
                              'text-align': column.textAlign ? column.textAlign : 'left',
                           }"
                        >
                           <span class="lim-ui-mobile-table-column-label"
                              >{{ lang().Parts }}:
                           </span>
                           @if (
                              taskExtraInfo.calculatedInfo.checklistTotalPartsCost > 0
                           ) {
                              <span>
                                 {{
                                    taskExtraInfo.calculatedInfo.checklistTotalPartsCost
                                       | localeCurrency: currencyCode()
                                 }}
                              </span>
                           }
                           @if (
                              taskExtraInfo.calculatedInfo.checklistTotalPartsCost == 0
                           ) {
                              <span>-</span>
                           }
                        </div>
                     }
                  </div>
               }

               <!-- checklistTotalInvoiceCost -->
               @case ("checklistTotalInvoiceCost") {
                  <div>
                     @if (taskExtraInfo.calculatedInfo) {
                        <div
                           [ngStyle]="{
                              'text-align': column.textAlign ? column.textAlign : 'left',
                           }"
                        >
                           <span class="lim-ui-mobile-table-column-label"
                              >{{ lang().Invoices }}:</span
                           >
                           @if (
                              taskExtraInfo.calculatedInfo.checklistTotalInvoiceCost > 0
                           ) {
                              <span>
                                 {{
                                    taskExtraInfo.calculatedInfo.checklistTotalInvoiceCost
                                       | localeCurrency: currencyCode()
                                 }}
                              </span>
                           }
                           @if (
                              taskExtraInfo.calculatedInfo.checklistTotalInvoiceCost == 0
                           ) {
                              <span>-</span>
                           }
                        </div>
                     }
                  </div>
               }

               <!-- checklistTotalOperatingCost -->
               @case ("checklistTotalOperatingCost") {
                  <div>
                     <div
                        [ngStyle]="{
                           'text-align': column.textAlign ? column.textAlign : 'left',
                        }"
                     >
                        <span class="lim-ui-mobile-table-column-label"
                           >{{ lang().Total }}:</span
                        >
                        @if (
                           taskExtraInfo.calculatedInfo &&
                           taskExtraInfo.calculatedInfo.checklistTotalOperatingCost > 0
                        ) {
                           <span>
                              {{
                                 taskExtraInfo.calculatedInfo.checklistTotalOperatingCost
                                    | localeCurrency: currencyCode()
                              }}
                           </span>
                        }
                        @if (
                           taskExtraInfo.calculatedInfo &&
                           taskExtraInfo.calculatedInfo.checklistTotalOperatingCost == 0
                        ) {
                           <span>-</span>
                        }
                     </div>
                  </div>
               }

               <!-- checklistPromptTimeTotalWithLaborCost -->
               @case ("checklistPromptTimeTotalWithLaborCost") {
                  <div>
                     <div
                        [ngStyle]="{
                           'text-align': column.textAlign ? column.textAlign : 'left',
                        }"
                     >
                        <span class="lim-ui-mobile-table-column-label"
                           >{{ lang().Labor }}:</span
                        >
                        <span class="column">
                           @if (taskExtraInfo.calculatedInfo) {
                              <div class="right-margin checklist-name-section-item">
                                 {{
                                    taskExtraInfo.calculatedInfo.checklistTotalLaborCost
                                       | localeCurrency: currencyCode()
                                 }}
                              </div>
                           }

                           @if (taskExtraInfo.calculatedInfo) {
                              <div class="checklist-name-section-item">
                                 ({{
                                    taskExtraInfo.calculatedInfo
                                       .checklistPromptTimeTotal /
                                       60 /
                                       60 | betterNumber: "1.2-2"
                                 }}
                                 @if (
                                    taskExtraInfo.calculatedInfo
                                       .checklistPromptTimeTotal == 3600
                                 ) {
                                    <span>{{ lang().hr }})</span>
                                 }
                                 @if (
                                    taskExtraInfo.calculatedInfo
                                       .checklistPromptTimeTotal != 3600
                                 ) {
                                    <span>{{ lang().hrs }})</span>
                                 }
                              </div>
                           }
                        </span>
                     </div>
                  </div>
               }

               <!-- checklistDowntime -->
               @case ("checklistDowntime") {
                  <div>
                     @if (task.checklistDowntime !== null) {
                        <span class="lim-ui-mobile-table-column-label"
                           >{{ lang().Downtime }}:
                        </span>
                        @if (
                           task.checklistDowntime !== null && task.checklistDowntime > 0
                        ) {
                           <span (click)="viewTask()" class="cursor"
                              >{{
                                 task.checklistDowntime / 60 / 60 | betterNumber: "1.2-2"
                              }}
                              {{ lang().hrs }}</span
                           >
                        }
                     }
                  </div>
               }

               <!-- finalColorStatus -->
               @case ("finalColorStatus") {
                  <div>
                     <span class="lim-ui-mobile-table-column-label"
                        >{{ lang().Completed }}:
                     </span>
                     @if (
                        task.finalColorStatus !== undefined && task.finalColorStatus == 1
                     ) {
                        <span class="greenColor">{{ lang().Green }}</span>
                     }
                     @if (
                        task.finalColorStatus !== undefined && task.finalColorStatus == 2
                     ) {
                        <span class="orangeColor">{{ lang().Orange }}</span>
                     }
                     @if (
                        task.finalColorStatus !== undefined && task.finalColorStatus == 3
                     ) {
                        <span class="redColor">{{ lang().Red }}</span>
                     }
                  </div>
               }

               <!-- checklistTemplateOldString -->
               @case ("checklistTemplateOldString") {
                  <div>
                     @if (taskExtraInfo.calculatedInfo) {
                        <span class="lim-ui-mobile-table-column-label"
                           >{{ lang().Type }}:
                        </span>
                        <span
                           [ngClass]="{
                              darkGreen:
                                 task.checklistTemplateOld !== null &&
                                 (task.checklistTemplateOld == 1 ||
                                    task.checklistTemplateOld == 4),
                              darkOrange:
                                 task.checklistTemplateOld !== null &&
                                 task.checklistTemplateOld == 2,
                           }"
                        >
                           {{ taskExtraInfo.calculatedInfo.checklistTemplateOldString }}
                        </span>
                     }
                  </div>
               }

               <!-- checklistCompletedDate -->
               @case ("checklistCompletedDate") {
                  <div>
                     @if (
                        taskExtraInfo.completedInfo &&
                        task.checklistCompletedDate !== null &&
                        task.checklistCompletedDate > 0 &&
                        task.finalColorStatus !== undefined
                     ) {
                        <div
                           [ngStyle]="{
                              'text-align': column.textAlign ? column.textAlign : 'left',
                           }"
                        >
                           <span class="lim-ui-mobile-table-column-label"
                              >{{ lang().Completed }}:</span
                           >

                           <span class="completed-date">
                              {{
                                 task.checklistCompletedDate * 1000 | betterDate: "date"
                              }}

                              @if (task.finalColorStatus !== undefined) {
                                 <span
                                    class="badge ml-1 mr-2"
                                    [ngClass]="{
                                       'badge-success': task.finalColorStatus * 1 == 1,
                                       'badge-orange': task.finalColorStatus * 1 == 2,
                                       'badge-danger':
                                          task.finalColorStatus * 1 == 3 ||
                                          task.finalColorStatus * 1 == 0,
                                    }"
                                    limUiTooltip="{{
                                       taskExtraInfo.completedInfo
                                          .completedDaysPastDueDateTooltip
                                    }}"
                                 >
                                    {{
                                       taskExtraInfo.completedInfo
                                          .completedDaysPastDueDate
                                    }}
                                 </span>
                              }
                           </span>
                        </div>
                     }
                  </div>
               }

               <!-- completedByStr -->
               @case ("completedByStr") {
                  <div>
                     @if (
                        taskExtraInfo.completedInfo &&
                        taskExtraInfo.completedInfo.completedByStr
                     ) {
                        <div
                           [ngStyle]="{
                              'text-align': column.textAlign ? column.textAlign : 'left',
                           }"
                        >
                           <span class="lim-ui-mobile-table-column-label"
                              >{{ lang().CompletedBy }}:
                           </span>

                           <span>
                              {{ taskExtraInfo.completedInfo.completedByStr }}
                           </span>
                        </div>
                     }
                  </div>
               }
            }
         </div>
      }

      @if (searchHint) {
         <span class="search-hint mobile-search-hint">
            <span [limbleHtml]="searchHint"> </span>
         </span>
      }
      @if (!taskIsCompleted && (duplicateCred || deleteCred) && globalSearch === false) {
         <lim-ui-row-hover-buttons
            class="table-end-of-row-buttons lim-ui-show-on-desktop"
            (clickDelete)="deleteTask()"
            (clickCopy)="duplicate($event)"
            [showDeleteButton]="true"
            [showCopyButton]="true"
            [deleteButtonTooltip]="lang().permDeleteTaskTooltip"
            [copyButtonTooltip]="lang().duplicateOpenTask"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'left'"
            [limUiPopoverHidden]="canDuplicateTasks || !addPartPopoverCheck"
            [limUiPopover]="duplicateTaskDesktopPopover"
         />
      }
      <ng-template #duplicateTaskDesktopPopover>
         <upsell-popover
            [message]="
               woInstructionLimit > 2
                  ? lang().FeatureThreeWOsPopoverMessage
                  : lang().FeatureTwoWOsPopoverMessage
            "
            [pricingLinkText]="lang().LearnMore"
         />
      </ng-template>
   </span>
}
@if (!taskIsCompleted && (duplicateCred || deleteCred) && globalSearch === true) {
   <lim-ui-row-hover-buttons
      class="table-end-of-row-buttons lim-ui-show-on-desktop"
      (clickDelete)="deleteTask()"
      (clickCopy)="duplicate($event)"
      [showDeleteButton]="true"
      [showCopyButton]="true"
      [deleteButtonTooltip]="lang().permDeleteTaskTooltip"
      [copyButtonTooltip]="lang().duplicateOpenTask"
      [limUiPopoverType]="'upsell'"
      [limUiPopoverPlacement]="'left'"
      [limUiPopoverHidden]="canDuplicateTasks || !addPartPopoverCheck"
      [limUiPopover]="duplicateTaskDesktopPopoverTwo"
   />
}
<ng-template #duplicateTaskDesktopPopoverTwo>
   <upsell-popover
      [message]="
         woInstructionLimit > 2
            ? lang().FeatureThreeWOsPopoverMessage
            : lang().FeatureTwoWOsPopoverMessage
      "
      [pricingLinkText]="lang().LearnMore"
   />
</ng-template>
