import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed, output, input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BadgeComponent,
   CheckboxComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   IconComponent,
   LimbleHtmlDirective,
   NumberInputWithButtonsComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   RadioButtonComponent,
   TooltipDirective,
   UpsellPopover,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { IntervalSettingsComponent } from "src/app/schedules/recurrences/pickReoccurModal/interval-settings/interval-settings.component";
import { PickReoccurService } from "src/app/schedules/recurrences/pickReoccurModal/pick-reoccur.service";
import { ThresholdSettingsComponent } from "src/app/schedules/recurrences/pickReoccurModal/threshold-settings/threshold-settings.component";
import { TimeOfDayPicker } from "src/app/shared/components/global/timeOfDayPickerElement/timeOfDayPicker.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { assert } from "src/app/shared/utils/assert.utils";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { PmSuggestionSchedule } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion-schedule/pm-suggestion-schedule";
import { ManageUser } from "src/app/users/services/manageUser";

type reoccurType = "day" | "week" | "month" | "year" | "everyX" | "threshold" | undefined;

@Component({
   selector: "reoccur-options",
   templateUrl: "./reoccur-options.component.html",
   styleUrls: ["./reoccur-options.component.scss"],
   imports: [
      BadgeComponent,
      RadioButtonComponent,
      FormsModule,
      NgClass,
      TimeOfDayPicker,
      CheckboxComponent,
      DropdownComponent,
      LimbleHtmlDirective,
      IconComponent,
      DropdownTextItemComponent,
      TooltipDirective,
      DatePickerInputComponent,
      PrimaryButtonComponent,
      PopoverDirective,
      UpsellPopover,
      NumberInputWithButtonsComponent,
      IntervalSettingsComponent,
      ThresholdSettingsComponent,
      SecondaryButtonComponent,
   ],
   providers: [PickReoccurService],
})
export class ReoccurOptionsComponent implements OnInit, OnDestroy {
   protected recurrence!: Recurrence;
   public monthFlip: "Weekday" | "Numeric" | false = false;
   public yearFlip: "Ordinal" | "Numeric" | false = false;
   public ordinalTranslator: { [key: string]: string } | undefined;
   public dayTranslator: { [key: string]: string } | undefined;
   public dayNumberTranslator: { [key: string]: string } | undefined;
   public dayOptions: string[] | undefined;
   public monthOptions: string[] | undefined;
   public monthTranslator: { [key: string]: string } | undefined;
   public monthOrdinalOptions: string[] | undefined;
   public hours: number[] | undefined;
   public week: { selected: boolean; day: string; num: number }[] | undefined;
   public reoccurType: reoccurType = undefined;
   public timeOfDay: boolean | undefined;
   public hour: string | undefined;
   public minute: string | undefined;
   public AMPM: string | undefined;
   public minDate: Date | undefined;
   public opened: boolean | undefined;
   public openedEnd: boolean | undefined;
   public hasLoaded = false;
   protected readonly i18n = inject(TranslationService).i18n;
   public reoccurID = input<number>();
   public scheduleSuggestion = input<PmSuggestionSchedule>();
   public taskTemplate = input.required<TaskTemplateEntity>();
   protected featureMeterThresholdPM: boolean = false;
   protected reoccurSpawnInMilliseconds: number | null = null;
   protected reoccurSpawnEndInMilliseconds: number | null = null;
   private readonly manageFeatureFlagsSub: Subscription;
   public readonly recurrenceID = output<number>();
   public readonly fieldNotFound = output<boolean>();

   private readonly manageTask = inject(ManageTask);
   public readonly manageAsset = inject(ManageAsset);
   private readonly tasksApiService = inject(TasksApiService);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);
   private readonly pickReoccurService = inject(PickReoccurService);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureMeterThresholdPM = isFeatureEnabledMap.featureMeterThresholdPM;
         },
      );
   }

   public ngOnInit() {
      this.setRecurrence();

      this.initializeDatesForDatePickers();

      this.yearFlip = false;

      this.setReoccurSpawnEndDefault();

      this.initializeTranslators();

      this.initializeSelectListOptions();

      this.initializeMinDate();

      this.initializeAccordingToReoccurType();

      this.initializeTimeOfDayView();

      this.hasLoaded = true;
   }

   private set24hrTimeOfDay(): void {
      this.timeOfDay = false;
      if (this.manageUser.getCurrentUser().userInfo.customerClockFormat === "24-hr") {
         this.hour = "00";
         this.minute = "00";
         this.AMPM = "";
      } else {
         this.hour = "12";
         this.minute = "00";
         this.AMPM = "AM";
      }
   }

   private set12hrTimeOfDay(): void {
      if (Number(this.hour) >= 12) {
         this.AMPM = "PM";
      } else {
         this.AMPM = "AM";
      }
      if (Number(this.hour) > 12) {
         this.hour = `${Number(this.hour) - 12}`;
      }

      if (this.hour === "00") {
         this.hour = "12";
         this.AMPM = "AM";
      }
   }

   private initializeTimeOfDayView(): void {
      if (
         this.recurrence.reoccurTimeOfDay === null ||
         this.recurrence.reoccurTimeOfDay === ""
      ) {
         this.set24hrTimeOfDay();
         return;
      }

      this.timeOfDay = true;

      this.hour = this.recurrence.reoccurTimeOfDay.substring(0, 2);
      this.minute = this.recurrence.reoccurTimeOfDay.substring(2, 4);

      if (this.manageUser.getCurrentUser().userInfo.customerClockFormat === "12-hr") {
         this.set12hrTimeOfDay();
      } else {
         this.AMPM = "";
      }
   }

   private initializeAccordingToReoccurType(): void {
      if (Number(this.recurrence.reoccurType === 1)) {
         this.reoccurType = "day";
      }

      if (Number(this.recurrence.reoccurType === 2)) {
         this.reoccurType = "week";
         this.changeReoccurFld2ToArray();
         this.setSelectedOptionForWeekDropdown();
      }

      if (Number(this.recurrence.reoccurType === 3)) {
         this.reoccurType = "month";
         this.monthFlip = "Numeric";
      }

      if (Number(this.recurrence.reoccurType === 4)) {
         this.reoccurType = "month";
         this.monthFlip = "Weekday";
      }

      if (Number(this.recurrence.reoccurType === 5)) {
         this.reoccurType = "year";
         this.yearFlip = "Numeric";
      }

      if (Number(this.recurrence.reoccurType === 6)) {
         this.reoccurType = "year";
         this.yearFlip = "Ordinal";
      }

      if (Number(this.recurrence.reoccurType === 7)) {
         this.reoccurType = "everyX";
         this.recurrence.reoccurFld2 = Number(this.recurrence.reoccurFld2);
         this.recurrence.reoccurFld3 = Number(this.recurrence.reoccurFld3);
         this.recurrence.reoccurFld4 = Number(this.recurrence.reoccurFld4);
         this.recurrence.reoccurFld5 = Number(this.recurrence.reoccurFld5);
      }

      if (Number(this.recurrence.reoccurType === 8)) {
         this.reoccurType = "threshold";
         this.recurrence.reoccurFld2 = Number(this.recurrence.reoccurFld2);
         this.recurrence.reoccurFld3 = Number(this.recurrence.reoccurFld3);
         this.recurrence.reoccurFld4 = Number(this.recurrence.reoccurFld4);
         this.recurrence.reoccurFld5 = Number(this.recurrence.reoccurFld5);
      }
   }

   private changeReoccurFld2ToArray(): void {
      if (typeof this.recurrence.reoccurFld2 === "string") {
         this.recurrence.reoccurFld2 = this.recurrence.reoccurFld2.split(",");
      }
   }

   private setSelectedOptionForWeekDropdown(): void {
      if (!this.week) {
         return;
      }
      this.week
         .filter((week) => this.recurrence.reoccurFld2.includes(week.num))
         .forEach((week) => {
            week.selected = true;
         });
   }

   private initializeSelectListOptions(): void {
      // allow for select list to use these day options
      this.dayOptions = this.pickReoccurService.getEnglishDayArray();

      // allow for select list to show these months
      this.monthOptions = this.pickReoccurService.getMonthArray();

      // allow for selected list based on these month abbr.
      this.monthOrdinalOptions =
         this.pickReoccurService.getEnglishMonthAbbreviationArray();

      this.week = this.pickReoccurService.getWeekSelectionArray();
   }

   private initializeTranslators(): void {
      // translate 'second' to '2nd'
      this.ordinalTranslator = this.pickReoccurService.getOrdinalTranslator();

      // translate 'mon' to 'Monday'
      this.dayTranslator = this.pickReoccurService.getDayTranslator();

      this.dayNumberTranslator = this.pickReoccurService.getDayNumberTranslator();

      // translate 'jan' to 'January'
      this.monthTranslator = this.pickReoccurService.getMonthTranslator();
   }

   protected updateSpawnStart(selectedDate: Date): void {
      this.reoccurSpawnInMilliseconds = selectedDate.getTime();
   }

   protected updateSpawnEnd(selectedDate: Date): void {
      this.reoccurSpawnEndInMilliseconds = selectedDate.getTime();
   }

   private setReoccurSpawnEndDefault(): void {
      if (
         this.recurrence.reoccurSpawnEnd === 0 ||
         this.pickReoccurService.spawnEndIsInvalid(this.recurrence)
      ) {
         this.reoccurSpawnEndInMilliseconds = null;
      }
   }

   private setRecurrence(): void {
      const reoccurID = this.reoccurID();
      if (!reoccurID) {
         this.clearRecurrence();
         const scheduleSuggestion = this.scheduleSuggestion();
         if (scheduleSuggestion) {
            this.convertScheduleSuggestionDataToRecurrence(scheduleSuggestion);
         }

         return;
      }
      const recurrence = this.manageTask.getRecurrence(reoccurID);
      if (!recurrence) {
         throw new Error("Error. The recurrence you are trying to edit does not exist");
      }
      this.recurrence = recurrence;
   }

   private convertScheduleSuggestionDataToRecurrence(
      scheduleSuggestion: PmSuggestionSchedule,
   ): void {
      const scheduleType = scheduleSuggestion.getType();
      const scheduleUnit = scheduleSuggestion.getUnits();
      const interval = scheduleSuggestion.getInterval();
      if (scheduleType === "metric") {
         //need to find the correct asset field
         if (!this.taskTemplate()?.assetID) {
            this.alertService.addAlert(this.lang().TaskTemplateNotFound, "warning", 6000);
            return;
         }
         const asset = this.manageAsset.getAsset(this.taskTemplate()?.assetID);
         if (!asset) {
            this.alertService.addAlert(this.lang().AssetNotFound, "warning", 6000);
            return;
         }
         const fieldValues: any = [];
         const fields: any = [];
         for (const assetValueID of asset.assetValueIDs) {
            const fieldValue = this.manageAsset.getFieldValue(assetValueID);
            if (fieldValue) {
               fieldValues.push(fieldValue);

               const field = this.manageAsset.getField(fieldValue.fieldID);
               if (field) {
                  fields.push(field);
               }
            }
         }

         const fieldOptionsFromLocation: any = [];
         const fieldOptionsFromAsset: any = [];
         let fieldToUse: any = 0;
         if (fields.length > 0) {
            for (const field of fields) {
               if (
                  field.fieldName === scheduleUnit &&
                  (field.fieldTypeID === AssetFieldTypeID.Number ||
                     field.fieldTypeID === AssetFieldTypeID.Currency)
               ) {
                  fieldToUse = `,${field.fieldName},`;
               } else if (
                  field.fieldTypeID === AssetFieldTypeID.Number ||
                  field.fieldTypeID === AssetFieldTypeID.Currency
               ) {
                  fieldOptionsFromAsset.push(field);
               }
            }
         }

         if (fieldToUse === 0) {
            this.fieldNotFound.emit(true);
            const assetFields = this.manageAsset
               .getFields()
               .filter((field) => asset.locationID === field.locationID);
            for (const field of assetFields) {
               if (
                  field.fieldTypeID === AssetFieldTypeID.Number ||
                  field.fieldTypeID === AssetFieldTypeID.Currency
               ) {
                  fieldOptionsFromLocation.push(field);
               }
            }
         } else {
            this.fieldNotFound.emit(false);
         }

         //this.assetFields =
         this.recurrence = {
            reoccurID: 0,
            reoccurType: 7,
            reoccurFld1: fieldToUse,
            reoccurFld2: interval,
            reoccurFld3: 0,
            reoccurFld4: 1,
            reoccurFld5: 0,
            reoccurSpawn: 0,
            reoccurSpawnEnd: 0,
            reoccurTimeOfDay: null,
            reoccurEndBy: 0,
            reoccurRepetitions: 0,
            valueEnd: 0,
            checklistID: this.taskTemplate().checklistID,
            assetID: this.taskTemplate().assetID ?? null,
            allowDuplicateInstances: 0,
            calculateBasedOnLastCompletedPM: 0,
            skipEveryX: 0,
            calculateBasedOnIncrementOnly: 0,
            scheduleCount: 0,
            reoccurFld6: 0,
            reoccurFld7: 0,
         };
      } else if (scheduleType === "time") {
         const scheduleUnitsConversion = {
            daily: 1,
            weekly: 2,
            monthly: 3, //we are going to default to ordinal options
            yearly: 5,
         };
         this.recurrence = {
            reoccurID: 0,
            reoccurType: scheduleUnitsConversion[scheduleUnit],
            reoccurFld1: interval,
            reoccurFld2: 0,
            reoccurFld3: 0,
            reoccurFld4: 0,
            reoccurFld5: 0,
            reoccurFld6: 0,
            reoccurFld7: 0,
            reoccurSpawn: 0,
            checklistID: this.taskTemplate().checklistID,
            assetID: this.taskTemplate().assetID ?? null,
            allowDuplicateInstances: 0,
            calculateBasedOnLastCompletedPM: 0,
            skipEveryX: 0,
            calculateBasedOnIncrementOnly: 0,
            scheduleCount: 0,
            reoccurSpawnEnd: 0,
            reoccurTimeOfDay: null,
            reoccurEndBy: 0,
            reoccurRepetitions: 0,
            valueEnd: 0,
         };
         this.recurrence = this.setReoccurFldsFromSuggestions(
            this.recurrence,
            scheduleUnitsConversion[scheduleUnit],
         );
      }
   }

   private setReoccurFldsFromSuggestions(
      recurrence: Recurrence,
      reoccurType: number,
   ): Recurrence {
      if (reoccurType === 1) {
         return recurrence;
      }
      let reoccurFld2: string | number = 1;
      if (reoccurType === 2) {
         reoccurFld2 = "1";
      }
      recurrence.reoccurFld2 = reoccurFld2;
      if (reoccurType === 5) {
         recurrence.reoccurFld3 = 1;
      }
      return recurrence;
   }

   private clearRecurrence(): void {
      this.recurrence = {
         reoccurID: 0,
         reoccurType: 0,
         reoccurSpawn: 0,
         reoccurSpawnEnd: 0,
         reoccurTimeOfDay: null,
         reoccurEndBy: 0,
         reoccurRepetitions: 0,
         reoccurFld1: 0,
         reoccurFld2: 0,
         reoccurFld3: 0,
         reoccurFld4: 0,
         reoccurFld5: 0,
         reoccurFld6: 0,
         reoccurFld7: 0,
         checklistID: this.taskTemplate().checklistID,
         assetID: null,
         allowDuplicateInstances: 0,
         calculateBasedOnLastCompletedPM: 0,
         skipEveryX: 0,
         calculateBasedOnIncrementOnly: 0,
         scheduleCount: 0,
         valueEnd: 0,
      };
   }

   private initializeDatesForDatePickers() {
      this.reoccurSpawnInMilliseconds =
         this.manageSchedule.configureReoccurSpawnForDisplay(
            Number(this.recurrence.reoccurSpawn),
         );

      this.reoccurSpawnEndInMilliseconds =
         this.manageSchedule.configureReoccurSpawnEndForDisplay(
            this.recurrence.reoccurSpawnEnd,
         );
   }

   public ngOnDestroy() {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private initializeMinDate() {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDate = this.minDate ? undefined : date;
   }

   public generateSparseArray(num: number): number[] {
      return new Array(num);
   }

   protected toggleWeekdayChecked(day: { selected: boolean; num: number }): void {
      if (day.selected === true) {
         if (!(this.recurrence.reoccurFld2 instanceof Array)) {
            this.recurrence.reoccurFld2 = [];
         }
         this.recurrence.reoccurFld2.push(day.num);
      } else if (day.selected === false) {
         for (const key in this.recurrence.reoccurFld2) {
            if (this.recurrence.reoccurFld2[key] === day.num) {
               this.recurrence.reoccurFld2.splice(key, 1);
            }
         }
      }
   }

   protected resetWeek(): void {
      this.recurrence.reoccurFld1 = 0;
      this.recurrence.reoccurFld2 = [];
   }

   protected resetDayMonthYear(): void {
      this.recurrence.reoccurFld1 = 0;
      this.recurrence.reoccurFld2 = 0;
      this.recurrence.reoccurFld3 = 0;
      this.recurrence.reoccurFld4 = 0;
      this.recurrence.reoccurFld5 = 0;
      if (this.week) {
         for (const week of this.week) {
            week.selected = false;
         }
      }
   }

   public validateReoccurType(): number | undefined {
      const reoccurType = this.pickReoccurService.setReoccurType(
         this.reoccurType,
         this.featureMeterThresholdPM,
         this.monthFlip,
         this.yearFlip,
      );
      if (reoccurType === undefined) {
         this.alertService.addAlert(
            this.lang().PleaseMakeSureYouHaveSelectedARecurrenceAndDate,
            "warning",
            6000,
         );
         return undefined;
      }

      return reoccurType;
   }

   protected setReoccurType(reoccurType: number): void {
      this.recurrence = this.typeAssetFieldRecurrenceFields(reoccurType, this.recurrence);

      this.recurrence.reoccurType = reoccurType;
   }

   protected async submit(): Promise<void> {
      assert(this.taskTemplate().checklistID, "Task template is required");

      const reoccurType = this.validateReoccurType();
      if (reoccurType === undefined) {
         return;
      }
      this.setReoccurType(reoccurType);

      if (
         !this.recurrenceValidation(this.recurrence) ||
         !this.isValidSpawnDate(
            this.reoccurSpawnInMilliseconds,
            Number(this.recurrence.reoccurType),
         )
      ) {
         return;
      }

      this.recurrence.reoccurTimeOfDay = this.pickReoccurService.createTimeOfDay(
         this.timeOfDay,
         this.AMPM ?? "",
         Number(this.hour ?? 0),
         Number(this.minute ?? 0),
      );

      await this.createRecurrence({
         recurrence: this.recurrence,
         reoccurSpawnInMilliseconds: this.reoccurSpawnInMilliseconds,
         reoccurSpawnEndInMilliseconds: this.reoccurSpawnEndInMilliseconds,
      });
   }

   private typeAssetFieldRecurrenceFields(
      reoccurType: number,
      recurrence: Recurrence,
   ): Recurrence {
      if (reoccurType === 7 || reoccurType === 8) {
         recurrence.reoccurFld2 = Number(recurrence.reoccurFld2);
         recurrence.reoccurFld3 = Number(recurrence.reoccurFld3);
         recurrence.reoccurFld4 = true;
      }
      return recurrence;
   }

   public cancel(): void {
      this.recurrenceID.emit(this.recurrence.reoccurID);
   }

   private async createRecurrence(result: {
      recurrence: Recurrence;
      reoccurSpawnInMilliseconds: number | null;
      reoccurSpawnEndInMilliseconds: number | null;
   }): Promise<void> {
      if (result.reoccurSpawnInMilliseconds === null) {
         this.alertService.addAlert(
            this.lang().PleaseSelectARecurrenceDate,
            "warning",
            6000,
         );
         return;
      }
      if (!this.taskTemplate) {
         this.alertService.addAlert(this.lang().TaskTemplateNotFound, "warning", 6000);
         return;
      }
      const task = await this.tasksApiService.getLegacySingleTask(
         this.taskTemplate().checklistID,
      );
      if (!task) {
         this.alertService.addAlert(this.lang().TaskNotFound, "warning", 6000);
         return;
      }
      const response = await this.manageSchedule.saveSchedule(
         result.recurrence,
         task,
         result.reoccurSpawnInMilliseconds,
         result.reoccurSpawnEndInMilliseconds,
      );
      this.manageTask.getRecurrences().set(response.data.rID, response.data.sched);
      this.recurrenceID.emit(response.data.rID);
   }

   protected isValidSpawnDate(
      spawnDateInMilliseconds: number | null,
      reoccurType: number,
   ): boolean {
      const spawnDateIsInThePast = this.manageSchedule.checkIfDateIsInThePast(
         spawnDateInMilliseconds,
      );

      if (spawnDateIsInThePast && reoccurType !== 7 && reoccurType !== 8) {
         this.alertService.addAlert(
            this.lang().DateCannotBeSetInThePast,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected clearEndDate(): void {
      this.reoccurSpawnEndInMilliseconds = null;
   }

   protected setEveryXReoccurType(recurrence: Recurrence): void {
      if (!this.featureMeterThresholdPM) {
         return;
      }
      this.resetDayMonthYear();
      this.setReoccur("everyX");
      recurrence.reoccurFld1 = null;
   }

   protected setThresholdType(reoccur): void {
      if (!this.featureMeterThresholdPM) {
         return;
      }
      this.resetDayMonthYear();
      this.setReoccur("threshold");
      reoccur.reoccurFld1 = null;
   }

   protected setReoccur(newReoccurType: reoccurType): void {
      this.reoccurType = newReoccurType;
   }

   protected setMonthlyReoccurType(type: "Weekday" | "Numeric"): void {
      this.resetDayMonthYear();
      this.monthFlip = type;
      if (type === "Weekday") {
         this.recurrence.reoccurFld1 = 1;
         this.recurrence.reoccurFld2 = "first";
         this.recurrence.reoccurFld3 = "mon";
         return;
      }
      this.recurrence.reoccurFld1 = 1;
      this.recurrence.reoccurFld2 = 1;
   }

   protected setYearlyReoccurType(type: "Ordinal" | "Numeric"): void {
      this.resetDayMonthYear();
      this.yearFlip = type;
      if (type === "Ordinal") {
         this.recurrence.reoccurFld1 = 1;
         this.recurrence.reoccurFld2 = "first";
         this.recurrence.reoccurFld3 = "mon";
         this.recurrence.reoccurFld4 = "jan";
         return;
      }
      this.recurrence.reoccurFld1 = 1;
      this.recurrence.reoccurFld2 = 1;
      this.recurrence.reoccurFld3 = 1;
   }

   protected dailyRecurrenceValidation(recurrence: Recurrence): boolean {
      if (
         Number(recurrence.reoccurFld1) < 1 ||
         Number(recurrence.reoccurFld1) > 9999 ||
         recurrence.reoccurFld1 === undefined
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfDays,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected weeklyRecurrenceValidation(recurrence: Recurrence): boolean {
      if (
         Number(recurrence.reoccurFld1) < 1 ||
         Number(recurrence.reoccurFld1) > 5000 ||
         recurrence.reoccurFld1 === undefined
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfWeeks,
            "warning",
            6000,
         );
         return false;
      }

      if (!(recurrence.reoccurFld2 instanceof Array)) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOneOfTheDaysOfTheWeek,
            "warning",
            6000,
         );
         return false;
      }
      if (recurrence.reoccurFld2.length === 0) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOneOfTheDaysOfTheWeek,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected monthlyNumericRecurrenceValidation(recurrence: Recurrence): boolean {
      if (Number(recurrence.reoccurFld1) < 1 || Number(recurrence.reoccurFld1) > 61) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfMonths,
            "warning",
            6000,
         );
         return false;
      }

      if (Number(recurrence.reoccurFld2) < 1 || Number(recurrence.reoccurFld2) > 31) {
         this.alertService.addAlert(
            this.lang().PleaseSelectDayOfTheMonth,
            "warning",
            6000,
         );
         return false;
      }

      return true;
   }
   protected monthlyOrdinalRecurrenceValidation(recurrence: Recurrence): boolean {
      if (Number(recurrence.reoccurFld1) < 1 || Number(recurrence.reoccurFld1) > 61) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfMonths,
            "warning",
            6000,
         );
         return false;
      }

      if (!this.pickReoccurService.isValidOrdinal(recurrence.reoccurFld2)) {
         this.alertService.addAlert(this.lang().PleaseSelectThe123or4th, "warning", 6000);
         return false;
      }

      if (!this.pickReoccurService.isValidDayOfTheWeek(recurrence.reoccurFld3)) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheDayOfTheWeek,
            "warning",
            6000,
         );
         return false;
      }

      return true;
   }

   protected yearlyNumericRecurrenceValidation(recurrence: Recurrence): boolean {
      if (Number(recurrence.reoccurFld1) < 1 || Number(recurrence.reoccurFld1) > 20) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfYears,
            "warning",
            6000,
         );
         return false;
      }

      if (Number(recurrence.reoccurFld2) < 1 || Number(recurrence.reoccurFld2) > 12) {
         this.alertService.addAlert(this.lang().PleaseSelectWhichMonth, "warning", 6000);
         return false;
      }

      if (Number(recurrence.reoccurFld3) < 1 || Number(recurrence.reoccurFld3) > 31) {
         this.alertService.addAlert(this.lang().PleaseSelectWhichDay, "warning", 6000);
         return false;
      }

      return true;
   }

   protected yearlyOrdinalRecurrenceValidation(recurrence: Recurrence): boolean {
      if (Number(recurrence.reoccurFld1) < 1 || Number(recurrence.reoccurFld1) > 20) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheNumberOfYears,
            "warning",
            6000,
         );
         return false;
      }

      if (!this.pickReoccurService.isValidOrdinal(recurrence.reoccurFld2)) {
         this.alertService.addAlert(this.lang().PleaseSelectThe123or4th, "warning", 6000);
         return false;
      }

      if (!this.pickReoccurService.isValidDayOfTheWeek(recurrence.reoccurFld3)) {
         this.alertService.addAlert(
            this.lang().PleaseSelectTheDayOfTheWeek,
            "warning",
            6000,
         );
         return false;
      }

      if (!this.pickReoccurService.isValidMonthAbbreviation(recurrence.reoccurFld4)) {
         this.alertService.addAlert(this.lang().PleaseSelectWhichMonth, "warning", 6000);
         return false;
      }

      return true;
   }

   protected thresholdRecurrenceValidation(recurrence: Recurrence): boolean {
      if (
         typeof recurrence.reoccurFld1 !== "string" ||
         !recurrence.reoccurFld1.split(",").every((id) => Number(id) > 0)
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectWhichFieldYouWishToBaseThisOffOf,
            "warning",
            6000,
         );
         return false;
      }

      //if it is a between choice then it has to be a valid one.  E.G between 1 and 10 and NOT between 10 and 1.
      if (
         recurrence.reoccurFld3 === 2 &&
         Number(recurrence.reoccurFld2) > Number(recurrence.reoccurFld5)
      ) {
         this.alertService.addAlert(
            this.lang().ThisBetweenConditionWillNeverBeMet,
            "warning",
            6000,
         );
         return false;
      }

      return true;
   }

   protected everyXRecurrenceValidation(recurrence: Recurrence): boolean {
      if (
         typeof recurrence.reoccurFld1 !== "string" ||
         !recurrence.reoccurFld1.split(",").every((id) => Number(id) > 0)
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectWhichFieldYouWishToBaseThisOffOf,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected flipAndTypeMatch(): boolean {
      if (this.reoccurType === "month" && this.monthFlip === false) {
         this.alertService.addAlert(
            this.lang().PleaseSelectEitherWeekdayOrNumericValue,
            "warning",
            6000,
         );
         return false;
      }
      if (this.reoccurType === "year" && this.yearFlip === false) {
         this.alertService.addAlert(
            this.lang().PleaseSelectEitherNumericOrOrdinal,
            "warning",
            6000,
         );
         return false;
      }
      return true;
   }

   protected recurrenceValidation(recurrence: Recurrence): boolean {
      if (!this.flipAndTypeMatch()) {
         return false;
      }
      switch (Number(recurrence.reoccurType)) {
         case 1:
            if (!this.dailyRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 2:
            if (!this.weeklyRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 3:
            if (!this.monthlyNumericRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 4:
            if (!this.monthlyOrdinalRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 5:
            if (!this.yearlyNumericRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 6:
            if (!this.yearlyOrdinalRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 7:
            if (!this.everyXRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         case 8:
            if (!this.thresholdRecurrenceValidation(recurrence)) {
               return false;
            }
            break;
         default:
            this.alertService.addAlert(
               this.lang().PleaseMakeSureYouHaveSelectedARecurrenceAndDate,
               "warning",
               6000,
            );
            return false;
      }
      return true;
   }
}
