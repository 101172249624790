@if (isUnitOfMeasureVisible()) {
   <lim-ui-panel [table]="true" [noSidePadding]="true">
      <div class="part-purchase-orders-panel-header">
         <span class="text-with-button">
            <span class="panel-text">
               <lim-ui-icon icon="box" />
               {{ i18n().t("Purchasables") }}
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  [limUiTooltip]="i18n().t('PurchasableToolTip')"
               />
            </span>
            <span>
               @if (canEditPurchasable && purchasables.size > 0) {
                  <lim-ui-secondary-button
                     icon="plus"
                     (click)="createPurchasable()"
                     [dataLogLabel]="dataLogLabelAddButton"
                  >
                     {{ i18n().t("AddAPurchasable") }}
                  </lim-ui-secondary-button>
               }
            </span>
         </span>
         <lim-ui-search-all-wrapper>
            <lim-ui-search-box
               searchBox
               [(searchVal)]="searchTerm"
               (searchValChange)="updateSearchTerm(searchTerm())"
               [placeholder]="i18n().t('Search')"
            />
         </lim-ui-search-all-wrapper>
      </div>
      @if (purchasables.size === 0) {
         <div class="no-purchasables">
            @if (canEditPurchasable) {
               <empty-here
                  [customText]="i18n().t('PurchasablesDefinition')"
                  [addText]="i18n().t('AddAPurchasable')"
                  (emptyHereAddButtonClicked)="createPurchasable()"
                  [addButtonSecondary]="true"
                  [showDefaultSubtitle]="false"
                  [dataLogAddButtonLabel]="dataLogLabelAddButton"
               />
            } @else {
               <empty-here
                  [customText]="
                     i18n().t('PurchasablesDefinition') +
                     ' ' +
                     i18n().t('WhoCanManagePurchasablesAnswer')
                  "
                  [showDefaultSubtitle]="false"
                  [showAddButton]="false"
               />
            }
         </div>
      } @else {
         <div class="listTable lim-ui-responsive-table scroll-height-inheritance">
            @if (!noSearchResults) {
               <div class="listItem listItemHeader lim-ui-responsive-table-header">
                  <sort-column_refactor
                     class="cell-container name-column col-md-2 column-header"
                     [options]="sortColumnInfo.name"
                  />
                  <sort-column_refactor
                     class="cell-container order-unit-column column-header"
                     [options]="sortColumnInfo.orderUnitDisplay"
                  />
                  <sort-column_refactor
                     class="cell-container size-column column-header"
                     [options]="sortColumnInfo.purchasableSize"
                  />
                  <span
                     class="cell-container lim-ui-hide-on-desktop actions-column column-header"
                  >
                  </span>
               </div>
            } @else {
               <span>
                  <no-search-results />
               </span>
            }

            @for (
               item of purchasables
                  | sliceLimbleMap: (page - 1) * itemsPerPage : page * itemsPerPage;
               track item
            ) {
               <div class="listItem lim-ui-responsive-table-row purchasables-container">
                  <div class="cell-container name-column col-md-2 mobile-grid-container">
                     <div class="mobile-name-row">
                        <span class="lim-ui-show-on-mobile mobile-column-header"
                           >{{ i18n().t("Name") }}:
                        </span>
                        {{ item.purchasable.name() }}
                     </div>
                     <div class="mobile-action-menu">
                        @if (canEditPurchasable) {
                           <lim-ui-dropdown
                              class="lim-ui-hide-on-desktop button-menu"
                              (click)="dropdownClicked = true"
                           >
                              <lim-ui-minimal-icon-button
                                 id="mobile-widget-buttons"
                                 button
                                 icon="ellipsisVertical"
                              />
                              <div menu aria-labelledby="mobile-widget-buttons">
                                 <lim-ui-dropdown-item
                                    dataLogLabel="manageUnitsOfMeasure_initiateEditAPurchasable"
                                    (click)="editPurchasable(item)"
                                 >
                                    <lim-ui-icon icon="editRegular" />
                                    <span class="action-label">
                                       {{ i18n().t("EditPurchasable") }}</span
                                    >
                                 </lim-ui-dropdown-item>
                                 <lim-ui-dropdown-divider />
                                 <lim-ui-dropdown-item
                                    dataLogLabel="manageUnitsOfMeasure_initiateCopyAPurchasable"
                                    (click)="copyPurchasable(item)"
                                 >
                                    <lim-ui-icon icon="cloneRegular" />
                                    <span class="action-label">{{
                                       i18n().t("CopyThisPurchasable")
                                    }}</span>
                                 </lim-ui-dropdown-item>
                                 <lim-ui-dropdown-divider />
                                 <lim-ui-dropdown-item (click)="deletePurchasable(item)">
                                    <lim-ui-icon
                                       icon="trashCanRegular"
                                       iconColor="danger"
                                    />
                                    <span class="action-label">{{
                                       i18n().t("DeletePurchasable")
                                    }}</span>
                                 </lim-ui-dropdown-item>
                              </div>
                           </lim-ui-dropdown>
                        }
                     </div>
                  </div>

                  <div
                     class="cell-container order-unit-column col-md-2 mobile-order-unit-row"
                  >
                     <span class="lim-ui-show-on-mobile mobile-column-header"
                        >{{ i18n().t("PurchasableOrderUnit") }}:
                     </span>
                     <unit-label
                        [unitDisplay]="{
                           name: item.orderUnit.singular(),
                           abbreviation: item.orderUnit.short(),
                        }"
                     />
                  </div>

                  <div class="cell-container size-column col-md-2 mobile-size-row">
                     <span class="lim-ui-show-on-mobile mobile-column-header"
                        >{{ i18n().t("PurchasableSize") }}:
                     </span>
                     <unit-conversion
                        [unitDisplay]="{
                           name: item.sizeUnit()?.singular() ?? '',
                           abbreviation: item.sizeUnit()?.short() ?? '',
                        }"
                        [stockUnits]="item.purchasableSize"
                     />
                     @if (item.showStockUnitsEnclosure) {
                        <span>&nbsp;</span>
                        <unit-conversion
                           [unitDisplay]="{
                              name: stockUoM()?.singular() ?? '',
                              abbreviation: stockUoM()?.short() ?? '',
                           }"
                           [stockUnits]="item.stockUnits() ?? 0"
                           [enclosed]="true"
                        />
                     }
                  </div>
                  <div
                     class="cell-container lim-ui-hide-on-mobile actions-column col-md-2 purchasables-list-item-hover-buttons"
                  >
                     @if (canEditPurchasable) {
                        <lim-ui-icon
                           icon="editRegular"
                           iconSize="small"
                           placement="left"
                           dataLogLabel="manageUnitsOfMeasure_initiateEditAPurchasable"
                           (click)="editPurchasable(item)"
                           [limUiTooltip]="i18n().t('EditPurchasable')"
                        />
                        <lim-ui-icon
                           icon="cloneRegular"
                           iconSize="small"
                           placement="left"
                           dataLogLabel="manageUnitsOfMeasure_initiateCopyAPurchasable"
                           (click)="copyPurchasable(item)"
                           [limUiTooltip]="i18n().t('CopyThisPurchasable')"
                        />
                     }
                     @if (canDeletePurchasable) {
                        <lim-ui-icon
                           icon="trashCanRegular"
                           iconSize="small"
                           iconColor="danger"
                           placement="left"
                           (click)="deletePurchasable(item)"
                           [limUiTooltip]="i18n().t('DeletePurchasable')"
                        />
                     }
                  </div>
               </div>
            }
         </div>

         <div class="pagination-container">
            <lim-ui-pagination
               [maxSize]="5"
               [rotate]="true"
               [(page)]="page"
               [pageSize]="itemsPerPage"
               [collectionSize]="purchasables.size"
            />
            <lim-ui-dropdown-results-per-page
               [selectedValue]="itemsPerPage"
               [label]="i18n().t('itemsPerPage')"
            >
               <ng-container menu>
                  @for (num of counter; track num) {
                     <lim-ui-dropdown-text-item
                        [displayString]="num.toString()"
                        [outputString]="num.toString()"
                        (itemSelectedEvent)="updatePaginationPreferences(num)"
                     />
                  }
               </ng-container>
            </lim-ui-dropdown-results-per-page>
         </div>
      }
   </lim-ui-panel>
}
