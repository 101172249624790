import { NgClass, NgStyle } from "@angular/common";
import type { DoCheck, OnDestroy, OnInit } from "@angular/core";
import { inject, Component, EventEmitter, Input, Output, computed } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   DropdownResultsPerPageComponent,
   DropdownTextItemComponent,
   LoadingAnimationComponent,
   PaginationComponent,
   ScrollContainerComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { filter, map } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import type { Comparator } from "src/app/shared/utils/sortingHelpers";
import { DEFAULT, REVERSE } from "src/app/shared/utils/sortingHelpers";
import { TaskItem } from "src/app/tasks/components/taskItemElement/taskItem.element.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

type taskColumnSortOptions =
   | "checklistName"
   | "checklistDueDate"
   | "assignment"
   | "checklistCompletedDate"
   | "checklistPromptTimeTotal"
   | "completedByStr"
   | "checklistTotalPartsCost"
   | "checklistTotalInvoiceCost"
   | "checklistTotalOperatingCost"
   | "priorityLevel"
   | "statusName"
   | "checklistLastEdited"
   | "unreadComments"
   | "assetName"
   | "typeIcon";

type AugmentedTask = Task & {
   assignment: string;
   checklistPromptTimeTotal: number;
   completedByStr: string;
   checklistTotalPartsCost: number;
   checklistTotalInvoiceCost: number;
   checklistTotalOperatingCost: number;
   priorityLevel: number;
   statusName: string;
   unreadComments: number;
   assetName: string;
   typeIcon: string;
};

@Component({
   selector: "view-list-of-tasks",
   templateUrl: "./viewListOfTasks.element.component.html",
   styleUrls: ["./viewListOfTasks.element.component.scss"],
   imports: [
      NgClass,
      SortColumn,
      TooltipDirective,
      NgStyle,
      ScrollContainerComponent,
      NoSearchResults,
      TaskItem,
      PaginationComponent,
      DropdownResultsPerPageComponent,
      DropdownTextItemComponent,
      LoadingAnimationComponent,
      NgxSkeletonLoaderModule,
   ],
})
export class ViewListOfTasks implements OnInit, OnDestroy, DoCheck {
   @Input() public taskIDs: Array<number> | undefined; // List of tasks to be shown
   @Input() public columns; // List of columns to be shown
   @Input() public loadingBar; // Whether or not to show the loading bar
   @Input() public noSearchResults; // Whether or not to show the noSearchResults section
   @Input() public sortFn; // Function that's run on column sort
   @Input() public storedSortBindValue: taskColumnSortOptions = "checklistName"; // Property to sort by
   @Input() public paginationLocation; // Location of pagination - top, bottom, or both
   @Input() public noHeaders; // If true, no column headers will be displayed
   @Input() public isModal = false; // If true, styling of nested list-item fields will be more compact
   @Input() public autoHeight; // If true, table height will be auto, not 100vh
   @Input() public tableInScrollablePage = false;
   @Input() public searchHints: LimbleMap<number, string> = new LimbleMap();
   @Input() public dataLogSection: string | undefined;
   @Input() public isTaskNameIncludingIdAndAssetName = true;
   @Output() readonly tasksInViewChanged: EventEmitter<{
      page: number;
      size: number;
      tasksInView: Array<number>;
   }>;

   public pageTasks: any = 1;
   public itemsPerPage;
   public isLoaded;
   public isLoadingTaskNotes = false;
   public skeletonThemes;
   public tasks: Lookup<"checklistID", AugmentedTask> = new Lookup("checklistID");
   public tasksInView: Array<Task> = [];
   public taskIDsForChangeDetection: Array<number> = [];
   private readonly tasksWatchVarSub;
   private readonly completedTasksWatchVarSub;
   private comparator: Comparator = DEFAULT;

   private readonly manageFilters = inject(ManageFilters);
   private readonly manageTask = inject(ManageTask);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageUser = inject(ManageUser);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageAsset = inject(ManageAsset);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly manageLang = inject(ManageLang);
   private readonly refreshService = inject(RefreshService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();
      this.tasksInViewChanged = new EventEmitter();
      this.fetchTaskNotesJITPhase1();

      this.tasksWatchVarSub = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.setTasksInView();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );

      this.completedTasksWatchVarSub = this.manageObservables.setSubscription(
         "completedTasksWatchVar",
         () => {
            this.setTasksInView();
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );
   }

   public ngOnInit() {
      this.isLoaded = false;
      if (this.taskIDs === undefined) {
         this.taskIDs = [];
      }
      this.setTaskIDsForChangeDetection();

      // Set some defaults if some things aren't passed in
      if (this.noSearchResults === undefined) {
         this.noSearchResults = false;
      }

      if (this.loadingBar === undefined) {
         this.loadingBar = false;
      }

      if (this.paginationLocation === undefined) {
         this.paginationLocation = "bottom";
      }

      this.manageUser.currentUserInitialized$.subscribe(() => {
         this.itemsPerPage =
            this.manageUser.getCurrentUser()?.userInfo.userUIPreferences.itemsPerPage ??
            20;
         const taskIDLength = this.taskIDs?.length ?? 0;
         this.pageTasks = this.manageFilters.checkPagination(
            this.pageTasks,
            this.itemsPerPage,
            taskIDLength,
         );
         this.setTasksInView();
         this.isLoaded = true;
      });
   }

   public ngDoCheck(): void {
      if (this.detectChanges()) {
         this.setTasksInView();
         this.setTaskIDsForChangeDetection();
      }
   }

   private detectChanges(): boolean {
      // A simple ID comparison should be all we need in this case to determine whether we
      // need to re-render the list. Any other pertinent changes in the tasks should be
      // detected by the template bindings.
      const taskIDs: Array<number> = this.taskIDs ?? [];
      const shouldReRender = !this.simpleIdCompare(
         taskIDs,
         this.taskIDsForChangeDetection,
      );

      if (shouldReRender) this.pageTasks = 1;

      return shouldReRender;
   }

   private setTaskIDsForChangeDetection(): void {
      this.taskIDsForChangeDetection = this.taskIDs ?? [];
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.completedTasksWatchVarSub,
         this.tasksWatchVarSub,
      ]);
   }

   private setTasksInView() {
      this.tasks = new Lookup("checklistID");
      if (!this.taskIDs) return;
      for (const taskID of this.taskIDs) {
         const task = this.manageTask.getTaskLocalLookup(taskID);
         if (task) {
            this.tasks.set(task.checklistID, this.buildSortableTask(task));
         }
      }
      this.sortTasks(this.tasks, this.storedSortBindValue);
   }

   protected sortTasks(
      tasks: Lookup<"checklistID", AugmentedTask>,
      newBind: taskColumnSortOptions,
   ) {
      let bind: taskColumnSortOptions = "checklistDueDate";
      if (newBind?.startsWith("-")) {
         bind = newBind.slice(1) as taskColumnSortOptions;
         this.comparator = REVERSE;
      } else {
         bind = newBind;
         this.comparator = DEFAULT;
      }
      this.tasksInView = Array.from(tasks.orderBy(bind, this.comparator)).slice(
         (this.pageTasks - 1) * this.itemsPerPage,
         this.pageTasks * this.itemsPerPage,
      );

      this.tasksInViewChanged.emit({
         page: this.pageTasks,
         size: this.itemsPerPage,
         tasksInView: this.tasksInView.map((task) => task.checklistID),
      });
   }

   protected pageNumberChanged(newPage: number) {
      this.pageTasks = newPage;
      this.setTasksInView();
   }

   public updateUIPref(): void {
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage =
         this.itemsPerPage;
      this.setTasksInView();
      this.manageUser.updateUserUIPreferences();
   }

   protected bindChanged(newBind: taskColumnSortOptions): void {
      this.storedSortBindValue = newBind;
      this.sortTasks(this.tasks, newBind);
   }

   private simpleIdCompare(
      newTaskIDs: Array<number>,
      oldTaskIDs: Array<number>,
   ): boolean {
      if (newTaskIDs.length !== oldTaskIDs.length) return false;
      return newTaskIDs.every(
         (value: number, index: number) => newTaskIDs.at(index) === oldTaskIDs.at(index),
      );
   }

   public updateItemsPerPage(newValue: string) {
      this.itemsPerPage = Number(newValue);
      this.pageTasks = 1;

      this.updateUIPref();
   }

   private buildSortableTask(task: Task): AugmentedTask {
      const {
         checklistPromptTimeTotal,
         checklistTotalPartsCost,
         checklistTotalInvoiceCost,
         checklistTotalOperatingCost,
      } = this.manageTask.getCalculatedTaskInfo(task);
      const completionInfo = this.manageTask.getCompletedTaskCalculatedInfo(task);
      const { assignment } = this.manageTask.getTaskAssignmentInfo(task);
      const statusInfo = this.manageTask.getStatusInfo(task.statusID);
      const priorityInfo = this.manageTask.getPriorityInfo(task.priorityID);
      const commentsInfo = this.manageTask.getTaskCommentsInfo(task);
      const asset = this.manageAsset.getAsset(task.assetID ?? -1);
      const typeIcon = this.taskTypeService.getTaskTypeIcon(task);
      return {
         ...task,
         assignment,
         checklistPromptTimeTotal,
         checklistTotalPartsCost,
         checklistTotalInvoiceCost,
         checklistTotalOperatingCost,
         completedByStr: completionInfo?.completedByStr ?? "",
         statusName: statusInfo?.statusName ?? "",
         priorityLevel: priorityInfo.priorityLevel ?? "",
         unreadComments: commentsInfo?.unreadComments,
         assetName: asset?.assetName ?? "",
         typeIcon,
      };
   }

   /**
    * This is needed for JIT Task Notes Phase 1 -
    * once all usages of viewListOfTasksElement is converted to JIT tasks that includes comments
    * then we can remove this (once phase 3 is completed)
    */
   private fetchTaskNotesJITPhase1(): void {
      this.tasksInViewChanged
         .pipe(
            takeUntilDestroyed(),
            filter((tasksInViewChange) => tasksInViewChange.tasksInView?.length > 0),
            map((tasksInViewChange) => tasksInViewChange),
         )
         .subscribe(
            (tasksInViewChange: {
               page: number;
               size: number;
               tasksInView: Array<number>;
            }) => {
               this.isLoadingTaskNotes = true;
               this.manageTask
                  .fetchNotes({ checklistIDs: tasksInViewChange.tasksInView })
                  .then()
                  .finally(() => {
                     this.isLoadingTaskNotes = false;
                  });
            },
         );
   }
}
