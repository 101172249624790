import { inject, Injectable } from "@angular/core";
import type { WorkRequestSubmissionEntity } from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.models";
import type { WorkRequestSubmissionViewModel } from "src/app/tasks/components/workRequests/work-request-submission-modal/work-request-submission-data-viewer.model";
import { WorkRequestSubmissionViewModelBuilderService } from "src/app/tasks/services/work-request-submission-view-model-factory/builders/work-request-submission-view-model-builder.service";

@Injectable({
   providedIn: "root",
})
export class WorkRequestSubmissionViewModelFactoryService {
   private readonly workRequestSubmissionViewModelBuilderService = inject(
      WorkRequestSubmissionViewModelBuilderService,
   );

   public getWorkRequestSubmissionViewModel(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): WorkRequestSubmissionViewModel {
      return this.workRequestSubmissionViewModelBuilderService.buildWorkRequestSubmissionViewModel(
         workRequestSubmission,
      );
   }
}
