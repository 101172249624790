import { inject, Injectable } from "@angular/core";
import {
   injectQuery,
   type CreateQueryResult,
} from "@tanstack/angular-query-experimental";
import { firstValueFrom, of, type Observable } from "rxjs";
import {
   FlannelApiService,
   type ListResponse,
   type RequestFilter,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type { TaskStatusEntity } from "src/app/tasks/components/shared/services/task-statuses-api/task-status-api.models";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTask } from "src/app/tasks/services/manageTask";

@Injectable({
   providedIn: "root",
})
export class TaskStatusApiService extends FlannelApiService<TaskStatusEntity> {
   private readonly manageStatus = inject(ManageStatus);
   private readonly manageTask = inject(ManageTask);
   public constructor() {
      super("task-statuses");
   }

   /**
    * Get task statuses using the query API
    * This is a custom implementation that will be replaced when the API is ready
    */
   public queryList(): CreateQueryResult<TaskStatusEntity[], Error> {
      return injectQuery(() => ({
         queryKey: this.keys({}).list,
         queryFn: async () => firstValueFrom(this.getStatuses()),
         // NOTE: 1 minute, since the statuses are not expected to change
         // and we don't want to hammer the API when loading tables that
         // use this data
         staleTime: 1000 * 60,
      }));
   }

   /**
    * Get statuses from the legacy API
    * NOTE: This is a temporary function that should be removed once
    * the task statuses are migrated to the new API.
    */
   private getStatuses(): Observable<TaskStatusEntity[]> {
      const statuses = this.manageStatus.getStatusList().map((status) => {
         const info = this.manageTask.getStatusInfo(status.statusID);
         return {
            ...status,
            name: info?.statusName,
            abbreviation: info?.statusAbbr,
         };
      });
      return of(statuses);
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   // NOTE2: This method does not work as expected. It creates an infinite request loop.
   // It is kept here for future reference.
   // public override getQueryList(
   //    _options?: Partial<RequestOptions<RequestFilter>>,
   // ): Observable<any> {
   //    throw new Error("Method not implemented.");
   // }

   /*  eslint-disable typescript/no-unused-vars -- The variables are needed to override */
   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override getList(
      _requestOptions?: Partial<RequestOptions<RequestFilter>>,
   ): Observable<Partial<ListResponse<TaskStatusEntity>>> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override getById(
      _id: number,
      _requestOptions?: Partial<RequestOptions<RequestFilter>>,
   ): Observable<TaskStatusEntity> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override create(
      _body: Partial<TaskStatusEntity>,
      _requestOptions?: Partial<RequestOptions<RequestFilter>>,
   ): Observable<TaskStatusEntity | null> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override update(
      _id: number,
      _body: Partial<TaskStatusEntity>,
      _requestOptions?: Partial<RequestOptions<RequestFilter>>,
   ): Observable<TaskStatusEntity> {
      throw new Error("Method not implemented.");
   }

   // NOTE: This method will be completed removed once it is implemented in the JIT API
   public override delete(
      _id: number,
      _requestOptions?: Partial<RequestOptions<RequestFilter>>,
   ): Observable<TaskStatusEntity> {
      throw new Error("Method not implemented.");
   }

   /* eslint-enable typescript/no-unused-vars */
}
