import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
   model,
} from "@angular/core";
import {
   DropdownResultsPerPageComponent,
   DropdownTextItemComponent,
   PaginationComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "data-viewer-paginator",
   imports: [
      DropdownResultsPerPageComponent,
      PaginationComponent,
      DropdownTextItemComponent,
   ],
   templateUrl: "./data-viewer-paginator.component.html",
   styleUrls: ["./data-viewer-paginator.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataViewerPaginatorComponent {
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public totalItems = input.required<number>();
   public areResultsPerPageEnabled = input<boolean>(true);

   readonly page = model<number>(0);
   readonly pageSize = model<number>(0);

   public onPageSizeChange(size: string) {
      const pageSize = Number(size);
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage = pageSize;
      this.manageUser.updateUserUIPreferences();
      this.pageSize.set(pageSize);
      this.page.set(1);
   }

   public onPageNumberChange(newPage: number) {
      this.page.set(newPage);
   }
}
