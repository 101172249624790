@if (item.itemFileName || item.taskImage) {
   <div class="file-row-spacing">
      @for (image of item.instructionalImages; track image) {
         <div [ngClass]="{ greyoutChkItem: item.greyOut }">
            <view-image [src]="image.src" />
         </div>
      }
      @if (item.instructionalFiles?.length > 0) {
         <div class="file-row-spacing">
            @for (file of item.instructionalFiles; track file) {
               <div>
                  <file-list-item
                     [file]="file"
                     url="viewFile.php?f=upload-{{ customerID }}/items/{{
                        item.itemBatchID
                     }}/{{ file.itemFileName }}"
                  />
               </div>
            }
         </div>
      }
   </div>
}
