import { computed, inject, Injectable } from "@angular/core";
import { TranslationService } from "src/app/languages/translation/translation.service";
import type { DayOptions } from "src/app/parts/types/threshold-task/threshold-task.types";

@Injectable({ providedIn: "root" })
export class PartsSettingsService {
   private readonly i18n = inject(TranslationService).i18n;

   public createDayOptions(): DayOptions {
      return [
         { days: 1, display: computed(() => `1 ${this.i18n().t("day")}`) },
         { days: 2, display: computed(() => `2 ${this.i18n().t("days")}`) },
         { days: 3, display: computed(() => `3 ${this.i18n().t("days")}`) },
         { days: 7, display: computed(() => `7 ${this.i18n().t("days")}`) },
         { days: 30, display: computed(() => `30 ${this.i18n().t("days")}`) },
      ];
   }

   public setMinThresholdTaskDueDaysString(value: number): string {
      if (value === 0) {
         return this.i18n().t("TheSameDay");
      }
      if (value === 1) {
         return `1 ${this.i18n().t("day")} ${this.i18n().t("after")}`;
      }
      if (value > 1) {
         return `${value} ${this.i18n().t("days")} ${this.i18n().t("after")}`;
      }

      return "";
   }
}
