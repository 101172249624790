@if (template()) {
   <div class="lim-ui-responsive-table-row-wrapper">
      <div class="list-item-wrapper lim-ui-responsive-table-row">
         <!-- The template() name -->
         <div
            [ngClass]="{ 'col-md-4': !showAssetName, 'col-md-2': showAssetName }"
            class="column-container lim-ui-responsive-table-cell"
         >
            <div class="task-info-container lim-ui-mobile-table-header">
               <span
                  (click)="openPMTemplate(template())"
                  class="column-sub-container clickable task-name-container"
               >
                  @if (template().checklistTemplate === 1) {
                     <lim-ui-icon
                        limUiTooltip="{{ lang().ThisIsAPMTemplate }}"
                        placement="right"
                        icon="wrench"
                     />
                  }

                  <span class="task-name" [limbleHtml]="template().checklistName"></span>
               </span>
            </div>

            @if (searchHint) {
               <span class="search-hint lim-ui-show-on-desktop" [limbleHtml]="searchHint">
               </span>
            }
         </div>

         <!-- Asset name -->
         @if (showAssetName) {
            <div class="col-md-2 column-container lim-ui-responsive-table-cell">
               @if (template().assets.length > 0) {
                  @if (template().assets; as assets) {
                     <span class="column-sub-container asset-info-container">
                        @if (template().assets.length > 1) {
                           <lim-ui-minimal-icon-button
                              limUiTooltip="{{ lang().primaryAssetForPMTemplateTooltip }}"
                              [superMinimal]="true"
                              [icon]="showExtraAssets() ? 'angleUp' : 'angleDown'"
                              (click)="showExtraAssets.set(!showExtraAssets())"
                              [darkHoverBackground]="true"
                              class="extra-assets-button"
                           />
                        }

                        <span class="all-asset-wrapper">
                           @if (assets[0]) {
                              <span class="single-asset-wrapper">
                                 <asset-hierarchy-list-item
                                    [showIcon]="true"
                                    [asset]="assets[0]"
                                 />
                              </span>
                           }

                           @if (showExtraAssets()) {
                              @for (
                                 asset of assets | slice: 1 | orderBy: "assetName";
                                 track asset.assetID
                              ) {
                                 <span class="extra-assets-asset-wrapper">
                                    <asset-hierarchy-list-item
                                       [showIcon]="true"
                                       [asset]="asset"
                                    />
                                 </span>
                              }
                           }
                        </span>

                        <lim-ui-row-hover-buttons
                           class="lim-ui-show-on-desktop"
                           [showEditButton]="true"
                           [showCopyButton]="false"
                           [showDeleteButton]="true"
                           [editButtonTooltip]="lang().ChangeWhichAssetThisPMBelongsTo"
                           [deleteButtonTooltip]="lang().RemoveThisAssetFromThisPM"
                           (clickEdit)="changeAssetOnTask()"
                           (clickDelete)="removeAssetFromPM(template())"
                        />
                     </span>
                  }
               }

               <!-- Add asset icon -->
               @if (template().assetID === 0) {
                  <span class="add-asset-container">
                     <lim-ui-icon
                        class="lim-ui-mobile-table-column-label"
                        icon="cube"
                        iconColor="standard"
                     />
                     <lim-ui-minimal-icon-button
                        class="add-asset-button"
                        icon="plus"
                        iconColor="danger"
                        (click)="changeAssetOnTask()"
                        limUiTooltip="{{ lang().AddThisPMToAnAsset }}"
                        placement="bottom"
                        [superMinimal]="true"
                        [darkHoverBackground]="true"
                     />
                  </span>
               }
            </div>
         }

         <!-- Schedule -->
         <div
            class="col-md-4 column-container lim-ui-responsive-table-cell"
            [ngClass]="{ clickable: !restrictBasedOnAsset }"
            (click)="openPopRecurrencesModal()"
         >
            @if (!restrictBasedOnAsset && template().recurrences?.length === 0) {
               <lim-ui-icon
                  icon="calendarXmark"
                  iconColor="danger"
                  limUiTooltip="{{ lang().SetTheSchedulesForThisPM }}"
                  placement="top"
               />
            }
            <div class="recurrence-list">
               @for (recurrence of template().recurrences; track recurrence.reoccurID) {
                  <recurrence-list-item
                     [recurrence]="recurrence"
                     [template]="template()"
                     [restrict]="restrict"
                     [showExtraAssets]="showExtraAssets()"
                     data-test-id="recurrenceListItem"
                  />
               }
            </div>
         </div>

         <!-- Assigned to -->
         <div class="col-md-2 column-container lim-ui-responsive-table-cell">
            <lim-ui-icon class="lim-ui-mobile-table-column-label" icon="user" />

            @if (!restrictBasedOnAsset && template().checklistTemplate === 1) {
               <span>
                  <span class="clickable text blue" (click)="changeSchedule(template())">
                     @if (!template().dynamicAssignment) {
                        <span [limbleHtml]="assignment()"> </span>
                     }
                     @if (template().dynamicAssignment) {
                        <span>
                           {{ lang().LastUserWhoEnteredMeterReading }}
                        </span>
                     }
                  </span>
               </span>
            }

            @if (restrictBasedOnAsset) {
               <span class="clickable text blue">
                  <span [limbleHtml]="assignment()"> </span>
               </span>
            }
         </div>

         <!-- The buttons container -->
         <div class="col-md-2 listLastItem options-container lim-ui-show-on-desktop">
            <lim-ui-options-dropdown
               [buttonText]="lang().Options"
               [options]="getDropdownOptions()"
            />
         </div>

         <!-- The mobile search hint -->
         @if (searchHint) {
            <span class="search-hint lim-ui-hide-on-desktop" [limbleHtml]="searchHint">
            </span>
         }
      </div>

      <!-- Menu button -->
      @if (!restrictBasedOnAsset) {
         <lim-ui-dropdown class="lim-ui-hide-on-desktop button-menu">
            <lim-ui-minimal-icon-button
               id="mobile-widget-buttons"
               button
               icon="ellipsisVertical"
            />
            <div menu aria-labelledby="mobile-widget-buttons">
               <!-- View upcoming schedules button -->
               @if (template().checklistTemplate === 1) {
                  <lim-ui-dropdown-item (click)="showSingleSchedules(template())">
                     <lim-ui-icon icon="squareCaretDownRegular" />
                     {{ lang().ViewUpcomingSchedulesForThisTemplate }}
                  </lim-ui-dropdown-item>
               }
               @if (template().checklistTemplate === 1) {
                  <lim-ui-dropdown-divider />
               }

               <!-- Start a task button -->
               <lim-ui-dropdown-item (click)="startInstanceTask(template())">
                  <lim-ui-icon icon="squareArrowUpRightRegular" />
                  {{ lang().StartACopyOfThisTemplate }}
               </lim-ui-dropdown-item>
               <lim-ui-dropdown-divider />

               <!-- Duplicate button -->
               <lim-ui-dropdown-item (click)="duplicatePM()">
                  <lim-ui-icon icon="cloneRegular" [ngClass]="{ paywalled: !canAddPM }" />
                  {{ lang().DuplicateThisPMTooltip }}
               </lim-ui-dropdown-item>
               <lim-ui-dropdown-divider />

               <!-- Delete template() button -->
               <lim-ui-dropdown-item (click)="deleteTask()">
                  <lim-ui-icon icon="trashCanRegular" iconColor="danger" />
                  {{ lang().DeleteThisTemplate }}
               </lim-ui-dropdown-item>
            </div>
         </lim-ui-dropdown>
      }
   </div>
}
