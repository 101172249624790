<div class="stepper-container">
   <div class="mobile-stepper">
      <donut-progress-tracker
         [steps]="steps"
         [currentStep]="currentStep()"
         [nextStep]="nextStep()"
         [totalNumberOfSteps]="totalNumberOfSteps"
      />
      <div class="step-info">
         <div class="step-name">{{ currentStep().name }}</div>
         @let nextStepName = nextStep()?.name;
         @if (nextStepName) {
            <div class="next-step-name">
               <span class="next">{{ i18n().t("Next") }}:</span> {{ nextStepName }}
            </div>
         }
      </div>
   </div>
   <div class="desktop-stepper">
      <horizontal-progress-tracker
         [steps]="steps"
         [currentStep]="currentStep()"
         [nextStep]="nextStep()"
         [totalNumberOfSteps]="totalNumberOfSteps"
      />
   </div>
</div>
