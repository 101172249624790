import type { PartPurchaseOrderItemDto } from "src/app/purchasing/pos/part-purchase-order-item-dto/part-purchase-order-item.dto";
import { PurchaseOrderItemType } from "src/app/purchasing/pos/purchase-order-item-type";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";

export function isPartItem(item: PurchaseOrderItem): item is PartPurchaseOrderItemDto {
   return (
      item.itemType === PurchaseOrderItemType.Part &&
      item.partID !== null &&
      item.partID > 0
   );
}
