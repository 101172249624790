import { NgStyle } from "@angular/common";
import {
   booleanAttribute,
   ChangeDetectorRef,
   Component,
   computed,
   EventEmitter,
   inject,
   Input,
   input,
   type OnInit,
   Output,
} from "@angular/core";
import {
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
   TableButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CustomizePriorities } from "src/app/settings/components/customizePrioritiesModal/customizePriorities.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "priority-button-legacy",
   templateUrl: "./priority-button-legacy.component.html",
   styleUrls: ["./priority-button-legacy.component.scss"],
   imports: [
      DropdownComponent,
      TableButtonComponent,
      TooltipDirective,
      IconComponent,
      NgStyle,
      DropdownItemComponent,
      LimbleHtmlDirective,
      DropdownDividerComponent,
   ],
})
export class PriorityButtonLegacyComponent implements OnInit {
   @Input({ transform: booleanAttribute }) taskIsCompleted: boolean = false;
   @Output() readonly dropdownClickEvent = new EventEmitter();
   @Input({ required: true }) priorityID!: number | null;
   @Input({ required: true }) taskID!: number;
   @Input({ required: true }) locationID!: number;
   @Input() superUser: boolean = false;
   @Input() fixedWidthTableButton: boolean = false;
   public useFullName = input<boolean>(false);
   // public task: Task | undefined;
   public priorityLevel: number | undefined;
   public priorityName: string | undefined;
   protected priorityList: any;
   protected priorityColor: string | undefined;

   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly changeDetectorRef = inject(ChangeDetectorRef);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.priorityLevel = this.manageTask.getPriorityInfo(this.priorityID).priorityLevel;
      this.priorityName = this.cleanedPriorityName(
         this.priorityLevel,
         this.manageTask.getPriorityInfo(this.priorityID).priorityName,
      );
      this.priorityList = this.managePriority.getPriorityList();
      const priorityListIndex = this.managePriority.getPriorityListIndex();
      if (
         this.priorityID !== null &&
         this.priorityID !== 0 &&
         priorityListIndex !== undefined
      ) {
         this.priorityColor = priorityListIndex[this.priorityID]?.color;
      }

      this.runChangeDetection();
   }

   editPriorities = () => {
      const instance = this.modalService.open(CustomizePriorities);
      this.paramsService.params = {
         modalInstance: instance,
      };
   };

   protected updateTaskPriorityID(priority): void {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.ChangeAnOpenTasksPriorityLevel,
         )
      ) {
         this.alertService.addAlert(this.lang().cred101Fail, "danger", 10000);
         return;
      }
      if (Number(priority.priorityID) === Number(this.priorityID)) {
         return;
      }

      if (this.priorityID === null) return;

      this.manageTask
         .updateTaskPriorityID(this.taskID, priority.priorityID, this.priorityID)
         .then((answer: any) => {
            if (answer.data.success == true) {
               this.priorityID = priority.priorityID;
               const priorityInfo = this.manageTask.getPriorityInfo(this.priorityID);
               this.priorityLevel = priorityInfo.priorityLevel;
               this.priorityName = this.cleanedPriorityName(
                  priorityInfo.priorityLevel,
                  priorityInfo.priorityName,
               );
               this.runChangeDetection();

               this.alertService.addAlert(
                  `<i class='fa-regular fa-square-check fa-fw'></i> ${this.lang().PrioritySuccessfullyChangedTo} <b>${priorityInfo.priorityName}`,
                  "success",
                  5000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   }

   private runChangeDetection() {
      this.changeDetectorRef.detectChanges();
   }

   private cleanedPriorityName(
      priorityLevel?: number,
      priorityName?: string,
   ): string | undefined {
      if (
         !this.useFullName() ||
         priorityLevel === undefined ||
         priorityName === undefined
      ) {
         return undefined;
      }
      return priorityName.replace(priorityLevel.toString(), "");
   }
}
