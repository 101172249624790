<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         @if (credCreateNewFields) {
            <span class="message-area" [limbleHtml]="message"></span>
         }
      </lim-ui-info-panel>

      <lim-ui-panel>
         @if (credConfigureNewFields || credCreateNewFields) {
            <div class="add-field-selection-list">
               <!--We add credCreateNewFields because why show the buttons if only one is available-->
               <lim-ui-radio-button
                  groupID="addFieldGroup"
                  [checked]="showSuggested"
                  label="{{ lang().ExistingFields }}"
                  (click)="showSuggested = true; showNew = false; errorMsg = ''"
               />
               <lim-ui-radio-button
                  groupID="addFieldGroup"
                  [checked]="showNew"
                  label="{{ lang().CreateNewField }}"
                  (click)="showSuggested = false; showNew = true; errorMsg = ''"
               />
            </div>
         }

         @if (showSuggested) {
            <div>
               <div class="suggested-fields-search-section">
                  @if (fields.size == 0 && allFields.length == 0) {
                     <div>
                        <p>{{ lang().NoFieldsAtLocation }}</p>
                        <br />
                        <p>{{ lang().CreateNewFieldTip }}</p>
                     </div>
                  }

                  @if (fields.size > 0 && credCreateNewFields) {
                     <div>
                        {{ lang().SuggestedFields }}
                     </div>
                  }

                  @if (showSuggested) {
                     <lim-ui-search-box
                        [(searchVal)]="searchFields"
                        [placeholder]="lang().Search"
                        (searchValChange)="updateSearch()"
                     />
                  }

                  @if (fields.size == 0 && allFields.length > 0) {
                     <div>
                        <no-search-results />
                     </div>
                  }
               </div>

               @for (field of fields; track field) {
                  @if (fieldDisplayInfo.get(field.fieldID); as fieldDisplayInfo) {
                     <div
                        (click)="focusField(field)"
                        class="lim-ui-list-item lim-ui-selectable-list-item"
                        [ngClass]="{
                           'lim-ui-selected-list-item': fieldDisplayInfo.selected,
                           'lim-ui-disabled-list-item': fieldDisplayInfo.greyOut,
                        }"
                     >
                        <div class="lim-ui-list-item-content">
                           @if (types.get(field.fieldTypeID); as type) {
                              <div
                                 class="label-and-icon-item-section"
                                 [ngClass]="{
                                    'field-bold-selected': fieldDisplayInfo.selected,
                                 }"
                              >
                                 @if (fieldDisplayInfo.selected) {
                                    <lim-ui-icon icon="check" iconColor="primary" />
                                 }

                                 @if (field.fieldTypeID != 6 && type.fieldTypeIcon) {
                                    <lim-ui-icon
                                       [icon]="type.fieldTypeIcon | iconAlias"
                                       limUiTooltip="{{ type.fieldTypeHint }}"
                                    />
                                 }
                                 @if (field.fieldTypeID == 6) {
                                    <span limUiTooltip="{{ type.fieldTypeHint }}"
                                       >{{ currencySymbol }}
                                    </span>
                                 }
                                 <span>
                                    <span
                                       class="field-type-name"
                                       [limbleHtml]="field.fieldName"
                                    ></span>
                                    @if (field.lockedDefault) {
                                       <lim-ui-icon
                                          icon="lockRegular"
                                          iconSize="small"
                                          [limUiTooltip]="lang().FieldLockdedTooltip"
                                          placement="right"
                                          iconColor="medium-grey"
                                       />
                                    }
                                 </span>
                              </div>
                           }

                           <lim-ui-minimal-icon-button
                              icon="penToSquareRegular"
                              [superMinimal]="true"
                              (click)="showFieldOptions(field)"
                              limUiTooltip="{{ lang().Edit }}"
                           />
                        </div>
                     </div>
                  }
               }
            </div>
         }

         @if (showNew) {
            <div>
               <div class="show-new-name-section">
                  <span [limbleHtml]="lang().NameOfTheInformationToBeTracked"></span>

                  <input
                     type="text"
                     [ngClass]="{ 'has-error': nameErr }"
                     id="focusedinput"
                     placeholder="{{ lang().Name }}"
                     [(ngModel)]="newName"
                  />
               </div>

               <div class="show-new-name-list-section">
                  <span
                     class="title-field-label"
                     [limbleHtml]="lang().TypeOfInformation"
                  ></span>

                  @for (type of types; track type) {
                     <div
                        (click)="focusType(type)"
                        class="lim-ui-list-item lim-ui-selectable-list-item"
                        [ngClass]="{
                           'lim-ui-selected-list-item': selectedType == type.fieldTypeID,
                        }"
                        limUiTooltip="{{ type.fieldTypeHint }}"
                        data-placement="top"
                     >
                        <div class="lim-ui-list-item-content">
                           <div
                              class="label-and-icon-item-section"
                              [ngClass]="{
                                 'field-bold-selected': selectedType == type.fieldTypeID,
                              }"
                           >
                              @if (selectedType == type.fieldTypeID) {
                                 <lim-ui-icon icon="check" iconColor="primary" />
                              }
                              @if (type.fieldTypeID != 6 && type.fieldTypeIcon) {
                                 <lim-ui-icon
                                    [icon]="type.fieldTypeIcon | iconAlias"
                                    limUiTooltip="{{ type.fieldTypeHint }}"
                                 />
                              }

                              @if (type.fieldTypeID == 6) {
                                 <span>{{ currencySymbol }}</span>
                              }
                              <span
                                 class="field-type-name"
                                 [limbleHtml]="type.fieldTypeName"
                              ></span>
                           </div>
                           <div>
                              @if (type.fieldTypeID == 1) {
                                 <i>{{ lang().fieldType1Example }}</i>
                              }
                              @if (type.fieldTypeID == 2) {
                                 <i>{{ lang().fieldType2Example }}</i>
                              }
                              @if (type.fieldTypeID == 3) {
                                 <i>{{ lang().fieldType3Example }}</i>
                              }
                              @if (type.fieldTypeID == 4) {
                                 <i>{{ lang().fieldType4Example }}</i>
                              }
                              @if (type.fieldTypeID == 5) {
                                 <i>{{ lang().fieldType5Example }}</i>
                              }
                              @if (type.fieldTypeID == 6) {
                                 <i>{{ lang().fieldType6Example }}</i>
                              }
                              @if (type.fieldTypeID == 7) {
                                 <i>{{ lang().fieldType7Example }}</i>
                              }
                           </div>
                        </div>
                     </div>
                  }
               </div>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      [successButtonText]="showNew ? lang().Create : lang().Add"
      (successClick)="submit()"
   />
</lim-ui-modal>
