import { describeUnitCategory } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/describe-unit-category";
import { UnitCategories } from "src/app/parts/unit-of-measure/unit.types";

export const lengthDescription = describeUnitCategory({
   category: UnitCategories.Length,
   detailsMap: {
      meter: {
         singular: "meter",
         short: "m",
         conversionFactorToSIUnit: 1,
      },
      inch: {
         singular: "inch",
         short: "in",
         conversionFactorToSIUnit: 0.0254,
      },
      foot: {
         singular: "foot",
         short: "ft",
         conversionFactorToSIUnit: 0.3048,
      },
      centimeter: {
         singular: "centimeter",
         short: "cm",
         conversionFactorToSIUnit: 0.01,
      },
      yard: {
         singular: "yard",
         short: "yd",
         conversionFactorToSIUnit: 0.9144,
      },
   },
});
