import { Component, EventEmitter, Input, Output, computed, inject } from "@angular/core";
import {
   CardComponent,
   IconButtonComponent,
   IconComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import Median from "median-js-bridge";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "qr-code-button",
   templateUrl: "./qrCodeButton.component.html",
   styleUrls: ["./qrCodeButton.component.scss"],
   imports: [SecondaryButtonComponent, IconButtonComponent, CardComponent, IconComponent],
})
export class QRCodeButton {
   @Input() public type: "button" | "card" | "list" = "button";
   @Input() public dataLogLabel: string | undefined;
   @Output() public readonly scan = new EventEmitter<string>();
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected async scanQrCode(): Promise<void> {
      const data = await Median.barcode.scan();
      if (data.success === false) {
         throw new Error(data.error);
      }
      this.scan.emit(data.code);
   }
}
