import { computed, inject } from "@angular/core";
import type { TableColumn } from "@empowered/base/table/table.models";
import {
   getExtraColumns,
   getParams,
} from "@empowered/base/table/utils/column-table-parser";
import {
   patchState,
   signalStore,
   withComputed,
   withHooks,
   withMethods,
   withState,
} from "@ngrx/signals";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import type { DataViewerFilter } from "src/app/shared/data-viewer/data-viewer-filters";
import { PaginationSettingsService } from "src/app/shared/data-viewer/data-viewer-paginator/pagination-settings.service";
import type {
   RequestFilter,
   RequestOptions,
   RequestParam,
   SortParams,
} from "src/app/shared/services/flannel-api-service";
import { ManageUser } from "src/app/users/services/manageUser";

export type DataViewerState = {
   /**
    * User-configurable filters that are actively applied to the data view.
    * Stored as a Map where the key is a unique filter identifier and the value contains the filter configuration.
    *
    * @example
    * ```typescript
    * new Map([
    *   ['assignedTo', { key: 'assignedTo', value: { assignedTo: 'John Doe' }, label: 'Assigned To: John Doe' }],
    *   ['status', { key: 'status', value: { status: 'active' }, label: 'Status: Active' }]
    * ])
    * ```
    */
   userFilters: Map<string, DataViewerFilter>;

   /**
    * Fixed filters that are always applied to this data view.
    * These filters are set when initializing the component and remain constant.
    * For example, when viewing tasks for a specific location, this would include { locationId: 'X' }.
    *
    * These filters:
    * - Are not modifiable by users
    * - Are specific to this instance of the data viewer
    * - Define the core scope of what data this component displays
    *
    * @example
    * ```typescript
    * [
    *   { locationId: '123' },
    *   { organizationId: '456' }
    * ]
    * ```
    */
   fixedFilters: Array<RequestFilter>;
   page: number;
   pageSize: number;
   search: string;
   sort: string | SortParams | Array<SortParams>;
   columns: string;
   params: RequestParam;
};

const initialState: DataViewerState = {
   userFilters: new Map(),
   fixedFilters: [],
   page: 0,
   pageSize: 0,
   search: "",
   sort: "",
   columns: "",
   params: {},
};

export const DataViewerStore = signalStore(
   withState<DataViewerState>(initialState),
   withComputed((store) => ({
      /**
       * This is used to get the filters that will be sent to the backend.
       * It is computed from the userFilters and fixedFilters.
       * e.g. { assignedTo: "John Doe", completedBy: "Jane Doe" }
       */
      filters: computed(() => {
         let filters = {};
         store.userFilters().forEach((dataViewerFilter: DataViewerFilter) => {
            filters = {
               ...filters,
               ...dataViewerFilter.value,
            };
         });

         store.fixedFilters().forEach((fixedFilter: RequestFilter) => {
            filters = {
               ...filters,
               ...fixedFilter,
            };
         });

         return filters;
      }),
      /**
       * This is used to check if the user has searched for something
       */
      hasTheUserSearched: computed(() => {
         return store.search() !== undefined && store.search() !== "";
      }),
   })),
   withComputed((store) => ({
      /**
       * This is used to get the request options that are used to make the request to the backend.
       * This is used to make the request to the backend without pagination and it is commonly used for export.
       */
      requestOptionsWithoutPagination: computed(() => {
         const { filters, search, sort, columns, params } = store;

         const options = {
            filters: filters ? filters() : undefined,
            search: search(),
            sort: sort ? sort() : undefined,
            columns: columns ? columns() : undefined,
            params: params ? params() : undefined,
         } as const;

         return options as Partial<RequestOptions<any>>;
      }),
   })),
   withComputed((store) => ({
      /**
       * This is used to get the request options that are used to make the request to the backend.
       */
      requestOptions: computed(() => {
         const { requestOptionsWithoutPagination, pageSize, page } = store;
         return {
            ...requestOptionsWithoutPagination(),
            pagination: {
               page: page(),
               limit: pageSize(),
            },
         };
      }),
   })),

   withMethods((store) => ({
      setPage(page: number) {
         patchState(store, { page });
      },

      setPageSize(pageSize: DataViewerState["pageSize"]) {
         patchState(store, { pageSize });
         patchState(store, { page: 1 });
      },

      setSearch(search: string) {
         patchState(store, { search });
         patchState(store, { page: 1 });
      },

      addUserFilter(userFilter: DataViewerFilter) {
         patchState(store, {
            userFilters: new Map([...store.userFilters(), [userFilter.key, userFilter]]),
         });
         patchState(store, { page: 1 });
      },

      removeUserFilter(userFilter: DataViewerFilter) {
         const userFilters = new Map(store.userFilters());
         userFilters.delete(userFilter.key);

         patchState(store, {
            userFilters,
         });
      },

      setUserFilters(userFilters: Array<DataViewerFilter>) {
         patchState(store, {
            userFilters: new Map(
               userFilters.map((userFilter) => [userFilter.key, userFilter]),
            ),
         });
      },

      setFixedFilters(fixedFilters: Array<RequestFilter>) {
         patchState(store, {
            fixedFilters: fixedFilters,
         });
      },

      addFixedFilter(fixedFilter: RequestFilter) {
         patchState(store, {
            fixedFilters: [...store.fixedFilters(), fixedFilter],
         });
      },

      removeFixedFilter(fixedFilter: RequestFilter) {
         patchState(store, {
            fixedFilters: store.fixedFilters().filter((filter) => filter !== fixedFilter),
         });
      },

      setSort(sortParams: SortParams | string) {
         let sort: string;
         if (typeof sortParams === "string") {
            sort = sortParams;
         } else {
            const { direction, field } = sortParams;
            sort = `${direction === "asc" ? "" : "-"}${field}`;
         }

         patchState(store, { sort });
      },

      setParams(params: RequestParam) {
         patchState(store, { params });
      },

      addParam(params: RequestParam) {
         patchState(store, { params: { ...store.params(), ...params } });
      },

      removeParam(paramKey: string) {
         patchState(store, {
            params: Object.fromEntries(
               Object.entries(store.params()).filter(([key]) => key !== paramKey),
            ),
         });
      },

      /**
       * This is used to set the columns and the params for the request by using the column definitions.
       * The extraRequestParams and extraColumns are optional and are used to set the params and the columns that are not part of the column definitions.
       *
       * @param columns - The columns to be set
       * @param extraRequestParams - The extra request params to be set that are not part of the column definitions
       * @param extraColumns - The extra columns to be set that are not part of the column definitions
       */
      setColumns(
         columns: Array<TableColumn | Column>,
         extraRequestParams?: RequestParam,
         extraColumns?: Array<string>,
      ) {
         const allColumns = getExtraColumns(columns, extraColumns);
         patchState(store, () => ({ columns: allColumns }));

         const params = getParams(columns, extraRequestParams);
         patchState(store, { params });
      },

      /**
       * This is used to set the extra columns for the request when we dont have complete column definitions
       */
      setExtraColumns(extraColumns: Array<string>) {
         const columns = extraColumns.join(",");
         patchState(store, { columns });
      },
   })),
   withMethods((store) => ({
      initialize(options: {
         sort?: SortParams | string;
         /**
          *  By Passing the columns, we can set the extra columns and params for the request
          */
         columns?: Array<TableColumn | Column>;
         /**
          * By passing the extra columns, we can set the columns for the request
          * This is useful when we don't want to pass the column definitions or the extra columns required are not part of them
          */
         extraColumns?: Array<string>;

         /**
          * This will be added to the request params
          * This is useful when we don't want to pass the column definitions or the params are not part  of them.
          */
         extraRequestParams?: RequestParam;
         userFilters?: Array<RequestFilter>;
      }) {
         const { sort, columns, userFilters, extraRequestParams, extraColumns } = options;

         if (sort) {
            store.setSort(sort);
         }

         if (columns && columns.length > 0) {
            store.setColumns(columns, extraRequestParams, extraColumns);
         }

         // If no columns are passed, we will set the params and the extra columns if they are passed
         if (!columns) {
            if (extraRequestParams) {
               store.setParams(extraRequestParams);
            }

            if (extraColumns) {
               store.setExtraColumns(extraColumns);
            }
         }

         if (userFilters && userFilters?.length > 0) {
            store.setUserFilters(userFilters as unknown as DataViewerFilter[]);
         }
      },
   })),
   withHooks({
      onInit(store) {
         const manageUser = inject(ManageUser);
         const pageSize = inject(PaginationSettingsService).getPageSize(
            manageUser.getCurrentUser(),
         );
         store.setPageSize(pageSize);
      },
   }),
);
