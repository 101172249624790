import { Component, inject, input } from "@angular/core";
import { IconComponent, LimbleHtmlDirective, ModalService } from "@limblecmms/lim-ui";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ParamsService } from "src/app/shared/services/params.service";
import { AssetName } from "src/app/tasks/components/taskItemElement/assetName.pipe";

// Use "AssetHierarchyListItemComponent" instead when working with JIT TaskAsset
// which contains parent asset data directly.
@Component({
   selector: "asset-hierarchy-list",
   imports: [IconComponent, LimbleHtmlDirective, AssetName, AssetParentList],
   templateUrl: "./asset-hierarchy-list.component.html",
   styleUrl: "./asset-hierarchy-list.component.scss",
})
export class AssetHierarchyListComponent {
   readonly assetID = input.required<number>();
   readonly locationID = input.required<number>();

   protected readonly manageAsset = inject(ManageAsset);
   protected readonly modalService = inject(ModalService);
   protected readonly paramsService = inject(ParamsService);

   protected viewAsset() {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: this.assetID(),
            locationID: this.locationID(),
            data: {
               restrict: false,
            },
         },
      };
   }
}
