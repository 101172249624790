@if (menuItems().length > 0) {
   <e-button
      [type]="'secondary'"
      [outlined]="true"
      [size]="buttonSize()"
      [ariaLabel]="selectedLabel()"
      [label]="selectedLabel()"
      [disabled]="taskIsCompleted()"
      [style]="buttonStyle()"
      (click)="menu.togglePopup($event)"
   />
   <e-menu #menu [items]="menuItems()" [popup]="true" />
}
