import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import type { ModalResult } from "@limblecmms/lim-ui";
import {
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimUiModalRef,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   OutlinedButtonComponent,
   PrimaryButtonComponent,
} from "@limblecmms/lim-ui";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { PartField } from "src/app/parts/types/field/field.types";
import type { VendorField } from "src/app/vendors/types/field/field.types";

type TaskInfo = {
   id: number;
   name: string;
   type: string;
};

@Component({
   selector: "confirm-field-lock",
   templateUrl: "./confirm-field-lock.modal.component.html",
   styleUrls: ["./confirm-field-lock.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
      IconComponent,
   ],
})
export class ConfirmFieldLock implements OnInit, ModalResult<boolean> {
   @Input() public linkedTaskInfo: Array<
      Record<
         "checklistID" | "checklistName" | "checklistTemplate" | "checklistTemplateOld",
         any
      >
   > = [];
   @Input() public field: AssetField | PartField | VendorField | undefined;
   public resolve;
   public modalInstance;
   public message: string = "";
   protected linkedTasks: Array<TaskInfo> = [];
   protected taskTemplateMap;
   protected hasLinkedTasks: boolean = false;
   protected title: string = "";
   protected buttonText: string = "";

   public readonly modalRef: LimUiModalRef<ConfirmFieldLock, boolean> =
      inject(LimUiModalRef);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.taskTemplateMap = {
         1: this.lang().PMTemplate,
         2: this.lang().WOTemplate,
         6: this.lang().InstructionSet,
      };
   }

   public ngOnInit() {
      this.hasLinkedTasks = this.linkedTaskInfo.length > 0;
      this.setModalContent();
      this.getTaskInfo();
   }

   protected close(): void {
      this.modalRef.close(false);
   }

   protected submit(): void {
      this.modalRef.close(true);
   }

   private setModalContent(): void {
      if (!this.field) {
         return;
      }
      if (this.field.lockedDefault) {
         this.title = `${this.lang().Lock} "${this.field.fieldName}"`;
         this.buttonText = this.lang().Lock;
      } else {
         this.title = `${this.lang().Unlock} "${this.field.fieldName}"`;
         this.buttonText = this.lang().Unlock;
      }
   }

   private getTaskInfo(): void {
      for (const task of this.linkedTaskInfo) {
         if (task.checklistTemplate === 0) {
            // It's an instance
            this.linkedTasks.push({
               id: task.checklistID,
               name: task.checklistName,
               type: this.lang().Task,
            });
         } else {
            // It's a template so we need to display which type of template it is.
            if (task.checklistTemplate !== null) {
               this.linkedTasks.push({
                  id: task.checklistID,
                  name: task.checklistName,
                  type: this.taskTemplateMap[task.checklistTemplate],
               });
            }
         }
      }
      this.linkedTasks.sort((elemA, elemB) => elemA.type.localeCompare(elemB.type));
   }
}
