@if (printData) {
   @if (isJITCompletedTasksChkEnabled | async) {
      <!-- Note for future JIT work (TASK-507) - we should ensure that [taskInstructions] match the data model as it is used in task-form. -->
      <task-print-template
         [taskId]="printData.checklistID"
         [informationToInclude]="informationToInclude"
         [checklistTemplate]="chk?.checklistTemplate ?? 0"
         [taskInstructions]="printData?.items ?? 0"
         [displayAssetInfo]="printData?.displayAssetInfo"
         [taskInfo]="printData?.info"
      />
   } @else {
      <task-print-template-legacy
         [data]="printData"
         [informationToInclude]="informationToInclude"
         [parts]="parts"
      />
   }
}
