import { CdkTrapFocus } from "@angular/cdk/a11y";
import { HttpErrorResponse } from "@angular/common/http";
import {
   type AfterViewInit,
   Component,
   computed,
   inject,
   input,
   signal,
   type Signal,
   type OnInit,
   HostListener,
} from "@angular/core";
import { takeUntilDestroyed, toObservable, toSignal } from "@angular/core/rxjs-interop";
import { RouterLink } from "@angular/router";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   IconComponent,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   type ModalResult,
   LoadingAnimationComponent,
} from "@limblecmms/lim-ui";
import { debounceTime, type Observer } from "rxjs";
import type { PhraseMap } from "src/app/languages/translation/phrase-map.types";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { InvalidPurchasableError } from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/errors/invalid-purchasable.error";
import { PurchasableMissingInEditModeError } from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/errors/purchasable-missing-in-edit-mode.error";
import { PartPurchasableAddModalService } from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/part-purchasable-add-modal.service";
import type { RequestParams } from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/part-purchasable-add-modal.service.params";
import type { PurchasableType } from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/part-purchasable-add-modal.types";
import { CountedAndMeasuredPurchasable } from "src/app/parts/purchasable/counted-and-measured-purchasable.model";
import { CountedPurchasable } from "src/app/parts/purchasable/counted-purchasable.model";
import type { PurchasableDto } from "src/app/parts/purchasable/purchasable.dto.types";
import type { Purchasable } from "src/app/parts/purchasable/purchasable.model";
import type { Part } from "src/app/parts/types/part.types";
import { UnitConversionComponent } from "src/app/parts/unit-of-measure/components/unit-conversion/unit-conversion.component";
import { UnitLabelComponent } from "src/app/parts/unit-of-measure/components/unit-label/unit-label.component";
import { ProvidedUnit } from "src/app/parts/unit-of-measure/provided-units/provided-unit.model";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { UnitCategories, type Unit } from "src/app/parts/unit-of-measure/unit.types";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AutoFocusDirective } from "src/app/shared/directives/autofocus/autoFocus.directive";
import { StripHtmlTagsPipe } from "src/app/shared/pipes/stripHtmlTags.pipe";
import { AlertService, type AlertType } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "part-purchasable-add-modal",
   templateUrl: "./part-purchasable-add-modal.component.html",
   styleUrl: "./part-purchasable-add-modal.component.scss",
   imports: [
      AutoFocusDirective,
      BasicModalHeaderComponent,
      CdkTrapFocus,
      DropdownDividerComponent,
      DropdownTextItemComponent,
      FormDropdownInputComponent,
      IconComponent,
      ModalBodyComponent,
      ModalComponent,
      NoSearchResults,
      PanelComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SearchBoxComponent,
      SecondaryButtonComponent,
      StripHtmlTagsPipe,
      TooltipDirective,
      LoadingAnimationComponent,
      UnitConversionComponent,
      UnitLabelComponent,
      AlertComponent,
      DropdownItemComponent,
      RouterLink,
   ],
})
export class PartPurchasableAddModalComponent
   implements ModalResult<boolean | undefined>, AfterViewInit, OnInit
{
   private static readonly SEARCH_FILTER_DUE_TIME = 250;

   public readonly part = input.required<Part>();
   public readonly editMode = input.required<boolean>();
   public readonly purchasable = input<Purchasable>();

   public readonly modalRef: LimUiModalRef<
      PartPurchasableAddModalComponent,
      boolean | undefined
   > = inject(LimUiModalRef);

   protected readonly i18n = inject(TranslationService).i18n;
   protected readonly credService = inject(CredService);
   protected readonly manageUser = inject(ManageUser);
   protected readonly manageUtil = inject(ManageUtil);
   protected isSuperUser = false;
   protected addUnitHelp: string | null = null;

   private readonly service = inject(PartPurchasableAddModalService);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);

   private readonly numberInputKeyExceptions = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
   ];

   protected readonly newName = signal<string | null>(null);
   protected readonly newSize = signal<number | null>(null);
   protected readonly orderUnitSearchQuery = signal("");
   protected readonly sizeUnitSearchQuery = signal("");
   private readonly selectedOrderUnit = signal<Unit | null>(null);
   private readonly selectedSizeUnit = signal<ProvidedUnit | null>(null);

   protected readonly modalTitle = this.computedModalTitle();
   protected readonly submitButtonText = this.computedSubmitButtonText();
   protected readonly name = this.computedName();
   protected readonly orderUnit = this.computedOrderUnit();
   protected readonly sizeUnit = this.computedSizeUnit();
   protected readonly isSizeUnitDifferentFromStockUnit =
      this.computedIsSizeUnitDifferentFromStockUnit();
   protected readonly stockUnit = this.computedStockUnit();
   protected readonly stockUnitConversion = this.computedStockUnitConversion();
   protected readonly purchasableType = this.computedPurchasableType();
   protected readonly size = this.computedSize();
   protected readonly orderUnits = this.computedOrderUnits();
   protected readonly sizeUnits = this.computedSizeUnits();

   private readonly orderUnitSearchQueryDebounced =
      this.computedOrderUnitSearchQueryDebounced();
   private readonly sizeUnitSearchQueryDebounced =
      this.computedSizeUnitSearchQueryDebounced();
   private readonly searchableOrderUnits = this.computedSearchableOrderUnits();
   private readonly searchableSizeUnits = this.computedSearchableSizeUnits();

   public ngOnInit(): void {
      assert(this.part);
      const currentUser = this.manageUser.getCurrentUser();
      this.isSuperUser = this.manageUtil.checkIfSuperUser(currentUser);
      this.addUnitHelp = this.isSuperUser
         ? this.i18n().t("CreateMissingUnitHelp")
         : this.i18n().t("MissingUnitHelp");
   }
   public ngAfterViewInit(): void {
      const params = this.paramsService.params;
      if (params?.resolve?.name) {
         this.newName.set(params.resolve.name);
      }
   }

   @HostListener("document:keydown.escape", ["$event"])
   private onEscapeKey(): void {
      this.close();
   }

   protected close(): void {
      this.modalRef.close();
   }

   protected updateOrderUnit(unit: Unit): void {
      this.selectedOrderUnit.set(unit);
   }

   protected updateSize(event: Event): void {
      assert(event.target instanceof HTMLInputElement);
      const value = event.target.value;
      const parsedValue = Number(value);

      this.newSize.set(parsedValue === 0 ? null : parsedValue);
   }

   protected updateSizeUnit(unit: ProvidedUnit): void {
      this.selectedSizeUnit.set(unit);
   }

   protected updateName(event: Event): void {
      assert(event.target instanceof HTMLInputElement);
      this.newName.set(event.target.value);
   }

   protected submit(): void {
      const partID = this.part().partID;
      try {
         const purchasable = this.service.validatePurchasable({
            name: this.name(),
            orderUnit: this.orderUnit(),
            partID,
            size: this.size(),
            sizeUnit: this.sizeUnit(),
            purchasableID: this.purchasable()?.id,
            purchasableType: this.purchasableType(),
         });

         if (this.editMode() === true) {
            this.requestEdit({ partID, purchasable });
         } else {
            this.requestCreate({ partID, purchasable });
         }
      } catch (error) {
         this.handleValidationError(error);
      }
   }

   protected preventNonNumericCharacters(event: KeyboardEvent): void {
      // Allow numeric characters
      if (!isNaN(Number(event.key))) return;

      // Allow exceptions (e.g., Backspace, Delete, etc.)
      if (this.numberInputKeyExceptions.includes(event.key)) return;

      // Allow Ctrl+A or Command+A for select all
      const isCtrlA = event.key === "a" && (event.ctrlKey || event.metaKey);
      if (isCtrlA) return;

      // Allow Tab key
      if (event.key === "Tab") return;

      event.preventDefault();
   }

   private computedModalTitle(): Signal<string> {
      return computed(() => {
         if (this.editMode()) {
            const purchasable = this.purchasable();
            assert(purchasable !== undefined, new PurchasableMissingInEditModeError());
            return `${this.i18n().t("EditPurchasable")} - ${purchasable.name()}`;
         }

         return this.i18n().t("AddAPurchasable");
      });
   }

   private computedSubmitButtonText(): Signal<string> {
      return computed(() =>
         this.editMode() ? this.i18n().t("Save") : this.i18n().t("Add"),
      );
   }

   private computedName(): Signal<string> {
      return computed(() => this.newName() ?? this.purchasable()?.name() ?? "");
   }

   private computedOrderUnit(): Signal<Unit | null> {
      return computed(() => {
         const editMode = this.editMode();
         if (editMode) {
            const purchasable = this.purchasable();
            assert(purchasable !== undefined, new PurchasableMissingInEditModeError());
            return (
               this.selectedOrderUnit() ??
               this.unitOfMeasureService.getUnit(purchasable.orderUnitDescription)()
            );
         }

         return this.selectedOrderUnit();
      });
   }

   private computedSizeUnit(): Signal<ProvidedUnit | null> {
      return computed(() => {
         const stockUnit = this.stockUnit();
         if (!stockUnit?.isMeasurementUnit) return null;

         const editMode = this.editMode();
         if (editMode) {
            const purchasable = this.purchasable();
            assert(purchasable !== undefined, new PurchasableMissingInEditModeError());
            if (purchasable instanceof CountedAndMeasuredPurchasable) {
               const purchasableSizeUnit = this.unitOfMeasureService.getUnit({
                  type: "provided",
                  id: purchasable.providedSizeUnitID,
               })();
               return this.selectedSizeUnit() ?? purchasableSizeUnit;
            }
         }

         return this.selectedSizeUnit() ?? stockUnit;
      });
   }

   private computedIsSizeUnitDifferentFromStockUnit(): Signal<boolean> {
      return computed(() => {
         const stockUnit = this.stockUnit();
         const sizeUnit = this.sizeUnit();
         if (stockUnit === null || sizeUnit === null) return false;

         return stockUnit !== sizeUnit;
      });
   }

   private computedStockUnit(): Signal<Unit | null> {
      return computed(() => {
         const stockUnitDescription = this.part().unitDescription;
         return this.unitOfMeasureService.getUnit(stockUnitDescription)();
      });
   }

   private computedStockUnitConversion(): Signal<number | null> {
      return computed(() => {
         const orderUnit = this.orderUnit();
         const stockUnit = this.stockUnit();
         const sizeUnit = this.sizeUnit();
         const purchasableType = this.purchasableType();
         const size = this.size();

         if (orderUnit === null || stockUnit === null) return null;

         switch (purchasableType) {
            case "counted": {
               return size;
            }
            case "measured": {
               return orderUnit.isMeasurementUnit && stockUnit.isMeasurementUnit
                  ? orderUnit.convertUnit(1, stockUnit)
                  : null;
            }
            case "counted-and-measured": {
               return sizeUnit === null || size === null || !stockUnit.isMeasurementUnit
                  ? null
                  : sizeUnit.convertUnit(size, stockUnit);
            }
            default: {
               return null;
            }
         }
      });
   }

   private computedPurchasableType(): Signal<PurchasableType | null> {
      return computed(() => {
         const orderUnit = this.orderUnit();
         const stockUnit = this.stockUnit();
         if (orderUnit === null || stockUnit === null) return null;

         if (orderUnit.isMeasurementUnit) return "measured";

         return stockUnit.isMeasurementUnit ? "counted-and-measured" : "counted";
      });
   }

   private computedSize(): Signal<number | null> {
      return computed(() => {
         const purchasable = this.purchasable();
         const inputPurchasableSize =
            purchasable instanceof CountedAndMeasuredPurchasable ||
            purchasable instanceof CountedPurchasable
               ? purchasable.size
               : null;

         return this.newSize() ?? inputPurchasableSize;
      });
   }

   private computedSearchableOrderUnits(): Signal<Array<Unit> | null> {
      return computed(() => {
         const units = this.unitOfMeasureService.units();
         const stockUnit = this.stockUnit();
         return units === null || stockUnit === null
            ? null
            : units.filter(
                 (unit) =>
                    unit !== stockUnit &&
                    (unit.category === UnitCategories.Count ||
                       unit.category === stockUnit.category),
              );
      });
   }

   private computedSearchableSizeUnits(): Signal<Array<Unit> | null> {
      return computed(() => {
         const providedUnits = this.unitOfMeasureService.units();
         const stockUnit = this.stockUnit();
         return providedUnits === null || stockUnit === null
            ? null
            : providedUnits.filter((unit) => unit.category === stockUnit.category);
      });
   }

   private computedOrderUnits(): Signal<Array<Unit> | null> {
      return computed(() =>
         this.filterUnitsByAnyName({
            units: this.searchableOrderUnits(),
            query: this.orderUnitSearchQueryDebounced(),
         }),
      );
   }

   private computedSizeUnits(): Signal<Array<ProvidedUnit> | null> {
      return computed(
         () =>
            this.filterUnitsByAnyName({
               units: this.searchableSizeUnits(),
               query: this.sizeUnitSearchQueryDebounced(),
            })?.filter((unit) => unit instanceof ProvidedUnit) ?? null,
      );
   }

   private computedOrderUnitSearchQueryDebounced(): Signal<string> {
      return toSignal(
         toObservable(this.orderUnitSearchQuery).pipe(
            debounceTime(PartPurchasableAddModalComponent.SEARCH_FILTER_DUE_TIME),
            takeUntilDestroyed(),
         ),
         { initialValue: "" },
      );
   }

   private computedSizeUnitSearchQueryDebounced(): Signal<string> {
      return toSignal(
         toObservable(this.sizeUnitSearchQuery).pipe(
            debounceTime(PartPurchasableAddModalComponent.SEARCH_FILTER_DUE_TIME),
            takeUntilDestroyed(),
         ),
         { initialValue: "" },
      );
   }

   private requestEdit(params: RequestParams): void {
      this.service.requestEdit(params).subscribe(this.createResponseObserver());
   }

   private requestCreate(params: RequestParams): void {
      this.service.requestCreate(params).subscribe(this.createResponseObserver());
   }

   private createResponseObserver(): Partial<Observer<PurchasableDto>> {
      return {
         next: () => {
            this.handleSuccess();
         },
         error: (error) => {
            this.handleRequestError(error);
         },
      };
   }

   private handleSuccess(): void {
      this.alertService.addAlert(this.i18n().t("successMsg"), "success", 6000);
      this.modalRef.close(true);
   }

   private handleValidationError(error: unknown): void {
      if (!(error instanceof InvalidPurchasableError)) return;

      const translationKey = this.getErrorTranslationKey(error);
      this.showAlert(this.i18n().t(translationKey), "warning");
   }

   private getErrorTranslationKey(error: InvalidPurchasableError): keyof PhraseMap {
      switch (error.field) {
         case "name":
            return "PleaseEnterAValidName";
         case "orderUnit":
            return "PleaseSelectAnOrderUnit";
         case "sizeUnit":
            return "PleaseSelectASizeUnit";
         case "size":
            return "PleaseEnterAValidSize";
         default:
            return "WhoopsSomethingWentWrong";
      }
   }

   private handleRequestError(error: unknown): void {
      if (error instanceof HttpErrorResponse) {
         //TODO : (Be-Leafers) check for 409 after backend is updated
         if (error?.error?.typename === "purchasable_linked_to_open_purchase_order") {
            this.showAlert(this.i18n().t("PurchasableCurrentlyInUseErrorText"), "danger");
         } else if (error.status === 409 || error.status === 400) {
            this.showAlert(this.i18n().t("DuplicatePurchasableErrorText"), "warning");
         } else if (error.status === 500) {
            this.showAlert(this.i18n().t("WhoopsSomethingWentWrong"), "danger");
         }
      } else {
         this.showAlert(this.i18n().t("WhoopsSomethingWentWrong"), "danger");
      }
   }

   private showAlert(message: string, type: AlertType): void {
      this.alertService.addAlert(message, type, 6000);
   }

   private filterUnitsByAnyName({
      units,
      query,
   }: {
      units: Array<Unit> | null;
      query: string;
   }): Array<Unit> | null {
      const processedQuery = query.toLowerCase().trim();
      return units === null
         ? null
         : units.filter(
              (unit) =>
                 unit.short().toLowerCase().includes(processedQuery) ||
                 unit.singular().toLowerCase().includes(processedQuery),
           );
   }
}
