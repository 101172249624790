import { computed, inject, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { Location } from "src/app/locations/types/location.types";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageUser } from "src/app/users/services/manageUser";

interface UserInfo {
   reportProblemShowCustomField1Title: string;
   reportProblemShowCustomField2Title: string;
   reportProblemShowCustomField3Title: string;
   reportProblemShowCustomDropdown1Title: string;
   reportProblemShowCustomDropdown2Title: string;
   reportProblemShowCustomDropdown3Title: string;
}

@Injectable({ providedIn: "root" })
export class BuildRequestFieldsHintService {
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   private readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly i18n = inject(TranslationService).i18n;

   public buildRequestFieldsHint(requestFieldHints: string[], task: TaskEntity): string {
      const location: Location | undefined = this.manageLocation.getLocation(
         task.locationID,
      );
      const currentUserInfo: UserInfo = this.manageUser.getCurrentUser().userInfo;

      return requestFieldHints
         .map((requestFieldHint) =>
            this.buildRequestFieldHint(requestFieldHint, task, location, currentUserInfo),
         )
         .join("<br/>");
   }

   private buildRequestFieldHint(
      requestFieldHint: string,
      task: TaskEntity,
      location: Location | undefined,
      currentUserInfo: UserInfo,
   ): string {
      let locationTitle: string | null = null;
      let userTitle: string | null = null;
      let requestField: string | undefined;
      let hintLabel: string | null = null;

      switch (requestFieldHint.toLowerCase()) {
         case task.requestName?.toLowerCase():
            requestField = task.requestName ?? "";
            hintLabel = this.lang().RequestName;
            break;
         case task.requestPhone?.toLowerCase():
            requestField = task.requestPhone ?? "";
            hintLabel = this.lang().RequestorsPhone;
            break;
         case task.requestDescription?.toLowerCase():
            requestField = task.requestDescription ?? "";
            hintLabel = this.lang().RequestDescription;
            break;
         case task.checklistEmailCN?.toLowerCase():
            requestField = task.checklistEmailCN ?? "";
            hintLabel = this.lang().RequestorsEmail;
            break;
         case task.requestField1?.toLowerCase():
            locationTitle =
               location?.reportProblemShowCustomField1Title ??
               this.i18n().t("CustomField1Instructions");
            userTitle = currentUserInfo.reportProblemShowCustomField1Title;
            requestField = task.requestField1 ?? "";
            break;
         case task.requestField2?.toLowerCase():
            locationTitle =
               location?.reportProblemShowCustomField2Title ??
               this.i18n().t("CustomField2Instructions");
            userTitle = currentUserInfo.reportProblemShowCustomField2Title;
            requestField = task.requestField2 ?? "";
            break;
         case task.requestField3?.toLowerCase():
            locationTitle =
               location?.reportProblemShowCustomField3Title ??
               this.i18n().t("CustomField3Instructions");
            userTitle = currentUserInfo.reportProblemShowCustomField3Title;
            requestField = task.requestField3 ?? "";
            break;
         case task.requestDropdown1?.toLowerCase():
            locationTitle = location?.reportProblemShowCustomDropdown1Title ?? null;
            userTitle = currentUserInfo.reportProblemShowCustomDropdown1Title;
            requestField = task.requestDropdown1 ?? "";
            break;
         case task.requestDropdown2?.toLowerCase():
            locationTitle = location?.reportProblemShowCustomDropdown2Title ?? null;
            userTitle = currentUserInfo.reportProblemShowCustomDropdown2Title;
            requestField = task.requestDropdown2 ?? "";
            break;
         case task.requestDropdown3?.toLowerCase():
            locationTitle = location?.reportProblemShowCustomDropdown3Title ?? null;
            userTitle = currentUserInfo.reportProblemShowCustomDropdown3Title;
            requestField = task.requestDropdown3 ?? "";
            break;
         default:
            break;
      }
      return this.getRequestFieldTitle(locationTitle, userTitle, requestField, hintLabel);
   }

   private getRequestFieldTitle(
      locationTitle: string | null,
      userTitle: string | null,
      requestField: string | undefined,
      hintLabel: string | null,
   ): string {
      if (locationTitle) {
         return `${locationTitle}: ${requestField}`;
      } else if (userTitle) {
         return `${userTitle}: ${requestField}`;
      } else if (hintLabel) {
         return `${hintLabel}: ${requestField}`;
      }
      return "";
   }
}
