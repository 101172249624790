import { Component, computed, inject } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import type { WrSubmissionDataViewerModel } from "src/app/tasks/components/shared/services/w-r-submissions-view-model/models/data-viewer-model";
import { WorkRequestsFacadeService } from "src/app/tasks/components/shared/services/work-requests-facade/work-requests-facade.service";

@Component({
   selector: "submission-name-cell-renderer-component",
   templateUrl: "./submission-name-cell-renderer.component.html",
   styleUrls: ["./submission-name-cell-renderer.component.scss"],
})
export class SubmissionNameCellRendererComponent extends BaseCellRendererComponent {
   private readonly facade = inject(WorkRequestsFacadeService);

   submission = computed(() => this.cell as WrSubmissionDataViewerModel);

   public viewSubmission(): void {
      this.facade.openWorkRequestSubmissionModal(this.submission().id);
   }
}
