import { z } from "zod";

export const CurrencyDtoSchema = z.object({
   id: z.number(),
   code: z.string(),
   symbol: z.string(),
   name: z.string(),
   rate: z.number(),
});

export type CurrencyDto = z.infer<typeof CurrencyDtoSchema>;
