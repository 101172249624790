import { debounceTime, type Observable } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

/**
 * Debounce and filters the search input values.
 * The operator it will be used in search input fields to avoid sending requests for every key stroke.
 *
 * @param debounceTimeInMs - The time to wait after the last event before emitting the value. Default is 400ms.
 * @param minWordLength - The minimum length of the word to be emitted. Default is 0.
 */
export function debounceAndFilterSearchInput(
   debounceTimeInMs: number = 400,
   minWordLength: number = 0,
): (source: Observable<string | null>) => Observable<string> {
   return function (source: Observable<string | null>): Observable<string> {
      return source.pipe(
         debounceTime(debounceTimeInMs),
         distinctUntilChanged(),
         map((searchedText) => searchedText ?? ""),
         filter(
            (searchedText) =>
               searchedText.length > minWordLength || searchedText.length === 0,
         ),
      );
   };
}
