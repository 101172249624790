import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "completeExternalTask",
   templateUrl: "./completeExternalTask.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      LoadingAnimationComponent,
      BasicModalFooterComponent,
   ],
})
export class CompleteExternalTask implements OnInit {
   public resolve;
   public modalInstance;
   public spinner;
   public message;
   public title;
   public task: Task | undefined;
   public cusID;
   public errorMsg;
   public link;
   public noteHidden;

   private readonly router = inject(Router);
   private readonly route = inject(ActivatedRoute);
   private readonly manageUser = inject(ManageUser);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.spinner = false;
      this.errorMsg = false;
      this.cusID = this.manageUser.getCurrentUser().userInfo.customerID;
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.task = this.resolve.task;
      this.link = this.resolve.link;
   }

   close = () => {
      this.modalInstance.close();
   };

   submit = async () => {
      if (!this.task) {
         return;
      }
      //post to addNote here so the external user can mark the task as complete.
      this.spinner = true;

      const currentUser = this.manageUser.getCurrentUser();

      this.noteHidden = Number(currentUser.userInfo.noteHiddenFromExternalFlag) || 0;

      //validate the task is complete first.
      const noteAnswer = await this.manageTask.addNote(
         this.lang().ExternalUserFinishedWorkingOnTask,
         this.task.checklistID,
         this.noteHidden,
         1,
         true,
      );

      if (noteAnswer.data.success) {
         //disable the link here
         const checklistAnswer = await this.manageTask.checklistShareComplete(this.link);
         this.spinner = false;

         if (checklistAnswer.data.checklistShareComplete) {
            //modals have an onbefore hook that stop transitions in modals
            //this closes the modal before starting the transition
            setTimeout(() => {
               this.router.navigate(["/thanks", "true"]);
            }, 0);
            this.modalInstance.close();
         } else {
            //alert here
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      } else {
         this.errorMsg = this.lang().errorMsg;
      }
   };
}
