import { Component, input, computed, inject, type Signal } from "@angular/core";
import { toSignal, toObservable } from "@angular/core/rxjs-interop";
import {
   ModalComponent,
   BasicModalHeaderComponent,
   ModalBodyComponent,
   ModalFooterComponent,
   SecondaryButtonComponent,
   PrimaryButtonComponent,
   PanelComponent,
   LimbleHtmlDirective,
   LimUiModalRef,
   IconComponent,
   ModalDirective,
   AlertComponent,
} from "@limblecmms/lim-ui";
import { filter, switchMap, distinctUntilChanged } from "rxjs";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { WorkRequestsDataService } from "src/app/tasks/components/shared/services/work-request-data/work-request-data.service";
import { WorkRequestsFacadeService } from "src/app/tasks/components/shared/services/work-requests-facade/work-requests-facade.service";
import { CustomFieldComponent } from "src/app/tasks/components/workRequests/work-request-submission-modal/custom-field/custom-field.component";
import { WorkRequestSubmissionViewModelFactoryService } from "src/app/tasks/services/work-request-submission-view-model-factory/work-request-submission-view-model-factory.service";

@Component({
   selector: "work-request-submission-modal",
   standalone: true,
   imports: [
      ModalComponent,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      ModalFooterComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
      PanelComponent,
      LimbleHtmlDirective,
      ViewImage,
      BetterDatePipe,
      IconComponent,
      AssetParentList,
      FileListItem,
      ModalDirective,
      AlertComponent,
      CustomFieldComponent,
   ],
   templateUrl: "./work-request-submission-modal.component.html",
   styleUrls: ["./work-request-submission-modal.component.scss"],
})
export class WorkRequestSubmissionModalComponent {
   public readonly workRequestSubmissionID = input.required<number>();
   private readonly workRequestsDataService = inject(WorkRequestsDataService);
   private readonly translationService = inject(TranslationService);
   private readonly facade = inject(WorkRequestsFacadeService);
   private readonly workRequestSubmissionID$ = toObservable(this.workRequestSubmissionID);
   private readonly modalRef = inject(LimUiModalRef);
   private readonly alertService = inject(AlertService);
   private readonly workRequestSubmissionViewModelFactory = inject(
      WorkRequestSubmissionViewModelFactoryService,
   );
   private readonly manageAsset = inject(ManageAsset);
   private readonly betterDate = inject(BetterDate);

   protected readonly i18n = this.translationService.i18n;

   private readonly workRequestSubmission$ = this.workRequestSubmissionID$.pipe(
      distinctUntilChanged(),
      filter((id) => id !== undefined),
      switchMap((id) =>
         this.workRequestsDataService.getWorkRequestSubmission(id, {
            columns: "files,parts",
         }),
      ),
   );

   private readonly workRequestSubmissionResponse = toSignal(this.workRequestSubmission$);

   protected readonly workRequestSubmission = computed(() => {
      const workRequestSubmission = this.workRequestSubmissionResponse();
      if (!workRequestSubmission) return undefined;
      return this.workRequestSubmissionViewModelFactory.getWorkRequestSubmissionViewModel(
         workRequestSubmission,
      );
   });

   public readonly modalTitle = computed(
      () =>
         `${this.workRequestSubmission()?.workRequestSubmissionModalTitle} ${this.workRequestSubmission()?.requestTitle}`,
   );

   protected readonly declinedMessage = computed(() => {
      const submission = this.workRequestSubmission();
      if (!submission?.reviewedByUserName || !submission?.dateReviewed) {
         return "";
      }

      return `${this.i18n().t("WorkRequestDeclinedBy")} ${
         submission.reviewedByUserName
      } ${this.i18n().t("On")} ${this.betterDate.formatBetterDate(
         submission.dateReviewed,
         "date",
      )}`;
   });

   protected readonly asset: Signal<Asset | undefined> = computed(() => {
      const assetID = this.workRequestSubmission()?.assetID;
      if (!assetID) return undefined;
      return this.manageAsset.getAsset(assetID);
   });

   protected async approveRequest(): Promise<void> {
      const success = await this.facade.approveWorkRequest(
         this.workRequestSubmission()?.workRequestSubmissionId ?? 0,
      );
      if (success) {
         this.modalRef.close();
      } else {
         this.alertService.addAlert(
            this.translationService.i18n().t("errorMsg"),
            "danger",
            6000,
         );
      }
   }

   protected declineRequest(): void {
      const declineModalRef = this.facade.openDeclineWorkRequestModal(
         this.workRequestSubmission()?.workRequestSubmissionId ?? 0,
         this.workRequestSubmission()?.requestTitle ?? "",
         this.workRequestSubmission()?.locationID ?? 0,
      );

      declineModalRef.result.then((declined) => {
         if (declined) {
            this.modalRef.close();

            this.alertService.addAlert(
               this.translationService.i18n().t("RequestDeclined"),
               "success",
               6000,
            );
         }
      });
   }

   protected closeModal(): void {
      this.modalRef.close();
   }
}
