<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="message-area">
            <span class="message" [limbleHtml]="message"></span>
            <lim-ui-icon-button
               icon="circleQuestionRegular"
               (click)="openHelp()"
               class="lim-ui-hide-on-mobile hidePrint"
            />
         </div>
      </lim-ui-info-panel>

      <div class="pre-panel">
         <div class="action-buttons">
            <lim-ui-dropdown>
               <lim-ui-secondary-button button icon="angleDown" id="bulk-actions">
                  {{ lang().BulkActions }}
               </lim-ui-secondary-button>
               <ng-container menu aria-labelledby="bulk-actions">
                  <lim-ui-dropdown-text-item
                     (click)="showMoreThenOneTask()"
                     [displayString]="lang().ShowMoreThanOneTasksQRCodes"
                     limUiTooltip="{{ lang().ShowMoreThanOneTasksQRCodesTooltip }}"
                  />
                  <lim-ui-dropdown-text-item
                     (click)="showMoreThanOneAsset()"
                     [displayString]="lang().ShowMoreThanOneAssetForATaskQRCodes"
                     limUiTooltip="{{
                        lang().ShowMoreThanOneAssetForATaskQRCodesTooltip
                     }}"
                  />
               </ng-container>
            </lim-ui-dropdown>

            <lim-ui-dropdown>
               <lim-ui-secondary-button button icon="angleDown" id="change-type">
                  {{ lang().ChangeTypeOfPMQRCode }}
               </lim-ui-secondary-button>
               <ng-container menu aria-labelledby="change-type">
                  <lim-ui-dropdown-text-item
                     (click)="changeType(1)"
                     [displayString]="lang().StartTask"
                     limUiTooltip="{{ lang().StartPMViaQRCodeTooltip }}"
                  />
                  <lim-ui-dropdown-text-item
                     (click)="changeType(2)"
                     [displayString]="lang().EnableOrDisablePMSchedule"
                     limUiTooltip="{{ lang().EnableOrDisablePMScheduleTooltip }}"
                  />
               </ng-container>
            </lim-ui-dropdown>
         </div>

         <div class="slider-and-text">
            {{ lang().ZoomLevel }}
            <div class="hidePrint slider">
               <lim-ui-slider
                  [min]="10"
                  [max]="90"
                  [step]="5"
                  [formControl]="zoomFormControl"
               />
            </div>
         </div>
      </div>

      @if (loading) {
         <lim-ui-panel>
            <div class="loading-container">
               <lim-ui-loading-animation />
               <div class="progress-bar-container">
                  <lim-ui-progress-bar
                     [progress]="(index / tasksLength) * 100 | betterNumber: '1.0-0'"
                     label="{{ lang().PercentageDone }}"
                  />
               </div>
            </div>
         </lim-ui-panel>
      }

      <lim-ui-scroll-container>
         <div id="printQRContainer" class="print-qr-container" [hidden]="!!loading">
            <lim-ui-panel>
               @for (template of templates | orderBy: "checklistName"; track template) {
                  <div class="qr-section">
                     <div>
                        <h2
                           class="lim-ui-fonts-h2"
                           [limbleHtml]="template.checklistName"
                        ></h2>
                     </div>

                     <div class="content-container">
                        <div class="qr-item">
                           <span class="qr-text">
                              <div>
                                 @if (typeOfQR === "startTask") {
                                    <b>
                                       {{ lang().StartTask }}
                                    </b>
                                 }
                              </div>
                              <div>
                                 @if (typeOfQR === "flipSchedule") {
                                    <b>
                                       {{ lang().EnableOrDisablePMSchedule }}
                                    </b>
                                 }
                              </div>
                              @if (typeOfQR === "startTask") {
                                 <a
                                    href="{{ generateQRCode(template) }}"
                                    target="_blank"
                                    class="hidePrint"
                                 >
                                    {{ generateQRCode(template) }}
                                 </a>
                              }
                              @if (typeOfQR === "flipSchedule") {
                                 <a
                                    href="{{ flipScheduleURL }}"
                                    target="_blank"
                                    class="hidePrint"
                                 >
                                    {{ flipScheduleURL }}
                                 </a>
                              }
                           </span>

                           <div
                              id="qrCodeContainer{{ template.checklistID }}-{{
                                 template.assetID
                              }}"
                           >
                              <div class="qr-code-container">
                                 <div
                                    id="qrCodeTask{{ template.checklistID }}-{{
                                       template.assetID
                                    }}"
                                    class="qr-code"
                                 ></div>
                                 <span class="resizeable-text">
                                    <b>
                                       <span [limbleHtml]="template.checklistName"></span>
                                       @if (
                                          template.assetID &&
                                             manageAsset.getAsset(template.assetID);
                                          as asset
                                       ) {
                                          <span>
                                             -
                                             <span [limbleHtml]="asset.assetName"></span>
                                          </span>
                                       }
                                    </b>
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               }
            </lim-ui-panel>
         </div>
      </lim-ui-scroll-container>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-dropdown>
         <lim-ui-secondary-button
            id="download-info"
            button
            icon="cloudDownloadRegular"
            secondaryIcon="angleDown"
         >
            {{ lang().DownloadUrlsAndInfo }}
         </lim-ui-secondary-button>
         <ng-container menu aria-labelledby="download-info">
            <lim-ui-dropdown-text-item
               (click)="downloadUrls()"
               [displayString]="lang().DownloadUrlsAndInfo"
            />
            <lim-ui-dropdown-text-item
               (click)="downloadAll()"
               [displayString]="lang().DownloadImages"
            />
         </ng-container>
      </lim-ui-dropdown>

      <lim-ui-secondary-button button icon="cloudDownloadRegular" (click)="downloadAll()">
         {{ lang().DownloadImages }}
      </lim-ui-secondary-button>
      <lim-ui-dropdown>
         <lim-ui-primary-button
            id="print-options"
            button
            icon="printRegular"
            secondaryIcon="angleDown"
            dataLogLabel="QRCodes-PrintOptions"
         >
            {{ lang().Print + " " + lang().Options }}
         </lim-ui-primary-button>
         <ng-container menu aria-labelledby="print-options">
            <lim-ui-dropdown-text-item
               print-div
               target="printQRContainer"
               [displayString]="lang().Print"
               data-log="QRCodes-print"
            />
            <lim-ui-dropdown-text-item
               data-log="QRCodes-customPrint"
               (click)="popQRCodesCustomizePrint()"
               [displayString]="lang().Customize + ' ' + lang().Print"
            />
         </ng-container>
      </lim-ui-dropdown>
   </lim-ui-modal-footer>
</lim-ui-modal>
