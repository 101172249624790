import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DraggableDirective, type TreeBranch } from "@limble/limble-tree";
import {
   CheckboxComponent,
   IconComponent,
   MinimalIconButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ColorPickerModule } from "nxt-color-picker";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
   selector: "priority-tree-item",
   templateUrl: "./priorityTreeItem.element.component.html",
   styleUrls: ["./priorityTreeItem.element.component.scss"],
   imports: [
      DraggableDirective,
      IconComponent,
      FormsModule,
      CheckboxComponent,
      ColorPickerModule,
      TooltipDirective,
      MinimalIconButtonComponent,
   ],
})
export class PriorityTreeItem implements OnInit {
   @Input() treeBranch?: TreeBranch<PriorityTreeItem>;
   @Input() updatePriorities;
   @Input() priorities;
   @Input() deletePriority;
   @Input() updateSinglePriority;

   public priority;
   public hexCodeInput;
   public hexCodeOld;

   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.priority = this.treeBranch?.meta().nodeData;
      this.hexCodeInput = this.priority.color;
      this.hexCodeOld = this.priority.color;
   }

   setPriorityDefault = (priority) => {
      this.priorities.forEach((item) => {
         if (item.priorityID === priority.priorityID) {
            item.isDefault = 1;
         } else {
            item.isDefault = 0;
         }
      });

      this.updatePriorities(this.priorities);
   };

   updatePriorityColor = (color) => {
      this.priority.color = color;
      this.updateSinglePriority(this.priority);
   };

   onColorChange = (color) => {
      if (color === this.hexCodeOld) return;

      this.hexCodeOld = color;

      const reg = /^#(?:[0-9a-f]{3}){1,2}$/i;
      if (reg.test(color)) {
         this.updatePriorityColor(color);
      } else {
         this.hexCodeInput = this.priority.color;
         this.alertService.addAlert(this.lang().ColorCodeErrorMessage, "danger", 6000);
      }
   };
}
