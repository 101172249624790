import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

export type LocationCellViewModel = {
   locationName?: string;
};

@Component({
   standalone: true,
   selector: "location-cell",
   templateUrl: "./location-cell.component.html",
})
export class LocationCellComponent {
   @Input({ required: true }) public task!: LocationCellViewModel;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
