import {
   Component,
   DestroyRef,
   effect,
   inject,
   input,
   model,
   type OnInit,
   output,
   signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   DropdownComponent,
   DropdownTextItemComponent,
   LimbleHtmlDirective,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { map } from "rxjs";
import { AssetFieldDefinitionService } from "src/app/assets/services/asset-field-definition.service";

@Component({
   selector: "dropdown-field-filter",
   standalone: true,
   imports: [
      DropdownComponent,
      SecondaryButtonComponent,
      LimbleHtmlDirective,
      DropdownTextItemComponent,
   ],
   templateUrl: "./dropdown-field-filter.component.html",
})
export class DropdownFieldFilterComponent implements OnInit {
   public readonly selected = model("");
   public readonly fieldID = input.required<number>();

   public initialValue = input<string | undefined>("");

   public readonly filterDropdownChange = output<string>();

   private readonly assetFieldDefinitionService = inject(AssetFieldDefinitionService);
   private readonly destroyRef = inject(DestroyRef);

   public options = signal<string[]>([]);

   public constructor() {
      effect(() => {
         const value = this.initialValue();
         if (value?.length) {
            this.setDropdownFilter(value);
         }
      });
   }

   public ngOnInit() {
      if (this.fieldID()) {
         this.assetFieldDefinitionService
            .getDefinition(this.fieldID())
            .pipe(
               takeUntilDestroyed(this.destroyRef),
               map((definition) => {
                  let optionsJSON: Array<any> = [];
                  if (typeof definition?.optionsJSON === "string") {
                     try {
                        optionsJSON = JSON.parse(definition.optionsJSON);
                     } catch {
                        optionsJSON = [];
                     }
                  }
                  return { ...definition, optionsJSON };
               }),
            )
            .subscribe((definition) => {
               this.options.set(
                  definition.optionsJSON
                     .map((item) => item?.name ?? "")
                     .filter((option) => option.length > 0),
               );
            });
      }
   }

   protected setDropdownFilter(option: string) {
      this.selected.set(option);
      this.filterDropdownChange.emit(option);
   }
}
