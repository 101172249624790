import { CurrencyPipe } from "@angular/common";
import { inject, computed, Pipe, type PipeTransform } from "@angular/core";
import { LocaleService } from "src/app/languages/locale/locale.service";
import { CurrencyCodeToRepresentativeLocale } from "src/app/shared/pipes/locale-currency/utils/currency-code-to-representative-locale";
import { LocaleToRepresentativeLocale } from "src/app/shared/pipes/locale-currency/utils/locale-to-representative-locale";
import { RepresentativeLocaleToMinorUnit } from "src/app/shared/pipes/locale-currency/utils/representative-locale-to-minor-unit";

@Pipe({
   name: "localeCurrency",
})
export class LocaleCurrencyPipe implements PipeTransform {
   private readonly localeService = inject(LocaleService);
   private readonly currencyPipe = inject(CurrencyPipe);
   private readonly locale = computed(() => this.localeService.language().locale);

   public transform(
      value: number | string | undefined | null,
      currencyCode: string,
   ): string {
      if (value === undefined || value === null) {
         return "";
      }

      /**
       * Angular CurrencyPipe does not handle XXX currency code as "no currency" for no-english locales.
       * I don't know why. So we handle it manually.
       */
      if (currencyCode === "XXX") {
         return this.formatNoCurrency(value);
      }

      const formattedValue = this.currencyPipe.transform(
         value,
         currencyCode,
         "symbol-narrow",
         undefined,
         CurrencyCodeToRepresentativeLocale[currencyCode] ?? this.locale(),
      );

      return formattedValue ?? "";
   }

   private formatNoCurrency(value: number | string | undefined | null): string {
      const representativeLocale = LocaleToRepresentativeLocale[this.locale()];
      const minorUnit = RepresentativeLocaleToMinorUnit[representativeLocale];

      return new Intl.NumberFormat(representativeLocale, {
         minimumFractionDigits: minorUnit,
         maximumFractionDigits: minorUnit,
         numberingSystem: "latn",
      }).format(Number(value));
   }
}
