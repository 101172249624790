@if (!isLoading) {
   <div class="table-container">
      @if (totalPartLogs === 0) {
         <no-search-results />
      }
      @if (partLogs && columns) {
         <div class="listTable lim-ui-responsive-table">
            @if (!noHeaders) {
               <div
                  [ngClass]="{
                     'listItem listItemHeader modal-no-margin': isInModal,
                     'listItem listItemHeader': !isInModal,
                     'table-scrollable-page-header': tableInScrollablePage,
                  }"
                  class="lim-ui-responsive-table-header scrollable-list-header"
                  [hidden]="!!noSearchResults || partLogs?.length == 0"
               >
                  @for (column of columns; track column) {
                     <sort-column
                        class="col-md-{{ column.columnWidth }}"
                        column="{{ column.sortBy }}"
                        name="{{ column.displayName }}"
                        [bind]="sort"
                        (bindChange)="onSortChanged($event)"
                        [ngStyle]="{
                           'text-align': column.textAlign ? column.textAlign : 'left',
                        }"
                        [hidden]="!!noSearchResults || !partLogs || partLogs.length == 0"
                     />
                  }
               </div>
            }

            <lim-ui-scroll-container
               class="tasks-container"
               [ngClass]="{ 'modal-height': isInModal || autoHeight }"
               [thin]="true"
            >
               @for (partLog of partLogs; track partLog.logID; let isLast = $last) {
                  <div class="table-row-container">
                     <div
                        class="lim-ui-responsive-table-row"
                        [ngClass]="[
                           isInModal ? 'listItem modal-no-margin' : 'listItem',
                           isLast ? 'last' : '',
                        ]"
                     >
                        <div class="col-md-3 lim-ui-responsive-table-cell">
                           {{ partLog?.logDate | betterDate: "dateTime" }}
                        </div>

                        <log-entry-cell
                           class="col-md-6 lim-ui-responsive-table-cell"
                           [log]="partLog"
                           [isAllPartsLog]="isAllPartsLog()"
                        />

                        <div class="col-md-3 lim-ui-responsive-table-cell">
                           <div>
                              <lim-ui-icon
                                 icon="user"
                                 iconSize="small"
                                 class="icon-styling"
                                 placement="top"
                                 limUiTooltip="{{ lang().ThisWasEnteredByAUser }}"
                              />

                              {{ partLog.user?.userFirstName }}
                              {{ partLog.user?.userLastName }}
                           </div>
                        </div>
                     </div>
                  </div>
               }
            </lim-ui-scroll-container>
         </div>
      }

      @if (!isLoading && totalPartLogs > 0) {
         <data-viewer-paginator
            class="table-footer"
            [ngClass]="{
               'pagination-container': !tableInScrollablePage,
               'modal-pagination': isInModal || autoHeight,
            }"
            [totalItems]="totalPartLogs"
            [page]="dataViewerState.page()"
            [pageSize]="dataViewerState.pageSize()"
            (pageChange)="onPageChanged($event)"
            (pageSizeChange)="onPageSizeChanged($event)"
         />
      }

      @if (isLoading) {
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      }
   </div>
} @else {
   <ng-container *ngTemplateOutlet="skeleton" />
}

<ng-template class="listTable" #skeleton>
   <div class="text-center">
      <ngx-skeleton-loader count="1" [theme]="{ width: '30%', height: '20px' }" />
   </div>

   <br /><ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
   <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
   <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />

   <div class="text-center">
      <ngx-skeleton-loader count="1" [theme]="{ width: '30%', height: '20px' }" />
   </div>

   @if (isLoading) {
      <div>
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      </div>
   }
</ng-template>
