import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input, signal, type OnInit } from "@angular/core";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { ManageLang } from "src/app/languages/services/manageLang";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import type { Column } from "src/app/shared/data-viewer/column-builder/column-builder.models";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

export type TaskLaborCostViewModel = {
   laborCost?: number;
   locationID?: number;
};

@Component({
   selector: "task-labor-cost-cell",
   imports: [CommonModule, LocaleCurrencyPipe],
   templateUrl: "./task-labor-cost-cell.component.html",
})
export class TaskLaborCostCellComponent implements OnInit {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;
   private readonly locationID = signal<number | undefined>(undefined);
   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationQueries.detail(this.locationID()),
   );
   protected readonly isCurrencyPending = computed(
      () => this.locationQuery.isPending() || this.accountCurrencyQuery.isPending(),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );

   @Input({ required: true }) public task!: TaskLaborCostViewModel;
   @Input() public column?: Column;

   public ngOnInit(): void {
      this.locationID.set(this.task.locationID);
   }
}
