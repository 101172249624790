<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (message) {
         <lim-ui-info-panel>
            <div class="info-panel-email-template">
               <span class="message" [limbleHtml]="message"></span>

               @if (sendingPOToVendor) {
                  <lim-ui-secondary-button
                     (click)="setPOEmailDefaults()"
                     [limUiTooltip]="lang().SetPOEmailDefaultsHint"
                  >
                     {{ lang().DefaultTemplate }}
                  </lim-ui-secondary-button>
               }
            </div>
         </lim-ui-info-panel>
      }

      <lim-ui-panel>
         @if (pickDate) {
            <div class="email-section pick-date-section">
               <div class="subject-label">{{ lang().Date }}:</div>
               <div class="horizontal-container">
                  <lim-ui-date-picker-input [(model)]="date" />

                  <time-of-day-picker
                     [(tod)]="timeOfDay"
                     [(hour)]="hour"
                     [(minute)]="minute"
                     [(ampm)]="AMPM"
                  />
               </div>
            </div>
         }

         @if (pickRecurringDate) {
            <div class="email-section pick-recurring-section">
               <div class="subject-label">
                  {{ lang().ThisWillScheduleYourNextReminderToGoOutOn }}:
                  @if (nextDate != 0) {
                     <span>{{ nextDate }}</span>
                  }
               </div>

               <div class="email-type-radio-buttons">
                  <lim-ui-radio-button
                     groupID="emailTemplateGroup"
                     [checked]="recurringData.reoccurType === 'disabled'"
                     [label]="lang().Disabled"
                     [(ngModel)]="recurringData.reoccurType"
                     (click)="
                        recurringData.reoccurType = 'disabled';
                        resetRecurringData();
                        calculateSendDate()
                     "
                  />
                  <lim-ui-radio-button
                     groupID="emailTemplateGroup"
                     [checked]="recurringData.reoccurType === 'week'"
                     [label]="lang().Weekly"
                     (click)="
                        recurringData.reoccurType = 'week';
                        resetRecurringData();
                        recurringData.reoccurFld1 = 'mon';
                        calculateSendDate()
                     "
                     [(ngModel)]="recurringData.reoccurType"
                  />
                  <lim-ui-radio-button
                     groupID="emailTemplateGroup"
                     [label]="lang().Monthly"
                     [checked]="recurringData.reoccurType === 'month'"
                     (click)="
                        recurringData.reoccurType = 'month';
                        resetRecurringData();
                        recurringData.reoccurFld1 = 'weekday';
                        recurringData.reoccurFld2 = 'first';
                        recurringData.reoccurFld3 = 'mon';
                        calculateSendDate()
                     "
                  />
               </div>

               @if (recurringData.reoccurType === "week") {
                  <div>
                     <ng-container
                        class="weekly-selection-checklist"
                        radio-button-list
                        groupID="weekSelectingGroup"
                     >
                        @for (day of dayOptions; track day) {
                           <lim-ui-radio-button
                              [label]="dayTranslator[day]"
                              [disabled]="false"
                              [checked]="recurringData.reoccurFld1 == day"
                              (click)="
                                 recurringData.reoccurFld1 = day; calculateSendDate()
                              "
                              groupId="weekSelectingGroup"
                           />
                        }
                     </ng-container>
                  </div>
               }

               @if (recurringData.reoccurType === "month") {
                  <div>
                     <div class="email-type-radio-buttons">
                        <lim-ui-radio-button
                           groupID="monthlyWeekdayNumericGroup"
                           [checked]="recurringData.reoccurFld1 === 'weekday'"
                           [label]="lang().Weekday"
                           [(ngModel)]="recurringData.reoccurFld1"
                           (click)="
                              resetRecurringData();
                              recurringData.reoccurFld1 = 'weekday';
                              recurringData.reoccurFld2 = 'first';
                              recurringData.reoccurFld3 = 'mon';
                              calculateSendDate()
                           "
                        />
                        <lim-ui-radio-button
                           groupID="monthlyWeekdayNumericGroup"
                           [checked]="recurringData.reoccurFld1 === 'numeric'"
                           [label]="lang().Numeric"
                           [(ngModel)]="recurringData.reoccurFld1"
                           (click)="
                              resetRecurringData();
                              recurringData.reoccurFld1 = 'numeric';
                              recurringData.reoccurFld2 = '1';
                              calculateSendDate()
                           "
                        />
                     </div>

                     @if (recurringData.reoccurFld1 === "numeric") {
                        <div>
                           {{ lang().onThe }}
                           <lim-ui-dropdown>
                              <lim-ui-secondary-button button secondaryIcon="angleDown">
                                 <span
                                    [limbleHtml]="
                                       recurringData.reoccurFld2 !== ''
                                          ? dayNumberTranslator[recurringData.reoccurFld2]
                                          : ''
                                    "
                                 ></span>
                              </lim-ui-secondary-button>

                              <div menu>
                                 @for (i of getNumber(31); track i) {
                                    <lim-ui-dropdown-text-item
                                       [displayString]="
                                          dayNumberTranslator
                                             ? dayNumberTranslator[$index + 1]
                                             : ''
                                       "
                                       [outputString]="
                                          dayNumberTranslator
                                             ? dayNumberTranslator[$index + 1]
                                             : ''
                                       "
                                       (click)="
                                          recurringData.reoccurFld2 = (
                                             $index + 1
                                          ).toString();
                                          calculateSendDate()
                                       "
                                    />
                                 }
                              </div>
                           </lim-ui-dropdown>

                           @if (pickTimeOfDay) {
                              <span (click)="timeOfDay = true">
                                 {{ lang().at }} &nbsp;

                                 <div>
                                    <time-of-day-picker
                                       [(tod)]="timeOfDay"
                                       [(hour)]="hour"
                                       [(minute)]="minute"
                                       [(ampm)]="AMPM"
                                    />
                                 </div>
                              </span>
                           }
                        </div>
                     }

                     @if (recurringData.reoccurFld1 === "weekday") {
                        <div>
                           {{ lang().onThe }}

                           <lim-ui-dropdown>
                              <lim-ui-secondary-button button secondaryIcon="angleDown">
                                 <div
                                    [limbleHtml]="
                                       recurringData.reoccurFld2 !== ''
                                          ? dayNumberTranslator[recurringData.reoccurFld2]
                                          : ''
                                    "
                                 >
                                    {{ recurringData.reoccurFld2 }}
                                 </div>
                              </lim-ui-secondary-button>

                              <div menu>
                                 <lim-ui-dropdown-text-item
                                    [displayString]="lang().firstFollowedByDayOfWeek"
                                    [outputString]="lang().firstFollowedByDayOfWeek"
                                    (click)="
                                       recurringData.reoccurFld2 = 'first';
                                       calculateSendDate()
                                    "
                                 />
                                 <lim-ui-dropdown-text-item
                                    [displayString]="lang().secondFollowedByDayOfWeek"
                                    [outputString]="lang().secondFollowedByDayOfWeek"
                                    (click)="
                                       recurringData.reoccurFld2 = 'second';
                                       calculateSendDate()
                                    "
                                 />
                                 <lim-ui-dropdown-text-item
                                    [displayString]="lang().thirdFollowedByDayOfWeek"
                                    [outputString]="lang().thirdFollowedByDayOfWeek"
                                    (click)="
                                       recurringData.reoccurFld2 = 'third';
                                       calculateSendDate()
                                    "
                                 />
                                 <lim-ui-dropdown-text-item
                                    [displayString]="lang().fourthFollowedByDayOfWeek"
                                    [outputString]="lang().fourthFollowedByDayOfWeek"
                                    (click)="
                                       recurringData.reoccurFld2 = 'fourth';
                                       calculateSendDate()
                                    "
                                 />
                              </div>
                           </lim-ui-dropdown>

                           <br />

                           <lim-ui-dropdown>
                              <lim-ui-secondary-button button secondaryIcon="angleDown">
                                 <div
                                    [limbleHtml]="
                                       recurringData.reoccurFld3 !== ''
                                          ? dayTranslator[recurringData.reoccurFld3]
                                          : ''
                                    "
                                 ></div>
                              </lim-ui-secondary-button>

                              <div menu>
                                 <div menu>
                                    @for (day of dayOptions; track day) {
                                       <lim-ui-dropdown-text-item
                                          (click)="
                                             recurringData.reoccurFld3 = day;
                                             calculateSendDate()
                                          "
                                          [displayString]="dayTranslator[day]"
                                          [outputString]="dayTranslator[day]"
                                       />
                                    }
                                 </div>
                              </div>
                           </lim-ui-dropdown>

                           @if (pickTimeOfDay) {
                              <span (click)="timeOfDay = true">
                                 {{ lang().at }} &nbsp;
                                 <time-of-day-picker
                                    [(tod)]="timeOfDay"
                                    [(hour)]="hour"
                                    [(minute)]="minute"
                                    [(ampm)]="AMPM"
                                 />
                              </span>
                           }
                        </div>
                     }
                  </div>
               }
            </div>
         }

         <div class="email-section">
            <div class="subject-label">
               {{ lang().To }}:
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  [hidden]="!allowEditTo"
                  [limUiTooltip]="lang().MultipleEmailsWithSemicolonHint"
               />
            </div>

            <div class="horizontal-container">
               @if (pickEmployees) {
                  <lim-ui-dropdown class="email-dropdown">
                     <lim-ui-secondary-button button secondaryIcon="angleDown">
                        {{ lang().PickEmployees }}
                     </lim-ui-secondary-button>

                     @for (
                        user of users | orderBy: ["userLastName", "userFirstName"];
                        track user
                     ) {
                        <div menu class="checkbox-dropdown-with-user-profile">
                           <lim-ui-checkbox
                              [(model)]="user.selected"
                              (modelChange)="updateRecip()"
                              [disabled]="false"
                           />
                           <user-image [user]="user" />
                           {{ user.userFirstName }} {{ user.userLastName }}
                        </div>
                     }
                  </lim-ui-dropdown>
               }
               <input
                  type="text"
                  class="email-template-input"
                  [disabled]="pickEmployees"
                  ngDefaultControl
                  [(ngModel)]="recipients"
               />

               @if (extraToHint) {
                  <div>
                     {{ extraToHint }}
                  </div>
               }
            </div>
         </div>

         <div class="email-section subject-section">
            <div class="subject-label">{{ lang().Subject }}:</div>

            <div
               class="email-template-input content-editable-input"
               [(ngModel)]="emailSubject"
               ngDefaultControl
               limbleContentEditable
               disableEditor="true"
               stripHtml="true"
            ></div>
         </div>

         <div class="email-section message-section">
            <div class="subject-label">{{ lang().Message }}:</div>

            <div
               type="text"
               class="large-text-field email-template-input message-box"
               [(ngModel)]="emailMessage"
               ngDefaultControl
               limbleContentEditable
               stripHtml="false"
               focusAuto
            ></div>
         </div>

         @if (attachments.length !== 0) {
            <div class="email-section attachments-section">
               <div class="subject-label">{{ lang().Attachments }}:</div>
               @for (attachment of attachments; track attachment) {
                  <div (click)="attachment.downloadClick()">
                     @if (sendingPOToVendor) {
                        <lim-ui-icon icon="filePdf" iconColor="danger" />
                     }

                     {{ attachment.name }}
                  </div>
               }
            </div>
         }
         @if (spinner) {
            <div>
               <lim-ui-loading-animation />
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      dataLogSubmitLabel="manageReminders-storeAReminder"
      [successButtonText]="submitButtonText"
      (successClick)="submit()"
   />
</lim-ui-modal>
