import { NgClass, UpperCasePipe } from "@angular/common";
import type { OnInit } from "@angular/core";
import { Component, computed, forwardRef, inject, input } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { IconComponent, LimbleHtmlDirective, TooltipDirective } from "@limblecmms/lim-ui";
import { catchError, combineLatest, of, startWith, switchMap } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { ViewImage } from "src/app/files/components/viewImage/viewImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { CostViewerComponent } from "src/app/purchasing/currency/components/cost-viewer-component/cost-viewer-component";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { FilterArrayPipe } from "src/app/shared/pipes/filterArray.pipe";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { SecToHoursMinutesPipe } from "src/app/shared/pipes/secToHoursMinutes.pipe";
import { WhiteLabelService } from "src/app/shared/services/white-label/white-label.service";
import type { BulkPrintTasksSettings } from "src/app/shared/types/general.types";
import type { Lookup } from "src/app/shared/utils/lookup";
import {
   ChkItem,
   type TaskInstructionDisplayData,
} from "src/app/tasks/components/chkItemElement/chkItem.element.component";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { TaskViewModelFactoryService } from "src/app/tasks/components/shared/services/task-view-model-factory/task-view-model-factory.service";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api";
import type { TaskComment } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { UpdateTaskStateService } from "src/app/tasks/components/task-form/services/update-task-state.service";
import type { TaskItem } from "src/app/tasks/components/taskItemElement/taskItem.element.component";
import { CommentUtilsService } from "src/app/tasks/services/comment-utils/comment-utils.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskFormSettings } from "src/app/tasks/types/info/task-info.types";
import { ManageUser } from "src/app/users/services//manageUser";

@Component({
   selector: "task-print-template",
   templateUrl: "./task-print-template.component.html",
   styleUrls: ["./task-print-template.component.scss"],
   imports: [
      LimbleHtmlDirective,
      IconComponent,
      forwardRef(() => ChkItem),
      TooltipDirective,
      NgClass,
      FileListItem,
      ViewImage,
      UpperCasePipe,
      BetterCurrencyPipe,
      BetterDatePipe,
      FilterArrayPipe,
      IconAlias,
      SecToHoursMinutesPipe,
      CostViewerComponent,
   ],
})
export class TaskPrintTemplateComponent implements OnInit {
   public readonly taskId = input.required<number>();
   public readonly taskInstructions = input.required<Array<TaskItem>>();
   public readonly displayAssetInfo = input<Record<any, any> | undefined>();
   public readonly checklistTemplate = input.required<number>();
   public readonly informationToInclude = input<BulkPrintTasksSettings | undefined>({
      status: 1,
      priority: 1,
      type: 1,
      assignment: 1,
      location: 1,
      asset: 1,
      dueDate: 1,
      downtime: 1,
      description: 1,
      instructions: 1,
      completionNotes: 1,
      comments: 1,
      parts: 1,
      invoices: 1,
      completedBy: 1,
      completedDate: 1,
      assetInformation: 1,
      timeSpent: 1,
   });
   public readonly taskInfo = input<TaskFormSettings>();
   public readonly taskInstructionsViewParameters = input<TaskInstructionDisplayData>();

   protected readonly assetNameStr = computed(() => {
      const taskData = this.taskData();
      if (!taskData?.assetID) {
         return "";
      }
      return this.manageAsset.getAssetNameIncludeParents(taskData.assetID);
   });
   public categoriesIndex;
   public currentUser;
   public customerID;
   public logoURL;
   public partsLookup: Lookup<"partID", Part>;
   public partCostOnPrintFlag;

   protected readonly crossOriginAnonymous: boolean;
   protected readonly logoURLDark;
   protected useCustomLogo: boolean = false;
   protected customLogoURL: string | undefined;
   protected assetInfoFromCompletionArr = computed(() => {
      const taskData = this.taskData();
      if (!taskData?.assetInfoFromCompletion) {
         return [];
      }
      return JSON.parse(this.taskData()?.assetInfoFromCompletion ?? "{}");
   });

   private readonly manageUser = inject(ManageUser);
   public readonly manageAsset = inject(ManageAsset);
   private readonly whiteLabelService = inject(WhiteLabelService);
   protected readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);
   private readonly tasksApiService = inject(TasksApiService);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly taskViewModelFactoryService = inject(TaskViewModelFactoryService);
   private readonly manageBilling = inject(ManageBilling);
   private readonly manageParts = inject(ManageParts);
   private readonly managePO = inject(ManagePO);
   private readonly manageLocation = inject(ManageLocation);
   private readonly updateTaskStateService = inject(UpdateTaskStateService);
   private readonly commentUtilsService = inject(CommentUtilsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

   private readonly taskId$ = toObservable(this.taskId);
   private readonly taskTemplate$ = toObservable(this.checklistTemplate);
   private readonly taskRequests$ = combineLatest([
      this.taskId$,
      this.taskTemplate$,
      this.updateTaskStateService.buildData$.pipe(startWith(undefined)),
   ]).pipe(
      switchMap(([id, template]) => {
         if (!id) {
            return of(undefined);
         }
         if (template > 0) {
            return this.taskTemplatesApiService.getById(id, {
               columns: `invoices`,
               filters: {
                  allowDeleted: true,
               },
            });
         }
         return this.tasksApiService.getById(id, {
            columns: `locationName,parts,comments,timeSpentSecs,completedBy,invoices,extraTime,partsDetails,assets`,
         });
      }),
      catchError((error) => {
         console.error("Task undefined in taskPrintTemplate: ", error);
         return of(undefined);
      }),
   );
   private readonly taskResponse = toSignal(this.taskRequests$);

   protected readonly taskData = computed(() => {
      const taskResponse = this.taskResponse();
      return taskResponse
         ? this.taskViewModelFactoryService.getTaskDataViewerViewModel(taskResponse)
         : undefined;
   });

   protected readonly assetFieldValues = computed(() => {
      const taskData = this.taskData();
      if (taskData?.assetID && taskData.assets?.length) {
         // We need the local assets as currently the backend does not return the field values.
         const localAssets = taskData.assets
            .map((asset) => {
               return this.manageAsset.getAsset(asset.assetID);
            })
            .filter((asset) => asset !== undefined);
         return localAssets
            .map((asset) => {
               if (!asset.assetValueIDs) {
                  return [];
               }
               const assetValueIDs = asset.assetValueIDs
                  .map((valueID) => {
                     return this.manageAsset.getFieldValue(valueID);
                  })
                  .filter((value) => value !== undefined);
               return assetValueIDs;
            })
            .filter((value) => value !== undefined)
            .reduce((acc, val) => acc.concat(val), []);
      }
      return [];
   });

   public constructor() {
      this.crossOriginAnonymous = /Chrome|FireFox/.test(window.navigator.userAgent);
      this.logoURLDark = this.whiteLabelService.logoUrlDark();
      this.partsLookup = this.manageParts.getParts();
      this.currentUser = this.manageUser.getCurrentUser();
      this.customerID = this.currentUser.userInfo.customerID;
      this.setCustomLogo(this.currentUser.userInfo.customLogoFileName);
   }

   public ngOnInit() {
      this.categoriesIndex = this.manageBilling.getCategoriesIndex();
      this.partCostOnPrintFlag = Number(
         this.manageUser.getCurrentUser().userInfo.partCostOnPrintFlag,
      );
   }

   protected getPartLocationName(locationID: number) {
      return this.manageLocation.getLocation(locationID)?.locationName;
   }

   protected getPoNumber(poItemID: number) {
      const purchaseOrderItemID = this.managePO.getPurchaseOrderItem(poItemID)?.poID;
      if (!purchaseOrderItemID) {
         return undefined;
      }
      const purchaseOrder = this.managePO.getPurchaseOrder(purchaseOrderItemID);
      return purchaseOrder?.poNumber;
   }

   protected getPoState(poItemID: number) {
      const purchaseOrderItemID = this.managePO.getPurchaseOrderItem(poItemID)?.poID;
      if (!purchaseOrderItemID) {
         return undefined;
      }
      return this.managePO.getPurchaseOrderCurrentState(purchaseOrderItemID)?.name;
   }

   private setCustomLogo(fileName: string | null) {
      if (!fileName) {
         this.useCustomLogo = false;
         this.customLogoURL = "";
         return;
      }
      this.customLogoURL = `viewFile.php?f=upload-${this.customerID}/customLogo/${fileName}`;
      this.useCustomLogo = true;
   }

   protected getUserFullName(user) {
      if ("deleted" in user) {
         return undefined;
      }
      return `${user.firstName} ${user.lastName}`;
   }
   protected isCommentVisible(comment: TaskComment): boolean {
      return this.commentUtilsService.isCommentVisible(comment, this.currentUser);
   }
}
