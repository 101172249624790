import { inject, Injectable } from "@angular/core";
import { isMobile } from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import clone from "rfdc";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import type {
   GeoCoordinates,
   GeoFeature,
   GeoFeatureCollection,
} from "src/app/maps/types/geoMap.types";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import type { TaskLookup } from "src/app/tasks/types/task.types";

const deepClone = clone();

@Injectable({ providedIn: "root" })
export class ManageMaps {
   private readonly axios = axios;
   private readonly geoMaps: { arr: Array<any>; index: object };
   public win: any = window;

   private readonly manageObservables = inject(ManageObservables);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageLang = inject(ManageLang);
   private readonly manageAsset = inject(ManageAsset);

   public constructor() {
      this.geoMaps = {
         arr: [],
         index: {},
      };
      this.createObservables();
   }

   private createObservables() {
      this.manageObservables.createObservable("maps");
   }

   private updateObservables() {
      this.manageObservables.updateObservable("maps", null);
   }

   public async getMaps() {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: { action: "getMaps" },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.processMapData(answer.data.maps);
         }
      });

      return post;
   }

   private processMapData(geoMaps: Array<any>) {
      for (const map of geoMaps) {
         if (map.layers === undefined) {
            map.layers = [];
         }
         for (const layer of map.layers) {
            //debugging tool to see which custom map layer failed
            const printError = (error, explicit) => {
               console.error(
                  `[${explicit ? "EXPLICIT" : "INEXPLICIT"}] ${error.name}: ${
                     error.message
                  }`,
               );
            };
            try {
               layer.layer_data = JSON.parse(layer.layer_data);
            } catch (event) {
               if (event instanceof SyntaxError) {
                  printError(event, true);
               } else {
                  printError(event, false);
               }
            }
         }
      }

      this.geoMaps.arr = geoMaps ?? [];
      for (const map of this.geoMaps.arr) {
         this.geoMaps.index[map.mapID] = map;
      }
      this.updateObservables();
   }

   public getMapsArr() {
      return this.geoMaps.arr;
   }

   public getMapsIndex() {
      return this.geoMaps.index;
   }

   public async addLayer(map: any, name: string, data: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "addLayer",
         },
         data: {
            name: name,
            mapID: map.mapID,
            data: data,
         },
      });
   }

   public async updateLayer(layer: any, data: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "updateLayer",
         },
         data: {
            map_layerID: layer.map_layerID,
            data: data,
            name: data.name,
         },
      });
   }

   public async deleteLayer(layer: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "deleteLayer",
         },
         data: {
            map_layerID: layer.map_layerID,
         },
      });
   }

   public async addMap() {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "addMap",
         },
         data: {},
      });
   }

   public async updateMapName(map: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "updateMapName",
         },
         data: {
            mapID: map.mapID,
            mapName: map.mapName,
         },
      });
   }

   public async deleteMap(map: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "deleteMap",
         },
         data: {
            mapID: map.mapID,
         },
      });
   }

   public async copyMap(map: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "copyMap",
         },
         data: {
            mapID: map.mapID,
         },
      });
   }

   public async shareMap(users, map: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "shareMap",
         },
         data: {
            users: users,
            mapID: map.mapID,
         },
      });
   }

   public async removeShare(userID: number, map: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "removeShare",
         },
         data: {
            userID: userID,
            mapID: map.mapID,
         },
      });
   }

   public async saveAssetGeo(assetID: number, geoJSON: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "saveAssetGeo",
         },
         data: {
            assetID: assetID,
            geoJSON: geoJSON,
         },
      });
   }

   public async saveTaskGeo(taskID: number, geoJSON: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "saveTaskGeo",
         },
         data: {
            taskID: taskID,
            geoJSON: geoJSON,
         },
      });
   }

   public async saveLocationGeo(locationID: number, geoJSON: any) {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageMaps.php",
         params: {
            action: "saveLocationGeo",
         },
         data: {
            locationID: locationID,
            geoJSON: geoJSON,
         },
      });
   }

   public getGISLayerForAssets(assets: any, data: any) {
      const featureCollection: GeoFeatureCollection = {
         type: "FeatureCollection",
         features: [],
         properties: {},
      };
      for (const asset of assets) {
         if (asset?.geoLocation) {
            asset.geoLocation.properties.name = asset.assetName;
            asset.geoLocation.properties.id = asset.assetID;
            asset.geoLocation.properties.type = "asset";
            asset.geoLocation.properties.customIcon = data.customIcon;
            asset.geoLocation.properties.customColor = data.customColor;
            asset.geoLocation.properties.popupIcon = "fa-solid fa-cube fa-fw";
            featureCollection.features.push(asset.geoLocation);
         }
      }
      return featureCollection;
   }

   public getGISLayerForTasks(tasks: TaskLookup, data: any): GeoFeatureCollection {
      const featureCollection: GeoFeatureCollection = {
         type: "FeatureCollection",
         features: [],
         properties: {},
      };
      const prioritiesIndex = this.managePriority.getPriorityListIndex();
      const noPriorityColor = getComputedStyle(document.documentElement).getPropertyValue(
         "--lim-el-no-priority-color",
      );

      for (const task of tasks) {
         const popupIcon = this.getTaskIcon(task);
         let customColor = data.customColor;
         if (data.colorType === "priorityColor" && task.priorityID) {
            customColor = prioritiesIndex[task.priorityID]
               ? prioritiesIndex[task.priorityID].color
               : noPriorityColor;
         } else if (data.colorType === "dueDateStatusColor") {
            const chkColor = Number(task.checklistColor);
            if (chkColor === 1) {
               customColor = "#429b1f";
            } else if (chkColor === 2) {
               customColor = "#efa131";
            } else {
               customColor = "#d81f1f";
            }
         }

         // CASE-2907: If the task is tied to an asset with a geoLocation property,
         // the asset's geoLocation property takes priority over the task's geoLocation property.
         // This mirrors how we handle geoLocation priority in chk.wrapper.component's setGeolocation()
         if (task.assetID != null && task.assetID > 0) {
            const asset = this.manageAsset.getAsset(task.assetID);
            if (asset?.geoLocation?.properties) {
               task.geoLocation = deepClone(asset.geoLocation);
            }
         }

         if (task?.geoLocation?.properties) {
            task.geoLocation.properties.name = task.checklistName ?? "";
            task.geoLocation.properties.id = task.checklistID;
            task.geoLocation.properties.type = "task";
            task.geoLocation.properties.customIcon = data.customIcon;
            task.geoLocation.properties.customColor = customColor;
            task.geoLocation.properties.popupIcon = popupIcon;
            featureCollection.features.push(task.geoLocation);
         }
      }

      return featureCollection;
   }

   public getTaskIcon(task: any) {
      if (task.checklistTemplateOld == 1) {
         return "fa-solid fa-wrench fa-fw";
      }
      if (
         task.checklistTemplateOld == 2 &&
         !(task.checklistBatchID == 10000 || task.checklistBatchID == 300112)
      ) {
         return "fa-brands fa-wpforms fa-fw";
      }
      if (task.checklistTemplateOld == 4) {
         return "fa-regular fa-file fa-fw";
      }
      if (task.checklistTemplateOld == 5) {
         return "fa-solid fa-gears fa-fw";
      }
      if (
         task.checklistTemplateOld == 2 &&
         (task.checklistBatchID == 10000 || task.checklistBatchID == 300112)
      ) {
         return "fa-solid fa-triangle-exclamation fa-fw";
      }
      return "fa-solid fa-list-ol fa-fw";
   }

   public isTheSameGeoJSON(JSON1: any, JSON2: any) {
      if (
         JSON1?.geometry?.coordinates?.[0] === JSON2?.geometry?.coordinates?.[0] &&
         JSON1?.geometry?.coordinates?.[1] === JSON2?.geometry?.coordinates?.[1] &&
         JSON1?.properties?.radius === JSON2?.properties?.radius
      ) {
         return true;
      }
      return false;
   }

   public async getDeviceLocation(): Promise<any> {
      const geoData = await this.getLocationFromBrowserOrNative();
      if (this.win.locationUpdated) {
         this.win.locationUpdated = null;
      }
      return geoData;
   }

   public async getLocationFromBrowserOrNative(): Promise<any> {
      return new Promise((resolve, reject) => {
         const DESIRED_ACCURACY_IN_METERS = 20;
         const TIMEOUT_MS = 2000;
         const MAX_TIMEOUT_MS = 3000;
         const startTime = Date.now();

         const successCallback = (position: GeolocationPosition) => {
            const accuracy = position.coords.accuracy;
            if (accuracy <= DESIRED_ACCURACY_IN_METERS) {
               navigator.geolocation.clearWatch(watchId);
               resolve(position);
            } else if (Date.now() - startTime > TIMEOUT_MS) {
               navigator.geolocation.clearWatch(watchId);
               console.warn(`Geolocation accuracy is ${accuracy.toFixed(1)} meters)`);
               resolve(position);
            }
         };

         const errorCallback = (error: GeolocationPositionError) => {
            navigator.geolocation.clearWatch(watchId);
            reject(new Error(error.message));
         };

         const watchId = navigator.geolocation.watchPosition(
            successCallback,
            errorCallback,
            {
               maximumAge: 60000,
               timeout: MAX_TIMEOUT_MS,
               enableHighAccuracy: true,
            },
         );
      });
   }

   public isDeviceGeoEnabled() {
      if (!navigator.geolocation) {
         return false;
      }
      return true;
   }

   public async getDeviceLocationInGeoJsonCoordsFormat(): Promise<
      GeoCoordinates | undefined
   > {
      let currentGeo;
      try {
         currentGeo = await this.getDeviceLocation();
      } catch (err) {
         console.error("Device Location Error:", err);
      }
      if (currentGeo?.coords?.longitude) {
         return [currentGeo.coords.longitude, currentGeo.coords.latitude];
      } else if (currentGeo?.locations?.[0]?.longitude) {
         return [currentGeo.locations[0].longitude, currentGeo.locations[0].latitude];
      }
      return undefined;
   }

   public async getDeviceLocationInGeoFeatureFormat(): Promise<GeoFeature | undefined> {
      const geoCoords = await this.getDeviceLocationInGeoJsonCoordsFormat();
      if (!geoCoords) {
         return undefined;
      }

      return {
         type: "Feature",
         geometry: { type: "Point", coordinates: geoCoords },
         properties: { name: this.manageLang.lang()?.MyLocation ?? "" },
      };
   }

   //This function takes in latitude and longitude of two locations and returns the distance between them (in meters)
   // point is a geoJson coordinates object which is an array [longitude, latitude]
   public calcDistanceBetweenTwoPoints(
      point1: GeoCoordinates,
      point2: GeoCoordinates,
   ): number {
      const [lon1, lat1] = point1;
      const [lon2, lat2] = point2;
      const EARTH_RADIUS_METERS = 6371000;
      const distanceLat = this.toRad(lat2 - lat1);
      const distanceLon = this.toRad(lon2 - lon1);
      const lat1InRad = this.toRad(lat1);
      const lat2Inrad = this.toRad(lat2);

      const distance1 =
         Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
         Math.sin(distanceLon / 2) *
            Math.sin(distanceLon / 2) *
            Math.cos(lat1InRad) *
            Math.cos(lat2Inrad);
      const distance2 = 2 * Math.atan2(Math.sqrt(distance1), Math.sqrt(1 - distance1));
      const finalDistance = EARTH_RADIUS_METERS * distance2;
      return finalDistance;
   }

   // Converts numeric degrees to radians
   private toRad(value) {
      return (value * Math.PI) / 180;
   }

   public checkIfImCloseEnough(
      geoToCheck: GeoCoordinates | Array<GeoCoordinates> | Array<Array<GeoCoordinates>>,
      currentGeo: GeoCoordinates,
   ): boolean {
      const distance = this.calcGeoDistance(geoToCheck, currentGeo);
      if (distance === true) {
         return true;
      } else if (typeof distance === "number" && distance <= 10) {
         return true;
      }
      return false;
   }

   // when the geoCoordinates come from a point it comes in as type GeoCoordinates
   // when the geoCoordinates come from a line it comes in as type Array<GeoCoordinates>
   // when the geoCoordinates come from a multi-point it comes in as type Array<Array<GeoCoordinates>> with all points on the first item of the first Array.
   public calcGeoDistance(
      geoToCheck: GeoCoordinates | Array<GeoCoordinates> | Array<Array<GeoCoordinates>>,
      currentGeo: GeoCoordinates,
   ): number | boolean {
      if (Array.isArray(geoToCheck[0]) && geoToCheck.length === 1) {
         return this.calcIfPointWithinPolygon(
            geoToCheck[0] as Array<GeoCoordinates>,
            currentGeo,
         );
      } else if (Array.isArray(geoToCheck[0]) && geoToCheck.length > 1) {
         return this.calcDistanceBetweenBoundary(
            geoToCheck as Array<GeoCoordinates>,
            currentGeo,
         );
      }
      return this.calcDistanceBetweenTwoPoints(geoToCheck as GeoCoordinates, currentGeo);
   }

   public calcDistanceBetweenBoundary(
      geoToCheck: Array<GeoCoordinates>,
      currentGeo: GeoCoordinates,
   ) {
      let closestDistance;
      for (const point of geoToCheck) {
         const tempDistance = this.calcDistanceBetweenTwoPoints(point, currentGeo);
         if (!closestDistance || (closestDistance && closestDistance > tempDistance)) {
            closestDistance = tempDistance;
         }
      }
      return closestDistance;
   }

   public calcIfPointWithinPolygon(
      geoToCheck: Array<GeoCoordinates>,
      currentGeo: GeoCoordinates,
   ): boolean {
      const lat: number = currentGeo[1];
      const lon: number = currentGeo[0];

      let inside = false;
      for (
         let index1 = 0, index2 = geoToCheck.length - 1;
         index1 < geoToCheck.length;
         index2 = index1++
      ) {
         const latIndex1: number = geoToCheck[index1][1];
         const lonIndex1: number = geoToCheck[index1][0];
         const latIndex2: number = geoToCheck[index2][1];
         const lonIndex2: number = geoToCheck[index2][0];

         const intersect =
            lonIndex1 > lon != lonIndex2 > lon &&
            lat <
               ((latIndex2 - latIndex1) * (lon - lonIndex1)) / (lonIndex2 - lonIndex1) +
                  latIndex1;
         if (intersect) inside = !inside;
      }

      return inside;
   }

   /**
    * @param geoToCheck
    * When the geoCoordinates come from a point it comes in as type `GeoCoordinates`;
    * when the geoCoordinates come from a line it comes in as type `Array<GeoCoordinates>`;
    * and when the geoCoordinates come from a multi-point it comes in as type
    * `Array<Array<GeoCoordinates>>` with all points on the first item of the first Array.
    */
   public navigateToGeolocation(
      geoToCheck: GeoCoordinates | Array<GeoCoordinates> | Array<Array<GeoCoordinates>>,
   ) {
      let geoCoords;
      if (Array.isArray(geoToCheck[0]) && geoToCheck.length === 1) {
         geoCoords = geoToCheck[0][0];
      } else if (Array.isArray(geoToCheck[0]) && geoToCheck.length > 1) {
         geoCoords = geoToCheck[0];
      } else {
         geoCoords = geoToCheck;
      }
      const href = `https://maps.google.com/?q=${geoCoords[1]},${geoCoords[0]}`;
      if (isMobile()) {
         window.location.href = href;
      } else {
         window.open(href, "_blank");
      }
   }
}
