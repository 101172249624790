import { computed, inject, Injectable } from "@angular/core";
import { ModalService } from "@limblecmms/lim-ui";
import type { NgxSkeletonLoaderConfigTheme } from "ngx-skeleton-loader";
import { ManageLang } from "src/app/languages/services/manageLang";
import { VideoPlayerModal } from "src/app/shared/components/global/video-player-modal/video-player-modal.component";
import { ParamsService } from "src/app/shared/services/params.service";
import type { Video } from "src/app/shared/types/general.types";
import * as XLSX from "xlsx";

/**
 * @deprecated
 * This service has very low cohesion. Please consider moving
 * methods out of this service instead of using them as-is.
 */
@Injectable({ providedIn: "root" })
export class ManageUtil {
   private readonly manageLang = inject(ManageLang);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly lang = computed(() => this.manageLang.lang() ?? {});

   public getRandomString(strLength = 8, currStr = "", pass = 0): string {
      let randStr: string;
      if (pass === 0) {
         randStr =
            Math.random().toString(36).substring(2) + Date.now().toString(36) + currStr;
      } else {
         randStr = Math.random().toString(36).substring(2) + currStr;
      }
      if (randStr.length >= strLength) return randStr.slice(0, strLength);
      return this.getRandomString(strLength, randStr, pass + 1);
   }

   public objsToExcel<T>(
      myObjects: Array<Array<T>>,
      titleIn: string,
      fileName: string,
   ): void {
      const listOfFields = this.fixFieldsWithTooManyCharacters(myObjects);

      let title = titleIn;
      /* make the worksheet */
      const workbook = XLSX.utils.book_new();
      let counter = 1;
      for (const myObject of listOfFields) {
         const worksheet = XLSX.utils.json_to_sheet(myObject);

         if (title.length > 27) {
            //safety check to make sure the title isn't too long.  If it is too long it will crash the library
            title = title.substring(0, 27);
         }
         title = `${title}(${counter})`;

         /* add to workbook */
         XLSX.utils.book_append_sheet(workbook, worksheet, title);
         counter++;
      }

      /* write workbook */
      XLSX.writeFile(workbook, fileName, { type: "buffer" });
   }

   private fixFieldsWithTooManyCharacters<T>(listOfObjects: Array<T>): Array<T> {
      listOfObjects.forEach((object) => {
         for (const property in object) {
            const text = String(object[property]);
            const lengthOfText = text.length;

            if (lengthOfText >= 32767) {
               // Cells with this value will be ignored on import
               object[property] = this.lang()
                  .ExcelHasLimitOfCharacters as unknown as T[typeof property];
            }
         }
      });

      return listOfObjects;
   }

   public objToExcel<T>(
      myObject: Array<T>,
      titleIn: string,
      fileName: string,
      keepNumbers: boolean = false,
   ): void {
      // Make sure no fields are too long
      const listOfFields = this.fixFieldsWithTooManyCharacters(myObject);

      let title = titleIn;

      let worksheet: XLSX.WorkSheet;

      if (keepNumbers) {
         /* make the worksheet with number type preservation */
         worksheet = XLSX.utils.json_to_sheet(listOfFields, {
            cellDates: true,
         });

         // Convert numeric strings to numbers in the worksheet
         Object.keys(worksheet).forEach((cell) => {
            if (cell.startsWith("!")) return; // Skip special keys
            const value = worksheet[cell].v;
            if (typeof value === "string" && !isNaN(Number(value))) {
               worksheet[cell].t = "n"; // Set cell type to number
               worksheet[cell].v = Number(value);
            }
         });
      } else {
         /* make the worksheet */
         worksheet = XLSX.utils.json_to_sheet(listOfFields);
      }

      if (title.length > 31) {
         //safety check to make sure the title isn't too long.  If it is too long it will crash the library
         title = title.substring(0, 31);
      }

      /* add to workbook */
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, title);

      /* write workbook */
      XLSX.writeFile(workbook, fileName, { type: "buffer" });
   }

   //this simply cleans up any duplicate whitespace
   public removeDuplicateSpaces(str: string): string {
      if (typeof str === "string") {
         return str.replace(/\s+/g, " ").replace(/^\s+|\s+$/, "");
      }
      return "";
   }

   public checkEmail(email: string): boolean {
      const email_regexp =
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return email_regexp.test(String(email).toLowerCase());
   }

   public splitArr<T>(arrToSplit: Array<T>, chunk: number): Array<Array<T>> {
      const batches: Array<Array<T>> = [];
      let index: number;
      for (index = 0; index < arrToSplit.length; index += chunk) {
         batches.push(arrToSplit.slice(index, index + chunk));
      }
      return batches;
   }

   public stripTags(str: string): string {
      const html = str;
      const div = document.createElement("div");
      div.innerHTML = html;
      const returnStr = div.textContent ?? div.innerText ?? "";
      return returnStr;
   }

   public stripTagsInObj(obj: { [key: string]: string }): { [key: string]: string } {
      for (const key in obj) {
         if (!obj[key]) {
            continue;
         }

         obj[key] = this.stripTags(obj[key]);
      }

      return obj;
   }

   /**
    * @deprecated
    * This method is deprecated and should be removed.
    * Import SkeletonLoaderThemes directly instead.
    */
   public generateSkeletonLoaderThemes(): Record<string, NgxSkeletonLoaderConfigTheme> {
      const skeletonRegular = {
         regular: { width: "20%", height: "15px", border: "0px" },
         short: { width: "15%", height: "15px", border: "0px" },
         long: { width: "60%", height: "15px", border: "0px" },
         large: { width: "90%", height: "25px", border: "0px" },
         fillSize: { height: "15px", border: "0px" },
         set150: { width: "150px", height: "15px", border: "0px" },
         set75: { width: "75px", height: "15px", border: "0px" },
         buttonRegular: {
            "width": "100px",
            "height": "25px",
            "border-radius": "15px",
            "margin-left": "2px",
            "border": "0px",
         },
         buttonLong: {
            "width": "200px",
            "height": "25px",
            "border-radius": "15px",
            "margin-left": "2px",
            "border": "0px",
         },
         buttonShort: {
            "width": "30px",
            "height": "25px",
            "border-radius": "15px",
            "margin-left": "2px",
            "border": "0px",
         },
      };
      return skeletonRegular;
   }

   public processGetData<T>(headers: Array<keyof T>, data: Array<Array<any>>): Array<T> {
      const partsTemp: Array<T> = [];
      for (const datum of data) {
         const obj: Partial<T> = {};
         for (const [index, header] of headers.entries()) {
            obj[header] = datum[index];
         }
         partsTemp.push(obj as T);
      }
      return partsTemp;
   }

   public createUploadObjImage(): {
      deleteData: Record<string, never>;
      maxFiles: 1;
      uploadTypes: "images";
   } {
      return {
         deleteData: {},
         maxFiles: 1,
         uploadTypes: "images",
      };
   }

   public timestampToDateStr(timestamp: number, includeHoursMinSec = false): string {
      const date = new Date(timestamp * 1000);
      let str;
      str = `${date.getFullYear()}/`;
      const month = date.getMonth() + 1;

      if (month < 10) {
         str += `0${month}`;
      } else {
         str += month;
      }

      str += "/";

      if (date.getDate() < 10) {
         str += `0${date.getDate()}`;
      } else {
         str += date.getDate();
      }

      if (includeHoursMinSec) {
         str += " ";
         let ampm = "AM";
         let hours = date.getHours();
         if (hours > 12) {
            ampm = "PM";
            hours = hours - 12;
         } else {
            ampm = "AM";
         }

         if (hours < 10) {
            str += `0${hours}:`;
         } else {
            str += `${hours}:`;
         }

         const minutes = date.getMinutes();
         if (minutes < 10) {
            str += `0${minutes}:`;
         } else {
            str += `${minutes}:`;
         }

         const seconds = date.getSeconds();
         if (seconds < 10) {
            str += `0${seconds}:`;
         } else {
            str += `${seconds}:`;
         }

         str += ` ${ampm}`;
      }

      return str;
   }

   public removeFromArray<T>(array: Array<T>, prop: keyof T, val: T[keyof T]): void {
      for (const key of array.keys()) {
         if (array[key][prop] == val) {
            array.splice(key, 1);
            return;
         }
      }
   }

   public padString(num: number, size: number): string {
      let str = `${num}`;
      while (str.length < size) str = `0${str}`;
      return str;
   }

   public alphabeticalSort<T>(list: Array<T>, tagName: keyof T): Array<T> {
      return list.sort((item1, item2) => {
         // eslint-disable-next-line no-nested-ternary -- Bryan approved
         return item1[tagName] < item2[tagName]
            ? -1
            : item1[tagName] > item2[tagName]
              ? 1
              : 0;
      });
   }

   public checkResetUserClicks(list: Array<any>, userClicks: number): number {
      //this function is used to reset how many times the User has clicked in a selectable list.
      // This helps us have the functionaly so that if they double click something it will either highlight/not hightlight or submit it
      let resetUserClicks = true;

      for (const item of list) {
         if (item.selected === true) {
            resetUserClicks = false;
         }
      }

      if (resetUserClicks) {
         return 0;
      }

      // We don't need to reset, so just return the original number
      return userClicks;
   }

   public watchVideo(video: Video): void {
      const instance = this.modalService.open(VideoPlayerModal);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: video,
         },
      };
   }

   public getDateStringMap(dateData?): Record<string, string> {
      const lang = this.lang();
      const dateStrObject = {
         "All Time": lang.AllTime,
         "Today": lang.Today,
         "Tomorrow": lang.Tomorrow,
         "Yesterday": lang.Yesterday,
         "This Week": lang.ThisWeek,
         "Last Week": lang.LastWeek,
         "Next Week": lang.NextWeek,
         "This Month": lang.ThisMonth,
         "Next Month": lang.NextMonth,
         "Last Month": lang.LastMonth,
         "Next 7 Days": lang.Next7Days,
         "Next 30 Days": lang.Next30Days,
         "Next 60 Days": lang.Next60Days,
         "Next 90 Days": lang.Next90Days,
         "Next 180 Days": lang.Next180Days,
         "Next 365 Days": lang.Next365Days,
         "Last 7 Days": lang.Last7Days,
         "Last 30 Days": lang.Last30Days,
         "Last 60 Days": lang.Last60Days,
         "Last 90 Days": lang.Last90Days,
         "Last 180 Days": lang.Last180Days,
         "Last 365 Days": lang.Last365Days,
         "This Year": lang.ThisYear,
         "Last Year": lang.LastYear,
         "This Fiscal Year": lang.ThisFiscalYear,
         "Last Fiscal Year": lang.LastFiscalYear,
         "Custom Date Range": lang.CustomDateRange,
      };

      if (dateData) {
         return {
            ...dateStrObject,
            "Next X Days": `${lang.Next} ${dateData.dateNextDays} ${lang.Days}`,
            "Last X Days": `${lang.Last} ${dateData.dateLastDays} ${lang.Days}`,
            "Last X Hours": `${lang.Last} ${dateData.dateLastHours} ${lang.Hours}`,
            "Next X Weeks": `${lang.Next} ${dateData.dateNextWeeks} ${lang.Weeks}`,
            "Last X Weeks": `${lang.Last} ${dateData.dateLastWeeks} ${lang.Weeks}`,
            "Next X Months": `${lang.Next} ${dateData.dateNextMonths} ${lang.Months}`,
            "Last X Months": `${lang.Last} ${dateData.dateLastMonths} ${lang.Months}`,
         };
      }
      return dateStrObject;
   }

   public checkIfSuperUser(currentUser): boolean {
      return currentUser.profileArr.includes(7) || currentUser.profileParents.includes(7);
   }

   public decodeHTMLEntities(htmlString: string): string {
      const textArea = document.createElement("textarea");
      textArea.innerHTML = htmlString;
      return textArea.value;
   }
}
