<lim-ui-scroll-container>
   <div class="step-body">
      <div class="alert-wrapper">
         <lim-ui-alert alertType="success" displayType="banner">
            <span>{{ i18n().t("PMTemplateCreatedReview") }}</span>
         </lim-ui-alert>
      </div>
      <task-form [data]="taskInput()" class="chk-wrapper" />
   </div>
</lim-ui-scroll-container>
<div class="step-footer">
   <lim-ui-outlined-button color="red" (click)="deleteTemplate.emit()">
      {{ i18n().t("DeleteTemplate") }}
   </lim-ui-outlined-button>
   <lim-ui-primary-button (click)="nextStep.emit()">
      {{ i18n().t("Next") + ": " + i18n().t("Schedules") }}
   </lim-ui-primary-button>
</div>
