<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      [title]="title()"
      (closeModal)="close()"
      class="modal-header"
      id="pickPOItems"
   />

   <lim-ui-modal-body class="modal-body">
      <lim-ui-panel>
         <receive-purchase-order-items [poID]="poID()" (navigate)="close()" />
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-basic-modal-footer
      class="modal-footer"
      [successButtonText]="i18n().t('Submit')"
      (successClick)="submit()"
   />
</lim-ui-modal>
