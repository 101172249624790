import type { Aliases, Colors } from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import type { Moment } from "moment";
import type { ReplaySubject } from "rxjs";
import type { Asset } from "src/app/assets/types/asset.types";
import type { LanguageDictionary } from "src/app/languages/types/language.types";
import type { Category } from "src/app/parts/types/category/category.types";
import type { PartField } from "src/app/parts/types/field/field.types";
import type { Part } from "src/app/parts/types/part.types";
import type { Bill } from "src/app/purchasing/types/bill.types";
import type { ManageSchedule } from "src/app/schedules/manageSchedule";
import type { Lookup } from "src/app/shared/utils/lookup";
import type { SubscriptionHandles } from "src/app/subscriptions/services/manageSubscription";
import type { TaskSeasons } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { ManageTask } from "src/app/tasks/services/manageTask";
import type { ExtraTime } from "src/app/tasks/types/extra-time/extra-time.types";
import type { VendorField } from "src/app/vendors/types/field/field.types";
import type { Vendor } from "src/app/vendors/types/vendor.types";

export interface HierarchyOptions {
   idKey: string;
   selection?: {
      submitOnClick?: boolean;
      singleSelection?: boolean;
      selectionDisabled?: boolean;
   };
   nodeButtons?: Array<{
      tooltip?: string;
      permissionNumber?: number;
      clickFunction?: (node: any) => void | Promise<void>;
      permissionValidated?: boolean;
      icon?: Aliases;
      iconColor?: "danger";
      text?: string;
   }>;
   onSelect?: (arg1?) => void;
   deselectAllAssetNodes?: () => void;
   submit?: (id?: number) => void | Promise<void>;
   isDisabled?: (nodeToToggle: any) => boolean;
}

export interface HierarchyNode {
   collapsed: boolean;
   selected: boolean;
   nodes: Array<HierarchyNode>;
   searchFound?: boolean;
   searchHint?: string;
   icon: Aliases;
   iconColor?: Colors;
   titleColor?: "asset";
   title: string;
   displayButtons?: boolean;
   nodeID?: number;
   locationID?: number;
   assetID?: number;
   regionID?: number;
   unselectable?: boolean;
   preventSelection?: boolean; //for items that could, under other circumstances, be selectable
   pagination?: {
      page: number;
      total: number;
   };
   includeShowMore?: boolean;
   name?: string;
   isLoading?: boolean;
}

export interface DepreciationOptions {
   purchaseCost?: number;
   depreciationStartDate?: Date;
   standardUsefulLife?: number;
   salvageValue?: number;
   userIDForTaskAssignment?: number;
   profileIDForTaskAssignment?: number;
   scheduleActive?: boolean;
}

export interface PaywallTrialOptions {
   trialTier: SubscriptionHandles;
   message: string;
   offerTrial: boolean;
}
export type SeasonType = "season" | "holiday";
export type SeasonEventType = "normal" | "skip" | "dayBefore" | "dayAfter";

/**
 * The DateFormatType tells us how we can format the date.
 * Note that the user can change how these dates are displayed in the configuration settings.
 * Passing these types into the betterDate pipe tells Angular what information we want to display.
 * Here is how they are translated:
 * | FORMAT               | DateFormatType Equivalent | Example                 |
 * | -------------------- | ------------------------- | ----------------------- |
 * | yyyy/MM/dd h:mm:ss a | dateTimeWithSeconds       | 2022/03/10 1:35:24 PM   |
 * | yyyy/MM/dd           | date                      | 2022/03/10              |
 * | h:mm a               | time                      | 1:35 PM                 |
 * | yyyy/MM/dd h:mm a    | dateTime                  | 2022/03/10 1:35PM       |
 * | yyyy/MM/dd h:mm a z  | dateTimeWithTZ            | 2022/03/10 1:35PM GMT-7 |
 * | a                    | amOrPm                    | PM                      |
 * | h                    | hour                      | 1                       |
 * | mm                   | minute                    | 35                      |
 */
export type DateFormatType =
   | "dateTimeWithSeconds"
   | "date"
   | "time"
   | "dateTime"
   | "dateTimeWithTZ"
   | "amOrPm"
   | "hour"
   | "minute";

export type DateFormatObject = {
   showDate: boolean;
   showTime: boolean;
   showHours: boolean;
   showMinutes: boolean;
   showSeconds: boolean;
   showAMOrPM: boolean;
   showTZ?: boolean;
};

export const globalSearchEntitiesTuple = [
   "tasks",
   "pms",
   "assets",
   "parts",
   "vendors",
   "pos",
] as const;
export type GlobalSearchEntities = (typeof globalSearchEntitiesTuple)[number];
export type GlobalSearchSettings = { [E in GlobalSearchEntities]: boolean };
export type GlobalSearchSettingsDto = { [E in GlobalSearchEntities]: 0 | 1 };

export type BulkPrintTasksSettings = {
   status: number;
   priority: number;
   type: number;
   assignment: number;
   location: number;
   asset: number;
   dueDate: number;
   downtime: number;
   description: number;
   instructions: number;
   completionNotes: number;
   comments: number;
   parts: number;
   invoices: number;
   completedBy: number;
   completedDate: number;
   assetInformation: number;
   timeSpent: number;
};

export type SmtpSettings = {
   enabled: number;
   itContactEmail: string | null;
   smtpServer: string | null;
   smtpUsername: string | null;
   smtpFromAddress: string | null;
   smtpFromName: string | null;
   smtpPassword: string | null;
   smtpPort: number | null;
   smtpSSLTLS: number | null;
   smtpSTARTTLS: number | null;
   emailsFailing: number | null;
};

export interface CheckOutRequest {
   checkOutID: number;
   requestedByUserID: number;
   requestDate: number;
   checkOutComment: string;
   requestSentToUserID?: number;
   requestSentToProfileID?: number;
   requestApprovalUserID?: number;
   requestDecisionDate?: number;
   requestDeniedComment?: string;
   checkInDate?: number;
   checkedInByUserID?: number;
   checklistID?: number;
   requestApproved?: boolean;
   requestCancelledDate?: number;
}

export interface CheckOutEntry {
   date: number;
   entry: string;
   user: string;
   checklistID?: number | undefined;
}

export type FeatureFlag = 0 | 1;

export type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export interface SortColumnInfo<X, Y> {
   columnIcon?: Aliases;
   columnName?: string;
   sortProperty: keyof X;
   locationOfProperty: Y;
   entityUniqueID?: number;
   sortDirection: "ascending" | "descending";
   sortChanges: ReplaySubject<SortColumnInfo<X, Y>>;
   onClickCallback?: () => unknown;
   order?: number;
   width?: number;
}

export interface UploadObj {
   primaryID: string;
   uploadTypes: "images" | "documents";
   posturl: string;
   viewOnly?: boolean;
   noPreview?: boolean;
   onUploadPopEditor?: boolean;
   uploadCall: (posturl: any, formdata: any) => Promise<AxiosResponse<any, any>>;
   uploadComplete: (data: any) => void;
   errorHandler?: (errorMsg: string) => void;
}

export interface CalculatedPartInfo {
   totalQty: number;
   totalPrice: number;
   averagePrice: number;
   reservedQty: number;
   totalAvailableQty: number;
   reservedTasks: Array<{
      checklistID: number;
      suggestedNumber: number;
   }>;
}

export type Filter<T> = {
   fieldName: string;
   fieldSearch: string;
   field: Partial<T>;
};

export type SearchFields = Record<
   string,
   {
      searchValue?: string;
      beginDate?: number;
      endDate?: number;
      highest?: number;
      lowest?: number;
      defaultField?: boolean;
   }
>;
export type VendorInfoTabHeader = "info" | "WOs" | "assets" | "parts" | "logs" | "POs";
export type AssetInfoTabHeader =
   | "info"
   | "pm"
   | "wo"
   | "parts"
   | "vendors"
   | "log"
   | "reports"
   | "showChildrenAssets";

export interface PmFilterBase {
   id: string;
   name: string;
   active: boolean;
   onActivate?: () => void;
}
export interface PmFilterName extends PmFilterBase {
   id: "name";
   value: string | null;
}
export interface PmFilterAssets extends PmFilterBase {
   id: "assets";
   value: string | null;
   assets: Lookup<"assetID", Asset>;
}
export interface PmFilterSchedules extends PmFilterBase {
   id: "schedules";
   value: { type: string; id: number } | null;
   manageTask: ManageTask;
}
export interface PmFilterAssignment extends PmFilterBase {
   id: "assignment";
   value: { name: string; userID?: number; profileID?: number };
   manageTask: ManageTask;
}
export interface PmFilterNextDue extends PmFilterBase {
   id: "nextDue";
   begin: Moment | null;
   end: Moment | null;
   manageSchedule: ManageSchedule;
   manageTask: ManageTask;
   language: LanguageDictionary;
}
export interface PmFilterHolidaysAndSeasons {
   id: "holidaysAndSeasons";
   name: string;
   active: boolean;
   value: TaskSeasons[number][number] | null;
   onActivate?: () => void;
}
export type PmFilter =
   | PmFilterName
   | PmFilterAssets
   | PmFilterSchedules
   | PmFilterAssignment
   | PmFilterNextDue
   | PmFilterHolidaysAndSeasons;

export type VendorSortColumnInfo = SortColumnInfo<
   Vendor & VendorField,
   "vendors" | "fields"
>;
export type PartSortColumnInfo = SortColumnInfo<
   Part &
      PartField &
      Pick<CalculatedPartInfo, "averagePrice" | "totalQty"> &
      Pick<Category, "categoryName"> &
      Pick<Vendor, "vendorName"> & { assetName: string; poNumber: string }, //these properties are being pulled from data we haven't declared types for yet, so they have to be tacked on manually for now
   "part" | "field" | "calculatedPartInfo" | "category" | "vendor" | "asset" | "PO"
>;

export type BillSortColumnInfo = SortColumnInfo<
   Bill & {
      total: number | undefined;
      vendorNameStr: string;
      displayName: string | undefined;
   },
   "bill"
>;

export type VendorSortFilterColumnInfo = SortColumnInfo<Vendor, "vendor">;
export type PartSortFilterColumnInfo = SortColumnInfo<
   Part & { locationName: string; totalQty: number }, //these properties are being pulled from data we haven't declared types for yet, so they have to be tacked on manually for now
   "part"
>;

export type Video = {
   title: string;
   src: string;
   thumbnail?: string;
};

export type TimeLoggedRecord = Partial<ExtraTime> &
   Partial<{
      promptTimeHours: number;
      promptTimeMinutes: number;
      userFirstName: string;
      userLastName: string;
      billableHours: number;
      billableMinutes: number;
      fromChecklistTime: boolean;
      createdByUserFullName: string;
   }>;

export const WORK_REQUEST_TEMPLATE_BATCH_ID = 300112;

export enum NotificationHistoryType {
   PRICING = 16,
   DASHBOARD = 17,
}
