import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";

export type TaskLaborCostAndTimeViewModel = {
   laborCost?: number;
   timeSpentSecs?: number;
   locationID?: number;
   currencyCode: string;
};

@Component({
   selector: "task-labor-cost-and-time-cell",
   imports: [CommonModule, BetterDecimalPipe, LocaleCurrencyPipe],
   templateUrl: "./task-labor-cost-and-time-cell.component.html",
})
export class TaskLaborCostAndTimeCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: TaskLaborCostAndTimeViewModel;
   @Input() public column?: Column;
}
