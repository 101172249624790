import type { OnInit } from "@angular/core";
import { Component, computed, inject } from "@angular/core";
import {
   BasicModalHeaderComponent,
   CheckboxComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   OutlinedButtonComponent,
   PanelComponent,
   PrimaryButtonComponent,
   RadioButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "transferAssetToNewLocation",
   templateUrl: "./transferAssetToNewLocation.component.html",
   styleUrls: ["./transferAssetToNewLocation.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      PanelComponent,
      RadioButtonComponent,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
      CheckboxComponent,
      IconComponent,
   ],
})
export class TransferAssetToNewLocationComponent implements OnInit {
   public message;
   public messageDescription;
   public selectionMessage;
   public title;
   public cancel;
   public resolve;
   public modalInstance;
   public shouldTransferParts: boolean | undefined = undefined;
   public currentLocationName;
   public newLocationName;
   public IUnderstand = false;
   private readonly manageLang = inject(ManageLang);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;

      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      if (params?.resolve.currentLocationName) {
         this.currentLocationName = params.resolve.currentLocationName;
      }
      if (params?.resolve.newLocationName) {
         this.newLocationName = params.resolve.newLocationName;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.messageDescription = this.resolve.messageDescription;
      this.selectionMessage = this.resolve.selectionMessage;
      this.cancel = this.resolve.cancel ?? this.lang().No;
   }

   public close(): void {
      if (this.modalInstance) {
         this.modalInstance.close({
            confirm: false,
            shouldTransferParts: this.shouldTransferParts,
         });
      }
   }

   public updateShouldTransferParts(NewSelection: boolean) {
      this.shouldTransferParts = NewSelection;
   }

   public submit(): void {
      if (this.modalInstance === undefined) {
         return;
      }

      if (this.shouldTransferParts === undefined) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAnOptionForAssociatedParts,
            "warning",
            4000,
         );
         return;
      }
      if (!this.IUnderstand) {
         this.alertService.addAlert(
            this.lang().PleaseConfirmThatYouHaveReadAndUnderstandTheseActions,
            "warning",
            4000,
         );
         return;
      }
      if (this.IUnderstand && this.shouldTransferParts !== undefined) {
         this.modalInstance.close({
            confirm: true,
            shouldTransferParts: this.shouldTransferParts,
         });
      }
   }
}
