import { Component, computed, inject, type OnInit, type Signal } from "@angular/core";
import {
   BasicModalHeaderComponent,
   CheckboxComponent,
   IconComponent,
   LimbleHtmlDirective,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   type ModalResult,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageTutorial } from "src/app/onboarding/services/tutorials/manageTutorial";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "confirm-unit-of-measure-modal",
   templateUrl: "./confirm-uom.modal.component.html",
   styleUrls: ["./confirm-uom.modal.component.scss"],
   imports: [
      PanelComponent,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      ModalFooterComponent,
      ModalComponent,
      PrimaryButtonComponent,
      IconComponent,
      CheckboxComponent,
      LimbleHtmlDirective,
      SecondaryButtonComponent,
      TooltipDirective,
   ],
})
export class ConfirmUnitOfMeasureModal implements OnInit, ModalResult<boolean> {
   public readonly modalRef: LimUiModalRef<ConfirmUnitOfMeasureModal, boolean> =
      inject(LimUiModalRef);
   public resolve;

   public title: string = "";
   public isConfirmed: boolean = false;
   public alertText = "";
   public message = "";
   public actionButtonText = "";
   public dismissButtonText = "";
   public showLearnMoreLink? = true;
   public showSuccessMessage? = true;
   public isSmallConfirm: boolean = false;
   public showCheckbox?: boolean = true;
   public isDeleteAction?: boolean = false;
   public unitOfMeasureTutorialName: string | undefined = undefined;

   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);
   private readonly heapService = inject(HeapService);
   private readonly manageTutorial = inject(ManageTutorial);
   private readonly tutorialSection =
      this.manageTutorial.getTutorialSection("unitOfMeasure");

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly tutorialTooltip = this.computeTutorialTooltip();

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      this.alertText = this.resolve.alertText;
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.isSmallConfirm = this.resolve.isSmallConfirm ?? false;
      this.actionButtonText = this.resolve.actionButtonText;
      this.dismissButtonText = this.resolve.dismissButtonText;
      this.showLearnMoreLink = this.resolve.showLearnMoreLink ?? this.showLearnMoreLink;
      this.unitOfMeasureTutorialName =
         this.resolve.unitOfMeasureTutorialName ?? this.unitOfMeasureTutorialName;
      this.showCheckbox = this.resolve.showCheckbox ?? this.showCheckbox;
      this.showSuccessMessage =
         this.resolve.showSuccessMessage ?? this.showSuccessMessage;
      this.isDeleteAction = this.resolve.isDeleteAction ?? this.isDeleteAction;
   }

   protected close(): void {
      this.modalRef.close(false);
   }

   protected submit(): void {
      if (!this.isConfirmed && this.showCheckbox) {
         this.alertService.addAlert(
            this.lang().ConfirmUoMModalAlertText,
            "warning",
            5000,
         );
         return;
      }

      if (this.showSuccessMessage) {
         this.alertService.addAlert(this.lang().successMsg, "success", 5000);
      }
      this.modalRef.close(true);
   }

   protected watchTutorial(): void {
      if (this.unitOfMeasureTutorialName === undefined) return;

      if (this.unitOfMeasureTutorialName === "StockUnits") {
         this.heapService.trackEvent("confirmChangeStockUnitModal_initiateWatchTutorial");
      } else if (this.unitOfMeasureTutorialName === "UnitsOfMeasure") {
         this.heapService.trackEvent(
            "confirmToggleUnitsOfMeasureFeatureModal_initiateWatchTutorial",
         );
      }

      const videoTutorial = this.tutorialSection.getVideoTutorialByName(
         this.unitOfMeasureTutorialName,
      );
      if (!videoTutorial) {
         return;
      }

      this.manageTutorial.watchVideoTutorial(videoTutorial);
   }

   private computeTutorialTooltip(): Signal<string> {
      return computed(() => {
         switch (this.unitOfMeasureTutorialName) {
            case "StockUnits":
               return this.lang().StockUnitsTutorialVideoTooltip;
            case "UnitOfMeasure":
               return this.lang().UnitOfMeasureTutorialVideoTooltip;
            default:
               return "";
         }
      });
   }
}
