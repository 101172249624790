<lim-ui-dropdown class="expand-mobile">
   <div button class="form-dropdown expand-mobile">
      <span
         class="header-wrapper header-wrapper-dropdown header-extra-space hide-asset-overflow expand-mobile"
      >
         <lim-ui-icon icon="cube" iconSize="small" class="text-and-icon-spacing" />

         @if (asset()) {
            <span
               class="asset-name-header"
               [limbleHtml]="asset()?.assetName"
               dataLogLabel="task-viewAsset"
            ></span>
         } @else {
            <span class="asset-name-header">
               {{ lang()?.None }}
            </span>
         }

         @if (asset()?.parentAssetID) {
            <asset-parent-list
               [assetID]="asset()?.assetID"
               linkColorClass="green-color"
            />
         }
      </span>
   </div>
   <div menu class="form-dropdown-input-menu-wrapper">
      @if (asset()?.assetName) {
         <lim-ui-dropdown-text-item
            [outputString]="lang()?.ViewAsset ?? 'View Asset'"
            [displayString]="lang()?.ViewAsset ?? 'View Asset'"
            (click)="requestViewAsset()"
         />
      }

      @if (disableChangeAsset() === false) {
         <lim-ui-dropdown-text-item
            [outputString]="lang()?.ChangeAsset ?? 'Change Asset'"
            [displayString]="lang()?.ChangeAsset ?? 'Change Asset'"
            (click)="requestChangeAsset()"
         />
      }
   </div>
</lim-ui-dropdown>
