<div class="toast-container">
   <div class="toast">
      <div class="message">
         {{ lang()?.UpdateAvailable }}
      </div>
      <div class="actions">
         <div class="action" (click)="refresh()">
            {{ lang()?.Refresh }}
         </div>
         <div class="dismiss" (click)="dismiss()">
            <lim-ui-icon icon="xmarkLarge" />
         </div>
      </div>
   </div>
</div>
