import { inject, Injectable } from "@angular/core";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskLocalViewModelMapperService } from "src/app/tasks/components/shared/services/task-view-model-factory/mappers/task-local-view-model-mapper.service";
import { TaskViewModelMapperService } from "src/app/tasks/components/shared/services/task-view-model-factory/mappers/task-view-model-mapper.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import type { Task } from "src/app/tasks/types/task.types";

@Injectable({
   providedIn: "root",
})
export class TaskDataViewerViewModelBuilderService {
   private readonly taskViewModelMapperService = inject(TaskViewModelMapperService);
   private readonly taskLocalViewModelMapperService = inject(
      TaskLocalViewModelMapperService,
   );

   public buildTaskDataViewerViewModel(task: TaskEntity): TaskDataViewerViewModel {
      const mapper = this.taskViewModelMapperService;

      const viewModel: TaskDataViewerViewModel = {
         checklistDueDateDisplay: mapper.getChecklistDueDateDisplay(task),
         selected: false,
         locationName: mapper.getLocationName(task),

         // setCompletedInfo
         isCompleted: mapper.getIsCompleted(task),

         // TODO TASK-1103 - Pass in the new status ID to these methods
         isPendingWorkRequest: mapper.getIsPendingWorkRequest(),
         isDeclinedWorkRequest: mapper.getIsDeclinedWorkRequest(),

         // setFieldsFromTaskInfo
         assignedTo: mapper.getAssignedTo(task),
         daysInfo: mapper.getDaysInfo(task),
         commentInfo: mapper.getCommentInfo(task),
         taskTypeIcons: mapper.getTaskTypeIcon(task),
         calculatedInfo: mapper.getCalculatedInfo(task),
         requestorInformation: mapper.getRequesterInformation(task),
         statusTimeInfo: mapper.getStatusTimeInfo(task),
         priorityInfo: mapper.getPriorityInfo(task),
         statusInfo: mapper.getStatusInfo(task),
         checklistPromptTimeTotal: mapper.getChecklistPromptTimeTotal(task),
         showLocation: mapper.getShowLocation(task),
         taskInstructionsUpdatedAt: mapper.getInstructionsUpdatedAt(task),
         taskInstructionsUpdatedAtUserID: mapper.getInstructionsUpdatedAtUserID(task),
         // NOTE: This can be moved to a pipe or a component since it is a view concern
         checklistTemplateOldString: mapper.getChecklistTemplateOldString(task),

         taskTypeStr: mapper.getTaskTypeStr(task),
         completionNotes: task.checklistComments,
         // TODO(rob): Attach the task type in Flannel so that this is just a simple translation of
         // an enum to a language string.
         typeLabel: mapper.getTypeLabel(task),
         hasParts: mapper.getHasParts(task),
         hasPartsInStock: mapper.getHasPartsInStock(task),
         parts: mapper.getParts(task),
         taskCredentials: mapper.getTaskCredentials(task),
         taskDisplayData: mapper.getTaskDisplayData(task),
         parsedSavedAssetInfo: mapper.getParsedSavedAssetInfo(task),
         currencyCode: mapper.getCurrencyCode(task),
         ...task, // TODO: eventually we should stop extending TaskEntity
      };

      // optional attributes
      const assets = mapper.getAssets(task);
      const dueDate = mapper.getDueDate(task);
      const completedDate = mapper.getCompletedDate(task);
      const startDate = mapper.getStartDate(task);
      const comments = mapper.getComments(task);
      /**
       * This sets the info needed by the Completed By and Completed On Cells
       */
      const completedInfo = mapper.getCompletedInfo(task);
      const calculatedDays = mapper.getCalculatedDays(task);

      const finalColorStatus = mapper.getFinalColorStatus(task);

      const downtimeEfficiencyRate = mapper.getDowntimeEfficiencyRate();
      const laborCostEfficiencyRate = mapper.getLaborCostEfficiencyRate();
      const partCostEfficiencyRate = mapper.getPartCostEfficiencyRate();
      const averageDowntimeCost = mapper.getAverageDowntimeCost();
      const downtimeEfficiencySavings = mapper.getDowntimeEfficiencySavings(task);
      const laborCostEfficiencySavings = mapper.getLaborCostEfficiencySavings(task);
      const partCostEfficiencySavings = mapper.getPartCostEfficiencySavings(task);
      if (comments) {
         viewModel.comments = comments;
      }

      if (assets) {
         viewModel.assets = assets;
      }

      if (dueDate) {
         viewModel.dueDate = dueDate;
      }
      if (completedDate) {
         viewModel.completedDate = completedDate;
      }
      if (startDate) {
         viewModel.startDate = startDate;
      }
      if (completedInfo) {
         viewModel.completedInfo = completedInfo;
      }

      if (calculatedDays) {
         viewModel.calculatedDays = calculatedDays;
      }

      if (finalColorStatus) {
         viewModel.finalColorStatus = finalColorStatus;
      }

      if (downtimeEfficiencyRate) {
         viewModel.downtimeEfficiencyRate = downtimeEfficiencyRate;
      }

      if (laborCostEfficiencyRate) {
         viewModel.laborCostEfficiencyRate = laborCostEfficiencyRate;
      }

      if (partCostEfficiencyRate) {
         viewModel.partCostEfficiencyRate = partCostEfficiencyRate;
      }

      if (averageDowntimeCost) {
         viewModel.averageDowntimeCost = averageDowntimeCost;
      }

      if (downtimeEfficiencySavings) {
         viewModel.downtimeEfficiencySavings = downtimeEfficiencySavings;
      }

      if (laborCostEfficiencySavings) {
         viewModel.laborCostEfficiencySavings = laborCostEfficiencySavings;
      }

      if (partCostEfficiencySavings) {
         viewModel.partCostEfficiencySavings = partCostEfficiencySavings;
      }

      return viewModel;
   }

   public buildTaskDataViewerViewModelWithLocalTask(task: Task): TaskDataViewerViewModel {
      const mapper = this.taskLocalViewModelMapperService;

      // Many of these values are defaulted to 0 or "" because
      // of how local tasks are stored, to make it compatible with the viewModal.
      // In practice, these values will be set within the task object.
      const viewModel: TaskDataViewerViewModel = {
         ...task,
         checklistID: task.checklistID,
         checklistName: task.checklistName ?? "",
         checklistTemplate: task.checklistTemplate ?? 0,
         checklistTemplateOld: task.checklistTemplateOld ?? 0,
         checklistDowntime: task.checklistDowntime ?? 0,
         checklistDepreciated: task.checklistDepreciated ?? 0,
         checklistTemplateID: task.checklistTemplateID ?? 0,
         priorityID: task.priorityID ?? 0,
         statusID: task.statusID ?? 0,
         locationID: task.locationID ?? 0,
         userID: task.userID ?? 0,
         profileID: task.profileID ?? 0,
         assetID: task.assetID ?? 0,
         checklistStartDateSetting: task.checklistStartDateSetting ?? 0,
         checklistDueDateSetting: task.checklistDueDateSetting ?? 0,
         finalColorStatus: task.finalColorStatus ?? 0,
         checklistColor: task.checklistColor ?? 0,
         approvalItemState: task.approvalItemState ?? 0,
         checklistBatchID: task.checklistBatchID ?? 0,
         checklistPriorBatchID: task.checklistPriorBatchID ?? 0,
         checklistComments: task.checklistComments ?? "",
         checklistEstTime: task.checklistEstTime ?? 0,
         checklistEmailCN: task.checklistEmailCN ?? "",
         checklistEmailCNImages: task.checklistEmailCNImages ?? "",
         checklistInstructions: task.checklistInstructions ?? "",
         reoccurID: task.reoccurID ?? 0,
         checklistPromptTime: task.checklistPromptTime ?? 0,
         checklistCompletedDate: task.checklistCompletedDate ?? 0,
         checklistDueDate: task.checklistDueDate ?? 0,
         checklistStartDate: task.checklistStartDate ?? 0,
         createdByUserID: task.createdByUserID ?? 0,
         checklistCreatedDate: task.checklistCreatedDate ?? 0,
         checklistStatusID: task.checklistStatusID ?? 0,
         checklistUserCompleted: task.checklistUserCompleted ?? 0,
         checklistGreen: task.checklistGreen ?? 0,
         checklistYellow: task.checklistYellow ?? 0,
         checklistRed: task.checklistRed ?? 0,
         checklistLastEdited: task.checklistLastEdited ?? 0,
         checklistPriority: task.checklistPriority ?? 0,
         requestName: task.requestName ?? "",
         requestPhone: task.requestPhone ?? "",
         billableRate: task.billableRate ?? 0,
         billableTime: task.billableTime ?? 0,
         categoryID: task.categoryID ?? 0,
         requestDescription: task.requestDescription ?? "",
         requestField1: task.requestField1 ?? "",
         requestField2: task.requestField2 ?? "",
         requestField3: task.requestField3 ?? "",
         requestDropdown1: task.requestDropdown1 ?? "",
         requestDropdown2: task.requestDropdown2 ?? "",
         requestDropdown3: task.requestDropdown3 ?? "",
         numOfDueDateChange: task.numOfDueDateChange ?? 0,
         taskImage: task.taskImage ?? "",
         geoLocation: JSON.stringify(task.geoLocation),
         checklistUserWage: task.checklistUserWage ?? 0,
         dynamicAssignment: task.dynamicAssignment ?? 0,
         extraTimeNotes: task.extraTimeNotes ?? "",
         assetInfoFromCompletion: task.assetInfoFromCompletion ?? "",
         isCompleted: mapper.getIsCompleted(task),
         taskTypeStr: mapper.getTaskTypeStr(task),
         completionNotes: task.checklistComments ?? undefined,
         assignedTo: mapper.getAssignedTo(task),
         requestorInformation: mapper.getRequesterInformation(task),
         checklistDueDateDisplay: mapper.getChecklistDueDateDisplay(task),
         priorityInfo: mapper.getPriorityInfo(task),
         statusInfo: mapper.getStatusInfo(task),
         selected: false,
         locationName: mapper.getLocationName(task),
         checklistPromptTimeTotal: mapper.getChecklistPromptTimeTotal(task),
         checklistTemplateOldString: mapper.getChecklistTemplateOldString(task),
         showLocation: mapper.getShowLocation(task),
         typeLabel: mapper.getTypeLabel(task),
         taskCredentials: mapper.getTaskCredentials(task),
         taskDisplayData: mapper.getTaskDisplayData(task),
         parsedSavedAssetInfo: mapper.getParsedSavedAssetInfo(task),
         instructionsUpdatedAt: task.instructionsUpdatedAt ?? 0,
         instructionsUpdatedAtUserID: task.instructionsUpdatedAtUserID ?? 0,
         taskInstructionsUpdatedAt: task.instructionsUpdatedAt
            ? new Date(task.instructionsUpdatedAt)
            : new Date(),
         taskInstructionsUpdatedAtUserID: Number(task.instructionsUpdatedAtUserID ?? 0),

         assets: mapper.getAssets(task),
         taskTypeIcons: mapper.getTaskTypeIcon(task),
         currencyCode: mapper.getCurrencyCode(task),
      };

      return viewModel;
   }
}
