<lim-ui-modal>
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <p [limbleHtml]="message"></p>
      </lim-ui-info-panel>
      @if (hasLinkedTasks) {
         <div class="linked-fields-warning">
            <lim-ui-icon icon="triangleExclamation" iconSize="small" iconColor="warn" />
            <span [limbleHtml]="lang().LinkedFieldsWarning" class="warning-text"></span>
            <br /><br />
            @for (task of linkedTasks; track task) {
               <div>
                  <p>{{ task.type }} - {{ task.name }} - #{{ task.id }}</p>
               </div>
            }
         </div>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (!hasLinkedTasks) {
         <lim-ui-outlined-button (click)="submit()">
            {{ buttonText }}
         </lim-ui-outlined-button>
      }
      <lim-ui-primary-button (click)="close()">
         {{ lang().Cancel }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
