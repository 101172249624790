import { inject, Injectable, signal } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MergeFieldsStepComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/merge-fields-step/merge-fields-step.component";
import { ReviewAndConfirmStepComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/review-and-confirm-step/review-and-confirm-step.component";
import { SelectAssetsStepComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/select-assets-step/select-assets-step.component";
import { SelectLocationStepComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/select-location-step/select-location-step.component";
import {
   type Step,
   StepperWizardContext,
   StepStatus,
} from "src/app/assets/services/apply-asset-templates/stepper-wizard-context";
import type { AssetTemplate } from "src/app/assets/services/asset-template-api.service";
import { TranslationService } from "src/app/languages/translation/translation.service";

@Injectable({ providedIn: "root" })
export class ApplyTemplateService {
   private readonly i18n = inject(TranslationService).i18n;
   private readonly _wizardContext: StepperWizardContext<unknown>;

   private readonly applyingTemplateSubject = new BehaviorSubject<AssetTemplate | null>(
      null,
   );
   public readonly applyingTemplate$ = this.applyingTemplateSubject.asObservable();

   public constructor() {
      this._wizardContext = new StepperWizardContext(this.steps(), null);
   }

   public get wizardContext(): StepperWizardContext<unknown> {
      return this._wizardContext;
   }

   public setApplyingMode(template: AssetTemplate): void {
      this.applyingTemplateSubject.next(template);
   }

   public clearApplyingMode(): void {
      this.applyingTemplateSubject.next(null);
   }

   private steps(): Array<Step> {
      return [
         {
            stepNumber: 0,
            label: this.i18n().t("SelectLocation"),
            headerText: this.i18n().t("SelectLocationToLinkAssetsToTemplate"),
            nextButtonText: this.i18n().t("SelectAssets"),
            nextButtonDataLogLabel:
               "assetTemplatesApplyTemplateFlow-selectLocation-clickNextSelectAssets",
            previousButtonText: undefined,
            status: signal(StepStatus.INPROGRESS),
            component: SelectLocationStepComponent,
         },
         {
            stepNumber: 1,
            label: this.i18n().t("SelectAssets"),
            headerText: this.i18n().t("SelectAssetsHeaderText"),
            nextButtonText: this.i18n().t("MergeAssetFields"),
            nextButtonDataLogLabel:
               "assetTemplatesApplyTemplateFlow-selectAssets-clickNextMergeAssetFields",
            previousButtonText: this.i18n().t("SelectLocation"),
            previousButtonDataLogLabel:
               "assetTemplatesApplyTemplateFlow-selectAssets-clickBackSelectLocation",
            status: signal(StepStatus.INCOMPLETE),
            component: SelectAssetsStepComponent,
         },
         {
            stepNumber: 2,
            label: this.i18n().t("MergeAssetFields"),
            headerText: this.i18n().t("MergeAssetFieldsHeaderText"),
            nextButtonText: this.i18n().t("Review+Confirm"),
            nextButtonDataLogLabel:
               "assetTemplatesApplyTemplateFlow-mergeAssetFields-clickReviewAndConfirm",
            previousButtonText: this.i18n().t("SelectAssets"),
            previousButtonDataLogLabel:
               "assetTemplatesApplyTemplateFlow-mergeAssetFields-clickBackSelectAssets",
            status: signal(StepStatus.INCOMPLETE),
            component: MergeFieldsStepComponent,
         },
         {
            stepNumber: 3,
            label: this.i18n().t("Review+Confirm"),
            headerText: "",
            nextButtonText: this.i18n().t("ApplyChanges"),
            nextButtonDataLogLabel:
               "assetTemplatesApplyTemplateFlow-reviewAndConfirm-clickApplyChanges",
            previousButtonText: this.i18n().t("MergeAssetFields"),
            previousButtonDataLogLabel:
               "assetTemplatesApplyTemplateFlow-reviewAndConfirm-clickBackMergeAssetFields",
            status: signal(StepStatus.INCOMPLETE),
            component: ReviewAndConfirmStepComponent,
         },
      ];
   }
}
