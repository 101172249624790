import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";

@Component({
   selector: "disapprove-approval",
   templateUrl: "./disapproveApproval.modal.component.html",
   styleUrls: ["./disapproveApproval.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      ContenteditableDirective,
      FormsModule,
      AlertComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
   ],
})
export class DisapproveApproval implements OnInit {
   public resolve;
   public modalInstance;
   public data;
   public title;
   public errorMsg;
   public text;
   public userID;
   public profileID;
   public multiUsers;
   public assignedBackStr;

   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;

      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.title = this.data.title;
      this.errorMsg = "";
      this.text = "";

      if (
         this.data.item.onDisapproveUserID > 0 ||
         this.data.item.onDisapproveProfileID > 0
      ) {
         this.userID = this.data.item.onDisapproveUserID;
         this.profileID = this.data.item.onDisapproveProfileID;
      } else {
         this.userID = this.data.item.lastUserID;
         this.profileID = this.data.item.lastProfileID;
      }
      this.multiUsers = []; //default
      this.setAssignBackStr();
   }

   setAssignBackStr = () => {
      if (this.userID > 0) {
         const user = this.manageTask.getAllUsers().get(this.userID);
         this.assignedBackStr = `${user?.userFirstName} ${user?.userLastName}`;
      } else if (this.profileID > 0) {
         const profile = this.manageTask.getAllProfiles().get(this.profileID);
         this.assignedBackStr = profile?.profileDescription;
      } else if (this.multiUsers.length > 0) {
         this.assignedBackStr = "";
         for (const multiUser of this.multiUsers) {
            const user = this.manageTask.getAllUsers()[multiUser];
            this.assignedBackStr = `${this.assignedBackStr + user.userFirstName} ${
               user.userLastName
            }, `;
         }
         this.assignedBackStr = this.assignedBackStr.substring(
            0,
            this.assignedBackStr.length - 2,
         );
      } else {
         this.assignedBackStr = this.lang().PickUser;
      }
   };

   setAssignedBack = async () => {
      const task = await this.manageTask.getTask(this.data.item.checklistID); //promise based
      const instance = this.modalService.open(PickUserOrProfileLegacy);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               title: this.lang().AssignedBackTo,
               message: this.lang().AssignedBackToMsg,
               locationID: task?.locationID,
            },
         },
      };

      instance.result.then((result) => {
         if (result != 0) {
            this.userID = result.userID;
            this.profileID = result.profileID;
            this.multiUsers = result.multiUsers;
            this.setAssignBackStr();
         }
      });
   };

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      if (this.text.length < 1) {
         this.errorMsg = this.lang().ReasonCantBeBlank;
         return;
      }

      if (!(this.userID > 0 || this.profileID > 0 || this.multiUsers.length > 0)) {
         this.errorMsg = this.lang().MustAssignBackToSomeone;
         return;
      }

      const obj: any = {};
      obj.reason = this.text;
      obj.userID = this.userID;
      obj.profileID = this.profileID;
      obj.multiUsers = this.multiUsers;

      this.modalInstance.close(obj);
   };
}
