/**
 * Used to get a human-readable environment description for logging purposes.
 * The return of this function should not be relied upon in code.
 */
export function getEnvString(): string {
   /* This function assumes that the current url is in one of the following formats:
    * 1. app.limblecmms.com/<path>
    * 2. app.limblecmms.com/c<number>/<path>
    * 3. <countryCode>-app.limblecmms.com/<path>
    * 4. <countryCode>-app.limblecmms.com/c<number><path>
    *
    * The following table illustrates the expected results from this function:
    * | URL                                               | Expected Result               |
    * | ------------------------------------------------- | ----------------------------- |
    * | app.limblecmms.com/<path>                         | main                          |
    * | app.limblecmms.com/c<number>/<path>               | main-canary-<number>          |
    * | <countryCode>-app.limblecmms.com/<path>           | <countryCode>                 |
    * | <countryCode>-app.limblecmms.com/c<number>/<path> | <countryCode>-canary-<number> |
    */
   const possibleCanaryPath = window.location.pathname.split("/")[1];
   const subdomain = window.location.hostname?.split(".")[0]?.split("-")[0] ?? "";
   const env = subdomain === "app" ? "main" : subdomain;
   if (
      possibleCanaryPath.startsWith("c") &&
      possibleCanaryPath.length === 2 &&
      parseInt(possibleCanaryPath[1])
   ) {
      return `${env}-canary-${possibleCanaryPath[1]}`;
   }
   return env;
}
