import { NgClass, NgStyle, NgTemplateOutlet } from "@angular/common";
import type { OnInit } from "@angular/core";
import { Component, computed, inject, Input, input } from "@angular/core";
import {
   IconComponent,
   LoadingAnimationComponent,
   ScrollContainerComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ManageLang } from "src/app/languages/services/manageLang";
import type {
   PartLogDataViewerViewModel,
   PartLogSortOptions,
} from "src/app/parts/components/part-logs-data-viewer/part-log-date-viewer.model";
import { LogEntryCellComponent } from "src/app/parts/components/part-modal/components/cell-renderers/log-entry-cell/log-entry-cell.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import {
   DataViewerPaginatorComponent,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { LimbleMap } from "src/app/shared/utils/limbleMap";

@Component({
   selector: "part-logs-data-viewer",
   templateUrl: "./part-logs-data-viewer.component.html",
   styleUrls: ["./part-logs-data-viewer.component.scss"],
   imports: [
      NgTemplateOutlet,
      NgClass,
      SortColumn,
      TooltipDirective,
      NgStyle,
      ScrollContainerComponent,
      NoSearchResults,
      LoadingAnimationComponent,
      NgxSkeletonLoaderModule,
      SortColumn,
      DataViewerPaginatorComponent,
      LogEntryCellComponent,
      BetterDatePipe,
      IconComponent,
   ],
})
export class PartLogsDataViewerComponent implements OnInit {
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);
   protected readonly dataViewerState = inject(DataViewerStateService);

   /**
    * List of part logs to be shown
    */
   @Input() public partLogs: Array<PartLogDataViewerViewModel> | null = null;

   /**
    * Total number of part logs
    */
   @Input() public totalPartLogs: number = 0;

   /**
    * List of columns to be shown
    */
   @Input() public columns: Array<Column> | undefined;

   /**
    *  Whether to show the loading bar
    */
   @Input() public isLoading = false;

   /**
    * Whether to show the noSearchResults section
    */
   @Input() public noSearchResults = false;

   /**
    *  Location of pagination - top, bottom, or both
    */
   @Input() public paginationLocation = "bottom";

   /**
    * Property to initially sort by
    */
   @Input() public initialSort: PartLogSortOptions = "logDate";

   @Input() public noHeaders; // If true, no column headers will be displayed

   @Input() public autoHeight; // If true, table height will be auto, not 100vh
   @Input() public tableInScrollablePage = false;
   @Input() public searchHints: LimbleMap<number, string> = new LimbleMap();
   public isAllPartsLog = input<boolean>(false); // If true, we're viewing all parts logs, not just logs for a single part

   public skeletonThemes;
   protected sort: PartLogSortOptions = this.initialSort;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected isInModal = true;

   public constructor() {
      this.skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();
   }

   public ngOnInit() {
      this.dataViewerState.setSort(`-${this.sort}`);
   }

   protected onSortChanged(newSortOption: PartLogSortOptions): void {
      this.sort = newSortOption;
      this.dataViewerState.setSort(this.sort);
   }

   protected onPageChanged(page: number) {
      this.dataViewerState.setPage(page);
   }

   protected onPageSizeChanged(pageSize: number) {
      this.dataViewerState.setPageSize(pageSize);
   }
}
