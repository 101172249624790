import { inject, Injectable } from "@angular/core";
import { assetFieldValueInitializer } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.initializer";
import { assetFieldValueSchema } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.schema";
import { AssetFieldValueStorageService } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.storage.service";
import { refetchValidData } from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { z } from "zod";

@Injectable({
   providedIn: "root",
})
export class AssetFieldValueStorageSyncService extends StorageSyncService {
   private readonly assetFieldValueStorageService = inject(AssetFieldValueStorageService);

   public constructor() {
      super();
   }

   public async syncAssetFieldValue(valueID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = assetFieldValueInitializer;
         const assetFieldValues = await refetchValidData({
            url,
            params: { valueIDs: [valueID], valueLastEdited: 0 },
            validation: z.array(assetFieldValueSchema),
         });
         const [assetFieldValue] = assetFieldValues;
         assert(
            assetFieldValue !== undefined,
            `AssetFieldValue #${valueID} not found in fetch when attempting to sync`,
         );
         await this.assetFieldValueStorageService.putAssetFieldValue(assetFieldValue);
      });
   }

   public async syncAssetFieldValueDeletion(valueID: number): Promise<void> {
      return this.sync(async () => {
         await this.assetFieldValueStorageService.deleteAssetFieldValue(valueID);
      });
   }
}
