import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { CredService } from "src/app/users/services/creds/cred.service";
import defaultHolidays from "src/root/holidays.json";

@Component({
   selector: "seasons-config",
   templateUrl: "./defaultHolidaysSelect.modal.component.html",
   styleUrls: ["./defaultHolidaysSelect.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      NgClass,
      IconComponent,
      BasicModalFooterComponent,
   ],
})
export class DefaultHolidaysSelect implements OnInit {
   public readonly title: string;
   public readonly message: string;
   public readonly superUser: boolean;
   public readonly defaultHolidayList: Array<any> = [];
   public alreadyChosenDefaults;

   private resolve;
   private modalInstance;
   private userClicks = 0;

   private readonly paramsService = inject(ParamsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
      this.title = `${this.lang().Select} ${this.lang().CommonHolidays}`;
      this.message = this.lang().CommonHolidaysMsg;
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;
      this.alreadyChosenDefaults = data.alreadyChosenDefaults ?? [];
      this.getData();
   }

   private getData() {
      for (const holiday of this.alreadyChosenDefaults) {
         const found = defaultHolidays[holiday.defaultHolidayID];
         if (found === undefined) {
            continue;
         }
         found.alreadyChosen = true;
      }
      this.defaultHolidayList.push(...Object.values(defaultHolidays));
   }

   public deselectAll() {
      for (const season of this.defaultHolidayList) {
         season.selected = false;
      }
   }

   private checkResetUserClicks() {
      this.userClicks = this.manageUtil.checkResetUserClicks(
         this.defaultHolidayList,
         this.userClicks,
      );
   }

   public focusSeason(season) {
      if (season.alreadyChosen) {
         return;
      }
      if (season.selected) {
         if (this.userClicks <= 0) {
            //only one is selected so they meant to double tap it to select
            this.selectSeasons();
            return;
         }
      }
      this.userClicks++;
      this.checkResetUserClicks();

      season.selected = !season.selected;
   }

   public close() {
      this.deselectAll();
      for (const season of this.defaultHolidayList) {
         season.alreadyChosen = false;
      }
      this.modalInstance.close(0);
   }

   public selectSeasons() {
      const pickedHolidays: Array<any> = [];
      let newHolidays: Array<any> = [];

      for (const season of this.defaultHolidayList) {
         season.alreadyChosen = false;
         if (season.selected === true) {
            pickedHolidays.push(season);
         }
      }

      newHolidays = pickedHolidays.map((holiday) => {
         return {
            name: holiday.name,
            event: "skip",
            applyToAllSchedules: 0,
            defaultHolidayID: holiday.defaultHolidayID,
         };
      });

      this.deselectAll();
      this.modalInstance.close(newHolidays);
   }
}
