export enum TaskAssignmentType {
   User = "user",
   Profile = "profile",
   Unassigned = "unassigned",
}

export interface AssignmentFilterValue {
   assignmentType: TaskAssignmentType;
   assignmentValue: number;
}
