<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="lang().AddAndEditFields" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         @if (credConfigureNewFields) {
            <span class="message-area" [limbleHtml]="message"></span>
         }
      </lim-ui-info-panel>

      <lim-ui-panel>
         @if (credConfigureNewFields) {
            <div class="add-field-selection-list">
               <!--We add credConfigureNewFields because why show the buttons if only one is available-->
               <lim-ui-radio-button
                  groupID="addFieldGroup"
                  [checked]="showSuggested"
                  label="{{ lang().ExistingFields }}"
                  (click)="showSuggested = true; showNew = false; errorMsg = ''"
                  data-log="manageAssets-addCustomField-clickExistingField"
               />
               <lim-ui-radio-button
                  groupID="addFieldGroup"
                  label="{{ lang().CreateNewField }}"
                  [checked]="showNew"
                  (click)="showSuggested = false; showNew = true; errorMsg = ''"
                  data-log="manageAssets-addCustomField-clickCreateNewField"
               />
            </div>
         }

         @if (showSuggested) {
            <div>
               <div class="suggested-fields-search-section">
                  <div class="content-holder">
                     @if (allFields?.size === 0) {
                        <div>
                           <p>
                              {{
                                 isForTemplate()
                                    ? lang().NoGlobalFields
                                    : lang().NoFieldsAtLocation
                              }}
                           </p>
                           <br />
                           <p>{{ lang().CreateNewFieldTip }}</p>
                        </div>
                     }

                     @if (
                        allFields?.size !== 0 &&
                        fields?.size !== 0 &&
                        credConfigureNewFields
                     ) {
                        <div>
                           {{ lang().SuggestedFields }}
                        </div>
                     }
                  </div>

                  @if (showSuggested) {
                     <lim-ui-search-box
                        [(searchVal)]="searchFields"
                        [placeholder]="lang().Search"
                        (searchValChange)="filterFields()"
                     />
                  }
               </div>
               @if (fields?.size === 0 && allFields && allFields.size > 0) {
                  <div class="no-results-holder">
                     <no-search-results />
                  </div>
               }
               @if (isLoading) {
                  <ngx-skeleton-loader
                     count="3"
                     [theme]="skeletonThemes.fillSize"
                  /><br />
               } @else {
                  @for (field of fields; track field.fieldID) {
                     @if (fieldDisplayInfo.get(field.fieldID); as fieldDisplayInfo) {
                        <div
                           (click)="focusField(field)"
                           class="lim-ui-list-item lim-ui-selectable-list-item"
                           [ngClass]="{
                              'lim-ui-selected-list-item': fieldDisplayInfo.selected,
                              'lim-ui-disabled-list-item background-half-opacity':
                                 fieldDisplayInfo.greyOut,
                           }"
                        >
                           <div class="lim-ui-list-item-content">
                              @if (types?.get(field.fieldTypeID); as type) {
                                 <div
                                    class="label-and-icon-item-section"
                                    [ngClass]="{
                                       'field-bold-selected': fieldDisplayInfo.selected,
                                       'half-opacity': fieldDisplayInfo.greyOut,
                                    }"
                                 >
                                    @if (fieldDisplayInfo.selected) {
                                       <lim-ui-icon icon="check" iconColor="primary" />
                                    }

                                    @if (
                                       field.fieldTypeID !== AssetFieldTypeID.Currency &&
                                       type.fieldTypeIcon
                                    ) {
                                       <lim-ui-icon
                                          [icon]="type.fieldTypeIcon | iconAlias"
                                          limUiTooltip="{{ type.fieldTypeHint }}"
                                       />
                                    }
                                    @if (
                                       field.fieldTypeID === AssetFieldTypeID.Currency
                                    ) {
                                       <span limUiTooltip="{{ type.fieldTypeHint }}"
                                          >{{ currencySymbol }}
                                       </span>
                                    }
                                    <span>
                                       <span
                                          class="field-type-name"
                                          [limbleHtml]="field.fieldName"
                                       ></span>
                                       @if (field.lockedDefault) {
                                          <lim-ui-icon
                                             icon="lockRegular"
                                             iconSize="small"
                                             [limUiTooltip]="lang().FieldLockdedTooltip"
                                             placement="right"
                                             iconColor="medium-grey"
                                             data-log="manageAssets-addCustomField-clickEditFieldSettings"
                                          />
                                       }
                                    </span>
                                 </div>
                              }

                              <lim-ui-minimal-icon-button
                                 icon="penToSquareRegular"
                                 [superMinimal]="true"
                                 (click)="
                                    showFieldOptions(field); $event.stopPropagation()
                                 "
                                 limUiTooltip="{{ lang().EditField }}"
                                 [dataLogLabel]="
                                    'manageAssets-initiateEditCustomField-clickEditCustomField'
                                 "
                              />
                           </div>
                        </div>
                     }
                  }
               }
            </div>
         }

         @if (showNew) {
            <div>
               <div class="show-new-name-section">
                  <span [limbleHtml]="lang().NameOfTheInformationToBeTracked"></span>

                  <input
                     type="text"
                     [ngClass]="{ 'has-error': nameErr }"
                     id="focusedinput"
                     placeholder="{{ lang().Name }}"
                     [(ngModel)]="newName"
                  />
               </div>

               <div class="show-new-name-list-section">
                  <span
                     class="title-field-label"
                     [limbleHtml]="lang().TypeOfInformation"
                  ></span>

                  @for (type of types; track type) {
                     <div
                        (click)="focusType(type)"
                        class="lim-ui-list-item lim-ui-selectable-list-item"
                        [ngClass]="{
                           'lim-ui-selected-list-item': selectedType == type.fieldTypeID,
                        }"
                        limUiTooltip="{{ type.fieldTypeHint }}"
                        data-placement="top"
                        [attr.data-log]="
                           'manageAssets-addCustomField-click' +
                           type.fieldTypeName +
                           'Field'
                        "
                     >
                        <div class="lim-ui-list-item-content">
                           <div
                              class="label-and-icon-item-section"
                              [ngClass]="{
                                 'field-bold-selected': selectedType == type.fieldTypeID,
                              }"
                           >
                              @if (selectedType == type.fieldTypeID) {
                                 <lim-ui-icon icon="check" iconColor="primary" />
                              }
                              @if (
                                 type.fieldTypeID !== AssetFieldTypeID.Currency &&
                                 type.fieldTypeIcon
                              ) {
                                 <lim-ui-icon
                                    [icon]="type.fieldTypeIcon | iconAlias"
                                    limUiTooltip="{{ type.fieldTypeHint }}"
                                 />
                              }

                              @if (type.fieldTypeID === AssetFieldTypeID.Currency) {
                                 <span>{{ currencySymbol }}</span>
                              }
                              <span
                                 class="field-type-name"
                                 [limbleHtml]="type.fieldTypeName"
                              ></span>
                           </div>
                           <div>
                              @if (type.fieldTypeID === AssetFieldTypeID.Text) {
                                 <i>{{ lang().fieldType1Example }}</i>
                              }
                              @if (type.fieldTypeID === AssetFieldTypeID.Date) {
                                 <i>{{ lang().fieldType2Example }}</i>
                              }
                              @if (type.fieldTypeID === AssetFieldTypeID.Picture) {
                                 <i>{{ lang().fieldType3Example }}</i>
                              }
                              @if (type.fieldTypeID === AssetFieldTypeID.File) {
                                 <i>{{ lang().fieldType4Example }}</i>
                              }
                              @if (type.fieldTypeID === AssetFieldTypeID.Number) {
                                 <i>{{ lang().fieldType5Example }}</i>
                              }
                              @if (type.fieldTypeID === AssetFieldTypeID.Currency) {
                                 <i>{{ lang().fieldType6Example }}</i>
                              }
                              @if (type.fieldTypeID === AssetFieldTypeID.Dropdown) {
                                 <i>{{ lang().fieldType7Example }}</i>
                              }
                              @if (type.fieldTypeID === AssetFieldTypeID.Video) {
                                 <i>{{ lang().fieldType8Example }}</i>
                              }
                           </div>
                        </div>
                     </div>
                  }
               </div>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="showNew ? lang().Create : lang().Add"
      (successClick)="submit()"
      data-log="manageAssets-storeAddCustomField-clickCreateField"
   />
</lim-ui-modal>
