import { inject, Component, Input, computed } from "@angular/core";
import { IconComponent, LimbleHtmlDirective, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "task-item-link-hint",
   templateUrl: "./taskItemLinkHint.element.component.html",
   styleUrls: ["./taskItemLinkHint.element.component.scss"],
   imports: [LimbleHtmlDirective, IconComponent, TooltipDirective],
})
export class TaskItemLinkHint {
   @Input() showHelp;
   @Input() linkHint;
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
