import { Injectable, resource, type ResourceRef } from "@angular/core";
import { Lookup } from "src/app/shared/utils/lookup";
import type { TaskAsset } from "src/app/tasks/components/shared/services/tasks-api";
import type { PmSuggestionsAssetsInterface } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/pm-suggestions-assets";

@Injectable()
export class PmSuggestionsAssetsFromMemoryService
   implements PmSuggestionsAssetsInterface
{
   public static readonly startingAssets: Lookup<"assetID", TaskAsset> = new Lookup(
      "assetID",
      [
         {
            assetID: 1,
            assetName: "Asset 1",
            assetDeleted: 0,
            assetFileName: "asset1.png",
            assetTrackCOOLabor: 0,
            assetTrackCOOParts: 0,
            assetOperationHoursPerWeek: 0,
            assetCreatedDate: 0,
            assetDeletedDate: 0,
            assetLastEdited: 0,
            migrated: 0,
            migratedFeedback: "",
            assetTrackHoursManually: 0,
            assetTrackHoursManuallyData: "",
            parentAssetID: 0,
            assetOrder: 0,
            reportProblemAssignmentUserID: 0,
            reportProblemAssignmentProfileID: 0,
            assetCheckSimiliarWRScope: 0,
            defaultChecklistID: 0,
            geoLocation: null,
            locationID: 7,
            includeChildData: 0,
            checkOutRequiresApproval: 0,
            canCheckOutAsTool: 0,
            checkOutApprovalProfileID: 0,
            checkOutApprovalUserID: 0,
            assetValueIDs: [],
            assetVendorIDs: [],
            assetPartRelationIDs: [],
            assetChildrenIDs: [],
         },
      ],
   );

   public readonly assets: ResourceRef<Lookup<"assetID", TaskAsset>> = resource({
      request: () => ({}),
      loader: async () =>
         new Promise((resolve) => {
            setTimeout(() => {
               resolve(PmSuggestionsAssetsFromMemoryService.startingAssets);
            }, 0);
         }),
   });
}
