import { NgClass, NgStyle } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import {
   ChangeDetectionStrategy,
   ChangeDetectorRef,
   Component,
   computed,
   forwardRef,
   inject,
   Input,
   signal,
} from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   BadgeComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   IconComponent,
   isMobile,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalService,
   PopoverDirective,
   RowHoverButtonsComponent,
   TableButtonComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import $ from "jquery";
import moment from "moment";
import { Subscription } from "rxjs";
import { AssetHierarchyListComponent } from "src/app/assets/components/asset-hierarchy-list/asset-hierarchy-list.component";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { EmailTemplate } from "src/app/shared/components/global/emailTemplateModal/emailTemplate.modal.component";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { DeferTask } from "src/app/tasks/components/deferTaskModal/deferTask.modal.component";
import { PickTaskType } from "src/app/tasks/components/pickTaskTypeModal/pickTaskType.modal.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { PriorityButtonLegacyComponent } from "src/app/tasks/components/priority-button-legacy/priority-button-legacy.component";
import { StatusButtonLegacyComponent } from "src/app/tasks/components/status-button-legacy/status-button-legacy.component";
import { AssetName } from "src/app/tasks/components/taskItemElement/assetName.pipe";
import { TaskSimultaneousUsersComponent } from "src/app/tasks/components/taskSimultaneousUsers/taskSimultaneousUsers.component";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { Task } from "src/app/tasks/types/task.types";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { ManageProfile } from "src/app/users/services//manageProfile";
import { ManageUser } from "src/app/users/services//manageUser";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

@Component({
   selector: "task-item",
   templateUrl: "./taskItem.element.component.html",
   styleUrls: ["./taskItem.element.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      NgClass,
      PriorityButtonLegacyComponent,
      StatusButtonLegacyComponent,
      DropdownComponent,
      MinimalIconButtonComponent,
      DropdownItemComponent,
      IconComponent,
      DropdownDividerComponent,
      TableButtonComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      forwardRef(() => AssetParentList),
      forwardRef(() => TaskSimultaneousUsersComponent),
      NgStyle,
      RowHoverButtonsComponent,
      BetterDatePipe,
      BetterDecimalPipe,
      AssetName,
      PopoverDirective,
      UpsellPopover,
      BadgeComponent,
      AssetHierarchyListComponent,
      BetterDatePipe,
      LocaleCurrencyPipe,
   ],
})
export class TaskItem implements OnInit, OnDestroy {
   @Input() public taskID: number | undefined;
   @Input() public columns;
   @Input() public isModal = false;
   @Input() public globalSearch = false;
   @Input() public searchHint: string | undefined = undefined;
   @Input() public dataLogSection: string | undefined;
   @Input() public isTaskNameIncludingIdAndAssetName = true;

   public customerID;
   public taskData;
   public assign;
   public deleteCred;
   public duplicateCred;
   public allUsers;
   public showLoc;
   public changeDueDateCred;
   public taskTypeStr;
   private readonly langLoadedSub: Subscription | undefined;
   public statusListSub;
   public statusList: any = [];
   public statusListIndex = {};
   public dropdownClicked = false;
   public superUser;
   public taskIsCompleted;
   public hasParts = false;
   public partsInStock = false;
   public task: Task | undefined;
   protected deferTaskPermission: boolean = false;
   private readonly isMobile: boolean;
   private readonly taskDataSub: Subscription;
   private readonly filtersAppliedSub: Subscription;
   public taskExtraInfo: {
      commentInfo?: { showCommentsHint: boolean; unreadComments: number };
      requestorInformation: string;
      taskTypeIcons?: Aliases;
      statusTimeInfo?:
         | { timeSpentInStatusOpen: number; timeSpentInStatusInProgress: number }
         | undefined;
      daysInfo?:
         | { days: number; daysMsg: string; daysStatus: string; exactDays: number }
         | undefined;
      calculatedInfo?: {
         checklistEstTimeMinutes: number;
         checklistPriorityDisplay: number;
         checklistTotalInvoiceCost: number;
         checklistTotalPartsCost: number;
         checklistPromptTimeTotal: number;
         checklistPromptTimeTotalHours: number;
         checklistPromptTimeTotalMinutes: number;
         billableTimeTotal: number;
         billableTimeTotalHours: number;
         billableTimeTotalMinutes: number;
         checklistTotalLaborCost: number;
         checklistTotalOperatingCost: number;
         checklistTemplateOldString: string;
      };
      completedInfo?: {
         completedFirstName: string;
         completedLastName: string;
         completedByStr: string;
         completedDaysPastDueDate: number;
         completedDaysPastDueDateTooltip: string;
      };
      displayName: string;
      checklistDueDateDisplay: string;
      priorityInfo: { priorityLevel?: number; priorityName: string };
      statusInfo: { statusName: string; statusAbbr: string } | undefined;
      selected: boolean;
      locationName: string;
      checklistPromptTimeTotal: number | null;
      checklistTemplateOldString: string;
      viewTask?: () => void;
   } = {
      displayName: "",
      checklistDueDateDisplay: "",
      requestorInformation: "",
      priorityInfo: { priorityName: "" },
      statusInfo: undefined,
      selected: false,
      locationName: "",
      checklistPromptTimeTotal: null,
      checklistTemplateOldString: "",
   };
   private manageFeatureFlagsSub: Subscription = new Subscription();
   protected canDuplicateTasks: boolean = false;
   protected addPartPopoverCheck: boolean = false;
   protected woInstructionLimit: number = 2;

   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageLocation = inject(ManageLocation);
   public readonly manageAsset = inject(ManageAsset);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);
   private readonly manageProfile = inject(ManageProfile);
   private readonly paramsService = inject(ParamsService);
   private readonly manageStatus = inject(ManageStatus);
   private readonly manageParts = inject(ManageParts);
   private readonly changeDetectorRef = inject(ChangeDetectorRef);
   private readonly manageFilters = inject(ManageFilters);
   private readonly credService = inject(CredService);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly manageLang = inject(ManageLang);

   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly locationQueries = inject(LocationQueriesService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;
   private readonly locationID = signal<number | undefined>(undefined);
   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationQueries.detail(this.locationID()),
   );
   protected readonly isCurrencyPending = computed(
      () => this.locationQuery.isPending() || this.accountCurrencyQuery.isPending(),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode),
      this.isMultiCurrencyEnabled,
   );

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.isMobile = isMobile();
      this.taskDataSub = this.manageTask.taskDataChanged$.subscribe(() => {
         this.runChangeDetection();
      });
      this.filtersAppliedSub = this.manageFilters.filtersApplied$.subscribe(() => {
         this.runChangeDetection();
      });
      this.langLoadedSub = inject(ManageLang).langLoaded$.subscribe(() => {
         this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
         this.deferTaskPermission = this.manageTask.deferTaskPermission(this.customerID);
         //its assigned to a user
         if (this.task) {
            this.buildDisplayData();
         }
      });
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
   }

   public ngOnInit() {
      if (!this.taskID) return;
      this.task = this.manageTask.getTaskLocalLookup(this.taskID);
      if (!this.task) return;

      this.buildDisplayData();
      this.superUser = this.credService.checkCredGlobal(30);

      this.statusListSub = this.manageObservables.setSubscription("statusList", () => {
         this.statusList = [];
         setTimeout(() => {
            this.statusList = this.manageStatus.getStatusList();
            this.statusListIndex = this.manageStatus.getStatusListIndex();
         }, 1);
      });
      this.taskData = [];
      this.locationID.set(this.task.locationID);
      this.assign = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.ChangeAssignmentsOfOpenTasks,
      );
      this.deleteCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.DeleteOpenTasks,
      );
      this.duplicateCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.StartNewTasks,
      );

      const locations = this.manageLocation.getLocationsIndex();
      if (locations[this.task.locationID]) {
         this.taskExtraInfo.locationName = locations[this.task.locationID].locationName;
      }

      this.allUsers = this.manageTask.getAllUsers();

      this.taskTypeService.getTaskTypeIcon(this.task);

      this.changeDueDateCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.ChangeDueDatesOfOpenTasks,
      );

      this.taskExtraInfo.viewTask = () => {
         this.viewTask();
      };

      this.checkPartsInStock();

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(() => {
         this.manageFeatureFlags
            .hasReachedWOInstructionLimitFor(moment())
            .then((hasReachedWOInstructionLimitFor) => {
               this.canDuplicateTasks = !hasReachedWOInstructionLimitFor;
            });
         this.woInstructionLimit = this.manageFeatureFlags.getWOInstructionLimit();
      });

      this.runChangeDetection();
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([this.statusListSub]);
      if (this.langLoadedSub !== undefined) {
         this.langLoadedSub.unsubscribe();
      }

      if (this.taskDataSub !== undefined) {
         this.taskDataSub.unsubscribe();
      }

      if (this.filtersAppliedSub !== undefined) {
         this.filtersAppliedSub.unsubscribe();
      }

      this.manageFeatureFlagsSub.unsubscribe();
   }

   private checkPartsInStock(): void {
      if (!this.task) return;
      if (this.task.partRelationIDs?.length > 0) {
         this.hasParts = true;
         let partsInStock = true;
         for (const partRelationID of this.task.partRelationIDs) {
            const taskPart = this.manageTask.getPartRelation(partRelationID);
            if (!taskPart) continue;
            const part = this.manageParts.getPart(taskPart.partID);
            if (!part) {
               partsInStock = false;
               continue;
            }
            const partRelationAvailability =
               this.manageParts.getPartRelationAvailability(part);
            if (partRelationAvailability?.get(partRelationID)?.inStock === false) {
               partsInStock = false;
            }
         }

         this.partsInStock = partsInStock;
      }
   }

   public calcTaskTypeStr(): void {
      //figure out what the type string should be... task, PM or WO
      //This is manual used so we don't have that many watches in the view
      if (!this.task) return;
      if (this.task.checklistTemplate == 0) {
         if (this.task.checklistTemplateOld == 1) {
            this.taskTypeStr = this.lang().ThisIsAPM;
         } else if (this.task.checklistTemplateOld == 2) {
            this.taskTypeStr = this.lang().ThisIsAUnplannedWO;
         } else if (this.task.checklistTemplateOld == 4) {
            this.taskTypeStr = this.lang().ThisIsAPlannedWO;
         } else if (this.task.checklistTemplateOld == 5) {
            this.taskTypeStr = this.lang().ThisIsACycleCountWO;
         }
         if (this.task.checklistTemplateOld == 2 && this.task.checklistBatchID == 10000) {
            this.taskTypeStr = this.lang().ThisIsAProblemReport;
         }
         if (
            this.task.checklistTemplateOld == 2 &&
            this.task.checklistBatchID == 300112
         ) {
            this.taskTypeStr = this.lang().ThisIsAWorkRequest;
         }
      } else if (this.task.checklistTemplate == 1) {
         this.taskTypeStr = this.lang().ThisIsAPM;
      } else if (this.task.checklistTemplate == 2) {
         this.taskTypeStr = this.lang().ThisIsAUnplannedWO;
      } else if (this.task.checklistTemplate == 4) {
         this.taskTypeStr = this.lang().ThisIsAPlannedWO;
      }

      if (!this.taskIsCompleted && this.task.checklistTemplateOld != 5) {
         // Add some extra text if the task isn't completed
         this.taskTypeStr = `${this.taskTypeStr}. ${this.lang().ClickForAFewOptions}`;
      }
   }

   public calcDays(): void {
      if (!this.task) return;
      this.manageTask.getDaysForTask(this.task);
   }

   public changeAssignment(task: Task): void {
      if (!task || !this.task) return;
      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangeAssignmentsOfOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred26Fail, "danger", 10000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      extraUsersOptions.arr.forEach((user) => {
         extraUsersOptions.key[user.userID] = user;
      });

      const instance = this.modalService.open(PickUserOrProfileLegacy);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               title: this.lang().changeAssignmentTitle,
               message: this.lang().changeAssignmentMessage,
               locationID: task.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: task.userID,
               defaultProfile: task.profileID,
            },
         },
      };

      instance.result.then((result) => {
         if (result != 0) {
            this.manageTask
               .changeOwner(
                  result.userID,
                  result.profileID,
                  result.multiUsers,
                  task.checklistID,
                  this.manageUser,
                  this.manageProfile,
               )
               .then((answer: any) => {
                  if (answer.data.success == true) {
                     if (!this.task) return;
                     this.task.userID = answer.data.userID;
                     this.task.profileID = answer.data.profileID;

                     this.alertService.addAlert(
                        `<i class='fa-regular fa-square-check fa-fw'></i>${this.lang().successMsgAssignmentAndNotification}`,
                        "success",
                        2000,
                     );

                     this.runChangeDetection();
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         }
      });
   }

   public emailReminder(task): void {
      const instance = this.modalService.open(EmailTemplate);
      const assetNameStr = this.manageAsset.getAssetNameIncludeParents(task.assetID);
      const currentUser = this.manageUser.getCurrentUser();
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().emailReminderMessage,
            title: this.lang().emailReminderTitle,
            data: {
               emailTo: false,
               emailSubject: this.lang().emailReminderSubject,
               emailMessage: `"${task.checklistName} - #${task.checklistID} - ${assetNameStr} at ${this.taskExtraInfo.locationName}" ${this.lang().emailReminderBody} - ${currentUser.userInfo.customerSignature}`,
               pickEmployees: true,
               checklistID: task.checklistID,
               onSubmit: "send",
            },
         },
         backdrop: "static",
         keyboard: false,
      };
   }

   public async viewTask(): Promise<void> {
      if (this.dropdownClicked) {
         this.dropdownClicked = false;
         return;
      }
      assert(this.task);
      const dataLogOptions: DataLogEventDefinition = {
         taskInitiateLabel: this.dataLogSection
            ? `${this.dataLogSection}-initiateAPart`
            : undefined,
         taskStoreLabel: "manageParts-storeAnAddition",
         vendorInitiateLabel: this.dataLogSection
            ? `${this.dataLogSection}-initiateAVendor`
            : undefined,
      };
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            closeModalFunc: () => {
               this.checkPartsInStock();
               this.runChangeDetection();
            },
            dataLogOptions,
            data: {
               checklistID: this.task.checklistID,
            },
         },
      };
      await instance.result;
      this.buildDisplayData();
   }

   public dropdownClick(): void {
      this.dropdownClicked = true;

      if (this.taskIsCompleted) {
         this.completedTaskWarning();
      }
   }

   public deleteTask(): void {
      this.addPartPopoverCheck = false;
      if (!this.task) return;
      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.DeleteOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred24Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().deleteTaskMessage,
            title: `${this.lang().Delete} ${this.task.checklistName}`,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            if (!this.task) return;
            this.manageTask
               .deleteChecklistInstance(this.task.checklistID)
               .then((answer) => {
                  if (answer.data.success == true) {
                     this.alertService.addAlert(
                        `<i class='fa-regular fa-square-check fa-fw'></i> ${this.lang().successMsgDeletion}`,
                        "success",
                        5000,
                     );
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         }
      });
   }

   public viewAsset(task: Task): void {
      if (this.isMobile) {
         //Rick requested that we deactivate this link on taskItems when they're on Mobile, and have it so tapping on it will just open the task instead.
         this.viewTask();
         return;
      }
      const instance = this.modalService.open(PopAsset);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: task.assetID,
            locationID: task.locationID,
            closeModalFunc: () => {
               this.runChangeDetection();
            },
            data: {
               restrict: false,
            },
         },
      };
   }

   public async duplicate($event): Promise<void> {
      if (!this.task) return;
      if (!this.canDuplicateTasks) {
         // Only make the extra network request if the user has reached the limit
         const itemsData = await this.manageTaskItem.getTaskItems(this.task.checklistID);

         if (itemsData.data.items && itemsData.data.items.length > 1) {
            this.addPartPopoverCheck = true;
            $event.stopPropagation();
            return;
         }
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred43Fail, "danger", 10000);
         return;
      }

      this.manageTask.duplicateOpenTask(this.task.checklistID, this.task.locationID).then(
         () => {
            this.alertService.addAlert(
               this.lang().successMsgAndNotification,
               "success",
               5000,
            );
         },
         () => {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         },
      );
   }

   public deferTask(): void {
      if (!this.task) return;
      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.DeferAnOpenTask,
         )
      ) {
         this.alertService.addAlert(this.lang().cred102Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(DeferTask);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().deferMessage,
            title: `${this.lang().Defer} - <b>${this.task.checklistName}</b>?`,
            checklistID: this.task.checklistID,
         },
      };

      instance.result.then((result) => {
         if (result) {
            this.manageTask
               .deferTask(this.task, result.reason, result.pickedDate)
               .then((answer) => {
                  if (!this.task) return;
                  if (answer.data.success == true) {
                     this.task.checklistDueDate = Number(
                        answer.data.task.checklistDueDate,
                     );
                     this.taskExtraInfo.checklistDueDateDisplay =
                        this.manageTask.getTaskDueDateDisplay(this.task);
                     this.calcDays();
                     this.runChangeDetection();

                     this.alertService.addAlert(
                        `<i class='fa-regular fa-square-check fa-fw'></i> ${this.lang().successMsgDeferred}`,
                        "success",
                        5000,
                     );
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         }
      });
   }

   public changeDueDate(): void {
      if (!this.task) return;
      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangeDueDatesOfOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred46Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().changeDueDateMessage,
            title: this.lang().changeDueDateTitle,
            buttonText: this.lang().Change,
            currentDate: this.task.checklistDueDate,
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: true,
               checklistID: this.task.checklistID,
               parentOfDate: "dueDate",

               startDate: {
                  viewTimeOfDay: true,
                  currentDate: this.task.checklistStartDate,
                  setting: this.task.checklistStartDateSetting,
                  setNoTimeOfDayOffset: true,
               },
            },
         },
      };

      instance.result.then((result) => {
         if (!this.task) return;
         if (result.date) {
            const date = new Date(result.date);
            const newTime = date.getTime() / 1000;
            const oldTime = `${this.task.checklistDueDate}`;

            if (result.timeOfDay == false) {
               this.task.checklistDueDateSetting = 0;
            } else {
               this.task.checklistDueDateSetting = 1;
            }

            let startDate, newStartDate, oldStartDate, startDateSetting;
            if (result.startDate.date == false) {
               newStartDate = 0;
               oldStartDate = this.task.checklistStartDate;
               startDateSetting = false;
            } else {
               startDate = new Date(result.startDate.date);
               newStartDate = startDate.getTime() / 1000;
               oldStartDate = this.task.checklistStartDate;
               if (result.startDate.timeOfDay == false) {
                  startDateSetting = false;
               } else {
                  startDateSetting = true;
               }
            }

            this.manageTask
               .updateDueDate(
                  Number(oldTime),
                  newTime,
                  this.task.checklistID,
                  result.timeOfDay,
                  oldStartDate,
                  newStartDate,
                  startDateSetting,
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     this.calcDays();
                     this.runChangeDetection();

                     this.alertService.addAlert(
                        `<i class='fa-regular fa-square-check fa-fw'></i> ${this.lang().successMsgDueDateChange}`,
                        "success",
                        5000,
                     );
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   //This function changes the type of the instance to any of the following:
   public changeTaskType(): void {
      if (!this.task) return;
      if (
         this.task.checklistUserCompleted !== null &&
         this.task.checklistUserCompleted > 0
      ) {
         this.alertService.addAlert(this.lang().cantChange, "warning", 6000);
         return;
      }

      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      const instance = this.modalService.open(PickTaskType);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().pickTaskTypeMessage,
            title: this.lang().pickTaskTypeTitle,
         },
      };

      instance.result.then((result) => {
         if (result) {
            if (!this.task) return;
            this.manageTask
               .updateTaskType(
                  this.task.checklistID,
                  result.checklistTemplateOld,
                  result.checklistBatchID,
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     if (!this.task) return;
                     const task = this.manageTask.getTaskLocalLookup(
                        this.task.checklistID,
                     );

                     if (task) {
                        task.checklistLastEdited = Math.floor(Date.now() / 1000);
                     }
                     this.runChangeDetection();
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);

                     //this is a work around for a list not updating properly.
                     setTimeout(() => {
                        if ($("#popUpCompletedTasksCloseMe")[0]) {
                           if (!this.task) return;
                           $("#popUpCompletedTasksCloseMe")[0].click();

                           //also closes out the task because its on the dashboard.
                           $(`#popTaskCloseMe${this.task.checklistID}`)[0].click();
                        }
                     }, 100);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   }

   public completedTaskWarning(): void {
      this.alertService.addAlert(this.lang().completedTaskWarning, "warning", 10000);
   }

   // This will run the change detection since the change detections strategy is set to onPush
   private runChangeDetection(): void {
      this.changeDetectorRef.detectChanges();
   }

   private buildDisplayData(): void {
      if (this.task === undefined) return;
      this.calcTaskTypeStr();
      this.taskExtraInfo.displayName = this.manageTask.getTaskAssignmentInfo(
         this.task,
      ).displayName;
      this.taskExtraInfo.daysInfo = this.manageTask.getDaysForTask(this.task);
      this.taskExtraInfo.commentInfo = this.manageTask.getTaskCommentsInfo(this.task);
      this.taskExtraInfo.taskTypeIcons = this.taskTypeService.getTaskTypeIcon(this.task);
      this.taskExtraInfo.calculatedInfo = this.manageTask.getCalculatedTaskInfo(
         this.task,
      );
      this.taskExtraInfo.requestorInformation = this.manageTask.getTaskRequestorInfo(
         this.task,
      );
      this.taskExtraInfo.checklistDueDateDisplay = this.manageTask.getTaskDueDateDisplay(
         this.task,
      );
      const statusLogs = this.manageStatus.getArrayOfStatusLogsByChecklistID(
         this.task.checklistID,
      );
      this.taskExtraInfo.statusTimeInfo = this.manageTask.getTaskTimeInStatuses(
         this.task,
         statusLogs,
      );
      this.taskIsCompleted =
         this.task?.checklistCompletedDate !== null &&
         this.task?.checklistCompletedDate !== undefined &&
         this.task?.checklistCompletedDate > 0;
      this.taskExtraInfo.priorityInfo = this.manageTask.getPriorityInfo(
         this.task.priorityID,
      );

      if (this.taskIsCompleted) {
         const completedInfo = this.manageTask.getCompletedTaskCalculatedInfo(this.task);
         if (completedInfo) this.taskExtraInfo.completedInfo = completedInfo;
      }

      this.taskExtraInfo.statusInfo = this.manageTask.getStatusInfo(this.task.statusID);

      this.taskExtraInfo.checklistPromptTimeTotal = this.manageTask.getCalculatedTaskInfo(
         this.task,
      ).checklistPromptTimeTotal;
      this.calcDays();
      //this will determine if the location item should be shown
      this.showLoc = this.manageLocation.getSelectedLocation() == this.task.locationID;
      if (this.task.checklistTemplateOld == 1) {
         this.taskExtraInfo.checklistTemplateOldString = this.lang().PM;
      } else if (
         this.task.checklistTemplateOld == 2 &&
         (this.task.checklistBatchID == 300112 || this.task.checklistBatchID == 10000)
      ) {
         this.taskExtraInfo.checklistTemplateOldString = this.lang().WorkRequest;
      } else if (this.task.checklistTemplateOld == 2) {
         this.taskExtraInfo.checklistTemplateOldString = this.lang().UnplannedWO;
      } else if (this.task.checklistTemplateOld == 4) {
         this.taskExtraInfo.checklistTemplateOldString = this.lang().PlannedWO;
      } else if (this.task.checklistTemplateOld == 5) {
         this.taskExtraInfo.checklistTemplateOldString = this.lang().CycleCount;
      }
      this.changeDetectorRef.detectChanges();
   }
}
