import { inject, Injectable } from "@angular/core";
import {
   catchError,
   firstValueFrom,
   map,
   type Observable,
   of,
   Subject,
   switchMap,
   take,
   tap,
   throwError,
} from "rxjs";
import { AssetFieldDefinitionService } from "src/app/assets/services/asset-field-definition.service";
import {
   type AssetFieldDefinitionRequest,
   type AssetTemplateField,
   AssetFieldScopeType,
} from "src/app/assets/services/asset-field.types";
import type { AssetTemplateFieldsAPI } from "src/app/assets/services/asset-template-fields/asset-template-field-api.models";
import { AssetTemplateFieldApiService } from "src/app/assets/services/asset-template-fields/asset-template-field-api.service";
import { EditAssetTemplateFieldApiService } from "src/app/assets/services/asset-template-fields/edit-asset-template-field-api.service";
import { AssetTemplateService } from "src/app/assets/services/asset-template.service";
import { TemplatesPageStore } from "src/app/assets/services/templatesPage.store";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { assert } from "src/app/shared/utils/assert.utils";

type TemplateID = number;

@Injectable({
   providedIn: "root",
})
export class AssetTemplateFieldService {
   private readonly templateFieldsSubject$ = new Subject<null>();
   private readonly assetTemplateService = inject(AssetTemplateService);
   private readonly assetFieldDefinitionService = inject(AssetFieldDefinitionService);
   private readonly assetTemplateFieldApiService = inject(AssetTemplateFieldApiService);
   private readonly editAssetTemplateService = inject(EditAssetTemplateFieldApiService);
   private readonly featureFlagService = inject(FeatureFlagService);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly templatePageStore = inject(TemplatesPageStore);

   private apiService: AssetTemplateFieldsAPI = this.assetTemplateFieldApiService;

   public getTemplateFieldsUpdates(): Observable<null> {
      return this.templateFieldsSubject$.asObservable();
   }

   public startEditing() {
      this.templatePageStore.setUiMode("editing");
      this.apiService = this.editAssetTemplateService;
   }

   public hasEdits(): boolean {
      return this.editAssetTemplateService.hasChanges();
   }

   public stopEditing(): void {
      this.apiService = this.assetTemplateFieldApiService;
      this.editAssetTemplateService.clear();
      this.templatePageStore.setUiMode("published");
   }

   public publishEdits(): Observable<AssetTemplateField[]> {
      return this.editAssetTemplateService.publish().pipe(
         take(1),
         tap(() => {
            this.stopEditing();
         }),
      );
   }

   /** interaction with new table tbl_asset_template_fields
    *   templateID: int(11) NOT NULL
    *   fieldID: int (11)NOT NULL
    *   customerID: int(11) unsigned NOT NULL
    *   viewOrder: int(11) unsigned NOT NULL **/

   public getTemplateFields(templateID: TemplateID): Observable<AssetTemplateField[]> {
      return this.apiService
         .getList({
            filters: {
               templateID,
            },
         })
         .pipe(map((response) => response.data ?? []));
   }

   public getTemplateFieldsMap(
      templateID: TemplateID = 0,
   ): Observable<Map<TemplateID, AssetTemplateField>> {
      const method =
         templateID > 0
            ? this.getTemplateFields(templateID)
            : this.getPublishedTemplateFields();
      return method.pipe(
         map((fields) => {
            return new Map((fields ?? []).map((field) => [field.fieldID, field]));
         }),
      );
   }

   /**
    * First creates standardized field
    * and then once that finishes/succeeds
    * it calls the endpoint to add a field to the template.  **/
   public createFieldForTemplate(
      requestData: Partial<AssetFieldDefinitionRequest>,
      templateID: number,
   ): Observable<AssetTemplateField> {
      //Enforce template fields are always standardized
      requestData.scopeType = AssetFieldScopeType.Standardized;
      requestData.locationID = null;
      return this.assetFieldDefinitionService.createDefinition(requestData).pipe(
         switchMap((field) => this.addFieldToTemplate(field.fieldID, templateID)),
         catchError((error) => {
            return throwError(() => new Error(error.message || "Unknown error occurred"));
         }),
      );
   }

   public bulkAddFieldsToTemplate(
      fieldIDs: number[],
      templateID: TemplateID,
   ): Observable<AssetTemplateField[]> {
      return this.apiService
         .createBulk(fieldIDs, {
            filters: {
               templateID,
            },
         })
         .pipe(
            tap((fields) => {
               this.templateFieldsSubject$.next(null);
               this.templatePageStore.addFields(fields);
            }),
         );
   }

   public addFieldToTemplate(
      fieldID: number,
      templateID: TemplateID,
   ): Observable<AssetTemplateField> {
      return this.bulkAddFieldsToTemplate([fieldID], templateID).pipe(
         map((fields) => fields[0]),
      );
   }

   public moveField(oldIndex: number, newIndex: number): Observable<AssetTemplateField> {
      if (oldIndex === newIndex) {
         return of(this.templatePageStore.fields()[oldIndex]);
      }

      // Rely on the store to handle updating the view orders for all fields.  Can't rely on the backend to update the viewOrder
      // because in edit mode we can't refetch the fields after the move.
      const field = this.templatePageStore.moveField(oldIndex, newIndex);
      assert(field, "Field not found");

      return this.apiService.patch(
         field.fieldID,
         { viewOrder: field.viewOrder },
         {
            filters: {
               templateID: field.templateID,
            },
         },
      );
   }

   public deleteTemplateField(fieldID: number, templateID: TemplateID): Observable<any> {
      return this.apiService
         .delete(fieldID, {
            filters: {
               templateID,
            },
         })
         .pipe(
            tap(() => {
               this.templateFieldsSubject$.next(null);
               this.templatePageStore.removeField(fieldID);
            }),
         );
   }

   public isFieldLinkedToTemplate(
      fieldID: number,
      templateID?: TemplateID,
   ): Observable<boolean> {
      if (!fieldID) return of(false);

      //if a template is not specified then check for any published templates
      if (!templateID) {
         return this.assetTemplateService.getTemplates(["published"]).pipe(
            map((templates) => {
               return {
                  templateID: templates[0]?.ID,
                  isPublished: templates[0]?.Status === "published",
               };
            }),
            switchMap((templateData) => {
               if (!templateData.isPublished) return of(false);

               return this.isFieldInTemplate(fieldID, templateData.templateID);
            }),
         );
      }

      if (templateID > 0) {
         return this.assetTemplateService.getTemplate(templateID).pipe(
            map((template) => {
               return template?.Status === "published";
            }),
            switchMap((isPublished) => {
               if (!isPublished) return of(false);

               return this.isFieldInTemplate(fieldID, templateID);
            }),
         );
      }
      return of(false);
   }

   public isFieldInTemplate(
      fieldID: number,
      templateID: TemplateID,
   ): Observable<boolean> {
      if (fieldID > 0 && templateID > 0) {
         return this.getTemplateFields(templateID).pipe(
            map((fields) =>
               fields.some(
                  (field) => field.fieldID === fieldID && field.templateID === templateID,
               ),
            ),
         );
      }
      return of(false);
   }

   public getPublishedTemplateFields(): Observable<AssetTemplateField[]> {
      return this.assetTemplateService.getTemplates(["published"]).pipe(
         map((templates) => {
            return templates[0]?.ID;
         }),
         switchMap((templateID) => {
            if (!templateID) return of([]);

            return this.getTemplateFields(templateID);
         }),
      );
   }

   public async getTemplateFieldsMapWithFlagChecks(): Promise<
      Map<number, AssetTemplateField>
   > {
      const isAssetTemplatesEnabled = await this.legacyLaunchFlagsService.isEnabled(
         Flags.ASSET_TEMPLATES,
      );
      const isFeatureAssetTemplates = this.featureFlagService
         .featureSet()
         ?.has("assetTemplates");

      if (!isAssetTemplatesEnabled || !isFeatureAssetTemplates) {
         return new Map<number, AssetTemplateField>();
      }

      let fieldsMap: Map<number, AssetTemplateField> = new Map<
         number,
         AssetTemplateField
      >();
      try {
         fieldsMap = await firstValueFrom(this.getTemplateFieldsMap());
      } catch (error) {
         console.error("Failed to retrieve asset template fields");
      }

      return fieldsMap;
   }
}
