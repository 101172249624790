export const LocaleToRepresentativeLocale: Record<string, string> = {
   // Western format (1,234.56) - uses en-US
   "en-US": "en-US", // United States
   "en-GB": "en-US", // United Kingdom
   "en-CA": "en-US", // Canada (when not using French)
   "en-AU": "en-US", // Australia
   "en-NZ": "en-US", // New Zealand
   "en-IE": "en-US", // Ireland
   "en-ZA": "en-US", // South Africa
   "en-PH": "en-US", // Philippines
   "en-SG": "en-US", // Singapore
   "en-HK": "en-US", // Hong Kong
   "es-US": "en-US", // Spanish (US)
   "es-MX": "en-US", // Mexico
   "es-AR": "en-US", // Argentina
   "es-CL": "en-US", // Chile
   "es-CO": "en-US", // Colombia
   "es-PE": "en-US", // Peru
   "pt-BR": "en-US", // Brazil
   "fil-PH": "en-US", // Filipino
   "ms-MY": "en-US", // Malay
   "id-ID": "en-US", // Indonesian
   "th-TH": "en-US", // Thai
   "vi-VN": "en-US", // Vietnamese
   "zh-HK": "en-US", // Chinese (Hong Kong)
   "zh-SG": "en-US", // Chinese (Singapore)

   // Continental European format (1.234,56) - uses de-DE
   "de-DE": "de-DE", // German
   "de-AT": "de-DE", // Austrian German
   "fr-FR": "de-DE", // French
   "fr-BE": "de-DE", // Belgian French
   "fr-CA": "de-DE", // Canadian French
   "fr-CH": "de-DE", // Swiss French
   "it-IT": "de-DE", // Italian
   "es-ES": "de-DE", // Spain
   "pt-PT": "de-DE", // Portugal
   "nl-NL": "de-DE", // Dutch
   "nl-BE": "de-DE", // Belgian Dutch
   "pl-PL": "de-DE", // Polish
   "cs-CZ": "de-DE", // Czech
   "sk-SK": "de-DE", // Slovak
   "hu-HU": "de-DE", // Hungarian
   "ro-RO": "de-DE", // Romanian
   "hr-HR": "de-DE", // Croatian
   "sl-SI": "de-DE", // Slovenian
   "bg-BG": "de-DE", // Bulgarian
   "sr-RS": "de-DE", // Serbian
   "el-GR": "de-DE", // Greek
   "tr-TR": "de-DE", // Turkish
   "ru-RU": "de-DE", // Russian
   "uk-UA": "de-DE", // Ukrainian
   "sv-SE": "de-DE", // Swedish
   "nb-NO": "de-DE", // Norwegian
   "da-DK": "de-DE", // Danish
   "fi-FI": "de-DE", // Finnish

   // No decimals format (1,234) - uses ja-JP
   "ja-JP": "ja-JP", // Japanese
   "ko-KR": "ja-JP", // Korean
   "zh-CN": "ja-JP", // Chinese (Simplified)
   "zh-TW": "ja-JP", // Chinese (Traditional)

   // Indian grouping format (1,23,456.78) - uses en-IN
   "en-IN": "en-IN", // Indian English
   "hi-IN": "en-IN", // Hindi
   "bn-IN": "en-IN", // Bengali (India)
   "ta-IN": "en-IN", // Tamil (India)
   "te-IN": "en-IN", // Telugu
   "mr-IN": "en-IN", // Marathi
   "gu-IN": "en-IN", // Gujarati
   "kn-IN": "en-IN", // Kannada
   "ml-IN": "en-IN", // Malayalam
   "pa-IN": "en-IN", // Punjabi
   "bn-BD": "en-IN", // Bengali (Bangladesh)
   "si-LK": "en-IN", // Sinhala
   "ur-PK": "en-IN", // Urdu

   // Middle Eastern format with 3 decimals (1,234.000) - uses ar-BH
   "ar-BH": "ar-BH", // Arabic (Bahrain)
   "ar-KW": "ar-BH", // Arabic (Kuwait)
   "ar-OM": "ar-BH", // Arabic (Oman)
   "ar-QA": "ar-BH", // Arabic (Qatar)
   "ar-SA": "ar-BH", // Arabic (Saudi Arabia)
   "ar-AE": "ar-BH", // Arabic (UAE)
   "ar-EG": "ar-BH", // Arabic (Egypt)
   "ar-IQ": "ar-BH", // Arabic (Iraq)
   "ar-JO": "ar-BH", // Arabic (Jordan)
   "ar-LB": "ar-BH", // Arabic (Lebanon)
   "ar-LY": "ar-BH", // Arabic (Libya)
   "ar-MA": "ar-BH", // Arabic (Morocco)
   "ar-SY": "ar-BH", // Arabic (Syria)
   "ar-TN": "ar-BH", // Arabic (Tunisia)
   "ar-YE": "ar-BH", // Arabic (Yemen)
   "fa-IR": "ar-BH", // Persian

   // Swiss format (1'234.56) - uses de-CH
   "de-CH": "de-CH", // Swiss German
   "it-CH": "de-CH", // Swiss Italian
   "rm-CH": "de-CH", // Romansh
};
