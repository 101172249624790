<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="lim-ui-form-column">
            <div class="lim-ui-form-group">
               @if (message) {
                  <label>
                     <div [limbleHtml]="message"></div>
                  </label>
               }
            </div>

            <div class="lim-ui-form-group">
               <label>{{ lang().DelayTaskTo }}</label>

               <lim-ui-date-picker-input class="filter-input-box" [(model)]="date" />
            </div>

            <div class="lim-ui-form-group" [ngClass]="{ 'has-error': errorMsg }">
               <label>{{ lang().Reason }}</label>
               <div
                  type="text"
                  [(ngModel)]="reason"
                  class="large-text-field"
                  limbleContentEditable
               ></div>
            </div>
         </div>
      </lim-ui-panel>

      <div class="alert alert-dismissable alert-danger" [hidden]="!errorMsg">
         <strong>{{ lang().Warning }}!</strong> -
         <span [limbleHtml]="errorMsg"></span>
      </div>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Submit }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
