@let taskVM = taskViewModel();
@if (taskVM && task) {
   <!--  -->
   <!-- chkTemplate -->
   <!--  -->
   @if (mode() === "template" && taskDisplayData) {
      <div class="task-container">
         <header class="chk-header">
            @if (taskFormState.shouldShowModeToggleButton()) {
               <div class="title-and-icons">
                  <div>
                     <div class="simple-modal-title">
                        @if (title) {
                           <span>{{ title }}</span>
                        }
                        @if (!title) {
                           @if (task.checklistName?.length === 0) {
                              <span>{{ lang().EditWOTemplateTask }}</span>
                           }
                           @if (task.checklistName?.length) {
                              @if (EditingWOTemplateInstanceBeforeLive) {
                                 <span>{{ lang().EditWOTemplateTask }}</span>
                              }
                              @if (!EditingWOTemplateInstanceBeforeLive) {
                                 <span> {{ lang().Task }} #{{ task.checklistID }} </span>
                              }
                           }
                        }
                     </div>

                     <div class="title-subtext">
                        @if (lastEditedDate() !== "" && lastEditedUser() !== "") {
                           <span
                              >{{ lang().InstructionsLastEdited }}: {{ lastEditedDate() }}
                              {{ lang().By }}
                              {{ lastEditedUser() }}
                           </span>
                        }
                     </div>
                  </div>

                  <div class="icons">
                     @if (task.checklistTemplate === 1) {
                        <lim-ui-minimal-icon-button
                           icon="rssRegular"
                           (click)="updateRelatedTasks()"
                           iconSize="medium-large"
                           limUiTooltip="{{ lang().updateRelatedPMsTooltip }}"
                           placement="left"
                        />
                     }
                     @if (task.checklistTemplate === 6) {
                        <lim-ui-minimal-icon-button
                           icon="rssRegular"
                           (click)="syncRelatedInstructionSets()"
                           iconSize="medium-large"
                           limUiTooltip="{{ lang().SyncRelatedInstructionSetsTooltip }}"
                           placement="left"
                        />
                     }
                     @if (task.checklistTemplate !== 6) {
                        <lim-ui-minimal-icon-button
                           class="lim-ui-hide-on-mobile"
                           icon="gearRegular"
                           (click)="settings()"
                           placement="left"
                           iconSize="medium-large"
                           limUiTooltip="{{ lang().SettingsTooltip }}"
                        />
                     }
                     @if (modalInstance !== undefined) {
                        <lim-ui-minimal-icon-button
                           icon="xmarkLargeRegular"
                           iconSize="medium-large"
                           (click)="close()"
                        />
                     }
                  </div>
               </div>
            }
         </header>

         <lim-ui-scroll-container limUiScrollWhileDragging>
            <div class="chk-body">
               @if (message) {
                  <lim-ui-info-panel>
                     <span [limbleHtml]="message"></span>
                  </lim-ui-info-panel>
               }

               <!-- progress bar for syncing tasks -->
               @if (end > 0) {
                  <div class="uploading-popup">
                     <div class="progress-bar">
                        <lim-ui-loading-animation />
                        <div class="text-and-progress">
                           <div class="text">
                              <div class="upload-header">
                                 {{ lang().WakingUpHamsters }}
                              </div>
                              <lim-ui-progress-bar
                                 [progress]="
                                    (currentlyAt / end) * 100 | betterNumber: '1.0-0'
                                 "
                                 label="{{ lang().ThisMayTakeAMoment }}"
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               }

               <lim-ui-panel>
                  <div class="name-description">
                     <div class="name-description-col">
                        <span class="name-header">{{ lang().Name }}</span>

                        <input
                           class="editable-name-header"
                           type="text"
                           [(ngModel)]="task.checklistName"
                           (blur)="updateTaskName()"
                           data-log="task-titleEdit"
                        />

                        @if (
                           task.checklistTemplate !== 6 && itemsEditable() && tagTaskCred
                        ) {
                           <span class="hidePrint add-tag">
                              <lim-ui-dropdown>
                                 <lim-ui-tertiary-button button icon="plus">
                                    {{ lang().addTags }}
                                 </lim-ui-tertiary-button>
                                 <custom-tag-list menu [data]="customTagListObj()" />
                              </lim-ui-dropdown>
                           </span>
                        }
                     </div>

                     <div class="name-description-col">
                        <span class="name-header">{{ lang().Description }}</span>
                        <div
                           class="updateFieldHolder editUnderline editableDivInCheck description-input"
                           [(ngModel)]="taskVM.checklistInstructions"
                           (afterEdit)="updateTaskDescription()"
                           limbleContentEditable
                           stripHtml="true"
                           data-log="task-descriptionEdit"
                        ></div>
                        @if (task.checklistTemplate !== 6) {
                           <span class="hidePrint add-tag">
                              <lim-ui-secondary-button
                                 icon="gearRegular"
                                 (click)="settings()"
                                 placement="left"
                                 limUiTooltip="{{ lang().SettingsTooltip }}"
                              >
                                 {{ lang().Settings }}
                              </lim-ui-secondary-button>
                           </span>
                        }
                     </div>
                  </div>
               </lim-ui-panel>

               @if (data.settings) {
                  <lim-ui-panel>
                     <div class="settings-info-wrapper">
                        <span class="custom-title-with-buttons"
                           >{{ lang().Settings }}
                        </span>

                        <task-color-status-config
                           [data]="data"
                           [taskID]="data.checklistID"
                           [disableAlerts]="disableAlerts()"
                        />

                        <span [hidden]="!!limitedSettings()" class="settings-info">
                           {{ lang().EstimatedTime }}:

                           <a
                              class="cursor mobileFloatRight"
                              (click)="updateEstimatedTime()"
                           >
                              {{ checklistEstTimeHours }} {{ lang().hr }}
                              {{ lang().and }} {{ checklistEstTimeMinutes }}
                              {{ lang().min }}
                           </a>
                           <br />
                        </span>

                        <span
                           [hidden]="limitedSettings() && task.checklistBatchID == 300112"
                           class="settings-info"
                        >
                           <span class="settings-info-title">
                              {{ lang().DefaultPriority }}:
                           </span>

                           <div class="dropdown">
                              @if (checkCredAtLocation(task.locationID, 101)) {
                                 <span
                                    class="dropdown-toggle cursor form-dropdown"
                                    data-toggle="dropdown"
                                 >
                                    <lim-ui-dropdown>
                                       <div button class="form-dropdown">
                                          @if (task.priorityID !== null) {
                                             <div>
                                                <lim-ui-icon
                                                   [ngStyle]="{
                                                      color: priorityListIndex[
                                                         task.priorityID
                                                      ]
                                                         ? priorityListIndex[
                                                              task.priorityID
                                                           ].color
                                                         : 'var(--lim-el-no-priority-color)',
                                                   }"
                                                   icon="circleExclamation"
                                                   iconSize="small"
                                                />
                                                <span
                                                   [ngStyle]="{
                                                      color: priorityListIndex[
                                                         task.priorityID
                                                      ]
                                                         ? priorityListIndex[
                                                              task.priorityID
                                                           ].color
                                                         : 'var(--lim-el-no-priority-color)',
                                                   }"
                                                   class="priority-name-header"
                                                   [limbleHtml]="
                                                      taskDisplayData.priorityName
                                                   "
                                                ></span>
                                             </div>
                                          }
                                       </div>
                                       <div menu class="form-dropdown-input-menu-wrapper">
                                          @for (
                                             priority of priorityList;
                                             track priority
                                          ) {
                                             <lim-ui-dropdown-item
                                                (click)="updateTaskPriorityID(priority)"
                                                dataLogLabel="task-defaultPriorityDropdownItem"
                                             >
                                                <a>
                                                   {{ priority.priorityLevel }} -
                                                   <span
                                                      [limbleHtml]="priority.name"
                                                   ></span> </a
                                             ></lim-ui-dropdown-item>
                                          }
                                          <lim-ui-dropdown-divider />
                                          @if (superUser) {
                                             <lim-ui-dropdown-item
                                                (click)="editPriorities()"
                                                dataLogLabel="task-editPrioritiesDropdownItem"
                                             >
                                                <div class="edit-priorities-container">
                                                   <lim-ui-icon
                                                      icon="pencil"
                                                      iconSize="small"
                                                   />{{ lang().EditPriorities }}
                                                </div>
                                             </lim-ui-dropdown-item>
                                          }
                                       </div>
                                    </lim-ui-dropdown>
                                 </span>
                              }
                              @if (
                                 !checkCredAtLocation(task.locationID, 101) &&
                                 task.priorityID !== null
                              ) {
                                 <span>
                                    <lim-ui-icon
                                       [ngStyle]="{
                                          color: priorityListIndex[task.priorityID]
                                             ? priorityListIndex[task.priorityID].color
                                             : 'var(--lim-el-no-priority-color)',
                                       }"
                                       icon="circleExclamation"
                                       iconSize="small"
                                    />
                                    <span
                                       [ngStyle]="{
                                          color: priorityListIndex[task.priorityID]
                                             ? priorityListIndex[task.priorityID].color
                                             : 'var(--lim-el-no-priority-color)',
                                       }"
                                       class="priority-name-header"
                                       [limbleHtml]="taskDisplayData.priorityName"
                                    ></span>
                                 </span>
                              }
                           </div>
                        </span>

                        <span
                           [hidden]="
                              !(
                                 !limitedSettings() &&
                                 (task.checklistTemplate == 2 ||
                                    task.checklistTemplate == 4) &&
                                 task.checklistBatchID != 300112
                              )
                           "
                           class="settings-info"
                        >
                           <label> {{ lang().PlannedWO }}: </label>
                           <lim-ui-checkbox
                              (modelChange)="changeWOTemplatePlannedVsUnplanned()"
                              class="budgets-checkbox"
                              [model]="task.checklistTemplate === 4"
                           />
                        </span>

                        <span
                           [hidden]="
                              !(
                                 !limitedSettings() &&
                                 (task.checklistTemplate == 2 ||
                                    task.checklistTemplate === 4) &&
                                 task.checklistBatchID != 300112 &&
                                 task.checklistDepreciated
                              )
                           "
                           class="settings-info"
                        >
                           <a
                              class="cursor"
                              (click)="changeDefaultWO()"
                              placement="bottom"
                              limUiTooltip="{{
                                 lang().ChangeDefaultWOForLocationTooltip
                              }}"
                           >
                              {{ lang().ChangeDefaultWOForLocation }}: "{{
                                 taskDisplayData.locationName ?? ""
                              }}"
                           </a>
                        </span>
                     </div>
                  </lim-ui-panel>
               }

               <lim-ui-info-panel [hidden]="!(task.checklistBatchID != 300112)">
                  <span class="asset-belongs-to">
                     <div panelCustomTitle class="custom-title-with-buttons">
                        <span>{{ lang().BelongsTo }}</span>
                     </div>
                     <span class="asset-name-and-location">
                        @if (task.checklistTemplate !== 6) {
                           <span>
                              <lim-ui-icon icon="cube" iconSize="small" />
                              <a
                                 (click)="changeAsset()"
                                 class="cursor green-color-link"
                                 limUiTooltip="{{ lang().BelongsToTooltip }}"
                                 placement="bottom"
                              >
                                 @if (asset()?.assetName) {
                                    <span [limbleHtml]="asset()?.assetName"></span>
                                 }
                                 @if (!asset()) {
                                    <span>{{ lang().None }}</span>
                                 }
                              </a>
                           </span>
                        }

                        <span>
                           <lim-ui-icon icon="houseChimney" iconSize="small" />
                           <a
                              (click)="changeLocation()"
                              limUiTooltip="{{ lang().ChangeLocationOfTaskTooltip }}"
                              placement="bottom"
                              class="cursor default-link"
                           >
                              <span [limbleHtml]="taskDisplayData.locationName"></span>
                           </a>
                        </span>
                     </span>
                  </span>
               </lim-ui-info-panel>

               @if (task.checklistTemplate !== 6) {
                  <div class="pm-parts">
                     <lim-ui-panel>
                        <div class="custom-title-with-buttons small-panel-title-spacing">
                           <span>{{ lang().Parts }}</span>
                        </div>
                        @if (parts) {
                           @for (part of parts; track part) {
                              <div>
                                 <div class="lim-ui-list-item part-list-item-wrapper">
                                    <span class="part-list-item-details">
                                       <span class="part-list-item-details-group">
                                          <lim-ui-icon icon="gear" iconSize="small" />
                                          <span class="part-quantity-column">
                                             <input
                                                type="number"
                                                min="0"
                                                onclick="this.focus();"
                                                [(ngModel)]="part.suggestedNumber"
                                                value="{{ part.suggestedNumber }}"
                                                (blur)="updatePartSuggestedNumber(part)"
                                             />
                                             @if (
                                                unitOfMeasureService.isFeatureEnabled()
                                             ) {
                                                <span
                                                   [limUiTooltip]="
                                                      part.partID
                                                         | partUnitOfMeasure: 'singular'
                                                   "
                                                >
                                                   {{
                                                      part.partID
                                                         | partUnitOfMeasure: "short"
                                                   }}
                                                </span>
                                             }
                                          </span>

                                          @if (taskEditable()) {
                                             <lim-ui-minimal-icon-button
                                                [superMinimal]="true"
                                                class="deletion-icon lim-ui-show-on-tablet"
                                                icon="trashCanRegular"
                                                iconSize="small"
                                                iconColor="danger"
                                                (click)="removePartFromTask(part)"
                                                placement="left"
                                                limUiTooltip="{{ lang().RemoveThisPart }}"
                                             />
                                          }
                                       </span>

                                       <span class="part-list-item-details-group">
                                          <span
                                             (click)="popPart(part)"
                                             class="part-name"
                                             [limbleHtml]="part.partName"
                                          ></span>
                                          @if (
                                             part.partNumber != null &&
                                             part.partNumber.length > 0
                                          ) {
                                             <span>
                                                -
                                                <span
                                                   [limbleHtml]="part.partNumber"
                                                ></span>
                                             </span>
                                          }
                                          -
                                          <span [limbleHtml]="part.locationName"></span>
                                       </span>

                                       @if (taskEditable()) {
                                          <lim-ui-minimal-icon-button
                                             [superMinimal]="true"
                                             class="deletion-icon lim-ui-hide-on-tablet"
                                             icon="trashCanRegular"
                                             iconSize="small"
                                             iconColor="danger"
                                             (click)="removePartFromTask(part)"
                                             placement="left"
                                             limUiTooltip="{{ lang().RemoveThisPart }}"
                                          />
                                       }
                                    </span>
                                 </div>
                              </div>
                           }
                        }
                        <lim-ui-secondary-button
                           icon="plusRegular"
                           dataLogLabel="manageParts-initiateAnAddition"
                           (click)="addPart()"
                        >
                           {{ lang().AddPart }}
                        </lim-ui-secondary-button>
                     </lim-ui-panel>
                  </div>
               }
               @if (
                  task.checklistTemplate !== 6 &&
                  assetCheckInOut &&
                  featureAssetTools &&
                  tools
               ) {
                  <div class="disableOffline pm-tools">
                     <lim-ui-panel>
                        <div class="custom-title-with-buttons small-panel-title-spacing">
                           <span class="part-name">{{ lang().Tools }}</span>
                        </div>
                        @for (toolData of tools; track toolData) {
                           <task-tool
                              [mostRecentRequest]="toolData.mostRecentRequest"
                              [tool]="toolData.tool"
                              [removeToolFromTask]="removeToolFromTask"
                              [checklistID]="task.checklistID"
                              [taskInput]="task"
                           />
                        }
                        <lim-ui-secondary-button
                           icon="plusRegular"
                           data-log="manageTask-initiateATool"
                           (click)="addToolToTask()"
                        >
                           {{ lang().AddTool }}
                        </lim-ui-secondary-button>
                     </lim-ui-panel>
                  </div>
               }

               <lim-ui-panel>
                  <div class="instructions-panel">
                     <header>
                        <div class="top">
                           <h3
                              limUiTooltip="{{ lang().InstructionsTooltip }}"
                              placement="right"
                           >
                              {{ lang().Instructions }}
                           </h3>
                           @if (
                              task.checklistTemplate &&
                              taskFormState.shouldShowModeToggleButton()
                           ) {
                              <lim-ui-primary-button
                                 icon="eyeRegular"
                                 (click)="switchMode(true)"
                              >
                                 {{ lang().Preview }}
                              </lim-ui-primary-button>
                           }
                           @if (
                              task.checklistTemplate === 0 &&
                              taskFormState.shouldShowModeToggleButton()
                           ) {
                              <lim-ui-primary-button
                                 (click)="switchMode(false)"
                                 limUiTooltip="{{ lang().TestThisTaskTooltip }}"
                                 placement="bottom"
                              >
                                 {{ lang().Continue }}
                              </lim-ui-primary-button>
                           }
                        </div>
                     </header>

                     <div class="nested-panels-body">
                        @if (info && task) {
                           <edit-instructions
                              [info]="info"
                              [options]="optionsState()"
                              [taskInstructions]="items()"
                              [data]="taskInstructionData()"
                              [checklistID]="data.checklistID"
                              [taskInput]="task"
                           />
                        }
                     </div>

                     <footer
                        class="pricing-plan-container"
                        [limUiPopover]="startUnlimitedWOsPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverPlacement]="'top'"
                        [limUiPopoverHidden]="featureUnlimitedWOs"
                     >
                        <!-- We don't show the add instruction button for instruction set templates. That would allow you to add instruction set templates inside of other instruction set templates. -->
                        @if (!isInstructionSetTemplate()) {
                           <lim-ui-primary-button
                              icon="plusRegular"
                              (click)="addItem()"
                              [disabled]="!featureUnlimitedWOs"
                           >
                              {{ lang().AddInstruction }}
                           </lim-ui-primary-button>
                        }
                        @if (!featureUnlimitedWOs) {
                           <lim-ui-badge
                              class="pricing-plan-badge"
                              [label]="lang().PremiumPlus"
                              icon="stars"
                           />
                        }
                        <ng-template #startUnlimitedWOsPopover>
                           <upsell-popover
                              [message]="lang().FeatureUnlimitedWOsPopoverMessage"
                              [pricingLinkText]="lang().LearnMore"
                           />
                        </ng-template>
                     </footer>
                  </div>
               </lim-ui-panel>
            </div>
         </lim-ui-scroll-container>
      </div>
   }

   <!--  -->
   <!-- chkInstance -->
   <!--  -->

   @if (mode() === "instance") {
      <div class="task-container">
         @if (loadedItems() === true && taskDisplayData) {
            <header
               class="chk-header"
               [ngClass]="{
                  'small-header': scrollPosition > 1,
                  'condensed-when-limited-height': isLimitedHeight$ | async,
               }"
            >
               <div class="title-and-icons">
                  <div class="title-and-id modal-title">
                     @if (title) {
                        <span>{{ title }}</span>
                     }
                     @if (!title) {
                        <span>
                           @if (EditingWOTemplateInstanceBeforeLive) {
                              <span>{{ lang().EditWOTemplateTask }}</span>
                           }
                           @if (!EditingWOTemplateInstanceBeforeLive) {
                              <span> {{ lang().Task }} #{{ task.checklistID }} </span>
                           }
                        </span>
                     }
                  </div>
                  <div class="icons">
                     @if (data) {
                        <span class="simultaneous-users-container">
                           <task-simultaneous-users
                              [checklistID]="data.checklistID"
                              [isPopTask]="true"
                              (connectionAdded)="broadcastTaskOpen()"
                           />
                        </span>
                     }

                     <lim-ui-dropdown
                        class="clickable icon-padding"
                        [widthFitContent]="true"
                        data-test-id="taskShareDropdown"
                     >
                        <span button class="form-dropdown">
                           <span>
                              <lim-ui-icon
                                 icon="shareFromSquareRegular"
                                 iconSize="medium-large"
                              />
                           </span>
                        </span>
                        <span menu class="form-dropdown-input-menu-wrapper">
                           @if (showPrintButton) {
                              <ng-template #printButtonContents>
                                 <a class="cursor headerLink share-dropdown-item">
                                    <span>
                                       <lim-ui-icon
                                          icon="printRegular"
                                          iconSize="small"
                                       />
                                       {{ lang().Print }}
                                    </span>
                                    <lim-ui-icon
                                       icon="circleQuestion"
                                       limUiTooltip="{{ lang().PrintTaskTooltip }}"
                                       placement="bottom"
                                    />
                                 </a>
                              </ng-template>

                              @if (
                                 (treeSize$ &&
                                    (treeSize$ | async) <= TASK_ITEM_LIMIT_FOR_PRINT) ||
                                 items()?.length === 0
                              ) {
                                 <lim-ui-dropdown-item
                                    attr.target="task-print{{ task.checklistID }}"
                                    print-div
                                    title="{{ task.checklistName }} - #{{
                                       task.checklistID
                                    }} - {{ this.assetNameStr() }}"
                                    id="print{{ task.checklistID }}"
                                 >
                                    <ng-container
                                       *ngTemplateOutlet="printButtonContents"
                                    />
                                 </lim-ui-dropdown-item>
                              } @else {
                                 <lim-ui-dropdown-item
                                    (click)="showTaskTooLongToPrintMessage()"
                                 >
                                    <ng-container
                                       *ngTemplateOutlet="printButtonContents"
                                    />
                                 </lim-ui-dropdown-item>
                              }
                           }
                           <lim-ui-dropdown-item
                              [hidden]="
                                 !(
                                    currentUser.workOrderUser !== 1 &&
                                    taskEditable() &&
                                    task.checklistTemplate == 0
                                 )
                              "
                           >
                              <a
                                 class="cursor share-dropdown-item headerLink"
                                 (click)="emailReminder(task)"
                              >
                                 <div>
                                    <span>
                                       <lim-ui-icon
                                          icon="envelopeRegular"
                                          iconSize="small"
                                       />

                                       {{ lang().SendAReminder }}
                                    </span>
                                    <lim-ui-icon
                                       icon="circleQuestion"
                                       iconSize="small"
                                       limUiTooltip="{{ lang().SendAReminderTooltip }}"
                                       data-placement="bottom"
                                    />

                                    <div style="clear: both"></div>
                                 </div>
                              </a>
                           </lim-ui-dropdown-item>
                           <lim-ui-dropdown-item
                              [hidden]="
                                 !(
                                    currentUser.workOrderUser !== 1 &&
                                    (taskEditable() || task.checklistUserCompleted) &&
                                    task.checklistTemplate == 0
                                 )
                              "
                           >
                              <a
                                 class="cursor share-dropdown-item headerLink"
                                 (click)="shareInternalLink(task)"
                              >
                                 <div>
                                    <span>
                                       <i class="fa-solid fa-link fa-fw"></i>
                                       <lim-ui-icon icon="link" iconSize="small" />
                                       {{ lang().InternalLink }}
                                    </span>
                                    <lim-ui-icon
                                       icon="circleQuestion"
                                       iconSize="small"
                                       limUiTooltip="{{ lang().InternalLinkTooltip }}"
                                       data-placement="bottom"
                                    />

                                    <div style="clear: both"></div>
                                 </div>
                              </a>
                           </lim-ui-dropdown-item>
                           <lim-ui-dropdown-item
                              [hidden]="
                                 !(
                                    currentUser.workOrderUser !== 1 &&
                                    (taskEditable() || task.checklistUserCompleted) &&
                                    task.checklistTemplate == 0
                                 )
                              "
                           >
                              <a
                                 class="cursor share-dropdown-item headerLink"
                                 (click)="handleClickOnShareTaskExternally($event, task)"
                              >
                                 <div
                                    class="pricing-plan-container"
                                    [limUiPopover]="startExternalSharesPopover"
                                    [limUiPopoverType]="'upsell'"
                                    [limUiPopoverPlacement]="'left'"
                                    [limUiPopoverHidden]="featureShareTaskExternally"
                                 >
                                    <span class="floatLeft">
                                       <lim-ui-icon
                                          icon="addressCard"
                                          iconSize="small"
                                          [ngClass]="{
                                             paywalled: !featureShareTaskExternally,
                                          }"
                                       />
                                       {{ lang().ShareTaskExternally }}
                                    </span>
                                    <lim-ui-icon
                                       icon="circleQuestion"
                                       iconSize="small"
                                       limUiTooltip="{{ lang().ShareTaskToolTip }}"
                                       data-placement="bottom"
                                       [ngClass]="{
                                          paywalled: !featureShareTaskExternally,
                                       }"
                                    />
                                    @if (!featureShareTaskExternally) {
                                       <lim-ui-badge
                                          class="pricing-plan-badge"
                                          icon="stars"
                                       />
                                    }
                                    <ng-template #startExternalSharesPopover>
                                       <upsell-popover
                                          [message]="
                                             lang()
                                                .FeatureShareTaskExternallyPopoverMessage
                                          "
                                          [pricingLinkText]="lang().LearnMore"
                                       />
                                    </ng-template>
                                    <div style="clear: both"></div>
                                 </div>
                              </a>
                           </lim-ui-dropdown-item>
                        </span>
                     </lim-ui-dropdown>
                     @if (modalInstance !== undefined) {
                        <span class="clickable icon-padding" (click)="close()">
                           <lim-ui-icon
                              icon="xmarkLargeRegular"
                              iconSize="medium-large"
                           />
                        </span>
                     }
                  </div>
               </div>
               <div class="title-and-id-editable mobile-disappear">
                  <!-- Editable name -->
                  @if (itemsEditable() && currentUser?.workOrderUser !== 1) {
                     <div
                        limbleContentEditable
                        [allowTextFormatting]="false"
                        stripHtml="true"
                        (afterEdit)="updateTaskName()"
                        [(ngModel)]="task.checklistName"
                        class="active lim-ui-fonts-body-semi-bold"
                        data-log="task-changeTitle"
                        data-test-id="taskTitleInput"
                     ></div>
                  } @else {
                     <span class="lim-ui-fonts-body-extra-bold">
                        {{ task.checklistName }}
                     </span>
                  }

                  <!-- Non-editable name -->
               </div>
               <div class="task-dashboard">
                  <span class="task-superficial mobile-disappear">
                     @if (task?.checklistID != undefined) {
                        <task-image-field
                           class="small-image"
                           [task]="task"
                           size="task-view"
                           [canEdit]="taskEditable()"
                           data-test-id="taskHeaderImageUpload"
                        />
                     }

                     <div class="asset-level row-spacing big">
                        <!--row 1-->
                        <span>
                           <!--task type header-->
                           @if (!EditingWOTemplateInstanceBeforeLive) {
                              <span
                                 class="header-wrapper header-extra-space first-row-dashboard"
                                 (click)="changeTaskType()"
                                 limUiTooltip="{{ lang().TaskType }}"
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2 && !tempEdit,
                                 }"
                                 data-test-id="taskTypeHeader"
                              >
                                 <lim-ui-icon
                                    [icon]="taskDisplayData.taskTypeIcon ?? 'notdef'"
                                    iconSize="small"
                                 />
                                 {{ taskDisplayData.typeDisplay }}
                              </span>
                           }

                           @if (task.priorityID !== null) {
                              <span
                                 class="header-wrapper header-wrapper-dropdown header-extra-space limit-priority-length priority-alignment first-row-dashboard"
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2,
                                 }"
                                 (click)="priorityDropdownClickHandler()"
                                 limUiTooltip="{{ lang().Priority }}"
                                 data-test-id="taskPriorityHeader"
                              >
                                 @if (currentUser.workOrderUser == 1) {
                                    <span>
                                       <div class="hidden-dropdown">
                                          <lim-ui-icon
                                             [ngStyle]="{
                                                color: priorityListIndex[task.priorityID]
                                                   ? priorityListIndex[task.priorityID]
                                                        .color
                                                   : 'var(--lim-el-no-priority-color)',
                                             }"
                                             icon="circleExclamation"
                                             iconSize="small"
                                          />
                                          <span
                                             [limbleHtml]="taskDisplayData.priorityName"
                                          ></span>
                                       </div>
                                    </span>
                                 }
                                 @if (currentUser.workOrderUser !== 1) {
                                    <span>
                                       <div class="hidden-dropdown">
                                          @if (
                                             checkCredAtLocation(task.locationID, 101)
                                          ) {
                                             <span
                                                class="dropdown-toggle cursor form-dropdown"
                                                data-toggle="dropdown"
                                             >
                                                <lim-ui-dropdown
                                                   #priorityDropdown
                                                   class="lim-dropdown"
                                                >
                                                   <div button class="form-dropdown">
                                                      <div>
                                                         <lim-ui-icon
                                                            [ngStyle]="{
                                                               color: priorityListIndex[
                                                                  task.priorityID
                                                               ]
                                                                  ? priorityListIndex[
                                                                       task.priorityID
                                                                    ].color
                                                                  : 'var(--lim-el-no-priority-color)',
                                                            }"
                                                            icon="circleExclamation"
                                                            iconSize="small"
                                                         />
                                                         <span
                                                            [ngStyle]="{
                                                               color: priorityListIndex[
                                                                  task.priorityID
                                                               ]
                                                                  ? priorityListIndex[
                                                                       task.priorityID
                                                                    ].color
                                                                  : 'var(--lim-el-no-priority-color)',
                                                            }"
                                                            class="priority-name-header"
                                                            [limbleHtml]="
                                                               taskDisplayData.priorityName
                                                            "
                                                         ></span>
                                                      </div>
                                                   </div>
                                                   @if (task.statusID !== 2) {
                                                      <div
                                                         menu
                                                         class="form-dropdown-input-menu-wrapper"
                                                      >
                                                         @for (
                                                            priority of priorityList;
                                                            track priority
                                                         ) {
                                                            <lim-ui-dropdown-item
                                                               (click)="
                                                                  updateTaskPriorityID(
                                                                     priority
                                                                  )
                                                               "
                                                               dataLogLabel="task-priorityDropdownItem"
                                                            >
                                                               <span
                                                                  class="cursor"
                                                                  [ngStyle]="{
                                                                     color: priorityListIndex[
                                                                        priority
                                                                           .priorityID
                                                                     ]
                                                                        ? priorityListIndex[
                                                                             priority
                                                                                .priorityID
                                                                          ].color
                                                                        : 'var(--lim-el-no-priority-color)',
                                                                  }"
                                                               >
                                                                  <span
                                                                     [limbleHtml]="
                                                                        priority.priorityName
                                                                     "
                                                                  ></span>
                                                               </span>
                                                            </lim-ui-dropdown-item>
                                                         }
                                                         @if (superUser) {
                                                            <lim-ui-dropdown-item
                                                               (click)="editPriorities()"
                                                               class="cursor disableOffline"
                                                            >
                                                               <div
                                                                  class="edit-priorities-container"
                                                               >
                                                                  <lim-ui-icon
                                                                     icon="pencil"
                                                                     iconSize="small"
                                                                  />{{
                                                                     lang().EditPriorities
                                                                  }}
                                                               </div>
                                                            </lim-ui-dropdown-item>
                                                         }
                                                      </div>
                                                   }
                                                </lim-ui-dropdown>
                                             </span>
                                          }
                                          @if (
                                             !checkCredAtLocation(task.locationID, 101)
                                          ) {
                                             <span>
                                                <lim-ui-icon
                                                   [ngStyle]="{
                                                      color: priorityListIndex[
                                                         task.priorityID
                                                      ]
                                                         ? priorityListIndex[
                                                              task.priorityID
                                                           ].color
                                                         : 'var(--lim-el-no-priority-color)',
                                                   }"
                                                   icon="circleExclamation"
                                                   iconSize="small"
                                                />
                                             </span>
                                          }
                                       </div>
                                    </span>
                                 }
                              </span>
                           }

                           @if (
                              task.checklistTemplate == 0 &&
                              currentUser.workOrderUser == 1
                           ) {
                              <span
                                 class="header-wrapper header-extra-space first-row-dashboard expand-mobile"
                                 limUiTooltip="{{ lang().theDueDateOfThisTask }}"
                                 data-test-id="taskDueDateHeader"
                              >
                                 @if (
                                    task.checklistStatusID == 0 &&
                                    task.checklistUserCompleted == 0
                                 ) {
                                    <div (click)="changeCompletedTaskDueDate()">
                                       <lim-ui-icon
                                          icon="calendarDays"
                                          iconSize="small"
                                          class="text-and-icon-spacing"
                                       />
                                       <span>{{ taskDisplayData.dueDateDisplay }}</span>
                                    </div>
                                 }
                              </span>
                           }
                           @if (
                              currentUser.workOrderUser !== 1 &&
                              !EditingWOTemplateInstanceBeforeLive &&
                              !data.preview &&
                              !limited()
                           ) {
                              <span
                                 class="header-wrapper header-extra-space first-row-dashboard expand-mobile"
                                 (click)="changeDueDate()"
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2 && !tempEdit,
                                 }"
                                 limUiTooltip="{{ lang().theDueDateOfThisTask }}"
                              >
                                 <span class="due-date-container">
                                    <lim-ui-icon
                                       icon="calendarDays"
                                       iconSize="small"
                                       class="text-and-icon-spacing"
                                    />

                                    <span class="due-date-label">{{
                                       taskDisplayData.dueDateDisplay
                                    }}</span>

                                    @if (
                                       task.checklistStatusID == 0 &&
                                       task.checklistUserCompleted == 0
                                    ) {
                                       <span
                                          class="badge due-date-badge"
                                          [ngClass]="{
                                             'badge-danger':
                                                task.checklistColor === 3 ||
                                                task.checklistColor === 0,
                                             'badge-orange': task.checklistColor === 2,
                                             'badge-success': task.checklistColor === 1,
                                          }"
                                          placement="top"
                                          limUiTooltip="{{
                                             taskDisplayData.days +
                                                ' ' +
                                                taskDisplayData.daysMsg
                                          }}"
                                       >
                                          {{ taskDisplayData.exactDays }}
                                       </span>
                                    }
                                 </span>
                              </span>
                           }
                        </span>
                        <!--row 2-->
                        <span class="test-row">
                           <span class="expand-mobile">
                              <asset-header-item
                                 [asset]="asset()"
                                 [disableChangeAsset]="!taskEditable() && !tempEdit"
                                 (viewAssetRequest)="popAsset(task)"
                                 [ngClass]="{
                                    unaccessible: !taskEditable() && !tempEdit,
                                 }"
                                 (changeAssetRequest)="changeAsset()"
                                 data-test-id="taskAssetHeader"
                              />
                           </span>
                           <!--location header-->
                           <span class="expand-mobile">
                              <div
                                 class="header-wrapper header-wrapper-dropdown header-extra-space expand-mobile"
                                 limUiTooltip="{{ lang().Location }}"
                                 [ngClass]="{
                                    unaccessible: !taskEditable() && !tempEdit,
                                 }"
                              >
                                 <lim-ui-dropdown
                                    [disabled]="
                                       (!taskEditable() && !tempEdit) ||
                                       task.locationID === 0
                                    "
                                 >
                                    >
                                    <div button class="form-dropdown">
                                       <span>
                                          <lim-ui-icon
                                             icon="houseChimney"
                                             iconSize="small"
                                          />

                                          {{
                                             taskVM?.taskDisplayData?.locationName ?? ""
                                          }}
                                       </span>
                                    </div>
                                    <div menu class="form-dropdown-input-menu-wrapper">
                                       <lim-ui-dropdown-text-item
                                          [outputString]="lang().ChangeLocation"
                                          [displayString]="lang().ChangeLocation"
                                          (click)="changeLocation()"
                                       />
                                    </div>
                                 </lim-ui-dropdown>
                              </div>
                           </span>
                        </span>
                        <!--row 3-->
                        <span>
                           <span class="expand-mobile">
                              <span
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2 && !tempEdit,
                                 }"
                                 [hidden]="!(currentUser.workOrderUser == 1)"
                                 limUiTooltip="{{ lang().AssignedTo }}"
                                 data-test-id="taskAssignmentHeader"
                              >
                                 <div
                                    class="header-wrapper header-extra-space expand-mobile"
                                 >
                                    <lim-ui-icon
                                       icon="user"
                                       iconSize="small"
                                       class="text-and-icon-spacing"
                                    />
                                    <span>{{ taskDisplayData.displayName }}</span>
                                 </div>
                              </span>
                              <span
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2 && !tempEdit,
                                 }"
                                 [hidden]="
                                    !(
                                       currentUser.workOrderUser !== 1 &&
                                       !EditingWOTemplateInstanceBeforeLive
                                    )
                                 "
                                 limUiTooltip="{{ lang().AssignedTo }}"
                              >
                                 <div
                                    (click)="changeAssignment()"
                                    class="header-wrapper header-extra-space expand-mobile"
                                 >
                                    <lim-ui-icon
                                       icon="user"
                                       iconSize="small"
                                       class="text-and-icon-spacing"
                                    />
                                    <span>{{ taskDisplayData.displayName }}</span>
                                 </div>
                              </span>
                           </span>
                           @if (
                              featureMaps != 0 &&
                              (geoLocation ||
                                 (!geoLocation && (tempEdit || taskEditable())))
                           ) {
                              <span
                                 class="header-wrapper header-extra-space expand-mobile"
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2,
                                 }"
                                 data-test-id="taskMapHeader"
                              >
                                 <lim-ui-icon
                                    icon="map"
                                    iconSize="small"
                                    class="text-and-icon-spacing"
                                 />
                                 <span class="cursor" (click)="openViewMap()">
                                    <span>{{
                                       geoLocation
                                          ? lang().ViewMapLocation
                                          : lang().SetMapLocation
                                    }}</span>
                                 </span>
                                 @if (geoLocation) {
                                    <span
                                       (click)="navigateToLocation(geoLocation)"
                                       class="cursor"
                                       limUiTooltip="{{ lang().NavigateToThisLocation }}"
                                       ><lim-ui-icon
                                          icon="locationArrow"
                                          iconSize="small"
                                    /></span>
                                 }
                              </span>
                           }
                           @if (
                              featureDowntimeTracking &&
                              task.assetID &&
                              task.checklistTemplateOld == 2
                           ) {
                              <span
                                 class="header-wrapper header-extra-space expand-mobile cursor"
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2 && !tempEdit,
                                 }"
                                 (click)="tempChangeDowntime()"
                                 limUiTooltip="{{ lang().TrackDowntimeTooltip }}"
                              >
                                 <span>
                                    {{ lang().Downtime }}: {{ checklistDowntimeHours }}
                                    @if (task.checklistDowntime == 0) {
                                       <span>0</span>
                                    }
                                    :{{ checklistDowntimeMinutes }}
                                    @if (task.checklistDowntime == 0) {
                                       <span>00</span>
                                    }
                                    {{ lang().hr }}
                                 </span>
                              </span>
                           }
                        </span>
                     </div>

                     <span class="asset-level row-spacing small">
                        <span>
                           <span
                              class="header-wrapper header-extra-space first-row-dashboard"
                              [ngClass]="{ 'remove-background': task.statusID === 2 }"
                              limUiTooltip="{{ lang().TaskType }}"
                           >
                              <lim-ui-icon
                                 [icon]="taskDisplayData.taskTypeIcon ?? 'notdef'"
                                 iconSize="small"
                              />
                              @if (task.statusID !== 2) {
                                 <span> {{ taskDisplayData.typeDisplay }}</span>
                              }
                           </span>

                           @if (
                              task.checklistStatusID == 0 &&
                              task.checklistUserCompleted == 0 &&
                              task.priorityID !== null
                           ) {
                              <span
                                 class="header-wrapper header-extra-space priority-alignment first-row-dashboard"
                                 limUiTooltip="{{ lang().Priority }}"
                              >
                                 <span [hidden]="!(currentUser.workOrderUser == 1)">
                                    <div
                                       style="display: inline-block"
                                       class="marginTop5InTask"
                                    >
                                       <lim-ui-icon
                                          [ngStyle]="{
                                             color: priorityListIndex[task.priorityID]
                                                ? priorityListIndex[task.priorityID].color
                                                : 'var(--lim-el-no-priority-color)',
                                          }"
                                          icon="circleExclamation"
                                          iconSize="small"
                                       />
                                       <span
                                          [limbleHtml]="taskDisplayData.priorityName"
                                       ></span>
                                    </div>
                                 </span>
                                 <span [hidden]="!(currentUser.workOrderUser !== 1)">
                                    <div
                                       class="marginTop5InTask dropdown hidden-dropdown"
                                    >
                                       @if (checkCredAtLocation(task.locationID, 101)) {
                                          <span
                                             class="dropdown-toggle cursor form-dropdown"
                                             data-toggle="dropdown"
                                          >
                                             <lim-ui-dropdown
                                                [disabled]="!taskEditable()"
                                             >
                                                <div button class="form-dropdown">
                                                   <lim-ui-icon
                                                      [ngStyle]="{
                                                         color: priorityListIndex[
                                                            task.priorityID
                                                         ]
                                                            ? priorityListIndex[
                                                                 task.priorityID
                                                              ].color
                                                            : 'var(--lim-el-no-priority-color)',
                                                      }"
                                                      icon="circleExclamation"
                                                      iconSize="small"
                                                   />
                                                   <span
                                                      [ngStyle]="{
                                                         color: priorityListIndex[
                                                            task.priorityID
                                                         ]
                                                            ? priorityListIndex[
                                                                 task.priorityID
                                                              ].color
                                                            : 'var(--lim-el-no-priority-color)',
                                                      }"
                                                      class="priority-name-header"
                                                      [limbleHtml]="
                                                         taskDisplayData.priorityName
                                                      "
                                                   ></span>
                                                </div>
                                                @if (task.statusID !== 2) {
                                                   <div
                                                      menu
                                                      class="form-dropdown-input-menu-wrapper"
                                                   >
                                                      @for (
                                                         priority of priorityList;
                                                         track priority
                                                      ) {
                                                         <lim-ui-dropdown-item
                                                            (click)="
                                                               updateTaskPriorityID(
                                                                  priority
                                                               )
                                                            "
                                                         >
                                                            <span
                                                               class="cursor"
                                                               [ngStyle]="{
                                                                  color: priorityListIndex[
                                                                     priority.priorityID
                                                                  ]
                                                                     ? priorityListIndex[
                                                                          priority
                                                                             .priorityID
                                                                       ].color
                                                                     : 'var(--lim-el-no-priority-color)',
                                                               }"
                                                            >
                                                               <!--{{ priority.priorityLevel }} - -->

                                                               <span
                                                                  [limbleHtml]="
                                                                     priority.priorityName
                                                                  "
                                                               ></span> </span
                                                         ></lim-ui-dropdown-item>
                                                      }
                                                      @if (superUser) {
                                                         <lim-ui-dropdown-item
                                                            (click)="editPriorities()"
                                                            class="cursor disableOffline"
                                                         >
                                                            <div
                                                               class="edit-priorities-container"
                                                            >
                                                               <lim-ui-icon
                                                                  icon="pencil"
                                                                  iconSize="small"
                                                               />{{
                                                                  lang().EditPriorities
                                                               }}
                                                            </div>
                                                         </lim-ui-dropdown-item>
                                                      }
                                                   </div>
                                                }
                                             </lim-ui-dropdown>
                                          </span>
                                       }
                                       @if (!checkCredAtLocation(task.locationID, 101)) {
                                          <span>
                                             <lim-ui-icon
                                                [ngStyle]="{
                                                   color: priorityListIndex[
                                                      task.priorityID
                                                   ]
                                                      ? priorityListIndex[task.priorityID]
                                                           .color
                                                      : 'var(--lim-el-no-priority-color)',
                                                }"
                                                icon="circleExclamation"
                                                iconSize="small"
                                             />
                                          </span>
                                       }
                                    </div>
                                 </span>
                              </span>
                           }
                           @if (
                              task.checklistStatusID == 0 &&
                              task.checklistUserCompleted == 0 &&
                              currentUser.workOrderUser !== 1 &&
                              !EditingWOTemplateInstanceBeforeLive &&
                              !data.preview
                           ) {
                              <span
                                 class="header-wrapper header-extra-space first-row-dashboard"
                                 (click)="changeDueDate()"
                                 limUiTooltip="{{ lang().theDueDateOfThisTask }}"
                              >
                                 <span class="due-date-container">
                                    <lim-ui-icon
                                       icon="calendarDays"
                                       iconSize="small"
                                       class="text-and-icon-spacing"
                                    />

                                    <span class="due-date-label">{{
                                       taskDisplayData.dueDateDisplay
                                    }}</span>

                                    @if (
                                       task.checklistStatusID == 0 &&
                                       task.checklistUserCompleted == 0
                                    ) {
                                       <span
                                          class="badge due-date-badge"
                                          [ngClass]="{
                                             'badge-danger':
                                                task.checklistColor === 3 ||
                                                task.checklistColor === 0,
                                             'badge-orange': task.checklistColor == 2,
                                             'badge-success': task.checklistColor == 1,
                                          }"
                                          placement="top"
                                          limUiTooltip="{{
                                             taskDisplayData.days +
                                                ' ' +
                                                taskDisplayData.daysMsg
                                          }}"
                                       >
                                          {{ taskDisplayData.exactDays }}
                                       </span>
                                    }
                                 </span>
                              </span>
                           }
                           <!--move asset up a row if it's a completed task-->
                           @if (task.statusID === 2) {
                              <span class="expand-mobile">
                                 <asset-header-item
                                    [asset]="asset()"
                                    [disableChangeAsset]="!taskEditable() && !tempEdit"
                                    (viewAssetRequest)="popAsset(task)"
                                    (changeAssetRequest)="changeAsset()"
                                 />
                              </span>
                           }
                        </span>

                        <!--row 2-->
                        <span>
                           <!--asset(hidden on this row if it's a completed task)-->
                           @if (task.statusID !== 2) {
                              <span class="expand-mobile">
                                 <asset-header-item
                                    [asset]="asset()"
                                    (viewAssetRequest)="popAsset(task)"
                                    (changeAssetRequest)="changeAsset()"
                                 />
                              </span>
                           }
                           <!--location-->
                           <span>
                              <div
                                 class="header-wrapper header-extra-space expand-mobile"
                                 [ngClass]="{
                                    'remove-background': task.statusID === 2,
                                    'unaccessible':
                                       (!taskEditable() && !tempEdit) ||
                                       task.locationID === 0,
                                 }"
                                 limUiTooltip="{{ lang().Location }}"
                              >
                                 <lim-ui-dropdown>
                                    <div button class="form-dropdown">
                                       <span>
                                          <lim-ui-icon
                                             icon="houseChimney"
                                             iconSize="small"
                                          />

                                          {{ taskDisplayData.locationName }}
                                       </span>
                                    </div>
                                    <div menu class="form-dropdown-input-menu-wrapper">
                                       <lim-ui-dropdown-item (click)="changeLocation()">
                                          <a class="cursor headerLink">
                                             {{ lang().ChangeLocation }}
                                          </a>
                                       </lim-ui-dropdown-item>
                                    </div>
                                 </lim-ui-dropdown>
                              </div>
                           </span>
                        </span>
                     </span>
                  </span>

                  @if (middleStatusDisplay) {
                     <div class="status-column mobile-disappear">
                        <span
                           class="tile-uploader-extra-small asset-level"
                           (click)="updateTaskStatusID(open)"
                           [ngClass]="{
                              selected: task.statusID == 0,
                              unaccessible: task.checklistUserCompleted,
                           }"
                           data-log="task-changeTaskStatusOpen"
                           data-test-id="taskStatusSelectorOpen"
                        >
                           <lim-ui-icon
                              class="img-holder-extra-small"
                              icon="lockOpen"
                              [iconSize]="isMobile ? 'small' : 'medium'"
                           />
                           <span>{{ open?.name }}</span>
                        </span>

                        <lim-ui-dropdown [disabled]="!taskEditable()">
                           <span
                              button
                              class="tile-uploader-extra-small asset-level"
                              [ngClass]="{
                                 selected: task.statusID !== 0 && task.statusID !== 2,
                                 unaccessible: task.checklistUserCompleted,
                              }"
                              (click)="updateTaskStatusID(middleStatusDisplay)"
                              data-test-id="taskStatusSelectorMiddle"
                           >
                              <div class="status-icon-wrapper">
                                 <lim-ui-icon
                                    class="img-holder-extra-small"
                                    icon="barsProgress"
                                    [iconSize]="isMobile ? 'small' : 'medium'"
                                 />
                                 <lim-ui-icon icon="angleDown" iconSize="small" />
                              </div>
                              @if (middleStatusDisplay.name) {
                                 <div class="form-dropdown">
                                    <div>
                                       @if (middleStatusDisplay.name.length < 20) {
                                          <span class="middle-status-name"
                                             >{{ middleStatusDisplay.name }}
                                          </span>
                                       }
                                       @if (middleStatusDisplay.name.length >= 20) {
                                          <span
                                             class="middle-status-name"
                                             limUiTooltip="{{ middleStatusDisplay.name }}"
                                             >{{
                                                middleStatusDisplay.statusShorten
                                             }}</span
                                          >
                                       }
                                    </div>
                                 </div>
                              }
                           </span>

                           <div menu class="form-dropdown-input-menu-wrapper">
                              @for (
                                 status of middleStatus | orderBy: "sortOrder";
                                 track status
                              ) {
                                 <lim-ui-dropdown-text-item
                                    [displayString]="status.name"
                                    [outputString]="status.name"
                                    (click)="updateTaskStatusID(status)"
                                    dataLogLabel="task-statusDropdownItem"
                                    [closeDropdownOnClick]="false"
                                 />
                              }
                              @if (superUser) {
                                 <lim-ui-dropdown-divider />
                              }
                              @if (superUser) {
                                 <lim-ui-dropdown-text-item
                                    [displayString]="lang().EditStatuses"
                                    (click)="editStatuses()"
                                 />
                              }
                           </div>
                        </lim-ui-dropdown>

                        <span
                           class="tile-uploader-extra-small asset-level"
                           (click)="complete()"
                           [ngClass]="{ selected: task.statusID === 2 }"
                           data-log="task-changeTaskStatusComplete"
                        >
                           <lim-ui-icon
                              class="img-holder-extra-small"
                              icon="circleCheck"
                              [iconSize]="isMobile ? 'small' : 'medium'"
                           />
                           <span>{{ completed?.name }}</span>
                        </span>
                     </div>
                  }
               </div>
            </header>

            <lim-ui-scroll-container (scroll)="setScrollPosition($event)">
               <!--because of the way we decided to do scrolling, when you are on the header, you cannot scroll.
         this is a problem on mobile because the header takes up about 60% of the screen when you first
      open the task.  So this section is the main header again, but will only appear when you are on mobile.-->
               <div class="title-and-id-editable mobile-only">
                  @if (itemsEditable() && currentUser?.workOrderUser !== 1) {
                     <div
                        limbleContentEditable
                        [allowTextFormatting]="false"
                        stripHtml="true"
                        (afterEdit)="updateTaskName()"
                        [(ngModel)]="task.checklistName"
                        class="active lim-ui-fonts-body-semi-bold"
                        data-log="task-changeTitle"
                     ></div>
                  }

                  <!-- Non-editable name -->
                  @if (!itemsEditable() || currentUser?.workOrderUser === 1) {
                     <span class="lim-ui-fonts-body-extra-bold">
                        {{ task.checklistName }}
                     </span>
                  }
               </div>
               <div class="task-dashboard mobile-only">
                  <span class="task-superficial">
                     @if (task?.checklistID != undefined) {
                        <span class="mobile-row">
                           <task-image-field
                              class="small-image"
                              [task]="task"
                              size="task-view"
                              [canEdit]="taskEditable()"
                           />
                           <span class="mobile-column">
                              <span>
                                 <span
                                    class="header-wrapper header-extra-space first-row-dashboard"
                                    (click)="changeTaskType()"
                                    [ngClass]="{
                                       unaccessible: task.statusID === 2 && !tempEdit,
                                    }"
                                 >
                                    <lim-ui-icon
                                       [icon]="taskDisplayData.taskTypeIcon ?? 'wpforms'"
                                       iconSize="small"
                                    />
                                    {{ taskDisplayData.typeDisplay }}
                                 </span>
                              </span>

                              @if (task.priorityID !== null) {
                                 <span
                                    class="header-wrapper header-wrapper-dropdown header-extra-space limit-priority-length priority-alignment first-row-dashboard"
                                    [ngClass]="{
                                       unaccessible: task.statusID === 2,
                                    }"
                                 >
                                    @if (currentUser.workOrderUser == 1) {
                                       <span>
                                          <div class="hidden-dropdown">
                                             <lim-ui-icon
                                                [ngStyle]="{
                                                   color: priorityListIndex[
                                                      task.priorityID
                                                   ]
                                                      ? priorityListIndex[task.priorityID]
                                                           .color
                                                      : 'var(--lim-el-no-priority-color)',
                                                }"
                                                icon="circleExclamation"
                                                iconSize="small"
                                             />
                                             <span
                                                [limbleHtml]="
                                                   taskDisplayData.priorityName
                                                "
                                             ></span>
                                          </div>
                                       </span>
                                    }
                                    @if (currentUser.workOrderUser !== 1) {
                                       <span>
                                          <div class="hidden-dropdown">
                                             @if (
                                                checkCredAtLocation(task.locationID, 101)
                                             ) {
                                                <span
                                                   class="dropdown-toggle cursor form-dropdown"
                                                   data-toggle="dropdown"
                                                >
                                                   <lim-ui-dropdown>
                                                      <div button class="form-dropdown">
                                                         <div>
                                                            <lim-ui-icon
                                                               [ngStyle]="{
                                                                  color: priorityListIndex[
                                                                     task.priorityID
                                                                  ]
                                                                     ? priorityListIndex[
                                                                          task.priorityID
                                                                       ].color
                                                                     : 'var(--lim-el-no-priority-color)',
                                                               }"
                                                               icon="circleExclamation"
                                                               iconSize="small"
                                                            />
                                                            <span
                                                               [ngStyle]="{
                                                                  color: priorityListIndex[
                                                                     task.priorityID
                                                                  ]
                                                                     ? priorityListIndex[
                                                                          task.priorityID
                                                                       ].color
                                                                     : 'var(--lim-el-no-priority-color)',
                                                               }"
                                                               class="priority-name-header"
                                                               [limbleHtml]="
                                                                  taskDisplayData.priorityName
                                                               "
                                                            ></span>
                                                         </div>
                                                      </div>
                                                      @if (task.statusID !== 2) {
                                                         <div
                                                            menu
                                                            class="form-dropdown-input-menu-wrapper"
                                                         >
                                                            @for (
                                                               priority of priorityList;
                                                               track priority
                                                            ) {
                                                               <lim-ui-dropdown-item
                                                                  (click)="
                                                                     updateTaskPriorityID(
                                                                        priority
                                                                     )
                                                                  "
                                                                  dataLogLabel="task-defaultPriorityDropdownItem"
                                                               >
                                                                  <span
                                                                     class="cursor"
                                                                     [ngStyle]="{
                                                                        color: priorityListIndex[
                                                                           priority
                                                                              .priorityID
                                                                        ]
                                                                           ? priorityListIndex[
                                                                                priority
                                                                                   .priorityID
                                                                             ].color
                                                                           : 'var(--lim-el-no-priority-color)',
                                                                     }"
                                                                  >
                                                                     <!--{{ priority.priorityLevel }} - -->

                                                                     <span
                                                                        [limbleHtml]="
                                                                           priority.priorityName
                                                                        "
                                                                     ></span> </span
                                                               ></lim-ui-dropdown-item>
                                                            }
                                                            @if (superUser) {
                                                               <lim-ui-dropdown-item
                                                                  (click)="
                                                                     editPriorities()
                                                                  "
                                                                  class="cursor disableOffline"
                                                               >
                                                                  <div
                                                                     class="edit-priorities-container"
                                                                  >
                                                                     <lim-ui-icon
                                                                        icon="pencil"
                                                                        iconSize="small"
                                                                     />
                                                                     {{
                                                                        lang()
                                                                           .EditPriorities
                                                                     }}
                                                                  </div>
                                                               </lim-ui-dropdown-item>
                                                            }
                                                         </div>
                                                      }
                                                   </lim-ui-dropdown>
                                                </span>
                                             }
                                             @if (
                                                !checkCredAtLocation(task.locationID, 101)
                                             ) {
                                                <span>
                                                   <lim-ui-icon
                                                      [ngStyle]="{
                                                         color: priorityListIndex[
                                                            task.priorityID
                                                         ]
                                                            ? priorityListIndex[
                                                                 task.priorityID
                                                              ].color
                                                            : 'var(--lim-el-no-priority-color)',
                                                      }"
                                                      icon="circleExclamation"
                                                      iconSize="small"
                                                   />
                                                </span>
                                             }
                                          </div>
                                       </span>
                                    }
                                 </span>
                              }
                           </span>
                        </span>
                     }

                     <div class="asset-level row-spacing big">
                        <!--row 1-->
                        <span>
                           @if (
                              task.checklistTemplate == 0 &&
                              currentUser.workOrderUser == 1
                           ) {
                              <span
                                 class="header-wrapper header-extra-space first-row-dashboard expand-mobile"
                              >
                                 @if (
                                    task.checklistStatusID == 0 &&
                                    task.checklistUserCompleted == 0
                                 ) {
                                    <div (click)="changeCompletedTaskDueDate()">
                                       <lim-ui-icon
                                          icon="calendarDays"
                                          limUiTooltip="{{ lang().theDueDateOfThisTask }}"
                                          iconSize="small"
                                          class="text-and-icon-spacing"
                                       />
                                       <span>{{ taskDisplayData.dueDateDisplay }}</span>
                                    </div>
                                 }
                              </span>
                           }
                           @if (
                              currentUser.workOrderUser !== 1 &&
                              !EditingWOTemplateInstanceBeforeLive &&
                              !data.preview
                           ) {
                              <span
                                 class="header-wrapper header-extra-space first-row-dashboard expand-mobile"
                                 (click)="changeDueDate()"
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2 && !tempEdit,
                                 }"
                              >
                                 <span class="due-date-container">
                                    <lim-ui-icon
                                       icon="calendarDays"
                                       limUiTooltip="{{ lang().theDueDateOfThisTask }}"
                                       iconSize="small"
                                       class="text-and-icon-spacing"
                                    />

                                    <span class="due-date-label">{{
                                       taskDisplayData.dueDateDisplay
                                    }}</span>

                                    @if (
                                       task.checklistStatusID == 0 &&
                                       task.checklistUserCompleted == 0
                                    ) {
                                       <span
                                          class="badge due-date-badge"
                                          [ngClass]="{
                                             'badge-danger':
                                                task.checklistColor === 3 ||
                                                task.checklistColor === 0,
                                             'badge-orange': task.checklistColor === 2,
                                             'badge-success': task.checklistColor === 1,
                                          }"
                                          placement="top"
                                          limUiTooltip="{{
                                             taskDisplayData.days +
                                                ' ' +
                                                taskDisplayData.daysMsg
                                          }}"
                                       >
                                          {{ taskDisplayData.exactDays }}
                                       </span>
                                    }
                                 </span>
                              </span>
                           }
                        </span>
                        <!--row 2-->
                        <span class="test-row">
                           <span class="expand-mobile">
                              <asset-header-item
                                 [asset]="asset()"
                                 [disableChangeAsset]="!taskEditable() && !tempEdit"
                                 (viewAssetRequest)="popAsset(task)"
                                 (changeAssetRequest)="changeAsset()"
                              />
                           </span>
                           <!--location header-->
                           <span
                              class="expand-mobile"
                              [ngClass]="{
                                 unaccessible:
                                    (task.statusID === 2 && !tempEdit) ||
                                    task.locationID === 0,
                              }"
                           >
                              <div
                                 class="header-wrapper header-wrapper-dropdown header-extra-space expand-mobile"
                              >
                                 <lim-ui-dropdown>
                                    <div button class="form-dropdown">
                                       <span>
                                          <lim-ui-icon
                                             icon="houseChimney"
                                             iconSize="small"
                                          />

                                          {{ taskDisplayData.locationName }}
                                       </span>
                                    </div>
                                    <div menu class="form-dropdown-input-menu-wrapper">
                                       <lim-ui-dropdown-text-item
                                          [outputString]="lang().ChangeLocation"
                                          [displayString]="lang().ChangeLocation"
                                          (click)="changeLocation()"
                                       />
                                    </div>
                                 </lim-ui-dropdown>
                              </div>
                           </span>
                        </span>
                        <!--row 3-->
                        <span>
                           <span class="expand-mobile">
                              <span [hidden]="!(currentUser.workOrderUser == 1)">
                                 <div
                                    class="header-wrapper header-extra-space expand-mobile"
                                    [ngClass]="{
                                       unaccessible: task.statusID === 2 && !tempEdit,
                                    }"
                                 >
                                    <lim-ui-icon
                                       icon="user"
                                       iconSize="small"
                                       class="text-and-icon-spacing"
                                    />
                                    <span>{{ taskDisplayData.displayName }}</span>
                                 </div>
                              </span>
                              <span
                                 [hidden]="
                                    !(
                                       currentUser.workOrderUser !== 1 &&
                                       !EditingWOTemplateInstanceBeforeLive
                                    )
                                 "
                              >
                                 <div
                                    (click)="changeAssignment()"
                                    class="header-wrapper header-extra-space expand-mobile"
                                    [ngClass]="{
                                       unaccessible: task.statusID === 2 && !tempEdit,
                                    }"
                                 >
                                    <lim-ui-icon
                                       icon="user"
                                       iconSize="small"
                                       class="text-and-icon-spacing"
                                    />
                                    <span>{{ taskDisplayData.displayName }}</span>
                                 </div>
                              </span>
                           </span>
                           @if (
                              featureMaps != 0 &&
                              (geoLocation ||
                                 (!geoLocation && (tempEdit || taskEditable())))
                           ) {
                              <span
                                 class="header-wrapper header-extra-space expand-mobile"
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2,
                                 }"
                              >
                                 <lim-ui-icon
                                    icon="map"
                                    iconSize="small"
                                    class="text-and-icon-spacing"
                                 />
                                 <span class="cursor" (click)="openViewMap()">
                                    <span>{{
                                       geoLocation
                                          ? lang().ViewMapLocation
                                          : lang().SetMapLocation
                                    }}</span>
                                 </span>
                                 @if (geoLocation) {
                                    <span
                                       (click)="navigateToLocation(geoLocation)"
                                       class="cursor"
                                       limUiTooltip="{{ lang().NavigateToThisLocation }}"
                                       ><lim-ui-icon
                                          icon="locationArrow"
                                          iconSize="small"
                                    /></span>
                                 }
                              </span>
                           }
                           @if (
                              featureDowntimeTracking &&
                              task.assetID &&
                              task.checklistTemplateOld == 2
                           ) {
                              <span
                                 class="header-wrapper header-extra-space expand-mobile cursor"
                                 [ngClass]="{
                                    unaccessible: task.statusID === 2 && !tempEdit,
                                 }"
                                 (click)="tempChangeDowntime()"
                                 limUiTooltip="{{ lang().TrackDowntimeTooltip }}"
                              >
                                 <span>
                                    {{ lang().Downtime }}: {{ checklistDowntimeHours }}
                                    @if (task.checklistDowntime == 0) {
                                       <span>0</span>
                                    }
                                    :{{ checklistDowntimeMinutes }}
                                    @if (task.checklistDowntime == 0) {
                                       <span>00</span>
                                    }
                                    {{ lang().hr }}
                                 </span>
                              </span>
                           }
                        </span>
                     </div>
                  </span>
                  @if (middleStatusDisplay) {
                     <div class="status-column">
                        <span
                           class="tile-uploader-extra-small asset-level"
                           (click)="updateTaskStatusID(open)"
                           [ngClass]="{
                              selected: task.statusID == 0,
                              unaccessible: task.checklistUserCompleted,
                           }"
                        >
                           <lim-ui-icon
                              class="img-holder-extra-small"
                              icon="lockOpen"
                              [iconSize]="isMobile ? 'small' : 'medium'"
                           />
                           <span>{{ open?.name }}</span>
                        </span>
                        <span
                           class="tile-uploader-extra-small asset-level"
                           [ngClass]="{
                              selected: task.statusID !== 0 && task.statusID !== 2,
                              unaccessible: task.checklistUserCompleted,
                           }"
                           (click)="updateTaskStatusID(middleStatusDisplay)"
                        >
                           <div class="status-icon-wrapper">
                              <lim-ui-icon
                                 class="img-holder-extra-small"
                                 icon="barsProgress"
                                 [iconSize]="isMobile ? 'small' : 'medium'"
                              />
                           </div>

                           <lim-ui-dropdown
                              (click)="$event.stopPropagation()"
                              [disabled]="!taskEditable()"
                           >
                              @if (middleStatusDisplay.name) {
                                 <div button class="form-dropdown">
                                    <div>
                                       @if (middleStatusDisplay.name.length < 20) {
                                          <span class="middle-status-name"
                                             >{{ middleStatusDisplay.name }}
                                          </span>
                                       }
                                       @if (middleStatusDisplay.name.length >= 20) {
                                          <span
                                             class="middle-status-name"
                                             limUiTooltip="{{ middleStatusDisplay.name }}"
                                             >{{
                                                middleStatusDisplay.statusShorten
                                             }}</span
                                          >
                                       }
                                    </div>
                                 </div>
                              }
                              <div menu class="form-dropdown-input-menu-wrapper">
                                 @for (
                                    status of middleStatus | orderBy: "sortOrder";
                                    track status
                                 ) {
                                    <lim-ui-dropdown-text-item
                                       [displayString]="status.name"
                                       [outputString]="status.name"
                                       (click)="updateTaskStatusID(status)"
                                       dataLogLabel="task-statusDropdownItem"
                                    />
                                 }
                                 <lim-ui-dropdown-divider />
                                 <lim-ui-dropdown-text-item
                                    [displayString]="lang().EditStatuses"
                                    (click)="editStatuses()"
                                 />
                              </div>
                           </lim-ui-dropdown>

                           <lim-ui-icon icon="angleDown" iconSize="small" />
                        </span>
                        <span
                           class="tile-uploader-extra-small asset-level"
                           (click)="complete()"
                           [ngClass]="{ selected: task.statusID === 2 }"
                        >
                           <lim-ui-icon
                              class="img-holder-extra-small"
                              icon="circleCheck"
                              [iconSize]="isMobile ? 'small' : 'medium'"
                           />
                           <span>{{ completed.name }}</span>
                        </span>
                     </div>
                  }
               </div>
               <div class="chk-body">
                  @if (task.checklistUserCompleted) {
                     <lim-ui-alert alertType="success">
                        {{ lang().thisTaskHasBeenCompleted }}
                     </lim-ui-alert>
                  }

                  @if (
                     !taskEditable() &&
                     !task.checklistUserCompleted &&
                     currentUser.workOrderUser !== 1
                  ) {
                     <lim-ui-alert alertType="warning">{{
                        lang().viewOnlyMode
                     }}</lim-ui-alert>
                  }
                  @if (relationData || relatedTask()) {
                     <lim-ui-alert alertType="info">
                        {{ lang().associatedTask }}:
                        @if (relationData) {
                           <u>
                              <a
                                 class="headerLink cursor"
                                 (click)="popTask(relationData.originalChecklistID)"
                              >
                                 @if (relationData.originalTaskStatusName) {
                                    <span>{{ relationData.originalTaskStatusName }}</span>
                                    -
                                 }
                                 <span [limbleHtml]="relationData.checklistName"></span> -
                                 #{{ relationData.originalChecklistID }}
                              </a>
                           </u>
                        }
                        @if (relationData && relatedTask()) {
                           <span>&nbsp;&nbsp;|&nbsp;</span>
                        }
                        @let relatedTaskItem = relatedTask();
                        @if (relatedTaskItem) {
                           <u>
                              <a
                                 class="headerLink cursor"
                                 (click)="popTask(relatedTaskItem.relatedChecklistID)"
                              >
                                 @if (relatedTaskItem.relatedTaskStatusName) {
                                    <span>{{
                                       relatedTaskItem.relatedTaskStatusName
                                    }}</span>
                                    -
                                 }
                                 <span
                                    [limbleHtml]="relatedTaskItem.relatedTaskName"
                                 ></span>
                                 - #{{ relatedTaskItem.relatedChecklistID }}
                              </a>
                           </u>
                        }
                     </lim-ui-alert>
                  }

                  @let viewModelTask = taskViewModel();
                  @if (viewModelTask) {
                     <task-description
                        [task]="viewModelTask"
                        [userStartedThisTask]="userStartedThisTask"
                        [checklistEstTimeMinutes]="checklistEstTimeMinutes"
                        [checklistEstTimeHours]="checklistEstTimeHours"
                        [itemsEditable]="itemsEditable()"
                        [tags]="tags()"
                        [customTagListObj]="customTagListObj()"
                        (clickTag)="clickTag($event)"
                     />
                  }
                  <!--TODO:(LIM-3262) loading-->
                  @if (!loadedItems()) {
                     <div class="chk-spinner-box loading-icon">
                        <lim-ui-loading-animation />
                     </div>
                  }
                  <!--TODO:(LIM-3262) end loading-->

                  <span [hidden]="!loadedItems()">
                     <!--TODO:(LIM-3262) checklist banners-->
                     @if (task.checklistTemplate == 0 && task.checklistDepreciated) {
                        <div>
                           <div class="alert alert-warning unfortunatelyTaskWasDeleted">
                              <span class="ng-binding">{{
                                 lang().UnfortunatelyThisTaskWasDeleted
                              }}</span>
                           </div>
                        </div>
                     }
                     <!--TODO:(LIM-3262) end checklist banners-->

                     <div
                        [hidden]="
                           !(
                              task.checklistDepreciated === 0 ||
                              task.checklistDepreciated === null ||
                              (task.checklistTemplate && task.checklistDepreciated)
                           )
                        "
                        class="checklist-wrapper"
                        [ngClass]="{
                           'is-single-task': data.isSingleTask,
                           'is-mobile': isMobile,
                        }"
                     >
                        @if (task.checklistUserCompleted) {
                           <lim-ui-panel>
                              <div class="checklist-report-container">
                                 <div class="checklist-report-titles">
                                    <div class="checklist-report-header">
                                       {{ lang().Summary }}
                                    </div>

                                    <div class="checklist-report-row">
                                       <div class="checklist-report-due-on report-title">
                                          {{ lang().DueOn }}:
                                       </div>
                                       @if (task.checklistUserCompleted) {
                                          <div
                                             class="checklist-report-due-on report-description"
                                          >
                                             <span>
                                                <span [hidden]="!!tempEdit">
                                                   {{ taskDisplayData.dueDateDisplay }}
                                                </span>
                                                <span [hidden]="!tempEdit">
                                                   <a
                                                      class="cursor"
                                                      (click)="
                                                         changeCompletedTaskDueDate()
                                                      "
                                                      >{{
                                                         taskDisplayData.dueDateDisplay
                                                      }}
                                                   </a>
                                                </span>
                                             </span>
                                          </div>
                                       }
                                    </div>
                                    <div class="checklist-report-row">
                                       <div
                                          class="checklist-report-completed-on report-title"
                                       >
                                          {{ lang().CompletedOn }}:
                                       </div>
                                       @if (
                                          task.checklistUserCompleted &&
                                          task.checklistCompletedDate
                                       ) {
                                          <div
                                             class="checklist-report-completed-on report-description"
                                          >
                                             <span [hidden]="!!tempEdit">
                                                {{
                                                   task.checklistCompletedDate * 1000
                                                      | betterDate: "dateTime"
                                                }}
                                             </span>
                                             <span [hidden]="!tempEdit">
                                                <a
                                                   class="cursor"
                                                   (click)="
                                                      changeCompletedTaskCompletedDate()
                                                   "
                                                   >{{
                                                      task.checklistCompletedDate * 1000
                                                         | betterDate: "dateTime"
                                                   }}
                                                </a>
                                             </span>
                                          </div>
                                       }
                                    </div>
                                    <div class="checklist-report-row">
                                       <div
                                          class="checklist-report-assigned-to report-title"
                                       >
                                          {{ lang().AssignedTo }}:
                                       </div>
                                       <div
                                          class="checklist-report-assigned-to report-description"
                                       >
                                          @if (task.profileID && !tempEdit) {
                                             <span
                                                [limbleHtml]="
                                                   taskDisplayData.profileDescription
                                                "
                                             ></span>
                                          }
                                          @if (task.userID && !tempEdit) {
                                             <span
                                                >{{ taskDisplayData.userFirstName }}
                                                {{ taskDisplayData.userLastName }}</span
                                             >
                                          }
                                          @if (
                                             task.userID === 0 &&
                                             task.profileID === 0 &&
                                             !tempEdit
                                          ) {
                                             <span>{{ lang().Unassigned }}</span>
                                          }

                                          @if (task.profileID && tempEdit) {
                                             <a
                                                class="cursor"
                                                (click)="tempChangeAssignedTo()"
                                                ><span
                                                   [limbleHtml]="
                                                      taskDisplayData.profileDescription
                                                   "
                                                ></span
                                             ></a>
                                          }
                                          @if (task.userID && tempEdit) {
                                             <a
                                                class="cursor"
                                                (click)="tempChangeAssignedTo()"
                                                >{{ taskDisplayData.userFirstName }}
                                                {{ taskDisplayData.userLastName }}</a
                                             >
                                          }
                                          @if (
                                             task.userID === 0 &&
                                             task.profileID === 0 &&
                                             tempEdit
                                          ) {
                                             <a
                                                class="cursor"
                                                (click)="tempChangeAssignedTo()"
                                                >Unassigned</a
                                             >
                                          }
                                       </div>
                                    </div>
                                    @if (
                                       getExtraTimeIds(task).length === 0 && !tempEdit
                                    ) {
                                       <div class="checklist-report-row">
                                          <div
                                             class="checklist-report-completed-by report-title"
                                          >
                                             {{ lang().CompletedBy }}:
                                          </div>
                                          <div
                                             class="checklist-report-completed-by report-description"
                                          >
                                             {{ taskDisplayData.completedFirstName }}
                                             {{ taskDisplayData.completedLastName }}
                                             @if (
                                                taskDisplayData.completedFirstName ===
                                                   null &&
                                                taskDisplayData.completedLastName === null
                                             ) {
                                                <span
                                                   limUiTooltip="{{
                                                      lang().DeletedUserTooltip
                                                   }}"
                                                   >{{ lang().DeletedUser }}</span
                                                >
                                             }
                                          </div>
                                       </div>
                                    }
                                    @if (
                                       taskDisplayData.checklistPromptTimeTotal &&
                                       task.checklistUserCompleted !== 0 &&
                                       task.checklistEstTime
                                    ) {
                                       <div class="checklist-report-row">
                                          <div
                                             class="checklist-report-estimated-time report-title"
                                          >
                                             {{ lang().EstimatedTime }}:
                                          </div>
                                          <div
                                             class="checklist-report-estimated-time report-description"
                                          >
                                             @if (!tempEdit) {
                                                <span class="mobileFloatRight">
                                                   {{ checklistEstTimeHours }}
                                                   {{ lang().hr }} {{ lang().and }}
                                                   {{ checklistEstTimeMinutes }}
                                                   {{ lang().min }}
                                                </span>
                                             }
                                             @if (tempEdit) {
                                                <a
                                                   class="mobileFloatRight cursor"
                                                   (click)="updateEstimatedTime()"
                                                >
                                                   {{ checklistEstTimeHours }}
                                                   {{ lang().hr }} {{ lang().and }}
                                                   {{ checklistEstTimeMinutes }}
                                                   {{ lang().min }}
                                                </a>
                                             }
                                          </div>
                                       </div>
                                    }
                                    @if (
                                       task.checklistUserCompleted !== 0 &&
                                       taskDisplayData.checklistPromptTimeTotal &&
                                       getExtraTimeIds(task).length === 0 &&
                                       !tempEdit &&
                                       ((task.extraTimeNotes &&
                                          task.extraTimeNotes.length) ||
                                          tempEdit)
                                    ) {
                                       <div class="checklist-report-row">
                                          <div
                                             class="checklist-report-description report-title"
                                          >
                                             {{ lang().Description }}:
                                          </div>
                                          <div
                                             class="checklist-report-description report-description"
                                          >
                                             @if (!tempEdit) {
                                                <span
                                                   [limbleHtml]="task.extraTimeNotes"
                                                ></span>
                                             }
                                             @if (tempEdit) {
                                                <a
                                                   class="cursor"
                                                   (click)="changeTaskExtraTimeNotes()"
                                                >
                                                   @if (
                                                      task.extraTimeNotes &&
                                                      task.extraTimeNotes.length
                                                   ) {
                                                      <span
                                                         [limbleHtml]="
                                                            task.extraTimeNotes
                                                         "
                                                      ></span>
                                                   }
                                                   @if (
                                                      task.extraTimeNotes &&
                                                      task.extraTimeNotes.length < 1
                                                   ) {
                                                      <span>______ </span>
                                                   }
                                                </a>
                                             }
                                          </div>
                                       </div>
                                    }
                                    @if (
                                       (taskDisplayData.checklistPromptTimeTotal &&
                                          getExtraTimeIds(task).length > 0) ||
                                       tempEdit
                                    ) {
                                       <div class="checklist-report-row">
                                          <div
                                             class="checklist-report-billable-hours-total report-title"
                                          >
                                             {{ lang().BillableHoursTotal }}:
                                          </div>
                                          <div
                                             class="checklist-report-billable-hours-total report-description"
                                          >
                                             {{ taskDisplayData.billableTimeTotalHours }}
                                             {{ lang().hr }} {{ lang().and }}
                                             {{
                                                taskDisplayData.billableTimeTotalMinutes
                                             }}
                                             {{ lang().min }}
                                          </div>
                                       </div>
                                    }
                                    @if (
                                       taskDisplayData.checklistPromptTimeTotal &&
                                       getExtraTimeIds(task).length === 0 &&
                                       !tempEdit &&
                                       task.categoryID
                                    ) {
                                       <div class="checklist-report-row">
                                          <div
                                             class="checklist-report-billable-hours report-title"
                                          >
                                             {{ lang().BillableHours }}:
                                          </div>
                                          <div
                                             class="checklist-report-billable-hours report-description"
                                          >
                                             {{ billableHours }} {{ lang().hr }}
                                             {{ lang().and }} {{ billableMinutes }}
                                             {{ lang().min }}
                                             <span
                                                [limbleHtml]="
                                                   categoriesIndex[task.categoryID] &&
                                                   categoriesIndex[task.categoryID]
                                                      .categoryName
                                                "
                                             ></span>
                                             @if (viewLaborCostsCred) {
                                                <span>
                                                   {{ lang().at }}
                                                   <cost-viewer-component
                                                      [rate]="taskVM?.billableRate"
                                                      [locationID]="taskVM?.locationID"
                                                      [currencySymbol]="currencySymbol"
                                                   />
                                                   {{ lang().perHour }}
                                                </span>
                                             }
                                          </div>
                                       </div>
                                    }
                                    @if (
                                       featureDowntimeTracking &&
                                       task.checklistDowntime &&
                                       task.checklistDowntime >= 0
                                    ) {
                                       <div class="checklist-report-row">
                                          <div
                                             class="checklist-report-downtime-time report-title"
                                          >
                                             {{ lang().Downtime }}:
                                          </div>
                                          <div
                                             class="checklist-report-downtime-time report-description"
                                          >
                                             <span>
                                                @if (
                                                   !tempEdit || !featureDowntimeTracking
                                                ) {
                                                   <span>
                                                      @if (
                                                         checklistDowntimeHours !==
                                                            undefined &&
                                                         checklistDowntimeMinutes !==
                                                            undefined
                                                      ) {
                                                         {{ checklistDowntimeHours }}
                                                         {{ lang().hr }}
                                                         {{ lang().and }}
                                                         {{ checklistDowntimeMinutes }}
                                                         {{ lang().min }}
                                                      }
                                                      @if (
                                                         checklistDowntimeHours ===
                                                            undefined ||
                                                         checklistDowntimeMinutes ===
                                                            undefined
                                                      ) {
                                                         0 {{ lang().hr }} 0
                                                         {{ lang().min }}
                                                      }
                                                   </span>
                                                }
                                                @if (
                                                   tempEdit && featureDowntimeTracking
                                                ) {
                                                   <a
                                                      class="cursor"
                                                      (click)="tempChangeDowntime()"
                                                   >
                                                      {{ checklistDowntimeHours }}
                                                      @if (task.checklistDowntime === 0) {
                                                         <span>0</span>
                                                      }
                                                      {{ lang().hr }} {{ lang().and }}
                                                      {{ checklistDowntimeMinutes }}
                                                      @if (task.checklistDowntime === 0) {
                                                         <span>0</span>
                                                      }
                                                      {{ lang().min }}
                                                   </a>
                                                }
                                             </span>
                                          </div>
                                       </div>
                                    }
                                    @if (
                                       taskDisplayData.checklistPromptTimeTotal ||
                                       getExtraTimeIds(task).length > 0
                                    ) {
                                       <div class="checklist-report-row">
                                          <div
                                             class="checklist-report-time-spent-total report-title"
                                          >
                                             {{ lang().TimeSpentTotal }}:
                                          </div>
                                          <div
                                             class="checklist-report-time-spent-total report-description"
                                          >
                                             {{
                                                taskDisplayData.checklistPromptTimeTotalHours
                                             }}
                                             {{ lang().hr }} {{ lang().and }}
                                             {{
                                                taskDisplayData.checklistPromptTimeTotalMinutes
                                             }}
                                             {{ lang().min }}
                                          </div>
                                       </div>
                                    }
                                 </div>

                                 @if (
                                    taskDisplayData.checklistPromptTimeTotal ||
                                    getExtraTimeIds(task).length > 0
                                 ) {
                                    <div class="checklist-report-itemizations">
                                       @if (
                                          displayMode === extraTimeDisplayModes.ByUser
                                       ) {
                                          <a
                                             href="#"
                                             (click)="
                                                $event.preventDefault();
                                                setDisplayMode(
                                                   extraTimeDisplayModes.ByTime
                                                )
                                             "
                                          >
                                             {{ lang().SortTimeLoggedByDate }}
                                          </a>
                                       }
                                       @if (
                                          displayMode === extraTimeDisplayModes.ByTime
                                       ) {
                                          <a
                                             href="#"
                                             (click)="
                                                $event.preventDefault();
                                                setDisplayMode(
                                                   extraTimeDisplayModes.ByUser
                                                )
                                             "
                                          >
                                             {{ lang().SortTimeLoggedByUser }}
                                          </a>
                                       }
                                       @let taskExtraTimeViewModel = taskExtraTimeVM();
                                       @if (taskExtraTimeViewModel) {
                                          <extra-time-list
                                             #extraTimeList
                                             [displayMode]="displayMode"
                                             [disabled]="!tempEdit"
                                             [checklistID]="task.checklistID"
                                             [extraTimeViewModel]="taskExtraTimeViewModel"
                                             (extraTimeUpdated)="tellTaskToRefresh()"
                                          />
                                       }
                                    </div>
                                 }
                              </div>
                           </lim-ui-panel>
                        }

                        @if (getTaskInvoices(task).length > 0) {
                           <task-invoices
                              [invoices]="getTaskInvoices(task)"
                              [taskID]="task.checklistID"
                              [locationID]="task.locationID"
                           />
                        }

                        @let viewModelTaskParts = taskViewModel();
                        @if (
                           getTaskPartRelationIDs(task).length > 0 &&
                           parts.length > 0 &&
                           viewModelTaskParts
                        ) {
                           <task-parts-list
                              [task]="viewModelTaskParts"
                              [taskFormState]="taskInstructionData()"
                              (startPoForPart)="startPOForPart([$event])"
                              (updatedPartSuggestedNumber)="
                                 updatedPartSuggestedNumber($event)
                              "
                              (removePartFromTask)="removePartFromTask($event)"
                              (popPartModal)="popPart($event)"
                              (popPoComponent)="popPoComponent($event)"
                              (partsUpdated)="tellTaskToRefresh()"
                           />
                        }

                        @if (
                           tools && tools.length && assetCheckInOut && featureAssetTools
                        ) {
                           <lim-ui-panel
                              [title]="lang().ToolsNeededForThisTask"
                              [collapsable]="true"
                           >
                              @for (toolData of tools; track toolData) {
                                 <task-tool
                                    [tool]="toolData.tool"
                                    [mostRecentRequest]="toolData.mostRecentRequest"
                                    [removeToolFromTask]="removeToolFromTask"
                                    [instance]="true"
                                    [checklistID]="task.checklistID"
                                    [taskInput]="task"
                                 />
                              }
                           </lim-ui-panel>
                        }

                        @if (displayAssetInfo()) {
                           <lim-ui-panel
                              [title]="
                                 task.checklistStatusID
                                    ? lang().AssetInformationWhenTaskWasCompleted
                                    : lang().AssetInformation
                              "
                              [collapsable]="true"
                           >
                              <!-- on open tasks -->
                              @if (!task.checklistStatusID) {
                                 <div>
                                    @for (valueID of assetFieldValues(); track valueID) {
                                       <div>
                                          @if (
                                             manageAsset.getFieldValue(valueID);
                                             as fieldValue
                                          ) {
                                             <div
                                                class="clickable"
                                                data-log="task-assetField"
                                             >
                                                @if (
                                                   manageAsset.getField(
                                                      fieldValue.fieldID
                                                   );
                                                   as field
                                                ) {
                                                   <div>
                                                      @if (
                                                         manageAsset.getFieldType(
                                                            field.fieldTypeID
                                                         );
                                                         as fieldType
                                                      ) {
                                                         <div>
                                                            @if (
                                                               field.fieldTypeID !== 3 &&
                                                               field.fieldTypeID !== 4 &&
                                                               field.displayOnTasks === 1
                                                            ) {
                                                               <div
                                                                  class="lim-ui-list-item"
                                                                  (click)="popAsset(task)"
                                                               >
                                                                  <div
                                                                     class="list-item-content-group"
                                                                  >
                                                                     <span
                                                                        class="text-with-icon"
                                                                     >
                                                                        <lim-ui-icon
                                                                           [icon]="
                                                                              fieldType.fieldTypeIcon
                                                                                 ? (fieldType.fieldTypeIcon
                                                                                   | iconAlias)
                                                                                 : 'notdef'
                                                                           "
                                                                           iconSize="small"
                                                                           placement="right"
                                                                           [limUiTooltip]="
                                                                              fieldType.fieldTypeHint ??
                                                                              ''
                                                                           "
                                                                        />
                                                                        <b
                                                                           >{{
                                                                              field.fieldName
                                                                                 ? field.fieldName
                                                                                 : "Unnamed"
                                                                           }}:</b
                                                                        >
                                                                     </span>
                                                                     <span
                                                                        class="text-with-icon asset-field-value"
                                                                     >
                                                                        @if (
                                                                           field.fieldTypeID ===
                                                                           2
                                                                        ) {
                                                                           <span>
                                                                              {{
                                                                                 fieldValue.valueContent
                                                                                    | betterDate
                                                                                       : "date"
                                                                              }}
                                                                           </span>
                                                                        }
                                                                        @if (
                                                                           field.fieldTypeID ===
                                                                           6
                                                                        ) {
                                                                           <span>
                                                                              {{
                                                                                 fieldValue.valueContent
                                                                                    | betterCurrency
                                                                              }}
                                                                           </span>
                                                                        }
                                                                        @if (
                                                                           field.fieldTypeID !==
                                                                              2 &&
                                                                           field.fieldTypeID !==
                                                                              6
                                                                        ) {
                                                                           <span
                                                                              [limbleHtml]="
                                                                                 fieldValue.valueContent
                                                                              "
                                                                           ></span>
                                                                        }
                                                                     </span>
                                                                     <lim-ui-icon
                                                                        icon="circleQuestion"
                                                                        iconSize="small"
                                                                        placement="top"
                                                                        limUiTooltip="{{
                                                                           lang().View
                                                                        }} {{
                                                                           lang()
                                                                              .AssociatedAssets
                                                                        }}"
                                                                     />
                                                                  </div>
                                                               </div>
                                                            }
                                                         </div>
                                                      }
                                                   </div>
                                                }
                                             </div>
                                          }
                                       </div>
                                    }
                                 </div>
                              }
                              <!-- on closed tasks -->
                              @if (
                                 task.checklistStatusID &&
                                 assetInfoFromCompletionArr.length
                              ) {
                                 <div>
                                    @for (
                                       field of assetInfoFromCompletionArr;
                                       track field
                                    ) {
                                       <div>
                                          @if (field.assetID === asset()?.assetID) {
                                             <div class="lim-ui-list-item">
                                                <span>
                                                   <i
                                                      class=" fa-fw {{
                                                         field.fieldTypeIcon
                                                      }}"
                                                      placement="right"
                                                      limUiTooltip="{{
                                                         field.fieldTypeHint
                                                      }}"
                                                   >
                                                   </i>
                                                   <b
                                                      >{{
                                                         field.fieldName
                                                            ? field.fieldName
                                                            : "Unnamed"
                                                      }}:</b
                                                   >
                                                </span>
                                                <span class="updateFieldHolder">{{
                                                   field.fieldTypeID === 2
                                                      ? (field.valueContent
                                                        | betterDate: "date")
                                                      : field.fieldTypeID === 6
                                                        ? (field.valueContent
                                                          | betterCurrency)
                                                        : field.valueContent
                                                }}</span>
                                                <lim-ui-icon
                                                   icon="circleQuestionRegular"
                                                   (click)="popAsset(task)"
                                                   placement="top"
                                                   limUiTooltip="{{ lang().View }} {{
                                                      lang().AssociatedAssets
                                                   }}"
                                                />
                                             </div>
                                          }
                                       </div>
                                    }
                                 </div>
                              }
                           </lim-ui-panel>
                        }

                        @if (
                           task.checklistTemplate !== 6 &&
                           requestedPurchases &&
                           requestedPurchases.size > 0
                        ) {
                           <lim-ui-panel
                              [title]="lang().PurchaseRequests"
                              [collapsable]="true"
                           >
                              @for (
                                 purchaseOrder of requestedPurchases;
                                 track purchaseOrder
                              ) {
                                 @if (purchaseOrder) {
                                    <div
                                       (click)="popPoComponent(purchaseOrder.poID)"
                                       class="clickable"
                                       data-log="task-purchaseRequest"
                                    >
                                       <div
                                          class="lim-ui-list-item purchase-request-item"
                                       >
                                          <lim-ui-icon
                                             icon="filePowerpoint"
                                             iconSize="small"
                                          />

                                          <span class="po-number"
                                             >{{ lang().PO }} -
                                             {{ purchaseOrder.poNumber }}</span
                                          >
                                          -
                                          <strong class="po-name-and-status">{{
                                             purchaseOrder.name
                                          }}</strong>

                                          @if (purchaseOrder.state === 101) {
                                             <span class="icon-and-text-container">
                                                <lim-ui-icon
                                                   icon="thumbsDown"
                                                   iconSize="small"
                                                   iconColor="danger"
                                                />
                                                <span
                                                   [limbleHtml]="
                                                      purchaseOrder.requestDisapproveReason
                                                   "
                                                ></span>
                                             </span>
                                          }
                                       </div>
                                    </div>
                                 }
                              }
                           </lim-ui-panel>
                        }

                        <!-- TODO (https://limble.atlassian.net/browse/TASK-1083) - Clean up flag -->
                        @if (collapsibleInstructionsFlag()) {
                           <lim-ui-panel
                              [collapsable]="true"
                              [title]="lang().Instructions"
                           >
                              <div
                                 [hidden]="
                                    !(
                                       (taskEditable() && !task.checklistUserCompleted) ||
                                       tempEdit
                                    )
                                 "
                                 class="hidePrint"
                                 buttons
                              >
                                 @if (
                                    task.checklistTemplate &&
                                    allowEditTemplateInstructions &&
                                    taskFormState.shouldShowModeToggleButton()
                                 ) {
                                    <span>
                                       <lim-ui-primary-button
                                          icon="wrenchRegular"
                                          (click)="switchMode(false)"
                                          placement="left"
                                          limUiTooltip="{{
                                             lang().EditThisTasksInstructions
                                          }}"
                                       >
                                          <span
                                             [hidden]="
                                                !!EditingWOTemplateInstanceBeforeLive
                                             "
                                          >
                                             {{ lang().BuildThisTask }}
                                          </span>
                                          <span
                                             [hidden]="
                                                !EditingWOTemplateInstanceBeforeLive
                                             "
                                          >
                                             {{ lang().EditThisWOInstanceDuringStart }}
                                          </span>
                                       </lim-ui-primary-button>
                                    </span>
                                 }

                                 @if (
                                    task.checklistTemplate === 0 &&
                                    (credToEditOpenTaskInstructions || tempEdit) &&
                                    taskFormState.shouldShowModeToggleButton()
                                 ) {
                                    <span>
                                       <lim-ui-secondary-button
                                          icon="wrenchRegular"
                                          dataLogLabel="tasks-initiateEdit"
                                          (click)="switchMode(true)"
                                          placement="left"
                                          limUiTooltip="{{
                                             lang().EditThisTasksInstructions
                                          }}"
                                          data-test-id="taskEditButton"
                                       >
                                          {{ lang().Edit }}
                                       </lim-ui-secondary-button>
                                    </span>
                                 }
                              </div>
                              <div class="instructions-panel">
                                 <header>
                                    <!-- TODO, allow it so customers can turn this on or off -->
                                    @if (
                                       items().length &&
                                       (EditingWOTemplateInstanceBeforeLive ||
                                          taskEditable() ||
                                          task.checklistUserCompleted)
                                    ) {
                                       <lim-ui-progress-bar
                                          [progress]="
                                             (totalCompleteItems() / totalItems()) * 100
                                                | betterNumber: '1.0-0'
                                          "
                                          label="{{ lang().PercentageDone }}"
                                       />
                                    }
                                 </header>

                                 <div class="nested-panels-body">
                                    @if (info !== undefined && viewModelTask) {
                                       <div>
                                          @if (items()?.length) {
                                             @for (
                                                item of items()
                                                   | filter: { itemParentID: 0 };
                                                track item
                                             ) {
                                                <chk-item
                                                   #chkItem
                                                   [info]="info"
                                                   [taskInstructionsViewParameters]="
                                                      taskInstructionData()
                                                   "
                                                   [item]="item"
                                                   [tempEdit]="tempEdit"
                                                   [parentTaskInput]="viewModelTask"
                                                   [taskHasOptionSelectedWithSameCustomTag]="
                                                      boundGatherOptionResponseCustomTagInfo
                                                   "
                                                   [instructions]="items()"
                                                   class="chk-items"
                                                />
                                             }
                                          } @else {
                                             <div>{{ lang().NoTaskInstructions }}</div>
                                          }
                                       </div>
                                    }

                                    @if (minPartQtyTask) {
                                       <span>
                                          @if (
                                             ((task.checklistUserCompleted === 0 &&
                                                taskEditable()) ||
                                                tempEdit) &&
                                             task.checklistTemplate === 0 &&
                                             currentUser.workOrderUser !== 1
                                          ) {
                                             <lim-ui-secondary-button
                                                (click)="
                                                   startPOForPart([minPartQtyTask.partID])
                                                "
                                                placement="bottom"
                                                limUiTooltip="{{
                                                   lang().StartPOForThisPart
                                                }}: {{ minPartQtyTask.partName }} - {{
                                                   minPartQtyTask.partNumber
                                                }}"
                                                icon="filePowerpointRegular"
                                                [classNames]="
                                                   minPartQtyTask
                                                      .pendingPOsCurrentStateMap?.size ===
                                                   0
                                                      ? 'primary '
                                                      : 'button-shadow '
                                                "
                                                class="start-po-styles"
                                                >{{
                                                   lang().StartPO
                                                }}</lim-ui-secondary-button
                                             >
                                          }

                                          @if (
                                             minPartQtyTask.pendingPOsCurrentStateMap &&
                                             minPartQtyTask.pendingPOsCurrentStateMap
                                                .size > 0 &&
                                             currentUser.workOrderUser !== 1
                                          ) {
                                             <div class="start-po-styles">
                                                {{ lang().OpenPOsForThisPart }}:
                                             </div>
                                          }
                                          @if (currentUser.workOrderUser !== 1) {
                                             @for (
                                                stateInfo of minPartQtyTask.pendingPOsCurrentStateMap;
                                                track stateInfo
                                             ) {
                                                <span>
                                                   @if (stateInfo !== undefined) {
                                                      <a
                                                         class="cursor"
                                                         (click)="
                                                            popPoComponent(stateInfo.poID)
                                                         "
                                                      >
                                                         {{ lang().PO }} -
                                                         {{ stateInfo.poNumber }}
                                                      </a>
                                                   }
                                                   ({{ stateInfo?.name }})
                                                   @if (!$last) {
                                                      <span>| </span>
                                                   }
                                                </span>
                                             }
                                          }
                                       </span>
                                    }

                                    @if (loading) {
                                       <div class="chk-spinner-box loading-icon">
                                          <lim-ui-loading-animation />
                                       </div>
                                    }
                                 </div>
                                 @if (
                                    items().length &&
                                    task.checklistUserCompleted === 0 &&
                                    taskEditable() &&
                                    currentUser.workOrderUser !== 1
                                 ) {
                                    <footer>
                                       <span>
                                          <lim-ui-secondary-button
                                             icon="rotateLeft"
                                             placement="bottom"
                                             limUiTooltip="{{ lang().ResetTooltip }}"
                                             (click)="reset()"
                                             dataLogLabel="task-resetResponses"
                                             data-test-id="taskResetButton"
                                          >
                                             {{ lang().Reset }}
                                          </lim-ui-secondary-button>
                                       </span>
                                    </footer>
                                 }
                              </div>
                           </lim-ui-panel>
                        } @else {
                           <lim-ui-panel>
                              <div class="instructions-panel">
                                 <header>
                                    <div class="top">
                                       <h3>{{ lang().Instructions }}</h3>

                                       <div
                                          [hidden]="
                                             !(
                                                (taskEditable() &&
                                                   !task.checklistUserCompleted) ||
                                                tempEdit
                                             )
                                          "
                                          class="hidePrint"
                                       >
                                          @if (
                                             task.checklistTemplate &&
                                             allowEditTemplateInstructions &&
                                             taskFormState.shouldShowModeToggleButton()
                                          ) {
                                             <span>
                                                <lim-ui-primary-button
                                                   icon="wrenchRegular"
                                                   (click)="switchMode(false)"
                                                   placement="left"
                                                   limUiTooltip="{{
                                                      lang().EditThisTasksInstructions
                                                   }}"
                                                >
                                                   <span
                                                      [hidden]="
                                                         !!EditingWOTemplateInstanceBeforeLive
                                                      "
                                                   >
                                                      {{ lang().BuildThisTask }}
                                                   </span>
                                                   <span
                                                      [hidden]="
                                                         !EditingWOTemplateInstanceBeforeLive
                                                      "
                                                   >
                                                      {{
                                                         lang()
                                                            .EditThisWOInstanceDuringStart
                                                      }}
                                                   </span>
                                                </lim-ui-primary-button>
                                             </span>
                                          }

                                          @if (
                                             task.checklistTemplate === 0 &&
                                             (credToEditOpenTaskInstructions ||
                                                tempEdit) &&
                                             taskFormState.shouldShowModeToggleButton()
                                          ) {
                                             <span>
                                                <lim-ui-secondary-button
                                                   icon="wrenchRegular"
                                                   dataLogLabel="tasks-initiateEdit"
                                                   (click)="switchMode(true)"
                                                   placement="left"
                                                   limUiTooltip="{{
                                                      lang().EditThisTasksInstructions
                                                   }}"
                                                   data-test-id="taskEditButton"
                                                >
                                                   {{ lang().Edit }}
                                                </lim-ui-secondary-button>
                                             </span>
                                          }
                                       </div>
                                    </div>

                                    <!-- TODO, allow it so customers can turn this on or off -->
                                    @if (
                                       items().length &&
                                       (EditingWOTemplateInstanceBeforeLive ||
                                          taskEditable() ||
                                          task.checklistUserCompleted)
                                    ) {
                                       <lim-ui-progress-bar
                                          [progress]="
                                             (totalCompleteItems() / totalItems()) * 100
                                                | betterNumber: '1.0-0'
                                          "
                                          label="{{ lang().PercentageDone }}"
                                       />
                                    }
                                 </header>

                                 <div class="nested-panels-body">
                                    @if (info !== undefined) {
                                       <div>
                                          @if (items()?.length) {
                                             @for (
                                                item of items()
                                                   | filter: { itemParentID: 0 };
                                                track item
                                             ) {
                                                <chk-item
                                                   #chkItem
                                                   [info]="info"
                                                   [taskInstructionsViewParameters]="
                                                      taskInstructionData()
                                                   "
                                                   [item]="item"
                                                   [tempEdit]="tempEdit"
                                                   [taskHasOptionSelectedWithSameCustomTag]="
                                                      boundGatherOptionResponseCustomTagInfo
                                                   "
                                                   [instructions]="items()"
                                                   class="chk-items"
                                                />
                                             }
                                          } @else {
                                             <div>{{ lang().NoTaskInstructions }}</div>
                                          }
                                       </div>
                                    }

                                    @if (minPartQtyTask) {
                                       <span>
                                          @if (
                                             ((task.checklistUserCompleted === 0 &&
                                                taskEditable()) ||
                                                tempEdit) &&
                                             task.checklistTemplate === 0 &&
                                             currentUser.workOrderUser !== 1
                                          ) {
                                             <lim-ui-secondary-button
                                                (click)="
                                                   startPOForPart([minPartQtyTask.partID])
                                                "
                                                placement="bottom"
                                                limUiTooltip="{{
                                                   lang().StartPOForThisPart
                                                }}: {{ minPartQtyTask.partName }} - {{
                                                   minPartQtyTask.partNumber
                                                }}"
                                                icon="filePowerpointRegular"
                                                [classNames]="
                                                   minPartQtyTask
                                                      .pendingPOsCurrentStateMap?.size ===
                                                   0
                                                      ? 'primary '
                                                      : 'button-shadow '
                                                "
                                                class="start-po-styles"
                                                >{{
                                                   lang().StartPO
                                                }}</lim-ui-secondary-button
                                             >
                                          }

                                          @if (
                                             minPartQtyTask.pendingPOsCurrentStateMap &&
                                             minPartQtyTask.pendingPOsCurrentStateMap
                                                .size > 0 &&
                                             currentUser.workOrderUser !== 1
                                          ) {
                                             <div class="start-po-styles">
                                                {{ lang().OpenPOsForThisPart }}:
                                             </div>
                                          }
                                          @if (currentUser.workOrderUser !== 1) {
                                             @for (
                                                stateInfo of minPartQtyTask.pendingPOsCurrentStateMap;
                                                track stateInfo
                                             ) {
                                                <span>
                                                   @if (stateInfo !== undefined) {
                                                      <a
                                                         class="cursor"
                                                         (click)="
                                                            popPoComponent(stateInfo.poID)
                                                         "
                                                      >
                                                         {{ lang().PO }} -
                                                         {{ stateInfo.poNumber }}
                                                      </a>
                                                   }
                                                   ({{ stateInfo?.name }})
                                                   @if (!$last) {
                                                      <span>| </span>
                                                   }
                                                </span>
                                             }
                                          }
                                       </span>
                                    }

                                    @if (loading) {
                                       <div class="chk-spinner-box loading-icon">
                                          <lim-ui-loading-animation />
                                       </div>
                                    }
                                 </div>
                                 @if (
                                    items().length &&
                                    task.checklistUserCompleted === 0 &&
                                    taskEditable() &&
                                    currentUser.workOrderUser !== 1
                                 ) {
                                    <footer>
                                       <span>
                                          <lim-ui-secondary-button
                                             icon="rotateLeft"
                                             placement="bottom"
                                             limUiTooltip="{{ lang().ResetTooltip }}"
                                             (click)="reset()"
                                             dataLogLabel="task-resetResponses"
                                             data-test-id="taskResetButton"
                                          >
                                             {{ lang().Reset }}
                                          </lim-ui-secondary-button>
                                       </span>
                                    </footer>
                                 }
                              </div>
                           </lim-ui-panel>
                        }

                        @if (
                           getExtraTimeIds(task).length > 0 &&
                           task.checklistCompletedDate === 0
                        ) {
                           <lim-ui-panel [useCustomTitle]="true" [collapsable]="true">
                              <div class="time-logged-custom-title" panelCustomTitle>
                                 @if (task.checklistStatusID === 0) {
                                    <span>
                                       {{ lang().TimeLogged }}
                                    </span>
                                 }
                              </div>
                              <div>
                                 @if (displayMode === extraTimeDisplayModes.ByUser) {
                                    <a
                                       href="#"
                                       (click)="
                                          $event.preventDefault();
                                          setDisplayMode(extraTimeDisplayModes.ByTime)
                                       "
                                    >
                                       {{ lang().SortTimeLoggedByDate }}
                                    </a>
                                 }
                                 @if (displayMode === extraTimeDisplayModes.ByTime) {
                                    <a
                                       href="#"
                                       (click)="
                                          $event.preventDefault();
                                          setDisplayMode(extraTimeDisplayModes.ByUser)
                                       "
                                    >
                                       {{ lang().SortTimeLoggedByUser }}
                                    </a>
                                 }
                              </div>
                              @if (getExtraTimeIds(task).length >= 1) {
                                 <span buttons class="total-time-logged-container">
                                    <span class="total-time-label">
                                       {{ lang().TotalTime }}:
                                    </span>
                                    {{ taskDisplayData.checklistPromptTimeTotalHours }}
                                    {{ lang().hr }} {{ lang().and }}
                                    {{ taskDisplayData.checklistPromptTimeTotalMinutes }}
                                    {{ lang().min }}
                                 </span>
                              }
                              @let taskExtraTimeViewModel = taskExtraTimeVM();
                              @if (taskExtraTimeViewModel) {
                                 <extra-time-list
                                    #extraTimeList
                                    [disabled]="
                                       !hasLogTimeForOthersCredentials &&
                                       !superUser &&
                                       !taskEditable() &&
                                       currentUser?.workOrderUser !== 1
                                    "
                                    [displayMode]="displayMode"
                                    [checklistID]="task.checklistID"
                                    [extraTimeViewModel]="taskExtraTimeViewModel"
                                    (extraTimeUpdated)="tellTaskToRefresh()"
                                 />
                              }
                           </lim-ui-panel>
                        }

                        <!-- This section displays comments on the task. -->
                        @if (
                           (getTaskNoteIDs(task).length > 0 ||
                              (task.checklistTemplate == 0 && addCommentCred)) &&
                           task.checklistTemplate !== 6 &&
                           !EditingWOTemplateInstanceBeforeLive &&
                           viewModelTask
                        ) {
                           <task-comments
                              [task]="viewModelTask"
                              [taskFormState]="taskInstructionData()"
                              (noteUpdated)="tellTaskToRefresh()"
                              (mentionClickTag)="mentionClickTag($event)"
                              (popTask)="popTask($event.checklistID)"
                              (preventDoubleClickUpdated)="
                                 taskFormState.updatePreventDoubleClick($event)
                              "
                           />
                        }

                        <!-- This panel is the panel that contains the completion notes for the task.-->
                        @if (
                           ((task.checklistComments &&
                              task.checklistComments.length > 0) ||
                              task.checklistTemplate !== 1) &&
                           task.checklistTemplate !== 6 &&
                           !EditingWOTemplateInstanceBeforeLive &&
                           currentUser?.workOrderUser !== 1 &&
                           !(
                              !task.checklistComments &&
                              task.checklistUserCompleted &&
                              !itemsEditable()
                           )
                        ) {
                           <lim-ui-panel
                              [collapsable]="true"
                              (click)="completionNotesError = false"
                              [title]="lang().CompletionNotes"
                           >
                              <div
                                 blurOnKeyboardClose
                                 class="large-text-field"
                                 type="text"
                                 [(ngModel)]="task.checklistComments"
                                 [ngClass]="{
                                    disabled:
                                       !itemsEditable() &&
                                       currentUser?.workOrderUser !== 1,
                                 }"
                                 [limbleContentEditable]="
                                    (itemsEditable() ?? false) &&
                                    currentUser?.workOrderUser !== 1
                                 "
                                 (afterEdit)="updateTaskCompletionNotes()"
                                 data-log="task-completionNotesEdit"
                                 (focus)="isCommentNoteFocused = true"
                                 (blur)="isCommentNoteFocused = false"
                                 data-test-id="taskCompletionNotesInput"
                              ></div>
                           </lim-ui-panel>
                        }

                        <div class="delete-task-button-wrapper">
                           @if (
                              deleteOpenTaskCred &&
                              task.checklistTemplate == 0 &&
                              currentUser.workOrderUser !== 1 &&
                              task.checklistUserCompleted == 0 &&
                              task.checklistStatusID == 0
                           ) {
                              <lim-ui-secondary-button
                                 class="delete-task-button"
                                 icon="trashCanRegular"
                                 iconColor="danger"
                                 (click)="deleteOpenTask()"
                                 limUiTooltip="{{ lang().permDeleteTaskTooltip }}"
                                 data-test-id="taskDeleteButton"
                                 >{{ lang().DeleteTask }}
                              </lim-ui-secondary-button>
                           }
                           @if (
                              (task.checklistUserCompleted != 0 ||
                                 task.checklistStatusID != 0) &&
                              deleteCompletedTaskCred
                           ) {
                              <lim-ui-secondary-button
                                 class="delete-task-button"
                                 icon="trashCanRegular"
                                 iconColor="danger"
                                 (click)="deleteCompletedTask()"
                                 limUiTooltip="{{ lang().permDeleteTaskTooltip }}"
                                 data-test-id="taskDeleteCompletedTaskButton"
                                 >{{ lang().DeleteTask }}
                              </lim-ui-secondary-button>
                           }
                        </div>
                     </div>
                  </span>
               </div>
            </lim-ui-scroll-container>
         } @else {
            <div class="skeleton">
               <div class="locationAddressAndName">
                  <div class="printViewShow"></div>
                  <div style="clear: both"></div>
               </div>

               @if (task.checklistTemplate == 0 && task.checklistDepreciated) {
                  <div>
                     <div class="alert alert-warning unfortunatelyTaskWasDeleted">
                        <span class="ng-binding">{{
                           lang().UnfortunatelyThisTaskWasDeleted
                        }}</span>
                     </div>
                  </div>
               }

               <div>
                  @if (task.checklistTemplate !== 6) {
                     <span [ngClass]="{ hidePrint: customerID == 944 }">
                        <div
                           class="alert alert-success"
                           [hidden]="!task.checklistUserCompleted"
                        >
                           <i
                              class="fa-regular fa-square-check greenColor taskBeenCompleted"
                           ></i>
                           {{ lang().thisTaskHasBeenCompleted }}
                        </div>

                        <h5>
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="{ width: '20%', height: '25px', border: '0px' }"
                           /><br />

                           <div
                              class="dropdown hidePrint shareDropdownLarge floatRight"
                              [hidden]="
                                 !(
                                    (!EditingWOTemplateInstanceBeforeLive &&
                                       shareTasksCred) ||
                                    currentUser.workOrderUser == 1
                                 )
                              "
                           >
                              <ngx-skeleton-loader
                                 count="1"
                                 class="floatRight"
                                 [theme]="{
                                    'width': '60px',
                                    'height': '25px',
                                    'border-radius': '15px',
                                    'border': '0px',
                                 }"
                              />
                           </div>

                           <div style="clear: both"></div>

                           <br />

                           <br class="mobileBR" />

                           <ngx-skeleton-loader count="1" [theme]="skeletonThemes.long" />

                           <br class="mobileBRHide" />

                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />

                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />

                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />

                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           />
                        </h5>

                        <div style="clear: both"></div>

                        <span>
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           />
                           <br />
                        </span>

                        <span>
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           />
                        </span>
                        <span>
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           />
                        </span>

                        @if (getTaskInvoices(task).length > 0 && viewInvoicesCred) {
                           <span>
                              <hr />
                              <b>{{ lang().Invoices }}:</b>

                              @if (tempEdit) {
                                 <div
                                    class="btn-raised btn btn-orange cursor"
                                    (click)="addInvoice()"
                                    style="margin-right: 0px; float: right"
                                    placement="left"
                                    limUiTooltip="{{ lang().editInvoiceTooltip }}"
                                 >
                                    <i class="fa-solid fa-solid fa-wrench"></i>

                                    @if (task.checklistTemplate == 0) {
                                       <span
                                          style="margin-left: 5px"
                                          class="mobileDisappear"
                                          >{{ lang().EditInvoiceBtn }}</span
                                       >
                                    }
                                 </div>
                              }
                              <br />
                           </span>
                        }

                        <div [hidden]="!(getTaskPartRelationIDs(task).length > 0)">
                           <hr />
                           <b [hidden]="!(task.checklistStatusID == 0)"
                              >{{ lang().PartsYouMayNeed }}:</b
                           >
                           <b [hidden]="!task.checklistStatusID"
                              >{{ lang().PartsThatWereUsed }}:</b
                           ><br />

                           <!--must keep track by $index in so that when adding parts that are simliar we don't get dups.  Rare place where we have to keep it-->
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.large"
                           />
                        </div>
                        <div style="clear: both"></div>
                        <hr />
                        <!-- Asset information section -->
                        @if (asset() && displayAssetInfo()) {
                           <div>
                              <!-- on open tasks -->
                              <ngx-skeleton-loader
                                 count="1"
                                 [theme]="skeletonThemes.large"
                              />
                              <!-- on closed tasks -->
                              @if (
                                 task.checklistStatusID &&
                                 assetInfoFromCompletionArr.length
                              ) {
                                 <div>
                                    <b
                                       >{{
                                          lang().AssetInformationWhenTaskWasCompleted
                                       }}:</b
                                    >
                                 </div>
                              }
                              <hr />
                           </div>
                        }
                     </span>
                  }

                  @if (
                     task.checklistTemplate !== 6 &&
                     requestedPurchases &&
                     requestedPurchases.size > 0
                  ) {
                     <div>
                        <b>{{ lang().PurchaseRequests }}:</b>
                        @for (
                           purchaseOrderStateInfo of requestedPurchases;
                           track purchaseOrderStateInfo
                        ) {
                           @if (purchaseOrderStateInfo) {
                              <div (click)="popPoComponent(purchaseOrderStateInfo.poID)">
                                 <div class="lim-ui-list-item">
                                    <i
                                       class="fa-solid fa-cart-shopping fa-fw"
                                       style="margin-right: 4px"
                                    ></i>

                                    <a class="cursor"
                                       >{{ lang().PO }} -
                                       {{ purchaseOrderStateInfo.poNumber }}</a
                                    >
                                    - {{ purchaseOrderStateInfo.name }}

                                    @if (purchaseOrderStateInfo.state == 101) {
                                       <span class="red-color">
                                          <i class="fa-solid fa-thumbs-down fa-fw"></i>
                                          <span
                                             [limbleHtml]="
                                                purchaseOrderStateInfo.requestDisapproveReason
                                             "
                                          ></span>
                                       </span>
                                    }
                                 </div>
                              </div>
                           }
                        }

                        <div style="clear: both"></div>
                        <hr />
                     </div>
                  }

                  <lim-ui-panel>
                     <div class="instructions-panel">
                        <header>
                           <div class="top">
                              <h3>{{ lang().Instructions }}</h3>

                              <div
                                 style="margin-bottom: 10px; float: right"
                                 [hidden]="
                                    !(
                                       (taskEditable() && !task.checklistUserCompleted) ||
                                       tempEdit
                                    )
                                 "
                                 class="hidePrint"
                              >
                                 <!--edit instructions-->
                                 <ngx-skeleton-loader
                                    count="1"
                                    [theme]="{
                                       'width': '100px',
                                       'height': '25px',
                                       'border-radius': '15px',
                                       'margin-left': '2px',
                                       'border': '0px',
                                    }"
                                 />
                              </div>
                           </div>
                        </header>

                        <!--sample instructions-->
                        <ngx-skeleton-loader
                           count="1"
                           [theme]="skeletonThemes.regular"
                        /><br />
                        <ngx-skeleton-loader
                           count="1"
                           [theme]="skeletonThemes.regular"
                        /><br />
                        <ngx-skeleton-loader
                           count="1"
                           [theme]="skeletonThemes.regular"
                        /><br />
                        <ngx-skeleton-loader count="1" [theme]="skeletonThemes.short" />
                     </div>
                  </lim-ui-panel>

                  <hr />
                  <!--buttons between comments and instructions-->
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonLong"
                  />
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonRegular"
                  />
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonLong"
                  />
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonRegular"
                  />
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonRegular"
                  />
                  <br />
                  <span
                     style="margin-top: 10px"
                     [hidden]="!!EditingWOTemplateInstanceBeforeLive"
                  >
                     <hr />
                     <b>{{ lang().Comments }}:</b>

                     @if (
                        getTaskNoteIDs(task).length > 0 && task.checklistTemplate == 0
                     ) {
                        <div style="margin-top: 10px">
                           <!--sample comments-->
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.short"
                           />
                        </div>
                     }

                     <div
                        [hidden]="!getTaskNoteIDs(task)?.length"
                        style="height: 10px; clear: both"
                     ></div>

                     <!--add comment button-->
                     <ngx-skeleton-loader
                        count="1"
                        class="floatRight"
                        [theme]="{
                           'width': '80px',
                           'height': '25px',
                           'border-radius': '15px',
                           'border': '0px',
                        }"
                     />

                     <div style="clear: both"></div>

                     <div
                        [hidden]="!getTaskNoteIDs(task)?.length"
                        style="height: 5px"
                     ></div>
                     <hr />
                  </span>

                  @if (task.checklistTemplate !== 6) {
                     <div
                        style="margin-top: 10px"
                        [hidden]="!!EditingWOTemplateInstanceBeforeLive"
                        (click)="completionNotesError = false"
                     >
                        <b [ngClass]="{ redColor: completionNotesError }"
                           >{{ lang().CompletionNotes }}:</b
                        >
                        <div style="height: 10px"></div>
                        <!--completion notes-->
                        <ngx-skeleton-loader count="1" [theme]="skeletonThemes.large" />
                     </div>
                  }

                  <div
                     style="margin-top: 20px"
                     [hidden]="!!EditingWOTemplateInstanceBeforeLive"
                  >
                     <!--reset button-->
                     <ngx-skeleton-loader
                        count="1"
                        class="floatRight"
                        [theme]="{
                           'width': '80px',
                           'height': '25px',
                           'border-radius': '15px',
                           'border': '0px',
                        }"
                     />

                     <span>
                        <ngx-skeleton-loader
                           count="1"
                           [theme]="{
                              'width': '20%',
                              'height': '25px',
                              'border-radius': '15px',
                              'border': '0px',
                           }"
                        />
                     </span>
                  </div>
                  <div style="clear: both; height: 10px"></div>
               </div>
            </div>
         }

         <ng-template #skeleton>
            <div class="skeleton">
               <div class="locationAddressAndName">
                  <div class="printViewShow"></div>
                  <div style="clear: both"></div>
               </div>

               @if (task.checklistTemplate == 0 && task.checklistDepreciated) {
                  <div>
                     <div class="alert alert-warning unfortunatelyTaskWasDeleted">
                        <span class="ng-binding">{{
                           lang().UnfortunatelyThisTaskWasDeleted
                        }}</span>
                     </div>
                  </div>
               }

               <div>
                  @if (task.checklistTemplate !== 6) {
                     <span [ngClass]="{ hidePrint: customerID == 944 }">
                        <div
                           class="alert alert-success"
                           [hidden]="!task.checklistUserCompleted"
                        >
                           <i
                              class="fa-regular fa-square-check greenColor taskBeenCompleted"
                           ></i>
                           {{ lang().thisTaskHasBeenCompleted }}
                        </div>

                        <h5>
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="{ width: '20%', height: '25px', border: '0px' }"
                           /><br />

                           <div
                              class="dropdown hidePrint shareDropdownLarge floatRight"
                              [hidden]="
                                 !(
                                    (!EditingWOTemplateInstanceBeforeLive &&
                                       shareTasksCred) ||
                                    currentUser.workOrderUser == 1
                                 )
                              "
                           >
                              <ngx-skeleton-loader
                                 count="1"
                                 class="floatRight"
                                 [theme]="{
                                    'width': '60px',
                                    'height': '25px',
                                    'border-radius': '15px',
                                    'border': '0px',
                                 }"
                              />
                           </div>

                           <div style="clear: both"></div>

                           <br />

                           <br class="mobileBR" />

                           <ngx-skeleton-loader count="1" [theme]="skeletonThemes.long" />

                           <br class="mobileBRHide" />

                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />

                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />

                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />

                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           />
                        </h5>

                        <div style="clear: both"></div>

                        <span>
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           />
                           <br />
                        </span>

                        <span>
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           />
                        </span>
                        <span>
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           />
                        </span>

                        @if (getTaskInvoices(task).length > 0 && viewInvoicesCred) {
                           <span>
                              <hr />
                              <b>{{ lang().Invoices }}:</b>

                              @if (tempEdit) {
                                 <div
                                    class="btn-raised btn btn-orange cursor"
                                    (click)="addInvoice()"
                                    style="margin-right: 0px; float: right"
                                    placement="left"
                                    limUiTooltip="{{ lang().editInvoiceTooltip }}"
                                 >
                                    <i class="fa-solid fa-solid fa-wrench"></i>

                                    @if (task.checklistTemplate == 0) {
                                       <span
                                          style="margin-left: 5px"
                                          class="mobileDisappear"
                                          >{{ lang().EditInvoiceBtn }}</span
                                       >
                                    }
                                 </div>
                              }
                              <br />
                           </span>
                        }

                        <div [hidden]="!(getTaskPartRelationIDs(task).length > 0)">
                           <hr />
                           <b [hidden]="!(task.checklistStatusID == 0)"
                              >{{ lang().PartsYouMayNeed }}:</b
                           >
                           <b [hidden]="!task.checklistStatusID"
                              >{{ lang().PartsThatWereUsed }}:</b
                           ><br />

                           <!--must keep track by $index in so that when adding parts that are simliar we don't get dups.  Rare place where we have to keep it-->
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.large"
                           />
                        </div>
                        <div style="clear: both"></div>
                        <hr />
                        <!-- Asset information section -->
                        @if (asset() && displayAssetInfo()) {
                           <div>
                              <!-- on open tasks -->
                              <ngx-skeleton-loader
                                 count="1"
                                 [theme]="skeletonThemes.large"
                              />
                              <!-- on closed tasks -->
                              @if (
                                 task.checklistStatusID &&
                                 assetInfoFromCompletionArr.length
                              ) {
                                 <div>
                                    <b
                                       >{{
                                          lang().AssetInformationWhenTaskWasCompleted
                                       }}:</b
                                    >
                                 </div>
                              }
                              <hr />
                           </div>
                        }
                     </span>
                  }

                  @if (
                     task.checklistTemplate !== 6 &&
                     requestedPurchases &&
                     requestedPurchases.size > 0
                  ) {
                     <div>
                        <b>{{ lang().PurchaseRequests }}:</b>
                        @for (
                           purchaseOrderStateInfo of requestedPurchases;
                           track purchaseOrderStateInfo
                        ) {
                           @if (purchaseOrderStateInfo) {
                              <div (click)="popPoComponent(purchaseOrderStateInfo.poID)">
                                 <div class="lim-ui-list-item">
                                    <i
                                       class="fa-solid fa-cart-shopping fa-fw"
                                       style="margin-right: 4px"
                                    ></i>

                                    <a class="cursor"
                                       >{{ lang().PO }} -
                                       {{ purchaseOrderStateInfo.poNumber }}</a
                                    >
                                    - {{ purchaseOrderStateInfo.name }}

                                    @if (purchaseOrderStateInfo.state == 101) {
                                       <span class="red-color">
                                          <i class="fa-solid fa-thumbs-down fa-fw"></i>
                                          <span
                                             [limbleHtml]="
                                                purchaseOrderStateInfo.requestDisapproveReason
                                             "
                                          ></span>
                                       </span>
                                    }
                                 </div>
                              </div>
                           }
                        }

                        <div style="clear: both"></div>
                        <hr />
                     </div>
                  }

                  <lim-ui-panel>
                     <div class="instructions-panel">
                        <header>
                           <div class="top">
                              <h3>{{ lang().Instructions }}</h3>

                              <div
                                 style="margin-bottom: 10px; float: right"
                                 [hidden]="
                                    !(
                                       (taskEditable() && !task.checklistUserCompleted) ||
                                       tempEdit
                                    )
                                 "
                                 class="hidePrint"
                              >
                                 <!--edit instructions-->
                                 <ngx-skeleton-loader
                                    count="1"
                                    [theme]="{
                                       'width': '100px',
                                       'height': '25px',
                                       'border-radius': '15px',
                                       'margin-left': '2px',
                                       'border': '0px',
                                    }"
                                 />
                              </div>
                           </div>
                        </header>

                        <!--sample instructions-->
                        <ngx-skeleton-loader
                           count="1"
                           [theme]="skeletonThemes.regular"
                        /><br />
                        <ngx-skeleton-loader
                           count="1"
                           [theme]="skeletonThemes.regular"
                        /><br />
                        <ngx-skeleton-loader
                           count="1"
                           [theme]="skeletonThemes.regular"
                        /><br />
                        <ngx-skeleton-loader count="1" [theme]="skeletonThemes.short" />
                     </div>
                  </lim-ui-panel>

                  <hr />
                  <!--buttons between comments and instructions-->
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonLong"
                  />
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonRegular"
                  />
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonLong"
                  />
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonRegular"
                  />
                  <ngx-skeleton-loader
                     count="1"
                     class="floatRight"
                     [theme]="skeletonThemes.buttonRegular"
                  />
                  <br />
                  <span
                     style="margin-top: 10px"
                     [hidden]="!!EditingWOTemplateInstanceBeforeLive"
                  >
                     <hr />
                     <b>{{ lang().Comments }}:</b>

                     @if (
                        getTaskNoteIDs(task).length > 0 && task.checklistTemplate === 0
                     ) {
                        <div style="margin-top: 10px">
                           <!--sample comments-->
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.regular"
                           /><br />
                           <ngx-skeleton-loader
                              count="1"
                              [theme]="skeletonThemes.short"
                           />
                        </div>
                     }

                     <div
                        [hidden]="!getTaskNoteIDs(task)?.length"
                        style="height: 10px; clear: both"
                     ></div>

                     <!--add comment button-->
                     <ngx-skeleton-loader
                        count="1"
                        class="floatRight"
                        [theme]="{
                           'width': '80px',
                           'height': '25px',
                           'border-radius': '15px',
                           'border': '0px',
                        }"
                     />

                     <div style="clear: both"></div>

                     <div
                        [hidden]="!getTaskNoteIDs(task)?.length"
                        style="height: 5px"
                     ></div>
                     <hr />
                  </span>

                  @if (task.checklistTemplate !== 6) {
                     <div
                        style="margin-top: 10px"
                        [hidden]="!!EditingWOTemplateInstanceBeforeLive"
                        (click)="completionNotesError = false"
                     >
                        <b [ngClass]="{ redColor: completionNotesError }"
                           >{{ lang().CompletionNotes }}:</b
                        >
                        <div style="height: 10px"></div>
                        <!--completion notes-->
                        <ngx-skeleton-loader count="1" [theme]="skeletonThemes.large" />
                     </div>
                  }

                  <div
                     style="margin-top: 20px"
                     [hidden]="!!EditingWOTemplateInstanceBeforeLive"
                  >
                     <!--reset button-->
                     <ngx-skeleton-loader
                        count="1"
                        class="floatRight"
                        [theme]="{
                           'width': '80px',
                           'height': '25px',
                           'border-radius': '15px',
                           'border': '0px',
                        }"
                     />

                     <span>
                        <ngx-skeleton-loader
                           count="1"
                           [theme]="{
                              'width': '20%',
                              'height': '25px',
                              'border-radius': '15px',
                              'border': '0px',
                           }"
                        />
                     </span>
                  </div>
                  <div style="clear: both; height: 10px"></div>
               </div>
            </div>
         </ng-template>

         <div
            class="task-footer"
            [ngClass]="{
               'is-single-task': data.isSingleTask,
               'is-mobile': isMobile,
               'hide-when-limited-height': isLimitedHeight$ | async,
               'focus': isCommentNoteFocused,
               'fab-backdrop-container': showFab,
            }"
         >
            <div [ngClass]="{ 'fab-backdrop': showFab }" (click)="showFab = false"></div>

            @if (task.checklistTemplate !== 6) {
               <div [hidden]="!!EditingWOTemplateInstanceBeforeLive">
                  <span
                     [hidden]="
                        !(
                           task.checklistUserCompleted === 0 &&
                           (taskEditable() || currentUser.workOrderUser === 1) &&
                           limited() == false
                        )
                     "
                     class="complete-resume-button-set"
                  >
                     @if (currentUser.workOrderUser === 1) {
                        <button
                           (click)="completeExternalUser()"
                           class="complete-button"
                           icon="circleCheckRegular"
                        >
                           <lim-ui-icon
                              icon="circleCheck"
                              iconSize="small"
                              iconColor="success"
                           />
                           <span class="lim-ui-hide-on-mobile">{{ lang().Finish }}</span>
                        </button>
                     }
                     @if (currentUser.workOrderUser === 1) {
                        <lim-ui-secondary-button
                           (click)="exitExternalUser()"
                           class="resume-later"
                        >
                           {{ lang().ResumeLater }}
                        </lim-ui-secondary-button>
                     }
                     @if (currentUser.workOrderUser === 1) {
                        <lim-ui-primary-button
                           [icon]="'dollarSign'"
                           class="external-user-add-invoice-button"
                           (click)="addInvoice()"
                           [limUiTooltip]="
                              getTaskInvoices(task).length === 0
                                 ? lang().AddInvoiceTooltip
                                 : lang().editInvoiceTooltip
                           "
                        >
                           {{
                              getTaskInvoices(task).length
                                 ? lang().EditInvoices
                                 : lang().AddInvoice
                           }}
                        </lim-ui-primary-button>
                     }
                     @if (currentUser.workOrderUser !== 1) {
                        <button
                           (click)="complete()"
                           data-test="completeTskBtn"
                           class="complete-button"
                           data-test-id="taskCompleteButton"
                        >
                           <lim-ui-icon
                              icon="circleCheck"
                              [iconSize]="isMobile ? 'medium' : 'small'"
                              iconColor="success"
                           />
                           <span class="lim-ui-hide-on-mobile">{{
                              lang().Complete
                           }}</span>
                        </button>
                     }
                  </span>
                  @if (tempEdit) {
                     <button
                        (click)="tempEditTask()"
                        data-test="completeEditTskBtn"
                        class="complete-button"
                     >
                        <lim-ui-icon
                           icon="circleCheck"
                           [iconSize]="isMobile ? 'medium' : 'small'"
                           iconColor="success"
                        />
                        <span class="lim-ui-hide-on-mobile">{{
                           lang().FinishEditing
                        }}</span>
                     </button>
                  }
               </div>
            }

            @if (showTimer()) {
               @if (taskOpen) {
                  <task-timer
                     [taskID]="task.checklistID"
                     [estimatedTime]="task.checklistEstTime ?? 0"
                     (stopped)="logTimeOnTask($event)"
                     [hidden]="!featureTimeCost"
                  />
               } @else if (featureTimeCost) {
                  <task-timer-static
                     [actualTime]="task.checklistPromptTime ?? 0"
                     [estimatedTime]="task.checklistEstTime ?? 0"
                  />
               }
            }

            @if (
               task.checklistTemplate !== 6 &&
               !EditingWOTemplateInstanceBeforeLive &&
               !(data.preview === true) &&
               currentUser.workOrderUser !== 1
            ) {
               <div class="action-buttons">
                  <div
                     class="fab-toggle"
                     (click)="toggleFab()"
                     [ngClass]="taskOpen ? '' : 'completed'"
                     data-test-id="taskToggleFab"
                  >
                     <span
                        class="toggle-icon"
                        [ngClass]="showFab && taskOpen ? 'rotate-toggle' : ''"
                     >
                        <lim-ui-icon
                           [icon]="taskOpen ? 'plus' : 'wrench'"
                           iconSize="medium-large"
                           iconColor="white"
                        />
                     </span>
                  </div>
                  @if (showFab) {
                     <div class="fab-button-group" [@fabAnimation]="showFab">
                        @if (
                           ((featureUnlimitedParts ||
                              featureLimitedNumber ||
                              superUser) &&
                              task.checklistUserCompleted === 0 &&
                              taskEditable() &&
                              currentUser.workOrderUser !== 1 &&
                              addPartsToOpenTasksCred === true) ||
                           tempEdit
                        ) {
                           <button
                              class="fab-button"
                              (click)="addPart(true)"
                              placement="left"
                              limUiTooltip="{{ lang().AddPart }}"
                              [limUiPopover]="startPartsPopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverPlacement]="'bottom'"
                              [limUiPopoverHidden]="
                                 featureUnlimitedParts || featureLimitedNumber
                              "
                              data-test-id="taskAddPartButton"
                           >
                              <lim-ui-icon
                                 icon="gearsRegular"
                                 iconSize="medium"
                                 [ngClass]="{
                                    paywalled:
                                       !featureUnlimitedParts && !featureLimitedNumber,
                                 }"
                              />
                              <div
                                 class="fab-button-text"
                                 [ngClass]="{
                                    paywalled:
                                       !featureUnlimitedParts && !featureLimitedNumber,
                                 }"
                              >
                                 {{ lang().AddPart }}
                              </div>
                              @if (
                                 !featureUnlimitedParts &&
                                 !featureLimitedNumber &&
                                 isMobile
                              ) {
                                 <lim-ui-badge icon="stars" />
                              }
                              <ng-template #startPartsPopover>
                                 <upsell-popover
                                    [message]="lang().FeatureUnlimitedPartsPopUpMessage"
                                    [pricingLinkText]="lang().LearnMore"
                                 />
                              </ng-template>
                           </button>
                           <hr class="fab-button-hr" />
                        }

                        @if (
                           viewInvoicesCred &&
                           ((task.checklistUserCompleted === 0 && taskEditable()) ||
                              tempEdit)
                        ) {
                           <button
                              class="fab-button"
                              (click)="addInvoice(); toggleFab()"
                              placement="left"
                              [limUiTooltip]="
                                 getTaskInvoices(task).length
                                    ? lang().editInvoiceTooltip
                                    : lang().AddInvoiceTooltip
                              "
                              data-test-id="taskAddInvoiceButton"
                           >
                              <lim-ui-icon icon="dollarSign" iconSize="medium" />
                              <div class="fab-button-text">
                                 {{
                                    getTaskInvoices(task).length
                                       ? lang().EditInvoices
                                       : lang().AddInvoice
                                 }}
                              </div>
                           </button>
                           <hr class="fab-button-hr" />
                        }

                        @if (
                           (featureUnlimitedPOs || featureLimitedNumber || superUser) &&
                           ((task.checklistUserCompleted === 0 && taskEditable()) ||
                              tempEdit) &&
                           task.checklistTemplate === 0 &&
                           currentUser.workOrderUser !== 1 &&
                           requestPurchaseCred === true
                        ) {
                           <button
                              class="fab-button"
                              (click)="requestPurchase()"
                              placement="left"
                              limUiTooltip="{{ lang().RequestPurchase }}"
                              [limUiPopover]="startRequestPurchasePopover"
                              [limUiPopoverType]="'upsell'"
                              [limUiPopoverPlacement]="'bottom'"
                              [limUiPopoverHidden]="
                                 featureUnlimitedPOs || featureLimitedNumber
                              "
                              data-test-id="taskRequestPurchaseButton"
                           >
                              <lim-ui-icon
                                 icon="cartShopping"
                                 iconSize="medium"
                                 [ngClass]="{
                                    paywalled:
                                       !featureUnlimitedPOs && !featureLimitedNumber,
                                 }"
                              />
                              <div
                                 class="fab-button-text"
                                 [ngClass]="{
                                    paywalled:
                                       !featureUnlimitedPOs && !featureLimitedNumber,
                                 }"
                              >
                                 {{ lang().RequestPurchase }}
                              </div>

                              @if (
                                 !featureUnlimitedPOs && !featureLimitedNumber && isMobile
                              ) {
                                 <lim-ui-badge icon="stars" />
                              }
                              <ng-template #startRequestPurchasePopover>
                                 <upsell-popover
                                    [message]="lang().FeatureUnlimitedPOsMessage"
                                    [pricingLinkText]="lang().LearnMore"
                                 />
                              </ng-template>
                           </button>

                           <hr class="fab-button-hr" />
                        }

                        @if (
                           (featureUnlimitedPOs || featureLimitedNumber || superUser) &&
                           ((task.checklistUserCompleted === 0 && taskEditable()) ||
                              tempEdit) &&
                           task.checklistTemplate === 0 &&
                           currentUser.workOrderUser !== 1 &&
                           startPOCred === true
                        ) {
                           @if (featureUnlimitedPOs || featureLimitedNumber) {
                              <button
                                 class="fab-button"
                                 placement="left"
                                 limUiTooltip="{{ lang().StartPO }}"
                                 [limUiPopover]="startPoPopover"
                                 [limUiPopoverPlacement]="'left-end'"
                                 [limUiPopoverCloseOnClick]="true"
                                 data-test-id="taskStartPoButton"
                              >
                                 <lim-ui-icon
                                    icon="filePowerpointRegular"
                                    iconSize="medium"
                                 />
                                 <div class="fab-button-text">{{ lang().StartPO }}</div>
                                 <ng-template #startPoPopover>
                                    <div class="start-po-popover">
                                       <div
                                          class="popover-item"
                                          (click)="prepStartPOForPart(); toggleFab()"
                                       >
                                          <div [ngClass]="{ paywalled: !canAddPOs }">
                                             {{ lang().StartPOForAPart }}
                                          </div>
                                          @if (!canAddPOs) {
                                             <lim-ui-badge icon="stars" />
                                          }
                                       </div>
                                       <lim-ui-dropdown-divider
                                          class="lim-ui-show-on-mobile"
                                       />
                                       <div
                                          class="popover-item"
                                          (click)="prepStartPOForService(); toggleFab()"
                                       >
                                          <div [ngClass]="{ paywalled: !canAddPOs }">
                                             {{ lang().StartPOForAService }}
                                          </div>
                                          @if (!canAddPOs) {
                                             <lim-ui-badge icon="stars" />
                                          }
                                       </div>
                                       <lim-ui-dropdown-divider
                                          class="lim-ui-show-on-mobile"
                                       />
                                       <div
                                          class="popover-item"
                                          (click)="prepStartPOForOther(); toggleFab()"
                                       >
                                          <div [ngClass]="{ paywalled: !canAddPOs }">
                                             {{ lang().StartPOForAOther }}
                                          </div>

                                          @if (!canAddPOs) {
                                             <lim-ui-badge icon="stars" />
                                          }
                                       </div>
                                    </div>
                                 </ng-template>
                              </button>
                              <hr class="fab-button-hr" />
                           } @else {
                              <button
                                 class="fab-button"
                                 placement="left"
                                 limUiTooltip="{{ lang().StartPO }}"
                                 [limUiPopover]="disabledStartPOPopover"
                                 [limUiPopoverType]="'upsell'"
                                 [limUiPopoverPlacement]="'bottom'"
                                 [limUiPopoverHidden]="
                                    featureUnlimitedPOs || featureLimitedNumber
                                 "
                                 data-test-id="taskStartPoButton"
                              >
                                 <lim-ui-icon
                                    icon="filePowerpointRegular"
                                    iconSize="medium"
                                    class="paywalled"
                                 />
                                 <div class="fab-button-text paywalled">
                                    {{ lang().StartPO }}
                                 </div>

                                 @if (
                                    !featureUnlimitedPOs &&
                                    !featureLimitedNumber &&
                                    isMobile
                                 ) {
                                    <lim-ui-badge icon="stars" />
                                 }
                                 <ng-template #disabledStartPOPopover>
                                    <upsell-popover
                                       [message]="lang().FeatureUnlimitedPOsMessage"
                                       [pricingLinkText]="lang().LearnMore"
                                    />
                                 </ng-template>
                              </button>
                              <hr class="fab-button-hr" />
                           }
                        }

                        @if (
                           (task.checklistUserCompleted === 0 &&
                              taskEditable() &&
                              limited() === false &&
                              currentUser.workOrderUser !== 1 &&
                              !featureDisableTimeLogging) ||
                           tempEdit
                        ) {
                           <button
                              class="fab-button"
                              (click)="logTimeOnTask(); toggleFab()"
                              placement="left"
                              limUiTooltip="{{ lang().LogTimeTooltip }}"
                              [disabled]="preventDoubleClick"
                              [hidden]="!featureTimeCost"
                           >
                              <lim-ui-icon icon="clockRegular" iconSize="medium" />
                              <div class="fab-button-text">{{ lang().LogTime }}</div>
                           </button>
                           <hr class="fab-button-hr" />
                        }

                        @if (
                           featureAssetTools &&
                           task.checklistCompletedDate === 0 &&
                           taskEditable() &&
                           !limited() &&
                           currentUser.workOrderUser !== 1 &&
                           assetCheckInOut
                        ) {
                           <button
                              class="fab-button disableOffline"
                              (click)="addToolToTask(); toggleFab()"
                              placement="left"
                              [disabled]="preventDoubleClick"
                              limUiTooltip="{{ lang().AddToolTooltip }}"
                           >
                              <lim-ui-icon
                                 icon="screwdriverWrenchRegular"
                                 iconSize="medium"
                              />
                              <div class="fab-button-text">{{ lang().AddTool }}</div>
                           </button>
                           <hr class="fab-button-hr" />
                        }

                        @if (showMergeWorkRequestButton()) {
                           <button
                              class="fab-button"
                              placement="left"
                              limUiTooltip="{{
                                 lang().MergeAnotherWorkRequestIntoThisWorkRequestTooltip
                              }}"
                              (click)="mergeWorkRequest(); toggleFab()"
                           >
                              <lim-ui-icon icon="compressRegular" iconSize="medium" />
                              <div class="fab-button-text">{{ lang().MergeTask }}</div>
                           </button>
                           <hr class="fab-button-hr" />
                        }

                        @if (editCompletedTaskCred && task.checklistStatusID === 1) {
                           <button
                              class="fab-button"
                              placement="left"
                              limUiTooltip="{{
                                 itemsEditable()
                                    ? lang().FinishEditing
                                    : lang().editCompletedTaskTooltip
                              }}"
                              (click)="tempEditTask(); toggleFab()"
                           >
                              <lim-ui-icon icon="wrenchRegular" iconSize="medium" />
                              <div class="fab-button-text">
                                 {{
                                    itemsEditable()
                                       ? lang().FinishEditing
                                       : lang().editCompletedTaskTooltip
                                 }}
                              </div>
                           </button>
                           <hr class="fab-button-hr" />
                           <button
                              class="fab-button"
                              placement="left"
                              limUiTooltip="{{ lang().reopenCompletedTaskTooltip }}"
                              (click)="reopenCompletedTask(); toggleFab()"
                           >
                              <lim-ui-icon icon="unlockRegular" iconSize="medium" />
                              <div class="fab-button-text">
                                 {{ lang().ReopenCompletedTask }}
                              </div>
                           </button>
                        }

                        @if (showRecreateWorkRequestButton()) {
                           <button
                              class="fab-button"
                              placement="left"
                              limUiTooltip="{{
                                 lang().RecreateWorkRequestWithADifferentTemplateTooltip
                              }}"
                              (click)="recreateWorkRequest(); toggleFab()"
                           >
                              <lim-ui-icon icon="arrowsRotate" iconSize="medium" />
                              <div class="fab-button-text">
                                 {{ lang().RecreateWorkRequest }}
                              </div>
                           </button>
                           <hr class="fab-button-hr" />
                        }

                        <ng-container>
                           @if (
                              ((task.checklistUserCompleted === 0 && taskEditable()) ||
                                 tempEdit) &&
                              task.checklistBatchID !== 20501 &&
                              showMachineDownAlert &&
                              currentUser.workOrderUser !== 1
                           ) {
                              <button
                                 class="fab-button"
                                 placement="left"
                                 limUiTooltip="{{ showMachineDownAlertButtonText }}"
                                 (click)="machineDownAlert(); toggleFab()"
                              >
                                 <lim-ui-icon icon="envelopeRegular" iconSize="medium" />
                                 <div class="fab-button-text">
                                    {{ showMachineDownAlertButtonText }}
                                 </div>
                              </button>
                           }
                           <hr class="fab-button-hr" />
                        </ng-container>
                     </div>
                  }
               </div>
            }
         </div>

         @let taskPrintData = printData();
         @let taskVM = taskViewModel();
         @if (
            taskPrintData &&
            taskVM &&
            ((treeSize$ && (treeSize$ | async) <= TASK_ITEM_LIMIT_FOR_PRINT) ||
               items()?.length === 0)
         ) {
            <task-print-template
               #taskPrintTemplate
               id="task-print{{ taskVM.checklistID }}"
               [taskId]="taskVM.checklistID"
               [checklistTemplate]="taskVM.checklistTemplate ?? 0"
               [taskInstructions]="taskPrintData?.items ?? []"
               [displayAssetInfo]="displayAssetInfo()"
               [taskInfo]="taskPrintData?.info"
               [taskInstructionsViewParameters]="taskInstructionData()"
            />
         }
      </div>
   }
}
