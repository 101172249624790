import type { TaskEntityType } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";

export type VirtualWidgetFilters = Partial<{
   assetIDs: Array<number>;
   locationIDs: Array<number>;
   taskTypes: Array<TaskEntityType>;
   taskStatuses: Array<number>;
   completedStart: Date;
   completedEnd: Date;
   dueStart: Date;
   dueEnd: Date;
   completedBy: string;
}>;

export const VirtualWidgets = [
   "totalOperatingCost",
   "totalPartsCost",
   "totalLaborCost",
   "totalInvoiceCost",
   "downtimeCount",
   "mttr",
   "mtbf",
   "plannedVsUnplanned",
   "plannedVsUnplannedMonthlyLine",
   "workRequests",
   "unplannedMaintenance",
   "plannedMaintenance",
   "operatingCostsList",
   "operatingCostsMonthlyLine",
   "operatingCostsByCostTypePie",
   "operatingCostsByLocationPie",
   "critical",
   "overdue",
   "onTime",
   "onTimeVsOverdueVsCriticalMonthlyLine",
   "tasksCompleted",
   "timeSpent",
   "downtimeHours",
   "partsUsed",
   "countInvoices",
   "downtimeHoursEfficiency",
   "laborCostEfficiency",
   "partCostEfficiency",
   "costOfPOsEfficiency",
   "downtimeHoursEfficiencyLine",
   "laborCostEfficiencyLine",
   "partCostEfficiencyLine",
   "costOfPOsEfficiencyLine",
] as const;

export type VirtualWidgetID = (typeof VirtualWidgets)[number];
