import { z } from "zod";

export const calendarNoteDtoSchema = z.object({
   calendarNoteID: z.number(),
   noteTitle: z.string().nullable(),
   noteDate: z.number(),
   noteDateEnd: z.number(),
   locationID: z.number(),
   userID: z.number().nullable(),
   color: z.string().nullable(),
   relations: z.array(
      z.object({
         userID: z.number(),
      }),
   ),
});

export type CalendarNoteDto = z.infer<typeof calendarNoteDtoSchema>;
