import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   IconButtonComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import { lastValueFrom } from "rxjs";
import { FileListItem } from "src/app/files/components/fileListItem/fileListItem.element.component";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { EscapePipe } from "src/app/shared/pipes/customEscape.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "add-invoice-to-template",
   templateUrl: "./add-invoice-to-template.component.html",
   styleUrls: ["./add-invoice-to-template.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      FormsModule,
      ContenteditableDirective,
      FileUploader,
      FileListItem,
      ModalFooterComponent,
      IconButtonComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      SecondaryButtonComponent,
      BetterDecimalPipe,
      EscapePipe,
   ],
})
export class AddInvoiceToTemplateComponent implements OnInit {
   public currencySymbol;
   public resolve;
   public message;
   public title;
   public template: TaskTemplateEntity | undefined;
   public invoiceIndex: Array<any> = [];
   public errorMsg;
   public customerID;
   public uploadObj;
   public modalInstance;
   private readonly axios = axios;

   private readonly modalService = inject(ModalService);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly managePO = inject(ManagePO);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.initialize();
   }

   private async initialize(): Promise<void> {
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;

      this.message = data.message;
      this.title = data.title;
      this.invoiceIndex = this.manageInvoice.getInvoicesIndex();

      await this.setInvoicesAndTemplate(data.checklistID);

      if (this.template === undefined) {
         console.error("No task exists for this ID.");
      }
      if (this.template?.invoices.length === 0) {
         this.addInvoice();
      }
   }

   private async setInvoicesAndTemplate(checklistID: number) {
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      this.template = await lastValueFrom(
         this.taskTemplatesApiService.getById(checklistID, {
            columns: "invoices",
         }),
      );
      this.loadingBarService.remove();
      this.errorMsg = "";
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;

      if (this.template.invoices.length > 0) {
         for (const invoice of this.template.invoices) {
            const localInvoice = this.invoiceIndex[invoice.invoiceID];
            this.addFileUploader(localInvoice);
            const po = this.managePO.getPurchaseOrderFromPurchaseOrderItem(
               localInvoice.poItemID,
            );
            if (po) {
               localInvoice.poNumber = po.poNumber;
               localInvoice.poID = po.poID;
            }

            localInvoice.invoiceDescriptionOld = localInvoice.invoiceDescription;
            localInvoice.fileName = localInvoice.invoiceFileName;
         }
      }
   }

   protected async removeInvoice(poItemID: number, invoiceID: number): Promise<void> {
      if (this.template === undefined) {
         throw new Error("Template does not exist.");
      }
      if (poItemID > 0) {
         this.alertService.addAlert(
            this.lang().WhoopsYouMustRemoveThePOItemToGetRidOfThisInvoice,
            "danger",
            4000,
         );
         return;
      }
      await this.manageInvoice.removeInvoice(invoiceID);
      await this.setInvoicesAndTemplate(this.template.checklistID);
   }

   protected async addInvoice(): Promise<void> {
      if (this.template === undefined) {
         throw new Error("Template does not exist.");
      }
      await this.manageInvoice.addInvoice(this.template.checklistID);
      await this.setInvoicesAndTemplate(this.template.checklistID);
   }

   protected updateInvoiceCost(invoiceID: number, invoiceCost: number): void {
      this.manageInvoice.updateInvoiceCost(invoiceID, invoiceCost).then((answer) => {
         if (answer.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   updateInvoiceDescription = (invoice) => {
      if (invoice.invoiceDescriptionOld != invoice.invoiceDescription) {
         this.manageInvoice.updateInvoiceDescription(invoice).then((answer) => {
            if (answer.data.success == true) {
               invoice.invoiceDescription = answer.data.invoiceDescription;
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               invoice.invoiceDescriptionOld = invoice.invoiceDescription;
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      }
   };

   popPoComponent = (poID) => {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   };

   addFileUploader = (invoice) => {
      invoice.uploadObj = {};
      invoice.uploadObj.primaryID = "fileName";
      invoice.uploadObj.deleteData = {};
      invoice.uploadObj.onUploadPopEditor = false;
      invoice.uploadObj.noPreview = true;
      invoice.uploadObj.maxFiles = 1;
      invoice.uploadObj.posturl = `phpscripts/checklistManager.php?action=makeFileInvoice&checklistID=${invoice.checklistID}&invoiceID=${invoice.invoiceID}`;

      invoice.uploadObj.deleteCall = () => {
         //leave empty in case we need to do a delete later
      };

      invoice.uploadObj.uploadCall = async (posturl, formData) => {
         return this.axios({
            url: posturl,
            method: "POST",
            data: formData,
         });
      };

      invoice.uploadObj.uploadComplete = (result) => {
         assert(this.template);
         if (result == undefined) {
            result.invoiceID = -1;
         }
         for (const templateInvoice of this.template.invoices) {
            if (templateInvoice.invoiceID == result.invoiceID) {
               const localInvoice = this.invoiceIndex[templateInvoice.invoiceID];
               localInvoice.invoiceFileName = result.fileName;
               localInvoice.fileName = result.fileName;

               // get the new file ext
               localInvoice.ext = result.fileName.substr(
                  result.fileName.lastIndexOf(".") + 1,
               );
            }
         }
      };
   };

   close = () => {
      this.modalInstance.close();
   };
}
