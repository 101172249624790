// eslint-disable-next-line max-classes-per-file -- This is temporary until we can finish the feature.
import {
   Component,
   inject,
   input,
   type WritableSignal,
   signal,
   computed,
   type OnInit,
} from "@angular/core";
import {
   MinimalIconButtonComponent,
   ModalComponent,
   LimUiModalRef,
   type ModalResult,
   ModalDirective,
} from "@limblecmms/lim-ui";
import { lastValueFrom } from "rxjs";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { AlertService } from "src/app/shared/services/alert.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import type { TaskDataInput } from "src/app/tasks/components/task-form/task-form.component";
import { AssignmentStepComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-wizard/step-views/assignment-step/assignment-step.component";
import { AssignmentService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-wizard/step-views/assignment-step/assignment.service";
import { RecurrenceStepComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-wizard/step-views/recurrence-step/recurrence-step.component";
import { TaskFormStepComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-wizard/step-views/task-form-step/task-form-step.component";
import { TaskPreviewStepComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-wizard/step-views/task-preview-step/task-preview-step.component";
import { PmBuilderWizardStepperComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-wizard/wizard-stepper/pm-builder-wizard-stepper.component";
import type { PmSuggestionSchedule } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion-schedule/pm-suggestion-schedule";
import type { PickUserOrProfileSelection } from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.component";

@Component({
   selector: "pm-builder-wizard-modal",
   templateUrl: "./pm-builder-wizard-modal.component.html",
   styleUrls: ["./pm-builder-wizard-modal.component.scss"],
   standalone: true,
   imports: [
      PmBuilderWizardStepperComponent,
      ModalComponent,
      ModalDirective,
      MinimalIconButtonComponent,
      TaskFormStepComponent,
      RecurrenceStepComponent,
      AssignmentStepComponent,
      TaskPreviewStepComponent,
   ],
})
export class PmBuilderWizardModalComponent implements ModalResult<void>, OnInit {
   public readonly showWizard = input.required<WritableSignal<boolean>>();
   private readonly alertService = inject(AlertService);
   public readonly modalRef: LimUiModalRef<PmBuilderWizardModalComponent, void> =
      inject(LimUiModalRef);
   protected readonly i18n = inject(TranslationService).i18n;
   private readonly taskTemplateApiService = inject(TaskTemplatesApiService);
   public readonly taskInput = input.required<TaskDataInput>();

   public readonly locationID = input.required<number>();
   public readonly stepNumber = signal<number>(1);
   public readonly maxSteps = 4;
   public taskTemplate: TaskTemplateEntity | undefined;
   public recurrenceID: number | undefined;
   public readonly scheduleSuggestion = input.required<PmSuggestionSchedule>();
   private readonly assignmentService = inject(AssignmentService);
   protected readonly taskInputPreview = computed(() => {
      const taskInput = this.taskInput();
      taskInput.mode = "instance";
      taskInput.editable = false;
      return taskInput;
   });

   public ngOnInit(): void {
      this.getTaskTemplate();
   }

   public async getTaskTemplate(): Promise<void> {
      this.taskTemplate = await lastValueFrom(
         this.taskTemplateApiService.getById(this.taskInput().checklistID, {}),
      );
   }

   protected close() {
      this.modalRef.close();
   }

   public recurrenceDeleted(): void {
      this.recurrenceID = undefined;
   }

   protected async deleteTemplate(): Promise<void> {
      const checklistID = this.taskInput()?.checklistID;

      if (checklistID) {
         const answer = await this.taskTemplateApiService.deleteLegacy(
            checklistID,
            this.locationID(),
         );

         if (answer.data.success === true) {
            this.alertService.addAlert(
               this.i18n().t("PMSuggestionDeleted"),
               "success",
               1000,
            );
         } else {
            this.alertService.addAlert(this.i18n().t("errorMsg"), "danger", 6000);
         }
      }
      this.modalRef.close();
   }

   public setRecurrenceID(recurrenceID: number): void {
      this.recurrenceID = recurrenceID;
   }

   protected nextStep(): void {
      if (this.stepNumber() >= this.maxSteps) {
         console.warn("No more steps");
         return;
      }
      this.stepNumber.update((step) => step + 1);
   }

   protected prevStep(): void {
      if (this.stepNumber() <= 1) {
         console.warn("No more steps");
         return;
      }
      this.stepNumber.update((step) => step - 1);
   }

   protected async saveAssignmentAndContinue(
      selection: PickUserOrProfileSelection,
   ): Promise<void> {
      await this.assignmentService.saveAssignment(
         selection,
         this.taskInput().checklistID,
      );
      this.nextStep();
   }
}
