export enum TempCurrencyCode {
   RUB = 8,
   AFN = 9,
   EUR = 10,
   ALL = 11,
   GBP = 13,
   DZD = 15,
   AOA = 16,
   XCD = 17,
   ARS = 18,
   AMD = 19,
   AWG = 21,
   AUD = 22,
   AZN = 23,
   BSD = 24,
   BHD = 25,
   BDT = 26,
   BYN = 27,
   BZD = 28,
   XOF = 29,
   BMD = 30,
   BTN = 31,
   INR = 32,
   BOB = 33,
   USD = 34,
   BAM = 35,
   BWP = 36,
   BRL = 37,
   SGD = 38,
   BGN = 39,
   CAD = 40,
   CLP = 41,
   CNY = 42,
   COP = 43,
   KMF = 44,
   CRC = 45,
   HRK = 46,
   ANG = 47,
   CZK = 48,
   DKK = 49,
   DJF = 51,
   DOP = 52,
   EGP = 53,
   ERN = 54,
   ETB = 55,
   FKP = 56,
   FJD = 59,
   GMD = 60,
   GEL = 61,
   GHS = 62,
   GIP = 63,
   GTQ = 64,
   HTG = 65,
   HNL = 66,
   HUF = 67,
   IDR = 69,
   IRR = 70,
   IQD = 71,
   ILS = 72,
   JPY = 73,
   JOD = 74,
   KZT = 75,
   KES = 76,
   KPW = 77,
   KRW = 78,
   KWD = 79,
   LAK = 80,
   LBP = 81,
   LSL = 82,
   LYD = 83,
   MKD = 84,
   MGA = 85,
   MWK = 86,
   MYR = 87,
   MVR = 88,
   MRU = 89,
   MNT = 90,
   MAD = 91,
   MMK = 92,
   NIO = 93,
   NGN = 94,
   TRY = 95,
   OMR = 96,
   PYG = 97,
   PHP = 98,
   PLN = 99,
   QAR = 100,
   RON = 101,
   SAR = 103,
   RSD = 104,
   LKR = 107,
   SDG = 108,
   SYP = 109,
   TND = 110,
   AED = 112,
   VUV = 113,
   VND = 114,
   YER = 115,
   THB = 116,
   MZN = 117,
   PKR = 118,
   NZD = 119,
   CHF = 120,
   MXN = 121,
   ZAR = 123,
   GYD = 124,
   PEN = 125,
   JMD = 126,
   NOK = 127,
   TTD = 128,
   XXX = 129,
   SEK = 130,
   HKD = 131,
   BBD = 132,
   TZS = 133,
}
