import { AsyncPipe, NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed, HostBinding } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   BlockIconComponent,
   CheckboxComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   OutlinedButtonComponent,
   PickListPanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   TooltipDirective,
   isPhoneScreenSize$,
} from "@limblecmms/lim-ui";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { ManageLang } from "src/app/languages/services/manageLang";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { AddUser } from "src/app/users/components/addUserModal/addUser.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "pick-user-or-profile",
   templateUrl: "./pickUserOrProfile.modal.component.html",
   styleUrls: ["./pickUserOrProfile.modal.component.scss"],
   imports: [
      AsyncPipe,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PickListPanelComponent,
      LimbleHtmlDirective,
      NgClass,
      ContenteditableDirective,
      FormsModule,
      SelectionControlsComponent,
      SearchBoxComponent,
      NoSearchResults,
      IconComponent,
      BlockIconComponent,
      OutlinedButtonComponent,
      UserImage,
      TooltipDirective,
      CheckboxComponent,
      BasicModalFooterComponent,
      OrderByPipe,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
      TranslateDirective,
   ],
})
export class PickUserOrProfileLegacy implements OnInit {
   public resolve;
   public modalInstance;
   public selected;
   public errorMsg;
   public message;
   public title;
   public locationID;
   public showAuditOptions;
   public advancedOptions;
   public locked;
   public defaultUser;
   public defaultProfile;
   public extraUsers;
   public userClicks;
   public lockable;
   public days;
   public numberCount;
   public users;
   public profiles;
   public userProfiles;
   public allUsers;
   public allProfiles;
   public manageProfileCred;
   protected canAddUsers: boolean = false;
   public searchUsersTeams;
   public selectedProfile;
   public noSearchResults;
   public auditOptionError;
   public readonly isPhoneScreenSize$ = isPhoneScreenSize$;
   protected MAX_USER_PROFILE_SEARCH_LENGTH: number = 5;

   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly modalService = inject(ModalService);
   private readonly router = inject(Router);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);

   @HostBinding("class") public readonly classes = "scroll-height-inheritance"; //scroll fix

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      const data = this.resolve.data;
      this.message = data.message;
      this.title = data.title;
      this.locationID = data.locationID;
      this.showAuditOptions = data.showAuditOptions || false;
      this.advancedOptions = Boolean(data.advancedOptions);
      this.locked = Boolean(data.locked);
      this.defaultUser = Number(data.defaultUser) || 0;
      this.defaultProfile = Number(data.defaultProfile) || 0;
      this.extraUsers = data.extraUsers; // custom user options (ie: current user)

      this.selected = 0;
      this.errorMsg = "";
      this.userClicks = 0; //user clicks is a method to allow them to double click the selection to submit ONLY if the user behavior is they double clicked a single one.
      this.lockable = true;
      this.users = [];
      this.profiles = [];
      this.allUsers = [];
      this.allProfiles = [];

      this.days = "0";
      this.numberCount = "0";

      this.initializeUsersAndProfiles();
   }

   private initializeUsersAndProfiles(autoSelectUserID: number = 0): void {
      const data = this.resolve.data;
      const excludeUser = data.excludeUser;

      const answer = this.manageUser.getUsersAndProfilesAtLocation(
         data.locationID,
      ) as any;
      this.users = answer.data.users || [];
      this.users = this.manageFilters.filterToUsersThatCanCompleteWork(
         this.users,
         {
            locations: [this.locationID],
         },
         this.manageUser,
         this.manageProfile,
      ); //if the user is only a view only then we shouldn't be able to assign to them

      if (this.extraUsers) {
         // add in the extra users at the end, flagged as 'extra'
         this.users = [
            ...this.users,
            ...this.extraUsers.map((user) => {
               user.extra = true;
               return user;
            }),
         ];
      }

      if (excludeUser !== undefined) {
         this.users = this.users.filter((user) => excludeUser.userID !== user.userID);
      }

      let profiles = answer.data.profiles || [];
      profiles = this.manageFilters.filterProfilesToWhoCanCompleteWork(
         profiles,
         this.manageProfile,
      ); //removes View only profile for example
      this.userProfiles = answer.data.userProfiles || [];

      this.profiles = this.manageFilters.filterProfilesByAssignment(
         this.locationID,
         profiles,
         this.userProfiles,
      );

      for (const userProfile of this.userProfiles) {
         for (const user of this.users) {
            if (userProfile.userID == user.userID) {
               user.search = `${user.search + userProfile.profileDescription} `;
            }
         }
      }

      for (const user of this.users) {
         user.hint = false;
         user.selected = false;
      }

      if (this.defaultProfile > 0) {
         const profile = this.manageUser.getProfilesIndex()[this.defaultProfile];

         if (profile.profileHidden == 1) {
            for (const user of profile.users) {
               for (const user2 of this.users) {
                  if (user.userID == user2.userID) {
                     this.focusUser(user2);
                  }
               }
            }
         } else {
            this.focusProfile(this.defaultProfile);
         }
      } else {
         this.users.forEach((user) => {
            if (user.extra != true && user.userID == this.defaultUser) {
               this.focusUser(user);
               this.userClicks = 1; //have to set userClicks because a very common behavior is someone trying to deselect the default user to pick someone
            }
         });
      }

      if (data.defaultToDynamicAssignment) {
         const defaultAssignmentUser = this.users.find((user) => user.dynamicAssignment);
         this.focusUser(defaultAssignmentUser);
      }

      // If the user just made a new user, deselect everyting and select the new user after creation
      if (autoSelectUserID > 0 && this.users?.length > 0) {
         const userToSelect = this.users.find((user) => user.userID === autoSelectUserID);
         if (userToSelect) {
            this.deselectAll();
            this.focusUser(userToSelect);
         }
      }

      this.allUsers = this.users;
      this.allProfiles = this.profiles;

      this.manageProfileCred = this.credService.isAuthorized(
         this.locationID,
         this.credService.Permissions.ViewManageTeams,
      );

      this.canAddUsers = this.credService.isAuthorized(
         this.locationID,
         this.credService.Permissions.AddUsers,
      );
   }

   close = () => {
      this.modalInstance.close(0);
   };

   runFilter = () => {
      const search = this.searchUsersTeams.toLowerCase();

      const userLookupSelected = {};
      for (const user of this.users) {
         userLookupSelected[user.userID] = user.selected;
      }

      this.users = [];
      for (const user of this.allUsers) {
         if (userLookupSelected[user.userID]) {
            //if they are selected we have to mark them as selected
            user.selected = true;
            this.users.push(user);
         } else if (
            user.userFirstName?.toLowerCase().includes(search) ||
            user.userLastName?.toLowerCase().includes(search)
         ) {
            this.users.push(user);
         }
      }

      this.profiles = [];
      for (const profile of this.allProfiles) {
         if (
            profile.profileID == this.selectedProfile ||
            profile.profileDescription.toLowerCase().includes(search)
         ) {
            this.profiles.push(profile);
         }
      }

      this.noSearchResults = false;
      if (this.users.length == 0 && this.profiles.length == 0) {
         this.noSearchResults = true;
      }
   };

   deselectAll = () => {
      this.selectedProfile = 0;
      for (const user of this.users) {
         user.selected = false;
      }
   };

   public closeAllAndRedirect(url: string): void {
      this.router.navigateByUrl(url);
   }

   public selectAllUsers(select: boolean) {
      for (const user of this.users) {
         user.selected = select;
         if (user.userID === 0) {
            user.selected = false;
         }
      }
   }

   public selectAllProfiles(select: boolean) {
      for (const profile of this.profiles) {
         profile.selected = select;
      }
   }

   focusUser = (userToFocus) => {
      this.selectedProfile = 0;

      //set all the users hint to false.  (hint is used to show which user belongs to which profile)
      this.users.forEach((user) => {
         user.hint = false;
      });

      //depending on what they clicked make the advanced options or not
      for (const user of this.users) {
         if (user.userID == userToFocus.userID) {
            if (user.userID == -101 || user.userID == -102) {
               this.locked = false;
               this.lockable = false;
            } else {
               this.lockable = true;
            }
         }
      }

      //if we are picking an option that isn't extra then we need to mark all extra options as not selected
      if (userToFocus.extra == false || userToFocus.extra === undefined) {
         for (const user of this.users) {
            if (user.extra == true) {
               user.selected = false;
            }
         }
      }

      //if we are picking an extra then we need to loop through and mark all others as false
      if (userToFocus.extra == true) {
         for (const user of this.users) {
            if (
               user.userID != userToFocus.userID ||
               (userToFocus.dynamicAssignment && !user.dynamicAssignment) ||
               (!userToFocus.dynamicAssignment && user.dynamicAssignment)
            ) {
               user.selected = false;
            }
         }
      }

      if (userToFocus.extra == true) {
         if (userToFocus.selected == true) {
            this.submit();
            return;
         }
      } else {
         if (userToFocus.selected == true) {
            //they picked one that was already selected
            if (this.userClicks <= 0) {
               //only one is selected so they meant to double tap it to select
               this.submit();
               return;
            }
            //they have more then 1 so they are trying to deselect this.
         }
         this.userClicks++;
         this.checkResetUserClicks();
      }

      userToFocus.selected = !userToFocus.selected;
   };

   checkResetUserClicks = () => {
      this.userClicks = this.manageUtil.checkResetUserClicks(this.users, this.userClicks);
   };

   resetUserSelection = () => {
      for (const user of this.users) {
         user.hint = false;
         user.selected = false;
      }
      this.userClicks = 0;
   };

   focusProfile = (profileID) => {
      this.resetUserSelection();

      for (const key in this.userProfiles) {
         if (this.userProfiles[key].profileID == profileID) {
            for (const key2 in this.users) {
               if (this.users[key2].userID == this.userProfiles[key].userID) {
                  this.users[key2].hint = true;
               }
            }
         }
      }

      if (this.selectedProfile == profileID) {
         //already selected so submit
         this.submit();
         return;
      }

      this.selectedProfile = profileID;
   };

   submit = () => {
      let error = false;
      this.errorMsg = "";
      this.auditOptionError = false;

      //checks to see if any users are selected
      let foundUser = false;
      const users = this.users.filter((user) => user.selected == true);
      if (users.length > 0) {
         foundUser = true;
      }

      if (
         //check to see if any profiles were selected, any users were selected or any of the extra options.  If not give them a warning...
         !(this.selectedProfile > 0 || foundUser)
      ) {
         this.errorMsg += this.lang().PleaseSelectUserOrProfile;
         error = true;
      }

      if (this.showAuditOptions && (this.days == "0" || this.numberCount == "0")) {
         this.errorMsg += this.errorMsg.length > 3 ? "<br />" : "";
         this.errorMsg += this.lang().PleaseEnterOptionsRequiredAbove;
         this.auditOptionError = true;
         error = true;
      }
      if (this.errorMsg !== "") {
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
      }

      if (!error) {
         const dataToSubmit: any = {};
         dataToSubmit.multiUsers = []; //sets a default so we also pass in something

         if (this.selectedProfile > 0) {
            dataToSubmit.userID = 0;
            dataToSubmit.profileID = this.selectedProfile;
         } else {
            //checks to see if any users are selected
            const usersToReturn: any = [];
            this.users.forEach((user) => {
               if (user.selected == true) {
                  usersToReturn.push(user);
               }
            });

            if (usersToReturn.length == 1) {
               //they only picked one user so use the userID for that.
               dataToSubmit.userID = usersToReturn[0].userID;
               dataToSubmit.profileID = usersToReturn[0].profileID || 0;
               dataToSubmit.dynamicAssignment = usersToReturn[0].dynamicAssignment;
            } else {
               //they picked multiple users so we need to send multiUsers
               dataToSubmit.userID = 0;
               dataToSubmit.profileID = 0;

               const tempUserIDs = usersToReturn.map((user) => user.userID);

               dataToSubmit.multiUsers = tempUserIDs;
            }
         }

         dataToSubmit.numberCount = this.numberCount;
         dataToSubmit.days = this.days;
         dataToSubmit.locked = this.locked;
         this.modalInstance.close(dataToSubmit);
      }
   };

   protected openAddUserModal(): void {
      if (!this.canAddUsers) return;

      const modalRef = this.modalService.open(AddUser);
      modalRef.setInput("locationID", this.locationID);

      modalRef.result.then((newUserID) => {
         if (newUserID) {
            this.initializeUsersAndProfiles(newUserID);
            this.manageTask.fetchUsers();
         }
      });
   }
}
