import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { CustomTagList } from "src/app/tasks/components/customTagListModal/customTagList.element.component";
import { ManageTaskItemOption } from "src/app/tasks/services/manageTaskItemOption";

export const enum CustomTagEvent {
   TaskCompleted = 0,
   InstructionChanged = 1,
   /**
    * @deprecated
    * This value is functionally the same as `1` and has been removed
    * from the UI. It is kept here for backwards compatibility with
    * existing data.
    */
   InstructionChangedOrTaskCompleted = 2,
}

@Component({
   selector: "task-options-advanced-settings",
   templateUrl: "./taskOptionsAdvancedSettings.modal.component.html",
   styleUrls: ["./taskOptionsAdvancedSettings.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      AlertComponent,
      LimbleHtmlDirective,
      InfoPanelComponent,
      DropdownComponent,
      DropdownTextItemComponent,
      CustomTagList,
   ],
})
export class TaskOptionsAdvancedSettings implements OnInit {
   public option;
   public title;
   public errorMsg;
   public customTagToAdd;
   public customTagListObjForToAdd;
   public customTagToRemove;
   public customTagListObjForToRemove;
   public data;
   public resolve;
   public modalInstance;
   public addCustomTagEventStr?: string;
   public removeCustomTagEventStr?: string;
   public customTagToAddEvent: CustomTagEvent = CustomTagEvent.TaskCompleted;
   public customTagToRemoveEvent: CustomTagEvent = CustomTagEvent.TaskCompleted;

   private readonly manageTaskItemOption = inject(ManageTaskItemOption);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.option = this.data.option;

      this.title = this.lang().TriggerCustomTag;
      this.errorMsg = "";

      this.manageTaskItemOption
         .getOptionCustomTag(this.option.itemOptionID)
         .then((answer) => {
            if (answer.data.success == true) {
               if (
                  answer.data.customTagToAdd != null &&
                  answer.data.customTagToAdd.length > 0
               ) {
                  this.customTagToAdd = answer.data.customTagToAdd;
               } else {
                  this.customTagToAdd = "";
               }

               this.customTagListObjForToAdd = {};
               this.customTagListObjForToAdd.customTags = this.customTagToAdd;
               this.customTagListObjForToAdd.type = 1;
               this.setCustomTagListObj(this.customTagListObjForToAdd);

               if (
                  answer.data.customTagToRemove != null &&
                  answer.data.customTagToRemove.length > 0
               ) {
                  this.customTagToRemove = answer.data.customTagToRemove;
               } else {
                  this.customTagToRemove = "";
               }

               this.customTagListObjForToRemove = {};
               this.customTagListObjForToRemove.customTags = this.customTagToRemove;
               this.customTagListObjForToRemove.type = 2;
               this.setCustomTagListObj(this.customTagListObjForToRemove);

               this.customTagToAddEvent = answer.data.customTagToAddEvent;
               this.customTagToRemoveEvent = answer.data.customTagToRemoveEvent;
               this.setCustomTagEventStrs();
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });

      //this timeout makes sure that the modal starts at the top of the screen
      setTimeout(() => {
         $("#taskOptionsAdvancedSettings").parent().parent().scrollTop(0);
      }, 1);
   }

   private setCustomTagEventStrs(): void {
      if (this.customTagToAddEvent === CustomTagEvent.TaskCompleted) {
         this.addCustomTagEventStr =
            this.lang().WhenThisTaskIsCompletedAdvOptSettingsNoHTML;
      } else {
         this.addCustomTagEventStr =
            this.lang().WhenThisInstructionIsChangedAdvOptSettingsNoHTML;
      }

      if (this.customTagToRemoveEvent === CustomTagEvent.TaskCompleted) {
         this.removeCustomTagEventStr =
            this.lang().WhenThisTaskIsCompletedAdvOptSettingsNoHTML;
      } else {
         this.removeCustomTagEventStr =
            this.lang().WhenThisInstructionIsChangedAdvOptSettingsNoHTML;
      }
   }

   setCustomTagListObj = (obj) => {
      const updateSource = (tagToDelete) => {
         if (
            typeof obj.customTags === "string" &&
            obj.customTags.toLowerCase().indexOf(`${tagToDelete.name.toLowerCase()};`) !==
               -1
         ) {
            //we found the tag so remove it
            let find = `${tagToDelete.name};`;
            let regex = new RegExp(find, "g");
            obj.customTags = obj.customTags.replace(regex, "");

            find = `${tagToDelete.name.toLowerCase()};`;
            regex = new RegExp(find, "g");
            obj.customTags = obj.customTags.replace(regex, "");
         }

         if (obj.type == 1) {
            this.customTagToAdd = obj.customTags;
         } else if (obj.type == 2) {
            this.customTagToRemove = obj.customTags;
         }
      };

      const updateSourceRename = () => {
         obj.customTags = obj.sources[0];
         if (obj.type == 1) {
            this.customTagToAdd = obj.sources[0];
         } else if (obj.type == 2) {
            this.customTagToRemove = obj.sources[0];
         }
      };

      const clickTag = (tag) => {
         if (
            typeof obj.customTags === "string" &&
            obj.customTags.toLowerCase().indexOf(`${tag.name.toLowerCase()};`) !== -1
         ) {
            let reg;
            //we found the tag so remove it
            reg = new RegExp(
               `${tag.name};`.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
               "g",
            );
            obj.customTags = obj.customTags.replace(reg, "");

            reg = new RegExp(
               `${tag.name.toLowerCase()};`.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
               "g",
            );
            obj.customTags = obj.customTags.replace(reg, "");

            if (obj.customTags.length < 2) {
               obj.customTags = null;
            }
         } else if (
            obj.customTags == null ||
            obj.customTags == "" ||
            obj.customTags.length < 2
         ) {
            obj.customTags = `${tag.name};`;
         } else {
            obj.customTags += `${tag.name};`;
         }

         if (obj.type == 1) {
            this.manageTaskItemOption
               .updateOptionCustomTagToAdd(this.option.itemOptionID, obj.customTags)
               .then((answer) => {
                  if (answer.data.success == true) {
                     this.customTagToAdd = obj.customTags;
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         } else {
            this.manageTaskItemOption
               .updateOptionCustomTagToRemove(this.option.itemOptionID, obj.customTags)
               .then((answer) => {
                  if (answer.data.success == true) {
                     this.customTagToRemove = obj.customTags;
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
         obj.sources = [obj.customTags];
      };

      const deleteTag = (tagToDelete) => {
         updateSource(tagToDelete);
      };
      const renameTag = () => {
         updateSourceRename();
      };

      obj.clickTag = clickTag;
      obj.deleteTag = deleteTag;
      obj.renameTag = renameTag;
      obj.selectOne = false;
      obj.advancedSettings = true;
      obj.sources = [obj.customTags];
   };

   protected async setCustomTagToAddEvent(event: CustomTagEvent): Promise<void> {
      const answer = await this.manageTaskItemOption.updateOptionCustomTagToAddEvent(
         this.option.itemOptionID,
         event,
      );
      if (answer.data.success == true) {
         this.customTagToAddEvent = event;
         this.setCustomTagEventStrs();
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   protected async setCustomTagToRemoveEvent(event: CustomTagEvent): Promise<void> {
      const answer = await this.manageTaskItemOption.updateOptionCustomTagToRemoveEvent(
         this.option.itemOptionID,
         event,
      );
      if (answer.data.success == true) {
         this.customTagToRemoveEvent = event;
         this.setCustomTagEventStrs();
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   close = () => {
      this.modalInstance.close(0);
   };
}
