import { CommonModule } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { IconComponent, LimbleHtmlDirective, ModalService } from "@limblecmms/lim-ui";
import { take, map } from "rxjs";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { AssetName } from "src/app/tasks/components/taskItemElement/assetName.pipe";

@Component({
   selector: "task-asset-cell",
   imports: [
      CommonModule,
      AssetParentList,
      IconComponent,
      AssetName,
      LimbleHtmlDirective,
   ],
   templateUrl: "./task-asset-cell.component.html",
   styleUrls: ["./task-asset-cell.component.scss"],
})
export class TaskAssetCellComponent {
   private readonly manageLang = inject(ManageLang);
   private readonly manageAsset = inject(ManageAsset);
   protected readonly facade = inject(TasksFacadeService);
   protected readonly modalService = inject(ModalService);
   protected readonly paramsService = inject(ParamsService);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly task = input.required<TaskDataViewerViewModel>();

   protected readonly mainAsset = computed(() => this.task()?.assets?.[0]);

   protected readonly assetsLoaded = toSignal(
      this.manageAsset.assetsLoaded().pipe(
         take(1),
         map(() => true),
      ),
      { initialValue: false },
   );

   protected readonly ancestorAssetID = computed(() => this.getParentAssetID());

   protected getParentAssetID(): number {
      const asset = this.mainAsset();
      const assetID = asset?.assetID;
      if (!assetID) {
         return 0;
      }

      let parentAssetID = assetID;
      while (parentAssetID) {
         const parentInfo = this.manageAsset.getAsset(parentAssetID);
         if (parentInfo?.parentAssetID) {
            parentAssetID = parentInfo.parentAssetID;
         } else {
            break;
         }
      }

      return parentAssetID;
   }

   public onAssetClick(event: Event): void {
      event.stopPropagation();
      //This even should open the Asset on desktop and the Task on mobile
      //The below combined with a flag passed into asset-hierarchy-list-item ensures that happens
      if (this.facade.isMobile) {
         this.facade.viewTask(this.task().checklistID);
      } else {
         const instance = this.modalService.open(PopAsset);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               assetID: this.mainAsset()?.assetID,
               locationID: this.mainAsset()?.locationID,
               data: {
                  restrict: false,
               },
            },
         };
      }
   }
}
