import { formatNumber } from "@angular/common";
import { computed, inject, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { PartLogDataViewerViewModel } from "src/app/parts/components/part-logs-data-viewer/part-log-date-viewer.model";
import type { DownloadableLog } from "src/app/parts/components/part-modal/components/shared/model/downloadable-log.model";
import {
   type PartLogEntityFilters,
   PartLogSourceID,
} from "src/app/parts/components/shared/services/logs-api/part-log-api.models";
import { PartLogsApiService } from "src/app/parts/components/shared/services/logs-api/part-log-api.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { RequestOptions } from "src/app/shared/services/flannel-api-service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({ providedIn: "root" })
export class PartLogExporterService {
   private readonly manageLang = inject(ManageLang);
   private readonly partLogAPI = inject(PartLogsApiService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly managePart = inject(ManageParts);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly managePO = inject(ManagePO);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUser = inject(ManageUser);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);

   protected readonly userCurrencySymbol =
      this.manageUser.getCurrentUser().currency.symbol;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public async downloadExcelPartLogs(
      args: Partial<RequestOptions<PartLogEntityFilters>>,
      currentPartID: number,
   ): Promise<void> {
      let partLogs: Array<any> = [];

      const total = await this.partLogAPI.getTotal(args);

      partLogs = await this.partLogAPI.getAllItems(
         {
            ...args,
            columns: args.columns ?? "",
         },
         total,
      );
      const downloadableLogs = partLogs.map((log) =>
         this.getDownloadableLogFromLog(log, currentPartID),
      );
      this.manageUtil.objToExcel(downloadableLogs, "Parts History", "Parts History.xlsx");
   }

   private difference(log: PartLogDataViewerViewModel): number | undefined {
      if (
         log.sourceID === PartLogSourceID.UpdatedPartQuantity ||
         log.sourceID === PartLogSourceID.TaskUsedPart ||
         PartLogSourceID.UpdatedPartFromPO ||
         PartLogSourceID.UpdatedPartReceivedFromPO
      ) {
         const difference = Number(log.newValue) - Number(log.oldValue);
         if (isNaN(difference)) {
            return undefined;
         }
         return difference;
      }
      return undefined;
   }

   private getDownloadableLogFromLog(
      log: PartLogDataViewerViewModel,
      currentPartID: number,
   ): DownloadableLog | undefined {
      const logEntry = this.getLogEntry(log, currentPartID);
      const parts = this.managePart.getParts();
      const part = parts.get(log.partID);
      const locations = this.manageLocation.getLocationsIndex();

      if (!part) {
         return undefined;
      }

      let usedAtLocation = "";
      let partLocation = "";
      const logTask = log.task;
      if (logTask !== undefined) {
         usedAtLocation = locations[logTask.locationID].locationName;
         partLocation = part.partLocation ?? "";
      }

      const downloadableLog: DownloadableLog = {
         "partPriceAtTime": log.partPriceAtTime ?? 0,
         "manualChangePartQtyReason": log.manualChangePartQtyReason ?? "",
         "hasReason": log.hasReason ?? false,
         "prID": this.getLogPrNumber(log),
         "Date": log.logDate ?? "",
         "Entry": logEntry ?? "",
         "Difference": this.difference(log)?.toString() ?? "",
         "Part Name": part.partName,
         "Part Number": part.partNumber,
         "First Name": log.user?.userFirstName ?? "",
         "Last Name": log.user?.userLastName ?? "",
         "Old Value": log.oldValue ?? "",
         "New Value": log.newValue ?? "",
         "Part ID": log.partID,
         "User ID": log.userID,
         "Task ID": log.task?.checklistID?.toString() ?? "",
         "Used At Location": usedAtLocation,
         "Part Location": partLocation,
      };

      return downloadableLog;
   }

   /**
    * Returns the entry for a log for downloading logs. This creates the entry without any HTML tags.
    */
   private getLogEntry(log: PartLogDataViewerViewModel, currentPartID: number) {
      const mainLogDisplay = this.getMainLogDisplay(log);
      if (currentPartID === 0) {
         const logPart = this.managePart.getPart(log.partID);
         const potentialLogPartNumber = this.getLogPartNumber(log);
         if (potentialLogPartNumber) {
            return `${mainLogDisplay} - ${logPart?.partName ?? ""} - ${this.getLogPartNumber(log)}`;
         }
         return `${mainLogDisplay} - ${logPart?.partName ?? ""}`;
      }
      return mainLogDisplay;
   }

   private getLogPrNumber(log: PartLogDataViewerViewModel) {
      if (log.sourceID === PartLogSourceID.UpdatedPartFromPO) {
         const getTransactionResponse = this.managePO.getBillTransaction(
            log.associatedID,
         );
         if (getTransactionResponse?.prID) {
            return getTransactionResponse.prID;
         }
      } else if (log.sourceID === PartLogSourceID.UpdatedPartReceivedFromPO) {
         const getBillResponse = this.managePO.getBill(log.associatedID);
         if (getBillResponse) {
            return log.associatedID;
         }
      }
      return undefined;
   }

   private getLogPartNumber(log: PartLogDataViewerViewModel) {
      const vendorRelation = this.manageAssociations
         .getAssociatedVendorsForPart(
            log.partID,
            this.managePO.getPurchaseOrderItemsByPartID(log.partID),
            this.managePO,
         )
         .get(log.associatedID);

      return vendorRelation?.partNumber;
   }

   private formatDiff(diff: number) {
      return formatNumber(diff, this.manageLang.getLocaleID(), "1.3-3");
   }

   private formatLogValueForUpdatedPartPrice(log: PartLogDataViewerViewModel) {
      const partPrice = Number(log.newValue);
      if (partPrice === null || partPrice === undefined) {
         return undefined;
      }
      return formatNumber(partPrice, this.manageLang.getLocaleID(), "1.2-2");
   }

   private getVendorPartNumber(log: PartLogDataViewerViewModel) {
      const vendorRelation = this.manageAssociations
         .getAssociatedVendorsForPart(
            log.partID,
            this.managePO.getPurchaseOrderItemsByPartID(log.partID),
            this.managePO,
         )
         .get(log.associatedID);

      return vendorRelation?.partNumber;
   }

   private getVendorPartPrice(log: PartLogDataViewerViewModel) {
      const vendorRelation = this.manageAssociations
         .getAssociatedVendorsForPart(
            log.partID,
            this.managePO.getPurchaseOrderItemsByPartID(log.partID),
            this.managePO,
         )
         .get(log.associatedID);

      return vendorRelation?.partPrice;
   }

   private getPartStockUnitName(newValue) {
      const unit = this.unitOfMeasureService.getUnit(JSON.parse(newValue))();
      return unit ? unit.short() : "";
   }

   private getMainLogDisplay(log: PartLogDataViewerViewModel) {
      const logDiff = Number(log.oldValue) - Number(log.newValue);
      switch (log.sourceID) {
         case PartLogSourceID.UpdatedPartName: {
            return `${this.lang().PartNameManuallyUpdatedTo} ${log.newValue}`;
         }
         case PartLogSourceID.UpdatedPartNumber: {
            const partNumber = log.newValue === "" ? "blank" : log.newValue;
            return `${this.lang().PartNumberManuallyUpdatedTo} ${partNumber}`;
         }
         case PartLogSourceID.UpdatedPartQuantity: {
            const partWasOrWere =
               Math.abs(logDiff) === 1 ? this.lang().partWas : this.lang().partsWere;
            const removedOrAdded = logDiff < 0 ? this.lang().added : this.lang().removed;
            return `${Math.abs(logDiff)} ${partWasOrWere} ${removedOrAdded} - ${this.lang().ManuallyAdjusted} - ${log.manualChangePartQtyReason}`;
         }
         case PartLogSourceID.AddedLogEntry: {
            return log.logDetails;
         }
         case PartLogSourceID.TaskUsedPart: {
            const partWasOrWere =
               Math.abs(logDiff) === 1 ? this.lang().partWas : this.lang().partsWere;
            return `${this.formatDiff(logDiff)} ${partWasOrWere} ${this.lang().usedOnATask}`;
         }
         case PartLogSourceID.UpdatedPartPrice: {
            return `${this.lang().PartPriceManuallyChangedTo} ${this.formatLogValueForUpdatedPartPrice(log)}`;
         }
         case PartLogSourceID.UpdatedPartStaleThreshold: {
            return `${this.lang().StaleThresholdManuallyChangedTo} ${log.newValue} ${this.lang().days}`;
         }
         case PartLogSourceID.UpdatedPartOverstockedThreshold: {
            return `${this.lang().PartQtyThresholdManuallyChangedTo} ${log.newValue}`;
         }
         case PartLogSourceID.DeletedPart: {
            return this.lang().PartWasMarkedAsDeleted;
         }
         case PartLogSourceID.CreatedTaskToOrderMoreOfThisPart: {
            return log.logDetails;
         }
         case PartLogSourceID.PartQuantityThresholdStatusChanged: {
            const thresholdColor =
               log.newValue === "1" ? this.lang().red : this.lang().green;
            return `${this.lang().PartQtyThresholdStatusChangedToA} ${thresholdColor} ${this.lang().status}`;
         }
         case PartLogSourceID.PartImportedOrBulkUpdated: {
            return log.logDetails;
         }
         case PartLogSourceID.UpdatedPartLocation: {
            return `${this.lang().PartLocationManuallyChangedTo} ${log.newValue}`;
         }
         case PartLogSourceID.UpdatedPartSupplier: {
            return `${this.lang().PartSupplierManuallyChangedTo} ${log.newValue}`;
         }
         case PartLogSourceID.UpdatedPartImage: {
            return `${this.lang().PartImageWas} ${log.newValue}`;
         }
         case PartLogSourceID.RestoredPartsBecauseCompletedTaskReopened: {
            const partWasOrWere =
               Math.abs(logDiff) === 1 ? this.lang().partWas : this.lang().partsWere;
            return `${logDiff * -1} ${partWasOrWere} ${this.lang().restoredBecauseThe} ${this.lang().completedTaskWOPM} ${this.lang().wasReopened}`;
         }
         case PartLogSourceID.PartsUpdatedBecauseTaskEdited: {
            const partWasOrWere =
               Math.abs(logDiff) === 1 ? this.lang().partWas : this.lang().partsWere;
            const displayDiff = logDiff > 0 ? logDiff : logDiff * -1;
            const usedOrRestored =
               logDiff > 0 ? this.lang().usedBecauseThe : this.lang().restoredBecauseThe;

            return `${displayDiff} ${partWasOrWere} ${usedOrRestored} ${this.lang().completedTaskWOPM} ${this.lang().wasEdited}`;
         }
         case PartLogSourceID.UpdatedPartFromPO: {
            const partWasOrWere =
               Math.abs(logDiff) === 1 ? this.lang().partWas : this.lang().partsWere;
            const displayDiff = logDiff >= 0 ? logDiff : logDiff * -1;
            const receivedOrRemoved =
               logDiff >= 0 ? this.lang().removed : this.lang().received;
            return `${displayDiff} ${partWasOrWere} ${receivedOrRemoved}`;
         }
         case PartLogSourceID.UpdatedPartMaxQuantityThreshold: {
            return `${this.lang().PartMaxQtyThresholdManuallyChangedTo} ${log.newValue}`;
         }
         case PartLogSourceID.UpdatedPartReceivedFromPO: {
            const partWasOrWere =
               Math.abs(logDiff) === 1 ? this.lang().partWas : this.lang().partsWere;
            return `${logDiff * -1} ${partWasOrWere} ${this.lang().markedAsUsedOn}`;
         }
         case PartLogSourceID.UpdatedPartVendorAssociation: {
            const wasAddedOrRemoved =
               log.newValue === "1" ? this.lang().added : this.lang().removed;
            return `${this.lang().VendorAssociation} ${wasAddedOrRemoved}`;
         }
         case PartLogSourceID.UpdatedPartVenderQuantity: {
            const partNumberOrBlank =
               log.newValue === "" ? "blank" : this.getVendorPartNumber(log);
            return `${this.lang().VendorPartNumberManuallyUpdatedTo} ${partNumberOrBlank}`;
         }
         case PartLogSourceID.UpdatedPartVendorPrice: {
            return `${this.lang().VendorPartPriceManuallyUpdatedTo} ${this.userCurrencySymbol} ${this.getVendorPartPrice(log)}`;
         }
         case PartLogSourceID.CopiedPart: {
            return this.lang().ThisPartWasCopiedFrom;
         }
         case PartLogSourceID.UpdatedPartUnitOfMeasure: {
            log.newStockUnitName = this.getPartStockUnitName(log.newValue);
            log.oldStockUnitName = this.getPartStockUnitName(log.oldValue);
            return `${this.lang().StockUnitWasChangedFrom} ${log.oldStockUnitName} ${this.lang().To} ${log.newStockUnitName}`;
         }
         default:
            throw new Error("Unknown sourceID type for given log");
      }
   }
}
