<div class="status-info-wrapper">
   <span class="current-status-container">
      @if (status === "checkedIn") {
         <span class="checked-in-status">
            <lim-ui-icon icon="circleCheck" iconSize="small" iconColor="success" />
            <span>{{ lang().CheckedIn }}</span>
         </span>
      }
      @if (status === "requestPending") {
         <span>
            <lim-ui-icon icon="circleCheck" iconSize="small" iconColor="warn" />
            {{ lang().PendingApproval }} {{ lang().From }}: {{ requestSentToDisplay }}
         </span>
      }
      @if (status === "checkedOut") {
         <span>
            <lim-ui-icon icon="circleXmark" iconSize="small" iconColor="danger" />
            {{ lang().CheckedOut }} {{ lang().By }}: {{ requestedByUserDisplay }}
         </span>
      }
   </span>
   <span class="info-buttons">
      <span limUiTooltip="{{ requestButtonTooltip }}">
         @if (
            status === "checkedIn" || (status === "requestPending" && !isUserWhoRequested)
         ) {
            <lim-ui-primary-button
               [disabled]="status === 'requestPending' || sendRequestClicked"
               (click)="runCreateCheckOutRequest()"
            >
               {{ tool.checkOutRequiresApproval ? lang().RequestTool : lang().CheckOut }}
            </lim-ui-primary-button>
         }
         @if (status === "checkedOut" && canCheckIn) {
            <lim-ui-primary-button (click)="runCheckInTool()">
               {{ lang().CheckInTool }}
            </lim-ui-primary-button>
         }
      </span>

      @if (status === "requestPending" && isUserWhoRequested) {
         <lim-ui-icon-button
            icon="arrowsRotate"
            limUiTooltip="{{ lang().ReRequestThisTool }}"
            (click)="reSendCheckOutRequest()"
            [disabled]="reSendRequestClicked"
         />

         <lim-ui-icon-button
            [icon]="'xmarkLargeRegular'"
            [iconColor]="'danger'"
            limUiTooltip="{{ lang().CancelThisRequest }}"
            (click)="runCancelRequest()"
         />
      }
   </span>
</div>
