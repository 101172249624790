import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   OutlinedButtonComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "no-time-logged",
   templateUrl: "./noTimeLogged.modal.component.html",
   styleUrls: ["./noTimeLogged.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      SecondaryButtonComponent,
      ModalFooterComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
   ],
})
export class NoTimeLogged implements OnInit {
   public resolve;
   public modalInstance;
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
   }

   logTime = () => {
      this.modalInstance.close("logTime");
   };

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      this.modalInstance.close(1);
   };

   dismiss = () => {
      this.modalInstance.close();
   };
}
