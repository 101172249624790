@if (part) {
   <lim-ui-panel id="partLogPrint{{ part.partID }}">
      <!-- header section (search, include children, && print header that is hidden until printing) -->
      <div>
         <div class="header-section-row">
            <lim-ui-search-box
               [(searchVal)]="searchBar"
               (searchValChange)="runFilter()"
               [placeholder]="lang().Search"
            />
            @if (showDownloadButton) {
               <div class="right-align-buttons">
                  <lim-ui-icon-button
                     class="lim-ui-hide-on-mobile"
                     icon="cloudArrowDown"
                     (click)="downloadPartHistory()"
                     limUiTooltip="{{ lang().ExportListToCSV }}"
                     placement="left"
                  />
               </div>
            }
         </div>
      </div>

      <div class="panel-body panel-bottom panel-middleGreen">
         @if (part) {
            <div class="listTable lim-ui-responsive-table">
               <!-- filter section -->
               <div class="filters-row">
                  <lim-ui-filters-wrapper [noMargins]="true" title="{{ lang().Filter }}:">
                     <lim-ui-dropdown-button [label]="lang().Type" filterDropdown>
                        <div menu>
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualEntry }}"
                              (click)="filterSource(4)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualUpdateName }}"
                              (click)="filterSource(1)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualUpdateNumber }}"
                              (click)="filterSource(2)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualUpdateQty }}"
                              (click)="filterSource(3)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualUpdatePrice }}"
                              (click)="filterSource(6)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualUpdateLocation }}"
                              (click)="filterSource(13)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualUpdateSupplier }}"
                              (click)="filterSource(14)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualUpdateStaleThreshold }}"
                              (click)="filterSource(7)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualUpdatePartQtyThreshold }}"
                              (click)="filterSource(8)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().EntryFromTaskWOPM }}"
                              (click)="filterSource(5)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().PartQtyStatusChange }}"
                              (click)="filterSource(11)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Import }}"
                              (click)="filterSource(12)"
                           />

                           <lim-ui-dropdown-divider />

                           <lim-ui-dropdown-clear-filter-item (click)="clearSource()">
                              {{ lang().ClearFilter }}
                           </lim-ui-dropdown-clear-filter-item>
                        </div>
                     </lim-ui-dropdown-button>
                     <lim-ui-dropdown-button [label]="lang().Date" filterDropdown>
                        <div menu>
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Last7Days }}"
                              (click)="filterDate(86400 * 7 * 1000)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Last14Days }}"
                              (click)="filterDate(86400 * 14 * 1000)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Last30Days }}"
                              (click)="filterDate(86400 * 30 * 1000)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Last60Days }}"
                              (click)="filterDate(86400 * 60 * 1000)"
                           />

                           <lim-ui-dropdown-divider />
                           <lim-ui-dropdown-date-range-picker
                              [sectionLabel]="lang().DateRange"
                              [startDateFieldLabel]="lang().From"
                              [(startDate)]="date1"
                              [endDateFieldLabel]="lang().To"
                              [(endDate)]="date2"
                              [setButtonLabel]="lang().SetDateRange"
                              (setButtonClicked)="setDateRange()"
                           />

                           <lim-ui-dropdown-divider />

                           <lim-ui-dropdown-clear-filter-item (click)="clearDateFilter()">
                              {{ lang().ClearFilter }}
                           </lim-ui-dropdown-clear-filter-item>
                        </div>
                     </lim-ui-dropdown-button>
                     <lim-ui-filter-input-wrapper filterInputs>
                        @if (sourceState) {
                           <lim-ui-filter-input (clearFilterEvent)="clearSource()">
                              <label inputLabel> {{ lang().ActiveFilter }}:</label>
                              <b additionalContent>
                                 <span [hidden]="!(sourceID == 1)">{{
                                    lang().ManualUpdateName
                                 }}</span>
                                 <span [hidden]="!(sourceID == 2)">{{
                                    lang().ManualUpdateNumber
                                 }}</span>
                                 <span [hidden]="!(sourceID == 3)">{{
                                    lang().ManualUpdateQty
                                 }}</span>
                                 <span [hidden]="!(sourceID == 4)">{{
                                    lang().ManualLogEntry
                                 }}</span>
                                 <span [hidden]="!(sourceID == 5)">{{
                                    lang().EntryFromTaskWOPM
                                 }}</span>
                                 <span [hidden]="!(sourceID == 6)">{{
                                    lang().ManualUpdatePrice
                                 }}</span>
                                 <span [hidden]="!(sourceID == 7)">{{
                                    lang().ManualUpdateStaleThreshold
                                 }}</span>
                                 <span [hidden]="!(sourceID == 8)">{{
                                    lang().ManualUpdatePartQtyThreshold
                                 }}</span>
                                 <span [hidden]="!(sourceID == 11)">{{
                                    lang().PartQtyStatusChange
                                 }}</span>
                                 <span [hidden]="!(sourceID == 12)">{{
                                    lang().Import
                                 }}</span>
                                 <span [hidden]="!(sourceID == 13)">{{
                                    lang().ManualUpdateLocation
                                 }}</span>
                                 <span [hidden]="!(sourceID == 14)">{{
                                    lang().ManualUpdateSupplier
                                 }}</span>
                              </b>
                           </lim-ui-filter-input>
                        }
                        @if (dateState) {
                           <lim-ui-filter-input (clearFilterEvent)="clearDateFilter()">
                              <label inputLabel> {{ lang().ActiveFilter }}:</label>
                              <b additionalContent>
                                 <span [hidden]="!(dateDuration == 86400 * 7 * 1000)">{{
                                    lang().Last7Days
                                 }}</span>
                                 <span [hidden]="!(dateDuration == 86400 * 14 * 1000)">{{
                                    lang().Last14Days
                                 }}</span>
                                 <span [hidden]="!(dateDuration == 86400 * 30 * 1000)">{{
                                    lang().Last30Days
                                 }}</span>
                                 <span [hidden]="!(dateDuration == 86400 * 60 * 1000)">{{
                                    lang().Last60Days
                                 }}</span>
                              </b>
                           </lim-ui-filter-input>
                        }
                        @if (customDate) {
                           <lim-ui-filter-input (clearFilterEvent)="clearDateFilter()">
                              <label inputLabel> {{ lang().ActiveFilter }}:</label>
                              <span additionalContent
                                 ><b>{{ date1 | betterDate: "date" }}</b> {{ lang().to }}
                                 <b>{{ date2 | betterDate: "date" }}</b></span
                              >
                           </lim-ui-filter-input>
                        }
                     </lim-ui-filter-input-wrapper>
                  </lim-ui-filters-wrapper>
               </div>

               <!-- Logs Table -->
               <div
                  class="listItem listItemHeader lim-ui-responsive-table-header"
                  [hidden]="!!noSearchResults"
               >
                  <sort-column
                     class="col-md-3 mobileLargeDisappear"
                     column="logDate"
                     name="{{ lang().Date }}"
                     [(bind)]="sortBind"
                     [sortFn]="sortLogsBySortBind"
                  />
                  <sort-column
                     class="col-md-6 mobileLargeDisappear"
                     column="sourceID"
                     name="{{ lang().Entry }}"
                     [(bind)]="sortBind"
                     [sortFn]="sortLogsBySortBind"
                  />
                  <sort-column
                     class="col-md-3 mobileLargeDisappear"
                     column="userLastName"
                     name="{{ lang().User }}"
                     [(bind)]="sortBind"
                     [sortFn]="sortLogsBySortBind"
                  />
               </div>

               @if (loadingBar) {
                  <div>
                     <div class="loading-icon">
                        <lim-ui-loading-animation />
                     </div>
                  </div>
               }

               <div [hidden]="!noSearchResults">
                  <no-search-results />
               </div>

               @if (logs?.length > 0) {
                  <div>
                     @for (
                        log of filteredLogs
                           | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
                        track log
                     ) {
                        <div class="listItem lim-ui-responsive-table-row">
                           <div
                              class="col-md-3 log-item-date-area lim-ui-responsive-table-cell"
                           >
                              {{ log?.logDate | betterDate: "dateTime" }}
                           </div>

                           <div
                              class="col-md-6 log-item-content-area lim-ui-responsive-table-cell"
                           >
                              @if (log?.sourceID == 1) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 2) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 3) {
                                 <div>
                                    <div
                                       placement="top"
                                       limUiTooltip="{{
                                          lang()
                                             .ThisLogWasCreatedWhenAUserManuallyAdjustedThePartQty
                                       }}"
                                    >
                                       <lim-ui-icon
                                          class="icon-styling"
                                          [icon]="log.diff > 0 ? arrowDown : arrowUp"
                                          iconSize="small"
                                          [iconColor]="
                                             log.diff > 0 ? colorRed : colorGreen
                                          "
                                       />
                                       <span [limbleHtml]="log.display"></span>
                                    </div>
                                    @if (
                                       log.QtyReasonTooLong == true && logHasReason(log)
                                    ) {
                                       <a class="cursor" (click)="popAlert(log)"
                                          >- {{ lang().Reason }}</a
                                       >
                                    }
                                 </div>
                              }

                              @if (log?.sourceID == 4) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 5) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       [icon]="log.diff > 0 ? arrowDown : arrowUp"
                                       iconSize="small"
                                       [iconColor]="log.diff > 0 ? colorRed : colorGreen"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                    @if (log.task) {
                                       <span>
                                          -
                                          <a (click)="popTask(log)" class="cursor"
                                             ><span
                                                [limbleHtml]="log.task.checklistName"
                                             ></span>
                                             - #{{ log.task.checklistID }}</a
                                          >
                                       </span>
                                    } @else {
                                       <span> - ({{ lang().DeletedOrHidden }}) </span>
                                    }
                                 </div>
                              }

                              @if (log?.sourceID == 6) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 7) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 8) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 9) {
                                 <div>
                                    <lim-ui-icon
                                       icon="trashCanRegular"
                                       class="icon-styling"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 10) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="squareArrowUpRight"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang()
                                             .ClickThisToSeeWhichTaskCausedThisPartToPassItsQtyThreshold
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>

                                    @if (log.task) {
                                       <span>
                                          -
                                          <a (click)="popTask(log)" class="cursor">
                                             {{ lang().Task }}
                                             @if (log.associatedID > 0) {
                                                #{{ log.associatedID }}
                                             }
                                          </a>
                                       </span>
                                    } @else {
                                       <span> - ({{ lang().DeletedOrHidden }}) </span>
                                    }
                                 </div>
                              }

                              @if (log?.sourceID == 11) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="triangleExclamation"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 12) {
                                 <div>
                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 13) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 14) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 15) {
                                 <div>
                                    <lim-ui-icon
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>
                                 </div>
                              }

                              @if (log?.sourceID == 16) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       [icon]="log.diff > 0 ? arrowDown : arrowUp"
                                       iconSize="small"
                                       [iconColor]="log.diff > 0 ? colorRed : colorGreen"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang()
                                             .ThisLogWasCreatedWhenCompletedTaskWasReopenedCausingAnyPArtsUsedToNoLongerBeUsed
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>

                                    @if (log.task) {
                                       <span>
                                          -
                                          <a (click)="popTask(log)" class="cursor"
                                             ><span
                                                [limbleHtml]="log.task.checklistName"
                                             ></span>
                                             - # {{ log.task.checklistID }}</a
                                          >
                                       </span>
                                    }
                                 </div>
                              }

                              @if (log?.sourceID == 17) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       [icon]="log.diff > 0 ? arrowDown : arrowUp"
                                       iconSize="small"
                                       [iconColor]="log.diff > 0 ? colorRed : colorGreen"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang()
                                             .ThisLogWasCreatedBecauseACompletedTaskPartsWereEdited
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>

                                    @if (log.task) {
                                       <span>
                                          -
                                          <a (click)="popTask(log)" class="cursor"
                                             ><span
                                                [limbleHtml]="log.task.checklistName"
                                             ></span>
                                             - # {{ log.task.checklistID }}</a
                                          >
                                       </span>
                                    }
                                 </div>
                              }

                              @if (log?.sourceID == 18) {
                                 <div>
                                    <div
                                       placement="top"
                                       limUiTooltip="{{
                                          lang()
                                             .ThisLogWasCreatedPartsWereReceivedOnAPurchaseOrder
                                       }}"
                                    >
                                       <lim-ui-icon
                                          class="icon-styling"
                                          [icon]="log.diff > 0 ? arrowDown : arrowUp"
                                          iconSize="small"
                                          [iconColor]="
                                             log.diff > 0 ? colorRed : colorGreen
                                          "
                                       />

                                       <span [limbleHtml]="log.display"></span>
                                       -
                                       <a (click)="popPR(log)" class="cursor">
                                          {{ lang().Bill }} {{ log.prNumber }}
                                       </a>
                                    </div>
                                    @if (
                                       logHasReason(log) && log.QtyReasonTooLong == false
                                    ) {
                                       <span class="cursor"
                                          >- {{ log.manualChangePartQtyReason }}</span
                                       >
                                    }
                                    @if (
                                       log.QtyReasonTooLong == true && logHasReason(log)
                                    ) {
                                       <a class="cursor" (click)="popAlert(log)"
                                          >- {{ lang().Reason }}</a
                                       >
                                    }
                                 </div>
                              }

                              @if (log?.sourceID == 19) {
                                 <div>
                                    <div>
                                       <lim-ui-icon
                                          icon="handBackPointUp"
                                          iconSize="small"
                                          class="icon-styling"
                                          placement="top"
                                          limUiTooltip="{{
                                             lang().ThisLogWasCreatedWhenThePartWasUpdated
                                          }}"
                                       />

                                       <span [limbleHtml]="log.display"></span>
                                       @if (part.partID == 0) {
                                          <span>
                                             -

                                             {{ log.partName }}{{ log.partNumberStr }}
                                          </span>
                                       }
                                    </div>
                                 </div>
                              }

                              @if (log?.sourceID == 20) {
                                 <div
                                    limUiTooltip="{{
                                       lang().ManuallyAdjustingExtraBatchTooltip
                                    }}"
                                 >
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="arrowDown"
                                       iconSize="small"
                                       iconColor="danger"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                    <a (click)="popPR(log)" class="cursor">
                                       {{ lang().Bill }} {{ log.prNumber }}
                                    </a>
                                    <span> - {{ lang().ManuallyAdjusted }} </span>
                                    @if (
                                       logHasReason(log) && log.QtyReasonTooLong == false
                                    ) {
                                       <span class="cursor"
                                          >- {{ log.manualChangePartQtyReason }}</span
                                       >
                                    }
                                    @if (
                                       log.QtyReasonTooLong == true && logHasReason(log)
                                    ) {
                                       <a class="cursor" (click)="popAlert(log)"
                                          >- {{ lang().Reason }}</a
                                       >
                                    }
                                 </div>
                              }
                              @if (log?.sourceID == 21) {
                                 <div
                                    limUiTooltip="{{ lang().AssociatedVendorLogTooltip }}"
                                 >
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="addressCard"
                                       iconSize="small"
                                    />
                                    <span [limbleHtml]="log.display"></span>
                                    <span>{{ lang().VendorAssociation }} </span>
                                    @if (log.newValue == 1) {
                                       <span
                                          ><b>{{ lang().added }}</b> -
                                       </span>
                                    }
                                    @if (log.newValue == -1) {
                                       <span
                                          ><b>{{ lang().removed }}</b> -
                                       </span>
                                    }
                                    <a (click)="popVendor(log)" class="cursor">
                                       {{ log.vendorName }}
                                    </a>
                                 </div>
                              }
                              @if (log?.sourceID == 22) {
                                 <div
                                    limUiTooltip="{{ lang().AssociatedVendorLogTooltip }}"
                                 >
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>
                                    <span> - </span>
                                    <a (click)="popVendor(log)" class="cursor">
                                       {{ log.vendorName }}
                                    </a>
                                 </div>
                              }
                              @if (log?.sourceID == 23) {
                                 <div
                                    limUiTooltip="{{ lang().AssociatedVendorLogTooltip }}"
                                 >
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>
                                    <span> - </span>
                                    <a (click)="popVendor(log)" class="cursor">
                                       {{ log.vendorName }}
                                    </a>
                                 </div>
                              }
                              @if (log?.sourceID == 24) {
                                 <div limUiTooltip="{{ lang().PartCopiedLogTooltip }}">
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{ lang().PartCopiedLogTooltip }}"
                                    />

                                    <span [limbleHtml]="log.display"></span>
                                    <span> - </span>
                                    <a (click)="popPart(log)" class="cursor">
                                       {{ log.PartName }}
                                    </a>
                                 </div>
                              }
                              @if (log?.sourceID === 25) {
                                 <div>
                                    <span [limbleHtml]="log.display"></span>
                                    <b> {{ log.AssetName }} </b>
                                    <span>{{ lang().FromDifferentLocation }}</span>
                                 </div>
                              }
                              @if (
                                 log?.sourceID === 26 &&
                                 isUnitOfMeasureStockUnitChangesEnabled
                              ) {
                                 <div>
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="handBackPointUp"
                                       iconSize="small"
                                       placement="top"
                                       limUiTooltip="{{
                                          lang().ThisLogWasCreatedWhenThePartWasUpdated
                                       }}"
                                    />
                                    @if (log.displayFrom) {
                                       <span
                                          >{{ lang().StockUnitWasChangedFrom }}
                                          <strong
                                             [limUiTooltip]="log.displayFromTooltip"
                                             >{{ log.displayFrom }}</strong
                                          >
                                          {{ lang().To.toLowerCase() }}
                                          <strong [limUiTooltip]="log.displayToTooltip">{{
                                             log.displayTo
                                          }}</strong>
                                       </span>
                                    }

                                    @if (log.displayFrom === null) {
                                       {{ lang().StockUnitWasChangedTo }}
                                       <strong [limUiTooltip]="log.displayToTooltip">{{
                                          log.displayTo
                                       }}</strong>
                                    }
                                 </div>
                              }
                           </div>

                           <div class="col-md-3 lim-ui-responsive-table-cell">
                              <div>
                                 <lim-ui-icon
                                    icon="user"
                                    iconSize="small"
                                    class="icon-styling"
                                    placement="top"
                                    limUiTooltip="{{ lang().ThisWasEnteredByAUser }}"
                                 />
                                 {{ log?.userFirstName }} {{ log?.userLastName }}
                              </div>
                           </div>
                        </div>
                     }
                  </div>
               }

               @if (!restrict) {
                  <div class="comments-container hidePrint">
                     <div class="comments-instructions-and-entry-container">
                        <div class="instruction-text">
                           {{ lang().AddEntry }}
                        </div>
                     </div>
                     <div class="comment-input-box">
                        <textarea
                           class="form-control log-input-field"
                           [(ngModel)]="textareaEntry"
                        >
                        </textarea>

                        <div class="bottom-buttons-row">
                           <lim-ui-primary-button (click)="addEntry()">
                              {{ lang().Submit }}
                           </lim-ui-primary-button>
                        </div>
                        <div class="entry-alert">
                           <lim-ui-alert
                              [title]="lang().Warning"
                              [hidden]="!errorMsg"
                              alertType="warning"
                           >
                              <span [limbleHtml]="errorMsg"></span>
                           </lim-ui-alert>

                           <lim-ui-alert
                              [hidden]="!successMsg"
                              [title]="lang().Success"
                              alertType="success"
                           >
                              <span [limbleHtml]="successMsg"></span>
                           </lim-ui-alert>
                        </div>
                     </div>
                  </div>
               }

               @if (filteredLogs?.length) {
                  <div class="pagination-container">
                     <lim-ui-pagination
                        [maxSize]="5"
                        [rotate]="true"
                        [(page)]="page"
                        [pageSize]="itemsPerPage"
                        [collectionSize]="filteredLogs?.length"
                        (pageChange)="pageChanged()"
                     />
                     <lim-ui-dropdown-results-per-page
                        [selectedValue]="itemsPerPage"
                        [label]="lang().itemsPerPage"
                     >
                        <ng-container menu>
                           @for (num of ["10", "20", "50", "100"]; track num) {
                              <lim-ui-dropdown-text-item
                                 [displayString]="num"
                                 [outputString]="num"
                                 (itemSelectedEvent)="updateUIPref(num)"
                              />
                           }
                        </ng-container>
                     </lim-ui-dropdown-results-per-page>
                  </div>
               }
            </div>
         }
      </div>
   </lim-ui-panel>
}
