import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, type Observable, Subject, tap } from "rxjs";
import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import { AssetFieldDefinitionApiService } from "src/app/assets/services/asset-field-definition-api.service";
import {
   type AssetFieldDefinitionRequest,
   type AssetFieldDefinition,
   AssetFieldDataType,
   AssetFieldScopeType,
} from "src/app/assets/services/asset-field.types";
import { AssetFieldStorageSyncService } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.storage.sync.service";
import { environment } from "src/environments/environment";

const FIELD_DEFINITIONS_URL = "assets/fields";

interface HttpResponse<T = any> {
   data: T;
}

@Injectable({
   providedIn: "root",
})
export class AssetFieldDefinitionService {
   private readonly http = inject(HttpClient);
   private readonly assetFieldStorageSyncService = inject(AssetFieldStorageSyncService);
   private readonly assetFieldDefinitionApiService = inject(
      AssetFieldDefinitionApiService,
   );

   private readonly fieldDefinitionSubject$ = new Subject<AssetFieldDefinition>();

   public getDefinitionUpdates(): Observable<AssetFieldDefinition | null> {
      return this.fieldDefinitionSubject$.asObservable();
   }

   public getDefinition(fieldID: number): Observable<AssetFieldDefinition> {
      return this.http.get<AssetFieldDefinition>(
         `${environment.servicesURL()}/${FIELD_DEFINITIONS_URL}/${fieldID}`,
         { withCredentials: true },
      );
   }

   public getDefinitions(filters?: {
      scopeType?: AssetFieldScopeType;
      locationID?: number;
   }): Observable<AssetFieldDefinition[]> {
      return this.assetFieldDefinitionApiService
         .getList({
            filters: filters ?? {},
         })
         .pipe(map((response) => response.data ?? []));
   }

   public createDefinition(
      data: Partial<AssetFieldDefinitionRequest>,
   ): Observable<AssetFieldDefinition> {
      const fullRequestData = this.addDefaultValues(data);
      return this.http
         .post<AssetFieldDefinition>(
            `${environment.servicesURL()}/${FIELD_DEFINITIONS_URL}/`,
            fullRequestData,
            { withCredentials: true },
         )
         .pipe(
            tap((newField) => {
               this.fieldDefinitionSubject$.next(newField);

               //update field data for offline
               this.assetFieldStorageSyncService.syncAssetField(newField.fieldID);
            }),
         );
   }

   public updateDefinition(
      field: AssetFieldDefinition,
   ): Observable<AssetFieldDefinition> {
      return this.http
         .patch<HttpResponse>(
            `${environment.servicesURL()}/${FIELD_DEFINITIONS_URL}/${field.fieldID}`,
            field,
            { withCredentials: true },
         )
         .pipe(
            tap(() => {
               this.fieldDefinitionSubject$.next(field);

               //update field data for offline
               this.assetFieldStorageSyncService.syncAssetFieldUpdate(field.fieldID, {
                  fieldName: field.fieldName,
                  displayOnTasks: field.displayOnTasks,
               });
            }),
            map((response) => response?.data),
         );
   }

   private addDefaultValues(
      definition: Partial<AssetFieldDefinitionRequest>,
   ): AssetFieldDefinitionRequest {
      return {
         fieldName: definition?.fieldName ?? "New Field",
         fieldTypeID: definition?.fieldTypeID ?? AssetFieldTypeID.Text,
         viewableByTechFieldDefault: definition?.viewableByTechFieldDefault ?? 1,
         optionsJSON: definition?.optionsJSON ?? "",
         dateReminder1: definition?.dateReminder1 ?? null,
         dateReminder2: definition?.dateReminder2 ?? null,
         dateReminder3: definition?.dateReminder3 ?? null,
         userID: definition?.userID ?? null,
         profileID: definition?.profileID ?? null,
         displayOnTasks: definition?.displayOnTasks ?? 0,
         valueUnique: definition?.valueUnique ?? 0,
         isCustomDefault: definition?.isCustomDefault ?? 0,
         updateChildAssetFieldValue: definition?.updateChildAssetFieldValue ?? "ignore",
         lockedDefault: definition?.lockedDefault ?? 0,
         locationID: definition?.locationID ?? null,
         scopeType: definition?.scopeType ?? AssetFieldScopeType.Standardized,
         dataType: definition?.dataType ?? AssetFieldDataType.Text,
      };
   }
}
