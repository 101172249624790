<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="lang().CustomizeStatuses" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="modal-info">
            <div class="info-group">
               @if (message) {
                  <div>
                     <span [limbleHtml]="message"></span>
                  </div>
               }
            </div>
         </div>

         @for (status of defaultStatuses; track status) {
            <div class="lim-ui-list-item">
               <div class="lim-ui-list-item-content">
                  <div class="list-section">
                     <div class="drag-icon-section-blank"></div>
                     <div>
                        <span class="status-name">{{ status.name }}</span> -
                        {{ status.description }}
                     </div>
                  </div>
               </div>
            </div>
         }

         <limble-tree-root
            [data]="statuses"
            [options]="treeOptions"
            (treeDrop)="onMoveNode($event)"
            #tree
         />

         <div class="lim-ui-list-item">
            <div class="lim-ui-list-item-content">
               <div class="list-section">
                  <div class="drag-icon-section-blank"></div>
                  <div>
                     <span class="status-name">{{ statusLastStep.name }}</span> -
                     {{ statusLastStep.description }}
                  </div>
               </div>
            </div>
         </div>

         <div class="bottom-buttons">
            <lim-ui-secondary-button icon="plus" (click)="addStatus()">{{
               lang().AddAStatus
            }}</lim-ui-secondary-button>
         </div>
      </lim-ui-info-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
