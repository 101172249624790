<div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
   <span class="lim-ui-mobile-table-column-label"
      >{{ lang().LaborCostEfficiency }}:
   </span>
   @if (task && task.laborCostEfficiencyRate) {
      <span>{{ task.laborCostEfficiencyRate }}%</span>
   } @else {
      <span>-</span>
   }
</div>
