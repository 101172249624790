import { Injectable, QueryList } from "@angular/core";
import { merge } from "rxjs";
import type { ChkItem } from "src/app/tasks/components/chkItemElement/chkItem.element.component";

@Injectable({
   providedIn: "root",
})
export class TaskTreeSnapshot {
   public getTaskTreeSnapshotSize(topLevelItemsCollection: QueryList<ChkItem>): number {
      if (!topLevelItemsCollection.length) {
         return 0;
      }
      const flatItems = this.flattenTree(topLevelItemsCollection);
      return flatItems.length;
   }

   public getRadioOrDropdownItemsWithResponses(
      topLevelItemsCollection,
      clickedItemID: number,
   ): Array<ChkItem> {
      const flatItems = this.flattenTree(topLevelItemsCollection, clickedItemID);
      return flatItems.filter((component) => {
         const item = component.item();
         if (item?.itemTypeID === 2 || item?.itemTypeID === 4) {
            return item.itemResponse !== null && item.itemResponse !== "";
         }
         return false;
      });
   }

   private flattenTree(itemTree: QueryList<ChkItem>, itemIDToOmit?: number) {
      return itemTree.reduce((acc, cur): QueryList<ChkItem> => {
         const collectedQueryList = new QueryList<ChkItem>();
         if (itemIDToOmit !== undefined && cur.item().itemID === itemIDToOmit) {
            collectedQueryList.reset([...acc.toArray()]);
            return collectedQueryList;
         }
         const nonSubItems = this.flattenTree(cur.directChildItems ?? new QueryList());
         const subDropdownItems = this.flattenTree(
            cur.subDropdownChildItems ?? new QueryList(),
         );
         const subRadioItems = this.flattenTree(
            cur.subDropdownChildItemsCollection ?? new QueryList(),
         );

         collectedQueryList.reset([
            ...nonSubItems.toArray(),
            ...subDropdownItems.toArray(),
            ...subRadioItems.toArray(),
            ...acc.toArray(),
         ]);

         return collectedQueryList;
      }, itemTree);
   }

   public generateTreeChangeObservable(topLevelItemsCollection: QueryList<ChkItem>) {
      const flattenedTree = this.flattenTree(topLevelItemsCollection);
      const arrayOfCombinedQueryListObservables = flattenedTree.map((chkItem) => {
         if (
            !chkItem.directChildItems ||
            !chkItem.subDropdownChildItems ||
            !chkItem.subDropdownChildItemsCollection
         ) {
            return new QueryList().changes;
         }
         return merge(
            chkItem.directChildItems.changes,
            chkItem.subDropdownChildItems.changes,
            chkItem.subDropdownChildItemsCollection.changes,
         );
      });
      const combinedValuesObservable = merge(...arrayOfCombinedQueryListObservables);
      return combinedValuesObservable;
   }
}
