import { AsyncPipe } from "@angular/common";
import { Component, inject, input, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   isPhoneScreenSize$,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   type ModalResult,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import {
   PickUserOrProfileComponent,
   type PickUserOrProfileSelection,
} from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.component";
import type { PickUserOrProfileData } from "src/app/users/components/pick-user-or-profile/pick-user-or-profile.types";

@Component({
   selector: "pick-user-or-profile-modal",
   templateUrl: "./pick-user-or-profile-modal.component.html",
   styleUrls: ["./pick-user-or-profile-modal.component.scss"],
   imports: [
      AsyncPipe,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      FormsModule,
      BasicModalFooterComponent,
      PickUserOrProfileComponent,
   ],
})
export class PickUserOrProfileModalComponent
   implements ModalResult<PickUserOrProfileData | 0 | undefined>
{
   public readonly modalRef: LimUiModalRef<
      PickUserOrProfileModalComponent,
      PickUserOrProfileData | 0 | undefined
   > = inject(LimUiModalRef);

   public message = input.required<string>();
   public title = input.required<string>();
   public locationID = input.required<number>();
   /** @deprecated */
   public showAuditOptions = input<boolean>(false);
   /** @deprecated */
   public advancedOptions = input<boolean>(false);
   /** @deprecated */
   public initialLocked = input<boolean>(false);
   public defaultUser = input<number>(0);
   public defaultProfile = input<number>(0);
   public readonly extraUsers = input<
      Array<{
         userFirstName: string;
         userID: number;
         profileID: number;
         extra?: boolean;
         dynamicAssignment?: 0 | 1;
      }>
   >(); // custom user options (ie: current user)
   public defaultToDynamicAssignment = input<boolean>(false);
   public beforeClose = input<(dataToSubmit: PickUserOrProfileData) => Promise<void>>();

   public readonly selection = signal<PickUserOrProfileSelection | undefined>(undefined);

   public readonly isPhoneScreenSize$ = isPhoneScreenSize$;
   public readonly lang = inject(ManageLang).lang;
   private readonly alertService = inject(AlertService);

   public close(): void {
      this.modalRef.close(0);
   }

   public async submit(): Promise<void> {
      const selection = this.selection();
      if (selection === undefined) {
         throw new Error("Selection is undefined");
      }
      if ("selectedProfile" in selection) {
         const dataToSubmit: PickUserOrProfileData = {
            multiUsers: [],
            userID: 0,
            profileID: selection.selectedProfile.profileID,
            dynamicAssignment: 0,
         };
         await this.beforeClose()?.(dataToSubmit);
         this.modalRef.close(dataToSubmit);
      } else {
         if (selection.selectedUsers.length === 0) {
            const lang = this.lang();
            if (lang === undefined) {
               throw new Error("Language is undefined");
            }
            this.alertService.addAlert(lang.PleaseSelectUserOrProfile, "warning", 6000);
            return;
         }
         const dataToSubmit = this.buildMultiUserData();
         await this.beforeClose()?.(dataToSubmit);
         this.modalRef.close(dataToSubmit);
      }
   }

   private buildMultiUserData(): {
      userID: number;
      profileID: number;
      dynamicAssignment: 0 | 1;
      multiUsers: Array<number>;
   } {
      const selection = this.selection();
      if (selection === undefined || !("selectedUsers" in selection)) {
         throw new Error("user selection is not found");
      }
      const selectedUsers = selection.selectedUsers;
      if (selectedUsers.length === 1) {
         return {
            userID: selectedUsers[0].userID,
            profileID: selectedUsers[0].profileID ?? 0,
            dynamicAssignment: selectedUsers[0].dynamicAssignment ?? 0,
            multiUsers: [],
         };
      }
      return {
         userID: 0,
         profileID: 0,
         dynamicAssignment: 0,
         multiUsers: selectedUsers.map((user) => user.userID),
      };
   }
}
