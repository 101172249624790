import type { OnInit } from "@angular/core";
import {
   ChangeDetectorRef,
   Component,
   computed,
   EventEmitter,
   inject,
   Input,
   input,
   Output,
} from "@angular/core";
import {
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   IconComponent,
   ModalService,
   TableButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CustomizeStatuses } from "src/app/settings/components/customizeStatusesModal/customizeStatuses.modal.component";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "status-button-legacy",
   templateUrl: "./status-button-legacy.component.html",
   styleUrls: ["./status-button-legacy.component.scss"],
   imports: [
      DropdownComponent,
      TableButtonComponent,
      TooltipDirective,
      DropdownItemComponent,
      DropdownDividerComponent,
      IconComponent,
      OrderByPipe,
   ],
})
export class StatusButtonLegacyComponent implements OnInit {
   @Input() taskIsCompleted: boolean = false;
   @Input({ required: true }) taskID!: number;
   @Input({ required: true }) statusID!: number | null;
   @Input({ required: true }) locationID!: number;
   @Input() statusList: any = [];
   @Input() superUser: boolean = false;
   @Output() readonly dropdownClickEvent = new EventEmitter();
   @Input() fixedWidthTableButton: boolean = false;
   public useFullName = input<boolean>(false);
   protected statusInfo: { statusName: string; statusAbbr: string } | undefined;

   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageStatus = inject(ManageStatus);
   private readonly changeDetectorRef = inject(ChangeDetectorRef);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.statusInfo = this.manageTask.getStatusInfo(this.statusID);
   }

   public updateTaskStatusID = (status) => {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.ChangeAnOpenTasksPriorityLevel,
         )
      ) {
         this.alertService.addAlert(this.lang().cred101Fail, "danger", 10000);
         return;
      }

      // If they try to change it to completed via the dropdown, we need to display a message instead
      if (status.statusID == 2) {
         this.alertService.addAlert(
            this.lang().ChangeStatusToCompletedWarning,
            "warning",
            10000,
         );
         return;
      }

      if (Number(status.statusID) === Number(this.statusID)) {
         return;
      }

      const noteHiddenFromExternal = Number(
         this.manageUser.getCurrentUser().userInfo.noteHiddenFromExternalFlag,
      );

      this.manageTask
         .updateTaskStatusID(
            this.taskID,
            status.statusID,
            this.statusID,
            noteHiddenFromExternal,
         )
         .then((answer: any) => {
            if (answer.data.success === true) {
               this.statusID = status.statusID;
               this.statusInfo = this.manageTask.getStatusInfo(this.statusID);
               this.runChangeDetection();

               this.alertService.addAlert(
                  `<i class='fa-regular fa-square-check fa-fw'></i> ${this.lang().StatusSuccessfullyChangedTo} <b>${this.statusInfo?.statusName}`,
                  "success",
                  5000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   };

   editStatuses = () => {
      const instance = this.modalService.open(CustomizeStatuses);
      this.paramsService.params = {
         modalInstance: instance,
      };
      instance.result.then(() => {
         this.statusList = this.manageStatus.getStatusList();
         this.statusInfo = this.manageTask.getStatusInfo(this.statusID);
         this.runChangeDetection();
      });
   };

   private runChangeDetection() {
      this.changeDetectorRef.detectChanges();
   }
}
