import { inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import type { CollectionRequest } from "src/app/lite/local-db/resources/collection/collection.resource.utils.types";
import {
   extraTimeDataLimitInitializer,
   extraTimeInitializer,
   filteredAndDataLimitExtraTimeInitializer,
   filteredExtraTimeInitializer,
} from "src/app/lite/local-db/resources/collection/task/extra-time/extra-time.initializer";
import { extraTimeSchema } from "src/app/lite/local-db/resources/collection/task/extra-time/extra-time.schema";
import { ExtraTimeStorageService } from "src/app/lite/local-db/resources/collection/task/extra-time/extra-time.storage.service";
import { fetchValidData$ } from "src/app/lite/local-db/resources/resource.initializer";
import { refetchValidData } from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { Flags } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { z } from "zod";

@Injectable({
   providedIn: "root",
})
export class ExtraTimeStorageSyncService extends StorageSyncService {
   private readonly extraTimeStorageService = inject(ExtraTimeStorageService);
   private readonly launchFlagsService = inject(LaunchFlagsService);
   public constructor() {
      super();
   }

   public async syncExtraTimeByID(extraTimeID: number): Promise<void> {
      return this.sync(async () => {
         const { url } = await this.fetchExtraTime();
         const extraTimeObjects = await refetchValidData({
            url,
            params: {
               extraTimeIDs: [extraTimeID],
            },
            validation: z.array(extraTimeSchema),
         });

         const [extraTime] = extraTimeObjects;
         assert(
            extraTime !== undefined,
            `ExtraTime #${extraTimeID} not found in fetch when attempting to sync`,
         );

         await this.extraTimeStorageService.putExtraTime(extraTime);
      });
   }

   public async syncExtraTimeDeletion(extraTimeID: number) {
      return this.sync(async () => {
         await this.extraTimeStorageService.deleteExtraTime(extraTimeID);
      });
   }

   public async syncExtraTimeByTaskID(taskID: number) {
      return this.sync(async () => {
         const { url } = extraTimeInitializer;
         const extraTimeObjects = await firstValueFrom(
            fetchValidData$({
               url,
               params: { taskIDs: [taskID] },
               validation: z.array(extraTimeSchema),
            }),
         );
         if (extraTimeObjects.length < 1) return;

         await Promise.all(
            extraTimeObjects.map(async (extraTimeObject) =>
               this.extraTimeStorageService.putExtraTime(extraTimeObject),
            ),
         );
      });
   }

   private async fetchExtraTime(): Promise<CollectionRequest> {
      const extraTimeFilter = await this.launchFlagsService.getFlagPromise(
         Flags.EXTRA_TIME_COMPLETED_FILTER,
         false,
      );
      const extraTimeDataLimiting = await this.launchFlagsService.getFlagPromise(
         Flags.EXTRA_TIME_DATA_LIMIT,
         false,
      );
      if (extraTimeFilter) {
         if (extraTimeDataLimiting) {
            return filteredAndDataLimitExtraTimeInitializer;
         }
         return filteredExtraTimeInitializer;
      }

      if (extraTimeDataLimiting) {
         return extraTimeDataLimitInitializer;
      }
      return extraTimeInitializer;
   }
}
