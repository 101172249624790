import { computed, inject, Injectable } from "@angular/core";
import { ModalService } from "@limblecmms/lim-ui";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";

@Injectable({ providedIn: "root" })
export class QrCodeService {
   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageParts = inject(ManageParts);
   private readonly manageLocation = inject(ManageLocation);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   /**
    * @returns the URL segments of the provided URL. If it is not a limble
    * URL, returns undefined.
    */
   public parseUrlString(url: string): Array<string> | undefined {
      const strippedURL = this.removeDymoPrinterUrlFragments(url);
      const urlObj = this.tryCreateUrl(strippedURL);
      if (urlObj === undefined) return undefined;

      if (
         !urlObj.hostname.includes("limblecmms.com") &&
         urlObj.hostname !== "localhost"
      ) {
         return undefined;
      }
      const route = url
         .replace(/\/\s*$/, "")
         .split("/")
         .slice(3);
      if (
         route[0].startsWith("#!") ||
         route[0].startsWith("#%21") ||
         route[0].startsWith("?m=true#!")
      ) {
         //convert legacy urls that look like...
         //https://app.limblecmms.com/?m=true#!/mobileAsset/1022/163
         //http://localhost/?m=true#!/mobilePart/1031/16
         //URL:https://app.limblecmms.com/#!/problem/qrvspr2472/10920/6467
         route.splice(0, 1);
      } else if (route[0].startsWith("?m=true") && route[1].startsWith("#!")) {
         //convert legacy urls that look like...
         //https://app.limblecmms.com/?m=true/#!/mobileAsset/1022/163
         route.splice(0, 2);
      }
      return route;
   }

   public throwError(code?: string): never {
      const message = this.lang().InvalidQRCode;
      this.alertService.addAlert(message, "warning", 6000);
      throw new Error(`${message}: ${code}`);
   }

   public handleQrCodeDefault(code: string) {
      //strip URL because dymo printer added this stupidly.
      const url = this.removeDymoPrinterUrlFragments(code);
      // sometimes the location url doesn't update on android - if it is stuck at this same url then force a reload so it will
      // go to this location, should be rare
      if (document.location.href == url) {
         document.location.reload();
      }
      if (url.includes("limblecmms") || url.includes("localhost")) {
         window.location.href = url;
      } else {
         this.defaultQrCodeHandler(url);
      }
   }

   private defaultQrCodeHandler(code: string): void {
      const foundAsset = this.tryGetAssetFromCode(code);

      if (foundAsset !== undefined && foundAsset.assetID > 0) {
         const instance = this.modalService.open(PopAsset);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               assetID: foundAsset.assetID,
               locationID: foundAsset.locationID,
               data: {
                  restrict: false,
               },
            },
         };
         return;
      }

      const foundPart = this.tryGetPartFromCode(code);

      if (foundPart !== undefined && foundPart.partID > 0) {
         const instance = this.modalService.open(PopPart);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               partID: foundPart.partID,
               locationID: foundPart.locationID,
               data: {
                  restrict: false,
               },
            },
         };
         return;
      }

      this.alertCannotMatchScannedCode(code);
   }

   public tryGetAssetFromCode(code: string): Asset | undefined {
      const codeLowerCase = code.toLowerCase();
      for (const asset of this.manageAsset.getAssets()) {
         if (asset.assetDeleted == 1) {
            continue;
         }
         if (this.manageLocation.getLocation(asset.locationID) === undefined) {
            continue;
         }
         if (asset.assetName?.toLowerCase().includes(codeLowerCase)) {
            return asset;
         }
         for (const valueID of asset.assetValueIDs) {
            const fieldValue = this.manageAsset.getFieldValue(valueID);
            if (!fieldValue) {
               continue;
            }
            if (fieldValue.valueContent && fieldValue.valueContent != null) {
               if (
                  fieldValue.valueContent.toString().toLowerCase().includes(codeLowerCase)
               ) {
                  return asset;
               }
            }
         }
      }
      return undefined;
   }

   public tryGetPartFromCode(code: string): Part | undefined {
      const codeLowerCase = code.toLowerCase();
      for (const part of this.manageParts.getParts()) {
         if (part.partDeleted == 1) {
            continue;
         }
         if (this.manageLocation.getLocation(part.locationID) === undefined) {
            continue;
         }
         if (part.partName?.toLowerCase().includes(codeLowerCase)) {
            return part;
         } else if (part.partNumber?.toLowerCase().includes(codeLowerCase)) {
            return part;
         }
         for (const valueID of part.partValueIDs) {
            const value = this.manageParts.getFieldValue(valueID);
            if (value?.valueContent !== null) {
               if (value?.valueContent.toString().toLowerCase().includes(codeLowerCase)) {
                  return part;
               }
            }
         }
      }
      return undefined;
   }

   public alertCannotMatchScannedCode(code: string): void {
      const codeLowerCase = code.toLowerCase();
      let warningString: string = `${this.lang().WeCouldNotFindThatBarCodeInLimble} <br />`;

      if (this.copyText(codeLowerCase) === true) {
         warningString += ` | <b>${codeLowerCase} </b>${this.lang().shareInternalLink2}`;
      }

      this.alertService.addAlert(warningString, "warning", 8000);
   }

   private copyText(codeCopy: string): boolean {
      const textarea = document.createElement("textarea");
      textarea.textContent = codeCopy;
      document.body.appendChild(textarea);
      const selection: any = document.getSelection();
      const range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);
      const val = document.execCommand("copy");
      selection.removeAllRanges();
      document.body.removeChild(textarea);
      return val;
   }

   private removeDymoPrinterUrlFragments(url: string) {
      //The Dymo brand printer prepends all urls with a string that we must remove
      return url.replace("URL:", "");
   }

   private tryCreateUrl(url: string): URL | undefined {
      try {
         return new URL(url);
      } catch (error) {
         return undefined;
      }
   }
}
