import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { InstructionOptionStorageSyncService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction-option/instruction-option.storage.sync.service";
import type { CustomTagEvent } from "src/app/tasks/components/taskOptionsAdvancedSettingsModal/taskOptionsAdvancedSettings.modal.component";

@Injectable({ providedIn: "root" })
export class ManageTaskItemOption {
   private readonly options: any = {};
   private readonly axios = axios;

   private readonly instructionOptionStorageSyncService = inject(
      InstructionOptionStorageSyncService,
   );

   public constructor() {
      this.options.arr = [];
      this.options.index = {};
   }

   clearData = () => {
      this.options.arr = [];
      this.options.index = {};
   };
   getOptions = () => {
      return this.options.arr;
   };
   getOption = (optionID) => {
      return this.options.index[optionID];
   };
   /****************************************
    *@function getData
    *@purpose
    *@name getData
    *@param
    *@return
    ****************************************/
   getData = async () => {
      return this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getAllOptions" },
         data: {},
      }).then((answer) => {
         this.options.arr = answer.data.options || [];
         //Sets array based on Primary Key then binds to orginial array for easier lookup.
         for (const option of this.options.arr) {
            this.options.index[option.itemID] = option;
         }
      });
   };
   /****************************************
    *@function getTaskOptions
    *@purpose
    *@name getTaskOptions
    *@param
    *@return
    ****************************************/
   getTaskOptions = async (checklistID) => {
      return this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getTaskOptions" },
         data: { checklistID: checklistID },
      });
   };

   insOption = async (item) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "insOption",
         },
         data: {
            item: item.itemID,
         },
      });

      if (post.data?.success && post.data?.oID) {
         const instructionOptionID = Number(post.data.oID);
         this.instructionOptionStorageSyncService.syncInstructionOption(
            instructionOptionID,
         );
      }

      return post;
   };
   /****************************************
    *@function optionDelete
    *@purpose
    *@name optionDelete
    *@param
    *@return
    ****************************************/
   optionDelete = async (option, item) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "optionDelete",
         },
         data: {
            id: option.itemOptionID,
            chk: item.checklistID,
         },
      });

      if (post.data?.success) {
         this.instructionOptionStorageSyncService.syncInstructionOptionDeletion(
            option.itemOptionID,
         );
      }

      return post;
   };
   /****************************************
    *@function updateOptionDescription
    *@purpose
    *@name updateOptionDescription
    *@param
    *@return
    ****************************************/
   updateOptionDescription = async (option, item) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateOptionDescription",
         },
         data: {
            option: option.itemOptionID,
            text: option.itemOptionDescription,
            chk: item.checklistID,
         },
      });

      if (post.data?.success) {
         this.instructionOptionStorageSyncService.syncInstructionOption(
            option.itemOptionID,
         );
      }

      return post;
   };
   /****************************************
    *@function updateOptionText
    *@purpose
    *@name updateOptionText
    *@param
    *@return
    ****************************************/
   updateOptionText = async (option, item) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateOptionText",
         },
         data: {
            option: option.itemOptionID,
            text: option.itemOptionText,
            chk: item.checklistID,
         },
      });

      if (post.data?.success) {
         this.instructionOptionStorageSyncService.syncInstructionOption(
            option.itemOptionID,
         );
      }

      return post;
   };
   /****************************************
    *@function updateOptionCollapsed
    *@purpose
    *@name updateOptionCollapsed
    *@param
    *@return
    ****************************************/
   updateOptionCollapsed = async (option, val) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateOptionCollapsed",
         },
         data: {
            itemOptionID: option.itemOptionID,
            val: val,
         },
      });

      return post;
   };
   /****************************************
    *@function updateItemCollapsed
    *@purpose
    *@name updateItemCollapsed
    *@param
    *@return
    ****************************************/
   updateItemCollapsed = async (item, val) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateItemCollapsed",
         },
         data: {
            itemID: item.itemID,
            val: val,
         },
      });

      return post;
   };
   /****************************************
    *@function updateOptionCustomTagToAdd
    *@purpose
    *@name updateOptionCustomTagToAdd
    *@param
    *@return
    ****************************************/
   updateOptionCustomTagToAdd = async (itemOptionID, customTagToAdd) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateOptionCustomTagToAdd",
         },
         data: {
            itemOptionID: itemOptionID,
            customTagToAdd: customTagToAdd,
         },
      });

      if (post.data?.success) {
         this.instructionOptionStorageSyncService.syncInstructionOption(itemOptionID);
      }

      return post;
   };

   public async updateOptionCustomTagToAddEvent(
      itemOptionID: number,
      event: CustomTagEvent,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateOptionCustomTagToAddEvent",
         },
         data: {
            itemOptionID: itemOptionID,
            event: event,
         },
      });

      if (post.data?.success) {
         this.instructionOptionStorageSyncService.syncInstructionOption(itemOptionID);
      }

      return post;
   }

   /****************************************
    *@function getOptionCustomTag
    *@purpose
    *@name getOptionCustomTag
    *@param
    *@return
    ****************************************/
   getOptionCustomTag = async (itemOptionID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "getOptionCustomTag",
         },
         data: {
            itemOptionID: itemOptionID,
         },
      });
      return post;
   };
   /****************************************
    *@function updateOptionCustomTagToRemove
    *@purpose
    *@name updateOptionCustomTagToRemove
    *@param
    *@return
    ****************************************/
   updateOptionCustomTagToRemove = async (itemOptionID, customTagToRemove) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateOptionCustomTagToRemove",
         },
         data: {
            itemOptionID: itemOptionID,
            customTagToRemove: customTagToRemove,
         },
      });

      if (post.data?.success) {
         this.instructionOptionStorageSyncService.syncInstructionOption(itemOptionID);
      }

      return post;
   };

   public async updateOptionCustomTagToRemoveEvent(
      itemOptionID: number,
      event: CustomTagEvent,
   ): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateOptionCustomTagToRemoveEvent",
         },
         data: {
            itemOptionID: itemOptionID,
            event: event,
         },
      });

      if (post.data?.success) {
         this.instructionOptionStorageSyncService.syncInstructionOption(itemOptionID);
      }

      return post;
   }
}
