import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import { TranslateService } from "src/app/languages/i18n/translate.service";
import { ButtonComponent } from "src/app/shared/empowered/base/button/button.component";
import { MenuComponent } from "src/app/shared/empowered/base/menus/menu/menu.component";
import type { MenuItem } from "src/app/shared/empowered/base/menus/menus.models";
import { BaseCellRendererComponent } from "src/app/shared/empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import {
   isTaskTypeIcon,
   type TaskTypeCellViewModel,
   TaskTypeIcon,
} from "src/app/shared/empowered/base/table/components/cells-renderers/task-type-cell-renderer/task-type-cell-renderer.models";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   selector: "task-type-cell",
   templateUrl: "./task-type-cell-renderer.component.html",
   imports: [ButtonComponent, MenuComponent],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskTypeCellRendererComponent extends BaseCellRendererComponent<TaskTypeCellViewModel> {
   protected readonly facade = inject(TasksFacadeService);
   private readonly t = inject(TranslateService);

   protected get viewModel(): TaskTypeCellViewModel {
      return this.cell ?? {};
   }

   protected get isDisabled(): boolean {
      return (
         (this.viewModel?.isCompleted ?? false) ||
         this.viewModel?.checklistTemplateOld === 5
      );
   }

   protected get iconName(): Aliases {
      const icon = this.viewModel?.taskTypeIcons;
      return isTaskTypeIcon(icon)
         ? (icon as Aliases)
         : (TaskTypeIcon.UnplannedWorkOrder as Aliases);
   }

   protected get label(): string | undefined {
      return this.viewModel?.typeLabel;
   }

   protected menuItems = computed<MenuItem[]>(() => [
      {
         label: this.t.instant("changeTasksType"),
         tooltip: this.t.instant("changeTasksTypeTooltip"),
         icon: "penToSquare",
         command: () => {
            this.onChangeTaskType();
         },
      },
   ]);

   protected onChangeTaskType(): void {
      const { isCompleted, checklistID, locationID } = this.viewModel;
      if (!isCompleted || !checklistID || !locationID) return;
      this.facade.changeTaskType(isCompleted, checklistID, locationID);
   }
}
