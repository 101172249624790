<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()">
      @if (!isInstructionSet) {
         <lim-ui-secondary-button (click)="popPickInstructionSet()" modalTitle>
            {{ lang().InstructionSetTemplates }}
         </lim-ui-secondary-button>
      }
   </lim-ui-basic-modal-header>
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         {{ lang().PleaseSelectAnInstructionType }}
      </lim-ui-info-panel>

      <div
         class="lim-ui-fonts-h2 section-header"
         innerHTML="{{ lang().BasicInstructions }}"
      ></div>
      <lim-ui-panel>
         @for (item of simpleItems; track item) {
            <div
               class="lim-ui-list-item lim-ui-selectable-list-item"
               (click)="selectItem(item)"
               [ngClass]="{
                  'lim-ui-selected-list-item':
                     taskItemsExtraInfo.get(item.itemTypeID)?.selected == true,
               }"
            >
               <div class="list-item-content-group">
                  <div class="lim-ui-list-item-content">
                     @if (taskItemsExtraInfo.get(item.itemTypeID)?.selected == true) {
                        <lim-ui-icon icon="check" iconColor="success" iconSize="small" />
                     }
                     @if (item.itemIcon) {
                        <lim-ui-icon
                           [icon]="item.itemIcon | iconAlias"
                           iconSize="small"
                        />
                     }
                     @if (item.itemTypeDescription) {
                        <div [limUiTooltip]="item.itemTypeDescription">
                           <!--ItemTypeID 12 is for assign audit tasks.  This is not applicable for limblecmms and was depreciated-->
                           {{ item.itemTypeName }}
                        </div>
                     }
                  </div>
               </div>
            </div>
         }
      </lim-ui-panel>
      <div
         class="lim-ui-fonts-h2 section-header"
         innerHTML="{{ lang().AdvancedInstructions }}"
      ></div>
      <lim-ui-panel>
         @for (item of complexItems; track item) {
            <div
               class="lim-ui-list-item lim-ui-selectable-list-item"
               (click)="selectItem(item)"
               [ngClass]="{
                  'lim-ui-selected-list-item':
                     taskItemsExtraInfo.get(item.itemTypeID)?.selected == true,
               }"
            >
               <div class="list-item-content-group">
                  <div
                     class="lim-ui-list-item-content"
                     [limUiPopover]="
                        (item.itemTypeID === 10 || item.itemTypeID === 14) &&
                        !featureStartWOStartPM
                           ? startWOStartPMPopover
                           : (item.itemTypeID === 16 || item.itemTypeID === 19) &&
                               !featureCustomApprovalInstruction
                             ? startCustomApprovalInstructionPopover
                             : noPopover
                     "
                     [limUiPopoverType]="'upsell'"
                     [limUiPopoverPlacement]="'right'"
                     [limUiPopoverHidden]="
                        ((item.itemTypeID !== 10 && item.itemTypeID !== 14) ||
                           featureStartWOStartPM) &&
                        ((item.itemTypeID !== 16 && item.itemTypeID !== 19) ||
                           featureCustomApprovalInstruction)
                     "
                  >
                     @if (taskItemsExtraInfo.get(item.itemTypeID)?.selected == true) {
                        <lim-ui-icon icon="check" iconColor="success" iconSize="small" />
                     }
                     @if (item.itemIcon) {
                        <lim-ui-icon
                           [icon]="item.itemIcon | iconAlias"
                           iconSize="small"
                        />
                     }
                     <div class="icon-with-text">
                        <!--ItemTypeID 12 is for assign audit tasks.  This is not applicable for limblecmms and was depreciated-->
                        {{ item.itemTypeName }}
                     </div>
                     @if (
                        (item.itemTypeID === 10 || item.itemTypeID === 14) &&
                        !featureStartWOStartPM
                     ) {
                        <lim-ui-badge
                           class="pricing-plan-badge"
                           [label]="lang().Standard"
                           icon="stars"
                        />
                     }
                     <ng-template #startWOStartPMPopover>
                        <upsell-popover
                           [message]="lang().FeatureStartWOStartPMPopoverMessage"
                           [pricingLinkText]="lang().LearnMore"
                        />
                     </ng-template>

                     @if (
                        (item.itemTypeID === 16 || item.itemTypeID === 19) &&
                        !featureCustomApprovalInstruction
                     ) {
                        <lim-ui-badge
                           class="pricing-plan-badge"
                           [label]="lang().Enterprise"
                           icon="stars"
                        />
                     }
                     <ng-template #startCustomApprovalInstructionPopover>
                        <upsell-popover
                           [message]="
                              lang().FeatureCustomApprovalInstructionPopoverMessage
                           "
                           [pricingLinkText]="lang().LearnMore"
                        />
                     </ng-template>
                  </div>
                  <ng-template #noPopover />
               </div>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Select }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
