import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { validate } from "src/app/shared/rxjs-operators/validate";
import type {
   SetCurrencyBodyDto,
   SetCurrencyResponseDto,
} from "src/app/users/models/php/account-settings.dto";
import { SetCurrencyResponseDtoSchema } from "src/app/users/models/php/account-settings.dto";

@Injectable({ providedIn: "root" })
export class AccountSettingsPhpApiService {
   private readonly http = inject(HttpClient);
   private readonly url = `phpscripts/manageUser.php`;

   public setCurrency({
      currencyCode,
      currencyID,
   }: SetCurrencyBodyDto): Observable<SetCurrencyResponseDto> {
      return this.http
         .post(
            this.url,
            {
               currencyCode,
               currencyID,
            },
            {
               params: {
                  action: "setCurrency",
               },
            },
         )
         .pipe(validate(SetCurrencyResponseDtoSchema));
   }
}
