import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   ChangeDetectorRef,
   Component,
   HostBinding,
   NgZone,
   computed,
   signal,
} from "@angular/core";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ScrollContainerComponent,
   isPhoneScreenSize$,
} from "@limblecmms/lim-ui";
import type { Observable } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PartModalLegacyComponent } from "src/app/parts/components/part-modal/part-modal-legacy/part-modal-legacy.component";
import { PartModalComponent } from "src/app/parts/components/part-modal/part-modal.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";

@Component({
   selector: "pop-part",
   templateUrl: "./popPart.modal.component.html",
   imports: [
      ScrollContainerComponent,
      NgTemplateOutlet,
      PartModalLegacyComponent,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      AlertComponent,
      LimbleHtmlDirective,
      AsyncPipe,
      PartModalComponent,
   ],
})
export class PopPart implements OnInit, OnDestroy {
   @HostBinding("class") public readonly classes = "scroll-height-inheritance";
   public locationID;
   public partID;
   public loadingBar;
   public restrict;
   public part: Part | undefined;
   public location;
   public modalInstance;
   public resolve;
   public locationWatchVarSub;
   public hasLoaded = false;
   public canViewPart;

   protected isPhoneScreenSize$: Observable<boolean> = isPhoneScreenSize$;
   protected dataLogOptions: DataLogEventDefinition | undefined;

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageParts = inject(ManageParts);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly zone = inject(NgZone);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly credService = inject(CredService);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly isJitCompletedTasksPartsEnabled = signal(false);

   public constructor() {
      setTimeout(() => {
         this.zone.run(() => {
            this.ref.detectChanges();
         });
      });
   }

   public ngOnInit() {
      this.initialize();
   }

   private async initialize() {
      this.isJitCompletedTasksPartsEnabled.set(
         await this.launchFlagsService.isEnabled(Flags.JIT_CT_PARTS),
      );

      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.dataLogOptions = this.resolve.dataLogOptions;
      this.locationID = this.resolve.locationID;
      this.partID = this.resolve.partID;
      this.loadingBar = true;
      this.restrict = this.resolve.data.restrict;
      this.canViewPart = this.credService.isAuthorized(
         this.locationID,
         this.credService.Permissions.ViewLookupAPart,
      );

      //if they are not logged in then we need to close the model because they are redirected to the login page.
      this.manageLogin.checkLogin().then((answer: any) => {
         if (answer.data.success == false) {
            this.modalInstance.close();
         }
      });

      //add a 400 ms delay so that the modal can load properly before the part loads.  This makes the tool look much smoother.
      setTimeout(() => {
         this.loadingBar = false;

         this.part = this.manageParts.getPart(this.partID);

         this.locationWatchVarSub = this.manageObservables.setSubscription(
            "locationWatchVar",
            () => {
               this.location = this.manageLocation.getLocation(this.locationID);
            },
         );
      }, 300);
      this.hasLoaded = true;
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.locationWatchVarSub);
   }

   close = () => {
      this.modalInstance.close();
   };
}
