import { computed, inject, signal } from "@angular/core";
import { BehaviorSubject, combineLatest, debounceTime, switchMap, tap } from "rxjs";
import { EntitySet } from "src/app/parts/components/shared/services/data-accumulator/entity-set";
import { DataViewerStateService } from "src/app/shared/data-viewer/data-viewer-state.service";
import { distinctUntilKeysChanged } from "src/app/shared/data-viewer/operators/distinct-until-keys-changed-operator/distinct-until-keys-changed-operator";
import type { FlannelApiService } from "src/app/shared/services/flannel-api-service";

const DEFAULT_LIMIT = 10;

export abstract class DataAccumulatorService<EntityType> {
   private readonly dataViewerStateService = inject(DataViewerStateService);

   private readonly data = signal<EntitySet<EntityType>>(
      new EntitySet(this.compareEntities.bind(this)),
   );

   private readonly _selectedItems = signal<Set<EntityType>>(new Set<EntityType>());
   private selectedItem?: EntityType | undefined;
   private selectionMode: "single" | "multiple" = "multiple";
   protected abstract apiService: FlannelApiService<EntityType>;

   public readonly dataSignal = computed(() => this.fetchedItemsSignal());
   public readonly selectedItems = computed(() => this._selectedItems());

   private readonly _total = signal<number>(0);
   public readonly total = this._total.asReadonly();

   private readonly _isLoading = signal<boolean>(false);
   public readonly isLoading = this._isLoading.asReadonly();

   private readonly refreshSubject = new BehaviorSubject(true);

   private readonly debounceTime = 500;

   private readonly fetchedItems = this.dataViewerStateService.requestOptions$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilKeysChanged([
         "filters",
         "search",
         "sort",
         "columns",
         "params",
         "pagination",
      ]),
      tap(() => {
         this._isLoading.set(true);
      }),
      switchMap((options) =>
         this.apiService.getList(options).pipe(
            tap(() => {
               this._isLoading.set(false);
            }),
         ),
      ),
   );

   private readonly fetchedItemsSignal = this.data.asReadonly();
   private readonly currentPage = computed(() => {
      return this.dataViewerStateService.requestOptions().pagination?.page ?? 1;
   });

   public constructor() {
      this.dataViewerStateService.setPageSize(DEFAULT_LIMIT);
      this.initializeData();
      // This resets the data once a refresh is triggered by removing  or adding items
      combineLatest([
         this.dataViewerStateService.requestOptionsWithoutPagination$.pipe(
            debounceTime(this.debounceTime),
            distinctUntilKeysChanged(["filters", "search", "sort", "columns", "params"]),
         ),
         this.refreshSubject.asObservable(),
      ]).subscribe(() => {
         this.initializeData();
      });

      this.fetchedItems.subscribe((entityData) => {
         this.addEntities(entityData.data ?? []);
         this._total.set(entityData.total ?? 0);
      });
   }

   /**
    * A comparator function that determines the uniqueness of entities.
    * Each child class must implement this function to define how to compare two entities.
    * The EntitySet object keeps us from adding duplicate objects to the set.
    * @param a - The first entity to compare.
    * @param b - The second entity to compare.
    * @returns `true` if the entities are considered equal, `false` otherwise.
    */
   protected abstract compareEntities(a: EntityType, b: EntityType): boolean;

   public addEntities(newItems: Array<EntityType>) {
      const currentData = this.data();
      newItems.forEach((item) => currentData.add(item));
      this.data.set(new EntitySet(this.compareEntities.bind(this), currentData));
   }

   public refreshDataAccumulated(): void {
      this.refreshSubject.next(true);
   }

   public fetch(): void {
      const page = this.currentPage() ?? 0;
      this.dataViewerStateService.setPage(page + 1);
   }

   public toggleSelectItem(entity: EntityType): void {
      if (this.selectionMode === "single") {
         if (this.selectedItem && this.compareEntities(this.selectedItem, entity)) {
            this.selectedItem = undefined;
         } else {
            this.selectedItem = entity;
         }
         return;
      }
      if (this._selectedItems().has(entity)) {
         const oldSelectedItems = this._selectedItems();
         oldSelectedItems.delete(entity);
         this._selectedItems.set(new Set(oldSelectedItems));
      } else {
         const oldSelectedItems = this._selectedItems();
         oldSelectedItems.add(entity);
         this._selectedItems.set(new Set(oldSelectedItems));
      }
   }

   public isItemSelected(entity: EntityType): boolean {
      if (this.selectionMode === "single") {
         if (this.selectedItem) {
            return this.compareEntities(this.selectedItem, entity);
         }
         return false;
      }
      return this._selectedItems().has(entity);
   }

   public getSelectedItems(): Array<EntityType> {
      if (this.selectionMode === "single") {
         return this.selectedItem === undefined ? [] : [this.selectedItem];
      }
      return Array.from(this._selectedItems());
   }

   public setSingleSelection(singleSelection: boolean): void {
      if (singleSelection) {
         this.selectionMode = "single";
         this._selectedItems.set(new Set());
      } else {
         this.selectionMode = "multiple";
         this.selectedItem = undefined;
      }
   }

   public deleteEntity(entity: EntityType): boolean {
      this._total.update((oldTotal) => {
         return oldTotal - 1;
      });
      return this.data().delete(entity);
   }

   private initializeData(): void {
      this._isLoading.set(true);

      this.clearDataSet();

      this._selectedItems.set(new Set());

      // Whenever we are showing the first page, we need to reset the page to 0
      // otherwise the observable won't trigger since it didn't change the
      // request options
      const currentPage = this.dataViewerStateService.page();
      if (currentPage === 1) {
         this.dataViewerStateService.setPage(0);
      }
      this.dataViewerStateService.setPage(1);
   }

   private clearDataSet(): void {
      // Creates a new EntitySet with the compareEntities function
      // This is necessary so we clear the data and start fresh
      this.data.set(new EntitySet(this.compareEntities.bind(this)));
   }
}
