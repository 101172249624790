import type { FilterBase } from "src/app/dashboards/filters/index";
import { TaskEntityType } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import type { TaskLookup } from "src/app/tasks/types/task.types";

export class TaskTypeFilter implements FilterBase {
   public readonly filterType = "taskType" as const;
   public readonly displayString: string;
   public readonly checklistTemplateOld: number;

   public constructor(public readonly taskTypeIndicator: TaskEntityType) {
      if (this.taskTypeIndicator === TaskEntityType.PlannedMaintenances) {
         this.displayString = "Planned Maintenance";
         this.checklistTemplateOld = 1;
      } else if (this.taskTypeIndicator === TaskEntityType.UnplannedWorkOrders) {
         this.displayString = "Unplanned Work Orders";
         this.checklistTemplateOld = 2;
      } else if (this.taskTypeIndicator === TaskEntityType.PlannedWorkOrders) {
         this.displayString = "Planned Work Orders";
         this.checklistTemplateOld = 4;
      } else {
         throw new Error("bad taskTypeIndicator");
      }
   }

   public filterFunction(tasks: TaskLookup): TaskLookup {
      return tasks.filter((task) => {
         if (task.checklistTemplateOld === null) return false;
         return Number(task.checklistTemplateOld) === this.checklistTemplateOld;
      });
   }

   public getValue(): TaskEntityType {
      return this.taskTypeIndicator;
   }
}
