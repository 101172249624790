import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, ViewChild, computed } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconButtonComponent,
   ModalService,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   SearchBoxComponent,
   SecondaryButtonComponent,
   TooltipDirective,
   manageDemo,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import { debounceTime, filter, type Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { HierarchyContainerComponent } from "src/app/shared/components/global/hierarchy/hierarchy-container-component/hierarchy-container.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import {
   WORK_REQUEST_TEMPLATE_BATCH_ID,
   type HierarchyNode,
   type HierarchyOptions,
} from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { ImportWOTemplates } from "src/app/tasks/components/importWOTemplatesModal/importWOTemplates.modal.component";
import { PickNewTemplateComponent } from "src/app/tasks/components/pickNewTemplateModal/pick-new-template.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { QRCodesStartTask } from "src/app/tasks/components/qrCodesStartTaskModal/qrCodesStartTask.modal.component";
import { TaskTemplateType } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type { TemplateHierarchyNode } from "src/app/tasks/components/shared/services/task-templates-hierarchy/template-hierarchy.service";
import { TemplateHierarchyService } from "src/app/tasks/components/shared/services/task-templates-hierarchy/template-hierarchy.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task, TaskLookup } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "work-order-templates",
   templateUrl: "./work-order-templates.component.html",
   styleUrls: ["./work-order-templates.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      SecondaryButtonComponent,
      IconButtonComponent,
      TooltipDirective,
      ModalBodyComponent,
      PanelComponent,
      SearchBoxComponent,
      HierarchyContainerComponent,
      NoSearchResults,
      AlertComponent,
      BasicModalFooterComponent,
      ReactiveFormsModule,
      LoadingAnimationComponent,
   ],
})
export class WorkOrderTemplatesComponent implements OnInit, OnDestroy {
   @ViewChild("hierarchy") hierarchyContainer?: HierarchyContainerComponent;
   public tasks: TaskLookup = new Lookup("checklistID");
   public modalInstance;
   public resolve;
   public message;
   public title;
   public locationID;
   public woTemplateLocked;
   public letUserPick;
   public data;
   public tasksLength;
   public treeData;
   public tasksWatchVarSub;
   public hierarchyOptions: HierarchyOptions;
   private featureUnlimitedWOs: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription;

   private locationsIndex: Record<number, HierarchyNode> = {};
   private locations: Array<HierarchyNode> = [];

   protected noSearchResults: boolean = false;
   protected noTemplatesExist: boolean = false;

   public templateNodes: LimbleMap<number, TemplateHierarchyNode> = new LimbleMap();

   protected isLoading: boolean = false;
   protected search: string = "";
   protected searchControl = new FormControl<string>("");

   private readonly searchControlSub: Subscription = this.searchControl.valueChanges
      .pipe(
         debounceTime(500),
         filter((searchValue) => {
            if (
               searchValue !== null &&
               searchValue.length > 0 &&
               searchValue.length < 3
            ) {
               return false;
            }
            return true;
         }),
      )
      .subscribe((search) => {
         this.fetchSearchedTemplates(search);
      });

   private readonly manageTask = inject(ManageTask);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly templateHierarchyService = inject(TemplateHierarchyService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageLang = inject(ManageLang);

   private readonly modalCloseEventsSub = this.modalService.closeEvents
      .pipe(filter(() => this.modalService.getActiveModal() === this.modalInstance))
      .subscribe(() => {
         this.rebuildTreeData();
      });

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureUnlimitedWOs = isFeatureEnabledMap.featureUnlimitedWOs;
         },
      );

      this.hierarchyOptions = {
         idKey: "checklistID",
         selection: { singleSelection: true },
         nodeButtons: [
            {
               tooltip: this.lang().ViewQRCodesForThisTask,
               clickFunction: this.viewQRCodes.bind(this),
               icon: "qrCodeRegular",
            },
            {
               tooltip: this.lang().OpenThisTask,
               clickFunction: this.popTask.bind(this),
               icon: "pencil",
               permissionNumber: this.credService.Permissions.AddWOTemplates,
            },
            {
               tooltip: this.lang().DeleteThisTask,
               clickFunction: this.deleteWOTemplate.bind(this),
               icon: "trashCanRegular",
               iconColor: "danger",
               permissionNumber: this.credService.Permissions.DeleteWOTemplates,
            },
         ],
         submit: this.submit.bind(this),
      };
   }

   public ngOnInit() {
      this.initialize();
   }

   public async initialize() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.message = this.data.message;
      this.title = this.data.title;
      this.locationID = this.data.locationID;
      this.woTemplateLocked = Boolean(this.data.locked);
      this.letUserPick = this.data.letUserPick || false;

      if (this.locationID == 0) {
         //this is a catch incase we accidently are looking at this globally (like when editing a global work request template).  We set it at a location so it is accessible.
         this.locationID = this.manageLocation.getLocations()[0].locationID;
      }
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      await this.fetchInitialTemplateData().finally(() => {
         this.loadingBarService.remove();
      });
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeSubscription(this.tasksWatchVarSub);
      this.manageFeatureFlagsSub.unsubscribe();
      this.searchControlSub.unsubscribe();
      this.modalCloseEventsSub.unsubscribe();
   }

   private async rebuildTreeData() {
      const { locationsIndex, treeData, noSearchResults, templateNodes } =
         await this.templateHierarchyService.rebuildTreeData(
            this.treeData,
            this.templateNodes,
            {
               search: this.search,
               excludeDefaultTemplates: true,
               checklistTemplates: [
                  TaskTemplateType.woTemplate,
                  TaskTemplateType.unPlannedWoTemplate,
               ],
               notBatchIDs: [WORK_REQUEST_TEMPLATE_BATCH_ID],
            },
         );

      this.locationsIndex = locationsIndex;
      this.treeData = treeData;
      this.noSearchResults = noSearchResults;
      this.templateNodes = templateNodes;

      if (this.hierarchyContainer) {
         this.hierarchyContainer.renderInitialHierarchyItems();
      }
   }

   private async fetchInitialTemplateData() {
      this.isLoading = true;
      this.buildLocations();

      const response = await this.templateHierarchyService
         .fetchInitialData(this.locations, {
            checklistTemplates: [
               TaskTemplateType.woTemplate,
               TaskTemplateType.unPlannedWoTemplate,
            ],
            excludeDefaultTemplates: true,
            notBatchIDs: [WORK_REQUEST_TEMPLATE_BATCH_ID],
         })
         .finally(() => {
            this.isLoading = false;
         });
      if (response === undefined) {
         console.error("Error fetching searched templates in work-order-templates");
         return;
      }
      this.treeData = response;
      if (this.treeData.length === 0) {
         this.noTemplatesExist = true;
      }
   }

   protected async fetchSearchedTemplates(search: string | null) {
      this.isLoading = true;
      this.buildLocations();
      this.noSearchResults = false;

      const response = await this.templateHierarchyService
         .fetchSearchedData(this.locations, {
            search: this.search,
            excludeDefaultTemplates: true,
            checklistTemplates: [
               TaskTemplateType.woTemplate,
               TaskTemplateType.unPlannedWoTemplate,
            ],
            notBatchIDs: [WORK_REQUEST_TEMPLATE_BATCH_ID],
         })
         .finally(() => {
            this.isLoading = false;
         });
      if (response === undefined) {
         console.error("Error fetching searched templates in work-order-templates");
         return;
      }

      const { filteredTreeData, groupedResponses, noSearchResults, templateNodes } =
         response;
      this.treeData = filteredTreeData;
      this.templateNodes = templateNodes;

      this.noSearchResults = noSearchResults;

      if (search === null || search === "") {
         return;
      }

      this.templateHierarchyService.processAndMapGroupedResponse(
         groupedResponses,
         this.locationsIndex,
         this.templateNodes,
         this.search,
      );
   }

   //This needs to be an arrow function to preserve its execution context because it is called from a child element
   //it WILL NOT WORK with .bind() for reasons unknown.
   fetchMoreTemplatesAtLocation = async (locationID: number) => {
      const location = this.locationsIndex[locationID];
      await this.templateHierarchyService.fetchMoreTasksAtLocation(
         location,
         this.templateNodes,
         {
            checklistTemplates: [
               TaskTemplateType.woTemplate,
               TaskTemplateType.unPlannedWoTemplate,
            ],
            search: this.search,
            excludeDefaultTemplates: true,
            notBatchIDs: [WORK_REQUEST_TEMPLATE_BATCH_ID],
         },
      );

      if (this.hierarchyContainer) {
         this.hierarchyContainer.renderInitialHierarchyItems();
      }
   };

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      for (const node of this.templateNodes) {
         if (node.selected == true) {
            node.woTemplateLocked = this.woTemplateLocked;
            this.modalInstance.close(node);
         }
      }
   };

   letUserPickSubmit = () => {
      const obj: any = {};
      obj.checklistID = 0;
      obj.woTemplateLocked = 0;
      this.modalInstance.close(obj);
   };

   private buildLocations() {
      const { locationsIndex, filteredTreeData } =
         this.templateHierarchyService.getLocationNodesWithoutRegions();

      this.locations = filteredTreeData;
      this.locationsIndex = locationsIndex;
   }

   deleteWOTemplate = async (template: TemplateHierarchyNode) => {
      if (template.locationID === undefined) {
         throw new Error("Template has no locationID and cannot be deleted");
      }
      if (
         !this.credService.isAuthorized(
            template.locationID,
            this.credService.Permissions.DeleteWOTemplates,
         )
      ) {
         this.alertService.addAlert(this.lang().cred122Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteWOTemplateMsg,
            title: this.lang().DeleteWOTemplate,
         },
      };

      const result = await instance.result;
      if (result !== 1) {
         return;
      }
      const answer = await this.manageTask
         .deleteWOTemplate(template.checklistID, template.locationID)
         .catch(() => {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         });
      if (answer?.data.success == true) {
         this.removeNodeLocally(template);
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   };

   private removeNodeLocally(template: TemplateHierarchyNode): void {
      if (template.locationID === undefined) {
         throw new Error("Template has no locationID and cannot be deleted");
      }
      const location = this.locationsIndex[template.locationID];

      const nodeToDeleteIndex = location.nodes.findIndex(
         (node) => node.nodeID === template.checklistID,
      );

      if (nodeToDeleteIndex !== -1) {
         location.nodes.splice(nodeToDeleteIndex, 1);
      }
      this.templateNodes.delete(template.checklistID);

      if (location.nodes.length === 0) {
         const locationIndex = this.treeData.indexOf(location);
         this.treeData.splice(locationIndex, 1);
         if (this.hierarchyContainer) {
            this.hierarchyContainer.renderInitialHierarchyItems();
         }
      }
   }

   protected async newWOTemplate() {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.AddWOTemplates,
         )
      ) {
         this.alertService.addAlert(this.lang().cred122Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PickNewTemplateComponent);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().NewTemplateMsg,
            title: this.lang().NewWOTemplate,
            data: { type: "WO" },
         },
      };

      const newTemplateData = await instance.result;
      if (!newTemplateData) {
         return;
      }
      const assetID = 0;
      //if task is passed we are copying
      if (newTemplateData.task) {
         const answer = await this.manageTask.newTaskTemplateFromCopy(
            newTemplateData.task.checklistID,
            assetID,
            this.locationID,
            newTemplateData.newName,
         );

         if (answer.data.success == true) {
            this.alertService.addAlert(
               this.lang().NewTemplateSuccessMsg,
               "success",
               1000,
            );
            await this.popTask({ checklistID: answer.data.chk });
            this.search = `#${answer.data.chk}`;
            this.searchControl.setValue(this.search);
            this.noTemplatesExist = false;
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      } else {
         const type = "WO";
         const answer = await this.manageTask.newTemplate(
            assetID,
            newTemplateData.newName,
            this.locationID,
            type,
         );

         if (answer.data.success !== true) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
         this.alertService.addAlert(this.lang().NewTemplateSuccessMsg, "success", 1000);
         await this.popTask({ checklistID: answer.data.chk });
         this.search = `#${answer.data.chk}`;
         this.searchControl.setValue(this.search);
         this.noTemplatesExist = false;
      }
   }

   private async popTask(templateNode: Partial<TemplateHierarchyNode>): Promise<void> {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: templateNode.checklistID,
               title: this.lang().EditWOTemplate,
               message: this.lang().EditPMTemplateMsg,
               preview: false,
               template: true,
            },
         },
      };
      await instance.result;
   }

   protected async importWOTemplates() {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.BulkDeleteVendors,
         )
      ) {
         this.alertService.addAlert(this.lang().cred128Fail, "danger", 10000);
         return;
      }

      if (manageDemo.demo) {
         this.alertService.addAlert(
            this.lang().ImportingPMTemplateIsDisabledWhenLiveTestDriving,
            "danger",
            6000,
         );
         return;
      }

      const instance = this.modalService.open(ImportWOTemplates);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: `${this.lang().ImportAListOfWOTemplatesInto} ${
               this.manageLocation.getLocationsIndex()[this.locationID].locationName
            }`,
            locationID: this.locationID,
         },
      };
      await instance.result;
      this.fetchInitialTemplateData();
   }

   public viewQRCodes(task: Task): void {
      if (!this.featureUnlimitedWOs) {
         return;
      }

      const instance = this.modalService.open(QRCodesStartTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ViewQRCodesForTaskTooltip,
            title: this.lang().TaskQRCodes,
            startingTaskID: task.checklistID,
            type: "wo-template",
         },
      };
   }
}
