import { computed, inject, Injectable } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { DashboardCurrencyService } from "src/app/dashboards/dashboard-currency.service";
import { ManageLang } from "src/app/languages";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type {
   TaskCalculatedDisplayInfo,
   TaskDisplayData,
   TaskModalCredentials,
} from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { Task } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";

@Injectable({
   providedIn: "root",
})
export class TaskLocalViewModelMapperService {
   protected readonly manageTask = inject(ManageTask);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLang = inject(ManageLang);
   private readonly credService = inject(CredService);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly dashboardCurrencyService = inject(DashboardCurrencyService);
   private readonly manageAsset = inject(ManageAsset);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public getIsCompleted(task: Task): boolean {
      const completedDate = task.checklistCompletedDate
         ? new Date(task.checklistCompletedDate * 1000)
         : undefined;
      return completedDate !== undefined;
   }

   public getTaskTypeStr(task: Task): string {
      return this.manageTask.getTypeDisplay(task) ?? "";
   }

   public getAssignedTo(task: Task): string {
      return this.manageTask.getTaskAssignmentInfo(task).displayName;
   }

   public getRequesterInformation(task: {
      checklistEmailCN?: string | null;
      requestName?: string | null;
      requestPhone?: string | null;
   }): string {
      let requester: string;

      requester = "";
      if (task.checklistEmailCN) {
         requester += `${task.checklistEmailCN}, `;
      }
      if (task.requestName) {
         requester += `${task.requestName}, `;
      }
      if (task.requestPhone) {
         requester += `${task.requestPhone}, `;
      }

      if (requester.length > 0) {
         requester = requester.slice(0, -2);
      }
      return requester;
   }

   public getChecklistDueDateDisplay(task: Task): string {
      return this.manageTask.getTaskDueDateDisplay(task);
   }

   public getPriorityInfo(task: Task): {
      priorityLevel?: number;
      priorityName: string;
   } {
      return this.manageTask.getPriorityInfo(task.priorityID);
   }

   public getStatusInfo(
      task: Task,
   ): { statusName: string; statusAbbr: string } | undefined {
      return this.manageTask.getStatusInfo(task.statusID);
   }

   public getLocationName(task: Task): string {
      return String(this.manageLocation.getLocation(task.locationID)?.locationName ?? "");
   }

   public getChecklistPromptTimeTotal(task: Task): number {
      return this.getCalculatedInfo(task).checklistPromptTimeTotal;
   }

   public getCalculatedInfo(task: Task): TaskCalculatedDisplayInfo {
      return this.manageTask.getCalculatedTaskInfo(task);
   }

   public getChecklistTemplateOldString(task: Task): string {
      // NOTE: This can be moved to a pipe or a component since it is a view concern
      if (task.checklistTemplateOld === 1) {
         return this.lang().PM;
      } else if (
         task.checklistTemplateOld === 2 &&
         (task.checklistBatchID === 300112 || task.checklistBatchID === 10000)
      ) {
         return this.lang().WorkRequest;
      } else if (task.checklistTemplateOld === 2) {
         return this.lang().UnplannedWO;
      } else if (task.checklistTemplateOld === 4) {
         return this.lang().PlannedWO;
      } else if (task.checklistTemplateOld === 5) {
         return this.lang().CycleCount;
      }
      return "";
   }

   public getShowLocation(task: Task): boolean {
      return this.manageLocation.getSelectedLocation() === task.locationID;
   }

   public getTypeLabel(task: Task): string {
      let typeLabel = this.getTaskTypeLabel(task);

      if (!task.checklistCompletedDate && task.checklistTemplateOld !== 5) {
         // Add some extra text if the task isn't completed
         typeLabel = `${typeLabel}. ${this.lang().ClickForAFewOptions}`;
      }

      return typeLabel;
   }

   private getTaskTypeLabel(task: Task): string {
      if (task.checklistTemplate === 0) {
         if (task.checklistTemplateOld === 1) {
            return this.lang().ThisIsAPM;
         } else if (task.checklistTemplateOld === 2) {
            if (task.checklistBatchID === 300112) {
               return this.lang().ThisIsAWorkRequest;
            } else if (task.checklistBatchID === 10000) {
               return this.lang().ProblemReport;
            }
            return this.lang().ThisIsAUnplannedWO;
         } else if (task.checklistTemplateOld === 4) {
            if (task.checklistBatchID === 20501) {
               return this.lang().MaterialsRequest;
            }
            return this.lang().ThisIsAUnplannedWO;
         } else if (task.checklistTemplateOld === 5) {
            return this.lang().ThisIsACycleCountWO;
         }
      } else if (task.checklistTemplate === 1) {
         return this.lang().ThisIsAPM;
      } else if (task.checklistTemplate === 2) {
         return this.lang().ThisIsAUnplannedWO;
      } else if (task.checklistTemplate === 4) {
         return this.lang().ThisIsAPlannedWO;
      } else if (task.checklistTemplate === 5) {
         return this.lang().ThisIsACycleCountWO;
      }
      return "";
   }

   public getTaskCredentials(task: Task): TaskModalCredentials {
      let credToEditOpenTaskInstructions: boolean = false;
      if (task.checklistTemplate == 0) {
         if (task.checklistTemplateOld == 1) {
            credToEditOpenTaskInstructions = this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.EditAnOpenPMsInstructions,
            ); //viewing a PM so check cred for open Task instructions
         } else if (task.checklistBatchID == 10000 || task.checklistBatchID == 300112) {
            credToEditOpenTaskInstructions = this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.EditAnOpenWorkRequestsInstructions,
            ); //viewing WRs and PRs, etc.
         } else {
            credToEditOpenTaskInstructions = this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.EditAnOpenWOsInstructions,
            ); //viewing WOs, etc.
         }
      }

      const deleteOpenTaskCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.DeleteOpenTasks,
      );

      const deleteCompletedTaskCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.DeleteCompletedTasks,
      );

      const editCompletedTaskCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.EditCompletedTasks,
      );

      const startPOCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.StartAPO,
      );

      const tagTaskCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.TagATaskWithACustomTag,
      );

      const deleteCommentCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.RemoveTaskComments,
      );

      const requestPurchaseCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.RequestPurchases,
      );

      const recreateWorkRequestCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.RecreateWorkRequest,
      );

      const addCommentCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.AddTaskComments,
      );

      const shareTasksCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.PrintOrShareTasks,
      );

      const viewLaborCostsCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.ViewLaborCosts,
      );

      const viewInvoicesCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.ViewTaskInvoices,
      );
      const superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );

      const assetCheckInOut = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.AssetCheckInOut,
      );

      const hasLogTimeForOthersCredentials = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.LogTimeForOtherUsers,
      );

      const hasEditCommentCredential = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.EditComments,
      );

      const addPartsToOpenTasksCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.AddPartsToOpenTasks,
      );

      return {
         credToEditOpenTaskInstructions: credToEditOpenTaskInstructions,
         deleteOpenTaskCred: deleteOpenTaskCred,
         deleteCompletedTaskCred: deleteCompletedTaskCred,
         editCompletedTaskCred: editCompletedTaskCred,
         startPOCred: startPOCred,
         tagTaskCred: tagTaskCred,
         deleteCommentCred: deleteCommentCred,
         requestPurchaseCred: requestPurchaseCred,
         recreateWorkRequestCred: recreateWorkRequestCred,
         addCommentCred: addCommentCred,
         shareTasksCred: shareTasksCred,
         viewLaborCostsCred: viewLaborCostsCred,
         viewInvoicesCred: viewInvoicesCred,
         superUser: superUser,
         assetCheckInOut: assetCheckInOut,
         hasLogTimeForOthersCredentials: hasLogTimeForOthersCredentials,
         hasEditCommentCredential: hasEditCommentCredential,
         addPartsToOpenTasksCred: addPartsToOpenTasksCred,
         allowEditTemplateInstructions: true,
      };
   }

   public getTaskDisplayData(task: Task): TaskDisplayData {
      const locations: Record<number, { locationID: number; locationName: string }> =
         this.manageLocation.getLocationsIndex();
      locations[0] = { locationID: 0, locationName: "Global" }; //Global location is necessary for global WR templates.
      return {
         ...this.manageTask.getCalculatedTaskInfo(task),
         dueDateDisplay: this.manageTask.getTaskDueDateDisplay(task),
         ...this.manageTask.getDaysForTask(task),
         ...this.manageTask.getPriorityInfo(task.priorityID ?? null),
         ...this.manageTask.getStatusInfo(task.statusID ?? null),
         taskTypeIcon: this.taskTypeService.getTaskTypeIcon(task),
         typeDisplay: this.manageTask.getTypeDisplay(task) ?? "",
         locationName: this.getLocationName(task),
         locationTaskImage: this.getLocationTaskImage(task),
         locationPhone: this.getLocationPhone(task),
         locationAddress: this.getLocationAddress(task),
         locationAddress2: this.getLocationAddress2(task),
         ...this.manageTask.getTaskAssignmentInfo(task),
         //the following two calls are cast as Task because a template should never be in a 'completed'
         //state or have comments on it
         ...this.manageTask.getCompletedTaskCalculatedInfo(task),
         //TODO (TASK-827): Create new JIT note data method
         noteData: this.manageTask.buildNoteDataMapForSingleTaskLegacy(task),
      };
   }

   public getParsedSavedAssetInfo(task: Task): string[] {
      const assetInfoFromCompletion = task.assetInfoFromCompletion;
      if (!assetInfoFromCompletion) {
         return [];
      }
      try {
         return JSON.parse(assetInfoFromCompletion);
      } catch {
         console.error("Failed to parse assetInfoFromCompletion");
         return [];
      }
   }

   public getLocationTaskImage(task: Task): string {
      return String(
         this.manageLocation.getLocation(task.locationID)?.locationTaskImage ?? "",
      );
   }

   public getLocationPhone(task: Task): string {
      return String(
         this.manageLocation.getLocation(task.locationID)?.locationPhone ?? "",
      );
   }

   public getLocationAddress(task: Task): string {
      return String(
         this.manageLocation.getLocation(task.locationID)?.locationAddress ?? "",
      );
   }

   public getLocationAddress2(task: Task): string {
      return String(
         this.manageLocation.getLocation(task.locationID)?.locationAddress2 ?? "",
      );
   }

   public getAssets(task: Task): Array<Asset> {
      const assets = Array.from(this.manageAsset.getAssets().values());
      return assets.filter(
         (asset) =>
            (task.extraAssetIDs?.includes(asset.assetID) ?? false) ||
            task.assetID === asset.assetID,
      );
   }

   public getTaskTypeIcon(task: Task): Aliases {
      return this.taskTypeService.getTaskTypeIcon(task);
   }

   public getCurrencyCode(task: Task): string {
      return this.dashboardCurrencyService.getTaskCurrencyCode(task);
   }
}
