import { formatDate } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { IconComponent, ModalService, TooltipDirective } from "@limblecmms/lim-ui";
import moment from "moment-timezone";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { PopSchedules } from "src/app/schedules/popSchedulesModal/popSchedules.modal.component";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { ViewListOfSchedulesType7 } from "src/app/schedules/veiwListOfSchedulesType7Modal/viewListOfSchedulesType7.modal.component";
import { AddDateEndingPipe } from "src/app/shared/pipes/addDateEnding.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { CapitalizePipe } from "src/app/shared/pipes/capitalize.pipe";
import { FirstSecondEtcToMultiLanguagePipe } from "src/app/shared/pipes/firstSecondEtcToMultiLanguage.pipe";
import { FullDayPipe } from "src/app/shared/pipes/fullDay.pipe";
import { FullMonthPipe } from "src/app/shared/pipes/fullMonth.pipe";
import { MonthNumericToStringPipe } from "src/app/shared/pipes/monthNumericToString.pipe";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api";
import { ManageTask } from "src/app/tasks/services/manageTask";

@Component({
   selector: "recurrence-list-item",
   templateUrl: "./recurrence-list-item.component.html",
   styleUrls: ["./recurrence-list-item.component.scss"],
   imports: [
      IconComponent,
      AddDateEndingPipe,
      FirstSecondEtcToMultiLanguagePipe,
      CapitalizePipe,
      FullDayPipe,
      MonthNumericToStringPipe,
      FullMonthPipe,
      TooltipDirective,
      BetterDatePipe,
   ],
})
export class RecurrenceListItemComponent {
   public readonly recurrence = input.required<Recurrence>();
   public readonly template = input.required<TaskTemplateEntity>();
   public readonly restrict = input<boolean>(false);
   public readonly showExtraAssets = input<boolean>(false);

   protected readonly manageSchedule = inject(ManageSchedule);
   private readonly manageLang = inject(ManageLang);
   protected readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly betterDate = inject(BetterDate);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageObservables = inject(ManageObservables);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   /**
    * These watch vars are used for the data which is not loaded JIT. As the whole app is JITified
    * these will no longer be needed.
    */
   private readonly locationsWatchVar$ =
      this.manageObservables.getObservable("locationWatchVar");
   private readonly locationsLoaded = this.locationsWatchVar$
      ? toSignal(this.locationsWatchVar$)
      : undefined;

   private readonly tasksWatchVar$ =
      this.manageObservables.getObservable("tasksWatchVar");
   private readonly tasksLoadedSignal = this.tasksWatchVar$
      ? toSignal(this.tasksWatchVar$)
      : undefined;
   private readonly assetsLoadedSignal = toSignal(this.manageAsset.assetsLoaded());

   /**
    * The recurrence reoccurFlds can store multiple different types. These private signals expose multiple
    * options to help improve type safety for the template.
    */

   private readonly reoccurFieldOneNumberSignal = computed(() => {
      const reoccurFieldOne = Number(this.recurrence().reoccurFld1);
      if (isNaN(reoccurFieldOne)) {
         return undefined;
      }
      return reoccurFieldOne;
   });

   private readonly reoccurFieldOneArraySignal = computed<number[]>(() => {
      if (this.recurrence().reoccurType !== 7 && this.recurrence().reoccurType !== 8) {
         return [];
      }
      const reoccurFieldOne = this.recurrence().reoccurFld1;
      // Backend is stored as a string, so we must manually split it.
      const reoccurFieldOneArray =
         typeof reoccurFieldOne === "string"
            ? reoccurFieldOne.split(",").map((valueID) => Number(valueID))
            : [];
      return reoccurFieldOneArray.filter((value) => !isNaN(value));
   });

   private readonly reoccurFieldTwoArraySignal = computed<number[]>(() => {
      if (this.recurrence().reoccurType !== 2) {
         return [];
      }
      const reoccurFieldTwo = this.recurrence().reoccurFld2;
      if (typeof reoccurFieldTwo !== "string") {
         return [];
      }
      const valueArray = reoccurFieldTwo.split(",");
      if (valueArray.length === 0) {
         return [];
      }
      // Backend is stored as a string, so we must manually split it.
      return valueArray.map((value) => Number(value)).filter((value) => !isNaN(value));
   });

   private readonly reoccurFieldTwoNumberSignal = computed(() => {
      const reoccurFieldTwo = Number(this.recurrence().reoccurFld2);
      if (isNaN(reoccurFieldTwo)) {
         return undefined;
      }
      return reoccurFieldTwo;
   });

   private readonly reoccurFieldThreeNumberSignal = computed(() => {
      const reoccurFieldThree = Number(this.recurrence().reoccurFld3);
      if (isNaN(reoccurFieldThree)) {
         return undefined;
      }
      return reoccurFieldThree;
   });

   private readonly recurrenceFrequencyTimeUnit = computed(() => {
      const recurrenceType = this.recurrence().reoccurType;
      const reoccurFieldOne = this.reoccurFieldOneNumberSignal();
      if (!reoccurFieldOne) {
         return "";
      }
      if (recurrenceType === 1) {
         if (reoccurFieldOne === 1) {
            return this.lang().Day;
         }
         return this.lang().Days;
      }

      if (recurrenceType === 2) {
         if (reoccurFieldOne === 1) {
            return this.lang().Week;
         }
         return this.lang().Weeks;
      }

      if (recurrenceType === 3 || recurrenceType === 4) {
         if (reoccurFieldOne === 1) {
            return this.lang().Month;
         }
         return this.lang().Months;
      }

      if (recurrenceType === 5 || recurrenceType === 6) {
         if (reoccurFieldOne === 1) {
            return this.lang().Year;
         }
         return this.lang().Years;
      }

      return "";
   });

   private readonly valueEnd = computed(() => {
      const recurrence = this.recurrence();
      if (
         recurrence.reoccurType === 7 &&
         (recurrence.valueEnd === undefined || recurrence.valueEnd === 0)
      ) {
         return recurrence.reoccurFld3; //since valueend doesn't exist it must be based off of starts on.
      }
      return recurrence.valueEnd;
   });

   protected readonly taskCurrentlyOpen = computed(() => {
      this.tasksLoadedSignal?.();
      const recurrence = this.recurrence();
      if (recurrence.reoccurType !== 7 && recurrence.reoccurType !== 8) {
         return undefined;
      }
      const tasks = this.manageTask.getTasks();
      const checklistBatchID = this.template().checklistBatchID;
      for (const task of tasks) {
         if (task.checklistTemplate === 0 && task.checklistBatchID === checklistBatchID) {
            return task.checklistID;
         }
      }
      return undefined;
   });

   protected readonly restrictBasedOnAsset = computed(() => {
      if (!this.template()?.assets?.[0]) {
         return false;
      }
      if (this.restrict() || this.template().assets[0].assetDeleted === 1) {
         return true;
      }
      return false;
   });

   protected readonly recurrenceHasSchedule = computed(() => {
      return this.manageSchedule.recurrenceHasSchedules_JIT(
         this.recurrence(),
         this.schedulesForRecurrence().length > 0,
      );
   });

   protected readonly fieldName = computed(() => {
      // While assets are kitchen sink loaded, make sure this fires when the assets are loaded.
      this.assetsLoadedSignal();

      const recurrence = this.recurrence();
      if (recurrence.reoccurType !== 7 && recurrence.reoccurType !== 8) {
         return undefined;
      }

      const valueIDs = this.reoccurFieldOneArraySignal();
      for (const valueID of valueIDs) {
         if (valueID > 0) {
            const value = this.manageAsset.getFieldValue(valueID);

            if (value) {
               return this.manageAsset.getField(value.fieldID)?.fieldName;
            }
         }
      }
      return undefined;
   });

   protected readonly recurrenceFrequencyDisplay = computed(() => {
      return `${this.lang().RepeatsEvery} ${this.recurrence().reoccurFld1} ${this.recurrenceFrequencyTimeUnit()}`;
   });

   protected readonly getDaysOfWeekFromRecurrence = computed(() => {
      const recurrence = this.recurrence();
      if (recurrence.reoccurType !== 2) {
         return [];
      }
      const daysOfWeek = this.reoccurFieldTwoArraySignal();
      return daysOfWeek.map((dayNumber) => this.getRecurrenceDay(dayNumber));
   });

   protected readonly schedulesForRecurrence = computed(() => {
      return this.template().schedules.filter(
         (schedule) => schedule.reoccurID === this.recurrence().reoccurID,
      );
   });

   protected readonly nextDueDisplay = computed(() => {
      const schedulesForRecurrence = this.schedulesForRecurrence();

      const nextScheduleStartDate = schedulesForRecurrence.reduce(
         (lowestStartDateSoFar, { scheduleStartDate }) => {
            if (scheduleStartDate === null) {
               return lowestStartDateSoFar;
            }
            return lowestStartDateSoFar < scheduleStartDate
               ? lowestStartDateSoFar
               : scheduleStartDate;
         },
         Infinity,
      );

      if (nextScheduleStartDate === Infinity) {
         return "";
      }

      const nextDueOn = ` | ${this.lang().NextDueOn} ${formatDate(
         nextScheduleStartDate * 1000,
         "EEE, MMM dd, yyyy",
         this.manageLang.getLocaleID(),
         this.getTimeZoneOffset(),
      )}`;

      return nextDueOn;
   });

   protected readonly timezone = computed(() => {
      this.locationsLoaded?.();

      const timezoneID = this.getTimezoneId();
      if (timezoneID === null) {
         return "";
      }
      const locationTimezone =
         this.manageLocation.getTimezonesIndex()?.[timezoneID]?.timezonePHPName;
      return moment().tz(locationTimezone).zoneAbbr();
   });

   protected readonly getNextCreatedAtDisplay = computed(() => {
      const taskCurrentlyOpen = this.taskCurrentlyOpen();
      if (taskCurrentlyOpen && taskCurrentlyOpen > 0) {
         return undefined;
      }

      // Remove any leading zeroes that were occurring when nextRecurrence was being used as a string value.
      const nextRecurrence = this.getNextRecurrence()?.toString().replace(/^0+/, "");
      return `${this.lang().NextCreatedAt} ${nextRecurrence} ${this.fieldName() ?? ""}`;
   });

   protected readonly getThresholdDisplay = computed(() => {
      const recurrence = this.recurrence();
      if (recurrence.reoccurType !== 8) {
         return undefined;
      }

      // Represents whether the trigger is below, above, or between the threshold values.
      const reoccurFieldThreeNumber = this.reoccurFieldThreeNumberSignal();
      if (reoccurFieldThreeNumber === undefined) {
         return "";
      }

      const thresholdText = this.getThresholdText(reoccurFieldThreeNumber);

      const textForRange =
         reoccurFieldThreeNumber === 2
            ? ` ${this.lang().and} ${recurrence.reoccurFld5}`
            : "";

      const fieldName = this.fieldName() ?? "";

      return `${this.lang().IfEver} ${thresholdText} ${recurrence.reoccurFld2}${textForRange} ${fieldName}`;
   });

   private getThresholdText(thresholdNumber: number): string {
      if (thresholdNumber === 0) {
         return this.lang().below;
      }

      if (thresholdNumber === 1) {
         return this.lang().above;
      }

      if (thresholdNumber === 2) {
         return this.lang().between;
      }
      return "";
   }

   private getRecurrenceDay(dayNumber: number): string {
      const recurrence = this.recurrence();
      const recurrenceType = recurrence.reoccurType;
      if (recurrenceType !== 2) {
         return "";
      }

      switch (dayNumber) {
         case 1:
            return this.lang().Mon;
         case 2:
            return this.lang().Tue;
         case 3:
            return this.lang().Wed;
         case 4:
            return this.lang().Thu;
         case 5:
            return this.lang().Fri;
         case 6:
            return this.lang().Sat;
         case 7:
            return this.lang().Sun;
         default:
            return "";
      }
   }

   private getNextRecurrence(): number | undefined {
      if (this.taskCurrentlyOpen()) {
         return this.manageSchedule.getNextCreatedAtForRecurrence(
            this.recurrence().reoccurID,
         );
      }
      const valueEndNumber = Number(this.valueEnd());
      if (isNaN(valueEndNumber)) {
         return undefined;
      }
      return valueEndNumber + (this.reoccurFieldTwoNumberSignal() ?? 0);
   }

   private getTimezoneId(): number | null {
      return (
         this.manageLocation.getLocation(this.template().locationID)?.timezoneID ?? null
      );
   }

   private getTimeZoneOffset(): string | undefined {
      const timezoneCode: number | null = this.getTimeZoneCode();
      if (timezoneCode === null) {
         return undefined;
      }
      return this.betterDate.timezoneOffsetHoursToIso8601(timezoneCode);
   }

   private getTimeZoneCode(): number | null {
      return (
         this.manageLocation.getLocation(this.template().locationID)?.timezoneGMT ?? null
      );
   }

   protected popTask(): void {
      const taskID = this.taskCurrentlyOpen();

      if (taskID === 0) {
         return;
      }
      const instance = this.modalService.open(PopTask);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: taskID,
               message: "",
            },
         },
      };
   }

   protected popMultipleType7Schedules(): void {
      const instance = this.modalService.open(ViewListOfSchedulesType7);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ListOfSchedulesType7Msg,
            title: this.lang().ListOfSchedulesType7,
            task: this.template(),
            reoccurID: this.recurrence().reoccurID,
         },
      };
   }

   protected showSingleSchedules(): void {
      const instance = this.modalService.open(PopSchedules);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: this.template().checklistID,
               editable: false,
               template: true,
               title: `${this.lang().UpcomingSchedulesFor}: <b>${this.template().checklistName}</b>`,
               message: `${this.lang().UpcomingSchedulesMsg}<br /><br />${this.lang().UpcomingSchedulesMsg2}<br /><br />${this.lang().UpcomingSchedulesMsg3}`,
            },
         },
      };
   }
}
