@if (displayMode === extraTimeDisplayModes.ByUser) {
   @for (userTasks of groupedLoggedTime(); track userTasks) {
      <div class="lim-ui-list-item">
         @if (userTasks.userExtraTime[0]) {
            <div
               class="assigned-to-panel-wrapper by-user-mode"
               (click)="
                  showUserExtraTime[userTasks.userID] =
                     !showUserExtraTime[userTasks.userID]
               "
            >
               <user-image [user]="getUserByUserID(userTasks.userID)" />
               <span
                  class="name-and-time-wrapper"
                  [class.show-user-extra-time]="
                     showUserExtraTime[userTasks.userID] === true && extraTimeViewModel()
                  "
               >
                  <span class="user-name">
                     {{ userTasks.userExtraTime[0].userFirstName }}
                     {{ userTasks.userExtraTime[0].userLastName }}
                  </span>
                  <span class="time-wrapper">
                     {{ userTasks.totalPromptTimeHours }} {{ lang().hr }}
                     {{ lang().and }} {{ userTasks.totalPromptTimeMinutes }}
                     {{ lang().min }}
                  </span>
               </span>
               <span class="icon-wrapper">
                  @if (userTasks.userExtraTime[0].userID) {
                     <lim-ui-icon
                        [icon]="
                           showUserExtraTime[userTasks.userExtraTime[0].userID]
                              ? 'chevronUp'
                              : 'chevronDown'
                        "
                        iconSize="small"
                     />
                  }
               </span>
            </div>
         }
         @if (showUserExtraTime[userTasks.userID] === true && extraTimeViewModel()) {
            @for (time of userTasks.userExtraTime; track time) {
               @if (time) {
                  <div class="time-list-detail-with-icon-wrapper">
                     <lim-ui-icon icon="clockRegular" />
                     <div class="time-list-detail-wrapper">
                        <div>
                           @if (time.loggedAt) {
                              <ng-container class="logged-completed-date-time">
                                 <ng-container class="completed-by-user">
                                    <span class="bold-text">{{ lang().CreatedBy }} </span>
                                    <span class="text-with-separation"
                                       >{{ time | timeCreatedByName }}
                                    </span>
                                 </ng-container>
                                 <span> {{ lang().onStr }}</span>
                                 @if (
                                    (time.userID === userID &&
                                       editAllAspectsOfOnlyMyOwnLoggedTime) ||
                                    editAllAspectsOfAnyonesLoggedTime
                                 ) {
                                    @if (time.showTime) {
                                       <a
                                          class="cursor allowOffline update-completed-date-show-time"
                                          [ngClass]="{ 'color-grey-link': disabled }"
                                          (click)="updateCompletedDate(time)"
                                       >
                                          {{
                                             time.loggedAt * 1000 | betterDate: "dateTime"
                                          }}
                                       </a>
                                    }

                                    @if (!time.showTime) {
                                       <a
                                          class="cursor allowOffline update-completed-date-hide-time"
                                          [ngClass]="{ 'color-grey-link': disabled }"
                                          (click)="updateCompletedDate(time)"
                                       >
                                          {{ time.loggedAt * 1000 | betterDate: "date" }}
                                       </a>
                                    }
                                 }

                                 @if (
                                    !(
                                       time.userID === userID &&
                                       editAllAspectsOfOnlyMyOwnLoggedTime
                                    ) && !editAllAspectsOfAnyonesLoggedTime
                                 ) {
                                    @if (time.showTime) {
                                       <span>
                                          {{
                                             time.loggedAt * 1000 | betterDate: "dateTime"
                                          }}
                                       </span>
                                    }
                                    @if (!time.showTime) {
                                       <span>
                                          {{ time.loggedAt * 1000 | betterDate: "date" }}
                                       </span>
                                    }
                                 }
                              </ng-container>
                           }
                        </div>
                        <div>
                           <ng-container class="assigned-to-group">
                              <span class="bold-text">{{ lang().AssignedTo }}</span>
                              @if (!disabled) {
                                 @if (
                                    (time.userID === userID &&
                                       reassignOnlyMyOwnLoggedTimeToAnyone) ||
                                    reassignAnyLoggedTimeToAnyone
                                 ) {
                                    <a
                                       class="cursor allowOffline text-with-separation assigned-to-update-completed-user"
                                       (click)="updateCompletedUser(time)"
                                    >
                                       <span>
                                          {{
                                             time.externalEmailAddress
                                                ? time.externalEmailAddress
                                                : time.userFirstName +
                                                  " " +
                                                  time.userLastName
                                          }}
                                       </span>
                                    </a>
                                 }
                                 @if (
                                    !(
                                       time.userID === userID &&
                                       reassignOnlyMyOwnLoggedTimeToAnyone
                                    ) && !reassignAnyLoggedTimeToAnyone
                                 ) {
                                    <span class="text-with-separation">
                                       <span>
                                          {{
                                             time.externalEmailAddress
                                                ? time.externalEmailAddress
                                                : time.userFirstName +
                                                  " " +
                                                  time.userLastName
                                          }}
                                       </span>
                                    </span>
                                 }
                              }
                              @if (disabled) {
                                 <span class="text-with-separation">
                                    {{
                                       time.externalEmailAddress
                                          ? time.externalEmailAddress
                                          : time.userFirstName + " " + time.userLastName
                                    }}
                                 </span>
                              }
                           </ng-container>
                        </div>
                        <div>
                           <ng-container class="time-spent">
                              <span class="bold-text">{{
                                 lang().timeSpent | titlecase
                              }}</span>
                              @if (
                                 (time.userID === userID &&
                                    editAllAspectsOfOnlyMyOwnLoggedTime) ||
                                 editAllAspectsOfAnyonesLoggedTime
                              ) {
                                 <a
                                    class="cursor text-with-separation time-spent-update-own-time"
                                    [ngClass]="{ 'color-grey-link': disabled }"
                                    (click)="updateSpentTime(time, 'prompt')"
                                 >
                                    {{ time.promptTimeHours }} {{ lang().hr }}
                                    {{ lang().and }} {{ time.promptTimeMinutes }}
                                    {{ lang().min }}
                                 </a>
                              }
                              @if (
                                 !(
                                    time.userID === userID &&
                                    editAllAspectsOfOnlyMyOwnLoggedTime
                                 ) && !editAllAspectsOfAnyonesLoggedTime
                              ) {
                                 <span class="text-with-separation">
                                    {{ time.promptTimeHours }} {{ lang().hr }}
                                    {{ lang().and }} {{ time.promptTimeMinutes }}
                                    {{ lang().min }}
                                 </span>
                              }
                           </ng-container>
                        </div>
                        @if (
                           extraTimeViewModel()?.checklistUserCompleted !== null &&
                           extraTimeViewModel()?.checklistUserCompleted &&
                           time.categoryID
                        ) {
                           <div>
                              <ng-container class="billable-hours">
                                 <span class="bold-text">{{
                                    lang().BillableHours | titlecase
                                 }}</span>
                                 @if (
                                    (time.userID === userID &&
                                       editAllAspectsOfOnlyMyOwnLoggedTime) ||
                                    editAllAspectsOfAnyonesLoggedTime
                                 ) {
                                    <a
                                       class="cursor text-with-separation"
                                       [ngClass]="{ 'color-grey-link': disabled }"
                                       (click)="updateSpentTime(time, 'billable')"
                                    >
                                       {{ time.billableHours }} {{ lang().hr }}
                                       {{ lang().and }} {{ time.billableMinutes }}
                                       {{ lang().min }}
                                    </a>
                                 }
                                 @if (
                                    !(
                                       time.userID === userID &&
                                       editAllAspectsOfOnlyMyOwnLoggedTime
                                    ) && !editAllAspectsOfAnyonesLoggedTime
                                 ) {
                                    <span class="text-with-separation">
                                       {{ time.billableHours }} {{ lang().hr }}
                                       {{ lang().and }} {{ time.billableMinutes }}
                                       {{ lang().min }}
                                    </span>
                                 }
                              </ng-container>
                           </div>
                        }
                        @if (time.categoryID) {
                           <div>
                              <ng-container class="category">
                                 <span class="bold-text">{{ lang().Category }}</span>
                                 @if (time.userID === userID || canEditAnyTimeLog) {
                                    <a
                                       class="cursor text-with-separation"
                                       [ngClass]="{ 'color-grey-link': disabled }"
                                       (click)="updateBillableCategory(time)"
                                    >
                                       <span>
                                          {{
                                             categoriesIndex[time.categoryID].categoryName
                                          }}
                                       </span>
                                    </a>
                                 }
                                 @if (time.userID !== userID && !canEditAnyTimeLog) {
                                    <span class="text-with-separation">
                                       {{ categoriesIndex[time.categoryID].categoryName }}
                                    </span>
                                 }
                              </ng-container>

                              @if (
                                 viewLaborCostsCredential &&
                                 extraTimeViewModel()?.checklistUserCompleted !== null &&
                                 extraTimeViewModel()?.checklistUserCompleted
                              ) {
                                 <ng-container class="billable-rate">
                                    <span>{{ lang().at }}</span>

                                    @if (time.userID === userID || canEditAnyTimeLog) {
                                       <a
                                          class="cursor"
                                          (click)="updateBillableRate(time)"
                                          [ngClass]="{ 'color-grey-link': disabled }"
                                       >
                                          <cost-viewer-component
                                             [rate]="time.billableRate"
                                             [locationID]="
                                                extraTimeViewModel().locationID
                                             "
                                             [currencySymbol]="currencySymbol"
                                          />
                                          {{ lang().perHour }}
                                       </a>
                                    }
                                    @if (time.userID !== userID && !canEditAnyTimeLog) {
                                       <span>
                                          {{
                                             categoriesIndex[time.categoryID].categoryName
                                          }}
                                       </span>
                                    }
                                 </ng-container>
                              }
                           </div>
                        }
                        <div class="last-description-box">
                           <div>
                              <span class="bold-text"> {{ lang().Description }}</span> -
                              @if (
                                 (canEditMyOwnTimeLog && time.userID === userID) ||
                                 canEditAnyTimeLog
                              ) {
                                 @if (time?.notes?.length) {
                                    <a
                                       [limbleHtml]="time.notes"
                                       class="cursor"
                                       [ngClass]="{ 'color-grey-link': disabled }"
                                       (click)="updateNotes(time)"
                                       limUiTooltip="{{
                                          lang().DescriptionOfLogTimeTooltip
                                       }}"
                                    ></a>
                                 }
                                 @if (!time.notes || !time.notes.length) {
                                    <a
                                       class="cursor"
                                       [ngClass]="{ 'color-grey-link': disabled }"
                                       (click)="updateNotes(time)"
                                       limUiTooltip="{{
                                          lang().DescriptionOfLogTimeTooltip
                                       }}"
                                       >{{ lang().Add }} {{ lang().Description }}</a
                                    >
                                 }
                              }
                              @if (
                                 !(canEditMyOwnTimeLog && time.userID === userID) &&
                                 !canEditAnyTimeLog
                              ) {
                                 @if (!time.notes || !time.notes?.length) {
                                    <span [limbleHtml]="time.notes">-------</span>
                                 }
                                 @if (time?.notes?.length) {
                                    <span [limbleHtml]="time.notes"> </span>
                                 }
                              }
                           </div>

                           @if (
                              !disabled &&
                              ((extraTimeViewModel()?.checklistUserCompleted === 0 &&
                                 canEditMyOwnTimeLog &&
                                 time.userID === userID) ||
                                 canEditAnyTimeLog) &&
                              (!time.fromChecklistTime || canEditAnyTimeLog)
                           ) {
                              <lim-ui-icon
                                 [ngClass]="{
                                    'icon-disabled': isLastTimeEntryForCompletedTask(),
                                 }"
                                 icon="trashCanRegular"
                                 iconSize="small"
                                 limUiTooltip="{{ deleteLoggedTimeTooltip() }}"
                                 (click)="deleteExtraTime(time)"
                              />
                           }
                        </div>
                     </div>
                  </div>
               }
            }
         }
      </div>
   }
}

@if (displayMode === extraTimeDisplayModes.ByTime) {
   <div class="lim-ui-list-item">
      <div
         class="assigned-to-panel-wrapper list-toggler"
         (click)="
            showUserExtraTime[extraTimeDisplayModes.ByTime] =
               !showUserExtraTime[extraTimeDisplayModes.ByTime]
         "
      >
         <span class="total-time-spent-wrapper by-time-mode">
            <span class="user-name">
               <span class="time-spent">
                  {{ lang().TimeSpentTotal }}:
                  <span class="amount">
                     {{ getTotalTime(groupedLoggedTime()).hours }}
                     {{ lang().hr }} {{ lang().and }}
                     {{ getTotalTime(groupedLoggedTime()).minutes }}
                     {{ lang().min }}
                  </span>
               </span>
            </span>
            <span class="icon-wrapper">
               <lim-ui-icon
                  [icon]="
                     showUserExtraTime[extraTimeDisplayModes.ByTime]
                        ? 'chevronUp'
                        : 'chevronDown'
                  "
                  iconSize="small"
            /></span>
         </span>
      </div>
      @if (showUserExtraTime[extraTimeDisplayModes.ByTime] && extraTimeViewModel()) {
         @for (userTasks of groupedLoggedTime(); track userTasks) {
            @for (time of userTasks.userExtraTime; track time) {
               @if (time) {
                  <div class="time-list-detail-with-icon-wrapper">
                     <lim-ui-icon icon="clockRegular" />
                     <div class="time-list-detail-wrapper">
                        <div>
                           @if (time.loggedAt) {
                              <ng-container class="logged-completed-date-time">
                                 <ng-container class="completed-by-user">
                                    <span class="bold-text">{{ lang().CreatedBy }} </span>
                                    <span class="text-with-separation"
                                       >{{ time | timeCreatedByName }}
                                    </span>
                                 </ng-container>
                                 <span> {{ lang().onStr }}</span>
                                 @if (
                                    (time.userID === userID &&
                                       editAllAspectsOfOnlyMyOwnLoggedTime) ||
                                    editAllAspectsOfAnyonesLoggedTime
                                 ) {
                                    @if (time.showTime) {
                                       <a
                                          class="cursor allowOffline update-completed-date-show-time"
                                          [ngClass]="{ 'color-grey-link': disabled }"
                                          (click)="updateCompletedDate(time)"
                                       >
                                          {{
                                             time.loggedAt * 1000 | betterDate: "dateTime"
                                          }}
                                       </a>
                                    }

                                    @if (!time.showTime) {
                                       <a
                                          class="cursor allowOffline update-completed-date-hide-time"
                                          [ngClass]="{ 'color-grey-link': disabled }"
                                          (click)="updateCompletedDate(time)"
                                       >
                                          {{ time.loggedAt * 1000 | betterDate: "date" }}
                                       </a>
                                    }
                                 }

                                 @if (
                                    !(
                                       time.userID === userID &&
                                       editAllAspectsOfOnlyMyOwnLoggedTime
                                    ) && !editAllAspectsOfAnyonesLoggedTime
                                 ) {
                                    @if (time.showTime) {
                                       <span>
                                          {{
                                             time.loggedAt * 1000 | betterDate: "dateTime"
                                          }}
                                       </span>
                                    }
                                    @if (!time.showTime) {
                                       <span>
                                          {{ time.loggedAt * 1000 | betterDate: "date" }}
                                       </span>
                                    }
                                 }
                              </ng-container>
                           }
                        </div>
                        <div>
                           <ng-container class="assigned-to-group">
                              <span class="bold-text">{{ lang().AssignedTo }}</span>
                              @if (!disabled) {
                                 @if (
                                    (time.userID === userID &&
                                       reassignOnlyMyOwnLoggedTimeToAnyone) ||
                                    reassignAnyLoggedTimeToAnyone
                                 ) {
                                    <a
                                       class="cursor allowOffline text-with-separation assigned-to-update-completed-user"
                                       (click)="updateCompletedUser(time)"
                                    >
                                       <span>
                                          {{
                                             time.externalEmailAddress
                                                ? time.externalEmailAddress
                                                : time.userFirstName +
                                                  " " +
                                                  time.userLastName
                                          }}
                                       </span>
                                    </a>
                                 }
                                 @if (
                                    !(
                                       time.userID === userID &&
                                       reassignOnlyMyOwnLoggedTimeToAnyone
                                    ) && !reassignAnyLoggedTimeToAnyone
                                 ) {
                                    <span class="text-with-separation">
                                       <span>
                                          {{
                                             time.externalEmailAddress
                                                ? time.externalEmailAddress
                                                : time.userFirstName +
                                                  " " +
                                                  time.userLastName
                                          }}
                                       </span>
                                    </span>
                                 }
                              }
                              @if (disabled) {
                                 <span class="text-with-separation">
                                    {{
                                       time.externalEmailAddress
                                          ? time.externalEmailAddress
                                          : time.userFirstName + " " + time.userLastName
                                    }}
                                 </span>
                              }
                           </ng-container>
                        </div>
                        <div>
                           <ng-container class="time-spent">
                              <span class="bold-text">{{
                                 lang().timeSpent | titlecase
                              }}</span>
                              @if (
                                 (time.userID === userID &&
                                    editAllAspectsOfOnlyMyOwnLoggedTime) ||
                                 editAllAspectsOfAnyonesLoggedTime
                              ) {
                                 <a
                                    class="cursor text-with-separation time-spent-update-own-time"
                                    [ngClass]="{ 'color-grey-link': disabled }"
                                    (click)="updateSpentTime(time, 'prompt')"
                                 >
                                    {{ time.promptTimeHours }} {{ lang().hr }}
                                    {{ lang().and }} {{ time.promptTimeMinutes }}
                                    {{ lang().min }}
                                 </a>
                              }
                              @if (
                                 !(
                                    time.userID === userID &&
                                    editAllAspectsOfOnlyMyOwnLoggedTime
                                 ) && !editAllAspectsOfAnyonesLoggedTime
                              ) {
                                 <span class="text-with-separation">
                                    {{ time.promptTimeHours }} {{ lang().hr }}
                                    {{ lang().and }} {{ time.promptTimeMinutes }}
                                    {{ lang().min }}
                                 </span>
                              }
                           </ng-container>
                        </div>
                        @if (
                           extraTimeViewModel()?.checklistUserCompleted !== null &&
                           extraTimeViewModel()?.checklistUserCompleted &&
                           time.categoryID
                        ) {
                           <div>
                              <ng-container class="billable-hours">
                                 <span class="bold-text">{{
                                    lang().BillableHours | titlecase
                                 }}</span>
                                 @if (
                                    (time.userID === userID &&
                                       editAllAspectsOfOnlyMyOwnLoggedTime) ||
                                    editAllAspectsOfAnyonesLoggedTime
                                 ) {
                                    <a
                                       class="cursor text-with-separation"
                                       [ngClass]="{ 'color-grey-link': disabled }"
                                       (click)="updateSpentTime(time, 'billable')"
                                    >
                                       {{ time.billableHours }} {{ lang().hr }}
                                       {{ lang().and }} {{ time.billableMinutes }}
                                       {{ lang().min }}
                                    </a>
                                 }
                                 @if (
                                    !(
                                       time.userID === userID &&
                                       editAllAspectsOfOnlyMyOwnLoggedTime
                                    ) && !editAllAspectsOfAnyonesLoggedTime
                                 ) {
                                    <span class="text-with-separation">
                                       {{ time.billableHours }} {{ lang().hr }}
                                       {{ lang().and }} {{ time.billableMinutes }}
                                       {{ lang().min }}
                                    </span>
                                 }
                              </ng-container>
                           </div>
                        }
                        @if (time.categoryID) {
                           <div>
                              <ng-container class="category">
                                 <span class="bold-text">{{ lang().Category }}</span>
                                 @if (time.userID === userID || canEditAnyTimeLog) {
                                    <a
                                       class="cursor text-with-separation"
                                       [ngClass]="{ 'color-grey-link': disabled }"
                                       (click)="updateBillableCategory(time)"
                                    >
                                       <span>
                                          {{
                                             categoriesIndex[time.categoryID].categoryName
                                          }}
                                       </span>
                                    </a>
                                 }
                                 @if (time.userID !== userID && !canEditAnyTimeLog) {
                                    <span class="text-with-separation">
                                       {{ categoriesIndex[time.categoryID].categoryName }}
                                    </span>
                                 }
                              </ng-container>

                              @if (
                                 viewLaborCostsCredential &&
                                 extraTimeViewModel()?.checklistUserCompleted !== null &&
                                 extraTimeViewModel()?.checklistUserCompleted
                              ) {
                                 <ng-container class="billable-rate">
                                    <span>{{ lang().at }}</span>

                                    @if (time.userID === userID || canEditAnyTimeLog) {
                                       <a
                                          class="cursor"
                                          (click)="updateBillableRate(time)"
                                          [ngClass]="{ 'color-grey-link': disabled }"
                                       >
                                          {{ currencySymbol }}{{ time.billableRate }}
                                          {{ lang().perHour }}
                                       </a>
                                    }
                                    @if (time.userID !== userID && !canEditAnyTimeLog) {
                                       <span>
                                          {{
                                             categoriesIndex[time.categoryID].categoryName
                                          }}
                                       </span>
                                    }
                                 </ng-container>
                              }
                           </div>
                        }
                        <div class="last-description-box">
                           <div>
                              <span class="bold-text"> {{ lang().Description }}</span> -
                              @if (
                                 (canEditMyOwnTimeLog && time.userID === userID) ||
                                 canEditAnyTimeLog
                              ) {
                                 @if (time?.notes?.length) {
                                    <a
                                       [limbleHtml]="time.notes"
                                       class="cursor"
                                       [ngClass]="{ 'color-grey-link': disabled }"
                                       (click)="updateNotes(time)"
                                       limUiTooltip="{{
                                          lang().DescriptionOfLogTimeTooltip
                                       }}"
                                    ></a>
                                 }
                                 @if (!time.notes || !time.notes.length) {
                                    <a
                                       class="cursor"
                                       [ngClass]="{ 'color-grey-link': disabled }"
                                       (click)="updateNotes(time)"
                                       limUiTooltip="{{
                                          lang().DescriptionOfLogTimeTooltip
                                       }}"
                                       >{{ lang().Add }} {{ lang().Description }}</a
                                    >
                                 }
                              }
                              @if (
                                 !(canEditMyOwnTimeLog && time.userID === userID) &&
                                 !canEditAnyTimeLog
                              ) {
                                 @if (!time.notes || !time.notes?.length) {
                                    <span [limbleHtml]="time.notes">-------</span>
                                 }
                                 @if (time?.notes?.length) {
                                    <span [limbleHtml]="time.notes"> </span>
                                 }
                              }
                           </div>

                           @if (
                              !disabled &&
                              ((extraTimeViewModel()?.checklistUserCompleted === 0 &&
                                 canEditMyOwnTimeLog &&
                                 time.userID === userID) ||
                                 canEditAnyTimeLog) &&
                              (!time.fromChecklistTime || canEditAnyTimeLog)
                           ) {
                              <lim-ui-icon
                                 [ngClass]="{
                                    'icon-disabled': isLastTimeEntryForCompletedTask(),
                                 }"
                                 icon="trashCanRegular"
                                 iconSize="small"
                                 limUiTooltip="{{ deleteLoggedTimeTooltip() }}"
                                 (click)="deleteExtraTime(time)"
                              />
                           }
                        </div>
                     </div>
                  </div>
               }
            }
         }
      }
   </div>
}
