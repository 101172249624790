import { Injectable, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ModalService } from "@limblecmms/lim-ui";
import moment from "moment";
import { type Observable, Subject, filter, from, map, of, switchMap } from "rxjs";
import { PickAssets } from "src/app/assets/components//pickAssetsModal/pickAssets.modal.component";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ParamsService } from "src/app/shared/services/params.service";
import { PickItem } from "src/app/tasks/components/pickItemModal/pickItem.modal.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { SetupWorkOrder } from "src/app/tasks/components/setupWorkOrderModal/setupWorkOrder.modal.component";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { TaskType, TaskTypeService } from "src/app/tasks/services/task-type.service";
import type { TaskInfo } from "src/app/tasks/types/info/task-info.types";
import type { Task } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services//creds/cred.service";
import { ManageProfile } from "src/app/users/services//manageProfile";
import { ManageUser } from "src/app/users/services//manageUser";

type ChecklistDataInput = {
   asset: Asset | undefined;
   task: Task | TaskEntity | TaskTemplateEntity;
};

type Item = {
   collapsed: boolean;
   hovered: boolean;
   itemCollapsed: boolean;
   instructionalImages:
      | {
           fileName: string;
           getURL: string;
           itemFileName: string;
           src: string;
        }[]
      | undefined;
   itemOrder: number;
   itemText: string;
   pastResponses: {
      checklistCompletedDate: number;
      dateStr: string;
   }[];
   viewOnly: boolean;
};

type PickItemModalResponse =
   | {
        instructionSet: boolean;
        instructionSetItem: {
           itemID: number;
        };
     }
   | number;

type loadingBarTitle =
   | {
        header: string;
     }
   | undefined;

@Injectable({ providedIn: "root" })
export class SetupWorkOrderService {
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageTask = inject(ManageTask);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly manageUser = inject(ManageUser);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly loadingBarSubject$ = new Subject<any>();
   private readonly manageWO$ = new Subject<any>();

   public readonly isPlgSetupWorkOrderEnabled = toSignal(
      from(this.launchFlagsService.isEnabled(Flags.PLG_SETUP_WORK_ORDER)),
      { initialValue: false },
   );

   public getChecklistData(props: ChecklistDataInput) {
      const { currency } = this.manageUser.getCurrentUser().userInfo;

      return from(this.manageTaskItem.getTaskItems(props.task.checklistID)).pipe(
         map(({ data }) => {
            const assetNameWithParents = this.manageAsset.getAssetNameIncludeParents(
               props.asset?.assetID ?? 0,
            );
            const editingWOTemplateInstanceBeforeLive =
               Number(props.task.checklistDepreciated) > 0;
            const currencySymbol = currency?.symbol;

            return {
               assetNameWithParents,
               currencySymbol,
               editingWOTemplateInstanceBeforeLive,
               // eslint-disable-next-line typescript/unbound-method -- bound in map
               items: data.items.map(this.itemTransformer),
            };
         }),
      );
   }

   public addNewInstruction(
      task: Task | TaskEntity | TaskTemplateEntity,
      taskInfo?: TaskInfo,
   ) {
      return this.canAddNewInstruction(task, taskInfo).pipe(
         filter((canAddNewInstruction) => Boolean(canAddNewInstruction)),
         switchMap(() => {
            const lang = this.manageLang.lang();
            const addItemHint =
               this.manageUser.getCurrentUser().userInfo.logins < 10
                  ? lang?.addInstructionHint
                  : "";

            const instance = this.modalService.open(PickItem);
            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: addItemHint,
                  title: lang?.AddInstruction,
                  button: lang?.Add,
                  parentBuildData: () => {
                     return true;
                  },
               },
            };

            return from(instance.result);
         }),
         filter((hasValidResult) => Boolean(hasValidResult)),
         switchMap(async (instructionResult: PickItemModalResponse) => {
            if (
               typeof instructionResult === "object" &&
               Boolean(instructionResult.instructionSet)
            ) {
               return this.manageTaskItem
                  .generateInstructionSetInstance(
                     instructionResult.instructionSetItem.itemID,
                     task.checklistID,
                  )
                  .then((result) => {
                     return Boolean(result.data.success);
                  });
            } else if (typeof instructionResult === "number" && instructionResult > 0) {
               return this.manageTask
                  .insItem(task, instructionResult, "")
                  .then((result) => {
                     return Boolean(result.data.success);
                  });
            }
            return of(false);
         }),
      );
   }

   public canAddNewInstruction(
      task: Task | TaskEntity | TaskTemplateEntity,
      taskInfo?: TaskInfo,
   ) {
      const compareDateLimit = moment(task.checklistCreatedDate ?? 0);

      return from(
         this.manageFeatureFlags.hasReachedWOInstructionLimitFor(compareDateLimit),
      ).pipe(
         map((hasReachedLimit) => {
            const isNotAInstructionSetTemplate =
               this.taskTypeService.getType(task) !== TaskType.InstructionSetTemplate;
            const hasReachedTaskInfoLimit =
               hasReachedLimit &&
               // eslint-disable-next-line no-implicit-coercion -- needed for type check
               !!taskInfo &&
               taskInfo.items.length > 0 &&
               taskInfo.items.length < 2;
            return (
               !hasReachedLimit &&
               !hasReachedTaskInfoLimit &&
               isNotAInstructionSetTemplate
            );
         }),
      );
   }

   private itemTransformer(item: Item, _index: number, items: Item[]) {
      item.collapsed = Boolean(item.itemCollapsed);
      item.hovered = items.length < 100;
      item.instructionalImages?.forEach((image) => {
         image.getURL = image.src;
         image.fileName = image.itemFileName;
         return image;
      });
      item.pastResponses.forEach((response) => {
         const date = new Date(response.checklistCompletedDate * 1000);
         const day = String(date.getDate()).padStart(2, "0");
         const month = String(date.getMonth() + 1).padStart(2, "0");
         response.dateStr = `${month}/${day}/${date.getFullYear()}`;
      });
      item.itemOrder = Number(item.itemOrder);
      item.itemText = item.itemText
         .replace(/&quot;/g, "'")
         .replace(/&gt;/g, ">")
         .replace(/&lt;/g, "<");

      item.viewOnly = true;

      return item;
   }

   public async startWorkOrder(locationID: any, date): Promise<void> {
      const lang = this.manageLang.lang();
      if (
         !this.credService.isAuthorized(
            locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(lang?.cred43Fail ?? "", "warning", 10000);
         return;
      }
      let asset;

      if (this.manageUser.getCurrentUser().userInfo.customerStartingAWOAskAsset == 0) {
         //they have turned off picking an asset, so have them pick no asset to start
         asset = {};
         asset.locationID = locationID;
         asset.assetID = 0;
         await this.newWO(asset, date);
         return;
      }
      if (this.isPlgSetupWorkOrderEnabled()) {
         asset = {};
         asset.locationID = locationID;
         asset.assetID = null;
         await this.newWO(asset, date);
      } else {
         await this.opeAssetsModal(locationID, asset, date);
      }
   }

   private async opeAssetsModal(
      locationID: number,
      asset: any,
      date: any,
      allowPickLocation?: boolean,
   ): Promise<void> {
      const lang = this.manageLang.lang;
      const title = allowPickLocation
         ? lang()?.PleaseSelectAnAsset
         : lang()?.FirstSelectAnAsset;

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = lang()?.WhichAssetWouldYouLikeToStartThisWorkOrderFor ?? "";
      instance.title = title ?? "";
      instance.singleLocation = locationID;
      instance.selectOne = true;
      instance.restrictToCred = false;
      instance.iDontKnowOption = true;
      instance.allowPickLocation = allowPickLocation ?? false;

      const result = await modalRef.result;

      if (result) {
         if (result === "unsure") {
            const localAsset = {
               locationID,
               assetID: 0, //they don't know what asset so don't assign it to an asset
            };
            await this.newWO(localAsset, date);
         } else {
            const localAsset = result;
            await this.newWO(localAsset, date);
         }
      }
   }

   private async newWO(asset, date): Promise<void> {
      const lang = this.manageLang.lang();
      if (
         !this.credService.isAuthorized(
            asset.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(lang?.cred43Fail ?? "", "warning", 10000);
         return;
      }
      const instance = this.modalService.open(SetupWorkOrder);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: lang?.SetupWorkOrderMsg,
            title: lang?.SetupWorkOrder,
            data: {
               WO: true,
               assetID: asset.assetID,
               locationID: asset.locationID,
               date: date,
            },
         },
      };

      const data = await instance.result;

      if (data) {
         this.setLoadingBarSubject({ header: lang?.ThisMayTakeAMoment ?? "" });

         try {
            const answer = await this.manageTask.goLiveWorkOrder(
               data.WO,
               data.profileID,
               data.userID,
               data.multiUsers,
               data.timestamp,
               0,
               data.timeOfDay,
               data.startDate,
               data.startDateTimeOfDay,
               this.manageUser,
               this.manageProfile,
            );
            this.setLoadingBarSubject(undefined);
            await this.checkAssignedUserAndSendNotification(
               answer,
               asset,
               data,
               lang,
               date,
            );
         } catch (error) {
            this.setLoadingBarSubject(undefined);
            this.alertService.addAlert(lang?.errorMsg ?? "", "danger", 10000);
         }
      }
   }

   private async checkAssignedUserAndSendNotification(
      answer,
      asset,
      data,
      lang,
      date,
   ): Promise<void> {
      if (answer.data.success) {
         const userID = answer.data.task.userID;
         const profileID = answer.data.task.profileID;

         const currentUser = this.manageUser.getCurrentUser();
         const profiles = currentUser.profileLoc || [];
         const assignedToProfileIBelongTo = profiles.some(
            (profile) =>
               profile.profileID === profileID && profile.locationID === asset.locationID,
         );

         if (userID === currentUser.gUserID || assignedToProfileIBelongTo) {
            await this.OpenTask(data.WO.task.checklistID, lang);
         } else {
            const link = data.WO.task.checklistID.toString();
            const linkInfo = link ? { link, title: lang?.ViewTask ?? "" } : undefined;
            this.alertService.addAlert(
               lang?.WorkOrderSuccessfullyStartedAndEmailPushNotificationSent ?? "",
               "success",
               7000,
               undefined,
               undefined,
               linkInfo,
            );
         }

         if (date) {
            this.setManageWO(date);
         }
      } else {
         this.alertService.addAlert(lang?.errorMsg ?? "", "danger", 10000);
      }
   }

   private async OpenTask(checklistID: number, lang): Promise<void> {
      const instanceP = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instanceP,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: true,
            },
         },
      };
      this.alertService.addAlert(
         lang?.WorkOrderSuccessfullyStarted ?? "",
         "success",
         5000,
      );
      await instanceP.result;
   }

   public setManageWO(data: boolean): void {
      this.manageWO$.next(data);
   }

   public getManageWO(): Observable<boolean> {
      return this.manageWO$;
   }

   public setLoadingBarSubject(object: loadingBarTitle): void {
      this.loadingBarSubject$.next(object);
   }
   public getLoadingBarSubject(): Observable<any> {
      return this.loadingBarSubject$;
   }
}
