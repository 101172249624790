import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";

export type TaskTimeSpentCellViewModel = {
   timeSpentSecs?: number;
};

@Component({
   selector: "task-time-spent-cell",
   imports: [CommonModule, BetterDecimalPipe],
   templateUrl: "./task-time-spent-cell.component.html",
})
export class TaskTimeSpentCellComponent {
   @Input({ required: true }) public task!: TaskTimeSpentCellViewModel;

   @Input() public column?: Column;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
