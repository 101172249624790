import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { type TreeBranch, TreeCollapseService } from "@limble/limble-tree";
import {
   ModalService,
   MinimalIconButtonComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { ChkItem } from "src/app/tasks/components/chkItemElement/chkItem.element.component";
import { PickItem } from "src/app/tasks/components/pickItemModal/pickItem.modal.component";
import { UpdateTaskStateService } from "src/app/tasks/components/task-form/services/update-task-state.service";
import { TaskOptionsAdvancedSettings } from "src/app/tasks/components/taskOptionsAdvancedSettingsModal/taskOptionsAdvancedSettings.modal.component";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { ManageTaskItemOption } from "src/app/tasks/services/manageTaskItemOption";
import type {
   TaskInfo,
   TaskFormSettings,
} from "src/app/tasks/types/info/task-info.types";

@Component({
   selector: "chk-option-build-options",
   templateUrl: "./chkOptionBuildOptions.element.component.html",
   styleUrls: ["./chkOptionBuildOptions.element.component.scss"],
   imports: [MinimalIconButtonComponent, TooltipDirective],
})
export class ChkOptionBuildOptions implements OnInit {
   @Input() public option;
   @Input() public options;
   @Input() public item;
   @Input() public items;
   @Input() public info?: TaskInfo | TaskFormSettings;
   @Input() public treeBranch?: TreeBranch<ChkItem>;
   public instructionSetTemplate;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly manageTaskItemOption = inject(ManageTaskItemOption);
   private readonly paramsService = inject(ParamsService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly collapseService = inject(TreeCollapseService);
   private readonly manageLang = inject(ManageLang);
   private readonly updateTaskStateService = inject(UpdateTaskStateService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (this.treeBranch === undefined) {
         throw new Error("'treeBranch' is a required input");
      }

      const parentTask = this.info?.task;
      if (!parentTask) return;

      if (Number(parentTask.checklistTemplate) === 6) {
         this.instructionSetTemplate = true;
      }
   }

   deleteItem = () => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteOptionMsg,
            title: this.lang().DeleteOption,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.manageTaskItemOption
               .optionDelete(this.option, this.item)
               .then((answer) => {
                  if (answer.data.success == true) {
                     for (const index in this.options) {
                        if (
                           this.options[index].itemOptionID == this.option.itemOptionID
                        ) {
                           this.deleteOptionDescendants(this.option.itemOptionCount);
                           this.options.splice(index, 1);
                           this.rebuildTree();
                        }
                     }

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   private rebuildTree(): void {
      this.updateTaskStateService.refreshInstructions();
   }

   deleteOptionDescendants = (itemOptionCount) => {
      for (const index in this.items) {
         if (this.items[index].itemParentResponse == itemOptionCount) {
            if (
               this.items[index].itemTypeID == TaskInstructionTypeID.OptionList ||
               this.items[index].itemTypeID == TaskInstructionTypeID.DropdownList
            ) {
               for (const option of this.options) {
                  if (
                     this.items[index] !== undefined &&
                     this.items[index].itemID == option.itemID
                  ) {
                     if (option.itemOptionCount != itemOptionCount) {
                        this.deleteOptionDescendants(option.itemOptionCount);
                     }
                  }
               }
            } else {
               this.deleteDescendants(this.items[index].checklistItemCount);
            }
            this.items.splice(index, 1);
         }
      }
   };

   deleteDescendants = (checklistItemCount) => {
      for (const index in this.items) {
         if (this.items[index].itemParentID == checklistItemCount) {
            this.deleteDescendants(this.items[index].checklistItemCount);
            this.items.splice(index, 1);
         }
      }
   };

   addSubItem = () => {
      const instance = this.modalService.open(PickItem);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AddItemMsg,
            title: this.lang().AddInstruction,
            button: this.lang().Add,
            isInstructionSet:
               this.item.instructionSetChild ||
               Number(this.item.itemTypeID) === TaskInstructionTypeID.InstructionSet,
         },
      };

      instance.result.then((response) => {
         if (typeof response === "object" && response.instructionSet) {
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            this.manageTaskItem
               .generateInstructionSetInstance(
                  response.instructionSetItem.itemID,
                  this.item.checklistID,
                  null,
                  this.option.itemOptionCount,
                  this.item.checklistItemCount,
               )
               .then((answer) => {
                  if (!answer.data.success) {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     this.loadingBarService.remove();
                     return;
                  }
                  assert(this.info !== undefined);
                  this.updateTaskStateService.buildData(true);
                  this.option.collapsed = false;
                  this.manageTaskItemOption.updateItemCollapsed(this.item, 0);
                  if (this.instructionSetTemplate) {
                     this.manageTaskItem.updateParentInstructionSetTimeStamp(
                        this.item.checklistID,
                     );
                  }
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  this.loadingBarService.remove();
               });
         } else if (response > 0) {
            const itemTypeID = response;
            this.manageTaskItem
               .insertChild(this.item, itemTypeID, Number(this.option.itemOptionCount))
               .then((answer) => {
                  if (answer.data.success == true) {
                     if (!this.items) {
                        this.items = [];
                     }
                     answer.data.myItem.itemOptionOrder = Number(
                        answer.data.myItem.itemOptionOrder,
                     );

                     this.items.push(answer.data.myItem);
                     this.rebuildTree();
                     this.option.collapsed = false;
                     this.manageTaskItemOption.updateOptionCollapsed(this.option, 0);
                     this.updateTaskStateService.refreshInstructions();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   protected async collapseRadioDropdownList(): Promise<AxiosResponse> {
      assert(this.treeBranch);
      if (this.collapseService.isCollapsed(this.treeBranch)) {
         this.collapseService.expand(this.treeBranch);
         this.option.collapsed = false;
      } else {
         this.collapseService.collapse(this.treeBranch);
         this.option.collapsed = true;
      }
      return this.manageTaskItemOption.updateOptionCollapsed(
         this.option,
         Number(this.option.collapsed),
      );
   }

   advancedSettings = () => {
      const instance = this.modalService.open(TaskOptionsAdvancedSettings);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               message: this.lang().TaskOptionAdvancedSettingsHeader,
               title: this.lang().TaskOptionAdvancedSettingsMsg,
               option: this.option,
            },
         },
      };
   };
}
