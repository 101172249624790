<ng-container *translate="let t">
   <lim-ui-modal [showWatermark]="true">
      <lim-ui-basic-modal-header
         [title]="t('ContinueWithoutMergingFields')"
         (closeModal)="close()"
      />
      <lim-ui-modal-body>
         <lim-ui-panel>
            <div>
               <p panelHeaderContent>
                  {{ t("NotSelectedFieldsNotice") }}
                  {{ selectedLocation()?.locationName }}
               </p>
               <br />
               <p>{{ t("HighlyRecommendMergingNotice") }}</p>
               <br />
               <p>{{ t("SureContinueWithoutMerging") }}</p>
            </div>
         </lim-ui-panel>
      </lim-ui-modal-body>
      <lim-ui-modal-footer>
         <lim-ui-secondary-button
            (click)="close()"
            data-log="assetTemplatesApplyTemplateFlow-mergeAssetFields-clickGoBack"
            >{{ t("GoBack") }}</lim-ui-secondary-button
         >
         <lim-ui-primary-button
            data-log="assetTemplatesApplyTemplateFlow-mergeAssetFields-clickContinueWithoutMerging"
            (click)="continue()"
            >{{ t("ContinueWithoutMerging") }}</lim-ui-primary-button
         >
      </lim-ui-modal-footer>
   </lim-ui-modal>
</ng-container>
