<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="message-area">
            <span class="message" [limbleHtml]="message"></span>
            <lim-ui-icon-button
               icon="youtube"
               iconColor="youtube-red"
               (click)="
                  linkToVideo(
                     lang().QRCodesTutorialVideoTitle,
                     lang().QRCodesTutorialVideoId,
                     245
                  )
               "
               class="lim-ui-hide-on-mobile hidePrint"
            />
         </div>
      </lim-ui-info-panel>

      <div class="pre-panel">
         <lim-ui-secondary-button
            (click)="showMoreThenOne()"
            limUiTooltip="{{ lang().ShowMoreThanOnePartsQRCodesTooltip }}"
            >{{ lang().ShowMoreThanOnePartsQRCodes }}</lim-ui-secondary-button
         >

         <div class="slider-and-text">
            {{ lang().ZoomLevel }}
            <div class="hidePrint slider">
               <lim-ui-slider
                  [min]="10"
                  [max]="90"
                  [step]="5"
                  [formControl]="zoomFormControl"
               />
            </div>
         </div>
      </div>

      @if (loading) {
         <lim-ui-panel>
            <div class="loading-container">
               <lim-ui-loading-animation />
               <div class="progress-bar-container">
                  <lim-ui-progress-bar
                     [progress]="(index / partsLength) * 100 | betterNumber: '1.0-0'"
                     label="{{ lang().PercentageDone }}"
                  />
               </div>
            </div>
         </lim-ui-panel>
      }

      <lim-ui-scroll-container>
         <div id="printQRContainer" class="print-qr-container" [hidden]="!!loading">
            <lim-ui-panel>
               @for (part of parts; track part) {
                  <div class="qr-section">
                     <div>
                        <h2 class="lim-ui-fonts-h2" [limbleHtml]="part.partName"></h2>
                     </div>

                     <div class="content-container">
                        <div class="qr-item">
                           <span class="qr-text">
                              <a
                                 href="{{
                                    partsExtraInfo.get(part.partID)?.lookupPartURL
                                 }}"
                                 target="_blank"
                                 class="hidePrint"
                              >
                                 {{ partsExtraInfo.get(part.partID)?.lookupPartURL }}
                              </a>
                           </span>

                           <div id="qrCodeContainer{{ part.partID }}">
                              <div class="qr-code-container">
                                 <div
                                    id="qrCodePart{{ part.partID }}"
                                    class="qr-code"
                                 ></div>
                                 <span class="resizeable-text">
                                    <div class="part-container">
                                       <span class="part-titles"
                                          >{{ lang().PartName }}:</span
                                       >
                                       <span class="part-values">{{
                                          part.partName
                                       }}</span>
                                    </div>
                                    <div
                                       class="part-container"
                                       [hidden]="!part.partNumber"
                                    >
                                       <span class="part-titles"
                                          >{{ lang().PartNumber }}:
                                       </span>
                                       <span class="part-values">{{
                                          part.partNumber
                                       }}</span>
                                    </div>
                                    <div [hidden]="!part.partLocation">
                                       <span class="part-titles"
                                          >{{ lang().PartLocation }}:
                                       </span>
                                       <span class="part-values">{{
                                          part.partLocation
                                       }}</span>
                                    </div>
                                    <div
                                       class="part-container"
                                       [hidden]="
                                          !(
                                             part.partSupplier &&
                                             part.partSupplier.length > 2
                                          )
                                       "
                                    >
                                       <span class="part-titles"
                                          >{{ lang().PartSupplier }}:
                                       </span>
                                       <span class="part-values">{{
                                          part.partSupplier
                                       }}</span>
                                    </div>
                                    @if (
                                       partCategories.get(part.categoryID)?.categoryName;
                                       as categoryName
                                    ) {
                                       @if (categoryName && categoryName.length > 2) {
                                          <div class="part-container">
                                             <span class="part-titles"
                                                >{{ lang().PartCategory }}:</span
                                             >
                                             <span class="part-values"
                                                >{{ categoryName }}
                                             </span>
                                          </div>
                                       }
                                    }

                                    @if (partsExtraInfo.get(part.partID)?.locationName) {
                                       <div>
                                          <span class="part-titles"
                                             >{{ lang().LocationName }}:
                                          </span>
                                          <span class="part-values"
                                             >{{
                                                partsExtraInfo.get(part.partID)
                                                   ?.locationName
                                             }}
                                          </span>
                                       </div>
                                    }

                                    @if (
                                       partsActiveAssetRelationIDs[part.partID].length > 0
                                    ) {
                                       <div class="part-container">
                                          <span class="part-titles"
                                             >{{ lang().Assets }}:</span
                                          >
                                          @for (
                                             relationID of partsActiveAssetRelationIDs[
                                                part.partID
                                             ];
                                             track relationID
                                          ) {
                                             @if (
                                                manageParts.getAssetRelation(relationID)
                                                   ?.assetID;
                                                as assetID
                                             ) {
                                                <span class="part-values"
                                                   >{{ assetsLookup[assetID]?.assetName
                                                   }}{{
                                                      $last
                                                         ? ""
                                                         : $index ==
                                                             partsActiveAssetRelationIDs[
                                                                part.partID
                                                             ].length -
                                                                2
                                                           ? ", and "
                                                           : ", "
                                                   }}</span
                                                >
                                             }
                                          }
                                       </div>
                                    }
                                    @if (part.partVendorIDs.length > 0) {
                                       <div class="part-container">
                                          <span class="part-titles"
                                             >{{ lang().Vendors }}:</span
                                          >
                                          @for (
                                             vendorID of part.partVendorIDs;
                                             track vendorID
                                          ) {
                                             <span>
                                                <span class="part-values"
                                                   >{{ vendors.get(vendorID)?.vendorName
                                                   }}{{
                                                      $last
                                                         ? ""
                                                         : $index ==
                                                             part.partVendorIDs.length - 2
                                                           ? ", and "
                                                           : ", "
                                                   }}</span
                                                >
                                             </span>
                                          }
                                       </div>
                                    }
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               }
            </lim-ui-panel>
         </div>
      </lim-ui-scroll-container>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-dropdown>
         <lim-ui-secondary-button
            id="download-info"
            button
            icon="cloudDownloadRegular"
            secondaryIcon="angleDown"
         >
            {{ lang().DownloadUrlsAndInfo }}
         </lim-ui-secondary-button>
         <ng-container menu aria-labelledby="download-info">
            <lim-ui-dropdown-text-item
               (click)="downloadUrls('selected')"
               [displayString]="lang().DownloadSelectedParts"
            />
            <lim-ui-dropdown-text-item
               (click)="downloadUrls('all')"
               [displayString]="lang().DownloadAllParts"
            />
         </ng-container>
      </lim-ui-dropdown>

      <lim-ui-secondary-button button icon="cloudDownloadRegular" (click)="downloadAll()">
         {{ lang().DownloadImages }}
      </lim-ui-secondary-button>
      <lim-ui-dropdown>
         <lim-ui-primary-button
            id="print-options"
            button
            icon="printRegular"
            secondaryIcon="angleDown"
         >
            {{ lang().Print + " " + lang().Options }}
         </lim-ui-primary-button>
         <ng-container menu aria-labelledby="print-options">
            <lim-ui-dropdown-text-item
               print-div
               target="printQRContainer"
               [displayString]="lang().Print"
            />
            <lim-ui-dropdown-text-item
               (click)="popQRCodesCustomizePrint()"
               [displayString]="lang().Customize + ' ' + lang().Print"
            />
         </ng-container>
      </lim-ui-dropdown>
   </lim-ui-modal-footer>
</lim-ui-modal>
