import type { OnInit } from "@angular/core";
import { inject, ChangeDetectorRef, Component, computed } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   IconButtonComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   ProgressBarComponent,
   ScrollContainerComponent,
   SecondaryButtonComponent,
   SliderComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import Cookies from "js-cookie";
import { from, lastValueFrom } from "rxjs";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { QRCodesCustomizePrint } from "src/app/shared/components/global/qrCodeCustomizePrintModal/qrCodesCustomizePrint.modal.component";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ElementsToImages } from "src/app/shared/services/elementsToImages";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { PickTasksTemplateViewLegacy } from "src/app/tasks/components/pickTasksTemplateViewLegacy/pickTasksTemplateViewLegacy.component";
import { PickTasksTemplateView } from "src/app/tasks/components/pickTasksTemplateViewModal/pickTasksTemplateView.modal.component";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { PrintDivDirective } from "src/app/tasks/directives/printDiv/printDiv.directive";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";
import QRCode from "src/assets/js/qrcode";

type AugmentedTask = Task & {
   startTemplateURL?: string;
   flipScheduleURL?: string;
};

type AugmentedTaskTemplate = TaskTemplateEntity & {
   startTemplateURL?: string;
   flipScheduleURL?: string;
};

@Component({
   selector: "qr-codes-start-task",
   templateUrl: "./qrCodesStartTask.modal.component.html",
   styleUrls: ["./qrCodesStartTask.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      IconButtonComponent,
      DropdownComponent,
      SecondaryButtonComponent,
      DropdownTextItemComponent,
      TooltipDirective,
      SliderComponent,
      FormsModule,
      ReactiveFormsModule,
      PanelComponent,
      LoadingAnimationComponent,
      ProgressBarComponent,
      ScrollContainerComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      PrintDivDirective,
      BetterDecimalPipe,
      OrderByPipe,
   ],
})
export class QRCodesStartTask implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public loading;
   public CID;
   public locationID;
   public type;
   public templates: Array<AugmentedTask | AugmentedTaskTemplate | TaskEntity> = [];
   public tasksLength: number = 0;
   public started;
   private startingTaskID: number | undefined;
   public startingTask: Task | TaskEntity | TaskTemplateEntity | undefined;
   public errorMsg;
   protected zoomFormControl = new FormControl(50);
   public index;
   public typeOfQR = "startTask";

   private readonly modalService = inject(ModalService);
   public readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly elementsToImages = inject(ElementsToImages);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly paramsService = inject(ParamsService);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly manageUser = inject(ManageUser);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly taskApiService = inject(TasksApiService);
   private readonly taskTemplateApiService = inject(TaskTemplatesApiService);
   private readonly manageLang = inject(ManageLang);

   protected startTemplateURL = "";
   protected flipScheduleURL = "";

   protected readonly isJitCompletedTasksChkEnabled = from(
      this.launchFlagsService.isEnabled(Flags.JIT_CT_TASK_CHK),
   );

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.zoomFormControl.valueChanges.subscribe((value) => {
         if (!this.started) {
            this.runWatch();
         }
         const date = new Date();
         date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
         Cookies.set("taskSliderValue", String(value), { expires: date, path: "/" });
      });
   }

   public ngOnInit() {
      this.initialize();
   }

   public async initialize() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.loading = false;
      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.startingTaskID = this.resolve.startingTaskID;
      if (!this.startingTaskID) {
         throw new Error("no startingTaskID was provided.");
      }

      if (this.resolve.type === "pm" || this.resolve.type === "wo-template") {
         this.startingTask = await lastValueFrom(
            this.taskTemplateApiService.getById(this.startingTaskID, {}),
         );
      } else {
         if (await lastValueFrom(this.isJitCompletedTasksChkEnabled)) {
            this.startingTask = await lastValueFrom(
               this.taskApiService.getById(this.startingTaskID, {
                  params: {
                     allowDeleted: true,
                  },
               }),
            );
         } else {
            this.startingTask = this.manageTask.getTaskLocalLookup(this.startingTaskID);
         }
      }
      this.locationID = this.startingTask?.locationID;
      this.type = this.resolve.type;
      this.errorMsg = "";
      this.started = false;

      setTimeout(() => {
         assert(this.startingTaskID);
         assert(this.startingTask);
         this.templates = [];
         this.templates.push(this.startingTask);
         this.tasksLength = this.templates.length;

         this.loading = true;
         this.staggerBuild(0);
      }, 100);

      const sliderCookieValue = Cookies.get("taskSliderValue");
      if (sliderCookieValue) {
         this.zoomFormControl.setValue(Number(sliderCookieValue));
      }
   }

   private getZoomValue(): number {
      return this.zoomFormControl.value ?? 50;
   }

   openHelp = () => {
      const url =
         "https://help.limblecmms.com/en/articles/6706053-how-to-print-labels-with-qr-codes-in-limble";
      const win = window.open(url, "_blank");
      win?.focus();
   };

   downloadAll = () => {
      const elementMap = new Map();
      for (const task of this.templates) {
         let assetNameStr = "";
         if (task.assetID) {
            assetNameStr = this.manageAsset.getAssetNameIncludeParents(task.assetID);
         }
         const idString = `${task.checklistID}-${task.assetID}`;
         const element = document.getElementById(`qrCodeContainer${idString}`);
         let name = task.checklistName ?? "";
         if (task.assetID && task.assetID > 0) {
            name += ` - ${assetNameStr}`;
         }
         name = name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
         elementMap.set(`${name}-${idString}`, element);
      }

      this.elementsToImages.run(elementMap).then(this.elementsToImages.zipAndDownload);
   };

   downloadUrls = () => {
      let columns;

      if (this.typeOfQR === "startTask") {
         columns = {
            startTemplateUrl: true,
         };
      } else if (this.typeOfQR === "flipSchedule") {
         columns = {
            flipScheduleURL: true,
         };
      }

      this.manageTask.downloadTasksLimited(
         this.templates as Array<AugmentedTask>,
         columns,
      );
   };

   showMoreThenOneTask = async () => {
      let tasks = this.manageTask.getTasks();

      if (this.type === "pm") {
         tasks = tasks.filter(
            (task) =>
               task.locationID === Number(this.locationID) &&
               task.checklistTemplate === 1,
         );
      } else {
         //must be trying to print WO templates
         tasks = this.manageFilters.filterTasksToWoTemplates(
            tasks,
            this.manageLocation.getLocationsIndex(),
         );
         tasks = this.manageFilters.filterOutTasksWithDeletedAssets(
            tasks,
            this.manageAsset.getAssets(),
         );
      }

      const isJitTemplatesDesktopEnabled = await this.launchFlagsService.isEnabled(
         Flags.JIT_TEMPLATES_DESKTOP,
      );
      let pickedTemplates;
      if (isJitTemplatesDesktopEnabled) {
         const modalRef = this.modalService.open(PickTasksTemplateView);
         modalRef.componentInstance.message = "";
         modalRef.componentInstance.title = this.lang().PickTemplates;
         modalRef.componentInstance.locationIDs = this.manageLocation
            .getLocations()
            .map((location) => location.locationID);
         modalRef.componentInstance.singleSelection = false;
         const pickedTemplateIDs = (await modalRef.result) ?? new Set<number>();
         pickedTemplates = [];

         for await (const template of this.taskTemplateApiService.getStreamedList({
            filters: { taskIDs: Array.from(pickedTemplateIDs) },
            sort: "checklistName",
         })) {
            pickedTemplates.push(template);
         }
      } else {
         const instance = this.modalService.open(PickTasksTemplateViewLegacy);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: "",
               title: this.lang().PickTemplates,
               tasks: tasks,
               data: { selectOne: false },
            },
         };
         pickedTemplates = await instance.result;
      }

      if (pickedTemplates.length > 0) {
         this.templates = [];
         this.templates.push(this.startingTask as Task | TaskTemplateEntity);
         for (const template of pickedTemplates) {
            this.templates.push(template);
         }

         this.loading = true;
         this.tasksLength = this.templates.length;
         this.index = 0;

         this.staggerBuild(0);
      }
   };

   protected showMoreThanOneAsset(): void {
      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = "";
      instance.title = this.lang().PickAssets;
      instance.singleLocation = 0;
      instance.selectOne = false;
      instance.restrictToCred = true;
      instance.iDontKnowOption = false;

      modalRef.result.then((assets) => {
         assert(this.startingTask);
         this.startingTask = this.startingTask as Task;
         if (assets.length > 0) {
            this.templates = [];
            for (const asset of assets) {
               const newTask: AugmentedTask = {
                  ...this.startingTask,
                  assetID: asset.assetID,
               };
               this.templates.push(newTask);
            }

            this.loading = true;
            this.tasksLength = this.templates.length;
            this.index = 0;

            this.staggerBuild(0);
         }
      });
   }

   staggerBuild = (index) => {
      if (this.templates[index]) {
         setTimeout(() => {
            this.index = index + 1;
            this.buildQRCodes(this.templates[index]);
            this.ref.detectChanges();
         }, 100);
      } else {
         setTimeout(() => {
            this.loading = false;
            this.ref.detectChanges();
         }, 100);
      }
   };

   changeType = (type) => {
      if (type == 1) {
         this.typeOfQR = "startTask";
      } else {
         this.typeOfQR = "flipSchedule";
      }
      this.staggerBuild(0);
   };

   protected generateQRCode(
      task: AugmentedTask | AugmentedTaskTemplate | TaskEntity,
   ): string {
      return this.manageTask.startTemplateURL(task);
   }

   private buildQRCodes(task: AugmentedTask | AugmentedTaskTemplate | TaskEntity): void {
      const index = this.index - 1;

      setTimeout(() => {
         let url;
         if (this.typeOfQR === "startTask") {
            this.startTemplateURL = this.manageTask.startTemplateURL(task);
            url = this.startTemplateURL;
         } else if (this.typeOfQR === "flipSchedule") {
            this.flipScheduleURL = this.manageTask.flipScheduleURL(task);
            url = this.flipScheduleURL;
         }

         $(`#qrCodeTask${task.checklistID}-${task.assetID}`).empty();

         // eslint-disable-next-line no-new -- we don't have control over this library
         new QRCode(
            document.getElementById(`qrCodeTask${task.checklistID}-${task.assetID}`),
            {
               text: url,
               width: 400 * (this.getZoomValue() / 100),
               height: 400 * (this.getZoomValue() / 100),
               colorDark: "#000000",
               colorLight: "#ffffff",
               correctLevel: QRCode.CorrectLevel.H,
            },
         );

         $(".resizeable-text").css("font-size", `${this.getZoomValue() / 3}px`);
         $(".qrCodeContainer").css("width", `${this.getZoomValue() * 10}px`);

         if ($(`#qrCodeTask${task.checklistID}-${task.assetID}`) !== undefined) {
            this.staggerBuild(index + 1);
         }
      }, 200);
   }

   popQRCodesCustomizePrint = () => {
      const instance = this.modalService.open(QRCodesCustomizePrint);
      instance.componentInstance.data.set(this.templates);
      instance.componentInstance.dataType.set("task");
   };

   runWatch = () => {
      this.started = true;
      setTimeout(() => {
         this.staggerBuild(0);
         this.started = false;
      }, 1000);
   };

   close = () => {
      this.modalInstance.close(false);
   };
}
