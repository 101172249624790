import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { TaskEmailDefaults } from "src/app/tasks/components/taskEmailDefaults/taskEmailDefaults.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "share-task-template",
   templateUrl: "./shareTaskTemplate.modal.component.html",
   styleUrls: ["./shareTaskTemplate.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      IconComponent,
      LimbleHtmlDirective,
      TooltipDirective,
      PrimaryButtonComponent,
      ContenteditableDirective,
      FormsModule,
      LoadingAnimationComponent,
      AlertComponent,
      BasicModalFooterComponent,
   ],
})
export class ShareTaskTemplate implements OnInit {
   public title;
   public message;
   public errorMsg;
   public emailMessage;
   public emailSubject;
   public feedback;
   public emailTo;
   public checklistID;
   public selectedUsers;
   public selectedVendors: Array<Vendor> = [];
   public recipient;
   public vendors: Lookup<"vendorID", Vendor> = new Lookup("vendorID");
   public sharedUsers;
   public sharedVendors: Array<Vendor> = [];
   public status;
   public spinner;
   public modalInstance;
   public resolve;
   public data;
   public task;
   public defaultEmailMessage;
   public vendorShareInfo: Map<number, { shareID: number; shareTargetEmail: string }> =
      new Map();
   private locationID: number | undefined;

   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageVendor = inject(ManageVendor);
   private readonly alertService = inject(AlertService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly paramsService = inject(ParamsService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLang = inject(ManageLang);

   protected isSubmitting = false;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.errorMsg = false;
      this.data = this.resolve.data;
      this.emailMessage = this.data.emailMessage;
      this.emailSubject = this.data.emailSubject;
      this.feedback = false;
      // this.emailTo = this.data.emailTo; //only used if pickEmployees is false
      this.checklistID = 0; //will get set if task gets passed in to the actually task ID
      this.selectedUsers = [];
      this.recipient = "";
      this.task = this.data.task;
      this.defaultEmailMessage = this.data.defaultEmailMessage;

      this.locationID = this.data.locationID;

      if (this.locationID === null || this.locationID === undefined) {
         throw new Error("Location ID is required");
      }

      let allVendors = Array.from(this.manageVendor.getVendors());
      allVendors = allVendors.filter((vendor) => vendor.vendorDeleted === 0);
      allVendors = orderBy(allVendors, "vendorName");
      this.vendors = new Lookup("vendorID", allVendors);

      let sharedUsers = [];

      //query for users this task is shared to
      this.manageTask
         .getSharedExternalUsers(this.data.task.checklistID)
         .then((answer) => {
            if (answer.data.getSharedExternalUsers == false) {
               this.sharedUsers = [];
            } else {
               sharedUsers = answer.data.getSharedExternalUsers;

               this.sharedUsers = [];

               sharedUsers.forEach((user: any) => {
                  if (user.vendorID > 0) {
                     const vendor = this.manageVendor.getVendor(user.vendorID);
                     if (!vendor) {
                        return;
                     }
                     this.vendorShareInfo.set(vendor.vendorID, {
                        shareID: user.shareID,
                        shareTargetEmail: user.shareTargetEmail.replace("\\", ""),
                     });
                     this.sharedVendors.push(vendor);
                  } else {
                     // Remove \ from email in the cases where there's an apostrophe in the name or else it shows up like: sample\'email@email.com
                     user.shareTargetEmail = user.shareTargetEmail.replace("\\", "");
                     this.sharedUsers.push(user);
                  }
               });
            }
         });

      this.status = {
         isopen: false,
      };
   }

   removeEmailFromList = (id) => {
      for (let index = this.selectedUsers.length - 1; index >= 0; index--) {
         if (this.selectedUsers[index] == id) {
            this.selectedUsers.splice(index, 1);
         }
      }
   };

   public removeVendorFromSelectedVendors(vendorID: number) {
      const vendorIndex = this.selectedVendors.findIndex(
         (vendor) => vendor.vendorID === vendorID,
      );
      if (vendorIndex === -1) {
         return;
      }
      this.selectedVendors.splice(vendorIndex, 1);
   }

   pickVendors = () => {
      const instance = this.modalService.open(PickVendors);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PleaseChooseWhichVendorsYouWouldLikeToShareThisTaskWith,
            title: this.lang().PickVendors,
            data: {
               singleLocation: 0,
               selectOne: false,
               restrictToCred: false,
               iDontKnowOption: false,
               checklistID: this.data.task.checklistID,
               locationID: this.data.task.locationID,
            },
         },
      };

      instance.result.then((vendorIDArray) => {
         if (!vendorIDArray.length) {
            return;
         }
         for (const returnedVendorID of vendorIDArray) {
            const vendor = this.manageVendor.getVendor(returnedVendorID);
            assert(vendor);
            if (!vendor?.vendorEmail?.length) {
               this.alertService.addAlert(
                  `${vendor.vendorName} ${this.lang().vendorMissingEmail}`,
                  "warning",
                  4000,
               );
               continue;
            }
            this.selectedVendors.push(vendor);
         }
      });
   };

   popVendor = (vendor) => {
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   detectEmail = ($event) => {
      if (
         $event.key === "Enter" ||
         $event.key === " " ||
         $event.key === "," ||
         $event.key === ";"
      ) {
         if (this.manageUtil.checkEmail(this.recipient)) {
            $event.preventDefault();

            let process = true;
            //first check to make sure it doesn't already exist..
            for (const selectedUser of this.selectedUsers) {
               if (selectedUser == this.recipient) {
                  process = false;
               }
            }
            for (const vendor of this.selectedVendors) {
               if (vendor.vendorEmail == this.recipient) {
                  process = false;
               }
            }

            if (process) {
               //next check to see if the email address belongs to a vendor
               let foundAVendor = false;
               for (const vendor of this.vendors) {
                  if (vendor.vendorEmail == this.recipient) {
                     this.selectedVendors.push(vendor);
                     foundAVendor = true;
                  }
               }

               if (!foundAVendor) {
                  // couldn't find a vendor so add just the email address
                  this.selectedUsers.push(this.recipient);
               }

               this.recipient = "";
            }
         }
      }
   };

   disableChecklistShare = (shareID, shareTargetEmail) => {
      this.manageTask.disableChecklistShare(shareID, shareTargetEmail).then((answer) => {
         if (answer.data.success) {
            for (let index = this.sharedUsers.length - 1; index >= 0; index--) {
               if (this.sharedUsers[index].shareID == shareID) {
                  this.sharedUsers.splice(index, 1);
               }
            }
            for (let index = this.sharedVendors.length - 1; index >= 0; index--) {
               const vendor = this.sharedVendors[index];
               const shareInfo = this.vendorShareInfo.get(vendor.vendorID);
               assert(shareInfo);
               if (shareInfo.shareID === shareID) {
                  this.sharedVendors.splice(index, 1);
               }
            }
            this.alertService.addAlert(this.lang().ShareDisabled, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         }
      });
   };

   public async disableChecklistShareForVendor(vendorID) {
      const shareInfo = this.vendorShareInfo.get(vendorID);
      assert(shareInfo);
      const answer = await this.manageTask.disableChecklistShare(
         shareInfo.shareID,
         shareInfo.shareTargetEmail,
      );
      if (!answer) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
         return;
      }
      const vendorIndex = this.sharedVendors.findIndex(
         (vendor) => vendor.vendorID === vendorID,
      );
      if (vendorIndex === -1) {
         return;
      }
      this.sharedVendors.splice(vendorIndex, 1);
      this.alertService.addAlert(this.lang().ShareDisabled, "success", 1000);
   }

   protected async submit(): Promise<void> {
      if (this.isSubmitting) {
         return;
      }

      try {
         this.isSubmitting = true;
         this.resetFeedback();

         if (!this.validateSubmission()) {
            return;
         }

         this.spinner = true;
         await this.shareTaskWithRecipients();

         this.alertService.addAlert(this.lang().TaskSuccessfullyShared, "success", 10000);
         this.modalInstance.close(this.data);
      } catch (error) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         this.spinner = false;
      } finally {
         // Reset after a short delay to prevent rapid re-clicks
         setTimeout(() => {
            this.isSubmitting = false;
         }, 1000);
      }
   }

   private resetFeedback(): void {
      this.feedback = false;
      this.errorMsg = "";
   }

   private validateSubmission(): boolean {
      // Process any additional emails typed into the input field
      this.processEmailsInInputField();

      // Validate recipients
      if (this.selectedVendors.length === 0 && this.selectedUsers.length === 0) {
         this.errorMsg = this.lang().WhoopsPleaseEnterAnEmailOrPickAVendor;
         return false;
      }

      // Validate email subject length
      const subject = this.emailSubject;
      if (subject.length > 997) {
         this.errorMsg = this.lang().error2EmailTemplate;
         return false;
      }

      return true;
   }

   private processEmailsInInputField(): void {
      this.recipient = this.manageUtil.stripTags(this.recipient);

      if (this.recipient.length > 0 && this.manageUtil.checkEmail(this.recipient)) {
         let found = false;
         for (const selectedUser of this.selectedUsers) {
            if (selectedUser === this.recipient) {
               found = true;
            }
         }
         if (!found) {
            this.selectedUsers.push(this.recipient);
         }
      }
   }

   private async shareTaskWithRecipients(): Promise<void> {
      // Format email message
      const emailMessage = this.emailMessage
         .toString()
         .replace(/<div>/g, "<br />")
         .replace(/<\/div>/g, "");

      // Generate links for vendors
      const generatedVendorLinksPromises = this.selectedVendors.map(
         async (selectedVendor) => {
            return this.manageTask.generateUniqueExternalLink(
               this.data.task.checklistID,
               selectedVendor.vendorEmail,
               emailMessage,
               this.emailSubject,
               this.data.task.locationID,
               selectedVendor.vendorID,
            );
         },
      );

      // Generate links for users
      const generatedUserLinksPromises = this.selectedUsers.map(async (selectedUser) => {
         return this.manageTask.generateUniqueExternalLink(
            this.data.task.checklistID,
            selectedUser,
            emailMessage,
            this.emailSubject,
            this.data.task.locationID,
            0,
         );
      });

      // Wait for all links to be generated
      const answers = await Promise.all([
         ...generatedVendorLinksPromises,
         ...generatedUserLinksPromises,
      ]);

      // Check if all shares were successful
      const allSucceeded = answers.every((answer) => answer.data.success);
      if (!allSucceeded) {
         throw new Error("Some shares failed");
      }
   }

   close = () => {
      this.modalInstance.close(0);
   };

   setTaskEmailDefaults = () => {
      const instance = this.modalService.open(TaskEmailDefaults);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            title: this.lang().SetTaskEmailDefaults,
            data: {
               emailMessage: this.emailMessage,
               emailSubject: this.emailSubject,
               task: this.data.task,
            },
         },
      };

      instance.result.then((resp) => {
         if (resp) {
            let taskInfo = `${this.task.checklistName} - #${this.task.checklistID}`;
            const assetNameStr = this.manageAsset.getAssetNameIncludeParents(
               this.task.assetID,
            );

            if (assetNameStr) {
               taskInfo += ` - ${assetNameStr}`;
            }

            taskInfo += ` at ${this.task.locationName}`;

            // The /{{task-info}}/g finds all instances of {{vendor-contact}}, rather than just the first
            if (resp.emailSubject.includes("{{task-info}}")) {
               this.emailSubject = resp.emailSubject.replace(/{{task-info}}/g, taskInfo);
            } else if (resp.emailSubject) {
               this.emailSubject = resp.emailSubject;
            }

            if (resp.emailMessage.includes("{{task-info}}")) {
               this.emailMessage = resp.emailMessage.replace(/{{task-info}}/g, taskInfo);
            } else if (resp.emailMessage) {
               this.emailMessage = resp.emailMessage;
            } else {
               this.emailMessage = this.defaultEmailMessage;
            }

            if (resp.emailMessage.includes("{{task-link}}")) {
               this.emailMessage = this.emailMessage.replace(
                  /{{task-link}}/g,
                  "<a href>Task</a>",
               );
            }
         }
      });
   };
}
