<lim-ui-modal [extraLarge]="true" [singleComponent]="isPREmbedded">
   @if (!loading && bill?.prID) {
      <lim-ui-basic-modal-header
         [title]="lang().Bill + ' - ' + bill?.prNumber"
         (closeModal)="this.close()"
         [showCloseButton]="!isPREmbedded"
      />
   }
   <lim-ui-modal-body>
      @if (loading) {
         <lim-ui-loading-animation />
      }

      @if (!loading && bill?.prID) {
         <lim-ui-panel>
            <header>
               <div class="related-records">
                  <!-- Current PR Status -->
                  <div
                     placement="right"
                     [limUiTooltip]="lang().ABillGoesThroughTheFollowingSteps"
                  >
                     <lim-ui-icon icon="circleExclamation" iconSize="small" />
                     @if (status) {
                        <span>{{ status }}</span>
                     }
                     @if (disapproveReason) {
                        <strong
                           class="disapprove-reason"
                           [limUiTooltip]="lang().ThisIsTheReasonItWasDisapproved"
                        >
                           - <span [limbleHtml]="disapproveReason"></span>
                        </strong>
                     }
                  </div>

                  <!-- Vendor -->
                  <div>
                     <lim-ui-icon icon="addressCard" iconSize="small" />
                     <lim-ui-text-button (click)="popVendor()"
                        >{{ vendorNameStr }}
                     </lim-ui-text-button>
                  </div>

                  <!-- Budget -->
                  <div>
                     <lim-ui-icon icon="creditCard" iconSize="small" />
                     <span [limbleHtml]="budgetNameStr"></span>
                  </div>

                  <!-- Assigned User -->
                  <div>
                     <lim-ui-icon icon="user" iconSize="small" />
                     <lim-ui-text-button (click)="changePRAssignment()">
                        {{ displayName }}
                     </lim-ui-text-button>
                  </div>

                  <!-- Associated PO -->
                  <div>
                     <lim-ui-icon icon="filePowerpoint" iconSize="small" />
                     <lim-ui-text-button (click)="popPoComponent()">
                        {{ lang().PO }} - {{ poNumberForDisplay }}
                     </lim-ui-text-button>
                  </div>
               </div>

               <div class="total">
                  <h3 class="label">{{ lang().Total }}</h3>
                  @if (isMultiCurrencyEnabled()) {
                     <h1 class="amount">
                        <span [limUiTooltip]="currencyCode() | currencyName">
                           {{ total | localeCurrency: currencyCode() }}
                        </span>
                     </h1>
                  } @else {
                     <h1 class="amount">{{ total | betterCurrency }}</h1>
                  }
               </div>
            </header>

            <h3
               class="header-collapsible section-header"
               (click)="showDetails = !showDetails"
            >
               <span>{{ lang().BillDetails }}</span>
               <lim-ui-minimal-icon-button
                  [icon]="showDetails ? 'chevronUp' : 'chevronDown'"
                  [superMinimal]="true"
               />
            </h3>
            <section class="details-grid" [hidden]="!showDetails">
               <div>
                  <label>{{ lang().BillDate }}</label>
                  <span>{{ dateDisplay | betterDate: "date" }}</span>
               </div>
               <div>
                  <label>{{ lang().ExpectedDeliveryDate }}</label>
                  <span>{{ expectedDateDisplay | betterDate: "date" }}</span>
               </div>
            </section>
         </lim-ui-panel>

         <lim-ui-panel
            [noSidePadding]="true"
            [collapsable]="true"
            title="{{ lang().ItemsReceived }}"
         >
            <section class="po-items listTable">
               <div class="listItem listItemHeader pr-item-row">
                  <div
                     limUiTooltip="{{ lang().GLDescriptionTooltip }}"
                     data-placement="right"
                     class="gl-col"
                  >
                     {{ lang().GL }}
                  </div>
                  <div class="item-col">{{ lang().Item }}</div>
                  <div class="desc-col">{{ lang().Description }}</div>
                  <div class="amount-col">{{ lang().Qty }}</div>
                  <div class="amount-col">{{ lang().Rate }}</div>
                  <div class="amount-col">{{ lang().Tax }}</div>
                  <div class="amount-col">{{ lang().Discount }}</div>
                  <div class="amount-col">{{ lang().Shipping }}</div>
                  <div class="total-col">{{ lang().Total }}</div>
                  <div class="received-col">{{ lang().Received }}</div>
                  <div class="delete-col">&nbsp;</div>
               </div>

               @for (transaction of transactions; track transaction) {
                  <pr-transaction
                     [transaction]="transaction"
                     [data]="data"
                     class="table-row-container"
                     [currencyCode]="currencyCode"
                  />
               }

               <div class="listItem">
                  @if (receivable) {
                     <lim-ui-secondary-button
                        class="hidePrint"
                        [hidden]="!receivable"
                        [disabled]="processing"
                        (click)="addPRTransaction()"
                        icon="plus"
                     >
                        {{ lang().AddItem }}
                     </lim-ui-secondary-button>
                  }
               </div>

               <footer>
                  <div class="fields">
                     <div>
                        <label>
                           {{ lang().NotesToVendor }}
                        </label>
                        @if (purchaseOrder) {
                           <div
                              type="text"
                              class="div-text-box"
                              [(ngModel)]="purchaseOrder.notesToVendor"
                              [limbleContentEditable]="false"
                              disableEditor="true"
                           ></div>
                        }
                     </div>
                  </div>
                  <div class="totals">
                     <div class="grand-total">
                        <span class="label"> {{ lang().Total }} </span>
                        @if (isMultiCurrencyEnabled()) {
                           <span
                              class="amount"
                              [limUiTooltip]="currencyCode() | currencyName"
                              >{{ total | localeCurrency: currencyCode() }}</span
                           >
                        } @else {
                           <span>{{ total | betterCurrency }}</span>
                        }
                     </div>
                  </div>
               </footer>
            </section>
         </lim-ui-panel>

         <lim-ui-panel [collapsable]="true" title="{{ lang().Comments }}">
            @for (comment of comments; track comment) {
               <div class="lim-ui-list-item">
                  <div class="lim-ui-list-item-content comment hover-show-icons">
                     <div class="comment-body">
                        <div class="comment-message-container">
                           <div
                              class="comment-message"
                              [limbleHtml]="comment.message"
                           ></div>
                           <div class="comment-meta">
                              <em>{{ comment.displayName }}</em>
                              -
                              @if (comment.timestamp) {
                                 {{ comment.timestamp * 1000 | betterDate: "dateTime" }}
                              }
                           </div>
                        </div>
                        <div>
                           @if (superUser) {
                              <lim-ui-minimal-icon-button
                                 (click)="deleteComment(comment)"
                                 icon="trashCanRegular"
                                 iconColor="danger"
                                 limUiTooltip="{{ lang().PermDeletePOCommentTooltip }}"
                              />
                           }
                        </div>
                     </div>

                     @if (comment.files.length > 0) {
                        <div class="comment-files">
                           @for (file of comment.files; track file) {
                              <div>
                                 @if (
                                    file.fileExt === "pdf" ||
                                    file.fileExt === "doc" ||
                                    file.fileExt === "docx" ||
                                    file.fileExt === "txt" ||
                                    file.fileExt === "xls" ||
                                    file.fileExt === "xlsx" ||
                                    file.fileExt === "msg"
                                 ) {
                                    <div>
                                       <div class="list-group-item">
                                          @if (bill?.prID) {
                                             <file-list-item
                                                [file]="file"
                                                url="viewFile.php?f=upload-{{
                                                   CID
                                                }}/comments/pr/{{ comment.userID }}/{{
                                                   bill?.prID
                                                }}/{{ file.fileName }}"
                                             />
                                          }
                                       </div>
                                    </div>
                                 }
                                 @if (
                                    !(
                                       file.fileExt === "pdf" ||
                                       file.fileExt === "doc" ||
                                       file.fileExt === "docx" ||
                                       file.fileExt === "txt" ||
                                       file.fileExt === "xls" ||
                                       file.fileExt === "xlsx" ||
                                       file.fileExt === "msg"
                                    )
                                 ) {
                                    <div style="max-width: 400px">
                                       <view-image [src]="file.getURL" />
                                    </div>
                                 }
                              </div>
                           }
                        </div>
                     }
                  </div>
               </div>
            }
            <lim-ui-secondary-button (click)="addPRComment()" class="hidePrint">
               <lim-ui-icon icon="commentRegular" />&nbsp;/&nbsp;
               <lim-ui-icon icon="cameraRegular" />&nbsp;
               <span>{{ lang().AddComment }}</span>
            </lim-ui-secondary-button>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      @if (!loading && bill?.prID) {
         <div class="modal-footer-buttons">
            <div>
               <lim-ui-secondary-button
                  icon="trashCanRegular"
                  iconColor="danger"
                  (click)="deletePR()"
               >
                  {{ lang().DeleteBill }}
               </lim-ui-secondary-button>
            </div>
            <div class="update-status">
               @if (bill?.status === 0) {
                  <lim-ui-primary-button (click)="submitPR()" [disabled]="processing"
                     >{{ lang().MarkAsReceived }}
                  </lim-ui-primary-button>
               }
               @if (bill?.status === 1) {
                  <lim-ui-primary-button (click)="markPRAsPaid()" [disabled]="processing"
                     >{{ lang().MarkAsPaid }}
                  </lim-ui-primary-button>
               }
            </div>
         </div>
      }
   </lim-ui-modal-footer>
</lim-ui-modal>
