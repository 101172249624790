<span class="lim-ui-mobile-table-column-label">{{ lang().ListOfCommentsHeader }}: </span>
<div class="row">
   @for (comment of comments; track comment) {
      <div class="col-md-7">
         <lim-ui-icon icon="commentRegular" iconSize="small" />
         <span class="comment-message" [limbleHtml]="comment.noteMessage"></span>
         @if (comment.relatedChecklistID) {
            <a class="related-task">
               (click)="clickRelatedTask(comment.relatedChecklistID)">
               @if (comment.relatedChecklistName) {
                  <span>{{ comment.relatedChecklistName }} -</span>
               }
               #{{ comment.relatedChecklistID }}'
            </a>
         }
      </div>
      @if (comment.noteTimestamp) {
         <div class="col-md-5">
            @if (comment.userDisplayName) {
               <span class="user-display-name">
                  <i>{{ comment.userDisplayName }}</i> -
               </span>
            }
            {{ (comment.noteTimestamp ?? 0) * 1000 | betterDate: "dateTime" }}
         </div>
      }
   }
</div>
