import { NgClass, NgStyle } from "@angular/common";
import { Component, computed, inject, input, type OnInit, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   getExtraColumns,
   getParams,
} from "@empowered/base/table/utils/column-table-parser";
import {
   IconButtonComponent,
   IconComponent,
   LoadingAnimationComponent,
   ModalService,
   PanelComponent,
   ScrollContainerComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { finalize, switchMap, tap } from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { AssetPartUsageExporterService } from "src/app/assets/services/asset-parts-usage-exporter.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import { PartUsageApiService } from "src/app/parts/components/shared/part-usage-api-service/part-usage-api.service";
import { PartColumnDefinitionFactoryService } from "src/app/parts/components/shared/services/part-column-definitions-factory/part-column-definitions-factory.service";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import {
   DataViewerSearchComponent,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { DataViewerPaginatorComponent } from "src/app/shared/data-viewer/data-viewer-paginator/data-viewer-paginator.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";

//TODO: Move this to its own data viewer model file when a data viewer model is created.

export type PartUsageSortOptions =
   | "partName"
   | "checklistCompletedDate"
   | "usedNumber"
   | "partNumber"
   | "totalPriceOfParts";

@Component({
   selector: "asset-parts-usage",
   templateUrl: "./asset-parts-usage.component.html",
   styleUrls: ["./asset-parts-usage.component.scss"],
   providers: [DataViewerStateService],
   imports: [
      PanelComponent,
      IconComponent,
      IconButtonComponent,
      FormsModule,
      TooltipDirective,
      NoSearchResults,
      DataViewerSearchComponent,
      LoadingAnimationComponent,
      DataViewerPaginatorComponent,
      SortColumn,
      NgStyle,
      ScrollContainerComponent,
      NgClass,
      BetterDatePipe,
      NgxSkeletonLoaderModule,
   ],
})
export class AssetPartsUsageComponent implements OnInit {
   public readonly assetID = input.required<number>();

   protected readonly itemCount = computed(() => this.partUsageResponse()?.total ?? 0);
   protected readonly partUsages = computed(() => this.partUsageResponse()?.data ?? []);
   protected readonly isLoading = signal(false);
   protected sort: PartUsageSortOptions = "checklistCompletedDate";

   protected columns: Array<Column> = [];
   protected readonly title = computed(() => {
      return this.lang().RecentlyUsedParts;
   });

   private readonly manageLang = inject(ManageLang);
   private readonly partUsageApi = inject(PartUsageApiService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly assertPartsUsageExporterService = inject(
      AssetPartUsageExporterService,
   );
   private readonly manageUtil = inject(ManageUtil);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly options = {
      sortBind: "-checklistCompletedDate",
      isModal: false,
      title: this.lang().RecentlyUsedParts,
   };

   protected readonly state = inject(DataViewerStateService);

   private readonly columnDefinitionsFactory = inject(PartColumnDefinitionFactoryService);

   private readonly partUsageRequest$ = this.state.requestOptions$.pipe(
      tap(() => {
         this.isLoading.set(true);
      }),
      switchMap((options) =>
         this.partUsageApi.getList(options).pipe(
            finalize(() => {
               this.isLoading.set(false);
            }),
         ),
      ),
   );

   private readonly partUsageResponse = toSignal(this.partUsageRequest$);

   protected readonly noSearchResults = computed(() => {
      const requestOptions = this.state?.requestOptions();
      const searchTerm = requestOptions?.search;
      return searchTerm?.length && this.itemCount() === 0;
   });

   protected readonly skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

   public ngOnInit(): void {
      this.initializeDataViewer();
   }

   private initializeDataViewer(): void {
      const columns = this.columnDefinitionsFactory.getPartUsageColumns();
      this.columns = columns;

      const requestParams = getParams(columns);
      this.state.setParams(requestParams);

      const extraColumns = getExtraColumns(columns);
      this.state.setExtraColumns(extraColumns);

      const assetIDsToQuery = [this.assetID()];

      this.state.setBaseFilters([{ assetIDs: assetIDsToQuery }]);

      this.state.setSort(`-${this.sort}`);
   }

   protected downloadExcel() {
      this.assertPartsUsageExporterService.exportPartsUsageData(
         this.state.requestOptionsState(),
      );
   }

   protected onSortChanged(newSortOption: PartUsageSortOptions): void {
      this.sort = newSortOption;
      this.state.setSort(this.sort);
   }

   protected popTask(taskID: number): void {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: taskID,
               editable: true,
            },
         },
      };
   }

   protected popPart(partID: number, locationID: number) {
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: partID,
            locationID: locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   protected popAsset(assetID: number, locationID?: number) {
      const modalInstance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: modalInstance,
         resolve: {
            assetID: assetID,
            locationID: locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   protected onPageChange(pageChange: number): void {
      this.state.setPage(pageChange);
   }

   protected onPageSizeChange(pageSize: number): void {
      this.state.setPageSize(pageSize);
   }

   protected onSearchChange(search: string) {
      this.state.setSearch(search);
   }
}
