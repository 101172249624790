import type { NgxSkeletonLoaderConfigTheme } from "ngx-skeleton-loader";

export const SkeletonLoaderThemes: Record<string, NgxSkeletonLoaderConfigTheme> = {
   regular: { width: "20%", height: "15px", border: "0px" },
   short: { width: "15%", height: "15px", border: "0px" },
   long: { width: "60%", height: "15px", border: "0px" },
   large: { width: "90%", height: "25px", border: "0px" },
   fillSize: { height: "15px", border: "0px" },
   set150: { width: "150px", height: "15px", border: "0px" },
   set75: { width: "75px", height: "15px", border: "0px" },
   buttonRegular: {
      "width": "100px",
      "height": "25px",
      "border-radius": "15px",
      "margin-left": "2px",
      "border": "0px",
   },
   buttonLong: {
      "width": "200px",
      "height": "25px",
      "border-radius": "15px",
      "margin-left": "2px",
      "border": "0px",
   },
   buttonShort: {
      "width": "30px",
      "height": "25px",
      "border-radius": "15px",
      "margin-left": "2px",
      "border": "0px",
   },
};
