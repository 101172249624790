import { computed, inject, Component, Input } from "@angular/core";
import { IconComponent, LimbleHtmlDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import type { TaskCommentViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   imports: [BetterDatePipe, IconComponent, LimbleHtmlDirective],
   selector: "comments-list-cell",
   templateUrl: "./comments-list-cell.component.html",
})
export class CommentsListCellComponent {
   @Input({ required: true }) public comments!: Array<TaskCommentViewModel>;

   private readonly taskFacade = inject(TasksFacadeService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected clickRelatedTask(taskID: number): void {
      this.taskFacade.viewTask(taskID);
   }
}
