import type { AfterViewInit, InputSignal, OnDestroy, OnInit } from "@angular/core";
import {
   Component,
   ViewChild,
   ViewContainerRef,
   computed,
   inject,
   input,
   signal,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import type { TreeNode, TreeOptions, TreeRoot } from "@limble/limble-tree";
import { DragEndEvent, TreeBranch, TreeService } from "@limble/limble-tree";
import type { Subscription } from "rxjs";
import { Subject, filter, switchMap } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { assert } from "src/app/shared/utils/assert.utils";
import {
   ChkItem,
   type TaskInstructionDisplayData,
} from "src/app/tasks/components/chkItemElement/chkItem.element.component";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api";
import { InstructionTreeBuilder } from "src/app/tasks/components/task-form/edit-instructions/instruction-tree-builder";
import { UpdateTaskStateService } from "src/app/tasks/components/task-form/services/update-task-state.service";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import type {
   TaskFormSettings,
   TaskInfo,
} from "src/app/tasks/types/info/task-info.types";

export type TaskInstructions = Array<any>;

@Component({
   selector: "edit-instructions",
   templateUrl: "./edit-instructions.component.html",
   styleUrls: ["./edit-instructions.component.scss"],
   imports: [],
})
export class EditInstructionsComponent implements OnInit, AfterViewInit, OnDestroy {
   public readonly info = input.required<TaskInfo | TaskFormSettings>();
   public readonly options = input.required<Array<any>>();
   public readonly taskInstructions = input.required<TaskInstructions>();

   public readonly data = input<TaskInstructionDisplayData | undefined>(undefined);

   public readonly taskInput = input<TaskEntity | undefined>();
   public readonly checklistID = input<number | undefined>();
   private readonly updateTaskStateService = inject(UpdateTaskStateService);

   @ViewChild("limbleTreeContainer", { read: ViewContainerRef })
   limbleTreeContainer?: ViewContainerRef;

   private readonly viewInit$: Subject<void> = new Subject();
   private readonly treeDropSub!: Subscription;
   private readonly treeOptions!: TreeOptions;

   private readonly instructionTreeBuilder = inject(InstructionTreeBuilder);

   private _previousTreeRoot: TreeRoot<ChkItem> | undefined = undefined;

   private readonly treeUpdated = signal(0);

   // The nodes need to be rendered whenever the tree is updated to handle collapsed states, so the new tree is calculated and rendered.
   private readonly instructionTree = computed(() => {
      // Add a dependency on the treeUpdated signal so that the tree is recalculated when it is updated.
      this.treeUpdated();
      const tree = this.instructionTreeBuilder.calcInstructionTree(
         this.taskInstructions(),
         this.options(),
      );
      const treeStruct = tree.instructionTree;
      // Return early if the tree is empty.
      if (treeStruct.length === 0) {
         return undefined;
      }
      treeStruct.forEach((item) => {
         this.setInstructionSetChild(item);
      });

      this._previousTreeRoot?.destroy();
      if (!this.limbleTreeContainer) {
         return undefined;
      }
      const root = this.treeService.createEmptyTree<ChkItem>(
         this.limbleTreeContainer,
         this.treeOptions,
      );
      for (const node of treeStruct) {
         this.renderTreeNode(root, node, this.info);
      }
      this.setShowCollapse(this.taskInstructions(), this.options());
      this._previousTreeRoot = root;
      return root;
   });

   private readonly tree$ = toObservable(this.instructionTree);

   private readonly task = computed(() => {
      if (this.taskInput()) return this.taskInput();
      const checklistID = this.checklistID();
      if (!checklistID) {
         return undefined;
      }

      return this.manageTask.getTaskLocalLookup(checklistID);
   });

   private readonly alertService = inject(AlertService);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly treeService = inject(TreeService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly disableAlerts = computed(() => {
      const disableAlerts = this.data()?.disableAlerts;
      if (disableAlerts !== undefined) {
         return disableAlerts;
      }
      const info = this.info();
      if ("disableAlerts" in info) {
         return info.disableAlerts;
      }
      return false;
   });

   public constructor() {
      this.treeOptions = {
         dragAndDrop: {
            allowDrop: (draggedNode, proposedParentNode) => {
               const item = draggedNode.meta().nodeData;
               const parentItem =
                  proposedParentNode instanceof TreeBranch
                     ? proposedParentNode.meta().nodeData
                     : null;
               if (
                  item.itemOptionID &&
                  item.itemID === parentItem?.itemID &&
                  item.parentItem?.itemTypeID === parentItem?.itemTypeID
               ) {
                  return true;
               } else if (item.itemOptionID) {
                  return false;
               } else if (
                  parentItem?.itemTypeID === TaskInstructionTypeID.OptionList ||
                  parentItem?.itemTypeID === TaskInstructionTypeID.DropdownList
               ) {
                  return false;
               } else if (
                  !parentItem &&
                  this.task &&
                  Number(this.task()?.checklistTemplate) === 6
               ) {
                  //this prevents items from being dropped at the root level of an instruction set when adding/editing instruction sets
                  return false;
               } else if (
                  Number(item.itemTypeID) === TaskInstructionTypeID.InstructionSet &&
                  (parentItem?.instructionSetChild ||
                     Number(parentItem?.itemTypeID) ===
                        TaskInstructionTypeID.InstructionSet)
               ) {
                  this.alertService.addAlert(
                     this.lang().InstructionSetsCannotBePutBelowOtherInstructionSets,
                     "warning",
                     6000,
                  );
                  //this prevents instruction sets from being dropped on instruction sets or their children
                  return false;
               }
               return true;
            },
         },
         indentation: 20,
      };

      this.treeDropSub = this.tree$
         .pipe(
            filter((root) => root !== undefined),
            switchMap((root) => root.events()),
            filter(
               (event): event is DragEndEvent<ChkItem> => event instanceof DragEndEvent,
            ),
         )
         .subscribe((event) => {
            this.onTreeDropped(event);
         });
   }

   public ngOnInit(): void {
      if (this.info === undefined) {
         throw new Error("`info` is a required input in EditInstructionsComponent");
      }
   }

   public ngAfterViewInit(): void {
      this.viewInit$.next();
      this.viewInit$.complete();
   }

   public ngOnDestroy(): void {
      this.treeDropSub.unsubscribe();
      this._previousTreeRoot?.destroy();
   }

   /** function called when you drag/drop an item */
   protected async onTreeDropped(treeEvent: DragEndEvent<ChkItem>): Promise<void> {
      const itemDropped = (treeEvent.source() as TreeBranch<ChkItem>).meta().nodeData;
      // check to see if move event is for an item or an option that is on an item.
      if (itemDropped.itemOptionID) {
         return this.optionDrop(treeEvent);
      }
      return this.itemDrop(treeEvent);
   }

   private async optionDrop(treeEvent: DragEndEvent<ChkItem>): Promise<void> {
      assert(this.info !== undefined);
      const itemDropped = (treeEvent.source() as TreeBranch<ChkItem>).meta().nodeData;
      const newParentNode = treeEvent.newParent();
      const newParent =
         newParentNode instanceof TreeBranch ? newParentNode.meta().nodeData : null;
      // check to make sure that the item option is dropping in the same option parent,
      // and that it is not being dropped into another option or into the same spot.
      if (
         itemDropped.itemID !== newParent.itemID ||
         newParent.itemOptionID ||
         treeEvent.oldIndex() === treeEvent.newIndex()
      ) {
         this.updateTaskStateService.refreshInstructions();
         return;
      }
      const newFamily = newParentNode.branches();
      const updates = {};
      for (const [index, branch] of newFamily.entries()) {
         const option = branch.meta().nodeData;
         option.itemOptionOrder = index + 1;
         updates[option.itemOptionID] = {
            itemOptionOrder: index + 1,
            itemOptionText: option.itemOptionText,
         };
      }
      const answer = await this.manageTaskItem.updateOptionFields(updates);
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         this.updateTaskStateService.refreshInstructions();
         return;
      }
      if (!this.disableAlerts()) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      }
      this.treeUpdated.set(this.treeUpdated() + 1);
   }

   private async itemDrop(treeEvent: DragEndEvent<ChkItem>): Promise<void> {
      assert(this.info() !== undefined);
      const itemDropped = (treeEvent.source() as TreeBranch<ChkItem>).meta().nodeData;
      const newParentNode = treeEvent.newParent();
      const newParent =
         newParentNode instanceof TreeBranch
            ? newParentNode.meta().nodeData
            : { checklistItemCount: 0 };
      const oldParentNode = treeEvent.oldParent();
      // check to make sure that the item isn't being dropped into a radio or dropdown item.
      if (
         newParent.itemTypeID === TaskInstructionTypeID.OptionList ||
         newParent.itemTypeID === TaskInstructionTypeID.DropdownList
      ) {
         this.updateTaskStateService.refreshInstructions();
         return;
      }
      let newParentOptionCount;

      if (newParent) {
         // radio button/dropdown options don't have a checklistItemCount value so we find the grandpa item and grab it from there.
         if (!newParent.checklistItemCount && newParent.parentItem) {
            newParent.checklistItemCount = newParent.parentItem.checklistItemCount;
         }
         newParentOptionCount = newParent.itemOptionCount || "";
      } else {
         newParentOptionCount = "";
      }

      const oldFamily = oldParentNode.branches();
      const newFamily = newParentNode.branches(); //an array of all the items (including the dropped one) that are siblings to the dropped one
      const updates = {};
      // reset the itemOrders
      for (const [index, branch] of oldFamily.entries()) {
         const item = branch.meta().nodeData;
         item.itemOrder = index + 1;
         updates[item.itemID] = {
            itemOrder: index + 1,
            itemText: item.itemText,
         };
      }
      for (const [index, branch] of newFamily.entries()) {
         const item = branch.meta().nodeData;
         item.itemOrder = index + 1;
         updates[item.itemID] = {
            itemOrder: index + 1,
            itemText: item.itemText,
         };
      }

      if (newParent.checklistItemCount == itemDropped.checklistItemCount) {
         //this is just in case Rich uses the system
         //this.alertService.addAlert("You dropped that item on itself!!!",'danger',6000);
         this.updateTaskStateService.refreshInstructions();
         return;
      }

      itemDropped.itemParentID = newParent.checklistItemCount;
      itemDropped.itemParentResponse = newParentOptionCount;
      updates[itemDropped.itemID].itemParentID = itemDropped.itemParentID;
      updates[itemDropped.itemID].itemParentResponse = itemDropped.itemParentResponse;
      updates[itemDropped.itemID].itemText = itemDropped.itemText;

      const answer = await this.manageTaskItem.updateItemFields(updates);
      if (answer.data.success !== true) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         this.updateTaskStateService.refreshInstructions();
         return;
      }

      const task = this.task();
      if (task && Number(task.checklistTemplate) === 6) {
         // If this is an instruction set template being edited, we need to update its
         // timestamp when its sub-items get moved around.
         await this.manageTaskItem.updateParentInstructionSetTimeStamp(task.checklistID);
      }
      if (!this.disableAlerts()) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      }
      this.treeUpdated.set(this.treeUpdated() + 1);
   }

   private setShowCollapse(taskInstructions: Array<any>, options: Array<any>): void {
      for (const item of taskInstructions) {
         if (
            (item.nodes !== undefined && item.nodes.length > 0) ||
            (item.options !== undefined && item.nodes.length > 0)
         ) {
            item.showCollapse = true;
         } else {
            item.showCollapse = false;
         }
      }
      for (const option of options) {
         if (
            (option.nodes !== undefined && option.nodes.length > 0) ||
            (option.options !== undefined && option.nodes.length > 0)
         ) {
            option.showCollapse = true;
         } else {
            option.showCollapse = false;
         }
      }
   }

   private setInstructionSetChild(item, parentIsTrue?): void {
      if (
         Number(item.itemTypeID) === TaskInstructionTypeID.InstructionSet ||
         parentIsTrue
      ) {
         item.instructionSetChild = true;
      }
      if (item.nodes?.length) {
         item.nodes.forEach((node) => {
            this.setInstructionSetChild(node, item.instructionSetChild);
         });
      }
   }

   private renderTreeNode(
      parent: TreeNode<ChkItem>,
      node: object & { collapsed: boolean; nodes?: Array<any> },
      info: InputSignal<TaskInfo | TaskFormSettings>,
   ): void {
      const branch = parent.grow(ChkItem, {
         inputBindings: { info: info, taskInstructionsViewParameters: this.data },
         meta: { nodeData: node },
         defaultCollapsed: node.collapsed ?? false,
      });
      for (const childNode of node.nodes ?? []) {
         this.renderTreeNode(branch, childNode, info);
      }
   }
}
