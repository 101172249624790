import { BaseSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/base-schema";
import { CustomOrderUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/custom-order-unit-schema";
import { CustomStockUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/custom-stock-unit-schema";
import { ProvidedOrderUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/provided-order-unit-schema";
import { ProvidedStockUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/provided-stock-unit-schema";
import { z } from "zod";

const CountedBaseSchema = BaseSchema.extend({
   purchasableSize: z.number(),
   purchasableProvidedSizeUnitID: z.null(),
   purchasableProvidedSizeUnitAlias: z.null(),
});

const ProvidedStockProvidedOrderDtoSchema = CountedBaseSchema.merge(
   ProvidedStockUnitSchema,
).merge(ProvidedOrderUnitSchema);

const ProvidedStockCustomOrderDtoSchema = CountedBaseSchema.merge(
   ProvidedStockUnitSchema,
).merge(CustomOrderUnitSchema);

const CustomStockProvidedOrderDtoSchema = CountedBaseSchema.merge(
   CustomStockUnitSchema,
).merge(ProvidedOrderUnitSchema);

const CustomStockCustomOrderDtoSchema =
   CountedBaseSchema.merge(CustomStockUnitSchema).merge(CustomOrderUnitSchema);

export const CountedPurchasablePurchaseOrderItemDtoSchema = z.union([
   ProvidedStockProvidedOrderDtoSchema,
   ProvidedStockCustomOrderDtoSchema,
   CustomStockProvidedOrderDtoSchema,
   CustomStockCustomOrderDtoSchema,
]);

export type CountedPurchasablePurchaseOrderItemDto = z.infer<
   typeof CountedPurchasablePurchaseOrderItemDtoSchema
>;
