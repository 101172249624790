<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header (closeModal)="close()" [title]="title" />
   <lim-ui-modal-body>
      <lim-ui-card [noPadding]="true">
         <div class="listTable">
            <!-- The header -->
            <div
               class="lim-ui-hide-on-mobile listItem listItemHeader who-and-why-headers"
            >
               <span class="col-md-6">{{ this.lang().Who }}</span>
               <span class="col-md-6">{{ this.lang().Why }}</span>
            </div>

            <!-- The rows -->
            @for (item of recips; track item) {
               <div class="table-row-container">
                  <div class="listItem mobile-list-item">
                     <div class="who-column">
                        @if (item.userEmail) {
                           <span>{{ item.userEmail }}</span>
                        }
                        @if (item.emailStr) {
                           <span>{{ item.emailStr }}</span>
                        }
                        @if (item.userFirstName) {
                           <span>
                              {{ "- " + item.userFirstName + " " + item.userLastName }}
                           </span>
                        }
                     </div>

                     <div class="why-column-mobile">
                        <span class="col-md-10">
                           @switch (item.reason) {
                              @case ("started") {
                                 <span>
                                    {{ lang().ThisUserManuallyStartedThisTask }}
                                 </span>
                              }
                              @case ("assigned to") {
                                 <span>
                                    @if (checklistID) {
                                       <span>{{
                                          lang().ThisTaskIsAssignedToThisUserOrHisTeam
                                       }}</span>
                                    }
                                    @if (purchaseOrderID) {
                                       <span>{{
                                          lang().ThisPOIsAssignedToThisUserOrHisTeam
                                       }}</span>
                                    }
                                    @if (billID) {
                                       <span>{{
                                          lang().ThisPRIsAssignedToThisUserOrHisTeam
                                       }}</span>
                                    }
                                 </span>
                              }
                              @case ("commented") {
                                 <span>
                                    {{ lang().ThisUserCommentedOnThisTask }}
                                 </span>
                              }
                              @case ("work requestor") {
                                 <span>
                                    {{
                                       lang()
                                          .ThisPersonRequestedThisTaskThourghAWorkRequestPortal
                                    }}
                                 </span>
                              }
                              @case ("manually added") {
                                 <span>
                                    {{
                                       lang().ManuallyAddedBy +
                                          " " +
                                          allUsers.get(item.createdByUserID)
                                             ?.userFirstName +
                                          " " +
                                          allUsers.get(item.createdByUserID)?.userLastName
                                    }}
                                 </span>
                              }
                              @case ("mentioned by") {
                                 <span>
                                    {{
                                       lang().MentionedBy +
                                          " " +
                                          allUsers.get(item.createdByUserID)
                                             ?.userFirstName +
                                          " " +
                                          allUsers.get(item.createdByUserID)?.userLastName
                                    }}
                                 </span>
                              }
                              @case ("mentioned in this comment") {
                                 <span>
                                    {{ lang().MentionedInThisComment }}
                                 </span>
                              }
                              @default {
                                 <span>{{ item.reason }}</span>
                              }
                           }
                        </span>

                        @if (
                           item.reason === "manually added" &&
                           item.emailStr &&
                           item.emailStr.length
                        ) {
                           <lim-ui-minimal-icon-button
                              icon="trashCanRegular"
                              iconColor="danger"
                              title="{{ lang().RemoveThisManualEntry }}"
                              (click)="
                                 removeManuallyAddedCommentNotificationEmailStr(
                                    item.emailStr
                                 )
                              "
                           />
                        }

                        @if (item.reason === "manually added" && !item.emailStr) {
                           <lim-ui-minimal-icon-button
                              icon="trashCanRegular"
                              iconColor="danger"
                              title="{{ lang().RemoveThisManualEntry }}"
                              (click)="
                                 removeManuallyAddedCommentNotification(item.userID)
                              "
                           />
                        }
                        @if (
                           item.reason === "mentioned by" &&
                           item.emailStr &&
                           item.emailStr.length
                        ) {
                           <lim-ui-minimal-icon-button
                              icon="trashCanRegular"
                              iconColor="danger"
                              title="{{ lang().RemoveThisManualEntry }}"
                              (click)="
                                 removeManuallyAddedCommentNotification(item.userID)
                              "
                           />
                        }
                     </div>
                  </div>
               </div>
            }

            <div class="short-warning-notification">
               {{ lang().IfYouAreLeavingAComment + ", " }}
               {{ lang().SelfNotifyWarningShort }}
            </div>
         </div>
      </lim-ui-card>
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      <div class="modal-footer-buttons">
         <div>
            @if (templateCred && checklistID) {
               <lim-ui-secondary-button (click)="editCommentTemplate()">
                  {{ lang().EditCommentNotificationTemplate }}
               </lim-ui-secondary-button>
            }
         </div>

         <div class="modal-footer-end-buttons">
            <lim-ui-primary-button (click)="addPerson()">
               {{ lang().AddSomeone }}
            </lim-ui-primary-button>
         </div>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
