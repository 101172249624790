import {
   Component,
   computed,
   inject,
   input,
   output,
   signal,
   type WritableSignal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PanelComponent } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { ExtraBatch } from "src/app/parts/types/extra-batch/extra-batch.types";
import type { TaskEntityPart } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskPartsListItemComponent } from "src/app/tasks/components/task-form/task-parts-list/task-parts-list-item/task-parts-list-item.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageUser } from "src/app/users/services/manageUser";

type PartID = number;

export type UpdatePartUsedNumberEvent = {
   partID: PartID;
   usedNumber: number;
};

export type PurchaseOrderWithExtraBatches = PurchaseOrder & {
   currentState: PurchaseOrderCurrentState | undefined;
   extraBatch?: ExtraBatch;
};

@Component({
   selector: "task-parts-list",
   imports: [PanelComponent, FormsModule, TaskPartsListItemComponent],
   templateUrl: "./task-parts-list.component.html",
   styleUrl: "./task-parts-list.component.scss",
})
export class TaskPartsListComponent {
   public readonly task = input.required<TaskDataViewerViewModel>();

   public readonly taskFormState = input.required<{
      tempEdit: boolean;
      taskEditable: boolean;
      disableAlerts: boolean;
   }>();

   public readonly startPoForPart = output<PartID>();
   // public readonly updatePartUsedNumber = output<TaskEntityPart>();
   public readonly updatedPartSuggestedNumber = output<TaskEntityPart>();
   public readonly removePartFromTask = output<TaskEntityPart>();
   public readonly popPartModal = output<TaskEntityPart>();
   public readonly partsUpdated = output();
   // Outputs the po ID
   public readonly popPoComponent = output<number>();

   protected readonly partsPreviousUsedNumbers: LimbleMap<number, number> =
      new LimbleMap();

   private readonly manageUser = inject(ManageUser);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected readonly manageLocation = inject(ManageLocation);
   protected readonly managePO = inject(ManagePO);
   protected readonly manageTask = inject(ManageTask);
   protected readonly currentUser = this.manageUser.getCurrentUser();
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly parts = computed<Array<TaskEntityPart>>(() => {
      return this.task().parts ?? [];
   });

   protected readonly partsOverReserved = computed<Set<number>>(() => {
      const partReservationDetails = this.task()?.partsDetails;
      if (!partReservationDetails || partReservationDetails === "noPartsNeeded") {
         return new Set();
      }

      return new Set(partReservationDetails.overReservedParts.map((part) => part.partID));
   });

   protected readonly previousPartsSuggestedNumbers = new Map<number, number>();
   protected readonly partSuggestedNumbers = new Map<number, WritableSignal<number>>();
   protected getPartSuggestedNumber(partID: number): WritableSignal<number> {
      return this.partSuggestedNumbers.get(partID) ?? signal(0);
   }

   protected getPartLocationName(locationID: number) {
      const locations = this.manageLocation.getLocations();
      const partLocation = locations.find(
         (location) => location.locationID === locationID,
      );
      return partLocation?.locationName ?? "";
   }

   protected getPartSelectedPO(part: TaskEntityPart): PurchaseOrder | undefined {
      if (part.poItemID === null || part.poItemID === 0) {
         return undefined;
      }
      const partPoId = this.managePO.getPurchaseOrderItem(part.poItemID)?.poID;
      if (partPoId === undefined) {
         return undefined;
      }
      return this.managePO.getPurchaseOrder(partPoId);
   }
}
