@if (task.timeSpentSecs !== undefined) {
   <div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
      <span class="lim-ui-mobile-table-column-label">{{ lang().TimeSpent }}: </span>
      {{ task.timeSpentSecs / 60 / 60 | betterNumber: "1.2-2" }}
      @if (task.timeSpentSecs == 3600) {
         <span>{{ lang().hr }}</span>
      }
      @if (task.timeSpentSecs != 3600) {
         <span>{{ lang().hrs }}</span>
      }
   </div>
}
