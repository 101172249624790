import type { OnInit } from "@angular/core";
import { Component, ViewChild, computed, inject, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { DragEndEvent, LimbleTreeOptions } from "@limble/limble-tree";
import { LimbleTreeRootComponent } from "@limble/limble-tree";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   CheckboxComponent,
   DangerButtonComponent,
   DropdownComponent,
   DropdownTextItemComponent,
   IconComponent,
   InfoPanelComponent,
   LimUiModalRef,
   LimbleHtmlDirective,
   LoadingBarService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   ModalService,
   PanelComponent,
   SecondaryButtonComponent,
   TextButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import clone from "rfdc";
import { catchError, firstValueFrom, of } from "rxjs";
import { ConfirmFieldLock } from "src/app/assets/components/confirm-field-lock/confirm-field-lock.modal.component";
import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import { AssetFieldDefinitionService } from "src/app/assets/services/asset-field-definition.service";
import {
   AssetFieldScopeType,
   type AssetFieldDefinition,
} from "src/app/assets/services/asset-field.types";
import { AssetTemplateFieldService } from "src/app/assets/services/asset-template-field.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { DateReminder } from "src/app/parts/components/field/reminderFields/dateReminder.element.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { EditFieldOption } from "src/app/shared/components/global/editFieldOptionElement/editFieldOption.element.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { cleanWordPaste } from "src/app/shared/utils/app.util";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

const deepClone = clone();

type FieldExtraInfo = {
   profileDescription: string;
   userFirstName: string;
   userLastName: string;
};

export type ChildrenOptions = "replace" | "increment";
const DEFAULT_UPDATE_CHILDREN_OPTION: ChildrenOptions = "replace";

@Component({
   selector: "edit-asset-field",
   templateUrl: "./editAssetField.modal.component.html",
   styleUrls: ["./editAssetField.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      ContenteditableDirective,
      FormsModule,
      CheckboxComponent,
      IconComponent,
      TooltipDirective,
      DropdownComponent,
      DropdownTextItemComponent,
      LimbleTreeRootComponent,
      SecondaryButtonComponent,
      DateReminder,
      ModalFooterComponent,
      DangerButtonComponent,
      AlertComponent,
      TextButtonComponent,
   ],
})
export class EditAssetField implements OnInit {
   @ViewChild("tree") limbleTree: LimbleTreeRootComponent | undefined;

   public fieldID = signal<number>(0);
   public assetTemplateID = signal<number>(0);
   public isForTemplate = computed(() => {
      return this.assetTemplateID() > 0;
   });
   public isStandardizedField: boolean = false;
   public isViewOnlyMode: boolean = false;
   public field: AssetField | undefined;
   public options;
   public oldOptions;
   public fields: Lookup<"fieldID", AssetField> | undefined;
   public treeOptions?: LimbleTreeOptions;
   public title: string = "";
   public isCustomDefault: boolean;
   public updateChildrenOptions: Array<ChildrenOptions>;
   public selectedChildrenOption: ChildrenOptions;
   public useSelectedChildrenOption: boolean;
   public childrenOptionPhrases: {
      [index: string]: string;
   };
   public fieldExtraInfo: FieldExtraInfo;
   protected displayOnTasks: boolean | undefined;
   protected valueUnique: boolean | undefined;
   protected showReplaceCheckbox: boolean = false;
   protected isSuperUser: boolean = false;
   protected readonly AssetFieldTypeID = AssetFieldTypeID;
   // Track a copy of the fields name so we can revert back to the original name if needed
   protected fieldName: string = "";

   private readonly modalService = inject(ModalService);

   private readonly manageAsset = inject(ManageAsset);
   private readonly assetTemplateFieldService = inject(AssetTemplateFieldService);
   private readonly assetFieldDefinitionService = inject(AssetFieldDefinitionService);
   private readonly alertService = inject(AlertService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageUser = inject(ManageUser);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageTask = inject(ManageTask);
   private readonly manageProfile = inject(ManageProfile);
   private readonly paramsService = inject(ParamsService);
   private readonly legacyLaunchFlagService = inject(LegacyLaunchFlagsService);
   private readonly manageLang = inject(ManageLang);
   private readonly featureFlagService = inject(FeatureFlagService);
   private readonly loadingBarService = inject(LoadingBarService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly modalRef: LimUiModalRef<EditAssetField, any> = inject(LimUiModalRef);

   public constructor() {
      this.fieldExtraInfo = {
         profileDescription: "",
         userFirstName: "",
         userLastName: "",
      };

      this.isCustomDefault = false;
      this.childrenOptionPhrases = {
         replace: this.lang().Replace,
         increment: this.lang().Increment,
      };
      this.updateChildrenOptions = ["replace", "increment"];
      this.selectedChildrenOption = DEFAULT_UPDATE_CHILDREN_OPTION;
      this.useSelectedChildrenOption = false;
   }

   public ngOnInit() {
      this.initializeComponent();
   }

   private async initializeComponent() {
      const isAssetTemplatesEnabled = await this.legacyLaunchFlagService.isEnabled(
         Flags.ASSET_TEMPLATES,
      );
      const isFeatureAssetTemplates = this.featureFlagService
         .featureSet()
         ?.has("assetTemplates");

      let field;
      if (isAssetTemplatesEnabled && isFeatureAssetTemplates) {
         // with lumberyard
         field = await this.getFieldDefinition();
         assert(field);
      } else {
         // without lumberyard
         field = this.manageAsset.getField(this.fieldID());
      }
      this.setField(field);
      this.useSelectedChildrenOption = Boolean(
         this.field?.updateChildAssetFieldValue !== "ignore",
      );
      if (this.field) {
         this.field.dateReminder1 = Number(this.field?.dateReminder1);
         this.field.dateReminder2 = Number(this.field?.dateReminder2);
         this.field.dateReminder3 = Number(this.field?.dateReminder3);
         this.field.displayOnTasks = Number(this.field?.displayOnTasks);
         this.field.fieldTypeID = Number(this.field?.fieldTypeID);

         this.isCustomDefault = Boolean(Number(this.field?.isCustomDefault));
         const currentUser = this.manageUser.getCurrentUser();
         this.isSuperUser = this.manageUtil.checkIfSuperUser(currentUser);

         this.displayOnTasks = Boolean(this.field?.displayOnTasks);
         this.valueUnique = Boolean(this.field?.valueUnique);
         this.showReplaceCheckbox = this.checkIfShowReplaceCheckbox();

         if (
            this.showReplaceCheckbox &&
            this.field.updateChildAssetFieldValue !== "ignore"
         ) {
            this.selectedChildrenOption = this.field.updateChildAssetFieldValue;
         } else {
            this.selectedChildrenOption = DEFAULT_UPDATE_CHILDREN_OPTION;
         }

         if (this.field?.viewableByTechFieldDefault === undefined) {
            this.field.viewableByTechFieldDefault = 1;
         }
      }

      this.fields = this.manageAsset
         .getFields()
         .filter((element) => element.locationID === this.field?.locationID);

      if (this.isForTemplate()) {
         this.assetTemplateFieldService
            .isFieldLinkedToTemplate(this.fieldID(), this.assetTemplateID())
            .subscribe({
               next: (isLinked: boolean) => {
                  this.isViewOnlyMode = this.isStandardizedField && isLinked;
                  this.setTitle();
                  this.buildTreeOptionsIncludingViewMode(this.isViewOnlyMode);
               },
               error: () => {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 2000);
               },
            });
      } else {
         // we might call this modal elsewhere, so need to check if this field is a standardized field
         if (this.isStandardizedField) {
            //a user (including superUser) is not able to edit standardized fields from the local level
            this.isViewOnlyMode = true;
            this.buildTreeOptionsIncludingViewMode(this.isViewOnlyMode);
         } else {
            this.buildTreeOptionsIncludingViewMode(false);
         }
         this.setTitle();
      }

      this.setReminderDisplayStr();
   }

   private setField(field: AssetField | undefined) {
      this.field = field;
      this.isStandardizedField =
         this.field?.scopeType === AssetFieldScopeType.Standardized;
      this.setTitle();
      this.setFieldName(this.field?.fieldName ?? "");
   }

   private setTitle() {
      if (this.isStandardizedField) {
         this.title = `${this.field?.fieldName} - ${this.isViewOnlyMode ? this.lang().View : this.lang().Edit}`;
      } else {
         this.title = `${this.field?.fieldName} - ${this.lang().Edit}`;
      }
   }

   private setFieldName(fieldName: string) {
      this.fieldName = fieldName;
   }

   private async getFieldDefinition(): Promise<AssetFieldDefinition> {
      const assetField$ = this.assetFieldDefinitionService.getDefinition(this.fieldID());

      assetField$.pipe(
         catchError((error) => {
            console.error(error);
            this.alertService.addAlert(this.lang().FieldSettingsError, "danger", 2000);
            return of(undefined);
         }),
      );

      return firstValueFrom(assetField$);
   }

   public checkIfShowReplaceCheckbox(): boolean {
      return (
         this.field?.fieldTypeID == 5 ||
         this.field?.fieldTypeID == 1 ||
         this.field?.fieldTypeID == 2 ||
         this.field?.fieldTypeID == 7
      );
   }

   /** set the order var to be the correct order */
   protected onMoveNode(event: DragEndEvent<EditFieldOption>): void {
      const draggedField = this.options.splice(event.oldIndex(), 1);
      this.options.splice(event.newIndex(), 0, draggedField[0]);
      for (const [index, option] of this.options.entries()) {
         option.order = index + 1;
      }
      this.updateFieldDropdownOptions(this.options);
   }

   updateFieldViewableByTechFieldDefault = (value: boolean) => {
      if (!this.field || this.isViewOnlyMode) {
         return;
      }
      // Kinda weird, but because the checkbox in the UI needs to display the inverse of the actual value, we need to manually set this
      this.field.viewableByTechFieldDefault = value ? 1 : 0;
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().ChangeDefaultShowHideMsg} <b>${this.field.fieldName}</b>?  <br /><br />${this.lang().ChangeDefaultShowHideMsg2}`,
            title: `${this.lang().ChangeDefaultShowHide} - <b>"${this.field.fieldName}"</b>`,
         },
      };

      instance.result.then((result) => {
         if (result === 1) {
            assert(this.field);
            this.manageAsset
               .updateFieldViewableByTechFieldDefault(this.field)
               .then((answer) => {
                  if (!this.field) {
                     return;
                  }
                  if (answer.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         } else {
            assert(this.field);
            // They cancelled/closed the modal, so we revert the viewableByTech value.
            this.field.viewableByTechFieldDefault =
               this.field.viewableByTechFieldDefault === 1 ? 0 : 1;
         }
      });
   };

   updateSuggestedFieldName = (fieldToUpdate: AssetField) => {
      if (this.isViewOnlyMode) return;

      assert(this.fields);
      if (this.fieldName === fieldToUpdate.fieldName) {
         //they are the same so no need to save anything.
         return;
      }

      for (const suggestedField of this.fields) {
         if (this.fieldName === suggestedField.fieldName) {
            this.alertService.addAlert(
               this.lang().cantNotNameFieldTheSameAsAnotherField,
               "warning",
               6000,
            );
            this.setFieldName(fieldToUpdate.fieldName);
            return;
         }
      }

      fieldToUpdate.fieldName = this.fieldName; //passed checks so save it

      if (this.isForTemplate()) {
         this.updateFieldDefinition(fieldToUpdate);
         return;
      }

      this.manageAsset
         .updateSuggestedFieldName(
            fieldToUpdate,
            fieldToUpdate.locationID,
            this.fieldName,
         )
         .then((answer) => {
            if (answer?.data.success === true) {
               this.setTitle();
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               if (answer?.data?.reason === "duplicatedName") {
                  this.alertService.addAlert(
                     this.lang().cantNotNameFieldTheSameAsAnotherField,
                     "warning",
                     6000,
                  );
                  this.setFieldName(fieldToUpdate.fieldName);
                  return;
               }
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   addDropdownOption = () => {
      if (this.isViewOnlyMode) return;

      const obj: any = {};

      let order = 0;
      for (const option of this.options) {
         if (option.order > order) {
            order = Number(option.order);
         }
      }
      obj.order = order + 1;

      this.options.push(obj);
      this.limbleTree?.update();
      this.updateFieldDropdownOptions(this.options);
   };

   deleteOption = (option) => {
      if (this.options.length == 1) {
         this.alertService.addAlert(
            "At least one dropdown option is required",
            "warning",
            1000,
         );
      } else {
         this.options = this.options.filter((opt) => opt.order != option.order);

         for (let index = 0; index < this.options.length; index++) {
            this.options[index].order = Number(index) + 1;
         }

         //set the orders to sync up
         this.updateFieldDropdownOptions(this.options);
      }
   };

   private updateFieldDropdownOptions(options) {
      if (this.isViewOnlyMode) return;

      assert(this.field);
      //might want to build an obj comparison function to check for equality and not run unnecessary calls to the db

      // checking to see if update is triggered by the renaming of a field
      // so that we can update any workflow automations that may have that value selected.
      let oldValue = null;
      let newValue = null;
      let numberOfChanges = 0;
      if (options?.length == this.oldOptions?.length) {
         for (const key in options) {
            if (options[key]?.name != this.oldOptions[key]?.name) {
               numberOfChanges++;
               oldValue = this.oldOptions[key].name;
               newValue = options[key].name;
            }
         }
         if (numberOfChanges != 1) {
            oldValue = null;
            newValue = null;
         }
      }

      //since we are treating everything in options as a json we only need one function to update the DB
      //prep to send to the D
      const optionsCopy = options.map((opt) => {
         //doesn't play well if there isn't a name
         if (!opt.name) {
            opt.name = "";
         }

         opt.name = this.manageUtil.removeDuplicateSpaces(opt.name);
         opt.name = opt.name.replace(/(\r\n|\n|\r)/gm, "");
         opt.name = cleanWordPaste(opt.name);
         return { order: opt.order, name: opt.name };
      });

      if (optionsCopy.length < 1) {
         this.addDropdownOption();
      }
      const optionsCopyJson = JSON.stringify(optionsCopy);
      const newField = deepClone(this.field);
      newField.optionsJSON = optionsCopyJson;

      if (this.isForTemplate()) {
         this.updateFieldDefinition(newField);
         return;
      }

      this.manageAsset
         .updateDropdownOptions(newField, oldValue, newValue)
         .then((answer) => {
            if (answer.data.success) {
               assert(this.field);
               //have to add this stupid thing because the global settings was wigging out without posting changes to DB ;/
               this.field.optionsJSON = optionsCopyJson;
               this.oldOptions = deepClone(optionsCopy);

               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   public setUpdateChildrenOption(option: ChildrenOptions) {
      if (!this.field || this.isViewOnlyMode) {
         return;
      }
      this.selectedChildrenOption = option;

      if (this.useSelectedChildrenOption === true) {
         this.manageAsset
            .updateAssetFieldChildSetting(this.field.fieldID, option)
            .then((result) => {
               if (result.data.success == true) {
                  assert(this.field);
                  this.field.updateChildAssetFieldValue = option;

                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }
   }

   removeSuggestedField = (fieldToRemove: AssetField) => {
      if (this.isStandardizedField) return;

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message:
               `${this.lang().RemoveSuggestedFieldMsg} <b>${fieldToRemove.fieldName}</b>?` +
               `  ${this.lang().RemoveSuggestedFieldMsg2}`,
            title: `${this.lang().RemoveSuggestedField} - <b>"${fieldToRemove.fieldName}"</b>`,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
            this.manageAsset.removeSuggestedField(fieldToRemove).then((answer) => {
               this.loadingBarService.remove();
               if (answer?.data.success == true) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  const removeField = {
                     fieldID: fieldToRemove.fieldID,
                     action: "delete",
                  };
                  this.modalRef.close(removeField);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   changeFieldOwner = (fieldToUpdate: AssetField) => {
      if (this.isViewOnlyMode) return;

      assert(this.field);
      const instance = this.modalService.open(PickUserOrProfileLegacy);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().ChangeReminderAssignmentMsg,
               title: this.lang().ChangeThresholdReminderAssignment,
               locationID: fieldToUpdate.locationID,
               defaultUser: this.field.userID,
               defaultProfile: this.field.profileID,
            },
         },
      };

      instance.result.then((data) => {
         if (data) {
            assert(this.field);
            this.manageAsset
               .updateDateReminderAssignments(
                  fieldToUpdate.fieldID,
                  data.userID,
                  data.profileID,
                  data.multiUsers,
                  this.field.locationID,
               )
               .then((response) => {
                  if (response.data.success == true) {
                     assert(this.field);
                     const userID = response.data.userID;
                     const profileID = response.data.profileID;
                     const profileDescription = response.data.profileDescription;
                     this.field.userID = userID;
                     this.field.profileID = profileID;

                     if (profileID > 0) {
                        this.manageFilters.updateHiddenProfiles(
                           {
                              profileID: profileID,
                              name: profileDescription,
                              locationID: this.field.locationID,
                              multiUsers: data.multiUsers,
                           },
                           this.manageTask,
                           this.manageUser,
                           this.manageProfile,
                        );
                     }

                     this.setReminderDisplayStr();

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   setReminderDisplayStr = () => {
      assert(this.field);
      if (this.field.userID && this.field.userID > 0) {
         const user = this.manageUser.getFlatUsersIndex()[this.field.userID];
         if (user === undefined) {
            this.fieldExtraInfo.userFirstName = this.lang().DeletedUser;
            this.fieldExtraInfo.userLastName = "";
         } else {
            this.fieldExtraInfo.userFirstName = user.userFirstName;
            this.fieldExtraInfo.userLastName = user.userLastName;
         }
      } else if (this.field.profileID && this.field.profileID > 0) {
         const profile = this.manageUser.getProfilesIndex()[this.field.profileID];
         if (profile === undefined) {
            this.fieldExtraInfo.profileDescription = this.lang().DeletedUser;
         } else {
            this.fieldExtraInfo.profileDescription = profile.profileDescription;
         }
      }
   };

   updateValueUnique = () => {
      if (this.isViewOnlyMode) return;

      assert(this.field);
      const newValue = this.field.valueUnique === 0 ? 1 : 0;
      if (this.field.valueUnique === 1) {
         const instance = this.modalService.open(Confirm);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().RemoveUniqueValueRequirementMessage,
               title: this.lang().RemoveUniqueValueRequirement,
            },
         };
         instance.result.then((answer) => {
            if (!this.field) {
               return;
            }
            if (answer === 1) {
               this.updateValueUniqueCall(newValue);
            } else {
               assert(this.field);
               this.field.valueUnique = 1;
               this.valueUnique = true;
            }
         });
      } else {
         if (!this.field) {
            return;
         }
         this.updateValueUniqueCall(newValue);
      }
   };

   updateValueUniqueCall = (newValue) => {
      assert(this.field);
      this.manageAsset.updateValueUnique(this.field.fieldID, newValue).then((result) => {
         if (result.data.success == true) {
            assert(this.field);
            this.field.valueUnique = newValue;
            this.valueUnique = Boolean(newValue);
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else if (result.data.error === "duplicateValuesAlready") {
            assert(this.field);
            this.field.valueUnique = 0;
            this.valueUnique = false;
            this.alertService.addAlert(
               this.lang().DuplicateValuesAlreadyError,
               "danger",
               6000,
            );
         } else if (result.data.error === "linkedToTask") {
            assert(this.field);
            this.field.valueUnique = 0;
            this.valueUnique = false;
            this.alertService.addAlert(
               this.lang().fieldLinkedToTaskError,
               "danger",
               6000,
            );
         } else if (result.data.error === "workflowAutomation") {
            assert(this.field);
            this.field.valueUnique = 0;
            this.valueUnique = false;
            this.alertService.addAlert(
               this.lang().valueUniqueWorkflowError,
               "danger",
               6000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };
   updateDisplayOnTasks = () => {
      if (this.isViewOnlyMode) return;

      assert(this.field);
      const newValue = this.displayOnTasks === false ? 1 : 0;

      this.manageAsset
         .updateFieldDisplayOnTasks(this.field.fieldID, newValue)
         .then((result) => {
            if (result.data.success == true) {
               assert(this.field);
               //trigger change detection
               this.field.displayOnTasks = newValue;
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   updateUseOption = () => {
      if (!this.field || this.isViewOnlyMode) {
         return;
      }
      this.useSelectedChildrenOption = !this.useSelectedChildrenOption;
      let selectedChildrenOption: "replace" | "increment" | "ignore";
      if (this.useSelectedChildrenOption === false) {
         selectedChildrenOption = "ignore";
      } else {
         selectedChildrenOption = this.selectedChildrenOption;
      }

      if (selectedChildrenOption != this.field.updateChildAssetFieldValue) {
         this.manageAsset
            .updateAssetFieldChildSetting(this.field.fieldID, selectedChildrenOption)
            .then((result) => {
               if (result.data.success == true) {
                  assert(this.field);
                  this.field.updateChildAssetFieldValue = selectedChildrenOption;
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }
   };

   dismiss = () => {
      this.modalRef.close(this.field);
   };

   /** Updates the `isCustomDefault` property of the field nd saves it to the
    * database, based on the state of the checkbox in the view. */
   public updateCustomDefault(): void {
      if (this.isStandardizedField) return;

      assert(this.field);
      this.manageAsset
         .updateFieldIsCustomDefault(this.field.fieldID, this.isCustomDefault)
         .then((succeeded) => {
            assert(this.field);
            if (succeeded === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               this.field.isCustomDefault = Number(this.isCustomDefault);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
            this.isCustomDefault = Boolean(this.field.isCustomDefault);
         });
   }

   protected async toggleFieldLockedDefault(): Promise<void> {
      if (!this.field || !this.isSuperUser || this.isViewOnlyMode) {
         return;
      }
      const linkedTaskInfo = await this.getLinkedTaskInfo();
      const instance = this.modalService.open(ConfirmFieldLock);
      instance.componentInstance.linkedTaskInfo = linkedTaskInfo;
      instance.componentInstance.field = this.field;
      instance.componentInstance.message = this.field.lockedDefault
         ? this.lang().LockConfirmationMessage
         : this.lang().UnlockConfirmationMessage;

      instance.result.then((result) => {
         if (result === true) {
            this.saveFieldLockedDefault();
         } else {
            assert(this.field);
            // They cancelled/closed the modal, so we revert the lockedDefault value.
            this.field.lockedDefault = this.field?.lockedDefault === 1 ? 0 : 1;
         }
      });
   }

   protected goToHelpCenter() {
      window.open("https://help.limblecmms.com/en/articles/9559704", "_blank");
   }

   private saveFieldLockedDefault(): void {
      assert(this.field);
      this.manageAsset.saveFieldLockedDefault(this.field).then((result) => {
         if (result.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   private async getLinkedTaskInfo(): Promise<
      Array<
         Record<
            | "checklistID"
            | "checklistName"
            | "checklistTemplate"
            | "checklistTemplateOld",
            any
         >
      >
   > {
      if (!this.field?.fieldID) {
         return [];
      }

      const linkedTaskInfo = await this.manageAsset.getLinkedTaskInfo(this.field.fieldID);
      return linkedTaskInfo;
   }

   private buildTreeOptionsIncludingViewMode(isViewOnlyMode: boolean = false) {
      assert(this.field);
      this.options = [{ name: "", order: 1 }];
      const parsedOptionsJson = this.manageAsset.getParsedOptionsJson(this.field);
      if (parsedOptionsJson) {
         this.options = parsedOptionsJson;
         this.oldOptions = deepClone(parsedOptionsJson);
      }

      this.treeOptions = {
         allowNesting: false,
         defaultComponent: {
            class: EditFieldOption,
            bindings: {
               options: this.options,
               updateFieldDropdownOptions: this.updateFieldDropdownOptions.bind(this),
               deleteOption: this.deleteOption,
               workflowLabel: "Asset",
               isViewOnlyMode,
            },
         },
      };
   }

   private updateFieldDefinition(fieldToUpdate: AssetField) {
      this.assetFieldDefinitionService
         .updateDefinition(fieldToUpdate as AssetFieldDefinition)
         .subscribe({
            next: () => {
               this.setTitle();
               this.manageAsset.addFieldToLocalData(fieldToUpdate);
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            },
            error: () => {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            },
         });
   }
}
