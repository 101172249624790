import { computed, inject, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { type Column, ColumnBuilder } from "src/app/shared/data-viewer/column-builder";

@Injectable({
   providedIn: "root",
})
export class PartColumnDefinitionFactoryService {
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly columnBuilder: ColumnBuilder = new ColumnBuilder(this.lang);

   public getByPartReportsTasks(): Array<Column> {
      return this.columnBuilder
         .addTaskPartName()
         .addTaskPartQuantity()
         .addTaskCompletedOn()
         .addTaskCompletedBy()
         .build();
   }

   public getPartUsageColumns() {
      return this.columnBuilder
         .addAssetPartUsedName()
         .addAssetPartUsedTaskCompletedOn()
         .addAssetPartUsedCount()
         .addAssetPartUsedNumber()
         .addAssetTotalCostOfPartsUsed()
         .build();
   }

   public getPartLogColumns() {
      return this.columnBuilder
         .addPartLogDate()
         .addPartLogEntry()
         .addPartLogUser()
         .build();
   }
}
