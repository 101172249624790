import { PartNotFoundError } from "src/app/parts/errors/part-not-found.error";
import type { CountedAndMeasuredPurchasable } from "src/app/parts/purchasable/counted-and-measured-purchasable.model";
import { InvalidConversionUnitsError } from "src/app/parts/purchasable/services/stock-unit-calculator/errors/invalid-conversion-units.error";
import type { StockUnitCalculator } from "src/app/parts/purchasable/services/stock-unit-calculator/stock-unit-calculator.interface";
import type { ManageParts } from "src/app/parts/services/manageParts";
import type { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { assert } from "src/app/shared/utils/assert.utils";

export class CountedAndMeasuredStockUnitCalculator implements StockUnitCalculator {
   public constructor(
      private readonly purchasable: CountedAndMeasuredPurchasable,
      private readonly unitOfMeasureService: UnitOfMeasureService,
      private readonly manageParts: ManageParts,
   ) {}

   public calculate(): number | null {
      if (this.manageParts.updated() === null) return null;

      const { partID } = this.purchasable;
      const part = this.manageParts.getPart(partID);
      assert(part !== undefined, new PartNotFoundError(partID));

      const { providedSizeUnitID } = this.purchasable;
      const stockUnitDescription = part.unitDescription;

      const sizeUnit = this.unitOfMeasureService.getUnit({
         id: providedSizeUnitID,
         type: "provided",
      })();
      const stockUnit = this.unitOfMeasureService.getUnit(stockUnitDescription)();

      if (sizeUnit === null || stockUnit === null) return null;

      assert(
         sizeUnit.isMeasurementUnit && stockUnit.isMeasurementUnit,
         new InvalidConversionUnitsError(),
      );

      return sizeUnit.convertUnit(this.purchasable.size, stockUnit);
   }
}
