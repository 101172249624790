<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <lim-ui-radio-button
            groupID="sensor-config"
            [checked]="config.selected === 0"
            [label]="lang().UpdateTheFieldWithTheMostRecentSensorReading"
            (click)="config.selected = 0; removeErrorMsg()"
         />
         <lim-ui-radio-button
            groupID="sensor-config"
            [checked]="config.selected === 1"
            [label]="
               '<b>' +
               lang().AddOrSubtract +
               '</b> ' +
               lang().FromTheCurrentValueBasedOnRulesYouCreate
            "
            (click)="config.selected = 1; removeErrorMsg()"
         />
         @if (config.selected === 1) {
            <div class="sensor-config-sub-setting">
               @for (rule of config.increment.rules; track rule) {
                  <div class="rule">
                     <a
                        (click)="config.toggleType(rule)"
                        placement="bottom"
                        limUiTooltip="{{ lang().ToggleBetweenAddAndSubtract }}"
                     >
                        {{ config.incrementTypes[rule.type] }}
                     </a>
                     <input type="number" [(ngModel)]="rule.amount" />
                     {{ lang().ToCurrentValueWhenSensorReadingIs }}
                     <a
                        (click)="config.toggleDirection(rule)"
                        placement="bottom"
                        limUiTooltip="{{ lang().ToggleBetweenAboveBelowOrBetween }}"
                     >
                        {{ config.incrementDirections[rule.direction] }}
                     </a>
                     <input type="number" [(ngModel)]="rule.threshold" />
                     <span [hidden]="!(rule.direction == 2)">
                        {{ lang().and }}
                        <input type="number" [(ngModel)]="rule.threshold2" />
                     </span>
                     <lim-ui-minimal-icon-button
                        icon="trashCanRegular"
                        iconSize="small"
                        iconColor="danger"
                        (click)="config.removeIncrementRule($index)"
                        placement="right"
                        limUiTooltip="{{ lang().DeleteRule }}"
                     />
                  </div>
               }
               <div>
                  <lim-ui-secondary-button
                     (click)="config.addIncrementRule()"
                     icon="plus"
                  >
                     {{ lang().AddRule }}
                  </lim-ui-secondary-button>
               </div>
            </div>
         }
         <lim-ui-radio-button
            groupID="sensor-config"
            [checked]="config.selected === 2"
            [label]="
               '<b>' +
               lang().EstimateHowLong +
               '</b> ' +
               lang().TheSensorReadingIsAboveOrBelowASpecifiedValue
            "
            (click)="config.selected = 2; removeErrorMsg()"
            placement="left"
            limUiTooltip="{{ lang().SensorRuntimeExplanationTooltip }}"
         />
         @if (config.selected === 2) {
            <div class="sensor-config-sub-setting">
               <div>
                  {{ lang().KeepTrackOfHowLongReadingIs }}
                  <a
                     (click)="config.toggleDirection(config.runtime)"
                     placement="bottom"
                     limUiTooltip="{{ lang().ToggleBetweenAboveBelowOrBetween }}"
                  >
                     {{ config.incrementDirections[config.runtime.direction] }}
                  </a>
                  <input type="number" [(ngModel)]="config.runtime.threshold" />
                  @if (config.runtime.direction === 2) {
                     <span>
                        {{ lang().and }}
                        <input type="number" [(ngModel)]="config.runtime.threshold2" />
                     </span>
                  }
               </div>
               <div class="track-time-config">
                  {{ lang().TrackTimeIn }}
                  <lim-ui-dropdown-inline-text
                     [label]="config.runtimeUnits[config.runtime.units]"
                  >
                     @for (u of config.runtimeUnits; track u) {
                        <lim-ui-dropdown-text-item
                           displayString="{{ config.runtimeUnits[$index] | capitalize }}"
                           (click)="config.runtime.units = $index"
                        />
                     }
                  </lim-ui-dropdown-inline-text>
                  {{ lang().and }} {{ lang().StartAt }}
                  <input type="number" [(ngModel)]="valueContent" />
                  {{ config.runtimeUnits[config.runtime.units] }}
                  <span
                     class="fa-solid fa-circle-question fa-fw greyColorBoldIcon dashboardItemHintOffset"
                     placement="bottom"
                     limUiTooltip="{{
                        lang().OnceSensorIsAttachedTheFieldWillShowTimeInUnits
                     }}"
                  ></span>
               </div>
            </div>
         }
         @if (errorMsg !== "") {
            <lim-ui-alert
               [title]="lang().ProblemConfiguringSensor + ' - ' + errorMsg"
               alertType="danger"
            />
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-secondary-button (click)="close()">
         {{ lang().Cancel }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button (click)="save()">
         {{ lang().Save }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
