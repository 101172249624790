import { A11yModule } from "@angular/cdk/a11y";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   BasicModalHeaderComponent,
   IconButtonComponent,
   InfoPanelComponent,
   ModalService,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { CommentFiles } from "src/app/files/components/commentFiles/commentFiles.element.component";
import type { FileUploaderModel } from "src/app/files/services/file-service.types";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Mention } from "src/app/shared/components/global/mention/mention";
import { MentionService } from "src/app/shared/components/global/mention/mention.service";
import { WhoGetsNoteNotifications } from "src/app/shared/components/global/whoGetsNoteNotificationsModal/whoGetsNoteNotifications.modal.component";
import { AutoFocusDirective } from "src/app/shared/directives/autofocus/autoFocus.directive";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "add-note",
   templateUrl: "./addNote.modal.component.html",
   styleUrls: ["./addNote.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      IconButtonComponent,
      TooltipDirective,
      ContenteditableDirective,
      AutoFocusDirective,
      FormsModule,
      CommentFiles,
      Mention,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
      A11yModule,
   ],
})
export class AddNote implements OnInit, OnDestroy {
   public data;
   public resolve;
   public modalInstance;
   public title;
   public message;
   public fileExts;
   public checklistID;
   public external;
   public externalEmail;
   public noteHidden;
   public mentionList;
   public userProfiles;
   public newMentionedList;
   public mentionUid;
   public uploadObject: FileUploaderModel | undefined;
   public files;
   public note;
   public userID;
   public externalEmailValue;
   public comment?;
   protected eyeSlashIcon: Aliases = "eyeSlashRegular";
   protected eyeIcon: Aliases = "eyeRegular";
   protected dataLogSubmitLabelLookup: Record<string, string>;

   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageFiles = inject(ManageFiles);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly mentionService = inject(MentionService);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.dataLogSubmitLabelLookup = {
         [this.lang().AddComment]: "task-submitAddComment",
      };
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.fileExts = this.manageFiles.fileExts;
      this.checklistID = this.data.checklistID;
      this.external = this.data.external || false;
      this.externalEmail = this.data.externalEmail || false;

      const customerID = this.data.customerID;
      const currentUser = this.manageUser.getCurrentUser();
      this.noteHidden =
         currentUser == "none"
            ? 0
            : Number(currentUser.userInfo.noteHiddenFromExternalFlag);

      //
      // begging of data and logic for the mention system
      //

      /** List of items passed into the mention system */
      this.mentionList = [];
      /** associative array of users and teams */
      this.userProfiles = [];
      /** newMentionedList is the list of newly mentioned items in the comment */
      this.newMentionedList = [];
      /** random string used to create unique id's for the mention system */
      this.mentionUid = this.manageUtil.getRandomString();

      /** calls function to populate mentioned list */
      if (this.manageUser.getCurrentUser()) {
         this.getMentionList(this.checklistID);
      }

      this.uploadObject = {
         deleteData: {},
         primaryID: "fileName",
         viewOnly: false,
         posturl: this.external
            ? `phpscripts/manageProblem.php?action=makeFileForComment&checklistID=${this.checklistID}&CID=${customerID}`
            : `phpscripts/checklistManager.php?action=makeFileForComment&checklistID=${this.checklistID}`,
         deleteCall: async (dataIn) => {
            return this.manageTask.deleteTempFileFromAddComment(dataIn.fileName);
         },
         deleteSuccess: (answer) => {
            if (answer.data?.success == true) {
               for (const key in this.files) {
                  if (this.files[key].fileName == answer.data?.fileName) {
                     this.files.splice(key, 1);
                  }
               }
               this.files = [...this.files];
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         },
         uploadComplete: (dataIn) => {
            if (dataIn.failed) {
               $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
            } else {
               $("#status").html(
                  `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
               );
            }

            const length = dataIn?.fileName?.length ?? 0;
            if (length > 2) {
               const regex: any = /(?:\.([^.]+))?$/;
               dataIn.row.fileExt = regex.exec(dataIn.row.fileName)[1].toLowerCase();
               if (this.userID === undefined) {
                  this.userID = dataIn.userID;
               }
               if (this.external) {
                  dataIn.row.getURL = `viewFile.php?f=upload-${customerID}/comments/${this.userID}/${this.checklistID}/${dataIn.fileName}&wr=true`;
               } else {
                  dataIn.row.getURL = `viewFile.php?f=upload-${customerID}/comments/${currentUser.userInfo.userID}/${this.checklistID}/${dataIn.fileName}`;
               }
               this.files = this.files || [];
               this.files = [...this.files, dataIn.row];
            }
         },
      };

      if (this.data.comment) {
         this.comment = this.data.comment;
         this.note = this.comment.noteMessage;
      }

      document.addEventListener("keydown", this.submitOnCtlEnter);
   }

   public ngOnDestroy() {
      document.removeEventListener("keydown", this.submitOnCtlEnter);
   }

   /** Gets mention list for the mention system dropdown menu */
   getMentionList = async (checklistID) => {
      const task: Task | undefined = await this.manageTask.getTask(checklistID);
      assert(task);
      const result = this.manageUser.getUsersAndProfilesAtLocation(task.locationID);
      const usersAtLocationData = result.data;

      let teamsWithMembers = usersAtLocationData.profiles.filter((profile) =>
         usersAtLocationData.userProfiles.some(
            (profileCheck) => profileCheck.profileID === profile.profileID,
         ),
      );
      teamsWithMembers = this.manageUtil.alphabeticalSort(
         teamsWithMembers,
         "profileDescription",
      );
      const tags = this.manageUser.getCurrentUser().tags.split("@");
      const customTags: any = [];
      if (
         this.credService.isAuthorized(
            task.locationID,
            this.credService.Permissions.TagATaskWithACustomTag,
         )
      ) {
         for (const key in tags) {
            if (tags[key].length > 0) {
               const obj: any = {};
               obj.name = `@${tags[key]}`;
               obj.tagDescription = tags[key];
               obj.itemID = this.manageUtil.getRandomString();
               customTags.push(obj);
            }
         }
      }
      const tagsList = this.manageUtil.alphabeticalSort(customTags, "tagDescription");

      this.mentionList = this.manageUtil.alphabeticalSort(
         usersAtLocationData.users,
         "userFirstName",
      );
      this.mentionList = this.mentionList.concat(teamsWithMembers);
      this.mentionList = this.mentionList.concat(tagsList);
      this.userProfiles = usersAtLocationData.userProfiles;
   };

   /** creates a callback function that allows the mention child component to update the newMentionedList data here */
   updateNewMentionedList = (newMentionedList) => {
      this.newMentionedList = newMentionedList;
   };

   //
   // end of initializing mention system data and logic
   // on submitting a comment, the data (newMentionedList) is passed down to the chk controller component
   // to handle the adding of users to the notification list, and adding the tags to the tag list.
   //

   showHideNoteSetting = () => {
      if (this.noteHidden == 0) {
         this.noteHidden = 1;
      } else {
         this.noteHidden = 0;
      }
   };

   submit = () => {
      const listOfMentionsToNotify = this.mentionService.createListOfMentionsToNotify(
         this.newMentionedList,
         this.mentionList,
         this.userProfiles,
      );

      const InputEl = document.getElementById(`input-field-${this.mentionUid}`);
      if (InputEl && InputEl.innerHTML != this.note) {
         this.note = InputEl.innerHTML;
      }
      if (this.note === undefined) {
         this.alertService.addAlert(this.lang().AddCommentError, "warning", 6000);
      } else {
         if (
            this.note.length == 0 &&
            (this.files === undefined || Object.entries(this.files).length == 0)
         ) {
            // The note can be empty only if there are files attached
            this.alertService.addAlert(this.lang().AddCommentError, "warning", 6000);
            return;
         }
         if (this.uploadObject?.uploading) {
            this.alertService.addAlert(
               this.lang().pleaseWaitForFileToLoad,
               "warning",
               6000,
            );
            return;
         }

         if (this.external == true && this.externalEmail == false) {
            //we are trying to add a note for an external source and the externalEmail wasn't set going into adding a note
            if (!this.externalEmailValue || this.externalEmailValue == "") {
               this.alertService.addAlert(
                  this.lang().PleaseEnterAValidEmailAddress,
                  "warning",
                  6000,
               );
               return;
            }
         }

         this.note = this.note.replace(/<\s*span[^>]*>/g, "<font color = '#4684d0'>");
         this.note = this.note.replace(/<\s*\/\s*span>/g, "</font>");
         this.note = this.note.replace(/&nbsp;/g, " ");

         const obj: any = {};
         obj.note = this.note;
         obj.noteHidden = this.noteHidden;
         obj.externalEmailValue = this.externalEmailValue;
         obj.newMentionedList = listOfMentionsToNotify;
         this.modalInstance.close(obj);
      }
   };

   whoToNotify = () => {
      const listOfMentionsToNotify = this.mentionService.createListOfMentionsToNotify(
         this.newMentionedList,
         this.mentionList,
         this.userProfiles,
      );

      const instance = this.modalService.open(WhoGetsNoteNotifications);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().whoGetsNoteNotificationsTitle,
            data: {
               checklistID: this.data.checklistID,
               newMentionedList: listOfMentionsToNotify,
            },
         },
      };
   };

   submitOnCtlEnter = (evt) => {
      if (evt.ctrlKey && evt.keyCode === 13) {
         // Have to blur the field manually because ng-model doesn't update with contenteditable divs until it loses focus
         $(".div-text-box").blur();
         evt.preventDefault();
         this.submit();
      }
   };

   close = () => {
      this.modalInstance.close(0);
   };

   protected lookupDataLogSubmitLabel(): string | undefined {
      return this.dataLogSubmitLabelLookup[this.title];
   }
}
