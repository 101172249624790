<lim-ui-panel class="scroll-height-inheritance" [noSidePadding]="true">
   <div class="header">
      @if (options.title) {
         <div class="title-text">
            <lim-ui-icon iconSize="small" icon="squareCheck" />
            <span>{{ options.title }}</span>
         </div>
      }
      <div class="title-buttons-and-search">
         <lim-ui-icon-button
            icon="cloudDownloadRegular"
            limUiTooltip="{{ lang().ExportListToExcel }}"
            (click)="downloadExcel()"
         />
         <lim-ui-search-box
            [(searchVal)]="searchBar"
            (searchValChange)="searchFilter()"
            [placeholder]="lang().Search"
         />
      </div>
   </div>

   @if (!haveDataToDisplay && !objs?.length) {
      <span class="no-data-message">{{ lang().NoItemsToDisplay }}</span>
   }

   @if (!haveDataToDisplay && objs?.length > 0) {
      <no-search-results />
   }

   @if (haveDataToDisplay) {
      <div class="listTable scroll-height-inheritance">
         <div
            class="listItem listItemHeader scrollable-list-header"
            [ngClass]="{ 'table-scrollable-page-header': tableInScrollablePage }"
         >
            <dashboard-list-view-obj-columns-headers
               class="column-headers"
               [columns]="columns"
               [(sort)]="options.sortBind"
            />
         </div>
         <lim-ui-scroll-container [thin]="true">
            @for (
               obj of showObjs
                  | orderBy: options.sortBind
                  | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
               track obj
            ) {
               <div class="listItem">
                  <dashboard-list-view-obj-columns
                     [obj]="obj"
                     [columns]="columns"
                     [searchHints]="searchHints"
                  />
               </div>
            }
         </lim-ui-scroll-container>

         @if (showLoading) {
            <div>
               <div class="loading-icon">
                  <lim-ui-loading-animation />
               </div>
            </div>
         }
      </div>
   }
   <div panelFooter class="pagination">
      <lim-ui-pagination
         [maxSize]="5"
         [rotate]="true"
         [(page)]="page"
         [pageSize]="itemsPerPage"
         [collectionSize]="showObjs?.length"
      />

      @if (showObjs?.length) {
         <lim-ui-dropdown-results-per-page
            [selectedValue]="itemsPerPage"
            [label]="lang().resultsPerPage"
         >
            <ng-container menu>
               @for (num of ["5", "10", "20", "50", "100"]; track num) {
                  <lim-ui-dropdown-text-item
                     [displayString]="num"
                     [outputString]="num"
                     (itemSelectedEvent)="itemsPerPage = num; updateUIPref()"
                  />
               }
            </ng-container>
         </lim-ui-dropdown-results-per-page>
      }
   </div>
</lim-ui-panel>
