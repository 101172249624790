import { PartNotFoundError } from "src/app/parts/errors/part-not-found.error";
import type { MeasuredPurchasable } from "src/app/parts/purchasable/measured-purchasable.model";
import { InvalidConversionUnitsError } from "src/app/parts/purchasable/services/stock-unit-calculator/errors/invalid-conversion-units.error";
import type { StockUnitCalculator } from "src/app/parts/purchasable/services/stock-unit-calculator/stock-unit-calculator.interface";
import type { ManageParts } from "src/app/parts/services/manageParts";
import type { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { assert } from "src/app/shared/utils/assert.utils";

export class MeasuredStockUnitCalculator implements StockUnitCalculator {
   public constructor(
      private readonly purchasable: MeasuredPurchasable,
      private readonly unitOfMeasureService: UnitOfMeasureService,
      private readonly manageParts: ManageParts,
   ) {}

   public calculate(): number | null {
      if (this.manageParts.updated() === null) return null;

      const { partID } = this.purchasable;
      const part = this.manageParts.getPart(partID);
      assert(part !== undefined, new PartNotFoundError(partID));

      const { orderUnitDescription } = this.purchasable;
      const stockUnitDescription = part.unitDescription;

      const orderUnit = this.unitOfMeasureService.getUnit(orderUnitDescription)();
      const stockUnit = this.unitOfMeasureService.getUnit(stockUnitDescription)();

      if (orderUnit === null || stockUnit === null) return null;

      assert(
         orderUnit.isMeasurementUnit && stockUnit.isMeasurementUnit,
         new InvalidConversionUnitsError(),
      );

      return orderUnit.convertUnit(1, stockUnit);
   }
}
