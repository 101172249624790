import {
   ChangeDetectionStrategy,
   Component,
   forwardRef,
   input,
   output,
   ViewChild,
} from "@angular/core";
import {
   type ControlValueAccessor,
   FormsModule,
   NG_VALUE_ACCESSOR,
} from "@angular/forms";
import {
   MultiSelect,
   type MultiSelectBlurEvent,
   type MultiSelectChangeEvent,
   type MultiSelectFilterEvent,
   type MultiSelectFocusEvent,
   MultiSelectModule,
   type MultiSelectRemoveEvent,
   type MultiSelectSelectAllChangeEvent,
} from "primeng/multiselect";
import { noop } from "rxjs";
import type { FilterMatchMode } from "src/app/shared/empowered/base/multi-select/multi-select.models";

@Component({
   selector: "e-multi-select",
   templateUrl: "./multi-select.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
   standalone: true,
   imports: [MultiSelectModule, FormsModule],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => MultiSelectComponent),
         multi: true,
      },
   ],
})
export class MultiSelectComponent implements ControlValueAccessor {
   /**
    * Array of options to display in the dropdown.
    */
   options = input<any[]>();

   /**
    * Defines a string that labels the input for accessibility.
    */
   ariaLabel = input<string>();

   /**
    * Label to display when there are no selections
    */
   placeholder = input<string>();

   /**
    * Unique identifier of the component.
    */
   id = input<string>();

   /**
    * Identifier of the focus input to match a label defined for the component.
    */
   inputId = input<string>();

   /**
    * When present, it specifies that the element should be disabled.
    */
   disabled = input<boolean>(false);

   /**
    * When present, it specifies that the component cannot be edited.
    */
   readonly = input<boolean>(false);

   /**
    * Defines placeholder of the filter input.
    */
   filterPlaceholder = input<string>();

   /**
    * Index of the element in tabbing order.
    */
   tabIndex = input<number>();

   /**
    * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
    */
   ariaLabeledBy = input<string>();

   /**
    * Name of the input element.
    */
   name = input<string>("multiselect");

   /**
    * Defines how the selected items are displayed.
    */
   display = input<"comma" | "chip">("chip");

   /**
    * Whether to show the checkbox at header to toggle all items at once.
    */
   showToggleAll = input<boolean>(false);

   /**
    * Text to display when there is no data.
    */
   emptyMessage = input<string>("");

   /**
    * Text to display when filtering does not return any results.
    */
   emptyFilterMessage = input<string>("");

   /**
    * Whether to show the header.
    */
   showHeader = input<boolean>(true);

   /**
    * Whether to show the filter input.
    */
   filters = input<boolean>(true);

   /**
    * Defines a string that labels the filter input.
    */
   ariaFilterLabel = input<string>();

   /**
    * Defines how the items are filtered.
    */
   filterMatchMode = input<FilterMatchMode>("contains");

   /**
    * Defines the autocomplete is active.
    */
   autocomplete = input<string>("");

   /**
    * When enabled, a clear icon is displayed to clear the value.
    */
   showClear = input<boolean>();

   /**
    * When present, it specifies that the component should automatically get focus on load.
    */
   autofocus = input<boolean>();

   /**
    * Name of the label field of an option.
    */
   optionLabel = input<string>();
   /**
    * Decides how many selected item labels to show at most.
    */
   maxSelectedLabels = input<number>(100);
   /**
    * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element
    * (note: use binding with brackets for template variables, e.g.
    * [appendTo]="mydiv" for a div element having #mydiv as variable name).
    */
   appendTo = input<any>("body");

   /**
    * Name of the disabled field of an option.
    */
   optionDisabled = input<string>();

   /**
    * Callback to invoke when value changes.
    */
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   readonly change = output<MultiSelectChangeEvent>();

   /**
    * Callback to invoke when data is filtered.
    */
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   readonly filter = output<MultiSelectFilterEvent>();

   /**
    * Callback to invoke when multiselect receives focus.
    */
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   readonly focus = output<MultiSelectFocusEvent>();

   /**
    * Callback to invoke when multiselect loses focus.
    */
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   readonly blur = output<MultiSelectBlurEvent>();

   /**
    * Callback to invoke when component is clicked.
    */
   // eslint-disable-next-line angular/no-output-native -- added to not break the existing code while updating to Angular 19
   readonly click = output<Event>();

   /**
    * Callback to invoke when input field is cleared.
    */
   readonly clear = output();

   /**
    * Callback to invoke when overlay panel becomes visible.
    */
   readonly panelShow = output();

   /**
    * Callback to invoke when overlay panel becomes hidden.
    */
   readonly panelHide = output();

   /**
    * Callback to invoke when an item is removed.
    */
   readonly remove = output<MultiSelectRemoveEvent>();

   /**
    * Callback to invoke when all data is selected.
    */
   readonly selectAllChange = output<MultiSelectSelectAllChangeEvent>();

   @ViewChild(MultiSelect)
   public readonly multiSelect!: MultiSelect;
   protected selectedOptions: any[] = [];
   private onChangeCallback: (value: any[]) => void = noop;
   protected onTouchedCallback: () => void = noop;

   public writeValue(value: any[]): void {
      this.selectedOptions = value ?? [];
   }

   public registerOnChange(callback: (value: any[]) => void): void {
      this.onChangeCallback = callback;
   }

   public registerOnTouched(callback: () => void): void {
      this.onTouchedCallback = callback;
   }

   /**
    * Shows the multiselect dropdown.
    */
   public show(): void {
      this.multiSelect?.show();
   }

   /**
    * Hides the multiselect dropdown.
    */
   public hide(): void {
      this.multiSelect?.hide();
   }

   public onChange(event: MultiSelectChangeEvent): void {
      this.onChangeCallback(event.value);
      this.change.emit(event);
   }

   public onFilter(event: MultiSelectFilterEvent): void {
      this.filter.emit(event);
   }

   public onFocus(event: MultiSelectFocusEvent): void {
      this.focus.emit(event);
      this.onTouchedCallback();
   }

   public onBlur(event: MultiSelectBlurEvent): void {
      this.blur.emit(event);
      this.onTouchedCallback();
   }

   public onClick(event: Event): void {
      this.click.emit(event);
   }

   public onClear(event: any): void {
      this.clear.emit(event);
   }

   public onPanelShow(): void {
      this.panelShow.emit();
   }

   public onPanelHide(): void {
      this.panelHide.emit();
   }

   public onRemove(event: MultiSelectRemoveEvent): void {
      this.remove.emit(event);
   }

   public onSelectAllChange(event: MultiSelectSelectAllChangeEvent): void {
      this.selectAllChange.emit(event);
   }
}
