@if (videoTutorial) {
   <h3>
      <strong>{{ videoTutorial.title }}</strong>
   </h3>

   <div
      class="image-container"
      (click)="linkToVideo(videoTutorial.title, videoTutorial.videoId)"
   >
      <img [src]="videoTutorial.thumbnail" alt="Video Thumbnail" />
      <lim-ui-icon
         class="play-icon"
         [icon]="'youtube'"
         [iconColor]="'youtube-red'"
         [iconSize]="'extra-large'"
      />
   </div>
}
