<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="message-area">
            <span class="message" [limbleHtml]="message"></span>

            <lim-ui-icon-button
               icon="youtube"
               iconColor="youtube-red"
               (click)="
                  linkToVideo(
                     lang().QRCodesTutorialVideoTitle,
                     lang().QRCodesTutorialVideoId,
                     120
                  )
               "
               class="lim-ui-hide-on-mobile hidePrint"
            />
         </div>
      </lim-ui-info-panel>

      <div class="pre-panel">
         <lim-ui-secondary-button (click)="showMoreThenOne()">{{
            lang().ShowMoreThanOneAssetQRCode
         }}</lim-ui-secondary-button>

         <div class="slider-and-text">
            {{ lang().ZoomLevel }}
            <div class="hidePrint slider">
               <lim-ui-slider
                  [min]="10"
                  [max]="90"
                  [step]="5"
                  [formControl]="zoomFormControl"
               />
            </div>
         </div>
      </div>

      @if (loading) {
         <lim-ui-panel>
            <div class="loading-container">
               <lim-ui-loading-animation />
               <div class="progress-bar-container">
                  <lim-ui-progress-bar
                     [progress]="(index / assetsLength) * 100 | betterNumber: '1.0-0'"
                     label="{{ lang().PercentageDone }}"
                  />
               </div>
            </div>
         </lim-ui-panel>
      }

      <lim-ui-scroll-container>
         <div id="printQRContainer" class="print-qr-container" [hidden]="!!loading">
            <lim-ui-panel>
               @for (asset of assets; track asset) {
                  <div class="qr-section">
                     <div>
                        <h2 class="lim-ui-fonts-h2" [limbleHtml]="asset.assetName"></h2>
                     </div>

                     <div class="content-container">
                        <div class="qr-item">
                           <span class="qr-text">
                              {{ lang().HavingAProblemWithHint }}

                              <a
                                 href="{{
                                    assetsExtraInfo.get(asset.assetID)?.reportProblemURL
                                 }}"
                                 target="_blank"
                                 class="hidePrint"
                              >
                                 {{
                                    assetsExtraInfo.get(asset.assetID)?.reportProblemURL
                                 }}
                              </a>
                           </span>

                           <div id="qrCodeContainerProblem{{ asset.assetID }}">
                              <div class="qr-code-container">
                                 <span class="resizeable-text"
                                    ><b>{{ lang().ReportAProblem }}</b></span
                                 >
                                 <div
                                    id="qrcodeReportProblem{{ asset.assetID }}"
                                    class="qr-code"
                                 ></div>
                                 <span class="resizeable-text">
                                    <b>{{ lang().AssetName }}:</b> {{ asset.assetName }}
                                    <span
                                       [hidden]="
                                          !assetsExtraInfo.get(asset.assetID)
                                             ?.locationName
                                       "
                                       >{{ lang().LocationName }}:
                                       <b>{{
                                          assetsExtraInfo.get(asset.assetID)?.locationName
                                       }}</b>
                                    </span>
                                 </span>
                              </div>
                           </div>
                        </div>

                        <div class="qr-item">
                           <div class="qr-text">
                              {{ lang().QrCodeLookupAssetHint }}

                              <a
                                 href="{{
                                    assetsExtraInfo.get(asset.assetID)?.lookupAssetURL
                                 }}"
                                 target="_blank"
                                 class="hidePrint"
                              >
                                 {{ assetsExtraInfo.get(asset.assetID)?.lookupAssetURL }}
                              </a>
                           </div>

                           <div id="qrCodeContainerDiag{{ asset.assetID }}">
                              <div class="qr-code-container">
                                 <span class="resizeable-text"
                                    ><b>{{ lang().LookUpAsset }}</b></span
                                 >
                                 <div
                                    id="qrcodeAssetDiag{{ asset.assetID }}"
                                    class="qr-code"
                                 ></div>
                                 <span class="resizeable-text">
                                    <b>{{ lang().AssetName }}:</b> {{ asset.assetName }}
                                    <span
                                       [hidden]="
                                          !assetsExtraInfo.get(asset.assetID)
                                             ?.locationName
                                       "
                                       >{{ lang().LocationName }}:
                                       <b>{{
                                          assetsExtraInfo.get(asset.assetID)?.locationName
                                       }}</b>
                                    </span>
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               }
            </lim-ui-panel>
         </div>
      </lim-ui-scroll-container>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-dropdown>
         <lim-ui-secondary-button
            id="download-info"
            button
            icon="cloudDownloadRegular"
            secondaryIcon="angleDown"
         >
            {{ lang().DownloadUrlsAndInfo }}
         </lim-ui-secondary-button>
         <ng-container menu aria-labelledby="download-info">
            <lim-ui-dropdown-text-item
               (click)="downloadUrls('selected')"
               [displayString]="lang().DownloadSelectedAssets"
            />
            <lim-ui-dropdown-text-item
               (click)="downloadUrls('all')"
               [displayString]="lang().DownloadAllAssets"
            />
         </ng-container>
      </lim-ui-dropdown>

      <lim-ui-secondary-button
         id="print-options"
         button
         icon="cloudDownloadRegular"
         (click)="downloadAll()"
      >
         {{ lang().DownloadImages }}
      </lim-ui-secondary-button>
      <lim-ui-dropdown>
         <lim-ui-primary-button button icon="printRegular" secondaryIcon="angleDown">
            {{ lang().Print + " " + lang().Options }}
         </lim-ui-primary-button>
         <ng-container menu aria-labelledby="print-options">
            <lim-ui-dropdown-text-item
               print-div
               target="printQRContainer"
               [displayString]="lang().Print"
            />
            <lim-ui-dropdown-text-item
               (click)="popQRCodesCustomizePrint()"
               [displayString]="lang().Customize + ' ' + lang().Print"
            />
         </ng-container>
      </lim-ui-dropdown>
   </lim-ui-modal-footer>
</lim-ui-modal>
