<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div [limbleHtml]="message"></div>
      </lim-ui-info-panel>
      <lim-ui-panel
         [showWatermark]="false"
         [noSidePadding]="true"
         [useCustomTitle]="true"
         [showHeaderDivider]="true"
      >
         <div class="vendor-parts-panel-header" panelCustomTitle>
            <span> {{ lang().ManuallyAssociatedWith }}... </span>
            <lim-ui-primary-button (click)="associate()" [hidden]="!changePartVendors">
               {{ lang().AssociateVendors }}
            </lim-ui-primary-button>
         </div>

         <div class="manual-vendor-list">
            @for (vendor of manualVendors | orderBy: "vendorName"; track vendor) {
               <div class="lim-ui-list-item lim-ui-selectable-list-item">
                  <div class="lim-ui-list-item-content">
                     <div class="vendor-information">
                        <lim-ui-icon icon="addressCard" />
                        <a
                           class="vendor-name"
                           (click)="popVendor(vendor); $event.stopPropagation()"
                           >{{ vendor.vendorName }}
                        </a>
                        @if (vendorRelationInfo?.get(vendor.vendorID)?.locationName) {
                           <span class="location-name">
                              -
                              <lim-ui-icon icon="houseChimney" />
                              {{ vendorRelationInfo?.get(vendor.vendorID)?.locationName }}
                           </span>
                        }
                     </div>

                     <div class="lim-ui-list-item-hover-buttons">
                        <lim-ui-minimal-icon-button
                           (click)="breakRelation(vendor)"
                           placement="left"
                           limUiTooltip="{{ lang().RemoveTheManualAssociation }}"
                           icon="trashCanRegular"
                           iconColor="danger"
                           [superMinimal]="true"
                        />
                     </div>
                  </div>
               </div>
            }
            @if (manualVendors.length == 0) {
               <div class="empty-vendors-message">
                  <span [limbleHtml]="zeroManualVendorsMsg"></span>
               </div>
            }
         </div>
      </lim-ui-panel>

      <lim-ui-panel
         [showWatermark]="false"
         [noSidePadding]="true"
         [useCustomTitle]="true"
         [showHeaderDivider]="true"
      >
         <div class="vendor-parts-panel-header" panelCustomTitle>
            <span> {{ lang().PreviouslyPurchasedFrom }}... </span>
         </div>

         <div class="previous-vendor-list">
            @for (vendor of purchasedFromVendors | orderBy: "vendorName"; track vendor) {
               <div class="lim-ui-list-item">
                  <div class="lim-ui-list-item-content">
                     <div class="vendor-information">
                        <lim-ui-icon icon="addressCard" />

                        <a
                           class="vendor-name"
                           (click)="popVendor(vendor); $event.stopPropagation()"
                           >{{ vendor.vendorName }}
                        </a>
                        {{ lang().at }}
                        {{ vendorRelationInfo?.get(vendor.vendorID)?.locationName }}

                        <lim-ui-minimal-icon-button
                           (click)="updateShowDetailsForVendor(vendor.vendorID)"
                           [icon]="
                              vendorRelationInfo?.get(vendor.vendorID)?.showDetails
                                 ? 'chevronUp'
                                 : 'chevronDown'
                           "
                        />
                     </div>
                  </div>
                  @if (vendorRelationInfo?.get(vendor.vendorID)?.showDetails) {
                     <div>
                        @for (
                           po of vendorRelationInfo?.get(vendor.vendorID)?.POs;
                           track po
                        ) {
                           <div>
                              <a class="cursor" (click)="popPoComponent(po.poID)"
                                 >{{ lang().PO }} - {{ po.poNumber }}</a
                              >
                           </div>
                        }
                     </div>
                  }
               </div>
            }
         </div>

         @if (purchasedFromVendors.length == 0) {
            <div class="empty-vendors-message">
               <span [limbleHtml]="zeroPurchasedFromVendorsMsg"></span>
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
