import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   DatePickerInputComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import moment from "moment";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";

@Component({
   selector: "defer-task",
   templateUrl: "./deferTask.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      DatePickerInputComponent,
      NgClass,
      ContenteditableDirective,
      FormsModule,
      BasicModalFooterComponent,
   ],
})
export class DeferTask implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public task: Task | undefined;
   public open;
   public date;
   public reason;
   public errorMsg;

   private readonly paramsService = inject(ParamsService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;

      if (this.resolve.checklistID) {
         const task = this.manageTask.getTaskLocalLookup(this.resolve.checklistID);
         if (task) this.task = task;
      }

      this.open = false;
      this.date = this.task?.checklistDueDate
         ? new Date(this.task.checklistDueDate * 1000)
         : new Date();
   }

   close = () => {
      this.modalInstance.close(false);
   };

   submit = () => {
      if (this.reason === undefined || this.reason == "") {
         this.errorMsg = this.lang().errorDeferTask;
         return;
      }

      const obj: any = {};
      obj.reason = this.reason;
      obj.pickedDate = moment(this.date).endOf("day").format("X");

      this.modalInstance.close(obj);
   };
}
