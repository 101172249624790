<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      title="{{ lang().Edit }} {{ tag.name }}"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message-area" [limbleHtml]="lang().CustomTagDescription"></span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="custom-tag-wrapper">
            <!-- custom tag name -->
            <div class="span-2-columns">
               <label>{{ lang().CustomTagName }}</label>
               <div>
                  <input type="text" [(ngModel)]="tag.name" (blur)="renameTag(tag)" />
               </div>
            </div>

            <!-- send notification when task gains tag -->
            <div>
               <lim-ui-checkbox
                  class="checkbox-row"
                  [model]="gained?.email?.active"
                  (modelChange)="enableDisableEvent('gained', 'email', gained?.email)"
                  [tooltip]="lang().SendANotificationWhenTaskGainsCustomTagTooltip"
                  [label]="lang().SendANotificationWhenTaskGainsCustomTag"
               />
               @if (gained?.email?.active == 1 || gained?.email?.active == true) {
                  <div class="input-form">
                     <label>{{ lang().SendEmailTo }}</label>
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        [limUiTooltip]="lang().SendEmailToTooltip"
                        iconSize="small"
                        placement="top"
                     />
                     <div>
                        <input
                           type="text"
                           [(ngModel)]="gained.email.email"
                           (blur)="
                              updateCustomTagEvent(
                                 'gained',
                                 'email',
                                 gained.email.email,
                                 gained.email.active
                              )
                           "
                        />
                     </div>
                  </div>
               }
            </div>

            <!-- send notification when task loses tag -->
            <div>
               <lim-ui-checkbox
                  class="checkbox-row"
                  [model]="lost?.email?.active"
                  (modelChange)="enableDisableEvent('lost', 'email', lost.email)"
                  [tooltip]="lang().SendANotificationWhenTaskLosesCustomTagTooltip"
                  [label]="lang().SendANotificationWhenTaskLosesCustomTag"
               />
               @if (lost?.email?.active == 1 || lost?.email?.active == true) {
                  <div class="input-form">
                     <label>{{ lang().SendEmailTo }}</label>
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        [limUiTooltip]="lang().SendEmailToTooltip"
                        iconSize="small"
                        placement="top"
                     />
                     <div>
                        <input
                           type="text"
                           [(ngModel)]="lost.email.email"
                           (blur)="
                              updateCustomTagEvent(
                                 'lost',
                                 'email',
                                 lost.email.email,
                                 lost.email.active
                              )
                           "
                        />
                     </div>
                  </div>
               }
            </div>

            <!-- change priority when task gains tag -->
            <div>
               <lim-ui-checkbox
                  class="checkbox-row"
                  [model]="gained?.priority?.active"
                  (modelChange)="
                     enableDisableEvent('gained', 'priority', gained?.priority)
                  "
                  [tooltip]="lang().ChangePriorityWhenTaskGainsCustomTag"
                  [label]="lang().ChangePriorityWhenTaskGainsCustomTag"
               />
               @if (gained?.priority?.active == 1 || gained?.priority?.active == true) {
                  <div class="input-form">
                     <label>{{ lang().SetPriorityTo }}</label>
                     <div>
                        <lim-ui-form-dropdown-input
                           [inputString]="gained?.priority.levelStr"
                        >
                           <div menu>
                              @for (priority of priorityList; track priority) {
                                 <lim-ui-dropdown-item
                                    (click)="
                                       updateCustomTagEvent(
                                          'gained',
                                          'priority',
                                          priority.priorityID,
                                          gained?.priority.active
                                       )
                                    "
                                    >{{ priority.priorityLevel }} -
                                    <span [limbleHtml]="priority.name"></span>
                                 </lim-ui-dropdown-item>
                              }
                           </div>
                        </lim-ui-form-dropdown-input>
                     </div>
                  </div>
               }
            </div>

            <!-- change priority when task loses tag -->
            <div>
               <lim-ui-checkbox
                  class="checkbox-row"
                  [model]="lost?.priority?.active"
                  (modelChange)="enableDisableEvent('lost', 'priority', lost?.priority)"
                  [tooltip]="lang().ChangePriorityWhenTaskLosesCustomTagTooltip"
                  [label]="lang().ChangePriorityWhenTaskLosesCustomTag"
               />
               @if (lost?.priority?.active == 1 || lost?.priority?.active == true) {
                  <div class="input-form">
                     <label>{{ lang().SetPriorityTo }}</label>
                     <div>
                        <lim-ui-form-dropdown-input
                           [inputString]="lost?.priority.levelStr"
                        >
                           <div menu>
                              @for (priority of priorityList; track priority) {
                                 <lim-ui-dropdown-item
                                    (click)="
                                       updateCustomTagEvent(
                                          'lost',
                                          'priority',
                                          priority.priorityID,
                                          lost?.priority.active
                                       )
                                    "
                                    >{{ priority.priorityLevel }} -
                                    <span [limbleHtml]="priority.name"></span>
                                 </lim-ui-dropdown-item>
                              }
                           </div>
                        </lim-ui-form-dropdown-input>
                     </div>
                  </div>
               }
            </div>

            <!-- remove other tag when task gains this tag -->
            <div>
               <lim-ui-checkbox
                  class="checkbox-row"
                  [model]="gained?.removeTags?.active"
                  (modelChange)="
                     enableDisableEvent('gained', 'removeTags', gained?.removeTags)
                  "
                  [tooltip]="lang().RemoveCustomTagsWhenThatTaskGainsThisCustomTagTooltip"
                  [label]="lang().RemoveCustomTagsWhenThatTaskGainsThisCustomTag"
               />
               @if (
                  gained?.removeTags?.active == 1 || gained?.removeTags?.active == true
               ) {
                  <div class="input-form">
                     <label>{{ lang().CustomTags }}</label>
                     <div>
                        <lim-ui-dropdown-inline-text
                           [label]="
                              gained?.removeTags.tags !== ''
                                 ? gained?.removeTags.tags
                                 : lang().PickTags
                           "
                        >
                           <ng-container menu aria-labelledby="chooseDateTypeTasks">
                              @if (
                                 customTagListObjForRemoveTagsGained?.sources &&
                                 customTagListObjForRemoveTagsGained?.sources?.length > 0
                              ) {
                                 <custom-tag-list
                                    [data]="customTagListObjForRemoveTagsGained"
                                 />
                              }
                           </ng-container>
                        </lim-ui-dropdown-inline-text>
                     </div>
                  </div>
               }
            </div>

            <!-- send notification when this task with this tag is completed -->
            <div>
               <lim-ui-checkbox
                  class="checkbox-row"
                  [model]="completed?.email?.active"
                  (modelChange)="
                     enableDisableEvent('completed', 'email', completed?.email)
                  "
                  [tooltip]="
                     lang().SendANotificationWhenTaskIsCompletedWithCustomTagTooltip
                  "
                  [label]="lang().SendANotificationWhenTaskWithThisCustomTagIsCompleted"
               />
               @if (completed?.email?.active == 1 || completed?.email?.active == true) {
                  <div class="input-form">
                     <label>{{ lang().SendEmailTo }}</label>
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        [limUiTooltip]="lang().SendEmailToTooltip"
                        iconSize="small"
                        placement="top"
                     />
                     <div>
                        <input
                           type="text"
                           [(ngModel)]="completed.email.email"
                           (blur)="
                              updateCustomTagEvent(
                                 'completed',
                                 'email',
                                 completed.email.email,
                                 completed.email.active
                              )
                           "
                        />
                     </div>
                  </div>
               }
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-danger-button (click)="deleteTag(tag)">{{
         lang().DeleteCustomTagBtn
      }}</lim-ui-danger-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
