import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import type { Schedule } from "src/app/schedules/schedule.types";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskPartRelation } from "src/app/tasks/types/part/task-part.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "view-single-asset-bar-report",
   templateUrl: "./viewSingleAssetBarReport.modal.component.html",
   styleUrls: ["./viewSingleAssetBarReport.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      SortColumn,
      NgClass,
      IconComponent,
      BetterDatePipe,
      BetterDecimalPipe,
      OrderByPipe,
   ],
})
export class ViewSingleAssetBarReport implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public data: Array<Schedule> = [];
   public currencySymbol;
   public errorMsg;
   public sortBind;
   public assets: Lookup<"assetID", Asset> = new Lookup("assetID");
   protected partsByScheduleID: Map<number, Array<Part & TaskPartRelation>> = new Map();

   private readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUser = inject(ManageUser);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageParts = inject(ManageParts);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.data = this.resolve.data[0]?.customData?.[0] || [];
      this.assets = this.manageAsset.getAssets();

      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }

      this.errorMsg = "";
      this.sortBind = "scheduleStartDate";
      this.setPartsByScheduleID();
   }

   private setPartsByScheduleID() {
      for (const schedule of this.data) {
         const partsForThisSchedule: Array<Part & TaskPartRelation> = [];
         // JIT TODO: fetch these schedules JIT with parts/partRelations attached to them (CMMS-2119)
         const template = this.manageTask.getTaskLocalLookup(schedule.checklistID);
         if (template === undefined) continue;
         for (const partRelationID of template.partRelationIDs) {
            const partRelation = this.manageTask.getPartRelation(partRelationID);
            if (partRelation === undefined) continue;
            const part = this.manageParts.getPart(partRelation.partID);
            if (part === undefined) continue;
            partsForThisSchedule.push({ ...part, ...partRelation });
         }
         this.partsByScheduleID.set(schedule.scheduleID, partsForThisSchedule);
      }
   }

   close = () => {
      this.modalInstance.close(false);
   };

   public popTask(schedule): void {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: schedule.checklistID,
               editable: false,
               preview: true,
               template: true,
            },
         },
      };
   }

   popAsset = (schedule) => {
      const asset = this.assets.get(schedule.assetID);
      if (!asset) {
         return;
      }

      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };
}
