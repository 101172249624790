{
   "NYD": {
      "defaultHolidayID": "NYD",
      "name": "New Year's Day",
      "static": true,
      "dates": ["01/01"]
   },
   "MLK": {
      "defaultHolidayID": "MLK",
      "name": "Martin Luther King Jr. Day",
      "static": false,
      "dates": [
         "01/18/2021",
         "01/17/2022",
         "01/16/2023",
         "01/15/2024",
         "01/20/2025",
         "01/19/2026",
         "01/18/2027",
         "01/17/2028",
         "01/15/2029",
         "01/21/2030",
         "01/20/2031",
         "01/19/2032",
         "01/17/2033",
         "01/16/2034",
         "01/15/2035",
         "01/21/2036",
         "01/19/2037",
         "01/18/2038",
         "01/17/2039",
         "01/16/2040",
         "01/21/2041",
         "01/20/2042",
         "01/19/2043",
         "01/18/2044",
         "01/16/2045",
         "01/15/2046",
         "01/21/2047",
         "01/20/2048",
         "01/18/2049",
         "01/17/2050",
         "01/16/2051",
         "01/15/2052",
         "01/20/2053",
         "01/19/2054",
         "01/18/2055",
         "01/17/2056",
         "01/15/2057",
         "01/21/2058",
         "01/20/2059",
         "01/19/2060",
         "01/17/2061",
         "01/16/2062",
         "01/15/2063",
         "01/21/2064",
         "01/19/2065",
         "01/18/2066",
         "01/17/2067",
         "01/16/2068",
         "01/21/2069",
         "01/20/2070",
         "01/19/2071",
         "01/18/2072",
         "01/16/2073",
         "01/15/2074",
         "01/21/2075",
         "01/20/2076",
         "01/18/2077",
         "01/17/2078",
         "01/16/2079",
         "01/15/2080",
         "01/20/2081",
         "01/19/2082",
         "01/18/2083",
         "01/17/2084",
         "01/15/2085",
         "01/21/2086",
         "01/20/2087",
         "01/19/2088",
         "01/17/2089",
         "01/16/2090",
         "01/15/2091",
         "01/21/2092",
         "01/19/2093",
         "01/18/2094",
         "01/17/2095",
         "01/16/2096",
         "01/21/2097",
         "01/20/2098",
         "01/19/2099",
         "01/18/2100"
      ]
   },
   "PD": {
      "defaultHolidayID": "PD",
      "name": "President's Day",
      "static": false,
      "dates": [
         "02/15/2021",
         "02/21/2022",
         "02/20/2023",
         "02/19/2024",
         "02/17/2025",
         "02/16/2026",
         "02/21/2027",
         "02/21/2028",
         "02/19/2029",
         "02/18/2030",
         "02/17/2031",
         "02/16/2032",
         "02/21/2033",
         "02/20/2034",
         "02/19/2035",
         "02/18/2036",
         "02/16/2037",
         "02/15/2038",
         "02/21/2039",
         "02/20/2040",
         "02/18/2041",
         "02/17/2042",
         "02/16/2043",
         "02/15/2044",
         "02/20/2045",
         "02/19/2046",
         "02/18/2047",
         "02/17/2048",
         "02/15/2049",
         "02/21/2050",
         "02/20/2051",
         "02/19/2052",
         "02/17/2053",
         "02/16/2054",
         "02/15/2055",
         "02/21/2056",
         "02/19/2057",
         "02/18/2058",
         "02/17/2059",
         "02/16/2060",
         "02/21/2061",
         "02/20/2062",
         "02/19/2063",
         "02/18/2064",
         "02/16/2065",
         "02/15/2066",
         "02/21/2067",
         "02/20/2068",
         "02/18/2069",
         "02/17/2070",
         "02/16/2071",
         "02/15/2072",
         "02/20/2073",
         "02/19/2074",
         "02/18/2075",
         "02/17/2076",
         "02/15/2077",
         "02/21/2078",
         "02/20/2079",
         "02/19/2080",
         "02/17/2081",
         "02/16/2082",
         "02/15/2083",
         "02/21/2084",
         "02/19/2085",
         "02/18/2086",
         "02/17/2087",
         "02/16/2088",
         "02/21/2089",
         "02/20/2090",
         "02/19/2091",
         "02/18/2092",
         "02/16/2093",
         "02/15/2094",
         "02/21/2095",
         "02/20/2096",
         "02/18/2097",
         "02/17/2098",
         "02/16/2099",
         "02/15/2100"
      ]
   },
   "MD": {
      "defaultHolidayID": "MD",
      "name": "Memorial Day",
      "static": false,
      "dates": [
         "05/31/2021",
         "05/30/2022",
         "05/29/2023",
         "05/27/2024",
         "05/26/2025",
         "05/25/2026",
         "05/31/2027",
         "05/29/2028",
         "05/28/2029",
         "05/27/2030",
         "05/26/2031",
         "05/31/2032",
         "05/30/2033",
         "05/29/2034",
         "05/28/2035",
         "05/26/2036",
         "05/25/2037",
         "05/31/2038",
         "05/30/2039",
         "05/28/2040",
         "05/27/2041",
         "05/26/2042",
         "05/25/2043",
         "05/30/2044",
         "05/29/2045",
         "05/28/2046",
         "05/27/2047",
         "05/25/2048",
         "05/31/2049",
         "05/30/2050",
         "05/29/2051",
         "05/27/2052",
         "05/26/2053",
         "05/25/2054",
         "05/31/2055",
         "05/29/2056",
         "05/28/2057",
         "05/27/2058",
         "05/26/2059",
         "05/31/2060",
         "05/30/2061",
         "05/29/2062",
         "05/28/2063",
         "05/26/2064",
         "05/25/2065",
         "05/31/2066",
         "05/30/2067",
         "05/28/2068",
         "05/27/2069",
         "05/26/2070",
         "05/25/2071",
         "05/30/2072",
         "05/29/2073",
         "05/28/2074",
         "05/27/2075",
         "05/25/2076",
         "05/31/2077",
         "05/30/2078",
         "05/29/2079",
         "05/27/2080",
         "05/26/2081",
         "05/25/2082",
         "05/31/2083",
         "05/29/2084",
         "05/28/2085",
         "05/27/2086",
         "05/26/2087",
         "05/31/2088",
         "05/30/2089",
         "05/29/2090",
         "05/28/2091",
         "05/26/2092",
         "05/25/2093",
         "05/31/2094",
         "05/30/2095",
         "05/28/2096",
         "05/27/2097",
         "05/26/2098",
         "05/25/2099",
         "05/31/2100"
      ]
   },
   "ID": {
      "defaultHolidayID": "ID",
      "name": "Independence Day",
      "static": true,
      "dates": ["07/04"]
   },
   "LD": {
      "defaultHolidayID": "LD",
      "name": "Labor Day",
      "static": false,
      "dates": [
         "09/06/2021",
         "09/05/2022",
         "09/04/2023",
         "09/02/2024",
         "09/01/2025",
         "09/07/2026",
         "09/06/2027",
         "09/04/2028",
         "09/03/2029",
         "09/02/2030",
         "09/01/2031",
         "09/06/2032",
         "09/05/2033",
         "09/04/2034",
         "09/03/2035",
         "09/01/2036",
         "09/07/2037",
         "09/06/2038",
         "09/05/2039",
         "09/03/2040",
         "09/02/2041",
         "09/01/2042",
         "09/07/2043",
         "09/05/2044",
         "09/04/2045",
         "09/03/2046",
         "09/02/2047",
         "09/07/2048",
         "09/06/2049",
         "09/05/2050",
         "09/04/2051",
         "09/02/2052",
         "09/01/2053",
         "09/07/2054",
         "09/06/2055",
         "09/04/2056",
         "09/03/2057",
         "09/02/2058",
         "09/01/2059",
         "09/06/2060",
         "09/05/2061",
         "09/04/2062",
         "09/03/2063",
         "09/01/2064",
         "09/07/2065",
         "09/06/2066",
         "09/05/2067",
         "09/03/2068",
         "09/02/2069",
         "09/01/2070",
         "09/07/2071",
         "09/05/2072",
         "09/04/2073",
         "09/03/2074",
         "09/02/2075",
         "09/07/2076",
         "09/06/2077",
         "09/05/2078",
         "09/04/2079",
         "09/02/2080",
         "09/01/2081",
         "09/07/2082",
         "09/06/2083",
         "09/04/2084",
         "09/03/2085",
         "09/02/2086",
         "09/01/2087",
         "09/06/2088",
         "09/05/2089",
         "09/04/2090",
         "09/03/2091",
         "09/01/2092",
         "09/07/2093",
         "09/06/2094",
         "09/05/2095",
         "09/03/2096",
         "09/02/2097",
         "09/01/2098",
         "09/07/2099",
         "09/06/2100"
      ]
   },
   "CD": {
      "defaultHolidayID": "CD",
      "name": "Columbus Day",
      "static": false,
      "dates": [
         "10/11/2021",
         "10/10/2022",
         "10/09/2023",
         "10/14/2024",
         "10/13/2025",
         "10/12/2026",
         "10/11/2027",
         "10/09/2028",
         "10/08/2029",
         "10/14/2030",
         "10/13/2031",
         "10/11/2032",
         "10/10/2033",
         "10/09/2034",
         "10/08/2035",
         "10/13/2036",
         "10/12/2037",
         "10/11/2038",
         "10/10/2039",
         "10/08/2040",
         "10/14/2041",
         "10/13/2042",
         "10/12/2043",
         "10/10/2044",
         "10/09/2045",
         "10/08/2046",
         "10/14/2047",
         "10/12/2048",
         "10/11/2049",
         "10/10/2050",
         "10/09/2051",
         "10/14/2052",
         "10/13/2053",
         "10/12/2054",
         "10/11/2055",
         "10/09/2056",
         "10/08/2057",
         "10/14/2058",
         "10/13/2059",
         "10/11/2060",
         "10/10/2061",
         "10/09/2062",
         "10/08/2063",
         "10/13/2064",
         "10/12/2065",
         "10/11/2066",
         "10/10/2067",
         "10/08/2068",
         "10/14/2069",
         "10/13/2070",
         "10/12/2071",
         "10/10/2072",
         "10/09/2073",
         "10/08/2074",
         "10/14/2075",
         "10/12/2076",
         "10/11/2077",
         "10/10/2078",
         "10/09/2079",
         "10/14/2080",
         "10/13/2081",
         "10/12/2082",
         "10/11/2083",
         "10/09/2084",
         "10/08/2085",
         "10/14/2086",
         "10/13/2087",
         "10/11/2088",
         "10/10/2089",
         "10/09/2090",
         "10/08/2091",
         "10/13/2092",
         "10/12/2093",
         "10/11/2094",
         "10/10/2095",
         "10/08/2096",
         "10/14/2097",
         "10/13/2098",
         "10/12/2099",
         "10/11/2100"
      ]
   },
   "TD": {
      "defaultHolidayID": "TD",
      "name": "Thanksgiving Day",
      "static": false,
      "dates": [
         "11/25/2021",
         "11/24/2022",
         "11/23/2023",
         "11/28/2024",
         "11/27/2025",
         "11/26/2026",
         "11/25/2027",
         "11/23/2028",
         "11/22/2029",
         "11/28/2030",
         "11/27/2031",
         "11/25/2032",
         "11/24/2033",
         "11/23/2034",
         "11/22/2035",
         "11/27/2036",
         "11/26/2037",
         "11/25/2038",
         "11/24/2039",
         "11/22/2040",
         "11/28/2041",
         "11/27/2042",
         "11/26/2043",
         "11/24/2044",
         "11/23/2045",
         "11/22/2046",
         "11/28/2047",
         "11/26/2048",
         "11/25/2049",
         "11/24/2050",
         "11/23/2051",
         "11/28/2052",
         "11/27/2053",
         "11/26/2054",
         "11/25/2055",
         "11/23/2056",
         "11/22/2057",
         "11/28/2058",
         "11/27/2059",
         "11/25/2060",
         "11/24/2061",
         "11/23/2062",
         "11/22/2063",
         "11/27/2064",
         "11/26/2065",
         "11/25/2066",
         "11/24/2067",
         "11/22/2068",
         "11/28/2069",
         "11/27/2070",
         "11/26/2071",
         "11/24/2072",
         "11/23/2073",
         "11/22/2074",
         "11/28/2075",
         "11/26/2076",
         "11/25/2077",
         "11/24/2078",
         "11/23/2079",
         "11/28/2080",
         "11/27/2081",
         "11/26/2082",
         "11/25/2083",
         "11/23/2084",
         "11/22/2085",
         "11/28/2086",
         "11/27/2087",
         "11/25/2088",
         "11/24/2089",
         "11/23/2090",
         "11/22/2091",
         "11/27/2092",
         "11/26/2093",
         "11/25/2094",
         "11/24/2095",
         "11/22/2096",
         "11/28/2097",
         "11/27/2098",
         "11/26/2099",
         "11/25/2100"
      ]
   },
   "XMASE": {
      "defaultHolidayID": "XMASE",
      "name": "Christmas Eve",
      "static": true,
      "dates": ["12/24"]
   },
   "XMAS": {
      "defaultHolidayID": "XMAS",
      "name": "Christmas Day",
      "static": true,
      "dates": ["12/25"]
   },
   "NYE": {
      "defaultHolidayID": "NYE",
      "name": "New Year's Eve",
      "static": true,
      "dates": ["12/31"]
   },
   "WKE": {
      "defaultHolidayID": "WKE",
      "name": "Weekends",
      "static": false,
      "dates": []
   }
}
