@if (task) {
   <div class="task-color-status-config-wrapper">
      <div>
         {{ lang().ThisTaskWillBeCreated }}
         <div class="dropdown">
            <lim-ui-dropdown>
               <div button class="form-dropdown">
                  <div>
                     <span class="cursor">{{ checklistGreenDropdownStr }}</span>
                  </div>
               </div>
               <div menu class="form-dropdown-input-menu-wrapper">
                  <lim-ui-dropdown-text-item
                     (click)="updateTemplateScheduleColor(task, 0, 1)"
                     [displayString]="lang().TheDayOf"
                     dataLogLabel="task-schedulerDropdownItem"
                  />
                  @for (dayOption of dayOptions; track dayOption) {
                     <lim-ui-dropdown-text-item
                        (click)="updateTemplateScheduleColor(task, dayOption.days, 1)"
                        [displayString]="dayOption.display + ' ' + lang().before"
                        dataLogLabel="task-schedulerDropdownItem"
                     />
                  }
                  <lim-ui-dropdown-item
                     class="cursor dropdown-with-input"
                     dataLogLabel="task-schedulerDropdownItem"
                  >
                     <div>
                        <input
                           type="number"
                           [(ngModel)]="task.checklistGreen"
                           (blur)="
                              updateTemplateScheduleColor(task, task.checklistGreen, 1)
                           "
                           class="color-config-input"
                           (click)="$event.stopPropagation()"
                        />
                        {{ lang().days }} {{ lang().before }}
                     </div>
                  </lim-ui-dropdown-item>
               </div>
            </lim-ui-dropdown>
         </div>
         {{ lang().TheTasksDueDate }}
      </div>

      <div>
         {{ lang().ThisTaskWillBecomeOverdue }}
         <div class="dropdown">
            <lim-ui-dropdown>
               <div button class="form-dropdown">
                  <div>
                     <span class="cursor">{{ checklistYellowDropdownStr }}</span>
                  </div>
               </div>
               <div menu class="form-dropdown-input-menu-wrapper">
                  <lim-ui-dropdown-text-item
                     (click)="updateTemplateScheduleColor(task, 0, 2)"
                     [displayString]="lang().TheDayOf"
                     dataLogLabel="task-schedulerDropdownItem"
                  />
                  @for (dayOption of dayOptions; track dayOption) {
                     <lim-ui-dropdown-text-item
                        (click)="
                           updateTemplateScheduleColor(task, dayOption.days * -1, 2)
                        "
                        [displayString]="dayOption.display + ' ' + lang().after"
                        dataLogLabel="task-schedulerDropdownItem"
                     />
                  }
                  <lim-ui-dropdown-item
                     class="cursor dropdown-with-input"
                     (click)="$event.stopPropagation()"
                     dataLogLabel="task-schedulerDropdownItem"
                  >
                     <input
                        type="number"
                        [(ngModel)]="checklistYellowDisplayDays"
                        (blur)="
                           updateTemplateScheduleColor(
                              task,
                              checklistYellowDisplayDays,
                              2,
                              true
                           )
                        "
                        class="color-config-input"
                     />
                     {{ lang().days }} {{ lang().after }}
                  </lim-ui-dropdown-item>
               </div>
            </lim-ui-dropdown>
         </div>

         {{ lang().TheTasksDueDate }}
      </div>

      <div>
         {{ lang().ThisTaskWillBecomeCritical }}
         <div class="dropdown">
            <lim-ui-dropdown>
               <div button class="form-dropdown">
                  <div>
                     <span class="cursor">{{ checklistRedDropdownStr }}</span>
                  </div>
               </div>
               <div menu class="form-dropdown-input-menu-wrapper">
                  <lim-ui-dropdown-text-item
                     class="cursor"
                     (click)="updateTemplateScheduleColor(task, 0, 3)"
                     [displayString]="lang().TheDayOf"
                     dataLogLabel="task-schedulerDropdownItem"
                  />
                  @for (dayOption of dayOptions; track dayOption) {
                     <lim-ui-dropdown-text-item
                        class="cursor"
                        (click)="
                           updateTemplateScheduleColor(task, dayOption.days * -1, 3)
                        "
                        [displayString]="dayOption.display + ' ' + lang().after"
                        dataLogLabel="task-schedulerDropdownItem"
                     />
                  }
                  <lim-ui-dropdown-item
                     class="cursor dropdown-with-input"
                     (click)="$event.stopPropagation()"
                     dataLogLabel="task-schedulerDropdownItem"
                  >
                     <input
                        type="number"
                        [(ngModel)]="checklistRedDisplayDays"
                        (blur)="
                           updateTemplateScheduleColor(
                              task,
                              checklistRedDisplayDays,
                              3,
                              true
                           )
                        "
                        class="color-config-input"
                     />

                     {{ lang().days }} {{ lang().after }}
                  </lim-ui-dropdown-item>
               </div>
            </lim-ui-dropdown>
         </div>
         {{ lang().TheTasksDueDate }}
      </div>
   </div>
}
