import { inject, Injectable } from "@angular/core";
import { Temporal } from "@js-temporal/polyfill";
import { ManageLocation } from "src/app/locations/services/manageLocation";

@Injectable({ providedIn: "root" })
export class TemporalUtilsService {
   private readonly manageLocation = inject(ManageLocation);

   /**
    * Converts a `Date` object into a Temporal `ZonedDateTime` object using
    * the provided timezone.
    *
    * The Temporal spec provides an easier way to do this, via
    * Date.prototype.toZonedDateTime(timeZone), but this method is more
    * backwards compatible while we wait for the spec to be fully implemented.
    */
   public dateToZdt(date: Date, timeZone: string): Temporal.ZonedDateTime {
      const instant = Temporal.Instant.fromEpochMilliseconds(date.getTime());
      return instant.toZonedDateTimeISO(timeZone);
   }

   /**
    * Converts a `Date` object into a Temporal `ZonedDateTime` object using
    * the timezone associated with the provided location.
    */
   public dateToZdtForLocation(date: Date, locationID: number): Temporal.ZonedDateTime {
      const timeZone = this.manageLocation.getTimeZoneForLocation(locationID);
      if (timeZone === undefined) {
         throw new Error(`Could not find time zone for location ${locationID}`);
      }
      return this.dateToZdt(date, timeZone);
   }

   /**
    * Converts an epoch timestamp (seconds) into an ISO 8601 date string (such
    * as `2024-10-25`)
    */
   public timestampToIsoDateString(timestamp: number, timeZone: string): string {
      return Temporal.Instant.fromEpochSeconds(timestamp)
         .toZonedDateTimeISO(timeZone)
         .toPlainDate()
         .toString();
   }
}
