import { computed, inject, Injectable, signal, type Signal } from "@angular/core";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import type { CalendarNote } from "src/app/tasks-analytics/calendar/calendar-notes/calendar-note";
import { CalendarNotesApi } from "src/app/tasks-analytics/calendar/calendar-notes/calendar-notes-api";
import { Mutex } from "src/app/tasks-analytics/calendar/calendar-notes/mutex";

/**
 * A service representing the persistence layer for CalendarNotes.
 *
 * This service provides a CRUD interface for CalendarNotes that abstracts away
 * the details of storage, retrieval, and caching.
 */
@Injectable({ providedIn: "root" })
export class CalendarNotesStoreService {
   private readonly cache = signal<Lookup<"id", CalendarNote> | undefined>(undefined);
   public readonly signal: Signal<Lookup<"id", CalendarNote>> = computed(() => {
      return new Lookup("id", this.cache());
   });
   private readonly calendarNotesApi = inject(CalendarNotesApi);
   private readonly syncMutex = new Mutex();

   public constructor() {
      this.sync();
   }

   /**
    * Get a single CalendarNote by ID.
    *
    * @returns The CalendarNote with the given ID, or `undefined` if the note
    * is not found.
    */
   public async get(id: number): Promise<CalendarNote | undefined> {
      await this.syncMutex.whenNoLocksPending();
      const cache = this.cache();
      assert(cache !== undefined);
      return cache.get(id);
   }

   /**
    * Create and save a new CalendarNote.
    *
    * @returns The newly created CalendarNote.
    */
   public async create(note: {
      text: string;
      noteDate: number;
      noteDateEnd: number;
      locationID: number;
      users: Array<number>;
      color: string;
   }): Promise<CalendarNote> {
      await this.syncMutex.whenNoLocksPending();
      const cache = this.cache();
      assert(cache !== undefined);
      const newNote = await this.calendarNotesApi.create(
         note.text,
         note.noteDate,
         note.noteDateEnd,
         note.locationID,
         note.users,
         note.color,
      );
      cache.setValue(newNote);
      this.updateCache();
      return newNote;
   }

   /** Save changes to an existing CalendarNote. */
   public async save(note: CalendarNote): Promise<void> {
      await this.syncMutex.whenNoLocksPending();
      const cache = this.cache();
      assert(cache !== undefined);
      await this.calendarNotesApi.update(note);
      cache.setValue(note);
      this.updateCache();
   }

   /** Delete a CalendarNote by ID. */
   public async delete(id: number): Promise<void> {
      await this.syncMutex.whenNoLocksPending();
      const cache = this.cache();
      assert(cache !== undefined);
      await this.calendarNotesApi.delete(id);
      cache.delete(id);
      this.updateCache();
   }

   /**
    * Refresh this service with the latest information from the backend.
    *
    * This is useful because this service has no way to know when another client
    * has changed the data it is managing. Hopefully one day we can use
    * WebSockets or something to make this unnecessary. Until then, we have to
    * call this method whenever we want the latest data from other clients.
    */
   public async sync(): Promise<void> {
      return this.syncMutex.executeWithLock(async () => {
         /* At current scale, grabbing all notes is okay, but it probably won't
         scale well over time. As of October 2024, the customer with the most notes
         has 3000 notes total. At some point we will have to consider only grabbing
         the notes that are needed for the current view, and making this service a
         lot more sophisticated as a result. */
         const notes = await this.calendarNotesApi.fetchAll();
         this.cache.set(new Lookup("id", notes));
      });
   }

   private updateCache(): void {
      this.cache.set(new Lookup("id", this.cache()));
   }
}
