import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, EventEmitter, Input, Output, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ModalService, LimbleHtmlDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import type { PartVendorRelation } from "src/app/parts/types/vendors-relations/vendor-relation.types";
import { AlertPopup } from "src/app/shared/components/global/alertModal/alertPopup.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "part-number-field",
   templateUrl: "./partNumber.element.component.html",
   styleUrls: ["../shared-field-styles.scss"],
   imports: [NgClass, ContenteditableDirective, FormsModule, LimbleHtmlDirective],
})
export class PartNumber implements OnInit {
   @Input() public partID: number | undefined;
   @Input() public usedIn: "table" | "listItem" | undefined = undefined;
   @Output() public readonly linkToVendor: EventEmitter<string> = new EventEmitter();
   public part: Part | undefined;
   public credChangePartNumber;
   public oldValue;
   public partVendorRelationsDisplay: Lookup<
      "vendorID",
      Partial<Vendor & PartVendorRelation>
   > = new Lookup("vendorID");

   private readonly modalService = inject(ModalService);
   private readonly credService = inject(CredService);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      assert(this.partID);
      this.part = this.manageParts.getPart(this.partID);
      assert(this.part);

      this.credChangePartNumber = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ChangePartNumber,
      );

      for (const relationID of this.part.partVendorRelationIDs) {
         const relation = this.manageAssociations.getPartVendorRelation(relationID);
         if (!relation) {
            continue;
         }
         const vendor = this.manageVendor.getVendor(relation?.vendorID);
         if (!vendor) {
            continue;
         }
         this.partVendorRelationsDisplay.set(vendor.vendorID, {
            partNumber: relation.partNumber,
            vendorID: vendor.vendorID,
            partID: this.part.partID,
            vendorName: vendor.vendorName,
         });
      }
      this.oldValue = this.part.partNumber;
   }

   public async updatePartNumber() {
      assert(this.part);
      if (this.oldValue == this.part.partNumber) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartNumber,
         )
      ) {
         this.alertService.addAlert(this.lang().cred71Fail, "danger", 10000);
         return;
      }

      const answer = await this.manageParts.updatePartNumber(this.part);

      if (answer.data.success == true) {
         this.checkForSameNameAndNumber(this.part.partID);
         this.oldValue = answer.data.partNumber;
         this.part.partNumber = answer.data.partNumber;
      } else if (answer.data.error === "nonUniqueValue") {
         this.part.partNumber = this.oldValue;
         this.alertService.addAlert(this.lang().valueUniqueError, "danger", 6000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   private checkForSameNameAndNumber(partID: number) {
      const foundMultiples = this.manageParts.checkForDuplicateNameAndNumber(partID);
      if (foundMultiples) {
         const instance = this.modalService.open(AlertPopup);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().warningMsgPartNameNumber,
               title: this.lang().DuplicatePartNumber,
            },
         };
         return;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   public popVendor(vendorID: number) {
      const vendor = this.manageVendor.getVendor(vendorID);
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor?.vendorID,
            locationID: vendor?.locationID,
            data: {
               restrict: false,
               navigateToTab: "parts",
            },
         },
      };
   }

   public setToVendorTab() {
      this.linkToVendor.emit("vendors"); //hardcoded id of the vendors tab
   }
}
