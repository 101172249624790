import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DatePickerInputComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   isMobile,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TimeOfDayPicker } from "src/app/shared/components/global/timeOfDayPickerElement/timeOfDayPicker.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "pick-date",
   templateUrl: "./pickDate.modal.component.html",
   styleUrls: ["./pickDate.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      DatePickerInputComponent,
      TimeOfDayPicker,
      AlertComponent,
      ModalFooterComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
   ],
})
export class PickDate implements OnInit {
   public resolve;
   public modalInstance;
   public data;
   public title;
   public message;
   public setTimeFromCurrentDate;
   public buttonText;
   public errorMsg;
   public startDate;
   public viewTimeOfDay;
   public task: Task | undefined;
   public dateTime;
   public timeOfDay;
   public hour;
   public minute;
   public AMPM;
   public errorDue;
   public errorStart;
   public endDateString: string | undefined;

   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly betterDate = inject(BetterDate);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.endDateString = this.lang().CompletedOn;
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const currentDate = this.resolve.currentDate;
      this.data = this.resolve.data;

      this.title = this.resolve.title;
      this.message = this.resolve.message;
      this.setTimeFromCurrentDate = this.data.setTimeFromCurrentDate;
      this.buttonText = this.resolve.buttonText;
      this.errorMsg = "";
      this.startDate = this.data.startDate;
      this.viewTimeOfDay = this.data.viewTimeOfDay;
      if (this.data.checklistID !== undefined) {
         const task = this.manageTask.getTaskLocalLookup(this.data.checklistID);
         if (!task) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }

         this.task = task;
      }
      if (this.data.parentOfDate) {
         const parentOfDate = this.data.parentOfDate;
         if (parentOfDate === "log") {
            this.endDateString = this.lang().logEnteredOn;
         } else if (parentOfDate === "dueDate") {
            this.endDateString = this.lang().DueDate;
         }
         //Completed on is the default so we don't need to do anything of it.
      }

      this.dateTime = new Date(currentDate * 1000);

      if (this.task === undefined) {
         this.timeOfDay = false;
         if (this.manageUser.getCurrentUser().userInfo.customerClockFormat == "24-hr") {
            this.hour = "00";
            this.minute = "00";
            this.AMPM = "";
         } else {
            this.hour = "12";
            this.minute = "00";
            this.AMPM = "AM";
         }
      } else if (this.task.checklistDueDateSetting == 1) {
         this.timeOfDay = true;
         const time = this.getTime(this.task.checklistDueDate);
         this.AMPM = time.AMPM;
         this.hour = time.hour;
         this.minute = time.minute;
      }

      if (this.startDate !== undefined) {
         if (this.startDate.currentDate > 0) {
            this.startDate.show = true;
            this.startDate.dateTime = new Date(this.startDate.currentDate * 1000);
         }

         if (this.startDate.setting == 1) {
            this.startDate.timeOfDay = true;
            const time = this.getTime(this.startDate.currentDate);
            this.startDate.AMPM = time.AMPM;
            this.startDate.hour = time.hour;
            this.startDate.minute = time.minute;
         } else {
            if (
               this.manageUser.getCurrentUser().userInfo.customerClockFormat == "24-hr"
            ) {
               this.startDate.timeOfDay = false;
               this.startDate.hour = "00";
               this.startDate.minute = "00";
               this.startDate.AMPM = "";
            } else {
               this.startDate.timeOfDay = false;
               this.startDate.hour = "12";
               this.startDate.minute = "00";
               this.startDate.AMPM = "AM";
            }
         }
      }

      if (this.setTimeFromCurrentDate) {
         this.timeOfDay = true;
         const time = this.getTime(currentDate);
         this.AMPM = time.AMPM;
         this.hour = time.hour;
         this.minute = time.minute;
      }

      if (this.AMPM) {
         this.AMPM = this.AMPM.trim();
      }
   }

   getTime = (tempDate) => {
      const date = new Date(tempDate * 1000);
      const time: any = {};

      // Get the hour
      time.hour = this.betterDate.formatBetterDate(date, "hour");

      // get the minute
      time.minute = this.betterDate.formatBetterDate(date, "minute");

      // Get AM or PM
      time.AMPM = this.betterDate.formatBetterDate(date, "amOrPm");

      return time;
   };

   close = () => {
      this.modalInstance.close(false);
   };

   submit = () => {
      if (this.dateTime === undefined || this.dateTime == false) {
         this.errorMsg = this.lang().PleaseSelectADate;
         this.errorDue = true;
         return;
      }

      const obj: any = {};
      const date = new Date(this.dateTime);

      if (this.timeOfDay == true) {
         if (this.AMPM === "PM" && this.hour != 12) {
            this.hour = `${Number(this.hour) + 12}`;
         }

         if (this.AMPM === "AM" && this.hour == "12") {
            this.hour = 0;
         }

         obj.timeOfDay = `${this.hour}:${this.minute}:00`;

         date.setHours(this.hour);
         date.setMinutes(this.minute);
         date.setSeconds(0);
      } else {
         if (this.data.setNoTimeOfDayOffset == true) {
            date.setHours(2);
            date.setMinutes(0);
            date.setSeconds(1);
         }
         obj.timeOfDay = false;
      }
      obj.date = date;

      //we have to check on start date too if it is included
      if (this.startDate) {
         obj.startDate = {};
         if (this.startDate.show == true) {
            //ok they plan on using it
            if (
               this.startDate.dateTime === undefined ||
               this.startDate.dateTime == false
            ) {
               //make sure something is set
               this.errorMsg = this.lang().PleaseSelectAStartDate;
               this.errorStart = true;
               return;
            }

            const startDate = new Date(this.startDate.dateTime);

            if (this.startDate.timeOfDay == true) {
               if (this.startDate.AMPM === "PM" && this.startDate.hour != 12) {
                  this.startDate.hour = `${Number(this.startDate.hour) + 12}`;
               }

               if (this.startDate.AMPM === "AM" && this.startDate.hour == "12") {
                  this.startDate.hour = 0;
               }

               obj.startDate.timeOfDay = `${this.startDate.hour}:${this.startDate.minute}:00`;

               startDate.setHours(this.startDate.hour);
               startDate.setMinutes(this.startDate.minute);
               startDate.setSeconds(0);
               obj.startDate.timeOfDay = true;
            } else {
               if (this.data.setNoTimeOfDayOffset == true) {
                  startDate.setHours(2);
                  startDate.setMinutes(0);
                  startDate.setSeconds(1);
               }
               obj.startDate.timeOfDay = false;
            }
            obj.startDate.date = startDate;

            if (obj.startDate.date >= obj.date) {
               //make sure the start date is AFTER the due date
               this.errorMsg = this.lang().TheStartDateMustBeBeforeTheDueDate;
               this.errorStart = true;
               return;
            }
         } else {
            obj.startDate.date = false;
         }
      }

      this.modalInstance.close(obj);
   };

   protected toggleStartDateTime(): void {
      this.startDate.show = !this.startDate.show;
      if (this.startDate.show && !this.startDate.dateTime) {
         const currentDate = this.resolve.currentDate;
         this.startDate.dateTime = new Date(currentDate * 1000);
      }

      if (!this.startDate.show && this.startDate.dateTime) {
         this.startDate.dateTime = undefined;
      }
   }

   protected lookupDataLogSubmitLabel(): string | undefined {
      const dataLogSubmitLabelLookup = {
         [this.lang().changeCompletedTaskExtraTimeDateTitle]:
            "task-submitChangeLoggedWorkDate",
         [this.lang().changeDueDateTitle]: isMobile()
            ? "taskMobile-submitChangeDate"
            : "task-submitChangeDate",
      };
      return dataLogSubmitLabelLookup[this.title];
   }
}
