@if (node && options) {
   <div [ngClass]="{ expanded: node.nodes?.length && !node.collapsed }">
      <div
         (click)="this.userClickSubject.next()"
         class="lim-ui-list-item lim-ui-selectable-list-item"
         [ngClass]="{
            'lim-ui-selected-list-item': node.selected,
            'lim-ui-disabled-list-item': node.preventSelection,
            'clickable': !node.preventSelection,
         }"
         data-test-id="hierarchyItemNode"
      >
         <div class="lim-ui-list-item-content">
            <div class="list-item-sub-container">
               <span class="information-container">
                  @if (
                     (node.nodes && node.nodes.length > 0) ||
                     node.pagination !== undefined
                  ) {
                     <button
                        class="unset"
                        type="button"
                        (click)="collapseNode(node); $event.stopPropagation()"
                        [attr.aria-label]="node.collapsed ? 'Expand' : 'Collapse'"
                        data-test-id="hierarchyItemNodeCollapseButton"
                     >
                        <lim-ui-icon
                           class="collapse-toggle"
                           [icon]="node.collapsed ? 'angleDown' : 'angleUp'"
                           iconSize="small"
                        />
                     </button>
                  }
                  <button
                     class="unset title-and-icons"
                     type="button"
                     [disabled]="node.preventSelection"
                     (click)="this.userClickSubject.next(); $event.stopPropagation()"
                  >
                     @if (node.selected) {
                        <lim-ui-icon
                           icon="check"
                           iconSize="small"
                           iconColor="primary"
                           data-test-id="hierarchyItemNodeSelectedIcon"
                        />
                     }
                     <span
                        [ngClass]="{
                           'search-found-title': node.searchHint?.length,
                           'asset-title': node.titleColor === 'asset',
                        }"
                        class="title"
                     >
                        @if (node.icon) {
                           <lim-ui-icon
                              [icon]="node.icon"
                              iconSize="small"
                              [iconColor]="node.iconColor ?? 'standard'"
                           />
                        }
                        <span [limbleHtml]="node.title"></span>
                        @if (node.nodeID) {
                           <span class="node-id-tag">#{{ node.nodeID }}</span>
                        }
                     </span>
                     @if (node.searchHint && node.searchHint.length) {
                        <span class="search-hint">
                           <div [limbleHtml]="node.searchHint"></div>
                        </span>
                     }
                     @if (node.selected && options.selection?.singleSelection) {
                        <span class="click-again-message">
                           {{ clickAgainText }}
                        </span>
                     }
                  </button>
               </span>

               @if (options.nodeButtons && node.displayButtons) {
                  <div class="buttons clickable">
                     @for (nodeButton of options.nodeButtons; track nodeButton) {
                        <span>
                           @if (nodeButton?.permissionValidated) {
                              <span>
                                 @if (nodeButton.icon) {
                                    <lim-ui-minimal-icon-button
                                       [superMinimal]="true"
                                       [icon]="nodeButton.icon"
                                       [iconColor]="nodeButton.iconColor ?? 'inherit'"
                                       [limUiTooltip]="nodeButton.tooltip"
                                       placement="left"
                                       (click)="
                                          nodeButton.clickFunction &&
                                             nodeButton.clickFunction(node);
                                          $event.stopPropagation()
                                       "
                                    />
                                 }
                                 @if (nodeButton.text) {
                                    <button
                                       class="unset text-node-button"
                                       type="button"
                                       (click)="
                                          nodeButton.clickFunction &&
                                             nodeButton.clickFunction(node);
                                          $event.stopPropagation()
                                       "
                                       [limUiTooltip]="nodeButton.tooltip"
                                       placement="left"
                                       data-test-id="hierarchyItemNodeViewButton"
                                    >
                                       {{ nodeButton.text }}
                                    </button>
                                 }
                              </span>
                           }
                        </span>
                     }
                  </div>
               }
            </div>
         </div>
      </div>
      @if (node.isLoading) {
         <div class="lim-ui-nested-list-item">
            <div class="lim-ui-list-item">
               <div class="lim-ui-list-item-content">
                  <lim-ui-loading-animation animationSize="extra-small" />
               </div>
            </div>
         </div>
      }
      @if (!node.collapsed) {
         @if (node.nodes && node.nodes.length && !node.isLoading) {
            <div class="lim-ui-nested-list-item">
               @for (childNode of node.nodes; track childNode) {
                  <hierarchy-item
                     [node]="childNode"
                     [options]="options"
                     [deselectAllNodes]="deselectAllNodes"
                     [selectNode]="selectNode"
                     [collapseNode]="collapseNode"
                     [clickAgainText]="clickAgainText"
                     data-test-id="hierarchyItemChildNode"
                  />
               }

               @if (node.includeShowMore) {
                  <lim-ui-text-button
                     text="{{ lang().Show }} {{ numberOfChildrenToAdd }} {{
                        lang().more
                     }}"
                     (click)="showMoreItems()"
                     data-test-id="hierarchyItemNodeShowMoreButton"
                  />
                  <span class="number-of-items-rendered">
                     ({{ numberOfItemsRendered }}/{{
                        fetchMore ? node.pagination?.total : node.nodes.length
                     }})
                  </span>
               }
            </div>
         }
      }
   </div>
}
