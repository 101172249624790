import { inject, Injectable } from "@angular/core";
import { UnitOfMeasureAvailabilityService } from "src/app/parts/unit-of-measure/unit-of-measure-availability.service";
import { AnyPurchasablePartPurchaseOrderItemDtoSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/any-purchasable-purchase-order-item.dto";
import { PurchasableSnapshotParserFactoryService } from "src/app/purchasing/pos/purchasable-snapshot/factory/purchasable-snapshot-parser-factory.service";
import type { PurchasableSnapshot } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.model";
import type { PurchaseOrderItemDto } from "src/app/purchasing/pos/purchase-order-item.dto";
import { isPartItem } from "src/app/purchasing/util/is-part-item";

@Injectable({ providedIn: "root" })
export class PurchasableSnapshotService {
   private readonly factory = inject(PurchasableSnapshotParserFactoryService);
   private readonly isFeatureEnabled = inject(UnitOfMeasureAvailabilityService)
      .isFeatureEnabled;

   public parseFromPurchaseOrderItem(
      item: PurchaseOrderItemDto,
   ): PurchasableSnapshot | null | undefined {
      if (this.isFeatureEnabled() === null) return null;
      if (
         this.isFeatureEnabled() === false ||
         !isPartItem(item) ||
         AnyPurchasablePartPurchaseOrderItemDtoSchema.safeParse(item).success === false
      ) {
         return undefined;
      }

      const parser = this.factory.createParser(item);
      return parser.parse();
   }
}
