import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import type { Observable } from "rxjs";
import { debounceTime, shareReplay, Subject } from "rxjs";
import { WebsocketService } from "src/app/shared/services/websockets/websocket.service";

@Injectable({
   providedIn: "root",
})
export class TaskSimultaneousUsersService {
   private numberOfInstances: number;
   private readonly numberOfInstancesChanged$: Subject<number>;
   public readonly update$: Observable<number>;
   private readonly websocketService = inject(WebsocketService);

   public constructor() {
      this.numberOfInstances = 0;
      this.numberOfInstancesChanged$ = new Subject();
      this.update$ = this.numberOfInstancesChanged$.pipe(
         debounceTime(2000),
         shareReplay(1),
      );
      this.update$.subscribe((difference) => {
         if (difference <= 0) {
            return;
         }
         this.retrieveAllOpenTasks();
      });
   }

   public increment() {
      this.numberOfInstances++;
      this.numberOfInstancesChanged$.next(1);
   }

   public decrement() {
      if (this.numberOfInstances <= 0) {
         throw new Error("can't go below zero");
      }
      this.numberOfInstances--;
      this.numberOfInstancesChanged$.next(-1);
   }

   public get() {
      return this.numberOfInstances;
   }

   public clear() {
      const originalNumberOfInstances = this.numberOfInstances;
      this.numberOfInstances = 0;
      this.numberOfInstancesChanged$.next(originalNumberOfInstances * -1);
   }

   /** Find out what tasks are open by other users */
   private async retrieveAllOpenTasks(): Promise<AxiosResponse<void> | undefined> {
      const connectionId = this.websocketService.getConnectionId();
      if (connectionId === undefined) {
         return undefined;
      }
      return axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "retrieveAllOpenTasks",
         },
         data: {
            connectionId,
         },
      });
   }
}
