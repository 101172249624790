@let loadedCalculatedPartInfo = calculatedPartInfo();
@let loadedPart = part();
@if (loadedPart === null || loadedCalculatedPartInfo === null) {
   <lim-ui-loading-animation />
} @else {
   <div class="flex-field-direction-column no-margin-left">
      <div class="flex-field-direction-row">
         @if (credChangePartQty) {
            <input
               title=""
               type="number"
               onclick="this.focus();"
               class="table-field-text number-field input-small-width"
               [class.part-quantity-input-purchasables-version]="
                  unitOfMeasureService.isFeatureEnabled() && usedIn === 'listItem'
               "
               [(ngModel)]="loadedCalculatedPartInfo.totalQty"
               (blur)="updatePartQty()"
            />
         }

         @if (!credChangePartQty) {
            <span>
               {{ loadedCalculatedPartInfo.totalQty }}
            </span>
         }

         @if (unitOfMeasureService.isFeatureEnabled()) {
            @let loadedUnit = unit();
            @let loadedUnits = units();
            @if (loadedUnits === null || loadedUnit === null) {
               <lim-ui-loading-animation />
            } @else {
               <span class="unit-of-measure-with-transfer-parts">
                  @if (usedIn === "listItem" && unitOfMeasureService.isFeatureEnabled()) {
                     @if (canEditBaseUoM) {
                        @if (isStockUnitLocked()) {
                           <lim-ui-form-dropdown-input
                              dataLogLabel="manageUnitsOfMeasure_disabledStockUnitClicked"
                              (click)="disabledStockUnitClicked()"
                              [disabled]="true"
                              [limUiTooltip]="lang().CannotChangePartStockUnitUoMAlert"
                              [inputString]="loadedUnit.short()"
                           />
                        } @else {
                           <lim-ui-form-dropdown-input
                              [limUiTooltip]="loadedUnit.singular()"
                              [inputString]="loadedUnit.short()"
                           >
                              <div menu>
                                 <lim-ui-search-box
                                    [useDropdownStyling]="true"
                                    [(searchVal)]="searchQuery"
                                    [placeholder]="lang().Search"
                                 />

                                 <lim-ui-dropdown-divider />

                                 @if (loadedUnits.length === 0) {
                                    <span class="no-search-results-text">
                                       {{ lang().NoMatchingResultsFound }}
                                    </span>
                                 } @else {
                                    @for (unit of loadedUnits; track unit.id) {
                                       <lim-ui-dropdown-text-item
                                          (itemSelectedEvent)="
                                             changePartUnitOfMeasure(unit)
                                          "
                                          [displayString]="unit.formatted()"
                                          [outputString]="unit.formatted()"
                                       />
                                    }
                                 }
                                 <lim-ui-dropdown-item [closeDropdownOnClick]="true">
                                    <lim-ui-alert alertType="info">
                                       <span
                                          >{{ addUnitHelp }}
                                          @if (isSuperUser) {
                                             <a
                                                class="lim-ui-show-on-desktop"
                                                [routerLink]="['/settings/limbleConfig']"
                                                routerLinkActive="active"
                                                >{{ lang().SettingsPage }}
                                             </a>
                                             <span class="lim-ui-show-on-desktop">{{
                                                lang().AddUnitPartSettings
                                             }}</span>
                                             <span
                                                class="lim-ui-hide-on-desktop lim-ui-show-on-mobile"
                                                >{{ lang().SettingsPage
                                                }}{{ lang().AddUnitPartSettings }}
                                             </span>
                                          }
                                       </span>
                                    </lim-ui-alert>
                                 </lim-ui-dropdown-item>
                              </div>
                           </lim-ui-form-dropdown-input>
                        }
                     } @else {
                        <div [limUiTooltip]="loadedUnit.singular()">
                           {{ loadedUnit.short() }}
                        </div>
                     }
                  }

                  @if (usedIn === "table" && unitOfMeasureService.isFeatureEnabled()) {
                     <div [limUiTooltip]="loadedUnit.singular()">
                        {{ loadedUnit.short() }}
                     </div>
                  }
               </span>
            }
         }

         @if (featureMultipleLocations) {
            <lim-ui-minimal-icon-button
               (click)="transferParts()"
               icon="arrowRightArrowLeft"
               placement="top"
               limUiTooltip="{{ lang().TransferParts }}"
               [superMinimal]="true"
            />
         }
         @if (!featureMultipleLocations) {
            <lim-ui-minimal-icon-button
               icon="arrowRightArrowLeft"
               [superMinimal]="true"
               [ngClass]="{ paywalled: !featureMultipleLocations }"
               [limUiPopover]="startPopover"
               [limUiPopoverType]="'upsell'"
               [limUiPopoverPlacement]="'bottom'"
               [limUiPopoverHidden]="featureMultipleLocations"
            />
         }
         <ng-template #startPopover>
            <upsell-popover
               [message]="lang().FeatureTransferPartsPopoverMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>

         @if (loadedPart.partExtraBatchIDs && loadedPart.partExtraBatchIDs.length > 0) {
            <lim-ui-minimal-icon-button
               (click)="toggleDetails()"
               [icon]="showDetails ? 'chevronUp' : 'chevronDown'"
               limUiTooltip="{{ lang().ViewPartsQtyDetails }}"
               [superMinimal]="true"
            />
         }
      </div>
      @if (
         unitOfMeasureService.isFeatureEnabled() &&
         canViewPurchaseOrders &&
         usedIn === "listItem"
      ) {
         <span
            class="clickable purchasable-note"
            (click)="clickPurchasingTabLink.emit()"
            colorizeString
            [limbleHtml]="lang().PartInfoTabPurchasingLinkSuggestion"
         >
         </span>
      }
      @if (loadedCalculatedPartInfo.reservedQty > 0) {
         <div (click)="popReservedTasks(loadedPart); $event.stopPropagation()">
            <span
               class="table-column-badge badge-color-green"
               limUiTooltip="{{ lang().PartsCurrentlyReservedOnOpenTasks }}"
            >
               {{ loadedCalculatedPartInfo.reservedQty }} {{ lang().reservedLeft }}
            </span>
         </div>
      }

      @if (
         showDetails === true &&
         loadedPart.partExtraBatchIDs &&
         loadedPart.partExtraBatchIDs.length > 0
      ) {
         <div class="flex-field-direction-column">
            <span>
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  limUiTooltip="{{ lang().GeneralStockTooltip }}"
               />
               {{ lang().GS }}:
               <span
                  limUiTooltip="{{ lang().ChangeGeneralStockTooltip }}"
                  placement="bottom"
               >
                  {{ loadedPart.partQty }}
                  @if (unitOfMeasureService.isFeatureEnabled()) {
                     <span
                        [limUiTooltip]="loadedPart.partID | partUnitOfMeasure: 'singular'"
                        >{{ loadedPart.partID | partUnitOfMeasure: "short" }}</span
                     >
                  }
               </span>
            </span>
         </div>
         <div class="flex-field-direction-column extra-small-row-gap">
            @for (extraBatchID of loadedPart.partExtraBatchIDs; track extraBatchID) {
               <div [ngClass]="{ extraBatchBorderBottom: !$last }">
                  @if (manageParts.getExtraBatch(extraBatchID); as extraBatch) {
                     <div class="flex-field-direction-row">
                        @if (extraBatchPONumbers.get(extraBatchID); as poNumber) {
                           <a class="cursor" (click)="popPoComponent(extraBatch.poItemID)"
                              >{{ lang().PO }} - {{ poNumber }}</a
                           >
                        }
                        <span
                           tooltip
                           data-title="{{
                              lang().HowManyPartsOfThePOThatHaveBeenReceived
                           }}"
                        >
                           <span class="mobileLargeShow">{{ lang().Qty }}: </span>
                           {{ (extraBatch.partQty ?? 0) - (extraBatch.partQtyUsed ?? 0) }}
                           @if (unitOfMeasureService.isFeatureEnabled()) {
                              <span
                                 [limUiTooltip]="
                                    loadedPart.partID | partUnitOfMeasure: 'singular'
                                 "
                                 >{{
                                    loadedPart.partID | partUnitOfMeasure: "short"
                                 }}</span
                              >
                           }
                           {{ lang().ofStr }} {{ extraBatch.partQty }}
                           @if (unitOfMeasureService.isFeatureEnabled()) {
                              <span
                                 [limUiTooltip]="
                                    loadedPart.partID | partUnitOfMeasure: 'singular'
                                 "
                                 >{{
                                    loadedPart.partID | partUnitOfMeasure: "short"
                                 }}</span
                              >
                           }
                        </span>
                     </div>
                  }
               </div>
            }
         </div>
      }
   </div>
}
