import type { PartPurchaseOrderItemDto } from "src/app/purchasing/pos/part-purchase-order-item-dto/part-purchase-order-item.dto";
import {
   CountedPurchasablePurchaseOrderItemDtoSchema,
   type CountedPurchasablePurchaseOrderItemDto,
} from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/counted-purchasable-purchase-order-item.dto";
import type { UnitDisplayParserService } from "src/app/purchasing/pos/purchasable-snapshot/parsers/util/unit-display-parser.service";
import type { PurchasableSnapshotParser } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot-parser.interface";
import type { PurchasableSnapshot } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.model";

export class CountedPurchasableSnapshotParser implements PurchasableSnapshotParser {
   public constructor(
      private readonly item: CountedPurchasablePurchaseOrderItemDto,
      private readonly unitDisplayParserService: UnitDisplayParserService,
   ) {}

   public static isValid(
      item: PartPurchaseOrderItemDto,
   ): item is CountedPurchasablePurchaseOrderItemDto {
      return CountedPurchasablePurchaseOrderItemDtoSchema.safeParse(item).success;
   }

   public parse(): PurchasableSnapshot | null {
      const item = this.item;

      const orderUnitDisplay =
         item.purchasableCustomOrderUnitID === null
            ? this.unitDisplayParserService.parseProvided({
                 providedUnitID: item.purchasableProvidedOrderUnitID,
                 providedUnitAlias: item.purchasableProvidedOrderUnitAlias,
              })
            : this.unitDisplayParserService.parseCustom({
                 customUnitNameShort: item.purchasableCustomOrderUnitNameShort,
                 customUnitNameSingular: item.purchasableCustomOrderUnitNameSingular,
              });
      if (orderUnitDisplay === null) return null;

      const stockUnitDisplay =
         item.partStockCustomUnitID === null
            ? this.unitDisplayParserService.parseProvided({
                 providedUnitID: item.partStockProvidedUnitID,
                 providedUnitAlias: item.partStockProvidedUnitAlias,
              })
            : this.unitDisplayParserService.parseCustom({
                 customUnitNameShort: item.partStockCustomUnitNameShort,
                 customUnitNameSingular: item.partStockCustomUnitNameSingular,
              });
      if (stockUnitDisplay === null) return null;

      return {
         partID: item.partID,
         name: item.purchasableName,
         orderUnitDisplay,
         stockUnitDisplay,
         partUnits: item.qty * item.purchasableSize,
      };
   }
}
