@if (allRegionsLength >= 3 || allLocationsLength > 10) {
   <div class="lim-ui-pick-list-search">
      <lim-ui-search-box
         [placeholder]="lang().Search"
         [(searchVal)]="searchBar"
         (searchValChange)="searchFilter()"
         [focusOnLoad]="true"
      />
   </div>
}

@if (
   data.massSelectOptions && !data.selectOne && (searchBar == false || searchBar === "")
) {
   <div class="lim-ui-pick-list-header">
      <span> {{ lang().Locations }} </span>
      <lim-ui-selection-controls
         [selectAll]="lang().SelectAll"
         (handleSelectAll)="markAllLocations($event)"
         [deselectAll]="lang().DeselectAll"
         (handleDeselectAll)="markAllLocations($event)"
      />
   </div>
}

@if (!data.selectOne) {
   <div
      (click)="markAllLocations(!anyLocation)"
      class="lim-ui-list-item lim-ui-selectable-list-item"
      [ngClass]="{
         'lim-ui-selected-list-item lim-ui-selected-pick-list-item': anyLocation == true,
      }"
   >
      <div class="lim-ui-list-item-content-group">
         @if (anyLocation == true) {
            <lim-ui-icon
               icon="check"
               iconSize="small"
               iconColor="primary"
               data-test-id="pickLocationsAnyLocationSelected"
            />
         }
         {{ lang().AnyLocation }}
      </div>
   </div>
}

<ng-template #recursiveListTmpl let-list="list">
   @for (node of list; track node) {
      <div>
         <div
            class="lim-ui-list-item lim-ui-selectable-list-item"
            [ngClass]="{ 'lim-ui-selected-list-item': node.selected == true }"
            id="container-{{ node.uniqueID }}"
            (click)="
               data.selectOne ? pickNodeSelectOne(node.uniqueID) : pickNode(node.uniqueID)
            "
            data-test-id="pickLocationsLocationNode"
         >
            <div class="lim-ui-list-item-content-group">
               <span id="arrow-{{ node.uniqueID }}">
                  @if (node.selected) {
                     <lim-ui-icon
                        icon="check"
                        iconSize="small"
                        iconColor="primary"
                        data-test-id="pickLocationsLocationNodeSelected"
                     />
                  }
               </span>
               @if (node.nodes && node.nodes.length > 0) {
                  <a (click)="collapse(node.uniqueID); $event.stopPropagation()">
                     <lim-ui-icon
                        id="collapse-${node.uniqueID}"
                        [icon]="
                           node && node.showKids == true ? 'chevronUp' : 'chevronDown'
                        "
                        iconColor="standard"
                        iconSize="small"
                        data-test-id="pickLocationsLocationNodeCollapse"
                     />
                  </a>
               }
               @if (node.region || node.noRegion) {
                  <span>
                     <lim-ui-icon icon="earthAmericas" iconSize="small" />
                     {{ node.regionName }}
                  </span>
               }
               @if (!(node.region || node.noRegion)) {
                  <span>
                     <lim-ui-icon icon="houseChimney" iconSize="small" />
                     {{ node.locationName }}
                  </span>
               }
            </div>
         </div>

         @if (node.showKids && node.nodes.length > 0) {
            <div
               [ngClass]="{
                  'lim-ui-nested-list-item': node && node.showKids == true,
               }"
               class="angular-ui-tree-nodes"
               [id]="'childNode-' + node.uniqueID"
            >
               <ng-container
                  *ngTemplateOutlet="recursiveListTmpl; context: { list: node.nodes }"
               />
            </div>
         }
      </div>
   }
</ng-template>

<div class="angular-ui-tree-nodes ng-not-empty">
   <ng-container *ngTemplateOutlet="recursiveListTmpl; context: { list: treeData }" />
</div>

@if (regionTreeLength == 0) {
   <span>
      <no-search-results />
   </span>
}
