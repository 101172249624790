import type { ProvidedUnit } from "src/app/parts/unit-of-measure/provided-units/provided-unit.model";

export class DifferentUnitCategoryConversionError extends Error {
   public constructor(from: ProvidedUnit, to: ProvidedUnit) {
      super(
         `Unit ${from.translationKey} (${from.id}) of category ${from.category} ` +
            `is not in the same category as unit ` +
            `${to.translationKey} (${to.id}) of category ${from.category}.`,
      );
   }
}
