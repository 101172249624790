import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

/*
 * Replaces the input, a number as a string, with the appropriate month in the user's language
 * Usage:
 *   string | monthNumericToString
 */
@Pipe({
   name: "monthNumericToString",
   standalone: true,
})
export class MonthNumericToStringPipe implements PipeTransform {
   private readonly manageLang = inject(ManageLang);

   public transform(inputIn) {
      let input = inputIn;
      if (input !== undefined) {
         const lang = this.manageLang.lang() ?? {};

         switch (Number(input)) {
            case 1:
               input = lang.January;
               break;
            case 2:
               input = lang.February;
               break;
            case 3:
               input = lang.March;
               break;
            case 4:
               input = lang.April;
               break;
            case 5:
               input = lang.May;
               break;
            case 6:
               input = lang.June;
               break;
            case 7:
               input = lang.July;
               break;
            case 8:
               input = lang.August;
               break;
            case 9:
               input = lang.September;
               break;
            case 10:
               input = lang.October;
               break;
            case 11:
               input = lang.November;
               break;
            case 12:
               input = lang.December;
               break;
            default:
         }

         return input;
      }
      return input;
   }
}
