import { Injectable } from "@angular/core";

/** Manipulates HTTP URL strings. Does not support non-HTTP URLs. */
@Injectable({ providedIn: "root" })
export class UrlStringManipulator {
   /** Determines whether the provided string is a valid HTTP(S) URL */
   public isValidHttpUrl(url: string): boolean {
      if (typeof url !== "string") {
         return false;
      }
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
         return false;
      }
      try {
         /* eslint-disable-next-line no-new -- In this case, we are using the
         constructor as a validation function. It would be great if there was a
         static method for this, but there isn't. */
         new URL(url);
      } catch (error) {
         return false;
      }
      return true;
   }

   /** Removes a search parameter from the given url string and returns the decoded URL */
   public removeSearchParam(url: string, key: string): string {
      if (!this.isValidHttpUrl(url)) {
         throw new Error(`Cannot remove search param from invalid URL: ${url}`);
      }
      const urlObj = new URL(url);
      urlObj.searchParams.delete(key);
      return decodeURIComponent(urlObj.toString());
   }

   /** Adds a search parameter to the given url string and returns the decoded URL */
   public setSearchParam(url: string, key: string, value: string): string {
      if (!this.isValidHttpUrl(url)) {
         throw new Error(`Cannot add search param to invalid URL: ${url}`);
      }
      const urlObj = new URL(url);
      urlObj.searchParams.set(key, value);
      return decodeURIComponent(urlObj.toString());
   }

   /**
    * Adds the given string to the current page's URL origin, validates that the result is
    * an HTTP(S) URL, and returns it
    */
   public setRemainderAfterOrigin(origin: string, remainder: string): string {
      const url = `${origin}${this.setStartingSlash(remainder)}`;
      if (!this.isValidHttpUrl(url)) {
         throw new Error(`Invalid URL: ${url}`);
      }
      return url;
   }

   /** Removes the origin from a URL, returning only the decoded path, search, and hash portions */
   public removeOrigin(url: string): string {
      if (!this.isValidHttpUrl(url)) {
         throw new Error(`Cannot remove origin from invalid URL: ${url}`);
      }
      const urlObj = new URL(url);
      return decodeURIComponent(`${urlObj.pathname}${urlObj.search}${urlObj.hash}`);
   }

   private setStartingSlash(path: string): string {
      if (!path.startsWith("/")) {
         return `/${path}`;
      }
      return path;
   }
}
