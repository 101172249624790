@if (part) {
   <lim-ui-panel id="partInfo{{ part.partID }}">
      <div class="lim-ui-list-item">
         <div class="lim-ui-list-item-content">
            <div class="icon-with-text">
               <lim-ui-icon icon="addressCard" iconSize="small" />
               <span>{{ lang().PartName }}</span>
            </div>
            <div class="item-information">
               <part-name-field [partID]="part.partID" />
            </div>
         </div>
      </div>
      <div class="lim-ui-list-item">
         <div class="lim-ui-list-item-content">
            <div class="icon-with-text">
               <lim-ui-icon icon="barcode" iconSize="small" />
               <span>{{ lang().PartNumber }}</span>
            </div>
            <div class="item-information">
               <part-number-field
                  [partID]="part.partID"
                  (LinkToVendor)="switchTab($event)"
               />
            </div>
         </div>
      </div>
      <div class="lim-ui-list-item">
         <div class="lim-ui-list-item-content">
            <div class="icon-with-text">
               <lim-ui-icon icon="hashtagRegular" iconSize="small" />
               <span>{{ lang().Qty }}</span>
            </div>
            <div class="item-information-flex-column">
               @if (!manageParts.partsAreReceiving()) {
                  <part-qty-field
                     [partID]="part.partID"
                     usedIn="listItem"
                     (clickPurchasingTabLink)="switchTab('purchaseOrders')"
                  />
               }
               @if (hasPermissionStartPurchaseOrder()) {
                  <lim-ui-outlined-button
                     (click)="startAPO()"
                     [icon]="canAddPOs ? 'squarePlusRegular' : 'stars'"
                     [disabled]="!canAddPOs"
                     [limUiPopover]="startAddPOButtonPopover"
                     [limUiPopoverType]="'upsell'"
                     [limUiPopoverPlacement]="'right'"
                     [limUiPopoverHidden]="canAddPOs"
                  >
                     {{ lang().AddAPO }}
                  </lim-ui-outlined-button>
               }
               <ng-template #startAddPOButtonPopover>
                  <upsell-popover
                     [message]="lang().FeatureAddPOPopoverMessage"
                     [pricingLinkText]="lang().LearnMore"
                  />
               </ng-template>
            </div>
         </div>
      </div>
      <div class="lim-ui-list-item">
         <div class="lim-ui-list-item-content">
            <div class="icon-with-text">
               <span placement="right">
                  @if (currencyService.isMultiCurrencyEnabled()) {
                     {{ currencyCode() | currencySymbol }}
                  } @else {
                     <lim-ui-icon icon="dollarSign" iconSize="small" />
                  }
               </span>
               <span>{{ lang().Price }}</span>
            </div>
            <div class="item-information">
               <part-price-field [usedIn]="'listItem'" [partID]="part.partID" />
            </div>
         </div>
      </div>
      <div class="lim-ui-list-item">
         <div class="lim-ui-list-item-content">
            <div class="icon-with-text">
               <lim-ui-icon icon="locationDot" iconSize="small" />
               <span>{{ lang().Location }}</span>
            </div>
            <div class="item-information">
               <part-location-field [partID]="part.partID" />
            </div>
         </div>
      </div>
      <div class="lim-ui-list-item">
         <div class="lim-ui-list-item-content">
            <div class="icon-with-text">
               <lim-ui-icon icon="folder" iconSize="small" />
               <span>{{ lang().Category }}</span>
            </div>
            <div class="item-information">
               <part-category-field [partID]="part.partID" usedIn="listItem" />
            </div>
         </div>
      </div>
      <div class="part-information-hierarchy">
         <limble-tree-root
            [data]="fields"
            [options]="treeOptions"
            (treeDrop)="onMoveNode($event)"
         />
      </div>
      <div class="panel-body-bottom-div">
         @if (creds.configurePartFields && part.partDeleted === 0) {
            <lim-ui-primary-button icon="plus" (click)="addField(part)">{{
               lang().AddField
            }}</lim-ui-primary-button>
         }
      </div>
   </lim-ui-panel>
}
