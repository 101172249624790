import type { OnInit } from "@angular/core";
import {
   inject,
   Component,
   HostListener,
   SecurityContext,
   computed,
} from "@angular/core";
import type { SafeResourceUrl } from "@angular/platform-browser";
import { DomSanitizer } from "@angular/platform-browser";
import {
   BasicModalHeaderComponent,
   ModalBodyComponent,
   ModalComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { EnvRoutesService } from "src/app/shared/services/envRoutes.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "approval-auth",
   styleUrls: ["./approvalAuth.modal.component.scss"],
   templateUrl: "./approvalAuth.modal.component.html",
   imports: [ModalBodyComponent, BasicModalHeaderComponent, ModalComponent],
})
export class ApprovalAuth implements OnInit {
   protected src: SafeResourceUrl = "";
   private modalInstance;
   private resolve;

   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly domSanitizer = inject(DomSanitizer);
   private readonly alertService = inject(AlertService);
   private readonly manageLogin = inject(ManageLogin);
   private readonly envRoutesService = inject(EnvRoutesService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      this.setupSrc();
   }

   @HostListener("window:message", ["$event"])
   public async onMessage(event) {
      const envRoutes = await this.envRoutesService.getRoutes();
      if (
         event.origin !== envRoutes.externalBackendAuthServer ||
         event.data === undefined
      ) {
         return;
      }

      const { success, user } = event.data;
      const currentUser = this.manageUser.getCurrentUser();
      let status, message;
      if (success) {
         if (currentUser.gUserID === user) {
            status = "success";
            message = "E signature has been verified.";
         } else {
            status = "failure";
            message = "E signature verification has been attempted by another user.";
         }
      } else if (success === false) {
         status = "failure";
         message = "E signature verification has failed for an unknown reason.";
      } else if (success === null) {
         this.alertService.addAlert(
            this.lang().ForgotPasswordEmailSent,
            "success",
            10000,
         );
         this.logout();
         this.close();
         return;
      }

      this.manageUser.logESignatureVerification({
         status: status,
         message: message,
         user: currentUser.userInfo.userLoginName,
      });

      if (status === "success") {
         this.markVerifiedOnce();
         this.resolve();
         this.close();
      } else {
         this.alertService.addAlert(
            this.lang().ESignatureVerificationFailed,
            "warning",
            10000,
         );
         this.logout();
         this.close();
      }
   }

   protected close = () => {
      if (this.modalInstance !== undefined) {
         this.modalInstance.close(0);
      }
   };

   private async setupSrc(): Promise<void> {
      const currentUser = this.manageUser.getCurrentUser();
      const username = this.domSanitizer.sanitize(
         SecurityContext.URL,
         currentUser.userInfo.userLoginName,
      );
      const userEmail = this.domSanitizer.sanitize(
         SecurityContext.URL,
         currentUser.userInfo.userEmail,
      );

      const envRoutes = await this.envRoutesService.getRoutes();
      let src = `${envRoutes.externalBackendAuthServer}/esignature-verification?username=${username}&userEmail=${userEmail}`;
      if (this.manageUser.verifiedESignatureOnce) {
         src += "&autofill=true";
      }

      this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(src);
   }

   private markVerifiedOnce(): void {
      if (this.manageUser.verifiedESignatureOnce) {
         return;
      }

      this.manageUser.verifiedESignatureOnce = true;
   }

   private logout(): void {
      this.manageLogin.logout();
   }
}
