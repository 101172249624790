<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body [scrollable]="true">
      @if (allPartsLength > 0 && partsLength === 0) {
         <lim-ui-alert alertType="warning">
            {{ lang().YourSearchDidNotYieldAnyPossibleParts }}
         </lim-ui-alert>
      }
      @if (partsLength === 0 && !createPartCred) {
         <lim-ui-alert alertType="warning">
            {{ lang().CreateAPartWarningNoPermission }}
         </lim-ui-alert>
      }
      <lim-ui-panel>
         <div class="panel-container scroll-height-inheritance">
            <div class="po-type-radio-buttons">
               <lim-ui-radio-button
                  groupID="poTypeItemGroup"
                  [checked]="newItemType === 1"
                  [label]="lang().Part"
                  (click)="updateNewItemType(1)"
               />
               <lim-ui-radio-button
                  groupID="poTypeItemGroup"
                  [checked]="newItemType === 2"
                  [label]="lang().Service"
                  (click)="updateNewItemType(2); checkIfStartedFromTask()"
               />
               <lim-ui-radio-button
                  groupID="poTypeItemGroup"
                  [checked]="newItemType === 4"
                  [label]="lang().Other"
                  (click)="updateNewItemType(4); checkIfStartedFromTask()"
               />
            </div>

            @if (newItemType) {
               <ng-container panel-content>
                  @if (newItemType === 1) {
                     <div class="type-container scroll-height-inheritance">
                        <lim-ui-search-all-wrapper>
                           <lim-ui-search-box
                              searchBox
                              [(searchVal)]="searchParts"
                              (searchValChange)="updateSearchParts()"
                              [placeholder]="lang().Search"
                              clearText="{{ lang().clear }}"
                              (click)="createPartShow = false"
                           />
                        </lim-ui-search-all-wrapper>

                        <lim-ui-filters-wrapper title="{{ lang().FilterPartsTo }}:">
                           <lim-ui-dropdown-button
                              label="{{ lang().Part }} {{ lang().Status }}"
                              filterDropdown
                           >
                              <div menu>
                                 <lim-ui-dropdown-text-item
                                    (click)="updatePartFilter('showOnlyVendorParts')"
                                    displayString="{{
                                       lang().PartsAssociatedWithVendor
                                    }}: {{ vendorName }}"
                                 />
                                 <lim-ui-dropdown-text-item
                                    (click)="
                                       updatePartFilter('showOnlyUnderstockedParts')
                                    "
                                    displayString="{{ lang().UnderstockedParts }}"
                                 />
                                 <lim-ui-dropdown-text-item
                                    (click)="
                                       updatePartFilter('showOnlyPartsWithoutOpenPOs')
                                    "
                                    displayString="{{ lang().PartsWithoutOpenPO }}"
                                 />
                              </div>
                           </lim-ui-dropdown-button>

                           <lim-ui-filter-input-wrapper filterInputs>
                              @if (showOnlyVendorParts) {
                                 <label
                                    inputLabel
                                    (click)="updatePartFilter('showOnlyVendorParts')"
                                    class="cursor"
                                 >
                                    <lim-ui-icon
                                       iconColor="danger"
                                       icon="xmarkLargeRegular"
                                       iconSize="small"
                                    />
                                    {{ lang().PartsAssociatedWithVendor }} :
                                    {{ vendorName }}</label
                                 >
                              }
                              @if (showOnlyUnderstockedParts) {
                                 <label
                                    inputLabel
                                    (click)="
                                       updatePartFilter('showOnlyUnderstockedParts')
                                    "
                                    class="cursor"
                                 >
                                    <lim-ui-icon
                                       iconColor="danger"
                                       icon="xmarkLargeRegular"
                                       iconSize="small"
                                    />
                                    {{ lang().UnderstockedParts }}
                                 </label>
                              }
                              @if (showOnlyPartsWithoutOpenPOs) {
                                 <label
                                    inputLabel
                                    (click)="
                                       updatePartFilter('showOnlyPartsWithoutOpenPOs')
                                    "
                                    class="cursor"
                                 >
                                    <lim-ui-icon
                                       iconColor="danger"
                                       icon="xmarkLargeRegular"
                                       iconSize="small"
                                    />
                                    {{ lang().PartsWithoutOpenPO }}
                                 </label>
                              }
                           </lim-ui-filter-input-wrapper>
                        </lim-ui-filters-wrapper>

                        @if (treeData.length && !createPartShow) {
                           <hierarchy-container-legacy
                              #hierarchy
                              [treeData]="treeData"
                              [options]="hierarchyOptions"
                           />
                        }

                        @if (
                           (partsLength === 0 || createPartShow === true) &&
                           createPartCred
                        ) {
                           <div>
                              <label class="control-label bold-text">
                                 {{ lang().CreateAPart }}...
                              </label>
                              <div class="form-wrapper">
                                 <div class="half-column">
                                    <div class="form-group">
                                       <label class="control-label">{{
                                          lang().PartName
                                       }}</label>
                                       <div
                                          type="text"
                                          [(ngModel)]="newPartName"
                                          limbleContentEditable
                                          disableEditor="true"
                                       ></div>
                                    </div>
                                    <div class="form-group">
                                       <label class="control-label">{{
                                          lang().PartPrice
                                       }}</label>

                                       <input
                                          type="number"
                                          [(ngModel)]="newPartPrice"
                                          class="form-control"
                                       />
                                    </div>
                                 </div>
                                 <div class="half-column">
                                    <div class="form-group">
                                       <label class="control-label">{{
                                          lang().PartNumber
                                       }}</label>

                                       <div
                                          type="text"
                                          [(ngModel)]="newPartNumber"
                                          limbleContentEditable
                                          disableEditor="true"
                                       ></div>
                                    </div>
                                    <div class="form-group">
                                       <label class="control-label">{{
                                          lang().Location
                                       }}</label>

                                       <lim-ui-form-dropdown-input
                                          [inputString]="newPartLocationName"
                                       >
                                          <ng-container menu>
                                             @for (
                                                location of newLocations;
                                                track location
                                             ) {
                                                <lim-ui-dropdown-text-item
                                                   [displayString]="location.locationName"
                                                   (click)="setNewPartLocation(location)"
                                                />
                                             }
                                          </ng-container>
                                       </lim-ui-form-dropdown-input>
                                    </div>
                                 </div>
                                 <div class="form-group">
                                    <lim-ui-primary-button
                                       (click)="createPart()"
                                       [disabled]="createPartDisabled || !canAddParts"
                                       [limUiPopover]="createPartPopover"
                                       [limUiPopoverType]="'upsell'"
                                       [limUiPopoverPlacement]="'right'"
                                       [limUiPopoverHidden]="canAddParts"
                                       dataLogLabel="manageParts-storeAPart"
                                    >
                                       {{ lang().Create }}
                                    </lim-ui-primary-button>
                                    <ng-template #createPartPopover>
                                       <upsell-popover
                                          [message]="lang().FeatureAddPartsPopoverMessage"
                                          [pricingLinkText]="lang().LearnMore"
                                       />
                                    </ng-template>
                                 </div>
                              </div>
                           </div>
                        }
                     </div>
                  }

                  @if (newItemType === 2) {
                     <div class="type-container">
                        <div class="form-wrapper">
                           <div class="half-column">
                              <div class="form-group">
                                 <label class="control-label">{{ lang().Name }}</label>
                                 <div
                                    type="textbox"
                                    limbleContentEditable
                                    disableEditor="true"
                                    [(ngModel)]="serviceDescription"
                                    tabindex="1"
                                 ></div>
                              </div>
                           </div>
                        </div>

                        <div class="form-wrapper">
                           <div class="half-column">
                              <div class="form-group">
                                 <div class="label-and-icon">
                                    <label class="control-label">{{ lang().Task }}</label>

                                    <lim-ui-icon
                                       icon="circleQuestionRegular"
                                       limUiTooltip="{{
                                          lang()
                                             .AServiceBasedPOItemMustHaveACorrespondingTask
                                       }}"
                                    />
                                 </div>
                                 <div>
                                    <span [hidden]="!(newChecklistID === 0)">
                                       <a class="cursor" (click)="pickTask()">
                                          {{ lang().PickTask }}
                                       </a>
                                       {{ lang().Or }}
                                       <a class="cursor" (click)="startWorkOrder()">
                                          {{ lang().CreateTask }}
                                       </a>
                                    </span>
                                    @if (task) {
                                       <span [hidden]="!(newChecklistID > 0)">
                                          <span (click)="pickTask()" [hidden]="!task">
                                             <a class="cursor">
                                                {{ task.checklistName }} - #{{
                                                   task.checklistID
                                                }}
                                             </a>
                                             <span
                                                class="greenColor"
                                                [hidden]="!this.assetNameStr"
                                             >
                                                - {{ this.assetNameStr }}
                                             </span>
                                          </span>

                                          <a
                                             class="cursor"
                                             (click)="pickTask()"
                                             [hidden]="!!task"
                                          >
                                             {{ lang().TaskDeleted }}
                                          </a>
                                       </span>
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>

                        @if (
                           purchaseOrder?.vendorID !== null &&
                           purchaseOrder?.vendorID !== 0
                        ) {
                           <div class="form-wrapper previous-service-container">
                              <div class="full-column">
                                 <div class="label-and-icon">
                                    <label class="control-label">{{
                                       lang().BaseServiceOffOf
                                    }}</label>
                                    <lim-ui-icon
                                       icon="circleQuestionRegular"
                                       limUiTooltip="{{
                                          lang()
                                             .ThisIsMadeUpOfServicesYouHavePreviouslyPurchased
                                       }}"
                                    />
                                 </div>
                                 <lim-ui-search-all-wrapper>
                                    <lim-ui-search-box
                                       searchBox
                                       [(searchVal)]="searchServices"
                                       (searchValChange)="filterServicesToSearch()"
                                       [placeholder]="lang().Search"
                                    />
                                 </lim-ui-search-all-wrapper>
                              </div>
                              <div class="services-list">
                                 <div
                                    (click)="setService(0)"
                                    class="lim-ui-list-item lim-ui-selectable-list-item previous-service"
                                    [ngClass]="{
                                       'lim-ui-selected-list-item': newService === true,
                                    }"
                                 >
                                    @if (newService === true) {
                                       <lim-ui-icon icon="check" iconColor="success" />
                                    }
                                    {{ lang().NewService }}
                                 </div>

                                 @for (service of filteredPastServices; track service) {
                                    <div
                                       (click)="setService(service)"
                                       class="lim-ui-list-item lim-ui-selectable-list-item lim-ui-list-item-content-group previous-service"
                                       [ngClass]="{
                                          'lim-ui-selected-list-item':
                                             service.selected === true,
                                       }"
                                    >
                                       @if (service.date) {
                                          @if (service.selected === true) {
                                             <lim-ui-icon
                                                icon="check"
                                                iconColor="success"
                                             />
                                          }

                                          <span
                                             [limbleHtml]="service.prodServDescription"
                                          ></span>
                                          <span [hidden]="!service.description"
                                             >•
                                             <span
                                                [limbleHtml]="service.description"
                                             ></span
                                          ></span>
                                          •
                                          <span>
                                             {{ lang().Qty }}: {{ service.qty }}
                                          </span>
                                          •
                                          <span
                                             >{{ lang().Rate }}: {{ currencySymbol
                                             }}{{
                                                service.rate | betterNumber: "1.2-2"
                                             }}</span
                                          >
                                          •
                                          <span
                                             ><a
                                                class="cursor"
                                                (click)="popPoComponent(service.poID)"
                                                >{{ lang().PO }}:
                                                {{ service.poNumber }}</a
                                             ></span
                                          >
                                          {{ lang().onStr }}
                                          {{ service.date * 1000 | betterDate: "date" }}
                                       }
                                    </div>
                                 }
                              </div>
                           </div>
                        }
                     </div>
                  }

                  @if (newItemType === 4) {
                     <div class="type-container">
                        <div class="form-wrapper">
                           <div class="half-column">
                              <div class="form-group">
                                 <label class="control-label">{{ lang().Name }}</label>

                                 <div
                                    type="textbox"
                                    class="div-text-box"
                                    limbleContentEditable
                                    disableEditor="true"
                                    [(ngModel)]="otherDescription"
                                    tabindex="1"
                                 ></div>
                              </div>
                           </div>
                        </div>

                        <div class="form-wrapper">
                           <div class="half-column">
                              <div class="form-group">
                                 <div class="label-and-icon">
                                    <label class="control-label">{{ lang().Task }}</label>

                                    <lim-ui-icon
                                       icon="circleQuestionRegular"
                                       limUiTooltip="{{
                                          lang()
                                             .AnOtherBasedPOItemMayHaveACorrespondingTask
                                       }}"
                                    />
                                 </div>
                              </div>
                              <div class="form-group">
                                 <span [hidden]="!(newChecklistID === 0)">
                                    <a class="cursor" (click)="pickTask()">
                                       {{ lang().PickTask }}
                                    </a>
                                 </span>
                                 <span [hidden]="!(newChecklistID > 0)">
                                    @if (task) {
                                       <span (click)="pickTask()">
                                          <a class="cursor">
                                             {{ task.checklistName }} - #{{
                                                task.checklistID
                                             }}
                                          </a>
                                          <span
                                             class="greenColor"
                                             [hidden]="!this.assetNameStr"
                                          >
                                             - {{ this.assetNameStr }}
                                          </span>
                                       </span>
                                    }

                                    <a
                                       class="cursor"
                                       (click)="pickTask()"
                                       [hidden]="!!task"
                                    >
                                       {{ lang().TaskDeleted }}
                                    </a>
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  }
               </ng-container>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <div class="modal-footer-buttons">
         <div>
            @if (createPartCred && newItemType === 1) {
               <lim-ui-secondary-button
                  class="left-button"
                  [icon]="
                     createPartShow === false
                        ? canAddParts
                           ? 'plus'
                           : 'stars'
                        : 'arrowLeft'
                  "
                  (click)="toggleCreatePartView()"
                  [disabled]="!canAddParts"
                  [limUiPopover]="toggleCreatePartWithAccessViewPopover"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'right'"
                  [limUiPopoverHidden]="canAddParts"
                  dataLogLabel="POs-initiateAPart"
               >
                  {{ createPartShow === false ? lang().CreatePart : lang().Back }}
               </lim-ui-secondary-button>
            }
            <ng-template #toggleCreatePartWithAccessViewPopover>
               <upsell-popover
                  [message]="lang().FeatureAddPartsPopoverMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>
         </div>

         <div class="modal-footer-end-buttons">
            <!-- 
               We show the save button only if there are parts and we are not creating parts.
               Or, we show it if we are adding a service or other item.
               -->
            @if (
               (partsLength !== 0 && createPartShow === false) ||
               newItemType === 2 ||
               newItemType === 4
            ) {
               <lim-ui-primary-button (click)="submit()">{{
                  lang().Save
               }}</lim-ui-primary-button>
            }
         </div>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
