import type { Observable, Subscription } from "rxjs";
import { BehaviorSubject, Subject } from "rxjs";
import { Lookup } from "src/app/shared/utils/lookup";

/** The base type for all store events */
export interface StoreEventBase {
   type: "add" | "remove" | "reset" | "update" | "general";
   values?: Array<unknown>;
}

/** An event indicating that values were added */
export interface StoreAddEvent<T> extends StoreEventBase {
   type: "add";
   values: Array<T>;
}

/** An event indicating that values were removed */
export interface StoreRemoveEvent<T> extends StoreEventBase {
   type: "remove";
   values: Array<T>;
}

/**
 * An event indicating that the store was reset, removing all previous values
 * and replacing them with new values.
 */
export interface StoreResetEvent extends StoreEventBase {
   type: "reset";
}

/** An event indicating that values were updated */
export interface StoreUpdateEvent<T> extends StoreEventBase {
   type: "update";
   values: Array<T>;
}

/**
 * Indicates that "something changed, but we don't know what". Used by legacy systems.
 *
 * @deprecated
 * Use a granular change type instead.
 */
export interface StoreGeneralEvent extends StoreEventBase {
   type: "general";
}

/** An event emitted by the store */
export type StoreEvent<T> =
   | StoreAddEvent<T>
   | StoreRemoveEvent<T>
   | StoreResetEvent
   | StoreUpdateEvent<T>
   | StoreGeneralEvent;

/**
 * A mutable data container with observable changes.
 *
 * It will emit a change event whenever the data is modified by an internal method.
 * It will not emit anything when the data is modified outside of this class.
 *
 * @example
 * ```typescript
 * const part1: Part;
 * const part2: Part;
 *
 * const store = new Store<"partID", Part>("partID");
 *
 * store.changes().subscribe((change) => {
 *    console.log(change);
 * });
 *
 * store.add(part1); // will log `{ type: "add", values: [part1] }`
 * store.add(part2); // will log `{ type: "add", values: [part2] }`
 * store.remove(part1); // will log `{ type: "remove", values: [part1] }`
 * part2.partName = "new name"; // Does not log anything
 * store.update(part2); // will log `{ type: "update", values: [part2] }`
 * console.log([...store.lookup().values()]); // will log `[part2]`
 * ```
 */
export class MutableStore<K extends keyof T, T extends object> {
   private readonly changes$ = new Subject<StoreEvent<T>>();
   private readonly changesSub: Subscription;
   private destroyed = false;
   private readonly _lookup: Lookup<K, T>;
   private readonly state$: BehaviorSubject<Lookup<K, T>>;

   /**
    * Creates a new store of data.
    * @param identifyingProperty The property of each data element that uniquely identifies it.
    * @param collection An optional collection of data to initialize the store with.
    */
   public constructor(
      private readonly identifyingProperty: K,
      collection: Iterable<T> = [],
   ) {
      this._lookup = new Lookup(this.identifyingProperty, []);
      for (const value of collection) {
         this._lookup.setValue(value);
      }
      this.state$ = new BehaviorSubject(this._lookup);
      this.changesSub = this.changes$.subscribe(() => {
         this.state$.next(this._lookup);
      });
   }

   /** Adds a value or array of values to the data, and emits a StoreAddEvent */
   public add(values: T | Array<T>): void {
      this.checkNotDestroyed();
      const valuesArr = [values].flat() as Array<T>;
      for (const value of valuesArr) {
         this._lookup.setValue(value);
      }
      this.changes$.next({ type: "add", values: valuesArr });
   }

   /**
    * @returns
    * An observable that emits a StoreEvent whenever the data is modified by
    * an internal method.
    */
   public changes(): Observable<StoreEvent<T>> {
      this.checkNotDestroyed();
      return this.changes$.asObservable();
   }

   public destroy(): void {
      this.checkNotDestroyed();
      this.changesSub.unsubscribe();
      this.changes$.complete();
      this.state$.complete();
      this.destroyed = true;
   }

   /** Gets a value by its unique key */
   public get(key: T[K]): T | undefined {
      this.checkNotDestroyed();
      return this._lookup.get(key);
   }

   /**
    * @returns
    * `true` if the store has been destroyed, `false` otherwise.
    */
   public isDestroyed(): boolean {
      return this.destroyed;
   }

   /**
    * @returns
    * The data contained in the store, as a Lookup.
    */
   public lookup(): Lookup<K, T> {
      this.checkNotDestroyed();
      return this._lookup;
   }

   /** Removes a value or array of values from the data, and emits a StoreRemoveEvent */
   public remove(values: T | Array<T>): void {
      this.checkNotDestroyed();
      const valuesArr = [values].flat() as Array<T>;
      for (const value of valuesArr) {
         this._lookup.deleteValue(value);
      }
      this.changes$.next({ type: "remove", values: valuesArr });
   }

   /** Replaces all existing data with new data, and emits a StoreResetEvent */
   public reset(collection: Iterable<T> = []): void {
      this.checkNotDestroyed();
      this._lookup.clear();
      for (const value of collection) {
         this._lookup.setValue(value);
      }
      this.changes$.next({ type: "reset" });
   }

   /**
    * @returns
    * An observable that emits the current data as a Lookup after each
    * modification by an internal method.
    */
   public state(): Observable<Lookup<K, T>> {
      this.checkNotDestroyed();
      return this.state$.asObservable();
   }

   /** Updates a value in the data and emits a StoreUpdateEvent */
   public update(values: T | Array<T>): void {
      this.checkNotDestroyed();
      const valuesArr = [values].flat() as Array<T>;
      for (const value of valuesArr) {
         this._lookup.setValue(value);
      }
      this.changes$.next({ type: "update", values: valuesArr });
   }

   /**
    * Emits an event indicating that "something changed, but we don't know what".
    * Used by legacy systems.
    *
    * @deprecated
    * Use one of the granular change methods instead.
    */
   public emitGeneral(): void {
      this.checkNotDestroyed();
      this.changes$.next({ type: "general" });
   }

   private checkNotDestroyed(): void {
      if (this.isDestroyed()) {
         throw new Error("Store is destroyed and can no longer be used");
      }
   }
}
