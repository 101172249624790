@if (vendor) {
   <lim-ui-panel id="vendorLogPrint{{ vendor.vendorID }}">
      <!-- header section (search, include children, && print header that is hidden until printing) -->
      <div>
         <div class="hidePrint header-section-row">
            <lim-ui-search-box
               [(searchVal)]="searchBar"
               (searchValChange)="searchLogs()"
               [placeholder]="lang().Search"
            />
         </div>
         <!-- print related content -->
         @if (vendor) {
            <div class="printViewShow">
               <h5>
                  <span class="printViewShow">
                     <lim-ui-icon icon="list" iconSize="medium" class="icon-styling" />
                     <b>{{ lang().VendorTabLogHeading }}</b> -
                     {{ lang().VendorTabLogHeadingMsg }}
                  </span>
               </h5>
            </div>
         }
      </div>

      <div class="panel-body panel-bottom panel-middleGreen">
         @if (vendor) {
            <div class="listTable lim-ui-responsive-table">
               <!-- filter section -->
               <div class="filters-row hidePrint">
                  <lim-ui-filters-wrapper [noMargins]="true" title="{{ lang().Filter }}:">
                     <lim-ui-dropdown-button [label]="lang().Source" filterDropdown>
                        <div menu>
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().ManualEntry }}"
                              (click)="filterSource(2)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().CompletedWOs }}"
                              (click)="filterSource(6)"
                           />

                           <lim-ui-dropdown-divider />

                           <lim-ui-dropdown-clear-filter-item (click)="clearSource()">
                              {{ lang().ClearFilter }}
                           </lim-ui-dropdown-clear-filter-item>
                        </div>
                     </lim-ui-dropdown-button>
                     <lim-ui-dropdown-button [label]="lang().Date" filterDropdown>
                        <div menu>
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Last7Days }}"
                              (click)="filterDate(86400 * 7 * 1000)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Last14Days }}"
                              (click)="filterDate(86400 * 14 * 1000)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Last30Days }}"
                              (click)="filterDate(86400 * 30 * 1000)"
                           />
                           <lim-ui-dropdown-text-item
                              displayString="{{ lang().Last60Days }}"
                              (click)="filterDate(86400 * 60 * 1000)"
                           />

                           <lim-ui-dropdown-divider />

                           <lim-ui-dropdown-clear-filter-item (click)="clearDateFilter()">
                              {{ lang().ClearFilter }}
                           </lim-ui-dropdown-clear-filter-item>
                        </div>
                     </lim-ui-dropdown-button>
                     <lim-ui-filter-input-wrapper filterInputs>
                        @if (sourceState) {
                           <lim-ui-filter-input (clearFilterEvent)="clearSource()">
                              <label inputLabel> {{ lang().ActiveFilter }}:</label>
                              <b additionalContent>
                                 <span [hidden]="!(sourceID == 2)">{{
                                    lang().ManualEntry
                                 }}</span>
                                 <span [hidden]="!(sourceID == 5)">{{
                                    lang().ProblemReport
                                 }}</span>
                                 <span [hidden]="!(sourceID == 6)">{{
                                    lang().CompletedWOsPMs
                                 }}</span>
                              </b>
                           </lim-ui-filter-input>
                        }
                        @if (dateState) {
                           <lim-ui-filter-input (clearFilterEvent)="clearDateFilter()">
                              <label inputLabel> {{ lang().ActiveFilter }}:</label>
                              <b additionalContent>
                                 <span [hidden]="!(dateDuration == 86400 * 7 * 1000)">{{
                                    lang().Last7Days
                                 }}</span>
                                 <span [hidden]="!(dateDuration == 86400 * 14 * 1000)">{{
                                    lang().Last14Days
                                 }}</span>
                                 <span [hidden]="!(dateDuration == 86400 * 30 * 1000)">{{
                                    lang().Last30Days
                                 }}</span>
                                 <span [hidden]="!(dateDuration == 86400 * 60 * 1000)">{{
                                    lang().Last60Days
                                 }}</span>
                              </b>
                           </lim-ui-filter-input>
                        }
                     </lim-ui-filter-input-wrapper>
                  </lim-ui-filters-wrapper>
               </div>

               <!-- Logs Table -->
               <div
                  class="listItem listItemHeader lim-ui-responsive-table-header"
                  [hidden]="!(totalLogs > 0)"
               >
                  <sort-column
                     class="col-md-3 mobileLargeDisappear"
                     column="logDate"
                     name="{{ lang().Date }}"
                     [(bind)]="orderBy"
                     [sortFn]="sortLogsBySortBind"
                  />
                  <sort-column
                     class="col-md-6 mobileLargeDisappear"
                     column="sourceID"
                     name="{{ lang().Entry }}"
                     [(bind)]="orderBy"
                     [sortFn]="sortLogsBySortBind"
                  />
                  <sort-column
                     class="col-md-3 mobileLargeDisappear"
                     column="userLastName"
                     name="{{ lang().User }}"
                     [(bind)]="orderBy"
                     [sortFn]="sortLogsBySortBind"
                  />
               </div>

               <span [hidden]="!noSearchResults">
                  <no-search-results />
               </span>

               @for (
                  log of logItems
                     | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
                  track log
               ) {
                  <div
                     class="listItem lim-ui-responsive-table-row"
                     [ngClass]="{ tranactionEveryOther: $even }"
                  >
                     <div
                        class="col-md-3 log-item-date-area lim-ui-responsive-table-cell"
                     >
                        @if (log.logStatus == 1 || log.logStatus == 2) {
                           <lim-ui-icon
                              icon="circleCheck"
                              iconSize="small"
                              iconColor="success"
                              [limUiTooltip]="lang().LogIsInGoodStatus"
                              placement="top"
                           />
                        }
                        @if (log.logStatus == 3 || log.logStatus == 4) {
                           <lim-ui-icon
                              icon="triangleExclamation"
                              iconSize="small"
                              iconColor="warn"
                              [limUiTooltip]="lang().LogIsInWarningStatus"
                              placement="top"
                           />
                        }
                        @if (log.logStatus == 5 || log.logStatus == 6) {
                           <lim-ui-icon
                              icon="circleExclamation"
                              iconSize="small"
                              iconColor="danger"
                              [limUiTooltip]="lang().LogIsInCriticalStatus"
                              placement="top"
                           />
                        }
                        {{ log.logDate | betterDate: "dateTime" }}
                     </div>

                     <div
                        class="col-md-6 log-item-content-area lim-ui-responsive-table-cell"
                     >
                        @if (log.sourceID == 2) {
                           <div>
                              <div [hidden]="!log.logDetails">
                                 <span [limbleHtml]="log.logDetails"></span>
                              </div>

                              <div
                                 [hidden]="!(log.logFiles.length > 0)"
                                 [ngClass]="{ marginRight65: manageVendorLogCred }"
                              >
                                 <div
                                    [hidden]="!(log.logFiles.length > 0)"
                                    [ngClass]="{ marginRight65: manageVendorLogCred }"
                                 >
                                    @for (file of log.logFiles; track file) {
                                       <div class="list-group">
                                          <div class="list-group-item">
                                             <file-list-item
                                                [file]="file"
                                                url="viewFile.php?f=upload-{{
                                                   CID
                                                }}/vendors/{{ vendor.locationID }}/{{
                                                   file.vendorID
                                                }}/logs/{{ log.vendorLogID }}/{{
                                                   file.fileName | escape
                                                }}"
                                             />
                                          </div>
                                       </div>
                                    }
                                 </div>
                              </div>

                              <div [hidden]="!(log.logPictures.length > 0)">
                                 @for (picture of log.logPictures; track picture) {
                                    <span>
                                       <view-image [src]="picture.getURL" />
                                    </span>
                                 }
                              </div>
                           </div>
                        }

                        @if (log.sourceID == 5) {
                           <div>
                              <div [hidden]="!log.logDetails">
                                 {{ log.logDetails }}
                              </div>
                           </div>
                        }

                        @if (log.sourceID == 6) {
                           <div>
                              <lim-ui-icon
                                 class="icon-styling"
                                 icon="wrench"
                                 iconSize="small"
                                 [hidden]="!(log.logDetails.checklistTemplateOld == 1)"
                              />
                              <lim-ui-icon
                                 class="icon-styling"
                                 icon="wpforms"
                                 iconSize="small"
                                 [hidden]="!(log.logDetails.checklistTemplateOld == 2)"
                              />
                              <lim-ui-icon
                                 class="icon-styling"
                                 icon="file"
                                 iconSize="small"
                                 [hidden]="!(log.logDetails.checklistTemplateOld == 4)"
                              />

                              <a
                                 class="cursor"
                                 (click)="popTask(log.logDetails.checklistID)"
                              >
                                 <span [limbleHtml]="log.logDetails.checklistName"></span>
                              </a>

                              {{ lang().HasBeenSuccessfullyCompleted }}
                           </div>
                        }

                        @if (log.sourceID == 2 && manageVendorLogCred) {
                           <span>
                              <lim-ui-row-hover-buttons
                                 class="hover-buttons"
                                 [showHideButton]="true"
                                 [showEditButton]="true"
                                 [showDeleteButton]="true"
                                 [showCopyButton]="false"
                                 [hideButtonTooltip]="lang().ShowHideVendorLogEntry"
                                 [editButtonTooltip]="lang().EditThisLogEntry"
                                 [deleteButtonTooltip]="lang().DeleteThisLogEntry"
                                 (clickHide)="hideShowLogEntry(log)"
                                 (clickEdit)="editLogEntry(log)"
                                 (clickDelete)="deleteLogEntry(log)"
                              />
                           </span>
                        }
                     </div>

                     <div class="col-md-3 lim-ui-responsive-table-cell">
                        <div [hidden]="!(log.sourceID == 5)">
                           <lim-ui-icon
                              class="icon-styling"
                              icon="triangleExclamation"
                              iconSize="small"
                              limUiTooltip="{{ lang().ThisWasPlacedByAProblemReport }}"
                              data-placement="top"
                           />
                           {{ log.userFirstName }} {{ log.userLastName }}
                        </div>
                        <div [hidden]="!(log.sourceID == 2)">
                           <span [hidden]="!(log.userID > 0)">
                              <lim-ui-icon
                                 icon="user"
                                 iconSize="small"
                                 limUiTooltip="{{ lang().ThisWasEnteredByAUser }}"
                                 data-placement="top"
                              />
                              {{ log.userFirstName }} {{ log.userLastName }}
                           </span>
                           <span [hidden]="!(log.userID == 0)">
                              <lim-ui-icon
                                 icon="wandMagicSparkles"
                                 iconSize="small"
                                 limUiTooltip="{{
                                    lang().ThisWasAutomaticallyAddedByLimble
                                 }}"
                                 data-placement="top"
                              />
                              {{ lang().AutomaticallyAdded }}
                           </span>
                        </div>
                        <div [hidden]="!(log.sourceID == 6)">
                           <lim-ui-icon
                              icon="user"
                              iconSize="small"
                              limUiTooltip="{{ lang().ThisTaskWasCompletedBy }}"
                              data-placement="top"
                           />
                           {{ log.userFirstName }} {{ log.userLastName }}
                        </div>
                     </div>
                  </div>
               }

               @if (!restrict) {
                  <div class="comments-container hidePrint">
                     <div class="comments-instructions-and-entry-container">
                        <div class="instruction-text">
                           {{ lang().AddEntry }}
                        </div>
                        <span class="date-and-time">
                           {{ lang().at }}
                           <a class="cursor" (click)="setAddEntryDate()">{{
                              addEntryDate | betterDate: "dateTime"
                           }}</a>
                        </span>
                        <div class="eye-eyecon">
                           @if (manageVendorLogCred) {
                              <lim-ui-icon-button
                                 [icon]="newEntryViewable == 1 ? eyeIcon : eyeSlashIcon"
                                 placement="top"
                                 limUiTooltip="{{ lang().ShowHideVendorLogEntry }}"
                                 (click)="showHideNewEntry()"
                              />
                           }
                        </div>
                     </div>
                     <div class="comment-input-box">
                        <textarea
                           class="form-control log-input-field"
                           [(ngModel)]="vendorDisplayInfo.entryText"
                        >
                        </textarea>

                        <div class="bottom-buttons-row">
                           <div class="bottom-buttons-row-left">
                              <comment-files
                                 [uploadObject]="logUploadObj"
                                 [documents]="fileQue"
                                 [images]="pictureQue"
                                 icon="plus"
                                 label="AttachFile"
                              />
                           </div>

                           <lim-ui-primary-button (click)="addEntry()">
                              {{ lang().Submit }}
                           </lim-ui-primary-button>
                        </div>
                        <div class="entry-alert">
                           <lim-ui-alert
                              [title]="lang().Warning"
                              [hidden]="!errorMsg"
                              alertType="warning"
                           >
                              <span [limbleHtml]="errorMsg"></span>
                           </lim-ui-alert>

                           <lim-ui-alert
                              [hidden]="!successMsg"
                              [title]="lang().Success"
                              alertType="success"
                           >
                              <span [limbleHtml]="successMsg"></span>
                           </lim-ui-alert>
                        </div>
                     </div>
                  </div>
               }

               @if (logItems?.length && logItems?.length > itemsPerPage) {
                  <div class="pagination-container">
                     <lim-ui-pagination
                        [maxSize]="5"
                        [rotate]="true"
                        [(page)]="page"
                        [pageSize]="itemsPerPage"
                        [collectionSize]="logItems?.length"
                        (pageChange)="pageChanged()"
                     />
                     <lim-ui-dropdown-results-per-page
                        [selectedValue]="itemsPerPage"
                        [label]="lang().itemsPerPage"
                     >
                        <ng-container menu>
                           @for (num of ["10", "20", "50", "100"]; track num) {
                              <lim-ui-dropdown-text-item
                                 [displayString]="num"
                                 [outputString]="num"
                                 (itemSelectedEvent)="updateUIPref(num)"
                              />
                           }
                        </ng-container>
                     </lim-ui-dropdown-results-per-page>
                  </div>
               }
            </div>
         }
      </div>
   </lim-ui-panel>
}
