import { filter, map, pipe, tap, type OperatorFunction } from "rxjs";
import type { z } from "zod";

export function validateFilter<T>(schema: z.ZodSchema<T>): OperatorFunction<unknown, T> {
   return pipe(
      map((payload) => schema.safeParse(payload)),
      tap((result) => {
         if (result.success === false) {
            console.error(result.error);
         }
      }),
      filter((result) => result.success === true),
      map((result) => result.data),
   );
}
