<lim-ui-panel [collapsable]="true" [title]="lang().Invoices">
   @for (invoice of invoices(); track invoice.invoiceID) {
      <div class="lim-ui-list-item invoice-item">
         @if (invoice.invoiceFileName) {
            <span>
               <a
                  href="viewFile.php?f=upload-{{ customerID }}/invoices/{{ taskID() }}/{{
                     invoice.invoiceFileName | escape
                  }}"
                  target="_blank"
               >
                  {{ invoice.invoiceFileName }}
               </a>
               <div>
                  {{ lang().forATotalOf }}
                  <cost-viewer-component
                     [rate]="invoice.invoiceCost"
                     [locationID]="currentLocationID()"
                     [currencySymbol]="currencySymbol"
                  />
               </div>
            </span>
         }
         @if (!invoice.invoiceFileName) {
            <span>
               <div>
                  {{ lang().InvoiceForATotalOf }}
                  <cost-viewer-component
                     [rate]="invoice.invoiceCost"
                     [locationID]="currentLocationID()"
                     [currencySymbol]="currencySymbol"
                  />
               </div>
            </span>
         }

         <span> - </span>

         <span tooltip data-title="{{ lang().InvoiceUniqueIDTooltip }}">
            #{{ invoice.invoiceID }}
         </span>

         @if ((invoice.invoiceDescription?.length ?? 0) > 0) {
            -

            <span [limbleHtml]="invoice.invoiceDescription"></span>
         }
         @let poItemID = invoice.poItemID;
         @if (poItemID && poItemID > 0 && currentUser.workOrderUser !== 1) {
            @if (managePO.getPurchaseOrderItem(poItemID)?.poID; as poID) {
               <span>
                  -
                  @if (managePO.getPurchaseOrder(poID); as purchaseOrder) {
                     <span class="po-number" (click)="popPoComponent(poID)"
                        >{{ lang().PO }} - {{ purchaseOrder.poNumber }}</span
                     >
                  }
                  @if (managePO.getPurchaseOrderCurrentState(poID); as currentState) {
                     ({{ currentState?.name }})
                  }
               </span>
            }
         }
      </div>
   }
</lim-ui-panel>
