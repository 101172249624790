import {
   Component,
   computed,
   inject,
   input,
   output,
   signal,
   type OnInit,
   type Signal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   IconComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalService,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import type { PartEntity } from "src/app/parts/components/shared/parts-api-service/parts-api.models";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ParamsService } from "src/app/shared/services/params.service";
import { AssociationType } from "src/app/shared/types/association.types";
import type { CalculatedPartInfo } from "src/app/shared/types/general.types";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "asset-associated-part",
   imports: [
      IconComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      PartUnitOfMeasurePipe,
      MinimalIconButtonComponent,
      FormsModule,
   ],
   templateUrl: "./asset-associated-part.component.html",
   styleUrl: "./asset-associated-part.component.scss",
})
export class AssetAssociatedPartComponent implements OnInit {
   public readonly associationDeleted = output<PartEntity>();
   public readonly manageParts = inject(ManageParts);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected readonly manageLang = inject(ManageLang);
   private readonly manageLocation = inject(ManageLocation);
   private readonly credService = inject(CredService);
   private readonly manageAssociations = inject(ManageAssociations);
   protected readonly alertService = inject(AlertService);
   public readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);

   protected readonly locationsIndex = this.manageLocation.getLocationsIndex();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly partAndRelation = input.required<PartEntity>();
   public readonly asset = input.required<Asset>();
   public readonly associatedPartUpdated = output();

   protected readonly associatedPartQty = signal(1); // The legacy component defaulted automatic associations to 1. For whatever reason. 🤷🏻‍♂️

   protected readonly partLocation = computed(() => {
      return this.partAndRelation().partLocation ?? "";
   });
   protected readonly canEditPartsCred = computed(() => {
      const asset = this.asset();
      if (!asset) {
         return false;
      }
      return this.credService.isAuthorized(
         asset.locationID,
         this.credService.Permissions.ChangePartAssetAssociations,
      );
   });

   protected readonly calculatedPartInfo: Signal<CalculatedPartInfo | undefined> =
      computed(() => {
         return this.manageParts.getSingleCalculatedPartInfo(
            this.partAndRelation().partID,
         );
      });

   protected partHasManualRelation(part: PartEntity): boolean {
      if (!part.assetPartQuantities) {
         return false;
      }
      return (
         part.assetPartQuantities.filter((assetPart) => {
            return (
               assetPart.assetID === this.asset().assetID &&
               assetPart.association_type === AssociationType.Manual
            );
         }).length > 0
      );
   }

   public ngOnInit(): void {
      const partAndRelationRelatedToThisAsset =
         this.partAndRelation().assetPartQuantities.find((assetPartQuantity) => {
            return assetPartQuantity.assetID === this.asset().assetID;
         });

      this.associatedPartQty.set(partAndRelationRelatedToThisAsset?.assetPartQty ?? 0);
   }

   public async updateAssetPartQty() {
      const result = await this.manageAssociations.updateAssetPartQty(
         this.asset().assetID,
         this.partAndRelation().partID,
         this.associatedPartQty(),
      );

      if (result?.data.success) {
         this.alertService.addAlert(this.lang().Success, "success", 2000);
      } else {
         this.associatedPartUpdated.emit();
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   protected deletePartRelation(part: PartEntity) {
      const asset = this.asset();
      if (!asset) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            asset.locationID,
            this.credService.Permissions.ChangePartAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred78Fail, "danger", 10000);
         return;
      }

      this.manageAssociations
         .deletePartAssetRelation(asset.assetID, part.partID)
         .then((answer) => {
            if (answer?.data.success === true) {
               this.associationDeleted.emit(part);

               this.alertService.addAlert(
                  this.lang().AssociationSuccessfullyRemoved,
                  "success",
                  2000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   }

   protected popPart() {
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: this.partAndRelation().partID,
            locationID: this.partAndRelation().locationID,
            data: {
               restrict: false,
            },
         },
      };

      instance.result.then(() => {
         this.associatedPartUpdated.emit();
      });
   }
}
