import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { ReplaySubject } from "rxjs";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { ManageUser } from "src/app/users/services//manageUser";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ManagePriority {
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);

   private readonly priorityList: any = {};
   private readonly axios = axios;

   public prioritiesLoadedSubject = new ReplaySubject<boolean>();
   public prioritiesLoaded$ = this.prioritiesLoadedSubject.asObservable();

   public constructor() {
      this.priorityList.arr = [];
      this.priorityList.index = {};

      this.createObservables();
   }

   createObservables = () => {
      this.manageObservables.createObservable("priorityList", this.priorityList.arr);
   };

   getData = async () => {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/priorities`);

      logApiPerformance(
         "priorities",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.sortData(response.data);
      this.prioritiesLoadedSubject.next(true);
   };

   sortData = (data) => {
      const indexedByPriorityLevel = data.sort((first, second) => {
         return first.priorityLevel - second.priorityLevel;
      });
      this.priorityList.arr = indexedByPriorityLevel;
      this.updatePriorityIndex();

      this.manageObservables.updateObservable("priorityList", 1);
   };

   updatePriorityIndex = () => {
      this.priorityList.index = {};
      for (const priority of this.priorityList.arr) {
         priority.priorityLevel = Number(priority.priorityLevel);
         priority.isDefault = Number(priority.isDefault);
         this.priorityList.index[priority.priorityID] = priority;
      }
   };

   clearData = () => {
      this.priorityList.arr = [];
      this.priorityList.index = {};
      this.manageObservables.updateObservable("priorityList", 1);
   };

   getPriorityList = () => {
      return this.priorityList.arr;
   };

   getPriorityListIndex = () => {
      return this.priorityList.index;
   };

   getDefaultPriority = () => {
      return (
         this.priorityList.arr.filter((priority) => priority.isDefault)[0] ||
         this.priorityList.arr[0]
      );
   };

   getPriorityByLevel = (level) => {
      return this.priorityList.arr.filter(
         (priority) => priority.priorityLevel == level,
      )[0];
   };

   createPriority = async () => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePriority.php",
         params: {
            action: "createPriority",
         },
         data: {},
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.priorityList.arr.push(answer.data.priority);
            const newIndex = this.priorityList.arr.length - 1;
            this.priorityList.index[answer.data.priority.priorityID] =
               this.priorityList.arr[newIndex];
         }
      });
      this.manageObservables.updateObservable("priorityList", 1);
      return post;
   };

   updateSinglePriority = async (priority) => {
      const priorityJSON = JSON.stringify(priority);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePriority.php",
         params: {
            action: "updateSinglePriority",
         },
         data: { priorityJSON: priorityJSON },
      });
      return post;
   };

   public async updatePriorities(priorities): Promise<AxiosResponse> {
      const priorityJSON = JSON.stringify(priorities);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePriority.php",
         params: {
            action: "updatePriorities",
         },
         data: { priorityJSON: priorityJSON },
      });

      //if bulk update succeeded, update locally stored priority data
      if (post.data.success) {
         this.priorityList.arr = priorities;
      }

      return post;
   }

   public async deletePriority(priorityID, priorityLevel): Promise<AxiosResponse> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePriority.php",
         params: {
            action: "deletePriority",
         },
         data: { priorityID: priorityID, priorityLevel: priorityLevel },
      });

      if (post.data.success) {
         //locally remove the deleted priority
         const indexToRemove = this.priorityList.arr.findIndex(
            (item) => item.priorityID === priorityID,
         );
         this.priorityList.arr.splice(indexToRemove, 1);
         this.updatePriorityIndex();

         //reorder all priorities that have a priorityLevel greater than the deleted priority
         this.priorityList.arr.forEach((item) => {
            if (item.priorityLevel > priorityLevel) {
               item.priorityLevel -= 1;
            }
         });
      }
      this.manageObservables.updateObservable("priorityList", 1);

      return post;
   }
}
