import { computed, inject, Injectable } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import moment from "moment";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { DashboardCurrencyService } from "src/app/dashboards/dashboard-currency.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { TaskEntityPart } from "src/app/parts/types/part.types";
import { InsightsService } from "src/app/shared/services/insights/insights.service";
import type {
   TaskCalculatedDisplayInfo,
   TaskCompletedInfo,
   TaskDisplayData,
   TaskModalCredentials,
} from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import type {
   TaskAsset,
   TaskComment,
   TaskEntity,
} from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TaskEntityType } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import type { TasksSchedulesCombinedEntity } from "src/app/tasks/components/shared/services/tasks-schedules-combined-api/tasks-schedules-combined.models";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskTypeService } from "src/app/tasks/services/task-type.service";
import { CredService } from "src/app/users/services/creds/cred.service";

@Injectable({
   providedIn: "root",
})
export class TaskViewModelMapperService {
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly manageStatus = inject(ManageStatus);
   public readonly manageAsset = inject(ManageAsset);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly dashboardCurrencyService = inject(DashboardCurrencyService);
   private readonly manageLang = inject(ManageLang);
   private readonly credService = inject(CredService);
   private readonly insightsService = inject(InsightsService);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public getAssignedTo(task: TaskEntity | TasksSchedulesCombinedEntity): string {
      return this.manageTask.getTaskAssignmentInfo(task).displayName;
   }

   public getChecklistDueDateDisplay(task: TaskEntity): string {
      return this.manageTask.getTaskDueDateDisplay(task);
   }

   public getRequesterInformation(task: {
      checklistEmailCN?: string;
      requestName?: string;
      requestPhone?: string;
   }): string {
      let requester: string;

      requester = "";
      if (task.checklistEmailCN) {
         requester += `${task.checklistEmailCN}, `;
      }
      if (task.requestName) {
         requester += `${task.requestName}, `;
      }
      if (task.requestPhone) {
         requester += `${task.requestPhone}, `;
      }

      if (requester.length > 0) {
         requester = requester.slice(0, -2);
      }
      return requester;
   }

   public getPriorityInfo(task: TaskEntity): {
      priorityLevel?: number;
      priorityName: string;
   } {
      return this.manageTask.getPriorityInfo(task.priorityID);
   }

   public getStatusInfo(
      task: TaskEntity,
   ): { statusName: string; statusAbbr: string } | undefined {
      return this.manageTask.getStatusInfo(task.statusID);
   }

   public getLocationName(task: TaskEntity): string {
      return String(
         task.locationName ??
            this.manageLocation.getLocation(task.locationID)?.locationName ??
            "",
      );
   }

   public getChecklistTemplateOldString(task: TaskEntity): string {
      // NOTE: This can be moved to a pipe or a component since it is a view concern
      if (task.checklistTemplateOld === 1) {
         return this.lang().PM;
      } else if (
         task.checklistTemplateOld === 2 &&
         (task.checklistBatchID === 300112 || task.checklistBatchID === 10000)
      ) {
         return this.lang().WorkRequest;
      } else if (task.checklistTemplateOld === 2) {
         return this.lang().UnplannedWO;
      } else if (task.checklistTemplateOld === 4) {
         return this.lang().PlannedWO;
      } else if (task.checklistTemplateOld === 5) {
         return this.lang().CycleCount;
      }
      return "";
   }

   public getIsCompleted(task: TaskEntity): boolean {
      const completedDate = task.checklistCompletedDate
         ? new Date(task.checklistCompletedDate * 1000)
         : undefined;
      return completedDate !== undefined;
   }

   /**
    * This is a placeholder function to determine if a task is in a pending state.
    * It will eventually take a statusID as an input and check the new statusID
    * TODO TASK-1103 - remove the placeholder logic and implement the correct logic.
    * @returns boolean
    */
   public getIsPendingWorkRequest(): boolean {
      // return task.statusID === newStatusID; // We would do something like this if we were using the new statusID
      return false; // Set this to true to test the pending state
   }

   /**
    * This is a placeholder function to determine if a task is in a declined state.
    * It will eventually take a statusID as an input and check the new statusID
    * TODO TASK-1103 - remove the placeholder logic and implement the correct logic.
    * @returns boolean
    */
   public getIsDeclinedWorkRequest(): boolean {
      // return task.statusID === newStatusID; // We would do something like this if we were using the new statusID
      return false; // Set this to true  to test the pending state
   }

   public getShowLocation(task: TaskEntity): boolean {
      return this.manageLocation.getSelectedLocation() === task.locationID;
   }

   public getDueDate(task: TaskEntity): Date | undefined {
      return task.checklistDueDate ? new Date(task.checklistDueDate * 1000) : undefined;
   }

   public getCompletedDate(task: TaskEntity): Date | undefined {
      return task.checklistCompletedDate
         ? new Date(task.checklistCompletedDate * 1000)
         : undefined;
   }

   public getStartDate(task: TaskEntity): Date | undefined {
      return task.checklistStartDate
         ? new Date(task.checklistStartDate * 1000)
         : undefined;
   }

   public getInstructionsUpdatedAt(task: TaskEntity): Date {
      return task.instructionsUpdatedAt
         ? new Date(task.instructionsUpdatedAt)
         : new Date();
   }

   public getInstructionsUpdatedAtUserID(task: TaskEntity): number {
      return task.instructionsUpdatedAtUserID ?? 0;
   }

   public getChecklistPromptTime(task: TaskEntity): number {
      return task.checklistPromptTime ?? 0;
   }

   public getChecklistPromptTimeHours(task: TaskEntity): number {
      return Math.floor((task.checklistPromptTime ?? 0) / 3600);
   }

   public getChecklistPromptTimeMinutes(task: TaskEntity): number {
      return Math.floor(((task.checklistPromptTime ?? 0) / 60) % 60);
   }

   public getBillableHours(task: TaskEntity): number {
      return Number(Math.floor((task.billableTime ?? 0) / 3600));
   }

   public getBillableMinutes(task: TaskEntity): number {
      return Number(Math.floor(((task.billableTime ?? 0) / 60) % 60));
   }

   public getAssets(task: TaskEntity): TaskAsset[] | undefined {
      return task.assets;
   }

   public getAssetName(task: TaskEntity | TasksSchedulesCombinedEntity): string {
      if (task.assetID === undefined) {
         return "";
      }
      return this.manageAsset.getAsset(task.assetID)?.assetName ?? "";
   }

   /**
    * This sets the info needed by the Completed By and Completed On Cells
    */
   public getCompletedInfo(task: TaskEntity): TaskCompletedInfo | undefined {
      const isCompleted = this.getIsCompleted(task);
      const completedDate = this.getCompletedDate(task);
      const dueDate = this.getDueDate(task);

      if (isCompleted && completedDate !== undefined && dueDate !== undefined) {
         let by = "";
         if (task.completedBy) {
            by = task.completedBy
               .map((user) => {
                  if (
                     Object.keys(user).includes("firstName") &&
                     Object.keys(user).includes("lastName")
                  ) {
                     const userType = user as { firstName: string; lastName: string };
                     return `${userType.firstName} ${userType.lastName}`;
                  } else if ("deleted" in user) {
                     return this.lang().DeletedUser;
                  }

                  return ``;
               })
               .join(", ");
         }

         const momentCompletedDate = moment(completedDate).local();
         const momentDueDate = moment(dueDate).local();
         const daysPastDueDate = momentCompletedDate.diff(momentDueDate, "days");

         //set the tooltip so it says completed after or before correctly
         const daysPastDueDateTooltip =
            daysPastDueDate >= 0
               ? `${this.lang().Completed} ${daysPastDueDate} ${this.lang().DaysAfterDueDate}`
               : `${this.lang().Completed} ${daysPastDueDate * -1} ${
                    this.lang().DaysBeforeDueDate
                 }`;

         return {
            by,
            daysPastDueDate,
            daysPastDueDateTooltip,
         };
      }

      return undefined;
   }

   public getDaysInfo(
      task: TaskEntity,
   ):
      | { days: number; daysMsg: string; daysStatus: string; exactDays: number }
      | undefined {
      return this.manageTask.getDaysForTask(task);
   }

   /**
    * This sets the info needed for displaying comment icon and number of unread comments on cells
    */
   public getCommentInfo(task: TaskEntity): {
      showCommentsHint: boolean;
      unreadComments: number;
   } {
      if (task.commentsDetails) {
         return {
            showCommentsHint: task.commentsDetails.count > 0,
            unreadComments: task.commentsDetails.unread,
         };
      }
      return {
         showCommentsHint: false,
         unreadComments: 0,
      };
   }

   public getTaskTypeIcon(task: TaskEntity | TasksSchedulesCombinedEntity): Aliases {
      return this.taskTypeService.getTaskTypeIcon(task);
   }

   public getCalculatedInfo(task: TaskEntity): TaskCalculatedDisplayInfo {
      return this.manageTask.getCalculatedTaskInfo(task);
   }

   public getStatusTimeInfo(task: TaskEntity) {
      const statusLogs = this.manageStatus.getArrayOfStatusLogsByChecklistID(
         task.checklistID,
      );

      let timeSpentInStatusOpen = 0;
      let timeSpentInStatusInProgress = 0;
      if (statusLogs === undefined) {
         return { timeSpentInStatusOpen, timeSpentInStatusInProgress };
      }
      for (const log of statusLogs) {
         // Status Open
         if (Number(log.statusID) === 0) {
            timeSpentInStatusOpen += Number(log.duration);
         }

         // Status In Progress
         if (Number(log.statusID) === 1) {
            timeSpentInStatusInProgress += Number(log.duration);
         }
      }

      return { timeSpentInStatusOpen, timeSpentInStatusInProgress };
   }

   public getChecklistPromptTimeTotal(task: TaskEntity): number {
      return this.getCalculatedInfo(task).checklistPromptTimeTotal;
   }

   public getCalculatedDays(task: TaskEntity):
      | {
           days: number;
           daysMsg: string;
           daysStatus: string;
           exactDays: number;
        }
      | undefined {
      const calculatedDays = this.manageTask.getDaysForTask(task);
      return calculatedDays ?? undefined;
   }

   // TODO(rob): Attach the task type in Flannel so that this is just a simple translation of
   // an enum to a language string.
   public getTypeLabel(task: TaskEntity): string {
      if (task.taskType === undefined) {
         return "";
      }

      //figure out what the type string should be... task, PM or WO
      //This is manual used so we don't have that many watches in the view
      let typeLabel = "";

      // eslint-disable-next-line default-case -- We want Typescript to do exhaustiveness checks.
      switch (task.taskType) {
         case TaskEntityType.PlannedMaintenances:
            typeLabel = this.lang().ThisIsAPM;
            break;
         case TaskEntityType.UnplannedWorkOrders:
            typeLabel = this.lang().ThisIsAUnplannedWO;
            break;
         case TaskEntityType.PlannedWorkOrders:
            typeLabel = this.lang().ThisIsAPlannedWO;
            break;
         case TaskEntityType.CycleCounts:
            typeLabel = this.lang().ThisIsACycleCountWO;
            break;
         case TaskEntityType.WorkRequests:
            typeLabel = this.lang().ThisIsAWorkRequest;
            break;
         case TaskEntityType.PartThresholds:
            typeLabel = this.lang().PartThreshold;
            break;
         case TaskEntityType.MaterialRequests:
            typeLabel = this.lang().MaterialsRequest;
            break;
      }

      if (!task.checklistCompletedDate && task.checklistTemplateOld !== 5) {
         // Add some extra text if the task isn't completed
         typeLabel = `${typeLabel}. ${this.lang().ClickForAFewOptions}`;
      }
      return typeLabel;
   }

   public getTaskTypeStr(task: TaskEntity): string {
      if (task.taskType === undefined) {
         return "";
      }
      // eslint-disable-next-line default-case -- We want Typescript to do exhaustiveness checks.
      switch (task.taskType) {
         case TaskEntityType.UnplannedWorkOrders:
            return this.lang().UnplannedWO;
         case TaskEntityType.PartThresholds:
            return this.lang().PartThreshold;
         case TaskEntityType.PlannedMaintenances:
            return this.lang().PM;
         case TaskEntityType.PlannedWorkOrders:
            return this.lang().PlannedWO;
         case TaskEntityType.WorkRequests:
            return this.lang().WorkRequest;
         case TaskEntityType.CycleCounts:
            return this.lang().CycleCount;
         case TaskEntityType.MaterialRequests:
            return this.lang().MaterialsRequest;
      }
      throw new Error(`Unexpected task type: ${task.taskType}`);
   }

   public getHasParts(task: TaskEntity): boolean {
      return !(task.partsDetails === undefined || task.partsDetails === "noPartsNeeded");
   }

   public getHasPartsInStock(task: TaskEntity): boolean {
      if (task.partsDetails === undefined || task.partsDetails === "noPartsNeeded") {
         return false;
      }
      return task.partsDetails.needsMet;
   }

   public getFinalColorStatus(task: TaskEntity): number | undefined {
      return task.completionColor;
   }

   public getParts(task: TaskEntity): Array<TaskEntityPart> | undefined {
      return task.parts;
   }

   public getComments(task: TaskEntity): Array<TaskComment> | undefined {
      return task.comments;
   }

   public getTaskCredentials(task: TaskEntity): TaskModalCredentials {
      let credToEditOpenTaskInstructions: boolean = false;
      if (task.checklistTemplate == 0) {
         if (task.checklistTemplateOld == 1) {
            credToEditOpenTaskInstructions = this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.EditAnOpenPMsInstructions,
            ); //viewing a PM so check cred for open Task instructions
         } else if (task.checklistBatchID == 10000 || task.checklistBatchID == 300112) {
            credToEditOpenTaskInstructions = this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.EditAnOpenWorkRequestsInstructions,
            ); //viewing WRs and PRs, etc.
         } else {
            credToEditOpenTaskInstructions = this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.EditAnOpenWOsInstructions,
            ); //viewing WOs, etc.
         }
      }

      const deleteOpenTaskCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.DeleteOpenTasks,
      );

      const deleteCompletedTaskCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.DeleteCompletedTasks,
      );

      const editCompletedTaskCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.EditCompletedTasks,
      );

      const startPOCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.StartAPO,
      );

      const tagTaskCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.TagATaskWithACustomTag,
      );

      const deleteCommentCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.RemoveTaskComments,
      );

      const requestPurchaseCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.RequestPurchases,
      );

      const recreateWorkRequestCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.RecreateWorkRequest,
      );

      const addCommentCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.AddTaskComments,
      );

      const shareTasksCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.PrintOrShareTasks,
      );

      const viewLaborCostsCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.ViewLaborCosts,
      );

      const viewInvoicesCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.ViewTaskInvoices,
      );
      const superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );

      const assetCheckInOut = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.AssetCheckInOut,
      );

      const hasLogTimeForOthersCredentials = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.LogTimeForOtherUsers,
      );

      const hasEditCommentCredential = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.EditComments,
      );

      const addPartsToOpenTasksCred = this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.AddPartsToOpenTasks,
      );

      return {
         credToEditOpenTaskInstructions: credToEditOpenTaskInstructions,
         deleteOpenTaskCred: deleteOpenTaskCred,
         deleteCompletedTaskCred: deleteCompletedTaskCred,
         editCompletedTaskCred: editCompletedTaskCred,
         startPOCred: startPOCred,
         tagTaskCred: tagTaskCred,
         deleteCommentCred: deleteCommentCred,
         requestPurchaseCred: requestPurchaseCred,
         recreateWorkRequestCred: recreateWorkRequestCred,
         addCommentCred: addCommentCred,
         shareTasksCred: shareTasksCred,
         viewLaborCostsCred: viewLaborCostsCred,
         viewInvoicesCred: viewInvoicesCred,
         superUser: superUser,
         assetCheckInOut: assetCheckInOut,
         hasLogTimeForOthersCredentials: hasLogTimeForOthersCredentials,
         hasEditCommentCredential: hasEditCommentCredential,
         addPartsToOpenTasksCred: addPartsToOpenTasksCred,
         allowEditTemplateInstructions: true,
      };
   }

   public getTaskDisplayData(task: TaskEntity): TaskDisplayData {
      const locations: Record<number, { locationID: number; locationName: string }> =
         this.manageLocation.getLocationsIndex();
      locations[0] = { locationID: 0, locationName: "Global" }; //Global location is necessary for global WR templates.
      return {
         ...this.manageTask.getCalculatedTaskInfo(task),
         dueDateDisplay: this.manageTask.getTaskDueDateDisplay(task),
         ...this.manageTask.getDaysForTask(task),
         ...this.manageTask.getPriorityInfo(task.priorityID ?? null),
         ...this.manageTask.getStatusInfo(task.statusID ?? null),
         taskTypeIcon: this.taskTypeService.getTaskTypeIcon(task),
         typeDisplay: this.manageTask.getTypeDisplay(task) ?? "",
         locationName: this.getLocationName(task),
         locationTaskImage: this.getLocationTaskImage(task),
         locationPhone: this.getLocationPhone(task),
         locationAddress: this.getLocationAddress(task),
         locationAddress2: this.getLocationAddress2(task),
         checklistPromptTime: this.getChecklistPromptTime(task),
         checklistPromptTimeHours: this.getChecklistPromptTimeHours(task),
         checklistPromptTimeMinutes: this.getChecklistPromptTimeMinutes(task),
         billableHours: this.getBillableHours(task),
         billableMinutes: this.getBillableMinutes(task),
         ...this.manageTask.getTaskAssignmentInfo(task),
         //the following two calls are cast as Task because a template should never be in a 'completed'
         //state or have comments on it
         ...this.manageTask.getCompletedTaskCalculatedInfo(task),
         //TODO (TASK-827): Create new JIT note data method
         noteData: this.manageTask.buildNoteDataMapForSingleTaskLegacy(task),
      };
   }

   public getParsedSavedAssetInfo(task: TaskEntity): string[] {
      const assetInfoFromCompletion = task.assetInfoFromCompletion;
      if (!assetInfoFromCompletion) {
         return [];
      }
      try {
         return JSON.parse(assetInfoFromCompletion);
      } catch {
         console.error("Failed to parse assetInfoFromCompletion");
         return [];
      }
   }

   public getDowntimeEfficiencyRate(): number | undefined {
      const efficiencyRates = this.insightsService.getEfficiencyRates();
      if (efficiencyRates().downtimeEfficiencyRate === undefined) {
         return undefined;
      }
      return efficiencyRates().downtimeEfficiencyRate;
   }

   public getLaborCostEfficiencyRate(): number | undefined {
      const efficiencyRates = this.insightsService.getEfficiencyRates();
      if (efficiencyRates().laborEfficiencyRate === undefined) {
         return undefined;
      }
      return efficiencyRates().laborEfficiencyRate;
   }

   public getPartCostEfficiencyRate(): number | undefined {
      const efficiencyRates = this.insightsService.getEfficiencyRates();
      if (efficiencyRates().partSpendEfficiencyRate === undefined) {
         return undefined;
      }
      return efficiencyRates().partSpendEfficiencyRate;
   }

   public getAverageDowntimeCost(): number | undefined {
      const efficiencyRates = this.insightsService.getEfficiencyRates();
      if (efficiencyRates().averageDowntimeCost === undefined) {
         return undefined;
      }
      return efficiencyRates().averageDowntimeCost;
   }

   public getDowntimeEfficiencySavings(task: TaskEntity): number | undefined {
      const efficiencyRates = this.insightsService.getEfficiencyRates();
      if (
         efficiencyRates().downtimeEfficiencyRate === undefined ||
         !task.checklistDowntime
      ) {
         return undefined;
      }
      return this.insightsService.calcDowntimeSavings(task.checklistDowntime / 60 / 60);
   }

   public getLaborCostEfficiencySavings(task: TaskEntity): number | undefined {
      const efficiencyRates = this.insightsService.getEfficiencyRates();
      if (efficiencyRates().laborEfficiencyRate === undefined || !task.laborCost) {
         return undefined;
      }
      return this.insightsService.calcLaborSavings(task.laborCost);
   }

   public getPartCostEfficiencySavings(task: TaskEntity): number | undefined {
      const efficiencyRates = this.insightsService.getEfficiencyRates();
      if (efficiencyRates().partSpendEfficiencyRate === undefined || !task.partsCost) {
         return undefined;
      }
      return this.insightsService.calcPartsSpendSavings(task.partsCost);
   }

   public getLocationTaskImage(task: TaskEntity): string {
      return String(
         this.manageLocation.getLocation(task.locationID)?.locationTaskImage ?? "",
      );
   }

   public getLocationPhone(task: TaskEntity): string {
      return String(
         this.manageLocation.getLocation(task.locationID)?.locationPhone ?? "",
      );
   }

   public getLocationAddress(task: TaskEntity): string {
      return String(
         this.manageLocation.getLocation(task.locationID)?.locationAddress ?? "",
      );
   }

   public getLocationAddress2(task: TaskEntity): string {
      return String(
         this.manageLocation.getLocation(task.locationID)?.locationAddress2 ?? "",
      );
   }

   public getCurrencyCode(task: TaskEntity): string {
      return this.dashboardCurrencyService.getTaskCurrencyCode(task);
   }
}
