import { inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { LiteEnablementService } from "src/app/lite/lite-enablement.service";
import type { BeamerConfig } from "src/app/shared/external-scripts/beamer/beamer-config";
import { getEnvString } from "src/app/shared/external-scripts/util/env-string";
import { loadJs } from "src/app/shared/external-scripts/util/load-js";
import { UserTypeService } from "src/app/shared/external-scripts/util/user-type.service";
import { ManageUser } from "src/app/users/services//manageUser";

@Injectable({ providedIn: "root" })
export class BeamerService {
   private readonly manageUser = inject(ManageUser);
   private readonly userType = inject(UserTypeService);
   private readonly isLiteEnabled = inject(LiteEnablementService).liteEnabled;

   public async load(): Promise<void> {
      const currentUser = await firstValueFrom(this.manageUser.currentUserInitialized$);
      const userInfo = currentUser.userInfo;
      const config: BeamerConfig = {
         product_id: "pRERhViQ25690",
         selector: "beamer-update",
         button: false,
         bounce: false,
         display_position: "top-right",
         user_firstname: userInfo.fName,
         user_lastname: userInfo.lName,
         user_email: userInfo.userEmail,
         user_id: userInfo.userID,
         customer_id: userInfo.customerID,
         plan_name: userInfo.customerPlan,
         company_name: userInfo.customerName,
         customer_subscription_status: userInfo.customerStatus,
         userType: this.userType.get(),
         env: getEnvString(),
         filter: this.buildFilter(),
      };
      (window as any).beamer_config = config;
      return loadJs("https://app.getbeamer.com/js/beamer-embed.js");
   }

   private buildFilter(): string {
      const filter: Array<string> = [];
      if (this.isLiteEnabled()) {
         filter.push("limble-lite");
      }
      return filter.join(";");
   }
}
