<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      [title]="lang().CustomizePriorities"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="modal-info">
            <div class="info-group">
               @if (message) {
                  <div>
                     <span [limbleHtml]="message"></span>
                  </div>
               }
            </div>
         </div>

         <limble-tree-root
            [data]="priorities"
            [options]="treeOptions"
            (treeDrop)="onMoveNode($event)"
            #tree
         />
         <div class="bottom-buttons">
            <lim-ui-secondary-button icon="plus" (click)="addPriority()">{{
               lang().AddAPriority
            }}</lim-ui-secondary-button>
         </div>
      </lim-ui-info-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
