import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { DEFAULT_CURRENCY } from "src/app/purchasing/currency/utils/default-currency-code";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "change-fields-asset-coo-graph",
   templateUrl: "./changeFieldsAssetCOOGraph.modal.component.html",
   styleUrls: ["./changeFieldsAssetCOOGraph.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      NgClass,
      IconComponent,
      BasicModalFooterComponent,
      CurrencySymbolPipe,
   ],
})
export class ChangeFieldsAssetCOOGraph implements OnInit {
   public resolve;
   public modalInstance;
   public selectedField;
   public selectedType;
   public asset: Asset | undefined;
   public errorMsg;
   public message;
   public title;
   protected currencyCode: string = DEFAULT_CURRENCY;
   public fieldValues: Lookup<"valueID", AssetFieldValue>;
   public assetFields: Lookup<"fieldID", AssetField>;

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.fieldValues = new Lookup("valueID");
      this.assetFields = new Lookup("fieldID");
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.selectedField = 0;
      this.selectedType = 0;
      const assetID = this.resolve.assetID;
      this.asset = this.manageAsset.getAsset(assetID);
      this.errorMsg = "";
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.currencyCode = this.resolve.currencyCode;

      assert(this.asset);
      const allFieldValues: Array<AssetFieldValue> | undefined =
         this.asset.assetValueIDs.map((valueID) => {
            const value = this.manageAsset.getFieldValue(valueID);
            assert(value);
            return value;
         });
      assert(allFieldValues);
      for (const fieldValue of allFieldValues) {
         const field = this.manageAsset.getField(fieldValue.fieldID);
         assert(field);
         if (field.fieldTypeID == 5 || field.fieldTypeID == 6) {
            this.fieldValues.set(fieldValue.valueID, fieldValue);
            this.assetFields.set(field.fieldID, field);
         }
      }
   }

   close = () => {
      this.modalInstance.close(0);
   };

   selectField = (field) => {
      if (field?.reportCostOfOwnership == 1) {
         field.reportCostOfOwnership = 0;
      } else {
         field.reportCostOfOwnership = 1;
      }
      this.manageAsset.changeReportCostOfOwnership(this.asset, field);
   };

   selectParts = () => {
      assert(this.asset);
      if (this.asset.assetTrackCOOParts == 1) {
         this.asset.assetTrackCOOParts = 0;
      } else {
         this.asset.assetTrackCOOParts = 1;
      }
      this.manageAsset.changeAssetTrackCOOParts(this.asset);
   };

   selectLabor = () => {
      assert(this.asset);
      if (this.asset.assetTrackCOOLabor == 1) {
         this.asset.assetTrackCOOLabor = 0;
      } else {
         this.asset.assetTrackCOOLabor = 1;
      }
      this.manageAsset.changeAssetTrackCOOLabor(this.asset);
   };

   submit = () => {
      this.modalInstance.close(1);
   };
}
