<div class="part-wrapper">
   <lim-ui-panel
      id="vendorPartsPrint{{ vendor?.vendorID }}"
      [noSidePadding]="true"
      [useCustomTitle]="true"
      [showHeaderDivider]="true"
   >
      <span class="vendor-parts-panel-header" panelCustomTitle>
         <lim-ui-icon icon="gears" />
         {{ i18n().t("AssociatedParts") }}
      </span>
      <div class="vendor-parts-table">
         <!-- The Header -->
         <div class="table-header">
            <div class="wrapper">
               <span class="col-md-4">{{ i18n().t("ManuallyAssociatedWith") }}...</span>
               <span class="col-md-2"
                  >{{ i18n().t("PartNumber") }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     [limUiTooltip]="i18n().t('PartNumberForVendorToolTip')"
               /></span>
               <span class="col-md-2"
                  >{{ i18n().t("PartPrice") }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     [limUiTooltip]="i18n().t('PartPriceForVendorToolTip')"
               /></span>
               @if (isPartLeadTimeEnabled()) {
                  <span class="col-md-2"
                     >{{ i18n().t("AverageLeadTimeHeader") }}
                     <lim-ui-icon
                        icon="circleQuestionRegular"
                        [limUiTooltip]="i18n().t('AverageLeadTimeTooltip')"
                     />
                  </span>
               }
            </div>
         </div>

         <!-- The Rows-->
         <div class="list-items">
            @for (partAssociation of manualParts; track partAssociation) {
               <div class="lim-ui-list-item associated-parts">
                  @if (
                     partAssociation.partID &&
                        partDisplayInfo.get(partAssociation.partID);
                     as partDisplayInfo
                  ) {
                     <div class="name-location-container col-md-4">
                        <lim-ui-icon icon="gears" />
                        <span
                           class="part-name"
                           (click)="popPart(partAssociation)"
                           [limbleHtml]="partAssociation.partName"
                        ></span>
                        @if (
                           partAssociation.partNumber &&
                           partAssociation.partNumber.length > 0
                        ) {
                           <span> <span - {{ partAssociation.partNumber }}></span></span>
                        }
                        <span>
                           <span> - </span>
                           <lim-ui-icon icon="houseChimney" class="house" />
                           {{ partDisplayInfo.locationName }}
                        </span>
                     </div>
                  }
                  <span class="part-number col-md-2">
                     <span class="lim-ui-show-on-mobile">{{
                        i18n().t("PartNumber")
                     }}</span>

                     <input
                        type="text"
                        [disabled]="!credChangePartNumber()"
                        [(ngModel)]="partAssociation.partNumber"
                        (blur)="updateNumberToVendorAssociation(partAssociation, vendor)"
                     />
                  </span>

                  <span class="part-price col-md-2">
                     <span class="lim-ui-show-on-mobile">{{
                        i18n().t("PartPrice")
                     }}</span>

                     <div class="arrow-adjustable-input">
                        @if (isMultiCurrencyEnabled()) {
                           <!-- @let currencyCode = partAssociation.currencyCode | currencySymbol ?? currencySymbol; -->
                           <lim-ui-input-with-prefix
                              [disabled]="!credChangePartPrice()"
                              [prefixText]="
                                 partAssociation.currencyCode
                                    ? (partAssociation.currencyCode | currencySymbol)
                                    : currencySymbol
                              "
                              [type]="'number'"
                              [value]="
                                 partAssociation.partPrice
                                    ? partAssociation.partPrice
                                    : null
                              "
                              (valueChange)="updatePartPrice($event, partAssociation)"
                              [min]="0"
                              (inputBlur)="
                                 updatePriceToVendorAssociation(partAssociation, vendor)
                              "
                              class="number-field"
                           />
                        } @else {
                           <lim-ui-input-with-prefix
                              [disabled]="!credChangePartPrice()"
                              [prefixText]="currencySymbol"
                              [type]="'number'"
                              [value]="
                                 partAssociation.partPrice
                                    ? partAssociation.partPrice
                                    : null
                              "
                              (valueChange)="updatePartPrice($event, partAssociation)"
                              [min]="0"
                              (inputBlur)="
                                 updatePriceToVendorAssociation(partAssociation, vendor)
                              "
                              class="number-field"
                           />
                        }
                     </div>
                  </span>
                  @if (isPartLeadTimeEnabled()) {
                     <span class="part-lead-time col-md-2">
                        @if (partAssociation?.partVendorAvgLeadTime) {
                           <span class="lim-ui-show-on-mobile">{{
                              i18n().t("AverageLeadTimeHeader")
                           }}</span>
                           <span>
                              {{ partAssociation?.partVendorAvgLeadTime }}
                           </span>
                        }
                     </span>
                  }
                  <span
                     (click)="deletePartVendorRelation(partAssociation)"
                     placement="left"
                     limUiTooltip="{{ i18n().t('RemoveTheManualAssociation') }}"
                  >
                  </span>
               </div>
            }
            <lim-ui-primary-button
               dataLogLabel="manageParts-initiateAnAssociation"
               (click)="associatePartsToVendor()"
               >{{ i18n().t("AssociateParts") }}</lim-ui-primary-button
            >
         </div>

         <div [hidden]="!(manualParts.length === 0)">
            <span [limbleHtml]="zeroManualPartsMsg"></span> <br />
         </div>
      </div>
   </lim-ui-panel>
   <lim-ui-panel [useCustomTitle]="true">
      <div class="previously-purchased-parts-title" panelCustomTitle>
         <lim-ui-icon iconSize="small" icon="squareCheck" />
         <span>{{ i18n().t("PreviouslyPurchasedParts") }}</span>
      </div>
      <div class="listTable">
         @for (part of usedParts; track part) {
            <div class="lim-ui-list-item">
               @if (part.partID) {
                  @if (partDisplayInfo.get(part.partID); as partDisplayInfo) {
                     <div class="prev-purchased-part-info-wrapper">
                        <div
                           (click)="flipPartShowDetails(part.partID)"
                           class="prev-purchased-header"
                        >
                           <div class="start-block">
                              <lim-ui-icon icon="gears" />

                              <span
                                 >{{ part.partName }}
                                 @if (part.partNumber) {
                                    <span>- {{ part.partNumber }}</span>
                                 }
                              </span>
                              <lim-ui-icon icon="houseChimney" class="house" />
                              <span>{{ partDisplayInfo.locationName }}</span>
                           </div>
                           <div class="end-block">
                              <lim-ui-icon
                                 [icon]="
                                    partDisplayInfo.showDetails
                                       ? 'chevronUp'
                                       : 'chevronDown'
                                 "
                              />
                           </div>
                        </div>
                        @if (partDisplayInfo.showDetails) {
                           @for (po of part.POs; track po) {
                              <div class="po-wrapper">
                                 <div class="powerpoint-wrapper">
                                    <lim-ui-icon
                                       icon="filePowerpoint"
                                       class="powerpoint"
                                    />
                                 </div>
                                 <a class="table-link" (click)="popPoComponent(po.poID)"
                                    >{{ i18n().t("PO") }} - {{ po.poNumber }}</a
                                 >
                                 <span> - </span>
                                 <span>
                                    {{ po.qty }} {{ i18n().t("Available") }} -
                                    @if (po.state === 0) {
                                       <span>{{ i18n().t("POStageSetup") }}</span>
                                    }
                                    @if (po.state === 97) {
                                       <span>{{
                                          i18n().t("POStageReadyToReceive")
                                       }}</span>
                                    }
                                    @if (po.state === 98) {
                                       <span>{{
                                          i18n().t("POStagePartiallyReceived")
                                       }}</span>
                                    }
                                    @if (po.state === 99) {
                                       <span>{{ i18n().t("POStageFullyReceived") }}</span>
                                    }
                                    @if (po.state === 100) {
                                       <span>{{ i18n().t("POStageClosed") }}</span>
                                    }
                                    @if (po.state === 101) {
                                       <span
                                          >{{ i18n().t("POStageClosed") }} -
                                          {{ i18n().t("PORequestDisapproved") }}</span
                                       >
                                    }
                                 </span>
                              </div>
                           }
                        }
                     </div>
                  }
               }
            </div>
         }
      </div>

      <div [hidden]="!(usedParts.length === 0)">
         <span [limbleHtml]="zeroUsedPartsMsg"></span> <br />
      </div>
   </lim-ui-panel>
</div>
