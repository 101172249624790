import type { OnDestroy, OnInit, Signal } from "@angular/core";
import { inject, Component, computed, input, output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   IconComponent,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import Decimal from "decimal.js";
import { filter } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitLabelComponent } from "src/app/parts/unit-of-measure/components/unit-label/unit-label.component";
import { UnitOfMeasureAvailabilityService } from "src/app/parts/unit-of-measure/unit-of-measure-availability.service";
import type { PurchasableSnapshot } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.model";
import { PurchasableSnapshotService } from "src/app/purchasing/pos/purchasable-snapshot/purchasable-snapshot.service";
import { ReceivePurchaseOrderItemsTriggerService } from "src/app/purchasing/pos/receive-purchase-order-items/receive-purchase-order-items.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";

type AugmentedPurchaseOrderItem = PurchaseOrderItem & {
   tempNumberReceived: number;
   receivedQty: number;
   itemNumber: string;
   itemName: string;
   purchasableSnapshot: Signal<PurchasableSnapshot | null | undefined>;
};
@Component({
   selector: "receive-purchase-order-items",
   templateUrl: "./receive-purchase-order-items.component.html",
   styleUrls: ["./receive-purchase-order-items.component.scss"],
   imports: [
      LoadingAnimationComponent,
      FormsModule,
      TooltipDirective,
      IconComponent,
      LimbleHtmlDirective,
      BetterDecimalPipe,
      UnitLabelComponent,
   ],
})
export class ReceivePurchaseOrderItems implements OnInit, OnDestroy {
   public locationID;
   public disableBills;
   public errorMsg;
   public loadingBar;
   public isSubmitDisabled;
   public removedItem;
   public purchaseOrder: PurchaseOrder | undefined;
   public canViewLocationPOs;
   public poIsReady;
   public items: Array<AugmentedPurchaseOrderItem> = [];
   public processing;
   public openPRWatchVarSub;

   private readonly manageLocation = inject(ManageLocation);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageParts = inject(ManageParts);
   private readonly manageLang = inject(ManageLang);
   private readonly receivePoItemsTrigger = inject(
      ReceivePurchaseOrderItemsTriggerService,
   ).trigger;
   protected readonly isUnitOfMeasureEnabled = inject(UnitOfMeasureAvailabilityService)
      .isFeatureEnabled;
   private readonly purchasableSnapshotService = inject(PurchasableSnapshotService);

   public readonly poID = input.required<number>();
   public readonly navigate = output();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly title = computed(() => this.lang().WhichItemsDidYouReceive);

   public constructor() {
      this.errorMsg = "";
      this.loadingBar = false;
      this.isSubmitDisabled = false;
      this.removedItem = false;
      this.receivePoItemsOnSubmit();
   }

   buildData = () => {
      this.getPOItems();
      this.locationID = this.purchaseOrder?.locationID;
      this.disableBills = this.manageLocation.getLocation(this.locationID)?.disableBills;
   };

   public ngOnInit() {
      this.openPRWatchVarSub = this.manageObservables.setSubscription(
         "OpenBillWatchVar",
         () => {
            if (this.managePO.getOpenBillWatchVar() == 0) return;
            this.buildData();
         },
      );
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.openPRWatchVarSub);
   }

   getPOItems = () => {
      const locations = this.manageLocation.getLocations();
      const locationIDs: any = [];

      this.purchaseOrder = this.managePO.getPurchaseOrder(this.poID());

      for (const location of locations) {
         if (
            this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.ReceivePOItems,
            )
         ) {
            locationIDs.push(location.locationID);
         }
      }

      if (this.purchaseOrder) {
         // Make sure the PO is at a location they can view
         this.canViewLocationPOs =
            this.manageFilters.filterArrayByLocationIDs([this.purchaseOrder], locationIDs)
               .length > 0;
         const purchaseOrderAsLookup: Lookup<"poID", PurchaseOrder> = new Lookup("poID");
         purchaseOrderAsLookup.set(this.purchaseOrder.poID, this.purchaseOrder);
         // Make sure the PO is in a ready state
         this.poIsReady =
            this.manageFilters.filterPurchaseOrdersToOnesReadyToReceive(
               purchaseOrderAsLookup,
               this.managePO,
            ).size > 0;
      }

      if (this.purchaseOrder && this.canViewLocationPOs && this.poIsReady) {
         const itemsList: Array<AugmentedPurchaseOrderItem> = [];
         for (const itemID of this.purchaseOrder.poItemIDs) {
            const purchaseOrderItem = this.managePO.getPurchaseOrderItem(itemID);
            if (!purchaseOrderItem) {
               continue;
            }
            const receivedQty =
               this.managePO.getPurchaseOrderItemReceivedInfo(purchaseOrderItem.poItemID)
                  ?.receivedQty ?? 0;
            let tempNumberReceived = Number(purchaseOrderItem.qty) - receivedQty; //sets the temp received to the number they should be able to receive
            tempNumberReceived = Number(tempNumberReceived.toFixed(3));

            if (tempNumberReceived === 0) continue;

            const relatedInfo = this.managePO.getPurchaseOrderItemRelatedInfo(
               purchaseOrderItem.poItemID,
            );

            const purchasableSnapshot = computed(() =>
               this.purchasableSnapshotService.parseFromPurchaseOrderItem(
                  purchaseOrderItem,
               ),
            );

            itemsList.push({
               ...purchaseOrderItem,
               tempNumberReceived,
               receivedQty,
               itemName: relatedInfo?.itemName ?? "",
               itemNumber: relatedInfo?.itemNumber ?? "",
               purchasableSnapshot,
            });
         }
         this.items = orderBy(itemsList, ["-itemType", "itemName"]);
      } else if (this.canViewLocationPOs == false) {
         this.errorMsg = this.lang().YouCannotViewPOsForThisLocation;
      } else if (this.poIsReady == false) {
         this.errorMsg = this.lang().YouCanOnlyViewPOsThatHaveAStatusOfReadyToReceive;
      }
   };

   validateItemNumber = (item: PurchaseOrderItem & { tempNumberReceived: number }) => {
      const receivedQty =
         this.managePO.getPurchaseOrderItemReceivedInfo(item.poItemID)?.receivedQty ?? 0;
      if (!item.qty) {
         item.qty = 0;
      }
      // Have to use Decimal.js to avoid floating point errors
      const maxQuantityRemainingToReceive = new Decimal(item.qty)
         .minus(new Decimal(receivedQty))
         .toNumber();
      if (
         item.tempNumberReceived === undefined ||
         item.tempNumberReceived > maxQuantityRemainingToReceive
      ) {
         item.tempNumberReceived = maxQuantityRemainingToReceive;
      }
      if (item.tempNumberReceived < 0) {
         item.tempNumberReceived = 0;
      }
   };

   public async receivePoItems() {
      this.loadingBar = true;
      this.isSubmitDisabled = true;
      for (const item of this.items) {
         this.validateItemNumber(item);
      }
      const arr: any = [];

      for (const item of this.items) {
         if (item.tempNumberReceived > 0) {
            //they marked they received some so let's send it for processing
            const obj: any = {};
            obj.poItemID = item.poItemID;
            obj.qtyReceived = item.tempNumberReceived;
            arr.push(obj);
         }
      }

      if (arr.length === 0) {
         this.errorMsg = this.lang().WhoopsYouMustReceiveSomething;
         this.processing = false;
         this.loadingBar = false;
         this.isSubmitDisabled = false;
         this.manageParts.setPartsAreReceiving(false);
         return;
      }
      for (const item of this.items) {
         //set back to default before returning the number
         item.tempNumberReceived = 0;
      }

      this.processing = true;
      this.manageParts.setPartsAreReceiving(true);
      this.navigate.emit();
      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      const answer = await this.managePO.addBill(this.poID());
      if (answer.data.success) {
         const prID = answer.data.pr.prID;
         const response = await this.managePO.addBillTransactions(prID, arr);
         if (response.data.success == true) {
            if (response.data.failed.length > 0) {
               let str = this.lang().WhoopsTheFollowingItemsCouldntBeReceived;
               str += "<br /><br />";
               for (const key in response.data.failed) {
                  str = `${
                     str +
                     this.managePO.getPurchaseOrderItemRelatedInfo(
                        response.data.failed[key].poItemID,
                     )?.itemName
                  }<br />`;
               }
               str += "<br />";
               str += this.lang().RefreshingData;
               this.alertService.addAlert(str, "warning", 10000);
               this.managePO.getData();
            } else {
               //we want to automatically submit the PR for them as well.

               const response2 = await this.managePO.submitBill(prID);

               if (response2.data.success == true) {
                  this.alertService.addAlert(
                     this.lang().POItemsSuccessfullyReceived,
                     "success",
                     3000,
                  );

                  const partPoItems = this.items.filter((item) => item.itemType === 1);
                  for (const poItem of partPoItems) {
                     if (!poItem.partID) {
                        continue;
                     }
                     const part = this.manageParts.getPart(poItem.partID);
                     if (!part) {
                        continue;
                     }
                     this.manageParts.calculatePartData(part);
                  }
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               }
            }
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         }
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 1500);
      }
      this.processing = false;
      this.loadingBar = false;
      this.isSubmitDisabled = false;
      this.manageParts.setPartsAreReceiving(false);
      this.loadingBarService.remove();
   }

   private receivePoItemsOnSubmit(): void {
      this.receivePoItemsTrigger
         .pipe(
            takeUntilDestroyed(),
            filter((poID) => poID === this.poID()),
         )
         .subscribe(() => {
            this.receivePoItems();
         });
   }
}
