const TIMEOUT_MS = 10000;
export async function loadJs(url: string): Promise<void> {
   return new Promise((resolve, reject) => {
      const scriptTag = document.createElement("script");
      scriptTag.type = "text/javascript";
      scriptTag.async = true;
      scriptTag.addEventListener("load", () => {
         clearTimeout(timeout);
         resolve();
      });
      scriptTag.src = url;
      const timeout = setTimeout(() => {
         reject(new Error(`Loading script ${url} timed out after 10 seconds`));
      }, TIMEOUT_MS);
      document.body.appendChild(scriptTag);
   });
}
