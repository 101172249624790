import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit, Signal } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DraggableDirective, type TreeBranch } from "@limble/limble-tree";
import {
   CheckboxComponent,
   DropdownInlineTextComponent,
   DropdownTextItemComponent,
   IconButtonComponent,
   IconComponent,
   InputWithPrefixComponent,
   ModalService,
   PanelComponent,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { BudgetWorkflow } from "src/app/purchasing/types/budget-workflow.types";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ParamsService } from "src/app/shared/services/params.service";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "budget-tree-item",
   templateUrl: "./budgetTreeItem.element.component.html",
   styleUrls: ["./budgetTreeItem.element.component.scss"],
   imports: [
      PanelComponent,
      DraggableDirective,
      IconComponent,
      FormsModule,
      NgClass,
      ContenteditableDirective,
      IconButtonComponent,
      CheckboxComponent,
      DropdownInlineTextComponent,
      DropdownTextItemComponent,
      InputWithPrefixComponent,
      CurrencySymbolPipe,
   ],
})
export class BudgetTreeItem implements OnInit, OnDestroy {
   @Input() treeBranch?: TreeBranch<BudgetTreeItem>;

   @Input() public editBudgetCred;
   @Input() public editBudgetWorkflowStepEmail;
   @Input() public deleteStep;
   @Input() public addStep;
   @Input() public budgetID;
   @Input() public currencyCode!: Signal<string>;

   public step: BudgetWorkflow | undefined;
   public skipStepDisplay;
   protected conditionSourceDisplay: string;
   private conditionSource: "poTotal" | "everyPoItemsTotal" | undefined;
   public skipStepCondition;
   public skipStepEnabled;
   public skipStepTotal;
   public skipStepTotalOld;
   public strCurrencySymbol;
   public stepDisplayName: string = "";
   public featureCustomBudgets: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription;

   private readonly managePO = inject(ManagePO);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.conditionSourceDisplay = this.lang().PoTotal;

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureCustomBudgets = isFeatureEnabledMap.featureCustomBudgets;
         },
      );
   }

   public ngOnInit() {
      this.step = this.treeBranch?.meta().nodeData;
      this.getStepDisplayName();
      this.getSkipData();
      this.strCurrencySymbol = this.manageUser.getCurrentUser().currency.symbol;
   }

   public ngOnDestroy() {
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private getSkipData() {
      if (!this.step) return;
      this.skipStepTotal = Number(this.step.skipStepTotal);
      this.skipStepTotalOld = this.skipStepTotal;
      this.skipStepCondition = this.step.skipStepCondition;
      this.conditionSource = this.step.conditionSource;
      this.skipStepEnabled = this.step.skipStepEnabled;

      // Sets the display for the 'less than/greater than' text
      if (this.skipStepCondition === "lessThan") {
         this.skipStepDisplay = this.lang().LessOrEqualTo;
      } else if (this.skipStepCondition === "greaterThan") {
         this.skipStepDisplay = this.lang().GreaterOrEqualTo;
      }

      if (this.conditionSource === "poTotal") {
         this.conditionSourceDisplay = this.lang().PoTotal;
      } else if (this.conditionSource === "everyPoItemsTotal") {
         this.conditionSourceDisplay = this.lang().EveryPOItemsTotal;
      }
   }

   protected updateBudgetSkipStepDataCondition(
      skipStepCondition: "lessThan" | "greaterThan",
   ): void {
      if (!this.featureCustomBudgets) {
         return;
      }
      if (this.skipStepCondition === skipStepCondition) return;
      this.skipStepCondition = skipStepCondition;
      this.skipStepDisplay =
         skipStepCondition === "lessThan"
            ? this.lang().LessOrEqualTo
            : this.lang().GreaterOrEqualTo;
      this.updateBudgetSkipStepData(
         this.step,
         "skipStepCondition",
         this.skipStepCondition,
      );
   }

   protected updateBudgetSkipStepConditionSource(
      conditionSource: "poTotal" | "everyPoItemsTotal",
   ): void {
      if (!this.featureCustomBudgets) {
         return;
      }
      if (this.conditionSource === conditionSource) return;
      this.conditionSource = conditionSource;
      this.conditionSourceDisplay =
         conditionSource === "poTotal"
            ? this.lang().PoTotal
            : this.lang().EveryPOItemsTotal;
      this.updateBudgetSkipStepData(this.step, "conditionSource", this.conditionSource);
   }

   protected async updateBudgetSkipStepData(budget, prop, value): Promise<void> {
      let newVal = value;
      if (prop === "skipStepEnabled") {
         // toggle the checkSkip value and make it 0/1 instead of true/false
         newVal = value == true ? 1 : 0;
      }

      // Only update this if the total has changed
      if (prop === "skipStepTotal" && newVal === this.skipStepTotalOld) {
         return;
      }

      const updateSkipStepDataResponse = await this.managePO.updateBudgetSkipStepData(
         budget.budgetWorkflowID,
         prop,
         newVal,
      );

      if (updateSkipStepDataResponse) {
         if (!this.step) return;
         this.step.skipStepEnabled = this.skipStepEnabled;
         this.step.skipStepTotal = this.skipStepTotal;
         this.step.skipStepCondition = this.skipStepCondition;
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   protected setPOWorkFlowStepAssignment(step: BudgetWorkflow): void {
      if (!this.featureCustomBudgets) {
         return;
      }

      const budget = this.managePO.getBudget(this.budgetID);
      if (!budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().TheUserThatStartedThePO,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      for (const user of extraUsersOptions.arr) {
         extraUsersOptions.key[user.userID] = user;
      }

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: "",
               title: this.lang().WhoWouldYouLikeToCompleteThisPOStep,
               locationID: budget.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: step.userID,
               defaultProfile: step.profileID,
            },
         },
      };

      instance.result.then(async (result) => {
         if (result != 0) {
            const workflowStepAssignmentResponse =
               await this.managePO.setPurchaseOrderWorkFlowStepAssignment(
                  step.budgetWorkflowID,
                  result.userID,
                  result.profileID,
                  result.multiUsers,
               );

            if (workflowStepAssignmentResponse) {
               this.getStepDisplayName();
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
            }
         }
      });
   }

   protected async setBudgetWorkflowCheckbox(
      step: BudgetWorkflow,
      field: string,
   ): Promise<void> {
      if (!this.featureCustomBudgets) {
         return;
      }

      const budget = this.managePO.getBudget(this.budgetID);
      if (!budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      const newValue = step[field];

      const setWorkflowCheckboxResponse = await this.managePO.setBudgetWorkflowCheckbox(
         step.budgetWorkflowID,
         field as keyof BudgetWorkflow,
         newValue,
      );

      if (setWorkflowCheckboxResponse) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   protected async updateBudgetWorkflowName(step: BudgetWorkflow): Promise<void> {
      if (!this.featureCustomBudgets) {
         return;
      }
      const budget = this.managePO.getBudget(this.budgetID);
      if (!budget?.locationID) return;
      if (
         this.alertService.noCredAtLocationAlert(
            budget.locationID,
            this.credService.Permissions.ChangeBudgetSettings,
         )
      ) {
         return;
      }

      const updateBudgetWorkflowNameResponse =
         await this.managePO.updateBudgetWorkflowName(step.name, step.budgetWorkflowID);

      if (updateBudgetWorkflowNameResponse) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
      }
   }

   private getStepDisplayName(): void {
      if (!this.step) return;

      const getDisplayNameResponse = this.managePO.getPurchasingAssignmentName({
         userID: this.step.userID ?? 0,
         profileID: this.step.profileID ?? 0,
         defaultValue: this.lang().TheUserThatStartedThePO,
      });
      if (getDisplayNameResponse) this.stepDisplayName = getDisplayNameResponse;
   }
}
