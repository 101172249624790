import {
   Component,
   computed,
   DestroyRef,
   effect,
   inject,
   type Signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { finalize, take } from "rxjs";
import { StepComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/step/step.component";
import {
   type ApplyTemplateLocation,
   ApplyTemplateStore,
} from "src/app/assets/services/apply-asset-templates/apply-template.store";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { PickLocations } from "src/app/locations/components/pickLocations/pickLocations.element.component";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { AlertService } from "src/app/shared/services/alert.service";
import { AssetsApiService } from "src/app/tasks/components/shared/services/assets-api/assets-api.service";

@Component({
   selector: "select-location-step",
   templateUrl: "./select-location-step.component.html",
   styleUrls: ["./select-location-step.component.scss"],
   standalone: true,
   imports: [PickLocations],
})
export class SelectLocationStepComponent extends StepComponent {
   public selectedLocationId: number | undefined = undefined;

   private isCheckingAssetsCount = false;

   private readonly applyTemplateState = inject(ApplyTemplateStore);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   protected readonly i18n = inject(TranslationService).i18n;
   private readonly assetService = inject(AssetsApiService);
   private readonly destroyRef = inject(DestroyRef);

   protected selectedLocation: Signal<ApplyTemplateLocation> =
      this.applyTemplateState.selectedLocation;
   public selectLocationData = computed(() => ({
      selectOne: true,
      selectedLocations: this.selectedLocation()?.locationID
         ? [this.selectedLocation().locationID]
         : [],
      massSelectOptions: false,
      initialSearch: this.applyTemplateState?.locationsSearch() ?? "",
   }));

   public constructor() {
      super();
      effect(() => {
         if (this.selectedLocation()?.locationID) {
            this.selectedLocationId = this.selectedLocation().locationID;
         }
      });
   }

   public override handleNextStepClick() {
      if (!this.selectedLocationId) {
         this.alertService.addAlert(
            this.i18n().t("PleaseSelectALocationToProceed"),
            "warning",
            6000,
         );
         return;
      }

      if (this.isCheckingAssetsCount) return;

      this.isCheckingAssetsCount = true;
      this.assetService
         .getList({
            params: {
               locationIDs: [this.selectedLocationId],
            },
         })
         .pipe(
            takeUntilDestroyed(this.destroyRef),
            take(1),
            finalize(() => {
               this.isCheckingAssetsCount = false;
            }),
         )
         .subscribe({
            next: ({ total }) => {
               if (total) {
                  this.context().goToNextStep();
                  return;
               }
               this.alertService.addAlert(
                  this.i18n().t("SelectedLocationDoesNotHaveAnyAssets"),
                  "warning",
                  6000,
               );
            },
         });
   }

   protected selectLocation(event: { selectedLocations: Array<number> }) {
      const locationID = event.selectedLocations[0];
      if (!locationID) return;

      const locationName =
         this.manageLocation.getLocation(locationID)?.locationName ?? "";
      /* The <pick-locations> component triggers a change event even when a user selects the same location again (no actual change occurred).
         Since we reset all selected assets and fields on location change, we should ensure the reset occurs only when the location truly changes.*/
      if (locationID !== this.selectedLocation()?.locationID) {
         this.applyTemplateState.setLocationsSet({
            locationID,
            locationName,
         });
      }
   }

   protected onSearchChange(search: string) {
      this.applyTemplateState.setLocationsSearch(search);
   }
}
