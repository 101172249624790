import type { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { combineLatest, concatMap, EMPTY, expand, from, map, reduce } from "rxjs";
import {
   FlannelApiService,
   type ListResponse,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type { TaskCommentsEntityFilters } from "src/app/tasks/components/shared/services/task-comments-api/task-comments-api.models";
import type { Comment } from "src/app/tasks/types/comment/comment.types";
import { splitArrayIntoChunks } from "src/app/tasks/utils/array-utils";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class TaskCommentsApiService extends FlannelApiService<
   Comment,
   TaskCommentsEntityFilters
> {
   private readonly TASK_COMMENTS_BATCH_SIZE = 500000;
   private readonly CHECKLIST_ID_CHUNK_SIZE = 800;
   private readonly PRE_JIT_URL = `${environment.flannelUrl}/tasks/notes`;

   private constructor() {
      super("task-notes"); // TODO - this calls out to `flannel/jit/task/notes` which does not yet exist. consumer must pass in url for now.
   }

   public override getList(
      requestOptions?: Partial<RequestOptions<TaskCommentsEntityFilters>>,
   ) {
      return super.getList({
         ...requestOptions,
         url: this.PRE_JIT_URL,
      });
   }

   /**
    * @deprecated - this is a temporary solution before we can remove tasks from initial load
    */
   public getPaginatedComments(
      requestOptions?: Partial<RequestOptions<TaskCommentsEntityFilters>>,
   ): Observable<Array<Comment>> {
      if (requestOptions?.filters?.checklistIDs) {
         const chunks = splitArrayIntoChunks(
            requestOptions?.filters?.checklistIDs,
            this.CHECKLIST_ID_CHUNK_SIZE,
         );
         const comments$ = from(chunks).pipe(
            concatMap((checklistIDs: number[]) => {
               return this.getPaginatedCommentsHelper({
                  ...requestOptions,
                  filters: {
                     ...requestOptions.filters,
                     checklistIDs,
                  },
               });
            }),
            reduce<Array<Comment>, Array<Comment>>((acc, val) => acc.concat(val), []),
         );
         return comments$;
      }

      return this.getPaginatedCommentsHelper(requestOptions);
   }

   protected override mapListResponse(
      response: HttpResponse<any>,
   ): ListResponse<Comment> {
      return {
         data: response.body ?? [],
         total: response.body?.length ?? 0,
      };
   }

   private getPaginatedCommentsHelper(
      requestOptions?: Partial<RequestOptions<TaskCommentsEntityFilters>>,
   ): Observable<Array<Comment>> {
      const limit = requestOptions?.pagination?.limit ?? this.TASK_COMMENTS_BATCH_SIZE;

      const paginatedRequestOptions = {
         ...requestOptions,
         url: this.PRE_JIT_URL,
         pagination: {
            page: 1,
            limit,
         },
      };

      const taskCommentResults$ = from(
         super.getList(paginatedRequestOptions).pipe(
            expand((response) => {
               paginatedRequestOptions.pagination.page += 1;
               return (response.total ?? 0) < limit
                  ? EMPTY
                  : super.getList(paginatedRequestOptions);
            }),
            map((val) => val.data ?? []),
            reduce((acc, cur) => {
               return [...acc, ...cur];
            }),
         ),
      );
      const taskComments$ = combineLatest([taskCommentResults$], (tasks) => tasks);
      return taskComments$;
   }
}
