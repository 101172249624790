import type { PmSuggestionSchedule } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion-schedule/pm-suggestion-schedule";
import {
   metricScheduleSchema,
   type MetricScheduleDto,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestion-dto";

export class MetricSchedule implements PmSuggestionSchedule {
   private readonly units: string;
   private readonly interval: number;
   private readonly startAt: number;

   public constructor(options: { units: string; interval: number; startAt: number }) {
      this.units = options.units;
      this.interval = options.interval;
      this.startAt = options.startAt;
   }

   public static fromDto(dto: MetricScheduleDto): MetricSchedule {
      const parsedDto = metricScheduleSchema.parse(dto);
      return new MetricSchedule({
         units: parsedDto.units,
         interval: parsedDto.interval,
         startAt: parsedDto.start_at,
      });
   }

   public toDto(): MetricScheduleDto {
      return {
         type: "metric",
         units: this.units,
         interval: this.interval,
         start_at: this.startAt,
      };
   }

   public getType(): "metric" {
      return "metric";
   }

   public getScheduleString(): string {
      const interval = this.interval;
      const units = this.units;
      const startAt = this.startAt;
      const result = `Every ${interval} ${units}`;
      if (startAt) {
         return `${result} starting at ${startAt} ${units}`;
      }
      return result;
   }

   public getUnits(): string {
      return this.units;
   }

   public getInterval(): number {
      return this.interval;
   }

   public getStartAt(): number {
      return this.startAt;
   }
}
