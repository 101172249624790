<ng-container *translate="let t">
   <span class="filter-inputs-container">
      {{ t("Between") }}
      <input
         class="filter-input-box"
         type="number"
         [ngModel]="filterNumberLowest()"
         (ngModelChange)="onFilterNumberLowestChanged($event)"
      />
      {{ t("and") }}
      <input
         class="filter-input-box"
         type="number"
         [ngModel]="filterNumberHighest()"
         (ngModelChange)="onFilterNumberHighestChanged($event)"
      />
   </span>
</ng-container>
