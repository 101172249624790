import { Component, computed, effect, inject, input, model } from "@angular/core";
import {
   FilterInputComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "data-viewer-search",
   templateUrl: "./data-viewer-search.component.html",
   imports: [SearchAllWrapperComponent, SearchBoxComponent, FilterInputComponent],
})
export class DataViewerSearchComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public isClearable = input<boolean>(false);
   public initialValue = input<string | undefined>("");

   public search = model<string>("");

   public constructor() {
      effect(() => {
         const value = this.initialValue();
         if (value?.length) {
            this.onSearch(value);
         }
      });
   }

   protected onSearch(searchText: unknown) {
      this.search.set(searchText as string);
   }
}
