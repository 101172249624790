import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageProfile } from "src/app/users/services/manageProfile";

@Component({
   selector: "add-user-to-notifications",
   templateUrl: "./addUserToNotifications.modal.component.html",
   styleUrls: ["./addUserToNotifications.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      LimbleHtmlDirective,
      ModalBodyComponent,
      PanelComponent,
      SearchBoxComponent,
      NgClass,
      IconComponent,
      UserImage,
      FormsModule,
      TooltipDirective,
      AlertComponent,
      BasicModalFooterComponent,
      OrderByPipe,
   ],
})
export class AddUserToNotifications implements OnInit {
   public resolve;
   public modalInstance;
   public users;
   public profiles;
   public userProfiles;
   public message;
   public title;
   public selectedUser;
   public errorMsg;
   public email;
   public userClicks;
   public filteredTeams;
   public filteredUsers;
   public searchTeamsAndUser = "";
   private locationID;

   private readonly paramsService = inject(ParamsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageProfile = inject(ManageProfile);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.users = this.resolve.users || [];
      let initialProfiles = this.resolve.profiles || [];
      this.userProfiles = this.resolve.userProfiles || [];
      this.locationID = this.resolve.locationID || null;
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.userClicks = 0;

      for (const user of this.users) {
         user.selected = false;
         user.search = `${user.userFirstName} ${user.userLastName}`;
         user.hint = false;
      }

      initialProfiles = this.manageFilters.filterProfilesToWhoCanCompleteWork(
         initialProfiles,
         this.manageProfile,
      ); //removes View only profile for example

      this.profiles = this.manageFilters.filterProfilesByAssignment(
         this.locationID,
         initialProfiles,
         this.userProfiles,
      );

      for (const profile of this.profiles) {
         profile.selected = false;
      }

      this.selectedUser = false;
      this.errorMsg = false;
      this.email = "";
      this.filteredTeams = this.profiles;
      this.filteredUsers = this.users;
   }

   focusProfile = (profileToFocus) => {
      if (profileToFocus.selected == true) {
         this.submit();
         return;
      }

      for (const user of this.users) {
         user.selected = false;
         user.hint = false;
      }

      for (const profile of this.profiles) {
         profile.selected = false;
      }

      for (const userProfile of this.userProfiles) {
         if (userProfile.profileID == profileToFocus.profileID) {
            this.users.forEach((user) => {
               if (user.userID == userProfile.userID) {
                  user.hint = true;
               }
            });
         }
      }

      profileToFocus.selected = true;
   };

   close = () => {
      this.modalInstance.close(false);
   };

   focusUser = (userToFocus) => {
      let veryFirstClick = false;
      const veryFirstUserID = 0;

      for (const user of this.users) {
         user.hint = false;
      }

      for (const profile of this.profiles) {
         profile.selected = false;
      }

      this.email = "";
      if (veryFirstClick && userToFocus.userID != veryFirstUserID) {
         //veryFirstClick is used to help smooth out the user experience when someone starts with a default selection.  If they do and they pick someone else then it will deselect themselves.  This is because MOST of the time they will want to select some other person.
         veryFirstClick = false;
         for (const user of this.users) {
            user.selected = false;
         }
      }

      if (userToFocus.selected == true) {
         //they picked one that was already selected
         if (this.userClicks <= 0) {
            //only one is selected so they meant to double tap it to select
            this.submit();
            return;
         }
         //they have more then 1 so they are trying to deselect this.
      }
      this.userClicks++;
      this.checkResetUserClicks();

      userToFocus.selected = !userToFocus.selected;
   };

   checkResetUserClicks = () => {
      this.userClicks = this.manageUtil.checkResetUserClicks(this.users, this.userClicks);
   };

   submit = () => {
      const usersToReturn: any = [];
      if (this.email.length > 1) {
         usersToReturn.push({ userID: 0, emailStr: this.email });
      }

      for (const user of this.users) {
         if (user.selected == true) {
            usersToReturn.push({ userID: user.userID, emailStr: "" });
         }
      }

      for (const user of this.users) {
         if (user.hint == true) {
            //if a user has a hint true that means we have selected a profile they belong to.  Basically this is a quick way to see who belongs to what profile and if we selected aprofile they belong to then we should include them in this notification
            usersToReturn.push({ userID: user.userID, emailStr: "" });
         }
      }

      if (usersToReturn.length < 1) {
         this.errorMsg = this.lang().PleaseSelectAUserOrEnterAnEmailAddress;
         return;
      }

      this.modalInstance.close(usersToReturn);
   };

   public filterTeamsAndUsers(searchVal: string): void {
      this.filteredTeams = this.profiles.filter((profile) =>
         profile.profileDescription.toLowerCase().includes(searchVal.toLowerCase()),
      );
      this.filteredUsers = this.users.filter(
         (user) =>
            user.userFirstName.toLowerCase().includes(searchVal.toLowerCase()) ||
            user.userLastName.toLowerCase().includes(searchVal.toLowerCase()),
      );
   }
}
