import type { OnChanges, OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   Directive,
   ElementRef,
   HostListener,
   Input,
   computed,
} from "@angular/core";
import { isNativeMobileApp, LoadingBarService } from "@limblecmms/lim-ui";
import $ from "jquery";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";

@Directive({
   selector: "[print-div]",
   standalone: true,
})
export class PrintDivDirective implements OnChanges, OnInit, OnDestroy {
   @Input() public title;
   @Input() public close;
   @Input() public runPrint;
   public target;
   public iframe;

   public element!: HTMLElement;
   private readonly COLOR_CLASS_MAPPER = {
      "#c22528": "redColorImportant",
      "#429b1f": "greenColorImportant",
      "#efa131": "yellowColorImportant",
      "#4f5259": "darkGreyColorImportant",
      "#999999": "lightGreyColorImportant",
      "#4684d0": "blueColorImportant",
      "#2bbce0": "lightBlueColorImportant",
      "#663399": "purpleColorImportant",
   };

   private readonly el = inject(ElementRef);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.element = this.el.nativeElement;
   }

   public ngOnInit() {
      this.target = this.el.nativeElement.getAttribute("target");
   }

   public ngOnChanges(changes) {
      if (changes.title) {
         this.title = changes.title.currentValue;
      }
      if (changes?.runPrint?.currentValue) {
         this.callOnClick();
      }
   }

   public ngOnDestroy() {
      //once the element with the print div is gone we'll also remove the iframe
      document.title = "Limble CMMS - Maintenance Management Software";
      if (this.iframe) {
         this.iframe.remove();
      }
   }

   @HostListener("click")
   public callOnClick() {
      if (isNativeMobileApp()) {
         this.alertService.addAlert(
            this.lang().PrintingNotSupportedOnMobile,
            "warning",
            8000,
         );
         return;
      }
      // Need a randomNum generated because otherwise, if you click to print, then change the content of what's printing
      // it just tries to print the old data for some reason
      const randomNum = Math.floor(100000 + Math.random() * 900000);
      //because angular was not updating that the actual checkbox is checked we have to go through and manually update.
      const printBulkDiv = document.querySelector(`#${this.target}`);

      // code to print out graphs if needed
      if (!printBulkDiv?.getAttribute("id")?.toLowerCase().includes("qr")) {
         //prevent from doubling QR codes
         printBulkDiv?.querySelectorAll(`canvas`).forEach((canvas) => {
            const dataURL = canvas.toDataURL("image/png");
            const img = document.createElement("img");
            img.src = dataURL;
            const parent = canvas.parentElement;
            parent?.removeChild(canvas);
            parent?.appendChild(img);
         });
      }

      printBulkDiv
         ?.querySelectorAll(`input[type="radio"], input[type="checkbox"]`)
         .forEach((element) => {
            if (!(element instanceof HTMLInputElement) || !element.checked) {
               return;
            }
            element.setAttribute("checked", "checked");
         });

      $(`#${this.target} input[type='number']`).each(function (this: HTMLElement) {
         const val = ($(this).val() as number | string | undefined) ?? null;
         $(this).attr("value", val);
      });
      $(`#${this.target} input[type='text']`).each(function (this: HTMLElement) {
         const val = ($(this).val() as number | string | undefined) ?? null;
         $(this).attr("value", val);
      });
      this.addClassesToFontTags();

      const printContents = $(`#${this.target}`)[0].innerHTML;

      if (this.title === "undefined" || this.title == undefined) {
         this.title = "";
      }
      document.title = this.title;

      const styleTags = Array.from(document.getElementsByTagName("style"));
      const linkTags = Array.from(document.getElementsByTagName("link"));

      const inlineStyle = styleTags.reduce((accumulator, currentValue) => {
         return accumulator + currentValue.innerHTML;
      }, "");

      const linkedStyle = linkTags.reduce((accumulator, currentValue) => {
         if (currentValue.href.includes(".css")) {
            return accumulator + currentValue.outerHTML;
         }
         return accumulator;
      }, "");

      const html = `
            <html>
            <head>
               <title>${this.title}</title>
               ${linkedStyle}
               <style>
                  @media print {
                     .signature-pad {
                        max-width: 424px !important;
                        width: 424px !important;
                     }
                     .signature-pad--body {
                        max-height: 55px !important;
                     }
                     .signature-pad--body img, .signature-complete {
                        max-width: 424px !important;
                        width: 424px !important;
                        height: 55px !important;
                     }
                     .signature-pad--footer {
                        display: none !important;
                     }
                    
                     .chk-item-inline-display {
                        display: inline-block !important;
                     }
                     .bold{
                        font-weight:bold;
                     }
                     input, dropdown-string-container, button {
                        max-height: 32px;
                     }
                    
                     *{
                        font-family: sans-serif !important;
                     }
                  }
                  ${inlineStyle}
               </style>
            </head>
            <body class="printPreviewContainer" style="margin-top: 0px; padding-top: 0px">
               <div class="printOverlay"></div>
               ${printContents}
            </body>
            </html>`; //if we want 'Clarity City' for fonts, then we will need to find a way to use 'preloading' to load that specific font early.  As it currently stands, using "Clarity City" will leave empty text.

      this.iframe = document.createElement("iframe");

      this.iframe.name = `printableDiv-#${this.target}${randomNum}`;
      this.iframe.width = "900";
      this.iframe.srcdoc = html;

      document.body.appendChild(this.iframe);
      window.frames[`printableDiv-#${this.target}${randomNum}`].focus();

      setTimeout(() => {
         //firefox again is requiring a stupid timeout else it wigs out :(

         /**
          * LIM-4099 the afterprint event is not firing possibly due to a chrome bug. beforeprint does
          * fire and we should be able to use it instead since when it's fired the print preview is opening
          * and all loading/rendering of the page to print inside of the iframe should be finished.
          */
         window.frames[`printableDiv-#${this.target}${randomNum}`].addEventListener(
            "beforeprint",
            () => {
               setTimeout(() => {
                  this.returnView();
               }, 0);
            },
         );

         window.frames[`printableDiv-#${this.target}${randomNum}`].print();
      }, 500);
   }

   private setTextColor(fontTag: Element, color: string): void {
      const cssClass = this.COLOR_CLASS_MAPPER[color];
      if (cssClass !== undefined) {
         fontTag.classList.add(cssClass);
      }
   }

   private colorAndBoldBTags(fontTag: HTMLElement, color: string) {
      const children = fontTag.children;
      for (const child of Array.from(children)) {
         if (child.nodeName !== "B") {
            return;
         }
         this.setTextColor(child, color);
         child.classList.add("bold");
      }
   }

   private addClassesToFontTags(): void {
      const fonts = document.body.getElementsByTagName("font");

      for (const fontEl of Array.from(fonts)) {
         if (!(fontEl instanceof HTMLElement)) {
            continue;
         }
         const color = fontEl.attributes.getNamedItem("color")?.value ?? "#000000";
         this.setTextColor(fontEl, color);
         this.colorAndBoldBTags(fontEl, color);
      }
   }

   returnView = () => {
      this.loadingBarService.remove();
      document.body.removeChild(this.iframe);
      if (this.close) {
         this.close();
      }
   };
}
