import type { Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs";

/**
 * An RXJS operator that behaves like distinctUntilKeyChanged
 * except it takes an array of keys to compare instead of just one key.
 */
export function distinctUntilKeysChanged<T>(
   keys: (keyof T)[],
): (source: Observable<T>) => Observable<T> {
   return function (source: Observable<T>): Observable<T> {
      return source.pipe(
         distinctUntilChanged((previous, current) => {
            return keys.every((key) => previous[key] === current[key]);
         }),
      );
   };
}
