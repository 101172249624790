<ng-container *translate="let t">
   <lim-ui-dropdown-button [label]="t('CustomTags')" filterDropdown>
      <div menu>
         <custom-tag-list [data]="tagListOptions" />
         <lim-ui-dropdown-clear-filter-item (click)="handleClearFilter()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
