import { describeUnitCategory } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/describe-unit-category";
import { UnitCategories } from "src/app/parts/unit-of-measure/unit.types";

export const volumeDescription = describeUnitCategory({
   category: UnitCategories.Volume,
   detailsMap: {
      liter: {
         singular: "liter",
         short: "L",
         conversionFactorToSIUnit: 1,
      },
      cup_us: {
         singular: "cup_us",
         short: "cup",
         conversionFactorToSIUnit: 0.24,
      },
      cup_imperial: {
         singular: "cup_imperial",
         short: "cup",
         conversionFactorToSIUnit: 0.284131,
      },
      fluid_ounce_us: {
         singular: "fluid_ounce_us",
         short: "fl oz",
         conversionFactorToSIUnit: 0.0295735,
      },
      fluid_ounce_imperial: {
         singular: "fluid_ounce_imperial",
         short: "fl oz",
         conversionFactorToSIUnit: 0.0284131,
      },
      pint_us: {
         singular: "pint_us",
         short: "pt",
         conversionFactorToSIUnit: 0.473176,
      },
      pint_imperial: {
         singular: "pint_imperial",
         short: "pt",
         conversionFactorToSIUnit: 0.568261,
      },
      quart_us: {
         singular: "quart_us",
         short: "qt",
         conversionFactorToSIUnit: 0.946353,
      },
      quart_imperial: {
         singular: "quart_imperial",
         short: "qt",
         conversionFactorToSIUnit: 1.13652,
      },
      gallon_us: {
         singular: "gallon_us",
         short: "gal",
         conversionFactorToSIUnit: 3.78541,
      },
      gallon_imperial: {
         singular: "gallon_imperial",
         short: "gal",
         conversionFactorToSIUnit: 4.54609,
      },
      milliliter: {
         singular: "milliliter",
         short: "mL",
         conversionFactorToSIUnit: 0.001,
      },
   },
});
