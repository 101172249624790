import { z } from "zod";

export const partFieldValueDtoStrict = z.object({
   valueID: z.number(),
   valueContent: z.union([z.string(), z.number(), z.date(), z.null()]),
   valueLabel: z.string().nullable(),
   valueSort: z.number().nullable(),
   partID: z.number(),
   fieldID: z.number(),
   viewableByTech: z.number().nullable(),
   valueDeleted: z.number().nullable(),
   partValueFileIDs: z.array(z.number()),
});
