<!-- Table -->
@if (part) {
   <lim-ui-panel [table]="true" [noSidePadding]="true" [useCustomTitle]="true">
      <div class="part-vendors-panel-header" panelCustomTitle>
         <span>
            <lim-ui-icon icon="addressCard" />
            {{ i18n().t("AssociatedVendors") }}
         </span>

         <lim-ui-primary-button
            (click)="viewPartVendors()"
            [hidden]="!changePartVendorAssociations"
         >
            {{ i18n().t("AssociateVendors") }}
         </lim-ui-primary-button>
      </div>
      <div class="listTable lim-ui-responsive-table">
         <!-- The header -->
         <div class="listItem listItemHeader lim-ui-responsive-table-header">
            <span class="col-md-4 vendor-header"
               >{{ i18n().t("Vendor") }}
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  [limUiTooltip]="i18n().t('VendorHeaderToolTip')"
            /></span>
            <span class="col-md-2 number-header"
               >{{ i18n().t("PartNumber") }}
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  [limUiTooltip]="i18n().t('PartNumberForVendorToolTip')"
               />
            </span>
            <span class="col-md-2 price-header"
               >{{ i18n().t("PartPrice") }}
               <lim-ui-icon
                  icon="circleQuestionRegular"
                  [limUiTooltip]="i18n().t('PartPriceForVendorToolTip')"
               />
            </span>
            @if (isVendorLeadTimeEnabled()) {
               <span class="col-md-2"
                  >{{ i18n().t("AverageLeadTimeHeader") }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     [limUiTooltip]="i18n().t('AverageLeadTimeTooltip')"
                  />
               </span>
            }
         </div>

         <!-- The rows -->
         @for (vendor of vendors; track vendor) {
            <div class="table-row-container">
               @if (vendorRelationInfo?.get(vendor.vendorID); as relationInfo) {
                  <div class="listItem lim-ui-responsive-table-row">
                     <div class="col-md-4 name-and-location-row">
                        <lim-ui-radio-button
                           groupID="data-type"
                           [checked]="
                              relationInfo.vendorID === part?.defaultVendorID &&
                              (relationInfo.defaultForPartPO ?? false)
                           "
                           (click)="setDefaultVendor(vendor)"
                           [disabled]="!changePartVendorAssociations"
                        />

                        <div class="vendor-and-icon-container">
                           <span class="vendor-name" (click)="popVendor(vendor)">
                              <lim-ui-icon class="vendor-icon" icon="addressCard" />
                              {{ vendor.vendorName }} -
                           </span>
                           <span class="location-name">
                              <lim-ui-icon icon="houseChimney" />
                              {{ locationsIndex[vendor.locationID].locationName }}
                           </span>
                        </div>
                     </div>

                     <div class="col-md-2 part-number-row">
                        <span class="lim-ui-show-on-mobile">Part Number: </span>
                        <input
                           [disabled]="!credChangePartNumber()"
                           type="text"
                           [(ngModel)]="relationInfo.partNumber"
                           (blur)="updateNumberToVendorAssociation(vendor, part)"
                        />
                     </div>

                     <div class="col-md-2 part-price">
                        @if (currencyService.isMultiCurrencyEnabled()) {
                           <lim-ui-input-with-prefix
                              [disabled]="!credChangePartPrice()"
                              [prefixText]="vendor.currencyCode | currencySymbol"
                              [type]="'number'"
                              [value]="
                                 relationInfo.partPrice ? relationInfo.partPrice : null
                              "
                              (valueChange)="updatePartPrice($event, relationInfo)"
                              [min]="0"
                              (inputBlur)="
                                 updatePriceToVendorAssociation(
                                    vendor,
                                    part,
                                    relationInfo
                                 )
                              "
                              [tabIndexInput]="'${item.poItemID}3'"
                              class="number-field"
                           />
                        } @else {
                           <lim-ui-input-with-prefix
                              [disabled]="!credChangePartPrice()"
                              [prefixText]="currencySymbol"
                              [type]="'number'"
                              [value]="
                                 relationInfo.partPrice ? relationInfo.partPrice : null
                              "
                              (valueChange)="updatePartPrice($event, relationInfo)"
                              [min]="0"
                              (inputBlur)="
                                 updatePriceToVendorAssociation(
                                    vendor,
                                    part,
                                    relationInfo
                                 )
                              "
                              [tabIndexInput]="'${item.poItemID}3'"
                              class="number-field"
                           />
                        }
                     </div>
                     @if (isVendorLeadTimeEnabled()) {
                        <div class="col-md-2 part-lead-time">
                           @if (vendor.vendorPartAverageLeadTime) {
                              <span>{{ vendor.vendorPartAverageLeadTime }} </span>
                           }
                        </div>
                     }

                     <div class="col-md-2 buttons">
                        <span class="lim-ui-show-on-mobile">Actions: </span>
                        @if (
                           vendorRelationInfo?.get(vendor.vendorID)?.automaticPartRelation
                        ) {
                           <lim-ui-icon
                              class="lim-ui-hide-on-mobile"
                              icon="wandMagicSparkles"
                              limUiTooltip="{{
                                 i18n().t('MagicPartVendorAssociationTooltip')
                              }}"
                           />
                        }

                        @if (
                           vendorRelationInfo?.get(vendor.vendorID)?.manualPartRelation &&
                           changePartVendorAssociations
                        ) {
                           <lim-ui-minimal-icon-button
                              icon="trashCanRegular"
                              iconColor="danger"
                              (click)="deleteManualPartAssociation(part?.partID, vendor)"
                              limUiTooltip="{{
                                 i18n().t('ThisVendorWasManuallyAssociatedWithThisPart')
                              }}"
                           />
                        }
                     </div>
                  </div>
               }
            </div>
         }

         <div class="table-row-container" [hidden]="!(vendors?.length == 0)">
            <div class="table-row-container">
               <div class="listItem">
                  <div class="col-md-12">
                     {{ i18n().t("NoVendorsAreAssociatedWithThisPart") }}
                  </div>
               </div>
            </div>
         </div>
      </div>
   </lim-ui-panel>
}
