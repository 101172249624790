import { CommonModule } from "@angular/common";
import { Component, computed } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import type { ColumnKeys } from "src/app/shared/data-viewer/column-builder";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";

@Component({
   selector: "submitted-on-cell-renderer",
   imports: [CommonModule, BetterDatePipe],
   templateUrl: "./submitted-on-cell-renderer.component.html",
})
export class SubmittedOnCellRendererComponent extends BaseCellRendererComponent {
   submission = computed(
      () =>
         this.cell as {
            [ColumnKeys.SUBMITTED_ON]: string;
         },
   );
}
