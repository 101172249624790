import { computed, inject, Injectable } from "@angular/core";
import type { ChartSegment } from "src/app/dashboards/widgets/chart-segment/chart-segment.models";
import { ManageLang } from "src/app/languages/services/manageLang";
import { FilterLabelKey, type DataViewerFilter } from "src/app/shared/data-viewer";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { DataViewerFiltersBuilder } from "src/app/shared/data-viewer/data-viewer-filters/data-viewer-filters.builder";
import type { RequestFilter } from "src/app/shared/services/flannel-api-service";
import type {
   TaskOptionsParams,
   TasksDataViewerOptions,
} from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import {
   TaskColumnDefinitionsFactoryService,
   TaskViewType_AnyTasks,
   TaskViewType_AverageDowntimeHours,
   TaskViewType_AverageTimeSpent,
   TaskViewType_AverageUptimeHours,
   TaskViewType_ByCost,
   TaskViewType_ByDowntime,
   TaskViewType_ByStatus,
   TaskViewType_ByType,
   TaskViewType_CompletedPlannedWOs,
   TaskViewType_CompletedPMs,
   TaskViewType_CompletedTasks,
   TaskViewType_CompletedUnplannedWOs,
   TaskViewType_CompletedWorkRequests,
   TaskViewType_CostInvoices,
   TaskViewType_CostLabor,
   TaskViewType_CostParts,
   TaskViewType_CostTotal,
   TaskViewType_CycleCountTasks,
   TaskViewType_DowntimeHours,
   TaskViewType_DowntimePercent,
   TaskViewType_mtbf,
   TaskViewType_mttr,
   TaskViewType_OpenPlannedWOs,
   TaskViewType_OpenTasks,
   TaskViewType_OpenUnplannedWOs,
   TaskViewType_OpenWorkRequests,
   TaskViewType_StartedVsCompleted,
   TaskViewType_TasksByStatusDuration,
   TaskViewType_TaskSchedulesCombined,
   TaskViewType_TasksIncludeUpcoming,
   TaskViewType_Total,
   TaskViewType_TotalCompletedTasks,
   TaskViewType_TotalDowntimeHours,
   TaskViewType_TotalOpenTasks,
   TaskViewType_TotalTasks,
   TaskViewType_TotalTimeSpent,
   TaskViewType_TotalUptimeHours,
   TaskViewType_UpcomingEstimatedTime,
   TaskViewType_UptimePercent,
   VirtualTaskViewType_CriticalWOsAndPMs,
   VirtualTaskViewType_downtimeCount,
   VirtualTaskViewType_downtimeHoursEfficiency,
   VirtualTaskViewType_downtimeHoursEfficiencyLine,
   VirtualTaskViewType_laborCostEfficiency,
   VirtualTaskViewType_laborCostEfficiencyLine,
   VirtualTaskViewType_mtbf,
   VirtualTaskViewType_mttr,
   VirtualTaskViewType_OnTimeWOsAndPMs,
   VirtualTaskViewType_operatingCostsMonthlyLine,
   VirtualTaskViewType_OverdueWOsAndPMs,
   VirtualTaskViewType_partCostEfficiency,
   VirtualTaskViewType_partCostEfficiencyLine,
   VirtualTaskViewType_PlannedMaintenance,
   VirtualTaskViewType_PlannedVsUnplannedMonthly,
   VirtualTaskViewType_totalInvoiceCost,
   VirtualTaskViewType_totalLaborCost,
   VirtualTaskViewType_totalOperatingCost,
   VirtualTaskViewType_totalPartsCost,
   VirtualTaskViewType_UnplannedMaintenance,
   VirtualTaskViewType_WorkRequests,
   type TaskViewTypes,
} from "src/app/tasks/components/shared/services/task-column-definitions-factory";

/**
 * This is a service that will provide utilities to set the configuration for
 * the tasks data viewer
 * NOTE: This service will be merged to  the TasksDataViewerStateService once
 * it is migrated to use Signal Store
 */
@Injectable({ providedIn: "root" })
export class TasksDataViewerOptionsFactoryService {
   private readonly columnsFactory = inject(TaskColumnDefinitionsFactoryService);
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   /**
    * Returns the sort bind to be used in the data viewer for the given view type.
    */
   public getSortBy(viewType: TaskViewTypes) {
      switch (viewType) {
         case TaskViewType_CompletedTasks:
         case TaskViewType_TotalTimeSpent:
         case TaskViewType_AverageTimeSpent:
            return "-checklistCompletedDate";

         // By Due Date
         case TaskViewType_OpenTasks:
         case TaskViewType_CompletedPlannedWOs:
         case TaskViewType_TotalCompletedTasks:
         case TaskViewType_CompletedWorkRequests:
         case TaskViewType_TotalTasks:
         case TaskViewType_Total:
         case TaskViewType_CompletedPMs:
         case TaskViewType_CycleCountTasks:
            return "checklistDueDate";

         /// By Downtime
         case TaskViewType_UptimePercent:
         case TaskViewType_DowntimePercent:
         case TaskViewType_mttr:
         case TaskViewType_mtbf:
         case TaskViewType_ByDowntime:
         case TaskViewType_DowntimeHours:
            return "-checklistDowntime";

         // By Task and Schedules Combined
         case TaskViewType_TaskSchedulesCombined:
            return "name";

         // By Cost
         case VirtualTaskViewType_totalOperatingCost:
         case VirtualTaskViewType_operatingCostsMonthlyLine:
            return "-checklistTotalOperatingCost";
         case VirtualTaskViewType_totalPartsCost:
            return "-checklistTotalPartsCost";
         case VirtualTaskViewType_totalLaborCost:
            return "-checklistTotalLaborCost";
         case VirtualTaskViewType_totalInvoiceCost:
            return "-checklistTotalInvoiceCost";

         // By Name Ascending
         case TaskViewType_StartedVsCompleted:
         case TaskViewType_TasksIncludeUpcoming:
         default:
            return "checklistName";
      }
   }

   /**
    * Formats the title to include the date if the view is a line graph
    */
   private formatTitleWithDate(
      baseTitle: string,
      viewedAs?: string,
      segmentKey?: string,
   ): string {
      if (viewedAs && viewedAs === "lineGraph" && segmentKey) {
         return `${baseTitle} - ${segmentKey}`;
      }
      return baseTitle;
   }

   /**
    *  Returns the title to be used in the data viewer for the given view type.
    */
   public getTitle(
      type: TaskViewTypes,
      viewedAs?: string,
      segmentKey?: string,
      titleOverride?: string,
   ) {
      switch (type) {
         case TaskViewType_CompletedTasks:
            return this.formatTitleWithDate(
               this.lang().CompletedTasks,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_OpenTasks:
            return this.formatTitleWithDate(this.lang().OpenTasks, viewedAs, segmentKey);
         /**
          * These are related to widget display types
          */
         case TaskViewType_TasksIncludeUpcoming:
            return this.formatTitleWithDate(this.lang().ViewList, viewedAs, segmentKey);
         case TaskViewType_CompletedPlannedWOs:
            return this.formatTitleWithDate(
               this.lang().CompletedPlannedWOs,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_CompletedUnplannedWOs:
            return this.formatTitleWithDate(
               this.lang().CompletedUnplannedWOs,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_TotalCompletedTasks:
            return this.formatTitleWithDate(
               this.lang().TotalCompletedTasks,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_CompletedPMs:
            return this.formatTitleWithDate(
               this.lang().CompletedPMs,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_CompletedWorkRequests:
            return this.formatTitleWithDate(
               this.lang().CompletedWorkRequests,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_TotalTasks:
            return this.formatTitleWithDate(this.lang().TotalTasks, viewedAs, segmentKey);
         case TaskViewType_mtbf:
         case TaskViewType_mttr:
         case TaskViewType_ByDowntime:
         case TaskViewType_DowntimeHours:
         case TaskViewType_UptimePercent:
         case TaskViewType_DowntimePercent:
         case TaskViewType_TotalDowntimeHours:
         case TaskViewType_TotalUptimeHours:
         case TaskViewType_AverageDowntimeHours:
         case TaskViewType_AverageUptimeHours:
            return this.formatTitleWithDate(
               this.lang().ListOfTasksThatCausedDowntime,
               viewedAs,
               segmentKey,
            );

         case TaskViewType_OpenPlannedWOs:
            return this.formatTitleWithDate(
               this.lang().OpenPlannedWOs,
               viewedAs,
               segmentKey,
            );

         case TaskViewType_OpenUnplannedWOs:
            return this.formatTitleWithDate(
               this.lang().OpenUnplannedWOs,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_OpenWorkRequests:
            return this.formatTitleWithDate(
               this.lang().OpenWorkRequests,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_StartedVsCompleted:
         case TaskViewType_TaskSchedulesCombined:
            return this.formatTitleWithDate(this.lang().ViewTasks, viewedAs, segmentKey);
         case TaskViewType_TotalOpenTasks:
            return this.formatTitleWithDate(
               this.lang().TotalOpenTasks,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_CycleCountTasks:
            return this.formatTitleWithDate(
               this.lang().ListOfCycleCountTasks,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_TotalTimeSpent:
            return this.formatTitleWithDate(
               this.lang().TotalTimeSpent,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_AverageTimeSpent:
            return this.formatTitleWithDate(
               this.lang().AverageTimeSpentPerAsset,
               viewedAs,
               segmentKey,
            );
         case TaskViewType_CostParts:
            return this.formatTitleWithDate(this.lang().Parts, viewedAs, segmentKey);
         case TaskViewType_CostLabor:
            return this.formatTitleWithDate(this.lang().Labor, viewedAs, segmentKey);
         case TaskViewType_CostInvoices:
            return this.formatTitleWithDate(this.lang().Invoices, viewedAs, segmentKey);
         case TaskViewType_CostTotal:
            return this.formatTitleWithDate(this.lang().Total, viewedAs, segmentKey);
         case VirtualTaskViewType_OnTimeWOsAndPMs:
            return this.formatTitleWithDate(
               this.lang().OntimeWorkOrdersAndPMs,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_OverdueWOsAndPMs:
            return this.formatTitleWithDate(
               this.lang().OverdueWorkOrdersAndPMs,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_CriticalWOsAndPMs:
            return this.formatTitleWithDate(
               this.lang().CriticalWorkOrdersAndPMs,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_PlannedMaintenance:
            return titleOverride ?? this.lang().NumberOfPMs;
         case VirtualTaskViewType_UnplannedMaintenance:
            return titleOverride ?? this.lang().NumberOfWorkOrders;
         case VirtualTaskViewType_PlannedVsUnplannedMonthly:
            return titleOverride ?? this.lang().PreventiveVsUnplannedMaintenance;
         case VirtualTaskViewType_WorkRequests:
            return this.formatTitleWithDate(
               this.lang().WorkRequests,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_mtbf:
         case VirtualTaskViewType_mttr:
         case VirtualTaskViewType_downtimeCount:
            return this.formatTitleWithDate(
               this.lang().ListOfTasksThatCausedDowntime,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_totalOperatingCost:
            return this.formatTitleWithDate(
               this.lang().TotalOperatingCost,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_totalPartsCost:
            return this.formatTitleWithDate(
               this.lang().TotalPartsCost,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_totalLaborCost:
            return this.formatTitleWithDate(
               this.lang().TotalLaborCost,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_totalInvoiceCost:
            return this.formatTitleWithDate(
               this.lang().TotalInvoiceCost,
               viewedAs,
               segmentKey,
            );
         case VirtualTaskViewType_operatingCostsMonthlyLine:
            return titleOverride ?? this.lang().TotalCostOfOperationsBreakdown;
         case VirtualTaskViewType_downtimeHoursEfficiency:
         case VirtualTaskViewType_downtimeHoursEfficiencyLine:
            return titleOverride ?? this.lang().DowntimeEfficiencySavings;
         case VirtualTaskViewType_laborCostEfficiency:
         case VirtualTaskViewType_laborCostEfficiencyLine:
            return titleOverride ?? this.lang().LaborEfficiencySavings;
         case VirtualTaskViewType_partCostEfficiency:
         case VirtualTaskViewType_partCostEfficiencyLine:
            return titleOverride ?? this.lang().PartSpendEfficiencySavings;
         /**
          * Default Cases
          */
         case TaskViewType_ByStatus:
         case TaskViewType_ByCost:
         case TaskViewType_ByType:
         case TaskViewType_AnyTasks:
         case TaskViewType_TasksByStatusDuration:
         case TaskViewType_UpcomingEstimatedTime:
         default:
            return this.formatTitleWithDate(this.lang().Tasks, viewedAs, segmentKey);
      }
   }

   /**
    * Retrieves an array of data viewer filters based on the given task view type.
    *
    * @param type - The task view type to filter the data viewer filters.
    *
    * @return An array of data viewer filters.
    */
   public getDataViewerFilters(type: TaskViewTypes): Array<DataViewerFilter> {
      const filtersBuilder = new DataViewerFiltersBuilder();

      switch (type) {
         case "cycleCountTasks":
            return filtersBuilder
               .addPriorityLevelFilter()
               .addTaskStatusFilter()
               .addDate({
                  labelKey: FilterLabelKey.DUE_DATE,
                  key: "due",
               })
               .addDate({
                  labelKey: FilterLabelKey.COMPLETED_BY,
                  key: "completed",
               })
               .addCompletedByFilter()
               .addAssignedToFilter()
               .build();

         default:
            return filtersBuilder.addTaskTypesFilter().addCustomTagsFilter().build();
      }
   }

   /**
    * Makes the options for a TasksDataViewer based on the options passed.
    */
   public getOptions(options: TaskOptionsParams): TasksDataViewerOptions {
      const { type, optionsOverrides, segment, requestFilters, viewedAs } = options;

      return {
         type,
         title: this.getTitle(
            type,
            viewedAs,
            segment?.segmentKey,
            optionsOverrides?.title,
         ),
         sortBy: this.getSortBy(type),
         filters: this.getDataViewerFilters(type),
         columns: this.columnsFactory.getByViewType(type) as Array<Column>,
         isStartWorkOrderButtonVisible: this.getIsStartWorkOrderVisible(type),
         requestFilters: this.getRequestFilters(requestFilters, segment),
         ...optionsOverrides,
      };
   }

   public getRequestFilters(
      requestFilters: Array<RequestFilter> | undefined,
      segment: ChartSegment | undefined,
   ): Array<RequestFilter> {
      const filters = [...(requestFilters ?? [])];

      filters.push({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });

      if (segment?.segmentKey) {
         filters.push({ widgetSegment: segment.segmentKey });
      }
      if (segment?.lineKey) {
         filters.push({ widgetLine: segment.lineKey });
      }
      return filters;
   }

   private getIsStartWorkOrderVisible(type: TaskViewTypes): boolean {
      const typesWithButton = [
         TaskViewType_OpenPlannedWOs,
         TaskViewType_OpenUnplannedWOs,
         TaskViewType_OpenWorkRequests,
         TaskViewType_TotalOpenTasks,
         TaskViewType_OpenTasks,
         TaskViewType_Total,
      ];
      return typesWithButton.includes(type);
   }
}
