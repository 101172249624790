import { Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import type { PmSuggestion } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion";

@Injectable({
   providedIn: "root",
})
export class PmSuggestionsCreationService {
   public async createTaskFromSuggestion(
      suggestion: PmSuggestion,
      locationID: number,
   ): Promise<AxiosResponse> {
      return axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "newTemplateFromSuggestion",
         },
         data: {
            assetID: suggestion.getAssetID(),
            locationID: locationID,
            newName: suggestion.getName(),
            type: "pm",
            instructions: suggestion.getInstructions(),
         },
      });
   }
}
