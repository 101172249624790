<lim-ui-modal [showWatermark]="true" *translate="let t">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body class="pick-user-or-profile-modal">
      <lim-ui-pick-list-panel [numberOfColumns]="noSearchResults ? 1 : 2">
         <ng-container custom-title>
            <div class="panel-info">
               <div class="title-section">
                  @if (message.length != 0 && !noSearchResults) {
                     <div [limbleHtml]="message"></div>
                  }
               </div>

               @if (showAuditOptions) {
                  <div [ngClass]="{ warning: auditOptionError }">
                     {{ lang().Repeat }}
                     <div
                        limbleContentEditable
                        disableEditor="true"
                        class="audit-options exitOnEnterKeyPress"
                        [(ngModel)]="numberCount"
                     ></div>
                     {{ lang().timesWithin }}
                     <div
                        limbleContentEditable
                        disableEditor="true"
                        class="audit-options exitOnEnterKeyPress"
                        [(ngModel)]="days"
                     ></div>
                     {{ lang().days }}
                  </div>
               }
            </div>
         </ng-container>

         @if (
            !(
               allUsers.length >= MAX_USER_PROFILE_SEARCH_LENGTH ||
               allProfiles.length >= MAX_USER_PROFILE_SEARCH_LENGTH
            ) &&
            canAddUsers &&
            !noSearchResults
         ) {
            <lim-ui-secondary-button
               icon="plus"
               heading-options
               (click)="openAddUserModal()"
               dataLogLabel="manageUsers-initiateAUser"
            >
               {{ lang().AddAUser }}
            </lim-ui-secondary-button>
         }

         @if (
            allUsers.length >= MAX_USER_PROFILE_SEARCH_LENGTH ||
            allProfiles.length >= MAX_USER_PROFILE_SEARCH_LENGTH
         ) {
            <ng-container heading-options>
               <div class="panel-options">
                  <lim-ui-search-box
                     class="search-box"
                     [placeholder]="lang().Search"
                     [(searchVal)]="searchUsersTeams"
                     (searchValChange)="runFilter()"
                     [focusOnLoad]="true"
                  />
               </div>
            </ng-container>
         }

         <ng-container panel-content>
            @if (noSearchResults) {
               <span class="no-search-results">
                  <no-search-results />
                  <div class="no-search-results-buttons">
                     @if (canAddUsers) {
                        <lim-ui-primary-button
                           icon="plus"
                           (click)="openAddUserModal()"
                           dataLogLabel="manageUsers-initiateAUser"
                        >
                           {{ lang().AddAUser }}
                        </lim-ui-primary-button>
                     }
                     <lim-ui-secondary-button
                        icon="plus"
                        (click)="closeAllAndRedirect('/profileManagement/' + locationID)"
                     >
                        {{ lang().SetUpATeam }}
                     </lim-ui-secondary-button>
                  </div>
               </span>
            }
            @if (!noSearchResults) {
               <div>
                  <div class="lim-ui-pick-list-header">
                     <div>
                        {{ lang().Teams }}
                     </div>
                  </div>
                  @if (manageProfileCred) {
                     <div class="add-team-btn">
                        <lim-ui-outlined-button
                           icon="plus"
                           (click)="
                              closeAllAndRedirect('/profileManagement/' + locationID)
                           "
                        >
                           {{ lang().SetUpATeam }}
                        </lim-ui-outlined-button>
                     </div>
                  }

                  @for (
                     profile of profiles | orderBy: "profileDescription";
                     track profile
                  ) {
                     <div
                        (click)="focusProfile(profile.profileID)"
                        class="lim-ui-list-item lim-ui-selectable-list-item"
                        [ngClass]="{
                           'lim-ui-selected-list-item lim-ui-selected-pick-list-item':
                              selectedProfile == profile.profileID,
                        }"
                     >
                        <div class="lim-ui-list-item-content-group">
                           @if (selectedProfile == profile.profileID) {
                              <lim-ui-icon
                                 icon="check"
                                 iconSize="small"
                                 iconColor="primary"
                              />
                           }
                           <div class="lim-ui-icon-with-text">
                              <lim-ui-block-icon icon="users" />
                              <span [limbleHtml]="profile.profileDescription"></span>
                           </div>
                        </div>
                     </div>
                  }
               </div>
            }
            @if (!noSearchResults) {
               <div>
                  <div class="lim-ui-pick-list-header">
                     <div>
                        {{ lang().Users }}
                     </div>
                  </div>
                  <div class="add-team-btn-container">
                     @if (canAddUsers && !noSearchResults) {
                        <lim-ui-secondary-button
                           icon="plus"
                           (click)="openAddUserModal()"
                           dataLogLabel="manageUsers-initiateAUser"
                        >
                           {{ lang().AddAUser }}
                        </lim-ui-secondary-button>
                     }
                     <lim-ui-selection-controls
                        [selectAll]="t('SelectAll')"
                        [deselectAll]="t('DeselectAll')"
                        (handleSelectAll)="selectAllUsers(true)"
                        (handleDeselectAll)="selectAllUsers(false)"
                     />
                  </div>
                  @for (user of users | orderBy: "userLastName"; track user) {
                     <div
                        (click)="focusUser(user)"
                        class="lim-ui-list-item lim-ui-selectable-list-item"
                        [ngClass]="{
                           'lim-ui-selected-list-item': user.selected == true,
                           'lim-ui-hinted-list-item': user.hint,
                           'lim-ui-warning-list-item': user.extra,
                        }"
                     >
                        <div class="lim-ui-list-item-content">
                           <div class="lim-ui-list-item-content-group">
                              @if (user.selected == true) {
                                 <lim-ui-icon
                                    icon="check"
                                    iconSize="small"
                                    [iconColor]="user.extra ? 'danger' : 'primary'"
                                 />
                              }

                              <div class="lim-ui-icon-with-text">
                                 <user-image [user]="user" />

                                 <span>
                                    {{ user.userFirstName }} {{ user.userLastName }}
                                 </span>
                              </div>
                           </div>
                           @if (user.tooltipText && user.tooltipText.length) {
                              <lim-ui-icon
                                 icon="circleQuestion"
                                 iconSize="small"
                                 limUiTooltip="{{ user.tooltipText }}"
                                 placement="top"
                              />
                           }
                        </div>
                     </div>
                  }
               </div>
            }
         </ng-container>
         <ng-container panel-footer>
            <div class="panel-footer">
               @if (advancedOptions) {
                  <div class="advanced-options">
                     <b>{{ lang().AdvancedSettings }}</b>

                     <lim-ui-checkbox
                        [label]="lang().ForceSelection"
                        [(model)]="locked"
                        [disabled]="!lockable"
                        [tooltip]="lang().LockedUserPickDescription"
                     />
                  </div>
               }
            </div>
         </ng-container>
      </lim-ui-pick-list-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Select }}"
      (successClick)="submit()"
      [dataLogSubmitLabel]="
         (isPhoneScreenSize$ | async)
            ? 'taskMobile-submitChangeAssigned'
            : 'task-submitChangeAssigned'
      "
   />
</lim-ui-modal>
