import { z } from "zod";

export const CustomStockUnitSchema = z.object({
   partStockCustomUnitID: z.number(),
   partStockCustomUnitNameShort: z.string(),
   partStockCustomUnitNameSingular: z.string(),

   partStockProvidedUnitID: z.null(),
   partStockProvidedUnitAlias: z.null(),
});
