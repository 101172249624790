<lim-ui-modal>
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()">
      <lim-ui-secondary-button (click)="newInstructionSet()" modalTitle>
         {{ lang().NewInstructionSetTemplate }}
      </lim-ui-secondary-button>
   </lim-ui-basic-modal-header>
   <lim-ui-modal-body [scrollable]="true">
      <!-- progress bar for syncing tasks -->
      @if (end > 0) {
         <div class="uploading-popup">
            <div class="progress-bar">
               <lim-ui-loading-animation />
               <div class="text-and-progress">
                  <div class="text">
                     <div class="upload-header">{{ lang().WakingUpHamsters }}</div>
                     <lim-ui-progress-bar
                        [progress]="(currentlyAt / end) * 100 | betterNumber: '1.0-0'"
                        label="{{ lang().ThisMayTakeAMoment }}"
                     />
                  </div>
               </div>
            </div>
         </div>
      }

      @if (message) {
         <lim-ui-info-panel>
            <span class="message" [limbleHtml]="message"></span>
         </lim-ui-info-panel>
      }
      <lim-ui-panel>
         <div class="search-container">
            <lim-ui-search-box
               [placeholder]="lang().Search"
               [(searchVal)]="search"
               [formControl]="searchControl"
               [focusOnLoad]="true"
            />
         </div>
         <div class="loading-icon">
            @if (isLoading) {
               <lim-ui-loading-animation />
            }
         </div>
         @if (treeData.length && !noSearchResults && hierOptions) {
            <hierarchy-container
               [treeData]="treeData"
               [options]="hierOptions"
               [selectedNodeID]="instructionSetSelectedID"
               [fetchMore]="fetchMoreTemplatesAtLocation"
               [hidden]="isLoading"
            />
         }
         @if (noSearchResults) {
            <no-search-results />
         }
         @if (noInstructionSetTemplatesExist) {
            <lim-ui-alert [title]="lang().Warning" alertType="warning">
               {{ lang().NoInstructionSetsExist }}
            </lim-ui-alert>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Select }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
