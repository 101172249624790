import { CommonModule } from "@angular/common";
import { computed, inject, Component, Input, forwardRef } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   selector: "task-latest-activity-cell",
   imports: [CommonModule, BetterDatePipe],
   providers: [forwardRef(() => TasksFacadeService)],
   templateUrl: "./task-latest-activity-cell.component.html",
})
export class TaskLatestActivityCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
