import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, HostListener, computed, DestroyRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   IconComponent,
   ModalComponent,
   ModalDirective,
   PrimaryButtonComponent,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import type { Subscription } from "rxjs";
import { filter, lastValueFrom } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { LwmGetAllOpenTasks } from "src/app/shared/types/websocket.types";
import { assert } from "src/app/shared/utils/assert.utils";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import {
   TasksApiService,
   type TaskEntity,
} from "src/app/tasks/components/shared/services/tasks-api";
import { TaskFormComponent } from "src/app/tasks/components/task-form/task-form.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "pop-task",
   templateUrl: "./popTask.modal.component.html",
   styleUrls: ["./popTask.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      PrimaryButtonComponent,
      IconComponent,
      NgxSkeletonLoaderModule,
      TaskFormComponent,
   ],
})
export class PopTask implements OnInit, OnDestroy {
   public loadingBar;
   public title;
   public message;
   public task: TaskTemplateEntity | TaskEntity | undefined;
   public data;
   public resolve;
   public modalInstance;
   public hasLoaded = false;
   public instructionSetTemplate;
   private closeModalFunc;
   public readonly currentUser;
   private getAllOpenTasksSubscription: Subscription | undefined;
   public completed: boolean = false;
   public superUser: boolean = false;
   protected dataLogOptions: DataLogEventDefinition | undefined;

   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly credService = inject(CredService);
   private readonly manageUser = inject(ManageUser);
   private readonly taskTemplateApiService = inject(TaskTemplatesApiService);
   private readonly taskApiService = inject(TasksApiService);
   private readonly manageLang = inject(ManageLang);
   private readonly destroyRef = inject(DestroyRef);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.currentUser = this.manageUser.getCurrentUser();
      assert(this.currentUser !== undefined);
   }

   public ngOnInit(): void {
      this.initialize();
   }

   private async initialize() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.dataLogOptions = this.resolve.dataLogOptions;
      this.closeModalFunc = this.resolve.closeModalFunc;
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
      this.loadingBar = true;

      let task;
      if (this.resolve.data.template === true) {
         task = await lastValueFrom(
            this.taskTemplateApiService
               .getById(this.resolve.data.checklistID, {
                  params: this.resolve.data.isDefaultTemplate
                     ? {
                          allowDeleted: true,
                       }
                     : {},
               })
               .pipe(takeUntilDestroyed(this.destroyRef)),
         );
      } else {
         task = await lastValueFrom(
            this.taskApiService
               .getById(this.resolve.data.checklistID, {
                  columns: "comments,invoices,extraTime,parts,partsDetails",
               })
               .pipe(takeUntilDestroyed(this.destroyRef)),
         );
      }

      // prevents the empty modal if the task is undefined
      if (task === undefined) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         this.modalInstance.close();
         return;
      }

      this.buildDataForDisplay(task, this.resolve.data);
      this.hasLoaded = true;
   }

   private buildDataForDisplay(task: TaskTemplateEntity | TaskEntity, data: any) {
      if (data.title) {
         this.title = data.title;
      }
      if (data.message) {
         this.message = data.message;
      }
      if (Number(task.checklistTemplate) === 6) {
         this.instructionSetTemplate = true;
      }
      this.completed = task.checklistUserCompleted !== 0 || task.checklistStatusID !== 0;

      if (!task || !data.checklistID) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         throw new Error("task not found");
      }

      this.data = {
         checklistID: data.checklistID,
         expand: false,
         guided: true,
         preview: data.preview,
         options: data.options || {},
         checklistTemplates: [task.checklistTemplate],
      };
      this.getAllOpenTasksSubscription = this.manageTask.taskMessages$
         .pipe(
            filter((msg): msg is LwmGetAllOpenTasks => msg.action === "getAllOpenTasks"),
         )
         .subscribe(() => {
            //tell the new task that I am open
            this.broadcastTaskOpen();
         });
      this.task = task;
      this.broadcastTaskOpen();
      this.loadingBar = false;
   }

   close = (): void => {
      this.modalInstance.close();
   };

   public broadcastTaskOpen() {
      if (this.data === undefined) {
         console.warn("data is not defined");
         return;
      }
      this.manageTask.broadcastTaskOpen(this.data.checklistID, this.currentUser.gUserID);
   }

   public broadcastTaskClosed() {
      if (this.data === undefined) {
         // Don't throw an error because a task can be closed before the data is defined.
         console.warn("data is not defined");
         return;
      }
      this.manageTask.broadcastTaskClosed(
         this.data.checklistID,
         this.currentUser.gUserID,
      );
   }

   public ngOnDestroy(): void {
      this.broadcastTaskClosed();
      if (this.closeModalFunc) {
         this.closeModalFunc();
      }
      if (this.getAllOpenTasksSubscription !== undefined) {
         this.getAllOpenTasksSubscription.unsubscribe();
      }
   }

   /**
    * This function is to catch when the page is closed/refreshed, in which case the ngOnDestroy
    * does not run but we still need to broadcast that we closed the task
    */
   @HostListener("window:beforeunload")
   public beforeUnloadHandler(): void {
      this.broadcastTaskClosed();
   }
}
