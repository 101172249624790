<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title()" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <label class="decline-request-header control-label medium-spacing">{{
            declineRequest()
         }}</label>
         <lim-ui-alert [alertType]="'info'" class="alert-banner">
            <span [limbleHtml]="declineRequestMessage()"></span>
         </lim-ui-alert>
         <label class="control-label medium-spacing secondary-text"
            >{{ reason() }}<span class="red-color"> *</span></label
         >
         <div class="dropdown-wrapper">
            <lim-ui-dropdown>
               <ng-container button>
                  <button
                     class="dropdown-button-style"
                     [ngClass]="{ 'has-error': selectedReasonError() }"
                  >
                     @if (selectedReason()) {
                        <div [limbleHtml]="selectedReason()?.reason"></div>
                     } @else {
                        <span>{{ reason() }}</span>
                     }
                     <lim-ui-icon icon="angleDown" iconSize="small" />
                  </button>
               </ng-container>
               <div menu class="dropdown-menu">
                  @for (reason of rejectedReasons(); track reason.reasonID) {
                     <lim-ui-dropdown-item
                        class="dropdown-item"
                        (click)="selectReason(reason)"
                     >
                        {{ reason.reason }}
                     </lim-ui-dropdown-item>
                  }
               </div>
            </lim-ui-dropdown>
         </div>
         <label class="control-label medium-spacing secondary-text">{{
            message()
         }}</label>
         <div class="custom-reason-text-field-container">
            <div
               blurOnKeyboardClose
               class="medium-text-field custom-reason-text-field"
               type="text"
               [(ngModel)]="declineCustomReason"
               limbleContentEditable
            ></div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-secondary-button (click)="close()">{{
         backToRequest()
      }}</lim-ui-secondary-button>
      <lim-ui-danger-button (click)="submit()">{{
         declineRequestButton()
      }}</lim-ui-danger-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
