import type { PipeTransform } from "@angular/core";
import { Pipe } from "@angular/core";
/*
 * Takes a time object and returns the first and last name of the user who created that time object.
 */
@Pipe({ name: "timeCreatedByName", standalone: true })
export class TimeCreatedByNamePipe implements PipeTransform {
   public transform(time: any) {
      if (time.createdByUserFullName) {
         return time.createdByUserFullName;
      }
      return "";
   }
}
