<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header id="confirmHeader" [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body id="confirmHolder">
      <lim-ui-info-panel>
         <div class="icon-and-message">
            @if (icon) {
               <span> {{ lang().ResponsesCurrentlyLoggedTo }}:</span>
            }
            @if (icon) {
               <lim-ui-icon [icon]="icon" />
            }
            <span [limbleHtml]="message"></span>
         </div>
      </lim-ui-info-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer id="confirmFooter">
      <div class="question-modal-footer-content">
         <lim-ui-secondary-button (click)="submit()">
            <span [limbleHtml]="yesButton"></span>
         </lim-ui-secondary-button>
         <lim-ui-primary-button (click)="close()">
            <span [limbleHtml]="noButton"></span>
         </lim-ui-primary-button>
         <lim-ui-secondary-button (click)="dismiss()">
            <span [limbleHtml]="lang().Cancel"></span>
         </lim-ui-secondary-button>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
