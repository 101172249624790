import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
   signal,
} from "@angular/core";
import { TranslateService } from "src/app/languages/i18n/translate.service";
import { ButtonComponent } from "src/app/shared/empowered/base/button/button.component";
import { MenuComponent } from "src/app/shared/empowered/base/menus/menu/menu.component";
import type { MenuItem } from "src/app/shared/empowered/base/menus/menus.models";
import { TaskStatusApiService } from "src/app/tasks/components/shared/services/task-statuses-api/task-status-api.service";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade";

@Component({
   selector: "status-button",
   templateUrl: "./status-button.component.html",
   imports: [MenuComponent, ButtonComponent],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusButtonComponent {
   private readonly tasksFacade = inject(TasksFacadeService);
   private readonly taskStatusesApiService = inject(TaskStatusApiService);
   protected readonly t = inject(TranslateService);

   /** The task ID */
   taskId = input.required<number>();

   /** The status ID */
   statusId = input<number>();

   /** The location ID */
   locationId = input.required<number>();

   /** Whether the task is completed */
   isTaskCompleted = input<boolean>(false);

   /** Whether the user is a super user */
   isSuperUser = input<boolean>(false);

   /** Whether to display the full status name */
   showFullName = input<boolean>(false);

   /** The selected status ID - using a writable signal */
   private readonly selectedStatusId = signal<number | null>(null);

   statusesQuery = this.taskStatusesApiService.queryList();
   statuses = computed(() => this.statusesQuery.data() ?? []);

   buttonSize = computed(() => (this.showFullName() ? "small" : "extra-small"));
   menuItems = computed<MenuItem[]>(() => {
      const items: MenuItem[] = this.statuses()
         .sort((a, b) => a.sortOrder - b.sortOrder)
         .map(({ statusID, name, description }) => ({
            label: this.t.instant(name),
            value: statusID,
            tooltip: this.t.instant(description),
            command: () => {
               const oldStatusId = this.statusId();
               if (oldStatusId === null) {
                  return;
               }

               // Update the selected status ID signal
               this.selectedStatusId.set(statusID);

               this.tasksFacade.updateTaskStatusId(
                  this.taskId() ?? 0,
                  this.locationId() ?? 0,
                  statusID,
                  oldStatusId ?? 0,
               );
            },
            disabled: this.isTaskCompleted(),
         }));
      // Add "Edit Statuses" option for super users
      if (this.isSuperUser()) {
         items.push({
            separator: true,
         });
         items.push({
            label: this.t.instant("EditStatuses"),
            command: () => {
               this.tasksFacade.editStatuses();
            },
         });
      }

      return items;
   });

   selectedStatusLabel = computed(() => {
      // Use the selectedStatusIdSignal or fall back to statusId if not set
      const currentStatusId = this.selectedStatusId() ?? this.statusId();
      const status = this.statuses().find((s) => s.statusID === currentStatusId);

      if (this.showFullName()) {
         return status?.name ? this.t.instant(status.name) : "";
      }

      return status?.abbreviation;
   });

   public constructor() {
      // Initialize the selectedStatusIdSignal with the initial statusId
      this.selectedStatusId.set(this.statusId() ?? null);
   }
}
