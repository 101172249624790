import type { OnDestroy, OnInit, Signal } from "@angular/core";
import { Component, computed, inject, Input, signal } from "@angular/core";
import {
   CheckboxComponent,
   IconComponent,
   LoadingAnimationComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { AlertService } from "src/app/shared/services/alert.service";
import type { RequestParam } from "src/app/shared/services/flannel-api-service";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { SetupWorkOrder } from "src/app/tasks/components/setupWorkOrderModal/setupWorkOrder.modal.component";
import { TasksPanelComponent } from "src/app/tasks/components/shared/components/tasks-panel/tasks-panel.component";
import { TasksPanelComponentLegacy } from "src/app/tasks/components/shared/components/tasks-panel-legacy/tasks-panel-legacy.component";
import { TaskEntityType } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ViewListOfTasks } from "src/app/tasks/components/viewListOfTasksElement/viewListOfTasks.element.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

type AssetCreds = {
   viewManageAssets: boolean;
   editAssetSettings: boolean;
};

@Component({
   selector: "asset-work-order-tab",
   templateUrl: "./asset-work-order-tab.component.html",
   styleUrls: ["./asset-work-order-tab.component.scss"],
   imports: [
      IconComponent,
      PanelComponent,
      PrimaryButtonComponent,
      CheckboxComponent,
      TooltipDirective,
      ViewListOfTasks,
      LoadingAnimationComponent,
      SearchBoxComponent,
      TasksPanelComponent,
      TasksPanelComponentLegacy,
   ],
})
export class AssetWorkOrderTabComponent implements OnInit, OnDestroy {
   private readonly modalService = inject(ModalService);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly credService = inject(CredService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageUser = inject(ManageUser);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUtil = inject(ManageUtil);
   private readonly paramsService = inject(ParamsService);
   private readonly launchFlagsService = inject(LaunchFlagsService);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageParts = inject(ManageParts);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageLang = inject(ManageLang);
   protected readonly isEmpoweredTables: Signal<boolean> =
      this.launchFlagsService.getFlag("empowered-tables", false);

   @Input() assetID: number = 0;
   @Input() public restrict: boolean = false;
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public creds: AssetCreds = {
      viewManageAssets: false,
      editAssetSettings: false,
   };
   public asset: Asset | undefined;
   public currentUser;
   public taskExtraData: { assign?: boolean; assetViewing?: number } = {};
   public locationID;
   public sortBind;
   public sortBindOpen;

   public tasks: TaskLookup = new Lookup("checklistID");
   public openTasksFiltered: TaskLookup = new Lookup("checklistID");

   public searchOpenWOs: string = "";
   public noSearchResults;
   public noSearchResultsOpenWOs: boolean = false;
   public showLoading;
   public showLoadingWO;
   public tasksWatchVarSub;

   public exportTasksCred;
   public page: any = 1;
   public itemsPerPage;
   public openWOColumns;

   public assetView: {
      woTile: { color: number; count: number };
      showTemplates: boolean;
      showOpen: boolean;
   } = {
      woTile: { color: 0, count: 0 },
      showTemplates: false,
      showOpen: false,
   };
   public openTaskSearchHints: LimbleMap<number, string> = new LimbleMap();
   public searchHints: LimbleMap<number, string> = new LimbleMap();
   public taskIDs: number[] = [];
   public canExportCompletedTasks = false;
   public completedTasksRequestFilters = signal([] as Array<RequestParam>);

   public ngOnInit() {
      this.currentUser = this.manageUser.getCurrentUser();
      this.asset = this.manageAsset.getAsset(this.assetID);
      assert(this.asset);

      this.itemsPerPage =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage || 10;

      this.canExportCompletedTasks =
         !this.restrict &&
         this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.ExportTasks,
         );

      this.exportTasksCred = this.credService.isAuthorized(this.asset.locationID, 181);
      this.creds.viewManageAssets = this.credService.isAuthorized(
         this.asset.locationID,
         39,
      );

      this.creds.editAssetSettings = this.credService.isAuthorized(
         this.asset.locationID,
         68,
      );

      //default variables for viewing an open task
      this.taskExtraData.assign = true;
      this.taskExtraData.assetViewing = this.asset.assetID;

      this.locationID = this.asset.locationID;
      this.sortBind = "checklistName";
      this.sortBindOpen = "checklistDueDate";

      this.tasksWatchVarSub = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.buildOpenWOsData();
         },
      );

      this.openWOColumns = [
         {
            key: "checklistName",
            displayName: this.lang().Name,
            sortBy: "checklistName",
            columnWidth: 6,
         },
         {
            key: "checklistDueDate",
            displayName: this.lang().Due,
            sortBy: "checklistDueDate",
            columnWidth: 3,
         },
         {
            key: "assignedTo",
            displayName: this.lang().AssignedTo,
            sortBy: "assignment",
            columnWidth: 3,
         },
      ];
      // Initialize the completed work orders section
      this.completedTasksRequestFilters.set([
         {
            statuses: [2],
            locationIDs: [this.locationID],
            assetIDs: [this.assetID],
            taskTypes: [
               TaskEntityType.UnplannedWorkOrders,
               TaskEntityType.PlannedWorkOrders,
               TaskEntityType.WorkRequests,
            ],
         },
      ]);
      this.canExportCompletedTasks =
         !this.restrict &&
         this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.ExportCompletedTasks,
         );
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([this.tasksWatchVarSub]);
   }

   protected buildOpenWOsData(): void {
      if (!this.asset) return;
      this.assetView.woTile = this.manageFilters.assetTileOpenWorkOrders(
         this.manageTask.getTasks(),
         this.asset.assetID,
      );

      let tasks = this.manageTask.getTasks();

      if (this.asset.includeChildData == 1) {
         const children = this.manageAsset.findChildrenIDs(this.asset, []);
         const tempAssetIDs: any = [];
         for (const child of children) {
            tempAssetIDs.push(child);
         }
         tempAssetIDs.push(this.asset.assetID);
         tasks = this.manageFilters.filterTasksByAssetIDs(tasks, tempAssetIDs);
      } else {
         tasks = this.manageFilters.filterTasksByAssetIDs(tasks, [this.asset.assetID]);
      }

      tasks = this.manageFilters.filterTasksByChecklistTemplateOld(tasks, [2, 4]);

      tasks = tasks.filter(
         (task) => task.checklistTemplate === 0 && task.checklistStatusID === 0,
      );

      this.tasks = tasks;
      this.buildFilteredOpenWOsData();
   }

   protected buildFilteredOpenWOsData(): void {
      let filteredTasks = this.tasks;
      this.noSearchResultsOpenWOs = false;

      if (this.searchOpenWOs !== undefined && this.searchOpenWOs.length > 0) {
         const filteredTaskInfo = this.manageFilters.filterTasksToSearch(
            filteredTasks,
            this.searchOpenWOs,
            this.manageTask,
            this.manageLocation,
            this.manageUser,
            this.manageUtil,
            this.manageAsset,
            this.manageInvoice,
            this.manageParts,
            this.manageSchedule,
         );
         filteredTasks = filteredTaskInfo.tasks;
         this.openTaskSearchHints = filteredTaskInfo.searchHints;
         if (filteredTasks.size === 0) {
            this.noSearchResultsOpenWOs = true;
         }
      }
      this.openTasksFiltered = filteredTasks;
      this.taskIDs = Array.from(this.openTasksFiltered).map((task) => task.checklistID);
   }

   public newWO(): void {
      assert(this.asset);
      if (!this.credService.isAuthorized(this.asset.locationID, 43)) {
         this.alertService.addAlert(this.lang().cred43Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(SetupWorkOrder);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().SetupWorkOrderMsg,
            title: this.lang().SetupWorkOrder,
            data: {
               WO: true,
               assetID: this.asset.assetID,
               locationID: this.asset.locationID,
            },
         },
      };

      instance.result.then(async (data) => {
         if (data) {
            this.showLoading = true;

            const goLiveWOResult = await this.manageTask.goLiveWorkOrder(
               data.WO,
               data.profileID,
               data.userID,
               data.multiUsers,
               data.timestamp,
               0,
               data.timeOfDay,
               data.startDate,
               data.startDateTimeOfDay,
               this.manageUser,
               this.manageProfile,
            );
            if (!this.asset) return;
            this.showLoading = false;
            if (!goLiveWOResult?.data.success) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               return;
            }
            const userID = goLiveWOResult.data.task.userID;
            const profileID = goLiveWOResult.data.task.profileID;

            //after it goes live update the open work orders number
            this.assetView.woTile = this.manageFilters.assetTileOpenWorkOrders(
               this.manageTask.getTasks(),
               this.asset.assetID,
            );

            const currentUser = this.manageUser.getCurrentUser();
            const profiles = currentUser.profileLoc;
            let assignedToProfileIBelongTo = false;
            for (const profile of profiles) {
               if (
                  profile.profileID == profileID &&
                  profile.locationID == this.asset.locationID
               ) {
                  assignedToProfileIBelongTo = true;
               }
            }

            if (userID == currentUser.gUserID || assignedToProfileIBelongTo) {
               //open the task for them since they gave it to themselves

               const instance2 = this.modalService.open(PopTask);
               this.paramsService.params = {
                  modalInstance: instance2,
                  resolve: {
                     data: {
                        checklistID: data.WO.task.checklistID,
                        editable: true,
                     },
                  },
               };

               this.alertService.addAlert(
                  this.lang().successMsgWorkOrderStarted,
                  "success",
                  5000,
               );
            } else {
               this.alertService.addAlert(
                  this.lang().WorkOrderSuccessfullyStartedAndNotify,
                  "success",
                  5000,
               );
            }
         }
      });
   }

   public downloadExcel(tasks): void {
      this.showLoadingWO = true;
      this.manageTask.downloadExcel(tasks).then(() => {
         this.showLoadingWO = false;
      });
   }
}
