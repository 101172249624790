import { inject, Injectable } from "@angular/core";
import { ManageUser } from "src/app/users/services/manageUser";

type User = {
   userInfo: {
      userUIPreferences: {
         itemsPerPage: number;
      };
   };
};

@Injectable({
   providedIn: "root",
})
export class PaginationSettingsService {
   private readonly DEFAULT_PAGE_SIZE = 50;

   public getPageSize(currentUser?: User): number {
      let user = currentUser;

      // If the page size is not set, get it from the user preferences
      if (!user) {
         const manageUser = inject(ManageUser);
         user = manageUser.getCurrentUser();
      }
      return user?.userInfo.userUIPreferences.itemsPerPage ?? this.DEFAULT_PAGE_SIZE;
   }
}
