@if (
   fieldValue && vendor && (creds?.viewManageVendors || fieldValue.viewableByTech == 1)
) {
   <div
      class="lim-ui-list-item"
      [ngClass]="{
         'locked-field': fieldLocked,
      }"
   >
      <div class="lim-ui-list-item-content">
         @if (creds?.configureVendorFields && vendor.vendorDeleted == 0 && !restrict) {
            <span class="tree-icon-action">
               @if (treeBranch) {
                  <lim-ui-icon
                     icon="arrowsUpDownLeftRightRegular"
                     iconSize="small"
                     [limbleTreeDraggable]="treeBranch"
                  />
               }
               <span>{{ fieldValue.valueSort }}</span>
            </span>
         }
         <div class="info-item-label-wrapper">
            <div class="icon-with-text">
               @if (
                  fieldInfo?.fieldTypeID != 6 &&
                  valueExtraInfo &&
                  valueExtraInfo.fieldTypeIcon
               ) {
                  <lim-ui-icon
                     [icon]="valueExtraInfo.fieldTypeIcon | iconAlias"
                     iconSize="small"
                     limUiTooltip="{{ valueExtraInfo?.fieldTypeHint }}"
                     placement="right"
                  />
               }
               @if (fieldInfo?.fieldTypeID == 6) {
                  <span
                     limUiTooltip="{{ valueExtraInfo?.fieldTypeHint }}"
                     placement="right"
                  >
                     @if (currencyCode()) {
                        {{ currencyCode() | currencySymbol }}
                     } @else {
                        {{ currencySymbol }}
                     }
                  </span>
               }
               <span [limbleHtml]="fieldInfo?.fieldName"></span>
               @if (fieldInfo?.lockedDefault) {
                  <lim-ui-icon
                     icon="lockRegular"
                     iconSize="small"
                     [limUiTooltip]="lang().FieldLockdedTooltip"
                     placement="right"
                     iconColor="medium-grey"
                  />
               }
            </div>

            <lim-ui-dropdown class="lim-ui-show-on-mobile">
               <lim-ui-minimal-icon-button
                  button
                  icon="ellipsisVertical"
                  iconSize="small"
               />
               <div class="mobile-item-options-dropdown" menu>
                  @if (creds.createAndDeleteVendorFields) {
                     <lim-ui-dropdown-item (click)="removeField()">
                        <lim-ui-icon
                           class="dropdown-option-icon"
                           icon="trashCanRegular"
                           iconSize="medium"
                           iconColor="danger"
                        />
                        {{ lang().RemoveThisField }}
                     </lim-ui-dropdown-item>
                  }
                  @if (creds.configureVendorFields) {
                     <lim-ui-dropdown-item
                        (click)="updateFieldViewableByTech(fieldValue)"
                     >
                        <lim-ui-icon
                           class="dropdown-option-icon"
                           [icon]="
                              fieldValue.viewableByTech == 1 ? eyeIcon : eyeSlashIcon
                           "
                           iconSize="medium"
                        />
                        {{ lang().ShowHideField }}
                     </lim-ui-dropdown-item>
                  }
               </div>
            </lim-ui-dropdown>
         </div>
         <div
            [ngClass]="{
               'growing-input-and-buttons-container': fieldInfo?.fieldTypeID == 1,
            }"
            class="input-and-buttons-container"
         >
            <!--Text Type-->
            @if (fieldInfo?.fieldTypeID == 1) {
               <div
                  class="item-information-container"
                  limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
               >
                  <div>
                     <div
                        class="input-control updateFieldHolder exitOnEnterKeyPress text-input"
                        [ngClass]="{
                           'editUnderline':
                              creds?.changeVendorFieldsValues &&
                              vendor.vendorDeleted == 0 &&
                              !restrict,
                           'red-border':
                              creds?.changeVendorFieldsValues &&
                              !$any(fieldValue.valueContent)?.length,
                        }"
                        [(ngModel)]="fieldValue.valueContent"
                        (afterEdit)="setFieldValue()"
                        [limbleContentEditable]="
                           creds.changeVendorFieldsValues &&
                           vendor.vendorDeleted == 0 &&
                           !restrict &&
                           !fieldLocked
                        "
                        stripHtml="true"
                        [hidden]="!(vendor.vendorDeleted == 0 && !restrict)"
                     ></div>
                     <!-- eslint-disable template/no-any -- No reason provided. This is a legacy disable. -->
                     @if (vendor.vendorDeleted == 1 || restrict) {
                        <div [limbleHtml]="$any(fieldValue.valueContent ?? '')"></div>
                     }
                     <!-- eslint-enable template/no-any-->
                  </div>
               </div>
            }

            <!--Date Type-->
            @if (fieldInfo?.fieldTypeID == 2) {
               <div
                  class="item-information-container"
                  limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
               >
                  @if (
                     creds?.changeVendorFieldsValues &&
                     vendor.vendorDeleted == 0 &&
                     !restrict &&
                     !fieldLocked
                  ) {
                     <lim-ui-date-picker-input
                        [(model)]="fieldValue.valueContent"
                        (modelChange)="setFieldValue()"
                     />
                  }

                  @if (
                     !creds.changeVendorFieldsValues ||
                     vendor.vendorDeleted == 1 ||
                     restrict ||
                     fieldLocked
                  ) {
                     <div>
                        {{ fieldValue.valueContent | betterDate: "date" }}
                     </div>
                  }

                  <div class="lim-ui-list-item-hover-button">
                     @if (
                        fieldValue.valueContent &&
                        showDateHint &&
                        (fieldInfo?.dateReminder1 != 0 ||
                           fieldInfo?.dateReminder2 != 0 ||
                           fieldInfo?.dateReminder3 != 0)
                     ) {
                        <lim-ui-minimal-icon-button
                           icon="bellRegular"
                           iconSize="small"
                           [superMinimal]="true"
                           limUiTooltip="{{ dateReminderHint }}"
                           placement="bottom"
                           (click)="showFieldOptions(fieldValue)"
                        />
                     }
                  </div>
               </div>
            }

            <!--Picture Type-->
            @if (fieldInfo?.fieldTypeID == 3) {
               <div>
                  @if (creds.changeVendorFieldsValues && !fieldLocked) {
                     <file-uploader
                        label="{{ lang().UploadPicture }}"
                        [uploadObject]="uploadObj"
                        icon="camera"
                        accept="images"
                     />
                  }

                  <div
                     class="images-container"
                     limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
                  >
                     @for (fileID of fieldValue?.vendorValueFileIDs; track fileID) {
                        <div class="img-holder-medium">
                           @if (allFiles?.get(fileID); as file) {
                              <editable-image
                                 [file]="file"
                                 [getURL]="fileGetURLs.get(fileID)"
                                 [permission]="
                                    creds.changeVendorFieldsValues && !fieldLocked
                                 "
                                 (delete)="deleteFile($event)"
                                 [edit]="openEditor"
                              />
                           }
                        </div>
                     }
                  </div>
               </div>
            }

            <!--File Type-->
            @if (fieldInfo?.fieldTypeID == 4) {
               <div
                  class="item-information-container flex-column"
                  limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
               >
                  @if (creds.changeVendorFieldsValues && !fieldLocked) {
                     <file-uploader
                        label="{{ lang().UploadDocument }}"
                        [uploadObject]="uploadObj"
                        icon="cloudArrowUp"
                        accept="all"
                     />
                  }

                  @if (fieldValue.vendorValueFileIDs.length) {
                     <div class="file-group word-break-all">
                        @for (fileID of fieldValue.vendorValueFileIDs; track fileID) {
                           <span>
                              @if (allFiles?.get(fileID); as file) {
                                 <view-file
                                    [file]="file"
                                    [permission]="
                                       creds?.changeVendorFieldsValues &&
                                       vendor.vendorDeleted == 0 &&
                                       !restrict &&
                                       !fieldLocked
                                    "
                                    [getURL]="fileGetURLs.get(fileID)"
                                    [delete]="deleteFile"
                                 />
                              }
                           </span>
                        }
                     </div>
                  }
               </div>
            }

            <!--Number Type-->
            @if (fieldInfo?.fieldTypeID == 5) {
               <div
                  class="item-information-container"
                  limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
               >
                  @if (
                     creds.changeVendorFieldsValues &&
                     vendor.vendorDeleted == 0 &&
                     !restrict &&
                     !fieldLocked
                  ) {
                     <input
                        type="number"
                        min="0"
                        [(ngModel)]="fieldValue.valueContent"
                        (blur)="changeNumberField()"
                        onclick="this.focus();"
                        class="input-control"
                     />
                  }

                  @if (
                     !creds.changeVendorFieldsValues ||
                     vendor.vendorDeleted == 1 ||
                     restrict ||
                     fieldLocked
                  ) {
                     <div>
                        {{ fieldValue.valueContent }}
                     </div>
                  }
               </div>
            }

            <!--Currency Type-->

            @if (fieldInfo?.fieldTypeID == 6) {
               <div
                  class="item-information-container lim-ui-input-icon-container"
                  limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
               >
                  @if (
                     creds.changeVendorFieldsValues &&
                     vendor.vendorDeleted == 0 &&
                     !restrict &&
                     !fieldLocked
                  ) {
                     @if (currencyCode()) {
                        <lim-ui-input-with-prefix
                           [prefixText]="currencyCode() | currencySymbol"
                           [type]="'number'"
                           [value]="
                              fieldValue.valueContent !== null
                                 ? fieldValue.valueContent.toString()
                                 : null
                           "
                           (valueChange)="changeCurrencyField($event)"
                           [min]="0"
                        />
                     } @else {
                        <lim-ui-input-with-prefix
                           [prefixText]="currencySymbol"
                           [type]="'number'"
                           [value]="
                              fieldValue.valueContent !== null
                                 ? fieldValue.valueContent.toString()
                                 : null
                           "
                           (valueChange)="changeCurrencyField($event)"
                           [min]="0"
                        />
                     }
                  }

                  @if (
                     !creds.changeVendorFieldsValues ||
                     vendor.vendorDeleted == 1 ||
                     restrict ||
                     fieldLocked
                  ) {
                     <div>
                        {{ fieldValue.valueContent }}
                     </div>
                  }
               </div>
            }

            <!--Dropdown Type-->
            @if (fieldInfo?.fieldTypeID == 7) {
               <div
                  class="item-information-container"
                  limUiTooltip="{{ fieldLocked ? lang().FieldLockdedTooltip : null }}"
               >
                  <!-- eslint-disable template/no-any -- No reason provided. This is a legacy disable. -->
                  @if (!fieldLocked) {
                     <lim-ui-form-dropdown-input
                        [inputString]="$any(fieldValue.valueContent)"
                        [disabled]="
                           !creds.changeVendorFieldsValues ||
                           vendor.vendorDeleted != 0 ||
                           restrict
                        "
                     >
                        <ng-container menu>
                           @for (name of valueExtraInfo?.json; track name) {
                              <lim-ui-dropdown-item
                                 (click)="changeSelectedNameField(name)"
                              >
                                 <span [limbleHtml]="name"></span>
                              </lim-ui-dropdown-item>
                           }
                        </ng-container>
                     </lim-ui-form-dropdown-input>
                  }
                  @if (fieldLocked) {
                     <span>{{ fieldValue.valueContent }}</span>
                  }
                  <!-- eslint-enable template/no-any-->
               </div>
            }
            @if (vendor.vendorDeleted == 0 && !restrict) {
               <div class="item-action lim-ui-hide-on-mobile">
                  <div class="lim-ui-list-item-hover-buttons">
                     @if (creds.configureVendorFields) {
                        <lim-ui-minimal-icon-button
                           [icon]="
                              fieldValue.viewableByTech == 1 ? eyeIcon : eyeSlashIcon
                           "
                           iconSize="small"
                           [superMinimal]="true"
                           limUiTooltip="{{ lang().ChangeDefaultShowHideVendorCard }}"
                           placement="top"
                           (click)="updateFieldViewableByTech(fieldValue)"
                        />
                     }

                     @if (creds.createAndDeleteVendorFields && !fieldLocked) {
                        <lim-ui-minimal-icon-button
                           icon="trashCanRegular"
                           iconSize="small"
                           iconColor="danger"
                           [superMinimal]="true"
                           limUiTooltip="{{ lang().RemoveThisField }}"
                           placement="top"
                           (click)="removeField()"
                        />
                     }
                  </div>
               </div>
            }
         </div>
      </div>
   </div>
}
