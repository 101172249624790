import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DangerButtonComponent,
   DatePickerInputComponent,
   DropdownComponent,
   DropdownItemComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import moment from "moment";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import { UserImage } from "src/app/files/components/userImage/userImage.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ParamsService } from "src/app/shared/services/params.service";
import { CalendarNotesStoreService } from "src/app/tasks-analytics/calendar/calendar-notes/calendar-notes-store.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "edit-calendar-note",
   templateUrl: "./editCalendarNote.modal.component.html",
   styleUrls: ["./editCalendarNote.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      ContenteditableDirective,
      FormsModule,
      DropdownComponent,
      SecondaryButtonComponent,
      IconComponent,
      DropdownItemComponent,
      DatePickerInputComponent,
      NgClass,
      TooltipDirective,
      SelectionControlsComponent,
      UserImage,
      ModalFooterComponent,
      DangerButtonComponent,
      PrimaryButtonComponent,
      OrderByPipe,
   ],
})
export class EditCalendarNote implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public calendarNoteID;
   public text: string | null = null;
   public errorMsg;
   public SubmitBtnTxt;
   public color;
   public oldColor;
   public colors;
   public dt1;
   public dt2;
   public users;
   public anyone;
   public colorDisplay;

   private readonly modalService = inject(ModalService);
   private readonly manageDashboard = inject(ManageDashboard);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);
   private readonly calendarNotesStore = inject(CalendarNotesStoreService);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      const data = this.resolve.data;
      this.message = this.resolve.data.message;
      this.title = this.resolve.data.title;
      //if calendar noteID is 0 that means we are adding a new calendar note
      this.calendarNoteID = this.resolve.data.calendarNoteID;
      if (this.resolve.data.text && typeof this.resolve.data.text === "string") {
         this.text = this.resolve.data.text;
      }

      this.errorMsg = "";

      if (this.calendarNoteID == 0) {
         this.SubmitBtnTxt = this.lang().Add;
         this.color = "663399";
      } else {
         this.SubmitBtnTxt = this.lang().Save;
         this.calendarNotesStore.get(data.calendarNoteID).then((note) => {
            if (note !== undefined) {
               this.color = note.getColor();
               this.text = note.getTitle();
            }
         });
      }
      this.oldColor = this.color;

      this.colors = this.manageDashboard.colorSets().arr;

      let index = this.colors.length;
      while (index--) {
         const color = this.colors[index];
         if (color.name === "Green" || color.name === "Red" || color.name === "Orange") {
            this.colors.splice(index, 1);
         }
      }

      this.dt1 = this.getNoteDateValue(data.noteDate);
      this.dt2 = this.getNoteDateValue(data.noteDateEnd);

      const answer = this.manageUser.getUsersAndProfilesAtLocation(
         data.locationID,
      ) as any;

      //only get one instance of each user object - there is one for each location coming in
      this.users = answer.data.users.filter(
         (filterUser, indexF, usersArr) =>
            usersArr.findIndex((findUser) => findUser.userID === filterUser.userID) ===
            indexF,
      );

      if (this.calendarNoteID == 0) {
         for (const user of this.users) {
            user.selected = true;
         }
         this.anyone = true;
      } else {
         this.calendarNotesStore.get(this.calendarNoteID).then((calendarNote) => {
            if (calendarNote === undefined) return;
            if (calendarNote.getUsers().length === 0) {
               //no relations are set there fore it should apply to everyone...
               this.anyone = true;
               for (const user of this.users) {
                  user.selected = true;
               }
            } else {
               for (const user of this.users) {
                  user.selected = false;
                  if (calendarNote.getUsers().includes(user.userID)) {
                     user.selected = true;
                  }
               }
               this.anyone = false;
            }
         });
      }
   }

   selectColor = (color) => {
      this.color = color.colorCode.replace("#", "").substring(0, 6);
      this.colorDisplay = `#${color.colorCode}`;
      this.oldColor = this.color;
   };

   validateColor = () => {
      this.color = this.color.replace("#", "");
      this.color = this.color.substring(0, 6);
   };

   focusUser = (user) => {
      user.selected = !user.selected;
      this.anyone = false;
   };

   close = () => {
      this.modalInstance.close(0);
   };

   public async deleteNote(): Promise<void> {
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().WouldYouLikeToRemoveTheNote,
            title: this.lang().WouldYouLikeToRemoveTheNote,
         },
      };
      const result = await instance.result;
      if (result != 1) return;
      this.modalInstance.close(1);
   }

   setAnyone = () => {
      this.anyone = !this.anyone;
      if (this.anyone == true) {
         for (const key in this.users) {
            this.users[key].selected = true;
         }
      }
   };

   public deslectAllusers() {
      this.anyone = false;
      for (const user of this.users) {
         user.selected = false;
      }
   }

   submit = () => {
      this.errorMsg = false;
      const result: any = {};

      if (this.text === null || this.text.length === 0) {
         this.errorMsg = this.lang().PleaseEnterANote;
         return;
      }

      result.text = this.text;

      if (this.dt1 == null) {
         this.errorMsg = this.lang().PleaseSelectADate;
         return;
      }

      let noteDateEnd;

      const noteDate = moment(this.dt1).startOf("day").format("X");

      if (this.dt2 == null) {
         noteDateEnd = 0;
      } else {
         noteDateEnd = moment(this.dt2).startOf("day").format("X");
      }

      result.noteDate = noteDate;
      result.noteDateEnd = noteDateEnd;
      result.color = this.color;

      const users: Array<number> = [];

      if (!this.anyone) {
         for (const key in this.users) {
            if (this.users[key].selected == true) {
               users.push(this.users[key].userID);
            }
         }
         if (users.length == 0) {
            this.errorMsg = this.lang().PleaseSelectAUser;
            return;
         }
      }

      result.users = users;

      this.modalInstance.close(result);
   };

   private getNoteDateValue(date) {
      return date > 0 ? new Date(date * 1000) : null;
   }
}
