import { computed, type Signal } from "@angular/core";
import type {
   ConditionallyWritableStringSignal,
   UnitParams,
} from "src/app/parts/unit-of-measure/unit.params";
import { UnitCategories } from "src/app/parts/unit-of-measure/unit.types";

export abstract class UnitBase<IsEverySignalWritable extends boolean = false> {
   public readonly id: number;
   public readonly category: string;
   public readonly singular: ConditionallyWritableStringSignal<IsEverySignalWritable>;
   public readonly short: ConditionallyWritableStringSignal<IsEverySignalWritable>;
   public readonly formatted: Signal<string>;
   protected readonly conversionFactorToSIUnit: number;
   public readonly isMeasurementUnit: boolean;

   public constructor(params: UnitParams<IsEverySignalWritable>) {
      this.id = params.id;
      this.category = params.category;
      this.singular = params.singular;
      this.short = params.short;
      this.formatted = computed(() => `${params.short()} (${params.singular()})`);
      this.conversionFactorToSIUnit = params.conversionFactorToSIUnit;
      this.isMeasurementUnit = params.category !== UnitCategories.Count;
   }
}
