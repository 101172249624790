<span>
   <lim-ui-icon icon="cube" iconColor="standard" />

   @if (assetID()) {
      <span
         (click)="viewAsset(); $event.stopPropagation()"
         class="text green"
         [limbleHtml]="assetID() ?? 0 | assetName"
      ></span>

      <asset-parent-list [assetID]="assetID()" linkColorClass="task-sub-title" />
   }
</span>
