import { PartPurchaseOrderItemDtoSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/part-purchase-order-item.dto";
import { CustomStockUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/custom-stock-unit-schema";
import { ProvidedStockUnitSchema } from "src/app/purchasing/pos/part-purchase-order-item-dto/purchasables/utils/provided-stock-unit-schema";
import { z } from "zod";

const IndividualBaseSchema = PartPurchaseOrderItemDtoSchema.extend({
   purchasableSize: z.null(),
});

const ProvidedStockDtoSchema = IndividualBaseSchema.merge(ProvidedStockUnitSchema);

const CustomStockDtoSchema = IndividualBaseSchema.merge(CustomStockUnitSchema);

export const IndividualPurchasablePurchaseOrderItemDtoSchema = z.union([
   ProvidedStockDtoSchema,
   CustomStockDtoSchema,
]);

export type IndividualPurchasablePurchaseOrderItemDto = z.infer<
   typeof IndividualPurchasablePurchaseOrderItemDtoSchema
>;
