<lim-ui-modal [showWatermark]="true" [extraLarge]="true">
   <lim-ui-basic-modal-header
      [title]="lang().QRCodeCustomizePrint"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span
            class="message-area"
            [limbleHtml]="lang().qrCodesCustomizePrintInstructions"
         ></span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="qr-code-container scroll-height-inheritance">
            <div class="qr-code-form-container scroll-height-inheritance">
               <div class="grid-controls-container">
                  <div class="top-button-container">
                     <!-- Use Template -->
                     <lim-ui-dropdown>
                        <lim-ui-secondary-button
                           id="use-template-dropdown"
                           button
                           icon="noteSticky"
                        >
                           {{ lang().Use }} {{ lang().Template }}
                        </lim-ui-secondary-button>
                        <ng-container menu aria-labelledby="use-template-dropdown">
                           <lim-ui-dropdown-text-item
                              (click)="applySavedTemplate(defaultTemplate)"
                              [displayString]="lang().Default"
                              [outputString]="lang().Default"
                           />
                           @if (savedTemplates && savedTemplates.length) {
                              <lim-ui-dropdown-divider />
                           }
                           @for (template of savedTemplates; track template) {
                              <lim-ui-dropdown-item
                                 (click)="applySavedTemplate(template)"
                              >
                                 @if (template.userID != user.gUserID) {
                                    <a
                                       class="cursor custom-link"
                                       limUiTooltip="{{
                                          lang().TemplateBelongsToAnotherUserTooltip
                                       }}"
                                       placement="right"
                                    >
                                       {{ template.name }}
                                       <lim-ui-icon icon="user" />
                                    </a>
                                 }
                                 @if (template.userID == user.gUserID) {
                                    <a class="cursor custom-link">{{ template.name }}</a>
                                 }
                              </lim-ui-dropdown-item>
                           }
                           <lim-ui-dropdown-divider />

                           <lim-ui-dropdown-item (click)="startNewTemplate()">
                              <a class="cursor">
                                 <lim-ui-icon icon="plusRegular" />
                                 {{ lang().Start }} {{ lang().New }}
                                 {{ lang().Template }}
                              </a>
                           </lim-ui-dropdown-item>
                        </ng-container>
                     </lim-ui-dropdown>

                     <div class="add-copy-container">
                        @if (currentTemplate.templateID !== 0) {
                           <lim-ui-icon-button
                              limUiTooltip="{{ lang().StartANewTemplate }}"
                              icon="plusRegular"
                              (click)="startNewTemplate()"
                           />
                        }
                        @if (currentTemplate.templateID !== 0) {
                           <lim-ui-icon-button
                              limUiTooltip="{{ lang().templateCopyTooltip }}"
                              icon="cloneRegular"
                              (click)="copyTemplate()"
                           />
                        }

                        @if (
                           (!usingSavedTemplate && templateBelongsToUser) ||
                           currentTemplate.templateID === 0
                        ) {
                           <lim-ui-icon-button
                              icon="floppyDiskRegular"
                              (click)="saveNewTemplate()"
                              limUiTooltip="{{ lang().Save }} {{ lang().New }} {{
                                 lang().Template
                              }}"
                           />
                        }

                        @if (
                           usingSavedTemplate &&
                           templateBelongsToUser &&
                           currentTemplate.templateID !== 0
                        ) {
                           <lim-ui-icon-button
                              icon="floppyDiskRegular"
                              (click)="updateTemplate()"
                              limUiTooltip="{{ lang().Save }}"
                           />
                           <lim-ui-icon-button
                              icon="trashCanRegular"
                              iconColor="danger"
                              (click)="deleteTemplate()"
                              limUiTooltip="
                                 {{ lang().Delete }}"
                           />
                        }
                     </div>
                  </div>

                  @if (
                     usingSavedTemplate &&
                     !templateBelongsToUser &&
                     currentTemplate.templateID === 0
                  ) {
                     <label>{{ lang().Default }} {{ lang().Template }} </label>
                  }
                  @if (usingSavedTemplate && templateBelongsToUser) {
                     <label>{{ lang().YourTemplate }} </label>
                  }
                  @if (!usingSavedTemplate && templateBelongsToUser) {
                     <label>{{ lang().NewTemplate }} </label>
                  }
                  @if (
                     usingSavedTemplate &&
                     !templateBelongsToUser &&
                     currentTemplate.templateID !== 0
                  ) {
                     <label>{{ templateOwnerName }}'s {{ lang().Template }} </label>
                  }
               </div>

               <!-- Grid controls -->
               <div class="grid-controls-container">
                  <!-- Name -->
                  <div class="input-container">
                     <div class="input-title">{{ lang().Name }}</div>
                     <input
                        [disabled]="!templateBelongsToUser"
                        type="text"
                        [(ngModel)]="currentTemplate.name"
                        (ngModelChange)="unsavedChanges = true"
                        class="text-input form-control"
                     />
                  </div>
                  <!-- Printer type -->
                  <div class="input-container">
                     <div class="input-title">{{ lang().PrinterType }}</div>
                     <div class="dropdown-radio-button-input">
                        <lim-ui-radio-button
                           groupID="setPrinterTypeOptionsGroup"
                           label="{{ lang().Standard }}"
                           [checked]="currentTemplate.options.printerType === 'standard'"
                           [disabled]="!templateBelongsToUser"
                           (click)="
                              unsavedChanges = true;
                              currentTemplate.options.printerType = 'standard';
                              setPrinterTypeOptions('standard')
                           "
                        />

                        <lim-ui-radio-button
                           groupID="setPrinterTypeOptionsGroup"
                           label="{{ lang().Label }}"
                           [checked]="currentTemplate.options.printerType === 'dymo'"
                           [disabled]="!templateBelongsToUser"
                           (click)="
                              unsavedChanges = true;
                              currentTemplate.options.printerType = 'dymo';
                              setPrinterTypeOptions('dymo')
                           "
                        />
                     </div>
                  </div>
                  <!-- Paper Size -->
                  <div class="input-container">
                     <div class="input-title">
                        {{ lang().PaperSize }}
                     </div>

                     @if (currentTemplate.options.printerType === "standard") {
                        <lim-ui-form-dropdown-input
                           [disabled]="!templateBelongsToUser"
                           class="paper-size-template-dropdown"
                           [inputString]="currentTemplate.options.aspectRatio"
                        >
                           <div menu>
                              @for (size of paperSizes; track size) {
                                 <lim-ui-dropdown-item
                                    (click)="
                                       setAspectRatio(size, printPreviewContainer);
                                       updateGridTemplate('all', printPreviewContainer);
                                       unsavedChanges = true
                                    "
                                 >
                                    <span [limbleHtml]="size"> </span>
                                 </lim-ui-dropdown-item>
                              }
                           </div>
                        </lim-ui-form-dropdown-input>
                     }

                     @if (currentTemplate.options.printerType === "dymo") {
                        <lim-ui-form-dropdown-input
                           [disabled]="!templateBelongsToUser"
                           class="paper-size-template-dropdown"
                           inputString="{{
                              currentTemplate.options.aspectRatio === 'custom'
                                 ? currentTemplate.options.customSizeLabel
                                 : currentTemplate.options.aspectRatio
                           }}"
                        >
                           <div menu>
                              @for (size of dymoSizes; track size) {
                                 <lim-ui-dropdown-item
                                    (click)="
                                       setAspectRatio(size, printPreviewContainer);
                                       updateGridTemplate('all', printPreviewContainer);
                                       unsavedChanges = true
                                    "
                                 >
                                    <span [limbleHtml]="size"></span>
                                 </lim-ui-dropdown-item>
                              }
                              <lim-ui-dropdown-divider />
                              <lim-ui-dropdown-item>
                                 <input
                                    type="number"
                                    min="1"
                                    [(ngModel)]="currentTemplate.options.customWidth"
                                    class="dropdown-number-input-small form-control"
                                    (ngModelChange)="unsavedChanges = true"
                                    (click)="$event.stopPropagation()"
                                 />
                                 <span>x</span>
                                 <input
                                    type="number"
                                    min="1"
                                    [(ngModel)]="currentTemplate.options.customHeight"
                                    class="dropdown-number-input-small form-control"
                                    (ngModelChange)="unsavedChanges = true"
                                    (click)="$event.stopPropagation()"
                                 />
                              </lim-ui-dropdown-item>

                              <lim-ui-dropdown-text-item
                                 (click)="
                                    setAspectRatio('custom', printPreviewContainer);
                                    updateGridTemplate('all', printPreviewContainer);
                                    unsavedChanges = true
                                 "
                                 [displayString]="lang().Apply + ' ' + lang().Custom"
                              />
                           </div>
                        </lim-ui-form-dropdown-input>
                     }
                  </div>
                  <!-- Number of rows -->
                  @if (currentTemplate.options.printerType === "standard") {
                     <div class="input-container">
                        <div class="input-title">{{ lang().Rows }}</div>
                        <input
                           [disabled]="!templateBelongsToUser"
                           type="number"
                           min="1"
                           [(ngModel)]="currentTemplate.options.gridRows"
                           class="number-input form-control"
                           (ngModelChange)="
                              updateGridTemplate(
                                 'grid-template-rows',
                                 printPreviewContainer
                              );
                              unsavedChanges = true
                           "
                        />
                     </div>
                  }
                  <!-- Number of columns -->
                  @if (currentTemplate.options.printerType === "standard") {
                     <div class="input-container">
                        <div class="input-title">{{ lang().Columns }}</div>
                        <input
                           [disabled]="!templateBelongsToUser"
                           type="number"
                           min="1"
                           [(ngModel)]="currentTemplate.options.gridCols"
                           class="number-input form-control"
                           (ngModelChange)="
                              updateGridTemplate(
                                 'grid-template-columns',
                                 printPreviewContainer
                              );
                              unsavedChanges = true
                           "
                        />
                     </div>
                  }
                  <!-- Margins (padding on div) -->
                  <div class="input-container">
                     <div class="input-title">
                        <div>{{ lang().Top }} {{ lang().Margins }}</div>
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           limUiTooltip="{{ lang().PrintQRPaddingTooltip }}"
                           placement="right"
                        />
                     </div>
                     <input
                        [disabled]="!templateBelongsToUser"
                        type="number"
                        min="0"
                        [(ngModel)]="currentTemplate.options.topPadding"
                        class="number-input form-control"
                        (ngModelChange)="
                           updateGridTemplate('padding', printPreviewContainer);
                           unsavedChanges = true
                        "
                     />
                  </div>
                  <div class="input-container">
                     <div class="input-title">
                        <div>{{ lang().Bottom }} {{ lang().Margins }}</div>

                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           limUiTooltip="{{ lang().PrintQRPaddingTooltip }}"
                           placement="right"
                        />
                     </div>
                     <input
                        [disabled]="!templateBelongsToUser"
                        type="number"
                        min="0"
                        [(ngModel)]="currentTemplate.options.bottomPadding"
                        class="number-input form-control"
                        (ngModelChange)="
                           updateGridTemplate('padding', printPreviewContainer);
                           unsavedChanges = true
                        "
                     />
                  </div>
                  <div class="input-container">
                     <div class="input-title">
                        <div>{{ lang().Side }} {{ lang().Margins }}</div>
                        <lim-ui-icon
                           icon="circleQuestionRegular"
                           limUiTooltip="{{ lang().PrintQRPaddingTooltip }}"
                           placement="right"
                        />
                     </div>
                     <input
                        [disabled]="!templateBelongsToUser"
                        type="number"
                        min="0"
                        [(ngModel)]="currentTemplate.options.sidePadding"
                        class="number-input form-control"
                        (ngModelChange)="
                           updateGridTemplate('padding', printPreviewContainer);
                           unsavedChanges = true
                        "
                     />
                  </div>
                  <!-- Horizontal gap (gutter) -->
                  @if (currentTemplate.options.printerType === "standard") {
                     <div class="input-container">
                        <div class="input-title">
                           <div>{{ lang().Horizontal + " " + lang().Gap }}</div>

                           <lim-ui-icon
                              icon="circleQuestionRegular"
                              limUiTooltip="{{ lang().PrintQRHorizontalGutterTooltip }}"
                              placement="right"
                           />
                        </div>
                        <input
                           [disabled]="!templateBelongsToUser"
                           type="number"
                           min="0"
                           [(ngModel)]="currentTemplate.options.horizontalGap"
                           class="number-input form-control"
                           (ngModelChange)="
                              updateGridTemplate('row-gap', printPreviewContainer);
                              unsavedChanges = true
                           "
                        />
                     </div>
                  }
                  <!-- Vertical gap (gutter) -->
                  @if (currentTemplate.options.printerType === "standard") {
                     <div class="input-container">
                        <div class="input-title">
                           <div>{{ lang().Vertical + " " + lang().Gap }}</div>
                           <lim-ui-icon
                              icon="circleQuestionRegular"
                              limUiTooltip="{{ lang().PrintQRVerticalGutterTooltip }}"
                              placement="right"
                           />
                        </div>
                        <input
                           [disabled]="!templateBelongsToUser"
                           type="number"
                           min="0"
                           [(ngModel)]="currentTemplate.options.verticalGap"
                           class="number-input form-control"
                           (ngModelChange)="
                              updateGridTemplate('column-gap', printPreviewContainer);
                              unsavedChanges = true
                           "
                        />
                     </div>
                  }
                  <!-- Offset start by -->
                  @if (currentTemplate.options.printerType === "standard") {
                     <div class="input-container">
                        <div class="input-title">
                           <div>{{ lang().StartAt }}</div>
                           <lim-ui-icon
                              icon="circleQuestionRegular"
                              limUiTooltip="{{ lang().PrintQROffsetStartTooltip }}"
                              placement="right"
                           />
                        </div>
                        <input
                           [disabled]="!templateBelongsToUser"
                           type="number"
                           min="1"
                           [max]="itemsPerPage - 1"
                           [(ngModel)]="currentTemplate.options.offsetStart"
                           class="number-input form-control"
                           (ngModelChange)="buildPreview(); unsavedChanges = true"
                        />
                     </div>
                  }
                  <!-- Font Size  -->
                  <div class="input-container">
                     <div class="input-title">
                        {{ lang().FontSize }}
                     </div>
                     <lim-ui-form-dropdown-input
                        [disabled]="!templateBelongsToUser"
                        class="paper-size-template-dropdown"
                        [inputString]="currentTemplate.options.fontSize"
                     >
                        <div menu>
                           <lim-ui-dropdown-text-item
                              (click)="
                                 currentTemplate.options.fontSize = 'Auto';
                                 unsavedChanges = true
                              "
                              [displayString]="lang().Auto"
                           />
                           <lim-ui-dropdown-divider />
                           <lim-ui-dropdown-item [closeDropdownOnClick]="false">
                              <input
                                 type="number"
                                 min="0"
                                 [(ngModel)]="currentTemplate.options.fontSize"
                                 class="dropdown-number-input form-control"
                                 (ngModelChange)="unsavedChanges = true"
                                 (blur)="setDefaultFontSize()"
                              />
                           </lim-ui-dropdown-item>
                        </div>
                     </lim-ui-form-dropdown-input>
                  </div>
               </div>

               @if (dataType() === "task" || dataType() === "asset") {
                  <div class="checkbox-container">
                     <label>{{ lang().IncludedQRCodeTypes }}</label>

                     <!-- Asset QR type checkboxes -->
                     @if (dataType() === "asset") {
                        <lim-ui-checkbox
                           [label]="lang().ReportAProblem"
                           [(model)]="currentTemplate.options.includeSubmitWorkRequest"
                           (modelChange)="buildData(); unsavedChanges = true"
                           [disabled]="!templateBelongsToUser"
                           groupId="asset-qr-type-checkboxes"
                        />

                        <lim-ui-checkbox
                           [label]="lang().AssetInformation"
                           [(model)]="currentTemplate.options.includeAssetLink"
                           (modelChange)="buildData(); unsavedChanges = true"
                           [disabled]="!templateBelongsToUser"
                           groupId="asset-qr-type-checkboxes"
                        />
                     }
                     <!-- Task QR type checkboxes -->
                     @if (dataType() === "task") {
                        <lim-ui-checkbox
                           [label]="lang().StartTask"
                           [(model)]="currentTemplate.options.includeStartTask"
                           (modelChange)="buildData()"
                           [disabled]="!templateBelongsToUser"
                           groupId="task-qr-type-checkboxes"
                        />

                        <lim-ui-checkbox
                           [label]="lang().EnableOrDisablePMSchedule"
                           [(model)]="currentTemplate.options.includeFlipPMSchedule"
                           (modelChange)="buildData()"
                           [disabled]="!templateBelongsToUser"
                           groupId="task-qr-type-checkboxes"
                        />
                     }
                  </div>
               }
               @if (availableFields.length) {
                  <lim-ui-scroll-container [thin]="true">
                     <!-- Item field information checkboxes -->

                     <div class="fields-container">
                        <label>{{ lang().AvailableInformationFields }}</label>

                        @if (availableFields?.length >= 5 && templateBelongsToUser) {
                           <lim-ui-search-box
                              [placeholder]="lang().Search"
                              [(searchVal)]="fieldsSearchValue"
                              (searchValChange)="searchUpdateFields()"
                           />
                        }

                        <div class="checkbox-container">
                           @for (field of fieldsFilteredToSearch; track field) {
                              <lim-ui-checkbox
                                 [label]="field.fieldName"
                                 [(model)]="field.show"
                                 (modelChange)="updateDisplayFields()"
                                 [disabled]="!templateBelongsToUser"
                                 groupId="available-fields-qr-type-checkboxes"
                              />
                           }
                        </div>
                     </div>
                  </lim-ui-scroll-container>
               }

               <div class="add-copy-container-end">
                  @if (currentTemplate.templateID !== 0) {
                     <lim-ui-icon-button
                        limUiTooltip="{{ lang().StartANewTemplate }}"
                        icon="plusRegular"
                        (click)="startNewTemplate()"
                     />
                  }
                  @if (currentTemplate.templateID !== 0) {
                     <lim-ui-icon-button
                        limUiTooltip="{{ lang().templateCopyTooltip }}"
                        icon="cloneRegular"
                        (click)="copyTemplate()"
                     />
                  }

                  @if (
                     (!usingSavedTemplate && templateBelongsToUser) ||
                     currentTemplate.templateID === 0
                  ) {
                     <lim-ui-icon-button
                        icon="floppyDiskRegular"
                        (click)="saveNewTemplate()"
                        limUiTooltip="{{ lang().Save }} {{ lang().New }} {{
                           lang().Template
                        }}"
                     />
                  }

                  @if (
                     usingSavedTemplate &&
                     templateBelongsToUser &&
                     currentTemplate.templateID !== 0
                  ) {
                     <lim-ui-icon-button
                        icon="floppyDiskRegular"
                        (click)="updateTemplate()"
                        limUiTooltip="{{ lang().Save }}"
                     />
                     <lim-ui-icon-button
                        icon="trashCanRegular"
                        iconColor="danger"
                        (click)="deleteTemplate()"
                        limUiTooltip="
                              {{ lang().Delete }}"
                     />
                  }
               </div>
            </div>

            <!-- Print preview and print element container -->
            <div class="qr-code-preview-container">
               <div class="preview-options-container">
                  <div>
                     {{ buildDataArray.length }}
                     <span>
                        @switch (dataType()) {
                           @case ("task") {
                              <span>{{
                                 buildDataArray.length > 1 ? lang().Tasks : lang().Task
                              }}</span>
                           }
                           @case ("asset") {
                              <span>{{
                                 buildDataArray.length > 1 ? lang().Assets : lang().Asset
                              }}</span>
                           }
                           @case ("part") {
                              <span>{{
                                 buildDataArray.length > 1 ? lang().Parts : lang().Part
                              }}</span>
                           }
                        }
                     </span>
                     {{ lang().Selected }}
                  </div>
                  @if (dataType() === "asset" || dataType() === "task") {
                     |
                     <div>
                        {{
                           displayData.length -
                              currentTemplate.options.offsetStart -
                              extraElementsToAdd +
                              1
                        }}
                        <span>
                           {{
                              displayData.length -
                                 currentTemplate.options.offsetStart -
                                 extraElementsToAdd +
                                 1 >
                              1
                                 ? lang().QRCodes
                                 : lang().QRCode
                           }}
                        </span>
                     </div>
                  }
                  |

                  <div>
                     @if (dataType() !== "task") {
                        <lim-ui-text-button (click)="updateQRItems()">
                           {{ lang().AddMore }}
                        </lim-ui-text-button>
                     }
                     @if (dataType() !== "task") {
                        <span> &nbsp;|&nbsp; </span>
                     }
                     @if (dataType() !== "task") {
                        <lim-ui-text-button (click)="updateQRItems('clear')">{{
                           lang().Reset
                        }}</lim-ui-text-button>
                     }
                     @if (dataType() === "task") {
                        <lim-ui-dropdown-inline-text [label]="lang().AddMore">
                           <lim-ui-dropdown-text-item
                              (itemSelectedEvent)="updateQRItems('multipleTemplates')"
                              displayString="{{ lang().ShowMoreThanOneTasksQRCodes }}"
                              outputString="{{ lang().ShowMoreThanOneTasksQRCodes }}"
                              limUiTooltip="{{
                                 lang().ShowMoreThanOneTasksQRCodesTooltip
                              }}"
                           />
                           <lim-ui-dropdown-text-item
                              (itemSelectedEvent)="updateQRItems('multipleAssets')"
                              displayString="{{
                                 lang().ShowMoreThanOneAssetForATaskQRCodes
                              }}"
                              outputString="{{
                                 lang().ShowMoreThanOneAssetForATaskQRCodes
                              }}"
                              limUiTooltip="{{
                                 lang().ShowMoreThanOneAssetForATaskQRCodesTooltip
                              }}"
                           />
                        </lim-ui-dropdown-inline-text>
                     }
                  </div>
               </div>
               <div class="paper-shadow">
                  <div #printPreviewContainer class="print-preview-container">
                     @for (
                        item of displayData
                           | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
                        track item;
                        let index = $index
                     ) {
                        <qr-code-item
                           [item]="item"
                           [dataType]="dataType()"
                           [codeItemWidth]="codeItemWidth"
                           [codeItemHeight]="codeItemHeight"
                           [gridCols]="currentTemplate.options.gridCols"
                           [gridRows]="currentTemplate.options.gridRows"
                           [displayFields]="displayFields"
                           [manualFontSize]="currentTemplate.options.fontSize"
                           [rotate90]="rotate90"
                           [preview]="true"
                        />
                     }
                  </div>
                  @if (printing) {
                     <div id="printingQRCodes">
                        <div class="print-preview-container">
                           <!-- This horrible nest allows printing to be batched, speeding up the process by a factor of 5 -->
                           @for (group of printGroupArray; track group) {
                              <div #printGroupContainer>
                                 @for (page of group; track page) {
                                    <div #printDiv class="print-container">
                                       @for (item of page; track item) {
                                          <qr-code-item
                                             [item]="item"
                                             [dataType]="dataType()"
                                             [codeItemWidth]="codeItemWidth"
                                             [codeItemHeight]="codeItemHeight"
                                             [gridCols]="currentTemplate.options.gridCols"
                                             [gridRows]="currentTemplate.options.gridRows"
                                             [displayFields]="displayFields"
                                             [manualFontSize]="
                                                currentTemplate.options.fontSize
                                             "
                                             [rotate90]="rotate90"
                                          />
                                       }
                                    </div>
                                 }
                              </div>
                           }
                        </div>
                     </div>
                  }
               </div>
               <div class="text-center qr-pagination">
                  @if (displayData?.length && displayData?.length > itemsPerPage) {
                     <lim-ui-pagination
                        [maxSize]="5"
                        [rotate]="true"
                        [(page)]="page"
                        [pageSize]="itemsPerPage"
                        [collectionSize]="displayData?.length"
                        (pageChange)="pageChanged()"
                     />
                  }
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-secondary-button
         icon="floppyDiskRegular"
         title="{{ lang().DownloadPDF }}"
         (click)="handleAction('download')"
         [disabled]="buildPdfDisabled"
      >
         {{ lang().DownloadPDF }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button
         icon="printRegular"
         title="{{ lang().Print }}"
         (click)="handleAction('print')"
         [disabled]="buildPdfDisabled"
      >
         {{ lang().Print }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
