@if (part) {
   <lim-ui-panel class="lim-ui-fonts-body" id="partReportsPrint{{ part.partID }}">
      <header class="report-metrics-header">
         <div>
            <lim-ui-dropdown-button
               label="{{ filterTimeStr }}"
               limUiTooltip="{{ lang().FilterThisAssetReportsToTimePeriodTooltip }}"
            >
               <div menu>
                  @for (filter of dayFilters; track filter) {
                     <lim-ui-dropdown-text-item
                        displayString="{{ filter.display }}"
                        outputString="filter.days"
                        (itemSelectedEvent)="setDaysFilter(filter.days, filter.display)"
                     />
                  }
                  <lim-ui-dropdown-divider />
                  <lim-ui-dropdown-date-range-picker
                     sectionLabel="{{ lang().DateRange }}"
                     [(startDate)]="dueDate1"
                     startDateFieldLabel="{{ lang().From }}"
                     [(endDate)]="dueDate2"
                     endDateFieldLabel="{{ lang().To }}"
                     setButtonLabel="{{ lang().Set }}"
                     (setButtonClicked)="setDateRange()"
                  />
               </div>
            </lim-ui-dropdown-button>
         </div>
      </header>

      <!-- Part Stats Section -->
      <section class="metrics">
         <lim-ui-card>
            <h3>
               {{ totalParts }}
            </h3>
            <p limUiTooltip="{{ lang().TotalPartsToolTip }}">
               {{ lang().TotalPartsUsed }}
            </p>
         </lim-ui-card>
         <lim-ui-card>
            @if (isMultiCurrencyEnabled()) {
               <h3 [limUiTooltip]="currencyCode() | currencyName">
                  {{ totalPartCost | localeCurrency: currencyCode() }}
               </h3>
            } @else {
               <h3>{{ currencySymbol }}{{ totalPartCost }}</h3>
            }

            <p limUiTooltip="{{ lang().TotalCostToolTip }}">
               {{ lang().TotalCost }}
            </p>
         </lim-ui-card>
         <lim-ui-card>
            <h3>
               {{ staleStatus }}
            </h3>
            <p limUiTooltip="{{ lang().StaleThresholdTooltip }}">
               {{ lang().StaleStatus }}
            </p>
         </lim-ui-card>
         <lim-ui-card>
            <h3>
               {{ daysSinceUsed }}
            </h3>
            <p limUiTooltip="{{ lang().DaysSinceLastUsedToolTip }}">
               {{ lang().DaysSinceLastUsed }}
            </p>
         </lim-ui-card>
      </section>

      <section class="data-visualizations">
         <!-- Part Forecast -->
         <part-reports-forecast-table [part]="part" [currencyCode]="currencyCode" />

         <!-- Part usage section -->
         <lim-ui-card [noPadding]="true" class="part-usage-table">
            <header>
               <h3>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().ViewWhereThisPartWasRecentlyUsed }}"
                  />
                  {{ lang().RecentUsage }}
               </h3>
               @if (totalParts > 0 && part.partPrice !== null) {
                  <p>
                     <strong>{{ totalParts }} {{ part.partName }}</strong>
                     {{ lang().wereUsedInThisPeriodOfTimeForATotalOf }}
                     @if (isMultiCurrencyEnabled()) {
                        <strong [limUiTooltip]="currencyCode() | currencyName">
                           {{
                              totalParts * part.partPrice | localeCurrency: currencyCode()
                           }}
                        </strong>
                     } @else {
                        <strong>
                           {{ currencySymbol }}
                           {{ totalParts * part.partPrice | betterNumber: "1.2-2" }}
                        </strong>
                     }
                  </p>
               }
            </header>
            <div class="listTable">
               <div [hidden]="!(tasks && tasks.size > 0)" class="listItem listItemHeader">
                  <sort-column_refactor
                     class="location-col"
                     [options]="sortColumnInfo.checklistName"
                  />
                  <sort-column_refactor
                     class="qty-col"
                     [options]="sortColumnInfo.qtyUsed"
                  />
                  <sort-column_refactor
                     class="date-col"
                     [options]="sortColumnInfo.checklistCompletedDate"
                  />
                  <sort-column_refactor
                     class="user-col"
                     [options]="sortColumnInfo.completedByStr"
                  />
               </div>

               @for (
                  task of tasks
                     | sliceLimbleMap: (page - 1) * itemsPerPage : page * itemsPerPage;
                  track task
               ) {
                  <div
                     class="table-row-container"
                     [ngClass]="{ tranactionEveryOther: $even }"
                  >
                     <div class="listItem">
                        <div class="location-col">
                           <lim-ui-icon
                              icon="houseChimney"
                              limUiTooltip="{{ lang().ThisTaskIsLocatedAt }} {{
                                 task.locationName
                              }}"
                           />
                           <span>
                              <a
                                 (click)="popTask(task)"
                                 class="cursor table-link checklist-name"
                              >
                                 <span [limbleHtml]="task.checklistName"></span> - #{{
                                    task.checklistID
                                 }}
                              </a>
                              @if (task.assetID && task.assetID > 0) {
                                 <span>
                                    -
                                    <a
                                       (click)="viewAsset(task)"
                                       class="cursor table-link asset-name"
                                    >
                                       <span
                                          [limbleHtml]="
                                             taskAssetNames.get(task.checklistID)
                                          "
                                       ></span>
                                    </a>
                                 </span>
                              }
                           </span>
                        </div>
                        <div class="qty-col">
                           {{ task.qtyUsed }}
                        </div>
                        @if (task.checklistCompletedDate) {
                           <div class="date-col">
                              {{
                                 task.checklistCompletedDate * 1000 | betterDate: "date"
                              }}
                           </div>
                        }
                        <div class="user-col">
                           {{ task.completedByStr }}
                        </div>
                     </div>
                  </div>
               }
               <footer [hidden]="tasks?.size == 0">
                  @if (tasks?.size && tasks.size > itemsPerPage) {
                     <lim-ui-pagination
                        [maxSize]="5"
                        [rotate]="true"
                        [(page)]="page"
                        [pageSize]="itemsPerPage"
                        [collectionSize]="tasks.size"
                        (pageChange)="pageChanged()"
                     />
                  }
               </footer>
               <div [hidden]="!(tasks?.size == 0)" class="usage-empty">
                  {{ lang().ThisPartHasNotBeenUsedDuringTheDateRangeSelected }}
               </div>
            </div>
         </lim-ui-card>
      </section>
   </lim-ui-panel>
}
