import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DropdownInlineTextComponent,
   DropdownTextItemComponent,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   RadioButtonComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { IncrementRule } from "src/app/assets/components/configureSensorInterfaceModal/IncrementRule";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CapitalizePipe } from "src/app/shared/pipes/capitalize.pipe";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "configure-sensor-interface",
   templateUrl: "./configureSensorInterface.modal.component.html",
   styleUrls: ["./configureSensorInterface.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      RadioButtonComponent,
      TooltipDirective,
      FormsModule,
      MinimalIconButtonComponent,
      SecondaryButtonComponent,
      DropdownInlineTextComponent,
      DropdownTextItemComponent,
      AlertComponent,
      ModalFooterComponent,
      PrimaryButtonComponent,
      CapitalizePipe,
   ],
})
export class ConfigureSensorInterface implements OnInit {
   public loading;
   public errorMsg;
   public message;
   public title;
   public valueContent;
   public config;
   public field;
   public previousConfig;
   public previousConfigRules;
   public resolve;
   public modalInstance;

   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.loading = true;

      this.errorMsg = "";
      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.field = this.resolve.field;
      this.previousConfig = this.resolve.previousConfig;
      this.previousConfigRules = this.resolve.previousConfigRules;
      this.valueContent = this.field.valueContent;

      this.config = {
         incrementTypes: [this.lang().Add, this.lang().Subtract],
         incrementDirections: [this.lang().Above, this.lang().Below, this.lang().Between],
         runtimeUnits: [this.lang().minutes, this.lang().hours, this.lang().days],
         selected: 0,
         increment: {
            rules: [],
         },
         runtime: {
            direction: 0,
            threshold: 0,
            threshold2: 0,
            units: 0,
         },
      };

      this.config.toggleType = (obj) => {
         this.errorMsg = "";
         if (obj.type == 0) {
            obj.type = 1;
         } else if (obj.type == 1) {
            obj.type = 0;
         }
      };

      this.config.toggleDirection = (obj) => {
         this.errorMsg = "";
         if (obj.direction == 0) {
            obj.direction = 1;
         } else if (obj.direction == 1) {
            obj.direction = 2;
         } else if (obj.direction == 2) {
            obj.direction = 0;
         }
      };

      this.config.removeIncrementRule = (index) => {
         this.errorMsg = "";
         this.config.increment.rules.splice(index, 1);
      };

      this.config.addIncrementRule = () => {
         this.errorMsg = "";
         this.config.increment.rules.push(new IncrementRule(0, 0, 0, 0, 0));
      };

      if (this.previousConfig && this.previousConfig >= 0) {
         this.config.selected = this.previousConfig;
         this.removeErrorMsg();
         if (this.previousConfig == 1) {
            this.previousConfigRules.forEach((rule) => {
               this.config.increment.rules.push(
                  new IncrementRule(
                     Number(rule.operation),
                     Number(rule.amount),
                     Number(rule.direction),
                     Number(rule.threshold),
                     Number(rule.threshold2),
                  ),
               );
            });
            this.config.addIncrementRule();
         } else if (this.previousConfig == 2) {
            this.config.runtime.direction = Number(this.previousConfigRules[0].direction);
            this.config.runtime.threshold = Number(this.previousConfigRules[0].threshold);
            this.config.runtime.threshold2 = Number(
               this.previousConfigRules[0].threshold2,
            );
            this.config.runtime.units = this.previousConfigRules[0].units;
         } else {
            this.config.addIncrementRule();
         }
      } else {
         this.config.addIncrementRule();
      }
   }
   removeErrorMsg = () => {
      this.errorMsg = "";
   };

   close = () => {
      this.modalInstance.close(false);
   };

   save = () => {
      this.errorMsg = "";
      if (this.config.selected == 1 && this.config.increment.rules.length == 0) {
         this.errorMsg =
            this.lang().PleaseAddARuleBeforeSavingOrSelectADifferentConfigurationOption;
         return;
      }
      this.field.valueContent = this.valueContent;
      this.modalInstance.close(this.config);
   };
}
