import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { ModalService, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PrComponent } from "src/app/purchasing/bills/prWrapper/pr.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { BillTransaction } from "src/app/purchasing/types/bill-transaction.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "po-delivery-date",
   templateUrl: "./poDeliveryDate.element.component.html",
   styleUrls: ["./poDeliveryDate.element.component.scss"],
   imports: [TooltipDirective, BetterDatePipe],
})
export class PoDeliveryDate implements OnInit, OnDestroy {
   @Input() public poID;
   @Input() public partIDToFilterTo?: number | undefined; // if this is set, only show PRs that have transactions for this partID

   public CID;
   public currencySymbol;
   public purchaseOrder: PurchaseOrder | undefined;
   public openPOWatchVarSub;
   protected billDeliveryTotals: LimbleMap<
      number,
      { total: number; date: number | null }
   > = new LimbleMap();
   protected deliveryDate: number | undefined;

   private readonly modalService = inject(ModalService);
   private readonly managePO = inject(ManagePO);
   private readonly manageUser = inject(ManageUser);
   private readonly paramsService = inject(ParamsService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.purchaseOrder = this.managePO.getPurchaseOrder(this.poID);
      this.deliveryDate = this.managePO.calculatePurchaseOrderDeliveryDate(this.poID);

      this.openPOWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.updateDeliveryTotalDisplay();
         },
      );
   }

   protected popPR(prID): void {
      const instance = this.modalService.open(PrComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { prID },
         },
      };
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.openPOWatchVarSub);
   }

   private updateDeliveryTotalDisplay(): void {
      this.purchaseOrder = this.managePO.getPurchaseOrder(this.poID);
      if (this.purchaseOrder === undefined) return;

      for (const prID of this.purchaseOrder.prIDs) {
         const bill = this.managePO.getBill(prID);
         if (bill === undefined) continue;
         let total = 0;
         for (const transactionID of bill.transactionIDs) {
            const transaction = this.managePO.getBillTransaction(transactionID);
            if (transaction === undefined) continue;
            if (this.shouldExcludeTransactionForPartFilter(transaction)) continue;
            total += Number(transaction.qtyReceived);
         }
         this.billDeliveryTotals.set(prID, { total, date: bill.date });
      }
   }

   private shouldExcludeTransactionForPartFilter(transaction: BillTransaction): boolean {
      if (!transaction.poItemID || !this.partIDToFilterTo) {
         return false;
      }
      const purchaseOrderItem = this.managePO.getPurchaseOrderItem(transaction.poItemID);
      if (purchaseOrderItem?.partID !== this.partIDToFilterTo) {
         return true;
      }
      return false;
   }
}
