import { signal, type Signal, type WritableSignal } from "@angular/core";
import type { PurchasableDtoBase } from "src/app/parts/purchasable/purchasable.dto.types";
import type { UnitDescription } from "src/app/parts/unit-of-measure/unit-of-measure.dto.types";
import { assert } from "src/app/shared/utils/assert.utils";

export abstract class Purchasable {
   public readonly id: number;
   public readonly partID: number;
   private readonly _name: WritableSignal<string>;
   public readonly orderUnitDescription: UnitDescription;

   public constructor(params: PurchasableDtoBase) {
      this.id = params.id;
      this.partID = params.partID;
      this._name = signal(params.name);
      this.orderUnitDescription = params.orderUnitDescription;
   }

   public get name(): Signal<string> {
      return this._name;
   }

   public updateName(name: string): void {
      assert(name.length > 0, "Name must not be empty.");

      this._name.set(name);
   }
}
