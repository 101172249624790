import type { OnInit } from "@angular/core";
import { inject, Component, ViewChild, computed } from "@angular/core";
import type { DragEndEvent, LimbleTreeOptions } from "@limble/limble-tree";
import { LimbleTreeRootComponent } from "@limble/limble-tree";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PriorityTreeItem } from "src/app/settings/components/priorityTreeItemElement/priorityTreeItem.element.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManagePriority } from "src/app/tasks/services/managePriority";

@Component({
   selector: "customize-priorities",
   templateUrl: "./customizePriorities.modal.component.html",
   styleUrls: ["./customizePriorities.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      LimbleTreeRootComponent,
      SecondaryButtonComponent,
   ],
})
export class CustomizePriorities implements OnInit {
   @ViewChild("tree") limbleTree: LimbleTreeRootComponent | undefined;

   public message;
   public title;
   public resolve;
   public modalInstance;
   public treeOptions?: LimbleTreeOptions;
   public priorities: any = [];

   private readonly paramsService = inject(ParamsService);
   private readonly managePriority = inject(ManagePriority);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.getPriorities();

      this.treeOptions = {
         allowNesting: false,
         defaultComponent: {
            class: PriorityTreeItem,
            bindings: {
               priorities: this.priorities,
               updatePriorities: this.updatePriorities,
               deletePriority: this.deletePriority,
               updateSinglePriority: this.updateSinglePriority,
            },
         },
      };
   }

   protected onMoveNode(event: DragEndEvent<PriorityTreeItem>): void {
      const draggedField = this.priorities.splice(event.oldIndex(), 1);
      this.priorities.splice(event.newIndex(), 0, draggedField[0]);
      for (const [index, priority] of this.priorities.entries()) {
         priority.priorityLevel = index + 1;
      }
      this.updatePriorities();
   }

   getPriorities = () => {
      this.priorities = this.managePriority.getPriorityList();
   };

   updatePriorities = () => {
      this.managePriority.updatePriorities(this.priorities).then((result) => {
         if (result.data.success) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   addPriority = () => {
      this.managePriority.createPriority().then((result) => {
         if (result.data.success) {
            this.getPriorities();
            this.limbleTree?.update();
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   deletePriority = (priority) => {
      if (priority.isDefault === 1) {
         this.alertService.addAlert(
            this.lang().WhoopsYouCannotDeleteTheDefaultPriority,
            "danger",
            6000,
         );
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().AreYouSureYouWantToDeleteThisPriority} </br></br> <b>${this.lang().DeletePriorityMessage} "<span class='red-color'>${this.lang().NoPriorityStr}</span>."</b>`,
            title: this.lang().DeletePriorityTitle,
         },
      };

      instance.result.then((modalResult) => {
         if (modalResult) {
            this.managePriority
               .deletePriority(priority.priorityID, priority.priorityLevel)
               .then((result) => {
                  if (result.data.success) {
                     this.getPriorities();
                     this.limbleTree?.update();
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else if (result.data.reason === "isDefault") {
                     //double check on isDefault to prevent accidental default delete
                     this.alertService.addAlert(
                        this.lang().WhoopsYouCannotDeleteTheDefaultPriority,
                        "danger",
                        6000,
                     );
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   updateSinglePriority = (priority) => {
      //remove accidental tags from priority name
      priority.name.replace(/<br>/g, "");

      this.managePriority.updateSinglePriority(priority).then((result) => {
         if (result.data.success) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
      this.limbleTree?.update();
   };

   close = () => {
      this.modalInstance.close();
   };
}
