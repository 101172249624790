import { HttpClient } from "@angular/common/http";
import { computed, inject, Injectable, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { map, ReplaySubject, Subject, type Observable, firstValueFrom } from "rxjs";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import type { AssetPartAssociation } from "src/app/assets/types/asset-part-association.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { partFieldValueDtoStrict } from "src/app/parts/schemata/fields/part-field-value.dto";
import { partAndAssociationDto } from "src/app/parts/schemata/part.schema";
import type { Category } from "src/app/parts/types/category/category.types";
import type { ConsumedRelation } from "src/app/parts/types/consumed-relation/consumed-relation.types";
import type { ExtraBatch } from "src/app/parts/types/extra-batch/extra-batch.types";
import type { PartField } from "src/app/parts/types/field/field.types";
import type { PartFieldType } from "src/app/parts/types/field/type/part-field-type.types";
import type { PartFieldValueFile } from "src/app/parts/types/field/value/file/file.type";
import type { PartFieldValue } from "src/app/parts/types/field/value/part-field-value.types";
import type { Part, PartDto } from "src/app/parts/types/part.types";
import type { PartVendorRelation } from "src/app/parts/types/vendors-relations/vendor-relation.types";
import { UnitOfMeasureRefreshService } from "src/app/parts/unit-of-measure/unit-of-measure-refresh.service";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import type { Unit } from "src/app/parts/unit-of-measure/unit.types";
import type {
   ManagePO,
   PurchaseOrderItemToAddSkeleton,
} from "src/app/purchasing/services/managePO";
import { BetterDate } from "src/app/shared/services/betterDate";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { UpdateSignaler } from "src/app/shared/services/update-signaler/update-signaler";
import type { CalculatedPartInfo } from "src/app/shared/types/general.types";
import { cleanWordPaste, resolvePromisesMap } from "src/app/shared/utils/app.util";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskPartRelation } from "src/app/tasks/types/part/task-part.types";
import type { Task, TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services//manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";
import { environment } from "src/environments/environment";
import reservedNames from "src/root/phpscripts/reservedNames.json";
import { z } from "zod";

@Injectable({ providedIn: "root" })
export class ManageParts {
   private readonly _partInfoCalculated$ = new Subject<number>();
   public readonly partsAreReceiving = signal(false);
   public readonly partInfoCalculated$ = this._partInfoCalculated$.asObservable();
   public tileFieldsObs$ = new Subject<null>();
   public dateReminderStreamMap: Map<number, Subject<null>> = new Map();

   private parts: Lookup<"partID", Part> = new Lookup("partID");
   private extraBatches: Lookup<"extraBatchID", ExtraBatch> = new Lookup("extraBatchID");
   private categories: Lookup<"categoryID", Category> = new Lookup("categoryID");
   private assetsRelations: Lookup<"relationID", AssetPartAssociation> = new Lookup(
      "relationID",
   );
   private fields: Lookup<"fieldID", PartField> = new Lookup("fieldID");
   private fieldValues: Lookup<"valueID", PartFieldValue> = new Lookup("valueID");
   private fieldTypes: Lookup<"fieldTypeID", PartFieldType> = new Lookup("fieldTypeID");
   private fieldValueFiles: Lookup<"fileID", PartFieldValueFile> = new Lookup("fileID");
   private consumedRelations: Lookup<"relationID", ConsumedRelation> = new Lookup(
      "relationID",
   );

   private readonly calculatedPartInfo: Map<number, CalculatedPartInfo> = new Map();

   private partsLoaded = 0;
   private partsWatchVar = 0;
   private readonly axios = axios;

   public partDataCalculated$: ReplaySubject<boolean> = new ReplaySubject();
   public isAllPartDataCalculated = toSignal(this.partDataCalculated$);

   private readonly manageTask = inject(ManageTask);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);
   private readonly betterDate = inject(BetterDate);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly unitOfMeasureRefreshService = inject(UnitOfMeasureRefreshService);
   private readonly httpClient = inject(HttpClient);

   private readonly updateSignaler = new UpdateSignaler();
   public readonly updated = this.updateSignaler.updated;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly createPartResponseDto = z.object({
      data: z.object({
         part: z.object({
            partID: z.number(),
         }),
         customDefaultFields: z.array(
            partFieldValueDtoStrict.omit({
               partValueFileIDs: true,
            }),
         ),
      }),
   });

   public constructor() {
      this.createObservables();
   }

   createObservables = () => {
      this.manageObservables.createObservable("partsLoaded", this.partsLoaded);
      this.manageObservables.createObservable("partsWatchVar", this.partsWatchVar);
      this.manageObservables.createObservable("partFields", null);
   };

   incrementPartsWatchVar = () => {
      this.partsWatchVar++;
      this.manageObservables.updateObservable("partsWatchVar", this.partsWatchVar);
   };

   printPDF = (
      locationID,
      site,
      storeroom,
      totalPartsCost,
      generalFilter,
      searchFilter,
      additionalFields,
   ) => {
      const encodedStoreroom = encodeURIComponent(storeroom);
      const encodedSite = encodeURIComponent(site);

      window.open(
         `phpscripts/managePart.php?action=printParts&locationID=${locationID}&site=${encodedSite}&storeroom=${encodedStoreroom}&partsTotalValue=${totalPartsCost}${additionalFields}${generalFilter}${searchFilter}`,
         "_blank",
      );
   };

   public calculatePartData(part: Part): CalculatedPartInfo {
      let totalQty = part.partQty ?? 0;
      /**
       * CASE-3043 - If the part quantity is negative, and there are extra batches associated with the part,
       * reconcile the negative quantity with the extra batches to get the price instead of using the original part price.
       */
      let totalPrice =
         part.partExtraBatchIDs.length === 0 || (part.partQty ?? 0) > 0
            ? (part.partPrice ?? 0) * (part.partQty ?? 0)
            : 0;
      let averagePrice;

      let reservedQty = 0;
      const reservedTasks: Array<{
         checklistID: number;
         suggestedNumber: number;
      }> = [];

      const negativeParts = new Map<number, number>();
      const extraBatchesForNegativeParts = new Map<
         number,
         Array<{
            partQty: number | null;
            partQtyUsed: number | null;
            partPrice: number | null;
         }>
      >();
      if (part.partQty && part.partQty < 0) {
         negativeParts.set(part.partID, part.partQty);
         extraBatchesForNegativeParts.set(part.partID, []);
      }

      for (const batchID of part.partExtraBatchIDs) {
         const extraBatch = this.getExtraBatch(batchID);
         if (!extraBatch) {
            //There are parts associated with extraBatches that either have been deleted or are being filtered out of the extraBatches results on the back end.  If we try to process them here, errors occur
            continue;
         }
         totalQty =
            totalQty + (Number(extraBatch.partQty) - Number(extraBatch.partQtyUsed));

         if (!extraBatch.partPrice) {
            continue;
         }

         if (extraBatch.partID && negativeParts.has(extraBatch.partID)) {
            const negativeBatches = extraBatchesForNegativeParts.get(extraBatch.partID);
            negativeBatches?.push(extraBatch);
         }

         totalPrice +=
            extraBatch.partPrice *
            (Number(extraBatch.partQty) - Number(extraBatch.partQtyUsed));
      }

      const negativePartCostOffset = this.reconcileNegativeParts(
         negativeParts,
         extraBatchesForNegativeParts,
      );

      totalPrice -= negativePartCostOffset;

      //relations find reserved tasks.  Basically if a part is attached to an Open Task then it is considered reserved.
      const relations = this.manageTask.getPartRelationsByPartID(part.partID);
      if (relations) {
         for (const relation of relations) {
            const task = this.manageTask.getUnfilteredTask(relation.checklistID);

            if (task && task.checklistTemplate == 0 && task.checklistCompletedDate == 0) {
               //ok we found an open task for this part
               reservedQty += Number(relation.suggestedNumber);

               if (reservedQty % 1 !== 0) {
                  reservedQty = Number(reservedQty.toFixed(3));
               }

               //some tasks might have multiple part relations of the same part so we have to be carehow and only put a reserved Task once...
               let found = false;
               for (const reservedTask of reservedTasks) {
                  if (reservedTask.checklistID == task.checklistID) {
                     found = true;
                  }
               }
               if (!found) {
                  reservedTasks.push({
                     checklistID: task.checklistID,
                     suggestedNumber: relation.suggestedNumber ?? 0,
                  });
               }
            }
         }
      }

      const totalAvailableQty = totalQty - reservedQty;

      if (part.partExtraBatchIDs.length == 0) {
         //if there are no extra batches the average price is just what they have set for the part
         averagePrice = part.partPrice;
      } else if (part.partExtraBatchIDs.length >= 1 && totalQty > 0) {
         //they have extra batches so we need to do a calculation to see what the averagePrice is amongst extrabatches and general batch.
         averagePrice = (totalPrice / totalQty).toFixed(2);
      } else {
         //safety catchall
         averagePrice = 0;
      }

      const calculatedPartInfo = {
         totalQty,
         totalPrice,
         averagePrice: Number(averagePrice),
         reservedQty,
         totalAvailableQty,
         reservedTasks,
      };

      this.setSingleCalculatedPartInfo(part.partID, calculatedPartInfo);

      return calculatedPartInfo;
   }

   /**
    * This function is used to deal with corrupted data caused by customers that allow negative inventory,
    * use parts before they are received, and don't set a default part price, causing the parts table to
    * have a negative quantity, but the extra batches remain unused.  This scenario causes the part quantity
    * to be correct, but the total cost is not.
    *
    * The negative quantity must be subtracted from the extra batches in order to correctly calculate the
    * part quantity and cost.
    *
    * Parts from Scenario 1 below should not be included in the extra batches passed to this function.  Any
    * part with a price above 0 should not be passed to this function.
    *
    * Scenarios:
    * 1. Part has a negative quantity and has no extra batches, but the part cost is greater than 0.
    * 2. Part has a negative quantity and has no extra batches, but the part cost is 0.
    * 3. Part has a negative quantity and is in extra batches, but the extra batch quantity is 0. (This scenario is functionally the same as scenarios 1 & 2)
    * 4. Part has a negative quantity and is in extra batches, but the extra batch quantity is less than the negative quantity and the part cost is greater than 0.
    * 5. Part has a negative quantity and is in extra batches, but the extra batch quantity is less than the negative quantity and the part cost is 0.
    * 6. Part has a negative quantity and is in extra batches, but the extra batch quantity is greater than the negative quantity.
    * 7. Part has a negative quantity and is in extra batches, but the extra batch quantity is equal to the negative quantity.
    */
   private reconcileNegativeParts(
      negativeParts: Map<number, number>,
      extraBatches: Map<
         number,
         Array<{
            partQty: number | null;
            partQtyUsed: number | null;
            partPrice: number | null;
         }>
      >,
   ): number {
      let negativePartCostOffset = 0;
      negativeParts.forEach((originalPartQty, partID) => {
         let partQty = originalPartQty;
         const partBatches = extraBatches.get(partID);
         if (!partBatches || partBatches.length === 0 || partQty >= 0) {
            // Scenario 2 falls into this category
            return;
         }

         partBatches.forEach((batch) => {
            const batchQty = (batch.partQty ?? 0) - (batch.partQtyUsed ?? 0);
            if (!batch.partQty || batchQty === 0) {
               // Scenario 3 falls into this category
               return;
            }

            if (batchQty < Math.abs(partQty)) {
               // Scenario 4 & 5 fall into this category
               partQty = (partQty ?? 0) + batchQty;
               negativePartCostOffset += batchQty * (batch.partPrice ?? 0);
               return;
            }

            if (batchQty >= Math.abs(partQty)) {
               // Scenario 6 & 7 fall into this category
               // subtract the negative quantity from the extra batch quantity
               negativePartCostOffset += Math.abs(partQty) * (batch.partPrice ?? 0);
               partQty = 0;
            }
         });
      });

      return negativePartCostOffset;
   }

   public getPartRelationAvailability(
      part: Part,
   ): LimbleMap<number, { inStock: boolean }> | undefined {
      let calculatedPartInfo = this.getSingleCalculatedPartInfo(part.partID);
      if (calculatedPartInfo === undefined) {
         calculatedPartInfo = this.calculatePartData(part);
      }
      const partRelations = this.manageTask.getPartRelationsByPartID(part.partID);
      if (partRelations === undefined) return undefined;
      const availabilityMap: LimbleMap<number, { inStock: boolean }> = new LimbleMap();
      let runningTotal = 0;
      for (const relation of partRelations) {
         if (
            relation.checklistID !== null &&
            this.manageTask.getOpenTaskLocalLookup(relation.checklistID) &&
            this.manageTask.getOpenTaskLocalLookup(relation.checklistID)
               ?.checklistTemplate == 0
         ) {
            if (
               runningTotal + Number(relation.suggestedNumber) <=
               calculatedPartInfo.totalQty
            ) {
               availabilityMap.set(relation.relationID, { inStock: true });
               runningTotal += Number(relation.suggestedNumber);
            } else {
               availabilityMap.set(relation.relationID, { inStock: false });
            }
         }
      }
      return availabilityMap;
   }

   public getAltPartNameAndNumberFromVendorRelation(
      partVendorRelation: PartVendorRelation,
   ): string {
      const part = this.getPart(partVendorRelation.partID);
      if (!part) return "";
      return `${part.partName} - ${partVendorRelation.partNumber ?? part.partNumber}`;
   }

   addExtraBatchToLookup = (extraBatch) => {
      this.extraBatches.set(extraBatch.extraBatchID, extraBatch);
      const partToUpdate = this.getPart(extraBatch.partID);
      if (partToUpdate) {
         partToUpdate.partExtraBatchIDs.push(extraBatch.extraBatchID);
      }
   };

   public async getData() {
      this.unitOfMeasureRefreshService.refresh();
      await Promise.all([
         this.fetchParts(),
         this.fetchExtraBatches(),
         this.fetchCategories(),
         this.fetchAssetsRelations(),
         this.fetchFields(),
         this.fetchFieldValues(),
         this.fetchFieldValueFiles(),
         this.fetchFieldTypes(),
         this.fetchPartsConsumed(),
      ]);
      this.incrementPartsLoaded();
      this.incrementPartsWatchVar();
      this.updatePartFieldsObs();
      this.updateSignaler.trigger();
   }

   public async fetchParts() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts`);

      logApiPerformance("parts", startTime, this.manageUser.getCurrentUser());
      this.parts = new Lookup("partID", response.data);
   }

   public async getSinglePart(partID: number): Promise<PartDto | undefined> {
      const startTime = Math.floor(Date.now());

      const response = await this.axios.get(`${environment.flannelUrl}/parts/${partID}`);

      logApiPerformance("part", startTime, this.manageUser.getCurrentUser());

      return response.data;
   }

   public async fetchExtraBatches() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts/extraBatches`);

      logApiPerformance("partsExtraBatches", startTime, this.manageUser.getCurrentUser());
      this.extraBatches = new Lookup("extraBatchID", response.data);
   }

   public async fetchCategories() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts/categories`);

      logApiPerformance("partsCategories", startTime, this.manageUser.getCurrentUser());
      this.categories = new Lookup("categoryID", response.data);
   }

   public async fetchAssetsRelations() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts/assetsRelations`);

      logApiPerformance(
         "partsAssetsRelations",
         startTime,
         this.manageUser.getCurrentUser(),
      );
      this.assetsRelations = new Lookup("relationID", response.data);
   }

   public async fetchFields() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts/fields`);

      logApiPerformance("partsFields", startTime, this.manageUser.getCurrentUser());
      this.fields = new Lookup("fieldID", response.data);
      const hasFieldLockFlag = await this.launchFlagsService.isEnabled(
         Flags.PART_VENDOR_FIELD_LOCK,
      );
      for (const field of this.fields) {
         if (field.fieldTypeID === 2) {
            this.dateReminderStreamMap.set(field.fieldID, new Subject());
         }

         if (!hasFieldLockFlag) {
            field.lockedDefault = 0;
         }
      }
   }

   public async fetchFieldValues() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts/fields/values`);

      logApiPerformance("partsFieldsValues", startTime, this.manageUser.getCurrentUser());
      this.fieldValues = new Lookup("valueID", response.data);
   }

   public async fetchFieldValueFiles() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts/fields/files`);

      logApiPerformance("partsFieldsFiles", startTime, this.manageUser.getCurrentUser());
      this.fieldValueFiles = new Lookup("fileID", response.data);
   }

   public async fetchFieldTypes() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts/fields/types`);

      logApiPerformance("partsFieldsTypes", startTime, this.manageUser.getCurrentUser());
      this.fieldTypes = new Lookup("fieldTypeID", response.data);
   }

   public async fetchPartsConsumed() {
      const startTime = Math.floor(Date.now());

      const response = await axios.get(`${environment.flannelUrl}/parts/consumed`);

      logApiPerformance("partsConsumed", startTime, this.manageUser.getCurrentUser());
      this.consumedRelations = new Lookup("relationID", response.data);
   }

   public async fetchPartsAndAssociationsByID(partIDs: Array<number>) {
      const startTime = Math.floor(Date.now());

      const partIDsList = partIDs.toString();
      const singlePartPromises = new Map([
         [
            "parts",
            axios.get(`${environment.flannelUrl}/parts`, {
               params: {
                  partIDs: partIDsList,
                  ignoreLocationCreds: true,
               },
            }),
         ],
         [
            "extraBatches",
            axios.get(`${environment.flannelUrl}/parts/extraBatches`, {
               params: {
                  partIDs: partIDsList,
               },
            }),
         ],
         [
            "assetsRelations",
            axios.get(`${environment.flannelUrl}/parts/assetsRelations`, {
               params: {
                  partIDs: partIDsList,
               },
            }),
         ],
         [
            "fields",
            axios.get(`${environment.flannelUrl}/parts/fields`, {
               params: {
                  partIDs: partIDsList,
               },
            }),
         ],
         [
            "fieldValues",
            axios.get(`${environment.flannelUrl}/parts/fields/values`, {
               params: {
                  partIDs: partIDsList,
               },
            }),
         ],
         [
            "fieldValueFiles",
            axios.get(`${environment.flannelUrl}/parts/fields/files`, {
               params: {
                  partIDs: partIDsList,
               },
            }),
         ],
         [
            "consumedAssociations",
            axios.get(`${environment.flannelUrl}/parts/consumed`, {
               params: {
                  partIDs: partIDsList,
               },
            }),
         ],
      ]);

      const responses = await resolvePromisesMap(singlePartPromises);

      logApiPerformance(
         "partsAndAssociationsByID",
         startTime,
         this.manageUser.getCurrentUser(),
      );
      const entries = [...responses.entries()].map((entry) => {
         return [entry[0], entry[1].data];
      });
      const unifiedResponse = Object.fromEntries(entries);

      for (const part of unifiedResponse.parts) {
         this.parts.set(part.partID, part);
         const calculatedData = this.calculatePartData(part);
         assert(calculatedData);
         this.setSingleCalculatedPartInfo(part.partID, calculatedData);
      }
      this.partDataCalculated$.next(true);

      for (const field of unifiedResponse.fields) {
         this.fields.set(field.fieldID, field);
      }

      for (const extraBatch of unifiedResponse.extraBatches) {
         this.extraBatches.set(extraBatch.extraBatchID, extraBatch);
      }

      for (const relation of unifiedResponse.assetsRelations) {
         this.assetsRelations.set(relation.relationID, relation);
      }

      for (const value of unifiedResponse.fieldValues) {
         this.fieldValues.set(value.valueID, value);
      }

      for (const file of unifiedResponse.fieldValueFiles) {
         this.fieldValueFiles.set(file.fileID, file);
      }

      for (const relation of unifiedResponse.consumedAssociations) {
         this.consumedRelations.set(relation.relationID, relation);
      }
   }

   public async updateSorts(updates: Array<any>, locationID: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateSorts",
         },
         data: {
            updates: JSON.stringify(updates),
            locationID: locationID,
         },
      });

      return post;
   }

   public transferParts(
      sendingLocation,
      receivingLocation,
      part: Part,
      receivingPart: Part,
      qty,
   ) {
      //totalQty is changed with part quantity because the totalQty triggers the update to be logged,
      //and part quantity so that the page itself knows to update.
      if (part.partQty === null) {
         part.partQty = 0;
      }

      part.partQty = part.partQty - qty;
      this.calculatePartData(part);
      const sendingReason = `${this.lang().TransferredTo} ${receivingLocation.locationName}`;
      this.updatePartQty(part.partID, sendingReason);

      receivingPart.partQty += qty;
      this.calculatePartData(receivingPart);
      const receivingReason = `${this.lang().TransferredFrom} ${sendingLocation.locationName}`;
      this.updatePartQty(receivingPart.partID, receivingReason);
   }

   getPartsWatchVar = () => {
      return this.partsWatchVar;
   };

   public getPart(partID: number): Part | undefined {
      return this.parts.get(partID);
   }
   public getExtraBatch(extraBatchID: number) {
      return this.extraBatches.get(extraBatchID);
   }

   public getCategory(categoryID: number) {
      return this.categories.get(categoryID);
   }

   public getAssetRelation(relationID: number) {
      return this.assetsRelations.get(relationID);
   }

   public getField(fieldID: number) {
      return this.fields.get(fieldID);
   }

   public getFieldValue(valueID: number): PartFieldValue | undefined {
      return this.fieldValues.get(valueID);
   }

   public getFieldType(fieldTypeID: number) {
      return this.fieldTypes.get(fieldTypeID);
   }

   public getFieldValueFile(fileID: number) {
      return this.fieldValueFiles.get(fileID);
   }

   public getConsumedRelation(relationID: number) {
      return this.consumedRelations.get(relationID);
   }

   public getParts() {
      return this.parts;
   }

   public getExtraBatches() {
      return this.extraBatches;
   }

   public getCategories() {
      return this.categories;
   }

   public getAssetsRelations() {
      return this.assetsRelations;
   }

   public getFields() {
      return this.fields;
   }

   public getFieldTypes() {
      return this.fieldTypes;
   }

   public getFieldValues() {
      return this.fieldValues;
   }

   public getFieldValueFiles() {
      return this.fieldValueFiles;
   }

   public getConsumedRelations() {
      return this.consumedRelations;
   }

   public getCalculatedPartInfo() {
      return this.calculatedPartInfo;
   }

   public getSingleCalculatedPartInfo(partID: number): CalculatedPartInfo | undefined {
      return this.calculatedPartInfo.get(partID);
   }

   public setSingleCalculatedPartInfo(
      partID: number,
      calculatedPartInfo: CalculatedPartInfo,
   ) {
      this.calculatedPartInfo.set(partID, calculatedPartInfo);
      this._partInfoCalculated$.next(partID);
   }

   public setPartsAreReceiving(partsAreReceiving: boolean) {
      this.partsAreReceiving.set(partsAreReceiving);
   }

   getPartsLoaded = () => {
      return this.partsLoaded;
   };

   incrementPartsLoaded = () => {
      this.partsLoaded++;
      this.manageObservables.updateObservable("partsLoaded", this.partsLoaded);
   };
   updatePartFieldsObs = () => {
      this.manageObservables.updateObservable("partFields", 1);
   };

   public async addExistingField(part: Part, fieldID: number) {
      const partID = part.partID;

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "addExistingField",
         },
         data: {
            locationID: part.locationID,
            partID: partID,
            fieldID: fieldID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const newValue = post.data.row;
      newValue.partValueFileIDs = [];
      this.fieldValues.set(newValue.valueID, newValue);
      part.partValueIDs.push(newValue.valueID);

      this.updatePartFieldsObs();
      this.manageObservables.updateObservable(`partFields${part.partID}`, 1);

      return post;
   }

   private getBannedFieldNames() {
      return reservedNames.Parts;
   }

   public usingForbiddenFieldNames(name: string): boolean {
      return this.getBannedFieldNames().some(
         (fieldName) => fieldName.toLowerCase() == name.toLowerCase(),
      );
   }

   public async addFieldValue(
      part: Part,
      fieldTypeID: number,
      name: string,
      options: string,
   ) {
      const nameClean = cleanWordPaste(name);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "addNewField",
         },
         data: {
            locationID: part.locationID,
            partID: part.partID,
            fieldTypeID: fieldTypeID,
            options: options,
            name: nameClean,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const newField = post.data.field;
      this.fields.set(newField.fieldID, newField);

      if (newField.fieldTypeID === 2) {
         this.dateReminderStreamMap.set(newField.fieldID, new Subject());
      }

      //adds the new value to the part's list.
      const newValue = post.data.row;
      newValue.partValueFileIDs = [];
      this.fieldValues.set(newValue.valueID, newValue);

      //adds the field into the correct part
      part.partValueIDs.push(newValue.valueID);

      //add fieldTypeID info to the return object
      post.data.row.fieldTypeID = fieldTypeID;

      this.updatePartFieldsObs();
      this.manageObservables.updateObservable(`partFields${part.partID}`, 1);

      return post;
   }

   public async addField(name: string, fieldTypeID: number, locationID: number) {
      const cleanName = cleanWordPaste(name);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "addField",
         },
         data: {
            name: cleanName,
            fieldTypeID: fieldTypeID,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const newField = post.data.field;
      this.fields.set(newField.fieldID, newField);

      if (newField.fieldTypeID === 2) {
         this.dateReminderStreamMap.set(newField.fieldID, new Subject());
      }

      this.updatePartFieldsObs();

      return post;
   }

   public async setFieldValue(value: PartFieldValue, part: Part) {
      let valueContent = value.valueContent;
      if (typeof valueContent === "string") {
         valueContent = cleanWordPaste(valueContent);
      }

      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "setFieldValue",
         },
         data: {
            locationID: part.locationID,
            valueID: value.valueID,
            valueContent: valueContent,
         },
      });

      return post;
   }

   public async updateCatName(cat: Category, part: Part) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateCatName",
         },
         data: {
            categoryID: cat.categoryID,
            categoryName: cat.categoryName,
            locationID: part.locationID,
         },
      });

      return post;
   }
   //this function updates the dropdown options of a part's custom fields
   public async updateDropdownOptions(field: PartField) {
      assert(field);
      field.optionsJSON = cleanWordPaste(String(field.optionsJSON));
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateDropdownOptions",
         },
         data: {
            fieldID: field.fieldID,
            optionsJSON: field.optionsJSON,
         },
      });
      post.then((answer) => {
         if (answer.data.success == true) {
            const fieldToUpdate = this.getField(field.fieldID);
            assert(fieldToUpdate);
            fieldToUpdate.optionsJSON = field.optionsJSON;
            this.manageObservables.updateObservable(
               `partFieldOptionsJSON${field.fieldID}`,
               1,
            );
            this.updatePartFieldsObs();
         }
      });

      return post;
   }

   public async updateFieldViewableByTech(field: PartFieldValue, locationID: number) {
      let newValue;
      if (field.viewableByTech == 1) {
         newValue = 0;
      } else {
         newValue = 1;
      }

      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateFieldViewableByTech",
         },
         data: {
            locationID: locationID,
            valueID: field.valueID,
            newValue: newValue,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            field.viewableByTech = newValue;
         }
      });

      return post;
   }

   importParts = async (partsToImport, fieldsToImport, locationID, assocPartsLocal) => {
      const partsStringified = JSON.stringify(partsToImport);
      const fieldsStringified = JSON.stringify(fieldsToImport);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "importParts",
         },
         data: {
            parts: partsStringified,
            fields: fieldsStringified,
            locationID: locationID,
            assocPartsLocal,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.incrementPartsWatchVar();
         }
      });

      return post;
   };

   importPartPurchasables = async (purchasablesToImport, locationID) => {
      const purchasablesStringified = JSON.stringify(purchasablesToImport);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "importPartPurchasables",
         },
         data: {
            parts: purchasablesStringified,
            locationID: locationID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.incrementPartsWatchVar();
         }
      });

      return post;
   };

   public async updateFieldViewableByTechFieldDefault(field: PartField) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateFieldViewableByTechFieldDefault",
         },
         data: {
            locationID: field.locationID,
            fieldID: field.fieldID,
            newValue: field.viewableByTechFieldDefault,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      for (const value of this.fieldValues) {
         if (value.fieldID === field.fieldID) {
            value.viewableByTech = field.viewableByTechFieldDefault;
         }
      }

      return post;
   }

   public async updateDateReminder(field: string, value: number, fieldID: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateDateReminder",
         },
         data: {
            fieldToUpdate: field,
            value: value,
            fieldID: fieldID,
         },
      });

      const currentField = this.fields.get(fieldID);
      if (currentField) {
         currentField[field] = value;
      }

      this.dateReminderStreamMap.get(fieldID)?.next(null);

      return post;
   }

   public async updateDateReminderAssignments(
      fieldID: number,
      userID: number,
      profileID: number,
      multiUsers: Array<number>,
      locationID: number,
   ) {
      const multiUsersStringifed = JSON.stringify(multiUsers);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateDateReminderAssignments",
         },
         data: {
            fieldID: fieldID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringifed,
            locationID: locationID,
         },
      });
      return post;
   }

   public async removeField(fieldToRemove: PartFieldValue, part: Part) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "removeField",
         },
         data: {
            locationID: part.locationID,
            valueID: fieldToRemove.valueID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      this.fieldValues.delete(fieldToRemove.valueID);
      const index = part.partValueIDs.indexOf(fieldToRemove.valueID);
      part.partValueIDs.splice(index, 1);

      const valuesOnField: Array<PartFieldValue> = [];
      for (const valueID of part.partValueIDs) {
         const value = this.getFieldValue(valueID);
         if (value) {
            valuesOnField.push(value);
         }
      }

      for (const value of valuesOnField) {
         if (
            value.valueSort &&
            fieldToRemove.valueSort &&
            value.valueSort > fieldToRemove.valueSort
         ) {
            value.valueSort = value.valueSort - 1;
         }
      }

      this.updatePartFieldsObs();
      this.manageObservables.updateObservable(`partFields${part.partID}`, 1);

      return post;
   }

   public async removeSuggestedField(fieldToRemove: PartField) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "removeSuggestedField",
         },
         data: {
            locationID: fieldToRemove.locationID,
            fieldID: fieldToRemove.fieldID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      //remove this field from the list of possible fields.
      this.fields.delete(fieldToRemove.fieldID);
      this.dateReminderStreamMap.delete(fieldToRemove.fieldID);

      const valueIDsWithFieldID: Array<number> = [];
      const partIDsThatHaveThisValue: Set<number> = new Set();
      for (const [valueID, value] of this.fieldValues.entries()) {
         if (value.fieldID !== fieldToRemove.fieldID) {
            continue;
         }
         partIDsThatHaveThisValue.add(value.partID);
         valueIDsWithFieldID.push(valueID);
         this.fieldValues.delete(valueID);
      }

      for (const partID of partIDsThatHaveThisValue) {
         const part = this.parts.get(partID);
         assert(part);
         const index = part.partValueIDs.findIndex((valueID) =>
            valueIDsWithFieldID.includes(valueID),
         );
         part.partValueIDs.splice(index, 1);
      }

      this.updatePartFieldsObs();

      return post;
   }

   public async updateSuggestedFieldName(
      field: PartField,
      locationID: number,
      fieldName: string,
   ) {
      const newFieldName = cleanWordPaste(fieldName);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateSuggestedFieldName",
         },
         data: {
            locationID: locationID,
            fieldID: field.fieldID,
            fieldName: newFieldName,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      //have to update the fields in the other parts to make sure this corresponds properly
      field.fieldName = fieldName;
      this.incrementPartsWatchVar();
      this.updatePartFieldsObs();

      return post;
   }

   public addCategory = async (part: Part) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "addCategory",
         },
         data: {
            locationID: part.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const newCategory = post.data.cat;
      this.categories.set(newCategory.categoryID, newCategory);

      return post;
   };

   public async deleteFile(fileID: number, value: PartFieldValue, locationID: number) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "deleteDocument",
         },
         data: {
            fileID: fileID,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const index = value.partValueFileIDs.indexOf(fileID);
      value.partValueFileIDs.splice(index, 1);
      this.fieldValueFiles.delete(fileID);

      return post;
   }

   public async deleteCat(categoryID: number, locationID: number) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "deleteCat",
         },
         data: {
            catID: categoryID,
            locationID: locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      this.categories.delete(categoryID);

      for (const part of this.parts) {
         if (part.partID === categoryID) {
            part.categoryID = 0;
         }
      }

      return post;
   }

   public async updateCatID(part: Part, categoryID: number) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateCatID",
         },
         data: {
            partID: part.partID,
            catID: categoryID,
            locationID: part.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      part.categoryID = categoryID;

      return post;
   }

   public async updatePartName(part: Part) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartName",
         },
         data: {
            partID: part.partID,
            partName: part.partName,
            locationID: part.locationID,
         },
      });

      return post;
   }

   updatePartNumber = async (
      part: Pick<Part, "partID" | "partNumber" | "locationID">,
   ) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartNumber",
         },
         data: {
            partID: part.partID,
            partNumber: this.manageUtil.stripTags(part.partNumber ?? ""),
            locationID: part.locationID,
         },
      });

      return post;
   };

   public async updatePartLocation(part: Part) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartLocation",
         },
         data: {
            partID: part.partID,
            partLocation: part.partLocation,
            locationID: part.locationID,
         },
      });

      return post;
   }

   updatePartSupplier = async (part) => {
      part.partSupplier = cleanWordPaste(part.partSupplier);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartSupplier",
         },
         data: {
            partID: part.partID,
            partSupplier: part.partSupplier,
            locationID: part.locationID,
         },
      });

      return post;
   };

   public async updatePartQty(partID: number, reason: string) {
      const totalQuantity = this.getSingleCalculatedPartInfo(partID)?.totalQty;
      const part = this.getPart(partID);
      assert(part);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartQty",
         },
         data: {
            partID: part.partID,
            partQty: totalQuantity,
            locationID: part.locationID,
            reason: reason,
         },
      });

      if (!post.data.success == true) {
         return undefined;
      }
      //if we updated any extra batches then we need to update them appropriately
      if (post.data.updatedExtraBatches) {
         for (const updatedBatch of post.data.updatedExtraBatches) {
            // const batch = this.extraBatches.index[updatedBatch.extraBatchID];
            const batch = this.getExtraBatch(updatedBatch.extraBatchID);
            assert(batch);
            if (batch) {
               batch.partQtyUsed = Number(updatedBatch.partQtyUsed);
            }
         }
      }

      //if a task needs to be created because part qty threshold was met
      if (post.data.newTasks) {
         for (const task of post.data.newTasks) {
            this.manageTask.addTaskToLookup(task);
         }
      }

      if (post.data.newPartQty !== undefined) {
         part.partQty = Number(post.data.newPartQty);
      }

      if (post.data.partOverstocked !== undefined) {
         part.partOverstocked = post.data.partOverstocked;
      }

      this.calculatePartData(part);

      this.incrementPartsWatchVar();

      return post;
   }

   public async updatePartPrice(partID: number) {
      const part = this.getPart(partID);
      assert(part);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartPrice",
         },
         data: {
            partID: part.partID,
            partPrice: part.partPrice,
            locationID: part.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }
      const calculatedPartInfo = this.calculatePartData(part);
      assert(calculatedPartInfo);
      this.setSingleCalculatedPartInfo(partID, calculatedPartInfo);

      this.incrementPartsWatchVar();

      return post;
   }

   public async addPart(
      locationID: number,
      name: string,
      number: string,
      price: number,
      qty: number,
   ) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "addPart",
         },
         data: {
            locationID: locationID,
            name: name,
            number: number,
            price: price,
            qty: qty,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      const {
         data: {
            part: { partID: id },
            customDefaultFields,
         },
      } = this.createPartResponseDto.parse(post);

      const part = await firstValueFrom(this.fetchSinglePartAndAssociations(id));
      this.parts.set(id, part);

      const fieldValues = customDefaultFields.map<PartFieldValue>((fieldValue) => ({
         ...fieldValue,
         partValueFileIDs: [],
      }));
      fieldValues.forEach((fieldValue) => {
         this.fieldValues.set(fieldValue.valueID, fieldValue);
      });

      const calculatedData = this.calculatePartData(part);
      this.setSingleCalculatedPartInfo(id, calculatedData);
      this.incrementPartsWatchVar();

      return { ...post, data: { ...post.data, part } };
   }

   public async copyPart(
      sourcePartID: number,
      locationIDTarget: number,
      newName: string,
   ) {
      const cleanNewName = cleanWordPaste(newName);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "copyPart",
         },
         data: {
            sourcePartID: sourcePartID,
            locationIDTarget: locationIDTarget,
            newName: cleanNewName,
         },
      });

      await this.fetchPartsAndAssociationsByID([post.data.part.partID]);

      this.incrementPartsWatchVar();
      return post;
   }

   public async deletePart(part: Part) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "deletePart",
         },
         data: {
            partID: part.partID,
            locationID: part.locationID,
         },
      });

      if (!post.data.success == true) {
         return undefined;
      }
      const partRelations = this.manageTask.getPartRelations();

      for (const relation of partRelations) {
         if (relation.partID === part.partID) {
            partRelations.delete(relation.relationID);
            const task = this.manageTask.getTaskLocalLookup(relation.checklistID);
            const relationIDIndex = task?.partRelationIDs.indexOf(relation.relationID);
            if (relationIDIndex !== undefined && relationIDIndex !== -1) {
               task?.partRelationIDs.splice(relationIDIndex, 1);
            }
            this.manageTask.getAllPartRelationsByPartID().delete(part.partID);
         }
      }

      this.parts.delete(part.partID);
      this.incrementPartsWatchVar();

      return post;
   }

   public async deletePartsInBulk(partIDsToDelete: Array<number>) {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "deletePartsInBulk",
         },
         data: {
            parts: partIDsToDelete,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      for (const partID of partIDsToDelete) {
         this.parts.delete(partID);
      }

      this.incrementPartsWatchVar();
      return post;
   }

   public async deletePartImage(partID: number) {
      const part = this.getPart(partID);
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "deletePartImage",
         },
         data: {
            partID: partID,
            locationID: part?.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      assert(part);
      part.partImage = null;

      return post;
   }

   public async getLogs(part: Part) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "getLogs",
         },
         data: {
            partID: part.partID,
            locationID: part.locationID,
         },
      });

      return post;
   }

   public async addEntry(logDetails: string, part: Part) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "addEntry",
         },
         data: {
            partID: part.partID,
            logDetails: logDetails,
         },
      });

      return post;
   }

   public async updatePartStaleThreshold(part: Part) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartStaleThreshold",
         },
         data: {
            partID: part.partID,
            threshold: part.partStaleThreshold,
            locationID: part.locationID,
         },
      });

      return post;
   }

   public async updatePartOverstockedThreshold(part: Part) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartOverstockedThreshold",
         },
         data: {
            partID: part.partID,
            threshold: part.partOverstockedThreshold,
            locationID: part.locationID,
         },
      });
      post.then((answer) => {
         const partToUpdate = this.getPart(part.partID);
         assert(partToUpdate);
         partToUpdate.partOverstocked = answer.data.partOverstocked;
      });

      return post;
   }

   public async updatePartMaxQtyThreshold(part: Part) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updatePartMaxQtyThreshold",
         },
         data: {
            partID: part.partID,
            maxQtyThreshold: part.partMaxQtyThreshold,
            locationID: part.locationID,
         },
      });

      return post;
   }

   public async changeOverstockedStatus(partID: number) {
      const part = this.getPart(partID);
      if (!part) {
         return undefined;
      }
      if (part.partOverstocked == 0) {
         part.partOverstocked = 1;
      } else {
         part.partOverstocked = 0;
      }
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "changeOverstockedStatus",
         },
         data: {
            partID: part.partID,
            status: part.partOverstocked,
            locationID: part.locationID,
         },
      });

      if (!post.data.success) {
         return undefined;
      }

      return post;
   }

   public async updateThresholdAssignments(
      partID: number,
      userID: number,
      profileID: number,
      multiUsers: Array<any>,
      all: number,
      locationID: number,
   ) {
      const multiUsersStringifed = JSON.stringify(multiUsers);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateThresholdAssignments",
         },
         data: {
            partID: partID,
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringifed,
            all: all,
            locationID: locationID,
         },
      });

      return post;
   }

   downloadExcel = (
      partsToDownload: Lookup<"partID", Part>,
      name: string,
      includeURLs: boolean,
      includeOverstocked: boolean,
      includeExtraBatches: boolean,
      mergeExtraBatches: boolean,
      includeLastCounted: boolean,
      vendorsRelations: Lookup<"relationID", PartVendorRelation>,
      managePO: ManagePO,
   ) => {
      const partsReadyToDownload: any = [];
      let locationID;
      const extraBatches = this.getExtraBatches();
      const arrayOfParts: Array<Part> = Array.from(partsToDownload);

      for (const [index, part] of arrayOfParts.entries()) {
         //IMPORTANT do not remove index, we need it later which is why we are doing a for in
         locationID = part.locationID;
         const calculatedPartInfo = this.calculatedPartInfo.get(part.partID);

         const obj: any = {};
         if (includeURLs == false) {
            //if they want to see URLs we don't want partID so they don't accidently do a bulk update by importing back in.  Same with merging extra batches.
            obj["Part ID"] = part.partID;
         }
         obj["Part Name"] = part.partName;

         // Remove all HTML tags
         if (part.partNumber) {
            const cleanPartNumber = this.manageUtil.stripTags(part.partNumber);
            obj["Part Number"] = cleanPartNumber;
         } else {
            obj["Part Number"] = part.partNumber;
         }

         if (mergeExtraBatches == false) {
            obj.Quantity = part.partQty;
            obj.Price = part.partPrice;
         } else {
            obj.Quantity = calculatedPartInfo?.totalQty;
            obj.Price = calculatedPartInfo?.averagePrice;
         }

         const isUnitOfMeasureEnabled = this.unitOfMeasureService.isFeatureEnabled;
         if (isUnitOfMeasureEnabled()) {
            const unit = this.unitOfMeasureService.getUnit(part.unitDescription)();
            /** Race condition. Make this function async and await unit initialization. */
            assert(unit !== null);
            obj["Stock Unit"] = unit.short();
         }

         const cleanLocation = this.manageUtil.stripTags(part.partLocation ?? "");

         obj.Location = cleanLocation;
         obj.Category = this.getCategory(part.categoryID)?.categoryName;
         if (includeLastCounted) {
            if (part.lastCounted && part.lastCounted > 0) {
               obj.lastCounted = this.betterDate.formatBetterDate(
                  part.lastCounted * 1000,
                  "date",
               );
            } else {
               obj.lastCounted = part.lastCounted;
            }
         }
         obj["Stale Threshold"] = part.partStaleThreshold;
         if (includeOverstocked) {
            if (part.partOverstocked === 1) {
               //why we called this partOverstocked I have no freaking clue ;p
               obj.Understocked = "Yes";
            } else {
               obj.Understocked = "No";
            }
         }

         obj["Part Stale Status"] = part.partStale
            ? this.lang().Stale
            : this.lang().Fresh;

         obj["Min Part Qty Threshold"] = part.partOverstockedThreshold;
         obj["Max Part Qty threshold"] = part.partMaxQtyThreshold;
         // if (part.userID && part.userID > 0) {
         //    if (users[part.userID]) {
         //       obj["Threshold Tasks Assigned To (users email or a team name)"] =
         //          users[part.userID].userLoginName;
         //    } else {
         //       obj["Threshold Tasks Assigned To (users email or a team name)"] =
         //          "Manager";
         //    }
         // } else if (part.profileID && part.profileID > 0) {
         //    if (profiles[part.profileID]?.profileDescription) {
         //       obj["Threshold Tasks Assigned To (users email or a team name)"] =
         //          profiles[part.profileID].profileDescription;
         //    } else {
         //       obj["Threshold Tasks Assigned To (users email or a team name)"] =
         //          "Manager";
         //    }
         // } else {
         //    obj["Threshold Tasks Assigned To (users email or a team name)"] = "Manager";
         // }

         obj["Manually Associated Assets"] = "";

         obj["Manually Associated Vendors"] = "";

         //fields
         for (const valueID of part.partValueIDs) {
            const value = this.fieldValues.get(valueID);
            if (!value) {
               continue;
            }

            const field = this.fields.get(value.fieldID);
            if (!field) {
               continue;
            }

            //sets the appropriate field values
            if (field?.fieldName === "lastCounted" && !includeLastCounted) {
               continue;
            }

            if (field.fieldTypeID == 5 || field.fieldTypeID == 6) {
               if (
                  typeof value.valueContent === "string" ||
                  typeof value.valueContent === "number"
               ) {
                  //valid so let's set them as their
               } else {
                  value.valueContent = "";
               }
            }

            if (field.fieldTypeID == 2 && value.valueContent) {
               const date = new Date(value.valueContent);
               let str;

               if (String(date) === "Invalid Date") {
                  str = "";
               } else {
                  str = this.betterDate.formatBetterDate(date, "date");
               }

               this.setFieldNameAndValue(obj, field.fieldName, str);
            }

            // We format dates (fieldTypeID 2) differently above, so exclude them here.
            // Also exclude types 3 and 4 as they are handled below.
            if (![2, 3, 4].includes(field.fieldTypeID)) {
               this.setFieldNameAndValue(obj, field.fieldName, value.valueContent);
            }

            if (field.fieldTypeID == 3 || field.fieldTypeID == 4) {
               let tempValue = "";

               for (const fileID of value.partValueFileIDs) {
                  const file = this.fieldValueFiles.get(fileID);
                  if (!file) {
                     continue;
                  }
                  tempValue += `${file.fileName}, `;
               }

               if (tempValue.length > 2) {
                  tempValue = tempValue.substring(0, tempValue.length - 2);
               }

               this.setFieldNameAndValue(obj, field.fieldName, tempValue);
            }

            //the first row must ALWAYS have whatever fieldName or else the export won't work appropriately
            if (index > 0 && partsReadyToDownload[0][field.fieldName] === undefined) {
               partsReadyToDownload[0][field.fieldName] = "";
            }
         }

         for (const relation of this.assetsRelations) {
            if (relation.partID == part.partID) {
               const asset = this.manageAsset.getAsset(relation.assetID);
               if (asset !== undefined && asset.assetDeleted == 0) {
                  obj["Manually Associated Assets"] = `${
                     obj["Manually Associated Assets"] + asset.assetName
                  }; `;
               }
            }
         }

         if (obj["Manually Associated Assets"].length > 0) {
            obj["Manually Associated Assets"] = obj[
               "Manually Associated Assets"
            ].substring(0, obj["Manually Associated Assets"].length - 2);
         }
         // When doing a bulk part update, defaultVendorID is set as the first vendor on the list,
         // so we need to make sure that when we build our excel sheet, that we put the current
         // default vendor as the first vendor on the list.
         let foundDefaultVendor: boolean = false;
         for (const relation of vendorsRelations) {
            if (relation.partID == part.partID) {
               const vendor = this.manageVendor.getVendor(relation.vendorID);
               if (vendor !== undefined && vendor.vendorDeleted == 0) {
                  const vendorExportName = this.getVendorExportName(vendor, relation);
                  if (relation.vendorID == part.defaultVendorID) {
                     obj["Manually Associated Vendors"] =
                        `${vendorExportName}; ${obj["Manually Associated Vendors"]}`;
                     foundDefaultVendor = true;
                  } else {
                     obj["Manually Associated Vendors"] = `${
                        obj["Manually Associated Vendors"] + vendorExportName
                     }; `;
                  }
               }
            }
         }
         // This checks to see if the defaultVendor exists, but hasn't been added yet,
         // which means that it is an automatically associated vendor, so we need to add it to the list
         // so that the default vendor ID gets properly set by the bulk update function.
         if (part.defaultVendorID && !foundDefaultVendor) {
            const vendor = this.manageVendor.getVendor(part.defaultVendorID);
            if (vendor !== undefined && vendor.vendorDeleted == 0) {
               obj["Manually Associated Vendors"] =
                  `${vendor.vendorName}; ${obj["Manually Associated Vendors"]}`;
            }
         }
         if (obj["Manually Associated Vendors"].length > 0) {
            obj["Manually Associated Vendors"] = obj[
               "Manually Associated Vendors"
            ].substring(0, obj["Manually Associated Vendors"].length - 2);
         }

         if (includeURLs == true) {
            const host = `${window.location.protocol}//${window.location.host}`;

            obj["Part Lookup URL"] =
               `${host}/mobilePart/${part.partID}/${part.locationID}?m=true`;
         }

         partsReadyToDownload.push(obj);
         if (includeExtraBatches == true && mergeExtraBatches == false) {
            for (const batchID of part.partExtraBatchIDs) {
               const batch = extraBatches.get(batchID);
               if (batch == undefined) {
                  continue;
               }
               const poItem = managePO.getPurchaseOrderItem(batch.poItemID);
               if (!poItem?.poID) continue;
               const purchaseOrder = managePO.getPurchaseOrder(poItem.poID);
               if (!batch.partQty) {
                  batch.partQty = 0;
               }
               if (!batch.partQtyUsed) {
                  batch.partQtyUsed = 0;
               }
               const poNumber = purchaseOrder?.poNumber;
               const categoryName = this.getCategory(part.categoryID)?.categoryName;
               const extraBatchPart = {
                  "Part ID": "-1", //we set at negative one so we know not to do anything with it on an import
                  "Part Name": part.partName,
                  "Part Number": part.partNumber,
                  "Quantity": batch.partQty - batch.partQtyUsed,
                  "Price": batch.partPrice,
                  "Part Location": part.partLocation,
                  "Supplier": `PO - #${poNumber}`,
                  "Category": categoryName,
                  "Stale Threshold": "",
                  "Part Qty Threshold": "",
                  "Threshold Tasks Assigned To (users email or a team name)": "",
                  "Understocked": "",
                  "Manually Associated Assets": "",
               };

               if (isUnitOfMeasureEnabled()) {
                  const unit = this.unitOfMeasureService.getUnit(part.unitDescription)();
                  assert(unit !== null);
                  extraBatchPart["Stock Unit"] = unit.short();
               }

               partsReadyToDownload.push(extraBatchPart);
            }
         }
      }

      if (partsReadyToDownload[1]) {
         //doing a little check here to make sure all fields are included on a download.  Previously it only shows fields if an asset has that value somewhere
         for (const field of this.fields) {
            if (field?.fieldName === "lastCounted" && !includeLastCounted) {
               continue;
            }
            if (field.locationID == locationID) {
               if (partsReadyToDownload[1][field.fieldName] === undefined) {
                  partsReadyToDownload[1][field.fieldName] = "";
               }
            }
         }
         this.updatePartFieldsObs();
      }

      const today = this.betterDate.createTodayTimestamp();

      this.manageUtil.objToExcel(partsReadyToDownload, "Parts", `${name}-${today}.xlsx`);
   };

   private setFieldNameAndValue(obj: any, fieldName: string, value: any): void {
      const cleanValue = this.manageUtil.stripTags(value);
      if (!this.usingForbiddenFieldNames(fieldName)) obj[fieldName] = cleanValue;
   }

   private getVendorExportName(vendor: Vendor, relation: any): string {
      let exportName = vendor.vendorName ?? "";
      const vendorPartNumber = relation.partNumber ?? "";
      const vendorPartPrice = relation.partPrice ?? "";
      if (relation.partNumber || relation.partPrice) {
         exportName += ` | ${vendorPartNumber} | ${vendorPartPrice}`;
      }
      return exportName;
   }

   public setPODescription(arr: string | null): string {
      if (arr == null) {
         return "";
      }
      const description = arr.split(",");

      if (description.length < 1 || description[0] == "") {
         return "";
      }

      return description.reduce((previousValue, currentValue) => {
         return `${previousValue}{{ ${currentValue} }}`;
      }, "");
   }

   public generateDescriptionForPart(
      poItemSkeleton: PurchaseOrderItemToAddSkeleton,
      part: Part,
   ): string {
      const PODescription = this.manageLocation.getLocationPODescription(part.locationID);
      if (PODescription == null) {
         return "";
      }
      if (poItemSkeleton.description !== "" && poItemSkeleton.description !== null) {
         return poItemSkeleton.description;
      }
      const fields = PODescription.split(",");

      let newDescription = "";

      for (const fieldValueID of part.partValueIDs) {
         const fieldValue = this.getFieldValue(fieldValueID);
         assert(fieldValue);
         const field = this.getField(fieldValue?.fieldID);

         for (const POField of fields) {
            if (field?.fieldName === POField) {
               newDescription += `${fieldValue.valueContent} `;
            }
         }
      }

      return newDescription;
   }

   public prepListOfPartsUsage(tasks: TaskLookup | Array<Task>): Array<{
      usedNumber: number;
      totalCost: number;
      partID: number;
      task?: any;
      checklistCompletedDate?: number;
      partName?: string;
      partNumber?: string;
   }> {
      //now that we know which tasks should be looked at let's loop through and create the parts for it...

      const partsUsageIndex: {
         [key: string]: {
            usedNumber: number;
            totalCost: number;
            partID: number;
            task?: any;
            checklistCompletedDate?: number;
            partName?: string;
            partNumber?: string;
            processedRelationIDs: Set<number>; // Add this to track processed relations
         };
      } = {};
      for (const task of tasks) {
         task.partRelationIDs.forEach((partRelationID) => {
            const partRelation = this.manageTask.getPartRelation(partRelationID);
            //if it isn't set yet lets set defaults
            if (partRelation !== undefined) {
               const key = String(partRelation.partID) + task.checklistID;
               if (partsUsageIndex[key] === undefined) {
                  partsUsageIndex[key] = {
                     usedNumber: 0,
                     totalCost: 0,
                     partID: partRelation.partID,
                     processedRelationIDs: new Set(),
                  };
               }
               // Only process this relation if we haven't seen it before
               if (
                  !partsUsageIndex[key].processedRelationIDs.has(partRelation.relationID)
               ) {
                  partsUsageIndex[key].processedRelationIDs.add(partRelation.relationID);
                  partsUsageIndex[key].usedNumber += Number(partRelation.usedNumber);

                  // Update total cost only for unique relations
                  if (
                     partRelation.usedPrice !== null &&
                     partRelation.usedNumber !== null
                  ) {
                     partsUsageIndex[key].totalCost +=
                        partRelation.usedPrice * partRelation.usedNumber;
                  }
               }

               if (partRelation.checklistID !== null) {
                  const partRelationTask = this.manageTask.getTaskLocalLookup(
                     partRelation.checklistID,
                  );
                  if (partRelationTask !== undefined) {
                     partsUsageIndex[key].task = partRelationTask;
                     const checklistCompletedDate =
                        partRelationTask.checklistCompletedDate;
                     if (checklistCompletedDate !== null) {
                        partsUsageIndex[key].checklistCompletedDate =
                           checklistCompletedDate;
                     }
                  }
               }
            }
         });
      }

      const listParts: Array<{
         usedNumber: number;
         totalCost: number;
         partID: number;
         task?: any;
         checklistCompletedDate?: number;
         partName?: string;
         partNumber?: string;
      }> = [];
      for (const key in partsUsageIndex) {
         //key as for in because it is a lookup we are looping through
         const partUsageItem = partsUsageIndex[key];
         const part = this.getPart(partUsageItem.partID);

         if (part) {
            partUsageItem.partName = part.partName ?? "";
            partUsageItem.partNumber = part.partNumber ?? "";
            listParts.push(partUsageItem);
         }
      }

      return listParts;
   }

   refreshExtraBatchForPartsOnATask = async (checklistID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "refreshExtraBatchForPartsOnATask",
         },
         data: {
            checklistID: checklistID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            if (answer.data.extraBatches) {
               for (const batch of answer.data.extraBatches) {
                  const batchInMem = this.getExtraBatch(batch.extraBatchID);
                  if (batchInMem) {
                     batchInMem.partQty = batch.partQty;
                     batchInMem.partQtyUsed = batch.partQtyUsed;
                     batchInMem.partPrice = batch.partPrice;
                  } else {
                     this.addExtraBatchToLookup(batch);
                  }
               }
            }
         }
      });

      return post;
   };

   /**
    * Updates the `isCustomDefault` property of a "part field" object and saves
    * to the database.
    * @param fieldID - The ID of the part field object to update
    * @param isCustomDefault - The new value for the `isCustomDefault` property
    * @returns true if successful or false if failed.
    */
   public async updateFieldIsCustomDefault(
      fieldID: number,
      isCustomDefault: boolean | 0 | 1,
   ): Promise<boolean> {
      const newValue = isCustomDefault ? 1 : 0;
      return axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateFieldIsCustomDefault",
         },
         data: {
            fieldID: fieldID,
            isCustomDefault: newValue,
         },
      })
         .then((answer) => {
            if (answer.data.success === false) {
               return false;
            }
            const field = this.getFields().get(fieldID);
            assert(field);
            field.isCustomDefault = newValue;
            return true;
         })
         .catch(() => {
            return false;
         });
   }

   public async updateValueUnique(fieldID: number, value: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "updateValueUnique",
         },
         data: {
            fieldID: fieldID,
            value: value,
         },
      });

      return post;
   }

   /****************************************
    *@function checkPartNameUnique
    *@purpose
    *@name checkPartNameUnique
    *@param
    *@return
    ****************************************/
   public async checkPartNameUnique(partName: string, locationID: number) {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "checkPartNameUnique",
         },
         data: {
            partName: partName,
            locationID: locationID,
         },
      });

      return post;
   }

   /****************************************
    *@function getAllConsumedParts
    *@purpose
    *@name getAllConsumedParts
    *@param
    *@return
    ****************************************/
   getAllConsumedParts = async () => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "getAllConsumedParts",
         },
      });

      return post;
   };

   public async getMissingPartInfoForTask(
      partIDs: Array<number>,
   ): Promise<AxiosResponse> {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "getMissingPartInfoForTask",
         },
         data: {
            partIDs: partIDs,
         },
      });

      post.then((answer) => {
         if (!answer.data.success) {
            return;
         }

         // I do not believe this is doing anything. We don't need the part relations for this.
         // We can clean this up in the future when we port this over to Flannel. This was likely
         // a catch all that was added just in case we need the part relations in the future.
         const partRelations = this.manageTask.getPartRelations();
         for (const relation of answer.data.partRelations) {
            partRelations.set(relation.relationID, relation);
            const task = this.manageTask.getTaskLocalLookup(relation.checklistID);
            if (task) {
               task.partRelationIDs.push(relation.relationID);
            }
            this.manageTask.addPartRelationByChecklistIDToLocalData(relation);
         }
         this.fetchPartsAndAssociationsByID(partIDs);
      });

      return post;
   }

   public calculateDataForAllParts() {
      for (const part of this.parts) {
         this.calculatePartData(part);
      }
      this.partDataCalculated$.next(true);
   }

   public checkForDuplicateNameAndNumber(partID: number) {
      const partToCheckAgainst = this.getPart(partID);
      assert(partToCheckAgainst);
      const partsWithNameAndNumber = this.parts.filter(
         (part) =>
            part.partDeleted === 0 &&
            part.locationID === partToCheckAgainst.locationID &&
            part.partName === partToCheckAgainst.partName &&
            part.partNumber === partToCheckAgainst.partNumber,
      );
      return partsWithNameAndNumber.size > 1;
   }

   public getPartFromPartNumber(partNumber: string) {
      return this.parts.find((part) => part.partNumber?.trim() === partNumber.trim());
   }

   public getPartInfoForTaskPart(taskPart: TaskPartRelation) {
      if (taskPart?.partID === null) return undefined;
      const part = this.getPart(taskPart.partID);
      if (part === undefined) return undefined;
      return {
         partName: part.partName,
         partNumber: part.partNumber,
      };
   }

   public async saveFieldLockedDefault(field: PartField): Promise<AxiosResponse> {
      const userID = this.manageUser.getCurrentUser().userInfo.userID;

      const post = axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "saveFieldLockedDefault",
         },
         data: {
            fieldLocked: field.lockedDefault,
            fieldID: field.fieldID,
            userID,
         },
      });

      return post;
   }

   public async setPartUnitOfMeasure(partID: number, unit: Unit): Promise<void> {
      const part = this.getPart(partID);
      assert(part !== undefined, `Part with ID ${partID} not found`);

      const unitDescription = this.unitOfMeasureService.convertUnitToDescription(unit);
      part.unitDescription = unitDescription;

      await this.updatePartUnitOfMeasure({ partID, unitDescription });

      this.parts.set(part.partID, part);
      this.incrementPartsWatchVar();
   }

   private async updatePartUnitOfMeasure(
      partUpdateDto: Pick<Part, "partID" | "unitDescription">,
   ): Promise<AxiosResponse<Part>> {
      return this.axios.patch(`${environment.flannelUrl}/parts/${partUpdateDto.partID}`, {
         unitDescription: partUpdateDto.unitDescription,
      });
   }

   private fetchSinglePartAndAssociations(id: number): Observable<Part> {
      return this.httpClient
         .get(`${environment.flannelUrl}/parts`, { params: { partIDs: [id] } })
         .pipe(
            map((part) => z.array(partAndAssociationDto).parse(part)),
            map((part) => part[0]),
         );
   }

   public async updateThresholdTaskType(
      partUpdateDto: Pick<Part, "partID" | "minThresholdTaskType">,
   ): Promise<AxiosResponse<Part>> {
      return this.axios.patch(`${environment.flannelUrl}/parts/${partUpdateDto.partID}`, {
         minThresholdTaskType: partUpdateDto.minThresholdTaskType,
      });
   }

   public async updateMinThresholdTaskComplete(
      partUpdateDto: Pick<Part, "partID" | "minThresholdTaskClose">,
   ): Promise<AxiosResponse<Part>> {
      return this.axios.patch(`${environment.flannelUrl}/parts/${partUpdateDto.partID}`, {
         minThresholdTaskClose: partUpdateDto.minThresholdTaskClose,
      });
   }

   public async updatesetMinThresholdDueDays(
      partUpdateDto: Pick<Part, "partID" | "minThresholdTaskDueDays">,
   ): Promise<AxiosResponse<Part>> {
      return this.axios.patch(`${environment.flannelUrl}/parts/${partUpdateDto.partID}`, {
         minThresholdTaskDueDays: partUpdateDto.minThresholdTaskDueDays,
      });
   }

   public async copyPartImage(
      sourcePartID: number,
      targetPartID: number,
   ): Promise<AxiosResponse> {
      const post = await axios({
         method: "POST",
         url: "phpscripts/managePart.php",
         params: {
            action: "copyPartImage",
         },
         data: {
            sourcePartID,
            targetPartID,
         },
      });

      const part = await firstValueFrom(
         this.fetchSinglePartAndAssociations(targetPartID),
      );
      this.parts.set(targetPartID, part);

      return post;
   }
}
