import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "view-list-of-vendors-for-part",
   templateUrl: "./viewListOfVendorsForPart.modal.component.html",
   styleUrls: ["./viewListOfVendorsForPart.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      PrimaryButtonComponent,
      IconComponent,
      MinimalIconButtonComponent,
      TooltipDirective,
      OrderByPipe,
   ],
})
export class ViewListOfVendorsForPart implements OnInit, OnDestroy {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public zeroManualVendorsMsg;
   public zeroPurchasedFromVendorsMsg;
   public showPurchaseOrder;
   public part: Part | undefined;
   public location;
   public manualVendors: Array<Vendor> = [];
   public purchasedFromVendors: Array<Vendor> = [];
   public data;
   public changePartVendors;
   public poWatchVarSub;
   public prWatchVarSub;
   public vendorWatchVarSub;
   public InitialLoadCheck = 0;
   public vendorRelationInfo:
      | Lookup<
           "vendorID",
           {
              automaticPartRelation?: boolean;
              manualPartRelation?: boolean;
              locationName: string;
              tmpPOItemsIDs: Array<number>;
              vendorID: number;
              POs?: any;
              defaultForPartPO?: boolean;
              showDetails?: boolean;
           }
        >
      | undefined;
   public partsWatchVarSub: Subscription | null | undefined;
   protected dataLogOptions: DataLogEventDefinition | undefined;

   private readonly manageParts = inject(ManageParts);
   private readonly managePO = inject(ManagePO);
   private readonly credService = inject(CredService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageVendor = inject(ManageVendor);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.dataLogOptions = this.resolve.dataLogOptions;
      this.data = this.resolve.data;
      this.message = this.lang().PartVendorAssociationsMessage;
      this.title = this.lang().PartVendorAssociations;
      this.zeroManualVendorsMsg = this.lang().NoManualAssociationsFound;
      this.zeroPurchasedFromVendorsMsg = this.lang().YouHaveNotPurchasedFromThisVendorYet;

      this.poWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.watchData();
         },
      );

      this.prWatchVarSub = this.manageObservables.setSubscription(
         "OpenBillWatchVar",
         () => {
            this.watchData();
         },
      );

      this.vendorWatchVarSub = this.manageObservables.setSubscription(
         "vendorsWatchVar",
         () => {
            this.watchData();
         },
      );
      this.partsWatchVarSub = this.manageObservables.setSubscription(
         "partsWatchVar",
         () => {
            this.watchData();
         },
      );
      this.manageAssociations.partVendorRelationUpdates$.subscribe((newRelation) => {
         const vendorRelation = this.vendorRelationInfo?.get(newRelation.vendorID);
         if (!vendorRelation) {
            return;
         }

         this.vendorRelationInfo?.set(vendorRelation.vendorID, {
            ...vendorRelation,
            ...newRelation,
         });
         if (newRelation.defaultForPartPO && this.part) {
            this.part.defaultVendorID = newRelation.vendorID;
         }
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeManySubscriptions([
         this.poWatchVarSub,
         this.prWatchVarSub,
         this.vendorWatchVarSub,
         this.partsWatchVarSub,
      ]);
   }

   watchData = () => {
      if (this.InitialLoadCheck < 2) {
         this.InitialLoadCheck++;
         return;
      }
      if (this.managePO.getOpenPurchaseOrderWatchVar() > 0) {
         this.part = this.manageParts.getPart(this.data.partID);
         if (!this.part) {
            throw new Error("Bad partID in viewListOfVendorsForPart");
         }

         this.changePartVendors = this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartVendorAssociations,
         );

         this.location = this.manageLocation.getLocation(this.part.locationID);
         this.setVendors();
      }
   };

   setVendors = () => {
      if (!this.part) {
         return;
      }
      const allVendors = this.manageVendor.getVendors();

      this.vendorRelationInfo = this.manageAssociations.getAssociatedVendorsForPart(
         this.data.partID,
         this.managePO.getPurchaseOrderItemsByPartID(this.data.partID),
         this.managePO,
      );
      if (!this.vendorRelationInfo) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return;
      }
      this.manualVendors = [];
      this.purchasedFromVendors = [];
      for (const [vendorID, relation] of this.vendorRelationInfo.entries()) {
         const vendor = allVendors.get(vendorID);
         if (!vendor) {
            continue;
         }
         if (relation.manualPartRelation == true) {
            this.manualVendors.push(vendor);
         }
         if (relation.automaticPartRelation == true) {
            this.purchasedFromVendors.push(vendor);
            for (const poItemID of relation.tmpPOItemsIDs) {
               const purchaseOrder = this.managePO.getPurchaseOrder(poItemID);

               if (!relation.POs) {
                  relation.POs = [purchaseOrder];
                  continue;
               }
               relation.POs.push(purchaseOrder);
            }
         }
         relation.defaultForPartPO = vendorID === this.part.defaultVendorID;
      }
   };

   popPoComponent = (poID) => {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   };

   associate = () => {
      if (!this.part) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartVendorAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred141Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PickVendors);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().PickVendors,
            dataLogOptions: this.dataLogOptions,
            data: {
               singleLocation: 0,
               selectOne: false,
               restrictToCred: false,
               iDontKnowOption: false,
               locationID: this.part.locationID,
            },
         },
      };

      instance.result.then((pickedVendors) => {
         if (pickedVendors === "unsure" || !pickedVendors.length || !this.part) {
            return;
         }
         this.manageAssociations
            .associateVendorsToPart(pickedVendors, this.part)
            .then((answer) => {
               if (answer?.data.success == true) {
                  this.managePO.incOpenBillWatchVar();
                  this.alertService.addAlert(this.lang().successMsg, "success", 2000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               }
            });
      });
   };

   popVendor = (vendor) => {
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   breakRelation = (vendor: Vendor) => {
      if (!this.part) {
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ChangePartVendorAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred141Fail, "danger", 10000);
         return;
      }

      this.manageAssociations
         .deleteManualPartVendorAssociation(vendor.vendorID, this.part.partID)
         .then((answer) => {
            if (answer?.data.success == true) {
               this.managePO.incOpenBillWatchVar();
               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   };

   close = () => {
      this.modalInstance.close(0);
   };

   public updateShowDetailsForVendor(vendorID: number) {
      const relationInfo = this.vendorRelationInfo?.get(vendorID);
      if (!relationInfo) {
         return;
      }
      relationInfo.showDetails = !relationInfo.showDetails;
   }
}
