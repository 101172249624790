import { computed, inject, Injectable, resource, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import type {
   GetPmSuggestionsParams,
   PmSuggestionsFilters,
   PmSuggestionsSort,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource-types";
import { PmSuggestionsResourceService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource.service";

@Injectable()
export class PmBuilderPanelService {
   private readonly pmSuggestionsResourceService = inject(PmSuggestionsResourceService);

   /** The filters that the program enforces on the suggestions, and which the user cannot change. */
   public readonly limits = signal<PmSuggestionsFilters>({});

   /** The user-defined filters. */
   public readonly userParams = signal<GetPmSuggestionsParams>({});

   /** The sort order of the suggestions. */
   public readonly sort = signal<PmSuggestionsSort>({
      attribute: "id",
      direction: "desc",
   });

   /** The combined params from both the app and the user, giving priority to the app's limits. */
   public readonly combinedParams = computed(() => {
      const userParams = this.userParams();
      const limits = this.limits();
      const filters = {
         ...userParams.filters,
         ...limits,
      };
      const combined: GetPmSuggestionsParams = {};
      if (Object.keys(filters).length > 0) {
         combined.filters = filters;
      }
      if (userParams.pagination !== undefined) {
         combined.pagination = userParams.pagination;
      }
      return combined;
   });

   /** The list of suggestions that match the combined params. */
   public readonly filteredSuggestions = resource({
      request: () => this.combinedParams(),
      loader: async ({ request }) => this.pmSuggestionsResourceService.get(request),
   }).asReadonly();

   /** The total number of suggestions, */
   public readonly count = resource({
      request: () => this.limits(),
      loader: async ({ request }) => {
         /* TODO(WRENCH-297): Use a more efficient method to get the count */
         const data = await this.pmSuggestionsResourceService.get({ filters: request });
         return data.length;
      },
   }).asReadonly();

   public constructor() {
      this.pmSuggestionsResourceService.mutations
         .pipe(takeUntilDestroyed())
         .subscribe(() => {
            this.filteredSuggestions.reload();
            this.count.reload();
         });
   }
}
