import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   DropdownResultsPerPageComponent,
   DropdownTextItemComponent,
   IconButtonComponent,
   IconComponent,
   LoadingAnimationComponent,
   PaginationComponent,
   PanelComponent,
   ScrollContainerComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import clone from "rfdc";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { DashboardCurrencyService } from "src/app/dashboards/dashboard-currency.service";
import { DashboardListViewObjColumns } from "src/app/dashboards/widgets/list/dashboardListViewObjColumnsElement/dashboardListViewObjColumns.element.component";
import { DashboardListViewObjColumnsHeaders } from "src/app/dashboards/widgets/list/dashboardListViewObjColumnsHeadersElement/dashboardListViewObjColumnsHeaders.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task, TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

const deepClone = clone();

type TaskDisplay = Task & {
   searchHint: string;
   uniqueIndex: number;
   uniqueID: number;
   assetNameStr: string;
};

@Component({
   selector: "view-list-of-any-obj",
   templateUrl: "./viewListOfAnyObj.element.component.html",
   styleUrls: ["./viewListOfAnyObj.element.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      IconButtonComponent,
      TooltipDirective,
      SearchBoxComponent,
      NoSearchResults,
      NgClass,
      DashboardListViewObjColumnsHeaders,
      ScrollContainerComponent,
      DashboardListViewObjColumns,
      LoadingAnimationComponent,
      PaginationComponent,
      DropdownResultsPerPageComponent,
      DropdownTextItemComponent,
      OrderByPipe,
      SliceArray,
   ],
})
export class ViewListOfAnyObj implements OnInit {
   @Input() public objs;
   @Input() public columns;
   @Input() public options;
   @Input() public tableInScrollablePage = false;

   public itemsPerPage;
   public searchBar;
   public showObjs;
   public haveDataToDisplay;
   public showLoading;
   public page: number = 1;
   public searchHints: LimbleMap<number, string> = new LimbleMap();

   private readonly manageUtil = inject(ManageUtil);
   private readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUser = inject(ManageUser);
   private readonly manageParts = inject(ManageParts);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageLang = inject(ManageLang);
   private readonly dashboardCurrencyService = inject(DashboardCurrencyService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      if (this.options?.itemsPerPage == undefined) {
         if (this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage) {
            this.itemsPerPage =
               this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage;
         } else {
            this.itemsPerPage = 10;
         }
      }

      this.buildView();
   }

   buildView = () => {
      this.page = 1;
      if (this.objs != undefined) {
         let objs = this.objs;

         // If search input
         if (this.searchBar && this.searchBar.length > 0) {
            if (
               (objs[0] &&
                  objs[0].checklistID === undefined &&
                  objs[0].checklistName === undefined &&
                  objs[0].notes === undefined) ||
               // POs have a checklistID, so they fail the above check, hence this other check below
               objs[0]?.poID !== undefined
            ) {
               const tempObjs: any = [];
               //we aren't searching tasks so do normal filters
               let count = 1;

               for (const obj of objs) {
                  for (const key in obj) {
                     let include = false;
                     if (key !== "$$hashKey") {
                        const toTest = `${obj[key]}`;
                        if (toTest.toLowerCase().includes(this.searchBar.toLowerCase())) {
                           include = true;
                        }
                     }
                     if (include) {
                        obj.uniqueIndex = count; //have to have a unique index to avoid duplicates
                        tempObjs.push(obj);
                        count++;
                        break;
                     }
                  }
               }
               objs = tempObjs;
            } else {
               //we are searching tasks so do the correct search function
               const tasksToCheck: TaskLookup = new Lookup("checklistID");

               for (const pseudoTask of objs) {
                  const task = this.manageTask.getTaskLocalLookup(pseudoTask.checklistID);

                  if (task) {
                     const clonedTask = deepClone(task);

                     if (pseudoTask.useExtraAsset) {
                        clonedTask.assetID = pseudoTask.assetID;
                     }

                     tasksToCheck.set(clonedTask.checklistID, clonedTask);
                  }
               }

               const filteredTaskInfo = this.manageFilters.filterTasksToSearch(
                  tasksToCheck,
                  this.searchBar,
                  this.manageTask,
                  this.manageLocation,
                  this.manageUser,
                  this.manageUtil,
                  this.manageAsset,
                  this.manageInvoice,
                  this.manageParts,
                  this.manageSchedule,
               );
               this.searchHints = filteredTaskInfo.searchHints;
               const taskSearchResults: Array<TaskDisplay> = [];

               let uniqueIdentifier = 1;

               for (const obj of objs) {
                  const task = filteredTaskInfo.tasks.get(obj.checklistID);
                  if (task === undefined) continue;

                  const searchHint =
                     filteredTaskInfo.searchHints.get(task.checklistID) ?? "";

                  taskSearchResults.push({
                     ...obj,
                     searchHint,
                     uniqueIndex: uniqueIdentifier,
                     uniqueID: uniqueIdentifier,
                     assetNameStr: "",
                  });

                  uniqueIdentifier++;
               }

               objs = taskSearchResults;
            }
            // Else if no search input
         } else {
            let count = 1;
            for (const obj of objs) {
               obj.uniqueIndex = count; //have to have a unique index to avoid duplicates
               if (obj.locationID) {
                  obj.currencyCode =
                     this.dashboardCurrencyService.getCurrencyCodeByLocationID(
                        obj.locationID,
                     );
               }
               count++;
            }
         }

         this.showObjs = objs;

         this.haveDataToDisplay = this.showObjs.length !== 0;
      }
   };

   searchFilter = () => {
      this.buildView();
   };

   downloadExcel = () => {
      const todayAsString = this.betterDate.createTodayTimestamp();

      this.showLoading = true;

      const tempObjs: Array<any> = Array.from(this.showObjs);

      const download: any = [];

      for (const obj of tempObjs) {
         if (obj.task) {
            obj.assetNameStr = this.manageAsset.getAssetNameIncludeParents(
               obj.task.assetID,
            );
         } else if (obj.checklistID) {
            const task = this.manageTask.getTaskLocalLookup(obj.checklistID);

            if (task?.assetID) {
               obj.assetNameStr = this.manageAsset.getAssetNameIncludeParents(
                  task.assetID,
               );
            }
         }

         if (obj.useExtraAsset) {
            obj.assetNameStr = this.manageAsset.getAssetNameIncludeParents(obj.assetID);
            delete obj.assetID;
            delete obj.useExtraAsset;
            delete obj.assetName;
            delete obj.assetNameString;
         }
      }
      const rowConversionToLangMap = new Map<string, string>([
         ["userDisplayName", this.lang().User],
         ["locationName", this.lang().LocationName],
         ["timeSpent", this.lang().TimeSpent],
         ["checklistName", this.lang().TaskName],
         ["completionNotes", this.lang().CompletionNotes],
         ["checklistID", this.lang().TaskID],
         ["assetNameStr", this.lang().AssetName],
         ["usedNumber", this.lang().TotalUsed],
         ["partNumber", this.lang().PartNumber],
         ["partName", this.lang().PartName],
         ["totalCost", this.lang().TotalCost],
         ["partID", this.lang().PartID],
      ]);
      const rowConversionToLangMapDateFormatting = new Map<string, string>([
         ["date", this.lang().Date],
         ["dueDate", this.lang().DueDate],
         ["completedDate", this.lang().CompletedDate],
         ["checklistCompletedDate", this.lang().Date],
         ["assetCreatedDate", this.lang().CreatedDate],
         ["assetDeletedDate", this.lang().DeletedDate],
         ["assetLastEdited", this.lang().LastEdited],
         ["partDeletedDate", this.lang().DeletedDate],
         ["partLastEdited", this.lang().LastEdited],
         ["lastCounted", this.lang().LastCounted],
         ["expectedDate", this.lang().ExpectedDate],
      ]);

      for (const tempObj of tempObjs) {
         const obj = {};

         for (const key2 in tempObj) {
            const val = tempObj[key2];

            if (
               key2 === "$hashKey" ||
               key2 === "$$hashKey" ||
               key2 === "task" ||
               key2 === "searchHint" ||
               key2 === "uniqueIndex"
            ) {
               //don't do anything for these...
            } else if (rowConversionToLangMap.has(key2)) {
               const name: string = rowConversionToLangMap.get(key2) ?? "";
               if (key2 === "timeSpent") {
                  // Convert seconds to hours
                  const hours = (val / 3600).toFixed(2);
                  // Convert string to float
                  obj[name] = parseFloat(hours);
               } else {
                  obj[name] = val;
               }
            } else if (rowConversionToLangMapDateFormatting.has(key2)) {
               const nameToFormat: string =
                  rowConversionToLangMapDateFormatting.get(key2) ?? "";
               obj[nameToFormat] = this.betterDate.formatBetterDate(val * 1000, "date");
            } else if (key2 === "estimatedTime") {
               obj[this.lang().EstimatedTime] = `${(val / 60 / 60).toFixed(2)} ${
                  this.lang().hrs
               }`;
            } else if (key2 === "partQty") {
               obj[key2] = this.getTotalPartQty(tempObj);
            } else if (key2 === "parentAssetID") {
               obj[key2] = val;
               /**
                * Lint requires dot notation, but typescript says the property doesn't exist on the object when using dot notation.
                * Use a variable to access the property and make lint and typescript happy.
                */
               const nameKey = "parentAssetName";
               obj[nameKey] = this.manageAsset.getAsset(val)?.assetName ?? "";
            } else {
               obj[key2] = val;
            }
         }

         download.push(obj);
      }

      this.manageUtil.objToExcel(download, "list", `download-${todayAsString}.xlsx`);
      this.showLoading = false;
   };

   private getTotalPartQty(tempPartInput: Part): number | string {
      return (
         this.manageParts.getSingleCalculatedPartInfo(tempPartInput.partID)?.totalQty ??
         this.lang().Error
      );
   }

   updateUIPref = () => {
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage =
         this.itemsPerPage;
      this.manageUser.updateUserUIPreferences();
   };
}
