import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, forwardRef, computed } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { PartAssetAssociationInfo } from "src/app/parts/types/asset-relation/asset-relation.types";
import type { Part } from "src/app/parts/types/part.types";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "part-assets-tab",
   templateUrl: "./part-assets-tab.component.html",
   styleUrls: ["./part-assets-tab.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      PrimaryButtonComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      forwardRef(() => AssetParentList),
      MinimalIconButtonComponent,
      LoadingAnimationComponent,
   ],
})
export class PartAssetsTabComponent implements OnInit, OnDestroy {
   @Input() public restrict: boolean | undefined;
   @Input() public part: Part | undefined;
   public location;
   public locationID;
   public loadingBar = true;
   public credAssetAssocation;
   public assetRelations: Array<PartAssetAssociationInfo> = [];
   public locationWatchVarSub;
   public partAssetRelationUpdatesSub: Subscription;

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly route = inject(ActivatedRoute);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.partAssetRelationUpdatesSub =
         this.manageAssociations.partAssetRelationUpdates$.subscribe((updates) => {
            const assetRelation = this.assetRelations.find(
               (relation) => relation.assetID === updates.assetID,
            );

            const currentRelationIndex = this.assetRelations.findIndex(
               (relation) => relation.assetID === updates.assetID,
            );
            if (!updates || !assetRelation) {
               return;
            }
            this.assetRelations[currentRelationIndex] = {
               ...assetRelation,
               ...updates,
            };
         });
   }

   public ngOnInit() {
      assert(this.part);
      this.locationID = Number(
         this.route.snapshot.paramMap.get("locationID") ?? this.part.locationID,
      );

      this.credAssetAssocation = this.credService.isAuthorized(
         this.locationID,
         this.credService.Permissions.ChangePartAssetAssociations,
      );

      this.locationWatchVarSub = this.manageObservables.setSubscription(
         "locationWatchVar",
         () => {
            this.location = this.manageLocation.getLocation(this.locationID);
         },
      );

      this.refetchAssets();
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.locationWatchVarSub);
      this.partAssetRelationUpdatesSub.unsubscribe();
   }

   associateAssetToPart = () => {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.ChangePartAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred78Fail, "danger", 10000);
         return;
      }

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      assert(this.part);

      instance.message = `${this.lang().PleaseChooseWhichAssetsYouWouldLikeToAssociateWithThePart} ${this.part?.partName}`;
      instance.title = `${this.lang().AssociateAssetsToThePart} <b>${this.part?.partName}</b>`;
      instance.singleLocation = 0;
      instance.selectOne = false;
      instance.restrictToCred = true;
      instance.iDontKnowOption = false;

      modalRef.result.then((assets) => {
         assert(this.part);
         if (assets.length > 0) {
            this.manageAssociations
               .associateAssetsToPart(assets, this.part.partID)
               .then((answer) => {
                  if (answer?.data.success == true) {
                     this.alertService.addAlert(
                        this.lang().AssetsSuccessfullyAssociated,
                        "success",
                        2000,
                     );
                     this.refetchAssets();
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   };

   viewAsset = (assetID) => {
      const asset = this.manageAsset.getAsset(assetID);
      if (!asset) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         return;
      }
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: asset.assetID,
            locationID: asset.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   deletePartRelation = (asset) => {
      if (
         !this.credService.isAuthorized(
            this.locationID,
            this.credService.Permissions.ChangePartAssetAssociations,
         )
      ) {
         this.alertService.addAlert(this.lang().cred78Fail, "danger", 10000);
         return;
      }
      assert(this.part);

      this.manageAssociations
         .deletePartAssetRelation(asset.assetID, this.part.partID)
         .then(async (answer) => {
            if (answer?.data.success === true) {
               assert(this.part);
               asset.manualPartRelation = false;

               await this.refetchAssets();

               this.alertService.addAlert(
                  this.lang().AssociationSuccessfullyRemoved,
                  "success",
                  2000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   };

   private async refetchAssets() {
      if (this.part === undefined) {
         return;
      }
      this.loadingBar = true;
      this.assetRelations = orderBy(
         await this.manageAssociations.getAssociatedAssetsForPart(this.part.partID),
         "assetName",
      );
      this.loadingBar = false;
   }
}
