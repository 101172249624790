import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";

export type LaborCostEfficiencyRateCellViewModel = {
   laborCostEfficiencyRate?: number;
};

@Component({
   selector: "labor-cost-efficiency-rate-cell",
   imports: [CommonModule],
   templateUrl: "./labor-cost-efficiency-rate-cell.component.html",
})
export class LaborCostEfficiencyRateCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: LaborCostEfficiencyRateCellViewModel;
   @Input() public column?: Column;
}
