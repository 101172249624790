<div class="itemEditOptions hoverContainer">
   <span>
      @if (type === "item" && canAddInstruction) {
         <lim-ui-minimal-icon-button
            icon="plus"
            iconColor="success"
            (click)="addSubItem()"
            limUiTooltip="{{ lang().addInstructionTooltip }}"
            placement="right"
            [disabled]="onlyDelete"
         />
      }
      @if (type === "item" && !canAddInstruction) {
         <lim-ui-minimal-icon-button
            icon="plus"
            iconColor="success"
            [limUiPopover]="startPartsPopover"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'right'"
            [limUiPopoverHidden]="canAddInstruction"
            [disabled]="onlyDelete"
         />
      }
   </span>

   <span limUiTooltip="{{ lang().addRadioOptionTooltip }}" placement="right">
      @if (type === "optionParent") {
         <lim-ui-minimal-icon-button
            icon="circlePlusRegular"
            iconColor="success"
            (click)="addSubItem()"
            [disabled]="onlyDelete"
         />
      }
   </span>
   @if (!instructionSetTemplateItem) {
      <span limUiTooltip="{{ lang().DeleteThisInstruction }}" placement="right">
         <lim-ui-minimal-icon-button
            icon="trashCanRegular"
            (click)="deleteItem()"
            [disabled]="noDelete"
         />
      </span>
   }
   @if (!instructionSetTemplateItem) {
      <span>
         @if (canAddInstruction) {
            <lim-ui-minimal-icon-button
               icon="cloneRegular"
               (click)="duplicateItem(item)"
               limUiTooltip="{{ lang().DuplicateThisInstruction }}"
               placement="right"
               [disabled]="onlyDelete"
            />
         }
         @if (!canAddInstruction) {
            <lim-ui-minimal-icon-button
               icon="cloneRegular"
               [limUiPopover]="startPartsPopover"
               [limUiPopoverType]="'upsell'"
               [limUiPopoverPlacement]="'right'"
               [limUiPopoverHidden]="canAddInstruction"
               [disabled]="onlyDelete"
            />
         }
      </span>
   }

   <span limUiTooltip="{{ lang().AttachInstructionalImage }}" placement="right">
      <lim-ui-minimal-icon-button
         icon="cameraRegular"
         (click)="uploadFile('imageUploader')"
         [disabled]="onlyDelete"
      />
   </span>

   <span limUiTooltip="{{ lang().AttachInstructionalDocument }}" placement="right">
      <lim-ui-minimal-icon-button
         icon="fileRegular"
         (click)="uploadFile('docUploader')"
         data-container="body"
         [disabled]="onlyDelete"
      />
   </span>

   <span
      data-limUiTooltip="{{ ItemPlaceHolderTooltip }}"
      placement="top"
      [hidden]="!showPlaceholders"
      (click)="goToPlaceholderTutorial()"
   >
      <lim-ui-minimal-icon-button
         icon="codeForkRegular"
         data-container="body"
         [disabled]="onlyDelete"
      />
   </span>

   <span limUiTooltip="{{ lang().LogResponseInAssetLog }}" placement="top">
      <lim-ui-minimal-icon-button
         icon="chartColumn"
         [hidden]="
            !(
               item.itemTypeID == TaskInstructionType.TextBox ||
               item.itemTypeID == TaskInstructionType.DatePicker ||
               item.itemTypeID == TaskInstructionType.Number
            )
         "
         [iconColor]="
            item.logged > 0 ? 'success' : item.logged == null ? 'danger' : 'primary'
         "
         (click)="toggleLog()"
         data-container="body"
         [disabled]="onlyDelete"
      />
   </span>

   <span limUiTooltip="{{ lang().linkResponseToAssetField }}" placement="top">
      @if (
         item.itemTypeID == TaskInstructionType.OptionList ||
         item.itemTypeID == TaskInstructionType.TextBox ||
         item.itemTypeID == TaskInstructionType.DatePicker ||
         item.itemTypeID == TaskInstructionType.Number ||
         item.itemTypeID == TaskInstructionType.DropdownList
      ) {
         <lim-ui-minimal-icon-button
            [icon]="'link'"
            [classNames]="item.fieldIDForLink ? 'primary' : ''"
            (click)="toggleLink()"
            [iconColor]="!item.fieldIDForLink ? 'danger' : 'white'"
            [disabled]="onlyDelete"
         />
      }
   </span>

   @if (
      item.itemTypeID == TaskInstructionType.InstructionSet && !instructionSetTemplate
   ) {
      <span limUiTooltip="{{ lang().SyncInstructionSetTooltip }}" placement="top">
         <lim-ui-minimal-icon-button
            icon="rss"
            (click)="syncInstructionSet()"
            [disabled]="onlyDelete"
         />
      </span>
   }

   @if (item.showCollapse) {
      <span limUiTooltip="{{ lang().collapseChildrenItemTooltip }}" placement="top">
         @if (item.collapsed) {
            <lim-ui-minimal-icon-button icon="chevronDown" (click)="collapseItems()" />
         }
         @if (!item.collapsed) {
            <lim-ui-minimal-icon-button icon="chevronUp" (click)="collapseItems()" />
         }
      </span>
   }

   @if (uploading) {
      <lim-ui-loading-animation animationSize="extra-small" />
   }
</div>

@if (item.itemFileName) {
   <div>
      @for (image of item.instructionalImages; track image) {
         @if (image.itemFileName) {
            <span class="file-name-wrapper">
               <lim-ui-minimal-icon-button icon="imageRegular" [disabled]="onlyDelete" />

               <a (click)="openViewer(image.src)" class="file-name"
                  >{{ image.itemFileName }}
               </a>
               <lim-ui-minimal-icon-button
                  class="edit-button"
                  icon="pencil"
                  [disabled]="onlyDelete"
               />

               <lim-ui-minimal-icon-button
                  class="delete-button"
                  icon="trashCanRegular"
                  (click)="removeItemImage(item, image.itemFileName)"
                  iconColor="danger"
                  [disabled]="onlyDelete"
               />
            </span>
         }
      }
      @for (file of item.instructionalFiles; track file) {
         <span class="file-name-wrapper">
            <lim-ui-minimal-icon-button
               icon="fileLines"
               [hidden]="!(file.ext === 'txt')"
               [disabled]="onlyDelete"
            />
            <lim-ui-minimal-icon-button
               icon="fileWordRegular"
               [hidden]="!(file.ext === 'doc' || file.ext === 'docx')"
               [disabled]="onlyDelete"
            />
            @if (file.ext === "pdf") {
               <lim-ui-minimal-icon-button icon="filePdfRegular" />
            }
            <lim-ui-minimal-icon-button
               icon="fileExcelRegular"
               [hidden]="!(file.ext === 'xls' || file.ext === 'xlsx')"
               [disabled]="onlyDelete"
            />
            <lim-ui-minimal-icon-button
               icon="fileZipperRegular"
               [hidden]="!(file.ext === 'rar' || file.ext === 'zip')"
               [disabled]="onlyDelete"
            />
            <lim-ui-minimal-icon-button
               icon="envelope"
               [hidden]="!(file.ext === 'eml')"
               [disabled]="onlyDelete"
            />
            <a
               href="viewFile.php?f=upload-{{ customerID }}/items/{{
                  item.itemBatchID
               }}/{{ file.itemFileName }}"
               class="file-name"
               target="_blank"
               >{{ file.itemFileName }}</a
            >
            <lim-ui-minimal-icon-button
               class="delete-button"
               icon="trashCanRegular"
               (click)="removeItemImage(item, file.itemFileName)"
               iconColor="danger"
               [disabled]="onlyDelete"
            />
         </span>
      }
   </div>
}

<ng-template #startPartsPopover>
   <upsell-popover
      [message]="
         woInstructionLimit > 2
            ? lang().FeatureThreeWOsPopoverMessage
            : lang().FeatureTwoWOsPopoverMessage
      "
      [pricingLinkText]="lang().LearnMore"
   />
</ng-template>
