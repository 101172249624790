import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "intro-to-sensors",
   templateUrl: "./introToSensors.modal.component.html",
   styleUrls: ["./introToSensors.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
   ],
})
export class IntroToSensors implements OnInit {
   public message;
   public title;
   public resolve;
   public modalInstance;

   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.message = this.resolve.message;
      this.title = this.resolve.title;
   }
   close = () => {
      this.modalInstance.close(0);
   };

   blogpost = () => {
      window.open(
         "https://limblecmms.com/blog/limble-introduces-modular-iot-sensor-setup/",
         "_blank",
      );
   };
   contact = () => {
      window.open("https://limblecmms.com/contact.php", "_blank");
   };
}
