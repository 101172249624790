import { inject, Injectable } from "@angular/core";
import { LoadingBarService } from "@limblecmms/lim-ui";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import Queue from "promise-queue/lib";
import { ManageLang } from "src/app/languages/services/manageLang";

@Injectable({ providedIn: "root" })
export class ElementsToImages {
   private readonly getMessage: (processed: number, totalToProcess: number) => string;
   private loading: boolean;
   private readonly loadingBarService = inject(LoadingBarService);
   protected readonly lang = inject(ManageLang).lang;

   public constructor() {
      this.loading = false;
      this.getMessage = (processed: number, totalToProcess: number) => `
         <b>
            ${this.lang()?.PerformingRiteOfPercussiveMaintenance}...
         </b>
         <br/>
         <span>
            ${this.lang()?.ThisMayTakeAMoment}
         </span>
         <br /> <br />
         ${processed}
         ${this.lang()?.ofStr}
         ${totalToProcess} 
         ${this.lang()?.completed}`;
   }

   /**
    * Convert html elements to png images. The user cannot use the app
    * while this is running.
    *
    * @remarks
    * This function utilizes the html2canvas library, which rebuilds the
    * whole page off in an off-screen iframe, then renders the selected
    * element on a canvas. We then convert the canvas to an image. This
    * process is quite expensive both for the processor and the network.
    *
    * To ensure that the html2canvas library can grab the selected elements,
    * this function will cause the app to disappear behind a loading screen,
    * preventing the user from interacting with the application until the
    * process completes.
    *
    * @param elementMap - a map where each entry consists of an arbitrary
    * string key and an HTMLElement value. The HTMLElement are what will
    * be converted to images.
    *
    * @returns A promise that resolves to a new map with the same keys as
    * the map that was passed in, but whose values are now base64 encoded
    * strings of the images of the elements, rather than the elements themselves.
    */
   public readonly run = async (
      elementMap: Map<string, HTMLElement>,
   ): Promise<Map<string, string>> => {
      if (this.loading) {
         //Running this concurrently with itself would cause undefined behavior
         throw new Error("elementsToImages is already running!");
      }

      const totalToProcess = elementMap.size;
      let processed = 0;
      this.updateLoadingScreen(processed, totalToProcess);

      return new Promise((resolve) => {
         //We use a setTimeout here so that the dom can render before we continue
         setTimeout(() => {
            //we use the promise-queue library to throttle the number of html2canvas
            //functions that run at a time. When they all run at once, the browser
            //locks up -- especially when the number of elements is high.
            const promiseQueue = new Queue(2);
            const imagePromises: Array<Promise<void>> = [];
            const returnMap = new Map<string, string>();
            const onImageCreated = (image: string, name: string) => {
               processed++;
               this.updateLoadingScreen(processed, totalToProcess);
               returnMap.set(name, image);
            };
            for (const [name, element] of elementMap) {
               imagePromises.push(
                  promiseQueue.add(async () => {
                     return this.getImageFromElement(element).then((imageString) => {
                        // this.openBase64InNewTab(imageString, "image/png"); // opens image in new tab for debug purposes
                        onImageCreated(imageString, name);
                     });
                  }),
               );
            }
            Promise.all(imagePromises).then(() => {
               //Clean up the css classes that we added in the
               //`getImageFromElement` method, then resolve.
               const images = Array.from(document.getElementsByTagName("img"));
               for (const image of images) {
                  image.classList.remove("keepImage");
               }
               resolve(returnMap);
            });
         });
      });
   };

   /**
    * To download a zip file of the results produced by the `run` method,
    * pass that result to this function.
    *
    * @param imagesMap The map of names and base64 strings
    */
   public readonly zipAndDownload = (imagesMap: Map<string, string>): void => {
      const zip = new JSZip();
      for (const [name, image] of imagesMap) {
         zip.file(`${name}.png`, image, {
            base64: true,
         });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
         saveAs(content, "QRCodeExport.zip");
      });
   };

   /**
    * Converts an HTMLElement to an image
    *
    * @param element - The element to convert
    *
    * @returns A promise that resolves to the base64 encoded string
    * of the image of the element.
    */
   private readonly getImageFromElement = async (
      element: HTMLElement,
   ): Promise<string> => {
      const images = Array.from(element.getElementsByTagName("img"));
      for (const image of images) {
         //Images without this class will be ignored by the ignoreElementsPredicate
         image.classList.add("keepImage");
      }
      const canvas = await html2canvas(element);
      //Wait for a specified amount of time before converting the cloned page to an image.
      await new Promise<void>((resolve) => {
         setTimeout(() => {
            resolve();
         }, 400);
      });
      const canvasDataUrl = canvas.toDataURL("images/png", 1);
      const base64 = canvasDataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
      return base64;
   };

   /**
    * Update the loading screen.
    *
    * - If the loading screen is not displayed and processing is underway,
    * show the loading screen.
    *
    * - If the loading screen is already displayed and processing is
    * underway, update the loading screen.
    *
    * - If processing is not underway, remove the loading screen.
    *
    * @param processed - (optional) the number of elements processed
    * @param totalToProcess - (optional) the sum of processed elements and elements
    * not yet processed
    */
   private readonly updateLoadingScreen = (
      processed: number = 0,
      totalToProcess: number = 0,
   ): void => {
      if (this.loading) {
         //Loading screen is already showing, so we take it down momentarily
         this.loadingBarService.remove();
      }
      if (totalToProcess > 0 && processed < totalToProcess) {
         //Processing is underway, so we hide the app and show the loading screen
         this.showApp(false);
         this.loadingBarService.show({
            header: this.getMessage(processed, totalToProcess),
         });
         this.loading = true;
      } else {
         //Processing is not underway. Show the application and leave the
         //loading screen turned off.
         this.loading = false;
         this.showApp(true);
      }
   };

   /**
    * Make the app disappear or reappear
    *
    * @param apply - if true, make the app reappear; if false, make the
    * app disappear.
    */
   private readonly showApp = (show: boolean): void => {
      if (show) {
         for (const element of Array.from(
            document.getElementsByClassName("modal-content"),
         )) {
            if (element instanceof HTMLElement) {
               element.style.opacity = "1";
            }
         }
      } else {
         for (const element of Array.from(
            document.getElementsByClassName("modal-content"),
         )) {
            if (element instanceof HTMLElement) {
               element.style.opacity = "0";
            }
         }
      }
   };
}
