import { Injectable } from "@angular/core";
import { loadJs } from "src/app/shared/external-scripts/util/load-js";

@Injectable({ providedIn: "root" })
export class SurvicateService {
   public async load(): Promise<void> {
      return loadJs(
         "https://survey.survicate.com/workspaces/65e17f3f028eb2f88904fa1b5dc20326/web_surveys.js",
      );
   }
}
