@if (vendor) {
   <lim-ui-panel id="vendorInfo{{ vendor.vendorID }}">
      <div class="panel-body">
         <div class="lim-ui-list-item">
            <div class="lim-ui-list-item-content">
               <div class="icon-with-text">
                  <lim-ui-icon icon="cube" iconSize="small" />
                  <span>{{ lang().Name }}</span>
               </div>
               <div class="item-information growing-input-and-buttons-container">
                  <div
                     class="form-control exitOnEnterKeyPress vendorFocus"
                     [ngClass]="{ editUnderline: creds.changeVendorName }"
                     (afterEdit)="updateVendorName()"
                     [limbleContentEditable]="creds.changeVendorName"
                     [(ngModel)]="vendor.vendorName"
                     disableEditor="true"
                     stripHtml="true"
                  ></div>

                  <span
                     placement="bottom"
                     limUiTooltip="{{ lang().LimblesInternalVendorIDTooltip }}"
                     class="smaller-grey-text"
                  >
                     #{{ vendor.vendorID }}
                  </span>
               </div>
            </div>
         </div>
         <div class="lim-ui-list-item">
            <div class="lim-ui-list-item-content">
               <div class="icon-with-text">
                  <lim-ui-icon icon="user" iconSize="small" />
                  <span>{{ lang().Contact }}</span>
               </div>
               <div class="item-information">
                  <div
                     class="form-control vendorFocus"
                     [ngClass]="{ editUnderline: creds.changeVendorContact }"
                     (afterEdit)="updateVendorContact()"
                     [limbleContentEditable]="creds.changeVendorContact"
                     [(ngModel)]="vendor.vendorContact"
                     stripHtml="true"
                  ></div>
               </div>
            </div>
         </div>
         <div class="lim-ui-list-item">
            <div class="lim-ui-list-item-content">
               <div class="icon-with-text">
                  <lim-ui-icon icon="envelope" iconSize="small" />
                  <span>{{ lang().Email }}</span>
               </div>
               <div class="item-information">
                  <div
                     class="form-control vendorFocus"
                     [ngClass]="{ editUnderline: creds.changeVendorEmail }"
                     (afterEdit)="updateVendorEmail()"
                     [limbleContentEditable]="creds.changeVendorEmail"
                     [(ngModel)]="vendor.vendorEmail"
                     disableEditor="true"
                     stripHtml="true"
                  ></div>
               </div>
            </div>
         </div>
         <div class="lim-ui-list-item">
            <div class="lim-ui-list-item-content">
               <div class="icon-with-text">
                  <lim-ui-icon icon="phone" iconSize="small" />
                  <span>{{ lang().Phone }}</span>
               </div>
               <div class="item-information">
                  <div
                     class="form-control vendorFocus"
                     [ngClass]="{ editUnderline: creds.changeVendorPhone }"
                     (afterEdit)="updateVendorPhone()"
                     [limbleContentEditable]="creds.changeVendorPhone"
                     [(ngModel)]="vendor.vendorPhone"
                     stripHtml="true"
                  ></div>
               </div>
            </div>
         </div>
         <div class="lim-ui-list-item">
            <div class="lim-ui-list-item-content">
               <div class="icon-with-text">
                  <lim-ui-icon icon="locationDot" iconSize="small" />
                  <span>{{ lang().Address }}</span>
               </div>
               <div class="item-information">
                  <div
                     class="form-control vendorFocus"
                     [ngClass]="{ editUnderline: creds.changeVendorAddress }"
                     (afterEdit)="updateVendorAddress()"
                     [limbleContentEditable]="creds.changeVendorAddress"
                     [(ngModel)]="vendor.vendorAddress"
                     stripHtml="true"
                  ></div>
               </div>
            </div>
         </div>
         <div class="asset-information-hierarchy">
            <limble-tree-root
               #tree
               [data]="fields"
               [options]="treeOptions"
               (treeDrop)="onMoveNode($event)"
            />
         </div>
         <div class="panel-body-bottom-div">
            @if (vendor.vendorDeleted == 0 && !restrict) {
               <lim-ui-primary-button icon="plus" (click)="addField(vendor)">{{
                  lang().AddField
               }}</lim-ui-primary-button>
            }
         </div>
      </div>
   </lim-ui-panel>
}
