<ng-container *translate="let t">
   @if (cell?.isCompleted !== undefined) {
      <div
         [ngClass]="[
            isInModal
               ? 'name-priority-type-status-container-modal'
               : 'name-priority-type-status-container',
            printOnly ? 'print-only-container' : '',
         ]"
         class="taskItemClass allowOffline cell-container"
      >
         <span class="content" [ngClass]="{ 'print-only-name': printOnly }">
            <e-button
               (click)="viewTask()"
               [label]="cell.checklistName"
               [link]="true"
               size="small"
               [style]="{ 'padding': 0, 'text-align': 'left' }"
            />
         </span>

         @if (!printOnly) {
            <div class="icon-container">
               @if (cell.hasParts) {
                  <div class="icon-sub-container">
                     <lim-ui-icon
                        [ngClass]="cell.hasPartsInStock ? 'superimposed-icon' : ''"
                        icon="gearsRegular"
                        limUiTooltip="{{ partsInStockTooltip }}"
                        placement="right"
                     />

                     @if (cell.hasPartsInStock) {
                        <lim-ui-icon icon="check" iconColor="success" placement="right" />
                     }
                  </div>
               }

               @if (hasMoreThanTwoComments) {
                  <lim-ui-icon
                     class="icon"
                     icon="noteSticky"
                     limUiTooltip="{{ t('completionNotes') }}"
                     placement="right"
                  />
               }

               @if (cell.requestorInformation && cell.requestorInformation.length > 0) {
                  <lim-ui-icon
                     class="icon"
                     icon="phoneRegular"
                     limUiTooltip="{{ t('RequestorInfo') }}: {{
                        cell.requestorInformation
                     }}"
                     placement="right"
                  />
               }

               @if (hasApproval) {
                  <lim-ui-icon
                     class="icon"
                     [iconColor]="cell?.approvalItemState === 2 ? 'danger' : 'inherit'"
                     [icon]="cell?.approvalItemState === 1 ? 'thumbsUp' : 'thumbsDown'"
                     limUiTooltip="{{ t('ApprovedOrDisapprovedHintTooltip') }}"
                     placement="right"
                  />
               }
            </div>
         }

         @if (searchHint && searchHint.length > 0 && !printOnly) {
            <span class="search-hint desktop-search-hint">
               <span [limbleHtml]="searchHint"> </span>
            </span>
         }
      </div>
   }
</ng-container>
