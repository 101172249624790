import { Injectable, inject } from "@angular/core";
import type { Moment } from "moment";
import moment from "moment";
import type { Filter, FilterType } from "src/app/dashboards/filters";
import { DateRangeFilter } from "src/app/dashboards/filters";
import { assert } from "src/app/shared/utils/assert.utils";
import type { TaskEntityType } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import type { Task, TaskLookup } from "src/app/tasks/types/task.types";

export const LIMITED_DASHBOARD_REPORTING_DAYS = 90;
export const RESTRICTED_DASHBOARD_REPORTING_DAYS = 30;

export type DashboardPanelDataFilters = {
   dashboardReportingLimit: number | undefined;
   dateRange?: {
      startDate: Date;
      endDate: Date;
   };
   locationID?: number;
   taskType?: TaskEntityType;
   userID?: number;
};

@Injectable({ providedIn: "root" })
export class DashboardService {
   private readonly tasksApiService = inject(TasksApiService);

   public getTasksByMonth(
      tasks: TaskLookup,
      startingDate: Moment,
      endingDate: Moment,
      attribute: "checklistDueDate" | "checklistCompletedDate",
   ): Array<Array<Task>> {
      //This function needs to be very efficient for cases where there are hundreds of thousands of tasks.
      //The way we organize tasks by month is a little strange, but it is the most efficient way we could come up with.
      const numberOfMonths =
         (endingDate.year() - startingDate.year()) * 12 +
         (endingDate.month() - startingDate.month()) +
         1;
      let monthCount = 0;
      const tasksByMonth = new Map<number, Array<Task>>();
      while (monthCount < numberOfMonths) {
         const currentMonth = startingDate.clone().add(monthCount, "months");
         const startOfMonth = currentMonth.clone().startOf("month");
         tasksByMonth.set(startOfMonth.unix(), []);
         monthCount++;
      }
      for (const task of tasks) {
         if (task[attribute] === null) continue;
         const startOfTaskMonth = moment
            .unix(Number(task[attribute]))
            .startOf("month")
            .unix();
         const bucket = tasksByMonth.get(startOfTaskMonth);
         if (bucket === undefined) {
            //Not in range
            continue;
         }
         bucket.push(task);
      }
      return [...tasksByMonth.values()];
   }

   public sumArray(array: Array<number>) {
      return array.reduce((previousValue, currentValue) => previousValue + currentValue);
   }

   public getEarliestTask(
      tasks: TaskLookup,
      attribute: "checklistDueDate" | "checklistCompletedDate",
   ): Task | undefined {
      if (tasks.size === 0) return undefined;
      return tasks.reduce((previousValue, currentValue) => {
         if (previousValue === undefined) {
            return currentValue;
         }

         if (currentValue[attribute] === null || previousValue[attribute] === null)
            return previousValue;

         if (Number(currentValue[attribute]) < Number(previousValue[attribute])) {
            return currentValue;
         }
         return previousValue;
      });
   }

   /**
    * @deprecated - this is only used by legacy panels in global dashboard
    */
   public getDefaultDateRange(
      tasks: TaskLookup,
      graphByAttribute: "checklistCompletedDate" | "checklistDueDate",
   ): { startDate: Moment; endDate: Moment } {
      const endDate = moment();

      const earliestTask = this.getEarliestTask(tasks, graphByAttribute);

      if (!earliestTask) {
         const startDate = endDate.clone().subtract(1, "month");
         return { startDate, endDate };
      }
      const startDate = moment.unix(Number(earliestTask.checklistCompletedDate));
      if (endDate.diff(startDate, "years", true) > 2) {
         //basically by default never show more then 2 years.
         startDate.year(endDate.year() - 2).month(endDate.month() + 1);
      }
      return { startDate, endDate };
   }

   /**
    * @deprecated - this is only used by legacy panels in global dashboard
    */
   public getDateRange(
      tasks: TaskLookup,
      graphByAttribute: "checklistCompletedDate" | "checklistDueDate",
      activeFilters: Map<FilterType, Filter>,
   ): { startDate: Moment; endDate: Moment } {
      const dateRangeFilter = activeFilters.get("dateRange");
      if (dateRangeFilter === undefined) {
         return this.getDefaultDateRange(tasks, graphByAttribute);
      }
      assert(dateRangeFilter instanceof DateRangeFilter);
      return { startDate: dateRangeFilter.date1, endDate: dateRangeFilter.date2 };
   }

   public getDefaultDateRangeForPanels(dashboardReportingLimit: number | undefined): {
      startDate: Date;
      endDate: Date;
   } {
      const defaultStartDate = moment().subtract(11, "months").startOf("month").toDate();
      const defaultEndDate = moment().endOf("month").toDate();

      return this.calculateDateRangeParams(dashboardReportingLimit, {
         startDate: defaultStartDate,
         endDate: defaultEndDate,
      });
   }

   public calculateDateRangeParams(
      dashboardReportingLimit: number | undefined,
      dateRangeFilter?: { startDate: Date; endDate: Date },
   ): {
      startDate: Date;
      endDate: Date;
   } {
      let startDate: Moment;
      let endDate: Moment;

      if (dateRangeFilter) {
         startDate = moment(dateRangeFilter.startDate);
         endDate = moment(dateRangeFilter.endDate);
      } else {
         endDate = moment();
         startDate = moment()
            .year(endDate.year() - 2)
            .month(endDate.month() + 1);
      }

      // TODO - we need to test out this logic
      if (
         dashboardReportingLimit &&
         endDate.diff(startDate, "days", true) > dashboardReportingLimit
      ) {
         startDate = moment().subtract(dashboardReportingLimit, "days");
      }

      return {
         startDate: startDate.toDate(),
         endDate: endDate.toDate(),
      };
   }
}
