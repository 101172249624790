<p-select
   #select
   optionLabel="label"
   [checkmark]="showCheckmark()"
   [showClear]="showClear()"
   [options]="options()"
   [placeholder]="placeholder()"
   [ariaLabel]="ariaLabel()"
   [ariaLabelledBy]="ariaLabelledBy()"
   [inputId]="inputId()"
   [filter]="filterable()"
   [appendTo]="'body'"
   [(ngModel)]="_value"
   [disabled]="isDisabled()"
   (onChange)="onChangeSelect($event)"
   (onFilter)="onFilterSelect($event)"
   (onFocus)="focus.emit($event)"
   (onBlur)="blur.emit($event)"
   (onShow)="show.emit()"
   (onHide)="hide.emit()"
/>
