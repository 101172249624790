<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title()" (closeModal)="close()" />
   <lim-ui-modal-body (click)="errorMsg.set('')">
      @if (message()) {
         <lim-ui-info-panel>
            <span class="message" [limbleHtml]="message()"></span>
         </lim-ui-info-panel>
      }

      <lim-ui-panel>
         <div class="panel-header-container">
            <div class="panel-header-title">{{ lang()?.SelectUsers ?? "" }}</div>
            <div class="panel-header-filters">
               <lim-ui-search-box
                  class="search-box"
                  [focusOnLoad]="true"
                  [placeholder]="lang()?.Search ?? ''"
                  [(searchVal)]="searchTerm"
                  (searchValChange)="filterOnSearchTerm()"
               />
               <lim-ui-filters-wrapper
                  title="{{ lang()?.Filter ?? '' }}:"
                  [noMargins]="true"
               >
                  <lim-ui-dropdown-button [label]="lang()?.Location ?? ''" filterDropdown>
                     <div menu>
                        <lim-ui-dropdown-item [closeDropdownOnClick]="false">
                           <lim-ui-search-box
                              [placeholder]="lang()?.Search ?? ''"
                              [(searchVal)]="searchTermForLocationFilter"
                           />
                        </lim-ui-dropdown-item>

                        @for (
                           location of filteredLocations();
                           track location.locationID
                        ) {
                           <lim-ui-dropdown-text-item
                              [displayString]="location.locationName"
                              [outputString]="location.locationName"
                              (click)="addLocationFilter(location)"
                           />
                        }

                        <lim-ui-dropdown-divider />

                        <lim-ui-dropdown-clear-filter-item
                           (click)="clearLocationFilter()"
                        >
                           {{ lang()?.ClearFilter ?? "" }}
                        </lim-ui-dropdown-clear-filter-item>
                     </div>
                  </lim-ui-dropdown-button>

                  <lim-ui-dropdown-button [label]="lang()?.Role ?? ''" filterDropdown>
                     <div menu>
                        <lim-ui-dropdown-item [closeDropdownOnClick]="false">
                           <lim-ui-search-box
                              [placeholder]="lang()?.Search ?? ''"
                              [(searchVal)]="searchTermForRoleFilter"
                           />
                        </lim-ui-dropdown-item>

                        @for (role of filteredRoles(); track role.roleID) {
                           <lim-ui-dropdown-text-item
                              [displayString]="role.roleDescription"
                              [outputString]="role.roleDescription"
                              (click)="addRoleFilter(role)"
                           />
                        }

                        <lim-ui-dropdown-divider />

                        <lim-ui-dropdown-clear-filter-item (click)="clearRoleFilter()">
                           {{ lang()?.ClearFilter ?? "" }}
                        </lim-ui-dropdown-clear-filter-item>
                     </div>
                  </lim-ui-dropdown-button>

                  <lim-ui-filter-input-wrapper filterInputs>
                     @if (locationFilter() !== null) {
                        <div>
                           <lim-ui-clear-filter-button (click)="clearLocationFilter()" />
                           {{ lang()?.Location }}:
                           <b>{{ locationFilter()?.locationName }}</b>
                        </div>
                     }
                     @if (roleFilter() !== null) {
                        <div>
                           <lim-ui-clear-filter-button (click)="clearRoleFilter()" />
                           {{ lang()?.Role }}:
                           <b>{{ roleFilter()?.roleDescription }}</b>
                        </div>
                     }
                  </lim-ui-filter-input-wrapper>
               </lim-ui-filters-wrapper>
            </div>
            <div class="panel-header-selection-controls">
               <div class="list-title">
                  {{ lang()?.Users }}
               </div>
               @if (multiselect()) {
                  <lim-ui-selection-controls
                     [selectAll]="lang()?.SelectAll"
                     [deselectAll]="lang()?.DeselectAll"
                     (handleSelectAll)="selectAllUsers(true)"
                     (handleDeselectAll)="selectAllUsers(false)"
                  />
               }
            </div>
         </div>

         @if (users().length === 0) {
            <span class="no-search-results">
               <no-search-results />
            </span>
         } @else {
            @for (user of users() | orderBy: "userLastName"; track user.userID) {
               <div
                  (click)="focusUser(user)"
                  class="lim-ui-list-item lim-ui-selectable-list-item user-row-content"
                  [ngClass]="{ 'lim-ui-selected-list-item': user.selected }"
               >
                  @if (user.selected) {
                     <lim-ui-icon icon="check" iconColor="success" iconSize="small" />
                  }

                  <user-image [user]="user" />

                  <span> {{ user.userFirstName }} {{ user.userLastName }} </span>
               </div>
            }
         }
         <span [hidden]="!(allUsers().length == 0)">{{ lang()?.NoUsersToPickFrom }}</span>
      </lim-ui-panel>

      @if (errorMsg() !== "") {
         <lim-ui-alert alertType="danger" [title]="lang()?.Warning">
            <span [limbleHtml]="errorMsg()"></span>
         </lim-ui-alert>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang()?.Select ?? ''"
      (successClick)="submit()"
      [dataLogSubmitLabel]="this.dataLogSubmitLabelLookup[this.title()]"
      [cancelButtonText]="lang()?.Cancel ?? ''"
      (cancelClick)="close()"
   />
</lim-ui-modal>
