import { Injectable } from "@angular/core";
import { isAndroidApp } from "@limblecmms/lim-ui";
import Median from "median-js-bridge";

@Injectable({
   providedIn: "root",
})
export class MobileFileService {
   /**
    * View a file in the native app.
    * See https://median.co/docs/download-file
    */
   public viewFile(url: string): void {
      if (isAndroidApp()) {
         // Median.share.downloadFile does not work on Android
         window.location.href = url;
         return;
      }

      Median.share.downloadFile({
         url,
         open: true,
      });
   }
}
