import { Component, inject, input, output } from "@angular/core";
import { TranslateService } from "src/app/languages/i18n/translate.service";
import type { DataViewerFilter } from "src/app/shared/data-viewer/data-viewer-filters/data-viewer-filter.models";

/**
 * Base class for all data viewer filters.
 * This is here to ensure that we follow the same structure on how to set the filter
 * and send the events to the parent component.
 */
@Component({ selector: "app-base-data-viewer", standalone: true, template: "" })
export class BaseFilterComponent {
   filter = input.required<DataViewerFilter>();

   readonly remove = output<DataViewerFilter>();
   readonly set = output<DataViewerFilter>();

   protected readonly translateService = inject(TranslateService);
}
