import { z } from "zod";

export const customUnitDtoSchema = z.object({
   category: z.literal("count"),
   id: z.number(),
   nameSingular: z.string(),
   nameShort: z.string(),
   type: z.literal("custom"),
});

export const UnitOfMeasureCustomInsertSchema = customUnitDtoSchema.pick({
   nameShort: true,
   nameSingular: true,
   type: true,
});

export type UnitOfMeasureCustomInsertType = z.infer<
   typeof UnitOfMeasureCustomInsertSchema
>;
