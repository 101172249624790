<div class="filter-wrapper-container">
   <lim-ui-filters-wrapper title="{{ lang().Filter }}:">
      <lim-ui-dropdown-button [label]="lang().PartInformation" filterDropdown>
         <div menu>
            <lim-ui-dropdown-text-item
               (click)="addFilter({ fieldName: 'Name' })"
               displayString="{{ lang().Name }}"
            />
            <lim-ui-dropdown-text-item
               (click)="addFilter({ fieldName: 'Number' })"
               displayString="{{ lang().Number }}"
            />
            <lim-ui-dropdown-text-item
               (click)="addFilter({ fieldName: 'Location' })"
               displayString="{{ lang().Location }}"
            />
            <lim-ui-dropdown-text-item
               (click)="addFilter({ fieldName: 'Category' })"
               displayString="{{ lang().Category }}"
            />
            @if (partFields && partFields.size > 0) {
               <lim-ui-dropdown-divider />
            }
            <lim-ui-dropdown-item [closeDropdownOnClick]="false">
               @if (partFields) {
                  <lim-ui-search-box
                     [(searchVal)]="searchPartFields"
                     [placeholder]="lang().Search"
                     (searchValChange)="runSearch()"
                  />
               }
            </lim-ui-dropdown-item>

            @for (field of filteredFields; track field) {
               <lim-ui-dropdown-text-item
                  [closeDropdownOnClick]="false"
                  (click)="addFilter(field)"
                  displayString="{{ field.fieldName }}"
               />
            }
            <lim-ui-dropdown-divider />
            <lim-ui-dropdown-clear-filter-item (click)="clearFilter()">
               {{ lang().ClearFilter }}
            </lim-ui-dropdown-clear-filter-item>
         </div>
      </lim-ui-dropdown-button>

      <lim-ui-filter-input-wrapper filterInputs>
         @for (filter of partFilters; track filter) {
            <div>
               <lim-ui-filter-input (clearFilterEvent)="clearFilter($index)">
                  <label inputLabel> {{ filter.fieldName }}: </label>

                  <!-- Custom text field -->
                  @if (filter.field.fieldTypeID === 1) {
                     <input
                        inputElement
                        class="filter-input-box"
                        type="text"
                        [(ngModel)]="searchFields[filter.fieldName].searchValue"
                        (ngModelChange)="triggerFilterParts()"
                     />
                  }

                  <!-- Custom Date field -->
                  @if (filter.field.fieldTypeID === 2) {
                     <span inputElement class="filter-inputs-container">
                        <div>{{ lang().Between }}</div>

                        <div>
                           <lim-ui-date-picker-input
                              class="filter-input-box"
                              [model]="searchFields[filter.fieldName].beginDate"
                              (modelChange)="triggerFilterParts()"
                           />
                        </div>

                        <div>{{ lang().and }}</div>

                        <div>
                           <lim-ui-date-picker-input
                              class="filter-input-box"
                              [model]="searchFields[filter.fieldName].endDate"
                              (modelChange)="triggerFilterParts()"
                           />
                        </div>
                     </span>
                  }

                  <!-- Picture or document field -->
                  @if (filter.field.fieldTypeID === 3 || filter.field.fieldTypeID === 4) {
                     <input
                        inputElement
                        type="text"
                        class="filter-input-box"
                        [(ngModel)]="searchFields[filter.fieldName].searchValue"
                        (ngModelChange)="triggerFilterParts()"
                     />
                  }

                  <!-- Number or currency field -->
                  @if (filter.field.fieldTypeID === 5 || filter.field.fieldTypeID === 6) {
                     <span inputElement class="filter-inputs-container">
                        {{ lang().Between }}
                        <input
                           class="filter-input-box"
                           type="number"
                           [(ngModel)]="searchFields[filter.fieldName].lowest"
                           (ngModelChange)="triggerFilterParts()"
                        />
                        {{ lang().and }}
                        <input
                           class="filter-input-box"
                           type="number"
                           [(ngModel)]="searchFields[filter.fieldName].highest"
                           (ngModelChange)="triggerFilterParts()"
                        />
                     </span>
                  }

                  <!-- Dropdown field -->
                  @if (filter.field.fieldTypeID === 7) {
                     <span inputElement class="filter-inputs-container">
                        <lim-ui-dropdown>
                           <lim-ui-secondary-button button secondaryIcon="angleDown">
                              <span> {{ filter.fieldSearch }} </span>
                           </lim-ui-secondary-button>

                           @if (filter?.field?.fieldID; as fieldID) {
                              <div menu>
                                 @for (
                                    item of optionsJsonMappedToFieldID?.get(fieldID);
                                    track item
                                 ) {
                                    <lim-ui-dropdown-text-item
                                       [displayString]="item.name"
                                       (click)="setDropdownFilter(filter, item.name)"
                                    />
                                 }
                              </div>
                           }
                        </lim-ui-dropdown>
                     </span>
                  }

                  <!-- partName, partNumber, partLocation, partCategory -->
                  @if (
                     filter.fieldName === "Name" ||
                     filter.fieldName === "Number" ||
                     filter.fieldName === "Location" ||
                     filter.fieldName === "Category"
                  ) {
                     <input
                        inputElement
                        type="text"
                        class="filter-input-box"
                        [(ngModel)]="searchFields[filter.fieldName].searchValue"
                        (ngModelChange)="triggerFilterParts()"
                     />
                  }
               </lim-ui-filter-input>
            </div>
         }
      </lim-ui-filter-input-wrapper>

      @if (searchBarField && searchBarField.length > 1) {
         <div>
            <lim-ui-clear-filter-button (click)="clearSearch()" />
            {{ lang().SearchFilter }}: <b [limbleHtml]="searchBarField"></b>
         </div>
      }
   </lim-ui-filters-wrapper>
</div>
