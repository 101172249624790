import { Component, input } from "@angular/core";
import type { StepperWizardContext } from "src/app/assets/services/apply-asset-templates/stepper-wizard-context";

@Component({
   selector: "step",
   standalone: true,
   imports: [],
   templateUrl: "./step.component.html",
   styleUrl: "./step.component.scss",
})
export class StepComponent {
   public context = input.required<StepperWizardContext<unknown>>();

   public handleNextStepClick() {
      // Handle any validation or user feedback needed before proceeding with next step.

      this.context().goToNextStep();
   }
}
