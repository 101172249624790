import type { CustomUnit } from "src/app/parts/unit-of-measure/custom-units/custom-unit.model";
import type { ProvidedUnit } from "src/app/parts/unit-of-measure/provided-units/provided-unit.model";

export const PROVIDED_UNIT = "provided";
export const CUSTOM_UNIT = "custom";

export enum UnitTypes {
   Provided = PROVIDED_UNIT,
   Custom = CUSTOM_UNIT,
}

export enum UnitCategories {
   Custom = "custom",
   Count = "count",
   Length = "length",
   Volume = "volume",
   Weight = "weight",
}

export type Unit = ProvidedUnit | CustomUnit;
