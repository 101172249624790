import type { OnInit } from "@angular/core";
import { Component, Input, computed, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DraggableDirective, type TreeBranch } from "@limble/limble-tree";
import { IconComponent, MinimalIconButtonComponent } from "@limblecmms/lim-ui";
import type { EditAssetField } from "src/app/assets/components/editAssetFieldModal/editAssetField.modal.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { EditPartField } from "src/app/parts/components/editPartFieldModal/editPartField.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import type { EditVendorField } from "src/app/vendors/components/editVendorFieldModal/editVendorField.modal.component";

@Component({
   selector: "edit-field-option",
   templateUrl: "./editFieldOption.element.component.html",
   styleUrls: ["./editFieldOption.element.component.scss"],
   imports: [
      DraggableDirective,
      IconComponent,
      ContenteditableDirective,
      FormsModule,
      MinimalIconButtonComponent,
   ],
})
export class EditFieldOption implements OnInit {
   @Input() treeBranch?: TreeBranch<EditFieldOption>;
   @Input() public updateFieldDropdownOptions:
      | EditAssetField["updateFieldDropdownOptions"]
      | EditPartField["updateFieldDropdownOptions"]
      | EditVendorField["updateFieldDropdownOptions"]
      | undefined;
   @Input() public deleteOption: EditAssetField["deleteOption"] | undefined;
   @Input() public options;
   @Input() workflowLabel: string | undefined;
   @Input() isViewOnlyMode: boolean = false;

   public option;
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.option = this.treeBranch?.meta().nodeData;
   }
}
