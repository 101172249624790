<lim-ui-modal [extraLarge]="true" [showWatermark]="true">
   <lim-ui-basic-modal-header (closeModal)="onClose()" title=" {{ options().title }}" />
   @if (options().dashboardReportingLimit) {
      <div alert class="data-limiting-alert">
         <upsell-popover
            [alertDismissable]="true"
            [message]="
               options().dashboardReportingLimit === 30
                  ? lang().FeatureLimited30DashboardsPopoverMessage
                  : lang().FeatureLimited90DashboardsPopoverMessage
            "
            [pricingLinkText]="lang().LearnMore"
         />
      </div>
   }
   @if (taskQuery(); as result) {
      <lim-ui-modal-body [scrollable]="true" class="scroll-height-inheritance">
         <lim-ui-panel class="scroll-height-inheritance">
            <div class="task-list-header-container">
               <div class="search-and-buttons">
                  <lim-ui-search-box
                     (searchValChange)="onSearch($event)"
                     [placeholder]="lang().Search"
                     class="search-box"
                  />
                  <!-- EXPORT DROPDOWN -->
                  @if (!onlyExportVisibleData()) {
                     <lim-ui-dropdown class="lim-ui-hide-on-mobile">
                        <lim-ui-icon-button
                           button
                           [disabled]="!hasResults()"
                           icon="cloudDownload"
                           limUiTooltip="{{ lang().ExportListToExcel }}"
                           [placement]="'bottom'"
                        />
                        @if (hasResults()) {
                           <div aria-labelledby="edit-visible-columns-dropdown" menu>
                              <lim-ui-dropdown-text-item
                                 (click)="onExportExcelLimited()"
                                 [disabled]="hasNoResults()"
                                 displayString="{{ lang().ExportVisibleData }}"
                              />
                              <lim-ui-dropdown-text-item
                                 (click)="onExportToExcel()"
                                 [disabled]="hasNoResults()"
                                 displayString="{{ lang().ExportAllData }}"
                              />
                           </div>
                        }
                     </lim-ui-dropdown>
                  }
                  @if (onlyExportVisibleData()) {
                     <lim-ui-icon-button
                        button
                        (click)="onExportExcelLimited()"
                        [disabled]="!hasResults()"
                        icon="cloudDownload"
                        limUiTooltip="{{ lang().ExportListToExcel }}"
                        [placement]="'bottom'"
                     />
                  }
                  @if (!hideBulkPrintButton()) {
                     <lim-ui-icon-button
                        (click)="onBulkPrint()"
                        [disabled]="hasNoResults()"
                        class="lim-ui-hide-on-mobile"
                        icon="print"
                        limUiTooltip="{{ lang().BulkPrintTasks }}"
                        [placement]="'top'"
                     />
                  }
                  @if (options().isStartWorkOrderButtonVisible) {
                     <lim-ui-primary-button
                        (click)="onStartWorkOrder(false)"
                        headerPrimaryButton
                        icon="plus"
                     >
                        {{ lang().StartWO }}
                     </lim-ui-primary-button>
                  }
               </div>

               <data-viewer-filters
                  [filters]="options().filters"
                  (set)="onSetFilter($event)"
                  (remove)="onRemoveFilter($event)"
               />
            </div>
            <tasks-data-viewer
               [columns]="options().columns"
               [tasks]="items()"
               [totalTasks]="totalItems()"
               [noSearchResults]="hasNoResults()"
               [isLoading]="result.isLoading()"
               [isPlaceholderData]="result.isPlaceholderData()"
            />
         </lim-ui-panel>
      </lim-ui-modal-body>
   }
</lim-ui-modal>
