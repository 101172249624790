import { Injectable, computed, inject } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { TaskTemplatesMapperService } from "src/app/tasks/components/shared/services/task-templates-mapper/task-templates-mapper.service";
import { TaskTemplatesPermissionsService } from "src/app/tasks/components/shared/services/task-templates-permissions/task-templates-permissions.service";
import { TaskExporterService } from "src/app/tasks/services/task-exporter-service/task-exporter.service";

@Injectable()
export class TaskTemplatesFacadeService {
   private readonly exportService = inject(TaskExporterService);
   private readonly mapperService = inject(TaskTemplatesMapperService);
   private readonly permissionsService = inject(TaskTemplatesPermissionsService);
   private readonly apiService = inject(TaskTemplatesApiService);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public async exportUpcomingWork(locationID: number, search: string): Promise<void> {
      await this.exportService.exportOpenAndUpcomingTasks(locationID, search);
   }

   public async exportTemplatesAtLocation(
      locationID: number,
      search: string,
   ): Promise<void> {
      await this.exportService.exportTemplatesAtLocation(locationID, search);
   }

   public getTemplateAssignment(template: TaskTemplateEntity): string {
      if (!template.assignedTo) {
         console.error(
            "'assignment' column is not attached to template, cannot display assignment",
         );
         return "";
      }
      return this.mapperService.mapTemplateAssignmentToDisplay(template.assignedTo);
   }

   public async canDuplicateTemplate(template: TaskTemplateEntity) {
      return this.permissionsService.canDuplicateTemplate(template);
   }

   public async canModifyRecurrences(template: TaskTemplateEntity) {
      return this.permissionsService.canModifyRecurrences(template);
   }

   /**
    * The return from this method indicates whether the customer has reached
    * the limit of templates that can have schedules if the customer does not have
    * the 'featureUnlimitedPMs' feature flag enabled.  This is used in conjunction
    * with whether a template has schedules to determine whether a template can be
    * duplicated or whether another recurrence can be added to a template that does
    * not have one already.
    */
   public async reachedCustomerScheduleLimit(): Promise<boolean> {
      return this.permissionsService.reachedCustomerScheduleLimit();
   }

   public async delete(
      templateID: number,
      locationID: number,
      assetID?: number,
   ): Promise<void> {
      const answer = await this.apiService.deleteLegacy(templateID, locationID, assetID);

      if (answer.data.success == true) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }
}
