import { Component, inject, input, output } from "@angular/core";
import {
   DropdownComponent,
   DropdownTextItemComponent,
   IconComponent,
   LimbleHtmlDirective,
} from "@limblecmms/lim-ui";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "asset-header-item",
   imports: [
      AssetParentList,
      DropdownComponent,
      IconComponent,
      LimbleHtmlDirective,
      DropdownTextItemComponent,
   ],
   templateUrl: "./asset-header-item.component.html",
   styleUrls: ["../../task-form.component.scss", "./asset-header-item.component.scss"],
})
export class AssetHeaderItemComponent {
   public asset = input<Asset>();
   public disableChangeAsset = input<boolean>(false);
   public readonly viewAssetRequest = output();
   public readonly changeAssetRequest = output();

   protected lang = inject(ManageLang).lang;

   public requestViewAsset(): void {
      this.viewAssetRequest.emit();
   }

   public requestChangeAsset(): void {
      this.changeAssetRequest.emit();
   }
}
