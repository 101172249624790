import { Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import type { Asset } from "src/app/assets/types/asset.types";
import { cleanWordPaste } from "src/app/shared/utils/app.util";

@Injectable({ providedIn: "root" })
export class AssetLogService {
   public async getLogs(assets: Array<number>): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageAssetLogs.php",
         { assets: JSON.stringify(assets) },
         { params: { action: "getAssetLogs" } },
      );
   }

   public async addEntry(
      entry: string,
      asset: Asset,
      date: Date,
      files: Array<number>,
      logViewable: 0 | 1,
   ): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageAssetLogs.php",
         {
            entry: cleanWordPaste(entry),
            assetID: asset.assetID,
            date: date,
            files: files,
            logViewable: logViewable,
         },
         { params: { action: "addEntry" } },
      );
   }

   public async updateLogDate(logID: number, date: Date): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageAssetLogs.php",
         { logID, date },
         { params: { action: "updateLogDate" } },
      );
   }

   public async deleteLogEntry(logID: number): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageAssetLogs.php",
         { logID },
         { params: { action: "deleteLogEntry" } },
      );
   }

   public async changeLogDetails(log: {
      logDetails: string;
      logID: number;
   }): Promise<AxiosResponse> {
      log.logDetails = cleanWordPaste(log.logDetails);
      return axios.post(
         "phpscripts/manageAssetLogs.php",
         { logID: log.logID, logDetails: log.logDetails },
         { params: { action: "changeLogDetails" } },
      );
   }

   public async deleteAssetLogFile(fileID: number): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageAssetLogs.php",
         { fileID },
         { params: { action: "deleteAssetLogFile" } },
      );
   }

   public async showHideLogEntry(log: {
      logID: number;
      logViewable: 0 | 1;
   }): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageAssetLogs.php",
         { logID: log.logID, logViewable: log.logViewable },
         { params: { action: "showHideLogEntry" } },
      );
   }
}
