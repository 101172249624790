import { computed, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageUser } from "src/app/users/services/manageUser";
import { z } from "zod";

@Injectable({ providedIn: "root" })
export class UnitOfMeasureAvailabilityService {
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly featureFlagService = inject(FeatureFlagService);
   private readonly manageUser = inject(ManageUser);

   private readonly userSchema = z.object({
      userInfo: z.object({
         customSettings: z.object({
            unitsOfMeasureEnabled: z.number(),
         }),
      }),
   });

   private readonly isLaunchFlagEnabled = computed(() => {
      const map = this.launchFlagsService.launchFlagMapSignal();
      if (map === null) return null;

      return map[Flags.UNITS_OF_MEASURE];
   });

   private readonly currentUser = toSignal(this.manageUser.currentUserChangesSafe$, {
      initialValue: null,
   });

   private readonly hasFeatureFlag = computed(() => {
      const flagSet = this.featureFlagService.featureSet();
      if (flagSet === null) return null;

      return flagSet.has("unitOfMeasure");
   });

   private readonly hasCustomerSettingUnitsOfMeasureEnabled = computed(() => {
      const currentUser = this.currentUser();
      if (currentUser === null) return null;

      const validationResult = this.userSchema.safeParse(currentUser);
      if (validationResult.success === false) return false;

      const currentUserValid = validationResult.data;
      return Boolean(currentUserValid.userInfo.customSettings.unitsOfMeasureEnabled);
   });

   public readonly canUseUnitOfMeasureSettings = computed(
      () => this.hasFeatureFlag() && this.isLaunchFlagEnabled(),
   );

   public readonly isFeatureEnabled = computed(
      () =>
         this.hasCustomerSettingUnitsOfMeasureEnabled() &&
         this.canUseUnitOfMeasureSettings(),
   );
}
