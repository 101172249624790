@if (type === "button") {
   <lim-ui-secondary-button
      icon="qrCodeRegular"
      class="lim-ui-hide-on-mobile"
      (click)="scanQrCode()"
      [dataLogLabel]="dataLogLabel"
   >
      {{ lang().ScanQRCode }}
   </lim-ui-secondary-button>
   <lim-ui-icon-button
      icon="qrCodeRegular"
      class="lim-ui-show-on-mobile"
      (click)="scanQrCode()"
      [dataLogLabel]="dataLogLabel"
   />
}
@if (type === "card") {
   <lim-ui-card>
      <a class="card-body" (click)="scanQrCode()">
         <lim-ui-icon
            icon="qrCodeRegular"
            iconColor="navy-blue"
            iconSize="large"
            [attr.data-log]="dataLogLabel"
         />
         <div>
            {{ lang().ScanQRCode }}
         </div>
      </a>
   </lim-ui-card>
}
@if (type === "list") {
   <li class="list-item">
      <a (click)="scanQrCode()" class="list-item-body">
         <lim-ui-icon
            icon="qrCodeRegular"
            iconSize="medium"
            class="list-item-icon"
            [attr.data-log]="dataLogLabel"
         />

         <span>{{ lang().ScanQRCode }}</span>
      </a>
   </li>
}
