import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   IconComponent,
   ModalService,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   SearchBoxComponent,
   UpsellPopover,
   SearchAllWrapperComponent,
} from "@limblecmms/lim-ui";
import { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { PurchaseOrderListComponent } from "src/app/purchasing/pos/purchase-order-list/purchase-order-list.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "vendor-purchase-order",
   templateUrl: "./vendorPurchaseOrders.wrapper.component.html",
   styleUrls: ["./vendorPurchaseOrders.wrapper.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      SearchBoxComponent,
      PrimaryButtonComponent,
      PopoverDirective,
      UpsellPopover,
      SearchAllWrapperComponent,
      PurchaseOrderListComponent,
   ],
})
export class VendorPurchasingOrders implements OnInit, OnDestroy {
   @Input() public vendor: Vendor | undefined;
   public search: string = "";
   public noSearchResults: boolean = false;
   public searchHints: LimbleMap<number, string> = new LimbleMap();
   private manageFeatureFlagsSub: Subscription = new Subscription();
   public purchaseOrders: Lookup<"poID", PurchaseOrder> = new Lookup("poID");
   protected canAddPOs: boolean = false;
   public openPurchaseOrderWatchVarSub: any;

   protected readonly managePO = inject(ManagePO);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageVendor = inject(ManageVendor);
   private readonly betterDate = inject(BetterDate);
   private readonly credService = inject(CredService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.setupPurchaseOrders();

      this.openPurchaseOrderWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.setupPurchaseOrders();

            this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
               () => {
                  this.canAddPOs = this.manageFeatureFlags.canAddPOs();
               },
            );
         },
      );
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.openPurchaseOrderWatchVarSub);
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private setupPurchaseOrders(): void {
      this.purchaseOrders = this.vendor?.vendorID
         ? this.managePO.getPurchaseOrdersByVendor(this.vendor?.vendorID)
         : new Lookup("poID");

      this.searchHints = new LimbleMap();

      if (this.search !== undefined && this.search.length > 0) {
         this.purchaseOrders =
            this.manageFilters.filterPurchaseOrdersToSearch(
               this.search,
               this.purchaseOrders,
               this.searchHints,
               this.managePO,
               this.manageLocation,
               this.manageVendor,
               this.betterDate,
            ) ?? this.purchaseOrders;
      }
   }

   private popPoComponent(poID: number): void {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };

      instance.result.then(() => {
         this.setupPurchaseOrders();
      });
   }

   protected createAPurchaseOrder(): void {
      assert(this.vendor);
      if (!this.canAddPOs) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.StartAPO,
         )
      ) {
         this.alertService.addAlert(this.lang().cred144Fail, "danger", 10000);
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangePOVendor,
         )
      ) {
         this.alertService.addAlert(this.lang().cred146Fail, "danger", 10000);
         return;
      }

      this.managePO
         .addPurchaseOrder(
            this.vendor.locationID,
            [],
            this.vendor.vendorID,
            0,
            0,
            "",
            "standard",
         )
         .then((answer) => {
            if (answer?.data.success === true) {
               this.popPoComponent(answer.data.po.poID);
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   protected searchPurchaseOrders(): void {
      this.setupPurchaseOrders();

      this.noSearchResults = this.purchaseOrders.size === 0;
   }
}
