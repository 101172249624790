import {
   ChangeDetectionStrategy,
   Component,
   input,
   output,
   ViewChild,
} from "@angular/core";
import type { MenuItem } from "@empowered/base/menus/menus.models";
import { type Menu, MenuModule } from "primeng/menu";

@Component({
   selector: "e-menu",
   templateUrl: "./menu.component.html",
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [MenuModule],
})
export class MenuComponent {
   @ViewChild("menu") menu!: Menu;

   /** Array of menuitems */
   items = input<MenuItem[]>();

   /** Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element */
   appendTo = input<any>("body");

   /** Whether the menu should be displayed as a popup */
   popup = input<boolean>(false);

   /** Defines a string that labels the input for accessibility */
   ariaLabel = input<string>();

   /** Establishes relationships between the component and label(s) where its value should be one or more element IDs */
   ariaLabelledBy = input<string>();

   /** Callback to invoke when overlay panel becomes visible */
   public readonly show = output();

   /** Callback to invoke when overlay panel becomes hidden */
   public readonly hide = output();

   /** Callback to invoke when a menu item is clicked */
   // eslint-disable-next-line angular/no-output-native -- We need this
   public readonly click = output<Event>();

   /** Toggles the visibility of the popup menu */
   public togglePopup(event: Event): void {
      event.stopPropagation();
      this.menu.toggle(event);
   }

   /** Shows the popup menu */
   public displayPopup(event: any): void {
      event.stopPropagation();
      this.menu.show(event);
   }

   /** Hides the popup menu  */
   public closePopup(event: Event): void {
      event.stopPropagation();
      this.menu.hide();
   }
}
