import { InjectionToken } from "@angular/core";
import type { Observable } from "rxjs";
import type { PmSuggestionDto } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestion-dto";
import { PmSuggestionsHttpService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestions-http.service";
import { PmSuggestionsMemoryService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestions-memory.service";
import type {
   CreateSuggestion,
   GetPmSuggestionsParams,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource-types";

export type PmSuggestionsPersistenceInterface = {
   get: (params: GetPmSuggestionsParams) => Observable<PmSuggestionDto[]>;
   delete: (params: { ids: Array<number> }) => Observable<void>;
   create: (params: { suggestion: CreateSuggestion }) => Observable<void>;
};

export const PmSuggestionsPersistence =
   new InjectionToken<PmSuggestionsPersistenceInterface>("PmSuggestionsPersistence");

export const providePmSuggestionsHttpPersistence = () => {
   return {
      provide: PmSuggestionsPersistence,
      useClass: PmSuggestionsHttpService,
   };
};

export const providePmSuggestionsMemoryPersistence = () => {
   return {
      provide: PmSuggestionsPersistence,
      useClass: PmSuggestionsMemoryService,
   };
};
