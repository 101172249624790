import { inject, Injectable } from "@angular/core";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskViewModelMapperService } from "src/app/tasks/components/shared/services/task-view-model-factory/mappers/task-view-model-mapper.service";
import type { TasksSchedulesCombinedEntity } from "src/app/tasks/components/shared/services/tasks-schedules-combined-api/tasks-schedules-combined.models";

@Injectable({
   providedIn: "root",
})
export class TasksSchedulesCombinedViewModelBuilderService {
   // injects
   private readonly mapper = inject(TaskViewModelMapperService);

   /**
    * Builds a TaskDataViewerViewModel object based on the provided TasksSchedulesCombinedEntity.
    * @param entity - The TasksSchedulesCombinedEntity object used to build the TaskDataViewerViewModel.
    * @return The built TaskDataViewerViewModel object.
    */
   public build(entity: TasksSchedulesCombinedEntity): TaskDataViewerViewModel {
      const numberOFComments = entity.checklistComments?.length ?? 0;
      // eslint-disable-next-line typescript/consistent-type-assertions -- This is a valid type assertion
      return {
         // For the name column
         tscNameCell: {
            id: entity.checklistID,
            name: entity.checklistName,
            taskTypeIcon: this.mapper.getTaskTypeIcon(entity),
            requesterInformation: this.mapper.getRequesterInformation(entity),
            hasComments: numberOFComments > 0,
            hasCompletionNotes: numberOFComments > 2,
            assetId: entity.assetID,
            assetName: this.mapper.getAssetName(entity),
            scheduleID: entity.scheduleID,
         },

         // For the Estimated Time Column
         estimatedTime: entity.checklistEstTime ?? 0,

         // For the Due Date Column
         checklistDueDateDisplay: this.mapper.getChecklistDueDateDisplay({
            checklistDueDate: entity.checklistDueDate,
            checklistStartDate: 0,
         } as any),
         scheduleID: entity.scheduleID,

         // For the Assigned To Column
         assignedTo: this.mapper.getAssignedTo(entity),
      } as TaskDataViewerViewModel;
   }
}
