import { AsyncPipe } from "@angular/common";
import type { OnInit } from "@angular/core";
import { Component, computed, forwardRef, inject, Input } from "@angular/core";
import type { LimbleTreeOptions } from "@limble/limble-tree";
import { TreeBranch } from "@limble/limble-tree";
import { from } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { WhiteLabelService } from "src/app/shared/services/white-label/white-label.service";
import type { BulkPrintTasksSettings } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ChkItem } from "src/app/tasks/components/chkItemElement/chkItem.element.component";
import { UpdateTaskStateService } from "src/app/tasks/components/task-form/services/update-task-state.service";
import { TaskPrintTemplateLegacyComponent } from "src/app/tasks/components/taskPrintTemplateElement/task-print-template-legacy/task-print-template-legacy.component";
import { TaskPrintTemplateComponent } from "src/app/tasks/components/taskPrintTemplateElement/task-print-template.component";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import type { Task } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services//manageUser";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "print-task-for-bulk",
   templateUrl: "./printTaskForBulk.element.component.html",
   styleUrls: ["./printTaskForBulk.element.component.scss"],
   imports: [
      forwardRef(() => TaskPrintTemplateLegacyComponent),
      forwardRef(() => TaskPrintTemplateComponent),
      AsyncPipe,
   ],
})
export class PrintTaskForBulk implements OnInit {
   @Input() public data:
      | {
           checklistID: number;
           mode: string;
           expand: boolean;
           guided: boolean;
           editable: boolean;
        }
      | undefined;
   @Input() public informationToInclude: BulkPrintTasksSettings | undefined;

   public chk: Task | undefined;
   public currentUser;
   public customerID;
   public logoURL;
   public userAssignedTo;
   public items;
   public info;
   public currencySymbol;
   public partsLookup: Lookup<"partID", Part>;
   public asset: Asset | undefined;
   public displayAssetInfo: boolean = false;
   public assetInfoFromCompletionArr;
   public checklistDowntimeHours;
   public checklistDowntimeMinutes;
   public checklistPromptTimeHours;
   public checklistPromptTimeMinutes;
   public billableHours;
   public billableMinutes;
   public categoriesIndex;
   public viewLaborCostsCred;
   public viewInvoicesCred;
   public itemsTree;
   public limbleTreeOptions?: LimbleTreeOptions;
   public EditingWOTemplateInstanceBeforeLive;
   public userStartedThisTask;
   public options;
   public minPartQtyTask;
   public countLoop;
   public customTagListObj;
   public clickTag;
   public tagsObs;
   public firstCall = 0;
   public typeStr;
   public totalItems;
   public totalCompleteItems;
   public allowEditTemplateInstructions;
   public locations;
   public userCompletedBy;
   public partCostOnPrintFlag;
   public assetNameStr: string = "";
   public assetFieldValues: Array<AssetFieldValue> = [];
   public printData;
   public relatedChecklistID;
   public parts: Array<Part> = [];

   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);
   private readonly manageParts = inject(ManageParts);
   private readonly manageBilling = inject(ManageBilling);
   private readonly credService = inject(CredService);
   private readonly manageFiles = inject(ManageFiles);
   private readonly manageTaskItem = inject(ManageTaskItem);
   public readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly managePO = inject(ManagePO);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly whiteLabelService = inject(WhiteLabelService);
   private readonly legacyLaunchFlagService = inject(LegacyLaunchFlagsService);
   private readonly updateTaskStateService = inject(UpdateTaskStateService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly isJITCompletedTasksChkEnabled = from(
      this.legacyLaunchFlagService.isEnabled(Flags.JIT_CT_TASK_CHK),
   );

   public constructor() {
      this.partsLookup = this.manageParts.getParts();

      this.updateTaskStateService.updateGuides$.subscribe(() => {
         this.info.setGuides();
      });
   }

   public ngOnInit() {
      this.locations = this.manageLocation.getLocationsIndex();
      assert(this.data);
      this.chk = this.manageTask.getTaskLocalLookup(this.data.checklistID);
      assert(this.chk);

      this.items = this.manageTaskItem.getTaskItems(this.chk.checklistID);
      if (this.chk.assetID !== null) {
         this.asset = this.manageAsset.getAsset(this.chk.assetID);
      }

      if (this.chk.assetInfoFromCompletion) {
         this.assetInfoFromCompletionArr = JSON.parse(this.chk.assetInfoFromCompletion);
      }

      this.partCostOnPrintFlag = Number(
         this.manageUser.getCurrentUser().userInfo.partCostOnPrintFlag,
      );
      this.limbleTreeOptions = {
         allowDrop: (draggedNode, proposedParentNode) => {
            const item = draggedNode.meta().nodeData;
            const parentItem =
               proposedParentNode instanceof TreeBranch
                  ? proposedParentNode.meta().nodeData
                  : null;
            if (
               item?.itemOptionID &&
               item?.itemID == parentItem?.itemID &&
               item?.parentItem?.itemTypeID == parentItem?.itemTypeID
            ) {
               return true;
            } else if (item?.itemOptionID) {
               return false;
            } else if (
               parentItem?.itemTypeID == TaskInstructionTypeID.OptionList ||
               parentItem?.itemTypeID == TaskInstructionTypeID.DropdownList
            ) {
               return false;
            }
            return true;
         },
         defaultComponent: { class: ChkItem, bindings: { info: this.info } },
         indent: 20,
      };
      this.userCompletedBy = this.manageUser.getUser(
         Number(this.chk.checklistUserCompleted),
      );
      if (this.chk.checklistPromptTime !== null) {
         this.checklistPromptTimeHours = Number(
            Math.floor(this.chk.checklistPromptTime / 3600),
         );
         this.checklistPromptTimeMinutes = Number(
            Math.floor((this.chk.checklistPromptTime / 60) % 60),
         );
      }
      this.categoriesIndex = this.manageBilling.getCategoriesIndex();

      if (this.chk.billableTime !== null && this.chk.billableTime > 0) {
         this.billableHours = Number(Math.floor(this.chk.billableTime / 3600));
         this.billableMinutes = Number(Math.floor((this.chk.billableTime / 60) % 60));
      }
      this.viewLaborCostsCred = this.credService.isAuthorized(
         this.chk.locationID,
         this.credService.Permissions.ViewLaborCosts,
      );
      this.viewInvoicesCred = this.credService.isAuthorized(
         this.chk.locationID,
         this.credService.Permissions.ViewTaskInvoices,
      );
      this.logoURL = this.whiteLabelService.logoUrl();
      this.currentUser = this.manageUser.getCurrentUser().userInfo;
      this.customerID = this.currentUser.customerID;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      this.userAssignedTo = this.manageUser.getUser(this.chk.userID);

      this.info = {
         mode: this.data.mode,
         items: [],
         options: [],
         editable: true,
         expand: false,
         limited: false,
         limitedSettings: false,
         itemWatchVar: 0,
         task: this.chk,
         setGuides: () => {
            //this makes the magic little arrow work...
            this.info.calcCompletion();
         },
         submitTags: null,
         openLogTimeModal: null,
         rebuildTags: () => {
            this.setCustomTagData();
         },
      };

      this.info.calcCompletion = () => {
         this.totalItems = 0;
         this.totalCompleteItems = 0;
         let complete = true;

         if (this.items) {
            for (const item of this.items) {
               if (
                  item.itemTypeID == TaskInstructionTypeID.Note ||
                  item.itemTypeID == TaskInstructionTypeID.InstructionSet
               ) {
                  continue;
               }

               if (item.itemTypeID == TaskInstructionTypeID.FileOrPictureAttachment) {
                  if (this.customerID == 546) {
                     //BRYAN TO DO... we will want a customer to be able to turn this on or off.
                     //we want to check files for bong
                  } else {
                     continue;
                  }
               }

               if (
                  item.itemTypeID == TaskInstructionTypeID.AssignAuditPM &&
                  item.checklistToSpawn == null
               ) {
                  continue;
               }

               if (
                  item.itemTypeID == TaskInstructionTypeID.AssignPM &&
                  item.checklistToSpawn == null
               ) {
                  continue;
               }

               if (this.info.doWeCheck(item)) {
                  this.totalItems++;

                  if (item.done) {
                     this.totalCompleteItems++;
                  } else {
                     complete = false;
                  }
               }
            }
         }

         return complete;
      };

      this.info.doWeCheck = (item) => {
         const parentResponse =
            item.parent === undefined ? null : item.parent.itemResponse;
         const parentTypeID = item.parent === undefined ? null : item.parent.itemTypeID;

         if (
            parentResponse == item.itemParentResponse ||
            item.itemParentID == 0 ||
            (parentTypeID > 0 &&
               parentTypeID != TaskInstructionTypeID.OptionList &&
               parentTypeID != TaskInstructionTypeID.DropdownList)
         ) {
            let returnVal = true;

            if (item.parent) {
               returnVal = this.info.doWeCheck(item.parent);
            }

            return returnVal;
         }
         return false;
      };

      this.info.buildData = this.buildData;

      this.updateTaskData();
      if (this.chk.checklistDowntime !== null) {
         this.checklistDowntimeHours = Number(
            Math.floor(this.chk.checklistDowntime / 3600),
         );
         this.checklistDowntimeMinutes = Number(
            Math.floor((this.chk.checklistDowntime / 60) % 60),
         );
      }
   }

   updateTaskData = () => {
      assert(this.data);
      this.chk = this.manageTask.getTaskLocalLookup(this.data.checklistID);
      assert(this.chk);
      const priority = this.manageTask.getPriorityInfo(this.chk.priorityID);
      if (!priority.priorityLevel) {
         this.manageTask.getPriorityInfo(this.chk.priorityID);
      }

      if (this.chk.assetID !== null) {
         this.asset = this.manageAsset.getAsset(this.chk.assetID);
         if (this.asset) {
            for (const valueID of this.asset?.assetValueIDs) {
               const value = this.manageAsset.getFieldValue(valueID);
               if (value) {
                  this.assetFieldValues.push(value);
               }
            }
            this.assetNameStr = this.manageAsset.getAssetNameIncludeParents(
               this.asset.assetID,
            );
         }
      }

      if (this.chk) {
         //remember these promptTimeHours, etc. is treated differently if extraTime is added or not
         if (this.chk.checklistPromptTime !== null) {
            this.checklistPromptTimeHours = Number(
               Math.floor(this.chk.checklistPromptTime / 3600),
            );

            this.checklistPromptTimeMinutes = Number(
               Math.floor((this.chk.checklistPromptTime / 60) % 60),
            );
         }

         if (this.chk.billableTime !== null && this.chk.billableTime > 0) {
            this.billableHours = Number(Math.floor(this.chk.billableTime / 3600));

            this.billableMinutes = Number(Math.floor((this.chk.billableTime / 60) % 60));
         }
      }

      //first call almost almost always is checklistID of false so only call after first
      if (this.firstCall < 1) {
         this.firstCall++;
         if (this.chk.checklistID > 0) {
            this.buildData();
         }
      }

      //this is a catch all in case it hasn't loaded for some reason.
      setTimeout(() => {
         if (this.items == 0) {
            this.buildData();
         }
      }, 3000);

      if (this.chk) {
         this.data.editable = false;
         this.info.editable = false;
      }
   };

   setShowCollapse = () => {
      for (const item of this.items) {
         if (
            (item.nodes !== undefined && item.nodes.length > 0) ||
            (item.options !== undefined && item.nodes.length > 0)
         ) {
            item.showCollapse = true;
         } else {
            item.showCollapse = false;
         }
         item.calcItemsTree = this.info.calcItemsTree;
      }

      for (const option of this.info.options) {
         if (
            (option.nodes !== undefined && option.nodes.length > 0) ||
            (option.options !== undefined && option.nodes.length > 0)
         ) {
            option.showCollapse = true;
         } else {
            option.showCollapse = false;
         }
      }
   };

   buildData = () => {
      assert(this.chk);
      if (this.chk.checklistID === undefined) {
         return;
      }

      if (this.chk.checklistDepreciated !== null && this.chk.checklistDepreciated > 0) {
         //basically when someone is starting a WO the WO is depreciated until it goes live.  We want to limit some of this functionality as they are basically previewing it before they make it go live.
         this.EditingWOTemplateInstanceBeforeLive = true;
      } else {
         this.EditingWOTemplateInstanceBeforeLive = false;
      }

      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }

      if (this.chk.assetID !== null) {
         this.assetNameStr = this.manageAsset.getAssetNameIncludeParents(
            this.chk.assetID,
         );
      }

      this.calcIfMinPartQtyTask();

      this.manageTaskItem.getTaskItems(this.chk.checklistID).then(
         (answer) => {
            assert(this.chk);
            //resynce some data that we moved out of the big get all completed tasks call
            const task = this.manageTask.getTaskLocalLookup(this.chk.checklistID);
            if (task !== undefined) {
               task.checklistGreen = Number(answer.data.checklistGreen);
               this.chk.checklistGreen = Number(answer.data.checklistGreen);
               task.checklistYellow = Number(answer.data.checklistYellow);
               this.chk.checklistYellow = task.checklistYellow;
               task.checklistRed = Number(answer.data.checklistRed);
               this.chk.checklistRed = task.checklistRed;
               task.reoccurID = Number(answer.data.reoccurID);
               this.chk.reoccurID = task.reoccurID;
               task.checklistPriorBatchID = Number(answer.data.checklistPriorBatchID);
               this.chk.checklistPriorBatchID = task.checklistPriorBatchID;
               task.checklistStartDate = Number(answer.data.checklistStartDate);
               task.assetInfoFromCompletion = answer.data.assetInfoFromCompletion;
               this.chk.assetInfoFromCompletion = answer.data.assetInfoFromCompletion;
            }

            for (const item of answer.data.items) {
               item.itemText = item.itemText.replace(/&quot;/g, "'");

               if (answer.data.items.length < 100) {
                  item.hovered = true;
               }
            }

            if (answer.data.userThatStartedThisTask > 0) {
               this.userStartedThisTask = {};
               this.userStartedThisTask.userID = answer.data.userThatStartedThisTask;

               const startedUser = this.manageTask
                  .getAllUsers()
                  .get(this.userStartedThisTask.userID);
               if (startedUser) {
                  this.userStartedThisTask.firstName = startedUser.userFirstName;
                  this.userStartedThisTask.lastName = startedUser.userLastName;
               } else {
                  this.userStartedThisTask = false;
               }
            } else {
               this.userStartedThisTask = false;
            }

            if (
               this.chk.checklistTemplate !== null &&
               this.chk.checklistTemplate > 0 &&
               this.chk.checklistDepreciated == 0
            ) {
               //we are editing one of the templates so limit the capabilities.  We add checklistDepreciated because sometimes we have temporairy templates loaded (start WO process)
               this.info.limited = true;
            }

            for (const item of answer.data.items) {
               if (item.itemCollapsed == 0) {
                  item.collapsed = false;
               } else {
                  item.collapsed = true;
               }
            }

            const options = answer.data.options || [];
            for (const option of options) {
               if (option.itemOptionCollapsed == 0) {
                  option.collapsed = false;
               } else {
                  option.collapsed = true;
               }
               option.itemOptionOrder = Number(option.itemOptionOrder);
            }

            this.items = answer.data.items || [];

            for (const item of this.items) {
               if (item.itemTypeID == TaskInstructionTypeID.Number) {
                  if (item.itemResponse == "" || item.itemResponse === false) {
                     item.itemResponse = "";
                  } else {
                     item.itemResponse = Number(item.itemResponse);
                  }
               }

               item.itemText = item.itemText.replace(/&gt;/g, ">");
               item.itemText = item.itemText.replace(/&lt;/g, "<");

               for (const past of item.pastResponses) {
                  const date = new Date(past.checklistCompletedDate * 1000);
                  let month: any = date.getDate();
                  if (month < 10) {
                     month = `0${month}`;
                  }
                  past.dateStr = `${date.getMonth() + 1}/${month}/${date.getFullYear()}`;
               }

               item.itemOrder = Number(item.itemOrder);

               // Note - this is a fix for CASE-4816. chk-item expects instruction items to have a task property.
               if (
                  item.itemTypeID === TaskInstructionTypeID.AssignPM ||
                  item.itemTypeID === TaskInstructionTypeID.StartWO ||
                  item.itemTypeID === TaskInstructionTypeID.Reassign
               ) {
                  item.task = this.manageTask.getTaskLocalLookup(
                     Number(item.itemResponse),
                  );
               }
            }

            this.items = orderBy(this.items, "itemOrder");

            const regex = /(?:\.([^.]+))?$/;
            for (const file of answer.data.files) {
               const tempFile = regex.exec(file.fileName);
               if (tempFile) {
                  file.ext = tempFile[1];
               }
            }

            this.info.items = this.items;
            this.info.files = answer.data.files;

            //set info for fileUploader
            for (const file of this.info.files) {
               if (this.manageFiles.imageExts.includes(file.ext)) {
                  file.getURL = `viewFile.php?f=upload-${this.customerID}/${file.checklistID}-${file.itemID}/${file.fileName}`;

                  file.parentRef = "itemID";
                  file.deleteData = {
                     fileName: file.fileName,
                     checklistID: file.checklistID,
                     itemID: file.itemID,
                  };
               }
            }

            //set info for fileuploader in chkitembuildoptions
            for (const item of this.items) {
               if (item.instructionalImages) {
                  item.instructionalImages.forEach((image) => {
                     image.getURL = image.src;
                     image.fileName = image.itemFileName;
                  });
               }
               item.viewOnly = true;
            }

            this.info.setGuides();

            //gets and consolidates the options
            this.options = answer.data.options || [];
            this.info.options = this.options;

            this.parts = answer.data.parts || [];

            this.calcIfMinPartQtyTask();

            this.countLoop = 0;

            this.calcTaskInvoices();
            this.setCustomTagData();

            //we do another calcuation to make sure the task is viewed properly.  This catchs when a task is completed on one device, but it isn't updated yet on another device
            this.chk.checklistUserCompleted = answer.data.checklistUserCompleted;
            if (
               this.chk.checklistUserCompleted !== null &&
               this.chk.checklistUserCompleted > 0
            ) {
               this.chk.checklistStatusID = 1;
            }

            this.calcItemsTree();
            //process saved snapshot of asset info if task is complete
            if (this.chk.checklistStatusID) {
               this.parseSavedAssetInfo();
            }

            if (this.asset) {
               this.displayAssetInfo = this.manageTask.toggleAssetFieldInfoDisplayLegacy(
                  this.asset,
                  this.chk.checklistID,
               );
            }

            this.printData = {
               checklistID: this.chk.checklistID,
               checklist: this.chk,
               items: this.items, // Note for future JIT work (TASK-507) - we should ensure that these items match the data model as it is used in task-form.
               info: this.info,
               asset: this.asset,
               displayAssetInfo: this.displayAssetInfo,
               itemsTree: this.itemsTree,
            };
         },
         (err) => {
            console.error(err);
         },
      );
   };

   parseSavedAssetInfo = () => {
      assert(this.chk);
      if (this.chk.assetInfoFromCompletion) {
         this.assetInfoFromCompletionArr = JSON.parse(this.chk.assetInfoFromCompletion);
      }
   };

   setCustomTagData = () => {
      assert(this.chk);
      this.customTagListObj = {};
      this.customTagListObj.clickTag = this.clickTag;
      this.customTagListObj.selectOne = false;
      this.customTagListObj.advancedSettings = true;
      this.customTagListObj.sources = [
         this.manageTask.getTaskLocalLookup(this.chk.checklistID)?.checklistInstructions,
         this.chk.checklistInstructions,
      ];
      this.customTagListObj.observable = this.tagsObs;
   };
   calcItemsTree = () => {
      this.itemsTree = [];
      if (!this.items) {
         return;
      }
      for (const item of this.items) {
         item.itemOrder = Number(item.itemOrder);

         if (item.itemParentID == 0) {
            this.itemsTree.push(item);
         }

         switch (Number(item.itemTypeID)) {
            case TaskInstructionTypeID.DropdownList:
            case TaskInstructionTypeID.OptionList:
               item.nodes = orderBy(
                  this.options.filter((option) => option.itemID === item.itemID),
                  "itemOptionOrder",
               );
               if (item.nodes.length > 0) {
                  if (
                     item.nodes[item.nodes.length - 1].itemOptionOrder !=
                     item.nodes.length
                  ) {
                     for (let index = 0; index < item.nodes.length; index++) {
                        item.nodes[index].itemOptionOrder = index + 1;
                     }
                  }
               }

               for (const option of item.nodes) {
                  option.nodrag = true;
                  option.parentItemTypeID = item.itemTypeID;
                  option.parentItem = item;
                  option.nodes = orderBy(
                     this.items.filter(
                        (elem) =>
                           elem.itemParentID === item.checklistItemCount &&
                           elem.itemParentResponse == option.itemOptionCount,
                     ),
                     "itemOrder",
                  );
                  if (option.nodes.length > 0) {
                     if (
                        option.nodes[option.nodes.length - 1].itemOrder !=
                        option.nodes.length
                     ) {
                        for (let index = 0; index < option.nodes.length; index++) {
                           option.nodes[index].itemOrder = index + 1;
                        }
                     }
                  }
               }

               break;
            default:
               item.nodes = orderBy(
                  this.items.filter(
                     (elem) => elem.itemParentID === item.checklistItemCount,
                  ),
                  "itemOrder",
               );
               if (item.nodes.length > 0) {
                  if (item.nodes[item.nodes.length - 1].itemOrder != item.nodes.length) {
                     for (let index = 0; index < item.nodes.length; index++) {
                        item.nodes[index].itemOrder = index + 1;
                     }
                  }
               }
               break;
         }
      }
      this.itemsTree = orderBy(this.itemsTree, "itemOrder");
      if (this.itemsTree.length > 0) {
         if (
            this.itemsTree[this.itemsTree.length - 1].itemOrder != this.itemsTree.length
         ) {
            for (let index = 0; index < this.itemsTree.length; index++) {
               this.itemsTree[index].itemOrder = index + 1;
            }
         }
      }

      this.setShowCollapse();
   };

   calcTaskInvoices = () => {
      this.countLoop++;
      if (this.countLoop > 200) {
         //protects vs inf loop
         return;
      }

      let load = false;

      if (this.manageTask.getAllUsers().size > 0) {
         //if users are loaded that means the data is loaded
         load = true;
      } else {
         setTimeout(() => {
            this.calcTaskInvoices();
         }, 300);
      }

      if (load) {
         setTimeout(() => {
            assert(this.chk);

            const invoices: Array<any> = [];
            const invoiceIndex = this.manageInvoice.getInvoicesIndex();
            for (const invoiceID of this.chk.invoiceIDs) {
               invoices.push(invoiceIndex[invoiceID]);
            }

            let invoiceLength = invoices.length;
            while (invoiceLength--) {
               const invoice = invoices[invoiceLength];
               if (!invoice.poItemID) continue;
               const poItem = this.managePO.getPurchaseOrderItem(invoice.poItemID);
               //go backwards so splice worked correctly every time if there are multiples
               if (!poItem?.poID) {
                  //have to do a sanity check to see if this doesn't exist.  If it doesn't exist then we need to remove it form the array
                  invoices.splice(invoiceLength, 1);
                  continue;
               }
               const purchaseOrder = this.managePO.getPurchaseOrder(poItem.poID);
               if (purchaseOrder === undefined) continue;
               invoice.poID = poItem.poID;
               invoice.poNumber = purchaseOrder.poNumber;
               invoice.poState = this.managePO.getPurchaseOrderCurrentState(
                  purchaseOrder.poID,
               )?.name;
            }

            if (
               this.chk.assetID &&
               (this.assetNameStr === undefined || this.assetNameStr == "")
            ) {
               this.assetNameStr = this.manageAsset.getAssetNameIncludeParents(
                  this.chk.assetID,
               );
            }
         }, 200);
      }
   };

   private calcIfMinPartQtyTask(): void {
      //This is used so that there is a shortcut to start a PO for that specific part
      if (
         this.chk !== undefined &&
         this.chk.checklistBatchID == 20001 &&
         this.chk.checklistPriorBatchID &&
         this.manageParts.getPart(this.chk.checklistPriorBatchID)
      ) {
         this.minPartQtyTask = this.manageParts.getPart(this.chk.checklistPriorBatchID);
      }

      if (this.minPartQtyTask) {
         //lets find pending POs for this
         const purchaseOrders = this.managePO.findPendingPurchaseOrderInfoForPart(
            this.minPartQtyTask.partID,
            true,
         ).purchaseOrders;

         this.minPartQtyTask.pendingPOsCurrentStateMap = purchaseOrders.map(
            ([poID, purchaseOrder]) => [
               poID,
               {
                  ...this.managePO.getPurchaseOrderCurrentState(poID),
                  poID,
                  poNumber: purchaseOrder.poNumber,
               },
            ],
         );
      }
   }
}
