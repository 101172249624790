<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="season-config">
            <span class="message-area message-alignment" [limbleHtml]="message"></span>
            @if (superUser) {
               <lim-ui-secondary-button
                  modalTitle
                  icon="editRegular"
                  (click)="openSeasonsConfig()"
               >
                  {{ successString }}
               </lim-ui-secondary-button>
            }
         </span>
      </lim-ui-info-panel>
      @if (seasonsList.size === 0 && !loading) {
         <lim-ui-panel>
            <div>
               {{ lang().noSeasonsSetupMsg }}
            </div>
         </lim-ui-panel>
      }

      @if (seasonsList.size > 0) {
         <lim-ui-panel>
            <div class="deselect-all">
               <a class="cursor" (click)="deselectAll()">{{ lang().DeselectAll }}</a>
            </div>
            @for (season of seasonsList | keyvalue: returnZero; track season) {
               <div (click)="focusSeason(season.value)">
                  <div
                     class="lim-ui-list-item lim-ui-selectable-list-item"
                     [ngClass]="{ 'lim-ui-selected-list-item': season.value.selected }"
                  >
                     <div class="lim-ui-list-item-content">
                        <span>
                           @if (season.value.selected) {
                              <lim-ui-icon
                                 iconColor="success"
                                 icon="check"
                                 iconSize="small"
                              />
                           }

                           <span
                              ><b>{{ season.value.name }}:</b>
                              {{ season.value.formattedStartDate }}</span
                           >
                           @if (season.value.endMonth > 0) {
                              <span> - {{ season.value.formattedEndDate }}</span>
                           }

                           @if (seasonType === "holiday") {
                              <span class="holiday-explanation"
                                 >{{ lang().PMsAre }}:
                                 <b>{{ season.value.createdStr }}</b></span
                              >
                           }
                        </span>
                     </div>
                  </div>
               </div>
            }
            <div
               (click)="focusNoSeasonOption()"
               class="lim-ui-list-item lim-ui-selectable-list-item"
               [ngClass]="{ 'lim-ui-selected-list-item': noSeasonOption }"
            >
               <i class="fa-solid fa-check greenColor" [hidden]="!noSeasonOption"></i>

               <span
                  ><b>{{ lang().None }}</b></span
               >
            </div>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Select"
      (successClick)="selectSeasons()"
   />
</lim-ui-modal>
