import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { CheckMarkPopFillComponent } from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";

@Component({
   selector: "anomalous-value-confirmed-card",
   standalone: true,
   imports: [CommonModule, CheckMarkPopFillComponent],
   templateUrl: "./anomalous-value-confirmed-card.component.html",
   styleUrls: ["./anomalous-value-confirmed-card.component.scss"],
})
export class AnomalousValueConfirmedCardComponent {
   protected readonly lang = inject(TranslationService).i18n;
}
