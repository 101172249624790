import { describeCountCategory } from "src/app/parts/unit-of-measure/provided-units/descriptions/utils/describe-unit-category";

export const countDescription = describeCountCategory({
   each: {
      singular: "each",
      short: "ea",
   },
   box: {
      singular: "box",
      short: "box",
   },
   case: {
      singular: "case",
      short: "case",
   },
   drum: {
      singular: "drum",
      short: "drum",
   },
   pallet: {
      singular: "pallet",
      short: "pallet",
   },
});
