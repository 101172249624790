import {
   PartFieldType,
   type PartField,
   type PartFieldJSON,
} from "src/app/parts/types/field/field.types";
import type { Filter, SearchFields } from "src/app/shared/types/general.types";
/**
 * Generates the correct JSON fields to be used in the part field search.
 */
export function generatePartFields(
   filters: Filter<PartField>[],
   searchFields: SearchFields,
) {
   const partFields: Array<PartFieldJSON> = filters.map((filter) => {
      const field = searchFields[filter.fieldName];
      const fieldName = filter.field.fieldName;
      const partFieldJSON: PartFieldJSON = {
         fieldName: filter.fieldName,
         id: filter.field.fieldID ?? 0,
         date: `${field.beginDate ?? ""} ${field.endDate ?? ""}`,
         displayName: fieldName ?? "",
         fieldTypeID: filter.field.fieldTypeID ?? 0,
         partNameFilter: fieldName === "Name",
         partNumberFilter: fieldName === "Number",
         partQtyFilter: fieldName === "Qty",
         partCategoryFilter: fieldName === "Category",
         partPriceFilter: filter.field.fieldTypeID === PartFieldType.Currency,
         partLocationFilter: fieldName === "Location",
         valueContentContains: field.searchValue ?? "",
         number: Number(field.searchValue ?? 0),
         includeCondition: true,
      };

      // Keeps the query params from getting unnecessarily large.
      for (const key in partFieldJSON) {
         if (
            partFieldJSON[key] === undefined ||
            partFieldJSON[key] === null ||
            partFieldJSON[key] === "" ||
            partFieldJSON[key] === false
         ) {
            delete partFieldJSON[key];
         }
      }

      return partFieldJSON;
   });
   return partFields;
}
