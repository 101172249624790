<lim-ui-panel>
   <div class="custom-title">
      <div class="title">
         <lim-ui-icon iconSize="small" icon="cubes" />
         {{ lang().ChildrenAssets }}
      </div>
      <lim-ui-search-all-wrapper>
         <lim-ui-search-box
            searchBox
            [(searchVal)]="searchBar"
            (searchValChange)="buildView()"
         />
      </lim-ui-search-all-wrapper>
   </div>
   @if (noSearchResults && assets?.size) {
      <span>
         <no-search-results />
      </span>
   }
   <hierarchy-container-legacy [treeData]="treeData" [options]="hierarchyOptions" />
   <div class="add-child-button">
      <lim-ui-primary-button
         icon="plus"
         [dataLogLabel]="
            'manageAssets-initiateAddChildAssetFromAssetCard-clickAddChildAsset'
         "
         (click)="addChildAsset()"
      >
         {{ lang().AddAChildAsset }}
      </lim-ui-primary-button>
   </div>
</lim-ui-panel>
