<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body [scrollable]="true">
      <lim-ui-panel>
         <div class="panel-container scroll-height-inheritance">
            <div class="header-action-container">
               <div>
                  @if (message) {
                     <div class="message">
                        <b><p [limbleHtml]="message"></p></b>
                     </div>
                  }
                  @if (filterToAssociatedVendors) {
                     <a (click)="viewAssociatedVendors()">{{
                        lang().ViewOnlyVendorsAssociatedToTheAssetThisTaskIsAssignTo
                     }}</a>
                  }

                  @if (!selectOne) {
                     <div class="selection-controls">
                        <lim-ui-selection-controls
                           [selectAll]="lang().SelectAll"
                           (handleSelectAll)="markAllVendorSelection()"
                           [deselectAll]="lang().DeselectAll"
                           (handleDeselectAll)="clearVendorSelection()"
                        />
                     </div>
                  }
               </div>
               <span class="header-action-right">
                  <lim-ui-search-all-wrapper>
                     <lim-ui-search-box
                        [focusOnLoad]="true"
                        searchBox
                        [(searchVal)]="searchBar"
                        (searchValChange)="updateSearchBar()"
                        [placeholder]="lang().Search"
                     />
                     @if (searchBar) {
                        <lim-ui-filter-input
                           searchAllCriteria
                           (clearFilterEvent)="searchBar = ''; updateSearchBar()"
                           [filterCriteria]="searchBar"
                        >
                           <label inputLabel> {{ lang().SearchFilter }}: </label>
                        </lim-ui-filter-input>
                     }
                  </lim-ui-search-all-wrapper>
               </span>
            </div>

            @if (iDontKnowOption && vendorsLength > 20) {
               <div>
                  @if (iDontKnowOption) {
                     <div
                        class="lim-ui-list-item lim-ui-selectable-list-item icon-with-text"
                        (click)="iDontKnow()"
                        [ngClass]="{ 'lim-ui-selected-list-item': unsure }"
                     >
                        <div class="icon-with-text">
                           @if (unsure) {
                              <lim-ui-icon
                                 icon="check"
                                 iconSize="small"
                                 class="green-color"
                              />
                           }
                           <lim-ui-icon icon="triangleExclamation" iconSize="small" />
                           {{ lang().DontAssignToAVendor }}
                        </div>
                     </div>
                  }
               </div>
            }

            @if (data.length) {
               <hierarchy-container-legacy
                  #hierarchy
                  [treeData]="data"
                  [options]="hierarchyOptions"
               />
            }

            @if (iDontKnowOption) {
               <div>
                  @if (iDontKnowOption) {
                     <div
                        class="lim-ui-list-item lim-ui-selectable-list-item icon-with-text"
                        (click)="iDontKnow()"
                        [ngClass]="{ 'lim-ui-selected-list-item': unsure }"
                     >
                        <div class="icon-with-text">
                           @if (unsure) {
                              <lim-ui-icon
                                 icon="check"
                                 iconSize="small"
                                 class="green-color"
                              />
                           }
                           <lim-ui-icon icon="triangleExclamation" iconSize="small" />
                           {{ lang().DontAssignToAVendor }}
                        </div>
                     </div>
                  }
               </div>
            }

            @if (vendorsLength == 0) {
               <span>
                  @if (searchBar) {
                     <span><no-search-results /></span>
                  }

                  @if (!addVendorCred) {
                     <label
                        class="control-label warning-text"
                        [limbleHtml]="
                           lang().PleaseHaveSomeoneWithAnAddVendorPermissionAddVendors
                        "
                     >
                     </label>
                  }
               </span>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      <div class="modal-footer-buttons">
         <div>
            @if (addVendorCred) {
               <lim-ui-secondary-button
                  class="left-button"
                  [icon]="canAddVendors ? 'plus' : 'stars'"
                  limUiTooltip="{{ lang().CreateANewVendorTooltip }}"
                  (click)="addVendor()"
                  [disabled]="!canAddVendors"
                  [limUiPopover]="createVendorPopover"
                  [limUiPopoverType]="'upsell'"
                  [limUiPopoverPlacement]="'right'"
                  [limUiPopoverHidden]="canAddVendors"
                  [dataLogLabel]="dataLogOptions?.vendorInitiateLabel"
               >
                  {{ lang().CreateVendor }}
               </lim-ui-secondary-button>
            }
            <ng-template #createVendorPopover>
               <upsell-popover
                  [message]="lang().FeatureAddVendorPopoverMessage"
                  [pricingLinkText]="lang().LearnMore"
               />
            </ng-template>
         </div>

         <div class="modal-footer-end-buttons">
            <lim-ui-primary-button (click)="submit()">{{
               lang().Select
            }}</lim-ui-primary-button>
         </div>
      </div>
   </lim-ui-modal-footer>
</lim-ui-modal>
