import {
   Component,
   computed,
   inject,
   input,
   Input,
   type OnInit,
   signal,
} from "@angular/core";
import { LimbleHtmlDirective, ModalService, PanelComponent } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CostViewerComponent } from "src/app/purchasing/currency/components/cost-viewer-component/cost-viewer-component";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { EscapePipe } from "src/app/shared/pipes/customEscape.pipe";
import { ParamsService } from "src/app/shared/services/params.service";
import type { InvoiceEntity } from "src/app/tasks/components/shared/services/invoices-api/invoice-api.models";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "task-invoices",
   templateUrl: "./task-invoices.component.html",
   styleUrls: ["./task-invoices.component.scss"],
   imports: [PanelComponent, EscapePipe, LimbleHtmlDirective, CostViewerComponent],
})
export class TaskInvoicesComponent implements OnInit {
   public readonly invoices = input.required<Array<InvoiceEntity>>();
   public readonly taskID = input.required<number>();
   protected readonly currentLocationID = signal<number | undefined>(undefined);
   @Input() public locationID: number | undefined; // Declare locationID as a function returning a signal

   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);
   protected readonly managePO = inject(ManagePO);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);

   protected readonly currentUser = this.manageUser.getCurrentUser();
   protected readonly currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
   protected readonly customerID = this.currentUser.userInfo.customerID;
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit(): void {
      this.currentLocationID.set(this.locationID);
   }
   protected popPoComponent(poID: number): void {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID },
         },
      };
   }
}
