import type { OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   Component,
   EventEmitter,
   Input,
   Output,
   computed,
   signal,
} from "@angular/core";
import type { DragEndEvent, LimbleTreeOptions } from "@limble/limble-tree";
import { LimbleTreeRootComponent } from "@limble/limble-tree";
import {
   IconComponent,
   ModalService,
   OutlinedButtonComponent,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { EditPartField } from "src/app/parts/components/editPartFieldModal/editPartField.modal.component";
import { AddPartField } from "src/app/parts/components/field/addPartFieldModal/addPartField.modal.component";
import { PartCategory } from "src/app/parts/components/fields/partCategory/partCategory.element.component";
import { PartLocation } from "src/app/parts/components/fields/partLocation/partLocation.element.component";
import { PartName } from "src/app/parts/components/fields/partName/partName.element.component";
import { PartNumber } from "src/app/parts/components/fields/partNumber/partNumber.element.component";
import { PartPrice } from "src/app/parts/components/fields/partPrice/partPrice.element.component";
import { PartQty } from "src/app/parts/components/fields/partQty/partQty.element.component";
import { PartInformationItemComponent } from "src/app/parts/components/part-modal/components/part-information-item/part-information-item.component";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { PartFieldValue } from "src/app/parts/types/field/value/part-field-value.types";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureAvailabilityService } from "src/app/parts/unit-of-measure/unit-of-measure-availability.service";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { PurchaseOrderItemType } from "src/app/purchasing/pos/purchase-order-item-type";
import type { PurchaseOrderItemToAddSkeleton } from "src/app/purchasing/services/managePO";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { CurrencyService } from "src/app/shared/services/currency.service";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "part-information-tab",
   templateUrl: "./part-information-tab.component.html",
   styleUrls: ["./part-information-tab.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      PartName,
      PartNumber,
      PartQty,
      OutlinedButtonComponent,
      PartPrice,
      PartLocation,
      PartCategory,
      LimbleTreeRootComponent,
      PrimaryButtonComponent,
      UpsellPopover,
      PopoverDirective,
      CurrencySymbolPipe,
   ],
})
export class PartInformationTabComponent implements OnInit, OnDestroy {
   @Input() public part: Part | undefined;
   @Input() public restrict;
   @Output() public readonly changeTab: EventEmitter<string> = new EventEmitter();
   public fields: Array<PartFieldValue> = [];
   public treeOptions?: LimbleTreeOptions;
   public dateOptions;
   public formats;
   public format;
   public partFieldsSub;
   public partFieldsObs;
   protected readonly hasPermissionStartPurchaseOrder = signal(false);
   public creds: { configurePartFields: boolean } = { configurePartFields: false };
   public allPartFieldsSub: Subscription | null;
   public partFieldValues: Lookup<"valueID", PartFieldValue>;
   protected canAddPOs: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription;

   private readonly modalService = inject(ModalService);
   protected readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly managePO = inject(ManagePO);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   protected readonly currencyService = inject(CurrencyService);

   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   protected readonly isUnitOfMeasureEnabled = inject(UnitOfMeasureAvailabilityService)
      .isFeatureEnabled;
   protected currencyCode = signal("");

   public constructor() {
      this.partFieldValues = this.manageParts.getFieldValues();

      this.allPartFieldsSub = this.manageObservables.setSubscription("partFields", () => {
         if (!this.part) {
            return;
         }
         this.buildFields();
      });

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(() => {
         this.canAddPOs = this.manageFeatureFlags.canAddPOs();
      });
   }

   public ngOnInit() {
      if (this.part === undefined) {
         throw new Error(
            "partInformation component requires a `part` input binding, but a value was not provided.",
         );
      }

      this.getPartCreds();

      if (
         this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.StartAPO,
         )
      ) {
         this.hasPermissionStartPurchaseOrder.set(true);
      }

      this.partFieldsObs = `partFields${this.part.partID}`;
      this.partFieldsSub = this.manageObservables.createAndSubscribe(
         this.partFieldsObs,
         this.buildFields.bind(this),
      );

      this.treeOptions = {
         allowNesting: false,
         defaultComponent: {
            class: PartInformationItemComponent,
         },
      };

      this.dateOptions = {
         formatYear: "yy",
         startingDay: 1,
      };

      this.formats = ["dd-MMMM-yyyy", "yyyy/MM/dd", "dd.MM.yyyy", "shortDate"];
      this.format = this.formats[0];
      this.currencyCode.set(
         this.currencyService.getCurrencyCodeByLocationID(this.part.locationID),
      );
   }

   public ngOnDestroy() {
      this.manageObservables.unsubscribeAndDelete(this.partFieldsObs, this.partFieldsSub);
      this.allPartFieldsSub?.unsubscribe();
      this.manageFeatureFlagsSub.unsubscribe();
   }

   /** function called when you drag/drop an item */
   protected async onMoveNode(
      event: DragEndEvent<PartInformationItemComponent>,
   ): Promise<void> {
      assert(this.part);
      const draggedField = this.fields.splice(event.oldIndex(), 1);
      this.fields.splice(event.newIndex(), 0, draggedField[0]);
      const updates: Array<[number, number]> = [];
      for (const [index, field] of this.fields.entries()) {
         field.valueSort = index + 1;
         updates.push([field.valueID, field.valueSort]);
      }
      const answer = await this.manageParts.updateSorts(updates, this.part.locationID);
      if (answer.data.success === true) {
         this.manageParts.tileFieldsObs$.next(null);
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   //copied from assets
   public addDropdown(field): void {
      const instance = this.modalService.open(EditPartField);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            field: field,
         },
      };

      instance.result.then((result) => {
         if (result.delete) {
            this.manageParts.removeSuggestedField(field).then((answer) => {
               if (answer?.data.success === true) {
                  for (const [idx, fieldToRemove] of this.fields.entries()) {
                     if (fieldToRemove.fieldID === field.fieldID) {
                        this.fields.splice(idx, 1);
                     }
                  }
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   }

   public async startAPO(): Promise<void> {
      assert(this.part);
      if (!this.canAddPOs) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.StartAPO,
         )
      ) {
         this.alertService.addAlert(this.lang().cred144Fail, "danger", 10000);
         return;
      }

      const description = this.getPODescription(this.part);

      const items: Array<PurchaseOrderItemToAddSkeleton> = [
         {
            partID: this.part.partID,
            assetID: 0,
            checklistID: 0,
            description: description,
            qty: this.managePO.calcPurchaseOrderItemQty(this.part),
            glID: 0,
            itemType: PurchaseOrderItemType.Part,
            rate: 0,
         },
      ];
      if (this.part.defaultVendorID === null || this.part.defaultVendorID < 0) return;

      const answer = await this.managePO.addPurchaseOrder(
         this.part.locationID,
         items,
         this.part.defaultVendorID,
         0,
         0,
         "",
         "standard",
      );

      if (answer?.data.success === true) {
         await this.popPoComponent(answer.data.po.poID);
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   private getPODescription(part: Part): string {
      const PODescription = this.manageLocation.getLocationPODescription(part.locationID);
      if (PODescription === undefined || PODescription === null) {
         return "";
      }
      const fields = PODescription.split(",");
      let newDescription = "";

      const partFields = this.manageParts.getFields();

      for (const field of this.partFieldValues) {
         for (const POField of fields) {
            if (
               partFields.get(field.fieldID)?.fieldName === POField &&
               field.partID === part.partID
            ) {
               newDescription += `${field.valueContent} `;
            }
         }
      }

      return newDescription;
   }

   public addField(part: Part): void {
      assert(this.part);
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ConfigurePartInformationFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred169Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(AddPartField);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AddAFieldMsg,
            title: this.lang().AddAField,
            part: part,
         },
      };

      instance.result.then((result) => {
         if (result !== 0) {
            if (result.view === "suggested") {
               let delay = 100;
               for (const fieldID of result.field) {
                  const obj = { part: part, fieldID: fieldID };
                  setTimeout(
                     () => {
                        this.manageParts
                           .addExistingField(obj.part, obj.fieldID)
                           .then((answer) => {
                              if (answer?.data.success === true) {
                                 //update the parent and the child's tile fields
                                 this.manageParts.tileFieldsObs$.next(null);
                                 this.alertService.addAlert(
                                    this.lang().FieldSuccessfullyAdded,
                                    "success",
                                    1000,
                                 );
                              } else {
                                 this.alertService.addAlert(
                                    this.lang().errorMsg,
                                    "danger",
                                    6000,
                                 );
                              }
                           });
                     },
                     delay,
                     obj,
                  );
                  delay = delay + 200;
               }
            }

            if (result.view === "new") {
               this.manageParts
                  .addFieldValue(
                     part,
                     result.type.fieldTypeID,
                     result.name,
                     result.options,
                  )
                  .then((answer) => {
                     if (answer?.data.success === true) {
                        this.manageParts.tileFieldsObs$.next(null);

                        this.alertService.addAlert(
                           this.lang().FieldSuccessfullyAdded,
                           "success",
                           1000,
                        );
                        //if it is a dropdown open up the dialog to edit the fellow
                        if (answer.data.row.fieldTypeID === 7) {
                           this.addDropdown(answer.data.row);
                        }
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     }
                  });
            }

            if (result.view === "updated") {
               this.manageParts.tileFieldsObs$.next(null);
            }
         }
      });
   }

   public async popPoComponent(poID: number): Promise<void> {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID },
         },
      };

      await instance.result;

      this.manageParts.tileFieldsObs$.next(null);
   }

   private getPartCreds(): void {
      assert(this.part);
      this.creds.configurePartFields = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.ConfigurePartInformationFields,
      );
   }

   protected switchTab($event): void {
      this.changeTab.emit($event);
   }

   public buildFields(): void {
      assert(this.part);
      this.fields = [];

      for (const valueID of this.part.partValueIDs) {
         const value = this.partFieldValues.get(valueID);
         assert(value);
         this.fields.push(value);
      }

      this.fields = orderBy(this.fields, "valueSort");
   }
}
