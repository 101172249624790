<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="config-container">
            <p>
               <b>{{ lang().possiblePlaceholders }}</b>
            </p>
            <p>{{ taskInfoPlaceholder }} - {{ lang().taskInfoPlaceholderDefinition }}</p>
            <p>{{ taskLinkPlaceholder }} - {{ lang().taskLinkPlaceholderDefinition }}</p>
         </div>
         <hr />
         <div class="config-container">
            <div class="form-group">
               <label class="control-label">{{ lang().Subject }}</label>
               <div
                  [(ngModel)]="emailSubject"
                  limbleContentEditable
                  disableEditor="true"
                  update-on-keyup
                  stripHtml="true"
                  class="limble-content-editable-class"
               ></div>
            </div>
            <div class="form-group">
               <label class="control-label">{{ lang().Message }}</label>
               <div
                  [(ngModel)]="emailMessage"
                  limbleContentEditable
                  disableEditor="true"
                  update-on-keyup
                  stripHtml="false"
                  class="limble-content-editable-class"
               ></div>
            </div>
         </div>
      </lim-ui-info-panel>
      @if (errorMsg) {
         <lim-ui-alert [title]="lang().Warning" alertType="danger">
            <div [limbleHtml]="errorMsg"></div>
         </lim-ui-alert>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Set }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
