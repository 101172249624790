<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="log-time-panel-container">
            <label class="control-label" [limbleHtml]="lang().HowLongToComplete"> </label>
            @if (viewingLengthHint) {
               <label
                  class="control-label secondary-text"
                  [limbleHtml]="viewingLengthHint"
               ></label>
            }
            <div class="log-time-panel-column">
               <div class="form-group" [ngClass]="{ 'has-error': hoursError }">
                  <label class="control-label-no-margin-bottom bold">{{
                     lang().Hours
                  }}</label>

                  <lim-ui-number-input-with-buttons
                     [(unit)]="hours"
                     (unitChange)="resetErrors(['hoursError', 'minutesError'])"
                     [incrementBy]="1"
                     [canEdit]="editTime"
                     [hasError]="hoursError"
                  />
               </div>
            </div>
            <div class="log-time-panel-column">
               <div class="form-group" [ngClass]="{ 'has-error': minutesError }">
                  <label class="control-label-no-margin-bottom bold">{{
                     lang().Minutes
                  }}</label>

                  <lim-ui-number-input-with-buttons
                     [(unit)]="minutes"
                     (unitChange)="resetErrors(['hoursError', 'minutesError'])"
                     [incrementBy]="5"
                     [canEdit]="editTime"
                     [hasError]="minutesError"
                  />
               </div>
            </div>
         </div>
         @if (cred) {
            <div class="log-time-panel-container cred-class">
               <div class="log-time-panel-column">
                  <div class="form-group">
                     <label class="control-label">{{ lang().WhoWhen }}</label>
                     <a class="cursor" (click)="changeWho()">{{ userStr }}</a>
                  </div>
               </div>
               <div class="log-time-panel-column">
                  <div class="form-group">
                     <label class="control-label">{{ lang().When }}</label>
                     <a class="cursor allowOffline" (click)="changeLoggedAt()">{{
                        loggedAt | betterDate: "date"
                     }}</a>
                  </div>
               </div>
            </div>
         }
      </lim-ui-panel>
      <lim-ui-panel>
         <div class="log-time-panel-container">
            <div class="label-icon-wrapper">
               <label [limbleHtml]="lang().WhatWasDoneOnThisTask"> </label>
               <lim-ui-icon-button
                  [icon]="noteHidden ? eyeSlashIcon : eyeIcon"
                  iconSize="small"
                  limUiTooltip="{{ lang().ShowHideDescriptionOfLaborTooltip }}"
                  (click)="showHideNoteSetting()"
               />
            </div>
            <div class="notes-to-the-requestor-wrapper">
               <div
                  type="text"
                  [(ngModel)]="notes"
                  class="large-text-field"
                  limbleContentEditable
               ></div>
            </div>
         </div>
      </lim-ui-panel>
      @if (categories?.length > 0) {
         <lim-ui-panel (click)="resetErrors(['laborCategoryError'])">
            <div class="log-time-panel-container">
               <label
                  class="control-label"
                  [limbleHtml]="lang().WhatBillingCategoryWouldYouLikeToUse"
               >
               </label>

               <div class="log-time-panel-column">
                  <div class="form-group" [ngClass]="{ 'has-error': laborCategoryError }">
                     <label class="control-label bold">{{
                        lang().LaborCategories
                     }}</label>
                     <lim-ui-form-dropdown-input
                        [inputString]="selectedCategoryName"
                        [hasError]="laborCategoryError"
                     >
                        <ng-container menu>
                           @for (category of categories; track category) {
                              <lim-ui-dropdown-text-item
                                 [displayString]="category.categoryName"
                                 (click)="setCategory(category)"
                              />
                           }
                        </ng-container>
                     </lim-ui-form-dropdown-input>
                  </div>
               </div>
            </div>
         </lim-ui-panel>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      dataLogSubmitLabel="manageTasks-storeATime"
      successButtonText="{{ buttonText }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
