import { Injectable, inject } from "@angular/core";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";

@Injectable({ providedIn: "root" })
export class TaskTemplatesPermissionsService {
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);

   public async canDuplicateTemplate(template: TaskTemplateEntity): Promise<boolean> {
      if (!template.schedules) {
         console.error(
            "'schedules' column is not attached to template, cannot calculate duplication permissions",
         );
         return false;
      }
      const hasActiveRecurrences = template.schedules.length > 0;
      const hasReachedScheduleLimit =
         await this.manageFeatureFlags.hasReachedPmsSchedulesLimit();
      return !hasActiveRecurrences || !hasReachedScheduleLimit;
   }

   public async canModifyRecurrences(template: TaskTemplateEntity): Promise<boolean> {
      if (!template.schedules) {
         console.error(
            "'schedules' column is not attached to template, cannot calculate duplication permissions",
         );
         return false;
      }
      const hasActiveRecurrences = template.schedules.length > 0;
      const hasReachedScheduleLimit =
         await this.manageFeatureFlags.hasReachedPmsSchedulesLimit();
      return !hasActiveRecurrences || !hasReachedScheduleLimit;
   }

   public async reachedCustomerScheduleLimit() {
      return this.manageFeatureFlags.hasReachedPmsSchedulesLimit();
   }
}
