<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (message) {
         <lim-ui-info-panel>
            <span class="message" [limbleHtml]="message"></span>
         </lim-ui-info-panel>
      }
      <lim-ui-panel>
         @if (loadingBar) {
            <div class="content-wrapper">
               {{ lang()?.PreparingTasksThisTakesRoughlyASecondPerTask }}
            </div>
         }
         @if (print == true && loadingBar == false) {
            <div class="content-wrapper">
               {{ lang()?.TasksSuccessfullyPrepared }}

               <lim-ui-primary-button
                  print-div
                  target="printBulkContainer"
                  icon="print"
                  >{{ lang()?.Print }}</lim-ui-primary-button
               >
            </div>
         }
         <div id="printBulkContainer">
            <div class="printOverlay"></div>
            @for (task of tasks; track task) {
               <div>
                  @if (task?.checklistID) {
                     <print-task-for-bulk
                        [data]="tasksExtraInfo.get(task.checklistID)"
                        [informationToInclude]="informationToInclude"
                     />
                  }
                  @if (!$last) {
                     <div class="page-break"></div>
                  }
               </div>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
