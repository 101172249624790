import { Injectable, inject } from "@angular/core";
import { AssetFieldScopeType } from "src/app/assets/services/asset-field.types";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { TranslateService } from "src/app/languages/i18n/translate.service";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";

export type InstructionsForCompletion = {
   task?:
      | {
           assetID?: number;
        }
      | undefined;
   assetIDForLink: number;
   fieldIDForLink: number;
   itemTypeID: number;
   itemResponse: string;
   displayText: string | number | Date | null;
};

export type FailureReason =
   | "alreadyCompleted"
   | "too many parts on extra batch"
   | "po not approved for service"
   | "can not complete not instances.";

@Injectable({
   providedIn: "root",
})
export class TaskCompleterService {
   private readonly manageAsset = inject(ManageAsset);
   private readonly translate = inject(TranslateService);

   public getOldInstructionValuesForAssets(
      instructions: InstructionsForCompletion[],
   ): Record<number, AssetFieldValue["valueContent"]> {
      const oldItemValues: Record<number, AssetFieldValue["valueContent"]> = [];
      instructions.forEach((item) => {
         const parentTask = item.task;
         if (parentTask === undefined) return;
         let assetIDForLink;
         if (item.assetIDForLink > 0) {
            assetIDForLink = item.assetIDForLink;
         } else {
            assetIDForLink = parentTask.assetID;
         }
         const valueID = this.manageAsset.getFieldValueFromFieldIDAndAssetID(
            item.fieldIDForLink,
            assetIDForLink,
         )?.valueID;

         if (!valueID) {
            return;
         }

         const oldItemValue = this.manageAsset.getFieldValue(valueID)?.valueContent;

         if (!oldItemValue) {
            return;
         }

         oldItemValues[valueID] = oldItemValue;
      });
      return oldItemValues;
   }

   public parseFailureReason(reason: FailureReason): string {
      switch (reason) {
         case "alreadyCompleted":
            return this.translate.instant("AlreadyCompletedMsg");
         case "too many parts on extra batch":
            return this.translate.instant("UnfortunatelySomePartsAlreadyUsed");
         case "po not approved for service":
            return this.translate.instant("UnfortunatelySomePOsArentApproved");
         case "can not complete not instances.":
            return this.translate.instant("CanNotCompleteNotInstances");
         default:
            return this.translate.instant("NotCompletedMsg");
      }
   }

   public parseFailureReasonFromAnswer(answer: unknown): string {
      if (!answer || typeof answer !== "object" || !("data" in answer)) return "";
      if (
         typeof answer.data !== "object" ||
         answer.data === null ||
         !("reason" in answer.data)
      )
         return "";
      const reason = answer.data.reason;
      if (typeof reason !== "string") return "";
      return this.parseFailureReason(reason as FailureReason);
   }

   public getLinkedDropdownItems(instructions: InstructionsForCompletion[]): {
      valueID: number | undefined;
      displayText: string | number | Date | null | undefined;
   }[] {
      const linkedDropdownItems = instructions
         .filter((item) => {
            return (
               (Number(item.itemTypeID) === TaskInstructionTypeID.DropdownList ||
                  Number(item.itemTypeID) === TaskInstructionTypeID.OptionList) &&
               Number(item.fieldIDForLink) > 0
            );
         })
         .map((linkedItem) => {
            const parentTask = linkedItem.task;
            if (parentTask === undefined) {
               return undefined;
            }
            let assetID;
            if (Number(linkedItem.assetIDForLink) > 0) {
               assetID = linkedItem.assetIDForLink;
            } else {
               assetID = parentTask.assetID;
            }
            const asset = this.manageAsset.getAsset(assetID);
            let field = this.manageAsset.getField(linkedItem.fieldIDForLink);
            if (!field || !asset) {
               return { valueID: undefined, displayText: undefined };
            }
            if (
               field &&
               asset &&
               field.locationID !== asset?.locationID &&
               field.scopeType === AssetFieldScopeType.Local
            ) {
               const matchingField =
                  this.manageAsset.findIdenticalFieldAtDifferentLocation(
                     field?.fieldID,
                     asset?.locationID,
                  );
               if (!matchingField) {
                  return { valueID: undefined, displayText: undefined };
               }
               field = matchingField;
            }
            const valueID = this.manageAsset.getFieldValueFromFieldIDAndAssetID(
               field.fieldID,
               assetID,
            )?.valueID;
            return {
               valueID: valueID,
               displayText: linkedItem.displayText ?? null,
            };
         })
         .filter((item) => item !== undefined);
      return linkedDropdownItems;
   }

   public updateLinkedDropdownItemsAssetInfoFromCompletion(
      instructions: InstructionsForCompletion[],
   ): void {
      const oldItemValues = this.getOldInstructionValuesForAssets(instructions);
      instructions.forEach((item) => {
         const parentTask = item.task;
         if (parentTask === undefined) return;
         let assetIDForLink;
         if (Number(item.assetIDForLink) > 0) {
            assetIDForLink = item.assetIDForLink;
         } else {
            assetIDForLink = parentTask.assetID;
         }
         const valueID = this.manageAsset.getFieldValueFromFieldIDAndAssetID(
            item.fieldIDForLink,
            assetIDForLink,
         )?.valueID;

         if (!valueID) {
            return;
         }

         const oldValue = oldItemValues[valueID];
         const assetID = assetIDForLink;
         const fieldID = item.fieldIDForLink;
         const fieldValue = this.manageAsset.getFieldValue(valueID);
         const asset = this.manageAsset.getAsset(assetID);
         const field = this.manageAsset.getField(fieldID);
         if (!fieldValue || !asset || !field || !item?.itemResponse) {
            return;
         }
         if (
            Number(item.itemTypeID) === TaskInstructionTypeID.DropdownList ||
            Number(item.itemTypeID) === TaskInstructionTypeID.OptionList
         ) {
            this.manageAsset.beginChildAssetUpdates(
               oldValue ?? null,
               item.displayText,
               fieldValue,
               asset,
               field,
            );
         } else {
            this.manageAsset.beginChildAssetUpdates(
               oldValue ?? null,
               item.itemResponse,
               fieldValue,
               asset,
               field,
            );
         }
      });
   }

   public updateValueContentForLinkedDropdownItems(
      instructions: InstructionsForCompletion[],
   ): void {
      const linkedDropdownItems = this.getLinkedDropdownItems(instructions);
      linkedDropdownItems.forEach((item) => {
         if (!item.valueID) return;
         const fieldValue = this.manageAsset.getFieldValue(item.valueID);
         if (!fieldValue) return;
         fieldValue.valueContent = item.displayText ?? null;
      });
   }
}
