import { inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { SeasonType } from "src/app/shared/types/general.types";

@Injectable({ providedIn: "root" })
export class SeasonsService {
   public readonly seasonsChanged$ = new Subject<null>();
   private readonly lang = inject(ManageLang).lang;

   public getSelectedHolidaysStr(seasonRelations: Array<any>): string {
      const lang = this.lang();

      const selectedHolidayNames: any = [];
      let holidays: any = [];

      holidays = this.getSelectedSeasonsByType("holiday", seasonRelations);

      for (const holiday of holidays) {
         selectedHolidayNames.push(holiday.name);
      }

      let selectedHolidaysStr = "";

      if (selectedHolidayNames.length > 0) {
         selectedHolidaysStr = selectedHolidayNames.join(", ");
      } else {
         selectedHolidaysStr = `${lang?.Select} ${lang?.Holidays}`;
      }

      return selectedHolidaysStr;
   }

   public getSelectedSeasonsStr(seasonRelations: Array<any>): string {
      const selectedSeasonNames: any = [];
      const lang = this.lang();
      let seasons: any = [];

      seasons = this.getSelectedSeasonsByType("season", seasonRelations);

      for (const season of seasons) {
         selectedSeasonNames.push(season.name);
      }

      let selectedSeasonsStr = "";

      if (selectedSeasonNames.length > 0) {
         selectedSeasonsStr = selectedSeasonNames.join(", ");
      } else {
         selectedSeasonsStr = `${lang?.Select} ${lang?.Seasons}`;
      }

      return selectedSeasonsStr;
   }

   public getSeasonTypeModalTitle(seasonType: SeasonType): string {
      const lang = this.lang();

      if (seasonType === "holiday") {
         return `${lang?.Choose} ${lang?.Holidays}`;
      }

      return `${lang?.Choose} ${lang?.Seasons}`;
   }

   public getSelectedSeasonsByType(
      seasonType: SeasonType,
      seasonRelations: any[],
   ): any[] {
      return seasonRelations.filter((season) => season.type === seasonType);
   }
}
