import type { AfterViewInit } from "@angular/core";
import { inject, Directive, ElementRef, Input } from "@angular/core";

@Directive({
   selector: "[focusAuto]",
   standalone: true,
})
export class AutoFocusDirective implements AfterViewInit {
   @Input() cursorPlacement: "default" | "start" | "end" = "default";
   private readonly host = inject(ElementRef);

   public ngAfterViewInit() {
      setTimeout(() => {
         this.host.nativeElement.focus();
         if (this.cursorPlacement === "end") {
            const range = document.createRange();
            const selection = window.getSelection();
            range.setStart(
               this.host.nativeElement,
               this.host.nativeElement.childNodes.length,
            );
            range.collapse(true);
            if (selection) {
               selection.removeAllRanges();
               selection.addRange(range);
            }
         }
      }, 0);
   }
}
