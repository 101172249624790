import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   CheckboxComponent,
   IconButtonComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalButtonComponent,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PaginationComponent,
   PanelComponent,
   SecondaryButtonComponent,
   SelectionControlsComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import clone from "rfdc";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { FilterArrayPipe } from "src/app/shared/pipes/filterArray.pipe";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { PickTasksTemplateViewLegacy } from "src/app/tasks/components/pickTasksTemplateViewLegacy/pickTasksTemplateViewLegacy.component";
import { PickTasksTemplateView } from "src/app/tasks/components/pickTasksTemplateViewModal/pickTasksTemplateView.modal.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task, TaskLookup } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";

const deepClone = clone();

@Component({
   selector: "update-related-tasks-legacy",
   styleUrls: ["./updateRelatedTasksLegacy.component.scss"],
   templateUrl: "./updateRelatedTasksLegacy.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      IconButtonComponent,
      PanelComponent,
      CheckboxComponent,
      LimbleHtmlDirective,
      LoadingAnimationComponent,
      SecondaryButtonComponent,
      SelectionControlsComponent,
      IconComponent,
      MinimalIconButtonComponent,
      TooltipDirective,
      PaginationComponent,
      MinimalButtonComponent,
      BasicModalFooterComponent,
      FilterArrayPipe,
      OrderByPipe,
      SliceArray,
   ],
})
export class UpdateRelatedTasksLegacyComponent implements OnInit {
   public loadingBar;
   public showExtraTasks;
   public updateMsg;
   public buttonColor;
   public title;
   public message;
   public template: Task | undefined;
   public locationName: string = "";
   public numOfLocations;
   public syncItems;
   public syncParts;
   public syncSettings;
   public syncName;
   public syncDescription;
   public syncAssignments;
   public syncRecurrances;
   public templates: Array<any> = [];
   public thisLocationTaskCount;
   public otherLocationTasks;
   public otherLocationTaskCount;
   public at;
   public end;
   public data;
   public resolve;
   public modalInstance;
   public page = 1;
   public itemsPerPage;
   public assets: Lookup<"assetID", Asset>;

   private readonly credService = inject(CredService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.assets = this.manageAsset.getAssets();
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.loadingBar = true;
      this.showExtraTasks = false;

      this.updateMsg = false;
      this.buttonColor = "green";
      this.data = this.resolve.data;

      this.itemsPerPage = 50;

      if (this.data.title) {
         this.title = this.data.title;
      }

      if (this.data.message) {
         this.message = this.data.message;
      }

      this.numOfLocations = this.manageLocation.getLocations().length;

      this.syncItems = false;
      this.syncParts = false;
      this.syncSettings = false;
      this.syncName = false;
      this.syncDescription = false;
      this.syncAssignments = false;
      this.syncRecurrances = false;

      this.setupData();
   }

   protected setupData(): void {
      if (this.data.checklistID) {
         this.loadingBar = true;
         this.manageTask
            .getTask(this.data.checklistID)
            .then(async (task) => {
               //promise based
               if (!task?.checklistID) {
                  console.error(
                     "Task not found or does not have valid checklistID in updateRelatedTasksLegacy",
                  );
                  return undefined;
               }
               this.template = task;
               return this.manageTask.getListOfRelatedTaskTemplatesOnBatchID(
                  this.template?.checklistID,
               );
            })
            .then((answer) => {
               if (answer?.data.success == true) {
                  this.templates = [];
                  const returnedTasks: Array<{
                     checklistID: number;
                     assetID: number;
                     locationID: number;
                     checklistBatchID: number;
                     checklistPriorBatchID: number;
                     checklistName: string;
                     update?: boolean;
                  }> = [...answer.data.tasks];

                  for (const task of returnedTasks) {
                     //if the task is at the same location as the source task then mark it by default to update
                     if (this.template?.locationID == task.locationID) {
                        task.update = true;
                     }

                     //IMPORTANT... do not delete the conversation to int here UNLESS we have php returning it as an int
                     task.locationID = Number(task.locationID);

                     //they have to have the ability to edit that task template
                     if (
                        this.template?.checklistID != task.checklistID &&
                        this.credService.isAuthorized(
                           task.locationID,
                           this.credService.Permissions.ChangePMDetails,
                        ) &&
                        this.credService.isAuthorized(
                           task.locationID,
                           this.credService.Permissions.ChangeTheAssetThisPMBelongsTo,
                        ) &&
                        this.credService.isAuthorized(
                           task.locationID,
                           this.credService.Permissions.ChangePMSchedules,
                        ) &&
                        this.credService.isAuthorized(
                           task.locationID,
                           this.credService.Permissions.ChangePMAssignments,
                        ) &&
                        this.credService.isAuthorized(
                           task.locationID,
                           this.credService.Permissions.BulkUpdatePMs,
                        )
                     ) {
                        this.templates.push(task);
                     }
                  }

                  const locationsIndex = this.manageLocation.getLocationsIndex();

                  if (this.template?.locationID) {
                     this.locationName =
                        locationsIndex[this.template.locationID].locationName;
                  }

                  for (const task of this.templates) {
                     const actualTask = this.manageTask.getTaskLocalLookup(
                        task.checklistID,
                     );
                     if (actualTask) {
                        task.checklistName = actualTask.checklistName;

                        if (locationsIndex[actualTask.locationID]) {
                           task.locationName =
                              locationsIndex[actualTask.locationID].locationName;
                        }
                     }
                  }

                  this.thisLocationTaskCount = this.templates.filter(
                     (task) => task.locationID === this.template?.locationID,
                  );
                  this.thisLocationTaskCount = this.thisLocationTaskCount.length;
                  this.otherLocationTasks = this.templates.filter(
                     (task) => task.locationID !== this.template?.locationID,
                  );
                  this.otherLocationTaskCount = this.otherLocationTasks.length;

                  this.loadingBar = false;
                  this.page = this.manageFilters.checkPagination(
                     this.page,
                     this.itemsPerPage,
                     this.templates.length,
                  );
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
      }
   }

   extraTasks = () => {
      this.showExtraTasks = !this.showExtraTasks;

      //reset the tasks that have been marked as update because they closed view other related tasks outside of location
      for (const task of this.templates) {
         if (task) {
            if (task.locationID != this.template?.locationID) {
               task.update = false;
            }
         }
      }
   };

   linkNewPMs = async () => {
      if (this.template === undefined) {
         console.error("");
         return;
      }
      let global;
      if (this.showExtraTasks == true) {
         global = true;
      } else {
         global = false;
      }

      const tasks = this.manageTask.getTasks();
      const finalTasks: any = [];
      let temp: TaskLookup = new Lookup("checklistID");
      const locations = this.manageLocation.getLocations();

      for (const location of locations) {
         if (
            this.credService.isAuthorized(
               location.locationID,
               this.credService.Permissions.BulkUpdatePMs,
            )
         ) {
            let include = false;
            if (global) {
               include = true;
            } else if (location.locationID == this.template?.locationID) {
               include = true;
            }

            if (include) {
               temp = this.manageFilters.getTaskTemplatesAtLocation(
                  tasks,
                  location.locationID,
               );
               temp = temp.filter((task) => task.checklistTemplate === 1);
               temp = this.manageFilters.filterOutTasksWithDeletedAssets(
                  temp,
                  this.manageAsset.getAssets(),
               );
               for (const task of temp) {
                  if (task.checklistID !== this.template?.checklistID) {
                     finalTasks.push(task);
                  }
               }
            }
         }
      }

      const isJitTemplatesDesktopEnabled = await this.launchFlagsService.isEnabled(
         Flags.JIT_TEMPLATES_DESKTOP,
      );

      let pickedTemplateIDs: Array<number>;
      if (isJitTemplatesDesktopEnabled) {
         const locationIDs = this.showExtraTasks
            ? this.manageLocation.getLocations().map((location) => location.locationID)
            : [this.template?.locationID];
         const modalRef = this.modalService.open(PickTasksTemplateView);
         modalRef.componentInstance.message = this.lang().CreateNewPMRelationsMsg;
         modalRef.componentInstance.title = this.lang().CreateNewPMRelationsTitle;
         modalRef.componentInstance.locationIDs = locationIDs;
         modalRef.componentInstance.singleSelection = false;
         pickedTemplateIDs = Array.from((await modalRef.result) ?? new Set<number>());
      } else {
         const instance = this.modalService.open(PickTasksTemplateViewLegacy);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().CreateNewPMRelationsMsg,
               title: this.lang().CreateNewPMRelationsTitle,
               tasks: finalTasks,
               data: { selectOne: false },
            },
         };
         const pickedTemplates = await instance.result;
         if (!pickedTemplates || pickedTemplates.length === 0) {
            return;
         }
         pickedTemplateIDs = pickedTemplates.map((template) => template.checklistID);
      }

      if (pickedTemplateIDs.length === 0) {
         return;
      }
      this.manageTask
         .changePMTemplateRelationsInBulk(
            pickedTemplateIDs,
            this.template?.checklistBatchID,
            this.template?.checklistID,
         )
         .then((answer) => {
            if (answer.data.success == true) {
               this.setupData();
               this.alertService.addAlert(
                  this.lang().successMsgNewRelations,
                  "success",
                  2000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         });
   };

   breakRelation = (task) => {
      this.manageTask.breakRelationPMTemplate(task.checklistID).then((answer) => {
         if (answer.data.success == true) {
            this.setupData();
            this.alertService.addAlert(
               this.lang().successMsgBreakRelations,
               "success",
               2000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         }
      });
   };

   popTask = (task): void => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               preview: true,
               title: this.lang().PreviewPM,
               template: true,
            },
         },
      };
   };

   popAsset = (task) => {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: task.assetID,
            locationID: task.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   submit = () => {
      if (this.loadingBar == true) {
         //this prevents something from repeatedly clicking
         return;
      }

      if (
         this.syncItems == false &&
         this.syncParts == false &&
         this.syncSettings == false &&
         this.syncName == false &&
         this.syncDescription == false &&
         this.syncAssignments == false &&
         this.syncRecurrances == false
      ) {
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOneItemToBulkSync,
            "warning",
            6000,
         );
         return;
      }

      const finalTasks: any = [];
      const tempCopy = deepClone(this.templates);
      for (const task of tempCopy) {
         if (task.update == true) {
            finalTasks.push(task);
         }
      }

      //we need to strip the data that isn't so the total amount isn't sent to the server.  If the url is too long the server times out.
      finalTasks.forEach((value) => {
         delete value.assetName;
         delete value.checklistName;
         delete value.locationName;
         delete value.checklistBatchID;
         delete value.checklistPriorBatchID;
         delete value.update;
      });

      if (finalTasks.length == 0) {
         this.loadingBar = false;
         this.alertService.addAlert(
            this.lang().PleaseSelectAtLeastOnePMToUpdateOtherPMs,
            "warning",
            6000,
         );
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AreYouSureUpdateRelatedPMsMsg,
            title: this.lang().AreYouSureUpdateRelatedPMsTitle,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.loadingBar = true;
            this.updateMsg = true;
            this.at = 0;
            this.end = finalTasks.length;

            for (const task of finalTasks) {
               task.processed = false;
            }

            this.buttonColor = "grey"; //grays out button so they know it is working
            this.processBatch(finalTasks);
         }
      });
   };

   processBatch = (tasksToProcess) => {
      const thisBatch: any = [];
      let count = 1;
      const interval = 1;

      for (const key in tasksToProcess) {
         if (count > interval) {
            break;
         }

         if (tasksToProcess[key].processed == false) {
            tasksToProcess[key].processed = true;
            thisBatch.push(tasksToProcess[key]);
            count++;
         }
      }

      if (thisBatch.length == 0) {
         //we have processed all of them so let's no longer do this
         return true;
      }

      setTimeout(() => {
         //I wanted to give this a little pause in between so that the server's load is spaced out a little bit

         //we only update thisBatch at a time because this can be a resource heavy process
         this.manageTask
            .updateListOfRelatedTaskTemplates(
               this.template?.checklistID,
               thisBatch,
               this.syncItems,
               this.syncParts,
               this.syncSettings,
               this.syncName,
               this.syncDescription,
               this.syncAssignments,
               this.syncRecurrances,
            )
            .then((answer) => {
               if (answer.data.success == true) {
                  for (const task of this.templates) {
                     if (answer.data.newTasks[task.checklistID]) {
                        task.checklistName =
                           answer.data.newTasks[task.checklistID].checklistName;
                        this.at++;
                     }
                  }

                  let doneProcessing = true;
                  for (const task of tasksToProcess) {
                     //we loop through all of the tasks that need to be process.  If there are any that aren't processed we need to start processing the batch again.
                     if (task.processed == false) {
                        doneProcessing = false;
                        this.processBatch(tasksToProcess);
                        break;
                     }
                  }

                  if (doneProcessing) {
                     //alot was changed so we need to fully resync the data.
                     this.manageAsset.getData().then(() => {
                        this.manageTask.getData().then(() => {
                           this.updateMsg = false;
                           this.loadingBar = false;
                           this.alertService.addAlert(
                              this.lang().UpdateRelatedPMsSuccessMsg,
                              "success",
                              2000,
                           );
                        });
                     });
                  }
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
               }
            });
      }, 2500);

      return true;
   };

   deselectAllThisLocation = () => {
      for (const task of this.templates) {
         if (task) {
            if (task.locationID == this.template?.locationID) {
               task.update = false;
            }
         }
      }
   };

   selectAllThisLocation = () => {
      for (const task of this.templates) {
         if (task) {
            if (task.locationID == this.template?.locationID) {
               task.update = true;
            }
         }
      }
   };

   deselectAllOtherLocations = () => {
      for (const task of this.templates) {
         if (task) {
            if (task.locationID != this.template?.locationID) {
               task.update = false;
            }
         }
      }
   };

   selectAllOtherLocations = () => {
      for (const task of this.templates) {
         if (task) {
            if (task.locationID != this.template?.locationID) {
               task.update = true;
            }
         }
      }
   };

   openHelp = () => {
      const url =
         "https://help.limblecmms.com/en/articles/2971790-duplicate-bulk-edit-pms#3-bulk-update-pm-details";
      const win = window.open(url, "_blank");
      if (win !== null) {
         win.focus();
      }
   };

   close = () => {
      this.modalInstance.close();
   };
}
