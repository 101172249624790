import { Temporal } from "@js-temporal/polyfill";
import type { CalendarNoteDto } from "src/app/tasks-analytics/calendar/calendar-notes/calendar-note-dto";

/** Represents a custom calendar event */
export class CalendarNote {
   /**
    * The default color as a hex code (no leading `#`). The default color is a
    * shade of Purple
    */
   public static readonly defaultColor = "663399";
   public readonly id: number;
   public readonly locationID: number;
   public readonly owner: number | null;
   private title: string;
   private startTime: Temporal.Instant;
   private endTime: Temporal.Instant;
   /** A color as a hex code (no leading `#`) */
   private color: string;
   /** Users who can see the note */
   private users: Array<number>;

   public constructor(fields: {
      id: number;
      title: string;
      startTime: Temporal.Instant;
      endTime: Temporal.Instant;
      locationID: number;
      owner: number | null;
      color: string;
      users: Array<number>;
   }) {
      this.id = fields.id;
      this.title = fields.title;
      this.startTime = fields.startTime;
      this.endTime = fields.endTime;
      this.locationID = fields.locationID;
      this.owner = fields.owner;
      this.color = fields.color;
      this.users = fields.users;
   }

   /** Creates a CalendarNote from a CalendarNote DTO */
   public static fromDto(dto: CalendarNoteDto): CalendarNote {
      return new CalendarNote({
         id: dto.calendarNoteID,
         title: dto.noteTitle ?? "",
         startTime: Temporal.Instant.fromEpochSeconds(dto.noteDate),
         endTime: Temporal.Instant.fromEpochSeconds(dto.noteDateEnd),
         locationID: dto.locationID,
         owner: dto.userID,
         color: dto.color ?? CalendarNote.defaultColor,
         users: dto.relations.map((relation) => relation.userID),
      });
   }

   /** Creates an array of CalendarNotes from an array of CalendarNote DTOs */
   public static fromDtos(dtos: Array<CalendarNoteDto>): Array<CalendarNote> {
      return dtos.map((dto) => CalendarNote.fromDto(dto));
   }

   /** Determines if the note should be displayed for a given user */
   public shouldDisplayForUser(userID: number): boolean {
      return (
         this.owner === userID ||
         this.users.length === 0 ||
         this.users.some((candidate) => {
            return candidate === userID;
         })
      );
   }

   public setStartTime(time: Temporal.Instant): void {
      this.startTime = time;
   }

   public getStartTime(): Temporal.Instant {
      return this.startTime;
   }

   public setEndTime(time: Temporal.Instant): void {
      this.endTime = time;
   }

   public getEndTime(): Temporal.Instant {
      return this.endTime;
   }

   public getTitle(): string | null {
      return this.title;
   }

   public setTitle(title: string): void {
      this.title = title;
   }

   public getColor(): string {
      return this.color;
   }

   public setColor(color: string): void {
      this.color = color;
   }

   public getUsers(): Array<number> {
      return this.users;
   }

   public setUsers(users: Array<number>): void {
      this.users = users;
   }

   /** Converts the CalendarNote to a CalendarNote DTO */
   public toDto(): CalendarNoteDto {
      return {
         calendarNoteID: this.id,
         noteTitle: this.title,
         noteDate: this.startTime.epochSeconds,
         noteDateEnd: this.endTime.epochSeconds,
         locationID: this.locationID,
         userID: this.owner,
         color: this.color,
         relations: this.users.map((userID) => ({ userID })),
      };
   }

   /**
    * Moves the note in the context of a Calendar by adjusting the start and end
    * times.
    *
    * @param days - The number of days to move the note. May be positive
    * (to move the note into the future) or negative (to move the note into the
    * past).
    * @param timeZone - The time zone in which the note is being moved. This is
    * crucial for determining the correct time to move the note to because it
    * allows us to account for daylight saving time changes and other time zone
    * differences.
    */
   public move(days: number, timeZone: string): void {
      this.setStartTime(
         this.getStartTime().toZonedDateTimeISO(timeZone).add({ days }).toInstant(),
      );
      this.setEndTime(
         this.getEndTime().toZonedDateTimeISO(timeZone).add({ days }).toInstant(),
      );
   }

   /**
    * Resizes the note in the context of a Calendar by adjusting the end time.
    *
    * @param days - The number of days to extend the note. May be positive
    * (to lengthen the note) or negative (to shorten the note).
    * @param timeZone - The time zone in which the note is being resized. This
    * is crucial for determining the correct resize amount because it allows us
    * to account for daylight saving time changes and other time zone
    * differences.
    */
   public resize(days: number, timeZone: string): void {
      this.setEndTime(
         this.getEndTime().toZonedDateTimeISO(timeZone).add({ days }).toInstant(),
      );
   }
}
