import type { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, EMPTY, expand, from, map, reduce, type Observable } from "rxjs";
import {
   FlannelApiService,
   type ListResponse,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type { TaskExtraTimeEntityFilters } from "src/app/tasks/components/shared/services/extra-time-api/task-extra-time-api.models";
import type { ExtraTime } from "src/app/tasks/types/extra-time/extra-time.types";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class TaskExtraTimeApiService extends FlannelApiService<
   ExtraTime,
   TaskExtraTimeEntityFilters
> {
   private readonly BATCH_SIZE = 25000;
   private readonly PRE_JIT_URL = `${environment.flannelUrl}/tasks/extraTime`;

   private constructor() {
      super("tasks-extraTime"); // just a placeholder, we need to pass in url to override.
   }

   public override getList(
      requestOptions?: Partial<RequestOptions<TaskExtraTimeEntityFilters>>,
   ) {
      return super.getList({
         ...requestOptions,
         url: this.PRE_JIT_URL,
      });
   }

   protected override mapListResponse(
      response: HttpResponse<any>,
   ): ListResponse<ExtraTime> {
      return {
         data: response.body ?? [],
         total: response.body?.length ?? 0,
      };
   }

   public getPaginatedExtraTime(
      requestOptions?: Partial<RequestOptions<TaskExtraTimeEntityFilters>>,
   ): Observable<Array<ExtraTime>> {
      const limit = requestOptions?.pagination?.limit ?? this.BATCH_SIZE;
      const paginatedRequestOptions = {
         ...requestOptions,
         url: this.PRE_JIT_URL,
         pagination: {
            page: 1,
            limit,
         },
      };

      const extraTimeResults$ = from(
         super.getList(paginatedRequestOptions).pipe(
            expand((response) => {
               paginatedRequestOptions.pagination.page += 1;
               return (response.total ?? 0) < limit
                  ? EMPTY
                  : super.getList(paginatedRequestOptions);
            }),
            map((val) => val.data ?? []),
            reduce((acc, cur) => {
               return [...acc, ...cur];
            }),
         ),
      );
      const extraTime$ = combineLatest([extraTimeResults$], (extraTime) => extraTime);
      return extraTime$;
   }
}
