import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Category } from "src/app/parts/types/category/category.types";
import type { Part } from "src/app/parts/types/part.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "change-part-cateogry",
   templateUrl: "./changePartCategory.modal.component.html",
   styleUrls: ["./changePartCategory.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      NgClass,
      IconComponent,
      FormsModule,
      ContenteditableDirective,
      MinimalIconButtonComponent,
      TooltipDirective,
      PrimaryButtonComponent,
      BasicModalFooterComponent,
   ],
})
export class ChangePartCategory implements OnInit {
   public resolve;
   public modalInstance;
   public message;
   public title;
   public part: Part | undefined;
   protected selectedCategory: Category | undefined;
   public categories: Lookup<"categoryID", Category> = new Lookup("categoryID");
   public emptyCategory: Category = {
      categoryID: 0,
      categoryName: "",
   };
   public categoryExtraInfo: Map<number, { edit: boolean }> = new Map();
   public isSuperUser: boolean = false;

   private readonly modalService = inject(ModalService);
   public readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      const currentUser = this.manageUser.getCurrentUser();
      this.isSuperUser = this.manageUtil.checkIfSuperUser(currentUser);

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.selectedCategory = this.resolve.data.selectedCategory;
      this.message = this.resolve.data.message;
      this.title = this.resolve.data.title;
      this.part = this.resolve.data.part;

      this.categories = this.manageParts.getCategories().orderBy("categoryName");

      for (const category of this.categories) {
         this.categoryExtraInfo.set(category.categoryID, { edit: false });
      }

      setTimeout(() => {
         $(".modal").each(function (this: HTMLElement) {
            if ($(this).has(".changePartCategory").length > 0) {
               $(this).scrollTop(0);
            }
         });
      }, 250);
   }

   protected focusCategory(newlySelectedCategory: Category): void {
      if (newlySelectedCategory.categoryID == this.selectedCategory?.categoryID) {
         this.submit();
         return;
      }
      this.selectedCategory = newlySelectedCategory;
      for (const category of this.categories) {
         if (category.categoryID != newlySelectedCategory.categoryID) {
            this.categoryExtraInfo.set(category.categoryID, { edit: false });
         }
      }
   }

   updateCatName = (cat) => {
      if (cat.categoryName.length > 0) {
         assert(this.part);
         this.manageParts.updateCatName(cat, this.part).then((answer) => {
            if (answer.data.success == true) {
               this.categoryExtraInfo.set(cat.categoryID, { edit: false });
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else if (answer.data.name) {
               cat.categoryName = answer.data.name;
               this.alertService.addAlert(
                  this.lang().CategoryNameAlreadyExists,
                  "danger",
                  6000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      } else {
         this.alertService.addAlert(
            this.lang().PleaseEnterCatNameLongerThen1Char,
            "danger",
            6000,
         );
      }
   };

   addCat = () => {
      assert(this.part);
      this.manageParts.addCategory(this.part).then((answer) => {
         if (answer?.data.success == true) {
            this.categories = this.manageParts.getCategories();
            this.alertService.addAlert(
               this.lang().ANewCategorySuccessfullyAdded,
               "success",
               1000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   deleteCat = (cat) => {
      if (!this.isSuperUser) {
         return;
      }
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteCategoryMsg,
            title: this.lang().DeleteCategory,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            assert(this.part);
            this.manageParts
               .deleteCat(cat.categoryID, this.part.locationID)
               .then((answer) => {
                  if (answer?.data.success == true) {
                     this.categories = this.manageParts.getCategories();

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   public toggleEdit(categoryID: number): void {
      const currentEditVal = this.categoryExtraInfo.get(categoryID)?.edit;
      this.categoryExtraInfo.set(categoryID, { edit: !currentEditVal });
   }

   close = () => {
      this.modalInstance.close(0);
   };

   submit = () => {
      this.modalInstance.close(this.selectedCategory);
   };

   protected clickOnContentEditable(cat): void {
      if (this.categoryExtraInfo.get(cat.categoryID)?.edit) {
         return;
      }
      this.focusCategory(cat);
   }
}
