@if (part) {
   <lim-ui-panel
      [showWatermark]="false"
      [noSidePadding]="true"
      [useCustomTitle]="true"
      [showHeaderDivider]="true"
      id="partAssetsPrint{{ part.partID }}"
   >
      <div class="part-assets-panel-header" panelCustomTitle>
         <span>
            <lim-ui-icon icon="cubes" />
            {{ lang().AssociatedAssets }}
         </span>

         <lim-ui-primary-button
            (click)="associateAssetToPart()"
            [hidden]="!credAssetAssocation"
         >
            {{ lang().AssociateAssets }}
         </lim-ui-primary-button>
      </div>

      @if (loadingBar) {
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      }
      @if (!loadingBar) {
         <div class="asset-list">
            @for (asset of assetRelations; track asset) {
               <div class="lim-ui-list-item">
                  <div class="lim-ui-list-item-content">
                     @if (asset.locationName) {
                        <span class="asset-information">
                           <span class="asset-breadcrumbs">
                              <lim-ui-icon
                                 class="lim-ui-hide-on-mobile asset-row-icon"
                                 icon="cube"
                                 placement="right"
                                 limUiTooltip="View the asset this task is for."
                              />

                              <span
                                 class="asset-name"
                                 (click)="viewAsset(asset.assetID)"
                                 [limbleHtml]="asset?.assetName"
                              ></span>

                              <asset-parent-list
                                 [assetID]="asset.assetID"
                                 [preventParentAccess]="false"
                                 linkColorClass="green-color"
                                 [restrict]="restrict"
                              />
                              -
                              <lim-ui-icon icon="houseChimney" />
                              <span [limbleHtml]="asset.locationName"></span>
                              <span> {{ lang().uses }} {{ asset.assetPartQty }} </span>
                           </span>
                        </span>
                     }

                     <div class="relation-icons">
                        @if (asset.automaticPartRelation) {
                           <lim-ui-icon
                              placement="left"
                              limUiTooltip="{{ lang().AssetAutomaticAssociationTooltip }}"
                              icon="wandMagicSparkles"
                           />
                        }
                        <lim-ui-minimal-icon-button
                           (click)="deletePartRelation(asset)"
                           placement="left"
                           limUiTooltip="{{ lang().RemoveAssociation }}"
                           icon="trashCanRegular"
                           iconColor="danger"
                           [superMinimal]="true"
                        />
                     </div>
                  </div>
               </div>
            }

            <div [hidden]="!(assetRelations?.length == 0)">
               <span [limbleHtml]="lang().NoAssetsAreAssociatedWithThisPart"></span>
            </div>
         </div>
      }
   </lim-ui-panel>
}
