import type { OnInit } from "@angular/core";
import { inject, Component, forwardRef, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   CheckboxComponent,
   DangerButtonComponent,
   DropdownInlineTextComponent,
   DropdownItemComponent,
   FormDropdownInputComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { CustomTagList } from "src/app/tasks/components/customTagListModal/customTagList.element.component";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageTags } from "src/app/tasks/services/manageTags";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "edit-custom-tag",
   templateUrl: "./editCustomTag.modal.component.html",
   styleUrls: ["./editCustomTag.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      FormsModule,
      CheckboxComponent,
      IconComponent,
      TooltipDirective,
      FormDropdownInputComponent,
      DropdownItemComponent,
      DropdownInlineTextComponent,
      forwardRef(() => CustomTagList),
      ModalFooterComponent,
      DangerButtonComponent,
   ],
})
export class EditCustomTag implements OnInit {
   public resolve;
   public modalInstance;
   public data;
   public tag;
   public tagNameOld;
   public gained;
   public lost;
   public completed;
   public customTagListObjForRemoveTagsGained;
   public priorityList;
   public superUser;
   public customerID;
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageTask = inject(ManageTask);
   private readonly paramsService = inject(ParamsService);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageTags = inject(ManageTags);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.tag = this.data.tag;
      this.tagNameOld = `${this.tag.name}`;
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.priorityList = this.managePriority.getPriorityList();

      //get event settings.
      this.getCustomTagEventSettings(this.tag).then((answer) => {
         if (answer.data.success == true) {
            this.gained = answer.data.gained;
            this.lost = answer.data.lost;
            this.completed = answer.data.completed;

            this.setLevelStr();

            //set the customtag dropdown lists
            this.customTagListObjForRemoveTagsGained = {};
            this.setCustomTagListObjRemoveTagsGained(
               this.customTagListObjForRemoveTagsGained,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   close = () => {
      this.modalInstance.close(false);
   };

   setLevelStr = () => {
      const priorityListIndex = this.managePriority.getPriorityListIndex();
      if (this.gained.priority.active) {
         const currentPriorityGained = priorityListIndex[this.gained.priority.level];
         if (currentPriorityGained) {
            this.gained.priority.levelStr = `${currentPriorityGained.priorityLevel} - ${currentPriorityGained.name}`;
         }
      }

      if (this.lost.priority.active) {
         const currentPriorityLost = priorityListIndex[this.lost.priority.level];
         if (currentPriorityLost) {
            this.lost.priority.levelStr = `${currentPriorityLost.priorityLevel} - ${currentPriorityLost.name}`;
         }
      }
   };

   setCustomTagListObjRemoveTagsGained = (obj) => {
      const clickTag = (tag) => {
         if (this.gained.removeTags.tags.includes(`${tag.name};`)) {
            this.gained.removeTags.tags = this.gained.removeTags.tags.replace(
               `${tag.name};`,
               "",
            );
         } else {
            this.gained.removeTags.tags += `${tag.name};`;
         }
         this.updateCustomTagEvents("gained", "removeTags", this.gained.removeTags);
      };
      obj.selectOne = false;
      obj.clickTag = clickTag;
      obj.advancedSettings = false;
      obj.sources = [this.gained.removeTags.tags];
   };

   updateCustomTagEvents = (trigger, action, obj) => {
      const customTag = `${this.tag.name};`;
      let field1;
      if (obj.email !== undefined) {
         field1 = obj.email;
      } else if (obj.level !== undefined) {
         field1 = obj.level;
      } else if (obj.tags !== undefined) {
         field1 = obj.tags;
      }
      const active = obj.active;

      this.manageTask
         .updateCustomTagEvent(customTag, trigger, action, field1, active)
         .then((answer) => {
            if (answer.data.success == true) {
               this.gained = answer.data.gained;
               this.lost = answer.data.lost;
               this.completed = answer.data.completed;
               this.setLevelStr();

               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   enableDisableEvent = (trigger, action, obj) => {
      obj.active = !obj.active;
      this.updateCustomTagEvents(trigger, action, obj);
   };

   updateCustomTagEvent = (trigger, action, field1, active) => {
      const customTag = `${this.tag.name};`;

      this.manageTask
         .updateCustomTagEvent(customTag, trigger, action, field1, active)
         .then((answer) => {
            if (answer.data.success == true) {
               this.gained = answer.data.gained;
               this.lost = answer.data.lost;
               this.completed = answer.data.completed;
               this.setLevelStr();

               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   renameTag = (tag) => {
      tag.name = tag.name.trim();
      if (tag.name == this.tagNameOld) {
         return; //no change detected
      }

      let result = `${tag.name}`;
      result = result.replace(/&nbsp;/, ""); //Remove white space from the tag to ensure future regex catches the tag

      let reg = new RegExp("@", "g");
      result = result.replace(reg, ""); //remove @ as a safety precaution because tags should only have that at the beginning
      reg = new RegExp('"', "g");
      result = result.replace(reg, ""); //we remove all " because it causes problems.
      reg = new RegExp(";", "g");
      result = result.replace(reg, ""); //we remove all ; because it causes problems.
      reg = new RegExp("[\\[\\]~`']", "g");
      result = result.replace(reg, ""); //we remove all [] because it causes problems.

      result = `@${result};`; //Always add on the @
      const oldName = `${this.tagNameOld};`;
      this.manageTask.renameCustomTag(result, oldName).then((answer) => {
         if (answer.data.success == true) {
            this.manageUser.getCurrentUser().tags = answer.data.tags;
            this.manageUser.getCurrentUser().tags = this.manageUser
               .getCurrentUser()
               .tags.replace(/\\'/g, "'");
            this.manageUser.getCurrentUser().tags = this.manageUser
               .getCurrentUser()
               .tags.replace(/;/g, "");
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);

            tag.name = result.slice(0, -1);
            this.tagNameOld = tag.name;
            for (let source of this.data.sources) {
               source = source.replace(oldName, `${tag.name};`);
            }

            if (this.data.renameTag) {
               this.data.renameTag(oldName, tag);
               this.manageTags.tags$ = this.data.tags;
            }
         } else {
            tag.name = this.tagNameOld;

            if (answer.data.reason === "already exists") {
               this.alertService.addAlert(
                  this.lang().TheNewNameYouTypedInAlreadyExists,
                  "danger",
                  6000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         }
      });
   };

   deleteTag = (tag) => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteCustomTagMsg,
            title: `${this.lang().DeleteCustomTagTitle}: <b>${tag.name}</b>`,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            const instance2 = this.modalService.open(Confirm);

            this.paramsService.params = {
               modalInstance: instance2,
               resolve: {
                  message: this.lang().DeleteCustomTagMsg2,
                  title: `${this.lang().DeleteCustomTagTitle}: <b>${tag.name}</b>`,
               },
            };
            instance2.result.then((confirmResult) => {
               if (confirmResult == 1) {
                  this.manageTask.deleteCustomTag(tag.name).then((answer) => {
                     if (answer.data.success == true) {
                        let reg = new RegExp(tag.name, "g"); //regex to find old tag

                        this.manageUser.getCurrentUser().tags = this.manageUser
                           .getCurrentUser()
                           .tags.replace(reg, "");

                        reg = new RegExp(
                           `${tag.name};`.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
                           "g",
                        );
                        //also have to clean up any sources
                        for (let source of this.data.sources) {
                           source = source.replace(reg, "");
                        }

                        for (let index = this.data.tags.length - 1; index >= 0; index--) {
                           if (this.data.tags[index].name == tag.name) {
                              this.data.tags.splice(index, 1);
                           }
                        }

                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                        const returnObj = {
                           deleted: true,
                           tag: tag,
                        };
                        this.modalInstance.close(returnObj);
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     }
                  });
               }
            });
         }
      });
   };

   private async getCustomTagEventSettings(tag): Promise<AxiosResponse> {
      const name = `${tag.name};`;
      const post = await axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "getCustomTagEventSettings",
         },
         data: {
            name: name,
         },
      });

      return post;
   }
}
