import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";

export type PartCostEfficiencyRateCellViewModel = {
   partCostEfficiencyRate?: number;
};

@Component({
   selector: "part-cost-efficiency-rate-cell",
   imports: [CommonModule],
   templateUrl: "./part-cost-efficiency-rate-cell.component.html",
})
export class PartCostEfficiencyRateCellComponent {
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input({ required: true }) public task!: PartCostEfficiencyRateCellViewModel;
   @Input() public column?: Column;
}
