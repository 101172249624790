<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         @if (sharedUsers?.length > 0 || sharedVendors.length > 0) {
            <div class="config-container">
               <label class="control-label">
                  {{ lang().ThisTaskIsCurrentlySharedWithPeople }}
               </label>
               <div class="break"></div>
               <div class="shared-vendor-user">
                  @for (vendor of sharedVendors; track vendor) {
                     <div class="lim-ui-list-item">
                        <lim-ui-icon icon="addressCard" iconSize="small" />
                        <span
                           (click)="popVendor(vendor)"
                           class="clickable-vendor-name"
                           style="cursor: pointer"
                           [limbleHtml]="vendor.vendorName"
                        ></span>
                        -
                        <span [limbleHtml]="vendor.vendorEmail"></span>
                        <lim-ui-icon
                           icon="trashCanRegular"
                           iconSize="small"
                           limUiTooltip="{{ lang().DisableShareTooltip }}"
                           placement="bottom"
                           (click)="disableChecklistShareForVendor(vendor.vendorID)"
                           class="action-icon"
                        />
                     </div>
                  }
                  @for (user of sharedUsers; track user) {
                     <div class="lim-ui-list-item">
                        <lim-ui-icon icon="user" iconSize="small" />

                        <span [limbleHtml]="user.shareTargetEmail"></span>

                        <lim-ui-icon
                           icon="trashCanRegular"
                           iconSize="small"
                           limUiTooltip="{{ lang().DisableShareTooltip }}"
                           placement="bottom"
                           (click)="
                              disableChecklistShare(user.shareID, user.shareTargetEmail)
                           "
                           class="action-icon"
                        />
                     </div>
                  }
               </div>
               <div class="break"></div>
               <hr />
            </div>
         }

         <div class="config-container">
            <label class="control-label width-80">
               {{ message }}
            </label>
            <lim-ui-primary-button
               (click)="setTaskEmailDefaults()"
               limUiTooltip="{{ lang().SetTaskEmailDefaultsHint }}"
               >{{ lang().Default }}</lim-ui-primary-button
            >
         </div>
      </lim-ui-panel>
      <lim-ui-panel>
         <div class="config-container">
            <div class="form-group">
               <label class="control-label">{{ lang().To }}</label>
               <div class="div-to-wrapper">
                  <div
                     [(ngModel)]="recipient"
                     limbleContentEditable
                     disableEditor="true"
                     update-on-keyup
                     stripHtml="false"
                     (keypress)="detectEmail($event)"
                     class="limble-content-editable-class width-80"
                  ></div>
                  <lim-ui-primary-button icon="addressCard" (click)="pickVendors()">{{
                     lang().PickVendors
                  }}</lim-ui-primary-button>
               </div>
            </div>
            <div class="form-group">
               <div class="shared-vendor-user">
                  @for (vendor of selectedVendors; track vendor) {
                     <div class="lim-ui-list-item">
                        <lim-ui-icon icon="addressCard" iconSize="small" />
                        <span
                           (click)="popVendor(vendor)"
                           class="clickable-vendor-name"
                           [limbleHtml]="vendor.vendorName"
                        ></span>
                        -
                        <span [limbleHtml]="vendor.vendorEmail"></span>
                        <lim-ui-icon
                           icon="trashCanRegular"
                           iconSize="small"
                           limUiTooltip="{{ lang().removeEmailFromListToolTip }}"
                           placement="bottom"
                           (click)="removeVendorFromSelectedVendors(vendor.vendorID)"
                           class="action-icon"
                        />
                     </div>
                  }
                  @for (user of selectedUsers; track user) {
                     <div class="lim-ui-list-item">
                        <lim-ui-icon icon="user" iconSize="small" />

                        <span [limbleHtml]="user"></span>

                        <lim-ui-icon
                           icon="trashCanRegular"
                           iconSize="small"
                           limUiTooltip="{{ lang().removeEmailFromListToolTip }}"
                           placement="bottom"
                           (click)="removeEmailFromList(user)"
                           class="action-icon"
                        />
                     </div>
                  }
               </div>
            </div>
            <div class="form-group">
               <label class="control-label">{{ lang().Subject }}</label>
               <div
                  [(ngModel)]="emailSubject"
                  limbleContentEditable
                  disableEditor="true"
                  stripHtml="true"
                  class="limble-content-editable-class"
               ></div>
            </div>
            <div class="form-group">
               <label class="control-label">{{ lang().Message }}</label>
               <div
                  [(ngModel)]="emailMessage"
                  limbleContentEditable
                  stripHtml="true"
                  class="limble-content-editable-class"
               ></div>
            </div>
         </div>
      </lim-ui-panel>

      @if (spinner) {
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      }

      @if (errorMsg) {
         <lim-ui-alert [title]="lang().Warning" alertType="danger">
            <div [limbleHtml]="errorMsg"></div>
         </lim-ui-alert>
      }
      @if (feedback) {
         <lim-ui-alert [title]="lang().Success" alertType="success">
            <div [limbleHtml]="feedback"></div>
         </lim-ui-alert>
      }
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().ShareTask }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
