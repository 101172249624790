<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span
            class="message-area"
            [limbleHtml]="lang().DocAndImgFieldsHaveBeenRemoved"
         ></span>
      </lim-ui-info-panel>
      <lim-ui-panel>
         <div class="modal-body">
            <div class="dd" id="nestable_list_1">
               @for (field of fields; track field) {
                  @if (field.fieldID; as fieldID) {
                     <div
                        (click)="focusField(fieldID)"
                        class="lim-ui-list-item lim-ui-selectable-list-item"
                        [ngClass]="{
                           'lim-ui-selected-list-item': selectedField == fieldID,
                        }"
                     >
                        @if (selectedField == fieldID) {
                           <lim-ui-icon icon="check" iconColor="success" />
                        }
                        {{ field.fieldName }}
                     </div>
                  }
               }
            </div>
         </div>

         <div class="modal-footer">
            @if (breakLink) {
               <lim-ui-secondary-button (click)="breakLinkFunc()" style="float: left">{{
                  lang().BreakLink
               }}</lim-ui-secondary-button>
            }
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Select"
      (successClick)="submit()"
   />
</lim-ui-modal>
