import type { OnDestroy, OnInit } from "@angular/core";
import {
   Component,
   computed,
   inject,
   input,
   Input,
   signal,
   forwardRef,
} from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   getExtraColumns,
   getParams,
} from "@empowered/base/table/utils/column-table-parser";
import type { Aliases, Colors } from "@limblecmms/lim-ui";
import {
   AlertComponent,
   IconButtonComponent,
   LimbleHtmlDirective,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import {
   BehaviorSubject,
   catchError,
   combineLatest,
   finalize,
   type Observable,
   of,
   type Subscription,
   switchMap,
   tap,
} from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { PartLogDataViewerViewModel } from "src/app/parts/components/part-logs-data-viewer/part-log-date-viewer.model";
import { PartLogsDataViewerComponent } from "src/app/parts/components/part-logs-data-viewer/part-logs-data-viewer.component";
import { PartTasksDataService } from "src/app/parts/components/shared/part-tasks-data-service.service.ts/part-tasks-data-sevice.service";
import {
   type PartLogEntity,
   PartLogSourceID,
} from "src/app/parts/components/shared/services/logs-api/part-log-api.models";
import { PartLogsApiService } from "src/app/parts/components/shared/services/logs-api/part-log-api.service";
import { PartColumnDefinitionFactoryService } from "src/app/parts/components/shared/services/part-column-definitions-factory/part-column-definitions-factory.service";
import { PartLogExporterService } from "src/app/parts/components/shared/services/part-log-exporter-service/part-log-exporter-service";
import { ManageParts } from "src/app/parts/services/manageParts";
import { PartLogViewModelFactoryService } from "src/app/parts/services/part-log-view-model-factory/part-log-model-factory.service";
import type { Part } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import {
   type DataViewerFilter,
   DataViewerFiltersComponent,
   DataViewerFilterType,
   DataViewerStateService,
   FilterLabelKey,
} from "src/app/shared/data-viewer";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { DataViewerSearchComponent } from "src/app/shared/data-viewer/data-viewer-search/data-viewer-search.component";
import { AlertService } from "src/app/shared/services/alert.service";
import type { ListResponse } from "src/app/shared/services/flannel-api-service";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CredService } from "src/app/users/services/creds/cred.service";

@Component({
   selector: "part-log-tab",
   templateUrl: "./part-log-tab.component.html",
   styleUrls: ["./part-log-tab.component.scss"],
   providers: [DataViewerStateService, PartTasksDataService],
   imports: [
      PanelComponent,
      IconButtonComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      FormsModule,
      PrimaryButtonComponent,
      AlertComponent,
      forwardRef(() => DataViewerFiltersComponent),
      PartLogsDataViewerComponent,
      DataViewerSearchComponent,
   ],
})
export class PartLogTabComponent implements OnInit, OnDestroy {
   @Input() public restrict;
   @Input() public part: Part | undefined;
   public readonly locationID = input<number | undefined>(undefined);

   public logs: PartLogDataViewerViewModel[] = [];
   // This button is shown in the tab section of the parts entity modal normally, but may need to be shown in this component itself in some cases
   @Input() public showDownloadButton: boolean = false;
   public isAllPartsLog = signal<boolean>(false);
   protected loadingBar: boolean = false;
   protected errorMsg;
   protected textareaEntry;
   protected successMsg;

   protected arrowDown: Aliases = "arrowDown";
   protected arrowUp: Aliases = "arrowUp";
   protected colorRed: Colors = "danger";
   protected colorGreen: Colors = "success";

   private isUnitOfMeasureStockUnitChangesEnabled: boolean = false;

   private readonly manageLang = inject(ManageLang);
   private readonly manageParts = inject(ManageParts);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly partLogExporterService = inject(PartLogExporterService);
   readonly #state = inject(DataViewerStateService);

   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   protected launchFlagsService = inject(LaunchFlagsService);

   protected isUnitOfMeasureVisible = this.unitOfMeasureService.isFeatureEnabled;
   private readonly launchFlagSub: Subscription;

   protected columns: Array<Column> = [];

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly dataViewerState = inject(DataViewerStateService);
   private readonly partLogsApi = inject(PartLogsApiService);
   private readonly partLogViewModelFactoryService = inject(
      PartLogViewModelFactoryService,
   );
   private readonly columnDefinitionsFactory = inject(PartColumnDefinitionFactoryService);

   protected filters: Array<DataViewerFilter> = [
      {
         type: DataViewerFilterType.DATE,
         labelKey: FilterLabelKey.DATE,
         key: "",
      },
      {
         type: DataViewerFilterType.PART_LOG_TYPE,
         labelKey: FilterLabelKey.TASK_TYPE,
         key: "sourceIDs",
      },
   ];

   private readonly addedPartLogEntry = new BehaviorSubject(0);

   private readonly partLogsRequest$: Observable<Partial<ListResponse<PartLogEntity>>> =
      combineLatest([
         this.dataViewerState.requestOptions$,
         this.addedPartLogEntry.asObservable(),
      ]).pipe(
         tap(() => (this.loadingBar = true)),
         switchMap(([requestOptions]) =>
            this.partLogsApi
               .getList(requestOptions)
               .pipe(finalize(() => (this.loadingBar = false))),
         ),
         catchError((error) => {
            console.error("Error fetching part logs:", error);
            return of();
         }),
      );

   protected readonly partLogsResponse = toSignal<Partial<ListResponse<PartLogEntity>>>(
      this.partLogsRequest$,
   );
   protected readonly partLogs = computed(() => {
      let logs =
         this.partLogsResponse()?.data?.map((logEntity) =>
            this.partLogViewModelFactoryService.getPartLogDataViewerViewModel(logEntity),
         ) ?? [];

      if (
         !this.isUnitOfMeasureVisible() ||
         !this.isUnitOfMeasureStockUnitChangesEnabled
      ) {
         logs = logs.filter(
            (log) => log.sourceID !== PartLogSourceID.UpdatedPartUnitOfMeasure,
         );
      }

      return logs;
   });

   protected readonly partLogsTotal = computed(() => this.partLogsResponse()?.total ?? 0);

   public constructor() {
      this.launchFlagSub = toObservable(
         this.launchFlagsService.getFlag(
            "release-part-stock-unit-changes-part-logs",
            false,
         ),
      ).subscribe((flag) => {
         this.isUnitOfMeasureStockUnitChangesEnabled = flag;
      });
   }
   public ngOnInit() {
      this.initializeDataViewer();
      assert(this.part);
   }

   public ngOnDestroy(): void {
      this.launchFlagSub.unsubscribe();
   }

   private initializeDataViewer(): void {
      const columns = this.columnDefinitionsFactory.getPartLogColumns();
      this.columns = columns;

      const requestParams = getParams(columns);
      this.dataViewerState.setParams(requestParams);

      const extraColumns = getExtraColumns(columns);
      this.dataViewerState.setExtraColumns(extraColumns);

      const locationID = this.locationID();
      // We only care to filter by location ID if it is not a specific part.
      if (this.part && this.part.partID !== 0) {
         this.dataViewerState.setBaseFilters([{ partIDs: [this.part.partID] }]);
      } else if (locationID !== undefined) {
         this.dataViewerState.setBaseFilters([{ locationIDs: [locationID] }]);
      }

      this.dataViewerState.setSort("-logDate");

      if (this.part?.partID === 0) {
         this.isAllPartsLog.set(true);
      }
   }

   public addEntry() {
      assert(this.part);
      if (
         !this.credService.isAuthorized(
            this.part.locationID,
            this.credService.Permissions.ManagePartsLog,
         )
      ) {
         this.alertService.addAlert(this.lang().cred77Fail, "danger", 10000);
         return;
      }

      if (this.part.partID === 0) {
         return;
      }

      this.errorMsg = false;
      let valid = true;

      if (this.textareaEntry === undefined || this.textareaEntry === "") {
         this.errorMsg = this.lang().PleaseEnterAMessage;
         valid = false;
      }

      if (valid) {
         this.manageParts.addEntry(this.textareaEntry, this.part).then((answer) => {
            if (answer.data.success === true) {
               this.addedPartLogEntry.next(this.addedPartLogEntry.value + 1);
               this.textareaEntry = "";
               this.successMsg = this.lang().YouHaveSuccessfullyAddedAnEntry;
               setTimeout(() => {
                  this.successMsg = "";
               }, 5000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      }
   }

   public async downloadPartHistory() {
      const filters = this.#state.requestOptionsWithoutPagination();
      await this.partLogExporterService.downloadExcelPartLogs(
         filters,
         this.part?.partID ?? 0,
      );
   }

   protected async onSetFilter(dataViewerFilter: DataViewerFilter) {
      await this.#state.addFilter(dataViewerFilter);
   }

   protected async onRemoveFilter(dataViewerFilter: DataViewerFilter) {
      await this.#state.removeFilter(dataViewerFilter);
   }

   protected onSearchChange(search: string) {
      this.#state.setSearch(search);
   }
}
