<ai-modal (close)="close()">
   @let hardLimitExceeded = documentCount() > hardLengthLimit;
   @let softLimitExceeded = documentCount() > softLengthLimit;
   @let noDocuments = documentCount() === 0;
   <div modalTitle>Select Documents to Search</div>
   <lim-ui-scroll-container>
      <div class="modal-body">
         @if (hardLimitExceeded) {
            <lim-ui-alert
               alertType="warning"
               title="Too many documents: {{ documentCount() }}"
               displayMode="banner"
            >
               <div class="alert-body">
                  <div>
                     There are too many documents to display. The maximum number of
                     documents that can be displayed is:
                     {{ hardLengthLimit }}.
                  </div>
                  <div>
                     Would you like to go back and select a smaller group of assets?
                  </div>
               </div>
            </lim-ui-alert>
         } @else if (softLimitExceeded && softLimitBypassed() !== true) {
            <lim-ui-alert
               alertType="warning"
               title="Large number of documents found: {{ documentCount() }}"
               displayMode="banner"
            >
               <div class="alert-body">
                  <div>
                     Based on your selections, a large number of documents were found. If
                     all are selected, suggestions may take several hours to generate.
                  </div>
                  <div>
                     You can start the process now and return later to view the results.
                     Or you can select a subset of documents to search.
                  </div>
                  <div>Would you like to proceed?</div>
               </div>
            </lim-ui-alert>
         } @else if (noDocuments) {
            <lim-ui-alert
               alertType="warning"
               title="No documents found"
               displayMode="banner"
            >
               <div class="alert-body">
                  <div>
                     Based on your selections, no documents were found for PM tasks and
                     schedules.
                  </div>
                  <div>
                     Would you like to go back and select different assets to search?
                  </div>
               </div>
            </lim-ui-alert>
         } @else {
            <lim-ui-panel>
               @defer (when documentsByAssetID() !== undefined; prefetch on immediate) {
                  @let documentsByAssetIDLocal = documentsByAssetID();
                  @if (documentsByAssetIDLocal !== undefined) {
                     <select-documents-list
                        [documentsByAssetID]="documentsByAssetIDLocal"
                        [(selection)]="selectedDocuments"
                     />
                  }
               } @placeholder {
                  <div class="placeholder">
                     <ngx-skeleton-loader [theme]="listOptionsSkeletonTheme" />
                     <ngx-skeleton-loader [theme]="listItemSkeletonTheme" />
                     <ngx-skeleton-loader [theme]="listItemSkeletonTheme" />
                     <ngx-skeleton-loader [theme]="listItemSkeletonTheme" />
                  </div>
               }
            </lim-ui-panel>
         }
      </div>
   </lim-ui-scroll-container>
   <div modalFooter class="modal-footer">
      @if (noDocuments || hardLimitExceeded) {
         <lim-ui-secondary-button (click)="close()"> Close </lim-ui-secondary-button>
         <lim-ui-primary-button (click)="back()">
            Back to Asset Selection
         </lim-ui-primary-button>
      } @else if (softLimitExceeded && softLimitBypassed() !== true) {
         <lim-ui-secondary-button (click)="back()">
            Back to Asset Selection
         </lim-ui-secondary-button>
         <lim-ui-primary-button (click)="bypassSoftLimit()">
            Proceed to Document Selection
         </lim-ui-primary-button>
      } @else {
         <lim-ui-secondary-button (click)="back()">
            Back to Asset Selection
         </lim-ui-secondary-button>
         <lim-ui-primary-button
            (click)="submit()"
            [disabled]="selectedDocuments().size === 0"
         >
            <div class="submit-button">
               <lim-ui-icon icon="sparkles" />
               Search Documents
            </div>
         </lim-ui-primary-button>
      }
   </div>
</ai-modal>
