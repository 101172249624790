import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   PrimaryButtonComponent,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ParamsService } from "src/app/shared/services/params.service";
import type { BulkPrintTasksSettings } from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { PrintTaskForBulk } from "src/app/tasks/components/printTaskForBulkElement/printTaskForBulk.element.component";
import { PrintDivDirective } from "src/app/tasks/directives/printDiv/printDiv.directive";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { ManageLogin } from "src/app/users/services/manageLogin";

@Component({
   selector: "pop-bulk-tasks",
   templateUrl: "./popBulkTasks.modal.component.html",
   styleUrls: ["./popBulkTasks.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      PrimaryButtonComponent,
      PrintDivDirective,
      PrintTaskForBulk,
   ],
})
export class PopBulkTasks implements OnInit {
   public title;
   public message;
   public print;
   public data;
   public tasks: TaskLookup = new Lookup("checklistID");
   public tasksExtraInfo: LimbleMap<
      number,
      {
         checklistID: number;
         mode: string;
         expand: boolean;
         guided: boolean;
         editable: boolean;
      }
   > = new LimbleMap();
   public resolve;
   public modalInstance;
   public resolveData;
   public loadingBar;
   public informationToInclude: BulkPrintTasksSettings | undefined;

   private readonly manageLogin = inject(ManageLogin);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageTask = inject(ManageTask);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.loadingBar = true;
      this.resolveData = this.resolve?.data;

      if (this.resolveData?.title) {
         this.title = this.resolveData?.title;
      }
      if (this.resolveData?.message) {
         this.message = this.resolveData?.message;
      }
      this.print = this.resolveData?.print;
      const expand = this.resolveData?.print; //if they are printing we will want printing to be expanded

      //if they are not logged in then we need to close the model because they are redirected to the login page.
      this.manageLogin.checkLogin().then((answer: any) => {
         if (answer.data.success == false) {
            this.modalInstance.close();
         }
      });

      this.data = {
         mode: "instance",
         editable: false,
         expand: expand,
         guided: true,
      };

      this.resolveData.taskIDs.forEach((taskID) => {
         const task = this.manageTask.getTaskLocalLookup(taskID);
         if (!task) return;
         this.tasks.set(taskID, task);
         this.tasksExtraInfo.set(task.checklistID, {
            checklistID: task.checklistID,
            mode: "instance",
            editable: this.manageLogin.credCheckEditChecklist(task.checklistID),
            expand: expand,
            guided: false,
         });
      });

      this.informationToInclude = this.resolveData.informationToInclude;

      //add a XXX ms delay so that the modal can load properly before the task loads.  This makes the tool look much smoother
      setTimeout(() => {
         let index = 0;
         const delayTask = setInterval(() => {
            if (index >= (this.resolveData?.taskIDs.length ?? 0)) {
               clearInterval(delayTask);
               this.loadingBar = false;
               this.loadingBarService.remove();
            } else {
               this.loadingBarService.show({
                  header: `<br />${
                     this.lang().PreparingTasksThisTakesRoughlyASecondPerTask
                  } <br /><br /> ${index + 1} ${this.lang().ofStr} ${
                     this.resolveData?.taskIDs.length
                  }`,
               });
            }
            index++;
         }, 1000);
      }, 200);
   }

   close = () => {
      this.modalInstance.close();
   };
}
