export const CurrencyCodeToRepresentativeLocale: Record<string, string> = {
   // Western format (1,234.56) - uses en-US
   AED: "en-US",
   AFN: "en-US",
   ALL: "en-US",
   AMD: "en-US",
   ANG: "en-US",
   AOA: "en-US",
   ARS: "en-US",
   AUD: "en-US",
   AWG: "en-US",
   AZN: "en-US",
   BBD: "en-US",
   BMD: "en-US",
   BOB: "en-US",
   BSD: "en-US",
   BWP: "en-US",
   BZD: "en-US",
   CAD: "en-US",
   CLP: "en-US",
   COP: "en-US",
   CRC: "en-US",
   DJF: "en-US",
   DOP: "en-US",
   DZD: "en-US",
   EGP: "en-US",
   ERN: "en-US",
   ETB: "en-US",
   FJD: "en-US",
   FKP: "en-US",
   GBP: "en-US",
   GEL: "en-US",
   GHS: "en-US",
   GIP: "en-US",
   GMD: "en-US",
   GTQ: "en-US",
   GYD: "en-US",
   HKD: "en-US",
   HNL: "en-US",
   HTG: "en-US",
   ILS: "en-US",
   IQD: "en-US",
   IRR: "en-US",
   JMD: "en-US",
   JOD: "en-US",
   KES: "en-US",
   KMF: "en-US",
   LAK: "en-US",
   LBP: "en-US",
   LSL: "en-US",
   LYD: "en-US",
   MAD: "en-US",
   MGA: "en-US",
   MMK: "en-US",
   MNT: "en-US",
   MRU: "en-US",
   MVR: "en-US",
   MWK: "en-US",
   MXN: "en-US",
   MYR: "en-US",
   MZN: "en-US",
   NGN: "en-US",
   NIO: "en-US",
   NZD: "en-US",
   PEN: "en-US",
   PHP: "en-US",
   PYG: "en-US",
   QAR: "en-US",
   RSD: "en-US",
   SAR: "en-US",
   SDG: "en-US",
   SGD: "en-US",
   SYP: "en-US",
   THB: "en-US",
   TND: "en-US",
   TTD: "en-US",
   TZS: "en-US",
   USD: "en-US",
   VUV: "en-US",
   XCD: "en-US",
   XOF: "en-US",
   YER: "en-US",
   ZAR: "en-US",

   // Continental European format (1.234,56) - uses de-DE
   BAM: "de-DE",
   BGN: "de-DE",
   BYN: "de-DE",
   CZK: "de-DE",
   DKK: "de-DE",
   EUR: "de-DE",
   HRK: "de-DE",
   HUF: "de-DE",
   MKD: "de-DE",
   NOK: "de-DE",
   PLN: "de-DE",
   RON: "de-DE",
   RUB: "de-DE",
   SEK: "de-DE",
   TRY: "de-DE",

   // No decimals format (1,234) - uses ja-JP
   IDR: "ja-JP",
   JPY: "ja-JP",
   KPW: "ja-JP",
   KRW: "ja-JP",
   VND: "ja-JP",

   // Indian grouping format (1,23,456.78) - uses en-IN
   BDT: "en-IN",
   BTN: "en-IN",
   INR: "en-IN",
   LKR: "en-IN",
   PKR: "en-IN",

   // Middle Eastern format with 3 decimals (1,234.000) - uses ar-BH
   BHD: "ar-BH",
   KWD: "ar-BH",
   OMR: "ar-BH",

   // Swiss format (1'234.56) - uses de-CH
   CHF: "de-CH",
};
