<span
   (click)="updateSort()"
   (keydown.enter)="updateSort()"
   class="sortColumn"
   tabindex="0"
   role="button"
   [attr.aria-label]="options?.columnName + ' ' + sortState()"
   aria-live="polite"
   data-test-id="sortColumn"
>
   <span
      [ngClass]="{
         'active-sortColumn': sortState() === 'descending' || sortState() === 'ascending',
         'ascending': sortState() === 'ascending',
         'descending': sortState() === 'descending',
      }"
   >
      @if (sortState() === "disordered") {
         <lim-ui-icon iconSize="small" icon="sort" />
      }
      @if (sortState() === "descending") {
         <lim-ui-icon iconSize="small" icon="sortDesc" />
      }
      @if (sortState() === "ascending") {
         <lim-ui-icon iconSize="small" icon="sortAsc" />
      }

      {{ options?.columnName }}

      <!-- This code renders the icon to the right of the column. -->
      @if (options?.columnIcon) {
         <lim-ui-icon iconSize="small" [icon]="options?.columnIcon ?? 'notdef'" />
      }
   </span>
</span>
