<lim-ui-modal
   [showWatermark]="true"
   [small]="true"
   data-test-id="work-request-submission-modal"
>
   <lim-ui-basic-modal-header
      [title]="modalTitle()"
      (closeModal)="closeModal()"
      data-test-id="work-request-submission-modal-header"
   />
   <lim-ui-modal-body class="modal-body">
      @if (workRequestSubmission()?.isRejected) {
         <lim-ui-alert
            [alertType]="'danger'"
            data-test-id="declined-alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
         >
            <div class="declined-alert-body">
               <strong>{{ declinedMessage() }}</strong>

               <span
                  >{{ i18n().t("Reason:") }}
                  {{ workRequestSubmission()?.reviewedReason }}</span
               >
            </div>
         </lim-ui-alert>
      }

      @let requestSubmission = workRequestSubmission();

      <lim-ui-panel
         [title]="i18n().t('RequestInformation')"
         [collapsable]="true"
         data-test-id="request-information-panel"
      >
         <div class="field-container">
            <label class="field-label secondary-text bold">{{
               i18n().t("WhereOrWhatIsHavingTheProblem")
            }}</label>

            <div class="information-item-container" data-test-id="asset-information">
               <lim-ui-icon icon="cube" iconSize="small" />
               @if (asset()) {
                  <div
                     class="information-item-text"
                     [limbleHtml]="asset()?.assetName"
                  ></div>
               } @else {
                  <span class="information-item-text">{{ i18n().t("None") }}</span>
               }

               @if (asset()?.parentAssetID) {
                  <asset-parent-list
                     [assetID]="asset()?.assetID"
                     linkColorClass="sub-title"
                  />
               }
            </div>
         </div>

         <div class="field-container">
            <label class="field-label secondary-text bold">{{
               i18n().t("HowCanWeHelp")
            }}</label>
            <div [limbleHtml]="requestSubmission?.requestDescription"></div>
         </div>

         @if (
            requestSubmission &&
            (requestSubmission.pictures.length > 0 ||
               requestSubmission.documents.length > 0)
         ) {
            <div class="field-container">
               <label class="field-label secondary-text bold">{{
                  i18n().t("WorkRequestSubmissionPicture")
               }}</label>
               <div
                  class="documents-container"
                  data-test-id="work-request-submission-documents"
               >
                  @for (picture of requestSubmission?.pictures; track picture.fileName) {
                     <view-image [src]="picture.getURL" />
                  }
                  @for (
                     document of requestSubmission?.documents;
                     track document.fileName
                  ) {
                     <file-list-item [file]="document" url="{{ document.getURL }}" />
                  }
               </div>
            </div>
         }
      </lim-ui-panel>

      @let requestInformation = requestSubmission?.requestInformation;

      <lim-ui-panel
         [title]="i18n().t('SubmitterInformation')"
         [collapsable]="true"
         data-test-id="submitter-information-panel"
      >
         @if (requestInformation?.requesterName || requestInformation?.requesterEmail) {
            <div class="field-container">
               <label class="field-label secondary-text bold">{{
                  i18n().t("WorkRequestSubmittedBy")
               }}</label>
               <div data-test-id="submitted-by">
                  @if (requestInformation?.requesterName) {
                     <span
                        >{{ requestSubmission?.requestInformation?.requesterName }}
                     </span>
                  }
                  @if (
                     requestInformation?.requesterName &&
                     requestInformation?.requesterEmail
                  ) {
                     <span> | </span>
                  }
                  @if (requestInformation?.requesterEmail) {
                     {{ requestSubmission?.requestInformation?.requesterEmail }}
                  }
               </div>
            </div>
         }

         @if (requestSubmission?.submittedOn) {
            <div class="field-container">
               <label class="field-label secondary-text bold">{{
                  i18n().t("WorkRequestSubmittedOn")
               }}</label>
               <div data-test-id="submitted-on">
                  {{ requestSubmission?.submittedOn | betterDate: "date" }}
               </div>
            </div>
         }

         <div class="field-container">
            <label class="field-label secondary-text bold">{{
               i18n().t("PortalWhereCreated")
            }}</label>
            <div class="information-item-container" data-test-id="portal-created-from">
               <lim-ui-icon icon="houseChimney" iconSize="small" />
               <span class="information-item-text">{{
                  requestSubmission?.portalCreatedFromName
               }}</span>
            </div>
         </div>
      </lim-ui-panel>
      <lim-ui-panel
         [title]="i18n().t('AdditionalInformation')"
         [collapsable]="true"
         data-test-id="additional-information-panel"
      >
         @let parts = requestSubmission?.partsRequestedInfo;
         <div class="field-container">
            @if (parts && parts.length > 0) {
               <label class="field-label secondary-text bold">{{
                  i18n().t("PartsRequested")
               }}</label>
               <div data-test-id="parts-requested" class="parts-requested-list">
                  @for (part of parts; track part.partId) {
                     <span>{{ part.partName }} - {{ part.requestedQuantity }}</span>
                  }
               </div>
            }
         </div>

         @if (requestInformation) {
            <!-- Custom Fields -->
            @if (requestInformation.customFieldOne) {
               <custom-field
                  [title]="requestInformation.customFieldOneTitle ?? ''"
                  [value]="requestInformation.customFieldOne"
                  data-test-id="custom-field-one"
               />
            }
            @if (requestInformation.customFieldTwo) {
               <custom-field
                  [title]="requestInformation.customFieldTwoTitle ?? ''"
                  [value]="requestInformation.customFieldTwo"
                  data-test-id="custom-field-two"
               />
            }
            @if (requestInformation.customFieldThree) {
               <custom-field
                  [title]="requestInformation.customFieldThreeTitle ?? ''"
                  [value]="requestInformation.customFieldThree"
                  data-test-id="custom-field-three"
               />
            }
            @if (requestInformation.customFieldDropdownAnswerOne) {
               <custom-field
                  [title]="requestInformation.customDropdownTitleOne ?? ''"
                  [value]="requestInformation.customFieldDropdownAnswerOne"
                  data-test-id="custom-dropdown-one"
               />
            }
            @if (requestInformation.customFieldDropdownAnswerTwo) {
               <custom-field
                  [title]="requestInformation.customDropdownTitleTwo ?? ''"
                  [value]="requestInformation.customFieldDropdownAnswerTwo"
                  data-test-id="custom-dropdown-two"
               />
            }
            @if (requestInformation.customFieldDropdownAnswerThree) {
               <custom-field
                  [title]="requestInformation.customDropdownTitleThree ?? ''"
                  [value]="requestInformation.customFieldDropdownAnswerThree"
                  data-test-id="custom-dropdown-three"
               />
            }
         }
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      @if (workRequestSubmission() && !workRequestSubmission()?.isRejected) {
         <lim-ui-secondary-button (click)="declineRequest()">
            {{ i18n().t("DeclineRequestButton") }}
         </lim-ui-secondary-button>
         <lim-ui-primary-button (click)="approveRequest()">{{
            i18n().t("ApproveRequestButton")
         }}</lim-ui-primary-button>
      }
   </lim-ui-modal-footer>
</lim-ui-modal>
