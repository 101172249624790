import { Component } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";

interface CompletedByData {
   completedInfo?: {
      by: string;
   };
}

@Component({
   selector: "completed-by-cell-renderer",
   templateUrl: "./completed-by-cell-renderer.component.html",
})
export class CompletedByCellRendererComponent extends BaseCellRendererComponent<CompletedByData> {}
