<div class="itemEditOptions">
   <lim-ui-minimal-icon-button
      class="templateEditOption"
      icon="plus"
      (click)="addSubItem()"
      limUiTooltip="{{ lang().addInstructionTooltip }}"
      placement="right"
   />
   <lim-ui-minimal-icon-button
      class="templateEditOption"
      icon="trashCanRegular"
      (click)="deleteItem()"
      limUiTooltip="{{ lang().DeleteThisInstruction }}"
      placement="right"
   />
   <lim-ui-minimal-icon-button
      icon="gearRegular"
      (click)="advancedSettings()"
      limUiTooltip="{{ lang().SetAdvancedSettingsForOptions }}"
      placement="right"
   />
   @if (option.showCollapse) {
      <span limUiTooltip="{{ lang().collapseChildrenItemTooltip }}" placement="top">
         @if (option.collapsed) {
            <lim-ui-minimal-icon-button
               icon="chevronDown"
               (click)="collapseRadioDropdownList()"
            />
         }
         @if (!option.collapsed) {
            <lim-ui-minimal-icon-button
               icon="chevronUp"
               (click)="collapseRadioDropdownList()"
            />
         }
      </span>
   }
</div>
