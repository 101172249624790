<ng-container *translate="let t">
   <lim-ui-dropdown-button [label]="t(filter().labelKey) || ''" filterDropdown>
      <div menu>
         @for (filterOption of defaultDateFilters; track filterOption.stateID) {
            @if (filterOption.disablement?.isDisabled === true) {
               @if (filterOption.disablement?.isUpsellDisablement) {
                  <lim-ui-dropdown-item
                     (click)="$event.stopPropagation()"
                     [limUiPopover]="popOver"
                     [limUiPopoverType]="'upsell'"
                     [limUiPopoverHidden]="false"
                     [limUiPopoverPlacement]="'right'"
                  >
                     <div class="disabled-option">
                        {{ t(filterOption.labelKey) }}
                        @if (filterOption.disablement?.isUpsellDisablement) {
                           <lim-ui-badge icon="stars" />
                        }
                     </div>
                  </lim-ui-dropdown-item>
                  <ng-template #popOver>
                     <upsell-popover
                        [message]="
                           filter().options?.dateBoundaries?.paywallBoundaryMessage ?? ''
                        "
                        [pricingLinkText]="t('LearnMore')"
                     />
                  </ng-template>
               } @else {
                  <lim-ui-dropdown-item (click)="$event.stopPropagation()">
                     <div class="disabled-option">
                        {{ t(filterOption.labelKey) }}
                     </div>
                  </lim-ui-dropdown-item>
               }
            } @else {
               <lim-ui-dropdown-text-item
                  displayString="{{ t(filterOption.labelKey) }}"
                  (click)="handleSetDateFilterOption(filterOption)"
               />
            }
         }

         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-date-range-picker
            [dateBoundaries]="dateBoundaries()"
            (click)="$event.stopPropagation()"
            [sectionLabel]="t('DateRange')"
            [startDateFieldLabel]="t('From')"
            [(startDate)]="inputStartDate"
            [endDateFieldLabel]="t('To')"
            [(endDate)]="inputEndDate"
            [setButtonLabel]="t('SetDateRange')"
            (setButtonClicked)="handleSetDateRangeFilter()"
         />
         <lim-ui-dropdown-divider />

         <lim-ui-dropdown-clear-filter-item (click)="handleClear()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
