import { Injectable } from "@angular/core";
import { Subject, type Observable } from "rxjs";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";

export type InstructionTreeData = {
   instructionTree: InstructionTree;
};
export type InstructionTree = Array<any>;

export type BuildingBlocks = {
   items: Array<any>;
   options: Array<any>;
};

@Injectable()
export class InstructionTreeBuilder {
   private readonly _treeChanges$: Subject<InstructionTreeData>;
   private previousBuildingBlocks?: BuildingBlocks | undefined;

   public constructor() {
      this._treeChanges$ = new Subject<InstructionTreeData>();
   }

   /**
    * @returns an observable that emits the current instruction tree upon subscription,
    * and then emits the updated tree whenever the tree is rebuilt.
    */
   public instructionTree(): Observable<InstructionTreeData> {
      return this._treeChanges$.asObservable();
   }

   public buildInstructionTree(buildingBlocks: BuildingBlocks): void {
      this.previousBuildingBlocks = buildingBlocks;
      this._treeChanges$.next(
         this.calcInstructionTree(buildingBlocks.items, buildingBlocks.options),
      );
   }

   public getPreviousBuildingBlocks(): BuildingBlocks | undefined {
      return this.previousBuildingBlocks;
   }

   public calcInstructionTree(
      items: Array<any>,
      options: Array<any>,
   ): InstructionTreeData {
      for (const item of items) {
         if (
            item.itemTypeID === TaskInstructionTypeID.DropdownList ||
            item.itemTypeID === TaskInstructionTypeID.OptionList
         ) {
            item.nodes = orderBy(
               options.filter((option) => option.itemID === item.itemID),
               "itemOptionOrder",
            );
            this.setItemOptionOrder(item.nodes);
            for (const option of item.nodes) {
               option.parentItemTypeID = item.itemTypeID;
               option.parentItem = item;
               option.nodes = orderBy(
                  items.filter(
                     (elem) =>
                        elem.itemParentID === item.checklistItemCount &&
                        Number(elem.itemParentResponse) === option.itemOptionCount,
                  ),
                  "itemOrder",
               );
               this.setItemOrder(option.nodes);
            }
         } else {
            item.nodes = orderBy(
               items.filter((elem) => elem.itemParentID === item.checklistItemCount),
               "itemOrder",
            );
            this.setItemOrder(item.nodes);
         }
      }
      const itemsTree: InstructionTree = orderBy(
         items.filter((item) => item.itemParentID === 0),
         "itemOrder",
      );
      this.setItemOrder(itemsTree);
      return {
         instructionTree: itemsTree,
      };
   }

   private setItemOrder(elements: Array<{ itemOrder: number }>): void {
      if (elements.at(-1)?.itemOrder === elements.length) return;
      for (let index = 0; index < elements.length; index++) {
         elements[index].itemOrder = index + 1;
      }
   }

   private setItemOptionOrder(elements: Array<{ itemOptionOrder: number }>): void {
      if (elements.at(-1)?.itemOptionOrder === elements.length) return;
      for (let index = 0; index < elements.length; index++) {
         elements[index].itemOptionOrder = index + 1;
      }
   }
}
