@if (vendor) {
   <lim-ui-panel
      [showWatermark]="false"
      [noSidePadding]="true"
      [useCustomTitle]="true"
      [showHeaderDivider]="true"
      id="vendorAssetsPrint{{ vendor.vendorID }}"
   >
      <div class="vendor-assets-panel-header" panelCustomTitle>
         <span>
            <lim-ui-icon icon="cubes" />
            {{ lang().AssociatedAssets }}
         </span>

         <lim-ui-primary-button (click)="associateAssetsToVendor()">
            {{ lang().AssociateAssets }}
         </lim-ui-primary-button>
      </div>

      @if (isLoading) {
         <ngx-skeleton-loader count="3" [theme]="skeletonThemes.fillSize" /><br />
      } @else {
         <div class="asset-list">
            @for (asset of assetsInfo | orderBy: "assetName"; track asset.assetID) {
               <div class="lim-ui-list-item">
                  <div class="lim-ui-list-item-content">
                     @if (asset.locationName) {
                        <span class="asset-information">
                           <span class="asset-breadcrumbs">
                              <lim-ui-icon
                                 class="lim-ui-hide-on-mobile asset-row-icon"
                                 icon="cube"
                              />

                              <span
                                 class="asset-name"
                                 (click)="popAsset(asset.assetID)"
                                 [limbleHtml]="asset?.assetName"
                              ></span>

                              <asset-parent-list
                                 [assetID]="asset.assetID"
                                 [preventParentAccess]="false"
                                 linkColorClass="green-color"
                                 [restrict]="restrict"
                              />
                              -
                              <lim-ui-icon icon="houseChimney" />
                              <span> {{ asset.locationName }} </span>
                           </span>
                        </span>
                     }

                     <span class="asset-icons">
                        @if (asset.associationType === AssociationType.Automatic) {
                           <lim-ui-icon
                              placement="left"
                              limUiTooltip="{{ lang().AssetVendorAutoAssociationTip }}"
                              icon="wandMagicSparkles"
                           />
                        }
                        <lim-ui-minimal-icon-button
                           (click)="deleteAssetVendorAssociation(asset.assetID)"
                           placement="left"
                           limUiTooltip="{{ lang().RemoveAssociation }}"
                           icon="trashCanRegular"
                           iconColor="danger"
                           [superMinimal]="true"
                        />
                     </span>
                  </div>
               </div>
            }
         </div>
      }
   </lim-ui-panel>
}
