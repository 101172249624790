import { z } from "zod";

export const CustomOrderUnitSchema = z.object({
   purchasableCustomOrderUnitID: z.number(),
   purchasableCustomOrderUnitNameShort: z.string(),
   purchasableCustomOrderUnitNameSingular: z.string(),

   purchasableProvidedOrderUnitID: z.null(),
   purchasableProvidedOrderUnitAlias: z.null(),
});
