<lim-ui-panel [noSidePadding]="true">
   <div class="header-section-row">
      <div class="header-items-group">
         <ng-content select="[title]" />
         <data-viewer-search
            [isClearable]="true"
            (searchChange)="onSearchChange($event)"
         />
      </div>

      @if (!hasRestrictedPermissions() && canExport()) {
         <lim-ui-icon-button
            class="mobileDisappear"
            icon="cloudArrowDown"
            (click)="handleExportToExcel()"
            limUiTooltip="{{ lang().ExportListToExcel }}"
            placement="bottom"
         />
      }
   </div>

   <tasks-data-viewer
      [columns]="columns()"
      [isLoading]="isLoading()"
      [noSearchResults]="!hasResults()"
      [tasks]="tasks()"
      [totalTasks]="tasksTotal()"
      [hidden]="!isDataViewerVisible()"
   />
   @if (!hasContent()) {
      <div class="no-content-message">
         {{ noContentLabel() }}
      </div>
   }

   @if (hasRestrictedPermissions()) {
      <div class="no-content-message">
         <span> {{ restrictedContentLabel() }} </span>
      </div>
   }
</lim-ui-panel>
