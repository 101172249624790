import type { FilterBase } from "src/app/dashboards/filters/index";
import type { TaskLookup } from "src/app/tasks/types/task.types";

export class UserFilter implements FilterBase {
   public readonly filterType = "user" as const;
   public readonly displayString: string;

   public constructor(
      public readonly userID: number,
      userFullName: string,
   ) {
      this.displayString = userFullName;
   }

   public filterFunction(tasks: TaskLookup): TaskLookup {
      return tasks.filter((task) => {
         if (task.checklistUserCompleted === null) return false;
         return Number(task.checklistUserCompleted) === this.userID;
      });
   }

   public getValue(): number {
      return this.userID;
   }
}
