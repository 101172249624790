import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import type { TagDetailsCellRenderer } from "src/app/shared/empowered/base/table/components/cells-renderers/cell-renderers.types";
import { TagComponent } from "src/app/shared/empowered/base/tag/tag.component";
import type { TagSeverityTrio } from "src/app/shared/empowered/base/tag/tag.component.types";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";

interface CompletedOnData {
   completedInfo?: {
      by: string;
      daysPastDueDate: string;
      daysPastDueDateTooltip: string;
   };
   completedDate?: Date;
   completionColor?: 0 | 1 | 2 | 3;
}

@Component({
   selector: "completed-on-cell-renderer",
   imports: [CommonModule, BetterDatePipe, TagComponent],
   templateUrl: "./completed-on-cell-renderer.component.html",
   styleUrls: ["./completed-on-cell-renderer.component.scss"],
})
export class CompletedOnCellRendererComponent extends BaseCellRendererComponent<CompletedOnData> {
   protected get tagDetails(): TagDetailsCellRenderer | undefined {
      if (!this.cell?.completedInfo || !this.cell?.completedDate) return undefined;

      let severity: TagSeverityTrio = undefined;
      // eslint-disable-next-line default-case, typescript/switch-exhaustiveness-check -- this is intentional
      switch (this.cell?.completionColor) {
         case 0:
         case 3:
            severity = "danger";
            break;
         case 1:
            severity = "success";
            break;
         case 2:
            severity = "warn";
            break;
      }

      return {
         tooltip: this.cell?.completedInfo?.daysPastDueDateTooltip,
         severity,
         value: this.cell?.completedInfo?.daysPastDueDate,
      };
   }
}
