@if (cell?.completedInfo && cell?.completedDate) {
   <div>
      <span class="completed-date">
         <span class="completed-date__label">
            {{ cell?.completedDate?.getTime() | betterDate: "date" }}
         </span>

         @if (cell?.completionColor !== undefined) {
            <e-tag
               [rounded]="true"
               [severity]="tagDetails?.severity"
               [tooltip]="tagDetails?.tooltip"
               [value]="tagDetails?.value ?? ''"
            />
         }
      </span>
   </div>
}
