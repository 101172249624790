import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { DragEndEvent, LimbleTreeOptions } from "@limble/limble-tree";
import { LimbleTreeRootComponent } from "@limble/limble-tree";
import {
   IconComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { AddVendorField } from "src/app/vendors/components/addVendorFieldModal/addVendorField.modal.component";
import { EditVendorField } from "src/app/vendors/components/editVendorFieldModal/editVendorField.modal.component";
import { VendorInformationItem } from "src/app/vendors/components/vendorInformationItemElement/vendorInformationItem.element.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { VendorFieldValue } from "src/app/vendors/types/field/value/vendor-field-value.types";
import type { Vendor } from "src/app/vendors/types/vendor.types";

type VendorCreds = {
   changeVendorName: boolean;
   changeVendorContact: boolean;
   changeVendorEmail: boolean;
   changeVendorPhone: boolean;
   changeVendorAddress: boolean;
   configureVendorFields: boolean;
};

@Component({
   selector: "vendor-information",
   templateUrl: "./vendorInformation.wrapper.component.html",
   styleUrls: ["./vendorInformation.wrapper.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      NgClass,
      ContenteditableDirective,
      FormsModule,
      TooltipDirective,
      LimbleTreeRootComponent,
      PrimaryButtonComponent,
   ],
})
export class VendorInformation implements OnInit, OnDestroy {
   @Input() public vendor: Vendor | undefined;
   @Input() public restrict: boolean = false;

   public cusID;
   public fields: Array<VendorFieldValue> = [];
   public treeOptions?: LimbleTreeOptions;
   public dateOptions;
   public formats;
   public format;
   public opened;
   public vendorFieldsSub;
   public vendorFieldsObs;
   public oldName;
   public creds: VendorCreds = {
      changeVendorName: false,
      changeVendorContact: false,
      changeVendorEmail: false,
      changeVendorPhone: false,
      changeVendorAddress: false,
      configureVendorFields: false,
   };
   public vendorFieldValues: Lookup<"valueID", VendorFieldValue>;
   private readonly allVendorFieldsSub: Subscription | null;

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly credService = inject(CredService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.vendorFieldValues = this.manageVendor.getValues();
      this.allVendorFieldsSub = this.manageObservables.setSubscription(
         "vendorFields",
         () => {
            if (!this.vendor) {
               return;
            }
            this.buildFields();
         },
      );
   }

   public ngOnInit() {
      if (this.vendor === undefined) {
         throw new Error(
            "VendorInformation component requires a `vendor` input binding, but a value was not provided.",
         );
      }

      this.getVendorCreds();

      this.cusID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.vendorFieldsObs = `vendorFields${this.vendor.vendorID}`;
      this.vendorFieldsSub = this.manageObservables.createAndSubscribe(
         this.vendorFieldsObs,
         this.buildFields.bind(this),
      );

      this.treeOptions = {
         allowNesting: false,
         defaultComponent: {
            class: VendorInformationItem,
            bindings: {
               restrict: this.restrict,
            },
         },
      };

      this.dateOptions = {
         formatYear: "yy",
         startingDay: 1,
      };

      this.formats = ["dd-MMMM-yyyy", "yyyy/MM/dd", "dd.MM.yyyy", "shortDate"];

      this.format = this.formats[0];
      this.oldName = this.vendor.vendorName;
   }

   public ngOnDestroy() {
      this.manageObservables.unsubscribeAndDelete(
         this.vendorFieldsObs,
         this.vendorFieldsSub,
      );
      this.allVendorFieldsSub?.unsubscribe();
   }

   /** function called when you drag/drop an item */
   protected async onMoveNode(event: DragEndEvent<VendorInformationItem>): Promise<void> {
      assert(this.vendor);
      const draggedField = this.fields.splice(event.oldIndex(), 1);
      this.fields.splice(event.newIndex(), 0, draggedField[0]);
      const updates: Array<[number, number]> = [];
      for (const [index, field] of this.fields.entries()) {
         field.valueSort = index + 1;
         updates.push([field.valueID, field.valueSort]);
      }
      const answer = await this.manageVendor.updateSorts(updates, this.vendor.locationID);
      if (answer.data.success == true) {
         this.manageVendor.tileFieldsObs$.next(null);
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   open = ($event) => {
      $event.preventDefault();
      $event.stopPropagation();

      this.opened = true;
   };

   updateVendorEmail = () => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangeVendorEmail,
         )
      ) {
         this.alertService.addAlert(this.lang().cred130Fail, "danger", 10000);
         return;
      }

      this.vendor.vendorEmail = this.manageFilters.htmlToPlaintext(
         this.vendor.vendorEmail,
      );

      this.manageVendor.updateVendorEmail(this.vendor).then((answer) => {
         if (answer?.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   updateVendorPhone = () => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangeVendorPhone,
         )
      ) {
         this.alertService.addAlert(this.lang().cred139Fail, "danger", 10000);
         return;
      }

      this.manageVendor.updateVendorPhone(this.vendor).then((answer) => {
         if (answer?.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   updateVendorContact = () => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangeVendorContact,
         )
      ) {
         this.alertService.addAlert(this.lang().cred140Fail, "danger", 10000);
         return;
      }

      this.manageVendor.updateVendorContact(this.vendor).then((answer) => {
         if (answer?.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   updateVendorName = () => {
      assert(this.vendor);
      if (this.oldName == this.vendor.vendorName) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangeVendorName,
         )
      ) {
         this.alertService.addAlert(this.lang().cred129Fail, "danger", 10000);
         return;
      }

      if (this.vendor.vendorName == "") {
         this.vendor.vendorName = this.oldName;
         this.alertService.addAlert(this.lang().NameFieldCannotBeBlank, "danger", 10000);
         return;
      }

      this.vendor.vendorName = this.manageFilters.htmlToPlaintext(this.vendor.vendorName);
      this.manageVendor.updateVendorName(this.vendor).then((answer) => {
         assert(this.vendor);
         if (answer?.data.success == true) {
            this.oldName = this.vendor.vendorName;
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else if (answer?.data.error === "nonUniqueValue") {
            this.vendor.vendorName = this.oldName;
            this.alertService.addAlert(
               this.lang().DefaultFieldUniqueError,
               "warning",
               6000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   updateVendorAddress = () => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ChangeVendorPhone,
         )
      ) {
         this.alertService.addAlert(this.lang().cred139Fail, "danger", 10000);
         return;
      }

      this.manageVendor.updateVendorAddress(this.vendor).then((answer) => {
         if (answer?.data.success == true) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   addField = (vendor) => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.ConfigureVendorInformationFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred132Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(AddVendorField);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AddAFieldMsg,
            title: this.lang().AddAField,
            vendor: vendor,
         },
      };

      instance.result.then((result) => {
         if (result != 0) {
            if (result.view === "suggested") {
               let delay = 100;
               for (const fieldID of result.field) {
                  const obj = {
                     vendor: vendor,
                     fieldID: fieldID,
                  };
                  setTimeout(
                     () => {
                        this.manageVendor
                           .addExistingField(obj.vendor, obj.fieldID)
                           .then((answer) => {
                              if (answer?.data.success == true) {
                                 //update the parent and the child's tile fields
                                 this.manageVendor.tileFieldsObs$.next(null);
                                 this.alertService.addAlert(
                                    this.lang().FieldSuccessfullyAdded,
                                    "success",
                                    1000,
                                 );
                              } else {
                                 this.alertService.addAlert(
                                    this.lang().errorMsg,
                                    "danger",
                                    6000,
                                 );
                              }
                           });
                     },
                     delay,
                     obj,
                  );
                  delay = delay + 200;
               }
            }

            if (result.view === "new") {
               this.manageVendor
                  .addNewField(
                     vendor,
                     result.type.fieldTypeID,
                     result.name,
                     result.options,
                  )
                  .then((answer) => {
                     if (answer?.data.success == true) {
                        this.manageVendor.tileFieldsObs$.next(null);
                        this.alertService.addAlert(
                           this.lang().FieldSuccessfullyAdded,
                           "success",
                           1000,
                        );
                        //if it is a dropdown open up the dialog to edit the fellow
                        if (answer?.data.row.fieldTypeID == 7) {
                           this.addDropdown(answer?.data.row);
                        }
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     }
                  });
            }

            if (result.view === "updated") {
               this.manageVendor.tileFieldsObs$.next(null);
            }
         }
      });
   };

   //copied from assets
   addDropdown = (field) => {
      const instance = this.modalService.open(EditVendorField);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            field: field,
         },
      };

      instance.result.then((result) => {
         if (result.delete) {
            this.manageVendor.removeSuggestedField(field).then((answer) => {
               if (answer?.data.success == true) {
                  for (const [idx, fieldToRemove] of this.fields.entries()) {
                     if (fieldToRemove.fieldID == field.fieldID) {
                        this.fields.splice(idx, 1);
                     }
                  }
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   private getVendorCreds() {
      assert(this.vendor);
      this.creds.changeVendorName = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ChangeVendorName,
      );

      this.creds.changeVendorContact = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ChangeVendorContact,
      );

      this.creds.changeVendorEmail = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ChangeVendorEmail,
      );

      this.creds.changeVendorPhone = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ChangeVendorPhone,
      );

      this.creds.changeVendorAddress = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ChangeVendorPhone,
      );

      this.creds.configureVendorFields = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ConfigureVendorInformationFields,
      );
   }

   public buildFields() {
      assert(this.vendor);
      this.fields = [];

      for (const valueID of this.vendor.vendorValueIDs) {
         const value = this.vendorFieldValues.strictGet(valueID);
         this.fields.push(value);
      }

      this.fields = orderBy(this.fields, "valueSort");
   }
}
