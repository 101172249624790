import { TitleCasePipe } from "@angular/common";
import type { AfterViewInit, OnChanges, OnInit, ElementRef } from "@angular/core";
import { inject, Component, Input, Renderer2, ViewChild, computed } from "@angular/core";
import { LimbleHtmlDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { assert } from "src/app/shared/utils/assert.utils";
import QRCode from "src/assets/js/qrcode";

@Component({
   selector: "qr-code-item",
   templateUrl: "./qrCodeItem.element.component.html",
   styleUrls: ["./qrCodeItem.element.component.scss"],
   imports: [LimbleHtmlDirective, TitleCasePipe, BetterDatePipe],
})
export class QRCodeItem implements OnInit, AfterViewInit, OnChanges {
   @ViewChild("qrCodeContainer") public qrCodeContainer?: ElementRef<HTMLElement>;
   @ViewChild("dataContainer") public dataContainer?: ElementRef<HTMLElement>;
   @ViewChild("dummyContainer") public dummyContainer?: ElementRef<HTMLElement>;
   @Input() public item;
   @Input() public dataType;
   @Input() public codeItemWidth;
   @Input() public codeItemHeight;
   @Input() public gridCols;
   @Input() public gridRows;
   @Input() public displayFields;
   @Input() public manualFontSize;
   @Input() public rotate90;
   @Input() public preview;

   public qrCodeContainerEl;
   public imgContainerEl;
   public dataContainerEl;
   public dummyContainerEl;
   public initialRender = true;
   public fontSize;
   public qrSize;
   public filteredFieldsArray: any = [];

   private readonly renderer = inject(Renderer2);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnChanges(changes) {
      if (
         (changes.codeItemWidth?.previousValue !== changes.codeItemWidth?.currentValue ||
            changes.codeItemHeight?.previousValue !==
               changes.codeItemHeight?.currentValue) &&
         !this.initialRender
      ) {
         this.buildQRCode(this.item);
      }
      if (
         changes.displayFields?.previousValue !== changes.displayFields?.currentValue &&
         !this.initialRender
      ) {
         this.filterFields();
         this.buildQRCode(this.item);
      }
      if (
         changes.manualFontSize?.previousValue !== changes.manualFontSize?.currentValue &&
         !this.initialRender
      ) {
         this.buildQRCode(this.item);
      }
      if (
         changes.rotate90?.previousValue !== changes.rotate90?.currentValue &&
         !this.initialRender
      ) {
         this.buildQRCode(this.item);
      }
   }

   public ngOnInit() {
      this.filterFields();
   }

   public ngAfterViewInit() {
      assert(this.qrCodeContainer !== undefined);
      assert(this.dataContainer !== undefined);
      assert(this.dummyContainer !== undefined);
      this.qrCodeContainerEl = this.qrCodeContainer.nativeElement;
      this.dataContainerEl = this.dataContainer.nativeElement;
      this.dummyContainerEl = this.dummyContainer.nativeElement;
      this.initialRender = false;
      this.buildQRCode(this.item);
   }

   buildQRCode = (qrItem) => {
      if (this.item.dummy) {
         this.renderer.setStyle(
            this.dummyContainerEl,
            "width",
            `${this.codeItemWidth}px`,
         );
         this.renderer.setStyle(
            this.dummyContainerEl,
            "height",
            `${this.codeItemHeight}px`,
         );
         this.renderer.setStyle(
            this.dummyContainerEl,
            "font-size",
            `${this.codeItemHeight / 7}px`,
         );
         return;
      }

      if (this.imgContainerEl) {
         //if the element has already been rendered, clear it out for the new one to be rendered
         this.renderer.removeChild(this.qrCodeContainerEl, this.imgContainerEl);
      }

      this.imgContainerEl = this.renderer.createElement("qrCodeImgContainer");
      this.renderer.insertBefore(
         this.qrCodeContainerEl,
         this.imgContainerEl,
         this.dataContainerEl,
      );

      //build the fields to display on the QR code

      //set qr image size to whichever is bigger, the item width or height
      this.qrSize =
         this.codeItemHeight > this.codeItemWidth / 2 - 20
            ? this.codeItemWidth / 2 - 20
            : this.codeItemHeight - 20;

      // eslint-disable-next-line no-new -- we don't have control over this library
      new QRCode(this.imgContainerEl, {
         text: qrItem.URL,
         width: this.qrSize,
         height: this.qrSize,
         colorDark: "#000000",
         colorLight: "#ffffff",
         correctLevel: QRCode.CorrectLevel.H,
      });

      this.setFontSize();

      this.renderer.setStyle(this.dataContainerEl, "font-size", `${this.fontSize}px`);
      this.renderer.setStyle(this.dataContainerEl, "line-height", `${this.fontSize}px`);
      this.renderer.setStyle(this.qrCodeContainerEl, "width", `${this.codeItemWidth}px`);
      this.renderer.setStyle(
         this.qrCodeContainerEl,
         "height",
         `${this.codeItemHeight}px`,
      );
      if (this.rotate90) {
         this.renderer.setStyle(this.qrCodeContainerEl, "flex-direction", "column");
         this.renderer.setStyle(
            this.dataContainerEl,
            "transform",
            `rotate(90deg) translateX(${this.dataContainerEl.offsetWidth / 2}px)`,
         );
      } else {
         this.renderer.setStyle(this.qrCodeContainerEl, "flex-direction", "row");
         this.renderer.setStyle(this.dataContainerEl, "transform", "none");
      }
   };

   protected setFontSize() {
      if (typeof this.manualFontSize === "number") {
         this.fontSize = this.manualFontSize;
      } else {
         // Auto set font size
         if (this.gridCols === 1 && this.gridRows <= 6) {
            this.setFontSizeBasedOnFieldCount();
         } else if (this.gridCols === 1 && this.gridRows > 6) {
            this.fontSize = 15;
         } else if (this.gridCols === 2 && this.gridRows <= 8) {
            this.setFontSizeBasedOnFieldCount();
         } else if (this.qrSize / 10 > 10) {
            this.fontSize = 10;
         } else if (this.qrSize / 10 < 4) {
            this.fontSize = 4;
         } else {
            this.fontSize = this.qrSize / 12;
         }
      }
   }

   protected setFontSizeBasedOnFieldCount() {
      const scaleFactor = 1.2;
      const minFontSize = 10;
      const maxFontSize = 22;
      this.fontSize = this.qrSize * 0.1 - scaleFactor * this.filteredFieldsArray.length;
      if (this.fontSize < 10) {
         this.fontSize = minFontSize;
      } else if (this.fontSize > maxFontSize) {
         this.fontSize = maxFontSize;
      }
   }

   filterFields = () => {
      //clear out the filtered fields
      this.filteredFieldsArray = [];

      if (this.item.fields) {
         this.item.fields.forEach((itemField) => {
            if (
               this.displayFields.findIndex(
                  (displayField) => displayField.fieldName == itemField.fieldName,
               ) !== -1
            ) {
               this.filteredFieldsArray.push(itemField);
            }
         });
      }
   };
}
