import type { TutorialData } from "src/app/onboarding/services/tutorials/tutorial";
import { Tutorial } from "src/app/onboarding/services/tutorials/tutorial";

export interface DocTutorialData extends TutorialData {
   link: string;
   description: string;
}

export class DocTutorial extends Tutorial {
   public link: string = "";
   public description: string = "";
   public override isDoc: boolean = true;

   public constructor(data: DocTutorialData) {
      super(data);
      Object.assign(this, data);
   }
}
