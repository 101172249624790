import { inject, Injectable } from "@angular/core";
import { CommentStorageSyncService } from "src/app/lite/local-db/resources/collection/task/comment/comment.storage.sync.service";
import { ExtraTimeStorageSyncService } from "src/app/lite/local-db/resources/collection/task/extra-time/extra-time.storage.sync.service";
import { TaskPartStorageSyncService } from "src/app/lite/local-db/resources/collection/task/part/task-part.storage.sync.service";
import { taskSchema } from "src/app/lite/local-db/resources/collection/task/task.schema";
import { TaskStorageService } from "src/app/lite/local-db/resources/collection/task/task.storage.service";
import type { Task } from "src/app/lite/local-db/resources/collection/task/task.types";
import type { SendRequestParams } from "src/app/lite/local-db/resources/resource.initializer.params";
import { refetchValidData } from "src/app/lite/local-db/resources/resource.utils";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { Flags } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { taskSchemaStrict } from "src/app/tasks/schemata/tasks/task.schema.strict";
import type { Task as MainAppTask } from "src/app/tasks/types/task.types";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class TaskStorageSyncService extends StorageSyncService {
   private readonly commentStorageService = inject(CommentStorageSyncService);
   private readonly extraTimeStorageService = inject(ExtraTimeStorageSyncService);
   private readonly partStorageService = inject(TaskPartStorageSyncService);
   private readonly taskStorageService = inject(TaskStorageService);
   private readonly launchFlagsService = inject(LaunchFlagsService);

   public constructor() {
      super();
   }

   public async syncTaskByID(taskID: number): Promise<void> {
      return this.sync(async () => {
         if (!taskID) return;

         const { url, params } = await this.fetchDetails(taskID);

         const task = await refetchValidData({
            url,
            params,
            validation: taskSchema.and(
               taskSchemaStrict.pick({
                  noteIDs: true,
                  extraTimeIDs: true,
                  partIDs: true,
               }),
            ),
         });
         if (task === undefined || task.checklistTemplate) return;

         this.taskStorageService.putTask(task);
         this.checkTaskAssociations(task);
      });
   }

   public async syncTaskDeletion(taskID: number): Promise<void> {
      return this.sync(async () => {
         await this.taskStorageService.deleteTask(taskID);
      });
   }

   private checkTaskAssociations(
      task: Task & Pick<MainAppTask, "noteIDs" | "extraTimeIDs" | "partIDs">,
   ): void {
      if (!task) return;

      if (task.noteIDs?.length) {
         this.commentStorageService.syncCommentsByTask(task.checklistID);
      }

      if (task.extraTimeIDs?.length) {
         this.extraTimeStorageService.syncExtraTimeByTaskID(task.checklistID);
      }

      if (task.partIDs?.length) {
         this.partStorageService.syncTaskPartsByTaskID(task.checklistID);
      }
   }

   private async fetchDetails(taskID: number): Promise<SendRequestParams> {
      const useMiniFlannel = await this.launchFlagsService.getFlagPromise(
         Flags.MINI_FLANNEL_SINGLE_TASK,
         false,
      );
      if (useMiniFlannel) {
         return {
            url: () => `${environment.servicesURL()}/tasks/${taskID}`,
            params: {},
         };
      }
      return {
         url: "tasks/task",
         params: { checklistID: taskID },
      };
   }
}
