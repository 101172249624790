import { Injectable } from "@angular/core";
import axios from "axios/dist/axios";
import { cleanWordPaste } from "src/app/shared/utils/app.util";

@Injectable({ providedIn: "root" })
export class ManageVendorLogs {
   private readonly vendorLogs: any = {};
   private readonly axios = axios;

   public constructor() {
      this.vendorLogs.arr = [];
      this.vendorLogs.obj = {};
   }

   getLogs = async (vendors) => {
      const vendorsStringified = JSON.stringify(vendors);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendorLogs.php",
         params: { action: "getVendorLogs" },
         data: { vendors: vendorsStringified },
      });

      return post;
   };

   addEntry = async (entry, vendor, date, files, logViewable) => {
      const entryCleaned = cleanWordPaste(entry);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendorLogs.php",
         params: {
            action: "addEntry",
         },
         data: {
            entry: entryCleaned,
            vendorID: vendor.vendorID,
            date: date,
            files: files,
            logViewable: logViewable,
         },
      });

      return post;
   };

   updateLogDate = async (log, date) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendorLogs.php",
         params: {
            action: "updateLogDate",
         },
         data: {
            logID: log.vendorLogID,
            date: date,
         },
      });

      return post;
   };

   deleteLogEntry = async (log) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendorLogs.php",
         params: {
            action: "deleteLogEntry",
         },
         data: {
            logID: log.vendorLogID,
         },
      });

      return post;
   };

   changeLogDetails = async (log) => {
      log.logDetails = cleanWordPaste(log.logDetails);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendorLogs.php",
         params: {
            action: "changeLogDetails",
         },
         data: {
            logID: log.vendorLogID,
            logDetails: log.logDetails,
         },
      });

      return post;
   };

   deleteVendorLogFile = async (file) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendorLogs.php",
         params: { action: "deleteVendorLogFile" },
         data: {
            fileID: file.fileID,
         },
      });

      return post;
   };

   showHideLogEntry = async (log) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageVendorLogs.php",
         params: { action: "showHideLogEntry" },
         data: {
            logID: log.vendorLogID,
            logViewable: log.logViewable,
         },
      });

      return post;
   };
}
