import {
   ChangeDetectorRef,
   Component,
   computed,
   type ElementRef,
   EventEmitter,
   inject,
   Input,
   type OnInit,
   Output,
   signal,
   ViewChild,
} from "@angular/core";
import { InputWithPrefixComponent } from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import type { LanguageCode } from "src/app/languages";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { assert } from "src/app/shared/utils/assert.utils";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

@Component({
   selector: "currency-input",
   templateUrl: "./currency-input.component.html",
   imports: [InputWithPrefixComponent, BetterCurrencyPipe, CurrencySymbolPipe],
})
export class CurrencyInputComponent implements OnInit {
   @Input() value: number = 0;
   @Input() disabled: boolean = false;
   @Input() min: number = 0;
   @Input() currencyInputSymbol: string | undefined;
   @Input() localeID: LanguageCode | undefined;
   @Input() locationID: number | undefined;
   @Input() classNames: string = "";
   @Output() readonly valueChanged = new EventEmitter<number>();
   @Output() readonly invalidInput = new EventEmitter<void>();
   @ViewChild("prefixSpan") prefixSpan!: ElementRef;
   @ViewChild("input") input!: ElementRef;
   private readonly parsedNumberPattern = /^\d*(?:\.\d*)?$/;
   private readonly changeDetectorRef = inject(ChangeDetectorRef);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   protected readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;
   private readonly locationQueries = inject(LocationQueriesService);
   private readonly queryLocationID = signal<number | undefined>(undefined);
   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationQueries.detail(this.queryLocationID()),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode ?? []),
      this.isMultiCurrencyEnabled,
   );

   public ngOnInit(): void {
      if (this.locationID) {
         this.queryLocationID.set(this.locationID);
      }
   }
   protected handleValueChange(inputValue: string | number | null | undefined) {
      if (typeof inputValue !== "string") {
         this.invalidInput.emit();
         return;
      }

      const inputNumber = this.parseNumberAndRoundToTwoDecimals(inputValue);

      if (inputNumber === this.value) this.revertInputValueToPreviousValue();
      else this.value = inputNumber;

      this.valueChanged.emit(this.value);
   }

   private revertInputValueToPreviousValue(): void {
      const previousValue = this.value;
      this.value = 0;
      this.changeDetectorRef.detectChanges();
      this.value = previousValue;
   }

   private parseNumberAndRoundToTwoDecimals(input: string): number {
      const parsedValidNumber = this.parseValidNumber(input);
      return this.roundToTwoDecimals(parsedValidNumber);
   }

   private parseValidNumber(input: string): number {
      const parsedNumberString = this.parseNumberString(input);

      if (!this.isValidNumber(parsedNumberString)) {
         this.invalidInput.emit();
         return this.value;
      }

      return parseFloat(parsedNumberString);
   }

   private isValidNumber(input: string): boolean {
      return this.parsedNumberPattern.test(input) && input !== "." && input !== "";
   }

   private parseNumberString(input: string): string {
      const localeNumberString = this.removeConfiguredCurrencySymbol(input).trim();
      return this.convertLocaleNumberStringToPlainNumberString(localeNumberString);
   }

   private removeConfiguredCurrencySymbol(input: string): string {
      let trimmedInput = input;
      if (input.startsWith(this._currencyInputSymbol)) {
         trimmedInput = input.slice(1);
      }
      if (input.endsWith(this._currencyInputSymbol)) {
         trimmedInput = input.slice(0, -1);
      }
      return trimmedInput;
   }

   private convertLocaleNumberStringToPlainNumberString(localeNumber: string): string {
      const plainNumberStringLocale = this.removeDigitGrouping(localeNumber);
      return plainNumberStringLocale.replace(",", ".");
   }

   private removeDigitGrouping(value: string): string {
      const digitGroupingCharacter = this.decimalSeparator === "," ? "." : ",";
      return value.replaceAll(digitGroupingCharacter, "");
   }

   private roundToTwoDecimals(inputNumber: number): number {
      return Number(inputNumber.toFixed(2));
   }

   private get decimalSeparator(): string {
      const numberWithDecimalSeparator = 1.1;

      const decimalPart = Intl.NumberFormat(this._localeID)
         .formatToParts(numberWithDecimalSeparator)
         .find((formatPart) => formatPart.type === "decimal");
      assert(decimalPart);

      return decimalPart.value;
   }

   private get _currencyInputSymbol(): string {
      return this.currencyInputSymbol ?? "$";
   }

   private get _localeID(): LanguageCode {
      return this.localeID ?? "en-US";
   }
}
