import { computed, inject, Injectable } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { ManageFilters } from "src/app/shared/services/manageFilters";

export type LocationWithNodes = {
   locationName: string;
   locationID: number;
   regionID: number;
   locationNameWithRegions: string;
   nodes: any[];
};

export type Region = {
   regionID: number;
   parentRegionID: number;
   regionName: string;
   region: true;
   nodes?: Array<Region | LocationWithNodes>;
   showKids?: boolean;
   explicitlySearched?: boolean;
   selected?: boolean;
};

export type RegionWithNodes = Region & {
   nodes: Array<RegionWithNodes | LocationWithNodes>;
};

export type NoRegion = {
   regionID: number;
   regionName: string;
   noRegion: true;
   showKids: boolean;
   nodes: Array<LocationWithNodes>;
};

/**
 * @deprecated
 * Use LocationHierarchy instead.
 */
@Injectable({ providedIn: "root" })
export class LocationHierarchyService {
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   // This function is used to prep the location and region data before building it in buildLocationHierData.  We have this because we don't want to actually update manageLocation.getLocations and manageLocation.getRegions
   public prepLocRegionData(showOnlyInactiveLocations: boolean = false): {
      locations: Array<{
         locationID: number;
         regionID: number;
         locationName: string;
         locationNameWithRegions: string;
         nodes: Array<any>;
         location: true;
         metaField1: string;
         metaField2: string;
         metaField3: string;
      }>;
      regions: Array<{
         regionID: number;
         parentRegionID: number;
         regionName: string;
         locationName: "   aaaaaaa";
         nodes: Array<any>;
         region: true;
      }>;
   } {
      const locations = (
         showOnlyInactiveLocations
            ? this.manageLocation.getInactiveLocations()
            : this.manageLocation.getLocations()
      ).map((loc) => {
         return {
            locationID: loc.locationID,
            regionID: loc.regionID,
            locationName: loc.locationName,
            locationNameWithRegions: loc.locationNameWithRegions,
            inactive: loc.inactive,
            nodes: [],
            location: true as const,
            metaField1: loc.metaField1 ?? "",
            metaField2: loc.metaField2 ?? "",
            metaField3: loc.metaField3 ?? "",
         };
      });

      const regions = this.manageLocation.getRegions().map((region) => {
         return {
            regionID: region.regionID,
            parentRegionID: region.parentRegionID,
            regionName: region.regionName,
            locationName: "   aaaaaaa" as const, //we add this so that when we order nodes by location name these start at the top
            nodes: [],
            region: true as const,
         };
      });
      return { locations, regions };
   }

   // This function builds the location hier data so it can be easily used throughout the entire tool...
   // it is a beast of a function.  Quite proud of the power and speed, but has a lot of recursion
   public buildHierarchy<T extends LocationWithNodes>(options: {
      locations: Array<T>;
      regions: Array<Region>;
      search: string | undefined;
      filterRegions: boolean;
      filterLocations: boolean;
      alwaysReturnRegions: boolean;
      totalLocationCount: number;
   }): {
      regionTree: Array<RegionWithNodes | NoRegion> | Array<T>;
      regionsIndex: Record<number, RegionWithNodes | NoRegion>;
      locationsIndex: Record<number, LocationWithNodes>;
      locationsByRegion: Record<number, Array<LocationWithNodes>>;
   } {
      let { locations, regions } = options;
      const {
         search,
         filterRegions,
         filterLocations,
         alwaysReturnRegions,
         totalLocationCount,
      } = options;
      //sets some data sets we may need to access later
      const locationsByRegion: Record<number, Array<LocationWithNodes>> = {};
      const locationsIndex: Record<number, LocationWithNodes> = {};
      for (const location of locations) {
         locationsByRegion[location.regionID] ??= [];
         locationsByRegion[location.regionID].push(location);
         locationsIndex[location.locationID] = location;
      }
      if (filterLocations && search && search.length > 0) {
         //we always have to run filter on locations first in case we have to return early
         locations = this.manageFilters.filterLocationsToSearch(locations, search);
      }
      locations = orderBy(locations, "locationName");
      regions = orderBy(regions, "regionName");
      //if they aren't setting up regions and they have no regions available just return locations
      //this is ideal for customers that don't have regions
      if (
         alwaysReturnRegions === false &&
         (regions.length === 0 || totalLocationCount <= 2) //they are only looking at 1-2 locations so why show a tree ;p  we may want to expand this threshold.  If we do update lang in Manage Locations to reflect the tip
      ) {
         return {
            regionTree: locations, //we return just locations as the top level full region tree
            regionsIndex: {},
            locationsIndex: locationsIndex,
            locationsByRegion: locationsByRegion,
         };
      }
      //sets up some more defaults
      let hierarchy: Array<RegionWithNodes> = regions.map((region) => {
         return {
            ...region,
            nodes: [],
            showKids: true,
            explicitlySearched: false,
            region: true,
         };
      });
      const regionsIndex: Record<number, RegionWithNodes> = {};
      for (const region of hierarchy) {
         regionsIndex[region.regionID] = region;
      }
      //builds out the region portion of the tree
      hierarchy
         .filter(
            (region) => region.parentRegionID > 0 && regionsIndex[region.parentRegionID],
         )
         .forEach((region) => {
            const parent = regionsIndex[region.parentRegionID];
            parent.nodes.push(region);
         });
      //add a blank region...
      const noRegion: NoRegion = {
         regionName: this.lang().NoRegion,
         regionID: 0,
         noRegion: true,
         showKids: true,
         nodes: locations.filter((location) => location.regionID === 0),
      };
      //goes through and puts the locations at the right places throughout the tree
      locations
         .filter((location) => location.regionID !== 0 && regionsIndex[location.regionID])
         .forEach((location) => {
            const region = regionsIndex[location.regionID];
            region.nodes.push(location);
         });
      //ok now that the tree is all built we need to do searches if needed...
      //we have to do it after the tree is built because we have to navigate the tree to see which parents are needed to be included
      if (filterRegions && search !== undefined && search.length > 0) {
         hierarchy = this.search(hierarchy, search, {
            regions: regionsIndex,
            locationsByRegion: locationsByRegion,
            locations: locationsIndex,
         });
      }
      const treeData: Array<RegionWithNodes | NoRegion> = hierarchy.filter(
         (region) => region.parentRegionID === 0,
      );
      treeData.push(noRegion);
      const finalRegionsIndex: Record<number, RegionWithNodes | NoRegion> = regionsIndex;
      finalRegionsIndex[0] = noRegion;
      for (const region of Object.values(finalRegionsIndex)) {
         region.nodes = orderBy(region.nodes, "locationName");
      }
      return {
         regionTree: treeData,
         regionsIndex: finalRegionsIndex,
         locationsIndex: locationsIndex,
         locationsByRegion: locationsByRegion,
      };
   }

   private search(
      locationHierarchy: Array<RegionWithNodes>,
      search: string,
      indexes: {
         regions: Record<number, RegionWithNodes>;
         locationsByRegion: Record<number, Array<LocationWithNodes>>;
         locations: Record<number, LocationWithNodes>;
      },
   ): Array<RegionWithNodes> {
      const tempObj: Record<number, RegionWithNodes> = {}; //we use tempObj so that we can flag the same region if needed and not get duplicates
      //first check which regions were explicity searched
      for (const region of locationHierarchy) {
         //checks if they are explicity searched
         if (region.regionName.toLowerCase().includes(search.toLowerCase())) {
            tempObj[region.regionID] = region;
            tempObj[region.regionID].explicitlySearched = true;
         }
         //checks if they are selected, if they are we should include them
         if (region.selected === true) {
            tempObj[region.regionID] = region;
         }
      }
      //checkParent recursively goes up and sets all regions that are parents so the tree stays preserved
      const checkParent = (region) => {
         const parent = indexes.regions[region.parentRegionID];
         if (region.parentRegionID === 0 || !parent) return;
         //we only set it if it hasn't already been set before (this is to make sure we don't erase explicity being searched)
         tempObj[parent.regionID] ??= parent;
         checkParent(parent);
      };
      Object.values(tempObj).forEach(checkParent);
      //if a location found via the search then we must include it's region hierarchy
      Object.values(indexes.locations)
         .filter((location) => location.regionID > 0)
         .forEach((location) => {
            const region = indexes.regions[location.regionID];
            //add it's parent if we don't have it.
            tempObj[location.regionID] ??= region;
            checkParent(region);
         });
      //used when adding child regions so that those child regions can have all of their locations show up
      const addAllLocationsToNode = (node) => {
         if (node.region !== true || !indexes.locationsByRegion[node.regionID]) return;
         //override the search and include that location if it doesn't already exist on that node
         for (const loc of indexes.locationsByRegion[node.regionID]) {
            const found = node.nodes?.some((kid) => kid.locationID === loc.locationID);
            if (!found && node.nodes) {
               node.nodes.push(loc);
            }
         }
      };
      //recursive function that helps us add region's descendants
      const addChildRegions = (node) => {
         if (node.region !== true) return;
         tempObj[node.regionID] ??= indexes.regions[node.regionID];
         addAllLocationsToNode(tempObj[node.regionID]);
         node.nodes.forEach(addChildRegions);
      };
      //when searching if they explicity searched a region then we should show all sub regions and all locations for both the original region and the sub regions
      Object.values(tempObj)
         .filter((region) => region.explicitlySearched === true)
         .forEach(addChildRegions);
      //converts from obj to an array so we can return it in the right format :)
      return orderBy(Object.values(tempObj), "regionName");
   }
}
