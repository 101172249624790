import { Injectable, inject } from "@angular/core";
import type { PartLogDataViewerViewModel } from "src/app/parts/components/part-logs-data-viewer/part-log-date-viewer.model";
import type { PartLogEntity } from "src/app/parts/components/shared/services/logs-api/part-log-api.models";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({
   providedIn: "root",
})
export class PartLogDataViewerViewModelBuilderService {
   private readonly manageUser = inject(ManageUser);

   public buildPartLogDataViewerViewModel(
      partLog: PartLogEntity,
      hasReason: boolean = false,
      searchTask: string = "",
   ): PartLogDataViewerViewModel {
      const viewModel: PartLogDataViewerViewModel = {
         hasReason: hasReason,
         searchTask: searchTask,
         ...partLog,
      };

      return viewModel;
   }
}
