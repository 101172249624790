import { inject, Injectable } from "@angular/core";
import { ColumnKeys } from "src/app/shared/data-viewer/column-builder";
import type { WrSubmissionDataViewerModel } from "src/app/tasks/components/shared/services/w-r-submissions-view-model/models/data-viewer-model";
import type { WorkRequestSubmissionEntity } from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.models";
import type { WorkRequestSubmissionViewModel } from "src/app/tasks/components/workRequests/work-request-submission-modal/work-request-submission-data-viewer.model";
import { WorkRequestSubmissionViewModelMapperService } from "src/app/tasks/services/work-request-submission-view-model-factory/mappers/work-request-submission-view-model-mapper.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable({
   providedIn: "root",
})
export class WorkRequestSubmissionViewModelBuilderService {
   private readonly workRequestSubmissionViewModelMapperService = inject(
      WorkRequestSubmissionViewModelMapperService,
   );

   private readonly manageUser = inject(ManageUser);

   public buildDataViewerViewModel(
      submission: WorkRequestSubmissionEntity,
   ): WrSubmissionDataViewerModel {
      return {
         [ColumnKeys.WR_SUBMISSION_STATUS]: submission.status,
         [ColumnKeys.WR_SUBMISSION_NAME]: submission?.requestTitle ?? "",
         [ColumnKeys.WR_SUBMISSION_ID]: submission.workRequestSubmissionId,
         [ColumnKeys.SUBMITTED_BY]: submission.requestInformation?.requesterName ?? "",
         [ColumnKeys.SUBMITTED_ON]: submission.createdAt ?? 0,
         [ColumnKeys.REVIEWER]: this.manageUser.getUserFullName(
            submission.reviewedByUserId ?? -1,
         ),
         [ColumnKeys.ASSET_NAME]: submission.assetID ?? 0,
         [ColumnKeys.LOCATION_ID]: submission.locationID ?? 0,
      };
   }

   public buildWorkRequestSubmissionViewModel(
      workRequestSubmission: WorkRequestSubmissionEntity,
   ): WorkRequestSubmissionViewModel {
      const mapper = this.workRequestSubmissionViewModelMapperService;

      const viewModel: WorkRequestSubmissionViewModel = {
         ...workRequestSubmission,
         workRequestSubmissionModalTitle:
            mapper.getWorkRequestSubmissionModalTitle(workRequestSubmission),
         requestTitle: mapper.getRequestTitle(workRequestSubmission),
         requestDescription: mapper.getRequestDescription(workRequestSubmission),
         submittedOn: mapper.getSubmittedOn(workRequestSubmission),
         portalCreatedFromName: mapper.getPortalCreatedFromName(workRequestSubmission),
         pictures: mapper.getPictures(workRequestSubmission),
         documents: mapper.getDocuments(workRequestSubmission),
         isRejected: mapper.getIsRejected(workRequestSubmission),
         reviewedByUserName: mapper.getReviewedByUserName(workRequestSubmission),
         partsRequestedInfo: mapper.getPartsRequestedInfo(workRequestSubmission),
      };

      return viewModel;
   }
}
