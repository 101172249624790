import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { ParamsService } from "src/app/shared/services/params.service";

@Component({
   selector: "pick-custom-list",
   templateUrl: "./pickCustomList.modal.component.html",
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      IconComponent,
      BasicModalFooterComponent,
      IconAlias,
      OrderByPipe,
   ],
})
export class PickCustomList implements OnInit {
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public resolve;
   public modalInstance;
   public message;
   public title;
   public list;

   private readonly paramsService = inject(ParamsService);

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.data.message;
      this.title = this.resolve.data.title;
      this.list = this.resolve.data.list;
   }

   close = () => {
      this.modalInstance.close(0);
   };

   submit = (val) => {
      this.modalInstance.close(val);
   };

   dismiss = () => {
      this.modalInstance.close();
   };
}
