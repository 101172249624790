<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <div class="header-info-panel">
            <span>{{ message }}</span>
            <lim-ui-icon-button
               icon="circleQuestionRegular"
               (click)="openHelp()"
               placement="bottom"
               iconSize="small"
            />
         </div>
      </lim-ui-info-panel>

      <!-- What information to update? -->
      <lim-ui-panel>
         <span class="selection-panel">
            <div class="lim-ui-fonts-body-semi-bold title">
               {{ lang().UpdateRelatedPMsWhatInfoWouldYouLikeToUpdate }}
            </div>
            <div class="selection-panel-items">
               <lim-ui-checkbox
                  [(model)]="syncName"
                  label="{{ lang().UpdateRelatedPMsName }}"
               />
               <lim-ui-checkbox
                  [(model)]="syncRecurrances"
                  label="{{ lang().UpdateRelatedPMsSchedules }}"
               />
               <lim-ui-checkbox
                  [(model)]="syncAssignments"
                  label="{{ lang().UpdateRelatedPMsAssignedTo }}"
               />
               <lim-ui-checkbox
                  [(model)]="syncParts"
                  label="{{ lang().UpdateRelatedPMsParts }}"
               />
               <lim-ui-checkbox
                  [(model)]="syncItems"
                  label="{{ lang().UpdateRelatedPMsInstructions }}"
               />
               <lim-ui-checkbox
                  [(model)]="syncSettings"
                  label="{{ lang().UpdateRelatedPMsSettings }}"
               />
               <lim-ui-checkbox
                  [(model)]="syncDescription"
                  label="{{ lang().UpdateRelatedPMsPMDescription }}"
               />
            </div>
         </span>
      </lim-ui-panel>

      @if (updateMsg || loadingBar) {
         <lim-ui-info-panel>
            @if (updateMsg) {
               <div class="update-message-loader">
                  {{ lang().WakingUpHamsters }}
                  <span [limbleHtml]="lang().ThisMayTakeAMoment"> </span>
                  <span class="ending-message-loader"
                     >{{ at }} {{ lang().ofStr }} {{ end }} {{ lang().completed }}</span
                  >
               </div>
            }

            @if (loadingBar) {
               <div>
                  <div class="loading-icon">
                     <lim-ui-loading-animation />
                  </div>
               </div>
            }
         </lim-ui-info-panel>
      }

      <!-- PM List -->
      <lim-ui-panel [showWatermark]="false" [useCustomTitle]="true">
         <div class="pm-description-panel-header" panelCustomTitle>
            <span>
               {{ lang().UpdateRelatedPMsPMDescription }}
            </span>
         </div>
         <div buttons>
            <lim-ui-secondary-button (click)="linkNewPMs()">
               {{ lang().CreateNewPMRelationsTitle }}
            </lim-ui-secondary-button>
         </div>
         <div class="pm-description-panel-content">
            @if (thisLocationTemplates.length > 0) {
               <lim-ui-selection-controls
                  selectAll="Select All"
                  (handleSelectAll)="selectAllThisLocation()"
                  deselectAll="Deselect All"
                  (handleDeselectAll)="deselectAllThisLocation()"
               />
            }
            @for (
               template of thisLocationTemplates
                  | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage
                  | orderBy: "checklistName";
               track template
            ) {
               <div class="lim-ui-list-item">
                  <div class="lim-ui-list-item-content">
                     <div class="icon-with-text">
                        <lim-ui-checkbox
                           class="inline-display"
                           [(model)]="template.selected"
                        />

                        <lim-ui-icon icon="wrench" iconSize="small" />

                        <!-- Task -->
                        <a
                           class="cursor"
                           [limbleHtml]="template?.checklistName"
                           (click)="popTask(template)"
                        ></a>

                        <!-- Asset -->
                        @if (assets.get(template.assetID); as asset) {
                           -
                           <lim-ui-icon icon="cube" iconSize="small" />
                           <a
                              class="cursor green-color-link"
                              [limbleHtml]="asset.assetName"
                              (click)="popAsset(template)"
                           ></a>
                        }

                        <!-- Location -->
                        @if (template?.locationName) {
                           -
                           <lim-ui-icon icon="houseChimney" iconSize="small" />
                           {{ template?.locationName }}
                        }
                     </div>
                     <div>
                        <lim-ui-minimal-icon-button
                           icon="linkSlash"
                           iconColor="danger"
                           [superMinimal]="true"
                           (click)="breakRelation(template)"
                           limUiTooltip="{{ lang().BreakPMRelationsTooltip }}"
                        />
                     </div>
                  </div>
               </div>
            }
            @if (thisLocationTemplates.length == 0) {
               <div class="lim-ui-list-item">
                  {{ lang().ThereAreNoRelatedPMsAt }}
                  <span [limbleHtml]="locationName"></span>
               </div>
            }
            @if (thisLocationTemplates.length > itemsPerPage) {
               <lim-ui-pagination
                  [maxSize]="5"
                  [rotate]="true"
                  [pageSize]="itemsPerPage"
                  [collectionSize]="thisLocationTemplates.length"
                  [(page)]="page"
               />
            }
         </div>
         <!-- Related PM Visibility Toggle -->
         @if (numOfLocations > 1) {
            <lim-ui-minimal-button class="related" (click)="extraTasks()">
               {{ lang().ViewRelatedPMTemplatesOutsideOf }}
               {{ locationName }}
               <lim-ui-icon
                  [icon]="showExtraTasks ? 'angleUp' : 'angleDown'"
                  iconSize="small"
               />
            </lim-ui-minimal-button>
         }

         <!-- Related PM List -->
         @if (showExtraTasks) {
            @if (otherLocationTemplates.length > 0) {
               <lim-ui-selection-controls
                  selectAll="Select All"
                  (handleSelectAll)="selectAllOtherLocations()"
                  deselectAll="Deselect All"
                  (handleDeselectAll)="deselectAllOtherLocations()"
               />
            }
            <div>
               @for (
                  task of otherLocationTemplates | orderBy: "checklistName";
                  track task
               ) {
                  <div class="lim-ui-list-item">
                     <div class="lim-ui-list-item-content">
                        <div class="icon-with-text">
                           <lim-ui-checkbox
                              class="inline-display"
                              [(model)]="task.selected"
                           />

                           <lim-ui-icon icon="wrench" iconSize="small" />

                           <!-- Task -->
                           <a
                              class="cursor"
                              [limbleHtml]="task?.checklistName"
                              (click)="popTask(task)"
                           ></a>

                           <!-- Asset -->
                           @if (assets.get(task.assetID); as asset) {
                              -
                              <lim-ui-icon icon="cube" iconSize="small" />
                              <a
                                 class="cursor green-color-link"
                                 [limbleHtml]="asset.assetName"
                                 (click)="popAsset(task)"
                              ></a>
                           }

                           <!-- Location -->
                           @if (task?.locationName) {
                              -
                              <lim-ui-icon icon="houseChimney" iconSize="small" />
                              {{ task?.locationName }}
                           }
                        </div>
                        <div>
                           <lim-ui-minimal-icon-button
                              icon="linkSlash"
                              iconColor="danger"
                              [superMinimal]="true"
                              (click)="breakRelation(task)"
                              limUiTooltip="{{ lang().BreakPMRelationsTooltip }}"
                           />
                        </div>
                     </div>
                  </div>
               }
            </div>
            @if (otherLocationTemplates.length == 0) {
               <div class="lim-ui-list-item">
                  {{ lang().ThereAreNoRelatedPMsAtOtherLocations }}
               </div>
            }
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Update"
      (successClick)="submit()"
   />
</lim-ui-modal>
