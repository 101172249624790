import { Component } from "@angular/core";
import {
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
} from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import {
   TaskTypeGroup,
   taskTypesForGroup,
} from "src/app/tasks/components/shared/services/tasks-api/task-api.models";

@Component({
   selector: "task-type-filter",
   imports: [
      DropdownClearFilterItemComponent,
      DropdownDividerComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      TranslateDirective,
   ],
   templateUrl: "./task-type-filter.component.html",
})
export class TaskTypeFilterComponent extends BaseFilterComponent {
   public handleSetTypeFilter(taskType: TaskTypeGroup): void {
      this.setState(taskType, this.getGroupName(taskType));
   }

   public handleClear() {
      this.remove.emit(this.filter());
   }

   public setState(value: TaskTypeGroup, activeLabel: string): void {
      const filter = this.filter();
      this.set.emit({
         ...filter,
         activeLabel,
         value: { [filter.key]: taskTypesForGroup(value) },
      });
   }

   // Little hack to get the enum visible in the template.
   protected get TaskTypeGroup() {
      return TaskTypeGroup;
   }

   public getGroupName(group: TaskTypeGroup): string {
      // eslint-disable-next-line default-case -- We want Typescript to check this.
      switch (group) {
         case TaskTypeGroup.PlannedMaintenances:
            return this.translateService.instant("PMs");
         case TaskTypeGroup.PlannedWOs:
            return this.translateService.instant("PlannedWOs");
         case TaskTypeGroup.UnplannedWOs:
            return this.translateService.instant("UnplannedWOs");
         case TaskTypeGroup.WorkRequests:
            return this.translateService.instant("WorkRequests");
         case TaskTypeGroup.PartThresholds:
            return this.translateService.instant("PartThreshold");
      }
      throw new Error(`Unexpected task type group: ${group}`);
   }
}
