import type { AfterViewInit, OnInit } from "@angular/core";
import {
   inject,
   Component,
   ViewChild,
   computed,
   input,
   ChangeDetectorRef,
   output,
} from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { isNativeMobileApp } from "@limblecmms/lim-ui";
import { debounceTime, distinctUntilChanged, from } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { CreateAssetSelection } from "src/app/assets/types/create-asset-selection.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationHierarchyService } from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy.service";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { BetterDate } from "src/app/shared/services/betterDate";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { Lookup } from "src/app/shared/utils/lookup";
import type { PermissionID } from "src/app/users/schemata/users/self/credentials/permission.enum";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type AssetHierarchyNode = HierarchyNode & {
   assetID?: number;
   uniqueID?: string;
};

@Component({
   selector: "pick-assets-hierarchy",
   templateUrl: "./pick-assets-hierarchy.component.html",
   styleUrls: ["./pick-assets-hierarchy.component.scss"],
   imports: [HierarchyContainerLegacy],
})
export class PickAssetsHierarchyComponent implements OnInit, AfterViewInit {
   @ViewChild("hierarchy") private readonly hierarchy?: HierarchyContainerLegacy;

   public singleLocation = input(0, {
      transform: (value: number | undefined) => value ?? 0,
   });
   public readonly selectOne = input<boolean>(false);
   public readonly restrictToCred = input<boolean>(false);
   public readonly allowPickLocation = input<boolean>(false);
   public readonly preselectedAssetIDs = input<Array<number>>([]);
   public readonly hiddenAssetIDs = input<Array<number>>([]);
   public readonly search = input<string>("");
   public readonly extraCredCheck = input<PermissionID | null>(null);
   public readonly autoSelectChildren = input<boolean>(false);
   /**
    * Emits to indicate that the user made a selection and would like to submit
    * the containing form. This commonly occurs when the user double clicks on
    * an asset.
    */
   public readonly autoSubmit = output();

   public locations;
   public assets: Lookup<"assetID", Asset> = new Lookup("assetID");
   public nodesUniqueIDIndex;
   public selection: CreateAssetSelection;
   public startCollapsed;
   public readonly inMobileApp = isNativeMobileApp();
   public locLength;
   public data: Array<AssetHierarchyNode> = [];
   public useParentTaskAsset;
   public assetsSearchHints: Map<number, { searchHint: string; searchFound: boolean }> =
      new Map();
   public assetNodes: Map<number, AssetHierarchyNode> = new Map();
   public hierarchyOptions: HierarchyOptions;
   public originalAssets: Lookup<"assetID", Asset> = new Lookup("assetID");
   public selectedAssetNode: AssetHierarchyNode | null = null;

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAsset = inject(ManageAsset);
   private readonly credService = inject(CredService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUser = inject(ManageUser);
   private readonly locationHierarchyService = inject(LocationHierarchyService);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly manageLang = inject(ManageLang);
   private readonly changeDetectorRef = inject(ChangeDetectorRef);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public readonly isPlgSetUpWorkOrderEnabled = toSignal(
      from(this.legacyLaunchFlagsService.isEnabled(Flags.PLG_SETUP_WORK_ORDER)),
      { initialValue: false },
   );

   public constructor() {
      this.hierarchyOptions = {
         idKey: "assetID",
         selection: {
            singleSelection: true,
         },
         nodeButtons: [
            {
               tooltip: this.lang().ViewThisAsset,
               clickFunction: this.runPopAsset.bind(this),
               permissionNumber: this.credService.Permissions.ViewLookupAnAsset,
               permissionValidated: true,
               text: this.lang().View,
            },
         ],
         onSelect: (selection) => {
            this.selectedAssetNode = selection;
            this.deselectNonTreeProperties();
            if (selection.selected && this.autoSelectChildren()) {
               this.hierarchy?.selectChildren(selection);
            }
         },
         deselectAllAssetNodes: this.deselectAllAssetNodes.bind(this),
         submit: () => {
            this.autoSubmit.emit();
         },
      };
      this.selection = null;
      toObservable(this.search)
         .pipe(debounceTime(500), distinctUntilChanged())
         .subscribe(() => {
            this.buildHierView();
            this.changeDetectorRef.detectChanges();
         });
   }

   public ngOnInit() {
      this.locations = {};
      this.nodesUniqueIDIndex = {};
      this.useParentTaskAsset = false;

      if (this.manageUser.getCurrentUser().userInfo.customerAssetsStartCollapsed == 1) {
         this.startCollapsed = true;
      } else {
         this.startCollapsed = false;
      }

      if (
         this.hierarchyOptions.selection &&
         (this.preselectedAssetIDs().length > 0 || !this.selectOne())
      ) {
         this.hierarchyOptions.selection.singleSelection = false;
      }

      this.buildHierView(true);
   }

   public ngAfterViewInit() {
      if (this.preselectedAssetIDs() && this.singleLocation()) {
         this.buildPreselectedHierarchy();
      }
   }

   buildPreselectedHierarchy = () => {
      //for each assetID, set that asset to be 'selected' and have its parents be collapsed = false
      const assetParentIDsToExpand: Set<number> = new Set();
      for (const assetID of this.preselectedAssetIDs()) {
         const asset = this.manageAsset.getAsset(assetID);
         const assetNode = this.getAssetNode(assetID);
         if (!assetNode) {
            continue;
         }
         //if the asset has a parent/grandparent, etc, expand the tree to the point of the selected asset
         if (asset?.parentAssetID) {
            const parentAssetIDs = this.getParentIDsToTopOfTree(asset.parentAssetID);
            for (const parentAssetID of parentAssetIDs) {
               assetParentIDsToExpand.add(parentAssetID);
            }
         }
         //now set the node to be selected
         this.hierarchy?.selectNode(assetNode);
      }

      for (const parentAssetID of assetParentIDsToExpand) {
         const node = this.assetNodes.get(parentAssetID);
         if (!node) {
            continue;
         }
         this.hierarchy?.collapseNode(node);
      }
   };

   private getParentIDsToTopOfTree(
      parentAssetID: number,
      parentIDs: Array<number> = [],
   ): Array<number> {
      parentIDs.push(parentAssetID);
      if (parentAssetID !== 0) {
         const currentAsset = this.manageAsset.getAsset(parentAssetID);
         if (typeof currentAsset?.parentAssetID !== "number") {
            return parentIDs;
         }

         this.getParentIDsToTopOfTree(currentAsset.parentAssetID, parentIDs);
      }
      return parentIDs;
   }

   buildHierView = (initialBuild = false) => {
      this.locations = [];
      let regions;
      let regionsIndex = {};

      if (this.singleLocation() > 0) {
         const locationsIndex = this.manageLocation.getLocationsIndex();
         if (locationsIndex[this.singleLocation()] === undefined) {
            return; //error catch if the location they are trying to look at doesn't exist
         }
         this.locations.push(locationsIndex[this.singleLocation()]);
      } else {
         this.locations = this.manageLocation.getLocations() || [];
      }

      if (this.restrictToCred() === true) {
         const tempArr: any = []; //filter to only locations where they have the manage assets credential
         for (const location of this.locations) {
            const extraCred = this.extraCredCheck();
            if (
               this.credService.isAuthorized(
                  location.locationID,
                  this.credService.Permissions.ChangePartAssetAssociations,
               ) ||
               (extraCred &&
                  this.credService.isAuthorized(location.locationID, extraCred))
            ) {
               tempArr.push(location);
            }
         }
         this.locations = tempArr;
      }

      const locationIDs: any = [];
      if (this.singleLocation()) {
         locationIDs.push(this.singleLocation());
      } else {
         for (const location of this.locations) {
            locationIDs.push(location.locationID);
         }
      }

      if (this.manageLocation.getRegions().length > 0) {
         //they are using regions so we have to behave a little differently
         const rst = this.locationHierarchyService.buildHierarchy({
            locations: this.locations,
            regions: this.manageLocation.getRegions(),
            search: this.search(),
            filterRegions: false,
            filterLocations: false,
            alwaysReturnRegions: false,
            totalLocationCount: this.manageLocation.getLocations().length,
         });
         regions = rst.regionTree;
         regionsIndex = rst.regionsIndex;
      }

      this.assets = this.manageAsset.getAssets().filter((asset) => {
         return (
            asset.assetDeleted === 0 &&
            locationIDs.includes(asset.locationID) &&
            !this.hiddenAssetIDs().includes(asset.assetID)
         );
      });
      this.originalAssets = this.assets;
      if (initialBuild) {
         this.assetNodes = new Map(
            [...this.assets.entries()].map(([assetID, asset]) => [
               assetID,
               {
                  icon: "cube",
                  collapsed: true,
                  selected: false,
                  nodes: [],
                  title: String(asset.assetName),
                  displayButtons: true,
                  assetID: asset.assetID,
               },
            ]),
         );
      }

      const currentUser = this.manageUser.getCurrentUser();
      if (currentUser.userInfo.customerPickingAssetsIsOrderedBy == 1) {
         this.assets = this.assets.orderBy("assetOrder");
      } else {
         this.assets = this.assets.orderBy("assetName");
      }
      for (const asset of this.assets) {
         if (!this.search().length) {
            this.assetsSearchHints.set(asset.assetID, {
               searchHint: "",
               searchFound: false,
            });
         }
      }

      const tempLoc: any = [];
      this.locLength = 0;
      for (const location of this.locations) {
         tempLoc[location.locationID] = location;
         this.locLength++;
      }
      this.locations = tempLoc; //basically converting to lookup;

      this.data = []; //prepare the tree structure

      if (this.locLength > 1) {
         for (const key in this.locations) {
            this.locations[key].nodes = [];
            this.locations[key].title = this.locations[key].locationName;
            this.locations[key].dataType = "location";
            this.locations[key].icon = "houseChimney";
            this.locations[key].unselectable = true;
         }

         for (const asset of this.assets) {
            const assetNode = this.getAssetNode(asset.assetID);
            if (!assetNode) {
               continue;
            }
            //this loop must run before the next loop so all empty node arrays are built
            assetNode.nodes = [];
         }
         for (const asset of this.assets) {
            const assetNode = this.getAssetNode(asset.assetID);
            if (!assetNode) {
               continue;
            }
            if (asset.parentAssetID == 0) {
               if (this.locations[asset.locationID]) {
                  this.locations[asset.locationID].nodes.push(assetNode);
               }
            } else if (asset.parentAssetID) {
               const parentAssetNode = this.getAssetNode(asset.parentAssetID);

               if (!parentAssetNode?.nodes) {
                  continue;
               }
               parentAssetNode.nodes.push(assetNode);
            }
         }
      } else {
         for (const asset of this.assets) {
            //this loop must run before the next loop so all empty node arrays are built
            const assetNode = this.getAssetNode(asset.assetID);
            if (!assetNode) {
               continue;
            }
            assetNode.nodes = [];
         }

         for (const asset of this.assets) {
            if (asset.parentAssetID && asset.parentAssetID > 0) {
               const assetNode = this.getAssetNode(asset.assetID);
               const parentAssetNode = this.getAssetNode(asset.parentAssetID);

               if (asset.parentAssetID) {
                  if (!parentAssetNode?.nodes || !assetNode) {
                     continue;
                  }
                  parentAssetNode.nodes.push(assetNode);
               }
            }
         }
      }

      //filter the assets down if needed to just the search bar
      if (this.search().length > 1) {
         this.assets = this.manageFilters.filterAssetsToNameAndTextFields_refactor(
            this.assets,
            this.manageAsset.getFields(),
            this.manageAsset.getFieldValues(),
            this.manageAsset.getFieldValueFiles(),
            this.assetsSearchHints,
            {
               search: this.search(),
               hier: true,
               field: false,
               includeChildren: true,
            },
            this.manageAsset,
            this.betterDate,
         );

         //after the search we need to refilter the nodes because some nodes should not exist (they weren't found in the search, but nodes persisted from before).  It sucks we have to run this again, but the search function needs the nodes to perform the search and its much faster to rebuild this way then loop through the asset's nodes of the pre built data set
         const assetsIndex2 = {};
         for (const asset of this.assets) {
            assetsIndex2[asset.assetID] = asset;

            const assetNode = this.getAssetNode(asset.assetID);
            if (!assetNode?.nodes) {
               continue;
            }
            const assetSearchHint = this.assetsSearchHints.get(asset.assetID);
            assetNode.nodes = [];
            assetNode.searchFound = Boolean(assetSearchHint?.searchFound);
            assetNode.searchHint = assetSearchHint?.searchHint ?? "";
         }

         for (const key in this.locations) {
            this.locations[key].nodes = [];
         }

         for (const asset of this.assets) {
            const assetNode = this.assetNodes.get(asset.assetID);
            if (!assetNode?.nodes) {
               continue;
            }

            if (asset.parentAssetID && asset.parentAssetID > 0) {
               if (assetsIndex2[asset.parentAssetID]) {
                  const parentAssetNode = this.assetNodes.get(asset.parentAssetID);
                  if (!parentAssetNode?.nodes) {
                     continue;
                  }
                  parentAssetNode.nodes.push(assetNode);
               }
            } else if (this.locations[asset.locationID]) {
               this.locations[asset.locationID].nodes.push(assetNode);
            }
         }
      }

      //we need to set Unique IDs because we will use them to do quick lookups via jquery since too many watchs exist
      for (const key in this.locations) {
         this.locations[key].uniqueID = `PICK-LL${this.locations[key].locationID}`;
         this.nodesUniqueIDIndex[`PICK-LL${this.locations[key].locationID}`] =
            this.locations[key]; //used for lookup

         if (this.search() !== undefined && this.search().length > 1) {
            this.locations[key].collapsed = false;
         } else {
            this.locations[key].collapsed = true;
         }
         if (initialBuild) {
            this.locations[key].selected = false;
         }
      }

      for (const asset of this.assets) {
         const assetNode = this.getAssetNode(asset.assetID);
         if (!assetNode?.nodes) {
            continue;
         }

         assetNode.uniqueID = `PICK-AA${asset.assetID}`;
         this.nodesUniqueIDIndex[`PICK-AA${asset.assetID}`] = assetNode; //used for lookup
         assetNode.assetID = asset.assetID;
         assetNode.locationID = asset.locationID;

         if (this.search() !== undefined && this.search().length > 1) {
            assetNode.collapsed = false;
         } else if (this.startCollapsed) {
            assetNode.collapsed = true;
         } else {
            assetNode.collapsed = false;
         }
         if (initialBuild) {
            assetNode.selected = false;
         }
      }

      for (const index in regionsIndex) {
         const region: any = regionsIndex[index];
         region.nodeDisplay = region.regionName;
         region.title = region.regionName;
         region.icon = "earthAmericas";
         region.uniqueID = `PICK-RR${region.regionID}`;
         region.childrenBuilt = false;
         region.unselectable = true;
         this.nodesUniqueIDIndex[`PICK-RR${region.regionID}`] = region; //used for lookup
         region.showPop = "hide";

         if (
            (this.search() !== undefined && this.search().length > 1) || //if we are searching start not collapsed
            (!this.startCollapsed && this.manageLocation.getRegions().length <= 3) //if they are starting collapsed and they don' thave many regions then let's show them
         ) {
            region.collapsed = false;
         } else {
            region.collapsed = true;
         }
         if (initialBuild) {
            region.selected = false;
         }
      }

      if (this.locLength > 1) {
         if (!this.assets) {
            return;
         }

         if (this.manageLocation.getRegions().length > 0) {
            //they are doing regions so let's show those correctly...

            //first we need to remove any locations that don't have assets on them or any regions that are empty
            this.manageFilters.cleanupEmptyRegionsAndLocations(regionsIndex);
            this.manageFilters.removeEmptyRegions(regions);

            //lastly add the top level regions
            for (const region of regions) {
               if (region.nodes.length > 0) {
                  this.data.push(region);
               }
            }
         } else {
            //they aren't doing regions so process it like normal locations
            for (const key in this.locations) {
               if (this.search() !== undefined && this.search().length > 1) {
                  if (this.locations[key].nodes.length > 0) {
                     this.data.push(this.locations[key]);
                  }
               } else {
                  this.data.push(this.locations[key]);
               }
            }
            this.data = orderBy(this.data, "locationName");
         }
      } else {
         for (const asset of this.assets) {
            if (asset.parentAssetID == 0) {
               const assetNode = this.getAssetNode(asset.assetID);
               if (!assetNode) {
                  continue;
               }
               this.data.push(assetNode);
            }
         }
      }

      if (this.isPlgSetUpWorkOrderEnabled() && this.allowPickLocation()) {
         this.addEmptyAssetNode();
      }
   };

   collapseAll = () => {
      this.hierarchy?.collapseAllNodes();
   };

   clearAssetSelection = () => {
      this.hierarchy?.deselectAllNodes();
   };

   markAllAssetSelection = () => {
      this.hierarchy?.selectAllNodes();
      for (const key in this.locations) {
         this.locations[key].collapsed = false;
      }

      for (const asset of this.assets) {
         const assetNode = this.getAssetNode(asset.assetID);
         if (!assetNode) {
            continue;
         }
         assetNode.selected = true;
      }
   };

   public deselectNonTreeProperties() {
      this.useParentTaskAsset = false;
   }

   public getSelectedUnsureAssetUnderLocation(): HierarchyNode | undefined {
      return this.hierarchy?.selectedUnsureAssetUnderLocation;
   }

   private addEmptyAssetNode() {
      if (this.data) {
         this.data.map((node) => {
            node.nodes = [
               {
                  icon: "cubeRegular",
                  collapsed: true,
                  selected: false,
                  nodes: [],
                  title: this.lang().CreateWithoutAnAssignedAsset,
                  displayButtons: true,
                  locationID: node?.locationID ?? 0,
                  assetID: node?.assetID ?? 0,
               },
               ...node.nodes,
            ];
            return node;
         });
      }
   }

   public getAssetNode(assetID: number): AssetHierarchyNode | undefined {
      return this.assetNodes.get(assetID);
   }

   private deselectAllAssetNodes() {
      if (!this.assetNodes) {
         return;
      }

      //prevents loop dependency if an asset is a parent to itself
      const visited = new Set<number>();

      for (const [, item] of this.assetNodes.entries()) {
         item.selected = false;
         if (item.nodes?.length > 0) {
            this.deselectChildren(item, visited);
         }
      }
   }

   private deselectChildren(node, visited: Set<number>) {
      //prevents loop dependency if an asset is a parent to itself
      if (visited.has(node.assetID)) {
         return;
      }

      visited.add(node.assetID);

      if (node.nodes?.length > 0) {
         for (const childNode of node.nodes) {
            childNode.selected = false;
            if (childNode.nodes?.length > 0) {
               this.deselectChildren(childNode, visited);
            }
         }
      }
   }

   public runPopAsset = (assetNode: AssetHierarchyNode) => {
      if (!assetNode.assetID) {
         return;
      }
      this.manageAsset.popAsset(assetNode.assetID);
   };
}
