import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import moment from "moment";
import type { Observable } from "rxjs";
import { BehaviorSubject, map, Subject } from "rxjs";
import type { UsersAndTeamsWithSelection } from "src/app/dashboards/custom-dashboards/custom-dashboard/dashboard-sharing/dashboard-sharing.types";
import { WidgetCacheService } from "src/app/dashboards/custom-dashboards/custom-dashboard/printable-dashboard/printable-widget/widget-cache.service";
import type {
   CustomDashboard,
   Widget,
   WidgetDefinition,
} from "src/app/dashboards/custom-dashboards/customDashboard.types";
import { WidgetService } from "src/app/dashboards/widgets/widget/widget.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";
import graphColors from "src/assets/styles/graph-colors.json";
import { environment } from "src/environments/environment";

export type Color = {
   name: string;
   displayName: string;
   tileClass: string;
   colorCode: string;
};

export type TaskTimeInStatus = {
   statusID: number;
   averageDuration: number;
   medianDuration: number;
};

@Injectable({ providedIn: "root" })
export class ManageDashboard {
   private dashboards: Lookup<"dashboardID", CustomDashboard>;
   public dashboards$: BehaviorSubject<Lookup<"dashboardID", CustomDashboard> | null>;
   private readonly axios = axios;
   public isCollapsedObs$ = new Subject();

   private readonly manageLang = inject(ManageLang);
   private readonly http = inject(HttpClient);
   private readonly manageUser = inject(ManageUser);
   private readonly customDashWidgetService = inject(WidgetService);
   private readonly widgetCacheService = inject(WidgetCacheService);

   public constructor() {
      this.dashboards = new Lookup("dashboardID");
      this.dashboards$ = new BehaviorSubject<Lookup<
         "dashboardID",
         CustomDashboard
      > | null>(null);
   }

   public fetchDashboards(): Observable<Lookup<"dashboardID", CustomDashboard>> {
      return this.http
         .get<Array<CustomDashboard>>(`${environment.flannelUrl}/dashboards/custom`)
         .pipe(
            map((answer) => {
               this.dashboards = new Lookup("dashboardID", answer);
               this.dashboards$.next(this.dashboards);
               return this.dashboards;
            }),
         );
   }

   public getDashboards(): Lookup<"dashboardID", CustomDashboard> {
      return this.dashboards;
   }

   public getDashboard(dashboardID: number): CustomDashboard | undefined {
      return this.dashboards.get(dashboardID);
   }

   public async updateCurrentItemsPosAndSize(
      widgets: Array<Widget>,
      dashboardID: number,
   ): Promise<AxiosResponse<{ success: boolean }>> {
      return this.axios.post<{ success: boolean }>(
         "phpscripts/manageDashboard.php",
         { items: widgets, dashboardID: dashboardID },
         { params: { action: "updateCurrentItemsPosAndSize" } },
      );
   }

   public async addWidget(
      widget: Omit<Widget, "widgetID">,
      name: string,
      widgetDef: WidgetDefinition,
      skipDashboardUpdate?: boolean,
   ): Promise<Widget> {
      const dashboard = this.dashboards.get(widget.dashboardID);
      if (dashboard === undefined) {
         throw new Error("Bad dashboardID");
      }
      return this.axios
         .post<{ success: true; dashboard_itemID: number } | { success: false }>(
            "phpscripts/manageDashboard.php",
            {
               name: name,
               row: widget.y,
               col: widget.x,
               sizeX: widget.cols,
               sizeY: widget.rows,
               dashboardID: widget.dashboardID,
               data: widgetDef,
            },
            { params: { action: "addWidget" } },
         )
         .then(({ data }) => {
            if (data.success === false) {
               throw new Error("Failed request");
            }
            const completedWidget = {
               ...widget,
               widgetID: data.dashboard_itemID,
            };
            if (!dashboard.widgetIDs) {
               dashboard.widgetIDs = [];
            }
            dashboard.widgetIDs.push(completedWidget.widgetID);
            if (!skipDashboardUpdate) {
               this.dashboards$.next(this.dashboards);
            }
            return completedWidget;
         });
   }

   public async updateWidgetDefinition(
      widget: Widget,
      data: WidgetDefinition,
   ): Promise<AxiosResponse<{ success: boolean }>> {
      return this.axios
         .post<{ success: boolean }>(
            "phpscripts/manageDashboard.php",
            {
               dashboard_itemID: widget.widgetID,
               data: data,
               name: data.name,
            },
            { params: { action: "updateWidget" } },
         )
         .then((answer) => {
            this.customDashWidgetService.clearWidgetContentCache(widget.widgetID);
            this.widgetCacheService.setWidgetDef(widget.widgetID, data);
            widget.widgetName = data.name;
            return answer;
         });
   }

   public async deleteWidget(
      widget: Widget,
   ): Promise<AxiosResponse<{ success: boolean }>> {
      return this.axios
         .post<{
            success: boolean;
         }>(
            "phpscripts/manageDashboard.php",
            { dashboard_itemID: widget.widgetID },
            { params: { action: "deleteWidget" } },
         )
         .then((answer) => {
            return answer;
         });
   }

   public async addDashboard(): Promise<{ success: true; dashboard: CustomDashboard }> {
      return this.axios
         .post<
            { success: false } | { success: true; dashboard: CustomDashboard }
         >("phpscripts/manageDashboard.php", {}, { params: { action: "addDashboard" } })
         .then(({ data }) => {
            if (data.success === false) {
               throw new Error("success: false");
            }
            this.dashboards.setValue(data.dashboard);
            this.dashboards$.next(this.dashboards);
            return data;
         });
   }

   public async updateDashboardName(
      dashboardID: number,
      newName: string,
   ): Promise<AxiosResponse<{ success: boolean }>> {
      const dashboard = this.dashboards.get(dashboardID);
      if (dashboard === undefined) {
         throw new Error("bad dashboardID");
      }
      return this.axios
         .post<{ success: boolean }>(
            "phpscripts/manageDashboard.php",
            {
               dashboardID: dashboardID,
               dashboardName: newName,
            },
            { params: { action: "updateDashboardName" } },
         )
         .then((answer) => {
            dashboard.dashboardName = newName;
            this.dashboards$.next(this.dashboards);
            return answer;
         });
   }

   public async deleteDashboard(
      dashboard: CustomDashboard,
   ): Promise<AxiosResponse<{ success: boolean }>> {
      return this.axios
         .post<{
            success: boolean;
         }>(
            "phpscripts/manageDashboard.php",
            { dashboardID: dashboard.dashboardID },
            { params: { action: "deleteDashboard" } },
         )
         .then((answer) => {
            this.dashboards.deleteValue(dashboard);
            this.dashboards$.next(this.dashboards);
            return answer;
         });
   }

   public async copyDashboard(dashboard: CustomDashboard): Promise<{
      success: true;
      newDashboard: CustomDashboard;
      newWidgets: Array<Widget>;
   }> {
      return this.axios
         .post<
            | { success: false }
            | { success: true; newDashboard: CustomDashboard; newWidgets: Array<Widget> }
         >("phpscripts/manageDashboard.php", { dashboardID: dashboard.dashboardID }, { params: { action: "copyDashboard" } })
         .then(({ data }) => {
            if (data.success === false) {
               throw new Error("Failed request");
            }
            const newDashboard: CustomDashboard = {
               ...data.newDashboard,
               permission: "owner",
            };
            this.dashboards.setValue(newDashboard);
            this.dashboards$.next(this.dashboards);
            return data;
         });
   }

   public async shareDashboard(
      dashboardID: number,
      currentUserID: number,
      shares: UsersAndTeamsWithSelection,
   ): Promise<void> {
      const dashboard = this.dashboards.get(dashboardID);
      if (dashboard === undefined) {
         throw new Error("Dashboard not found.");
      }
      await this.axios
         .post<{ success: boolean }>(
            "phpscripts/manageDashboard.php",
            {
               dashboardID,
               currentUserID,
               shares,
            },
            { params: { action: "shareDashboardWithTeamsAndUsers" } },
         )
         .then(({ data }) => {
            if (data.success === false) {
               throw new Error("Failed to share dashboard");
            }
         });
   }

   public async shareDashboardForReminder(
      dashboardID: number,
      currentUserID: number,
      userIDs: number[],
   ): Promise<void> {
      const dashboard = this.dashboards.get(dashboardID);
      if (dashboard === undefined) {
         throw new Error("Dashboard not found.");
      }
      await this.axios
         .post<{ success: boolean; reason?: string }>(
            "phpscripts/manageDashboard.php",
            {
               dashboardID,
               currentUserID,
               userIDs,
            },
            { params: { action: "shareDashboardForReminder" } },
         )
         .then(({ data }) => {
            if (data.success === false) {
               let message = "Failed request";
               if (data.reason) {
                  message += `: ${data.reason}`;
               }
               console.error(message);
            }
         });
   }

   public async removeShare(
      userID: number,
      dashboardID: number,
   ): Promise<{ success: true; deletedShareIDs: Array<number> }> {
      const dashboard = this.dashboards.get(dashboardID);
      if (dashboard === undefined) {
         throw new Error("bad dashboardID");
      }
      return this.axios
         .post<{ success: false } | { success: true; deletedShareIDs: Array<number> }>(
            "phpscripts/manageDashboard.php",
            {
               userID: userID,
               dashboardID: dashboard.dashboardID,
            },
            { params: { action: "removeShare" } },
         )
         .then((answer) => {
            const data = answer.data;
            if (data.success === false) {
               throw new Error("Failed to remove dashboard share");
            }
            this.dashboards$.next(this.dashboards);
            return data;
         });
   }

   public async setDashboardReminder(
      reminderID: number,
      email: string,
      sendDate,
      subject,
      message: string,
      dashboardID: number,
      reoccurType,
      reoccurFld1,
      reoccurFld2,
      reoccurFld3,
      timezone,
   ): Promise<AxiosResponse<{ success: boolean }>> {
      return this.axios.post<{ success: boolean }>(
         "phpscripts/manageDashboard.php",
         {
            reminderID,
            email,
            sendDate,
            subject,
            message,
            dashboardID,
            reoccurType,
            reoccurFld1,
            reoccurFld2,
            reoccurFld3,
            timezone,
         },
         { params: { action: "setDashboardReminder" } },
      );
   }

   public async getDashboardReminder(
      dashboardID: number,
   ): Promise<{ success: true; reminder: Array<any> }> {
      return this.axios
         .post<
            { success: false } | { success: true; reminder: Array<any> }
         >("phpscripts/manageDashboard.php", { dashboardID: dashboardID }, { params: { action: "getDashboardReminder" } })
         .then(({ data }) => {
            if (data.success === false) {
               throw new Error(
                  `Failed to get dashboard reminder for dashboardID: ${dashboardID}`,
               );
            }
            return data;
         });
   }

   /** @deprecated plaid-dashboards But good for plaid_tasks */
   findMomentStartBasedOnDateStr = (data) => {
      //now generate the string start and end of what we are going to filter the date range by.

      let start;
      let end;
      const fiscalYear = this.manageUser.getCurrentUser().userInfo.customerFiscalYear;
      let fiscalYearAdjusted = 0; //Date is saved with year, which has caused some complaints;
      //this will adjust the data so if fiscal year is changed, "This fiscal year" will always be the current fiscal year
      if (fiscalYear !== 0) {
         const newDate = new Date(fiscalYear * 1000);
         newDate.setFullYear(new Date().getFullYear());
         fiscalYearAdjusted = newDate.getTime();
         if (fiscalYearAdjusted > new Date().getTime()) {
            //check if the fiscal year starts later in the year than our current date
            newDate.setFullYear(newDate.getFullYear() - 1);
            fiscalYearAdjusted = newDate.getTime();
         }
      }
      //IMPORTANT... do not remove any of these statements.  Old customer settings can be dependant on them
      if (data.dateStr === "Today") {
         start = moment().startOf("day");
         end = moment().endOf("day");
      } else if (data.dateStr === "Yesterday") {
         start = moment().subtract(1, "days").startOf("day");
         end = moment().subtract(1, "days").endOf("day");
      } else if (data.dateStr === "Tomorrow") {
         start = moment().add(1, "days").startOf("day");
         end = moment().add(1, "days").endOf("day");
      } else if (data.dateStr === "This Week") {
         //weeks can vary depending on how a customer defines customerStartOfWorkWeek
         start = moment().startOf("week");
         end = moment().endOf("week");
      } else if (data.dateStr === "Next Week") {
         //weeks can vary depending on how a customer defines customerStartOfWorkWeek
         start = moment().add(1, "weeks").startOf("week");
         end = moment().add(1, "weeks").endOf("week");
      } else if (data.dateStr === "Last Week") {
         //weeks can vary depending on how a customer defines customerStartOfWorkWeek
         start = moment().subtract(1, "weeks").startOf("week");
         end = moment().subtract(1, "weeks").endOf("week");
      } else if (data.dateStr === "Last 2 Weeks") {
         //weeks can vary depending on how a customer defines customerStartOfWorkWeek
         start = moment().subtract(2, "weeks").startOf("week");
         end = moment().subtract(1, "weeks").endOf("week");
      } else if (data.dateStr === "Next X Weeks") {
         //weeks can vary depending on how a customer defines customerStartOfWorkWeek
         if (data.dateNextXWeeksIncludeThisWeek) {
            start = moment().startOf("week");
            end = moment()
               .add(data.dateNextWeeks - 1, "weeks")
               .endOf("week");
         } else {
            start = moment().add(1, "weeks").startOf("week");
            end = moment().add(data.dateNextWeeks, "weeks").endOf("week");
         }
      } else if (data.dateStr === "Last X Weeks") {
         //weeks can vary depending on how a customer defines customerStartOfWorkWeek
         if (data.dateLastXWeeksIncludeThisWeek) {
            start = moment()
               .subtract(data.dateLastWeeks - 1, "weeks")
               .startOf("week");
            end = moment().endOf("week");
         } else {
            start = moment().subtract(data.dateLastWeeks, "weeks").startOf("week");
            end = moment().subtract(1, "weeks").endOf("week");
         }
      } else if (data.dateStr === "This Month") {
         start = moment().startOf("month");
         end = moment().endOf("month");
      } else if (data.dateStr === "Next Month") {
         start = moment().add(1, "months").startOf("month");
         end = moment().add(1, "months").endOf("month");
      } else if (data.dateStr === "Last Month") {
         start = moment().subtract(1, "months").startOf("month");
         end = moment().subtract(1, "months").endOf("month");
      } else if (data.dateStr === "This Year") {
         start = moment().startOf("year");
         end = moment().endOf("year");
      } else if (data.dateStr === "Last Year") {
         start = moment().subtract(1, "year").startOf("year");
         end = moment().subtract(1, "year").endOf("year");
      } else if (data.dateStr === "This Fiscal Year") {
         if (fiscalYear == 0) {
            start = moment().startOf("year");
            end = moment().endOf("year");
         } else {
            start = moment(fiscalYearAdjusted).startOf("day");
            end = moment(fiscalYearAdjusted)
               .add(1, "year")
               .subtract(1, "day")
               .endOf("day");
         }
      } else if (data.dateStr === "Last Fiscal Year") {
         if (fiscalYear == 0) {
            start = moment()
               .subtract(1, "year") //for last year
               .startOf("year");
            end = moment().subtract(1, "year").endOf("year");
         } else {
            start = moment(fiscalYearAdjusted)
               .subtract(1, "year") //for last year
               .startOf("day");
            end = moment(fiscalYearAdjusted)
               .subtract(1, "year") //for last year
               .add(1, "year")
               .subtract(1, "day")
               .endOf("day");
         }
      } else if (data.dateStr === "Next X Months") {
         //weeks can vary depending on how a customer defines customerStartOfWorkWeek
         if (data.dateNextXMonthsIncludeThisMonth) {
            start = moment().startOf("month");
            end = moment()
               .add(data.dateNextMonths - 1, "months")
               .endOf("month");
         } else {
            start = moment().add(1, "months").startOf("month");
            end = moment().add(data.dateNextMonths, "months").endOf("month");
         }
      } else if (data.dateStr === "Last X Months") {
         //weeks can vary depending on how a customer defines customerStartOfWorkWeek
         if (data.dateLastXMonthsIncludeThisMonth) {
            start = moment()
               .subtract(data.dateLastMonths - 1, "months")
               .startOf("month");
            end = moment().endOf("month");
         } else {
            start = moment().subtract(data.dateLastMonths, "months").startOf("month");
            end = moment().subtract(1, "months").endOf("month");
         }
      } else if (data.dateStr === "Next 7 Days") {
         start = moment().startOf("day");
         end = moment()
            .add(7 - 1, "days")
            .endOf("day");
      } else if (data.dateStr === "Next 30 Days") {
         start = moment().startOf("day");
         end = moment()
            .add(30 - 1, "days")
            .endOf("day");
      } else if (data.dateStr === "Next 60 Days") {
         start = moment().startOf("day");
         end = moment()
            .add(60 - 1, "days")
            .endOf("day");
      } else if (data.dateStr === "Next 90 Days") {
         start = moment().startOf("day");
         end = moment()
            .add(90 - 1, "days")
            .endOf("day");
      } else if (data.dateStr === "Next 180 Days") {
         start = moment().startOf("day");
         end = moment()
            .add(180 - 1, "days")
            .endOf("day");
      } else if (data.dateStr === "Next 365 Days") {
         start = moment().startOf("day");
         end = moment()
            .add(365 - 1, "days")
            .endOf("day");
      } else if (data.dateStr === "Next X Days") {
         if (data.dateNextXDaysIncludeToday) {
            start = moment().startOf("day");
            end = moment()
               .add(data.dateNextDays - 1, "days")
               .endOf("day");
         } else {
            start = moment().add(1, "days").startOf("day");
            end = moment().add(data.dateNextDays, "days").endOf("day");
         }
      } else if (data.dateStr === "Last 7 Days") {
         start = moment()
            .subtract(7 - 1, "days")
            .startOf("day");
         end = moment().endOf("day");
      } else if (data.dateStr === "Last 30 Days") {
         start = moment()
            .subtract(30 - 1, "days")
            .startOf("day");
         end = moment().endOf("day");
      } else if (data.dateStr === "Last 60 Days") {
         start = moment()
            .subtract(60 - 1, "days")
            .startOf("day");
         end = moment().endOf("day");
      } else if (data.dateStr === "Last 90 Days") {
         start = moment()
            .subtract(90 - 1, "days")
            .startOf("day");
         end = moment().endOf("day");
      } else if (data.dateStr === "Last 180 Days") {
         start = moment()
            .subtract(180 - 1, "days")
            .startOf("day");
         end = moment().endOf("day");
      } else if (data.dateStr === "Last 365 Days") {
         start = moment()
            .subtract(365 - 1, "days")
            .startOf("day");
         end = moment().endOf("day");
      } else if (data.dateStr === "Last X Days") {
         if (data.dateLastXDaysIncludeToday) {
            start = moment()
               .subtract(data.dateLastDays - 1, "days")
               .startOf("day");
            end = moment().endOf("day");
         } else {
            start = moment().subtract(data.dateLastDays, "days").startOf("day");
            end = moment().subtract(1, "days").endOf("day");
         }
      } else if (data.dateStr === "Last X Hours") {
         if (data.dateLastXHoursIncludeToday) {
            start = moment().subtract(data.dateLastHours - 1, "hours");
            end = moment().endOf("day");
         } else {
            start = moment().subtract(data.dateLastHours, "hours");
            end = moment().subtract(1, "days").endOf("day");
         }
      } else if (data.dateStr === "Custom Date Range") {
         start = moment(data.dateRange1).startOf("day");
         end = moment(data.dateRange2).endOf("day");
      }

      return { start: start, end: end };
   };

   public colorSets(): {
      arr: Array<Color>;
      index: { [index: string]: Color };
   } {
      const lang = this.manageLang.lang() ?? {};
      const colors: {
         arr: Array<Color>;
         index: { [index: string]: Color };
      } = {
         arr: [],
         index: {},
      };

      /** The bellow set globals outline the new lim-ui palette for custom dashboard widgets.
       ** Prior to UI recode, widget colors were being set in a similar (through the typescript) way using a now deleted manageTheme service.
       ** With that manageTheme file now deleted, we set global colors here until we can find a better way to pull them from lim-ui _colors.scss file directly.
       **/
      document.documentElement.style.setProperty("--lim-base-color-one", "#E62737");
      document.documentElement.style.setProperty("--lim-base-color-two", "#289E49");
      document.documentElement.style.setProperty("--lim-base-color-three", "#F29423");
      document.documentElement.style.setProperty("--lim-base-color-four", "#152232");
      document.documentElement.style.setProperty("--lim-base-color-five", "#8D8D8F");
      document.documentElement.style.setProperty("--lim-base-color-six", "#5083D5");
      document.documentElement.style.setProperty("--lim-base-color-seven", "#0A4966");
      document.documentElement.style.setProperty("--lim-base-color-eight", "#16A8D9");
      document.documentElement.style.setProperty("--lim-base-color-nine", "#BF1D89");
      document.documentElement.style.setProperty("--lim-base-color-ten", "#583798");

      colors.arr = [
         {
            name: "Red",
            displayName: `${lang.Color} 1`,
            tileClass: "tiles-red",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-one",
            ),
         },
         {
            name: "Green",
            displayName: `${lang.Color} 2`,
            tileClass: "tiles-success",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-two",
            ),
         },
         {
            name: "Orange",
            displayName: `${lang.Color} 3`,
            tileClass: "tiles-orange",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-three",
            ),
         },
         {
            name: "Dark Grey",
            displayName: `${lang.Color} 4`,
            tileClass: "tiles-inverse",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-four",
            ),
         },
         {
            name: "Light Grey",
            displayName: `${lang.Color} 5`,
            tileClass: "tiles-grey",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-five",
            ),
         },
         {
            name: "Blue",
            displayName: `${lang.Color} 6`,
            tileClass: "tiles-primary",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-six",
            ),
         },
         {
            name: "Dark Blue",
            displayName: `${lang.Color} 7`,
            tileClass: "tiles-midnightblue",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-seven",
            ),
         },
         {
            name: "Light Blue",
            displayName: `${lang.Color} 8`,
            tileClass: "tiles-info",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-eight",
            ),
         },
         {
            name: "Pink",
            displayName: `${lang.Color} 9`,
            tileClass: "tiles-pink",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-nine",
            ),
         },
         {
            name: "Purple",
            displayName: `${lang.Color} 10`,
            tileClass: "tiles-purple",
            colorCode: getComputedStyle(document.documentElement).getPropertyValue(
               "--lim-base-color-ten",
            ),
         },
      ];

      for (const color of colors.arr) {
         colors.index[color.name] = color;
      }

      return colors;
   }

   public getGraphColors() {
      return graphColors;
   }

   public async getMaintenancePerformanceData({
      startDate,
      endDate,
      locationID,
      checklistTemplateOld,
   }: {
      startDate?: number | undefined;
      endDate?: number | undefined;
      locationID?: number;
      checklistTemplateOld?: number;
   }): Promise<TaskTimeInStatus[]> {
      const response = await axios.get<TaskTimeInStatus[]>(
         `${environment.flannelUrl}/dashboard/maintenancePerformance`,
         {
            params: {
               startDate,
               endDate,
               locationID,
               checklistTemplateOld,
            },
         },
      );
      return response.data;
   }

   determineListSort = (defaultSort: string, widgetDef: WidgetDefinition): string => {
      const display = widgetDef.display;
      if (widgetDef.viewedAs !== "listView" || typeof display === "string") {
         throw new Error("Only listView widget definitions can be sorted");
      }
      const userPreferences = this.manageUser.getCurrentUser().userInfo.userUIPreferences;
      if (
         userPreferences.customDashboardSort &&
         widgetDef.widgetID in userPreferences.customDashboardSort
      ) {
         //Note that in some cases this may return a reference to a column that
         //no longer exists on the table.
         //We can't easily change this behavior, because the sortKey could be an
         //arbitrary string, and we can't match it up with a particular
         //column in this context. So we can't determine whether the key
         //references a deleted column or not. -- Derek 19 Sept 2022

         return userPreferences.customDashboardSort[widgetDef.widgetID];
      }
      return defaultSort;
   };

   updateUserUIPreferences = (dashboardID: number, sort: string) => {
      const currentUser = this.manageUser.getCurrentUser();
      if (currentUser === undefined) {
         return;
      }
      const preferences = currentUser.userInfo.userUIPreferences;
      preferences.customDashboardSort ??= {};
      preferences.customDashboardSort[dashboardID] = sort;
      this.manageUser.updateUserUIPreferences();
   };

   updateUserUIListViewPreferences = (dashboardID: number, columnData) => {
      const currentUser = this.manageUser.getCurrentUser();
      if (currentUser === undefined) {
         return;
      }
      const preferences = currentUser.userInfo.userUIPreferences;
      preferences.customDashboardListViewColumns ??= {};
      preferences.customDashboardListViewColumns[dashboardID] = columnData;
      this.manageUser.updateUserUIPreferences();
   };

   public getFirstDash(): number | undefined {
      return [...this.dashboards][0]?.dashboardID;
   }

   public async emailDashboard(options: {
      recipients: string;
      subject: string;
      message: string;
      attachment: Blob;
      dashboardID: number;
   }): Promise<void> {
      const formData = new FormData();
      formData.append("recipients", options.recipients);
      formData.append("subject", options.subject);
      formData.append("message", options.message);
      formData.append("dashboardID", options.dashboardID.toString());
      formData.append(
         "attachment",
         options.attachment,
         `emailDashboard-${options.dashboardID}.pdf`,
      );

      await axios.post(`phpscripts/manageDashboard.php`, formData, {
         params: { action: "emailDashboard" },
         headers: { "Content-Type": "multipart/form-data" },
      });
   }
}
