import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ManageUserClicks {
   protected userClicks: number = 0;

   public wasDoubleClicked(): boolean {
      return this.userClicks === 0;
   }

   // Track the number of clicks on a list so we can detect double clicks
   public handleClick(data: Iterable<any> | ArrayLike<any>): void {
      const list = Array.from(data);
      this.userClicks++;

      for (const item of list) {
         if (item.selected === true) {
            // First click so don't reset
            return;
         }
      }

      this.userClicks = 0;
   }
}
