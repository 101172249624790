import { AsyncPipe, NgClass } from "@angular/common";
import { Component, computed, forwardRef, inject, type OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TextButtonComponent,
} from "@limblecmms/lim-ui";
import {
   BehaviorSubject,
   catchError,
   combineLatest,
   EMPTY,
   filter,
   mergeMap,
   type Observable,
   scan,
   share,
   switchMap,
   tap,
} from "rxjs";
import { map } from "rxjs/operators";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import {
   DataViewerSearchComponent,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import { AlertService } from "src/app/shared/services/alert.service";
import type { ListResponse } from "src/app/shared/services/flannel-api-service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";

interface PickTaskTemplateModalViewModel {
   data: TaskTemplateEntity[];

   /**
    * The total number of items with the same search criteria.
    */
   total: number;

   /**
    * The number of items displayed in the list.
    */
   itemsDisplayed: number;

   /**
    * The number of items that can be loaded.
    * This is either the page size or the remaining items if there are less than the page size.
    */
   loadableItems: number;

   /**
    * Whether there are more items to load.
    * This uses the loadableItems to determine if there are more items to load.
    */
   hasMoreItems: boolean;

   /**
    * Whether there are no items.
    */
   hasNoItems: boolean;
}

@Component({
   selector: "pick-task-template-modal",
   templateUrl: "./pick-task-template-modal.component.html",
   providers: [DataViewerStateService],
   imports: [
      AsyncPipe,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      FormsModule,
      NgClass,
      IconComponent,
      TextButtonComponent,
      forwardRef(() => AssetParentList),
      NoSearchResults,
      BasicModalFooterComponent,
      DataViewerSearchComponent,
   ],
})
export class PickTaskTemplateModalComponent implements OnInit {
   public errorMsg?: string;
   public modalInstance;
   public selectedTask: TaskTemplateEntity | undefined;

   private readonly locationIdSubject = new BehaviorSubject<number>(-1);
   protected loadMoreItemsSubject = new BehaviorSubject<number>(1);
   protected loadMoreItems$ = this.loadMoreItemsSubject.asObservable();

   private readonly state = inject(DataViewerStateService);

   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   /**
    * The message to display in the modal.
    * Default: Translation of AssignANewPMMsg
    */
   public message = this.lang().AssignANewPMMsg;

   /**
    * The title of the modal.
    * Default: Translation of AssignANewPM
    */
   public title = this.lang().AssignANewPM;

   /**
    * This assets are used to display the asset name in the task template list.
    * NOTE: This should be refactored to not use the manageAsset service.
    */
   protected allAssets = this.manageAsset.getAssets();

   protected readonly tasksTemplatesResponse$: Observable<
      Partial<ListResponse<TaskTemplateEntity>>
   > = combineLatest([
      this.locationIdSubject.asObservable(),
      this.state.requestOptions$,
   ]).pipe(
      // NOTE: this prevents that we filter if the locationID is not set since it is getting it from the paramsService
      filter(([locationID]) => locationID > -1),
      // NOTE: This resets the accumulated and start requesting the first page for current parameters
      tap(() => {
         this.loadMoreItemsSubject.next(1);
      }),
      switchMap(([locationID, options]) =>
         this.loadMoreItems$.pipe(
            mergeMap((page) => this.loadItemsPage(page, options.search, locationID)),
            scan(
               (acc, response) => {
                  if (
                     acc.data === undefined ||
                     response.data === undefined ||
                     response.total === undefined
                  ) {
                     return response;
                  }
                  const items = acc.data.concat(response.data);
                  return {
                     data: items,
                     total: response.total,
                  } satisfies ListResponse<TaskTemplateEntity>;
               },
               { data: [] as Array<TaskTemplateEntity>, total: 0 } as any,
            ),
         ),
      ),
      share(),
   );

   protected readonly viewModel$: Observable<PickTaskTemplateModalViewModel> =
      this.tasksTemplatesResponse$.pipe(
         map((response) => {
            // TODO: Refactor to return all these values from the API Service
            //  Work this on the ticket https://limble.atlassian.net/browse/CMMS-1835
            const data = response?.data ?? [];
            const hasNoItems = data.length === 0;
            const total = response?.total ?? 0;
            const itemsDisplayed = data.length;
            const itemsLeft = total - itemsDisplayed;
            const loadableItems = itemsLeft > 50 ? 50 : itemsLeft;
            const hasMoreItems = loadableItems > 0;

            return {
               data,
               total,
               hasMoreItems,
               loadableItems,
               hasNoItems,
               itemsDisplayed,
            } satisfies PickTaskTemplateModalViewModel;
         }),
         share(),
      );

   public ngOnInit() {
      // Get the modal instance from the paramsService
      const params = this.paramsService.params;
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      // Get the parameters passed to the modal
      const resolve = params?.resolve;
      if (!resolve.locationID) {
         this.alertService.addAlert("No locationID passed to this modal", "danger", 6000);
         return;
      }

      if (resolve.message) {
         this.message = resolve.message;
      }

      if (resolve.title) {
         this.title = resolve.title;
      }
      this.locationIdSubject.next(Number(resolve.locationID));
   }

   protected selectTask(taskTemplateEntity: TaskTemplateEntity) {
      if (this.selectedTask === taskTemplateEntity) {
         //handles the double click.tap
         this.submit();
      }
      this.selectedTask = taskTemplateEntity;
   }

   protected loadItemsPage(page: number, search: string | undefined, locationID: number) {
      const searchFilter = search ? { search: search } : undefined;
      return this.taskTemplatesApiService
         .getList({
            sort: "checklistName",
            pagination: {
               page,
               limit: 50,
            },
            params: {
               locationIDs: [locationID],
               checklistTemplates: [1],
               columns: "assets,recurrences",
               excludeDeletedAssets: true,
               ...searchFilter,
            },
         })
         .pipe(
            catchError((error) => {
               this.errorMsg = error;
               return EMPTY;
            }),
         );
   }
   protected loadMoreItems() {
      this.loadMoreItemsSubject.next(this.loadMoreItemsSubject.value + 1);
   }
   protected close() {
      this.modalInstance.close(0);
   }

   protected submit() {
      this.modalInstance.close(this.selectedTask);
   }

   protected onSearchChange(search: string) {
      this.state.setSearch(search);
   }
}
