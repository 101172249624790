import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DraggableDirective, type TreeBranch } from "@limble/limble-tree";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   DatePickerInputComponent,
   DropdownComponent,
   DropdownItemComponent,
   FormDropdownInputComponent,
   IconComponent,
   InputWithPrefixComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import moment from "moment";
import type { Subscription } from "rxjs";
import { EditableImage } from "src/app/files/components/editableImage/editableImage.element.component";
import { FileUploader } from "src/app/files/components/fileUploader/fileUploader.element.component";
import { ViewFile } from "src/app/files/components/viewFile/viewFile.element.component";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { CurrencyService } from "src/app/shared/services/currency.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { EditVendorField } from "src/app/vendors/components/editVendorFieldModal/editVendorField.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { VendorField } from "src/app/vendors/types/field/field.types";
import type { VendorFieldType } from "src/app/vendors/types/field/type/vendor-field-type.types";
import type { VendorFieldValueFile } from "src/app/vendors/types/field/value/file/file.types";
import type { VendorFieldValue } from "src/app/vendors/types/field/value/vendor-field-value.types";
import type { Vendor } from "src/app/vendors/types/vendor.types";

type ValueExtraInfo = {
   oldValueContent: string | number | Date | null;
   json: Array<any>;
   fieldTypeIcon: string | null;
   fieldTypeHint: string | null;
};

type VendorCreds = {
   changeVendorFieldsValues: boolean;
   viewManageVendors: boolean;
   configureVendorFields: boolean;
   createAndDeleteVendorFields: boolean;
};

@Component({
   selector: "vendor-information-item",
   templateUrl: "./vendorInformationItem.element.component.html",
   styleUrls: ["./vendorInformationItem.element.component.scss"],
   imports: [
      IconComponent,
      DraggableDirective,
      TooltipDirective,
      LimbleHtmlDirective,
      DropdownComponent,
      MinimalIconButtonComponent,
      DropdownItemComponent,
      NgClass,
      FormsModule,
      ContenteditableDirective,
      DatePickerInputComponent,
      FileUploader,
      EditableImage,
      ViewFile,
      InputWithPrefixComponent,
      FormDropdownInputComponent,
      BetterDatePipe,
      IconAlias,
      CurrencySymbolPipe,
   ],
})
export class VendorInformationItem implements OnInit, OnDestroy {
   @Input() treeBranch?: TreeBranch<VendorInformationItem>;
   @Input() restrict: boolean = false;

   public CID;
   public currencySymbol;
   public vendor: Vendor | undefined;
   public uploadObj;
   public dateReminderHint;
   public showDateHint;
   public fieldOptionsSub;
   public fieldOptionsObs;
   public fieldValue: VendorFieldValue | undefined;
   public valueExtraInfo: ValueExtraInfo | undefined;
   public oldValueContent;
   public creds: VendorCreds = {
      changeVendorFieldsValues: false,
      viewManageVendors: false,
      configureVendorFields: false,
      createAndDeleteVendorFields: false,
   };
   private dateReminderStreamSub: Subscription | undefined;
   public fieldInfo: VendorField | undefined;
   public allFiles: Lookup<"fileID", VendorFieldValueFile> | undefined;
   public fieldTypes: Lookup<"fieldTypeID", VendorFieldType>;
   public fileGetURLs: Map<number, string> = new Map();
   private readonly vendorFieldsSub: Subscription | null;
   protected eyeSlashIcon: Aliases = "eyeSlashRegular";
   protected eyeIcon: Aliases = "eyeRegular";
   protected fieldLocked: boolean = false;
   public currencyCode = signal<string>("");

   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageTask = inject(ManageTask);
   private readonly credService = inject(CredService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageFiles = inject(ManageFiles);
   private readonly manageObservables = inject(ManageObservables);
   private readonly paramsService = inject(ParamsService);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUser = inject(ManageUser);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageLang = inject(ManageLang);
   protected readonly currencyService = inject(CurrencyService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.fieldTypes = this.manageVendor.getFieldTypes();
      this.allFiles = this.manageVendor.getFiles();
      this.vendorFieldsSub = this.manageObservables.setSubscription(
         "vendorFields",
         () => {
            if (!this.fieldValue) {
               return;
            }
            for (const fileID of this.fieldValue.vendorValueFileIDs) {
               this.setFileGetURLs(fileID);
            }
         },
      );
   }

   public ngOnInit() {
      this.fieldValue = this.treeBranch?.meta().nodeData;
      if (this.fieldValue === undefined) {
         throw new Error(
            "VendorInformationItem component requires a `treeBranch` input binding, but a value was not provided.",
         );
      }

      this.fieldInfo = this.manageVendor.getFields().get(this.fieldValue.fieldID);
      if (this.fieldInfo === undefined) {
         throw new Error(
            "fieldItem component requires `fieldInfo`, no field for the given fieldID exists.",
         );
      }

      const currentUser = this.manageUser.getCurrentUser();
      this.fieldLocked = Boolean(
         this.fieldInfo.lockedDefault && !this.manageUtil.checkIfSuperUser(currentUser),
      );

      this.fieldInfo = this.manageVendor.getFields().strictGet(this.fieldValue.fieldID);

      this.oldValueContent = this.fieldValue.valueContent;

      const fieldType = this.fieldTypes.get(this.fieldInfo.fieldTypeID);
      const typeIcon = fieldType ? fieldType.fieldTypeIcon : null;
      const typeHint = fieldType ? fieldType.fieldTypeHint : null;

      this.valueExtraInfo = {
         json: [],
         fieldTypeIcon: typeIcon,
         fieldTypeHint: typeHint,
         oldValueContent: this.fieldValue.valueContent,
      };

      if (this.fieldInfo.fieldTypeID === 7) {
         this.fieldOptionsObs = `vendorFieldOptionsJSON${this.fieldValue.fieldID}`;
         this.fieldOptionsSub = this.manageObservables.createAndSubscribe(
            this.fieldOptionsObs,
            () => {
               assert(this.fieldInfo);
               //optionsJSON was having problems with ng-repeat if it was updated elsewhere
               // building a local copy (field.json) cleared the errors
               if (this.fieldInfo.optionsJSON) {
                  assert(this.valueExtraInfo);
                  const parsedJSON = JSON.parse(this.fieldInfo.optionsJSON);
                  this.valueExtraInfo.json = [];

                  parsedJSON.forEach((option) => {
                     this.valueExtraInfo?.json.push(option.name);
                  });
               }
            },
         );
      }

      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;

      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
         this.currencyCode.set(this.currencySymbol);
      }
      //overriding this.vendor so that it is the vendor associated with the field and not the vendor that this item is created under
      //This is so that we can display different vendor's fields in a single vendor.  This allows for group vendor information
      this.vendor = this.manageVendor.getVendor(this.fieldValue.vendorID);
      this.getVendorCreds();

      //if it is a number of currency type then it needs to become a number not a string for the html element to work.
      if (this.fieldInfo.fieldTypeID === 5 || this.fieldInfo.fieldTypeID === 6) {
         this.fieldValue.valueContent = Number(this.fieldValue.valueContent);
      } else if (this.fieldInfo.fieldTypeID === 3 || this.fieldInfo.fieldTypeID === 4) {
         //prep data for the file uploader

         assert(this.vendor);
         this.uploadObj = this.manageVendor.setUploadObject(
            this.fieldInfo.fieldTypeID,
            this.fieldValue.valueID,
            this.vendor,
         );

         for (const fileID of this.fieldValue.vendorValueFileIDs) {
            this.setFileGetURLs(fileID);
         }
      }

      if (this.fieldInfo.fieldTypeID === 2) {
         if (this.fieldValue.valueContent !== null) {
            // this.fieldValue.valueContent = new Date(this.fieldValue.valueContent);
            this.fieldValue.valueContent = new Date(this.fieldValue.valueContent);
         }
      }

      if (this.fieldInfo.fieldTypeID === 2) {
         this.setDateReminderHint();

         //we need to watch for dateReminder changes
         const dateReminderStream$ = this.manageVendor.dateReminderStreamMap.get(
            this.fieldValue.fieldID,
         );

         this.dateReminderStreamSub = dateReminderStream$?.subscribe(() => {
            this.setDateReminderHint();
         });
      }

      this.valueExtraInfo.oldValueContent = this.fieldValue.valueContent;
      if (this.vendor?.locationID) {
         this.currencyCode.set(
            this.currencyService.getCurrencyCodeByLocationID(this.vendor.locationID),
         );
      }
   }

   public ngOnDestroy() {
      this.manageObservables.unsubscribeAndDelete(
         this.fieldOptionsObs,
         this.fieldOptionsSub,
      );
      this.dateReminderStreamSub?.unsubscribe();
      this.vendorFieldsSub?.unsubscribe();
   }

   //opens a doka instance -> no permissions here as if you are on the build tasks page you should have permission to edit the files.
   openEditor = async (file) => {
      assert(this.fieldValue);
      assert(this.vendor);
      if (this.creds.changeVendorFieldsValues) {
         const imagePath = `viewFile.php?f=upload-${this.CID}/vendors/${this.vendor.locationID}/${this.vendor.vendorID}/${this.fieldValue.valueID}/${file.fileName}`;
         const doka: any = await this.manageFiles.createImageEditor(imagePath);
         //when the user clicks confirm this deletes the old version of the file, and uploads the edited file
         //has to convert doka's output to formdata for uploading and play with the fileName a little bit so the names
         //don't get longer each time the file is edited
         doka.onconfirm = async (output) => {
            assert(this.fieldValue);
            assert(this.vendor);
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            await this.manageVendor.deleteFile(
               file.fileID,
               this.fieldValue,
               this.vendor.locationID,
            );
            const formData = new FormData();
            formData.append(
               "myfile",
               output.file,
               file.fileName.replace(/\d\d\d\d-/, ""),
            );

            const response = await this.uploadObj.uploadCall(
               this.uploadObj.posturl,
               formData,
            );
            if (!response) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 5000);
            }
            this.loadingBarService.remove();
            this.uploadObj.uploadComplete(response.data);
         };
      }
   };

   protected changeNumberField(): void {
      assert(this.fieldValue);
      assert(this.valueExtraInfo);
      if (typeof this.fieldValue?.valueContent === "number") {
         this.setFieldValue();
      } else {
         this.fieldValue.valueContent = this.valueExtraInfo.oldValueContent;
         this.alertService.addAlert(this.lang().PleaseEnterANumber, "warning", 1000);
      }
   }

   protected changeCurrencyField(newValue: string | number | null | undefined): void {
      assert(this.fieldValue);
      assert(this.valueExtraInfo);
      const newFieldValue = Number(newValue);
      if (typeof newFieldValue === "number") {
         this.fieldValue.valueContent = newFieldValue;
         this.setFieldValue();
      } else {
         this.fieldValue.valueContent = this.valueExtraInfo.oldValueContent;
         this.alertService.addAlert(this.lang().PleaseEnterANumber, "warning", 1000);
      }
   }

   changeSelectedNameField = (selected) => {
      assert(this.fieldValue);
      this.fieldValue.valueContent = selected;
      this.setFieldValue();
   };

   setFieldValue = () => {
      assert(this.fieldValue);
      assert(this.fieldInfo);
      assert(this.valueExtraInfo);
      assert(this.vendor);
      if (this.valueExtraInfo.oldValueContent == this.fieldValue?.valueContent) {
         //prevents un needed calls ;p
         return;
      }

      if (this.fieldLocked) {
         // The field is locked and they're not a super user so it can't be changed
         this.fieldValue.valueContent = this.oldValueContent;
         return;
      }

      // If it's a date, we need to make sure it's a valid date and not a string
      // An empty field is ok so that they can unset the field though
      if (this.fieldInfo.fieldTypeID == 2) {
         assert(this.fieldValue.valueContent);
         if (
            !(this.fieldValue.valueContent instanceof Date) &&
            this.fieldValue.valueContent !== null
         ) {
            this.fieldValue.valueContent = this.valueExtraInfo.oldValueContent;
            return;
         }
      }
      assert(this.fieldValue);

      this.manageVendor.setFieldValue(this.fieldValue, this.vendor).then((answer) => {
         assert(this.fieldValue);
         assert(this.valueExtraInfo);
         if (answer.data.success == true) {
            this.valueExtraInfo.oldValueContent = this.fieldValue.valueContent;

            this.manageTask.incTasksWatchVar();

            if (this.fieldInfo?.fieldTypeID == 2) {
               this.setDateReminderHint();
            }

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else if (answer.data.error === "nonUniqueValue") {
            this.fieldValue.valueContent = this.oldValueContent;
            this.alertService.addAlert(this.lang().valueUniqueError, "warning", 6000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   };

   setDateReminderHint = () => {
      assert(this.fieldValue);
      assert(this.fieldInfo);

      const fieldDate = this.fieldValue.valueContent;
      const today = moment();

      this.dateReminderHint = this.lang().DateReminderHint;

      const reminderDates = [
         ...new Set([
            this.fieldInfo.dateReminder1,
            this.fieldInfo.dateReminder2,
            this.fieldInfo.dateReminder3,
         ]),
      ]
         .filter((day) => day !== null)
         .map((days) => moment(fieldDate).subtract(days, "d"))
         .filter((date) => date.isAfter(today))
         .sort((dateA, dateB) => dateA.toDate().getTime() - dateB.toDate().getTime())
         .map((date) => this.betterDate.formatBetterDate(date.toDate(), "date"));

      if (reminderDates.length > 0) {
         this.dateReminderHint += ` ${reminderDates.join(", ")}`;
      } else {
         this.dateReminderHint = "No future reminders scheduled.";
      }

      // The tooltip title won't update unless we do something like this
      this.showDateHint = false;
      setTimeout(() => {
         this.showDateHint = true;
      }, 10);
   };

   showFieldOptions = (field) => {
      assert(this.vendor);
      if (
         !this.credService.isAuthorized(
            this.vendor.locationID,
            this.credService.Permissions.CreateNewFieldsAndDeleteExistingFields,
         )
      ) {
         this.alertService.addAlert(this.lang().cred65Fail, "danger", 10000);
         return;
      }

      const instance: any = this.modalService.open(EditVendorField);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            field: field,
         },
      };

      instance.result.then(() => {
         this.setDateReminderHint();
      });
   };

   updateFieldViewableByTech = (field) => {
      assert(this.fieldInfo);
      this.manageVendor
         .updateFieldViewableByTech(field, this.fieldInfo?.locationID)
         .then((answer) => {
            if (answer.data.success == true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 3000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   /*
    * This needs to stay as an arrow function to keep the correct context
    * since this function is passed into view-file as an input
    */
   deleteFile = async (file) => {
      assert(this.vendor);
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteDocumentMsg,
            title: this.lang().DeleteDocument,
         },
      };

      const result = await instance.result;
      if (result !== 1) {
         return;
      }
      assert(this.fieldValue);

      await this.manageVendor.deleteFile(
         file.fileID,
         this.fieldValue,
         this.vendor.locationID,
      );
   };

   removeField = () => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AreYouSure,
            title: this.lang().RemoveField,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            assert(this.fieldValue);
            assert(this.vendor);
            this.manageVendor.removeField(this.fieldValue, this.vendor).then((answer) => {
               if (answer?.data.success == true) {
                  //rebuilds this vendor's field data.
                  this.manageVendor.tileFieldsObs$.next(null);

                  this.manageTask.getData().then(() => {
                     //resets the correct data so that it loads properly
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  });

                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   private getVendorCreds() {
      assert(this.vendor);
      this.creds.changeVendorFieldsValues = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ChangeVendorInformationValues,
      );

      this.creds.viewManageVendors = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ViewManageVendors,
      );

      this.creds.configureVendorFields = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.ConfigureVendorInformationFields,
      );

      this.creds.createAndDeleteVendorFields = this.credService.isAuthorized(
         this.vendor.locationID,
         this.credService.Permissions.CreateAndDeleteVendorFields,
      );
   }

   public setFileGetURLs(fileID) {
      assert(this.vendor);
      const file = this.allFiles?.get(fileID);
      if (!file || !this.fieldValue) {
         return;
      }

      this.fileGetURLs.set(
         fileID,
         `viewFile.php?f=upload-${this.CID}/vendors/${this.vendor.locationID}/${this.vendor.vendorID}/${this.fieldValue.valueID}/${file.fileName}`,
      );
   }
}
