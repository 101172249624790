@if (asset) {
   <lim-ui-panel id="assetLogPrint{{ asset?.assetID }}">
      <!-- header section (search, include children, && print header that is hidden until printing) -->
      <div>
         <div class="hidePrint header-section-row">
            <lim-ui-search-box
               [(searchVal)]="searchBar"
               (searchValChange)="searchLogs()"
               [placeholder]="lang().Search"
            />
            @if (asset.assetChildrenIDs.length > 0) {
               <div class="checkbox-row">
                  <lim-ui-checkbox
                     [(model)]="asset.includeChildData"
                     (modelChange)="updateIncludeChildData()"
                     [disabled]="restrict || !editAssetSettingsCred"
                     [label]="lang().IncludeChildrenData"
                  />
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     [limUiTooltip]="
                        viewManageAssetsCred && !editAssetSettingsCred
                           ? lang().IncludeChildrenDataCredTooltip
                           : lang().IncludeChildrenDataTooltip
                     "
                     iconSize="small"
                  />
               </div>
            }
         </div>
         <!-- print related content -->
         <div class="printViewShow">
            <div style="text-align: center; margin-bottom: 10px">
               <div>
                  @if (notNullish(location.locationTaskImage)) {
                     <img
                        [src]="
                           'viewFile.php?f=upload-' +
                           CID +
                           '/locations/' +
                           asset.locationID +
                           '/defaultTaskImage/' +
                           location.locationTaskImage
                        "
                        class="img-rounded img-responsive"
                        style="max-width: 175px; width: 100%"
                     />
                  }

                  @if (notNullish(location.locationAddress)) {
                     <div>
                        <div>{{ location.locationName }}</div>
                        <div>{{ location.locationAddress }}</div>
                     </div>
                  }

                  @if (notNullish(location.locationAddress2)) {
                     <div>
                        {{ location.locationAddress2 }}
                     </div>
                  }

                  @if (location.locationPhone) {
                     <div>
                        {{ location.locationPhone }}
                     </div>
                  }

                  <br />
                  <br />
                  <div style="font-size: 26px">{{ location.assetName }}</div>
               </div>
               <div style="clear: both"></div>
            </div>

            <h5 style="margin: 10px 0">
               <span class="printViewShow">
                  <lim-ui-icon icon="list" iconSize="medium" class="icon-styling" />
                  <b>{{ lang().AssetTabLogHeading }}</b> -
                  {{ lang().AssetTabLogHeadingMsg }}
               </span>
            </h5>
         </div>
      </div>

      <div class="panel-body panel-bottom panel-middleGreen">
         <div class="listTable lim-ui-responsive-table">
            <!-- filter section -->
            <div class="filters-row hidePrint">
               <lim-ui-filters-wrapper [noMargins]="true" title="{{ lang().Filter }}:">
                  <lim-ui-dropdown-button [label]="lang().Source" filterDropdown>
                     <div menu>
                        <lim-ui-dropdown-text-item
                           displayString="{{ lang().ManualOrSystemEntry }}"
                           (click)="filterSource(2)"
                        />
                        <lim-ui-dropdown-text-item
                           displayString="{{ lang().CompletedWOsPMs }}"
                           (click)="filterSource(6)"
                        />
                        <lim-ui-dropdown-text-item
                           displayString="{{ lang().PMWOResponses }}"
                           (click)="filterSource(1)"
                        />

                        <lim-ui-dropdown-divider />

                        <lim-ui-dropdown-clear-filter-item (click)="clearSource()">
                           {{ lang().ClearFilter }}
                        </lim-ui-dropdown-clear-filter-item>
                     </div>
                  </lim-ui-dropdown-button>
                  <lim-ui-dropdown-button [label]="lang().Date" filterDropdown>
                     <div menu>
                        <lim-ui-dropdown-text-item
                           displayString="{{ lang().Last7Days }}"
                           (click)="filterDate(86400 * 7 * 1000)"
                        />
                        <lim-ui-dropdown-text-item
                           displayString="{{ lang().Last14Days }}"
                           (click)="filterDate(86400 * 14 * 1000)"
                        />
                        <lim-ui-dropdown-text-item
                           displayString="{{ lang().Last30Days }}"
                           (click)="filterDate(86400 * 30 * 1000)"
                        />
                        <lim-ui-dropdown-text-item
                           displayString="{{ lang().Last60Days }}"
                           (click)="filterDate(86400 * 60 * 1000)"
                        />

                        <lim-ui-dropdown-divider />

                        <lim-ui-dropdown-clear-filter-item (click)="clearDateFilter()">
                           {{ lang().ClearFilter }}
                        </lim-ui-dropdown-clear-filter-item>
                     </div>
                  </lim-ui-dropdown-button>
                  <lim-ui-filter-input-wrapper filterInputs>
                     @if (sourceState) {
                        <lim-ui-filter-input (clearFilterEvent)="clearSource()">
                           <label inputLabel> {{ lang().ActiveFilter }}:</label>
                           <b additionalContent>
                              @switch (sourceID) {
                                 @case (1) {
                                    <span>{{ lang().PMWOResponses }}</span>
                                 }
                                 @case (2) {
                                    <span>{{ lang().ManualOrSystemEntry }}</span>
                                 }
                                 @case (3) {
                                    <span>{{ lang().Import }}</span>
                                 }
                                 @case (4) {
                                    <span>{{ lang().Sensor }}</span>
                                 }
                                 @case (5) {
                                    <span>{{ lang().ProblemReport }}</span>
                                 }
                                 @case (6) {
                                    <span>{{ lang().CompletedWOsPMs }}</span>
                                 }
                              }
                           </b>
                           @if (sourceID === 1 && logTasks.length > 0) {
                              <span
                                 inputElement
                                 class="filter-inputs-container"
                                 additionalContent
                              >
                                 <lim-ui-dropdown>
                                    <lim-ui-secondary-button
                                       button
                                       secondaryIcon="angleDown"
                                    >
                                       <div
                                          [limbleHtml]="
                                             workOrderChoice
                                                ? workOrderChoice.checklistName +
                                                  ' | ' +
                                                  workOrderChoice.itemText
                                                : ''
                                          "
                                       ></div>
                                    </lim-ui-secondary-button>

                                    <div menu>
                                       @for (logTask of logTasks; track logTask) {
                                          <lim-ui-dropdown-text-item
                                             [displayString]="
                                                logTask.checklistName +
                                                ' | ' +
                                                logTask.itemText
                                             "
                                             [outputString]="
                                                logTask.checklistName +
                                                ' | ' +
                                                logTask.itemText
                                             "
                                             (click)="setWorkOrderChoice(logTask)"
                                          />
                                       }
                                    </div>
                                 </lim-ui-dropdown>
                              </span>
                           }
                        </lim-ui-filter-input>
                     }
                     @if (dateState) {
                        <lim-ui-filter-input (clearFilterEvent)="clearDateFilter()">
                           <label inputLabel> {{ lang().ActiveFilter }}:</label>
                           <b additionalContent>
                              @switch (dateDuration) {
                                 @case (86400 * 7 * 1000) {
                                    <span>{{ lang().Last7Days }}</span>
                                 }
                                 @case (86400 * 14 * 1000) {
                                    <span>{{ lang().Last14Days }}</span>
                                 }
                                 @case (86400 * 30 * 1000) {
                                    <span>{{ lang().Last30Days }}</span>
                                 }
                                 @case (86400 * 60 * 1000) {
                                    <span>{{ lang().Last60Days }}</span>
                                 }
                              }
                           </b>
                        </lim-ui-filter-input>
                     }
                  </lim-ui-filter-input-wrapper>
               </lim-ui-filters-wrapper>
            </div>

            <!-- Logs Table -->
            @if (isLoading) {
               <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
               <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
               <ngx-skeleton-loader count="1" [theme]="skeletonThemes.fillSize" /><br />
            } @else {
               @if (noSearchResults) {
                  <no-search-results />
               }
               @if (logItems?.length > 0) {
                  <div class="listItem listItemHeader lim-ui-responsive-table-header">
                     <sort-column
                        class="col-md-3"
                        column="logDate"
                        name="{{ lang().Date }}"
                        [(bind)]="orderBy"
                        [sortFn]="sortLogsBySortBind"
                     />
                     <sort-column
                        class="col-md-6"
                        column="sourceID"
                        name="{{ lang().Entry }}"
                        [(bind)]="orderBy"
                        [sortFn]="sortLogsBySortBind"
                     />
                     <sort-column
                        class="col-md-3"
                        column="userLastName"
                        name="{{ lang().User }}"
                        [(bind)]="orderBy"
                        [sortFn]="sortLogsBySortBind"
                     />
                  </div>

                  @for (
                     log of logItems
                        | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
                     track log
                  ) {
                     <div
                        class="listItem lim-ui-responsive-table-row"
                        [ngClass]="{ tranactionEveryOther: $even }"
                     >
                        <div
                           class="col-md-3 log-item-date-area lim-ui-responsive-table-cell"
                        >
                           @if (log.logStatus == 1 || log.logStatus == 2) {
                              <lim-ui-icon
                                 icon="circleCheck"
                                 iconSize="small"
                                 iconColor="success"
                                 [limUiTooltip]="lang().LogIsInGoodStatus"
                                 placement="top"
                              />
                           }
                           @if (log.logStatus == 3 || log.logStatus == 4) {
                              <lim-ui-icon
                                 icon="triangleExclamation"
                                 iconSize="small"
                                 iconColor="warn"
                                 [limUiTooltip]="lang().LogIsInWarningStatus"
                                 placement="top"
                              />
                           }
                           @if (log.logStatus == 5 || log.logStatus == 6) {
                              <lim-ui-icon
                                 icon="circleExclamation"
                                 iconSize="small"
                                 iconColor="danger"
                                 [limUiTooltip]="lang().LogIsInCriticalStatus"
                                 placement="top"
                              />
                           }
                           {{ log.logDate | betterDate: "dateTime" }}
                        </div>

                        <div
                           class="col-md-6 log-item-content-area lim-ui-responsive-table-cell"
                        >
                           @if (log.sourceID == 1) {
                              <div>
                                 <!--This is going to have to be dynamically built to receive all
                              of the different item types that can be logged in the tool-->
                                 <div>
                                    <!--This will need to link to the actual task in a modal.-->
                                    <!--Hover will show that this itemResponse refers to-->
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="squareArrowUpRight"
                                       iconSize="small"
                                       limUiTooltip="{{
                                          lang().ViewTheTaskThisLogOriginatedFrom
                                       }}"
                                       data-placement="top"
                                       (click)="popTask(log.logDetails.checklistID)"
                                    />

                                    <i [limbleHtml]="log.logDetails.itemText"></i> :

                                    @if (
                                       log.logDetails.itemTypeID ==
                                       TaskInstructionType.DatePicker
                                    ) {
                                       <span>{{
                                          log.logDetails.itemResponse * 1000
                                             | betterDate: "date"
                                       }}</span>
                                    } @else {
                                       <span>{{ log.logDetails.itemResponse }}</span>
                                    }

                                    @if (asset.includeChildData == 1) {
                                       <span>
                                          -
                                          <a
                                             [limbleHtml]="log.assetName"
                                             (click)="viewAsset(log.assetID)"
                                             class="green-color-link cursor"
                                          ></a>
                                       </span>
                                    }
                                 </div>
                              </div>
                           }

                           @if (log.sourceID == 2) {
                              <div>
                                 @if (log.logDetails) {
                                    <div>
                                       <span [limbleHtml]="log.logDetails"></span>
                                       @if (asset.includeChildData == 1) {
                                          <span>
                                             -
                                             <a
                                                [limbleHtml]="log.assetName"
                                                (click)="viewAsset(log.assetID)"
                                                class="green-color-link cursor"
                                             ></a>
                                          </span>
                                       }
                                    </div>
                                 }

                                 @if (log.logFiles.length > 0) {
                                    <div
                                       style="margin-top: 5px"
                                       [ngClass]="{ marginRight65: manageAssetLogCred }"
                                    >
                                       @for (file of log.logFiles; track file) {
                                          <div class="list-group">
                                             <div
                                                class="list-group-item"
                                                style="
                                                   margin-bottom: 10px;
                                                   word-break: break-all;
                                                "
                                             >
                                                <file-list-item
                                                   [file]="file"
                                                   url="viewFile.php?f=upload-{{
                                                      CID
                                                   }}/assets/{{ asset.locationID }}/{{
                                                      file.assetID
                                                   }}/logs/{{ log.logID }}/{{
                                                      file.fileName | escape
                                                   }}"
                                                />
                                             </div>
                                          </div>
                                       }
                                    </div>
                                 }

                                 @if (log.logPictures.length > 0) {
                                    <div style="margin-top: 5px">
                                       @for (picture of log.logPictures; track picture) {
                                          <span>
                                             <view-image [src]="picture.getURL" />
                                          </span>
                                       }
                                    </div>
                                 }
                                 <div style="clear: both"></div>
                              </div>
                           }

                           @if ([3, 4, 5].includes(log.sourceID)) {
                              <div>log.sourceID: {{ log.sourceID }}</div>
                              @if (log.logDetails) {
                                 <div>
                                    {{ log.logDetails }}

                                    @if (asset.includeChildData == 1) {
                                       <span>
                                          -
                                          <a
                                             [limbleHtml]="log.assetName"
                                             (click)="viewAsset(log.assetID)"
                                             class="green-color-link cursor"
                                          ></a>
                                       </span>
                                    }
                                 </div>
                              }
                           }

                           @if (log.sourceID == 6) {
                              <div>
                                 @if (log.logDetails.checklistTemplateOld == 1) {
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="wrench"
                                       iconSize="small"
                                    />
                                 }
                                 @if (
                                    log.logDetails.checklistTemplateOld == 2 &&
                                    !(
                                       log.logDetails.checklistBatchID == 300112 ||
                                       log.logDetails.checklistBatchID == 10000
                                    )
                                 ) {
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="wpforms"
                                       iconSize="small"
                                    />
                                 }
                                 @if (
                                    log.logDetails.checklistTemplateOld == 2 &&
                                    (log.logDetails.checklistBatchID == 300112 ||
                                       log.logDetails.checklistBatchID == 10000)
                                 ) {
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="triangleExclamation"
                                       iconSize="small"
                                    />
                                 }
                                 @if (log.logDetails.checklistTemplateOld == 4) {
                                    <lim-ui-icon
                                       class="icon-styling"
                                       icon="file"
                                       iconSize="small"
                                    />
                                 }

                                 <a
                                    class="cursor"
                                    (click)="popTask(log.logDetails.checklistID)"
                                 >
                                    <span
                                       [limbleHtml]="
                                          log.logDetails.checklistName +
                                          ' - #' +
                                          log.logDetails.checklistID
                                       "
                                    ></span>
                                 </a>

                                 @if (asset.includeChildData == 1) {
                                    <span>
                                       -
                                       <a
                                          [limbleHtml]="log.assetName"
                                          (click)="viewAsset(log.assetID)"
                                          class="green-color-link cursor"
                                       ></a>
                                    </span>
                                 }

                                 {{ lang().HasBeenSuccessfullyCompleted }}
                              </div>
                           }

                           @if (log.sourceID == 2 && manageAssetLogCred) {
                              <span>
                                 <lim-ui-row-hover-buttons
                                    class="hover-buttons"
                                    [showHideButton]="true"
                                    [showEditButton]="true"
                                    [showDeleteButton]="true"
                                    [showCopyButton]="false"
                                    [hideButtonTooltip]="lang().ShowHideAssetLogEntry"
                                    [editButtonTooltip]="lang().EditThisLogEntry"
                                    [deleteButtonTooltip]="lang().DeleteThisLogEntry"
                                    (clickHide)="hideShowLogEntry(log)"
                                    (clickEdit)="editLogEntry(log)"
                                    (clickDelete)="deleteLogEntry(log)"
                                 />
                              </span>
                           }
                        </div>

                        <div class="col-md-3 lim-ui-responsive-table-cell">
                           @if (log.sourceID == 5) {
                              <div>
                                 <lim-ui-icon
                                    class="icon-styling"
                                    icon="triangleExclamation"
                                    iconSize="small"
                                    limUiTooltip="{{
                                       lang().ThisWasPlacedByAProblemReport
                                    }}"
                                    data-placement="top"
                                 />
                                 {{ log.userFirstName }} {{ log.userLastName }}
                              </div>
                           }
                           @if (log.sourceID == 4) {
                              <div>
                                 <lim-ui-icon
                                    class="icon-styling"
                                    icon="desktop"
                                    iconSize="small"
                                    limUiTooltip="{{ lang().ThisWasPlacedByASensor }}"
                                    data-placement="top"
                                 />
                                 {{ log.sensorName }}
                              </div>
                           }
                           @if (
                              log.sourceID == 1 || log.sourceID == 2 || log.sourceID == 3
                           ) {
                              <div>
                                 @if (log.userID > 0) {
                                    <span>
                                       <lim-ui-icon
                                          icon="user"
                                          iconSize="small"
                                          limUiTooltip="{{
                                             lang().ThisWasEnteredByAUser
                                          }}"
                                          data-placement="top"
                                       />
                                       {{ log.userFirstName }} {{ log.userLastName }}
                                    </span>
                                 }
                                 @if (log.userID == 0) {
                                    <span>
                                       <lim-ui-icon
                                          icon="wandMagicSparkles"
                                          iconSize="small"
                                          limUiTooltip="{{
                                             lang().ThisWasAutomaticallyAddedByLimble
                                          }}"
                                          data-placement="top"
                                       />
                                       {{ lang().AutomaticallyAdded }}
                                    </span>
                                 }
                              </div>
                           }
                           @if (log.sourceID == 6) {
                              <div>
                                 <lim-ui-icon
                                    icon="user"
                                    iconSize="small"
                                    limUiTooltip="{{ lang().ThisTaskWasCompletedBy }}"
                                    data-placement="top"
                                 />
                                 {{ log.userFirstName }} {{ log.userLastName }}
                              </div>
                           }
                        </div>
                     </div>
                  }

                  @if (logItems?.length) {
                     <div class="pagination-container">
                        <lim-ui-pagination
                           [maxSize]="5"
                           [rotate]="true"
                           [(page)]="page"
                           [pageSize]="itemsPerPage"
                           [collectionSize]="logItems?.length"
                           (pageChange)="pageChanged()"
                        />
                        <lim-ui-dropdown-results-per-page
                           [selectedValue]="itemsPerPage"
                           [label]="lang().itemsPerPage"
                        >
                           <ng-container menu>
                              @for (num of ["10", "20", "50", "100"]; track num) {
                                 <lim-ui-dropdown-text-item
                                    [displayString]="num"
                                    [outputString]="num"
                                    (itemSelectedEvent)="
                                       itemsPerPage = num; updateUIPref()
                                    "
                                 />
                              }
                           </ng-container>
                        </lim-ui-dropdown-results-per-page>
                     </div>
                  }
               }
            }
            @if (!restrict) {
               <div class="comments-container hidePrint">
                  <div class="comments-instructions-and-entry-container">
                     <div class="instruction-text">
                        {{ lang().AddEntry }}
                     </div>
                     <span class="date-and-time">
                        {{ lang().at }}
                        <a class="cursor" (click)="setAddEntryDate()">{{
                           addEntryDate | betterDate: "dateTime"
                        }}</a>
                     </span>
                     <div class="eye-eyecon">
                        @if (manageAssetLogCred) {
                           <lim-ui-icon-button
                              [icon]="newEntryViewable == 1 ? eyeIcon : eyeSlashIcon"
                              placement="top"
                              limUiTooltip="{{ lang().ShowHideAssetLogEntry }}"
                              (click)="showHideNewEntry()"
                           />
                        }
                     </div>
                  </div>
                  <div class="comment-input-box">
                     <textarea
                        class="form-control log-input-field"
                        [(ngModel)]="entryText"
                     >
                     </textarea>

                     <div class="bottom-buttons-row">
                        <div class="bottom-buttons-row-left">
                           <comment-files
                              [uploadObject]="logUploadObj"
                              [documents]="fileQue"
                              [images]="pictureQue"
                              icon="plus"
                              label="AttachFile"
                           />
                        </div>

                        <lim-ui-primary-button (click)="addEntry()">
                           {{ lang().Submit }}
                        </lim-ui-primary-button>
                     </div>
                     <div class="entry-alert">
                        @if (errorMsg) {
                           <lim-ui-alert [title]="lang().Warning" alertType="warning">
                              <span [limbleHtml]="errorMsg"></span>
                           </lim-ui-alert>
                        }

                        @if (successMsg) {
                           <lim-ui-alert [title]="lang().Success" alertType="success">
                              <span [limbleHtml]="successMsg"></span>
                           </lim-ui-alert>
                        }
                     </div>
                  </div>
               </div>
            }
         </div>
      </div>
   </lim-ui-panel>
}
