import { inject, Injectable } from "@angular/core";
import { LoadingBarService } from "@limblecmms/lim-ui";
import { BehaviorSubject } from "rxjs";
import type { Timer } from "src/app/shared/utils/app.util";

/**
 * This service is used to fake a percentage timer for the loading bar or whatever else your heart desires.
 * It is not accurate, but strives to be close enough to give the user a sense of progress.
 */
@Injectable({
   providedIn: "root",
})
export class FakePercentageTimerService {
   private loadingInterval: Timer | undefined;
   private readonly progressPercentageSubject: BehaviorSubject<number> =
      new BehaviorSubject<number>(0);
   private readonly loadingBarService = inject(LoadingBarService);

   public setLoadingTimer(
      totalItems: number,
      estimatedItemsCompleteIn10Seconds: number = 4000,
   ) {
      // We estimate that for 2000 tasks it will take 10 seconds
      const timeToGetData = (totalItems / estimatedItemsCompleteIn10Seconds) * 5;
      const timeToConvertToExcel = 10;
      const totalEstimatedTime = timeToGetData + timeToConvertToExcel;

      // Timer that fakes the percentage
      const incrementBy = 5;
      const numberOfIntervals = 100 / incrementBy; // Gives us 20 increments
      const intervalTime = (totalEstimatedTime * 1000) / numberOfIntervals;
      this.loadingInterval = setInterval(() => {
         const percentage = this.progressPercentageSubject.value + incrementBy;
         if (percentage <= 90) {
            this.progressPercentageSubject.next(percentage); // I use the subject here to keep track of the last emitted value
            this.loadingBarService.update({ loadingPercentage: percentage });
         }
      }, intervalTime);
   }

   public clearLoadingTimer(): void {
      clearInterval(this.loadingInterval);
   }
}
