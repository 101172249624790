import type { OnDestroy } from "@angular/core";
import { inject, Injectable, NgZone } from "@angular/core";
import type { Observable, Subscription } from "rxjs";
import {
   BehaviorSubject,
   filter,
   from,
   fromEvent,
   interval,
   map,
   merge,
   of,
   switchMap,
   takeUntil,
} from "rxjs";

/**
 * Tracks whether the device is connected to the internet.
 */
@Injectable({ providedIn: "root" })
export class ConnectionService implements OnDestroy {
   private readonly HEARTBEAT_INTERVAL_MS = 10 * 1000;
   private readonly PING_URL = "https://online.limblecmms.com";
   private readonly networkChanges = this.observeNetworkConnectionChanges();
   private readonly internetChanges = new BehaviorSubject(navigator.onLine);
   private readonly internetConnectsOrNetworkDisconnects =
      this.observeInternetConnectedOrNetworkDisconnected();

   private isSendingHeartbeat = false;
   private networkConnectionSubscription?: Subscription;
   private heartbeatSubscription?: Subscription;
   private readonly ngZone = inject(NgZone);

   public constructor() {
      this.reactToNetworkConnectionChanges();
   }

   /**
    * Sends a ping to a Limble endpoint to verify online status.
    *
    * @returns A promise that resolves to `true` if the ping is successful (indicating
    * a valid internet connection), or `false` otherwise (indicating a lack of connection
    * to the internet).
    */
   public async isPingSuccess(): Promise<boolean> {
      try {
         const response = await this.ping();
         if (response.status === 504) {
            throw new Error("bad gateway");
         }
      } catch (error) {
         return false;
      }
      return true;
   }

   public ngOnDestroy(): void {
      this.networkConnectionSubscription?.unsubscribe();
      this.heartbeatSubscription?.unsubscribe();
   }

   public isOnlineObs(): Observable<boolean> {
      return this.internetChanges.asObservable();
   }

   public isOnline(): boolean {
      return this.internetChanges.value;
   }

   /**
    * Forcibly changes the network status to offline.
    *
    * By itself, this class can only detect changes to network connectivity, not internet
    * connectivity. Usually, these are the same thing; but in cases where the client loses
    * internet connectivity but remains connected to the network, we need to manually
    * change the network status to offline from some other part of the application --
    * probably some kind of network interceptor that detects errors.
    */
   public setNoInternet(): void {
      this.changeInternetConnectionStatus(false);
      this.sendHeartbeatUntilSuccess();
   }

   private observeNetworkConnectionChanges(): Observable<boolean> {
      return merge(fromEvent(window, "online"), fromEvent(window, "offline")).pipe(
         map(() => navigator.onLine),
      );
   }

   private reactToNetworkConnectionChanges(): void {
      // We run this outside of Angular to avoid triggering change detection unnecessarily
      this.ngZone.runOutsideAngular(() => {
         this.networkConnectionSubscription = this.networkChanges.subscribe(
            (isNetworked) => of(this.handleNetworkConnectionChange(isNetworked)),
         );
      });
   }

   private sendHeartbeatUntilSuccess(): void {
      if (this.isSendingHeartbeat === true) return;

      this.heartbeatSubscription = interval(this.HEARTBEAT_INTERVAL_MS)
         .pipe(
            takeUntil(this.internetConnectsOrNetworkDisconnects),
            switchMap(() => from(this.isPingSuccess())),
            filter((isPingSuccess) => isPingSuccess === true),
         )
         .subscribe(() => {
            this.changeInternetConnectionStatus(true);
            this.isSendingHeartbeat = false;
         });
   }

   private observeInternetConnectedOrNetworkDisconnected(): Observable<boolean> {
      return merge(
         this.internetChanges.pipe(
            filter((isInternetConnected) => isInternetConnected === true),
         ),
         this.networkChanges.pipe(filter((isNetworked) => isNetworked === false)),
      );
   }

   private changeInternetConnectionStatus(hasInternet: boolean): void {
      this.ngZone.run(() => {
         this.internetChanges.next(hasInternet);
      });
   }

   /**
    * Sends a ping to a Limble endpoint to verify online status.
    *
    * The endpoint is constructed specifically to verify online status and has
    * no other purpose. It has a separate infrastructure to ensure it is reliable.
    */
   private async ping(): Promise<Response> {
      return fetch(this.PING_URL);
   }

   private async handleNetworkConnectionChange(isNetworked: boolean): Promise<void> {
      if (!isNetworked) {
         this.changeInternetConnectionStatus(false);
         return;
      }

      /**
       * If the browser connects to a network,
       * we must check if the network is connected to the internet with a ping to Limble.
       */
      if (await this.isPingSuccess()) this.changeInternetConnectionStatus(true);
      // Double check that the browser still thinks it is connected before starting the hb
      else if (navigator.onLine === true) {
         this.sendHeartbeatUntilSuccess();
      }
   }
}
