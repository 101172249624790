import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import type { LimUiModalRef } from "@limblecmms/lim-ui";
import {
   BasicModalHeaderComponent,
   IconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import clone from "rfdc";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageTool } from "src/app/assets/services/manageTool";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageTutorial } from "src/app/onboarding/services/tutorials/manageTutorial";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { CredService } from "src/app/users/services/creds/cred.service";

const deepClone = clone();

@Component({
   selector: "pick-tools",
   templateUrl: "./pickTools.modal.component.html",
   styleUrls: ["./pickTools.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      SearchBoxComponent,
      NoSearchResults,
      HierarchyContainerLegacy,
      IconButtonComponent,
      TooltipDirective,
      ModalFooterComponent,
      PrimaryButtonComponent,
   ],
})
export class PickTools implements OnInit {
   public readonly hierarchyOptions: HierarchyOptions;
   public readonly tools;
   public filteredTools;
   public treeData: Array<HierarchyNode> = [];
   public searchText: string = "";
   public title: string | undefined;
   public message: string | undefined;

   private readonly locations;
   private resolve;
   private modalInstance: LimUiModalRef | null = null;
   private locationsIndex = {};
   private initialLoad = true;
   protected dataLogOptions: DataLogEventDefinition | undefined;

   private readonly manageTool = inject(ManageTool);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly credService = inject(CredService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTutorial = inject(ManageTutorial);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.tools = deepClone(this.manageTool.getAllTools());
      this.filteredTools = this.tools;
      this.locations = this.manageLocation.getLocations().map((location) => {
         return { locationID: location.locationID, locationName: location.locationName };
      });

      this.hierarchyOptions = {
         idKey: "assetID",
         submit: this.submit.bind(this),
         nodeButtons: [
            {
               tooltip: this.lang().OpenThisAsset,
               clickFunction: this.runPopAsset.bind(this),
               permissionNumber: this.credService.Permissions.ViewLookupAnAsset,
               text: this.lang().View,
            },
         ],
      };
      this.setLocationHierarchyData();
      this.buildTreeData();
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.dataLogOptions = this.resolve.dataLogOptions;
      this.title = this.resolve.title;
      this.message = this.resolve.message;
   }

   public updateSearch() {
      if (this.searchText.length) {
         this.filteredTools = this.tools.filter((tool) => {
            return Object.values(tool).some((item) => {
               return String(item)
                  .toLowerCase()
                  .trim()
                  .includes(this.searchText.toLowerCase().trim());
            });
         });
         this.buildTreeData();
      } else {
         this.filteredTools = this.tools;
         this.buildTreeData();
      }
   }

   public noSearchResults() {
      return this.tools.length && !this.filteredTools.length;
   }

   //arrow syntax to preserve 'this' when passed to sub-component
   public close() {
      if (this.modalInstance) {
         this.modalInstance.close();
      }
   }

   public submit() {
      const selectedToolIDs = this.filteredTools
         .filter((item) => item.selected)
         .map((item) => item.assetID);
      if (!selectedToolIDs.length) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
         return;
      }
      if (this.modalInstance) {
         this.modalInstance.close(selectedToolIDs);
      }
   }

   private buildTreeData() {
      this.setToolsOnLocations();

      for (const location of this.locations) {
         if (location.nodes.length > 0) {
            location.showNodesClass = "showNodes";
         } else {
            location.showNodesClass = "";
         }
      }

      this.treeData = this.locations.filter((item) => item.nodes.length);

      const largeDataSet = this.filteredTools.length > 50;

      this.treeData.forEach((location) => {
         location.collapsed = largeDataSet;
      });
   }

   private setLocationHierarchyData() {
      for (const location of this.locations) {
         location.title = location.locationName;
         location.type = 1;
         location.icon = "houseChimney";
         this.locationsIndex[location.locationID] = location;
      }
   }

   private setToolsOnLocations() {
      this.locations.forEach((location) => {
         location.nodes = [];
      });
      this.filteredTools.forEach((tool) => {
         if (this.initialLoad) {
            tool.selected = false;
         }
         tool.locationName =
            this.manageLocation.getLocation(tool.locationID)?.locationName ?? "";
         tool.title = `${tool.assetName} - ${tool.locationName}`;
         tool.icon = "screwdriverWrench";
         tool.displayButtons = true;
         const hasCredAtLocation = this.credService.isAuthorized(
            tool.locationID,
            this.credService.Permissions.AssetCheckInOut,
         );
         if (this.locationsIndex[tool.locationID] && hasCredAtLocation) {
            this.locationsIndex[tool.locationID].nodes.push(tool);
         }
      });
      this.initialLoad = false;
   }

   public watchTutorial() {
      const videoTutorial = this.manageTutorial
         .getTutorialSection("assetsModal")
         .getVideoTutorialByName("Asset Settings");
      if (!videoTutorial) {
         return;
      }

      this.manageTutorial.watchVideoTutorial(videoTutorial);
   }

   public runPopAsset(asset) {
      this.manageAsset.popAsset(asset.assetID, { dataLogOptions: this.dataLogOptions });
   }
}
