<ng-container *translate="let t">
   <lim-ui-dropdown-button [label]="t(filter().labelKey) || ''" filterDropdown>
      @if (users().length > 5) {
         <div header>
            <lim-ui-search-box
               [placeholder]="t('Search')"
               [searchVal]="searchUsers()"
               (searchValChange)="handleSearch($event)"
               [useDropdownStyling]="true"
            />
            <lim-ui-dropdown-divider />
         </div>
      }
      <div menu>
         @for (user of usersDropdown(); track user.userID) {
            <lim-ui-dropdown-text-item
               displayString="{{ user.userFullName }}"
               (click)="handleSetUser(user)"
               [dataLogLabel]="filter().dataLogLabel"
            />
         }
         <lim-ui-dropdown-divider />
         <lim-ui-dropdown-clear-filter-item (click)="handleClear()">
            {{ t("ClearFilter") }}
         </lim-ui-dropdown-clear-filter-item>
      </div>
   </lim-ui-dropdown-button>
</ng-container>
