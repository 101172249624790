import type { PipeTransform } from "@angular/core";
import { Pipe } from "@angular/core";

/*
 * Takes in a string and removes all HTML tags from it.
 * Usage:
 *   string | stripHtmlTags
 */

@Pipe({
   name: "stripHtmlTags",
   standalone: true,
})
export class StripHtmlTagsPipe implements PipeTransform {
   public transform(input: string | null | undefined): string {
      const normalizedInput = input ?? "";
      return normalizedInput.replace(/<[^>]*>/g, "");
   }
}
