import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconButtonComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PaginationComponent,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { lastValueFrom } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { PrintDivDirective } from "src/app/tasks/directives/printDiv/printDiv.directive";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import type { Task } from "src/app/tasks/types/task.types";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "pop-schedules",
   templateUrl: "./popSchedules.modal.component.html",
   styleUrls: ["./popSchedules.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      IconButtonComponent,
      PrintDivDirective,
      PanelComponent,
      LoadingAnimationComponent,
      NgClass,
      IconComponent,
      PaginationComponent,
      BasicModalFooterComponent,
      BetterDatePipe,
      SliceArray,
   ],
})
export class PopSchedules implements OnInit {
   public loadingBar;
   public title;
   public message;
   public task: Task | TaskTemplateEntity | undefined;
   public days: number | null = null;
   public futureTasks: Array<any> = [];
   public resolve;
   public data;
   public modalInstance;
   public itemsPerPage = 20;
   public page = 1;

   private readonly credService = inject(CredService);
   private readonly manageTask = inject(ManageTask);
   private readonly alertService = inject(AlertService);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageSchedule = inject(ManageSchedule);
   private readonly manageUser = inject(ManageUser);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly taskTemplateApiService = inject(TaskTemplatesApiService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.initialize();
   }

   private async initialize(): Promise<void> {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.data = this.resolve.data;

      this.loadingBar = true;

      if (this.data.title) {
         this.title = this.data.title;
      }
      if (this.data.message) {
         this.message = this.data.message;
      }

      this.task = await lastValueFrom(
         this.taskTemplateApiService.getById(this.data.checklistID),
      );

      if (!this.task) return;

      // For some reason we are overriding this.days with the following. There is
      // similar code in production, so we are emulating it here, even though we
      // are not 100% sure what it is doing. See this github PR for a picture of the old similar code:
      // https://github.com/LimbleCMMS/webApp/pull/5447
      this.manageTaskItem.getTaskItems(this.task.checklistID).then((answer) => {
         this.days = Math.max(
            Number(answer.data.checklistGreen),
            Number(answer.data.checklistYellow),
            Number(answer.data.checklistRed),
         );
      });

      this.manageTask
         .getFutureTasks(this.task.checklistID, this.task.locationID)
         .then((futureTasks) => {
            if (!futureTasks) return;
            this.futureTasks = futureTasks;
            this.loadingBar = false;
            for (const futureTask of futureTasks) {
               futureTask.isDisabled =
                  futureTask.skipScheduleFlag === "skip" ||
                  futureTask.skipScheduleFlag === "deleted";
               if (futureTask.deletedByUser > 0) {
                  const user = this.manageUser.getUser(futureTask.deletedByUser);
                  futureTask.deletedByUserName = `${user.userFirstName} ${user.userLastName}`;
               }
            }
         });
   }

   public updateSingleSchedule(schedule): void {
      if (
         !this.task ||
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangePMAssignments,
         )
      ) {
         this.alertService.addAlert(this.lang().cred56Fail, "danger", 10000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };

      extraUsersOptions.arr.forEach((user) => {
         extraUsersOptions.key[user.userID] = user;
      });

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().ChangeTheTaskAssignmentForASingleScheduleMsg,
               title: this.lang().ChangeTheTaskAssignmentForASingleSchedule,
               locationID: schedule.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: schedule.schedOwnerID,
               defaultProfile: schedule.schedGroupID,
            },
         },
      };

      instance.result.then((pickUserData) => {
         if (pickUserData) {
            this.manageTask
               .updateSingleSchedule(
                  pickUserData.userID,
                  pickUserData.profileID,
                  pickUserData.multiUsers,
                  schedule.scheduleID,
               )
               .then(
                  (answer) => {
                     if (answer.data.lName == null) {
                        schedule.profileDescription = answer.data.fName;
                        schedule.userFirstName = null;
                        schedule.userLastName = null;
                     } else {
                        schedule.profileDescription = null;
                        schedule.userFirstName = answer.data.fName;
                        schedule.userLastName = answer.data.lName;
                     }

                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  },
                  () => {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  },
               );
         }
      });
   }

   public deleteSingleSchedule(
      task: Task | TaskTemplateEntity | undefined,
      schedule,
   ): void {
      if (
         !task ||
         !this.credService.isAuthorized(
            task.locationID,
            this.credService.Permissions.ChangePMSchedules,
         )
      ) {
         this.alertService.addAlert(this.lang().cred55Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: `${this.lang().DeleteTaskMsg} <b>${task?.checklistName}</b>?`,
            title: this.lang().DeleteTask,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            const currentUser = this.manageUser.getCurrentUser().userInfo.userID;
            const promise = this.manageTask.deleteSingleSchedule(
               schedule.scheduleID,
               currentUser,
            );

            promise.then(
               () => {
                  this.manageTask.incTasksWatchVar();
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               },
               () => {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               },
            );
         }
      });
   }

   public restoreSingleSchedule(schedule) {
      assert(schedule.skipScheduleFlag === "deleted");
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().RestoreScheduleMessage,
            title: this.lang().RestoreSchedule,
         },
      };

      instance.result.then((result) => {
         if (result != 1) {
            return;
         }
         this.manageSchedule.restoreSingleSchedule(schedule.scheduleID).then((answer) => {
            if (!answer.data.success) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         });
      });
   }

   public close(): void {
      this.modalInstance.close();
   }
}
