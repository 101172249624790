import { Component, inject, input, output } from "@angular/core";
import {
   AlertComponent,
   OutlinedButtonComponent,
   PrimaryButtonComponent,
   ScrollContainerComponent,
} from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import {
   TaskFormComponent,
   type TaskDataInput,
} from "src/app/tasks/components/task-form/task-form.component";

@Component({
   selector: "task-form-step",
   templateUrl: "./task-form-step.component.html",
   styleUrls: ["./task-form-step.component.scss"],
   imports: [
      TaskFormComponent,
      AlertComponent,
      ScrollContainerComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
   ],
})
export class TaskFormStepComponent {
   protected readonly i18n = inject(TranslationService).i18n;
   public taskInput = input.required({
      transform: (value: TaskDataInput) => {
         value.displayType = "PMSuggestion";
         return value;
      },
   });
   public readonly deleteTemplate = output();
   public readonly nextStep = output();
}
