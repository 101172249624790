import { MetricSchedule } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion-schedule/metric-schedule";
import { TimeSchedule } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion-schedule/time-schedule";
import type {
   MetricScheduleDto,
   ScheduleDto,
   TimeScheduleDto,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/persistence/pm-suggestion-dto";

export type PmSuggestionSchedule = {
   getScheduleString: () => string;
   toDto: () => ScheduleDto;
   getType: () => "time" | "metric";
   getUnits: () => string;
   getInterval: () => number;
};

export function pmSuggestionScheduleFactory(dto: ScheduleDto): PmSuggestionSchedule {
   if (isTimeDto(dto)) {
      return TimeSchedule.fromDto(dto);
   } else if (isMetricDto(dto)) {
      return MetricSchedule.fromDto(dto);
   }
   throw new Error("Invalid schedule type");
}

function isTimeDto(dto: ScheduleDto): dto is TimeScheduleDto {
   return dto.type === "time";
}

function isMetricDto(dto: ScheduleDto): dto is MetricScheduleDto {
   return dto.type === "metric";
}
