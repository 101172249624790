import {
   inject,
   Component,
   computed,
   signal,
   type OnInit,
   type Signal,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   IconComponent,
   LimbleHtmlDirective,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { from } from "rxjs";
import { AssetTemplateSettingsService } from "src/app/assets/services/asset-template-settings.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";

type AssetHierarchyNode = HierarchyNode & {
   assetID?: number;
};

interface ActionButtonInfo {
   text: string;
   dataLogLabel: string;
   color?: "danger";
}

@Component({
   selector: "view-assets",
   templateUrl: "./viewAssets.modal.component.html",
   styleUrls: ["./viewAssets.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      HierarchyContainerLegacy,
      ModalFooterComponent,
      PrimaryButtonComponent,
      SecondaryButtonComponent,
      AlertComponent,
      IconComponent,
   ],
})
export class ViewAssets implements OnInit {
   public message = signal<string>("");
   public title = signal<string>("");
   public subTitle = signal<string>("");
   public assetsIDs = signal<number[]>([]);
   public assets: Lookup<"assetID", Asset> = new Lookup("assetID");
   public actionType = signal<"copy" | "delete" | "update" | null>(null);
   public buttonActionAll = signal<ActionButtonInfo | null>(null);
   public buttonActionTopLevel = signal<ActionButtonInfo | null>(null);
   public dataLogWorkflowLabel = signal<string>("");
   public data = computed(() => {
      return this.setData(this.assetsIDs());
   });
   public showUndoneWarning = signal<boolean>(false);
   public assetNodes: Lookup<"assetID", AssetHierarchyNode>;
   public hierarchyOptions: HierarchyOptions;

   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);
   private readonly assetTemplateSettingsService = inject(AssetTemplateSettingsService);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly featureFlagService = inject(FeatureFlagService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly assetTemplatesLaunchFlag: Signal<boolean | undefined> = toSignal(
      from(this.legacyLaunchFlagsService.isEnabled(Flags.ASSET_TEMPLATES)),
   );

   protected isAssetTemplatesEnabled = computed(() => {
      return (
         this.assetTemplatesLaunchFlag() &&
         this.featureFlagService.featureSet()?.has("assetTemplates")
      );
   });

   protected assetTemplatesRequired: boolean = false;
   protected assetFamilyHasTemplate: boolean = false;

   public readonly modalRef: LimUiModalRef<
      ViewAssets,
      "includeChildren" | "parentOnly" | undefined
   > = inject(LimUiModalRef);

   public constructor() {
      this.assetNodes = new Lookup("assetID");
      this.hierarchyOptions = {
         idKey: "assetID",
         selection: {
            selectionDisabled: true,
         },
         nodeButtons: [
            {
               tooltip: this.lang().OpenThisAsset,
               clickFunction: this.runPopAsset.bind(this),
               permissionNumber: this.credService.Permissions.ViewLookupAnAsset,
               text: this.lang().View,
            },
         ],
      };
   }

   public ngOnInit() {
      if (this.isAssetTemplatesEnabled()) {
         this.assetTemplateSettingsService.getAccountSettings().subscribe({
            next: (settings) => {
               this.assetTemplatesRequired = settings.templatesStatus === "required";
            },
            error: (err) => {
               console.error("Error fetching account settings", err);
            },
         });
      }
   }

   public setData(assetIDs: number[]): any[] {
      const assetData: any[] = [];
      for (const assetID of assetIDs) {
         const asset = this.manageAsset.getAsset(assetID);
         assert(asset);
         this.assets.set(assetID, asset);
      }
      assert(this.assets);
      this.assets.orderBy("assetName");

      for (const asset of this.assets) {
         //this loop must run before the next loop so all empty node arrays are built
         this.assetNodes?.set(asset.assetID, {
            icon: "cube",
            collapsed: false,
            selected: false,
            nodes: [],
            title: String(asset.assetName),
            displayButtons: true,
            locationID: asset.locationID,
            assetID: asset.assetID,
         });
      }
      for (const asset of this.assets) {
         assert(asset);
         const parentAssetView = this.findAssetNodeAtAnyDepth(asset?.parentAssetID ?? 0);
         if (parentAssetView) {
            const childAssetView = this.findAssetNodeAtAnyDepth(asset.assetID);
            assert(childAssetView);
            assert(parentAssetView);

            parentAssetView.nodes.push(childAssetView);
            this.assetNodes.delete(asset.assetID);
         }
      }

      for (const asset of this.assets) {
         const assetNode = this.assetNodes?.get(asset.assetID);
         if (assetNode?.nodes?.length) {
            assetData.push(assetNode);
         }
      }

      if (this.isAssetTemplatesEnabled()) {
         this.assetFamilyHasTemplate = true;
         for (const asset of this.assets) {
            if (asset.templateID === null || asset.templateID === undefined) {
               this.assetFamilyHasTemplate = false;
               break;
            }
         }
      }

      return assetData;
   }

   protected actionWithParentOnly(): void {
      this.modalRef.close("parentOnly");
   }

   protected dismissModal(): void {
      this.modalRef.close();
   }

   protected actionWithParentAndChildren(): void {
      this.modalRef.close("includeChildren");
   }

   public runPopAsset = (assetNode: AssetHierarchyNode) => {
      if (!assetNode.assetID) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 3000);
         return;
      }
      this.manageAsset.popAsset(assetNode.assetID);
   };

   protected goToHelpCenter() {
      window.open("https://help.limblecmms.com/en/articles/9559704", "_blank");
   }

   private findAssetNodeAtAnyDepth(assetID: number): AssetHierarchyNode | undefined {
      if (!assetID) {
         return undefined;
      }

      // First check if asset exists in the top level of assetNodes
      const directNode = this.assetNodes.get(assetID);
      if (directNode) {
         return directNode;
      }

      // Helper function to search through a node and its children
      const searchNode = (node: AssetHierarchyNode): AssetHierarchyNode | undefined => {
         // Check if this is the node we're looking for
         if (node.assetID === assetID) {
            return node;
         }

         // Search through children
         for (const childNode of node.nodes) {
            const found = searchNode(childNode);
            if (found) {
               return found;
            }
         }

         return undefined;
      };

      // Search through all top-level nodes
      for (const topLevelNode of this.assetNodes.values()) {
         const found = searchNode(topLevelNode);
         if (found) {
            return found;
         }
      }

      return undefined;
   }
}
