import type { HttpErrorResponse } from "@angular/common/http";
import {
   Component,
   computed,
   inject,
   Input,
   type OnInit,
   signal,
   type WritableSignal,
   DestroyRef,
   effect,
   type OnDestroy,
   type Signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   DropdownResultsPerPageComponent,
   DropdownTextItemComponent,
   PaginationComponent,
   PanelComponent,
   MinimalIconButtonComponent,
   ModalService,
   IconComponent,
   SearchAllWrapperComponent,
   SearchBoxComponent,
   TooltipDirective,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import {
   type Observable,
   ReplaySubject,
   catchError,
   of,
   tap,
   timer,
   type Subscription,
} from "rxjs";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ConfirmUnitOfMeasureModal } from "src/app/parts/components/confirm-uom-modal/confirm-uom.modal.component";
import { PartPurchasableAddModalComponent } from "src/app/parts/components/part-modal/components/part-purchasable-add-modal/part-purchasable-add-modal.component";
import { CountedAndMeasuredPurchasable } from "src/app/parts/purchasable/counted-and-measured-purchasable.model";
import { CountedPurchasable } from "src/app/parts/purchasable/counted-purchasable.model";
import { MeasuredPurchasable } from "src/app/parts/purchasable/measured-purchasable.model";
import { PurchasableApiService } from "src/app/parts/purchasable/purchasable-api.service";
import type { CreatePurchasableBodyDto } from "src/app/parts/purchasable/purchasable.dto.types";
import type { Purchasable } from "src/app/parts/purchasable/purchasable.model";
import { PurchasableService } from "src/app/parts/purchasable/purchasable.service";
import { StockUnitCalculatorService } from "src/app/parts/purchasable/services/stock-unit-calculator/stock-unit-calculator.service";
import type { Part } from "src/app/parts/types/part.types";
import { UnitConversionComponent } from "src/app/parts/unit-of-measure/components/unit-conversion/unit-conversion.component";
import { UnitLabelComponent } from "src/app/parts/unit-of-measure/components/unit-label/unit-label.component";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import type { Unit } from "src/app/parts/unit-of-measure/unit.types";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { EmptyHere } from "src/app/shared/components/global/emptyHere/emptyHere.element.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { SortColumn_refactor } from "src/app/shared/components/global/sortColumnModal/sortColumn_refactor.element.component";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { SliceLimbleMap } from "src/app/shared/pipes/sliceLimbleMap.pipe";
import { AlertService, type AlertType } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { SortColumnInfo } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { getComparator } from "src/app/shared/utils/sortingHelpers";
import { CredService } from "src/app/users/services/creds/cred.service";

type PurchasableForDisplay = PurchasablesListItem & {
   name: string;
   orderUnitDisplay: string;
   stockUnits: Signal<number | null>;
   purchasableSize: number;
   sizeUnit: Signal<Unit | null>;
   showStockUnitsEnclosure: boolean;
};

type PurchasablesListItem = {
   purchasableID: number;
   purchasable: Purchasable;
   orderUnit: Unit;
};

type uomModalParams = {
   title: string;
   alertText?: string;
   message: string;
   actionButtonText: string;
   dismissButtonText: string;
   showLearnMoreLink?: boolean;
   showSuccessMessage: boolean;
   isSmallConfirm?: boolean;
   showCheckbox?: boolean;
};

@Component({
   selector: "part-purchasables-list",
   templateUrl: "./part-purchasables-list.component.html",
   styleUrl: "./part-purchasables-list.component.scss",
   imports: [
      DropdownComponent,
      DropdownDividerComponent,
      DropdownItemComponent,
      DropdownResultsPerPageComponent,
      DropdownTextItemComponent,
      IconComponent,
      MinimalIconButtonComponent,
      NoSearchResults,
      PaginationComponent,
      PanelComponent,
      SearchAllWrapperComponent,
      SearchBoxComponent,
      SecondaryButtonComponent,
      SliceLimbleMap,
      SortColumn_refactor,
      TooltipDirective,
      UnitConversionComponent,
      UnitLabelComponent,
      EmptyHere,
   ],
   providers: [BetterDecimalPipe],
})
export class PartPurchasablesListComponent implements OnInit, OnDestroy {
   @Input() public part: Part | undefined;
   public orderBy;
   protected readonly i18n = inject(TranslationService).i18n;
   private readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly managePO = inject(ManagePO);
   private readonly purchaseableService = inject(PurchasableService);
   private readonly purchasableApiService = inject(PurchasableApiService);
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly destroyRef = inject(DestroyRef);
   private readonly credService = inject(CredService);
   private readonly paramsService = inject(ParamsService);
   private readonly stockUnitCalculatorService = inject(StockUnitCalculatorService);
   private readonly heapService = inject(HeapService);
   private static readonly SEARCH_FILTER_DUE_TIME = 300;

   protected readonly dataLogLabelAddButton = "manageUnitsOfMeasure_initiateAPurchasable";

   protected isUnitOfMeasureVisible = this.unitOfMeasureService.isFeatureEnabled;
   protected noSearchResults = false;
   protected stockUoM: WritableSignal<Unit | undefined> = signal(undefined);
   protected page = 1;
   protected itemsPerPage = 5;
   protected counter = Array<number>();
   protected originalPurchasables: Lookup<"purchasableID", PurchasableForDisplay> =
      new Lookup("purchasableID");
   protected purchasables: Lookup<"purchasableID", PurchasableForDisplay> =
      this.originalPurchasables;
   protected searchTerm = signal<string>("");
   protected readonly searchTermDebounced = signal<string>("");
   protected canEditPurchasable = false;
   protected canDeletePurchasable = false;
   protected maxLimit = 3;
   protected dropdownClicked: boolean = false;
   public searchHints: LimbleMap<number, string> = new LimbleMap();

   protected readonly sortChanges: ReplaySubject<
      SortColumnInfo<PurchasableForDisplay, "purchasableForDisplay">
   > = new ReplaySubject();
   protected sortInfo:
      | SortColumnInfo<PurchasableForDisplay, "purchasableForDisplay">
      | undefined;
   private readonly sortChangesSub: Subscription;
   public sortColumnInfo: Record<
      "name" | "orderUnitDisplay" | "purchasableSize",
      SortColumnInfo<PurchasableForDisplay, "purchasableForDisplay">
   >;

   // Computed signal to filter the purchasable list based on searchQuery
   filteredPurchasables = computed(() => {
      const query = this.searchTermDebounced().toLowerCase();
      return this.originalPurchasables.filter((purchasableItem) =>
         purchasableItem.purchasable.name()?.toLowerCase().includes(query),
      );
   });

   public constructor() {
      this.sortColumnInfo = {
         name: {
            columnName: this.i18n().t("Name"),
            sortProperty: "name",
            locationOfProperty: "purchasableForDisplay",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         orderUnitDisplay: {
            columnName: this.i18n().t("PurchasableOrderUnit"),
            sortProperty: "orderUnitDisplay",
            locationOfProperty: "purchasableForDisplay",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
         purchasableSize: {
            columnName: this.i18n().t("PurchasableSize"),
            sortProperty: "purchasableSize",
            locationOfProperty: "purchasableForDisplay",
            sortDirection: "ascending",
            sortChanges: this.sortChanges,
         },
      };

      this.sortChangesSub = this.sortChanges.subscribe((sortOptions) => {
         this.sortPurchasables(sortOptions);
      });

      effect(() => {
         const term = this.searchTerm().toLowerCase();
         if (term) {
            // Use RxJS timer to debounce
            timer(PartPurchasablesListComponent.SEARCH_FILTER_DUE_TIME).subscribe(() => {
               this.searchTermDebounced.set(term);
            });
         } else {
            this.searchTermDebounced.set("");
         }
      });
   }

   public ngOnInit(): void {
      this.fetchPurchasables();
      assert(this.part);
      this.canEditPurchasable = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.CreateAndEditPurchasables,
      );
      this.canDeletePurchasable = this.credService.isAuthorized(
         this.part.locationID,
         this.credService.Permissions.DeletePurchasables,
      );
   }

   private async fetchPurchasables(): Promise<void> {
      assert(this.part);
      await this.unitOfMeasureService.areUnitsInitialized;
      const stockUnit = this.unitOfMeasureService.getUnit(this.part.unitDescription)();
      assert(stockUnit !== null);
      this.stockUoM.set(stockUnit);
      this.originalPurchasables = new Lookup("purchasableID");
      this.purchaseableService.fetchByPart(this.part.partID).subscribe((purchasables) => {
         this.originalPurchasables = new Lookup("purchasableID");
         this.purchasables = new Lookup("purchasableID");
         purchasables.forEach((purchasable) => {
            const orderUnit = this.unitOfMeasureService.getUnit(
               purchasable.orderUnitDescription,
            )();
            assert(orderUnit !== null);

            const stockUnits = computed(() =>
               this.stockUnitCalculatorService.fromPurchasable(purchasable),
            );
            assert(stockUnits !== null);
            const purchasableID = purchasable.id;
            const listItem: PurchasablesListItem = {
               purchasableID,
               purchasable,
               orderUnit,
            };

            const tempPurchasableSize = computed(() =>
               purchasable instanceof CountedAndMeasuredPurchasable
                  ? purchasable.size
                  : stockUnits(),
            );
            const listItemForDisplay: PurchasableForDisplay = {
               ...listItem,
               name: purchasable.name(),
               orderUnitDisplay: orderUnit?.short() ?? "",
               stockUnits,
               purchasableSize: tempPurchasableSize() ?? 0,
               sizeUnit: computed(() =>
                  purchasable instanceof CountedAndMeasuredPurchasable
                     ? this.unitOfMeasureService.getUnit({
                          id: purchasable.providedSizeUnitID,
                          type: "provided",
                       })()
                     : stockUnit,
               ),
               showStockUnitsEnclosure:
                  purchasable instanceof CountedAndMeasuredPurchasable &&
                  this.stockUoM()?.id !== purchasable.providedSizeUnitID,
            };

            this.originalPurchasables.set(purchasable.id, listItemForDisplay);
            this.purchasables.set(purchasable.id, listItemForDisplay);
            return listItem;
         });
         this.generatePageArray();
      });
   }
   protected updatePaginationPreferences(itemsPerPage: number): void {
      this.itemsPerPage = itemsPerPage;
      this.generatePageArray();
   }

   protected generatePageArray(): void {
      const count = this.purchasables.size;
      const totalPages = Math.ceil(count / this.itemsPerPage);

      // Generate the counter array
      this.counter = [];
      for (let index = 1; index <= Math.min(totalPages, 5); index++) {
         this.counter.push(index * 5);
      }
   }

   protected updateSearchTerm(term: string): void {
      if (term === "" || term === null) {
         this.purchasables = this.originalPurchasables;
      } else {
         this.searchTerm.set(term);
         this.purchasables = this.filteredPurchasables();
      }
   }

   protected sortPurchasables(
      sortInfo: SortColumnInfo<PurchasableForDisplay, "purchasableForDisplay">,
   ) {
      if (!sortInfo) {
         return;
      }
      if (sortInfo.locationOfProperty === "purchasableForDisplay") {
         this.sortInfo = sortInfo;
         const comparator = getComparator(sortInfo.sortDirection);
         this.purchasables = this.purchasables.orderBy(sortInfo.sortProperty, comparator);
      }
   }

   protected createPurchasable(): void {
      const instance = this.modalService.open(PartPurchasableAddModalComponent);
      assert(this.part);
      instance.setInput("part", this.part);
      instance.setInput("editMode", false);
      instance.result.then(() => {
         this.fetchPurchasables();
      });
   }

   protected editPurchasable(purchasableItem: PurchasablesListItem): void {
      if (this.isPurchasableInUse(purchasableItem)) {
         this.showAlert(this.i18n().t("PurchasableCurrentlyInUseErrorText"), "danger");
         return;
      }
      const instance = this.modalService.open(PartPurchasableAddModalComponent);
      assert(this.part);
      instance.setInput("part", this.part);
      instance.setInput("editMode", true);
      instance.setInput("purchasable", purchasableItem.purchasable);
      this.heapService.trackEvent("manageUnitsOfMeasure_initiateEditAPurchasable");
      instance.result.then(() => {
         this.fetchPurchasables();
      });
   }
   protected deletePurchasable(purchasableItem: PurchasablesListItem): void {
      if (this.isPurchasableInUse(purchasableItem)) {
         this.showAlert(this.i18n().t("PurchasableCurrentlyInUseErrorText"), "danger");
         return;
      }

      this.heapService.trackEvent("manageUnitsOfMeasure_initiateDeleteAPurchasable");

      const modalInstance = this.modalService.open(ConfirmUnitOfMeasureModal);
      const modalParameters: uomModalParams = {
         title: `${this.i18n().t("DeletePurchasable")} - ${purchasableItem.purchasable.name()}`,
         message: `${this.i18n().t("AreYouSureYouWantToDeleteThisPurchasable")} 
                  <strong>${purchasableItem.purchasable.name()}</strong>
                  <br><br>${this.i18n().t("PurchasableDeleteWarning")}`,
         alertText: this.i18n().t("ConfirmCheckboxTextUnitOfMeasureModal"),
         actionButtonText: this.i18n().t("Yes"),
         dismissButtonText: this.i18n().t("No"),
         showLearnMoreLink: false,
         showSuccessMessage: false,
         isSmallConfirm: true,
      };

      this.paramsService.params = {
         modalInstance: modalInstance,
         resolve: modalParameters,
      };

      modalInstance.result.then((confirmed) => {
         if (confirmed) {
            assert(this.part);
            assert(purchasableItem.purchasable.id);
            this.callDeleteAPIService(this.part.partID, purchasableItem.purchasable.id);
         }
      });
   }

   private isPurchasableInUse(purchasableItem: PurchasablesListItem): boolean {
      const poItems = this.managePO.getPurchaseOrderItems();
      const poItemsWithSelectedPurchasable = poItems.filter(
         (poItem) =>
            poItem.partID === this.part?.partID &&
            poItem.purchasableID === purchasableItem.purchasableID,
      );

      return poItemsWithSelectedPurchasable.size > 0;
   }

   protected copyPurchasable(purchasableItem: PurchasablesListItem): void {
      const { purchasable } = purchasableItem;
      const basePurchasable: Partial<CreatePurchasableBodyDto> = {
         name: `${purchasable.name()} - Copy`,
         orderUnitDescription: purchasable.orderUnitDescription,
      };

      const newPurchasable = this.createPurchasableCopy(purchasable, basePurchasable);
      this.heapService.trackEvent("manageUnitsOfMeasure_initiateCopyAPurchasable");
      if (newPurchasable) {
         this.callCreateAPIService(purchasable.partID, newPurchasable);
      }
   }

   // Helper method that determines the type and calls the correct method
   private createPurchasableCopy(
      purchasable: any,
      basePurchasable: Partial<CreatePurchasableBodyDto>,
   ): CreatePurchasableBodyDto | undefined {
      if (purchasable instanceof MeasuredPurchasable) {
         return this.createMeasuredPurchasable(basePurchasable);
      } else if (
         purchasable instanceof CountedPurchasable &&
         purchasable instanceof CountedAndMeasuredPurchasable === false
      ) {
         return this.createCountedPurchasable(basePurchasable, purchasable);
      } else if (purchasable instanceof CountedAndMeasuredPurchasable) {
         return this.createCountedAndMeasuredPurchasable(basePurchasable, purchasable);
      }
      return undefined; // Handle unknown purchasable types if necessary
   }

   // Separate methods for each purchasable type
   private createMeasuredPurchasable(
      base: Partial<CreatePurchasableBodyDto>,
   ): CreatePurchasableBodyDto {
      const newPurchasable: CreatePurchasableBodyDto = {
         ...(base as CreatePurchasableBodyDto),
      };
      return newPurchasable;
   }

   private createCountedPurchasable(
      base: Partial<CreatePurchasableBodyDto>,
      purchasable: CountedPurchasable,
   ): CreatePurchasableBodyDto {
      const newPurchasable: CreatePurchasableBodyDto = {
         ...(base as CreatePurchasableBodyDto),
         size: purchasable.size,
      };
      return newPurchasable;
   }

   private createCountedAndMeasuredPurchasable(
      base: Partial<CreatePurchasableBodyDto>,
      purchasable: CountedAndMeasuredPurchasable,
   ): CreatePurchasableBodyDto {
      const result: CreatePurchasableBodyDto = {
         ...(base as CreatePurchasableBodyDto),
         size: purchasable.size,
         providedSizeUnitID: purchasable.providedSizeUnitID,
      };
      return result;
   }

   private callCreateAPIService(
      partID: number,
      purchasableDto: CreatePurchasableBodyDto,
   ): void {
      this.purchasableApiService
         .createPurchasable(partID, purchasableDto)
         .pipe(
            tap(() => {
               this.handleSuccess();
            }),
            catchError((error) => this.handleError(error)),
            takeUntilDestroyed(this.destroyRef),
         )
         .subscribe();
   }

   private callDeleteAPIService(partID: number, purchasableID: number): void {
      this.purchasableApiService
         .deletePurchasable(partID, purchasableID)
         .pipe(
            tap(() => {
               this.handleSuccess();
            }),
            catchError((error) => this.handleError(error)),
            takeUntilDestroyed(this.destroyRef),
         )
         .subscribe();
   }

   private handleSuccess(): void {
      this.alertService.addAlert(this.i18n().t("successMsg"), "success", 6000);
      this.fetchPurchasables();
   }

   private handleError(error: HttpErrorResponse): Observable<null> {
      assert(error.error);
      if (error.error.message === "A purchasable with this name already exists.") {
         this.showAlert(this.i18n().t("DuplicatePurchasableErrorText"), "warning");
      }
      return of(null);
   }

   private showAlert(message: string, type: AlertType): void {
      this.alertService.addAlert(message, type, 6000);
   }

   public ngOnDestroy(): void {
      this.sortChangesSub.unsubscribe();
   }
}
