import { inject, Injectable } from "@angular/core";
import { datadogLogs } from "@datadog/browser-logs";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";

@Injectable({ providedIn: "root" })
export class TaskFormHelperService {
   private readonly featureFlagService = inject(FeatureFlagService);

   public getInstructionParent(item: any, items: any[]): any {
      return items.find(
         (parentInstruction) =>
            parentInstruction.checklistItemCount === item.itemParentID,
      );
   }

   public doWeCheck(item: any, items: any[], currentStack: number = 1) {
      // Define a max stack to prevent infinite recursion.
      const MAX_STACK = 500;

      if (currentStack > MAX_STACK) {
         return false;
      }

      // Avoid infinite recursion for case where A is parent of B and B is parent of A.

      if (item.parent?.parent?.itemID === item.itemID) {
         return false;
      }

      const instructionParent = this.getInstructionParent(item, items);

      if (instructionParent === undefined && item.itemParentID > 0) {
         // Handle the rare case where items are hanging off of a non-existent parent.
         datadogLogs.logger.error("Item is hanging off of a non-existent parent", {
            item: item,
            items: items,
         });
         return false;
      }
      if (instructionParent === undefined) {
         return true;
      }

      const parentResponse = instructionParent.itemResponse;
      const parentTypeID = instructionParent.itemTypeID;

      if (
         Number(parentResponse) === Number(item.itemParentResponse) ||
         item.itemParentID === 0 ||
         (parentTypeID > 0 &&
            parentTypeID !== TaskInstructionTypeID.OptionList &&
            parentTypeID !== TaskInstructionTypeID.DropdownList)
      ) {
         let returnVal = true;

         if (instructionParent) {
            returnVal = this.doWeCheck(instructionParent, items, currentStack + 1);
         }

         return returnVal;
      }
      return false;
   }

   public calcCompletion(
      items: any[],
      checkingAfterApprovalItemID?: number,
   ): {
      totalItems: number;
      totalCompleteItems: number;
      complete: boolean;
   } {
      let totalItems = 0;
      let totalCompleteItems = 0;
      let complete = true;

      if (items) {
         for (const item of items) {
            // If a user just approved an item, we should opportunistically not include it as part of the completed calculation.
            if (
               item.itemTypeID === TaskInstructionTypeID.RequestApproval &&
               item.itemID === checkingAfterApprovalItemID
            ) {
               continue;
            }
            if (
               (item.itemTypeID === TaskInstructionTypeID.VerifyLocation ||
                  item.itemTypeID === TaskInstructionTypeID.RequestApproval) &&
               !this.featureFlagService
                  .featureSetDefaulted()
                  .has("customApprovalInstruction")
            ) {
               item.done = true;
               continue;
            }

            if (
               item.itemTypeID === TaskInstructionTypeID.Note ||
               item.itemTypeID === TaskInstructionTypeID.InstructionSet
            ) {
               item.done = true;
               continue;
            }

            if (item.itemTypeID === TaskInstructionTypeID.FileOrPictureAttachment) {
               if (
                  this.featureFlagService
                     .featureSetDefaulted()
                     .has("requirePhotoInstruction")
               ) {
                  //feature flag to require photo instruction
               } else {
                  continue;
               }
            }

            if (
               item.itemTypeID === TaskInstructionTypeID.AssignAuditPM &&
               item.checklistToSpawn === null
            ) {
               continue;
            }

            if (
               item.itemTypeID === TaskInstructionTypeID.AssignPM &&
               item.checklistToSpawn === null
            ) {
               continue;
            }

            if (this.doWeCheck(item, items)) {
               totalItems += 1;

               if (item.done) {
                  totalCompleteItems += 1;
               } else {
                  complete = false;
               }
            }
         }
      }

      return {
         totalItems: totalItems,
         totalCompleteItems: totalCompleteItems,
         complete: complete,
      };
   }
}
