import { ColumnKeys } from "src/app/shared/data-viewer/column-builder/column-builder.models";
import {
   AssetNameCellRendererComponent,
   CompletedByCellRendererComponent,
   CompletedOnCellRendererComponent,
   DueDateCellRendererComponent,
   IdCellRendererComponent,
   LatestActivityCellRendererComponent,
   ReviewerCellRendererComponent,
   SubmissionNameCellRendererComponent,
   SubmittedByCellRendererComponent,
   SubmittedOnCellRendererComponent,
   TaskActionsCellRendererComponent,
   TaskIDCellRendererComponent,
   TaskNameCellRendererComponent,
   TaskStatusCellRendererComponent,
   TaskTypeCellRendererComponent,
   TimeSpentCellRendererComponent,
   WorkRequestActionsCellRendererComponent,
} from "src/app/shared/empowered/base/table/components/cells-renderers";
import { TaskPriorityCellRendererComponent } from "src/app/shared/empowered/base/table/components/cells-renderers/task-priority-cell-renderer/task-priority-cell-renderer.component";
import type { TableColumn } from "src/app/shared/empowered/base/table/table.models";

export class TableColumnBuilder {
   private columns: Array<TableColumn> = new Array<TableColumn>();

   public build(): TableColumn[] {
      const columns = Object.assign(this.columns);
      this.columns = new Array<TableColumn>();
      return columns;
   }

   public addTaskID(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.TASK_ID,
         headerNameKey: "ID",
         sortBy: "checklistID",
         params: { includeDeletedAssets: true },
         exportableFields: ["checklistID"],
         cellRenderer: TaskIDCellRendererComponent,
         minWidth: 50,
         ...columnOverrides,
      };

      this.columns.push(column);
      return this;
   }

   public addTaskStatus(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: "",
         headerNameKey: "Status",
         sortBy: "status",
         cellRenderer: TaskStatusCellRendererComponent,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskPriority(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: "",
         headerNameKey: "Priority",
         sortBy: "priority",
         cellRenderer: TaskPriorityCellRendererComponent,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskName(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.TASK_NAME,
         headerNameKey: "Name",
         sortBy: "checklistName",
         extraColumns: ["assets", "locationName", "partsDetails", "commentsDetails"],
         params: { includeDeletedAssets: true },
         exportableFields: ["checklistName"],
         cellRenderer: TaskNameCellRendererComponent,
         minWidth: 150,
         ...columnOverrides,
      };

      this.columns.push(column);
      return this;
   }

   public addTaskDueDate(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.DUE_DATE,
         headerNameKey: "Due",
         sortBy: "checklistDueDate",
         exportableFields: ["checklistDueDate"],
         cellRenderer: DueDateCellRendererComponent,
         minWidth: 50,
         ...columnOverrides,
      };

      this.columns.push(column);
      return this;
   }

   public addTaskCompletedOn(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.COMPLETED_ON,
         headerNameKey: "Completed",
         sortBy: "checklistCompletedDate",
         extraColumns: ["completionColor"],
         exportableFields: ["checklistCompletedDate"],
         cellRenderer: CompletedOnCellRendererComponent,
         minWidth: 150,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskCompletedBy(columnOverrides?: Partial<TableColumn>): this {
      const column = {
         field: ColumnKeys.COMPLETED_BY,
         headerNameKey: "CompletedBy",
         sortBy: "completedBy",
         exportableFields: ["completedBy"],
         cellRenderer: CompletedByCellRendererComponent,
         minWidth: 150,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskTimeSpent(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.TIME_SPENT,
         headerNameKey: "CompletionTime",
         sortBy: "timeSpentSecs",
         extraColumns: ["timeSpentSecs"],
         exportableFields: ["checklistPromptTime"],
         cellRenderer: TimeSpentCellRendererComponent,
         minWidth: 150,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskLatestActivityTime(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.LATEST_ACTIVITY_TIME,
         headerNameKey: "LatestActivity",
         sortBy: "checklistLastEdited",
         exportableFields: ["checklistLastEdited"],
         cellRenderer: LatestActivityCellRendererComponent,
         minWidth: 180,
         ...columnOverrides,
      };

      this.columns.push(column);
      return this;
   }
   public addTaskActions(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: "",
         headerNameKey: "",
         cellRenderer: TaskActionsCellRendererComponent,
         frozen: true,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addWRActions(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: "",
         headerNameKey: "Actions",
         cellRenderer: WorkRequestActionsCellRendererComponent,
         frozen: true,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addAssetName(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.ASSET_NAME,
         headerNameKey: "Asset",
         sortBy: "assetName",
         exportableFields: ["assetName"],
         cellRenderer: AssetNameCellRendererComponent,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addWRSubmissionName(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.WR_SUBMISSION_NAME,
         headerNameKey: "Name",
         sortBy: "name",
         extraColumns: [],
         cellRenderer: SubmissionNameCellRendererComponent,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addWRSubmissionStatus(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.WR_SUBMISSION_STATUS,
         headerNameKey: "Status",
         sortBy: "",
         extraColumns: [],
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addWRSubmissionID(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.WR_SUBMISSION_ID,
         headerNameKey: "ID",
         sortBy: "id",
         extraColumns: [],
         cellRenderer: IdCellRendererComponent,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addSubmittedBy(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.SUBMITTED_BY,
         headerNameKey: "SubmittedBy",
         sortBy: "submittedBy",
         extraColumns: [],
         cellRenderer: SubmittedByCellRendererComponent,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addSubmittedOn(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.SUBMITTED_ON,
         headerNameKey: "SubmittedOn",
         sortBy: "submittedOn",
         extraColumns: [],
         cellRenderer: SubmittedOnCellRendererComponent,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addReviewer(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.REVIEWER,
         headerNameKey: "Reviewer",
         sortBy: "reviewer",
         extraColumns: [],
         cellRenderer: ReviewerCellRendererComponent,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }

   public addTaskType(columnOverrides?: Partial<TableColumn>): this {
      const column: TableColumn = {
         field: ColumnKeys.TASK_TYPE,
         headerNameKey: "Type",
         sortBy: "checklistTemplateOld",
         cellRenderer: TaskTypeCellRendererComponent,
         minWidth: 50,
         ...columnOverrides,
      };
      this.columns.push(column);
      return this;
   }
}
