<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header
      title="{{ lang().IntroducingLimbleSensorTech }}"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="panel-content">
            <div class="content-label">
               {{ lang().LimbleSensorsAreDevicesThatProvideLiveData }}
            </div>
            <div>
               {{ lang().SensorPitchDescription }}
            </div>
         </div>
         <div class="panel-content">
            <div class="content-label">
               {{ lang().ReadAboutHowSensorsCouldMakeYourMaintenanceEasy }}
            </div>
            <lim-ui-secondary-button (click)="blogpost()">
               {{ lang().LearnMore }}
            </lim-ui-secondary-button>
         </div>
         <div class="panel-content">
            <div class="content-label">
               {{ lang().TalkToUsAboutSettingUpSensorsAtYourFacility }}
            </div>
            <lim-ui-primary-button (click)="contact()">
               {{ lang().ContactUs }}
            </lim-ui-primary-button>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
