import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input } from "@angular/core";
import { TooltipDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";

@Component({
   selector: "task-completed-on-cell",
   imports: [CommonModule, BetterDatePipe, TooltipDirective],
   templateUrl: "./task-completed-on-cell.component.html",
   styleUrls: ["./task-completed-on-cell.component.scss"],
})
export class TaskCompletedOnCellComponent {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;

   @Input() public column?: Column;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
}
