<div class="listTable lim-ui-responsive-table scroll-height-inheritance">
   @if (!noSearchResults()) {
      <div class="listItem lim-ui-responsive-table-header">
         <sort-column_refactor
            class="cell-container col-md-2 column-header"
            [options]="sortColumnInfo.poNumber"
         />
         @if (!excludeVendorColumn()) {
            <sort-column_refactor
               class="cell-container col-md-2 column-header"
               [options]="sortColumnInfo.vendor"
            />
         }
         <sort-column_refactor
            class="cell-container col-md-2 column-header"
            [options]="sortColumnInfo.orderDate"
         />
         <sort-column_refactor
            class="cell-container col-md-2 column-header"
            [options]="sortColumnInfo.deliveryDate"
         />
         <sort-column_refactor
            class="cell-container col-md-2 column-header"
            [options]="sortColumnInfo.status"
         />
         <sort-column_refactor
            class="cell-container col-md-2 column-header"
            [options]="sortColumnInfo.assignedTo"
         />
      </div>
   }

   @if (noSearchResults()) {
      <span>
         <no-search-results />
      </span>
   }

   @for (
      purchaseOrder of purchaseOrdersForDisplay()
         | sliceLimbleMap: (page - 1) * itemsPerPage : page * itemsPerPage;
      track purchaseOrder
   ) {
      <div class="listItem lim-ui-responsive-table-row">
         <div class="cell-container col-md-2 lim-ui-responsive-table-cell">
            <div class="name-and-icons-container">
               <lim-ui-icon icon="filePowerpoint" />
               <div
                  (click)="popPurchaseOrder(purchaseOrder.poID)"
                  class="table-column-badge-and-name-column"
               >
                  <a class="table-link"
                     >{{ purchaseOrder.purchaseOrderNumberForDisplay }}
                  </a>
                  @if (searchHints?.get(purchaseOrder.poID); as searchHint) {
                     <div
                        class="table-column-badge lim-ui-show-on-desktop"
                        [limbleHtml]="searchHint"
                     ></div>
                  }
               </div>
            </div>
         </div>

         @if (!excludeVendorColumn()) {
            <div class="cell-container col-md-2 lim-ui-responsive-table-cell">
               <div class="name-and-icons-container">
                  @if (purchaseOrder.vendorNameStr) {
                     <lim-ui-icon icon="addressCard" />
                     <a (click)="popVendor(purchaseOrder.vendorID)" class="table-link">
                        {{ purchaseOrder.vendorNameStr }}
                     </a>
                  }
               </div>
            </div>
         }

         <div class="cell-container col-md-2 lim-ui-responsive-table-cell">
            <span class="lim-ui-mobile-table-column-label mobile-label"
               ><lim-ui-icon icon="calendar"
            /></span>
            {{ purchaseOrder.dateDisplay | betterDate: "date" }}
         </div>

         <div class="cell-container col-md-2 lim-ui-responsive-table-cell">
            <po-delivery-date
               [poID]="purchaseOrder.poID"
               [partIDToFilterTo]="partID ?? undefined"
            />
         </div>

         <div class="cell-container col-md-2 lim-ui-responsive-table-cell">
            <span class="lim-ui-mobile-table-column-label mobile-label"
               >{{ lang().Status }}:</span
            >
            {{ purchaseOrder.currentStatusName }}
         </div>

         <div class="cell-container col-md-2 lim-ui-responsive-table-cell">
            <span class="lim-ui-mobile-table-column-label mobile-label"
               ><lim-ui-icon icon="user"
            /></span>
            <a
               (click)="changePOAssignment(purchaseOrder.poID); $event.stopPropagation()"
               class="table-link"
               [limbleHtml]="purchaseOrder.assignedToName"
            ></a>
         </div>
      </div>
   }

   @if (
      purchaseOrdersForDisplay().size && purchaseOrdersForDisplay().size > itemsPerPage
   ) {
      <div class="table-footer" panelFooter>
         <lim-ui-pagination
            [maxSize]="5"
            [rotate]="true"
            [(page)]="page"
            [pageSize]="itemsPerPage"
            [collectionSize]="purchaseOrdersForDisplay().size ?? 0"
         />

         <lim-ui-dropdown-results-per-page
            [selectedValue]="itemsPerPage"
            [label]="lang().itemsPerPage"
         >
            <ng-container menu>
               @for (num of ["10", "20", "50", "100"]; track num) {
                  <lim-ui-dropdown-text-item
                     [displayString]="num"
                     [outputString]="num"
                     (itemSelectedEvent)="updateUserUIPreferences(num)"
                  />
               }
            </ng-container>
         </lim-ui-dropdown-results-per-page>
      </div>
   }
</div>
