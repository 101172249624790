@if (part) {
   <div
      [ngClass]="{
         'flex-field-direction-column': usedIn === 'table',
         'flex-field-direction-row': usedIn === 'listItem',
      }"
   >
      <div
         class="part-number-info"
         [ngClass]="{ 'full-width-input-wrapper-with-buttons': usedIn === 'listItem' }"
      >
         @if (credChangePartNumber && part.partDeleted == 0) {
            <div
               class="table-field-text growing-field"
               [(ngModel)]="part.partNumber"
               (afterEdit)="updatePartNumber()"
               limbleContentEditable
            ></div>
         }
      </div>
      <div class="part-number-related-info">
         @for (relation of partVendorRelationsDisplay; track relation) {
            <div>
               @if (
                  relation.partNumber &&
                  relation.partNumber.length > 0 &&
                  relation.vendorID
               ) {
                  <span class="table-field-text">
                     <span (click)="popVendor(relation.vendorID)"
                        ><a class="cursor">{{ relation.partNumber }}</a>
                     </span>
                     <span> {{ lang().from }}</span>
                     <span (click)="popVendor(relation.vendorID)"
                        ><a class="cursor"> {{ relation.vendorName }}</a>
                     </span>
                  </span>
               }
            </div>
         }

         @if (!credChangePartNumber || part.partDeleted == 1) {
            <div class="table-field-text">
               <span [limbleHtml]="part.partNumber"></span>
            </div>
         }
      </div>
   </div>
}
