import { inject, Injectable, signal, type OnDestroy } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { filter, type Subscription } from "rxjs";
import { UpdateToastService } from "src/app/shared/services/update/update-toast/update-toast.service";

/**
 * Manages the update lifecycle of the Angular application
 */
@Injectable({ providedIn: "root" })
export class UpdateService implements OnDestroy {
   public readonly localVersion = signal<string | null>(null);
   private readonly updateToastService = inject(UpdateToastService);
   private readonly router = inject(Router);
   private readonly navigations: Subscription;

   public constructor() {
      this.navigations = this.watchNavigations();
   }

   public ngOnDestroy(): void {
      this.navigations.unsubscribe();
   }

   /**
    * Manually check for updates by comparing frontend and backend version numbers. This
    * check will work even if the service worker is not enabled.
    */
   public checkVersions(backendVersion: string): void {
      if (this.localVersion() === null) {
         // This must be the first time this method has been called since angular was
         // bootstrapped, so we have to set the localVersion for future checks.
         this.localVersion.set(backendVersion);
         return;
      }
      if (backendVersion === this.localVersion()) {
         // Versions match, no update needed.
         return;
      }
      // Versions don't match. This means the backend has been updated since the last
      // check, which is a good indicator that the frontend might be outdated.
      this.updateToastService.show();
   }

   private watchNavigations(): Subscription {
      return (
         this.router.events
            // We don't necessarily want to wait for the navigation to finish, since we
            // have to reload it anyway. We can potentially reload as soon as we know
            // the destination URL.
            .pipe(
               filter(
                  (event): event is RoutesRecognized => event instanceof RoutesRecognized,
               ),
            )
            .subscribe((event) => {
               if (this.updateToastService.isShown() === false) return;
               // Reload the page to update to the latest version.
               // Use the destination URL instead of just reloading the current URL,
               // so that the navigation doesn't seem to have been cancelled.
               document.location = event.urlAfterRedirects;
            })
      );
   }
}
