<div class="part-wrapper">
   <lim-ui-panel [noSidePadding]="true">
      <div class="header">
         <div class="text-with-button">
            <span class="panel-text">
               <lim-ui-icon icon="gears" />
               {{ lang().AssociatedParts }}
            </span>
            <lim-ui-primary-button (click)="associatePartsToAsset()">{{
               lang().AssociateParts
            }}</lim-ui-primary-button>
         </div>

         <lim-ui-search-all-wrapper>
            <lim-ui-search-box
               searchBox
               [(searchVal)]="search"
               (searchValChange)="filterPartNamesToSearch()"
               [placeholder]="lang().Search"
            />
         </lim-ui-search-all-wrapper>
      </div>
      <div class="list-items">
         @if (noSearchResults) {
            <span>
               <no-search-results />
            </span>
         }
         @if (isLoading) {
            <ngx-skeleton-loader count="3" [theme]="skeletonThemes.fillSize" /><br />
         } @else {
            @for (partAndRelation of filteredPartsAndRelations; track partAndRelation) {
               <div class="lim-ui-list-item">
                  <lim-ui-icon
                     class="lim-ui-hide-on-mobile"
                     icon="gears"
                     iconSize="small"
                  />
                  <div class="arrow-adjustable-input">
                     <input
                        type="number"
                        min="1"
                        [disabled]="!canEditPartsCred"
                        [(ngModel)]="partAndRelation.assetPartQty"
                        (blur)="
                           updateAssetPartQty(
                              partAndRelation.partID,
                              partAndRelation.assetPartQty
                           )
                        "
                     />
                  </div>
                  @if (unitOfMeasureService.isFeatureEnabled()) {
                     <div
                        [limUiTooltip]="
                           partAndRelation.partID ?? 0 | partUnitOfMeasure: 'singular'
                        "
                     >
                        {{ partAndRelation.partID ?? 0 | partUnitOfMeasure: "short" }}
                     </div>
                  }
                  @if (
                     manageParts.getSingleCalculatedPartInfo(partAndRelation.partID ?? 0);
                     as calculatedPartInfo
                  ) {
                     <span>
                        <a (click)="popPart(partAndRelation)" class="table-link">
                           <span [limbleHtml]="partAndRelation.partName"></span>
                           <span [hidden]="!partAndRelation.partNumber">
                              - <span [limbleHtml]="partAndRelation.partNumber"></span
                           ></span>
                        </a>
                        - {{ lang().Qty }}: {{ calculatedPartInfo.totalQty }}
                        @if (unitOfMeasureService.isFeatureEnabled()) {
                           <span
                              [limUiTooltip]="
                                 partAndRelation.partID ?? 0
                                    | partUnitOfMeasure: 'singular'
                              "
                              >{{
                                 partAndRelation.partID ?? 0 | partUnitOfMeasure: "short"
                              }}</span
                           >
                        }
                        -
                        @if (partAndRelation?.partLocation) {
                           <span
                              ><span [limbleHtml]="partAndRelation.partLocation"></span>
                              -
                           </span>
                        }
                        @if (partAndRelation.locationName) {
                           <span class="house-wrapper">
                              <lim-ui-icon icon="houseChimney" />
                              <span [limbleHtml]="partAndRelation.locationName"></span>
                           </span>
                        }
                     </span>
                  }
                  <div class="relation-icons">
                     @if (
                        partAndRelation.association_type === AssociationType.Automatic
                     ) {
                        <lim-ui-icon
                           placement="left"
                           limUiTooltip="{{ lang().PartAutomaticAssociationTooltip }}"
                           icon="wandMagicSparkles"
                        />
                     }
                     <lim-ui-minimal-icon-button
                        (click)="deletePartRelation(partAndRelation)"
                        placement="left"
                        limUiTooltip="{{ lang().RemoveAssociation }}"
                        icon="trashCanRegular"
                        iconColor="danger"
                        [superMinimal]="true"
                     />
                  </div>
               </div>
            }
         }
      </div>
   </lim-ui-panel>
   <div class="panel-body panel-middleGreen" style="border-bottom: 0">
      <view-list-of-any-obj
         [objs]="listParts"
         [columns]="columns"
         [options]="options"
         [tableInScrollablePage]="true"
      />
   </div>
</div>
