import { Injectable } from "@angular/core";
import { CountedAndMeasuredPurchasable } from "src/app/parts/purchasable/counted-and-measured-purchasable.model";
import { CountedPurchasable } from "src/app/parts/purchasable/counted-purchasable.model";
import { MeasuredPurchasable } from "src/app/parts/purchasable/measured-purchasable.model";
import type {
   PurchasableCountedAndMeasuredDto,
   PurchasableCountedDto,
   PurchasableDto,
} from "src/app/parts/purchasable/purchasable.dto.types";
import type { Purchasable } from "src/app/parts/purchasable/purchasable.model";

@Injectable({ providedIn: "root" })
export class PurchasableMappingService {
   public mapDtos(dtos: Array<PurchasableDto>): Array<Purchasable> {
      return dtos.map((dto) => this.mapDto(dto));
   }

   private mapDto(dto: PurchasableDto): Purchasable {
      if (this.isCountedDto(dto)) {
         return this.isCountedAndMeasuredDto(dto)
            ? new CountedAndMeasuredPurchasable(dto)
            : new CountedPurchasable(dto);
      }

      return new MeasuredPurchasable(dto);
   }

   private isCountedDto(dto: PurchasableDto): dto is PurchasableCountedDto {
      return Object.hasOwn(dto, "size");
   }

   private isCountedAndMeasuredDto(
      dto: PurchasableCountedDto,
   ): dto is PurchasableCountedAndMeasuredDto {
      return Object.hasOwn(dto, "providedSizeUnitID");
   }
}
