import {
   type DataViewerFilter,
   DataViewerFilterType,
   FilterLabelKey,
} from "src/app/shared/data-viewer/data-viewer-filters/data-viewer-filter.models";

export class DataViewerFiltersBuilder {
   private filters = new Array<DataViewerFilter>();

   public build(): DataViewerFilter[] {
      const filters = Object.assign(this.filters);
      this.filters = new Array<DataViewerFilter>();
      return filters;
   }

   public addDate(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.DATE,
         labelKey: FilterLabelKey.COMPLETED_DATE,
         key: "completed",
         ...overrides,
      });

      return this;
   }

   public addLocationFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.LOCATION,
         labelKey: FilterLabelKey.LOCATION,
         key: "locationIDs",
         ...overrides,
      });
      return this;
   }

   public addTaskTypesFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.TASK_TYPE,
         labelKey: FilterLabelKey.TASK_TYPE,
         key: "taskTypes",
         ...overrides,
      });
      return this;
   }

   public addCompletedByFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.COMPLETED_BY,
         labelKey: FilterLabelKey.USER,
         key: "completedBy",
         ...overrides,
      });
      return this;
   }

   public addAssignedToFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.ASSIGNED_TO,
         labelKey: FilterLabelKey.ASSIGNED_TO,
         key: "assignedTo",
         ...overrides,
      });
      return this;
   }

   public addAssignedToByLocationFilter(
      locationID: number,
      overrides?: Partial<DataViewerFilter>,
   ): this {
      this.filters.push({
         type: DataViewerFilterType.ASSIGNED_TO,
         labelKey: FilterLabelKey.ASSIGNED_TO,
         key: "assignedToByLocation",
         options: {
            locationID,
         },
         ...overrides,
      });
      return this;
   }

   public addCustomTagsFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.CUSTOM_TAG,
         labelKey: FilterLabelKey.CUSTOM_TAGS,
         key: "customTags",
         ...overrides,
      });
      return this;
   }

   public addPriorityLevelFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.PRIORITY_LEVEL,
         labelKey: FilterLabelKey.PRIORITY_LEVEL,
         key: "priorityLevels",
         ...overrides,
      });
      return this;
   }

   public addPriorityIDFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.PRIORITY_LEVEL,
         labelKey: FilterLabelKey.PRIORITY_LEVEL,
         key: "priorityIDs",
         ...overrides,
      });
      return this;
   }

   public addTaskStatusFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.TASK_STATUS,
         labelKey: FilterLabelKey.STATUS,
         key: "statuses",
         ...overrides,
      });
      return this;
   }

   public addTaskDueDateFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.DATE,
         labelKey: FilterLabelKey.DUE_DATE,
         key: "due",
         ...overrides,
      });
      return this;
   }

   public addTaskCreatedDateFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.DATE,
         labelKey: FilterLabelKey.CREATED_DATE,
         key: "created",
         ...overrides,
      });
      return this;
   }

   public addTaskAssetFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.Asset,
         labelKey: FilterLabelKey.ASSET_NAME,
         key: "assetIDs",
         ...overrides,
      });
      return this;
   }

   public addWorkRequestStatusFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.WORK_REQUEST_STATUS,
         labelKey: FilterLabelKey.STATUS,
         key: "statuses",
         ...overrides,
      });
      return this;
   }

   public addWorkRequestCreatedDateFilter(overrides?: Partial<DataViewerFilter>): this {
      this.filters.push({
         type: DataViewerFilterType.DATE,
         labelKey: FilterLabelKey.CREATED_DATE,
         key: "createdDate",
         ...overrides,
      });
      return this;
   }
}
