import type { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { Component, computed, inject, Input, signal } from "@angular/core";
import {
   CardComponent,
   CheckboxComponent,
   DropdownButtonComponent,
   DropdownDateRangePickerComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   IconComponent,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import {
   ArcElement,
   BarController,
   BarElement,
   CategoryScale,
   Chart,
   type ChartData,
   type ChartOptions,
   type Color,
   DoughnutController,
   Legend,
   Tooltip,
} from "chart.js";
import type { InteractionItem } from "chart.js/dist/core/core.interaction";
import $ from "jquery";
import moment from "moment";
import { combineLatestWith, debounceTime, first, Subject, type Subscription } from "rxjs";
import { ChangeFieldsAssetCOOGraph } from "src/app/assets/components/changeFieldsAssetsCOOGraphModal/changeFieldsAssetCOOGraph.modal.component";
import { DepreciationLineChartCard } from "src/app/assets/components/depreciationLineChartElement/depreciationLineChartCard.element.component";
import { ViewSingleAssetBarReport } from "src/app/assets/components/viewSingleAssetBarReportModal/viewSingleAssetBarReport.modal.component";
import { CountUpDirective } from "src/app/assets/directives/countUp/countUp.directive";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ChartJSHelper } from "src/app/dashboards/widgets/chartJSHelper";
import { ManageLang } from "src/app/languages/services/manageLang";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import type { Schedule } from "src/app/schedules/schedule.types";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { ViewListModal } from "src/app/shared/components/global/lists/viewList.modal.component";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { ScheduleEntity } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import { SchedulesApiService } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.service";
import { ViewTaskList } from "src/app/tasks/components/viewTaskListModal/viewTaskList.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

Chart.register(
   ArcElement,
   BarController,
   BarElement,
   CategoryScale,
   DoughnutController,
   Legend,
   Tooltip,
);

type AssetCreds = {
   viewManageAssets: boolean;
   editAssetSettings: boolean;
};

type AugmentedTask = Task & {
   checklistTotalLaborCost: number;
   checklistTotalPartsCost: number;
   checklistTotalInvoiceCost: number;
   checklistTotalOperatingCost: number;
   checklistPromptTimeTotal: number;
};

@Component({
   selector: "asset-report-tab-legacy",
   templateUrl: "./asset-report-tab-legacy.component.html",
   styleUrls: ["./asset-report-tab-legacy.component.scss"],
   imports: [
      PanelComponent,
      DropdownButtonComponent,
      TooltipDirective,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownDateRangePickerComponent,
      CheckboxComponent,
      IconComponent,
      CardComponent,
      CountUpDirective,
      PrimaryButtonComponent,
      DepreciationLineChartCard,
      LocaleCurrencyPipe,
   ],
})
export class AssetReportTabLegacyComponent implements OnInit, OnDestroy, AfterViewInit {
   private readonly manageLang = inject(ManageLang);
   private readonly modalService = inject(ModalService);
   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageParts = inject(ManageParts);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly schedulesApiService = inject(SchedulesApiService);
   private readonly chartJSHelper = inject(ChartJSHelper);
   private readonly refreshService = inject(RefreshService);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;
   private readonly locationQueries = inject(LocationQueriesService);
   private readonly locationID = signal<number | undefined>(undefined);
   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationQueries.detail(this.locationID()),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode ?? []),
      this.isMultiCurrencyEnabled,
   );

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   @Input() public assetID;
   @Input() public restrict: boolean;
   public asset: Asset | undefined;
   public creds: AssetCreds = {
      viewManageAssets: false,
      editAssetSettings: false,
   };
   public loaded;
   public rendered;
   public filterTimeStr;
   public credAssetSettings;
   public dueDate1: Date | null = null;
   public dueDate2: Date | null = null;
   public totalCompletedWorkOrders;
   public workOrdersWithInvoices;
   public plannedWork;
   public unplannedWork;
   public totalPMs;
   public totalWOs;
   public MTBF;
   public totalTimeRanInHours;
   public numberOfTasksThatCausedDowntime;
   public totalTimeToRepair;
   public MTTR;
   public finalPMSCount;
   public finalWOSCount;
   public myData;
   public totalCost: number;
   public totalParts;
   public totalHours: number;
   public downtimeHours;
   public barData: ChartData<"bar"> | undefined;
   // eslint-disable-next-line typescript/no-redundant-type-constituents -- Chart.JS specific type options are missing properties in declaration
   public barOptions: ChartOptions<any> | undefined;
   public theMonths;
   public getNextMonth;
   public yyyy;
   public laborCost;
   public totalPartsCost;
   public totalPartsNumber;
   // eslint-disable-next-line typescript/no-redundant-type-constituents -- Chart.JS specific type options are missing properties in declaration
   public doughnutOptions: ChartOptions<any> | undefined;
   public barChart: Chart<any> | undefined;
   public doughnutChart: Chart<any> | undefined;
   public dateOptions;
   public filterTime;
   public partTasks: Array<AugmentedTask> = [];
   public laborTasks: Array<AugmentedTask> = [];
   public downtimeTasks: Array<AugmentedTask> = [];
   public flatUsers;
   public pieColors;
   public filterToDays: number = 0;
   public pieChartData;
   public barChartData;
   public completedTasksWatchVarSub;
   public reports;
   public depreciationSchedule;
   private readonly graphData: any[] = [];
   private readonly partsData: Map<number, Array<ScheduleEntity>> = new Map();
   private readonly viewInitialized$ = new Subject<void>();
   private readonly initializeChart$ = new Subject<void>();
   private readonly initializeChartSubscription: Subscription;
   private readonly localeCurrencyPipe = new LocaleCurrencyPipe();

   public dayFilters: Array<any> = [];

   public constructor() {
      this.restrict = true;
      this.totalCost = 0;
      this.totalHours = 0;
      this.initializeChartSubscription = this.initializeChart$
         .pipe(combineLatestWith(this.viewInitialized$.pipe(first())), debounceTime(10))
         .subscribe(() => {
            if (this.reports) {
               this.rendered = true;
               this.buildData();
               this.buildLineData();
               this.loadingBarService.remove();
            }
         });
   }

   public ngOnInit() {
      this.asset = this.manageAsset.getAsset(this.assetID);
      assert(this.asset);
      this.reports = true;
      this.depreciationSchedule = this.asset?.assetDepreciationID
         ? this.manageAsset.getDepreciationSchedule(this.asset?.assetDepreciationID)
         : null;
      this.loaded = false;

      this.rendered = false;
      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      this.locationID.set(this.asset.locationID);
      this.creds.viewManageAssets = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.ViewManageAssets,
      );

      this.creds.editAssetSettings = this.credService.isAuthorized(
         this.asset.locationID,
         this.credService.Permissions.EditAssetSettings,
      );
      this.filterTimeStr = this.lang().AllTime;
      this.filterTime = "All";

      this.dayFilters = [
         {
            days: 7,
            display: this.lang().Last7Days,
         },
         {
            days: 30,
            display: this.lang().Last30Days,
         },
         {
            days: 60,
            display: this.lang().Last60Days,
         },
         {
            days: 90,
            display: this.lang().Last90Days,
         },
         {
            days: 365,
            display: this.lang().Last365Days,
         },
         {
            days: 0,
            display: this.lang().AllTime,
         },
      ];

      this.completedTasksWatchVarSub = this.manageObservables.setSubscription(
         "completedTasksWatchVar",
         () => {
            if (this.reports) {
               setTimeout(() => {
                  this.buildData();
                  this.buildLineData();
               }, 100);
            }
         },
         { waitForObservable: this.refreshService.dataInitialized() },
      );

      if (
         this.credService.isAuthorized(
            this.asset.locationID,
            this.credService.Permissions.EditAssetSettings,
         )
      ) {
         this.credAssetSettings = true;
      } else {
         this.credAssetSettings = false;
      }

      //We added this so that on mobile devices when the orientation changes we rebuild the report data.
      //This is a crappy work around, but using the built in resize functions on the chart objects were not triggering
      $(window).bind("orientationchange", () => {
         setTimeout(() => {
            this.reports = false;
            setTimeout(() => {
               this.reports = true;
            }, 1);
         }, 1);
      });

      this.flatUsers = this.manageUser.getFlatUsersIndex();
      this.doughnutOptions = {
         responsive: true,
         plugins: {
            legend: {
               display: false,
            },
            tooltip: {
               mode: "point",
               titleFont: {
                  size: 14,
               },
               bodyFont: {
                  size: 14,
               },
               position: "nearest",
               callbacks: {
                  labelColor: function (context) {
                     const borderColor = context.dataset.borderColor as Color;
                     const backgroundColor = context.dataset.backgroundColor as Color;
                     return {
                        borderColor: borderColor,
                        backgroundColor: backgroundColor,
                     };
                  },
               },
            },
            htmlLegend: {
               containerID: `pieChart1Legend${this.asset.assetID}`,
            },
            aspectRatio: 2.0,
            maintainAspectRatio: true,
            cutout: "55%",
            onHover: (event, _chartElement, chart) => {
               this.chartJSHelper.onHoverHandler(event, chart);
            },
         },
      };

      this.pieColors = [
         {
            //green
            color: "#429b1f",
            highlight: "#47ab20",
         },
         {
            //red
            color: "#c22528",
            highlight: "#d01f22",
         },
         {
            //light grey
            color: "#949fb1",
            highlight: "#a7afbc",
         },
         {
            //orange
            color: "#ff6600",
            highlight: "#ed6002",
         },
         {
            //dirty gold
            color: "#897217",
            highlight: "#a0851b",
         },
         {
            //blue
            color: "#000148",
            highlight: "#01026d",
         },
         {
            //green
            color: "#429b1f",
            highlight: "#47ab20",
         },
         {
            //red
            color: "#c22528",
            highlight: "#d01f22",
         },
         {
            //orange
            color: "#ff6600",
            highlight: "#ed6002",
         },
         {
            //light grey
            color: "#949fb1",
            highlight: "#a7afbc",
         },
         {
            //dirty gold
            color: "#897217",
            highlight: "#a0851b",
         },
         {
            //blue
            color: "#000148",
            highlight: "#01026d",
         },
      ];

      this.dateOptions = {
         formatYear: "yy",
         startingDay: 1,
      };
      this.initializeChart$.next();
   }

   public ngAfterViewInit(): void {
      this.viewInitialized$.next();
   }

   updateIncludeChildData = () => {
      assert(this.asset);

      this.manageAsset
         .updateIncludeChildData(this.asset.assetID, this.asset.includeChildData)
         .then((answer) => {
            if (answer.data.success == true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               this.manageTask.incTasksWatchVar();
               this.manageTask.incCompletedTasksWatchVar();
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   };

   buildData = () => {
      assert(this.asset);
      let children: Array<any> = [];
      const tempHierAssetIDs: Array<number> = [];
      if (this.asset.includeChildData == 1) {
         children = this.manageAsset.findChildrenIDs(this.asset, []);
         for (const child of children) {
            tempHierAssetIDs.push(child);
         }
         tempHierAssetIDs.push(this.asset.assetID); //tempHierAssetIDs will include the main asset and all of it's children and sub children
      }

      //total cost of ownership section
      let completedTasks = this.manageTask.getCompletedTasks();

      if (this.asset.includeChildData == 1) {
         completedTasks = this.manageFilters.filterTasksByAssetIDs(
            completedTasks,
            tempHierAssetIDs,
         );
      } else {
         completedTasks = this.manageFilters.filterTasksByAssetIDs(completedTasks, [
            this.asset.assetID,
         ]);
      }

      let start;
      let end;
      let startDaysRanOnAssetCreated = false;

      //if filterTime is set then we need to filter to the time period
      if (this.filterTime === "7Days") {
         start = moment().subtract(7, "days").startOf("day");
         end = moment().endOf("day");
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            7,
         );
      } else if (this.filterTime === "30Days") {
         start = moment().subtract(30, "days").startOf("day");
         end = moment().endOf("day");
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            30,
         );
      } else if (this.filterTime === "60Days") {
         start = moment().subtract(60, "days").startOf("day");
         end = moment().endOf("day");
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            60,
         );
      } else if (this.filterTime === "90Days") {
         start = moment().subtract(90, "days").startOf("day");
         end = moment().endOf("day");
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            90,
         );
      } else if (this.filterTime === "365Days") {
         start = moment().subtract(365, "days").startOf("day");
         end = moment().endOf("day");
         completedTasks = this.manageFilters.filterTasksToLastXDays(
            completedTasks,
            "checklistCompletedDate",
            365,
         );
      } else if (this.filterTime === "dateRange") {
         if (this.dueDate1 !== null && this.dueDate2 !== null) {
            completedTasks = this.manageFilters.dateRange(completedTasks, {
               property: "checklistCompletedDate",
               startUnix: this.dueDate1.getTime(),
               endUnix: this.dueDate2.getTime(),
            });

            start = moment(this.dueDate1, "yyyy-mm-dd").startOf("day");
            end = moment(this.dueDate2, "yyyy-mm-dd").endOf("day");
         } else {
            startDaysRanOnAssetCreated = true;
         }
      } else {
         //this is all time so we need to figure out how long the asset as been alive
         startDaysRanOnAssetCreated = true;
      }

      if (startDaysRanOnAssetCreated) {
         let earliestTimestamp = 9999999999;
         let oldestTimestamp = 0;

         const tempTasks = completedTasks.orderBy("checklistCompletedDate");

         for (const tempTask of tempTasks) {
            if (
               tempTask.checklistCompletedDate &&
               tempTask.checklistCompletedDate < earliestTimestamp
            ) {
               earliestTimestamp = tempTask.checklistCompletedDate;
            }
            if (
               tempTask.checklistCompletedDate &&
               tempTask.checklistCompletedDate > oldestTimestamp
            ) {
               oldestTimestamp = tempTask.checklistCompletedDate;
            }
         }

         let earliestCreatedDate;

         if (this.asset.includeChildData == 1) {
            earliestCreatedDate = 999999999999999;
            for (const tempAssetID of tempHierAssetIDs) {
               const selectedAsset = this.manageAsset.getAsset(tempAssetID);
               assert(selectedAsset);
               if (
                  selectedAsset.assetCreatedDate !== null &&
                  selectedAsset.assetCreatedDate < earliestCreatedDate
               ) {
                  //this will always trigger at least once
                  earliestCreatedDate = selectedAsset.assetCreatedDate; //gets the earliest asset created date
               }
            }
         } else {
            earliestCreatedDate = this.asset.assetCreatedDate;
         }

         if (earliestCreatedDate < earliestTimestamp) {
            earliestTimestamp = earliestCreatedDate;
         }

         const currentTime = Math.floor(Date.now() / 1000);
         if (oldestTimestamp < currentTime) {
            //we want to calculate up to this second, but if they put a task in the future we have to catch it
            oldestTimestamp = currentTime;
         }

         const datea1 = moment.unix(earliestTimestamp);
         const dateb2 = moment.unix(oldestTimestamp);

         start = datea1.startOf("day");
         end = dateb2.endOf("day");
      }

      let laborCost: number = 0;
      let totalHours: any = 0;
      let downtimeHours: number = 0;
      let partsCost: number = 0;
      let partsNumber: number = 0;
      this.partTasks = [];
      this.laborTasks = [];
      this.downtimeTasks = [];
      let invoiceCost: number = 0;
      let invoiceNumber: number = 0;
      this.totalCompletedWorkOrders = 0;
      this.workOrdersWithInvoices = [];
      this.plannedWork = 0;
      this.unplannedWork = 0;
      this.totalPMs = 0;
      this.totalWOs = 0;
      let numberOfTasksThatCausedDowntime = 0;
      let totalTimeToRepair = 0;

      for (const tempTask of completedTasks) {
         this.totalCompletedWorkOrders++;

         const {
            checklistPromptTimeTotal,
            checklistTotalLaborCost,
            checklistTotalPartsCost,
            checklistTotalInvoiceCost,
            checklistTotalOperatingCost,
         } = this.manageTask.getCalculatedTaskInfo(tempTask);

         let hours: number = checklistPromptTimeTotal;
         hours = Number((hours / 60 / 60).toFixed(2));
         totalHours += hours;

         laborCost += checklistTotalLaborCost;

         const dHours = Number(tempTask.checklistDowntime);

         downtimeHours += Number(dHours);

         let addPartTask = false;
         if (tempTask.partRelationIDs.length > 0) {
            for (const partRelationID of tempTask.partRelationIDs) {
               // GEt the partRelation baased on id
               const partRelation = this.manageTask.getPartRelation(partRelationID);

               if (!partRelation?.usedNumber) {
                  continue;
               }

               partsCost =
                  partsCost +
                  Number(partRelation.usedPrice) * Number(partRelation.usedNumber);
               partsNumber = partsNumber + Number(partRelation.usedNumber);

               if (partRelation.usedNumber > 0) {
                  addPartTask = true;
               }
            }
         }
         const augmentedTask = {
            ...tempTask,
            checklistTotalLaborCost,
            checklistTotalPartsCost,
            checklistTotalInvoiceCost,
            checklistTotalOperatingCost,
            checklistPromptTimeTotal,
         };
         if (addPartTask) {
            this.partTasks.push(augmentedTask);
         }
         if (checklistPromptTimeTotal > 0) {
            this.laborTasks.push(augmentedTask);
         }

         if (Number(tempTask.checklistDowntime) > 0) {
            this.downtimeTasks.push(augmentedTask);
         }

         if (tempTask.checklistTemplateOld == 1) {
            this.plannedWork++;
            this.totalPMs++;
         }

         if (tempTask.checklistTemplateOld == 2) {
            this.unplannedWork++;
            this.totalWOs++;
         }

         if (tempTask.checklistTemplateOld == 4) {
            this.plannedWork++;
            this.totalPMs++;
         }

         //if they have invoices
         if (tempTask.invoiceIDs && tempTask.invoiceIDs.length > 0) {
            for (const invoiceID of tempTask.invoiceIDs) {
               const invoice = this.manageInvoice.getInvoicesIndex()[invoiceID];

               invoiceCost += invoice.invoiceCost;
               invoiceNumber++;
            }
            this.workOrdersWithInvoices.push(tempTask);
         }

         //next we need to calucate MTBF and MTTR
         //we might expand this functionality so that they can exclude PMs etc.  Right now it includes any completedTasks that cause downtime
         if (Number(tempTask.checklistDowntime) > 0) {
            //for MTBF
            numberOfTasksThatCausedDowntime++;

            //for MTTR
            totalTimeToRepair += Number(tempTask.checklistDowntime);
         }
      }
      let totalTimeRanInHours = 0;

      if (this.asset.includeChildData == 1) {
         for (const tempAssetID of tempHierAssetIDs) {
            const tempAsset = this.manageAsset.getAsset(tempAssetID);
            assert(tempAsset);
            totalTimeRanInHours =
               totalTimeRanInHours +
               this.manageAsset.calculateAssetHrsRunTime(tempAsset, start, end);
         }
      } else {
         totalTimeRanInHours = this.manageAsset.calculateAssetHrsRunTime(
            this.asset,
            start,
            end,
         );
      }
      this.MTBF = (totalTimeRanInHours / numberOfTasksThatCausedDowntime).toFixed(2);
      this.totalTimeRanInHours = totalTimeRanInHours.toFixed(2);
      this.numberOfTasksThatCausedDowntime = numberOfTasksThatCausedDowntime;

      totalTimeToRepair = totalTimeToRepair / 60 / 60; //need to convert this to hours
      this.totalTimeToRepair = totalTimeToRepair.toFixed(2);
      this.MTTR = (totalTimeToRepair / numberOfTasksThatCausedDowntime).toFixed(2);

      //if MTTR isn't set from the above calculations then we need to set it to 0
      if (isNaN(this.MTTR)) {
         this.MTTR = 0;
      }

      //if MTBF isn't set from the above calculations then we need to set it to 0
      if (isNaN(this.MTBF)) {
         this.MTBF = 0;
      }

      this.finalPMSCount =
         (this.plannedWork / (this.plannedWork + this.unplannedWork)) * 100;
      this.finalWOSCount =
         (this.unplannedWork / (this.plannedWork + this.unplannedWork)) * 100;

      const laborCostCurrency = this.localeCurrencyPipe.transform(
         laborCost,
         this.currencyCode(),
      );
      const partsCostCurrency = this.localeCurrencyPipe.transform(
         partsCost,
         this.currencyCode(),
      );
      const invoiceCostCurrency = this.localeCurrencyPipe.transform(
         invoiceCost,
         this.currencyCode(),
      );
      totalHours = totalHours.toFixed(2);
      let count = 0;

      const laborObj = {
         value: laborCost,
         color: this.pieColors[count].color,
         highlight: this.pieColors[count].highlight,
         label: `${this.lang().LaborCost} - ${totalHours} ${this.lang().hours} | ${this.lang().Total}: ${laborCostCurrency}`,
         customData: { type: "labor", tasks: this.laborTasks },
      };

      count++;

      const partsObj = {
         value: partsCost,
         color: this.pieColors[count].color,
         highlight: this.pieColors[count].highlight,
         label: `${this.lang().PartsCost} - ${partsNumber} ${this.lang().PartsHaveBeenUsed} | ${this.lang().Total}: ${partsCostCurrency}`,
         customData: { type: "parts", tasks: this.partTasks },
      };

      count++;

      const invoiceObj = {
         value: invoiceCost,
         color: this.pieColors[count].color,
         highlight: this.pieColors[count].highlight,
         label: `${this.lang().InvoiceCost} - ${invoiceNumber} ${this.lang().InvoicesHaveOccurred} | ${this.lang().Total}: ${invoiceCostCurrency}`,
         customData: {
            type: "invoices",
            tasks: this.workOrdersWithInvoices,
         },
      };

      count++;

      this.myData = [];
      this.totalCost = 0;

      if (this.asset.assetTrackCOOParts == 1) {
         this.myData.push(partsObj);
         this.totalCost = this.totalCost + Number(partsCost);
      }

      if (this.asset.assetTrackCOOLabor == 1) {
         this.myData.push(laborObj);
         this.totalCost = this.totalCost + Number(laborCost);
      }

      this.myData.push(invoiceObj);
      this.totalCost = this.totalCost + Number(invoiceCost);

      for (const tempFieldID of this.asset.assetValueIDs) {
         const tempField = this.manageAsset.getFieldValue(tempFieldID);
         if (tempField?.reportCostOfOwnership == 1) {
            let totalValue = Number(tempField.valueContent ?? 0);

            //if this asset should include it's children we also need to check all of the kids to see if they have the same field and then add the value
            if (this.asset.includeChildData == 1) {
               for (const kid of children) {
                  const childAsset = this.manageAsset.getAsset(kid);
                  if (childAsset) {
                     for (const fieldID of childAsset.assetValueIDs) {
                        const field = this.manageAsset.getFieldValue(fieldID);
                        assert(field);
                        if (field.fieldID == tempField.fieldID) {
                           if (
                              field.valueContent &&
                              Number(field.valueContent ?? 0) > 0
                           ) {
                              totalValue += Number(field.valueContent ?? 0);
                           }
                        }
                     }
                  }
               }
            }

            //got totalValue set now so do the rest
            const obj: any = {};
            obj.value = totalValue;
            obj.color = this.pieColors[count].color;
            obj.highlight = this.pieColors[count].highlight;

            const currency = String(
               this.localeCurrencyPipe.transform(totalValue, this.currencyCode()),
            );
            const field = this.manageAsset.getField(tempField.fieldID);
            assert(field);
            obj.label = `${field.fieldName} - ${currency}`;

            this.myData.push(obj);
            this.totalCost = this.totalCost + Number(totalValue);

            count++;
         }
      }

      const myNewData = this.updatePieChartData(this.myData);

      // The new charts library loads really slowly when we have big customData objects on them
      // so we are pulling the customData onto a separate array to speed up load times.
      this.pieChartData = myNewData.datasets[0].customData;
      myNewData.datasets[0].customData = [];

      if (this.doughnutChart !== undefined) {
         this.doughnutChart.clear();
         this.doughnutChart.destroy();
      }

      const ctx = document.getElementById(`pieChart1${this.asset.assetID}`);

      if (ctx instanceof HTMLCanvasElement) {
         this.doughnutChart = new Chart(ctx, {
            type: "doughnut",
            data: myNewData,
            options: this.doughnutOptions,
            plugins: [
               {
                  id: "htmlLegend",
                  afterUpdate: (chart) => {
                     const chartId = `pieChart1Legend${this.asset?.assetID}`;
                     const ul = this.chartJSHelper.getOrCreateLegendList(
                        chart,
                        chartId,
                        "column",
                     );

                     while (ul.firstChild) {
                        ul.firstChild.remove();
                     }
                     this.chartJSHelper.chartHTMLLegend(chart, ul);
                  },
               },
            ],
         });
      }

      //since we already have this information store it to a scope variable and use it in the statistics section of reports
      this.totalParts = partsNumber.toFixed(3);
      this.totalHours = totalHours;
      this.downtimeHours = (downtimeHours / 60 / 60).toFixed(2);
   };

   updatePieChartData = (oldData) => {
      const newData: any = {
         datasets: [
            {
               data: [],
               backgroundColor: [],
               customData: [],
            },
         ],
         labels: [],
      };
      for (const data of oldData) {
         newData.datasets[0].data.push(data.value);
         newData.datasets[0].backgroundColor.push(data.color);
         this.graphData.push(data.customData);
         newData.labels.push(data.label);
      }
      if (newData.datasets[0]) {
         newData.datasets[0].hoverBorderWidth = 5;
         newData.datasets[0].hoverBorderColor = "#CACACA";
         newData.datasets[0].borderWidth = 1;
         newData.datasets[0].borderColor = "#FFFFFF";
      }
      return newData;
   };

   buildLineData = async () => {
      assert(this.asset);
      //Future Estimated Costs section
      const dataStyleOptions = {
         tension: 0.5,
         pointRadius: 5,
         fill: false,
         xAxisID: "xAxes",
         yAxisID: "yAxes",
      };
      this.barData = {
         labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         datasets: [
            {
               ...dataStyleOptions,
               label: "",
               backgroundColor: "rgba(66,155,31,1)",
               borderColor: "rgba(66,155,31,1)",
               data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            },
            {
               ...dataStyleOptions,
               label: "",
               backgroundColor: "rgba(194,37,40,.9)",
               borderColor: "rgba(194,37,40,.9)",
               data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            },
         ],
      };

      //// Chart.js Options
      this.barOptions = {
         plugins: {
            legend: {
               display: false,
               position: "bottom",
               align: "start",
               labels: {
                  font: {
                     size: 14,
                  },
               },
            },
            tooltip: {
               mode: "index",
               titleFont: {
                  size: 14,
               },
               bodyFont: {
                  size: 14,
               },
               position: "nearest",
               intersect: false,
               callbacks: {
                  labelColor: function (context) {
                     const borderColor = context.dataset.borderColor as Color;
                     const backgroundColor = context.dataset.backgroundColor as Color;
                     return {
                        borderColor: borderColor,
                        backgroundColor: backgroundColor,
                     };
                  },
               },
            },
            htmlLegend: {
               containerID: `barChart1Legend${this.asset.assetID}`,
            },
         },
         scales: {
            xAxes: {
               beginAtZero: true,
               grid: {
                  display: true,
                  lineWidth: 1,
               },
            },
            yAxes: {
               beginAtZero: true,
               grid: {
                  display: true,
                  lineWidth: 1,
               },
            },
         },
         aspectRatio: 2,

         onHover: (event, _chartElement, chart) => {
            this.chartJSHelper.onHoverHandler(event, chart);
         },
      };

      this.theMonths = {
         1: {
            short: this.lang().Jan,
            long: this.lang().January,
            days: 31,
         },
         2: {
            short: this.lang().Feb,
            long: this.lang().February,
            days: 28,
         },
         3: {
            short: this.lang().Mar,
            long: this.lang().March,
            days: 31,
         },
         4: {
            short: this.lang().Apr,
            long: this.lang().April,
            days: 30,
         },
         5: { short: this.lang().May, long: this.lang().May, days: 31 },
         6: { short: this.lang().Jun, long: this.lang().June, days: 30 },
         7: { short: this.lang().Jul, long: this.lang().July, days: 31 },
         8: {
            short: this.lang().Aug,
            long: this.lang().August,
            days: 31,
         },
         9: {
            short: this.lang().Sep,
            long: this.lang().September,
            days: 30,
         },
         10: {
            short: this.lang().Oct,
            long: this.lang().October,
            days: 31,
         },
         11: {
            short: this.lang().Nov,
            long: this.lang().November,
            days: 30,
         },
         12: {
            short: this.lang().Dec,
            long: this.lang().December,
            days: 31,
         },
      };

      this.getNextMonth = (mm) => {
         if (mm > 11) {
            this.yyyy = Number(this.yyyy) + 1;
            return 1;
         }
         return mm + 1;
      };

      const children = this.manageAsset.findChildrenIDs(this.asset, []);
      const tempHierAssetIDs: any = [];
      for (const child of children) {
         tempHierAssetIDs.push(child);
      }
      tempHierAssetIDs.push(this.asset.assetID); //tempHierAssetIDs will include the main asset and all of it's children and sub children

      const foundSchedule: Array<ScheduleEntity | Schedule> =
         await this.getRelevantSchedulesForLineGraph();

      //sets the information for the bar chart
      let today = new Date(new Date().setFullYear(new Date().getFullYear()));
      today.setMonth(today.getMonth());
      let mm = today.getMonth() + 1; //January is 0!
      this.yyyy = today.getFullYear();

      this.laborCost = 0;
      //loop through the months
      assert(this.barData?.labels);
      for (let index = 0; index < 12; index++) {
         this.barData.labels[index] = `${this.theMonths[mm].short}, ${this.yyyy}`;
         // this.barData.datasets[0].dataSrc[index] = {};
         //this.barData.datasets[0].dataSrc[x].customData = [];
         // this.barData.datasets[0].dataSrc[index].label =
         // `${this.theMonths[mm].short}, ${this.yyyy}`;
         let start = new Date(`${mm}/01/${this.yyyy}`).getTime();
         start = Math.floor(start / 1000);

         mm = this.getNextMonth(mm);

         let end = new Date(`${mm}/01/${this.yyyy}`).getTime();
         end = Math.floor(end / 1000);

         let count = 0;

         let thisTime = 0;
         const customerID = this.manageUser.getCurrentUser().userInfo.customerID;
         let defaultTeamWage = 25;
         if (customerID == 185 || customerID == 266) {
            defaultTeamWage = 0;
         }

         for (const tempSchedule of foundSchedule) {
            const relatedTemplate = (tempSchedule as ScheduleEntity).relatedTemplate;

            if (
               relatedTemplate === undefined ||
               tempSchedule.userID === null ||
               relatedTemplate.checklistEstTime === null
            )
               continue;
            if (
               Number(tempSchedule.scheduleStartDate) > start &&
               Number(tempSchedule.scheduleStartDate) < end
            ) {
               count = count + 1;
               if (this.flatUsers[tempSchedule.userID]) {
                  thisTime =
                     thisTime +
                     relatedTemplate.checklistEstTime *
                        this.flatUsers[tempSchedule.userID].userWage;
               } else {
                  //if we can't find the user's wage then we need to add a basic 15 dollars an hour
                  thisTime =
                     thisTime + relatedTemplate.checklistEstTime * defaultTeamWage;
               }
            }
         }

         let cost: any = thisTime / 60 / 60;

         cost = cost.toFixed(2);
         this.laborCost = this.laborCost + Number(cost);

         this.barData.datasets[0].data[index] = cost;
      }

      const parts = this.manageParts.getParts();

      //sets the information for the bar chart
      today = new Date(new Date().setFullYear(new Date().getFullYear()));
      today.setMonth(today.getMonth());
      mm = today.getMonth() + 1; //January is 0!
      this.yyyy = today.getFullYear();

      this.totalPartsCost = 0;
      this.totalPartsNumber = 0;

      //loop through the months
      for (let index = 0; index < 12; index++) {
         this.barData.labels[index] = `${this.theMonths[mm].short}, ${this.yyyy}`;
         this.barData.datasets[1].data[index] = 0;
         let start = new Date(`${mm}/01/${this.yyyy}`).getTime();
         start = Math.floor(start / 1000);

         mm = this.getNextMonth(mm);

         let end = new Date(`${mm}/01/${this.yyyy}`).getTime();
         end = Math.floor(end / 1000);

         let thisPartsCost = 0;
         let thisPartTotalNum = 0;

         //loop through schedules and if it falls in date range then calculate values for the parts section of the graph
         const tempSchedules: Array<ScheduleEntity> = [];
         for (const tempSchedule of foundSchedule) {
            if (
               Number(tempSchedule.scheduleStartDate) > start &&
               Number(tempSchedule.scheduleStartDate) < end
            ) {
               const associatedTemplate = this.manageTask.getTaskLocalLookup(
                  tempSchedule.checklistID,
               );

               if (associatedTemplate?.partRelationIDs) {
                  for (const partRelationID of associatedTemplate?.partRelationIDs) {
                     const partRelation = this.manageTask.getPartRelation(partRelationID);
                     if (partRelation === undefined) {
                        continue;
                     }
                     const part = parts.get(partRelation.partID);
                     assert(part);
                     thisPartTotalNum = Number(partRelation.suggestedNumber);
                     thisPartsCost = part.partPrice ?? 0;

                     this.totalPartsCost =
                        this.totalPartsCost + thisPartsCost * thisPartTotalNum * 1;
                     this.totalPartsNumber =
                        this.totalPartsNumber + Number(thisPartTotalNum);
                     this.barData.datasets[1].data[index] =
                        (this.barData.datasets[1].data[index] as number) +
                        thisPartsCost * thisPartTotalNum * 1;
                  }

                  if (
                     associatedTemplate.partRelationIDs.length > 0 ||
                     (associatedTemplate.checklistEstTime &&
                        associatedTemplate.checklistEstTime > 0)
                  ) {
                     tempSchedules.push(tempSchedule as ScheduleEntity);
                     this.partsData.set(index, tempSchedules);
                  }
               }
            }
         }

         //this.totalPartsCost = (this.totalPartsCost*1).toFixed(2);
         this.barData.datasets[1].data[index] = Number(
            this.barData.datasets[1].data[index],
         );
      }

      // this.barData.datasets[0].label = `<span class="greenColor">${this.lang().Labor}</span>`;
      // this.barData.datasets[1].label = `<span class="redColor">${this.lang().Parts}</span>`;

      this.barData.datasets[0].label = this.lang().Labor;
      this.barData.datasets[1].label = this.lang().Parts;

      if (this.barChart !== undefined) {
         this.barChart.destroy();
      }

      const ctx = document.getElementById(`barChart1${this.asset.assetID}`);
      if (ctx instanceof HTMLCanvasElement) {
         this.barChart = new Chart(ctx, {
            type: "bar",
            data: this.barData,
            options: this.barOptions,
            plugins: [
               {
                  id: "htmlLegend",
                  afterUpdate: (chart) => {
                     const chartId = `barChart1Legend${this.asset?.assetID}`;
                     const ul = this.chartJSHelper.getOrCreateLegendList(
                        chart,
                        chartId,
                        "row",
                     );

                     while (ul.firstChild) {
                        ul.firstChild.remove();
                     }
                     this.chartJSHelper.chartHTMLLegend(chart, ul);
                  },
               },
            ],
         });
      }
   };

   private async getRelevantSchedulesForLineGraph(): Promise<Array<ScheduleEntity>> {
      if (this.asset === undefined) return [];

      const nextScheduleStart = moment().startOf("month").toDate();
      const nextScheduleEnd = moment().add(1, "year").endOf("month").toDate();

      let assetIDs: Array<number> = [];
      if (this.asset.includeChildData === 1) {
         const children = this.manageAsset.findChildrenIDs(this.asset, []);
         assetIDs = [...children, this.asset.assetID];
      } else {
         assetIDs = [this.asset.assetID];
      }

      const schedules: Array<ScheduleEntity> = [];

      const schedulesStream = this.schedulesApiService.getStreamedList({
         filters: {
            assetIDs,
            nextScheduleStart,
            nextScheduleEnd,
         },
         columns: "relatedTemplate",
      });

      for await (const schedule of schedulesStream) {
         schedules.push(schedule);
      }

      return schedules;
   }

   changeFields = () => {
      assert(this.asset);
      const instance = this.modalService.open(ChangeFieldsAssetCOOGraph);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ChangeFieldsMsg,
            title: this.lang().ChangeFields,
            assetID: this.asset?.assetID,
            currencyCode: this.currencyCode(),
         },
      };

      instance.result.then((result) => {
         if (result != 0) {
            this.buildData();
         }
      });
   };

   public viewBarChartItem(event): void {
      assert(this.barChart);
      const activePoints: InteractionItem[] = this.barChart.getElementsAtEventForMode(
         event,
         "nearest",
         { intersect: true },
         false,
      );
      if (activePoints?.length == 0) return;

      const label =
         (this.barChart.config.data?.labels?.[activePoints[0].index] as string) || "";

      const data: any = [{ customData: [] }];

      const customData = this.partsData.get(activePoints[0].index) ?? [];
      data[0].customData = [customData];

      const instance = this.modalService.open(ViewSingleAssetBarReport);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: `${this.lang().FutureEstimatedCosts} - ${label}`,
            data: data,
         },
      };
   }

   public viewPieItem(event): void {
      assert(this.asset);
      assert(this.doughnutChart);
      const activePoints = this.doughnutChart.getElementsAtEventForMode(
         event,
         "nearest",
         { intersect: true },
         false,
      );
      if (activePoints?.length == 0) return;

      const selectedData = this.graphData[activePoints[0].index];
      const dataSet: Array<Task> = [...new Set(selectedData.tasks)] as Array<Task>; // removes any duplicates from the tasks list
      const taskIDs = dataSet.map((task: Task) => task.checklistID);
      const dataType = this.graphData[activePoints[0].index].type;

      const label =
         (this.doughnutChart.config.data?.labels?.[activePoints[0].index] as string) ??
         "";

      if (dataType !== undefined) {
         if (dataType === "labor") {
            const instance = this.modalService.open(ViewTaskList);
            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: this.lang().TotalLaborCostTitleMsg,
                  title: `${this.lang().TotalLaborCostTitle} - ${label}`,
                  data: {}, //we are not passing data which means it will not be calculated on the fly.  Only when it is clicked.
                  taskIDs,
                  locationID: this.asset.locationID,
                  viewingAsset: this.asset.assetID,
                  sortBind: "-checklistTotalLaborCost",
                  modalType: "tasksByCost",
               },
            };
         } else if (dataType === "parts") {
            const updatedTaskIDs = this.getUpdatedTaskIDsForParts();

            const instance = this.modalService.open(ViewTaskList);
            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: this.lang().TotalPartsCostTitleMsg,
                  title: `${this.lang().TotalPartsCostTitle} - ${label}`,
                  data: {}, // Data recalculated or fetched based on the current state
                  taskIDs: updatedTaskIDs, // Use the updated task IDs
                  locationID: this.asset.locationID,
                  viewingAsset: this.asset.assetID,
                  sortBind: "-checklistTotalPartsCost",
                  modalType: "tasksByCost",
               },
            };
         } else if (dataType === "invoices") {
            const instance = this.modalService.open(ViewTaskList);
            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: this.lang().TotalInvoiceCostTitleMsg,
                  title: `${this.lang().TotalInvoiceCostTitle} - ${label}`,
                  data: {}, //we are not passing data which means it will not be calculated on the fly.  Only when it is clicked.
                  taskIDs,
                  locationID: this.asset.locationID,
                  viewingAsset: this.asset.assetID,
                  sortBind: "-checklistTotalInvoiceCost",
                  modalType: "tasksByCost",
               },
            };
         }
      } else if (dataType === undefined) {
         const instance = this.modalService.open(Confirm);

         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: `<b>${label}</b> ${this.lang().FieldMsg}`,
               title: this.lang().Field,
            },
         };
      }
   }

   showCompletedPreventiveWorkOrders = () => {
      assert(this.asset);
      const assets: Array<Asset> = [];
      if (this.asset.includeChildData == 1) {
         const children = this.manageAsset.findChildrenIDs(this.asset, []);
         const tempHierAssetIDs: any = [];
         for (const child of children) {
            tempHierAssetIDs.push(child);
         }
         tempHierAssetIDs.push(this.asset.assetID); //tempHierAssetIDs will include the main asset and all of it's children and sub children
         for (const tempAssetID of tempHierAssetIDs) {
            const retrievedAsset = this.manageAsset.getAsset(tempAssetID);
            assert(retrievedAsset);
            assets.push(retrievedAsset);
         }
      } else {
         assets.push(this.asset);
      }

      const data = {
         checklistTemplateOld: [1, 4],
         assetID: false,
         locationID: false,
         dateRange1: this.dueDate1,
         dateRange2: this.dueDate2,
         xDays: this.filterToDays,
         assets: assets,
      };

      const instance = this.modalService.open(ViewTaskList);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().CompletedPlannedWorkAssetReportsMsg,
            title: this.lang().CompletedPlannedWorkAssetReports,
            data: data,
            locationID: this.asset.locationID,
            viewingAsset: this.asset.assetID,
            sortBind: "-checklistTotalLaborCost",
            modalType: "tasksByCost",
         },
      };
   };

   showCompletedWorkOrders = () => {
      assert(this.asset);
      const assets: any = [];
      if (this.asset.includeChildData == 1) {
         const children = this.manageAsset.findChildrenIDs(this.asset, []);
         const tempHierAssetIDs: any = [];
         for (const child of children) {
            tempHierAssetIDs.push(child);
         }
         tempHierAssetIDs.push(this.asset.assetID); //tempHierAssetIDs will include the main asset and all of it's children and sub children
         for (const tempAssetID of tempHierAssetIDs) {
            assets.push(this.manageAsset.getAsset(tempAssetID));
         }
      } else {
         assets.push(this.asset);
      }

      const data = {
         checklistTemplateOld: [2],
         assetID: false,
         locationID: false,
         dateRange1: this.dueDate1,
         dateRange2: this.dueDate2,
         xDays: this.filterToDays,
         assets: assets,
      };

      const instance = this.modalService.open(ViewTaskList);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().CompletedUnplannedWorkAssetReportsMsg,
            title: this.lang().CompletedUnplannedWorkAssetReports,
            data: data,
            locationID: this.asset.locationID,
            viewingAsset: this.asset.assetID,
            sortBind: "-checklistTotalLaborCost",
            modalType: "tasksByCost",
         },
      };
   };

   viewListOfLaborTasks = () => {
      assert(this.asset);
      const assets: any = [];
      if (this.asset.includeChildData == 1) {
         const children = this.manageAsset.findChildrenIDs(this.asset, []);
         const tempHierAssetIDs: any = [];
         for (const child of children) {
            tempHierAssetIDs.push(child);
         }
         tempHierAssetIDs.push(this.asset.assetID); //tempHierAssetIDs will include the main asset and all of it's children and sub children
         for (const tempAssetID of tempHierAssetIDs) {
            assets.push(this.manageAsset.getAsset(tempAssetID));
         }
      } else {
         assets.push(this.asset);
      }

      const data = {
         assetID: false,
         locationID: false,
         dateRange1: this.dueDate1,
         dateRange2: this.dueDate2,
         xDays: this.filterToDays,
         has: "labor",
         assets: assets,
      };

      const instance = this.modalService.open(ViewTaskList);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().ListOfTasks,
            data: data, //we are not passing data which means it will not be calculated on the fly.  Only when it is clicked.,
            locationID: this.asset.locationID,
            viewingAsset: 0,
            sortBind: "-checklistTotalLaborCost",
            modalType: "tasksByCost",
         },
      };
   };

   viewListOfPartsUsage = () => {
      assert(this.asset);
      const assets: Array<Asset> = [];
      if (this.asset.includeChildData == 1) {
         const children = this.manageAsset.findChildrenIDs(this.asset, []);
         const tempHierAssetIDs: any = [];
         for (const child of children) {
            tempHierAssetIDs.push(child);
         }
         tempHierAssetIDs.push(this.asset.assetID); //tempHierAssetIDs will include the main asset and all of it's children and sub children
         for (const tempAssetID of tempHierAssetIDs) {
            const retrievedAsset = this.manageAsset.getAsset(tempAssetID);
            assert(retrievedAsset);
            assets.push(retrievedAsset);
         }
      } else {
         assets.push(this.asset);
      }

      /**
       * Tasks Team TODO replace this with an API call to Flannel - TASK-512
       */
      const dateRanges =
         this.dueDate1 !== null && this.dueDate2 !== null
            ? {
                 dateRange1: this.dueDate1,
                 dateRange2: this.dueDate2,
              }
            : undefined;

      const tasksForPartUsage =
         this.manageAssociations.getTaskListFromAssetsForPartsUsage(
            assets,
            dateRanges,
            this.filterToDays,
         );
      const partsUsageObjs = this.manageParts.prepListOfPartsUsage(tasksForPartUsage);

      const columns = [
         {
            key: "partName",
            displayName: this.lang().PartName,
            sortBy: "partName",
            manualWidth: 2,
         },
         {
            key: "checklistCompletedDate",
            displayName: this.lang().Date,
            sortBy: "checklistCompletedDate",
            manualWidth: 2,
         },
         {
            key: "usedNumber",
            displayName: this.lang().TotalUsed,
            sortBy: "usedNumber",
            manualWidth: 4,
         },
         {
            key: "partNumber",
            displayName: this.lang().PartNumber,
            sortBy: "partNumber",
            manualWidth: 2,
         },
         {
            key: "totalCost",
            displayName: this.lang().TotalCost,
            sortBy: "totalCost",
            manualWidth: 2,
         },
      ];

      const options = {
         sortBind: "-checklistCompletedDate",
         isModal: true,
      };

      const instance = this.modalService.open(ViewListModal);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            objs: partsUsageObjs,
            options: options,
            modalTitle: this.lang().ListOfPartsUsed,
            columns: columns,
         },
      };
   };

   viewListOfDowntimeTasks = () => {
      assert(this.asset);
      const taskIDs = this.downtimeTasks.map((task) => task.checklistID);
      const instance = this.modalService.open(ViewTaskList);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().ListOfTasksThatCausedDowntime,
            data: {},
            taskIDs,
            locationID: this.asset.locationID,
            viewingAsset: 0,
            sortBind: "-checklistTotalOperatingCost",
            modalType: "tasksByDowntime",
         },
      };
   };

   setDaysFilter = (days: number, displayText: string) => {
      this.filterTimeStr = displayText;
      this.filterToDays = days;

      if (days) {
         this.filterTime = `${days}Days`;
      } else {
         this.filterTime = false;
      }

      //reset custom range
      this.dueDate1 = null;
      this.dueDate2 = null;

      this.buildData();
   };

   setDateRange = () => {
      const month: any = [];
      month[0] = this.lang().Jan;
      month[1] = this.lang().Feb;
      month[2] = this.lang().Mar;
      month[3] = this.lang().Apr;
      month[4] = this.lang().May;
      month[5] = this.lang().Jun;
      month[6] = this.lang().Jul;
      month[7] = this.lang().Aug;
      month[8] = this.lang().Sep;
      month[9] = this.lang().Oct;
      month[10] = this.lang().Nov;
      month[11] = this.lang().Dec;

      this.dueDate1 = this.dueDate1 ?? new Date();
      this.dueDate2 = this.dueDate2 ?? new Date();

      const str1 = `${month[this.dueDate1.getMonth()]} ${this.dueDate1.getDate()}`;
      const str2 = `${month[this.dueDate2.getMonth()]} ${this.dueDate2.getDate()}`;
      this.filterTimeStr = `${str1}, ${this.dueDate1.getFullYear()} - ${str2},${this.dueDate2.getFullYear()}`;

      this.filterTime = "dateRange";

      //reset x day filter
      this.filterToDays = 0;

      this.buildData();
   };

   public ngOnDestroy() {
      this.loadingBarService.remove();

      this.manageObservables.removeSubscription(this.completedTasksWatchVarSub);
      $(window).unbind("orientationchange");
   }

   private getUpdatedTaskIDsForParts(): number[] {
      assert(this.asset);

      // Use manageAsset methods to determine asset IDs
      const assetIDs = this.asset.includeChildData
         ? this.manageAsset.findChildrenIDs(this.asset, []).concat(this.asset.assetID)
         : [this.asset.assetID];

      // Use manageTask filters to get tasks for these asset IDs
      const completedTasks = this.manageTask.getCompletedTasks();
      const filteredTasks = Array.from(
         this.manageFilters.filterTasksByAssetIDs(completedTasks, assetIDs),
      );

      // Map the filtered tasks to their checklist IDs
      return filteredTasks.map((task: Task) => task.checklistID);
   }
}
