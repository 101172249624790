<ng-container *translate="let t">
   @if (cell?.checklistID !== undefined) {
      <e-button
         (click)="viewTask()"
         [label]="'#' + cell.checklistID"
         [link]="true"
         size="small"
         [style]="{ 'padding': 0, 'text-align': 'left' }"
      />
   }
</ng-container>
