import {
   Component,
   createEnvironmentInjector,
   EnvironmentInjector,
   inject,
   type OnInit,
   type Signal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MultiSelectComponent } from "@empowered/base/multi-select/multi-select.component";
import { IconComponent, ModalService, TooltipDirective } from "@limblecmms/lim-ui";
import { switchMap, take } from "rxjs";
import { ContinueWithoutMergingModalComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/continue-without-merging-modal/continue-without-merging-modal.component";
import { StepComponent } from "src/app/assets/components/asset-templates-page/apply-template-wizard/step/step.component";
import type { ApplyTemplateFieldMappings } from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import {
   type ApplyTemplateLocation,
   ApplyTemplateStore,
} from "src/app/assets/services/apply-asset-templates/apply-template.store";
import { AssetErrorService } from "src/app/assets/services/asset-error.service";
import { AssetFieldDefinitionService } from "src/app/assets/services/asset-field-definition.service";
import type {
   AssetFieldDefinition,
   AssetTemplateField,
} from "src/app/assets/services/asset-field.types";
import { AssetTemplateFieldService } from "src/app/assets/services/asset-template-field.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";

type AssetFieldDefinitionOption = AssetFieldDefinition & { disabled: boolean };

@Component({
   selector: "merge-fields-step",
   standalone: true,
   imports: [
      TranslateDirective,
      IconComponent,
      TooltipDirective,
      IconAlias,
      MultiSelectComponent,
      FormsModule,
   ],
   templateUrl: "./merge-fields-step.component.html",
   styleUrl: "./merge-fields-step.component.scss",
})
export class MergeFieldsStepComponent extends StepComponent implements OnInit {
   public templateID: number = 0;
   public templateFields: AssetTemplateField[] = [];
   public locationFields: AssetFieldDefinition[] = [];
   public selectedFields: Record<string, AssetFieldDefinitionOption[]> = {};

   private readonly route = inject(ActivatedRoute);
   private readonly environmentInjector = inject(EnvironmentInjector);
   public readonly assetTemplateFieldService = inject(AssetTemplateFieldService);
   private readonly assetErrorService = inject(AssetErrorService);
   public readonly manageAsset = inject(ManageAsset);
   public readonly assetFieldDefinitionService = inject(AssetFieldDefinitionService);
   private readonly modalService = inject(ModalService);
   private readonly applyTemplateState = inject(ApplyTemplateStore);

   protected selectedLocation: Signal<ApplyTemplateLocation> =
      this.applyTemplateState.selectedLocation;
   protected fieldMappings: Signal<ApplyTemplateFieldMappings> =
      this.applyTemplateState.fieldMappings;

   public ngOnInit() {
      this.templateID = Number(this.route.snapshot.params.assetTemplateId);
      if (this.templateID) {
         this.getFields();
      }
   }

   public override async handleNextStepClick() {
      const hasAnyMappings = Object.values(this.fieldMappings()).some(
         (mapping) => mapping.mappings.length > 0,
      );
      if (hasAnyMappings) {
         this.context().goToNextStep();
         return;
      }

      const modalRef = this.modalService.open(ContinueWithoutMergingModalComponent, {
         injector: createEnvironmentInjector(
            [{ provide: ApplyTemplateStore, useValue: this.applyTemplateState }],
            this.environmentInjector,
         ),
      });

      const isConfirmed = await modalRef.result;
      if (isConfirmed === true) {
         this.context().goToNextStep();
      }
   }

   public getFields(): void {
      const locationID = this.selectedLocation()?.locationID;

      if (!locationID) return;

      this.assetTemplateFieldService
         .getTemplateFields(this.templateID)
         .pipe(
            take(1),
            switchMap((fields) => {
               this.templateFields = fields;

               return this.assetFieldDefinitionService
                  .getDefinitions({ locationID })
                  .pipe(take(1));
            }),
         )
         .subscribe({
            next: (locationFields) => {
               this.locationFields = locationFields;
               this.initSelectedFields();
            },
            error: (err) => {
               this.assetErrorService.handleRequestError(err);
            },
         });
   }

   private initSelectedFields(): void {
      this.templateFields.forEach((field) => {
         this.selectedFields[field.fieldID] = this.getSelectedFields(field);
      });
   }

   protected getFieldIcon(typeID: number): string {
      if (!typeID) return "";
      return this.manageAsset.getFieldType(typeID)?.fieldTypeIcon ?? "";
   }

   public onMultiselectChange(data: any, fieldID: number) {
      const value: AssetFieldDefinition[] = data?.value;
      if (!value || !fieldID) return;
      const fieldMappings = {
         ...this.fieldMappings(),
         [fieldID]: { mappings: value.map((field) => field.fieldID) },
      };
      this.applyTemplateState.setFieldMapping(fieldMappings);
   }

   public getLocationFieldsGroup(
      templateField: AssetTemplateField,
   ): AssetFieldDefinitionOption[] {
      const filteredFields = this.locationFields
         .filter((field) => field.fieldTypeID === templateField.fieldTypeID)
         .map((field) => ({ ...field, disabled: false }));
      //need to exclude fields already selected in the same field type, but not the current template field
      if (Object.keys(this.fieldMappings()).length > 0) {
         return filteredFields.map((field) => {
            // Get all field mappings except for the current template field
            const otherMappings = Object.entries(this.fieldMappings())
               .filter(([key]) => Number(key) !== templateField.fieldID)
               .flatMap(([, value]) => value.mappings);

            // Disable the field if it's mapped to another template field
            return {
               ...field,
               disabled: otherMappings.includes(field.fieldID),
            };
         });
      }
      return filteredFields;
   }

   protected getSelectedFields(
      templateField: AssetTemplateField,
   ): AssetFieldDefinitionOption[] {
      const mappings = this.fieldMappings()[templateField.fieldID]?.mappings ?? [];
      const options = this.getLocationFieldsGroup(templateField);
      return options.filter((field) => mappings.includes(field.fieldID));
   }
}
