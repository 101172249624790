import { Component, computed, inject, input, forwardRef } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { CardComponent, PanelComponent, TooltipDirective } from "@limblecmms/lim-ui";
import { combineLatest, type Observable, switchMap } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PartReportsForecastTableComponent } from "src/app/parts/components/part-modal/components/part-reports-tab/part-reports-forecast-table/part-reports-forecast-table.component";
import { PartReportsUsageTableComponent } from "src/app/parts/components/part-modal/components/part-reports-tab/part-reports-usage-table/part-reports-usage-table.component";
import { PartsApiService } from "src/app/parts/components/shared";
import { PartTasksDataService } from "src/app/parts/components/shared/part-tasks-data-service.service.ts/part-tasks-data-sevice.service";
import type { PartEntity } from "src/app/parts/components/shared/parts-api-service/parts-api.models";
import type { DataViewerFilter } from "src/app/shared/data-viewer";
import {
   FilterLabelKey,
   DataViewerFiltersComponent,
   DataViewerFilterType,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import type {
   RequestFilter,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import { LimbleMap } from "src/app/shared/utils/limbleMap";

@Component({
   selector: "part-reports-tab",
   templateUrl: "./part-reports-tab.component.html",
   styleUrls: ["./part-reports-tab.component.scss"],
   providers: [DataViewerStateService, PartTasksDataService],
   imports: [
      PanelComponent,
      TooltipDirective,
      forwardRef(() => DataViewerFiltersComponent),
      CardComponent,
      PartReportsUsageTableComponent,
      PartReportsForecastTableComponent,
   ],
})
export class PartReportsTabComponent {
   private readonly manageLang = inject(ManageLang);
   private readonly partTasksDataService = inject(PartTasksDataService);
   private readonly partsApiService = inject(PartsApiService);
   private readonly state = inject(DataViewerStateService);

   private readonly partRequest$: Observable<PartEntity> = combineLatest([
      this.state.requestOptions$,
   ]).pipe(
      switchMap(([requestOptions]) => {
         return this.partsApiService.getPartsByID(
            [this.partID()],
            this.getRequestOptionsForPart(requestOptions),
         );
      }),
   );

   public readonly partID = input.required<number>();
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public taskAssetNames: LimbleMap<number, string> = new LimbleMap();
   public dayFilters: Array<{ days: number | false; display: string }> = [];

   protected readonly part = toSignal<PartEntity>(this.partRequest$);
   protected readonly currencySymbol = this.partTasksDataService.currencySymbol;

   protected readonly staleStatus = computed(() => {
      const daysSinceUsed = this.part()?.daysSincePartUsed;
      const partStaleThreshold = this.part()?.partStaleThreshold;
      if (partStaleThreshold === -1) {
         return this.lang().Disabled;
      } else if (
         partStaleThreshold &&
         daysSinceUsed &&
         daysSinceUsed >= partStaleThreshold
      ) {
         return this.lang().Stale;
      }
      return this.lang().NotStale;
   });

   protected readonly filters: DataViewerFilter[] = [
      {
         type: DataViewerFilterType.DATE,
         labelKey: FilterLabelKey.COMPLETED_DATE,
         key: "completed",
      },
   ];

   private getRequestOptionsForPart(
      baseRequestOptions: Partial<RequestOptions<RequestFilter>>,
   ) {
      // The data viewer only currently supports one request type, so manually change the request options to fetch the part.
      const completedStartRequestOption = baseRequestOptions.filters?.completedStart
         ? { taskCompletedStart: baseRequestOptions.filters?.completedStart }
         : undefined;
      const completedEndRequestOption = baseRequestOptions.filters?.completedEnd
         ? { taskCompletedEnd: baseRequestOptions.filters?.completedEnd }
         : undefined;
      const options = {
         ...baseRequestOptions,
         filters: {
            ...baseRequestOptions.filters,
            partIDs: [this.partID()],
            ...completedStartRequestOption,
            ...completedEndRequestOption,
         },
         columns: "totalPartsUsed,totalCostOfPartsUsed,daysSincePartUsed",
      };
      return options;
   }

   protected async onSetFilter(dataViewerFilter: DataViewerFilter) {
      await this.state.addFilter(dataViewerFilter);
   }

   protected async onRemoveFilter(dataViewerFilter: DataViewerFilter) {
      await this.state.removeFilter(dataViewerFilter);
   }
}
