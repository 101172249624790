import { inject, Injectable } from "@angular/core";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { ManageLang } from "src/app/languages/services/manageLang";
import { FileStorageSyncService } from "src/app/lite/local-db/resources/collection/task/file/file.storage.sync.service";
import { InstructionStorageSyncService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.sync.service";
import { Flags } from "src/app/shared/services/launch-flags/launch-flags.models";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { cleanWordPaste } from "src/app/shared/utils/app.util";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ManageTaskItem {
   private readonly items: any = {
      arr: [],
      index: {},
   };
   private readonly axios = axios;
   private readonly manageLang = inject(ManageLang);
   private readonly instructionStorageSyncService = inject(InstructionStorageSyncService);
   private readonly fileStorageSyncService = inject(FileStorageSyncService);
   private readonly launchFlagService = inject(LaunchFlagsService);
   protected readonly isGetTaskItemsMiniFlannelEnabled = this.launchFlagService.getFlag(
      Flags.TASK_1354_GET_TASK_ITEMS_MINI_FLANNEL,
      false,
   );

   clearData = () => {
      this.items.arr = [];
      this.items.index = {};
   };
   getLocalTaskItems = () => {
      return this.items.arr;
   };
   getTaskItem = (itemID) => {
      return this.items.index[itemID];
   };
   makeFileURL = (itemID, checklistID, lastModified) => {
      const url = `/phpscripts/checklistManager.php?action=makeFile&itemID=${itemID}&checklistID=${checklistID}`;
      if (lastModified) {
         return `${url}&lastModified=${lastModified}`;
      }
      return url;
   };
   /****************************************
    *@function getData
    *@purpose
    *@name getData
    *@param
    *@return
    ****************************************/
   getData = async () => {
      return this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getAllItems" },
         data: {},
      }).then((answer) => {
         this.items.arr = answer.data.this.items || [];
         //Sets array based on Primary Key then binds to orginial array for easier lookup.
         for (const item of this.items.arr) {
            this.items.index[item.itemID] = item;
         }
      });
   };
   /****************************************
    *@function getTaskItems
    *@purpose
    *@name getTaskItems
    *@param
    *@return
    ****************************************/
   getTaskItems = async (checklistID) => {
      if (this.isGetTaskItemsMiniFlannelEnabled()) {
         const response = await fetch(
            `${environment.servicesURL()}/tasks/${checklistID}/items`,
            {
               mode: "cors",
               credentials: "include",
               method: "GET",
            },
         );

         const data = await response.json();
         if (data.items) {
            for (const item of data.items) {
               this.items.arr.push(item);
               this.items.index[item.itemID] = item;

               if (item.itemParentID > 0) {
                  for (const parentItem of data.items) {
                     if (parentItem.checklistItemCount == item.itemParentID) {
                        item.parent = parentItem;
                        break;
                     }
                  }
               }
            }
         }

         return Promise.resolve({ data });
      }

      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: { action: "getTaskItems" },
         data: { checklistID: checklistID },
      });

      post.then((answer) => {
         if (answer.data.items) {
            for (const item of answer.data.items) {
               this.items.arr.push(item);
               this.items.index[item.itemID] = item;

               if (item.itemParentID > 0) {
                  for (const parentItem of answer.data.items) {
                     if (parentItem.checklistItemCount == item.itemParentID) {
                        item.parent = parentItem;
                        break;
                     }
                  }
               }
            }
         }
      });
      return post;
   };
   /****************************************
    *@function addFile
    *@purpose
    *@name addFile
    *@param
    *@return
    ****************************************/
   addFile = async (posturl, formdata) => {
      const post = this.axios({
         url: posturl,
         method: "POST",
         data: formdata,
         params: { action: "makeFile" },
      });

      return post;
   };
   /****************************************
    *@function addInstructionalFile
    *@purpose
    *@name addInstructionalFile
    *@param
    *@return
    ****************************************/
   addInstructionalFile = async (postUrl: string, formData: FormData) => {
      const post = await this.axios({
         url: postUrl,
         method: "POST",
         data: formData,
         params: { action: "makeFileForItem" },
      });

      if (!post.data.failed) {
         const instructionID = Number(postUrl.split("&itemID=")[1].split("&")[0]);
         const fileName = String(post.data.fileName);

         this.fileStorageSyncService.syncInstructionalFileAddition({
            instructionID,
            fileName,
            formData,
         });
      }

      return post;
   };
   /****************************************
    *@function deleteFile
    *@purpose
    *@name deleteFile
    *@param
    *@return
    ****************************************/
   deleteFile = async (deleteData) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "deleteFile",
         },
         data: {
            fileName: deleteData.fileName,
            checklistID: deleteData.checklistID,
            itemID: deleteData.itemID,
         },
      });
      return post;
   };
   /****************************************
    *@function setResponse
    *@purpose
    *@name setResponse
    *@param
    *@return
    ****************************************/
   setResponse = async (
      itemID,
      itemResponse,
      overrideCompleted,
      resetCustomTag: boolean = false,
      isAnomaly: boolean = false,
   ) => {
      const itemResponseClean = cleanWordPaste(itemResponse);

      // extraLogData is needed specifically for 21CFR info - don't remove until we create a full logging system
      const item = this.items.index[itemID];
      const extraLogData: Record<string, any> = {
         checklistID: item?.checklistID,
         instructionName: item?.itemText,
         displayText: item?.displayText ?? "",
      };

      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "setResponse",
         },
         data: {
            response: itemResponseClean,
            item: itemID,
            overrideCompleted: overrideCompleted,
            resetCustomTag,
            extraLogData,
            isAnomaly,
         },
      });
      return post;
   };
   /****************************************
    *@function updateItemDescription
    *@purpose
    *@name updateItemDescription
    *@param
    *@return
    ****************************************/
   updateItemDescription = async (item) => {
      item.itemDescription = cleanWordPaste(item.itemDescription);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateItemDescription",
         },
         data: {
            text: item.itemDescription,
            item: item.itemID,
            chk: item.checklistID,
         },
      });

      return post;
   };

   /****************************************
    *@function updateItemSourceText
    *@purpose
    *@name updateItemSourceText
    *@param
    *@return
    ****************************************/
   updateItemSourceText = async (item) => {
      item.itemSourceText = cleanWordPaste(item.itemSourceText);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateItemSource",
         },
         data: {
            text: item.itemSourceText,
            item: item.itemID,
            chk: item.checklistID,
         },
      });

      return post;
   };

   /****************************************
    *@function itemDelete
    *@purpose
    *@name itemDelete
    *@param
    *@return
    ****************************************/
   itemDelete = async (item) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "itemDelete",
         },
         data: {
            id: item.itemID,
            chk: item.checklistID,
         },
      });

      if (post.data.success) {
         const instructionID = Number(item.itemID);
         this.instructionStorageSyncService.syncInstructionDeletion(instructionID);
      }

      return post;
   };

   /****************************************
    *@function insertChild
    *@purpose
    *@name insertChild
    *@param
    *@return
    ****************************************/
   insertChild = async (item, itemTypeID, parentResponse) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "insertChild",
         },
         data: {
            response: parentResponse,
            parent: item.checklistItemCount,
            type: itemTypeID,
            chk: item.checklistID,
         },
      });

      if (post.data?.success && post.data?.myItem?.itemID) {
         const instructionID = Number(post.data.myItem.itemID);
         this.instructionStorageSyncService.syncInstruction(instructionID);
      }

      return post;
   };

   /****************************************
    *@function updateItemText
    *@purpose
    *@name updateItemText
    *@param
    *@return
    ****************************************/
   updateItemText = async (item) => {
      item.itemText = cleanWordPaste(item.itemText);

      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateItemText",
         },
         data: {
            item: item.itemID,
            text: item.itemText,
            chk: item.checklistID,
         },
      });

      if (post.data.success) {
         const instructionID = Number(item.itemID);
         this.instructionStorageSyncService.syncInstruction(instructionID);
      }

      return post;
   };
   //PT - Good to go
   /****************************************
    *@function linkAssignTask
    *@purpose
    *@name linkAssignTask
    *@param
    *@return
    ****************************************/
   linkAssignTask = async (item, linkedChecklistID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "linkAssignTask",
         },
         data: {
            item: item.itemID,
            linkedChk: linkedChecklistID,
            chk: item.checklistID,
         },
      });

      return post;
   };

   /****************************************
    *@function updateItemFields
    *@purpose updates any fields on any number of items (1 or more items)
    *@name updateItemFields
    *@param updates OBJECT top key = itemID, sub objects = field/value to be updated example: {'10252':{'itemOrder','2'},'11325':{itemText:'hello world'}}
    *@return
    ****************************************/
   updateItemFields = async (updates) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateItemFields",
         },
         data: {
            updates: updates,
         },
      });

      return post;
   };
   /****************************************
    *@function updateOptionFields
    *@purpose updates any fields on any number of item options (1 or more items)
    *@name updateOptionFields
    *@param updates OBJECT top key = itemOptionID, sub objects = field/value to be updated example: {'10252':{'itemOptionOrder','2'},'11325':{itemOptionText:'hello world'}}
    *@return
    ****************************************/
   updateOptionFields = async (updates) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateOptionFields",
         },
         data: {
            updates: updates,
         },
      });

      return post;
   };
   /****************************************
    *@function addItemResponseToLogLink
    *@purpose
    *@name addItemResponseToLogLink
    *@param
    *@return
    ****************************************/
   addItemResponseToLogLink = async (item, assetID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "addItemResponseToLogLink",
         },
         data: {
            itemBatchID: item.itemBatchID,
            assetID: assetID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            item.logged = answer.data.matchID;
            item.assetIDToLogResponseTo = answer.data.assetIDToLogResponseTo;

            const instructionID = Number(item.itemID);
            this.instructionStorageSyncService.syncInstruction(instructionID);
         }
      });

      return post;
   };
   /****************************************
    *@function removeItemResponseToLogLink
    *@purpose
    *@name removeItemResponseToLogLink
    *@param
    *@return
    ****************************************/
   removeItemResponseToLogLink = async (item, assetID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "removeItemResponseToLogLink",
         },
         data: {
            itemBatchID: item.itemBatchID,
            assetID: assetID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            item.logged = null;
            item.assetIDToLogResponseTo = 0;
         }
      });

      return post;
   };

   /****************************************
    *@function removeItemImage
    *@purpose
    *@name removeItemImage
    *@param
    *@return
    ****************************************/
   removeItemImage = async (deleteData) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "removeItemImage",
         },
         data: {
            itemID: deleteData.itemID,
            itemFileName: deleteData.fileName,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            if (deleteData.item) {
               deleteData.item.itemFileName = deleteData.item.itemFileName.replace(
                  `${answer.data.fileName};`,
                  "",
               );
            }

            const fileName = String(deleteData.fileName);
            const instructionID = Number(deleteData.itemID);
            this.fileStorageSyncService.syncInstructionalFileDeletion({
               fileName,
               instructionID,
            });
         }
      });

      return post;
   };

   linkItemToAssetInfo = async (item, fieldIDForLink: number, assetIDForLink: number) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "linkItemToAssetInfo",
         },
         data: {
            itemID: item.itemID,
            fieldIDForLink,
            assetIDForLink,
            itemTypeID: item.itemTypeID,
         },
      });

      post.then((answer) => {
         if (answer.data.success) {
            const instructionID = Number(item.itemID);
            this.instructionStorageSyncService.syncInstructionUpdate(instructionID, {
               fieldIDForLink,
               assetIDForLink,
            });
         }
      });

      return post;
   };

   /****************************************
    *@function changeItemType
    *@purpose
    *@name changeItemType
    *@param
    *@return
    ****************************************/
   changeItemType = async (item, itemTypeID) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "changeItemType",
         },
         data: {
            itemID: item.itemID,
            itemTypeID: itemTypeID,
         },
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            //if the old itemtypeid = 10 then we need to set the itemText to the default 'Instructions"
            if (item.itemTypeID == TaskInstructionTypeID.AssignPM) {
               item.itemText = "Instructions";
               item.checklistToSpawn = null;
            }

            item.itemTypeID = itemTypeID;
            item.itemResponse = answer.data.itemResponse;
            item.itemIcon = answer.data.itemIcon;
            item.itemTypeDescription = answer.data.itemTypeDescription;
            item.fieldIDForLink = answer.data.fieldIDForLink;
            item.assetIDForLink = answer.data.assetIDForLink;
            item.logged = null;

            //if we are changing to a dropdown or a radio list we need to get rid of images
            if (
               itemTypeID == TaskInstructionTypeID.OptionList ||
               itemTypeID == TaskInstructionTypeID.DropdownList
            ) {
               item.itemFileName = null;
            }
         }
      });

      return post;
   };
   /****************************************
    *@function duplicateItem
    *@purpose
    *@name duplicateItem
    *@param
    *@return
    ****************************************/
   duplicateItem = async (item) => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "duplicateItem",
         },
         data: {
            itemID: item.itemID,
         },
      });

      if (post.data.success) {
         const instructionID = Number(item.checklistID);
         this.instructionStorageSyncService.syncInstructionsByTask(instructionID);
      }

      return post;
   };
   /****************************************
    *@function changeApprovalTarget
    *@purpose
    *@name changeApprovalTarget
    *@param
    *@return
    ****************************************/
   changeApprovalTarget = async (userID, profileID, multiUsers, item) => {
      const multiUsersStringifed = JSON.stringify(multiUsers);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "changeApprovalTarget",
         },
         data: {
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringifed,
            itemID: item.itemID,
         },
      });

      return post;
   };

   /****************************************
    *@function changeAfterApprovalTarget
    *@purpose
    *@name changeAfterApprovalTarget
    *@param
    *@return
    ****************************************/
   changeAfterApprovalTarget = async (userID, profileID, multiUsers, item) => {
      const multiUsersStringifed = JSON.stringify(multiUsers);
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "changeAfterApprovalTarget",
         },
         data: {
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringifed,
            itemID: item.itemID,
         },
      });

      return post;
   };

   /****************************************
    *@function changeAfterDisapproveTarget
    *@purpose
    *@name changeAfterDisapproveTarget
    *@param
    *@return
    ****************************************/
   changeAfterDisapproveTarget = async (userID, profileID, multiUsers, item) => {
      const multiUsersStringifed = JSON.stringify(multiUsers);

      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "changeAfterDisapproveTarget",
         },
         data: {
            userID: userID,
            profileID: profileID,
            multiUsers: multiUsersStringifed,
            itemID: item.itemID,
         },
      });

      return post;
   };

   syncInstructionSetInstance = async (
      itemID: number,
      checklistID: number,
      instructionSetBatchID: number,
      parentItemOptionCount: number | null,
   ): Promise<any> => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "syncInstructionSetInstance",
         },
         data: {
            itemID: itemID,
            checklistID: checklistID,
            instructionSetBatchID: instructionSetBatchID,
            parentItemOptionCount,
         },
      });

      return post;
   };

   updateParentInstructionSetTimeStamp = async (checklistID: number): Promise<any> => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "updateParentInstructionSetTimeStamp",
         },
         data: {
            checklistID: checklistID,
         },
      });

      return post;
   };
   prepSyncRelatedInstructionSets = async (
      checklistID: number,
   ): Promise<
      | {
           instructionSetBatchID: number;
           toUpdateArray: Array<{
              itemID: number;
              checklistID: number;
              itemParentResponse: string;
           }>;
           message: string;
        }
      | false
   > => {
      const lang = this.manageLang.lang() ?? {};
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "getRelatedInstructionSets",
         },
         data: {
            checklistID: checklistID,
         },
      });
      if (!post.data.success) {
         return false;
      }

      const instructionSetBatchID = post.data.instructionSetBatchID;
      const toUpdateArray = post.data.toUpdateArray;
      const itemsPerInstructionSet = post.data.itemsPerInstructionSet;
      const message = `${lang.ConfirmSyncRelatedInstructionSets} <br><br> ${lang.InstructionSetsToUpdate}: ${toUpdateArray.length} <br> ${lang.NumberOfInstructionsPerInstructionSet}: ${itemsPerInstructionSet}`;

      return { instructionSetBatchID, toUpdateArray, message };
   };

   syncBatchOfRelatedInstructionSets = async (
      relatedInstructionSets: any[],
      instructionSetBatchID: number,
   ): Promise<AxiosResponse<any>> => {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "syncBatchOfRelatedInstructionSets",
         },
         data: {
            stringifiedArray: relatedInstructionSets,
            instructionSetBatchID: instructionSetBatchID,
         },
      });

      return post;
   };

   generateInstructionSetInstance = async (
      itemID: number,
      targetChecklistID: number,
      targetParentItem?: number | null,
      parentOptionCount?: number | null,
      parentChecklistItemCount?: number | null,
   ): Promise<any> => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/checklistManager.php",
         params: {
            action: "generateInstructionSetInstance",
         },
         data: {
            itemID: itemID,
            targetChecklistID: targetChecklistID,
            targetParentItem: targetParentItem,
            parentOptionCount: parentOptionCount,
            parentChecklistItemCount: parentChecklistItemCount,
         },
      });

      return post;
   };
}
