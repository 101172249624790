import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";
import {
   ModalService,
   OutlinedButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { interval, take } from "rxjs";
import { PmSuggestionsTableComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel-body/pm-suggestions-table/pm-suggestions-table.component";
import { SelectAssetsModalComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel-body/select-assets-modal/select-assets-modal.component";
import { SelectDocumentsModalComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel-body/select-documents-modal/select-documents-modal.component";
import { PmBuilderPanelService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-panel/pm-builder-panel.service";
import { PmSuggestionsResourceService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource.service";

@Component({
   selector: "pm-builder-panel-body",
   templateUrl: "./pm-builder-panel-body.component.html",
   styleUrls: ["./pm-builder-panel-body.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [PmSuggestionsTableComponent, OutlinedButtonComponent, TooltipDirective],
})
export class PmBuilderPanelBodyComponent {
   private readonly pmSuggestionsResources = inject(PmSuggestionsResourceService);
   private readonly modalService = inject(ModalService);
   private readonly panelService = inject(PmBuilderPanelService);
   public readonly isLoading = signal(false);

   public async startManualBuildRun(): Promise<void> {
      const assetIDs = await this.selectAssets();
      if (assetIDs === undefined) {
         return;
      }
      const modalRef = this.modalService.open(SelectDocumentsModalComponent);
      modalRef.setInput("assetIDs", assetIDs);
      const result = await modalRef.result;
      if (result === undefined) return;
      if (result === "back") {
         this.startManualBuildRun();
         return;
      }
      // TODO(WRENCH-297): We are just pretending to add the PM suggestions for now
      interval(1500)
         .pipe(take(result.size * 2))
         .subscribe(() => {
            this.addPmSuggestion();
         });
   }

   /** TODO(WRENCH-297): This is just to facilitate the placeholder until we can get some real content */
   public async addPmSuggestion(): Promise<void> {
      this.isLoading.set(true);
      await this.pmSuggestionsResources.create(this.generateRandomSuggestion());
      this.isLoading.set(false);
   }

   private generateRandomSuggestion() {
      const generatedTime = {
         type: "time" as const,
         units: this.generateRandomScheduleUnitsTime(),
         interval: Math.floor(Math.random() * 10) + 1,
      };
      const generatedMetric = {
         type: "metric" as const,
         units: this.generateRandomScheduleUnitsMetric(),
         interval: Math.floor(Math.random() * 10) + 1,
         startAt: 0,
      };
      const scheduleType = Math.random() < 0.5 ? generatedTime : generatedMetric;
      return {
         name: `PM Suggestion ${Math.floor(Math.random() * 1000000)}`,
         instructions: [],
         customerID: 1,
         assetID: 1,
         documentID: "1234567890",
         schedule: scheduleType,
      };
   }

   private generateRandomScheduleUnitsTime(): "daily" | "weekly" | "monthly" | "yearly" {
      return (["daily", "weekly", "monthly", "yearly"] as const)[
         Math.floor(Math.random() * 4)
      ];
   }

   private generateRandomScheduleUnitsMetric():
      | "hours"
      | "miles"
      | "liters"
      | "kilograms" {
      return (["hours", "miles", "liters", "kilograms"] as const)[
         Math.floor(Math.random() * 4)
      ];
   }

   private async selectAssets(): Promise<Array<number> | undefined> {
      const limits = this.panelService.limits();
      if (limits.assetID !== undefined) {
         return [limits.assetID];
      }
      const modalRef = this.modalService.open(SelectAssetsModalComponent);
      modalRef.setInput("locationID", limits.locationID);
      return modalRef.result;
   }
}
