import type { OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import { IconComponent, ModalService, LimbleHtmlDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderItem } from "src/app/purchasing/types/purchase-order-item.types";
import { ParamsService } from "src/app/shared/services/params.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { ManageUser } from "src/app/users/services/manageUser";

type AugmentedPurchaseOrderItem = PurchaseOrderItem & {
   itemName: string;
   itemNumber: string;
   receivedQty: number;
};

@Component({
   selector: "po-item-left-list-short",
   templateUrl: "./poItemLeftListShort.element.component.html",
   styleUrls: ["./poItemLeftListShort.element.component.scss"],
   imports: [IconComponent, LimbleHtmlDirective],
})
export class POItemLeftListShort implements OnInit {
   @Input() public itemIDs: Array<number> = [];
   @Input() public click;

   protected items: Lookup<"poItemID", AugmentedPurchaseOrderItem> = new Lookup(
      "poItemID",
   );
   public CID;
   public currencySymbol;

   private readonly managePO = inject(ManagePO);
   private readonly manageUser = inject(ManageUser);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      this.CID = this.manageUser.getCurrentUser().userInfo.customerID;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;

      for (const itemID of this.itemIDs) {
         const item = this.managePO.getPurchaseOrderItem(itemID);
         if (!item) {
            continue;
         }
         const relatedInfo = this.managePO.getPurchaseOrderItemRelatedInfo(itemID);
         const itemName = relatedInfo?.itemName ?? "";
         const itemNumber = relatedInfo?.itemNumber ?? "";
         const receivedQty =
            this.managePO.getPurchaseOrderItemReceivedInfo(item.poItemID)?.receivedQty ??
            0;
         this.items.set(itemID, { ...item, itemName, itemNumber, receivedQty });
      }

      for (const item of this.items) {
         if (
            item.qty ===
            this.managePO.getPurchaseOrderItemReceivedInfo(item.poItemID)?.receivedQty
         ) {
            // they are fully received so don't show
            this.items.delete(item.poItemID);
         }
      }
   }

   protected popPoComponent(poID: number) {
      if (this.click == false) {
         return;
      }

      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID: poID },
         },
      };
   }
}
