@if (asset) {
   @if (isPhoneScreenSize$ | async) {
      <lim-ui-scroll-container [thin]="true">
         <ng-container *ngTemplateOutlet="assetTile" />
      </lim-ui-scroll-container>
   } @else {
      <asset-modal
         [assetID]="asset.assetID"
         [dataLogOptions]="dataLogOptions"
         [preventParentAccess]="preventParentAccess"
         [restrict]="restrict"
         [navigateToTab]="navigateToTab"
         [scrollableContent]="(isPhoneScreenSize$ | async) === false"
         (closeModalEvent)="close()"
      />
   }

   <ng-template #assetTile>
      <asset-modal
         [assetID]="asset.assetID"
         [dataLogOptions]="dataLogOptions"
         [preventParentAccess]="preventParentAccess"
         [restrict]="restrict"
         [navigateToTab]="navigateToTab"
         [scrollableContent]="(isPhoneScreenSize$ | async) === false"
         (closeModalEvent)="close()"
      />
   </ng-template>
}
