import { z } from "zod";

export const SetCurrencyResponseDtoSchema = z.object({
   success: z.literal(true),
   currency: z.object({
      currencyName: z.string(),
      currencySymbol: z.string(),
      alphabeticCodeIso: z.string(),
      rate: z.string(),
      currencyID: z.number(),
   }),
});
export type SetCurrencyResponseDto = z.infer<typeof SetCurrencyResponseDtoSchema>;
export type SetCurrencyBodyDto = {
   currencyCode: string;
   currencyID: number;
};
