<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header title="{{ title }}" (closeModal)="close()" />
   <lim-ui-modal-body (click)="resetError()">
      <div class="panel-container-wrapper">
         <lim-ui-panel>
            <div class="panel-container">
               <label class="control-label" [limbleHtml]="lang().DoYouNeedToRequestParts">
               </label>
               <div class="lim-ui-radio-container">
                  <lim-ui-radio-button
                     groupID="purchaseParts"
                     label="{{ lang().Yes }}"
                     [checked]="purchaseParts === 1"
                     (click)="purchaseParts = 1; checkIfFirstPartsClick()"
                  />
                  <lim-ui-radio-button
                     groupID="purchaseParts"
                     label="{{ lang().No }}"
                     [checked]="purchaseParts === 0"
                     (click)="purchaseParts = 0"
                  />
               </div>
               @if (purchaseParts === 1) {
                  <div class="conditional-wrapper">
                     @for (part of parts; track part) {
                        <div class="lim-ui-list-item">
                           <div class="conditional-list-item-wrapper">
                              <div class="conditional-icon-textbox-wrapper">
                                 <lim-ui-icon
                                    icon="check"
                                    iconSize="small"
                                    class="green-color"
                                 />
                                 <lim-ui-icon icon="gears" iconSize="small" />
                                 <input
                                    type="number"
                                    onclick="this.focus();"
                                    [(ngModel)]="part.tempNumber"
                                    (ngModelChange)="validateNum(part.tempNumber)"
                                    min="0"
                                 />
                                 @if (unitOfMeasureService.isFeatureEnabled()) {
                                    <span
                                       [limUiTooltip]="
                                          part.partID | partUnitOfMeasure: 'singular'
                                       "
                                    >
                                       {{ part.partID | partUnitOfMeasure: "short" }}
                                    </span>
                                 }
                              </div>
                              <div class="conditional-info-wrapper">
                                 <span class="bold">{{ part.partName }}</span>
                                 @if (part.partNumber) {
                                    <span class="part-name-span">
                                       - {{ part.partNumber }}
                                    </span>
                                 }
                                 <lim-ui-icon
                                    icon="trashCanRegular"
                                    iconSize="small"
                                    (click)="removePart(part)"
                                 />
                              </div>
                           </div>
                        </div>
                     }
                     <div class="conditional-action-button">
                        <lim-ui-secondary-button
                           icon="plus"
                           (click)="addPart()"
                           [ngClass]="{
                              'btn-success': parts.length === 0,
                              'btn-default': parts.length > 0,
                           }"
                        >
                           {{ lang().AddPart }}
                        </lim-ui-secondary-button>
                     </div>
                  </div>
               }
            </div>
         </lim-ui-panel>
         <lim-ui-panel>
            <div class="panel-container">
               <label
                  class="control-label"
                  [limbleHtml]="lang().DoYouNeedToRequestService"
               >
               </label>
               <div class="lim-ui-radio-container">
                  <lim-ui-radio-button
                     groupID="purchaseService"
                     label="{{ lang().Yes }}"
                     [checked]="purchaseService === 1"
                     (click)="purchaseService = 1"
                  />
                  <lim-ui-radio-button
                     groupID="assignToAsset"
                     label="{{ lang().No }}"
                     [checked]="purchaseService === 0"
                     (click)="purchaseService = 0"
                  />
               </div>
               @if (purchaseService === 1) {
                  <div class="conditional-wrapper">
                     <div class="action-wrapper">
                        <lim-ui-icon icon="addressCard" iconSize="small" />
                        <a (click)="pickVendor(1)" class="action-link">
                           @if (!vendor) {
                              <span> {{ lang().PickVendor }} </span>
                           }
                           @if (vendor) {
                              <span [limbleHtml]="vendor.vendorName"> </span>
                           }
                        </a>
                     </div>
                     <div class="lim-ui-list-item">
                        <div class="conditional-list-item-wrapper no-icon">
                           <div
                              type="text"
                              [(ngModel)]="service"
                              limbleContentEditable
                              [ngClass]="{ 'red-border': serviceError }"
                              attr.placeholder="{{ lang().Description }}"
                           ></div>
                           <div class="price-calculated-div">
                              <input
                                 type="number"
                                 onclick="this.focus();"
                                 [(ngModel)]="serviceQty"
                                 (ngModelChange)="serviceQty = validateNum(serviceQty)"
                                 attr.placeholder="{{ lang().Qty }}"
                                 [ngClass]="{ 'red-border': serviceCostErrorQty }"
                                 min="0"
                              />
                              <input
                                 type="number"
                                 onclick="this.focus();"
                                 [(ngModel)]="servicePrice"
                                 (ngModelChange)="
                                    servicePrice = validateNum(servicePrice)
                                 "
                                 attr.placeholder="{{ lang().Price }}"
                                 [ngClass]="{ 'red-border': serviceCostErrorPrice }"
                                 min="0"
                              />

                              <div disabled="true" class="cost-wrapper">
                                 @if (!(serviceQty > 0 && servicePrice > 0)) {
                                    <span>
                                       {{ lang().TotalCost }}
                                    </span>
                                 }
                                 @if (serviceQty && servicePrice > 0) {
                                    <cost-viewer-component
                                       [rate]="serviceQty * servicePrice"
                                       [locationID]="currentLocationID()"
                                    />
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               }
            </div>
         </lim-ui-panel>
         <lim-ui-panel>
            <div class="panel-container">
               <label
                  class="control-label"
                  [limbleHtml]="lang().DoYouNeedToRequestAnOtherPurchase"
               >
               </label>
               <div class="lim-ui-radio-container">
                  <lim-ui-radio-button
                     groupID="purchaseOther"
                     label="{{ lang().Yes }}"
                     [checked]="purchaseOther === 1"
                     (click)="purchaseOther = 1"
                  />
                  <lim-ui-radio-button
                     groupID="purchaseOther"
                     label="{{ lang().No }}"
                     [checked]="purchaseOther === 0"
                     (click)="purchaseOther = 0"
                  />
               </div>
               @if (purchaseOther === 1) {
                  <div class="conditional-wrapper">
                     <div class="action-wrapper">
                        <lim-ui-icon icon="addressCard" iconSize="small" />
                        <a class="action-link" (click)="pickVendor(2)">
                           @if (!vendor2) {
                              <span> {{ lang().PickVendor }} </span>
                           }
                           @if (vendor2) {
                              <span [limbleHtml]="vendor2.vendorName"> </span>
                           }
                        </a>
                     </div>
                     @for (item of otherArr; track item; let idx = $index) {
                        <div class="lim-ui-list-item">
                           <div class="conditional-list-item-wrapper">
                              <div
                                 type="text"
                                 [(ngModel)]="item.other"
                                 limbleContentEditable
                                 disableEditor="true"
                                 [ngClass]="{ 'red-border': item.otherError }"
                                 attr.placeholder="{{ lang().Description }}"
                              ></div>
                              <div class="price-calculated-div">
                                 <input
                                    type="number"
                                    onclick="this.focus();"
                                    [(ngModel)]="item.otherQty"
                                    (ngModelChange)="
                                       item.otherQty = validateNum(item.otherQty);
                                       changeTotal()
                                    "
                                    attr.placeholder="{{ lang().Qty }}"
                                    min="0"
                                    [ngClass]="{ 'red-border': item.otherCostErrorQty }"
                                 />
                                 <input
                                    type="number"
                                    onclick="this.focus();"
                                    [(ngModel)]="item.otherPrice"
                                    (ngModelChange)="
                                       item.otherPrice = validateNum(item.otherPrice);
                                       changeTotal()
                                    "
                                    attr.placeholder="{{ lang().Price }}"
                                    min="0"
                                    [ngClass]="{ 'red-border': item.otherCostErrorPrice }"
                                 />

                                 <div disabled="true" class="cost-wrapper">
                                    @if (!(item.otherQty > 0 && item.otherPrice > 0)) {
                                       <span>
                                          {{ lang().TotalCost }}
                                       </span>
                                    }
                                    @if (item.otherQty && item.otherPrice > 0) {
                                       <cost-viewer-component
                                          [rate]="item.otherQty * item.otherPrice"
                                          [locationID]="currentLocationID()"
                                          [currencySymbol]="currencySymbol"
                                       />
                                    }
                                 </div>
                                 <div class="icon-wrapper">
                                    @if (otherArr.length > 1) {
                                       <lim-ui-minimal-icon-button
                                          icon="trashCanRegular"
                                          [superMinimal]="true"
                                          iconColor="danger"
                                          iconSize="small"
                                          (click)="removeOtherItem(idx)"
                                       />
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     }

                     <div class="grand-total-wrapper">
                        <div class="grand-total-price-wrapper">
                           @if (otherArr.length > 1) {
                              <div class="label bold">
                                 {{ lang().Total }}
                              </div>
                           }

                           @if (otherArr.length > 1) {
                              <div disabled="true" class="cost-wrapper">
                                 @if (totalOtherCost === 0) {
                                    <span>
                                       {{ lang().GrandTotal }}
                                    </span>
                                 }
                                 @if (totalOtherCost > 0) {
                                    <span>
                                       {{ totalOtherCost | betterCurrency }}
                                    </span>
                                 }
                              </div>
                           }
                        </div>
                        <lim-ui-secondary-button icon="plus" (click)="addItemToOther()">
                           {{ lang().AddAnotherItem }}
                        </lim-ui-secondary-button>
                     </div>
                  </div>
               }
            </div>
         </lim-ui-panel>
         <lim-ui-panel>
            <div class="panel-container">
               <label
                  class="control-label"
                  [limbleHtml]="lang().WhatIsTheReasonForThisRequest"
               >
               </label>
               <div
                  type="text"
                  [(ngModel)]="reason"
                  limbleContentEditable
                  disableEditor="true"
                  class="reason-textarea"
                  [ngClass]="{ 'red-border': reasonError }"
               ></div>
               <div class="conditional-action-button">
                  <comment-files
                     [uploadObject]="uploadObject"
                     [files]="files"
                     icon="plus"
                     label="AttachFile"
                     class="comment-files-class"
                  />
               </div>
            </div>
         </lim-ui-panel>
      </div>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      successButtonText="{{ lang().Submit }}"
      (successClick)="submit()"
   />
</lim-ui-modal>
