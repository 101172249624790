import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type {
   PostLogsEntity,
   PostLogsEntityFilters,
} from "src/app/settings/services/post-logs-api/post-logs-api.models";
import { FlannelApiService } from "src/app/shared/services/flannel-api-service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class PostLogsApiService extends FlannelApiService<
   PostLogsEntity,
   PostLogsEntityFilters
> {
   private readonly http = inject(HttpClient);

   public constructor() {
      super("post-logs?columns=user");
   }

   public getActions(): Observable<Array<string>> {
      return this.http.get<Array<string>>(
         `${environment.flannelUrl}/jit/post-logs/actions`,
      );
   }
}
