@if (this.assetID() && assetsLoaded()) {
   <span class="asset-cell">
      <lim-ui-icon icon="cube" iconColor="standard" />
      <span
         (click)="onAssetClick($event)"
         class="green-color green-color clickable"
         [limbleHtml]="this.assetID() ?? 0 | assetName"
      ></span>
      @if (assetsLoaded()) {
         <asset-parent-list [assetID]="this.assetID()" linkColorClass="task-sub-title" />
      }
   </span>
}
