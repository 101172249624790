import { computed, inject, Injectable, type Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import moment from "moment";
import clone from "rfdc";
import { from, take } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageDepreciationSchedule } from "src/app/assets/services/manageDepreciationSchedule";
import { ManageTool } from "src/app/assets/services/manageTool";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldValueFile } from "src/app/assets/types/field/value/file/file.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import type {
   WellDefinedWidgetDef,
   WidgetDefinition,
} from "src/app/dashboards/custom-dashboards/customDashboard.types";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import type { Column } from "src/app/dashboards/widgets/list/list.service";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { DEFAULT_CURRENCY } from "src/app/purchasing/currency/utils/default-currency-code";
import { CustomColumnListHeaderService } from "src/app/shared/components/global/customColumnListHeader/customColumnListHeader.service";
import { betterCurrency } from "src/app/shared/pipes/betterCurrency.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import type { RequestFilter } from "src/app/shared/services/flannel-api-service/api.models";
import { Flags } from "src/app/shared/services/launch-flags/launch-flags.models";
import { LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags/legacy-launch-flags.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import type { CheckOutRequest } from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import {
   TaskEntityType,
   TaskStatus,
} from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

const deepClone = clone();

export type FieldData = AssetField &
   AssetFieldValue & {
      files: Array<AssetFieldValueFile>;
      fileNamesStr: string;
      formattedValueForExport?: any;
   };

export type TaskTypeView =
   | "completedPlannedWOs"
   | "completedUnplannedWOs"
   | "completedWorkRequests"
   | "completedPms"
   | "openPlannedWorkOrders"
   | "openUnplannedWorkOrders"
   | "openPMs"
   | "openWorkRequests"
   | "totalCompletedTasks"
   | "totalOpenTasks"
   | "totalTasks";

export type AssetsExtraInfo = {
   assetID: number;
   assetName: string;
   assetCreatedDate: number;
   locationName: string;
   locationID: number;
   openTasksList: Array<any>;
   openTasks: Array<any>;
   completedTasksList: Array<any>;
   completedTasks: Array<any>;
   completedPlannedWOsList: Array<any>;
   completedPlannedWOs: number;
   completedUnplannedWOsList: Array<any>;
   completedUnplannedWOs: number;
   completedPmsList: Array<any>;
   completedPms: number;
   completedWorkRequestsList: Array<any>;
   completedWorkRequests: number;
   totalCompletedTasksList: Array<any>;
   totalCompletedTasks: number;
   openPlannedWOsList: Array<any>;
   openPlannedWOs: number;
   openUnplannedWOsList: Array<any>;
   openUnplannedWOs: number;
   openPmsList: Array<any>;
   openPms: number;
   openWorkRequestsList: Array<any>;
   openWorkRequests: number;
   totalOpenTasksList: Array<any>;
   totalOpenTasks: number;
   totalTasksList: Array<any>;
   totalTasks: number;
   plannedVsUnplannedList: Array<any>;
   plannedVsUnplanned2List: Array<any>;
   timeSpent2List: Array<any>;
   partsUsedList: Array<any>;
   plannedVsUnplanned: number | string;
   plannedVsUnplanned2: number | string;
   partsUsed: number;
   timeSpent2: number;
   downtimeTaskList: Array<any>;
   downtimeTaskCount: number;
   downtime: number;
   downtimeHrs: number;
   uptime: number;
   uptimeHrs: number;
   downtimePercent: number;
   uptimePercent: number;
   mtbf: number;
   mttr: number;
   costPartsList: Array<any>;
   costParts: number;
   costLaborList: Array<any>;
   costLabor: number;
   costInvoicesList: Array<any>;
   costInvoices: number;
   costTotalList: Array<any>;
   costTotal: number;
   purchaseCost: string;
   salvageValue: string;
   standardUsefulLife: string;
   scheduleStartDate: string;
   scheduleEndDate: string;
   depreciationScheduleTaskAssignment: string;
   customField1Data?: FieldData;
   customField2Data?: FieldData;
   customField3Data?: FieldData;
   customField4Data?: FieldData;
   customField5Data?: FieldData;
   customField6Data?: FieldData;
   customField7Data?: FieldData;
   customField8Data?: FieldData;
   costPerCustomField: string;
   checkOutStatus: string;
   requestedByOrCheckedOutTo: string;
   approvedOrDeniedBy: string | false;
   checkOutActionDate: string;
   currencyCode?: string;
};

export type AssetListWidgetDef = WellDefinedWidgetDef & {
   type: "assets";
   viewedAs: "listView";
};

@Injectable({ providedIn: "root" })
export class AssetListService {
   private combinedFieldValueKeyLookup: Map<string, AssetFieldValue> | undefined;

   private readonly manageLang = inject(ManageLang);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageDashboard = inject(ManageDashboard);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly alertService = inject(AlertService);
   private readonly customColumnListHeaderService = inject(CustomColumnListHeaderService);
   private readonly manageDepreciationSchedule = inject(ManageDepreciationSchedule);
   private readonly manageTool = inject(ManageTool);
   private readonly manageUser = inject(ManageUser);
   private readonly betterDate = inject(BetterDate);
   private readonly i18n = inject(TranslationService).i18n;
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly toolCheckouts = new Map<number, Array<CheckOutRequest>>();
   private readonly featureFlagService = inject(FeatureFlagService);
   private readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;

   protected readonly assetTemplatesLaunchFlag: Signal<boolean | undefined> = toSignal(
      from(this.legacyLaunchFlagsService.isEnabled(Flags.ASSET_TEMPLATES)),
   );

   protected isAssetTemplatesEnabled = computed(() => {
      return (
         this.assetTemplatesLaunchFlag() &&
         this.featureFlagService.featureSet()?.has("assetTemplates")
      );
   });

   public rememberToolCheckouts(
      toolCheckoutsMap: Map<number, Array<CheckOutRequest>>,
   ): void {
      toolCheckoutsMap.forEach((toolCheckouts, assetID) => {
         this.toolCheckouts.set(assetID, toolCheckouts);
      });
   }

   public recallToolCheckouts(): Map<number, Array<CheckOutRequest>> {
      return this.toolCheckouts;
   }

   public initializeColumns(widgetDef: AssetListWidgetDef): Array<Column> {
      const ignored = [
         "autoRotate",
         "manualWidth",
         "itemsPerPage",
         "splitLineBarByCustomTag",
         "splitLineBarByCustomTagValue",
      ];
      const columns = Object.keys(widgetDef.display)
         .filter(
            (key) =>
               widgetDef.display[key] === true &&
               ignored.includes(key) === false &&
               this.getName(key, widgetDef) !== null,
         )
         .map((key) => ({
            key,
            name: this.getName(key, widgetDef),
            width: widgetDef.manualWidth?.find((item) => item.key === key)?.value,
            isCurrency: this.getCurrencyCode(key),
         }));
      return this.customColumnListHeaderService.setColumnsToUserUIPreferences(
         [{ key: "assetName", name: this.i18n().t("AssetName") }, ...columns],
         widgetDef.widgetID,
      );
   }

   public calculateAssets(widgetDef: WidgetDefinition): Array<Asset> {
      const tempAssetData = deepClone(widgetDef);
      if (tempAssetData.type === "assets" && tempAssetData.viewedAs === "listView") {
         //The properties below are hard-coded for being run through filterAssetsCustomDashboardBasedOnParams
         //so that if the assets are filtered to a specific field (eg/ 'Asset Name'),
         //only the matched asset will appear and not its children.
         //The data ABOUT the asset's children will be included or not based upon what the user chooses
         //for 'Ignore Include Children Data Setting' in addOrEditWidget and processed below
         tempAssetData.assetFieldsIgnoreIncludeChildrenSetting = true;
         tempAssetData.showChildAssetData = Boolean(
            tempAssetData.assetListDisplayChildAssets,
         );
      }

      return [
         ...this.manageFilters.filterAssetsCustomDashboardBasedOnParams(
            this.manageAsset.getAssets().filter((asset) => asset.assetDeleted === 0),
            tempAssetData,
            this.manageAsset,
            this.manageLocation,
         ),
      ];
   }

   private getTaskTypeFilter(taskType: TaskTypeView): TaskEntityType | undefined {
      switch (taskType) {
         case "completedPlannedWOs":
         case "openPlannedWorkOrders":
            return TaskEntityType.PlannedWorkOrders;
         case "completedUnplannedWOs":
         case "openUnplannedWorkOrders":
            return TaskEntityType.UnplannedWorkOrders;
         case "completedWorkRequests":
         case "openWorkRequests":
            return TaskEntityType.WorkRequests;
         case "completedPms":
         case "openPMs":
            return TaskEntityType.PlannedWorkOrders;
         default:
            return undefined;
      }
   }

   private getCompletedFilter(
      widgetDef: AssetListWidgetDef,
      taskType: TaskTypeView,
   ): RequestFilter {
      const { startDate, endDate } = this.getDateRangerFilter(widgetDef);
      const taskTypeFilter = this.getTaskTypeFilter(taskType);
      if (!taskTypeFilter) {
         throw new Error(`Invalid task type: ${taskType}`);
      }
      return {
         statuses: [TaskStatus.Closed],
         taskTypes: [taskTypeFilter],
         completedStart: startDate,
         completedEnd: endDate,
      };
   }

   private getOpenFilter(
      widgetDef: AssetListWidgetDef,
      taskType: TaskTypeView,
   ): RequestFilter {
      const { startDate, endDate } = this.getDateRangerFilter(widgetDef);
      const taskTypeFilter = this.getTaskTypeFilter(taskType);
      if (!taskTypeFilter) {
         throw new Error(`Invalid task type: ${taskType}`);
      }
      return {
         statusesNot: [TaskStatus.Closed],
         taskTypes: [taskTypeFilter],
         dueStart: startDate,
         dueEnd: endDate,
      };
   }

   private getTotalCompletedTasksFilter(widgetDef: AssetListWidgetDef): RequestFilter {
      const { startDate, endDate } = this.getDateRangerFilter(widgetDef);

      const taskTypesToDisplay = widgetDef.displayData.totalCompletedTasks;
      const taskTypesToDisplayArray: Array<string> = [];
      if (taskTypesToDisplay?.completedPlannedWOs) {
         taskTypesToDisplayArray.push(TaskEntityType.PlannedWorkOrders);
      }
      if (taskTypesToDisplay?.completedUnplannedWOs) {
         taskTypesToDisplayArray.push(TaskEntityType.UnplannedWorkOrders);
      }
      if (taskTypesToDisplay?.completedWorkRequests) {
         taskTypesToDisplayArray.push(TaskEntityType.WorkRequests);
      }
      if (taskTypesToDisplay?.completedPms) {
         taskTypesToDisplayArray.push(TaskEntityType.PlannedMaintenances);
      }

      return {
         statuses: [TaskStatus.Closed],
         taskTypes: taskTypesToDisplayArray,
         completedStart: startDate,
         completedEnd: endDate,
      };
   }

   private getTotalOpenTasksFilter(widgetDef: AssetListWidgetDef): RequestFilter {
      const { startDate, endDate } = this.getDateRangerFilter(widgetDef);
      const taskTypesToDisplay = widgetDef.displayData.totalOpenTasks;
      const taskTypesToDisplayArray: Array<string> = [];
      if (taskTypesToDisplay?.openPlannedWOs) {
         taskTypesToDisplayArray.push(TaskEntityType.PlannedWorkOrders);
      }
      if (taskTypesToDisplay?.openUnplannedWOs) {
         taskTypesToDisplayArray.push(TaskEntityType.UnplannedWorkOrders);
      }
      if (taskTypesToDisplay?.openPms) {
         taskTypesToDisplayArray.push("plannedMaintenances");
      }
      if (taskTypesToDisplay?.openWorkRequests) {
         taskTypesToDisplayArray.push("workRequests");
      }
      return {
         statusesNot: [TaskStatus.Closed],
         taskTypes: taskTypesToDisplayArray,
         dueStart: startDate,
         dueEnd: endDate,
      };
   }

   private getTotalTasksFilter(widgetDef: AssetListWidgetDef): RequestFilter {
      return {
         widgetID: widgetDef.widgetID,
      };
   }

   public getTasksFilter(
      widgetDef: AssetListWidgetDef,
      taskType: TaskTypeView,
   ): RequestFilter {
      switch (taskType) {
         case "completedPlannedWOs":
         case "completedUnplannedWOs":
         case "completedWorkRequests":
         case "completedPms":
            return this.getCompletedFilter(widgetDef, taskType);
         case "openPlannedWorkOrders":
         case "openUnplannedWorkOrders":
         case "openPMs":
         case "openWorkRequests":
            return this.getOpenFilter(widgetDef, taskType);
         case "totalCompletedTasks":
            return this.getTotalCompletedTasksFilter(widgetDef);
         case "totalOpenTasks":
            return this.getTotalOpenTasksFilter(widgetDef);
         case "totalTasks":
            return this.getTotalTasksFilter(widgetDef);
         default:
            return {};
      }
   }

   public getDateRangerFilter(widgetDef: AssetListWidgetDef): RequestFilter {
      const dateString = widgetDef.dateStr;
      if (dateString === "All Time") {
         return {};
      }

      //figure out date range via moment
      const temp = this.manageDashboard.findMomentStartBasedOnDateStr(widgetDef);
      const start = temp.start;
      const end = temp.end;

      return {
         startDate: Number(start.format("X")),
         endDate: Number(end.format("X")),
      };
   }

   public getExtraInfo(
      assets: Array<Asset & { currencyCode?: string }>,
      widgetDef: AssetListWidgetDef,
   ): LimbleMap<number, AssetsExtraInfo> {
      const assetsExtraInfo = new LimbleMap<number, AssetsExtraInfo>();
      if (assets.length === 0) return assetsExtraInfo;
      const tempTaskData = {
         ...deepClone(widgetDef),
         type: "tasks",
      };
      // get completed tasks if asset is displaying completed task or maintenance performance data
      let completedTasks: TaskLookup = new Lookup("checklistID");
      if (this.needsCompletedWOs(widgetDef)) {
         completedTasks = this.manageTask.getCompletedTasks("AssetListService");
         tempTaskData.tasks.dueVsCompletedForTasks = "completed";
         completedTasks = this.manageFilters.dashboardTaskFilterToDateRange(
            completedTasks,
            {
               params: tempTaskData,
            },
            this.manageDashboard,
            this.alertService,
         );
      }
      // get open tasks if asset is displaying open task data
      let openTasks: TaskLookup = new Lookup("checklistID");
      if (this.hasOpenWOsDisplayed(widgetDef)) {
         tempTaskData.tasks.dueVsCompletedForTasks = "Created";
         openTasks = this.manageTask.getTasks();
         openTasks = this.manageFilters.dashboardTaskFilterToDateRange(
            openTasks,
            {
               params: tempTaskData,
            },
            this.manageDashboard,
            this.alertService,
         );
      }
      const globalLookupKidToParentPlusSelf: any = {};
      const assetsIndex = {};
      // iterates through the assets to add the additional displayed data needed for the list to display
      for (const asset of assets) {
         assetsIndex[asset.assetID] = asset;

         globalLookupKidToParentPlusSelf[asset.assetID] = [];
         globalLookupKidToParentPlusSelf[asset.assetID].push(asset.assetID);
         let assetIDs: Array<number> = [];
         if (
            (!widgetDef.assetFieldsIgnoreIncludeChildrenSetting &&
               asset.includeChildData == 1) ||
            (widgetDef.assetFieldsIgnoreIncludeChildrenSetting == true &&
               widgetDef.showChildAssetData == true)
         ) {
            assetIDs = this.manageAsset.findChildrenIDs(asset, []);
            const kidLength = assetIDs.length;
            for (let kidIndex = 0; kidIndex < kidLength; kidIndex++) {
               if (
                  typeof globalLookupKidToParentPlusSelf[assetIDs[kidIndex]] ===
                  "undefined"
               ) {
                  globalLookupKidToParentPlusSelf[assetIDs[kidIndex]] = [];
               }
               globalLookupKidToParentPlusSelf[assetIDs[kidIndex]].push(asset.assetID);
            }
         }
         assetsExtraInfo.set(asset.assetID, this.initializeExtraInfo(asset));
      }
      if (this.needsCompletedWOs(widgetDef)) {
         for (const completedTask of completedTasks) {
            if (
               completedTask.assetID !== null &&
               globalLookupKidToParentPlusSelf[completedTask.assetID]
            ) {
               const globalLookupKidToParentPlusSelfKidsLen =
                  globalLookupKidToParentPlusSelf[completedTask.assetID].length;
               for (
                  let index2 = 0;
                  index2 < globalLookupKidToParentPlusSelfKidsLen;
                  index2++
               ) {
                  assetsExtraInfo
                     .get(globalLookupKidToParentPlusSelf[completedTask.assetID][index2])
                     ?.completedTasks.push(completedTask);
               }
            }
         }
      }
      if (this.hasOpenWOsDisplayed(widgetDef)) {
         for (const openTask of openTasks) {
            if (
               openTask.assetID !== null &&
               globalLookupKidToParentPlusSelf[openTask.assetID]
            ) {
               const globalLookupKidToParentPlusSelfKidsLen =
                  globalLookupKidToParentPlusSelf[openTask.assetID].length;
               for (
                  let index2 = 0;
                  index2 < globalLookupKidToParentPlusSelfKidsLen;
                  index2++
               ) {
                  assetsExtraInfo
                     .get(globalLookupKidToParentPlusSelf[openTask.assetID][index2])
                     ?.openTasks.push(openTask);
               }
            }
         }
      }

      const combinedFieldKeyLookup = new Map<string, AssetField>();
      for (const assetField of this.manageAsset.getFields().values()) {
         const lowerCaseFieldName = assetField.fieldName.toLocaleLowerCase();
         const combinedKey = `${lowerCaseFieldName}-${assetField.locationID}`;
         combinedFieldKeyLookup.set(combinedKey, assetField);
      }

      let combinedFieldValueKeyLookup = new Map<string, AssetFieldValue>();
      if (this.combinedFieldValueKeyLookup === undefined) {
         for (const fieldValue of this.manageAsset.getFieldValues().values()) {
            const combinedKey = `${fieldValue.fieldID}-${fieldValue.assetID}`;
            combinedFieldValueKeyLookup.set(combinedKey, fieldValue);
         }
         this.combinedFieldValueKeyLookup = combinedFieldValueKeyLookup;
         this.manageAsset
            .fieldValueChanges()
            .pipe(take(1))
            .subscribe(() => {
               this.combinedFieldValueKeyLookup = undefined;
            });
      } else {
         combinedFieldValueKeyLookup = this.combinedFieldValueKeyLookup;
      }

      let fieldValueLookup: Map<number, AssetFieldValue[]> | undefined = undefined;
      if (this.isAssetTemplatesEnabled()) {
         /**
          * Hash map to store field values for each asset
          * This is used to quickly find if a field is standardized or not
          */
         fieldValueLookup = new Map<number, AssetFieldValue[]>();
         for (const fieldValue of this.manageAsset.getFieldValues().values()) {
            const key = fieldValue.assetID;
            if (fieldValueLookup.has(key)) {
               fieldValueLookup.get(key)?.push(fieldValue);
            } else {
               fieldValueLookup.set(key, [fieldValue]);
            }
         }
      }

      for (const asset of assets) {
         const extraInfo = assetsExtraInfo.strictGet(asset.assetID);

         extraInfo.currencyCode = asset.currencyCode ?? DEFAULT_CURRENCY;
         // organize completed Task data if asset list is displaying any completed Task information
         if (this.hasCompletedWOsDisplayed(widgetDef)) {
            this.organizeCompleteTaskData(extraInfo, widgetDef);
         }
         // organize open Task data if asset list is displaying any open Task information
         if (this.hasOpenWOsDisplayed(widgetDef)) {
            this.organizeOpenTaskData(extraInfo, widgetDef);
         }
         // organize total Tasks data if it is being displayed in the asset list
         if (widgetDef.display.totalTasks) {
            this.organizeTotalTasks(extraInfo, widgetDef);
         }
         // organize maintenance performance data if it is being displayed in the asset list
         if (this.hasMaintPerfDisplayed(widgetDef)) {
            this.organizeMaintPerfData(extraInfo, widgetDef);
         }
         // organize cost data if it is being displayed in the asset list
         if (this.hasCostsDisplayed(widgetDef)) {
            this.organizeCostData(extraInfo);
         }
         if (
            this.hasDepreciationScheduleDisplayed(widgetDef) &&
            asset.assetDepreciationID !== undefined
         ) {
            this.organizeDepreciationData(asset.assetDepreciationID, extraInfo);
         }
         // organize custom Field data if it is being displayed in the asset list
         if (
            widgetDef.display.customField1 ||
            widgetDef.display.customField2 ||
            widgetDef.display.customField3 ||
            widgetDef.display.customField4 ||
            widgetDef.display.customField5 ||
            widgetDef.display.customField6 ||
            widgetDef.display.customField7 ||
            widgetDef.display.customField8
         ) {
            this.organizeCustomField(
               extraInfo,
               combinedFieldKeyLookup,
               combinedFieldValueKeyLookup,
               widgetDef,
               fieldValueLookup,
            );
         }
         // organize cost per custom Field data if it is being displayed in the asset list
         if (widgetDef.display.costPerCustomField) {
            this.organizeCostPerCustomField(extraInfo, widgetDef);
         }
      }
      if (this.hasToolCheckOutInfoDisplayed(widgetDef)) {
         this.organizeCheckOutData(assets, assetsExtraInfo, this.recallToolCheckouts());
      }
      return assetsExtraInfo;
   }

   public getCurrencyCode(key: string): boolean {
      switch (key) {
         case "costParts":
         case "salvageValue":
         case "purchaseCost":
            return true;
         default:
            return false;
      }
   }

   public getName(key: string, widgetDef: AssetListWidgetDef): string | null {
      switch (key) {
         case "assetName":
            return this.i18n().t("AssetName");
         case "completedPlannedWOs":
            return this.i18n().t("CompletedPlannedWOs");
         case "completedUnplannedWOs":
            return this.i18n().t("CompletedUnplannedWOs");
         case "totalCompletedTasks":
            return this.i18n().t("TotalCompletedTasks");
         case "completedPms":
            return this.i18n().t("CompletedPMs");
         case "completedWorkRequests":
            return this.i18n().t("CompletedWorkRequests");
         case "openPlannedWOs":
            return this.i18n().t("OpenPlannedWOs");
         case "openUnplannedWOs":
            return this.i18n().t("OpenUnplannedWOs");
         case "totalOpenTasks":
            return this.i18n().t("TotalOpenTasks");
         case "openPms":
            return this.i18n().t("OpenPMs");
         case "openWorkRequests":
            return this.i18n().t("OpenWorkRequests");
         case "totalTasks":
            return this.i18n().t("TotalTasks");
         case "downtimeHrs":
            return `${this.i18n().t("Downtime")} ${this.i18n().t("hrs")}`;
         case "uptimeHrs":
            return `${this.i18n().t("Uptime")} ${this.i18n().t("hrs")}`;
         case "downtimePercent":
            return `${this.i18n().t("Downtime")} %`;
         case "uptimePercent":
            return `${this.i18n().t("Uptime")} %`;
         case "mtbf":
            return this.i18n().t("MTBF");
         case "mttr":
            return this.i18n().t("MTTR");
         case "assetID":
            return this.i18n().t("AssetID");
         case "assetCreatedDate":
            return this.i18n().t("StartedDate");
         case "locationName":
            return this.i18n().t("LocationName");
         case "customField1":
            if (widgetDef.displayData?.customField1?.fieldName) {
               return widgetDef.displayData.customField1.fieldName;
            }
            return `${this.i18n().t("CustomField")} 1`;
         case "customField2":
            if (widgetDef.displayData?.customField2?.fieldName) {
               return widgetDef.displayData.customField2.fieldName;
            }
            return `${this.i18n().t("CustomField")} 2`;
         case "customField3":
            if (widgetDef.displayData?.customField3?.fieldName) {
               return widgetDef.displayData.customField3.fieldName;
            }
            return `${this.i18n().t("CustomField")} 3`;
         case "customField4":
            if (widgetDef.displayData?.customField4?.fieldName) {
               return widgetDef.displayData.customField4.fieldName;
            }
            return `${this.i18n().t("CustomField")} 4`;
         case "customField5":
            if (widgetDef.displayData?.customField5?.fieldName) {
               return widgetDef.displayData.customField5.fieldName;
            }
            return `${this.i18n().t("CustomField")} 5`;
         case "customField6":
            if (widgetDef.displayData?.customField6?.fieldName) {
               return widgetDef.displayData.customField6.fieldName;
            }
            return `${this.i18n().t("CustomField")} 6`;
         case "customField7":
            if (widgetDef.displayData?.customField7?.fieldName) {
               return widgetDef.displayData.customField7.fieldName;
            }
            return `${this.i18n().t("CustomField")} 7`;
         case "customField8":
            if (widgetDef.displayData?.customField8?.fieldName) {
               return widgetDef.displayData.customField8.fieldName;
            }
            return `${this.i18n().t("CustomField")} 8`;
         case "plannedVsUnplanned":
            return this.i18n().t("PlannedVsUnplanned");
         case "timeSpent2":
            return this.i18n().t("TimeSpent");
         case "partsUsed":
            return this.i18n().t("PartsUsed");
         case "costParts":
            return this.i18n().t("PartsCost");
         case "costLabor":
            return this.i18n().t("LaborCost");
         case "costInvoices":
            return this.i18n().t("InvoiceCost");
         case "costTotal":
            return this.i18n().t("TotalCost");
         case "purchaseCost":
            return this.i18n().t("PurchaseCost");
         case "salvageValue":
            return this.i18n().t("SalvageValue");
         case "standardUsefulLife":
            return this.i18n().t("StandardUsefulLife");
         case "scheduleStartDate":
            return this.i18n().t("DepreciationScheduleStartDate");
         case "scheduleEndDate":
            return this.i18n().t("DepreciationScheduleEndDate");
         case "depreciationScheduleTaskAssignment":
            return this.i18n().t("EndOfScheduleTaskAssignment");
         case "checkOutStatus":
            return this.i18n().t("CurrentCheckOutStatus");
         case "requestedByOrCheckedOutTo":
            return this.i18n().t("RequestedByOrCheckedOutTo");
         case "approvedOrDeniedBy":
            return this.i18n().t("ApprovedOrDeniedBy");
         case "checkOutActionDate":
            return this.i18n().t("Date");
         case "costPerCustomField":
            if (widgetDef.displayData?.costPerCustomField?.fieldName) {
               return `${this.i18n().t("CostPer")} ${widgetDef.displayData.costPerCustomField.fieldName}`;
            }
            return this.i18n().t("CostPerCustomField");

         default:
      }
      return null;
   }

   public needsCompletedWOs(widgetDef: AssetListWidgetDef): boolean {
      return (
         this.hasCompletedWOsDisplayed(widgetDef) ||
         this.hasMaintPerfDisplayed(widgetDef) ||
         this.hasCostsDisplayed(widgetDef)
      );
   }

   public hasCompletedWOsDisplayed(widgetDef: AssetListWidgetDef): boolean {
      return (
         widgetDef.display.completedUnplannedWOs === true ||
         widgetDef.display.completedPlannedWOs === true ||
         widgetDef.display.completedPms === true ||
         widgetDef.display.totalCompletedTasks === true ||
         widgetDef.display.completedWorkRequests === true ||
         widgetDef.display.totalTasks === true
      );
   }

   public hasOpenWOsDisplayed(widgetDef: AssetListWidgetDef): boolean {
      return (
         widgetDef.display.openUnplannedWOs === true ||
         widgetDef.display.openPlannedWOs === true ||
         widgetDef.display.openPms === true ||
         widgetDef.display.totalOpenTasks === true ||
         widgetDef.display.openWorkRequests === true ||
         widgetDef.display.totalTasks === true
      );
   }

   public hasCostsDisplayed(widgetDef: AssetListWidgetDef): boolean {
      return (
         widgetDef.display.costParts === true ||
         widgetDef.display.costLabor === true ||
         widgetDef.display.costInvoices === true ||
         widgetDef.display.costTotal === true ||
         widgetDef.display.costPerCustomField === true
      );
   }

   public hasMaintPerfDisplayed(widgetDef: AssetListWidgetDef): boolean {
      return (
         widgetDef.display.downtimeHrs === true ||
         widgetDef.display.uptimeHrs === true ||
         widgetDef.display.downtimePercent === true ||
         widgetDef.display.uptimePercent === true ||
         widgetDef.display.mtbf === true ||
         widgetDef.display.mttr === true ||
         widgetDef.display.plannedVsUnplanned === true ||
         widgetDef.display.timeSpent2 === true ||
         widgetDef.display.partsUsed === true
      );
   }

   public hasDepreciationScheduleDisplayed(widgetDef: AssetListWidgetDef): boolean {
      return (
         widgetDef.display.purchaseCost === true ||
         widgetDef.display.salvageValue === true ||
         widgetDef.display.standardUsefulLife === true ||
         widgetDef.display.scheduleStartDate === true ||
         widgetDef.display.scheduleEndDate === true ||
         widgetDef.display.depreciationScheduleTaskAssignment === true
      );
   }

   public hasToolCheckOutInfoDisplayed(widgetDef: AssetListWidgetDef): boolean {
      return (
         widgetDef.display.checkOutStatus === true ||
         widgetDef.display.requestedByOrCheckedOutTo === true ||
         widgetDef.display.approvedOrDeniedBy === true ||
         widgetDef.display.checkOutActionDate === true
      );
   }

   public organizeCompleteTaskData(
      extraInfo: AssetsExtraInfo,
      widgetDef: AssetListWidgetDef,
   ): void {
      const completedTasksByAsset = extraInfo.completedTasks;
      if (completedTasksByAsset && completedTasksByAsset.length > 0) {
         extraInfo.completedPlannedWOsList = completedTasksByAsset.filter(
            (workOrder) => workOrder.checklistTemplateOld === 4,
         );
         extraInfo.completedPlannedWOs = extraInfo.completedPlannedWOsList.length;
         const tempCompletedUnplannedWorkOrders = completedTasksByAsset.filter(
            (workOrder) => workOrder.checklistTemplateOld === 2,
         );
         extraInfo.completedUnplannedWOsList = tempCompletedUnplannedWorkOrders.filter(
            (workOrder) =>
               !(
                  workOrder.checklistBatchID === 10000 ||
                  workOrder.checklistBatchID === 300112
               ),
         );
         extraInfo.completedUnplannedWOs = extraInfo.completedUnplannedWOsList.length;
         extraInfo.completedPmsList = completedTasksByAsset.filter(
            (workOrder) => workOrder.checklistTemplateOld === 1,
         );
         extraInfo.completedPms = extraInfo.completedPmsList.length;
         extraInfo.completedWorkRequestsList = tempCompletedUnplannedWorkOrders.filter(
            (workOrder) =>
               workOrder.checklistBatchID === 10000 ||
               workOrder.checklistBatchID === 300112,
         );
         extraInfo.completedWorkRequests = extraInfo.completedWorkRequestsList.length;
         // organize data for totalCompletedTasks
         extraInfo.totalCompletedTasks = 0;
         extraInfo.totalCompletedTasksList = [];
         if (widgetDef.display.totalCompletedTasks) {
            if (widgetDef.displayData?.totalCompletedTasks?.completedPlannedWOs) {
               extraInfo.totalCompletedTasks =
                  extraInfo.totalCompletedTasks + extraInfo.completedPlannedWOs;
               extraInfo.totalCompletedTasksList =
                  extraInfo.totalCompletedTasksList.concat(
                     extraInfo.completedPlannedWOsList,
                  );
            }
            if (widgetDef.displayData?.totalCompletedTasks?.completedUnplannedWOs) {
               extraInfo.totalCompletedTasks =
                  extraInfo.totalCompletedTasks + extraInfo.completedUnplannedWOs;
               extraInfo.totalCompletedTasksList =
                  extraInfo.totalCompletedTasksList.concat(
                     extraInfo.completedUnplannedWOsList,
                  );
            }
            if (widgetDef.displayData?.totalCompletedTasks?.completedPms) {
               extraInfo.totalCompletedTasks =
                  extraInfo.totalCompletedTasks + extraInfo.completedPms;
               extraInfo.totalCompletedTasksList =
                  extraInfo.totalCompletedTasksList.concat(extraInfo.completedPmsList);
            }
            if (widgetDef.displayData?.totalCompletedTasks?.completedWorkRequests) {
               extraInfo.totalCompletedTasks =
                  extraInfo.totalCompletedTasks + extraInfo.completedWorkRequests;
               extraInfo.totalCompletedTasksList =
                  extraInfo.totalCompletedTasksList.concat(
                     extraInfo.completedWorkRequestsList,
                  );
            }
         }
      } else {
         extraInfo.completedTasks = [];
         extraInfo.completedPlannedWOs = 0;
         extraInfo.completedUnplannedWOs = 0;
         extraInfo.totalCompletedTasks = 0;
         extraInfo.completedPms = 0;
         extraInfo.completedWorkRequests = 0;
         extraInfo.completedTasksList = [];
         extraInfo.completedPlannedWOsList = [];
         extraInfo.completedUnplannedWOsList = [];
         extraInfo.totalCompletedTasksList = [];
         extraInfo.completedPmsList = [];
         extraInfo.completedWorkRequestsList = [];
      }
   }

   public organizeOpenTaskData(
      extraInfo: AssetsExtraInfo,
      widgetDef: AssetListWidgetDef,
   ): void {
      const openTasksByAsset = extraInfo.openTasks;
      if (openTasksByAsset && openTasksByAsset.length > 0) {
         extraInfo.openPlannedWOsList = openTasksByAsset.filter(
            (workOrder) => workOrder.checklistTemplateOld === 4,
         );
         extraInfo.openPlannedWOs = extraInfo.openPlannedWOsList.length;
         const tempOpenUnplannedWorkOrders = openTasksByAsset.filter(
            (workOrder) => workOrder.checklistTemplateOld === 2,
         );
         extraInfo.openUnplannedWOsList = tempOpenUnplannedWorkOrders.filter(
            (workOrder) =>
               !(
                  workOrder.checklistBatchID === 10000 ||
                  workOrder.checklistBatchID === 300112
               ),
         );
         extraInfo.openUnplannedWOs = extraInfo.openUnplannedWOsList.length;
         extraInfo.openPmsList = openTasksByAsset.filter(
            (workOrder) => workOrder.checklistTemplateOld === 1,
         );
         extraInfo.openPms = extraInfo.openPmsList.length;
         extraInfo.openWorkRequestsList = tempOpenUnplannedWorkOrders.filter(
            (workOrder) =>
               workOrder.checklistBatchID === 10000 ||
               workOrder.checklistBatchID === 300112,
         );
         extraInfo.openWorkRequests = extraInfo.openWorkRequestsList.length;
         // organize data for totalCompletedTasks
         extraInfo.totalOpenTasks = 0;
         extraInfo.totalOpenTasksList = [];
         if (widgetDef.display.totalOpenTasks) {
            if (widgetDef.displayData?.totalOpenTasks?.openPlannedWOs) {
               extraInfo.totalOpenTasks =
                  extraInfo.totalOpenTasks + extraInfo.openPlannedWOs;
               extraInfo.totalOpenTasksList = extraInfo.totalOpenTasksList.concat(
                  extraInfo.openPlannedWOsList,
               );
            }
            if (widgetDef.displayData?.totalOpenTasks?.openUnplannedWOs) {
               extraInfo.totalOpenTasks =
                  extraInfo.totalOpenTasks + extraInfo.openUnplannedWOs;
               extraInfo.totalOpenTasksList = extraInfo.totalOpenTasksList.concat(
                  extraInfo.openUnplannedWOsList,
               );
            }
            if (widgetDef.displayData?.totalOpenTasks?.openPms) {
               extraInfo.totalOpenTasks = extraInfo.totalOpenTasks + extraInfo.openPms;
               extraInfo.totalOpenTasksList = extraInfo.totalOpenTasksList.concat(
                  extraInfo.openPmsList,
               );
            }
            if (widgetDef.displayData?.totalOpenTasks?.openWorkRequests) {
               extraInfo.totalOpenTasks =
                  extraInfo.totalOpenTasks + extraInfo.openWorkRequests;
               extraInfo.totalOpenTasksList = extraInfo.totalOpenTasksList.concat(
                  extraInfo.openWorkRequestsList,
               );
            }
         }
      } else {
         extraInfo.openTasks = [];
         extraInfo.openPlannedWOs = 0;
         extraInfo.openUnplannedWOs = 0;
         extraInfo.totalOpenTasks = 0;
         extraInfo.openPms = 0;
         extraInfo.openWorkRequests = 0;
         extraInfo.openTasksList = [];
         extraInfo.openPlannedWOsList = [];
         extraInfo.openUnplannedWOsList = [];
         extraInfo.totalOpenTasksList = [];
         extraInfo.openPmsList = [];
         extraInfo.openWorkRequestsList = [];
      }
   }

   public organizeTotalTasks(
      extraInfo: AssetsExtraInfo,
      widgetDef: AssetListWidgetDef,
   ): void {
      extraInfo.totalTasks = 0;
      extraInfo.totalTasksList = [];
      if (widgetDef.displayData?.totalTasks?.openPlannedWOs) {
         extraInfo.totalTasks = extraInfo.totalTasks + extraInfo.openPlannedWOs;
         extraInfo.totalTasksList = extraInfo.totalTasksList.concat(
            extraInfo.openPlannedWOsList,
         );
      }
      if (widgetDef.displayData?.totalTasks?.openUnplannedWOs) {
         extraInfo.totalTasks = extraInfo.totalTasks + extraInfo.openUnplannedWOs;
         extraInfo.totalTasksList = extraInfo.totalTasksList.concat(
            extraInfo.openUnplannedWOsList,
         );
      }
      if (widgetDef.displayData?.totalTasks?.openPms) {
         extraInfo.totalTasks = extraInfo.totalTasks + extraInfo.openPms;
         extraInfo.totalTasksList = extraInfo.totalTasksList.concat(
            extraInfo.openPmsList,
         );
      }
      if (widgetDef.displayData?.totalTasks?.openWorkRequests) {
         extraInfo.totalTasks = extraInfo.totalTasks + extraInfo.openWorkRequests;
         extraInfo.totalTasksList = extraInfo.totalTasksList.concat(
            extraInfo.openWorkRequestsList,
         );
      }
      if (widgetDef.displayData?.totalTasks?.completedPlannedWOs) {
         extraInfo.totalTasks = extraInfo.totalTasks + extraInfo.completedPlannedWOs;
         extraInfo.totalTasksList = extraInfo.totalTasksList.concat(
            extraInfo.completedPlannedWOsList,
         );
      }
      if (widgetDef.displayData?.totalTasks?.completedUnplannedWOs) {
         extraInfo.totalTasks = extraInfo.totalTasks + extraInfo.completedUnplannedWOs;
         extraInfo.totalTasksList = extraInfo.totalTasksList.concat(
            extraInfo.completedUnplannedWOsList,
         );
      }
      if (widgetDef.displayData?.totalTasks?.completedPms) {
         extraInfo.totalTasks = extraInfo.totalTasks + extraInfo.completedPms;
         extraInfo.totalTasksList = extraInfo.totalTasksList.concat(
            extraInfo.completedPmsList,
         );
      }
      if (widgetDef.displayData?.totalTasks?.completedWorkRequests) {
         extraInfo.totalTasks = extraInfo.totalTasks + extraInfo.completedWorkRequests;
         extraInfo.totalTasksList = extraInfo.totalTasksList.concat(
            extraInfo.completedWorkRequestsList,
         );
      }
   }

   public organizeMaintPerfData(
      extraInfo: AssetsExtraInfo,
      widgetDef: AssetListWidgetDef,
   ): void {
      let downtimeTaskCount = 0;
      const downtimeTaskList: any = [];
      let downtime = 0;
      let plannedWork = 0;
      let unplannedWork = 0;
      let timeSpent = 0;
      let parts = 0;
      const assetIDList: Set<number> = new Set();
      assetIDList.add(extraInfo.assetID);
      extraInfo.plannedVsUnplannedList = [];
      extraInfo.plannedVsUnplanned2List = [];
      extraInfo.timeSpent2List = [];
      extraInfo.partsUsedList = [];
      const completedTasksByAsset = extraInfo.completedTasks;
      const { start, end } =
         this.manageDashboard.findMomentStartBasedOnDateStr(widgetDef);
      const filterStartTime = start?.valueOf();
      const filterEndTime = end?.valueOf();

      if (
         //make sure this is an array
         typeof completedTasksByAsset === "object" &&
         completedTasksByAsset.length > 0
      ) {
         for (const completedTask of completedTasksByAsset) {
            // organize data for downtime/uptime
            if (
               Number(completedTask.checklistDowntime) &&
               !isNaN(Number(completedTask.checklistDowntime)) &&
               Number(completedTask.checklistDowntime) > 0
            ) {
               downtime = downtime + Number(completedTask.checklistDowntime);
               downtimeTaskCount++;
               downtimeTaskList.push(completedTask);
            }
            let asset: Asset | undefined;
            if (completedTask.assetID) {
               asset = this.manageAsset.getAsset(completedTask.assetID);
            }
            if (asset) {
               assetIDList.add(Number(asset.assetID));
            }
            // organize info for planned/unplanned
            if (
               completedTask.checklistTemplateOld === 1 ||
               completedTask.checklistTemplateOld === 4
            ) {
               extraInfo.plannedVsUnplannedList.push(completedTask);
               plannedWork++;
            }
            if (completedTask.checklistTemplateOld === 2) {
               extraInfo.plannedVsUnplanned2List.push(completedTask);
               unplannedWork++;
            }
            // organize info for time spent
            // because this is an asset,
            // we only want to count **logged time** of the `extraTime` which are within the timeframe defined by the widget,
            // as opposed to the **completion time** of the associated task
            const checklistPromptTimeTotal = this.manageTask.getChecklistPromptTimeTotal(
               completedTask,
               { extraTimeDateRange: { start: filterStartTime, end: filterEndTime } },
            );
            if (checklistPromptTimeTotal > 0) {
               timeSpent = timeSpent + checklistPromptTimeTotal;
               extraInfo.timeSpent2List.push(completedTask);
            }
            // organize info for total parts
            if (completedTask?.partRelationIDs.length) {
               for (const relationID of completedTask.partRelationIDs) {
                  const partRelation = this.manageTask.getPartRelation(relationID);
                  if (partRelation === undefined) continue;
                  if (Number(partRelation.usedNumber) > 0)
                     parts = parts + Number(partRelation.usedNumber);
                  extraInfo.partsUsedList.push(completedTask);
               }
            }
         }
      }
      extraInfo.plannedVsUnplanned = Number(
         ((plannedWork / (plannedWork + unplannedWork)) * 100).toFixed(2),
      );
      extraInfo.plannedVsUnplanned2 = Number(
         ((unplannedWork / (plannedWork + unplannedWork)) * 100).toFixed(2),
      );
      extraInfo.partsUsed = Number(parts.toFixed(3));
      extraInfo.timeSpent2 = Number((timeSpent / 60 / 60).toFixed(2));
      if (isNaN(extraInfo.plannedVsUnplanned)) {
         extraInfo.plannedVsUnplanned = "--";
      }
      if (isNaN(extraInfo.plannedVsUnplanned2)) {
         extraInfo.plannedVsUnplanned2 = "--";
      }
      extraInfo.downtime = downtime;
      extraInfo.downtimeTaskList = downtimeTaskList;
      extraInfo.downtimeTaskCount = downtimeTaskCount;
      let totalTimeInHrs = 0;
      if (assetIDList.size > 0) {
         for (const assetIDInList of assetIDList) {
            const asset = this.manageAsset.getAsset(assetIDInList);
            if (asset) {
               let startTime = asset.assetCreatedDate ?? 0 * 1000;
               let endTime = Date.now();
               if (filterStartTime !== undefined && filterStartTime > startTime) {
                  startTime = filterStartTime;
               }
               if (filterEndTime !== undefined && filterEndTime < endTime) {
                  endTime = filterEndTime;
               }
               const startMoment = moment(startTime).startOf("day");
               const endMoment = moment(endTime).endOf("day");
               totalTimeInHrs += this.manageAsset.calculateAssetHrsRunTime(
                  asset,
                  startMoment,
                  endMoment,
               );
            }
         }
      }
      const totalTime: any = (totalTimeInHrs * 60 * 60).toFixed(2);
      extraInfo.downtimeHrs = Number((extraInfo.downtime / 60 / 60).toFixed(2));
      extraInfo.uptime = totalTime - extraInfo.downtime;
      extraInfo.uptimeHrs = Number((extraInfo.uptime / 60 / 60).toFixed(2));
      extraInfo.downtimePercent = Number(
         ((extraInfo.downtime / totalTime) * 100).toFixed(2),
      );
      extraInfo.uptimePercent = Number(((extraInfo.uptime / totalTime) * 100).toFixed(2));
      extraInfo.mtbf = Number((totalTimeInHrs / downtimeTaskCount).toFixed(2));
      extraInfo.mttr = Number((extraInfo.downtimeHrs / downtimeTaskCount).toFixed(2));
      if (
         extraInfo.mtbf === Infinity ||
         extraInfo.mtbf === -Infinity ||
         isNaN(extraInfo.mtbf)
      ) {
         extraInfo.mtbf = 0;
      }
      if (
         extraInfo.mttr === Infinity ||
         extraInfo.mttr === -Infinity ||
         isNaN(extraInfo.mttr)
      ) {
         extraInfo.mttr = 0;
      }
      if (
         extraInfo.downtimePercent === Infinity ||
         extraInfo.downtimePercent === -Infinity ||
         isNaN(extraInfo.downtimePercent)
      ) {
         extraInfo.downtimePercent = 0;
      }
      if (
         extraInfo.uptimePercent === Infinity ||
         extraInfo.uptimePercent === -Infinity ||
         isNaN(extraInfo.uptimePercent)
      ) {
         extraInfo.uptimePercent = 0;
      }
   }

   public organizeCostData(extraInfo: AssetsExtraInfo): void {
      extraInfo.costParts = 0;
      extraInfo.costLabor = 0;
      extraInfo.costInvoices = 0;
      extraInfo.costTotal = 0;
      extraInfo.costPartsList = [];
      extraInfo.costLaborList = [];
      extraInfo.costInvoicesList = [];
      extraInfo.costTotalList = [];
      if (extraInfo.completedTasks && extraInfo.completedTasks.length > 0) {
         for (const task of extraInfo.completedTasks) {
            const {
               checklistTotalPartsCost,
               checklistTotalLaborCost,
               checklistTotalInvoiceCost,
               checklistTotalOperatingCost,
            } = this.manageTask.getCalculatedTaskInfo(task);
            if (checklistTotalPartsCost && checklistTotalPartsCost > 0) {
               extraInfo.costParts = Number(
                  (extraInfo.costParts + checklistTotalPartsCost).toFixed(2),
               );
               extraInfo.costPartsList.push(task);
            }
            if (checklistTotalLaborCost && checklistTotalLaborCost > 0) {
               extraInfo.costLabor = Number(
                  (extraInfo.costLabor + checklistTotalLaborCost).toFixed(2),
               );
               extraInfo.costLaborList.push(task);
            }
            if (checklistTotalInvoiceCost && checklistTotalInvoiceCost > 0) {
               extraInfo.costInvoices = Number(
                  (extraInfo.costInvoices + checklistTotalInvoiceCost).toFixed(2),
               );
               extraInfo.costInvoicesList.push(task);
            }
            if (checklistTotalOperatingCost && checklistTotalOperatingCost > 0) {
               extraInfo.costTotal = Number(
                  (extraInfo.costTotal + checklistTotalOperatingCost).toFixed(2),
               );
               extraInfo.costTotalList.push(task);
            }
         }
      }
   }

   public organizeCheckOutData(
      assets: Array<Asset>,
      extraInfo: LimbleMap<number, AssetsExtraInfo>,
      toolCheckOutRequests: Map<number, Array<CheckOutRequest>>,
   ): void {
      for (const asset of assets) {
         this.setAssetCheckOutData(
            asset,
            extraInfo.strictGet(asset.assetID),
            toolCheckOutRequests.get(asset.assetID),
         );
      }
   }

   public setAssetCheckOutData(
      asset: Asset,
      extraInfo: AssetsExtraInfo,
      assetCheckOutInfo: Array<CheckOutRequest> | undefined,
   ): void {
      if (assetCheckOutInfo === undefined) {
         if (asset.canCheckOutAsTool) {
            extraInfo.checkOutStatus = this.i18n().t("CheckedIn");
         }
         return;
      }
      const mostRecentCheckOutRequest = assetCheckOutInfo[0];
      const checkOutStatus = this.manageTool.determineToolStatus(
         mostRecentCheckOutRequest,
      );
      const approvalUserDisplay = this.manageUser.getUserOrProfileDisplay(
         mostRecentCheckOutRequest.requestSentToUserID,
         mostRecentCheckOutRequest.requestSentToProfileID,
      );
      const timezoneIndex = this.manageLocation.getTimezonesIndex();
      const locTimezone =
         this.manageLocation.getLocation(asset.locationID)?.timezoneID ?? undefined;
      let timezone = undefined;
      if (locTimezone !== undefined) {
         timezone = timezoneIndex[locTimezone].timezoneForDate;
      }
      switch (checkOutStatus) {
         case "requestPending":
            extraInfo.checkOutStatus = this.i18n().t("PendingApproval");

            extraInfo.requestedByOrCheckedOutTo = this.manageUser.getUserFullName(
               Number(mostRecentCheckOutRequest.requestedByUserID),
            );
            extraInfo.approvedOrDeniedBy = approvalUserDisplay;
            extraInfo.checkOutActionDate = this.betterDate.formatBetterDate(
               mostRecentCheckOutRequest.requestDate,
               "dateTime",
               timezone,
            );
            break;
         case "checkedOut":
            extraInfo.checkOutStatus = this.i18n().t("CheckedOut");
            extraInfo.requestedByOrCheckedOutTo = this.manageUser.getUserFullName(
               Number(mostRecentCheckOutRequest.requestedByUserID),
            );
            if (mostRecentCheckOutRequest.requestDecisionDate) {
               extraInfo.checkOutActionDate = this.betterDate.formatBetterDate(
                  mostRecentCheckOutRequest.requestDecisionDate,
                  "dateTime",
                  timezone,
               );
               extraInfo.approvedOrDeniedBy = this.manageUser.getUserFullName(
                  Number(mostRecentCheckOutRequest.requestApprovalUserID),
               );
            } else {
               extraInfo.checkOutActionDate = this.betterDate.formatBetterDate(
                  mostRecentCheckOutRequest.requestDate,
                  "dateTime",
                  timezone,
               );
               extraInfo.approvedOrDeniedBy = this.i18n().t("BypassedApproval");
            }
            break;
         case "checkedIn":
            extraInfo.checkOutStatus = this.i18n().t("CheckedIn");
            extraInfo.requestedByOrCheckedOutTo = "";
            extraInfo.approvedOrDeniedBy = "";
            extraInfo.checkOutActionDate = "";
            break;
         default:
            this.alertService.addAlert(this.i18n().t("errorMsg"), "danger", 6000);
            throw new Error("Problem in setAssetCheckOutData, hit default case");
      }
   }

   public organizeDepreciationData(scheduleID: number, extraInfo: AssetsExtraInfo): void {
      const schedule = this.manageAsset.getDepreciationSchedule(scheduleID);
      if (!schedule) {
         return;
      }
      if (!schedule.depreciationStartDate) {
         schedule.depreciationStartDate = 0;
      }
      if (this.isMultiCurrencyEnabled()) {
         extraInfo.purchaseCost = schedule.purchaseCost?.toString() ?? "0";
         extraInfo.salvageValue = schedule.salvageValue?.toString() ?? "0";
      } else {
         extraInfo.purchaseCost = betterCurrency(
            schedule.purchaseCost,
            this.manageLang,
            this.manageUser,
         );
         extraInfo.salvageValue = betterCurrency(
            schedule.salvageValue,
            this.manageLang,
            this.manageUser,
         );
      }

      extraInfo.standardUsefulLife = `${schedule.standardUsefulLife} ${this.i18n().t("Months")}`;
      extraInfo.scheduleStartDate = this.betterDate.formatBetterDate(
         moment(schedule.depreciationStartDate * 1000).toDate(),
         "date",
      );
      extraInfo.scheduleEndDate = this.betterDate.formatBetterDate(
         moment(
            this.manageDepreciationSchedule.getEndOfDepreciationScheduleTimestamp(
               schedule,
            ) * 1000,
         ).toDate(),
         "date",
      );
      extraInfo.depreciationScheduleTaskAssignment =
         this.manageDepreciationSchedule.getUserOrProfileDisplayForDepreciation(
            Number(schedule.profileIDForTaskAssignment),
            Number(schedule.userIDForTaskAssignment),
         );
   }

   public organizeCustomField(
      extraInfo: AssetsExtraInfo,
      combinedFieldKeyLookup: Map<string, AssetField>,
      combinedFieldValueKeyLookup: Map<string, AssetFieldValue>,
      widgetDef: AssetListWidgetDef,
      fieldValueLookup?: Map<number, AssetFieldValue[]>,
   ): void {
      const numberOfCustomFields = 8;
      for (let index = 1; index <= numberOfCustomFields; index++) {
         const key = `customField${index}`;
         if (widgetDef.display[key] && widgetDef.displayData[key]) {
            this.setCustomFieldData(
               key as `customField${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8}`,
               extraInfo,
               widgetDef,
               combinedFieldKeyLookup,
               combinedFieldValueKeyLookup,
               fieldValueLookup,
            );
         }
      }
   }

   /**
    * Get fields that match the custom field key
    * and determine if they are standardized or not.
    *
    * This is necessary because standardized fields have a location
    * of 0, so the other way of looking up fields by name and asset location
    * doesn't work for these.
    */
   private assetFieldIsStandardized(
      asset: Asset | undefined,
      customFieldName: string,
      fieldValueLookup?: Map<number, AssetFieldValue[]>,
   ): boolean {
      let result = false;
      if (!asset || !fieldValueLookup) {
         return result;
      }

      /**
       * Find the field value for the custom field key
       */
      const fieldValue = fieldValueLookup?.get(asset.assetID)?.find((assetFieldValue) => {
         const assetField = this.manageAsset.getField(assetFieldValue.fieldID);
         if (!assetField) {
            return false;
         }
         return assetField.fieldName.toLocaleLowerCase() === customFieldName;
      });

      if (!fieldValue) {
         return result;
      }

      /**
       * Check if the field is standardized
       */
      const field = this.manageAsset.getField(fieldValue.fieldID);
      if (field && field.scopeType === "standardized") {
         result = true;
      }

      return result;
   }

   public setCustomFieldData(
      key: `customField${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8}`,
      extraInfo: AssetsExtraInfo,
      widgetDef: AssetListWidgetDef,
      combinedFieldKeyLookup?: Map<string, AssetField>,
      combinedFieldValueKeyLookup?: Map<string, AssetFieldValue>,
      fieldValueLookup?: Map<number, AssetFieldValue[]>,
   ): void {
      const displayData = widgetDef.displayData;
      const assetID = extraInfo.assetID;
      const asset = this.manageAsset.getAsset(assetID);

      // There can be multiple fields with the same name for a customer (potentially one per location)
      // So we have to make sure to get the corresponding field at the asset's location
      let field: AssetField | undefined;
      if (combinedFieldKeyLookup) {
         const localFieldLookupKey = `${displayData[key]?.fieldName?.toLowerCase()}-${
            asset?.locationID
         }`;
         field = combinedFieldKeyLookup.get(localFieldLookupKey);

         /**
          * Get global field if the field is standardized
          */
         if (
            this.isAssetTemplatesEnabled() &&
            this.assetFieldIsStandardized(
               asset,
               displayData[key]?.fieldName?.toLowerCase(),
               fieldValueLookup,
            )
         ) {
            const globalFieldLookupKey = `${displayData[key]?.fieldName?.toLowerCase()}-0`;
            field = combinedFieldKeyLookup.get(globalFieldLookupKey);
         }
      } else {
         field = Array.from(this.manageAsset.getFields()).find((assetField) => {
            return (
               assetField.fieldName.toLocaleLowerCase() ===
                  displayData[key]?.fieldName?.toLowerCase() &&
               assetField.locationID === asset?.locationID
            );
         });
      }

      if (!field) {
         return;
      }

      let value: AssetFieldValue | undefined;
      if (combinedFieldValueKeyLookup) {
         const combinedKey = `${field.fieldID}-${assetID}`;
         value = combinedFieldValueKeyLookup.get(combinedKey);

         /* For some reason, there might be duplicates of fieldName causing the value to be undefined,
            so we try to find it this way. */
         if (!value) {
            if (asset?.assetValueIDs && asset.assetValueIDs?.length > 0) {
               for (const valueID of asset?.assetValueIDs) {
                  const currentValue = this.manageAsset.getFieldValue(valueID);
                  if (!currentValue) continue;

                  const currentField = this.manageAsset.getField(currentValue.fieldID);
                  if (!currentField) continue;

                  if (
                     currentField.fieldName.toLocaleLowerCase() ===
                        displayData[key]?.fieldName?.toLowerCase() &&
                     currentField.locationID === asset?.locationID
                  ) {
                     field = currentField;
                     value = currentValue;
                     break;
                  }
               }
            }
         }
      } else {
         value = Array.from(this.manageAsset.getFieldValues()).find(
            (fieldValue) =>
               fieldValue.fieldID === field?.fieldID && fieldValue.assetID === assetID,
         );
      }
      if (!value) {
         return;
      }
      let formattedValueForExport: any = value.valueContent;
      if (field.fieldTypeID === 2 && value.valueContent) {
         formattedValueForExport = this.formatFieldValueDate(value);
      }
      if ((field.fieldTypeID === 5 || field.fieldTypeID === 6) && value.valueContent) {
         value.valueContent = Number(value.valueContent);
      }
      const files = Array.from(
         this.manageAsset
            .getFieldValueFiles()
            .filter((valueFile) => valueFile.valueID === value?.valueID),
      );
      let fileNamesStr = "";
      if (files.length) {
         const fileNames = files.map((file) => file.fileName);
         fileNamesStr = fileNames.toString();
      }
      extraInfo[`${key}Data`] = {
         ...field,
         ...value,
         files,
         fileNamesStr,
         formattedValueForExport: formattedValueForExport,
      };
      extraInfo[key] = value.valueContent;
   }

   public formatFieldValueDate(fieldValue: AssetFieldValue): string {
      return this.betterDate.formatBetterDate(String(fieldValue.valueContent), "date");
   }

   public organizeCostPerCustomField(
      extraInfo: AssetsExtraInfo,
      widgetDef: AssetListWidgetDef,
   ): void {
      if (
         widgetDef.display.costPerCustomField &&
         widgetDef.displayData.costPerCustomField
      ) {
         this.setCostPerCustomFieldData(extraInfo, widgetDef);
      }
   }

   public setCostPerCustomFieldData(
      extraInfo: AssetsExtraInfo,
      widgetDef: AssetListWidgetDef,
   ): void {
      const asset = this.manageAsset.getAsset(extraInfo.assetID);
      const displayData = widgetDef.displayData;
      if (extraInfo.costTotal) {
         const field = Array.from(this.manageAsset.getFields()).find(
            (assetField) =>
               assetField?.fieldName &&
               displayData.costPerCustomField.fieldName &&
               assetField.fieldName.toLowerCase() ===
                  displayData.costPerCustomField.fieldName.toLowerCase() &&
               assetField.locationID === asset?.locationID,
         );
         if (!field) {
            return;
         }
         const value = Array.from(this.manageAsset.getFieldValues()).find(
            (fieldValue) =>
               fieldValue.fieldID === field.fieldID &&
               fieldValue.assetID === extraInfo.assetID,
         );
         if (!value) {
            return;
         }
         extraInfo.costPerCustomField = (
            extraInfo.costTotal / Number(value.valueContent)
         ).toFixed(2);
      }
   }

   public initializeExtraInfo(asset: Asset): AssetsExtraInfo {
      return {
         assetID: asset.assetID,
         assetName: asset.assetName ?? "",
         assetCreatedDate: asset.assetCreatedDate ?? 0,
         locationID: asset.locationID,
         locationName:
            this.manageLocation.getLocation(asset.locationID)?.locationName ?? "",
         openTasksList: [],
         openTasks: [],
         completedTasksList: [],
         completedTasks: [],
         completedPlannedWOsList: [],
         completedPlannedWOs: 0,
         completedUnplannedWOsList: [],
         completedUnplannedWOs: 0,
         completedPmsList: [],
         completedPms: 0,
         completedWorkRequestsList: [],
         completedWorkRequests: 0,
         totalCompletedTasksList: [],
         totalCompletedTasks: 0,
         openPlannedWOsList: [],
         openPlannedWOs: 0,
         openUnplannedWOsList: [],
         openUnplannedWOs: 0,
         openPmsList: [],
         openPms: 0,
         openWorkRequestsList: [],
         openWorkRequests: 0,
         totalOpenTasksList: [],
         totalOpenTasks: 0,
         totalTasksList: [],
         totalTasks: 0,
         plannedVsUnplannedList: [],
         plannedVsUnplanned: 0,
         plannedVsUnplanned2List: [],
         plannedVsUnplanned2: 0,
         timeSpent2List: [],
         partsUsedList: [],
         partsUsed: 0,
         timeSpent2: 0,
         downtimeTaskList: [],
         downtimeTaskCount: 0,
         downtime: 0,
         downtimeHrs: 0,
         uptime: 0,
         uptimeHrs: 0,
         downtimePercent: 0,
         uptimePercent: 0,
         mtbf: 0,
         mttr: 0,
         costPartsList: [],
         costParts: 0,
         costLaborList: [],
         costLabor: 0,
         costInvoicesList: [],
         costInvoices: 0,
         costTotalList: [],
         costTotal: 0,
         purchaseCost: "",
         salvageValue: "",
         standardUsefulLife: "",
         scheduleStartDate: "",
         scheduleEndDate: "",
         depreciationScheduleTaskAssignment: "",
         costPerCustomField: "",
         checkOutStatus: "",
         requestedByOrCheckedOutTo: "",
         approvedOrDeniedBy: "",
         checkOutActionDate: "",
         currencyCode: DEFAULT_CURRENCY,
      };
   }

   public extractValueFromField(data: FieldData | undefined): string {
      if (data === undefined) {
         return "";
      }
      if (data.fieldTypeID === 3 || data.fieldTypeID === 4) {
         return data.files.map((file) => file.fileName?.slice(5)).join(", ");
      }
      if (data.fieldTypeID === 2 && data.valueContent) {
         return this.betterDate.formatBetterDate(data.valueContent, "date");
      }
      if (data.fieldTypeID === 6) {
         return betterCurrency(data.valueContent, this.manageLang, this.manageUser);
      }
      return String(data.valueContent ?? "");
   }
}
