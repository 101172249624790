import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ManageTags {
   private readonly tagSubject = new BehaviorSubject<Array<any>>([]);
   private readonly redirectWRTagsSelectedLocationSubject = new BehaviorSubject<any>(
      null,
   );

   public get tags$() {
      return this.tagSubject.asObservable();
   }

   public set tags$(tags: any) {
      this.tagSubject.next(tags);
   }

   public get redirectWRTagsSelectedLocation$() {
      return this.redirectWRTagsSelectedLocationSubject.asObservable();
   }

   public set redirectWRTagsSelectedLocation$(selectedLocation: any) {
      this.redirectWRTagsSelectedLocationSubject.next(selectedLocation);
   }
}
