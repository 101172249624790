import { NgClass } from "@angular/common";
import type { OnInit } from "@angular/core";
import { inject, Component, computed, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   RadioButtonComponent,
   SecondaryButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import { CommentFiles } from "src/app/files/components/commentFiles/commentFiles.element.component";
import { ManageLang } from "src/app/languages/services/manageLang";
import {
   PartsFacadeService,
   type AddPartModalData,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { CostViewerComponent } from "src/app/purchasing/currency/components/cost-viewer-component/cost-viewer-component";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { ManageUser } from "src/app/users/services/manageUser";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Component({
   selector: "request-purchase",
   templateUrl: "./requestPurchase.modal.component.html",
   styleUrls: ["./requestPurchase.modal.component.scss"],
   imports: [
      TooltipDirective,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      LimbleHtmlDirective,
      RadioButtonComponent,
      IconComponent,
      FormsModule,
      SecondaryButtonComponent,
      NgClass,
      ContenteditableDirective,
      MinimalIconButtonComponent,
      CommentFiles,
      BasicModalFooterComponent,
      BetterCurrencyPipe,
      PartUnitOfMeasurePipe,
      CostViewerComponent,
   ],
})
export class RequestPurchase implements OnInit {
   protected readonly currentLocationID = signal<number | undefined>(undefined);
   public title;
   public currencySymbol;
   public customerID;
   public purchaseParts;
   public purchaseService;
   public purchaseOther;
   public checklistID: number | undefined;
   public files;
   public parts;
   public service;
   public otherArr;
   public vendor;
   public vendor2;
   public reason;
   public totalOtherCost;
   public uploadObject;
   public userID;
   public serviceError;
   public reasonError;
   public serviceCostErrorQty;
   public serviceCostErrorPrice;
   public serviceQty;
   public servicePrice;
   public otherQty;
   public otherPrice;
   public data;
   public modalInstance;
   public resolve;

   private readonly modalService = inject(ModalService);
   private readonly manageVendor = inject(ManageVendor);
   private readonly manageUser = inject(ManageUser);
   private readonly alertService = inject(AlertService);
   private readonly managePO = inject(ManagePO);
   private readonly paramsService = inject(ParamsService);
   private readonly partsFacadeService = inject(PartsFacadeService);
   private readonly manageLang = inject(ManageLang);
   private readonly manageParts = inject(ManageParts);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.data = this.resolve.data;
      this.title = this.lang().RequestPurchase;
      this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;

      this.purchaseParts = false;
      this.purchaseService = false;
      this.purchaseOther = false;

      this.checklistID = this.data.checklistID;
      this.currentLocationID.set(this.data.locationID);

      this.files = [];

      this.parts = [];
      this.service = "";
      this.otherArr = [
         {
            other: "",
            otherPrice: "",
            otherQty: "",
            otherError: false,
            otherCostErrorQty: false,
            otherCostErrorPrice: false,
         },
      ];
      this.vendor = false;
      this.vendor2 = false;
      this.reason = "";

      this.totalOtherCost = 0;

      this.resetError();

      // file upload data
      this.uploadObject = {};
      this.uploadObject.deleteData = {};
      this.uploadObject.primaryID = "fileName";
      this.uploadObject.viewOnly = false;

      this.uploadObject.posturl = `phpscripts/managePO.php?action=makeTempFileForComment`;

      this.uploadObject.deleteCall = async (result) => {
         return this.managePO.deleteTempFileFromAddComment(result.fileName);
      };

      this.uploadObject.deleteSuccess = (answer) => {
         if (answer.data.success === true) {
            for (const [index, file] of this.files.entries()) {
               if (file.fileName === answer.data.fileName) {
                  this.files.splice(index, 1);
               }
            }
            this.files = [...this.files];
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         }
      };

      this.uploadObject.uploadComplete = (result) => {
         if (result.failed) {
            $("#status").html(`<font color='red'>${this.lang().UploadFailed}</font>`);
         } else {
            $("#status").html(
               `<font color='green'>${this.lang().UploadHasCompleted}</font>`,
            );
         }

         if (result.fileID !== 0) {
            const regex = /(?:\.([^.]+))?$/;
            const regexResult = regex.exec(result.row.fileName);
            if (regexResult === null) {
               result.row.fileExt = undefined;
            } else {
               result.row.fileExt = regexResult[1].toLowerCase();
            }
            if (this.userID === undefined) {
               this.userID = result.userID;
            }
            result.row.getURL = `viewFile.php?f=upload-${this.customerID}/temp/${result.fileName}`;
            this.files = [...this.files, result.row];
         }
      };
   }

   addItemToOther = () => {
      this.otherArr.push({
         other: "",
         otherPrice: "",
         otherQty: "",
         otherError: false,
         otherCostErrorQty: false,
         otherCostErrorPrice: false,
      });
   };

   removeOtherItem = (idx) => {
      this.otherArr.splice(idx, 1);
      this.changeTotal();
   };

   changeTotal = () => {
      this.totalOtherCost = 0;
      for (const item of this.otherArr) {
         this.totalOtherCost += item.otherQty * item.otherPrice;
      }
   };

   resetError = () => {
      this.serviceError = false;
      this.reasonError = false;
      this.serviceCostErrorQty = false;
      this.serviceCostErrorPrice = false;

      for (const item of this.otherArr) {
         item.otherError = false;
         item.otherCostErrorQty = false;
         item.otherCostErrorPrice = false;
      }
   };

   public async addPart(): Promise<void> {
      if (!this.checklistID) {
         return;
      }

      const modalData: AddPartModalData = {
         title: this.lang().AddPartTitle,
         message: this.lang().AddPartMsg,
         buttonText: this.lang().Select,
         taskID: this.checklistID,
         locationID: this.data.locationID,
      };

      const parts = await this.partsFacadeService.openAddPartModal(modalData);

      if (parts && parts.length > 0) {
         for (const part of parts) {
            let alreadyExists = false;
            for (const key in this.parts) {
               if (this.parts[key].partID === part.partID) {
                  alreadyExists = true;
               }
            }
            if (!alreadyExists) {
               part.tempNumber = 1;
               this.parts.push(part);
            }
         }
      }
   }

   pickVendor = (vendorToChange) => {
      const instance = this.modalService.open(PickVendors);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().PickVendor,
            data: {
               singleLocation: 0,
               selectOne: true,
               restrictToCred: false,
               iDontKnowOption: true,
            },
         },
      };

      instance.result.then((vendorIDArray) => {
         const vendor = this.manageVendor.getVendor(vendorIDArray[0]);
         assert(vendor);
         if (vendorToChange === 1) {
            if (vendorIDArray === "unsure") {
               this.vendor = false;
            } else {
               this.vendor = vendor;
            }
         } else {
            if (vendorIDArray === "unsure") {
               this.vendor2 = false;
            } else {
               this.vendor2 = vendor;
            }
         }
      });
   };

   validateNum = (num) => {
      let numCopy = num;
      if (numCopy < 0) {
         numCopy = 0;
      }
      return numCopy;
   };

   removePart = (part) => {
      for (const key in this.parts) {
         if (part.partID === this.parts[key].partID) {
            this.parts.splice(key, 1);
         }
      }
   };

   checkIfFirstPartsClick = () => {
      if (this.parts.length === 0) {
         this.addPart();
      }
   };

   submit = () => {
      this.resetError();
      if (this.purchaseParts && this.parts.length === 0) {
         this.alertService.addAlert(this.lang().PleaseSelectAPart, "warning", 6000);
         return;
      }

      if (this.purchaseService && this.service.length === 0) {
         this.alertService.addAlert(
            this.lang().PleaseDescribeTheService,
            "warning",
            6000,
         );
         this.serviceError = true;
         return;
      }
      if (
         this.purchaseService &&
         (this.serviceQty === undefined || this.serviceQty === 0)
      ) {
         this.alertService.addAlert(
            this.lang().PleaseEnterACostForTheService,
            "warning",
            6000,
         );
         this.serviceCostErrorQty = true;
         return;
      }
      if (this.purchaseService && this.servicePrice === undefined) {
         this.alertService.addAlert(
            this.lang().PleaseEnterACostForTheService,
            "warning",
            6000,
         );
         this.serviceCostErrorPrice = true;
         return;
      }

      if (this.purchaseOther) {
         for (const item of this.otherArr) {
            if (item.other.length === 0) {
               this.alertService.addAlert(
                  this.lang().PleaseDescribeTheOtherPurchase,
                  "warning",
                  6000,
               );
               item.otherError = true;
               return;
            }

            if (item.otherQty === undefined || item.otherQty === 0) {
               this.alertService.addAlert(
                  this.lang().PleaseDescribeTheOtherPurchase,
                  "warning",
                  6000,
               );
               item.otherCostErrorQty = true;
               return;
            }

            if (item.otherPrice === undefined || item.otherPrice === "") {
               this.alertService.addAlert(
                  this.lang().PleaseDescribeTheOtherPurchase,
                  "warning",
                  6000,
               );
               item.otherCostErrorPrice = true;
               return;
            }
         }
      }

      if (
         this.purchaseParts === false &&
         this.purchaseService === false &&
         this.purchaseOther === false
      ) {
         this.alertService.addAlert(
            this.lang().PleasePickSomethingToRequest,
            "warning",
            6000,
         );
         return;
      }

      if (this.reason.length === 0) {
         this.alertService.addAlert(
            this.lang().PleaseGiveUsAReasonForThePurchase,
            "warning",
            6000,
         );
         this.reasonError = true;
         return;
      }

      if (this.purchaseOther) {
         //
      } else {
         this.otherArr = []; // since purchaseOther isn't picked we need to not add an other purchase onto the request
      }

      const obj: any = {};
      obj.parts = this.parts;
      obj.service = this.service;
      if (this.vendor) {
         obj.vendorID = this.vendor.vendorID;
      } else {
         obj.vendorID = 0;
      }
      obj.serviceQty = this.serviceQty;
      obj.servicePrice = this.servicePrice;
      obj.otherArr = this.otherArr;
      obj.otherQty = this.otherQty;
      obj.otherPrice = this.otherPrice;
      if (this.vendor2) {
         obj.vendor2ID = this.vendor2.vendorID;
      } else {
         obj.vendor2ID = 0;
      }
      obj.reason = this.reason;
      obj.filesUploaded = this.files;

      this.modalInstance.close(obj);
   };

   close = () => {
      this.modalInstance.close(false);
   };
}
