import { NgClass, NgTemplateOutlet } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import {
   inject,
   Component,
   EventEmitter,
   Input,
   Output,
   computed,
   input,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BadgeComponent,
   CheckboxComponent,
   DatePickerInputComponent,
   IconButtonComponent,
   IconComponent,
   ModalService,
   LoadingAnimationComponent,
   PopoverDirective,
   ToggleComponent,
   TooltipDirective,
   UpsellPopover,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import moment from "moment";
import { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageSchedule } from "src/app/schedules/manageSchedule";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { ReoccurOptionsComponent } from "src/app/schedules/recurrences/pickReoccurModal/pickReoccur/reoccur-options.component";
import { PickReoccur } from "src/app/schedules/recurrences/pickReoccurModal/pickReoccur.modal.component";
import { RecurrenceService } from "src/app/schedules/recurrences/recurrence/recurrence.service";
import { SeasonsService } from "src/app/schedules/seasons/seasons.service";
import { SeasonsSelect } from "src/app/schedules/seasons/seasonsSelectModal/seasonsSelect.modal.component";
import { TemporalUtilsService } from "src/app/schedules/temporal-utils/temporal-utils.service";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { AddDateEndingPipe } from "src/app/shared/pipes/addDateEnding.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { CapitalizePipe } from "src/app/shared/pipes/capitalize.pipe";
import { FirstSecondEtcToMultiLanguagePipe } from "src/app/shared/pipes/firstSecondEtcToMultiLanguage.pipe";
import { FullDayPipe } from "src/app/shared/pipes/fullDay.pipe";
import { FullMonthPipe } from "src/app/shared/pipes/fullMonth.pipe";
import { MonthNumericToStringPipe } from "src/app/shared/pipes/monthNumericToString.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ParamsService } from "src/app/shared/services/params.service";
import type { SeasonType } from "src/app/shared/types/general.types";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesFacadeService } from "src/app/tasks/components/shared/services/task-templates-facade/task-templates-facade.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";

@Component({
   selector: "recurrence",
   templateUrl: "./recurrence.element.component.html",
   styleUrls: ["./recurrence.element.component.scss"],
   imports: [
      BadgeComponent,
      NgClass,
      TooltipDirective,
      CheckboxComponent,
      IconComponent,
      FormsModule,
      AlertComponent,
      PopoverDirective,
      UpsellPopover,
      BetterDatePipe,
      DatePickerInputComponent,
      ToggleComponent,
      NgTemplateOutlet,
      IconButtonComponent,
      AddDateEndingPipe,
      FirstSecondEtcToMultiLanguagePipe,
      FullDayPipe,
      CapitalizePipe,
      MonthNumericToStringPipe,
      FullMonthPipe,
      LoadingAnimationComponent,
      ReoccurOptionsComponent,
   ],
   providers: [TaskTemplatesFacadeService],
})
export class RecurrenceComponent implements OnInit, OnDestroy {
   @Input() taskTemplate!: Task | TaskTemplateEntity | undefined;
   @Input() recurrence!: Recurrence;
   @Input() isJitTemplatesEnabled: boolean = false;
   @Input({ required: true }) canToggleRecurrences: boolean = false;
   @Output() readonly recurrenceDeleted = new EventEmitter<number>();
   @Output() readonly recurrenceUpdated: EventEmitter<Recurrence> = new EventEmitter();
   public useModalPopup = input<boolean>(true);
   protected editing: boolean = false;
   protected loading: boolean = true;
   protected selectedHolidaysStr: string = "";
   protected selectedSeasonsStr: string = "";
   private originalSkipEveryXVal: number | null = null;
   protected featureConditionalScheduling: boolean = false;
   protected seasonRelations: Array<any> = [];
   protected showAdvancedSettings: boolean = false;
   protected fieldName: string | undefined = undefined;
   protected recurrenceHasActiveSchedules: boolean = false;
   protected reoccurSpawnInMilliseconds!: number;
   protected reoccurSpawnEndInMilliseconds!: number | null;
   protected featureMeterThresholdPM: boolean = false;
   protected featureUnlimitedPMs: boolean = false;
   private manageFeatureFlagsSub: Subscription = new Subscription();
   private readonly seasonsChangedSub$: Subscription;
   protected recurrenceIs7Or8: boolean = false;
   protected taskTemplateEntity: TaskTemplateEntity | undefined = undefined;

   private readonly manageSchedule = inject(ManageSchedule);
   private readonly alertService = inject(AlertService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageTask = inject(ManageTask);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly recurrenceService = inject(RecurrenceService);
   private readonly seasonsService = inject(SeasonsService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly heapService = inject(HeapService);
   private readonly taskTemplateFacadeService = inject(TaskTemplatesFacadeService);
   private readonly manageLang = inject(ManageLang);
   private readonly temporalUtils = inject(TemporalUtilsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.seasonsChangedSub$ = this.seasonsService.seasonsChanged$.subscribe(() => {
         this.loading = true;
         this.refreshRecurrenceSeasons();
         this.loading = false;
      });
   }

   private refreshRecurrenceSeasons(): void {
      this.getRecurrenceAssociatedSeasons(this.recurrence.reoccurID).then(
         (seasonRelations) => {
            this.seasonRelations = seasonRelations;
            this.updateSelectedSeasonAndHolidayStrings();
         },
      );
   }

   public ngOnInit(): void {
      if (!this.recurrence) {
         throw new Error("Recurrence does not exist.");
      }
      this.taskTemplateEntity = this.taskTemplate as TaskTemplateEntity;
      this.originalSkipEveryXVal = this.recurrence.skipEveryX;

      this.getRecurrenceAssociatedSeasons(this.recurrence.reoccurID).then(
         (seasonRelations) => {
            this.seasonRelations = seasonRelations;

            this.configureRecurrenceDisplaySettings(this.recurrence);

            this.updateSelectedSeasonAndHolidayStrings();
            this.loading = false;
         },
      );

      this.manageFeatureFlagsSub = this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureMeterThresholdPM = isFeatureEnabledMap.featureMeterThresholdPM;
            this.featureUnlimitedPMs = isFeatureEnabledMap.featureUnlimitedPMs;
            this.featureConditionalScheduling =
               isFeatureEnabledMap.featureConditionalScheduling;
         },
      );
   }

   public ngOnDestroy(): void {
      this.seasonsChangedSub$.unsubscribe();
      this.manageFeatureFlagsSub.unsubscribe();
   }

   private updateSelectedSeasonAndHolidayStrings(): void {
      this.selectedHolidaysStr = this.seasonsService.getSelectedHolidaysStr(
         this.seasonRelations,
      );

      this.selectedSeasonsStr = this.seasonsService.getSelectedSeasonsStr(
         this.seasonRelations,
      );
   }

   private configureRecurrenceDisplaySettings(recurrence: Recurrence): void {
      this.recurrenceIs7Or8 = this.recurrenceService.recurrenceIs7Or8(recurrence);

      recurrence.calculateBasedOnIncrementOnly = Number(
         recurrence.calculateBasedOnIncrementOnly,
      );

      const allTemplateSchedules =
         (this.taskTemplate as TaskTemplateEntity).schedules ?? [];

      const hasActiveTimeBasedSchedules =
         allTemplateSchedules.filter(
            (schedule) => schedule.reoccurID === recurrence.reoccurID,
         ).length > 0;
      this.recurrenceHasActiveSchedules = this.manageSchedule.recurrenceHasSchedules_JIT(
         recurrence,
         hasActiveTimeBasedSchedules,
      );

      if (Number(recurrence.reoccurType) === 2) {
         if (typeof recurrence.reoccurFld2 === "string") {
            recurrence.reoccurFld2 = recurrence.reoccurFld2.split(",");
         }
      }

      if (this.recurrenceIs7Or8) {
         this.fieldName = this.manageSchedule.getRecurrenceFieldName(recurrence);
      }

      if (Number(recurrence.reoccurType) === 8) {
         recurrence.reoccurFld3 = Number(recurrence.reoccurFld3);
      }

      this.reoccurSpawnInMilliseconds =
         this.manageSchedule.configureReoccurSpawnForDisplay(
            Number(recurrence.reoccurSpawn),
         );

      this.reoccurSpawnEndInMilliseconds =
         this.manageSchedule.configureReoccurSpawnEndForDisplay(
            recurrence.reoccurSpawnEnd,
         );

      this.showAdvancedSettings = this.recurrenceService.setShowAdvancedSettingsToDefault(
         recurrence,
         this.seasonRelations,
      );
      this.recurrence = recurrence;
   }

   private async getRecurrenceAssociatedSeasons(reoccurID: number): Promise<any[]> {
      let associatedSeasons = [];
      const answer = await this.manageSchedule.getRecurrenceAssociatedSeasons(reoccurID);

      if (answer.data.success) {
         associatedSeasons = answer.data.seasons;
      }

      return associatedSeasons;
   }

   protected changeAllowDuplicateInstances(recurrence: Recurrence): void {
      this.manageSchedule.updateAllowDuplicateInstances(recurrence).then((answer) => {
         if (!answer.data.success) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      });
   }

   protected changeCalculateBasedOnLastCompletedPM(recurrence: Recurrence): void {
      this.manageSchedule
         .updateCalculateBasedOnLastCompletedPM(recurrence)
         .then((answer) => {
            if (!answer.data.success) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               return;
            }

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         });
   }

   private async updateRecurrenceSeasons(
      newlySelectedSeasons: any[],
      seasonType: SeasonType,
   ): Promise<any> {
      this.loading = true;

      const seasonIDs: any = [];

      if (newlySelectedSeasons.length > 0) {
         for (const season of newlySelectedSeasons) {
            seasonIDs.push(season.seasonID);
         }
      }

      const answer = await this.manageSchedule.updateSeasonRelations(
         Number(this.recurrence.reoccurID),
         seasonIDs,
         seasonType,
      );
      if (!answer.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }

      // Update all recurrence's on this same task with the updated season relations
      this.refreshRecurrenceSeasons();

      await this.spawnSchedulesForRecurrence();

      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      this.loading = false;
   }

   private async spawnSchedulesForRecurrence(): Promise<any> {
      if (!this.taskTemplate) {
         return;
      }

      // If this recurrence isn't currently active we don't need to spawn any schedules
      if (!this.recurrenceHasActiveSchedules) {
         this.loadingBarService.remove();
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         return;
      }

      const response = await this.manageSchedule.saveSchedule(
         this.recurrence,
         this.taskTemplate,
         this.reoccurSpawnInMilliseconds,
         this.reoccurSpawnEndInMilliseconds,
         true,
      );
      if (!response.data.success) {
         if (response.data.reason === "Date cannot be set in the past") {
            this.alertService.addAlert(
               this.lang().SaveSchedulePastDateStartsOnError,
               "danger",
               10000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
         this.loadingBarService.remove();
         return;
      }

      // This updates the start time to tomorrow's date if it was in the past.
      this.reoccurSpawnInMilliseconds = new Date(response.data.time * 1000).getTime();
   }

   public async openSeasonsModal(
      recurrence: Recurrence,
      seasonType: SeasonType,
   ): Promise<any> {
      if (!this.featureConditionalScheduling || !this.taskTemplate) {
         return;
      }

      const selectedSeasons = this.seasonsService.getSelectedSeasonsByType(
         seasonType,
         this.seasonRelations,
      );

      const instance = this.modalService.open(SeasonsSelect);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               mode: "select",
               seasonType: seasonType,
               selectedSeasons: selectedSeasons,
               currentRecurrence: recurrence,
            },
         },
      };

      const newlySelectedSeasons = await instance.result;

      if (newlySelectedSeasons === 0) {
         return;
      }
      await this.updateRecurrenceSeasons(newlySelectedSeasons, seasonType);
   }

   public toggleSkipEveryX(recurrence: Recurrence): void {
      if (!this.featureConditionalScheduling) {
         recurrence.skipEveryX = null;
         return;
      }
      if (recurrence.skipEveryX === 0) {
         recurrence.skipEveryX = null;
         this.updateSkipEveryX(recurrence);
      }
   }

   public updateSkipEveryX(recurrence: Recurrence): void {
      if (!this.featureConditionalScheduling) {
         recurrence.skipEveryX = 0;
         return;
      }

      if (Number(this.originalSkipEveryXVal) === Number(recurrence.skipEveryX)) {
         return;
      }

      // // Make sure the number is higher than 2 unless it's null (off)
      if (recurrence.skipEveryX && Number(recurrence.skipEveryX) < 2) {
         recurrence.skipEveryX = this.originalSkipEveryXVal;
         this.alertService.addAlert(
            this.lang().SkipEveryXMustBeTwoOrHigher,
            "danger",
            6000,
         );
         return;
      }

      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });

      this.manageSchedule.updateSkipEveryX(recurrence).then((answer) => {
         if (!answer.data.success) {
            this.loadingBarService.remove();
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            return;
         }

         if (
            Number(recurrence.reoccurType) === 7 ||
            Number(recurrence.reoccurType) === 8
         ) {
            this.loadingBarService.remove();
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            return;
         }

         if (!this.taskTemplate) {
            throw new Error("Cannot find the task in the flat data.");
         }

         this.manageSchedule
            .saveSchedule(
               recurrence,
               this.taskTemplate,
               this.reoccurSpawnInMilliseconds,
               this.reoccurSpawnEndInMilliseconds,
               true,
            )
            .then((response) => {
               if (!response.data.success) {
                  recurrence.skipEveryX = this.originalSkipEveryXVal;
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  return;
               }
               this.originalSkipEveryXVal = recurrence.skipEveryX;

               this.loadingBarService.remove();
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            });
      });
   }

   public updateCalculateBasedOnIncrementOnly(recurrence: Recurrence): void {
      if (!this.featureConditionalScheduling) {
         return;
      }

      this.manageSchedule
         .updateCalculateBasedOnIncrementOnly(recurrence)
         .then((answer) => {
            if (!answer.data.success) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               return;
            }

            if (answer.data.reloadSchedules) {
               // Updates the Next Created at info
               this.manageTask.getData();
            }
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         });
   }

   private refreshRecurrenceDisplay(recurrence: Recurrence): void {
      this.manageTask.getRecurrences().set(recurrence.reoccurID, recurrence);

      const updatedRecurrence = this.manageTask
         .getRecurrences()
         .get(recurrence.reoccurID);

      if (updatedRecurrence) {
         this.configureRecurrenceDisplaySettings(updatedRecurrence);
      }
      this.recurrenceUpdated.emit(recurrence);
   }

   private async handlePickReoccurModal(
      task: Task | TaskTemplateEntity,
      recurrence: Recurrence,
   ): Promise<void> {
      const instance = this.modalService.open(PickReoccur);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().EditRecurrenceMsg,
            title: this.lang().EditRecurrence,
            reoccurID: recurrence.reoccurID,
            checklistID: task.checklistID,
         },
      };

      const result = await instance.result;
      if (result !== 0) {
         this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
         const answer = await this.manageSchedule.saveSchedule(
            result.recurrence,
            task,
            result.reoccurSpawnInMilliseconds,
            result.reoccurSpawnEndInMilliseconds,
         );
         if (answer.data.success === true) {
            if (result.reoccurID > 0 && result.reoccurType === 7) {
               //have to reload data so that the reoccur spawn history is correct
               this.manageTask.getData();
            }
            if (answer.data.time) {
               result.recurrence.reoccurSpawn = answer.data.time;
            }
            this.refreshRecurrenceDisplay(result.recurrence);

            this.recurrenceHasActiveSchedules = true;

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }

         this.loadingBarService.remove();
      }
   }

   protected async editRecurrence(
      task: Task | TaskTemplateEntity | undefined,
      recurrence: Recurrence,
   ): Promise<void> {
      if (!task) {
         return;
      }
      if (this.useModalPopup()) {
         await this.handlePickReoccurModal(task, recurrence);
      } else {
         this.editing = true;
      }
   }

   protected async updateRecurrenceStartDate(
      locationID: number,
      recurrence: Recurrence,
      selectedDate: Date,
   ): Promise<void> {
      const recurrenceStart = selectedDate.getTime();
      if (recurrenceStart === this.reoccurSpawnInMilliseconds) {
         return;
      }
      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      const selectedDateZoned = this.temporalUtils.dateToZdtForLocation(
         selectedDate,
         locationID,
      );
      const answer = await this.manageSchedule.updateRecurrenceSpawnDate(
         recurrence,
         selectedDateZoned,
      );

      if (answer.data.success === true) {
         recurrence.reoccurSpawn = Number(recurrenceStart / 1000);
         this.recurrenceHasActiveSchedules = true;
         this.refreshRecurrenceDisplay(recurrence);

         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         if (answer.data.reason === "Date cannot be set in the past") {
            this.alertService.addAlert(
               this.lang().DateCannotBeSetInThePast,
               "danger",
               6000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      }
      this.loadingBarService.remove();
   }

   protected updateRecurrenceEndDate(
      locationID: number,
      recurrence: Recurrence,
      selectedDate?: Date,
   ): void {
      const recurrenceEnd = new Date(moment(selectedDate).valueOf()).getTime();

      if (recurrenceEnd === this.reoccurSpawnEndInMilliseconds) {
         return;
      }

      this.loadingBarService.show({ header: this.lang().ThisMayTakeAMoment });
      this.manageSchedule
         .updateSingleRecurrenceEndOnDate(recurrence, locationID, selectedDate)
         .then((answer) => {
            if (answer.data.success == true) {
               if (selectedDate) {
                  recurrence.reoccurSpawnEnd = selectedDate.getTime() / 1000;
                  this.reoccurSpawnEndInMilliseconds = selectedDate.getTime();
               }

               this.refreshRecurrenceDisplay(recurrence);

               this.recurrenceHasActiveSchedules = true;

               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
            this.loadingBarService.remove();
         });
   }

   protected clearEndOnDate(recurrence: Recurrence, locationID: number): void {
      recurrence.reoccurSpawnEnd = null;
      this.reoccurSpawnEndInMilliseconds = null;
      this.updateRecurrenceEndDate(locationID, recurrence, undefined);
   }

   protected updateRecurrence(reoccurID: number): void {
      this.recurrence = this.manageTask.getRecurrences().get(reoccurID) as Recurrence;
      this.editing = false;
   }

   protected flipRecurrence(recurrence: Recurrence): void {
      if (!this.canToggleRecurrences) {
         return;
      }

      if (
         (recurrence.reoccurType === 7 || recurrence.reoccurType === 8) &&
         !this.featureMeterThresholdPM
      ) {
         return;
      }
      this.recurrenceHasActiveSchedules = !this.recurrenceHasActiveSchedules;
      if (this.recurrenceHasActiveSchedules === true) {
         this.heapService.trackEvent("managePMs-enableAPMSchedule");
         this.manageSchedule.enableSchedules(recurrence).then((answer) => {
            if (answer.data.success === true) {
               recurrence.reoccurSpawn = new Date(answer.data.time).getTime();
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else if (answer.data.reason === "All schedules will be skipped.") {
               this.alertService.addAlert(
                  this.lang().AllSchedulesWillBeSkipped,
                  "danger",
                  12000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      } else if (this.recurrenceHasActiveSchedules === false) {
         this.heapService.trackEvent("managePMs-disableAPMSchedule");
         this.manageSchedule.disableSchedules(recurrence).then((answer) => {
            if (answer.data.success === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      }
   }

   protected toggleShowAdvancedSettings(): void {
      this.showAdvancedSettings = !this.showAdvancedSettings;
   }

   protected deleteRecurrence(
      task: Task | TaskTemplateEntity | undefined,
      reoccurID: number,
   ): void {
      if (!task) {
         return;
      }
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteRecurrenceMsg,
            title: this.lang().DeleteRecurrence,
         },
      };

      instance.result.then((result) => {
         if (result === 1) {
            this.manageSchedule
               .deleteRecurrence(reoccurID, task.checklistID)
               .then((answer) => {
                  if (answer.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                     if (answer.data.removedDynamicAssignment) {
                        task.dynamicAssignment = 0;
                     }
                     this.recurrenceDeleted.emit(reoccurID);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   }

   protected updateType7StartOn(recurrence: Recurrence): void {
      this.manageSchedule
         .updateType7StartOn(recurrence.reoccurID, recurrence.reoccurFld3)
         .then((answer) => {
            if (answer.data.success == true) {
               // have to reload data so that the reoccur spawn history is correct
               this.manageTask.getData();

               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   protected async flipRecurrenceFor7and8(recurrence: Recurrence): Promise<void> {
      let canModifyRecurrences = false;
      canModifyRecurrences = await this.taskTemplateFacadeService.canModifyRecurrences(
         this.taskTemplate as TaskTemplateEntity,
      );

      if (!canModifyRecurrences) {
         return;
      }

      if (
         (recurrence.reoccurType == 7 || recurrence.reoccurType == 8) &&
         !this.featureMeterThresholdPM
      ) {
         return;
      }

      if (recurrence.reoccurFld4 == "1") {
         recurrence.reoccurFld4 = "0";
         this.recurrenceHasActiveSchedules = false;
      } else {
         recurrence.reoccurFld4 = "1";
         this.recurrenceHasActiveSchedules = true;
      }

      this.manageSchedule
         .enableDisableTypes7and8(recurrence.reoccurID, recurrence.reoccurFld4)
         .then((answer) => {
            if (answer.data.success == true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   protected openRegularIntervals() {
      window.open(
         "https://help.limblecmms.com/en/articles/7068697-advanced-pm-scheduling-options#h_9737e0c247",
         "_blank",
      );
   }

   protected openSkipOccurrences() {
      window.open(
         "https://help.limblecmms.com/en/articles/6930674-conditional-pm-schedules-skip-every-x-occurrence",
         "_blank",
      );
   }

   protected openStackPMs() {
      window.open("https://help.limblecmms.com/en/articles/7068669-stack-pms", "_blank");
   }
}
