<p-menu
   #menu
   [model]="items()"
   [appendTo]="appendTo()"
   [ariaLabel]="ariaLabel()"
   [ariaLabelledBy]="ariaLabelledBy()"
   [popup]="popup()"
   (onShow)="show.emit()"
   (onHide)="hide.emit()"
   (click)="click.emit($event)"
/>
