import { computed, inject, Injectable } from "@angular/core";
import { ModalService } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { DocTutorial } from "src/app/onboarding/services/tutorials/doc-tutorial";
import { TutorialSection } from "src/app/onboarding/services/tutorials/tutorial-section";
import { VideoTutorial } from "src/app/onboarding/services/tutorials/video-tutorial";
import { TutorialsModal } from "src/app/shared/components/global/tutorials-modal/tutorials-modal.component";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";

@Injectable({ providedIn: "root" })
export class ManageTutorial {
   private readonly tutorialSections: { [key: string]: TutorialSection } = {};

   private readonly manageLang = inject(ManageLang);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUtil = inject(ManageUtil);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.setAssetsTutorials();
      this.setAssetsModalTutorials();
      this.setCustomDashboardsTutorials();
      this.setPMsTutorials();
      this.setManageWorkTutorials();
      this.setOpenTasksTutorials();
      this.setPurchasingTutorials();
      this.setBudgetsTutorials();
      this.setUsersTutorials();
      this.setTeamsTutorials();
      this.setPartsTutorials();
      this.setLocationsTutorials();
      this.setWorkRequestsTutorials();
      this.setMobileDashboardTutorials();
      this.setMapsTutorials();
      this.setCycleCountsTutorials();
      this.setLimbleConfigTutorials();
      this.setVendorsTutorials();
      this.setUnitOfMeasureTutorials();
   }

   public getTutorialSection = (tutorialSectionName): TutorialSection => {
      const tutorialSection = this.tutorialSections[tutorialSectionName];
      if (!tutorialSection) {
         console.error(`Tutorial section ${tutorialSectionName} not found`);
      }

      return tutorialSection;
   };

   public openTutorialsModal = (tutorialSection: TutorialSection): void => {
      const instance = this.modalService.open(TutorialsModal);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            tutorialSection: tutorialSection,
         },
      };
   };

   public watchVideoTutorial(videoTutorial: VideoTutorial): void {
      this.manageUtil.watchVideo(videoTutorial.video);
   }

   public openDocTutorial(docTutorial: DocTutorial): void {
      window.open(docTutorial.link, "_blank");
   }

   private setAssetsTutorials(): void {
      this.tutorialSections.assets = new TutorialSection({
         title: this.lang().AssetsTutorialsTitle,
         description: this.lang().AssetsTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().AssetsVideoTitle,
               videoId: "lD9HLw4Iez0",
               cta: this.lang().WatchNow,
               order: 0,
            }),
            new VideoTutorial({
               title: this.lang().ImportAssetsFromExcel,
               videoId: "5JARsO-JAcM",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               title: this.lang().AllAboutAssetHierarchies,
               videoId: "bGGFrBwQqck",
               cta: this.lang().WatchNow,
               order: 2,
            }),
         ],
         limbleRepName: "Natalie Harbin",
         limbleRepPhotoURL: "assets/img/csHeadshots/Natalie.jpg",
      });
   }

   private setAssetsModalTutorials(): void {
      this.tutorialSections.assetsModal = new TutorialSection({
         title: "",
         description: "",
         tutorials: [
            new VideoTutorial({
               name: "Asset Settings",
               title: this.lang().CredDescription196,
               videoId: "YPdKjsjnNbg",
               cta: this.lang().WatchNow,
               order: 0,
            }),
            new VideoTutorial({
               name: "Info Tab",
               title: this.lang().tutorialAssetCardTitle,
               videoId: "lD9HLw4Iez0",
               startTime: "100",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               name: "Log Tab",
               title: this.lang().tutorialAssetCardLogTabTitle,
               videoId: "lD9HLw4Iez0",
               startTime: "362",
               cta: this.lang().WatchNow,
               order: 2,
            }),
            new VideoTutorial({
               name: "WOs Tab",
               title: this.lang().tutorialAssetCardWorkOrderTabTitle,
               videoId: "oAkvYW7f3v8",
               cta: this.lang().WatchNow,
               order: 3,
            }),
            new VideoTutorial({
               name: "Reports Tab",
               title: this.lang().tutorialAssetCardAssetTabTitle,
               videoId: "lD9HLw4Iez0",
               startTime: "362",
               cta: this.lang().WatchNow,
               order: 4,
            }),
            new VideoTutorial({
               name: "PMs Tab",
               title: this.lang().tutorialAssetCardSettingUpPMTitle,
               videoId: "J-7gxiIF_8w",
               cta: this.lang().WatchNow,
               order: 5,
            }),
            new VideoTutorial({
               name: "Children Tab",
               title: this.lang().tutorialChildrenCardSettingUpPMTitle,
               videoId: "bGGFrBwQqck",
               cta: this.lang().WatchNow,
               order: 6,
            }),
         ],
         limbleRepName: "",
         limbleRepPhotoURL: "",
      });
   }

   private setCustomDashboardsTutorials(): void {
      this.tutorialSections.customDashboards = new TutorialSection({
         title: this.lang().CustomDashboardTutorialsTitle,
         description: this.lang().CustomDashboardTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               name: "Custom Dashboards",
               title: this.lang().IntroToCustomDashboards,
               videoId: "EuZEDDar2MU",
               cta: this.lang().WatchNow,
               order: 1,
            }),
         ],
         limbleRepName: "Miguel Ramos",
         limbleRepPhotoURL: "assets/img/csHeadshots/Miguel-circle.jpg",
      });
   }

   private setPMsTutorials(): void {
      this.tutorialSections.PMs = new TutorialSection({
         title: this.lang().PMsTutorialsTitle,
         description: this.lang().PMsTutorialsModalDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().PMBeginnersGuide,
               videoId: "J-7gxiIF_8w",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               title: this.lang().QRCodesTutorialForPMsVideoTitle,
               videoId: this.lang().QRCodesTutorialVideoId,
               startTime: "348",
               cta: this.lang().WatchNow,
               order: 2,
            }),
         ],
         limbleRepName: "Dan Cahoon",
         limbleRepPhotoURL: "assets/img/csHeadshots/Dan-circle.jpg",
      });
   }

   private setOpenTasksTutorials(): void {
      this.tutorialSections.openTasks = new TutorialSection({
         title: this.lang().openTasksTutorialsTitle,
         description: this.lang().openTasksTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().IntroToWorkOrders,
               videoId: "oAkvYW7f3v8",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               title: this.lang().MobileAppTutorial,
               videoId: "m2uQ-n1u4sM",
               cta: this.lang().WatchNow,
               order: 2,
            }),
         ],
         limbleRepName: "Appio Hunter",
         limbleRepPhotoURL: "assets/img/csHeadshots/Appio.jpg",
      });
   }

   private setManageWorkTutorials(): void {
      this.tutorialSections.manageWork = new TutorialSection({
         title: this.lang().manageWorkTutorialsTitle,
         description: this.lang().manageWorkTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().IntroToManagingWork,
               videoId: "Da_kL16Xjac",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               title: this.lang().IntroToWorkOrders,
               videoId: "oAkvYW7f3v8",
               cta: this.lang().WatchNow,
               order: 2,
            }),
            new VideoTutorial({
               title: this.lang().MobileAppTutorial,
               videoId: "m2uQ-n1u4sM",
               cta: this.lang().WatchNow,
               order: 3,
            }),
         ],
         limbleRepName: "Appio Hunter",
         limbleRepPhotoURL: "assets/img/csHeadshots/Appio.jpg",
      });
   }

   private setPurchasingTutorials(): void {
      this.tutorialSections.purchasing = new TutorialSection({
         title: this.lang().PurchasingTutorialsTitle,
         description: this.lang().PurchasingTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().IntroToPurchasing,
               videoId: "fg3ZAfolUbU",
               cta: this.lang().WatchNow,
               order: 1,
            }),
         ],
         limbleRepName: "Appio Hunter",
         limbleRepPhotoURL: "assets/img/csHeadshots/Appio.jpg",
      });
   }

   private setBudgetsTutorials(): void {
      this.tutorialSections.budgets = new TutorialSection({
         title: this.lang().BudgetsTutorialsTitle,
         description: this.lang().BudgetsTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().BudgetsAndApprovalWorkflows,
               videoId: "yKkPRz8oYPQ",
               cta: this.lang().WatchNow,
               order: 1,
            }),
         ],
         limbleRepName: "",
         limbleRepPhotoURL: "",
      });
   }

   private setUsersTutorials(): void {
      this.tutorialSections.users = new TutorialSection({
         title: this.lang().UsersTutorialsTitle,
         description: this.lang().UsersTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().UsersBeginnersGuide,
               videoId: "XJhvbhkiqrA",
               cta: this.lang().WatchNow,
               order: 1,
            }),
         ],
         limbleRepName: "Bonnie Bartleson",
         limbleRepPhotoURL: "assets/img/csHeadshots/Bonnie-circle.jpg",
      });
   }

   private setTeamsTutorials(): void {
      this.tutorialSections.teams = new TutorialSection({
         title: this.lang().TeamsTutorialsTitle,
         description: this.lang().TeamsTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().TeamManagementTutorial,
               videoId: "XJhvbhkiqrA",
               startTime: "573",
               cta: this.lang().WatchNow,
               order: 1,
            }),
         ],
         limbleRepName: "Gabrielle Lucero",
         limbleRepPhotoURL: "assets/img/csHeadshots/Gabbey-circle.jpg",
      });
   }

   private setPartsTutorials(): void {
      this.tutorialSections.parts = new TutorialSection({
         title: this.lang().PartsTutorialsTitle,
         description: this.lang().PartsTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().PartsVideoTitle,
               videoId: "MkDA4aU6cdU",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new DocTutorial({
               title: this.lang().PrintLabelsForYourParts,
               link: "https://help.limblecmms.com/en/articles/6706053-how-to-print-labels-with-qr-codes-in-limble",
               cta: this.lang().LearnHow,
               description: this.lang().PrintLabelsForYourPartsDescription,
               order: 2,
            }),
         ],
         limbleRepName: "Gabrielle Lucero",
         limbleRepPhotoURL: "assets/img/csHeadshots/Gabbey-circle.jpg",
      });
   }

   private setLocationsTutorials(): void {
      this.tutorialSections.locations = new TutorialSection({
         title: this.lang().LocationsTutorialsTitle,
         description: this.lang().LocationsTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().IntroLocationManagementTutorial,
               videoId: "qO0SXTwWeMM",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               title: this.lang().UsingRegions,
               videoId: "l68TTPP0OQE",
               cta: this.lang().WatchNow,
               order: 2,
            }),
         ],
         limbleRepName: "Natalie Harbin",
         limbleRepPhotoURL: "assets/img/csHeadshots/Natalie.jpg",
      });
   }

   private setWorkRequestsTutorials(): void {
      this.tutorialSections.workRequests = new TutorialSection({
         title: this.lang().WorkRequestsTutorialsTitle,
         description: this.lang().WorkRequestsTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().HowToSetUpAWorkRequestPortal,
               videoId: "-EZE01yE3t0",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               title: this.lang().AdvancedWorkRequestSettings,
               videoId: "KqsWCHzJQCY",
               cta: this.lang().WatchNow,
               order: 2,
            }),
         ],
         limbleRepName: "Bonnie Bartleson",
         limbleRepPhotoURL: "assets/img/csHeadshots/Bonnie-circle.jpg",
      });
   }

   private setMobileDashboardTutorials(): void {
      this.tutorialSections.mobileDashboard = new TutorialSection({
         title: this.lang().MobileDashboardTutorialsTitle,
         description: this.lang().MobileDashboardTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().MobileAppGuide,
               videoId: "m2uQ-n1u4sM",
               cta: this.lang().WatchNow,
               order: 1,
            }),
         ],
         limbleRepName: "Dan Cahoon",
         limbleRepPhotoURL: "assets/img/csHeadshots/Dan-circle.jpg",
      });
   }

   private setMapsTutorials(): void {
      this.tutorialSections.maps = new TutorialSection({
         title: this.lang().MapsTutorialsTitle,
         description: this.lang().MapsTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().MapsTutorialHeader,
               videoId: "wYJbZ-E5uaA",
               cta: this.lang().WatchNow,
               order: 1,
            }),
         ],
         limbleRepName: "",
         limbleRepPhotoURL: "",
      });
   }

   private setCycleCountsTutorials(): void {
      this.tutorialSections.cycleCounts = new TutorialSection({
         title: this.lang().CycleCountsTutorialsTitle,
         description: "",
         tutorials: [
            new VideoTutorial({
               name: "Cycle Counts Video",
               title: this.lang().GettingStartedUsingCycleCountsTutorialHeader,
               videoId: "P7TEcoqQ-5I",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new DocTutorial({
               name: "Cycle Counts Doc",
               title: "",
               link: "https://help.limblecmms.com/en/articles/5695413-using-cycle-counts-to-audit-parts-inventory",
               cta: this.lang().LearnHow,
               description: "",
               order: 2,
            }),
         ],
         limbleRepName: "",
         limbleRepPhotoURL: "",
      });
   }

   private setLimbleConfigTutorials(): void {
      this.tutorialSections.limbleConfig = new TutorialSection({
         title: "",
         description: "",
         tutorials: [
            new VideoTutorial({
               name: "Priorities",
               title: this.lang().Priorities,
               videoId: "mLRgiVU65Wo",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               name: "Labor Categories",
               title: this.lang().LaborCategories,
               videoId: "vKcImpvLTkU",
               cta: this.lang().WatchNow,
               order: 2,
            }),
            new VideoTutorial({
               name: "API Authentication",
               title: this.lang().APIAuthentication,
               videoId: "R5ZfNgO1aFE",
               cta: this.lang().WatchNow,
               order: 3,
            }),
         ],
         limbleRepName: "",
         limbleRepPhotoURL: "",
      });
   }

   private setVendorsTutorials(): void {
      this.tutorialSections.vendors = new TutorialSection({
         title: this.lang().VendorsTutorialsTitle,
         description: this.lang().VendorsTutorialsDescription,
         tutorials: [
            new VideoTutorial({
               title: this.lang().VendorsVideoTitle,
               videoId: "HcfT2dHlX5k",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new DocTutorial({
               title: this.lang().ShareTasksWithVendors,
               link: "https://intercom.help/limble-cmms/en/articles/3610812",
               cta: this.lang().LearnHow,
               description: this.lang().ShareTasksWithVendorsDescription,
               order: 2,
            }),
         ],
         limbleRepName: "",
         limbleRepPhotoURL: "",
      });
   }

   private setUnitOfMeasureTutorials(): void {
      this.tutorialSections.unitOfMeasure = new TutorialSection({
         title: this.lang().HowToUseUnitOfMeasure,
         description: this.lang().HowToUseUnitOfMeasure,
         tutorials: [
            new VideoTutorial({
               name: "UnitOfMeasure",
               title: this.lang().HowToUseUnitOfMeasure,
               videoId: "a14552891aa5464ab0854bf7c386089d",
               trackingID: "2f562ad8-acd1-4cc1-bebb-1c0fe7214b4a",
               domain: "loom",
               cta: this.lang().WatchNow,
               order: 1,
            }),
            new VideoTutorial({
               name: "Purchasables",
               title: this.lang().HowToUsePurchasable,
               videoId: "db62025495b548f7b288040e6dac0d10",
               trackingID: "f84c54be-7e72-40d9-8864-81a49e7fad25",
               domain: "loom",
               cta: this.lang().WatchNow,
               order: 2,
            }),
            new VideoTutorial({
               name: "StockUnits",
               title: this.lang().HowToUseStockUnits,
               videoId: "f548883f65034d14b94b3bfd016ce8a7",
               trackingID: "0c29b682-119a-449c-b98f-698f4e584aa2",
               domain: "loom",
               cta: this.lang().WatchNow,
               order: 3,
            }),
         ],
         limbleRepName: "",
         limbleRepPhotoURL: "",
      });
   }
}
