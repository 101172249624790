import { Component, computed, input } from "@angular/core";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";

@Component({
   selector: "data-viewer-pagination-info",
   templateUrl: "./data-viewer-pagination-info.component.html",
   imports: [TranslateDirective],
})
export class DataViewerPaginationInfoComponent {
   currentPage = input.required<number>();
   pageSize = input.required<number>();
   totalResults = input.required<number>();

   startIndex = computed(() => (this.currentPage() - 1) * this.pageSize() + 1);
   endIndex = computed(() =>
      Math.min(this.startIndex() + this.pageSize() - 1, this.totalResults()),
   );
}
