<div class="reminder-wrapper">
   <lim-ui-checkbox
      [model]="showDateReminderDisplay"
      (click)="updateDateReminderEnabled()"
      [label]="lang().SendAReminder"
      [attr.data-log]="dataLogLabel()"
      [disabled]="disabled()"
   />
   <lim-ui-dropdown [disabled]="disabled()">
      <div button class="blue clickable" id="date-reminder-display">
         {{ showDateReminderDisplay ? dateReminderDisplay : lang().Select }}
      </div>
      <div menu aria-labelledby="date-reminder-display">
         <lim-ui-dropdown-text-item
            (click)="updateReminderTime(1)"
            [displayString]="lang().OneDayBefore"
            [attr.data-log]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         />
         <lim-ui-dropdown-text-item
            (click)="updateReminderTime(7)"
            [displayString]="lang().OneWeekBefore"
            [attr.data-log]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         />
         <lim-ui-dropdown-text-item
            (click)="updateReminderTime(30)"
            [displayString]="lang().OneMonthBefore"
            [attr.data-log]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         />
         <lim-ui-dropdown-text-item
            (click)="updateReminderTime(60)"
            [displayString]="lang().TwoMonthsBefore"
            [attr.data-log]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         />
         <lim-ui-dropdown-text-item
            (click)="updateReminderTime(90)"
            [displayString]="lang().ThreeMonthsBefore"
            [attr.data-log]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         />
         <lim-ui-dropdown-text-item
            (click)="updateReminderTime(180)"
            [displayString]="lang().SixMonthsBefore"
            [attr.data-log]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         />
         <lim-ui-dropdown-text-item
            (click)="updateReminderTime(365)"
            [displayString]="lang().OneYearBefore"
            [attr.data-log]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         />
         <lim-ui-dropdown-item
            [closeDropdownOnClick]="false"
            [dataLogLabel]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         >
            <div class="custom-date-wrapper">
               <input
                  class="custom-date-input"
                  type="number"
                  (blur)="updateReminderTime(customDays)"
                  min="1"
                  [(ngModel)]="customDays"
               />
               {{ lang().Days }} {{ lang().before }}
            </div>
         </lim-ui-dropdown-item>
         <lim-ui-dropdown-item
            [closeDropdownOnClick]="false"
            [dataLogLabel]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         >
            <div class="custom-date-wrapper">
               <input
                  class="custom-date-input"
                  type="number"
                  (blur)="updateReminderTime(customWeeks * 7)"
                  min="1"
                  [(ngModel)]="customWeeks"
               />
               {{ lang().Weeks }} {{ lang().before }}
            </div>
         </lim-ui-dropdown-item>
         <lim-ui-dropdown-item
            [closeDropdownOnClick]="false"
            [dataLogLabel]="
               'manage' + type + '-storeAddCustomField-clickChangeReminderDate'
            "
         >
            <div class="custom-date-wrapper">
               <input
                  class="custom-date-input"
                  type="number"
                  (blur)="updateReminderTime(customMonths * 30)"
                  min="1"
                  [(ngModel)]="customMonths"
               />
               {{ lang().Months }} {{ lang().before }}
            </div>
         </lim-ui-dropdown-item>
      </div>
   </lim-ui-dropdown>
</div>
