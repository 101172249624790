import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import type { LocationResponse } from "src/app/locations/models/locations-api.models";
import type { LocationEntityFilters } from "src/app/locations/models/locations-flannel-api.models";
import type { LocationFlannelApi } from "src/app/locations/services/api/locations-flannel-api.interface";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class LocationMonolithFlannelApiService implements LocationFlannelApi {
   private readonly http = inject(HttpClient);
   private readonly url = `${environment.flannelUrl}/locations`;

   public list(
      requestOptions: {
         params: LocationEntityFilters;
      } = { params: {} },
   ): Observable<Array<LocationResponse>> {
      return this.http.get<Array<LocationResponse>>(this.url, {
         params: requestOptions.params as Record<string, string>,
      });
   }

   public detail(id: number): Observable<LocationResponse> {
      return this.http.get<LocationResponse>(`${this.url}/${id}`);
   }
}
