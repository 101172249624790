<div [ngStyle]="{ 'text-align': column?.textAlign ?? 'left' }">
   <span class="lim-ui-mobile-table-column-label"
      >{{ lang().PartCostEfficiencySavings }}:
   </span>
   @if (task && task.partCostEfficiencySavings) {
      <span>
         {{ task.partCostEfficiencySavings | localeCurrency: task.currencyCode }}</span
      >
   } @else {
      <span>-</span>
   }
</div>
