import type { RequestParam } from "src/app/shared/services/flannel-api-service";

export type Column = {
   key: string;
   displayName: string;
   sortBy: string;
   columnWidth: number;
   textAlign?: string;

   /**
    * Whether the column is sortable
    */
   sortable?: boolean;

   /**
    * List of field keys that should be exported
    */
   exportableFields?: Array<string>;

   /**
    * List of extra columns to be added to the API request in order to render the column
    */
   extraColumns?: Array<string>;
   /**
    * List of parameters to be added to the API request in order to render the column
    */
   params?: RequestParam;
};

export enum ColumnKeys {
   ASSET_NAME = "assetName",
   ASSIGNED_TO = "assignedTo",
   COMPLETED_BY = "completedByStr",
   COMPLETED_ON = "checklistCompletedDate",
   COMPLETION_NOTES = "completionNotes",
   DOWNTIME = "checklistDowntime",
   DUE_DATE = "checklistDueDate",
   INVOICE_COST = "checklistTotalInvoiceCost",
   LATEST_ACTIVITY_TIME = "checklistLastEdited",
   LOCATION = "locationName",
   LOCATION_ID = "locationID",
   OPERATING_COST = "checklistTotalOperatingCost",
   TASK_ESTIMATED_TIME = "estimatedTime",
   TASK_ID = "checklistID",
   TASK_NAME = "checklistName",
   LABOR_COST = "laborCost",
   /**
    This is a column similar to the task name but with less information
    *
    */
   TASK_MINIMAL_NAME = "taskMinimalName",
   TASK_NAME_WITHOUT_ASSET = "taskNameWithoutAsset",

   TASK_PART_NAME = "taskPartName",
   TASK_PART_QUANTITY = "taskPartQuantity",
   TASK_STATUS_COLOR = "finalColorStatus",
   TASK_TIME_IN_NONE = "none",
   TASK_TIME_IN_OPEN = "timeSpentInStatusOpen",
   TASK_TIME_IN_PROGRESS = "timeSpentInStatusInProgress",
   TASK_TYPE = "checklistTemplateOldString",
   TIME_SPENT = "checklistPromptTimeTotal",
   TIME_TOTAL_WITH_LABOR_COST = "checklistPromptTimeTotalWithLaborCost",
   TOTAL_PARTS_COST = "checklistTotalPartsCost",

   /**
    * Here are the columns exclusive for the tasks schedules columns
    */
   TASK_SCHEDULE_NAME = "tasksSchedulesCombinedName",
   TASK_SCHEDULE_DUE_DATE = "dueDate",

   PART_LOG_DATE = "logDate",
   PART_LOG_ENTRY = "logEntry",
   PART_LOG_USER = "logUser",
   PART_NAME = "partName",
   PARTS_USED = "usedNumber",
   PART_NUMBER = "partNumber",
   TOTAL_COST_OF_PARTS_USED = "totalPriceOfParts",

   DOWNTIME_EFFICIENCY_RATE = "downtimeEfficiencyRate",
   LABOR_COST_EFFICIENCY_RATE = "laborCostEfficiencyRate",
   PART_COST_EFFICIENCY_RATE = "partCostEfficiencyRate",
   AVERAGE_DOWNTIME_COST = "averageDowntimeCost",
   DOWNTIME_EFFICIENCY_SAVINGS = "downtimeEfficiencySavings",
   LABOR_COST_EFFICIENCY_SAVINGS = "laborCostEfficiencySavings",
   PART_COST_EFFICIENCY_SAVINGS = "partCostEfficiencySavings",

   /**
    * Here are columns for logged time
    */
   PROMPT_TIME = "promptTime",
   LOGGED_AT = "loggedAt",
   NOTES = "notes",
   USER_DISPLAY_NAME = "userDisplayName",
   LOGGED_TIME_TASK_NAME = "loggedTimeTaskName",

   /**
    * Here are columns for work request submissions
    */
   WR_SUBMISSION_STATUS = "status",
   WR_SUBMISSION_NAME = "name",
   WR_SUBMISSION_ID = "id",
   SUBMITTED_BY = "submittedBy",
   SUBMITTED_ON = "submittedOn",
   REVIEWER = "reviewer",
}

type KeysOfColumnKeys = keyof typeof ColumnKeys;
export type ValuesOfColumnKeys = (typeof ColumnKeys)[KeysOfColumnKeys];
